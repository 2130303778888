export class RackCellMapDto{
    rackCellMapId?:number;
    rackId:number;
    zoneId:number;
    cellId:number;
    levelId:number;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    
    /**
     * 
     * @param rackCellMapId 
     * @param levelId 
     * @param rackId 
     * @param zoneId 
     * @param cellId 
     * @param createdUser
     * @param isActive
     */
    constructor(rackCellMapId:number,levelId:number,zoneId:number,cellId:number,rackId:number,createdUser:string,isActive:boolean){
    this.rackCellMapId = rackCellMapId;
    this.rackId = rackId;
    this.cellId = cellId;
    this.levelId = levelId;
    this.zoneId = zoneId;
    this.createdUser = createdUser;
    this.isActive = isActive
    }
} 
