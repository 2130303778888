import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { EmploymentStatusDto } from "./employment-status.dto";

export class EmploymentStatusResponse extends GlobalResponseObject{
    data?: EmploymentStatusDto  ;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: EmploymentStatusDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}