import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { UidResponseModel } from "./uid-response.model";

export  class DeviceDetailsResponse extends GlobalResponseObject {
    data?: UidResponseModel[];

     /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data
     */
      

    constructor(status: boolean, errorCode: number, internalMessage: string, data?: UidResponseModel[]){
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}