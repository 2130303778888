import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ProductionInventoryDetails } from './production-inventory.dto';

export class ProductionInventoryResponse extends GlobalResponseObject{
    data?: ProductionInventoryDetails[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductionInventoryDetails
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ProductionInventoryDetails[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
