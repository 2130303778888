import { CorpusApprovalEnum, PaymentStatusEnum } from "@gtpl/shared-models/common-models";

export class ExpensesDto {
    expensesId:number;
    expensesAgainst:string;
    expensesCategoryId:number;
    paymentMode:string;
    refrenceNo:string;
    paymentStatus:PaymentStatusEnum;
    amount:number;
    corpusApproval:CorpusApprovalEnum;
    approvedBy:string;
    remarks:string;
    isActive:boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser?: string;
    versionFlag?: number;
    constructor( expensesId:number,
        expensesAgainst:string,
        expensesCategoryId:number,
        paymentMode:string,
        refrenceNo:string,
        paymentStatus:PaymentStatusEnum,
        amount:number,
        corpusApproval:CorpusApprovalEnum,
        approvedBy:string,
        remarks:string,
        isActive:boolean,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser?: string,
        versionFlag?: number,){
       this.expensesId=expensesId;
       this.expensesCategoryId=expensesCategoryId;
       this.expensesAgainst=expensesAgainst;
       this.paymentMode=paymentMode;
       this.refrenceNo=refrenceNo;
       this.paymentStatus=paymentStatus;
       this.amount=amount;
       this.corpusApproval=corpusApproval;
       this.approvedBy=approvedBy;
       this.remarks=remarks;
       this.isActive=isActive;
       this.createdAt=createdAt;
       this.createdUser=createdUser;
       this.updatedAt=updatedAt;
       this.updatedUser=updatedUser;
       this.versionFlag=versionFlag;
    }
}