import { Param } from "@nestjs/common";

export class TransferMaterialRequest{
    unitId : number;

    /**
    
     * @param unitId 
     
     */

    constructor(unitId : number){
        this.unitId = unitId

    }
}