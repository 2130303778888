export class UomsDropDownDto {
    uomId: number;
    uomName: string;
    /**
     * 
     * @param uomId 
     * @param uomName
     * 
     */
    constructor(uomId: number, uomName: string) {
        this.uomId = uomId;
        this.uomName = uomName;
    }
}