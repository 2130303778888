import { CommonResponse } from '@gtpl/shared-models/masters';
import { AllQcDefectResponseModel, operationRequest, Qcdefectdto, QcDefectItemDetailsReq, QcdefectReportRequest, QCDefectRequest, QcdefectResponsemodel, QcdefectworkstationNameResponseModell, testCodeRequestForScan, workStationForOpearation, WorkstationRequest } from '@gtpl/shared-models/production-management';
import axios from 'axios';
import connection from './connection';
export class QcDefectService{
    URL=connection.DEPLOY_URL + '/qcdefect';

    async createQcDefect(req: Qcdefectdto): Promise<QcdefectResponsemodel> {
        return await axios.post(this.URL + '/createQcDefect',req)
            .then(res => {
                return res.data
            })
    }
    async  updateQcDefectDetails(qcdefectdto: Qcdefectdto): Promise<QcdefectResponsemodel> {
        return await axios.post(this.URL + '/updateQcDefectDetails', qcdefectdto)
               .then(res => {
                   return res.data
               })
    }

    async getAllQcDefectDetails(req:QcdefectReportRequest): Promise<AllQcDefectResponseModel> {
        return await axios.post(this.URL + '/getAllQcDefectDetails',req)
            .then(res => {
                return res.data
            })     
    }
    async  activateOrDeactivateQcDefectDetails(qcdefectdto: Qcdefectdto): Promise<QcdefectResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/activateOrDeactivateQcDefectDetails', qcdefectdto)
                  .then(res => {
                      return res.data
                  })
      }
      async  getActiveQcDefectById(Request: QCDefectRequest): Promise<QcdefectResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/getActiveQcDefectById', Request)
                  .then(res => {
                      return res.data
                  })
      }
      async  getAllActiveQcDefectDetails(): Promise<AllQcDefectResponseModel> {
        return await axios.post(this.URL + '/getAllActiveQcDefectDetails')
            .then(res => {
                return res.data
            })
    }
    async  getWorkstation(request?: WorkstationRequest): Promise<QcdefectworkstationNameResponseModell> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/getWorkstation', request)
                  .then(res => {
                      return res.data
                  })
      }
async getTestnameforOperation(req:operationRequest):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getTestnameforOperation',req)
    .then(res =>{
        return res.data
    })
}

async getAllActiveWorkstation(req?:workStationForOpearation):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getAllActiveWorkstation',req)
    .then(res =>{
        return res.data
    })
}

async getAllLotnumbers():Promise<CommonResponse>{
    return await axios.post(this.URL + '/getAllLotnumbers')
    .then(res =>{
        return res.data
    })
}
async getTestnameforOperationForScan(req:testCodeRequestForScan):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getTestnameforOperationForScan',req)
    .then(res =>{
        return res.data
    })
}
async getTestNameForReport():Promise<CommonResponse>{
    return await axios.post(this.URL + '/getTestNameForReport')
    .then(res =>{
        return res.data
    })
}
async getQcdefectItemByQcId(req:QcDefectItemDetailsReq):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getQcdefectItemByQcId',req)
    .then(res =>{
        return res.data
    })
}

}