import { AssetService } from "@gtpl/shared-services/asset-management"
import { AlertMessages } from "@gtpl/shared-utils/alert-messages"
import { Col, Row } from "antd"
import Form, { useForm } from "antd/lib/form/Form"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { title } from "process"
import React, { useEffect, useState } from "react"

export interface AssetsSkippedGraphProps{}

export function AssetsSkippedGraph(){
    const form = useForm

    useEffect(() =>{
     getSkippedAssetsCount();
    },[])
    const[assetsData,setAssetsData]= useState<any[]>([]);
    console.log(assetsData)
    const getSkippedAssetsCount =()=>{
        service.getSkippedAssetsCount().then(res => {
          if (res.status) {
            setAssetsData(res.data);
          } else {
              if (res.intlCode) {
                setAssetsData([]);
                  AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
              }
          }
      }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setAssetsData([]);
      })
    
      }
      const plantname = assetsData.map(i =>{return i.plantName});
      const totalassets = assetsData.map(i =>{return Number(i.assets)});

      console.log(plantname);
      console.log(totalassets)
    const service = new AssetService();

    const config = {
      colors: ['#058DC7', '#50B432', '#FFC000', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
      chart: {
        type: 'bar',
        backgroundColor: '#272953'
      },
  
      title: {
        text: 'Service Skipped Assets',
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.75rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        }
      },
  
      xAxis: {
        categories: plantname,
        labels: {
          style: {
            color: 'var(--text-color,#eee)'
          }
        }
      },
  
      yAxis: {
        allowDecimals: false,
        min: 0,
        // tickAmount: 16,
        // tickPixelInterval: 100,
        style: {
          color: 'var(--text-color,#eee)',
          fontSize: '1.15rem',
          lineHeight: '1.4',
          marginBottom: '0',
          overflow: 'hidden',
          // paddingTop: '2px',
          paddingTop: 'calc(2px*var(--scale-factor, 1))',
          position: 'relative',
          textOverFlow: 'ellipsis',
          whiteSpace: 'nowrap',
          zIndex: '5',
          fontFamily: 'visuelt-bold-pro,Arial,sans-serif,Font Awesome\ 5 Pro',
        },
        labels: {
          style: {
            color: 'var(--text-color,#eee)'
          }
        }
      },
  
      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>'  + 'No.of Assets: ' + this.y  + '<br/>'
  
        }
      },
  
      plotOptions: {
        bar: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
  
      series: [
        {
        name: 'Units',
        data:totalassets,
        // stack: 'male'
      }, 
      // {
      //   name: 'Containers',
      //   data:graphContainersData,
      //   stack: 'male'
      // },
    ]
    }
return(
      <div><HighchartsReact highcharts={Highcharts} options={config} /></div>

)
}
