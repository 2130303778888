export enum AssetStatusEnum {
     
    GRN = 'GRN',
    INSTOCK = 'INSTOCK',
    INUSE = 'INUSE',
    UNDERMAINTENANCE = 'UNDERMAINTENANCE',
    DAMAGED = 'DAMAGED',
    RETIRED = 'RETIRED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    INTRANSIT = 'INTRANSIT',
    SERVICE = 'SERVICE',
    LOCATIONMAPPED = 'LOCATION-MAPPED',
    SOLD = 'SOLD'
}