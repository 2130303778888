export class RackPositionModel{
    rackCode: string;
    plantId: number;
    assocRfId: string;
    rackPosition: string;
    rackLevel: number;
    rackBoundaries: string;
    occupancy: string;    
    rackId?:number;
    isActive?:boolean;
    rackMasterId?: number;
    rackMasterName?: string;
    rackMasterCode?: string;
    rackPositionId?: number;
    maxPalletCapacity?: number;




    /**
     * @param rackId
     * @param rackCode 
     * @param plantId 
     * @param rfIds 
     * @param rackPosition 
     * @param rackLevel 
     * @param rackBoundaries 
     * @param occupancy 
     * @param rackId
     * @param isActive
     * @param rackMasterId 
     * @param rackMasterName 
     * @param rackMasterCode
     */
     constructor(rackCode:string,plantId: number,assocRfId: string,rackPosition: string,rackLevel: number,rackBoundaries: string,occupancy: string,rackId?:number,isActive?:boolean,rackMasterId?: number, rackMasterName?: string, rackMasterCode?: string,rackPositionId?: number, maxPalletCapacity?:number){
        this.rackMasterId = rackMasterId;
        this.rackCode = rackCode;
        this.plantId = plantId;        
        this.assocRfId = assocRfId;
        this.rackPosition = rackPosition;
        this.rackLevel = rackLevel;
        this.rackBoundaries = rackBoundaries;
        this.occupancy = occupancy;
        this.rackId = rackId;
        this.isActive = isActive;
        this.rackMasterId = rackMasterId;
        this.rackMasterName = rackMasterName;
        this.rackMasterCode = rackMasterCode;
        this.rackPositionId = rackPositionId;
        this.maxPalletCapacity = maxPalletCapacity;
    }
}