import axios from 'axios';
import connection from './connection';
import {ComplaintsDto, AllComplaintsResponseModel, ComplaiintsResponseModel} from '@gtpl/shared-models/hrms';

export class ComplaintsService{

    URL = connection.DEPLOY_URL + '/complaints';

    async createComplaint(complaintsdto: ComplaintsDto ): Promise<ComplaiintsResponseModel> {
        console.log(complaintsdto);
       
        return await axios.post(this.URL + '/createComplaint',complaintsdto)
            .then(res => {
                return res.data
            })
    }

    async getAllComplaints(): Promise<AllComplaintsResponseModel> {
                
        return await axios.post(this.URL + '/getAllComplaints')
            .then(res => {
                return res.data 
            })     
    }

    async  updateComplaint(complaintsdto: ComplaintsDto): Promise<ComplaiintsResponseModel> {
        return await axios.post(this.URL + '/updateComplaint', complaintsdto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateComplaint(complaintsDto: ComplaintsDto): Promise<ComplaiintsResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateComplaint', complaintsDto)
                        .then(res => {
                                return res.data
                        })
                }
                
}