export class HlPercentageDto {
    hlPercentageId?: number;
    minGrade:number;
    maxGrade:number
    hlPercentage: number;
    createdUser:string;
    isActive: boolean;
    updatedUser?:string;
    constructor(hlPercentageId:number,minGrade:number,maxGrade:number,hlPercentage:number,createdUser:string,isActive:boolean,updatedUser?:string){
        this.hlPercentageId = hlPercentageId;
        this.hlPercentage = hlPercentage;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.createdUser =createdUser;
        this.isActive = isActive;
        this.updatedUser=updatedUser
    }
}

export const HlPercentageDtoDefault : HlPercentageDto = {
    hlPercentageId: 0,
    minGrade:0,
    maxGrade:0,
    hlPercentage: 0,
    createdUser:"",
    isActive:true,
    updatedUser:'',
};

