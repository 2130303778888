
export class GrnItemsInfo {
    grnItemId?: number;

    grnId?: number;

    itemCategoryId: number;

    itemId: number;

    sizeId: number;
    stockId?: number;

    poQuantity: number;

    acceptedQuantity: number;

    rejectedQuantity: number;
    receivedQuantity: number;

    unitPrice: number;

    discountPercentage: number;

    discountAmount: number;

    taxPercentage: number;

    taxAmount: number;

    totalAmount: number;


    createdUser: string;


    updatedUser?: string;

    createdAt?: Date;

    updatedAt?: Date;
    saleOrderId?: number;
    itemSubCategoryId?: number;
    isAsset?: string;
    itemName?: string;




    /**
    * @param grnId
    * @param itemCategoryId
    * @param itemId
    * @param sizeId
    * @param poQuantity
    * @param acceptedQuantity
    * @param rejectedQuantity
    * @param unitPrice
    * @param discountPercentage
    * @param discountAmount
    * @param taxPercentage
    * @param taxAmount
    * @param totalAmount
    * @param createdUser
    * @param updatedUser
    * @param saleOrderId
   
     */
    constructor(grnId: number,itemCategoryId: number,itemId: number,sizeId: number,poQuantity: number,acceptedQuantity: number,rejectedQuantity: number,receivedQuantity: number,unitPrice: number,discountPercentage: number,discountAmount: number,taxPercentage: number,taxAmount: number,totalAmount: number,createdUser: string,updatedUser?: string,saleOrderId?:number,itemSubCategoryId?: number,isAsset?: string,itemName?: string) {
        this.grnId = grnId;
    this.itemCategoryId = itemCategoryId;
    this.itemId = itemId;
    this.sizeId = sizeId;
    this.poQuantity = poQuantity;
    this.acceptedQuantity = acceptedQuantity;
    this.rejectedQuantity = rejectedQuantity;
    this.receivedQuantity = receivedQuantity;
    this.unitPrice = unitPrice;
    this.discountPercentage = discountPercentage;
    this.discountAmount = discountAmount;
    this.taxPercentage = taxPercentage;
    this.taxAmount = taxAmount;
    this.totalAmount = totalAmount;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;
    this.saleOrderId = saleOrderId;
    this.itemSubCategoryId = itemSubCategoryId;
    this.isAsset = isAsset;
    this.itemName = itemName;
    }
}