import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Tooltip, Form, Col, Row, Select, Modal, Empty, Popconfirm, message, Tabs, Tag, DatePicker, Typography, Pagination } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { PrinterOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FgStockStatusEnum, InputIdRequest, SoPlantRequest, StockOutModel, StockStatusRequest } from '@gtpl/shared-models/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import './stock-grid.css';
import { RacksForZoneId, ZoneDto } from '@gtpl/shared-models/masters';
import { RackService, ZoneService, EndCustomersService } from '@gtpl/shared-services/masters';
import { RackDropDownDto, ZoneDropDownDto, RackEndCustomerMapDto, EndCustomerDto } from '@gtpl/shared-models/masters';
import PalletLocation from './pallet-location';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { networkInterfaces } from 'os';
const { Option } = Select;
/* eslint-disable-next-line */
export interface StockGridProps {
  isUpdate: boolean;
  Data: RackEndCustomerMapDto;
  updateRackZone: (data: RackEndCustomerMapDto) => void;
}

export interface RackEndcustomerMappingFormProps {
  Data: RackEndCustomerMapDto;
  updateRackZone: (data: RackEndCustomerMapDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export function StockGrid(props: StockGridProps) {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [stockOutForm] = Form.useForm();
  const plantId = Number(localStorage.getItem('unit_id'));
  const [brandfiltersData, setbrandFiltersData] = useState<any[]>([]);
  const [productfiltersData, setproductFiltersData] = useState<any[]>([]);
  const createdUser = localStorage.getItem('createdUser');
  const [stockInData, setStockInData] = useState<StockOutModel[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const stockService = new FGStockService();
  const stockInService = new StockInService();
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [stockOutModel, setStockOutModel] = useState<StockOutModel[]>([]);
  const [selectedInformation, setSelectedInformation] = useState<StockOutModel>();
  const [page, setPage] = React.useState(1);
  const { RangePicker } = DatePicker;
  const [fromDate, setEtdFromDate] = useState(undefined);
  const [toDate, setEtdToDate] = useState(undefined);
  const [rackData, setRackData] = useState<RackDropDownDto[]>([]);
  const rackService = new RackService();
  const zoneService = new ZoneService();
  const [custData, setCustData] = useState<EndCustomerDto[]>([]);
  const [zoneData, setZoneData] = useState<ZoneDto[]>([]);
  const custService = new EndCustomersService();
  const [etdData, setEtdData] = useState<string[]>([]);
  const [productData, setProductData] = useState<any[]>([]);

  const { TabPane } = Tabs;

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    console.log(selectedKeys + "&" + selectedKeys[0] + "************" + confirm + "************" + dataIndex);
    console.log(stockInData);
    if (dataIndex === "poNumber" && selectedKeys[0] != undefined) {
      let productFiltersArray: { text: string, value: string }[] = []
      let produtsUniq = (stockInData.filter(res => res?.poNumber === selectedKeys[0])).filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.variantCode === thing.variantCode
        ))
      );
      produtsUniq.map(item => {
        if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
          productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
      })
      console.log("productFiltersArray " + productFiltersArray.length);
      console.log("productFiltersArray " + productFiltersArray);

      setproductFiltersData(
        productFiltersArray)
    }
    else {
      let productFiltersArray: { text: string, value: string }[] = []
      let produtsUniq = stockInData.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.variantCode === thing.variantCode
        ))
      );
      produtsUniq.map(item => {
        if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
          productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
      })
      console.log("productFiltersArray " + productFiltersArray.length);
      console.log("productFiltersArray " + productFiltersArray);

      setproductFiltersData(
        productFiltersArray
      )
    }
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {

    let productFiltersArray: { text: string, value: string }[] = []
    let produtsUniq = stockInData.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t.variantCode === thing.variantCode
      ))
    );
    produtsUniq.map(item => {
      if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
        productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
    })
    console.log("productFiltersArray " + productFiltersArray.length);
    console.log("productFiltersArray " + productFiltersArray);
    productFiltersArray.sort((a, b) => (a.text > b.text) ? 1 : -1)
    setproductFiltersData(
      productFiltersArray
    )
    clearFilters();
    setSearchText('');
  }
  /**
  * 
  * @param pagination 
  * @param filters 
  * @param sorter 
  * @param extra 
  */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const searchData = () => {
    console.log(form.getFieldsValue());
    //  console.log(form.getFieldValue('stockDateRange')[0]);
    //  console.log(form.getFieldValue('stockDateRange')[1]);
    //  console.log(form.getFieldValue('coldStorage'));
    const coldStorage = form.getFieldValue('coldStorage');
    const varientId = form.getFieldValue('varientId')
    console.log(form.getFieldValue('varientId'))
    let selectedData = filtersData;
  console.log(filtersData)
    if (form.getFieldValue('stockDateRange') != undefined) {
      const selectedFromDate = moment(form.getFieldValue('stockDateRange')[0]).format("YYYY-MM-DD");
      const selectedToDate = moment(form.getFieldValue('stockDateRange')[1]).format("YYYY-MM-DD");
      console.log(selectedToDate, selectedFromDate);
      if (selectedFromDate && selectedToDate) {
        selectedData = filtersData.filter(item => moment(item.stockDate).format("YYYY-MM-DD") > selectedFromDate && moment(item.stockDate).format("YYYY-MM-DD") < selectedToDate)
      }
    }
    if (coldStorage != undefined) {
      selectedData = filtersData.filter(item => item.coldStorage == coldStorage);
    }
    if(varientId != undefined){
      selectedData = filtersData.filter(item => item.varientId == varientId)
    }
    //setStockInData(selectedData);
    const zoneData = form.getFieldValue('zoneData');
    console.log(zoneData);
    //let selectedData = filtersData;
    if (zoneData) {
      selectedData = selectedData.filter(item => item.unitName === zoneData)
    }
    setStockInData(selectedData);
  }

  useEffect(() => {
    getFiltersDropdownData(filtersData);
  }, [filtersData])

  const getFiltersDropdownData = (filtersData) => {
    let etdData: any[] = [];
    filtersData.forEach(element => {
      if (element.etd > fromDate && element.etd < toDate) {
        etdData.push(element.etd)
      }

    });
    setEtdData(etdData)
  }

  useEffect(() => {
    getStockInData();
    getProductDropForStockReport();
  }, []);
  const getStockInSaleOrders = () => {
    stockService.getStockInSaleOrders({ inputId: plantId }).then((res) => {
      if (res.status) {
        setSoData(res.data);
      } else {
        if (res.intlCode) {
          setSoData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoData([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const getProductDropForStockReport = () => {
    stockInService.getProductDropForStockReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setProductData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setProductData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProductData([]);

    });
  }

  const getStockInData = () => {
    stockInService.getAllStock({ plantId: Number(localStorage.getItem('unit_id')), role: localStorage.getItem('role').replace(/"/g, '') }).then(res => {
      if (res.status) {
        let filtersArray: { text: string, value: string }[] = []
        let uniq = res.data.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.brandName === thing.brandName
          ))
        );
        // data for products filter
        uniq.map(item => {
          if (!(filtersArray.includes({ text: item.brandName, value: item.brandName })))
            filtersArray.push({ text: item.brandName, value: item.brandName })
        })

        let productFiltersArray: { text: string, value: string }[] = []
        let produtsUniq = res.data.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.variantCode === thing.variantCode
          ))
        );
        produtsUniq.map(item => {
          if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
            productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
        })

        setproductFiltersData(
          productFiltersArray
        )
        setbrandFiltersData(filtersArray)
        setStockInData(res.data);
        setFiltersData(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  console.log(productfiltersData.sort(collator.compare), 'filter data');


  const handleSoChange = (value) => {
    if (value) {
      stockOutForm.setFieldsValue({
        saleOrderItemId: value
      })
      getStockInData();
    }
  }
  useEffect(() => {
    getAllZonesData();
    getAllEndCustomersData();
    if (props.isUpdate) {
      getRacksForZone(props.Data.zoneId)
    }
  }, [])
  let history = useHistory();

  const getAllZonesData = () => {
    zoneService.getPlantZones({ plantId: Number(localStorage.getItem("unit_id")) }).then((res) => {
      if (res.status) {
        setZoneData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getRacksForZone = (zoneId) => {
    // const zone = new ZoneDropDownDto(zoneId, zoneName.children);
    const zoneRequest = new RacksForZoneId(zoneId);
    rackService.getRacksForZone(zoneRequest).then((res) => {
      if (res.status) {
        setRackData(res.data);
      } else {
        setRackData([]);
        form.setFieldsValue({ rackName: '' })
        AlertMessages.getErrorMessage(res.internalMessage);
      }

    });
  }
  const getAllEndCustomersData = () => {
    custService.getAllActiveEndCustomers().then((res) => {
      if (res.status) {
        setCustData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.internalMessage);
    })
  }
  const onReset = () => {
    // form.resetFields();
    form.resetFields();
    getStockInData();
    // window.location.reload();
  };
  const displayModel = (data: StockOutModel) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    console.log('Clicked on cancel');
    setIsModalVisible(false);
    getStockInData();
  };

  const printStockOut = () => {
    if (stockOutForm.getFieldValue("saleOrderId")) {
      setIsModalVisible(true);
      console.log('print logic goes here');
    } else {
      AlertMessages.getWarningMessage('Please select so number');
    }
  }

  const tableColumns = (key) => {
    console.log(key)
    const columnsSkelton: ColumnProps<StockOutModel>[] = [
      {
        title: 'Sno',
        dataIndex: 'saleOrderId',
        width: 50,
        fixed: 'left',
        align: 'center',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)

      },
      {
        title: 'Prod Unit',
        dataIndex: 'unit',
        fixed: 'left',
        filters: [
          { text: "Kakinada", value: "Kakinada" },
          { text: "Unit 1", value: "Unit 1" },
          { text: "Unit 2", value: "Unit 2" },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          return record.unitName == value;
        }
      },
      // {
      //   title: 'LotNumber',
      //   dataIndex: 'lotNo',
      //   sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('lotNo'),
      // },
      {
        title: 'Stock Type',
        dataIndex: 'stockType',
        sorter: (a, b) => a.stockType.length - b.stockType.length || a.stockType.localeCompare(b.stockType),
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('stockType'),
        responsive: ["lg"],
        filters: [
          { text: 'Anticipated', value: 'anticipated' },
          { text: 'Dummy', value: 'dummy' },
          { text: 'SaleOrder', value: 'SaleOrder' },
          { text: 'SuperDummy', value: 'superDummy' },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          return record.stockType === value;
        },
      },
      {
        title: 'SO No',
        dataIndex: 'saleOrderNumber',
        sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('saleOrderNumber'),
        responsive: ["lg"]
      },
      {
        title: 'Customer PO',
        dataIndex: 'poNumber',
        sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('poNumber'),
      },


      {
        title: 'Brand Name',
        dataIndex: 'brandName',
        // sorter: (a, b) => a.brandName?.length - b.brandName.length || a.brandName.localeCompare(b.brandName),
        // sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('brandName'),
        filters: brandfiltersData,

        filterMultiple: true,
        onFilter: (value, record) => {
          return record.brandName == value;
        },
        render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
      },
      // {
      //   title: 'Product Name',
      //   dataIndex: 'productName',
      //   sorter: (a, b) => a.productName?.length - b.productName.length || a.productName.localeCompare(b.productName),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('productName'),
      //   render: (value) => (<span style={{float:'right'}}>{value}</span>)
      // },
      {
        title: 'Product SKU',
        dataIndex: 'variantCode',
        // sorter: (a, b) => a.variantCode?.length - b.variantCode.length || a.variantCode.localeCompare(b.variantCode),
        // sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('variantCode'),
        // filters: productfiltersData,

        // filterMultiple: true,
        // onFilter: (value, record) => {
        //   return record.variantCode == value;
        // },
        // render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
      },
      {
        title: 'Product Category',
        dataIndex: 'productCategory',
        sorter: (a, b) => a.productCategory.length - b.productCategory.length || a.productCategory.localeCompare(b.productCategory),
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('productCategory'),
        responsive: ["lg"],
        filters: [
          { text: 'Over Count', value: 'overcount' || 'Over Count ' },
          { text: 'Under Count', value: 'undercount' || 'Under Count' },
          { text: 'G1', value: 'g1' || 'G1' },
          { text: 'G2', value: 'g2' || 'G2' },
          { text: 'Cut', value: 'cut' || 'Cut' },
          { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
        ],
        filterMultiple: true,
        onFilter: (value, record) => {
          return record.productCategory == value;
        },
      },
      {
        title: 'Cartons Shortage',
        dataIndex: 'cartonsShortage',
        sorter: (a, b) => a.cartonsShortage.length - b.cartonsShortage.length || a.cartonsShortage.localeCompare(b.cartonsShortage),
        sortDirections: ['descend', 'ascend'],
        filters: [
          { text: 'YES', value: 'YES' },
          { text: 'NO', value: 'NO' },

        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.cartonsShortage === value;
        },
      },
      {
        title: 'Pouches Shortage',
        dataIndex: 'pouchesShortage',
        sorter: (a, b) => a.pouchesShortage.length - b.pouchesShortage.length || a.pouchesShortage.localeCompare(b.pouchesShortage),
        sortDirections: ['descend', 'ascend'],
        filters: [
          { text: 'YES', value: 'YES' },
          { text: 'NO', value: 'NO' },

        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.pouchesShortage === value;
        },
      },
      {
        title: 'Cartons',
        dataIndex: 'totalCartons',
        // ...getColumnSearchProps('totalCartons'),
        render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
      },
      {
        title: 'Loose Pouches',
        dataIndex: 'loosePouches',
        // ...getColumnSearchProps('loosePouches'),
        render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
      },

      // {
      //   title: 'Grade',
      //   dataIndex: 'grade',
      //   ...getColumnSearchProps('grade'),
      //   render: (value) => (<span style={{float:'right'}}>{value}</span>)
      // },
      {
        title: 'Date',
        dataIndex: 'stockDate',
        key: 'stockDate',
        render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY:h:mm A")}</span>)
      },
    ];
    const actionColumn: ColumnProps<any>[] = [
      {
        title: 'Location Update',
        dataIndex: 'location',
        render: (text, rowData: StockOutModel, index) => (

          <span>
            {
              rowData.stockLocationId == 0 &&
              <DeliveredProcedureOutlined
                style={{ color: 'red' }}
                onClick={() => displayModel(rowData)} />
            }
          </span>
        )
      },
    ]
    if (key === "3") {
      return [...columnsSkelton];
    }
    else {
      return [...columnsSkelton, ...actionColumn];
    }
  }

  const locationsAvailable: ColumnProps<StockOutModel>[] = [
    {
      title: 'Sno',
      dataIndex: 'saleOrderId',
      width: 50,
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      fixed: 'left',
      filters: [
        { text: "Kakinada", value: "Kakinada" },
        { text: "Unit 1", value: "Unit 1" },
        { text: "Unit 2", value: "Unit 2" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.unitName == value;
      }
    },
    {
      title: 'Stock Type',
      dataIndex: 'stockType',
      sorter: (a, b) => a.stockType.length - b.stockType.length || a.stockType.localeCompare(b.stockType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('stockType'),
      responsive: ["lg"],
      filters: [
        { text: 'Anticipated', value: 'anticipated' },
        { text: 'Dummy', value: 'dummy' },
        { text: 'SaleOrder', value: 'SaleOrder' },
        { text: 'SuperDummy', value: 'superDummy' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.stockType === value;
      },
    },

    {
      title: 'Cold Storage',
      dataIndex: 'coldStorage',
      sorter: (a, b) => a.coldStorage?.localeCompare(b.coldStorage),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('coldStorage'),
    },
    {
      title: 'Rack Position',
      dataIndex: 'rackPosition',
      sorter: (a, b) => a.rackPosition.toString().length - b.rackPosition.toString().length || a.rackPosition.toString().localeCompare(b.rackPosition.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rackPosition'),
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      filters: brandfiltersData,

      filterMultiple: true,
      onFilter: (value, record) => {
        return record.brandName == value;
      },
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      // filters: productfiltersData,
      // filterMultiple: true,
      // onFilter: (value, record) => {
      //   return record.variantCode == value;
      // },
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      ...getColumnSearchProps('packStyle'),
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Product Category',
      dataIndex: 'productCategory',
      sorter: (a, b) => a.productCategory.length - b.productCategory.length || a.productCategory.localeCompare(b.productCategory),
      sortDirections: ['descend', 'ascend'],
      responsive: ["lg"],
      filters: [
        { text: 'Over Count', value: 'overcount' },
        { text: 'Under Count', value: 'undercount' },
        { text: 'G1', value: 'g1' },
        { text: 'G2', value: 'g2' },
        { text: 'Cut', value: 'cut' },
        { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.productCategory == value;
      },
    },

    {
      title: 'Cartons Shortage',
      dataIndex: 'cartonsShortage',
      sorter: (a, b) => a.cartonsShortage.length - b.cartonsShortage.length || a.cartonsShortage.localeCompare(b.cartonsShortage),
      sortDirections: ['descend', 'ascend'],
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.cartonsShortage === value;
      },
    },
    {
      title: 'Pouches Shortage',
      dataIndex: 'pouchesShortage',
      sorter: (a, b) => a.pouchesShortage.length - b.pouchesShortage.length || a.pouchesShortage.localeCompare(b.pouchesShortage),
      sortDirections: ['descend', 'ascend'],
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.pouchesShortage === value;
      },
    },


    {
      title: 'Cartons',
      dataIndex: 'totalCartons',
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Loose Pouches',
      dataIndex: 'loosePouches',
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Date',
      dataIndex: 'stockDate',
      render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY:h:mm A")}</span>)
    },

  ];
  return (
    <Card
      title={<span style={{ color: 'white' }}>Stock</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
        <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
          <Input hidden />
        </Form.Item>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Form.Item name="stockDateRange" label="From Date       To Date" rules={[{
              required: false,
              message: "stockDate From Date"
            },
            ]}>
              <RangePicker />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="coldStorage"
              label="Cold Storage"
              rules={[
                {
                  required: false,
                  message: 'Cold Storage is required'
                },
              ]}
            >
              <Select
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
                onChange={(zoneId, value) => getRacksForZone(zoneId)}
              >
                <Option value={''}>Please Select</Option>
                {
                  zoneData.map((zne) => {
                    return <Option value={zne.zoneName}>{zne.zoneName}</Option>
                  })
                }
              </Select>

            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="varientId"
              label="Product SKU"
              rules={[
                {
                  required: false,
                  message: 'product is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {productData?.map(productsku => {
                  return <Option key={productsku.varientId} value={productsku.varientId}>{productsku.variantCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col style={{ marginTop: 30 }} span={5}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block onClick={() => searchData()}
              style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>
            {/* <Button type='primary' block onClick={()=>searchData()}>Get</Button> */}
            <Button type="primary" htmlType="submit" onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
      <Tabs type={'card'} tabPosition={'top'}>

        <TabPane
          // tab="Open Orders"
          key="1"
          tab={<span style={{ color: "#1890ff" }}>{'Location Pending: ' + (stockInData.filter(res => res.stockLocationId == 0).length)}</span>}
        >
          <Table rowKey={record => record.stockId}
            columns={tableColumns(localStorage.getItem("unit_id"))} dataSource={stockInData.filter(res => res.stockLocationId == 0)}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll={{ x: true }}
            size="small"
            bordered
            summary={(pageData) => {
              let totalQuantity = 0;

              pageData.forEach(({ totalCartons }) => {
                totalQuantity += Number(totalCartons);

              });

              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={10} ><Text >Total</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={3}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }
            } />
        </TabPane>

        <TabPane
          // tab="Open Orders"
          key="2"
          tab={<span style={{ color: "#1890ff" }}>{'Location Available: ' + (stockInData.filter(res => res.stockLocationId != 0).length)}</span>}
        >

          <Table rowKey={record => record.stockId}
            columns={locationsAvailable} dataSource={(stockInData.filter(res => res.stockLocationId != 0))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll={{ x: true }}
            size="small"
            bordered
            summary={(pageData) => {
              let totalQuantity = 0;

              pageData.forEach(({ totalCartons }) => {
                totalQuantity += Number(totalCartons);

              });

              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={12} ><Text >Total</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }
            } />
        </TabPane>
      </Tabs>
      <Modal
        className='dispatch-list'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        onCancel={handleCancel}
        title="Update Location"
        onOk={handleOk}
        footer={[]}
      >
        <PalletLocation stockOutModel={selectedInformation} handleCancel={handleCancel} />
      </Modal>
      {/* <div id="printme">
            <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">        
            
            </table>
          </div>           */}
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}></Row>
    </Card>

  );
}

export default StockGrid;
