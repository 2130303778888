import { ProductDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { VarientOperationsDTO } from "./varient-operations.dto";

export class variantOperationResponseModel extends GlobalResponseObject{
    data?: VarientOperationsDTO | VarientOperationsDTO[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: VarientOperationsDTO | VarientOperationsDTO[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 