export class SOVendorRequest {
    saleOrderId: number[];
    vendorId: number;

    /**
     * 
     * @param saleOrderId 
     * @param vendorId 
     */
    constructor(saleOrderId: number[], vendorId: number){
        this.saleOrderId = saleOrderId;
        this.vendorId = vendorId;
    }
}