import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { HlPercentageDto } from './hl-percentage.dto';


export class AllHlPercentageResponseModel extends GlobalResponseObject {
    data?: HlPercentageDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: HlPercentageDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

