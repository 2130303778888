export class LotCodeDropDown{
    lotCodeOpsId?:number;
    lotNumber:string;
    subLotNumber?:string;
    mainPlantId?:number;
    concatLotNum?:string;


    /**
     * 
     * @param lotCodeOpsId 
     * @param lotNumber 
     */
    constructor(lotNumber:string, subLotNumber?:string, lotCodeOpsId?:number,mainPlantId?:number,concatLotNum?:string){
        this.lotCodeOpsId = lotCodeOpsId;
        this.lotNumber = lotNumber;
        this.subLotNumber = subLotNumber;
        this.mainPlantId = mainPlantId;
        this.concatLotNum = concatLotNum ;
    }
}