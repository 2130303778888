export class FgInspectionSampleDto{
    fgInspectionSampleId:number;
    fgInspectionId:number;
    sampleNo: number;
    time:Date;
    temperature: number;
    fillingWeight: number;
    frozenWeight: number;
    deglazedWeight: number;
    netWeight: number;
    subSampleWeight: number;
    frozenCount: number;
    deglazedCount: number;
    netCount: number;
    totalPieces: number;
    glazePercent: number;
    totalOunce: number;
    cluster: string;
    generalAppearance: string;
    generalOdour: string;
    freshness: string;
    texture: string;
    dehydration: string;
    discoloration: string;
    deterioration: string;
    peeling: string;
    hangingMeat: string;
    blackSpot: string;
    broken: string;
    wrongCut: string;
    shells: string;
    foreignMatter: string;
    uniformityBig: number;
    uniformitySmall: number;
    uniformityRatio: number;
    productMetSpec: string;
    bestBefore: Date;
    remarks: string;
   
}