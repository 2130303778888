export class MaterialPreperationRequest{
    unitId:number;
    saleOrderId:number;
    saleOrderItemId:number;
    itemId:number;
    itemBarcode:number;
    preparedQuantity:number;
    consumedQuantity:number;
    stockinQuantity:number;
    poNumber:string;
    soItemName:string;
    itemName:string;
    createdUser?:string;
    soItemBarcode?:string;
    brandId?:number;
    variantId?:number;
    endCustomerId?:number;
    packStyleId?:number;
    subCategoryId?:number
}