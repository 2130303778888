import { GlobalStatus } from "@gtpl/shared-models/common-models";

export class LabellingSamplesDto{
    labellingSamplesId : number;
    labellingId : number;
    time : Date;
    shrimpsLabel : boolean;
    allergenLabel : boolean;
    certificationLogo : string;
    remarks: string;
    correctiveAction : string
    checkedBy : string;
}