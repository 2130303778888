import { EmployeeRolesEnum, EmpPrefixEnum } from "@gtpl/shared-models/common-models";
import { EmployeeEducationDetailsDto, EmployeeExperienceDetailsDto, EmployeeFamilyDetailsDto, EmployeeIdProofDetailsDto } from "@gtpl/shared-models/masters";

export class EmployeeDto {
  employeeId: number;
  employeeCode: string;
  employeeName: string;
  mobileNumber: string;
  employeeRole: EmployeeRolesEnum;
  unitId: number;
  prefix: EmpPrefixEnum;
  dateOfBirth: Date;
  gender: string;
  maritualStatus: string;
  altMobileNumber: string;
  emailID: string;
  bloodGroup: string;
  nationality: string;
  departmentId: string;
  priorityOne: string;
  priorityType: string;
  currentAddress: string;
  permanentAddress: string;
  qualification: string;
  yearsOfExperience: Number;
  companyEmail: string;
  employeeMode: string;
  employeeStatus: string;
  contractorName: string;
  shiftGroup: string;
  dateOfJoin: Date;
  dateOfleave: Date;
  workstationNumber: string;
  healthCardNumber: string;
  uanNumber: string;
  salary: string;
  bankAccountNumber: string;
  ifscCode: string;
  designation: string;
  dateOfEntry: Date;
  employeeType: string;
  emergencyContactNo: string;
  emergencyContactName: string;
  isProbPeriodCompleted: string;
  transportation: string;
  accommodation: string;
  isAuditCompleted: string;
  skillSet: string;
  pickUpPoint: string;
  routeNumber: string;
  panNumber: string;
  esiNumber: string;
  reportingManager: string;
  bankName: string;
  employeeCategory: string;
  wageType: string;
  aadhaarNumber: string;
  state: string;
  pinCode: string;
  permanentState: string;
  permanentPinCode: string;
  isActive: boolean;
  createdAt: Date;
  createdUser: string;
  updatedAt: Date;
  updatedUser: string;
  versionFlag: number;
  familyDetails?: EmployeeFamilyDetailsDto[];
  educationDetails?: EmployeeEducationDetailsDto[];
  experienceDetails?: EmployeeExperienceDetailsDto[];
  idProofDetails?: EmployeeIdProofDetailsDto[];
  employeeLastName?: string;
  image?: string;

}

// export const EmployeeDtoDefault : EmployeeDto = {
//     employeeId: 0,
//     employeeCode: "",
//     employeeName: "",
//     mobileNumber: "",
//     // emailId:"",
//     employeeRole: EmployeeRolesEnum.ADMIN,
//     isActive: true,
//     createdAt : new Date() ,
//     createdUser : '0',
//     updatedAt : new Date() ,
//     updatedUser : '0',
//     versionFlag : 1,
//     unitId : 0
// };

