import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import './transfer-print.css';
import { LogIdRequest, LogInfoData } from '@gtpl/shared-models/production-management'
import { ProdlogService } from '@gtpl/shared-services/production'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FactoriesInput } from '@gtpl/shared-models/sale-management'
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { TransferIdRequest, TransferMaterialInfo } from '@gtpl/shared-models/procurement-management';
import { TransferMaterialService } from '@gtpl/shared-services/procurement';
/* eslint-disable-next-line */
export interface TransferPrintProps {
  transferId: number;
  printOrder: () => void;
  printType: string;
}


export function TransferPrint(
  props: TransferPrintProps
) {

  useEffect(() => {
    getData(props.transferId);
  }, [])

  const [transferInfo, setTransferInfo] = useState<TransferMaterialInfo>(undefined);
  const transferMaterialService = new TransferMaterialService();

  const getData = (transferId) => {
    const reqObj = new LogIdRequest(transferId);
    const req = new TransferIdRequest();
    req.packingStockTransferId = transferId;
    req.createdUser = localStorage.getItem('createdUser');
    transferMaterialService.TransferMaterialDetailInfo(req).then(res => {
      if (res.status) {
        setTransferInfo(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };



  if (transferInfo) {
    return (
        <div id='printme' className='transferPrint'>
          <h1 style={{ textAlign: 'center', fontSize: '28px', marginTop: '12px' }}>SANDHYA AQUA</h1>
          <h1 style={{ textAlign: 'center', fontSize: '20px', marginTop: '4px' }}>
            {props.printType}
          </h1>
          <br />
          <table className={'ta-b no-bt-bb'} style={{ width: '100%' }}>
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'} colSpan={2}>
                <h4>From Plant: </h4>
                <b>{transferInfo.fromPlantName}</b>
              </td>
              <td className={'no-bt-bb'} style={{ padding: '15px' }}>
                {' '}
              </td>
              <td className={'ta-b'} colSpan={2}>
                <h4>To Plant: </h4>
                <b>{transferInfo.toPlantName}</b>
              </td>
            </tr>
            <br />
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC NUMBER</b>
              </td>
              <td className={'ta-b'}>{transferInfo.dcNumber}</td>
              <td className={'no-bt-bb'} style={{ padding: '15px' }}>
                {' '}
              </td>
              <td className={'ta-b'}>
                <b>MRN NUMBER</b>
              </td>
              <td className={'ta-b'}>{transferInfo.mrnNumber}</td>
            </tr>
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC DATE</b>
              </td>
              <td className={'ta-b'}>{moment(transferInfo.transferredDate).format("YYYY/MM/DD LTS")}</td>
              <td className={'no-bt-bb'} style={{ padding: '30px' }}>
                {' '}
              </td>
              <td className={'ta-b'}>
                <b>RECEIVED DATE</b>
              </td>
              <td className={'ta-b'}>{(transferInfo.receivedDate)?moment(transferInfo.receivedDate).format("YYYY/MM/DD LTS"):transferInfo.status}</td>
            </tr>
           
          </table>
          <br />
          <br />

          <table className={'ta-b'} style={{ width: '100%' }}>
            <tr className={'ta-b'}>
              <th className={'ta-b'}>S.No</th>
              <th className={'ta-b'}>ITEM NAME</th>
              <th className={'ta-b'}>QUANTITY</th>
              <th className={'ta-b'}>UNIT PRICE</th>
            </tr>
            {
              transferInfo.items?.map((item, index) => {
                return <tr>
                  <td className={'ta-b'} style={{ textAlign: 'center' }}>{index+1}</td>
                  <td className={'ta-b'}>{item.item}</td>
                  <td className={'ta-b'} style={{ textAlign: 'right' }}>{item.transferredQty}</td>
                  <td className={'ta-b'} style={{ textAlign: 'right' }}>{item.unitPrice}</td>
                </tr>
              })
            }

          </table>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>{transferInfo.createdUser}</p>
              <h4>ISSUED BY</h4>
            </div>
            <div style={{ padding: '10px' }}>
              <p>{transferInfo.receivedUser}</p>
              <h4>RECEIVED BY</h4>
            </div>
          </div>
          <Button onClick={props.printOrder} className='noprint' style={{ display: 'block', margin: 'auto', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}>Print</span></Button>
        </div>
    );
  }
  else {
    return (<Empty />);
  }
}

export default TransferPrint;