import React, { useEffect, useState } from 'react';
import {Button, Card, Col, Divider, Form, Input, Modal, Row, Select} from 'antd';
import {BarcodeOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {AssetLocationMappingService} from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import './pages-asset-management-asset-management-components-asset-classification-form.css';
import Scanner from './Scanner';
import { AssetLocationMappingDto } from 'libs/shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import {AssetLocationService} from 'libs/shared-services/asset-management/src/lib/asset-location';
import {AssetsLocationDto} from 'libs/shared-models/asset-management/src/lib/asset-location/asset-location.dto';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import { LocationById } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-id.dto';
import {AssetsDetailedView} from '../../../aasets-grid/src/lib/Detailed-view';
import {AssetAssignmentService} from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from '@gtpl/shared-models/asset-management';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const {Option} = Select;

/* eslint-disable-next-line */
export interface AssetLocationMappingFormProps {}

export function AssetLocationMappingForm(
  props: AssetLocationMappingFormProps
) {

  const  [form] = Form.useForm();
  const [modal, setModal] = useState('');
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [assetsData, setAssetsData] = useState<any>([])
  const [locationData,setLocationData] = useState<AssetsLocationDto[]>([]);
  const [assetView,setAssetView] = useState<boolean>(false)
  let [previewdata,setPreviewData] = useState<number>(0);
  const [finishModal,setFinishModal] = useState<boolean>(false);
  const [plantName, setPlantName] = useState<any>([]);
  const service = new AssetLocationMappingService();
  const assetService = new AssetService();
  const locationService = new AssetLocationService();
  const assignmentService = new AssetAssignmentService();
  const plantsService = new UnitcodeService();
  const role = JSON.parse(localStorage.getItem('role'));
  const [itemCategory,setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();


  useEffect(()=>{
    let plantId;
    if (Number(localStorage.getItem('unit_id')) != 5) {
     plantId= Number(localStorage.getItem('unit_id'))
    }  
    getAssetCode();
    getLocationsData(plantId);
    getAllPlants();
    getItemCategory();
  },[])

  const getAssetCode = () => {
    const req = new AssetByStatus()
    if(form.getFieldValue('plantId') !== undefined){
      req.plantId = form.getFieldValue('plantId')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'));
    }
    if(form.getFieldValue('assetCategory') != undefined) {
      req.assetCategory = form.getFieldValue('assetCategory');
    }
    if(form.getFieldValue('assetType') != undefined) {
      req.assetType = form.getFieldValue('assetType');
    }
    service.getassetCodeDropDownforLocationMapping(req).then((res) => {
      if(res.status) {
        setAssetsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocationsData =(plantId) => {
    const req = new LocationByPlant()
    req.plant = plantId;
    locationService.getAllAssets(req).then(res => {
      if(res.status) {
        console.log(res.data,'--------------locationa--------------')
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getItemCategory = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('plantId') !== undefined){
      req.plantId = form.getFieldValue('plantId')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'));
    }
    service.getassetCategoryDropDownforLocationMapping(req).then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  // const onFinish = (values) => {
  //   service.create(values).then( res => {
  //       if(res.status) {
  //         console.log(res.data,'---------response')
  //         console.log(res.data[0].asset_type,'---------assettype')
  //         if(res.data[0].asset_type === 'immovable') {
  //           const req = {assetsCode:null,employeeId:null};
  //           const owner = res.data[1].owner_of_asset;
  //           const assetCode = res.data[1].assets_code;
  //           req.assetsCode = assetCode;
  //           req.employeeId = owner;
  //           console.log(req,'-req')
  //           const request = new AssetAssignmentDto(null,null,req.assetsCode,req.employeeId,null,null,true,null)
  //           console.log(request,'---------request')
  //           assignmentService.createCheckIn(request).then(res => {
  //             if(res.status){
  //               console.log('checkin')
  //             }
  //           })
  //         }
  //         AlertMessages.getSuccessMessage(res.internalMessage)
  //         setFinishModal(true)
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage)
  //       }
  //     }
  //   )
  // }

  const onFinish = (values) => {
    service.create(values).then( res => {
        if(res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
          setFinishModal(true)
          setAssetView(false);
          form.resetFields()
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    )
  }

  const onReset = () => {
    setAssetView(false);
    form.resetFields();
  }
  // const getByCode = (assetId) => {
  //   const req = new AssetByCode(assetId)
  //   assetService.getByAssetCode(req).then(res => {
  //     if (res.status) {
  //       setPreviewData(res.data);
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message)
  //   })
  // }

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetId: value
    })   
      // getByCode(value)
 
  }

  const handleLocationIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      locationId: value
    })
  }

  const onCodeChange =() => {
    setAssetView(true);
    const assetId = form.getFieldValue('assetId')
    const request = new AssetByCode(assetId)
    assetService.getByAssetCode(request).then(res => {
      if(res.status) {
        form.setFieldsValue({
          assetName: res.data.assetsName,
        });
        setPreviewData(++previewdata)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const onlocationIdChange = () => {
    const locationId = form.getFieldValue('locationId')
    const req = new LocationById(locationId)
    locationService.getById(req).then(res => {
      if(res.status) {
        form.setFieldsValue({
          location: res.data.locationName
        })
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const onPlantChange = () => {
    getAssetCode()
    getItemCategory()
    
  }

  return (
    <Card title={<span style={{ color: 'white' }}> Asset Location Mapping</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-location-mapping-grid'><span style={{ color: 'white' }} >
          <Button className='panel_button' > View </Button> 
          </span></Link>}>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Row gutter={[24,24]}>
        {/* <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} offset = {0} style={{marginTop:'3%'}}>
            <span><BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} style={{ fontSize: '30px', marginTop: '35px' }} /><b>Enter / Scan Asset Code</b></span>
            <span><b>Enter/<Button type='link'  onClick={(e) => { setShowQrScan(true); setModal('assetId') }}> Scan Asset Code</Button></b></span>
          </Col> */}

          <Col span={3}>
              <Form.Item label='Unit' name='plantId' initialValue={Number(localStorage.getItem('unit_id'))}>
                <Select
                  onChange={onPlantChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                  showSearch
                  allowClear >
                  {plantName.map((plant) => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          
        <Col span={3}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
               defaultValue={'All'}
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear
               onChange={getAssetCode}>
              <Option key={'all'} value={''}>All</Option>
            {itemCategory.map((items) => {
              return <Option key={items.item_sub_category_id} value={items.item_sub_category_id}>{items.item_sub_category}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType' >
          <Select
                placeholder='assetType'
                showSearch
                defaultValue={'All'}
                onChange={getAssetCode}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
          </Form.Item>
          </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
        <Form.Item 
          name="assetId" 
          label='Asset Code'
          rules={[
            {
              required: true,
              message: "Asset Code is required"
            },
          ]}
        >
          {/* <Input  placeholder="asset code"/> */}
          <Select 
            placeholder='Enter or Scan Asset Code'
            suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }}/>}
            showSearch
            optionFilterProp="children"
            onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assets_code} value={assets.assets_code}>{`${assets.item_name} -- ${assets.assets_code}`}</Option>
            })

            }
          </Select>
        </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item
              name="locationId"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Location is required"
                },
              ]}
            >
              <Select
                placeholder='Enter or scan location code'
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('locationId') }}/>}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
              {locationData.map((location) => {
              return <Option key={location.locationId} value={location.locationId}>{location.locationName}</Option>
            })

            }
              </Select>
            </Form.Item>
            </Col>
        
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{textAlign:'right',marginTop:'2%'}}>
          <Button htmlType='submit' type='primary'>
            Submit
          </Button>
          { [] && <Button onClick={onReset} style={{margin: '0 14px'}}>Reset</Button>}
          </Col>
        </Row>
        </Form>
        {
          assetView ? (
            <AssetsDetailedView key={form.getFieldValue('assetId')}  assetsCode={form.getFieldValue('assetId')}/>
          ) : (
            <>
            </>
          )
        }

        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
             modal == 'assetId' ? <Scanner handleScan={handleAssetIdScan} /> : modal == 'locationId' ? <Scanner handleScan={handleLocationIdScan}/> : null
             //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal>

        <Modal
          className='qr-container'
          key={'modall' + Date.now()}
          width={'50%'}
          style={{ top: 50, alignContent: 'center' }}
          visible={finishModal}
          onOk={() => {setFinishModal(false)}}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          <p><b>Location Mapped Successfully</b></p>

        </Modal>
    </Card>
  );
}

export default AssetLocationMappingForm;
