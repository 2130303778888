import { RmAuditRequest } from "@gtpl/shared-models/raw-material-procurement";
import { RMGrnService } from "@gtpl/shared-services/raw-material-procurement";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Col, Descriptions, Form, Input, InputNumber, Row } from "antd";
import React from "react";
export interface GrnIndentProps{
    closeModal: () => void
    itemData: any;

}
const GrnIndent = (props:GrnIndentProps) =>{
    const [form] = Form.useForm();
console.log(props.itemData)

    const service = new RMGrnService();

    const onFinish = (values) => {
        const req = new RmAuditRequest(props.itemData.grnItemId,form.getFieldValue('actualCount'),form.getFieldValue('actualQuantity'))
        // const actualCount=form.getFieldValue('actualCount')
        // const actualQuantity=form.getFieldValue('actualQuantity')
        service.updateGrnAuditQty(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage("Updated Successfully");
                props.closeModal();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }
    return <>
    <Descriptions column={3}>
        <Descriptions.Item label="Farmer">
            {props.itemData.farmerName}
        </Descriptions.Item>
        <Descriptions.Item label="Middle Man">
            {props.itemData.BrokerName}
        </Descriptions.Item>
        <Descriptions.Item label="Farm">
            {props.itemData.farmName}
        </Descriptions.Item>
        <Descriptions.Item label="Contact Number">
            {props.itemData.mobileNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Expected Count">
            {props.itemData.expectedCount}
        </Descriptions.Item>
        <Descriptions.Item label="Expected Quantity">
            {props.itemData.expectedQty}
        </Descriptions.Item>
        <Descriptions.Item label="GRN Count">
            {props.itemData.count}
        </Descriptions.Item>
        <Descriptions.Item label="GRN Quantity">
            {props.itemData.quantity}
        </Descriptions.Item>
    </Descriptions>
    <Form
        layout="vertical"
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
    >
        <Row gutter={24}>
            <Form.Item name={'grnItemId'} style={{ display: "none" }} 
            initialValue={props.itemData.grnItemId}
            >
                <Input hidden />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item
                    label="Audit Count"
                    name="actualCount"
                    rules={[{ required: true, message: 'Please input your Count!' }]}
                    initialValue={props.itemData.actualCount}
                >
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item
                    label="Audit Quantity(in Kgs)"
                    name="actualQuantity"
                    rules={[{ required: true, message: 'Please input your Quantity(in Kgs)!' }]}
                    initialValue={props.itemData.actualQuantity}
                >
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ paddingTop: '30px' }}>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>
</>;
}
export default  GrnIndent
