import axios from 'axios';
import {WorkstationCategoryDTO, WorkstationCategoryResponseModel, AllWorkstationCategoryResponseModel} from '@gtpl/shared-models/production-management';
import connection from './connection';

export class WorkstationCategoryService {
URL = connection.DEPLOY_URL + '/workstation-category';

  async createWorkstationCategory(workstationCategory: WorkstationCategoryDTO): Promise<WorkstationCategoryResponseModel> {
    console.log(workstationCategory);
      return await axios.post(this.URL + '/createWorkstationCategory',workstationCategory)
          .then(res => {
              return res.data
          })
  }
  async  updateWorkstationCategory(workstationCategory: WorkstationCategoryDTO): Promise<WorkstationCategoryResponseModel> {
    return await axios.post(this.URL + '/updateWorkstationCategory', workstationCategory)
           .then(res => {
               return res.data
           })
}
  async getAllWorkstationCategories(): Promise<AllWorkstationCategoryResponseModel> {
    return await axios.post(this.URL + '/getAllWorkstationCategories')
        .then(res => {
            return res.data
        })     
}
    async  activateorDeactivateWorkstationCategory(workstationCategory: WorkstationCategoryDTO): Promise<AllWorkstationCategoryResponseModel> {
      console.log(workstationCategory);
      return await axios.post(this.URL + '/activateorDeactivateWorkstationCategory', workstationCategory)
              .then(res => {
                  return res.data
              })
 }

 async getAllActiveWorkstationCategories(): Promise<AllWorkstationCategoryResponseModel> {
  return await axios.post(this.URL + '/getAllActiveWorkstationCategories')
      .then(res => {
          return res.data
      })     
}
 

} 