import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AllProdInvResponseModel, LotCodeDropDownResponse, LotNoReq, LotNumberInfoResponse, ProdInvResponseModel } from '@gtpl/shared-models/production-management';

export class ProdInvService {
    URL = connection.DEPLOY_URL + '/production-inventory';

    async createProdInv(values): Promise<ProdInvResponseModel> {
        return await axios.post(this.URL + '/createProdInv', values)
            .then(res => {
                return res.data
            })
    }

    async updateProdInv(values): Promise<ProdInvResponseModel> {
        return await axios.post(this.URL + '/updateProdInv', values)
            .then(res => {
                return res.data
            })
    }


    async getAllProdInvs(): Promise<AllProdInvResponseModel> {
        return await axios.post(this.URL + '/getAllProdInvs',
        )
            .then(res => {
                return res.data
            })
    }


    async getLotNumbersForBeheading(): Promise<LotCodeDropDownResponse> {
        return await axios.post(this.URL + '/getLotNumbersForBeheading',)
            .then(res => {
                return res.data
            })
    }

    async getInformationForLotNumber(req:LotNoReq): Promise<LotNumberInfoResponse> {
        return await axios.post(this.URL + '/getInformationForLotNumber',req)
            .then(res => {
                return res.data
            })
    }
}