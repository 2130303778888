import { DeptDataModel } from "./dept-data.model";
import { EmployeeDataModel } from "./employee-data.model";


export class ShiftWiseDataModel {
    shift: string;
    deptDetailes: DeptDataModel[];


    constructor(shift: string, deptDetailes: DeptDataModel[]) {
        this.shift = shift;
        this.deptDetailes = deptDetailes;

    }
}