import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { FgStockTransferDto } from ".";

export class AllFgStockTransferResponseModel extends GlobalResponseObject{
    data:FgStockTransferDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: FgStockTransferDto) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}