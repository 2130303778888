import { LotCodeSoakingChild } from "./lotCodeSoaking-Child";

export class peelDeveinChild{
    name: string;
    children:LotCodeSoakingChild;
    constructor(name: string, children:LotCodeSoakingChild){

        this.name = name;
        this.children = children;
    }
}