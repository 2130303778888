import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {PODetailViewInfoDTO,} from './po-detail-view-dto';
import {POViewInfoDTO} from './po-detail-view-dto';
import { PoDetailsDto } from './po-details.dto';
import {PoRequest} from './po-request'
import {PoDto} from './po.dto'

export class PoDetailViewResponseModel extends GlobalResponseObject {
    data?: PoDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PoDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}