export class SaleOrderFewDetailsDto{
    saleOrderId: number;
    saleOrderNumber: string;
    poNumber: string;
    category: string;
    dueDate: Date;
    status: string;
    
    /**
     * 
     * @param saleOrderId
     * @param saleOrderNumber
     * @param poNumber
     * @param category
     * @param dueDate
     * @param Status
     */

    constructor(saleOrderId: number, saleOrderNumber: string, poNumber: string, category: string, dueDate: Date, status: string){
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.poNumber = poNumber;
        this.category = category;
        this.dueDate = dueDate;
        this.status = status;
    }
}