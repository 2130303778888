import { AnalyticsUnitReq, StockInResponseData } from "@gtpl/shared-models/analytics-data";
import { ExpectedStockInOutResponse, StockInRqstDto } from "@gtpl/shared-models/warehouse-management";
import { Body } from "@nestjs/common";
import axios from "axios";
import { CommonResponse } from "../../../../shared-models/sale-management/src";
import connection from "./connection";
import { PlantIdRequest } from '@gtpl/shared-models/warehouse-management';

export class WarehouseDashboardService {
    URL = connection.DEPLOY_URL + '/warehouse';

    async getStockInfo(req: StockInRqstDto): Promise<StockInResponseData> {
        return await axios.post(this.URL + '/getStockInfo', req)
            .then(res => {
                return res.data
            })
    }
    async getrecentFiveStockIns(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getrecentFiveStockIns',req)
            .then(res => {
                return res.data
            })
    }
    async getrecentFiveStockOuts(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getrecentFiveStockOuts',req)
            .then(res => {
                return res.data
            })
    }
    async getTodaysStockOutSOs(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTodaysStockOutSOs',req)
            .then(res => {
                return res.data
            })
    }
    async getTodayStockTransfers(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTodayStockTransfers',req)
            .then(res => {
                return res.data
            })
    }
    async getCartonsInWayRacks(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getCartonsInWayRacks',req)
            .then(res => {
                return res.data
            })
    }
    async getWarehouseCapacity(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getWarehouseCapacity',req)
            .then(res => {
                return res.data
            })
    }
    async getLongRunningDummyStock(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLongRunningDummyStock',req)
            .then(res => {
                return res.data
            })
    }
    async getRepackingEfficiency(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRepackingEfficiency',req)
            .then(res => {
                return res.data
            })
    }
    async getExpectedStockInsAndStockOuts(req:AnalyticsUnitReq): Promise<ExpectedStockInOutResponse> {
        return await axios.post(this.URL + '/getExpectedStockInsAndStockOuts',req)
            .then(res => {
                return res.data
            })
    }
    async getPOsProductionAbove70Perc(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPOsProductionAbove70Perc',req)
            .then(res => {
                return res.data
            })
    }

    async getTransferdSOsStock(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransferdSOsStock',req)
            .then(res => {
                return res.data
            })
    }
    async getWarehouseLifeCycle(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getWarehouseLifeCycle',req)
            .then(res => {
                return res.data
            })
    }
    async getTop10DummyProducts(req:AnalyticsUnitReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTop10DummyProducts',req)
            .then(res => {
                return res.data
            })
    }
    
}