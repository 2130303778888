import React, { useEffect, useState } from 'react';
import { BackTop, Button, Card, Col, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteFilled, ClearOutlined } from '@ant-design/icons';
import { ItemCategoryService, ItemsService, SizesService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemCategoriesDto, ItemsDropDownDto, ItemsReqForCategoryId } from '@gtpl/shared-models/masters';
import { GrnItemReturnService, GrnService } from '@gtpl/shared-services/procurement';
import { GrnItemReceivedQtyRequest, GrnItemsForReturn, GrnNumberDto, GrnReturnItemSaveDto, GrnReturnItemsDto } from '@gtpl/shared-models/procurement-management';
import { useIntl } from "react-intl";
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import TextArea from 'antd/lib/input/TextArea';
import './grn-return-form.css';
export interface IGRNItemsFormProps {
    form: FormInstance<any>,
    grnReturnDetailsForm: FormInstance<any>
    gridData: any[]
    addItems: (values) => void;
}
const { Option } = Select;
const GrnReturnItemsFrom = (props: IGRNItemsFormProps) => {
    const [editedItemInfo, setEditedItemInfo] = useState<GrnItemsForReturn>(undefined);
    const [shouldUpdate, setShouldUpdate] = useState<number>(0);




    const columnsSkelton: ColumnProps<GrnItemsForReturn>[] = [
        {
            title: "Item Category",
            dataIndex: 'itemCategory',
            fixed: 'left',
            render: (value, record) => {
                return record.itemCategoryName
            }
        },
        {
            title: "Item Sub Category",
            dataIndex: 'itemSubCategory',
            fixed: 'left',
            render: (value, record) => {
                return record.itemSubCategoryName
            }
        },
        {
            title: "Item Name",
            dataIndex: 'itemName',
            fixed: 'left',
            render: (value, record) => {
                return record.itemName;
            }
        },
        // {
        //     title: "Size",
        //     dataIndex: 'sizeName',
        //     fixed: 'left',
        //     render: (value, record) => {
        //         return record.sizeName;
        //     }
        // },
        {
            title: "Available Qty",
            dataIndex: 'availQty'
        },
        {
            title: "Received Qty",
            dataIndex: 'grnReceivedQty'
        },
        {
            title: "Returned Qty",
            dataIndex: 'grnReturnQty',
            render: (value, record, index) => {
                return record.grnReturnQty;
            }

        },
        {
            title: "Unit Price",
            dataIndex: 'unitPrice',
            render: (value, record) => {
                return record.unitPrice
            }
        },
        {
            title: "Discount(%)",
            dataIndex: 'discountPercentage',
            render: (value, record) => {
                return record.discountPercentage
            }
        },
        {
            title: "Discount",
            dataIndex: 'discountAmount',
            render: (value, record) => {
                return record.discountAmount;
            }
        },
        {
            title: "Tax(%)",
            dataIndex: 'taxPercentage',
            render: (value, record) => {
                return record.taxPercentage;
            }
        },
        {
            title: "Tax",
            dataIndex: 'taxAmount',
            render: (value, record) => {
                return record.taxAmount;
            }
        },
        {
            title: "Total Amount",
            dataIndex: 'grnTotalReturnPrice',
            render: (value, record) => {
                return record.grnTotalReturnPrice
            }
        },
        {
            title: "Remarks",
            dataIndex: 'remarks',
        },
        {
            title: `Action`,
            dataIndex: 'action',
            fixed: 'right',
            render: (text, rowData, index) => (
                <span>
                    <EditOutlined className={'editSamplTypeIcon'} type="edit"
                        onClick={() => {
                            setEditForm(rowData, index);
                        }}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                    />
                    <Divider type="vertical" />
                    <Popconfirm onConfirm={e => { deleteRecord(rowData, index); }}
                        title='Are you sure to Delete ?'
                    >
                        <DeleteFilled style={{ color: 'red' }} />
                    </Popconfirm>
                </span >
            )
        }
    ];

    const setEditForm = (record: GrnItemsForReturn, index) => {
        setEditedItemInfo(record);
        props.form.setFieldsValue({
            index: index,
            grnReturnQty: record.grnReturnQty,
            remarks: record.remarks
        })
    }

    const deleteRecord = (rec, index) => {
        props.gridData.splice(index, 1);
        props.addItems(props.gridData);
    }

    const validateAmount = (qty) => {
        const itemCost = qty * editedItemInfo.unitPrice; // price
        const discountAmount = itemCost * (editedItemInfo.discountPercentage / 100); //discount
        editedItemInfo.discountAmount = discountAmount;
        const taxAmount = (itemCost - discountAmount) * (editedItemInfo.taxPercentage / 100); //discount
        editedItemInfo.taxAmount = taxAmount;
        const totalAmount = itemCost - discountAmount + taxAmount;
        editedItemInfo.grnReturnQty = qty;
        editedItemInfo.grnTotalReturnPrice = totalAmount;
        setEditedItemInfo(editedItemInfo);
        setShouldUpdate(shouldUpdate + 1);
    }

    const onAddProduct = () => {
        props.form.validateFields().then((formFields) => {
            editedItemInfo.grnReturnQty = formFields.grnReturnQty;
            editedItemInfo.remarks = formFields.remarks;
            props.gridData.splice(formFields.index, 1, editedItemInfo);
            props.addItems(props.gridData);
            setEditedItemInfo(undefined);
            clearItems();
        })
    }

    const clearItems = () => {
        props.form.resetFields();
    };

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>GRN Items</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <BackTop />
                <Row gutter={24} style={{ display: (editedItemInfo) ? 'block' : 'none' }}>
                    <Col>
                        <Descriptions column={6}>
                            <Descriptions.Item label="Item Category">
                                {editedItemInfo?.itemCategoryName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Item Sub Category">
                                {editedItemInfo?.itemSubCategoryName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Item">
                                {editedItemInfo?.itemName}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label="Size">
                                {editedItemInfo?.sizeName}
                            </Descriptions.Item> */}
                            <Descriptions.Item label="GRN Qty">
                                &nbsp;{editedItemInfo?.grnReceivedQty}
                            </Descriptions.Item>
                            <Descriptions.Item label="Stock Qty">
                                &nbsp;{editedItemInfo?.availQty}
                            </Descriptions.Item>
                            <Descriptions.Item label="Unit Price">
                                &nbsp;{editedItemInfo?.unitPrice}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={24} style={{ display: (editedItemInfo) ? 'block' : 'none' }}>
                    <Col>
                        <Descriptions column={6}>

                            <Descriptions.Item label="Discount (%)">
                                &nbsp;{editedItemInfo?.discountPercentage}
                            </Descriptions.Item>
                            <Descriptions.Item label="Discount">
                                &nbsp;{editedItemInfo?.discountAmount}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tax (%)">
                                &nbsp;{editedItemInfo?.taxPercentage}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tax Amount">
                                &nbsp;{editedItemInfo?.taxAmount}
                            </Descriptions.Item>
                            <Descriptions.Item label="Total Amount">
                                &nbsp;{editedItemInfo?.grnTotalReturnPrice}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Form layout="vertical" form={props.form} name="control-hooks" style={{ display: (editedItemInfo) ? 'block' : 'none' }}>
                    <Row gutter={24}>
                        <Form.Item name="index" >
                            <Input hidden />
                        </Form.Item>
                        <Col span={4}>
                            <Form.Item
                                label="Returning Qty"
                                name={'grnReturnQty'}
                                rules={[{ required: true, message: 'Missing Returning Qty' }, {
                                    validator: (_, value) => {
                                        const maxQty = (editedItemInfo?.availQty < editedItemInfo?.grnReceivedQty) ? editedItemInfo?.availQty : editedItemInfo?.grnReceivedQty;

                                        return value <= maxQty ? (value == 0) ? Promise.reject(new Error('Returning Qty should be > 0')) : Promise.resolve() : Promise.reject(new Error('Quantity should be <= ' + maxQty))
                                    }
                                }]}
                            >
                                <InputNumber min={0} style={{ width: '100%' }} onChange={validateAmount} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="remarks" label="Remarks" rules={[{ required: true, message: 'Missing Remarks' }]}>
                                <TextArea showCount />
                            </Form.Item>
                        </Col>
                        <Col>
                            <br />
                            <br />
                            <span>
                                <Tooltip placement="top" title='edit'>
                                    <EditOutlined className={'editSamplTypeIcon'} type="edit"
                                        onClick={() => {
                                            onAddProduct();
                                        }}
                                        style={{ color: '#1890ff', fontSize: '20px' }}
                                    />
                                </Tooltip>

                                <Divider type="vertical" />
                                <Tooltip placement="top" title='clear'>
                                    <ClearOutlined onClick={() => {
                                        clearItems();
                                    }}
                                        style={{ color: 'red', fontSize: '20px' }} />
                                </Tooltip>
                            </span>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={24} key={Date.now()}>
                    <Table
                        rowKey={record => record.grnItemId}
                        columns={columnsSkelton}
                        dataSource={props.gridData}
                        pagination={false}
                        bordered
                        style={{ width: '100%' }}
                        scroll={{ x: true }}
                    />
                </Row>
            </Card>

        </>
    )
}

export default GrnReturnItemsFrom;
