import { LineSeries } from "./line-series";

export class HighColumn{
    title:string;
    categories:string[];
    text:string;
    seriesDetails:LineSeries[];
    constructor(title:string,categories:string[],text:string,seriesDetails:LineSeries[]){

        this.title = title,
        this.categories = categories,
        this.text = text,
        this.seriesDetails = seriesDetails
  
    }
}