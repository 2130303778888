import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GradeLotInvModel } from "./grade-lot-inv.model";

export class GradeLotInvResponse extends GlobalResponseObject{
    data: GradeLotInvModel[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data: GradeLotInvModel[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}