import { EnvVarEnum } from '@gtpl/shared-models/common-models';
import {appSettings} from '../../../../../apps/services/config';


const envType = 'DEV';
const url=appSettings.production_url;

export const connection = {
    'DEPLOY_URL': url,
    
}

export default connection;