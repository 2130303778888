import React, { useState, useEffect, useRef } from 'react';
import {  Table,  DatePicker, Card, Form, Select, Input, Button, Tag, Row, Col,  Typography, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  SearchOutlined } from '@ant-design/icons';
import { Excel } from 'antd-table-saveas-excel';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';
import moment from 'moment';
import { PoDropDownDto,  PoReportDto, PoReportRequest } from '@gtpl/shared-models/procurement-management';
import { POTypeEnum, StatusEnum,  POLifeCycleStatusEnum } from '@gtpl/shared-models/common-models';
import {  SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import {  PlantsDropDown, VendorDropDownDto} from '@gtpl/shared-models/masters';
import './purchase-order-report.css';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

/* eslint-disable-next-line */
export interface PurchaseOrderReportProps { }

export function PurchaseOrderReport(props: PurchaseOrderReportProps) {
  const { Text } = Typography;
  const [disable, setDisable] = useState<boolean>(false)
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [NoData, setSetNoData] = useState(false);
  const [triggerReport, setTriggerReport] = useState(false);
  const [isShowtable, setIsShowtable] = useState(false);
  const [selectedunit, setUnit] = useState<number>(0)
  const [totalPoCount, settotalPoCount] = useState<number>(0)
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [plantsData, setPlantsData] = useState<PlantsDropDown[]>([]);
  const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([]);
  const [purchaseOrderStatus, setPurchaseOrderStatus] = useState(null);
  const [poNumbers, setPoNumbers] = useState<PoDropDownDto[]>([]);
  const [poReportData, setPoReportData] = useState<any[]>([])
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const [poNoDropDown, setPoNoDropDown] = useState<any[]>([]);
  const [vendorDropDown, setVendorDropDown] = useState<any[]>([]);
  const [itemDropDown, setItemDropDown] = useState<any[]>([]);
  const [poDropDown, setPoDropDown] = useState<any[]>([]);
  const [poStatusDropDown , setPoStatusDropDown] = useState<any[]>([]);
  const [itemSubCatDrop, setItemSubCatDrop] = useState<any[]>([]);

  const poService = new PurchaseOrderService;
  const service = new SaleOrderService;
  const unitcodeService = new UnitcodeService;


  useEffect(() => {
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
    getPoDropForPoReport();
    getAllSaleOrderItems();
    getAllActivePlants();
    getSoDropForPoReport();
    getVendorDropForPoReport();
    getItemDropForPoReport();
    getPoLifeCycleStatus();
    getItemSubDropForPoReport();
    form.setFieldsValue({
      fromDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1, 'months')), moment(moment().format("YYYY-MM-DD"))]
    })
    setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1, 'months').add(1, 'days')))
    setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")).add(1, 'days'))
  }, [])

  const getPurchaseOrdersForReport = (req?) => {
    setDisable(true)
    if (!req) {
      const req = new PoReportRequest();
      req.fromDate = undefined
      req.toDate = undefined
      req.saleOrderId = undefined
      req.poStatus = undefined
      req.purchaseOrderId = undefined
      req.vendorId = undefined
      req.poType = undefined
      req.unitId = Number(localStorage.getItem('unit_id'));
      req.createdDate = undefined
      req.itemNameId = undefined
      req.itemSubCategoryId = undefined
      req.lifeCycleStatus = undefined
    }
    console.log(Number(localStorage.getItem('unit_id')))
    poService.getPoDetailReport(req).then(res => {
      setDisable(false)
      if (res.status) {
        console.log(res.data);
        setIsShowtable(true);
        setPoReportData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
        var tempResult = {}

        for (let { poNumber } of res.data)
          tempResult[poNumber] = {
            poNumber,
            count: tempResult[poNumber] ? tempResult[poNumber].count + 1 : 1
          }

        let result = Object.values(tempResult)
        settotalPoCount(result.length)

        console.log(result.length)
      } else {
        if (res.intlCode) {
          setSetNoData(true)
          setIsShowtable(false);
          setPoReportData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setSetNoData(true)
          setIsShowtable(false);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPoReportData([]);
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllSaleOrderItems = () => {
    service.getSaleOrdersDropDownList().then(res => {
      if (res.status) {
        console.log("-----------------");
        console.log(res.data);
        setSoItemData(res.data);
      } else {
        if (res.intlCode) {
          setSoItemData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoItemData([]);
    })
  }
  const EstimatedETDDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedEstimatedFromDate(fromDate)
    setSelectedEstimatedToDate(toDate)
    console.log(fromDate)
    console.log(toDate)
  }

  const getPoDropForPoReport = () => {
    poService.getPoDropForPoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setPoDropDown(res.data);
      } else {
        setPoDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoDropDown([]);
    })
  }


  const getSoDropForPoReport = () => {
    poService.getSoDropForPoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setPoNoDropDown(res.data);
      } else {
        setPoNoDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoNoDropDown([]);
    })
  }
  const getVendorDropForPoReport = () => {
    poService.getVendorDropForPoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setVendorDropDown(res.data);
      } else {
        setVendorDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setVendorDropDown([]);
    })
  }
  const getItemDropForPoReport = () => {
    poService.getItemDropForPoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setItemDropDown(res.data);
      } else {
        setItemDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDropDown([]);
    })
  }

  const getPoLifeCycleStatus = () => {
    poService.getPoLifeCycleStatus({unitId: Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setPoStatusDropDown(res.data);
      } else {
        setPoStatusDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoStatusDropDown([]);
    })
  }
    const getItemSubDropForPoReport = () => {
    poService.getItemSubDropForPoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setItemSubCatDrop(res.data);
      } else {
        setItemSubCatDrop([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemSubCatDrop([]);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  function unique(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }
  const onReset = () => {
    form.resetFields();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
    setSelectedEstimatedFromDate(undefined);
    setSelectedEstimatedToDate(undefined);


    // getReportData();
  }

  const handleSoNumber = (value, item) => {
    const newPoReportData = poReportData.filter(po =>
      po.soNumber == value
    )
    setPoReportData(newPoReportData);
    setPoNumbers(JSON.parse(JSON.stringify(unique(newPoReportData, it => it.purchaseOrderId))));
    setVendorData(JSON.parse(JSON.stringify(unique(newPoReportData, it => it.vendorName))));

  }

  const excelcolumns = [
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      // responsive: ['lg'],
    },
    {
      title: 'PO Date',
      dataIndex: 'purchaseOrderDate',
      render: (value, record: PoReportDto, index) => {
        return moment(record.purchaseOrderDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (value, record: PoReportDto, index) => {
        return moment(record.createdDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'EDD',
      dataIndex: 'EDD',
      render: (value, record, index) => {
        return <span>{moment(record.EDD).format('YYYY-MM-DD')}</span>;
      }
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
    // {
    //   title: 'Billing Unit',
    //   dataIndex: 'billingUnit',
    // },
    // {
    //   title: 'Delivery Unit',
    //   dataIndex: 'deliveryUnit',
    // },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
    },
    {
      title: 'Customer PO Number',
      dataIndex: 'custPoNumber',
    },
    {
      title: 'Sub Category',
      dataIndex: 'itemSubCategory',
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
    },
    {
      title: 'PO Qty',
      dataIndex: 'itemQuantity',
      render: (value, record, index) => {
        return <span>{Number(value).toLocaleString('en-IN', undefined)}</span>;
      }
    },
    {
      title: 'GRN Qty',
      dataIndex: 'GRNQuantity',
      render: (value, record, index) => {
        return <span>{(Number(value).toLocaleString('en-IN', undefined))}</span>;
      }
    },
    {
      title: 'Balance(+)/Excess(-)',
      dataIndex: 'GRNQuantity',
      render: (value, record, index) => {
        return <span>{((record.itemQuantity - record.GRNQuantity).toLocaleString('en-IN', undefined))}</span>;
      }
    },

    {
      title: 'Unit Price(₹)',
      dataIndex: 'itemCost',
      render: (text, data, index) => {
        return <span>{`${Number(text)}`}</span>
      }
    },
    {
      title: 'Tax %',
      dataIndex: 'taxPer',
    },
    {
      title: 'Tax(₹)',
      dataIndex: 'taxAmount',
      render: (text, data, index) => {
        return <span>{Number(text).toLocaleString('en-IN', undefined)}</span>
      }
    },
    {
      title: 'Total Amount(₹)',
      dataIndex: 'amount',
      render: (text, data, index) => {
        return <span>{Number(text)?.toLocaleString('en-IN', undefined)}</span>
      }
    },

    {
      title: ' PO Status',
      dataIndex: 'status',
    },

  ]

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      width: '50px',
      key: 'sno',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      width: '150px',
      fixed: 'left',
      // sortDirections: ['descend', 'ascend'],
      // sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      // ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      // responsive: ['lg'],
      // sorter: (a, b) => a.poType.localeCompare(b.poType),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record: PoReportDto, index) => {
        return record.poType
      },
      filters: [
        {
          text: POTypeEnum.WITHOUTSO,
          value: POTypeEnum.WITHOUTSO,
        },
        {
          text: POTypeEnum.WITHSO,
          value: POTypeEnum.WITHSO,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.poType === value;
      },
    },
    {
      title: 'PO Date',
      dataIndex: 'purchaseOrderDate',
      width: '100px',
      // responsive: ['lg'],
      // sorter: (a, b) => moment(a.purchaseOrderDate).unix() - moment(b.purchaseOrderDate).unix(),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('purchaseOrderDate'),
      render: (value, record: PoReportDto, index) => {
        return moment(record.purchaseOrderDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'EDD',
      dataIndex: 'EDD',
      width: '100px',
      // responsive: ['lg'],
      // sorter: (a, b) => moment(a.ETD).unix() - moment(b.ETD).unix(),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('ETD'),
      render: (value, record, index) => {
        console.log('opopop')
        let bckgrndColor = ''
        console.log(moment(new Date()).format('YYYY-MM-DD'))
        console.log(moment(record.EDD).format('YYYY-MM-DD'))
        console.log(record.EDD < new Date())
        if ((record.EDD) < new Date()) {
          console.log('kkkk')
          bckgrndColor = 'red'
        } else {
          bckgrndColor = "#38f438"

        }
        return <span style={{ color: '' }}>{moment(record.EDD).format('YYYY-MM-DD')}</span>;
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: '100px',
      // responsive: ['lg'],
      // sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record: PoReportDto, index) => {
        return moment(record.createdDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoicedDate',
      width: '100px',
      // responsive: ['lg'],
      // sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return moment(record.invoicedDate) ? moment(record.invoicedDate).format('YYYY-MM-DD') : '-' ;
      }
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      width: '100px',
      // responsive: ['lg'],
      // sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return moment(record.grnDate) ? moment(record.grnDate).format('YYYY-MM-DD') : '-' ;
      }
    },
    // {
    //   title: 'GRN User',
    //   dataIndex: 'grnUser',
    //   render:(value,record)=>{
    //     return record.grnUser ? record.grnUser :'-'
    //   }
    // },
    // {
    //   title: 'Received By',
    //   dataIndex: 'receivedBy',
    //   width:'100px',
    //   render:(value,record)=>{
    //     return record.receivedBy ? record.receivedBy :'-'
    //   }
    // },
    // {
    //   title: 'Quality Check',
    //   dataIndex: 'qualityCheck',
    //   width:'100px',
    //   render:(value,record)=>{
    //     return record.qualityCheck ? record.qualityCheck :'-'
    //   }
    // },
    // {
    //   title: 'Rejections',
    //   dataIndex: 'rejections',
    //   width:'100px',
    //   render:(value,record)=>{
    //     return (record.rejections) ? Math.round(record.rejections) :'-'
    //   }
    // },
    {
      title: 'Unit',
      dataIndex: 'unit',
      // sorter: (a, b) => a.unit?.localeCompare(b.unit),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('unit'),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '250px',
      // sorter: (a, b) => a.vendor?.localeCompare(b.vendor),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('vendor'),
    },
    {
      title: 'Customer PO',
      dataIndex: 'custPoNumber',
      width:100
      // sorter: (a, b) => a.custPoNumber?.localeCompare(b.custPoNumber),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('custPoNumber'),
    },
    {
      title: 'Sub Category',
      dataIndex: 'itemSubCategory',
      // sorter: (a, b) => a.itemSubCategory?.localeCompare(b.itemSubCategory),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategory'),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width: '200px',
      // sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('itemName'),
    },
    {
      title: 'PO Qty',
      dataIndex: 'itemQuantity',
      align: 'right',
      // sorter: (a, b) => a.itemQuantity - b.itemQuantity,
      render: (value, record, index) => {
        return <span>{Number(value).toLocaleString('en-IN', undefined)}</span>;
      }
    },
    {
      title: 'GRN Qty',
      dataIndex: 'GRNQuantity',
      align: 'right',
      // sorter: (a, b) => a.GRNQuantity - b.GRNQuantity,
      render: (value, record, index) => {
        return <span>{Number(value).toLocaleString('en-IN', undefined)}</span>;
      }
    },
    {
      title: 'Balance(+)/Excess(-)',
      dataIndex: 'GRNQuantity',
      width: '160px',
      align: 'right',
      // sorter: (a, b) => a.GRNQuantity - b.GRNQuantity,
      render: (value, record, index) => {
        let bckgrndcolour = ''
        let balorexcessqty = 0
        if (record.itemQuantity >= record.GRNQuantity) {
          bckgrndcolour = 'Green'
          balorexcessqty = record.itemQuantity - record.GRNQuantity
        } else {
          bckgrndcolour = 'Red'
          balorexcessqty = record.GRNQuantity - record.itemQuantity
        }
        return <span style={{ color: bckgrndcolour }}>{((balorexcessqty).toLocaleString('en-IN', undefined))}</span>;
      }
    },

    {
      title: 'Unit Price(₹)',
      dataIndex: 'itemCost',
      align: 'right',
      // sorter: (a, b) => a.itemCost - b.itemCost,
      render: (text, data, index) => {
        return <span>{`${Number(text)}`}</span>
      }
    },
    {
      title: 'Tax %',
      dataIndex: 'taxPer',
      // sorter: (a, b) => a.taxPer?.localeCompare(b.taxPer),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('taxPer'),
    },
    {
      title: 'Tax(₹)',
      dataIndex: 'taxAmount',
      align: 'right',
      // sorter: (a, b) => a.taxAmount - b.taxAmount,
      render: (text, data, index) => {
        return <span>{Number(text).toLocaleString('en-IN', undefined)}</span>
      }
    },
    {
      title: 'Total Amount(₹)',
      dataIndex: 'amount',
      align: 'right',
      // sorter: (a, b) => a.amount - b.amount,
      render: (text, data, index) => {
        return <span>{Number(text)?.toLocaleString('en-IN', undefined)+' ₹'}</span>
      }
    },
    // {
    //   title:'Total Stock',
    //   dataIndex: 'totalStock',
    //   width:'130px'
    // },
    // {
    //   title:'KKD stock volume/value ',
    //   dataIndex: 'kkdValue',
    //   // width:'130px'
    // },
    // {
    //   title:'Unit-1 volume/value',
    //   dataIndex: 'unitOneValue',
    //   // width:'130px'
    // },
    // {
    //   title:'Unit-2 volume/value',
    //   dataIndex: 'unitTwoValue',
    //   // width:'130px'
    // },
    // {
    //   title:'% dead stock- without orders ',
    //   dataIndex: 'percentage',
    //   // width:'130px'
    // },
    {
      title:'life Cycle Status',
      dataIndex: 'lifeCycleStatus',
    },
    {
      title: ' PO Status',
      dataIndex: 'status',
      fixed: 'right',
      width: '130px',
      // responsive: ['lg'],
      // sorter: (a, b) => a.status.localeCompare(b.status),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record: PoReportDto, index) => {
        return ((record.status == StatusEnum.OPEN) ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>{record.status}</Tag> : ((record.status == StatusEnum.IN_PROGRESS) ? <Tag style={{ backgroundColor: '#e8e21e', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : ((record.status == StatusEnum.CLOSED) ? <Tag style={{ backgroundColor: '#f5222d', color: "black", fontWeight: 'bold' }}>{record.status}</Tag> : (record.status == StatusEnum.CANCELLED) ? <Tag style={{ backgroundColor: '#d56666bf', color: "black", fontWeight: 'bold' }}>{record.status}</Tag> : '')))
      },
      filters: [
        {
          text: 'OPEN',
          value: StatusEnum.OPEN,
        },
        {
          text: 'IN_PROGRESS',
          value: StatusEnum.IN_PROGRESS,
        },
        {
          text: 'CLOSED',
          value: StatusEnum.CLOSED,
        },
        {
          text: 'CANCELLED',
          value: StatusEnum.CANCELLED,
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },

  ]
  const aging: ColumnProps<any>[] = [
    {
      title: 'Aging',
      fixed: 'right',
      key: 'aging',
      render: (text, record) => {

        {
          const obj: any = {
            children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(moment(record.EDD).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
            props: {
              style: {
                background: Math.floor((new Date(moment(record.EDD).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
                color: Math.floor((new Date(moment(record.EDD).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
              }
            },
          };
          return record.status == StatusEnum.OPEN || record.status == StatusEnum.IN_PROGRESS ? obj : "-"


        }
      }
    },
  ];

  const headers = [
    { label: "Po Number", key: "poNumber" },
    { label: "PO Type", key: "poType" },
    { label: "PO Date", key: "purchaseOrderDate" },
    { label: "EDD", key: "EDD" },
    { label: "Unit", key: "unit" },
    { label: "Vendor", key: "vendor" },
    { label: "Customer PO", key: "custPoNumber" },
    { label: "Item Sub category", key: "itemSubCategory" },
    { label: "Item", key: "itemName" },
    { label: "PO Quantity", key: "itemQuantity" },
    { label: "GRN Quantity", key: "GRNQuantity" },
    { label: "Balance", key: "GRNQuantity" },
    { label: "Unit Price", key: "itemCost" },
    { label: "Tax Percentage", key: "taxPer" },
    { label: "Tax Amount", key: "taxAmount" },
    { label: "Total Amount", key: "amount" },
    { label: "PO Status", key: "status" },
  ];


  const csvReport = {
    data: poReportData.map((record) => {
      return { EDD: moment(record.EDD).format('YYYY-MM-DD'), purchaseOrderDate: moment(record.purchaseOrderDate).format('YYYY-MM-DD'), balance: ((record.itemQuantity > record.GRNQuantity) ? (record.itemQuantity - record.GRNQuantity) : (record.GRNQuantity - record.itemQuantity)), ...record }
    }),
    headers: headers,
    filename: 'POReport.csv'
  };

  const poColumns = [...columns, ...aging,];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const [form] = Form.useForm();
  const { Option } = Select;
  const onFocus = () => {
  }

  const onSearch = (val) => {
  }
  const onBlur = () => {
  }

  const handleStatus = (val) => {
    setPurchaseOrderStatus(val);
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const getReportData = () => {
    setTriggerReport(true)
    const fromDate = form.getFieldValue('poFromDate');
    const poToDate = form.getFieldValue('poToDate');
    // console.log(new Date(poFromDate[0].format('YYYY-MM-DD')))
    // console.log(new Date(poFromDate.format('YYYY-MM-DD')))
    // console.log(poToDate.format('YYYY-MM-DD'))
    const saleOrderId = form.getFieldValue('saleOrderId');
    const purchaseOrderId = form.getFieldValue('poId');
    const poStatus = form.getFieldValue('status');
    const vendorId = form.getFieldValue('vendorId');
    const poType = form.getFieldValue('poType');
    const itemNameId = form.getFieldValue('itemName');
    const itemSubCategoryId = form.getFieldValue('itemSubCategory');
    const excessPercent = form.getFieldValue('excessPercent');
    const lifeCycleStatus = form.getFieldValue('lifeCycleStatus');

    const req = new PoReportRequest();
    req.fromDate = selectedEstimatedFromDate;
    req.toDate = selectedEstimatedToDate;
    req.saleOrderId = saleOrderId;
    req.poStatus = poStatus;
    req.purchaseOrderId = purchaseOrderId;
    req.vendorId = vendorId;
    req.poType = poType;
    req.itemNameId = itemNameId;
    req.itemSubCategoryId = itemSubCategoryId;
    req.excessPercent = excessPercent;
    req.lifeCycleStatus = lifeCycleStatus;
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    console.log(req)

    getPurchaseOrdersForReport(req)
  }

  const getAllActivePlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantsData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
    });
  }

  const handleUnit = (value,) => {
    setUnit(value)

  }

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('POReport')
      .addColumns(excelcolumns)
      .addDataSource(poReportData, { str2num: true })
      .saveAs('POReport.xlsx');
  }

  const exportToPdf = () => {
    //  var header = [
    //     {title: "Country", dataIndex: "countryName"},
    //     {title: "Destination", dataIndex: "destinationName"},
    // ];
    var columns = [
      { title: "Country", dataKey: "countryName" },
      { title: "Destination", dataKey: "destinationName" },
      // {title: "Status", dataKey: "isActive"}, 
    ];
    // var rows = destinationData;
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(excelcolumns, poReportData, {
      // styles: {fillColor: [100, 255, 255]},
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      // addPageContent: function (data) {
      //   doc.text("POREPORT", 50, 15);
      // }
    });
    // doc.autoTable({ html: '#my-table' })
    doc.save('PoReport.pdf')
  }


  return (

    <>
      <Card title={<span style={{ color: 'white' }} >Purchase Order Report</span>} 
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Form form={form} layout={'vertical'} style={{ padding: '0px' }}  >
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="fromDate"
                label="PO Date"
                rules={[
                  {
                    required: false,
                    message: "Missing EDD Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate} allowClear />
              </Form.Item>
            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
              <Form.Item name="saleOrderId"
                label="Customer PO"
                rules={[{ required: false, message: ' Select ' },]}>
                <Select showSearch
                  placeholder="Select Customer po"
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus} onBlur={onBlur} onSearch={onSearch}

                >
                  <Option key={0} value={null}>Select Sale Order</Option>
                  {poNoDropDown.map((data) => {
                    return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

              <Form.Item
                name="poId"
                label="PO Number"

              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select Po Number"
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {poDropDown.map(dropData => {
                    return <Option name="poId" key={dropData.purchaseOrderId} value={dropData.purchaseOrderId}>{dropData.poNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 8 }}>
              <Form.Item name="vendorId"
                label="Vendor Name"

              >
                <Select showSearch
                  placeholder="Select Vendor"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                  //  onChange={handlevendorName}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                >
                  <Option key={0} value={null}>Select Vendor</Option>
                  {vendorDropDown.map((vendor) => {
                    return <Option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendor}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Form.Item name="delUnitId" label="Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  onChange={handleUnit}
                  allowClear
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="createdDate"
                label="Created Date"
                rules={[
                  {
                    required: false,
                    message: "Missing created Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate} allowClear />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 10 }}>
              <Form.Item label="Item Name"
                name='itemName'
              >
                <Select showSearch
                  allowClear
                  placeholder="Select Item"
                  // onSelect={handleItemName}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {itemDropDown === undefined ? '' : itemDropDown.map(dropData => {
                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5}}>
              <Form.Item label="Item Sub category"
                name='itemSubCategory'
              >
                <Select showSearch
                  allowClear
                  placeholder="Select Item Sub category"
                  // onSelect={handleItemName}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {itemSubCatDrop === undefined ? '' : itemSubCatDrop.map(dropData => {
                    return <Option key={dropData.itemSubCategoryId} value={dropData.itemSubCategoryId}>{dropData.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
              <Form.Item
                name="excessPercent"
                label="Excess GRN %"
              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select GRN %"
                  optionFilterProp="children"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0}>{'below 100%'}</Option>
                  <Option value={100}>{'100% to 105%'}</Option>
                  <Option value={105}>{'above 105%'}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
              <Form.Item
                name="lifeCycleStatus"
                label="PO Life Cycle"
              >
                <Select
                  showSearch
                  placeholder="Select Life Cycle Status"
                  optionFilterProp="children"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {Object.values(POLifeCycleStatusEnum).map(PoLifeCycleStatus => {
                    return <Option value={PoLifeCycleStatus}>{PoLifeCycleStatus}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} style={{ marginTop: '30px' }}>
              <Form.Item >
                <Button disabled={disable} onClick={() => getReportData()} type="primary" htmlType="submit" > Get Details</Button>
              </Form.Item>
            </Col>
            <Col span={2} style={{ marginTop: '30px' }}>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>

              </Form.Item>
            </Col>
          </Row>
        </Form>
        <br />
        {(triggerReport) ? (isShowtable) ? <>
          <Row gutter={40}>
            <Col>
              <Card title={"PO's: " + totalPoCount} style={{ textAlign: 'left', width: 170, height: 41, backgroundColor: '#bfbfbf' }}></Card>
            </Col>
            <Col>
              <Card title={"PO Items: " + poReportData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
            </Col>
            <Col>
              <Card title={'OPEN :' + poReportData.filter(el => el.status == StatusEnum.OPEN).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#52c41a' }}></Card>
            </Col>
            <Col>
              <Card title={'IN-PROGRESS :' + poReportData.filter(el => el.status == StatusEnum.IN_PROGRESS).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#e8e21e' }}></Card>
            </Col>
            <Col>
              <Card title={'CLOSED :' + poReportData.filter(el => el.status == StatusEnum.CLOSED).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f5222d' }}></Card>
            </Col>
            <Col>
              <Card title={'CANCELLED :' + poReportData.filter(el => el.status == StatusEnum.CANCELLED).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#d56666bf' }}></Card>
            </Col>
            <Col>
              <Button onClick={() => { exportExcel(); }}>Get Excel</Button>
            </Col>
          </Row>
          <Table
            rowKey={record => record.purchaseOrderId}
            columns={poColumns}
            dataSource={poReportData}
            // style={{display: display}}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize)
              }
            }}
            onChange={onChange}
            scroll={{ x: 3000, y: 1000 }}
            bordered
            summary={(pageData) => {
              let totalQuantity = 0;
              let totalTax = 0;

              poReportData.forEach(({ amount, taxAmount }) => {
                console.log(amount)
                console.log(taxAmount)
                totalQuantity += Number(amount);
                totalTax += Number(taxAmount);

              });

              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={16} ><Text >Total(₹)</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{totalQuantity.toLocaleString('en-IN', undefined)}</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}><Text style={{ textAlign: 'end' }}>{totalTax.toLocaleString('en-IN', undefined)}</Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }
            }

          /></> : NoData ? <></> : <span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span> : <></>}

      </Card>
    </>
  );
}





