import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { palletTypeDto } from './pallet-type.dto';

export class SuggestPalletTypeResponseModel extends GlobalResponseObject {
    data?: palletTypeDto[];

   
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: palletTypeDto[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}