
import axios from 'axios';
import { DeviceCategoryAndDeptNameRequest, DeviceNameDto, DeviceResponse } from '@gtpl/shared-models/masters';
import connection from './connection';

export class DeviceService {
    // create(destinationData: DestinationDto) {
    //   throw new Error('Method not implemented.');
    // }
    URL = connection.DEPLOY_URL + '/device';
    async getAllDevicesByDeviceCategoryAndDepartment(deviceCatAndDeptNameReq: DeviceCategoryAndDeptNameRequest): Promise<DeviceResponse> {
        // console.log(destination);
        return await axios.post(this.URL + '/getAllDevicesByDeviceCategoryAndDepartment', deviceCatAndDeptNameReq)
            .then(res => {
                return res.data
            })
    }

    async getDeviceDataByMacId(deviceCatAndDeptNameReq: DeviceNameDto): Promise<DeviceResponse> {
        // console.log(destination);
        return await axios.post(this.URL + '/getDeviceDataByMacId', deviceCatAndDeptNameReq)
            .then(res => {
                return res.data
            })
    }
} 