export class EducationDto{
    educationId: number;
    education: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;

    constructor(
        educationId: number,
        education: string,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number,
    ){
        this.educationId = educationId;
        this.education = education;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}

export const EducationDtoDefault : EducationDto = {
    educationId: 0,
    education: "",
    createdUser:"",
    isActive:true,
    createdAt: new Date(),
    updatedAt: new Date(),
    updatedUser: '',
    versionFlag: 1,
};