import { Param } from '@nestjs/common';
import { IsNotEmpty, IsAlphanumeric, MaxLength, Matches, IsOptional, IsNumber } from 'class-validator';

export class RmBomItemRequest {
    productId: number;
    count: number;
    sizeId?: number;
    isActive?: boolean;

    /**
    
     * @param productId
     * @param count
     * @param isActive
     */

    constructor(
        productId: number, count: number,sizeId?:number
    ) {
        this.productId = productId;
        this.count = count;
        this.sizeId = sizeId;
    }
}