export class GradeSequenceModel{
    sequence:number;
    priority:number;
    plannedQuantity:number;
    gradeSuggestionId: number;

    constructor(
        sequence:number,
    priority:number,
    plannedQuantity:number,
    gradeSuggestionId: number
    ){
        this.sequence = sequence;
        this.priority = priority;
        this.plannedQuantity = plannedQuantity;
        this.gradeSuggestionId = gradeSuggestionId;
    }
}