import axios from 'axios';
import { JobCompletedEnum, JobStatusEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import {ValAddInventoryDto, ValAdditionResponse, ValAdditionResponseModel, ValueAdditionDto, ValAddSoakingDto, ValAdditionSoakingResponseModel, DeheadingValAdditionWorkStatusResponse, WorkStatusDto, GradeToValAdditionResponseModel,GradeInventoryRequest, InventoryRequest} from '@gtpl/shared-models/production-management';
import connection from './connection';
export class ValueAdditionService{
    URL = connection.DEPLOY_URL + '/val-addition-operation';

    async valAdditionsReportedData(plantReq:UnitRequest):Promise<ValAdditionSoakingResponseModel>{
        // const data = {
        //     numOfLots: 4,
        //     qtytobeprocessed: 2000,
        //     valAddInventory: 1500,
        //     gridData : [
        //         {
        //             jobNumber: '210604val01',
        //             workStation: 'Table - 1',
        //             lotNo: 'Lot001',
        //             soNumber: 'SO001',
        //             grade: 29,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260, 
        //     }, 
        //     {
        //             jobNumber: '210604val02',
        //             workStation: 'Table - 2',
        //             lotNo: 'Lot002',
        //             soNumber: 'SO002',
        //             grade: 30,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260, 
        //     }, 
        //     {
        //             jobNumber: '210604val03',
        //             workStation: 'Table - 3',
        //             lotNo: 'Lot003',
        //             soNumber: 'SO003',
        //             grade: 31,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260,
        //     }
        // ]
        // }
        //return new ValAdditionSoakingResponseModel(true,100022,'Data Retrived',data)
        return await axios.post(this.URL + '/valAdditionsReportedData',plantReq)
            .then(res => {
                return res.data
            })
    }

    async  AssignValadditionOperation(data: ValueAdditionDto): Promise<ValAdditionResponse> {
        return await axios.post(this.URL + '/AssignValadditionOperation', data)
               .then(res => {
                   return res.data
               })
    }

    async valAdditionsWipData(req:InventoryRequest): Promise<ValAdditionResponseModel>{
        //         const data = {
        //     numOfLots: 4,
        //     qtytobeprocessed: 2000,
        //     valAddInventory: 1500,
        //     gridData : [
        //         {
        //             jobNumber: '210604val01',
        //             workStation: 'Table - 1',
        //             lotNo: 'Lot001',
        //             soNumber: 'SO001',
        //             grade: 29,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260, 
        //             jobStatus: JobCompletedEnum.NO,
                       
        //     }, 
        //     {
        //             jobNumber: '210604val02',
        //             workStation: 'Table - 2',
        //             lotNo: 'Lot002',
        //             soNumber: 'SO002',
        //             grade: 30,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260, 
        //             jobStatus: JobCompletedEnum.YES,
        //     }, 
        //     {
        //             jobNumber: '210604val03',
        //             workStation: 'Table - 3',
        //             lotNo: 'Lot003',
        //             soNumber: 'SO003',
        //             grade: 31,
        //             valueAddition: 'PDTON',
        //             boxes: 10,
        //             quantity: 260,
        //             jobStatus: JobCompletedEnum.NO,
        //     }
        // ]
        // }
        // return new ValAdditionResponseModel(true,100022,'Data Retrived',data)
        return await axios.post(this.URL + '/valAdditionsWipData',req)
            .then(res => {
                return res.data
            })
    }

    async valAdditionWorkStatusInfo(plantReq:UnitRequest): Promise<DeheadingValAdditionWorkStatusResponse>{
        return await axios.post(this.URL + '/valAdditionWorkStatusInfo',plantReq)
            .then(res => {
                return res.data
            })
    }
    async receiveSubPlantStockInfo(plantReq:UnitRequest): Promise<DeheadingValAdditionWorkStatusResponse>{
        return await axios.post(this.URL + '/receiveSubPlantStockInfo',plantReq)
            .then(res => {
                return res.data
            })
    }

    async jobCompletedStatus(workstatusDto:WorkStatusDto): Promise<DeheadingValAdditionWorkStatusResponse>{
        return await axios.post(this.URL + '/jobCompletedStatus', workstatusDto)
            .then(res => {
                return res.data
            })
    }

    async ReportSubPlantOperation(workstatusDto:WorkStatusDto): Promise<DeheadingValAdditionWorkStatusResponse>{
        return await axios.post(this.URL + '/ReportSubPlantOperation', workstatusDto)
            .then(res => {
                return res.data
            })
    }

    async isReceivedStatus(workstatusDto:WorkStatusDto): Promise<DeheadingValAdditionWorkStatusResponse>{
        return await axios.post(this.URL + '/isReceivedStatus', workstatusDto)
            .then(res => {
                return res.data
            })
    }

    async getGradedInventoryForValAddition(inputReq:GradeInventoryRequest):Promise<GradeToValAdditionResponseModel>{
        return await axios.post(this.URL + '/getGradedInventoryForValAddition', inputReq)
            .then(res => {
                return res.data
            })
    }
}