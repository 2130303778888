
export class SaleOrderItemsInfo {
    saleOrderItemId: number;
    saleOrderId: number;
    itemId: number;
    minGrade: number;
    maxGrade: number;
    foodTypeId: number;
    valueAdditionOneId: number;
    valueAdditionTwoId: number;
    valueAdditionThreeId: number;
    valueAdditionFourId: number;
    valueAdditionFiveId: number;
    pouches: number;
    cases: number;
    caseWeight: string;
    netWeight: string;
    netAmount: number;
    createdUser: string;
    pouchWeight: number;
    categoryId: number;
    unitPrice: number;
    brandId: number;
    uomId: number;
    // freezeStle: number;
    minGlaze: number;
    MaxGlaze: number;
    soakingTime: string;
    soakingStyle: string;
    styleWeighment: string;
    varientId: number;
    innerBags: number;
    shrimpWeight: number;
    innerBagSizeId: number;
    skewerSizeItemId: number;
    updatedUser: string;
    masterBrandId:number;
    finalPercentage?:number;
    category?:string;
    requiredCount?:number;
    packStyleId?:number;
    jobCases?: number;
    reportedCases?: number;
    palletCapacity?: number;
    subJobCodeListData?: any[];
    barcode?: string;

    /**
    * @param saleOrderItemId
    * @param saleOrderId
    * @param itemId
    * @param minGrade
    * @param maxGrade
    * @param foodTypeId
    * @param valueAdditionOneId
    * @param valueAdditionTwoId
    * @param valueAdditionThreeId
    * @param valueAdditionFourId
    * @param valueAdditionFiveId
    * @param pouches
    * @param totalAmount
    * @param cases
    * @param caseWeight
    * @param netWeight
    * @param netAmount
    * @param createdUser
    * @param pouchWeight
    * @param category
    * @param uomId
    // * @param freezeStle
    * @param minGlaze
    * @param MaxGlaze
    * @param soakingTime
    * @param soakingStyle
    * @param styleWeighment
    * @param varientId
    * @param innerBags
    * @param shrimpWeight
    * @param innerBagSizeId
    * * @param skewerSizeItemId
    * @param updatedUser
    * @param masterBrandId
     */
    constructor(saleOrderItemId: number, saleOrderId: number, itemId: number, minGrade: number, maxGrade: number, foodTypeId: number, valueAdditionOneId: number, valueAdditionTwoId: number, valueAdditionThreeId: number, valueAdditionFourId: number,valueAdditionFiveId:number, pouches: number, cases: number, caseWeight: string, netWeight: string, netAmount: number, createdUser: string, pouchWeight: number, categoryId: number, unitPrice: number, brandId: number, uomId: number, minGlaze: number, MaxGlaze: number, soakingTime: string, soakingStyle: string, styleWeighment: string,varientId: number,innerBags: number,shrimpWeight: number,innerBagSizeId: number,skewerSizeItemId:number,updatedUser: string,masterBrandId:number,finalPercentage?:number,category?:string,requiredCount?:number, packStyleId?:number,jobCases?: number,reportedCases?:number,palletCapacity?: number,subJobCodeListData?: any[],barcode?: string) {
        this.saleOrderItemId = saleOrderItemId;
        this.saleOrderId = saleOrderId;
        this.itemId = itemId;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.foodTypeId = foodTypeId;
        this.valueAdditionOneId = valueAdditionOneId;
        this.valueAdditionTwoId = valueAdditionTwoId;
        this.valueAdditionThreeId = valueAdditionThreeId;
        this.valueAdditionFourId = valueAdditionFourId;
        this.valueAdditionFiveId = valueAdditionFiveId;
        this.pouches = pouches;
        this.cases = cases;
        this.caseWeight = caseWeight;
        this.netWeight = netWeight;
        this.netAmount = netAmount;
        this.createdUser = createdUser;
        this.pouchWeight = pouchWeight;
        this.categoryId = categoryId;
        this.unitPrice = unitPrice;
        this.brandId = brandId;
        this.uomId = uomId;
        // this.freezeStle = freezeStle;
        this.minGlaze = minGlaze;
        this.MaxGlaze = MaxGlaze;
        this.soakingTime = soakingTime;
        this.soakingStyle = soakingStyle;
        this.styleWeighment = styleWeighment;
        this.varientId = varientId;
        this.innerBags = innerBags;
        this.shrimpWeight = shrimpWeight;
        this.innerBagSizeId = innerBagSizeId;
        this.skewerSizeItemId = skewerSizeItemId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.masterBrandId = masterBrandId;
        this.finalPercentage = finalPercentage;
        this.category = category;
        this.requiredCount = requiredCount;
        this.packStyleId = packStyleId;
        this.jobCases = jobCases;
        this.reportedCases = reportedCases;
        this.palletCapacity = palletCapacity;
        this.subJobCodeListData = subJobCodeListData;
        this.barcode = barcode;
    }
}


