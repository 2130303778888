import { AssertIdRequest, CommonResponse, SoakingRequestDTO, SoakingTubResponseModel } from '@gtpl/shared-models/production-management';
import axios from 'axios';
import connection from './connection';

export class SoakingTubService {
  URL = connection.DEPLOY_URL + '/soaking-tub';

  async createSoaking(req:SoakingRequestDTO): Promise<SoakingTubResponseModel> {
    return await axios.post(this.URL + '/createSoaking',req)
        .then(res => {
            return res.data
        })   
    }
    async getMachineDetails(req:AssertIdRequest): Promise<CommonResponse> {
      return await axios.post(this.URL + '/getMachinedetails',req)
          .then(res => {
              return res.data
          })   
      }


  }

