import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { EmployeesDeptWiseCountDto } from './employees-dept-wise-count-dto';


export class EmployeesDeptWiseResponseModel extends GlobalResponseObject {
    data?: EmployeesDeptWiseCountDto;
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: EmployeesDeptWiseCountDto) {
        super(status, errorCode, internalMessage);
        this.data = data
    }
}
