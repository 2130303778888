import { JobCompletedEnum, JobStatusEnum } from "@gtpl/shared-models/common-models";

export class GradeToValAddInventory{
    numOfLots: number;
    qtytobeprocessed: number;
    valAddInventory: number;
    gridData:GradeToValAddGridDto[];
   
    constructor(
        numOfLots: number,
        qtytobeprocessed: number,
        valAddInventory: number,
        gridData:GradeToValAddGridDto[]
    ){
        this.numOfLots = numOfLots;
        this.qtytobeprocessed = qtytobeprocessed;
        this.valAddInventory = valAddInventory;
        this.gridData = gridData;
    }
}
export class GradeToValAddGridDto{
    lotNo: string;
    soNumber: string;
    grade: number;
    valueAddition: string;
    boxes: number;
    quantity: number;
    subLotNumber?:string;
    saleOrderId?:number;
    saleOrderItemId?:number;
    operation:number;
    physicalQuantity?:number;
    poNumber?:string;
    inputGrade?:number;
    outputGrade?:number;

    constructor(
        lotNo: string,
        soNumber: string,
        grade: number,
        valueAddition: string,
        boxes: number,
        quantity: number, 
        subLotNumber?:string,
        saleOrderId?:number,
        saleOrderItemId?:number,
        operation?:number,
        physicalQuantity?:number,
        poNumber?:string,
        inputGrade?:number,
        outputGrade?:number
       ){
         this.lotNo = lotNo;
         this.soNumber = soNumber;
         this.grade = grade;
         this.valueAddition = valueAddition;
         this.boxes = boxes;
         this.quantity = quantity;
         this.subLotNumber = subLotNumber;
         this.saleOrderId = saleOrderId;
         this.saleOrderItemId = saleOrderItemId;
         this.operation = operation;
         this.physicalQuantity = physicalQuantity;
         this.poNumber = poNumber;
         this.inputGrade = inputGrade;
         this.outputGrade = outputGrade;
       }
}