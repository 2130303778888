import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { DatePicker, Space } from "antd";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import moment from "moment";
import { Excel } from 'antd-table-saveas-excel';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';


const { Option } = Select;
const { RangePicker } = DatePicker;
import Highlighter from 'react-highlight-words';
import { AssetByStatus, AssetServiceRportReq } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";


/* eslint-disable-next-line */
export interface AuditReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const AuditReport = (
  props: AuditReportProps
) => {
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const onSearch = (value: string) => console.log(value);
  const [transData, setTransData] = useState<any>([]);
  const { TabPane } = Tabs;
  const [assetsData, setAssetsData] = useState<AssetDto[]>([])
  const assetService = new AssetService();
  const { Text } = Typography;
  const [length, setLength] = useState('');
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [categoryData,setCategoryData] =  useState<any[]>([])
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const [pageSize, setPageSize] = useState<number>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);




  // const service = new AssetMaintenanceService();
  const role = JSON.parse(localStorage.getItem('role'))

  let diff = 0;
  let date;
  let todate;
  var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear, currentMonth, 1)
  const dateformat = 'YYYY-MM-DD';

  useEffect(() => {
    //getAllData();
    getAuditReportData();
    getAssetCode();
    getAllPlants();
    getItemCategory();
    getAllCategories();
    // getTransById();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    } getLocations();
  }, [])

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAuditReportData = () => {
    const assetcode = form.getFieldValue('assetsCode')
    const location = form.getFieldValue('assetLocation')
    const assetcategory = form.getFieldValue('categoryName');
    const plant = form.getFieldValue('plantId')
    const req= new AssetServiceRportReq();

    if (Number(localStorage.getItem('unit_id')) == 5) {
      req.plant= plant
      }else{
        req.plant= Number(localStorage.getItem('unit_id'))
      }
      req.location = location
      req.assetCategory = assetcategory;
      req.assetCode=assetcode;
    // req.plantId = Number(localStorage.getItem('unit_id'))
    assetService.getAuditReportData(req).then((res) => {
        if (res.status) {setTransData(res.data)
          setLength(res.data.length)
            setTransData(res.data);
        } else {
            setTransData([]);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setTransData([]);
    })
}

const onReset = () => {
  form.resetFields();
  getAuditReportData();
  }
  // const getAllData = () => {
  //   service.getTransById().then(res => {
  //     if(res.status) {
  //       setTransData(res.data)
  //       // AlertMessages.getSuccessMessage('Data retrieved successfully')
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // // }


  const getAssetCode = () => {
    assetService.getallAssets().then((res) => {
      if(res.status) {
        setAssetsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllCategories = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setCategoryData(res.data)
      } else {
        setCategoryData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setCategoryData([]);
    });
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

  const searchHandler = () => {
    // if(form.getFieldValue('date') !== undefined) {
    //   const fromDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[0]).format('YYYY-MM-DD') : null;
    //   const toDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[1]).format('YYYY-MM-DD') : null;
    //   const selectedDate = { fromDate, toDate };
    //   getDateReport(selectedDate);
    // } else {
    //   getTransById()
    // }
    // getTransById()

  }


  const resetHandler = () => {
    form.resetFields();
    setTransData([])
    // getTransById()
  }

  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      // width: "30px",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: "Unit",
      dataIndex: "unitName",
      align: "left",
      //width:120,
      sorter: (a, b) => a.unitName?.localeCompare(b.unitName),
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => {
        return (
          <>
            {record.unitName ? record.unitName : '-'}
          </>
        )
      },
      ...getColumnSearchProps('unitName')
    },
    

    {
      title: "Location",
      dataIndex: "locationName",
      align: "left",
      // width:120,
      sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
      sortDirections: ['ascend', 'descend',],
      ...getColumnSearchProps('locationName'),
      render: (text, record) => {
        return (
          <>
            {record.locationName ? record.locationName : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: 'Asset Category',
      dataIndex: 'categoryName',
    align:'left',
      // width:'90px',
      sorter: (a, b) => a.categoryName?.localeCompare(b.categoryName),
      sortDirections: ['ascend', 'descend',],
      ...getColumnSearchProps('categoryName')
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetsCode',
      // width:'90px',
      align:'left',
      sorter: (a, b) => a.assetsCode.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode')
    },

    {
      title: "Audit Type",
      dataIndex: "auditType",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.auditType?.localeCompare(b.auditType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('auditType'),
    },
    {
      title: "Audit Date",
      dataIndex: "auditDate",
      // width: 150,
      align: "left",

      sorter: (a, b) => a.auditDate?.localeCompare(b.auditDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('auditDate'),
      render: (text, record) => {
        return (
          <>
            {record.auditDate ? moment(record.auditDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      },

    },
    {
      title: "Audit Provider",
      dataIndex: "auditProvider",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.auditProvider?.localeCompare(b.auditProvider),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('auditProvider'),

    },
    
    {
      title: "Audit Name",
      dataIndex: "auditName",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.auditName?.localeCompare(b.auditName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('auditName'),

    },
    {
      title: "Audit Owner",
      dataIndex: "auditOwner",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.auditOwner?.localeCompare(b.auditOwner),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('auditOwner'),

    },
    
    {
      title: "Total Assets",
      dataIndex: "assetsCount",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.assetsCount-b.assetsCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCount'),
      render: (text, record) => {
        return (
          <>
            {record.assetsCount  ? Number(Number(record.assetsCount  ).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      }

    },
    {
      title: "Working Assets",
      dataIndex: "workingAssets",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.workingAssets-b.workingAssets,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workingAssets'),
      render: (text, record) => {
        return (
          <>
            {record.workingAssets ? record.workingAssets : '-'}
          </>
        )
      },

    },
    {
      title: "Not Working Assets",
      dataIndex: "notWorkingAssets",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.notWorkingAssets-b.notWorkingAssets,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('notWorkingAssets'),
      render: (text, record) => {
        return (
          <>
            {record.notWorkingAssets ? record.notWorkingAssets : '-'}
          </>
        )
      },

    },
    {
      title: "Status",
      dataIndex: "status",
      // width: 150,
      align: "left",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status'),

    },
    {
      title: "Percentage",
      dataIndex:"percentage",
      align:"left",
      width:110,
      sorter: (a, b) => a.percentage?.localeCompare(b.percentage),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('percentage'),
      render:(text,record) => {
            const successCount = (record.workingAssets/record.assetsCount)*100
            const failureCount = (record.notWorkingAssets/record.assetsCount)*100
            return(
              <>
              <div style={{background:"#38f438" }}><p>{successCount.toFixed(0)}%</p></div>
              <div style={{background:'#f4646c' }}><p>{failureCount.toFixed(0)}%</p></div>
              </>
            )

      }
    }
  ];


  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const exportedData = [];
  const excelData = transData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Unit', dataIndex: 'unitName', render: (text, record) => { return record.unitName ? record.unitName : '-' } },
    { title: 'Location', dataIndex: 'locationName', render: (text, record) => { return record.locationName ? record.locationName : '-' } },
    { title: 'Audit Type', dataIndex: 'auditType', render: (text, record) => { return record.auditType ? record.auditType : '-' } },
    { title: 'Audit Date', dataIndex: 'auditDate', render: (text, record) => { return record.auditDate ? moment(record.auditDate).format('YYYY-MM-DD') : '-' } },
    { title: 'Audit Provider', dataIndex: 'auditProvider', render: (text, record) => { return record.auditProvider ? record.auditProvider : '-' } },
    { title: 'Audit Name', dataIndex: 'auditName', render: (text, record) => { return record.auditName ? record.auditName : '-' } },
    { title: 'Audit Owner', dataIndex: 'auditOwner', render: (text, record) => { return record.auditOwner ? record.auditOwner : '-' } },
    { title: 'Total', dataIndex: 'assetsCount', render: (text, record) => { return record.assetsCount ? record.assetsCount : '-' } },
    { title: 'Working Assets', dataIndex: 'workingAssets', render: (text, record) => { return record.workingAssets ? record.workingAssets : '-' } },
    { title: 'Not Working Assets', dataIndex: 'notWorkingAssets', render: (text, record) => { return record.notWorkingAssets ? record.notWorkingAssets : '-' } },
    { title: 'Status', dataIndex: 'status', render: (text, record) => { return record.status ? record.status : '-' } },

    // { title: 'Repair Start Date', dataIndex: 'createdAt', render: (text, record) => { return record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-' } },
    // { title: 'Completion Date', dataIndex: 'updatedAt', render: (text, record) => { return record.updatedAt ? moment(record.updatedAt).format('YYYY-MM-DD') : '-' } },
    // {
    //   title: 'Total No of Days', dataIndex: 'days', render: (text, record) => {
    //     todate = moment(moment(record.createdAt).format('YYYY-MM-DD'));
    //     date = moment(moment(record.updatedAt).format('YYYY-MM-DD'));
    //     diff = (todate - date) / 864e5;
    //     return (
    //       <>
    //         {diff ? diff : 0}
    //       </>
    //     )
    //   }
    // },

    // { title: 'Cost of Repair', dataIndex: 'costOfRepair', render: (text, record) => { return record.costOfRepair ? record.costOfRepair : '-' } },
    // { title: 'Damage Reason', dataIndex: 'damageReason', render: (text, record) => { return record.damageReason ? record.damageReason : '-' } },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Audit-report')
      .addColumns(data)
      .addDataSource(transData, { str2num: true })
      .saveAs('Audit-report.xlsx');
  }

  return (
    <Card
      title={<span style={{ color: "white" }}>Audit Report</span>}
      style={{ textAlign: "center" }}
      headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}>

    
      <br /><br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={[24, 24]}>
         
        <Col span={3}>
            <Form.Item label='Unit' name='plantId' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
               placeholder='All Units'
                showSearch
                allowClear>
                   <Option key='all' value=''>All Units </Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="categoryName" label='Asset Category'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='All Categories'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
              <Option key='all' value=''>All Categories</Option>
            {categoryData.map((assets) => {
              return <Option key={assets.itemSubCategoryId} value={assets.itemSubCategoryId}>{`${assets.itemSubCategory}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetLocation" label='Asset Location'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='All Locations'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             <Option key='all' value=''>All Locations </Option>
            {locationData.map((assets) => {
              return <Option key={assets.locationId} value={assets.locationId}>{`${assets.locationName}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
       
       
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} 
            onClick={() => getAuditReportData()}
            >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>

          <Col span={3} style={{marginLeft:'85%',paddingBottom:'1px'}}>
            <Button >Total no of Records:{length}
            </Button>
            </Col>
        </Row>
      </Form>

     
      {transData.length > 0 ? <>
        <Table
          columns={columns}
          scroll={{ x: 1400 }}
          dataSource={transData}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalCost = 0;

            pageData.forEach(({ assetsCount }) => {
              if (Number(assetsCount)) {
                totalCost += Number(assetsCount)
              }
            })

            return (
              <>
                <Table.Summary.Row className="tableFooter">
                  <Table.Summary.Cell index={9} colSpan={9}><Text>Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={10} colSpan={1}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={11} colSpan={1}><Text style={{ textAlign: 'start'}}>{Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>

                </Table.Summary.Row>
              </>
            )
          }}
        />
      </> : <Alert
        message="Search For Data"
        type="info"
        showIcon
      />
      }
    </Card>
  );
}

export default AuditReport;

