import { GlobalResponseObject, Series } from "@gtpl/shared-models/common-models";
import { ChartInfo } from "./chart-data";

export class BeheadingDashboardResponse extends GlobalResponseObject{
    data?: ChartInfo;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ChartInfo){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}