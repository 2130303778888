

export class RecruitmentActivitiesDto{
    recruitmentActivityId: number;
    candidateName: string;
    activity: string;
    activityDate: Date;
    remarks: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;

    constructor(
    recruitmentActivityId: number,
    candidateName: string,
    activity: string,
    activityDate: Date,
    remarks: string,
    isActive: boolean,
    createdAt: Date | any,
    createdUser: string,
    updatedAt: Date | any,
    updatedUser: string,
    versionFlag: number,
    ){
        this.recruitmentActivityId = recruitmentActivityId;
        this.candidateName = candidateName;
        this.activity = activity;
        this.activityDate = activityDate;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}