import { GlobalStatus, PlantsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from '@gtpl/shared-models/common-models';

export class BeHeadingDto {
    productionLogId?: number;
    lotNumber: string;
    jobNumber: string;
    saleOrderId: number;
    saleOrder:string;
    dcNumber:string;
    mrnNumber:string;
    subPlantId: SubPlantCodesEnum;
    issuingQuantity: number;
    boxes: number;
    plantId: number;
    isReceived:GlobalStatus
}
