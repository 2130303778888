import { WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { GradingModel } from "./gradings.model";

export class GradeReportingRequest {
    shift: string;
    plantId: number;
    lotNo: string;
    workstationType: WorkStationTypeEnum;
    workstation: number;
    grades: GradingModel[];
    soNumber:string;
    createdUser: string;
    mainPlantId: number;

    constructor(shift: string,plantId: number,lotNo: string,workstationType: WorkStationTypeEnum,workstation: number,grades: GradingModel[],soNumber:string,createdUser: string,mainPlantId:number){
        this.shift = shift;
        this.plantId = plantId;
        this.lotNo = lotNo;
        this.workstationType = workstationType;
        this.workstation = workstation;
        this.grades = grades;
        this.soNumber= soNumber;
        this.createdUser = createdUser;
        this.mainPlantId = mainPlantId;
    }

}