import axios from "axios";
import { ProductInspectionDto, ProductInspectionResponse, AllProductInspectionsResponse, ProductInspecReq } from "@gtpl/shared-models/production-management";
import { connection } from "..";

export class ProductInspecService{
    getEvaluation(req: ProductInspecReq) {
      throw new Error('Method not implemented.');
    }

    URL = connection.DEPLOY_URL + '/product-inspection';

    async create(dto: ProductInspectionDto): Promise<ProductInspectionResponse>{
        return await axios.post(this.URL+ '/createProductInspection', dto)
            .then(res => {
                return res.data
            })
    }

    async update(dto: ProductInspectionDto): Promise<ProductInspectionResponse>{
        return await axios.post(this.URL+ '/updateProductInspection', dto)
            .then(res => {
                return res.data
            })
    }

    async getAll(): Promise<AllProductInspectionsResponse>{
        return await axios.post(this.URL+ '/getAllProductInspections')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveInfo(): Promise<AllProductInspectionsResponse>{
        return await axios.post(this.URL+ '/getAllActiveProductInspections')
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivate(dto: ProductInspectionDto): Promise<ProductInspectionResponse>{
        return await axios.post(this.URL+ '/activateOrDeactivateInspection', dto)
            .then(res => {
                return res.data
            })
    }

    async createSamples(dto:ProductInspectionDto):Promise<ProductInspectionResponse>{
        return await axios.post(this.URL+'/createSamples',dto)
          .then(res =>{
              return res.data
          })
    }

    async getAllProductInspectionSamplesForSampleId(dto: ProductInspecReq): Promise<ProductInspectionResponse> {
        return await axios.post(this.URL + '/getAllProductInspectionSamplesForSampleId', dto)
            .then(res => {
                return res.data
            })

        }
            

}