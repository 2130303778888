export class StrRtnCreateDto {
    saleOrderId: number;
    storeReturnDate: Date;
    itemCategoryId: number;
    itemId: number;
    returnQuantity:number;
    remarks:string;
    createdUser : string;
    approvar : string;
    storeConsumptionId: number;
    unitId:any
    returnReason:number;
}