import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, Descriptions, InputNumber, Modal, notification, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EndCustomersService, HrmsEmpDetService, MasterBrandService, PackingMethodService, ProductService, RackPositionService, RackService, SizesService, SkuService, UnitcodeService, ZoneService } from '@gtpl/shared-services/masters';
import { FGStockService, ForkLiftJobService, StockInService } from '@gtpl/shared-services/warehouse-management'
import { ProductionInventoryService } from '@gtpl/shared-services/production'
import './stock-in-form.css';
import moment from 'moment';
import { CategoriesInput, ForkliftJobcodesDropDownDto, ItemsDetails, PackTypeCategory, SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderItemsDTO, SaleOrderItemsInfo, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { CusAddressInfo, ProductDto, RackDropDownDto, SizesDropDownDto, SkuDto, ZoneDropDownDto, GetValueAdditionDataBySkuCodesRequest, Brands, UnitcodeDto, RackSuggestionResponseDto, RackDropDownResponseModel, HrmsEmployeeDetailsDto, PackingMethodDto } from '@gtpl/shared-models/masters';
import { FgStatusEnum, OperationTypeEnum, PackingStatus, PalletType, ProductCategoryDropDown, ProductCategoryEnum, ShiftsDropDown, ShiftsEnum, StockTypeDropDown, stockTypeEnum, StockTypeStatus, TransactionType, TransactionTypeDropDown } from '@gtpl/shared-models/common-models';
import { FgStockStatusEnum, ForkLiftJobRequestDTO, StockInDto, StockInRequestDto } from '@gtpl/shared-models/warehouse-management';
import { LotCodeDropDown, LotInformationRequest } from '@gtpl/shared-models/production-management';
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';
import { SizesForm } from '@gtpl/pages/master/master-components/sizes-form';
import { QrcodeOutlined } from '@ant-design/icons';
import { LotCodesDTO } from '@gtpl/shared-models/raw-material-procurement';
import ItemDetailsForm from './item-details';
import { BrandsInfo } from 'libs/shared-models/masters/src/lib/endCuctomer/brands-model';
import {CodeListMainService} from '@gtpl/shared-services/planning';
import TextArea from 'antd/lib/input/TextArea';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import StockinPrint from './stock-in-print';
import {StockService, StoreConsumptionService} from '@gtpl/shared-services/procurement'
const { Option } = Select;
/* eslint-disable-next-line */
export interface StockInFormModalViewProps { }

export function StockInFormModalView(
  props: StockInFormModalViewProps
) {

  let stockType = StockTypeDropDown;
  let transactionTypeDropDown = TransactionTypeDropDown;
  let shifts = ShiftsDropDown;
  let history = useHistory();
  let categories = ProductCategoryDropDown;
  let forkliftJobcodesDropDownDto =ForkliftJobcodesDropDownDto;
  const [form] = Form.useForm();
  const [hideColumns, setHideColumns] = useState<boolean>(false)
  const [hidePackStyle, setHidePackStyle] = useState<boolean>(false)
  const [hidejobType,setHidejobType] = useState<boolean>(true)
  const [itemValidation, setItemValidation] = useState<boolean>(false)
  const [hideRackColumns, setHideRackColumns] = useState<boolean>(false)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [saleOrderId, setSaleOrderId] = useState<number>(undefined)
  const [jobId,setJobId] = useState<number>(undefined)
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [hideForm, setHideForm] = useState<boolean>(true);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [packStyle, setPackStyle] = useState<PackingMethodDto[]>([]);
  const [stockStatus, setStockStatus] = useState<string>(undefined)
  const [saleOrderItemDetails, setSaleOrderItemDetails] = useState<SaleOrderItemsDTO>(undefined)
  const [saleOrderItemsData, setSaleOrderItemsData] = useState<any[]>([])
  const [saleOrderData, setSaleOrderData] = useState<any[]>([])
  const [itemCodeInfo, setItemCodeInfo] = useState<any[]>([])
  const [traceCodeList, setTraceCodeList] = useState<any[]>([])
  const [jobcodesData,setJobcodesData] = useState<ForkliftJobcodesDropDownDto[]>([])
  const [rackPostionsDropDown, setRackPostionsDropDown] = useState<any[]>([])
  const [zoneDropDown, setZoneDropDown] = useState<ZoneDropDownDto[]>([])
  const [modal, setModal] = useState('')
  const [saleOrderDetails, setSaleOrderDetails] = useState<SaleOrderDetailViewInfoDTO>(undefined)
  const [saleOrderItemsDetails, setSaleOrderItemsDetails] = useState<SaleOrderItemsDTO[]>(undefined)
  const [itemDetailsGridData, setItemDetailsGridData] = useState<any>(undefined)
  const [masterBrandData, setMasterBrandData] = useState<any>(undefined)
  const [varientId, setVarientId] = useState<number>(undefined)
  const [customerBrandsData, setCustomerBrandsData] = useState<BrandsInfo[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<number>(1);
  const [itemsKey, setItemsKey] = useState<number>(10);
  const [lotCodesDropDown, setLotCodesDropDown] = useState<string>(undefined)
  const [plantInfo, setPlantInfo] = useState<UnitcodeDto>(undefined)
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [isJobcodeRequired,setIsJobcodeRequired] = useState<boolean>(false);
  const [isTracecodeRequired,setIsTracecodeRequired] = useState<boolean>(true);
  const [isModalVisible,setIsModalVisible] = useState<boolean>(false);
  const [modalName,setModalName] = useState<string>('')
  const unitcodeService = new UnitcodeService();
  const saleOrderService = new SaleOrderService();
  const packingMethodService = new PackingMethodService();
  const zoneService = new ZoneService();
  const rackService = new RackService();
  const rackPositionService = new RackPositionService();
  const sizeService = new SizesService();
  const endcustomerservice = new EndCustomersService();
  const stockInService = new StockInService();
  const rMGrnService = new RMGrnService();
  const masterBrandService = new MasterBrandService();
  const forkliftService = new ForkLiftJobService();
  const codeListMainService = new CodeListMainService();
  const employeeService = new HrmsEmpDetService();
  const stockService = new StockService();
  const storeConsumptionService = new StoreConsumptionService();


  const [reportingLogId,setReportingLogId] = useState<any>()
  let createdUser = '';
  createdUser = localStorage.getItem('createdUser');
  let plant = '';
  plant = localStorage.getItem('unit_id');

  useEffect(() => {
    console.log(form.getFieldsValue());
    // getRacks(undefined);
    // getPlantById(plant);
    if(plant === "3" || plant === "2"){
      setHideRackColumns(true);
    }
    else{
      setHideRackColumns(false);
    }
    
    form.setFieldsValue({stockStatus:StockTypeStatus.SaleOrder});
    form.setFieldsValue({ packingCompleted: PackingStatus.YES })
    form.setFieldsValue({pouchesShortage : PackingStatus.NO})
    form.setFieldsValue({cartonsShortage : PackingStatus.NO})
    form.setFieldsValue({productCategory : ProductCategoryEnum.g1})
    setHideColumns(false);
    setItemValidation(true);
    // getSaleOrderData(localStorage.getItem("unit_id"));
    // getZones(); commented zones service bcz dropdown is hidden
    getWarehousePersonsData()
    // getLotCodes(localStorage.getItem("unit_id"));
    // getItemsData();
    // getSkuCodes();
    // getSizes()
    getRacks(undefined); //reserve logic commented
    getPackStyles(localStorage.getItem("unit_id"));

  }, []);

  const queryParams = new URLSearchParams(window.location.search)
  const getPackStyles = (unitId) => {
    packingMethodService.getAllActivePackingMethods().then((res) => {
      if (res.status) {
        setPackStyle(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPackStyle([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPackStyle([]);
    });
  }

  const getWarehousePersonsData = () => {
    employeeService.getActiveEmployeesByDept({employeeDept:"warehouse"}).then((res) => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setEmployeeData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeData([]);
    });
  }

  const addDataToItemDetails = (itemDetalFormdata) => {
    // console.log(itemDetalFormdata)
    setItemDetailsGridData(itemDetalFormdata)
    setIsUpdate(true);
  }

  const handleVarientId = (val) => {
    // console.log(val);
    setVarientId(val)
    // getRacks(val); //reserve logic commented
  }
  const mastesrBrandData = (masterBrand) => {
    // console.log(masterBrand);
    setMasterBrandData(masterBrand);


  }
  const getPlantById = (value) => {
    unitcodeService.getPlantDetailsById({unitcodeId:value}).then(res => {
      if (res.status) {
        // console.log(res.data);
        setPlantInfo(res.data);
        if(res.data.unitcodeId === 3){
          setHideRackColumns(true);
        }
      }
      else {
        setPlantInfo(undefined);
        setHideRackColumns(false);

      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantInfo(undefined);
      setHideRackColumns(false);

    });
  }
  const getLotCodes = (value) => {
    rMGrnService.getAllLotCodes({ unitcodeId: value }).then(res => {
      if (res.status) {
        setLotCodesDropDown(res.data[0].lotCode);
        form.setFieldsValue({ lotCode: res.data[0].lotCode })
        // getSaleOrderData();
      }
      else {
        setLotCodesDropDown(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotCodesDropDown(undefined);
    });
  }
  const getendCustomerAddress = (value) => {
    if(form.getFieldValue("stockStatus") === "anticipated"){
      endcustomerservice.getAllActiveBrands().then((res) => {
        if (res.status) {
          setCustomerBrandsData(res.data);
        } else {
          // if (res.intlCode) {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // } else {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // }
          setCustomerBrandsData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCustomerBrandsData([]);
      });
    }
    else{
      endcustomerservice.getEndCustomerDataById({endCustomerId:value,isActive:true}).then((res) => {
        if (res.status) {
          setCustomerBrandsData(res.data.brandsInfo);
        } else {
          // if (res.intlCode) {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // } else {
          //   AlertMessages.getErrorMessage(res.internalMessage);
          // }
          setCustomerBrandsData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCustomerBrandsData([]);
      });
    }
    
  }
  
  const getSaleOrderDetails = (reqData) => {
    // console.log("is it calling")
    saleOrderService.getSaleOrderDetailsById({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        // console.log(res.data);
        setSaleOrderDetails(res.data);
        // (res.data.itemData).filter(rec => ((moment(rec.createdAt).format("YYYY-MM-DD")) === (moment().format("YYYY-MM-DD")))?
        // setSaleOrderItemsDetails(rec):setSaleOrderItemsDetails(undefined))
        setSaleOrderItemsDetails(res.data.itemData)
        if(res.data.itemData.length === 1){
          form.setFieldsValue({saleOrderItemId:res.data.itemData[0].saleOrderItemId});
        }
        setItemsKey(preStateval => preStateval + 10);
        setSelectedCategory(res.data.category);
        setSelectedCurrency(res.data.currencyId);
        // getRacks(undefined) //reserve logic commented
        // if(res.data.category === PackTypeCategory.SKEWERS){
        //   setIsInnerBagsHidden(false)
        //   setIsInnerBagsizeHidden(false)
        // }
        // checkItemExist(res.data.itemData);
        getendCustomerAddress(res.data.endCustomerId)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderDetails(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderDetails(undefined);
    })
  }
  const getZones = () => {
    zoneService.getAllZoneDropDown().then((res) => {
      if (res.status) {
        // console.log(res.data);
        setZoneDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setZoneDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setZoneDropDown([]);
    });
  }
  const getRacks = (variantId) => {
    console.log("GetRacksService")
    setRackPostionsDropDown([])
    console.log(setRackPostionsDropDown)
    // form.setFieldsValue({rackPostion : null})
    let saleOrderId = form.getFieldValue("saleOrderId");
    let saleOrderItemId = form.getFieldValue("saleOrderItemId");
    let reportingCases = form.getFieldValue("reportingCases");
    // let packStyle = form.getFieldValue("packStyleId");
    let packStyle = form.getFieldValue("packStyleId");
    let stockStatus = form.getFieldValue("stockStatus");
    let plantId = Number(localStorage.getItem("unit_id"));
    console.log(packStyle);
    if(plantId != undefined && saleOrderId != undefined && saleOrderItemId != undefined && reportingCases != undefined && stockStatus != undefined){
      console.log(plantId);
        // {plantId!=2?form.setFieldsValue({rackPostion : undefined}):""} commented bcz the change is need to empty rack position when change PO for unit one plant also
        form.setFieldsValue({rackPostion : undefined})
        rackPositionService.getAvailableRackPositionsForPlantId({plantId:plantId,saleOrderId:saleOrderId,saleOrderItemId:saleOrderItemId,mastersCartons:reportingCases,stockTypeStatus:stockStatus,varientId:undefined,packStyleId:packStyle}).then((res) => {
          if (res.status) {
            console.log(res.data)
            setRackPostionsDropDown(res.data);
          }
          else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
              setRackPostionsDropDown([]);
            } else {
              setRackPostionsDropDown([]);
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setRackPostionsDropDown([]);
        });
    }
    // console.log(variantId);
    // console.log(form.getFieldsValue());
    // console.log(plantId+"***" +saleOrderId+"***" + saleOrderItemId+"***"+reportingCases+"***"+stockStatus+"*****"+variantId);
    // if(stockStatus === stockTypeEnum.SaleOrder){
      // if(plant === "2"){
      //   rackPositionService.getUnit1RackPositions({plantId:plantId}).then(res =>{
      //     if (res.status) {
      //       setRackPostionsDropDown(res.data);
      //     }
      //     else {
      //       if (res.intlCode) {
      //         AlertMessages.getErrorMessage(res.internalMessage);
      //         setRackPostionsDropDown([]);
      //       } else {
      //         setRackPostionsDropDown([]);
      //         AlertMessages.getErrorMessage(res.internalMessage);
      //       }
      //     }
      //   }).catch((err) => {
      //       AlertMessages.getErrorMessage(err.message);
      //       setRackPostionsDropDown([]);
      //   });
        
      // }
      // else{
        // if((plantId != undefined && saleOrderId != undefined && saleOrderItemId != undefined && reportingCases != undefined && reportingCases > 0 && stockStatus != undefined) || variantId != undefined){
        //   // console.log("hi");
        //   rackPositionService.reserveRackPosition({plantId:plantId,saleOrderId:saleOrderId,saleOrderItemId:saleOrderItemId,mastersCartons:reportingCases,stockTypeStatus:stockStatus,varientId:variantId}).then(res => {
        //     if (res.status) {
        //       // console.log(res);

        //       openReserveNotification(res.data1);
        //       setRackPostionsDropDown(res.data);
        //       form.setFieldsValue({rackPostion : res.data[0]?.rackPositionId})
        //       form.setFieldsValue({coldStorageId : res.data[0]?.zoneId})
        //     }
        //   else {
        //     if (res.intlCode) {
        //       AlertMessages.getErrorMessage(res.internalMessage);
        //       setRackPostionsDropDown([]);
        //     } else {
        //       setRackPostionsDropDown([]);
        //       AlertMessages.getErrorMessage(res.internalMessage);
        //     }
        //   }
        //   }).catch((err) => {
        //     AlertMessages.getErrorMessage(err.message);
        //     setRackPostionsDropDown([]);
        //   });
        // }
      // }

    // }
    // else{
    //   rackPositionService.getRackPositionsForDummy({plantId:Number(localStorage.getItem("unit_id")),varientId:varientId}).then(res => {
    //     if(res.status){
    //       setRackPostionsDropDown(res.data);
    //     }
    //     else {
    //       if (res.intlCode) {
    //         setRackPostionsDropDown([]);
    //         AlertMessages.getErrorMessage(res.internalMessage);
    //       } else {
    //         setRackPostionsDropDown([]);
    //         AlertMessages.getErrorMessage(res.internalMessage);
    //       }
    //     }
    //   }).catch((err) => {
    //     AlertMessages.getErrorMessage(err.message);
    //     setRackPostionsDropDown([]);
    //   });
    // }
    
  }
  console.log(reportingLogId,'props')
  const getDummySaleOrdersData = (value) => {
    saleOrderService.getDummySaleOrders({ plantId: value }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const getSaleOrderData = (value) => {
    saleOrderService.getMTOSaleOrders({ plantId: value }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const getSaleOrderItems = (reqData) => {
    // console.log("item details service");
    setSaleOrderItemDetails(undefined);
    setSaleOrderItemsData([]);
    saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        // console.log(res.data.length);
        setSaleOrderItemsData(res.data);
        if(form.getFieldValue('stockStatus') === "anticipated" || form.getFieldValue('stockStatus') === "dummy"){
          getendCustomerAddress(res.data[0].endCustomerId)
        }
        else if(form.getFieldValue('stockStatus') === "superDummy"){
          form.setFieldsValue({'packStyleId': res.data[0].packStyleId});
          form.setFieldsValue({'reportingCases': packStyle.find(rec => rec.packingMethodId === res.data[0].packStyleId).maxPalletCapacity});
        }
        
        if(res.data.length === 1){
          // console.log(reqData)
          form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId });
          setSaleOrderId(reqData);
          getSaleOrderItemDetails(res.data[0].saleOrderItemId);
          getRacks(undefined); //reserve logic commented
        }
        else{
          form.setFieldsValue({ saleOrderItemId: null });
        }
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemsData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData([]);
    })
  }
  const getcheckPalletCapacity = (reqData,stockStatus,varient) => {
    // console.log(reqData);
    packingMethodService.getPalletCapacity({noOfPouches:reqData.pouches,pouchesPerRow:reqData.pouchWeight,uomId:reqData.uomId}).then(res => {
      if(res.status){
        form.setFieldsValue({ reportingCases: res.data.palletCapacity });
        if(stockStatus === "dummy"){
          getRacks(varient) //reserve logic commented
        }
        else{
          getRacks(undefined) //reserve logic commented
        }
      }
      else {
        if (res.intlCode) {
          // console.log("hi")
          form.setFieldsValue({ reportingCases: 0 });
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          form.setFieldsValue({ reportingCases: 0 });
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData([]);
    })
  }
  const getSaleOrderItemDetails = (reqData) => {
    // console.log(saleOrderId)
    // console.log(reqData)
    // setSaleOrderItemDetails(undefined);
    saleOrderService.getSaleOrderItemDetails({ saleOrderId: form.getFieldValue("saleOrderId"), saleOrderItemId: reqData, stockType: form.getFieldValue("stockStatus") }).then(res => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderItemDetails(res.data);
        form.setFieldsValue({'packStyleId':res.data.packStyleId});
        if(Number(localStorage.getItem('unit_id')) != 2 && form.getFieldValue("stockStatus") != "superDummy"){
          form.setFieldsValue({ reportingCases: res.data.maxPalletCapacity });
        }
        if(form.getFieldValue("stockStatus") === "dummy"){
          getRacks(res.data.varientId) //reserve logic commented
        }
        else{
          getRacks(undefined) //reserve logic commented
        }
      
        // getcheckPalletCapacity(res.data,form.getFieldValue("stockStatus"),res.data.varientId);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemDetails(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemDetails(undefined);
    })
  }
  const getCodeList =(saleOrderId:number)=>{
    codeListMainService.geTracebilityCodeForSoId({saleOrderId:saleOrderId}).then((res) => {
      // console.log(res);
      if (res.status) {
        setTraceCodeList(res.data);
      } else {
        if (res.intlCode) {
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
        setTraceCodeList([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setTraceCodeList([]);
    })
  }
  const handleSaleOrder = (value) => {
    
    const transactionType = form.getFieldValue("transactionType");
    const stockType = form.getFieldValue("stockStatus");
    
    if ((stockType === "SaleOrder" || stockType === "superDummy") && transactionType != "rm") {
      form.setFieldsValue({ isDamaged: PackingStatus.NO });
    }
    else if((stockType === "dummy" || stockType === "Anticipated") && transactionType != "rm"){
      form.setFieldsValue({ isDamaged: PackingStatus.YES });
    }
    else{
      form.setFieldsValue({ isDamaged: PackingStatus.NO });
    }

    if (stockType === "SaleOrder" || stockType === "superDummy") {
      form.setFieldsValue({ saleOrderItemId: '' });
      form.setFieldsValue({ reportingCases: 0 });
      // form.setFieldsValue({ rackPostion: null });
      getCodeList(value);
    }
    else if(stockType === "dummy" || stockType === "Anticipated"){
      console.log("dummy Anticipated")
      // getSaleOrderItems(value); // items dropdown

      // getSaleOrderDetails(value)
    }
    getSaleOrderItems(value); // items dropdown

    setSaleOrderId(value);
    form.setFieldsValue({ code : null });

    // getSaleOrderDetails(value);

    // getRacks(undefined); //reserve logic commented
  }
  
  const saveData = (values: any) => {
    console.log(values);
    setBtnDisable(true)
    
    form.validateFields().then(res => {
      console.log(res);
      if ((res.stockStatus == "dummy" && hideColumns) || res.stockStatus == "Anticipated") {
      // if (res.stockStatus == "Anticipated") {
        // console.log("Dummy Entry");
        // itemform.validateFields().then(itemRes => {
          // console.log(itemRes);
        //   setBtnDisable(true);

        //   // if(res.stockStatus === StockTypeStatus.SaleOrder){
        //   //   res.saleOrderItemId = res.SaleOrderItemId;
        //   // }
        //   // else{
        //    res.saleOrderItemId = 0;
        //   // }

          // console.log(itemRes.masterBrandId);
        //   let saleOrderItemId = undefined
        //   if (isUpdate) {
        //     saleOrderItemId = saleOrderItemsDetails[0].saleOrderItemId;
        //   }
        //   const itemvalues = new SaleOrderItemsInfo(saleOrderItemId, res.saleOrderId, itemRes.itemId, itemRes.grade.minGrade, itemRes.grade.maxGrade, itemRes.foodTypeId, itemRes.valueAdditionOneId, itemRes.valueAdditionTwoId, itemRes.valueAdditionThreeId, itemRes.valueAdditionFourId, itemRes.valueAdditionFiveId, itemRes.packStyle.pouches, itemRes.cases, itemRes.caseWeight, itemRes.netWeight, itemRes.netAmount, res.createdUser, itemRes.packStyle.pouchWeight, 1, itemRes.Cost, itemRes.Brand, itemRes.WeighingType, itemRes.glazzing.minGlaze, itemRes.glazzing.maxGlaze, itemRes.soakingTime, itemRes.sockingStyleId, itemRes.netwgtcnt, itemRes.varientCode, itemRes.innerBags === undefined ? 0 : itemRes.innerBags, itemRes.shrimpWeight, itemRes.innerBagSizeId === undefined ? 0 : itemRes.innerBagSizeId, itemRes.skewerSizeItemId, itemRes.updatedUser, masterBrandData === undefined ? 1 : masterBrandData,0,itemRes.category,itemRes.requiredCount,itemRes.packStyleId);
          // console.log(itemvalues);
        //   const values = new StockInRequestDto(res.saleOrderId, res.saleOrderItemId, res.reportingCases, res.palletType, res.palletId, res.rackPostion, res.loosePouches, res.lotCode, res.transactionType,res.code, res.coldStorageId, res.plantId, res.shifts, res.packingCompleted, res.stockStatus, res.reportingDate, res.count, itemvalues, isUpdate, res.manualReported, createdUser, createdUser,res.cartonsShortage,res.pouchesShortage,res.productCategory,res.jobId);
          // console.log('values*************')
          // console.log(values);
        //   stockInService.reportStock(values).then((res) => {
        //     if (res.status) {
        //       AlertMessages.getSuccessMessage(res.internalMessage + ' with pallet code =' + res.data.palletCode);
        //       history.push("/fg-stock")
        //       // window.location.reload();
        //     } else {
        //       if (res.intlCode) {
        //         AlertMessages.getErrorMessage(res.internalMessage);
        //       } else {
        //         AlertMessages.getErrorMessage(res.internalMessage);
        //       }
        //     }
        //   }).catch((err) => {
        //     AlertMessages.getErrorMessage(err.message);
        //   })
        // }).catch((err) => {
          // console.log(err);
        //   AlertMessages.getErrorMessage(err.message);
        // });
      }
      else {
        setBtnDisable(true);
        console.log(res);
        const values = new StockInRequestDto(res.saleOrderId, res.saleOrderItemId, res.reportingCases, res.palletType, res.palletId, res.rackPostion ? res.rackPostion : 1, res.loosePouches, res.lotCode, res.transactionType,res.code, res.coldStorageId, res.plantId, res.shifts, res.packingCompleted, res.stockStatus, res.reportingDate, res.count, undefined, isUpdate, res.manualReported, createdUser, createdUser,res.cartonsShortage,res.pouchesShortage,res.productCategory,jobId,res.isDamaged,res.remarks,undefined,0,res.packStyleId)
        // console.log('values+++++++++++')
     
        stockInService.reportStock(values).then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            // openNotification(res.data.palletCode);
            setReportingLogId(res.data.reportingLogId)
            // window.location.reload();
            // history.push("/fg-stock")
            console.log(form.getFieldsValue());

            form.setFieldsValue({
              rackPostion: undefined,
              reportingCases:0
            })

            setBtnDisable(false);
            getRacks(undefined)

          } else {
            setBtnDisable(false);
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        })
      }
    }).catch((err) => {
      // console.log(err);
      AlertMessages.getErrorMessage(err.message);
    });

  }
  const getSOsAgainstJob =(value,transactionType)=>{
    forkliftService.getSosAgainstJob({jobId:value,transactionType:transactionType}).then((res) => {
      if(res.status){
        setSaleOrderData(res.data);
      }
      else{
        setSaleOrderData([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getPackingMaterialInfo =(value)=>{
    setSaleOrderItemsData([]);
    storeConsumptionService.getIssuedQty({itemCode:value,unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if(res.status){
        console.log(res.data);
        // form.setFieldsValue({saleOrderId: res.data[0].saleOrderId});
        // form.setFieldsValue({saleOrderItemId: res.data[0].saleOrderItemId});
        // form.setFieldsValue({reportingCases: res.data[0].consumedQuantity});
        // getSaleOrderItems(res.data[0].saleOrderId);
        // getCodeList(res.data[0].saleOrderId);
        setItemCodeInfo(res.data);
        setSaleOrderData(res.data);
        setSaleOrderItemsData(res.data);
        if(res.data.length === 1){
          form.setFieldsValue({'saleOrderItemId': res.data[0].saleOrderItemId});
          form.setFieldsValue({'saleOrderId': res.data[0].saleOrderId});
          form.setFieldsValue({'stockStatus': res.data[0].stockType});
          form.setFieldsValue({'transactionType': res.data[0].transactionType});

          getSaleOrderItemDetails(res.data[0].saleOrderItemId);
        }
        setHideForm(false);
      }
      else{
        setItemCodeInfo([]);
        setHideForm(true);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCodeInfo([]);
    });
  }
  const getJobsList =(value)=>{
    let plantId = Number(localStorage.getItem("unit_id"));
    form.setFieldsValue({jobId : null})
    form.setFieldsValue({saleOrderId:null})
    form.setFieldsValue({saleOrderItemId:null})
    console.log(plantId);
    if(value!=='rm' && value !== 'stockTransfer'){
      forkliftService.getForkliftJobCodes({unitId:plantId,transactionType:value}).then((res) => {
        if (res.status) {
          // console.log(res.data);
          if(res.data.length>0){
            setJobcodesData(res.data);
          }else{
            setJobcodesData([]);
          }
          
          setHidejobType(false);
          setIsJobcodeRequired(true);
        } else {
          setHidejobType(false);
          setIsJobcodeRequired(false);
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
         setJobcodesData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setJobcodesData([]);
      });
    }
    if(value=='stockTransfer'){
      forkliftService.getForkliftJobCodes({unitId:plantId,transactionType:'stockTransfer'}).then((res) => {
        if (res.status) {
          // console.log(res.data);
          if(res.data.length>0){
            setJobcodesData(res.data);
          }else{
            setJobcodesData([]);
          }
          
          setHidejobType(false);
          setIsJobcodeRequired(true);
        } else {
          setHidejobType(false);
          setIsJobcodeRequired(false);
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
         setJobcodesData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setJobcodesData([]);
      });
    }
    else{
      setHidejobType(true);
    }
  }
  const handleStockType = (value) => {
    console.log(value);
    form.setFieldsValue({'reportingCases': 0});
    if(value=='superDummy'){
      setHidePackStyle(true)
    }
    else{
      setHidePackStyle(false)
    }
    const transactionType = form.getFieldValue("transactionType");
    if((transactionType === "repacking" || transactionType === "reprocessing") && (value === "SaleOrder" || value === "superDummy")){
      getJobsList(transactionType);
    }
    else if((transactionType === "repacking" || transactionType === "reprocessing") && (value === "dummy" || value === "Anticipated")){
      getDummySaleOrdersData(localStorage.getItem("unit_id"));
    } 

    if ((value === "SaleOrder" || value === "superDummy") && transactionType != "rm") {
      form.setFieldsValue({ isDamaged: PackingStatus.NO });
    }
    else if((value === "dummy" || value === "Anticipated") && transactionType != "rm"){
      form.setFieldsValue({ isDamaged: PackingStatus.YES });
    }

    form.setFieldsValue({ saleOrderId: null })
    form.setFieldsValue({ saleOrderItemId: null })
    setSaleOrderItemDetails(undefined)
    setStockStatus(value);
    setStockStatus(value);
    if(value === StockTypeStatus.dummy || value === StockTypeStatus.Anticipated){
      setIsTracecodeRequired(false);
    }
    else if(value === StockTypeStatus.SaleOrder || value === StockTypeStatus.superDummy){
      setIsTracecodeRequired(true);
    }
    if(value === StockTypeStatus.superDummy || value === StockTypeStatus.Anticipated){
      form.setFieldsValue({pouchesShortage : PackingStatus.YES})
      form.setFieldsValue({cartonsShortage : PackingStatus.YES})
    }
    else{
      form.setFieldsValue({pouchesShortage : PackingStatus.NO})
      form.setFieldsValue({cartonsShortage : PackingStatus.NO})
    }
    if (value === StockTypeStatus.dummy || value === StockTypeStatus.Anticipated) {
      // setHideColumns(true);
      setItemValidation(false);
      form.setFieldsValue({ packingCompleted: PackingStatus.NO })
      getDummySaleOrdersData(localStorage.getItem("unit_id"));
    }
    else
      if (value == StockTypeStatus.SaleOrder) {
        // console.log(value + '===' + StockTypeStatus);
        form.setFieldsValue({ packingCompleted: PackingStatus.YES })
        setHideColumns(false);
        setItemValidation(true);
        // getSaleOrderData(localStorage.getItem("unit_id"));
      } else {
        form.setFieldsValue({ packingCompleted: PackingStatus.NO })
        setHideColumns(false);
        setItemValidation(true);
        // getSaleOrderData(localStorage.getItem("unit_id"));
      }
      getRacks(undefined); //reserve logic commented
  }
  const handleSaleOrderItem = (value) => {
    // console.log(`selected ${value}`);
    let saleOrder = saleOrderItemsData.find(rec => rec.saleOrderItemId === value).saleOrderId;
    console.log(saleOrder)
    form.setFieldsValue({'saleOrderId': saleOrder})
    getSaleOrderItemDetails(value);
  }
  const clearDependentFields = () => {
    form.setFieldsValue({ saleOrderId: null })
    form.setFieldsValue({ saleOrderItemId: null })
    form.setFieldsValue({ TransactionType: null })
    form.setFieldsValue({ jobId: null })
  }
  const handleColdStorage = (value) => {
    // getRacks(value);

  }
  const handlePackStyle = (value) => {
    console.log(value);
    console.log(packStyle.find(rec => rec.packingMethodId === value));
    form.setFieldsValue({'reportingCases': packStyle.find(rec => rec.packingMethodId === value).maxPalletCapacity});
    getRacks(undefined);
  }
  const onReset = () => {
    form.resetFields();
  };
  const openNotification = (content) => {
    notification.open({
      message: 'Pallet Code',
      description:
        'Stock updated with pallet code' + content,
      // duration: 0, 
      onClick: () => {
        // console.log('Notification Clicked!');
      },
    });
  };
  const openReserveNotification = (content) => {
    if(content != null){
      notification.open({
        message: '',
        description: content,
        // duration: 1,
        onClick: () => {
          // console.log('Notification Clicked!');
        },
      });
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
    getSaleOrderItems(saleOrderId)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getSaleOrderItems(saleOrderId)
  };
  const handleJobcodes =(content)=>{
    let transactionType = form.getFieldValue("transactionType");
    // if(transactionType!=='rm' && transactionType !== 'stockTransfer'){
      getSOsAgainstJob(content,transactionType);
    // }

    // console.log('content'+content)
    setJobId(content);
    
  }
  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      packingmaterial: value
    })
    getPackingMaterialInfo(value);

  }
  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }

  const printModalOpen =() => {
    
    setIsModalVisible(true); // model should be open
    setModalName('print')
  }

  return (
    <>
      <Card
        title={<span style={{ color: 'white' }}> Stock In</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        extra={
          <Link to="/fg-stock">
            <Button className="panel_button">View </Button>
          </Link>
        }

      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={saveData}
        >
          <Form.Item
            style={{ display: 'none' }}
            name="createdUser"
            initialValue={createdUser}
          >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="targetPosition" >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="manualReported" initialValue={true}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="currentPosition" >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="palletType" initialValue={PalletType.new}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="palletId" initialValue={1}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>
          
          <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:4}}>
            <Form.Item name="reportingDate" label = "Date" initialValue={moment()}
            rules={[
              {
                required: true, message: 'Missing Date',
              },
            ]}
            >
              <DatePicker showTime={{ format: "HH:mm" }} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
                name="packingMaterial"
                label="Packing Material"
                rules={[
                  {
                    required:true,
                    message: 'Packing Material must be required'
                  },
                
                ]}
              >

                <Input onChange={e => getPackingMaterialInfo(e.target.value)}/>
            </Form.Item>
            </Col>
          <Col span={3} style={{ paddingTop: '25px'}}>
                <Button htmlType='button' onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{ backgroundColor:'#37f6d9cc', color: '#1890ff', width: '120px' }} >
                  Scan
                </Button>
          </Col>
          </Row><br/>
          <Row gutter={24} hidden={hideForm}>
          
          <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
              <Form.Item
                name="transactionType"
                label="Transaction Type"
                initialValue={TransactionType.rm}
                rules={[
                  {
                    required: true, message: 'Missing Transaction Type',
                  },
                ]}

              >
                <Select
                  // placeholder="Select Transaction Type"
                   onChange={getJobsList}
                  // defaultValue="rm"
                  allowClear
                  onClear={clearDependentFields}
                  disabled
                >
                  {transactionTypeDropDown.map(transactionType => {
                    return <Option value={transactionType.value}>{transactionType.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            {  !hidejobType  ?
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>

              <Form.Item
                name="jobId"
                label="Job Codes"
                rules={[
                  {
                    required: isJobcodeRequired, message: 'Missing Job code',
                  },
                ]}
              >
                <Select
                  placeholder="Select Jobcode"
                  onChange={handleJobcodes}
                  allowClear
                  showSearch
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>Please select</Option>
                  {jobcodesData.map(jobcodesDropdown => {
                    return <Option value={jobcodesDropdown.jobId}>{jobcodesDropdown.jobCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>:""}
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
              <Form.Item
                name="stockStatus"
                label="Stock Type"
                rules={[
                  {
                    required: true, message: 'Missing Stock Type',
                  },
                ]}

              >
                <Select
                  placeholder="Select Stock Type"
                  onChange={handleStockType}
                  allowClear
                  onClear={clearDependentFields}
                  disabled
                >
                  <Option value="0">Please select</Option>
                  {stockType.map(stockType => {
                    return <Option value={stockType.value}>{stockType.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}  hidden>
              <Form.Item name="lotCode" label="Lot Code"
                rules={[
                  {
                    required: false,
                    message: 'Missing Lot Code',
                  },
                ]} initialValue="LOT-DUMMY/01/01/0001">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>

              <Form.Item
                name="saleOrderId"
                label="Customer PO"
                rules={[
                  {
                    required: true, message: 'Missing Sale Order',
                  },
                ]}
              >
                <Select
                  placeholder="Select Sale Order"
                  onChange={handleSaleOrder}
                  allowClear
                  showSearch
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  // disabled
                >
                  {saleOrderData.map(saleOrderDropDown => {
                    return <Option value={saleOrderDropDown.saleOrderId}>{saleOrderDropDown.saleOrderNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            
            {
              !hideColumns ?
                <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:12}} xl={{span:12}}>
                  <Form.Item
                    name="saleOrderItemId"
                    label="SO Item"
                    rules={[
                      {
                        required: itemValidation, message: 'Missing Sale Order Item',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Sale Order Item"
                      onChange={handleSaleOrderItem}
                      allowClear
                      showSearch
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      // disabled
                    >
                      <Option value={null}>select SO Item</Option>
                      {saleOrderItemsData.map(saleOrderDropDown => {
                        return <Option value={saleOrderDropDown.saleOrderItemId}>{saleOrderDropDown.saleOrderNumber + ' - ' + saleOrderDropDown.productCode}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                : ""
            }
            {
              hidePackStyle?
              <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
                <Form.Item name="packStyleId" label="Pack Style"
                  rules={[
                    {
                      required: hidePackStyle, message: 'Missing Pack Style',
                    },
                  ]}>
                  <Select
                    placeholder="Select Pack Style"
                    onChange={handlePackStyle}
                    allowClear
                  >
                    {packStyle?.map(dropdown => {
                      return <Option value={dropdown.packingMethodId}>{dropdown.packingMethodName}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              :""
            }
        
            {
              (form.getFieldValue("stockStatus") === StockTypeStatus.dummy || form.getFieldValue("stockStatus") === StockTypeStatus.Anticipated) && form.getFieldValue("saleOrderId")?
                <Col span={4} style={{paddingTop:'30px'}}>
                  <Button type="primary" hidden={false} onClick={() =>{setIsModalVisible(true); setModalName('items')}} style={{ backgroundColor: '#40a9ff' }}>+Add New Product</Button>
                </Col>
              :""
            }
            <Modal
              title="Add Product"
              key={'modal' + Date.now()}
              width={'80%'}
              style={{ top: 30, alignContent: 'right' }}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={[
              ]}>
             { modalName == 'items' ? <ItemDetailsForm key={itemsKey} mastesrBrandData={mastesrBrandData} 
              // getVarientId={handleVarientId} 
              brandsData={customerBrandsData} category={selectedCategory} 
              // initialData={saleOrderItemsDetails} 
              soStatus={saleOrderDetails?.soStatus} packCategory={saleOrderDetails?.category} currency={selectedCurrency} updateCurrency={saleOrderDetails?.currencyId} stockStatus={stockStatus} saleOrderId={form.getFieldValue("saleOrderId")} closeModal={handleOk} varientsData={saleOrderItemsData} /> : <StockinPrint  reportingLogId={reportingLogId}/>
            }</Modal>
            {
              isTracecodeRequired?
              <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>

              <Form.Item
                name="code"
                label="Trace Code"
                rules={[
                  {
                    required: false, message: 'Missing Trace Code',
                  },
                ]}
              >
                <Select
                  placeholder="Select Trace Code"
                  // onChange={handleSaleOrder}
                  allowClear
                  showSearch
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>select Trace Code</Option>
                  {traceCodeList.map(res => {
                    return <Option value={res.tracebilityCodeId}>{res.tracebilityCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            :""
            }
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="isDamaged" label="Is Damaged?"
                rules={[
                  {
                    required: true, message: 'Missing Damage Status',
                  },
                ]}
                initialValue={PackingStatus.NO}
              >
                <Select
                  placeholder="Select Damage Status"
                  allowClear
                  defaultValue={PackingStatus.NO}
                  disabled
                >
                  <Option value="null">Select Damage Status</Option>
                  <Option value={PackingStatus.NO}>{PackingStatus.NO}</Option>
                  <Option value={PackingStatus.YES}>{PackingStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>
            
          {/* </Row> */}
          

          {!hideColumns && saleOrderItemDetails != undefined ?
            <Row gutter={24}>
              {saleOrderItemDetails ?
                <Descriptions column={{ xxl: 4, xl: 3, lg: 4, md: 3, sm: 2, xs: 2 }}>
                  {/* <Descriptions.Item 
                  label="Brand"
                  >
                    {saleOrderItemDetails.brand ? saleOrderItemDetails.brand : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Product">
                    {saleOrderItemDetails.product ? saleOrderItemDetails.product : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Varient">
                    {saleOrderItemDetails.varient ? saleOrderItemDetails.varient : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Grade">
                    {saleOrderItemDetails.minGrade ? saleOrderItemDetails.minGrade + '-' + saleOrderItemDetails.maxGrade : ''}
                  </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Uom" >
                    {saleOrderItemDetails.uom ? saleOrderItemDetails.uom : ''}
                  </Descriptions.Item> */}
                   <Descriptions.Item  label="Brand" style={{paddingLeft:'15px'}} >
                    {saleOrderItemDetails.brand ? saleOrderItemDetails.brand: ''}
                  </Descriptions.Item>
                  {/* <Descriptions.Item  label="Pack Style" style={{paddingLeft:'15px'}} >
                    {saleOrderItemDetails.packingStyle ? saleOrderItemDetails.packingStyle + " " + saleOrderItemDetails.uom : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Glazing">
                    {saleOrderItemDetails.maxGlaze ? saleOrderItemDetails.maxGlaze + '-' + saleOrderItemDetails.maxGlaze : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Soak Style" style={{paddingLeft:'15px'}}>
                    {saleOrderItemDetails.soakingStyle ? saleOrderItemDetails.soakingStyle : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Soak Time" style={{paddingLeft:'15px'}}>
                    {saleOrderItemDetails.soakingTime ? saleOrderItemDetails.soakingTime : ""}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Cases Req.">
                    {saleOrderItemDetails.jobCases ? saleOrderItemDetails.jobCases : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Reported Cases" style={{paddingLeft:'15px'}}>
                    {saleOrderItemDetails.reportedCases ? saleOrderItemDetails.reportedCases : 0}
                  </Descriptions.Item>
                  <Descriptions.Item label="Pallet Capacity" style={{paddingLeft:'15px'}}>
                    {saleOrderItemDetails.palletCapacity ? saleOrderItemDetails.palletCapacity : 0}
                  </Descriptions.Item>
                </Descriptions>
                : ""}
            </Row>
            : ""}
          {/* {
            hideColumns && saleOrderId > 0 ?
              // <Row gutter={24}>
                <ItemDetailsForm key={itemsKey} form={itemform} addDataToItemDetails={addDataToItemDetails} mastesrBrandData={mastesrBrandData} getVarientId={handleVarientId} brandsData={customerBrandsData} category={selectedCategory} initialData={saleOrderItemsDetails} soStatus={saleOrderDetails?.soStatus} packCategory={saleOrderDetails?.category} currency={selectedCurrency} updateCurrency={saleOrderDetails?.currencyId} stockStatus={stockStatus} />
              // </Row> 
              : ""
          } */}

          {/* <Row gutter={24}> */}
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="coldStorageId" label="Cold Storage"
                rules={[
                  {
                    required: false, message: 'Missing Cold Storage',
                  },
                ]}>
                <Select
                  placeholder="Select Cold Storage"
                  onChange={handleColdStorage}
                  allowClear
                >
                  {zoneDropDown?.map(dropdown => {
                    return <Option value={dropdown.zoneId}>{dropdown.zoneName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            
            
            <Col xs={{span:12}} sm={{span:12}} md={{span:4}} lg={{span:3}} xl={{span:6}}>
              <Form.Item name="reportingCases" label="Reporting Cases"

                rules={[
                  {
                    required: true,
                    message: 'Missing Reporting Cases',
                    validator: (_, value) =>
                      value >= 0 ? Promise.resolve() : Promise.reject(new Error('Enter only positive values')),
                  },
                ]}>
                <Input type='number' min={(1)}
                onBlur={e => plant === '3' ? getRacks(varientId):null} //reserve logic commented
                />
              </Form.Item>
            </Col>
            
            <Col xs={{span:12}} sm={{span:12}} md={{span:4}} lg={{span:3}} xl={{span:6}}>
              <Form.Item name="loosePouches" label="Loose Pouches"
                initialValue={0}>
                <Input type='number' />
              </Form.Item>
            </Col>
            {
              hideRackColumns?
                <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
                  <Form.Item
                    name="rackPostion"
                    label="Rack Position"
                    rules={[
                      {
                        required: true, message: 'Missing Rack Position',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Rack Position"
                      // onChange={handleSaleOrder}
                      allowClear
                      showSearch
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={undefined}>Select Rack Position</Option>
                      {rackPostionsDropDown.map(res => {
                        return <Option value={res.rackPositionId}>{res.rackCode}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>:""
            }
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="packingCompleted" label="Packing Completed"
                rules={[
                  {
                    required: true, message: 'Missing Packing Status',
                  },
                ]}
              // initialValue={PackingStatus.NO}
              >
                {/* <Input/> */}
                <Select
                  placeholder="Select packing Status"
                  allowClear
                  defaultValue={PackingStatus.NO}
                >
                  <Option value="null">Select Packing Status</Option>
                  <Option value={PackingStatus.NO}>{PackingStatus.NO}</Option>
                  <Option value={PackingStatus.YES}>{PackingStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="pouchesShortage" label="Is Pouches Shortage?"
                rules={[
                  {
                    required: true, message: 'Missing Pouches Status',
                  },
                ]}
              >
                <Select
                  placeholder="Select Pouches Status"
                  allowClear
                  defaultValue={PackingStatus.NO}
                >
                  <Option value="null">Select Pouches Status</Option>
                  <Option value={PackingStatus.NO}>{PackingStatus.NO}</Option>
                  <Option value={PackingStatus.YES}>{PackingStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="cartonsShortage" label="Is Cartons Shortage?"
                rules={[
                  {
                    required: true, message: 'Missing Cartons Status',
                  },
                ]}
              >
                <Select
                  placeholder="Select Cartons Status"
                  allowClear
                  defaultValue={PackingStatus.NO}
                >
                  <Option value="null">Select Cartons Status</Option>
                  <Option value={PackingStatus.NO}>{PackingStatus.NO}</Option>
                  <Option value={PackingStatus.YES}>{PackingStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}} hidden>
              <Form.Item name="count" label="Grade"
                rules={[
                  {
                    required: false,
                    message: 'Missing Grade',
                    validator: (_, value) =>
                      value >= 0 ? Promise.resolve() : Promise.reject(new Error('Enter only positive values')),
                  },
                ]} initialValue={0}>
                <Input type='number'
                />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
              <Form.Item
                name="productCategory"
                label="Product Category"
                rules={[
                  {
                    required: true, message: 'Missing Product Category',
                  },
                ]}
              >
                <Select
                  placeholder="Select Product Category"
                  allowClear
                >
                  {categories.map(item => {
                    return <Option value={item.value}>{item.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
              <Form.Item
                name="shifts"
                label="Shift"
                rules={[
                  {
                    required: true, message: 'Missing Shift',
                  },
                ]}
                initialValue={shifts[0].value}
              >
                <Select
                  placeholder="Select Shifts"
                  allowClear
                >
                  {shifts.map(shift => {
                    return <Option value={shift.value}>{shift.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
              <Form.Item name="whIncharge" label="WH Incharge"
                rules={[
                  {
                    required: true, message: 'Missing WH Incharg',
                  },
                ]}
              >
                 <Select
                  placeholder="Select WH Incharge"
                  // onChange={handleSaleOrder}
                  allowClear
                  showSearch
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employeeData.map(dropData => {
                  return <Option value={dropData.employeeName}>{`${dropData.employeeName}`}</Option>
                })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name="remarks" label="Remarks">
                    <TextArea showCount  />
                </Form.Item>
            </Col>
          </Row>
          <Row>
          <Col>
            <Button type="primary" htmlType="submit" block disabled={btnDisable}>
              Submit
            </Button>
            </Col>
            <Col>
            <Button
              htmlType="button"
              style={{ margin: '0 14px' }}
              onClick={onReset}
            >
              Reset
            </Button>
          </Col>
          <Col hidden={reportingLogId?false:true}>
            <Button type="primary" onClick={printModalOpen} >
              Print previous transaction
            </Button>
            </Col>
          </Row>
        </Form>
        <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
        }

      </Modal>
      </Card>
    </>
  );
}

export default StockInFormModalView;
