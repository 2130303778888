import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col, DatePicker, InputNumber, AutoComplete, Descriptions, Collapse, TimePicker, Radio, Table  } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import './inspection-form.css'; 
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDto, SODto, SoRequest, BomPackingItemsDto, FactoriesInput, SoNumbersModel, CrrencySymbols, ItemsDetails, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ConditionInput, ShiftsEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { SoItemDetailsBySoIdDTO } from 'libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto';
import { ApprovalInput } from '@gtpl/shared-models/common-models';
import { LotCodeDropDown, ProductInspecReq, ProductInspectionDto } from '@gtpl/shared-models/production-management';
import { LotCodeOpsService, ProductInspecService } from '@gtpl/shared-services/production';
import { GrnVehicleInfo } from '@gtpl/shared-models/raw-material-procurement';

const { TextArea } = Input;
const { Option } = Select;
export interface InspectionFormProps {
  isUpdate:boolean;
  closeForm: () => void;
  inspectionData:ProductInspectionDto
}

export function InspectionForm(props: InspectionFormProps) {
  const { Panel } = Collapse;
  const history = useHistory();
  const [form] = Form.useForm();
  const soService = new SaleOrderService;
  const [soItemName, setSoItemName] = useState<ItemsDetails[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [soItemDetails, setSoItemDetails] = useState<SoItemDetailsBySoIdDTO>(undefined);
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const saleOrderService = new SaleOrderService();
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const lotCodeService = new LotCodeOpsService();
  const [samplesInfo] = Form.useForm();
  const [disable,setDisable] = useState<boolean>(false);
  const inspectionService = new ProductInspecService;
  const [gridData,setGridData] = useState<ProductInspectionDto[]>([])
  const plainOptions = ['RAW IQF','COOKING IQF','BLOCK',]
  const [certification, setCertification] = useState<boolean>(false);
  const selectBefore = (
    <Select defaultValue="Nil" className="select-before">    
      <Option value="Nil">N</Option>
      <Option value="Good">G</Option>
      <Option value="Saticifactory">SF</Option>
      <Option value="Not Applicable">NA</Option>
      <Option value="Yes">Y</Option>
      <Option value="Pcs">Pc</Option>
    </Select>
  );

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
    const onReset=()=>{
    form.resetFields();
   }

  const columns = [
    {
      title: 'Sample',
      dataIndex: 'sampleNo',
      key: 'sampleNo',
    },
    // {
    //   title: 'Time',
    //   dataIndex: 'time',
    //   key: 'time',
    // },
    {
      title: 'Temperature',
      dataIndex: 'temperature',
      key: 'temperature',
    },
    {
      title: 'General Appearance',
      dataIndex: 'generalAppearance',
      key: 'generalAppearance',
    },
    {
      title: 'General Odour',
      dataIndex: 'generalOdour',
      key: 'generalOdour',
    },
    {
      title: 'Freshness',
      dataIndex: 'freshness',
      key: 'freshness',
    },
    {
      title: 'Dehydration',
      dataIndex: 'dehydration',
      key: 'dehydration',
    },
    {
      title: 'Discolouration',
      dataIndex: 'discoloration',
      key: 'discoloration',
    },
    {
      title: 'Deterioration',
      dataIndex: 'deterioration',
      key: 'deterioration',
    },
    {
      title:'Soft/semi soft/improper',
      dataIndex:'peeling',
      key:'peeling'
    },
    {
      title: 'Hanging meat/Th meat',
      dataIndex: 'hangingMeat',
      key: 'hangingMeat',
    },
    {
      title: 'BlackSpot/Tail',
      dataIndex: 'blackSpot',
      key: 'blackSpot',
    },
    {
      title: 'Broken/Bkn tail/uncut',
      dataIndex: 'broken',
      key: 'broken',
    },
    {
      title: 'Uncut/button hole',
      dataIndex: 'wrongCut',
      key: 'wrongCut',
    },
    {
      title: 'LEges/veins/shells',
      dataIndex: 'shells',
      key: 'shells',
    },
    {
      title: 'Foreign Matter',
      dataIndex: 'foreignMatter',
      key: 'foreignMatter',
    },
    {
      title: 'Uniformity(Big/samll)',
      dataIndex: 'uniformity',
      key: 'uniformity',
    },
    {
      title: 'Uniformity Ratio',
      dataIndex: 'uniformityRatio',
      key: 'uniformityRatio',
    },
    {
      title: 'Product Met spec-Y/M/N',
      dataIndex: 'productMetSpec',
      key: 'productMetSpec',
    },
    {
      title: 'Best before date',
      dataIndex: 'bestBefore',
      key: 'bestBefore',
    },
   
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    }
  ];

  useEffect(() => {
    getSaleOrders()
    getAllProductInspectionSamplesForSampleId();
    if(props.isUpdate){
      form.setFieldsValue({
        inspectionId : props.inspectionData.inspectionId,
        date : moment(props.inspectionData.date),
        saleOrderId : props.inspectionData.saleOrderId,
        saleOrderItemId : props.inspectionData.saleOrderItemId,
        lotNumber : props.inspectionData.lotNumber,
        section : props.inspectionData.section
      })
    }
  }, [])

  const getSaleOrders = () => {
    setSoItemDetails(undefined);
    lotCodeService.getSaleOrders().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getAllSoItemsDropDown = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    console.log(saleOrderId)
    form.setFieldsValue({
      saleOrderItemId: ''
    })
    const soREq: saleOrder = {
      saleOrderId: saleOrderId
    }
    lotCodeService.getSaleOrderItemsForSo(soREq).then((res) => {
      if (res.status) {
        setSoItemName(res.data);
      } else {
        AlertMessages.getErrorMessage("Something went wrong");
        setSoItemName([]);
      }
    })
      .catch((err) => {
        setSoItemName([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };


  const getSaleOrderItemDetails = () => {
    setSoItemDetails(undefined);
    const saleOrderId = form.getFieldValue('saleOrderId');
    const saleOrderItemId = form.getFieldValue('saleOrderItemId');
    saleOrderService.getSaleOrderItemDetailsBySoItemId({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId }).then((res) => {
      if (res.status) {
        setSoItemDetails(res.data);
      }
      else {
        if (res.intlCode) {
          setSoItemDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoItemDetails(null);
        AlertMessages.getErrorMessage(err.message);
      });
  }

  const getAllLotNumbersForSoItem = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    const saleOrderItemId = form.getFieldValue('saleOrderItemId');
    lotCodeService.getAllLotNumbersForSoItem({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId, plantId: Number(localStorage.getItem('unitId')) }).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const createSample=()=>{
    setDisable(true)
    form.validateFields().then(mainForm => {
      samplesInfo.validateFields().then(inspectionSamples => {
        mainForm.samplesInfo = (props.isUpdate) ? [...dataSource, inspectionSamples] : [inspectionSamples];
    inspectionService.createSamples(mainForm).then(res => {
      setDisable(false)
      if (res.status) {
        if(props.isUpdate){
          getAllProductInspectionSamplesForSampleId()
        }
       history.push('/fg-inspection-print');
        AlertMessages.getSuccessMessage('Created Successfully');
        
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
        })
      })
    })
 }

 const certificationChange = (value) => {
  if (value) {

  }
}

const getAllProductInspectionSamplesForSampleId = () => {
  const request = new ProductInspecReq();
  request.inspectionId = props.inspectionData?.inspectionId
  inspectionService.getAllProductInspectionSamplesForSampleId(request).then(res => {
    if (res.status) {
      setDataSource(res.data.samplesInfo);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}

const uniformRatioCal = () => {
  const big = samplesInfo.getFieldValue('uniformityBig');
  const small = samplesInfo.getFieldValue('uniformitySmall');
  const ratio = big/small
  samplesInfo.setFieldsValue({
    uniformityRatio:ratio.toFixed(2)
  })
}

  return (
  

    <Card title={<span style={{color:'white'}}>PD-Inspection</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/inspection-print' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>} >
          {props.isUpdate?
           <Descriptions style={{ display: (props.isUpdate) ? 'unset' : 'none' }}>
        <Descriptions.Item label="Inspection Date">
          {props.inspectionData?.date ? moment(props.inspectionData?.date).format("DD-MM-YYYY") : ''}
        </Descriptions.Item>
        <Descriptions.Item label="SO Number">
          {props.inspectionData.soNumber}
        </Descriptions.Item>
        <Descriptions.Item label="SO Item">
          {props.inspectionData.item}
        </Descriptions.Item>
        <Descriptions.Item label="Lot No">
          {props.inspectionData.lotNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Section">
          {props.inspectionData?.section ? props.inspectionData?.section : ''}
        </Descriptions.Item>
      </Descriptions> : <span> </span>
}
          <Form form={form}        
           name="control-hooks"          
          layout="vertical"
          onFinish={createSample}
          >
        <div style={{ display: props.isUpdate ? 'none' : 'block' }}>
          <Form.Item name="inspectionId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
          <Input hidden/>
        </Form.Item>
        <Row gutter={24}>
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="date"
                      label="Date "
                      initialValue={moment()}
                      >
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
            </Col>       
            
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>

              <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: true, message: 'Missing SO Number' }]}>
                <Select
                  placeholder="Select SO Number"
                  allowClear
                  onChange={getAllSoItemsDropDown}
                  showSearch
                >
                  {saleOrderData.map((dropData) => {
                    return (
                      <Option
                        name="saleOrderId"
                        key={dropData.saleOrderId}
                        value={dropData.saleOrderId}
                      >
                        {dropData.saleOrderNumber}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>            

            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item name="saleOrderItemId" label="SO Item" rules={[{ required: true, message: 'Missing SO Number' }]}>
              <Select
                placeholder="Select SO Item"
                onChange={getSaleOrderItemDetails}
                onSelect={getAllLotNumbersForSoItem}
                allowClear
              >
                {soItemName.map((dropData) => {
                  return (
                    <Option
                      name="saleOrderItemId"
                      key={dropData.saleOrderItemId}
                      value={dropData.saleOrderItemId}
                    >
                      {dropData.item}
                    </Option>
                  );
                })}

              </Select>
            </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>

                  <Form.Item name="lotNumber" label="Lot No" rules={[{ required: true, message: 'Missing Lot No' }]}>
                    <Select
                      placeholder="Select Lot No"
                      allowClear
                    >
                      <Option key={0} value={null}>Select Lot</Option>
                      {lotNumbers.map((lot) => {
                        return <Option key={lot.lotCodeOpsId} value={lot.lotNumber}>{lot.lotNumber}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  </Col>
                            
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                  <Form.Item
                    name={"section"}
                    label="Section"
                    rules={[
                      {
                        required: true,
                        message: `Section`,
                      },
                    ]}
                  >
                    <Radio.Group options={plainOptions} onChange={certificationChange} value='RAW IQF'>
                      <Radio value={4}>
                        More...
                        {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                </Row>
                </div>
                <Row style={{ display: (soItemDetails) ? 'unset' : 'none' }}>
      <Descriptions column={3}>
        <Descriptions.Item label="Brand">
          {soItemDetails?.brand? soItemDetails.brand:''}
        </Descriptions.Item> 
        <Descriptions.Item label="Grade">
          {soItemDetails?.grade? soItemDetails.grade:''}
        </Descriptions.Item>
        <Descriptions.Item label="Pack Style">
          {soItemDetails?.packingStyle? soItemDetails.packingStyle:''}
        </Descriptions.Item>
        <Descriptions.Item label="Variety">
          {soItemDetails?.variety?soItemDetails.variety:''}
        </Descriptions.Item>
        <Descriptions.Item label="Species">
          {soItemDetails?.specie? soItemDetails.specie:''}
        </Descriptions.Item>
      </Descriptions>
    </Row>
        
           
        <Form 
          form={samplesInfo}               
          layout="vertical"
        >   
        <Collapse >
          <Panel header="Mandatory" key="1" style={{ backgroundColor: '#69c0ff', border: 0 ,width:'100%'}} showArrow={true}> 
        
          <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name={"sampleNo"}
                label="Sample No"
                rules={[
                  {
                    required: true,
                    message: `Missing Sample No`,
                  },
                ]}
                initialValue={dataSource.length + 1}
              >
                <InputNumber min={0} style={{ width: '100%' }} disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                    name={"time"}
                    label="Time"
                    rules={[
                      {
                        required: true,
                        message: `Missing Start Time`,
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <TimePicker use12Hours format="h:mm a" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                    <Form.Item
                        name="temperature"
                        label="Temperature (<4.0 C)"
                        rules={[
                          {
                            required: true,
                            message: `Missing Temperature`,
                          },
                        ]}
                      >
                        <InputNumber min={0} max={4} style={{ width: '100%' }} />
                      </Form.Item>
              </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="fillingWeight"
                      label="Filling Weight(Gm)"
                      rules={[
                        {
                          required: false,
                          message: `Missing Filling Weight`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="frozenWeight"
                      label="Frozen Weight(Gm)"
                      rules={[
                        {
                          required: false,
                          message: `Missing Frozen Weight`,
                        },
                      ]}

                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="deglazedWeight"
                      label="Deglazed Weight(Gm)"
                      rules={[
                        {
                          required: false,
                          message: `Missing Deglazed Weight`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="netWeight"
                      label="Net Weight"
                      rules={[
                        {
                          required: false,
                          message: `Missing Net Weight`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="subSampleWeight"
                      label="Sub Sample Weight"
                      rules={[
                        {
                          required: false,
                          message: `Missing Sub Sample Weight`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="frozenCount"
                      label="Frozen Count"
                      rules={[
                        {
                          required: false,
                          message: `Missing Frozen count`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="deglazedCount"
                      label="Daglazer Count"
                      rules={[
                        {
                          required: false,
                          message: `Missing Deglazer Count`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>       
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="netCount"
                      label="Net Count"
                      rules={[
                        {
                          required: false,
                          message: `Missing Net Count`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col> 
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="totalPieces"
                      label="Total Pieces"
                      rules={[
                        {
                          required: false,
                          message: `Missing Total pieces%`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="glazePercent"
                      label="Glaze %"
                      rules={[
                        {
                          required: false,
                          message: `Missing gleaze %`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>	
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="totalOunce"
                      label="Total Ounce"
                      rules={[
                        {
                          required: false,
                          message: `Missing Toatal ounce`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="cluster"
                      label="Cluster >2pcs/2pcs"
                      rules={[
                        {
                          required: false,
                          message: `Missing Cluster`,
                        },
                      ]}
                      >
                      <Input/>
                    </Form.Item>
            </Col>
            </Row>
            </Panel>
            </Collapse>
          
        
        <Collapse >
          <Panel header="Sample Info" key="1" style={{ backgroundColor: '#69c0ff', border: 0 ,width:'100%'}} showArrow={true}> 
        
          <Row gutter={50}>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
        <Form.Item 
          label="General Appearance" 
          name={'generalAppearance'} initialValue={ConditionInput[0].value} 
          rules={[
                {
                  required: true,
                  message: `Missing General Appearance`,
                },
              ]}>
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
      <Form.Item label="General Odour" name={'generalOdour'} initialValue={ConditionInput[0].value} 
      rules={[
                {
                  required: true,
                  message: `Missing General Odour`,
                },
              ]}>
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
      <Form.Item label="Freshness" name={'freshness'} initialValue={ConditionInput[0].value} rules={[
                {
                  required: true,
                  message: `Missing Freshness`,
                },
              ]}>
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
			<Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
      <Form.Item label="Texture" name={'texture'} initialValue={ConditionInput[2].value} rules={[
                {
                  required: true,
                  message: `Missing Texture`,
                },
              ]}>
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="dehydration" 
              label="Dehydration" 
              rules={[{ required: true, message: 'Dehydration' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={1} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="discoloration" 
              label="Discolouration " 
              rules={[{ required: true, message: 'Discolouration ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="deterioration" 
              label="Deterioration " 
              rules={[{ required: true, message: 'Deterioration ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="peeling" 
              label="Soft/Semi Soft/Improper Peeling" 
              rules={[{ required: true, message: 'Soft/Semi Soft/ Improper Peeling ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="hangingMeat" 
              label="Hanging Meat/ The Meat " 
              rules={[{ required: true, message: 'Hanging Meat/ The Meat ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="blackSpot" 
              label="Black Tail/Black Spot" 
              rules={[{ required: true, message: 'Black Tail/Black Spot is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>

            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="broken" 
              label="Broken/Bkn Tai/Un use " 
              rules={[{ required: true, message: 'Broken/Bkn Tai/Un use ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="wrongCut" 
              label="Un Cut/ Button hole  " 
              rules={[{ required: true, message: 'Un Cut/ Button hole  ' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="shells" 
              label="Legs/Veins/Shells " 
              rules={[{ required: true, message: 'Legs/Veins/Shells' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item initialValue="0" 
              name="foreignMatter" 
              label="Foreign Matter" 
              rules={[{ required: true, message: 'Foreign Matter' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
			      <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
           <Form.Item label='Uniformity(Big/Small)'  >
              <Input.Group compact>
                <Form.Item
                  name={'uniformityBig'}
                  // rules={[{ required: packMaterialRequiredFields, message: 'Missing Min Grade' }]}
                  noStyle

                >
                  <InputNumber style={{ width: "50%" }} placeholder="Big" min={0} />
                </Form.Item>


                <Form.Item
                  name={"uniformitySmall"}
                  // rules={[{ required: packMaterialRequiredFields, message: 'Missing Max Grade' }]}
                  noStyle

                >
                  <InputNumber style={{ width: "50%" }} placeholder="Small" min={0} onChange={uniformRatioCal}/>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
			      <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="uniformityRatio"
                      label="Uniformity Ratio" 
                      rules={[
                        {
                          required: true,
                          message: `Missing Uniformity Ratio`,
                        },
                      ]}>
                      <Input/>
                    </Form.Item>
            </Col>
			      <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  < Form.Item
                      name="productMetSpec"
                      label="Product met spec-Y/M/N" 
                      rules={[
                        {
                          required: true,
                          message: `Missing Product met spec-Y/M/N`,
                        },
                      ]}>
                      <Input/>
                    </Form.Item>
            </Col>
			      <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="bestBefore"
                      label="Best before date" 
                      rules={[
                        {
                          required: true,
                          message: `Missing Best before date`,
                        },
                      ]}>
                        <DatePicker
                      style={{ width: '100%' }} format="YYYY-MM-DD"
                      showToday={true}/>
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item name="remarks" label="Remarks" >
                <TextArea rows={4}></TextArea>
              </Form.Item>
            </Col>                       
            </Row>
            </Panel>
          </Collapse>
         
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary"disabled={disable} htmlType="button" onClick={createSample}  >
                  Submit
                </Button>
                {(props.isUpdate === false ) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
          }
                </Col>
              </Row>
              </Form>
          </Form>
          <Row gutter={24}
       style={{ display: dataSource.length ? 'unset' : 'none' }}
       >
        <Table dataSource={dataSource} columns={columns} scroll={{ x: true }} />
      </Row>
        </Card>
      );
}


