export class MasterInfoFromIndentRequest {
    farmerId: number;
    farmerCodeId: number;
    productId: number;
    assignLead1: number;
    assignLead2: number;
    indentBy?: number
    constructor(farmerId:number,farmerCodeId:number,productId:number,assignLead1:number,assignLead2:number,indentBy?:number){
        this.farmerId = farmerId;
        this.farmerCodeId = farmerCodeId;
        this.productId = productId;
        this.assignLead1 = assignLead1;
        this.assignLead2 = assignLead2;
        this.indentBy = indentBy;
    }
}