import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { WorkStationsListDto } from "./workstations-list.dto";

export class WorkStationsListResponseModel extends GlobalResponseObject{
    data?: WorkStationsListDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: WorkStationsListDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}
