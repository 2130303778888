import { AllDefectResponse, DefectDto, DefectResponse } from "@gtpl/shared-models/masters";
import { CommonResponse } from "@gtpl/shared-models/production-management";
import axios from "axios";
import connection from "./connection";
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export class DefectService {
    URL = connection.DEPLOY_URL + '/defect';

    async createDefect(dto: DefectDto): Promise<DefectResponse> {
        console.log(dto)
        return await axios.post(this.URL + '/createDefect', dto)
            .then(res => {
                return res.data
            })
    }

    async updateDefect(dto: DefectDto): Promise<DefectResponse> {
        return await axios.post(this.URL + '/updateDefect', dto)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateDefect(Dto: DefectDto): Promise<DefectResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateDefect', Dto)
            .then(res => {
                return res.data
            })
    }

    async getAllDefects(req?:UserRequestDto): Promise<AllDefectResponse> {
        return await axios.post(this.URL + '/getAllDefects',req)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveDefects(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllActiveDefects')
            .then(res => {
                return res.data
            })
    }

}