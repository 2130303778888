

export class EmployeeAttendanceRequest {
    date: string;
    departmentId?: number;
    shift?: string;
    unitId?: number;

    /**
     * 
     * @param date 
     * @param departmentId 
     * @param shift 
     * @param unitId 
     */
    constructor(date: string, departmentId?: number, shift?: string, unitId?: number) {
        this.date = date;
        this.departmentId = departmentId;
        this.shift = shift;
        this.unitId = unitId;
    }
}