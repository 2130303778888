import { LotCodeIwAttributes } from "./lotCodeAttribute-i-w";
import { LotCodeFreezingChild } from "./lotCodeFreez";

export class LotCodeSoakingChild{
    name: string;
    attribute:LotCodeIwAttributes;
    freezingChild:LotCodeFreezingChild;
    constructor(name: string, attribute:LotCodeIwAttributes, freezingChild:LotCodeFreezingChild){
        this.name = name,
        this.attribute = attribute,
        this.freezingChild = freezingChild
    }
}