import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PalletSuggestionDto } from './pallets-suggestion.dto';
import { StockInDto } from './stock-in.dto';

export class GetReportedPalletsResponseModel extends GlobalResponseObject {
    data?: StockInDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: StockInDto[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}