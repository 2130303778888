import axios from 'axios';
import {SoakingDto, SoakingResponseModel, AllSoakingResponseModel, JobCompletedStatusDto, assignSoakingForFreezingResponse, SoakingDetailViewResponseModel, SoakingDetailsRequest} from '@gtpl/shared-models/production-management';
import connection from './connection';
import { UnitRequest } from '@gtpl/shared-models/common-models';

export class SoakingInventoryService {
  URL = connection.DEPLOY_URL + '/soaking';

  async getAllSoakingInventories(): Promise<AllSoakingResponseModel> {
    return await axios.post(this.URL + '/getAllSoakingInventories')
        .then(res => {
            return res.data
        })   
}
    async  soakingInventoryJobCompleted(soakingInventory: SoakingDto): Promise<AllSoakingResponseModel> {
      console.log(soakingInventory);
      return await axios.post(this.URL + '/soakingInventoryJobCompleted', soakingInventory)
              .then(res => {
                  return res.data
              })
 }
 async  getSoakingDataById(data: SoakingDetailsRequest): Promise<SoakingDetailViewResponseModel> {
      return await axios.post(this.URL + '/getSoakingDataById', data)
             .then(res => {
                 return res.data
             })
  }

//   async  assignSoakingForFreezing(soakingInfo: JobCompletedStatusDto): Promise<assignSoakingForFreezingResponse> {
//     console.log(JobCompletedStatusDto);
//     return await axios.post(this.URL + '/assignSoakingForFreezing', soakingInfo)
//             .then(res => {
//                 return res.data
//             })
// }
async  jobCompletedStatus(soakingInfo: JobCompletedStatusDto): Promise<assignSoakingForFreezingResponse> {
    console.log(JobCompletedStatusDto);
    return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
            .then(res => {
                return res.data
            })
}

async  getAllSoakingInventoryInfo(plantReq: UnitRequest): Promise<AllSoakingResponseModel> {
    console.log(plantReq);
    return await axios.post(this.URL + '/getAllSoakingInventoryInfo', plantReq)
            .then(res => {
                return res.data
            })
}


  }

