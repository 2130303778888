export class SaleOrderProductIdRequest{
    saleOrderId:number;
    productId:number;
    plantId:number;
    count?:number;

    /**
     * 
     * @param productId 
     * @param plantId 
     */
    constructor(saleOrderId:number, productId:number, plantId:number,count?:number){
        this.saleOrderId = saleOrderId;
        this.productId = productId;
        this.plantId = plantId;
        this.count = count;
    }
}