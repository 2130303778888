import axios from 'axios';
import {AllType1ResponseModel, Type1Dto, Type1ResponseModel, ValAddOneDropDownData, ValAddOneDropDownDataResponseModel, ValueAdditionIdRequest, valueAdditionIdResponse} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class Type1Service {
URL = connection.DEPLOY_URL + '/value-addition1-types';

        async createType1(product: Type1Dto): Promise<Type1ResponseModel> {
            return await axios.post(this.URL + '/createValAddOneType',product)
                .then(res => {
                    return res.data
                })
        }
        async  updateType1(product: Type1Dto): Promise<Type1ResponseModel> {
        return await axios.post(this.URL + '/updateValAddOneType', product)
                        .then(res => {
                            return res.data
                        })
                }
            async getAllTypes1(req?:UserRequestDto): Promise<AllType1ResponseModel> {
                
                // return new AllType1ResponseModel(true,1111,'Products retrieved successfully',[new Type1Dto(1,'code','name','Remark 1','Bhuvan',true)])
                return await axios.post(this.URL + '/getAllValAddTypes',req)
                    .then(res => {
                        return res.data
                    })     
            }
            async getAllActiveValAddOneTypes(): Promise<AllType1ResponseModel> {
                
                // return new AllType1ResponseModel(true,1111,'Products retrieved successfully',[new Type1Dto(1,'code','name','Remark 1','Bhuvan',true)])
                return await axios.post(this.URL + '/getAllActiveValAddTypes')
                    .then(res => {
                        return res.data
                    })     
            }

            
                async  activatedeActivateProduct(type1Dto: Type1Dto): Promise<Type1ResponseModel> {
                    //   console.log(State.stateCode);
                    return await axios.post(this.URL + '/activateOrDeactivateValAddOne', type1Dto)
                            .then(res => {
                                return res.data
                            })
                }

    async getValAdditionOneTypes(){
        //   alert('hai')
        return new ValAddOneDropDownDataResponseModel(true,11,'customersData retrived successfully',[new ValAddOneDropDownData(1,'Peeled On'),new ValAddOneDropDownData(2,'Peeled Off')])
    }
    
    /**
     * Function to get valAddDetails
     * @param valueAdditionId  
     */
    async getValAddDetails(valAddOneReq: ValueAdditionIdRequest): Promise<valueAdditionIdResponse> {
        return await axios.post(this.URL + '/getValAddDetails', valAddOneReq).then(res => {return res.data});
      }

}