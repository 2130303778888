import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Dropdown, Form, Select } from 'antd';
import { ItemCategoryRequest, ItemsDto, ItemSubCategoryDropDownDto, SubCatReqForCategoryId } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService } from '@gtpl/shared-services/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemsForm } from '@gtpl/pages/master/master-components/items-form';

import './items-grid.css';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ItemsGridProps { }

export function ItemsGrid(props: ItemsGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [itemData, setItemData] = useState<ItemsDto[]>([]);
  const [selectedItemData, setSelectedItemData] = useState<any>(undefined);
  const [itemSubCategoryData, setItemSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [form] = Form.useForm()
  const itemDataService = new ItemsService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const { Option } = Select


  useEffect(() => {
    getAllItems();
    getAllItemCat();
  }, []);

  /**
   * 
   */
  const getAllItems = () => {
    itemDataService.getAllItems().then(res => {
      if (res.status) {
        console.log(res.data,'-dataa')
        setItemData(res.data);
        setFiltersData(res.data);
        AlertMessages.getSuccessMessage('Data retrieved successfully')
      } else {
        if (res.intlCode) {
          setItemData([]);
          //AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setItemData([]);
     // AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllItemCat = () => {
    const itemsRequest = new SubCatReqForCategoryId(6);
    itemSubCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setItemSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          setItemSubCategoryData([]);
         // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setItemSubCategoryData([]);
     // AlertMessages.getErrorMessage(err.message);
    })
  }

  const searchData = () => {
    console.log(form.getFieldsValue());
    const itemSubCategoryId = form.getFieldValue('itemSubCategoryId');
    console.log(itemSubCategoryId, 'itemSub')
    let selectedData = filtersData;
    console.log(selectedData, 'selectedData1')
    //  console.log('lnth',itemSubCategoryId.length)
    if (itemSubCategoryId?.length > 0) {
      selectedData = filtersData.filter(item => itemSubCategoryId?.includes(item.itemSubCategoryName));
    }
    console.log(selectedData, 'selectedData')
    setItemData(selectedData);
  }

  const onReset = () => {

    form.resetFields();
    getAllItems();

  };



  /**
   * 
   * @param itemData 
   */
  const deleteItem = (itemData: ItemsDto) => {
    itemData.isActive = itemData.isActive ? false : true;
    itemDataService.activatedeActivateItem(itemData).then(res => {
      console.log(res);
      if (res.status) {
        getAllItems();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param variantData 
   */
  const updateItem = (itemData: ItemsDto) => {
    itemDataService.updateItem(itemData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllItems();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.internalMessage);
    })
  }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData: ItemsDto) => {
    console.log(viewData,'----viewdata')
    setDrawerVisible(true);
    setSelectedItemData(viewData);
  }






  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Asset Category',
      dataIndex: 'itemCategoryName',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.itemCategoryName)?.localeCompare(b.itemCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemCategoryName'),

    },
    {
      title: 'Asset Sub Category',
      dataIndex: 'itemSubCategoryName',
      // responsive: ['lg'],
      sorter: (a, b) => a.itemSubCategoryName?.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName'),
      // filters:  filterData(itemData)(i => i.name),
      // filters: [
      //   { text: 'Carton', value: 'Carton' },
      //   { text: 'Platter', value: 'Platter' },
      //   { text: 'Pouch', value: 'Pouch' },
      //   { text: 'Ring', value: 'Ring' },
      //   { text: 'Skewer', value: 'Skewer' },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   return record.itemSubCategoryName === value;
      // }
    },
    {
      title: 'Asset Name',
      dataIndex: 'itemName',
      // responsive: ['lg'],
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    // {
    //   title: 'Brand',
    //   dataIndex: 'brandName',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('brandName')
    // },
    // {
    //   title: 'BAP Number',
    //   dataIndex: 'bapNo',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.bapNo?.localeCompare(b.bapNo),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('bapNo'),
    
      
    // },
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   responsive: ['sm'],
    //   sorter: (a, b) => String(a.remarks).localeCompare(String(b.remarks)),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('remarks')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Asset');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteItem(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Asset ?'
                : 'Are you sure to Activate Asset ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (

    <Card title={<span style={{ color: 'white' }}>Assets</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/items-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >


      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total Assets: ' + itemData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + itemData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active: ' + itemData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>< br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={24}>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name='itemSubCategoryId' label='Item Sub Category:'>
              <Select
                              showSearch
                              // style={{ width: 200 }}
                              placeholder="Select Sub Category"
                              optionFilterProp="children"
                             
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value={null}>Select Sub Category</Option>
                              {itemSubCategoryData.map((type2)=>{
                                return <Option value={type2.itemSubCategory} > {type2.itemSubCategory}</Option>
                              })}
                            </Select>
                    
              </Form.Item>
                </Col> */}
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{paddingTop:'30px'}}>
                <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        block onClick={() => searchData()}
                        style={{width: 100 }}
                      >
                        Search
                      </Button>
                      
                      <Button type="primary" htmlType="submit" onClick={onReset} style={{marginLeft:2}} >
                        Reset
                      </Button>
                </Col> */}

        </Row>
      </Form>
      <br /> <br />
      <Table
        rowKey={record => record.itemId}
        columns={columnsSkelton}
        dataSource={itemData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        scroll={{ x: true }}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '75%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <ItemsForm key={Date.now()}
            updateItem={updateItem}
            isUpdate={true}
            itemData={selectedItemData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}


export default ItemsGrid;
