import React, { useEffect, useState } from 'react';
import { RackDetails, RackDto, RackPositionModel, ZoneDropDownDto, ZoneDto , LevelDto, RackPositionTypeEnum} from '@gtpl/shared-models/masters';
import { OccupancyEnum, RackPositionEnum } from "@gtpl/shared-models/masters";
import './rack-form.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { RackPositionService, RackService, ZoneService, LevelService } from '@gtpl/shared-services/masters'
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export interface RackFormProps {
  rackData: RackPositionModel;
  updateRack: (rack: RackPositionModel) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function RackForm(
  props: RackFormProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const rackService = new RackPositionService();
  const rackMasterService = new RackService();
  const levelMasterService = new LevelService();
  const [rackData, setRackData] = useState<RackDetails[]>([]);
  const [levelData, setLevelData] = useState<LevelDto[]>([]);
  const [selectedRackName, setSelectedRackName] = useState<RackDetails[]>([]);
  const [selectedRackLevel,setSelectedRackLevel] = useState<LevelDto[]>([]);
  useEffect(() => { 
    getRacksMaster() 
    getLevelMaster()
  }, [])
  

  const getRacksMaster = () => {
    rackMasterService.getAllRacks().then(res => {
      if (res.status) {
        setRackData(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getLevelMaster = () => {
    levelMasterService.getAllLevels().then(res => {
      if (res.status) {
        setLevelData(res.data);
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }



  const createRack = (rackDto: RackPositionModel) => {
    rackDto.plantId=Number(localStorage.getItem('unit_id'));
    console.log(rackDto.plantId)
    rackService.createRack(rackDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Rack Created Successfully');
        history.push("/rack-position");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleRackName = (value) => {
    setSelectedRackName(value);
  }
  const handleRackLevel = (value) => {
    setSelectedRackLevel(value);
  }
  const onReset = () => {
    form.resetFields();
  }
  
  const saveData = (values: RackPositionModel) => {
    if (props.isUpdate) {
      props.updateRack(values);
    } else {
      createRack(values);
    }

  };
  return (<Card title={<span style={{ color: 'white' }}>Rack Position</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate ? '' : <Link to='/rack-position' ><Button className='panel_button' >View </Button></Link>}
  >
    <Form layout="vertical" form={form} initialValues={props.rackData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="rackId" style={{ display: "none" }} >
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
        <Input hidden />
      </Form.Item>

      
      <Row gutter={24}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackMasterId"
            label="Rack Name"
            rules={[
              {
                required: true,
                message: 'Rack Name is required'
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }

            ]}>
            <Select
              showSearch
              placeholder="Select Rack"
              optionFilterProp="children"
              onSelect={handleRackName}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Rack</Option>
              {rackData.map((rack) => {
                return <Option key={rack.rackId} value={rack.rackId}>{rack.rackName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackPositionCode"
            label="Rack Position Code"
            rules={[
              {
                required: true,
                message: 'Rack Position Code Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackPositionType"
            label="Rack Position Type"

            rules={[
              {
                required: true,
                message: 'Rack Position Type is  Required',

              },
            ]}>
            <Select
            showSearch
            placeholder = "Select Rack Position Type"
            allowClear = {true}
            >
            {Object.keys(RackPositionTypeEnum).map((type)=>{
              return <Option value = {RackPositionTypeEnum[type]}>{RackPositionTypeEnum[type]}</Option>
            })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackPosition"
            label="Rack Position"

            rules={[
              {
                required: true,
                message: 'Rack Position is  Required',

              },
            ]}>
            <Select
            showSearch
            placeholder = "Select Rack Position"
            allowClear = {true}
            >
            {Object.keys(RackPositionEnum).map((type)=>{
              return <Option value = {RackPositionEnum[type]}>{RackPositionEnum[type]}</Option>
            })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackLevel"
            label="Rack Level"
            rules={[
              {
                required: true,
                message: 'Rack Level Required',
              },
              {
                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <Select
              showSearch
              placeholder="Select Rack Level"
              optionFilterProp="children"
              onSelect={handleRackLevel}
              // style={{width:'200px'}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value={null}>Select Rack Level</Option>
              {levelData.map((level) => {
                return <Option key={level.levelId} value={level.levelId}>{level.levelName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="rackBoundaries"
            label="Rack Boundaries"
            rules={[
              {
                required: true,
                message: 'Rack Boundaries Are Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="assocRfId"
            label="Associated RF ID"
            rules={[
              {
                required: true,
                message: 'RFID is Required',

              },
              {
                pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                message: `Don't Allow Spaces`
              }

              
            ]}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item
            name="occupancy"
            label="Occupancy"
            >
            <Select
            showSearch
            defaultValue={OccupancyEnum.NOT_OCCUPIED}
            allowClear = {true}
            >
            {Object.keys(OccupancyEnum).map((type)=>{
              return <Option value = {OccupancyEnum[type]}>{OccupancyEnum[type]}</Option>
            })}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" htmlType="submit" >
            Submit
         </Button>
          {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          }
        </Col>
      </Row>
    </Form>
  </Card>
  );
}


export default RackForm;