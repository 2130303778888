import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, AutoComplete, Descriptions, TimePicker, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory, useLocation } from "react-router-dom";
import { SoakingSampleTestDto, SoakingDto, LogIdRequest } from '@gtpl/shared-models/production-management'
import { SoakingInventoryService, SoakingSampleTestService } from '@gtpl/shared-services/production'
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import './soaking-sample-test-form.css';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoItemDetailsBySoIdDTO } from 'libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto';

/* eslint-disable-next-line */
export interface SoakingSampleTestFormProps {
  isUpdate: boolean
  soakingSampleData: SoakingDto
}

export function SoakingSampleTestForm(
  props: SoakingSampleTestFormProps
) {

  const [form] = Form.useForm();
  const service = new SoakingSampleTestService;
  const [soItemDetails, setSoItemDetails] = useState<SoItemDetailsBySoIdDTO>(undefined);
  const soItemService = new SaleOrderService;
  const [soakingSamplesData, setSoakingSamplesData] = useState<SoakingSampleTestDto[]>();
  const [page, setPage] = React.useState(1);
  let location = useLocation();
 
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const onReset = () => {
    form.resetFields();
  }


  useEffect(() => {

    getSaleOrderItemDetails()
    getSamplesInfoBylogId();
    form.setFieldsValue({
      jobNumber: props.soakingSampleData.jobNumber,
      batchNoScode: props.soakingSampleData.lotNumber,
      logId: props.soakingSampleData.productionLogId
    })

  }, []);

  const createSoakingSampleTest = (data: SoakingSampleTestDto) => {

    service.createSoakingSampleTest(data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(' Created Successfully');
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
    getSamplesInfoBylogId();
  }

  const getSamplesInfoBylogId = () => {
    const req = new LogIdRequest(props.soakingSampleData.productionLogId);
    service.getSamplesInfoBylogId(req).then(res => {
      if (res.status) {
        setSoakingSamplesData(res.data);
        console.log("data", res.data);

      } else {
        setSoakingSamplesData(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const pickupPercentage =()=> {
    const asWeight=parseInt(form.getFieldValue('asWeight'))
    const bsWeight=parseInt(form.getFieldValue('bsWeight'))
    if (asWeight < bsWeight) {
      AlertMessages.getErrorMessage( 'After soaking weight cannot be lessthan before soaking weight');
    }    else if (asWeight && bsWeight) {
      const pickupPercentage= (((asWeight)-(bsWeight))/(bsWeight))*100;
      form.setFieldsValue ({pickupPercentage:(Number(pickupPercentage).toFixed(3))})
    }
  }

    const columns = [

      {
        title: 'S No',
        dataIndex: 'sno',
        key: 'sno',
      },
     
      {
        title: 'Batch No & S Code',
        dataIndex: 'batchNoScode',
        key: 'batchNoScode',
      },
     
      {
        title: 'Temp before Soaking',
        dataIndex: 'bsTemp',
        key: 'bsTemp',
      },
      {
        title: 'Count Before Soaking',
        dataIndex: 'bsCount',
        key: 'bsCount',
      },
     
      {
        title: 'weight before Soaking',
        dataIndex: 'bsWeight',
        key: 'bsWeight',
      },
      {
        title: 'Temp after Soaking',
        dataIndex: 'asTemp',
        key: 'asTemp',
      },
      {
        title: 'Count after Soaking',
        dataIndex: 'asCount',
        key: 'asCount',
      },
     
      {
        title: 'weight after Soaking',
        dataIndex: 'asWeight',
        key: 'asWeight',
      },
      {
        title: 'Chemical Consumption(salt)',
        dataIndex: 'chemicalConsumptiona',
        key: 'chemicalConsumptiona',
      },
      {
        title: 'Chemical Consumption(P/NP)',
        dataIndex: 'chemicalConsumptionb',
        key: 'chemicblConsumptionb',
      },
      {
        title: 'Time In',
        dataIndex: 'timeIn',
        key: 'timeIn',
        render: (value) => {
          return moment(value).format("h:m A");
        }
      },
      {
        title: 'Time Out',
        dataIndex: 'timeOut',
        key: 'timeOut',
        render: (value) => {
          return moment(value).format("h:m A");
        }
      },
      {
        title: 'Pick Up Percentage',
        dataIndex: 'pickupPercentage',
        key: 'pickupPercentage',
      },
      
     
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
      },
 
    {
      title: 'Time Out',
      dataIndex: 'timeOut',
      key: 'timeOut',
      render: (value) => {
        return moment(value).format("h:m A");
      }
    },
    {
      title: 'Pick Up Percentage',
      dataIndex: 'pickupPercentage',
     key: 'pickupPercentage',
    },



    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    }

  ]
  

  const getSaleOrderItemDetails = () => {
    setSoItemDetails(undefined);
    const saleOrderId = props.soakingSampleData.saleOrderId;
    const saleOrderItemId = props.soakingSampleData.saleOrderItemId;
    soItemService.getSaleOrderItemDetailsBySoItemId({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId }).then((res) => {
      if (res.status) {
        setSoItemDetails(res.data);
        form.setFieldsValue({
          brand: res.data.brand,
          packing: res.data.packingStyle,
          product: res.data.productName
        })
      }
      else {
        if (res.intlCode) {
          setSoItemDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoItemDetails(null);
        AlertMessages.getErrorMessage(err.message);
      });
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Soaking Inventory</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

        <Row gutter={24}>
          <Descriptions>
            <Descriptions.Item label="Batch No/Lot No">
              {props.soakingSampleData.lotNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Job Number">
              {props.soakingSampleData.jobNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Input Qunatity">
              {props.soakingSampleData.physicalQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Variety">
              {soItemDetails?.productName}
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Card>

      <Card title={<span style={{ color: 'white' }}>Sample Form</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

        <Form form={form}
          name="control-hooks"
          layout="vertical"
          onFinish={createSoakingSampleTest}
        >

          <Form.Item name="soakingSampleTestId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="logId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="jobNumber"  >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="batchNoScode"  >
            <Input hidden />
          </Form.Item>


          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <h2 style={{ color: 'grey', paddingTop: '27px' }}>  <LeftOutlined /> Before Soaking :</h2>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="bsTemp"
                label="Temp (C)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="bsCount"
                label="Count / lb"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="bsWeight"
                label="Weight in KGs"
                rules={[
                  {
                    required: true,
                    message: `Missing Weight`,
                  },
                ]}
              >
                <Input onChange={pickupPercentage} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <h2 style={{ color: 'grey', paddingTop: '27px' }}> <RightOutlined /> After  Soaking :</h2>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="asTemp"
                label="Temp (C)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="asCount"
                label="Count / lb"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="asWeight"
                label="Weight in KGs"
                rules={[
                  {
                    required: true,
                    message: `Missing Weight`,
                  },
                ]}
              >
                <Input onChange={pickupPercentage} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="chemicalConsumptiona"
                label="Chemical Consumption(salt)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="chemicalConsumptionb"
                label="Chemical Consumption(pool)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="timeIn"
                label="Time In"
                rules={[
                  {
                    required: true,
                    message: `Missing  Time`,
                  },
                ]}
              >
                <TimePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="timeOut"
                label="Time Out"
                rules={[
                  {
                    required: true,
                    message: `Missing  Time`,
                  },
                ]}
              >
                <TimePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="pickupPercentage"
                label="Pick Up (%)"
                
                rules={[
                  {
                    required: true,
                    message: `Missing Pick Up percentage`,
                    
                  },
                ]}
                
              >
                
                <InputNumber min={0} style={{ width: '100%' }} disabled  />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: `Missing Remark`,
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>


          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>

              <Button type="primary" htmlType="submit" >
                Submit
              </Button>
              {(props.isUpdate !== true) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                  Reset
                </Button>
              }
            </Col>
          </Row>

          <Row gutter={24}>
            <Table
              dataSource={soakingSamplesData}
              columns={columns} scroll={{ x: true }} />
          </Row>
        </Form>
      </Card>

    </>

  );
}

export default SoakingSampleTestForm;
