import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table,DatePicker, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, Spin } from 'antd';
import { DepartmentDto, ItemCategoriesDto, ItemsDropDownDto, ItemsDto, PlantsDropDown } from '@gtpl/shared-models/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import {ItemsService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { StockService, StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { CSVLink } from 'react-csv';
import './store-consumption-report.css';
import { ConsumedData, ConsumptionReportRequest, StockDto } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import{SaleOrderService} from '@gtpl/shared-services/sale-management';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Excel } from 'antd-table-saveas-excel';
const { Option } = Select;



export interface StoreConsumptionReportProps { }

export const StoreConsumptionReport =(
  props: StoreConsumptionReportProps
) => {
  const itemService = new ItemsService;
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [disable,setDisable]=useState<boolean>(false);
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [triggerReport, setTriggerReport] = useState(false);
  const [storeConsumptionData, setStoreConsumptionData] = useState<ConsumedData[]>([]);
  const [SODataForDropDown, setSODataForDropDown] = useState<StockDto[]>([]);
  const [itemCategoryForDropDown, setItemCategoryForDropDown] = useState<StockDto[]>([]);
  const [itemNameForDropDown, setItemNameForDropDown] = useState<StockDto[]>([]);
  const [display, setDisplay] = useState<string>('none');
  const stockService = new StockService;
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const storeConsumptionService = new StoreConsumptionService()
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
  const { RangePicker } = DatePicker;
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const service = new SaleOrderService;
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate]= useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate]= useState(undefined);
  const [NoData, setSetNoData] = useState(false);
  const [soDrop, setSoDrop] = useState<ConsumedData[]>([]);
  const [itemDrop, setItemDrop] = useState<ConsumedData[]>([]);

  
  useEffect(() => {
    getSoDropForScReport();
    getItemDropForScReport();
    // getAvailableConsumedStockForSO();
    form.setFieldsValue({
      createdDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1,'months')),moment(moment().format("YYYY-MM-DD"))] 
    })
    setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1,'months')))
    setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
}, []);
  
  const onFocus=() =>{
    console.log('focus');
  }

  const headers = [
    { label: "Consumption Code", key: "consumptionCode" },
    { label: "Indent Date", key: "consumedDate" },
    { label: "PO NUmber", key: "saleOrder" },
    { label: "Item Name", key: "itemName" },
    { label: "Issued Quantity", key: "issuedQty" }
  ];
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }
  function unique(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  const EstimatedETDDate=(value) =>{
    console.log(value,'value') 
    if (value){
    const consumedFromDate = new Date(value[0].format('YYYY-MM-DD'));
    const consumedToDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedEstimatedFromDate(consumedFromDate)
    setSelectedEstimatedToDate(consumedToDate)}
  }

  const getAvailableConsumedStockForSO = (onReset?:boolean) => {
    setDisable(true)
    setTriggerReport(true)
    const req = new ConsumptionReportRequest();
    let saleOrderId = form.getFieldValue('saleOrderId')
    let itemId = form.getFieldValue('itemId')
    req.saleOrderId = saleOrderId
    req.itemId = itemId
    req.consumedFromDate = onReset?undefined : selectedEstimatedFromDate
    req.consumedToDate = onReset?undefined : selectedEstimatedToDate
    req.unitId = Number(localStorage.getItem('unit_id'))

    storeConsumptionService.getAvailableConsumedStockForSO(req).then(res => {
      setDisable(false)
      if (res.status) {
        setStoreConsumptionData(res.data);
        setDisplay('block');
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode){
          setSetNoData(true)
          setStoreConsumptionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
          setSetNoData(true)
        } 
      }
    }).catch(err => {
      setDisable(false)
      setStoreConsumptionData([]);
    })
  }
  // const handleSaleOrder=(value, item)=>{
  //   const newStockData = storeConsumptionData.filter(stock =>
  //     stock.saleOrderId == value
  //   )
  //   setStoreConsumptionData(newStockData);
    
  // }
  
  // const handleSaleOrder = (value, item) => {
  //   const newStockData = storeConsumptionData.filter(stock =>
  //     stock.saleOrderId == value
  //   )
  //   setStoreConsumptionData(newStockData);
  //   setSODataForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.saleOrderId))));
  //   //setItemCategoryForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemCategoryId))));
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  // }
  // const handleItemName = (value, item) => {
  //   const newStockData = storeConsumptionData.filter(stock =>
  //     stock.itemId == value
  //   )
  //   setStoreConsumptionData(newStockData);
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  // }

  const getItemDropForScReport = () => {
    storeConsumptionService.getItemDropForScReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setItemDrop(res.data);
      } else {
        if (res.intlCode) {
          setItemDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDrop([]);
    })
  }
const getSoDropForScReport = () => {
  storeConsumptionService.getSoDropForScReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
    if (res.status) {
      setSoDrop(res.data);
    } else {
      if (res.intlCode) {
        setSoDrop([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setSoDrop([]);
  })
}


  
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const onReset = () => {
    form.resetFields();  
    // getAvailableConsumedStockForSO(true);
    setSelectedEstimatedFromDate(undefined);
     setSelectedEstimatedToDate(undefined);
  
   }
   const excelcolumns = [
    {
      title: 'Unit',
      dataIndex: 'unitName',
    },
    {
      title: 'Indent Date',
      dataIndex: 'consumedDate',
      render: (text, record) => { return moment(record.consumedDate).format('YYYY-MM-DD') },
      // ...getColumnSearchProps('consumedDate')
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrder',
    },
    {
      title: 'Store Consumption Code',
      dataIndex: 'consumptionCode',
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
    },
    {
      title: 'issued quantity',
      dataIndex: 'issuedQty',
      render:(text)=>{
        return <span>{Number(text)}</span>
      }
    },
  ];

   const exportExcel=()=>{
    const excel = new Excel();
          excel
            .addSheet('StoreConsumptionReport')
            .addColumns(excelcolumns)
            .addDataSource(storeConsumptionData)
            .saveAs('StoreConsumptionReport.xlsx');
    }

   
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unitName',
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unitName')
    },
    {
      title: 'Indent Date',
      dataIndex: 'consumedDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.consumedDate).unix() - moment(b.consumedDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.consumedDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.createdDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrder',
      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      ...getColumnSearchProps('saleOrder')
    },
    {
      title: 'Store Consumption Code',
      dataIndex: 'consumptionCode',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.consumptionCode.localeCompare(b.consumptionCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('consumptionCode')
    },

    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrder',

    //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrder')
    // },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width:'200px',
      // responsive: ['lg'],
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'issued quantity',
      dataIndex: 'issuedQty',
      align:'right',
      // responsive: ['lg'],
      // sorter: (a, b) => a.issuedQty.localeCompare(b.issuedQty),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('issuedQty'),
      render:(text)=>{
        return <span>{Number(text)}</span>
      }
    },
    // {
    //   title: 'returned quantity',
    //   dataIndex: 'returnQty',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.returnedquantity.localeCompare(b.returnedquantity),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('returnQty')
    // },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Store Consumption Report</span>} extra={<Button onClick={() => {exportExcel();}}>Get Excel</Button>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form form={form} layout={'vertical'} >
      <Row gutter={[24, 24]}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item name="fromDate"
                    label="Indent Date"
                    initialValue={undefined}                  
                    rules={[
                      {
                        required: false,
                        message: " ETD Date"
                      },
                    ]}>
                    <RangePicker onChange={EstimatedETDDate} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item name="createdDate"
                    label="Created Date"
                    initialValue={undefined}                  
                    rules={[
                      {
                        required: false,
                        message: " created Date"
                      },
                    ]}>
                    <RangePicker onChange={EstimatedETDDate} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
               <Form.Item name="saleOrderId"
                    label="Customer PO"
                    rules=
                           {[{ required: false, message: ' Select ' },]}>
                    <Select showSearch placeholder="Select Customer po"
                            optionFilterProp="children"
                            allowClear
                           //onSelect={handleSaleOrder}
                         //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} 
                            >
                     <Option key={0} value={null}>Select Sale Order</Option>
                      {soDrop.map((data)=>{
                        return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                      })}
                </Select>
                  </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 9 }} >
                        <Form.Item label="Item Name"
                            name={'itemId'}
                            rules={[{ required: false, message: 'Missing Item Name' }]}
                        >
                            <Select 
                            showSearch 
                            placeholder="Select Item" 
                            //onSelect={handleItemName}
                            allowClear
                             filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              dropdownMatchSelectWidth={false}>
                                {itemDrop === undefined ? '' : itemDrop.map(dropData => {
                                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>               
          <Col style={{ marginTop: '30px' }}>
          <Button
                    type="primary"
                    // icon={<SearchOutlined />}
                    // size="small"
                    disabled={disable}
                    onClick={() => getAvailableConsumedStockForSO()}
                    style={{ marginRight: 2, width: 100 }}
                    
                  >
                    Get Details
                  </Button>
                  <Button  type="primary" htmlType="submit" onClick={onReset} style={{marginLeft:'5px'}}>
                    Reset
                  </Button>
          </Col>
        </Row>
      </Form>
        {(triggerReport) ? storeConsumptionData.length > 0 ?
      <div>
      <Table
        id="emp"
        rowKey={record => record.deptId}
        columns={columnsSkelton}
        dataSource={storeConsumptionData}
        style={{ display: display }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: 1000,y:500 }}
        onChange={onChange}
        bordered />
          {/* <ReactHTMLTableToExcel  
                  className="btn btn-info"  
                  table="emp"  
                  filename="ReportExcel"  
                  sheet="Sheet"  
                  buttonText="Export excel" />   */}
  </div>:NoData?<></>:<span style={{ paddingTop: '10px', textAlign: 'center' }} ><Spin /></span>:<></>}
    </Card>
  );
      
}
  
export default StoreConsumptionReport;
