import { OperationTypeEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class RejectionsDto{
    lotNumber: string;
    subLotNumber: string;
    saleOrderId: number;
    saleOrderItemId?: number;
    operation: OperationTypeEnum;
    nextOperation: OperationTypeEnum;
    physicalQuantity: number;
    issuedQuantity: number;
    intransitQuantity?: number;
    wipQuantity?: number;
    wastageQuantity?: number;
    rejectedQuantity?: number;
    inputGrade: number;
    outputGrade: number;
    boxes: number;
    ipBoxes: number;
    opBoxes: number;
    createdUser: string;
    updatedUser: string;
    workstation: number;
    plantId?: number;
    assignedTime?: Date
    subPlantId?: number;
    productionInventoryId?: number;
    
    constructor(
        lotNumber: string,
        subLotNumber: string,
        saleOrderId: number,
        saleOrderItemId: number,
        operation: OperationTypeEnum,
        nextOperation: OperationTypeEnum,
        physicalQuantity: number,
        issuedQuantity: number,
        intransitQuantity: number,
        wipQuantity: number,
        wastageQuantity: number,
        rejectedQuantity: number,
        inputGrade: number,
        outputGrade: number,
        boxes: number,
        ipBoxes: number,
        opBoxes: number,
        createdUser: string,
        updatedUser: string,
        workstation:number,
        plantId?: number,
        assignedTime?: Date,
        subPlantId?: number,
        productionInventoryId?: number,){
            this.lotNumber= lotNumber,
            this.subLotNumber= subLotNumber,
            this.saleOrderId= saleOrderId,
            this.saleOrderItemId= saleOrderItemId,
            this.operation= operation,
            this.nextOperation= nextOperation,
            this.physicalQuantity= physicalQuantity,
            this.issuedQuantity= issuedQuantity,
            this.intransitQuantity= intransitQuantity,
            this.wipQuantity= wipQuantity,
            this.wastageQuantity= wastageQuantity,
            this.rejectedQuantity= rejectedQuantity,
            this.inputGrade= inputGrade,
            this.outputGrade= outputGrade,
            this.boxes= boxes,
            this.ipBoxes= ipBoxes,
            this.opBoxes= opBoxes,
            this.createdUser= createdUser,
            this.updatedUser= updatedUser,
            this.workstation= workstation
            this.plantId= plantId,
            this.assignedTime= assignedTime,
            this.subPlantId= subPlantId,
            this.productionInventoryId= productionInventoryId
    }
}