import { JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class WorkStationsResponseDto{
    jobNumber: string;
    saleOrderNo: string;
    workstation: string;
    lotNumber: string;
    soakingTime: string;
    soakingStyle: string;
    quantity: number;
    assigneTime: Date;
    reportedTime: Date;
    jobStatus: JobCompletedEnum;
    poNumber?:string;
    constructor(
        jobNumber: string,
        saleOrderNo: string,
        workstation: string,
        lotNumber: string,
        soakingTime: string,
        soakingStyle: string,
        quantity: number,
        assigneTime: Date,
        reportedTime: Date,jobStatus:JobCompletedEnum,poNumber?:string){
            this.jobNumber= jobNumber,
            this.saleOrderNo= saleOrderNo,
            this.workstation= workstation,
            this.lotNumber= lotNumber,
            this.soakingTime= soakingTime,
            this.soakingStyle= soakingStyle,
            this.quantity= quantity,
            this.assigneTime= assigneTime,
            this.reportedTime= reportedTime
            this.jobStatus= jobStatus
            this.poNumber = poNumber
    }

}