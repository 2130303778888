import { SubJobItemDto } from "./sub-job-item-dto";
import { SubJobRevisionCodesRequest } from "./sub-job-revision-code-list-req";

export class SubJobRequest{
    saleOrderId: number;
    itemDetails:SubJobItemDto[];
    traceCodesData?: SubJobRevisionCodesRequest[];
    createdUser?:string
    constructor(saleOrderId: number,itemDetails:SubJobItemDto[],traceCodesData?: SubJobRevisionCodesRequest[],createdUser?:string){
    this.saleOrderId = saleOrderId;
    this.itemDetails = itemDetails;
    this.traceCodesData = traceCodesData;
    this.createdUser = createdUser;
}

}