import axiox from 'axios';
import connection from './connection';
import { CertificationDto, AllCertificationsResponse, CertificationResponseModel, CertificationReq } from "@gtpl/shared-models/hrms";
import axios from 'axios';
import { CertificatesDto } from '@gtpl/shared-models/masters';

export class CertificationService{
     
    URL = connection.DEPLOY_URL + '/certifications';
    userId = localStorage.getItem('userId');
    userName = localStorage.getItem('userName');


    async createCertification(dto: CertificationDto): Promise<CertificationResponseModel>{
        return await axios.post(this.URL + '/createCertification', dto)
            .then(res => {
                return res.data;
            })
    }

    async updateCertification(dto: CertificationDto): Promise<CertificationResponseModel>{
        return await axios.post(this.URL + '/updateCertification', dto)
            .then(res => {
                return res.data;
            })
    }

    async getAllCertifications(certificatesData?:CertificatesDto): Promise<AllCertificationsResponse>{
        return await axios.post(this.URL + '/getAllCertifications')
            .then(res => {
                return res.data;
            })
    }

    async getAllActiveCertifications(): Promise<AllCertificationsResponse>{
        return await axios.post(this.URL + '/getAllActiveCertifications')
            .then(res => {
                return res.data;
            })
    }

    async activateOrDeactivate(dto: CertificationReq): Promise<CertificationResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivate', dto)
            .then(res => {
                return res.data;
            })
    }

    async getCertificationById(req: CertificationReq): Promise<CertificationResponseModel>{
        return await axios.post(this.URL + '/getCertificationById', req)
            .then(res => {
                return res.data;
            })
    }

}