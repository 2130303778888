import React, { useEffect, useState } from 'react';
import { ZoneDto } from '@gtpl/shared-models/masters';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import './zone-form.css';
import {ZoneService} from '@gtpl/shared-services/masters'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService} from '@gtpl/shared-services/masters';
import { PlantsDropDown} from '@gtpl/shared-models/masters';

/* eslint-disable-next-line */
export interface ZoneFormProps {
  zoneData:ZoneDto;
  updateZone:(zone:ZoneDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function ZoneForm(
  props: ZoneFormProps
) {

  const [form] = Form.useForm();
  let history = useHistory();
  const unitsService = new UnitcodeService();
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  useEffect(() => {
    
    form.setFieldsValue({plantId:Number(localStorage.getItem("unit_id"))});
    getAllPlants();
  }, []);
  const zoneService=new ZoneService()

  const createZone=(ZoneDto:ZoneDto)=>{
    ZoneDto.plantId = Number(localStorage.getItem('unit_id'));
    console.log(ZoneDto.plantId)
    zoneService.createZone(ZoneDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Zone Created Successfully');
        history.push("/zone");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const onReset=()=>{
    // console.log('hhhhhhhhhhhhhhhhhhhhhhh');
    form.resetFields();
    
  }

  const saveData = (values: ZoneDto) => {
    if(props.isUpdate){
      console.log(values)
      props.updateZone(values);
    }else{

      console.log(values)
      createZone(values);
    }
  
  };

  return (
    <Card title={<span style={{color:'white'}}>Zones</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}
     extra={(!props.isUpdate)?<Link to='/zone' ><Button className='panel_button' >View </Button></Link>:''}
    >
      <Form layout="vertical" form={form} initialValues={props.zoneData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="zoneId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row>
           <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name="zoneCode"
                label="Zone Code"
                rules={[
                  {
                    required: true,message:'Zone Code is required'
                    
                  },
                  {
                    pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                    message: `Don't allow spaces`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name="zoneName"
                label="Zone Name"
                rules={[
                  {
                    required: true,message:'Zone Name is required'
                    
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Should contain only alphabets`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
        {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item
                name="plantId"
                label="Plant"
                rules={[
                  {
                    required: true, message: 'Missing Plant'
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Select Plant"
                  optionFilterProp="children"

                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={undefined}>please select Plant</Option>;
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col> */}
      </Row>
     
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
      
            
        </Col>
      </Form>
    </Card>
  );
}

export default ZoneForm;
