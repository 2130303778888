
import React, { useEffect, useRef, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Tree, Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import form from 'antd/lib/form';
import Highlighter from 'react-highlight-words';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { BeheadingService, LotCodeOpsService, ProductionInventoryService } from '@gtpl/shared-services/production';
import { LotCodeDropDown, ProductionInventoryDetails } from '@gtpl/shared-models/production-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ProductionLogRequest } from 'libs/shared-models/production-management/src/lib/production-inv/production-log.req';
import moment from 'moment';


const { Option } = Select;

export interface ProductionInventoryProps{}

export function ProductionInvenGrid(props:ProductionInventoryProps){
  const [form] = Form.useForm();
  const[searchText, setSearchText] = useState('');
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;
  const [page, setPage] = React.useState(1);
  const production = new ProductionInventoryService();
  const beheadService = new BeheadingService();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [behead, setBeheadData] = useState<ProductionInventoryDetails[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [saleId, setSaleId] = useState(0);
const [selectedunit, setUnit] = useState<number>(0)
  const [disable,setDisable]=useState<boolean>(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const lotCodeService = new LotCodeOpsService();
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const [lotNumber, setLotNumber] = useState("");


  const handleChange = (value) => {
    // setDisplay("none");
    setLotNumber(value);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    // getBeheadingInventory();
    getProductionLogReport();
    getAllLotNumbers();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
  }
    
  }, []);

  const getAllLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new UnitRequest(plant);

    lotCodeService.getAllLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getProductionLogReport = (onReset?:boolean)=>{
    // const plant = Number(localStorage.getItem('unit_id'))
    const lotNumber = form.getFieldValue('lotNumber')
    let fromDate = onReset ? undefined : selectedEstimatedFromDate;
    let toDate = onReset ? undefined : selectedEstimatedToDate;
    const req = new ProductionLogRequest();
    req.lotNumber=lotNumber
    req.fromDate=fromDate
    req.toDate=toDate
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    production.getProductionInventoryReport(req).then((res)=>{
      if(res.status){
        setReportData(res.data);
      }else{
        setReportData([]);
      }
    }).catch((err)=>{
      AlertMessages.getErrorMessage(err.message);
      setReportData([]);
    })
  }

  const EstimatedETDDate = (value) => {
    if (value) {
      console.log(value)
      const fromDate = new Date(value[0].format('yyyy-MM-DD'));
      const toDate = new Date(value[1].format('yyyy-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
      console.log(fromDate)
    }
  }
//   const getBeheadingInventory = () => {
//     const beheadInvReq = new UnitRequest(Number(localStorage.getItem('unit_id'))
//    );
//    beheadService.getBeheadingInventoryInfo(beheadInvReq).then((res) => {
//        if (res.status) {
//         setBeheadData(res.data);          

//        } 
//        else {
//          if (res.intlCode) {
//            AlertMessages.getErrorMessage(res.internalMessage);
//          } else {
//            AlertMessages.getErrorMessage(res.internalMessage);
//          }
//        }
//      })
//      .catch((err) => {
//        AlertMessages.getErrorMessage(err.message);
//      });
//  };

    const goToSaleDetailView = (saleOrderId: number) => {
        setSaleId(saleOrderId);
        console.log("soid",saleOrderId);
        // setIsModalVisible(true)
      }
    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'Lot Code',
            dataIndex: 'lotNumber',
            sorter: (a, b) => a.lotNumber?.localeCompare(b.lotNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('lotNumber'),
          },
          {
            title: 'Po Number',
          dataIndex: 'poNumber',
          sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('poNumber'),
          },

          {
            title: 'Date',
          dataIndex: 'DATE',
            render: (value, record) => {
              return <span>
                {record.DATE ? moment(record.DATE).format('YYYY-MM-DD') : '-'}
              </span>
            },
               sorter: (a, b) => a.DATE?.localeCompare(b.DATE),
            sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('DATE'),
          },
          {
            title: 'Operation',
          dataIndex: 'operation',
          width:'110px',
          sorter: (a, b) => a.operation.localeCompare(b.operation),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('operation'),
          },
          
         
          {
            title: 'Input Grade',
          dataIndex: 'inputGrade',
          align:'right',
          sorter: (a, b) => a.inputGrade-b.inputGrade,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.inputGrade ? record.inputGrade : '-'}
              </span>
            },
            ...getColumnSearchProps('inputGrade'),
          },
          {
            title: 'Transfer Quantity',
          dataIndex: 'intransitQuantity',
          align:'right',
          sorter: (a, b) => a.intransitQuantity-b.intransitQuantity,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.intransitQuantity ? record.intransitQuantity : '-'}
              </span>
            },
            ...getColumnSearchProps('intransitQuantity'),
          },
              {
                title: 'Physical Quantity',
                dataIndex: 'physicalQuantity',
          align:'right',
                sorter: (a, b) => a.physicalQuantity-b.physicalQuantity,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.physicalQuantity ? record.physicalQuantity : '-'}
              </span>
            },
            ...getColumnSearchProps('physicalQuantity'),
                // sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
                // sortDirections: ['descend', 'ascend'],
                //   ...getColumnSearchProps('poNumber'),
                // render: (value, record) => 
                // <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
                },
                
                // {
                //   title: 'Planned Output Quantity',
                // dataIndex: 'plannedOutputQuantity',
                // },
                {
                  title: 'Output Grade',
                dataIndex: 'outputGrade',
          align:'right',
                sorter: (a, b) => a.outputGrade-b.outputGrades,
            sortDirections: ['descend', 'ascend'],
            // render: (value, record) => {
            //   return <span>
            //     {record.outputGrade ? record.outputGrade : '-'}
            //   </span>
            // },
            ...getColumnSearchProps('outputGrade'),
                },
                
                {
                  title: 'Wastage Quantity',
                dataIndex: 'wastageQuantity',
          align:'right',
                sorter: (a, b) => a.wastageQuantity-b.wastageQuantity,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.wastageQuantity ? record.wastageQuantity : '-'}
              </span>
            },
            ...getColumnSearchProps('wastageQuantity'),
                },
                {
                  title: 'Rejected Quantity',
                dataIndex: 'rejectedQuantity',
          align:'right',
                sorter: (a, b) => a.rejectedQuantity-b.rejectedQuantity,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.rejectedQuantity ? record.rejectedQuantity : '-'}
              </span>
            },
            ...getColumnSearchProps('rejectedQuantity'),
                },
                {
                  title: 'Wip Quantity',
                dataIndex: 'wipQuantity',
          align:'right',
                sorter: (a, b) => a.wipQuantity-b.wipQuantity,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.wipQuantity ? record.wipQuantity : '-'}
              </span>
            },
            ...getColumnSearchProps('wipQuantity'),
                },
                {
                  title: 'I/P Boxes',
                dataIndex: 'boxes',
          align:'right',
                sorter: (a, b) => a.boxes-b.boxes,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.boxes ? record.boxes : '-'}
              </span>
            },
            ...getColumnSearchProps('boxes'),
                },

                  {
                    title: 'O/P Boxes',
                    dataIndex: 'opBoxes',
                    align:'right',
                    sorter: (a, b) => a.opBoxes-b.opBoxes,
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.opBoxes ? record.opBoxes : '-'}
              </span>
            },
            ...getColumnSearchProps('opBoxes'),
                    // render: (value) => {
                    //   return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
                    // }
                  },
             
                 
    ];

    const onReset = () => {
      form.resetFields();
      setSelectedEstimatedFromDate(undefined);
      setSelectedEstimatedToDate(undefined);
        // setReportData([]);
      }

    return(
        <Card
        title={<span style={{ color: 'white' }}>Production Inventory Report</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        >
        <Form layout={'vertical'} form={form}>
          <Row gutter={[24,24]}>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 6 }}>
            <Card
                title={'No. of Lots : '+reportData.length}
                style={{
                  textAlign: 'center',
                  height: 41,
                  backgroundColor: '#52c41a',
                }}
            ></Card>
        </Col> */}

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="lotNumber"
              label="Lot Number"
            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Lot Number"
                optionFilterProp="children"
                onChange={handleChange}
              >

                <Option key={0} value={null}>Select Lot</Option>
                {lotNumbers.map((lot) => {
                  return <Option key={lot.lotNumber} value={lot.lotNumber}>{lot.lotNumber}</Option>
                })}


              </Select>
            </Form.Item>
            {console.log(lotNumber)}
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="fromDate"
              label="Date Range"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "select date range"
                },
              ]}>
              <RangePicker onChange={EstimatedETDDate} />
            </Form.Item>
          </Col>


          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} 
            onClick={() => getProductionLogReport()}
            >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          </Row>
          <Row>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Card title={'No. of Lots : '+reportData.length} style={{textAlign:'left',width: 250, height:41,  backgroundColor: '#52c41a',}}></Card>
          </Col>
              
            </Row>
        </Form>
        <br />
        <Row>
        <Table
            // rowKey={record => record.downtimeTrackingId}
            columns={columnsSkelton}
            dataSource={reportData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            size='small'
            
            // scroll={{ y:400 }}
            scroll={{ x: 1300, y: 300 }}

            bordered />
            </Row>
    
        </Card>
    )
}
export default ProductionInvenGrid;

