export class PoSaleIdRequest {
    saleOrderId: number;

    /**
     * 
     * @param saleOrderId 
     */
    constructor(saleOrderId: number){
        this.saleOrderId = saleOrderId;
    }
}