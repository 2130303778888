import React, { useEffect, useState } from 'react';
import './reserve-rack-positions.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantZone, RackDashboard, WarehouseDashboard, WarehouseResponseData } from '@gtpl/shared-models/warehouse-management';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import { Card, Form, Row, Col, Select, Button, InputNumber, Collapse, Tag, Tooltip, Descriptions } from 'antd';
import { OccupancyEnum, RackDropDownDto, SkuDropDownDataResponseModel, SkuDto, ZoneDto } from '@gtpl/shared-models/masters';
import {EndCustomersService, MasterBrandService, ProductService, RackService, SizesService, SkuService, ZoneService,HrmsEmpDetService, RackPositionService} from '@gtpl/shared-services/masters';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'
import CheckboxButton from "./CheckboxButton";
import { PONumbersDropDownDto } from 'libs/shared-models/sale-management/src/lib/sale-order/po-number-dropdown.dto';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { RackTypeEnum } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
const { Option } = Select;
const { Panel } = Collapse;

export interface ReserveRackPositionsProps {}

export function ReserveRackPositions(
  props: ReserveRackPositionsProps
) {
  const [form] = Form.useForm();
  const [racks,setRacks] = useState<WarehouseDashboard[]>([]);   
  const [visible,setVisible] = useState<boolean>(false);   
  const stockInService = new StockInService();
  const [zoneDropDown, setZoneDropDown] = useState<ZoneDto[]>([]);
  const [rackDropDown, setRackDropDown] = useState<RackDropDownDto[]>([]);
  const [varientsDropDown, setVarientsDropDown] = useState<SkuDto[]>([]);
  const [poNumbers, setPoNumbers] = useState<SaleOrderDropDownDto[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [zoneId, setZoneId] = useState<number>(undefined);
  const [reservedForDummy, setReservedForDummy] = useState<boolean>(true);
  const [dummyVisible, setDummyVisible] = useState<boolean>(false);
  const [soVisible, SetSoVisible] = useState<boolean>(false);
  const zoneService = new ZoneService();
  const rackService = new RackService();
  const skuService = new SkuService();
  const soService = new SaleOrderService();
  const rackPositionService = new RackPositionService();

  useEffect(() => {
    getZones();
    getVarients();
    getCustomerPoNo();
}, []);


const saveData = (values: any, data: any) => {
  setBtnDisable(true)
  console.log(data.poNo)
  if(data.poNo != undefined){
    AlertMessages.getWarningMessage("You cannot reserve Assigned rack. ");
    setBtnDisable(false)
  }
  else{
      console.log(form.getFieldsValue())
      console.log(values);
      console.log(data);
      let flag = true;
    form.validateFields().then(rec => {
      if(rec.soType === "saleOrder" && rec.saleOrderId === undefined && data.isReserved === 0){
        flag = false;
        AlertMessages.getWarningMessage("sale Order cannot be null. ");
        setBtnDisable(false)
      }
      else if(rec.soType === "saleOrder" && data.isReserved === 1){
        flag = true;
      }
      else if(rec.soType === "dummy" && rec.variant === undefined && data.isReserved === 0){
        flag = false;
        AlertMessages.getWarningMessage("Varient cannot be null. ");
        setBtnDisable(false)
      }
      else if(rec.soType === "dummy" && rec.variant != undefined && data.isReserved === 1){
        flag = true;
      }
      console.log(flag);
      if(flag){
        data.isReserved = data.isReserved?false:true;
        rackPositionService.getReserveRackPosition({plantId:Number(localStorage.getItem("unit_id")),zoneId:data.zoneId,rackId:data.rackId,rackPositionId:data.rackPositionId,isReserved: data.isReserved,updatedUser:localStorage.getItem('username'),saleOrderId:form.getFieldValue('saleOrderId'),varientId:form.getFieldValue('variant')}).then((res) => {
          if(res.status){
            AlertMessages.getSuccessMessage(res.internalMessage);
            setBtnDisable(false)
            getZoneLevelRacks(res.data.zoneId,res.data.rackId);
          } else {
            if (res.intlCode) {
              setBtnDisable(false)
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              setBtnDisable(false)
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
          // form.setFieldsValue({saleOrderId:undefined});  
          // form.setFieldsValue({variant:undefined});  
        }).catch((err) => {
        setBtnDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
      }
    
  })
  }
};

const getCustomerPoNo = () => {
  soService.getMTOSaleOrders({ plantId: Number(localStorage.getItem("unit_id")) }).then((res) => {
    if(res.status){
      setPoNumbers(res.data);
    }
    else{
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setPoNumbers([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setPoNumbers([]);
  });
}
const getVarients = () => {
  skuService.getActiveSkuCodes().then((res) => {
    if(res.status){
      setVarientsDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setVarientsDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setVarientsDropDown([]);
  });
}
const getZones = () => {
  zoneService.getPlantZones({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
    if (res.status) {
      console.log(res.data);
      setZoneDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setZoneDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setZoneDropDown([]);
  });
}
const getRacks = (zoneId:number, reservedForDummy:boolean) => {
  rackService.getdummyRacksByZone({zoneId:zoneId,reservedForDummy:reservedForDummy}).then((res) => {
    if (res.status) {
      console.log(res.data);
      setRackDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setRackDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setRackDropDown([]);
  });
}
const handleSoType = (value) => {
  if(value === "dummy"){
    // setReservedForDummy(true)
    setVisible(false);
    setDummyVisible(true)
    form.setFieldsValue({rack:undefined})
    form.setFieldsValue({saleOrderId:undefined})
    getRacks(zoneId, true)

  }
  else{
    setVisible(false);
    // setReservedForDummy(false)
    setDummyVisible(false)
    SetSoVisible(true)
    form.setFieldsValue({rack:undefined})
    form.setFieldsValue({variant:undefined})
    getRacks(zoneId, false)
  }

}
const handleColdStorage = (value) => {
  console.log(value);
  setZoneId(value);
  // getRacks(value)
}
const handleRack = (value) => {
  console.log(value);
  getZoneLevelRacks(zoneId,value)
}
const getZoneLevelRacks = (zoneId:number, rackId:number) => {
  // stockInService.getRackDetails({plantId:Number(localStorage.getItem("unit_id")),zoneId:zoneId,rackId:rackId}).then((res) => {
    stockInService.getWarehouseRackDetails({plantId:Number(localStorage.getItem("unit_id")),zoneId:zoneId,rackId:rackId}).then((res) => {
    if (res.status) {
      setRacks(res.data);
      setVisible(true);
    } else {
      if (res) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  })
  .catch((err) => {
    AlertMessages.getErrorMessage(err.message);
  });
}
const displayTooltip = (props:any,rackName:string) => {
  return (
      <>
          <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      PO Number:&nbsp;&nbsp;{props.poNumber}<br/>
                      Brand Name:&nbsp;&nbsp;{props.brandName}<br/>
                      Grade:&nbsp;&nbsp;{props.grade}<br/>
                      Product Name:&nbsp;&nbsp;{props.productName}<br/>
                      Variant Code:&nbsp;&nbsp;{props.variantCode}<br/>
                      Pallet Code:&nbsp;&nbsp;{props.palletCode}<br/>
                      Packing Completed:&nbsp;&nbsp;{props.packingCompleted}<br/>
                      {/* Stock State:&nbsp;&nbsp;{props.stockState}<br/> */}
                      Stock Type:&nbsp;&nbsp;{props.stockType} <br/>
                      Pallet Capacity:&nbsp;&nbsp;{props.palletCapacity} <br/>
                      Filled Cartons:&nbsp;&nbsp;{props.totalCartons}
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
      </>
  )
}
const displayRackNameTooltip = (props:any,rackName:string, type:string) => {
  return (
      <>
      {type==="PoNo"?
          <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      PO No:&nbsp;&nbsp;{props.poNumber}<br/>
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
          :type==="Varient"?
          <Descriptions layout="horizontal" size="default" contentStyle={{backgroundColor:"white", padding:'3px', fontSize:'12px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      Varient:&nbsp;&nbsp;{props.variantCode}<br/>
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
          :""}
      </>
  )
}
const renderData = (racks) => {
  const mainTr = [];
  racks.forEach(rackObj => {
    console.log(rackObj);
    let rackTds = [];
    // rackTds.push(<td className={'ta-b'} rowSpan={rackObj.rackPositions.length * 2}> Rack - {rackObj.rackName}</td>); // A 
    rackObj.rackPositions.forEach(rackPosition => { // 1
      rackTds.push(<td className={'ta-b'} rowSpan={rackPosition.rackDetails.length} style={{width:'20px',left:'0px'}}><b>{rackObj.rackName + rackPosition.rackLevel}</b></td>) // 1
      rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
        rackTds.push(<td className={'ta-b'}><b>{rackTypeObj.rackType}</b></td>)
        rackTypeObj.locations.forEach(childRackPostion => {
          console.log(childRackPostion);
          let bgColor;
          let name;
          let tooltipType;
          if(childRackPostion.isActive){
            if(childRackPostion.occupancy === OccupancyEnum.ASSIGNED){
              if(childRackPostion.palletCapacity > childRackPostion.totalCartons){
                bgColor="#f7f871";
              }
              else if(childRackPostion.palletCapacity <= childRackPostion.totalCartons){
                bgColor="#52c41a8c";
              }
              name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
              console.log("testt"+childRackPostion.poNo);
              console.log("testt"+childRackPostion.poNumber);
              tooltipType = "poNo";
            }
            else if(childRackPostion.occupancy === OccupancyEnum.OCCUPIED){
              bgColor="#c1dffa";
              name = childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):"";
              tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
            }
            else{

              bgColor="white";
              name = "";
              tooltipType = null;
            }
          }
          else{
            bgColor="grey";
            name = "";
            tooltipType = "inActive";
          }
          {console.log(rackTypeObj.rackType)}
          {console.log(childRackPostion.rackLevel)}

                
          {
                !childRackPostion.isActive ?
                rackTds.push(<Tooltip title={<Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        FORKLIFT WAY
                    </strong>                    
                </Descriptions.Item>
            </Descriptions>}>
                <td className={'ta-b'} style={{backgroundColor:bgColor}}></td></Tooltip>)
                :childRackPostion.poNo!=null?
                rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNo}</td></Tooltip>)
                :childRackPostion.isReserved && childRackPostion.poNo!=null?rackTds.push(<Tooltip title={displayRackNameTooltip(childRackPostion,childRackPostion.rackCode,"PoNo")}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNo}</td></Tooltip>)
                :childRackPostion.isReserved && childRackPostion.poNo==null?rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNumber}</td></Tooltip>)
                :childRackPostion.isReserved && childRackPostion.variantCode!=null?rackTds.push(<Tooltip title={displayRackNameTooltip(childRackPostion,childRackPostion.rackCode,"Varient")}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNumber}</td></Tooltip>)
                :rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor,color:'black'}} onClick={((e) => saveData(e, childRackPostion))}><Button block disabled={btnDisable} style={{color:'white'}}>{childRackPostion.rackCode}</Button>
                  </td>)
            // (tooltipType===null?
            // rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td>)
            // :rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}><td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td></Tooltip>))
          }
        });
        mainTr.push(<tr>{rackTds}</tr>);
         rackTds = [];
      });
    });
  });
  return mainTr;
}


const renderDataForUnit1 = (racks) => {
const mainTr = [];
racks.forEach(rackObj => {
  console.log(rackObj);
  let rackTds = [];
  rackObj.rackPositions.forEach(rackPosition => { // 1
      console.log(rackPosition.rackDetails.length);
    rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
      rackTypeObj.locations.forEach(childRackPostion => {
        console.log(childRackPostion);
        let bgColor;
        let name;
        let tooltipType;
        if(childRackPostion.isActive){
          if(childRackPostion.occupancy === OccupancyEnum.ASSIGNED){
            if(childRackPostion.palletCapacity > childRackPostion.totalCartons){
              bgColor="#f7f871";
            }
            else if(childRackPostion.palletCapacity <= childRackPostion.totalCartons){
              bgColor="#faad14";
            }
            name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
            console.log("testt"+childRackPostion.poNo);
            console.log("testt"+childRackPostion.poNumber);
            tooltipType = "poNo";
          }
          else if(childRackPostion.occupancy === OccupancyEnum.OCCUPIED){
            bgColor="#c1dffa";
            name = childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):childRackPostion.rackCode;
            tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
          }
          else{
            bgColor="white";
            name = childRackPostion.rackCode;
            tooltipType = null;
          }
        }
        else{
            bgColor="grey";
            name = "";
            tooltipType = "inActive";
        }
        {
          !childRackPostion.isActive ?
          rackTds.push(<Tooltip title={<Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
          <Descriptions.Item>
              <strong>
                  FORKLIFT WAY
              </strong>                    
          </Descriptions.Item>
      </Descriptions>}>
          <td className={'ta-b'} style={{backgroundColor:bgColor}}></td></Tooltip>)
          :childRackPostion.poNo!=null?
          rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
          <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNo}</td></Tooltip>)
          :childRackPostion.isReserved && childRackPostion.poNo!=null?rackTds.push(<Tooltip title={displayRackNameTooltip(childRackPostion,childRackPostion.rackCode,"PoNo")}>
          <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNo}</td></Tooltip>)
          :childRackPostion.isReserved && childRackPostion.poNo==null?rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
          <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNumber}</td></Tooltip>)
          :childRackPostion.isReserved && childRackPostion.variantCode!=null?rackTds.push(<Tooltip title={displayRackNameTooltip(childRackPostion,childRackPostion.rackCode,"Varient")}>
          <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNumber}</td></Tooltip>)
          :rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor,color:'black'}} onClick={((e) => saveData(e, childRackPostion))}><Button block disabled={btnDisable} style={{color:'black'}}>{childRackPostion.rackCode}</Button>
            </td>)
      // (tooltipType===null?
      // rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td>)
      // :rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}><td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td></Tooltip>))
    }
      });
      mainTr.push(<tr>{rackTds}</tr>);
       rackTds = [];
    });
  });
});
return mainTr;
}

const eachRackDisplay = (racks:WarehouseDashboard[]) => {
  return (
    <>
    {
      Number(localStorage.getItem("unit_id")) === 2?
        <div id="table-wrapper">
        <div id="table-scroll" style={{overflow:'scroll'}}>
          <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
              <tr style={{backgroundColor:'#faad145c'}}>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
                {renderDataForUnit1(racks)}
          </table>
          </div>
          </div>
      :
      <div id="table-wrapper">
        <div id="table-scroll" style={{overflow:'scroll'}}>
          <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
              <tr style={{backgroundColor:'#f3b69f'}}>
                <th style={{width:'40px',left:'0px'}}></th>
                <th style={{width:'60px'}}></th>
                {
                  
                  racks[0]?.rackType === RackTypeEnum.Corner ? 
                  Array.from(Array(racks[0]?.colCount), (e, i) => {
                    return <th className={'ta-b'} style={{width:'55px'}}>{Number(racks[0]?.colCount)-i}</th>
                  })
                  :racks[0]?.rackType === RackTypeEnum.Center ? 
                  Array.from(Array(racks[0]?.colCount), (e, i) => {
                    if(Number(racks[0]?.colCount)-2-i === 13){
                      return <><th className={'ta-b'} style={{ width: '55px', color:'white' }}>{27}</th><th className={'ta-b'} style={{ width: '55px', color:'white' }}>{26}</th><th className={'ta-b'} style={{ width: '55px' }}>{Number(racks[0]?.colCount)-2-i}</th></>
                    }
                    else if(i<25){
                      console.log("test" + racks[0]?.colCount + "test"+ i)
                      return <th className={'ta-b'} style={{width:'55px'}}>{Number(racks[0]?.colCount)-2-i}</th>
                    }
                  })
                  :""
                }
              </tr>
                {renderData(racks)}
          </table>
        </div>
      </div>
    }

  </>
)
}

  
function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

  return (
      // {eachRackDisplay(racks)}
      <Card
      title={<span style={{ color: 'white' }}>Reserve Pallets</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form layout={'vertical'} form={form} name="control-hooks">
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="coldStorageId" label="Cold Storage"
              rules={[
                {
                  required: true, message: 'Missing Cold Storage',
                },
              ]}>
                {/* <Input /> */}
              <Select
                  placeholder="Select Cold Storage"
                  onChange={handleColdStorage}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {zoneDropDown?.map(zonedata => {
                    return <Option key={zonedata.zoneId} value={zonedata.zoneId}>{zonedata.zoneName}</Option>
                  })}
                </Select>
              </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
                name="soType"
                label="SO Type"
                rules={[
                  {
                    required: true, message: 'Missing So Type',
                  },
                ]}
              >
                <Select
                  placeholder="Select Rack"
                  onChange={handleSoType}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0}>Select So Type</Option>
                  <Option value="dummy">dummy</Option>
                  <Option value="saleOrder">saleOrder</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="rack"
                label="Rack"
                rules={[
                  {
                    required: true, message: 'Missing Rack',
                  },
                ]}
              >
                <Select
                  placeholder="Select Rack"
                  onChange={handleRack}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {rackDropDown.map(saleOrderDropDown => {
                    return <Option value={saleOrderDropDown.rackId}>{saleOrderDropDown.rackCode}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          {dummyVisible?
          <><Col span={6}>
                <Form.Item
                  name="variant"
                  label="Variant"
                  rules={[
                    {
                      required: false, message: 'Missing Variant',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Variant"
                    allowClear
                    optionFilterProp="children"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {varientsDropDown.map(res => {
                      return <Option value={res.skuCodeId}>{res.variantCode}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item
                  name='minGrade'
                  label="Min Grade"
                  rules={[{ required: false, message: 'Missing Min Grade' }]}
                >
                  <InputNumber placeholder="Min" min={0} />
                </Form.Item>
              </Col><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item
                  name="maxGrade"
                  label="Max Grade"
                  rules={[{ required: false, message: 'Missing Max Grade' }]}
                >
                  <InputNumber placeholder="Max" min={0} />
                </Form.Item>
              </Col> */}
              </>:
              soVisible?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item name="saleOrderId" label="Customer PO"
                rules={[
                  {
                    required: false, message: 'Missing Customer PO',
                  },
                ]}>
                  {/* <Input /> */}
                <Select
                    placeholder="Select Customer PO"
                    // onChange={handleColdStorage}
                    allowClear
                    optionFilterProp="children"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {poNumbers?.map(data => {
                      return <Option value={data.saleOrderId}>{data.saleOrderNumber}</Option>
                    })}
                  </Select>
              </Form.Item>
          </Col>:""
              }
        </Row>
      </Form>
      {visible && racks[0].rackPositions.length>0?
      <Card title={<span style={{ color: 'white' }}>Rack Details</span>}
      style={{ textAlign: 'center'}}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}> 
        <Tag style={{backgroundColor:'#52c41a8c', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}> Reserved & Occupied</Tag>  &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'#c1dffa', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Reserved & Not Occupied</Tag> &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'#f7f871', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Partially Occupied</Tag> &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'white', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Not Reserved</Tag> &nbsp;&nbsp;&nbsp;
        <br/>
        <br/>
        <Collapse defaultActiveKey={[0]} accordion>
          {eachRackDisplay(racks)}
        </Collapse>
      </Card>
      :<Card title={<span style={{ color: 'white' }}>Rack Details</span>}
      style={{ textAlign: 'center'}}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}> No Racks found. </Card>}
      </Card>
  );
}

export default ReserveRackPositions;
