import axios from 'axios';
import {
  AllTypesOfValueAddition2ResponseModel,
  TypesOfValueAddition2Dto,
  TypesOfValueAddition2ResponseModel,
  ValAddTwoDropDownData,
  ValAddTwoDropDownDataResponseModel,
  ValueAdditionIdRequest,
  valueAdditionIdResponse,
} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class TypesOfValueAddition2Service {
  URL = connection.DEPLOY_URL + '/value-addition2-types';

  async createTypesOfValueAddition2(typesOfValueAddition2: TypesOfValueAddition2Dto): Promise<TypesOfValueAddition2ResponseModel> {
    return await axios
      .post(this.URL + '/createValAddTwoType', typesOfValueAddition2)
      .then((res) => {
        return res.data;
      });
  }
  async updateTypesOfValueAddition2(typesOfValueAddition2: TypesOfValueAddition2Dto): Promise<TypesOfValueAddition2ResponseModel> {
    return await axios
      .post(this.URL + '/updateValAddTwoType',typesOfValueAddition2)
      .then((res) => {
        return res.data;
      });
  }
  async getAllTypesOfValueAddition2s(req?:UserRequestDto): Promise<AllTypesOfValueAddition2ResponseModel> {
    // return new AllTypesOfValueAddition2ResponseModel(
    //   true,
    //   1111,
    //   'TypesOfValueAddition2s retrieved successfully',
    //   [
    //     new TypesOfValueAddition2Dto(
    //       1,
    //       'TypesOfValueAddition2 Code',
    //       'TypesOfValueAddition2 Name',
    //       'Remark 1',
    //       'KK',
    //       true
    //     ),
    //   ]
    // );
    return await axios.post(this.URL + '/getAllValAddTypes',req)
        .then(res => {
            return res.data
        })
  }
  async activatedeActivateTypesOfValueAddition2(
    TypesOfValueAddition2Dto: TypesOfValueAddition2Dto
  ): Promise<TypesOfValueAddition2ResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivateValAddTwoType', TypesOfValueAddition2Dto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveTypesOfValueAddition2s(req?:UserRequestDto): Promise<AllTypesOfValueAddition2ResponseModel> {
    return await axios.post(this.URL + '/getAllValAddTypes',req).then((res) => {
      return res.data;
    });
  }

  async getAllActiveValAddTwoTypes(): Promise<AllTypesOfValueAddition2ResponseModel> {
    return await axios.post(this.URL + '/getAllActiveValAddTwoTypes').then((res) => {
      return res.data;
    });
  }

  
  async getActiveTypesOfValueAddition2sCount(): Promise<AllTypesOfValueAddition2ResponseModel> {
    return await axios.post(this.URL + '/getActiveTypesOfValueAddition2Count').then((res) => {
      return res.data;
    });
  }

  async getValAdditionTwoTypes(){
    //   alert('hai')
    return new ValAddTwoDropDownDataResponseModel(true,11,'customersData retrived successfully',[new ValAddTwoDropDownData(1,'Divinded On'),new ValAddTwoDropDownData(2,'Divinded Off')])
  }

   /**
     * Function to get valAddDetails
     * @param valueAdditionId  
     */
    async getValAddDetails(valAddOneReq: ValueAdditionIdRequest): Promise<valueAdditionIdResponse> {
      //   console.log(State.stateCode);
      return await axios.post(this.URL + '/getValAddDetails', valAddOneReq).then(res => {return res.data});
    }
}
