import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DowntimeTrackingMacineNameResponse } from './downtimetracking-macinename-response';

export class DowntimeTrackingMachineNameResponseModell extends GlobalResponseObject{
    data?:DowntimeTrackingMacineNameResponse[];
      /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

   constructor(status: boolean, intlCode: number, internalMessage: string, data?: DowntimeTrackingMacineNameResponse[]){
    super(status,intlCode,internalMessage);
    this.status = status;
    this.intlCode = intlCode;
    this.internalMessage = internalMessage;
    this.data = data;
}
}