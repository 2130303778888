
import { GlobalResponseObject } from '../global-response-object';
import { AssetStatusTypeInfoModel } from './asset-status-type-info.model';

export class AssetStatusWiseAbstractInfoResp extends GlobalResponseObject{
    data: AssetStatusTypeInfoModel[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data: AssetStatusTypeInfoModel[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}