import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Tooltip, Form, Col, Row, Select, Modal, Empty, Popconfirm, message, Tabs, DatePicker, Typography, Pagination, Descriptions } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { PrinterOutlined, SearchOutlined, DownOutlined, CloseCircleOutlined,DoubleLeftOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FgStockStatusEnum, InputIdRequest, SoPlantRequest, StockOutModel, Shifts } from '@gtpl/shared-models/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { PlantsDropDown, RacksForZoneId } from '@gtpl/shared-models/masters';
import { RackService, ZoneService, EndCustomersService, UnitcodeService } from '@gtpl/shared-services/masters';
import { RackDropDownDto, ZoneDropDownDto, RackEndCustomerMapDto, EndCustomerDto } from '@gtpl/shared-models/masters';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { ShiftsDropDown, StockInTransactionsRequest, StockTypeDropDown, StockTypeStatus, UnitRequest } from '@gtpl/shared-models/common-models';
import { CSVLink } from 'react-csv';
import { SkuDto, TypeOfValueAddition4Dto, TypesOfValueAddition2Dto, Type1Dto, ValueAdditionThreeDto, ValueAdditionFiveDto, ProductDto, FoodTypesDto, FoodTypesDropDownData } from '@gtpl/shared-models/masters';
import { SkuService, Type3Service, TypeofValueAddition4Service, TypesOfValueAddition2Service, Type1Service, ProductService, FoodTypeService, TypeofValueAddition5Service } from '@gtpl/shared-services/masters';
import Item from 'antd/lib/list/Item';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { render } from 'react-dom';

const { Option } = Select;
/* eslint-disable-next-line */
export interface StockInReverseProps {

}


export function StockInReverse(props: StockInReverseProps) {
    const { Text } = Typography;
    const [form] = Form.useForm();
    let stockType = StockTypeDropDown;
    let shift = ShiftsDropDown;
    const [stockOutForm] = Form.useForm();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const stockService = new FGStockService();
    const stockInService = new StockInService();

    const [filtersData, setFiltersData] = useState<any[]>([]);
    const [page, setPage] = React.useState(1);
    const { RangePicker } = DatePicker;
    const [stockinTransactionsData, setStockinTransactionsData] = useState<any>([])


    let reportingCases = 0
    let totalCart = 0;

    useEffect(() => {
        getStockInTransactions()
    }, [])

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                )
            ) : null,
    });

    const getStockInTransactions = () => {
        stockService.getStockInReverseTransactions({plantId:Number(localStorage.getItem("unit_id")),role:localStorage.getItem("role").replace(/"/g, '')}).then((res) => {
            if (res.status) {
                setStockinTransactionsData(res.data)
                setFiltersData(res.data)
                AlertMessages.getSuccessMessage("Data retrived sucessfully")
            } else {
                setStockinTransactionsData([])
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    const openFormWithData=(viewData: any)=>{
        stockInService.reverseStockIn({reportingLogId:viewData.reportingLogId,saleOrderId:viewData.saleOrderId,saleOrderItemId:viewData.saleOrderItemId,shifts:viewData.shift,reportedPerson:viewData.reportedPerson,reportingCases:viewData.reportingCases,reportingDate:viewData.reportingDate,createdUser:viewData.createdUser}).then((res) => {
            if(res.status){
                getStockInTransactions();
                AlertMessages.getSuccessMessage(res.internalMessage);
            }
            else{
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })


    }
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
       
    }
const notallownegitive =() =>{
    
}

    /**
    * 
    * @param pagination 
    * @param filters 
    * @param sorter 
    * @param extra 
    */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const searchData = () => {
        console.log(form.getFieldsValue());
        let selectedData = filtersData;
        console.log(selectedData, 'selectedData1')


        if (form.getFieldValue('reportingDate') != undefined) {
            const selectedFromDate = moment(form.getFieldValue('reportingDate')[0]).format("YYYY-MM-DD");
            const selectedToDate = moment(form.getFieldValue('reportingDate')[1]).format("YYYY-MM-DD");
            console.log(selectedToDate, selectedFromDate);
            if (selectedFromDate && selectedToDate) {
                selectedData = filtersData.filter(item => moment(item.reportingDate).format("YYYY-MM-DD") > selectedFromDate && moment(item.reportingDate).format("YYYY-MM-DD") < selectedToDate)
            }
        }
        console.log(selectedData, 'selectedData')
        setStockinTransactionsData(selectedData);
    }


    let history = useHistory();

    const onReset = () => {
        // form.resetFields();
        form.resetFields();
        getStockInTransactions();
     };

    const columnsSkelton: ColumnProps<any>[] = [

        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            fixed: 'left',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Prod Unit',
            dataIndex: 'unit',
            fixed: 'left',
            filters :[
                {text:"Kakinada", value:"Kakinada"},
                {text:"Unit 1", value:"Unit 1"},
                {text:"Unit 2", value:"Unit 2"},
            ],
            filterMultiple :false,
            onFilter : (value,record)=>{
                 return record.unit == value;
            }
        },
        {
            title: 'Customer PO',
            dataIndex: 'poNumber',
            fixed: 'left',
            // sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
            sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('poNumber'),
        },
        // {
        //   title: 'SO Type',
        //   dataIndex: 'stockType',
        //   render: (value, record, StockOutModel) => {
        //     return record.stockType
        //   },
        //   filters: [
        //     { text: 'Anticipated', value: 'Anticipated' },
        //     { text: 'dummy', value: 'dummy' },
        //     { text: 'SaleOrder', value: 'SaleOrder' },
        //     { text: 'SuperDummy', value: 'SuperDummy' },
        //   ],
        //   filterMultiple: false,
        //   onFilter: (value, record) => {
        //     return record.stockType === value;
        //   },

        // },
        // {
        //   title: 'End Customer',
        //   dataIndex: 'endCustomer',
        //   // sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
        //   //sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('endCustomer'),
        // },
        {
            title: ' Reporting Date',
            dataIndex: 'reportingDate',
            fixed: 'left',
            width: '40px',
            key: 'stockDate',
            render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY:h:mm A")}</span>)
        },
        // {
        //   title: 'Cold Storage',
        //   dataIndex: 'coldStorage',
        //   sorter: (a, b) => a.coldStorage.length - b.coldStorage.length || a.coldStorage.localeCompare(b.coldStorage),
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('coldStorage'),
        // },
        {
            title: 'Reporting Cases',
            dataIndex: "reportingCases",
            fixed: 'left',
            sorter: (a, b) => a.reportingCases.localeCompare(b.reportingCases),
            sortDirections: ['descend', 'ascend'],

        },
      

        {
            title: 'Rack Position',
            dataIndex: 'rackCode',
            sorter: (a, b) => a.rackCode?.localeCompare(b.rackCode),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('rackCode'),
        },
        // {
        //   title: 'Pallet',
        //   dataIndex: 'pallet',
        //   sorter: (a, b) => a.pallet.length - b.pallet.length || a.pallet.localeCompare(b.pallet),
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('pallet'),
        // },
        {
            title: 'Shift',
            dataIndex: 'shift',
            filters: [
                { text: 'Shift A', value: 'shiftA' },
                { text: 'Shift B', value: 'shiftB' },
                { text: 'Shift C', value: 'shiftC' },
                { text: 'General', value: 'general' },
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.shift === value;

            }
        },

        {
            title: 'Loose Pouches',
            dataIndex: 'loosePouches'
        },
        {
            title: 'Pack Style',
            dataIndex: 'packStyle'
        },
        {
            title: "Transaction Type",
            dataIndex: "transactionType"
        },
        {
            title: "Packing status",
            dataIndex: 'packingCompleted',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.packingCompleted === value;
            },

        },
        {
            title: 'Pouches Shortage',
            dataIndex: 'pouchShortage',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.pouchShortage === value;
            },

        },
        {
            title: 'Cartons Shortage',
            dataIndex: 'cartonShortage',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.cartonShortage === value;
            },

        },
        // {
        //   title: 'Pallet Code',
        //   dataIndex: 'palletCode',
        //   // ...getColumnSearchProps('palletCode'),
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },

        // {
        //   title: 'Location',
        //   dataIndex: 'rackPosition',
        //   ...getColumnSearchProps('rackPosition'),
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },
        // {
        //   title: 'Brand Name',
        //   dataIndex: 'brandName',
        //   sorter: (a, b) => { a = a.brandName || ''; b = b.brandName || ''; return a.localeCompare(b); },
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('brandName'),
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },
        // {
        //   title: 'Grade',
        //   dataIndex: 'grade',
        //   // ...getColumnSearchProps('grade'),
        //   render: (value) => (<span style={{float:'right'}}>{value}</span>)
        // },
        // {
        //   title: 'Product Name',
        //   dataIndex: 'productName',
        //   sorter: (a, b) => a.productName.localeCompare(b.productName),
        //   sortDirections: ['descend', 'ascend'],
        //   // ...getColumnSearchProps('productName'),
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },
        {
            title: 'Variant',
            dataIndex: 'variantCode',
            // sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
            //   sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('variantCode'),
            render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
            title: 'Brand',
            dataIndex: "brandName"

        },
        // {
        //   title: 'Pack Style',
        //   dataIndex: 'packStyle',
        //   render: (value, record: any) => {
        //     return <span style={{ textAlign: 'end' }}>
        //       {record.pouches + '*' + record.pouchWeight + record.uomName}
        //     </span>
        //   }

        // },
        // {
        //   title: 'Cartons',
        //   dataIndex: 'totalCartons',
        //   // ...getColumnSearchProps('totalCartons'),
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },
        // {
        //   title: "Quantity(KG's)",
        //   dataIndex: 'quantity',
        //   fixed: 'right',
        //   // ...getColumnSearchProps('palletCode'),
        //   render: (value, record: StockOutModel) => (<span style={{ float: 'right' }}>{Math.round(value)}</span>)
        // },

        {
            title:`Action`,
            dataIndex: 'action',
            render: (text, rowData) => (
              <span>         
                  <Tooltip title='Reverse Stock'><DoubleLeftOutlined  className={'editSamplTypeIcon'}  type="edit" 
                    onClick={() => {
                    //   if (rowData.isActive) {
                        openFormWithData(rowData);
                    //   } else {
                    //     AlertMessages.getErrorMessage('You Cannot Edit Deactivated Departments');
                    //   }
                    }}
                    style={{ color: '#1890ff', fontSize: '16px' }}
                  /></Tooltip>
              </span>
            )
          }

    ];
    return (
        <Card
            title={<span style={{ color: 'white' }}>Stock In Reverse</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

            <Table rowKey={record => record.stockId}
                columns={columnsSkelton} dataSource={stockinTransactionsData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered
                summary={(pageData) => {
                    let totalreportingCases = 0
                    pageData.forEach(({ reportingCases }) => {
                        totalreportingCases += Number(reportingCases);
                        

                    });
                    

                    return (
                        <>
                            <Table.Summary.Row className='tableFooter'>
                                {/* <Table.Summary.Cell index={3} colSpan={12}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell> */}
                                <Table.Summary.Cell index={10} colSpan={3} ><Text >Total</Text></Table.Summary.Cell>
                                <Table.Summary.Cell index={11}><Text style={{ textAlign: 'end' }}>{totalreportingCases}</Text></Table.Summary.Cell>
                                {/* <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell> */}
                            </Table.Summary.Row>
                        </>
                    );
                }
                } />

            {

                stockinTransactionsData.forEach((item) => {
                    reportingCases += (Number(item.reportingCases));
                  
                })
            }
        </Card>

    );
}

export default StockInReverse;
