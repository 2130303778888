import { PaymentmodesDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllPaymentmodesResponseModel extends GlobalResponseObject {
    data?: PaymentmodesDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: PaymentmodesDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

