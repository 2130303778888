
// import { AddressModel, AddressModelDefault  } from "@brandix-apps/shared-models/master-data-management";

export class ContactModel {
    contactId:string;
    landLineNo:string | null;
    mobileNo:string;
    email:string | null;   
    createdAt: Date | any;
    updatedAt: Date | any;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
}


export const ContactModelDefault: ContactModel = {
    contactId: '',
    landLineNo: '',
    mobileNo: '',
    email: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdUser: '',
    updatedUser: '',
    versionFlag: 0,
}