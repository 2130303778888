import { CusAddressInfo } from "./address-model";

export class CustomerDto{
    custId : number;
    clientCode : string;            
    clientName : string; 
    accountType : string;           
    gstNumber : string;            
    phoneNo : string;            
    // street : string;            
    website : string;            
    email : string;            
    // city : string;            
    // appartment : string;            
    // state : string;            
    currency : string;            
    countryId : number;            
    ratePerHour : string;            
    // postalCode : string;            
    contactPerson : string;            
    paymentTerms : string; 
    shipmentTerms : string;
    paymentModeId : number;           
    publicNote : string;            
    privateNote : string;    
    isActive: boolean;
    createdUser : string;
    updatedUser : string;
    cusAddressInfo:CusAddressInfo[]
    
    /**
    * @param custId  number
    * @param clientCode  string            
    * @param clientName  string  
    * @param accountType  string          
    * @param gstNumber  string            
    * @param phoneNo  string            
    * @param street  string            
    * @param website  string            
    * @param email  string            
    * @param city  string            
    * @param appartment  string            
    * @param state  string            
    * @param currency  string            
    * @param countryId  number            
    * @param ratePerHour  string            
    * @param postalCode  string            
    * @param contactPerson  string            
    * @param paymentTerms  string            
    * @param publicNote  string            
    * @param privateNote  string 
    * @param shipmentTerms  string 
    * @param paymentModeId  number              
    * @param isActive boolean
    * @param createdUser  string
    * @param updatedUser  string
    * 
    */
    constructor(custId : number,clientCode : string,clientName : string,accountType : string,gstNumber : string, phoneNo : string, website : string, email : string,currency : string,countryId : number,ratePerHour : string,contactPerson : string,paymentTerms : string,shipmentTerms : string,paymentModeId : number,publicNote : string,privateNote : string,isActive: boolean,
    createdUser : string,updatedUser:string)
    {
        this.custId = custId;
        this.clientCode = clientCode;     
        this.clientName = clientName;
        this.accountType = accountType;  
        this.gstNumber = gstNumber;    
        this.phoneNo = phoneNo;            
        // this.street = street;            
        this.website = website;            
        this.email = email;            
        // this.city = city;            
        // this.appartment = appartment;            
        // this.state = state;            
        this.currency = currency;            
        this.countryId = countryId;            
        this.ratePerHour = ratePerHour;            
        // this.postalCode = postalCode;            
        this.contactPerson = contactPerson;            
        this.paymentTerms = paymentTerms;
        this.shipmentTerms = shipmentTerms;
        this.paymentModeId = paymentModeId;            
        this.publicNote = publicNote;            
        this.privateNote = privateNote;     
        this.isActive= isActive;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;

    }
}
export const CustomersDtoDefault : CustomerDto = {
    custId : 0,
    clientCode : '',            
    clientName : '',
    accountType : '',            
    gstNumber : '',            
    phoneNo : '',            
    // street : '',            
    website : '',            
    email : '',            
    // city : '',            
    // appartment : '',            
    // state : '',            
    currency : '',            
    countryId : 0,            
    ratePerHour : '',            
    // postalCode : '',            
    contactPerson : '',            
    paymentTerms : '',
    shipmentTerms : '',
    paymentModeId : 0,            
    publicNote : '',            
    privateNote : '',            
    isActive: true,
    createdUser : '',
    updatedUser : '',

    cusAddressInfo:[],

    
}; 