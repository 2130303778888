import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Popconfirm,Table, Card, Tooltip, Switch,Input,Button, Tag, } from 'antd';
import Highlighter from 'react-highlight-words';
import { CreateUserDto } from '@gtpl/shared-models/gtpl';
import { ColumnProps } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined,CheckCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';


/* eslint-disable-next-line */
export interface UserGridProps {
  userData: CreateUserDto[];
  viewuser: (user: CreateUserDto, viewOnly: boolean) => void;
  deleteuser: (user: CreateUserDto) => void;
}

export const UserGrid =( props: UserGridProps)=> {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  // console.log('userData');
  // console.log(props.userData)
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    
    },
    // {
    //   title:'S.No',
    //   dataIndex:'Id'
    // },
    {
      title: 'User Name',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('username')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      responsive: ['lg'],
      sorter: (a, b) => a.email?.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('email')
    },
    {
      title: 'Employee',
      dataIndex: ['empInfo','employeeName'],
      sorter: (a, b) => a.employeeId - b.employeeId,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('employeeId')
    },
    {
      title: 'Role',
      dataIndex: 'employeeRole',
      responsive: ['lg'],  
      sorter: (a, b) => a.employeeRole.localeCompare(b.employeeRole),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeRole')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      responsive: ['lg'],
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        console.log(typeof value) // the result is string
        // === is not work
        return record.isActive === value;
      },
    },
    {
      title:`Action     `,
      dataIndex: 'action',
      // responsive: ['lg'],
      render: (text, rowData) => (
        <span>

           {/* <EyeOutlined   className={'viewSamplTypeIcon'} type="eye" 
              onClick={() => { props.viewuser(rowData, true); }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
         
          <Divider type="vertical" /> */}
          <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                console.log(rowData);

                if (rowData.isActive) {
                  props.viewuser(rowData, false);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated User');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          {/* <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  console.log(rowData);
                  props.viewuser(rowData, false);
                } else {

                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated user');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> */}
             <Divider type="vertical" />
            <Popconfirm onConfirm={e => { props.deleteuser(rowData);  }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate user?'
                :  'Are you sure to Activate user?'
            }
            >
               <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
 
 )
}
];
function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
return (
  <Table
  rowKey={record => record.Id}
  columns={sampleTypeColumns}
  dataSource={props.userData}
  pagination={{onChange(current) {setPage(current);}}}
  onChange={onChange}
  bordered
  
/>
);
}

export default UserGrid;
