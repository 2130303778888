export class RMFiltersRequest{
    countryId:number;
    rating:number;
    poNumber:string;
    dataformat?:string
    
    
    constructor(countryId:number,rating:number,poNumber:string,dataformat?:string){
        this.countryId = countryId;
        this.rating = rating;
        this.poNumber = poNumber;
        this.dataformat = dataformat;
    }
}