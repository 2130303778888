export class RmRackPositionIdRequest{
    plantId: number;
    zoneId: number;
    rmRackId: number;
    rmRackPositionId: number;
    isReserved: boolean;
    updatedUser: string;
    saleOrderId: number;
    varientId: number;
    
    constructor(plantId: number,
        zoneId: number,rmRackId:number,rmRackPositionId: number,isReserved: boolean,
        updatedUser: string,saleOrderId: number,varientId:number){
        this.plantId = plantId;
        this.zoneId = zoneId;
        this.rmRackId = rmRackId;
        this.rmRackPositionId = rmRackPositionId;
        this.isReserved = isReserved;
        this.updatedUser = updatedUser;
        this.saleOrderId = saleOrderId;
        this.varientId = varientId;
    }
}