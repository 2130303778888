import { ContainerStatusEnum,BlStatusEnum } from "@gtpl/shared-models/common-models";

export class EtdUpdateRequest{
    etd:Date;
    containerId:number;
    type?:string;
    
    constructor(etd:Date,containerId:number,type:string){
        this.etd = etd;
        this.containerId = containerId;
        this.type = type;
    }
}