import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GrnData } from './raw-material-grn.dto';

export class GrnResponseModel extends GlobalResponseObject{
    data?: GrnData;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //GrnData
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: GrnData) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
