import { SubJobItemDto } from "./sub-job-item-dto";
import { SubJobRevisionCodesRequest } from "./sub-job-revision-code-list-req";

export class SubJobReviseRequest{
    traceCodesData: SubJobRevisionCodesRequest[];
    createdUser:string
    constructor(traceCodesData: SubJobRevisionCodesRequest[],createdUser:string){
    this.traceCodesData = traceCodesData;
    this.createdUser = createdUser;
}

}