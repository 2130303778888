export class HlPercentageRequest{
    [x: string]: any;
    hlPercentageId : number;
    versionFlag: any;
    
    constructor(hlPercentageId : number)
    {
        this.hlPercentageId = hlPercentageId;
        
    }
}
