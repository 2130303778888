import { PlantsEnum } from "@gtpl/shared-models/common-models";
import { GrnItemDefects } from "./grn-item-defects";

export class RmGrnItemDetails {
    farmer: number;
    farmerCode: number;
    quantity: number;
    count: number;
    price: number;
    createdUser: string;
    farmCode?: string;
    farmId?: number;
    farmerName?: string;
    grnId?: number;
    grnItemId?: number;
    indentId: number;
    lotNumber?: string;
    saleOrderId?: number;
    saleOrderItemId?: number;
    softPercentage?: number;
    boxes?: number;
    updatedUser?: string;
    assignToRm?: boolean;
    receivedBoxes?: number;
    indentItemId?: number;
    product?: number;
    looseShell?: string;
    fungusPercentage?: number;
    remarks?:string;
    grnItemDefects?: GrnItemDefects[];
    // isVInspectionCompleted?: boolean;
    // isRmInspectionCompleted?:boolean;
    // isAntibioticsTestCompleted?:boolean;
 /**
  * 
  * @param farmer 
  * @param farmerCode 
  * @param indentId 
  * @param quantity 
  * @param count 
  * @param price 
  * @param createdUser 
  * @param grnItemId 
  * @param farmCode 
  * @param farmId 
  * @param farmerName 
  * @param grnId 
  * @param lotNumber 
  * @param saleOrderId 
  * @param saleOrderItemId 
  * @param softPercentage 
  * @param boxes 
  * @param updatedUser 
  * @param assignToRm 
  * @param receivedBoxes 
  * @param indentItemId 
  * @param product 
  * @param looseShell 
  * @param fungusPercentage 
  * @param remarks 
  * @param grnItemDefects 
  */
    constructor(farmer: number, farmerCode: number, indentId: number, quantity: number, count: number, price: number, createdUser: string, grnItemId?: number, farmCode?: string, farmId?: number, farmerName?: string, grnId?: number, lotNumber?: string, saleOrderId?: number, saleOrderItemId?: number, softPercentage?: number, boxes?: number, updatedUser?: string, assignToRm?: boolean, receivedBoxes?: number, indentItemId?: number, product?: number, looseShell?: string, fungusPercentage?: number, remarks?:string, grnItemDefects?: GrnItemDefects[]) {
        this.farmer = farmer
        this.farmerName = farmerName
        this.farmerCode = farmerCode
        this.farmCode = farmCode
        this.farmId = farmId
        this.quantity = quantity
        this.count = count
        this.price = price
        this.createdUser = createdUser
        this.grnId = grnId
        this.grnItemId = grnItemId
        this.indentId = indentId
        this.lotNumber = lotNumber
        this.saleOrderId = saleOrderId
        this.saleOrderItemId = saleOrderItemId
        this.softPercentage = softPercentage
        this.boxes = boxes
        this.updatedUser = updatedUser
        this.assignToRm = assignToRm
        this.receivedBoxes = receivedBoxes
        this.indentItemId = indentItemId
        this.product = product
        this.fungusPercentage = fungusPercentage
        this.looseShell = looseShell
        this.remarks = remarks
        this.grnItemDefects = grnItemDefects
    }
}

