import { UserRequestDto } from '@gtpl/shared-models/common-models';
import { FileUploadResponse } from '@gtpl/shared-models/logistics';
import { AllSpecSheetResponseModel, SpecSheetDto, specSheetRequest, SpecSheetResponseModel } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';


export class SpecSheetService{
    URL=connection.DEPLOY_URL + '/specsheet';

    async createSpecSheet(specsheetdto: SpecSheetDto): Promise<SpecSheetResponseModel> {
        return await axios.post(this.URL + '/createSpecSheet',specsheetdto)
            .then(res => {
                return res.data
            })
    }

    async  updateSpecSheet(specsheetdto: SpecSheetDto): Promise<SpecSheetResponseModel> {
        return await axios.post(this.URL + '/updateSpecSheet', specsheetdto)
               .then(res => {
                   return res.data
               })
    }
    async getAllSpecSheetDetails(req?:UserRequestDto): Promise<AllSpecSheetResponseModel> {
        return await axios.post(this.URL + '/getAllSpecSheetDetails',req)
            .then(res => {
                return res.data
            })     
    }
    async  activateOrDeactivateSpecSheetDetails(specsheetdto: SpecSheetDto): Promise<SpecSheetResponseModel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/activateOrDeactivateSpecSheetDetails', specsheetdto)
                  .then(res => {
                      return res.data
                  })
      }
      async  getActiveSpecSheetById(Request: specSheetRequest): Promise<SpecSheetResponseModel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/getActiveSpecSheetById', Request)
                  .then(res => {
                      return res.data
                  })
      }
      async  getAllActiveSpecSheetDetails(): Promise<AllSpecSheetResponseModel> {
        return await axios.post(this.URL + '/getAllActiveSpecSheetDetails')
            .then(res => {
                return res.data
            })
    }


    async specSheetFileUpload(file: any): Promise<FileUploadResponse> {
        return await axios.post(this.URL + '/specSheetFileUpload', file)
            .then(res => {
                return res.data
            })
      }

}