export class DocumentsUploadData{
    documentsApprovalId?:number;
    saleOrderId:number;
    saleOrderNumber:string;
    customsInvoiceUploaded:boolean;
    commonInvoiceUploaded:boolean;
    commercialPackingListUploaded:boolean;
    commercialInvoiceUploaded:boolean;
    plantPackingListUploaded:boolean;
    plantInvoiceUploaded:boolean;
    shipmentInstructionsUploaded:boolean;
    form13sUploaded:boolean;
    bLDraftUploaded:boolean;
    freightInvoiceUploaded:boolean;
    oblUploaded:boolean;
    iSFWorksheetUploaded:boolean;
    sIMPUploaded:boolean;
    certificateOfOriginUploaded:boolean;
    declarationOfShrimpUploaded:boolean;

    approverOneStatusUploaded:boolean;
    approverOne:string;

    approverTwoStatusUploaded:boolean;
    approverTwo:string;
}