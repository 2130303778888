export class CellDto{
    cellId?:number;
    cellName:string;
    cellCode:string;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    
    /**
     * 
     * @param cellId 
     * @param cellName 
     * @param cellCode 
     * @param createdUser 
     * @param isActive 
     */
    constructor(cellId:number,cellName:string,cellCode:string,createdUser:string,isActive:boolean){
    this.cellId = cellId;
    this.cellName = cellName;
    this.cellCode = cellCode;
    this.createdUser = createdUser;
    this.isActive = isActive
    }
} 
