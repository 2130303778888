import { ZoneDto } from '../zone_master';

export class RmRackDto {
  rmRackId?: number;
  zoneId: number;
  zoneName:string;
  rmRackName: string;
  rmRackCode: string;
  reservedForDummy: number;
  createdUser: string;
  updatedUser: string;
  isActive: boolean;
  versionFlag: number;
  rmRacksinfo?: ZoneDto;
  plantId?: number;
  plantName?:string;

  /**
   *
   * @param rackId
   * @param zoneId
   * @param rackName
   * @param createdUser
   * @param isActive
   */
  constructor(
    rmRackId: number,
    rmRackName: string,
    rmRackCode: string,
    reservedForDummy: number,
    zoneId: number,
    remarks: string,
    createdUser: string,
    isActive: boolean,
    versionFlag: number,
    rmRacksinfo?: ZoneDto,
    plantId?: number,
    plantName?:string

  ) {
    this.rmRackId = rmRackId;
    this.rmRackName = rmRackName;
    this.rmRackCode = rmRackCode;
    this.reservedForDummy = reservedForDummy;
    this.zoneId = zoneId;
    this.createdUser = createdUser;
    this.isActive = isActive;
    this.rmRacksinfo = rmRacksinfo;
    this.versionFlag = versionFlag;
    this.plantId= plantId;
    this.plantName= plantName;
  }
}
