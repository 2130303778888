import { GlobalResponseObject } from "@gtpl/shared-models/common-models";

export class AuthResponse extends GlobalResponseObject{
    data1?: any;
    // data2?: string;


    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data1?: any,data2?:string) {
        super(status, errorCode, internalMessage);
        this.data1 = data1;
        // this.data2 = data2;

    }
}