import axios, { AxiosRequestConfig } from 'axios';
import connection from './connection';
import {ItemCategoriesDto,ItemCategoryResponse,AllItemCategoryResponse, ItemCategoryRequest, ItemCategoryDropDownDto,ItemCategoryReq, ItemCategoryNameRequest} from '@gtpl/shared-models/masters'
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class ItemCategoryService{
    URL = connection.DEPLOY_URL + '/item-categories';


    async create(dto: ItemCategoriesDto): Promise<ItemCategoryResponse> {
        return await axios.post(this.URL + '/createItemCategory',dto)
            .then(res => {
                return res.data
            })
    }


    async  update(dto: ItemCategoriesDto): Promise<ItemCategoryResponse> {
        return await axios.post(this.URL + '/updateItemCategory', dto)
                        .then(res => {
                            return res.data
                        })
    }


    async  activatedeActivate(Dto: ItemCategoriesDto): Promise<ItemCategoryResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateItemCategory', Dto)
        .then(res => {
            return res.data
        })
    }



    async getAll(req?:UserRequestDto): Promise<AllItemCategoryResponse> {
        
        return await axios.post(this.URL + '/getAllItemCategories',req)
            .then(res => {
                return res.data 
        })     
    }

    async getActiveItemCategories(): Promise<AllItemCategoryResponse> {
        return await axios.post(this.URL + '/getAllItemCategoriesDropDown')
            .then(res => {
                return res.data 
            })     
    }

    /**
     * item category details
     * @param req 
     * @returns 
     */
    async getItemCategoryForId(req:ItemCategoryReq): Promise<ItemCategoryResponse> {
        // console.log(req +'Main Service Call');
        return await axios.post(this.URL + '/getItemCategoryForId', req)
            .then(res => {
                console.log(res);
                return res.data 
            })     
    }

    async getItemCategoryById(itemCategoryRequest:ItemCategoryRequest): Promise<ItemCategoryDropDownDto> {
        return await axios.post(this.URL + '/getItemCategoryById',itemCategoryRequest).then((res) => {
          return res.data;
        });
      }

      async getItemCategoryByName(itemcategoryreq: ItemCategoryNameRequest): Promise<ItemCategoryDropDownDto> {
        return await axios.post(this.URL + '/getItemCategoryByName',itemcategoryreq).then((res) => {
          return res.data;
        });
      }

}