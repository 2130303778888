export class ShiftDto {
    id: number;
    shift: string;
    inTime: any;
    outTime: any;
    dayChange: any;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;

    /**
     * 
     * @param id 
     * @param shift 
     * @param inTime 
     * @param outTime 
     * @param dayChange 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */



    constructor(id: number, shift: string, inTime: any, outTime: any, dayChange: string, isActive: boolean,
        createdAt: Date, createdUser: string, updatedAt: Date, updatedUser: string, versionFlag: number) {
        this.id = id;
        this.shift = shift;
        this.inTime = inTime;
        this.outTime = outTime;
        this.dayChange = dayChange;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}