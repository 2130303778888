import { GetStoreConsumptionItemsDto } from "@gtpl/shared-models/procurement-management";
import { CrrencySymbols, SOItemsDto, UnitsOfWeightInput } from "@gtpl/shared-models/sale-management";
import { StoreConsumptionService } from "@gtpl/shared-services/procurement";
import { CaretRightOutlined, SearchOutlined, FilterOutlined, EditOutlined, RotateRightOutlined, RedoOutlined } from '@ant-design/icons'
import { saleOrder, SaleOrderService } from "@gtpl/shared-services/sale-management";
import { Card, Table } from "antd";
import { ColumnProps, ColumnsType } from "antd/lib/table";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import React, { useEffect, useState } from "react";
import { EmployeeRolesEnum } from "@gtpl/shared-models/common-models";

export interface SaleOrderItemProps {
  saleOrderId: number;
  currencyId: number;
  poNumber:string
}

function SaleOrderItemsView(
  props: SaleOrderItemProps
) {

  const service = new SaleOrderService;
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [isReProcess, setIsReProcess] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [page, setPage] = React.useState(1);
  const username = localStorage.getItem('username')

  useEffect(() => {
    getAllSaleOrderItems();
   
  }, []);
  console.log(username)
  const displayModel = (type, data) => {
    if (type == 'ReProcess') {
      setIsReProcess(true)
    } else {
      setIsReProcess(false)
    }
    setIsModalVisible(true);
    setSelectedSoItem(data);
  };

  const getAllSaleOrderItems = () => {
    console.log(props.saleOrderId)
    console.log(props.poNumber)
    service.getAllSaleOrderItemDetails(new saleOrder(props.saleOrderId,0,props.poNumber) ).then(res => {

      if (res.status) {
        setSoItemData(res.data);
      } else {
        if (res.intlCode) {
          setSoItemData([]);
        } else {
          setSoItemData([]);
        }
      }
    }).catch(err => {
      setSoItemData([]);
    })
  }

  const getTableColumns = () => {
    const Columns: ColumnsType<any> = [
      {
        title:'Job Type',
        dataIndex:'jobType'
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        align: 'left',
        fixed: 'left',
        // sorter: (a, b) => a.brand.localeCompare(b.brand),
        // ...getColumnSearchProps('brand'),
        // render: (text, data, index) => {
        //   console.log(text, data, index)
        //   return <span>{data.brand.children}</span>
        // }
      },
      // {
      //   title: 'Product',
      //   dataIndex: 'itemId',
      //   align: 'left',
      //   // sorter: (a, b) => a.itemId.localeCompare(b.itemId),
      //   // ...getColumnSearchProps('itemId'),
      //   // render: (text, data, index) => {
      //   //   console.log(text, data, index)
      //   //   return <span>{data.itemId.children}</span>
      //   // }
      // },
  
      // {
      //   title: 'Food Type',
      //   dataIndex: 'foodTypeId',
      //   // render: (text, data, index) => {
      //   //   console.log(text, data, index)
      //   //   return <span>{data.foodTypeId.children}</span>
      //   // }
      // },
      // {
      //   title: 'Grade',
      //   dataIndex: 'minGrade',
  
      //   align: 'left',
      //   // sorter: (a, b) => a.minGrade-b.minGrade,
      //   // ...getColumnSearchProps('minGrade'),
      //   render: (text, data, index) => {
      //     console.log(text, data, index)
      //     return <span>{`${text} - ${data.maxGrade} `}</span>
      //   }
      // },
      {
        title: 'Product SKU',
        dataIndex: 'varientId',
  
        align: 'left',
        // sorter: (a, b) => a.varientId.localeCompare(b.varientId),
        // ...getColumnSearchProps('varientId'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text}`}</span>
        }
      },
  
      {
        title: 'Pack Type',
        dataIndex: 'pouches',
  
        align: 'left',
        // sorter: (a, b) => a.pouches-b.pouches,
        // ...getColumnSearchProps('pouches'),
        render: (text, data, index) => {
          const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
          console.log(text, data, index)
          return <span>{`${data.pouches}  *  ${Math.round(data.pouchWeight)} ${uomData.name}`}</span>
        }
      },
  
      {
        title: 'Category',
        dataIndex: 'category',
        align: 'center',
      },
      {
        title: 'Variety',
        dataIndex: 'variety',
        align: 'center',
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{text ? text : 'N/A'}</span>
        }
      },
  
      {
        title: 'No of Cases',
        dataIndex: 'cases',
  
        align: 'left',
        // sorter: (a, b) => a.cases-b.cases,
        // ...getColumnSearchProps('cases'),
      },
      {
        title: 'Job cases',
        dataIndex: 'jobCases',
  
        align: 'left',
        // sorter: (a, b) => a.cases-b.cases,
        // ...getColumnSearchProps('cases'),
      },
      {
        title: 'Style Weighment',
        dataIndex: 'styleWeighment',
  
        align: 'left',
        // sorter: (a, b) => a.styleWeighment.localeCompare(b.styleWeighment),
        // ...getColumnSearchProps('styleWeighment'),
      },
      // {
      //   title: 'Soaking Style',
      //   dataIndex: 'soakingStyle',
      //   align: 'left',
      //   // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      //   // ...getColumnSearchProps('soakingStyle'),
      // },
      {
        title: 'Dispatched Quantity',
        dataIndex: 'dispatchedQty',
        align: 'left',
        // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
        // ...getColumnSearchProps('soakingStyle'),
      },
      {
        title: 'Invoice Amount',
        dataIndex: 'invAmount',
        align: 'right',
        // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
        // ...getColumnSearchProps('soakingStyle'),
        render: (text, record) => {
          return CrrencySymbols.find(item => item.id == props.currencyId).symbol + " " + record.invAmount
        },
      },
      // {
      //   title: 'Case Weight',
      //   dataIndex: 'caseWeight',
      //   sorter: (a, b) => a.caseWeight-b.caseWeight,
      //   ...getColumnSearchProps('caseWeight'),
      //   render: (text, data, index) => {
      //     // console.log(itemsdata[index]);
      //     return <span>{`${text} ${data.uomId}`}</span>
      //   }
      // },
      {
        title: 'Final(FG)',
        dataIndex: 'finalFG',
        sorter: (a, b) => a.finalFG - b.finalFG,
      },
      {
        title: 'Dummy',
        dataIndex: 'dummyStock',
        sorter: (a, b) => a.dummyStock - b.dummyStock,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        sorter: (a, b) => a.balance - b.balance,
      },
      {
        title: 'Re-Packing Inventory',
        dataIndex: 'rePackQty',
        key: 'rePackQty',
        // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
        // ...getColumnSearchProps('pouchWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text ? text.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}`}&nbsp;&nbsp;<RotateRightOutlined onClick={() => displayModel('RePack', data)} type='edit' style={{ color: 'blue' }}>Re-Pack</RotateRightOutlined></span>
        }
      },
      {
        title: 'Re-Processing Inventory',
        dataIndex: 'reProcessQty',
        key: 'reProcessQty',
        // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
        // ...getColumnSearchProps('pouchWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text ? text.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}`}&nbsp;&nbsp;<RedoOutlined onClick={() => displayModel('ReProcess', data)} type='edit' style={{ color: 'red' }}>Re-Process</RedoOutlined></span>
        }
      },
  
      {
        title: 'Total Weight',
        dataIndex: 'netWeight',
  
        align: 'left',
        // sorter: (a, b) => a.netWeight-b.netWeight,
        // ...getColumnSearchProps('netWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId)
          return <span>{`${Math.round(text).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ${uomData.name}`}</span>
        }
      },
      {
        title: 'Rm Count',
        dataIndex: 'rmCount',
        sorter: (a, b) => a.unitPrice - b.unitPrice,
  
      },
      {
        title: 'Total Amount',
        dataIndex: 'netAmount',
        fixed: 'right',
        // width:'100',
        align: 'right',
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{CrrencySymbols.find(item => item.id == props.currencyId).symbol + ` ${Math.round(data.netAmount).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}</span>
        }
        // sorter: (a, b) => a.netAmount- b.netAmount,
        // ...getColumnSearchProps('netAmount'),
      },
  
    ];
    const priceColumn: any =
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      fixed:'right',
      align: 'left',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{CrrencySymbols.find(item => item.id == props.currencyId).symbol + ` ${data.unitPrice}`}</span>
      }
      // sorter: (a, b) => a.cases-b.cases,
      // ...getColumnSearchProps('cases'),
    }
    if (username != `"superadmin"`) return Columns
    return [...Columns, priceColumn]
  }
  const getAmountDeletedColumns = () => {
    const Columns: ColumnsType<any> = [
      {
        title:'Job Type',
        dataIndex:'jobType'
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        align: 'left',
        fixed: 'left',
        // sorter: (a, b) => a.brand.localeCompare(b.brand),
        // ...getColumnSearchProps('brand'),
        // render: (text, data, index) => {
        //   console.log(text, data, index)
        //   return <span>{data.brand.children}</span>
        // }
      },
      // {
      //   title: 'Product',
      //   dataIndex: 'itemId',
      //   align: 'left',
      //   // sorter: (a, b) => a.itemId.localeCompare(b.itemId),
      //   // ...getColumnSearchProps('itemId'),
      //   // render: (text, data, index) => {
      //   //   console.log(text, data, index)
      //   //   return <span>{data.itemId.children}</span>
      //   // }
      // },
  
      // {
      //   title: 'Food Type',
      //   dataIndex: 'foodTypeId',
      //   // render: (text, data, index) => {
      //   //   console.log(text, data, index)
      //   //   return <span>{data.foodTypeId.children}</span>
      //   // }
      // },
      // {
      //   title: 'Grade',
      //   dataIndex: 'minGrade',
  
      //   align: 'left',
      //   // sorter: (a, b) => a.minGrade-b.minGrade,
      //   // ...getColumnSearchProps('minGrade'),
      //   render: (text, data, index) => {
      //     console.log(text, data, index)
      //     return <span>{`${text} - ${data.maxGrade} `}</span>
      //   }
      // },
      {
        title: 'Product SKU',
        dataIndex: 'varientId',
  
        align: 'left',
        // sorter: (a, b) => a.varientId.localeCompare(b.varientId),
        // ...getColumnSearchProps('varientId'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text}`}</span>
        }
      },
  
      {
        title: 'Pack Type',
        dataIndex: 'pouches',
  
        align: 'left',
        // sorter: (a, b) => a.pouches-b.pouches,
        // ...getColumnSearchProps('pouches'),
        render: (text, data, index) => {
          const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
          console.log(text, data, index)
          return <span>{`${data.pouches}  *  ${Math.round(data.pouchWeight)} ${uomData.name}`}</span>
        }
      },
  
      {
        title: 'Category',
        dataIndex: 'category',
        align: 'center',
      },
      {
        title: 'Variety',
        dataIndex: 'variety',
        align: 'center',
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{text ? text : 'N/A'}</span>
        }
      },
  
      {
        title: 'No of Cases',
        dataIndex: 'cases',
  
        align: 'left',
        // sorter: (a, b) => a.cases-b.cases,
        // ...getColumnSearchProps('cases'),
      },
      {
        title: 'Job cases',
        dataIndex: 'jobCases',
  
        align: 'left',
        // sorter: (a, b) => a.cases-b.cases,
        // ...getColumnSearchProps('cases'),
      },
      {
        title: 'Style Weighment',
        dataIndex: 'styleWeighment',
  
        align: 'left',
        // sorter: (a, b) => a.styleWeighment.localeCompare(b.styleWeighment),
        // ...getColumnSearchProps('styleWeighment'),
      },
      // {
      //   title: 'Soaking Style',
      //   dataIndex: 'soakingStyle',
      //   align: 'left',
      //   // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      //   // ...getColumnSearchProps('soakingStyle'),
      // },
      {
        title: 'Dispatched Quantity',
        dataIndex: 'dispatchedQty',
        align: 'left',
        // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
        // ...getColumnSearchProps('soakingStyle'),
      },
      {
        title: 'Invoice Amount',
        dataIndex: 'invAmount',
        align: 'right',
        // sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
        // ...getColumnSearchProps('soakingStyle'),
        render: (text, record) => {
          return CrrencySymbols.find(item => item.id == props.currencyId).symbol + " " + record.invAmount
        },
      },
      // {
      //   title: 'Case Weight',
      //   dataIndex: 'caseWeight',
      //   sorter: (a, b) => a.caseWeight-b.caseWeight,
      //   ...getColumnSearchProps('caseWeight'),
      //   render: (text, data, index) => {
      //     // console.log(itemsdata[index]);
      //     return <span>{`${text} ${data.uomId}`}</span>
      //   }
      // },
      {
        title: 'Final(FG)',
        dataIndex: 'finalFG',
        sorter: (a, b) => a.finalFG - b.finalFG,
      },
      {
        title: 'Dummy',
        dataIndex: 'dummyStock',
        sorter: (a, b) => a.dummyStock - b.dummyStock,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        sorter: (a, b) => a.balance - b.balance,
      },
      {
        title: 'Re-Packing Inventory',
        dataIndex: 'rePackQty',
        key: 'rePackQty',
        // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
        // ...getColumnSearchProps('pouchWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text ? text.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}`}&nbsp;&nbsp;<RotateRightOutlined onClick={() => displayModel('RePack', data)} type='edit' style={{ color: 'blue' }}>Re-Pack</RotateRightOutlined></span>
        }
      },
      {
        title: 'Re-Processing Inventory',
        dataIndex: 'reProcessQty',
        key: 'reProcessQty',
        // sorter: (a, b) => a.pouchWeight-b.pouchWeight,
        // ...getColumnSearchProps('pouchWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          return <span>{`${text ? text.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}`}&nbsp;&nbsp;<RedoOutlined onClick={() => displayModel('ReProcess', data)} type='edit' style={{ color: 'red' }}>Re-Process</RedoOutlined></span>
        }
      },
  
      {
        title: 'Total Weight',
        dataIndex: 'netWeight',
  
        align: 'left',
        // sorter: (a, b) => a.netWeight-b.netWeight,
        // ...getColumnSearchProps('netWeight'),
        render: (text, data, index) => {
          console.log(text, data, index)
          const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId)
          return <span>{`${Math.round(text).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ${uomData.name}`}</span>
        }
      },
      {
        title: 'Rm Count',
        dataIndex: 'rmCount',
        sorter: (a, b) => a.unitPrice - b.unitPrice,
  
      },
    ];
    const priceColumn: any =
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      fixed:'right',
      align: 'left',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{CrrencySymbols.find(item => item.id == props.currencyId).symbol + ` ${data.unitPrice}`}</span>
      }
      // sorter: (a, b) => a.cases-b.cases,
      // ...getColumnSearchProps('cases'),
    }
    if (username != `"superadmin"`) return Columns
    return [...Columns, priceColumn]
  }

 
  return (
    <>
      <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>SALE ORDER ITEMS </span>}></Card>


      <Table
        key={Date.now()}
        // {(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.HO_SALES_MANAGER)?
        //  columns={getAmountDeletedColumns()}:columns={getTableColumns()}}
        
        columns={((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.HO_SALES_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PLANT_SALES_MANAGER))?getTableColumns():getAmountDeletedColumns()}
        dataSource={soItemData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        // onChange={onChange}
        scroll={{ x: true }}
        size="small"
        bordered
      ></Table>


    </>
  );


  // const storeConsumptionItems: ColumnProps<any>[] = [
  //     {
  //         title: 'Item',
  //         dataIndex: "itemName",
  //         width: 150,
  //         align: 'left',
  //     },
  //     {
  //         title: 'Consumed Quantity',
  //         dataIndex: 'quantity',
  //         width: 150,
  //         align: 'left',
  //     },
  // ];
  // return (
  //     <>
  //         <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>Store Consumption Items</span>}></Card>
  //         <Table
  //             key={Date.now()}
  //             className="components-table-nested"
  //             columns={storeConsumptionItems}
  //             dataSource={storeConsumptionItemsData}
  //         />

  //     </>
  // );
}
export default SaleOrderItemsView;