export class SaleOrderIdRequest {
    saleOrderId:number;
    plantId?:number;
    itemSubCategoryId?:number;
    state?:string;
    isPreperationRequired?:string;
    fromSaleOrder?:number;
    jobType? : string;
    saleOrderItemId? : number;
    constructor(saleOrderId: number, plantId?: number,itemSubCategoryId?:number,state?:string,isPreperationRequired?:string,fromSaleOrder?:number,jobType? : string, saleOrderItemId? : number) {
        this.saleOrderId = saleOrderId;
        this.plantId = plantId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.state = state;
        this.isPreperationRequired = isPreperationRequired;
        this.fromSaleOrder = fromSaleOrder;
        this.jobType = jobType;
        this.saleOrderItemId = saleOrderItemId;
    }
}