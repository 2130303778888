import { GlobalResponseObject } from "../global-response-object";
import { SoYearWisePlanningDetails } from "./so-year-wise-planning";

export class SoYearWisePlanningDetailsResponse extends GlobalResponseObject{
    data?:SoYearWisePlanningDetails[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoYearWisePlanningDetails[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}