
import {  CommonResponse, RmRackAckResponse,RmRackPositionIdReq,RmRackPositionIdRequestDto,RmRackPositionModel,RmRackPostionReq, RMrackStatusRequest, RmReserveRackRequest } from '@gtpl/shared-models/masters';
import { PlantIdRequest, RackReserveRequest, WarehouseDashboardResponseModel } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import connection from './connection';

export class RmRackPositionService {
URL = connection.DEPLOY_URL + '/rm-rack-position';

  async createRmRackPosition(rackDto: RmRackPositionModel): Promise<RmRackAckResponse> {
      return await axios.post(this.URL + '/createRmRackPosition',rackDto)
          .then(res => {
              return res.data
          })
  }
  async  updateRmRackPosition(rackDto: RmRackPositionModel): Promise<RmRackAckResponse> {
    return await axios.post(this.URL + '/updateRackPosition', rackDto)
           .then(res => {
               return res.data
           })
}
  async getAllRmRacksPositions(): Promise<RmRackAckResponse> {
    return await axios.post(this.URL + '/getAllRmRacksPositions')
        .then(res => {
            return res.data
        })     
}
async  ActivateorDeactivateRmRack(rackDto: RmRackPostionReq): Promise<RmRackAckResponse> {
      return await axios.post(this.URL + '/activateorDeactivateRmRack', rackDto)
              .then(res => {
                  return res.data
              })
 }
 async getWarehouseRackDetails(req: RackReserveRequest): Promise<WarehouseDashboardResponseModel> {
    return await axios.post(this.URL + '/getWarehouseRackDetails', req)
        .then(res => {
            return res.data
        })
}
async getReserveRackPosition(rackPositionIdRequest: RmReserveRackRequest): Promise<CommonResponse> {
    console.log(rackPositionIdRequest)
    return await axios.post(this.URL + '/getReserveRackPosition', rackPositionIdRequest)
    .then(res => {
        return res.data
    })
}
async getRmRackPostionsForLocationMapping(req: PlantIdRequest): Promise<CommonResponse> {
    console.log(req)
    return await axios.post(this.URL + '/getRmRackPostionsForLocationMapping', req)
    .then(res => {
        return res.data
    })
}
async getReverseLocationAvailability(req: RmRackPositionIdRequestDto): Promise<CommonResponse> {
    console.log(req)
    return await axios.post(this.URL + '/getReverseLocationAvailability', req)
    .then(res => {
        return res.data
    })
}

async updateRmLocationStatus(req: RMrackStatusRequest): Promise<CommonResponse> {
    console.log(req)
    return await axios.post(this.URL + '/updateRmLocationStatus', req)
    .then(res => {
        return res.data
    })
}

async getRmZones(req:PlantIdRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getRmZones',req)
    .then(res => {
        return res.data
    })
}

async getLocationsForStockEntryScreen(): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getLocationsForStockEntryScreen')
    .then(res => {
        return res.data
    })
}

async updateLocationData(dto:RmRackPositionIdReq):Promise<CommonResponse>{
    return await axios.post(this.URL + '/updateLocationData',dto)
    .then(res=>{
        return res.data
    })
}

//  async getRackPositionsForRackMasterId(rackMasterId :RacksForRackId): Promise<RackResponseModel> {
//   return await axios.post(this.URL + '/getRackPositionsForRackMasterId',rackMasterId)
//       .then(res => {
//           return res.data 
//       })     
// }
//  async getRacksForRackId(racksForRackId :RacksForRackId): Promise<RackPositionResponseModel> {
//      console.log(racksForRackId.rackId);
//   return await axios.post(this.URL + '/getRacksForRackId',racksForRackId)
//       .then(res => {
//           console.log(res.data);
//           return res.data 
//       })     
// }

// async getRacksForZoneId(racksForZoneId :RacksForZoneId): Promise<RackPositionResponseModel> {
//     console.log(racksForZoneId.zoneId);
//  return await axios.post(this.URL + '/getRacksForZoneId',racksForZoneId)
//      .then(res => {
//          console.log(res.data);
//          return res.data 
//      })     
// }

//  async updateRackPostionOccupancy(racksForRackId :RacksForRackId): Promise<CommonResponse> {
//   return await axios.post(this.URL + '/updateRackPostionOccupancy',racksForRackId)
//       .then(res => {
//           return res.data 
//       })     
// }

// async freeRackPostionOccupancy(racksForRackId :RacksForRackId): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/freeRackPostionOccupancy',racksForRackId)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async updateRackStatus(plantRackPosReq: UpdateRackpositionRequest):Promise<boolean> {
//     return await axios.post(this.URL + '/updateRackStatus',plantRackPosReq)
//         .then(res => {
//             console.log(res);
//             return res.data 
//         })     
//   }
// async getReserveRackPosition(rackPositionIdRequest: RackPositionIdRequest): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/getReserveRackPosition',rackPositionIdRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getSuggestLocation(locationsRequest: LocationRequestDto): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/getSuggestLocation',locationsRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async reserveRackPosition(rackSuggestionRequest: LocationRequestDto):Promise<any>{
//     return await axios.post(this.URL + '/reserveRackPosition',rackSuggestionRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getUnit1RackPositions(plantIdRequest: PlantIdRequest):Promise<any>{
//     return await axios.post(this.URL + '/getUnit1RackPositions',plantIdRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async reserveRackPositions(rackSuggestionRequest: LocationRequestDto):Promise<any>{
//     return await axios.post(this.URL + '/reserveRackPositions',rackSuggestionRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getAvailableRackPositionsForZoneId(req: ZoneIdRequest): Promise<RackPositionResponseModel> {
//     return await axios.post(this.URL + '/getAvailableRackPositionsForZoneId',req)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getAvailableRackPositionsForPlantId(req: LocationRequestDto): Promise<RackPositionResponseModel> {
//     return await axios.post(this.URL + '/getAvailableRackPositionsForPlantId',req)
//         .then(res => {
//             return res.data 
//         })     
//   }

//   async getLocationByZRL(req: RackZoneLevelRequest) {
//     return await axios.post(this.URL + '/getLocationByZRL',req)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getUnreserveRackPositions(req:SaleOrderIdRequest): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/getUnreserveRackPositions',req)
//         .then(res => {
//             return res.data 
//         })     
//   }
//   async getRackStatus(req:RackStatusRequest): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/getRackStatus',req)
//         .then(res => {
//             return res.data 
//         })     
//   }

//   async updateOccupancyAfterTransfer(req:RackPostionReq): Promise<CommonResponse> {
//     return await axios.post(this.URL + '/updateOccupancyAfterTransfer',req)
//         .then(res => {
//             return res.data 
//         })     
//   }
  
//   async getReservedRackPositionsForSO(reservedRacksSoRequest: ReservedRacksSoRequest): Promise<RackPositionResponseModel> {
//     return await axios.post(this.URL + '/getReservedRackPositionsForSO',reservedRacksSoRequest)
//         .then(res => {
//             return res.data 
//         })     
//   }

  

} 