import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SupplierDTO } from './supplier.dto';

export class SupplierResponseModel extends GlobalResponseObject{
    data?: SupplierDTO;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SupplierDTO){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
}
}
