export class TransferItemsDetails {
    itemCategory: string;
    itemCategoryId: number;
    itemSubCategory: string;
    itemSubCategoryId: number;
    item: string;
    itemId: number;
    size: string;
    sizeId: number;
    transferredQty: number;
    ipQty: number;
    opQty: number;
    unitPrice?: number;
    unitId?: number;
    brandId?:number;
    saleOrderId?:number;
    packingStockTransferItemId?:number;
    logId?:boolean;
    allocatedQty?:number;


    // constructor(itemCategory: string,
    //     itemCategoryId: number,
    //     itemSubCategory: string,
    //     itemSubCategoryId: number,
    //     item: string,
    //     itemId: number,
    //     size: string,
    //     sizeId: number,
    //     transferredQty: number,
    //     ipQty: number,
    //     opQty: number) {
    //     this.itemCategoryId = itemCategoryId;
    //     this.itemCategory = itemCategory;
    //     this.itemSubCategoryId = itemSubCategoryId;
    //     this.itemSubCategory = itemSubCategory;
    //     this.item = item;
    //     this.itemId = itemId;
    //     this.size = size;
    //     this.sizeId = sizeId;
    //     this.transferredQty = transferredQty;
    //     this.ipQty = ipQty;
    //     this.opQty = opQty;
    // }
}