import { ConversionType } from "@gtpl/shared-models/common-models";

export class ProductConversionModel {
    productConversionId: number;
    conversionType: ConversionType;
    fromSaleOrderId: number;
    fromSaleOrderItemId: number;
    fromStockId: number;
    fromQuantity: number;
    toSaleOrderId: number;
    toSaleOrderItemId: number;
    toStockId: number;
    toQuantity: number;
    conversionQuantity: number;
    reason: string;
    remarks: string;
    createdUser?: string | null;
    createdAt?: Date;
    locationId?: any;
    unitId?: number;
    itemSubCategoryId?: number;
    soStatus?: string;


    constructor(productConversionId: number,
        conversionType: ConversionType,
        fromSaleOrderId: number,
        fromSaleOrderItemId: number,
        fromStockId: number,
        fromQuantity: number,
        toSaleOrderId: number,
        toSaleOrderItemId: number,
        toStockId: number,
        toQuantity: number,
        conversionQuantity: number,
        reason: string,
        remarks: string,
        createdUser?: string | null,
        createdAt?: Date,
        locationId?: any,
        unitId?: number,
        itemSubCategoryId?: number,
        soStatus?: string

    ) {
        this.productConversionId = productConversionId;
        this.conversionType = conversionType;
        this.fromSaleOrderId = fromSaleOrderId;
        this.fromSaleOrderItemId = fromSaleOrderItemId;
        this.fromStockId = fromStockId;
        this.fromQuantity = fromQuantity;
        this.toSaleOrderId = toSaleOrderId;
        this.toSaleOrderItemId = toSaleOrderItemId;
        this.toStockId = toStockId;
        this.toQuantity = toQuantity;
        this.reason = reason;
        this.remarks = remarks;
        this.createdUser = createdUser;
        this.conversionQuantity = conversionQuantity;
        this.createdAt = createdAt;
        this.locationId = locationId;
        this.unitId = unitId;
        this.itemSubCategoryId = itemSubCategoryId
        this.soStatus = soStatus

    }
}
