import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { HatcheriesDropDownData } from './hatcheries-drop-down-model';

export class HatcheriesDropDownDataResponseModel extends GlobalResponseObject{
    data?: HatcheriesDropDownData[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //HatcheriesDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: HatcheriesDropDownData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}