import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { CertificatesDto } from '.';

export class CertificatesResponseModel extends GlobalResponseObject{
    data?: CertificatesDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CertificatesDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
}
}
