import axios from 'axios';
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/sale-management';
import { indentformDto, IndentQuantityRequestDto, RequestforIndentItem } from '@gtpl/shared-models/procurement-management';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
import { UnitRequest } from '@gtpl/shared-models/common-models';

export class IndentformService{
    URL = connection.DEPLOY_URL + '/indentform';

    async createIndentFormDetails(dto:indentformDto) : Promise<CommonResponse>{
        console.log("hhhhhhhhhhhhhhhhhhhhhh")
        return await axios.post(this.URL + '/createIndentFormDetails', dto)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async  updateindentform(dto: indentformDto): Promise<CommonResponse> {
        console.log('123')
        return await axios.post(this.URL + '/updateindentform', dto)
               .then(res => {
                   return res.data
               })
    }
    async getallindentitemDetails(req:RequestforIndentItem) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getallindentitemDetails', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getIndentsDropDown(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getIndentsDropDown', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getindentformdetails(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getindentformdetails',req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getAllIndentdataforindentmaterialId(req:RequestforIndentItem) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getAllIndentdataforindentmaterialId', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getIndentItemDetailsById(req:RequestforIndentItem) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getIndentItemDetailsById', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async CancelIndentformaterialPreparation(req:RequestforIndentItem) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/CancelIndentformaterialPreparation', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    
    async getQuantityValidationforIndent(req:IndentQuantityRequestDto) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getQuantityValidationforIndent', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
}