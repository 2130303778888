export class StockTransferModel{
    transferType:string;
    stockIds:string[];
    saleOrderId?:number;
    saleOrderItemId?:number;
    toPlant?:number;
    zoneId?:number;
    rackPostion?:number;
    createdUser?:string;
    selectedRows?:any[];
    constructor(transferType:string,stockIds:string[],
        saleOrderId?:number,
        saleOrderItemId?:number,
        toPlant?:number,
        zoneId?:number,
        rackPostion?:number,
        createdUser?:string,selectedRows?:any[]){
            this.transferType = transferType;
            this.stockIds = stockIds;
            this.saleOrderId = saleOrderId;
            this.saleOrderItemId = saleOrderItemId;
            this.toPlant = toPlant;
            this.zoneId = zoneId;
            this.rackPostion = rackPostion;
            this.createdUser = createdUser;
            this.selectedRows = selectedRows;
        }
}