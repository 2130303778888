export class PackingMethodCapacityRequest{
    noOfPouches:number;
    pouchesPerRow:number;
    uomId:number;
    packingMethodId?:number;
   
   /**
    * 
    * @param noOfPouches 
    * @param pouchesPerRow 
    */
    constructor(noOfPouches:number,pouchesPerRow:number,uomId:number,packingMethodId?:number){
    this.noOfPouches = noOfPouches;
    this.pouchesPerRow = pouchesPerRow;
    this.uomId = uomId;
    this.packingMethodId = packingMethodId;
    }
} 


