import { InvoiceStatusEnum, SoLifeCycleStatusEnum, SoProcessTypesEnum, SoTypesEnum } from "@gtpl/shared-models/common-models";

export class SaleOrderTrackDto {
    saleOrderId: number;
    saleOrderNumber: string;
    customer:string;
    product:string;
    orderedDate:Date;
    expectedEndDate:Date;
    orderQty:number;
    cost:number;
    destination:string;
    status:string;
    rmStock:number;
    process:string;
    packing:string;
    qualityAssurance:string;
    logistics:string;
    doc:string;
    fgStock:number;
    unit:number;
    poDate:Date;
    poNumber:string;
    endCustomername:string;
    countryName:string;
    totnetWeight:number;
    loadType:boolean;
    currencyId:number;
    currency:string;
    uom:number;
    isInvoiced:string;
    buyerPONo:string;
    soType:SoTypesEnum;
    soProcessType:SoProcessTypesEnum;
    actualEtd:Date;
    prodStatus:InvoiceStatusEnum;
    endCustomerId?:number;
    unitId?:number;
    processingUnitId?:number;
    processingUnitName?:string;
    jobType?:string;
    dispatchStatus?:boolean;
    lifeCyclestatus?:SoLifeCycleStatusEnum;
    isSubContract?:string;
    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     * @param destination 
     * @param customer 
     * @param product 
     * @param orderedDate 
     * @param expectedEndDate 
     * @param orderQty 
     * @param cost 
     * @param status 
     * @param rmStock 
     * @param process 
     * @param packing 
     * @param qualityAssurance 
     * @param logistics 
     * @param doc 
     * @param fgStock 
     * @param unit 
     * @param poDate 
     * @param poNumber 
     * @param endCustomername 
     * @param countryName 
     * @param totnetWeight 
     * @param currencyId 
     * @param currency 
     * @param uom 
     * @param isInvoiced 
     * @param buyerPONo 
     * @param soType 
     */
    constructor(saleOrderId: number,saleOrderNumber: string,destination:string,customer:string,product:string,orderedDate:Date,expectedEndDate:Date,orderQty:number,cost:number,status:string,rmStock:number,process:string,packing:string,qualityAssurance:string,logistics:string,doc:string,fgStock:number,unit:number,poDate:Date,poNumber:string,endCustomername:string,countryName:string,totnetWeight:number,
        loadType:boolean,
        currencyId:number,currency:string,uom:number,isInvoiced:string,buyerPONo:string,soType:SoTypesEnum,soProcessType:SoProcessTypesEnum,actualEtd:Date,prodStatus:InvoiceStatusEnum,endCustomerId?:number,unitId?:number,processingUnitId?:number,processingUnitName?:string,dispatchStatus?:boolean,lifeCyclestatus?:SoLifeCycleStatusEnum,isSubContract?:string){ 
            this.saleOrderId = saleOrderId
            this.saleOrderNumber = saleOrderNumber
            this.customer= customer;
            this.product= product;
            this.orderedDate= orderedDate;
            this.expectedEndDate= expectedEndDate;
            this.orderQty= orderQty;
            this.cost= cost;
            this.destination= destination;
            this.status= status;
            this.rmStock= rmStock;
            this.process= process;
            this.packing= packing;
            this.qualityAssurance= qualityAssurance;
            this.logistics= logistics;
            this.doc= doc;
            this.fgStock= fgStock;
            this.unit=unit;
            this.poDate=poDate;
            this.poNumber=poNumber;
            this.endCustomername=endCustomername;
            this.countryName=countryName;
            this.totnetWeight=totnetWeight;
            this.loadType=loadType;
            this.currencyId=currencyId;
            this.currency=currency;
            this.uom=uom;
            this.isInvoiced=isInvoiced;
            this.buyerPONo=buyerPONo;
            this.soType=soType;
            this.soProcessType=soProcessType;
            this.actualEtd=actualEtd;
            this.prodStatus=prodStatus;
            this.endCustomerId=endCustomerId;
            this.unitId=unitId;
            this.processingUnitId=processingUnitId;
            this.processingUnitName=processingUnitName;
            this.dispatchStatus = dispatchStatus;
            this.lifeCyclestatus = lifeCyclestatus;
            this.isSubContract = isSubContract;
        }
    }
    

    export class SaleOrderFiltersDto{
        selectedEstimatedFromDate:string;
        selectedEstimatedToDate:string;
        saleOrderStatus:string;
        selectedEndCustomer:number;
        unit:number; 
        prodUnit?:number; 
        country?:number[];
        destinations?:number[];
        selectedCreatedFromDate?:string;
        selectedCreatedToDate?:string;
        masterBrandId?:number;
        varientCode?:number;
        lifeCyclestatus?:string[];
    }