
import { SaleOrderService, SoItemReq, SoProcessTypeREQ,StoreConsumptionItemQtyReq } from '@gtpl/shared-services/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
const { Option } = Select;
import './indent-form.css';
import { ItemsDropDownDto, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ItemsService } from '@gtpl/shared-services/masters';
import { SoProcessTypesEnum, StockTypeDropDown, stockTypeEnum, TransactionType, TransactionTypeDropDown, UnitRequest } from '@gtpl/shared-models/common-models';
import { PackingIndentService, StockService, StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { IndentFormDTO } from '@gtpl/shared-models/procurement-management';
import { async } from 'rxjs/internal/scheduler/async';
import { Link, Redirect, useHistory } from 'react-router-dom';

/* eslint-disable-next-line */

export const IndentAgainstDropDown = [
  { name: 'Dummy', value: 'dummy' }, { name: 'Sale Order', value: 'SaleOrder' }, { name: 'Super Dummy', value: 'superDummy' }
]
export interface IndentModuleFormProps {
  getItemsPanel: (saleOrderId: number) => void;
  saleOrderData: SaleOrderDropDownDto[];
}

export function IndentModuleForm(props: IndentModuleFormProps) {
  let stockTypes = IndentAgainstDropDown;
  const [form] = Form.useForm();
  const [saleorderData, setSaleOrderData] = useState<any[]>([])
  const [productsData,setProductsData] = useState<any[]>([])
  const [packingItems,setPackingItems] = useState<any[]>([])
  const [itemQty,setItemQty] = useState<any>()
  const [preparedQty,setPreparedQty] = useState<any>()
  let history = useHistory();

 
  let transactionTypeDropDown = TransactionTypeDropDown;

  const itemService = new ItemsService;
  const [items, setItems] = useState<any[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const service = new PackingIndentService();
  const soService = new SaleOrderService();
  const stockService = new StockService();

  useEffect(() => {
    getItems();
    // console.log('count--',indentCount.indCount)


    //form.setFieldsValue('indentNumber')
  }, [])

  const onReset = () => {
    form.resetFields();
  }

  

  const getItems = () => {
    setItems([]);
    itemService.getItemsDropDownData().then((res) => {
      if (res.status) {
        setItems(res.data);
      } else {
        setItems([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const getCurrentMonthsProductData = () => {
    form.resetFields(['saleOrderItemId'])
    setProductsData([])
    const req = new SoProcessTypeREQ()
    req.unitId = Number(localStorage.getItem('unit_id'))
    req.barcode = (form.getFieldValue('barcode'))
    const stockType = form.getFieldValue('stockType')
    if(stockType){
      stockType != stockTypeEnum.Dummy ? req.soProcessType = SoProcessTypesEnum.MTO : req.soProcessType = SoProcessTypesEnum.MTS
    }
    soService.getCurrentMonthSaleordersForIndentForm(req).then((res) => {
      if(res.status){
        setProductsData(res.data)
        if(res.data.length == 1){
          form.setFieldsValue({saleOrderItemId : res.data[0].soItemId})
          getPAckingItemsDropdown()
        }
      }else{
        setProductsData([])
      }
    })
  }

  const getPAckingItemsDropdown = ( ) => {
    form.resetFields(['itemId'])
    setPackingItems([])
    const req = new SoItemReq()
    req.saleOrderItemId = form.getFieldValue('saleOrderItemId')
    soService.getPackingItemsFormSoItem(req).then((res) => {
      if(res.status){
        setPackingItems(res.data)
      }else{
        setPackingItems([])
      }
    })
  }

  const getRequiredQuantityForSelectedItem = () => {
    const req = new StoreConsumptionItemQtyReq()
    req.saleOrderItemId = form.getFieldValue('saleOrderItemId');
    req.itemId = form.getFieldValue('itemId')
    soService.getRequiredPMQtyForSoItem(req).then((res) => {
      if(res.status){
        setItemQty(res.data[0].requiredQuantity)
        setPreparedQty(res.data[0].preparedQuantity )
      }else{
        setItemQty([])
        setPreparedQty([])
      }

    })
  }

  const CreateIndentFormEntry = (dto: IndentFormDTO) => {
    const unitId = Number(localStorage.getItem('unit_id'))
    dto.unitId = unitId;
    const selectedProductRecord =  productsData.find((el) => { return el.soItemId == form.getFieldValue('saleOrderItemId')})
    dto.saleOrderId =selectedProductRecord.saleOrderId 
    dto.barcode = selectedProductRecord.barcode
    service.CreateIndentFormEntry(dto).then((res) => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('saved sucessfully')
        history.push('/indent-barcode-grid')
        form.resetFields()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message)
    });
  }
  const handleSaleOrder = (val) => {
    //setSaleOrderData(val)
  }

  


  return (
    <div>
      <Card title={<span style={{ color: 'white' }}>Indent Form</span>}
         style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/indent-barcode-grid' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>}
       >
        <Form layout="vertical" form={form} name="control-hooks" onFinish={CreateIndentFormEntry}>
          <Form.Item name="indentFormId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item
                name="stockType"
                label="Indent Against"
                rules={[
                  {
                    required: true, message: 'Missing Stock Type',
                  },
                ]}
              >
                <Select
                  placeholder="Select Stock Type"
                  allowClear
                  onSelect={getCurrentMonthsProductData}
                >
                  <Option value={undefined}>Please select StockType</Option>
                  {stockTypes.map(stockType => {
                    return <Option value={stockType.value}>{stockType.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name="transactionType"
                label="Transaction Type"
                initialValue={TransactionType.rm}
                rules={[
                  {
                    required: true, message: 'Missing Transaction Type',
                  },
                ]}
              >
                <Select
                  // placeholder="Select Transaction Type"
                  allowClear
                 
                //   onClear={clearDependentFields}
                >
                  {transactionTypeDropDown.map(transactionType => {
                    return <Option value={transactionType.value}>{transactionType.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item
                name="barcode"
                label="Scan/Enter Barcode"
              >
                <Input onChange={(e) => {getCurrentMonthsProductData()}} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item
              name="saleOrderItemId"
              label="Product"
              rules={[
                {
                  required: true,
                  message: 'Product Required',
                },
              ]}>
              <Select
                placeholder="Select Product"
                allowClear
                showSearch
                onSelect={getPAckingItemsDropdown}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>select SO Item</Option>
                {productsData.map(productDropDown => {
                  return <Option  value={productDropDown.soItemId}>{productDropDown.productName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="itemId" label="Item Name" rules={[{ required: form.getFieldValue('stockType') == stockTypeEnum.SaleOrder ? true:false, message: "Item is required" }]}>
              <Select
                showSearch
                placeholder="Select Item"
                allowClear
                onChange={() => getRequiredQuantityForSelectedItem()}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={false}
              >
                { packingItems.map(dropData => {
                  return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: "Qty is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span = {4}>
          {itemQty && <div className='qty-display-card' >
            <b>Required Qty : {itemQty} </b><br />
            <b>FG Qty : {preparedQty ? preparedQty : 0}</b>
          </div>
}
       
          </Col>
        
          {/* <Col span={3}>
            <Form.Item name="indentNumber" label="Indent Number">
              <Input disabled />
            </Form.Item>
          </Col> */}
        </Row>
        <Row justify='end'>
          <Col span={4} style={{ textAlign: 'right', paddingTop: '30px' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
    </div >
  );
}

export default IndentModuleForm;

// CreateIndentFormEntry