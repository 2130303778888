export class AssetStatusInfoModel {
    assetStatus: string; // In stock, In use etc..
    assetsCount: number;
    totalAssetsValue: number;
    
    constructor(
      assetStatus: string,
      assetsCount: number,
      totalAssetsValue: number
      
    ) {
      this.assetStatus = assetStatus;
      this.assetsCount = assetsCount;
      this.totalAssetsValue = totalAssetsValue;
      
    }
  }
  