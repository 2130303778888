export class ItemSubCategoryRequest{
    itemSubCategoryId:number;
    
    constructor(itemSubCategoryId:number){
        this.itemSubCategoryId = itemSubCategoryId;
    }
}

export class ItemSubCategoryRequestforsonumber{
    saleOrderId?:number;
    
    constructor(saleOrderId?:number){
        this.saleOrderId = saleOrderId;
    }
}