export class ReasonsDropDown {
    reasonId: number;
    reasonCode: string;
    reasonName: string;

    /**
     * 
     * @param reasonId 
     * @param reasonCode 
     * @param reasonName 
     */
    constructor(reasonId: number, reasonCode: string, reasonName: string) {
        this.reasonId = reasonId;
        this.reasonCode = reasonCode;
        this.reasonName = reasonName;
    }
}