import { packingIndentEnum, stockTypeEnum } from "@gtpl/shared-models/common-models";

export class IndentFormDTO {
    indentFormId: number;
    stockType :stockTypeEnum;
    saleOrderId: number;
    itemId: number;
    quantity: number;
    unitId: number;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    indentNumber : string;
    receivedQuantity:number
    issuedQuantity:number
    status:packingIndentEnum
    barcode?:string
    constructor(    indentFormId: number,stockType :stockTypeEnum,saleOrderId: number,itemId: number,quantity: number,unitId: number,createdAt: Date,createdUser: string | null,updatedAt: Date,updatedUser: string | null,versionFlag: number,indentNumber : string,receivedQuantity:number,status:packingIndentEnum,issuedQuantity:number,barcode?:string){
         this.indentFormId = indentFormId;
         this.stockType = stockType;
         this.saleOrderId = saleOrderId;
         this.itemId = itemId;
         this.quantity = quantity;
         this.unitId = unitId;
         this.createdAt = createdAt;
         this.createdUser = createdUser;
         this.updatedAt = updatedAt;
         this.updatedUser = updatedUser;
         this.versionFlag = versionFlag;
         this.indentNumber = indentNumber;
         this.receivedQuantity = receivedQuantity;
         this.barcode = barcode;
         this.status=status;
         this.issuedQuantity=issuedQuantity;
    }
}