import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Space,Tabs, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import './vehicle-assignment-grid.css';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import {IndentService} from '@gtpl/shared-services/raw-material-procurement'
import { HarvestingTime, IndentModel } from '@gtpl/shared-models/raw-material-procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Redirect, useLocation } from 'react-router-dom';
import {CaretRightOutlined , SearchOutlined, FilterOutlined,EditOutlined, RotateRightOutlined, RedoOutlined} from '@ant-design/icons'
import { EmployeeRolesEnum, PlantsEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface VehicleAssignmentGridProps {}

export function VehicleAssignmentGrid(
  props: VehicleAssignmentGridProps
) {
  let location = useLocation();
  const tabKey: string | any = location.state;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [defaultTab, setDefaultTab] = useState<string>(undefined);
  const [formstatus, setFormstatus] = useState<boolean>(false);
  const [rowData, setRowData] = useState<IndentModel>(undefined);
  const [page, setPage] = React.useState(1);
  const [indentData, setIndentData] =useState<IndentModel[]>([]);
  const indentService = new IndentService();
  const searchInput = useRef(null);
  const { TabPane } = Tabs;
  const unit = JSON.parse(localStorage.getItem('unit_id'));
  const role = JSON.parse(localStorage.getItem('role'));
  const plantId = JSON.parse(localStorage.getItem('unit_id'))===1?PlantsEnum.UNIT_ONE:JSON.parse(localStorage.getItem('unit_id'))===2?PlantsEnum.UNIT_TWO:JSON.parse(localStorage.getItem('unit_id'))===3?PlantsEnum.KAKINADA:'';
  console.log(plantId);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      console.log(searchInput.current);
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text => 
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
const tableColumns = (key) => {
  console.log(key);
  const vehicleAssignmentColumns: ColumnProps<any>[] = [
    {
      title: 'SNo',
      key: 'sno',
      responsive: ['md'],
      width: '60px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Indent Code',
      key: 'indentCode',
      dataIndex: 'indentCode',
      ...getColumnSearchProps('indentCode'),
      sorter: (a, b) => a.indentCode.localeCompare(b.indentCode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Indent Date',
      key: 'indentDate',
      dataIndex: 'indentDate',
      // width:150,
      render: (value, rowData: IndentModel, index) => {
        return moment(value).format('YYYY-MM-DD');
      },
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Harvesting Date',
      key: 'harvestingDate',
      dataIndex: 'harvestingDate',
      render: (value, rowData: IndentModel, index) => {
        return moment(value).format('YYYY-MM-DD');
      },
      sorter: (a, b) => moment(a.harvestingDate).unix() - moment(b.harvestingDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Harvesting Time',
      key: 'harvestingTime',
      dataIndex: 'harvestingTime',
      filters: [
        {
          text: 'Morning',
          value: 'Morning',
        },
        {
          text: 'Evening',
          value: 'Evening',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.harvestingTime === value;
      },
      // sorter: (a, b) => a.harvestingTime.localeCompare(b.harvestingTime),
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Farmer Name',
    //   key: 'supplierName',
      
    //   dataIndex: 'supplierName',
    //   width:40,
    //   ...getColumnSearchProps('supplierName'),
    //   sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Address',
    //   key: 'address',
    //   dataIndex: 'address',
    //   width:40,
    // },
    // {
    //   title: 'Mobile Number',
    //   key: 'mobileNumber',
    //   dataIndex: 'mobileNumber',
    //   ...getColumnSearchProps('mobileNumber'),
  
    // },
  ];
  const vehAndDriverColumns: ColumnProps<any>[] = [
    {
      title: 'Driver',
      key: 'driver',
      dataIndex: 'driver',
      width:50,
      ...getColumnSearchProps('driver'),
      sorter: (a, b) => a.driver.localeCompare(b.driver),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      dataIndex: 'vehicle',
      width:50,
      ...getColumnSearchProps('vehicle'),
      sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
      sortDirections: ['descend', 'ascend'],
  
    },
  ];
  const VehStatusColumns: ColumnProps<any>[] = [
    {
      title: 'Is Vehicle Assigned?',
      key: 'vehicleAssigned',
      dataIndex: 'vehicleAssigned',
      // sorter: (a, b) => a.vehicleAssigned - b.vehicleAssigned,
      // sortDirections: ['descend', 'ascend'],
      render: (value, rowData: IndentModel, index) => {
        return rowData.vehicleAssigned?"YES":"NO"
      },
      
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.vehicleAssigned === value;
      },
     
    },
    
    // {
    //   title: 'Grn Status',
    //   key: 'grnCompleted',
    //   dataIndex: 'grnCompleted',
    //   render: (value, rowData: IndentModel, index) => {
    //     return rowData.grnCompleted?"YES":"NO"

    //   },
    //   filters: [
    //     {
    //       text: 'YES',
    //       value: true,
    //     },
    //     {
    //       text: 'NO',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     return record.grnCompleted === value;
    //   },
    //   sorter: (a, b) => a.grnCompleted.localeCompare(b.grnCompleted),
    //   sortDirections: ['descend', 'ascend'],
    // },
    
  ]
  const actionColumns: ColumnProps<any>[] = [

    {
      title: 'Action',
      dataIndex: 'containerStatus',
      render: (value, rowData: IndentModel, index) => {
            console.log(rowData);
            return <><Button style={{ backgroundColor: '#8fec79', color: "black", fontWeight: 'bold' }}
              onClick={() => { setRowData(rowData); } }>Assign Vehicle & Driver</Button></>
          },
    },
  ]
  const updateActionColumns: ColumnProps<any>[] = [

    {
      title: 'Action',
      dataIndex: 'containerStatus',
      render: (value, rowData: IndentModel, index) => {
            console.log(rowData);
            return <><Button style={{ backgroundColor: '#8fec79', color: "black", fontWeight: 'bold' }}
              onClick={() => { setRowData(rowData); } }>Update Vehicle & Driver</Button>
              {rowData.grnCompleted?null:
                <Button style={{ backgroundColor: '#69c0ff', color: "black", fontWeight: 'bold' }}
                onClick={() => { cancelAssignedVehicle(rowData); } }>Cancel Harvesting</Button>
              }
              </>
          },
    },
  ]

  if(key === "2"){
    return [...vehicleAssignmentColumns, ...VehStatusColumns, ...actionColumns];
  }
  else if(key === "3"){
    return [...vehicleAssignmentColumns,...vehAndDriverColumns,...VehStatusColumns,...updateActionColumns];
  }
  else if(key === "4"){
    return [...vehicleAssignmentColumns,...VehStatusColumns];
  }
  else{
    return [...vehicleAssignmentColumns,...VehStatusColumns,...actionColumns];
  }
}

  useEffect(()=>{
    getHarvestingData();
    if(tabKey!= undefined){
      setDefaultTab(tabKey);
    }
    console.log(JSON.parse(localStorage.getItem('unit_id')));
    console.log(moment().format("YYYY-MM-DD"))
    console.log(moment().add(1, 'days').format("YYYY-MM-DD"));
  },[])
    const date = moment().add(1, 'days');
  const getHarvestingData = () =>{
    let IsPlantHO = false
    if(role == EmployeeRolesEnum.ADMIN){
      IsPlantHO = true
    }
    indentService.getHarvestinData({plantId:unit,isPlantHO:IsPlantHO}).then(res=>{
      if(res.status){
        setIndentData(res.data);
        console.log(res.data);
        console.log(plantId);
      }else {
        if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
        setIndentData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setIndentData([]);
    })
  }
  const cancelAssignedVehicle = (rowData:IndentModel) =>{
    indentService.cancelAssignedVehicle(rowData).then(res=>{
      if(res.status){
        AlertMessages.getSuccessMessage(res.internalMessage);
        getHarvestingData();
      }else {
        if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setIndentData([]);
    })
  }
  return (
    <>
    {rowData?<Redirect to={{ pathname: "/assign-vehicle", state:{data: rowData, key:{defaultTab}} }} />:null}
    <Card title={<span style={{color:'white'}}>Vehicle Assignment</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
    
     <br></br>
          <div className="card-container">
            <Tabs type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
              <TabPane 
                key="1"
                tab={<span style={{ color: "#f5222d" }}>{'Backlog : ' + (indentData.filter(rec => rec.isActive && !rec.vehicleAssigned && !rec.grnCompleted && (moment(rec.harvestingDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") ))).length}</span>}
                >
                <Table
                key={defaultTab}
                // key={ContainerStatusEnum.NOT_READY}
                rowKey={record => record.indentId}
                columns={tableColumns("1")}
                dataSource={indentData.filter(rec => rec.isActive && !rec.vehicleAssigned && !rec.grnCompleted && (moment(rec.harvestingDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") ))}
                // || moment(rec.harvestingDate).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")))}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                scroll={{ x:true }}
                size="middle"
                bordered/>
              </TabPane>
          
            <TabPane 
            key="2" 
            tab={<span style={{ color: "#d4b106" }}>{'Future Harvestings : ' + (indentData.filter(rec => rec.isActive && !rec.vehicleAssigned && !rec.grnCompleted && (moment(rec.harvestingDate).format("YYYY-MM-DD") >= moment().add(1, "days").format("YYYY-MM-DD")))).length}</span>}
              >
            <Table
              key={defaultTab}
            // key={ContainerStatusEnum.READY_TO_BOOK}
            rowKey={record => record.indentId}
            columns={tableColumns("2")}
            // dataSource={indentData.filter(rec => !rec.vehicleAssigned && (moment(rec.harvestingDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") && moment(rec.harvestingDate).format("YYYY-MM-DD") !== moment().add(1, "days").format("YYYY-MM-DD")))}
            dataSource={indentData.filter(rec => rec.isActive && !rec.vehicleAssigned && !rec.grnCompleted && (moment(rec.harvestingDate).format("YYYY-MM-DD") >= moment().add(1, "days").format("YYYY-MM-DD")))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            size="middle"
            bordered/>
            </TabPane>
            <TabPane 
            key="3" 
            tab={<span style={{ color: "#52c41a" }}>{'Vehicles Assigned : ' + (indentData.filter(rec => rec.isActive && rec.vehicleAssigned)).length}</span>}
              >
            <Table
                key={defaultTab}
            rowKey={record => record.indentId}
            columns={tableColumns("3")}
            dataSource={indentData.filter(rec => rec.isActive && rec.vehicleAssigned)}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll={{ x:true }}
            size="small"
            bordered/>
            </TabPane>
            <TabPane 
            key="4" 
            tab={<span style={{ color: "#08979c" }}>{'Cancelled Indents : ' + (indentData.filter(rec => !rec.isActive)).length}</span>}
              >
            <Table
              key={defaultTab}
            // key={ContainerStatusEnum.READY_TO_BOOK}
            rowKey={record => record.indentId}
            columns={tableColumns("4")}
            dataSource={indentData.filter(rec => !rec.isActive)}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            size="small"
            bordered/>
            </TabPane>
          </Tabs>
          </div>
     </Card>
     </>
  );
}

export default VehicleAssignmentGrid;
