export class ReceivedBoxesRequest {
    receivedBoxes: number;
    grnId: number;

    constructor(receivedBoxes: number, grnId: number){
        this.receivedBoxes = receivedBoxes;
        this.grnId = grnId;
    }
}

