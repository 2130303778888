export class LocationRequestDto {
    plantId:number;
    saleOrderId:number;
    saleOrderItemId:number;
    mastersCartons:number;
    stockTypeStatus:string;
    varientId?:number;
    packStyleId?:number;
    role?:string;
    /**
     * 
     * @param plantId 
     * @param rackName 
     * @param mastersCartons 
     * @param stockTypeStatus 
     */
    constructor(plantId:number, saleOrderId:number, saleOrderItemId:number,mastersCartons:number,stockTypeStatus:string,varientId?:number,packStyleId?:number,role?:string){
        this.plantId = plantId;
        this.saleOrderId=saleOrderId;
        this.saleOrderItemId=  saleOrderItemId;
        this.mastersCartons=  mastersCartons;
        this.stockTypeStatus=  stockTypeStatus;
        this.varientId=  varientId;
        this.packStyleId=  packStyleId;
        this.role=  role;
    }
}
