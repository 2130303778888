import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { OperationService, QaQcOperationsService } from '@gtpl/shared-services/production'
import './qa-qc-operations.css';
import { OperationsDropDown, QaQcData, VarientIdRequest } from '@gtpl/shared-models/production-management';

/* eslint-disable-next-line */
export interface QaQcOperationsProps {
  qaqcData: QaQcData;
  updateQaQcTest: (qaqcData: QaQcData) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
const { Option } = Select;
export function QaQcOperations(
  props: QaQcOperationsProps

) {
  const qaqcService = new QaQcOperationsService;
  const [disable, setDisable] = useState<boolean>(false)
  const [form] = Form.useForm();
  const operationService = new OperationService
  const [selectedOperation, setSelectedOperation] = useState<number>(null);
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);

  let history = useHistory();


  useEffect(() => {
    getAllOperations()
  }, [])

  const createQaQcTest = (qaqcData: QaQcData) => {
    setDisable(true)
    qaqcData.createdUser = localStorage.getItem('createdUser');
    qaqcData.updatedUser = localStorage.getItem('createdUser');
    qaqcService.createQaQcTest(qaqcData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('QA/QC Test Created Successfully');
        history.push("/qa-qc-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllOperations = () => {
    operationService.getAllOperationsDropDown().then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  //  const [display, setDisplay] = useState<string>('none');
  //   const handleOperation=(value)=>{
  //     setSelectedOperation(value);
  //     if(value == ''){
  //       setDisplay('block');
  //     } else {
  //       setDisplay('none');
  //     }

  //   }


  const saveData = (values: QaQcData) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateQaQcTest(values);
    } else {
      setDisable(false)
      createQaQcTest(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }


  return (
    <Card title={<span style={{ color: 'white' }}>QA QC Operations</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}extra={props.isUpdate==true?"":<Link to='/qa-qc-grid' ><span style={{color:'white'}} ><Button className='panel_button' >View </Button> </span></Link>}  >


      <Form form={form} initialValues={props.qaqcData} name="control-hooks" onFinish={saveData}
        layout="vertical"
      >

        <Form.Item name="qaQcTestId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={props.qaqcData}>
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="testName"
              label="Test Name"
              rules={[
                {
                  required: true,
                  message: 'Test Name Required'
                },
                {
          
                  pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                  message: `Should contain only alphabets.`
                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/\s-]*$/,
                  message: `Invalid Test Name`
                },

              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="testCode"
              label="Test Code"
              rules={[
                {
                  required: true,
                  message: 'Test Code Required'
                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/\s-]*$/,
                  message: `Invalid Vendor Code`
                },

              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item
              name="operationInfo"
              label="Operation"
              rules={[
                {
                  required: true, message: 'Operation Name is required'

                },

              ]}>
              <Select
                showSearch
                placeholder="Select Operation Category"
                optionFilterProp="children"
                // onChange={handleOperation}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Operations</Option>
                {operations.map((workstationCategory) => {
                  return <Option key={workstationCategory.operationId} value={workstationCategory.operationId}>{workstationCategory.operationName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}  style={{ paddingTop:'28px' }}>
            <Button type="primary"disabled={disable} htmlType="submit">
              Submit
            </Button>
            {(props.isUpdate!=true) ?
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>:''
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default QaQcOperations;
