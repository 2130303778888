import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoakingSampleTestDto } from './soaking-sample-test.dto';

export class SoakingSampleTestReponseModel extends GlobalResponseObject{
    data?: SoakingSampleTestDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoakingSampleTestDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}