import {  IsNotEmpty, IsAlphanumeric, MaxLength, Matches, IsOptional, IsNumber } from 'class-validator';
import { ApiProperty } from '@nestjs/swagger';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';

export class LotInformationRequest {
    lotNumber: string;
    plantId: number;
    presentOperation?: OperationTypeEnum;
    saleOrderId?: number;
    saleOrderItemId?: number;

    /**
     * @param lotNumber
     * @param plantId
     */
    constructor(lotNumber:string,plantId:number, presentOperation?: OperationTypeEnum, saleOrderId?: number, saleOrderItemId?:number){
        this.lotNumber = lotNumber;
        this.plantId = plantId;
        this.presentOperation = presentOperation;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
    }
   
}