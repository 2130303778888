export class ItemsReqForSubCategoryId {
    itemSubCategoryId: number;

    /**
     * 
     * @param itemSubCategoryId 
     */
    constructor(itemSubCategoryId: number){
        this.itemSubCategoryId = itemSubCategoryId;
    }
}