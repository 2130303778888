import axios from 'axios';
import { SkuDto,SkuDetails, SkuResponseModel, AllSkuResponseModel,SkuDetailsResponseModel, SkuDropDownData, SkuDropDownDataResponseModel, GetValueAdditionDataBySkuCodesRequest, SkuCodeRequest, SkuDataRequest, SkuPercentagesResponseModel } from '@gtpl/shared-models/masters';
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/production-management';
import { AllGrnResponseModel, grnAbstract, GrnData } from '@gtpl/shared-models/raw-material-procurement';
import { UserRequestDto } from '@gtpl/shared-models/common-models';



export class SkuService {
  URL = connection.DEPLOY_URL + '/sku-codes';

  async createSku(sku: SkuDto): Promise<SkuResponseModel> {
    console.log(sku);
    return await axios.post(this.URL + '/createSkuCode', sku)
      .then(res => {
        return res.data
      })
  }
  async updateSku(sku: SkuDto): Promise<SkuResponseModel> {
    return await axios.post(this.URL + '/updateSkuCode', sku)
      .then(res => {
        return res.data
      })
  }
  async getAllSku(req?:UserRequestDto): Promise<AllSkuResponseModel> {
    return await axios.post(this.URL + '/getAllSkuCodes',req)
      .then(res => {
        return res.data
      })
  }
  async ActivateOrDeactivateSku(skuDto: SkuDto): Promise<SkuResponseModel> {
    //   console.log(State.stateCode);
    return await axios.post(this.URL + '/activateOrDeactivateSkuCode', skuDto)
      .then(res => {
        return res.data
      })
  }
  async getGRNAbstract(grnAbstractRequest: grnAbstract) : Promise<AllGrnResponseModel> {
    return await axios.post(this.URL + '/getGRNAbstract', grnAbstractRequest)
    .then(res=>{
      return res.data
    })
  }
  

  async getActiveSkuCodes(): Promise<AllSkuResponseModel> {
    // return new SkuDropDownDataResponseModel(true, 11, 'itemsData retrived successfully', [new SkuDropDownData(1, 'PDTON'), new SkuDropDownData(2, 'PD')])
    return await axios.post(this.URL + '/getAllActiveSkuCodes')
      .then(res => {
        return res.data
      })
  }

  async getValueAdditionDataBySkuCode(skuCodeReq: GetValueAdditionDataBySkuCodesRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getValueAdditionDataBySkuCode', skuCodeReq)
      .then(res => {
        return res.data
      })
  }
  async getSkuDataById(skuCodeReq:SkuCodeRequest): Promise<SkuResponseModel> {
    return await axios.post(this.URL + '/getSkuDataById', skuCodeReq).then(res => {
      // console.log(res);
        return res.data
    });
  }

  async getSkuPercentages(Req:SkuDataRequest): Promise<SkuPercentagesResponseModel> {
    return await axios.post(this.URL + '/getYieldPercentages', Req).then(res => {
      // console.log(res);
        return res.data
    });
  }

  async createSkuDetails(Sku:SkuDetails):Promise<SkuDetailsResponseModel>{
    return await axios.post(this.URL + '/createSkuDetails', Sku).then(res => {
  
  return res.data
});
}

  async getSkuConversions(req:SkuCodeRequest):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getSkuConversions', req).then(res => {
    return res.data
    });
  }

  async getSkuCodeNamesForSkucodeId(skuCodeReq: GetValueAdditionDataBySkuCodesRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getSkuCodeNamesForSkucodeId', skuCodeReq)
      .then(res => {
        return res.data
      })
  }
}


