import { VendorPriceListCreateDto } from "./vendor-price-list-create.dto";

export class VendorPriceListInfo{
    inActiveItems:number[];
    vendorPriceListData:VendorPriceListCreateDto[];

    /**
     * 
     * @param inActiveItems 
     * @param vendorPriceListData 
     */
    constructor(inActiveItems:number[], vendorPriceListData:VendorPriceListCreateDto[]){
        this.inActiveItems = inActiveItems;
        this.vendorPriceListData = vendorPriceListData;
    }
}