import { StockTypeStatus } from "@gtpl/shared-models/common-models";

export class RackZoneLevelRequest{
    zoneId:number;
    rackId:number;
    levelId:number;
    stockType?:StockTypeStatus;
    unitId:any;
    reportingFromDate?:any;
    reportingToDate?:any;
    role:string;
    variantCode?:any;
    loginUnit?:number;
}