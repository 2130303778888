import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ValAddInventoryDto } from './val-addtion-inventory.dto';

export class ValAdditionResponse extends GlobalResponseObject{
    data?: boolean;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: boolean){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
