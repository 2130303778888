import { InsuranceType } from "@gtpl/shared-models/common-models";

export class InsuranceRequest{
    insuranceId : number;
    isActive : boolean;
    updatedUser : string;
    versionFlag : number;
   
    constructor(insuranceId : number,isActive : boolean,updatedUser : string,versionFlag : number
        ){
        this.insuranceId = insuranceId;
        this.isActive = isActive
        this.updatedUser = updatedUser
        this.versionFlag = versionFlag
    }
}
