export class AssetCatDropDownDto {
    assetsCatagoryId: number;
    assetsCatagoryName: string;

    /**
     * 
     * @param assetsCatagoryId 
     * @param assetsCatagoryName 
     */
    constructor(assetsCatagoryId: number, assetsCatagoryName: string) {
        this.assetsCatagoryId = assetsCatagoryId;
        this.assetsCatagoryName = assetsCatagoryName;

    }
}