import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ItemsDropDownDto } from './items-drop-down.dto';
import { RmBomDto } from './rm-bom.dto';
import { RmBomData } from './rmBomData';


export class RmBomResponse extends GlobalResponseObject {
    data?: RmBomData;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ItemsDropDownDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RmBomData) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 