

export class StockInputRequest {
    saleOrderId:number;
    itemcategoryId:number;
    sizeId:number;
    itemId:number;
    unitId?:number;
    unitPrice?:number;
    requiredQuantity:number;
    existingQuantity:number;
    assignedQuantity:number;
    itemSubCategoryId?:number;
    createdUser?:string;
    packingmaterialId?:number;
    locationWiseStock?:any[]
    constructor(saleOrderId: number, itemcategoryId: number, sizeId: number, itemId: number, requiredQuantity: number, existingQuantity: number, assignedQuantity:number, unitPrice?: number, unitId?: number,itemSubCategoryId?:number,createdUser?:string,packingMaterialId?:number,locationWiseStock?:any[]) {
        this.saleOrderId = saleOrderId;
        this.itemcategoryId = itemcategoryId;
        this.sizeId = sizeId;
        this.itemId = itemId;
        this.unitId = unitId;
        this.unitPrice = unitPrice;
        this.requiredQuantity = requiredQuantity;
        this.existingQuantity = existingQuantity;
        this.assignedQuantity = assignedQuantity;
        this.itemSubCategoryId = itemSubCategoryId;
        this.packingmaterialId = packingMaterialId;
        this.locationWiseStock = locationWiseStock;
    }

}