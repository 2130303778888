export class EndCustomerRequest{
    endCustomerId : number;
    isActive ?: boolean;
    
    constructor(endCustomerId : number,isActive : boolean)
    {
        this.endCustomerId = endCustomerId;
        this.isActive = isActive;
        
    }
}
