import { Param } from '@nestjs/common';
import { IsNotEmpty, IsAlphanumeric, MaxLength, Matches, IsOptional, IsNumber } from 'class-validator';

export class BomItemRequest {
    packingCategory: string;
    minGrade: number;
    maxGrade: number;
    brandId: number;
    varientId: number;
    pouches: number;
    pouchWeight: number;
    uomId: number;
    sizeId?: number;
    isActive?: boolean;
    brandsData?: any;
    foodType?: number;
    valueAdditionOneId?: number;
    valueAdditionTwoId?: number;
    valueAdditionThreeId?: number;
    valueAdditionFourId?: number;
    valueAdditionFiveId?: number;
    productId?: number;
    bapNo?: string;

    /**
     * @param packingCategory
     * @param minGrade
     * @param maxGrade
     * @param brandId
     * @param varientId
     * @param pouches
     * @param pouchWeight
     * @param uomId
     * @param isActive
     */

    constructor(
        packingCategory:string,minGrade: number, maxGrade: number, brandId: number, varientId: number, pouches: number, pouchWeight: number,uomId:number,sizeId?:number,brandsData?: any,foodType?: number,valueAdditionOneId?: number,valueAdditionTwoId?: number,valueAdditionThreeId?: number,valueAdditionFourId?: number,valueAdditionFiveId?: number,productId?:number,bapNo?:string
    ) {
        this.packingCategory = packingCategory;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.brandId = brandId;
        this.varientId = varientId;
        this.pouches = pouches;
        this.pouchWeight = pouchWeight;
        this.uomId = uomId;
        this.sizeId = sizeId;
        this.brandsData = brandsData;
        this.foodType = foodType;
        this.valueAdditionOneId = valueAdditionOneId;
        this.valueAdditionTwoId = valueAdditionTwoId;
        this.valueAdditionThreeId = valueAdditionThreeId;
        this.valueAdditionFourId = valueAdditionFourId;
        this.valueAdditionFiveId = valueAdditionFiveId;
        this.productId = productId;
        this.bapNo = bapNo;
    }
}