import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderInfo } from './sale-order.info';

export class SaleOrderInfoResponseModel extends GlobalResponseObject{
    data?: SaleOrderInfo;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //SaleOrderInfo
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderInfo) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
