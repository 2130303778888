export enum TransferMethodEnum {
    'Plant To Plant' = 'INTERNAL',
    'Plant To Third Party' = 'EXTERNAL',
    'Warehouse To Plant' = 'INDENT',
}

export enum IsPreparedMaterial {
    'YES' = 'yes',
    'NO' = 'no',
}

export enum AssetTransferMethodEnum {
    'Internal' = 'INTERNAL',
    'Plant To Plant' = 'EXTERNAL',
    'Indent' = 'INDENT',

}