export class GrnReturnAutoFillDto {
    grnId: number;
    grnNumber: string;
    grnDate: any;
    branch: string;
    invoiceDate: any;
    invoiceNumber: string;
    totalReturnQuantity: number;
    totalCost: number;
    indentNumber: string;
    dcNumber: string;

    /**
     * @param grnDate
     * @param grnId 
     * @param grnNumber 
     * @param branch 
     * @param InvoiceDate 
     * @param invoiceNumber 
     * @param totalReturnQuantity 
     * @param totalCost 
     * @param indentNumber 
     * @param dcNumber
     */
    constructor(grnDate: any,grnId: number, grnNumber: string, branch: string, InvoiceDate: any, invoiceNumber: string, totalReturnQuantity: number, totalCost: number, indentNumber: string, dcNumber: string) {
        this.grnDate = grnDate;
        this.grnNumber = grnNumber;
        this.grnId = grnId;
        this.branch = branch;
        this.invoiceDate = InvoiceDate;
        this.invoiceNumber = invoiceNumber;
        this.totalReturnQuantity = totalReturnQuantity;
        this.totalCost = totalCost;
        this.indentNumber = indentNumber;
        this.dcNumber = dcNumber;
    }
}