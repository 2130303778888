import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { GrnItemReturnService } from '@gtpl/shared-services/procurement';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Redirect, Route } from 'react-router-dom';
import { GrnDetailsDto,GrnIdRequest, GrnNumberDto } from '@gtpl/shared-models/procurement-management';
import { ItemCategoriesDto, ItemsDropDownDto, ItemsReqForCategoryId } from '@gtpl/shared-models/masters';
import {GrnReturnGridDto,GrnReturnResponseModel} from '@gtpl/shared-models/procurement-management';

import './grn-return-grid.css';
import moment from 'moment';
import { UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface GrnReturnGridProps {
  
}

export function GrnReturnGrid(props: GrnReturnGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [grnId, setGrnIdState] = useState<number>(null);
  const [grnReturnData, setGrnReturnData] = useState<GrnReturnGridDto[]>([]);
  const [object, setObject] = useState(null);

  const gnrService = new GrnItemReturnService();

  useEffect(() => {
    getGrnReturnDetails();
  }, []);

  /**
   * 
   */
  const getGrnReturnDetails = () => {
    const unitId = Number(localStorage.getItem('unit_id'))
    gnrService.getAllGrnReturns(new UnitRequest(unitId)).then(res => {
      if (res.status) {
        setGrnReturnData(res.data);
      } else {
        if (res.intlCode) {
          setGrnReturnData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setGrnReturnData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  

  
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.grnId)

  }

  const setGrnId = (rowdata) => {
    setGrnIdState(rowdata.grnReturnId)
  }

  




  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  // const openFormWithData = (viewData: ProductDto) => {
  //   setDrawerVisible(true);
  //   setSelectedProductData(viewData);
  // }


  const columnsSkelton: ColumnProps<GrnReturnGridDto>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'GRN Number',
      dataIndex: 'grnNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.grnNumber.localeCompare(b.grnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnNumber')
    },
    {
      title: 'GRN Return Number',
      dataIndex: 'grnReturnNumber',  
      // responsive: ['lg'],
      sorter: (a, b) => a.grnReturnNumber.localeCompare(b.grnReturnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnReturnNumber')
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      sorter: (a, b) => moment(a.grnDate).unix() - moment(b.grnDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('grnDate'),
      render: (value, record, index) => {
        return moment(value).format('YYYY/MM/DD');
      }
    },
    {
      title: 'GRN Return date',
      dataIndex: 'grnReturnDate',
      // responsive: ['lg'],
      sorter: (a, b) => moment(a.grnReturnDate).unix() - moment(b.grnReturnDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('grnReturnDate'),
      render: (value, record, index) => {
        return moment(value).format('YYYY/MM/DD');
      }
    },
    
    // {
    //   title: 'Item Category Name',
    //   dataIndex: 'itemCategoryName',
    //   // responsive: ['lg'],
    //   sorter: (a, b) =>a.itemCategoryName.localeCompare(b.itemCategoryName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('itemCategoryName')
    // },
    // {
    //   title: 'Item',
    //   dataIndex: 'itemName',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.itemName.length - b.itemName.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('itemName')
    // },
    // {
    //   title: 'Return Quantity',
    //   dataIndex: 'returnQuantity',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.returnQuantity - b.returnQuantity,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('returnQuantity')
    // },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unitPrice',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.unitPrice - b.unitPrice,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('unitPrice')
    // },
    // {
    //   title: 'Total Value',
    //   dataIndex: 'totalValue',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.totalValue - b.totalValue,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('totalValue')
    // },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      // responsive: ['lg'],
      sorter: (a, b) => a.remarks.length - b.remarks.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('remarks')
    },
   

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(grnId) ?<Redirect
            to={
               `/grn-return-detail-view/${grnId}`
            }
          />:null}
          <EyeOutlined  type="view"
            onClick={() => {
              
              // if (rowData.isActive) {
                setGrnId(rowData);
              // } else {
              //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
              // }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />





        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (

    <Card size="small" title={<span style={{ color: 'white' }} >GRN Return Details</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/grn-return-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >


      <br></br>
      <Row gutter={40}>
        {/* <Col>
          <Card title={'Total GRN Return: ' + grnReturnData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Open: ' + grnReturnData.filter(el => el.status == 'OPEN').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
        <Card title={'Inprogress: ' + grnReturnData.filter(el => el.status == 'IN_PROGRESS').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#e8e21e' }}></Card> 
        </Col> */}

        {/* <Col>
          <Card title={'Closed :' + grnReturnData.filter(el => el.status == 'Closed').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col> */}
      </Row>
      <br />
      <br />
      <br />
      <Table
        rowKey={record => record.grnReturnId}
        columns={columnsSkelton}
        dataSource={grnReturnData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />

    </Card>
  );
}

export default GrnReturnGrid;
