import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AllPackingCategoryResponseModel } from '@gtpl/shared-models/masters';
import { GetStoreConsumptionItemsDto } from './get-store-consumption-items.dto';

export class GetAllConsumptionItemsResponseModel extends GlobalResponseObject {
    data?: any[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: any[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}