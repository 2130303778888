import connection from "./connection";
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import {LocationAuditDto} from '../../../../shared-models/asset-management/src/lib/open-assets/location-audit.dto';
import { LocationById } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-id.dto";


export class LocationAuditService {
    URL = connection.DEPLOY_URL + '/locationAudit';

    async createLocationAudit(req: LocationAuditDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createLocationAudit',req)
        .then(res => {
            return res.data;
        })
    }

    async updateLocationAudit(req: LocationAuditDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateLocationAudit',req)
        .then(res => {
            return res.data;
        })
    }

    async getLocationAuditDetails() : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationAuditDetails')
        .then(res => {
            return res.data;
        })
    }

    async getAssetsByLocation(req: LocationById) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetsByLocation',req)
        .then(res => {
            return res.data;
        })
    }


}