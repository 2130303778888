import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber } from 'antd';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemsDetails, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import './assign-rejections.css';
import { ProductionInventoryDetails, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
const { Option } = Select;
import {ProductionInventoryService, RejectionsService, VariantOperationsService, WorkstationService} from '@gtpl/shared-services/production'
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";

/* eslint-disable-next-line */
export interface AssignRejectionsProps {
  rejectionsData:ProductionInventoryDetails;
  closeForm: () => void;
}

export function AssignRejections(
  props: AssignRejectionsProps
) {
  let history = useHistory();
  const [assignRejectionsToSOForm] = Form.useForm();
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const [saleOrderNumbers, setSaleOrderNumbers] = useState<SaleOrderDropDownDto[]>([]);
  const [soItemNumbers, setSoItemNumbers] = useState<ItemsDetails[]>([]);
  const [nextOperation, setNextOperation] = useState<OperationTypeEnum>(undefined);
  const service = new SaleOrderService();
  const varientService = new VariantOperationsService();
  const prodInv = new ProductionInventoryService();
  const rejectionsService = new RejectionsService();
  const workstationService = new WorkstationService();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 10,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 4,
    },
  };
  useEffect(() => {
    console.log(props.rejectionsData);
    assignRejectionsToSOForm.setFieldsValue({lotNumber:props.rejectionsData.lotNumber,boxes:props.rejectionsData.boxes,physicalQuantity:props.rejectionsData.rejectedQuantity-props.rejectionsData.assignedRejection});
    getAllSaleOrders();
    getNextOperationForVariantId();
    getWorkStations()
  }, []);

  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory = 13;
    catReq.unitId = Number(localStorage.getItem('unit_id'));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        setWorkStations(res.data);
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }
  const getAllSaleOrders = () => {
    service.getSaleOrdersDropDownList().then((res) => {
      if (res.status) {
        setSaleOrderNumbers(res.data.filter(rec => rec.saleOrderNumber != props.rejectionsData.saleOrder));
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNumbers([]);
    });
  };
  const getNextOperationForVariantId = () => {
    varientService.getNextOperationForVariantId({presentOperation:props.rejectionsData.operation,variantId:props.rejectionsData.varientId,plantId:plantId}).then((res) => {
      if (res) {
        setNextOperation(res);
      } else {
        if (!res) {
          // AlertMessages.getErrorMessage('something went wrong. ');
        } else {
          // AlertMessages.getErrorMessage('something went wrong. ');
        }
      }
    })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNumbers([]);
    });
  }

  const getSaleOrderItemIds = () => {
    const saleOrderId = assignRejectionsToSOForm.getFieldValue('saleOrderId');
    service.getSaleOrderItemsForSo(new saleOrder(saleOrderId,plantId)).then((res) => {
      if (res.status) {
        setSoItemNumbers(res.data.itemsDetails);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderNumbers([]);
    });
  
    
  };
  const saleOrderOnchange = () => {
    assignRejectionsToSOForm.setFieldsValue({saleOrderItemId:undefined});
    getSaleOrderItemIds();
  }

  const onReset = () => {
    assignRejectionsToSOForm.resetFields();
  };

  const onFinish = () => {
    const formInfo = assignRejectionsToSOForm.getFieldsValue();
    formInfo.createdUser = localStorage.getItem('createdUser');
    formInfo.plantId = localStorage.getItem('unit_id');
    formInfo.subPlantId = localStorage.getItem('unit_id');
    formInfo.subLotNumber = props.rejectionsData.subLotNumber;
    formInfo.operation = props.rejectionsData.operation;
    formInfo.issuedQuantity = 0;
    formInfo.intransitQuantity = 0;
    formInfo.wipQuantity = 0;
    formInfo.wastageQuantity = 0;
    formInfo.rejectedQuantity = 0;
    formInfo.inputGrade = props.rejectionsData.inputGrade;
    formInfo.outputGrade = props.rejectionsData.outputGrade;
    formInfo.boxes = props.rejectionsData.boxes;
    formInfo.ipBoxes = 0;
    formInfo.opBoxes = 0;
    formInfo.assignedTime = moment();
    formInfo.updatedUser = localStorage.getItem('createdUser');
    formInfo.productionInventoryId = props.rejectionsData.productionInventoryId;
    console.log("my data is:",formInfo);
    rejectionsService.createRejectionAssignment(formInfo).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        props.closeForm();
        history.push("/rejections-grid");
        onReset();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  return (
    <Card
      title={<span style={{ color: 'white' }}>Assign Rejection to SO</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
    <Form {...layout} form={assignRejectionsToSOForm} name="control-hooks" onFinish={onFinish}>
        
            <Form.Item name="lotNumber" label="Lot Number" rules={[{ required: true, message: 'Lot Number is required' }]}>
              <InputNumber style={{ width: '100%' }}  readOnly/>
            </Form.Item>
            
            <Form.Item name="saleOrderId" label="Sale Order Number" rules={[{ required: true, message: 'SO Number is required' }]}>
              <Select
                placeholder="Select SO No"
                allowClear
                onChange = {saleOrderOnchange}
              >
                {saleOrderNumbers.map(dropData => {
                  return <Option key={dropData.saleOrderNumber} value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                })}
              </Select>
            </Form.Item>
         
            <Form.Item name="saleOrderItemId" label="Sale Order Item" rules={[{ required: true, message: 'SO Item Number is required' }]}>
              <Select
                placeholder="Select So Item No"
                allowClear
              >
                {soItemNumbers.map(dropData => {
                  return <Option key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}>{dropData.item}</Option>
                })}
              </Select>
            </Form.Item>
          
            <Form.Item name="boxes" label="Headless Count" rules={[{ required: true, message: 'Headless count is required' }]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          
            <Form.Item name="physicalQuantity" label="Rejected Quantity" rules={[{ required: true, message: 'Quantity is required' }]}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
        
            <Form.Item name="nextOperation" label="Next Operation" rules={[{ required: true, message: 'Next Operation is required' }]}>
              <Select
                placeholder="Select Next Operation"
                allowClear
              >
                <Option key={1} value= {props.rejectionsData.operation}>{props.rejectionsData.operation}</Option>
                {nextOperation?<Option key={2} value= {nextOperation}>{nextOperation}</Option>:null}
              </Select>
            </Form.Item>
            <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]} >
            <Select
              placeholder="Select Workstation"
              allowClear
            >
              {workStations.map(dropData => {
                      return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
                    })}
            </Select>
          </Form.Item>
         
            <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </Form>
    </Card>
  );
}

export default AssignRejections;
