import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class CommonResponse extends GlobalResponseObject {
    data?: any;
    data1?: any;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     * @param data1 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: any, data1?: any){
        super(status,errorCode,internalMessage);
        this.data = data;
        this.data1 = data1;
    }
}