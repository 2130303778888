export class lotCodeDashTableDto{
  operationType: string;
  lots: string;
  readyForJob:string;
  wtp: string;
  reqQty: number;
  constructor(operationType: string,lots: string,readyForJob:string,wtp: string,reqQty: number) {
    this.operationType = operationType;
    this.lots = lots;
    this.readyForJob = readyForJob; 
    this.wtp = wtp;
    this.reqQty = reqQty;
  }
}