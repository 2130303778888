export class UpdateCasesRequest{
    saleOrderItemId:number; 
    reported:number;
    dispatch:number;
    dispatchStatus: string;
    constructor(
        saleOrderItemId:number, 
        reported:number,
        dispatch:number,
        dispatchStatus: string,
    ){
        this.saleOrderItemId= saleOrderItemId, 
        this.reported= reported,
        this.dispatch= dispatch,
        this.dispatchStatus= dispatchStatus
    }
}