import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DownTimePieChartDto } from './downtime-pie-chart-dto';
//import { DownTimeTrackingDto } from './downtime-tracking.dto';

export class DowntimePiechartResonseModel extends GlobalResponseObject{

    data?:DownTimePieChartDto[];
  /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

   constructor(status: boolean, intlCode: number, internalMessage: string, data?: DownTimePieChartDto[]){
    super(status,intlCode,internalMessage);
    this.status = status;
    this.intlCode = intlCode;
    this.internalMessage = internalMessage;
    this.data = data;
}


}