export class DayStatusModel {
    day: number;
    presentStatus: string;

    /**
     * 
     * @param day 
     * @param presentStatus 
     */
    constructor(day: number, presentStatus: string) {
        this.day = day;
        this.presentStatus = presentStatus;
    }
}