export class HatcheriesDropDownData{
    hatcheriesId:number;
    hatcheriesName:string;
    
    
    /**
     * 
     * @param hatcheriesId 
     * @param hatcheriesName 
     * 
     */
    constructor(hatcheriesId:number,minGrade:number,maxGrade:number,hatcheriesName:string){
      this.hatcheriesId = hatcheriesId;
      this.hatcheriesName = hatcheriesName;
    }
}