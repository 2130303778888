import { HarvestTypes, PlantsEnum } from "@gtpl/shared-models/common-models";
import { RmGrnItemDetails } from "./rm-grn-item-details";

export class GrnDetails {
    grnId?: number;
    grnDate: any;
    indentId: number;
    grnNumber?: string;
    supplierType: string;
    grnItemsInfo:RmGrnItemDetails[];
    plantId?: number;
    subPlantId?: number;
    createdUser: string;
    updatedUser?: string;
    subPlantName?: string;
    isVInspectionCompleted?: boolean;
    isRmInspectionCompleted?:boolean;
    isAntibioticsTestCompleted?:boolean;
    harvestType?:HarvestTypes
    /**
     * 
     * @param grnId 
     * @param grnDate 
     * @param indentNumber 
     * @param grnNumber 
     * @param lotNumber
     * @param supplierType 
     * @param farmer 
     * @param farmerCode 
     * @param quantity 
     * @param count 
     * @param price
     * @param status 
     */
    
    constructor(grnDate: any,indentId: number,supplierType: string,createdUser: string,grnItemsInfo:RmGrnItemDetails[],plantId?: number,grnNumber?: string,subPlantId?: number,grnId?: number,updatedUser?: string,subPlantName?: string,isVInspectionCompleted?: boolean,isRmInspectionCompleted?:boolean,isAntibioticsTestCompleted?:boolean,harvestType?:HarvestTypes) {
    this.grnId = grnId;
    this.grnDate = grnDate;
    this.indentId = indentId;
    this.grnNumber = grnNumber;
    this.supplierType = supplierType;
    this.grnItemsInfo = grnItemsInfo;
    this.plantId = plantId;
    this.subPlantId = subPlantId;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;
    this.subPlantName = subPlantName;
    this.isVInspectionCompleted = isVInspectionCompleted;
    this.isRmInspectionCompleted = isRmInspectionCompleted;
    this.isAntibioticsTestCompleted = isAntibioticsTestCompleted;
    this.harvestType = harvestType;
    }
}

