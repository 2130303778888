import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ProfilesDto } from "./profiles.dto";

export class ProfileResponse extends GlobalResponseObject{
    data?: ProfilesDto;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: ProfilesDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}