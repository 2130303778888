export class LoanCategoriesDto{
    loanCategoryId?: number;
    loanCategoryName: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;
}

export const LoanCategoriesDtoDefault: LoanCategoriesDto = {
    loanCategoryName: '',
    isActive: true,
    createdAt: new Date(),
    createdUser: '0',
    updatedAt: new Date(),
    updatedUser: '0',
    versionFlag: 1
};