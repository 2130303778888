export enum DocumentsEnum {
    CUSTOMS_INVOICE='Customs Invoice',
    COMMON_INVOICE='Common Invoice',
    COMMERCIAL_PACKING_LIST='Commercial Packing List',
    COMMERCIAL_INVOICE='Commercial Invoice',
    PLANT_PACKING_LIST='Plant Packing List',
    PLANT_INVOICE='Plant Invoice',
    SHIPMENT_INSTRUCTIONS='Shrimp Instructions',
    FORM_13='Form 13',
    BL_DRAFT='BL Draft',
    FREIGHT_INVOICE='Freight Invoice',
    OBL='OBL',
    ISF_Worksheet='ISF Worksheet',
    SIMP='SIMP',
    CERTIFICATE_OF_ORIGIN='Certificate Of Origin',
    DECLARATION_OF_SHRIMP='Declaration Of Shrimp',
}