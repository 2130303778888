export class QtyRequest {
    itemCategoryId: number;
    itemSubCategoryId: number;
    itemId: number;
    sizeId: number;
    unitId: number;
    saleOrderId?: number;
    isPreparedMaterial?: string;
    constructor(itemCategoryId: number, itemSubCategoryId: number, itemId: number, sizeId: number, unitId: number, saleOrderId?: number,isPreparedMaterial?:string) {
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemId = itemId;
        this.sizeId = sizeId;
        this.unitId = unitId;
        this.saleOrderId = saleOrderId;
        this.isPreparedMaterial = isPreparedMaterial;
    }
}