export class PlantsDropDown {
    plantId: number;
    plantName: string;
    plantType?: string;

    /**
     * 
     * @param plantId 
     * @param plantName 
     */
    constructor(plantId: number, plantName: string, plantType?:string) {
        this.plantId = plantId;
        this.plantName = plantName;
        this.plantType = plantType;
    }

}