import { ProductDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GrnDetailsDto } from "./grn-details.dto";
import { AllGrnDto } from "./all-grn-data.dto";



export class AllGrnResponseModel extends GlobalResponseObject {
    data?: AllGrnDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AllGrnDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 