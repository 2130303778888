import axios from 'axios';
import connection from './connection';
import {AllCodeListResponseModel, CodeListDto, CodeListRequest, CodeListResponseModel, CodeListStatus} from '@gtpl/shared-models/planning'


export class CodeListService {
  URL = connection.DEPLOY_URL + '/code-list';

  async createCodeList(codeList: CodeListDto): Promise<CodeListResponseModel> {
    return await axios
      .post(this.URL + '/createCodeList', codeList)
      .then((res) => {
        return res.data;
      });
  }

  async getAllCodeList(): Promise<AllCodeListResponseModel> {
    return await axios
      .post(this.URL + '/getAllCodeList')
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveCodeList(): Promise<AllCodeListResponseModel> {
    return await axios
      .post(this.URL + '/getAllActiveCodeList')
      .then((res) => {
        return res.data;
      });
  }
  async updateCodeList(codeListDto: CodeListDto): Promise<CodeListResponseModel> {
    return await axios
      .post(this.URL + '/updateCodeList',codeListDto)
      .then((res) => {
        return res.data;
      });
  }

  async activateOrDeactivateProduct(codeListReq: CodeListDto): Promise<CodeListResponseModel> {
    return await axios
      .post(this.URL + '/activateOrDeactivateProduct',codeListReq)
      .then((res) => {
        return res.data;
      });
  }
  
}
