export class ShippingTermsRequest {
    shippingTermsId:number;
    
    /**
     * 
     * @param shippingTermsId 
     */
   
    constructor(shippingTermsId:number){
    this.shippingTermsId = shippingTermsId;
  
    }
} 
