import { EmploymentStatusEnum } from "@gtpl/shared-models/common-models";

export class EmploymentStatusDto{
    employmentStatusId?: number;
    employmentStatus: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;    
    versionFlag: number;

    constructor(
    employmentStatusId: number,
    employmentStatus: string,
    createdAt: Date | any,
    createdUser: string,
    isActive: boolean,
    updatedUser: string,
    updatedAt: Date | any,
    versionFlag: number
    ){
      this.employmentStatusId =  employmentStatusId;
      this.employmentStatus = employmentStatus;
      this.isActive = isActive;
      this.createdAt = createdAt;
      this.createdUser = createdUser;
      this.updatedUser = updatedUser;
      this.updatedAt = updatedAt;
      this.versionFlag = versionFlag;
    }
}