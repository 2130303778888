import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { RmIndentReportDto } from "./rm-report-dto";




export class AllIndentReportResponse extends GlobalResponseObject {
    data?: RmIndentReportDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RmIndentReportDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

