import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { AllAssetsPoDetailsInfo } from "./assets-po-details-dto";


export class GetAllAssetsPoResponse extends GlobalResponseObject {
    data?: AllAssetsPoDetailsInfo[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: AllAssetsPoDetailsInfo[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}