import { LeaveApprovalStatusEnum } from "@gtpl/shared-models/common-models";
import { ApiProperty } from "@nestjs/swagger";

export class AttendanceUpdateRequest {

    employeeCode: string;
    employeeName?: string;
    date?: string;
    inTime?: string;
    outTime?: string;
    presentStatus?: string;
    status?: LeaveApprovalStatusEnum;
    reason?: string;
    user?: string;

    /**
     * 
     * @param employeeCode 
     * @param employeeName 
     * @param date 
     * @param inTime 
     * @param outTime 
     * @param presentStatus 
     * @param status 
     * @param reason 
     */
    constructor(employeeCode: string, employeeName?: string, date?: string, inTime?: string, outTime?: string, presentStatus?: string, status?: LeaveApprovalStatusEnum, reason?: string, user?: string) {

        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.date = date;
        this.inTime = inTime;
        this.outTime = outTime;
        this.presentStatus = presentStatus;
        this.status = status;
        this.reason = reason;
        this.user = user;
    }
}