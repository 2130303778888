import { RmZonesDto, RmZonesResponseModel, AllRmZonesResponseModel, RmZonesDropDownResponseModel, PlantIdRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import { PlantRequest } from 'libs/shared-models/raw-material-procurement/src/lib/indent';
import connection from './connection';

export class RmZonesService {
URL = connection.DEPLOY_URL + '/rm-zones';

  async createZone(rmZoneDto: RmZonesDto): Promise<RmZonesResponseModel> {
      return await axios.post(this.URL + '/createZone',rmZoneDto)
          .then(res => {
              return res.data
          })
  }
  async  updateZone(rmZoneDto: RmZonesDto): Promise<RmZonesResponseModel> {
    return await axios.post(this.URL + '/updateZone', rmZoneDto)
           .then(res => {
               return res.data
           })
  }
  async getAllZones(): Promise<AllRmZonesResponseModel> {
    return await axios.post(this.URL + '/getAllZones')
        .then(res => {
            return res.data
        })     
  }
  async getAllZoneDropDown():Promise<RmZonesDropDownResponseModel>{
    return await axios.post(this.URL + '/getAllZoneDropDown')
    .then(res => {
        return res.data
    })   
  }
  async getPlantWiseZones(req: PlantIdRequest):Promise<RmZonesDropDownResponseModel>{
    return await axios.post(this.URL + '/getPlantWiseZones',req)
    .then(res => {
        return res.data
    })   
  }
}
