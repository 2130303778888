
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { RmRackDetails } from './rm-rack-details';


export class AllRmRacksResponseModel extends GlobalResponseObject {
    data?: RmRackDetails[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /RmRackDetails[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RmRackDetails[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 