import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ZoneDto } from './zone-dto';


export class AllZonesResponseModel extends GlobalResponseObject {
    data?: ZoneDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ZoneDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

