import React, { useState, useEffect } from 'react';
import { Button, Card,Drawer,Form,Row,Col,Alert, DatePicker, Anchor,Spin} from 'antd';
import { useIntl, FormattedDate} from 'react-intl'
import { render } from 'react-dom';
import { UserCreateResponse,UserService,EmployeeService} from '@gtpl/shared-services/attendance';
import {CreateUserDto,CreateUserDtoDefault,EmployeeDto} from '@gtpl/shared-models/gtpl';
import { UserGrid } from '@gtpl/pages/user-management/user-components/user-grid';
import { UserForm} from '@gtpl/pages/user-management/user-components/user-form';
import { AlertMessages} from '@gtpl/shared-utils/alert-messages';
export interface UserPagesProps {}
export const UserPages =( props:UserPagesProps) =>{
  const [drawerVisible, setdrawerVisible] = useState<boolean>(false);
  const [isNewForm, setisNewForm] = useState<boolean>(true);
  const [viewOnly, setViewOnly] = useState(true);
  const [EmployeeData, setEmployeeData] = useState<EmployeeDto[]>([])
  const [user, setUserData] = useState<CreateUserDto[]>([])
  const [selectedUser, setSelectedUser] = useState<any>(CreateUserDtoDefault)
  const [employeeProjectsData, setEmployeeProjectsData] = useState<any>([])

  const userService = new UserService;
  const service = new EmployeeService;
  const { Link } = Anchor;
  // const {formatMessage:fm}= useIntl()
  const OpenFormTocreateRecord = () => {
    const duplicatedState = JSON.parse(JSON.stringify(CreateUserDtoDefault));
    setisNewForm(true);
    setSelectedUser(duplicatedState);
    setdrawerVisible(true);
    setViewOnly(false);
  }
  useEffect(() => {getAllUser();getAllEmployees(); }, [])


  const getAllUser= () => {
    // console.log('users request');
    userService.getAllUser().then(res => {
      console.log('res.data = '+res.data);
      if (res.data) {
        console.log('users info');
        // console.log(res);
        setUserData(res.data);
        console.log(user);
      } else {
        if (res.intlCode) {
          setUserData([]);
          
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setUserData([]);
    })
  }
  const getAllEmployees= () => {
    service.getAllEmployees().then(res => {
      if (res.status) {setEmployeeData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeData([]);
    })
  }
  const viewUser= (UserViewData: CreateUserDto, viewOnly: boolean = true) => {
    console.log(UserViewData);
    setisNewForm(false);
    setSelectedUser(UserViewData);
    setdrawerVisible(true);
    setViewOnly(viewOnly);
    // getEmployeeAssignedProjects(UserViewData.employeeId);
  }
  const deleteUser= (UserViewData: CreateUserDto) => {
    console.log(UserViewData);
    UserViewData.isActive=UserViewData.isActive?false:true;
    userService.ActivatedeActivateEmployee(UserViewData).then(res => {
      getAllUser();
      if (res.status) {
        getAllUser();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateUser = (UserData: CreateUserDto) => {
    console.log(UserData);
    // EmployeeData.stateId=3;
    // EmployeeData.createdUser=1;
    UserData.password=(UserData.password)?UserData.password:'';
    UserData.updatedUser=localStorage.getItem('createdUser');
    userService.updateUser(UserData).then(res => {
      if (res.success) {
        setdrawerVisible(false);
        getAllUser();
        AlertMessages.getSuccessMessage('Updated Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * To Save The State
   * @param UserData
   */
  const saveUser= (UserData: CreateUserDto) => {
    UserData.Id=0;
    UserData.createdUser=localStorage.getItem('createdUser');
    // UserData.unitId=JSON.parse(localStorage.getItem('unit_id'));
    userService.register(UserData).then(res => {
      console.log(res);
       if (res.success) {
         getAllUser();
         AlertMessages.getSuccessMessage('User created Successfully');
         setdrawerVisible(false);
       } else {
         if (res.intlCode) {
           AlertMessages.getErrorMessage(res.internalMessage);
         } else {
           AlertMessages.getErrorMessage(res.internalMessage);
         }
       }
     }).catch(err => {
       AlertMessages.getErrorMessage(err.message);
     })
   }
   const closeDrawer = () => { setdrawerVisible(false);}
   let viewPortwidth = window.innerWidth;
   const [loading, setLoading] = useState(true)

   useEffect(() => {
     setTimeout(() => setLoading(false), 1000)
   }, [])
   return (
     <>
      {loading === false ? (
     <>
     {/* <Anchor affix={false}>
           <Link href="#/dashboard" title="Dashboard" />
         </Anchor> */}
         <Card title={<span style={{color:'white'}}>User Details</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Button type="primary" onClick={() => { OpenFormTocreateRecord(); } } style={{ backgroundColor: 'white',color:'black',float:'right', display: "block" }}>Create</Button>}>
         <br></br>
          <Row gutter={40} >
            <Col>
              <Card title={'Total Users: ' + user.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
              </Col>
              <Col>
              <Card title={'Active: ' + user.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
              </Col>
              <Col>
              <Card title={'In-Active: ' + user.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
              </Col>
              </Row>
          <br></br>
             <UserGrid key={Date.now()} viewuser={viewUser} deleteuser={deleteUser} userData={user} />
           </Card>
           <Drawer bodyStyle={{ paddingBottom: 80 }} title='User Creation Form ' width={viewPortwidth > 768 ? '50%' : '85%'}
             onClose={closeDrawer} visible={drawerVisible} closable={true}>
             <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} title="User Details" size='small'>
               <UserForm key={Date.now()}
                 userData={selectedUser}
                 saveUser={saveUser}
                 updateUser={updateUser}
                //  removeproject={removeproject}
                 employeeData={EmployeeData}
                 employeeProjectsData= {employeeProjectsData}
                 viewOnly={viewOnly}
                 closeForm={closeDrawer}
                 isNewForm={isNewForm} />
             </Card>
           </Drawer>
           </>
   ): (
    <div className="spins">
    <Spin size="large"/>
    </div>
  )}
     </>
   )
  }
  export default UserPages;
