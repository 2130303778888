import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card,Form,DatePicker, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GrnService } from '@gtpl/shared-services/procurement';
import { GRNLocationPropsRequest } from '@gtpl/shared-models/masters';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { Redirect } from 'react-router-dom';
export interface GrnPendingInfoGridProps{}

export function GrnPendingInfoGrid(props:GrnPendingInfoGridProps){
  const service =new GrnService()
  const [grndata,setGrndata]=useState<any[]>([]);
  const [locationData,setLocationData]=useState<GRNLocationPropsRequest>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
    useEffect(() =>{
        getitemsdata();
    },[])
    // const setData = (val) => {
    //   const testdata = new GRNLocationPropsRequest(2,1,'Coromandel Cartons and Containers Private Limited',139,'Carton/METROCHEF/16-20/VREZPEELI/10*800GM',107,'CCC-510',2000,0,6,3,4,233,327,8,143,null,null,'')
    //   setLocationData(testdata)
    // }

    const setData = (rowdata) =>{
      console.log(rowdata)
      const testdata =new GRNLocationPropsRequest(rowdata.grnUnitId,rowdata.vendorId,rowdata.vendorName,rowdata.itemId,rowdata.itemName,rowdata.brandId,rowdata.invoiceNumber,rowdata.receivedQuantity,rowdata.saleOrderId,rowdata.itemCategoryId,rowdata.itemSubCategoryId,rowdata.unitPrice,rowdata.grnId,rowdata.grnItemId,rowdata.sizeId,rowdata.poId,rowdata.locationId,rowdata.physicalQuantity,null,rowdata.createdUser,rowdata.grnNo)
      setLocationData(testdata)
  console.log(rowdata)

    }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
    * 
    * @param selectedKeys 
    * @param confirm 
    * @param dataIndex 
    */
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
    const sampleTypeColumns:ColumnsType<any>= [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * pageSize + (index + 1)
          },
        {
            title: 'Vendor',
            dataIndex:"vendorName",
            align:'left',
            sorter: (a, b) => a.vendorName.trim().localeCompare(b.vendorName.trim()),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('vendorName')
          },
        {
            title: 'Dc Number',
            dataIndex:"invoiceNumber",
            align:'left',
            sorter: (a, b) => a.invoiceNumber.length-(b.invoiceNumber.length),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('invoiceNumber')
          
          },
        {
          title: 'Item',
          dataIndex:"itemName",
          align:'left',
          sorter: (a, b) => a.itemName-b.itemName,
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('itemName')
        
        },
      {
          title: 'Grn Quantity',
          dataIndex:'receivedQuantity',
          align:'left',
          sorter: (a, b) => a.receivedQuantity - b.receivedQuantity,
          sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Location Mapped',
        dataIndex:'physicalQuantity',
        align:'left',
        sorter: (a, b) => a.physicalQuantity - b.physicalQuantity,
        sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Balance',
      dataIndex:'balance',
      align:'left',
      sorter: (a, b) => a.balance - b.balance,
//       render:(record) =>{
//         const balance=record.receivedQuantity-record.physicalQuantity
//       return balance
//         }
      }, 
    {
        title:'Allocate',
        render: (rowData) =>(
                  <span>
                <Button type="primary" shape="round" size="small" 
                   onClick={() => {
                    console.log(rowData)
                    setData(rowData)
                    // setOpen(true)
                    // setIndentnumber(rowData.indentNumber)
                    // getReceivedQuantityagainstPonumber(rowData.indentNumber)
                  }}
                    >
                    Allocate
                  </Button>
              </span>
  )
      },
       
    ];
    const getitemsdata = () =>{
        const unitReq =  new UnitRequest( Number(localStorage.getItem('unit_id'))) 
        service.grninfoforpending(unitReq).then(res =>{
          if(res.status){
            setGrndata(res.data);
          }else{
            setGrndata([]);
          }
        }).catch(err =>{
            setGrndata([]);
        })
    
      }
      const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
      return (
        <>
        {(locationData) ? <Redirect
            to={{
              pathname: "/location-mapping",
              state: locationData
            }}
          /> : null}
           <Card title={<span style={{color:'white'}}>Grn Pending Details</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} >
            {/* <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#69c0ff', height: 40, margin: 1 ,textAlign:'center'}} title={<span>Grn Pending Details</span>}></Card> */}
            <Table
                rowKey={record => record.productId}
                className="components-table-nested"
                columns={sampleTypeColumns}
                dataSource={grndata}
                pagination={{
                  onChange(current ,pageSize) {
                    setPage(current);
                    setPageSize(pageSize)
                  }
                }}
                  onChange={onChange}
                  scroll={{ x: 500 }}
                  // size='small'
                  bordered
            />
      </Card>
        </>
      );
    
}
export default GrnPendingInfoGrid;