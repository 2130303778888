export class TypesRequest{
    typeId : number;
    isActive ?: boolean;
    
    constructor(typeId : number,isActive? : boolean)
    {
        this.typeId = typeId;
        this.isActive = isActive;
        
    }
}
