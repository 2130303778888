export class ShippingTermsDto{
    shippingTermsId : number;
    shippingTermsName : string;
    isActive: boolean;
    createdUser : string;
    updatedUser : string;

    
    /**
     * 
     * @param shippingTermsId number
     * @param shippingTermsName string
     * @param isActive boolean
     * @param createdUser string
     * @param updatedUser string
     * 
     */
    constructor(shippingTermsId : number,shippingTermsName : string,isActive: boolean,createdUser : string,updatedUser:string){
        this.shippingTermsId = shippingTermsId;
        this.shippingTermsName = shippingTermsName;
        this.isActive= isActive;
        this.createdUser= createdUser;
        this.updatedUser= updatedUser;

    }
}
export const ShippingTermsDtoDefault : ShippingTermsDto = {
    shippingTermsId: 0,
    shippingTermsName: '',
    isActive: true,
    createdUser : '',
    updatedUser : '',



}; 