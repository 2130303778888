import axios from 'axios';
import connection from './connection';
import {SalesPersonDto} from '@gtpl/shared-models/masters'
import {SalesPersonResponseModel} from '@gtpl/shared-models/masters'
import {AllSalesPersonResponseModel} from '@gtpl/shared-models/masters'
export class SalesPersonService{
    URL = connection.DEPLOY_URL + '/SalesPerson';


    async create(dto: SalesPersonDto): Promise<SalesPersonResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/create',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: SalesPersonDto): Promise<SalesPersonResponseModel> {
    return await axios.post(this.URL + '/updateProduct', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: SalesPersonDto): Promise<SalesPersonResponseModel> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/updateStatus', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            // async getAll(): Promise<AllSalesPersonResponseModel> {
                
            //     // const dto = [];
            //     // for(let i=0;i<5;i++){
            //     //     const isActive = (i%2 == 1)? true:false;
            //     //                 dto.push(new SalesPersonDto(i+1,'person',111,true,) )
            //     // }
            //     // return new AllSalesPersonResponseModel(true,1111,'Profile retrieved successfully',dto)
            //     return await axios.post(this.URL + '/getAll')
            //         .then(res => {
            //             return res.data 
            //         })     
            // }

}