import { CardHeaderBody } from "./card-header-body";

export class CardHeader {
    cardHeaderTitle ?: string;
    cardHederData?: CardHeaderBody[];
    cardType?: string;
    cardHeaderCountTitle?:string;

    cardHeaderCountValue?:string;
    

    constructor(cardHeaderTitle ?: string,cardHederData?: CardHeaderBody[],cardType?: string,cardHeaderCountTitle?:string,cardHeaderCountValue?:string) {
        this.cardHeaderTitle = cardHeaderTitle,
        this.cardHederData = cardHederData
        this.cardType = cardType
        this.cardHeaderCountTitle = cardHeaderCountTitle;
        this.cardHeaderCountValue = cardHeaderCountValue;
    }
}