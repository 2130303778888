export class gradeResponse {
    grade:string;
    qty:number;
    constructor(grade:string,
        qty:number){
        this.grade = grade;
        this.qty = qty;
    }
}
export class StockSummaryResponse {
    variant:string;
    unit:string;
    totalCartons:number;
    gradeQty:gradeResponse[];
    prodShortCode:string;
    constructor(variant:string,unit:string,totalCartons:number,
        gradeQty:gradeResponse[],prodShortCode:string){
            this.variant = variant;
            this.totalCartons  = totalCartons;
            this.unit = unit;
            this.gradeQty = gradeQty;
            this.prodShortCode = prodShortCode;
    }
}