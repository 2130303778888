
export class IndentItemsModel {
    indentItemId: number;
    plantId: number;
    farmer: number;
    farmerCode: number;
    product: number;
    expectedCount: number;
    expectedQty: number;
    expectedPrice: number;
    expectedCount2: number;
    expectedPrice2: number;
    expectedCount3: number;
    expectedPrice3: number;
    broker?: number;
    indentId?: number;
    createdUser?: string;
    createdAt?: Date;
    updatedUser?: string;
    updatedAt?: Date;


    /**
     * 
     * @param indentItemId 
     * @param plantId 
     * @param farmer 
     * @param farmerCode 
     * @param product 
     * @param expectedCount 
     * @param expectedQty 
     * @param expectedPrice 
     * @param expectedCount2 
     * @param expectedPrice2 
     * @param expectedCount3 
     * @param expectedPrice3 
     * @param broker 
     * @param indentId 
     * @param createdUser 
     * @param createdAt 
     * @param updatedUser 
     * @param updatedAt 
     */
    constructor(indentItemId: number, plantId: number, farmer: number, farmerCode: number, product: number, expectedCount: number, expectedQty: number, expectedPrice: number, expectedCount2: number, expectedPrice2: number, expectedCount3: number, expectedPrice3: number, broker?: number, indentId?: number, createdUser?: string, createdAt?: Date, updatedUser?: string, updatedAt?: Date) {
        this.indentItemId = indentItemId;
        this.plantId = plantId;
        this.farmer = farmer;
        this.farmerCode = farmerCode;
        this.product = product;
        this.expectedCount = expectedCount;
        this.expectedQty = expectedQty;
        this.expectedPrice = expectedPrice;
        this.expectedCount2 = expectedCount2;
        this.expectedPrice2 = expectedPrice2;
        this.expectedCount3 = expectedPrice3;
        this.expectedPrice3 = expectedPrice3;
        this.broker = broker;
        this.indentId = indentId;
        this.createdUser = createdUser;
        this.createdAt = createdAt;
        this.updatedUser = updatedUser;
        this.updatedAt = updatedAt;
    }
}