import { JobCompletedEnum, OperationTypeEnum, SubPlantCodesEnum, SubPlantOperationStatusEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";

export class InProgressBeHeadingDto {
    productionLogId?: number;
    lotNumber:string;
    subLotNumber:string;
    workstationType: WorkStationTypeEnum;
    workstation: number;
    operation:OperationTypeEnum;
    plantId?: number;
    subPlantId?: SubPlantCodesEnum;
    createdUser: string;
    updatedUser: string;
    assignedTime:string;
    jobStatus?:JobCompletedEnum;
    jobNumber?:string;
    dcNumber?:string;
    mrnNumber?:string;
    saleOrderId?:number;
    saleOrder?:string;
    saleOrderItemId?:number;
    boxes?:number;
    isReceived?:SubPlantOperationStatusEnum;
    quantity:number
    wastageQuantity?:number
    wipQuantity?:number
    physicalQuantity?:number
    outputQuantity?:number;
    opBoxes?:number;
    poNumber?:string;
    variety?:number;
    varietyOfMaterial?:string;
    // lotandplantlot?:string;
    // plantLotNumber?:string;
}
