import { FgStatusEnum, GlobalStatus, PlantsEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class LotCodeOpsData {
  lotCodeOpsId: number;
  grnDate: Date;
  indentId: number;
  grnNumber?: string;
  lotNumber?: string;
  saleOrderId?: number;
  soNumber?: string;
  saleOrderItemId?: number;
  itemName?: string;
  supplierType?: string;
  farmer?: number;
  farmerCode?: number;
  quantity?: number;
  count?: number;
  price?: number;
  boxes?: number;
  plantId?: number;
  subPlantId?: number;
  createdAt?: Date;
  createdUser?: string;
  updatedAt?: Date;
  updatedUser?: string;
  isActive?: boolean;
  versionFlag?: number;
  farmerName?: string;
  farmCode?: string;
  supplierName?: string;
  supplierCode?: string;
  poNumber?: string;
  concatLotNum?:string;
  constructor(lotCodeOpsId: number,
     grnDate: Date,
     indentId: number,
     grnNumber?: string,
     lotNumber?: string,
     saleOrderId?: number,
    soNumber?: string,
    saleOrderItemId?: number,
    itemName?: string,
    supplierType?: string,
    farmer?: number,
    farmerCode?: number,
    quantity?: number,
    count?: number,
    price?:number,
    boxes?: number, 
    plantId?: number,
    subPlantId?: number,
    createdAt?: Date,
    createdUser?: string,
    updatedAt?: Date,
    updatedUser?: string,
    isActive?: boolean,
    versionFlag?: number,
    farmerName?: string,
    farmCode?: string,
    supplierName?: string,
    supplierCode?: string,
    poNumber?:string,concatLotNum?:string) {

    this.lotCodeOpsId = lotCodeOpsId;
    this.grnDate = grnDate;
    this.indentId = indentId;
    this.grnNumber = grnNumber;
    this.lotNumber = lotNumber;
    this.saleOrderId = saleOrderId;
    this.soNumber = soNumber;
    this.saleOrderItemId = saleOrderItemId;
    this.itemName = itemName;
    this.supplierType = supplierType;
    this.farmer = farmer;
    this.farmerCode = farmerCode;
    this.quantity = quantity;
    this.count = count;
    this.price = price;
    this.boxes = boxes;
    this.plantId = plantId;
    this.subPlantId = subPlantId;
    this.createdAt = createdAt;
    this.createdUser = createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.isActive = isActive;
    this.versionFlag = versionFlag;
    this.farmerName = farmerName;
    this.farmCode = farmCode;
    this.supplierName = supplierName;
    this.supplierCode = supplierCode;
    this.poNumber = poNumber;
    this.concatLotNum = concatLotNum;
  }

}