export class ReportSoakingRequest{
    productionLogId: number;
    outputGrade: number;
    workstation: number;
    lotNumber: string;
    outputQuantity: number;
    saleOrderItemId: number;
    plantId: number;
    inputQuantity?: number;
    
    constructor(
        productionLogId: number,
        outputGrade: number,
        workstation: number,
        lotNumber: string,
        outputQuantity: number,
        saleOrderItemId: number,
        plantId: number,
        inputQuantity?: number
        ){
            this.productionLogId= productionLogId,
            this.outputGrade= outputGrade,
            this.workstation= workstation,
            this.lotNumber= lotNumber,
            this.outputQuantity = outputQuantity,
            this.saleOrderItemId = saleOrderItemId
            this.plantId = plantId
            this.inputQuantity = inputQuantity
    }
}