export class SubJobCodeListDto{
    saleOrderItemId:number;
    codelistmainId:number;
    cartons:number;
    traceabilityCode:String;
    unitName:String;
    soItem:String;
    constructor(saleOrderItemId:number,codelistmainId:number,cartons:number,traceabilityCode:String,unitName:String,soItem:String,){
        this.saleOrderItemId = saleOrderItemId;
        this.codelistmainId = codelistmainId;
        this.cartons = cartons;
        this.traceabilityCode = traceabilityCode;
        this.unitName = unitName;
        this.soItem = soItem;
    }
}