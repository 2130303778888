export class RMrackStatusRequest {
    rmRackPositionId:number
    status:string
    plantId:number
    itemSubCategoryId:number
    brandId:number
    constructor(rmRackPositionId:number,status:string,plantId:number,itemSubCategoryId:number,
        brandId:number) {
        this.rmRackPositionId = rmRackPositionId;
        this.status = status;
        this.plantId = plantId;
        this.brandId = brandId;
        this.itemSubCategoryId = itemSubCategoryId;
    }
}