export class operationRequest{
    operationId:number;

}
export class testCodeRequestForScan{
    testCode:string;
}
export class workStationForOpearation{
    operationName?:string
    lotNumber?:string;
    saleOrderId?:number;
    saleOrderItemId?:number;
    plantId?:number;
    subLotNumber?:string

}