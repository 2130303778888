import { LocationStatus } from "../location/loaction-status.enum";

export class LocationStatusUpdateRequest {
  locationId: number;
  versionFlag: number;
  isActive: boolean;
  updatedUser: string | null;

 
  constructor(
    locationId: number,
    versionFlag: number,
    isActive: boolean,
    updatedUser: string | null,
  ) {
    this.locationId = locationId;
    this.versionFlag = versionFlag;
    this.isActive = isActive;
    this.updatedUser = updatedUser;
  }
}
