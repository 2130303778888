import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import './fg-damages-grid.css';
import { Link, Redirect } from 'react-router-dom';
import { FgDamagesForm } from '@gtpl/pages/warehouse-management/warehouse-components/fg-damages-form';
import { FgDamgesService } from '@gtpl/shared-services/warehouse-management';
import { FgDamagesDto, unitRequiest } from '@gtpl/shared-models/warehouse-management';


/* eslint-disable-next-line */
export interface FgDamagesGridProps {}

export function FgDamagesGrid(  props: FgDamagesGridProps) 
{
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [damgesdata,setDamgesdata] = useState<any[]>([]);
  const [selecteddata,setSelecteddata] = useState<any>([]);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const fgdamageservice = new FgDamgesService
useEffect(() =>{
  getAllfgDamagesData();
},[])
   /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const openFormWithData=(viewdata: FgDamagesDto)=>{
    console.log(viewdata);
    setDrawerVisible(true);
    setSelecteddata(viewdata);
  }
  const DeleteFgDamages = (ViewData:FgDamagesDto) => {
    ViewData.isActive=ViewData.isActive?false:true;
    fgdamageservice.activateOrDeactivateDamges(ViewData).then(res => { console.log(res);
      if (res.status) {
        getAllfgDamagesData();
        AlertMessages.getSuccessMessage('Success'); 
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'location',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.location.localeCompare(b.location),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('location')
    // },
    {
      title: 'SaleOrder Aginst Location',
      dataIndex: 'product',
      width:'400px',
      align:'right',
      sorter: (a, b) => a.product - b.product,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('product')
    },
    {
      title: 'Damage Level',
      dataIndex: 'damageLevel',
      // width:'90px',
      align:'right',
      sorter: (a, b) => a.damageLevel - b.damageLevel,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('damageLevel')
    },
    {
      title: 'Damage Type',
      dataIndex: 'damageType',
      // width:'90px',
      align:'right',
      sorter: (a, b) => a.damageType - b.damageType,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('damageType')
    },
    {
      title: 'No Of Cartons',
      dataIndex: 'noOfCartons',
      // width:'90px',
      align:'right',
      sorter: (a, b) => a.noOfCartons - b.noOfCartons,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('noOfCartons')
    },
    {
      title: 'Damaged By',
      dataIndex: 'employeeName',
      // width:'90px',
      align:'right',
      sorter: (a, b) => a.employeeName - b.employeeName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('damageBy')
    },
    {
      title: 'Reason',
      dataIndex: 'damageReason',
      width:'90px',
      align:'right',
      sorter: (a, b) => a.damagereason - b.damagereason,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('damagereason')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align:'center',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>         
            {/* <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                  console.log(rowData)
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated data');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> */}
          
          <Divider type="vertical" />
            <Popconfirm
             onConfirm={e =>{DeleteFgDamages(rowData);}}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate fgdamges ?'
                :  'Are you sure to Activate fgdamges ?'
            }
          >
            <Switch  size="default"
                className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren={<RightSquareOutlined type="check" />}
                unCheckedChildren={<RightSquareOutlined type="close" />}
                checked={rowData.isActive}
              />
            
          </Popconfirm>
        </span>
      )
    }
  ];
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
 const onChange=(pagination, filters, sorter, extra)=> {
  console.log('params', pagination, filters, sorter, extra);
}
const closeDrawer=()=>{
  setDrawerVisible(false);
}
const getAllfgDamagesData= () => {
  const req = new unitRequiest(Number(localStorage.getItem('unit_id')))
  fgdamageservice.getAllfgDamagesData(req).then(res => {
    if (res.status)
     {setDamgesdata(res.data);
    } else {
      if (res.intlCode) {
        setDamgesdata([]);
          AlertMessages.getErrorMessage(res.internalMessage);
      } else {
       AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setDamgesdata([]);
  })
}
const updateDmagesdata = (data: FgDamagesDto) => {
  fgdamageservice.updateFgdamages(data).then(res => { console.log(res);
    if (res.status) {
      AlertMessages.getSuccessMessage('Updated Successfully');
      getAllfgDamagesData();
      setDrawerVisible(false);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}
  return (
    <Card title={<span style={{color:'white'}}>Fg RealTime Damges</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/fg-damages-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
       <Row gutter={40}>
        <Col>
            <Card title={'Total damges: ' + damgesdata.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
            </Col>
            <Col>
             <Card title={'Active: ' + damgesdata.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
            </Col>
            <Col>
             <Card title={'In-Active: ' + damgesdata.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
            </Col>
            </Row><br></br>
          <Card >        
          <Table
            rowKey={record => record.fgDamageId}
            columns={columnsSkelton}
            dataSource={damgesdata}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll = {{x:true}}
            onChange={onChange}
            size='small'
            bordered />
          </Card>
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '75%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                <FgDamagesForm key={Date.now()}
                  updateItem={updateDmagesdata}
                  isUpdate={true}
                  fgDamgesData={selecteddata}
                  closeForm={closeDrawer} />
              </Card>
            </Drawer>
          </Card>
  );
}

export default FgDamagesGrid;
