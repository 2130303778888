import { ApiProperty } from '@nestjs/swagger';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto } from '@gtpl/shared-models/masters';
import { PmStatusEnum } from '@gtpl/shared-models/common-models';

export class SoTrackModel {
    pmStatus: string;
    

    /**
     * @param pmStatus
    
     */

    constructor(pmStatus:string) {
        this.pmStatus = pmStatus;
        
    }


}

