import { AddressModel, AddressModelDefault } from './address-model';

// import { AddressModel, AddressModelDefault  } from "@brandix-apps/shared-models/master-data-management";

export class LocationModel {
    locationId:string;
    locationType:string;
    lat:string | null;
    long:string | null;
    createdAt: Date | any;
    updatedAt: Date | any;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
    
}

export const LocationModelDefault: LocationModel = {
    locationId: '',
    locationType: '',
    lat: '',
    long: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdUser: '',
    updatedUser: '',
    versionFlag: 0,
    
}