import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { RmLevelDto } from './rm-level.dto';


export class RmLevelResponseModel extends GlobalResponseObject {
    data?: RmLevelDto;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /RmLevelDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RmLevelDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 