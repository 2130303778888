import { FgStatusEnum, GlobalStatus, PlantsEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class GrnInformationDto {
    grnId?: number;
    grnDate: Date;
    indentId: number;
    grnNumber?: string;
    lotNumber?: string;
    saleOrderId?: number;
    saleOrderItemId?: number;
    supplierType: string;
    farmer: number;
    farmerCode: number;
    quantity: number;
    count: number;
    price: number;
    boxes: number;
    plantId: number;
    subPlantId: number;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    receivedBoxes : number;
    remarks:string;
}