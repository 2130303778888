export class ForkLiftJobUpdateRequest {
    jobId: number;
    stockId: number;
    rackPosition: number;
    reportedId: number;
    constructor(jobId: number,stockId: number,rackPosition: number,reportedId: number)
    {      
        this.jobId = jobId;
        this.stockId = stockId;
        this.rackPosition = rackPosition;
        this.reportedId = reportedId;
    }
}