export class DesignationDto {
    designationId: number;
    designation: string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    department?: string;
    toDepartment?: string;
    constructor(designationId: number, designation: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number, department?: string, toDepartment?: string) {
        this.designationId = designationId;
        this.designation = designation;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.department = department;
        this.toDepartment = toDepartment;
    }
}