import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { LoadingReportModel } from "./loading-report.model";

export class AllLoadingReportResponse extends GlobalResponseObject{
    data?: LoadingReportModel[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data BlStatusInfo
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: LoadingReportModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}