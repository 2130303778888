import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ApplyForLeavesDto } from "./apply-for-leaves-dto";

export class ApplyForLeavesResponseModel extends GlobalResponseObject{
    data?: ApplyForLeavesDto;

    /**
     * 
     * @param status
     * @param intlCode
     * @param internalMessage
     * @param data
     */

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ApplyForLeavesDto){
        super(status, intlCode, internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}