import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "antd";
import TrafficLight from './traffic-light';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import { AssetManagementService } from '@gtpl/shared-services/asset-management'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import CustomCard from './CustomCard';


import './traffic-lights.css';



/* eslint-disable-next-line */
export interface AssetsProps {
  updateKey : number
}

const duaration = 4 * 60;

export function Assets(
  props: AssetsProps
) {
  const service = new AssetManagementService();
  const [soakingData, setSoakingData] = useState<any[]>([])
  const [page, setPage] = React.useState(1);

  const [startTime, setStartTime] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [timerId, setTimerId] = useState<any>(0);
  const [durationTime, setDurationTime] = useState<any>(0)
  const [styles,setStyles] = useState<string>('')

  

  if(durationTime  < 180 && durationTime > 60){
    console.log()
    setStyles('-orange')
  }
  // else if(durationTime < 60 ){
  //   setStyles('-red')
  // }
  // else{
  //   setStyles('')
  // }
  const MINUTE_MS = 20000;
  useEffect(() => {
    const interval = setInterval(() => {
      getAssets();
      console.log('Logs every minute');
    }, MINUTE_MS);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    // if(soakingData){
    getAssets();
    // }
    
  }, [props.updateKey])

  const getAssets = () => {
    service.getSoakingMachineDashboardData().then(res => {
      if (res.status) {
        setSoakingData(res.data)
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  // const constructAssetDetail = (bodyDetail: any[]) => {
  //   console.log(bodyDetail);
  //   return bodyDetail.map(bodyProps =>
  //     <tr style={{ lineHeight: ' 1.8em' }}>
  //       <td >-</td>
  //       <td ><TrafficLight /></td>
  //       <td >{bodyProps.assetName}</td>
  //       <td >-</td>
  //     </tr>
  //   )
  // }
  // const constructTableHeader = (headers: string[]) => {
  //   return headers.map(head => <th>{head}</th>)
  // }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Asset',
      dataIndex: 'assetName',
      render: (text, rowData) => (
        <span>
          <TrafficLight />
        </span>
      )
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetCode',
    },
    // {
    //     title: 'Lot Product',
    //     dataIndex: 'lotProduct',
    // },
    // {
    //     title: 'Status',
    //     dataIndex: 'Status',
    // },
    {
      title: 'Start Time',
      dataIndex: 'createdAt',
      render: (value) => (moment(value).format('DD-MM-YYYY hh:mm'))
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      render: (value, rowData, index) => (moment(value).add(rowData.durationOne, 'minutes').format('hh:mm'))
    },
    {
      title: 'Total Duration',
      dataIndex: 'durationOne',
    }
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  
  return (
    <Row gutter={24}>
      {soakingData?.map(i => <CustomCard {...i} />)
      }
    </Row>
  );
}

export default Assets;
