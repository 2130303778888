export enum EmployeeRolesEnum {
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    L4 = 'L4',
    L5 = 'L5',
    L6 = 'L6',
    ADMIN = 'ADMIN',
    ASSISTANT_SALES_MANAGER = 'ASSISTANT SALES MANAGER',//head office sales to make saleorders
    HO_SALES_MANAGER = 'SALES MANAGER',//head office sales to make saleorders
    SR_SALES_MANAGER = 'SENIOR SALES MANAGER',//head office sales to make saleorders
    PLANT_SALES_MANAGER = 'PLANT SALES MANAGER',//plant sales to make dummy saleorders for inventory stock
    OPERATIONS_MANAGER = 'OPERATIONS_MANAGER',
    OPERATIONS_EXECUTIVE = 'OPERATIONS_EXECUTIVE',
    DOCUMENTATION_MANAGER = 'DOCUMENTATION HEAD',//logistics documentation head
    DOCUMENTATION_EXECUTIVE = 'DOCUMENTATION EXECUTIVE',//logistics documentation EXECUTIVE
    LOGISTICS_PLANT_HEAD = 'LOGISTICS PLANT HEAD',//
    LOGISTICS_HEAD = 'LOGISTICS HEAD',//
    LOGISTICS_PLANT_EXECUTIVE = 'LOGISTICS PLANT EXECUTIVE',//logistics documentation EXECUTIVE
    LOGISTICS_AND_WAREHOUSE_MANAGER = 'LOGISTICS AND WAREHOUSE MANAGER',//logistics documentation EXECUTIVE
    WAREHOUSE_MANAGER = 'WAREHOUSE MANAGER',//warehouse manager - warehouse full access
    TRANSPORTATION_MANAGER = 'TRANSPORTATION_MANAGER',
    TRANSPORTATION_EXECUTIVE = 'TRANSPORTATION_EXECUTIVE',
    PROCUREMENT_MANAGER = 'PROCUREMENT_MANAGER',
    DRIVER = 'DRIVER',
    RM_GRN = 'RM_GRN', //For RM GRN Updation
    NAVTECH_WH = 'NAVTECH_WH',
    ASSISTANT_PACKAGING_MANAGER = 'ASSISTANT PACKAGING MANAGER', // SAAPM HO- Assistant Packaging Manager
    HO_PACKAGING_MANAGER = 'HO PACKAGING MANAGER', // SAPM HO- Packaging Manager(ho packaging executive)
    SENIOR_PACKAGING_MANAGER = 'SENIOR PACKAGING MANAGER', // SASPM  PL- Senior Packaging Manager
    PACKAGING_SUPERVISOR = 'PACKAGING SUPERVISOR', // PS  PLANT- Packaging Supervisor
    INDENT_PERSON = 'INDENT PERSON', // role who brought the indent
    LOGISTICS_EXECUTIVE = 'LOGISTICS EXECUTIVE',//logistics  EXECUTIVE
    WAREHOUSE_INCHARGE = 'WAREHOUSE INCHARGE',//logistics  EXECUTIVE
    PACKING_CAPTAINS = 'PACKING CAPTAINS',
    UNIT_PACKING_CONTROLLER = 'UNIT PACKING CONTROLLER',
    PACKING_WAREHOUSE_CONTROLLER = 'PACKING WAREHOUSE CONTROLLER'
}