import React, { useState } from 'react';
import { Card, Col, Divider, Row } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import CounterDown from './CounterDown';
import moment from 'moment';

import './CustomCard.css';

const getLiveDuration = (createdDate, duration) => {
  console.log(moment(createdDate).format("hh:mm:ss"), duration, 'params', 'asset code')
  const now = moment(new Date());
  const endTime = moment(createdDate).add(duration, 'minutes')
  var durationTime = moment.duration(endTime.diff(now));
  var mins = durationTime.asMinutes();
  // setDurationTime(Math.round(mins)*60)

  return (Math.round(mins) * 60)
}


const CustomCard: React.FC = (props: any) => {
  const [color, setColor] = React.useState<string>('green');
  const [colorTwo, setColorTwo] = React.useState<string>('green');
  const [additionalStyles, setAdditionalStyles] = useState<any>({})
  const [blinkClass, setBlinkClass] = useState<string>('')


  React.useEffect(() => {
    if (color === 'red' && colorTwo == 'red') {
      setBlinkClass('-blink')
    } else {
      setAdditionalStyles({});
    }
  }, [color]);

  return (<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 3 }} xl={{ span: 3 }} style={{ padding: '1px' }}>

    <div className={'soaking-dashboard-card'} style={{ color: color }} >

      <Card style={{ ...additionalStyles, height: '75px', borderRadius: '3px', backgroundColor: props.assetStatus ? color : 'grey' }} bordered={false} title={<span style={{fontSize:'25px'}} >{props.assetCode}</span>}>
        {/* <FontAwesomeIcon icon={faClock} fontSize={'3vh'} /> */}
        <div className={'card' + blinkClass}>
          {props.assetStatus == true ? <CounterDown duration={getLiveDuration(props.createdAt, props.durationOne)} setColor={setColor} /> : <h2 style={{ fontSize: '4vh',  }}>  STOPPED</h2>
          }        </div>
      </Card>
      {/* <Divider type='horizontal' style={{ margin: '0' }} /> */}
      <Card style={{ ...additionalStyles, height: '75px', borderRadius: '3px', backgroundColor: colorTwo }} bordered={false} title={<span style={{fontSize:'25px'}} >{props.tubCode}</span>}>
        {/* <FontAwesomeIcon icon={faClock} fontSize={'3vh'} /> */}
        <div className={'card' + blinkClass}>
          <CounterDown duration={getLiveDuration(props.createdAt, (props.durationOne + props.durationTwo))} setColor={setColorTwo} />
        </div>

      </Card>

    </div>
    <Divider type='horizontal' style={{ margin: '3px',backgroundColor:'black' }} />


  </Col>)
}

export default CustomCard;