import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GradeToValAddInventory } from './grade-inventory-for-val-addition-data';

export class GradeToValAdditionResponseModel extends GlobalResponseObject{
    data?: GradeToValAddInventory;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: GradeToValAddInventory){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
