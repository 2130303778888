import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DepartmentDto } from './department-dto';


export class AllDepartmentsResponseModel extends GlobalResponseObject {
    data?: DepartmentDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: DepartmentDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

