import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { FGStockData } from './all-re-packing-stock.dto';

export class getDispatchListResponseModel extends GlobalResponseObject {
    data?: FGStockData[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: FGStockData[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}