export class IndentDropdownModel{
    indentId: number;
    indentCode: string;
    itemId: any;
  

    /**
     * 
     * @param indentId 
     * @param indentCode 
     */
    constructor(indentId: number,indentCode: string){
        this.indentId = indentId;
        this.indentCode = indentCode;
    }
}