import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ValAddOneDropDownData } from './valaddone-drop-down-model';

export class ValAddOneDropDownDataResponseModel extends GlobalResponseObject{
    data?: ValAddOneDropDownData[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //ValAddOneDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ValAddOneDropDownData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
