import React, { useEffect, useRef, useState } from 'react';
import { Divider, Form, Table, Popconfirm, Card, Tooltip, Switch, Input, InputNumber, Button, Tag, Row, Tabs, Col, Descriptions, Drawer, Typography, Modal } from 'antd';
import { ReceiveSubplantStockDto, StockReceiveData, WorkStatusDto, ReceiveStockData } from '@gtpl/shared-models/production-management';
import { ReceiveSubplantStockService, StockTransferService, ValueAdditionService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PrinterOutlined } from '@ant-design/icons';

import Link from 'antd/lib/typography/Link';
import { SubPlantMrnPrint } from '@gtpl/pages/production-management/production-management-components/sub-plant-mrn-print'
import './receive-sub-plants-stock.css';
import { OperationTypeEnum, TransferStatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface ReceiveSubPlantsStockNewProps { }

export function ReceiveSubPlantsStockNew(props: ReceiveSubPlantsStockNewProps) {
  const searchInput = useRef(null);
  
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [receiveSubplantStockData, setReceiveSubplantStockData] = useState<ReceiveSubplantStockDto[]>([]);
  const [workStatusData, setWorkStatusData] = useState<WorkStatusDto[]>([]);
  const [rowData, setRowData] = useState<ReceiveSubplantStockDto>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [printType, setPrintType] = useState('');
  const [selectedLogId, setSelectedLogId] = useState<number>(0);
  const [operationType, setOperationType] = useState<OperationTypeEnum>();
  const [formOrPrint, setFormOrPrint] = useState('');
  const [updateStateData, setUpdateStateData] = useState<number>(1);
  const [saleId, setSaleId] = useState(0);
  const [typeModel, setTypeModel]  = useState('');
  const { TabPane } = Tabs;
  const receiveSubplantStockService = new ReceiveSubplantStockService();
  const valueAdditionService = new ValueAdditionService();
  const transferService = new StockTransferService();

  useEffect(() => {
    getAllReceiveSubplantStock();
  }, []);

  /**
   * 
   */
  const getAllReceiveSubplantStock = () => {
    setReceiveSubplantStockData([]);
    const unitId = Number(localStorage.getItem('unit_id'))
    transferService.getAllReceiveSubplantStock(new UnitRequest(unitId)).then(res => {
      if (res.status) {
        setReceiveSubplantStockData(res.data);
      } else {
        if (res.intlCode) {
          setReceiveSubplantStockData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setReceiveSubplantStockData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getReceiveStock = () => {
    setWorkStatusData([]);
    const unitId = Number(localStorage.getItem('unit_id'));
    valueAdditionService.receiveSubPlantStockInfo(new UnitRequest(unitId)).then(res => {
      if (res.status) {
        setWorkStatusData(res.data);
      } else {
        if (res.intlCode) {
          setWorkStatusData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setWorkStatusData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const jobCompletedStatus = (record: ReceiveSubplantStockDto) => {
    if (record.receivedBoxes && record.receivedQuantity) {
      const recStock = new StockReceiveData(record.transferLogId, record.receivedQuantity, record.receivedBoxes, localStorage.getItem('createdUser'))
      const stock = new ReceiveStockData([recStock]);
      transferService.receiveStock(stock).then(res => {
        if (res.status) {
          getAllReceiveSubplantStock();
          AlertMessages.getSuccessMessage('Received Successfully');
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage("Enter Valid Information");
    }
  }

  /**
   * 
   * @param soakingData 
   */
  // const activateorDeactivateSoakingInventory = (soakingData:SoakingDto) => {
  //   soakingData.jobStatus=soakingData.jobStatus?false:true;
  //   soakingInventoryService.activateorDeactivateSoakingInventory(soakingData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllSoakingInventories();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }


  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid", saleOrderId);
    setIsModalVisible(true)
    setTypeModel('detailView');
  }

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  /**
   * 
   * @param soakingData 
   */
  const soakingAssignmentJobCompleted = (receiveSubplantdata: ReceiveSubplantStockDto) => {
    receiveSubplantStockService.jobCompletedStatus(receiveSubplantdata).then(res => {
      console.log(res);
      if (res.status) {
        getAllReceiveSubplantStock();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const setData = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.productionInventoryId)
    console.log(object);
  }

  const onFinish = (data) => {
    console.log(data);
  }

  const jobCompleted = (data: ReceiveSubplantStockDto) => {
    receiveSubplantStockService.jobCompletedStatus(data).then(res => {
      console.log(res);
      if (res.status) {
        getAllReceiveSubplantStock();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const columnsSkelton1: ColumnProps<ReceiveSubplantStockDto>[] = [
    {
      title: 'S No',
      key: 'sno',
      responsive: ['md'],
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'From Plant',
      dataIndex: 'fromPlant',
      // responsive: ['lg'],
      sorter: (a, b) => a.fromPlant.localeCompare(b.fromPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromPlant')
    },
    {
      title: 'To Plant',
      dataIndex: 'toPlant',
      // responsive: ['lg'],
      sorter: (a, b) => a.toPlant.localeCompare(b.toPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toPlant')
    },
    {
      title: 'Dc Number',
      dataIndex: 'dcNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotandplantlot',
      // responsive: ['lg'],
      render: (value, record, index) => {
        return <span>{record.lotandplantlot}</span>;
      },
      sorter: (a, b) => a.lotandplantlot.localeCompare(b.lotandplantlot),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotandplantlot')
    },
    // {
    //   title: 'Job Number',
    //   dataIndex: 'jobNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('jobNumber')
    // },

    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber')
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) =>
        <Link onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>

    },

    {
      title: 'Count',
      dataIndex: 'sendCount',
      align: 'right',
    },
    {
      title: 'Boxes',
      dataIndex: 'sendBoxes',
      align: 'right',

    },
    {
      title: 'Received Boxes',
      dataIndex: 'receivedBoxes',
      align: 'right',
      render: (value, record, index) => {
        record.receivedBoxes = (record.receivedBoxes) ? record.receivedBoxes : record.sendBoxes;
        return Math.round(record.receivedBoxes);
      }
      // render: (value, record, index) => {
      //   record.receivedBoxes = (record.receivedBoxes) ? record.receivedBoxes : record.sendBoxes;
      //   return <><Form><Form.Item name={record.transferLogId + "boxes"} rules={[{ required: true, message: 'Missing Received Boxes.' }, { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }, {
      //     validator: (_, value) =>
      //       value <= record.sendBoxes ? (value == 0) ? Promise.reject(new Error('Received Boxes should be > 0')) : Promise.resolve() : Promise.reject(new Error('Received Boxes should be <= boxes ' + record.sendBoxes)),
      //   }]}><InputNumber
      //       min={0}
      //       max={record.sendBoxes}
      //       defaultValue={(record.sendBoxes > 0) ? record.sendBoxes : 0}
      //       readOnly={!record.isActive}
      //       onChange={(value) => changeReceivedBoxes(record, index, value)} /></Form.Item></Form></>;
      // },

    },
    {
      title: 'Quantity',
      dataIndex: 'sendQuantity',
      align: 'right',
      render: (value, record, index) => {
        return Math.round(value);
      }
    },
    {
      title: 'Received Quantity',
      dataIndex: 'receivedQuantity',
      align: 'right',
      render: (value, record, index) => {
        record.receivedQuantity = (record.receivedQuantity) ? record.receivedQuantity : record.sendQuantity;
        return Math.round(record.receivedQuantity);
      }
      // render: (value, record, index) => {
      //   record.receivedQuantity = (record.receivedQuantity) ? record.receivedQuantity : record.sendQuantity;
      //   return <><Form><Form.Item name={record.transferLogId + " quantity"} rules={[{ required: true, message: 'Missing Received Quantity.' }, { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }, {
      //     validator: (_, value) =>
      //       value <= record.sendQuantity ? (value == 0) ? Promise.reject(new Error('Received Qty should be > 0')) : Promise.resolve() : Promise.reject(new Error('Quantity should be <= qty ' + record.sendQuantity)),
      //   }]}><InputNumber
      //       min={0}
      //       max={record.sendQuantity}
      //       defaultValue={(record.sendQuantity > 0) ? record.sendQuantity : 0}
      //       readOnly={!record.isActive}
      //       onChange={(value) => changeReceivedQuantity(record, index, value)} /></Form.Item></Form></>;
      // },
    },
    {
      title: `Is Received?`,
      dataIndex: 'status',
      render: (status, rowData) => (
        <span>
          <>
          {/* <Popconfirm onConfirm={e => { jobCompletedStatus(rowData); }}
            title={rowData.status === TransferStatusEnum.RECEIVED
              ? 'Are you sure to make it as not received ?'
              : 'Are you sure to make it as received ?'}
          >
            <Switch
              className={rowData.status === TransferStatusEnum.RECEIVED ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren='Yes'
              unCheckedChildren='No'
              checked={rowData.status === TransferStatusEnum.RECEIVED ? true : false}
              disabled={rowData.status === TransferStatusEnum.RECEIVED ? true : false} />
          </Popconfirm> */}
          { rowData.status === TransferStatusEnum.IN_TRANSIT ?
            <Popconfirm onConfirm={e => { jobCompletedStatus(rowData); }}
              title={'Are you sure to make it as received ?'}
            >
            <Switch
              className={'toggle-deactivated'}
              checkedChildren='Yes'
              unCheckedChildren='No'
              />
          </Popconfirm>
          :""
        }
        <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.transferLogId, 'MRN', rowData.operation, rowData);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
          </>
        </span>
      )
    }
  ];

  const changeReceivedBoxes = (record: ReceiveSubplantStockDto, index, value) => {
    if (value) {
      record.receivedBoxes = (value) ? value : 0;
      setUpdateStateData(updateStateData + 1);
    }
  }

  const changeReceivedQuantity = (record: ReceiveSubplantStockDto, index, value) => {
    if (value) {
      record.receivedQuantity = (value) ? value : 0;
      setUpdateStateData(updateStateData + 1);
    }
  }

  const openPrint = (logId, printType, operationType, data) => {
    setSelectedLogId(logId)
    setIsModalVisible(true);
    setPrintType(printType);
    setOperationType(operationType);
    setFormOrPrint('print');
    setRowData(data);
  }

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');



  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
* get form data 
* @param fromDoc 
* @param toDoc 
*/
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }
  /**
* print modal
*/
  const printOrder = () => {
    console.log("****************************");
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };


  return (
    <Card title={<span style={{ color: 'white' }}>Receive Stock</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <br></br>
      {/* <Row gutter={40} >
      <Col>
          <Card title={'Total Departments: ' + departmentsData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + departmentsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + departmentsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row> */}
      {/* <br></br> */}

      <Tabs type={'card'} tabPosition={'top'}>
        {/* <TabPane
          key="3"
          tab={<span style={{ color: "blue" }}>GRN: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.GRN).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.GRN)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane> */}
        <TabPane
          key="1"
          tab={<span style={{ color: "blue" }}>Deheading: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.BE_HEADING).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.BE_HEADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "#db0f0f" }}>Grading: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.GRADING).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.GRADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>
        <TabPane
          key="3"
          tab={<span style={{ color: "#32a852" }}>Value Addition: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>
       

        <TabPane
          key="4"
          tab={<span style={{ color: "#0fd4db" }}>Soaking: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.SOAKING).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.SOAKING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>

        <TabPane
          key="5"
          tab={<span style={{ color: "#f707ef" }}>Freezing: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.FREEZING).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.FREEZING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>

        <TabPane
          key="6"
          tab={<span style={{ color: "#f79707" }}>Packing: {receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.PACKING).length}</span>}
        >
          <Table
            rowKey={record => record.transferLogId}
            columns={columnsSkelton1}
            dataSource={receiveSubplantStockData.filter(res => res.operation === OperationTypeEnum.PACKING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>


      </Tabs>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {<SubPlantMrnPrint logId={selectedLogId} printOrder={printOrder} operationType={operationType} data={rowData} />}
      </Modal>
    </Card>
  );
}

export default ReceiveSubPlantsStockNew;