export class QaQcData{
    qaQcTestId?: number;
    testCode: string;
    testName: string;
    operationInfo: number;
    isActive: boolean;
    createdAt?: Date;
    createdUser?: string;
    updatedAt?: Date;
    updatedUser?: string;
    versionFlag?: number;
    operationName?: string;


    /**
     * 
     * @param testCode 
     * @param testName 
     * @param testDescription 
     * @param operationInfo 
     * @param isActive 
     * @param qaQcTestId 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(testCode: string, testName: string, operationInfo: number, isActive: boolean, qaQcTestId?: number , createdAt?: Date, createdUser?: string, updatedAt?: Date, updatedUser?: string, versionFlag?: number, operationName?: string){
        this.qaQcTestId =  qaQcTestId;
        this.testCode =  testCode;
        this.testName =  testName;
        this.operationInfo =  operationInfo;
        this.isActive =  isActive;
        this.createdAt =  createdAt;
        this.createdUser =  createdUser;
        this.updatedAt =  updatedAt;
        this.updatedUser =  updatedUser;
        this.versionFlag =  versionFlag;
        this.operationName =  operationName;
    }

}