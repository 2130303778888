import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { MasterInfoModel } from './get-master-info.model';

export class MasterInfoFromIndentResponse extends GlobalResponseObject{
    data?: MasterInfoModel;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: MasterInfoModel){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
