import React, { useEffect, useRef, useState } from 'react';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { HarvestIdRequest, HarvestingTime, HarvestModel, IndentDetailsModel, IndentModel, SupplierType } from '@gtpl/shared-models/raw-material-procurement';
import { IndentForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-form';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, ConfigProvider, Modal, Layout, Popover } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Table, { ColumnProps } from 'antd/lib/table';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import './indent-grid.css';
import { RawMaterialGrnForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-form';
import { HarvestReport } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/harvest-report';
import { render } from 'react-dom';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { PlantIdRequest } from '@gtpl/shared-models/masters';


/* eslint-disable-next-line */
export interface IndentGridProps { }

export function IndentGrid(
  props: IndentGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');


  const [drawerVisible, setDrawerVisible] = useState(false);
  const [indentData, setIndentData] = useState<IndentModel[]>([]);
  const [completedIndentData, setCompletedIndentData] = useState<IndentModel[]>([]);
  // const [updateState, setUpdateState] = useState(false);
  const [plantId, setPlantIdState] = useState(0);
  // const [object, setObject] = useState(null);
  const indentService = new IndentService();

  const [selectedIndentData, setSelectedIndentData] = useState<any>(undefined);
  const [allData, setAllData] = useState<IndentModel[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);


  const [indentId, setIndentIdState] = useState(0);
  const { TabPane } = Tabs;
  const unitId = localStorage.getItem("unit_id");
  const role = localStorage.getItem("role");
  const content = (
    <div>
      <p>Assign a Vehicle to raise a GRN </p>
    </div>
  );

  useEffect(() => {
    getAllIndentDetails();
    getCompletedIndents();
  }, []);


  const getAllIndentDetails = () => {
    // console.log(moment());
    // console.log(new Date().toISOString());
    // console.log(new Date(Date.now() - 1 * 86400000 - new Date().getTimezoneOffset() * 60000).toISOString())
    // console.log(new Date(Date.now() - 2 * 86400000 - new Date().getTimezoneOffset() * 60000).toISOString())
    indentService.getAllIndents().then(res => {
      if (res.status) {
        if (role === '"ADMIN"') {
          console.log(role);
          setIndentData(res.data);
        }
        else {
          setIndentData(res.data.filter(rec => rec.plantId === null || rec.plantId === 0 || rec.plantId === Number(unitId)));
        }

        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setIndentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAllData([]);
      setIndentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
console.log(indentData)
  // const updateIndent = (indentData:IndentDetailsModel) => {
  //   indentService.updateIndent(indentData).then(res => {
  //     if (res.status) {
  //       AlertMessages.getSuccessMessage('Indent Updated Successfully');
  //       getAllIndentDetails();
  //       setDrawerVisible(false);
  //     } else {
  //       if (res.intlCode) {
  //         // AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const deleteIndent = (indentData: IndentModel) => {
    indentData.isActive = indentData.isActive ? false : true;
    indentService.activateOrDeactivateIndent(indentData).then(res => {
      if (res.status) {
        getAllIndentDetails();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const setPlantId = (rowdata: { plantId: number; }) => {
    setPlantIdState(rowdata.plantId)

  }


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const closeDrawer = () => {
    setDrawerVisible(false);
    getAllIndentDetails();
  }


  const openFormWithData = (viewData: IndentModel) => {
    setDrawerVisible(true);
    setSelectedIndentData(viewData);
  }



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Indent Code',
      key: 'indentCode',
      dataIndex: 'indentCode',
      // responsive: ['sm'],
      width: 180,
      align: 'left',
      ...getColumnSearchProps('indentCode'),
      sorter: (a, b) => a.indentCode.localeCompare(b.indentCode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      key: 'indentDate',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Indent By',
      dataIndex: 'indentByName',
      key: 'indentByName',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => a.indentByName.localeCompare(b.indentByName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentByName')
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Count',
      dataIndex: 'expectedCount',
      key: 'expectedCount',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Quantity',
      dataIndex: 'expectedQty',
      key: 'expectedQty',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Unit',
      dataIndex: 'plant',
      key: 'plant',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Supplier Type',
      dataIndex: 'supplierType',
      key: 'SupplierType',
      responsive: ['md'],
      width: 180,
      align: 'left',
      sorter: (a, b) => a.supplierType.localeCompare(b.supplierType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: SupplierType.Agent,
          value: SupplierType.Agent,
        },

        {
          text: SupplierType.Broker,
          value: SupplierType.Broker,
        },
        {
          text: SupplierType.Farmer,
          value: SupplierType.Farmer,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.supplierType === value;
      },


    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier',
      key: 'supplier',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => a.supplier.localeCompare(b.supplier),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('supplier')
    },
  
    {
      title: 'Harvest Date',
      dataIndex: 'harvestingDate',
      key: 'harvestingDate',
      responsive: ['md'],
      width: 180,
      align: 'left',
      sorter: (a, b) => moment(a.harvestingDate).unix() - moment(b.harvestingDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.harvestingDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Harvest Time',
      dataIndex: 'harvestingTime',
      key: 'harvestingTime',
      responsive: ['sm'],
      width: 180,
      align: 'left',
      sorter: (a, b) => a.harvestingTime.localeCompare(b.harvestingTime),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: HarvestingTime.MORNING,
          value: HarvestingTime.MORNING,
        },

        {
          text: HarvestingTime.EVENING,
          value: HarvestingTime.EVENING,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.harvestingTime === value;
      },
      // ...getColumnSearchProps('status')
    },
    {
      title: 'Status',
      dataIndex: 'grnCompleted',
      key: 'grnCompleted',
      // responsive: ['sm'],
      width: 180,
      align: 'left',
      render: (grnCompleted, rowData) => (
        <>
          {((rowData.vehicleAssigned === 1 && rowData.isVehicleRequired === 1) || rowData.isVehicleRequired === 0) && rowData.plantId != 0 && grnCompleted === 0 ?
            <span>
              {/* <Button size={'small'} title={'Click to add GRN'} icon={<ExclamationCircleOutlined />} onClick={() => {
                console.log(rowData)
                if (rowData.isActive) {
                  return <Link
                    to={{
                      pathname: "/rm-grn-form",
                      state: { id: rowData.indentId }
                    }}
                  />
                } else {
                  AlertMessages.getErrorMessage("You can't Create Grn For Deactivated Indent");
                }
              }}
                style={{ backgroundColor: '#e8e21e', color: "black", fontWeight: 'bold', fontSize: 'small' }}
              >
                Add GRN
              </Button> */}
              <Link
                to={`/rm-grn-form/${rowData.indentId}`}
              >
                <Button
                  size={'small'}
                  title={'Click to Add GRN'}
                  icon={<ExclamationCircleOutlined />}
                  style={{ backgroundColor: '#e8e21e', color: "black", fontWeight: 'bold', fontSize: 'small' }}
                >
                  Add GRN
                </Button>
              </Link>
            </span>

            : rowData.vehicleAssigned === 1 && rowData.plantId != 0 && grnCompleted === 1 ?
              <Tag
                icon={<CheckCircleOutlined />}
                style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}
              >
                COMPLETED
              </Tag>
              : rowData.plantId === 0 ?
                <Button
                  size={'small'}
                  title={'Click to Assign Vehicle'}
                  icon={<ExclamationCircleOutlined />}
                  style={{ backgroundColor: '#08979c', color: "white", fontWeight: 'bold', fontSize: 'small' }}
                  onClick={() => {
                    if (rowData.isActive) {
                      openFormWithData(rowData);
                    } else {
                      AlertMessages.getErrorMessage('You Cannot Edit Deactivated Indent');
                    }
                  }}
                >
                  Assign Plant
                </Button>
                : rowData.vehicleAssigned === 0 && rowData.isVehicleRequired === 1 ?
                  <Link to="/vehicle-assignment">
                    <Button
                      size={'small'}
                      title={'Click to Assign Vehicle'}
                      icon={<ExclamationCircleOutlined />}
                      style={{ backgroundColor: '#faad14', color: "black", fontWeight: 'bold', fontSize: 'small' }}
                    >
                      Assign Vehicle
                    </Button>
                  </Link>
                  //  <Popover content={(rowData.isActive) ? content : 'Activate indent to create grn'} title={(rowData.isActive) ? "Vehicle is Not Assigned" : "You Can't Perform action on Deactivated Indent"} trigger="click">
                  //   <Tag icon={<ExclamationCircleOutlined />} style={{ backgroundColor: '#fa8c16', color: "black", fontWeight: 'bold' }}>PENDING</Tag>
                  //    </Popover>
                  : "-"}
        </>
      ),
    },
    {
      title: 'Aging',
      key: 'age',
      // responsive: ['sm'],
      sorter: (a, b) => (Math.floor((new Date(moment(a.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.harvestingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
          props: {
            style: {
              background: Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
            }
          },
        };
        return obj;
      }
    },
    {
      title: `Action`,
      dataIndex: 'action',
      width: 180,
      // responsive: ['sm'],
      render: (text, rowData) => (
        <span>

          <Tooltip placement="top" title='Update/Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Indent');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />

          <Popconfirm onConfirm={e => { deleteIndent(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Indent ?'
                : 'Are you sure to Activate Indent ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: "/indent-detail-view",
              state: rowData.indentId
            }}
          >
            <Tooltip placement="top" title='Detail View'>
              <EyeOutlined type="view" name="detail view"
                onClick={() => {

                  // if (rowData.isActive) {
                  setPlantId(rowData);
                  // } else { 
                  //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                  // }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            </Tooltip>
          </Link>
        </span>
      )
    }
  ];

  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Indent Code',
      key: 'indentCode',
      dataIndex: 'indentCode',
      width: 180,
      align: 'left',
      ...getColumnSearchProps('indentCode'),
      sorter: (a, b) => a.indentCode.localeCompare(b.indentCode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Indent By',
      dataIndex: 'indentPerson',
      key: 'indentPerson',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => a.indentPerson.localeCompare(b.indentPerson),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentPerson')
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      key: 'indentDate',
      width: 180,
      align: 'left',
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Supplier Type',
      dataIndex: 'supplierType',
      key: 'supplierType',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.supplierType.localeCompare(b.supplierType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('supplierType')
    },
    {
      title: 'Supplier Name',
      dataIndex: 'farmerName',
      key: 'farmerName',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => a.farmerName.localeCompare(b.farmerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('farmerName')
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Count',
      dataIndex: 'expectedCount',
      key: 'expectedCount',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Quantity',
      dataIndex: 'expectedQty',
      key: 'expectedQty',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Unit',
      dataIndex: 'plant',
      key: 'plant',
      width: 180,
      // responsive: ['sm'],
      align: 'left',
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    // {
    //   title: 'Farmer Name',
    //   key: 'supplierName',      
    //   dataIndex: 'supplierName',
    //   responsive: ['sm'],
    //   hideInSearch: true,
    //   hideInForm: true,
    //   width: 180,
    //   align: 'left',
    //   ...getColumnSearchProps('supplierName'),
    //   // sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
    //   // sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Product Code',
    //   dataIndex:'productCode',
    //   key: 'productCode',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   width: 200,
    //   align: 'left',
    //   // render: (rowData) => {return rowData.product},
    //   sorter: (a, b) => a.productCode.localeCompare(b.productCode),
    //   // sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('productCode')
    // },
    // {
    //   title: 'Expected Quantity',
    //   dataIndex:'expectedQty',
    //   key: 'expectedQty',      
    //   hideInSearch: true,
    //   hideInForm: true,
    //   width: 200,
    //   align: 'right',
    //   sorter: (a, b) => a.expectedQty - b.expectedQty,
    //   sortDirections: ['descend', 'ascend'],

    // },
    {
      title: 'Harvest Date',
      dataIndex: 'harvestingDate',
      key: 'harvestingDate',
      width: 180,
      align: 'left',
      sorter: (a, b) => moment(a.harvestingDate).unix() - moment(b.harvestingDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { console.log(record); return moment(record.harvestingDate).format('YYYY-MM-DD') },

    },
    {
      title: 'Harvest Time',
      dataIndex: 'harvestingTime',
      key: 'harvestingTime',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.harvestingTime.localeCompare(b.harvestingTime),
      sortDirections: ['descend', 'ascend'],

    },
    // {
    //   title: 'Address',
    //   dataIndex:'address',
    //   key: 'address',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   width: 180,
    //   // align: 'left',
    // },
    // {
    //   title: 'Mobile No',
    //   dataIndex:'mobileNumber',
    //   key: 'mobileNumber',
    //   hideInSearch: true,
    //   hideInForm: true,
    //   // width: 180,
    //   // align: 'left',
    //   sorter: (a, b) => a.mobileNumber.localeCompare(b.mobileNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   // filters: true,
    //   // onFilter: true,
    //   // valueEnum: {
    //   //   Morning: { text: 'Morning', status: 'Default' },
    //   //   Evening: { text: 'Evening', status: 'Default' }
    //   // } 
    // },
    {
      title: 'GRN',
      dataIndex: 'grnCompleted',
      key: 'grnCompleted',
      width: 180,
      align: 'left',
      // sorter: (a, b) => a.grnCompleted.localeCompare(b.grnCompleted),
      // sortDirections: ['descend', 'ascend'],
      // filters: true,
      // onFilter: true,
      // filters: [
      //   {
      //     text: 'CLOSED',
      //     value: true,
      //   },
      //   {
      //     text: 'OPEN',
      //     value: false,
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.grnCompleted === value;
      // },
      render: (grnCompleted, rowData: IndentModel) => (
        <>
          {(rowData.isVehicleRequired == true && rowData.vehicleAssigned == true) ? grnCompleted == false ?
            <span>
              <Tag icon={<ExclamationCircleOutlined />} onClick={() => { setIndentId(rowData.indentId); }}
                style={{ backgroundColor: '#e8e21e', color: "black", fontWeight: 'bold' }}>PENDING</Tag>
            </span>
            : <Tag icon={<CheckCircleOutlined />} style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>COMPLETED</Tag>
            : <Popover content={content} title="Vehicle is Not Assigned" trigger="click">
              <Tag icon={<ExclamationCircleOutlined />} style={{ backgroundColor: '#fa8c16', color: "black", fontWeight: 'bold' }}>PENDING</Tag>
            </Popover>
          }
        </>
      ),
    },

    // {
    //   title: 'Aging',
    //   key: 'age',
    //   // responsive: ['sm'],
    //   sorter: (a, b) => (Math.floor((new Date(moment(a.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.harvestingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('aging'),
    //   render(text, record) {
    //     {return "-"}
    //   }
    // },
    {
      title: `Action`,
      dataIndex: 'action',
      width: 180,
      render: (text, rowData) => (
        <span>

          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Indent');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
          <Divider type="vertical" />

          <Popconfirm onConfirm={e => { deleteIndent(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Indent ?'
                : 'Are you sure to Activate Indent ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />
          </Popconfirm>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: "/indent-detail-view",
              state: rowData.indentId
            }}
          >
            <EyeOutlined type="view" name="detail view"
              onClick={() => {

                // if (rowData.isActive) {
                setPlantId(rowData);
                // } else { 
                //  AlertMessages.getErrorMessage('You Cannot Edit Deactivated Indent');
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Link>


        </span>
      )
    },
    {

    }
  ];


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const setIndentId = (indentId: number) => {
    setIndentIdState(indentId);
    setIsModalVisible(true);
    // setCompName(compName);  
  }

  const openModal = () => {
    setIsModalVisible(true);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const tabOnchange = (value) => {
  //   console.log(value)
  //   if (value == 4) {
  //     getCompletedIndents();
  //   }
  // }
  const getCompletedIndents = () => {
    indentService.getCompletedIndents().then(res => {
      if (res.status) {
        if (role === '"ADMIN"') {
          console.log(role);
          console.log(res.data);
          setCompletedIndentData(res.data);
        }
        else {
          setCompletedIndentData(res.data.filter(rec => rec.plantId === null || rec.plantId === 0 || rec.plantId === Number(unitId)));
        }

        setAllData(res.data);
      } else {
        setAllData([]);
        if (res.intlCode) {
          setCompletedIndentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAllData([]);
      setIndentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

const sum = (indentData && completedIndentData) ? [...indentData,...completedIndentData] : [0]
// console.log(sum)
// console.log(sum.length)

  return (
    <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
      <Card title={<span style={{ color: 'white' }}>Indents</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/indent-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
        <br></br>
        <Row gutter={24}>
          <Col>
            <Card title={'Total Indents: ' + sum.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
        </Row>
        <br></br>
        <ConfigProvider locale={enUSIntl}>
          <Tabs type={'card'} tabPosition={'top'} >
            <TabPane
              key="1"
              tab={<span style={{ color: "#f5222d" }}>Pending: {indentData?.length}</span>}
            >
              <Table<IndentModel>
                rowKey={record => record.indentId}
                columns={columnsSkelton}
                dataSource={indentData}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                // request={(params, sorter, filter) => {
                //   return Promise.resolve({
                //     data: indentData,
                //     success: true,
                //   });
                // }}

                // dateFormatter='string'
                // search={{
                //   searchText: 'Filter',
                // }}
                // onSubmit={(params) => {
                //   if (Object.keys(params).length) {
                //     let filteredData = [];
                //     if (params.indentDate && params.harvestingDate && params.indentCode && params.age) {
                //       filteredData = indentData.filter((record) => {
                //         if (moment(record.indentDate).format('YYYY-MM-DD') === params.indentDate && (moment(record.harvestingDate).format('YYYY-MM-DD') >= params.harvestingDate[0]) && (moment(record.harvestingDate).format('YYYY-MM-DD') <= params.harvestingDate[1]) && (record.indentCode.includes(params.indentCode)) && (Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 === params.age)) {
                //           return record;
                //         }
                //       });
                //     } else if (params.indentDate) {
                //       filteredData = indentData.filter((record) => {
                //         if ((moment(record.indentDate).format('YYYY-MM-DD') >= params.indentDate[0]) && (moment(record.indentDate).format('YYYY-MM-DD') <= params.indentDate[1])) {
                //           return record;
                //         }
                //       });
                //       // filteredData = indentData.filter(record => moment(record.indentDate).format('YYYY-MM-DD') === params.indentDate);
                //     } else if (params.harvestingDate) {
                //       filteredData = indentData.filter((record) => {
                //         if ((moment(record.harvestingDate).format('YYYY-MM-DD') >= params.harvestingDate[0]) && (moment(record.harvestingDate).format('YYYY-MM-DD') <= params.harvestingDate[1])) {
                //           return record;
                //         }
                //       });
                //     } else if (params.indentCode) {
                //       filteredData = indentData.filter(record => record.indentCode.includes(params.indentCode))
                //     } else if (params.age) {
                //       filteredData = indentData.filter(record => {
                //         const age = Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1;
                //         if (age.toString() === params.age) {
                //           return record;
                //         }
                //       })
                //     }
                //     setIndentData(filteredData);
                //   }
                // }}
                // onReset={() => {
                //   setIndentData(allData);
                // }}
                scroll={{ x: 500 }} />
            </TabPane>
            <TabPane
              key="4"
              tab={<span style={{ color: "#e8e21e" }}>Completed: {completedIndentData.length}</span>}
            >
              <Table<IndentModel>
                rowKey={record => record.indentId}
                columns={columnsSkelton1}
                dataSource={completedIndentData}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                // onSubmit={(params) => {
                //   if (Object.keys(params).length) {
                //     let filteredData = [];
                //     if (params.indentDate && params.harvestingDate && params.indentCode && params.age) {
                //       filteredData = indentData.filter((record) => {
                //         if (moment(record.indentDate).format('YYYY-MM-DD') === params.indentDate && (moment(record.harvestingDate).format('YYYY-MM-DD') >= params.harvestingDate[0]) && (moment(record.harvestingDate).format('YYYY-MM-DD') <= params.harvestingDate[1]) && (record.indentCode.includes(params.indentCode)) && (Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 === params.age)) {
                //           return record;
                //         }
                //       });
                //     } else if (params.indentDate) {
                //       filteredData = indentData.filter((record) => {
                //         if ((moment(record.indentDate).format('YYYY-MM-DD') >= params.indentDate[0]) && (moment(record.indentDate).format('YYYY-MM-DD') <= params.indentDate[1])) {
                //           return record;
                //         }
                //       });
                //       // filteredData = indentData.filter(record => moment(record.indentDate).format('YYYY-MM-DD') === params.indentDate);
                //     } else if (params.harvestingDate) {
                //       filteredData = indentData.filter((record) => {
                //         if ((moment(record.harvestingDate).format('YYYY-MM-DD') >= params.harvestingDate[0]) && (moment(record.harvestingDate).format('YYYY-MM-DD') <= params.harvestingDate[1])) {
                //           return record;
                //         }
                //       });
                //     } else if (params.indentCode) {
                //       filteredData = indentData.filter(record => record.indentCode.includes(params.indentCode))
                //     } else if (params.age) {
                //       filteredData = indentData.filter(record => {
                //         const age = Math.floor((new Date(moment(record.harvestingDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1;
                //         if (age.toString() === params.age) {
                //           return record;
                //         }
                //       })
                //     }
                //     setIndentData(filteredData);
                //   }
                // }}
                // onReset={() => {
                //   setIndentData(allData);
                // }}
                scroll={{ x: 500 }} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '65%' : '100%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <IndentForm key={Date.now()}
              isUpdate={true}
              indentData={selectedIndentData}
              // updateIndent={updateIndent}
              closeDrawer={closeDrawer}
            />

          </Card>
        </Drawer>
      </Card>
      {isModalVisible ?
        <Modal
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          <RawMaterialGrnForm key={Date.now()}
            updateItem={undefined}
            isUpdate={false}
            grnData={undefined}
            closeForm={closeDrawer}
            indentId={indentId} />
        </Modal> : ""}

    </Layout>
  );
}

export default IndentGrid;
