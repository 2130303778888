
export class LocationAuditDto {
    auditId: number;
    locationId: number;
    auditType: string;
    audtiDate:any;
    startDate:any;
    completedDate:any;
    auditProvider:string;
    auditName:string;
    auditOwner : string;
    phoneNumber : string;
    working: number;
    Notworking: number;
    status : string;
    remarks : string;
    reason : string;
    isActive: boolean;

    constructor(
        auditId: number,
        locationId: number,
        auditType: string,
        audtiDate:any,
        startDate:any,
        completedDate:any,
        auditProvider:string,
        auditName:string,
        auditOwner : string,
        phoneNumber : string,
        working: number,
        Notworking: number,
        status : string,
        remarks : string,
        reason : string,
        isActive: boolean,
      

    ) {
        this.auditId = auditId;
        this.locationId = locationId;
        this.auditType = auditType;
        this.audtiDate = audtiDate;
        this.startDate = startDate;
        this.completedDate = completedDate;
        this.auditProvider = auditProvider;
        this.auditName = auditName;
        this.auditOwner = auditOwner;
        this.phoneNumber = phoneNumber;
        this.working = working;
        this.Notworking = Notworking;
        this.status = status;
        this.remarks = remarks;
        this.reason = reason;
        this.isActive = isActive;
    }

}
