import { GlobalStatus, StockStatus, TransactionType } from "@gtpl/shared-models/common-models";
import { StoreConsumptionStatus } from "@gtpl/shared-models/procurement-management";

export class StoreIssuesRequestDto {
    storeConsumptionId: number;
    indentDate: Date;
    indentNo: string;
    storeConsumptionCode: string;
    consumedDate: Date;
    saleOrderId: number;
    saleOrderItemId: number;
    unitId: number;
    salePerson: number;
    status: StoreConsumptionStatus;
    isPreparedMaterial: GlobalStatus;
    itemId: number;
    issuedQuantity: number;
    returnedQuantity: number;
    barcode: string;
    stockType: StockStatus;
    transactionType: TransactionType;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    tracebilityCodeId: number;
    constructor(storeConsumptionId: number,indentDate: Date,indentNo: string,storeConsumptionCode: string,consumedDate: Date,saleOrderId: number,saleOrderItemId: number,unitId: number,salePerson: number,status: StoreConsumptionStatus,isPreparedMaterial: GlobalStatus,itemId: number,issuedQuantity: number,returnedQuantity: number,barcode: string,stockType: StockStatus,transactionType: TransactionType,createdUser: string | null,updatedAt: Date,updatedUser: string | null,tracebilityCodeId: number){
        this.storeConsumptionId = storeConsumptionId
        this.indentDate = indentDate
        this.indentNo = indentNo
        this.storeConsumptionCode = storeConsumptionCode
        this.consumedDate = consumedDate
        this.saleOrderId = saleOrderId
        this.saleOrderItemId = saleOrderItemId
        this.unitId = unitId
        this.salePerson = salePerson
        this.status = status
        this.isPreparedMaterial = isPreparedMaterial
        this.itemId = itemId
        this.issuedQuantity = issuedQuantity
        this.returnedQuantity = returnedQuantity
        this.barcode = barcode
        this.stockType = stockType
        this.transactionType = transactionType
        this.createdUser = createdUser
        this.updatedAt = updatedAt
        this.updatedUser = updatedUser
        this.tracebilityCodeId = tracebilityCodeId
    }
}