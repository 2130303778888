import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { DatePicker, Space } from "antd";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import moment from "moment";
import { Excel } from 'antd-table-saveas-excel';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { Option } = Select;
const { RangePicker } = DatePicker;
import Highlighter from 'react-highlight-words';
import { AssetByStatus, AssetServiceRportReq } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";


/* eslint-disable-next-line */
export interface ChecklistReportProps {
    viewrow(rowData: any, arg1: boolean): unknown;
}

export const ChecklistReport = (
    props: ChecklistReportProps
) => {
    const [page, setPage] = React.useState(1);
    const [form] = Form.useForm();
    const onSearch = (value: string) => console.log(value);
    const [transData, setTransData] = useState<any>([]);
    const { TabPane } = Tabs;
    const [assetsData, setAssetsData] = useState<AssetDto[]>([])
    const assetService = new AssetService();
    const { Text } = Typography;
    const [length, setLength] = useState('');
    const locationService = new AssetLocationService();
    const [locationData, setLocationData] = useState<any[]>([]);
    const plantsService = new UnitcodeService();
    const [categoryData, setCategoryData] = useState<any[]>([])
    const [plantName, setPlantName] = useState<any>([]);
    const [itemCategory, setItemCategory] = useState([]);
    const itemSubCategoryService = new ItemSubCategoryService();
    const [pageSize, setPageSize] = useState<number>(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);




    // const service = new AssetMaintenanceService();
    const role = JSON.parse(localStorage.getItem('role'))

    let diff = 0;
    let date;
    let todate;
    var d = new Date();
    var currentMonth = d.getMonth();
    var currentYear = d.getFullYear();
    var startDate = new Date(currentYear, currentMonth, 1)
    const dateformat = 'YYYY-MM-DD';

    useEffect(() => {
        //getAllData();
        getChecklistReport();
        getAssetCode();
        getAllPlants();
        getItemCategory();
        // getAllCategories();
        // getTransById();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
        } getLocations();
    }, [])

    const getItemCategory = () => {
        itemSubCategoryService.getGeneralItemSubCategories().then(res => {
            if (res.status) {
                setItemCategory(res.data)
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }

    const getAllPlants = () => {
        plantsService.getAllPlants().then(res => {
            if (res.status) {
                setPlantName(res.data)
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }

    const getLocations = () => {
        const req = new LocationByPlant();
        req.plant = form.getFieldValue('plantId')
        locationService.getAllAssets(req).then(res => {
            if (res.status) {
                setLocationData(res.data)
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }

    const getChecklistReport = () => {
        const req = new AssetByStatus();
        if (form.getFieldValue('assetCategory') !== undefined) {
            req.assetCategory = form.getFieldValue('assetCategory')
        }
        if (form.getFieldValue('assetCode') != undefined) {
            console.log(form.getFieldValue('assetCode'),'assetCode')
            req.assetCode = form.getFieldValue('assetCode')
        }
        if (form.getFieldValue('plantId') != undefined) {
            req.plantId = form.getFieldValue('plantId')
        }
        if (form.getFieldValue('location') != undefined) {
            req.location = form.getFieldValue('location')
        }
        if (form.getFieldValue('date') !== undefined) {
            req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM_DD')
        }
        if (form.getFieldValue('date') !== undefined) {
            req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM_DD')
        }
        console.log(req,'req')
        assetService.getChecklistReport(req).then((res) => {
            if (res.status) {
                setTransData(res.data)
                setLength(res.data.length)
            } else {
                setTransData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setTransData([]);
        })
    }

    const onReset = () => {
        form.resetFields();
        getChecklistReport();
    }


    const getAssetCode = () => {
        assetService.getallAssets().then((res) => {
            if (res.status) {
                setAssetsData(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }


    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            ) : null
    });



    const resetHandler = () => {
        form.resetFields();
        setTransData([])
    }

    const columns: ColumnProps<any>[] = [
        {
            title: "S.No",
            key: "sno",
            width: "30px",
            responsive: ["sm"],
            render: (text, object, index) => (page - 1) * pageSize + (index + 1)
        },
        {
            title: "Unit",
            dataIndex: "plant",
            align: "left",
            // width:90,

            // width:120,
            sorter: (a, b) => a.unit?.localeCompare(b.unit),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => {
                return (
                    <>
                        {record.unit ? record.unit : '-'}
                    </>
                )
            },
            ...getColumnSearchProps('unit')
        },


        {
            title: "Location",
            dataIndex: "locationName",
            align: "left",
            // width:80,

            // width:120,
            sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
            sortDirections: ['ascend', 'descend',],
            ...getColumnSearchProps('locationName'),
        },

        {
            title: "Asset Code",
            dataIndex: "assetCode",
            align: "left",
            // width:90,

            //width:120,
            sorter: (a, b) => a.assetCode?.localeCompare(b.assetCode),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => {
                return (
                    <>
                        {record.assetCode ? record.assetCode : '-'}
                    </>
                )
            },
            ...getColumnSearchProps('assetCode')
        },
        {
            title: "Asset Name",
            dataIndex: "assetName",
            align: "left",
            // width:90,

            //width:120,
            sorter: (a, b) => a.assetName?.localeCompare(b.assetName),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => {
                return (
                    <>
                        {record.assetName ? record.assetName : '-'}
                    </>
                )
            },
            ...getColumnSearchProps('assetName')
        },
        {
            title: "Service Date",
            dataIndex: "serviceDate",
            align: "left",
            // width:80,

            // width:120,
            sorter: (a, b) => a.serviceDate?.localeCompare(b.serviceDate),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('serviceDate'),
            render: (text, record) => {
                return (
                  <>
                    {record.serviceDate ? moment(record.serviceDate).format('YYYY-MM-DD') : 'N/A'}
                  </>
                )
              }
        },
        {
            title: "Checklist",
            dataIndex: "checklist",
            align: "left",
            // width:80,

            // width:120,
            sorter: (a, b) => a.checklist?.localeCompare(b.checklist),
            sortDirections: ['ascend', 'descend',],
            ...getColumnSearchProps('checklist'),
        },
        
        {
            title: "Condition",
            dataIndex: "condition",
            align: "left",
            // width:80,

            // width:120,
            sorter: (a, b) => a.condition?.localeCompare(b.condition),
            sortDirections: ['ascend', 'descend',],
            ...getColumnSearchProps('condition'),
        },
        {
            title: "Reason",
            dataIndex: "reason",
            align: "left",
            // width:80,

            // width:120,
            sorter: (a, b) => a.reason?.localeCompare(b.reason),
            sortDirections: ['ascend', 'descend',],
            ...getColumnSearchProps('reason'),
        },
        {
            title: "Status",
            dataIndex: "checklistStatus",
            align: "left",
            sorter: (a, b) => a.checklistStatus?.localeCompare(b.checklistStatus),
            sortDirections: ['ascend', 'descend',],
            ...getColumnSearchProps('checklistStatus'),
        },
    ];


    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", pagination, filters, sorter, extra);
    };

    const exportedData = [];
    const excelData = transData
    let i = 1;
    const data = [
        { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
        { title: 'Unit', dataIndex: 'unit', render: (text, record) => { return record.unit ? record.unit : '-' } },
        { title: 'Location', dataIndex: 'locationName', render: (text, record) => { return record.locationName ? record.locationName : '-' } },
        { title: 'Asset Category', dataIndex: 'assetCategory', render: (text, record) => { return record.assetCategory ? record.assetCategory : '-' } },
        { title: 'Asset Code', dataIndex: 'assetCode', render: (text, record) => { return record.assetCode ? record.assetCode : '-' } },
        { title: 'Service Date', dataIndex: 'serviceDate', render: (text, record) => { return record.serviceDate ? moment(record.serviceDate).format('YYYY-MM-DD') : '-' } },
        { title: 'Checklist', dataIndex: 'checklist', render: (text, record) => { return record.checklist ? record.checklist : '-' } },

        { title: 'Condition', dataIndex: 'condition', render: (text, record) => { return record.condition ? record.condition : '-' } },
        { title: 'Reason', dataIndex: 'reason', render: (text, record) => { return record.reason ? record.reason : '-' } },
        { title: 'Status', dataIndex: 'checklistStatus', render: (text, record) => { return record.status ? record.status : '-' } },

    ];

    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('asset-checklist-report')
            .addColumns(data)
            .addDataSource(transData, { str2num: true })
            .saveAs('asset-checklist-report.xlsx');
    }

    return (
        <Card
            title={<span style={{ color: "white" }}>Checklist Report</span>}
            style={{ textAlign: "center" }}
            headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
            // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
            extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}>


            <br /><br />
            <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
                <Row gutter={[24, 24]}>

                    <Col span={3}>
                        <Form.Item label='Unit' name='plantId' >
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                                placeholder='All Units'
                                showSearch
                                allowClear>
                                    <Option key={'all'} value={''}>All Units</Option>
                                {plantName.map((plant) => {
                                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                        <Form.Item name="assetCode" label='Asset Code'>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder='Asset Code'
                                // onChange={onCodeChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {assetsData.map((assets) => {
                                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                                })

                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                        <Form.Item name="assetLocation" label='Asset Location'>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder='All Locations'
                                // onChange={onCodeChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={'all'} value={''}>All Locations</Option>
                                {locationData.map((assets) => {
                                    return <Option key={assets.locationId} value={assets.locationId}>{`${assets.locationName}`}</Option>
                                })

                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                        <Form.Item name="date" label='Date'>
                            <RangePicker
                            // defaultValue={[
                            //   moment(startDate,dateformat),
                            //   moment(new Date(),dateformat)
                            // ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col style={{ padding: '20px', marginTop: '30px' }}>
                        <Button type="primary" style={{ marginRight: '4px' }}
                        onClick={() => getChecklistReport()}
                        >
                            Get Report
                        </Button>
                        <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
                    </Col>

                    <Col span={3} style={{ marginLeft: '85%', paddingBottom: '1px' }}>
                        <Button >Total no of Records:{length}
                        </Button>
                    </Col>
                </Row>
            </Form>


            {transData.length > 0 ? <>
                <Table
                    columns={columns}
                    scroll={{ x: 1100 }}
                    dataSource={transData}
                    pagination={{
                        onChange(current, pageSize) {
                            setPage(current);
                            setPageSize(pageSize)
                        }
                    }}
                    onChange={onChange}
                    bordered
                />
            </> : <Alert
                message="Search For Data"
                type="info"
                showIcon
            />
            }
        </Card>
    );
}

export default ChecklistReport;

