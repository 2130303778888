import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ProductConversionDetailsDTO } from "./product-conversion-details.dto";

export class ProductConversionDetailsResponseModel extends GlobalResponseObject{
    data?: ProductConversionDetailsDTO[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ProductConversionDetailsDTO[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}