import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ContainerStatusInfo } from "./containers-status-info";
import {FillSealInfoDto} from './fill-seal-info.dto'
export class FillSealInfoResponse extends GlobalResponseObject{
    data?: FillSealInfoDto;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: FillSealInfoDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}