
import { Card, Button, Form, Descriptions, Row, Col, Input, Modal, Select } from 'antd';
import './stock-in-form.css'
import React, { useEffect, useState } from 'react';
import form from 'antd/lib/form';
import { ScanOutlined, SearchOutlined, PrinterOutlined, BarcodeOutlined } from '@ant-design/icons';
import { StockInPrinResponse, StockInPritnRequest, StockInService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';
const { Option } = Select;


export interface StockPlacementVerificationProps{ reportingLogId : number}
export function StockPlacementVerification(props:StockPlacementVerificationProps){

  const [form] = Form.useForm();
  const time = moment().format("DD-MM-YYYY HH:mm")
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [palletDropDown, setPalletDropDown] = useState<any[]>([]);

  const [modal, setModal] = useState('')
  const service = new StockInService;

  useEffect(() => {
    getPalletsCodes()
  }, []);
  const getPalletsCodes = () => {
    service.getPalletsVerificationDropDown({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
        if(res.status){
            setPalletDropDown(res.data);
            console.log(res.status)
        }else {
        if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
            AlertMessages.getErrorMessage(res.internalMessage);
        }
        }
    }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
        });
  }

    const saveData = (value) => {
        service.stockPlacementVerification({rackPostion:form.getFieldValue("location"),palletCode:form.getFieldValue("pallet")}).then((res) => 
        {
            if(res.status){
                console.log(res.status)
            }else {
            if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
        });
    }

  const getRackPositionsInfo = (value) => {
  }

  const handleQrScan = (value) => {
    setShowQrScan(false)
  }
    return(
        <Card title={<span style={{ color: 'white' }}> Stock In</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
            <Form  layout="vertical" form={form} name="control-hooks" onFinish={saveData} >
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            name="pallet"
                            label="Pallet Barcode"
                            rules={[
                            {
                                required:true,
                                message: 'Pallet Barcode must be required'
                            },
                            
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select or scan Pallet "
                                // onChange={getAllQcQcActiveTestsForOperation}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                suffixIcon={<ScanOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'2px',marginRight:'-6px'}}/>}
                            >
                                {palletDropDown.map(res => {
                                    return <Option value={res.palletCode}>{res.palletCode}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="location"
                            label="Location Barcode"
                            rules={[
                            {
                                required:true,
                                message: 'Location Barcode must be required'
                            },
                            
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select or scan Location "
                                // onChange={getAllQcQcActiveTestsForOperation}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                suffixIcon={<ScanOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'2px',marginRight:'-6px'}}/>}
                            >
                                {palletDropDown.map(res => {
                                    return <Option value={res.rackPosition}>{res.rackCode}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" htmlType="submit" block disabled={btnDisable} style={{marginTop:'28px'}}>
                        Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Modal
                className='qr-container'
                key={'modal' + Date.now()}
                width={'95%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={showQrSacn}
                onCancel={() => { setShowQrScan(false) }}
                title={<React.Fragment>
                </React.Fragment>}
                >
                {
                modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
                }

            </Modal>
        </Card>
    )
}
export default StockPlacementVerification