export class StockInVerfication {
    rackPostion:number;
    palletCode?:number;
   
    constructor(rackPostion:number,palletCode?:number){
        this.rackPostion= rackPostion,
        this.palletCode= palletCode
    }
}


