

export class AssetsSubCatagoryDto {
    assetsSubCatagoryId?: number;
    assetsCatagoryId: number;
    assetsCatagoryName: string;
    assetsSubCatagoryName: string;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag: number;

    /**
     *
     * @param assetsSubCatagoryId
     * @param assetsCatagoryId
     * @param assetsSubCatagoryName
     * @param createdUser
     * @param isActive
     */
    constructor(

        assetsCatagoryId: number,
        assetsCatagoryName: string,
        assetsSubCatagoryName: string,
        createdUser: string,
        updatedUser: string,
        isActive: boolean,
        versionFlag: number,
        assetsSubCatagoryId?: number,


    ) {
        this.assetsSubCatagoryId = assetsSubCatagoryId;
        this.assetsCatagoryId = assetsCatagoryId;
        this.assetsCatagoryName = assetsCatagoryName;
        this.assetsSubCatagoryName = assetsSubCatagoryName;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;

    }
}
