import axios from 'axios';
import connection from './connection';
import {PickUpDto, AllPickUpResponseModel, PickUpResponseModel} from '@gtpl/shared-models/hrms';

export class PickUpService{

    URL = connection.DEPLOY_URL + '/pickUp';

    async createPickUp(pickUpDto: PickUpDto ): Promise<PickUpResponseModel> {
        console.log(pickUpDto);
       
        return await axios.post(this.URL + '/createPickUp',pickUpDto)
            .then(res => {
                return res.data
            })
    }

    async getAllPickUp(): Promise<AllPickUpResponseModel> {
                
        return await axios.post(this.URL + '/getAllPickUp')
            .then(res => {
                return res.data 
            })     
    }

    async  updatePickUp(pickUpDto: PickUpDto): Promise<PickUpResponseModel> {
        return await axios.post(this.URL + '/updatePickUp', pickUpDto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivatePickUp(pickUpDto: PickUpDto): Promise<PickUpResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivatePickUp', pickUpDto)
                        .then(res => {
                                return res.data
                        })
                }
                
}