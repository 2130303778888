import { GlobalStatus,PackingStatus, PalletType, ProductCategoryEnum, StockStatus, StockTypeStatus, TransactionType } from "@gtpl/shared-models/common-models";
import { ItemsDetails, SaleOrderItemsInfo } from "@gtpl/shared-models/sale-management";
import { Shifts } from "./shifts.enum";

export class ReportingStockDto {
    // reportingLogId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    // reportedPerson: string;
    reportingCases: number;
    palletType: PalletType;
    palletId:number;
    rackPostion:number;
    loosePouches: number;
    lotCode: string;
    // transaction: string;
    transactionType: TransactionType;
    traceCode: number;
    // receivingPlant: number;
    coldStorageId: number;
    plantId: number;
    shifts: Shifts;
    packingCompleted: PackingStatus;
    stockType: StockStatus;
    // loactionId:number;
    reportingDate: Date;
    count: number;
    itemDetails: SaleOrderItemsInfo;
    createdUser: string;
    updatedUser: string;
    isUpdate: boolean;
    manualReported: boolean;
    cartonsStatus: PackingStatus;
    pouchesStatus: PackingStatus;
    productCategory: ProductCategoryEnum;
    jobId:number;
    isDamaged:PackingStatus;
    remarks:string;
    variantId?:number;
    brandId?:number;
    packStyleId?:number;
    storeConsumptionId?:number;
    palletCode?:string;
    warehouseIncharge?:number;



   
    constructor(
        // reportingLogId: number,
        
        saleOrderId: number,
        saleOrderItemId: number,
        // shifts: Shifts,
        // reportedPerson: string,
        reportingCases: number,
        // casesRequired:number,
        palletType: PalletType,
        palletId:number,
        rackPostion:number,
        loosePouches: number,
        lotCode: string,
        // transaction: string,
        transactionType: TransactionType,
        traceCode: number,
        // receivingPlant: number,
        coldStorageId: number,
        plantId:number,
        shifts: Shifts,
        packingCompleted: PackingStatus,
        stockType: StockStatus,
        // loactionId:number,
        reportingDate: Date,
        count: number,
        itemDetails: SaleOrderItemsInfo,
        isUpdate: boolean,
        manualReported:boolean,
        createdUser: string,
        updatedUser: string,
        cartonsStatus: PackingStatus,
        pouchesStatus: PackingStatus.NO,
        productCategory: ProductCategoryEnum,
        jobId:number,
        isDamaged:PackingStatus,
        remarks:string,
        variantId?:number,
        brandId?:number,
        packStyleId?:number,
        storeConsumptionId?:number,
        palletCode?:string,warehouseIncharge?:number
         ){
            
        this.saleOrderId= saleOrderId,
        this.saleOrderItemId= saleOrderItemId,
        // this.shifts= shifts,
        // this.reportedPerson= reportedPerson,
        this.reportingCases= reportingCases,
        // this.casesRequired= casesRequired
        this.palletType= palletType,
        this.palletId= palletId
        this.rackPostion= rackPostion
        this.loosePouches= loosePouches,
        this.lotCode= lotCode,
        // this.transaction= transaction,
        this.transactionType= transactionType,
        this.traceCode= traceCode,
        // this.receivingPlant= receivingPlant,
        this.coldStorageId= coldStorageId,
        this.plantId= plantId
        this.shifts= shifts,
        this.packingCompleted= packingCompleted,
        this.stockType= stockType,
        // this.loactionId= loactionId
        this.reportingDate= reportingDate,
        this.count= count,
        this.itemDetails= itemDetails,
        this.isUpdate= isUpdate,
        this.manualReported= manualReported
        this.createdUser= createdUser,
        this.updatedUser= updatedUser,
        this.cartonsStatus= cartonsStatus,
        this.pouchesStatus= pouchesStatus,
        this.productCategory= productCategory,
        this.jobId= jobId,
        this.isDamaged= isDamaged,
        this.remarks= remarks
        this.variantId = variantId
        this.brandId = brandId
        this.packStyleId = packStyleId
        this.storeConsumptionId = storeConsumptionId
        this.palletCode = palletCode
        this.warehouseIncharge = warehouseIncharge
    }
}


