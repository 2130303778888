import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {SaleOrderProcessDto} from './sale_order_process.dto'

export class SaleOrderProcessResponseModel extends GlobalResponseObject{
    data?: SaleOrderProcessDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SaleOrderProcessDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}