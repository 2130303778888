import { RackTypeEnum } from "@gtpl/shared-models/common-models";
import { RackLevelResponseDto } from "./rack-level-response.dto";
export class WarehouseDashboard {
    rackId: number;
    rackName: string;
    colCount: number;
    rackType: RackTypeEnum;
    rackPositions: RackLevelResponseDto[];
    expandStatus:boolean;
    plantId:number;
}