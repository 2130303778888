export class grnQtyDto{
    grnQty : number;
    
    /**
     * 
     * @param grnQty number
     */
    constructor(grnQty:number){
        this.grnQty = grnQty;
    }
}