import { UserRequestDto } from "@gtpl/shared-models/common-models";
import { TeamDto, TeamRequest, TeamsResponseModel } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class TeamsService{

    URL = connection.DEPLOY_URL + '/teams';

    async createTeam(data: TeamDto): Promise<TeamsResponseModel>{        
        return await axios.post(this.URL + '/createTeam', data)
            .then(res => {
                return res.data
            })
    }

    async updateTeam(data: TeamDto): Promise<TeamsResponseModel>{
        return await axios.post(this.URL + '/updateTeam', data)
            .then(res => {
                return res.data
            })
    }

    async getAllTeams(req?:UserRequestDto): Promise<TeamsResponseModel>{   
        return await axios.post(this.URL + '/getAllTeams',req)
           .then(res => {
               return res.data
           })
    }

    async getAllActiveTeams(): Promise<TeamsResponseModel>{
        return await axios.post(this.URL + '/getAllActiveTeams')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateTeams(data: TeamRequest): Promise<TeamsResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivateTeams', data)
            .then(res => {
                return res.data
            })
    }
}