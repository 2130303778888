import React, { useEffect, useState } from 'react';
import { Form, Select, Row, Col, DatePicker, Input, TimePicker, InputNumber, Radio, Button, Descriptions } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import {HarvestModel, IndentModel} from '@gtpl/shared-models/raw-material-procurement';
import './harvest-report.css';
import {EmployeeService, ProductService, SuppliersService, VehicleService} from '@gtpl/shared-services/masters';
import { EmployeeDto, EmployeeRoleRequest, FarmerModel, ProductDto, VehicleDto } from '@gtpl/shared-models/masters';
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';
import {IndentService} from '@gtpl/shared-services/raw-material-procurement';
const { Option } = Select;
const { TextArea } = Input;
/* eslint-disable-next-line */
export interface HarvestReportProps {
  indentRecord: any,
  closeModal: (harvestData:HarvestModel) => void;
}

export function HarvestReport(
  props: HarvestReportProps
) {
  const userName = localStorage.getItem("createdUser");
  const plantId = localStorage.getItem("unit_id");
  const [farmerData, setFarmerData] = useState<string>('');
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [vehicles, setVehicles] = useState<VehicleDto[]>([]);
  const [drivers, setDrivers] = useState<EmployeeDto[]>([]);
  const [cleanBoxes, setCleanBoxes] = useState<number>(1);
  const [hygineHandle, setHygineHandle] = useState<number>(1);
  const [healthyShirmps, setHealthyShirmps] = useState<number>(1);
  const [chillKilled, setChillKilled] = useState<number>(1);
  const [properIcing, setProperIcing] = useState<number>(1);
  const [harvestTime, setHarvestTime] = useState<string>('');
  const [vlTime, setVlTime] = useState<string>('');
  const [form] = Form.useForm();
  const supplierService = new SuppliersService();
  const productService = new ProductService();
  const employeeService = new EmployeeService();
  const vehicleService = new VehicleService();
  const indentService = new IndentService();

  useEffect(() => {
    getDriverEmployees();
    getVehicleNos();
    getAllProducts();
    getFarmerCode();
  },[]);

  const getFarmerCode = () => {
    supplierService.getSupplierBySupplierId(new FarmerModel(props.indentRecord.farmer)).then(res => {
      if (res.status) {
        form.setFieldsValue({farmerData:res.data.supplierCode+'/'+res.data.supplierName});
      } else {
        if (res.intlCode) {
          form.setFieldsValue({farmerData:''});
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getAllProducts = () => {
    productService.getAllProducts().then(res => {
      if (res.status) {
        setProducts(res.data);
      } else {
        if (res.intlCode) {
          setProducts([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProducts([]);
    });
  }

  const getVehicleNos = () => {
    vehicleService.getAllActiveVehicles().then(res => {
      if (res.status) {
        setVehicles(res.data);
      } else {
        if (res.intlCode) {
          setVehicles([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setVehicles([]);
    });
  }

  const getDriverEmployees = () => {
    employeeService.getActiveEmployeesByRole(new EmployeeRoleRequest(EmployeeRolesEnum.DRIVER)).then(res => {
      if (res.status) {
        setDrivers(res.data);
      } else {
        if (res.intlCode) {
          setDrivers([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDrivers([]);
    });
  }

  const onChangeHarvestTime = (time,timeString) => {
    setHarvestTime(timeString);
  }

  const onChangeVLTime = (time,timeString) => {
    setVlTime(timeString);
  }
  
  const cleanBoxesChange = (e) => {
    setCleanBoxes(e.target.value);
  }
  const hygineHandleChange = (e) => {
    setHygineHandle(e.target.value);
  }
  const healthyShirmpsChange = (e) => {
    setHealthyShirmps(e.target.value);
  }
  const chillKilledChange = (e) => {
    setChillKilled(e.target.value);
  }
  const properIcingChange = (e) => {
    setProperIcing(e.target.value);
  }
  const saveHarvestForm = () => {
    form.validateFields().then(values => {
      const productName = products.find(product => product.productId===values.product).productName;
      const harvestModel = new HarvestModel(Number(plantId),values.harvestDate,values.farmerData,harvestTime,productName,values.quantity,values.temperature1,values.temperature2,values.temperature3,values.temperature4,values.temperature5,values.cleanBoxes,values.hygineHandle,values.healthyShirmps,values.chillKilled,values.properIcing,values.vehicleNo,values.driverName,vlTime,props.indentRecord.indentId,userName,values.remarks,values.sealNo, props.indentRecord.indentItemId);
      indentService.createHarvestReport(harvestModel).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Harvest Report Created Successfully');
          props.closeModal(harvestModel);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      });   
    })
  }
  const onReset = () => {
    form.resetFields();
    setFarmerData('');
    setHarvestTime('');
    setVlTime('');
    setCleanBoxes(1);
    setHygineHandle(1);
    setHealthyShirmps(1);
    setChillKilled(1);
    setProperIcing(1);
  }

  
  return (
    <>
    <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        initialValues = {props.indentRecord}
        onFinish={saveHarvestForm}
        autoComplete={"off"}
      >  

        <Row gutter={24}>
          <Descriptions>
            <Descriptions.Item label=" Harvest Date">
            {(props.indentRecord) ? moment(props.indentRecord?.harvestingDate).format("YYYY-MM-DD") : ''}
            {/* {moment(props.indentRecord.harvestingDate)} */}
            </Descriptions.Item>
            <Descriptions.Item label="Farmer ID">
            {props.indentRecord.farmerName}
            </Descriptions.Item>
            <Descriptions.Item label="Product">
             {props.indentRecord.productName}
            </Descriptions.Item>
            <Descriptions.Item label="Quantity (In KGs)">
              {props.indentRecord.expectedQty}
            </Descriptions.Item>
            
          </Descriptions>
        </Row>
       
          
            <Form.Item name="harvestDate" initialValue={moment(props.indentRecord.harvestingDate)}>
                <Input style={{ width: '100%' }}  hidden/>
            </Form.Item>
          
          
            <Form.Item name="farmerData" >
                <Input style={{ width: '100%' }} defaultValue={farmerData} hidden/>
            </Form.Item>
          
         
          
            <Form.Item name="product" hidden>
              <Select
                showSearch
                placeholder="Select Product"
                allowClear = {true}
                defaultValue = {props.indentRecord.product}
                key={Date.now()}   
                         
              >
                <Option value={null}>Select Product</Option>
                {products.map((product)=>{
                  return <Option value={product.productId}>{product.productName}</Option>
                })}
              </Select>
            </Form.Item>
          
          
            <Form.Item name="quantity" initialValue={props.indentRecord.expectedQty} hidden>
                <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          
          <Row gutter={24}>
          <Col span={4}>
            <Form.Item name="harvestTime" label="Harvest Time" rules={[{ required: true,message:'Harvest Time is required' }]}>
              <TimePicker use12Hours style={{ width: '100%' }} format="h:mm a" onChange={onChangeHarvestTime} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="sealNo" label="Seal No" rules={[{ required: true,message:'Seal No is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="temperature1" label="Temperature 1" rules={[{ required: true,message:'Temperature 1 is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
         
          <Col span={4}>
            <Form.Item name="temperature2" label="Temperature 2" rules={[{ required: true,message:'Temperature 2 is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="temperature3" label="Temperature 3" rules={[{ required: true,message:'Temperature 3 is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="temperature4" label="Temperature 4" rules={[{ required: true,message:'Temperature 4 is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="temperature5" label="Temperature 5" rules={[{ required: true,message:'Temperature 5 is required' }]}>
                <Input style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="cleanBoxes" label="Whether boxes are clean?" rules={[{ required: true }]} initialValue={cleanBoxes}>
              <Radio.Group onChange={cleanBoxesChange} value={cleanBoxes}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="hygineHandle" label="Whether the handling is hygine?" rules={[{ required: true }]} initialValue={hygineHandle}>
              <Radio.Group onChange={hygineHandleChange} value={hygineHandle}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="healthyShirmps" label="Whether shrimps are healthy?" rules={[{ required: true }]} initialValue={healthyShirmps}>
              <Radio.Group onChange={healthyShirmpsChange} value={healthyShirmps}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="chillKilled" label="Whether shrimps are chill killed?" rules={[{ required: true }]} initialValue={chillKilled}>
              <Radio.Group onChange={chillKilledChange} value={chillKilled}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="properIcing" label="Whether Icing is done properly?" rules={[{ required: true }]} initialValue={properIcing}>
              <Radio.Group onChange={properIcingChange} value={properIcing}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="vehicleNo" label="Vehicel No" rules={[{ required: true,message:'Vehicel No is required' }]}>
              <Select
                showSearch
                placeholder="Select Vehicle"
                allowClear = {true}             
              >
                <Option value={null}>Select Vehicle</Option>
                {vehicles.map((vehicle)=>{
                  return <Option value={vehicle.registrationNumber}>{vehicle.registrationNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="driverName" label="Driver Name" rules={[{ required: true,message:'Driver Name is required' }]}>
              <Select
                showSearch
                placeholder="Select Driver"
                allowClear = {true}            
              >
                <Option value={null}>Select Driver</Option>
                {drivers.map((driver)=>{
                  return <Option value={driver.employeeName}>{driver.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="vehicleLeavingTime" label="Vehicle Leaving Time" rules={[{ required: true,message:'Vehicle Leaving Time is required' }]}>
              <TimePicker use12Hours style={{ width: '100%' }} format="h:mm a" onChange={onChangeVLTime} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="remarks" label="Remarks" rules={[{ required: true,message:'Remarks is required' }]}>
                <TextArea style={{ width: '100%' }} rows={4}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default HarvestReport;
