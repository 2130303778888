import { ItemCategoryDropDownDto, ItemsDropDownDto, ItemSubCategoryDropDownDto } from "@gtpl/shared-models/masters";

export class PackingSTItems {
    packingStockTransferItemId?: number;
    itemCategoryId: number;
    itemCategory?: string;
    itemSubCategoryId: number;
    itemSubCategory?: string;
    itemId: number;
    item?: string;
    size?: string;
    sizeId: number;
    transferredQty: number;
    issuedQty: number;
    availableQty: number;
    requiredQty?: number;
    unitPrice: number;
    locationStockInfo?: any[];

    /**
     * 
     * @param packingStockTransferItemId 
     * @param itemCategoryId 
     * @param itemSubCategoryId 
     * @param itemId 
     * @param transferredQty 
     */
    constructor(itemCategoryId: number, itemSubCategoryId: number, itemId: number, transferredQty: number, itemCategory?: string, itemSubCategory?: string, item?: string, size?: string, packingStockTransferItemId?: number, unitPrice?: number, requiredQty?: number,locationStockInfo?: any[]) {
        this.packingStockTransferItemId = packingStockTransferItemId;
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemId = itemId;
        this.transferredQty = transferredQty;
        this.itemCategory = itemCategory;
        this.itemSubCategory = itemSubCategory;
        this.item = item;
        this.size = size;
        this.unitPrice = unitPrice;
        this.requiredQty = requiredQty;
        this.locationStockInfo = locationStockInfo;

    }
}