import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, DatePicker, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'
import { EndCustomersService, UnitcodeService } from '@gtpl/shared-services/masters';

import './transferred-saleorders-report.css';
import { TransferredSosFilterDto } from '@gtpl/shared-models/sale-management';

/* eslint-disable-next-line */
export interface TransferredSaleordersReportProps {}

export function TransferredSaleordersReport(
  props: TransferredSaleordersReportProps
) {
  const [form] = Form.useForm();;
  const {Option} = Select
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [unitData, setunitData] = useState<any[]>([]);
  const unitsService = new UnitcodeService();
  const service = new SaleOrderService();
  const [reportData, setReportData] = useState<any[]>([]);
  const unitId = Number(localStorage.getItem('unit_id'))
  const [disable, setDisable] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false)
  const [endCustomerDropdown,setEndCustomerDropdown] = useState<any>([])
  const endCustService = new EndCustomersService



  /**
   * used for column filter
   * @param dataIndex column data index
   */ 

  useEffect(() => {
    getReportData();
    getAllPlants();
    getEndCustomerDropdown()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
    }
  },[])

  const getReportData = () => {
    setDisable(true);setEmpty(false);
    const req = new TransferredSosFilterDto()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.bapUnit = Number(localStorage.getItem('unit_id'));
    } else {
      req.bapUnit = form.getFieldValue('bapUnit');
    }
  console.log(form.getFieldValue('createdAt'))
  if (form.getFieldValue('createdAt') != undefined) {
      const selectedFromDate = moment(form.getFieldValue('createdAt')[0]).format('YYYY-MM-DD');
      const selectedToDate = moment(form.getFieldValue('createdAt')[1]).format('YYYY-MM-DD');
      req.createdFromDate = (selectedFromDate)
      req.createdFromDate = (selectedToDate)
  }
  if (form.getFieldValue('endCustomer')){ req.endCustomer =  form.getFieldValue('endCustomer')}
    service.getTransferredSosReport(req).then((res) => {
      setDisable(false)
      if(res.status){
        setReportData(res.data)
        AlertMessages.getSuccessMessage('Data retreived sucessfully')
      }else{
        setReportData([])
      }
    }).catch(error => {
      setDisable(false);
      AlertMessages.getErrorMessage(error)
    })
  }

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
        if (res.status) {
            if (unitId == 5) {
                setunitData(res.data)
            } else {
                setunitData(res.data.filter(i => i.plantId == unitId))
            }
        } else {
            setunitData([]);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setunitData([]);
    })
}

const getEndCustomerDropdown = () => {
  endCustService.getAllActiveEndCustomers().then((res) => {
    if(res.status){
      setEndCustomerDropdown(res.data)
    }else{
      setEndCustomerDropdown([])
    }
  }).catch((err) => {
    setEndCustomerDropdown([])
  })
}

const onReset = () => {
  form.resetFields();
  if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
  }
  setEmpty(true)
};


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
    sorter: (a, b) => a.poNumber - b.poNumber,
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('poNumber')
    },
    {
      title:'Customer',
      dataIndex:'customer',
      sorter: (a, b) => a.customer - b.customer,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('customer')

    },
    {
      title: 'Buyer PO Number',
      dataIndex: 'buyerPoNumber',
    sorter: (a, b) => a.buyerPoNumber - b.buyerPoNumber,
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('buyerPoNumber')
    },
    {
      title: 'From Mfg Unit',
      dataIndex: 'fromMfgUnit',
      // responsive: ['lg'],
      sorter: (a, b) => a.fromMfgUnit.trim().localeCompare(b.fromMfgUnit.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromMfgUnit')
    },
    {
      title: 'To Mfg Unit',
      dataIndex: 'toMfgUnit',
      sorter: (a, b) => a.toMfgUnit.trim().localeCompare(b.toMfgUnit.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toMfgUnit')
    },
    {
      title: 'From Processing Unit',
      dataIndex: 'fromProcessingUnitName',
      // responsive: ['lg'],
      sorter: (a, b) => a.fromProcessingUnitName.trim().localeCompare(b.fromProcessingUnitName.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromProcessingUnitName')
    },
   
    {
      title: 'To Processing Unit',
      dataIndex: 'toProcessingUnitName',
      sorter: (a, b) => a.toProcessingUnitName.trim().localeCompare(b.toProcessingUnitName.trim()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('toProcessingUnitName')
    },
   
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Transferred Sale Orders</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
    <br></br>
    <Card >
    <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                <Form.Item
                    style={{ display: "none" }}
                    name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                </Form.Item>
                <Row gutter={[24, 24]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="createdAt" label="Date" rules={[{
                            required: false,
                            message: "Date",

                        },
                        ]}>
                            <RangePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="bapUnit"
                            label="Bap Unit"
                            rules={[
                                {
                                    required: false, message: 'Select Unit',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Unit"
                                allowClear
                                style={{ width: '100%' }}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

                            >
                                {unitData.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                            name="endCustomer"
                            label="End Customer"
                            rules={[
                                {
                                    required: false, message: 'Select EndCustomer',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select EndCustomer"
                                allowClear
                                style={{ width: '100%' }}

                            >
                                {endCustomerDropdown?.map(dropData => {
                                    return <Option value={dropData.endCustomerId}>{dropData.endCustomerName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col style={{ paddingTop: '30px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Button
                            type="primary"
                            onClick={() => getReportData()}
                            style={{ width: 100 }}
                            disabled={disable}
                        >
                            Get Report
                        </Button>
                    </Col>
                    <Col style={{ paddingTop: '30px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Button type="primary" htmlType="submit" onClick={onReset} style={{ marginLeft: 2 }} >
                            Reset
                        </Button>
                    </Col>


                </Row>
            </Form>

      <Table
        rowKey={record => record.downtimeTrackingId}
        columns={columnsSkelton}
        dataSource={reportData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        size='small'
        bordered />
    </Card>
   
  </Card>
  );
}

export default TransferredSaleordersReport;
