import axios from 'axios';
import connection from './connection';
import {GrnRejectionReasonResponse,RejectionReasonDto,AllRejectionReasonsResponse} from '@gtpl/shared-models/masters'
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class GrnRejectionReasonService{
    URL = connection.DEPLOY_URL + '/grn-rejection-reasons';


    async create(dto: RejectionReasonDto): Promise<GrnRejectionReasonResponse> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createGrnRejectionReason',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: RejectionReasonDto): Promise<GrnRejectionReasonResponse> {
    return await axios.post(this.URL + '/updateGrnRejectionReason', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: RejectionReasonDto): Promise<GrnRejectionReasonResponse> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivateGrnRejectionReason', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(req?:UserRequestDto): Promise<AllRejectionReasonsResponse> {
                
                return await axios.post(this.URL + '/getAllGrnRejectionReasons',req)
                    .then(res => {
                        return res.data 
                    })     
            }

}