import React, { useEffect, useState } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, Modal, DatePicker, InputNumber, Radio, Table, Tooltip, Divider, Popconfirm, FormInstance } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './qc-defect-details-form.css';
import { OperationIdRequest, operationRequest, OperationsDropDown, QaQcData, QaQcDropDown, Qcdefectdto, QcDefectItemDetailsDto, WorkstationDTO } from '@gtpl/shared-models/production-management';
import { from } from 'rxjs';
import { QrcodeOutlined, UserOutlined, ScanOutlined, EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { QcDefectService } from '@gtpl/shared-services/production';
import { ColumnProps } from 'antd/lib/table';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';


const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export interface QcDefectItemsFormProps{
// itemdata:QcDefectItemDetailsDto
itemdetailsform: FormInstance<any>
addDataToItemDetails: (itemDetalFormdata: QcDefectItemDetailsDto[]) => void;

}
export function QcdefectItemForm(props:QcDefectItemsFormProps){
  const [form] = Form.useForm();
  const [itemdetailsform] = Form.useForm();

  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [testsData, setTestsData] = useState<QaQcDropDown[]>([]);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const [testScandat, settestScandat] = useState<any[]>([]);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [indexVal, setIndexVal] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [fieldsData, setFieldsData] = useState<any>(undefined);
  const [itemType,setItemType] = useState<any>('')

  


  const qcDefectiveService = new QcDefectService;

  const gettestresultname = (value) => {
    setShowQrScan(true);
    setModal('testResultId')
    gettestResultforoperation(value);
  }

  const gettestResultforoperation = (value) => {
    if (value != undefined) {
      qcDefectiveService.getTestnameforOperationForScan({ testCode: value }).then(res => {
        if (res.data) {
          if (res.data.length > 0) {
            form.setFieldsValue({
              testResultId: res.data[0].testName,
            })

            settestScandat(res.data)

          } else {
            form.resetFields(['testResultId'])
            AlertMessages.getErrorMessage('No testresult Found for scanned  code')
          }
        } else {
          form.resetFields(['testResultId'])
          AlertMessages.getErrorMessage('No testresult Found for scanned code')

        }
      }).catch(err => {
        // AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage("Scann Valid QR Code");

    }
  }
  const onReset = () => {
    setBtnChange(false);
    itemdetailsform.resetFields();
  };
  const handletestresult = (value) => {
    setShowQrScan(false)
    itemdetailsform.setFieldsValue({ testResultId: value, })
    setShowQrScan(false)
  }
  const deleteData = (index) => {
    console.log(index)
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }
  const setEditForm = (rowData, index) => {
    setIndexVal(index);
    setBtnChange(true);
    console.log(tableData)
    itemdetailsform.setFieldsValue({
      qcdefectitemId: !isUpdate ? rowData.qcdefectitemId : Number(rowData.qcdefectitemId),
      testResultId: !isUpdate ? rowData.testResultId : rowData.testResultId,
      rejectedQuantity: !isUpdate ? rowData.rejectedQuantity : rowData.rejectedQuantity,
      isUpdate: true
    });
    setFieldsData(rowData);
  }
  const saveDefectItems = (values: any) => {
    setFieldsData(values);
    itemdetailsform.validateFields().then(res => {
      let tableName = tableData;
      let duplicateData;
      if (!btnChange) {
        duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key));
      }
      else {
        duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key && item.quantity == fieldsData.quantity));
      }

      if (duplicateData) {
        AlertMessages.getErrorMessage('Item already exist in. ');
        onReset();
      } else {
        // console.log('btnChangetest');

        if (!btnChange) {
            itemdetailsform.validateFields().then(res => {
            setShowTable(true);
            fieldsData.availableStock = values.availableQty;
            let dynTableData = [...tableData, fieldsData]
            setTableData(dynTableData);
            props.addDataToItemDetails(dynTableData);
          })
        } else {
         
          let dynTableData = [...tableData]

          // dynTableData[indexVal].itemCategory=fieldsData.itemCategory
          dynTableData[indexVal].restResultId = fieldsData.testResultId
          dynTableData[indexVal].rejectedQuantity = fieldsData.rejectedQuantity
          // let dynTableData = [...tableData]
          // dynTableData[indexVal] = fieldsData
          // console.log(dynTableData);
          setShowTable(true);
          setTableData(dynTableData);
          props.addDataToItemDetails(dynTableData);

        }
        onReset();
      }
    })
  };
//   const appendData = (val, row) => {
  
//    const { label } = row;
//     setFieldsData({ ...fieldsData, [label]: row })
 
//    if (row.label === "testResultId") {
//         getAvailableStock(val, props.saleOrderId);
//         getConsumedStock(val, props.saleOrderId);
//       }
    
//   else{
//     if (row.label === "itemId") {
//       getAvailableStock(val,0);
//       getConsumedStock(val, 0);
//     }
//   }
//   }

  const columns: ColumnProps<any>[] = [
    {
      title: 'Defect',
      dataIndex: 'testResultId',
      key: 'testResultId',
      align: 'left',
      render: (text, data, index) => {
        // console.log(text, data, index)
        return <span>{data.testResultId.children}</span>
      }
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectedQuantity',
      key: 'quarejectedQuantityntity',
      align: 'left',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData) {
                  console.log(rowData);
                  setEditForm(rowData, index);
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title='dlete'>
          <Popconfirm title='Sure to delete?' onConfirm={e =>{deleteData(index);}}>
            <MinusCircleOutlined 
              style={{ color: '#1890ff', fontSize: '14px' }} />
            </Popconfirm>

          </Tooltip>
        </span>)
    }
  ]
return(
    <Card title={<span style={{ color: 'white' }}>Defect Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout="vertical" form={itemdetailsform} name="control-hooks" 
      onFinish={saveDefectItems}
      >
   <Row gutter={24}>
        <Col span={8}>
                  <Form.Item name="testResultId" label="Defect"
                    //   rules={[{
                    //     // required: form.getFieldValue('testResult') == 'Fail' || form.getFieldValue('testResult') == 'Pass With Defect' ? true : false,

                    //     message: 'Missing Test'
                    //   }]}
                    >
                      <Select
                        placeholder="Select Defect"
                        allowClear
                        // onChange={appendData}
                        suffixIcon={<ScanOutlined onClick={(e) => { gettestresultname(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px' }} />}
                      >
                        <Option key={0} value={null}>Select Defect</Option>
                        {testsData.map((test) => {
                          return <Option key={test.qaQcTestId} value={test.qaQcTestId}>{test.testName}</Option>
                        })}
                      </Select>
                    </Form.Item>
               </Col>
               <Col span={8}>
               <Form.Item name="rejectedQuantity" label="Rejected Quantity"
                      rules={[{
                        required: form.getFieldValue('testResult') == 'Fail' || form.getFieldValue('testResult') == 'Pass With Defect' ? true : false,
                        message: 'Missing Rejection Quantity'
                      }]}
                    >
                      <InputNumber style={{ width: '100%' }} min={0}
                    //    onChange={e => appendData(e,'rejectedQuantity')} 
                       />
                    </Form.Item>

               </Col>
               <Col span={2}>
            <Form.Item style={{padding:'3em'}}>

               <Button style={{ backgroundColor: "#67b32d", color: "white" }} type="primary" htmlType="submit" >
                {btnChange ? 'UPDATE' : 'ADD'}
              </Button>
              </Form.Item>
              </Col>
   </Row>
   <br />
        {showTable ?
          <Table key={Date.now()} scroll={{ x: 500 }} dataSource={tableData}
            columns={columns} pagination={false} /> :
          <></>
        }
    </Form>
    <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
        modal === 'testResultId' ? <QrScanner handleScan={handletestresult} /> : null
          }

        </Modal>
    </Card>
    
)
}
export default  QcdefectItemForm;
