import axios from 'axios';
import {CustomerDto,AllCustomersResponseModel,CustomersResponseModel, CustomersDropDownDataResponseModel, CustomerRequest} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class CustomersService {
  activatedeActivate(customersData: CustomerDto) {
    throw new Error('Method not implemented.');
  }
  URL = connection.DEPLOY_URL + '/customers';

  async createCustomers(customers:CustomerDto): Promise<CustomersResponseModel> {
    console.log(customers);
    return await axios.post(this.URL + '/createCustomers', customers)
      .then(res => {
        return res.data
      })
  }
  async  updateCustomers(customers: CustomerDto): Promise<CustomersResponseModel> {
    return await axios.post(this.URL + '/updateCustomer', customers)
      .then(res => {
        return res.data
      })
  }
  async getAllCustomersData(): Promise<AllCustomersResponseModel> {
    return await axios.post(this.URL + '/getAllCustomersData')
      .then(res => {
        return res.data
      })
  }
  async getAllCustomers(req?:UserRequestDto): Promise<AllCustomersResponseModel> {
    return await axios.post(this.URL + '/getAllCustomers',req)
      .then(res => {
        return res.data
      })
  }
  async ActivateorDeactivateCustomers(customersDto: CustomerDto): Promise<AllCustomersResponseModel> {
    console.log(customersDto);
    return await axios.post(this.URL + '/activateorDeactivatecustomer', customersDto)
      .then(res => {
        return res.data
      })
  }
  async getAllActiveCustomers(): Promise<AllCustomersResponseModel> {
    return await axios.post(this.URL + '/getAllActiveCustomers')
      .then(res => {
        return res.data
      })
  }
  async getActiveCustomersCount(): Promise<CustomersDropDownDataResponseModel> {
    return await axios.post(this.URL + '/getActiveCustomersCount').then(res => {
      return res.data
  });
}
async getCustomerDataById(customerRequest:CustomerRequest): Promise<CustomersResponseModel> {
  return await axios.post(this.URL + '/getCustomerDataById', customerRequest).then(res => {
    // console.log(res);
      return res.data
  });
}


  async getActiveCustomersDropDownData() {
    return await axios.post(this.URL + '/getActiveCustomersDropDownData').then(res => {
      return res.data
    });
  }

}
