import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {HrmsEmployeeDetailsDto} from './hrms_emp_det.dto'

export class HrmsAllResponseModel extends GlobalResponseObject{
    data?: HrmsEmployeeDetailsDto[];
    /**
     *
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: HrmsEmployeeDetailsDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}