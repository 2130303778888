import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card,Modal, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography } from 'antd';
import './beheading-dashboard.css';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OperationTypeEnum, Series, UnitRequest } from '@gtpl/shared-models/common-models';
import {Link,Route, useHistory } from "react-router-dom";
import { InProgressBeHeadingDto, LotNumberRequest, OperationRequest, InventoryBeHeadingDashboardDto } from '@gtpl/shared-models/production-management';
import { BeheadingService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' ;
/* eslint-disable-next-line */
export interface BeheadingDashboardProps { }

export function BeheadingDashboard(
  props: BeheadingDashboardProps
) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [beheadingInprogressData, setBeheadingInprogressData] = useState<InProgressBeHeadingDto[]>([]);
  const [beheadingInventoryData, setBeheadingInventoryData] = useState<InventoryBeHeadingDashboardDto[]>([]);
  const [beheadingSeries, setBeheadingSeries] = useState<Series[]>([]);
  const [xseries, setXseries] = useState<any[]>([]);
  const [noOfBoxes, setNoOfBoxes] = useState<number>(0);
  const [qtyToBeProcessed, setQtyToBeProcessed] = useState<number>(0);
  const [inventoryQty, setInventoryQty] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saleId, setSaleId] = useState(0);
  const beheadingService = new BeheadingService();
  const inventoryService = new ProductionInventoryService();

  useEffect(() => {
    getWorkstationWiseInprogressData();
    getBeHeadingWorkStationWiseInfo();
    getAllBeheadingPlantJobs();
    getNoOfAvailableBoxes();
    getQtyToBeProcessed();
    getInventoryQty();
  }, [])

  const getNoOfAvailableBoxes = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getNoOfAvailableBoxes(lotNoReq).then((res) => {
      if (res.status) {
        setNoOfBoxes(res.data);
      } else {
        setNoOfBoxes(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setNoOfBoxes(0);
    });
  }
  console.log(noOfBoxes)

  const getQtyToBeProcessed = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getQtyToBeProcessed({plant:plant,operation:OperationTypeEnum.BE_HEADING}).then((res) => {
      if (res.status) {
        console.log(res)
        setQtyToBeProcessed(res.data);
      } else {
        setQtyToBeProcessed(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setQtyToBeProcessed(0);
    });
  }
  console.log(qtyToBeProcessed)

  const getInventoryQty = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getInventoryQty(lotNoReq).then((res) => {
      if (res.status) {
        setInventoryQty(res.data);
      } else {
        setInventoryQty(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setInventoryQty(0);
    });
  }

  console.log(inventoryQty)



  const getBeHeadingWorkStationWiseInfo = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    beheadingService.getAllBeheadingInfo(req).then((res) => {
      if (res.status) {
        setBeheadingSeries(res.data.data);
        setXseries(res.data.xaxis);
      } else {
        setBeheadingSeries([]);
        setXseries([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setBeheadingSeries([]);
      setXseries([]);
    });
  }



  const getWorkstationWiseInprogressData = () => {
    const catReq = new OperationRequest(OperationTypeEnum.BE_HEADING, Number(localStorage.getItem('unit_id')));
    beheadingService.getWorkstationWiseInprogressData(catReq).then((res) => {
      if (res.status) {
        setBeheadingInprogressData(res.data);
      } else {
        setBeheadingInprogressData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setBeheadingInprogressData([]);
    });
  }
  const getAllBeheadingPlantJobs = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    beheadingService.getAllBeheadingPlantJobs(req).then((res) => {
      if (res.status) {
        setBeheadingInventoryData(res.data);
      } else {
        setBeheadingInventoryData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setBeheadingInventoryData([]);
    });
  }
  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const setData = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.productionLogId)
    console.log(object);
  }


  /**
* used for column filter
* @param dataIndex column data index
*/
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Table',
      dataIndex: 'workstation',
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotandplantlot',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Assigned Time',
      dataIndex: 'assignedTime',
      render: (value) => {
        return <div style={{ textAlign: 'right' }}><Tag style={{ textAlign: 'right', backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{moment(value).format('hh:mm A')}</Tag></div>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
  ];
  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'Table',
      dataIndex: 'workstation',
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    {
      title: 'LOT Code',
      dataIndex: 'lotandplantlot',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotandplantlot.localeCompare(b.lotandplantlot),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotandplantlot')
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrder',
      // responsive: ['lg'],
      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder'),
      render: (value, record) => 
     <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.saleOrder}</Text>
    
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
     <Text  onClick={e => goToSaleDetailView(record.poNumber)} >{record.poNumber}</Text>
    
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'I/P QTY',
      dataIndex: 'quantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'O/P Qty',
      dataIndex: 'physicalQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Wastage Quantity',
      dataIndex: 'wastageQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Job Completed',
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData) => (
        <div style={{ textAlign: 'center' }}>
          {jobStatus === "YES" ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Yes</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">No</Tag>}

        </div>
      ),
      filters: [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.jobStatus === value;
      },

    },
    {
      title: 'Assigned Time',
      dataIndex: 'assignedTime',
      // sorter: (a, b) => a.assignedTime.localeCompare(b.assignedTime),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedTime'),
      render: (value) => {
        return <div style={{ textAlign: 'right' }}><Tag style={{ textAlign: 'right', backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{moment(value).format('hh:mm A')}</Tag></div>
      }
    },
  ];


  const constructChartData = () => {

    const options = {
      chart: {
        type: 'column'
      },
      colors: ['#ff8c00', '#1d99db'],
      title: {
        text: 'Table Wise Inventory- ' + moment().format('DD-MM-YYYY - hh:mm A')
      },
      xAxis: {
        categories: xseries
      },
      yAxis: {
        min: 0,
        title: {
          text: 'In-progress vs completed in tons'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        },
        bar: {
          pointPadding: 100,
          borderWidth: 100
        },
        series: {
          pointWidth: 25
        }
      },
      series: beheadingSeries
    };
    return options;
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Card title={<span style={{ color: 'white' }}>Deheading Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'No. of Lots Available: ' + Number(noOfBoxes[0]?.noOfRecords).toLocaleString('en-IN')
          }
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Quantity To be Processed :' + Number(Math.round(qtyToBeProcessed[0]?.qty)).toLocaleString('en-IN')}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Inventory: ' + Number(Math.round(inventoryQty[0]?.qty)).toLocaleString('en-IN')}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to='/be-heading'><Button danger type="primary" htmlType="submit">
          Assign Material
        </Button></Link>
      </Row>
      <br />
      <br />
      <Row gutter={24}>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card>
            <HighchartsReact
              highcharts={Highcharts}
              options={constructChartData()}
            />
          </Card>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card title={<span style={{ color: 'white' }}>Table Wise In-progress Quantity in kg s</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Table
              rowKey={record => record.logId}
              columns={columnsSkelton}
              dataSource={beheadingInprogressData}
              scroll={{ x: true }}
              bordered
              pagination={false}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>{'Total'}</Table.Summary.Cell>

                    <Table.Summary.Cell index={3}>{beheadingInprogressData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.quantity);
                    }, 0)}</Table.Summary.Cell>




                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Card>

        </Col>
      </Row>
      <br />
      <Row>
        <Col style={{ width: '100%' }}>
          <Card title={<span style={{ color: 'white', paddingTop: "3%" }}>Inventory in kg s </span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Table
              rowKey={record => record.productionLogId}
              columns={columnsSkelton2}
              dataSource={beheadingInventoryData}
              scroll={{ x: true }}
              bordered
              pagination={false}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>{'Total'}</Table.Summary.Cell>

                    <Table.Summary.Cell index={4}>{beheadingInventoryData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.boxes);
                    }, 0)}</Table.Summary.Cell>

                    <Table.Summary.Cell index={5}>{beheadingInventoryData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.opBoxes);
                    }, 0)}</Table.Summary.Cell>

                    <Table.Summary.Cell index={6}>{beheadingInventoryData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.quantity);
                    }, 0)}</Table.Summary.Cell>


                    <Table.Summary.Cell index={7}>{beheadingInventoryData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.physicalQuantity);
                    }, 0)}</Table.Summary.Cell>

                    <Table.Summary.Cell index={8}>{beheadingInventoryData.reduce(function (previousValue, currentValue) {
                      return previousValue + Number(currentValue.wastageQuantity);
                    }, 0)}</Table.Summary.Cell>

                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    <Table.Summary.Cell index={10}></Table.Summary.Cell>



                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
         <SoInfoViewPages saleOrderId={saleId} screen='production'
          />
        {/* <StockTransfer operation={OperationTypeEnum.BE_HEADING} inventoryInfo={selectedInformation}/> */}
      </Modal>
    </Card>
  );
}

export default BeheadingDashboard;
