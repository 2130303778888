export class ItemCodeRequestDto {
    itemCode: string;
    unitId: number;

    /**
     * 
     * @param itemCode 
     */
    constructor(itemCode: string,unitId: number) {
        this.itemCode = itemCode;
        this.unitId = unitId;
    }
}