export class UpdateChecklist {
    assetServiceChecklistId: number;
    condition: string | null;
    reason: string | null;
    checklistStatus: string | null;
    assetServeId: number;
    status:string | null;
    
    /**
     * 
     * @param assetServiceChecklistId 
     * @param condition 
     * @param reason 
     * @param checklistStatus 
     * @param assetServeId 
     * @param status 
     */
    constructor( assetServiceChecklistId: number,condition: string | null,reason: string | null,checklistStatus: string | null,assetServeId: number,status:string | null){
        this.assetServiceChecklistId = assetServiceChecklistId;
        this.condition = condition;
        this.reason = reason;
        this.checklistStatus = checklistStatus;
        this.assetServeId = assetServeId;
        this.status = status;

    }


}