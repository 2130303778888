export class LocationUpdateRequest {
    itemCategory: string;
    itemCategoryId: number;
    itemSubCategory: string;
    itemSubCategoryId: number;
    item: string;
    itemId: number;
    size: string;
    sizeId: number;
    quantity: number;
    ipQty: number;
    opQty: number;
    unitPrice?: number;
    unitId?: number;
    brandId?:number;
    saleOrderId?:number;
    locationId?:number;
    createdUser?:string;
    packingStockTransferItemId?:number;
    locationStatus?:string;
}