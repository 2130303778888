import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { VendorPriceListDto } from './vendor-price-list.dto';

export class PriceListForVendorResponse extends GlobalResponseObject{
        data?: VendorPriceListDto[];

        /**
         * 
         * @param status 
         * @param intlCode 
         * @param internalMessage 
         * @param data 
         */
        constructor(status: boolean, intlCode: number, internalMessage: string, data?: VendorPriceListDto[]) {
            super(status, intlCode, internalMessage);
            this.data = data;
        }
}
    