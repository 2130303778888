export class SkuDto{

    skuCodeId: number;
    productId: number
    requiredCount:number;
    minCount:number;
    maxCount:number;
    lossOrGainPercentage:number
    gradeId:number;
    minglaze:number;
    maxglaze:number;
    soakingStyle:string;
    soakingTime: string;
    foodTypeId: number;
    // skuCode: string;
    valueAdditionOneId: number;
    valueAdditionTwoId: number;
    valueAdditionThreeId: number;
    valueAdditionFourId: number;
    valueAdditionFiveId: number;
    onlySalt: number;
    sttpSalt: number;
    npSalt: number;
    cpSalt: number;
    variantCode: string;
    isActive: boolean;
    createdUser: string;
    minGrade?:number;
    maxGrade?:number;
    unitPrice?:number;
    shortCode?:string
    constructor(
        skuCodeId:number,
        productId: number,
        //foodTypeId: number,
        requiredCount:number,
        minCount:number,
        maxCount:number,
        lossOrGainPercentage:number,
        gradeId:number,
        minglaze:number,
        maxglaze:number,
        soakingStyle:string,
        soakingTime: string,
        variantCode: string,
        valueAdditionOneId: number,
        valueAdditionTwoId: number,
        valueAdditionThreeId: number,
        // valueAdditionFourId: number,
         onlySalt: number,
        sttpSalt: number,
        npSalt: number,
        cpSalt: number,skuCode:string,createdUser: string,isActive: boolean,minGrade?:number,
        maxGrade?:number,
        unitPrice?:number,
        shortCode?:string)
    {
        this.skuCodeId=skuCodeId;
        this.productId=productId;
        this.requiredCount=requiredCount;
        this.minCount=minCount;
        this.maxCount=maxCount;
        this.minglaze=minglaze;
        this.maxglaze=maxglaze;
        this.lossOrGainPercentage=lossOrGainPercentage;
        this.soakingStyle=soakingStyle;
        this.soakingTime=soakingTime;
        this.gradeId=gradeId;
        //this.foodTypeId=foodTypeId;
        this.variantCode=variantCode;
        this.valueAdditionOneId=valueAdditionOneId;
        this.valueAdditionTwoId=valueAdditionTwoId;
        this.valueAdditionThreeId=valueAdditionThreeId;
        // this.valueAdditionFourId=valueAdditionFourId;
        this.onlySalt=onlySalt;
        this.sttpSalt=sttpSalt;
        this.npSalt=npSalt;
        this.cpSalt=cpSalt;
        // this.skuCode=skuCode;
        this.createdUser=createdUser;
        this.isActive=isActive;
        this.minGrade=minGrade;
        this.maxGrade=maxGrade;
        this.unitPrice=unitPrice;
        this.shortCode=shortCode;
    }
}