import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AllLotCodeOpsResponseModel, AllProdInvResponseModel, LotCodeDropDownResponse, LotCodeOpsResponseModel, OperationandLotReq, ProdInvResponseModel } from '@gtpl/shared-models/production-management';
import { SaleOrderItemsDropdownResponse, SoNumbersResponseModel } from '@gtpl/shared-models/sale-management';
import {  saleOrder, SaleOrderItemsRequest} from '@gtpl/shared-services/sale-management';
import { CommonResponse } from '@gtpl/shared-models/masters';


export class LotCodeOpsService {
    URL = connection.DEPLOY_URL + '/lot-code-ops';
    
    async createLotCodeOps(values):Promise<LotCodeOpsResponseModel>{
        return await axios.post(this.URL + '/createLotCodeOps',values)
        .then(res => {
            return res.data
        })
    }

    async updateLotCodeOps(values):Promise<LotCodeOpsResponseModel>{
        return await axios.post(this.URL + '/updateLotCodeOps',values)
        .then(res => {
            return res.data
        })
    }


    async getAllLotOps(plantReq: UnitRequest):Promise<AllLotCodeOpsResponseModel>{
        return await axios.post(this.URL + '/getAllLotOps',plantReq)
        .then(res => {
            return res.data
        })
    }

    async getAllLotNumbers(req?: UnitRequest):Promise<LotCodeDropDownResponse>{
        return await axios.post(this.URL + '/getAllLotNumbers',req)
        .then(res => {
            return res.data
        })
    }

    async getSaleOrders() :Promise<SoNumbersResponseModel>{
        return await axios.post(this.URL + '/getSaleOrders')
        .then(res => {
            return res.data
        })
    }

    async getSaleOrderItemsForSo(req: saleOrder): Promise<SaleOrderItemsDropdownResponse>{
        return await axios.post(this.URL + '/getSaleOrderItemsForSo',req)
        .then(res => {
            return res.data
        })
    }

    async getAllLotNumbersForSoItem(req: SaleOrderItemsRequest): Promise<LotCodeDropDownResponse>{
        return await axios.post(this.URL + '/getAllLotNumbersForSoItem',req)
        .then(res => {
            return res.data
        })
    }
    async getLotCodeTracking(): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getLotCodeTracking')
        .then(res => {
            return res.data
        })
    }
    async getSonumberforQaQcrejection(req:OperationandLotReq): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSonumberforQaQcrejection',req)
        .then(res => {
            return res.data
        })
    }

}