import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderDropDownDto } from './sale-order-drop-down.dto';

export class SaleOrderDropDownResponseModel extends GlobalResponseObject {
    data?: SaleOrderDropDownDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}

