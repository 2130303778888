import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Form, DatePicker, Select, Spin, Tabs, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import Link from 'antd/lib/typography/Link';

import { BomPackingItemsDto, BomSODto, CrrencySymbols, PortOfEntryInput, SaleOrderFiltersDto, SaleOrderStatusDropdownDto, SODto, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { SaleOrderItemsInfo, SaleOrderItemsDto, SOItemsDto } from '@gtpl/shared-models/sale-management';

import { SaleOrderService, SaleOrderTrackFilters, SoLifeCycleStatusCategoryReq } from '@gtpl/shared-services/sale-management';
import { SoDesignForm } from '@gtpl/pages/master/master-components/so-design-form';

import { Table } from "ant-table-extensions";

import { FormattedMessage } from 'react-intl';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Redirect, useLocation } from 'react-router-dom';
import './sale-order-report-grid.css';
import { DestinationDto, EndCustomerDto, FoodTypesDto, PlantsDropDown, ProductDto, CountryDto, Brands, MasterBrandsDropDownDto } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import moment from 'moment';
import SOReportItemsGrid from './so-report-items';
import { DestinationService, EndCustomersService, UnitcodeService, CountryService, MasterBrandService, SkuService } from '@gtpl/shared-services/masters';
import { SoLifeCycleCategoryEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import Text from 'antd/lib/typography/Text';
import CustomSpinner from 'libs/shared-components/custom-spinner/src/lib/custom-spinner';
import { SoLifeCycleStatusEnum, } from "@gtpl/shared-models/common-models";
const { Option } = Select;


/* eslint-disable-next-line */
export interface SaleDetailsPropsProps { }

export function SaleDetailsReport(props: SaleDetailsPropsProps) {
  // let soLifeCycleStatus = SoLifeCycleStatusEnum;
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const [display, setDisplay] = useState<string>('none');
  const [disable, setDisable] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true)
  const [showAbstract, setshowAbstract] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isNewForm, setisNewForm] = useState<boolean>(true);
  const [object, setObject] = useState(null);
  const [saleId, setSaleId] = useState(0);
  const [viewOnly, setViewOnly] = useState(true);
  const [endCustomers, setEndCustomers] = useState<any[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<any[]>([]);
  const [selectedEndCustomer, setSelectedEndCustomer] = useState<number>(0);
  const [masterBrandId, setMasterBrandId] = useState<number>(0);
  const [varientCode, setVarientCode] = useState<number>(0);
  const [statusList, setStatusList] = useState<SaleOrderStatusDropdownDto[]>(null)
  const [unit, setUnit] = useState<number>(0)
  const [prodUnit, setProcdUnit] = useState<number>(0)
  const [destinations, setDestinations] = useState<number[]>([])
  const [totalPoCount, settotalSOCount] = useState<number>(0)
  const [country, setCountry] = useState<number[]>([])
  const [filters, setFilters] = useState<SaleOrderFiltersDto>(null)
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);
  const [bomSoData, setBomSoData] = useState<BomPackingItemsDto[]>([]);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<any>(undefined);
  const [selectedSoItem, setSelectedSoItem] = useState<any>(undefined);
  const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [foodTypeData, setFoodType] = useState<FoodTypesDto[]>([]);
  const [saleOrderStatus, setSaleOrderStatus] = useState(null);
  const [destinationData, setDestinationData] = useState<any[]>([]);
  const [plantsData, setPlantsData] = useState<PlantsDropDown[]>([]);
  const [countryData, setCountryData] = useState<any[]>([]);
  const [manufacturingUnitData, setManufacturingUnitData] = useState<any[]>([]);
  const [brandsInfo, setBrandsInfo] = useState<Brands[]>([]);
  const [brandsDropDown, setBrandsDropDown] = useState<any[]>([]);
  const brandsService = new MasterBrandService()
  const countryService = new CountryService();
  const destinationservice = new DestinationService();
  const endCustomersService = new EndCustomersService;
  const unitcodeService = new UnitcodeService;
  const [product, setProduct] = useState<any[]>([]);
  const productService = new SkuService()
  const [data1, setData1] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [readyForShipment, setReadyForShipment] = useState<string>('All');
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>();
  const [lifeCycleStatusforCategory,setLifeCycleStatusforCategory] = useState<any[]>([])
  const [lifeCycleCategory,setLifeCycleCategory] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(null);
  const columns = useState('');
  let location = useLocation();
  console.log(location.state);
  let lifeCycleStat = location.state
  useEffect(() => {
    if (lifeCycleStat) {
      form.setFieldsValue({ lifeCycleStatus: lifeCycleStat })
      setLifeCycleStatus(lifeCycleStat)
      // setFilters({ selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, country, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode });
      // viewFilteredReport(form.getFieldsValue())
    }
  }, [])
  const { formatMessage: fm } = useIntl();
  const service = new SaleOrderService;
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    getFinalDestinationData();
    getAllActiveCountries()
    getStatusList();
    getActiveEndCustomersDropDownData();
    getBrandData();
    getProducts();
    getManfUintDropForSoReport();
    // setSaleOrderData(saleData); console.log(saleData);
    getAllActivePlants();
    GetSoLifeCycleStatusForCategory();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ prodUnit: Number(localStorage.getItem('unit_id')) })
    }
    form.setFieldsValue({
      createdDate: [(moment(moment().format("YYYY-MM-DD")).subtract(5, 'month')), moment(moment().format("YYYY-MM-DD"))]
    })

    setSelectedCreatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(5, 'month').add(1, 'days')))
    setSelectedCreatedToDate(moment(moment().format("YYYY-MM-DD")).add(1, 'days'))

  }, []);

  // console.log(typeof (moment().month()),'month')
  // console.log(String(moment().year())+"-04-01",'year')
  //console.log(typeof(moment().subtract(1, 'years')+"-04-01"),"last year")
  //console.log((moment(moment().format("YYYY-MM-DD")).subtract(6,'months')),"last 6 months")

  const getAllActivePlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantsData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
    });
  }


  const getActiveEndCustomersDropDownData = () => {
    service.getEndCustDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setEndCustomers(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setEndCustomers([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setEndCustomers([]);
      });
  }


  const getStatusList = () => {
    service.getStatusList().then((res) => {
      if (res.status) {
        setStatusList(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setStatusList([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setStatusList([]);
      });
  }

  const getFinalDestinationData = () => {
    service.getDestinationDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setDestinationData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setDestinationData([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setDestinationData([]);
      });
  }
  const getAllActiveCountries = () => {
    service.getCountryDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setCountryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setCountryData([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setCountryData([]);
      });
  }
  const getManfUintDropForSoReport = () => {
    service.getManfUintDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setManufacturingUnitData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setManufacturingUnitData([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setManufacturingUnitData([]);
      });
  }
  const getAllSaleOrders = (selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, countryId, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode, readyForShipmentVal,lifeCycleCategory ,lifeCycleStatus) => {
    setDisable(true);
    const req = new SaleOrderTrackFilters()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.prodUnit = Number(localStorage.getItem('unit_id'));
    } else {
      req.prodUnit = prodUnit;
    }
    req.selectedEstimatedFromDate = selectedEstimatedFromDate;
    req.selectedEstimatedToDate = selectedEstimatedToDate;
    req.saleOrderStatus = saleOrderStatus;
    req.selectedEndCustomer = selectedEndCustomer;
    req.unit = unit;
    req.countryId = countryId;
    req.destinations = destinations;
    req.selectedCreatedFromDate = selectedCreatedFromDate;
    req.selectedCreatedToDate = selectedCreatedToDate;
    req.masterBrandId = masterBrandId;
    req.varientCode = varientCode;
    req.lifeCycleStatus = lifeCycleStatus;
    req.mainCategory = lifeCycleCategory
    if (readyForShipmentVal !== 'All') {
      req.readyForShipment = readyForShipmentVal === "Yes" ? true : false;
    }
    setLoading(true);
    service.totalSoData(req).then(res => {
      setDisable(false);
      if (res.status) {
        setSaleOrderData(res.data);
        setLoading(false);
        setshowAbstract(true)
        console.log(res.data)
        console.log(exportedData)
        AlertMessages.getSuccessMessage(res.internalMessage);
        var tempResult = {}

        for (let { poNo } of res.data)
          tempResult[poNo] = {
            poNo,
            count: tempResult[poNo] ? tempResult[poNo].count + 1 : 1
          }

        let result = Object.values(tempResult)
        settotalSOCount(result.length)

        console.log(result.length)
      } else {
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setSaleOrderData([]);
          setshowAbstract(true)
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setLoading(false);

          setshowAbstract(true)
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
      setDisable(false);
    })
  }

  const setBrandName = (value) => {
    setMasterBrandId(value);
  }

  const getBrandData = () => {
    service.getBrandDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setBrandsDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setBrandsDropDown([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setBrandsDropDown([]);
      });
  }
  const getProducts = () => {
    service.getVariantDropForSoReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        setProduct(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setProduct([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setProduct([]);
      });
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const GetSoLifeCycleStatusForCategory = () => {
    service.GetSoLifeCycleStatusForCategory({mainCategory:form.getFieldValue('lifeCycleCategory')}).then(res => {
      if (res.status) {
        setLifeCycleStatusforCategory(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: '#',
      width: '50px',
      key: 'sno',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },

    {
      title: 'Customer PO Number',
      dataIndex: 'poNo',
      fixed: 'left',
      width: '120px',
      sorter: (a, b) => a.poNo.localeCompare(b.poNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNo')
    },

    {
      title: 'Buyer PO Number',
      dataIndex: 'buyerPoNo',
      width: '130px',
      ...getColumnSearchProps('buyerPoNo')
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      width: '150px',
      sorter: (a, b) => a.brand?.localeCompare(b.brand),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('brand')
    },
    {
      title: 'Pro Unit',
      dataIndex: 'processingUnit',
      width: '80px',
      sorter: (a, b) => a.processingUnit.localeCompare(b.processingUnit),
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Mfg Unit',
      dataIndex: 'manUnit',
      width: '80px',
      sorter: (a, b) => a.manUnit.localeCompare(b.manUnit),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Product SKU',
      dataIndex: 'productSku',
      width: '300px',
      sorter: (a, b) => a.productSku.localeCompare(b.productSku),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('productSku')
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      width: '80px',
      ...getColumnSearchProps('packStyle'),
      render: (text, data, index) => {
        const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
        return <span>{`${data.pouches}*${Number(data.pouchWeight)} ${uomData?.name}`}</span>
      }
      // sorter: (a, b) => a.packStyle.localeCompare(b.packStyle),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Style Weighment',
      dataIndex: 'styleWeighment',
      width: '120px',
      render: (text) => {
        return <span>{(text)}</span>//dropdown search
      },
      filters: [
        {
          text: 'NWNC',
          value: 'NWNC',
        },

        {
          text: 'FWNC',
          value: 'FWNC',
        },
        {
          text: 'FWFC',
          value: 'FWFC',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.styleWeighment === value;
      },
    },
    {
      title: 'Job Cases',
      dataIndex: 'jobCases',
      align: 'right',
      width: '120px',
      render: (text, record) => {
        console.log(record)
        return <span>{`${Number(text)}`}</span>
      }
    },
    //Bulk


    {
      title: 'Required Cartons',
      dataIndex: 'cartons',
      width: '120px',
    },
    {
      title: 'Carton PO Qty',
      dataIndex: 'cartonPoQty',
      width: '120px',
    },
    {
      title: 'Received Cartons',
      dataIndex: 'cartonGrnQty',
      width: '120px',
    },
    {
      title: 'Assigned Carton Qty',
      dataIndex: 'cartonAssignedQty',
      width: '120px',
    },
    {
      title: 'Available Bulk Cartons',
      dataIndex: 'cartonAvailableQty',
      width: '120px',
    },

    {
      title: 'Required Pouches',
      dataIndex: 'Qty',
      width: '120px',
    },
    {
      title: 'pouch PO Qty',
      dataIndex: 'pouchPoQty',
      width: '120px',
    },
    {
      title: 'Received Pouches',
      dataIndex: 'pouchGrnQty',
      width: '120px',
    },
    {
      title: 'Assigned Pouch Qty',
      dataIndex: 'pouchAssignedQty',
      width: '120px',
    },
    //
    {
      title: 'Available Bulk Pouches',
      dataIndex: 'pouchAvailableQty',
      width: '120px',
    },
    {
      title: 'FG %',
      dataIndex: 'productionPercent',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return <span>{text}</span>
      }
    },
    {
      title: 'Aging',
      key: 'etd',
      width: 80,
      align: 'left',
      sorter: (a, b) => (Math.floor((new Date(a.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date().getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{record.etd ? (Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)) : '-'}</div>),
          props: {
            style: {
              background: record.etd ? Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c' : 'white',
              color: record.etd ? Math.floor((new Date(record.etd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white' : 'Black'
            }
          },
        };
        return obj;
      }
    },
    {
      title: 'So Status',
      dataIndex: 'soStatus',
      width: '150px',
      // responsive: ['lg'],
      render: (status, rowData) => (
        <>
          {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : status == "CANCELLED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CANCELLED</Tag> : ""}

        </>
      ),
      // sorter: (a, b) => a.status.localeCompare(b.status),
      // sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'OPEN',
          value: 'OPEN',
        },

        {
          text: 'INPROGRESS',
          value: 'INPROGRESS',
        },
        // {
        //   text: 'CLOSED',
        //   value: 'CLOSED',
        // },
        // {
        //   text: 'CANCELLED',
        //   value: 'CANCELLED',
        // },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        // === is not work
        return record.soStatus === value;
      },
    },
    {
      title: 'Life Cycle Status',
      dataIndex: 'lifeCycleStatus',
      width: '130px',
      ...getColumnSearchProps('lifeCycleStatus')
    },
    {
      title: 'Latest Reported Date',
      dataIndex: 'reportedDate',
      width: '100px',
      sorter: (a, b) => a.reportedDate?.localeCompare(b.reportedDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.reportedDate ? moment(record.reportedDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'Carton Status',
      dataIndex: 'cartonStatus',
      width: '120px',
    },
    {
      title: 'Pouch Status',
      dataIndex: 'pouchStatus',
      width: '120px',
    },
    {
      title: 'ArtWork Approval Status',
      dataIndex: 'isBrandApproved',
      width: '80px',
      sorter: (a, b) => a.isBrandApproved.localeCompare(b.isBrandApproved),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('isBrandApproved')
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: '80px',
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('country')
    },

    {
      title: 'Final Destination',
      dataIndex: 'destinationAddress',
      width: '170px',
      sorter: (a, b) => a.destinationAddress.localeCompare(b.destinationAddress),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('destinationAddress')
    },
    {
      title: 'INSPECTION STATUS',
      dataIndex: 'inspectionStatus',
      width: '130px',
      ...getColumnSearchProps('inspectionStatus')
    },
    {
      title: 'Container Allocated Y/N',
      dataIndex: 'containerStatus',
      width: '150px',
      ...getColumnSearchProps('containerStatus')
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      width: '80px',
      filters: [
        {
          text: 'Main Job',
          value: 'Main Job',
        },

        {
          text: 'Sub Job',
          value: 'Sub Job',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.jobType === value;
      },
    },
    {
      title: 'PO Date',
      dataIndex: 'invDate',
      width: '100px',
      sorter: (a, b) => moment(a.invDate).unix() - moment(b.invDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.invDate ? moment(record.invDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: '100px',
      sorter: (a, b) => a.etd?.localeCompare(b.etd),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'Reporting Cases',
      dataIndex: 'reportingCases',
      align: 'right',
      width: '120px',
      render: (text, record) => {
        return <span>{`${Number(text)}`}</span>
      }
    },
    {
      title: 'Total Cases',
      dataIndex: 'cases',
      align: 'right',
      width: '120px',
      render: (text, record) => {
        return <span>{`${Number(text)}`}</span>
      }
    },


    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: '100px',
      sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      render: (value, rowData, index) => {
        return moment(value).format('YYYY-MM-DD');
      },
    },

    {
      title: 'Customer Name',
      dataIndex: 'customer',
      width: '200px',
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('customer')
    },

    {
      title: 'Category',
      dataIndex: 'category',
      width: '100px',
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('category')
    },
    {
      title: 'Shrimp Weight',
      dataIndex: 'shrimpWeight',
      width: '150px',
      align: 'right',

      render: (text, record) => {
        return <span>{`${Number(text)}`}</span>
      }
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      width: '150px',
      align: 'right',
      render: (text, record) => {
        const uomData = UnitsOfWeightInput.find(uom => uom.value == record.uomId);
        return <span>{`${Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${uomData?.name}`}</span>
      }
    },

    {
      title: 'End Customer Name',
      dataIndex: 'endCustomer',
      width: '200px',
      // fixed: 'left',
      sorter: (a, b) => a.endCustomer.localeCompare(b.endCustomer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomer')
    },


    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return <span>{CrrencySymbols?.find(item => item.id == record.currencyId)?.symbol + " " + `${Number(parseFloat(record.unitPrice).toFixed(2))}`}</span>
      },
    },

    {
      title: 'ETA',
      dataIndex: 'eta',
      width: '100px',
      sorter: (a, b) => a.eta?.localeCompare(b.eta),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.eta ? moment(record.eta).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'Lab Inspection',
      dataIndex: 'labInspection',
      width: '100px',
      ...getColumnSearchProps('labInspection'),
      render: (text) => {
        return <span>{text ? text : '-'}</span>
      }
    },
    {
      title: 'Load Inspection',
      dataIndex: 'loadInspection',
      width: '80px',
      render: (record) => {
        return <span>{record ? 'YES' : 'NO'}</span>
      },
      filters: [
        {
          text: 'YES',
          value: 'YES',
        },

        {
          text: 'NO',
          value: 'NO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.loadInspection === value;
      },

    },
    {
      title: 'SO Type',
      dataIndex: 'soType',
      width: '80px',
      sorter: (a, b) => a.soType.localeCompare(b.soType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'Dispatch',
          value: 'Dispatch',
        },

        {
          text: 'Stock',
          value: 'Stock',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.soType === value;
      },
    },
    {
      title: 'Is Sub Contract',
      dataIndex: 'isSubContract',
      width: '100px',
      sorter: (a, b) => a.isSubContract.localeCompare(b.isSubContract),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'YES',
          value: 'YES',
        },

        {
          text: 'NO',
          value: 'NO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isSubContract === value;
      }
    },

    {
      title: 'Lot Code',
      dataIndex: 'lotCode',
      width: '80px',
      sorter: (a, b) => a.lotCode?.localeCompare(b.lotCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotCode'),
      render: (text) => {
        return <span>{text ? text : '-'}</span>
      }
    },
    {
      title: 'Life Cycle Status',
      dataIndex: 'lifeCycleStatus',
      sorter: (a, b) => a.lifeCycleStatus?.localeCompare(b.lifeCycleStatus),
      sortDirections: ['descend', 'ascend'],
      width: '130px',
      ...getColumnSearchProps('lifeCycleStatus')
    },

    {
      title: 'COMPLETION DATE',
      dataIndex: 'productionCompletedDate',
      width: '130px',
      ...getColumnSearchProps('productionCompletedDate'),
      render: (value, record) => {
        return <span>
          {record.productionCompletedDate ? moment(record.productionCompletedDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: ' Product Compilation (%)',
      dataIndex: 'productionPercent',
      width: '130px',
      ...getColumnSearchProps('productionPercent')
    },

    {
      title: 'TENTATIVE VESSEL DATE',
      dataIndex: 'buyerPoNo',
      width: '150px',
      ...getColumnSearchProps('buyerPoNo')
    },

    {
      title: 'Certification',
      dataIndex: 'certification',
      width: '110px',
      filters: [
        {
          text: 'BAP',
          value: 'BAP',
        },

        {
          text: 'ASC',
          value: 'ASC',
        },
        {
          text: 'N/A',
          value: 'N/A',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.certification === value;
      }
    },

    {
      title: 'Rating',
      dataIndex: 'rating',
      width: '70px',
      ...getColumnSearchProps('rating'),//dropdown search
      render: (text, record) => {
        return <span>{((record.certification == 'BAP') ? text : '-')}</span>
      }
    },

    {
      title: 'SGS Inspection',
      dataIndex: 'sgsInspection',
      width: '80px',
      sorter: (a, b) => a.sgsInspection?.localeCompare(b.sgsInspection),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.sgsInspection ? record.sgsInspection : '-'}
        </span>
      }
    },
    {
      title: 'TUV Inspection',
      dataIndex: 'tuvInspection',
      width: '80px',
      sorter: (a, b) => a.tuvInspection?.localeCompare(b.tuvInspection),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.tuvInspection ? record.tuvInspection : '-'}
        </span>
      }
    },

  ];

  const exportedData = [];

  saleOrderData.forEach(element => {

    soItemData.forEach(element1 => element.saleOrderId == element1.saleOrderId ? exportedData.push(Object.assign(element, element1)) : {})

  })
  console.log(exportedData)
  const excelData = saleOrderData

  console.log(moment().format('MM'))
  // const headers = [
  //   { label: "Po Number", key: "poNo" },
  //   { label: "Po Date", key: "invDate" },
  //   { label: "End Customer", key: "endCustomer" },
  //   { label: "Buyer Po Number", key: "buyerPoNo" },
  //   { label: "SO process TYpe", key: "soProcessType" },
  //   { label: "SO Type", key: "soType" },
  //   { label: "Job Type", key: "jobType" },
  //   { label: "Invoice Date", key: "excelInvDate" },
  //   { label: "Customer", key: "customer" },
  //   { label: "ETD", key: "excelEtd" },
  //   { label: "ETA", key: "excelEta" },
  //   { label: "Port Of Entry", key: "excelPortOfEntry" },
  //   { label: "Unit", key: "manUnit" },
  //   { label: "Processing Unit", key: "processingUnit" },
  //   { label: "Country", key: "country" },
  //   { label: "Destination Address", key: "destinationAddress" },
  //   { label: "Category", key: "category" },
  //   { label: "Brand", key: "brand" },
  //   { label: "Product", key: "productSku" },
  //   { label: "Pack Style", key: "packStyle" },
  //   { label: "Shrimp Weight", key: "shrimpWeight" },
  //   { label: "job Cases", key: "jobCases" },
  //   { label: "Cases", key: "cases" },
  //   { label: "Net Case Weight", key: "caseWeight" },
  //   { label: "Net Weight", key: "netWeight" },
  //   { label: "Unit Price", key: "unitPrice" },
  //   { label: "Net Amount", key: "netAmount" },
  //   { label: "Style Weighment", key: "styleWeighment" },
  //   { label: "Brand Design", key: "brandType" },
  //   { label: "Is Brand Approved", key: "isBrandApproved" },
  //   { label: "Additional Note", key: "addNote" },
  //   { label: "Certification", key: "certification" },
  //   { label: "Rating", key: "rating" },
  //   { label: "Is Certified", key: "isCertified" },
  //   { label: "Lab Inspection", key: "labInspection" },
  //   { label: "Lot Code", key: "lotCode" },
  //   { label: "Load Inspection", key: "loadInspection" },
  //   { label: "status", key: "soStatus" },
  // //   { label: "Dispatched Qty", key: "dispatchedQty" },
  // //   { label: "Invoice Amount", key: "invAmount" },
  // //   { label: "Re Pack Qty", key: "rePackQty" },
  // //   { label: "Re Process Qty", key: "reProcessQty" },
  // //   { label: "Required Quantity", key: "requiredQuantity" },
  // ];
  const data = [
    { title: "End Customer", dataIndex: "endCustomer" },
    { title: "Po Number", dataIndex: "poNo" },
    { title: "Po Date", dataIndex: "invDate", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Created Date", dataIndex: "createdDate" },
    { title: "Buyer Po Number", dataIndex: "buyerPoNo" },
    { title: "Customer Name", dataIndex: "customer" },
    { title: "Category", dataIndex: "category" },
    { title: "Brand", dataIndex: "brand" },
    { title: "Product SKU", dataIndex: "productSku" },
    { title: "Pack Style", dataIndex: "packStyle" },
    { title: "Style Weighment", dataIndex: "styleWeighment" },
    { title: "Shrimp Weight", dataIndex: "shrimpWeight", render: (value) => Number(value) },
    { title: "Net Weight", dataIndex: "netWeight", render: (value) => Number(value) },
    { title: "Reporting Cases", dataIndex: "reportingCases" },
    { title: "Total Cases", dataIndex: "cases", render: (value) => Number(value) },
    { title: "job Cases", dataIndex: "jobCases" },
    {
      title: "FG %", dataIndex: "fg", render: (text, record) => {
        return <span>{Math.round((record.reportingCases / record.cases) * 100)}</span>
      }
    },
    { title: "Pouch Status", dataIndex: "pouchStatus" },
    { title: "Carton Status", dataIndex: "cartonStatus" },
    { title: "Unit Price", dataIndex: "unitPrice", render: (value) => Number(value) },
    { title: "Manufacturing Unit", dataIndex: "manUnit" },
    { title: "Processing Unit", dataIndex: "processingUnit" },
    { title: "Country", dataIndex: "country" },
    { title: "Final Destination", dataIndex: "destinationAddress" },
    { title: "ETD", dataIndex: "etd", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "ETA", dataIndex: "eta", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Lab Inspection", dataIndex: "labInspection" },
    { title: "Load Inspection", dataIndex: "loadInspection" },
    { title: "SO Type", dataIndex: "soType" },
    { title: "Job Type", dataIndex: "jobType" },
    { title: "Is Sub Contract", dataIndex: "isSubContract" },
    { title: "Lot Code", dataIndex: "lotCode" },
    { title: "Certification", dataIndex: "certification" },
    { title: "Rating", dataIndex: "rating" },
    { title: "status", dataIndex: "soStatus" },
  ];
  // const csvReport = {
  //   data: excelData.map((record)=>{
  //       return {excelEtd:moment(record.etd).format('YYYY-MM-DD'),excelInvDate:moment(record.invDate).format('YYYY-MM-DD'),excelEta:moment(record.eta).format('YYYY-MM-DD'),excelPortOfEntry:PortOfEntryInput.find(item => item.value == record.portOfEntry).name,...record}
  //   }),
  //   headers: headers,
  //   filename: 'SaleOrderReport.csv'
  // };
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Saleorders')
      .addColumns(data)
      .addDataSource(saleOrderData, { str2num: true })
      .saveAs('saleorders.xlsx');
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log(saleOrderId);
  }
  const EstimatedETDDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedEstimatedFromDate(fromDate)
    setSelectedEstimatedToDate(toDate)
  }
  const EstimatedCreatedDate = (value) => {
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));

      setSelectedCreatedFromDate(fromDate)
      setSelectedCreatedToDate(toDate)
    } else {
      setSelectedCreatedFromDate(undefined)
      setSelectedCreatedToDate(undefined)
    }

  }
  const handleEndCustomer = (value) => {
    console.log(value);
    setSelectedEndCustomer(value)
  }
  const handleUnit = (value) => {
    setUnit(value)
  }
  const handleProdUnit = (value) => {
    setProcdUnit(value)
  }

  const handleDestination = (value) => {
    console.log(value)
    setDestinations(value)
  }

  const handleCountrys = (value) => {
    console.log(value)
    setCountry(value)
  }

  const handleStatus = (val) => {
    setSaleOrderStatus(val);
  }
  const handleVarient = (value) => {
    setVarientCode(value)
  }
  const handleReadyForShipment = (shipmentVal: string) => {
    setReadyForShipment(shipmentVal);
  }
  const handleLifeCycle = (value) => {
    setLifeCycleStatus(value);
  }
  const onReset = () => {
    // form.resetFields();
    form.resetFields();
    setSearchText('');
    getAllSaleOrders(undefined, undefined, null, 0, 0, 0, 0, 0, 0, [], 0, 0, "All",0, 0)
    setSelectedEstimatedFromDate(undefined)
    setSelectedEstimatedToDate(undefined)
    setSelectedCreatedFromDate(undefined)
    setSelectedCreatedToDate(undefined)
    setDestinations([]);
    setVarientCode(0);
    setReadyForShipment('');
    setCountry([]); setProcdUnit(0); setUnit(0); setSelectedEndCustomer(0); setMasterBrandId(0);
    setSaleOrderStatus(null);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ prodUnit: Number(localStorage.getItem('unit_id')) })
    }
    // window.location.reload();
  };
const handleCategory = (value) =>{
  console.log(value)
setLifeCycleCategory(value)
  GetSoLifeCycleStatusForCategory()
  form.resetFields(['lifeCycleStatus'])
} 
  const viewFilteredReport = (values) => {
    setFilters({ selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, country, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode });
    getAllSaleOrders(selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, country, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode, readyForShipment, lifeCycleCategory,lifeCycleStatus);
    // if(values.destinations){}
  }
  return (
    <>
      {(saleId) ? <Redirect to={{ pathname: "/saleorder-detail-view", state: saleId }} /> : null}

      <Card title={<span style={{ color: 'white' }}>Sale Order Report</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >

        <Form form={form} layout={'vertical'} style={{ padding: '0px' }} onFinish={viewFilteredReport} >
          <Row gutter={[24, 10]}>
            <Col span={5}>
              <Form.Item name="fromDate"
                label="PO Date"
                rules={[
                  {
                    required: false,
                    message: "Missing PO Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="createdDate"
                label="Created Date"

                rules={[
                  {
                    required: false,
                    message: "Missing Created Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedCreatedDate} />

              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="saleOrderStatus" label="SO Status" rules={[{ required: false }]}>
                <Select
                  placeholder="SO Status"
                  onChange={handleStatus}
                  allowClear
                  showSearch
                  mode="multiple" >
                  <Option key={'OPEN'} value={'OPEN'}>{'OPEN'}</Option>
                  <Option key={'INPROGRESS'} value={'INPROGRESS'}>{'INPROGRESS'}</Option>



                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="endCustomers" label="End Customer" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="End Customer"
                  onChange={handleEndCustomer}
                  allowClear
                  dropdownMatchSelectWidth={false}
                >
                  <Option key={0} value={null}>Select All End Customer</Option>
                  {endCustomers ? endCustomers.map(endCust => {
                    return <Option key={endCust.endCustomerId} value={endCust.endCustomerId}>{endCust.endCustomerName}</Option>
                  }) : ""}

                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="Unit" label=" Manufacturing Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  onChange={handleUnit}
                  allowClear
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {manufacturingUnitData?.map(plant => {
                    return <Option key={plant.factoryId} value={plant.factoryId}>{plant.manUnit}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="prodUnit" label=" Processing Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  onChange={handleProdUnit}
                  allowClear
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="country" label="Country" rules={[{ required: false }]}>
                <Select
                  showSearch
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Country"
                  onChange={handleCountrys}
                  allowClear

                >
                  {countryData.map(dropData => {
                    return <Option key={dropData.countryId} value={dropData.countryId}>{dropData.countryName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="destination" label="Destination" rules={[{ required: false }]}>
                <Select
                  showSearch
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Destination"
                  onChange={handleDestination}
                  allowClear
                  mode="multiple"
                >
                  {destinationData.map(dropData => {
                    return <Option value={dropData.destinationId}>{dropData.destinationName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Brand Name"
                name='masterBrandId'
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  placeholder="Please Select Brand"
                  optionFilterProp="children"
                  allowClear
                  onChange={setBrandName}
                  onSearch={onSearch}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Please select brand</Option>
                  {brandsDropDown.map(brandDropData => {
                    return <Option label={'brandId'} key={brandDropData.masterBrandId} value={brandDropData.masterBrandId}>{brandDropData.masterBrandName}</Option>
                  })}
                  |
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="varientCode"
                label="Varient"
                rules={[
                  {
                    required: false,
                    message: "Product is required"
                  },
                ]}
              >
                <Select
                  showSearch
                  // style={{ width: 200 }}
                  placeholder="Select Product"
                  optionFilterProp="children"
                  onChange={handleVarient}
                  allowClear
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                >
                  <Option value={null}>Select Product</Option>
                  {product?.map(product => {
                    return <Option key={product.skuCodeId} value={product.skuCodeId}> {product.variantCode}  </Option>
                  })}
                </Select>
              </Form.Item></Col >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
              <Form.Item
                name="lifeCycleCategory"
                label="Life Cycle Category"
                rules={[
                  {
                    required: false, message: 'Missing Life Cycle category',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Life Cycle category"
                  allowClear
                  onChange={handleCategory}
                mode="multiple"
                >
                  {Object.values(SoLifeCycleCategoryEnum).map(SoLifeCycleStatus => {
                    return <Option value={SoLifeCycleStatus}>{SoLifeCycleStatus}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
              <Form.Item
                name="lifeCycleStatus"
                label="Life Cycle Status"
                rules={[
                  {
                    required: false, message: 'Missing Life Cycle Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Life Cycle Status"
                  allowClear
                  onChange={handleLifeCycle}
                >
                 <Option key={0} value={null}>Select Life Cycle Status</Option>
                {lifeCycleStatusforCategory?.map((value) => {
                  return <Option key={value.subCategory} value={value.subCategory}>{value.subCategory}</Option>
                })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="readyForShipment"
                label="Ready For Shipment"
                rules={[
                  {
                    required: false,
                    message: "Ready For Shipment is required"
                  },
                ]}
              >
                <Select
                  showSearch
                  // style={{ width: 200 }}
                  placeholder="Select"
                  optionFilterProp="children"
                  onChange={handleReadyForShipment}
                  defaultValue={readyForShipment}
                  allowClear
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                >
                  <Option key={'All'} value={"All"}> All  </Option>
                  <Option key={'Yes'} value={"Yes"}> Yes  </Option>
                </Select>
              </Form.Item ></Col >


            {/* </Row>
           <Row style={{marginRight:'20px'}} gutter={24}> */}
            < Col style={{ paddingLeft: '50px', marginTop: '30px' }
            }>
              <Form.Item >
                <Button type="primary" htmlType="submit" disabled={disable} onClick={() => setDisplay('block')}> Get Details</Button>
              </Form.Item>
            </Col >
            <Col style={{ paddingLeft: '10px', marginTop: '30px' }}>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row >
        </Form >
        {showAbstract ? <>< Row gutter={28} >
          <Col span={3}>
            <Card title={"Total SO's: " + totalPoCount} style={{ textAlign: 'left', width: 160, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          <Col span={4}>
            <Card title={"Total SO Items: " + saleOrderData.length} style={{ textAlign: 'left', width: 220, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          <Col span={3}>
            <Card title={'OPEN :' + saleOrderData.filter(el => el.soStatus == "OPEN").length} style={{ textAlign: 'left', height: 41, width: 130, backgroundColor: '#f6e552' }}></Card>
          </Col>
          <Col span={4}>
            <Card title={'IN-PROGRESS :' + saleOrderData.filter(el => el.soStatus == "INPROGRESS").length} style={{ textAlign: 'left', width: 190, height: 41, backgroundColor: '#f2a637' }}></Card>
          </Col>
          <Col>
            <Button icon={<DownloadOutlined />} onClick={() => { exportExcel(); }}>Get Excel</Button>
          </Col>
          {/* <Col span={3}>
            <Card title={'CLOSED :' + saleOrderData.filter(el => el.soStatus == "CLOSED").length} style={{ textAlign: 'left', width: 160, height: 41, backgroundColor: '#52c41a' }}></Card>
          </Col>
          <Col span={5}>
            <Card title={'CANCELLED :' + saleOrderData.filter(el => el.soStatus == "CANCELLED").length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f16565' }}></Card>
          </Col> */}
        </Row ></> : <></>}
        {/* <Row gutter={24}>
      // <Col span={4}>
      //   <Card title={'OPEN :' + saleOrderData.filter(el => el.soStatus == "OPEN").length} style={{textAlign: 'left', height: 41,backgroundColor:'#f6e552'}}></Card>
      // </Col>
      // <Col span={5}>
      //   <Card title={'IN-PROGRESS :' + saleOrderData.filter(el => el.soStatus == "INPROGRESS").length} style={{textAlign: 'left', height: 41,backgroundColor:'#f2a637' }}></Card>
      // </Col>
      // <Col span={4}>
      //   <Card title={'CLOSED :' + saleOrderData.filter(el => el.soStatus == "CLOSED").length} style={{textAlign: 'left', height: 41,backgroundColor:'#52c41a' }}></Card>
      // </Col>
      // <Col span={5}>
      //   <Card title={'CANCELLED :' + saleOrderData.filter(el => el.soStatus == "CANCELLED").length} style={{textAlign: 'left', height: 41,backgroundColor:'#f16565' }}></Card>
      // </Col>
      
    </Row> */}
        {/* <Col>
       <Card title={'Active: ' + saleOrderData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
      </Col>
      <Col>
       <Card title={'In-Active: ' + saleOrderData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
      </Col> */}
        <br></br>
        {showAbstract ? <Tabs type={'card'} tabPosition={'top'}>
          <TabPane
            key={saleOrderData.length}
            //  random color generator -- 'hsla(' + (Math.random() * 360) + ', 100%, 50%, 1)'
            tab={<span style={{ color: '#69c0ff', fontWeight: 5 }}>{'All' + ': ' + saleOrderData.length}</span>}
          >
            <Table
              rowKey={record => record.saleOrderId}
              columns={columnsSkelton}
              dataSource={saleOrderData}

              // expandIconColumnIndex={6}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              scroll={{ x: 5000, y: 500 }}
              size="large"
              bordered
            >
            </Table>

          </TabPane>
          {saleOrderData.filter((v, i, a) => a.findIndex(v2 => (v.endCustomerCode === v2.endCustomerCode)) === i).map((element, index) => {
            // let key = 1
            return <TabPane
              key={index}
              //  random color generator -- 'hsla(' + (Math.random() * 360) + ', 100%, 50%, 1)'
              tab={<span style={{ color: '#69c0ff', fontWeight: 5 }}>{element.endCustomerCode + ': ' + saleOrderData.filter(item => item.endCustomerCode === element.endCustomerCode).length}</span>}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={columnsSkelton}
                dataSource={saleOrderData.filter(i => i.endCustomerCode == element.endCustomerCode)}

                // expandIconColumnIndex={6}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: 5000, y: 500 }}
                size="large"
                bordered
              >
              </Table>

            </TabPane>

          })}


        </Tabs> : null}


      </Card >
    </>
  );
}


export default SaleDetailsReport;
