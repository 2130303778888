import React,{ useState, useEffect, useRef } from 'react';
import {  Table, Card,Input,Button,Row, Col, Form, InputNumber, FormInstance } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { StockService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';

export interface LocationWiseStockTransferProps{
    data:any;
    form: FormInstance<any>;
    StockTransferData: (req: any[], index:number) => void;
    index:number;
    closeModal: () => void;
}
export function LocationWiseStockTransfer(props:LocationWiseStockTransferProps){

  const [locationData,setLocationData] = useState<any[]>([])
  const service = new StockService();
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [selectedQty, setSelectedQty] = useState<number>(0);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const searchInput = useRef(null);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  const issuedQtyOnchange = (value, index, rowData) => {
    console.log(index);
    console.log(value.target.value);
    console.log(rowData);
    rowData.issuedQty = value.target.value
    const newData = [...locationData];
    newData[index].issuedQty = value.target.value;
    console.log(newData)
    setLocationData(newData);
    if (value.target.value === 0 || value.target.value === null || value.target.value < 0) {
      setBtnDisable(true)
      AlertMessages.getErrorMessage('Issued Quantity should be greater than zero')
    }
    if (Number(value.target.value) > Number(rowData.availableQty)) {
      setBtnDisable(true)
      AlertMessages.getErrorMessage('Issued Quantity should be less than Avaialble Quantity--')
    }
   
  }
    const sampleTypeColumns:ColumnsType<any>= [
       
        {
            title: 'Location',
            dataIndex:"location",
            align:'center',
            width:'10px',
           ...getColumnSearchProps('variantCode')
          },
        {
          title: 'Available Qty',
          dataIndex:"availableQty",
          align:'center',
          width:'10px',
          render: (value, rowData, index) => {
            return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
          },
        
        },
        {
            title: 'Issued Qty',
            dataIndex: 'issuedQty',
            width:'10px',
            align:'center',
            render: (text, rowData, index) => { 
              return (
                <>
                  <Form.Item
                    name={rowData.stockId}
                    rules={[
                      {
                        required: true, message: 'Missing issued Qty',
                        validator: (_, value) =>
                        value >= 0 ? Promise.resolve() : Promise.reject(new Error('Enter only positive values')),
                      }
      
                    ]}
                    style={{ margin: 0 }}
                  >
                  <InputNumber key={rowData.stockId} defaultValue={rowData.issuedQty >0 ? rowData.issuedQty : 0} name={`issuedQty${rowData.stockId}`} onBlur={(e)=>issuedQtyOnchange(e,index,rowData)} 
                   />
                  </Form.Item></>
              )
            }
        },
    ];
    useEffect(() =>{
        getStockDetailsForItem();
    },[props])

  const getStockDetailsForItem = () =>{
    service.getStockDetailsForItem(props.data).then(res =>{
      if(res.status){
        setLocationData(res.data);
      }else{
        setLocationData([]);
      }
    }).catch(err =>{
        setLocationData([]);
    })
  }
  let totalIssuedQty = 0;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
          console.log(selectedRowKeys);
          const selectedQtyFlag = new Set<boolean>()
          const shippedQtyFlag = new Set<boolean>()
          selectedRows.forEach((data,index) => {
            console.log(data.availableQty+"********"+index+"**********"+data.issuedQty);
            if (Number(data.issuedQty) > 0) {
              selectedQtyFlag.add(true)
            } else {
              selectedQtyFlag.add(false)
    
            }
            if (Number(data.issuedQty) > Number(data.availableQty)) {
              shippedQtyFlag.add(false)
              console.log('true cartons > orginal cartons')
            } else {
              shippedQtyFlag.add(true)
            }

            totalIssuedQty = Number(totalIssuedQty) + Number(data.issuedQty)
          })
          setSelectedQty(totalIssuedQty);
          setSelectedRowKeysData(selectedRowKeys);
          console.log(selectedRows);
          setSelectedRowsData(selectedRows);
          console.log(selectedRowsData);
          if (selectedRows.length > 0) {
            console.log('enter')
            console.log(totalIssuedQty);
            console.log(props.data);

              if (Number(totalIssuedQty) > Number(props.data.requiredQty)) {
                console.log("1");
                AlertMessages.getErrorMessage("Issued Quantity should be less than Required Quantity.");
                setBtnDisable(true);
              }
              else if ((selectedQtyFlag.has(false))) {
                console.log("2");

                AlertMessages.getErrorMessage("Uncheck the rows with no input");
                setBtnDisable(true);
              } 
              else{
                console.log("3");

                setBtnDisable(false);
              }
          }
          else{
            setBtnDisable(true);
          }
        },
      };

      const updateIssuedQty = () => {
        console.log(selectedRowsData);
        if(selectedRowsData.filter((res) => res.issuedQty > 0).length > 0){
          setBtnDisable(true)
          props.StockTransferData(selectedRowsData,props.index)
          props.closeModal();
        }
      }
    return (
        <>
            <Form layout={'vertical'} form={props.form} name="control-hooks" onFinish={updateIssuedQty}>
              <Row gutter={24}>
                <Col span={4}>
                  <Button type="primary" block disabled={btnDisable} htmlType={"submit"}>Transfer</Button>
                </Col>
              </Row>
            </Form>
            <br/>
            <Table
                rowSelection={{
                    ...rowSelection
                }}
                rowKey={record => record.stockId}
                columns={sampleTypeColumns}
                dataSource={locationData}
                bordered
                pagination={false}
                style={{ width: '100%' }}
            />
        </>
      );

}
export default LocationWiseStockTransfer;
