import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Select, Card, Col, DatePicker, Row, Form, Input, Descriptions } from 'antd';
import moment from 'moment';
import { GrnData, AntibiotsMicrobiologyInfo, GrnVehicleInfo } from '@gtpl/shared-models/raw-material-procurement';
import { RMGrnService, AntiBioticsReportService } from '@gtpl/shared-services/raw-material-procurement';
import TextArea from 'antd/lib/input/TextArea';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './anti-biotics-microbiology-form.css';
import { OperationTypeEnum, PlantsEnum } from '@gtpl/shared-models/common-models';
import { ProductDto } from '@gtpl/shared-models/masters';
import { ProductService } from '@gtpl/shared-services/masters';
import { ProductRequest } from '@gtpl/shared-models/masters';
import { UnitcodeService } from "@gtpl/shared-services/masters";
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { Capacitor } from '@capacitor/core';
import { LotCodeOpsData } from 'libs/shared-models/production-management/src/lib/lot-code-ops';
import {  LotNumberRequest } from '@gtpl/shared-models/production-management';
const { Option } = Select;
/* eslint-disable-next-line */
export interface AntiBioticsMicrobiologyFormProps {
  grnInfo: GrnData;
  
  handleCancel: () => void;
}

export function AntiBioticsMicrobiologyForm(
  props: AntiBioticsMicrobiologyFormProps
) {
  const [testingInfo] = Form.useForm();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [rmInfo, setRmInfo] = useState<GrnVehicleInfo>();
  const rmGrnService = new RMGrnService();
  const antiMicroTInfoService = new AntiBioticsReportService();
const [lotNumber,setLotNumber] = useState<LotCodeOpsData>();
  const [productData, setProductData] = useState<ProductDto>();
  const productService = new ProductService();

  
 
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitService = new UnitcodeService();

  useEffect(() => {
    getGrnInfoByItemId();
    
  }, [])


 


  const getGrnInfoByItemId = () => {
    rmGrnService.getGrnInfoByItemId({ grnItemId: props.grnInfo.grnItemId }).then(res => {
      if (res.status) {
        setRmInfo(res.data);
        const req = new ProductRequest(res.data.productId);
        productService.getActiveProductById(req).then(res => {
          if (res.status) {
            setProductData(res.data);
            testingInfo.setFieldsValue({
              productId: res.data?.productId,
              plantId: rmInfo?.plantId,
              productCode: res.data?.productCode,
              variety: res.data?.productName,
             
            })
          } else {
            AlertMessages.getErrorMessage("Product data is not retrieved" + res.internalMessage);
          }
        });
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });

    unitService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    });
  }
  
  const saveInfo = () => {
    testingInfo.validateFields().then(info => {
      info.plantId = Number(localStorage.getItem('unit_id'));
      info.createdUser = localStorage.getItem('createdUser');
      info.updatedUser = localStorage.getItem('createdUser');
      info.lotNumber = props.grnInfo.lotNumber?props.grnInfo.lotNumber:''
      console.log(saveInfo);
      limitValidator();
      antiMicroTInfoService.createAntibioticsInfo(info).then(res => {
        if (res.status) {
          props.handleCancel();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    });

  }

  const limitValidator = () => {
    if(testingInfo.getFieldValue('CAP') > testingInfo.getFieldValue('CAPL')){
      AlertMessages.getWarningMessage('CAP value should not exceed CAP Limit')
    }
    else if(testingInfo.getFieldValue('AOZ') > testingInfo.getFieldValue('AOZL')){
      AlertMessages.getWarningMessage('AOZ value should not exceed APZL Limit')
     }
    else if(testingInfo.getFieldValue('AMOZL') > testingInfo.getFieldValue('AMOZL')){
      AlertMessages.getWarningMessage('AMOZ value should not exceed AMOZL Limit')

  }
}
  return (
    <Card size="small" title={<span style={{ color: 'white' }} >InHouse AntiBiotic Test Information</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

      <Form
        layout="vertical"
        form={testingInfo}
      >
        <Row gutter={24}>
          <Form.Item name={'infoId'} style={{ display: "none" }}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'grnId'} initialValue={props.grnInfo.grnId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'grnItemId'} initialValue={props.grnInfo.grnItemId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'productId'}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'productCode'}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'plantId'}>
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Descriptions>
              <Descriptions.Item label="Product">
                {productData?.productCode}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Descriptions>
              <Descriptions.Item label="Variety">
                {rmInfo?.varietyOfMaterial}
              </Descriptions.Item>
            </Descriptions>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
          <Descriptions>
              <Descriptions.Item label="Lot Number">
                {props.grnInfo.lotNumber?props.grnInfo.lotNumber:' '}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label="Deheading Center" name={'deHeadingCenter'} rules={[
              {
                required: true,
                message: `Deheading Center is required`,
              },
            ]}>
              <Select
                placeholder="Select Deheading Center"
              >
                <Option key={'plant'} value={''}>Select Deheading Center</Option>
                {plantData.map(dropData => {
                  return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"sampleDetails"}
              label="Sample Details"
              rules={[
                {
                  required: true,
                  message: `Missing Sample Details`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label="Count" name={'count'} initialValue={props.grnInfo.count} rules={[
              {
                required: true,
                message: `Missing Count`,
              },
            ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label="Number of boxes" name={'noOfBoxes'} initialValue={props.grnInfo.boxes} rules={[
              {
                required: true,
                message: `Missing Number of boxes`,
              },
            ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item 
              name={"CAPL"}
              label="CAP(limit)"
              rules={
                [
                  { required: true, 
                        message: 'Missing Consumed Quantity.' }, 
                        { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }
                      ]
                    }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"CAP"}
              label="CAP (Limit - 0.1 ppb)"
              rules={[
                {
                  required: true,
                  message: `Missing CAP`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"AOZL"}
              label="AOZ (limit)"
              rules={[
                {
                  required: true,
                  message: `Missing AOZ`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"AOZ"}
              label="AOZ (Limit - 0.08 ppb)"
              rules={[
                {
                  required: true,
                  message: `Missing AOZ`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"AMOZL"}
              label="AMOZ (limit)"
              rules={[
                {
                  required: true,
                  message: `Missing AMOZ`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"AMOZ"}
              label="AMOZ (Limit - 0.4 ppb)"
              rules={[
                {
                  required: true,
                  message: `Missing AMOZ`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
       
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"SEM"}
              label="SEM (Limit - 0.43 ppb)"
              rules={[
                {
                  required: true,
                  message: `Missing SEM`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"AHD"}
              label="AHD (Limit - 0.40 ppb)"
              rules={[
                {
                  required: true,
                  message: `Missing AHD`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Don't Allow Spaces`
                }
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={24} >
          <Col>
            <Button type='primary' onClick={saveInfo} block disabled={btnDisable}>
              Submit
            </Button>
          </Col>
        </Row>

      </Form>
    </Card>
  );
}

export default AntiBioticsMicrobiologyForm;
