import React,{ useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EyeOutlined } from '@ant-design/icons';
import { IndentformService } from '@gtpl/shared-services/procurement';
import { Link, Redirect } from 'react-router-dom';
export interface IndentItemsGridProps{
    indentMaterialPreparationId:number;
}
export function IndentItemsGrid(props:IndentItemsGridProps){
  const [ItemsData,setItemsData] = useState<any[]>([])
  const service = new IndentformService();
  const [data, setData] = useState<number>(0);

    const sampleTypeColumns:ColumnsType<any>= [
       
        {
            title: 'Sub category',
            dataIndex:"itemsubcategory",
            align:'left',
          
          },
        {
          title: 'Item',
          dataIndex:"item",
          align:'left',
        
        },
      {
          title: 'Quantity',
          dataIndex:'quantity',
          align:'left',
      },
      {
        title: 'Receive Quantity',
        dataIndex:'receivedQuantity',
        align:'left',
    },
      // {
      //   title: `Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) => (
      //     <span>
      //       {(data) ? <Redirect to={ `/packing-stock-transfer/${data}` } /> : null}
      //       <EyeOutlined type="stock transfer"
      //         onClick={() => {
      //           setData(rowData);
      //         }}
      //         style={{ color: '#1890ff', fontSize: '14px' }}
      //       />
      //     </span>
      //   )
      // }
       
    ];
    useEffect(() =>{
        getitemsdata();
    },[props.indentMaterialPreparationId])

  const getitemsdata = () =>{
    service.getallindentitemDetails({indentMaterialPreparationId: props.indentMaterialPreparationId}).then(res =>{
      if(res.status){
        setItemsData(res.data);
      }else{
        setItemsData([]);
      }
    }).catch(err =>{
        setItemsData([]);
    })

  }
    return (
        <>
            <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 ,textAlign:'center'}} title={<span>Item Details</span>}></Card>
            <Table
                key={Date.now()}
                className="components-table-nested"
                columns={sampleTypeColumns}
                dataSource={ItemsData}
            />
      
        </>
      );

}
export default IndentItemsGrid;
