import { DocumentType } from "@gtpl/shared-models/common-models";

export class DocumentsList {
    documentsListId: number;
    saleOrderId: number;
    documentCategory: string;
    documentType: DocumentType;
    filePath:string;
    isUploaded: boolean;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    fileName: string;
    constructor(
        documentsListId: number,
        saleOrderId: number,
        documentCategory: string,
        documentType: DocumentType,
        filePath: string,
        isUploaded: boolean,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number,
        fileName: string
        ) {
        this.documentsListId = documentsListId;
        this.saleOrderId = saleOrderId;
        this.documentCategory = documentCategory;   
        this.documentType = documentType;   
        this.filePath = filePath;   
        this.isUploaded = isUploaded;   
        this.isActive = isActive;   
        this.createdAt = createdAt;   
        this.createdUser = createdUser;   
        this.updatedAt = updatedAt;   
        this.updatedUser = updatedUser;   
        this.versionFlag = versionFlag;   
        this.fileName = fileName;   
    }
    
}