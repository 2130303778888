export class SoViewDto {
    saleOrderId: number;
    customerName: string;
    endCustomerName: string;
    saleOrderNumber: string;
    status: string;
    expectedDate: string;
    isActive: boolean;

    constructor(saleOrderId: number,customerName:string,endCustomerName:string,saleOrderNumber:string,status:string,expectedDate: string,isActive: boolean){
      this.saleOrderId = saleOrderId;
      this.customerName = customerName;
      this.endCustomerName = endCustomerName;
      this.saleOrderNumber = saleOrderNumber;
      this.status = status;
      this.expectedDate = expectedDate;
      this.isActive = isActive;
    }
  }
  export const SoViewDtoDtoDefault : SoViewDto = {
    saleOrderId:0,
    customerName: '',
    endCustomerName: '',
    saleOrderNumber: '',
    status: '',
    expectedDate: '',
    isActive: true,

};