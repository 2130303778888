import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import {GrnService} from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Route, useHistory } from "react-router-dom";




import './grn-form.css';
import { GrnDetailViewModel, GrnDto, GrnIdRequest, GrnItemsInfo, PoDataModel, PoDropDownDto } from '@gtpl/shared-models/procurement-management';
import GrnDetailsForm from './grn-details-form';
import GrnItemsForm from './grn-items-form';
import moment from 'moment';
import {useLocation} from 'react-router-dom'


/* eslint-disable-next-line */
export interface GrnFormProps {
  // form:FormInstance<any>
  isUpdate: boolean;


}

  export function GrnForm(
    props: GrnFormProps
  )
  {
  let history = useHistory();

  let location = useLocation();
 

  const [grnDetailsForm] = Form.useForm();
  const [grnItemsForm] = Form.useForm();

  // const form = props.form;

  const [poNumbers, setPoNumbers] = useState<PoDropDownDto[]>([]);
  const [grnItemsdata, setGrnItemsdata] = useState<any[]>([]);
  const [updateState, setUpdateState] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<number>(0);


  const [poData, setPoData] = useState<PoDataModel>();
  const [grnDetails, setGrnDetails] = useState<GrnDetailViewModel>();
  const [grnItemsFormKeyUpdate, setGrnItemsFormKeyUpdate] = useState<number>(3);
  const [grnDetailsFormKey, setGrnDetailsFormKey] = useState<number>(2);
  const [testKey, setTestKey] = useState<number>(0);
  // const isUpdate = location.state.updateState;
  // setUpdateState(isUpdate)
  const grnId = location.state;
  // if(grnId){
  //   setUpdateState(true);

  // }
  // if(location.state){
  //   setUpdateState(true);
  // }
  


  const grnService = new GrnService();

  useEffect(()=>{
    if(grnId){
      getGrnData(grnId)
    }
    if(grnDetails){
    }
    // getPoNumbers()
  },[])

  const poDetails = (poData) => {
    setPoData(poData);
    setGrnItemsFormKeyUpdate(preStateval=>preStateval+1);

  }

  const getGrnData = (grnId) => {
    grnService.getGrnDataById(new GrnIdRequest(grnId)).then((res) => {

        if (res.status) {
            // form.setFieldsValue({
            //     vendorName: res.data.vendor.vendorName,
            //     contactPerson: res.data.vendor.contactPerson,
            //     mobileNumber: res.data.vendor.contactNumber,
            //     address: res.data.vendor.apartment + ',' + res.data.vendor.street + ',' + res.data.vendor.city + ',' + res.data.vendor.postalCode,
            //     totalCost: res.data.totalCost
            // })

            // // console.log(res.data);
            // setPoDetails(res.data.poCreationItemsInfo);
            setGrnDetails(res.data)
            setGrnDetailsFormKey(prevStateVal => prevStateVal+1)
            setGrnItemsFormKeyUpdate(prevStateVal => prevStateVal+2)
            // setShowTable(true);
        } else {

            setGrnDetails(null);
        }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setGrnDetails(null);
    });

}

  const grnItemDetails = (itemDetalFormdata) => {
    setGrnItemsdata(itemDetalFormdata)
  }

  const saveGrn =() =>{
    grnDetailsForm.validateFields().then(grnRes => {
    setBtnDisable(true);
    let grnItemSet = new Set<boolean>();
    for(const item of grnItemsdata){
      if(item.acceptedQuantity > 0){
        grnItemSet.add(true);
      }else{
        grnItemSet.add(false);
      }
    }
    if(!(grnItemSet.has(true))){
      AlertMessages.getErrorMessage('Please update accepted Quantity');

    }else{
      console.log(grnItemsdata)
      setGrnDetailsFormKey(prevState=>prevState+1)
      const grnData = grnDetailsForm.getFieldsValue();
      const grnDate = moment(grnData.grnDate).format('YYYY/MM/DD');
      const invoiceDate = moment(grnData.invoiceDate).format('YYYY/MM/DD');
    const grnItemsInfo: GrnItemsInfo[] = []
        grnItemsdata?.forEach(itemData => {
        grnItemsInfo.push(new GrnItemsInfo(undefined,itemData.itemCategory.itemCategoryId,itemData.itemName.itemId,itemData.size.sizeId,itemData.itemQuantity,itemData.acceptedQuantity,itemData.rejectedQuantity,itemData.receivedQuantity,itemData.itemCost,itemData.discountPer,itemData.discount,itemData.taxPer.taxId,itemData.tax,itemData.subAmount,localStorage.getItem('createdUser'),undefined,itemData.saleOrderId,itemData.itemSubCategory.itemSubCategoryId,itemData.itemName.isAsset,itemData.itemName.itemName))
      })
      
  
      const grnDto = new GrnDto(undefined,grnDate,invoiceDate,undefined,grnData.invoiceNumber,grnData.poId,poData.plantId,grnData.saleOrderId,poData.totalQuantity,poData.totalDiscount,poData.totalTax,grnData.totalCost,grnData.remarks,'Open',undefined,localStorage.getItem('createdUser'),undefined,grnData.vendorId,grnItemsInfo,undefined,grnData.locationId);
      
      console.log(grnDto)
      grnService.createGrn(grnDto).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('GRN created successfully');
          // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
          history.push("/grn-grid");
          // onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
            setBtnDisable(false);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setBtnDisable(false);
          }
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
    }

    })

  }

  const priceData = async (totalAmount) => {
    setTotalCost(totalAmount);
    await setGrnDetailsFormKey(prevState=>prevState-1)
    await setGrnDetailsFormKey(prevState=>prevState+1)
  }
 

  return (
    <>
    <GrnDetailsForm key= {grnDetailsFormKey} form={grnDetailsForm} poDetails={poDetails} isUpdate={grnId?true:false} grnDetails={grnDetails?grnDetails:null} totalCost={totalCost?totalCost:0}/>
    <GrnItemsForm grnItemDetails={grnItemDetails} key = {grnItemsFormKeyUpdate} form={grnItemsForm} poData = {poData} grnItemsData={grnDetails?.grnItemsInfo} isUpdate={grnId?true:false} PriceData={priceData}/>
    <br />
      <Row justify="end">
        <Col>
          <Form.Item >

            <br />
            {/* <Link to={`/saleorder-view`}> */}
            <Button type='primary'  block onClick={saveGrn} disabled = {btnDisable}>
              Submit
            </Button>
            {/* </Link> */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default GrnForm;
