
export class GrnReturnDto {
    grnId: number;
    grnNumber: string;
    grnReturnNumber: string;
    grnReturnDate: Date;  
    itemCategoryName: string;
    itemName: string;
    returnQuantity: number;
    unitPrice:number;
    totalValue:number;
    status:string;

    /**
     * 
     * @param grnId 
     * @param grnNumber 
     * @param grnReturnNumber 
     * @param grnReturnDate 
     * @param itemCategoryName 
     * @param itemName 
     * @param returnQuantity 
     * @param unitPrice 
     * @param totalValue 
     * @param status 
     */
    constructor(grnId: number,
        grnNumber: string,
        grnReturnNumber: string,
        grnReturnDate: Date,  
        itemCategoryName: string,
        itemName: string,
        returnQuantity: number,
        unitPrice:number,
        totalValue:number,
        status:string){
        this.grnId = grnId;
        this.grnNumber = grnNumber;
        this.grnReturnNumber = grnReturnNumber;
        this.grnReturnDate = grnReturnDate;  
        this.itemCategoryName = itemCategoryName;
        this.itemName = itemName;
        this.returnQuantity = returnQuantity;
        this.unitPrice = unitPrice;
        this.totalValue = totalValue;
        this.status = status;
    }
  }