import axios from 'axios';
import { QaQcData, QaQcResponse, GetAllQaQcTestsResponse, OperationRequest, QaQcTestsDropDownResponse, OperationIdRequest, QaQcTestIdRequest } from '@gtpl/shared-models/production-management';
import connection from './connection';

export class QaQcOperationsService {
    URL = connection.DEPLOY_URL + '/qa-qc-operations';

    async createQaQcTest(qaqc: QaQcData): Promise<QaQcResponse> {
        console.log(qaqc);
        return await axios.post(this.URL + '/createQaQcTest', qaqc)
            .then(res => {
                return res.data
            })
    }
    async updateQaQcTest(qaqc: QaQcData): Promise<QaQcResponse> {
        return await axios.post(this.URL + '/updateQaQcTest', qaqc)
            .then(res => {
                return res.data
            })
    }

    async getAllQcQcTests(): Promise<GetAllQaQcTestsResponse> {
        return await axios.post(this.URL + '/getAllQcQcTests')
            .then(res => {
                return res.data
            })
    }
    async getAllQcQcTestsForOperation(req: OperationRequest): Promise<GetAllQaQcTestsResponse> {
        return await axios.post(this.URL + '/getAllQcQcTestsForOperation', req)
            .then(res => {
                return res.data
            })
    }
    async activateOrDeactivateQaQcTest(qaqc: QaQcData): Promise<GetAllQaQcTestsResponse> {
        console.log(qaqc);
        return await axios.post(this.URL + '/activateOrDeactivateQaQcTest', qaqc)
            .then(res => {
                return res.data
            })
    }

    async getAllQcQcDropDownForOperation(req: OperationIdRequest): Promise<QaQcTestsDropDownResponse> {
        return await axios.post(this.URL + '/getAllQcQcDropDownForOperation', req)
            .then(res => {
                return res.data
            })
    }
    async getTestDataForTestId(req: QaQcTestIdRequest): Promise<QaQcTestsDropDownResponse> {
        return await axios.post(this.URL + '/getTestDataForTestId', req)
            .then(res => {
                return res.data
            })
    }
    async getAllQcQcActiveTests(): Promise<GetAllQaQcTestsResponse> {
        return await axios.post(this.URL + '/getAllQcQcActiveTests')
            .then(res => {
                return res.data
            })
    }

}