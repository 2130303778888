import axios from "axios";
import { FgInspectionDto, FgInspectionResponse, AllFgInspectionsResponse, FgInspecReq } from "@gtpl/shared-models/production-management";
import { connection } from "..";

export class FgInspecService{
    getEvaluation(req: FgInspecReq) {
      throw new Error('Method not implemented.');
    }

    URL = connection.DEPLOY_URL + '/fg-inspection';

    async create(dto: FgInspectionDto): Promise<FgInspectionResponse>{
        return await axios.post(this.URL+ '/createFgInspection', dto)
            .then(res => {
                return res.data
            })
    }

    async update(dto: FgInspectionDto): Promise<FgInspectionResponse>{
        return await axios.post(this.URL+ '/updateFgInspectionInfo', dto)
            .then(res => {
                return res.data
            })
    }

    async getAll(): Promise<AllFgInspectionsResponse>{
        return await axios.post(this.URL+ '/getAllFgInspections')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveInfo(): Promise<AllFgInspectionsResponse>{
        return await axios.post(this.URL+ '/getAllActiveFgInspections')
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateInspection(dto: FgInspectionDto): Promise<FgInspectionResponse>{
        return await axios.post(this.URL+ '/activateOrDeactivateInspection', dto)
            .then(res => {
                return res.data
            })
    }

    async createFgInspectionInfo(dto:FgInspectionDto):Promise<FgInspectionResponse>{
        return await axios.post(this.URL+'/createFgInspectionInfo',dto)
          .then(res =>{
              return res.data
          })
    }

    async getAllFgInspectionSamplesForLabelId(dto: FgInspecReq): Promise<FgInspectionResponse> {
        return await axios.post(this.URL + '/getAllFgInspectionSamplesForLabelId', dto)
            .then(res => {
                return res.data
            })
    }

}