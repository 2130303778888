export class TypeOfValueAddition4Dto{
    valueAdditionFourId:number;
    code:string;
    name:string;
    remarks:string;
    createdUser:string;
    updatedUser:string;

    isActive:boolean;
    
    /**
     * 
     * @param valueAdditionFourId number
     * @param code string
     * @param name string
     * @param remarks string
     * @param createdUser string
     * @param updatedUser string
     * 
     * @param isActive boolean
     */
    constructor(valueAdditionFourId:number,code:string,name:string,remarks:string,createdUser:string,updatedUser:string,isActive:boolean){
    this.valueAdditionFourId = valueAdditionFourId;
    this.code = code;
    this.name = name;
    this.remarks = remarks;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;

    this.isActive = isActive
    }
}