import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col, DatePicker, InputNumber, AutoComplete, Descriptions, TimePicker, Radio, Table  } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {  Link, useHistory } from "react-router-dom";
import {BlanchingCookingDto, CookingDto, LogIdRequest, ValAddGridDto, ValAddInventoryDto} from '@gtpl/shared-models/production-management'
import {BlanchingCookingService} from '@gtpl/shared-services/production'
import moment from 'moment';

import './blanching-cooking-form.css';

/* eslint-disable-next-line */
export interface BlanchingCookingFormProps {
  isUpdate:boolean
  blanchingData : CookingDto;
  
}

export function BlanchingCookingForm(
  props: BlanchingCookingFormProps
) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [dataSource, setDataSource] = useState<BlanchingCookingDto[]>(undefined);
  const plainOptions = ['Blanching','Cooking']
  const [certification, setCertification] = useState<boolean>(false);
  const blanchingCookingservice = new BlanchingCookingService;
  const [page, setPage] = React.useState(1);
  const columns = [
    {
      title: 'Sample',
      dataIndex: 'sample',
      key: 'sample',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (value) => {
        return moment(value).format("h:m A");
      }
    },
    {
      title: 'Blanching/Cooking',
      dataIndex: 'blanchingCooking',
      key: 'blanchingCooking',
    },
    {
      title: 'Temp(C1)',
      dataIndex: 'temp1',
      key: 'temp1',
    },
    {
      title: 'Temp(C2)',
      dataIndex: 'temp2',
      key: 'temp2',
    },
    {
      title: 'Temp(C3)',
      dataIndex: 'temp3',
      key: 'temp3',
      width : '50px'
    },
    {
      title: 'Cooking Time',
      dataIndex: 'cookingTime',
      key: 'cookingTime',
      render: (value,record :BlanchingCookingDto ) => {return moment(record.cookingTime).format('HH:MM:SS')}
    },
    {
      title: 'Product Core Temp(c)',
      dataIndex: 'productCoreTemp',
      key: 'productCoreTemp',
    },
    {
      title: 'Product Temp(c) after Chilling',
      dataIndex: 'productTempAfterChilling',
      key: 'productTempAfterChilling',
    },
    {
      title: 'Steam Pressure',
      dataIndex: 'steamPressure',
      key: 'steamPressure',
    },

    {
      title: 'Chill Water Temp(c)',
      dataIndex: 'chillWaterTemp',
      key: 'chillWaterTemp',
    },
    {
      title: 'Chiller self speed time',
      dataIndex: 'chillerTime',
      key: 'chillerTime',
      render: (value,record :BlanchingCookingDto ) => {return moment(record.chillerTime).format('HH:MM:SS')}
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    
    
  ];
  
  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
    const onReset=()=>{
    form.resetFields();
   }

   useEffect (() => {
   form.setFieldsValue({
          logId : props.blanchingData.productionLogId
        });
      getSamplesInfoByLogId();
   },[])
   
   const createBlanchingCooking=(data: BlanchingCookingDto)=>{
    console.log(props.blanchingData.productionLogId)
    blanchingCookingservice.createBlanchingCooking(data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');
        getSamplesInfoByLogId();
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
   
  }

  const getSamplesInfoByLogId = () => {
    const req = new LogIdRequest(props.blanchingData.productionLogId);
    blanchingCookingservice.getSamplleInfoByLogId(req).then(res => {
      if (res.status) {
        setDataSource(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  

  const saveData = (values: BlanchingCookingDto) => {
   
      createBlanchingCooking(values);
    }
  
    const certificationChange = (value) => {
      if (value) {
    
      }
    }

  return (
    <>
    <Card title={<span style={{color:'white'}}>BLANCHING & COOKING</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} >
          <Form form={form}        
           name="control-hooks"          
          layout="vertical"
          >
             <Row gutter={24}>
          <Descriptions>
          {/* <Descriptions.Item label="SO Number">
              {props.blanchingData.valAddInventory}
            </Descriptions.Item> */}
             <Descriptions.Item label="SO Number">
              {props.blanchingData?.soNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Job Number">
              {props.blanchingData?.jobNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Lot Number">
              {props.blanchingData?.lotNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {props.blanchingData?.physicalQuantity}
            </Descriptions.Item>
           

            
          </Descriptions>
        </Row>
    </Form>
    </Card>
    <Card size="small" title={<span style={{ color: 'white' }}>Samples Form</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
    <Form
      layout="vertical"
      onFinish={saveData}  
      form = {form}
    >
          <Form.Item name="cookingId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item name="logId" style={{display:"none"}} >
            <Input />
          </Form.Item>
            <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
            <Input hidden/>
          </Form.Item>
        <Row gutter={24}>
            {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name={"sampleNo"}
                label="Sample No"
                rules={[
                  {
                    required: true,
                    message: `Missing Sample No`,
                  },
                ]}
                initialValue={dataSource.length + 1}
              >
                <InputNumber min={0} style={{ width: '100%' }} disabled />
              </Form.Item>
            </Col> */}
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="time"
                      label="Time"
                      initialValue={moment()}
                      rules={[
                        {
                          required: true,
                          message: `Time is required`,
                        },
                      ]}
                      >
                      <TimePicker use12Hours format="h:mm a" style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
         
			 
		        
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"blanchingCooking"}
                    rules={[
                      {
                        required: true,
                        message: `Section`,
                      },
                    ]}
                  >
                    <Radio.Group  options={plainOptions} onChange={certificationChange} value='Cooking' style={{paddingTop:'30px'}}>
                      <Radio  value={4}>
                        More...
                        {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="temp1"
                      label="Temp (C-1)"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="temp2"
                      label="Temp (C-2)"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="temp3"
                      label="Temp (C-3)"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="cookingTime"
                      label="Cooking Time"
                      rules={[
                        {
                          required: true,
                          message: `Missing Cooking Time`,
                        },
                      ]}
                    >
                      <TimePicker style={{width:'100%'}} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="productCoreTemp"
                      label="Product Core Temp(in C)"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="productTempAfterChilling"
                      label="Product Temp(in C) after chilling"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="steamPressure"
                      label="Steam Pressure"
                      rules={[
                        {
                          required: true,
                          message: `Missing steam pressure`,
                        },
                      ]}

                      >
                      <Input/>
                    </Form.Item>
            </Col> 
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="chillWaterTemp"
                      label="Chill water Temperature(in C)"
                      rules={[
                        {
                          required: true,
                          message: `Missing Temperature`,
                        },
                      ]}
                    >
                      <InputNumber min={0}  style={{ width: '100%' }} />
                    </Form.Item>
            </Col> 
          
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="chillerTime"
                      label="Chiller self speed time"
                      rules={[
                        {
                          required: true,
                          message: `Missing Time`,
                        },
                      ]}
                    >
                      <TimePicker  style={{ width: '100%' }}/>
                    </Form.Item>
            </Col> 
           
			
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="remarks"
                      label="Remarks" 
                      >
                      <Input/>
                    </Form.Item>
            </Col>

            {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                  <Form.Item
                      name="correctiveAction"
                      label="CorrectiveAction" 
                      rules={[
                        {
                          required: true,
                          message: `Missing Corrective Action`,
                        },
                      ]}>
                      <Input/>
                    </Form.Item>
            </Col> */}
            
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
                {(props.isUpdate !== true ) && 
           <Button htmlType="button" style = {{margin: '0 14px'}} onClick={onReset}>
             Reset
           </Button>
          }
                </Col>
              </Row>
              <Row gutter={24}>
            <Table dataSource={dataSource} columns={columns} scroll={{ x: true }}  rowKey={(record) => { return record.cookingId}} />
          </Row>
          </Form>
        </Card>
        </>
  );
}

export default BlanchingCookingForm;
