import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch,Modal, Form, InputNumber, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Empty, Tabs } from 'antd';
import { SubPlantHistoryDto, SubPlantOperationsDto } from '@gtpl/shared-models/production-management';
import { SubPlantCodesEnum, PlantsEnum, OperationTypeEnum } from '@gtpl/shared-models/common-models';
import { SubPlantOperationsService } from '@gtpl/shared-services/production';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import './sub-plant-operations-grid.css';
import Link from 'antd/lib/typography/Link';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 

/* eslint-disable-next-line */
export interface SubPlantOperationsGridProps { }

export function SubPlantOperationsGrid(
  props: SubPlantOperationsGridProps
) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { TabPane } = Tabs;
  const [saleId, setSaleId] =useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [subPlantData, setSubPlantData] = useState<SubPlantHistoryDto[]>([]);
  const plantService = new SubPlantOperationsService();
  
  useEffect(() => {
    getAllSubPlantOperations();
  }, []);

  
  

  const getAllSubPlantOperations = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    plantService.getAllSubPlantOperations(req).then(res => {
      if (res.status) {
        setSubPlantData(res.data);
      } else {
        if (res.intlCode) {
          setSubPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSubPlantData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteplantsubPlant = (subPlantData: SubPlantOperationsDto) => {

  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleCancel =()=>{
    getAllSubPlantOperations();
    setIsModalVisible(false);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const opBoxes = (value, index, rowData) => {

  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'From Plant',
      dataIndex: 'plantName',
      // responsive: ['lg'],
      sorter: (a, b) => a.plantName.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName')
    },
    {
      title: 'To Plant',
      dataIndex: 'subPlantName',
      // responsive: ['lg'],
      sorter: (a, b) => a.subPlantName.localeCompare(b.subPlantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('subPlantName')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Quantity',
      dataIndex: 'inputQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right' }}>{value}</div>
      }
      // responsive: ['lg'],
    },
    {
      title: 'WIP Quantity',
      dataIndex: 'wipQuantity',
      ...getColumnSearchProps('wipQuantity'),
      render: (value) => {
        return <div style={{ textAlign: 'right' }}>{value}</div>
      }
      // responsive: ['lg'],

    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   responsive:['md'],
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
      // render: (value, record) => 
      //       <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
            <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'inputBoxes',
      render: (value) => {
        return <div style={{ textAlign: 'right' }}>{value}</div>
      }
      //responsive: ['lg'],
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'outputBoxes',
      render: (value) => {
        return <div style={{ textAlign: 'right' }}>{value}</div>
      }
    },
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Operations History</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <br />
      <Tabs type={'card'} tabPosition={'top'}>
      <TabPane key='1'
          tab={<span style={{ color: '#52c41a' }}>GRN: {subPlantData.filter(res => res.operation === OperationTypeEnum.GRN).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.GRN)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='2'
          tab={<span style={{ color: '#52c41a' }}>Deheading: {subPlantData.filter(res => res.operation === OperationTypeEnum.BE_HEADING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.BE_HEADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='3'
          tab={<span style={{ color: '#d4b106' }}>Grading: {subPlantData.filter(res => res.operation === OperationTypeEnum.GRADING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.GRADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='4'
          tab={<span style={{ color: '#f5222d' }}>Value Addition: {subPlantData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='5'
          tab={<span style={{ color: '#08979c' }}>Soaking: {subPlantData.filter(res => res.operation === OperationTypeEnum.SOAKING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.SOAKING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='6'
          tab={<span style={{ color: 'blue' }}>Freezing: {subPlantData.filter(res => res.operation === OperationTypeEnum.FREEZING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={subPlantData.filter(res => res.operation === OperationTypeEnum.FREEZING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
      </Tabs>
  <Modal 
     className ='so-info-view'
     key={'model'+ Date.now()}
     width={'80%'}
     style={{top:30, alignContent: 'right'}}
     visible={isModalVisible}
     title={<React.Fragment></React.Fragment>}
     onCancel={handleCancel}
     footer={[]}  
  >
      <SoInfoViewPages saleOrderId={saleId} screen='production' />
  </Modal>


    </Card>
  );
}

export default SubPlantOperationsGrid;

