import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { GrnService,PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { ItemCategoryService, ItemsService, SizesService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { MinusCircleOutlined, PlusOutlined,EditOutlined } from '@ant-design/icons';



const { Option } = Select;

import './grn-form.css';
import { grnItemsDataModel, PoDataModel, PoDropDownDto } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { ItemCategoriesDto, ItemsDropDownDto, ItemsReqForCategoryId, ItemSubCategoriesDto, SizesDropDownDto } from '@gtpl/shared-models/masters';
import { EmployeeRolesEnum, SaleOrderStatusEnum } from '@gtpl/shared-models/common-models';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';

/* eslint-disable-next-line */
export interface GrnItemsFormProps {
  grnItemDetails: (itemDetalFormdata: any) => void;
  form: FormInstance<any>
  poData:PoDataModel
  grnItemsData:grnItemsDataModel[]
    isUpdate: boolean;
    PriceData:(totalAmount:any) => void;


}



export function GrnItemsForm(
  props: GrnItemsFormProps
) {
  const form = props.form;

  const [itemCategories, setItemCategories] = useState<ItemCategoriesDto[]>([]);
  const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [itemSubCategories, setItemSubCategories] = useState<ItemSubCategoriesDto[]>([]);
  const [tableIndex, settableIndex] = useState<number>(null);
  const [tableData, setTableData] = useState<any[]>([
    {acceptedQuantity:0},{rejectedQuantity:0},{receivedQuantity:0}
  ]);
  const [newTableData,setNewTableData] = useState<PoDataModel[]>([])
  const [showTable, setShowTable] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [transfferedSos, setTransferredSos] = useState<any[]>([]);
  const grnService = new GrnService();
  const itemCategoryService = new ItemCategoryService();
  const itemService = new ItemsService();
  const sizeService = new SizesService();
  const saleService = new SaleOrderService();


  const role = JSON.parse(localStorage.getItem('role'));

  useEffect(()=>{
    // if(tableData.length>0){

      let totalAmount = 0;
      tableData.forEach(tableItem => {
        totalAmount += Number(tableItem.subAmount) ? Number(tableItem.subAmount) : 0;
      })
      props.PriceData(totalAmount);
    // }

console.log(tableData,'tableData')
  },[tableData])

  useEffect(() => {
    getTransfferdSos();
    getSizes()
    getItemCategories()
    if(props.poData){
      setShowTable(true)
      console.log(props.poData)
      // const newEleemet = [{acceptedQuantity:0},{rejectedQuantity:0}]
      // const poData = props.poData.poCreationItemsInfo;
      // const updatedArray = [...poData, {acceptedQuantity:0,rejectedQuantity:0}];
      setTableData(props.poData.poCreationItemsInfo)
      props.grnItemDetails(props.poData.poCreationItemsInfo);

      // setTableData(updatedArray)
      // let object = [...tableData,acceptedQuantity:0]
    }
    if(props.isUpdate){
      console.log(props.isUpdate)
      console.log(props.grnItemsData)
      // const dynTableData = [...tableData,props.grnItemsData];
      setTableData(props.grnItemsData);
      props.grnItemDetails(props.grnItemsData);
      setShowTable(true)
    }
  }, [])

  const onAddProduct = () => {
    form.validateFields().then(res => {

    console.log(form.getFieldValue('totalAmount'));
    // console.log(form.getFieldValue('acceptedQuantity'));

      let dynTableData = [...tableData]
      dynTableData[tableIndex].acceptedQuantity=form.getFieldValue('acceptedQuantity')
      dynTableData[tableIndex].rejectedQuantity=form.getFieldValue('rejectedQuantity')
      dynTableData[tableIndex].receivedQuantity=form.getFieldValue('receivedQuantity')
      dynTableData[tableIndex].discount=form.getFieldValue('discountAmount')
      dynTableData[tableIndex].tax=form.getFieldValue('taxAmount')
      dynTableData[tableIndex].subAmount=form.getFieldValue('totalAmount')
      // // dynTableData[tableIndex]=fieldsData
      console.log(dynTableData);
      setShowTable(true);
      setTableData(dynTableData);
      props.grnItemDetails(dynTableData);

      onReset();
    })
    
  }

  const getTransfferdSos = () => {
    saleService.getTransferredSaleOrders().then((res) => {
      if (res.status) {
        setTransferredSos(res.data)
      } else {
        setTransferredSos([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const setTransferData = () => {
    setNewTableData(tableData?.map(element => {
      transfferedSos.find(rec => {
        rec.saleOrderId == element.saleOrderId;
        if (rec.saleOrderId == element.saleOrderId) {
          element.isTransferred = true
        }
      });
      return element;
    }))
  }

  useEffect(() => {
   setTransferData()
  },[transfferedSos,tableData])
 console.log(newTableData,'new table data')

const setEditForm = (rowData,index) =>{
  console.log(rowData,index)
  let setAcceptedQty = rowData.acceptedQuantity*Number(rowData.itemCost)
  if(!(rowData.acceptedQuantity>0)){
    setAcceptedQty = Number(rowData.itemQuantity-rowData.prevAcceptedQty)*Number(rowData.itemCost);
  }
  console.log(setAcceptedQty)
  const setDiscAmnt = setAcceptedQty*Number(rowData.discountPer/100)
  console.log(setDiscAmnt)

  const setTaxAmnt = (setAcceptedQty-setDiscAmnt)*Number(rowData.taxPer.taxPercentage/100)
  console.log(setTaxAmnt)

  const setTotalAmnt = (setAcceptedQty-setDiscAmnt)+setTaxAmnt;
  console.log(setTotalAmnt)

  // const setTotalAmnt = Number(setAcceptedQty-setDiscAmnt+setTaxAmnt)
  console.log(setTotalAmnt)

  form.setFieldsValue({
    itemCategoryId:rowData.itemCategory.itemCategory,
    itemSubCategoryId:rowData.itemSubCategory.itemSubCategory,
    itemId:rowData.itemName.itemName,
    sizeId:rowData.size.sizeName,
    acceptedQuantity:rowData.acceptedQuantity>0?rowData.acceptedQuantity:(rowData.itemQuantity-rowData.prevAcceptedQty),
    rejectedQuantity:rowData.rejectedQuantity?rowData.rejectedQuantity:0,
    receivedQuantity:props.isUpdate?rowData.receivedQuantity:rowData.receivedQuantity>0?rowData.receivedQuantity:rowData.itemQuantity-rowData.prevAcceptedQty,
    poQuantity:rowData.itemQuantity,
    unitPrice:rowData.itemCost,
    taxAmount:rowData.acceptedQuantity>0 ? Number((rowData.tax).toFixed(2)) : Number(setTaxAmnt.toFixed(2)),
    discountPercentage:rowData.discountPer,
    taxPercentage:rowData.taxPer.taxPercentage,
    transportation:rowData.transportation,
    discountAmount:rowData.acceptedQuantity>0 ? Number((rowData.discount).toFixed(2)) : Number(setDiscAmnt.toFixed(2)),
    totalAmount:rowData.acceptedQuantity>0 ? Number((rowData.subAmount).toFixed(2)) : Number(setTotalAmnt.toFixed(2))

  })
  settableIndex(index);
  setBtnDisable(false);
  // tableData[index] = 
}


  const getSizes = () => {
    sizeService.getAllSizesDropDown().then((res) => {
      console.log('test')

      if (res.status) {
        // console.log(res.data);
        setSizes(res.data);
      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }

  const deleteData = (index) => {
    console.log(index)
    tableData.splice(index,1);
    setTableData([...tableData]);
    if(tableData.length == 0){
      setShowTable(false)
    }
  }
  const getItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then((res) => {
      console.log('test')

      if (res.status) {
        // console.log(res.data);
        setItemCategories(res.data);
      } else {

        setItemCategories([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategories([]);
    });
  }

  const getItems = (val) => {
    const itemsRequest = new ItemsReqForCategoryId(val);
    itemService.getItemByCategoryId(itemsRequest).then((res) => {

      if (res.status) {
        // console.log(res.data);
        setItems(res.data);
      } else {

        setItems([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItems([]);
    });
  }

  const onReset = () => {
    form.resetFields();
  };

  const savevalue = (e) => {
    console.log(e.target.value)
   
  }

  const validateQty =  (e) => {
    const val = e.target.value
    console.log(val);
    console.log(form.getFieldValue('poQuantity'))
    let po_qty = form.getFieldValue('poQuantity')
    let valExcessPer = Number(po_qty*3/100)
    let excessPoVal = Number(parseFloat(po_qty)+valExcessPer);
    console.log(valExcessPer)
    console.log(excessPoVal)
    //need to remove this validation for manager level
    if(!(role == EmployeeRolesEnum.ADMIN || role == EmployeeRolesEnum.HO_PACKAGING_MANAGER)){
      if(Number(val) <= (Number(excessPoVal))){
        setRejectedQty();
      }else{
        AlertMessages.getErrorMessage('Received Qty should not exceed PO Qty');
        form.setFieldsValue({receivedQuantity:null})
        form.setFieldsValue({rejectedQuantity:0})
      }
    }
  }

  const validateAcceptedQty = (e) => {
    const val = e.target.value
    console.log(val);
    console.log(form.getFieldValue('poQuantity'))
    if(Number(val) <= Number(form.getFieldValue('receivedQuantity'))){
      const acceptedQty = val;
      const unitPrice = Number(form.getFieldValue('unitPrice'))
      const ItemsCost = acceptedQty*unitPrice;
      const itemDiscountAMount = ItemsCost*Number(form.getFieldValue('discountPercentage'))/100;
      const itemTaxAMount = (ItemsCost-itemDiscountAMount)*Number(form.getFieldValue('taxPercentage'))/100;
      const itemTransportation = Number(form.getFieldValue('transportation'));
      const itemTotCost = (ItemsCost-itemDiscountAMount)+itemTaxAMount+itemTransportation;
      form.setFieldsValue({
        discountAmount : itemDiscountAMount,
        taxAmount : itemTaxAMount,
        totalAmount : itemTotCost,
      })
      setRejectedQty();
    }else{
      AlertMessages.getErrorMessage('Accepted Qty should not exceed Received Qty');
      form.setFieldsValue({acceptedQuantity:null})
      form.setFieldsValue({rejectedQuantity:0})
    }
  }

  const setRejectedQty = () =>{
    if(form.getFieldValue('receivedQuantity')>=0 &&form.getFieldValue('acceptedQuantity') >= 0){
      const rejQty = form.getFieldValue('receivedQuantity')-form.getFieldValue('acceptedQuantity');
      form.setFieldsValue({rejectedQuantity:rejQty})
    }else{
      form.setFieldsValue({rejectedQuantity:0})
    }
  }

  const columns = [
    // {
    //   title: 'Brand',
    //   dataIndex: 'brand',
    //   render: (text, data, index) => {
    //     console.log(text, data, index)
    //     return <span>{data.brand.children}</span>
    //   }
    // },
    {
      title: 'Item Category',
      dataIndex: 'itemCategory',
      key: 'itemCategory',
      render: (text, data, index) => {
        console.log(data);
        return <span>{data.itemCategory?.itemCategory}</span>
      }
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategory',
      key: 'itemSubCategory',
      render: (text, data, index) => {
        console.log(data);
        return <span>{data.itemSubCategory?.itemSubCategory}</span>
      }
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      render: (text, data, index) => {
        return <span>{data.itemName?.itemName}</span>
      }
    },
    {
      title: 'Size',
      dataIndex: 'size',
      render: (text, data, index) => {
        return <span>{data.size?.sizeName}</span>
      }
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   render: (text, data, index) => {
    //     return <span style={{color:(data.soStatus === SaleOrderStatusEnum.CANCELLED ? 'Red': 'Black')}}>{data.soStatus === SaleOrderStatusEnum.CANCELLED ? (data.soNumber+' - CANCELLED') : (data.isTransferred == true?  data.soNumber+ '-TRANSFERRED' : data.soNumber) }</span>
    //   }
    // },
    {
      title: 'PO number',
      dataIndex: 'poNumber',
      render: (text, data, index) => {
        return <span style={{color:(data.soStatus === SaleOrderStatusEnum.CANCELLED ? 'Red': 'Black')}}>{data.soStatus === SaleOrderStatusEnum.CANCELLED ? (data.poNumber+' - CANCELLED') : (data.isTransferred == true?  data.poNumber+ '-TRANSFERRED' : data.poNumber) }</span>
      }
    },
    {
      title: 'PO Qty',
      dataIndex: 'itemQuantity',
      render: (text, data, index) => {
        return <span>{Number((data.itemQuantity))}</span>
      }
    },
    // {
    //   title: 'PO Amount',
    //   dataIndex: 'soNumber',
    //   render: (text, data, index) => {
    //     console.log(text, data, index)
    //     return <span>{data.subAmount}</span>
    //   }
    // },

    {
      title: 'Previous Qty',
      dataIndex: 'prevAcceptedQty',
      render: (text, data, index) => {
        return <span>{Number(data.prevAcceptedQty?data.prevAcceptedQty:0)}</span>
      }
    },

    {
      title: 'Received Qty',
      dataIndex: 'receivedQuantity',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{Number(data.receivedQuantity?data.receivedQuantity:0)}</span>
      }
    },

    {
      title: 'Accepted Qty',
      dataIndex: 'acceptedQuantity',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{Number(data.acceptedQuantity?data.acceptedQuantity:0)}</span>
      }
    },

    {
      title: 'Rejected Qty',
      dataIndex: 'rejectedQuantity',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{Number(data.rejectedQuantity?data.rejectedQuantity:0)}</span>
      }
    },

    {
      title: 'Unit Price',
      dataIndex: 'itemCost',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{Number((data.itemCost))}</span>
      }
    },

    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${Number((data.discount))} ( ${Number((data.discountPer))}% )`}</span>
      }
    },

    {
      title: 'Tax',
      dataIndex: 'tax',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${Number((data.tax))} ( ${Number((data?.taxPer?.taxPercentage))}% )`}</span>
      }
    },

    {
      title: 'Transportation',
      dataIndex: 'transportation',
      key:'transportation',
      render: (text, data, index) => {
        console.log(text, data, index)
        return <span>{`${Number(text)}`}</span>
      }
    },

    {
      title: 'GRN Amount',
      dataIndex: 'subAmount',
      render: (text, data, index) => {
        console.log(text, data, index)
        // let setAcceptedQty = 0
        // if((data.acceptedQuantity>0)){
        //   setAcceptedQty = Number(data.itemQuantity-data.prevAcceptedQty);
        // }
        // console.log(setAcceptedQty)
        // const setDiscAmnt = setAcceptedQty*Number(data.itemCost)*Number(data.discountPer/100)
        // console.log(setDiscAmnt)

        // const setTaxAmnt = (setAcceptedQty-setDiscAmnt)*Number(data.taxPer.taxPercentage/100)

        // const setTotalAmnt = (setAcceptedQty-setDiscAmnt)+setTaxAmnt;
        // console.log(setTotalAmnt)
        // data.subAmount = 0;
        return <span>{Number((data.acceptedQuantity?data.subAmount : 0).toFixed(2))}</span>
      }
    },
   
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                // if (rowData) {
                  console.log(rowData)
                  setEditForm(rowData, index);
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title='dlete'>
            <Popconfirm title='Are you sure to delete this?' onConfirm={e =>{deleteData(index);}}>
              <MinusCircleOutlined 
                style={{ color: '#1890ff', fontSize: '14px' }} />
            </Popconfirm>
          </Tooltip>
        </span>)
    }

  ]

  const saveSelectvalue = (val) => {
    console.log(val)
  }

  return (
    <>

<Card size="small" title={<span style={{ color: 'white' }} >GRN Items</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      {/* <Card title={'GRN Items'} size="small"> */}
        <Form layout={"vertical"} autoComplete="off" form={form} >
          <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name={'itemCategoryId'} label="Item category" rules={[{ required: true,message: 'Missing Item Category' }]}>
                <Select
                  placeholder="Select Item Category"
                  // onClear={clearCustomerDependentData}
                  allowClear
                  disabled
                >
                  {itemCategories.map(dropData => {
                    return <Option key={dropData.itemCategoryId} value={dropData.itemCategoryId}>{dropData.itemCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
              <Form.Item name={'itemSubCategoryId'} label="Item Sub category" rules={[{ required: true,message: 'Missing Item Sub Category' }]}>
                <Select
                  placeholder="Select Item Sub Category"
                  // onClear={clearCustomerDependentData}
                  allowClear
                  disabled  
                >
                  {itemSubCategories === undefined ? '' : itemSubCategories.map(dropData => {
                    return <Option key={dropData.itemSubCategoryId} value={dropData.itemSubCategoryId}>{dropData.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>   
                <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
              <Form.Item name="itemId" label="Item" rules={[{ required: false }]}>
                <Select
                  placeholder="Select Item"
                  disabled
                  // onClear={clearCustomerDependentData}
                  allowClear
                >
                  {items === undefined ? '' : items.map(dropData => {
                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="sizeId" label="Size" rules={[{ required: false,message: 'Missing Size' }]}>
                <Select
                  placeholder="Select Size"
                  onChange={savevalue}
                  disabled
                  // onClear={clearCustomerDependentData}
                  allowClear
                >
                  {sizes.map(dropData => {
                    return <Option name="sizeId" key={dropData.sizeId} value={dropData.sizeId}>{dropData.sizeName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="poQuantity" label="PO Quantity"  >
                <Input disabled 
                  onChange={savevalue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item name="receivedQuantity" label="Received Qty" rules={[{ required: true,message: 'Missing Received Qty' }]} >
                <Input
                  onBlur={validateQty}
                />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item name="acceptedQuantity" label="Accepted Qty" rules={[{ pattern: /^[\d]{0,9}$/, required: true,message: 'Missing Accepted Qty' }]}>
                <Input 
                  onChange={validateAcceptedQty}
                
                />
              </Form.Item>
            </Col>
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="rejectedQuantity" label="Rejected Quantity" rules={[{ required: true,message: 'Missing Rejected Qty' }]}>
                <Input 
                disabled
                  onChange={savevalue}
                
                />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item name="unitPrice" label="Unit Price" rules={[{ required: true,message: 'Missing Unit Price' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item name="discountPercentage" label="Discount %" rules={[{ required: false,message: 'Discount %' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="discountAmount" label="Discount Amount" rules={[{ required: true,message: 'Discount Amount' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item name="taxPercentage" label="Tax Percentage" rules={[{ required: false,message: 'Missing Tax %' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="taxAmount" label="Tax Amount" rules={[{ required: true,message: 'Missing tax Amount' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <Form.Item name="totalAmount" label="Total Amount" rules={[{ required: true,message: 'Missing Total Amount' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}}>
            <Form.Item name="transportation" label="Transportation" rules={[{ required: true,message: 'Missing Transportation' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:3}} xl={{span:3}}>
              <Form.Item >

                <br />
                <Button disabled= {btnDisable} style={{ backgroundColor: "#67b32d", color: "white" , marginTop:'8px'}} onClick={onAddProduct} block >
                  Update
                    </Button>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:3}} lg={{span:3}} xl={{span:3}}>
              <Form.Item >
                <br />

                <Button style={{ backgroundColor: "#f19c9b", color: "white",marginTop:'8px' }}

                  onClick={onReset}
                >
                  Reset
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <br />
          {
            showTable ?
              <Table scroll={{ x: 1500 }} dataSource={newTableData} columns={columns}  pagination={false} /> :
              <></>
          }
      </Card>
    </>
  );
}

export default GrnItemsForm;
