import axios from "axios";

import connection from './connection';
import { JobCompletedEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import { CommonResponse, QaQcSampleData } from "@gtpl/shared-models/production-management";

export class QaQcSampleTestService{
    URL = connection.DEPLOY_URL + '/rejections';

    async CreateQaQcSampleData(formInfo:QaQcSampleData){
        return await axios.post(this.URL + '/createRejection', formInfo)
            .then(res => {
                return res.data
            })   
    }
    async   getAllActiveWorkstation(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllActiveWorkstation')
            .then(res => {
                return res.data
            })
    }

}