import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderDetailViewInfoDTO } from './sale-order-detail-view-info.dto';

export class SaleOrderDetailViewInfoResponseModel extends GlobalResponseObject {
    data?: SaleOrderDetailViewInfoDTO;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderDetailViewInfoDTO){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

