import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StockResponseDto } from '.';
export class StockQtyResponseModel extends GlobalResponseObject {
    data?: StockResponseDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: StockResponseDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

