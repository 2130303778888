import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, Row, Table } from 'antd';
import './yield-report.css';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import {CSVLink} from 'react-csv';
import { GradingService} from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
/* eslint-disable-next-line */
export interface YieldReportProps {}

export function YieldReport(
  props: YieldReportProps
) {  
  const plantId = Number(localStorage.getItem('unit_id'));
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);
  const [yieldReportData, setYieldReportData] = useState([]);
  const gradingService = new GradingService();
  useEffect(() => {
    getYieldReport();
  }, []);

  const getYieldReport = () => {
    gradingService.getYieldReportData({unitId:plantId}).then(res => {
      if (res.status) {
        setYieldReportData(res.data);
      } else {
        if (res.intlCode) {
          setYieldReportData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setYieldReportData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  const columns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed:'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Lot Code',
      dataIndex: 'concatLotNum',
      fixed:'left',
      sorter: (a, b) => a.concatLotNum.localeCompare(b.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('concatLotNum'),
    },
    {
      title: 'Supplier Type',
      dataIndex: 'supplierType',
      sorter: (a, b) => a.supplierType.localeCompare(b.supplierType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('supplierType'),
    },
    // {
    //   title: 'Farmer Code',
    //   dataIndex: 'farmerCode',
    //   sorter: (a, b) => a.farmerCode.localeCompare(b.farmerCode),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('farmerCode'),
    // },
    // {
    //   title: 'Farmer Name',
    //   dataIndex: 'farmerName',
    //   sorter: (a, b) => a.farmerName.localeCompare(b.farmerName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('farmerName'),
    // },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: any, index) => {
        return moment(record.indentDate).format('YYYY/MM/DD');
      },
    },
    {
      title: 'Indent Booked By',
      dataIndex: 'bookedBy',
      sorter: (a, b) => a.bookedBy.localeCompare(b.bookedBy),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('bookedBy'),
    },
    {
      title: 'Unit Code',
      dataIndex: 'unitCode',
      sorter: (a, b) => a.unitCode.localeCompare(b.unitCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unitCode'),
    },
    {
      title: 'Indent Weight',
      dataIndex: 'indentWeight',
      sorter: (a, b) => a.indentWeight - b.indentWeight,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentWeight'),
    },
    {
      title: 'Indent Count',
      dataIndex: 'indentCount',
      sorter: (a, b) => a.indentCount - b.indentCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentCount'),
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      sorter: (a, b) => moment(a.grnDate).unix() - moment(b.grnDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: any, index) => {
        return moment(record.grnDate).format('YYYY/MM/DD');
      }
    },
    {
      title: 'GRN Weight',
      dataIndex: 'grnWeight',
      sorter: (a, b) => a.grnWeight - b.grnWeight,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnWeight'),
    },
    {
      title: 'GRN Count',
      dataIndex: 'grnCount',
      sorter: (a, b) => a.grnCount - b.grnCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnCount'),
    },
    {
      title: 'Antibiotic Result',
      dataIndex: 'antibioticResult',
      sorter: (a, b) => a.antibioticResult.localeCompare(b.antibioticResult),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('antibioticResult'),
    },
    {
      title: 'Soft(%)',
      dataIndex: 'soft',
      sorter: (a, b) => a.soft.localeCompare(b.soft),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soft'),
    },
    {
      title: 'Grading Date',
      dataIndex: 'gradingDate',
      sorter: (a, b) => moment(a.gradingDate).unix() - moment(b.gradingDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: any, index) => {
        return moment(record.gradingDate).format('YYYY/MM/DD');
      }
    },
    {
      title: 'AVG Count',
      dataIndex: 'avgCount',
      sorter: (a, b) => a.avgCount - b.avgCount,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('avgCount'),
    },
    {
      title: 'Total Weight',
      dataIndex: 'totalWeight',
      fixed: 'right',
      sorter: (a, b) => a.totalWeight - b.totalWeight,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('totalWeight'),
    },
    {
      title: 'Yeild(%)',
      dataIndex: 'yeild',
      fixed: 'right',
      sorter: (a, b) => a.yeild - b.yeild,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('yeild'),
      render: (value, record: any, index) => {
        return {
          props: {
            style: { background: (value<65 || value>90) ? "red" : "green" }
          },
          children: <div>{value}</div>
        };
      }
    },
  ];

  const headers = [
    { label: 'Lot Number', key: 'concatLotNum' },
    { label: 'Supplier Type', key: 'supplierType' },
    { label: 'Farmer Code', key: 'farmerCode' },
    { label: 'Farmer Name', key: 'farmerName' },
    { label: 'Indent Date', key: 'indentDate' },
    { label: 'Indent Booked By', key: 'bookedBy' },
    { label: 'Unit Code', key: 'unitCode' },
    { label: 'Indent Weight', key: 'indentWeight' },
    { label: 'Indent Count', key: 'indentCount' },
    { label: 'GRN Date', key: 'grnDate' },
    { label: 'GRN Count', key: 'grnCount' },
    { label: 'Antibiotic Result', key: 'antibioticResult' },
    { label: 'Soft(%)', key: 'soft' },
    { label: 'Grading Date', key: 'gradingDate' },
    { label: 'AVG Count', key: 'avgCount' }
  ]

  const exportedData = [];
  yieldReportData.forEach(element => {
    element.counts.forEach(element1 => {
      Object.entries(element1).map(count => {
        headers.push({label: count[0], key: count[0]});
        element[count[0]] = count[1];
      })
      exportedData.push(element);
    });
  });
  const otherHeaders= [{ label: 'Total Weight', key: 'totalWeight' },{ label: 'Yeild(%)', key: 'yeild' }];
  headers.push(...otherHeaders);

  const expandedRowRender =  (record:any,index, indent, expanded)  => {
    const subColumns: ColumnProps<any>[] = [];
    Object.keys(record.counts[0]).forEach(count => {
      subColumns.push({
        title: count,
        dataIndex: count
      })
    });
    
    return (
      <>
        <Table
          className="components-table-demo-nested"
          columns={subColumns}
          dataSource={record.counts}
          bordered 
        />
      </>
    );  
  }

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Yield Report</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Row>
          <Col style={{marginTop:'30px'}}>
            <Button><CSVLink filename={"yieldreport.csv"} data={exportedData} headers={headers} >Get Excel </CSVLink></Button>
          </Col>
        </Row>
        <Table
          rowKey={record => record?.lotNumber}
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={yieldReportData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          bordered 
          scroll={{ x:2500 }}
        />
      </Card>
    </>
  );
}

export default YieldReport;
