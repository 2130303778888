export class GrnVehicleInfo{
    rmGrnId:number;
    rmGrnItemId:number;
    indentId:number;
    supplierId:number;
    vehicleId:number;
    productId:number;
    indentNumber:string;
    rmVINumber?:string;
    rmGrnNumber:string;
    supplierCode:string;
    supplierAddress:string;
    varietyOfMaterial:string;
    vehicleNumber:string;
    vehicleInTime:Date;
    sealNumber:string;
    icingConditionOfMaterial:string;
    cratesCondition:string;
    cleanlinessOfCrates:string;
    vehicleCleanliness:string;
    anySignOfInfestation:string;
    contaminationDustOrFilth:string;
    insulationDamage:string;
    remarks:string;
    plantId:number;
    createdUser:string;
    updatedUser:string;
    inspectionId:number;
}