import { UnitRequest } from "@gtpl/shared-models/common-models";
import { AssignGradingResponse, AssignSoModel, CommonResponse, GradeLotInvResponse, GradeLotNoRequest, GradeLotNoResponse, GradeReportingRequest, GradingDashboardResponse, GradingInventoryResponse, LotCodesResponse } from "@gtpl/shared-models/production-management";
import axios from "axios";
import connection from './connection';

export class GradingService{
    URL = connection.DEPLOY_URL + '/grade-inventory';

    async assignGradingOperation(formInfo):Promise<AssignGradingResponse>{
        return await axios.post(this.URL + '/assignGradingOperation', formInfo)
            .then(res => {
                return res.data
            })   
    }

    async getLotLevelInfoForGrading(requestData: GradeLotNoRequest):Promise<GradeLotNoResponse>{
        return await axios.post(this.URL + '/getLotLevelInfoForGrading', requestData).then(res => {
            return res.data
        })   
    }  

    async saveCountInGradeInventory(requestData: GradeReportingRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/saveCountInGradeInventory', requestData).then(res => {
            return res.data
        })   
    }
    async getLotLevelGradingInventory(requestData: UnitRequest):Promise<GradeLotInvResponse>{
        return await axios.post(this.URL + '/getLotLevelGradingInventory', requestData).then(res => {
            return res.data
        })   
    }
    
    async assignCountToSO(inputData: AssignSoModel):Promise<AssignGradingResponse>{
        return await axios.post(this.URL + '/assignCountToSO', inputData).then(res => {
            return res.data
        })   
    }

    async getGradingInventory(inputData: UnitRequest):Promise<GradingInventoryResponse>{
        return await axios.post(this.URL + '/getGradingInventory', inputData).then(res => {
            return res.data
        })   
    }

    async getGradingDashboardData(inputData: UnitRequest):Promise<GradingDashboardResponse>{
        return await axios.post(this.URL + '/getGradingDashboardData', inputData).then(res => {
            return res.data
        })   
    }

    async getLotNosForGrading(inputData: UnitRequest):Promise<LotCodesResponse>{
        return await axios.post(this.URL + '/getLotNosForGrading', inputData).then(res => {
            return res.data
        })   
    }

    async getYieldReportData(inputData: UnitRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getYieldReportData', inputData).then(res => {
            return res.data
        })   
    }

    async clearWip(requestData: GradeLotNoRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/clearWip',requestData).then(res => {
            return res.data
        })   
    }

    async deheadingInventotyData(requestData: UnitRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/deheadingInventotyData',requestData).then(res => {
            return res.data
        })   
    }

    

}