import { VariantDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllVariantResponseModel extends GlobalResponseObject {
    data?: VariantDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: VariantDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}

