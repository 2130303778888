import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GrnVehicleInfo } from "./grn-vehicle.dto";

export class RMVehicleInfoResponse extends GlobalResponseObject {
    data?: GrnVehicleInfo;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: GrnVehicleInfo){
        super(status,intlCode,internalMessage);
        this.data = data;
    }
}