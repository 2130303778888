import React, { useEffect, useState } from 'react';

import './bom-parameter-grid.css';
import { Button, Card, Divider, Space, Table } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { BomPackingItemsDto, BomSODto } from '@gtpl/shared-models/sale-management';
import {RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined, SolutionOutlined } from '@ant-design/icons';
/* eslint-disable-next-line */
export interface BomParameterGridProps {}

export function BomParameterGrid(
  props: BomParameterGridProps
) { 
  
  const [saleOrderId, setSaleOrderId] =useState<number>();
  const [bomSoData, setBomSoData] = useState<BomSODto[]>();
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [redirectToBom, setRedirectToBom] = useState<boolean>(false);
  const [redirectToBomDetailView, setRedirectToBomDetailView] = useState<boolean>(false);
  const saleOrderService = new SaleOrderService();
  useEffect(() => {
    getAllBomDetails()
  }, []);
  
      // get all BomDetails 
      const getAllBomDetails = () => {
        saleOrderService.getAllBomDetails().then((res) => {
            if(res.status){
              setBomSoData(res.data);
              // setUpdateStateData(updateStateData+1);
            }
        });
      }
      const getColumns = () => {
        const columns = [
          {
            title: 'Sale Order Number',
            dataIndex: 'saleOrderNumber',
            key: 'saleOrderNumber',
            render: (value, record: BomSODto, index) => {
              return (record.saleOrderNumber) ? record.saleOrderNumber : '';
            },
          },
         
          {
            title: 'Action',
            key: 'action',
            render: (text, rowData) => (
              <span>
              {(redirectToBom)?<Redirect
                to={{
                  pathname: "/saleorder-bom",
                  state: rowData.saleOrderId
                }}
              />:''}<SolutionOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  setRedirectToBom(true);
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
               <Divider type="vertical" />
              {(redirectToBomDetailView)?<Redirect
                to={{
                  pathname: "/bom-detail-view",
                  state: saleOrderId
                }}
              />:''}
              <EyeOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  setRedirectToBomDetailView(true);
                  setSaleOrderId(rowData.saleOrderId);
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />
            </span>)
          },
        ];
        return columns;
      }
      
 
  return (
    <Card title={<span style={{color:'white'}}>Bom Calculation</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/saleorder-bom' ><Button className='panel_button' >Create </Button></Link>}>
      <Table key={keyUpdate + 1} columns={getColumns()} dataSource={bomSoData} pagination={false}  />
    </Card>
  );
}



export default BomParameterGrid;
