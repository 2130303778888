export class SpecSheetDto{
    specSheetId:number;
    customerId:number;
    brandId:number;
    skuCodeId:number;
    fileName:string;
    filePath:string;
    createdUser:string;
    updatedUser:string;
    isActive: boolean;
    fileUpload?: Blob;
    constructor(specSheetId:number,customerId:number,brandId:number, skuCodeId:number,createdUser:string,updatedUser:string,isActive: boolean,fileName:string,filePath:string,fileUpload?:Blob){
        this.specSheetId=specSheetId;
        this.customerId=customerId;
        this.brandId=brandId;
        this.skuCodeId=skuCodeId; 
        this.fileName=fileName;
        this.filePath=filePath;
        this.createdUser=createdUser;
        this.updatedUser=updatedUser;

        this.isActive=isActive;
        this.fileUpload = fileUpload;

    }

}
export const SpecSheetDetailsDefualt:SpecSheetDto={
    specSheetId:0,
    customerId:0,
    brandId:0,
    skuCodeId:0,
    fileName:" ",
    filePath:" ",
    createdUser:" ",
    updatedUser:" ",

    isActive:true,
};
