import Table, { ColumnProps } from 'antd/lib/table';
import { QrcodeOutlined, PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Button, Modal } from 'antd';

export interface ReportingPrintProps { }

export function ReportingPrint(props: ReportingPrintProps) {
    const [printData, setPrintData] = useState<any[]>([])
    const [page, setPage] = React.useState(1);
    const [imageUrl, setImageUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [showQRModal, setShowQRModal] = useState('')
    const [rowData, setRowData] = useState<any>()

    const generateQR = async (record) => {
        try {
            const response = await QRCode.toDataURL(record.saleOrder);
            console.log(record, 'res')
            setImageUrl(response);
            setIsModalVisible(true);
            setShowQRModal('QRModal');
            setRowData(record)
        } catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const printOrder = () => {
        const divContents = document.getElementById('printme').innerHTML;
        const element = window.open('', '', 'height=700, width=1024');
        element.document.write(divContents);
        element.document.close();
        element.print();
        element.close(); // to close window when click on cancel/Save
        setIsModalVisible(true); // model should be open
    }

    return (
        <>
                <html>
                    <head>
                    </head>
                    <body id='printme'>
                        <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                        <div className="ticket">
                            <h3 className="centered">SANDHYA AQUA </h3>
                            <p style={{ textAlign: 'center' }}>Description</p>
                            <p style={{ textAlign: 'left' }}>Sale Order : {rowData ? rowData.saleOrder : ''}</p>
                            <tr>
                                <td style={{ textAlign: 'left' }} className={'ta-b'} colSpan={3}>Rack Position : </td>
                                <td className={'ta-b'} colSpan={2}><b>{(rowData[0].brandName) ? rowData[0].brandName : '-'}</b></td>
                            </tr>
                            <a href={imageUrl} download>
                                <img src={imageUrl} alt="img" style={{ width: '100%' }} />
                            </a>
                        </div>
                    </body>
                </html>
        </>
    )
}
export default ReportingPrint;