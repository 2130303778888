import { AntibiotsMicrobiologyInfo, GrnRequest } from "@gtpl/shared-models/raw-material-procurement";
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from 'axios';
import connection from "./connection";

export class AntiBioticsReportService{

    URL = connection.DEPLOY_URL+'/rm-antibiotics-report';

    async createAntibioticsInfo(data: AntibiotsMicrobiologyInfo): Promise<CommonResponse>{
       return await axios.post(this.URL+ '/createAntibioticsInfo', data)
           .then(res => {
               return res.data
           })
    }
}