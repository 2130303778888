import { JobStatusEnum, PlantsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from '@gtpl/shared-models/common-models';
export class BeheadingInventoryDto {
    lotNumber: string;
    saleOrderId: number;
    saleOrderNo: string;
    saleOrderItemId?: number;
    jobNumber: string;
    subPlant: number;
    workStationType: WorkStationTypeEnum;
    inputQuantity:number;
    plannedOutputQuantity: number;
    boxes: number;
    workStationId: number;
    jobStatus:JobStatusEnum;
    plantId?: PlantsEnum;
    subPlantId?: SubPlantCodesEnum;
    createdUser: string;
    updatedUser: string;
    physicalQuantity?:number;
    issuedQuantity?:number;
    constructor(
        lotNumber: string,
        saleOrderId: number,
        saleOrderNo: string,
        jobNumber: string,
        subPlant: number,
        workStationType: WorkStationTypeEnum,
        inputQuantity:number,
        plannedOutputQuantity: number,
        boxes: number,
        workStationId: number,
        jobStatus:JobStatusEnum,
        createdUser: string,
        updatedUser: string,
        plantId?: PlantsEnum,
        subPlantId?: SubPlantCodesEnum,
        saleOrderItemId?: number,
        physicalQuantity?:number,
        issuedQuantity?:number
    ){
        this.lotNumber=lotNumber,
        this.saleOrderId=saleOrderId,
        this.saleOrderNo=saleOrderNo,
        this.jobNumber=jobNumber,
        this.subPlant=subPlant,
        this.workStationType=workStationType,
        this.inputQuantity = inputQuantity,
        this.plannedOutputQuantity=plannedOutputQuantity,
        this.boxes=boxes,
        this.workStationId=workStationId,
        this.jobStatus=jobStatus,
        this.createdUser=createdUser,
        this.updatedUser=updatedUser,
        this.plantId=plantId,
        this.subPlantId=subPlantId,
        this.saleOrderItemId=saleOrderItemId
        this.physicalQuantity=physicalQuantity
        this.issuedQuantity=issuedQuantity
    }
}
