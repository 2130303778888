import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import { LotDetailsDto, LotDetailsResModel, QaQcSampleData } from '@gtpl/shared-models/production-management';
import { QaQcSampleTestService, RejectionsService} from '@gtpl/shared-services/production';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

import { Link, Redirect } from 'react-router-dom';
import {  QaQcSampleTest } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-sample-test';


import './qa-qc-sample-test-grid.css';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';

/* eslint-disable-next-line */
export interface QaQcSampleTestGridProps {}

export function QaQcSampleTestGrid(
  props: QaQcSampleTestGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [qaqcData, setQaqcData] = useState<LotDetailsDto[]>([]);
  const [selectedQaqcData, setSelectedWorkstationData] = useState<LotDetailsDto>();
  const [productionLogId, setProductionLogId] = useState<number>(0);
  const [rejectionId, setRejectionId] = useState<number>(null);
  
  const qaqcService=new  RejectionsService ();
 
 useEffect( () => {
  getAllLotDetailsInfo();
 },[])

  const  getAllLotDetailsInfo= () => {
    const req = new PlantRequest();
    req.plantId = Number(localStorage.getItem('unit_id'));
    qaqcService.getAllLotDetailsInfo(req).then(res => {
      if (res.status) {
        setQaqcData(res.data);
      } else {
        if (res.intlCode) {
            setQaqcData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setQaqcData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  };
  
  const goToDetailView = (productionLogId:number) => {
    setProductionLogId(productionLogId);
    console.log(productionLogId);
  }
   /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });


  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  
    //TO open the form for updation
   


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'LOT Code',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'product',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.product.localeCompare(b.product),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('product')
    // },

    {
      title: 'Test Name',
      dataIndex: 'testName',
      // responsive: ['lg'],
      sorter: (a, b) => a.testName.localeCompare(b.testName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('testName')
    },
    {
      title: 'Test Result',
      dataIndex: 'testResult',
      // responsive: ['lg'],
      sorter: (a, b) => a.testResult.localeCompare(b.testResult),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('testResult')
    },
    {
      title: 'No Of Samples',
      dataIndex: 'noOfSamples',
      // responsive: ['lg'],
      sorter: (a, b) => a.noOfSamples - b.noOfSamples,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('noOfSamples')
    },
    {
      title: 'Tested Quantity',
      dataIndex: 'quantityTested',
      // responsive: ['lg'],
      sorter: (a, b) => a.quantityTested-b.quantityTested,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('quantityTested')
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectionQuantity',
      // responsive: ['lg'],
      sorter: (a, b) => a.rejectionQuantity - b.rejectionQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rejectionQuantity')
    },
    {
      title: 'Rejection reason',
      dataIndex: 'rejectionReason',
      // responsive: ['lg'],
      sorter: (a, b) => a.rejectionReason.localeCompare(b.rejectionReason),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rejectionReason')
    },
    {
      title:'Action',
      dataIndex:'action',
      render: (text, rowData) => (
        <span>
          {/* {(object) ?<Redirect
            to={{
              pathname: "/rm-grn-form",
              state: object
            }}
          />:null} */}
         

          {(rejectionId) ?<Redirect
            to={{
              pathname: "/qa-qc-sample-detail-view",
              state: rejectionId
            }}
          />:null}
          <EyeOutlined  type="view"
            onClick={() => {
              
              // if (rowData.isActive) {
                setRejectionId(rowData);
              // } else {
              //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
              // }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />





        </span>
      )
      
    }

    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.remarks.length - b.remarks.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'Active',
    //       value: true,
    //     },
    //     {
    //       text: 'InActive',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     return record.isActive === value;
    //   },
      
    // },
   
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   


  }
  return (
    <Card title={<span style={{color:'white'}}>QA QC Sample Test </span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/qa-qc-sample-test' ><Button className='panel_button' >Create </Button></Link>}
    
    >
     <br></br>
     <Row gutter={40} >
      <Col>
          <Card title={'Total QAQC Sample Tests: ' + qaqcData.length} style={{textAlign: 'left', width: 290, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
        
          </Row>
          <br></br>
          <Table
          rowKey={record => record.workstationId}
          columns={columnsSkelton}
          dataSource={qaqcData}
          scroll= {{x:true}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
       
     </Card>
  );
}

export default QaQcSampleTestGrid;


