import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeDropDown, LotInformationRequest, LotNoInfo, LotNumberRequest, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import { SoakingAssignmentService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { Link, Redirect, useHistory } from 'react-router-dom';

import './assign-beheading-to-grading.css';
import { OperationTypeEnum, WorkStationCategoryEnum, WorkStationTypeEnum } from '@gtpl/shared-models/common-models';
import { GradingService } from '../../../../../../shared-services/production/src/lib/grading.service';
import moment from 'moment';
const { Option } = Select;

/* eslint-disable-next-line */
export interface AssignBeheadingToGradingProps {}

export function AssignBeheadingToGrading(
  props: AssignBeheadingToGradingProps
) {
  const [assignGradingForm] = Form.useForm();
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const service = new ProductionInventoryService();
  const gradingService = new GradingService();
  const [lotNoInfo, setLotNoInfo] = useState<LotNoInfo>(undefined);
  const [boxWeight, setBoxWeight] = useState<number>(0);
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const workstationService = new WorkstationService();
  const [disable, setDisable] = useState<boolean>(false)
  let history = useHistory();
  useEffect(() => {
    // assignGradingForm.setFieldsValue({workStationType:"INTERNAL"});

    getLotNumbers();
    getWorkStations();
  }, [])

  const getLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.GRADING);
    service.getLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }
  // let workstation = 0;
  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory = WorkStationCategoryEnum.find((res) => res.name === "GRADING").value;
    catReq.unitId = Number(localStorage.getItem('unit_id'));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        console.log(res.data);
        setWorkStations(res.data);
        // workstation = (res.data[0].workstationId)
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }

    
  const getLotNumberInfo = (lotNumber) => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.GRADING)
    service.getLotNumberInfo(lotNoReq).then((res) => {
      console.log(res.data);
      if (res.status) {
        assignGradingForm.setFieldsValue({ mainPlantId: res.data.mainPlantId });
        setLotNoInfo(res.data)
        let boxWeight = (res.data.boxWeight);
        setBoxWeight(boxWeight);
        assignGradingForm.setFieldsValue({
          productionInventoryId:res.data.productionInventoryId,
          saleOrderId:res.data.saleOrderId,
          saleOrderItemId:res.data.saleOrderItemId,
          saleOrder:res.data.poNumber,
          count:res.data.count,
          boxes:res.data.boxes,
          availableQuantity:res.data.availableQuantity,
          physicalQuantity:Math.round(res.data.availableQuantity),
        })
      } else {
        setLotNoInfo(undefined);
      }
    }).catch((err) => {
      setLotNoInfo(undefined);
      AlertMessages.getErrorMessage(err.message);
    });
  }
  const onFinish = (values) => {
    console.log(values)
    setDisable(true)
    const formInfo = assignGradingForm.getFieldsValue();
    console.log(formInfo);
    formInfo.createdUser = localStorage.getItem('createdUser');
    formInfo.plantId = Number(localStorage.getItem('unit_id'))
    formInfo.workStationType = WorkStationTypeEnum.INTERNAL;
    console.log(workStations.filter(rec => rec.workstation === "Grading"));
    // formInfo.workstation = 5
    console.log("my data is:",formInfo);
    assignGradingForm.validateFields().then(res => {
        gradingService.assignGradingOperation(formInfo).then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            onReset();
            history.push("/grade-reporting")
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch((err) => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        });
    });
  }
  const onReset=()=>{
    assignGradingForm.resetFields();
    
  }
  const calculateIssuingQty = () =>{
    const noOfBoxes = assignGradingForm.getFieldValue('boxes');
    const availableQuantity = assignGradingForm.getFieldValue('availableQuantity');
    // const boxWeight = availableQuantity/noOfBoxes;
    const physicalQuantity = (boxWeight*noOfBoxes).toFixed(2);
    if(Number(Number(physicalQuantity).toFixed(3)) <= Number(Number(availableQuantity).toFixed(3))){
      assignGradingForm.setFieldsValue({physicalQuantity:physicalQuantity});
    }else{
      assignGradingForm.setFieldsValue({physicalQuantity:0, boxes:0});
    }
    if(noOfBoxes===0){
      AlertMessages.getErrorMessage("Boxes cannot be 0. ");
    }
  }
  return (
    <>
    <Card title={<span style={{ color: 'white' }}>Grading Assignment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/grade-inventory' ><Button className='panel_button' >View </Button></Link>}>
      <Form layout={'vertical'} form={assignGradingForm} name="control-hooks">

        <Row gutter={24}>
          <Form.Item name="productionInventoryId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="mainPlantId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="assignedTime" initialValue={moment().format('YYYY-MM-DD HH:mm:ss')}>
                  <Input hidden/>
              </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }}>
            <Form.Item name="lotNumber" label="Lot Code" rules={[{ required: true, message: 'Missing Lot No' }]}>
              <Select
                placeholder="Select Lot Code"
                allowClear
                onChange={getLotNumberInfo}
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    .toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.lotNumber} value={dropData.lotNumber}>{dropData.lotandplantlot
                  }</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
            <Form.Item name="saleOrderId">
              <Input hidden/>
            </Form.Item>
            <Form.Item name="saleOrderItemId">
              <Input hidden/>
            </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrder" label="Customer PO" rules={[{ required: true, message: 'Missing Sale Order No' }]}>
              <Input disabled/>
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="count" label="Count" rules={[{ required: true, message: 'Missing Count' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="availableQuantity" label="Quantity Available" rules={[{ required: true, message: 'Missing Quantity Available' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="boxes" label="No. Of. Boxes" rules={[{ required: true, message: 'Missing No. Of. Boxes' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={calculateIssuingQty} />
            </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="physicalQuantity" label="Issuing Quantity" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{display:'none'}}>
              <Form.Item name="workStationType" label="Workstation Type" rules={[{ required: true, message: 'Missing Workstation Type' }]} initialValue="INTERNAL">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]}>
              <Select
                placeholder="Select Workstation"
                allowClear
              >
                {workStations.map(dropData => {
                  return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
                })}
              </Select>
              </Form.Item>
            </Col>
         </Row>
         <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary"disabled={disable} onClick={onFinish} htmlType="submit" >
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
            </Button>
        </Col>

        </Row>
      </Form>
    </Card>
    </>
  );
}

export default AssignBeheadingToGrading;
