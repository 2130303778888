import { GetStoreConsumptionItemsDto } from "@gtpl/shared-models/procurement-management";
import { StoreConsumptionService } from "@gtpl/shared-services/procurement";
import { Card, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { useEffect, useState } from "react";

export interface StoreConsumptionsItemsDetailsProps {
    storeConsumptionId: number;
}

function StoreConsumptionsItemsDetails(
    props: StoreConsumptionsItemsDetailsProps
) {

    const [storeConsumptionItemsData, setStoreConsumptionItemsData] = useState<GetStoreConsumptionItemsDto[]>([]);
    const storeConsumptionService = new StoreConsumptionService();
    useEffect(() => {
        getStoreItems();
    }, [])

    const getStoreItems = () => {
        storeConsumptionService.getStoreConsumptionItemsById({ saleOrderId: 0, storeConsumptionId: props.storeConsumptionId }).then(res => {
            if (res.status) {
                setStoreConsumptionItemsData(res.data);
            } else {
                setStoreConsumptionItemsData([]);
            }
        }).catch(err => {
            setStoreConsumptionItemsData([]);
        })
    }


    const storeConsumptionItems: ColumnProps<any>[] = [
        {
            title: 'Item',
            dataIndex: "itemName",
            width: 150,
            align: 'left',
        },
        {
            title: 'Consumed Quantity',
            dataIndex: 'quantity',
            width: 150,
            align: 'left',
        },
    ];
    return (
        <>
            <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>Store Consumption Items</span>}></Card>
            <Table
                key={Date.now()}
                className="components-table-nested"
                columns={storeConsumptionItems}
                dataSource={storeConsumptionItemsData}
            />

        </>
    );
}
export default StoreConsumptionsItemsDetails;