import { ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto } from "@gtpl/shared-models/masters";
import { SOInfoReq } from "./so-info.req";

export class BomInfoForDetailView {
    itemCategoryDetails:ItemCategoryDropDownDto;
    itemDetails:ItemsDropDownDto;
    size:SizesDropDownDto;
    quantity:number;
    saleOrderInfo:SOInfoReq


    /**
     * 
     * @param saleOrderId 
     * @param itemCategoryDetails 
     * @param itemDetails 
     * @param size 
     * @param quantity 
     * @param createdUser 
     * @param updatedUser 
     * @param packingMaterialId 
     */
    constructor(itemCategoryDetails:ItemCategoryDropDownDto, itemDetails:ItemsDropDownDto, size:SizesDropDownDto, quantity:number, saleOrderInfo:SOInfoReq){
        this.itemCategoryDetails = itemCategoryDetails;
        this.itemDetails = itemDetails;
        this.size = size;
        this.quantity = quantity;
        this.saleOrderInfo = saleOrderInfo;
    }
}