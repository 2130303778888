import axios from 'axios';
import {ColumnDto, AllColumnsResponseModel, ColumnsResponseModel, CreateColumnsResponseModel, ColumnResponse, ColumnRequest, ColumnsDropDownResponseModel} from '@gtpl/shared-models/masters';
import connection from './connection';
 
export class ColumnService{

    URL = connection.DEPLOY_URL + '/columns';
    
    async createColumn(columnDto: ColumnDto): Promise<CreateColumnsResponseModel>{
        return await axios.post(this.URL+ '/createColumns', columnDto)
            .then(res =>{
                return res.data
            })
    }
    async updateColumn(columnDto: ColumnDto): Promise<CreateColumnsResponseModel>{
     return await axios.post(this.URL+ '/updateColumns', columnDto)
         .then(res =>{
             return res.data
         })
    }
    async getAllColumns(): Promise<AllColumnsResponseModel>{
     console.log(URL);
     return await axios.post(this.URL+ '/getAllColumns')
         .then(res =>{
             return res.data
         })
    }
    async ActivateorDeactivateColumn(columnDto: ColumnDto): Promise<ColumnResponse>{
     return await axios.post(this.URL+ '/activateorDeactivateColumn', columnDto)
         .then(res =>{
             return res.data
         })
    }
    async getColumnById(columnRequest: ColumnRequest): Promise<any>{
     return await axios.post(this.URL+ '/getColumnById', columnRequest)
         .then(res =>{
             return res.data
         })
    }
    async getAllColumnDropDown():Promise<ColumnsDropDownResponseModel>{
        return await axios.post(this.URL + '/getAllColumnDropDown')
        .then(res => {
            return res.data
        })   
      }
      

}