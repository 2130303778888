import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, Input, InputNumber, Row, Select, Table } from 'antd';
import './stock-transfer.css';
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { OperationTypeEnum } from "@gtpl/shared-models/common-models";
import { UnitcodeService } from "@gtpl/shared-services/masters";
import { StockTransferService } from "@gtpl/shared-services/production";

import { ProductionInventoryDetails, SendStockTransferDto, StockTransferDefaultInformation, StockTransferData } from '@gtpl/shared-models/production-management';
import { ColumnProps } from 'antd/lib/table';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
const { Option } = Select;
/* eslint-disable-next-line */
export interface StockTransferProps {
  operation: OperationTypeEnum,
  inventoryInfo: ProductionInventoryDetails
  handleCancel: () => void;
}

export function StockTransfer(
  props: StockTransferProps
) {
  const [stockTransferForm] = Form.useForm<StockTransferData>();
  const [stockTransferInfo, setStockTransferInfo] = useState<StockTransferData[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [alternateKeyUpdate, setAlternateKeyUpdate] = useState<number>(1);
  const [availableQty, setAvailableQty] = useState<number>(props.inventoryInfo.physicalQuantity - props.inventoryInfo.issuedQuantity);
  const [revisedQty, setRevisedQty] = useState<number>(props.inventoryInfo.physicalQuantity - props.inventoryInfo.issuedQuantity);

  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [subPlantData, setSubPlantData] = useState<PlantsDropDown[]>([]);

  const unitService = new UnitcodeService();
  const transferService = new StockTransferService();

  useEffect(() => {
    console.log(props.inventoryInfo.nextOperation);
    getMainPlants();
    getSubPlants();
  }, [])

  const getMainPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    });
  }

  const getSubPlants = () => {
    unitService.getAllPlants().then((res) => {
      if (res.status) {
        if(props.inventoryInfo.nextOperation === "SOAKING" || props.inventoryInfo.nextOperation === "FREEZING" || props.inventoryInfo.nextOperation === "COOKING"){
          setSubPlantData(res.data.filter((rec) => rec.plantType === "PLANT"));
        }
        else{
          setSubPlantData(res.data);
        }
      } else {
        setSubPlantData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSubPlantData([]);
    });
  }

  const columns: ColumnProps<StockTransferData>[] = [
    {
      title: 'Plant',
      dataIndex: 'plantId',
      render: (value, record: StockTransferData, index) => {
        record.plantName = subPlantData.find(plant => plant.plantId === Number(localStorage.getItem('unit_id')))?.plantName;
        return record.plantName;
      },
    },
    {
      title: 'To Sub Plant',
      dataIndex: 'subPlantId',
      render: (value, record: StockTransferData, index) => {
        record.subPlantName = subPlantData.find(subPlant => subPlant.plantId === value)?.plantName;
        return record.subPlantName;
      },
    },
    {
      title: 'Boxes',
      dataIndex: 'noOfBoxes',
      key: 'noOfBoxes',
      render: (value, record: StockTransferData, index) => {
        return record.noOfBoxes
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ['descend', 'ascend'],
      render: (value, record: StockTransferData, index) => {
        let qtyName = Math.random() + "subPlantQTY";
        return record.quantity;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'estimatedDate',
      key: 'estimatedDate',
      render: (value, record: StockTransferData, index) => {
        return moment(value).format('YYYY-MM-DD h:m:s');
      },
    },
    {
      title: "Remove",
      key: 'control',
      align: 'center',
      render: (value, record: StockTransferData, index) => {
        return <div className='align-center'>{<><Divider type="vertical" />  <Button icon={<MinusSquareOutlined />} style={{ backgroundColor: 'red', color: 'white' }} onClick={() => deleteAdjustmentDetails(record, index)}>
        </Button></>} </div>

      },
    }
  ];

  const addAdjustmentDetails = (record: StockTransferData, index) => {
    stockTransferInfo.push(record);
    setStockTransferInfo(stockTransferInfo);
    setKeyUpdate(keyUpdate + 1);
  }

  const deleteAdjustmentDetails = (record: StockTransferData, index) => {
    stockTransferInfo.splice(index, 1);
    setStockTransferInfo(stockTransferInfo);
    setKeyUpdate(keyUpdate - 1);
  }

  const addNewRecord = () => {
    stockTransferForm.validateFields().then(formDetails => {
      formDetails.plantId = Number(localStorage.getItem('unit_id'));
      formDetails.inventoryplantId = props.inventoryInfo.plantId;
      formDetails.prodInvId = props.inventoryInfo.productionInventoryId;
      formDetails.subLotNo = props.inventoryInfo.subLotNumber;
      formDetails.lotNo = props.inventoryInfo.lotNumber;
      formDetails.operation = props.inventoryInfo.operation;
      formDetails.nextOperation = props.inventoryInfo.nextOperation;
      formDetails.count = props.inventoryInfo.count;
      formDetails.createdUser = localStorage.getItem('createdUser');
      if (stockTransferInfo.filter(function (e) { return (e.subPlantId === formDetails.subPlantId) }).length == 0) {
        let transferQty = 0;
        let transferBoxes = 0;
        stockTransferInfo.map((transferInfo) => {
          transferQty += transferInfo.quantity;
          transferBoxes += transferInfo.noOfBoxes;
        });
        transferQty += formDetails.quantity;
        transferBoxes += formDetails.noOfBoxes;
        if (transferBoxes <= props.inventoryInfo.opBoxes) {
          if (transferQty <= availableQty) {
            console.log(formDetails);

            stockTransferInfo.push(formDetails);
            setKeyUpdate(keyUpdate + 1);
            stockTransferForm.resetFields();
          } else {
            AlertMessages.getErrorMessage("Enter Valid Quantity");
          }
        } else {
          AlertMessages.getErrorMessage("Enter Valid Boxes");
        }

      } else {
        AlertMessages.getErrorMessage("Please change the plant it was already selected.");
      }

    });
  }

  const calQty = (val) => {
    console.log(val);
    console.log(props.inventoryInfo.physicalQuantity);
    console.log(props.inventoryInfo.issuedQuantity);
    console.log(props.inventoryInfo.issuedQuantity);
    let boxes = props.inventoryInfo.opBoxes;
    const boxQty = Number(props.inventoryInfo.physicalQuantity) / Number(boxes);
    console.log(boxQty);
    const qty:number = Number(boxQty) * Number(val);
    if(Number(qty) > Number(props.inventoryInfo.physicalQuantity)){
      AlertMessages.getWarningMessage("Qty should be less than existing qty. ");
      stockTransferForm.setFieldsValue({quantity:props.inventoryInfo.physicalQuantity})
      stockTransferForm.setFieldsValue({noOfBoxes:props.inventoryInfo.opBoxes})
    }
    else{
      stockTransferForm.setFieldsValue({quantity:Number(qty.toFixed(0))})
    }
    console.log(qty);
  }
  const plantOnChange = (value, record: StockTransferData, index) => {
    record.plantName = value;
    setAlternateKeyUpdate(alternateKeyUpdate + 1);
  }

  const subPlantOnChange = (value, record: StockTransferData, index) => {
    record.subPlantName = value;
    setAlternateKeyUpdate(alternateKeyUpdate + 1);
  }

  const EditQty = (value, record: StockTransferData, index, formInput) => {
    console.log(value);
    if (value) {
      if (value <= availableQty) {
        console.log(value);
        record.quantity = value;
      } else {
        console.log(record);
        record.quantity = 0;
        console.log(record);
      }
      stockTransferForm.setFieldsValue({ [formInput]: record.quantity })
      setAlternateKeyUpdate(alternateKeyUpdate + 1);
    }
  }
  const SubmitInfo = () => {
    const transferStock = new SendStockTransferDto();
    transferStock.sendStocks = stockTransferInfo;
    console.log(transferStock);

    transferService.sendStock(transferStock).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        props.handleCancel();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  let record = 0;
  return (
    <Card title={<span style={{ color: 'white' }}>{props.operation} Inventory Transfer</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
      <Row gutter={24}>
        <Col>
          <Descriptions column={4}>
            <Descriptions.Item label="Lot Number">
              {props.inventoryInfo.lotNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Sub Lot Number">
              {props.inventoryInfo.subLotNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Sale Order">
              {props.inventoryInfo.poNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Sale Order Item">
              {props.inventoryInfo.productSKU}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col>
          <Descriptions column={4}>
            <Descriptions.Item label="Inventory">
              {props.inventoryInfo.physicalQuantity + " - (KGS)"}
            </Descriptions.Item>
            <Descriptions.Item label="WIP QTY">
              {props.inventoryInfo.wipQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="In-Transit QTY">
              {props.inventoryInfo.intransitQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Wastage QTY">
              {props.inventoryInfo.wastageQuantity}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>


      <Row gutter={24}>
        <Col>
          <Descriptions column={4}>
            <Descriptions.Item label="Issued QTY">
              {props.inventoryInfo.issuedQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Rejected QTY">
              {props.inventoryInfo.rejectedQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="I/P Boxes">
              {props.inventoryInfo.boxes}
            </Descriptions.Item>
            <Descriptions.Item label="O/P Boxes">
              &nbsp; {props.inventoryInfo.opBoxes}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row> 

      <Row gutter={24}>
        <Col>
          <Descriptions column={4}>
            <Descriptions.Item label="Available/To be Transfer">
              {availableQty}
            </Descriptions.Item>

            <Descriptions.Item label="Transferring QTY">
              &nbsp; {stockTransferInfo?.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.quantity);
              }, 0)}
            </Descriptions.Item>

            <Descriptions.Item label="Remaining QTY">
            &nbsp; {availableQty - stockTransferInfo?.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.quantity);
              }, 0)}
            </Descriptions.Item>
            <Descriptions.Item label="Next Operation">
              {props.inventoryInfo.nextOperation}
            </Descriptions.Item>
          </Descriptions>

          
        </Col>
      </Row>

      <Form form={stockTransferForm} layout="vertical">
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"subPlantId"}
              label="Plant/Sub Plant"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Select Sub Plant!`,
                },
              ]}
            >
              <Select
                placeholder="Select To Sub Plant"
                allowClear
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option key={'splant'} value={''}>Select Sub Plant</Option>
                {subPlantData.map(dropData => {
                  return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option> : ''
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"noOfBoxes"}
              label="Boxes"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input Boxes!`,
                },
              ]}
              initialValue={props.inventoryInfo.opBoxes}
            >
              <InputNumber style={{ width: '100%' }} min={0} onBlur={e=>calQty(e.target.value)} placeholder={'transferred Boxes'} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"quantity"}
              label="QTY"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input Quantity!`,
                },
              ]}
              initialValue={props.inventoryInfo.physicalQuantity}
            ><InputNumber style={{ width: '100%' }} min={0} placeholder={'transferred QTY'} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"estimatedDate"}
              label="ETD"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Expected Del Date`,
                },
              ]}
              initialValue={moment()}
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col style={{paddingTop:'25px'}} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Button  type='primary' icon={<PlusSquareOutlined />} onClick={() => addNewRecord()}>Add</Button>
          </Col>
        </Row>
      </Form>
      <br />
      {
        stockTransferInfo.length ? <><Row gutter={24}>
          <Table
            key={keyUpdate}
            columns={columns}
            dataSource={stockTransferInfo}
            bordered
            pagination={false}
            style={{ width: '100%' }} />
        </Row>
          <br />
          <Row><Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={SubmitInfo} >
              Submit
            </Button>
          </Col></Row></> : ''
      }


    </Card >
  );
}

export default React.memo(StockTransfer);
