import { StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { EtdWiseDTO, saleOrderNoRequest } from '@gtpl/shared-models/procurement-management';
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import { RecruitmentResponseModel } from '@gtpl/shared-models/masters';



export interface EtdWiseGridProps { }

export function EtdWiseGrid(props: EtdWiseGridProps) {
    const [form] = Form.useForm();
    const Option = Select;
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);
    const [poDropDown, setPoDropDown] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const service = new StoreConsumptionService();
    const [buttonDisable, setButtonDisable] = useState<boolean>(true)
    const [filtersData, setFiltersData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    const updateEtd = () => {
        const etdWisereq: EtdWiseDTO[] = []
        selectedRows.forEach((element) => {
            etdWisereq.push(new EtdWiseDTO(undefined, element.saleOrderId, element.etd, element.revisedEtd, Number(localStorage.getItem('unit_id')), localStorage.getItem("createdUser")))
        })
        service.saveEtdWiseGridData(etdWisereq).then((res) => {
            if (res.status) {
                getEtdWiseData()
                setFiltersData(res.data);
                setSelectedRows([])
                setSelectedRowKeys([])
                form.resetFields()
                AlertMessages.getSuccessMessage('saved sucessfully')
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch((err) => {
            setFiltersData([]);
            AlertMessages.getErrorMessage(err.message)
        });
    }

    // useEffect(()=>{
    //     getFilteredData(filtersData)
    // },[filtersData])

    useEffect(() => {
        getEtdWiseData();
        getPoNumberForEtdGrid();
    }, []);

    const getEtdWiseData = () => {
        const req = new saleOrderNoRequest();
        let saleOrderId = form.getFieldValue('saleOrderId')
        let selectedData = filtersData;
        req.saleOrderId = saleOrderId;
        req.unitId = Number(localStorage.getItem('unit_id'))
        service.getEtdWiseData(req).then((res) => {
            if (res.data) {
                res.data.forEach(element => {
                    element.inputDisabled = false
                });
                setData(res.data)
                setFiltersData(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
        setData(selectedData)
    }

    const getPoNumberForEtdGrid = () => {
        service.getPoNumberForEtdGrid().then((res) => {
            if (res.data) {
                setPoDropDown(res.data)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    /**
  * used for column filter
  * @param dataIndex column data index
  */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });

    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const columns: ColumnProps<any>[] = [
        {
            title: 'PO Number',
            dataIndex: 'soItem',
            ...getColumnSearchProps('saleOrder')
        },
        {
            title: 'Country',
            dataIndex: 'country',
            ...getColumnSearchProps('saleOrder')
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            render: (value, record) => {
                return moment(record.invoiceDate).format('YYYY-DD-MM')
            }
        },
        {
            title: 'ETD',
            dataIndex: 'edlDate',
            render: (value, record) => {
                return moment(record.edlDate).format('YYYY-DD-MM')
            }
        },

        {
            title: 'Actual ETD',
            dataIndex: 'etd',
            render: (value, record) => {
                return moment(record.etd).format('YYYY-DD-MM')
            }
        },

        {
            title: 'Revised ETD',
            dataIndex: 'revisedEtd',
            render: (value, record) => {
                return <span><Form >
                    <Form.Item name='revisedEtd' >
                        <DatePicker onChange={(value) => { record.revisedEtd = value; validateRevisedDate(record) }} disabled={record.inputDisabled} />
                    </Form.Item>
                </Form></span>
            }
        },
    ]
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const validateRevisedDate = (record) => {

    }

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(newSelectedRowKeys);
        if (selectedRows.length == 0) {
            setButtonDisable(true)
            data.forEach((element) => { element.inputDisabled = false })
        } else {
            selectedRows.forEach((item) => {
                if (item.revisedEtd) {
                    const so = selectedRows.find(i => i.saleOrderId == item.saleOrderId)
                    if (so) {
                        item.inputDisabled = true
                    } else {
                        item.inputDisabled = false
                    }
                    setButtonDisable(false)
                } else {
                    setButtonDisable(true)
                    item.inputDisabled = true
                    AlertMessages.getErrorMessage('Please Select Date')
                }
            })
        };
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Card size="small" style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} title={<span style={{ color: 'white' }}> ETD Wise Grid</span>} >
                <Form layout={"vertical"} autoComplete="off" form={form} >
                    <Row gutter={[24, 24]} >
                        {/* <Col span={4}>
                            <Form.Item
                                name='saleOrderId'
                                label='SO Number'
                                rules={[
                                    {
                                        required: false, message: 'Select Unit',
                                    },
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Customer Po"
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={null}>Select Sale Order</Option>
                                    {poDropDown.map((data) => {
                                        return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.saleOrder}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        {/* <Col style={{ marginTop: '30px' }}>
                            <Button type="primary" onClick={()=>getEtdWiseData()}> Get data
                            </Button>
                        </Col> */}
                    </Row>
                </Form>
                <Table
                    rowSelection={rowSelection}
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        onChange(current) {
                            setPage(current);
                        }
                    }}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
                <Row justify='end'>
                    <Col span={4} >
                        <Button type="primary" onClick={() => updateEtd()} disabled={buttonDisable}>Save</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
export default EtdWiseGrid;