import { StoreConsumptionStatus } from "./store-consumption.enum";

export class StoreConsumptionItemsDto {
    storeConsumptionItemsId: number;
    storeConsumptionId: number;
    itemCategoryId:number;
    itemId:number;
    availableStock:number;
    quantity:number;
    status: StoreConsumptionStatus;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    stockType?: string;
   /**
    * 
    * @param storeConsumptionItemsId 
    * @param storeConsumptionId 
    * @param itemCategoryId 
    * @param itemId 
    * @param quantity 
    * @param status 
    * @param createdAt 
    * @param createdUser 
    * @param updatedAt 
    * @param updatedUser 
    * @param versionFlag 
    */
    constructor(storeConsumptionItemsId: number, storeConsumptionId: number, itemCategoryId:number, itemId:number, availableStock:number, quantity:number, status: StoreConsumptionStatus, isActive: boolean, createdAt: Date, createdUser: string, updatedAt: Date, updatedUser: string, versionFlag: number,stockType?:string){ 
            this.storeConsumptionId = storeConsumptionId
            this.storeConsumptionItemsId = storeConsumptionItemsId
            this.itemCategoryId = itemCategoryId
            this.itemId = itemId
            this.availableStock = availableStock
            this.quantity = quantity
            this.status = status
            this.createdAt = createdAt
            this.createdUser = createdUser
            this.updatedAt = updatedAt
            this.updatedUser = updatedUser
            this.versionFlag = versionFlag
            this.isActive = isActive
            this.stockType = stockType
        }
    }
