import { AllRmLevelsResponseModel, RmLevelDropDownResponseModel, RmLevelDto, RmLevelIdRequset, RmLevelResponseModel } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';

export class RmLevelService {

URL = connection.DEPLOY_URL + '/rmlevel';

  async createLevel(levelDto: RmLevelDto): Promise<RmLevelResponseModel> {
    console.log(levelDto);
      return await axios.post(this.URL + '/createLevel',levelDto)
          .then(res => {
              return res.data
          })
  }
  async  updateLevel(levelDto: RmLevelDto): Promise<RmLevelResponseModel> {
    return await axios.post(this.URL + '/updateLevel', levelDto)
           .then(res => {
               return res.data
           })
}
  async getAllLevels(): Promise<AllRmLevelsResponseModel> {
    console.log(URL);
    return await axios.post(this.URL + '/getAllLevels')
        .then(res => {
            return res.data
        })     
}
async getAllLevelDropDown():Promise<RmLevelDropDownResponseModel>{
  return await axios.post(this.URL + '/getAllLevelDropDown')
  .then(res => {
      return res.data
  })   
}

async  ActivateorDeactivateLevel(levelDto: RmLevelDto): Promise<RmLevelResponseModel> {
      return await axios.post(this.URL + '/activateorDeactivateLevel', levelDto)
              .then(res => {
                  return res.data
              })
 }
async  getLevelByLevelId(levelIdRequset: RmLevelIdRequset): Promise<RmLevelResponseModel> {
      return await axios.post(this.URL + '/getLevelByLevelId', levelIdRequset)
              .then(res => {
                  return res.data
              })
 }


} 