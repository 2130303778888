import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ShippingTermsDto } from './shipping-terms-dto';


export class AllShippingTermsResponseModel extends GlobalResponseObject {
    data?: ShippingTermsDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ShippingTermsDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

