export class ProductIdRequest{
    productId:number;
    plantId:number;

    /**
     * 
     * @param productId 
     * @param plantId 
     */
    constructor(productId:number, plantId:number){
        this.productId = productId;
        this.plantId = plantId;
    }
}