import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { IndentModel } from './indent.model';


export class IndentResponse extends GlobalResponseObject{
    data?: IndentModel;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: IndentModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}