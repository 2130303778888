import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import TextArea from 'antd/lib/input/TextArea';
import { SaleOrderDropDownDto, SaleOrderFewDetailsDto } from '@gtpl/shared-models/sale-management';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, ItemsReqForCategoryId, ReasonsDropDown } from '@gtpl/shared-models/masters';
import { ItemsService, ReasonsService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { ConsumedQtyRequest, IssueDropDownDto, StoreConsumptionDetails, StoreConsumptionSosDto, StoreReturnDto, StrRtnCreateDto } from '@gtpl/shared-models/procurement-management';
import { StockService, StoreConsumptionService, StoreReturnService } from '@gtpl/shared-services/procurement';

const { Option } = Select;

/* eslint-disable-next-line */
export interface StoreReturnMainFormProps {
  soNo: number;
  itemId: number;
  form: FormInstance<any>
  saleOrderData: StoreConsumptionSosDto[];
  itemCategoriesData: ItemCategoriesDto[];
  saveStoreReturn: (storeReturnData) => void
}
export function StoreReturnMainForm(
  props: StoreReturnMainFormProps
) {
  useEffect(() => {
    if (props?.soNo != undefined && props?.itemId) {
      form.setFieldsValue({ saleOrderId: props.soNo, itemName: props.itemId });
      getConsumedNumbers(props.soNo);
      getConsumedItemsData();

    }
    form.setFieldsValue({ 'itemCategory': 6 });
    getReasonsDropDownInfo();
  }, [])
  const [saleOrderItemsData, setSaleOrderItemsData] = useState([]);
  const [displayItemsData, setDisplayItemsData] = useState<boolean>(false);
  const [itemsData, setItemsData] = useState<ItemsDropDownDto[]>([])
  const form = props.form;

  const [storeIssuesDropDown, setStoreIssuesDropDown] = useState<IssueDropDownDto[]>([]);
  const [reasonsData, setReasonsData] = useState<ReasonsDropDown[]>([]);
  const [itemCategoryDetails, setItemCategoriesData] = useState<ItemCategoryDropDownDto[]>([]);
  const [itemDetails, setItemDetails] = useState<ItemsDropDownDto>();
  const [availStockQty, setAvailStockQty] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [returnedQty, setReturnedQty] = useState<number>(0);
  const [consumedQuantity, setConsumedQuantity] = useState<number>(0);

  const saleOrderService = new SaleOrderService();
  const itemService = new ItemsService();
  const stockService = new StockService();
  const storeConsumptionService = new StoreConsumptionService();
  const strRtnService = new StoreReturnService();
  const reasonsService = new ReasonsService();
  // get sale order items against sale order 
  const displayItemDetails = () => {
    setDisplayItemsData(true);
  }

  const getReasonsDropDownInfo = () => {
    reasonsService.getReasonsDropDownInfo().then((res) => {
      if (res.status) {
        setReasonsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        setReasonsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setReasonsData([]);
    });
  }


  const getItemsCategoryData = (reqData) => {
    console.log(reqData);
    saleOrderService.getAllBomDetailsBySoId({ saleOrderId: reqData }).then((res) => {
      console.log(res.data);
      if (res.status) {
        setDisplayItemsData(true);
        setItemCategoriesData(res.data);
        setItemsData([]);
        getItemsData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemCategoriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategoriesData([]);
    });
  }

  const getConsumedQty = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    const storeConsumptionId = form.getFieldValue('storeConsumptionId');
    const itemId = form.getFieldValue('itemName');
    if ((storeConsumptionId && itemId) || (saleOrderId && itemId)) {
      const consumedQty: ConsumedQtyRequest = {
        storeConsumptionId: storeConsumptionId,
        saleOrderId: saleOrderId,
        itemId: itemId,
        unitId: Number(localStorage.getItem('unit_id'))
      }
      storeConsumptionService.getConsumedQty(consumedQty).then((res) => {
        form.setFieldsValue({ consumedQuantity: res })
        setConsumedQuantity(res);
      })
    }
  }
  const getAvailableStock = () => {
    const formData = form.getFieldsValue();
    const itemId = formData.itemName;
    const itemCategoryId = formData.itemCategory;
    const saleOrderId = formData.saleOrderId;
    console.log(formData);
    console.log("formData");
    if (itemId) {
      console.log(itemId);
      console.log("itemId");
      getConsumedQty();
      getReturnedQty();
      stockService.getAvailableStockQty({ saleOrderId: saleOrderId, itemCategoryId: itemCategoryId, itemId: itemId, unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
        if (res.status) {
          setAvailStockQty(Number(res.data.physicalQuantity));
          form.setFieldsValue({ availableQty: Number(res.data.physicalQuantity) });
        } else {
          AlertMessages.getErrorMessage("stock not available. ");
          form.setFieldsValue({ availableQty: 0 });
          form.setFieldsValue({ quantity: 0 });
          setQuantity(0);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setAvailStockQty(0);
      });
    }
  }

  const getReturnedQty = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    const storeConsumptionId = form.getFieldValue('storeConsumptionId');
    const itemId = form.getFieldValue('itemName');
    if ((storeConsumptionId && itemId) || (saleOrderId && itemId)) {
      const returnQty: ConsumedQtyRequest = {
        storeConsumptionId: storeConsumptionId,
        saleOrderId: saleOrderId,
        itemId: itemId,
        unitId: Number(localStorage.getItem('unit_id'))
      }
      strRtnService.getReturnedQty(returnQty).then((res) => {
        if (res) {
          setReturnedQty(res);
        } else {
          setReturnedQty(0);
        }
      }).catch((err) => {
        setReturnedQty(0);
      });
    }
  }

  // get items against item category
  const getItems = (itemCategoryId, itemCategoryName) => {
    const itemCategory = new ItemCategoryDropDownDto(itemCategoryId, itemCategoryName.children);
    const itemsRequest = new ItemsReqForCategoryId(itemCategoryId);
    itemService.getItemByCategoryId(itemsRequest).then((res) => {
      if (res.status) {
        setItemsData(res.data);
      } else {
        setItemsData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const setItem = (itemId, itemName) => {
    const item = new ItemsDropDownDto(itemId, itemName.children);
    setItemDetails(item);
  }


  const saveStoreReturn = () => {
    form.validateFields().then(res => {
      const formDetails = form.getFieldsValue();
      const strRtnData: StrRtnCreateDto = {
        saleOrderId: formDetails.saleOrderId,
        storeReturnDate: formDetails.storeReturnDate,
        itemCategoryId: 6,//formDetails.itemCategory,
        itemId: formDetails.itemName,
        returnQuantity: formDetails.returnQuantity,
        remarks: formDetails.remarks,
        createdUser: localStorage.getItem('createdUser'),
        unitId: Number(localStorage.getItem('unit_id')),
        approvar: formDetails.approvar,
        storeConsumptionId: formDetails.storeConsumptionId,
        returnReason: formDetails.returnReason,
      }
      props.saveStoreReturn(strRtnData);
      form.resetFields();
    })
  }
  const clearItemsData = () => {
    form.setFieldsValue({ 'itemName': '' });
  }
  const getItemsData = () => {
    const formData = form.getFieldsValue();
    const itemCategoryId = 6;//formData.itemCategory;
    const saleOrderId = formData.saleOrderId;
    clearItemsData();
    saleOrderService.getBomItemsDetails({ saleOrderId: saleOrderId, categoryId: itemCategoryId }).then((res) => {
      console.log('getItemsData');

      console.log(res.data);
      if (res.status) {
        setItemsData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsData([]);
    });
  }

  const getConsumedItemsData = () => {
    setDisplayItemsData(false);
    const soId = form.getFieldValue('saleOrderId');
    const storeConsumptionId = form.getFieldValue('storeConsumptionId');
    setItemsData([]);
    if (storeConsumptionId || soId) {
      form.setFieldsValue({ availableQty: 0, consumedQuantity: 0, quantity: 0 });
      setQuantity(0);
      setReturnedQty(0);
      setConsumedQuantity(0);
      storeConsumptionService.getConsumedItemsData({ saleOrderId: soId, storeConsumptionId: storeConsumptionId }).then((res) => {
        if (res.status) {
          setItemsData(res.data);
          displayItemDetails();
          if (props?.itemId) {
            getAvailableStock();
          }
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.message);
          } else {
            AlertMessages.getErrorMessage(res.message);
          }
          setItemsData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setItemsData([]);
      });
    }
    if (!props?.itemId) {
      form.setFieldsValue({ itemName: null, availableQty: 0 });
    }
  }

  const getSoContainerStatus = (soId) => {
    saleOrderService.getSOContainerStatus({saleOrderId: soId}).then((res) => {
      if(res.status){
        console.log(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else{
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getConsumedNumbers = (soId) => {
    storeConsumptionService.getConsumedNumbers({ saleOrderId: soId }).then((res) => {
      if (res.status) {
        setStoreIssuesDropDown(res.data);
        displayItemDetails();
        getConsumedItemsData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setStoreIssuesDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setStoreIssuesDropDown([]);
    });
    if (!props?.itemId) {
      form.setFieldsValue({ itemName: '', availableQty: 0 });
    }
    getSoContainerStatus(soId);
  }

  return (
    <Form layout="vertical" form={form} name="control-hooks" >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="storeReturnDate" label="Returned Date" initialValue={moment()} rules={[{ required: true, message: 'Missing Return Date' }]} >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item
            name="approvar"
            label="Person Name"
            rules={[{ required: true, message: 'Missing Person Name' },
            {

              pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
              message: `Should contain only alphabets.`
            }

            ]}>

            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: true, message: 'Missing SO Number' }]}>
            <Select
              showSearch
              placeholder="Select a SO Number"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(saleOrderId) => getConsumedNumbers(saleOrderId)}
              allowClear
              dropdownMatchSelectWidth={false}
            >
              {(props.saleOrderData) ? props.saleOrderData.map(dropData => {
                return <Option value={dropData.saleOrderId}>{`${dropData.saleOrderNo} - ${dropData.poNumber}`}</Option>
              }) : ''}
            </Select>
          </Form.Item>
        </Col>


        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item name="storeConsumptionId" label="Store Issues" rules={[{ required: false, message: 'Missing Store Consumption' }]}>
            <Select
              showSearch
              placeholder="Select a Issue"
              optionFilterProp="children"
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={getConsumedItemsData}
              allowClear
              dropdownMatchSelectWidth={false}
            >
              {(storeIssuesDropDown && storeIssuesDropDown.length) ? storeIssuesDropDown.map(dropData => {
                return <Option value={dropData.storeConsumptionId}>{dropData.storeConsumptionCode}</Option>
              }) : ''}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item
            name="availableQty"
            label="Available Quantity"
            rules={[{ required: true, message: 'Missing Available Quantity' }]}
          >
            <Input readOnly={true} />
          </Form.Item>
        </Col>
      </Row>
      {(displayItemsData) ? <><hr />
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Item Category" name='itemCategory' rules={[{ required: true, message: 'Missing Item Category' }]}>
              <Select showSearch placeholder="Select Item Category" onChange={getItemsData} allowClear
                dropdownMatchSelectWidth={false}
                disabled={true}
              >
                <Option value={''}>Please Select</Option>
                {props.itemCategoriesData.map(category => {
                  return <Option key={category.itemCategoryId} value={category.itemCategoryId}>{category.itemCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item name="itemName" label="Item Name" rules={[{ required: true, message: 'Missing Item' }]}>
              <Select
                showSearch
                placeholder="Select Item"
                onChange={getAvailableStock}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={''}>Please Select</Option>
                {itemsData.map(item => {
                  return <Option value={item.itemId}>{item.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <label><span style={{ color: 'red' }}>*</span>Returned Qty: <span style={{ color: 'red' }}>Returned. Qty :{returnedQty}</span></label>
            <br />
            <Form.Item name="returnQuantity" rules={[{ required: true, message: 'Missing Returned Quantity.' }, { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }, {
              validator: (_, value) =>
                value <= consumedQuantity ? (value == 0) ? Promise.reject(new Error('Returned Qty should be > 0')) : Promise.resolve() : Promise.reject(new Error('Quantity should be <= consumed qty ' + consumedQuantity)),
            }]}>
              <InputNumber
                min={0}
                required={true}
                style={{ width: '100%' }}
              // max={consumedQuantity}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="consumedQuantity" label="Consumed Quantity" rules={[{ required: true, message: 'Missing Consumed Quantity.' }, { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }]}>
              <InputNumber
                min={0}
                required={true}
                style={{ width: '100%' }}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item name="returnReason" label="Return Reason" rules={[{ required: true, message: 'Missing Return Reason' }]}>
              <Select
                showSearch
                placeholder="Select Return Reason"
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
               
               <Option value={'Damage'}>{'Damage'}</Option>
               <Option value={'Excess'}>{'Excess'}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="remarks" label="Remarks" rules={[{ required: true, message: 'Remarks is required' }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item style={{ paddingTop: '25px' }}>
              <Button type='primary' block onClick={saveStoreReturn}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row></> : ''}
    </Form>

  );
}

export default StoreReturnMainForm;
