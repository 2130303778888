export class TypesOfLeavesDto{
    typeOfLeaveId:number;
    typeOfLeave:string;
    leaveCode:string;
    defaultLeaves:number
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;

    constructor(
        typeOfLeaveId:number,
        typeOfLeave:string,
        leaveCode:string,
        defaultLeaves:number,
        createdUser: string,
        isActive: boolean,
        updatedUser?: string,
        versionFlag?: number
    ){
        this.typeOfLeaveId=typeOfLeaveId;
        this.typeOfLeave=typeOfLeave;
        this.leaveCode=leaveCode;
        this.defaultLeaves=defaultLeaves;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        
    }

}