import { FgStatusEnum, GlobalStatus, OperationTypeEnum, PlantsEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class ProdData {
    productionInventoryId?: number;
    lotNumber?: string;
    saleOrderId?:number;
    saleOrderItemId?:number;
    physicalQuantity?: number;
    inputGrade?: number;
    boxes?: number;
    plantId?:number;
    subPlantId?:number;
    weighmentReq: GlobalStatus;
    operation?: OperationTypeEnum;
    nextOperation?: OperationTypeEnum;
    status?: FgStatusEnum;
    createdUser?:string;

}