import axios from 'axios';
import connection from './connection';
import { LabellingDto, AllLabellingResponseModel, LabellingResponseModel, LabelIdRequest } from '@gtpl/shared-models/production-management';
import { CommonResponse } from '@gtpl/shared-models/masters';

export class LabellingService {

    URL = connection.DEPLOY_URL + '/labelling';

    async createLabelling(labellingsdto: LabellingDto): Promise<LabellingResponseModel> {
        console.log(labellingsdto);

        return await axios.post(this.URL + '/createLabelling', labellingsdto)
            .then(res => {
                return res.data
            })
    }

    async getAllLabellings(): Promise<AllLabellingResponseModel> {

        return await axios.post(this.URL + '/getAllLabellings')
            .then(res => {
                return res.data
            })
    }

    async updateLabelling(labellingsdto: LabellingDto): Promise<LabellingResponseModel> {
        return await axios.post(this.URL + '/updateLabelling', labellingsdto)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateLabelling(labellingDto: LabellingDto): Promise<LabellingResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateLabelling', labellingDto)
            .then(res => {
                return res.data
            })
    }


    async getLabellingById(value): Promise<LabellingResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/getLabellingById', value)
            .then(res => {
                return res.data
            })
    }
    async createLabellingInfo(labellingDto: LabellingDto): Promise<CommonResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/createLabellingInfo', labellingDto)
            .then(res => {
                return res.data
            })
    }
    async getAllLabellingSamplesForLabelId(labellingDto: LabelIdRequest): Promise<LabellingResponseModel> {
        return await axios.post(this.URL + '/getAllLabellingSamplesForLabelId', labellingDto)
            .then(res => {
                return res.data
            })
    }

}