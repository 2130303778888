import { ContainerStatusEnum,BlStatusEnum, SaleOrderStatusEnum } from "@gtpl/shared-models/common-models";

export class SoContainerStatusUpdate{
    saleOrderId:number;
    containerStatus?:ContainerStatusEnum;
    containerId?:number;
    docStatus?:string;
    blStatus?:BlStatusEnum;
    soStatus?:SaleOrderStatusEnum;
    is_dispatched?:boolean;

    constructor(saleOrderId:number, containerStatus:ContainerStatusEnum, containerId?:number,docStatus?:string, blStatus?:BlStatusEnum,is_dispatched?:boolean){
        this.saleOrderId = saleOrderId;
        this.containerStatus = containerStatus;
        this.containerId = containerId;
        this.docStatus = docStatus;
        this.blStatus = blStatus;
        this.is_dispatched = is_dispatched;

    }
}