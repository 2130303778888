export class StoreConsumptionItemsDetails {
    itemCategoryId: number;
    itemId: number;
    quantity: number;
    createdUser: string;
    storeConsumptionItemsId?: number;
    storeConsumptionId?: number;
    updatedUser?: string;
    
   /**
    * 
    * @param itemCategoryId 
    * @param itemId 
    * @param quantity 
    * @param createdUser 
    * @param storeConsumptionItemsId 
    * @param storeConsumptionId 
    * @param updatedUser 
    */
    constructor(itemCategoryId: number, itemId: number, quantity: number, createdUser: string, storeConsumptionItemsId?: number, storeConsumptionId?: number, updatedUser?: string){
        this.itemCategoryId = itemCategoryId; 
        this.itemId = itemId; 
        this.quantity = quantity; 
        this.createdUser = createdUser; 
        this.storeConsumptionItemsId = storeConsumptionItemsId; 
        this.storeConsumptionId = storeConsumptionId; 
        this.updatedUser = updatedUser; 
    }
}