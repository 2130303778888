export class ValAddSoakingDto{
    numOfLots: number;
    qtytobeprocessed: number;
    valAddInventory: number;
    gridData:ValAddSoakingGridDto[];
   
    constructor(
        numOfLots: number,
        qtytobeprocessed: number,
        valAddInventory: number,
        gridData:ValAddSoakingGridDto[]
    ){
        this.numOfLots = numOfLots;
        this.qtytobeprocessed = qtytobeprocessed;
        this.valAddInventory = valAddInventory;
        this.gridData = gridData;
    }
}

export class ValAddSoakingGridDto{
   lotNo: string;
   soNumber: string;
   variantCode: string;
   soakingStyle: string;
   soakingTime: string;
   quantity: number;
   issuedQuantity: number;
   saleOrderId?:number;
   poNumber?:string;

   constructor(
    lotNo: string,
    soNumber: string,
    variantCode: string,
    soakingStyle: string,
    soakingTime: string,
    quantity: number,
   issuedQuantity: number,
   saleOrderId?:number,
   poNumber?:string
   ){
     this.lotNo = lotNo;
     this.soNumber = soNumber;
     this.variantCode = variantCode;
     this.soakingStyle = soakingStyle;
     this.soakingTime = soakingTime;
     this.quantity = quantity;
     this.issuedQuantity = issuedQuantity;
     this.saleOrderId = saleOrderId;
     this.poNumber = poNumber;
   }
}