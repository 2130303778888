import { OperationTypeEnum, QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";

export class QaQcSampleData{
    testSampleId?:number;
    lotNumber:string;
    subLotNumber: string;
    saleOrderId:number;
    saleOrderItemId:number;
    shift:ShiftsEnum;
    operation:OperationTypeEnum;
    workstation:number;
    qaQcTestId:number;
    noOfSamples:number;
    count:number;
    quantityTested:number;
    rejectedQuantity:number;
    rejectionReason:RejectionReasonsEnum;
    testResult:QaQcTestResultEnum;
    remarks:string;
    plantId:number;

    /**
     * 
     * @param testSampleId 
     * @param lotNumber 
     * @param saleOrderId 
     * @param saleOrderItemId 
     * @param shift 
     * @param operation 
     * @param workstation 
     * @param qaQcTestId 
     * @param noOfSamples 
     * @param quantityTested 
     * @param rejectedQuantity 
     * @param rejectionReason 
     * @param testResult 
     * @param remarks 
     */
    constructor(lotNumber:string, saleOrderId:number, saleOrderItemId:number, shift:ShiftsEnum, operation:OperationTypeEnum, workstation:number, qaQcTestId:number, noOfSamples:number, count:number, quantityTested:number, rejectedQuantity:number, rejectionReason:RejectionReasonsEnum, testResult:QaQcTestResultEnum, remarks:string, plantId:number, testSampleId?:number, ){
        this.testSampleId = testSampleId;
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.shift = shift;
        this.operation = operation;
        this.workstation = workstation;
        this.qaQcTestId = qaQcTestId;
        this.noOfSamples = noOfSamples;
        this.count = count;
        this.quantityTested = quantityTested;
        this.rejectedQuantity = rejectedQuantity;
        this.rejectionReason = rejectionReason;
        this.testResult = testResult;
        this.remarks = remarks;
        this.plantId = plantId;
    }

}