export class MasterBrandsDropDownDto{
    masterBrandId:number;
    masterBrandName:string;

  /**
   * 
   * @param masterBrandId 
   * @param masterBrandName 
   */
    constructor(masterBrandId:number, masterBrandName:string){
         this.masterBrandId = masterBrandId;
         this.masterBrandName = masterBrandName;
    }
}