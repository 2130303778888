import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SubPlantHistoryDto } from './sub-plant-history.dto';

export class ProductionLogDetailsResponseModel extends GlobalResponseObject{
    data?: SubPlantHistoryDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //SubPlantHistoryDto
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: SubPlantHistoryDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
