import { LeaveApprovalStatusEnum } from "@gtpl/shared-models/common-models";

export class OverTimeDTO {

    employeeCode: string;
    employeeName: string;
    date: string;
    inTime: Date;
    outTime: Date;
    workingHours: number;
    otType: string;
    otHours: any;
    status: LeaveApprovalStatusEnum;

    constructor(employeeCode: string, employeeName: string, date: string, inTime: Date, outTime: Date, workingHours: number, otType: string, otHours: any, status: LeaveApprovalStatusEnum) {
        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.date = date;
        this.inTime = inTime;
        this.outTime = outTime;
        this.workingHours = workingHours;
        this.otType = otType;
        this.otHours = otHours;
        this.status = status;

    }
}