export class PaymentmodesDto{
    paymentModeId:number;
    paymentMode:string;
    createdUser:string;
    isActive:boolean;
    updatedUser?:string
    
    /**
     * 
     * @param paymentModeId 
     * @param paymentMode 
     * @param createdUser
     * @param isActive
     * @param updatedUser
     */
    constructor(paymentModeId:number,paymentMode:string,createdUser:string,isActive:boolean,updatedUser?:string){
    this.paymentModeId = paymentModeId;
    this.paymentMode = paymentMode;
    this.createdUser = createdUser;
    this.isActive = isActive
    this.updatedUser=updatedUser;
    }
}