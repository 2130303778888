export class RacksForZoneId {
    zoneId: number;
    reservedForDummy?: boolean;

    /**
     * 
     * @param zoneId 
     */
    constructor(zoneId: number,reservedForDummy?:boolean){
        this.zoneId = zoneId;
        this.reservedForDummy = reservedForDummy;
    }
}

export class ReserveRequest {
    plantId:number;
    positions:number;
    saleOrderId?:number;
    constructor( plantId:number,
        positions:number,saleOrderId?:number){
            this.plantId = plantId;
            this.positions = positions;
            this.saleOrderId = saleOrderId;
    }
}
export class RacksForRackId {
    rackId: number;
    saleOrderId?:number;

    /**
     * 
     * @param rackId 
     */
    constructor(rackId: number,saleOrderId?:number){
        this.rackId = rackId;
        this.saleOrderId = saleOrderId;
    }
}
export class ReserveRackRequest {
    rackId: number;
    saleOrderId: number;

    /**
     * 
     * @param rackId 
     */
    constructor(rackId: number,saleOrderId:number){
        this.rackId = rackId;
        this.saleOrderId = saleOrderId;
    }
}
export class RacksForRackIds {
    rackId: string[];

    /**
     * 
     * @param rackId 
     */
    constructor(rackId: string[]){
        this.rackId = rackId;
    }
}

