import { NotificationStatus } from "@gtpl/shared-models/common-models";

export class NotificationDto{
    notificationId: number;
    plant : string;
    plantId : number;
    jobRole : string;
    jobDescription: string;
    notificationDate: any;
    resourceRequired: string;
    skills:string;
    yearOfExperience:string;
    plannedClosingDate: any;
    remarks: string;
    status: NotificationStatus;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;
  viewData: any;

    constructor(
    notificationId: number,
    plant : string,
    plantId : number,
    jobRole : string,
    jobDescription: string,
    notificationDate: any,
    resourceRequired: string,
    skills:string,
    yearOfExperience:string,
    plannedClosingDate: any,
    remarks: string,
    status: NotificationStatus,
    isActive: boolean,
    createdAt: Date | any,
    createdUser: string,
    updatedAt: Date | any,
    updatedUser: string,
    versionFlag: number,
    ){
        this.notificationId = notificationId;
        this.plant = plant;
        this.plantId = plantId;
        this.jobRole = jobRole;
        this.jobDescription = jobDescription;
        this.notificationDate = notificationDate;
        this.resourceRequired = resourceRequired;
        this.skills = skills;
        this.yearOfExperience = yearOfExperience;
        this.plannedClosingDate = plannedClosingDate;
        this.remarks = remarks;
        this.status = status;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}