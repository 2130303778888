import axios from 'axios';
import connection from './connection';
import { AllHolidaysCalendarResponseModel, HolidaysCalendarDto, HolidaysResponseModel} from '@gtpl/shared-models/hrms';
import { CommonResponse } from '@gtpl/shared-models/masters';

export class HolidaysCalendarService{

    URL = connection.DEPLOY_URL + '/holidays-calender';

    async createHoliday(HolidaysCalendarDto: HolidaysCalendarDto ): Promise<HolidaysResponseModel> {
        console.log(HolidaysCalendarDto);
       
        return await axios.post(this.URL + '/createHoliday',HolidaysCalendarDto)
            .then(res => {
                return res.data
            })
    }

    async getAllHolidays(): Promise<AllHolidaysCalendarResponseModel> {
                
        return await axios.post(this.URL + '/getAllHolidays')
            .then(res => {
                return res.data 
            })     
    }

    async  updateHoliday(HolidaysCalendarDto: HolidaysCalendarDto): Promise<HolidaysResponseModel> {
        return await axios.post(this.URL + '/updateHoliday', HolidaysCalendarDto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateHoliday(HolidaysCalendarDto: HolidaysCalendarDto): Promise<HolidaysResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateHoliday', HolidaysCalendarDto)
                        .then(res => {
                                return res.data
                        })
                }

     async getHolidaysForYear(): Promise<CommonResponse> {
                
                    return await axios.post(this.URL + '/getHolidaysForYear')
                        .then(res => {
                            return res.data 
                        })     
                }
                
}