

export class ShiftAttendanceRequest {
    fromDate: string;
    toDate: string;
    shift?: string;

    /**
     * 
     * @param fromDate 
     * @param toDate 
     * @param shift 
     */
    constructor(fromDate: string, toDate: string, shift?: string) {
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.shift = shift;
    }
}