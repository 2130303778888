import React, { useEffect, useRef, useState } from 'react';
import { Card,Button, Descriptions, Layout, Table, Divider, Popconfirm, Switch, Tag, Rate, Input, Row, Col } from 'antd';
import {RollbackOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
// import Link from 'antd/lib/typography/Link';
import { Route, Link, useHistory, withRouter, BrowserRouter as Router, } from 'react-router-dom';
// import {SaleOrderDetails} from '../data';
import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import { AlertMessages} from '../../../../../../shared-utils/alert-messages/src';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import { ColumnProps } from 'antd/lib/table';
import { CrrencySymbols, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO } from '@gtpl/shared-models/sale-management';
import axios from 'axios';
import { appSettings } from 'apps/services/config';
import { createBrowserHistory} from 'history';

import './so-info-view.css';
import DescriptionsItem from 'antd/lib/descriptions/Item';

/* eslint-disable-next-line */
export interface SoInfoViewProps {
  SaleOrderDetails:SaleOrderDetailViewInfoDTO;
  netWeightConversion:number;
  netWeightInKg:number;
  screen:string;
}

export function SoInfoView(
  props: SoInfoViewProps
) {

  useEffect(() => {
    console.log(props.SaleOrderDetails);
  }, [])
  const [page, setPage] = React.useState(1);
  const [columnVisible, setColumnVisible] = useState<boolean>(false);
  const searchInput = useRef(null);

 
  let weight = 0;
  const tableColumns = () => {

  const sampleTypeColumns: ColumnProps<any>[] = [
    
    {
      title: 'SNo',
      key: 'sno',
      width:70,
      align: 'center',
      fixed:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Brand',
      dataIndex: 'brand',
      width:90,
      // fixed:'left',
      align: 'center',
      // ...getColumnSearchProps('brand'),

      // // responsive: ['lg'],
      // sorter: (a, b) => a.brand.localeCompare(b.brand),
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'product',
    //   width:90,
      
    //   align: 'center',
      // ...getColumnSearchProps('product'),

      // // responsive: ['lg'],
      // sorter: (a, b) => a.product.localeCompare(b.product),
      // sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Grade',
    //   dataIndex: 'minGrade',
    //   width:70,
      
    //   align: 'center',
      // ...getColumnSearchProps('minGrade'),

      // responsive: ['lg'],
      // render: (text,record) => {return record.minGrade + ' - ' + record.maxGrade},
      // sorter: (a, b) => a.minGrade.length - b.minGrade.length,
      // sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Product SKU',
      dataIndex: 'varient',
      width:120,
      
      align: 'center',
      // ...getColumnSearchProps('varient'),

      // // responsive: ['lg'],
      // sorter: (a, b) => a.varient.localeCompare(b.varient),
      // sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Pack Type',
      dataIndex: 'packingStyle',
      width:150,
      
      align: 'center',
      // ...getColumnSearchProps('packingStyle'),

      render: (text,record) => {return ` ${Math.round(record.pouches)}` + " * " + ` ${Math.round(record.pouchWeight)}` + ' ' + record.uom},

      // responsive: ['lg'],
      // sorter: (a, b) => a.packingStyle.length - b.packingStyle.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Net Case Weight',
      dataIndex: 'caseWeight',
      width:100,
      
      align: 'center',
      // ...getColumnSearchProps('caseWeight'),

      // responsive: ['lg'],
      render: (text,record) => {return ` ${Math.round(record.caseWeight)}` + ' ' + record.uom},

      // sorter: (a, b) => a.caseWeight - b.caseWeight,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      // responsive: ['lg']
      width:80,
      
      align: 'center',
      // ...getColumnSearchProps('cases'),

      // sorter: (a, b) => a.cases - b.cases,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fg Quantity',
      dataIndex: 'reportedCases',
      // responsive: ['lg']
      width:80,
      
      align: 'center',
      // ...getColumnSearchProps('cases'),

      // sorter: (a, b) => a.cases - b.cases,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Wip Quantity',
      // dataIndex: 'wipQuantity',
      render: (value,record) =>{
       return record.wipQuantity == null ? 0:record.wipQuantity
      },
      // responsive: ['lg']
      width:80,
      
      align: 'center',
      // ...getColumnSearchProps('cases'),

      // sorter: (a, b) => a.cases - b.cases,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      width:100,
      align: 'center',
      render: (text,record) => {
        if(record.uomId == 1){
          let totWeightConversion = record.netAmount * 2;
          weight = totWeightConversion;
        }
        return `${Math.round(record.netWeight)}` + ' ' + record.uom},
    },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unitPrice',
    //   width:130,
      
    //   align: 'center',
    //   // ...getColumnSearchProps('unitPrice'),
    //   render: (text,record) => {return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " "  + `${Math.round(record.unitPrice)}`},

    //   // responsive: ['lg'],
    //   // sorter: (a, b) => a.unitPrice - b.unitPrice,
    //   // sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Net Drained Weight',
      dataIndex: 'shrimpWeight',
      align: 'center',
      // fixed:'right',

      width: 100,
      render: (text,record) => {return `${Math.round(record.shrimpWeight)}`},

      // responsive: ['lg'],
      // ...getColumnSearchProps('shrimpWeight'),

      // sorter: (a, b) => a.shrimpWeight - b.shrimpWeight,
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Net Amount',
    //   dataIndex: 'netAmount',
    //   width:100,
      
    //   align: 'right',
    //   // ...getColumnSearchProps('netAmount'),
    //   render: (text,record) => {
       
    //     return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + `${Math.round(record.netAmount)}`
    //   },


      // responsive: ['lg'],
      // sorter: (a, b) => a.netAmount - b.netAmount,
      // sortDirections: ['descend', 'ascend'],
    // }, 
    {
      title: 'Style Weighment',
      dataIndex: 'styleWeighment',
      align: 'center',
      width:100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('styleWeighment'),

      // sorter: (a, b) => a.styleWeighment.localeCompare(b.styleWeighment),
      // sortDirections: ['descend', 'ascend'],
    }, 

   
    // {
    //   title: 'Glazing',
    //   dataIndex: 'minGlaze',
    //   align: 'center',
    //   width:100,
    //   // responsive: ['lg'],
    //   render: (text,record) => {return record.minGlaze + ' - ' + record.maxGlaze},
    //   // sorter: (a, b) => a.minGlaze.length - b.minGlaze.length,
    //   // sortDirections: ['descend', 'ascend'],
    //  }, 
    // {
    //   title: 'Soaking Time',
    //   dataIndex: 'soakingTime',
    //   align: 'center',
    //   width:100,
    //   // responsive: ['lg'],
    //   // ...getColumnSearchProps('soakingTime'),

    //   // sorter: (a, b) => a.soakingTime - b.soakingTime,
    //   // sortDirections: ['descend', 'ascend'],
    // }, 
    // {
    //   title: 'Soaking Style',
    //   dataIndex: 'soakingStyle',
    //   align: 'center',
    //   // fixed:'right',

    //   width: 100,
    //   // responsive: ['lg'],
    //   // ...getColumnSearchProps('soakingStyle'),

    //   // sorter: (a, b) => a.soakingStyle.length - b.soakingStyle.length,
    //   // sortDirections: ['descend', 'ascend'],
    // }, 
    
    
  ]
  const invoiceColumns: ColumnProps<any>[] =
  [
    {
      title: 'Dispatch Quantity',
      dataIndex: 'dispatchQty',
      align: 'center',
      // fixed:'right',

      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('dispatchQty'),

      // sorter: (a, b) => a.dispatchQty - b.dispatchQty,
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Invoice Amount',
    //   dataIndex: 'invoiceAmnt',
    //   align: 'right',
    //   // fixed:'right',

    //   width: 100,
    //   // responsive: ['lg'],
    //   // ...getColumnSearchProps('invoiceAmnt'),

    //   // sorter: (a, b) => a.invoiceAmnt - b.invoiceAmnt,
    //   // sortDirections: ['descend', 'ascend'],
    //   render: (text,record) => {
    //     return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + `${Math.round(record.invoiceAmnt)}`
    //   },
    // }
  
  ];
  const skewersColumns: ColumnProps<any>[] =
  [
    {
      title: 'Skewers',
      dataIndex: 'innerBags',
      align: 'center',
      // fixed:'right',
      // visible:{props.SaleOrderDetails.category==="Skewers"?true:false},
      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('innerBags'),
      // sorter: (a, b) => a.innerBags - b.innerBags,
      // sortDirections: ['descend', 'ascend'],
    }, 
    {
      title: 'Skewer Variety',
      dataIndex: 'innerBagSizeId',
      align: 'center',
      // fixed:'right',
      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('innerBagSizeId'),

      // sorter: (a, b) => a.innerBagSizeId.localeCompare(b.innerBagSizeId),
      // sortDirections: ['descend', 'ascend'],
    }
  
  ];
  
  if(props.SaleOrderDetails.category === "Skewers" && props.SaleOrderDetails.isInvoiced === "YES"){
    return [...sampleTypeColumns,...invoiceColumns,...skewersColumns];
  }
  else if(props.SaleOrderDetails.category !== "Skewers" && props.SaleOrderDetails.isInvoiced === "YES"){
    return [...sampleTypeColumns,...invoiceColumns];
  }
  else if(props.SaleOrderDetails.category === "Skewers" && props.SaleOrderDetails.isInvoiced === "NO"){
    return [...sampleTypeColumns,...skewersColumns];
  }
  else if(props.SaleOrderDetails.category !== "Skewers" && props.SaleOrderDetails.isInvoiced === "NO"){
    return sampleTypeColumns;
  }
  else{
    return sampleTypeColumns;
  }
}


  
  
  // const history = useHistory();
  

  

  return (
    <>
  
    {/* <Layout style={{padding:10, backgroundColor:'white' , border:10}}> */}
    {props.SaleOrderDetails?
    <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>SO Detail View</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}}  
              >
        <br></br>
        
       
              
         <>
          <Card title={<span style={{ color: 'black', border: 0 }}>Customer Details</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0, maxHeight:20 }}>
                 
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }} >
         
            <Descriptions.Item label="SO Number"  >
              {props.SaleOrderDetails.saleOrder}
            </Descriptions.Item>
         
            <Descriptions.Item label="Customer Name">
              {props.SaleOrderDetails.customer}
            </Descriptions.Item>
          
            
            <Descriptions.Item label="End Customer">
              {props.SaleOrderDetails.endCustomer}
            </Descriptions.Item>
          
          </Descriptions>
          
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="PO Date">
                {props.SaleOrderDetails.invDate}
              </Descriptions.Item>
              <Descriptions.Item label="Customer PO Number">
                {props.SaleOrderDetails.poNo}
              </Descriptions.Item>
              <Descriptions.Item label="Buyer PO Number">
                {props.SaleOrderDetails.buyerPoNo}
              </Descriptions.Item>
          </Descriptions>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            {props.SaleOrderDetails.etd === props.SaleOrderDetails.actualEtd ? 
              <Descriptions.Item label="Sale Person">
                {props.SaleOrderDetails.salePerson}
              </Descriptions.Item> :
              <Descriptions.Item label="Revised ETD">
                {props.SaleOrderDetails.actualEtd}
              </Descriptions.Item>
            }
            <Descriptions.Item label="ETD">
              {props.SaleOrderDetails.etd}
            </Descriptions.Item>
            
            <Descriptions.Item label="ETA">
              {props.SaleOrderDetails.eta}
            </Descriptions.Item>
          </Descriptions>
          <div style={{ display: props.screen == ('production' || 'rm-bom-tracker') ? 'none' : 'block' }}>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }} >
          <Descriptions.Item label="Port Of Entry">
              {props.SaleOrderDetails.portOfEntry}
            </Descriptions.Item>
            <Descriptions.Item label="Final Country">
              {props.SaleOrderDetails.country}
            </Descriptions.Item>
            <Descriptions.Item label="Final Destination">
              {props.SaleOrderDetails.finalDestination}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Delivery Terms">
              {props.SaleOrderDetails.deliveryTerms}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Terms">
              {props.SaleOrderDetails.paymentTerms}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Terms">
              {props.SaleOrderDetails.shippingTerms}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
          
            <Descriptions.Item label=" Processing Unit">
              {props.SaleOrderDetails.unit}
            </Descriptions.Item>
            <Descriptions.Item label="Currency">
              {props.SaleOrderDetails.currency}
            </Descriptions.Item>
          <Descriptions.Item label="Category">
              {props.SaleOrderDetails.category}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Customer Address" style={{width:'33%', textAlign:'left'}} >
              {props.SaleOrderDetails.address}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Address" style={{width:'33%', textAlign:'left'}}>
              {props.SaleOrderDetails.destinationAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Is Invoiced?" style={{width:'33%', textAlign:'left'}}>
              {props.SaleOrderDetails.isInvoiced}
            </Descriptions.Item>
          </Descriptions>
          </div>
          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            {props.SaleOrderDetails.etd === props.SaleOrderDetails.actualEtd ?
                "":
                <Descriptions.Item label="Sale Person">
                  {props.SaleOrderDetails.salePerson}
                </Descriptions.Item> 
            }
          </Descriptions>

        </Card>
            
          <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Product Details</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>
              <Table
                rowKey={record => record.Id}
                // columns={props.SaleOrderDetails.length>0?sampleTypeColumns:null}
                columns={(props.SaleOrderDetails.itemData).length==0?null:tableColumns()}
                dataSource={props.SaleOrderDetails.itemData}
                pagination={{ onChange(current) { setPage(current); } }}
                scroll={{ x: true }}
                size="middle"
                bordered
              ></Table>

              
              {(props.SaleOrderDetails.itemData).length==0?null:
              <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 2, sm: 2, xs: 2 }}>
              <Descriptions.Item label="Total Weight(In Lb):">
                {Math.round(props.netWeightConversion)}
              </Descriptions.Item>
              <Descriptions.Item label="Total Weight(In Kgs):">
                 {Math.round(props.netWeightInKg)}
              </Descriptions.Item>
              
              {/* <Descriptions.Item label="Total Amount:">  {CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + props.SaleOrderDetails.totAmount}
              </Descriptions.Item> */}
              <Descriptions.Item label="Total Poches:">
              {props.SaleOrderDetails.totPouches}
              </Descriptions.Item>
              <Descriptions.Item label="Total Cases:">
              {props.SaleOrderDetails.totCases}
              </Descriptions.Item>
             </Descriptions>
         }

              {/* {(props.SaleOrderDetails.itemData).length==0?null:
                <><span><b>Total Weight(In LB) : {Math.round(props.netWeightConversion)}&nbsp;&nbsp;&nbsp;Total Weight(In KG) : {Math.round(props.netWeightInKg)}&nbsp;&nbsp;&nbsp;Total Amount : {CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + props.SaleOrderDetails.totAmount} &nbsp;&nbsp;&nbsp;Total Pouches : {props.SaleOrderDetails.totPouches} &nbsp;&nbsp;&nbsp;Total Cases : {props.SaleOrderDetails.totCases}</b></span></>
              } */}
            </Card>
            <br></br>
            <div style={{ display: props.screen == 'rm-bom-tracker' ? 'block' : 'none' }}>
            <Card title={<span style={{ color: 'black', border: 0 }}>Certifications</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0, maxHeight:20 }}>
              <Descriptions column={3}>
          
                <Descriptions.Item label="Certification">
                  {props.SaleOrderDetails.certfication}
                </Descriptions.Item>
                {props.SaleOrderDetails.certfication=="ASC" ?
                  <Descriptions.Item label="Certification Approved?">
                    {props.SaleOrderDetails.isCertified}
                  </Descriptions.Item>
                   :props.SaleOrderDetails.certfication=="BAP" ?
                   <Descriptions.Item label="Rating">
                    <Rate value={props.SaleOrderDetails.rating} count={4}/>
                  </Descriptions.Item>
                  :""
                }
                
                
                
              </Descriptions>
              </Card>
              </div>
         
           
            
            <Row gutter={24}>
                    <Col span={12}>
                        <Card title={<span style={{ color: 'black' }}>Lab Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Inspection">
                              {props.SaleOrderDetails.sgsInspection?props.SaleOrderDetails.sgsInspection:"-"}
                            </Descriptions.Item>
                           
                            <Descriptions.Item label="Lot Code">
                              {props.SaleOrderDetails.lotCode?props.SaleOrderDetails.lotCode:"-"}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card title={<span style={{ color: 'black' }}>QA/QC Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                          <Descriptions  column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Surveyer Loading">
                              {props.SaleOrderDetails.loadInspection}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                    </Col>
                </Row>
           <br></br></>
       
        </Card>:''}<br></br>
   {/* </Layout> */}

    </>
  );

  
}

export default SoInfoView;
