import React, { useEffect, useState } from 'react';

import './store-return-form.css';
import {Button, Card, Form, Table} from 'antd';
import { StockService, StoreConsumptionService, StoreReturnService } from '@gtpl/shared-services/procurement';
import { StoreConsumptionDetails, StoreConsumptionSosDto, StoreReturnDto, StrRtnCreateDto } from '@gtpl/shared-models/procurement-management';
import { Link, Redirect, useLocation } from 'react-router-dom';
import StoreReturnMainForm from './store-return-main-form';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, ProductDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemCategoryService } from '@gtpl/shared-services/masters';
import { SaleOrderDropDownDto, SaleOrderFewDetailsDto } from '@gtpl/shared-models/sale-management';
import { UnitRequest } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface StoreReturnFormProps {}


export function StoreReturnForm(
  props: StoreReturnFormProps
) {
  useEffect(() => {
    getItemCategories();
    getSaleOrderData();
  }, []);

  let location = useLocation();
  const consumption :any= location.state;
  console.log(consumption)
  if(consumption != undefined){

  }
  const [storeReturnForm] = Form.useForm();
  const [saleOrderData, setSaleOrderData] = useState<StoreConsumptionSosDto[]>([])
  const [itemCategoriesData, setItemCategoriesData] = useState<ItemCategoriesDto[]>([]);
  const [redirectView, setRedirectView] = useState<boolean>(false)
  const [itemsdata, setItemsdata] = useState<ItemsDropDownDto[]>([]);
  
  const saleOrderService = new SaleOrderService();
  const itemCategoryService = new ItemCategoryService();
  const strRtnService = new StoreReturnService();
  const stockService = new StockService();
  const storeConsumptionService = new StoreConsumptionService();



  // get sale order drop down details
  const getSaleOrderData = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getStoreConsumptionSOs(req).then((res) => {

    // })
    // stockService.getAvailableStockSosForReturns().then((res) => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  
   // get item categories data
   const getItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then((res) => {
      if (res.status) {
        setItemCategoriesData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemCategoriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategoriesData([]);
    });
  }

  const getItemsData = (reqData,saleorder) => {
    console.log(reqData,saleorder);
    saleOrderService.getBomItemsDetails({saleOrderId:saleorder,categoryId:reqData}).then((res) => {
      console.log(res.data);
      if (res.status) {
        setItemsdata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemsdata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsdata([]);
    });
  }

  const goToView = () => {
    setRedirectView(true);
  }
  const saveStoreReturn = (storeReturnData:StrRtnCreateDto) => {
    strRtnService.createStoreReturn(storeReturnData).then((res) => {
      if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          goToView();
      } else {
          AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  return (
    <>
    {(redirectView) ? <Redirect key={Date.now()} to={{pathname:"/store-return"}}/>:null}

    <Card title={<span style={{color:'white'}}>Store Return</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/store-return' ><Button className='panel_button' >View </Button></Link>}>
         <StoreReturnMainForm soNo={consumption?.returnData?.saleOrderId} itemId={consumption?.returnData?.itemId} form={storeReturnForm} saleOrderData={saleOrderData} itemCategoriesData={itemCategoriesData} saveStoreReturn={saveStoreReturn}/>
    </Card>
  </>

  );
}

export default StoreReturnForm;
