
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { TemperatureResponse } from './temp.response';

export class TemperatureLogResponse extends GlobalResponseObject {
    data?: TemperatureResponse;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: TemperatureResponse) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 