import { RmEvaluationDto, RmEvaluationSamplesDto, RmEvaluationResponse, GrnRequest } from "@gtpl/shared-models/raw-material-procurement";
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";

export class RMEvaluationService{
    URL = connection.DEPLOY_URL+ '/rm-evaluation';

    async createRmEvaluation(rmEvalData: RmEvaluationDto): Promise<CommonResponse>{
        return await axios.post(this.URL+ '/createRmEvaluation', rmEvalData)
            .then(res => {
                return res.data
            })
    }

    async getRmEvaluation(grnReq): Promise<RmEvaluationResponse>{
        return await axios.post(this.URL+ '/getRmEvaluationInfoByGrnId', grnReq)
            .then(res => {
                return res.data
            })
    }
}