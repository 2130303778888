import { LocationStatus } from "./loaction-status.enum";

export class LocationDetails{
    locationId?: number;
    rackId: number;
    zoneId:number;
    levelId:number;
    columnId:number;
    rackName:string;
    zoneName:string;
    levelName:string;
    columnsCode:string;
    locationStatus: LocationStatus;
    locationName: string;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag?: number;


    /**
     * 
     * @param locationId
     * @param locationName
     * @param rackId
     * @param zoneId
     * @param levelId
     * @param columnId
     * @param rackName
     * @param zoneName
     * @param levelName
     *@param columnsCode
     * @param locationStatus
     * @param createdUser
     * @param isActive
     * @param versionFlag
     */

    constructor( rackId:number,zoneId:number,levelId:number,columnId:number,rackName:string,zoneName:string,levelName:string,columnsCode:string,locationStatus: LocationStatus,locationName: string, createdUser:string,locationId?: number, isActive?:boolean, updatedUser?: string, versionFlag?: number){
        this.rackId = rackId;
        this.zoneId =zoneId;
        this.levelId = levelId;
        this.columnId =columnId;
        this.rackName =rackName;
        this.zoneName =zoneName;
        this.levelName =levelName;
        this.columnsCode =columnsCode;
        this.locationStatus=locationStatus;
        this.locationName =locationName;
        this.locationId =locationId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
    }
}