import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { RejectionReasonDto } from "./grn-rejection-reason.dto";

export class GrnRejectionReasonResponse extends GlobalResponseObject{

      data?: RejectionReasonDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: RejectionReasonDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }

}