import React, { useEffect, useRef, useState } from 'react';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { IndentQuantityRequest, RMFiltersRequest } from '@gtpl/shared-models/raw-material-procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import './rm-procurement-dashboard.css';
import { BomTrackerGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/bom-tracker-grid'
import { RawMaterialGrnGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-grid';
import { IndentGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-grid';
import { SuppliersGrid } from '@gtpl/pages/master/master-components/suppliers-grid';
import {
  Divider,
  Table,
  Typography,
  Popconfirm,
  Card,
  Tooltip,
  Switch,
  Input,
  Button,
  Tag,
  Row,
  Col,
  Drawer,
  Tabs,
  Spin,
  Empty,
  Form,
  Select,
  InputNumber,
  Modal,
  Badge,
  Alert,
} from 'antd';
import { Link, Redirect, Route } from 'react-router-dom';
import { RmBomDto } from '@gtpl/shared-models/sale-management';
import { ColumnProps } from 'antd/lib/table';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RightSquareOutlined,
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { RmBomData } from 'libs/shared-models/sale-management/src/lib/bom/rmBomData';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from "highcharts/modules/drilldown.js";
// import drilldown from 'highcharts-drilldown';
import { Series } from '@gtpl/shared-models/common-models';
import { CountryService } from '@gtpl/shared-services/masters';
import { CountryDto } from '@gtpl/shared-models/masters';
import { PONumbersDropDownDto } from 'libs/shared-models/sale-management/src/lib/sale-order/po-number-dropdown.dto';
import Title from 'antd/lib/typography/Title';
import { PoInfo } from 'libs/shared-models/sale-management/src/lib/bom/po-info';
import { countRangeWiseInfo } from 'libs/shared-models/sale-management/src/lib/bom/product-count-wise-bom-info';
import { seriesInfo } from 'libs/shared-models/sale-management/src/lib/bom/series-info';
import modal from 'antd/lib/modal';
drilldown(Highcharts)


/* eslint-disable-next-line */
export interface RmProcurementDashboardProps {}

export function RmProcurementDashboard(props: RmProcurementDashboardProps) {
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const saleService = new SaleOrderService();
  const [bomData, setBomData] = useState<RmBomData>();
  const [dataState, setDataState] = useState<boolean>(true);
  const [filtered7daysData, setFiltered7daysData] = useState<RmBomDto[]>([]);
  const [filtered15daysData, setFiltered15daysData] = useState<RmBomDto[]>([]);
  const [filtered30daysData, setFiltered30daysData] = useState<RmBomDto[]>([]);
  const [filteredabove30daysData, setFilteredabove30daysData] = useState<RmBomDto[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [visible, setVisible] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRMModalVisible, setIsRMModalVisible] = useState(false);
  const [isIndentModalVisible, setIsIndentModalVisible] = useState(false);
  const [isFarmerModalVisible, setIsFarmerModalVisible] = useState(false);
  const [isGrnModalVisible, setIsGrnModalVisible] = useState(false);
  const [countriesData, setcountriesData] = useState<CountryDto[]>([]);
  const [tabKey, setTabKey] = useState<number>(0);
  const [minCount, setMinCount] = useState<number>(0);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [poNumbers, setPoNumbers] = useState<PONumbersDropDownDto[]>([]);
  const [poTableData, setPoTableData] = useState<PoInfo[]>([]);

const { Option } = Select;
const { Text, Link } = Typography;
const countriesservice = new CountryService();
const ratingInput = [
  {id:1, value:1},{id:2, value:2},{id:3, value:3},{id:4, value:4}
]



  //graph info
  const [zeroToSevenCount, setZeroToSevenCount] = useState<number[]>([]);
  const [zeroToSevenQTY, setZeroToSevenQTY] = useState<number[]>([]);
  const [seriesData, setSeriesData] = useState<Series[]>();
    const [form] = Form.useForm();


  useEffect(() => {
    getRmBomData();
    getAllFinalCountries();
    getAllPoNumbers();
  }, []);

  // useEffect(() => {
  //   console.log(bomData)
  // },[bomData])

  
  const getAllPoNumbers = () => {
    saleService.getPoNumbers().then((res) => {
      if (res.status) {
        setPoNumbers(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPoNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPoNumbers([]);
    });
  }
  const getAllFinalCountries = () => {
    countriesservice.getAllActiveCountries().then((res) => {
      if (res.status) {
        setcountriesData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setcountriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setcountriesData([]);
    });
  }

  const setPoWiseData = (poWiseData) => {
    console.log(poWiseData)
    setPoTableData(poWiseData);
  }

 

  

  const constructChartData = (bomData: seriesInfo[],drilldownInfo:seriesInfo[],xaxisKeys) => {
    const seriesInfo: Series[] = [];


    const options = {
      chart: {
        type: 'column',
        
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        },
        column: {
          dataLabels: {
              enabled: true,
              // color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'black',
              formatter: function(){
              return (this.y!=0)?this.y:"";
              }
          }
      }
      },
      title: {
        text: '',
        style: {
          color: '#00008B',
          fontWeight: 'bold',
        },
      },
      labels: {
        style: {
          fontWeight: 'bold',
        },
      },
      xAxis: {
        title: {
          text: 'Count Range',
        },
        type:'category',
        
        labels: {
          style: {
            fontWeight: 'bold',
          },
        },
        // barPercentage: 0.4,
      },
      yAxis: {
        title: {
          text: 'Weight(Product Vs Count) in Ton',
        },
        // stackLabels: {
        //   style: {
        //     color: 'white',
        //     fontSize: '87%',
        //     fontWeight: 'bold',
        //   },
        //   enabled: true,
        //   verticalAlign: 'middle',
        //   rotation: -200,
        //   align: 'left',
        //   allowOverlap: true,
        // },
      },
      tooltip: {
        formatter: function () {
          console.log(this.series.userOptions)
          let label =  this.series.userOptions.data[0].label?this.series.userOptions.data[0].label:this.series.userOptions.label;
          return (
            '<b>' +label
            +
            '</b><br/>' +
            'Weight' +
            ': ' +
            this.y 
          );
        },
      },
      colors: [
        // '#1569C7',
        'rgb(82, 196, 26)',
        '#ff4d4f',
        '#7cb5ec',
        'orange',
        'black'

        // '#228B22',
        // '#A52A2A',
      ],
      legend: {
        align: 'center',
        x: 5,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        // backgroundColor:
          // (Highcharts.theme && Highcharts.theme.colors) || 'white',
        borderColor: '#CCC',
        borderWidth: 0.5,
        shadow: false,
        labelFormatter: function () {
          console.log(this);
          let legendLabel =  this.userOptions.data[0].label?this.userOptions.data[0].label:this.userOptions.label;
          return legendLabel;
      }
      },
    
      series: bomData,
      drilldown: {
        series: drilldownInfo
      }
    };
    return options;
  };

  // useEffect(()=>{
  //   console.log(bomData);
  // },[bomData])

  const getRmBomData = () => {
    saleService
      .getRmBomData(new RMFiltersRequest(0,0,''))
      .then((res) => {
        if (res.status) {
          console.log(res);
          setBomData(res.data);
          // setPoTableData(res.data.zeroToSevenDays.poWiseInfo);
        } else {
          setDataState(false);
          if (res.intlCode) {
            setBomData(null);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        setBomData(null);
        AlertMessages.getErrorMessage(err.message);
      });
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          // searchText
          text
        )
      ) : null,
  });

  /* used for column filter
   * @param dataIndex column data index
   */



  const poDataColumns = [
    {
      title: 'SO Number',
      dataIndex: 'poNumber',
      key: 'poNumber',
      ...getColumnSearchProps('poNumber')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      ...getColumnSearchProps('product')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      ...getColumnSearchProps('count')

      // render: (text, data, index) => {
      //   return <span>{'count'}</span>
      // }
    },
    {
      title: 'Quantity(In Tons)',
      dataIndex: 'quantity',
      key: 'quantity',


      render: (text, data, index) => {
        return <span>{Number((text/1000).toFixed(2))}</span>
      }
    },
    

  ]


  const countryDataColumns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ...getColumnSearchProps('country')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      ...getColumnSearchProps('product')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      ...getColumnSearchProps('count')

      // render: (text, data, index) => {
      //   return <span>{'count'}</span>
      // }
    },
    {
      title: 'Quantity(In Tons)',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, data, index) => {
        return <span>{Number((text/1000).toFixed(2))}</span>
      }
    },
    

  ]


  const ratingDataColumns = [
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      ...getColumnSearchProps('rating'),
      render: (text, data, index) => {
        console.log(data)
        let columnValue;
        let colorIndication;
        if(text){
          columnValue = 'BAP('+text+'*)';
          colorIndication = 'red';
        }else{
          columnValue = 'ASC';
          colorIndication = 'black';
        }
        return <span style={{color:colorIndication}}>{columnValue}</span>
      }

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      ...getColumnSearchProps('product')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      ...getColumnSearchProps('count')

      // render: (text, data, index) => {
      //   return <span>{'count'}</span>
      // }
    },
    {
      title: 'Quantity(In Tons)',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, data, index) => {
        return <span>{Number((text/1000).toFixed(2))}</span>
      }
    },
    

  ]

  const BomIndentDataColumns = [
    
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      ...getColumnSearchProps('product')

      // render: (text, data, index) => {

      //   return <span>{'Vinamme'}</span>
      // }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      ...getColumnSearchProps('count')

      // render: (text, data, index) => {
      //   return <span>{'count'}</span>
      // }
    },
    {
      title: 'BOM Quantity(In Tons)',
      dataIndex: 'bomQuantity',
      key: 'bomQuantity',
      render: (text, data, index) => {
        return <span>{text > 0 ? Number(Math.round(text/1000).toFixed(2)): 0}</span>
      }
    },
    {
      title: 'Indent Quantity(In Tons)',
      dataIndex: 'indentQuantity',
      key: 'indentQuantity',
      render: (text, data, index) => {
        return <span>{text > 0 ? Number(Math.round(text/1000).toFixed(2)): 0}</span>
      }
    },
    

  ]



  /**
   *
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */

  const onFilterData = (val:any) => {

    const filteredValues = form.getFieldsValue();
    console.log(filteredValues);
    let countryId = 0
    let rating = 0
    let poNumber = ''
    let minCountValue = 0
    let maxCountValue = 0
    
    if(filteredValues.country > 0){
      countryId = filteredValues.country
    }
    if(filteredValues.rating > 0){
      rating = filteredValues.rating
    }
    if(filteredValues.poNumber){
      poNumber = filteredValues.poNumber
    }
    if(!(countryId == 0 && rating == 0 && poNumber == '')){
      setBomData(null)
      saleService
        .getRmBomData(new RMFiltersRequest(countryId,rating,poNumber))
        .then((res) => {
          if (res.status) {
            setBomData(res.data);
          } else {
            if (res.intlCode) {
              setBomData(null);
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        })
        .catch((err) => {
          setBomData(null);
          AlertMessages.getErrorMessage(err.message);
        });
    }
    setMinCount(0)
    setMaxCount(0)
    const countvalues = form.getFieldValue('count');
    console.log(countvalues);
    if(countvalues){
      if(countvalues.minCount > 0){
        minCountValue = countvalues.minCount
        setMinCount(minCountValue);
      }
      if(countvalues.maxCount > 0){
        maxCountValue = countvalues.maxCount
        setMaxCount(maxCountValue);
      }
    }

    
  };


  

  const showModal = () => {
    setIsModalVisible(true);
  };
  function poInfo(poWiseData) {
    Modal.info({
      title: 'PO wise Information',
      width:'580px',
           
      content: (
        <div>
          <Card>
          
                <Table 
                dataSource={poWiseData} 
                columns={poDataColumns}
                pagination={{ position: ['bottomLeft'] }} 
                />
                </Card>
        </div>
      ),
  
      // okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },

      closable:true,
      okText:'Close',
      onOk() {},
    });
    
  }

  function countryInfo(countryTableData) {
   


    Modal.info({ 
     title: 'Country wise Information',
     width:'580px',
 
      content: (
        <div>
          <Card>
                <Table 
                dataSource={countryTableData} 
                columns={countryDataColumns}
                pagination={{ position: ['bottomLeft'] }} 
                />
                </Card>
        </div>
      ),
    closable:true,
      okText:'Close',
      onOk() {},
    });
  }

  function ratingInfo(ratingTableData) {

    Modal.info({

      title: 'Rating wise Information',
      width:'580px',
      content: (
       
       <div>
          <Card>
            
                <Table 
                dataSource={ratingTableData} 
                columns={ratingDataColumns}
                
                pagination={{ position: ['bottomLeft'] }} 
                />
                </Card>
                
        </div>
      ),
      closable:true, 
      okText:'Close',
      onOk() {},
    });
  }

  function bomIndentInfo(bomIndentTabledata) {
    Modal.info({
      title: 'BOM Vs Indent Information',
      width:'580px',
      content: (
        <div>
          <Card>
                <Table 
                dataSource={bomIndentTabledata} 
                columns={BomIndentDataColumns}
                pagination={{ position: ['bottomLeft'] }} 
                />
                </Card>
        </div>
      ),
      closable:true,
      okText:'Close',
      onOk() {},
    });
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if(isRMModalVisible){
      setIsRMModalVisible(false);
    }
    if(isIndentModalVisible){
      setIsIndentModalVisible(false)
    }
    if(isFarmerModalVisible){
      setIsFarmerModalVisible(false)
    }
    if(isGrnModalVisible){
      setIsGrnModalVisible(false)
    }
  };



  return (
    <>
    {
      dataState ? 
      (bomData?.zeroToSevenDays || bomData?.sevenToFourteenDays || bomData?.fifteenToThirtyDays || bomData?.aboveThirtyDays) ?
      <>
    <Card
      title={<span style={{ color: 'white' }}>Requisition Info</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Row gutter={24}>
        <Col xs={12} md={6} lg={6} xl={6}>
          <Card  title="To Be Procured" extra={<a onClick={() => setIsRMModalVisible(true)}>More</a>} style={{backgroundColor:'#dde66a',height:'100px',fontSize:'10px'}}>
          <Text style={{fontSize:'20px',color:'white'}} strong >{bomData.totalToBeProcuredQty > 0 ? Math.round(Number(bomData.totalToBeProcuredQty/1000)).toLocaleString('en-IN',undefined): 0} T</Text>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={6}>
        <Card title="Indent" extra={<a onClick={() => setIsIndentModalVisible(true)}>More</a>} style={{backgroundColor:'#ffc1bf',height:'100px',fontSize:'10px'}}>
          <p><Text style={{fontSize:'15px',color:'white'}} strong >Count:{bomData.totalIndentCount}</Text><Divider type="vertical" style={{color:'black'}} /><Text style={{fontSize:'15px',color:'white'}} strong >Qty:{Number(bomData.totalIndentQty).toLocaleString('en-IN',undefined)} T</Text></p>
          {/* <p><Text style={{fontSize:'15px'}} strong >Qty:{bomData.totalIndentQty}</Text></p> */}
          </Card>
        </Col>
        <Col xs={12} md={6} lg={6}>
        <Card  title="Farmers" extra={<a onClick={() => setIsFarmerModalVisible(true)}>More</a>} style={{backgroundColor:'#f7db94',height:'100px',fontSize:'10px'}}>
          <Text style={{fontSize:'20px',color:'white'}} strong >{bomData.totalFarmers}</Text>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={6}>
        <Card  title="Pending GRN Qty" extra={<a onClick={() => setIsGrnModalVisible(true)}>More</a>} style={{backgroundColor:'#dbdfe2',height:'100px',fontSize:'10px'}}>
          <Text style={{fontSize:'20px',color:'white'}} strong type="danger">{bomData.totalPendingGrnCount > 0 ? Math.round(Number(bomData.totalPendingGrnCount/1000)).toLocaleString('en-IN',undefined) : 0} T</Text>
          </Card>
        </Col>
      </Row>
     
     
      {/* <Form layout={"vertical"} form={form} name="control-hooks" onFinish={onFilterData} >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="country" label="Country" >
              <Select
                placeholder="Select Country"
                // onChange={countryOnchange}
                allowClear
                // disabled = {customerHidden}
              >
                {countriesData.map(dropData => {
                  return <Option value={dropData.countryId}>{dropData.countryName}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3  }} lg={{ span: 5  }} xl={{ span: 5  }}>
          <Form.Item name="rating" label="Rating" >
              <Select
                placeholder="Select Rating"
                // onChange={countryOnchange}
                allowClear
                // disabled = {customerHidden}
              >
                
                {ratingInput.map(dropData => {
                  return <Option value={dropData.value}>{dropData.value}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5  }} lg={{ span: 5  }} xl={{ span: 5  }}>
          <Form.Item name="poNumber" label="PO Number" >
              <Select
                placeholder="Select PO Number"
                // onChange={countryOnchange}
                allowClear
                // disabled = {customerHidden}
              >
                {poNumbers.map(dropData => {
                  return <Option value={dropData.poNumber}>{dropData.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5  }} lg={{ span: 5}} xl={{ span: 5  }}>
            <Form.Item label="Count Range" required >
                  <Input.Group compact>
                    <Form.Item
                      name={['count', 'minCount']}
                      noStyle
                      rules={[{ required: true, message: 'Missing Min Count' }]}
                    >
                      <InputNumber   style={{ width: "35%" }} placeholder="Min" min={1}  />
                    </Form.Item>

                    <Form.Item name={['count', 'middle']} noStyle>
                      <Input style={{ width: "30%" }}
                        placeholder="~"
                        disabled

                      />
                    </Form.Item>
                    <Form.Item
                      name={['count', 'maxCount']}
                      noStyle
                      rules={[{ required: true, message: 'Missing Max Count/Max Count should greater than Min Count' }]}
                      
                    >
                      <InputNumber   style={{ width: "35%" }} placeholder="Max" min={1}  />
                    </Form.Item>
                  </Input.Group>
              </Form.Item>



              </Col>

              <Col span={4} style={{ paddingTop:10 }}>
                <Form.Item >
                  <br />
                  <Button  type="primary" htmlType="submit" >
                  Submit</Button>
                </Form.Item>
              </Col>
      </Row>
      </Form> */}
      <br></br>
      <Row gutter = {24}>
            
            </Row>
      <br></br>
      
      {/* <Row gutter={40}>
        <Col>
          <Card title={'Total GRN: ' + grnData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        </Row> */}
      <Row gutter={40}>
        <br></br>
        
      </Row>
      <br></br>
      <Tabs type={'card'} tabPosition={'top'}>

      <TabPane
          // tab="Open Orders"
          key="5"
          tab={
            <span style={{ color: 'green' }}>
              {'Backlog Orders'}
            </span>
          }
          style={{ color: '#363636' }}
        >
          {bomData?.belowSevenDays?.countRangeWiseInfo?.length > 0? (
            
            <>
            
            <Row gutter={24}>

              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => poInfo(bomData.belowSevenDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => countryInfo(bomData.belowSevenDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => bomIndentInfo(bomData.belowSevenDays.bomIndentInfo)} type="primary">BOM Vs Indent Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => ratingInfo(bomData.belowSevenDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
              </Col>
            </Row>
            <br/>
            <Row gutter={24}>
            {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                <Card style={{height:'200px'}}>
                  <Table key={Date.now()} 
                dataSource={tableData} 
                columns={dataColumns} 
                />
                </Card>
              </Col> */}
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <Card>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={constructChartData(bomData.belowSevenDays.countRangeWiseInfo,bomData.belowSevenDays.drilldownInfo, undefined)}
                />
                </Card>
              </Col>
              {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
              >
                <Card>
                  <Row>
                  <Button style={{backgroundColor:'#314153',width:'100%',height:'105px',color:'white'}}onClick={e => poInfo(bomData.zeroToSevenDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#1cabd7',width:'100%',height:'105px',color:'white'}}onClick={e => countryInfo(bomData.zeroToSevenDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#f5dc3c',width:'100%',height:'105px'}}>Product Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#badcc8',width:'100%',height:'105px',color:'white'}}onClick={e => ratingInfo(bomData.zeroToSevenDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
                  </Row>
                </Card>
              </Col> */}
              
            </Row>
            </>
          ) : (
            'No data found'
          )}
          {/* } */}
        </TabPane>
        
        <TabPane
          // tab="Open Orders"
          key="1"
          tab={
            <span style={{ color: 'green' }}>
              {'0-7 Days'}
            </span>
          }
          style={{ color: '#363636' }}
        >
          {bomData?.zeroToSevenDays?.countRangeWiseInfo?.length > 0? (
            
            <>
            
            <Row gutter={24}>

              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => poInfo(bomData.zeroToSevenDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => countryInfo(bomData.zeroToSevenDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => bomIndentInfo(bomData.zeroToSevenDays.bomIndentInfo)} type="primary">BOM Vs Indent Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => ratingInfo(bomData.zeroToSevenDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
              </Col>
            </Row>
            <br/>
            <Row gutter={24}>
            {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                <Card style={{height:'200px'}}>
                  <Table key={Date.now()} 
                dataSource={tableData} 
                columns={dataColumns} 
                />
                </Card>
              </Col> */}
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <Card>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={constructChartData(bomData.zeroToSevenDays.countRangeWiseInfo,bomData.zeroToSevenDays.drilldownInfo,bomData.zeroToSevenDays.dataExistingKeys)}
                />
                </Card>
              </Col>
              {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
              >
                <Card>
                  <Row>
                  <Button style={{backgroundColor:'#314153',width:'100%',height:'105px',color:'white'}}onClick={e => poInfo(bomData.zeroToSevenDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#1cabd7',width:'100%',height:'105px',color:'white'}}onClick={e => countryInfo(bomData.zeroToSevenDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#f5dc3c',width:'100%',height:'105px'}}>Product Wise Info</Button>
                  </Row>
                  <Row>
                  <Button style={{backgroundColor:'#badcc8',width:'100%',height:'105px',color:'white'}}onClick={e => ratingInfo(bomData.zeroToSevenDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
                  </Row>
                </Card>
              </Col> */}
              
            </Row>
            </>
          ) : (
            <><Alert message="No Data Found" type="error" /></>
          )}
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="2"
          tab={
            <span style={{ color: '#096dd9' }}>
              {'8-15 Days'}
            </span>
          }
          style={{ color: '#363636' }}
        >
          {bomData?.sevenToFourteenDays?.countRangeWiseInfo?.length > 0? (
            <>
            
            <Row gutter={24}>

              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => poInfo(bomData.sevenToFourteenDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => countryInfo(bomData.sevenToFourteenDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} type="primary" onClick={e => bomIndentInfo(bomData.sevenToFourteenDays.bomIndentInfo)}>BOM Vs Indent Info</Button>
              </Col>
              <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => ratingInfo(bomData.sevenToFourteenDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
              </Col>
            </Row>
            <br/>
            <Row gutter={24}>
            {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                <Card style={{height:'200px'}}>
                  <Table key={Date.now()} 
                dataSource={tableData} 
                columns={dataColumns} 
                />
                </Card>
              </Col> */}
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <Card>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={constructChartData(bomData.sevenToFourteenDays.countRangeWiseInfo,bomData.sevenToFourteenDays.drilldownInfo,undefined)}
                />
                </Card>
              </Col>
              
            </Row>
            </>
          ) : (
            <><Alert message="No Data Found" type="error" /></>
          )}
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="3"
          tab={
            <span style={{ color: '#096dd9' }}>
              {'16-30 Days'}
            </span>
          }
          style={{ color: '#363636' }}
        >
          {bomData?.fifteenToThirtyDays?.countRangeWiseInfo?.length > 0 ?(
            <>
            <Row gutter={24}>

            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => poInfo(bomData.fifteenToThirtyDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => countryInfo(bomData.fifteenToThirtyDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} type="primary" onClick={e => bomIndentInfo(bomData.fifteenToThirtyDays.bomIndentInfo)}>BOM Vs Indent Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => ratingInfo(bomData.fifteenToThirtyDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
            </Col>
          </Row>
          <br/>
          <Row gutter={24}>
          {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
            >
              <Card style={{height:'200px'}}>
                <Table key={Date.now()} 
              dataSource={tableData} 
              columns={dataColumns} 
              />
              </Card>
            </Col> */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card>
              <HighchartsReact
                highcharts={Highcharts}
                options={constructChartData(bomData.fifteenToThirtyDays.countRangeWiseInfo,bomData.fifteenToThirtyDays.drilldownInfo,undefined)}
              />
              </Card>
            </Col>
            
          </Row>
          </>
          ) : (
            <><Alert message="No Data Found" type="error" /></>
          )}
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="4"
          tab={
            <span style={{ color: 'red' }}>
              {' Above 30 Days'}
            </span>
          }
          style={{ color: '#363636' }}
        >
          {bomData?.aboveThirtyDays?.countRangeWiseInfo?.length > 0 ? (
            <>
            <Row gutter={24}>

            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => poInfo(bomData.aboveThirtyDays.poWiseInfo)} type="primary">PO Number Wise Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => countryInfo(bomData.aboveThirtyDays.countryWiseInfo)} type="primary">Country Wise Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} type="primary" onClick={e => bomIndentInfo(bomData.aboveThirtyDays.bomIndentInfo)}>BOM Vs Indent Info</Button>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button style = {{width:'250px',backgroundColor:'#60b888'}} onClick={e => ratingInfo(bomData.aboveThirtyDays.ratingWiseInfo)} type="primary">Rating Wise Info</Button>
            </Col>
          </Row>
          <br/>
          <Row gutter={24}>
          {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
            >
              <Card style={{height:'200px'}}>
                <Table key={Date.now()} 
              dataSource={tableData} 
              columns={dataColumns} 
              />
              </Card>
            </Col> */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card>
              <HighchartsReact
                highcharts={Highcharts}
                options={constructChartData(bomData.aboveThirtyDays.countRangeWiseInfo,bomData.aboveThirtyDays.drilldownInfo,undefined)}
              />
              </Card>
            </Col>
            
          </Row>
          </>
          ) : (
            <><Alert message="No Data Found" type="error" /></>
          )}

          <Row></Row>
          {/* } */}
        </TabPane>
      </Tabs>

      <br></br>
      <Row gutter={40}></Row>
      <br />
      <br />
      <br />
    </Card>
    {isRMModalVisible?
      <Modal
        key={'modal' + Date.now()}
        width={'90%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isRMModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[
          
        ]}
        >
        <BomTrackerGrid/>
        </Modal>:""}
        {isIndentModalVisible?
      <Modal
        key={'modal' + Date.now()}
        width={'90%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isIndentModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[
          
        ]}
        >
        <IndentGrid/>
        </Modal>:""}
        {isFarmerModalVisible?
      <Modal
        key={'modal' + Date.now()}
        width={'90%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isFarmerModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[
          
        ]}
        >
        <SuppliersGrid/>
        </Modal>:""}
        {isGrnModalVisible?
      <Modal
        key={'modal' + Date.now()}
        width={'90%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isGrnModalVisible}
       
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[
          
        ]}
        >
        <RawMaterialGrnGrid/>
        </Modal>:""}
        </>
    :
    <Card
      title={<span style={{ color: 'white' }}>Procurement Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    ><span style={{paddingTop:'10px' , textAlign:'center'}} ><Spin /></span></Card>
    :<></>
    }
    
    </>
  );
}

<style></style>;

export default RmProcurementDashboard;

//procurement dashboard
