

export class StockReportFilters{
    unitId : number[]  ;
    plantId : number;
    stockFromDate : any;
    stockToDate : any;
    soType:string[];
    shift:string[];
    coldStorage : number[];
    lifeCycleStatus?:string;
    deadStock?:string;
    product?:number;
}