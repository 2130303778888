import { Card, Col, DatePicker, Descriptions, Form, FormInstance, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { GrnService } from '@gtpl/shared-services/procurement';
import { GrnDetailsForReturn, GrnIdRequest, GrnNumberDto } from '@gtpl/shared-models/procurement-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

export interface GRNDetailsFormProps {
  // intialData: GrnItemsInfo;
  grnNumberDropDowndata: GrnNumberDto[]
  form: FormInstance<any>
  grnReturnItemsForm: FormInstance<any>
  updateReturnItems: (values) => void;
}

const GrnReturnDetails = (props: GRNDetailsFormProps) => {
  const form = props.form;
  const [grnAutoFillDetails, setGrnAutoFillDetails] = useState<GrnDetailsForReturn>(undefined);

  const grnService = new GrnService();


  useEffect(() => {
    form.setFieldsValue({ grnReturnDate: moment() });
  }, []);

  const getGrnDataById = (grnIdReq: GrnIdRequest) => {
    grnService.getGrnDataByIdForReturn(grnIdReq).then((res) => {
      if (res.status) {
        props.updateReturnItems(res.data.grnItems);
        setGrnAutoFillDetails({ ...grnAutoFillDetails, ...res.data });
        form.setFieldsValue({ vendorId: res.data.vendorId, vendorName:res.data.vendorName});
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        props.updateReturnItems([]);
        setGrnAutoFillDetails(undefined);
      }
    }).catch((err) => {
      props.updateReturnItems([]);
      setGrnAutoFillDetails(undefined);
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const disabledDate = (current) => {
    const grnDate = moment(grnAutoFillDetails?.grnDate);
  
    return current >= grnDate;
  }
  return (
    <Form layout={"vertical"} autoComplete="off" form={form}>
      <Row gutter={24}>
        <Form.Item name="vendorId" >
          <Input hidden />
        </Form.Item>
        <Form.Item name="vendorName" >
          <Input hidden />
        </Form.Item>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="grnReturnDate" label="Date" rules={[{ required: true }]} >
            <DatePicker style={{ width: '100%' }} disabledDate={disabledDate}/>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
          <Form.Item name="grnId" label="GRN Number" rules={[{ required: true }]}>
            <Select
              placeholder="Select GRN Number"
              optionLabelProp="label"
              onChange={(grnId) => { getGrnDataById(new GrnIdRequest(Number(grnId))) }}
              allowClear
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {props.grnNumberDropDowndata.map(dropData => {
                return <Option
                  value={dropData.grnId}
                  key={dropData.grnId}
                  label={dropData.grnNumber}>{dropData.grnNumber}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Form.Item name="remarks" label="Remarks" rules={[{ required: true, message: 'Missing Remarks' }]}>
            <TextArea showCount />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ display: (grnAutoFillDetails) ? 'block' : 'none' }}>
        <Col>
          <Descriptions column={4}>
            <Descriptions.Item label="GRN Date">
              {(grnAutoFillDetails) ? moment(grnAutoFillDetails?.grnDate).format("YYYY-MM-DD") : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Date">
              {(grnAutoFillDetails) ? moment(grnAutoFillDetails?.invoiceDate).format("YYYY-MM-DD") : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Number">
              {grnAutoFillDetails?.invoiceNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor Name">
              {grnAutoFillDetails?.vendorName}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Form>
  )
}

export default GrnReturnDetails
