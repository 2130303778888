import axios from 'axios';
import connection from './connection';
import {ShippingTermsDropDownDto, ShippingTermsDropDownResponseModel, ShippingTermsDto, ShippingTermsRequest} from '@gtpl/shared-models/masters'
import {ShippingTermsResponseModel} from '@gtpl/shared-models/masters'
import {AllShippingTermsResponseModel} from '@gtpl/shared-models/masters'
import { UserRequestDto } from '@gtpl/shared-models/common-models';
export class ShippingTermsService{
    URL = connection.DEPLOY_URL + '/shipping-terms';


    async create(dto: ShippingTermsDto): Promise<ShippingTermsResponseModel> {

        console.log(dto);

        return await axios.post(this.URL + '/createShippingTerms', dto)
            .then(res => {
                return res.data
            })
    }


    async update(dto: ShippingTermsDto): Promise<ShippingTermsResponseModel> {
        return await axios.post(this.URL + '/updateShippingTerms', dto)
            .then(res => {
                return res.data
            })
    }


    async activatedeActivate(Dto: ShippingTermsDto): Promise<ShippingTermsResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateShippingTerms', Dto)
            .then(res => {
                return res.data
            })
    }
    async getAll(req?:UserRequestDto): Promise<AllShippingTermsResponseModel> {
    return await axios.post(this.URL + '/getAllShippingTerms',req)
      .then(res => {
        return res.data
      })
  }
    async getShippingTermsById(shippingTermsRequest: ShippingTermsRequest): Promise<ShippingTermsResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/getShippingTermsById', shippingTermsRequest)
            .then(res => {
                return res.data
            })
    }

    async getAllShippingTermsDropDown(): Promise<ShippingTermsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllShippingTermsDropDown')
            .then(res => {
                return res.data
            })
        // return new ShippingTermsDropDownResponseModel(true,11,'customersData retrived successfully',[new ShippingTermsDropDownDto(1,'shterm1'),new ShippingTermsDropDownDto(2,'shterm2')]);
    }

}