import React, { useEffect, useState } from 'react';

import './qa-qc-sample-test.css';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button, Radio } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import { LotAndSaleOrderIdReq, LotCodeDropDown, OperationIdRequest, OperationRequest, OperationandLotReq, OperationsDropDown, QaQcDropDown, WorkStationCategoryReq, WorkStationsDropDown, workStationForOpearation } from '@gtpl/shared-models/production-management';
import { OperationTypeEnum, QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeOpsService, OperationService, QaQcOperationsService, QaQcSampleTestService, QcDefectService, WorkstationService } from '@gtpl/shared-services/production';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;
import { ItemsDetails, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
import { format } from 'url';

/* eslint-disable-next-line */
export interface QaQcSampleTestProps { }

export function QaQcSampleTest(
  props: QaQcSampleTestProps
) {
  let history = useHistory();
  const [qaQcSampleTestForm] = Form.useForm();
  const [operations, setOperations] = useState<any[]>([]);
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const [testsData, setTestsData] = useState<QaQcDropDown[]>([]);
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const [disable, setDisable] = useState<boolean>(false)
  const [Pass, setPass] = useState({});
  const [PassWithDefect, setPassWithDefect] = useState({});
  const [Fail, setFail] = useState({});
  const [wokStationForOperation, setwokStationForOperation] = useState<any[]>([])
  const [soanditemdata, setSoanditemdata] = useState<any[]>([])
  const [operationName,setOperationName] = useState()
  const lotCodeService = new LotCodeOpsService();
  const operationService = new OperationService();
  const qaQcService = new QaQcOperationsService();
  const qaQcSampleTestService = new QaQcSampleTestService();
  const defectservice = new QcDefectService();
  
  useEffect(() => {
    qaQcSampleTestForm.setFieldsValue({ noOfSamples:1,quantityTested:30 })
    getAllLotNumbers();
  }, [])

  const getAllLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new PlantIdRequest(plant);

    operationService.getLotNumbersDropDownForRejections(lotNoReq).then((res) => {
      if (res.status) {
        console.log(res.data)
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getAllOperations = (req) => {
    operationService.getAllOperationsAgainistlotAndsoId(req).then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        setOperations([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setOperations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  
  const handleSoChange = (value) => {
    const soId = soanditemdata.filter((el) => { return el.saleOrderItemId == value })
    qaQcSampleTestForm.resetFields(['saleOrderId','operation'])
    qaQcSampleTestForm.setFieldsValue({
      saleOrderId: soId[0].saleOrderId
    })
    const lotNumber = soId[0].lotNumber
    const saleId= soId[0].saleOrderId
    const soItemId= soId[0].saleOrderItemId
    const plantId= Number(localStorage.getItem('unit_id'))
    const sublot = soId[0].subLotNumber
    const req= new LotAndSaleOrderIdReq();
    req.lotNumber=lotNumber;
    req.saleOrderId=saleId;
    req.saleOrderItemId=soItemId;
    req.plantId=plantId;
    req.subLotNumber=sublot;
    console.log(req)
    getAllOperations(req)
  }
  const handleLotChange = (e) =>{
    const data = lotNumbers.filter((el) =>{return el.subLotNumber == e})
    setOperationName(data[0].operation)
    const plant = Number(localStorage.getItem('unit_id'))
    const sublotlotNumber = data[0].subLotNumber
    const operation=data[0].operation
    const req = new OperationandLotReq()
    req.plant=plant;
    req.subLotNumber=sublotlotNumber
    req.operation=operation;
    getSonumberforQaQcrejection(req);
    qaQcSampleTestForm.resetFields(['saleOrderId','saleOrderItemId','operation'])
    qaQcSampleTestForm.setFieldsValue({lotNumber:data[0].lotNumber})
  }

  const getSonumberforQaQcrejection = (req) => {
    lotCodeService.getSonumberforQaQcrejection(req).then(res => {
      if (res.status) {
        setSoanditemdata(res.data);
      } else {
        setSoanditemdata([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSoanditemdata([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getActiveWorkStationforoperation = (req) => {
    defectservice.getAllActiveWorkstation(req).then(res => {
      if (res.status) {
        setwokStationForOperation(res.data);
      } else {
        setwokStationForOperation([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setwokStationForOperation([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllQcQcActiveTestsForOperation = (value) => {
    if (value) {
      qaQcService.getAllQcQcDropDownForOperation({operationName:value}).then(res => {
        if (res.status) {
          setTestsData(res.data);
        } else {
          setTestsData([]);
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setTestsData([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }

  const onFinish = () => {
    setDisable(true)
    qaQcSampleTestForm.validateFields().then(formDetails => {
      formDetails.plantId = Number(localStorage.getItem('unit_id'));
      qaQcSampleTestService.CreateQaQcSampleData(formDetails).then((res) => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          history.push('/qa-qc-sample-test-grid')
        } else {

        }
      }).catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);

      });
    })
  }

  const onReset = () => {
    qaQcSampleTestForm.resetFields();
  };
  const handleoperation = (e) =>{
    console.log(e)
    const data = operations.filter((el) =>{return el.operationName == e})
    console.log(data[0].lotNumber)
    const lotno = data[0].lotNumber
    const sublotnumber = data[0].subLotNumber
    const saleOrderId = qaQcSampleTestForm.getFieldValue('saleOrderId')
    const saleOrdetitemid = qaQcSampleTestForm.getFieldValue('saleOrderItemId')
    const plantId= Number(localStorage.getItem('unit_id'))
    const req = new workStationForOpearation()
    req.lotNumber=lotno;
    req.subLotNumber=sublotnumber;
    req.operationName=e;
    req.saleOrderId=saleOrderId;
    req.saleOrderItemId=saleOrdetitemid
    req.plantId=plantId;
    getActiveWorkStationforoperation(req)
    getAllQcQcActiveTestsForOperation(e)


  }
  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  const onChange = (event) => {
    const { value, checked } = event.target;
    value === "Pass" && checked
      ? setPass({ background: "green", color: "black" })
      : setPass({});
    value === "Pass With Defect" && checked
      ? setPassWithDefect({ background: "orange", color: "black" })
      : setPassWithDefect({});
    value === "Fail" && checked
      ? setFail({ background: "red" })
      : setFail({});
  };

 
  return (
    <Card >
      <Form layout={'vertical'} form={qaQcSampleTestForm} name="control-hooks">
        <Card title={<span style={{ color: 'white' }}>Lot Details</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  extra={<Link to='/qa-qc-sample-test-grid' ><Button className='panel_button' > View </Button></Link>}>
          <Row gutter={24}>
            <Form.Item name="testSampleId" >
              <Input hidden />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }}>
            <Form.Item
              name="subLotNumber"
              label="SubLot No"
              rules={[
                {
                  required:true,
                  message: 'Missing Sub Lot No'
                },

              ]}
            >
              <Select
                showSearch
                placeholder="Select SubLoNumber"
                optionFilterProp="children"
                onChange={e =>handleLotChange(e)}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                 {lotNumbers.map(dropData => {
                    return <Option key={dropData.subLotNumber} value={dropData.subLotNumber
                    }>{dropData.sublotnplsntlot}</Option>
                  })}
              </Select>
            </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
            <Form.Item
              name="lotNumber"
              label="Lot No"
              rules={[
                {
                  required:true,
                  message: 'Missing Lot No'
                },

              ]}
            >
             <Input/>
            </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 10 }}>

              <Form.Item name="saleOrderItemId" label="SO Number" rules={[{ required: true, message: 'Missing SO Number' }]}>
                <Select
                  allowClear
                  showSearch
                  placeholder="Select SO Number"
                  onChange={handleSoChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}              
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {soanditemdata.map((dropData) => {
                    return (
                      <Option
                        name="saleOrderItemId"
                        key={dropData.saleOrderItemId}
                        value={dropData.saleOrderItemId}
                      >
                        {dropData.salOrderandItem}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>

              <Form.Item name="saleOrderId" label="SO Id" rules={[{ required: true, message: 'Missing SO Number' }]}>
                 <Input  />
              </Form.Item>
            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Missing Shift' }]} >
                <Select
                  placeholder="Select Shift"
                  allowClear
                  showSearch
                >
                  <Option key={0} value={null}>Select Shift</Option>
                  <Option key={1} selected value={ShiftsEnum.General}>{ShiftsEnum.General}</Option>
                  <Option key={2} value={ShiftsEnum.Shift_A}>{ShiftsEnum.Shift_A}</Option>
                  <Option key={3} value={ShiftsEnum.Shift_B}>{ShiftsEnum.Shift_B}</Option>
                  <Option key={4} value={ShiftsEnum.Shift_C}>{ShiftsEnum.Shift_C}</Option>
                </Select>
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="operation"
                label="Operation"
                rules={[
                  {
                    required: true, message: 'Operation Name is required'

                  },

                ]}>
                <Select
                  showSearch
                  placeholder="Select Operation Category"
                onChange={e => handleoperation(e)}
                >
                  <Option key={0} value={null}>Select Operations</Option>
                  {/* {Object.values(OperationTypeEnum).map((value) => {
                  return <Option key={value} value={value}>{value}</Option>
                })} */}
                  {operations.map((operation) => {
                    return <Option key={operation.operationName} value={operation.operationName}>{operation.operationName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]}>
                <Select
                  showSearch
                  placeholder="Select Workstation"
                  allowClear
                >
                  {wokStationForOperation.map(dropData => {
                    return <Option key={dropData.workStationId} value={dropData.workStationId}>{dropData.workstation}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <br />
        <br />
        <Card title={<span style={{ color: 'white' }}>QC Details</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="qaQcTestId" label="Test" rules={[{ required: true, message: 'Missing Test' }]}>
                <Select
                  placeholder="Select Test"
                  allowClear
                >
                  <Option key={0} value={null}>Select Tests</Option>
                  {testsData.map((test) => {
                    return <Option key={test.qaQcTestId} value={test.qaQcTestId}>{test.testName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="noOfSamples" label="No. Of. Samples" rules={[{ required: true, message: 'Missing No. Of. Samples' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

              <Form.Item name="count" label="Tested Count" rules={[{ required: true, message: 'Missing Count' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="quantityTested" label="Quantity Tested" rules={[{ required: true, message: 'Missing Quantity Tested' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

              <Form.Item name="rejectedQuantity" label="Rejected Quantity" rules={[{ required: true, message: 'Missing Rejection Quantity' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item name="rejectionReason" label="Rejection Reason" rules={[{ required: true, message: 'Missing Rejection Reason' }]}>
                <Select
                  showSearch
                  placeholder="Select Rejection Reason"
                  allowClear
                >
                  <Option key={0} value={null}>Select Rejection Reason</Option>
                  <Option key={1} selected value={RejectionReasonsEnum.BLACK_DOTES}>{RejectionReasonsEnum.BLACK_DOTES}</Option>

                </Select>
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }} >
            <Form.Item name="testResult" label="Test Result" rules={[{ required: true, message: 'Missing Test Result' }]} >
              <Radio.Group buttonStyle="solid" onChange={onChange}>
                <Radio.Button value="Pass" className='ant-radio-checked' style={Pass}>Pass</Radio.Button>
                <Radio.Button value="Pass With Defect" style={PassWithDefect}>Pass with Defect</Radio.Button>
                <Radio.Button value="Fail" style={Fail}>Fail</Radio.Button>
              </Radio.Group>
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item name="remarks" label="Remarks" rules={[{ required: false, message: 'Missing Remarks' }]} >
                <TextArea rows={4}></TextArea>
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} style={{ paddingTop: '92px' }}>
                <Button type='primary' onClick={onFinish}>
                  Submit
                </Button>
                <Button htmlType="button" style={{ margin: '0 14px', color:'white', backgroundColor:'red' }} onClick={onReset}>
                  Cancel
                </Button>
            </Col>
          </Row>
        </Card>
      </Form>

    </Card>
  );
}
export default QaQcSampleTest;
