export class GradingDashboardModel {
    lotCount: number;
    qtyToBeProcessed: number;
    qtyInProgress: number;
    gradingInventory: number;
    counts: string[];
    pendingQty: string[];
    filledQty: string[];

    constructor(lotCount: number,qtyToBeProcessed: number,qtyInProgress: number,gradingInventory: number,counts: string[],
        pendingQty: string[],filledQty: string[]){
        this.lotCount = lotCount;
        this.qtyToBeProcessed = qtyToBeProcessed;
        this.qtyInProgress = qtyInProgress;
        this.gradingInventory = gradingInventory;
        this.counts = counts;
        this.pendingQty = pendingQty;
        this.filledQty = filledQty;
    }
}