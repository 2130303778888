import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { AssetByAssetCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto";
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { Excel } from 'antd-table-saveas-excel';
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import { AssetServiceReq } from "libs/shared-models/common-models/src/lib/asset-service-req";
import { useParams } from "react-router-dom";
import moment from "moment";
import Highlighter from 'react-highlight-words';
import { AssetTypeEnum } from "libs/shared-models/common-models/src/lib/enum/asset-type.enum";

const { Option } = Select;

/* eslint-disable-next-line */
export interface AssetServiceDateReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const AssetServiceDateReport = (
  props: AssetServiceDateReportProps
) => {
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const onSearch = (value: string) => console.log(value);
  const { TabPane } = Tabs;
  const [assetsData, setAssetsData] = useState<any>([])
  const locationService = new AssetLocationService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const [Data, setData] = useState<AssetAssignmentDto[]>([]);
  const service = new AssetAssignmentService()
  const [disable, setDisable] = useState<boolean>(false);
  const { id } = useParams<any>()
  const [serviceOverdue, setServiceOverdue] = useState<any[]>([])
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const { Text } = Typography;
  const [length, setLength] = useState('');
  const [pageSize, setPageSize] = useState<number>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {

    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations();
    getItemCategory();
    getDepartments();
    getAllPlants();
    getServiceOverdue();
    getAssetCode();
  }, [])

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getServiceOverdue = () => {
    setDisable(true);
    const req = new AssetServiceReq();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('locationName') != undefined) {
      req.location = form.getFieldValue('locationName')
    };
    if (form.getFieldValue('assetType') != undefined) {
      req.assetType = form.getFieldValue('assetType')
    };
    if (id) {
      form.setFieldsValue({ assetId: id })
    }

    if (form.getFieldValue('assetCode') != undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (form.getFieldValue('assetCategory') != undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    service.getServiceOverdue(req).then((res) => {
      if (res.status) {
        setDisable(true);
        setServiceOverdue(res.data);
        setLength(res.data.length);
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Expired assets retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDisable(false)
    })
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });
  const searchHandler = () => {
    getServiceOverdue()
  }
  const resetHandler = () => {
    form.resetFields();
    getServiceOverdue();
  }
  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: "Unit",
      dataIndex: "plant",
      align: "left",
      ...getColumnSearchProps('plant'),
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      }
    },

    {
      title: "Location",
      dataIndex: "locationName",
      align: "left",
      sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('locationName'),
      render: (text, record) => {
        return (
          <>
            {record.locationName ? record.locationName : '-'}
          </>
        )
      }
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      align: "left",
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],

      // filters: [
      //   {
      //     text: 'MOVABLE',
      //     value: 'movable',
      //   },

      //   {
      //     text: 'FIXED',
      //     value: 'FIXED',
      //   },
      // ],
      filterMultiple: false,
      render: (text, record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      },

    },
    {
      title: "Asset Category",
      dataIndex: "assetCategory",
      width: '100',
      align: "left",
      ...getColumnSearchProps('assetCategory'),

      sorter: (a, b) => a.assetCategory?.localeCompare(b.assetCategory),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.assetCategory ? record.assetCategory : '-'}
          </>
        )
      },
    },

    {
      title: "Asset Name",
      dataIndex: "assetName",
      align: "left",
      sorter: (a, b) => a.assetName?.localeCompare(b.assetName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetName'),
      render: (text, record) => {
        return (
          <>
            {record.assetName ? record.assetName : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Code",
      dataIndex: "assetCode",
      align: "left",
      ...getColumnSearchProps('assetCode'),
      sorter: (a, b) => a.assetCode.localeCompare(b.assetCode),
      sortDirections: ['descend', 'ascend'], render: (text, record) => {
        return (
          <>
            {record.assetCode ? record.assetCode : '-'}
          </>
        )
      }
    },
   

   
    {
      title: "Service Date",
      dataIndex: "serviceDate",
      align: "left",
      sorter: (a, b) => a.serviceDate?.localeCompare(b.serviceDate),
      render: (text, record) => {
        return (
          <>
            {record.serviceDate ? moment(record.serviceDate).format('YYYY-MM-DD') : '-'}
          </>
        )
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "left",

      render: (text, record) => {
        return (
          <>
            {record.status ? record.status : '-'}
          </>
        )
      }
    },
    {
      title: "Age",
      dataIndex: "age",
      align: "left",
      sorter: (a, b) => a.age - b.age,
      sortDirections: ['descend', 'ascend'], render: (text, record) => {
        return (
          <>
            {record.age ? record.age : '-'}
          </>
        )
      }
    },
  ];
  const exportedData = [];
  const excelData = serviceOverdue
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    {
      title: 'Unit', dataIndex: 'plant', render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      }
    },

    {
      title: 'Location', dataIndex: 'locationName', render: (text, record) => {
        return (
          <>
            {record.locationName ? record.locationName : '-'}
          </>
        )
      }
    },
    {
      title: 'Asset Type', dataIndex: 'assetType', render: (text, record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Category', dataIndex: 'assetCategory', render: (text, record) => {
        return (
          <>
            {record.assetCategory ? record.assetCategory : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Name', dataIndex: 'assetName', render: (text, record) => {
        return (
          <>
            {record.assetName ? record.assetName : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Code', dataIndex: 'assetCode', render: (text, record) => {
        return (
          <>
            {record.assetCode ? record.assetCode : '-'}

          </>
        )
      }
    },
    
   
    
    {
      title: "Service Date",
      dataIndex: "serviceDate",
      render: (text, record) => {
        return (
          <>
            {record.serviceDate ? moment(record.serviceDate).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return (
          <>
            {record.status ? record.status : '-'}
          </>
        )
      }
    },
    {
      title: "Age",
      dataIndex: "age",
      render: (text, record) => {
        return (
          <>
            {record.age ? record.age : '-'}
          </>
        )
      }
    },

  ];
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Service Overdue Report')
      .addColumns(data)
      .addDataSource(serviceOverdue, { str2num: true })
      .saveAs('Service Date Overdue Report.xlsx');
  }
  const getAssetCode = () => {
    const req = new AssetServiceReq();
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getServiceOverdue(req).then((res) => {
      if (res.status) {
        setAssetsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  return (
    <Card
      title={<span style={{ color: "white" }}>Service Date Overdue Report</span>}
      style={{ textAlign: "center" }} headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={serviceOverdue.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
    >
      <br /><br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={40}>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetCode" label='Asset Code'>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {serviceOverdue.map((assets) => {
                  return <Option key={assets.assetCode} value={assets.assetCode}>{`${assets.assetName} -- ${assets.assetCode}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                // defaultValue={'all'}
                placeholder='All Categories'
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={'all'}>All Categories</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategoryId}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Unit' name='plantId' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                placeholder='All Units'
                showSearch
                allowClear>
                  <Option key={'all'} value={'all'}>All Units</Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Location' name='locationName'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // defaultValue={'all'}
                placeholder='All Locations'
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All Locations</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationId}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                // placeholder='assetType'
                showSearch
                defaultValue={'MOVABLE'}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col >
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                block
                onClick={() => searchHandler()}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
          <Col >
            <Form.Item>
              <Button
                icon={<UndoOutlined />}
                onClick={resetHandler}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
          <Col span={3} style={{ marginTop: '2%' }}>
            <Button style={{ marginLeft: '680%' }} >Total no of Records:{length}
            </Button></Col>
        </Row>
      </Form>
      {serviceOverdue.length > 0 ? (<>
        <Table
          columns={columns}
          scroll={{ x: 1200 }}
          dataSource={serviceOverdue}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          bordered

        />  </>) : (
        <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
      )}

    </Card>
  );
}

export default AssetServiceDateReport;

