import { StockStatus, stockTypeEnum } from "@gtpl/shared-models/common-models";

export class StockOutResponseDto {
    stockId: number;
    lotNo: string;
    soNumber: string;
    rackPosition: string;
    quantity: number;
    totalCartons: number;
    totalPouches: number;
    loosePouches: number;
    pouchWeight: number;
    pallet: string;
    coldStorage: string;
    packingCompleted: string;
    palletCode:number;
    stockLocationId:number;
    productName : string;
    variantCode : string;
    stockType?: StockStatus
    constructor(stockId: number,lotNo: string,soNumber: string,rackPosition: string,quantity: number,totalCartons: number,totalPouches: number,loosePouches: number,pouchWeight: number,pallet:string,coldStorage: string,packingCompleted: string,palletCode:number,stockLocationId:number,productName?:string,variantCode?:string,stockType?:StockStatus){
        this.stockId = stockId;
        this.lotNo = lotNo;
        this.soNumber = soNumber;
        this.rackPosition = rackPosition;
        this.quantity = quantity;
        this.totalCartons = totalCartons;
        this.totalPouches = totalPouches;
        this.loosePouches = loosePouches;
        this.pouchWeight = pouchWeight;
        this.pallet = pallet;
        this.coldStorage = coldStorage;
        this.packingCompleted = packingCompleted;
        this.palletCode = palletCode;
        this.stockLocationId=stockLocationId;
        this.productName = productName;
        this.variantCode = variantCode;
        this.stockType = stockType
    }
}