

export class DtoOfRecruitmentDashboard {
    Plant:string;
    jobDescription:string;
    totalProfileAssigned: number;
    Rejected: number;
    toBeInterviewed: number;
    Selected: number;
    Status:string;
 
/**
 * 
 * @param Plant 
 * @param jobDescription 
 * @param totalProfileAssigned 
 * @param Rejected 
 * @param toBeInterviewed 
 * @param Selected 
 */
    constructor( Plant:string,jobDescription:string,totalProfileAssigned: number, Rejected: number,toBeInterviewed: number,  Selected: number,Status:string,) {
      this.Plant=Plant;
      this.jobDescription=jobDescription;
       this.totalProfileAssigned= totalProfileAssigned;
       this.Rejected = Rejected;
       this.toBeInterviewed = toBeInterviewed;
       this.Selected = Selected;
       this.Status=Status;
    }
 
 
 }