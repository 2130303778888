export class LanguagesDto{
    languagesId: number;
    languages: string;
    isActive: boolean;
    updatedAt: Date | any;
    updatedUser: string;
    createdAt: Date | any;
    createdUser: string;
    versionFlag: number;

    constructor(
    languagesId: number,
    languages: string,
    isActive: boolean,
    updatedAt: Date | any,
    updatedUser: string,
    createdAt: Date | any,
    createdUser: string,
    versionFlag: number,
    ){
        this.languagesId = languagesId;
        this.languages = languages;
        this.isActive = isActive;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.versionFlag = versionFlag;
    }
}

export const LanguagesDtoDefault : LanguagesDto = {
    languagesId: 0,
    languages: "",
    createdUser:"",
    isActive:true,
    createdAt: new Date(),
    updatedAt: new Date(),
    updatedUser: '',
    versionFlag: 1,
};