import { GlobalResponseObject, PoQtyGrnDtoReqDto, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { PoDropDownDto, PoAllDetailViewResponseModel, AllPoResponseModel, PoDropDownResponseModel, PoItemsResponseModel, PoDetailViewResponseModel, PoRequest, PoItemsDataModel, PoDataModel, PoDto, GetItemsForSaleOrderVendor, SOVendorRequest, PoDetailsDto, UpdatePoResponseModel, GetAllPoResponse, PoDetailsForPoIdResponse, PoSaleIdRequest, PoDataResponseModel, PoNumbersModel, PoReportRequest, PoReportResponseModel, PoNumbersResponseModel, PoFilterDto, POCloseRequest, POItemIdRequest, PoStockReq } from "@gtpl/shared-models/procurement-management";
import { CommonResponse } from '@gtpl/shared-models/masters';
import { BomIdsRequest } from '@gtpl/shared-models/sale-management';


export class PurchaseOrderService {
    URL = connection.DEPLOY_URL + '/po-creation';

    async getPoNumbers() {

        return await axios.post(this.URL + '/getPoNumbers')
            .then(res => {
                return res.data
            })
        //   return new PoDropDownResponseModel(true,11,'Food types retrived successfully',[new PoDropDownDto(1,'PO/001'),new PoDropDownDto(2,'PO/002')]);
    }
    async getPoDataById(poIdReq: PoRequest): Promise<PoItemsResponseModel> {

        return await axios.post(this.URL + '/getPoDataById', poIdReq)
            .then(res => {
                return res.data
            })
    }
    async CreatePo(poData: PoDetailsDto): Promise<PoItemsResponseModel> {
        // console.log(selectedCustomer, selectedClient,selectedSaleOrder,selectedStatus, selectedExpectedDate);
        return await axios.post(this.URL + '/CreatePo', poData)
            .then(res => {
                return res.data
            }).catch(err => {
                console.log(err)
            })
    }
    async UpdatePo(poData: PoDto): Promise<PoItemsResponseModel> {
        // console.log(selectedCustomer, selectedClient,selectedSaleOrder,selectedStatus, selectedExpectedDate);
        return await axios.post(this.URL + '/UpdatePo', poData)
            .then(res => {
                return res.data
            })
    }




    async getPODetailViewById(poIdReq: PoRequest): Promise<PoDetailViewResponseModel> {

        // poIdReq.purchaseOrderId=1;
        return await axios.post(this.URL + '/getPoDetailViewDataById', poIdReq)
            .then(res => {
                console.log(res + "................" + poIdReq)
                return res.data
            })
    }

    async getAllPoDetails(): Promise<AllPoResponseModel> {

        return await axios.post(this.URL + '/getAllPoDetails')
            .then(res => {
                return res.data
            })
    }


    async GetItemsForSaleOrderVendor(req: SOVendorRequest): Promise<GetItemsForSaleOrderVendor> {

        return await axios.post(this.URL + '/GetItemsForSaleOrderVendor', req)
            .then(res => {
                return res.data
            }).catch(err => {
                console.log(err)
                // console.log(err)
            })
    }

    async GetPurchaseOrderDetailsForPoId(poIdReq: PoRequest): Promise<UpdatePoResponseModel> {
        return await axios.post(this.URL + '/GetPurchaseOrderDetailsForPoId', poIdReq)
            .then(res => {
                return res.data
            })
    }

    async getAllPoDetailsInfo(req: PoFilterDto): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/getAllPoDetailsInfo', req)
            .then(res => {
                return res.data
            })
    }

    async getCancelledSosForPo(req: PoFilterDto): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/getCancelledSosForPo', req)
            .then(res => {
                return res.data
            })
    }

    async getCancelledPosData(req: PoFilterDto): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/getCancelledPosData', req)
            .then(res => {
                return res.data
            })
    }

    async getRevisedSos(req: PoFilterDto): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/getRevisedSos', req)
            .then(res => {
                return res.data
            })
    }

    async getTransferredSos(): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/getTransferredSos')
            .then(res => {
                return res.data
            })
    }

    async closePOManually(req: POCloseRequest): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/closePOManually', req)
            .then(res => {
                return res.data
            })
    }

    async CancelPoForCancelledSo(req: POCloseRequest): Promise<GetAllPoResponse> {
        return await axios.post(this.URL + '/CancelPoForCancelledSo', req)
            .then(res => {
                return res.data
            })
    }

    async deletePoItem(req: POItemIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/deletePoItem', req)
            .then(res => {
                return res.data
            })
    }

    /**
     * 
     * @param poIdReq 
     * @returns 
     */
    async GetPoDataDetailsForPoId(poIdReq: PoRequest): Promise<PoDetailsForPoIdResponse> {
        return await axios.post(this.URL + '/GetPoDataDetailsForPoId', poIdReq)
            .then(res => {
                return res.data
            })
    }

    async GetItemsForBomVendor(req: BomIdsRequest): Promise<GetItemsForSaleOrderVendor> {
        return await axios.post(this.URL + '/GetItemsForBomVendor', req)
            .then(res => {
                return res.data
            }).catch(err => {
                return err;
            })
    }

    /**
     * 
     * @param poIdReq 
     * @returns 
     */
    async getPoDetailsForSaleId(poSaleIdRequest: PoSaleIdRequest): Promise<PoDataResponseModel> {
        return await axios.post(this.URL + '/getPoDetailsForSaleId', poSaleIdRequest)
            .then(res => {
                console.log(res);
                return res.data
            })
    }

    async getPoNumberById(poIdReq: PoRequest): Promise<PoNumbersModel> {
        return await axios.post(this.URL + '/getPoNumberById', poIdReq)
            .then(res => {
                console.log(res);
                return res.data
            })
    }

    async getPurchaseOrdersForReport(req: PoReportRequest): Promise<PoReportResponseModel> {
        return await axios.post(this.URL + '/getPurchaseOrdersForReport', req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }
    async getPoDetailReport(req: PoReportRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPoDetailReport', req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }
    async getItemSubDropForPoReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemSubDropForPoReport', req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }
    
    async getPoNumber(): Promise<PoNumbersResponseModel> {
        return await axios.post(this.URL + '/getPoNumber')
            .then(res => {
                console.log(res);
                return res.data
            })
    }

    async getPoItemDetailsForSaleId(req:PoSaleIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPoItemDetailsForSaleId',req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }

    async getPoNumbersByVendor(req: BomIdsRequest) {

        return await axios.post(this.URL + '/getPoNumbersByVendor',req)
            .then(res => {
                return res.data
            })
        //   return new PoDropDownResponseModel(true,11,'Food types retrived successfully',[new PoDropDownDto(1,'PO/001'),new PoDropDownDto(2,'PO/002')]);
    }

    async getSoDropForPoReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForPoReport',req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }
    async getPoDropForPoReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPoDropForPoReport',req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }



    async getVendorDropForPoReport(req:UnitRequest): Promise<CommonResponse> {
        console.log(req);
        return await axios.post(this.URL + '/getVendorDropForPoReport',req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }

    async getItemDropForPoReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemDropForPoReport',req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }
    async getPoLifeStatus(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoLifeStatus',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getPoDeadStock(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoDeadStock',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getPoSubCategory(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoSubCategory',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getSoWiseQty(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSoWiseQty',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getDummyWiseQty(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getDummyWiseQty',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async fastMovingProd(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/fastMovingProd',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }

    async getPoLifeCycleStatus(req:UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoLifeCycleStatus',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getPoQtyGrnQty(dtoObj: PoQtyGrnDtoReqDto): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoQtyGrnQty',dtoObj)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getPackingStockData(req:PoStockReq): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPackingStockData',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getItemSubCatForPackingStock(req: UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getItemSubCatForPackingStock',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getPoNumForPackingStock(req: UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoNumForPackingStock',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getSupplyPerformanceReport(req:PoStockReq): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSupplyPerformanceReport',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getItemSubCatForStockPerformance(req: UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getItemSubCatForStockPerformance',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getVendorsForSupplyPerformance(req: UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getVendorsForSupplyPerformance',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
    async getItemsForSupplyPerformance(req: UnitRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getItemsForSupplyPerformance',req)
        .then(res => {
            console.log(res);
            return res.data
        })
    }
}

