import axios from "axios";
import connection from './connection';
import {ExitActionDto, AllExitActionsResponseModel, ExitActionResponseModel } from '@gtpl/shared-models/hrms';

export class ExitActionService{

    URL = connection.DEPLOY_URL + '/exit-action';

    async createExitAction(data: ExitActionDto): Promise<ExitActionResponseModel>{
        return await axios.post(this.URL + '/createExitAction', data)
            .then(res => {
                return res.data
            })
    }

    async updateExitAction(data: ExitActionDto): Promise<ExitActionResponseModel>{
        return await axios.post(this.URL + '/updateExitAction', data)
            .then(res => {
                return res.data
            })
    }

    async getAllExitActions(): Promise<AllExitActionsResponseModel>{
        // const data = [
        //     {
        //     exitActionId: 1,
        //     employeeId: 2,
        //     employeeName: "Sample",
        //     exitDate: new Date,
        //     exitReason: "sample",
        //     remarks: 'Good',
        //     createdAt:new Date,
        //     createdUser: 'divya',
        //     isActive: true,
        //     updatedUser: '',
        //     versionFlag: 1,
        //     },
        //     {
        //         exitActionId: 2,
        //         employeeId: 3,
        //         employeeName: "Sample4",
        //         exitDate: new Date,
        //         exitReason: "sample4",
        //         remarks: 'Good',
        //         createdAt:new Date,
        //         createdUser: 'divyasri',
        //         isActive: false,
        //         updatedUser: '',
        //         versionFlag: 1,
        //     },
        // ];
        // return new AllExitActionsResponseModel(true, 23232, 'Exit Actions Retrieved Successfully', data)
        return await axios.post(this.URL + '/getAllExitActions')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateExitAction(data: ExitActionDto): Promise<ExitActionResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivateExitAction', data)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveExitActions(): Promise<AllExitActionsResponseModel>{
        return await axios.post(this.URL + '/getAllActiveExitActions')
           .then(res => {
               
               return res.data
           })
    }
}