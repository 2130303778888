import axios from 'axios';
import connection from './connection';
import { AllTypesOfLeavesResponseModel, TypesOfLeavesDto, TypesOfLeavesResponseModel} from '@gtpl/shared-models/hrms';

export class TypesOfLeavesService{

    URL = connection.DEPLOY_URL + '/types-of-leaves';

    async createTypesOfLeaves(typesOfLeaves: TypesOfLeavesDto ): Promise<TypesOfLeavesResponseModel> {
        console.log(typesOfLeaves);
       
        return await axios.post(this.URL + '/createTypesOfLeaves',typesOfLeaves)
            .then(res => {
                return res.data
            })
    }

    async getAllTypesOfLeaves(): Promise<AllTypesOfLeavesResponseModel> {
                
        return await axios.post(this.URL + '/getAllTypesOfLeaves')
            .then(res => {
                return res.data 
            })     
    }

    async  updateTypesOfLeaves(typesOfLeaves: TypesOfLeavesDto): Promise<TypesOfLeavesResponseModel> {
        return await axios.post(this.URL + '/updateTypesOfLeaves', typesOfLeaves)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateTypesOfLeave(typesOfLeaves: TypesOfLeavesDto): Promise<TypesOfLeavesResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateTypesOfLeave', typesOfLeaves)
                        .then(res => {
                                return res.data
                        })
                }
    async getAllActiveTypesOfLeaves(): Promise<AllTypesOfLeavesResponseModel> {

        return await axios.post(this.URL + '/getAllActiveTypesOfLeaves')
                        .then(res => {
                            return res.data
                        })
                }
                
}