import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm,Modal, Card, Form, FormInstance, Tooltip, Switch, Input, Button, Tag, Row, Col, Select, Descriptions, Drawer, Typography, Tabs, DatePicker } from 'antd';
import { CookingDto, SoakingDto } from '@gtpl/shared-models/production-management';
import { CookingService, SoakingInventoryService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined ,SafetyCertificateOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
const { Option } = Select;
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import {BlanchingCookingForm} from '@gtpl/pages/production-management/production-management-components/blanching-cooking-form'


import { Link, Redirect } from 'react-router-dom';
import { FgStatusEnum, JobCompletedEnum, OperationTypeEnum, ShiftsEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import './cooking-grid.css';

/* eslint-disable-next-line */
export interface CookingGridProps { }

export function CookingGrid(
  props: CookingGridProps
) {
  const plantId = JSON.parse(localStorage.getItem('unit_id'))
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [readyForCookingInfo, setReadyForCookingInfo] = useState([]);
  const [cookingInfo, setCookingInfo] = useState([]);
  let total = 0;
  const [object, setObject] = useState(null);
  const [workShitValue, setWorkShiftvalue] = useState(null);
  const [dateValue, setDateValue] = useState(moment());
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [saleId, setSaleId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalType,setModalType] = useState<string>();
  const [setsData,setSelectedData] = useState<CookingDto>();

  const cookingService = new CookingService();

  useEffect(() => {
    getReadyForCookingInventory();
    getCookingInprogressLogs();
  }, []);

  const getReadyForCookingInventory = () => {
    const unitIdReq: UnitRequest = {
      unitId: plantId
    }
    cookingService.getReadyForCookingInventory(unitIdReq).then(res => {
      if (res.status) {
        setReadyForCookingInfo(res.data);
      } else {
        setReadyForCookingInfo([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setReadyForCookingInfo([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getCookingInprogressLogs = () => {
    const unitIdReq: UnitRequest = {
      unitId: plantId
    }
    cookingService.getCookingInprogressLogs(unitIdReq).then(res => {
      if (res.status) {
        setCookingInfo(res.data);
      } else {
        setCookingInfo([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setCookingInfo([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const handleCancel = () => {
    getReadyForCookingInventory();
    setIsModalVisible(false);
  };


  const setData = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.productionLogId)
    console.log(object);
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
    setModalType('so-view')
  }

  const displayModel = (data :CookingDto, type) => {
    setModalType('blanching-cooking')
    setIsModalVisible(true);
    setSelectedData(data)

    
  };


  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    // {
    //   title: 'Job Number',
    //   dataIndex: 'jobNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('jobNumber')
    // },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    }, 
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    }, {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      responsive:['md'],
      sorter: (a, b) => a.variantCode?.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    }, {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      align: 'right',
      sorter: (a, b) => a.physicalQuantity - b.physicalQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('physicalQuantity'),
      render: (text, rowData) => (Number(rowData.physicalQuantity) - Number(rowData.inputQuantity)).toFixed(3)
    },
    {
      title: 'Assign for Cooking',
      dataIndex: 'nextOperation',
      render: (text, rowData) => (
        <span>
          {Number(rowData.inputQuantity) < Number(rowData.physicalQuantity) ?
            <Link to='/assign-to-cooking' ><Button className='panel_button'>Assign For Cooking</Button></Link>
            : ''}

        </span>
      )

    },
  ];

  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    }, 
    // {
    //   title: 'So. Number',
    //   dataIndex: 'soNumber',
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    }, {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      responsive:['md'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    }, {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      align: 'right',
      sorter: (a, b) => a.physicalQuantity - b.physicalQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('physicalQuantity')
    },
    {
      title: `Job Completed`,
      dataIndex: 'jobStatus',
      render: (text, rowData) => (
        <span>
          {rowData.jobStatus === "YES" ?

            <Switch size="small"
              className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={rowData.jobStatus === "YES" ? true : false}
              disabled={rowData.jobStatus === "YES" ? true : false}
            />
            :
             workShitValue != null && dateValue != null &&
            <Popconfirm
              onConfirm={e => { cookingJobCompleted(rowData); }}
              title={
                rowData.jobStatus === "YES"
                  ? 'Are you sure to make it as incompleted ?'
                  : 'Are you sure to make it as completed ?'
              }
            >
              <Switch size="small"
                className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={rowData.jobStatus === "YES" ? true : false}
                disabled={rowData.jobStatus === "YES" ? true : false}
              />
            </Popconfirm>}
        </span>
      )
    },
      {
      title:'Action',
      dataIndex:'action',
      render: (text, rowData, index) => (
        <span> <SafetyCertificateOutlined
        style={{ color: 'red' }}
        onClick={() => displayModel(rowData , 'blanching-cooking')} />
         
        </span>
      )
    }
  ];

  const cookingJobCompleted = (cookingData: SoakingDto) => {
    cookingData.shift = workShitValue;
    cookingData.assignedTime =new Date(dateValue.format());
    cookingService.jobCompletedStatus(cookingData).then(res => {
      console.log(res);
      if (res.status) {
        getReadyForCookingInventory();
        getCookingInprogressLogs();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');
  }

  const dateChange = () => {
    console.log(form.getFieldValue('dateAndTime')
    )
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Cooking Inventory</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      {/* <br></br>
      <Row gutter={40} >
        <Col>

          <Card title={'Total: ' + total} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
      </Row>
      <br></br> */}

      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane
          key="1"
          tab={<span style={{ color: "green" }}>Assigned For Cooking: {readyForCookingInfo.length}</span>}
        >
          <Card title={<span>Assign Inventory for Cooking</span>}
            style={{ textAlign: 'center', padding: 0 }} headStyle={{ border: 0 }} bodyStyle={{ padding: 0 }}
          >
            <Table
              rowKey={record => record.deptId}
              columns={columnsSkelton1}
              dataSource={readyForCookingInfo}
              scroll={{ y: 500 }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              size='small'
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;

                pageData.forEach(({ physicalQuantity, inputQuantity }) => {
                  totalQuantity += (Number(physicalQuantity) - Number(inputQuantity));

                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={0} colSpan={4} ><Text >Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                    </Table.Summary.Row>

                  </>
                );
              }
              } />
          </Card>
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "green" }}>Cooking Assigned: {cookingInfo.length}</span>}
        >
          <Card title={<span>Cooking Assigned</span>}
            style={{ textAlign: 'center', padding: 0 }} headStyle={{ border: 0 }} bodyStyle={{ padding: 0 }}
          >
            <Form autoComplete="off" layout="vertical">
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item label="Work Shift:"
                    name={'shift'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={'Note: Select to Change the Job Status'}
                  >
                    <Select
                      showSearch
                      placeholder="Select Work Shift"
                      allowClear={true}
                      onChange={setWorkShiftvalue}
                    >
                      <Option value={null}>Select Sub Plant</Option>
                      {Object.keys(ShiftsEnum).map((type) => {
                        return <Option value={ShiftsEnum[type]}>{ShiftsEnum[type]}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label="Date & Time:"
                    name={'assignedTime'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={'Note: Select to Change the Assign time'}
                    initialValue={moment()} >
                    <DatePicker showTime onChange={setDateValue} format="YYYY-MM-DD HH:mm:ss"
                      showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={record => record.deptId}
              columns={columnsSkelton2}
              dataSource={cookingInfo}
              scroll={{ y: 500 }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              size='small'
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;

                pageData.forEach(({ physicalQuantity }) => {
                  totalQuantity += Number(physicalQuantity);

                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={3} colSpan={5} ><Text >Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                    </Table.Summary.Row>

                  </>
                );
              }
              } />
          </Card>
        </TabPane>
      </Tabs>
      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(modalType == 'so-view')? <SoInfoViewPages saleOrderId={saleId} screen="production"/> : ''}
        {(modalType=='blanching-cooking')? <BlanchingCookingForm isUpdate={true} blanchingData={setsData}/> : ''}

      </Modal>
    </Card>
  );
}

export default CookingGrid;

