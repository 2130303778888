import axios from 'axios';
import {AllDepartmentsResponseModel, AllLevelsResponseModel, LevelDropDownResponseModel, LevelDto, LevelIdRequset, LevelResponseModel} from '@gtpl/shared-models/masters';
import connection from './connection';

export class LevelService {

URL = connection.DEPLOY_URL + '/level';

  async createLevel(levelDto: LevelDto): Promise<LevelResponseModel> {
    console.log(levelDto);
      return await axios.post(this.URL + '/createLevel',levelDto)
          .then(res => {
              return res.data
          })
  }
  async  updateLevel(levelDto: LevelDto): Promise<LevelResponseModel> {
    return await axios.post(this.URL + '/updateLevel', levelDto)
           .then(res => {
               return res.data
           })
}
  async getAllLevels(): Promise<AllLevelsResponseModel> {
    console.log(URL);
    return await axios.post(this.URL + '/getAllLevels')
        .then(res => {
            return res.data
        })     
}
async getAllLevelDropDown():Promise<LevelDropDownResponseModel>{
  return await axios.post(this.URL + '/getAllLevelDropDown')
  .then(res => {
      return res.data
  })   
}

async  ActivateorDeactivateLevel(levelDto: LevelDto): Promise<LevelResponseModel> {
      return await axios.post(this.URL + '/activateorDeactivateLevel', levelDto)
              .then(res => {
                  return res.data
              })
 }
async  getLevelByLevelId(levelIdRequset: LevelIdRequset): Promise<LevelResponseModel> {
      return await axios.post(this.URL + '/getLevelByLevelId', levelIdRequset)
              .then(res => {
                  return res.data
              })
 }


} 