export class DeHeadingSampleDto{
    deheadingSampleId: number;
    sampleNo: number;
    jobNo?: string;
    batchNo?: string;
    temperature: number;
    discoloration: string;
    deterioration: string;
    softTail: string;
    blackTail: string;
    brokenOrUnUsable: string;
    improperDeheading: string;
    brownMeat: string;
    hangingMeatOrThroat: string;
    plantId: number;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
}