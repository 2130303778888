import { GlobalResponseObject } from "../global-response-object";
import { AssetCategoryWiseMaintenanceDetail } from "./asset-category-wise-maintenance-details";

export class AssetCategoryWiseMaintenanceResponse extends GlobalResponseObject{
    data: AssetCategoryWiseMaintenanceDetail[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data: AssetCategoryWiseMaintenanceDetail[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}