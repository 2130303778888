import { Form, Input, Button, Space, Card, Select, Col, Row, Layout, Table, Empty, InputNumber, Tooltip, Divider, Descriptions, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GrnService } from '@gtpl/shared-services/procurement';
import {Link, useLocation, useParams} from 'react-router-dom'
import moment from 'moment';
const { Text } = Typography;



import './grn-detailview-grid.css';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import { ColumnProps } from 'antd/lib/table';
import { GrnItemsInfo, GrnDto, GrnIdRequest, GrnDetailViewResponseModel, GrnDetailViewModel, grnItemsDataModel } from '@gtpl/shared-models/procurement-management'

/* eslint-disable-next-line */
export interface GrnDetailviewGridProps {
  GrnDetails: GrnDto;
}

export function GrnDetailviewGrid(props: GrnDetailviewGridProps) {
  const [page, setPage] = React.useState(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [grnDetailViewdata, setGrnDetailViewdata] = useState<GrnDetailViewModel>(null);
  const [indexVal, setIndexVal] = useState(0);
  const service = new GrnService;
  const [data, setdata] = useState<GrnItemsInfo[]>([])
  let location = useLocation();
  const grnId = location.state;
  const {id} = useParams<any>()


  //  let grnIdReq=  new GrnIdRequest(props.GrnDetails.grnId);

  const setEditForm = (rowData: any, index) => {
    setDefaultFormData(rowData);
    setIndexVal(index);
  }

  useEffect(() => {
    if(id){
      getGrnDataById(id)
    }
  }, [id]);

  const getGrnDataById = (grnId) => {
    service.getGrnDataById(new GrnIdRequest(grnId)).then(res => {
      console.log(res);
      if (res.status) {
        setGrnDetailViewdata(res.data)
        // AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          setGrnDetailViewdata(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setGrnDetailViewdata(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  const columns = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },

    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   render:(text, data, index)=>{
    //     console.log(data)
    //     return <span>{text}</span>
    //   }
    // },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      render:(text, data, index)=>{
        console.log(data)
        return <span>{text}</span>
      }
    },
    
    // {
    //   title: 'Item Category',
    //   dataIndex: 'itemCategory',
    //   render:(text, data, index)=>{
    //     console.log(data)
    //     return <span>{data.itemCategory?.itemCategory}</span>
    //   }
    // },

    {
      title: 'Item',
      dataIndex: 'itemName',
      render:(text, data, index)=>{
        console.log(data)
        return <span>{data.itemName?.itemName}</span>
      }
    },
    {
      title: 'Size',
      dataIndex: 'size',
      render:(text, data, index)=>{
        console.log(data)
        return <span>{data.size?.sizeName}</span>
      }
    },
    {
      title: 'PO Quantity',
      dataIndex: 'itemQuantity',
      key: 'itemQuantity',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Accepted Quantity',
      dataIndex: 'acceptedQuantity',
      key: 'acceptedQuantity',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectedQuantity',
      key: 'rejectedQuantity',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'itemCost',
      key: 'itemCost',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(parseFloat(text).toFixed(2)):0}</div>
      }
    },
    {
      title: 'Discount %',
      dataIndex: 'discountPer',
      key: 'discountPer',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discount',
      key: 'discount',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Tax',
      dataIndex: 'taxPer',
      key: 'taxPer',
      render: (value, record: grnItemsDataModel, index) => {
        return (record.taxPer) ? record.taxPer.taxName : '';
      },
    },
    {
      title: 'Tax %',
      dataIndex: 'taxPer',
      key: 'taxPer',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{data.taxPer.taxPercentage?Number(data.taxPer.taxPercentage):0}</div>
      }
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax',
      key: 'tax',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },
    {
      title: 'Total Amount',
      dataIndex: 'subAmount',
      key: 'subAmount',
      render:(text, data, index)=>{
        return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
      }
    },

  ]

  return (
    <>
      {
        grnDetailViewdata ?
          <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
            <Card title='GRN' style={{ textAlign: 'center'}} headStyle={{ backgroundColor: '#69c0ff',fontSize:'15px'  }} extra={<Link to='/grn-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} size='small'> 

              <Descriptions column={3}>
                <Descriptions.Item label="GRN Date">
                  {moment(grnDetailViewdata.grnDate).format('YYYY/MM/DD')}
                </Descriptions.Item>
                <Descriptions.Item label="GRN No">
                  {grnDetailViewdata.grnNumber}
                </Descriptions.Item>
                <Descriptions.Item label="PO No">
                  {(grnDetailViewdata.poId)?grnDetailViewdata.poId.poNumber:' - '}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Number">
                  {grnDetailViewdata.invoiceNumber}
                </Descriptions.Item>

                <Descriptions.Item label="Invoice Date">
                  {moment(grnDetailViewdata.InvoiceDate).format('YYYY/MM/DD')}
                </Descriptions.Item>
                <Descriptions.Item label="Total Quantity">
                  {grnDetailViewdata.totalQuantity}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Approver">
                  {grnDetailViewdata.approvar}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {grnDetailViewdata.status}
                </Descriptions.Item> */}
              </Descriptions>

            </Card><br></br>
            <Card title='GRN Items' style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>

            <Table columns={columns} 
            dataSource={grnDetailViewdata.grnItemsInfo} 
            scroll={{x:true}}
            pagination={false} bordered
            summary={(pageData) => {
              let totalQuantity = 0;
              let totalDiscount = 0;
              let totalTaxAmount = 0;
              let totalacceptedQty = 0;
              let totalrejectedQty = 0;
              // let totalTransport=0;
              let totalGrnCost=0;;
              let totalAmount=0
              pageData.forEach(({ itemQuantity, acceptedQuantity,rejectedQuantity,discount,tax,subAmount }) => {
                totalQuantity += Number(itemQuantity);
                totalacceptedQty += Number(acceptedQuantity);
                totalrejectedQty += Number(rejectedQuantity);
                totalDiscount += Number(discount);
                totalTaxAmount+= Number(tax);
                // totalTransport+= Number(transportation);
                totalGrnCost += Number(subAmount);
                
              });
      
              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={3} ><Text >Total Amount</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={6} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={7} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={7} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text ></Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text >{totalTaxAmount}</Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} ><Text >{totalGrnCost}</Text></Table.Summary.Cell>
                  </Table.Summary.Row>
                  
                </>
              );
            }}/>
            </Card>
          </Layout> : <></>
      }
    </>
  );
}

export default GrnDetailviewGrid;
