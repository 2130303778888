import { UserRequestDto } from '@gtpl/shared-models/common-models';
import { AllNotifyPartyResponse, CommonResponse, NotifyPartyDto, NotifyPartyResponse,NotifypartyRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';


export class NotifyPartyService {
    URL = connection.DEPLOY_URL + '/notify-party'

    async createNotifyParty(dto: NotifyPartyDto): Promise<NotifyPartyResponse> {
        return await axios.post(this.URL + '/createNotifyParty', dto)
            .then(res => {
                return res.data
            })
    }
    async updateNotifyParty(dto: NotifyPartyDto): Promise<NotifyPartyResponse> {
        return await axios.post(this.URL + '/updateNotifyParty', dto)
            .then(res => {
                return res.data
            })
    }
    async activateOrDeactivateNotifyParty(Dto: NotifyPartyDto): Promise<NotifyPartyResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateNotifyParty', Dto)
            .then(res => {
                return res.data
            })
    }
    async getAllNotifyParty(req?:UserRequestDto): Promise<AllNotifyPartyResponse> {
        return await axios.post(this.URL + '/getAllNotifyParty',req)
            .then(res => {
                return res.data
            })
    }
    async getAllNotifyPartyDropDown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllNotifyPartyDropDown')
            .then(res => {
                return res.data
            })
    }

    // async getTrimsInfoById(NotifyPartyReq: NotifypartyRequest): Promise<NotifyPartyResponse> {
    //     return await axios.post(this.URL + '/getNotifyPartyInfoById', NotifyPartyReq)
    //         .then(res => {
    //             return res.data
    //         })
    // }

    async getNotifyPartyById(req:NotifypartyRequest): Promise<NotifyPartyResponse> {
        return await axios.post(this.URL + '/getNotifyPartyInfoById', req)
            .then(res => {
                return res.data
            })
    }
    async getNotifyPartyInfoById(req:NotifypartyRequest): Promise<NotifyPartyResponse> {
        return await axios.post(this.URL + '/getNotifyPartyInfoById', req)
            .then(res => {
                console.log(res);
                return res.data
            })
    }


}