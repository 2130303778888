import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Input, Descriptions, Collapse, Tag, BackTop, Card, Modal } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantZone, RackDashboard, RackLevelResponseDto, RackTypeResponseDto, WarehouseDashboard, WarehouseResponseData } from '@gtpl/shared-models/warehouse-management';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import {ProjectOutlined,AuditOutlined,AppstoreOutlined,PartitionOutlined,CalendarOutlined,DownOutlined,BellFilled,InsertRowLeftOutlined } from '@ant-design/icons'
import { RackPositionService, RackService } from '@gtpl/shared-services/masters';
import { OccupancyEnum, RackDropDownDto, RackStatusRequest } from '@gtpl/shared-models/masters';
import { RackTypeEnum } from '@gtpl/shared-models/common-models';
import StockDetails from './stock-details';
const { Search } = Input;
const { Panel } = Collapse;

export interface ZoneDisplayProps {
    plantId: number;
    zoneId: number;
}

export function ZoneDisplay(props: ZoneDisplayProps) {    
    const [racks,setRacks] = useState<RackDashboard[]>([]);    
    const [searchString,setSearchString] = useState('');
    const [racksData,setRacksData] = useState<RackDropDownDto[]>([]);
    const [rackDashboard,setRackDashboard] = useState<WarehouseDashboard[]>([]);
    const [rackStatus,setRackStatus] = useState<any[]>(undefined);
    const [showVisible,setShowVisible] = useState<boolean>(false);
    const [rackInfo,setRackInfo] = useState<number>(0);

    const stockInService = new StockInService();
    const rackService = new RackService();
    const rackPositionService = new RackPositionService();
   
    const [A, setA] = React.useState(false)
    const [B, setB] = React.useState(false)
    const [C, setC] = React.useState(false)
    const [D, setD] = React.useState(false)
    const [E, setE] = React.useState(false)
    const [F, setF] = React.useState(false)
    const [AB, setAB] = React.useState(false)
    const [BC, setBC] = React.useState(false)
    const [CD, setCD] = React.useState(false)
    const [DE, setDE] = React.useState(false)
    const [EF, setEF] = React.useState(false)
    const [ABC, setABC] = React.useState(false)
    const [BCD, setBCD] = React.useState(false)
    const [CDE, setCDE] = React.useState(false)
    const [DEF, setDEF] = React.useState(false)
    const [EFA, setEFA] = React.useState(false)

    useEffect(() => {
      getRacksForZoneId()    
      getColdStorageStatus();    
    }, []);

    const getColdStorageStatus = () => {
      rackPositionService.getRackStatus({plantId:props.plantId,zoneId:props.zoneId}).then((res) => {
        if(res.status){
          setRackStatus(res.data)
        }
        else {
          if (res) {
            setRackStatus(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            setRackStatus(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        setRackStatus(undefined);
        AlertMessages.getErrorMessage(err.message);
    });
    }
    const getRacksForZoneId = () => {
      rackService.getRacksByZone({zoneId:props.zoneId}).then((res) => {
        if(res.status){
          setRacksData(res.data);
        }
        else {
          if (res) {
            setRacksData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            setRacksData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        setRacksData([]);
        AlertMessages.getErrorMessage(err.message);
    });
    }
    
    const displayRackNameTooltip = (props:any,rackName:string, type:string) => {
      return (
          <>
          {type==="PoNo"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                  <Descriptions.Item>
                      <strong>
                          Rack Name:&nbsp;&nbsp;{rackName}<br/>
                          PO No:&nbsp;&nbsp;{props.poNumber}<br/>
                      </strong>                    
                  </Descriptions.Item>
              </Descriptions>
              :type==="Varient"?
              <Descriptions layout="horizontal" size="default" contentStyle={{backgroundColor:"white", padding:'3px', fontSize:'12px'}}>
                  <Descriptions.Item>
                      <strong>
                          Rack Name:&nbsp;&nbsp;{rackName}<br/>
                          Varient:&nbsp;&nbsp;{props.variantCode}<br/>
                      </strong>                    
                  </Descriptions.Item>
              </Descriptions>:""}
          </>
      )
    }
    const displayTooltip = (props:any,rackName:string,type:string) => {
        return (
            <>
            {type === "poNo" ? 
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        PO Number:&nbsp;&nbsp;{props.poNumber}<br/>
                        Brand Name:&nbsp;&nbsp;{props.brandName}<br/>
                        Grade:&nbsp;&nbsp;{props.grade}<br/>
                        Product Name:&nbsp;&nbsp;{props.productName}<br/>
                        Variant Code:&nbsp;&nbsp;{props.variantCode}<br/>
                        Pallet Code:&nbsp;&nbsp;{props.palletCode}<br/>
                        Packing Completed:&nbsp;&nbsp;{props.packingCompleted}<br/>
                        {/* Stock State:&nbsp;&nbsp;{props.stockState}<br/> */}
                        Stock Type:&nbsp;&nbsp;{props.stockType} <br/>
                        Pallet Capacity:&nbsp;&nbsp;{props.palletCapacity} <br/>
                        Filled Cartons:&nbsp;&nbsp;{props.totalCartons}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="varient"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        Variant Code:&nbsp;&nbsp;{props.variantCode}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="poNumber"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        PO Number:&nbsp;&nbsp;{props.poNumber}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="inActive"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong style={{textAlign:'center'}}>
                        FORKLIFT WAY
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :""
            }
            </>
        )
    }

    const renderData = (racks) => {
      const mainTr = [];
      racks.forEach(rackObj => {
        console.log(rackObj);
        let rackTds = [];
        // rackTds.push(<td className={'ta-b'} rowSpan={rackObj.rackPositions.length * 2}> Rack - {rackObj.rackName}</td>); // A 
        rackObj.rackPositions.forEach(rackPosition => { // 1
          console.log(rackObj.rackName+rackPosition.rackLevel);
          if((rackObj.rackName === "AB" || rackObj.rackName === "BC" || rackObj.rackName === "CD" || rackObj.rackName === "DE" || rackObj.rackName === "EF" || rackObj.rackName === "ABC" || rackObj.rackName === "BCD" || rackObj.rackName === "CDE" || rackObj.rackName === "DEF" || rackObj.rackName === "EFA") && rackPosition.rackLevel === 1){
            rackTds.push(<td className={'ta-b'} rowSpan={rackPosition.rackDetails.length} style={{width:'24px',left:'0px'}}><b>{rackObj.rackName + rackPosition.rackLevel}</b></td>) // 1
            rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
              rackTds.push(<td className={'ta-b'}><b>{rackTypeObj.rackType}</b></td>)
              rackTypeObj.locations.forEach(childRackPostion => {
                console.log(childRackPostion);
                let bgColor;
                let name;
                let tooltipType;
                if(childRackPostion.isActive){
                  if(childRackPostion.occupancy === OccupancyEnum.ASSIGNED){
                    if(childRackPostion.palletCapacity > childRackPostion.totalCartons){
                      bgColor="#f7f871";
                    }
                    else if(childRackPostion.palletCapacity <= childRackPostion.totalCartons){
                      bgColor="#52c41a69";
                    }
                    // name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
                    // console.log("testt"+childRackPostion.poNo);
                    // console.log("testt"+childRackPostion.poNumber);
                    tooltipType = "poNo";
                  }
                  else if(childRackPostion.occupancy === OccupancyEnum.OCCUPIED){
                    bgColor="#c1dffa";
                    name = 
                    // childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):
                    childRackPostion.rackCode;
                    // tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
                  }
                  else{
                    bgColor="white";
                    name = "";
                    tooltipType = null;
                  }
                }
                else{
                    bgColor="grey";
                    name = "";
                    tooltipType = "inActive";
                }
                {console.log(rackTypeObj.rackType)}
                {console.log(childRackPostion.rackLevel)}
                {console.log(childRackPostion)}
                {
                    (tooltipType===null?
                    rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td>)
                    :tooltipType === "inActive"?
                    rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}><td style={{backgroundColor:bgColor}}>{name}</td></Tooltip>)
                    :rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}><td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td></Tooltip>))
                }
              });
              mainTr.push(<tr>{rackTds}</tr>);
               rackTds = [];
            });
          }
          else if(rackObj.rackName != "AB" && rackObj.rackName != "BC" && rackObj.rackName != "CD" && rackObj.rackName != "DE" && rackObj.rackName != "EF" && rackObj.rackName != "ABC" && rackObj.rackName != "BCD" && rackObj.rackName != "CDE" && rackObj.rackName != "DEF" && rackObj.rackName != "EFA"){
            rackTds.push(<td className={'ta-b'} rowSpan={rackPosition.rackDetails.length} style={{width:'24px',left:'0px'}}><b>{rackObj.rackName + rackPosition.rackLevel}</b></td>) // 1
            rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
              rackTds.push(<td className={'ta-b'}><b>{rackTypeObj.rackType}</b></td>)
              rackTypeObj.locations.forEach(childRackPostion => {
                console.log(childRackPostion);
                let bgColor;
                let name;
                let tooltipType;
                if(childRackPostion.isActive){
                  if(childRackPostion.occupancy === OccupancyEnum.ASSIGNED){
                    if(childRackPostion.palletCapacity > childRackPostion.totalCartons){
                      bgColor="#f7f871";
                    }
                    else if(childRackPostion.palletCapacity <= childRackPostion.totalCartons){
                      bgColor="#52c41a69";
                    }
                    // name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
                    // console.log("testt"+childRackPostion.poNo);
                    // console.log("testt"+childRackPostion.poNumber);
                    // tooltipType = "poNo";
                  }
                  else if(childRackPostion.occupancy === OccupancyEnum.OCCUPIED){
                    bgColor="#c1dffa";
                    //name = 
                    //childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):
                    //childRackPostion.rackCode;
                    //tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
                  }
                  else{
                    bgColor="white";
                    name = "";
                    tooltipType = null;
                  }
                }
                else{
                    bgColor="grey";
                    name = "";
                    tooltipType = "inActive";
                }
                {console.log(rackTypeObj.rackType)}
                {console.log(childRackPostion.rackLevel)}
                {console.log(childRackPostion)}
                {
                    (tooltipType===null?
                    rackTds.push(
                      <Tooltip title={childRackPostion.rackCode}>
                    <td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td>
                    </Tooltip>
                    )
                    :tooltipType === "inActive"?
                    rackTds.push(
                    // <Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}>
                      <td style={{backgroundColor:bgColor}}>{name}</td>
                      // </Tooltip>
                      )                   
                    :rackTds.push(
                    // <Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}>
                    <Tooltip title={'click to check '+childRackPostion.rackCode+' rack info'}>
                      <td className={'ta-b'} id={childRackPostion.rackCode} onClick={() => showStockDetails(childRackPostion.rackPositionId)} style={{backgroundColor:bgColor}}>{name}</td>
                    </Tooltip>
                      ))
                }
              });
              mainTr.push(<tr>{rackTds}</tr>);
               rackTds = [];
            });
          }
        });
      });
      return mainTr;
    }
    const eachRackDisplay = (racks:WarehouseDashboard[]) => {
        return (
          <>
    <div id="table-wrapper">
      <div id="table-scroll" style={{overflow:'scroll'}}>
        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
            <tr style={{backgroundColor:'#52c41a695c'}}>
              <th style={{width:'40px',left:'0px'}}></th>
              <th style={{width:'60px'}}></th>
              {
                props.plantId === 3?
                  racks[0]?.rackType === RackTypeEnum.Corner ? 
                    Array.from(Array(racks[0]?.colCount), (e, i) => {
                      return <th className={'ta-b'} style={{width:'45px'}}>{Number(racks[0]?.colCount)-i}</th>
                    })
                  :racks[0]?.rackType === RackTypeEnum.Center? 
                    Array.from(Array(racks[0]?.colCount), (e, i) => {
                      if(Number(racks[0]?.colCount)-2-i === 13){
                        return <><th className={'ta-b'} style={{ width: '45px', color:'white' }}>{27}</th><th className={'ta-b'} style={{ width: '45px', color:'white' }}>{26}</th><th className={'ta-b'} style={{ width: '45px' }}>{Number(racks[0]?.colCount)-2-i}</th></>
                      }
                      else if(i<25){
                        console.log("test" + racks[0]?.colCount + "test"+ i)
                        return <th className={'ta-b'} style={{width:'45px'}}>{Number(racks[0]?.colCount)-2-i}</th>
                      }
                    })
                    :""
                : props.plantId === 1?
                  Array.from(Array(racks[0]?.colCount), (e, i) => {
                    return <th className={'ta-b'} style={{width:'45px'}}>{Number(racks[0]?.colCount)-i}</th>
                  })
                :""
                // :
                // racks[0]?.rackType === RackTypeEnum.Center ? 
                // Array.from(Array(racks[0]?.colCount), (e, i) => {
                //   if(i===13){
                //     return <><th className={'ta-b'} style={{ width: '45px' }}>{racks[0]?.colCount-i}</th><th className={'ta-b'} style={{ width: '45px' }}></th>
                //     <th className={'ta-b'} style={{ width: '45px' }}></th>
                //     </>
                //   }
                //   else{
                //     return <th className={'ta-b'} style={{width:'45px'}}>{racks[0]?.colCount-i}</th>
                //   }
                // })
                // :""
              }
            </tr>
              {renderData(racks)}
        </table>
        </div>
        </div>
        </>
      )
    }

    const closeModal = (closeModal) => {
      if(closeModal){
        setShowVisible(false);
        window.location.reload();
      }
    }
    const handleTransferCancel = () => {
      setShowVisible(false);
    }
    
    const showStockDetails = (data:any) => {
      console.log(data);
      setRackInfo(data);
      setShowVisible(true)
    }
      
    
    const getZoneLevelRacks = (rackId:number) => {
      setRackDashboard([]);
      stockInService.getWarehouseRackDetails({plantId:props.plantId,zoneId:props.zoneId,rackId:rackId}).then((res) => {
        if(res.status){
          setRackDashboard(res.data);
        }
        else {
              if (res) {
                setRackDashboard([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                setRackDashboard([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
      }).catch((err) => {
          setRackDashboard([]);
          AlertMessages.getErrorMessage(err.message);
      });
    }
    const handleRack = (rackCode:string,rackId:number, rackType:RackTypeEnum) => {
      console.log(rackCode)
      if(rackCode === "A"){
        A==true? setA(false):setA(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);

      }
      else if(rackCode === "B"){
        B==true? setB(false):setB(true)
        setA(false);
        setC(false);
        setD(false);
        setE(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "C"){
        C==true? setC(false):setC(true)
        setB(false);
        setA(false);
        setD(false);
        setE(false);
        setF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "D"){
        D==true? setD(false):setD(true)
        setB(false);
        setC(false);
        setA(false);
        setE(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "E"){
        E==true? setE(false):setE(true)
        setB(false);
        setC(false);
        setD(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "F"){
        F==true? setF(false):setF(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "AB"){
        AB==true? setAB(false):setAB(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "BC"){
        BC==true? setBC(false):setBC(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "CD"){
        CD==true? setCD(false):setCD(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "DE"){
        DE==true? setDE(false):setDE(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "EF"){
        EF==true? setEF(false):setEF(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "ABC"){
        ABC==true? setABC(false):setABC(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "BCD"){
        BCD==true? setBCD(false):setBCD(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setCDE(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "CDE"){
        CDE==true? setCDE(false):setCDE(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setDEF(false);
        setEFA(false);
      }
      else if(rackCode === "DEF"){
        DEF==true? setDEF(false):setDEF(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setEFA(false);
      }
      else if(rackCode === "EFA"){
        EFA==true? setEFA(false):setEFA(true)
        setB(false);
        setC(false);
        setD(false);
        setE(false);
        setA(false);
        setF(false);
        setAB(false);
        setBC(false);
        setCD(false);
        setDE(false);
        setEF(false);
        setABC(false);
        setBCD(false);
        setCDE(false);
        setDEF(false);
      }
      getZoneLevelRacks(rackId)
    }
    return (
        <>
            <Tag style={{backgroundColor:'white', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}> Available</Tag>  &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#f7f871', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Partially Occupied</Tag> &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#52c41a69', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Fully Occupied</Tag> &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#c1dffa', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Reserved</Tag>
            <br/><br/>
            {
              racksData.length>0?racksData.map((res) => {
                // console.log(res);
                return <><Card style={{ overflow: 'auto', overflowY:'hidden',backgroundColor:'#bfbfbf', color:'white', height:50,margin:1  }} onClick={() => handleRack(res.rackCode, res.rackId, res.rackType)} title={<span><DownOutlined /><InsertRowLeftOutlined /><span style={{color:'white'}}>Rack - {res.rackCode} </span></span>} 
                extra={<span style={{color:'black'}}>{(rackStatus!=undefined?
                rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.ASSIGNED)?.cnt!=undefined?"RESERVED :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.ASSIGNED)?.cnt + ", ":"RESERVED : 0, " :"RESERVED : 0, ") + 
                (rackStatus!=undefined?rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.OCCUPIED)?.cnt!=undefined?"OCCUPIED :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.OCCUPIED)?.cnt + ", ":"OCCUPIED : 0, " :"OCCUPIED : 0, ") + 
                (rackStatus!=undefined?rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.NOT_OCCUPIED)?.cnt!=undefined?"AVAILABLE :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.NOT_OCCUPIED)?.cnt + ", ":"AVAILABLE : 0, " :"AVAILABLE : 0, ") +
                (rackStatus!=undefined? "Rack Capacity : " + res.cnt:"Rack Capacity : 0")
                }</span>}></Card>
                  {res.rackCode === "A"? A? eachRackDisplay(rackDashboard):null 
                  : res.rackCode === "B" ?B? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "C" ?C? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "D" ?D? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "E" ?E? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "F" ?F? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "AB" ?AB? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "BC" ?BC? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "CD" ?CD? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "DE" ?DE? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "EF" ?EF? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "ABC" ?ABC? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "BCD" ?BCD? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "CDE" ?CDE? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "DEF" ?DEF? eachRackDisplay(rackDashboard): null
                  : res.rackCode === "EFA" ?EFA? eachRackDisplay(rackDashboard): null
                  :null
                  }
              </>
              }):<Card> No Racks Found. </Card>
            }
            <Modal
              className='print-docket-modal'
              key={'modal' + Date.now()}
              width={'50%'}
              style={{ top: 30, alignContent: 'right' }}
              visible={showVisible}
              title={<React.Fragment>
              </React.Fragment>}
              onCancel={handleTransferCancel}
              footer={[

              ]}
            >
              {<StockDetails closeModal={closeModal} rackPositionId={rackInfo} />}
          </Modal>
        </>
    )
}

export default ZoneDisplay;