import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SkuPercentageDto } from '.';

export class SkuPercentagesResponseModel extends GlobalResponseObject{
    data?: SkuPercentageDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SkuPercentageDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}