export class AllGrnReportDto {
    grnId?: number;
    grnDate: Date;
    grnNumber?: number;
    invNo: number;
    poDate: Date;
    soDate: Date;
    venderName: string;
    totalValue: number;
    status?: string;
    createdUser: string;
    updatedUser?: string;
    soNumber?: number;
    poNumber?: number;
    invoiceNumber: number;
    createdDate?: Date;
    saleOrderId?: number;
    purchaseOrderId?: number;
    itemSubCategoryId?: number;
    itemSubCategory?:string;
    itemId?: number;
    item?: string;
    vendorId?: number;
    vendorName?: string;


    /**
     * 
     * @param grnId 
     * @param grnDate 
     * @param grnNumber 
     * @param invoiceNumber 
     * @param poDate 
     * @param soDate 
     * @param totalValue 
     * @param vendorName 
     * @param createdUser 
     * @param updatedUser 
     * @param soNumber 
     * @param poNumber 
     * @param createdDate 
     * @param saleOrderId 
     */


    constructor(grnId: number, grnDate: Date, grnNumber: number, invoiceNumber: number, poDate: Date, soDate: Date, totalValue: number, vendorName: string, createdUser: string, updatedUser: string, soNumber?: number, poNumber?: number, createdDate?: Date, saleOrderId?: number,purchaseOrderId?: number,itemSubCategoryId?: number,itemSubCategory?: string, itemId?: number, item?: string,vendorId?: number) {
        this.grnId = grnId;
        this.grnDate = grnDate;
        this.grnNumber = grnNumber;
        this.invoiceNumber = invoiceNumber;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.vendorName = vendorName;
        this.soNumber = soNumber;
        this.poDate = poDate;
        this.soDate = soDate;
        this.totalValue = totalValue;
        this.poNumber = poNumber;
        this.createdDate = createdDate;
        this.saleOrderId = saleOrderId;
        this.purchaseOrderId = purchaseOrderId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemSubCategory = itemSubCategory;
        this.itemId = itemId;
        this.item = item;
        this.vendorId = vendorId;
    }
}