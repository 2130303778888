import { AssetMaintainanceTransactionsDto } from "./asset-maintainance-transactions.dto";

export class AssetMaintainanceDto {
    assetMaintainanceId : number;
    assetId : number;
    assetLocationId : number;
    assetCondition : string;
    maintainanceFrequencyInDays : string;
    assigned: string;
    maintainanceType: string;
    owner: string;
    expiredDate : any;
    costOfRepair:string;
    damageReason: string;
    remarks: string;
    status: string;
    isActive : boolean;
    createdAt : Date;
    createdUser: string;
    updatedAt : Date;
    updatedUser: string;
    versionFlag: number;
    assetMaintainanceTransactions:AssetMaintainanceTransactionsDto[];
    employeeName?: string;
    itemName?: string;
    assetsCode?: string;
    itemSubCategoryName?: string;
    plant?: string;
    currentLocation?: string;
    assetType?: string;
    invoiceNo?:string;
    assetSerialNo?:string;
    assetMode?:string;

    /**
     * 
     * @param assetMaintainanceId 
     * @param assetId 
     * @param assetLocationId 
     * @param assetCondition 
     * @param maintainanceFrequencyInDays 
     * @param assigned 
     * @param maintainanceType 
     * @param owner 
     * @param expiredDate 
     * @param costOfRepair 
     * @param damageReason
     * @param remarks 
     * @param status 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     * @param assetMaintainanceTransactions 
     */

    constructor(assetMaintainanceId : number,assetId : number,assetLocationId : number,assetCondition : string,maintainanceFrequencyInDays : string,assigned: string,maintainanceType: string,owner: string,expiredDate : any,costOfRepair:string,damageReason:string,remarks: string,status: string,isActive : boolean,createdAt : Date,createdUser: string,updatedAt : Date,updatedUser: string,versionFlag: number,assetMaintainanceTransactions:AssetMaintainanceTransactionsDto[],employeeName?: string,itemName?: string,assetsCode?: string,itemSubCategoryName?: string,
        plant?: string,
        currentLocation?: string,
        assetType?: string,
        invoiceNo?:string,
        assetSerialNo?:string,
        assetMode?:string
    ){

        this.assetMaintainanceId = assetMaintainanceId;
        this.assetId = assetId;
        this.assetLocationId = assetLocationId;
        this.assetCondition = assetCondition;
        this.maintainanceFrequencyInDays = maintainanceFrequencyInDays;
        this.assigned = assigned;
        this.maintainanceType = maintainanceType;
        this.owner = owner;
        this.expiredDate = expiredDate;
        this.costOfRepair = costOfRepair;
        this.damageReason = damageReason;
        this.remarks = remarks;
        this.status = status;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.assetMaintainanceTransactions = assetMaintainanceTransactions;
        this.employeeName = employeeName;
        this.itemName = itemName;
        this.assetsCode = assetsCode;
        this.itemSubCategoryName = itemSubCategoryName;
        this.plant = plant;
        this.currentLocation = currentLocation;
        this.assetType = assetType;
        this.invoiceNo = invoiceNo;
        this.assetSerialNo = assetSerialNo;
        this.assetMode = assetMode;
    }
}