export class AssetsSaleDto{
    assetSaleId:number ;
    assetId : number;
    approvedBy: string;
    assetSaleCost: string;
    status: string;
    reason: string;
    isActive: boolean;
    constructor(
        assetSaleId:number,
        assetId: number,
        approvedBy: string,
        assetSaleCost: string,
        status: string,
        reason:string,
        isActive: boolean,
         ){
        this.assetSaleId = assetSaleId;
        this.assetId = assetId;
        this.approvedBy = approvedBy;
        this.assetSaleCost = assetSaleCost;
        this.status = status;
        this.reason = reason;
        this.isActive = isActive;
    }
}