export class OperationsDropDown{
     operationId:number;
     operationName:string;
     workstationCategoryId?: number;
     /**
      * 
      * @param operationId 
      * @param operationName 
      */
     constructor(operationId:number, operationName:string, workstationCategoryId?: number){
        this.operationId = operationId;
        this.operationName = operationName;
        this.workstationCategoryId = workstationCategoryId;
    }
}