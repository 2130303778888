import { LocationDto, PalletDto } from '@gtpl/shared-models/masters';
import {SaleOrderDropDownDto, SaleOrderItemsDTO} from '@gtpl/shared-models/sale-management'
export class StockSoRequest {
    saleOrderId: number;
   
    constructor(
        saleOrderId: number){
        this.saleOrderId = saleOrderId;
    }
}
