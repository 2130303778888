import axios from 'axios';
import connection from './connection';
import { AllExpensesResponse, ExpensesDto, ExpensesResponse} from '@gtpl/shared-models/hrms';

export class ExpensesService{

    URL = connection.DEPLOY_URL + '/expenses';

    async createExpenses(expensesDto: ExpensesDto ): Promise<ExpensesResponse> {
        console.log(expensesDto);
       
        return await axios.post(this.URL + '/createExpenses',expensesDto)
            .then(res => {
                return res.data
            })
    }

    async getAllExpenses(): Promise<AllExpensesResponse> {
                
        return await axios.post(this.URL + '/getAllExpensess')
            .then(res => {
                return res.data 
            })     
    }

    async  updateExpenses(expensesDto: ExpensesDto): Promise<ExpensesResponse> {
        return await axios.post(this.URL + '/updateExpenses', expensesDto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateExpenses(expensesDto: ExpensesDto): Promise<ExpensesResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateExpenses', expensesDto)
                        .then(res => {
                                return res.data
                        })
                }
                
}