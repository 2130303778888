import { CurrencyDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllCurrencyResponseModel extends GlobalResponseObject {
    data?: CurrencyDto[];

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CurrencyDto[]){
        super(status,intlCode,internalMessage);
        this.data = data;
    }
}

