import { CommonResponse } from '@gtpl/shared-models/masters';
import { GrnRequest, GrnVehicleInfo, RMVehicleInfoResponse } from '@gtpl/shared-models/raw-material-procurement';
import axios from 'axios';
import connection from './connection';
export class RmVehicleInspectionService {
    URL = connection.DEPLOY_URL + '/vehicle-inspection';

    async createVehicleInspectionInfo(values:GrnVehicleInfo): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createVehicleInspectionInfo', values)
            .then(res => {
                return res.data
            })
    }


   async getVehicleInspectionInfo(grnReq): Promise<CommonResponse>{
       return await axios.post(this.URL+ '/getVehicleInspectionInfo', grnReq)
           .then(res => {
               return res.data
           })
   }
}