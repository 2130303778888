import { DeHeadingSampleDto } from "./deheading-sample.dto";

export class DeheadingQaQcDto {
    deheadingQAQCId: number;
    logId:number;
    varietyOfMaterial: number;
    jobNo?: string;
    batchNo?: string;
    sampleCode: string;
    hOnQty: number;
    hLessQty: number;
    plantId: number;
    createdAt: Date;
    createdUser: string | null;
    updatedUser: string | null;
    deheadingSampleInfo: DeHeadingSampleDto[];
}