export class ItemssubLotDto{
    sublotNumber:string
    lotNumber:string
    quantity:number
    opBoxes:number
    constructor(
        sublotNumber:string,
        lotNumber:string,
        quantity:number,
        opBoxes:number
    ){
        this.sublotNumber=sublotNumber;
        this.lotNumber=lotNumber;
        this.quantity=quantity;
        this.opBoxes=opBoxes;
    }
     }