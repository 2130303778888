export class RmLevelDto{
    levelId?:number;
    levelName:string;
    levelCode:string;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    
    /**
     * 
     * @param levelId 
     * @param levelName 
     * @param levelCode 
     * @param createdUser
     * @param isActive
     */
    constructor(levelId:number,levelName:string,levelCode:string,createdUser:string,isActive:boolean){
    this.levelId = levelId;
    this.levelName = levelName;
    this.levelCode = levelCode;
    this.createdUser = createdUser;
    this.isActive = isActive
    }
} 
