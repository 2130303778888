export class DepartmentDto {
    deptId: number;
    deptName: string;
    isActive: boolean;
    createdUser: string;
    deptHead?: number;

    /**
     * 
     * @param deptId number
     * @param deptName string
     * @param isActive boolean
     * @param createdUser string
     * @param deptHead number
     */
    constructor(deptId: number, deptName: string, isActive: boolean, createdUser: string, deptHead?: number) {
        this.deptId = deptId;
        this.deptName = deptName;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.deptHead = deptHead
    }
}
export const DepartmentDtoDefault: DepartmentDto = {
    deptId: 0,
    deptName: '',
    isActive: true,
    createdUser: '',
    deptHead: 0,


}; 