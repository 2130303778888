import { CategoryEnum, RackPositionEnum } from "../enums";
import { RfidAssocProductModel } from "./rfid-assoc-product.model";

export class RfidTagModel{
    plantId: number;
    rfIds: string;
    type: CategoryEnum;
    primaryId: number;
    id: string;
    length: number;
    breadth: number;
    width: number;
    boundaries?: string;
    level?: number;
    position?: RackPositionEnum;
    assocPalletId?: string;
    productInfo?: RfidAssocProductModel;


    /**
     * 
     * @param plantId 
     * @param rfIds 
     * @param type 
     * @param primaryId
     * @param id 
     * @param length 
     * @param breadth 
     * @param width 
     * @param boundaries 
     * @param level 
     * @param position 
     * @param assocPalletId 
     * @param productInfo 
     */
    constructor(plantId: number,rfIds: string, type: CategoryEnum,primaryId: number,id: string, length: number,breadth: number,width: number,boundaries?: string,level?: number, position?: RackPositionEnum,assocPalletId?: string,productInfo?: RfidAssocProductModel){
        this.plantId = plantId;        
        this.rfIds = rfIds;
        this.type = type;
        this.primaryId = primaryId;
        this.id = id;
        this.length = length;
        this.breadth = breadth;
        this.width = width;
        this.boundaries = boundaries;
        this.level = level;
        this.position = position;
        this.assocPalletId = assocPalletId;
        this.productInfo = productInfo;
    }
}