

export class ItemForBrandReq{
   brandId?:number;
   itemSubCategoryId?:number
   saleOrderId?:number
}

export class BrandReqForLocation{
   brandId:number;
   unitId:number;
}