import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Typography, Form, Col, Row, Select, Modal, Popconfirm, message, InputNumber, Alert } from 'antd';
import './stock-out.css';
import { ColumnProps } from 'antd/lib/table';
import { PrinterOutlined, SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { BulkStockOutRequest, FgStockStatusEnum, InputIdRequest, SoPlantRequest, StockOutModel, StockStatusRequest } from '@gtpl/shared-models/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { ProductService } from '@gtpl/shared-services/masters';
import { ProductDto } from '@gtpl/shared-models/masters';
import { StockStatus, StockTypesEnum } from '@gtpl/shared-models/common-models';
import QRCode from "react-qr-code";
const { Option } = Select;
/* eslint-disable-next-line */
export interface GeneratePickListProps { }

export function GeneratePickList(
  props: GeneratePickListProps
) {
  const [stockOutForm] = Form.useForm();
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const { Text } = Typography;
  const [stockInData, setStockInData] = useState<StockOutModel[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<number>(0);
  const [buttonVisible, setButtonVisible] = useState<boolean>(false);
  const [stockoutData, setStockoutData] = useState<any[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedQty, setSelectedQty] = useState<number>(0);
  const stockService = new FGStockService();
  const stockInService = new StockInService();
  const saleOrderService = new SaleOrderService();
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<StockOutModel[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [updateState, setupdateState] = useState<number>(0);
  const [productsData, setProductsData] = useState<ProductDto[]>([]);
  const [isDummy, setIsDummy] = useState<boolean>(false)
  const activeProducts = new ProductService();
  let totalStock = 0;
  let stockOutQty = 0;

  const shippedCartons = [];
  let totalStockOutQty = 0;
  // const setDefaultStockOutQty = () => {
  //   console.log('stockInDatarepavk')

  //   const newStockData = [...stockInData]
  //   for(let i=0;i<stockInData.length;i=i+1){
  //     newStockData[i].stockOutQty = newStockData[i]?.totalCartons
  //   }
  //     // setStockInData(newStockData)
  // }

  // useEffect(() => {
  //   if (stockInData) {
  //     // console.log(stockOutForm.getFieldValue('stockOutType'))
  //     // if((stockOutForm.getFieldValue('stockOutType') == StockTypesEnum.RE_PACK) ||  (stockOutForm.getFieldValue('stockOutType') == StockTypesEnum.RE_PROCESS)){
  //     //   setDefaultStockOutQty()
  //     // }
  //     const selectedQtyFlag = new Set<boolean>()
  //     const dummyFlag = new Set<boolean>()
  //     if (selectedRowsData.length > 0) {
  //       selectedRowsData.forEach(item => {
  //         if (item.stockType == StockStatus.dummy) {
  //           dummyFlag.add(true)
  //         }
  //         if (Number(item.stockOutQty) > 0 && (Number(item.stockOutQty) <= Number(item.totalCartons))) {
  //           selectedQtyFlag.add(true)
  //         }
  //         else {
  //           selectedQtyFlag.add(false)
  //         }
  //         if (item.stockOutQty == 0) {
  //           selectedQtyFlag.add(false)
  //         }
  //       })
  //       if (selectedRowsData.length > 0) {
  //         if (!(dummyFlag.has(true))) {
  //           if (Number(totalStockOutQty) <= Number(saleOrderData) && !(selectedQtyFlag.has(false))) {
  //             setBtnDisable(false);
  //             console.log('false --btn')
  //           }
  //           if (selectedQtyFlag.has(false)) {
  //             setBtnDisable(true)
  //           }
  //         } else if (dummyFlag.has(true)) {
  //           if (!(selectedQtyFlag.has(false))) {
  //             setBtnDisable(false);
  //             console.log('true --btn')
  //             0
  //           }
  //           if (selectedQtyFlag.has(false)) {
  //             setBtnDisable(true)
  //           }
  //         }

  //         else {
  //           setBtnDisable(true);
  //           if (Number(totalStockOutQty) > Number(saleOrderData)) {
  //             AlertMessages.getErrorMessage("Shipment quantity should be less than Ordered qty.");
  //           }
  //           else if ((selectedQtyFlag.has(false))) {
  //             AlertMessages.getErrorMessage("Shipment qunatity should be greater than 0");

  //           } else {
  //             AlertMessages.getErrorMessage("Something went wrong");
  //           }
  //         }


  //       }
  //     }

  //     // console.log(totalStockOutQty,'total')
  //   }
  // }, [stockInData])


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows);
      const selectedQtyFlag = new Set<boolean>()
      const shippedQtyFlag = new Set<boolean>()
      const dummyFlag = new Set<boolean>()
      selectedRows.forEach((data,index) => {
        console.log(data.totalCartons+"********"+index+"**********"+data.stockOutQty);
        if (Number(data.stockOutQty) > 0) {
          selectedQtyFlag.add(true)
        } else {
          selectedQtyFlag.add(false)

        }
        if (data.stockType == StockStatus.dummy) {
          console.log('true----')
          dummyFlag.add(true)
        }

        if (data.stockOutQty > data.totalCartons) {
          shippedQtyFlag.add(false)
          console.log('true cartons > orginal cartons')
        } else {
          shippedQtyFlag.add(true)
        }
        totalStockOutQty = Number(totalStockOutQty) + Number(data.stockOutQty)
      })
      setSelectedQty(totalStockOutQty);
      setSelectedRowKeysData(selectedRowKeys);
      console.log(selectedRows);
      setSelectedRowsData(selectedRows);
      console.log(selectedRowsData);
      setBtnDisable(false);
      if (selectedRows.length > 0) {
        console.log('enter')
        if (!(dummyFlag.has(true))) {
          console.log('false--')
          if (Number(totalStockOutQty) <= Number(saleOrderData) && !(shippedQtyFlag.has(false)) && !(selectedQtyFlag.has(false))) {
            // setTransferVissible(true)
            setBtnDisable(false);
            console.log(btnDisable, 'tbn')
          }
          if (shippedQtyFlag.has(false)) {
            setBtnDisable(true)
          }
        } else if (dummyFlag.has(true)) {
          console.log('true--')
          if (!(selectedQtyFlag.has(false)) && !(shippedQtyFlag.has(false))) {
            // setTransferVissible(true)
            setBtnDisable(false);
            console.log(btnDisable, 'tbn')
          }
          if (shippedQtyFlag.has(false)) {
            setBtnDisable(true)
          }
        }
        else {
          setBtnDisable(true);
          if (Number(totalStockOutQty) > Number(saleOrderData)) {
            AlertMessages.getErrorMessage("Shipment cartons should be less than Ordered cartons.");
          }
          else if ((selectedQtyFlag.has(false))) {
            AlertMessages.getErrorMessage("Uncheck the rows with no input");

          } else {
            AlertMessages.getErrorMessage("Something went wrong");
          }
        }
      }
      else {
        // setTransferVissible(false)
        setBtnDisable(true);

      }
    },
  };
  const cartonsOnChange = (value, index, rowData) => {
    console.log(index);
    rowData.stockOutQty = value
    console.log(value, 'row value')
    const newData = [...stockInData];
    newData[index].stockOutQty = value;
    setStockInData(newData);
    if (value > rowData.totalCartons) {
      setBtnDisable(true)
      AlertMessages.getErrorMessage('Shipment cartons should be less than Ordered cartons--')

    }
    if (value == 0 || value == null) {
      AlertMessages.getErrorMessage('Shipment cartons should be greater than zero')
      setBtnDisable(true)
    }
  }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });



  const getAllActiveProducts = () => {
    activeProducts.getAllActiveProducts().then((res) => {
      if (res.status) {
        setProductsData(res.data);
        let filtersArray: { text: string, value: string }[] = []
        res.data.map(item => {
          if (!(filtersArray.includes({ text: item.productName, value: item.productName })))
            filtersArray.push({ text: item.productName, value: item.productName })
        })
        setFiltersData(filtersArray)

        console.log(res.data)
        // console.log(getProductFilters())
      } else {
        setProductsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProductsData([]);
    });
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  useEffect(() => {
    getStockInSaleOrders();
    getAllActiveProducts();
  }, []);

  const getStockInSaleOrders = () => {
    setSoData([])
    stockService.getStockInSaleOrders({ inputId: plantId, dispatchType: stockOutForm.getFieldValue('stockOutType') }).then((res) => {
      if (res.status) {
        setSoData(res.data);
      } else {
        if (res.intlCode) {
          setSoData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoData([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };
  const getSaleOrderDetailsById = (soNumber: number) => {
    saleOrderService.getTotalJobCases({ saleOrderId: soNumber }).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data1);
      } else {
        if (res.intlCode) {
          setSaleOrderData(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setSaleOrderData(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSaleOrderData(undefined);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getCheckPickListGeneratedORNot = (value) => {
    stockService.getCheckPickListGeneratedORNot({saleOrderId:value}).then((res) => {
      if (res.status) {
        setButtonVisible(res.status);
        setIsModalVisible(false);
      } else {
        if (res.intlCode) {
          setButtonVisible(false);
          setIsModalVisible(true);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setButtonVisible(false);
          setIsModalVisible(true);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setButtonVisible(false);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getStockInData = (soNumber: number, stockOutType: string) => {

    stockInService.getReportedStockForSoId({ saleOrderId: soNumber, plantId: plantId, stockOutType: stockOutType }).then(res => {
      // stockInService.getReportedStockForSoId({saleOrderId:soNumber,plantId:plantId}).then(res => {
      if (res.status) {

        // console.log(res.data);
        setStockInData(res.data);
        setStockoutData(res.data);
        if(res.data[0].stockType != 'dummy'){
          getSaleOrderDetailsById(soNumber);
        }
      } else {
        if (res.intlCode) {
          setStockInData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setStockInData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleSoChange = (value) => {
    getStockInSaleOrders()
    setBtnDisable(true)
    setSelectedRowsData([])
    if (value && stockOutForm.getFieldValue('saleOrderId') > 0) {
      stockOutForm.setFieldsValue({
        saleOrderItemId: stockOutForm.getFieldValue('saleOrderId')
      })
      getCheckPickListGeneratedORNot(stockOutForm.getFieldValue('saleOrderId'));
      getStockInData(stockOutForm.getFieldValue('saleOrderId'), stockOutForm.getFieldValue('stockOutType'));
    }
  }

  const updateStockOut = () => {
    console.log(selectedRowsData);
    // if(stockOutForm.getFieldValue("saleOrderId")){
    setBtnDisable(true)
    let totIssuedQty = 0
    let totStockOutQty = 0
    const dummyFlag = new Set<boolean>();
    let stockOutModel: BulkStockOutRequest[] = [];
    selectedRowsData?.forEach((item, index) => {
      console.log(item)
      console.log(index);
      totStockOutQty = Number(totStockOutQty)+Number(item.stockOutQty);
     
      if(selectedRowsData[0].stockType == 'dummy'){
        dummyFlag.add(true)
      }

      const issuedQty = Number(item.stockOutQty) * Number(item.pouches) * Number(item.pouchWeight)
      totIssuedQty = totIssuedQty+Number(item.stockOutQty) * Number(item.pouches) * Number(item.pouchWeight);
      // item.stockOutQty = stockInData[index].stockOutQty
      const stockOutData = new BulkStockOutRequest(item.stockId, FgStockStatusEnum.StockIn, localStorage.getItem("username").replace(/"/g, ""), item.stockOutQty, stockOutForm.getFieldValue('stockOutType'), totStockOutQty, totIssuedQty,issuedQty)
      stockOutModel.push(stockOutData)
    })
    stockOutForm.validateFields().then(rec => {
      if(!(dummyFlag.has(true))){

        if (Number(selectedQty) <= Number(saleOrderData)) {
          
            stockService.updateStockOutStatus(stockOutModel).then(res => {
            if (res) {
              if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                setBtnDisable(true)
                window.location.reload()
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setBtnDisable(false)
              }
             
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
          })
        }
        else {
          AlertMessages.getErrorMessage("Shipment qty should be less than Ordered qty. ");
        }
      }else{
        stockService.updateStockOutStatus(stockOutModel).then(res => {
          if (res) {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              setBtnDisable(true)
              window.location.reload()
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
              setBtnDisable(false)
            }
            // getStockInData(stockOutForm.getFieldValue("saleOrderId"),stockOutForm.getFieldValue("stockOutType"));
            // stockOutForm.setFieldsValue({saleOrderId:''});
            // setStockInData([]);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }
     

    })
  }

  const printStockOut = () => {
    stockService.generatePickList({saleOrderId:stockOutForm.getFieldValue("saleOrderId"),plantId:Number(localStorage.getItem("unit_id"))}).then((res) => {
      if(res.status){
        setIsModalVisible(true);
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onSearch(val) {
    // console.log('search:', val);
  }
  /**
     * get form data 
     * @param fromDoc 
     * @param toDoc 
     */
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };

  const onReset = () => {
    stockOutForm.resetFields();
    setStockInData([])
  };


  const columnsSkelton: ColumnProps<StockOutModel>[] = [
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      fixed: 'left',
      filters :[
          {text:"Kakinada", value:"Kakinada"},
          {text:"Unit 1", value:"Unit 1"},
          {text:"Unit 2", value:"Unit 2"},
      ],
      filterMultiple :false,
      onFilter : (value,record)=>{
           return record.unitName == value;
      }
  },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      sorter: (a, b) => a.brandName?.length - b.brandName.length || a.brandName.localeCompare(b.brandName),
      sortDirections: ['descend', 'ascend'],
      //render: (value) => (<span style={{float:'right'}}>{value}</span>)

      ...getColumnSearchProps('brandName')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      sorter: (a, b) => a.variantCode?.length - b.variantCode.length || a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>),
      ...getColumnSearchProps('packStyle')
    },
    {
      title: 'Product Category',
      dataIndex: 'productCategory',
      sorter: (a, b) => a.productCategory.length - b.productCategory.length || a.productCategory.localeCompare(b.productCategory),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('productCategory'),
      responsive: ["lg"],
      filters: [
          { text: 'Over Count', value: 'overcount'},
          { text: 'Under Count', value: 'undercount'},
          { text: 'G1', value:  'g1' },
          { text: 'G2', value: 'g2'  },
          { text: 'Cut', value: 'cut'  },
          { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
          return record.productCategory == value;
      },
  },
  {
    title: 'Cartons Shortage',
    dataIndex: 'cartonsShortage',
    sorter: (a, b) => a.cartonsShortage.length - b.cartonsShortage.length || a.cartonsShortage.localeCompare(b.cartonsShortage),
    sortDirections: ['descend', 'ascend'],
    filters: [
      { text: 'YES', value: 'YES' },
      { text: 'NO', value: 'NO' },
    
    ],
    filterMultiple: false,
    onFilter: (value, record) => 
    {
      // === is not work
      return record.cartonsShortage === value;
    },
  },
  {
    title: 'Pouches Shortage',
    dataIndex: 'pouchesShortage',
    sorter: (a, b) => a.pouchesShortage.length - b.pouchesShortage.length || a.pouchesShortage.localeCompare(b.pouchesShortage),
    sortDirections: ['descend', 'ascend'],
    filters: [
      { text: 'YES', value: 'YES' },
      { text: 'NO', value: 'NO' },
    
    ],
    filterMultiple: false,
    onFilter: (value, record) => 
    {
      // === is not work
      return record.pouchesShortage === value;
    },
  },
    {
      title: 'Cartons',
      dataIndex: 'totalCartons',
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: 'Rack Position',
      dataIndex: 'rackPosition',
      sorter: (a, b) => a.rackPosition.toString().length - b.rackPosition.toString().length || a.rackPosition.toString().localeCompare(b.rackPosition.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rackPosition'),
    },
  ];
  return (
    <Card
      title={<span style={{ color: 'white' }}>Print Pick List</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form layout={'vertical'} form={stockOutForm} name="control-hooks" onFinish={updateStockOut}>
        <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item initialValue={StockTypesEnum.Dispatch} name="stockOutType" label="Stock Out Type" rules={[{ required: true, message: 'Missing type' }]}>
              <Select
                showSearch
                placeholder="Type"
                allowClear
                onSearch={onSearch}
                onChange={handleSoChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.keys(StockTypesEnum).map(item => {
                  return <Option value={StockTypesEnum[item]}>{StockTypesEnum[item]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrderId" label="Customer PO" rules={[{ required: true, message: 'Missing SO Number' }]}>
              <Select
                showSearch
                placeholder="Select SO Number"
                allowClear
                onSearch={onSearch}
                onChange={handleSoChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {soData.map((dropData) => {
                  return (
                    <Option
                      name="saleOrderId"
                      key={dropData.saleOrderId}
                      value={dropData.saleOrderId}
                    >
                      {dropData.saleOrderNumber}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ marginTop: 30 }}  span={4}>
            <Button type="primary" onClick={() => printStockOut()} style={{ marginRight: 10, display:buttonVisible?'block':'none' }} >
              Generate Pick List
            </Button>
          </Col>
          <Col style={{ marginTop: 30 }}  span = {2}>
            <Button type="primary" onClick={onReset}>
              Reset
            </Button>
          </Col>

        </Row>
      
      </Form>
     
      <Modal
        className='dispatch-list'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
      >
        <br /><br />
        <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined />Print</span></Button>
        <div id="printme">

          <table className={'ta-b'} style={{ width: '100%' }} id="table-to-xls">
            <tr><td className={'ta-b'} colSpan={7} style={{ textAlign: 'center', fontSize: '18px' }}><h1>Dispatch List</h1></td>
            </tr>
            <tr>
              <td className={'ta-b'}>Sale Order</td>
              <td className={'ta-b'}>Product Sku</td>
              <td className={'ta-b'}>Brand</td>
              <td className={'ta-b'}>Cartons</td>
              <td className={'ta-b'}>Cold Storage</td>
              <td className={'ta-b'}>Pallet Code</td>
              <td className={'ta-b'}>Rack Position</td>
            </tr>
            {
              stockoutData.forEach((res) => {
                totalStock+=res.totalCartons;
              })
            }
            {stockoutData.map((stock) => {
              return (
                <tr>
                  <td className={'ta-b'}>{stock.poNumber}</td>
                  <td className={'ta-b'}>{stock.packStyle + " " +stock.variantCode}</td>
                  <td className={'ta-b'}>{stock.brandName}</td>
                  <td className={'ta-b'}>{stock.totalCartons}</td>
                  <td className={'ta-b'}>{stock.coldStorage}</td>
                  <td className={'ta-b'}>{stock.palletCode}
                  {/* <br /><QRCode size={256} style={{ height: "auto", maxWidth: "35%", width: "35%" }} value={`${stock.palletCode}`} viewBox={`0 0 256 256`} /> */}
                  </td>
                  <td className={'ta-b'}>{stock.rackPosition}<br />
                  <QRCode size={256} style={{ height: "auto", maxWidth: "35%", width: "35%" }} value={`${stock.rackPositionId}`} viewBox={`0 0 256 256`} /></td>
                 
                </tr>
              )
            })}
            <tr><td colSpan={3}>Total</td><td>{Math.round(totalStock)}</td><td colSpan={3}></td></tr>
          </table>
        </div>
      </Modal>
    </Card>
  );
}

export default GeneratePickList;
