export class ProductConversionDetailsDTO {

    date:Date;
    toSaleOrder:string;
    fromSaleOrder:string;
    quantity:number;
    lotnumber:string;
    conversionReason:string;
    fromOperation:string;
    toOperation:string;
    plant:string;

    constructor(
        date:Date,
        toSaleOrder:string,
        fromSaleOrder:string,
        quantity:number,
        lotnumber:string,
        conversionReason:string,
        fromOperation:string,
        toOperation:string,
        plant:string,
    ){
        this.date = date;
        this.toSaleOrder = toSaleOrder;
        this.fromSaleOrder = fromSaleOrder;
        this.quantity = quantity;
        this.lotnumber = lotnumber;
        this.conversionReason = conversionReason;
        this.fromOperation = fromOperation;
        this.toOperation = toOperation;
        this.plant = plant;
    }

}