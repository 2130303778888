import React,{useRef,useState,useEffect} from 'react';
import { Table, Card, Input, Button, Tooltip, } from 'antd';
import './dispatch-list-grid.css';
import {  Link } from "react-router-dom";
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FgStockStatusEnum, InputIdRequest, SoPlantRequest, StockOutModel, StockStatusRequest } from '@gtpl/shared-models/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
/* eslint-disable-next-line */
export interface dispatchlistgridProps {}

export function dispatchlistgrid(
  props: dispatchlistgridProps
) {
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const [stockInData,setStockInData] = useState<StockOutModel[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(''); 
  const stockService = new FGStockService();

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  useEffect(() => {
    getStockInData();
  }, []);

  
  const getStockInData = () => {
    stockService.getStockInData(new SoPlantRequest(0,Number(plantId))).then(res => {
      if (res.status) {
        setStockInData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }      
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  } 

  const updatedispatchlist = (stockInRecord:StockOutModel) => {
    // stockService.updateStockOutStatus(new StockStatusRequest(stockInRecord.stockId,FgStockStatusEnum.StockOut,createdUser)).then(res => {
    //   if (res) {
    //     AlertMessages.getSuccessMessage(res.internalMessage);
    //     getStockInData();
    //   } else {
    //     if (res.intlCode) {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     } else {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     }
    //   }      
    // }).catch(err => {
    //   AlertMessages.getErrorMessage(err.message);
    // })
  }

  const columnsSkelton: ColumnProps<StockOutModel>[] = [
    {
      title: 'LotNumber',
      dataIndex: 'lotNo',
      sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNo'),
    },
    {
      title: 'Sale Order No',
      dataIndex: 'soNumber',
      sorter: (a, b) => a.soNumber.length - b.soNumber.length || a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
    },
    {
      title: 'Rack Position',
      dataIndex: 'rackPosition',
      sorter: (a, b) => a.rackPosition.toString().length - b.rackPosition.toString().length || a.rackPosition.toString().localeCompare(b.rackPosition.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rackPosition'),
    },
    {
      title: 'Total Cartons',
      dataIndex: 'totalCartons',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Total Pouches',
      dataIndex: 'totalPouches',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Pouch Weight',
      dataIndex: 'pouchWeight',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Action',
      key:'action',
      width:100,
      render:(value,record)=>(<Tooltip title="Stock Out">
          <DeliveredProcedureOutlined onClick={event=>updatedispatchlist(record)} style={{fontSize:'24px',color:'#1890ff'}}/>
        </Tooltip>)
    },
  ];
  return (
    <Card
      title={<span style={{ color: 'white' }}>Dispatch list</span>}
      style={{ textAlign: 'center' }}  headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/dispatch-list-print'><span style={{color:'white'}} ><Button className='panel_button' >Print </Button> </span></Link>}     
    >
      <br></br>
      <Table columns={columnsSkelton} dataSource={stockInData} bordered />
    </Card>
  );
}

export default dispatchlistgrid;
 