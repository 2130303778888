export class CertificationDto{
    certificationId: number;
    certification: string;
    isActive: boolean;
    updatedAt: Date | any;
    updatedUser: string;
    createdAt: Date | any;
    createdUser: string;
    versionFlag: number;

    constructor(
    certificationId: number,
    certification: string,
    isActive: boolean,
    updatedAt: Date | any,
    updatedUser: string,
    createdAt: Date | any,
    createdUser: string,
    versionFlag: number,
    ){
        this.certificationId = certificationId;
        this.certification = certification;
        this.isActive = isActive;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.versionFlag = versionFlag;
    }
}

export const CertificationDtoDefault : CertificationDto = {
    certificationId: 0,
    certification: "",
    createdUser:"",
    isActive:true,
    createdAt: new Date(),
    updatedAt: new Date(),
    updatedUser: '',
    versionFlag: 1,
};