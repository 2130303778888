import { ItemssubLotDto } from "./item-lot-club.dto";

export class MainLotClubDto {
    mainlotNo: string;
    operation:string;
    saleOrderId: number;
    soItemId: number;
    plantId: number;
    subPlantId: number;
    purpose:string;
    remarks:string;
    subLotInfo:ItemssubLotDto[]
    availableQuantity:number;
    
    constructor(
        mainlotNo: string,
        operation:string,
        saleOrderId: number,
        soItemId: number,
        plantId: number,
        subPlantId: number,
        purpose:string,
        remarks:string,
        subLotInfo:ItemssubLotDto[],
        availableQuantity:number
    )
    {
        this.mainlotNo=mainlotNo;
        this.operation=operation;
        this.saleOrderId=saleOrderId;
        this.soItemId=soItemId;
        this.plantId=plantId;
        this.subPlantId=subPlantId;
        this.purpose=purpose;
        this.remarks=remarks;
        this.subLotInfo=subLotInfo;
        this.availableQuantity=availableQuantity;

    }
   
    }
