export class DeviceRequest{
    on:boolean;
    hue:number;
    ip:string;
    userName:string;
    deviceName:string;
    plantId:number;
    bulbId:number;
    status:string;
    constructor(on:boolean,hue:number,ip:string,userName:string,deviceName:string,plantId:number,bulbId:number,status:string,) {
         this.hue = hue; 
         this.on = on; 
         this.ip = ip; 
         this.userName = userName; 
         this.deviceName = deviceName; 
         this.plantId = plantId; 
         this.bulbId = bulbId; 
         this.status = status;
    }  
}