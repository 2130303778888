import { AttendanceAdjustRequest, AttendanceAdjustResponse, AttendanceLogRequest, AttendanceLogResponse, AttendanceMonthRequest, AttendanceResponse, AttendanceUpdateRequest, AttnAdjustLogReq, DateReq, EmpCodeShiftRequest, EmployeeAttDateRequest, EmployeeAttendanceRequest, EmployeeAttendanceResponse, EmployeeCodesRequest, EmployeeDataModelResponse, EmployeeShiftAttendanceResponse, EmployeeShiftCountResponse, LeaveUpdateRequest, MonthAttendanceResponse, ShiftAttendanceRequest } from "@gtpl/shared-models/hrms";
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";

export class AttendanceService {

    URL = connection.DEPLOY_URL + '/attendance';

    async createAttendanceLogs(attnLogReq: AttendanceLogRequest): Promise<AttendanceLogResponse> {
        return await axios.post(this.URL + '/createAttendanceLogs', attnLogReq)
            .then(res => {
                return res.data;
            })
    }

    async createAttendance(): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/createAttendance')
            .then(res => {
                return res.data;
            })
    }

    async updateEmpLogsByShift(req: EmpCodeShiftRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/updateEmpLogsByShift', req)
            .then(res => {
                return res.data;
            })
    }

    async createEmpAttendanceLogs(req: EmployeeCodesRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/createEmpAttendanceLogs', req)
            .then(res => {
                return res.data;
            })
    }

    async createEmpAttnLogForToday(req: EmployeeCodesRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/createEmpAttnLogForToday', req)
            .then(res => {
                return res.data;
            })
    }

    async attendanceAdjustment(req: AttnAdjustLogReq): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/attendanceAdjustment', req)
            .then(res => {
                return res.data;
            })
    }

    async getAttnAdjustTableData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAttnAdjustTableData')
            .then(res => {
                return res.data;
            })
    }

    async updateAttendance(req: AttendanceUpdateRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/updateAttendance', req)
            .then(res => {
                return res.data;
            })
    }

    async updateLeave(req: LeaveUpdateRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/updateLeave', req)
            .then(res => {
                return res.data;
            })
    }

    async getDataForEmpShiftMapping(): Promise<EmployeeDataModelResponse> {
        return await axios.post(this.URL + '/getDataForEmpShiftMapping')
            .then(res => {
                return res.data;
            })
    }

    async getAttendanceData(empAttnReq: EmployeeAttendanceRequest): Promise<EmployeeAttendanceResponse> {
        return await axios.post(this.URL + '/getAttendanceData', empAttnReq)
            .then(res => {
                return res.data;
            })
    }

    async getAllNonProcessedAttendanceLogs(): Promise<AttendanceLogResponse> {
        return await axios.post(this.URL + '/getAllNonProcessedAttendanceLogs')
            .then(res => {
                return res.data;
            })
    }

    async getAttendanceDataShiftWise(empAttnReq: EmployeeAttendanceRequest): Promise<EmployeeShiftAttendanceResponse> {
        return await axios.post(this.URL + '/getAttendanceDataShiftWise', empAttnReq)
            .then(res => {
                return res.data;
            })
    }

    async getAttendanceCountShiftWise(shiftAttnReq: ShiftAttendanceRequest): Promise<EmployeeShiftCountResponse> {
        return await axios.post(this.URL + '/getAttendanceCountShiftWise', shiftAttnReq)
            .then(res => {
                return res.data;
            })
    }

    async getMonthWiseAttendanceReport(empAttnReq: AttendanceMonthRequest): Promise<MonthAttendanceResponse> {
        return await axios.post(this.URL + '/getMonthWiseAttendanceReport', empAttnReq)
            .then(res => {
                return res.data;
            })
    }

    async createAttendanceLog(attnLogReq: AttendanceLogRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/createAttendanceLog', attnLogReq)
            .then(res => {
                return res.data;
            })
    }

    async getAllEmpDateAtten(Req: EmployeeAttDateRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/getAllEmpDateAtten', Req)
            .then(res => {
                return res.data;
            })
    }

    async getAdjustmentData(Req: AttendanceAdjustRequest): Promise<AttendanceAdjustResponse> {
        return await axios.post(this.URL + '/getAdjustmentData', Req)
            .then(res => {
                return res.data;
            })
    }

    async getPresentEmployeesData(Req: DateReq): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/getPresentEmployeesData', Req)
            .then(res => {
                return res.data;
            })
    }

    async getPresentEmployeeDataByEmpCode(req: AttendanceAdjustRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/getPresentEmployeeDataByEmpCode', req)
            .then(res => {
                return res.data;
            })
    }

    async getLessWorkingEmployees(): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/getLessWorkingEmployees')
            .then(res => {
                return res.data;
            })
    }

    async getCriteriaNotMatchingReport(): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/getCriteriaNotMatchingReport')
            .then(res => {
                return res.data;
            })
    }

    async updateAttendanceByHR(req: AttendanceUpdateRequest): Promise<AttendanceResponse> {
        return await axios.post(this.URL + '/updateAttendanceByHR', req)
            .then(res => {
                return res.data;
            })
    }

    async getShiftDropForAttReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getShiftDropForAttReport')
            .then(res => {
                return res.data;
            })
    }

    async getUnitDropForAttReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnitDropForAttReport')
            .then(res => {
                return res.data;
            })
    }
}