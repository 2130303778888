export class UomsDto{
    uomId?: number;
    uomName:string;
    uomType:string;
    createdUser:string;
    updatedUser:string;

    isActive:boolean;
    /**
     * 
     * @param uomId 
     * @param uomName
     * @param uomType
     * @param createdUser
     * @param updatedUser
     * 
     * @param isActive 
     * 
     */
    constructor(uomId:number,uomName:string,uomType:string,createdUser:string,updatedUser:string,isActive:boolean){
        this.uomId = uomId;
        this.uomName = uomName;
        this.uomType = uomType;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;

        this.isActive = isActive;
    }
}