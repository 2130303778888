import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { CustomerDto  } from "@gtpl/shared-models/masters";

export class CustomersResponseModel extends GlobalResponseObject{
    data?: CustomerDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CustomerDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
}
}
