import axios from 'axios';
import { SoakingDto, SoakingResponseModel, AllSoakingResponseModel, JobCompletedStatusDto, assignSoakingForFreezingResponse, AllFreezingResponseModel, FreezingDto, CookingDto } from '@gtpl/shared-models/production-management';
import connection from './connection';
import { UnitRequest } from '@gtpl/shared-models/common-models';

export class CookingService {
    URL = connection.DEPLOY_URL + '/cooking';


    async assignToCooking(soakingInfo: CookingDto): Promise<assignSoakingForFreezingResponse> {
        console.log(CookingDto);
        return await axios.post(this.URL + '/assignToCooking', soakingInfo)
            .then(res => {
                return res.data
            })
    }
    async jobCompletedStatus(soakingInfo: SoakingDto): Promise<assignSoakingForFreezingResponse> {
        console.log(SoakingDto);
        return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
            .then(res => {
                return res.data
            })
    }

    async getCookingInprogressLogs(plantReq: UnitRequest): Promise<AllSoakingResponseModel> {
        return await axios.post(this.URL + '/getCookingInprogressLogs', plantReq)
            .then(res => {
                return res.data
            });
    }

    async getCookingInventory(plantReq: UnitRequest): Promise<AllFreezingResponseModel> {
        return await axios.post(this.URL + '/getCookingInventory', plantReq)
            .then(res => {
                return res.data
            });
    }

    async getReadyForCookingInventory(plantReq: UnitRequest): Promise<AllFreezingResponseModel> {
        return await axios.post(this.URL + '/getReadyForCookingInventory', plantReq)
            .then(res => {
                return res.data
            })
    }

}

