import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AssetsDropDown } from './assets-dropdown.dto';

export class AssetsDropDownResponseModel extends GlobalResponseObject {
    data?: AssetsDropDown[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: AssetsDropDown[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

