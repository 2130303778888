import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoDropCntQtyModel } from './so-drop-count-qty.model';

export class SoDropCntQtyResponse extends GlobalResponseObject {
    data?: SoDropCntQtyModel;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoDropCntQtyModel){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}