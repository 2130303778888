import axios from 'axios';
import {
  AllSaleOrderTypeResponseModel,
  SaleOrderTypeDto,
  SaleOrderTypeResponseModel,
} from '@gtpl/shared-models/masters';
import connection from './connection';

export class SaleOrderTypeService {
  URL = connection.DEPLOY_URL + '/sale-order-type';

  async createSaleOrderType(
    saleOrderType: SaleOrderTypeDto
  ): Promise<SaleOrderTypeResponseModel> {
    return await axios
      .post(this.URL + '/createSaleOrderType', saleOrderType)
      .then((res) => {
        return res.data;
      });
  }
  async updateSaleOrderType(
    saleOrderType: SaleOrderTypeDto
  ): Promise<SaleOrderTypeResponseModel> {
    console.log('vvvvvvvvvvvvvvvvvvvvvvvvvv')
    console.log(saleOrderType);
    return await axios
      .post(this.URL + '/updateSaleOrderType', saleOrderType)
      .then((res) => {
        return res.data;
      });
  }
  async getAllSaleOrderTypes(): Promise<AllSaleOrderTypeResponseModel> {
    return await axios.post(this.URL + '/getAllSaleOrderType')
        .then(res => {
            return res.data
        })
  }
  async activatedeActivateSaleOrderType(
    saleOrderTypeDto: SaleOrderTypeDto
  ): Promise<SaleOrderTypeResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/updateSaleOrderType', saleOrderTypeDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveSaleOrderTypes(): Promise<AllSaleOrderTypeResponseModel> {
    return await axios
      .post(this.URL + '/getAllActiveSaleOrderType')
      .then((res) => {
        return res.data;
      });
  }
  async getActiveSaleOrderTypesCount(): Promise<AllSaleOrderTypeResponseModel> {
    return await axios
      .post(this.URL + '/getActiveSaleOrderTypeCount')
      .then((res) => {
        return res.data;
      });
  }
}
