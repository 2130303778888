import { CarrierType } from "@gtpl/shared-models/common-models";
import { RMReWeighingService } from "@gtpl/shared-services/raw-material-procurement";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Button, Col, Empty, Form, Input, InputNumber, Row, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReWeighingItemsDto } from "@gtpl/shared-models/raw-material-procurement";
const { Option } = Select;
export interface RMReweighingProps {
    indentData: any
    closeModal : ()=>void
    indentRecord: any
}

export const RMReweighing = (
    props: RMReweighingProps
) => {
    const [form] = Form.useForm();
    const [reWeighingData, setReWeighingData] = useState<any[]>([]);
    const [index, setIndex] = useState();
    const [keyUpdate, setKeyUpdate] = useState(0);
    const [btnName, setBtnName] = useState('Add');
    const service = new RMReWeighingService();
    const data = {
        plantId: Number(localStorage.getItem('unit_id')),
        indentId: props.indentData.indentId,
        indentItemId: props.indentData.indentItemId,
        grnId: (props.indentData?.grnId) ? props.indentData?.grnId : 0,
        grnItemId: (props.indentData?.grnItemId) ? props.indentData?.grnItemId : '',
        createdUser: localStorage.getItem("createdUser"),
        updatedUser: localStorage.getItem("createdUser")
    }
    useEffect(() => {
        getReWeighingData();
    }, []);

    const getReWeighingData = () => {
        service.getRmReWeighingData({ indentItemId: props.indentData?.indentItemId }).then(res => {
            if (res.status) {
                setReWeighingData(res.data);
            } else {
                setReWeighingData([]);
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch(err => {
            setReWeighingData([]);
            AlertMessages.getErrorMessage(err.message);
          });
    }

    const saveData = () => {
        const data = new ReWeighingItemsDto();
        data.rmItemWeighData = reWeighingData;
        service.createRmReWeighing(data).then(res => {
            if (res.status) {
                setReWeighingData([]);
                AlertMessages.getSuccessMessage("Updated Successfully");
                props.closeModal();
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
          });                        
    }

    const onFinish = (values) => {
        if (index || index === 0) {
            reWeighingData.splice(index, 1, { ...values, ...data })
        } else {
            setReWeighingData((prevState) => [...prevState, { ...values, ...data }]);
        }
        setIndex(undefined);
        clearData();
        setKeyUpdate((prevState) => prevState + 1);
        setBtnName('Add');
    }


    const clearData = () => {
        form.resetFields();
    }

    const onFinishFailed = () => {

    }

    const editItem = (row, index) => {
        setBtnName('Update');
        setIndex(index);
        form.setFieldsValue(row);
    }

    const deleteItem = (row, index) => {
        if(row.rmReWeighingId){
            service.deleteReWeighItem({ rmReWeighingId: row.rmReWeighingId }).then(res => {
                if (res.status) {
                    getReWeighingData();
                } else {
                  AlertMessages.getErrorMessage(res.internalMessage);
                }
              }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
              });
        }else{
            reWeighingData.splice(index, 1);
            setReWeighingData(reWeighingData);
            setKeyUpdate((prevState) => prevState + 1); 
        }
    }


    const columns = [
        {
            title: 'Carrier Type',
            dataIndex: 'carrierType',
            key: 'carrierType',
        },
        {
            title: 'Expected Count/Qty',
            dataIndex: 'expectedCntQty',
            key: 'expectedCntQty',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'No. Of. Crates/Nets',
            dataIndex: 'noOfCratesOrNets',
            key: 'noOfCratesOrNets',
        },
        {
            title: 'Quantity(in Kgs)',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (val, row, index) => {
                return <><Space><EditOutlined style={{ color: 'blue' }} onClick={() => editItem(row, index)} /><DeleteOutlined style={{ color: 'red' }} onClick={() => deleteItem(row, index)} /></Space></>
            }
        },

    ];

    return <>
        <Form
            layout="vertical"
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row gutter={24}>
                <Form.Item name={'rmReWeighingId'} style={{ display: "none" }}>
                    <Input hidden />
                </Form.Item>
                <Form.Item name={'index'} style={{ display: "none" }}>
                    <Input hidden />
                </Form.Item>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Form.Item
                        label="Carrier Type"
                        name="carrierType"
                        rules={[{ required: true, message: 'Please input your Carrier Type!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >

                            {Object.entries(CarrierType).map((val) => <Option key={val[1]} value={val[1]}>{val[1]}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                    <Form.Item
                        label="Expected Count/Qty"
                        name="expectedCntQty"
                        rules={[{ required: true, message: 'Please input your Expected Count/Qty!' }]}
                        initialValue={props.indentRecord.expectedCount+"/"+props.indentRecord.expectedQty}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Form.Item
                        label="Count"
                        name="count"
                        rules={[{ required: true, message: 'Please input your Count!' }]}
                    >
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Form.Item
                        label="No. Of. Crates/Nets"
                        name="noOfCratesOrNets"
                        rules={[{ required: true, message: 'Please input your No. Of. Crates/Nets!' }]}
                    >
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Form.Item
                        label="Quantity(in Kgs)"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input your Quantity(in Kgs)!' }]}
                    >
                        <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ paddingTop: '30px' }}>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            {btnName}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        {
            reWeighingData.length ? <><Table key={Date.now()} dataSource={reWeighingData} columns={columns} pagination={false} /><Row justify="end">
                <Col>
                    <Button type="primary" onClick={saveData}>
                        Submit
                    </Button>
                </Col></Row></> : <Empty />
        }

    </>;
}

export default RMReweighing;