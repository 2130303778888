export class GradingMonitoringDto{
    gradingMonitoringId: number;
    typeOfProduct: string;
    headlessCount: number;
    qualityEval: string;
    icing: string;
    tempOfProduct: string;  
    chlorineLevel: number;
    gradesObtaine: number;
    observedCount: number;
    correctiveAction: string;
    signOfSupervisor: string;
    verificationByQC: string;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    gradingInventoryId?:number;

    constructor(
        gradingMonitoringId: number,
        typeOfProduct: string,
        headlessCount: number,
        qualityEval: string,
        icing: string,
        tempOfProduct: string,  
        chlorineLevel: number,
        gradesObtaine: number,
        observedCount: number,
        correctiveAction: string,
        signOfSupervisor: string,
        verificationByQC: string,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number,
        gradingInventoryId?:number
    ){
        this.gradingMonitoringId = gradingMonitoringId;
        this.typeOfProduct = typeOfProduct;
        this.headlessCount = headlessCount;
        this.qualityEval = qualityEval;
        this.icing = icing;
        this.tempOfProduct = tempOfProduct;
        this.chlorineLevel = chlorineLevel;
        this.gradesObtaine = gradesObtaine;
        this.observedCount = observedCount;
        this.correctiveAction = correctiveAction;
        this.signOfSupervisor = signOfSupervisor;
        this.verificationByQC = verificationByQC;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.gradingInventoryId = gradingInventoryId;


    }
}