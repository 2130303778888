import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AllGrnResponseModel, GrnDetailViewResponseModel, GrnDto, GrnIdRequest, GrnQtyRequest, GrnQtyResponseModel, grnResponse, PoDropDownDto, PoDropDownResponseModel, PoItemsResponseModel, PoRequest, GrnReturnAutoFillDto, GrnReturnAutoFillModel, GrnNumberResponseModel, GrnNumberDto, GrnItemReceivedQtyRequest, GrnItemReceivedQtyResponse, GrnItemReturnResponseModel, GrnReturnItemSaveDto, GrnReturnItemsDto, AllGrnReportResponse, GrnRtRequest, GrnItemReturnResponse, GrnAbstractResponse } from "@gtpl/shared-models/procurement-management";
// import { PoDropDownDto, PoDropDownResponseModel } from "@gtpl/shared-models/procurement-management";
import { GrnReturnDto, GrnReturnResponseModel } from "@gtpl/shared-models/procurement-management";
import {grnAbstract} from '@gtpl/shared-models/raw-material-procurement';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class GrnService {
    URL = connection.DEPLOY_URL + '/grn';


    async createGrn(grnDto: GrnDto): Promise<grnResponse> {
        // console.log(selectedCustomer, selectedClient,selectedSaleOrder,selectedStatus, selectedExpectedDate);
        return await axios.post(this.URL + '/createGrn', grnDto)
            .then(res => {
                return res.data
            })
    }




    async getPoDataById(poIdReq: PoRequest): Promise<PoItemsResponseModel> {

        return await axios.post(this.URL + '/getPoDataById', poIdReq)
            .then(res => {
                return res.data
            })
    }

    async getGrnQtyByPoId(poIdReq: GrnQtyRequest): Promise<GrnQtyResponseModel> {

        return await axios.post(this.URL + '/getGrnQtyByPoId', poIdReq)
            .then(res => {
                return res.data
            })
    }

    async getGrnByPoId(poIdReq: GrnQtyRequest): Promise<CommonResponse> {

        return await axios.post(this.URL + '/getGrnByPoId', poIdReq)
            .then(res => {
                return res.data
            })
    }

    async getAllGrnDetails(req?: UnitRequest): Promise<AllGrnResponseModel> {

        return await axios.post(this.URL + '/getAllGrnDetails',req)
            .then(res => {
                return res.data
            })
    }

    async getAllGrnData(req?: UnitRequest): Promise<AllGrnResponseModel> {

        return await axios.post(this.URL + '/getAllGrnData',req)
            .then(res => {
                return res.data
            })
    }
    async getAllGrnReport(req?: GrnRtRequest): Promise<AllGrnReportResponse> {

        return await axios.post(this.URL + '/getAllGrnReport', req)
            .then(res => {
                return res.data
            })
    }
    async getSoDropForGrnReport(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSoDropForGrnReport', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getPoDropForGrnReport(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoDropForGrnReport', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getItemSubDropForGrnReport(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getItemSubDropForGrnReport', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getItemDropForGrnReport(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getItemDropForGrnReport', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getVendorDropForGrnReport(req:UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getVendorDropForGrnReport', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }


    async getAllActiveGrnNumbers(req?:UnitRequest): Promise<GrnNumberResponseModel> {
        return await axios.post(this.URL + '/getAllActiveGrnNumbers',req)
            .then(res => {
                return res.data
            })
    }

    async getGrnReturnAutoFillDataByGrnNumber(grnIdReq: GrnIdRequest): Promise<GrnReturnAutoFillModel> {
        return await axios.post(this.URL + '/getGrnReturnAutoFillDataByGrnNumber', grnIdReq)
          .then(res => {
            return res.data
          })
    }





    async getGrnDataById(grnIdReq: GrnIdRequest): Promise<GrnDetailViewResponseModel> {

        return await axios.post(this.URL + '/getGrnDataById', grnIdReq)
            .then(res => {
                return res.data
            })
    }

    async getGrnItemReceivedQtyByGrnIdandItem(grnItemReq: GrnItemReceivedQtyRequest): Promise<GrnItemReceivedQtyResponse> {
        return await axios.post(this.URL + '/getGrnItemReceivedQtyByGrnIdandItem', grnItemReq)
            .then(res => {
                return res.data
            });
        return new GrnItemReceivedQtyResponse(true, 11, '', 4)
    }

    async getAllGrnReturnItemsForGrid(): Promise<GrnReturnResponseModel> {
        return await axios.post(this.URL + '/getAllGrnReturnItemsForGrid')
            .then(res => {
                return res.data;
            })
    }

    async getGrnReturnItemsById(grnIdReq: GrnIdRequest): Promise<GrnItemReturnResponseModel> {
        return await axios.post(this.URL + '/getGrnReturnItemsById')
            .then(res => {
                return res.data;
            })
    }

    async getGrnDataByIdForReturn(grnIdReq: GrnIdRequest): Promise<GrnItemReturnResponse> {
        return await axios.post(this.URL + '/getGrnDataByIdForReturn', grnIdReq)
            .then(res => {
                return res.data;
            })
    }
    async getGRNAbstract(req :grnAbstract) : Promise<GrnAbstractResponse>{
        return await axios.post(this.URL + '/getGRNAbstract', req)
        .then(res=>{
        return res.data
        })
    }
    async getTransferredSosDataForGrnGrid(req?:UnitRequest) : Promise<GrnAbstractResponse>{
        return await axios.post(this.URL + '/getTransferredSosDataForGrnGrid', req)
        .then(res=>{
        return res.data
        })
    }
    

    async grninfoforpending(req?: UnitRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/grninfoforpending',req)
        .then(res=>{
        return res.data
        })
    }
    

}

