import axios from "axios";
import { from } from "rxjs";
import connection from "./connection";
import {ItemSubCategoriesDto, AllItemSubCategoryResponse, ItemSubCategoryResponse, ItemCategoryRequest, ItemSubCategoriesDropDownResponse, ItemSubCategoryDropDownDto, ItemSubCategoryRequest, SubCatReqForCategoryId, ItemSubCategoryRequestforsonumber} from '@gtpl/shared-models/masters';
import { CommonResponse } from "@gtpl/shared-models/production-management";
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export class ItemSubCategoryService{
    URL = connection.DEPLOY_URL + '/item-sub-categories';

    async create(dto: ItemSubCategoriesDto): Promise<ItemSubCategoryResponse>{
        return await axios.post(this.URL + '/createItemSubCategory', dto)
        .then(res => {
            return res.data
        })
    }   
    
    async update(dto: ItemSubCategoriesDto): Promise<ItemSubCategoryResponse>{
        return await axios.post(this.URL + '/updateItemSubCategory', dto)
        .then(res => {
            return res.data
        })
    } 

    async activeOrDeactivate(dto: ItemSubCategoriesDto): Promise<ItemSubCategoryResponse>{
        return await axios.post(this.URL + '/activeOrDeactivateCategory', dto)
        .then(res => {
            return res.data
        })
    } 

    async getAll(req?:UserRequestDto): Promise<AllItemSubCategoryResponse> {
        return await axios.post(this.URL + '/getAllItemSubCategories',req)
            .then(res => {
                return res.data 
        })     
    }

    async getItemSubCategoriesForCategoryDropDown(req:ItemCategoryRequest): Promise<ItemSubCategoriesDropDownResponse> {
        return await axios.post(this.URL + '/getItemSubCategoriesForCategoryDropDown',req)
            .then(res => {
                return res.data 
        })     
    }

    async getItemSubCategoryForId(req:ItemSubCategoryRequest): Promise<ItemSubCategoryDropDownDto> {
        return await axios.post(this.URL + '/getItemSubCategoryForId',req)
            .then(res => {
                return res.data 
        })     
    }

    async getSubCategoryByCategoryId(itemCatId :SubCatReqForCategoryId): Promise<AllItemSubCategoryResponse> {
        
        return await axios.post(this.URL + '/getSubCategoryByCategoryId', itemCatId)
            .then(res => {
                return res.data 
        })     
    }

    async activateOrDeactivateItemSubCategory(Dto: ItemSubCategoriesDto): Promise<ItemSubCategoryResponse> {
        
        return await axios.post(this.URL + '/activateOrDeactivateItemSubCategory', Dto)
            .then(res => {
                return res.data 
        })     
    }
    async getitemsubcategoryforsalorderId(req: ItemSubCategoryRequestforsonumber): Promise<ItemSubCategoryResponse> {
        
        return await axios.post(this.URL + '/getitemsubcategoryforsalorderId', req)
            .then(res => {
                return res.data 
        })     
    }

    async getGeneralItemSubCategories(): Promise<CommonResponse> {
        
        return await axios.post(this.URL + '/getGeneralItemSubCategories')
            .then(res => {
                return res.data 
        })     
    }
    
}