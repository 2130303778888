import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './pages-production-management-production-management-components-grade-monitoring-form.css';
import { AutoComplete, Button, Card, Cascader, Col, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch, Table, Tag, TimePicker, TreeSelect } from 'antd';
import { GradingMonitoringInput } from '@gtpl/shared-models/common-models';
import { GradeInventoryIdReq, GradeLotNoModel, GradingModel, GradingMonitoringDto } from '@gtpl/shared-models/production-management';
import {GradingMonitoringService} from'@gtpl/shared-services/production'
/* eslint-disable-next-line */
export interface GradeMonitoringFormProps {
  handleCancel: () => void;
  isUpdate : boolean;
  gradingInfo : GradingModel;
  gradingLotNo : GradeLotNoModel;
}
export function GradeMonitoringForm(
  props: GradeMonitoringFormProps
) {
  const [gradingSample] = Form.useForm();
  const [gradingInfo] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [gradMonSamples, setGradMonSamples] = useState<GradingMonitoringDto[]>();
 

  const service = new GradingMonitoringService;
  const columns = [
   
    {
      title: 'Quality Evalution',
      dataIndex: 'qualityEval',
      key: 'qualityEval',
    },
    {
      title: 'Icing',
      dataIndex: 'icing',
      key: 'icing',
    },
    {
      title: 'Temp of product',
      dataIndex: 'tempOfProduct',
      key: 'tempOfProduct',
    },
    {
      title: 'Chlorine Level',
      dataIndex: 'chlorineLevel',
      key: 'chlorineLevel',
    },
    {
      title: 'Grades Obtained',
      dataIndex: 'gradesObtaine',
      key: 'gradesObtaine',
    },
    {
      title: 'Observed Count',
      dataIndex: 'observedCount',
      key: 'observedCount',
    },
    // {
    //   title: 'Corrective Action',
    //   dataIndex: 'correctiveAction',
    //   key: 'correctiveAction',
    // },
    // {
    //   title: 'Sign of Supervisor',
    //   dataIndex: 'signOfSupervisor',
    //   key: 'signOfSupervisor',
    // },
    // {
    //   title: 'Verification by QC',
    //   dataIndex: 'verificationByQC',
    //   key: 'verificationByQC',
    // },
    
  ];

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
    
  useEffect(() => {
    getSamplesInfoByGrdInvId();
    gradingSample.setFieldsValue({
      headlessCount : props.gradingInfo.grade,
      gradingInventoryId :props.gradingInfo.gradingInventoryId

    })
    getSamplesInfoByGrdInvId()
   
  }, [])

  const getSamplesInfoByGrdInvId = () => {
    const req = new GradeInventoryIdReq(props.gradingInfo.gradingInventoryId);
    service.getSamplesInfoByGrdInvId(req).then(res => {
      if (res.status) {
        setGradMonSamples(res.data);
        console.log("data",res.data);
       
      } else {
        setGradMonSamples(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
 


  const createGradingMonitoring=(data: GradingMonitoringDto)=>{
   service.createGradingMonitoring(data).then(res => {
      if (res.status) {
        getSamplesInfoByGrdInvId();
        AlertMessages.getSuccessMessage(' Created Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  return (
    <><Card size="small" title={<span style={{ color: 'white' }}>Grading Inventory</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form
        layout="vertical"
        form={gradingInfo}
        
      >
        
        <Row gutter={24}>
          <Descriptions>
          <Descriptions.Item label="SO Number">
              {props.gradingLotNo.soNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Head Less count">
              {props.gradingInfo.grade}
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {props.gradingInfo.receivedQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Product">
              Vanname
            </Descriptions.Item>
           
            

            
          </Descriptions>
        </Row>
      </Form>
    </Card>

      <Card size="small" title={<span style={{ color: 'white' }}>Grading Sample</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Form
          layout="vertical"
          form={gradingSample}
          onFinish={createGradingMonitoring}
        >
         
          
          <Row gutter={16}>
          <Form.Item name="gradingMonitoringId" style={{ display: "none" }} >
          <Input hidden />
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="gradingInventoryId"  initialValue={props.gradingInfo.gradingInventoryId}>
            <Input hidden/>
          </Form.Item>
          <Form.Item name="headlessCount" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          

            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"typeOfProduct"}
                label="Type of Product"
                rules={[
                  {
                    required: true,
                    message: `Missing Product`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"qualityEval"}
                label="Quality Evalution"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
                initialValue={GradingMonitoringInput[0].value}
                >
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={GradingMonitoringInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"icing"}
                label="Icing "
                rules={[
                  {
                    required: true,
                    message: `Missing Icing `,
                  },
                ]}
                initialValue={GradingMonitoringInput[1].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={GradingMonitoringInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"tempOfProduct"}
                label="Temp of Product(c)(<4)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temp`,
                  },
                ]}
              >
                <InputNumber min={0}  max={4} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"chlorineLevel"}
                label="Chlorine Level"
                rules={[
                  {
                    required: true,
                    message: `Missing Chlorine Level`,
                  },
                ]}
               
              >
                <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"gradesObtaine"}
                label="Grades Obtained"
                rules={[
                  {
                    required: true,
                    message: `Missing Soft Tail`,
                  },
                ]}
               
              >
                <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"observedCount"}
                label="Observed Count"
                rules={[
                  {
                    required: true,
                    message: `Missing Observed Count`,
                  },
                ]}
               
              >
                 <InputNumber min={0} style={{ width: "100%" }} />
                 </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"correctiveAction"}
                label="Corrective Action"
                rules={[
                  {
                    required: true,
                    message: `Missing Corrective Action`,
                  },
                ]}
                initialValue={GradingMonitoringInput[2].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={GradingMonitoringInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"signOfSupervisor"}
                label="Sign of Supervisor"
                rules={[
                  {
                    required: true,
                    message: `Missing Sign of Supervisor`,
                  },
                ]}
               
              >
                <Input style={{ width: "100%" }} />
               </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"verificationByQC"}
                label="Verification By QC"
                rules={[
                  {
                    required: true,
                    message: `Missing Verification By QC `,
                  },
                ]}
               
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}
            
          </Row>
          <Row justify="space-between">
            <Col>
              <h3 style={{ color: 'blue' }} >*Note: &ensp; SF - Satisfactory, G-Good</h3>
            </Col>
            <Col>
              <Button type='primary'  htmlType="submit" disabled={btnDisable}>
                Submit
              </Button>
              <Button onClick={props.handleCancel} disabled={btnDisable}>
                Back
              </Button>
            </Col>
          </Row>
          <br/><br/>
          <Row justify='center'>
            <Table 
            bordered={true}
            // title={() => 'Samples Info'}
            size='large'
            dataSource={gradMonSamples} 
            columns={columns} scroll={{ x : true }} 
            />
          </Row>
        </Form>
      </Card >
    </>
  );
}

export default GradeMonitoringForm;
