export class  SaleOrderDetailViewInfoDTO{
    saleOrderId:number;
    saleOrder:string;
    invDate:Date;
    customer:string;
    customerId:number;
    poNo:string;
    brand:string;
    endCustomer:string;
    endCustomerId:number;
    address:string;
    destinationAddress:string;
    deliveryTerms: string;
    paymentTerms: string;      
    paymentTermsId: number;      
    totWeight:number;
    totAmount:number;
    styleWeightment : string;
    certfication:string;
    soakingTime:string;
    soakingStyle:string;
    isBrandApproved:string;
    noOfPouches:string;
    noOfCartone:string;
    additionalNote:string;
    sgsInspection:string; 
    tuvInspection:string;
    rating:number;
    isCertified:string;
    brandDesignType:string;
    eta:Date;
    etd:Date;
    actualEtd:Date;
    portOfEntry:string;
    portOfEntryId:number;
    country:string;
    shippingTerms:string;
    loadInspection:string;
    category:string;
    lotCode:string;
    currencyId:number;
    currency:string;
    unitId:number;
    unit:string;
    uom:string;
    filePath:string;
    totPouches:number;
    totCases:number;
    salePerson:string;
    isInvoiced:string;
    finalDestination:string;
    finalDestinationId:number;
    buyerPoNo:string;
    itemData : SaleOrderItemsDTO[];
    soStatus:string;
    countryId?:number;
    processingUnitId?:number;
    processingUnit?:string;

     /**
      * 
      * @param saleOrderId 
      * @param saleOrder 
      * @param invDate 
      * @param customer 
      * @param customerId 
      * @param poNo 
      * @param endCustomer 
      * @param endCustomerId 
      * @param brand 
      * @param address 
      * @param destinationAddress 
      * @param deliveryTerms 
      * @param paymentTerms 
      * @param paymentTermsId 
      * @param totWeight 
      * @param totAmount 
      * @param styleWeightment 
      * @param certfication 
      * @param soakingTime 
      * @param soakingStyle 
      * @param isBrandApproved 
      * @param noOfPouches 
      * @param noOfCartone 
      * @param additionalNote 
      * @param sgsInspection 
      * @param tuvInspection 
      * @param rating 
      * @param isCertified 
      * @param brandDesignType 
      * @param eta 
      * @param etd 
      * @param actualEtd 
      * @param portOfEntry 
      * @param portOfEntryId 
      * @param country 
      * @param shippingTerms 
      * @param loadInspection 
      * @param category 
      * @param lotCode 
      * @param currencyId 
      * @param currency 
      * @param unitId 
      * @param unit 
      * @param uom 
      * @param filePath 
      * @param totPouches 
      * @param totCases 
      * @param salePerson 
      * @param isInvoiced 
      * @param finalDestination 
      * @param finalDestinationId 
      * @param buyerPoNo 
      * @param itemData 
      * @param soStatus 
      * @param countryId 
      */
      constructor(saleOrderId:number,
        saleOrder:string,
        invDate:Date,
        customer:string,
        customerId:number,
        poNo:string,
        endCustomer:string,
        endCustomerId:number,
        brand:string,
        address:string,
        destinationAddress:string,
        deliveryTerms: string,
        paymentTerms: string,
        paymentTermsId: number,
        totWeight:number,
        totAmount:number,
        styleWeightment : string,
        certfication:string,
        soakingTime:string,
        soakingStyle:string,
        isBrandApproved:string,
        noOfPouches:string,
        noOfCartone:string,
        additionalNote:string,
        sgsInspection:string,
        tuvInspection:string,
        rating:number,
        isCertified:string,
        brandDesignType:string,
        eta:Date,
        etd:Date,
        actualEtd:Date,
        portOfEntry,
        portOfEntryId,
        country,
        shippingTerms,
        loadInspection,
        category:string,
        lotCode:string,
        currencyId:number,
        currency:string,
        unitId:number,
        unit:string,
        uom:string,
        filePath:string,
        totPouches:number,
        totCases:number,
        salePerson:string,
        isInvoiced:string,
        finalDestination:string,
        finalDestinationId:number,
        buyerPoNo:string,
        itemData : SaleOrderItemsDTO[],soStatus:string,countryId?:number,processingUnitId?:number,processingUnit?:string){
            this.saleOrderId=saleOrderId
            this.saleOrder=saleOrder
            this.invDate=invDate
            this.customer=customer
            this.customerId=customerId
            this.poNo=poNo
            this.endCustomer=endCustomer
            this.endCustomerId=endCustomerId
            this.brand=brand
            this.address=address
            this.destinationAddress=destinationAddress
            this.deliveryTerms= deliveryTerms
            this.paymentTerms= paymentTerms
            this.paymentTermsId= paymentTermsId
            this.totWeight=totWeight
            this.totAmount=totAmount
            this.styleWeightment= styleWeightment
            this.certfication=certfication
            this.soakingTime=soakingTime
            this.soakingStyle=soakingStyle
            this.isBrandApproved=isBrandApproved
            this.noOfPouches=noOfPouches
            this.noOfCartone=noOfCartone
            this.additionalNote=additionalNote
            this.sgsInspection=sgsInspection
            this.tuvInspection=tuvInspection
            this.rating=rating
            this.isCertified=isCertified
            this.brandDesignType=brandDesignType
            this.eta = eta
            this.etd = etd
            this.actualEtd = actualEtd
            this.portOfEntry = portOfEntry
            this.portOfEntryId = portOfEntryId
            this.country = country
            this.countryId = countryId
            this.shippingTerms = shippingTerms
            this.loadInspection = loadInspection
            this.lotCode = lotCode
            this.category = category
            this.currencyId = currencyId
            this.currency = currency
            this.unitId = unitId
            this.unit = unit
            this.uom = uom
            this.filePath = filePath
            this.totPouches = totPouches
            this.totCases = totCases
            this.salePerson = salePerson
            this.isInvoiced = isInvoiced
            this.finalDestination = finalDestination
            this.finalDestinationId = finalDestinationId
            this.buyerPoNo = buyerPoNo
            this.itemData = itemData
            this.soStatus = soStatus
            this.processingUnit = processingUnit
            this.processingUnitId = processingUnitId
      }
}
export class SaleOrderItemsDTO{
    saleOrderItemId:number;
    brandId:number;
    brand:string;
    minGrade:number;
    maxGrade:number;
    varientId:number;
    varient:string;
    unitPrice:number;
    packingStyle:string;
    cases:number;
    caseWeight:number;
    netWeight:number;
    netAmount:number;
    uom:string;
    styleWeighment:string;
    minGlaze:number;
    maxGlaze:number;
    soakingTime:string;
    soakingStyle:string;
    shrimpWeight:number;
    innerBags:number;
    innerBagSizeId:string;
    productId:number;
    product:string;
    pouches:number;
    pouchWeight:number;
    dispatchQty:number;
    invoiceAmnt:number;
    reportedCases:number;
    categoryId:number;
    reportingCases?:number;
    finalPercentage?:number;
    uomId?:number;
    grossWeight?:number; 
    totalGrossWeight?:number; 
    createdAt?:Date;
    taxId?:number;
    taxName?:string;
    taxPer?:number;
    foodTypeId?:number;
    valueAdditionOneId?:number;
    valueAdditionTwoId?:number;
    valueAdditionThreeId?:number;
    valueAdditionFourId?:number;
    valueAdditionFiveId?:number;
    masterBrandId?:number;
    invoicedCases?:number;
    category?:string;
    jobCases?:number;
    packStyleId?:number;
    maxPalletCapacity?:number;
    requiredCount?:number;
    mainSoItemId?:number;
    operationType?:string;
    endCustomerId?: number;
    palletCapacity?:number;
    invoicedQty?:number;
    wipQuantity?:number;
    revisedUnitPrice?:number;
    constructor(saleOrderItemId:number,minGrade:number,maxGrade:number,
    brandId:number,
    brand:string,
    varientId:number,
    varient:string,
    packingStyle:string,
    cases:number,
    caseWeight:number,
    unitPrice:number,
    netWeight:number,
    netAmount:number,
    uom:string,
    styleWeighment:string,
    minGlaze:number,
    maxGlaze:number,
    soakingTime:string,
    soakingStyle:string,
    shrimpWeight:number,
    innerBags:number,
    innerBagSizeId:string,productId:number,product:string,pouches:number,
    pouchWeight:number,
    dispatchQty:number,
    invoiceAmnt:number,
    reportedCases:number,
    categoryId:number,
    finalPercentage?:number,
    uomId?:number,
    grossWeight?:number,
    totalGrossWeight?:number,
    createdAt?:Date,
    taxId?:number,
    taxName?:string,
    taxPer?:number,
    foodTypeId?:number,
    valueAdditionOneId?:number,
    valueAdditionTwoId?:number,
    valueAdditionThreeId?:number,
    valueAdditionFourId?:number,
    valueAdditionFiveId?:number,
    masterBrandId?:number,
    invoicedCases?:number,
    category?:string,
    jobCases?:number,
    packStyleId?:number,
    maxPalletCapacity?:number,
    requiredCount?:number,
    mainSoItemId?:number,
    operationType?:string,
    endCustomerId?: number,
    palletCapacity?:number,
    invoicedQty?:number,
    wipQuantity?:number,
    revisedUnitPrice?:number
    ){
        this.saleOrderItemId=saleOrderItemId;
        this.minGrade=minGrade;
        this.maxGrade=maxGrade;
        this.brandId=brandId;
        this.brand=brand;
        this.varientId=varientId;
        this.varient=varient;
        this.packingStyle=packingStyle;
        this.cases=cases;
        this.caseWeight=caseWeight;
        this.netWeight=netWeight;
        this.unitPrice=unitPrice;
        this.netAmount=netAmount;
        this.uom=uom;
        this.styleWeighment=styleWeighment;
        this.minGlaze=minGlaze;
        this.maxGlaze=maxGlaze;
        this.soakingTime=soakingTime;
        this.soakingStyle=soakingStyle;
        this.shrimpWeight=shrimpWeight;
        this.innerBags=innerBags;
        this.innerBagSizeId=innerBagSizeId;
        this.productId=productId;
        this.product=product;
        this.pouches=pouches;
        this.pouchWeight=pouchWeight;
        this.dispatchQty=dispatchQty;
        this.invoiceAmnt=invoiceAmnt;
        this.reportedCases=reportedCases;
        this.categoryId=categoryId;
        this.finalPercentage=finalPercentage;
        this.uomId = uomId
        this.grossWeight = grossWeight
        this.totalGrossWeight = totalGrossWeight
        this.createdAt = createdAt
        this.taxId = taxId
        this.taxName = taxName
        this.taxPer = taxPer
        this.foodTypeId= foodTypeId,
        this.valueAdditionOneId= valueAdditionOneId,
        this.valueAdditionTwoId= valueAdditionTwoId,
        this.valueAdditionThreeId= valueAdditionThreeId,
        this.valueAdditionFourId= valueAdditionFourId,
        this.valueAdditionFiveId= valueAdditionFiveId,
        this.masterBrandId= masterBrandId,
        this.invoicedCases= invoicedCases,
        this.category= category,
        this.jobCases= jobCases,
        this.packStyleId = packStyleId,
        this.maxPalletCapacity = maxPalletCapacity,
        this.requiredCount = requiredCount,
        this.mainSoItemId = mainSoItemId,
        this.operationType= operationType,
        this.endCustomerId = endCustomerId,
        this.palletCapacity = palletCapacity,
        this.invoicedQty = invoicedQty
        this.wipQuantity=wipQuantity;
        this.revisedUnitPrice = revisedUnitPrice;
    }
}