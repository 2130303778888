import React, { useEffect, useState } from 'react';

import './bom-parameter-form.css';
import { BomItemRemovedQty, BomPackingItemsDto, CreateBomDTO, PackTypeCategory, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderInfo, SaleOrderItemsDTO, SaleOrderItemsDto, UpdateBomItemDto } from '@gtpl/shared-models/sale-management';
import { Button, Card, Col, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Popconfirm, Row, Select, Space, Switch, Table, Tag, Modal, Tooltip, Alert } from 'antd';
import Column from 'antd/lib/table/Column';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemRequest, ItemsDropDownDto, ItemsReqForCategoryId, SizesDropDownDto } from '@gtpl/shared-models/masters';
import { AssignStockInputRequest, StockInputRequest } from '@gtpl/shared-models/procurement-management';
import { ItemsService } from '@gtpl/shared-services/masters';
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, EditOutlined, RightSquareOutlined, ExclamationCircleOutlined, RollbackOutlined, CheckOutlined, UndoOutlined, WarningTwoTone } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Redirect, Route } from 'react-router-dom';
import { EmployeeRolesEnum, GlobalStatus, StatusEnum } from '@gtpl/shared-models/common-models';
import { StockService } from '@gtpl/shared-services/procurement'
/* eslint-disable-next-line */
const { Option } = Select;
export interface BomParameterFormProps {
  saleOrderId: any;
  unitId: any;
  saleOrderData: SaleOrderDropDownDto[];
  itemCategoriesData: ItemCategoriesDto[];
  sizesData: SizesDropDownDto[];
  saveBomDetails?: (bomData: CreateBomDTO) => void;
}
export function BomParameterForm(
  props: BomParameterFormProps

) {
  useEffect(() => {
    if (props?.saleOrderId) {
      getAllBomDetailsForSoId(props.saleOrderId);
      getSaleOrderItems(props.saleOrderId);
      bomForm.setFieldsValue({
        saleOrderId: props.saleOrderId
      });
    }
  }, [props.saleOrderId]);

  
  const [bomForm] = Form.useForm();
  const [bomItemsForm] = Form.useForm();
  const [removeConfirmForm] = Form.useForm();

  const [saleOrderItemsData, setSaleOrderItemsData] = useState([]);
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDetailViewInfoDTO>();
  const [displayItemsData, setDisplayItemsData] = useState<boolean>(false);
  const [editData, setEditData] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [itemsData, setItemsData] = useState<ItemsDropDownDto[]>([])
  const [locationWiseStock, setLocationWiseStock] = useState<any[]>([])
  const [rowRecord, setRowRecord] = useState<any>([])
  const [stockAction, setStockAction] = useState<string>('')
  const [bomPackingMaterialData, setBomPackingMaterialData] = useState<BomPackingItemsDto[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [updateStateData, setUpdateStateData] = useState<number>(1);
  const [index, setIndex] = useState<number>();
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [itemInitialQty, setItemInitialQty] = useState(0);
  const [packCategory, setPackCategory] = useState(undefined);
  const [itemCategoryDetails, setItemCategoryDetails] = useState<ItemCategoryDropDownDto>();
  const [itemDetails, setItemDetails] = useState<ItemsDropDownDto>();
  const [size, setsize] = useState<SizesDropDownDto>();
  const { confirm } = Modal;
  const saleOrderService = new SaleOrderService();
  const itemService = new ItemsService();
  const stockService = new StockService();
  const role = JSON.parse(localStorage.getItem('role'));

  useEffect(()=> {
    console.log(locationWiseStock)
  },[locationWiseStock])


  // get getBomDetails against sale order 
  const getAllBomDetailsForSoId = (saleOrderId) => {
    const saleOrderReq = new saleOrder(saleOrderId, props.unitId);
    saleOrderService.getAllBomDetailsForSoId(saleOrderReq).then((res) => {
      if (res.status) {
        setBomPackingMaterialData(res.data);
        setUpdateStateData(updateStateData + 1);
      }
    });
  }

  // get sale order items against sale order 
  const getSaleOrderItems = (saleOrderId) => {
    setBomPackingMaterialData([]);
    const saleOrderReq = new saleOrder(saleOrderId, props.unitId);
    saleOrderService.getAllBomDetailsForSoId(saleOrderReq).then((res) => {
      if (res.status) {
        console.log(res.data)
        setBomPackingMaterialData(res.data);
        setUpdateStateData(updateStateData + 1);
      }
    });
    saleOrderService.getSaleOrderInformationById(saleOrderReq).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
        setUpdateStateData(updateStateData + 1);
      }
    });
  }

  useEffect(() => {
    bomPackingMaterialData.map((bomItem) => {
      if (bomItem.avilableQuantity > 0 && bomItem.AssignedQuantity == 0) {
        bomItem.assign = 'Assign'
      } else if (bomItem.AssignedQuantity > 0) {
        bomItem.assign = 'Assigned';
      } else {
        bomItem.assign = '';
      }
    })
    setBomPackingMaterialData(bomPackingMaterialData);
    setUpdateStateData(updateStateData + 1);
  }, [bomPackingMaterialData])

  // get items against item category
  const getItems = (itemCategoryId, itemCategoryName) => {
    if (itemCategoryName) {
      const itemCategory = new ItemCategoryDropDownDto(itemCategoryId, itemCategoryName.children);
      setItemCategoryDetails(itemCategory);
    }
    const itemsRequest = new ItemsReqForCategoryId(itemCategoryId);
    itemService.getItemByCategoryId(itemsRequest).then((res) => {
      if (res.status) {
        setItemsData(res.data);
      } else {
        setItemsData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const setItem = (itemId, itemName) => {
    const item = new ItemsDropDownDto(itemId, itemName.children);
    setItemDetails(item);
  }

  const setSize = (sizeId, sizeName) => {
    const size = new SizesDropDownDto(sizeId, sizeName.children);
    setsize(size);
  }


  const saveBom = async () => {
    await bomForm.validateFields().then(bomRes => {
      if (bomPackingMaterialData.length > 0) {
        const bomDetails: BomPackingItemsDto[] = [];
        const bomFormData = bomForm.getFieldsValue();
        for (const items of bomPackingMaterialData) {
          const bomData: BomPackingItemsDto = {
            itemCategoryDetails: items.itemCategoryDetails,
            itemSubCategoryDetails: null,
            itemDetails: items.itemDetails,
            size: items.size,
            quantity: items.quantity,
            status: items.status,
            createdUser: 'createdUser',
            saleOrderId: bomFormData.saleOrderId,
            updatedUser: 'createdUser',
            packingMaterialId: (items.packingMaterialId) ? items.packingMaterialId : null,
            packingCategory: items.packingCategory,
            isRequired: items.isRequired,
            isActiveRevised: items.isActiveRevised,
            isQtyRevised: items.isQtyRevised

          }
          bomDetails.push(bomData);
        }
        const createBom = new CreateBomDTO(bomDetails, deletedRecords);
        props.saveBomDetails(createBom);
        bomForm.resetFields();
        bomItemsForm.resetFields();
        setBomPackingMaterialData([]);
        setDeletedRecords([]);
        setSaleOrderData(undefined);
      } else {
        AlertMessages.getErrorMessage('Please Fill Details');
      }
    });
  }

  const modalColumns = [
    {
      title: 'Item',
      dataIndex: 'itemName',
      key: 'packingCategory',
      render: (value, record: any, index) => {
        return (record.itemName) ? record.itemName : '';
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'packingCategory',
      render: (value, record: any, index) => {
        return (record.location) ? record.location : '';
      },
    },
    {
      title: 'Qunatity',
      dataIndex: 'quantity',
      key: 'packingCategory',
      render: (value, record: any, index) => {
        console.log(record)
        return <>
        <InputNumber
            min={0}
            // max={(maxQty > 0) ? maxQty : 0}
            defaultValue={(record.quantity > 0) ? Number(record.quantity) : 0}
            // readOnly={!record.isActive}
            onChange={(value) => changeAssignQty(record, index, value)}
          />
        </>
        // return (record.quantity > 0) ? Number(record.quantity) : '';
      },
    },

  ]

  const updateRemovedQty = async (record: BomPackingItemsDto) => {
    console.log(locationWiseStock)
    let totRemovedQty = 0
    locationWiseStock.map(item => {
      totRemovedQty = Number(totRemovedQty)+Number(item?.ToBeAssignedQuantity? item.ToBeAssignedQuantity :item.quantity)
    })
    await removeConfirmForm.validateFields().then(bomRes => {
      const updateBom: BomItemRemovedQty = {
        packingMaterialId: record.packingMaterialId,
        itemId: record.itemDetails.itemId,
        saleOrderId: props.saleOrderId,
        removedQuantity: totRemovedQty,
        unitId : props.unitId,
        createdUser :  localStorage.getItem('createdUser'),
        locationWiseStock:locationWiseStock

      }
      console.log(updateBom)
      saleOrderService.UpdateBomRemovedQty(updateBom).then((res) => {
        if (res) {
          AlertMessages.getSuccessMessage('Successfully assigned qty removed for sale order');
          getSaleOrderItems(props.saleOrderId);
          bomItemsForm.resetFields();
          removeConfirmForm.resetFields();
        } else {
          AlertMessages.getErrorMessage('Failed To Update Bom Quantity');
        }
      });
    })
  }

  const showDeleteConfirm = (record: BomPackingItemsDto) => {
    confirm({
      title: 'Are you sure do you want to remove assigned quantity?',
      icon: <ExclamationCircleOutlined />,
      content: <><Form form={removeConfirmForm}><Form.Item name='removedQty' initialValue={record.AssignedQuantity}><InputNumber
        min={1}
        max={record.AssignedQuantity}
        defaultValue={record.AssignedQuantity} /></Form.Item><p style={{ color: 'red' }}>Available Qty: {record.avilableQuantity}</p></Form></>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateRemovedQty(record);
      },
      onCancel() {
        AlertMessages.getErrorMessage('Cancelled removing assigning Qty');
      },
    });
  }

  const reAssignStock = (record) => {

  }

  const locationWiseStockModal = (record,val,action) => {
    console.log(record)
    setRowRecord(record)
    setStockAction(action)
    if(action == 'Assign'){
      const itemReq = new ItemRequest(record.itemDetails.itemId,props.unitId);
      stockService.getLocationWiseStockByItem(itemReq).then((res) => {
        if (res.status) {
          console.log(res.data)
          if(res.status){
            setLocationWiseStock(res.data)
          }
        }
      });
    }
    else{
      const itemReq = new ItemRequest(record.itemDetails.itemId,props.unitId,record.saleOrderId);
      stockService.getLocationWiseStockBySaleOrder(itemReq).then((res) => {
        if (res.status) {
          console.log(res.data)
          if(res.status){
            setLocationWiseStock(res.data)
          }
        }
      });
    }
    setIsModalVisible(true)
  } 

  const getColumns = () => {
    const columns = [
      {
        title: 'Packing Category',
        dataIndex: 'packingCategory',
        key: 'packingCategory',
        render: (value, record: BomPackingItemsDto, index) => {
          return (record.packingCategory) ? record.packingCategory : '';
        },
      },
      {
        title: 'Item',
        dataIndex: 'item.itemId',
        key: 'item.itemId',
        render: (value, record: BomPackingItemsDto, index) => {
          return (record.itemDetails) ? record.itemDetails.itemName : '';
        },
      },
      {
        title: 'Required',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (value, record: BomPackingItemsDto, index) => {
          console.log(record)
          return <>{Number(value)}</>

        //   return <>{(!record.isActive) ? <Tooltip placement="topLeft" title={"De Activated Item Can't be changed"}></Tooltip> : ''}<Form.Item name={record.packingMaterialId + " quantity"}><InputNumber
        //     min={(record.poQty) ? Number(record.poQty) : 0}
        //     defaultValue={(value > 0) ? value : 0}
        //     readOnly={!record.isActive}
        //     onChange={(value) => changeRequiredQty(record, index, value)} /></Form.Item></>;
        },
      },
      {
        title: 'PO',
        dataIndex: 'poQty',
        key: 'poQty',
        render: (value, record: BomPackingItemsDto, index) => {
          return <div style={{ textAlign: 'end' }}>{(record.poQty) ? Number(record.poQty) : 0}</div>;
        },
      },
      {
        title: 'Received',
        dataIndex: 'receivedQuantity',
        key: 'receivedQuantity',
        render: (value, record: BomPackingItemsDto, index) => {
          return <div style={{ textAlign: 'end' }}>{(record.receivedQuantity) ? Number(record.receivedQuantity) : 0}</div>;
        },
      },
      {
        title: 'Assigned',
        dataIndex: 'AssignedQuantity',
        key: 'AssignedQuantity',
        render: (value, record: BomPackingItemsDto, index) => {
          return <div style={{ textAlign: 'end' }}>{(record.AssignedQuantity) ? Number(record.AssignedQuantity) : 0}{record.AssignedQuantity > 0 ? <Tooltip placement="topLeft" title={'Remove/Change Assigned QTY'}><RollbackOutlined
            onClick={() => locationWiseStockModal(record,index,'ReAssign')}
            style={{ color: 'red', fontSize: '14px' }}
          /></Tooltip> : ''} </div>;
        },
      },
      {
        title: 'To Be Procured',
        dataIndex: 'requiredQuantity',
        key: 'requiredQuantity',
        render: (value, record: BomPackingItemsDto, index) => {
          return <div style={{ textAlign: 'end' }}>{(record.requiredQuantity) ? Number(record.requiredQuantity) : 0}</div>;
        },
      },
      {
        title: 'Available',
        dataIndex: 'avilableQuantity',
        key: 'avilableQuantity',
        render: (value, record: BomPackingItemsDto, index) => {
          return <div style={{ textAlign: 'end' }}>{(record.avilableQuantity) ? Number(record.avilableQuantity) : 0}</div>;
        },
      },
      // {
      //   title: 'Assign',
      //   dataIndex: 'ToBeAssignedQuantity',
      //   key: 'ToBeAssignedQuantity',
      //   render: (value, record: BomPackingItemsDto, index) => {
      //     console.log(record);
      //     let Qty: number = 0;
      //     let maxQty: number;
      //     if (Number(record.avilableQuantity) <= Number(record.quantity)) {
      //       Qty = Number(record.avilableQuantity)
      //       maxQty = Number(record.avilableQuantity)

      //     } else if (Number(record.avilableQuantity) > Number(record.quantity)) {
      //       Qty = Number(record.quantity) - (Number(record.AssignedQuantity) + Number(record.receivedQuantity));
      //       maxQty = Number(record.quantity) - (Number(record.AssignedQuantity) + Number(record.receivedQuantity));
      //       if (role == EmployeeRolesEnum.ADMIN) {
      //         let add5Percent: number = (record.quantity * (5 / 100))
      //         if (record.avilableQuantity >= add5Percent) {
      //           maxQty =  maxQty + add5Percent
      //         }
      //       }
      //     }
      //     // record.ToBeAssignedQuantity = (record.ToBeAssignedQuantity) ? record.ToBeAssignedQuantity : record.requiredQuantity;
      //     record.ToBeAssignedQuantity = (record.ToBeAssignedQuantity) ? record.ToBeAssignedQuantity : (Number(Qty));
      //     return <>{(!record.isActive) ? <Tooltip placement="topLeft" title={"De Activated Item Can't be changed"}></Tooltip> : ''}
      //     {/* <Form.Item name={record.packingMaterialId + " ToBeAssignedQuantity"}> */}
      //       {value}
      //       {/* <Button  block onClick={() => locationWiseStockModal(record, index,'Assign')} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{(record.requiredQuantity > 0 && record.avilableQuantity > 0) ? 'Allocate' : record.assign}</Button> */}
      //       {/* <InputNumber
      //       min={0}
      //       max={(maxQty > 0) ? maxQty : 0}
      //       defaultValue={(Qty > 0) ? Qty : 0}
      //       readOnly={!record.isActive}
      //       onChange={(value) => changeAssignQty(record, index, value)}
      //     /> */}
      //     {/* </Form.Item> */}
      //     </>;
      //   },
      // },
      {
        title: 'Assign Qty Status',
        dataIndex: 'assign',
        key: 'assign',
        render: (value, record: BomPackingItemsDto, index) => {
          console.log(record)
          console.log( Number(record.requiredQuantity))
          console.log(Number(record.avilableQuantity))

          return <>
          <Tooltip placement="topLeft" title={(record.changedQty) ? 'Please Confirm Changes Before Assigning QTY or discard changes' : 'Assign QTY'}>
            {/* <Button key={record.packingMaterialId} disabled={(record.AssignedQuantity == record.quantity) || record.changedQty} block onClick={() => AssignSo(record, index)} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{(record.requiredQuantity > 0 && record.avilableQuantity > 0) ? 'Assign' : value}</Button> */}
            <Button  block onClick={() => locationWiseStockModal(record, index,'Assign')} style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>
              { (Number(record?.avilableQuantity) > 0) ?'Assign' : record.assign}</Button>
            </Tooltip></>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (value, record: BomPackingItemsDto, index) => (

          <Space size="middle">
            {(record.changedQty && record.isActive) ? <><Tooltip placement="topLeft" title={'Please confirm changes'}>
              <CheckOutlined className={'editSamplTypeIcon'} type="edit"

                onClick={() => {
                  if (record.isActive) {
                    submitItemInfo(record, index);
                  } else {
                    AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item');
                  }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }} />
            </Tooltip> <Popconfirm onConfirm={() => getSaleOrderItems(record.saleOrderId)}
              title={
                'Are you sure to discard changes'
              }
            ><Tooltip placement="topLeft" title={'Discard changes'}><UndoOutlined type='edit' style={{ color: 'red' }} /></Tooltip> </Popconfirm></> : ''}

            <Divider type="vertical" />
            {(record.status == StatusEnum.OPEN) ? !(record.isRequired === GlobalStatus.NO) ?
              <Popconfirm onConfirm={() => deleteItems(index, record)}
                title={
                  record.isActive
                    ? 'Are you sure to Deactivate Item ?'
                    : 'Are you sure to Activate Item ?'
                }
              >
                <Switch size="default"
                  className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                  checkedChildren={<RightSquareOutlined type="check" />}
                  unCheckedChildren={<RightSquareOutlined type="close" />}
                  checked={record.isActive}
                />

              </Popconfirm> : <Tooltip placement="topLeft" title={"You Can't use this item"}><WarningTwoTone twoToneColor="red" /></Tooltip> : <Tooltip placement="topLeft" title={"You Can't remove this item"}><WarningTwoTone twoToneColor="red" /></Tooltip>}
          </Space>
        ),
      },
    ];
    return columns;
  }

  const changeAssignQty = (record: BomPackingItemsDto, index, value) => {
    console.log(record);
    console.log(value);
    console.log(index);
    record.ToBeAssignedQuantity = value;
    console.log(record)
    // setRowRecord(record)

    // setUpdateStateData(updateStateData + 1);
  }

  const changeRequiredQty = (record: BomPackingItemsDto, index, value) => {
    if (value) {
      if (value >= Number(record.AssignedQuantity) + Number(record.receivedQuantity)) {
        record.quantity = (value) ? value : 0;
        record.requiredQuantity = Number(record.quantity) - (Number(record.AssignedQuantity) + Number(record.receivedQuantity));
        record.changedQty = true;
        setUpdateStateData(updateStateData + 1);
      } else {
        record.quantity = (Number(record.AssignedQuantity) + Number(record.receivedQuantity));
        record.requiredQuantity = 0;
        record.changedQty = true;
        setUpdateStateData(updateStateData + 1);
      }
    }
  }

  const AssignSo = (record: BomPackingItemsDto, index) => {
    console.log(record)
    record.ToBeAssignedQuantity = Number(record?.ToBeAssignedQuantity) > 0 ? Number(record.ToBeAssignedQuantity) : Number(record.quantity)
    console.log(locationWiseStock)
    if (record.isActive) {

      if (Number(record.ToBeAssignedQuantity) > 0) {
        const assignStockReq = new StockInputRequest(record.saleOrderId, record.itemCategoryDetails.itemCategoryId, record.size.sizeId, record.itemDetails.itemId, record.quantity, record.avilableQuantity, record.ToBeAssignedQuantity);
        assignStockReq.createdUser = localStorage.getItem('createdUser');
        assignStockReq.unitId = props.unitId;
        assignStockReq.itemSubCategoryId = record.itemSubCategoryDetails.itemSubCategoryId;
        assignStockReq.packingmaterialId = record.packingMaterialId;
        assignStockReq.locationWiseStock = locationWiseStock;
        console.log(assignStockReq)
        stockService.AssignInventoryStock(assignStockReq).then((res) => {

          if (res) {
            AlertMessages.getSuccessMessage('Successfully assigned QTY for so');
            getSaleOrderItems(record.saleOrderId);
          } else {
            AlertMessages.getErrorMessage('Failed To Assign Quantity');
          }
        });
      } else {
        AlertMessages.getWarningMessage('Assigned QTY should be > 0');
      }
    } else {
      AlertMessages.getWarningMessage('Can not assign for deactivated Item');
    }


  }

  const EditItem = (record: BomPackingItemsDto, index) => {
    setEditData(true);
    setIndex(index);
    getItems(record.itemCategoryDetails.itemCategoryId, null)
    bomItemsForm.setFieldsValue({
      // itemCategory:record.itemCategoryDetails.itemCategoryId, 
      item: record.itemDetails.itemId,
      // size:record.size.sizeId,
      quantity: record.quantity,
      avilableQuantity: record.avilableQuantity,
      requiredQuantity: record.requiredQuantity,
      packingMaterialId: record.packingMaterialId,
      packingCategory: record.packingCategory,
      status: record.status,
      assign: record.assign,
      AssignedQuantity: record.AssignedQuantity
    })
    setItemCategoryDetails(record.itemCategoryDetails);
    setItemDetails(record.itemDetails);
    setsize(record.size);
    setItemInitialQty(record.quantity);
    setPackCategory(record.packingCategory);
  }

  const submitItemInfo = async (record: BomPackingItemsDto, index) => {
    // await bomItemsForm.validateFields().then(bomRes => {
    if (record.quantity > 0) {
      const updateBom: UpdateBomItemDto = {
        packingMaterialId: record.packingMaterialId,
        itemId: record.itemDetails.itemId,
        quantity: record.quantity,
        toBeProcured: record.requiredQuantity
      }
      saleOrderService.UpdateBomItemsData(updateBom).then((res) => {
        if (res) {
          getSaleOrderItems(props.saleOrderId);
          bomItemsForm.resetFields();
        } else {
          AlertMessages.getErrorMessage('Failed To Update Bom Quantity');
        }
      });
    } else {
      AlertMessages.getWarningMessage('Please enter valid information');
    }

    // })
  }
  /**
   * add packing materials
   */
  const addItems = async () => {
    await bomItemsForm.validateFields().then(bomRes => {
      const bomDetails = bomItemsForm.getFieldsValue();
      const bomData: BomPackingItemsDto = {
        itemCategoryDetails: itemCategoryDetails,
        itemSubCategoryDetails: null,
        itemDetails: itemDetails,
        size: size,
        status: bomDetails.status,
        quantity: bomDetails.quantity,
        createdUser: 'ADMIN',
        saleOrderId: null,
        updatedUser: 'ADMIN',
        packingMaterialId: (bomDetails.packingMaterialId) ? bomDetails.packingMaterialId : null,
        avilableQuantity: bomDetails.avilableQuantity,
        requiredQuantity: bomDetails.requiredQuantity,
        packingCategory: bomDetails.packingCategory,
        isActive: true,
        assign: bomDetails.assign,
        AssignedQuantity: bomDetails.AssignedQuantity,
        isRequired: bomDetails.isRequired,
        isActiveRevised: bomDetails.isActiveRevised,
        isQtyRevised: bomDetails.isQtyRevised
      }
      if (editData) {
        bomPackingMaterialData.splice(index, 1, bomData)
      } else {
        bomPackingMaterialData.push(bomData);
      }
      setItemCategoryDetails(null);
      setItemDetails(null);
      setsize(null);
      setKeyUpdate(keyUpdate + 1);
      setItemInitialQty(0);
      setPackCategory(undefined);
      ResetForm();
    })
  }

  const ResetForm = () => {
    bomItemsForm.resetFields();
  }

  const deleteItems = (index: number, record: BomPackingItemsDto) => {
    // bomPackingMaterialData.splice(index, 1);
    record.isActive = record.isActive ? false : true;
    saleOrderService.ActivateorDeactivateBom({ packingMaterialId: record.packingMaterialId, isActive: record.isActive, poQty: 0, fromPo: false }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
    setKeyUpdate(keyUpdate + 1);
  }

  const calculateQty = (value) => {
    const assignQty = bomItemsForm.getFieldValue('AssignedQuantity');
    const toBeProcured = Number(value) - Number(assignQty);
    bomItemsForm.setFieldsValue({ requiredQuantity: toBeProcured });
  }

  const handleModalOk = () => {
    if(stockAction == 'Assign'){
      console.log(rowRecord)
      setIsModalVisible(false);
      AssignSo(rowRecord,null)
    }else if(stockAction == 'ReAssign'){
      setIsModalVisible(false);
      updateRemovedQty(rowRecord)
    }else{
      AlertMessages.getErrorMessage('Something Went Wrong');
    }
    
  };

  const handlecancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>

      <Form layout="vertical" form={bomForm} name="control-hooks" >
        <Row gutter={24}>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: true, message: 'Sale Order is required' }]}>
              <Select
                placeholder="Select SO Number"
                onChange={getSaleOrderItems}
                allowClear
                disabled={true}
              >
                <Option value={''}>Please Select</Option>
                {props.saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{dropData.saleOrderNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 14 }}>
            {(saleOrderData) ? <Descriptions style={{ paddingTop: 30 }}>
              <Descriptions.Item label="Invoice Date">
                {saleOrderData.invDate}
              </Descriptions.Item>
              <Descriptions.Item label="Customer">
                {saleOrderData.customer}
              </Descriptions.Item>
              <Descriptions.Item label="End Customer">
                {saleOrderData.endCustomer}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Address">
                {saleOrderData.address}
              </Descriptions.Item>
            </Descriptions>
              : ''}
          </Col>
        </Row>
        <Row>
          {(saleOrderData) ?
            <Descriptions>
              <Descriptions.Item label="ETA">
                {saleOrderData.eta}
              </Descriptions.Item>
              <Descriptions.Item label="ETD">
                {saleOrderData.etd}
              </Descriptions.Item>
              <Descriptions.Item label="Destination Address">
                {saleOrderData.destinationAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Delivery Terms">
                {saleOrderData.deliveryTerms}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Terms">
                {saleOrderData.paymentTerms}
              </Descriptions.Item>
              <Descriptions.Item label="Certification">
                {saleOrderData.certfication}
              </Descriptions.Item>
              <Descriptions.Item label="Design Type">
                {saleOrderData.brandDesignType}
              </Descriptions.Item>
              <Descriptions.Item label="PO Number">
                {saleOrderData.poNo}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Packing Category">
                {saleOrderData.category}
              </Descriptions.Item> */}
              <Descriptions.Item label="Additional Note">
                {saleOrderData.additionalNote}
              </Descriptions.Item>
              <Descriptions.Item label="Is Brand Approved">
                {saleOrderData.isBrandApproved}
              </Descriptions.Item>
              <Descriptions.Item label="Manufacturing Unit">
                {saleOrderData.unit}
              </Descriptions.Item>
              <Descriptions.Item label="Processing Unit">
                {saleOrderData.processingUnit}
              </Descriptions.Item>
            </Descriptions> : ''}
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <Table
            className={'bomParameterForm'}
            key={keyUpdate + 1}
            columns={getColumns()}
            dataSource={bomPackingMaterialData}
            pagination={false} />
        </Col>
      </Row>
      <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        onCancel={ handlecancel}
        onOk={handleModalOk}
        title={<React.Fragment>
        </React.Fragment>}
      >
        <Table
            className={'bomParameterForm'}
            key={keyUpdate + 1}
            columns={modalColumns}
            dataSource={locationWiseStock}
            pagination={false} />

      </Modal>
      </>
  );
}

export default BomParameterForm;
