

export enum CustomerEnum {
    // DO NOT MODIFY THIS ENUM

    // GRP - a prefix to identify the groups
    GRP_SANDHYA_AQUA_COMMON = 'GRP_SANDHYA_AQUA_COMMON',
    GRP_SCHEMAX_COMMON='GRP_SCHEMAX_COMMON',
    
    // PER - a prefix to identify the personal numbers
    PER_SANDHYA_AQUA_COMMON_ROLE1 = 'PER_SANDHYA_AQUA_COMMON_ROLE1',
    PER_SANDHYA_AQUA_COMMON_ROLE2 = 'PER_SANDHYA_AQUA_COMMON_ROLE2',
}