import { LocationDto } from '@gtpl/shared-models/masters';
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class LocationResponseModel extends GlobalResponseObject {
  data?: LocationDto;
  /**
   *
   * @param status
   * @param errorCode
   * @param internalMessage
   * @param data //LocationDto
   */
  constructor(
    status: boolean,
    errorCode: number,
    internalMessage: string,
    data?: LocationDto
  ) {
    super(status, errorCode, internalMessage);
    this.data = data;
  }
}
