import React, { useState, useEffect } from 'react';
import './rm-evaluation--print.css';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { GrnRequest, RmEvaluationDto } from '@gtpl/shared-models/raw-material-procurement';
import { RMEvaluationService } from '@gtpl/shared-services/raw-material-procurement';
import moment from 'moment';
/* eslint-disable-next-line */
export interface RmEvaluationPrintProps {
  grnItemId: number;
}

export function RmEvaluationPrint(
  props: RmEvaluationPrintProps
) {
  let temp = 4.0;
  const [evaluationDetails, setEvaluationDetails] = useState<RmEvaluationDto>();
  const service = new RMEvaluationService();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const sampleColumnObj = { sampleNo: 'Sample No.', time: 'Time', species: 'Type / Species', temperature: 'Temperature', generalAppearance: 'General Appearance', generalOdour: 'General Odour', freshness: 'Freshness', dehydration: 'Dehydration', discolouration: 'Discolouration', deterioration: 'Deterioration', blackSpotOrTail: 'Black Spot / Tail', looseShells: 'Loose Shells', brokenOrDamagedPcs: 'Broken / Damaged pcs.', fungalGrowth: 'Fungal growth', softSpecies: 'Soft Pieces', droopingHead: 'Drooping Head', foreignMatter: 'Foriegn Matter', sulphiteKit: 'Sulphite Kit', pests: 'Pests absent/Present', muddyOdour: 'Muddy Odour', cookedTexture: 'Cooked Texture', cookedFlavor: 'Cooked Flavor', parasite: 'Parasite' };

  const sampleSpec = { sampleNo: '', time: '', species: '', temperature: '&lt;4.0<sup>0</sup>C', generalAppearance: '', generalOdour: '', freshness: '', dehydration: '5%', discolouration: '5%', deterioration: 'Nil', blackSpotOrTail: '5%', looseShells: '6%', brokenOrDamagedPcs: '5%', fungalGrowth: '10%', softSpecies: '10%', droopingHead: '10%', foreignMatter: 'Nil', sulphiteKit: 'No Colour Change', pests: 'Nil', muddyOdour: 'Nil', cookedTexture: '', cookedFlavor: '', parasite: 'Nil' };

  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };

  const getData = (grnItemId) => {
    service.getRmEvaluation({grnItemId:grnItemId}).then(res => {
      if (res.status) {
        console.log(res.data);
        setEvaluationDetails(res.data)
      } else {
        if (res.intlCode) {
          setEvaluationDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEvaluationDetails(undefined);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    if (props.grnItemId) {
      getData(props.grnItemId);
    }
  }, [props.grnItemId])

  const getColSpan = () => {
    const len = (evaluationDetails?.RmEvalSamplesInfo?.length + 2) / 2;
    const evenOdd = len % 2;
    if (evenOdd === 0) {
      return len;
    } else {
      return len + 1;
    }
  }

  const getSingleRowColSpan = () => {
    return (evaluationDetails?.RmEvalSamplesInfo.length % 2 === 0) ? evaluationDetails?.RmEvalSamplesInfo?.length + 2 : evaluationDetails?.RmEvalSamplesInfo?.length + 3
  }
  return (
    <div>
      <>
        <br /><br />
        {!evaluationDetails ?
          ''
          : <html>
            <head>
            </head>
            <body id='printme'>
              <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
              <br /><br />
              <table style={{ width: '100%' }}>
                <tr style={{ borderBottom: '0px' }}>
                  <td colSpan={getSingleRowColSpan()} style={{ fontSize: '17px', lineHeight: '12px', paddingTop: '20px', textAlign: 'center' }}>
                    <h2 >SANDHYA AQUA EXPORTS PVT. LIMITED, PAMARRU</h2>
                    <h3 ><br /><span style={{ backgroundColor: '#57595A', color: '#D4E1E7', margin: '4px' }}>RAW MATERIAL EVALUATION REPORT</span></h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ display: 'inline' }}>Date: </b>&ensp;{moment(new Date()).format('YYYY-MM-DD')}
                  </td>
                </tr>
                <tr>
                  <td><b>Vehicle Number</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>
                    :&ensp;{evaluationDetails?.vehicleNumber}</td>
                  <td><b>Batch Number</b> </td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp; {evaluationDetails?.batchNumber}</td>
                </tr>
                <tr>
                  <td><b>Arrival Time</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{moment(evaluationDetails?.arrivalTime).format("h:m A")}</td>
                  <td><b>Center / Source Code</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{evaluationDetails?.center}&ensp;/&ensp;{evaluationDetails?.sourceCode}</td>
                </tr>
                <tr>
                  <td><b>Start Time of Observation</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{moment(evaluationDetails?.startTimeOfObv).format("h:m A")}</td>
                  <td><b>Total Quantity</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{evaluationDetails?.totalQuantity}</td>
                </tr>
                <tr>
                  <td><b>Frequency of Inspection</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{evaluationDetails?.freqencyOfInsp}</td>
                  <td><b>Number of samples drawn</b></td>
                  <td colSpan={evaluationDetails?.RmEvalSamplesInfo?.length / 2}>:&ensp;{evaluationDetails?.noOfSamplesDrawn}</td>
                </tr>

                {Object.entries(sampleColumnObj).map(([key, value]) => {
                  return (<tr style={{ border: '1px solid black' }}>
                    <td colSpan={(evaluationDetails?.RmEvalSamplesInfo.length % 2 === 0) ? 1 : 2} style={{ border: '1px solid black' }}><b>{value}</b></td>
                    <td style={{ border: '1px solid black', textAlign: 'center' }}><b>{(key == 'temperature') ? '<4°C' : sampleSpec[key]}</b></td>
                    {evaluationDetails?.RmEvalSamplesInfo?.map((sample, index, array) => <td style={{ border: '1px solid black', textAlign: 'center' }}>{(key == 'time') ? moment(sample[key]).format('h:m A') : sample[key]}</td>)}
                  </tr>)
                })}

                <tr>
                  <td colSpan={getSingleRowColSpan() / 2} style={{ border: '1px solid black', borderRight: '0px' }}><h3><u>Remarks and <br />Corrective Action</u></h3></td>
                  <td colSpan={getSingleRowColSpan() / 2} style={{ border: '1px solid black', borderLeft: '0px' }}>{evaluationDetails?.remarks}</td>
                </tr>
                <tr >
                  <td colSpan={getSingleRowColSpan()} style={{ textAlign: 'center' }}><h3>*Note:</h3>&ensp; G -Good, S - Satisfy, N - Nil/absent, SF - Soft & Firm, US - UnSatisfy, NA - Not Applicable, NCC - No Colour Change, Pc - Piece</td>
                </tr>
                <tr>
                  <td colSpan={getSingleRowColSpan() / 2} style={{ textAlign: 'center' }}>
                    <br /><br /><br /><br />
                    <h3>Checked by</h3>
                  </td>
                  <td colSpan={getSingleRowColSpan() / 2} style={{ textAlign: 'center' }}>
                    <br /><br /><br /><br />
                    <h3>Verified by</h3>
                  </td>
                </tr>
              </table>
            </body>
          </html>
        }
      </>
    </div>
  );
}

export default RmEvaluationPrint;


