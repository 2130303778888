import { FgStatusEnum, JobCompletedEnum, SubPlantOperationStatusEnum } from "@gtpl/shared-models/common-models";

export class subPlantHistoryReport {
    subPlantId?: number;
    plantName: string;
    operation: number;
    saleOrderNo: string;
    lotNumber: string;
    jobNumber: string;
    ipBoxes: number;
    opBoxes: number;
    quantity: number;
    status?: FgStatusEnum;
    jobStatus?: JobCompletedEnum;
    isReceived: SubPlantOperationStatusEnum;
    grade: number;
    /**
     * 
     * @param plantName
     * @param subPlantId 
     * @param operation 
     * @param saleOrderNo 
     * @param lotNumber 
     * @param jobNumber 
     * @param boxes
     * @param ipBoxes 
     * @param opBoxes
     * @param quantity
     * @param jobStatus
     * @param isReceived 
     * @param opBoxes 
     * @param grade 
     */


    constructor(plantName: string, operation: number, saleOrderNo: string, lotNumber: string, jobNumber: string, ipBoxes: number, opBoxes: number, isReceived: SubPlantOperationStatusEnum, quantity: number, grade: number, jobStatus?: JobCompletedEnum, subPlantId?: number) {
        this.subPlantId = subPlantId;
        this.plantName = plantName;
        this.operation = operation;
        this.saleOrderNo = saleOrderNo;
        this.lotNumber = lotNumber;
        this.jobNumber = jobNumber;
        this.ipBoxes = ipBoxes;
        this.opBoxes = opBoxes;
        this.isReceived = isReceived;
        this.quantity = quantity;
        this.grade = grade;
        this.jobStatus = jobStatus


    }

}