export const CrrencySymbols = [
    {id: 4,symbol:'₹', name:'INR',locale:'en-IN'},
    {id : 2,symbol:'￠', name:'Cent',locale:'en-IN'},
    {id : 3,symbol:'€', name:'EURO',locale:'en-IE'},
    {id : 5,symbol:'£', name:'Pound',locale:'en-GB'},
    {id:1,symbol:'$', name:'USD',locale:'en-US'},
    {id : 6,symbol:'$', name:'dollars',locale:'en-US'},
    {id : 7,symbol:'﷼', name:'Rial',locale:'en-IN'},
    {id : 8,symbol:'¥', name:'YEN',locale:'ja-JP'}
]
