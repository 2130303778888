export class ValAddThreeDropDownData{
  valueAdditionThreeId:number;
  name:string;
    
    /**
     * 
     * @param valueAdditionThreeId 
     * @param name 
     */
    constructor(valueAdditionThreeId:number,name:string){
      this.valueAdditionThreeId = valueAdditionThreeId;
      this.name = name;
    }
}