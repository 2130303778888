import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Modal, Radio, Row, Select, Table, Tooltip } from 'antd';
import { GRNLocationPropsRequest, LocationMappingRmRackPostionReq, VendorDropDownDto } from '@gtpl/shared-models/masters';
import { Link, useHistory,useLocation} from 'react-router-dom';
import { PlantIdRequest } from '@gtpl/shared-models/warehouse-management';
import { RmRackPositionService, VendorsService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './location-mapping-form.css';
import {GrnService, StockService} from '@gtpl/shared-services/procurement';
import type { RadioChangeEvent } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';
import { GrnInvoiceNumberRequest, locationIdRequest } from '@gtpl/shared-models/procurement-management';
const { Option } = Select;

/* eslint-disable-next-line */
export interface LocationMappingFormProps {
  // grnData : GRNLocationPropsRequest
}

export function LocationMappingForm(
  props: LocationMappingFormProps
) {
  let location = useLocation();
  console.log(location.state)
  const grnData: GRNLocationPropsRequest | any = location.state;
    let history = useHistory();
   console.log(grnData);
  // useEffect(()=>{
  //   if(grnData){
  //     console.log(grnData)
  //     getAllLocations(3, 1);
  //   }
  // },[grnData])
 
  const vendorService = new VendorsService;
  const locationService = new RmRackPositionService;
  const grnService = new GrnService();
  const stockService = new StockService();
  const [page, setPage] = React.useState(1);
  const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [prevTransactionsInfo, sePrevTransactionsInfo] = useState<any[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [locationStatusValue, setValue] = useState<string>('Occupied');
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);

  const [form] = Form.useForm();
  useEffect(() => {
    if(grnData){
      console.log(grnData)
      getAllLocations(grnData.grnUnitId, grnData.brandId);
      getExistingInvoiceWiseStock(grnData.invoiceNumber)
      form.setFieldsValue({vendorName:grnData.vendorName})
      form.setFieldsValue({itemName:grnData.itemName})
    }
    console.log(grnData)
    // const testdata = new GRNLocationPropsRequest(2,1,'Coromandel Cartons and Containers Private Limited',139,'Carton/METROCHEF/16-20/VREZPEELI/10*800GM',107,'CCC-510',2000,0,6,3,null,null,null,'')
    // props.grnData = testdata
    // props.grnData.grnUnitId = 3
    // props.grnData.brandId = 1
    // props.grnData.vendorName = 'Pragathi'
    // props.grnData.itemName = 'Pouch/Rain Forest/16-20/VREZPEEL/10291/24OZ'
      getAllVendors();
      // getAllLocations(3, 1);
      // getLocationMappedGrns(props.grnData.invoiceNumber);
  }, [])

  const getExistingInvoiceWiseStock = (invNum) => {
    stockService.getLocationWiseStockByGrn(new GrnInvoiceNumberRequest(invNum)).then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setTableData(res.data);
      } else {
        if (res.intlCode) {
          setTableData([]);
        } else {
          setTableData([]);
        }
      }
    }).catch(err => {
      setTableData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const getLocationMappedGrns = (grnUnitId, brandId) => {
  //   grnService.getRmRackPostionsForLocationMapping(new LocationMappingRmRackPostionReq(grnUnitId, brandId)).then(res => {
  //     if (res.status) {
  //       // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
  //       setLocations(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         setLocations([]);
  //       } else {
  //         setLocations([]);
  //       }
  //     }
  //   }).catch(err => {
  //     setLocations([]);
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      locationId: value
    })
  }

  const getAllLocations = (grnUnitId, brandId) => {
    locationService.getRmRackPostionsForLocationMapping(new LocationMappingRmRackPostionReq(grnUnitId, brandId)).then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setLocations(res.data);
      } else {
        if (res.intlCode) {
          setLocations([]);
        } else {
          setLocations([]);
        }
      }
    }).catch(err => {
      setLocations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllVendors = () => {
    vendorService.getVendorsDropDownData().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setVendorData(res.data);
      } else {
        if (res.intlCode) {
          setVendorData([]);
        } else {
          setVendorData([]);
        }
      }
    }).catch(err => {
      setVendorData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  const saveLocation = (btnval) => {
    form.validateFields().then(res => {
    console.log(grnData)
    setBtnDisable(true)
    const values = form.getFieldsValue();
    const request = new GRNLocationPropsRequest(grnData.grnUnitId,grnData.vendorId,grnData.vendorName,grnData.itemId,grnData.itemName,grnData.brandId,grnData.invoiceNumber,grnData.receivedQty,grnData.saleOrderId,grnData.itemCategoryId,grnData.itemSubCategoryId,grnData.unitPrice,grnData.grnId,grnData.grnItemId,grnData.sizeId,grnData.poId,form.getFieldValue('locationId'),form.getFieldValue('quantity'),locationStatusValue,'admin')
    console.log(request)
    stockService.createLocationMappedStock(request).then((res) => {
      // setDisable(false)
      if (res.status) {
        if(btnval == 'close'){
          AlertMessages.getSuccessMessage('Location Mapped sucessfully')
          history.push("/pending-grn-info-grid");
        }
        if(btnval == 'continue'){
          AlertMessages.getSuccessMessage('Location Mapped sucessfully')
          // form.setFieldsValue({quantity:null})
          form.setFieldsValue({locationId:null})
          form.setFieldsValue({locationStatus:null})
          setBtnDisable(false)
          getExistingInvoiceWiseStock(grnData.invoiceNumber)
          getAllLocations(grnData.grnUnitId, grnData.brandId)
          grnData.physicalQuantity = Number(grnData.physicalQuantity)+Number(form.getFieldValue('quantity'))

        }
      } else {
        setBtnDisable(false)
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      // setDisable(false)
      AlertMessages.getErrorMessage(err.message)
    });
  })

  }

  const validateQty = (selQty) => {
    console.log(selQty)
    if(selQty > grnData.receivedQty){
      AlertMessages.getErrorMessage('Qunatity should not greater than Received')
      form.setFieldsValue({quantity:null})
    }
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    // {
    //   title: 'Invoice Number',
    //   dataIndex: 'invNumber',
    // },
    {
      title: 'Item',
      dataIndex: 'itemName',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text, object, index) => {
        return <>{Number(text)}</>
      }

    },
  ] 
  const getPreviousTransactions = (val) => {
    console.log(val)
    stockService.getPreviousTransactionsByGrn(new locationIdRequest(val,JSON.parse(localStorage.getItem('unit_id')))).then(res => {
      if (res.status) {
        console.log(res.data)
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        sePrevTransactionsInfo(res.data);
      } else {
        if (res.intlCode) {
          sePrevTransactionsInfo([]);
        } else {
          sePrevTransactionsInfo([]);
        }
      }
    }).catch(err => {
      sePrevTransactionsInfo([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getLocationDetails = (value) => {
    setShowQrScan(true);
    const sellocationId = (locations.find(item => item.location == value)).rackPositionId

    form.setFieldsValue({locationId:sellocationId})
    // setModal('workstation')
    // getlocationId(value);
  }

  // const getlocationId = (val) => {
    
  // }

  const columns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Location',
      dataIndex: 'location',
      // responsive: ['lg'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render:(text,data) => {
        return <>{Number(text)}</>
      }
      // responsive: ['lg'],
    },

  ]
  return (
    <div>
      <Card size="small" title={<span style={{ color: 'white' }} >Location Mapping</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/pending-grn-info-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
          <Row gutter={24}>
            <Col span={12}>
          <Descriptions column={2}>
            <Descriptions.Item label="Invoice Number" style={{width:'33%'}}>
              {grnData.invoiceNumber}
            </Descriptions.Item>
            <Descriptions.Item label="GRN Number" style={{width:'33%'}}>
              {grnData.invoiceNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Received Quantity" style={{width:'33%'}}>
              {Number(grnData.receivedQty)}
            </Descriptions.Item>
            <Descriptions.Item label="Stock" style={{width:'33%'}}>
              {grnData.physicalQuantity > 0 ? Number(grnData.physicalQuantity): 0}
            </Descriptions.Item>
            <Descriptions.Item label="Location Pending Quantity" style={{width:'33%'}}>
              {Number((grnData.receivedQty)-(grnData.physicalQuantity))}
            </Descriptions.Item>
            </Descriptions>
            </Col>
            {prevTransactionsInfo.length > 0 ? 
            <Col span= {12}>
            <Card title={<span style={{ color: 'black' }} >Previous 3 Transaction on Selected Location</span>}>
            {/* Previous Transaction for selected location: */}
            <Table
            columns={columns}
            dataSource={prevTransactionsInfo}
            pagination={false} 
            />
            </Card>
            </Col>
            
            :<></>}
            
            </Row>
        <Form layout={"vertical"} autoComplete="off" form={form} >
        <Form.Item name="vendorId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="itemId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="vendorName" label="Vendor" rules={[{ required: true, message: 'Missed Vendor' }]}>
                <Input
                 disabled ={grnData}/>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
              <Form.Item name="itemName" label="Item" rules={[{ required: true, message: 'Missed Item' }]}>
                <Input disabled={grnData}/>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="locationId" label="Location" rules={[{ required: true, message: 'Missed Location' }]}>
                <Select 
                onChange={getPreviousTransactions}
                showSearch allowClear placeholder="Select Location" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<QrcodeOutlined onClick={(e) => { getLocationDetails(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px' }} />}
                >
                  {locations.map((locationData) => {
                    return <Option style = {{color:locationData.color}} value={locationData.rackPositionId}>{locationData.location}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: 'Missed Quantity' }]}>
                <InputNumber min={1} style={{ width: "100%" }} onChange={e => validateQty(e)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                Location Status : <Radio.Group name = 'locationStatus' onChange={onChange} value = {locationStatusValue}>
                {/* <Radio value={'Empty'}>Empty</Radio> */}
                <Radio value={'Occupied'}>Fully Occupied</Radio>
                <Radio value={'partially Occupied'}>Partially Occupied</Radio>
              </Radio.Group>
            </Col>
            <Col span={4}>
                <Form.Item >
                <Button type='primary' onClick={e=>(saveLocation('continue'))} block disabled={btnDisable}>
                      Save & Continue...
                    </Button>
                </Form.Item>
            </Col>
            <Col span={4}>
          <Form.Item >
            <Button type='primary' danger onClick={e=>(saveLocation('close'))} block disabled={btnDisable}>
              Save & Close
            </Button>
          </Form.Item>
        </Col>
          </Row>
        </Form>
        {tableData.length > 0 ?<Table
          columns={columnsSkelton}
          dataSource={tableData}
          // pagination={{
          //   onChange(current) {
          //     setPage(current);
          //   }
          // }}
          // onChange={onChange}
          bordered
        />:<></>}
         <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
           <QrScanner handleScan={handleQrScan} /> 
          }

        </Modal>
      </Card>
    </div>
  );
  
}

export default LocationMappingForm;
