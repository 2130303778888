export class PoDropDownDto{
    purchaseOrderId : number;
    poNumber : string;
    
    /**
     * 
     * @param purchaseOrderId number
     * @param poNumber string
     */
    constructor(purchaseOrderId:number,poNumber:string){
        this.purchaseOrderId = purchaseOrderId;
        this.poNumber = poNumber;
    }
}