export class TrimsRequest{
    trimsId : number;
    isActive ?: boolean;
    
    constructor(trimsId : number,isActive? : boolean)
    {
        this.trimsId = trimsId;
        this.isActive = isActive;
        
    }
}
