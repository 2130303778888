import { EnvVarEnum } from '@gtpl/shared-models/common-models';
import {appSettings} from '../../../../../apps/services/config';
const envType = 'DEV';//EnvVarEnum[process.env.NX_ENVIRONMENT.toLocaleUpperCase()];
// const url = process.env[`NX_PMS_` + envType + `_SERVICE_URL`];
const url=appSettings.rm_procure_url;//'http://206.189.138.212:3334/erpx';
// const url='http://localhost:3334/erpx';
export const connection = {
    'DEPLOY_URL': url,
    // 'userid':JSON.parse(localStorage.getItem('userid')),
    // 'role':JSON.parse(localStorage.getItem('role')),
    // 'unit_id':JSON.parse(localStorage.getItem('unit_id')),
}

export default connection;