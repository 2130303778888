import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Popconfirm, Row, Select, Tooltip,Modal } from 'antd';
import './packing-stock-transfer.css';
import { CategorySubCategoryReq, ItemCategoryIdReq, PackingSTItems, QtyRequest, SizeIdRequest } from '@gtpl/shared-models/procurement-management'
import { ItemCategoryDropDownDto, ItemsDropDownDto, ItemSubCategoryDropDownDto, SizesDropDownDto } from '@gtpl/shared-models/masters';
import Table, { ColumnProps, ColumnsType } from 'antd/lib/table';
import { IsPreparedMaterial, UnitRequest } from '@gtpl/shared-models/common-models';
import { StockService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EditOutlined, MinusCircleOutlined, PlusSquareOutlined, ClearOutlined } from '@ant-design/icons';
import { SaleOrderFileUploadResponse } from '@gtpl/shared-models/sale-management';
import LocationWiseStockTransfer from './location-wise-stock-transfer';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
const { Option } = Select;
/* eslint-disable-next-line */
export interface PackingStockTransferItemsProps {
  itemCategories: ItemCategoryDropDownDto[];
  form: FormInstance<any>;
  stItems: any;
  addItems: (values) => void;
  availQty:number;
  isSo:boolean;
  fromSO:number;
  isFormDisable:boolean;
  buttonDisable:boolean;
  isPreparedMaterialStatus:boolean;
}


const dropDrownInfo = (key, value) => <Option key={key} value={key}>{value}</Option>
export function PackingStockTransferItems(
  props: PackingStockTransferItemsProps
) {

  const [locationWiseStockForm] = Form.useForm();
  const [itemSubCategories, setItemSubCategories] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  // const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [alternateKeyUpdate, setAlternateKeyUpdate] = useState<number>(1);
  const [editItem, setEditItem] = useState<string>('Add');
  const [availableQty, setAvailableQty] = useState<number>(props.availQty);
  const [itemEdit, setItemEdit] = useState<boolean>(false);
  const [transferBtn, setTransferBtn] = useState<boolean>(false);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const [record, setRecord] = useState<any>(undefined);
  const [index, setIndex] = useState<any>(undefined);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [locationData, setLocationData] = useState<any[]>([]);

  const [selectedQty, setSelectedQty] = useState<number>(0);
  const searchInput = useRef(null);

  useEffect(()=> {
    console.log(props.form);
      console.log(props.isFormDisable)
      props.form.setFieldsValue({'unitId' : Number(localStorage.getItem('unit_id'))})
  },[props.isFormDisable])

  const skService = new StockService();

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  // Table Columns
  const columns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed: 'left',
      render: (text, object, index) => (index + 1)
    },
    {
      title: 'Item Category',
      dataIndex: 'itemCategory',
      key: 'itemCategory',
      render: (value, row) => {
        const itemCategory = JSON.parse(value);
        row.itemCategoryId = itemCategory.itemCategoryId;
        return itemCategory.itemCategory;
      }
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategory',
      key: 'itemSubCategory',
      render: (value, row) => {
        const itemSubCategory = JSON.parse(value)
        row.itemSubCategoryId = itemSubCategory.itemSubCategoryId;
        return itemSubCategory.itemSubCategory;
      }
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      render: (value, row) => {
        const item = JSON.parse(value)
        row.itemId = item.itemId;
        return item.itemName;
      }
    },
    // {
    //   title: 'Size',
    //   dataIndex: 'size',
    //   key: 'size',
    //   render: (value, row) => {
    //     const size = JSON.parse(value)
    //     row.sizeId = size.sizeId;
    //     return size.sizeName;
    //   }
    // },
    {
      title: 'Required Qty',
      dataIndex: 'requiredQty',
      key: 'requiredQty',
      className: props.isSo ? 'showColumn' : 'hideColumn',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    {
      title: 'Available Qty',
      dataIndex: 'availableQty',
      key: 'availableQty',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    {
      title: 'Issued Qty',
      dataIndex: 'issuedQty',
      key: 'issuedQty',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unitPrice',
    //   key: 'unitPrice',
    //   render: (value, row) => {
    //     return Number(value);
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      // width: '20%',
      render: (text, rowData, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                setEditForm(rowData, index);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm title='Sure to delete?' onConfirm={e =>{deleteData(index);}}>
          
          <Tooltip placement="top" title='delete'>
            <MinusCircleOutlined 
              style={{ color: '#1890ff', fontSize: '14px' }} />
          </Tooltip>
          </Popconfirm>
        </span>)
    }
  ];
  const issuedQtyOnchange = (value, index, rowData) => {
    console.log(index);
    console.log(value.target.value);
    console.log(rowData);
    rowData.issuedQty = value.target.value
    // const newData = [...locationData];
    // newData[index].issuedQty = value.target.value;
    // console.log(newData)
    // setLocationData(newData);
    if (value.target.value === 0 || value.target.value === null || value.target.value < 0) {
      AlertMessages.getErrorMessage('Issued Quantity should be greater than zero')
      locationWiseStockForm.setFieldsValue({[rowData.stockId]:(rowData.requiredQty>rowData.availableQty?rowData.requiredQty:rowData.availableQty)});
    }
    if (Number(value.target.value) > Number(rowData.availableQty)) {
      locationWiseStockForm.setFieldsValue({[rowData.stockId]:(rowData.requiredQty>rowData.availableQty?rowData.requiredQty:rowData.availableQty)});
      AlertMessages.getErrorMessage('Issued Quantity should be less than Avaialble Quantity--')
    }
   
  }
  const tableColumns = () => {

  const sampleTypeColumns:ColumnsType<any>= [
    {
        title: 'Location',
        dataIndex:"location",
        align:'center',
        width:'10px',
       ...getColumnSearchProps('location')
    },
    {
      title: 'Item',
      dataIndex:"itemName",
      align:'center',
      width:'10px',
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Available Qty',
      dataIndex:"availableQty",
      align:'center',
      width:'10px',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
      },
    
    },
    {
        title: 'Issued Qty',
        dataIndex: 'issuedQty',
        width:'10px',
        align:'center',
        render: (text, rowData, index) => { 
          return (
            <>
              <Form.Item
                name={rowData.stockId}
                rules={[
                  {
                    required: true, message: 'Missing issued Qty',
                    validator: (_, value) =>
                    value >= 0 ? Promise.resolve() : Promise.reject(new Error('Enter only positive values')),
                  }
  
                ]}
                style={{ margin: 0 }}
              >
              <InputNumber key={rowData.stockId} defaultValue={rowData.issuedQty >0 ? rowData.issuedQty : 0} name={`issuedQty${rowData.stockId}`} 
              onBlur={(e)=>issuedQtyOnchange(e,index,rowData)} 
               />
              </Form.Item>
              </>
          )
        }
    },
];
const checkedStatus:ColumnsType<any>= [
  {
    title: '*',
    dataIndex: 'isChecked',
    width:'10px',
    align:'center',
    render: (text, rowData, index) => { 
      return (
        <>
        <Form layout="vertical" form={locationWiseStockForm} name="control-hooks">
          <Form.Item
            name={`isChecked${rowData.stockId}`}
            rules={[
              {
                required: false, message: 'Missing isChecked',
              }

            ]}
            style={{ margin: 0 }}
            initialValue={rowData.isChecked}>
            <InputNumber  name={`isChecked${rowData.stockId}`} /> 
          </Form.Item>
          </Form></>
      )
    }
  },
  ]

  return [...sampleTypeColumns];

  }

  const indentColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed: 'left',
      render: (text, object, index) => (index + 1)
    },
    {
      title: 'Item Category',
      dataIndex: 'itemCategory',
      key: 'itemCategory',
      // render: (value, row) => {
      //   console.log(value);
      //   console.log(JSON.parse(value));

      //   const itemCategory = JSON.parse(value);
      //   row.itemCategoryId = itemCategory.itemCategoryId;
      //   return itemCategory.itemCategory;
      // }
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategory',
      key: 'itemSubCategory',
      // render: (value, row) => {
      //   const itemSubCategory = JSON.parse(value)
      //   row.itemSubCategoryId = itemSubCategory.itemSubCategoryId;
      //   return itemSubCategory.itemSubCategory;
      // }
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      // render: (value, row) => {
      //   const item = JSON.parse(value)
      //   row.itemId = item.itemId;
      //   return item.itemName;
      // }
    },
    
    {
      title: 'Required Qty',
      dataIndex: 'requiredQty',
      key: 'requiredQty',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    {
      title: 'Available Qty',
      dataIndex: 'availableQty',
      key: 'availableQty',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    {
      title: 'Issued Qty',
      dataIndex: 'issuedQty',
      key: 'issuedQty',
      render: (value, rowData, index) => {
        return <span style={{float:'right'}}>{Number(value)}</span>;
      },
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     return <>
    //     {
    //       <Button disabled={transferBtn} type="primary" onClick={() => {
    //         TransferStock(data, index);
    //         }} 
    //         style={{ backgroundColor: '#40a9ff' }}>Transfer Stock</Button>
    //     }
    //     </>
    //   }
    // },
  ];
  let totalIssuedQty = 0;
  let totalRequiredQty = 0;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows);
      console.log(selectedRowKeys);
      setSelectedRowKeysData(selectedRowKeys);
      setSelectedRowsData(selectedRows);
      const selectedQtyFlag = new Set<boolean>()
      const issuedQtyFlag = new Set<boolean>()
      if(selectedRows.length > 0){
        selectedRows?.forEach((data,index) => {
          totalRequiredQty = data.requiredQty;
          console.log(data);
          console.log(index);

          console.log(data.availableQty+"********"+index+"**********"+data.issuedQty);

          if (Number(data.issuedQty) > 0) {
            console.log(locationWiseStockForm.getFieldsValue());
            selectedQtyFlag.add(true)
            locationWiseStockForm.setFieldsValue({['isChecked'+data.stockId] : 1});
            data.isChecked = "1";
          } else {
            // console.log(selectedRowKeysData)
            // console.log(selectedRowKeys);
            // console.log(selectedRowKeys[0]);
            selectedRowKeys[index] = ''
            selectedQtyFlag.add(false)
            // console.log(locationWiseStockForm.getFieldsValue());
            // console.log(locationWiseStockForm.getFieldValue(['isChecked'+data.stockId]));
            locationWiseStockForm.setFieldsValue({['isChecked'+data.stockId] : 0});
            data.isChecked = "0";

            // console.log(locationWiseStockForm.getFieldValue(['isChecked'+data.stockId]));
            // console.log(locationWiseStockForm.getFieldsValue());

            AlertMessages.getErrorMessage("Issued Quantity should be greater than 0.");
          }
          if (Number(data.issuedQty) > Number(data.availableQty)) {
            issuedQtyFlag.add(false)
            selectedRowKeys[index] = ''
            locationWiseStockForm.setFieldsValue({['isChecked'+data.stockId] : 0});
            data.isChecked = "0";

            console.log('true cartons > orginal cartons')
          } else {
            issuedQtyFlag.add(true)
            locationWiseStockForm.setFieldsValue({['isChecked'+data.stockId] : 1});
            data.isChecked = "1";

          }

          totalIssuedQty = Number(totalIssuedQty) + Number(data.issuedQty)
        })

      }
      else{
        selectedRowKeysData.map((element, index) => (  
          locationWiseStockForm.setFieldsValue({['isChecked'+element] : 0})
        ))
        // console.log(selectedRowKeysData);
        // console.log(locationWiseStockForm.getFieldsValue());
      }
      setSelectedQty(totalIssuedQty);
      // console.log(selectedRows);
      // console.log(selectedRowKeys);

      // console.log(selectedRowsData);
      if(!selectedQtyFlag.add(true)){
        AlertMessages.getErrorMessage("Issued Quantity should be greater than 0.");
      }
      if(!issuedQtyFlag.add(true)){
        AlertMessages.getErrorMessage("Issued Quantity should be less than available quantity.");
      }

      if (selectedRows.length > 0) {
        // console.log('enter')
        // console.log(totalIssuedQty);
        // console.log(totalRequiredQty);
        // console.log(props.isSo)
          if (Number(totalIssuedQty) > Number(totalRequiredQty) && props.isSo) {
            selectedRowKeysData.map((element, index) => (  
              locationWiseStockForm.setFieldsValue({['isChecked'+element] : 0})
            ))
            AlertMessages.getErrorMessage("Issued Quantity should be less than Required Quantity.");
          }
      }
      else{
        AlertMessages.getErrorMessage("Uncheck the rows with no input");
      }
      console.log(selectedRows);
      console.log(selectedRowKeys);
      console.log(selectedRowsData);
      console.log(selectedRowKeysData)
      console.log(props.stItems);
      props.stItems?.map((ele,index) => (
        ele.locationStockInf?.map((element,index1) => (
          selectedRows.find((rec) => rec.stockId === element.stockId) ? element.isChecked === "1" : "0"
        ))
      ))
      console.log(props.stItems);

    },
  }
  const TransferStock = (record,index) => {
    setRecord(record);
    setIndex(index);
    // setTransferBtn(true);
    setIsTransferModalVisible(true);
  }
  const StockTransferData = (stockTransferData, index) => {
    // console.log("props.stItems");
    // console.log(props.stItems);

    let issuedStockQty = 0;
    stockTransferData?.forEach(async element => {
      issuedStockQty = Number(issuedStockQty) + Number(element.issuedQty);
    });
    const obj = {...props.stItems[index],issuedQty:issuedStockQty,'locationStockInfo':stockTransferData}
    props.stItems.splice(index, 1, obj)
    // console.log(props.stItems)
  }

  const setEditForm = (rowData, index) => {

    console.log(rowData);
    
    if(rowData.availableQty === null){
      AlertMessages.getErrorMessage("Stock not available.")
    }
    else{
      console.log(rowData)
      if (rowData.saleOrderId) {
        props.form.setFieldsValue({
          saleOrderId: rowData.saleOrderId,
        });
      }

      setAvailableQty(rowData.availableQty);
      console.log(rowData)

      setItemEdit(true);
      console.log(rowData)

      props.form.setFieldsValue({
        itemCategory: rowData.itemCategory,
      });
      getItemSubCategories();
      props.form.setFieldsValue({
        itemSubCategory: rowData.itemSubCategory,
      });
      getItems();
      props.form.setFieldsValue({
        item: rowData.item
      });
    
      props.form.setFieldsValue({
        // size: rowData.size,
        requiredQty: rowData.requiredQty,
        issuedQty: rowData.issuedQty,
        index: index,
        unitPrice:rowData.unitPrice,
        locationStockInfo:rowData.locationStockInfo
        // locationStockInfo:rowData.locationStockInfo
      });
    }
    // getAvailableStockLocations()

  }
  
  // const renderItems = (record: any, index, indent, expanded) => {
  //   return <LocationWiseStockTransfer data={record} form={locationWiseStockForm} StockTransferData={StockTransferData} index = {index}/>
  // }


  const deleteData = (index) => {
    props.stItems.splice(index, 1);
    props.addItems(props.stItems);
  }

  const getItemSubCategories = () => {
    const itemCategoryId = JSON.parse(props.form.getFieldValue('itemCategory')).itemCategoryId;
    const saleOrderId = props.form.getFieldValue('saleOrderId');
    console.log(saleOrderId);
    if (itemCategoryId) {
      props.form.setFieldsValue({ itemSubCategory: '', item: '', size: '', availableQty: 0, issuedQty: 0 })
      setItemSubCategories([]);
      setItems([]);
      const req = new ItemCategoryIdReq(itemCategoryId, Number(localStorage.getItem('unit_id')), saleOrderId);
      skService.getAvailableStockItemSubCategories(req).then((res) => {
        if (res.status) {
          setItemSubCategories(res.data);
        } else {
          setItemSubCategories([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setItemSubCategories([]);
      });
    }
  }


  const getItems = () => {
    const itemCategoryId = JSON.parse(props.form.getFieldValue('itemCategory')).itemCategoryId;
    const itemSubCategoryId = JSON.parse(props.form.getFieldValue('itemSubCategory')).itemSubCategoryId;
    const saleOrderId = props.form.getFieldValue('saleOrderId');
    if (itemCategoryId && itemSubCategoryId) {
      props.form.setFieldsValue({ item: '', availableQty: 0, issuedQty: 0 })
      setItems([]);
      const req = new CategorySubCategoryReq(itemCategoryId, itemSubCategoryId, Number(localStorage.getItem('unit_id')), saleOrderId);
      skService.getAvailableStockTransferItems(req).then((res) => {
        if (res.status) {
          setItems(res.data);
        } else {
          setItems([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setItems([]);
      });
    }
  }

  const getAvailableStockLocations = () => {
    const itemCategoryId = JSON.parse(props.form.getFieldValue('itemCategory')).itemCategoryId;
    const itemSubCategoryId = JSON.parse(props.form.getFieldValue('itemSubCategory')).itemSubCategoryId;
    const itemId = JSON.parse(props.form.getFieldValue('item')).itemId;
    // const sizeId = JSON.parse(props.form.getFieldValue('size')).sizeId;
    const saleOrderId = props.form.getFieldValue('saleOrderId');
    
    if (itemCategoryId && itemSubCategoryId && itemCategoryId) {
      const req = new QtyRequest(itemCategoryId, itemSubCategoryId, itemId, 0, Number(localStorage.getItem('unit_id')), saleOrderId,props.isPreparedMaterialStatus?'yes':'no');
      console.log(req);
      skService.getAvailableStockLocations(req).then((res) => {
        if (res.status) {
          setLocationData(res.data);
          props.form.setFieldsValue({'locationStockInfo':res.data})
        } else {
          AlertMessages.getWarningMessage("Location Wise stock not found. ");
          setLocationData([]);
        }
      }).catch((err) => {
        setLocationData([]);
        AlertMessages.getErrorMessage(err.message);
      });
    }
  }

  const getAvailableQty = () => {
    const itemCategoryId = JSON.parse(props.form.getFieldValue('itemCategory')).itemCategoryId;
    const itemSubCategoryId = JSON.parse(props.form.getFieldValue('itemSubCategory')).itemSubCategoryId;
    const itemId = JSON.parse(props.form.getFieldValue('item')).itemId;
    // const sizeId = JSON.parse(props.form.getFieldValue('size')).sizeId;
    const saleOrderId = props.form.getFieldValue('saleOrderId');
    if (itemCategoryId && itemSubCategoryId && itemCategoryId) {
      props.form.setFieldsValue({ issuedQty: 0 })
      const req = new QtyRequest(itemCategoryId, itemSubCategoryId, itemId, 0, Number(localStorage.getItem('unit_id')), saleOrderId,props.isPreparedMaterialStatus?'yes':'no');
      skService.getAvailableTransferStockQty(req).then((res) => {
        if (res.status) {
          if(props.isPreparedMaterialStatus){
            setAvailableQty(Number(res.data.preparedQuantity));
            props.form.setFieldsValue({ issuedQty: Number(res.data.preparedQuantity), unitPrice: Number(res.data.unitPrice)})
          }
          else{
            setAvailableQty(Number(res.data.physicalQuantity));
            props.form.setFieldsValue({ issuedQty: Number(res.data.physicalQuantity), unitPrice: Number(res.data.unitPrice)})
          }
        } else {
          setAvailableQty(0);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setAvailableQty(0);
      });
    }
    getAvailableStockLocations()
  };

  const onAddProduct = () => {
    console.log(props.form.getFieldsValue());
    props.form.validateFields().then((formFields) => {
      console.log("formFields");
      console.log(formFields);

      formFields.availableQty = availableQty;
      const exists = props.stItems.find(item => ((item.item === formFields.item) && (item.size === formFields.size)));
      console.log(exists);
      console.log(itemEdit);

      if (itemEdit) {
        if (exists && exists.length > 1) {
          AlertMessages.getErrorMessage('Item already in list');
        } else {
          props.stItems.splice(formFields.index, 1, formFields)
          props.addItems(props.stItems);
        }
      } else {
        if (exists) {
          AlertMessages.getErrorMessage('Item already in list');
        } else {
          props.addItems([...props.stItems, formFields]);
        }
      }
      clearItems();
      setItemEdit(false);
      setAlternateKeyUpdate(alternateKeyUpdate + 1);
      setKeyUpdate(keyUpdate + 1);
    })
    // getAvailableStockLocations()
  }

  const clearItems = () => {
    props.form.resetFields();
    setAvailableQty(0);
  };

  const handleTransferModalOk = () => {
    setIsTransferModalVisible(false);
  };
  const handleTransferModalcancel = () => {
    setIsTransferModalVisible(false);
  };

  const expandedRow = row => {
    console.log(row);
    console.log(row.indentItemId);
    console.log(props.stItems?.find((res) => res.indentItemId === row.indentItemId)?.locationStockInfo);
    let inTable = (props.stItems?.find((res) => res.indentItemId === row.indentItemId)?.locationStockInfo)?.length > 0 ? props.stItems?.find((res) => res.indentItemId === row.indentItemId)?.locationStockInfo:''
    return <><br /><Table 
      rowSelection={{
        ...rowSelection
    }}
    rowKey={record => record.stockId}
    columns={tableColumns()} dataSource={inTable}  /></>;
  };
  const expandedRow1 = row => {
    console.log(row);
    console.log(row.itemId);
    console.log(props.stItems?.find((res) => res.itemId === row.itemId)?.locationStockInfo);
    let inTable = (props.stItems?.find((res) => res.itemId === row.itemId)?.locationStockInfo)?.length > 0 ? props.stItems?.find((res) => res.itemId === row.itemId)?.locationStockInfo:''
    return <><br /><Table 
      rowSelection={{
        ...rowSelection
    }}
    rowKey={record => record.stockId}
    columns={tableColumns()} dataSource={inTable}  /></>;
  };
  return (
    
    <Card size="small" title={<span style={{ color: 'white' }} >Stock Transfer Items</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
    {
      !props.isFormDisable ? 
        props.stItems.length ? 
          <><Row gutter={24}>
            <Table
              key={keyUpdate}
              rowKey={record => record.indentItemId}
              columns={indentColumns}
              dataSource={props.stItems}
              expandedRowRender={expandedRow}
              expandable = {{
                defaultExpandAllRows : true
                }}
              // expandedRowRender={renderItems}
              // expandedRowKeys={expandedIndex}
              // onExpand={setIndex}
              // expandIconColumnIndex={7}
              bordered
              pagination={false}
              style={{ width: '100%' }} />
          </Row></> : ''
      :
        <Form layout="vertical" form={props.form} name="control-hooks">
          <Row gutter={24}>
            <Form.Item name="packingStockTransferItemId" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="saleOrderId" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="unitPrice" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="index" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="unitId" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="locationStockInfo" >
              <Input hidden />
            </Form.Item>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}}>
              <Form.Item label="Item Category"
                name={'itemCategory'}
                rules={[{ required: true, message: 'Missing Item Category' }]}
              >
                <Select dropdownMatchSelectWidth={false} onSelect={getItemSubCategories} showSearch placeholder="Select Item Category" allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={props.isSo?true:false}
                >
                  {props.isSo?props.stItems.map(dropData=>{
                    return dropDrownInfo(dropData.itemCategory, JSON.parse(dropData.itemCategory).itemCategory)
                  }):props.itemCategories.map(dropData => {
                    return dropDrownInfo(JSON.stringify(dropData), dropData.itemCategory);
                  })}

                  
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}}>
              <Form.Item label="Item Sub Category"
                name={'itemSubCategory'}
                rules={[{ required: true, message: 'Missing Item Sub Category' }]}
              >
                <Select dropdownMatchSelectWidth={false} showSearch placeholder="Select Item Sub Category" allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onSelect={getItems}
                disabled={props.isSo?true:false}
                >
                  {props.isSo?props.stItems.map(dropData=>{
                    return dropDrownInfo(dropData.itemSubCategory, JSON.parse(dropData.itemSubCategory).itemSubCategory)
                  }):itemSubCategories.map(dropData => {
                    return dropDrownInfo(JSON.stringify(dropData), dropData.itemSubCategory)
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:34}}>
              <Form.Item label="Item Name"
                name={'item'}
                rules={[{ required: true, message: 'Missing Item Name' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Item"
                  allowClear
                  filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                  onSelect={getAvailableQty}
                  dropdownMatchSelectWidth={false}
                  disabled={props.isSo?true:false}
                >
                  {props.isSo?props.stItems.map(dropData=>{
                    return dropDrownInfo(dropData.item, JSON.parse(dropData.item).itemName)
                  }):items.map(dropData => {
                    return dropDrownInfo(JSON.stringify(dropData), dropData.itemName)
                  })}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={4}>
              <Form.Item label="Size"
                name={'size'}
                rules={[{ required: true, message: 'Missing Size' }]}
              >
                <Select
                  showSearch
                  placeholder="Select size"
                  allowClear
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSelect={getAvailableQty}
                  dropdownMatchSelectWidth={false}
                >
                  {sizes.map(dropData => {
                    return dropDrownInfo(JSON.stringify(dropData), dropData.sizeName)
                  })}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}} className={props.isSo ? 'showColumn' : 'hideColumn'}>
              <Form.Item
                name={'requiredQty'}
                label={"Required Qty"}
                rules={[{ required: props.isSo, message: 'Missing Required Qty' }]}
              >
                <InputNumber min={0}  style={{ width: '100%' }} readOnly={true}/>
              </Form.Item>
            </Col>
            
              <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
              <label><span style={{ color: 'red' }}>*</span>Issuing Qty: <span style={{ color: 'red' }}>Avl. Qty :{availableQty}</span></label>
              <br />
              <Form.Item
                name={'issuedQty'}
                rules={[{ required: true, message: 'Missing Issuing Qty' }, {
                  validator: (_, value) =>
                    value <= availableQty ? (value == 0) ? Promise.reject(new Error('Transferred Qty should be > 0')) : Promise.resolve() : Promise.reject(new Error('Quantity should be <= Available qty ' + availableQty)),
                }]}
              >
                <InputNumber min={0} max={availableQty} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col>
              <br />
              <br />
              <span>
                {!itemEdit ? <Tooltip placement="top" title='add'>
                  <PlusSquareOutlined className={'editSamplTypeIcon'} type="add"
                    onClick={() => {
                      onAddProduct();
                    }}
                    style={{ color: '#1890ff', fontSize: '20px' }}
                  />
                </Tooltip> : <Tooltip placement="top" title='edit'>
                  <EditOutlined className={'editSamplTypeIcon'} type="edit"
                    onClick={() => {
                      onAddProduct();
                    }}
                    style={{ color: '#1890ff', fontSize: '20px' }}
                  />
                </Tooltip>}

                <Divider type="vertical" />
                <Tooltip placement="top" title='clear'>
                  <ClearOutlined onClick={() => {
                    clearItems();
                  }}
                    style={{ color: 'red', fontSize: '20px' }} />
                </Tooltip>
              </span>
            </Col>
          </Row>

          {
            props.stItems.length ? <><Row gutter={24}>
              <Table
                key={keyUpdate}
                columns={columns}
                dataSource={props.stItems}
                expandedRowRender={expandedRow1}
                expandable = {{
                defaultExpandAllRows : true
                }}
                bordered
                pagination={false}
                style={{ width: '100%' }} />
            </Row>
            </> : ''
          }
        </Form>
    }
    <Modal width={'50%'} title="Location Wise Stock" visible={isTransferModalVisible} onOk={handleTransferModalOk} onCancel={handleTransferModalcancel}>
      <LocationWiseStockTransfer data={record} form={locationWiseStockForm} StockTransferData={StockTransferData} index = {index} closeModal = {handleTransferModalcancel}/>
    </Modal>
    </Card>
  );
}

export default PackingStockTransferItems;