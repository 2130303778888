export class GrnItemDefects {
    defectName: string;
    defectId: number;
    percentage: number;
    indentId: number;
    isNewItem: boolean;
    constructor(defectName: string, defectId: number, percentage: number, indentId: number, isNewItem: boolean) {
        this.defectName = defectName;
        this.defectId = defectId;
        this.percentage = percentage;
        this.indentId = indentId;
        this.isNewItem = isNewItem;
    }
}