import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoPackingStatusDto} from '@gtpl/shared-models/procurement-management';


export class SoPackingStatusModel extends GlobalResponseObject {
    data?: SoPackingStatusDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /BomPackingItemsDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SoPackingStatusDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 