export class AllIndentReportDto{
    indentId?: number;
    indentCode: string;
    indentDate: Date;
    harvestDate: Date;
    productCode?: string;
    expectedCount: number;
    expectedQty: number;
    expectedPrice: number;
    grnNumber?: number;
    grnId?: number;
    grnDate: Date;
    grnQuantity: number;
    grnCount: number;
    grnPrice: number;
    supplierType: string;

    constructor(
        indentId: number,
        indentCode: string,
        indentDate: Date,
        harvestDate: Date,
        productCode: string,
        expectedCount: number,
        expectedQty: number,
        expectedPrice: number,
        grnNumber: number,
        grnId: number,
        grnDate: Date,
        grnQuantity: number,
        grnCount: number,
        grnPrice: number, 
        supplierType: string, 
    ){
        this.indentId = indentId;
        this.indentCode = indentCode;
        this.indentDate = indentDate;
        this.harvestDate = harvestDate;
        this.productCode = productCode;
        this.expectedCount = expectedCount;
        this.expectedQty = expectedQty;
        this.expectedPrice = expectedPrice;
        this.grnNumber = grnNumber;
        this.grnId = grnId;
        this.grnDate = grnDate;
        this.grnQuantity = grnQuantity;
        this.grnCount = grnCount;
        this.grnPrice = grnPrice;
        this.supplierType = supplierType;
    }
}