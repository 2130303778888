import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GradingDashboardModel } from "./grading-dashboard.model";

export class GradingDashboardResponse extends GlobalResponseObject{
    data: GradingDashboardModel;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data: GradingDashboardModel){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}