import { CurrencyDto } from "../currency";
import { CusAddressInfo } from "../customers/address-model";
import { BrandsInfo } from "./brands-model";

export class EndCustomerDto {
    endCustomerId: number;
    endCustomerCode: string;
    endCustomerName: string;
    currencyId: number;
    isActive: boolean;
    createdUser: string;
    updatedUser: string;

    addressInfo:CusAddressInfo[];
    brandsInfo:BrandsInfo[];
    currenciesInfo:CurrencyDto;
    filePath: string;
    fileName: string;
    consignee?: string;

    constructor(endCustomerId:number,endCustomerCode:string,endCustomerName:string,currencyId: number,isActive:boolean,createdUser: string,
        updatedUser:string,addressInfo:CusAddressInfo[],brandsInfo:BrandsInfo[],currenciesInfo:CurrencyDto,filePath: string, fileName: string,consignee?:string){
        this.endCustomerId = endCustomerId;
        this.endCustomerCode = endCustomerCode;
        this.endCustomerName = endCustomerName;
        this.currencyId = currencyId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;

        this.isActive = isActive;
        this.addressInfo = addressInfo;
        this.brandsInfo = brandsInfo;
        this.currenciesInfo = currenciesInfo;
        this.filePath = filePath;
        this.fileName = fileName;
        this.consignee = consignee;
    }
}
