export class ValAddFourDropDownData{
    valueAdditionFourId:number;
    name:string;
    
    /**
     * 
     * @param valueAdditionFourId 
     * @param name 
     */
    constructor(valueAdditionFourId:number,name:string){
      this.valueAdditionFourId = valueAdditionFourId;
      this.name = name;
    }
}