import { LeaveApprovalStatusEnum } from "@gtpl/shared-models/common-models";

export class ApplyForLeavesRequest {
    applyForLeavesId: number;
    updatedUser?: string;
    versionFlag?: number;
    isActive?: boolean;
    status?: LeaveApprovalStatusEnum;
    rejectionReason?: string;
    /**
     * 
     * @param applyForLeavesId 
     * @param updatedUser 
     * @param versionFlag 
     * @param isActive 
     * @param status 
     */
    constructor(applyForLeavesId: number, updatedUser?: string, versionFlag?: number, isActive?: boolean, status?: LeaveApprovalStatusEnum,rejectionReason?: string) {
        this.applyForLeavesId = applyForLeavesId;
        this.isActive = isActive;
        this.status = status;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.rejectionReason = rejectionReason;
    }
}