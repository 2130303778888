import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { RmLevelDropDownDto } from './rm-level-drop-down..dto';




export class RmLevelDropDownResponseModel extends GlobalResponseObject {
    data?: RmLevelDropDownDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: RmLevelDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}

