import { useState, useEffect } from 'react';
import { Layout, Menu, Tooltip, Popover, Avatar, Input, Button, Tag, Empty, Result } from 'antd';
import { BrowserRouter as Router1, HashRouter as Router, Route, Link, Redirect, useHistory } from "react-router-dom";
import SubMenu from 'antd/lib/menu/SubMenu';
import './app.css';
import logo from './aquax.jpg';
import schemax22 from './schemax22.jpg';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeRolesEnum, UiConfig } from '@gtpl/shared-models/common-models';
import { UserOutlined, EyeOutlined, ProfileOutlined, AppstoreAddOutlined, DeleteOutlined, HistoryOutlined, PlusSquareOutlined, AppstoreOutlined, EnvironmentOutlined, LaptopOutlined, NotificationOutlined, DeliveredProcedureOutlined, DatabaseOutlined, CarOutlined, TeamOutlined, PieChartOutlined, MoneyCollectOutlined, DashboardOutlined, GoldOutlined, SelectOutlined, CarryOutOutlined, PicCenterOutlined, FileProtectOutlined, SnippetsOutlined, TableOutlined, EditOutlined, HomeOutlined, HomeFilled, FrownTwoTone, InsertRowLeftOutlined, FontColorsOutlined, UserDeleteOutlined, FormOutlined, SettingOutlined, SolutionOutlined, CalendarOutlined } from '@ant-design/icons';
import { StarOutlined, StarFilled, StarTwoTone, MenuFoldOutlined, ContainerOutlined, MenuUnfoldOutlined, ExportOutlined, GroupOutlined, FundProjectionScreenOutlined, FilePptOutlined, DropboxOutlined, FundViewOutlined, ReadOutlined, FileDoneOutlined, ScheduleOutlined, ImportOutlined, BookOutlined, DownloadOutlined, RiseOutlined, ExperimentTwoTone, MonitorOutlined, FileSyncOutlined, ExceptionOutlined, InteractionOutlined, InteractionFilled, WhatsAppOutlined, MessageOutlined } from '@ant-design/icons';
import { GrnForm } from '@gtpl/pages/procurement/packing-materials-components/grn-form';
import { GrnGrid, GrnPendingInfoGrid } from '@gtpl/pages/procurement/packing-materials-components/grn-grid';
import { LocationMappingForm } from '@gtpl/pages/procurement/packing-materials-components/location-mapping-form';
import { IndentFromForPreparedMaterial } from '@gtpl/pages/procurement/packing-materials-components/indent-from-for-prepared-material';
import { IndentGridForPreparedMaterial } from '@gtpl/pages/procurement/packing-materials-components/indent-grid-for-prepared-material';


import { CertificationsForm } from '@gtpl/pages/master/master-components/certifications-form';
import { CertificationsGrid } from '@gtpl/pages/master/master-components/certifications-grid';
import { SaleOrderProcessTypesForm } from '@gtpl/pages/master/master-components/sale-order-status-types-form';
import { SaleOrderProcessTypesGrid } from '@gtpl/pages/master/master-components/sale-order-status-types-grid';
import { PaymentModesGrid } from '@gtpl/pages/master/master-components/payment-modes-grid';
import { PaymentModesForm } from '@gtpl/pages/master/master-components/payment-modes-form';
import { SaleOrderTypesForm } from '@gtpl/pages/master/master-components/sale-order-types-form';
import { SaleOrderTypesGrid } from '@gtpl/pages/master/master-components/sale-order-types-grid';
import { UomsForm } from '@gtpl/pages/master/master-components/uoms-form';
import { UomsGrid } from '@gtpl/pages/master/master-components/uoms-grid';
import { PackingMehodsForm } from '@gtpl/pages/master/master-components/packing-methods-form';
import { PackingMethodsGrid } from '@gtpl/pages/master/master-components/packing-methods-grid';
import { DestinationForm } from '@gtpl/pages/master/master-components/destination-form';
import { DestinationGrid } from '@gtpl/pages/master/master-components/destination-grid';
import { EndCustomersForm } from '@gtpl/pages/master/master-components/end-customer-form';
import { EndCustomerGrid } from '@gtpl/pages/master/master-components/end-customer-grid'
import { ItemTypeForm } from '@gtpl/pages/master/master-components/item-type-form';
import { ItemTypeGrid } from '@gtpl/pages/master/master-components/item-type-grid';
import { ItrmTypePages } from '@gtpl/pages/master/master-pages/itrm-type-pages';
import { OTApply, OtReport, OverTimeApproval } from '@gtpl/pages/hrms/hrms-components/ot-apply';
import { TaxesGrid } from '@gtpl/pages/master/master-components/taxes-grid';
import { TaxesForm } from '@gtpl/pages/master/master-components/taxes-form';
import { CurrenciesForm } from '@gtpl/pages/master/master-components/currencies-form';
import { CurrenciesGrid } from '@gtpl/pages/master/master-components/currencies-grid';
import { CountriesForm } from '@gtpl/pages/master/master-components/countries-form';
import { CountriesGrid } from '@gtpl/pages/master/master-components/countries-grid';
import { ProductsForm } from '@gtpl/pages/master/master-components/products-form';
import { ProductsGrid } from '@gtpl/pages/master/master-components/products-grid';
import { DepartmentsGrid } from '@gtpl/pages/master/master-components/departments-grid';
import { DepartmentsForm } from '@gtpl/pages/master/master-components/departments-form';
import { EmployeeDetailView, HrmsEmployeeDetailsForm } from '@gtpl/pages/master/master-components/hrms-employee-details-form';
import { HrmsEmployeeDetailsGrid, HrmsUploadEmployeeDetails } from '@gtpl/pages/master/master-components/hrms-employee-details-grid';
import { TypeOfValueAddition1Form } from '@gtpl/pages/master/master-components/type-of-value-addition1-form';
import { TypeOfValueAddition1Grid } from '@gtpl/pages/master/master-components/type-of-value-addition1-grid';
import { GradeGrid } from '@gtpl/pages/master/master-components/grades-grid';
import { GradeForm } from '@gtpl/pages/master/master-components/grades-form';
import { TypeOfValueAddition2Grid } from '@gtpl/pages/master/master-components/type-of-value-addition2-grid';
import { TypeOfValueAddition2Form } from '@gtpl/pages/master/master-components/type-of-value-addition2-form';
import { TypeOfValueAddition3Grid } from '@gtpl/pages/master/master-components/type-of-value-addition3-grid';
import { TypeOfValueAddition3Form } from '@gtpl/pages/master/master-components/type-of-value-addition3-form';
import { TypeOfValueAddition4Form } from '@gtpl/pages/master/master-components/type-of-value-addition4-form';
import { TypeOfValueAddition4Grid } from '@gtpl/pages/master/master-components/type-of-value-addition4-grid';
import { SaleOrderDetailViewPages } from '@gtpl/pages/sale-management/sale-pages/sale-order-detail-view-pages';
import { SkuDetailView, SkuForm } from '@gtpl/pages/master/master-components/sku-form';
import { SkuGrid } from '@gtpl/pages/master/master-components/sku-grid';
import { HsnCodesForm } from '@gtpl/pages/master/master-components/hsn-codes-form';
import { HsnCodesGrid } from '@gtpl/pages/master/master-components/hsn-codes-grid';

import { SoDesignForm } from '@gtpl/pages/master/master-components/so-design-form';
import { PaymentTermsForm } from '@gtpl/pages/master/master-components/payment-terms-form';
import { PaymentTermsGrid } from '@gtpl/pages/master/master-components/payment-terms-grid';
import { CustomersGrid } from '@gtpl/pages/master/master-components/customers-grid';
import { CustomersForm } from '@gtpl/pages/master/master-components/customers-form';
import { SoDesignGrid } from '@gtpl/pages/master/master-components/so-design-grid';
import { TypeOfFoodGrid } from '@gtpl/pages/master/master-components/type-of-food-grid';
import { TypeOfFoodForm } from '@gtpl/pages/master/master-components/type-of-food-form';
import { SaleOrdersGrid } from '@gtpl/pages/master/master-components/sale-orders-grid';
import { SaleOrderTrackingReportPages } from '@gtpl/pages/sale-management/sale-pages/sale-order-tracking-report-pages';
import { StoreConsumptionReport, IndentReport, StoreReturnReport, PackingBomReport } from '@gtpl/pages/reports/reports-components/store-consumption-report';
import { DeliveryTermsGrid } from '@gtpl/pages/master/master-components/delivery-terms-grid';
import { DeliveryTermsForm } from '@gtpl/pages/master/master-components/delivery-terms-form';
import { ShippingTermsGrid } from '@gtpl/pages/master/master-components/shipping-terms-grid';
import { ShippingTermsForm } from '@gtpl/pages/master/master-components/shipping-terms-form';

import { ItemCategoriesForm } from '@gtpl/pages/master/master-components/item-categories-form';
import { ItemCategoriesGrid } from '@gtpl/pages/master/master-components/item-categories-grid';

import { ItemDamageReasonsForm } from '@gtpl/pages/master/master-components/item-damage-reasons-form';
import { ItemDamageReasonsGrid } from '@gtpl/pages/master/master-components/item-damage-reasons-grid';

import { VendorsForm } from '@gtpl/pages/master/master-components/vendors-form';
import { VendorsGrid } from '@gtpl/pages/master/master-components/vendors-grid';

import { ReasonsForm } from '@gtpl/pages/master/master-components/reasons-form';
import { ReasonsGrid } from '@gtpl/pages/master/master-components/reasons-grid';

import { UnitCodeForm } from '@gtpl/pages/master/master-components/unit-code-form';
import { UnitCodeGrid } from '@gtpl/pages/master/master-components/unit-code-grid'

import { ItemsForm } from '@gtpl/pages/master/master-components/items-form';
import { ItemsGrid } from '@gtpl/pages/master/master-components/items-grid';

import { PurchaseOrderForm } from '@gtpl/pages/procurement-masters/procurement-master-components/purchase-order-form';
import { PurchaseOrderGrid } from '@gtpl/pages/procurement-masters/procurement-master-components/purchase-order-grid';

import { StoreConsumptionForm, StoreIssuesForm } from '@gtpl/pages/procurement/packing-materials-components/store-consumption-form';
import { PlaceListPrint, StoreConsumptionGrid } from '@gtpl/pages/procurement/packing-materials-components/store-consumption-grid';
import { MaterialPreperationGrid } from '@gtpl/pages/procurement/packing-materials-components/material-preperation-grid'
import { MaterialPreperationForm } from '@gtpl/pages/procurement/packing-materials-components/material-preperation-form'
import { StoreConsumptionDetailView } from '@gtpl/pages/procurement/packing-materials-components/store-consumption-detail-view';

import { GrnRejectionReasonsForm } from '@gtpl/pages/master/master-components/grn-rejection-reasons-form';
import { GrnRejectionReasonsGrid } from '@gtpl/pages/master/master-components/grn-rejection-reasons-grid';

import { SizesForm } from '@gtpl/pages/master/master-components/sizes-form';
import { SizesGrid } from '@gtpl/pages/master/master-components/sizes-grid';

import { HatcheriesForm } from '@gtpl/pages/master/master-components/hatcheries-form';
import { HlPercentagesForm } from '@gtpl/pages/master/master-components/hl-percentages-form';
import { HatcheriesGrid } from '@gtpl/pages/master/master-components/hatcheries-grid';
import { HlPercentagesGrid } from '@gtpl/pages/master/master-components/hl-percentages-grid';
import { SuppliersGrid } from '@gtpl/pages/master/master-components/suppliers-grid';
import { SuppliersForm } from '@gtpl/pages/master/master-components/suppliers-form';

import { RolesForm } from '@gtpl/pages/master/master-components/roles-form';
import { RolesGrid } from '@gtpl/pages/master/master-components/roles-grid'
import { TypeOfValueAddition5Form } from '@gtpl/pages/master/master-components/type-of-value-addition5-form';
import { TypeOfValueAddition5Grid } from '@gtpl/pages/master/master-components/type-of-value-addition5-grid';

import { GrnDetailviewGrid } from '@gtpl/pages/procurement/packing-materials-components/grn-detailview-grid';

import { BomParameterGrid } from '@gtpl/pages/sale-management/sale-components/bom-parameter-grid';
import { BomParameter } from '@gtpl/pages/sale-management/sale-pages/bom-parameter';
import { BomParameterDetailView } from '@gtpl/pages/sale-management/sale-components/bom-parameter-grid';

import { StoreReturnPages } from '@gtpl/pages/procurement/packing-materials-pages/store-return-pages';
import { StoreReturnGrid, StoreReturnDetailView } from '@gtpl/pages/procurement/packing-materials-components/store-return-grid';
import { StoreReturnForm } from '@gtpl/pages/procurement/packing-materials-components/store-return-form';
import { PoDetailviewGrid } from '@gtpl/pages/procurement-masters/procurement-master-components/po-detailview-grid';
import { PoDetailViewPages, PoPrint } from '@gtpl/pages/procurement-masters/procurement-master-pages/po-detail-view-pages';
import { VendorPriceListGrid } from '@gtpl/pages/master/master-components/vehicle-price-list-grid';

import { VendorPriceListForm } from '@gtpl/pages/master/master-components/vehicle-price-list-form';
import { TransferPrint } from "@gtpl/pages/procurement/packing-materials-components/transfer-print";
import { GrnReturnForm } from '@gtpl/pages/procurement/packing-materials-components/grn-return-form';
import { GrnReturnGrid } from "@gtpl/pages/procurement/packing-materials-components/grn-return-grid";
import { GrnReturnDetailView } from "@gtpl/pages/procurement/packing-materials-components/grn-return-grid";
import { PackingStockTransfer, PackingStockEntry, PackingProductConversion, CycleChecker, CycleCheckerApproval, WhatsAppBroadCast } from "@gtpl/pages/procurement/packing-materials-components/stock-transfer";
import { ReportingForm } from '@gtpl/pages/warehouse-management/warehouse-components/reporting-form';
import { StockIn, StockInForm, StockOutVerification, StockPlacementVerification } from '@gtpl/pages/warehouse-management/warehouse-components/stock-in-form';
import { ShipmentStatusReport, StockGrid, StockInReverseReport, StockOutTransaction, StockLocationUpdate } from '@gtpl/pages/warehouse-management/warehouse-components/stock-grid';
import { StockReportWarehouse, StockInTransactionsReport, DummyPackingReport } from '@gtpl/pages/warehouse-management/warehouse-components/stock-grid';
import { GeneratePickList, StockInReverse } from '@gtpl/pages/warehouse-management/warehouse-components/stock-out';
import { EndCustomerSoReport, SaleOrderReportGrid, SaleDetailsReport, SaleOrderAbstractReport } from '@gtpl/pages/reports/reports-components/sale-order-report-grid';
import { PackingStockReport, PurchaseOrderReport, SoPackingStatus, SupplyPerformanceReport } from '@gtpl/pages/reports/reports-components/purchase-order-report';
import { StockReport } from '@gtpl/pages/reports/reports-components/stock-report';
import { GrnReportGrid } from '@gtpl/pages/reports/reports-components/grn-report';
import { ExcessInventoryReport } from '@gtpl/pages/reports/reports-components/excess-inventory-report';
import { GrnAbstractReportGrid } from '@gtpl/pages/reports/reports-components/grn-report';
import { FactoryHeadDashboardGrid, ModuleWiseDashboard } from '@gtpl/pages/dashboard/dashboard-components/factory-head-dashboard-grid';
import { LevelGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/level-grid';
import { LevelForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/level-form';
import { PalletGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/pallet-grid';
import { PalletForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/pallet-form';
import { ColumnsGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/columns-grid';
import { ColumnsForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/columns-form';
import { RackGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-grid'
import { RackForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-form'
import { RacksMasterGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/racks-master-grid';
import { RacksMasterForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/racks-master-form'
import { ZoneGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/zone-grid'
import { ZoneForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/zone-form';
import { RackEndcustomerMappingForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-endcustomer-mapping-form';
import { RackEndcustomerMappingGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-endcustomer-mapping-grid';
import { ContainerRegisterGrid } from '@gtpl/pages/logistics/logistics-components/container-register-grid';
import { ContainerReport } from '@gtpl/pages/reports/reports-components/container-report';
import { Invoice } from '@gtpl/pages/logistics/logistics-components/invoice';
import { InvoiceGrid } from '@gtpl/pages/logistics/logistics-components/invoice-grid';
import { LogisticsInvoiceDetailView } from '@gtpl/pages/logistics/logistics-components/invoice-detail-view';
import { RmProcurementReportGrid } from '@gtpl/pages/reports/reports-components/rm-procurement-report-grid';

import { BookContainer, ContainerRegister, DispatchToPlant, AssignSo, FillSealInfo } from '@gtpl/pages/logistics/logistics-components/container-register';

import { LocationsGrid } from '@gtpl/pages/warehouse-masters/warehouse-master-component/locations-grid';
import { LocationsForm } from '@gtpl/pages/warehouse-masters/warehouse-master-component/locations-form';

import { BlStatusGrid } from '@gtpl/pages/logistics/logistics-components/bl-status-grid';

import { MasterBrandsForm } from '@gtpl/pages/master/master-components/master-brands-form';
import { MasterBrandsGrid } from '@gtpl/pages/master/master-components/master-brands-grid';
import { CommonInvoice, CusInvoice, CommonPackingList, ShippingInstructions, PackingList, CommercialPackingList, CommercialInvoice, InsuranceUpload, FreigstInvoice, TaxInvoice, PlantInvoice, PlantPackingList, DocumentRegister, IsfWorksheet, DocumentUploadForm } from '@gtpl/pages/logistics/logistics-components/documents';

import { StockView } from '@gtpl/pages/procurement/packing-materials-components/stock-view';

import { FgDamagesForm } from '@gtpl/pages/warehouse-management/warehouse-components/fg-damages-form';
import { FgDamagesGrid } from '@gtpl/pages/warehouse-management/warehouse-components/fg-damages-grid';

import { WarehouseDashboard } from '@gtpl/pages/warehouse-management/warehouse-components/warehouse-dashboard';


import { RecruitmentDashboard } from '@gtpl/pages/dashboard/dashboard-components/recruitment-dashboard';
import { DocumentsApprovalPages } from '@gtpl/pages/logistics/logistics-components/documents-approval-pages';
import { DocumentsApprovalForm } from '@gtpl/pages/logistics/logistics-components/documents-approval-form';

import { UserGrid } from '@gtpl/pages/user-management/user-components/user-grid';
import { UserForm } from '@gtpl/pages/user-management/user-components/user-form';
import { UserPages } from '@gtpl/pages/user-management/user-management-pages/user-pages';

import { SoInspectionGrid } from '@gtpl/pages/inspection/inspection-components/so-inspection-grid';

import { RawMaterialGrnForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-form';
import { RawMaterialGrnGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-grid';
import { RawMaterialGrnDetailView } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-detail-view'
// indent form
import { IndentForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-form';
import { IndentGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-grid';

import { IndentDetailView, IndentDetailViewPages } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-detail-view';
import { VehicleAssignmentGrid } from '@gtpl/pages/transportation/transportation-components/vehicle-assignment-grid';
import { VehicleAssignmentForm } from '@gtpl/pages/transportation/transportation-components/vehicle-assignment-form';
import { BomTrackerGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/bom-tracker-grid'
import { ProcurementDashboard } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/procurement-dashboard';
import { VehicleMasterForm } from '@gtpl/pages/master/master-components/vehicle-master-form';
import { VehicleMasterGrid } from '@gtpl/pages/master/master-components/vehicle-master-grid';
import { RmProcurementDashboard } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-procurement-dashboard';
import { RmDashboard } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-dashboard';
import { OperationsForm } from '@gtpl/pages/production-management/production-management-components/operations-form';
import { OperationsGrid } from '@gtpl/pages/production-management/production-management-components/operations-grid';
import { WorkstationCategoryForm } from '@gtpl/pages/production-management/production-management-components/workstation-category-form';
import { WorkstationCategoryGrid } from '@gtpl/pages/production-management/production-management-components/workstation-category-grid';
import { WorkstationsForm } from '@gtpl/pages/production-management/production-management-components/workstations-form';
import { QaQcGrid } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-grid';
import { QaQcOperations } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-operations';
import { InspectionForm } from '@gtpl/pages/qa-qc/qa-qc-components/product-inspection-form';
import { InspectionPrint } from '@gtpl/pages/qa-qc/qa-qc-components/product-inspection-print';
import { PdQaqcForm } from '@gtpl/pages/production-management/production-management-components/peeling-preparation-form';
import { PdQaqcPrint } from '@gtpl/pages/production-management/production-management-components/peeling-preparation-print';

import { MetalDetection } from '@gtpl/pages/qa-qc/qa-qc-components/metal-detection';
import { MetalDetectionForm } from '@gtpl/pages/qa-qc/qa-qc-components/metal-detection-form';

import { VariantOperationsForm } from '@gtpl/pages/production-management/production-management-components/variant-operations-form';
import { WorkstationsGrid } from '@gtpl/pages/production-management/production-management-components/workstations-grid';
import { BeHeading } from '@gtpl/pages/production-management/production-management-components/assign-rm-inventory';
import { AssignGradeToValAddition } from '@gtpl/pages/production-management/production-management-components/assign-grade-to-val-addition';
import { AssignSoakingMaterial } from '@gtpl/pages/production-management/production-management-components/assign-soaking-material';
import { SoakingReporting } from '@gtpl/pages/production-management/production-management-components/soaking-reporting';
import { SoakingDashboard, SoakingTubBulbIntegration } from '@gtpl/pages/production-management/production-management-components/soaking-dashboard';
import { SoakingMaterialGrid } from '@gtpl/pages/production-management/production-management-components/soaking-material-grid';
import { SoakingMaterialDetailView } from '@gtpl/pages/production-management/production-management-components/soaking-material-detail-view';
import { ValAdditionInventoryGrid } from '@gtpl/pages/production-management/production-management-components/val-addition-inventory-grid';
import { ValAdditionSoakingGrid } from '@gtpl/pages/production-management/production-management-components/val-addition-soaking-grid';
import { ValAdditionGradingDashboard } from '@gtpl/pages/production-management/production-management-components/val-addition-grading-dashboard';
import { AssignSoGrading } from '@gtpl/pages/production-management/production-management-components/assign-so-grading';
import { GradingInventoryGrid } from '@gtpl/pages/production-management/production-management-components/grading-inventory-grid';
import { GradingDashboard } from '@gtpl/pages/production-management/production-management-components/grading-dashboard';
import { GradeReporting } from '@gtpl/pages/production-management/production-management-components/grade-reporting';
import { AssignBeheadingToGrading } from '@gtpl/pages/production-management/production-management-components/assign-beheading-to-grading';
import { YieldReport } from '@gtpl/pages/production-management/production-management-components/yield-report';
import { BeheadingDashboard } from '@gtpl/pages/production-management/production-management-components/beheading-dashboard';
import { SubPlantValAdditionsReport } from '@gtpl/pages/production-management/production-management-components/sub-plant-val-additions-report';
import { ProductionInventoryGrid } from '@gtpl/pages/production-management/production-management-components/production-inventory-grid';

import { BeheadingInventoryGrid } from '@gtpl/pages/production-management/production-management-components/beheading-inventory-grid';
import { BeheadingSubPlantHistory } from '@gtpl/pages/production-management/production-management-components/beheading-sub-plant-history'
import { SoakingMaterialInventory } from '@gtpl/pages/production-management/production-management-components/soaking-material-inventory';
import { SubPlantOperationsGrid } from '@gtpl/pages/production-management/production-management-components/sub-plant-operations-grid';

import { AssignSoakingToFreezeGrid } from '@gtpl/pages/production-management/production-management-components/assign-soaking-to-freeze-grid';
import { ReportSubPlantOperations } from '@gtpl/pages/production-management/production-management-components/report-sub-plant-operations';
import { ReceiveSubPlantsStockNew } from '@gtpl/pages/production-management/production-management-components/receive-sub-plants-stock';
import { AssignForFreezingForm } from '@gtpl/pages/production-management/production-management-components/assign-for-freezing-form';
import { BeheadingInprogressGrid } from '@gtpl/pages/production-management/production-management-components/beheading-inprogress-grid';
import { OperationsRegister } from '@gtpl/pages/reports/reports-components/operations-register';
import { CookingGrid } from '@gtpl/pages/production-management/production-management-components/cooking-grid';

import { RejectionsGrid } from '@gtpl/pages/production-management/production-management-components/rejections-grid';

import { ProductionInventoryTransfer } from '@gtpl/pages/production-management/production-management-components/production-inventory-transfer';

// import { RejectionsGrid } from '@gtpl/pages/production-management/production-management-components/rejections-grid';
import { LotTrackingReport } from '@gtpl/pages/reports/reports-components/lot-tracking-report';
import { FgInspectionForm } from '@gtpl/pages/qa-qc/qa-qc-components/fg-inspection-form';
import { FgInspectionPrint } from '@gtpl/pages/qa-qc/qa-qc-components/fg-inspection-print';
import { QaQcSampleTest } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-sample-test'
import { QaQcSampleTestGrid } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-sample-test-grid'
import { QaQcSampleDetailView } from '@gtpl/pages/qa-qc/qa-qc-components/qa-qc-sample-detail-view'
import { PackingDashboard } from '@gtpl/pages/procurement/packing-materials-components/packing-dashboard';
import { PackingBOM } from '@gtpl/pages/procurement/packing-materials-components/packing-dashboard';
import { QualificationsForm } from '@gtpl/pages/hrms/hrms-components/qualifications';
import { QualificationsGrid } from '@gtpl/pages/hrms/hrms-components/qualifications-grid';
import { RecruitmentForm } from '@gtpl/pages/hrms/hrms-components/recruitment-mode-form';
import { RecruitmentGrid, RecruitmentReportGrid } from '@gtpl/pages/hrms/hrms-components/recruitment-mode';
import { DesignationActionForm } from '@gtpl/pages/hrms/hrms-components/designation-action-form';
import { DesignationActionGrid } from '@gtpl/pages/hrms/hrms-components/designation-action-grid'
import { PlantOperationCapacityForm } from '@gtpl/pages/master/master-components/plant-operation-capacity-form';
import { PlantOperationCapacityGrid } from '@gtpl/pages/master/master-components/plant-operation-capacity-grid';
import { ExpensesCategory } from '@gtpl/pages/hrms/hrms-components/expenses-category';
import { ExpensesCategoryGrid } from '@gtpl/pages/hrms/hrms-components/expenses-category-grid';
import { ExpensesSubCategory } from '@gtpl/pages/hrms/hrms-components/expenses-sub-category';
import { ExpensesSubCategoryGrid } from '@gtpl/pages/hrms/hrms-components/expenses-sub-category-grid';
import { Skills } from '@gtpl/pages/hrms/hrms-components/skills';
import { SkillsGrid } from '@gtpl/pages/hrms/hrms-components/skills-grid';
import { Designation } from '@gtpl/pages/hrms/hrms-components/designation';
import { DesignationGrid } from '@gtpl/pages/hrms/hrms-components/designation-grid';
import { ExpensesForm } from '@gtpl/pages/hrms/hrms-components/expenses';
import { ExpensesGrid } from '@gtpl/pages/hrms/hrms-components/expenses-grid';
import { LanguagesForm } from '@gtpl/pages/hrms/hrms-components/languages';
import { LanguagesGrid } from '@gtpl/pages/hrms/hrms-components/languages-grid';
import { TransferActionForm } from '@gtpl/pages/hrms/hrms-components/transfer-action-form';
import { TransferActionGrid } from '@gtpl/pages/hrms/hrms-components/transfer-action-grid';
import { HolidaysCalender } from '@gtpl/pages/hrms/hrms-components/holidays-calender';
import { HolidaysCalenderGrid } from '@gtpl/pages/hrms/hrms-components/holidays-calender-grid';
import { EmploymentStatusForm } from '@gtpl/pages/hrms/hrms-components/employment-status-form';
import { EmploymentStatusGrid } from '@gtpl/pages/hrms/hrms-components/employment-status-grid';
import { ProfilesForm } from '@gtpl/pages/hrms/hrms-components/profiles-form';
import { ProfilesGrid } from '@gtpl/pages/hrms/hrms-components/profiles-grid';
import { NotificationsForm } from '@gtpl/pages/hrms/hrms-components/notifications-form';
import { NotificationsGrid } from '@gtpl/pages/hrms/hrms-components/notifications-grid';
import { MiscellaneousLogs, MiscellaneousLogsReport } from '@gtpl/pages/hrms/hrms-components/miscellaneous-logs';
import { AttendanceAdjustment, AttendanceAdjustmentApproval, CreateAttendanceLogs } from '@gtpl/pages/hrms/hrms-components/attendance-adjustment';
import { ActivitiesForm } from '@gtpl/pages/hrms/hrms-components/activities-form';
import { ActivitiesGrid } from '@gtpl/pages/hrms/hrms-components/activities-grid';
import { SelfServicePortal, NMenu, LeaveBalance, Attandance, otDetails } from '@gtpl/pages/hrms/hrms-components/self-service-portal';
import { InterviewForm } from '@gtpl/pages/hrms/hrms-components/schedule-interview-form';
import { InterviewGrid } from '@gtpl/pages/hrms/hrms-components/interview-grid';
import { CodeListForm } from '@gtpl/pages/planning/planning-components/code-list-form';
import { CodeListGrid, CodeListPrint } from '@gtpl/pages/planning/planning-components/code-list-grid';
import { ResourceOperationEfficiencyGrid } from '@gtpl/pages/planning/planning-components/resource-operation-efficiency-grid'
import { ResourceOperationEfficiencyForm } from '@gtpl/pages/planning/planning-components/resource-operation-efficiency-form'
import { PlantCapacityForm } from '@gtpl/pages/planning/planning-components/plant-capacity-form'
import { DowntimeReasonForm } from '@gtpl/pages/master/master-components/downtime-reason-form';
import { DowntimeReasonGrid } from '@gtpl/pages/master/master-components/downtime-reason-grid';
import { ItemSubCategoriesForm } from '@gtpl/pages/master/master-components/item-sub-categories-form';
import { ItemSubCategoriesGrid } from '@gtpl/pages/master/master-components/item-sub-categories-grid';
import { IdProofs } from '@gtpl/pages/hrms/hrms-components/id-proofs';
import { IdProofsGrid } from '@gtpl/pages/hrms/hrms-components/id-proofs-grid';
import { Relations } from '@gtpl/pages/hrms/hrms-components/relations';
import { RelationsGrid } from '@gtpl/pages/hrms/hrms-components/relations-grid';
import { LoanCategoriesGrid } from '@gtpl/pages/hrms/hrms-components/loan-categories-grid';
import { LoanCategories } from '@gtpl/pages/hrms/hrms-components/loan-categories';
import { OverTimeCategoryGrid } from '@gtpl/pages/hrms/hrms-components/over-time-category-grid';
import { OverTimeCategory } from '@gtpl/pages/hrms/hrms-components/over-time-category';
import { Suggestion } from '@gtpl/pages/hrms/hrms-components/suggestion';
import { SuggestionGrid } from '@gtpl/pages/hrms/hrms-components/suggestion-grid';
import { ComplaintsGrid } from '@gtpl/pages/hrms/hrms-components/complaints-grid';
import { Complaints } from '@gtpl/pages/hrms/hrms-components/complaints';
import { StockOut } from '@gtpl/pages/warehouse-management/warehouse-components/stock-out';
import { FgStockTransferGrid } from '@gtpl/pages/warehouse-management/warehouse-management-components/fg-stock-transfer-grid';
import { FgStockTransfer } from '@gtpl/pages/warehouse-management/warehouse-management-components/fg-stock-transfer';
import { SoInfoView } from '@gtpl/pages/sale-management/sale-pages/so-info-view';
import { SoInfoViewPages } from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages';
import { dispatchlistgrid } from '@gtpl/pages/warehouse-management/warehouse-components/dispatch-list-grid';
import { StockTransferGrid } from '@gtpl/pages/procurement/packing-materials-components/stock-transfer-grid';
import { StockTransferDetailView } from '@gtpl/pages/procurement/packing-materials-components/stock-transfer-detail-view';
import { CaptureGrossWeightForm } from '@gtpl/pages/logistics/logistics-components/capture-gross-weight-form';
import { RmVehicleInspection } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-vehicle-inspection';
import { LogBookGrid } from '@gtpl/pages/logistics/logistics-components/log-book-grid';
import { ExitActionForm } from '@gtpl/pages/hrms/hrms-components/exit-action-form';
import { ExitActionGrid } from '@gtpl/pages/hrms/hrms-components/exit-action-grid';
import { TypesOfLeaves } from '../../../../../libs/pages/hrms/hrms-components/types-of-leaves/src';
import { TypesOfLeavesGrid } from '../../../../../libs/pages/hrms/hrms-components/types-of-leaves-grid/src'
import { EmployeeLeavesGrid } from '@gtpl/pages/hrms/hrms-components/employee-leaves-grid'
import { JobNotificationsForm } from '@gtpl/pages/hrms/hrms-components/job-notifications-form';
import { JobNotificationsGrid } from '@gtpl/pages/hrms/hrms-components/job-notifications-grid';
import { Education } from '@gtpl/pages/hrms/hrms-components/education';
import { EducationGrid } from '@gtpl/pages/hrms/hrms-components/education-grid';
import { Certification } from '@gtpl/pages/hrms/hrms-components/certification';
import { CertificationGrid } from '@gtpl/pages/hrms/hrms-components/certification-grid';
import { ReprocessingRepackingGrid } from '@gtpl/pages/warehouse-management/warehouse-components/reprocessing-repacking-grid';
import { ReserveRackPositions } from '@gtpl/pages/warehouse-management/warehouse-components/reserve-rack-positions';
import { ApplyForLeavesForm } from '@gtpl/pages/hrms/hrms-components/apply-for-leaves-form'
import { ApplyForLeavesGrid } from '@gtpl/pages/hrms/hrms-components/apply-for-leaves-grid';
import { HolidayCalander, LeaveApprovalGrid } from '@gtpl/pages/hrms/hrms-components/leave-approval-grid'
import { LabellingForm } from '@gtpl/pages/qa-qc/qa-qc-components/labelling-form';
import { LabellingGrid } from '@gtpl/pages/qa-qc/qa-qc-components/labelling-grid';
import { QcDefectDetailsForm } from '@gtpl/pages/production-management/production-management-components/qc-defect-details-form';
import { QcDefectDetailsGrid } from '@gtpl/pages/production-management/production-management-components/qc-defect-details-grid';
import { BlanchingCookingForm } from '@gtpl/pages/production-management/production-management-components/blanching-cooking-form';
import { CookingForm } from '@gtpl/pages/production-management/production-management-components/assign-cooking-form';
import { ShippmentDetailsForm, ShippmentDetailsReceivedTabForm } from '@gtpl/pages/logistics/logistics-components/shippment-details-form';
import { InsuranceForm } from '@gtpl/pages/master/master-components/insurance-form'
import { InsuranceGrid } from '@gtpl/pages/master/master-components/insurance-grid'
import ScrollNumber from 'antd/lib/badge/ScrollNumber';
import { FgStockSummaryReport, FgStockReport, LocationsHistoryReport, FgStockTransferReport } from '@gtpl/pages/warehouse-management/warehouse-management-components/fg-stock-summary-report';
import { RmToleranceGrid } from '@gtpl/pages/master/master-components/rm-tolerance-grid';
import { RmToleranceForm } from '@gtpl/pages/master/master-components/rm-tolerance-form';
import { RmIndentQtyLimitGrid } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-indent-qty-limit-grid';
import { RmIndentQtyLimit } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-indent-qty-limit';
import { GradeSuggestionFrom } from '@gtpl/pages/master/master-components/grade-suggestion-from';
import { PalletCapacityGrid } from '@gtpl/pages/master/master-components/pallet-capacity-grid';
import { PalletCapacityForm } from '@gtpl/pages/master/master-components/pallet-capacity-form';
import { TrimsGrid } from '@gtpl/pages/master/master-components/shrimps-grid';
import { TrimsMappingGrid } from '@gtpl/pages/master/master-components/trims-mapping-grid';
import { TrimsForm } from '@gtpl/pages/master/master-components/shrimps-form';
import { CodeListMainForm } from '@gtpl/pages/planning/planning-components/code-list-main-form';
import { CodeListMainGrid } from '@gtpl/pages/planning/planning-components/code-list-main-grid';
import { CodeListDetailView } from '@gtpl/pages/planning/planning-components/code-list-main-grid';
import { NotifyPartyGrid } from '@gtpl/pages/master/master-components/notify-party-grid';
import { NotifyPartyForm } from '@gtpl/pages/master/master-components/notify-party-form';
import { TypesForm } from '@gtpl/pages/master/master-components/types-form';
import { TypesGrid } from '@gtpl/pages/master/master-components/types-grid';
import { SpecSheetGrid } from '@gtpl/pages/master/master-components/spec-sheet-grid';
import { SpecSheetForm } from '@gtpl/pages/master/master-components/spec-sheet-form';
import { DownTimeReport, DownTimeTrackingGrid, DownTimeEventCalender } from '@gtpl/pages/master/master-components/down-time-tracking-grid';
import { DownTimeTrackingForm, NewDowntimeTrackingform } from '@gtpl/pages/master/master-components/down-time-tracking-form';
import { faArrowUpShortWide, faBuildingCircleArrowRight, faCartShopping, faCertificate, faCode, faCommentSlash, faDiceFive, faDiceFour, faDiceOne, faDiceThree, faDiceTwo, faFileCircleQuestion, faIdBadge, faLocationPin, faMoneyBillWaveAlt, faNetworkWired, faNewspaper, faPersonCircleCheck, faRuler, faShrimp, faTemperatureHalf, faUsers, faUsersRays, faGraduationCap, faUsersGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faArrowRightToBracket, faArrowsRotate, faBook, faBoxesStacked, faBoxOpen, faCartFlatbedSuitcase, faColumns, faFile, faFileCircleCheck, faFileInvoice, faFileLines, faPallet, faSnowflake, faTruckFast, faTruckLoading, faTruckPickup, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { CurrentTemp, TemperatureLogGrid } from '@gtpl/pages/dashboard/dashboard-components/temperature-log';
import DayWiseTemp from 'libs/pages/dashboard/dashboard-components/temperature-log/src/lib/day-wise-temp';
import { SubJobRevision } from '@gtpl/pages/sale-management/sale-components/sub-job-revision';
import { TemperatureLogForm } from '@gtpl/pages/dashboard/dashboard-components/temperature-log'
import { CurrentDayStockOut, CurrentDayStockOutPos, CurrentDayStockTransfer, ExpectedStockInAndOut, LongRunningDummyStock, ProductionPercentage, RepackingEfficiency, WayRackStock, WhAvailability, WhDashboard, WhTrendsGraph } from '@gtpl/pages/analytics/analytics-components/warehouse-dashboard';
import { AttendanceDashboard } from '@gtpl/pages/dashboard/dashboard-components/attendance-dashboard';
import { UserDashboard } from '@gtpl/pages/dashboard/dashboard-components/user-dashboard';
import { LotCodeDashboard } from '@gtpl/pages/dashboard/dashboard-components/lot-code-dashboard';
import { PackingDashboards } from '@gtpl/pages/dashboard/dashboard-components/packing-dashboard';
import { ContainerLoadingReport } from '@gtpl/pages/logistics/logistics-components/container-loading-report';
import { DownTimePieChart } from '@gtpl/pages/production-management/production-management-components/down-time-dashboard';
import { ContainerLogBookForm } from '@gtpl/pages/logistics/logistics-components/container-log-book-form'
import { ContainerLogBookGrid } from '@gtpl/pages/logistics/logistics-components/container-log-book-form'
import { ContractorGrid } from '@gtpl/pages/hrms/hrms-components/human-resource-agent-grid';
import { ContractorForm } from '@gtpl/pages/hrms/hrms-components/human-resource-agent-form';
import { MonthwiseReportGrid } from '@gtpl/pages/hrms/hrms-components/monthwise-report-grid';
import { EmployeesShiftMapping } from '@gtpl/pages/hrms/hrms-components/employee-shift-mapping';
import { LogisticLifeCycle, LogisticsDashboard } from '@gtpl/pages/logistics/logistics-components/logistics-dashboard';
import React from 'react';
import { TeamGrid } from '@gtpl/pages/hrms/hrms-components/teams-grid';
import { TeamForm } from '@gtpl/pages/hrms/hrms-components/teams-form';
import { ShiftGrid } from '@gtpl/pages/hrms/hrms-components/shifts-grid';
import { ShiftForm } from '@gtpl/pages/hrms/hrms-components/shifts';
import { AssetActivityControllerForm } from '@gtpl/shared-components/asset-activity-controller';
import { AssetActivityControllerGrid } from '@gtpl/shared-components/asset-activity-controller';
// import { EmployeeTeamGrid } from '@gtpl/pages/hrms/hrms-components/employee-team-grid';
// import { EmployeeTeamForm } from '@gtpl/pages/hrms/hrms-components/employee-team-form';
import { TeamCalenderGrid } from 'libs/pages/hrms/hrms-components/team-calender-grid/src';
import { TeamCalender } from 'libs/pages/hrms/hrms-components/team-calender-form/src';
import { EmpMonthwiseAttendanceReport } from '@gtpl/pages/hrms/hrms-components/emp-monthwise-attendance-report';
//import { SoakingMainDashboard } from '@gtpl/pages/production-management/production-management-components/soaking-main-dashboard';
import { TransferredSaleordersReport } from '@gtpl/pages/reports/reports-components/transferred-saleorders-report';
import { WorkCalenderGrid } from '@gtpl/pages/hrms/hrms-components/work-calender-grid';
import { WorkCalender } from '@gtpl/pages/hrms/hrms-components/work-calender-form';
// import {EmpMonthwiseAttendanceReport} from './lib/emp-monthwise-attendance-report';
import { SoakingTubReport } from '@gtpl/pages/production-management/production-management-components/soaking-tub-report'
import { SoakingMainDashboard } from '@gtpl/pages/production-management/production-management-components/soaking-main-dashboard';
import { LeaveManagementReport } from '@gtpl/pages/hrms/hrms-components/leave-management-report'
import { EmployeeDashboard } from '@gtpl/pages/hrms/hrms-components/employee-dashboard';
import { RouteGrid } from 'libs/pages/master/master-components/route-grid/src';
import { RouteForm } from 'libs/pages/master/master-components/route-form/src';
import { SupplierMappingForm } from 'libs/pages/master/master-components/supplier-mapping/src/lib/supplier-mapping';
import { SupplierMappingGrid } from 'libs/pages/master/master-components/supplier-mapping/src/lib/supplier-mapping-grid'
import { EtdWiseGrid, IndentModuleForm } from '@gtpl/pages/procurement/packing-materials-components/indent-form';
import { BarCodeIndentGrid } from '@gtpl/pages/procurement/packing-materials-components/indent-grid';
// import { EmployeeReport } from '@gtpl/pages/hrms/hrms-components/employee-report';
import { PickUp } from '@gtpl/pages/master/master-components/pickup-form';
import { PickUpGrid } from '@gtpl/pages/master/master-components/pickup-grid';
import { TrimsConfigurationForm } from '@gtpl/pages/master/master-components/trims-configuration-form'
import { RmLevelForm } from '@gtpl/pages/master/master-components/rm-level-form';
import { RmLevelGrid } from '@gtpl/pages/master/master-components/rm-level-grid';
import { RmColumnsForm } from '@gtpl/pages/master/master-components/rm-columns-form';
import { RmColumnsGrid } from '@gtpl/pages/master/master-components/rm-columns-grid';
import { RmRackPositionGrid } from '@gtpl/pages/master/master-components/rm-rack-position-grid';
import { RmRackPositionForm } from '@gtpl/pages/master/master-components/rm-rack-position-form';
import { RmRacksForm } from '@gtpl/pages/master/master-components/rm-racks-form';
import { RmRacksGrid } from '@gtpl/pages/master/master-components/rm-racks-grid';
import { RmReserveLocation } from '../../../../../libs/pages/procurement/packing-materials-components/rm-reserve-location/src';
import { DefectForm } from '@gtpl/pages/master/master-components/defect-form';
import { DefectGrid } from '@gtpl/pages/master/master-components/defect-grid';
import { AssetLocationMapping } from 'libs/pages/asset-management/asset-management-components/asset-classification-grid/src/lib/pages-asset-management-asset-management-components-asset-classification-grid';
import { AssetLocationMappingForm } from 'libs/pages/asset-management/asset-management-components/asset-classification-form/src/lib/pages-asset-management-asset-management-components-asset-classification-form';
import { AssetCheckIn } from 'libs/pages/asset-management/asset-management-components/mrn-grid/src/lib/pages-asset-management-asset-management-components-mrn-grid';
import { AssetGrid } from 'libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/pages-asset-management-asset-management-components-aasets-grid';
import { AssetForm } from 'libs/pages/asset-management/asset-management-components/aasets-view/src/lib/pages-asset-management-asset-management-components-aasets-view';
import { AssetCategory } from 'libs/pages/asset-management/asset-management-components/asset-po-grid/src/lib/pages-asset-management-asset-management-components-asset-po-grid';
import { AssetCategoryForm } from 'libs/pages/asset-management/asset-management-components/asset-po-form/src/lib/pages-asset-management-asset-management-components-asset-po-form';
import { locationForm } from 'libs/pages/asset-management/asset-management-components/dc-form/src/lib/pages-asset-management-asset-management-components-dc-form';
import { AssetLocation } from 'libs/pages/asset-management/asset-management-components/dc-grid/src/lib/pages-asset-management-asset-management-components-dc-grid';
import { AssetMaintainanceGrid } from 'libs/pages/asset-management/asset-management-components/asset-sub-category-grid/src/lib/pages-asset-management-asset-management-components-asset-sub-category-grid';
import { AssetMaintainanceForm } from 'libs/pages/asset-management/asset-management-components/asset-sub-category/src/lib/pages-asset-management-asset-management-components-asset-sub-category';
import { AssetsDetailedView } from 'libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/Detailed-view';
import { AssetsAssignmentReport } from 'libs/pages/asset-management/asset-management-components/assets-repair-log/src/lib/pages-asset-management-asset-management-components-assets-repair-log';
import { AssetsmaintenanaceReport } from 'libs/pages/asset-management/asset-management-components/assets-track-report/src/lib/pages-asset-management-asset-management-components-assets-track-report';
import { AssetRACIReport } from 'libs/pages/asset-management/asset-management-components/dc-view/src/lib/pages-asset-management-asset-management-components-dc-view';
import { AssetHistoryReport } from 'libs/pages/asset-management/asset-management-components/asset-po-view/src/lib/pages-asset-management-asset-management-components-asset-po-view';
import { AssetsDashboard } from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/pages-asset-management-asset-management-components-assets-dashboard';
import { MaterialRequisitionReport } from '@gtpl/pages/reports/reports-components/material-requisition-report';
import { RepackingReprocessingReport } from '@gtpl/pages/reports/reports-components/repacking-reprocessing-report';
import { AssetsReport } from 'libs/pages/asset-management/asset-management-components/mrn-form/src/lib/pages-asset-management-asset-management-components-mrn-form';
import { DecommisionedAssets } from 'libs/pages/asset-management/asset-management-components/new-aasets-form/src/lib/pages-asset-management-asset-management-components-new-aasets-form';
import { AbstractReport } from 'libs/pages/asset-management/asset-management-components/opening-aasets-form/src/lib/pages-asset-management-asset-management-components-opening-aasets-form';
import { AssetServiceForm } from 'libs/pages/asset-management/asset-management-components/asset-service/src/lib/asset-service-form';
import { AssetServiceCalender } from '@gtpl/pages/asset-management/asset-management-components/assets-dashboard';
import AssetsServices from 'libs/pages/asset-management/asset-management-components/asset-service/src/lib/asset-service-grid';
import { DecommisionedAssetsReport } from 'libs/pages/asset-management/asset-management-components/new-aasets-form/src/lib/decomissioned-assets-report';
import AsserServiceReport from 'libs/pages/asset-management/asset-management-components/mrn-form/src/lib/assets-service-report';
import {AssetsAuditReport} from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/asset-audit-report'
import {PurchasedNotAssigned} from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/purchased-not-assigned-audit-report';
import {AssetServiceDateReport} from  'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/service-date-overdue'
import {ExpiredDateReport} from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/expired-assets-list'
import {EmployeeInactiveReport} from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/employee-inactive';
import { DownTimeDashboard } from '@gtpl/pages/production-management/production-management-components/down-time-dashboard';
import {InsuranceReport} from 'libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/insurance-report';
import {WarrantyReport} from 'libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/warranty-report';
import AssetTransfersGrid from '../../../../../libs/pages/asset-management/asset-management-components/asset-transfers-grid/src/lib/asset-transfers-grid';
import AssetTransfersForm from 'libs/pages/asset-management/asset-management-components/asset-transfers-form/src/lib/asset-transfers-form';
import AssetRequisitionForm from 'libs/pages/asset-management/asset-management-components/asset-requisition-form/src/lib/pages-asset-management-asset-management-components-asset-requisition-form';
import AssetRequisitionGrid from 'libs/pages/asset-management/asset-management-components/asset-requisition-grid/src/lib/pages-asset-management-asset-management-components-asset-requisition-grid';
import ServiceDetailView from 'libs/pages/asset-management/asset-management-components/asset-service/src/lib/service-detail-view';
import AssetLicenseGrid from 'libs/pages/asset-management/asset-management-components/asset-license-grid/src/lib/asset-license-grid';
import { AssetLicenseForm } from 'libs/pages/asset-management/asset-management-components/asset-license-form/src/lib/asset-license-form';
import {DepreciationReport} from  'libs/pages/asset-management/asset-management-components/aasets-grid/src/lib/depreciation-report';
import {AssetSaleGrid} from 'libs/pages/asset-management/asset-management-components/asset-sale-grid/src/lib/pages-asset-management-asset-management-components-asset-sale-grid';
import {AssetSaleForm} from 'libs/pages/asset-management/asset-management-components/asset-sale-form/src/lib/pages-asset-management-asset-management-components-asset-sale-form';
import {LocationAuditForm} from 'libs/pages/asset-management/asset-management-components/location-audit-form/src/lib/pages-asset-management-asset-management-components-location-audit-form';
import {LocationAuditGrid} from 'libs/pages/asset-management/asset-management-components/location-audit-grid/src/lib/pages-asset-management-asset-management-components-location-audit-grid';
import {LocationAuditCalender} from 'libs/pages/asset-management/asset-management-components/location-audit-grid/src/lib/location-audit-calender';
import {AuditReport} from 'libs/pages/asset-management/asset-management-components/assets-track-report/src/lib/audit-report';
import {AssetSaleReport} from 'libs/pages/asset-management/asset-management-components/assets-track-report/src/lib/asset-sale-report';
import {VmsReport} from 'libs/pages/asset-management/asset-management-components/assets-track-report/src/lib/vms-report';
import { ChecklistReport } from 'libs/pages/asset-management/asset-management-components/asset-service/src/lib/checklist-report';
import MainPage from 'libs/pages/asset-management/asset-management-components/assets-dashboard/src/lib/main-page';






const { Header, Sider, Content, Footer }: any = Layout;
export const BasicLayout = React.memo((props: any) => {
    const [collapsed, setcollapsed] = useState(true);
    const [selectedMenu, setselectedMenu] = useState('/');
    const [subMenu, setSubmenu] = useState([]);
    const [username, setUserName] = useState<string>(props.username);
    const [soakingDashboardUpdateKey, setSoakingDashboardUpdateKey] = useState<number>(1)
    console.log(soakingDashboardUpdateKey, 'update key');
    if (props) {
        const [UserName, setUserName] = useState<string>();
    }

    const user_name_local = JSON.parse(localStorage.getItem('username'));
    console.log('role');
    console.log(localStorage.getItem('role'));
    const Search = Input.Search;
    const uiConfig = new UiConfig();

    useEffect(() => {
        console.log(JSON.parse(localStorage.getItem('role')));
        console.log(props.user);
        let href = window.location.href.split('/');
        setUserName(props?.user?.user_data.username);
        // localStorage.setItem("createdUser", 'Admin');
        const routeLink = href[3];
        if (routeLink) {
            setselectedMenu(routeLink);
            const rootMenus = ['mdm', 'sms', 'pps', 'pts'];
            if (!rootMenus.includes(routeLink)) {
                if (routeLink === 'plantCalendar' || routeLink === 'editPlantCalendar') {
                    setSubmenu(['subPlantCalendarMenu', 'pms'])
                } else {
                    setSubmenu(['pms'])
                }
            }
        } else {
            setSubmenu(['pms'])
            setselectedMenu('/');
        }
    }, [])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])

    const updateSoakingDashboardState = (value) => {
        setSoakingDashboardUpdateKey(soakingDashboardUpdateKey + value)
    }

    const menu = (e) => {
        if (e.keyPath.length < 2) {
            setSubmenu([])
            setselectedMenu(e.key)
        } else {
            setSubmenu(e.keyPath)
            setselectedMenu(e.key)
        }
    }
    const { SubMenu } = Menu;
    const toggle = () => {
        setcollapsed(!collapsed);
    }

    const onOpenChange = (openKeys: string[]) => {
        setSubmenu(openKeys)
    }

    const logOut = () => {
        localStorage.clear();
        window.location.reload();
        <Redirect exact from="/" to="/" />
        AlertMessages.getSuccessMessage('Logging out');

    }
    const ifNotHaveAccess = () => {
        return <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
        // extra={<Link to='' ><Button type="primary">Back Home</Button></Link>}
        />
    }
    return (<Layout className="layout">
        <Router>

            <Sider breakpoint="lg" collapsedWidth="80" className='noprint'
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                collapsible
                onCollapse={(collapsed, type) => {
                    setcollapsed(true);
                }} collapsed={collapsed} trigger={null}>
                <div className="logo" >
                    {/* <img src={schemax22} /> */}
                    {/* <h1 style={{ display: collapsed ? 'none' : 'block' }}>AquaX</h1> */}
                </div>
                <Menu theme="dark" mode="inline"
                    onClick={menu}
                    openKeys={subMenu}
                    defaultOpenKeys={[]} style={{ paddingTop: "20px" }}
                    selectedKeys={[selectedMenu]}
                    onOpenChange={onOpenChange}
                    defaultSelectedKeys={['/']} >
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&
                        <Menu.Item key="ums-grid" icon={<UserOutlined />}>
                            <Link to="/user-grid"><span>Users</span></Link>
                        </Menu.Item>
                    }
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&
					
                            <Menu.Item key="asset-dashboard" icon={<PieChartOutlined />}>
                                <Link to="asset-dashboard"><span>Dashboard</span></Link>
                            </Menu.Item>

                    }
                    {(([
                        EmployeeRolesEnum.ADMIN,
                        EmployeeRolesEnum.NAVTECH_WH,
                        EmployeeRolesEnum.ASSISTANT_SALES_MANAGER,
                        EmployeeRolesEnum.SR_SALES_MANAGER,
                        EmployeeRolesEnum.PLANT_SALES_MANAGER,
                        EmployeeRolesEnum.HO_SALES_MANAGER,
                        EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER,
                        EmployeeRolesEnum.HO_PACKAGING_MANAGER
                    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1)
                        &&
                        <SubMenu
                            key="masters" icon={<FontAwesomeIcon icon={faGraduationCap} />}
                            title={
                                <span>
                                    <span>Masters</span>
                                </span>
                            }
                        >
                            <Menu.Item key="item-category-view" icon={<PicCenterOutlined />}>
                                <Link to="/item-category-view"><span>Asset Categories</span></Link>
                            </Menu.Item>
                            <Menu.Item key="item-sub-category-view" icon={<PicCenterOutlined />}>
                                <Link to="/item-sub-category-view"><span>Asset Sub Categories</span></Link>
                            </Menu.Item>
                            <Menu.Item key="items-view" icon={<PicCenterOutlined />}>
                                <Link to="/items-view"><span>Assets</span></Link>
                            </Menu.Item>
                            <Menu.Item key="Asset-Location" icon={<PicCenterOutlined />}>
                                <Link to="/Asset-Location"><span>Asset Level</span></Link>
                            </Menu.Item>
                            <Menu.Item key="item-damage-reasons-view" icon={<PicCenterOutlined />}>
                                <Link to="/item-damage-reasons-view"><span>Asset Damage Reasons</span></Link>
                            </Menu.Item>
                            <Menu.Item key="vendorsView" icon={<PicCenterOutlined />}>
                                <Link to="/vendorsView"><span>Vendors</span></Link>
                            </Menu.Item>
                            <Menu.Item key="reasonsView" icon={<PicCenterOutlined />}>
                                <Link to="/reasonsView"><span>AMC Reasons</span></Link>
                            </Menu.Item>
                            <Menu.Item key="unitcode-view" icon={<PicCenterOutlined />}>
                                <Link to="/unitcode-view"><span>Units</span></Link>
                            </Menu.Item>
							<Menu.Item key="certificates-view" icon={<PicCenterOutlined />}>
                                <Link to="/certificates-view"><span>Certificates</span></Link>
                            </Menu.Item>
                        </SubMenu>

                    }
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&
                        <SubMenu
                            key="hrms" icon={<FontAwesomeIcon icon={faUsersGear} />}
                            title={
                                <span>
                                    <span>Employee Management</span>
                                </span>
                            }
                        >
                            {/* <Menu.Item key="employee-detail-view" icon={<PicCenterOutlined />}>
                                    <Link to="/employee-detail-view"><span>Employee Detail view</span></Link>
                                </Menu.Item> */}
                            <Menu.Item key="department-view" icon={<PicCenterOutlined />}>
                                <Link to="/department-view"><span>Departments</span></Link>
                            </Menu.Item>
                            <Menu.Item key="HRMS-employee_details-view" icon={<PicCenterOutlined />}>
                                <Link to="/HRMS-employee_details-view"><span>Employees</span></Link>
                            </Menu.Item>
                        </SubMenu>
                    }
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="aseet" icon={<AppstoreOutlined />}
                            title={
                                <span>
                                    <span>Request and Transfer</span>
                                </span>
                            }
                        >
						    <Menu.Item key="view-asset-transfers" icon={<CalendarOutlined />}>
                                <Link to="/view-asset-transfers"><span>Transfer</span></Link>
                            </Menu.Item>
                       
                            <Menu.Item key="asset-requistion-grid" icon={<AppstoreAddOutlined />}>
                                <Link to="/asset-requistion-grid"><span>Requistion</span></Link>
                            </Menu.Item>


                        </SubMenu>


                    }
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="ams" icon={<AppstoreOutlined />}
                            title={
                                <span>
                                    <span>Asset Management</span>
                                </span>
                            }
                        >
                            <Menu.Item key="assets-form" icon={<AppstoreOutlined />}>
                                <Link to="/assets-form"><span>Add Asset</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-location-mapping-grid" icon={<EnvironmentOutlined />}>
                                <Link to="/asset-location-mapping-grid"><span>Location Mapping</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-assignment" icon={<FormOutlined />}>
                                <Link to="/asset-assignment"><span>Assignment</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-maintainance" icon={<SettingOutlined />}>
                                <Link to="/asset-maintainance"><span> Maintenance</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-decommission" icon={<DeleteOutlined />}>
                                <Link to="/asset-decommission"><span>Retire</span></Link>
                            </Menu.Item>  


                        </SubMenu>


                    }
                    {/* } */}
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="inventory" icon={<FontAwesomeIcon icon={faBoxesStacked} />}
                            title={
                                <span>
                                    <span>Inventory</span>
                                </span>
                            }
                        >
                            <Menu.Item key="assets-grid" icon={<PicCenterOutlined />}>
                                <Link to="/assets-grid"><span>Assets</span></Link>
                            </Menu.Item>
							<Menu.Item key="asset-history-report" icon={<HistoryOutlined />}>
                                <Link to="/asset-history-report"><span>History</span></Link>
                            </Menu.Item>

                        </SubMenu>


                    }
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="ams-service" icon={<SettingOutlined />}
                            title={
                                <span>
                                    <span>Asset Service</span>
                                </span>
                            }
                        >


                            <Menu.Item key="asset-service-grid" icon={<SolutionOutlined />}>
                                <Link to="/asset-service-grid"><span>Service</span></Link>
                            </Menu.Item>

                            <Menu.Item key="asset-service-calender" icon={<CalendarOutlined />}>
                                <Link to="/asset-service-calender"><span>Service Calender</span></Link>
                            </Menu.Item>

                        </SubMenu>


                    }
                    {/* } */}
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="ams-sale" icon={<SettingOutlined />}
                            title={
                                <span>
                                    <span>Sales</span>
                                </span>
                            }
                        >


                            <Menu.Item key="asset-sale-grid" icon={<AppstoreAddOutlined />}>
                                <Link to="/asset-sale-grid"><span>Asset Sale</span></Link>
                            </Menu.Item>


                        </SubMenu>


                    }
                    {/* } */}
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&

                        <SubMenu
                            key="ams-audit" icon={<SettingOutlined />}
                            title={
                                <span>
                                    <span>Audit</span>
                                </span>
                            }
                        >

                            <Menu.Item key="location-audit-form" icon={<AppstoreAddOutlined />}>
                                <Link to="/location-audit-form"><span>Audit Form</span></Link>
                            </Menu.Item>


                        </SubMenu>


                    }
                    {/* } */}
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) ||  (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PLANT_SALES_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.SR_SALES_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ASSISTANT_SALES_MANAGER)) &&
                        <SubMenu
                            key="downtime-module" icon={<MoneyCollectOutlined />}
                            title={
                                <span>
                                    <span>Downtime</span>
                                </span>
                            }
                        >
						    <Menu.Item key="down-time-dashboard" icon={<PicCenterOutlined />}>
                                <Link to="/down-time-dashboard"><span>Dashboard</span></Link>
                            </Menu.Item> 
                            <Menu.Item key="DownTimeReason-view" icon={<PicCenterOutlined />}>
                                <Link to="/DownTimeReason-view"><span>Reason</span></Link>
                            </Menu.Item>
                            <Menu.Item key="downtimetracking-view" icon={<PicCenterOutlined />}>
                                <Link to="/downtimetracking-view"><span>Tracking</span></Link>
                            </Menu.Item>
                            {/* <Menu.Item key="downtime-event-calender" icon={<PicCenterOutlined />}>
                                <Link to="/downtime-event-calender"><span>Event Calender</span></Link>
                            </Menu.Item> */}
                        </SubMenu>
                    }
					{((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN) ||  (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PLANT_SALES_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.SR_SALES_MANAGER) || (JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ASSISTANT_SALES_MANAGER)) &&
                        <SubMenu
                            key="License" icon={<MoneyCollectOutlined />}
                            title={
                                <span>
                                    <span>License</span>
                                </span>
                            }
                        >
						    <Menu.Item key="down-time-dashboard" icon={<PicCenterOutlined />}>
                                <Link to="/asset-license-view"><span>License</span></Link>
                            </Menu.Item> 
                        </SubMenu>
                    }
                    {/* {((JSON.parse(localStorage.getItem('role'))==='L4')||(JSON.parse(localStorage.getItem('role'))==='L1')) && */}
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&
                        <SubMenu
                            key="reports" icon={<EditOutlined />}
                            title={
                                <span>
                                    <span>Operational Reports</span>
                                </span>
                            }
                        >

                            <Menu.Item key="asset-report" icon={<AppstoreOutlined />}>
                                <Link to="/asset-report"><span>Asset Report</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-assignment-report" icon={<FormOutlined />}>
                                <Link to="/asset-assignment-report"><span> Assignment Report</span></Link>
                            </Menu.Item>
                            <Menu.Item key="asset-maintenance" icon={<SettingOutlined />}>
                                <Link to="/asset-maintenance"><span> Maintenance Report</span></Link>
                            </Menu.Item>
                            <Menu.Item key="racinformation" icon={<ProfileOutlined />}>
                                <Link to="/racinformation"><span>RACI Matrix Report</span></Link>
                            </Menu.Item>
                            <Menu.Item key="abstract-report" icon={<FontColorsOutlined />}>
                                <Link to="/abstract-report"><span>Abstract Report</span></Link>
                            </Menu.Item>
                            <Menu.Item key="decomissioned-assets-report" icon={<DeleteOutlined />}>
                                <Link to="/decomissioned-assets-report"><span>Retired Report</span></Link>
                            </Menu.Item>
							<Menu.Item key="asset-service-report" icon={<SolutionOutlined />}>
                                    <Link to="/asset-service-report"><span>Service Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="insurance-report" icon={<FontColorsOutlined />}>
                                    <Link to="/insurance-report"><span>Insurance Report</span></Link>
                                </Menu.Item>
                                <Menu.Item key="warranty-report" icon={<FontColorsOutlined />}>
                                    <Link to="/warranty-report"><span>Warranty Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="depreciation-report" icon={<FontColorsOutlined />}>
                                    <Link to="/depreciation-report"><span>Depreciation Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="vms-report" icon={<FontColorsOutlined />}>
                                    <Link to="/vms-report"><span>VM Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="checklist-report" icon={<FontColorsOutlined />}>
                                    <Link to="/checklist-report"><span>Checklist Report</span></Link>
                                </Menu.Item>
                        </SubMenu>
                    }
					 {/* {((JSON.parse(localStorage.getItem('role'))==='L4')||(JSON.parse(localStorage.getItem('role'))==='L1')) && */}
                    {((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ADMIN)) &&
                        <SubMenu
                            key="audit-reports" icon={<EditOutlined />}
                            title={
                                <span>
                                    <span>Audit Reports</span>
                                </span>
                            }
                        >

                                <Menu.Item key="asset-audit-report" icon={<FontColorsOutlined />}>
                                    <Link to="/asset-audit-report"><span>Rented Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="purchased-not-assigned" icon={<FontColorsOutlined />}>
                                    <Link to="/purchased-not-assigned"><span>Purchased Un-Assigned</span></Link>
                                </Menu.Item>
								 <Menu.Item key="asset-service-date-report" icon={<FontColorsOutlined />}>
                                    <Link to="/asset-service-date-report"><span>Service Date Overdue Report</span></Link>
                                </Menu.Item>
                                <Menu.Item key="expired-date-assets-report" icon={<FontColorsOutlined />}>
                                    <Link to="/expired-date-assets-report"><span>Expired Date Assets Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="employeeinactive-report" icon={<FontColorsOutlined />}>
                                    <Link to="/employeeinactive-report"><span>Employee InActive Report</span></Link>
                                </Menu.Item>
								<Menu.Item key="audit-report" icon={<FontColorsOutlined />}>
                                    <Link to="/audit-report"><span>Audit Report</span></Link>
                                </Menu.Item>
                                <Menu.Item key="asset-sale-report" icon={<FontColorsOutlined />}>
                                    <Link to="/asset-sale-report"><span>Asset Sale Report</span></Link>
                                </Menu.Item>
								
                        </SubMenu>
                    }


                </Menu>
            </Sider>

            <Layout
                style={{ marginLeft: collapsed ? 80 : 230 }}
            >
                <Header style={{
                    background: '#fff',
                    padding: 0
                }} className='noprint' >

                    <div className="ant-pro-global-header">
                        <div className="samllLogo">
                            <img style={{ display: collapsed ? 'block' : 'none' }} src={schemax22} />
                        </div>
                        <span className="ant-pro-global-header-trigger">
                            <MenuFoldOutlined
                                className="trigger"
                                type={collapsed ? 'menu-unfold' : 'menu-unfold'}
                                onClick={toggle}
                            />
                        </span>

                        <span>

                            <Button type="primary" style={{ float: 'right' }} onClick={logOut} ><ExportOutlined /><span className="l-rem">logout</span></Button>
                        </span>
                        <span ><Button style={{ float: 'right', border: 'white' }}>Hello! {username === '' ? user_name_local : props.user.user_data.username}</Button></span>

                        <span>
                            <Avatar icon={<UserOutlined />} />
                        </span>

                    </div>
                </Header>

                <Content
                    style={{
                        margin: '5px 5px',
                        padding: 8,
                        background: '#fff',
                        minHeight: 280,
                        overflow: 'scroll',
                    }}
                >
                    <Route exact path="/department-view" component={DepartmentsGrid} />
                    <Route exact path="/department-form" render={props => <DepartmentsForm departmentData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDepartment={(undefined) => { }}
                    />} />

                    <Route exact path="/route-view" component={RouteGrid} />
                    <Route exact path="/route-form" render={props => <RouteForm routeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRoute={(undefined) => { }}
                    />} />
                    <Route exact path="/taxes-view" component={TaxesGrid} />
                    <Route exact path="/taxes-form" render={props => <TaxesForm taxesData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateTax={(undefined) => { }}
                    />} />
                    <Route exact path="/Currencies-view" component={CurrenciesGrid} />
                    <Route exact path="/Currencies-form" render={props => <CurrenciesForm currencyData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }} />}
                    />
                    <Route exact path="/Countries-view" component={CountriesGrid} />
                    <Route exact path="/Countries-form" render={props => <CountriesForm countryData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }} />}
                    />
                    <Route exact path="/DownTimeReason-view" component={DowntimeReasonGrid} />
                    <Route exact path="/DowntimeReason-form" render={props => <DowntimeReasonForm downtimereasonData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }} />}
                    />
                    <Route exact path="/fg-damages-view" component={FgDamagesGrid} />
                    <Route exact path="/fg-damages-form" render={props => <FgDamagesForm fgDamgesData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }} />}
                    />
                    <Route exact path="/specsheet-view" component={SpecSheetGrid} />
                    <Route exact path="/spec-sheet-form" render={props => <SpecSheetForm specsheetData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }}
                    />}
                    />
                    <Route exact path="/qcdefect-view" component={QcDefectDetailsGrid} />
                    <Route exact path="/qcdefect-form" render={props => <QcDefectDetailsForm qcdefectData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }}
                    />}
                    />

                    <Route exact path="/downtimetracking-view" component={DownTimeTrackingGrid} />
                    <Route exact path="/down-time-tracking-form" render={props => <DownTimeTrackingForm downtimeTrackingData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }} />}
                    />
                    <Route exact path="/downtime-tracking-view" component={DownTimeTrackingGrid} />
                    <Route exact path="/downtime-event-calender" component={DownTimeEventCalender} />
                    <Route exact path="/down-time-tracking-new-form" render={props => <NewDowntimeTrackingform downtimeTrackingData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }} />}
                    />
                    <Route exact path="/downtime-report" component={DownTimeReport} />
                    <Route exact path="/excess-inventory-report" component={ExcessInventoryReport} />
                    <Route exact path="/holiday-calander" component={HolidayCalander} />


                    <Route exact path="/packing-stock-report" component={PackingStockReport} />
                    <Route exact path="/supply-performance-report" component={SupplyPerformanceReport} />
                    <Route exact path="/customers-view" component={CustomersGrid} />
                    <Route exact path="/customers-form" render={props => <CustomersForm customersData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }} />}
                    />
                    <Route exact path="/Grades-view" component={GradeGrid} />
                    <Route exact path="/Grades-form" render={props => <GradeForm gradesData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateGrade={(undefined) => { }}
                    />} />
                    <Route exact path="/HRMS-employee_details-view" component={HrmsEmployeeDetailsGrid} />
                    <Route exact path="/HRMS-employee_details-form" render={props => <HrmsEmployeeDetailsForm hrmsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />
                    <Route exact path="/create-attn-logs" component={CreateAttendanceLogs} />
                    <Route exact path="/miscellaneous-logs" component={MiscellaneousLogs} />
                    <Route exact path="/attendance-adjustment" component={AttendanceAdjustment} />
                    <Route exact path="/attendance-adjustment-approval" component={AttendanceAdjustmentApproval} />
                    <Route exact path="/designation-action-form" component={DesignationActionForm} />
                    <Route exact path="/designationaction-view" component={DesignationActionGrid} />
                    {/* <Route exact path="/valueaddition5-view" component={TypeOfValueAddition5Grid} />
                    <Route exact path="/valueaddition5-form" render={props => <TypeOfValueAddition5Form productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} /> */}
                    <Route exact path="/plant-operation-capacity-form" component={PlantOperationCapacityForm} />
                    <Route exact path="/plant-operation-capacity-view" component={PlantOperationCapacityGrid} />
                    <Route exact path="/grade-suggestion" component={GradeSuggestionFrom} />
                    <Route exact path="/valueaddition5-view" component={TypeOfValueAddition5Grid} />
                    <Route exact path="/valueaddition5-form" render={props => <TypeOfValueAddition5Form productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} />
                    <Route exact path="/Payment-modes-view" component={PaymentModesGrid} />
                    <Route exact path="/Payment-modes-form" render={props => <PaymentModesForm paymentmodeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePaymentmode={(undefined) => { }}
                    />} />
                    <Route exact path="/payment-terms-view" component={PaymentTermsGrid} />
                    <Route exact path="/payment-terms-form" render={props => <PaymentTermsForm paymentTermsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />
                    <Route exact path="/delivery-terms-view" component={DeliveryTermsGrid} />
                    <Route exact path="/delivery-terms-form" render={props => <DeliveryTermsForm deliverytermsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />
                    <Route exact path="/shipping-terms-view" component={ShippingTermsGrid} />
                    <Route exact path="/shipping-terms-form" render={props => <ShippingTermsForm shippingtermsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />

                    <Route exact path="/UOMs-view" component={UomsGrid} />
                    <Route exact path="/UOMs-form" render={props => <UomsForm uomsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }}
                    />} />

                    {/* <Route exact path="/unitcode-view" component={UnitCodeGrid} />
                    <Route exact path="/unitcode-form" component={UnitCodeForm} /> */}

                    <Route exact path="/saleorderprocesstypes-view" component={SaleOrderProcessTypesGrid} />
                    <Route exact path="/saleorderprocesstypes-form" render={props => <SaleOrderProcessTypesForm saleorderData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />

                    <Route exact path="/sale-order-types-view" component={SaleOrderTypesGrid} />
                    <Route exact path="/sale-order-types-form" render={props => <SaleOrderTypesForm SaleOrderTypeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }}
                    />} />
                    <Route exact path="/Products-view" component={ProductsGrid} />
                    <Route exact path="/Products-form" render={props => <ProductsForm productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} />
                    <Route exact path="/upload-insurance" component={InsuranceUpload} />

                    <Route exact path="/valueaddition1-view" component={TypeOfValueAddition1Grid} />
                    <Route exact path="/valueaddition1-form" render={props => <TypeOfValueAddition1Form type1Data={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateType1={(undefined) => { }}
                    />} />
                    <Route exact path="/valueaddition2-view" component={TypeOfValueAddition2Grid} />
                    <Route exact path="/valueaddition2-form" render={props => <TypeOfValueAddition2Form TypesOfValueAddition2Data={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateTypesOfValueAddition2Dto={(undefined) => { }}
                    />} />

                    <Route exact path="/valueaddition3-view" component={TypeOfValueAddition3Grid} />
                    <Route exact path="/valueaddition3-form" render={props => <TypeOfValueAddition3Form TypesOfValueAddition3Data={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateTypesOfValueAddition3Dto={(undefined) => { }}
                    />} />

                    <Route exact path="/saleordertypes-view" component={SaleOrderTypesGrid} />
                    <Route exact path="/saleordertypes-form" render={props => <SaleOrderTypesForm SaleOrderTypeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateItem={(undefined) => { }}
                    />} />
                    <Route exact path="/valueaddition4-view" component={TypeOfValueAddition4Grid} />
                    <Route exact path="/valueaddition4-form" render={props => <TypeOfValueAddition4Form productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} />

                    <Route exact path="/certificates-view" component={CertificationsGrid} />
                    <Route exact path="/certificates-form" render={props => <CertificationsForm certificationData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateCertification={(undefined) => { }}
                    />} />
                    {/* <Route exact path="/saleorderdetailview" component={SaleOrderDetailViewGrid} /> */}
                    <Route exact path="/Packingmethods-view" component={PackingMethodsGrid} />
                    <Route exact path="/Packingmethods-form" render={props => <PackingMehodsForm packingMehodData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePackingMethod={(undefined) => { }}
                    />} />
                    <Route exact path="/saleorder-detail-view/:id/:poNumber" render={props => <SaleOrderDetailViewPages />} />
                    <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
                    <Route exact path="/subjob-revision" component={SubJobRevision} />
                    <Route exact path="/end-customer-wise-so-report" component={EndCustomerSoReport} />
                    <Route exact path="/so-track" component={SaleOrderTrackingReportPages} />
                    {/* <Route exact path="/employee-detail-view/:empCode" render={props => <EmployeeDetailViewPages />} /> */}
                    <Route exact path="/destination-view" component={DestinationGrid} />
                    {/* <Route exact path="/destination-form" component={DestinationForm} /> */}
                    <Route exact path="/EndCustomerform" render={props => <EndCustomersForm endCustomersData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined => { })}
                    />} />
                    <Route exact path="/EndCustomerview" component={EndCustomerGrid} />


                    <Route exact path="/saleorder-form" render={props => <SoDesignForm saleOrderData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                    //   updateSaleOrder={(undefined)=>{}}
                    />}
                    />
                    <Route exact path="/sku-view" component={SkuGrid} />
                    <Route exact path="/sku-form" render={props => <SkuForm skuData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateSku={(undefined => { })}
                    />} />
                    <Route exact path="/hsn-view" component={HsnCodesGrid} />
                    <Route exact path="/hsn-form" render={props => <HsnCodesForm hsnData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateHsn={(undefined => { })}
                    />} />
                    <Route exact path="/saleorder-view" component={SoDesignGrid} />
                    <Route exact path="/typeofFood-form" render={props => <TypeOfFoodForm foodTypeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined => { })}
                    />} />

                    <Route exact path="/typeofFood-view" component={TypeOfFoodGrid} />

                    <Route exact path="/unitcode-view" component={UnitCodeGrid} />
                    <Route exact path="/unitcode-form" render={props => <UnitCodeForm unitcodeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateUnitcode={(undefined => { })}
                    />} />

                    <Route exact path="/vehicles" component={VehicleMasterGrid} />
                    <Route exact path="/vehicles-form" component={VehicleMasterForm} />

                    <Route exact path="/suppliers-view" component={SuppliersGrid} />
                    <Route exact path="/suppliers-form" render={props => <SuppliersForm
                        supplierData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateSupplier={(undefined => { })}
                    />
                    } />

                    <Route exact path="/hl-percentage-view" component={HlPercentagesGrid} />
                    <Route exact path="/hl-percentages-form" render={props => <HlPercentagesForm
                        hlpercentagesData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateHLpercentages={(undefined => { })}
                    />
                    } />
                    <Route exact path="/hatcheries-view" component={HatcheriesGrid} />
                    <Route exact path="/hatcheries-form" render={props => <HatcheriesForm
                        hatcheryData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateHatchery={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rm-tolerance-view" component={RmToleranceGrid} />
                    <Route exact path="/rm-tolerance-form" render={props => <RmToleranceForm
                        rmToleranceData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRmTolerance={(undefined => { })}
                    />
                    } />

                    <Route exact path="/level" component={LevelGrid} />
                    <Route exact path="/level-form" render={props => <LevelForm levelData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateLevel={(undefined => { })}
                    />
                    } />
                    <Route exact path="/pallet" component={PalletGrid} />
                    <Route exact path="/pallet-form" render={props => <PalletForm palletData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePallet={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rm-level-grid" component={RmLevelGrid} />
                    <Route exact path="/rm-level-form" render={props => <RmLevelForm levelData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateLevel={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rm-columns-grid" component={RmColumnsGrid} />
                    <Route exact path="/rm-columns-form" render={props => <RmColumnsForm columnData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateColumn={(undefined => { })}
                    />
                    } />
                    <Route exact path="/zone" component={ZoneGrid} />
                    <Route exact path="/zone-form" render={props => <ZoneForm zoneData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateZone={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rack-position" component={RackGrid} />
                    <Route exact path="/rack-form" render={props => <RackForm rackData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRack={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rm-rack-position" component={RmRackPositionGrid} />
                    <Route exact path="/rm-rack-position-form" render={props => <RmRackPositionForm rackData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRack={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rm-rack" component={RmRacksGrid} />
                    <Route exact path="/rm-rack-form" render={props => <RmRacksForm rackData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRack={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rack-master-grid" component={RacksMasterGrid} />
                    <Route exact path="/pallet-capacity-grid" component={PalletCapacityGrid} />
                    <Route exact path="/pallet-capacity-form" render={props => <PalletCapacityForm palletCapacityData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePalletCapacity={(undefined => { })}
                    />
                    } />

                    <Route exact path="/rack-master-form" render={props => <RacksMasterForm rackData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRack={(undefined => { })}
                    />
                    } />
                    <Route exact path="/locations" component={LocationsGrid} />
                    <Route exact path="/locations-form" render={props => <LocationsForm
                        locationsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateLocations={(undefined => { })}
                    />
                    } />
                    <Route exact path="/column" component={ColumnsGrid} />
                    <Route exact path="/columns-form" render={props => <ColumnsForm
                        columnData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateColumn={(undefined => { })}
                    />
                    } />
                    <Route exact path="/rack-endCustomer-mapping" component={RackEndcustomerMappingGrid} />
                    <Route exact path="/rack-endCustomers-mapping-form" component={RackEndcustomerMappingForm} />

                    <Route exact path="/roles-view" component={RolesGrid} />
                    <Route exact path="/roles-form" render={props => <RolesForm roleData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRole={(undefined => { })} />
                    } />
                    <Route exact path="/recruitment-mode-view" component={RecruitmentGrid} />
                    <Route exact path="/recruitment-mode-form" render={props => <RecruitmentForm recruitmentData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateRecruitment={(undefined => { })} />
                    } />

                    <Route exact path="/transfer-action-view" component={TransferActionGrid} />
                    <Route exact path="/transfer-action-form" component={TransferActionForm} />
                    {/* <Route exact path="/valueaddition5-view" component={TypeOfValueAddition5Grid} /> */}
                    {/* <Route exact path="/valueaddition5-form" render={props => <TypeOfValueAddition5Form productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} /> */}

                    <Route exact path="/expenses-form" component={ExpensesForm} />
                    <Route exact path="/expenses-view" component={ExpensesGrid} />

                    <Route exact path="/self-service-portal" component={SelfServicePortal} />
                    <Route exact path="/menu" component={NMenu} />
                    <Route exact path="/leave-balance-sheet" component={LeaveBalance} />
                    <Route exact path="/attandance" component={Attandance} />
                    {/* <Route exact path="/employee-report" component={EmployeeReport} /> */}
                    <Route exact path="/ot-details" component={otDetails} />

                    <Route exact path="/ot-apply" component={OTApply} />
                    <Route exact path="/ot-approval" component={OverTimeApproval} />
                    <Route exact path="/ot-report" component={OtReport} />
                    <Route exact path="/asset-report" component={AssetsReport} />
                    <Route exact path="/asset-assignment-report" component={AssetsAssignmentReport} />
                    <Route exact path="/asset-maintenance" component={AssetsmaintenanaceReport} />
                    <Route exact path="/asset-history-report" component={AssetHistoryReport} />

                    <Route exact path="/profiles-form" component={ProfilesForm} />
                    <Route exact path="/profiles-view" component={ProfilesGrid} />

                    <Route exact path="/notifications-form" component={NotificationsForm} />
                    <Route exact path="/notifications-view" component={NotificationsGrid} />

                    <Route exact path="/activities-form" component={ActivitiesForm} />
                    <Route exact path="/activities-view" component={ActivitiesGrid} />

                    {/* <Route exact path="/notifications-view" component={NotificationsGrid} /> */}

                    {/* <Route exact path="/languages-form" component={LanguagesForm} />
                    <Route exact path="/languages" component={LanguagesGrid} /> */}

                    <Route exact path="/holidays-calendar-form" component={HolidaysCalender} />
                    <Route exact path="/holidays-calendar-view" component={HolidaysCalenderGrid} />

                    <Route exact path="/employment-status-view" component={EmploymentStatusForm} />
                    <Route exact path="/employment-status-form" component={EmploymentStatusGrid} />

                    {/* <Route exact path="/valueaddition5-view" component={TypeOfValueAddition5Grid} />
                    <Route exact path="/valueaddition5-form" render={props => <TypeOfValueAddition5Form productData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePoduct={(undefined) => { }}
                    />} /> */}

                    <Route exact path="/destination-form" render={props => <DestinationForm destinatonData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined) => { }}
                    />} />

                    {/* <Route exact path="/unitcode-view" component={UnitCodeGrid} />
                    <Route exact path="/unitcode-form" render={props => <UnitCodeForm unitcodeData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateUnitcode={(undefined => { })}
                    />} /> */}

                    {/* <Route exact path="/item-category-view" component={ItemCategoriesGrid} /> */}
                    {/* <Route exact path="/item-category-form" render={props => <ItemCategoriesForm itemcategoryData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined => { })}
                    />} /> */}

                    <Route exact path="/item-damage-reasons-view" component={ItemDamageReasonsGrid} />
                    <Route exact path="/item-damage-reasons-form" render={props => <ItemDamageReasonsForm itemdamagereasonsData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateDetails={(undefined => { })} />
                    } />
                    <Route exact path="/invoice-form" render={props => <Invoice
                        isUpdate={false}
                    />
                    } />
                    <Route exact path="/saleorder-bom" component={BomParameter} />
                    <Route exact path="/saleorder-bom-grid" component={BomParameterGrid} />
                    <Route exact path='/bom-detail-view' component={BomParameterDetailView} />
                    <Route exact path="/reporting" component={ReportingForm} />
                    <Route exact path="/stock-in" component={StockInForm} />
                    <Route exact path="/swap-in" component={StockIn} />
                    <Route exact path="/stock-placement" component={StockPlacementVerification} />
                    <Route exact path="/stock-out-verification" component={StockOutVerification} />
                    <Route exact path="/fg-stock" component={StockGrid} />
                    <Route exact path="/location-update" component={StockLocationUpdate} />
                    <Route exact path="/sale-otder-report" component={SaleDetailsReport} />
                    <Route exact path="/purchase-order-report" component={PurchaseOrderReport} />
                    <Route exact path="/so-packing-status" component={SoPackingStatus} />
                    <Route exact path="/store-return-req" component={StoreReturnReport} />
                    <Route exact path="/packing-bom-reprt" component={PackingBomReport} />
                    {/* <Route exact path="/HRMS-uplode-deatails" component={HrmsUploadEmployeeDetails} /> */}
                    <Route exact path="/store-consumption-report" component={StoreConsumptionReport} />
                    <Route exact path="/indent-report" component={IndentReport} />
                    <Route exact path="/stock-report" component={StockReport} />
                    <Route exact path="/operationlog-books-register" component={OperationsRegister} />
                    <Route exact path="/grn-report" component={GrnReportGrid} />
                    <Route exact path="/down-time-dashboard" component={DownTimeDashboard} />
                    <Route exact path="/lot-code-dashboard" component={LotCodeDashboard} />
                    <Route exact path="/packing-dashboards" component={PackingDashboards} />
                    <Route exact path="/fh-dashboard-grid" component={FactoryHeadDashboardGrid} />
                    <Route exact path="/dashboard" component={ModuleWiseDashboard} />
                    <Route exact path="/current-temp" component={WayRackStock} />
                    <Route exact path="/wh-dashboard-components" component={WhDashboard} />
                    <Route exact path="/dept-wise-attn" component={AttendanceDashboard} />
                    <Route exact path="/employee-dashboard" component={EmployeeDashboard} />
                    <Route exact path="/monthwise-report" component={MonthwiseReportGrid} />
                    <Route exact path="/criteria-not-matching-report" component={MiscellaneousLogsReport} />
                    <Route exact path="/recruitment-report" component={RecruitmentReportGrid} />
                    <Route exact path="/leavemanagement-report" component={LeaveManagementReport} />
                    <Route exact path="/warehouse-trends-graph" component={ExpectedStockInAndOut} />
                    <Route exact path="/document-approval" component={DocumentsApprovalForm} />
                    <Route exact path="/container-register-grid" component={ContainerRegisterGrid} />
                    <Route exact path="/container-report" component={LogisticLifeCycle} />
                    <Route exact path="/capture-gross-weight" component={CaptureGrossWeightForm} />
                    <Route exact path="/container-register-form" component={ContainerRegister} />
                    <Route exact path="/container-booking" component={BookContainer} />
                    <Route exact path="/container-dispatch-to-plant" component={DispatchToPlant} />
                    <Route exact path="/assign-so" component={AssignSo} />
                    <Route exact path="/fill-seal-info" component={FillSealInfo} />
                    <Route exact path="/bl-status-grid" component={BlStatusGrid} />
                    <Route exact path="/log-book" component={LogBookGrid} />
                    <Route exact path="/container-log-book" component={ContainerLogBookForm} />
                    <Route exact path="/container-log-book-grid" component={ContainerLogBookGrid} />

                    <Route exact path="/logistics-dashboard" component={LogisticsDashboard} />
                    <Route exact path="/master-brands-form" render={props => <MasterBrandsForm masterBrandData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateMasterBrand={(undefined => { })} />
                    } />
                    <Route exact path="/master-brands-grid" component={MasterBrandsGrid} />
                    <Route exact path="/cus-invoice-detail-view" component={CusInvoice} />
                    <Route exact path="/common-invoice-detail-view" component={CommonInvoice} />
                    <Route exact path="/plant-packing-list-view" component={PackingList} />

                    <Route exact path="/common-packing-list-view" component={CommonPackingList} />
                    <Route exact path="/shipping-instructions-view" component={ShippingInstructions} />
                    <Route exact path="/doc-upload-form" component={DocumentUploadForm} />

                    {/* <Route exact path="/tax-invoice-detail-view" component={TaxInvoice}/> */}
                    <Route exact path="/stock-grid" component={StockView} />
                    <Route exact path="/stock-out-transaction" component={StockOutTransaction} />
                    <Route exact path="/packing-stock-entry" component={PackingStockEntry} />
                    <Route exact path="/cycle-checker" component={CycleChecker} />
                    <Route exact path="/cycle-checker-approval" component={CycleCheckerApproval} />
                    <Route exact path="/packing-product-conversion" component={PackingProductConversion} />
                    <Route exact path="/wh-dashboard" component={WarehouseDashboard} />
                    <Route exact path="/recruitment-dashboard" component={RecruitmentDashboard} />
                    <Route exact path="/users-dashboard" component={UserDashboard} />
                    <Route exact path="/commercial-packing-list-view" component={CommercialPackingList} />
                    <Route exact path="/commercial-invoice-view" component={CommercialInvoice} />
                    <Route exact path="/invoice-grid" component={InvoiceGrid} />
                    <Route exact path="/invoice-detail-view/:id" render={props => <LogisticsInvoiceDetailView />} />
                    <Route exact path="/freigst-invoice" component={FreigstInvoice} />
                    <Route exact path="/plant-invoice" component={PlantInvoice} />
                    <Route exact path="/plant-packing-list" component={PlantPackingList} />
                    <Route exact path="/document-register" component={DocumentRegister} />
                    <Route exact path="/po-print-view" component={PoPrint} />
                    <Route exact path="/isf-worksheet" component={IsfWorksheet} />
                    <Route exact path="/container-loading-report" component={ContainerLoadingReport} />
                    <Route exact path="/loading-report" component={ContainerLoadingReport} />
                    <Route exact path="/user-grid" component={UserPages} />
                    <Route exact path="/user-form" component={UserForm} />

                    <Route exact path="/procurement-dashboard" component={RmProcurementDashboard} />
                    <Route exact path="/rm-dashboard" component={RmDashboard} />
                    <Route exact path="/so-inspection" component={SoInspectionGrid} />

                    <Route exact path="/rm-grn-form/:id?" component={RawMaterialGrnForm} />
                    <Route exact path="/rm-grn-detailview" component={RawMaterialGrnDetailView} />
                    <Route exact path="/rm-grn-grid" component={RawMaterialGrnGrid} />
                    <Route exact path="/location-mapping" render={props => <LocationMappingForm
                    />
                    } />
                    <Route exact path="/rm-grn-vehicle-inspection" component={RmVehicleInspection} />
                    <Route exact path="/transfer-print" component={TransferPrint} />
                    <Route exact path="/rm-indent-qty-limit-grid" component={RmIndentQtyLimitGrid} />
                    <Route exact path="/rm-indent-qty-limit" render={props => <RmIndentQtyLimit rmIndentQtyLimitData={undefined}
                        isUpdate={false}
                        closeForm={() => { }} updateRmIndentQtyLimit={() => { }} />
                    } />
                    <Route exact path="/indent-form-for-prepared-material" render={props => <IndentFromForPreparedMaterial updateItem={() => { }}
                        isUpdate={false}
                        selectedData={undefined}
                        closeForm={() => { }} />
                    } />
                    <Route exact path="/indent-grid-for-prepared-material" component={IndentGridForPreparedMaterial} />
                    <Route exact path="/pending-grn-info-grid" component={GrnPendingInfoGrid} />
                    <Route exact path="/rm-reserve-location" component={RmReserveLocation} />


                    <Route exact path="/indent-view" component={IndentGrid} />
                    <Route exact path="/indent-form" render={props => <IndentForm indentData={undefined}
                        isUpdate={false}
                        // form={''}
                        closeDrawer={() => { }}
                    // updateIndent={(undefined => { })} 
                    />

                    } />
                    <Route exact path="/indent-detail-view" component={IndentDetailView} />
                    <Route exact path="/vehicle-assignment" component={VehicleAssignmentGrid} />
                    <Route exact path="/assign-vehicle" component={VehicleAssignmentForm} />
                    <Route exact path="/vehicle-grid" component={VehicleMasterGrid} />
                    <Route exact path="/vehicle-form" render={props => <VehicleMasterForm
                        vehiclesData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateVehicle={(undefined => { })} />
                    } />
                    <Route exact path="/operations-view" component={OperationsGrid} />
                    <Route exact path="/operations-form" render={props => <OperationsForm
                        operationData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateOperation={(undefined => { })} />
                    } />
                    <Route exact path="/workstation-category-form" render={props => <WorkstationCategoryForm workstationCategoryData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateWorkstationCategory={(undefined => { })} />} />
                    <Route exact path="/workstation-category-grid" component={WorkstationCategoryGrid} />
                    <Route exact path="/workstations-form" render={props => <WorkstationsForm workstationData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateWorkstation={(undefined => { })} />} />
                    <Route exact path="/workstations-grid" component={WorkstationsGrid} />
                    <Route exact path="/qa-qc-operations" component={QaQcOperations} />
                    <Route exact path="/qa-qc-grid" component={QaQcGrid} />
                    <Route exact path="/varient-operations-form" component={VariantOperationsForm} />
                    <Route exact path="/inspection-form" component={InspectionForm} />
                    <Route exact path="/inspection-print" component={InspectionPrint} />
                    <Route exact path="/pd-qaqc" component={PdQaqcForm} />
                    <Route exact path="/pd-qaqc-print" component={PdQaqcPrint} />
                    <Route exact path="/metal-detection-grid" component={MetalDetection} />
                    <Route exact path="/metal-detection-form" component={MetalDetectionForm} />

                    <Route exact path="/be-heading" component={BeHeading} />
                    <Route exact path="/be-heading-dashboard" component={BeheadingDashboard} />
                    <Route exact path="/assign-to-val-addition" component={AssignGradeToValAddition} />
                    <Route exact path="/assign-soaking-for-freezing" component={AssignSoakingToFreezeGrid} />
                    <Route exact path="/rejections-grid" component={RejectionsGrid} />
                    <Route exact path="/bom-tracker-grid" component={BomTrackerGrid} />

                    <Route exact path="/soaking-material-detail-view" component={SoakingMaterialDetailView} />

                    {/* <Route exact path="/soaking-material-grid" component={SoakingMaterialGrid} /> */}
                    <Route exact path="/val-addition-inventory-grid" component={ValAdditionInventoryGrid} />
                    <Route exact path="/val-addition-soaking-grid" component={ValAdditionSoakingGrid} />
                    <Route exact path="/val-addition-grading-dashboard" component={ValAdditionGradingDashboard} />
                    <Route exact path="/grade-dashboard" component={GradingDashboard} />
                    <Route exact path="/soaking-tub" component={SoakingTubBulbIntegration} />
                    <Route exact path="/assign-to-grading" component={AssignBeheadingToGrading} />
                    <Route exact path="/grade-reporting" component={GradeReporting} />
                    <Route exact path="/assign-so-to-grade" component={AssignSoGrading} />
                    <Route exact path="/grade-inventory" component={GradingInventoryGrid} />
                    <Route exact path="/yield-report" component={YieldReport} />
                    <Route exact path="/soaking-assignment-form" component={AssignSoakingMaterial} />
                    <Route exact path='/sub-plant-val-addition-report' component={SubPlantValAdditionsReport} />
                    {/* <Route exact path="/soaking-assignment-form" component={AssignSoakingMaterial} /> */}
                    <Route exact path="/production-inventory-grid" component={ProductionInventoryGrid} />
                    <Route exact path="/shippment-details-form" component={ShippmentDetailsForm} />
                    <Route exact path="/shippment-details-received-tab" component={ShippmentDetailsReceivedTabForm} />
                    <Route exact path="/be-heading-inventory" component={BeheadingInventoryGrid} />
                    <Route exact path="/be-heading-inprogress-inventory" component={BeheadingInprogressGrid} />
                    <Route exact path="/be-heading-sub-plant-history" component={BeheadingSubPlantHistory} />
                    <Route exact path="/cooking-grid" component={CookingGrid} />
                    {/* <Route exact path="/soaking--main-dashboard" component={SoakingMainDashboard} /> */}
                    <Route exact path="/soaking--main-dashboard" render={props => <SoakingMainDashboard updateKey={soakingDashboardUpdateKey} />} />
                    <Route exact path="/soaking-assignment-grid" component={SoakingMaterialInventory} />
                    <Route exact path="/soaking-reporting" component={SoakingReporting} />
                    <Route exact path="/soaking-tub-report" component={SoakingTubReport} />
                    <Route exact path="/soaking-dashboard" component={SoakingDashboard} />
                    <Route exact path="/report-subplant-operations" component={ReportSubPlantOperations} />
                    <Route exact path="/receive-subplant-stock" component={ReceiveSubPlantsStockNew} />
                    <Route exact path="/assign-for-freezing-form" component={AssignForFreezingForm} />
                    <Route exact path="/grn-abstract-report-grid" component={GrnAbstractReportGrid} />
                    <Route exact path="/rm-procurement-report" component={RmProcurementReportGrid} />
                    <Route exact path="/lot-tracking-report" component={LotTrackingReport} />
                    <Route exact path="/emp-monthwise-attendance-report" component={EmpMonthwiseAttendanceReport} />
                    <Route exact path="/qa-qc-sample-test" component={QaQcSampleTest} />
                    <Route exact path="/qa-qc-sample-test-grid" component={QaQcSampleTestGrid} />
                    <Route exact path="/qa-qc-sample-detail-view" component={QaQcSampleDetailView} />
                    <Route exact path="/labelling-form" component={LabellingForm} />
                    <Route exact path="/labelling-grid" component={LabellingGrid} />
                    <Route exact path="/cooking-form" component={BlanchingCookingForm} />
                    <Route exact path="/assign-to-cooking" component={CookingForm} />
                    <Route exact path="/fg-inspection-form" component={FgInspectionForm} />
                    <Route exact path="/fg-inspection-print" component={FgInspectionPrint} />

                    {/* packing module access */}
                    {((
                        [
                            EmployeeRolesEnum.ADMIN,
                            EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER,
                            EmployeeRolesEnum.HO_PACKAGING_MANAGER,
                            EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER
                        ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? <>

                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>
                                <Route exact path="/grn-rejection-reason-view" component={GrnRejectionReasonsGrid} />
                                <Route exact path="/grn-rejection-reason-form" render={props => <GrnRejectionReasonsForm rejectionreasonData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateReason={(undefined => { })} />} />
                            </>

                        }

                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.HO_PACKAGING_MANAGER
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>
                                <Route exact path="/item-category-view" component={ItemCategoriesGrid} />
                                <Route exact path="/item-category-form" render={props => <ItemCategoriesForm itemcategoryData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateDetails={(undefined => { })}
                                />} />

                                <Route exact path="/item-sub-category-view" component={ItemSubCategoriesGrid} />
                                <Route exact path="/item-sub-category-form" render={props => <ItemSubCategoriesForm subCategoryData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateData={(undefined => { })}
                                />} />

                                <Route exact path="/TrimsView" component={TrimsGrid} />
                                <Route exact path="/trims-form" render={props => <TrimsForm
                                    trimsData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateTrims={(undefined => { })}
                                />
                                } />
                                <Route exact path="/trims-configuration-grid" component={TrimsMappingGrid} />
                                <Route exact path="/trims-configuration-form" component={TrimsConfigurationForm} />




                                <Route exact path="/types-grid" component={TypesGrid} />
                                <Route exact path="/types-form" render={props => <TypesForm
                                    typesData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateDetails={(undefined => { })}
                                />
                                } />
                                <Route exact path="/notify-party-grid" component={NotifyPartyGrid} />
                                <Route exact path="/notify-party-form" render={props => <NotifyPartyForm
                                    notifyPartyData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateNotifyParty={(undefined => { })}
                                />
                                } />
                                <Route exact path="/items-view" component={ItemsGrid} />
                                <Route exact path="/items-form" render={props => <ItemsForm itemData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateItem={(undefined => { })} />
                                } />

                                <Route exact path="/sizes-form" render={props => <SizesForm sizesData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateSize={(undefined => { })}
                                />
                                } />
                                <Route exact path="/sizes-view" component={SizesGrid} />

                                <Route exact path="/vendors-form" render={props => <VendorsForm vendorsData={undefined}
                                    isUpdate={false}
                                    closeForm={() => { }}
                                    updateDetails={(undefined => { })}
                                />} />

                                <Route exact path="/vendorsPriceListView" component={VendorPriceListGrid} />
                                <Route exact path="/vendorsPriceListForm" render={props => <VendorPriceListForm />} />
                                <Route exact path="/vendorsView" component={VendorsGrid} />
                            </>}

                        <Route exact path="/reasonsView" component={ReasonsGrid} />
                        <Route exact path="/reasons-form" render={props => <ReasonsForm reasonsData={undefined}
                            isUpdate={false}
                            closeForm={() => { }}
                            updateReasons={(undefined => { })} />}
                        />
                        <Route exact path="/defect-grid" component={DefectGrid} />
                        <Route exact path="/defect-form" render={props => <DefectForm defectData={undefined}
                            isUpdate={false}
                            closeForm={() => { }}
                            updateDefect={(undefined => { })} />}
                        />
                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER,
                                EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER, EmployeeRolesEnum.HO_PACKAGING_MANAGER
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>

                                <Route exact path="/grn-grid" component={GrnGrid} />
                                <Route exact path="/grn-detail-view/:id" component={GrnDetailviewGrid} />
                                <Route exact path="/grn-form" render={props => <GrnForm
                                    isUpdate={false} />} />
                                <Route exact path='/grn-return-form' component={GrnReturnForm} />
                                <Route exact path='/grn-return-grid' component={GrnReturnGrid} />
                                <Route exact path='/grn-return-detail-view/:id' component={GrnReturnDetailView} />
                                <Route exact path="/purchase-order-grid" component={PurchaseOrderGrid} />
                                <Route exact path="/po-detail-view/:id" render={props => <PoDetailViewPages />} />
                                <Route exact path="/purchase-order-form" render={props => <PurchaseOrderForm
                                    isUpdate={false}
                                    closeForm={() => { }} />
                                } />
                            </>}

                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.HO_PACKAGING_MANAGER
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>
                                <Route exact path="/packing-dashboard" component={PackingDashboard} />
                                <Route exact path="/allsaleorders-view" component={SaleOrdersGrid} />
                                <Route exact path="/packing-bom-report" component={PackingBOM} />
                            </>}

                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.HO_PACKAGING_MANAGER,
                                EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER,
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>
                                <Route exact path="/packing-stock-transfer" component={PackingStockTransfer} />

                                <Route exact path="/stock-transfer-grid" component={StockTransferGrid} />
                                <Route exact path="/stock-transfer-detail-view/:id" component={StockTransferDetailView} />
                            </>}

                        {((
                            [
                                EmployeeRolesEnum.ADMIN,
                                EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER, EmployeeRolesEnum.HO_PACKAGING_MANAGER, EmployeeRolesEnum.PACKAGING_SUPERVISOR
                            ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) && <>
                                <Route exact path="/store-consumption-grid" component={StoreConsumptionGrid} />
                                <Route exact path="/material-preperation-grid" component={MaterialPreperationGrid} />
                                <Route exact path="/material-preperation-form" component={MaterialPreperationForm} />
                                <Route exact path="/store-consumption-detail-view" component={StoreConsumptionDetailView} />
                                <Route exact path="/indent-module-form" component={IndentModuleForm} />
                                <Route exact path="/indent-barcode-grid" component={BarCodeIndentGrid} />

                                <Route exact path="/store-consumption-form" render={props => <StoreConsumptionForm
                                    isUpdate={false}
                                    closeForm={() => { }} />
                                } />

                                <Route exact path="/store-return-form" render={props => <StoreReturnForm />} />
                                <Route exact path="/store-issues-form" render={props => <StoreIssuesForm />} />
                                <Route exact path="/store-return" component={StoreReturnPages} />
                                <Route exact path="/store-return-grid" component={StoreReturnGrid} />
                                <Route exact path="/store-return-detail-view" component={StoreReturnDetailView} />

                                <Route exact path="/etd-wise-grid" component={EtdWiseGrid} />
                            </>}


                    </> : ''}

                    <Route exact path="/sku-detail-view" component={SkuDetailView} />
                    <Route exact path="/sub-plant-operations-grid" component={SubPlantOperationsGrid} />
                    <Route exact path="/id-proofs" component={IdProofs} />
                    <Route exact path="/id-proofs-grid" component={IdProofsGrid} />
                    <Route exact path="/relations" component={Relations} />
                    <Route exact path="/relations-grid" component={RelationsGrid} />
                    <Route exact path="/expenses-category-form" component={ExpensesCategory} />
                    <Route exact path="/expenses-category-view" component={ExpensesCategoryGrid} />
                    <Route exact path="/expenses-sub-category-form" component={ExpensesSubCategory} />
                    <Route exact path="/expenses-sub-category-view" component={ExpensesSubCategoryGrid} />

                    <Route exact path="/loan-categories-grid" component={LoanCategoriesGrid} />
                    <Route exact path="/loan-categories-form" component={LoanCategories} />
                    <Route exact path='/over-time-categories-grid' component={OverTimeCategoryGrid} />
                    <Route exact path="/over-time-categories-form" component={OverTimeCategory} />
                    <Route exact path='/suggestion-view' component={SuggestionGrid} />
                    <Route exact path="/suggestion" component={Suggestion} />
                    <Route exact path='/complaints-view' component={ComplaintsGrid} />
                    <Route exact path="/complaints" component={Complaints} />
                    <Route exact path="/qualifications-view" component={QualificationsGrid} />
                    <Route exact path="/qualifications-form" component={QualificationsForm} />
                    <Route exact path="/skills-grid" component={SkillsGrid} />
                    <Route exact path="/skills" component={Skills} />
                    <Route exact path="/pickUp-grid" component={PickUpGrid} />
                    <Route exact path="/pickUp" component={PickUp} />

                    <Route exact path="/types-of-leaves-grid" component={TypesOfLeavesGrid} />
                    <Route exact path="/types-of-leaves" component={TypesOfLeaves} />
                    <Route exact path="/apply-for-leaves" component={ApplyForLeavesForm} />
                    <Route exact path="/apply-for-leaves-grid" component={ApplyForLeavesGrid} />
                    <Route exact path="/employee-leaves-grid" component={EmployeeLeavesGrid} />
                    <Route exact path="/leave-approval" component={LeaveApprovalGrid} />
                    <Route exact path="/job-notifications-view" component={JobNotificationsGrid} />
                    <Route exact path="/job-notifications-form" component={JobNotificationsForm} />
                    <Route exact path="/interview-view" component={InterviewGrid} />
                    <Route exact path="/interview-form" component={InterviewForm} />
                    <Route exact path="/contractor-view" component={ContractorGrid} />
                    <Route exact path="/contract-form" component={ContractorForm} />
                    <Route exact path="/team-view" component={TeamGrid} />
                    <Route exact path="/team-form" component={TeamForm} />
                    <Route exact path="/shift-view" component={ShiftGrid} />
                    <Route exact path="/shift-form" component={ShiftForm} />
                    {/* <Route exact path="/Asset-view" component={AssetActivityControllerForm updateState={updateSoakingDashboardState}} /> */}
                    <Route exact path="/Asset-view" render={props => <AssetActivityControllerForm updateState={updateSoakingDashboardState}
                        isUpdate={false}
                        closeForm={() => { }}
                    />} />
                    <Route exact path="/Asset-grid" render={props => <AssetActivityControllerGrid updateState={updateSoakingDashboardState} />} />
                    {/* <Route exact path="/Asset-grid" component={AssetActivityControllerGrid} /> */}
                    {/* <Route exact path="/employeeteam-view" component={EmployeeTeamGrid} />
                    <Route exact path="/employeeteam-form" component={EmployeeTeamForm} /> */}
                    <Route exact path="/teamcalender-grid" component={TeamCalenderGrid} />
                    <Route exact path="/employee-shift-mapping" component={EmployeesShiftMapping} />
                    <Route exact path="/teamcalender" component={TeamCalender} />
                    <Route exact path="/workcalender-grid" component={WorkCalenderGrid} />
                    <Route exact path="/workcalender" component={WorkCalender} />
                    <Route exact path="/inventory-stock-transfer-grid" component={ProductionInventoryTransfer} />
                    <Route exact path="/stock-out-grid" component={StockOut} />
                    <Route exact path="/generate-pick-list" component={GeneratePickList} />
                    <Route exact path="/reprocess-repacking" component={ReprocessingRepackingGrid} />
                    <Route exact path="/fg-stock-transfer-grid" component={FgStockTransferGrid} />
                    <Route exact path="/fg-stock-transfer" component={FgStockTransfer} />
                    <Route exact path="/reserve-racks" component={ReserveRackPositions} />
                    <Route exact path="/stock-summary-report" component={FgStockSummaryReport} />
                    <Route exact path="/fg-stock-report" component={FgStockReport} />
                    <Route exact path="/fg-stock-transfer-report" component={FgStockTransferReport} />
                    <Route exact path="/locations-history-report" component={LocationsHistoryReport} />
                    <Route exact path="/stock-report-warehouse" component={StockReportWarehouse} />
                    <Route exact path="/so-info-view" component={SoInfoView} />
                    <Route exact path="/stock-in-transactions" component={StockInTransactionsReport} />
                    <Route exact path="/stock-in-reverse" component={StockInReverse} />
                    <Route exact path="/stock-in-reverse-report" component={StockInReverseReport} />
                    <Route exact path="/shipment-status-report" component={ShipmentStatusReport} />
                    <Route exact path="/dummy-packing-report" component={DummyPackingReport} />
                    <Route exact path="/whatsapp-broad-cast" component={WhatsAppBroadCast} />
                    <Route exact path="/resource-operation-efficiency-form" render={props => <ResourceOperationEfficiencyForm resourceOpEfficiency={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateResourceOpEfficiency={(undefined) => { }}
                    />} />
                    <Route exact path="/plant-capacity-form" render={props => <PlantCapacityForm plantCapacity={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updatePlantCapacity={(undefined) => { }}
                    />} />

                    <Route exact path="/code-list-form" render={props => <CodeListForm codeListData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateCodeList={(undefined) => { }}
                    />} />
                    <Route exact path="/code-list-grid" component={CodeListGrid} />
                    <Route exact path="/code-list-print" component={CodeListPrint} />
                    <Route exact path="/code-list-main-form" component={CodeListMainForm} />
                    <Route exact path="/code-list-main-grid" component={CodeListMainGrid} />
                    <Route exact path="/code-list-detailView" component={CodeListDetailView} />
                    <Route exact path="/resource-op-efficiency-view" component={ResourceOperationEfficiencyGrid} />

                    <Route exact path="/dispatch-list-grid" component={dispatchlistgrid} />
                    <Route exact path="/so-info-view" component={SoInfoViewPages} />
                    <Route exact path="/exit-action-view" component={ExitActionGrid} />
                    <Route exact path="/exit-action-form" render={props => <ExitActionForm exitActionData={undefined}
                        isUpdate={false}
                        closeForm={() => { }}
                        updateExitAction={(undefined) => { }}
                    />} />

                    <Route exact path="/education-form" component={Education} />
                    <Route exact path="/educations-view" component={EducationGrid} />
                    <Route exact path='/certification-form' component={Certification} />
                    <Route exact path='/certifications-view' component={CertificationGrid} />
                    <Route exact path="/designation-grid" component={DesignationGrid} />
                    <Route exact path="/designation" component={Designation} />
                    <Route exact path='/insurance-view' component={InsuranceGrid} />
                    <Route exact path='/insurance-form' component={InsuranceForm} />
                    <Route exact path="/temperature-log-view" component={TemperatureLogGrid} />
                    <Route exact path="/temperature-log-form" render={props => <TemperatureLogForm temperatureData={undefined}
                        closeForm={() => { }}
                    />} />
                    <Route exact path="/transferred-saleorders-report" component={TransferredSaleordersReport} />
                    <Route exact path="/saleorder-abstract-report" component={SaleOrderAbstractReport} />
                    <Route exact path="/supplier-creation" component={SupplierMappingForm} />
                    <Route exact path="/supplier-creation-grid" component={SupplierMappingGrid} />
                    <Route exact path="/asset-location-mapping" component={AssetLocationMappingForm} />
                    <Route exact path="/asset-location-mapping-grid" component={AssetLocationMapping} />
                    <Route exact path="/asset-assignment" component={AssetCheckIn} />
                    <Route exact path="/assets-grid" component={AssetGrid} />
                    <Route exact path="/assets-form" component={AssetForm} />
                    <Route exact path="/asset-category" component={AssetCategory} />
                    <Route exact path="/asset-category-form" component={AssetCategoryForm} />
                    <Route exact path="/asset-Location" component={AssetLocation} />
                    <Route exact path="/location-Form" component={locationForm} />
                    <Route exact path="/asset-maintainance" component={AssetMaintainanceGrid} />
                    <Route exact path="/asset-maintainance-form" component={AssetMaintainanceForm} />
                    <Route exact path="/assetdetailedview/:code" component={AssetsDetailedView} />
                    <Route exact path="/asset-decommission" component={DecommisionedAssets} />
                    <Route exact path="/asset-dashboard" component={MainPage} />
                    <Route exact path="/racinformation" component={AssetRACIReport} />
                    <Route exact path="/abstract-report" component={AbstractReport} />
                    <Route exact path="/asset-service-form/:id?/:status?/:assetServiceId?/:startDate?/:serviceProvider?/:serviceName?/:remarks?" component={AssetServiceForm} />
                    <Route exact path="/asset-service-calender" component={AssetServiceCalender} />
                    <Route exact path="/asset-service-grid" component={AssetsServices} />
                    <Route exact path="/decomissioned-assets-report" component={DecommisionedAssetsReport} />
                    <Route exact path="/repacking-reprocessing-report" component={RepackingReprocessingReport} />
                    <Route exact path="/material-reqisition-report" component={MaterialRequisitionReport} />
					<Route exact path="/asset-service-report" component={AsserServiceReport} />
					<Route exact path="/purchased-not-assigned" component={PurchasedNotAssigned} />
                    <Route exact path="/asset-audit-report" component={AssetsAuditReport} />
					<Route exact path="/asset-service-date-report" component={AssetServiceDateReport} />
                    <Route exact path="/expired-date-assets-report" component={ExpiredDateReport} />
					<Route exact path="/employeeinactive-report" component={EmployeeInactiveReport} />
                    <Route exact path="/insurance-report" component={InsuranceReport}/>
                    <Route exact path="/warranty-report" component={WarrantyReport}/>
					<Route exact path="/asset-service-detailview/:assetServiceId?" component={ServiceDetailView} />
            <Route exact path="/asset-transfers" component={AssetTransfersForm} />
            <Route exact path="/view-asset-transfers" component={AssetTransfersGrid} />
            <Route exact path="/asset-requistion-form" component= {AssetRequisitionForm} />
            <Route exact path="/asset-requistion-grid" component= {AssetRequisitionGrid} />
			 <Route exact path="/asset-license-form" component={AssetLicenseForm} />
			  <Route exact path="/asset-license-view" component={AssetLicenseGrid} />
			  <Route exact path="/depreciation-report" component={DepreciationReport}/>
			  <Route exact path="/asset-sale-form" component= {AssetSaleForm} />
            <Route exact path="/asset-sale-grid" component= {AssetSaleGrid} />
			 <Route exact path="/location-audit-form" component= {LocationAuditForm} />
            <Route exact path="/location-audit-grid/:id?/:auditId?" component= {LocationAuditGrid} />
            <Route exact path="/location-audit-calender" component= {LocationAuditCalender} />
			<Route exact path="/audit-report" component={AuditReport}/>
            <Route exact path="/asset-sale-report" component={AssetSaleReport}/>
            <Route exact path="/vms-report" component={VmsReport}/>
			<Route exact path="/checklist-report" component={ChecklistReport}/>




                </Content>
                <Footer style={{ textAlign: 'center' }} className='noprint'>Design © {new Date().getUTCFullYear()} Created by Schemaxtech</Footer>
            </Layout>
        </Router>
    </Layout >
    )
});
export default BasicLayout
