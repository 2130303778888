import { UnitRequest } from "@gtpl/shared-models/common-models";
import { CommonResponse } from "@gtpl/shared-models/masters";
import { AllReceiveSubplantStockResponseModel, SendStockTransferDto, StockReceiveData, ReceiveStockData } from "@gtpl/shared-models/production-management";
import axios from "axios";
import connection from "./connection";

export class StockTransferService {
    URL = connection.DEPLOY_URL + '/stock-transfer';

    async sendStock(transferStock: SendStockTransferDto):Promise<CommonResponse> {
        return await axios.post(this.URL + '/sendStock', transferStock)
            .then(res => {
                return res.data
            })
    }

    async getAllReceiveSubplantStock(plantReq: UnitRequest): Promise<AllReceiveSubplantStockResponseModel> {
        return await axios.post(this.URL + '/getAllReceiveSubplantStock', plantReq)
            .then(res => {
                return res.data;
            })
    }

    async receiveStock(recStock: ReceiveStockData):Promise<CommonResponse> {
        return await axios.post(this.URL + '/receiveStock', recStock)
            .then(res => {
                return res.data;
            })
    }

}