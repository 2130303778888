import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { OperationsDropDown } from './operations-drop-down.dto';
export class OperationsDropDownResponse extends GlobalResponseObject{
    data?: OperationsDropDown[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: OperationsDropDown[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}
