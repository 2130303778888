import axios from 'axios';
import connection from './connection';
import {GradesDto,GradesResponseModel,AllGradesResponseModel, GradesDropDownDataResponseModel, GradesDropDownData, GradesRequest} from '@gtpl/shared-models/masters'; 
import { UserRequestDto } from '@gtpl/shared-models/common-models';
export class GradeServices{
    URL = connection.DEPLOY_URL + '/grades';

    async createGrade(gradeDto: GradesDto): Promise<GradesResponseModel> {
        return await axios.post(this.URL + '/createGrade',gradeDto)
            .then(res => {
                return res.data
            }) 
    }
    async updateGrade(gradeDto: GradesDto): Promise<GradesResponseModel> {
        return await axios.post(this.URL + '/updateGrade',gradeDto)
            .then(res => {
                return res.data
            }) 
    }
    async getAllGrades(req?:UserRequestDto): Promise<AllGradesResponseModel> {
        return await axios.post(this.URL + '/getAllGrades',req)
            .then(res => {
                return res.data
            }) 
    }
    async  activateDeactivateGrades(gradeDto: GradesDto): Promise<GradesResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateGrade', gradeDto)
                .then(res => {
                    return res.data
                })
   }
    async  getGradeById(gradesRequest: GradesRequest): Promise<GradesResponseModel> {
        return await axios.post(this.URL + '/getGradeById', gradesRequest)
                .then(res => {
                    return res.data
                })
   }
   async getActiveGradeNames(): Promise<GradesDropDownDataResponseModel> {
    return new GradesDropDownDataResponseModel(true,11,'itemsData retrived successfully',[new GradesDropDownData(1,'64'),new GradesDropDownData(2,'54')])
    // return await axios.post(this.URL + '/getActiveProductCount').then((res) => {
    //   return res.data;
    // });
  }
}