import { QaQcTestResultEnum } from "@gtpl/shared-models/common-models";

export class LotDetailsDto {
    lotNumber: string;
    saleOrderId: number;
    saleOrderName: string;
    saleOrderItemId: number;
    product: string;
    testId: number;
    testName: string;
    testResult: QaQcTestResultEnum;
    noOfSamples: number;
    quantityTested: number;
    rejectionReason: string;
    rejectionQuantity?: number;



    constructor(
        lotNumber: string,
        saleOrderId: number,
        saleOrderName: string,
        product: string,
        testId: number,
        testName: string,
        testResult: QaQcTestResultEnum,
        noOfSamples: number,
        quantityTested: number,
        rejectionReason: string,
        rejectionQuantity?: number



    ) {
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderName = saleOrderName;
        this.product = product;
        this.testId = testId;
        this.testName = testName;
        this.testResult = testResult;
        this.noOfSamples = noOfSamples;
        this.quantityTested = quantityTested;
        this.rejectionReason = rejectionReason;
        this.rejectionQuantity = rejectionQuantity


    }
}