import { AttnCountModel } from "./attn-count.model";
import { DeptDataModel } from "./dept-data.model";
import { EmployeeDataModel } from "./employee-data.model";


export class ShiftWiseCountModel {
    shift: string;
    attnDetails: AttnCountModel[];


    constructor(shift: string, attnDetails: AttnCountModel[]) {
        this.shift = shift;
        this.attnDetails = attnDetails;

    }
}