export class WorkstationCategoryDTO{
    workstationCategoryId : number;
    workstationCategory : string;
    isActive: boolean;
    createdUser : string;
    
    /**
     * 
     * @param workstationCategoryId number
     * @param workstationCategory string
     * @param isActive boolean
     * @param createdUser string
     */
    constructor(workstationCategoryId : number,workstationCategory : string,isActive: boolean,createdUser : string){
        this.workstationCategoryId = workstationCategoryId;
        this.workstationCategory = workstationCategory;
        this.isActive= isActive;
        this.createdUser= createdUser;
    }
}