import React from 'react';

import './pages-sale-management-sale-components-sub-job-revision.css';

/* eslint-disable-next-line */
export interface PagesSaleManagementSaleComponentsSubJobRevisionProps {}

export function PagesSaleManagementSaleComponentsSubJobRevision(
  props: PagesSaleManagementSaleComponentsSubJobRevisionProps
) {
  return (
    <div>
      <h1>
        Welcome to pages-sale-management-sale-components-sub-job-revision!
      </h1>
    </div>
  );
}

export default PagesSaleManagementSaleComponentsSubJobRevision;
