import axios from 'axios';
import connection from './connection';
import {LanguagesDto, LanguagesResponseModel, AllLanguagesResponse} from '@gtpl/shared-models/hrms';

export class LanguagesService{
    
    URL = connection.DEPLOY_URL + "/Languages";

    async create(dto: LanguagesDto): Promise<LanguagesResponseModel>{
        return await axios.post(this.URL+ '/createLanguages', dto)
           .then(res => {
               return res.data
           })
    }

    async update(dto: LanguagesDto): Promise<LanguagesResponseModel>{
        return await axios.post(this.URL+ '/updateLanguages', dto)
           .then(res => {
               return res.data
           })
    }

    async getAllLanguages(): Promise<AllLanguagesResponse>{
        return await axios.post(this.URL + '/getAllLanguages')
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivate(dto: LanguagesDto): Promise<LanguagesResponseModel>{
        return await axios.post(this.URL+ '/activateOrDeactivate', dto)
           .then(res => {
               return res.data
           })
    }

    async getAllActiveLanguages(): Promise<AllLanguagesResponse>{
        return await axios.post(this.URL+ '/getAllActiveLanguages')
        .then(res => {
            return res.data
        })
    } 
}