import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Input, Button, Row, Col, Form, Select, Spin } from 'antd';
import { PlantsDropDown, ItemsDropDownDto, ItemSubCategoryDropDownDto, SubCatReqForCategoryId, MasterBrandsDropDownDto } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService, UnitcodeService, MasterBrandService } from '@gtpl/shared-services/masters';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { StockService } from '@gtpl/shared-services/procurement';
import { stockDropdownData } from '@gtpl/shared-models/common-models';
import './stock-report.css';
import { StockDto } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
const { Option } = Select;
/* eslint-disable-next-line */
export interface StockReportProps { }

export function StockReport(
  props: StockReportProps
) {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [stockData, setStockData] = useState<StockDto[]>([]);
  const [brandsDropDown, setBrandsDropDown] = useState<MasterBrandsDropDownDto[]>([]);
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const [poDropDown, setPoDropDown] = useState<StockDto[]>([]);
  const [itemSubDropDown, setItemSubDropDown] = useState<StockDto[]>([]);
  const [itemDropDown, setItemDropDown] = useState<StockDto[]>([]);
  const [brandDropDown, setBrandDropDown] = useState<StockDto[]>([]);
  const [locationDrop , setLocationDrop] = useState<any[]>([]);

  const searchInput = useRef(null);

  const [form] = Form.useForm();

  const itemService = new ItemsService();
  const services = new SaleOrderService();
  const stockService = new StockService();
  const unitsService = new UnitcodeService();
  const subCategoryService = new ItemSubCategoryService();
  const brandsService = new MasterBrandService();
  const [pageSize, setPageSize] = useState<number>(null);

  const getAllStockData = () => {
    setLoading(true);
    setDisable(true);
    console.log(form.getFieldValue('saleOrderId'),'so id');
    const req = new stockDropdownData();
    if (form.getFieldValue('brandName') != undefined) {
      req.brandId = form.getFieldValue('brandName')
    };
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('unitId') != undefined) {
        req.unitId = form.getFieldValue('unitId')
      };
    }
    if (form.getFieldValue('unitId') != undefined) {
      req.unitId = form.getFieldValue('unitId')
    };
    if (form.getFieldValue('saleOrderId') != undefined) {
      req.saleOrderId = form.getFieldValue('saleOrderId')
      console.log(req.saleOrderId,'req')
      console.log(form.getFieldValue('saleOrderId'),'so id');
    };
    if (form.getFieldValue('itemName') != undefined) {
      req.itemId = form.getFieldValue('itemName');
    };
    if (form.getFieldValue('itemSubCategoryId') != undefined) {
      req.itemSubCategoryId = form.getFieldValue('itemSubCategoryId')
    };
    if (form.getFieldValue('locationId') != undefined){
      req.locationId = form.getFieldValue('locationId')
    }

    stockService.getAllStockDataForReport(req).then(res => {
      setDisable(false)
      if (res.status) {
        setStockData(res.data);
        setLoading(false);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        setStockData([]);
        setLoading(false);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setStockData([]);
      setDisable(false)
      setLoading(false);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const onFocus = () => {
  }

  const onSearch = (val) => {
  }
  const onBlur = () => {
  }

  useEffect(() => {
    getAllPlants();
    getSoDropForStockReport();
    getItemSubDropForStockReport();
    getItemDropForStockReport();
    getBrandData();
    setTimeout(() => setLoading(false), 1000);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
    getLocationsForStockReport();
  }, []);

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const getSoDropForStockReport = () => {
    stockService.getSoDropForStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setPoDropDown([...res.data, { saleOrderId: 0, saleOrderNumber: 'BULK', poNumber: 'BULK' }]);
      } else {
        if (res.intlCode) {
          setPoDropDown([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoDropDown([]);
    })
  };
  
  const getItemSubDropForStockReport = () => {
    stockService.getItemSubDropForStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setItemSubDropDown(res.data);
      } else {
        setItemSubDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemSubDropDown([]);
    })
  }
  const getItemDropForStockReport = () => {
    stockService.getItemDropForStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setItemDropDown(res.data);
      } else {
        setItemDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDropDown([]);
    })
  }
  const getLocationsForStockReport = () => {
    stockService.getLocationsForStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setLocationDrop(res.data);
      } else {
        setLocationDrop([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setLocationDrop([]);
    })
  }
  const getBrandData = () => {
    stockService.getBrandDropForStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setBrandDropDown(res.data);
      } else {

        setBrandDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setBrandDropDown([]);
    })
  }

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('StockReport')
      .addColumns(data)
      .addDataSource(stockData,{str2num:true})
      .saveAs('stock-report.xlsx');
  }

  const data = [
    { title: "Unit", dataIndex: "unitName" },
    { title: "Location", dataIndex: "rackPosition" },
    { title: "Customer PO", dataIndex: "poNumber", render: (value) => <>{value == null || undefined ? "BULK" : value}</> },
    { title: "Item Sub Category", dataIndex: "itemSubCategoryName" },
    { title: "Item Name", dataIndex: "itemName" },
    { title: "Brand", dataIndex: "brandName" },
    { title: "Size", dataIndex: "sizeName" },
    { title: "BAP Number", dataIndex: "bapNumber" },
    { title: "Packing Style", dataIndex: "packStyle" },
    { title: "Quantity", dataIndex: "physicalQuantity" },
    // { title: "Minimum Stock", dataIndex: "minimumStock" },
  ]

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const onReset = () => {
    form.resetFields();
    getAllStockData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'sno',
      width: '60px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unitName',
      // responsive: ['lg'],
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortDirections: ['descend', 'ascend'],
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber != null ? a.poNumber.localeCompare(b.poNumber) : "Have Null Values",
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      width: '130px',
      ...getColumnSearchProps('poNumber'),
      render: (value) => <>
        {
          value == null || undefined ? "BULK" : value
        }
      </>
    },
    {
      title:'Location',
      dataIndex:'rackPosition',
      fixed: 'left',
      width: '100px',
      ...getColumnSearchProps('rackPosition'),
      render:(text,record)=>{
        return record.rackPosition ? record.rackPosition : '-';
      }
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategoryName',
      // responsive: ['lg'],
      width: '130px',
      sorter: (a, b) => a.itemSubCategoryName.length - b.itemSubCategoryName.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName'),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      // responsive: ['lg'],
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName'),
      width: '170px'
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      // responsive: ['lg'],
      width: '120px',
      sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
      sortDirections: ['descend', 'ascend'],

      ...getColumnSearchProps('brandName'),
       render:(value) => value?(value):'N/A'
    },
    {
      title: 'Size',
      dataIndex: 'sizeName',
      // responsive: ['lg'],
      width: '120px',
      sorter: (a, b) => a.sizeName?.localeCompare(b.sizeName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('sizeName')
    },
    {
      title: 'BAP Number',
      dataIndex: 'bapNumber',
      sorter: (a, b) => a.bapNumber?.localeCompare(b.bapNumber),
      sortDirections: ['descend', 'ascend'],
      width: '100px',
      ...getColumnSearchProps('bapNumber'),
      render: (value) => value ? (Math.round(value)) : '-',
    },

    {
      title: 'Packing Style',
      dataIndex: 'packStyle',
      sorter: (a, b) => a.packStyle?.localeCompare(b.packStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packStyle'),
      width: '120px',
      // render:(value) => value?(Math.round(value)):'-'
    },
    // {
    //   title: 'ETD',
    //   dataIndex: 'etd',
    //   width: '100px',
    //   sorter: (a, b) => a.etd?.localeCompare(b.etd),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (value, record) => {
    //     return <span>
    //       {record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}
    //     </span>
    //   }
    // },
    {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      // responsive: ['lg'],
      width: '100px',
      sorter: (a, b) => a.physicalQuantity?.localeCompare(b.physicalQuantity),
      sortDirections: ['descend', 'ascend'],
      render: (value) => value ? (Math.round(value)) : '-'
    },

    // {
    //   title: 'Minimum Stock',
    //   dataIndex: 'minimumStock',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.minimumStock?.localeCompare(b.minimumStock),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (value) => value ? (Math.round(value)) : '-'

    // },

  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Stock Report</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <br></br>
      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="unitId"
              label=" Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                style={{ width: '100%' }}
              >
                {plantData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrderId"
              label="Customer PO"
              rules=
              {[{ required: false, message: ' Select ' },]}>
              <Select
                showSearch
                allowClear
                placeholder="Select Customer Po"
                optionFilterProp="children"
              //  onFocus={onFocus}
              //  onBlur={onBlur}
              //  onSearch={onSearch} 
              //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

              >
                <Option key={0} value={null}>Select Sale Order</Option>
                {poDropDown.map((data) => {
                  return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="locationId"
              label="Location"
              rules={[
                {
                  required: false, message: 'Select Location',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
              >
                {locationDrop.map(dropData => {
                  return <Option value={dropData.locationId}>{dropData.rackPosition}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="itemSubCategoryId"
              label="Item Sub Category"
              rules={[
                {
                  required: false,
                  message: 'Item Sub Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Item Sub Category `,
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Sub Category"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Sub Category</Option>
                {itemSubDropDown.map((subCategory) => {
                  return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategoryName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 9 }}>
            <Form.Item label="Item Name"
              name='itemName'
            // rules={[{ required: true, message: 'Missing Item Name' }]}
            >
              <Select showSearch
                allowClear
                placeholder="Select Item"
                // onSelect={handleItemName}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {itemDropDown === undefined ? '' : itemDropDown.map(dropData => {
                  return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }}>
            <Form.Item
              label="Brand Name"
              name='brandName'
            // rules={[{ required: true, message: 'Missing brand name' }, { pattern: /^(?!\s*$).+/, message: `Enter Brand Name` }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Please Select Brand"
                optionFilterProp="children"
                // onChange={setBrandName}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Please select brand</Option>
                {brandDropDown.map(brandDropData => {
                  return <Option key={brandDropData.brandId} value={brandDropData.brandId}>{brandDropData.brandName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col style={{ marginTop: '30px' }}>
            <Button style={{ marginRight: '4px' }} disabled={disable} onClick={getAllStockData}>Get Report</Button>
          </Col>
          <Col style={{ marginTop: '30px' }}>
            <Button type="primary" htmlType="submit" onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>

      {stockData.length > 0 && <>
        <Row justify="end"><Col style={{ marginBottom: '5px' }}>
          <Button icon={<DownloadOutlined />} onClick={() => { exportExcel(); }} > GET EXCEL </Button>
        </Col></Row><Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={stockData}
          scroll={{ x: 1500, y: 400 }}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
          bordered />
        <Card title={"TOTAL Stock Items: " + stockData.length} style={{ textAlign: 'left', height: 41, backgroundColor: 'none' }}>
        </Card></>
      }

    </Card>

  );
}

export default StockReport;
