import { LotCodeIwAttributes } from "./lotCodeAttribute-i-w";
import { LotGradeChildren } from "./lotCodeGrade-children";

export class LotCodeDeHeading{
    name: string;
    attribute:LotCodeIwAttributes;
    children:LotGradeChildren;
    constructor(name: string, attribute:LotCodeIwAttributes, children:LotGradeChildren) {
        this.name = name;
        this.attribute = attribute;
        this.children = children;
    }
}