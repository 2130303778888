import React, { useEffect, useState } from 'react';

import './assign-grade-to-val-addition.css';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ProductionInventoryService, WorkstationService, ValueAdditionService, VariantOperationsService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeDropDown, LotInformationRequest, LotNoInfo, LotNumberRequest, ValAddOpModel, ValueAdditionDto, WorkStationCategoryReq, WorkStationIdReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import { OperationTypeEnum, PlantsEnum, ShiftsEnum, SubPlantCodesEnum, UnitRequest, WorkStationCategoryEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
const { Option } = Select;
import {UnitcodeService} from '@gtpl/shared-services/masters';
import { UnitcodeDto } from '@gtpl/shared-models/masters';

/* eslint-disable-next-line */
export interface AssignGradeToValAdditionProps { }

export function AssignGradeToValAddition(
  props: AssignGradeToValAdditionProps
) {
  const [valAdditionForm] = Form.useForm();
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const [plantDetails, setPlantDetails] = useState<UnitcodeDto>(undefined);
  const [valAddOperations, setValAddOperations] = useState<ValAddOpModel[]>([]);
  const [lotNoInfo, setLotNoInfo] = useState<LotNoInfo>(undefined);
  const [boxesCount, setBoxesCount] = useState<number>(0);
  const [workstationValidation, setWorkstationValidation] = useState<boolean>(true);
  const [plantValidation, setPlantValidation] = useState<boolean>(false);
  const [boxWeight, setBoxWeight] = useState<number>(0);
  const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(WorkStationTypeEnum.INTERNAL);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  let history = useHistory();

  const productionInvService = new ProductionInventoryService()
  const workstationService = new WorkstationService()
  const varientOpService = new VariantOperationsService()
  const valAddService = new ValueAdditionService()
  const unitcodeService = new UnitcodeService()

  useEffect(() => {
    valAdditionForm.setFieldsValue({ workStationType: WorkStationTypeEnum.INTERNAL })
    // getLotNumbers();
    getWorkStations();
    getValAdditionOperations();
    getPlantDetailsById(Number(localStorage.getItem("unit_id")));
  }, [])
  const checkWorkStationType = () => {
    const type = valAdditionForm.getFieldValue('workStationType');
    if (type == WorkStationTypeEnum.EXTERNAL) {
      setWorkstationValidation(false)
      setPlantValidation(true)
      setWorkStationType(WorkStationTypeEnum.EXTERNAL)
    } else {
      setWorkstationValidation(true)
      setPlantValidation(false)
      setWorkStationType(WorkStationTypeEnum.INTERNAL)
      getWorkStations();
    }
  }

  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory =  WorkStationCategoryEnum.find((res) => res.name === "VALUE ADDITION").value;
    catReq.unitId = Number(localStorage.getItem("unit_id"));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        setWorkStations(res.data);
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }
  const getPlantDetailsById = (plant) => {
    unitcodeService.getPlantDetailsById({unitcodeId:plant}).then((res) => {
      if (res.status) {
        setPlantDetails(res.data);
      } else {
        setPlantDetails(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantDetails(undefined);
    });
  }

  const getValAdditionOperations = () => {
    varientOpService.getValAddOperations(new UnitRequest(Number(localStorage.getItem('unit_id')))).then((res) => {
      if (res.status) {
        setValAddOperations(res.data);
      } else {
        setValAddOperations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setValAddOperations([]);
    });
  }

  const getWorkStationCapacity = (val) => {
    console.log(val)
    workstationService.getWorkstationCapacity(new WorkStationIdReq(val, Number(localStorage.getItem('unit_id')))).then((res) => {
      console.log(res)
      if (res.status) {

        setBoxWeight(res.data.workstationUnitCapacity);
        console.log(res);
        // valAdditionForm.setFieldsValue({
        //   saleOrderId:res.data.saleOrderId,
        //   count:res.data.count,
        //   boxes:res.data.boxes,
        //   availableQuantity:res.data.availableQuantity,
        // })
      } else {
        setLotNoInfo(undefined)
      }

    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });
  }

  const getLotNumberInfo = (val) => {
    productionInvService.getLotNumberInfo(new LotInformationRequest(val, Number(localStorage.getItem('unit_id')), valAdditionForm.getFieldValue('operation'))).then((res) => {
      if (res.status) {

        setLotNoInfo(res.data)
        console.log(res);
        setBoxesCount(res.data.boxes)
        setBoxWeight(res.data.boxWeight);
        valAdditionForm.setFieldsValue({
          saleOrderNumber: res.data.poNumber,
          saleOrderId: res.data.saleOrderId,
          lotNumber: res.data.lot,
          saleOrderItemId: res.data.saleOrderItemId,
          count: res.data.outputGrade,
          boxes:res.data.boxes,
          availableQuantity: res.data.availableQuantity,
          issuingQuantity: res.data.availableQuantity,
          prodInvId: res.data.productionInventoryId
        })
      } else {
        setLotNoInfo(undefined)
      }

    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const clearForm = () => {
    valAdditionForm.resetFields();
  }



  const getLotNumbers = () => {
    const selectedOperation = valAdditionForm.getFieldValue('operation');
    console.log(selectedOperation);
    if(selectedOperation){
      valAdditionForm.setFieldsValue({subLotNumber:'',saleOrderNumber:'',availableQuantity:null,boxes:null,count:null,issuingQuantity:null})
      productionInvService.getLotNumbers(new LotNumberRequest(Number(localStorage.getItem('unit_id')), selectedOperation)).then((res) => {
        console.log(res);
        if (res.status) {
          setLotNumbers(res.data);
        } else {
          setLotNumbers([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setLotNumbers([]);
      });
    }else{
      AlertMessages.getErrorMessage('select operation');
    }

  }

  const calculateIssuingQty = () => {
    const issuedQty = valAdditionForm.getFieldValue('boxes') * boxWeight;
    const availQty = Number(valAdditionForm.getFieldValue('availableQuantity'));
    console.log(valAdditionForm.getFieldValue('availableQuantity'))
    console.log(boxWeight)

    if (Number(availQty) >= Number(boxWeight)) {
      if (issuedQty > valAdditionForm.getFieldValue('availableQuantity')) {
        AlertMessages.getErrorMessage('Issued Quantity should not exceed available Quantity');
        valAdditionForm.setFieldsValue({ boxes: null });
        valAdditionForm.setFieldsValue({ issuingQuantity: null });
      } else {
        valAdditionForm.setFieldsValue({ issuingQuantity: issuedQty });
      }
    } else {
      valAdditionForm.setFieldsValue({ boxes: 1 });
      valAdditionForm.setFieldsValue({ issuingQuantity: valAdditionForm.getFieldValue('availableQuantity') });

    }
  }

  const saveValAdditionInventory = () => {
    valAdditionForm.setFieldsValue({ workStationType: WorkStationTypeEnum.INTERNAL });
    const formData = valAdditionForm.getFieldsValue();
    console.log(formData);
    const valAddDto = new ValueAdditionDto(formData.lotNumber, formData.subLotNumber, formData.saleOrderId, formData.operation, undefined, undefined, formData.issuingQuantity, formData.count, undefined, formData.shift, formData.workStationType, formData.workStationId, undefined, localStorage.getItem('createdUser'), Number(localStorage.getItem('unit_id')), undefined, undefined, formData.saleOrderItemId, formData.issuingQuantity, undefined, undefined, undefined, undefined, formData.boxes,0,plantDetails.plantType != undefined?plantDetails.plantType:"",formData.prodInvId)
    console.log('formData')
    console.log(valAddDto)
    valAdditionForm.validateFields().then(formData => {
      setDisableSubmit(true)
      valAddService.AssignValadditionOperation(valAddDto).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Value addition created successfully');
          // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
          history.push("/val-addition-grading-dashboard");
          // onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setDisableSubmit(false)
        }
      }).catch((err) => {
        setDisableSubmit(false)
        AlertMessages.getErrorMessage(err.message);
      });
    });
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Value Addition Assignment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/val-addition-grading-dashboard' ><Button className='panel_button' >Dashboard </Button></Link>}>
      <Form layout={'vertical'} form={valAdditionForm} name="control-hooks" >

        <Row gutter={24}>
          <Form.Item name="prodInvId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="saleOrderId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="lotNumber" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="saleOrderItemId" >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

            <Form.Item name="operation" label="Operation" rules={[{ required: true, message: 'Missing peration' }]} >
              <Select
                placeholder="Select Operation"
                allowClear
                onChange={getLotNumbers}
                onClear={clearForm}

              >
                {valAddOperations.map(dropData => {
                  return <Option key={dropData.operationName} value={dropData.operationName}>{dropData.operationName}</Option>
                })}
              </Select>
            </Form.Item>


          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 10 }}>

            <Form.Item name="subLotNumber" label="Sub Lot Code" rules={[{ required: true, message: 'Missing Lot No' }]} >
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Lot No"
                allowClear
                onChange={getLotNumberInfo}
                dropdownMatchSelectWidth={false}
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.subLotNumber} value={dropData.subLotNumber}>{dropData.sublotandplantlot}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>

            <Form.Item name="saleOrderNumber" label="Customer PO" rules={[{ required: true, message: 'Missing Sale Order No' }]}>
              <Input />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>

            <Form.Item name="count" label="Count" rules={[{ required: true, message: 'Missing Count' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="availableQuantity" label="Quantity Available" rules={[{ required: true, message: 'Missing Quantity Available' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: 'none' }}>

            <Form.Item name="workStationType" label="Workstation Type" rules={[{ required: true, message: 'Missing Workstation Type' }]}>
              <Select
                placeholder="Select Workstation Type"
                allowClear
                onChange={checkWorkStationType}
              >
                <Option key={0} value={null}>Select Workstation Type</Option>
                <Option key={1} selected value={WorkStationTypeEnum.INTERNAL}>{WorkStationTypeEnum.INTERNAL}</Option>
                <Option key={2} value={WorkStationTypeEnum.EXTERNAL}>{WorkStationTypeEnum.EXTERNAL}</Option>
              </Select>
            </Form.Item>

          </Col>
          


        {/* </Row> */}

        {/* <Row gutter={24}> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.INTERNAL) ? 'block' : 'none' }}>

          <Form.Item name="workStationId" label="Workstation" rules={[{ required: workstationValidation, message: 'Missing Lot No' }]} >
            <Select
              placeholder="Select Workstation"
              allowClear
              // onChange={getWorkStationCapacity}
            >
              {workStations.map(dropData => {
                return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
              })}
              </Select>
            </Form.Item>


          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.EXTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="externalPlant" label="Plant" rules={[{ required: plantValidation, message: 'Missing Plant' }]} >
              <Select
                placeholder="Select Plant"
                allowClear
              >
                <Option key={0} value={null}>Select Plant</Option>
                <Option key={1} selected value={SubPlantCodesEnum.KDM}>{SubPlantCodesEnum.KDM}</Option>
                <Option key={2} value={SubPlantCodesEnum.BVRM}>{SubPlantCodesEnum.BVRM}</Option>
                <Option key={3} value={SubPlantCodesEnum.MTM}>{SubPlantCodesEnum.MTM}</Option>
              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Missing Shift' }]} >
              <Select
                placeholder="Select Shift"
                allowClear
              >
                <Option key={0} value={null}>Select Plant</Option>
                <Option key={1} selected value={ShiftsEnum.General}>{ShiftsEnum.General}</Option>
                <Option key={2} value={ShiftsEnum.Shift_A}>{ShiftsEnum.Shift_A}</Option>
                <Option key={3} value={ShiftsEnum.Shift_B}>{ShiftsEnum.Shift_B}</Option>
                <Option key={4} value={ShiftsEnum.Shift_C}>{ShiftsEnum.Shift_C}</Option>
              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <label>No. Of. Boxes: <span style={{ color: 'red' }}>Avl. Boxes :{boxesCount}</span></label>
            <Form.Item name="boxes" label={false}  rules={[{ required: true, message: 'Missing No. Of. Boxes' }]}>
              <InputNumber style={{ width: '100%' }} min={0}
                onChange={calculateIssuingQty}
              />
            </Form.Item>

          </Col>

         

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="issuingQuantity" label="Issuing Quantity" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>






          <Col>
            <Form.Item style={{ paddingTop: '25px' }}>
              <Button type='primary' block htmlType="submit" disabled={disableSubmit} onClick={saveValAdditionInventory}>
                Submit
              </Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Card>
  );
}

export default AssignGradeToValAddition;
