export class ProductsDropDownData{
    productId:number;
    productName:string;
    
    /**
     * 
     * @param productId 
     * @param productName 
     */
    constructor(productId:number,productName:string){
      this.productId = productId;
      this.productName = productName;
    }
}