export class OperationRegisterRequest{
    lotNumber:string;
    soNumber:number;

    /**
     * 
     * @param lotNumber 
     * @param soNumber 
     */
    constructor(lotNumber:string, soNumber:number){
        this.lotNumber = lotNumber;
        this.soNumber = soNumber;
    }
}