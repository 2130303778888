import { CodeListInfoDto } from "@gtpl/shared-models/planning";
import { ApiProperty } from "@nestjs/swagger";



export class SubJobRevisionCodesRequest {
    saleOrderItemId: number
    codelistmainId:number
    tracebilityCodeId: number
    unitId: number
    cartons: number
    updatedUser?: string;
    traceabilityCode?:string
    unitName?:string
    constructor(saleOrderItemId: number,codelistmainId:number,tracebilityCodeId: number,unitId: number,cartons: number,updatedUser?: string,traceabilityCode?:string,unitName?:string){
            this.saleOrderItemId =  saleOrderItemId;
            this.codelistmainId = codelistmainId;
            this.tracebilityCodeId =  tracebilityCodeId;
            this.unitId =  unitId;
            this.cartons =  cartons;
            this.updatedUser = updatedUser;
            this.traceabilityCode = traceabilityCode;
            this.unitName = unitName;

    }

}