import axios from "axios";
import { PdQaqcDto, ProductInspectionResponse, AllProductInspectionsResponse, ProductInspecReq, pdQaqcReq, AllPdQaqcResponse } from "@gtpl/shared-models/production-management";


import connection from './connection';
import { JobCompletedEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import { CommonResponse } from "@gtpl/shared-models/masters";
export class PdQaqcService{

    URL = connection.DEPLOY_URL + '/peeling';

    async createPeelingInfo(formInfo:PdQaqcDto): Promise<CommonResponse>{
      return await axios.post(this.URL + '/createPeelingInfo', formInfo)
          .then(res => {
              return res.data
          })   
  }

  async getAllPeelingSamplesForSamplesId(dto: pdQaqcReq): Promise<AllPdQaqcResponse> {
    return await axios.post(this.URL + '/getAllPeelingSamplesForSamplesId', dto)
        .then(res => {
            return res.data
        })
}

 async getAll():Promise<AllPdQaqcResponse> {
     return await axios.post(this.URL + '/getAllPeelings')
        .then(res => {
            return res.data
        })
 }

}