import axios from "axios";
import { CaptureWorkStatusDto,JobCompletedStatusForCaptureWorkDto, AllCaptureWorkStatusResponseModel, CaptureWorkStatusReponseModel } from '@gtpl/shared-models/production-management';
import { ShiftsEnum,OperationTypeEnum,JobCompletedEnum } from "@gtpl/shared-models/common-models";
import connection from './connection';

export class CaptureWorkStatusService{
    URL = connection.DEPLOY_URL + '/capture-work-status';

    async getAllCaptureWorkStatus():Promise<AllCaptureWorkStatusResponseModel>{
        return await axios.post(this.URL + './getAllCaptureWorkStatus')
            .then(res =>{
                return res.data;
            })
        // const data = new CaptureWorkStatusDto(1,OperationTypeEnum.BE_HEADING,"jobNo","so number",23,11,JobCompletedEnum.NO,true,"me");
        // const data1 = new CaptureWorkStatusDto(1,OperationTypeEnum.BE_HEADING,"jobNo1","so number1",231,111,JobCompletedEnum.YES,true,"me");
        // const data2 = new CaptureWorkStatusDto(1,OperationTypeEnum.VALUE_ADDITION,"jobNo2","so number2",223,121,JobCompletedEnum.NO,false,"me");
        // const data3 = new CaptureWorkStatusDto(1,OperationTypeEnum.VALUE_ADDITION,"jobNo2","so number2",223,121,JobCompletedEnum.YES,false,"me");
        // return new AllCaptureWorkStatusResponseModel(true,1,"",[data,data1,data3,data2]);
    }
    async  jobCompletedStatus(soakingInfo: JobCompletedStatusForCaptureWorkDto): Promise<CaptureWorkStatusReponseModel> {
        console.log(soakingInfo);
        return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
                .then(res => {
                    return res.data
                })
    }

}