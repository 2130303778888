import { SoItemDetailsBySoIdDTO } from "libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto";
import { ForkLiftJobDTO } from "../fork-lift-job";

export class ForkLiftItemsDTO {
    saleOrderId: number;
    saleOrderItemId: number;
    pallet: number;
    currentPosition: number;
    targetPosition: number;
    realPosition: number;
    date: Date;
    createdUser: string;
    updatedUser?: string;
    forkLiftJobInfo?: ForkLiftJobDTO;
    brand?: string;
    specie?: string;
    variety?: string;
    productForm?: string;
    freezingType?: string;
    packingStyle?: string;
    uom?: string;
    grade?: string;
    valAdditionOne?: string;
    noOfPouches?: number;
    pouchWeight?: number;
    productName?:string;
    issuedQuantity?:number;
    receivedQuantity?:number;
    jobCartons?:number;
    unitId?:number;
    stockId?:number;


   
    constructor(saleOrderId: number,
        saleOrderItemId: number,
        pallet: number,
        currentPosition: number,
        targetPosition: number,
        realPosition: number,
        date: Date,
        createdUser: string,
        updatedUser?: string,
        forkLiftJobInfo?: ForkLiftJobDTO,
        brand?: string,
        specie?: string,
        variety?: string,
        productForm?: string,
        freezingType?: string,
        packingStyle?: string,
        uom?: string,
        grade?: string,
        valAdditionOne?: string,
        noOfPouches?: number,
        pouchWeight?: number,
        productName?:string, 
        issuedQuantity?:number,
        receivedQuantity?:number,
        jobCartons?:number,
        unitId?:number,
        stockId?:number
        ){
        this.saleOrderId=saleOrderId;
        this.saleOrderItemId=saleOrderItemId;
        this.pallet=pallet;
        this.currentPosition=currentPosition;
        this.targetPosition=targetPosition;
        this.realPosition=realPosition;
        this.date=date;
        this.createdUser=createdUser;
        this.updatedUser= updatedUser;
        this.forkLiftJobInfo=forkLiftJobInfo;
        this.brand = brand,
        this.specie = specie,
        this.variety = variety,
        this.productForm = productForm,
        this.freezingType = freezingType,
        this.packingStyle = packingStyle,
        this.uom = uom,
        this.grade = grade,
        this.valAdditionOne = valAdditionOne,
        this.noOfPouches = noOfPouches,
        this.pouchWeight = pouchWeight,
        this.productName = productName,
        this.issuedQuantity = issuedQuantity,
        this.receivedQuantity = receivedQuantity
        this.jobCartons = jobCartons
        this.unitId = unitId
        this.stockId = stockId
    }
}
