export class HatcheriesDto {
    hatcheriesId?: number;
    hatcheriesName: string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(hatcheriesId: number, hatcheriesName: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.hatcheriesId = hatcheriesId;
        this.hatcheriesName = hatcheriesName;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}