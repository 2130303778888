import { JobCompletedEnum, OperationTypeEnum, PlantsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";

export class ProductionLogDto{

    
    productionLogId: number;
    
    operationType: OperationTypeEnum;
    
    lotNumber: string;
    
    subLotNumber: string;
    
    jobNumber: string
    
    saleOrderId: number;
    
    saleOrderItemId: number;
    
    plantId: number;
    
    subPlantId: number;
    
    workStationType: WorkStationTypeEnum;
    
    jobStatus: JobCompletedEnum;
    
    assignFreezing: JobCompletedEnum;
    
    workStation: number;
    
    inputGrade?: number;
    
    plannedGrade?: number;
    
    actualGrade?: number;
    
    inputQuantity: number;
    
    plannedOutputQuantity?: number;
    
    actualOutputQuantity?: number;
    
    inputBoxes: number;
    
    outputBoxes: number;
    
    assignedUser: string
    
    assignedTime: string
    
    shift: string;
    
    isReceived: JobCompletedEnum;
    
    createdUser: string | null;
    
    updatedUser: string | null;


}