import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker } from 'antd';
import Link from 'antd/lib/typography/Link';
import {EmployeeService, VehicleService} from '@gtpl/shared-services/masters';
import './vehicle-assignment-form.css';
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';
import { EmployeeDto, VehicleDto } from '@gtpl/shared-models/masters';
import {IndentService} from '@gtpl/shared-services/raw-material-procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Redirect, useLocation } from 'react-router-dom';
import { HarvestingTime, IndentModel, IndentRequest } from '@gtpl/shared-models/raw-material-procurement';
import {VehicleAssignmentService} from '@gtpl/shared-services/transportation';
import moment from 'moment';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
/* eslint-disable-next-line */
export interface VehicleAssignmentFormProps {}
const { Option } = Select;

export function VehicleAssignmentForm(
  props: VehicleAssignmentFormProps
) {

  let location = useLocation();
  const indentStatusInfo: IndentModel | any = location.state;
  console.log(indentStatusInfo);
  const [vehicleAssignmentForm] = Form.useForm();
  const [tab, setTab] = useState<string>(undefined);
  const [vehiclesData, setVehiclesData] = useState<VehicleDto[]>([]);
  const [driversData, setDriversData] = useState<EmployeeDto[]>([]);
  const [indentData, setIndentData] = useState<any[]>([]);
  const employeeService = new EmployeeService();
 const empService = new HrmsEmpDetService();
  const vehicleService = new VehicleService();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [afterSubmitDisable, setAfterSubmitDisable] = useState<boolean>(false);
  const vehicleAssignmentService = new VehicleAssignmentService();

  const indentService = new IndentService();


  const saveData = (values) => {
    console.log(values);
    setAfterSubmitDisable(true);

  const userName = localStorage.getItem("createdUser");
  values.createdUser = userName;
    vehicleAssignmentService.createVehicleAssignment(values).then(res => {
      if(res.status){
        goToGrid()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else{
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getInfoMessage(err.message);
    })
  }
  useEffect(()=>{
    getAllDriversData();
    getAllVehiclesData();
    // console.log(moment(indentStatusInfo.harvestingDate).format("YYYY-MM-DD"));
    if (indentStatusInfo != undefined) {
      console.log(indentStatusInfo);
      vehicleAssignmentForm.setFieldsValue({
          harvestingTime: indentStatusInfo.data.harvestingTime,
          harvestingDate: moment(indentStatusInfo.data.harvestingDate, 'YYYY-MM-DD'),
          indentId: indentStatusInfo.data.indentId,
          indentCode: indentStatusInfo.data.indentCode,
          // farmerName: indentStatusInfo.data.farmName,
          // location: indentStatusInfo.data.address,
          driverId: indentStatusInfo.data.driverId === 0 ?null:indentStatusInfo.data.driverId,
          vehicleId: indentStatusInfo.data.vehicleId===0?null:indentStatusInfo.data.vehicleId,
          assigneddate: moment()
      });
      getIndentItemsData(indentStatusInfo.data.indentId);

      
    } 
  },[indentStatusInfo])

  const getIndentItemsData = (indentId) => {
    const indentReq = new IndentRequest();
    indentReq.indentId =indentId;
    indentService.getIndentDataById(indentReq).then((res) => {
      if(res.status){
        setIndentData(res.data)
        vehicleAssignmentForm.setFieldsValue({farmerName:(res.data)[0].farmerName,location:(res.data)[0].farmAddress})
      }
      else {
        if (res.intlCode) {
          setIndentData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getAllDriversData = () =>{
    empService.getAllActiveEmployees().then(res => {
      if(res.status){
        setDriversData(res.data);
        console.log(res.data);
      }else {
        if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        }
        setDriversData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDriversData([]);
    })
  }
  const getAllVehiclesData = () =>{
    vehicleService.getAllActiveVehicles().then(res=>{
      if(res.status){
        setVehiclesData(res.data);
        console.log(res.data);
      }else {
        if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        }
        setVehiclesData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setVehiclesData([]);
    })
  }
  const goToVehicleAssignmentGrid = () => {
    setTab("NeedToAssign");
  }
  const onReset = () => {
    vehicleAssignmentForm.resetFields();
}
const goToGrid = () => {
  setTab(indentStatusInfo.key);
}
return (
  <>
  {tab ? <Redirect to={{pathname:"/vehicle-assignment", state:tab}}/>:null}
  <Card title={<span style={{ color: 'white' }}>Assign Vehicle & Driver</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link onClick={e => goToVehicleAssignmentGrid()} ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
      <Form layout="vertical" form={vehicleAssignmentForm} name="control-hooks" onFinish={saveData} >
        <Row gutter={24}>
          <Form.Item name="indentId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="assigneddate" initialValue={moment()} hidden>
            <DatePicker format="YYYY-MM-DD HH:mm:ss" defaultValue={moment('YYYY-MM-DD HH:mm:ss')}  />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="vehicleId" label="Vehicle"  rules={[{ required: true, message: 'Missing Vehicle' }]}>
              <Select
                  placeholder="Select Vehicle"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  disabled={false} >
                  <Option value="Please Select">Please Select</Option>
                  {vehiclesData.map(dropData => {
                      return <Option value={dropData.vehicleId}>{dropData.vehicleName}</Option>
                  })}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="driverId" label="Driver"  rules={[{ required: true, message: 'Missing drivers' }]}>
              <Select
                  placeholder="Select Driver"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  disabled={false} >
                  <Option value={''}>Please Select</Option>
                  {driversData.map(dropData => {
                       return <Option value={dropData.employeeId}>{dropData.employeeName}</Option>
                      })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="indentCode" label="Indent Code" rules={[{ required: true,message:'Missed Indent Code' }]}>
                <Input disabled/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="farmerName" label="Farmer Name" rules={[{ required: true,message:'Missed Farmer Name' }]}>
                <Input disabled/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="location" label="Location" rules={[{ required: true,message:'Missed Location' }]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="harvestingDate" label="Harvesting Date" rules={[{ required: true,message:'Missed Harvesting Date' }]}>
                <DatePicker onChange={() => { }} disabled={true} style={{ width: '100%' }} format={'YYYY-MM-DD'} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="harvestingTime"
              label="Harvesting Time"
              rules={[
                  {
                      required: true, message: 'Missed Harvesting Time'
                  },
              ]}>
              <Select
                  placeholder="Select Harvesting Time"
                  // defaultValue={HarvestingTime.BOOKED}
                  disabled={true}
              >
                  {/* <Option key={0} value={null}>Select Container Status</Option>
                  {ContainerStatusInput.map((container) => {
                      return <Option key={container.status} value={container.status}>{container.status}</Option>
                  })} */}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{paddingTop:'30px'}}>
            <Button type="primary" htmlType="submit" disabled={afterSubmitDisable} >
                Submit
            </Button>
            {/* {(isUpdate === false) &&
                <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                    Reset
            </Button> } */}
          </Col>
        </Row>
      </Form>
  </Card>
  </>
);
}

export default VehicleAssignmentForm;
