import axios from "axios";
import connection from './connection';
import {DesignationActionDto, AllDesignationActionResponseModel, DesignationActionResponseModel } from '@gtpl/shared-models/hrms';

export class DesignationActionService{

    URL = connection.DEPLOY_URL + '/designation-action';

    async createDesignationAction(data: DesignationActionDto): Promise<DesignationActionResponseModel>{        
        return await axios.post(this.URL + '/createDesignationAction', data)
            .then(res => {
                return res.data
            })
    }

    async updateDesignationAction(data: DesignationActionDto): Promise<DesignationActionResponseModel>{
        return await axios.post(this.URL + '/updateDesignationAction', data)
            .then(res => {
                return res.data
            })
    }

    async getAllDesignationActions(): Promise<AllDesignationActionResponseModel>{
       
        return await axios.post(this.URL + '/getAllDesignationActions')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateDesignationAction(data: DesignationActionDto): Promise<DesignationActionResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivateDesignation', data)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveDesignationActions(): Promise<AllDesignationActionResponseModel>{
        return await axios.post(this.URL + '/getAllActiveDesignationActions')
           .then(res => {
               return res.data
           })
    }
}