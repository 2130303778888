import React, { useEffect, useRef, useState } from 'react';
import { Divider,  Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, DatePicker, Typography } from 'antd';
import { GradingService } from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import {SearchOutlined ,CloseCircleOutlined,CheckCircleOutlined} from '@ant-design/icons';

export interface DeheadingInventoryProps { }

export function DeheadingInventory(props: DeheadingInventoryProps) {
    const searchInput = useRef(null);
    const [form] = Form.useForm();
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [inventoryData, setInventoryData] = useState<any[]>([]);
    const service = new GradingService();

    useEffect(() => {
        deheadingInventotyData()
    }, [])

    const deheadingInventotyData = () => {
        service.deheadingInventotyData({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setInventoryData(res.data)
                // AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
          text ? (
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : text
          )
            : null
    
      });
    
      /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };
    const columns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title:'LOT Number',
            dataIndex: 'concatLotNum',
            ...getColumnSearchProps('concatLotNum')
        },
        {
            title:'Sale Order No',
            dataIndex : 'soNumber'
        },
        {
            title:'Operation',
            dataIndex:'operation'
        },
        {
            title :'Product SKU',
            dataIndex :'variantCode'
        },
        {
            title :'Qty',
            dataIndex :'qty'
        }
    ]
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Deheading Inventory</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <br></br>
            </Card>
            <Table
                rowKey={record => record.saleOrderId}
                columns={columns}
                dataSource={inventoryData}
                pagination={false}
                onChange={onChange}
                scroll={{ x: true }}
                size='small'
                bordered
            />
        </>
    )
}
export default DeheadingInventory;