import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Card } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';

export interface LocationsHistoryReportProps { }

export function LocationsHistoryReport(props: LocationsHistoryReportProps) {
    const [page, setPage] = React.useState(1);
    const [reportData, setReportData] = useState<any[]>([]);
    const service = new FGStockService();

    useEffect(() => {
        getlocationsHistoryReportData();
    }, [])

    const getlocationsHistoryReportData = () => {
        service.getlocationsHistoryReportData().then((res) => {
            if (res.data) {
                setReportData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    const Columns: ColumnProps<any>[] = [
        {
            title: '#Sno',
            key: 'sno',
            width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Action',
            dataIndex: 'actionItems',
        },
        {
            title: 'Sale Order',
            dataIndex: 'poNumber',
            // render: (record, value) => {
            //     return (record.poNumber ? record.poNumber : '-');
            // }
        },
        {
            title: 'Old Rack',
            dataIndex: 'oldRack',
        },
        {
            title: 'New Rack',
            dataIndex: 'newRack',
        },
        {
            title: 'Old SO',
            dataIndex: 'oldSo',
        },
        {
            title: 'new SO',
            dataIndex: 'upadatedSo',
        },
        {
            title: 'Cartons',
            dataIndex: 'cartons',
            
        },
    ];

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Locations History</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} ></Card>
            <Table
                rowKey={record => record.saleOrderId}
                columns={Columns}
                dataSource={reportData}
                scroll={{ x: true }}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                size='small'
                bordered />
        </>
    )
}
export default LocationsHistoryReport;