import { AllZonesResponseModel, RacksForZoneId, ZoneDropDownDto, ZoneDropDownResponseModel, ZoneDto,PlantsDropDownResponse, ZoneResponseModel, PlantsIdRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import { PlantRequest } from 'libs/shared-models/raw-material-procurement/src/lib/indent';
import connection from './connection';

export class ZoneService {
URL = connection.DEPLOY_URL + '/zone-master';

  async createZone(zoneDto: ZoneDto): Promise<ZoneResponseModel> {
    console.log(zoneDto);
      return await axios.post(this.URL + '/createZone',zoneDto)
          .then(res => {
              return res.data
          })
  }
  async  updateZone(zoneDto: ZoneDto): Promise<ZoneResponseModel> {
    return await axios.post(this.URL + '/updateZone', zoneDto)
           .then(res => {
               return res.data
           })
}
  async getAllZones(): Promise<AllZonesResponseModel> {
    return await axios.post(this.URL + '/getAllZones')
        .then(res => {
            return res.data
        })     
  }
  async getPlantZones(plantIdRequest: PlantsIdRequest): Promise<AllZonesResponseModel> {
    return await axios.post(this.URL + '/getPlantZones',plantIdRequest)
        .then(res => {
            return res.data
        })     
  }
async getAllZoneDropDown():Promise<ZoneDropDownResponseModel>{
  return await axios.post(this.URL + '/getAllZoneDropDown')
  .then(res => {
      return res.data
  })   
}

async  ActivateorDeactivateZone(zoneDto: ZoneDto): Promise<ZoneResponseModel> {
      console.log(zoneDto);
      return await axios.post(this.URL + '/activateorDeactivateZone', zoneDto)
              .then(res => {
                  return res.data
              })
              
 }

 async  getZoneById(zoneDto: RacksForZoneId): Promise<ZoneDropDownDto> {
  return await axios.post(this.URL + '/getZoneById', zoneDto)
          .then(res => {
              return res.data
          })
}
 
async getAllPlants(): Promise<PlantsDropDownResponse> {
  return await axios.post(this.URL + '/getAllZones')
      .then(res => {
          return res.data
      }) 
} 

async getPlantZonesExceptFromZone(plantIdRequest:PlantRequest): Promise<AllZonesResponseModel> {
  return await axios.post(this.URL + '/getPlantZonesExceptFromZone', plantIdRequest)
  .then(res => {
      return res.data
  }) 
} 

}
