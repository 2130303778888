import { EmployeeTerminationApprovalStatus } from "../..";
import { RolesList } from '@gtpl/shared-models/gtpl';
import { EmployeeRolesEnum, EmpPrefixEnum } from "@gtpl/shared-models/common-models";
import { EmployeeFamilyDetailsDto } from "./employee-family-details.dto";
import { EmployeeIdProofDetailsDto } from "./employee-idproof-details.dto";
import { EmployeeExperienceDetailsDto } from "./employee-experience-details.dto";
import { EmployeeEducationDetailsDto } from "./employee-education-details.dto";
export class EmployeeDto {


  employeeId: number;
  employeeCode: string;
  employeeName: string;
  employeeLastName: string;
  mobileNumber: string;
  employeeRole: EmployeeRolesEnum;
  teamCode: string;
  unitId: number;
  prefix: EmpPrefixEnum;
  dateOfBirth: Date;
  gender: string;
  maritualStatus: string;
  altMobileNumber: string;
  emailID: string;
  bloodGroup: string;
  nationality: string;
  departmentId: string;
  priorityOne: string;
  priorityType: string;
  currentAddress: string;
  permanentAddress: string;
  qualification: string;
  yearsOfExperience: Number;
  companyEmail: string;
  employeeMode: string;
  employeeStatus: string;
  contractorName: string;
  shiftGroup: string;
  dateOfJoin: Date;
  dateOfleave: Date;
  workstationNumber: string;
  healthCardNumber: string;
  uanNumber: string;
  salary: string;
  bankAccountNumber: string;
  ifscCode: string;
  designation: string;
  dateOfEntry: Date;
  status: string;
  emergencyContactNo: string;
  emergencyContactName: string;
  isProbPeriodCompleted: string;
  transportation: string;
  accommodation: string;
  isAuditCompleted: string;
  skillSet: string;
  pickUpPoint: string;
  routeNumber: string;
  panNumber: string;
  esiNumber: string;
  reportingManager: string;
  bankName: string;
  employeeCategory: string;
  wageType: string;
  aadhaarNumber: string;
  state: string;
  pinCode: string;
  permanentState: string;
  permanentPinCode: string;
  isActive: boolean;
  createdAt: Date;
  createdUser: string;
  updatedAt: Date;
  updatedUser: string;
  versionFlag: number;
  employeeType: string;
  familyDetails?: EmployeeFamilyDetailsDto[];
  educationDetails?: EmployeeEducationDetailsDto[];
  idProofDetails?: EmployeeIdProofDetailsDto[];
  experienceDetails?: EmployeeExperienceDetailsDto[];
  image?: string;


}


