export class TypesDto {
  typeId?: number;
  type: string;
  itemSubCategoryId : number;
  isActive: boolean;
  createdUser: string;
  createdAt: Date;
  updatedAt: Date;
  updatedUser: string;
  versionFlag: number;
  itemSubCategoryName?: string;

}