
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { DefectDto } from "./defect.dto";

export class DefectResponse extends GlobalResponseObject{
      data?: DefectDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: DefectDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }

}