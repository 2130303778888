export class AssetByCode {
    assetsCode? : string;
    assetCategory?:  string;
    plant?: string;
    location?: string;
    assetType?: string;
    plantId? : number;
    fromDate?: any;
    toDate?:any;

    // constructor (
    //     assetsCode: string,
    // ) {
    //     this.assetsCode = assetsCode;
    // }
}