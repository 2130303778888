import axiox from 'axios';
import connection from './connection';
import { EmployeeTypesDto, AllEmployeeTypesResponse, EmployeeTypesResponseModel, EmployeeTypesReq } from "@gtpl/shared-models/hrms";
import axios from 'axios';

export class EmployeeTypesService{
     
    URL = connection.DEPLOY_URL + '/employeeTypes';

    async createEmployeeTypes(dto: EmployeeTypesDto): Promise<EmployeeTypesResponseModel>{
        return await axios.post(this.URL + '/createEmployeeTypes', dto)
            .then(res => {
                return res.data;
            })
    }

    async updateEmployeeTypes(dto: EmployeeTypesDto): Promise<EmployeeTypesResponseModel>{
        return await axios.post(this.URL + '/updateEmployeeTypes', dto)
            .then(res => {
                return res.data;
            })
    }

    async getAllEmployeeTypes(): Promise<AllEmployeeTypesResponse>{
        return await axios.post(this.URL + '/getAllEmployeeTypes')
            .then(res => {
                return res.data;
            })
    }

    async getAllActiveEmployeeTypes(): Promise<AllEmployeeTypesResponse>{
        return await axios.post(this.URL + '/getAllActiveEmployeeTypes')
            .then(res => {
                return res.data;
            })
    }

    async activateOrDeactivate(dto: EmployeeTypesReq): Promise<EmployeeTypesResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivate', dto)
            .then(res => {
                return res.data;
            })
    }

    async getEmployeeTypesById(req: EmployeeTypesReq): Promise<EmployeeTypesResponseModel>{
        return await axios.post(this.URL + '/getEmployeeTypesById', req)
            .then(res => {
                return res.data;
            })
    }

}