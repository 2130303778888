import { StockInData } from "./stock.dto";

export class StockInResDto {
    names:string[];
    stockIn: StockInData;
    stockOut: StockInData;

    constructor(names: string[], stockIn: StockInData, stockOut: StockInData){
            this.names= names;
            this.stockIn= stockIn;            
            this.stockOut= stockOut;            
    }
}
