import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StoreReturnInformation } from './store-return-information.dto';

export class StoreReturnResponseModel extends GlobalResponseObject{
    data?: StoreReturnInformation;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: StoreReturnInformation) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
