import axios from 'axios';
import connection from './connection';
import { AllTransferActionResponseModel, TransferActionDto, TransferActionResponseModel} from '@gtpl/shared-models/hrms';

export class TransferActionService{

    URL = connection.DEPLOY_URL + '/transfer-action';

    async createTransferAction(transferActiondto: TransferActionDto ): Promise<TransferActionResponseModel> {
        console.log(transferActiondto);
       
        return await axios.post(this.URL + '/createTransferAction',transferActiondto)
            .then(res => {
                return res.data
            })
    }

    async getAllTransferActions(): Promise<AllTransferActionResponseModel> {
                
        return await axios.post(this.URL + '/getAllTransferActions')
            .then(res => {
                return res.data 
            })     
    }

    async  updateTransferAction(transferActiondto: TransferActionDto): Promise<TransferActionResponseModel> {
        return await axios.post(this.URL + '/updateTransferAction', transferActiondto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateTransferAction(transferActiondto: TransferActionDto): Promise<TransferActionResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateTransferAction', transferActiondto)
                        .then(res => {
                                return res.data
                        })
                }
                
}