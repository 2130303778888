import { InsuranceType } from "@gtpl/shared-models/common-models";
export class InsuranceDto{
    insuranceId : number;
    companyName : string;
    emailId : string;
    mobileNumber : string;
    insuranceType : InsuranceType;
    isActive : boolean;
    updatedUser : string;
    createdUser : string;
    createdAt : Date;
    updatedAt : Date;
    versionFlag : number;
    
    /**
     * 
     * @param insuranceId number
     * @param companyName string
     * @param insuranceType InsuranceType
     */
    constructor(insuranceId : number,companyName : string, emailId : string,
        mobileNumber : string,insuranceType:InsuranceType, isActive : boolean,updatedUser : string,createdUser : string,
        createdAt : Date,
        updatedAt : Date,versionFlag : number
        ){
        this.insuranceId = insuranceId;
        this.companyName = companyName;
        this.emailId = emailId;
        this.mobileNumber = mobileNumber;
        this.insuranceType = insuranceType
        this.isActive = isActive
        this.updatedUser = updatedUser
        this.createdUser = createdUser
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.versionFlag = versionFlag
    }
}
export const InsuranceDtoDefault : InsuranceDto = {
    insuranceId : 0,
    companyName : "",
    emailId : "",
    mobileNumber : "",
    insuranceType : InsuranceType.Internal,
    isActive : true,
    updatedUser : "",
    createdUser : "",
    createdAt : new Date(),
    updatedAt : new Date(),
    versionFlag : 0
}; 