export class SaleOrderItemsDto {
    saleOrderItemId: number;
    saleOrderId: number;
    itemId: string;
    minGrade: number;
    maxGrade: number;
    foodTypeId: string;
    valueAdditionOneId: number;
    valueAdditionTwoId: number;
    valueAdditionThreeId: number;
    valueAdditionFourId: number;
    cases: number;
    netAmount: number;
    unitPrice: number;
    pouches: number;
    pouchWeight: number;
    caseWeight: string;
    categoryId: number;
    netWeight: string;
    freezeStle: number;
        minGlaze: number;
        MaxGlaze: number;
        soakingTime: string;
        soakingStyle: string;
        styleWeighment: string;
        varientId: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    brandId: number;
    uomId: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    /**
     * 
     * @param saleOrderItemId 
     * @param saleOrderId 
     * @param itemId 
     * @param minGrade 
     * @param maxGrade 
     * @param foodTypeId 
     * @param valueAdditionOneId 
     * @param valueAdditionTwoId 
     * @param valueAdditionThreeId 
     * @param valueAdditionFourId 
     * @param cases 
     * @param netAmount 
     * @param unitPrice 
     * @param pouches 
     * @param pouchWeight 
     * @param caseWeight 
     * @param categoryId 
     * @param netWeight 
     * @param uomId 
     * @param freezeStle 
     * @param minGlaze 
     * @param MaxGlaze 
     * @param soakingTime 
     * @param soakingStyle 
     * @param styleWeighment 
     * @param varientId 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param brandId 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
   
    constructor(saleOrderItemId: number,
        saleOrderId: number,
        itemId: string,
        minGrade: number,
        maxGrade: number,
        foodTypeId: string,
        valueAdditionOneId: number,
        valueAdditionTwoId: number,
        valueAdditionThreeId: number,
        valueAdditionFourId: number,
        cases: number,
        netAmount: number,
        unitPrice: number,
        pouches: number,
        pouchWeight: number,
        caseWeight: string,
        categoryId: number,
        netWeight: string,
        uomId: string,
        freezeStle: number,
        minGlaze: number,
        MaxGlaze: number,
        soakingTime: string,
        soakingStyle: string,
        styleWeighment: string,
        varientId: string,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        brandId: number,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number,){ 
            this.saleOrderItemId = saleOrderItemId
            this.saleOrderId = saleOrderId
            this.itemId = itemId
            this.minGrade = minGrade
            this.maxGrade = maxGrade
            this.foodTypeId = foodTypeId
            this.valueAdditionOneId = valueAdditionOneId
            this.valueAdditionTwoId = valueAdditionTwoId
            this.valueAdditionThreeId = valueAdditionThreeId
            this.valueAdditionFourId = valueAdditionFourId
            this.cases = cases
            this.netAmount = netAmount
            this.unitPrice = unitPrice
            this.pouches = pouches
            this.pouchWeight = pouchWeight
            this.caseWeight = caseWeight
            this.netWeight = netWeight
            this.brandId = brandId
            this.categoryId = categoryId
            this.uomId=uomId
            this.freezeStle = freezeStle
            this.styleWeighment = styleWeighment
            this.minGlaze = minGlaze
            this.MaxGlaze = MaxGlaze
            this.soakingTime = soakingTime
            this.soakingStyle = soakingStyle
            this.varientId = varientId
            this.isActive = isActive
            this.createdAt = createdAt
            this.createdUser = createdUser
            this.updatedAt = updatedAt
            this.updatedUser = updatedUser
            this.versionFlag = versionFlag
        }
    }

    export class SOItemsDto {
        saleOrderItemId: number;
        saleOrderId: number;
        itemId: string;
        minGrade: number;
        maxGrade: number;
        foodTypeId: string;
        varientId: string;
        // valueAdditionOneId: number;
        // valueAdditionTwoId: number;
        // valueAdditionThreeId: number;
        // valueAdditionFourId: number;
        cases: number;
        netAmount: number;
        unitPrice: number;
        pouches: number;
        pouchWeight: number;
        caseWeight: number;
        categoryId: number;
        netWeight: number;
        // freezeStle: number;
            minGlaze: number;
            MaxGlaze: number;
            soakingTime: string;
            soakingStyle: string;
            styleWeighment: string;       
        brandId: number;
        brand: string;
        uomId: string;
        dispatchedQty: number;
        invAmount: number;
        currencyId: number;
        rePackQty?: number;
        reProcessQty?: number;
        productId?: number;
        shrimpWeight?:number;
        requiredQuantity?:number; //rm required Qty
        varient?:number; //rm required Qty
        hlPercentage?:number;
        finalPercentage?:number;
        jobCases?:number;
        convVariantIds?:number[];
        finalFG?:number;
        dummyStock?:number;
        balance?:number;
        variant?:number;
        masterBrandId?:number;
        netDrainedWeight?:number;

        /**
         * 
         * @param saleOrderItemId 
         * @param saleOrderId 
         * @param itemId 
         * @param minGrade 
         * @param maxGrade 
         * @param foodTypeId 
         * @param valueAdditionOneId 
         * @param valueAdditionTwoId 
         * @param valueAdditionThreeId 
         * @param valueAdditionFourId 
         * @param cases 
         * @param netAmount 
         * @param unitPrice 
         * @param pouches 
         * @param pouchWeight 
         * @param caseWeight 
         * @param categoryId 
         * @param netWeight 
         * @param uomId 
         * @param freezeStle 
         * @param minGlaze 
         * @param MaxGlaze 
         * @param soakingTime 
         * @param soakingStyle 
         * @param styleWeighment 
         * @param varientId 
         * @param brandId 
       
         */
       
        constructor(saleOrderItemId: number,
            saleOrderId: number,
            itemId: string,
            minGrade: number,
            maxGrade: number,
            foodTypeId: string,
            varientId: string,
            // valueAdditionOneId: number,
            // valueAdditionTwoId: number,
            // valueAdditionThreeId: number,
            // valueAdditionFourId: number,
            cases: number,
            netAmount: number,
            unitPrice: number,
            pouches: number,
            pouchWeight: number,
            caseWeight: number,
            categoryId: number,
            netWeight: number,
            uomId: string,
            // freezeStle: number,
            minGlaze: number,
            MaxGlaze: number,
            soakingTime: string,
            soakingStyle: string,
            styleWeighment: string,
            brandId: number,brand:string,
            dispatchedQty: number,
            invAmount: number,
            currencyId: number,
            reProcessQty?: number,
            rePackQty?: number,
            productId?:number,
            shrimpWeight?:number,
            requiredQuantity?:number, //rm required Qty
            varient?:number,
            hlPercentage?:number,
            finalPercentage?:number,
            jobCases?:number,
            convVariantIds?:number[],
            finalFG?:number,
            dummyStock?:number,
            balance?:number,
            variant?:number,
            masterBrandId?:number,netDrainedWeight?:number

            ){ 
                this.saleOrderItemId = saleOrderItemId
                this.saleOrderId = saleOrderId
                this.itemId = itemId
                this.minGrade = minGrade
                this.maxGrade = maxGrade
                this.foodTypeId = foodTypeId
                this.varientId = varientId
                // this.valueAdditionOneId = valueAdditionOneId
                // this.valueAdditionTwoId = valueAdditionTwoId
                // this.valueAdditionThreeId = valueAdditionThreeId
                // this.valueAdditionFourId = valueAdditionFourId
                this.cases = cases
                this.netAmount = netAmount
                this.unitPrice = unitPrice
                this.pouches = pouches
                this.pouchWeight = pouchWeight
                this.caseWeight = caseWeight
                this.netWeight = netWeight
                this.brandId = brandId
                this.categoryId = categoryId
                this.uomId=uomId
                // this.freezeStle = freezeStle
                this.styleWeighment = styleWeighment
                this.minGlaze = minGlaze
                this.MaxGlaze = MaxGlaze
                this.soakingTime = soakingTime
                this.soakingStyle = soakingStyle
                this.brand = brand
                this.dispatchedQty = dispatchedQty
                this.invAmount = invAmount
                this.currencyId = currencyId
                this.rePackQty = rePackQty
                this.reProcessQty = reProcessQty
                this.productId = productId
                this.shrimpWeight = shrimpWeight
                this.requiredQuantity = requiredQuantity//rm required Qty
                this.varient = varient
                this.hlPercentage = hlPercentage
                this.finalPercentage = finalPercentage
                this.jobCases = jobCases
                this.convVariantIds = convVariantIds
                this.finalFG = finalFG
                this.dummyStock = dummyStock
                this.balance = balance
                this.variant = variant
                this.masterBrandId = masterBrandId
                this.netDrainedWeight = netDrainedWeight
            }
        }
    
