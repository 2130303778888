

export class DefectDto {
    defectId: number;
    defectName: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
}