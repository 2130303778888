export class InternalTransferDto {
    zoneId: number;
    rackId: number;
    saleOrderId: number;
    plantId: number;
    varientId: number;

    constructor(zoneId: number,rackId: number,saleOrderId: number,plantId: number,varientId: number){
        this.zoneId= zoneId;
        this.rackId= rackId;
        this.saleOrderId= saleOrderId;
        this.plantId= plantId;
        this.varientId= varientId;
    }
}