import { OperationTypeEnum, SubPlantCodesEnum, SubPlantOperationStatusEnum } from "@gtpl/shared-models/common-models";

export class ExternalPlantInfo{
    subPlant:SubPlantCodesEnum;
    operation:OperationTypeEnum;
    lotNumber:string;
    boxes:number;
    soNumber:string;
    grade:number;
    quantity:number;
    subPlantOpStatus:SubPlantOperationStatusEnum;
    saleOrderId:number;
    saleOrder:string;
}