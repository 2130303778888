import { CardHeaderBody } from "./card-header-body";

export class BarChartRequest {
    name: string;
    chartCategories:CardHeaderBody[];

    /**
     * 
     * @param name 
     * @param chartCategories 
     */

    constructor(name:string,chartCategories:CardHeaderBody[]){
        this.name = name;
        this.chartCategories = chartCategories;
    }
}