import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { UomsDropDownDto } from "./uoms-drop-down-dto";


export class UomsDropDownResponseModel extends GlobalResponseObject {
    data?: UomsDropDownDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: UomsDropDownDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

