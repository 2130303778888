export class TypesDropDownDto {
  typeId: number;
  type: string;

  /**
   * 
   * @param typeId 
   * @param type 
   */
  constructor(typeId: number, type: string) {
    this.typeId = typeId;
    this.type = type;
  }
}