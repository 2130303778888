import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoAllInspectionsDto } from './dto/so-all-inspections.dto';

export class SoAllInspectionsResponseModel extends GlobalResponseObject {
    data?: SoAllInspectionsDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoAllInspectionsDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

