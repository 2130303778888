export class BrandsDto{
    masterBrandId ?: number;
    masterBrandName : string;
    isPouchVariantConsider:boolean;
    isPouchBapNoConsider:boolean;
    isPouchGradeConsider:boolean;
    isPouchValAddOneConsider:boolean;
    isPouchValAddTwoConsider:boolean;
    isPouchValAddThreeConsider:boolean;
    isPouchValAddFourConsider:boolean;
    isPouchValAddFiveConsider:boolean;
    isPouchFoodTypeConsider:boolean;
    isPouchProductConsider:boolean;
    isCartonVariantConsider:boolean;
    isCartonBapNoConsider:boolean;
    isCartonGradeConsider:boolean;
    isCartonValAddOneConsider:boolean;
    isCartonValAddTwoConsider:boolean;
    isCartonValAddThreeConsider:boolean;
    isCartonValAddFourConsider:boolean;
    isCartonValAddFiveConsider:boolean;
    isCartonFoodTypeConsider:boolean;
    isCartonProductConsider:boolean;
    filePath: string;
    fileName: string;
    brandImage: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    displayBrandName:string;
      
    /**
     * 
     * @param masterBrandId number
     * @param masterBrandName string
     * @param filePath string
     * @param fileName string
     * @param isActive boolean
     * @param createdAt Date
     * @param createdUser string
     * @param updatedAt Date
     * @param updatedUser string
     * @param versionFlag number
     */
    constructor(masterBrandId : number,masterBrandName : string,filePath: string,fileName: string,brandImage:string,isActive: boolean,createdAt:Date | any,createdUser : string,updatedAt: Date | any,
        updatedUser:string,versionFlag:number,isPouchVariantConsider:boolean,
        isPouchBapNoConsider:boolean,
        isPouchGradeConsider:boolean,
        isCartonVariantConsider:boolean,
        isCartonBapNoConsider:boolean,
        isCartonGradeConsider:boolean,displayBrandName:string){
        this.masterBrandId = masterBrandId;
        this.masterBrandName = masterBrandName;
        this. filePath= filePath;
        this. fileName= fileName;
        this. brandImage= brandImage;
        this.isActive= isActive;
        this.createdUser= createdUser;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isPouchBapNoConsider = isPouchBapNoConsider;
        this.isPouchGradeConsider = isPouchGradeConsider;
        this.isPouchVariantConsider = isPouchVariantConsider;
        this.isCartonBapNoConsider = isCartonBapNoConsider;
        this.isCartonGradeConsider = isCartonGradeConsider;
        this.isCartonVariantConsider = isCartonVariantConsider;
        this.displayBrandName = displayBrandName;
    }
}
