import axios from 'axios';
import connection from "../../../masters/src/lib/connection";
import {allNotificationsResponse,JobNotificationDto,jobNotificationResponse} from '@gtpl/shared-models/hrms'

export class jobNotificationStatsService{
  createjobNotification(jobNotificationData: JobNotificationDto) {
    throw new Error('Method not implemented.');
  }
    URl = connection.DEPLOY_URL + "/";
    async create(dto: JobNotificationDto): Promise<jobNotificationResponse>{
       return await axios.post(this.URl+ '/',dto)
        .then(res =>{
            return res.data
        })
    }
        async update(dto:JobNotificationDto) : Promise<jobNotificationResponse>{
            return await axios.post(this.URl+ '/',dto)
            .then(res=> {
                return res.data
            })
        }
        // async getAllJobNotificationStatus(): Promise <allNotificationsResponse>{
        //     return await axios.post(this.URl+ '/getallJobNotificationStatus ',dto)
        //     .then(res =>{
        //         return res.data
        //     })
        // }

    }

