import { FgStockStatusEnum } from './fg-stock-status.enum';
export class BulkStockOutRequest {
    stockId:number;
    status:FgStockStatusEnum;
    updatedUser: string;
    stockOutQty: number;
    stockOutType?:string
    totalStockOutQty?:number
    totalIssuedQty?:number
    issuedQty?:number

    constructor(stockId:number,status:FgStockStatusEnum,updatedUser: string,stockOutQty:number,stockOutType?:string,totalStockOutQty?:number,totalIssuedQty?:number,issuedQty?:number){
        this.stockId = stockId;
        this.status = status;
        this.updatedUser = updatedUser;
        this.stockOutQty = stockOutQty;
        this.stockOutType = stockOutType;
        this.totalStockOutQty = totalStockOutQty;
        this.totalIssuedQty = totalIssuedQty;
        this.issuedQty = issuedQty;
    }
}
