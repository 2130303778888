import { ColumnProps } from "antd/lib/table";
import React, { useEffect, useRef, useState } from "react";
import { Divider, Table, Popconfirm, Card, Tooltip, Switch,Modal, Form, InputNumber, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Empty, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Link from 'antd/lib/typography/Link';
import { SoInfoViewPages } from "@gtpl/pages/sale-management/sale-pages/so-info-view-pages";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { UnitRequest } from "@gtpl/shared-models/common-models";
import { SubPlantOperationsService } from "@gtpl/shared-services/production";


export interface SendAndReceiveTransactionsProps{}

export function SendAndReceiveTransactions(){
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [saleId, setSaleId] =useState(0);
    const plantService = new SubPlantOperationsService();
    const [transactionData , setTransactionData] = useState<any[]>([])


    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
          text ? (
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : text
          )
            : null
    
      });
    
      /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      const handleCancel =()=>{
        setIsModalVisible(false);
      }
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };
      const goToSaleDetailView = (saleOrderId: number) => {
        setSaleId(saleOrderId);
        console.log("soid",saleOrderId);
        setIsModalVisible(true)
      }

      useEffect(()=>{
        getTransactionDetails()
      },[])

      const getTransactionDetails = () => {
        const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
        plantService.getTransactionDetails(req).then(res => {
          if (res.status) {
            setTransactionData(res.data);
          } else {
            if (res.intlCode) {
              setTransactionData([]);
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
            setTransactionData([]);
          AlertMessages.getErrorMessage(err.message);
        })
      }
      
    const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          responsive:['md'],
          render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
          title: 'From Plant',
          dataIndex: 'fromPlantName',
          sorter: (a, b) => a.fromPlantName.localeCompare(b.fromPlantName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('fromPlantName')
        },
        {
          title: 'Sub Plant',
          dataIndex: 'toPlantName',
          sorter: (a, b) => a.toPlantName.localeCompare(b.toPlantName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('toPlantName')
        },
        {
          title: 'Lot Code',
          dataIndex: 'lotNumber',
          sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('lotNumber')
        },
        {
          title: 'Quantity',
          dataIndex: 'inputQuantity',
          render: (value) => {
            return <div style={{ textAlign: 'right' }}>{value}</div>
          }
        },
        {
          title: 'WIP Quantity',
          dataIndex: 'wipQuantity',
          ...getColumnSearchProps('wipQuantity'),
          render: (value) => {
            return <div style={{ textAlign: 'right' }}>{value}</div>
          }
    
        },
        {
          title: 'Customer PO',
          dataIndex: 'poNumber',
          sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('poNumber'),
          render: (value, record) => 
                <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>
        },
        {
          title: 'I/P Boxes',
          dataIndex: 'inputBoxes',
          render: (value) => {
            return <div style={{ textAlign: 'right' }}>{value}</div>
          }
        },
        {
          title: 'O/P Boxes',
          dataIndex: 'outputBoxes',
          render: (value) => {
            return <div style={{ textAlign: 'right' }}>{value}</div>
          }
        },
      ];
      const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
      }
    return(
        <>
         <Card title={<span style={{ color: 'white' }}>Tranasactions</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
         <Table
            rowKey={record => record.saleOrderId}
            columns={columnsSkelton}
            dataSource={transactionData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            scroll={{ x: 1500, y: 500 }}
            bordered
          />
         <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
      >
         <SoInfoViewPages saleOrderId={saleId} screen="production"/>
      </Modal>
         </Card>
        </>
    )
}
export default SendAndReceiveTransactions;