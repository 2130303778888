
export class GradesRequest {
    gradeId: number;
    
    /**
     * 
     * @param gradeId 
     */
    constructor(gradeId: number){
        this.gradeId = gradeId;
    }
}


