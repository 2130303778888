

// this array is used to determine the order of sizes to be displayed in the UI grids
export const sizesDisplayOrderArray = [
    'XXS',
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    'XXXL'
]