export class RejectionReasonDto {
    rejectionReasonId?:number;
    rejectionReasonCode : string;
    rejectionReason: string;
    remarks: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;


}

export const rejectionReasonDtoDefault : RejectionReasonDto = {
    rejectionReason: "",
    rejectionReasonCode:"",
    remarks: "",
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1

};