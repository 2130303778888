import { EndCustomersData } from "../endCuctomer";
import { RackDropDownDto } from "../racks";
import { ZoneDropDownDto } from "../zone_master";

export class RackZoneEmpData{
    rack:RackDropDownDto;
    zone:ZoneDropDownDto;
    endCustomer:EndCustomersData;
    isActive:boolean;
    versionFlag:number;
    rackZoneMappingId?:number;
    constructor(rack:RackDropDownDto, zone:ZoneDropDownDto, endCustomer:EndCustomersData, isActive:boolean, versionFlag:number, rackZoneMappingId?:number){
        this.rack = rack;
        this.zone = zone;
        this.endCustomer = endCustomer;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.rackZoneMappingId = rackZoneMappingId;
    }

}