import { TemperaturesModel } from "./temperatures.model";

export class TemperatureResponseModel {
    date: string;
    deptName: string;
    module:string;
    subModule:string;
    temperature : TemperaturesModel[]
    constructor(date: string,deptName: string,module:string,subModule:string, temperature : TemperaturesModel[]){
        this.date = date;
        this.deptName = deptName;
        this.module = module;
        this.subModule = subModule
        this.temperature = temperature;
    }
}