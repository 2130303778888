import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class GradingInventoryModel {
    subLotNo: string;
    count: number;
    quantity: number;
    issuedQty: number;    
    soNumber?: string;
    lotNo?: string;
    nextOperation?: OperationTypeEnum;
    poNumber?:string;
    saleOrderId?:number;
    concatLotNum?:string;
    constructor(subLotNo: string,count: number,quantity: number,issuedQty: number,soNumber?: string,lotNo?: string,nextOperation?: OperationTypeEnum,poNumber?:string,saleOrderId?:number,concatLotNum?:string){
        this.count = count;
        this.quantity = quantity;
        this.subLotNo = subLotNo;
        this.issuedQty = issuedQty;
        this.soNumber = soNumber;
        this.lotNo = lotNo;
        this.nextOperation = nextOperation;
        this.poNumber = poNumber;
        this.saleOrderId = saleOrderId;
        this.concatLotNum = concatLotNum;
    }
}