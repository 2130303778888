export enum SoLifeCycleStatusEnum {
  OPEN = 'OPEN',
  WORK_IN_PROGRESS = 'Work In Progress',
  Risk1 = 'Risk1',
  NOT_STARTED = 'Not Started',
  PRODUCTION_NOT_STARTED = 'Production Not Started',
  PARTIALLY_COMPLETED = 'Partially Completed',
  SHIPMENT_PENDINGS = 'Shipment Pendings',
  EARLY_SHIPMENT = 'Early Shipment',
  SHIPPED_BUT_NOT_CLOSED = 'Shipped But Not closed',
  ON_TIME_SHIPMENT = 'On-time Shipment',
  DELAY_SHIPMENT = 'Delay Shipment',
  RISK2 = 'Risk2',
  PENDING_FOR_INSPECTION = 'Pending for Inspection',
  READY_FOR_INSPECTION = 'Ready for inspection',
  PENDING_FOR_CONTAINER_SLOT = 'Pending for Container Slot',
  WAITING_FOR_CONTAINER = 'Waiting for Container',
  READY_FOR_INSPECTION_AND_PENDING_FOR_CONTAINER_SLOT = 'Ready for Inspection & Pending for Container slot',
  PENDING_FOR_INSPECTION_AND_CONTAINER_BOOKED = 'Pending for Inspection & Container Booked',
  LOADED_AND_SEALED = 'Loaded and Sealed',
  CLOSED = 'Closed',
  
 
}