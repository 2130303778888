import axios from 'axios';
import connection from './connection';
import { AllExpensesSubCategoryResponse, ExpensesSubCategoryDto, ExpensesSubCategoryResponse} from '@gtpl/shared-models/hrms';

export class ExpensesSubCategoryService{

    URL = connection.DEPLOY_URL + '/expenses-sub-Category';

    async createExpensesSubCategory(expensesSubCategorydto: ExpensesSubCategoryDto ): Promise<ExpensesSubCategoryResponse> {
        console.log(expensesSubCategorydto);
       
        return await axios.post(this.URL + '/createExpensesSubCategory',expensesSubCategorydto)
            .then(res => {
                return res.data
            })
    }

    async getAllExpensesSubCategories(): Promise<AllExpensesSubCategoryResponse> {
                
        return await axios.post(this.URL + '/getAllExpensesSubCategories')
            .then(res => {
                return res.data 
            })     
    }

    async  updateExpensesSubCategory(expensesSubCategorydto: ExpensesSubCategoryDto): Promise<ExpensesSubCategoryResponse> {
        return await axios.post(this.URL + '/updateExpensesSubCategory', expensesSubCategorydto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateExpensesSubCategory(expensesSubCategorydto: ExpensesSubCategoryDto): Promise<ExpensesSubCategoryResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateExpensesSubCategory', expensesSubCategorydto)
                        .then(res => {
                                return res.data
                        })
                }
                
}