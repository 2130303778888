import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, Row, Select, Table, Tooltip } from 'antd';
import { ItemCategoryService, ItemsService, SizesService, TaxesService } from '@gtpl/shared-services/masters';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import './purchase-order-form.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PoDataModel, PoDropDownDto, PoItemsDataModel, PoDto } from '@gtpl/shared-models/procurement-management';
import { ItemCategoriesDto, ItemsDropDownDto, SizesDropDownDto, ItemsReqForCategoryId, TaxesDto, ItemCategoryDropDownDto } from '@gtpl/shared-models/masters';
import TextArea from 'antd/lib/input/TextArea';
import { SaleOrderDto, SODto, SoRequest, BomPackingItemsDto } from '@gtpl/shared-models/sale-management';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
const { Option } = Select;

export interface ItemDetailsFormProps {

  poItemDetails: (itemDetailFormdata: any) => void;
  form: FormInstance<any>
  poData: PoDataModel;
  poItemData: PoItemsDataModel[];
  isUpdate: boolean;
  soData: BomPackingItemsDto[];
  // updateTotalAmount?:(amount:number) =>void;
  transport?: number;
  currency?: any;
}


const ItemDetailsForm = (props: ItemDetailsFormProps) => {
  const form = props.form;
  const [tableData, setTableData] = useState<any[]>();
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [fieldsData, setFieldsData] = useState<any>({});
  const [page, setPage] = React.useState(1);
  const [itemCategories, setItemCategories] = useState<ItemCategoriesDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [tableIndex, settableIndex] = useState<number>(null);
  const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [taxes, setTaxes] = useState<TaxesDto[]>([]);
  const [itemCategoryDetails, setItemCategoryDetails] = useState<ItemCategoryDropDownDto>();
  const [itemDetails, setItemDetails] = useState<ItemsDropDownDto>();
  const [size, setsize] = useState<SizesDropDownDto>();
  const [keyUpdate, setKeyUpdate] = useState<number>(1);
  const [tot, setTot] = useState<any>();
  const [currency, setCurrency] = useState<any>();

  const itemCategoryService = new ItemCategoryService;
  const itemService = new ItemsService;
  const sizeService = new SizesService;
  const taxService = new TaxesService;

  const [showTable, setShowTable] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);

  useEffect(() => {
    if (defaultFormData) {
      form.setFieldsValue({
        itemCategory: defaultFormData.itemCategoryId,
        itemName: defaultFormData.itemDetails.itemName,
        size: defaultFormData.size.sizeId,
        itemQuantity: defaultFormData.itemQuantity,
        itemCost: defaultFormData.itemCost,
        tax: defaultFormData.tax,
        taxPer: defaultFormData.taxPer,
        discount: defaultFormData.discount,
        discountPer: defaultFormData.discountPer,
        subAmount: defaultFormData.subAmount,
        transportation: defaultFormData.transportation,
        otherTerms: defaultFormData.otherTerms,

      });
      // const test=form.getFieldsValue();
      // form.setFieldsValue({...test, glazzing['minGlaze']: defaultFormData.minGlaze,
      // glazzing['maxGlaze']: defaultFormData.maxGlaze})
    }
  }, [defaultFormData])

  useEffect(() => {
    getSizes();
    getTaxes();
    getItemCategories();
    if (props.soData) {
      setShowTable(true)
      // const newEleemet = [{acceptedQuantity:0},{rejectedQuantity:0}]
      // const poData = props.poData.poCreationItemsInfo;
      // const updatedArray = [...poData, {acceptedQuantity:0,rejectedQuantity:0}];
      setTableData(props.soData)
      // setTableData(updatedArray)
      // let object = [...tableData,acceptedQuantity:0]
    }
    if (props.isUpdate) {

      // const dynTableData = [...tableData,props.poItemData];
      setTableData(props.poItemData);
      setShowTable(true);
    }
  }, [])



  const onFinish = values => {
  };

  const onReset = () => {
    form.resetFields();
  };

  const savevalue = (e) => {

  }
  const onAddProduct = () => {
    form.validateFields().then(res => {
      const childIitemDetails = form.getFieldsValue();
      childIitemDetails.itemCategoryDetails = itemCategoryDetails;
      childIitemDetails.itemDetails = itemDetails;
      childIitemDetails.size = size;

      let dynTableData = [...tableData, childIitemDetails];
      setShowTable(true);
      setTableData(dynTableData);
      props.poItemDetails(dynTableData)

      // For Total Amount
      // let totalAmount = 0;
      // tableData.map((items)=>{
      //   items.subAmount;
      // })
      // for(const items of tableData){
      //   console.log(items.subAmount);
      //   totalAmount+=items.subAmount;
      // }

      // props.poItemDetails(dynTableData);
      // props.updateTotalAmount(totalAmount);
      setKeyUpdate(keyUpdate + 1);
      onReset();
    })

  }

  const validateAmount = () => {

    const acceptedQty: number = (form.getFieldValue('itemQuantity')) ? Number(form.getFieldValue('itemQuantity')) : 0;
    const unitPrice: number = (form.getFieldValue('itemCost')) ? Number(form.getFieldValue('itemCost')) : 0;
    const ItemsCost: number = acceptedQty * unitPrice;
    const itemDiscountAMount: number = (form.getFieldValue('discountPer')) ? (ItemsCost * Number(form.getFieldValue('discountPer')) / 100) : 0;
    let taxPercentage = 0;
    if (form.getFieldValue('taxPer')) {
      taxes.map((tax) => {
        if (tax.taxId == form.getFieldValue('taxPer')) {
          taxPercentage = Number(tax.taxPercentage);
        }
      })
    }
    const itemTaxAMount = (taxPercentage) ? ((ItemsCost - itemDiscountAMount) * Number(taxPercentage / 100)) : 0;
    const itemTotCost = (ItemsCost - itemDiscountAMount) + itemTaxAMount;
    const transport: number = (form.getFieldValue('transportation')) ? Number(form.getFieldValue('transportation')) : 0;
    const totalCost = transport + itemTotCost;

    form.setFieldsValue({
      itemQuantity: acceptedQty,
      discount: itemDiscountAMount,
      tax: itemTaxAMount,
      transportation: transport,
      subAmount: totalCost ? totalCost : 0,
    })
  }

  const handleCurrency = () => {
    if (props.currency) {
      setCurrency(props.currency)
    }
  }
  const handleTrans = () => {
    if (props.transport) {
      setTot(props.transport ? props.transport : 0)
    }
  }

  // To set the values to edit
  const setEditForm = (rowData: any, index) => {
    // alert('test');
    setDefaultFormData(rowData);
    setIndexVal(index);
    setBtnDisable(false);
  }

  // const setEditForm = (rowData,index) =>{
  //   console.log(rowData);
  //   form.setFieldsValue({
  //     itemCategory:rowData.itemCategoryId,
  //     itemName:rowData.itemDetails.itemName,
  //     size:rowData.size.sizeId,
  //     itemQuantity:rowData.itemQuantity,
  //     itemCost:rowData.itemCost,
  //     tax:rowData.tax,
  //     taxPer:rowData.taxPer,
  //     discount:rowData.discount,
  //     discountPer:rowData.discountPer,
  //     subAmount:rowData.subAmount,
  //     transportation:rowData.transportation,
  //     otherTerms:rowData.otherTerms,
  //   })
  //   settableIndex(index);
  //   setBtnDisable(false);
  // }

  // To get the Sizes Data
  const getSizes = () => {
    sizeService.getAllSizesDropDown().then((res) => {

      if (res.status) {
        // console.log(res.data);
        setSizes(res.data);
      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }

  // To get the Item Categories
  const getItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then((res) => {

      if (res.status) {
        // console.log(res.data);
        setItemCategories(res.data);
      } else {

        setItemCategories([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategories([]);
    });
  }

  // To get the Items Against Item Category
  const getItems = (itemCategoryId, itemCategoryName) => {
    const itemCategory = new ItemCategoryDropDownDto(itemCategoryId, itemCategoryName.children);
    setItemCategoryDetails(itemCategory);
    const itemsRequest = new ItemsReqForCategoryId(itemCategoryId);
    itemService.getItemByCategoryId(itemsRequest).then((res) => {
      if (res.status) {
        setItems(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  // To Set the Item from dropdown
  const setItem = (itemId, itemName) => {
    const item = new ItemsDropDownDto(itemId, itemName.children);
    setItemDetails(item);
  }

  // To set Size from dropdown
  const setSize = (sizeId, sizeName) => {
    const size = new SizesDropDownDto(sizeId, sizeName.children);
    setsize(size);
  }

  // To delete the row data
  const deleteData = (index) => {
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }


  // const getItems = (val) => {
  //   const itemsRequest = new ItemsReqForCategoryId(val);
  //   itemService.getItemByCategoryId(itemsRequest).then((res) => {

  //     if (res.status) {
  //       // console.log(res.data);
  //       setItems(res.data);
  //     } else {

  //       setItems([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setItems([]);
  //   });
  // }

  // To get the Taxes Data
  const getTaxes = () => {
    taxService.getAllTaxes(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then((res) => {
      // console.log('test')

      if (res.status) {
        // console.log(res.data);
        setTaxes(res.data);
      } else {

        setTaxes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setTaxes([]);
    });
  }

  // Table Columns
  const columns = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },
    {
      title: 'Item Category',
      dataIndex: 'itemCategoryDetails.itemCategoryId',
      key: 'itemCategoryDetails.itemCategoryId',
      render: (value, record: any, index) => {
        return (record.itemCategoryDetails) ? record.itemCategoryDetails.itemCategory : '';
      },
    },
    {
      title: 'Item',
      dataIndex: 'item.itemId',
      key: 'item.itemId',
      render: (value, record: any, index) => {
        return (record.itemDetails) ? record.itemDetails.itemName : '';
      },
    },
    {
      title: 'Size',
      dataIndex: 'size.sizeId',
      key: 'size.sizeId',
      render: (value, record: any, index) => {
        return (record.size) ? record.size.sizeName : '';
      },
    },

    {
      title: 'Quantity',
      dataIndex: 'itemQuantity',
      key: 'itemQuantity',
      render: (text, data, index) => {
        return <span>{data.itemQuantity}</span>
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'itemCost',
      key: 'itemCost',
      render: (text, data, index) => {
        return <span>{data.itemCost}</span>
      }
    },
    {
      title: 'Tax (%)',
      dataIndex: 'taxPer',
      key: 'taxPer',
      render: (text, data, index) => {
        return <span>{data.taxPer}</span>
      }
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax',
      key: 'tax',
      render: (text, data, index) => {
        return <span>{data.tax}</span>
      }
    },

    {
      title: 'Discount (%)',
      dataIndex: 'discountPer',
      key: 'discountPer',
      render: (text, data, index) => {
        return <span>{data.discountPer}</span>
      }
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discount',
      key: 'discount',
      render: (text, data, index) => {
        return <span>{data.discount}</span>
      }
    },
    {
      title: 'Transportation',
      dataIndex: 'transportation',
      key: 'transportation',
      render: (text, data, index) => {
        return <span>{data.transportation}</span>
      }
    },
    {
      title: 'Sub Amount',
      dataIndex: 'subAmount',
      key: 'subAmount',
      render: (text, data, index) => {
        return <span>{data.subAmount}</span>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                // if (rowData) {
                setEditForm(rowData, index);
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title='dlete'>
            <MinusCircleOutlined onClick={() => {
              // if (rowData) {
              // openFormWithData(rowData);
              deleteData(index)
              // }
            }}
              style={{ color: '#1890ff', fontSize: '14px' }} />
          </Tooltip>
        </span>)
    }

  ]

  // To give the Footer Data to table of total amount
  const renderFooterData = (data: any[]) => {
    let totalAmount = 0;
    for (const items of tableData) {
      console.log(items.subAmount);
      totalAmount += items.subAmount;
    }
    const total = totalAmount + tot;
    return (<><span><b>Total Cost : {total ? total : 0} {handleCurrency ? handleCurrency : ''}</b></span></>)
  }

  // To return the Output
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>PO - Item Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Form autoComplete="off" layout="vertical" form={form}>
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item label="Item Category"
                name={'itemCategoryId'}
                rules={[{ required: false, message: 'Missing Item Category' }]}
              >
                <Select placeholder="Select Item Category" onChange={(itemCategoryId, value) => getItems(itemCategoryId, value)} allowClear>
                  {itemCategories.map(dropData => {
                    return <Option key={dropData.itemCategoryId} value={dropData.itemCategoryId}>{dropData.itemCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Name"
                name={'itemName'}
                rules={[{ required: false, message: 'Missing Item Name' }]}
              >
                <Select placeholder="Select Item" onChange={(itemId, value) => setItem(itemId, value)}>
                  {items === undefined ? '' : items.map(dropData => {
                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Size"
                name={'size'}
                rules={[{ required: false, message: 'Missing Item Size' }]}
              >
                <Select
                  placeholder="Select Size"
                  onChange={(sizeId, value) => setSize(sizeId, value)}
                  allowClear
                >
                  {sizes.map(dropData => {
                    return <Option name="sizeId" key={dropData.sizeId} value={dropData.sizeId}>{dropData.sizeName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Quantity"
                name={'itemQuantity'}
                rules={[{ required: false, message: 'Missing Item Quantity' }]}

              >
                <Input onChange={validateAmount} />

              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Cost"
                name={'itemCost'}
                rules={[{ required: false, message: 'Missing Item Cost' }]}
              >
                <Input onChange={validateAmount} />

              </Form.Item>
            </Col>



          </Row>
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item label="Tax (%)"
                name={'taxPer'}
                rules={[{ required: false, message: 'Missing Tax' }]}
              >
                <Select
                  placeholder="Select Tax"
                  // onChange={appendData}
                  allowClear
                  onChange={validateAmount}
                >
                  {taxes.map(dropData => {
                    return <Option name="taxId" key={dropData.taxId} value={dropData.taxId}>{dropData.taxName + " - " + dropData.taxPercentage}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Tax Amount"
                name={'tax'}
                rules={[{ required: false, message: 'Missing Tax Amount' }]}
              >
                <Input onChange={validateAmount} />

              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Discount (%)"
                name={'discountPer'}
                rules={[{ required: false, message: 'Missing Discount' }]}
              >
                <Input onChange={validateAmount} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Discount Amount"
                name={'discount'}
                rules={[{ required: false, message: 'Missing Disocunt Amount' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Transportation"
                name={'transportation'}
                rules={[{ required: false, message: 'Missing Transportation' }]}
              >
                <Input onChange={validateAmount} />

              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Sub Amount"
                name={'subAmount'}
                rules={[{ required: false, message: 'Missing Sub Amount' }]}
              >
                <Input disabled={true} />

              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Other Terms"
                name={'otherTerms'}
                rules={[{ required: false, message: 'Missing Other Terms' }]}
              >
                <Input />

              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>

              <Form.Item >

                <br />
                <Button
                  disabled={btnDisable}
                  style={{ backgroundColor: "#67b32d", color: "white" }}
                  onClick={onAddProduct}
                  block
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item >
                <br />

                <Button style={{ backgroundColor: "#f19c9b", color: "white" }}

                  onClick={onReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </Col>

            {/* <Col span={6}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col> */}
          </Row>

          {
            showTable ?
              <Table scroll={{ x: 1500 }} dataSource={tableData} columns={columns} footer={() => renderFooterData(tableData)} pagination={false} /> : <></>
          }
        </Form>
      </Card>
      <br />
    </>
  );
};

export default ItemDetailsForm;  