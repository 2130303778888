export class AssetsDropDown{
    assetId : number;
    assetCode : string;

    /**
     * 
     * @param assetId 
     * @param assetCode 
     */
    constructor(assetId : number,assetCode : string){
        this.assetId = assetId;
        this.assetCode = assetCode;
    }
}