
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { title } from 'process';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PoStockReq } from '@gtpl/shared-models/procurement-management';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Excel } from 'antd-table-saveas-excel';

export interface SupplyPerformanceReportProps { }

export function SupplyPerformanceReport(props: SupplyPerformanceReportProps) {
    const { Option } = Select;
    const [page, setPage] = React.useState(1);
    const searchInput = useRef(null);
    const [reportData, setReportData] = useState<any[]>([]);
    const service = new PurchaseOrderService();
    const [form] = Form.useForm();
    const [filtersData, setFiltersData] = useState<any[]>([]);
    const [itemSubDrop, setItemSubCat] = useState<any[]>([]);
    const [selectedunit, setUnit] = useState<number>(0)
    const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const unitsService = new UnitcodeService()
    const [vendors, setVendors] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
    const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        //getSupplyPerformanceReport();
        getAllPlants();
        getItemSubCatForPackingStock();
        getVendorsForSupplyPerformance();
        getItemsForSupplyPerformance();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
    }, [])

    const getSupplyPerformanceReport = (onReset?: boolean) => {
        const req = new PoStockReq()
        let unitId = form.getFieldValue('unitId');
        let itemSubcategoryId = form.getFieldValue('itemSubcategoryId');
        let vendorId = form.getFieldValue('vendorId');
        let itemId = form.getFieldValue('itemId');
        let selectedData = filtersData;
        req.fromDate = onReset ? undefined : selectedEstimatedFromDate;
        req.toDate = onReset ? undefined : selectedEstimatedToDate;
        req.unitId = unitId;
        req.itemSubcategoryId = itemSubcategoryId;
        req.vendorId = vendorId;
        req.itemId = itemId;
        if (Number(localStorage.getItem('unit_id')) != 5) {
            req.unitId = Number(localStorage.getItem('unit_id'));
        } else {
            if (form.getFieldValue('unitId') != undefined) {
                req.unitId = form.getFieldValue('unitId')
            }
        };
        service.getSupplyPerformanceReport(req).then((res) => {
            if (res.data) {
                setReportData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
        setReportData(selectedData)
    }
    const EstimatedETDDate = (value) => {
        if (value) {
          const fromDate = new Date(value[0].format('YYYY-MM-DD'));
          const toDate = new Date(value[1].format('YYYY-MM-DD'));
          setSelectedEstimatedFromDate(fromDate)
          setSelectedEstimatedToDate(toDate)
        }
      }
    const getItemSubCatForPackingStock = () => {
        service.getItemSubCatForPackingStock({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setItemSubCat(res.data)
            } else {
                setItemSubCat([])
            }
        });
    }
    const getVendorsForSupplyPerformance = () => {
        service.getVendorsForSupplyPerformance({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setVendors(res.data)
            } else {
                setVendors([])
            }
        });
    }
    const getItemsForSupplyPerformance = () => {
        service.getItemsForSupplyPerformance({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setItems(res.data)
            } else {
                setItems([])
            }
        });
    }
    const onReset = () => {
        form.resetFields();
        setReportData([]);
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
        setSelectedEstimatedFromDate(undefined);
        setSelectedEstimatedToDate(undefined);
    }

    const getAllPlants = () => {
        unitsService.getAllMainPlants().then((res) => {
            if (res.status) {
                setPlantData(res.data);
            } else {
                setPlantData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setPlantData([]);
        })
    }
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('Supply Performance Report')
            .addColumns(data)
            .addDataSource(reportData, { str2num: true })
            .saveAs('sopacking-status-report.xlsx');
    }
    const data = [
        { title: 'Item', dataIndex: 'itemName', },
        { title: 'PO Qty', dataIndex: 'poQty', },
        { title: 'GRN Qty', dataIndex: 'grnQty' },
        { title: 'Balance(+)/Excess(-)', dataIndex: 'balanceAndExcess' },
        { title: 'Excess %', dataIndex: 'excessPercentage' },
        { title: 'Material Ordered %', dataIndex: 'materialOrdered' },
        { title: 'Total Amount', dataIndex: 'totalAmount' },
        { title: 'Money Lost', dataIndex: 'moneyLost' }
    ];

    const Columns: ColumnProps<any>[] = [
        {
            title: '#Sno',
            key: 'sno',
            //width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Item',
            dataIndex: 'itemName',
            width: '300px',
            ...getColumnSearchProps('itemName')
        },
        {
            title: 'PO Qty',
            dataIndex: 'itemQuantity',
            align: 'right'
        },
        {
            title: 'GRN Qty',
            dataIndex: 'grnQty',
            align: 'right'
        },
        {
            title: 'Balance(+)/Excess(-)',
            dataIndex: 'balanceAndExcess',
            align: 'right'
        },
        {
            title: 'Excess %',
            dataIndex: 'excessPercentage',
            align: 'right',
            render: (value, record) => {
                return (record.excessPercentage) ? Math.round(record.excessPercentage) : '-'
            }
        },
        {
            title: 'Material Ordered %',
            dataIndex: 'materialOrdered',
            align: 'right',
            render: (value, record) => {
                return (record.materialOrdered) ? Math.round(record.materialOrdered) : '-'
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            align: 'right',
            render: (value, record) => {
                return (record.totalAmount) ? Math.round(record.totalAmount) : '-'
            }
        },
        // {
        //     title: 'PO Number Count',
        //     dataIndex: 'poCount',
        // },
        {
            title: 'Money Lost',
            dataIndex: 'moneyLost',
            align: 'right',
            render: (value, record) => {
                return (record.moneyLost) ? Math.round(record.moneyLost) : '-'
            }
        },
    ]
    const handleUnit = (value) => {
        setUnit(value)
    }

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Supply Performance Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
                extra={<Button icon={<DownloadOutlined />} onClick={() => { exportExcel(); }} > GET EXCEL </Button>} >
                <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                    <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                            <Form.Item
                                name="unitId"
                                label=" Plant"
                                rules={[
                                    {
                                        required: false, message: 'Select Unit',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Unit"
                                    allowClear
                                    style={{ width: '100%' }}
                                    disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                >
                                    {plantData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="fromDate"
                                label="PO Date"
                                initialValue={undefined}
                                rules={[
                                    {
                                        required: false,
                                        message: "PO Date"
                                    },
                                ]}>
                                <RangePicker onChange={EstimatedETDDate} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="vendorId"
                                label=" Vendor Name"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Vendor Name"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {vendors.map(dropData => {
                                        return <Option value={dropData.vendorId}>{dropData.vendor}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="itemSubcategoryId"
                                label=" Item Sub Category"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Item Sub Category"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {itemSubDrop.map(dropData => {
                                        return <Option value={dropData.itemSubcategoryId}>{dropData.itemSubCat}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                            <Form.Item
                                name="itemId"
                                label=" item Name"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select item Name"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {items.map(dropData => {
                                        return <Option value={dropData.itemId}>{dropData.itemName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Button
                                type="primary"
                                block onClick={() => getSupplyPerformanceReport()} style={{ marginRight: 2, width: 100 }}>
                                Get Report
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={onReset}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {reportData.length > 0 ? <>
                    <Table
                        rowKey={record => record.downtimeTrackingId}
                        columns={Columns}
                        dataSource={reportData}
                        scroll={{ x: 1600, y: 600 }}
                        pagination={{
                            onChange(current, pageSize) {
                                setPage(current);
                            }
                        }}
                        onChange={onChange}
                        size='small'
                        bordered />
                </> : ' '}

            </Card>
        </>
    )
}
export default SupplyPerformanceReport;