export class PoReportRequest{
    fromDate:Date;
    toDate:Date;
    saleOrderId?:number;
    purchaseOrderId:number;
    poStatus:string;
    vendorId:number;
    poType?:string;
    unitId?:number;
    createdDate?:Date
    itemNameId?:number;
    excessPercent?:number;
    lifeCycleStatus?:string;
    itemSubCategoryId?:number;
    
}