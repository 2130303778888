import React, { useEffect, useState } from 'react';

import styles from './packing-dashboard.module.css';
import { Row, Col, Divider, Card, Badge } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SaleOrderService } from "@gtpl/shared-services/sale-management";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Series } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface PackingDashboardProps { }

export function PackingDashboard(
  props: PackingDashboardProps
) {
  const [procurementSeries, setProcurementSeries] = useState<Series[]>([]);
  const [xseries, setXseries] = useState<any[]>([]);


  const saleService = new SaleOrderService();
  useEffect(() => {
    getReqPackingmetirial();
  }, [])


  const getReqPackingmetirial = () => {
    // const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    saleService.packingRequirementForMonthly().then((res) => {
      if (res.status) {
        setProcurementSeries(res.data.data);
        setXseries(res.data.xaxis);
      } else {
        setProcurementSeries([]);
        setXseries([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProcurementSeries([]);
      setXseries([]);
    });
  }
  const constructChartData = () => {

    const options = {  
      chart: {
        type: 'column'
      },
      colors: ['#fc9d03', '#c5fac7', '#dae6f5', '#ff7d95' ],
      title: {
        text: 'Required Packing Material'
      },
      xAxis: {
        categories: xseries,
        crosshair: true
      },
      yAxis: {
        min: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series:procurementSeries
  };
    return options;
  };

  return (
    <Card>
    <Badge.Ribbon text="Dashboard under development" color='#fa541c'>
    <Card className={styles.mainCardSpace}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card className ={styles.cardSpace1}>
            <span>Sale Orders:</span>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card className ={styles.cardSpace2}>
            <span>Purchase Orders:</span>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card className ={styles.cardSpace3}>
             <span>GRN:</span>
          </Card>
        </Col>
      </Row>
    </Card>

    <Card className={styles.mainCardSpace}>
      <Row gutter={24}>
        <Col span={24}>
         <HighchartsReact
              highcharts={Highcharts}
              options={constructChartData()}
            />
        </Col>
      </Row>
    </Card>
    </Badge.Ribbon>
    </Card>
  );
}

export default PackingDashboard;
