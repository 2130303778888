import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GetStoreConsumptionDetails } from './get-store-consumtion.dto';

export class GetStoreConsumptionResponse extends GlobalResponseObject {
    data?: GetStoreConsumptionDetails;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: GetStoreConsumptionDetails){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}