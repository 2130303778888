export class RmReserveRackRequest{
    plantId:number;
    zoneId: number;
    rackId: number;
    rackPositionId: number;
    isReserved: boolean;
    updatedUser: string;
    itemSubCategoryId: number;
    brandId: number;
    
    constructor(plantId:number,zoneId: number,rackId:number,rackPositionId: number,isReserved: boolean,
        updatedUser: string,itemSubCategoryId: number,
        brandId: number){
        this.plantId = plantId;
        this.zoneId = zoneId;
        this.rackId = rackId;
        this.rackPositionId = rackPositionId;
        this.isReserved = isReserved;
        this.updatedUser = updatedUser;
        this.itemSubCategoryId = itemSubCategoryId;
        this.brandId = brandId;
    }
}