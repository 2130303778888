export class ItemCategoryRequest{
    itemCategoryId:number;
    
    constructor(itemCategoryId:number){
        this.itemCategoryId = itemCategoryId;
    }
}
export class ItemCategoryReq {
    itemCategoryId:number;

    /**
     * 
     * @param itemCategoryId 
     */
    constructor(itemCategoryId:number){
        this.itemCategoryId= itemCategoryId;
    }
}