export class IdProofsTypeDto{
    idProofTypeId : number;
    idProofType : string;
    isActive: boolean;
    createdUser : string;
    
    /**
     * 
     * @param idProofsTypeId number
     * @param idProofsType string
     * @param isActive boolean
     * @param createdUser string
     */
    constructor(idProofTypeId : number,idProofType : string,isActive: boolean,createdUser : string){
        this.idProofTypeId = idProofTypeId;
        this.idProofType = idProofType;
        this.isActive= isActive;
        this.createdUser= createdUser;
    }
}
