import { InvoiceCategoriesEnum, StEnum } from "@gtpl/shared-models/common-models";
import { InvoiceCustomerDetailsModel, UnitcodeDto } from "@gtpl/shared-models/masters";
import { SaleOrderItemsDetails } from "./sale-order-items-details";
import { SoDataModel } from "./so-data.model";


export class PlantInvoiceDetailsModel {
  invoiceId: number;
  saleOrderId: number; //saleordernumber-> sale_ordertable   so_invoice_details(table)->paymentterms->ID ->paymentterms(master)
  saleOrderNo: string;
  invoiceNumber: string;
  invoiceDate: any;
  exporterId: number;
  unitId: number;
  customerId: number; //customer(masters)
  clientCode: string;
  clientName: string;
  country: string;
  endCustomerId: number; //endcustomer(master)
  endcustomerCode: string;
  endCustomerName: string;
  flightNumber: string;
  portofloading?: string;
  status: string;
  exchangeRate: number;
  createdAt: Date;
  totalAmount: number;
  total_INR_amount: number;
  isInvoiced: string;
  freightCharges: number;
  saleOrderItems?: SaleOrderItemsDetails[];
  unitName?: string;
  convertedCurrency?: string;
  currency?: number;
  blNO?: string;
  shipmentTerms?: string;
  buyerPoNo?: string;
  custPoNo?: string;
  edl?: Date;
  actualEdl?: string;
  refNo?: string;
  trackingNo?: string;
  vesselName?: string;
  portOfTranshipment?: string;
  portOfDischarge?: string;
  importingVesselName?: string;
  originatingVesselName?: string;
  dateOfTranshipment?: Date;
  shippedOnboardDate?: Date;
  dischargeDate?: Date;
  destinationDate?: Date;
  isTaxApplicable?: string;
  unitData?: UnitcodeDto
  invoicedPlant?: UnitcodeDto;
  manufacturedPlant?: UnitcodeDto;
  lotCode?: string;
  saleCommission?: number;
  insurance?: number;
  finNumber?: string;
  notifyPartyOne?: string;
  notifyPartyTwo?: string;
  notifyPartyThree?: string;
  portOfDischargeId?: number;
  orderDate?: string;
  destinationName?: string;
  notifyPartyFour?: string;

  placeOfReceipt?: string;
  invoiceCategory?: InvoiceCategoriesEnum;
  shipmentDate?: any;
  epcgLicenseNumber?: string;
  epcgLicenseDate?: string;
  epcgFobValue?:number;
  exportObligation?:number;
  remarks?: string;
  displayBrandname?:string;
  consignee?:string;
  invoiceAmount?:number;
  

  /**
   * @param invoiceId
   * @param saleOrderId
   * @param saleOrderNo
   * @param invoiceNumber
   * @param invoiceDate
   * @param exporterId
   * @param unitId
   * @param customerId
   * @param clientCode
   * @param clientName
   * @Param country
   * @param endCustomerId
   * @param endcustomerCode
   * @param endCustomerName
   * @param flightNumber
   * @param status
   * @param exchangeRate
   * @param createdAt
   * @Pram totalAmount
   * @Param total_INR_amount
   * @param isInvoiced
   * @param saleOrderItems
   * @param freightCharges
   * @param lotCode
   * @param destinationName
   * @param notifyPartyFour
   * @param placeOfReceipt
   * @param invoiceCategory
   */
  
  constructor(invoiceId: number, saleOrderId: number, saleOrderNo: string, invoiceNumber: string, invoiceDate: string, exporterId: number, unitId: number, customerId: number, clientCode: string, clientName: string, country: string, endCustomerId: number, endcustomerCode: string, endCustomerName: string, flightNumber: string, status: string, exchangeRate: number, createdAt: Date, totalAmount: number, total_INR_amount: number, isInvoiced: string, freightCharges: number, saleOrderItems?: SaleOrderItemsDetails[], unitName?: string, convertedCurrency?: string, currency?: number, blNO?: string, shipmentTerms?: string, buyerPoNo?: string, custPoNo?: string, edl?: Date, actualEdl?: string, refNo?: string,
    trackingNo?: string,
    vesselName?: string,
    portOfTranshipment?: string,
    portOfDischarge?: string,
    importingVesselName?: string,
    originatingVesselName?: string,
    dateOfTranshipment?: Date,
    shippedOnboardDate?: Date,
    dischargeDate?: Date,
    destinationDate?: Date, isTaxApplicable?: string, unitData?: UnitcodeDto, invoicedPlant?: UnitcodeDto, lotCode?: string,
    portofloading?: string, saleCommission?: number, insurance?: number, finNumber?: string, notifyPartyOne?: string,
    notifyPartyTwo?: string,
    notifyPartyThree?: string, portOfDischargeId?: number, orderDate?: string, destinationName?: string, manufacturedPlant?: UnitcodeDto, notifyPartyFour?: string, placeOfReceipt?: string, invoiceCategory?: InvoiceCategoriesEnum, shipmentDate?: any,epcgLicenseNumber?: string,epcgLicenseDate?: string,epcgFobValue?:number,exportObligation?:number,remarks?: string,displayBrandname?:string,consignee?:string,invoiceAmount?:number,
  ) {
    this.invoiceId = invoiceId;
    this.saleOrderId = saleOrderId;
    this.saleOrderNo = saleOrderNo;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.exporterId = exporterId;
    this.unitId = unitId;
    this.customerId = customerId;
    this.clientCode = clientCode;
    this.clientName = clientName;
    this.country = country;
    this.endCustomerId = endCustomerId;
    this.endcustomerCode = endcustomerCode;
    this.endCustomerName = endCustomerName;
    this.flightNumber = flightNumber;
    this.status = status;
    this.exchangeRate = exchangeRate;
    this.createdAt = createdAt;
    this.totalAmount = totalAmount;
    this.total_INR_amount = total_INR_amount;
    this.isInvoiced = isInvoiced;
    this.freightCharges = freightCharges;
    this.saleOrderItems = saleOrderItems;
    this.unitName = unitName;
    this.convertedCurrency = convertedCurrency;
    this.currency = currency;
    this.blNO = blNO;
    this.shipmentTerms = shipmentTerms;
    this.buyerPoNo = buyerPoNo;
    this.custPoNo = custPoNo;
    this.edl = edl;
    this.actualEdl = actualEdl;
    this.refNo = refNo;
    this.trackingNo = trackingNo;
    this.vesselName = vesselName;
    this.portOfTranshipment = portOfTranshipment;
    this.portOfDischarge = portOfDischarge;
    this.importingVesselName = importingVesselName;
    this.originatingVesselName = originatingVesselName;
    this.dateOfTranshipment = dateOfTranshipment;
    this.shippedOnboardDate = shippedOnboardDate;
    this.dischargeDate = dischargeDate;
    this.destinationDate = destinationDate;
    this.isTaxApplicable = isTaxApplicable;
    this.unitData = unitData;
    this.invoicedPlant = invoicedPlant;
    this.lotCode = lotCode;
    this.portofloading = portofloading;
    this.saleCommission = saleCommission;
    this.insurance = insurance;
    this.finNumber = finNumber;
    this.notifyPartyOne = notifyPartyOne;
    this.notifyPartyTwo = notifyPartyTwo;
    this.notifyPartyThree = notifyPartyThree;
    this.portOfDischargeId = portOfDischargeId;
    this.orderDate = orderDate;
    this.destinationName = destinationName;
    this.manufacturedPlant = manufacturedPlant;
    this.notifyPartyFour = notifyPartyFour;
    this.placeOfReceipt = placeOfReceipt;
    this.invoiceCategory = invoiceCategory;
    this.shipmentDate = shipmentDate;
    this.epcgLicenseNumber = epcgLicenseNumber;
    this.epcgLicenseDate = epcgLicenseDate;
    this.epcgFobValue = epcgFobValue;
    this.exportObligation = exportObligation;
    this.remarks = remarks;
    this.displayBrandname = displayBrandname;
    this.consignee = consignee;
    this.invoiceAmount = invoiceAmount;
    
  }
}