export class HsnCodeRequestByVal{
    foodTypeId : number;
    valueAddOneId : number;
    valueAddTwoId : number;
    valueAddThreeId : number;
    valueAddFourId : number;
    valueAddFiveId : number;
    productId : number;
    
    constructor(foodTypeId : number,
        valueAddOneId : number,
        valueAddTwoId : number,
        valueAddThreeId : number,
        valueAddFourId : number,
        valueAddFiveId : number,productId : number)
    {
        this.foodTypeId = foodTypeId;
        this.valueAddOneId = valueAddOneId;
        this.valueAddTwoId = valueAddTwoId;
        this.valueAddThreeId = valueAddThreeId;
        this.valueAddFourId = valueAddFourId;
        this.valueAddFiveId = valueAddFiveId;        
        this.productId = productId;        
    }
}
