import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { CertificationsDropDownData } from './certifications-drop-down-model';

export class CertificationsDropDownDataResponseModel extends GlobalResponseObject{
    data?: CertificationsDropDownData[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //CertificationsDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: CertificationsDropDownData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
