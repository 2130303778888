export class PlantOperationCapacityDto{
    plantOperationId:number;
    plantId:number;
    // plantName:string;
    // operationName:string;
    operationId:number;
    mincount:number;
    maxcount:number;
    isActive: boolean;
    createdUser :string ;
    updatedUser : string;
    versionFlag : number;
    updatedAt : Date;
    constructor(  plantOperationId:number,plantId:number,operationId:number,mincount:number,maxcount:number,isActive: boolean,createdUser :string ,updatedUser : string,versionFlag : number,updatedAt : Date){
    this.plantOperationId=plantOperationId;
    this.operationId=operationId;
    this.plantId=plantId;
    this.mincount=mincount;
    this.maxcount=maxcount;
    this.isActive= isActive;
    this.createdUser =createdUser ;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
    this.updatedAt = updatedAt;
    }
}