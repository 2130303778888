import { ApiProperty } from '@nestjs/swagger';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, ItemSubCategoryDropDownDto, SizesDropDownDto, TaxDropDownDto } from '@gtpl/shared-models/masters';

export class PoItemsDataModel {
    purchaseOrderItemId: number;
    purchaseOrderId: number;
    itemCategory: ItemCategoryDropDownDto;
    itemName: ItemsDropDownDto;
    size: SizesDropDownDto;
    itemQuantity: number;
    itemCost: number;
    discount: number;
    tax: number;
    subAmount: number;
    taxPer: TaxDropDownDto;
    discountPer: number;
    prevAcceptedQty: number;
    received_quantity: number;
    transportation: number;
    saleOrderId?: number;
    soNumber?: string;
    itemSubCategory?: ItemSubCategoryDropDownDto;
    poNumber?: string;
    soStatus?: string;
    isTransferred?: boolean;

    /**
     * @param purchaseOrderItemId
    * @param purchaseOrderId
    * @param itemCategory
    * @param itemName
    * @param size
    * @param itemQuantity
    * @param itemCost
    * @param discount
    * @param tax
    * @param  subAmount
    * @param taxPer
    * @param discountPer  
    * @param prevAcceptedQty  
    * @param received_quantity
    * @param transportation
    * @param saleOrderId
    * @param soNumber
     */

    constructor(purchaseOrderItemId: number, purchaseOrderId: number, itemCategory: ItemCategoryDropDownDto, itemName: ItemsDropDownDto, size: SizesDropDownDto, itemQuantity: number, itemCost: number, discount: number, tax: number, subAmount: number, taxPer: TaxDropDownDto, discountPer: number, prevAcceptedQty: number, received_quantity: number, transportation: number,saleOrderId?: number,soNumber?: string,itemSubCategory?: ItemSubCategoryDropDownDto,poNumber?: string,soStatus?:string,isTransferred?: boolean
        ) {
        this.purchaseOrderItemId = purchaseOrderItemId;
        this.purchaseOrderId = purchaseOrderId;
        this.itemCategory = itemCategory;
        this.itemName = itemName;
        this.size = size;
        this.itemQuantity = itemQuantity;
        this.itemCost = itemCost;
        this.discount = discount;
        this.tax = tax;
        this.subAmount = subAmount;
        this.taxPer = taxPer;
        this.discountPer = discountPer;
        this.prevAcceptedQty = prevAcceptedQty;
        this.received_quantity = received_quantity;
        this.transportation = transportation;
        this.saleOrderId = saleOrderId;
        this.soNumber = soNumber;
        this.itemSubCategory = itemSubCategory;
        this.poNumber = poNumber;
        this.soStatus = soStatus;
        this.isTransferred = isTransferred;
    }


}

