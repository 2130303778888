import { RackPositionTypeEnum } from "../enums";

export class RackModel{
    rackPositionId: number;
    rackPositionCode: string;
    plantId: number;
    assocRfId: string;
    rackPosition: string;
    rackLevel: number;
    rackBoundaries: string;
    occupancy: string;
    createdDate: Date;
    modifiedDate: Date;
    isActive:boolean;
    versionFlag:number;
    rackMasterId: number;
    rackMasterName: string;
    rackMasterCode: string;
    rackPositionType?: RackPositionTypeEnum;



    /**
     * 
     * @param rackPositionId 
     * @param rackPositionCode 
     * @param plantId 
     * @param rfIds 
     * @param rackPosition 
     * @param rackLevel 
     * @param rackBoundaries 
     * @param occupancy 
     * @param createdDate 
     * @param modifiedDate 
     * @param versionFlag
     * @param rackMasterId 
     * @param rackMasterName 
     * @param rackMasterCode
     */
     constructor(rackId:number,rackCode:string,plantId: number,assocRfId: string,rackPosition: string,rackLevel: number,rackBoundaries: string,occupancy: string,createdDate: Date, modifiedDate: Date, isActive:boolean,versionFlag:number,rackMasterId: number, rackMasterName: string, rackMasterCode: string,rackPositionType?: RackPositionTypeEnum){
        this.rackPositionId = rackId;
        this.rackPositionCode = rackCode;
        this.plantId = plantId;        
        this.assocRfId = assocRfId;
        this.rackPosition = rackPosition;
        this.rackLevel = rackLevel;
        this.rackBoundaries = rackBoundaries;
        this.occupancy = occupancy;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.rackMasterId = rackMasterId;
        this.rackMasterName = rackMasterName;
        this.rackMasterCode = rackMasterCode;
        this.rackPositionType = rackPositionType;
    }
}