import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownTimeTrackingDto } from '@gtpl/shared-models/masters';
import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import moment from 'moment';
import { ProdInvResponseModel } from '@gtpl/shared-models/production-management';


export interface DowntimeSummaryTableProps{}
export function DowntimeSummaryDetailsTable(ProdInvResponseModel:DowntimeSummaryTableProps){
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    

    const downtimetrackingService = new DownTimeTrackingService();
    const [downtimeTrackingData, setDownTimeTrackingData] = useState<DownTimeTrackingDto[]>([]);

    /**
     * used for column filter
     * @param dataIndex column data index
     */
     const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: '#',
            key: 'sno',
            width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Department',
            dataIndex: 'departmentname',
            width:'75px',
   
        },
        {
            title: 'Nof.Resources',
            dataIndex: 'resources',
            width:'90px',
            align:'right',
            // responsive: ['lg'],
          
        },
        {
            title: 'Standard Hours',
            //dataIndex: '',
            align:'right',
          width:'90px',
            render: (value, record) => {

                 return 10;

            }
        },
        {
            title: 'Total Downtime(hrs)',
            dataIndex: 'hours',
         width:'90px',
            align:'left',
            render: (value, record) => {

                 return record.hours;

            }
        },
        {
            title: 'Utilisation',
            dataIndex:'utilization',
              width:'90px',
            align:'right',
            render:(value,record) =>{
                return Math.round(record.utilization) + '%';
            }
   
        },
    ];
    /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    useEffect(() => { gettrackingDepartmentData(); }, [])

    const gettrackingDepartmentData = () => {
        
        downtimetrackingService.getTotalDowntimeDepartmentWise().then(res => {
            if (res.status) {
                setDownTimeTrackingData(res.data);
            } else {
                if (res.intlCode) {
                    setDownTimeTrackingData([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setDownTimeTrackingData([]);
        })
    }

    /**
        * 
        * @param variantData 
        */


    /**
      * 
      * @param VariantViewData 
      */

    return (
        <Table
    
        rowKey={record => record.downtimeTrackingId}
            columns={columnsSkelton}
            dataSource={downtimeTrackingData}
         
            scroll={{ y:200 }}
           // onChange={onChange}
           pagination={false}
         

            size='small'
            bordered />

    );

}
export default DowntimeSummaryDetailsTable;
