import { GlobalResponseObject } from "../global-response-object";
import { SoQntDetail } from "./so-qnt-details";


export class SoQntDetailResponse extends GlobalResponseObject {
    data?: SoQntDetail[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoQntDetail[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}