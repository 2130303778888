import React, { useState, useEffect, useRef } from "react";
import { ItemsDropDownDto, MasterBrandsDropDownDto, PlantsDropDown, ZoneDto } from "@gtpl/shared-models/masters";
import { Button, Card, Col, DatePicker, Empty, Form, Input, Row, Select, Statistic } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import Table, { ColumnProps } from "antd/lib/table";
import { ItemsService, MasterBrandService, SkuService, UnitcodeService, ZoneService } from "@gtpl/shared-services/masters";
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Shifts, DPReportRequest } from "@gtpl/shared-models/warehouse-management";
import { StockTypeDropDown } from "@gtpl/shared-models/common-models";
import { FGStockService } from "@gtpl/shared-services/warehouse-management";
import moment from "moment";
import { Excel } from "antd-table-saveas-excel";
import jsPDF from "jspdf";

export interface DummyPackingReportProps {

}

export function DummyPackingReport(props: DummyPackingReportProps) {

    const { RangePicker } = DatePicker;
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = useState<number>(null);
    const [disable, setDisable] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [form] = Form.useForm();
    let stockType = StockTypeDropDown;
    const [filtersData, setFiltersData] = useState<any[]>([]);
    const [selectedunit, setUnit] = useState<number>(0);
    const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
    const [brandsDropDown, setBrandsDropDown] = useState<any[]>([]);
    const [zoneDropDown, setZoneDropDown] = useState<ZoneDto[]>([]);
    const [dprReport, setDprReport] = useState<any[]>([]);
    const [product, setProduct] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [varientCode, setVarientCode] = useState<number>(0);
    const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
    const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
    const [valAdd1, setValAdd1] = useState([]);
    const [valAdd2, setValAdd2] = useState([]);
    const [valAdd3, setValAdd3] = useState([]);
    const [valAdd4, setValAdd4] = useState([]);
    const [valAdd5, setValAdd5] = useState([]);

    const [foodtypeData, setFoodTypeData] = useState([])
    const [items, setItems] = useState<ItemsDropDownDto[]>([]);
    let totalQty = 0;
    const { Option } = Select;

    const unitsService = new UnitcodeService();
    const brandsService = new MasterBrandService();
    const zoneService = new ZoneService();
    const productService = new SkuService();
    const service = new FGStockService();
    const itemService = new ItemsService();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        console.log(selectedKeys);
        console.log(confirm);
        console.log(dataIndex);
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    }

    const handleUnit = (value) => {
        setUnit(value)
    }

    const onSearch = (val) => {
        console.log('search:', val);
    }

    const handleVarient = (value) => {
        setVarientCode(value)
    }

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }

                    onPressEnter={() => {
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                {console.log(selectedKeys)}
                <Button
                    type="primary"
                    onClick={() => {
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                )
            ) : null,
    });

    const getAllPlants = () => {
        unitsService.getAllMainPlants().then((res) => {
            if (res.status) {
                setPlantData(res.data);
            } else {
                setPlantData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setPlantData([]);
        })
    }



    const getValAdditionsData = () => {
        service.getValueAdditionsFiltersData().then((res) => {
            if (res.status) {
                setValAdd1(res.data)
            } else {
                setValAdd1([])
            }
        })
    }

    const getValAdditionTwoData = () => {
        service.getValueAdditionsTwoFiltersData().then((res) => {
            if (res.status) {
                setValAdd2(res.data)
            } else {
                setValAdd2([])
            }
        })
    }

    const getValAdditionThreeData = () => {
        service.getValueAdditionsThreeFiltersData().then((res) => {
            if (res.status) {
                setValAdd3(res.data)
            } else {
                setValAdd3([])
            }
        })
    }

    const getValAdditionFourData = () => {
        service.getValueAdditionsfourFiltersData().then((res) => {
            if (res.status) {
                setValAdd4(res.data)
            } else {
                setValAdd4([])
            }
        })
    }

    const getValAdditionFiveData = () => {
        service.getValueAdditionsfiveFiltersData().then((res) => {
            if (res.status) {
                setValAdd5(res.data)
            } else {
                setValAdd5([])
            }
        })
    }

    const getFoodTypesDropDown = () => {
        service.getFoodTypeFiltersData().then((res) => {
            if (res.status) {
                setFoodTypeData(res.data)
            } else {
                setFoodTypeData([])
            }
        })
    }



    // const getZones = () => {
    //     zoneService.getPlantZones({ plantId: Number(localStorage.getItem('unit_id')) }).then((res) => {
    //         if (res.status) {
    //             // console.log(res.data);
    //             setZoneDropDown(res.data);
    //         } else {
    //             if (res.intlCode) {
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             } else {
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             }
    //             setZoneDropDown([]);
    //         }
    //     }).catch((err) => {
    //         AlertMessages.getErrorMessage(err.message);
    //         setZoneDropDown([]);

    //     });
    // }

    const getBrandData = () => {
        service.getBrandDropForDummyPackingReport({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
            if (res.status) {
                setBrandsDropDown(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getProducts = () => {
        service.getVariantDropForDummyPackingReport({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
            if (res.status) {
                setProduct(res.data)
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getItems = () => {
        setItems([]);
        itemService.getItemsDropDownData().then((res) => {
            if (res.status) {
                setItems(res.data);
            } else {
                setItems([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    const getDummyPackingReport = (onReset?: boolean) => {
        setDisable(true);
        setLoading(true);
        const req = new DPReportRequest;
        let stockType = form.getFieldValue('stockType');
        let zoneId = form.getFieldValue('zoneId');
        let masterBrandId = form.getFieldValue('masterBrandId');
        let skuCodeId = form.getFieldValue('skuCodeId');
        let shift = form.getFieldValue('shift');
        let selectedData = filtersData;
        req.fromDate = onReset ? undefined : selectedEstimatedFromDate;
        req.toDate = onReset ? undefined : selectedEstimatedToDate;
        req.stockType = stockType;
        req.masterBrandId = masterBrandId;
        req.shift = shift;
        req.skuCodeId = skuCodeId;
        req.zoneId = zoneId;
        req.foodTypeId = form.getFieldValue('foodTypeId')

        if (Number(localStorage.getItem('unit_id')) != 5) {
            req.unitId = Number(localStorage.getItem('unit_id'));
        } else {
            req.unitId = selectedunit
        }
        service.getDummyPackingReport(req).then(res => {
            if (res.status) {
                setDprReport(res.data);
                // for (const StockReport of res.data) {
                //     const packStyle =  (StockReport.packingStyle)
                //     StockReport.packingStyle = packStyle
                //   }
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                if (res.intlCode) {
                    setDprReport([]);
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }
        }).catch(err => {
            setDprReport([]);
            AlertMessages.getErrorMessage(err.message);
        })
        setDprReport(selectedData);
    }

    const EstimatedCreatedDate = (value) => {
        const fromDate = new Date(value[0].format('YYYY-MM-DD'));
        const toDate = new Date(value[1].format('YYYY-MM-DD'));
        setSelectedEstimatedFromDate(fromDate)
        setSelectedEstimatedToDate(toDate)
    }

    const onReset = () => {
        form.resetFields();
        setSelectedEstimatedFromDate(undefined);
        setSelectedEstimatedToDate(undefined);
        setDprReport([]);
        getItems();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
    }

    useEffect(() => {
        getAllPlants();
        //getZones();
        getBrandData();
        getProducts();
        getItems();
        getFoodTypesDropDown();
        // getValAdditionsData();
        // getValAdditionTwoData();
        // getValAdditionThreeData();
        // getValAdditionFourData();
        // getValAdditionFiveData();
        // getFoodTypesDropDown()''
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
        form.setFieldsValue({
            fromDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1, 'days')), moment(moment().format("YYYY-MM-DD"))]
        })
        setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1, 'days')))
        setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
    }, [])

    const data = [
        { title: 'Unit', dataIndex: 'unit' },
        { title: 'SO Type', dataIndex: 'soType', render: (value, record) => (record.soType ? record.soType : '-') },
        { title: 'Customer PO', dataIndex: 'customerPO' },
        { title: 'Brand', dataIndex: 'brandName' },
        { title: 'Shift', dataIndex: 'shift' },
        { title: 'Reporting Date', dataIndex: 'reportingDate', render: (value, record) => (record.reportingDate ? moment(record.reportingDate).format('YYYY-MM-DD') : '-') },
        { title: 'Product SKU', dataIndex: 'skuCode' },
        { title: 'Product Category', dataIndex: 'productCategory' },
        { title: 'Packing Style', dataIndex: 'packingStyle' },
        { title: 'Pouch & Carton Shortage', dataIndex: 'pouchShortage' },
        { title: 'Only Carton Shortage', dataIndex: 'cartonShortage' },
        { title: 'Final', dataIndex: 'finalPacking' },
        { title: 'Total', dataIndex: 'totalPacking' },
        { title: 'Qty(LB)', dataIndex: 'weight' }
    ];

    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('dprReport')
            .addColumns(data)
            .addDataSource(dprReport, { str2num: true })
            .saveAs('dpr-report.xlsx');
    }

    const exportToPdf = () => {
        var columns = [
            { title: 'Unit', dataKey: 'unit' },
            { title: 'SO Type', dataKey: 'soType', render: (value, record) => (record.soType ? record.soType : '-') },
            { title: 'Customer PO', dataKey: 'customerPO' },
            { title: 'Brand', dataKey: 'brandName' },
            { title: 'Shift', dataKey: 'shift' },
            { title: 'Reporting Date', dataKey: 'reportingDate', render: (value, record) => (record.reportingDate ? moment(record.reportingDate).format('YYYY-MM-DD') : '-') },
            { title: 'Product SKU', dataKey: 'skuCode' },
            { title: 'Product Category', dataKey: 'productCategory' },
            { title: 'Packing Style', dataKey: 'packingStyle' },
            { title: 'Pouch & Carton Shortage', dataKey: 'pouchShortage' },
            { title: 'Only Carton Shortage', dataKey: 'cartonShortage' },
            { title: 'Final', dataKey: 'finalPacking' },
            { title: 'Total', dataKey: 'totalPacking' },
            { title: 'Qty(LB)', dataKey: 'weight' }
        ];
        const doc = new jsPDF()
        // @ts-ignore

        doc.autoTable(columns, dprReport, {
            columnStyles: {
                id: { fillColor: 255 }
            },

            margin: { top: 20 },
            addPageContent: function (data) {
                doc.text("DPR REPORT", 50, 15);
            }
        });
        doc.save('dpr-report.pdf')
    }

    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: 70,
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            width: '100px'
        },
        {
            title: 'SO Type',
            dataIndex: 'soType',
            width: '120px',
            render: (value, record) => {
                return <span>
                    {record.soType ? record.soType : '-'}
                </span>
            }
        },
        {
            title: 'Customer PO',
            dataIndex: 'customerPO',
            width: '100px',
            sorter: (a, b) => a.customerPO?.localeCompare(b.customerPO),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('customerPO')
        },
        {
            title: 'Brand',
            dataIndex: 'brandName',
            width: '120px',
            sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('brandName')
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            width: '100px',
        },
        {
            title: 'Reporting Date',
            dataIndex: 'reportingDate',
            width: '120px',
            sorter: (a, b) => a.reportingDate?.localeCompare(b.reportingDate),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
                return <span>
                    {record.reportingDate ? moment(record.reportingDate).format('YYYY-MM-DD') : '-'}
                </span>
            }
        },
        {  
            title: 'Product SKU',
            dataIndex: 'skuCode',
            width: '280px',
            align: 'center',
            ...getColumnSearchProps('skuCode')
        },
        {
            title: 'Product Category',
            dataIndex: 'productCategory',
            width: '110px',
            align: 'center',
            render: (value, record) => {
                return <span>
                    {record.productCategory ? record.productCategory : '-'}
                </span>
            }
        },
        {
            title: 'Packing Style',
            dataIndex: 'packingStyle',
            width: '100px',
            render:(value,record)=>{
                return record.packingStyle ? record.packingStyle : '-' ;
            }
        },
        {
            title: 'Pouch & Carton Shortage',
            dataIndex: 'pouchShortage',
            width: '110px',
            align: 'right',
            sorter: (a, b) => a.pouchShortage?.localeCompare(b.pouchShortage),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Pouch Shortage')
        },
        {
            title: 'Only Carton Shortage',
            dataIndex: 'cartonShortage',
            width: '110px',
            align: 'right',
            sorter: (a, b) => a.cartonShortage?.localeCompare(b.cartonShortage),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Carton Shortage')
        },
        {
            title: 'Final',
            dataIndex: 'finalPacking',
            width: '80px',
            align: 'right',
            sorter: (a, b) => a.finalPacking - b.finalPacking,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Total',
            dataIndex: 'totalPacking',
            width: '80px',
            align: 'right',
            sorter: (a, b) => a.totalPacking - b.totalPacking,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Qty(LB)',
            dataIndex: 'weight',
            width: '100px',
            align: 'right',
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend']
        }

    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Day Wise Production Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
                <Form layout={"vertical"} autoComplete="off" form={form} >
                    <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                            <Form.Item
                                name="unitId"
                                label=" Unit"
                                rules={[
                                    {
                                        required: false, message: 'Select Unit',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Unit"
                                    allowClear
                                    style={{ width: '100%' }}
                                    onChange={handleUnit}
                                    disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                >
                                    {plantData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 5 }}>
                            <Form.Item
                                label="Brand Name"
                                name='masterBrandId'
                                // fieldKey={[field.fieldKey, 'brandId']}
                                rules={[{ required: false, message: 'Missing brand name' }, { pattern: /^(?!\s*$).+/, message: `Enter Brand Name` }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Please Select Brand"
                                    optionFilterProp="children"
                                    allowClear
                                    onSearch={onSearch}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                >
                                    <Option key={0} value={null}>Please select brand</Option>
                                    {brandsDropDown.map(branddropData => {
                                        return <Option key={branddropData.masterBrandId} value={branddropData.masterBrandId}>{branddropData.masterBrandName}</Option>
                                    })}
                                    |
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="skuCodeId"
                                label="Product SKU"
                                rules={[
                                    {
                                        required: false,
                                        message: "Product is required"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    // style={{ width: 200 }}
                                    placeholder="Select Product"
                                    optionFilterProp="children"
                                    onChange={handleVarient}
                                    allowClear
                                // filterOption={(input, option) =>
                                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                                >
                                    <Option value={null}>Select Product</Option>
                                    {product?.map(product => {
                                        return <Option key={product.skuCodeId} value={product.skuCodeId}> {product.variantCode}  </Option>
                                    })}
                                </Select>
                            </Form.Item></Col >
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                            <Form.Item
                                name="shift"
                                label="Shift"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Shift is required'
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Shift"
                                    // onChange={getSaleOrderItems}
                                    allowClear
                                    mode="multiple"
                                >
                                    <Option value="0">Please select</Option>
                                    {Object.values(Shifts).map(res => {
                                        return <Option value={res}>{res}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="stockType"
                                label="SO Type"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Stock Type is required'
                                    },
                                ]}
                                initialValue={StockTypeDropDown[1].value}
                            >
                                <Select
                                    placeholder="Select Stock Type"
                                    // onChange={getSaleOrderItems}
                                    allowClear
                                    defaultValue={StockTypeDropDown[1].value}
                                >
                                    <Option value="0">Please select</Option>
                                    {stockType?.map(stockType => {
                                        return <Option value={stockType.value}>{stockType.name}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="fromDate"
                                label="Reporting Date"
                                rules={[
                                    {
                                        required: false,
                                        message: "Missing Reporting Date"
                                    },
                                ]}>
                                <RangePicker onChange={EstimatedCreatedDate} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                            <Form.Item
                                name="foodTypeId"
                                label="Food Type"
                                rules={[
                                    {
                                        required: false,
                                        message: 'FoodType is required'
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select FoodType"
                                    // onChange={getSaleOrderItems}
                                    allowClear

                                >
                                    <Option value="0">Please select</Option>
                                    {foodtypeData.filter(element => { return element !== null }).map(res => {
                                        return <Option value={res.foodTypeId}>{res.foodType}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <br /><br />

                        <Col style={{ padding: '20px', marginTop: '30px' }}>
                            <Button type="primary" style={{ marginRight: '4px' }} onClick={() => getDummyPackingReport()}>
                                Get Report
                            </Button>
                            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
                        </Col>
                        <Col span={4}>
                            <Button icon={<DownloadOutlined />} style={{ marginTop: '30px', marginRight: '100px' }} onClick={() => { exportExcel(); }}>
                                Get Excel
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button icon={<FilePdfOutlined />} style={{ marginLeft: '-173px', marginTop: '30px' }} onClick={() => { exportToPdf(); }}>
                                Get PDF
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <br></br>
                {
                    dprReport?.length > 0 ?
                        <>
                            {
                                dprReport?.forEach(item => {
                                    totalQty = Number(item.weight) + Number(totalQty)
                                })
                            }
                            {console.log(totalQty, 'totalQty')}
                            <Card title='Summary'>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Statistic title="Total Transactions:" value={dprReport.length}></Statistic>
                                    </Col>
                                    <Col span={4}>
                                        <Statistic title="Total Quantity(Lbs):" value={totalQty}></Statistic>
                                    </Col>
                                    <Col span={4}>
                                        <Statistic title="Fresh Production(Kgs):" value={Math.round(dprReport.reduce((quantitySum, data) => { if (data.transactionType == 'rm') return (quantitySum + (Number(data.weight))); return quantitySum }, 0) * 0.454).toLocaleString('en-IN')}></Statistic>
                                    </Col>
                                    <Col span={4}>
                                        <Statistic title="Repacking(Kgs):" value={Math.round(dprReport.reduce((quantitySum, data) => { if (data.transactionType == 'repacking') return (quantitySum + (Number(data.weight))); return quantitySum }, 0) * 0.454).toLocaleString('en-IN')}></Statistic>
                                    </Col>
                                    <Col span={4} >
                                        <Statistic title="Reprocessing(Kgs):" value={Math.round(dprReport.reduce((quantitySum, data) => { if (data.transactionType == 'reprocessing') return (quantitySum + (Number(data.weight))); return quantitySum }, 0) * 0.454).toLocaleString('en-IN')}></Statistic>
                                    </Col>
                                    <Col span={4} >
                                        <Statistic title="Block Load Qty(Kgs):" value={Math.round(dprReport.reduce((quantitySum, data) => { if (data.valAddFour == 2) return (quantitySum + (Number(data.weight))); return quantitySum }, 0) * 0.454).toLocaleString('en-IN')}></Statistic>
                                    </Col>

                                </Row>
                            </Card>

                            <Table
                                rowKey={record => record.saleOrderId}
                                columns={columnsSkelton}
                                dataSource={dprReport}
                                pagination={{
                                    onChange(current) {
                                        setPage(current);
                                    },
                                    pageSize: 10
                                }}
                                onChange={onChange}
                                scroll={{ x: 1500, y: 500 }}
                                bordered
                            />
                        </> : <Empty></Empty>
                }
            </Card>
        </>
    )
}

export default DummyPackingReport;