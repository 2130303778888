export class PlantCapacityDto {
    plantCapacityId?: number;
    operation:string;
    plantCode:string;
    machineCapacity:number;
    peopleCapacity:number;
    createdUser:string;
    isActive?: boolean;
    constructor(plantCapacityId:number,operation:string,plantCode:string,machineCapacity:number,peopleCapacity:number,createdUser:string,isActive?:boolean){
        this.plantCapacityId = plantCapacityId;
        this.operation = operation;
        this.plantCode = plantCode;
        this.machineCapacity=machineCapacity;
        this.peopleCapacity=peopleCapacity;
        this.createdUser =createdUser;
        this.isActive = isActive;
    }
}

export const PlantCapacityDtoDefault : PlantCapacityDto = {
    plantCapacityId: 0,
    operation: '',
    plantCode: "",
    machineCapacity:0,
    peopleCapacity:0,
    createdUser:"",
    isActive:true,
};

