import { EmployeeDto } from "@gtpl/shared-models/masters";
import { GetSaleOrderInformationResponseModel, SaleOrderDetailsResponse, SaleOrderDetailViewInfoDTO } from "@gtpl/shared-models/sale-management";
import { StoreConsumptionItemsDto } from "./store-consumption-items.dto";
import { StoreConsumptionStatus } from "./store-consumption.enum";

export class StoreConsumptionDetails {
    consumedDate: Date;
    indentDate: Date;
    saleOrderId: number;
    storeConsumptionCode: string;
    salePerson: number;
    salePersonInfo?: EmployeeDto;
    indentNo: string; 
    status: StoreConsumptionStatus; 
    createdUser: string;
    storeConsumptionItems: StoreConsumptionItemsDto[];
    storeConsumptionId?: number;
    saleOrderInfo?: SaleOrderDetailViewInfoDTO;
    updatedUser?: string | null;
    unitId:number;
    soStatus:string;
    tracebilityCodeId?:number;
    containerStatus?:string;
    saleOrderItemId?:number;
    issuedQuantity?:number;
    itemId?:number;
    stockType?:string;
    data: any;
    itemName?:string;
    soType?:string


    

    /**
     * 
     * @param consumedDate 
     * @param indentDate 
     * @param saleOrderInfo 
     * @param storeConsumptionCode 
     * @param indentNo 
     * @param status 
     * @param createdUser 
     * @param storeConsumptionItems 
     * @param storeConsumptionId 
     * @param updatedUser 
     */
    constructor(consumedDate: Date, indentDate: Date,  saleOrderId: number, storeConsumptionCode: string, indentNo: string, salePerson:number, status: StoreConsumptionStatus, createdUser: string, unitId:number, storeConsumptionItems: StoreConsumptionItemsDto[], soStatus:string, storeConsumptionId?: number,saleOrderInfo?: SaleOrderDetailViewInfoDTO,salePersonInfo?:EmployeeDto, updatedUser?: string | null,tracebilityCodeId?:number,containerStatus?:string, saleOrderItemId?:number,issuedQuantity?:number,itemId?:number,stockType?:string,itemName?:string,soType?:string){
        this.consumedDate = consumedDate;
        this.indentDate = indentDate;
        this.saleOrderId = saleOrderId;
        this.storeConsumptionCode = storeConsumptionCode;
        this.indentNo = indentNo; 
        this.salePerson = salePerson; 
        this.status = status; 
        this.createdUser = createdUser;
        this.storeConsumptionItems = storeConsumptionItems;
        this.storeConsumptionId = storeConsumptionId;
        this.saleOrderInfo = saleOrderInfo;
        this.salePersonInfo = salePersonInfo; 
        this.updatedUser = updatedUser;
        this.unitId = unitId;
        this.soStatus = soStatus;
        this.tracebilityCodeId = tracebilityCodeId;
        this.containerStatus = containerStatus;
        this.saleOrderItemId = saleOrderItemId;
        this.issuedQuantity = issuedQuantity;
        this.itemId = itemId;
        this.stockType = stockType;
        this.itemName = itemName;
        this.soType = soType
    }
}
