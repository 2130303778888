import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { grnQtyDto } from './grn-qty.dto';


export class GrnQtyResponseModel extends GlobalResponseObject {
    data?: grnQtyDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: grnQtyDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

