import { ConversionType } from "@gtpl/shared-models/common-models"

export class ProductConversionReq {
    conversionType: ConversionType
    unitId: number
    itemId?: number
    saleOrderId?: number
    qty?: number
    toSaleOrderId?: number
    soStatus?: string

}