export class SaleOrderProcessDto{

    saleorderprocessTypeId: number;
    saleorderprocessTypeName: string;
    saleorderprocessTypeCode: string;
    remarks: string;
    isActive: boolean;
    constructor(saleorderprocessTypeId:number,saleorderprocessTypeName:string,saleorderprocessTypeCode:string,remarks:string,isActive: boolean)
    {
        this.saleorderprocessTypeCode=saleorderprocessTypeCode;
        this.saleorderprocessTypeId=saleorderprocessTypeId;
        this.remarks=remarks;
        this.saleorderprocessTypeName=saleorderprocessTypeName;
        this.isActive=isActive;
    }
}