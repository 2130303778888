export class ValAddOneDropDownData{
  valueAdditionOneId:number;
    name:string;
    
    /**
     * 
     * @param valueAdditionOneId 
     * @param name 
     */
    constructor(valueAdditionOneId:number,name:string){
      this.valueAdditionOneId = valueAdditionOneId;
      this.name = name;
    }
}