export class TrimsMappingRequset {
    brandId: number;
    packStyleId: number;
    variantId: number;
    endCustomerId: number;
    reqFrom?: string;
    itemId?: number[];
    subcategoryId: number;
    constructor(brandId: number,packStyleId: number,variantId: number,endCustomerId: number,reqFrom?:string,itemId?:number[],subcategoryId?: number) {
            this.brandId= brandId;
            this.packStyleId= packStyleId;
            this.variantId= variantId;
            this.endCustomerId= endCustomerId;
            this.reqFrom= reqFrom;
            this.itemId= itemId;
            this.subcategoryId= subcategoryId;
    }
}

export class TrimsItemMappingRequest{
    trimId:number;
    constructor(trimId:number){
        this.trimId=trimId;

    }
}