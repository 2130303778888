
export class SoNumbersModel {
    saleOrderId: number;
    saleOrderNumber: string;
    poNumber?: string;
    status?: string;
    jobType?: string;
    processingUnit?:number;
   
  
    /**
     * @param saleOrderId
    * @param saleOrderNumber
    
     */
    constructor(saleOrderId: number,saleOrderNumber: string,poNumber?:string,status?:string,jobType?: string,processingUnit?:number){
      this.saleOrderId = saleOrderId;
      this.saleOrderNumber = saleOrderNumber;
      this.poNumber = poNumber;
      this.status = status;
      this.jobType = jobType;
      this.processingUnit = processingUnit;
    }
  }
  
  