import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StoreConsumptionDetails } from '.';
import { IssueDropDownDto } from './store-consumption-drop-down-dto';

export class IssuesDropDownResponse extends GlobalResponseObject {
    data?: IssueDropDownDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: IssueDropDownDto[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}