import axios from 'axios';
import connection from './connection';
import {RolesDto,RolesResponse,AllRolesResponse } from '@gtpl/shared-models/masters'
export class RolesService{
    URL = connection.DEPLOY_URL + '/roles';


    async createRole(rolesdto: RolesDto ): Promise<RolesResponse> {
        console.log(rolesdto);
       
        return await axios.post(this.URL + '/createRole',rolesdto)
            .then(res => {
                return res.data
            })
        }

        async  updateRole(rolesdto: RolesDto): Promise<RolesResponse> {
            return await axios.post(this.URL + '/updateRole', rolesdto)
                            .then(res => {
                                return res.data
                            })
                    }
        
                    async  activatedeActivateRole(rolesDto: RolesDto): Promise<RolesResponse> {
                        //   console.log(State.stateCode);
                        return await axios.post(this.URL + '/activateOrDeactivateRole', rolesDto)
                                .then(res => {
                                    return res.data
                                })
                    }
                    async getAllRoles(): Promise<AllRolesResponse> {
                
                        return await axios.post(this.URL + '/getAllRoles')
                            .then(res => {
                                return res.data 
                            })     
                    }

                    async getAllActiveRoles(): Promise<AllRolesResponse> {
                
                        return await axios.post(this.URL + '/getAllActiveRoles')
                            .then(res => {
                                return res.data 
                            })     
                    }
                    
        
        }
        
    