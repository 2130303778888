import axios from 'axios';
import {
    AllPackingMehodsResponseModel,
  PackingMethodCapacityRequest,
  PackingMethodDto,
  PackingMethodResponseModel,
} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class PackingMethodService {

  // private user;
  // constructor(_user?: string) {
  //   this.user = _user
  // }
  // ue = localStorage.getItem('');

  URL = connection.DEPLOY_URL + '/packing-mathods';
  async createPackingMethod(packingMethod: PackingMethodDto): Promise<PackingMethodResponseModel> {
    return await axios
      .post(this.URL + '/createPackingMethod', packingMethod)
      .then((res) => {
        return res.data;
      });
  }
  async updatePackingMethod(packingMethod: PackingMethodDto): Promise<PackingMethodResponseModel> {
    return await axios
      .post(this.URL + '/updatePackingMethod', packingMethod)
      .then((res) => {
        return res.data;
      });
  }
  async getAllPackingMethod(req?:UserRequestDto): Promise<AllPackingMehodsResponseModel> {
    return await axios.post(this.URL + '/getAllPackingMethods',req)
        .then(res => {
            return res.data
        })
  }
  async activatedActivatePackingMethod(
    packingMethodDto: PackingMethodDto
  ): Promise<PackingMethodResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivatePackingMethod', packingMethodDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActivePackingMethods(): Promise<AllPackingMehodsResponseModel> {
    return await axios.post(this.URL + '/getAllActivePackingMethods').then((res) => {
      return res.data;
    });
  }
  async getActivePackingMethodsCount(): Promise<AllPackingMehodsResponseModel> {
    return await axios.post(this.URL + '/getActivePackingMethodCount').then((res) => {
      return res.data;
    });
  }
  async getPalletCapacity(packingMethodCapacityRequest:PackingMethodCapacityRequest): Promise<PackingMethodResponseModel> {
    return await axios.post(this.URL + '/getPalletCapacity',packingMethodCapacityRequest).then((res) => {
      return res.data;
    });
  }
  async getPalletCapacityById(packingMethodCapacityRequest:PackingMethodCapacityRequest): Promise<PackingMethodResponseModel> {
    return await axios.post(this.URL + '/getPalletCapacityById',packingMethodCapacityRequest).then((res) => {
      return res.data;
    });
  }
}
