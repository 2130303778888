import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { RfidTagModel } from "./rfid-tag.model";

export class RfIdTagResponse extends GlobalResponseObject{
    data?: RfidTagModel;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //RackDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RfidTagModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 