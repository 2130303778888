import connection from "./connection";
import axios from 'axios';
import { CommonResponse } from "@gtpl/shared-models/masters";
import { AssetsCatagoryDto } from "../../../../shared-models/asset-management/src/lib/assets-catagory/assets-catagory-dto";
import {AssetsCatagoryByIdReq} from '../../../../shared-models/asset-management/src/lib/assets-catagory/assets-catagory-id';
import { AssetsCatagoryResponse } from "../../../../shared-models/asset-management/src/lib/assets-catagory/assets-catagory-active-inactive";
import {AssetsClassificationByIdDto} from '../../../../shared-models/asset-management/src/lib/assets-catagory/assets-classification-id-request';

export class AssetCatagoryService {
    URL = connection.DEPLOY_URL + '/assets-catagory';

    async createAssetsCatagory(req: AssetsCatagoryDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetsCatagory',req)
        .then(res => {
            return res.data;
        })
    }

    async updateAssetsClassification(req: AssetsCatagoryDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateAssetsClassification',req)
        .then(res => {
            return res.data;
        })
    }

    async getRecordByAssetsCatagoryId(req: AssetsCatagoryByIdReq) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRecordByAssetsCatagoryId',req)
        .then(res => {
            return res.data;
        })
    }

    async getAllAssetsCatagory() : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsCatagory')
        .then(res => {
            return res.data;
        })
    }

    async activateorDeactivateAssets(req: AssetsCatagoryResponse) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/activateorDeactivateAssets',req)
        .then(res => {
            return res.data;
        })
    }

    async getAllAssetsCatagoryDropDown() : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsCatagoryDropDown')
        .then(res => {
            return res.data;
        })
    }

    async getAssetsCatagoryForAssetsClassificationId(req: AssetsClassificationByIdDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetsCatagoryForAssetsClassificationId',req)
        .then(res => {
            return res.data;
        })
    }


}