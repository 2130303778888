import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AllRackZoneMappingDto } from './all-rack-zone-mapping.dto';
import { RackZoneMappingDTo } from './rack-zone-mapping.dto';



export class AllRackZoneMapResponseModel extends GlobalResponseObject{
    data?: AllRackZoneMappingDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AllRackZoneMappingDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 