import axios from 'axios';
import connection from './connection';
import {OperationDto, OperationSequenceResponseModel, ValAddOperationsModel, VariantNextOpModel, VarientIdRequest, VarientOperationsDTO} from '@gtpl/shared-models/production-management';
import { OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
export class VariantOperationsService{
    URL = connection.DEPLOY_URL + '/variant-operations';

    async createVariantOperation(variantOperationDto: VarientOperationsDTO){
        return await axios.post(this.URL+'/createVariantOperations',variantOperationDto)
            .then(res => {
                return res.data
            })
    }
    async getOperationsAssignedByVarientId(varientIdRequest: VarientIdRequest) : Promise<OperationSequenceResponseModel>{
        return await axios.post(this.URL+'/getOperationsAssignedByVarientId',varientIdRequest)
            .then(res => {
                return res.data
            })
    }
    async getNextOperationForVariantId(inputReq:VariantNextOpModel) : Promise<OperationTypeEnum> {
        return await axios.post(this.URL+'/getNextOperationForVariantId',inputReq)
            .then(res => {
                return res.data
            })
    }

    async getValAddOperations(unitReq:UnitRequest) : Promise<ValAddOperationsModel> {
        return await axios.post(this.URL+'/getValAddOperations',unitReq)
            .then(res => {
                return res.data
            })
    }

    
}