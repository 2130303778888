import { OrderInfo } from "./order-info";

export class CustomersInfo{
    customerName:string;
    children:OrderInfo[];
    rowSpan:number;
    totalQty: number;
    totalRequiredQty: number;
    totalPoQty: number;
    totalGrnQty: number;
    totalAssignedQty: number;
    availableQty: number;
    othersAvailableQty:number;
}