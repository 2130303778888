import { ExitReasonsEnum } from "@gtpl/shared-models/common-models";
export class ExitActionDto{
    exitActionId?: number;
    employeeId: number;
    employeeName?: string;
    exitDate:any;
    exitReason: ExitReasonsEnum;
    remarks: string;
    approvedBy: number;
    approvedByName?: string;
    createdAt: Date | any;
    createdUser: string;
    isActive: boolean;
    updatedUser: string;
    versionFlag: number;
    viewData?: any;
  /**
   * 
   * @param exitActionId 
   * @param employeeId
   * @param employeeName 
   * @param exitDate 
   * @param exitReason 
   * @param remarks 
   * @param approvedBy 
   * @param approvedByName
   * @param createdAt 
   * @param createdUser 
   * @param isActive 
   * @param updatedUser 
   * @param versionFlag 
   */
    constructor(
    exitActionId: number,
    employeeId: number,
    employeeName: string,
    exitDate: any,
    exitReason: ExitReasonsEnum,
    remarks: string,
    approvedBy: number,
    approvedByName: string,
    createdAt: Date | any,
    createdUser: string,
    isActive: boolean,
    updatedUser: string,
    versionFlag: number,
    
    ){
      this.exitActionId = exitActionId;
      this.employeeId = employeeId;
      this.employeeName = employeeName;
      this.exitDate = exitDate;
      this.exitReason = exitReason;
      this.remarks = remarks;
      this.approvedBy = approvedBy;
      this.approvedByName = approvedByName;
      this.createdAt = createdAt;
      this.createdUser = createdUser;
      this.isActive = isActive;
      this.updatedUser = updatedUser;
      this.versionFlag = versionFlag;
    }
}