export class LogInfoData {
    plantId:number;
    fromPlant:string;
    toPlant:string;
    dcNumber:string;
    mrnNumber:string;
    lotNumber:string;
    dcDate:string;
    mrnDate:string;
    receivedDate:string;
    itemName:string;
    itemId:number;
    ipBoxes:number;
    opBoxes:number;
    ipQuantity:number;
    opQuantity:number;
    reportedBy:string;
    receivedBy:string;
    productionLogId:number;
    saleOrderId:number;
    soNumber:number;
    jobNumber:number;

    constructor(plantId:number, fromPlant:string, toPlant:string, dcNumber:string, mrnNumber:string, lotNumber:string, dcDate:string, mrnDate:string, receivedDate:string, itemName:string, itemId:number, ipBoxes:number, opBoxes:number, ipQuantity:number, opQuantity:number, reportedBy:string, receivedBy:string, productionLogId:number, jobNumber:number){
        this.plantId = plantId;
        this.fromPlant = fromPlant;
        this.toPlant = toPlant;
        this.dcNumber = dcNumber;
        this.mrnNumber = mrnNumber;
        this.lotNumber = lotNumber;
        this.dcDate = dcDate;
        this.mrnDate = mrnDate;
        this.receivedDate = receivedDate;
        this.itemName = itemName;
        this.itemId = itemId;
        this.ipBoxes = ipBoxes;
        this.opBoxes = opBoxes;
        this.ipQuantity = ipQuantity;
        this.opQuantity = opQuantity;
        this.reportedBy = reportedBy;
        this.receivedBy = receivedBy;
        this.productionLogId = productionLogId;
        this.jobNumber = jobNumber;
    }
}