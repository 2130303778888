import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import { Alert, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, TimePicker } from "antd";
import { QrcodeOutlined } from '@ant-design/icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AssetActionControlRequest, AssetCodeDto } from "@gtpl/shared-models/asset-management";
import { AssetManagementService } from "@gtpl/shared-services/asset-management";
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import { ItemsService } from '@gtpl/shared-services/masters'
import { ItemBySubcategory } from "libs/shared-models/masters/src/lib/items/item-by-subcategory";

const format = 'HH:mm';

export interface AssetActivityControllerProps {
  isUpdate: boolean;
  closeForm: () => void;
  updateState?: (key: number) => void;
}

export function AssetActivityControllerForm(props: AssetActivityControllerProps) {
  const createdUser = localStorage.getItem("createdUser");
  const assetActionService = new AssetManagementService();
  const [form] = Form.useForm();
  let history = useHistory();
  const dateFormat = "YYYY-MM-DD";
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [startTime, setStartTime] = useState(null);
  const [startButtonVisible, setStartButtonVisible] = useState<boolean>(true);
  const [stopButtonVisible, setStopButtonVisible] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [runningCronPattern, setRunningCronPattern] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [scannerType, setScannerType] = useState<string>()
  const itemsService = new ItemsService()
  const [soakingTubsDrop, setSoakingTubsDrop] = useState<any[]>([])
  const [soakingMAchinesDrop, setSoakingMachinesDrop] = useState<any[]>([])



  useEffect(() => {
    var today = new Date();
    var setStartTim = today.getHours() + ":" + today.getMinutes();
    setStartTime(moment(setStartTim, 'HH:mm').add(150, 'minutes'));
    getSoakingTubs()
    getSoakingMachines()
  }, []);

  const getSoakingTubs = () => {

    const req = new ItemBySubcategory(70)
    itemsService.getAllGeneralItems(req).then((res) => {
      if (res.status) {
        setSoakingTubsDrop(res.data)
      } else {
        setSoakingTubsDrop([])
      }
    }).catch((err) => {
      setSoakingTubsDrop([])

    })
  }

  const getSoakingMachines = () => {
    const req = new ItemBySubcategory(71)
    itemsService.getAllGeneralItems(req).then((res) => {
      if (res.status) {
        setSoakingMachinesDrop(res.data)
      } else {
        setSoakingMachinesDrop([])
      }
    }).catch((err) => {
      setSoakingMachinesDrop([])

    })
  }


  // const onTimeChange = (time, timeString: string) => {
  //   console.log(time.hour() + ':' + time.minutes(), timeString);
  //   setStartTime(timeString);
  // };

  const assetStartControl = async () => {
    form.validateFields().then(res => {
      const startControlReq = new AssetActionControlRequest();
      // const formDate = form.getFieldValue('date').format('YYYY-MM-DD');
      const formDate = moment(new Date()).format('YYYY-MM-DD');
      console.log(form.getFieldsValue())
      console.log(form.getFieldValue('durationOne'))
      const durationOne = form.getFieldValue('durationOne');
      const durationTwo = form.getFieldValue('durationTwo');
      const assetCode = form.getFieldValue('assetCode');
      const tubCode = form.getFieldValue('tubCode');
      startControlReq.assetCode = assetCode;
      startControlReq.tubCode = tubCode;
      startControlReq.durationOne = durationOne;
      startControlReq.durationTwo = durationTwo;
      startControlReq.assetControl = true;
      startControlReq.createdUser = createdUser;
      const timeSelected = startTime.format('HH:mm')
      const startHour = timeSelected.split(':');
      startControlReq.cronJobTime = getCronPatternFor24Format(formDate, 0, Number(durationOne));
      startControlReq.date = formDate;
      startControlReq.jobId = assetCode;
      console.log(startControlReq);
      assetActionService.saveAssetAction(startControlReq).then((resp) => {
        if (resp.status) {
          AlertMessages.getSuccessMessage('Asset action started successfully');
          props.updateState(1)
          history.push('/Asset-grid');
          setStopButtonVisible(true);
          setStartButtonVisible(true);
          setAlertMessage('');
          setRunningCronPattern('');
          form.setFieldsValue({
            assetCode: ''
          })
        } else {
          AlertMessages.getSuccessMessage(resp.internalMessage);
          history.push('/Asset-grid');
          form.setFieldsValue({
            assetCode: ''
          })
          form.setFieldsValue({
            tubCode: ''
          })
        }
      })


    })

  }

  const getCronPatternFor24Format = (date: string, hour: number, min: number) => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + min * 60000);
    console.log(futureDate)
    console.log(futureDate.getMinutes())
    const cronHrs = Number(futureDate.getHours())
    const cronMinutes = Number(futureDate.getMinutes())


    const splitDate = date.split('-');
    console.log(splitDate)
    console.log(cronHrs)
    console.log(cronMinutes)
    const currentdate = new Date();
    const currentMin = currentdate.getMinutes()
    let hours
    let rhours
    let minutes
    let rminutes
    // if(min > 60){
    //   hours = Number(min)/60
    //   rhours = Math.floor(hours);
    //   console.log(rhours)
    //   minutes = (hours - rhours) * 60;
    //   console.log(minutes)
    //   rminutes = Math.round(minutes);
    //   console.log(rminutes)
    // }else{
    //   hours = Number(currentdate.getHours())
    // }
    // const cronHrs = Number(currentdate.getHours())+Number(rhours)
    // const cronMinutes = Number(currentMin)+Number(rminutes)
    // if(cronMinutes > 60){

    // }
    // const minutes = Number(currentMin)+min
    // const time = 
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];
    const dateFormate = new Date(date);
    return `${cronMinutes} ${cronHrs} ${day} ${month} ${dateFormate.getDay()}`;
  }

  const assetStopControl = async () => {
    const startControlReq = new AssetActionControlRequest();
    const formDate = form.getFieldValue('date').format('YYYY-MM-DD');
    const assetCode = form.getFieldValue('assetCode');
    if (assetCode == '') {
      AlertMessages.getErrorMessage('Please Enter/Scan Asset Code');
      history.push('/Asset-grid')
    } else {
      startControlReq.assetCode = assetCode;
      startControlReq.tubCode = assetCode;
      startControlReq.assetControl = false;
      startControlReq.createdUser = createdUser;
      const timeSelected = startTime.format('HH:mm')
      const startHour = timeSelected.split(':');
      startControlReq.cronJobTime = runningCronPattern;
      startControlReq.date = formDate;
      startControlReq.jobId = assetCode;
      await assetActionService.saveAssetAction(startControlReq);
      AlertMessages.getSuccessMessage('Asset action stopped successfully');
      history.push('/Asset-grid');
      setStopButtonVisible(true);
      setStartButtonVisible(true);
      setAlertMessage('');
      setRunningCronPattern('');
      form.setFieldsValue({
        assetCode: ''
      });
    }
  }
  const onTimeChange = (time) => {
    // console.log(time.hour() + ':' + time.minutes(), timeString);
    setStartTime(time);
  };

  const handleCancel = () => {
    setShowModal(false);
  }

  const getWarning = (msg) => {
    console.log(msg)
    Modal.warning({
      title: 'Warning!',
      content: (
        <div>
          <p>{msg}</p>
        </div>
      ),
      onOk() { },
    });
  }

  const assetCodeChange = async (value: any, label: string) => {
    console.log('function called')
    console.log(label)
    setStopButtonVisible(true);
    setStartButtonVisible(true);
    setAlertMessage('');
    setRunningCronPattern('');
    let assetCode
    let qrcodeType
    if (label == 'assetCode') {
      assetCode = form.getFieldValue('assetCode');
      // assetCode = value
      qrcodeType = 'assetCode'
    }
    if (label == 'tubCode') {
      assetCode = form.getFieldValue('tubCode');
      // assetCode = value
      qrcodeType = 'tubCode'
    }
    console.log('assetCode', assetCode);
    if (assetCode != '' && assetCode) {
      const dtoObj = new AssetCodeDto();
      dtoObj.assetCode = assetCode;
      dtoObj.codeType = qrcodeType;
      const assetActionHistory = await assetActionService.getLatestActionOfAsset(dtoObj);
      if (assetActionHistory) {
        assetActionHistory.assetControl ? setStopButtonVisible(false) : setStartButtonVisible(false);
        assetActionHistory.assetControl ? qrcodeType == 'tubCode' ? setAlertMessage(`This Tub is running from ${assetActionHistory.date}`) : setAlertMessage(`This asset is running from ${assetActionHistory.date}`) : '';
        getWarning(qrcodeType == 'tubCode' ? `This Tub is running from ${assetActionHistory.date}` : `This asset is running from ${assetActionHistory.date}`)
        assetActionHistory.assetControl ? setRunningCronPattern(assetActionHistory.cronJobTime) : '';
      } else {
        setStartButtonVisible(false)
      }
    }



  }

  const handleQrScan = (value) => {
    if (scannerType == 'assetCode') {
      assetCodeChange(value, 'assetCode');
      form.setFieldsValue({
        assetCode: value
      })
    }
    else {
      assetCodeChange(value, 'tubCode');
      form.setFieldsValue({
        tubCode: value
      })
    }
    setShowModal(false)
    assetCodeChange(value, 'assetCode')


  }

  return (
    <Card title={<span style={{ color: 'white' }}>Assest Activity Controller</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/Asset-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button></span></Link>} >


      <Form form={form} name="control-hooks" layout="vertical">

        <Row gutter={24}>
          <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} offset={0}>
            <span><QrcodeOutlined onClick={(e) => { setShowModal(true); setScannerType('assetCode') }} style={{ fontSize: '30px', marginTop: '35px' }} /><b>Enter / Scan Asset Code</b></span>

          </Col>
          <Col xs={{ span: 21 }} sm={{ span: 21 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="assetCode"
              label="Asset Code"
              rules={[
                {
                  required: true,
                  message: "Asset Code is required"
                },

              ]}>
              {/* <Input min={1} onBlur={(selected) => assetCodeChange(selected, 'assetCode')} /> */}
              <Select placeholder='Asset code' allowClear showSearch onBlur={(selected) => assetCodeChange(selected, 'assetCode')}>
                {soakingMAchinesDrop.map((sm) => {
                  return <Select.Option value={sm.itemName}>{sm.itemName}</Select.Option>
                })}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="durationOne"
              label="Duration1(In min)"
              rules={[
                {
                  required: true,
                  message: "Duration1 is required"
                },

              ]}>
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} offset={0}>
            <span><QrcodeOutlined onClick={(e) => { setShowModal(true); setScannerType('tubCode') }} style={{ fontSize: '30px', marginTop: '35px' }} /><b>Enter / Scan Tub Code</b></span>

          </Col>
          <Col xs={{ span: 21 }} sm={{ span: 21 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="tubCode"
              label="Saoking Tub Code"
              rules={[
                {
                  required: true,
                  message: "Tub Code is required"
                },

              ]}>
              {/* <Input min={1} onBlur={(selected) => assetCodeChange(selected, 'tubCode')} /> */}
              <Select placeholder='Saoking Tub Code' allowClear showSearch onBlur={(selected) => assetCodeChange(selected, 'tubCode')}>
                {soakingTubsDrop.map((sm) => {
                  return <Select.Option value={sm.itemName}>{sm.itemName}</Select.Option>
                })}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="durationTwo"
              label="Duration2(In min)"
              rules={[
                {
                  required: true,
                  message: "Duration2 is required"
                },

              ]}>
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {/* <Form.Item name="date" label="Date" initialValue={moment()}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                onChange={(selected) => { setFromDate(selected) }}
                showToday={true} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
            <Form.Item
              name="time"
              label="Time"
            >
              <TimePicker key={startTime} defaultValue={startTime} format='HH:mm' onChange={onTimeChange} value={startTime} />
            </Form.Item> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
            <br />
            <Button type="primary" htmlType="submit" onClick={() => { assetStartControl() }} hidden={startButtonVisible}>
              Start
            </Button>
            {/* {(props.isUpdate !== true) &&
              <Button type="primary" danger ghost style={{ margin: '0 14px' }} onClick={() => { assetStopControl() }} hidden={stopButtonVisible}>
                Stop
              </Button>
            } */}
          </Col>
        </Row>
        {/* <Row align="middle">
          <h3 style={{ color: "red" }}>{alertMessage}</h3>
        </Row> */}
      </Form>
      <Modal
        className='qr-code-model'
        key={'modal' + Date.now()}
        width={'90%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showModal}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <QrScanner handleScan={handleQrScan} />
      </Modal>
    </Card>

  )
}
export default AssetActivityControllerForm;