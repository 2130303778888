import { UserRequestDto } from '@gtpl/shared-models/common-models';
import { AllVehiclesResponseModel, VehicleDto, VehicleRequest, VehicleResponseModel } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';

export class VehicleService {
  URL = connection.DEPLOY_URL + '/vehicle-master';

  async createVehicle(vehicleDto: VehicleDto): Promise<VehicleResponseModel> {
    //console.log(vehicleDto);
    return await axios.post(this.URL + '/createVehicle', vehicleDto)
      .then(res => {
        return res.data
      })
  }
  async updateVehicle(vehicleDto: VehicleDto): Promise<VehicleResponseModel> {
    //console.log(vehicleDto);
    return await axios.post(this.URL + '/updateVehicle', vehicleDto)
      .then(res => {
        return res.data
      })
  }

  async getAllVehicles(req?:UserRequestDto): Promise<AllVehiclesResponseModel> {
    return await axios.post(this.URL + '/getAllVehicles',req)
      .then(res => {
        return res.data
      })
  }
  async getAllActiveVehicles(): Promise<AllVehiclesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveVehicles')
      .then(res => {
        return res.data
      })
  }
  async activateorDeactivateVehicle(departmentDto: VehicleDto): Promise<AllVehiclesResponseModel> {
    //console.log(departmentDto);
    return await axios.post(this.URL + '/ActivateorDeactivateVehicle', departmentDto)
      .then(res => {
        return res.data
      })
  }
  async getVehicleByVehId(vehicleRequest: VehicleRequest): Promise<VehicleResponseModel> {
    // console.log('hhhhhhhhhhhhhhhhhhhhhh');
    return await axios.post(this.URL + '/getVehicleByVehId', vehicleRequest)
      .then(res => {
        return res.data
      })
  }

} 