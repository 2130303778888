import { StringifyOptions } from "querystring";

export class StockOutModel {
    stockId: number;
    lotNo: string;
    soNumber: string;
    rackPosition: string;
    quantity: number;
    totalCartons: number;
    totalPouches: number;
    loosePouches: number;
    pouchWeight: number;
    pallet: string;
    coldStorage: string;
    packingCompleted: string;
    palletCode:number;
    stockLocationId:number;
    saleOrderNumber?: string;
    brandName?: string;
    poNumber?:string;
    stockType?:string;
    pouches?:string;
    endCustomer?:string;
    productName? : string;
    variantCode? : string;
    stockOutQty? : number;
    cartonsShortage? : string;
    pouchesShortage? : string;
    productCategory? : string;
    unitName?: string;
    zoneId?: number;
    
   constructor(stockId: number,lotNo: string,soNumber: string,rackPosition: string,quantity: number,totalCartons: number,totalPouches: number,loosePouches: number,pouchWeight: number,pallet:string,coldStorage: string, packingCompleted: string,palletCode:number,stockLocationId:number,brandName?:string,saleOrderNumber?:string, poNumber?:string,stockType?:string,productName?:string,variantCode?: string,stockOutQty?:number,cartonsShortage? : string,pouchesShortage? : string,productCategory? : string,unitName?: string,zoneId?: number){
        this.stockId = stockId;
        this.lotNo = lotNo;
        this.soNumber = soNumber;
        this.rackPosition = rackPosition;
        this.quantity = quantity;
        this.totalCartons = totalCartons;
        this.totalPouches = totalPouches;
        this.loosePouches = loosePouches;
        this.pouchWeight = pouchWeight;
        this.pallet = pallet;
        this.coldStorage = coldStorage;
        this.packingCompleted = packingCompleted;
        this.palletCode = palletCode;
        this.stockLocationId= stockLocationId;
        this.brandName= brandName;
        this.saleOrderNumber= saleOrderNumber;
        this.poNumber=poNumber;
        this.stockType=stockType;
        this.productName = productName;
        this.variantCode = variantCode;
        this.stockOutQty = stockOutQty;
        this.cartonsShortage = cartonsShortage;
        this.pouchesShortage = pouchesShortage;
        this.productCategory = productCategory;
        this.unitName = unitName ;
        this.zoneId = zoneId;
    }
}