import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Tabs, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { AssetMaintenanceService } from 'libs/shared-services/asset-management/src/lib/asset-maintenance';
import { AlertMessages } from "@gtpl/shared-utils/alert-messages";
import moment from "moment";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { DepartmentService } from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByAssetCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto";
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { Excel } from 'antd-table-saveas-excel';
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { BarcodeOutlined } from '@ant-design/icons';
import Scanner from '../../../asset-classification-form/src/lib/Scanner';
import Highlighter from 'react-highlight-words';
import { AssetTypeEnum } from "libs/shared-models/common-models/src/lib/enum/asset-type.enum";

const { Option } = Select;

/* eslint-disable-next-line */
export interface ExpiredDateReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const ExpiredDateReport = (
  props: ExpiredDateReportProps
) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [form] = Form.useForm();
  const [transData, setTransData] = useState<any>([]);
  const [assetsData, setAssetsData] = useState<AssetDto[]>([])
  const service = new AssetService();
  const locationService = new AssetLocationService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const [itemCategory, setItemCategory] = useState([]);
  const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const assetCodeservice = new AssetAssignmentService();
  const [assetTransData, setAssetTransData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<AssetDto[]>([]);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const [code, setCode] = useState(0);
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [length, setLength] = useState('');

  let diff = 0;
  let date;
  let todate;

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });
  useEffect(() => {
    getAssetCode();
    getLocations();
    getItemCategory();
    getDepartments();
    getAllPlants();
    getExpiredAssets();
  }, [])

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getExpiredAssets = () => {
    const req = new AssetByStatus();
    if (form.getFieldValue('assetsCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetsCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('aging') !== undefined) {
      req.aging = form.getFieldValue('aging')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    service.getExpiredAssets(req).then(res => {
      if (res.status) {
        setAssetsData(res.data)
        setLength(res.data.length);
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Expired assets data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const searchHandler = () => {
    getExpiredAssets()
  }
  const resetHandler = () => {
    form.resetFields();
    getExpiredAssets();
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAssetCode = () => {
    const req = new AssetByStatus()
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getallAssets(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })

  }


  const columns: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: "Unit",
      dataIndex: "plant",
      align: "left",
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
    },
    {
      title: "Location",
      dataIndex: "location_name",
      align: "left",
      width: 100,
      ...getColumnSearchProps('location_name'),
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
    },
    {
      title: "Asset Type",
      dataIndex: "asset_type",
      align: "left",
      render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
      filters: [
        {
          text: 'MOVABLE',
          value: 'movable',
        },

        {
          text: 'FIXED',
          value: 'FIXED',
        },
        {
          text: 'SOFTASSETS',
          value: 'softassets',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.asset_type === value;
      },

      ...getColumnSearchProps('asset_type')

    },


    {
      title: "Asset Category",
      dataIndex: "asset_category",
      align: "left",
      sorter: (a, b) => a.asset_category?.localeCompare(b.asset_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_category')
    },
    {
      title: "Asset Name",
      dataIndex: "assets_name",
      align: "left",
      sorter: (a, b) => a.assets_name?.localeCompare(b.assets_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assets_name')
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align: "left",
      ...getColumnSearchProps('assetsCode'),
      sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.assetsCode ? record.assetsCode : '-'}
          </>
        )
      },


    },

    // {
    //   title: "Department",
    //   dataIndex: "department",
    //   align: "left",
    //   ...getColumnSearchProps('department'),
    //   sorter: (a, b) => a.department?.localeCompare(b.department),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.department ? record.department : '-'}
    //       </>
    //     )
    //   },
    // },
    {
      title: "Purchase Date",
      dataIndex: "date_of_purchased",
      align: "right",
      sorter: (a, b) => a.date_of_purchased.localeCompare(b.date_of_purchased),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: "Expire Date",
      dataIndex: "expired_date",
      align: "right",
      sorter: (a, b) => a.expired_date.localeCompare(b.expired_date),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: "Expired age",
      dataIndex: "aging",
      align: "right", 
      sorter: (a, b) => a.aging - b.aging,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        // todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        // date = moment(moment(record.expireDate).format('YYYY-MM-DD'));
        // diff = (todate - date) / 864e5;
        return (
          <>
            {record.aging ? record.aging : null}
          </>
        )
      }
    },

  ];
  const excelData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    {
      title: 'Asset Name', dataIndex: 'assets_name', render: (text, record) => {
        return (
          <>
            {record.assets_name ? record.assets_name : '-'}
          </>
        )
      },
    },
    {
      title: 'Unit', dataIndex: 'plant', render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
    },
    {
      title: 'Location', dataIndex: 'location_name', render: (text, record) => {
        return (
          <>
            {record.location_name ? record.location_name : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Type', dataIndex: 'asset_type', render: (text, record) => {
        return (
          <>
            {record.asset_type ? record.asset_type : '-'}
          </>
        )
      },
    },
    {
      title: 'Asset Code', dataIndex: 'assetsCode', render: (text, record) => {
        return (
          <>
            {record.assetsCode ? record.assetsCode : '-'}
          </>
        )
      },
    },


    {
      title: 'Asset Category', dataIndex: 'asset_category', render: (text, record) => {
        return (
          <>
            {record.asset_category ? record.asset_category : '-'}
          </>
        )
      },
    },

    {
      title: 'Department', dataIndex: 'department', render: (text, record) => {
        return (
          <>
            {record.department ? record.department : '-'}
          </>
        )
      },
    },
    {
      title: 'Purchase Date', dataIndex: 'date_of_purchased', render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Expire Date', dataIndex: 'expired_date', render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Expired age', dataIndex: 'aging', render: (text, record) => {
        return (
          <>
            {record.aging ? record.aging : '-'}
          </>
        )
      },
    },


  ];
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Expired date Report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('Expired Date Assets Report.xlsx');
  }

  function handleAgingFilter(value) {

  }

  return (
    <Card
      title={<span style={{ color: "white" }}>Expired Date Assets Report</span>}
      style={{ textAlign: "center" }} headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={assetsData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}

    >
      <br /><br />
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={45}>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
              <Select
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} />}
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetCodeData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                // defaultValue={'all'}
                placeholder='All Categories'
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={'all'}>All Categories</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Unit' name='plantId' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                placeholder='All Units'
                showSearch
                allowClear>
                   <Option key={'all'} value={'all'}>All Units</Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // defaultValue={'all'}
                placeholder='All Locations'
                showSearch
                allowClear>
                <Option key={'all'} value={''}>All Locations</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label='Department' name='department' >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // defaultValue={'All'}
                placeholder='All Departments'
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All Departments</Option>
                {depart.map((e) => {
                  return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Aging' name='aging'>
              <Select
                placeholder='aging'
                showSearch
                defaultValue={'all'}
                allowClear
                onChange={handleAgingFilter}
              >
                <Option key='all' value='all'>All</Option>
                <Option key='below 1000' value='1000'>below 1000</Option>
                <Option key='1001-2000' value='1001-2000'>1001-2000</Option>
                <Option key='2001-3000' value='2001-3000'>2001-3000 </Option>
                <Option key='above 3000' value='above 3000'>Above 3000</Option>

              </Select>
            </Form.Item>
          </Col><Col span={5}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                // placeholder='assetType'
                showSearch
                defaultValue={'MOVABLE'}
                allowClear
              >
                <Option key='all' value='all'>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col style={{ marginTop: '25px' }}>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                block
                onClick={() => searchHandler()}
              >
                Search
              </Button>
            </Form.Item>
          </Col>

          <Col style={{ marginTop: '25px' }} >
            <Form.Item>
              <Button
                icon={<UndoOutlined />}
                onClick={resetHandler}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
          <Col span={3} style={{ marginTop: '25px' }}>
            <Button >Total no of Records:{length}
            </Button>
          </Col>
        </Row>
      </Form>
      {assetsData.length > 0 ? (<>
        <Table
          columns={columns}
          scroll={{ x: 1400, y: 500 }}
          dataSource={assetsData}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          bordered
        />
        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            modal == 'assetId' ? <Scanner handleScan={handleAssetIdScan} /> : null
            //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal></>) : (
        <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
      )}



    </Card>
  );
}

export default ExpiredDateReport;

