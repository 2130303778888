export class PackingMethodDto{
    packingMethodId?:number;
    packingMethodName:string;
    noOfPouches:number;
    pouchesPerRow:number;
    palletCapacity:number;
    uomId?: number;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    maxPalletCapacity?:number;
    
    /**
     * 
     * @param packingMethodId number
     * @param packingMethodName string
     * @param noOfPuches number
     * @param poucheSperRow number
     * @param palletCapacity number
     * @param uomId number
     * @param createdUser number
     * @param updatedUser number
     * 
     * @param isActive boolean
     */
    constructor(packingMethodId:number,packingMethodName:string,noOfPouches:number,pouchesPerRow:number,palletCapacity:number,uomId:number,createdUser:string,updatedUser:string,isActive:boolean,maxPalletCapacity?:number){
    this.packingMethodId = packingMethodId;
    this.packingMethodName = packingMethodName;
    this.noOfPouches = noOfPouches;
    this.pouchesPerRow = pouchesPerRow;
    this.palletCapacity =  palletCapacity;
    this.uomId =  uomId;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;

    this.isActive = isActive;
    this.maxPalletCapacity = maxPalletCapacity;
    }
} 


