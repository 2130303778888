import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { UomsDto } from './uoms-dto';

export class UomsResponseModel extends GlobalResponseObject{
    data?: UomsDto;
    
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: UomsDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
