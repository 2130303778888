import axios from 'axios';
import connection from './connection';
import { AllDownTimeTrackingResponseModel,DownTimeTrackingResponsemodel,DownTimeTrackingDto,DownTimeTrackingRequest, DowntimePiechartResonseModel, DownTimeDashboardDateRequest, DownTimeFiltersDto, DowntimeMachineNameRequest, DowntimeTrackingMachineNameResponseModell, EmployeeUnitRequest, } from '@gtpl/shared-models/masters';
import { CommonResponse } from '@gtpl/shared-models/production-management';

export class DownTimeTrackingService{
    URL=connection.DEPLOY_URL + '/downtimetracking';

    async createDownTimeTracking(downtimetracking: DownTimeTrackingDto): Promise<DownTimeTrackingResponsemodel> {
        return await axios.post(this.URL + '/createDownTimeTracking',downtimetracking)
            .then(res => {
                return res.data
            })
    }

    async  updateDownTimeTracking(downtimetracking: DownTimeTrackingDto): Promise<DownTimeTrackingResponsemodel> {
        return await axios.post(this.URL + '/updateDownTimeTracking', downtimetracking)
               .then(res => {
                   return res.data
               })
    }


    async getAllDownTimeTrackings(req:EmployeeUnitRequest): Promise<AllDownTimeTrackingResponseModel> {
        return await axios.post(this.URL + '/getAllDownTimeTrackings',req)
            .then(res => {
                return res.data
            })     
        // return new getAllDownTimeReason(true,123,"successful",[new CountryDto(1,"Japan",true)]);
    }

    async  activateOrDeactivateDownTimeTracking(downtimetrackingDto: DownTimeTrackingDto): Promise<DownTimeTrackingResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/activateOrDeactivateDownTimeTracking', downtimetrackingDto)
                  .then(res => {
                      return res.data
                  })
      }


      async  getActiveDownTimeTrackingById(): Promise<AllDownTimeTrackingResponseModel> {
        return await axios.post(this.URL + '/getActiveDownTimeTrackingById')
            .then(res => {
                return res.data
            })
    }



      async  getDowTimeTrackingById(downtimetrackingRequest: DownTimeTrackingRequest): Promise<DownTimeTrackingResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/getDowTimeTrackingById', downtimetrackingRequest)
                  .then(res => {
                      return res.data
                  })
      }

      async gettrackingGroupbyReason(req:DownTimeDashboardDateRequest): Promise<DowntimePiechartResonseModel>{
        return await axios.post(this.URL+'/gettrackingGroupbyReason',req)
        .then(res =>{
            return res.data
        })
    }
    async gettrackingDepartmentData(): Promise<AllDownTimeTrackingResponseModel>{
        return await axios.post(this.URL+'/gettrackingDepartmentData')
        .then(res =>{
            return res.data
        })
    }
    async getTotalDowntimeDepartmentWise(): Promise<AllDownTimeTrackingResponseModel>{
        return await axios.post(this.URL+'/getTotalDowntimeDepartmentWise')
        .then(res =>{
            return res.data
        })
    }

    async getTotalDownTimeType():Promise<AllDownTimeTrackingResponseModel>{
        return await axios.post(this.URL+'/getTotalDownTimeType')
        .then(res =>{
            return res.data
        })

    }


    async getTotalSkippedSlaAndUndeSla():Promise<AllDownTimeTrackingResponseModel>{
        return await axios.post(this.URL+'/getTotalSkippedSlaAndUndeSla')
        .then(res =>{
            return res.data
        })

    }
    async getMachineName(req:DowntimeMachineNameRequest): Promise<CommonResponse>{
        return await axios.post(this.URL+'/getMachineName',req)
        .then(res =>{
            return res.data
        })
    }


    
    async getDownTimeTrackingReport(req:DownTimeFiltersDto): Promise<AllDownTimeTrackingResponseModel> {
        return await axios.post(this.URL + '/getDownTimeTrackingReport',req)
            .then(res => {
                return res.data
            })     
    }

    async downtimeventcalnder(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/downtimeventcalnder')
            .then(res => {
                return res.data
            })     
    }

    async assetCategoryDropDown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetCategoryDropDown')
            .then(res => {
                return res.data
            })     
    }
    
        
}