import { LabellingSamplesDto } from "./labelling-deatils.dto";

export class LabellingDto {
    labellingId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    labellingDate: Date;
    traceabilityCode: string;
    lotNumber: string;
    // product : string;
    // packing : string;
    country: string;
    totalMCs: string;
    // brand: string;
    labellingSamplesInfo: LabellingSamplesDto[];
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    isActive: boolean;
    versionFlag: number;
    item?: string;
    soNumber?:string;
    constructor(
        labellingId: number,
        saleOrderId: number,
        saleOrderItemId: number,
        labellingDate: Date,
        traceabilityCode: string,
        // product : string,
        // packing : string,
        country: string,
        totalMCs: string,
        // brand: string,
        labellingSamplesInfo: LabellingSamplesDto[],
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number

    ) {
        this.labellingId = labellingId;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.labellingDate = labellingDate;
        this.traceabilityCode = traceabilityCode;
        // this.product = product;
        // this.packing = packing;
        this.country = country;
        this.totalMCs = totalMCs;
        // this.brand = brand;
        this.labellingSamplesInfo = labellingSamplesInfo;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}