export class Type1Dto{
    valueAdditionOneId:number;
    code:string;
    name:string;
    remarks:string;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    
    /**
     * 
     * @param valueAdditionOneId 
     * @param code 
     * @param name 
     * @param remarks 
     * @param createdUser
     * @param updatedUser
     * 
     * @param isActive
     */
    constructor(valueAdditionOneId:number,code:string,name:string,remarks:string,createdUser:string,updatedUser:string,isActive:boolean){
    this.valueAdditionOneId = valueAdditionOneId;
    this.code = code;
    this.name = name;
    this.remarks = remarks;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;

    this.isActive = isActive
    }
}