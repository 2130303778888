export class SkuDetails{
    skuCodeDetailId:number;
    skuCodeId: number;
    multipleVarientCode :number[];
    isActive: boolean;
    createdUser: string;
    constructor(
        skuCodeDetailId:number,
        skuCodeId: number,
        multipleVarientCode :number[],
        isActive: boolean,
        createdUser: string

    ){
        this.skuCodeDetailId=skuCodeDetailId;
        this.skuCodeId=skuCodeId;
        this.multipleVarientCode=multipleVarientCode;
        this.isActive=isActive;
        this.createdUser=createdUser;
        
    }
}