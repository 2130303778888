
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Table, Card, Input, Button, Form, Col, Row, Select, Typography, Spin, Statistic,Space } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { FgSaleReportRequest, FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FgStockReportDto } from '@gtpl/shared-models/warehouse-management';
import moment from 'moment';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { ItemSubCategoryDropDownDto, MasterBrandsDropDownDto, PlantsDropDown, SubCatReqForCategoryId } from '@gtpl/shared-models/masters';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemSubCategoryService, MasterBrandService, UnitcodeService } from '@gtpl/shared-services/masters';
import { PoDropDownDto } from '@gtpl/shared-models/procurement-management';
import { CategoriesInput, PackTypeCategory, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
export interface FgStockReportProps {

}

export function FgStockReport(props: FgStockReportProps) {
  let categories = CategoriesInput;
  const Option = Select;
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [disable,setDisable]=useState<boolean>(false);
  const stockService = new FGStockService();
  const stockInService = new StockInService();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [stockData, setStockData] = useState<FgStockReportDto[]>([])
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [selectedunit, setUnit] = useState<number>(0)
  const unitsService = new UnitcodeService();
  const subCategoryService = new ItemSubCategoryService();
  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [soItemData, setSoItemData] = useState<FgStockReportDto[]>([]);
  const [categoryData, setCategoryData] = useState<FgStockReportDto[]>([]);
  const service = new SaleOrderService();
  const [brandsDropDown, setBrandsDropDown] = useState<FgStockReportDto[]>([]);
  const brandsService = new MasterBrandService()
  const [brandfiltersData, setbrandFiltersData] = useState<any[]>([]);
  const [productfiltersData, setproductFiltersData] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([])
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<any[]>([]);

  let role: string;
  let totalCart = 0;
  useEffect(() => {
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
    getAllPlants();
    getBrandData();
    getUnitDropdownForStockInReport();
    getPoNoForFgStockReport();
    getCategoryDropForFgStockReport();
    getProductDropForFgStockReport();
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const getProductDropForFgStockReport = () => {
    stockInService.getProductDropForFgStockReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setProductData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setProductData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProductData([]);

    });
  }
  const  getCategoryDropForFgStockReport = () => {
    stockService.getCategoryDropForFgStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      console.log(res)
      if (res.status) {
        setCategoryData(res.data);
      } else {
        if (res.intlCode) {
          setCategoryData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setCategoryData([]);
    })
  }
  const getUnitDropdownForStockInReport = () => {
    stockService.getUnitDropdownForStockInReport().then((res) => {
      if (res.status) {
       setUnits(res.data);
      } else {
       setUnits(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getPoNoForFgStockReport = () => {
    stockService.getPoNoForFgStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      console.log(res)
      if (res.status) {
        setSoItemData(res.data);
      } else {
        if (res.intlCode) {
          setSoItemData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoItemData([]);
    })
  }

  const getBrandData = () => {
    stockService.getBrandDropForFgStockReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setBrandsDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  

  
  const onReset = () => {
    form.resetFields();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
    // setStockData([]);

  }
  const getAllFgStockReport = () => {
    setDisable(true);
    setLoading(true);
    const req = new FgSaleReportRequest()
    let saleOrderId = form.getFieldValue('saleOrderId');
    console.log(form.getFieldValue('saleOrderId'))
    let unitId = form.getFieldValue('unitId');
    let category = form.getFieldValue('category')
    let brandId = form.getFieldValue('brandId');
    req.saleOrderId = saleOrderId;
    req.poNumber = soItemData.filter(res => res.saleOrderId === form.getFieldValue('saleOrderId'))[0]?.poNumber;
    req.category = category;
    req.brandId = brandId;
    console.log(req, '--req')
    console.log(soItemData.filter(res => res.saleOrderId === form.getFieldValue('saleOrderId'))[0]?.poNumber);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    stockService.getAllFgStockReport(req).then(res => {
      setDisable(false);
      if (res.status) {
        setStockData(res.data);
        setFiltersData(res.data);
        setLoading(false);
        let filtersArray:{text:string,value:string}[] = []
           let uniq = res.data.filter((thing, index, self) =>
           index === self.findIndex((t) => (
             t.brand === thing.brand
           ))
         );
       // data for products filter
       uniq.map(item => {
           if(!(filtersArray.includes({text:item.brand,value:item.brand})))
           filtersArray.push({text:item.brand,value:item.brand})
           })

           let productFiltersArray:{text:string,value:string}[] = []
           let produtsUniq = res.data.filter((thing, index, self) =>
           index === self.findIndex((t) => (
               t.variantcode === thing.variantcode
           ))
           );
           produtsUniq.map(item => {
               if(!(productFiltersArray.includes({text:item.variantcode,value:item.variantcode})))
               productFiltersArray.push({text:item.variantcode,value:item.variantcode})
               })
               setproductFiltersData( productFiltersArray.sort((a, b) => {
                let splittedKey1 = a.text.split('/');
                let splittedKey2 = b.text.split('/');
                let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                let key1 = splittedKey1[1] + `${numberKey1}`;
                let key2 = splittedKey2[1] + `${numberKey2}`;
                return (key1 > key2) ? 1 : -1
              })
              .sort((a, b) => {
                let splittedKey1 = a.text.split('/');
                let splittedKey2 = b.text.split('/');
                let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                return (numberKey1 - numberKey2) ? 1 : -1
              })
              )
           setbrandFiltersData(filtersArray)
        // AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setLoading(false);
          setStockData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          setLoading(false);
          setDisable(false);
          AlertMessages.getErrorMessage(res.internalMessage);
          AlertMessages.getErrorMessage('No Data Found');
        }
      }
    })

  }


 

  
  const handleUnit = (value) => {
    console.log(value+"******************")
    setUnit(value)
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const getBalance = (record) => {
    console.log(record);
    let balance = Number(Number(record.jobCases ? record.jobCases : 0) - (Number(record.finalFg ? record.finalFg : 0) + Number(record.dummy ? record.dummy : 0) + Number(record.pouchFinal ? record.pouchFinal : 0))).toLocaleString(undefined, { maximumFractionDigits: 0 });
    return balance;
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed:'left',
      render: (text, object, index) => (page - 1) * 100 + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'plantName',
      width: '100px',
      fixed:'left',
      //sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      
      filters: [
        { text: 'Kakinada', value: 'Kakinada' },
        { text: 'Unit 1', value: 'Unit 1' },
        { text: 'Unit 2', value: 'Unit 2' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.plantName == value;
      }

    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      key: 'poNumber',
      width: '200px',
      fixed:'left',
      ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      align: 'left',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
      render: (value, record) => {
        return <span>
          {record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },


    {
      title: 'Brand',
      dataIndex: 'brand',
      width: '150px',
      // sorter: (a, b) => a.brand.localeCompare(b.brand),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('brand'),
      filters: brandfiltersData,
            
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.brand == value;
            },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '100px',
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('category'),
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantcode',
      width: '220px',
      // sorter: (a, b) => a.variantcode.localeCompare(b.variantcode),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('variantcode'),
      filters: productfiltersData,
            
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.variantcode == value;
            },
    },

    {
      title: 'Pack Style',
      dataIndex: 'packingMethodName',
      width: '150px',
      sorter: (a, b) => a.packingMethodName.localeCompare(b.packingMethodName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packingMethodName'),
    },

    {
      title: 'Break Up/Required',
      dataIndex: 'cases',
      width: '170px',
      // sorter: (a, b) => a.cases?.length - b.cases.length,
      // sortDirections: ['descend', 'ascend'],    
      // ...getColumnSearchProps('cases'),
      render: (index, record) => {
        return <><span style={{ float: 'right' }}>{record.cases ? record.cases : '0'}</span></>
      }
    },
    {
      title: 'Job Cases',
      dataIndex: 'jobCases',
      width: '100px',
      ...getColumnSearchProps('jobCases'),
      sorter: (a, b) => a.jobCases - b.jobCases,
      sortDirections: ['descend', 'ascend'],
      render: (index, record) => {
        return <><span style={{ float: 'right' }}>
          {record.jobCases ? record.jobCases : '0'}</span></>
      },
    },
    {
      title: 'Final(FG)',
      dataIndex: 'finalFg',
      width: '100px',
      // sorter: (a, b) => a.finalFg?.localeCompare(b.finalFg),
      // sortDirections: ['descend', 'ascend'], 
      // ...getColumnSearchProps('finalFg'),
      render: (index, record) => {
        return <><span style={{ float: 'right' }}>
          {record.finalFg ? record.finalFg : '0'}</span></>
      }
    },
    {
      title: 'Pouch Final',
      dataIndex: 'pouchFinal',
      width: '100px',
      render: (index, record) => {
        return <>
          <span style={{ float: 'right' }}>{record.pouchFinal ? record.pouchFinal : '0'}</span></>
      }
    },
    {
      title: 'Super Dummy',
      dataIndex: 'dummy',
      width: '100px',
      // sorter: (a, b) => a.dummy.localeCompare(b.dummy),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('dummy'),
      render: (index, record) => {
        return <><span style={{ float: 'right' }}>{record.dummy ? record.dummy.toLocaleString(undefined, { maximumFractionDigits: 0 }) : '0'}</span></>
      }
    },

    {
      title: 'Balance',
      dataIndex: 'balance',
      width: '100px',
      fixed:'right',
      // sorter: (a, b) => a.balance.localeCompare(b.balance),
      // sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('balance'),
      render: (index, record) => {
        return <><span style={{ float: 'right' }}>{getBalance(record)}</span></>
      }
    },
    // {Number(Number(record.jobCases ? record.jobCases : 0) - (Number(record.finalFg ? record.finalFg : 0) + Number(record.dummy ? record.dummy : 0)))}
  ];

  const data = [
    { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 100 + (index + 1) },
    { title: "Unit", dataIndex: "plantName" },
    { title: "Customer PO", dataIndex: "poNumber" },
    { title: "ETD", dataIndex: "etd", render: (value, record) => (record.etd ? moment(record.etd).format('DD-MM-YYYY') : '-') },
    { title: "Brand", dataIndex: "brand" },
    { title: "Category", dataIndex: "category" },
    { title: "Variant Code", dataIndex: "variantcode" },
    { title: "Pack Style", dataIndex: "packingMethodName" },
    { title: "Break Up/Required", dataIndex: "cases" },
    { title: 'Job Cases', dataIndex: 'jobCases' },
    { title: "Final(FG)", dataIndex: "finalFg" },
    { title: "Pouch Final", dataIndex: "pouchFinal" },
    { title: "Dummy", dataIndex: "dummy" },
    { title: "Balance", dataIndex: "balance", render: (index, record) => { return getBalance(record) } }];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('FG Stock Report')
      .addColumns(data)
      .addDataSource(stockData,{str2num:true})
      .saveAs('fg-sale-report.xlsx');
  }
  const exportToPdf = () => {

    var columns = [
      { title: "Sno", dataKey: "sno" },
      { title: "Unit", dataKey: "plantName" },
      { title: "Customer PO", dataKey: "poNumber" },
      {
        title: "ETD", dataKey: "etd", render: (value, record) => {
          (record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-')
        }
      },
      { title: "Brand", dataKey: "brand" },
      { title: "Category", dataKey: "category" },
      { title: "Variant Code", dataKey: "variantcode" },
      { title: "Pack Style", dataKey: "packingMethodName" },
      { title: "Break Up/Required", dataKey: "required" },
      { title: "Final(FG)", dataKey: "finalFg" },
      { title: "Pouch Final", dataKey: "pouchFinal" },
      { title: "Dummy", dataKey: "dummy" },
      { title: "Balance", dataKey: "balance", render: (index, record) => { return getBalance(record) } }];
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, stockData, {
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      addPageContent: function (data) {
        doc.text("FG STOCK REPORT", 50, 15);
      }
    });
    doc.save('fg-sale-report.pdf')
  }

  return (
    <Card title={<span style={{ color: 'white' }}>FG Stock Report</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <br></br>
      <Form layout={"vertical"} autoComplete="off" form={form} >
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item
              name="delUnitId"
              label="Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                onChange= {handleUnit}
                allowClear
                style={{ width: '100%' }}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              >
                {units.map(dropData => {
                  return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="saleOrderId"
              label="Customer PO "
            >
              <Select
                showSearch
                placeholder="Select Sale Order"
                optionFilterProp="children"
                allowClear
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {soItemData.map((data) => {
                  return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item

              label="Brand Name"
              name='brandId'
              // fieldKey={[field.fieldKey, 'brandId']}
              rules={[{ required: false, message: 'Missing brand name' }, { pattern: /^(?!\s*$).+/, message: `Enter Brand Name` }]}
            >
              <Select
                showSearch
                placeholder="Please Select Brand"
                optionFilterProp="children"
                allowClear
                onSearch={onSearch}
               // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

              >
                <Option key={0} value={null}>Please select brand</Option>
                {brandsDropDown.map(brandDropData => {
                  return <Option key={brandDropData.brandId} value={brandDropData.brandId}>{brandDropData.brand}</Option>
                })}
                |
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item name="category" label="Category" rules={[{ required: false, message: 'Missing category' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Category"
                allowClear
                onSearch={onSearch}
              >
                <Option key={0} value={null}>Select category</Option>
                {categoryData.map(brandDropData => {
                  return <Option key={brandDropData.category} value={brandDropData.category}>{brandDropData.category}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="product"
              label="Product SKU"
              rules={[
                {
                  required: false,
                  message: 'product is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {productData?.map(productsku => {
                  return <Option key={productsku.varientId} value={productsku.varientId}>{productsku.variantCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getAllFgStockReport()}>
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
        </Row>
      </Form>
      <br />

      {(stockData.length) ? <>

        {

            stockData.forEach(item => {
            totalCart += Number(item.finalFg)+Number(item.dummy)+Number(item.pouchFinal);
          })
        }
        <Card title='Report Summary' >
                <Row gutter={24}>
                  <Col span={4}>
                    <Statistic title="Total Cartons" value={Math.round(totalCart)} />
                  </Col>
                  <Col span={4}>
                    <Button icon={<FileExcelOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }}>
                      Get Excel
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
                      Get PDF
                    </Button>
                  </Col>

                </Row>
              </Card>
        <Table
          columns={columnsSkelton}
          rowKey={record => record.stockId}
          //  dataSource={localStorage.getItem("role") === "ADMIN"? stockData:localStorage.getItem("role") != "ADMIN"?stockData.filter(rec=>rec.plantId === Number(localStorage.getItem("unit_id"))):stockData}
          dataSource={stockData}
          pagination={false}
          //  pagination={{  pageSize:100,
          //   onChange(current){ 
          //     setPage(current);
          //   }
          // }}
          scroll={{ y: 500 }}
          size="small"
          bordered
          summary={(pageData) => {
            console.log(pageData)
            let cases = 0;
            let required = 0;
            let finalFG = 0;
            let dummy = 0;
            let balance = 0;
            let pouchFinal = 0;


            pageData.forEach((res) => {
              console.log(res);
              cases += Number(res.cases);
              required += Number(res.jobCases);
              finalFG += Number(res.finalFg);
              dummy += Number(res.dummy);
              pouchFinal += Number(res.pouchFinal);
              balance += Number(res.jobCases) - (Number(res.finalFg)+Number(res.dummy)+Number(res.pouchFinal));
            });

            return (
              <>
              {console.log(cases)}
              {console.log(required)}
              {console.log(finalFG)}
              {console.log(dummy)}
              {console.log(pouchFinal)}
              {console.log(balance)}

                <Table.Summary.Row className='tableFooter'>
                <Table.Summary.Cell index={1} colSpan={7}><Text style={{float:'right'}}></Text></Table.Summary.Cell>

                <Table.Summary.Cell index={1} ><Text style={{float:'right'}}>TOTAL</Text></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><Text style={{float:'right'}}>{cases.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={1} colSpan={8} ><Text >Total</Text></Table.Summary.Cell> */}
                  <Table.Summary.Cell index={1} ><Text style={{float:'right'}}>{required.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}><Text style={{float:'right'}}>{finalFG.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}><Text style={{float:'right'}}>{pouchFinal.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={1} colSpan={2}><Text  style={{float:'right'}}></Text></Table.Summary.Cell> */}
                  <Table.Summary.Cell index={1}><Text style={{float:'right'}}>{dummy.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                <Table.Summary.Cell index={1} ><Text style={{float:'right'}}>{balance.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                  
                </Table.Summary.Row>
              </>
            );
          }
          }
        >
        </Table>
      </> : <></>}
    </Card>
  )

};

export default FgStockReport;