import axios from 'axios';
import connection from './connection';
import {SuggestionsDto, AllSuggestionsResponseModel, SuggestionsResponseModel} from '@gtpl/shared-models/hrms';

export class SuggestionsService{

    URL = connection.DEPLOY_URL + '/suggestions';

    async createSuggestion(suggestionsDto: SuggestionsDto ): Promise<SuggestionsResponseModel> {
        console.log(suggestionsDto);
       
        return await axios.post(this.URL + '/createSuggestions',suggestionsDto)
            .then(res => {
                return res.data
            })
    }

    async getAllSuggestions(): Promise<AllSuggestionsResponseModel> {
                
        return await axios.post(this.URL + '/getAllSuggestions')
            .then(res => {
                return res.data 
            })     
    }

    async  updateSuggestion(suggestionsDto: SuggestionsDto): Promise<SuggestionsResponseModel> {
        return await axios.post(this.URL + '/updateSuggestion', suggestionsDto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateSuggestion(complaintsDto: SuggestionsDto): Promise<SuggestionsResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateSuggestion', complaintsDto)
                        .then(res => {
                                return res.data
                        })
                }
                
}