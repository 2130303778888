import axios from "axios";
import connection from './connection';
import { AttendanceMonthRequest, OTStatusRequest, OverTimeDTO, OverTimeRequest, OverTimeResponse } from "@gtpl/shared-models/hrms";
import { CommonResponse } from "@gtpl/shared-models/masters";

export class OverTimeService {

    URL = connection.DEPLOY_URL + '/over-time';

    async createOTApply(dto: OverTimeRequest): Promise<OverTimeResponse> {
        return await axios.post(this.URL + '/createOverTime', dto)
            .then(res => {
                return res.data
            })
    }

    async getOverTimeTableData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getOverTimeTableData')
            .then(res => {
                return res.data
            })
    }

    async updateOTStatus(req: OTStatusRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateOTStatus', req)
            .then(res => {
                return res.data
            })
    }

    async getOTHoursByEmployeeCode(req: AttendanceMonthRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getOTHoursByEmployeeCode', req)
            .then(res => {
                return res.data
            })
    }

    async getOTShiftsByEmployeeCode(req: AttendanceMonthRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getOTShiftsByEmployeeCode', req)
            .then(res => {
                return res.data
            })
    }
}
