import React,{useState,useEffect}  from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, Row, Select, Table, Tooltip } from 'antd';
/* eslint-disable-next-line */
import { PoItemsDataModel,PoDataModel,PoDto } from '@gtpl/shared-models/procurement-management';
import {VendorsDto,CountryDto,CurrencyDto,EmployeeDto,PaymentTermsDropDownDto}  from '@gtpl/shared-models/masters';
import {VendorsService,CurrencyService,EmployeeService,PaymentTermsService} from '@gtpl/shared-services/masters';
import { StatusEnum } from '@gtpl/shared-models/common-models';

import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import {SaleOrderDto,SODto,SoRequest,BomPackingItemsDto, SoNumbersModel}  from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import './purchase-order-form.css';
import moment from 'moment';
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

export interface PoFormProps {
isUpdate: Boolean;
form: FormInstance<any>
  // soDetails: (soData: any) => void;
  poDetails: PoDto;
  totalAmount?:number;
  transport?:any;
  currency:any;
}

export function PoForm(props: PoFormProps) {
  const form = props.form;

       let history = useHistory();
  const [selectedVendor, setSelectedVendor] = useState<number>(null);
  const [vendorData, setVendorData] = useState<VendorsDto[]>([]);
  const [currencyData,setCurrencyData] = useState<CurrencyDto[]>([]);
  const [currency, setCurrency] = useState<any>(props.currency);
  const [paymentTermsData,setPaymentTermsData] = useState<PaymentTermsDropDownDto[]>([])
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<number>(null);
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [soDetails, setSoDetails] = useState<BomPackingItemsDto[]>([]);

  const [selectedApprover, setSelectedApprover] = useState<number>(null);
  const [selectedStatus, setSelectedStatus] = useState<number>(null);
  const [approverData, setApproverData] = useState<EmployeeDto[]>([]);
  const [statusData, setStatusData] = useState<StatusEnum[]>([]);
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState<number>(null);
  const [isSoDisabled, setIsSoDisabled] = useState<boolean>(false);
  const [keyUpdate, setkeyUpdate] = useState<number>(1);
  const [trans, setTrans] = useState<number>(props.transport);


  const soService = new SaleOrderService;
  const vendorService = new VendorsService;
  const currencyService = new CurrencyService;
  const employeeService = new EmployeeService;
  const paymentTermsService = new PaymentTermsService;

  useEffect(()=> {
    // getSoNumbers();
    // getAllVendors();
    getAllEmployees();
    getAllPaymentTerms();

  //   console.log(props.totalAmount);
  // if(props.totalAmount){
  //   form.setFieldsValue({
  //     totalCost : props.totalAmount,
  //   })
  // }
  if (props.isUpdate) {
    setIsSoDisabled(true);
    if (props.poDetails) {
        console.log(props.isUpdate)
        console.log(props.poDetails)
        const initialdata = props.poDetails
        if(initialdata){
            // initialdata.purchaseOrderDate = moment(initialdata.purchaseOrderDate, 'YYYY-MM-DD')
            

            // form.resetFields();
            form.setFieldsValue({
                expectedDeliveryDate:initialdata.expectedDeliveryDate,
                purchaseOrderDate:initialdata.purchaseOrderDate,
                paymentTerms:initialdata.paymentTerms,
                transportation:initialdata.transportation,
                address:initialdata.deliveryAddress,
                note:initialdata.note,
                remarks:initialdata.remarks
            })
            setkeyUpdate(keyUpdate+1);
        }

        console.log(form.getFieldsValue());
    }
    // console.log(props.grnId)
    // getGrnData(props.grnId);

} else {

}
}, [form])


const handleChange =()=>{
const transport:number = (form.getFieldValue('transportation'))?Number(form.getFieldValue('transportation')):0;

props.transport(transport?transport:0);
}

       
      // let createdUser="";
      // if(!props.isUpdate){
      //   createdUser= localStorage.getItem("createdUser");
      // }

      const getSoNumbers = () => {
        soService.getSoNumbers().then((res) => {
            console.log('test')

            if (res.status) {
                // console.log(res.data);
                setSaleOrderData(res.data);
            } else {

              setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

      

      const getAllVendors=() =>{
        vendorService.getAll().then(res => {
          if(res.status){
            // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
            setVendorData(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }
      const getAllEmployees=() =>{
        employeeService.getAllActiveEmployees().then(res => {
          if(res.status){
            // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
            setApproverData(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }

      const getAllPaymentTerms=() =>{
        paymentTermsService.getAll().then(res => {
          if(res.status){
            // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
            setPaymentTermsData(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }
     
      
      // const handleVendor=(value)=>{
      //   console.log(value);
      //   setSelectedVendor(value);
      // }

      // const handleApprover=(value)=>{
      //   console.log(value);
      //   setSelectedApprover(value);
      // }

      // const handleStatus=(value)=>{
      //   setSelectedStatus(value);
      // }
      const handlePaymentTerm=(value)=>{
        setSelectedPaymentTerms(value);
      }
      const handleCurrency=()=>{
        if(props.currency){
          setCurrency(props.currency)
        }
      }
         
      const onFocus=() =>{
        console.log('focus');
      }
      
      const onSearch=(val)=> {
        console.log('search:', val);
      }
      const onBlur=() =>{
        console.log('blur');
      }   
  const onReset = () => {
    console.log('So Reset');
    form.resetFields();
    // props.soDetails(null)
    setShowTable(false);
  }; 

  return (
  

    <Card title={props.isUpdate ?<span  style={{ color: 'white' }}>Update Purchase Order Details</span>:<span  style={{ color: 'white' }}>Create Purchase Order</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
    
    
          <Form form={form} key={Date.now()} autoComplete="off" name="control-hooks" layout="vertical"> 
    
          <Form.Item name="purchaseOrderId" style={{display:"none"}} >
            <Input hidden/>
          </Form.Item>
          <Form.Item style={{display:"none"}} name="createdUser" 
          //  initialValue={createdUser}
           >
          <Input hidden/>
        </Form.Item>
        <Row>
       
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="purchaseOrderDate"
                    label="Purchase Order Date"
                    rules={[
                      {
                        required: false,
                      },
                    ]}initialValue={moment()}>
                    <DatePicker style={{ width: '100%' }} />
                    
                  </Form.Item>
            </Col>
            <Col  xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
                <Form.Item label="Transportation"
                  name="transportation"
                  rules={[{ required: false, message: 'Missing Transportation' }]}
                >
                 <Input onChange={handleChange}/>
                 
                </Form.Item>
                </Col>
            
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="paymentTerms"
                    label="Payment Terms"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Select
                showSearch
                
                placeholder="Select Payment Term"
                optionFilterProp="children"
                onChange={handlePaymentTerm}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              > 
               <Option key={0} value={null}>Select Payment</Option>
              {paymentTermsData.map((payment)=>{
                return <Option key={payment.paymentTermsId} value={payment.paymentTermsId}>{payment.paymentTermsName}</Option>
              })}
              </Select>
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="currencyName"
                    label="Currency"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Input onChange={handleCurrency} disabled/>
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="exchangeRate"
                    label="Exchange Rate"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Input onChange={handleChange}/>
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Input/>
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="note"
                    label="Note"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                     <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="expectedDeliveryDate"
                    label="Expected Delivery Date"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                     <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
            </Col>
           
           
            <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}} >
                <Form.Item
                    name="deliveryAddress"
                    label="Delivery Address"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Input/>
                  </Form.Item>
            </Col>
            </Row>
            
          </Form>
        </Card>
      );
}
export default PoForm;






