import { LotInformationRequest, LotNumberReportResponseModel, ProductionLogResponse, SubPlantTransferResponse } from '@gtpl/shared-models/production-management';
import axios from 'axios';
import connection from './connection';

export class ProdlogService {
    URL = connection.DEPLOY_URL + '/prod-log';

    async createProdLog(values): Promise<ProductionLogResponse> {
        return await axios.post(this.URL + '/createProdLog', values)
            .then(res => {
                return res.data
            })
    }

    async getLogDetailsForId(values): Promise<SubPlantTransferResponse> {
        return await axios.post(this.URL + '/getLogDetailsForId', values)
            .then(res => {
                return res.data
            })
    }
    async getLotTrackingReportData(req:LotInformationRequest): Promise<LotNumberReportResponseModel> {
        return await axios.post(this.URL + '/getLotTrackingReportData',req)
            .then(res => {
                return res.data
            })
    }
}