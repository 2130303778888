import { Form, Input, Button, Space, Card, Select, Col, Row, Layout, Table, Empty, InputNumber, Tooltip, Divider, Descriptions, Typography, Drawer, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';
import { GrnData, GrnDetails, RmGrnItemDetails, RmGrnPlantDetails } from '@gtpl/shared-models/raw-material-procurement';
import { RawMaterialGrnForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-form';
import { RmVehicleInspection } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-vehicle-inspection';
import { RmEvaluation } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-evaluation';
import { RmEvaluationPrint } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-evaluation--print';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import { ColumnProps } from 'antd/lib/table';
import { GrnIdRequest } from '@gtpl/shared-models/procurement-management';
const { Text } = Typography;
import { UnitcodeService } from '@gtpl/shared-services/masters';
import './raw-material-grn-detail-view.css';
import { PlantsEnum, SupplierTypeEnum } from '@gtpl/shared-models/common-models';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { CheckCircleOutlined,FormOutlined, SnippetsOutlined,CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, CloseSquareOutlined, SnippetsFilled, PrinterOutlined, ReconciliationOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { RMGrnPrint } from '@gtpl/pages/logistics/logistics-components/documents';


import { AntiBioticsMicrobiologyForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/anti-biotics-microbiology-form'
import GrnIndent from './grn-audit';
import { LeavesDTO } from 'libs/shared-models/hrms/src/lib/employee-leaves/leaves.dto';

/* eslint-disable-next-line */
export interface RawMaterialGrnDetailViewProps { }

export function RawMaterialGrnDetailView(props: RawMaterialGrnDetailViewProps) {
  const [page, setPage] = React.useState(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [grnDetailViewdata, setGrnDetailViewdata] = useState<GrnData>(null);
  const [productsBtnDisable, setProductsBtnDisable] = useState<boolean>(false);
  const [updateBtnDisable, setUpdateBtnDisable] = useState<boolean>(false);
  const [rmAssigned, setRmAssigned] = useState<boolean>(false);
  const [allGrnData, setAllGrnData] = useState<any[]>([]);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [subFactoriesData, setSubFactoriesData] = useState<PlantsDropDown[]>([]);
  const [selectedGrn, setSelectedGrn] = useState<any>(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const service = new RMGrnService;
  const [form] = Form.useForm();
  const [grnform] = Form.useForm();
  const unitcodeService = new UnitcodeService();
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalVisible2, setIsModalVisible2] = useState<boolean>(false);
  const [selectedInformation, setSelectedInformation] = useState<GrnData>();
  const [modelType, setModelType] = useState<string>('');


  //const [data, setdata] = useState<GrnItemsInfo[]>([])
  let location = useLocation();
  const grnId = location.state;
  const userName = localStorage.getItem("createdUser");


  //  let grnIdReq=  new GrnIdRequest(props.GrnDetails.grnId);

  const setEditForm = (rowData: any, index) => {
    setDefaultFormData(rowData);
    setIndexVal(index);
  }

  useEffect(() => {
    getAllMainPlants();
    getAllSubPlants();
    if (grnId) {
      getGrnDataById(grnId)
    }
  }, []);
  console
  .log(GrnData)
  useEffect(() => {
    if (allGrnData) {
      grnform.setFieldsValue({ plantId: allGrnData[0]?.plantId })
      grnform.setFieldsValue({ subPlantId: allGrnData[0]?.subPlantId })
      console.log(allGrnData)
      let rmReceiveFlag = false
      for(const record of allGrnData){
        if(record.assignToRm == 1){
          rmReceiveFlag = true
          console.log('yessss')
          setRmAssigned(true)
          break
        }else{
          rmReceiveFlag = false
        }
      }
    }
  }, [allGrnData])

  useEffect(()=> {
    if(rmAssigned){
      console.log(rmAssigned)
    }
  },[rmAssigned])

  const getAllSubPlants = () => {
    unitcodeService.getAllSubPlants().then((res) => {
      if (res.status) {
        setSubFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setSubFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };

  const getGrnDataById = (grnId) => {
    // service.getGrnDataForId(new GrnIdRequest(grnId)).then(res => {
    service.getRmGrnDataById(new GrnIdRequest(grnId)).then(res => {
      console.log('grn', grnId)
      console.log(res);
      if (res.status) {
        setAllGrnData(res.data)
        // AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          setAllGrnData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setAllGrnData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  const getAllMainPlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  // const columns = [
  //   {
  //     title: 'S No',
  //     key: 'sno',
  //     width: '70px',
  //     render: (text, object, index) => (page - 1) * 10 + (index + 1)

  //   },

  //   // {
  //   //   title: 'Item Category',
  //   //   dataIndex: 'itemCategory',
  //   //   render:(text, data, index)=>{
  //   //     console.log(data)
  //   //     return <span>{data.itemCategory?.itemCategory}</span>
  //   //   }
  //   // },

  //   {
  //     title: 'Item',
  //     dataIndex: 'itemName',
  //     render:(text, data, index)=>{
  //       console.log(data)
  //       return <span>{data.itemName?.itemName}</span>
  //     }
  //   },
  //   {
  //     title: 'Size',
  //     dataIndex: 'size',
  //     render:(text, data, index)=>{
  //       console.log(data)
  //       return <span>{data.size?.sizeName}</span>
  //     }
  //   },
  //   {
  //     title: 'PO Quantity',
  //     dataIndex: 'itemQuantity',
  //     key: 'itemQuantity',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Accepted Quantity',
  //     dataIndex: 'acceptedQuantity',
  //     key: 'acceptedQuantity',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Rejected Quantity',
  //     dataIndex: 'rejectedQuantity',
  //     key: 'rejectedQuantity',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Unit Price',
  //     dataIndex: 'itemCost',
  //     key: 'itemCost',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Discount %',
  //     dataIndex: 'discountPer',
  //     key: 'discountPer',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Discount Amount',
  //     dataIndex: 'discount',
  //     key: 'discount',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Tax %',
  //     dataIndex: 'taxPer',
  //     key: 'taxPer',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{data.taxPer.taxPercentage?Number(data.taxPer.taxPercentage):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Tax Amount',
  //     dataIndex: 'tax',
  //     key: 'tax',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },
  //   {
  //     title: 'Total Amount',
  //     dataIndex: 'subAmount',
  //     key: 'subAmount',
  //     render:(text, data, index)=>{
  //       return <div style={{ textAlign: 'right' }}>{text?Number(text):0}</div>
  //     }
  //   },

  // ]

  const updateGrn = (grnData: any) => {
    console.log('allGrnData:', allGrnData)
    console.log(grnData)
    const dynTableData = [...allGrnData];
    dynTableData[indexVal].count = Number(grnData.count)
    // dynTableData[indexVal].price = Number(grnData.price)
    dynTableData[indexVal].quantity = Number(grnData.quantity)
    dynTableData[indexVal].saleOrderId = grnData.saleOrderId
    dynTableData[indexVal].saleOrderItemId = grnData.saleOrderItemId
    dynTableData[indexVal].softPercentage = Number(grnData.softPercentage)
    dynTableData[indexVal].boxes = Number(grnData.boxes)
    dynTableData[indexVal].receivedBoxes = Number(grnData.receivedBoxes)
    dynTableData[indexVal].fungusPercentage = Number(grnData.fungusPercentage)
    dynTableData[indexVal].looseShell = Number(grnData.looseShell)
    dynTableData[indexVal].remarks = grnData.remarks
    setAllGrnData(dynTableData);
    // dynTableData[indexVal].subPlantId = grnData.price
    let grnItems: RmGrnItemDetails[] = [];
    
    allGrnData.forEach(element => {
      const grnItemObj = new RmGrnItemDetails(element.farmer, element.farmerCode, element.indentId, element.quantity, element.count, element.price, undefined, element.grnItemId, element.farmCode, element.farmerCode, undefined, element.grnId, undefined, element.saleOrderId, element.saleOrderItemId, element.softPercentage, element.boxes, userName, undefined, element.receivedBoxes, undefined, element.indentItemId, element.looseShell, element.fungusPercentage,element.remarks)
      grnItems.push(grnItemObj)
    });
    const grnDetails = new GrnDetails(grnData.grnDate, grnData.indentId, grnData.supplierType, undefined, grnItems,  allGrnData[0]?.plantId, allGrnData[0].grnNumber, allGrnData[0]?.subPlantId, grnData.grnId, userName, undefined, undefined, undefined, undefined, allGrnData[0]?.harvestType)
    console.log(grnDetails)
    service.updateGrn(grnDetails).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        setDrawerVisible(false);
        getGrnDataById(grnId)
        // getGrnDetails();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const openFormWithData = (viewData: GrnData, index: number) => {
    console.log(index)
    viewData.grnDate = moment(viewData.grnDate);
    setDrawerVisible(true);
    setSelectedGrn(viewData);
    setIndexVal(index);

  };

  const assigntoRm = (grnData: any, index: number) => {
    form.validateFields().then(res => {
      grnData.receivedBoxes = form.getFieldValue("receivedBoxes" + index);
      // if(grnData.receivedBoxes > 0){

        console.log(form.getFieldValue("receivedBoxes" + index))
        setProductsBtnDisable(true);
        console.log(grnData);
        service.assignToRM(grnData).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage('RM Inserted Successfully');
            getGrnDataById(grnId)
            // setProductsBtnDisable(false);
            // getGrnDetails();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          // setGrnData([]);
          AlertMessages.getErrorMessage(err.message);
          setProductsBtnDisable(false);
        })
      // }else{
      //   AlertMessages.getErrorMessage('boxes should enter')
      // }

    })
  }

  const updateReceivedBoxes = (e, grnId: number, boxes: number,rowData) => {
    console.log(e)
    console.log(Number(boxes))
    if (e > Number(boxes)) {
      AlertMessages.getWarningMessage("Received Boxes exceeded actual Grn Quantity. ");
    }else{
      rowData.receivedBoxes = e
    }
  }

  const columns: ColumnProps<any>[] = [
    {
      title: 'Lot Number',
      dataIndex: 'lotnplantlot',
    //   render: (value,record) => {

    //  return record.lotNumber + '-' + record.plantLotNumber
    //   }
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNo',
    //   render: (text, data, index) => {

    //     return <span>{text}</span>
    //   }
    // },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },

    {
      title: 'Middle Man',
      dataIndex: 'BrokerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farmer',
      dataIndex: 'farmerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farm',
      dataIndex: 'farmName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text, data, index) => {
        console.log(data)

        return <span>{text}</span>
      }
    },
    // {
    //   title: 'Soft %',
    //   dataIndex: 'softPercentage',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     return <span>{text}</span>
    //   }
    // },
    // {
    //   title: 'Fungus %',
    //   dataIndex: 'fungusPercentage',
    //   render: (text, data, index) => {
    //     console.log(data)
    //     return <span>{text}</span>
    //   }
    // },
    // {
    //   title: 'Loose Shell ',
    //   dataIndex: 'looseShell',
    //   render: (text, data, index) => {
    //     console.log(data)

    //     return <span>{text}</span>
    //   }
    // },
    {
      title: 'No Of Boxes ',
      dataIndex: 'boxes',
      render: (text, data, index) => {
        console.log(data)

        return <span>{text}</span>
      }
    },
    {
      title: 'Received Boxes',
      key: 'receivedBoxes',
      dataIndex: 'receivedBoxes',
      width: 185,
      align: 'right',
      render: (text, data, index) => (
        <span>
          
            <>
              <Form.Item
                name={"receivedBoxes" + index}
                initialValue={data.receivedBoxes == null ? data.boxes : data.receivedBoxes}
                rules={[
                  {
                    required: data.assignToRm == 0 ? true : false, message: 'Missing Received Boxes',
                  },
                ]}
                style={{ margin: 0 }}
              >
                {(data.assignToRm == 0 ) ?
                  <InputNumber name={"receivedBoxes" + index}
                    onChange={(e) => updateReceivedBoxes(e.target.value, data.grnId, data.boxes,data)}
                    defaultValue={data.receivedBoxes == null ? data.boxes : data.receivedBoxes}
                     /> : (data.assignToRm == 1) ? data.receivedBoxes : "-"
                }
              </Form.Item>
            </>
            

        </span>
      ),
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData, index) => (
        <span>
          {rowData.assignToRm === 0 ?
            <span>
              <Tooltip placement="top" title='Assign Saleorder/Plant/Edit'>
                <EditOutlined className={'editSamplTypeIcon'} type="edit"
                  onClick={() => {
                    openFormWithData(rowData, index);
                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                />
              </Tooltip>

            </span>
            : ''}
        </span>
      )
    },
    {
      title: 'RM Received',
      dataIndex: 'assignToRm',
      render: (text, rowData: any, index) => (

        <span>
        {(rowData.assignToRm === 0 && rowData.saleOrderId > 0) ? 
          // <Link to='/rm-grn-grid'> 
          <Button onClick={() => { assigntoRm(rowData, index) }} disabled={rowData.assignToRm == 0 ? false :true}>
            Receive RM
          </Button>
          // </Link>
           : (rowData.assignToRm) === 1 ? 'RM Received' : 'Update Saleorder to receive'
          
        }
      </span>
      )
    },
    {
      title: 'Vehicle Inspection',
      dataIndex: 'assignToRm',
      render: (text, rowData: GrnData, index) => {
        return rowData.isVInspectionCompleted ? <PrinterOutlined style={{ color: 'blue' }} onClick={() => displayModel(rowData, 'print')} /> : <ReconciliationOutlined
          style={{ color: 'red' }}

          onClick={() => displayModel(rowData, 'inspection')} />
      }
    },
    {
      title: 'RM Evaluation',
      dataIndex: 'assignToRm',
      render: (text, rowData: GrnData, index) => (
        <span> <SafetyCertificateOutlined
          style={{ color: 'red' }}
          onClick={() => displayModel(rowData, 'rmEvaluation')} />{rowData.isRmInspectionCompleted ?
            <> <Divider type="vertical" /> <PrinterOutlined style={{ color: 'blue' }} onClick={() => displayModel(rowData, 'evalPrint')} /></> : ''
          }</span>
      )
    },
    {
      title: 'RM Antibiotics Test',
      dataIndex: 'assignToRm',
      render: (text, rowData: GrnData, index) => (
        <span>{rowData.isAntibioticsTestCompleted ? 'Done' : <SafetyCertificateOutlined
          style={{ color: 'red' }}
          onClick={() => displayModel(rowData, 'antibioticsTest')} />}</span>
      )
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => {
        let buttonName;
        let icon;
        // if (rowData.harvestType === HarvestTypes.Live_Harvest) {
        //   buttonName = 'Audit';
        //   icon = <FormOutlined />;
        // } else {
          buttonName = 'Audit';
          icon = <SnippetsOutlined />;
        // }
        return <Button type="primary" onClick={() => displayModel(rowData,'audit')} danger icon={icon}>{buttonName}</Button>
      }
    
    }


  ]

  const handleCancel = () => {
    if (grnId) {
      getGrnDataById(grnId)
    }
    setIsModalVisible(false);
  };

  const displayModel = (data: GrnData, type) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
    setModelType(type);
  };

  const onPlantUpdate = (values) => {
    console.log(values)
    console.log(values.plantId)
    console.log(values.subplantId)
    setUpdateBtnDisable(true)
    let subPlantId = 0
    let grnId = allGrnData[0]?.grnId;
    if (values.subPlantId) {
      subPlantId = values.subPlantId;
    }
    service.updatePlantOrSubPlant(new RmGrnPlantDetails(values.plantId, grnId, subPlantId)).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Plant/Subplant Updated Successfully');
        setUpdateBtnDisable(false)
        getGrnDataById(grnId)

        // history.push("/rm-grn-grid");
        // onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setUpdateBtnDisable(false)

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setUpdateBtnDisable(false)

        }
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });


  }
  const onSubmit =()=>{
    setIsModalVisible(false);

  }

  return (
    <>

      <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
        <Card title='GRN' style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', fontSize: '15px' }} extra={<Link to='/rm-grn-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} size='small'>
          {
            allGrnData ?
              <>
                <Descriptions column={3}>
                  <Descriptions.Item label="GRN Date">
                    {moment(allGrnData[0]?.grnDate).format('YYYY/MM/DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Indent Number">
                    {(allGrnData[0]?.indentCode)}
                  </Descriptions.Item>
                  <Descriptions.Item label="GRN Number">
                    {allGrnData[0]?.grnNumber}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="LOT Number">
                  {allGrnData[0].lotNumber? allGrnData[0].lotNumber:"-"}
                </Descriptions.Item> */}
                  <Descriptions.Item label="Supplier Type">
                    {allGrnData[0]?.supplierType ? allGrnData[0].supplierType : "-"}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Farmer">
                  {grnDetailViewdata.farmerName?grnDetailViewdata.farmerName:"-"}
                </Descriptions.Item>
                <Descriptions.Item label="Farmer Code">
                  {grnDetailViewdata.farmCode?grnDetailViewdata.farmCode:"-"}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity">
                  {grnDetailViewdata.quantity?grnDetailViewdata.quantity:"-"}
                </Descriptions.Item>
                <Descriptions.Item label="Count">
                  {grnDetailViewdata.count?grnDetailViewdata.count:"-"}
                </Descriptions.Item>
                <Descriptions.Item label="Price">
                  {grnDetailViewdata.price?grnDetailViewdata.price:"-"}
                </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Assigned Plant">
                  {grnDetailViewdata.price?grnDetailViewdata.plantId:'Not Assigned'}
                </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Assigned Plant"> */}
                  {/* {grnDetailViewdata?.plantId?(grnDetailViewdata.plantId == 1 ? PlantsEnum.UNIT_ONE :  grnDetailViewdata.plantId == 2 ? PlantsEnum.UNIT_TWO : grnDetailViewdata.plantId == 3 ? PlantsEnum.KAKINADA : '' ):'Not Assigned'} */}
                  {/* </Descriptions.Item> */}
                  {/* <Descriptions.Item label="Assigned Sub Plant"> */}
                  {/* {grnDetailViewdata.subPlantName?grnDetailViewdata.subPlantName:'Not Assigned'} */}
                  {/* </Descriptions.Item> */}
                </Descriptions>
               
                <Form form={grnform} onFinish={onPlantUpdate}>
                  <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                      <Form.Item initialValue={allGrnData[0]?.plantId} name="plantId" label="Plant" rules={[{ required: true, message: 'Missing Unit' }]} >
                        <Select
                          placeholder="Select Plant"
                          allowClear
                          defaultValue={allGrnData[0]?.plantId}
                          disabled={rmAssigned}

                        >
                          <Option value={null}>Select Plant</Option>
                          {factoriesData.map(dropData => {
                            return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                      <Form.Item initialValue={allGrnData[0]?.subPlantId} name="subPlantId" label="Sub Plant" help={'Note: Select Sub Plant When Required'} >
                        <Select
                          showSearch
                          placeholder="Select Sub Plant"
                          allowClear={true}
                          defaultValue={allGrnData[0]?.subPlantId}
                          disabled={rmAssigned}
                        >
                          <Option value={null}>Select Sub Plant</Option>
                          {subFactoriesData.map(dropData => {
                            return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item >
                        <Button disabled={updateBtnDisable} style={{ backgroundColor: "#67b32d", color: "white" }} block htmlType='submit'>
                          {'Update Plant/Sub Plant'}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <br />

                <Form form={form} layout="vertical">
                  <Table key={Date.now()} scroll={{ x: 1000 }} dataSource={allGrnData} columns={(allGrnData[0]?.supplierType != SupplierTypeEnum.BROKER) ? columns.filter(col => (col.dataIndex != 'brokerName')) : columns} pagination={false} /></Form>
                <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '65%' : '85%'}
                  onClose={closeDrawer} visible={drawerVisible} closable={true}>
                  <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
                    <RawMaterialGrnForm key={Date.now()}
                      updateItem={updateGrn}
                      isUpdate={true}
                      grnData={selectedGrn}
                      closeForm={closeDrawer}
                      indentId={undefined} />
                  </Card>
                </Drawer>
              </>
              : <></>
          }
        </Card><br></br>
        <Modal
          className='rm-'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          {(modelType == 'rmEvaluation') ? <RmEvaluation grnInfo={selectedInformation} handleCancel={handleCancel} OnClose={onSubmit}/> : ''}
          {(modelType == 'inspection') ? <RmVehicleInspection grnInfo={selectedInformation} handleCancel={handleCancel} /> : ''}
          {(modelType == 'antibioticsTest') ? <AntiBioticsMicrobiologyForm grnInfo={selectedInformation} handleCancel={handleCancel} /> : ''}
          {(modelType == 'print') ? <RMGrnPrint grnItemId={selectedInformation.grnItemId} /> : ''}
          {(modelType == 'evalPrint') ? <RmEvaluationPrint grnItemId={selectedInformation.grnItemId} /> : ''}
          {(modelType == 'audit') ? <GrnIndent itemData={selectedInformation} closeModal={handleCancel}/>:''} 

        </Modal>
      </Layout>

    </>
  );
}

export default RawMaterialGrnDetailView;
