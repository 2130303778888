import { EmployeeTeamDto, EmployeeTeamRequest, EmployeeTeamResponse } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class EmployeeTeamService{

    URL = connection.DEPLOY_URL + '/employeeTeam';

    async createEmployeeTeam(data: EmployeeTeamDto): Promise<EmployeeTeamResponse>{        
        return await axios.post(this.URL + '/createEmployeeTeam', data)
            .then(res => {
                return res.data
            })
    }

    async updateEmployeeTeam(data: EmployeeTeamDto): Promise<EmployeeTeamResponse>{
        return await axios.post(this.URL + '/updateEmployeeTeam', data)
            .then(res => {
                return res.data
            })
    }

    async getAllEmployeeTeamActions(): Promise<EmployeeTeamResponse>{
       
        return await axios.post(this.URL + '/getAllEmployeeTeamActions')
           .then(res => {
               return res.data
           })
    }

    async getAllActiveEmployeeTeamActions(): Promise<EmployeeTeamResponse>{
        return await axios.post(this.URL + '/getAllActiveEmployeeTeamActions')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateEmployeeTeam(data: EmployeeTeamRequest): Promise<EmployeeTeamResponse>{
        return await axios.post(this.URL + '/activateOrDeactivateEmployeeTeam', data)
            .then(res => {
                return res.data
            })
    }
}