import { TitleValue } from "./title-value-stack";

export class HighChartReq{
    title:string;
    category:string[];
    subTitle:string;
    chartDetails:TitleValue[];

    constructor(title:string,category:string[],subTitle:string,chartDetails:TitleValue[]){

        this.title = title,
        this.category = category,
        this.subTitle = subTitle,
        this.chartDetails = chartDetails

    }
}