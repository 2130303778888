import React,{ useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Tabs, Modal, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import {PackingIndentService} from '@gtpl/shared-services/procurement'
import { Link, Redirect } from 'react-router-dom';
import {ArrowRightOutlined,SearchOutlined } from '@ant-design/icons';


import './indent-grid.css';
import { packingIndentEnum } from '@gtpl/shared-models/common-models';
import { render } from 'react-dom';
import { indentformDto, IndentFormDTO, IndentNumberRequest, indentrequest } from '@gtpl/shared-models/procurement-management';
import { from } from 'rxjs';

/* eslint-disable-next-line */
export interface IndentGridProps {
  
}

export function BarCodeIndentGrid( props: IndentGridProps) 
{
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const[indenbBarcodeData,setIndenbBarcodeData] =useState<any[]>([]);
  const[object,setObject]=useState(null);
  const[indentnum,setIndentnum]=useState(0)
  const [ponumber,setPonumber]=useState(0);
  const[itemId,setitemId]=useState(0);
  const[soItemNumber,setSoItemNumber]=useState(0);
  const [renderTostoreconsumptionForm, setRenderTostoreconsumptionForm] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [indentnumber,setIndentnumber]=useState('');
  const [data, setData] = useState<any>();

  const service = new PackingIndentService;
  const { TabPane } = Tabs;


  useEffect(() =>{
    getindentbarcodedetails();
  },[])

  /**
   * used for column filter
   * @param dataIndex column data index
   */
   const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const renderToPo=(rowdata) =>{
    // updatestatus(rowdata.indentNumber)
    setRenderTostoreconsumptionForm(true);
    setIndentnum(rowdata.indentNumber);
    setPonumber(rowdata.poNumber)
    setSoItemNumber(rowdata.saleOrderItemId);
    setObject({ indentNumber: rowdata.indentNumber, poNumber: rowdata.poNumber });
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Stock Type',
      dataIndex: 'stockType',
      // responsive: ['lg'],
      // sorter: (a, b) => a.stockType.localeCompare(b.stockType),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('stockType')
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      // width:'90px',
      // align:'right',
      // sorter: (a, b) => a.sale0rderNo - b.sale0rderNo,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      // responsive: ['lg'],
      // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.quantity - b.quantity,
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('quantity')
    },    
    {
      title: 'Indent Number',
      dataIndex: 'indentNumber',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.indentNumber - b.indentNumber,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNumber')

    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
   

    },   
  
  {
    title:'Action',
    render: (text, rowData) => (
      <span>
         {(object) ? <Redirect
            to={{
              pathname: "/store-issues-form",
              state: object
            }}
          /> : null}
        {rowData.status == packingIndentEnum.OPEN ? <a><Tooltip placement="top" title='Issue material'><ArrowRightOutlined style={{ color: 'red' }} onClick={() => renderToPo(rowData)} /></Tooltip> </a> : ""}
           </span>
    )
   
  }
  ];
  const columnsSkeltonforissued: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Stock Type',
      dataIndex: 'stockType',
      // responsive: ['lg'],
      // sorter: (a, b) => a.stockType.localeCompare(b.stockType),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('stockType')
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      // width:'90px',
      // align:'right',
      // sorter: (a, b) => a.sale0rderNo - b.sale0rderNo,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      // responsive: ['lg'],
      // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Issued Quantity',
      dataIndex: 'issuedQuantity',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.quantity - b.quantity,
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('quantity')
    },    
    {
      title: 'Indent Number',
      dataIndex: 'indentNumber',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.indentNumber - b.indentNumber,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNumber')

    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
   

    },   
    {
      title:'Acknowledge',
      render: (rowData) =>(
                <span>
              {rowData.status == packingIndentEnum.ISSUESD ? 
             
              <Popconfirm title={'sure to issue'} 
              onConfirm={e =>{updateReceivedQuantity(rowData.indentNumber)}}
              >
              <Button type="primary" shape="round" size="small">
                  Acknowledge
                </Button>
                </Popconfirm>
            
                :""}
            </span>
)
    },
  ];
  const columnsSkeltonforReceived: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Stock Type',
      dataIndex: 'stockType',
      // responsive: ['lg'],
      // sorter: (a, b) => a.stockType.localeCompare(b.stockType),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('stockType')
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      // width:'90px',
      // align:'right',
      // sorter: (a, b) => a.sale0rderNo - b.sale0rderNo,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      // responsive: ['lg'],
      // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Received Quantity',
      dataIndex: 'receivedQuantity',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.quantity - b.quantity,
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('quantity')
    },    
    {
      title: 'Indent Number',
      dataIndex: 'indentNumber',
      // width:'90px',
      align:'right',
      // sorter: (a, b) => a.indentNumber - b.indentNumber,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNumber')

    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
   

    },   
  ];

/**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
 const onChange=(pagination, filters, sorter, extra)=> {
  console.log('params', pagination, filters, sorter, extra);
}
//drawer related
const closeDrawer=()=>{
  setDrawerVisible(false);
}
const getindentbarcodedetails= () => {
  service.getindentbarcodedetails().then(res => {
    if (res.status) {setIndenbBarcodeData(res.data);
    } else {
      if (res.intlCode) {
        setIndenbBarcodeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
      } else {
       AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setIndenbBarcodeData([]);
  })
}
const getReceivedQuantityagainstPonumber= (indentnumber) => {
  const req= new IndentNumberRequest(indentnumber)
  console.log(req,'req')
  service.getReceivedQuantityagainstPonumber(req).then(res => {
    if (res.status) 
    {
    console.log(res)
    // data array lo ostundi
      form.setFieldsValue({
        indentQuantity: res.data[0].IndentQuantity,
      });
    } else {
      if (res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
    
      } else {
       AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setIndenbBarcodeData([]);
  })
}



const handleCancel = () => {
  setOpen(false);
  getindentbarcodedetails();
};

 const updateReceivedQuantity = (indentNumber:string) => {
  const req = new indentrequest(indentNumber, packingIndentEnum.RECEIVED,)
  console.log(req,'req');
    service.updateReceivedQuantity(req).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
     getindentbarcodedetails();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  // const updatestatus = (data: IndentFormDTO) => {
  //   const req = new indentrequest(indentnumber, packingIndentEnum.ISSUESD)
  //   console.log(req,'req');
  //     service.updateReceivedQuantity(req).then(res => { console.log(res);
  //       if (res.status) {
  //         AlertMessages.getSuccessMessage('Updated Successfully');
  //       } else {
  //         if (res.intlCode) {
  //           AlertMessages.getErrorMessage(res.internalMessage);
  //         } else {
  //           AlertMessages.getErrorMessage(res.internalMessage);
  //         }
  //       }
  //     }).catch(err => {
  //       AlertMessages.getErrorMessage(err.message);
  //     })
  //   }
  // const handlesubmit = () => {
  //   // alert();
  //   updateReceivedQuantity(data);
  //    setOpen(false);
  //    getindentbarcodedetails();
    
  // }

  return (
    <Card title={<span style={{color:'white'}}>Indent Register</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/indent-module-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}>
      <Tabs type={'card'} tabPosition={'top'}>
      <TabPane
          // tab="Open Orders"
          key="1"
          tab={<span style={{ color: "green" }}>{"Open: " + (indenbBarcodeData.filter(el => (el.status == packingIndentEnum.OPEN)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
            // rowKey={record => record.productId}
            columns={columnsSkelton}
            dataSource={indenbBarcodeData.filter(res =>
              (res.status == packingIndentEnum.OPEN))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="2"
          tab={<span style={{ color: "orange" }}>{"Issuesd: " + (indenbBarcodeData.filter(el => (el.status == packingIndentEnum.ISSUESD)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
            // rowKey={record => record.productId}
            columns={columnsSkeltonforissued}
            dataSource={indenbBarcodeData.filter(res =>
              (res.status == packingIndentEnum.ISSUESD))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="3"
          tab={<span style={{ color: "red" }}>{"Received: " + (indenbBarcodeData.filter(el => el.status == packingIndentEnum.RECEIVED).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
            // rowKey={record => record.productId}
            columns={columnsSkeltonforReceived}
            dataSource={indenbBarcodeData.filter(res =>
              (res.status == packingIndentEnum.RECEIVED))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>  
          </Tabs>
      
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
              onClose={closeDrawer} visible={drawerVisible} closable={true}>
              <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              </Card>
            </Drawer>
      {/* <Modal
        title="Received Quantity"
        // onOk={handleOk}
        onCancel={handleCancel}
        key={Date.now()}
        visible={open}
        width={'40%'}
        style={{ top: 30, alignContent: 'right' }}
        footer={[
          <Button key="back"  onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type='primary'  onClick={handlesubmit}>
            Submit
          </Button>,
         
        ]}
      >
              <div>
          <Form form={form}
            name="control-hooks"
            layout="vertical"
            onFinish={() => { updateReceivedQuantity }}
          >
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name="indentQuantity"
                label="Indent Quantity"

                rules={[
                  {
                    required: true,
                    message: 'indentQuantity required'
                  },
                  {
                    pattern: /^[0-9\b]+$/,
                    message: `Should contain only numbers`
                  }
                ]}>

                <Input />
              </Form.Item>
            </Col>
          </Form>
        </div>
      </Modal> */}
          </Card>
  );
}

export default BarCodeIndentGrid;
