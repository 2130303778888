import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AvailableStockQtyResponseModel, ConsumedQtyRequest, ConsumptionReportRequest, EtdWiseDTO, GetAllConsumptionItemsResponseModel, getAllStoreConsumptionsResponse, GetConsumedItemsRequest, GetStoreConsumptionResponse, GetStoreConsumptionSosResponseModel, IndentFormDTO, IssuesDropDownResponse, ItemCodeRequestDto, PlaceListDTO, PlaceListPalletDto, saleOrderNoRequest, StocktemRequest, StoreConsumptionDetails, StoreConsumptionReportResponse, StoreConsumptionRequest, StoreConsumptionResponse, StoreConsumptionStatus, StoreIssuesRequestDto, UpdatePlaceListStatus } from '@gtpl/shared-models/procurement-management';
import { CommonResponse, SoRequest } from '@gtpl/shared-models/sale-management';
export class StoreConsumptionService {
    URL = connection.DEPLOY_URL + '/store-consumption';


    async createStoreConsumption(storeConsumption: StoreConsumptionDetails): Promise<StoreConsumptionResponse> {
        return await axios.post(this.URL + '/createStoreConsumedStock', storeConsumption)
            .then(res => {
                return res.data
            })
    }

    async getConsumedStock(stockItemRequest: StocktemRequest) {
        return await axios.post(this.URL + '/getConsumedStockQty', stockItemRequest)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAllStoreConsumptionDetails(req: UnitRequest): Promise<getAllStoreConsumptionsResponse> {

        return await axios.post(this.URL + '/getAllStoreConsumptionsData', req)
            .then(res => {
                return res.data
            })
    }

    async getAllCancelledSosStoreConsumptions(req: UnitRequest): Promise<getAllStoreConsumptionsResponse> {
        return await axios.post(this.URL + '/getAllCancelledSosStoreConsumptions', req)
            .then(res => {
                return res.data
            })
    }

    async getAllRevisedSosStoreConsumptions(req: UnitRequest): Promise<getAllStoreConsumptionsResponse> {
        return await axios.post(this.URL + '/getAllRevisedSosStoreConsumptions', req)
            .then(res => {
                return res.data
            })
    }

    async getAllStoreConsumptionItemDetails(): Promise<GetAllConsumptionItemsResponseModel> {

        return await axios.post(this.URL + '/getAllStoreConsumptionItemDetails')
            .then(res => {
                return res.data
            })
    }

    async getStoreConsumptionDataForId(storeConsumptionRequest: StoreConsumptionRequest): Promise<GetStoreConsumptionResponse> {
        console.log(storeConsumptionRequest);
        return await axios.post(this.URL + '/getStoreConsumptionDataForId', storeConsumptionRequest)
            .then(res => {
                // console.log(res.data);
                return res.data
            })
    }

    async getConsumedItemsData(soIdReq: GetConsumedItemsRequest) {
        return await axios.post(this.URL + '/getConsumedItemsForSo', soIdReq)
            .then(res => {
                return res.data
            })
    }

    async getStoreConsumptionItemsById(soIdReq: GetConsumedItemsRequest) {
        return await axios.post(this.URL + '/getStoreConsumptionItemsById', soIdReq)
            .then(res => {
                return res.data
            })
    }

    async getConsumedNumbers(soIdReq: SoRequest): Promise<IssuesDropDownResponse> {
        return await axios.post(this.URL + '/getConsumedNumbers', soIdReq)
            .then(res => {
                return res.data
            })
    }

    async getConsumedQty(req: ConsumedQtyRequest): Promise<number> {
        return await axios.post(this.URL + '/getConsumedQty', req)
            .then(res => {
                return res.data
            })
    }
    async getStoreConsumptionSOs(req: UnitRequest): Promise<GetStoreConsumptionSosResponseModel> {
        return await axios.post(this.URL + '/getStoreConsumptionSOs', req)
            .then(res => {
                return res.data
            })
    }

    async getAvailableConsumedStockForSO(req: ConsumptionReportRequest): Promise<StoreConsumptionReportResponse> {
        return await axios.post(this.URL + '/getAvailableConsumedStockForSO', req)
            .then(res => {
                return res.data
            })
    }
    async getSoDropForScReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForScReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getItemDropForScReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemDropForScReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getIssuedQty(req: ItemCodeRequestDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getIssuedQty', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async createStoreIssues(req: StoreIssuesRequestDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createStoreIssues', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getPlaceListData(req: any) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPlaceListData', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async placeListPrintData(req: GetConsumedItemsRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/placeListPrintData',req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async updatePalletCodeInPlaceList(req: PlaceListPalletDto) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/updatePalletCodeInPlaceList', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async updatePlaceListStatus(req: UpdatePlaceListStatus) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/updatePlaceListStatus', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async updateReportedStock(req: UpdatePlaceListStatus) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/updateReportedStock', req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    
 
    
    async getSosForIndentForm() : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSosForIndentForm')
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    
    async getCountOfIndent() : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getCountOfIndent')
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    
    async getEtdWiseData(req:saleOrderNoRequest) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getEtdWiseData',req)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async getPoNumberForEtdGrid() : Promise<CommonResponse>{
        return await axios.post(this.URL + '/getPoNumberForEtdGrid')
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async saveEtdWiseGridData(dto:EtdWiseDTO[]) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/saveEtdWiseGridData',dto)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
}

