import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { DeptWiseDataModel } from "./dept-wise-data.model";


export class EmployeeAttendanceResponse extends GlobalResponseObject {
    data?: DeptWiseDataModel[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: DeptWiseDataModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}