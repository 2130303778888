import { TransferStatusEnum } from "@gtpl/shared-models/common-models";

export class TransferMaterialDTO{
    packingStockTransferId:number;
    plantId:number;
    fromPlant:number;
    toPlant:number;
    transferredDate:Date;
    note:string;
    dcNumber:string;
    mrnNumber:string; 
    status: TransferStatusEnum;
   
}