import { GlobalResponseObject } from "../global-response-object";
import { SoYearWiseShippedDetails } from "./so-yearwise-shipped";

export class SoYearWiseShippedDetailsResponse extends GlobalResponseObject{
    data?:SoYearWiseShippedDetails[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoYearWiseShippedDetails[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
    
}