import { Divider, Row, Col, Table, Drawer, Popconfirm, Switch, Tag, Input, Button, Card } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined, EditOutlined, RightSquareOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './rm-indent-qty-limit-grid.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import {RmIndentQtyLimit} from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/rm-indent-qty-limit';
import {RmIndentQtyLimitService} from '@gtpl/shared-services/raw-material-procurement';
import {RmIndentQtyLimitDto} from '@gtpl/shared-models/raw-material-procurement';
import moment from 'moment';
/* eslint-disable-next-line */
export interface RmIndentQtyLimitGridProps {}

export function RmIndentQtyLimitGrid(
  props: RmIndentQtyLimitGridProps
) {
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRmIndentQtyLimitData, setSelectedRmIndentQtyLimitData] = useState<any>(undefined);
  const [rmIndentQtyLimitData,setRmIndentQtyLimitData] = useState<RmIndentQtyLimitDto[]>([])
  const rmIndentQtyLimitService = new RmIndentQtyLimitService();


useEffect(()=>{
  getAll();
} , []); 

const getColumnSearchProps = (dataIndex: string) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: visible => {
    if (visible) { setTimeout(() => searchInput.current.select()); }
  },
  render: text =>
    text ? (
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : text
    )
      : null

});

const getAll = () => {
  rmIndentQtyLimitService.getAll().then(res => {
    if (res.status) {
      setRmIndentQtyLimitData(res.data);
    } else {
      if (res.intlCode) {
        setRmIndentQtyLimitData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    setRmIndentQtyLimitData([]);
    AlertMessages.getErrorMessage(err.message);
  })
}

const deleteRmIndentQtyLimit = (Data: RmIndentQtyLimitDto) => {
  Data.isActive = Data.isActive ? false : true;
  rmIndentQtyLimitService.activatedeActivate(Data).then(res => {
    console.log(res);
    if (res.status) {
      getAll();
      AlertMessages.getSuccessMessage('Success');
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}

const updateRmIndentQtyLimit = (Data: RmIndentQtyLimitDto) => {
  rmIndentQtyLimitService.update(Data).then(res => {
    console.log(res);
    if (res.status) {
      AlertMessages.getSuccessMessage('Updated Successfully');
      getAll();
      setDrawerVisible(false);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}


function handleSearch(selectedKeys, confirm, dataIndex) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

function handleReset(clearFilters) {
  clearFilters();
  setSearchText('');
};

const openFormWithData = (viewData: RmIndentQtyLimitDto) => {
  setDrawerVisible(true);
  viewData.date=moment(viewData.date, 'YYYY-MM-DD')
  setSelectedRmIndentQtyLimitData(viewData);

}

const closeDrawer = () => {
  setDrawerVisible(false);
}

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Date',
      dataIndex: 'date',
      responsive: ['sm'],

      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],

      render: (value, record: RmIndentQtyLimitDto) => {
        return moment(record.date).format('DD-MM-YYYY')
      }
    },
    {
      title: 'Planned Qty(Tones)',
      dataIndex: 'plannedQty',
      //  responsive: ['lg'],
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedQty')
    },
    {
      title: 'Indent Qty(Tones)',
      dataIndex: 'indentQty',
      // responsive: ['lg'],
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentQty')
    },

 
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Rm Indent Qty Limit');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteRmIndentQtyLimit(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate  ?'
                : 'Are you sure to Activate  ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Rm Indent Qty Limit</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to="/rm-indent-qty-limit"  ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
    <br></br>
    <Row gutter={24}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
        <Card title={'Total Indent Qty: ' + rmIndentQtyLimitData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
      </Col>
      <Col>
        <Card title={'Active: ' + rmIndentQtyLimitData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
      </Col>
      <Col>
        <Card title={'In-Active :' + rmIndentQtyLimitData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
      </Col>
    </Row>
    <br></br>
    <Table
      rowKey={record => record.productId}
      columns={columnsSkelton}
      dataSource={rmIndentQtyLimitData}
      pagination={{
        onChange(current) {
          setPage(current);
        }
      }}
      onChange={onChange}
      scroll={{ x: true }}
      bordered />
    <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
      onClose={closeDrawer} visible={drawerVisible} closable={true}>
      <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
        <RmIndentQtyLimit key={Date.now()}
          updateRmIndentQtyLimit={updateRmIndentQtyLimit}
          isUpdate={true}
          rmIndentQtyLimitData={selectedRmIndentQtyLimitData}
          closeForm={closeDrawer} />
      </Card>
    </Drawer>
  </Card>
  );
}

export default RmIndentQtyLimitGrid;
