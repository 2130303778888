import React, { useState, useRef, useEffect } from 'react';
import './CustomCard.css'

type CardColors = 'green' | 'red' | 'orange'

type Props = {
  duration: number;
  setColor?: (CardColors) => void;
};
const CounterDown: React.FC<Props> = ({ duration, setColor }) => {
  const Ref = useRef(null);

  const [timer, setTimer] = useState('00:00:00');
  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 60);
    console.log(total, 'total', seconds, 'seconds', minutes, 'minutes', hours, 'hours')
    // if(minutes >= 3 ) {
    //     setColor('green');
    // } else if(seconds + minutes > 0 ) {
    //     setColor('orange');`1`
    // } else {
    //     setColor('red')
    // }
    if (total >= 179000) {
      setColor('green');
    } else if (total > 0) {
      setColor('orange'); `1`
    } else {
      setColor('red')
    }
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: Date) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e.toString());
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes) +
        ':' +
        (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e: Date) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e.toString());
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes) +
        ':' +
        (seconds > 9 ? seconds : '0' + seconds)
      );
    }

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    (Ref.current as any) = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + duration);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return <h2 style={{ fontSize: '4vh' }}>{timer}</h2>;
};

export default CounterDown;