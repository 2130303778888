import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ContainerStatusInfo } from './containers-status-info';
export class AllContainerStatusInfo extends GlobalResponseObject{
    data?: ContainerStatusInfo[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data ContainerStatusInfo
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ContainerStatusInfo[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}