import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, DatePicker, Table, FormInstance, Tooltip, Divider, Popconfirm, InputNumber } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './indent-from-for-prepared-material.css';
import FormItem from 'antd/lib/form/FormItem';
import { ItemForBrandReq, ItemsDropDownDto } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService, SaleOrderService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { indentformDto, IndentFormItemsDto, IndentQuantityRequestDto, ItemIdReq } from '@gtpl/shared-models/procurement-management';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';
import { from } from 'rxjs';
import { table } from 'console';
import { IndentAgainistEnum, indentstatusenum } from '@gtpl/shared-models/common-models';
import { IndentformService } from '@gtpl/shared-services/procurement';




/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

/* eslint-disable-next-line */
/* eslint-disable-next-line */

export interface IndentItemFromProps {
  // addDataToItemDetails: (itemDetalFormdata: IndentFormItemsDto[]) => void;
  // saleOrderItemId: number;
  form: FormInstance<any>
  indentItemDetailForm: FormInstance<any>
  setIndentData: (indentFormItemsDto: IndentFormItemsDto[]) => void;
  isUpdate?: boolean
  initialValues: any
  saleOrderId: number
  status: string
  indentAgainst: string;
}
export function IndentItemFrom(props: IndentItemFromProps) {
  const { form } = props;
  const [itemsdata, setItemsdata] = useState<any>([]);
  const [itemDropdownData, setItemDropdownData] = useState<any[]>([]);
  const [itemName, setItemName] = useState<any>()
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [fieldsData, setFieldsData] = useState<any>(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [defaultFormData, setDefaultFormData] = useState<any>(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [itemSubCategory, setItemSubCategory] = useState<any>()

  const itemsubcategoryservice = new ItemSubCategoryService();
  const itemservice = new ItemsService();
  const indentservice = new IndentformService()

  let createdUser = "";
  let unitId = 0;
  let history = useHistory();

  console.log(props.saleOrderId, 'sale id')
  console.log(props.indentAgainst)

  useEffect(() => {
    if (props.saleOrderId >= 0) {
      getAllItemSubcategories()
    }
  }, [props.saleOrderId])

  const onFocus = () => {
    console.log('focus');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  const onReset = () => {
    setBtnChange(false);
    form.resetFields();
  }
  useEffect(() => {
    // getAllItemSubcategories();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }, [props.initialValues])

  React.useEffect(() => {
    console.log(props.initialValues)
    setTableData(props.initialValues)
  }, [props.initialValues]);

  console.log(tableData)

  const handleitem = (value, children) => {
    setItemSubCategory(children.children)
    getAllItemNameForSubcategories(value);
    appendData(value, children);
  }

  const getAllItemSubcategories = () => {
    itemsubcategoryservice.getitemsubcategoryforsalorderId({ saleOrderId: props.saleOrderId }).then((res) => {
      if (res.status) {
        setItemsdata(res.data)
      } else {
        setItemsdata([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const getAllItemNameForSubcategories = (value) => {
    itemservice.getitemsforsubcategories({ itemSubCategoryId: value, saleOrderId: props.saleOrderId }).then((res) => {
      console.log(res)
      if (res.status) {
        setItemDropdownData(res.data)
      } else {
        setItemDropdownData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getQuantityValidationforIndent = () => {
    const saleOrderId = props.saleOrderId
    const itemId = form.getFieldValue('itemId')
    const req = new IndentQuantityRequestDto(saleOrderId, itemId)
    indentservice.getQuantityValidationforIndent(req).then((res) => {
      console.log(res)
      if (res.status) {
        console.log(res.data[0].excess?res.data[0].excess:0)
        if (Number(form.getFieldValue('quantity')) > Number(res.data[0].excess?res.data[0].excess:0)) {
          form.resetFields(['quantity'])
          return AlertMessages.getInfoMessage('quantity should not exceedd by bom quantity')
        }
        
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const openFormWithData = (rowData, index) => {

    setDefaultFormData(rowData);
    setIndexVal(index);
    setBtnChange(true);
    if (props.isUpdate) {
      getAllItemNameForSubcategories(rowData.itemsubcategoryId)
    }
    form.setFieldsValue({
      itemsubcategoryId: props.isUpdate ? rowData.itemsubcategoryId : rowData.itemsubcategoryId.children,
      // itemId: props.isUpdate ? rowData.itemId : props.indentAgainst == 'DUMMY' ? rowData.itemId : rowData.itemId.children,
      itemId: props.isUpdate ? rowData.itemId : rowData.itemId.children,
      quantity: rowData.quantity,
    });
    setFieldsData(rowData);
    // setIsUpdate(true);
  }

  const deleteData = (index) => {
    console.log(index)
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }
  const columns: ColumnProps<any>[] = [
    {
      title: 'Item Sub Category',
      dataIndex: 'itemsubcategory',
      render: (text, data, index) => {
        // console.log(text, data, index)
        return <span>{data?.itemsubcategoryId?.children ? data?.itemsubcategoryId?.children : text}</span>
      }

    },
    {
      title: 'Item Name',
      dataIndex: 'item',
      key: 'itemId',
      align: 'left',
      render: (text, data, index) => {
        // console.log(text, data, index)
        return <span>{data?.itemId?.children ? data?.itemId?.children : text}</span>
      }

    },
    {
      title: ' Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'left',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData) {
                  console.log(rowData);
                  openFormWithData(rowData, index);
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          {props.isUpdate ? " " :
            <Tooltip placement="top" title='dlete'>
              <Popconfirm title='Sure to delete?' onConfirm={e => { deleteData(index); }}>
                <MinusCircleOutlined
                  style={{ color: '#1890ff', fontSize: '14px' }} />
              </Popconfirm>

            </Tooltip>
          }
        </span>)
    }

  ]
  const saveIndentItems = (values: any) => {
    setFieldsData(values);
    console.log(values)
    console.log(btnChange)
    form.validateFields().then(res => {
      let tableName = tableData;
      console.log(tableName);
      let duplicateData;
      if (!btnChange) {
        // if (props.indentAgainst == 'DUMMY') {
        //   duplicateData = tableName.find(item => (item.itemsubcategoryId.key == fieldsData.itemsubcategoryId.key && item.quantity == fieldsData.quantity));
        // }
        //  else {
          duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key && item.itemsubcategoryId.key == fieldsData.itemsubcategoryId.key && item.quantity == fieldsData.quantity));
        // }
      }

      else {
        // if (props.indentAgainst == 'DUMMY') {
        //   duplicateData = tableName.find(item => (item.itemsubcategoryId.key == fieldsData.itemsubcategoryId.key && item.quantity == fieldsData.quantity));
        // }
        // else {
          duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key && item.itemsubcategoryId.key == fieldsData.itemsubcategoryId.key && item.quantity == fieldsData.quantity));
        // }
      }
      console.log('btnchange')
      console.log(duplicateData);

      if (duplicateData) {
        AlertMessages.getErrorMessage('Item already exist In Indent. ');
        onReset();
      } else {
        console.log('btnChangetest');

        if (!btnChange) {
          form.validateFields().then(res => {
            res.itemsubcategory = itemSubCategory
            res.item = itemName
            console.log(res)
            console.log(fieldsData)
            let dynTableData = [...tableData, fieldsData]
            setTableData(dynTableData);
            console.log(dynTableData)
            // setTableData((prevState) => { return [...prevState, res] })
            console.log(dynTableData)
            form.resetFields(['itemsubcategoryId', 'itemId', 'quantity']);
            props.setIndentData(dynTableData);
            // props.addDataToItemDetails(dynTableData);
          })

        } else {
          console.log("hiiiiiiiiiiiii")
          let dynTableData = [...tableData]
          dynTableData[indexVal].itemsubcategoryId = fieldsData.itemsubcategoryId
          dynTableData[indexVal].itemId = fieldsData.itemId
          dynTableData[indexVal].quantity = fieldsData.quantity

          // let dynTableData = [...tableData]
          // dynTableData[indexVal] = fieldsData
          // console.log(dynTableData);
          setShowTable(true);
          setTableData(dynTableData);
          props.setIndentData(dynTableData);
          console.log(dynTableData)
          // props.addDataToItemDetails(dynTableData);
        }
        onReset();
      }
    })
  };

  const appendInputData = (val, label) => {
    if (label == "quantity") {
      setFieldsData({ ...fieldsData, [label]: val });
    }
    getQuantityValidationforIndent()
  }
  const appendData = (val, row) => {
    console.log(row)
    console.log(val)
    const { label } = row;
    console.log(label);
    setFieldsData({ ...fieldsData, [label]: row })
    if (label === "itemsubcategoryId") {
      getAllItemNameForSubcategories(val);
      setItemSubCategory(row.children);
      form.resetFields(['itemId'])
    }
    else if (label === "itemId") {
      setItemName(row.children);
    }
  }
  const addItem = () => {
    form.validateFields().then((res) => {
      res.itemsubcategory = itemSubCategory
      res.item = itemName
      console.log(res)
      let dynTableData = [...tableData, res]
      setTableData(dynTableData);
      // setTableData((prevState) => { return [...prevState, res] })
      console.log(tableData)
      form.resetFields(['itemsubcategoryId', 'itemId', 'quantity']);
      props.setIndentData(tableData);

    })
  }

  console.log(props.initialValues)

  return (
    <Card title={<span style={{ color: 'white' }}>Add Indent Items</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout={'vertical'} initialValues={props.initialValues} onFinish={saveIndentItems}>
        <Row gutter={24}>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="indentItemId" >
            <Input hidden />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>
          <Col span={4}>
            <Form.Item name="itemsubcategoryId" label="Item Subcategory"
              rules={[{ required: true, message: "'itemName' is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Item Name"
                // onChange={(value, children) => { handleitem(value, children) }}
                onChange={appendData}
                allowClear
                disabled={props.isUpdate && (props.status != ('OPEN' || 'PARTIALLY TRANSFERRED'))}
              >
                <Option key={0} value={null}>Select Item Sub Category</Option>
                {itemsdata.map(dropData => {
                  return <Option key={dropData.itemSubCategoryId} label='itemsubcategoryId' value={dropData.itemSubCategoryId}>{dropData.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}
            // hidden={props.indentAgainst == "SALE ORDER" ? false : true}
          >
            <Form.Item name="itemId" label="Item Name"
              rules={[{
                required:true,
                // required: props.indentAgainst == 'SALE ORDER' ? true : false,
                message: "itemName' is required"
              }]}>

              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Item Name"
                onSelect={appendData}
                allowClear
                disabled={props.isUpdate && (props.status != ('OPEN' || 'PARTIALLY TRANSFERRED'))}
              >
                <Option key={0} value={null}>Select Item Name</Option>
                {itemDropdownData.map(dropData => {
                  return <Option key={dropData.itemId} label='itemId' value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item name="quantity" label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Enter Indent Quantity"
                },
                {
                  pattern: /^[0-9\b]+$/,
                  message: `Should contain only numbers`
                },
              ]}  >
              {/* < InputNumber
                min={1} max={10}
                onChange={ e => appendInputData(e.target)} 
               /> */}
              <Input type='number' onChange={e => appendInputData(e.target.value, 'quantity')}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item >
              <Button style={{ backgroundColor: "#67b32d", color: "white", marginTop: "40px" }} type="primary" htmlType="submit" >
                {btnChange ? 'UPDATE' : 'ADD'}
              </Button>
            </Form.Item>
          </Col>
          <br />
          <Col span={2}>
            <Form.Item >
              <Button style={{ backgroundColor: "#f19c9b", color: "white", marginTop: "40px" }} onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>

        </Row>
        <br />
        {tableData.length ?
          <Table key={Date.now()} scroll={{ x: 500 }}
            dataSource={tableData}
            columns={columns} pagination={false} /> :
          <></>
        }

      </Form>




    </Card>
  );
}
export default IndentItemFrom;