import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SpecSheetDto } from './spec-sheet.dto';

export class AllSpecSheetResponseModel extends GlobalResponseObject{
    data?:SpecSheetDto[];
     /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

      constructor(status: boolean, intlCode: number, internalMessage: string, data?: SpecSheetDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}