export class PoDataDto {
  purchaseOrderDate:Date;
  purchaseOrderNumber:string;

 /**
  * 
  * @param purchaseOrderDate 
  * @param purchaseOrderNumber 
  */
  constructor(purchaseOrderDate:Date,
    purchaseOrderNumber:string,){
    this.purchaseOrderDate = purchaseOrderDate;
    this.purchaseOrderNumber = purchaseOrderNumber;
  }
}

