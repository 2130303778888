export class AssetMaintainanceTransactionsDto{
    assetMaintainanceTransactionsId : number;
    assetMaintainanceId : number;
    assetId : number;
    assetLocationId : number;
    assetCondition : string;
    maintainanceFrequencyInDays : string;
    assigned: string;
    maintainanceType: string;
    owner: string;
    expiredDate : any;
    costOfRepair:string;
    damageReason: string;
    remarks: string;
    status: string;
    isActive : boolean;
    createdAt : Date;
    createdUser: string;
    updatedAt : Date;
    updatedUser: string;
    versionFlag: number;
    itemName?: string;
    assetsCode?: string;
    employeeName?: string;
    assetCategory?: string;
    assetType?: string;
    plant?: string;
    currentLocation?: string;
    assetMode?:string;

    /**
     * 
     * 
     * @param assetMaintainanceTransactionsId 
     * @param assetMaintainanceId 
     * @param assetId 
     * @param assetLocationId 
     * @param assetCondition 
     * @param maintainanceFrequencyInDays 
     * @param assigned 
     * @param maintainanceType 
     * @param owner 
     * @param expiredDate 
     * @param costOfRepair 
     * @param damageReason
     * @param remarks 
     * @param status 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(assetMaintainanceTransactionsId : number,assetMaintainanceId : number,assetId : number,assetLocationId : number,
        assetCondition : string, maintainanceFrequencyInDays : string,assigned: string, maintainanceType: string,owner: string,
        expiredDate : any,costOfRepair:string,damageReason:string,remarks: string,status: string,isActive : boolean,createdAt : Date,
        createdUser: string,updatedAt : Date,updatedUser: string,versionFlag: number,itemName?: string,
        assetsCode?: string,employeeName?: string,assetCategory?: string,assetType?: string,
        plant?: string,
        currentLocation?: string,assetMode?:string){

            this.assetMaintainanceTransactionsId  = assetMaintainanceTransactionsId;
            this.assetMaintainanceId = assetMaintainanceId;
            this.assetId = assetId;
            this.assetLocationId = assetLocationId;
            this.assetCondition = assetCondition;
            this.maintainanceFrequencyInDays = maintainanceFrequencyInDays;
            this.assigned = assigned;
            this.maintainanceType = maintainanceType;
            this.owner = owner;
            this.expiredDate = expiredDate;
            this.costOfRepair = costOfRepair;
            this.damageReason = damageReason;
            this.remarks = remarks;
            this.status = status;
            this.isActive = isActive;
            this.createdAt = createdAt;
            this.createdUser = createdUser;
            this.updatedAt = updatedAt;
            this.updatedUser = updatedUser;
            this.versionFlag = versionFlag;
            this.itemName =itemName;
            this.assetsCode = assetsCode;
            this.employeeName = employeeName;
            this.assetCategory = assetCategory;
            this.assetType = assetType;
            this.plant = plant;
            this.currentLocation = currentLocation;
            this.assetMode = assetMode;
    }

}