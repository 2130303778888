
export class EtdWiseDTO {
    etdWiseId: number;
    saleOrderId: number;
    actualEdaDate: Date;
    revisedEdaDate: Date;
    unitId:number;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    constructor(
        etdWiseId: number,
        saleOrderId: number,
        actualEdaDate: Date,
        revisedEdaDate: Date,
        unitId:number,
        createdUser: string,
       
    ){

        this.etdWiseId = etdWiseId;
        this.saleOrderId = saleOrderId;
        this.actualEdaDate = actualEdaDate;
        this.revisedEdaDate = revisedEdaDate;
        this.unitId = unitId;
        this.createdUser = createdUser;

    }
}