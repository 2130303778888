export const GlobalVariableValues = {
    GradingOutputAbove50:30,
    GradingOutputBelow50:33,
    companyId:7,
    assetmanagementcompanyId:9,
    salemoduleId:4,
    warehouseModuleId:1,
    hrmsModuleId:2,
    procurementmoduleId:5,
    logisticsmoduleId:6,
    downtimeModuleId:12,
    supplierPortaModuleId:13,
    rmProcurementmoduleId:14,
    assetManagementmoduleId:11,
    canteenmanagementmoduleId:16,
    AllModuleId:15,
    vistorManagementId:18,
    productionModuleId : 20
}