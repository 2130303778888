import { CommonResponse, PlantIdRequest } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import connection from './connection';
import { AllContainerLogBookResponseModel, ContainerLoadingReportResponseModel, ContainerLogBookDTO, ContainerLogBookResponseModel, FileUploadResponse } from '@gtpl/shared-models/logistics'
import { SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';
export class ContainerLogBookService{
    URL = connection.DEPLOY_URL + '/container-log-book';

    async containerLoadingFileUpload(file: any): Promise<FileUploadResponse> {
      return await axios.post(this.URL + '/containerLoadingFileUpload', file)
          .then(res => {
              return res.data
          })
    }
    async getCreateContainerLogBook(req:ContainerLogBookDTO): Promise<ContainerLogBookResponseModel> {
        return await axios.post(this.URL + '/getCreateContainerLogBook', req)
          .then((res) => {
            return res.data;
          });
      }
    async getUpdateContainerLogBook(req:ContainerLogBookDTO): Promise<ContainerLogBookResponseModel> {
      return await axios.post(this.URL + '/getUpdateContainerLogBook', req)
        .then((res) => {
          return res.data;
        });
    }
    async getContainerLogDetails(): Promise<AllContainerLogBookResponseModel> {
        return await axios.post(this.URL + '/getContainerLogDetails')
          .then((res) => {
            return res.data;
          });
      }
      async getContainerLoadingReport(req:SaleOrderIdRequest): Promise<ContainerLoadingReportResponseModel> {
        return await axios.post(this.URL + '/getContainerLoadingReport',req)
          .then((res) => {
            return res.data;
          });
      }
      async getSaleOrderDropdown(req:PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSaleOrderDropdown',req)
          .then((res) => {
            return res.data;
          });
      }
}
