import { DeviceStatusEnum, DeviceTypeEnum } from "../enums";

export class DeviceModel{
    plantId: number;
    deviceName: string;
    deviceType: DeviceTypeEnum;
    deviceMacId: string;
    deviceStatus: DeviceStatusEnum;
    lastReportedTime: Date;
    deviceErrorMsg: string;
    deptName: string;
    deptCode: string;
    module: string;
    secretKey: string;

   /**
    * 
    * @param plantId 
    * @param deviceName 
    * @param deviceType 
    * @param deviceMacId 
    * @param deviceStatus 
    * @param lastReportedTime 
    * @param deviceErrorMsg 
    * @param deptName 
    * @param deptCode 
    * @param module 
    * @param secretKey
    */
    constructor(plantId: number,deviceName:string,deviceType:DeviceTypeEnum,deviceMacId: string,deviceStatus: DeviceStatusEnum,lastReportedTime: Date,deviceErrorMsg: string,deptName: string, deptCode: string, module: string, secretKey: string){        
        this.plantId = plantId;  
        this.deviceName = deviceName;
        this.deviceType = deviceType;      
        this.deviceMacId = deviceMacId;
        this.deviceStatus = deviceStatus;
        this.lastReportedTime = lastReportedTime;
        this.deviceErrorMsg = deviceErrorMsg;
        this.deptName = deptName;
        this.deptCode = deptCode;
        this.module = module;
        this.secretKey = secretKey;
    }
}