import { AllRmColumnsResponseModel, RmColumnDto, RmColumnRequest, RmColumnResponse, RmColumnsDropDownResponseModel, RmCreateColumnsResponseModel } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';
 
export class RmColumnService{

    URL = connection.DEPLOY_URL + '/rmcolumns';
    
    async createColumn(columnDto: RmColumnDto): Promise<RmCreateColumnsResponseModel>{
        return await axios.post(this.URL+ '/createColumns', columnDto)
            .then(res =>{
                return res.data
            })
    }
    async updateColumn(columnDto: RmColumnDto): Promise<RmCreateColumnsResponseModel>{
     return await axios.post(this.URL+ '/updateColumns', columnDto)
         .then(res =>{
             return res.data
         })
    }
    async getAllColumns(): Promise<AllRmColumnsResponseModel>{
     console.log(URL);
     return await axios.post(this.URL+ '/getAllColumns')
         .then(res =>{
             return res.data
         })
    }
    async ActivateorDeactivateColumn(columnDto: RmColumnDto): Promise<RmColumnResponse>{
     return await axios.post(this.URL+ '/activateorDeactivateColumn', columnDto)
         .then(res =>{
             return res.data
         })
    }
    async getColumnById(columnRequest: RmColumnRequest): Promise<any>{
     return await axios.post(this.URL+ '/getColumnById', columnRequest)
         .then(res =>{
             return res.data
         })
    }
    async getAllColumnDropDown():Promise<RmColumnsDropDownResponseModel>{
        return await axios.post(this.URL + '/getAllColumnDropDown')
        .then(res => {
            return res.data
        })   
      }
      

}