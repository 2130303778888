export class PalletSuggestionDto {
    existingPallets: Pallets[];
    newPallets?: Pallets[];

    constructor(existingPallets:Pallets[],newPallets?:Pallets[]){
        this.existingPallets=existingPallets;
        this.newPallets=newPallets;

    }
   
}


export class Pallets{
    palletId:number;
    palletNo:string;

    constructor(palletId:number,palletNo:string,
        // palletcapacity:number
        ){

        this.palletId = palletId;
        this.palletNo = palletNo;
        // this.palletcapacity = palletcapacity;

    }
}