import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { FreezingDto } from './freezing.dto';
import { FreezingGetDto } from './get-freezing.dto';

export class AllFreezingResponseModel extends GlobalResponseObject{
    data?: FreezingGetDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: FreezingGetDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
