import axios from 'axios';
import connection from './connection';
import {ValueAdditionThreeDto , valueAdditionThreeResponse , AllvalueAdditionThreeResponse, ValAddThreeDropDownData, ValAddThreeDropDownDataResponseModel, ValueAdditionIdRequest, valueAdditionIdResponse } from '@gtpl/shared-models/masters'
import { UserRequestDto } from '@gtpl/shared-models/common-models';



export class Type3Service{
    URL = connection.DEPLOY_URL + '/value-addition3-types';

    async createTypesOfValueAddition3(dto: ValueAdditionThreeDto): Promise<valueAdditionThreeResponse> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createValAddThreeType',dto)
            .then(res => {
                return res.data
            })
}

async updateTypesOfValueAddition3(typesOfValueAddition2: ValueAdditionThreeDto): Promise<valueAdditionThreeResponse> {
    return await axios
      .post(this.URL + '/updateValAddThreeType',typesOfValueAddition2)
      .then((res) => {
        return res.data;
      });
  }

async  activatedeActivateTypesOfValueAddition3(dto: ValueAdditionThreeDto): Promise<valueAdditionThreeResponse> {
    //   console.log(State.stateCode);
    return await axios.post(this.URL + '/activateOrDeactivateValAddThreeType',dto)
            .then(res => {
                return res.data
            })
}

async getAllTypesOfValueAddition3s(req?:UserRequestDto): Promise<AllvalueAdditionThreeResponse> {
                
    return await axios.post(this.URL + '/getAllValAddTypes',req)
        .then(res => {
            return res.data
        })     
}

async getAllAvtiveValAddThreeTypes(): Promise<AllvalueAdditionThreeResponse> {
                
    return await axios.post(this.URL + '/getAllAvtiveValAddThreeTypes')
        .then(res => {
            return res.data
        })     
}


async getValAdditionThreeTypes(){
    //   alert('hai')
    return new ValAddThreeDropDownDataResponseModel(true,11,'customersData retrived successfully',[new ValAddThreeDropDownData(1,'Tail On'),new ValAddThreeDropDownData(2,'Tail Off')])
}

    /**
     * Function to get valAddDetails
     * @param valueAdditionId  
     */
    async  getValAddDetails(valAddOneReq: ValueAdditionIdRequest): Promise<valueAdditionIdResponse> {
        return await axios.post(this.URL + '/getValAddDetails', valAddOneReq).then(res => {return res.data});
    }

}

