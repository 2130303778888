export class IndentQtyModel{
    productId: number;
    expectedCount: number;
    expectedQty: number;
    totalIndents?:number
    constructor(productId: number,expectedCount: number,expectedQty: number,totalIndents?:number){
        this.productId = productId;
        this.expectedCount = expectedCount;
        this.expectedQty = expectedQty;
        this.totalIndents = totalIndents;
    }
}