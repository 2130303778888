export class StockInRequest {
    reportingLogId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    shifts: string;
    reportedPerson: string;
    reportingCases: number;
    reportingDate: Date;
    createdUser: string;

   
     constructor(reportingLogId: number,
        saleOrderId: number,
        saleOrderItemId: number,
        shifts: string,
        reportedPerson: string,
        reportingCases: number,
        reportingDate: Date,
        createdUser: string,){
       this.reportingLogId = reportingLogId;
       this.saleOrderId = saleOrderId;
       this.saleOrderItemId = saleOrderItemId;
       this.shifts = shifts;
       this.reportedPerson = reportedPerson;
       this.reportingCases = reportingCases;
       this.reportingDate = reportingDate;
       this.createdUser = createdUser;
     }
  }
