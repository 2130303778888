import connection from "./connection";
import {AssetLocationMappingDto} from '../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";

export class AssetLocationMappingService {
    URL = connection.DEPLOY_URL + '/assetLocationMapping';

    async create(req: AssetLocationMappingDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/create',req)
        .then(res => {
            return res.data;
        })
    }

    async getLocationMappedAssets(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationMappedAssets',req)
        .then(res => {
            return res.data;
        })
    }

    async getLocationUnmappedAssets(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationUnmappedAssets',req)
        .then(res => {
            return res.data;
        })
    }

    async getassetCodeDropDownforLocationMapping(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getassetCodeDropDownforLocationMapping',req)
        .then(res => {
            return res.data;
        })
    }

    async getassetCategoryDropDownforLocationMapping(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getassetCategoryDropDownforLocationMapping',req)
        .then(res => {
            return res.data;
        })
    }

}