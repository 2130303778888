export class employeeUpdateRequest {
  employeeId?:number;
  employeeCode:string;
  employeeName:string;
  emailId:string;
  mobileNo:string;
  isTerminated:boolean;
  Role:string;
  costcenterId:number;
  status:boolean;
  createdUser:number;
  terminationApprovedBy:number;
  updatedUser: number | null;
  versionFlag: number;
}
export class employeeIdRequest {
  employeeId:number;
  updatedUser: number | null;
  versionFlag: number;
}
export class employeeStatusUpdateRequest{
  employeeId:number;
  status:boolean;
}