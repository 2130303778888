import React, { useEffect, useRef, useState } from 'react';
import { Card,Button, Descriptions, Layout, Table, Divider, Popconfirm, Switch, Tag, Rate, Input, Row, Col } from 'antd';
import {SearchOutlined , ArrowDownOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
// import Link from 'antd/lib/typography/Link';
import { Route, Link } from 'react-router-dom';
import {SaleOrderDetails} from '../data';
import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import { AlertMessages} from '../../../../../../shared-utils/alert-messages/src';

import './sale-order-detail-view-grid.css';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import { ColumnProps } from 'antd/lib/table';
import { CrrencySymbols, PortOfEntryInput, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import axios from 'axios';
import { appSettings } from 'apps/services/config';
import { UomEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface SaleOrderDetailViewGridProps {
  SaleOrderDetails:SaleOrderDetailViewInfoDTO;
  netWeightConversion:number;
  netWeightInKg:number;
  
}

export function SaleOrderDetailViewGrid(
  props: SaleOrderDetailViewGridProps
) {

  useEffect(() => {
    console.log(props.SaleOrderDetails);
  }, [props.SaleOrderDetails]);


  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [columnVisible, setColumnVisible] = useState<boolean>(false);
  const searchInput = useRef(null);
  const username = localStorage.getItem('username')



  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      console.log(searchInput.current);
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text => 
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  let weight = 0;
  const tableColumns = () => {

  let sampleTypeColumns: ColumnProps<any>[] = [
    
    {
      title: 'SNo',
      key: 'sno',
      width:70,
      align: 'center',
      fixed:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Brand',
      dataIndex: 'brand',
      width:90,
      // fixed:'left',
      align: 'center',
      // ...getColumnSearchProps('brand'),

      // // responsive: ['lg'],
      // sorter: (a, b) => a.brand.localeCompare(b.brand),
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'product',
    //   width:90,
      
    //   align: 'center',
    //   // ...getColumnSearchProps('product'),

    //   // // responsive: ['lg'],
    //   // sorter: (a, b) => a.product.localeCompare(b.product),
    //   // sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Grade',
    //   dataIndex: 'minGrade',
    //   width:70,
      
    //   align: 'center',
    //   // ...getColumnSearchProps('minGrade'),

    //   // responsive: ['lg'],
    //   render: (text,record) => {return record.minGrade + ' - ' + record.maxGrade},
    //   // sorter: (a, b) => a.minGrade.length - b.minGrade.length,
    //   // sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Product SKU',
      dataIndex: 'varient',
      width:120,
      
      align: 'center',
      // ...getColumnSearchProps('varient'),

      // // responsive: ['lg'],
      // sorter: (a, b) => a.varient.localeCompare(b.varient),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Pack Type',
      dataIndex: 'packingStyle',
      width:150,
      
      align: 'center',
      // ...getColumnSearchProps('packingStyle'),

      render: (text,record) => {return ` ${Number(record.pouches)}` + " * " + ` ${Number(record.pouchWeight)}` + ' ' + record.uom},

      // responsive: ['lg'],
      // sorter: (a, b) => a.packingStyle.length - b.packingStyle.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Net Case Weight',
      dataIndex: 'caseWeight',
      width:100,
      
      align: 'center',
      // ...getColumnSearchProps('caseWeight'),

      // responsive: ['lg'],
      render: (text,record) => {
        const selUom = UnitsOfWeightInput.find(item => item.value == record.uomId);

        let outputUom
        if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
          outputUom = UomEnum.LB;
        }
        if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
          outputUom = UomEnum.KG;
        }
        return ` ${Number(parseFloat(record.caseWeight).toFixed(2))}` + ' ' + outputUom
      },

      // sorter: (a, b) => a.caseWeight - b.caseWeight,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      // responsive: ['lg']
      width:80,
      
      align: 'center',
      // ...getColumnSearchProps('cases'),

      // sorter: (a, b) => a.cases - b.cases,
      // sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      width:100,
      align: 'center',
      render: (text,record) => {
        const selUom = UnitsOfWeightInput.find(item => item.value == record.uomId);

        let outputUom
        if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
          outputUom = UomEnum.LB;
        }
        if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
          outputUom = UomEnum.KG;
        }
        if(record.uomId == 1){
          let totWeightConversion = record.netAmount * 2;
          weight = totWeightConversion;
        }
        return `${Math.round(record.netWeight)}` + ' ' + outputUom},
    },
  
    {
      title: 'Net Shrimp Weight',
      dataIndex: 'shrimpWeight',
      align: 'center',
      // fixed:'right',

      width: 100,
      render: (text,record) => {return `${Math.round(record.shrimpWeight)}`},

      // responsive: ['lg'],
      // ...getColumnSearchProps('shrimpWeight'),

      // sorter: (a, b) => a.shrimpWeight - b.shrimpWeight,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title:'RM Count',
      dataIndex:'requiredCount',
      align: 'center',
      width:100,
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      width:100,
      
      align: 'right',
      // ...getColumnSearchProps('netAmount'),
      render: (text,record) => {
       
        return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + `${Math.round(record.netAmount)}`
      },


      // responsive: ['lg'],
      // sorter: (a, b) => a.netAmount - b.netAmount,
      // sortDirections: ['descend', 'ascend'],
    }, 
    {
      title: 'Style Weighment',
      dataIndex: 'styleWeighment',
      align: 'center',
      width:100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('styleWeighment'),

      // sorter: (a, b) => a.styleWeighment.localeCompare(b.styleWeighment),
      // sortDirections: ['descend', 'ascend'],
    }, 
    {
      title:'Category',
      dataIndex:'category',
      align: 'center',
      width:100,
    },
    {
      title:'Variety',
      dataIndex:'innerBagSizeId',
      align: 'center',
      width:100,
      render: (text,record) => {
        return <span>{text?text:'N/A'}</span>
      },
    },
    // {
    //   title: 'Glazing',
    //   dataIndex: 'minGlaze',
    //   align: 'center',
    //   width:100,
    //   // responsive: ['lg'],
    //   render: (text,record) => {return record.minGlaze + ' - ' + record.maxGlaze},
    //   // sorter: (a, b) => a.minGlaze.length - b.minGlaze.length,
    //   // sortDirections: ['descend', 'ascend'],
    // }, 
    // {
    //   title: 'Soaking Time',
    //   dataIndex: 'soakingTime',
    //   align: 'center',
    //   width:100,
    //   // responsive: ['lg'],
    //   // ...getColumnSearchProps('soakingTime'),

    //   // sorter: (a, b) => a.soakingTime - b.soakingTime,
    //   // sortDirections: ['descend', 'ascend'],
    // }, 
    // {
    //   title: 'Soaking Style',
    //   dataIndex: 'soakingStyle',
    //   align: 'center',
    //   // fixed:'right',

    //   width: 100,
    //   // responsive: ['lg'],
    //   // ...getColumnSearchProps('soakingStyle'),

    //   // sorter: (a, b) => a.soakingStyle.length - b.soakingStyle.length,
    //   // sortDirections: ['descend', 'ascend'],
    // }, 
    
    
  ]

  const superAdminColumns : any = 
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      width:130,
      
      align: 'center',
      // ...getColumnSearchProps('unitPrice'),
      render: (text,record) => {return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " "  + `${Number(parseFloat(record.unitPrice).toFixed(2))}`},
    }
  
 
  const invoiceColumns: ColumnProps<any>[] =
  [
    {
      title: 'Dispatch Quantity',
      dataIndex: 'dispatchQty',
      align: 'center',
      // fixed:'right',

      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('dispatchQty'),

      // sorter: (a, b) => a.dispatchQty - b.dispatchQty,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmnt',
      align: 'right',
      // fixed:'right',

      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('invoiceAmnt'),

      // sorter: (a, b) => a.invoiceAmnt - b.invoiceAmnt,
      // sortDirections: ['descend', 'ascend'],
      render: (text,record) => {
        return CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + `${Math.round(record.invoiceAmnt)}`
      },
    }
  
  ];
  const skewersColumns: ColumnProps<any>[] =
  [
    {
      title: 'Skewers',
      dataIndex: 'innerBags',
      align: 'center',
      // fixed:'right',
      // visible:{props.SaleOrderDetails.category==="Skewers"?true:false},
      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('innerBags'),
      // sorter: (a, b) => a.innerBags - b.innerBags,
      // sortDirections: ['descend', 'ascend'],
    }, 
    {
      title: 'Skewer Variety',
      dataIndex: 'innerBagSizeId',
      align: 'center',
      // fixed:'right',
      width: 100,
      // responsive: ['lg'],
      // ...getColumnSearchProps('innerBagSizeId'),

      // sorter: (a, b) => a.innerBagSizeId.localeCompare(b.innerBagSizeId),
      // sortDirections: ['descend', 'ascend'],
    }
  
  ];

  if(username == `"superadmin"`){
    sampleTypeColumns.push(superAdminColumns)
  }

  
  console.log(sampleTypeColumns)
  
  if(props.SaleOrderDetails.category === "Skewers" && props.SaleOrderDetails.isInvoiced === "YES"){
    return [...sampleTypeColumns,...invoiceColumns,...skewersColumns];
  }
  else if(props.SaleOrderDetails.category !== "Skewers" && props.SaleOrderDetails.isInvoiced === "YES"){
    return [...sampleTypeColumns,...invoiceColumns];
  }
  else if(props.SaleOrderDetails.category === "Skewers" && props.SaleOrderDetails.isInvoiced === "NO"){
    return [...sampleTypeColumns,...skewersColumns];
  }
  else if(props.SaleOrderDetails.category !== "Skewers" && props.SaleOrderDetails.isInvoiced === "NO"){
    return sampleTypeColumns;
  }
  else{
    return sampleTypeColumns;
  }
}

  const downloadEmployeeData = () => {
    window.location.href = "http://206.189.138.212/erpx_dev/upload-files/sale-order-upload-files/grid-export.pdf"
    
  // fetch('http://206.189.138.212/erpx/upload-files/sale-order-upload-files/grid-export.pdf')
  // fetch('file:///D:/erpx/upload-files/sale-order-upload-files/grid-export.pdf')
  // fetch('http://dev.schemaxtech.in/erpx/upload-files/sale-order-upload-files/grid-export.pdf', {mode:"no-cors"})
  //   .then(response => {
  //     response.blob().then(blob => {
  //       let url = window.URL.createObjectURL(blob);
  //       let a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'grid-export.pdf';
  //       a.click();
  //     });
  //     //window.location.href = response.url;
  // });
}
//  const fetchFile = () => {
//   axios({
//         url: `http://localhost/erpx/upload-files/sale-order-upload-files/grid-export.pdf`,
//         method: "POST",
//         // headers: headers,
//         responseType: "blob" // important
//     }).then(response => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute(
//             "download",
//             `grid-export.pdf`
//         );
//         document.body.appendChild(link);
//         link.click();
//     });
// }
const download = () => {
  console.log(`${props.SaleOrderDetails.filePath}`)
  const fileName = `${props.SaleOrderDetails.filePath}`;
  if(fileName != 'null'){
    setTimeout(() => {
      const response = {
        // file: 'http://206.189.138.212/erpx/'+`${props.SaleOrderDetails.filePath}`,
        file: appSettings.file_upload_path+`${props.SaleOrderDetails.filePath}`,
      };
      
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  }
  else{
    AlertMessages.getErrorMessage("Please upload file. ");

  }
}

  return (
    <>
  {/* <a href="http://206.189.138.212/erpx/upload-files/sale-order-upload-files/grid-export.pdf" target="_blank" rel="noopener noreferrer" download="grid-export.pdf">download</a> */}
    <Layout style={{padding:10, backgroundColor:'white' , border:10}}>
    <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>SO Detail View</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} extra={<> <Button href="/#/saleorder-tracker" style={{color:'black'}}>SO Tracker</Button> <Button style={{marginLeft:20}} onClick={download}> <ArrowDownOutlined />Download Work Order & Code list</Button>
              </>} 
              
              >
        <br></br>
        
       
              
        {props.SaleOrderDetails? <>
          <Card title={<span style={{ color: 'black', border: 0 }}>Customer Details</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0, maxHeight:20 }}>
          <Descriptions column={3}>
            <Descriptions.Item label="SO Number" style={{width:'33%'}}>
              {props.SaleOrderDetails.saleOrder}
            </Descriptions.Item>
            <Descriptions.Item label="Customer Name">
              {props.SaleOrderDetails.customer}
            </Descriptions.Item>
            <Descriptions.Item label="End Customer">
              {props.SaleOrderDetails.endCustomer}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
              <Descriptions.Item label="PO Date">
                {props.SaleOrderDetails.invDate}
              </Descriptions.Item>
              <Descriptions.Item label="Customer PO Number">
                {props.SaleOrderDetails.poNo}
              </Descriptions.Item>
              <Descriptions.Item label="Buyer PO Number">
                {props.SaleOrderDetails.buyerPoNo}
              </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
            {props.SaleOrderDetails.etd === props.SaleOrderDetails.actualEtd ? 
              <Descriptions.Item label="Sale Person">
                {props.SaleOrderDetails.salePerson}
              </Descriptions.Item> :
              <Descriptions.Item label="Revised ETD">
                {props.SaleOrderDetails.actualEtd}
              </Descriptions.Item>
            }
            <Descriptions.Item label="ETD">
              {props.SaleOrderDetails.etd}
            </Descriptions.Item>
            
            <Descriptions.Item label="ETA">
              {props.SaleOrderDetails.eta}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
          <Descriptions.Item label="Port Of Entry">
              {PortOfEntryInput.find(item => item.value == props.SaleOrderDetails?.portOfEntryId).name}
            </Descriptions.Item>
            <Descriptions.Item label="Final Country">
              {props.SaleOrderDetails.country}
            </Descriptions.Item>
            <Descriptions.Item label="Final Destination">
              {props.SaleOrderDetails.finalDestination}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
            <Descriptions.Item label="Delivery Terms">
              {props.SaleOrderDetails.deliveryTerms}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Terms">
              {props.SaleOrderDetails.paymentTerms}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Terms">
              {props.SaleOrderDetails.shippingTerms}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
          
            <Descriptions.Item label=" Processing Unit">
              {props.SaleOrderDetails.processingUnit}
            </Descriptions.Item>
            <Descriptions.Item label="Manufacturing Unit">
              {props.SaleOrderDetails.unit}
            </Descriptions.Item>
            <Descriptions.Item label="Currency">
              {props.SaleOrderDetails.currency}
            </Descriptions.Item>
          {/* <Descriptions.Item label="Category">
              {props.SaleOrderDetails.category}
            </Descriptions.Item> */}
          </Descriptions>
          <Descriptions column={3}>
            <Descriptions.Item label="Customer Address" style={{width:'33%', textAlign:'left'}} >
              {props.SaleOrderDetails.address}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Address" style={{width:'33%', textAlign:'left'}}>
              {props.SaleOrderDetails.destinationAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Is Invoiced?" style={{width:'33%', textAlign:'left'}}>
              {props.SaleOrderDetails.isInvoiced}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={3}>
            {props.SaleOrderDetails.etd === props.SaleOrderDetails.actualEtd ?
                "":
                <Descriptions.Item label="Sale Person">
                  {props.SaleOrderDetails.salePerson}
                </Descriptions.Item> 
            }
          </Descriptions>

        </Card>
            
          <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Product Details</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>
              <Table
                rowKey={record => record.Id}
                // columns={props.SaleOrderDetails.length>0?sampleTypeColumns:null}
                columns={(props.SaleOrderDetails.itemData).length==0?null:tableColumns()}
                dataSource={props.SaleOrderDetails.itemData}
                pagination={{ onChange(current) { setPage(current); } }}
                scroll={{ x: true }}
                size="middle"
                bordered
              ></Table>
              {(props.SaleOrderDetails.itemData).length==0?null:
          <><span><b>Total Weight(In LB) : {Math.round(props.netWeightConversion)}&nbsp;&nbsp;&nbsp;Total Weight(In KG) : {Math.round(props.netWeightInKg)}&nbsp;&nbsp;&nbsp;Total Amount : {CrrencySymbols.find(item => item.id == props.SaleOrderDetails.currencyId).symbol + " " + props.SaleOrderDetails.totAmount} &nbsp;&nbsp;&nbsp;Total Pouches : {props.SaleOrderDetails.totPouches} &nbsp;&nbsp;&nbsp;Total Cases : {props.SaleOrderDetails.totCases}</b></span></>
              }
            </Card>
            <br></br>
          <Card title={<span style={{ color: 'black', border: 0 }}>Certifications</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0, maxHeight:20 }}>
              <Descriptions column={3}>
                {/* <Descriptions.Item label="Style Weightment">
              {props.SaleOrderDetails.styleWeightment}

                                 </Descriptions.Item> */}
                <Descriptions.Item label="Certification">
                  {props.SaleOrderDetails.certfication}
                </Descriptions.Item>
                {props.SaleOrderDetails.certfication=="ASC" ?
                  <Descriptions.Item label="Certification Approved?">
                    {props.SaleOrderDetails.isCertified}
                  </Descriptions.Item>
                   :props.SaleOrderDetails.certfication=="BAP" ?
                   <Descriptions.Item label="Rating">
                    <Rate value={props.SaleOrderDetails.rating} count={4} style={{marginTop:'-9px'}}/>
                  </Descriptions.Item>
                  :""
                }
                
                
                {/* <Descriptions.Item label="Soaking Time">
                {props.SaleOrderDetails.soakingTime}

                                  </Descriptions.Item>
              </Descriptions>

              <Descriptions column={1}>
                <Descriptions.Item label="Soaking Style">
                {props.SaleOrderDetails.soakingStyle}


                </Descriptions.Item> */}
              </Descriptions>
            </Card><br></br><Card title={<span style={{ color: 'black', border: 0 }}>Packing Details</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0, maxHeight:20 }}>
              <Descriptions column={3}>
               
                <Descriptions.Item label="Brand Type">
                {props.SaleOrderDetails.brandDesignType.toUpperCase()}

                </Descriptions.Item>

                <Descriptions.Item label="Is Barnd Approved?">
                {props.SaleOrderDetails.isBrandApproved.toUpperCase()}

                </Descriptions.Item>

                
                {/* <Descriptions.Item label="No Of Pouches">
                {props.SaleOrderDetails.noOfPouches}

                  
                </Descriptions.Item>
                <Descriptions.Item label="No Of Cartons">
                {props.SaleOrderDetails.noOfCartone}
                  
                </Descriptions.Item> */}
                  <Descriptions.Item label="Additional Note">
                {props.SaleOrderDetails.additionalNote}

                </Descriptions.Item>
              </Descriptions>

              {/* <Descriptions column={3}>
              
              </Descriptions> */}
            </Card><br></br>
            
            <Row gutter={24}>
                    <Col span={12}>
                        <Card title={<span style={{ color: 'black' }}>Lab Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                          <Descriptions column={2}>
                            <Descriptions.Item label="Inspection">
                              {props.SaleOrderDetails.sgsInspection?props.SaleOrderDetails.sgsInspection:"-"}
                            </Descriptions.Item>
                           
                            <Descriptions.Item label="Lot Code">
                              {props.SaleOrderDetails.lotCode?props.SaleOrderDetails.lotCode:"-"}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card title={<span style={{ color: 'black' }}>QA/QC Inspection</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                          <Descriptions column={1}>
                            <Descriptions.Item label="Surveyer Loading">
                              {props.SaleOrderDetails.loadInspection}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                    </Col>
                </Row>
           <br></br></>:''}
       
        </Card><br></br>
   </Layout>

    </>
  );
}

export default SaleOrderDetailViewGrid;
