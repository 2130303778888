import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Input, Button, Row, Col, Form, Select, Spin, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UnitcodeService,ItemSubCategoryService, ItemsService } from '@gtpl/shared-services/masters';
import { PlantsDropDown,ItemSubCategoryDropDownDto,SubCatReqForCategoryId, ItemsDropDownDto } from '@gtpl/shared-models/masters';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import {  UnitRequest } from '@gtpl/shared-models/common-models';
import { StoreReturnService,StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { StoreReturnInformation, StoreConsumptionDetails } from '@gtpl/shared-models/procurement-management';
import { ReturnReq } from '@gtpl/shared-models/procurement-management';
import { Excel } from 'antd-table-saveas-excel';



export interface StoreReturnReportProps { }

export function StoreReturnReport(
  props: StoreReturnReportProps
) {
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [disable, setDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [storeReturnReport, setStoreReturnReport] = useState<StoreReturnInformation[]>([]);
  const { Option } = Select;
  const [storeConsumptionData, setStoreConsumptionData] = useState<StoreConsumptionDetails[]>([]);
  const { RangePicker } = DatePicker;
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);
  const [poNoDropDown, setPoNoDropDown] = useState<any[]>([]);
  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [itemSubDrop, setItemSubDrop] = useState<StoreReturnInformation[]>([]);
  const [itemDropDown, setItemDrop] = useState<StoreReturnInformation[]>([]);

  const unitsService = new UnitcodeService();
  const service = new StoreReturnService();
  const storeConsumptionService = new StoreConsumptionService();
  const subCategoryService = new ItemSubCategoryService();
  const itemService = new ItemsService();




  const getAllStoreReturnsReports = (onReset?: boolean) => {
    setDisable(true);
    setLoading(true);
    const req = new ReturnReq()

    let storeConsumptionCode = form.getFieldValue('storeConsumptionCode');
    let saleOrder = form.getFieldValue('saleOrder');
    let unitId = form.getFieldValue('unitId');
    let itemName = form.getFieldValue('itemName');
    let returnReasion = form.getFieldValue('returnReasion');
    let approvar = form.getFieldValue('approvar');
    let itemCtegory = form.getFieldValue('itemCtegory');
    let remarks = form.getFieldValue('remarks');
    let poNumber = form.getFieldValue('poNumber');
    let itemSubCategoryId = form.getFieldValue('itemSubCategoryId');
    let itemId = form.getFieldValue('itemId')
    if (form.getFieldValue('createdDate') != undefined) {
      const selectedFromDate = moment(form.getFieldValue('createdDate')[0]).format('YYYY-MM-DD');
      const selectedToDate = moment(form.getFieldValue('createdDate')[1]).format('YYYY-MM-DD');
      req.selectedCreatedFromDate = (selectedFromDate)
      req.selectedCreatedToDate = (selectedToDate)
    }
    req.storeConsumptionCode = storeConsumptionCode;
    req.saleOrder = saleOrder;
    req.unitId = unitId;
    req.itemName = itemName;
    req.returnReasion = returnReasion;
    req.approvar = approvar;
    req.itemCtegory = itemCtegory;
    req.remarks = remarks;
    req.poNumber = poNumber;
    req.itemSubCategoryId = itemSubCategoryId;
    req.itemId = itemId;
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = unitId
    }
    service.getAllStoreReturnsReports(req).then(res => {
      setDisable(false)
      if (res.status) {
        setStoreReturnReport(res.data);
        setLoading(false);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setStoreReturnReport([]);
        } else {
          setLoading(false);
        }
      }
    }).catch(err => {
      setStoreReturnReport([]);
      AlertMessages.getErrorMessage(err.message);
    })
    
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys);
    console.log(confirm);
    console.log(dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  }
  
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const onReset = () => {
    form.resetFields();
    setStoreReturnReport([]);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }

  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>

        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }

          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex)
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        {console.log(selectedKeys)}
        <Button
          type="primary"
          onClick={() => {
            handleSearch(selectedKeys, confirm, dataIndex)
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
      ) : null,
  });

  useEffect(() => {
    getAllPlants();
    getAllStoreConsumptionDetails();
    getSoDropForStoreReturnReport();
    getItemSubDropForStoreReturnReport();
    getItemDropForStoreReturnReport();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
    form.setFieldsValue({
      createdDate: [(moment(moment().format("YYYY-MM-DD")).subtract(2,'months')),moment(moment().format("YYYY-MM-DD"))] 
    })
    setSelectedCreatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(2,'months')));
    setSelectedCreatedToDate(moment(moment().format("YYYY-MM-DD")));
  }, []);

  const getAllPlants = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const getAllStoreConsumptionDetails = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getAllStoreConsumptionDetails(req).then(res => {
      console.log(res.data);
      if (res.status) {
        setStoreConsumptionData(res.data);
      } else {
        if (res.intlCode) {
          setStoreConsumptionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setStoreConsumptionData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSoDropForStoreReturnReport = () => {
    service.getSoDropForStoreReturnReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setPoNoDropDown(res.data);
      } else {
        setPoNoDropDown([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoNoDropDown([]);
    })
  }

  const getItemSubDropForStoreReturnReport = () => {
    service.getItemSubDropForStoreReturnReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        console.log(res)
        setItemSubDrop(res.data);
      } else {
        setItemSubDrop([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemSubDrop([]);
    })
  }

  const getItemDropForStoreReturnReport = () => {
    service.getItemDropForStoreReturnReport({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setItemDrop(res.data);
      } else {
        setItemDrop([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDrop([]);
    })
  }




  const EstimatedCreatedDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedCreatedFromDate(fromDate);
    setSelectedCreatedToDate(toDate);
    console.log(value);
  }
 



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unitId',
      align: 'center',
      width: 100,
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.unitId == value;
      },
    },
    {
      title: 'Person Name ',
      dataIndex: 'approvar',
      width: 120,
      align: 'left',
      // sorter: (a, b) => a.approvar.localeCompare(b.approvar),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('approvar')
    },
    {
      title: 'Store Consumption Code',
      dataIndex: 'storeConsumptionCode',
      width: 180,
      align: 'left',
      // sorter: (a, b) => a.storeConsumptionCode.localeCompare(b.storeConsumptionCode),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('storeConsumptionCode')
    },


    {
      title: 'Customer Po',
      dataIndex: 'poNumber',
      align: 'left',
      width: 140,
      // sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: 130,
      render: (value, record: ReturnReq, index) => {
        return moment(record.createdDate).format('YYYY-MM-DD');}
      // sorter: (a, b) => a.createdDate?.localeCompare(b.createdDate),
      // sortDirections: ['descend', 'ascend'],
      // 
     
    },

    {
      title: 'Store Return Date',
      dataIndex: 'storeReturnDate',
      width:130,
      render: (value, record: StoreReturnInformation, index) => {
        return moment(record.storeReturnDate).format('YYYY-MM-DD');
      }
      // sorter: (a, b) => moment(a.storeReturnDate).unix() - moment(b.storeReturnDate).unix(),
    },
    {
      title: 'Item category ',
      dataIndex: 'itemCtegory',
      width: 120,
      align: 'left',
      ...getColumnSearchProps('itemCtegory')
    },
    {
      title: 'Item ',
      dataIndex: 'itemName',
      width: 200,
      align: 'left',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
   
    {
      title: 'Return Reason',
      dataIndex: 'returnReasion',
      width: 120,
      align: 'left',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 120,
      align: 'left',
    },

  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */

  const onChangeHandler = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('storeReturnReport')
      .addColumns(data)
      .addDataSource(storeReturnReport,{str2num:true})
      .saveAs('store-return.xlsx');
  }

  const data = [
    { title: "Unit", dataIndex: "unitId" },
    { title: "Person Name", dataIndex: "approvar" },
    { title: "store Consumption Code", dataIndex: "storeConsumptionCode" },
    { title: "Customer Po", dataIndex: "saleOrder" },
    { title: "Created Date", dataIndex: "createdDate",render: (value, record) => (record.createdDate ? moment(record.createdDate).format('YYYY-MM-DD') : '-') },
    { title: "store Return Date", dataIndex: "storeReturnDate", render: (value, record) => (record.storeReturnDate ? moment(record.storeReturnDate).format('YYYY-MM-DD') : '-')},
    { title: "Item Ctegory", dataIndex: "itemCtegory" },
    { title: "Item", dataIndex: "itemName" },
    { title: "Return Reasion", dataIndex: "returnReasion" },
    { title: "Remarks", dataIndex: "remarks" },
  ]


  return (
    <Card title={<span style={{ color: 'white' }}>Store Return Report</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <br></br>
      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="unitId"
              label=" Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                style={{ width: '100%' }}
              >
                {plantData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
            <Form.Item
              name="poNumber"
              label=" Customer PO"
              rules={[
                {
                  required: false, message: 'Select PO',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select PO"
                allowClear
                style={{ width: '100%' }}
              >
                {poNoDropDown.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{dropData.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }}>
              <Form.Item
                name="itemSubCategoryId"
                label="Item Sub Category"
                rules={[
                  {
                    required: false,
                    message: 'Item Sub Category is required'
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Sub Category"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  <Option key={0} value={null}>Select Sub Category</Option>
                  {itemSubDrop.map((subCategory) => {
                    return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemCtegory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 9 }}>
            <Form.Item label="Item Name"
              name='itemName'
            >
              <Select showSearch
                allowClear
                placeholder="Select Item"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {itemDropDown === undefined ? '' :itemDropDown.map(dropData => {
                  return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <Form.Item name="createdDate"
                label="Created Date"
                rules={[
                  {
                    required: false,
                    message: "Missing Created Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedCreatedDate} />
              </Form.Item>
            </Col>
          <Col style={{ padding: '20px', marginTop: '10px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getAllStoreReturnsReports()}>
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          
        </Row>
      </Form>
      {storeReturnReport.length > 0 && <>
       <Row justify="end"><Col style={{ marginBottom: '5px' }}>
       <Button icon={<DownloadOutlined />} onClick={() => { exportExcel(); }} > GET EXCEL </Button>
     </Col></Row><Table
        rowKey={record => record.saleOrderId}
        columns={columnsSkelton}
        dataSource={storeReturnReport}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChangeHandler}
        scroll={{ x: 1500, y: 500 }}
        bordered /> : <></>
        <Card title={"TOTAL Return Items's: " + storeReturnReport.length} style={{ textAlign: 'left', height: 41, backgroundColor: 'none' }}>
        </Card></>
      }
    </Card >
  );


}
export default StoreReturnReport;

