
export class PoNumbersModel {
  purchaseOrderId: number;
  poNumber: string;
 

  /**
   * @param purchaseOrderId
  * @param poNumber
  
   */
  constructor(purchaseOrderId: number,poNumber: string){
    this.purchaseOrderId = purchaseOrderId;
    this.poNumber = poNumber;
    
  }
}

