export class EmployeeTypesDto{
    employeeTypesId: number;
    employeeTypes: string;
    isActive: boolean;
    updatedAt: Date | any;
    updatedUser: string;
    createdAt: Date | any;
    createdUser: string;
    versionFlag: number;

    constructor(
    employeeTypesId: number,
    employeeTypes: string,
    isActive: boolean,
    updatedAt: Date | any,
    updatedUser: string,
    createdAt: Date | any,
    createdUser: string,
    versionFlag: number,
    ){
        this.employeeTypesId = employeeTypesId;
        this.employeeTypes = employeeTypes;
        this.isActive = isActive;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.versionFlag = versionFlag;
    }
}

export const EmployeeTypesDtoDefault : EmployeeTypesDto = {
    employeeTypesId: 0,
    employeeTypes: "",
    createdUser:"",
    isActive:true,
    createdAt: new Date(),
    updatedAt: new Date(),
    updatedUser: '',
    versionFlag: 1,
};