import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { OperatonsRegisterDto } from './operatins-register.dto';
export class OperationsRegisterResponseModel extends GlobalResponseObject {
    data?: OperatonsRegisterDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: OperatonsRegisterDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

