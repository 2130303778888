import { FoodTypesDropDownData, FoodTypesDropDownDataResponseModel ,FoodTypeResponse,AllFoodTypesResponse,FoodTypesDto,FoodTypeRequest } from "@gtpl/shared-models/masters";
import connection from "./connection";
import axios from 'axios';
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export class FoodTypeService{ 
  URL = connection.DEPLOY_URL + '/food-type';

  async createFoodType(foodDto: FoodTypesDto): Promise<FoodTypeResponse> {
    return await axios.post(this.URL + '/createFoodType',foodDto)
        .then(res => {
            return res.data
        }) 
}
async updateFoodType(foodDto: FoodTypesDto): Promise<FoodTypeResponse> {
    return await axios.post(this.URL + '/updateFoodType',foodDto)
        .then(res => {
            return res.data
        }) 
}
async getAllFoodTypes(req?:UserRequestDto): Promise<AllFoodTypesResponse> {
    return await axios.post(this.URL + '/getAllFoodTypes',req)
        .then(res => {
            return res.data
        }) 
}
async getAllActiveFoodTypes(): Promise<AllFoodTypesResponse> {
    return await axios.post(this.URL + '/getAllActiveFoodTypes')
        .then(res => {
            return res.data
        }) 
}
async  activateDeactivateFoodType(foodDto: FoodTypesDto): Promise<FoodTypeResponse> {
    return await axios.post(this.URL + '/activateOrDeactivateFoodType', foodDto)
            .then(res => {
                return res.data
            })
}

async getFoodTypeDataById(foodTypeRequest:FoodTypeRequest): Promise<FoodTypeResponse> {
    return await axios.post(this.URL + '/getFoodTypeDataById', foodTypeRequest).then(res => {
      // console.log(res);
        return res.data
    });
  }

  async getFoodTypes(){
    //   alert('hai')
      return new FoodTypesDropDownDataResponseModel(true,11,'Food types retrived successfully',[new FoodTypesDropDownData(1,'Raw'),new FoodTypesDropDownData(2,'Cooked')])
  }

}