import React, { useEffect, useState } from 'react';
import { StoreConsumptionDetails,StoreConsumptionRequest,GetStoreConsumptionResponse,GetStoreConsumptionDetails, GetStoreConsumptionItemsDto } from '@gtpl/shared-models/procurement-management';
import { StoreConsumptionItemsDto} from '@gtpl/shared-models/procurement-management';

import {DeleteOutlined } from '@ant-design/icons';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';

import { Button, Card, Form, Row, Col, Select, Table, Space,Input, Layout, Descriptions } from 'antd';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { ColumnsType } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

export interface StoreConsumptionDetailViewProps {
}
export function StoreConsumptionDetailView(props:StoreConsumptionDetailViewProps){
    const storeConsumptionService = new StoreConsumptionService();
    const [storeConsumptionData, setStoreConsumptionData] = useState<GetStoreConsumptionDetails>(undefined);
    const [page, setPage] = React.useState(1);

    let location = useLocation();
    
    const getStoreConsumptionDataForId= (reqDate) => {
      
      storeConsumptionService.getStoreConsumptionDataForId({storeConsumptionId:reqDate}).then(res => {
        if (res.status) 
        {
          console.log(res.data);
          setStoreConsumptionData(res.data);
        } else {
          if (res.intlCode) {
            setStoreConsumptionData(undefined);
              AlertMessages.getErrorMessage(res.internalMessage);
          } else {
           AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setStoreConsumptionData(undefined);
      })
    }

  const sampleTypeColumns:ColumnsType<any>= [
    {
      title:'#',
      dataIndex:'storeConsumptionId',
      width: 50,
      fixed: 'left',
      align:'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Item Category',
      dataIndex: ["itemCategoryDetails","itemCategory"],
      align:'left',
      // render: (text, record) => {record.itemCategoryDetails.itemCategory},
    },
  {
      title: 'Item',
      dataIndex: ["itemDetails","itemName"],
      align:'left',
      // render: (text,record) => { record.itemCategoryDetails.itemCategory},
    },
    // {
    //   title: 'Item Name',
    //   dataIndex: ['storeConsomptionData.itemCategoryId'],
    //   align:'center',
    //   // render: (text,record) => {record.itemCategoryDetails.itemCategoryCode},
    // },
    
    {
      title: 'Consumed Quantity',
      dataIndex: 'quantity',
      // render: (text,record) => {record.quantity},
    },
];
const [loading, setLoading] = useState(true)

useEffect(() => {
  setTimeout(() => setLoading(false), 1000)
  if(location.state){
  getStoreConsumptionDataForId(location.state);
}
}, [location.state])
    
    return (
      <>
      
      <Layout style={{padding:10, backgroundColor:'white' , border:10}}>
      <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Store Consumption Detail View</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} extra={<Button href="/#/store-consumption-grid" style={{color:'black'}}>Store Consumption</Button> } >
          <br></br>
          
          {storeConsumptionData != undefined ?       
           <>
           <Descriptions column={4}>
              <Descriptions.Item label="Store Consumption No">
                  {storeConsumptionData.storeConsumptionCode}
                </Descriptions.Item>
              <Descriptions.Item label="Indent No" >
                {storeConsumptionData.indentNo}
              </Descriptions.Item>
              <Descriptions.Item label="Consumed Date">
                {moment(storeConsumptionData.consumedDate).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Indent Date">
                {moment(storeConsumptionData.indentDate).format('YYYY-MM-DD')}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={4}>
              <Descriptions.Item label="SO Number">
                {storeConsumptionData.saleOrder.saleOrderNumber}
              </Descriptions.Item>
            </Descriptions>
            </>
          :null}
            

           <br></br>
           <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Store Consumption Items</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>
                <Table
                columns={sampleTypeColumns}
                dataSource={(storeConsumptionData)?storeConsumptionData.storeConsumptionItems:[]}
                pagination={{ onChange(current) { setPage(current); } }}
                scroll={{ y: 500 }}
                size="small"
                bordered
              ></Table>
  
          </Card>
         
      </Card>
      
     </Layout>
  
      </>
    );
}


export default StoreConsumptionDetailView;