export class RmAuditRequest{
    grnItemId: number;
    actualCount: number;
    actualQuantity: number;
    constructor(
        grnItemId: number,
        actualCount: number,
        actualQuantity: number
    ){
     this.grnItemId=grnItemId;
     this.actualCount=actualCount;
     this.actualQuantity=actualQuantity;
    }
}
