import {UnitcodeDto} from './unit-code.dto'
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class UnitcodeResponseModel extends GlobalResponseObject{
    data?: UnitcodeDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //UnitcodeDto
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: UnitcodeDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
        this.internalMessage = internalMessage;

    }
}
