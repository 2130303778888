import React, { useEffect, useState } from 'react';

import './store-return-pages.css';
import { StoreReturnService } from '@gtpl/shared-services/procurement';
import { StoreReturnDto } from '@gtpl/shared-models/procurement-management';
import {StoreReturnGrid} from '@gtpl/pages/procurement/packing-materials-components/store-return-grid';
import {StoreReturnForm} from '@gtpl/pages/procurement/packing-materials-components/store-return-form';
import { Button, Card } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { StoreReturnInformation } from 'libs/shared-models/procurement-management/src/lib/store-returns/store-return-information.dto';
import { EmployeeRolesEnum, UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface StoreReturnPagesProps {}

export function StoreReturnPages(
  props: StoreReturnPagesProps
) {

  useEffect(() => {
    getAllStoreReturns();
  }, []);
  const [storeReturnData, setStoreReturnData] = useState<StoreReturnInformation[]>([]);
  const storeRtnService = new StoreReturnService();

  // get store return sdata 
  const getAllStoreReturns = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeRtnService.getAllStoreReturns(req).then((res) => {
      if(res.status){
        setStoreReturnData(res.data);
        console.log(res.data);
      }
    })
  }
  return (
    <Card title={<span style={{color:'white'}}>Store Returns</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={((JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.HO_PACKAGING_MANAGER) ?<Link to='/store-return-form' ><Button className='panel_button' >Create </Button></Link>:<></>}>
     <StoreReturnGrid storeReturnData = {storeReturnData}/>
    </Card>
  );
}

export default StoreReturnPages;
