import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {useLocation, useParams} from 'react-router-dom'
// import './po-detail-view-pages.css';
import {PoDto, UpdatePoDetails,AllPoDetailsInfo} from '@gtpl/shared-models/procurement-management'
import {PurchaseOrderService} from '@gtpl/shared-services/procurement';
import {PoDetailviewGrid} from '@gtpl/pages/procurement-masters/procurement-master-components/po-detailview-grid';


/* eslint-disable-next-line */
export interface PoDetailViewPagesProps {}

export function PoDetailViewPages(props: PoDetailViewPagesProps) {
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<AllPoDetailsInfo>()
  const service = new PurchaseOrderService;
  const [loading, setLoading] = useState(true);
  const {id} = useParams<any>()
  
  let location = useLocation();
  const getPurchaseOrderDetailsById= (reqDate) => {
    service.GetPoDataDetailsForPoId({purchaseOrderId:reqDate}).then(res => {
      if (res.status) 
      {
        setSelectedPurchaseOrder(res.data);
      } else {
        if (res.intlCode) {
          setSelectedPurchaseOrder(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSelectedPurchaseOrder(undefined);
    })
  }

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
    if(id){
      getPurchaseOrderDetailsById(id);
    }
  }, [id])
  return (
    <>
    <PoDetailviewGrid PurchaseOrderDetails={selectedPurchaseOrder} />
    </>
  );
}

export default PoDetailViewPages;
