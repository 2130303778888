import { JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class JobCompletedStatusDto{
    productionLogId:number;
    jobStatus?: JobCompletedEnum;
    assignFreezing?: JobCompletedEnum;


    constructor(productionLogId:number,jobStatus?:JobCompletedEnum, assignFreezing?: JobCompletedEnum){
        this.productionLogId = productionLogId;
        this.jobStatus = jobStatus;
        this.assignFreezing = assignFreezing;
    }
}