import { FgStatusEnum, GlobalStatus, OperationTypeEnum, PlantsEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class ProdInvData {
    productionInventoryId?: number;
    lotNumber?: string;
    saleOrderId?: number;
    saleOrderItemId?: number;
    physicalQuantity?: number;
    issuedQuantity?: number;
    intransitQuantity: number;
    wipQuantity?: number;
    wastageQuantity?: number;
    rejectedQuantity: number;
    inputGrade?: number;
    boxes?: number;
    plantId?: number;
    saleOrderNumber?: string;
    itemName?: string;
    subPlantId?: number;
    weighmentReq: GlobalStatus;
    operation?: OperationTypeEnum;
    nextOperation?: OperationTypeEnum;
    status?: FgStatusEnum;
    createdUser?: string;

    constructor(productionInventoryId?: number,
        lotNumber?: string,
        saleOrderId?: number,
        saleOrderItemId?: number,
        physicalQuantity?: number,
        issuedQuantity?: number,
        intransitQuantity?: number,
        wastageQuantity?: number,
        wipQuantity?: number,
        rejectedQuantity?: number,
        inputGrade?: number,
        boxes?: number,
        plantId?: number,
        saleOrderNumber?: string,
        itemName?: string,
        subPlantId?: number,
        weighmentReq?: GlobalStatus,
        operation?: OperationTypeEnum,
        nextOperation?: OperationTypeEnum,
        status?: FgStatusEnum,
        createdUser?: string) {
        this.productionInventoryId = productionInventoryId;
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.physicalQuantity = physicalQuantity;
        this.issuedQuantity = issuedQuantity;
        this.intransitQuantity = intransitQuantity;
        this.wipQuantity = wipQuantity;
        this.rejectedQuantity = rejectedQuantity;
        this.wastageQuantity = wastageQuantity;
        this.inputGrade = inputGrade;
        this.boxes = boxes;
        this.plantId = plantId;
        this.saleOrderNumber = saleOrderNumber;
        this.itemName = itemName;
        this.subPlantId = subPlantId;
        this.weighmentReq = weighmentReq;
        this.operation = operation;
        this.nextOperation = nextOperation;
        this.status = status;
        this.createdUser = createdUser;
    }

}

