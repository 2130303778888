import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ContainerBarChartDto } from './container-barchat.dto';


export class ContainerBarChartResponseModel extends GlobalResponseObject{

    data?:ContainerBarChartDto[];
  /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

   constructor(status: boolean, intlCode: number, internalMessage: string, data?: ContainerBarChartDto[]){
    super(status,intlCode,internalMessage);
    this.status = status;
    this.intlCode = intlCode;
    this.internalMessage = internalMessage;
    this.data = data;
}


}