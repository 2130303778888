import axios from 'axios';
import connection from './connection';
import {PackingCategoryDto} from '@gtpl/shared-models/masters'
import {PackingCategoryResponseModel} from '@gtpl/shared-models/masters'
import {AllPackingCategoryResponseModel} from '@gtpl/shared-models/masters'
export class PackingCategoryService{
    URL = connection.DEPLOY_URL + '/packing-category';


    async create(dto: PackingCategoryDto): Promise<PackingCategoryResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createPackingCategory',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: PackingCategoryDto): Promise<PackingCategoryResponseModel> {
    return await axios.post(this.URL + '/updatePackingCategory', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: PackingCategoryDto): Promise<PackingCategoryResponseModel> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivatePackingCategory', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(): Promise<AllPackingCategoryResponseModel> {
                
                // const dto = [];
                // for(let i=0;i<5;i++){
                //     const isActive = (i%2 == 1)? true:false;
                //                 dto.push(new PackingCategoryDto(i+1,'person',111,true,) )
                // }
                // return new AllPackingCategoryResponseModel(true,1111,'Profile retrieved successfully',dto)
                return await axios.post(this.URL + '/getAllPackingCategory')
                    .then(res => {
                        return res.data 
                    })     
            }

}