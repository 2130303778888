export class AssetLocationTransactionDto {

    assetLocationTransactionId : number;
    assetLocationId: number;
    assetId : number;
    locationId : number;
    isActive : boolean;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;

    constructor(
        assetLocationTransactionId : number,
        assetLocationId: number,
        assetId : number,
        locationId : number,
        isActive : boolean,
        versionFlag: number,
    ) {
        this.assetLocationTransactionId = assetLocationTransactionId;
        this.assetLocationId = assetLocationId;
        this.assetId = assetId;
        this.locationId = locationId;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
    }
}