import { CommonResponse } from "@gtpl/shared-models/masters";
import { TemperatureLogDTO, TemperatureLogResponse, TemperatureResponse, DateDptRequest, DeptTemperaturesResponse, DeptDateHourRequest, DayWiseDeptTempResponse, DayWiseTempRequest } from "@gtpl/shared-models/warehouse-management";
import axios from "axios";
import connection from "./connection";

export class TemperatureLogService {
    URL = connection.DEPLOY_URL + '/temperature-log';

    async createTemperatureLog (dto:TemperatureLogDTO): Promise<TemperatureLogResponse>{
        return await axios.post(this.URL +'/createTemperatureLog', dto)
        .then(res=>{
            return res.data
        })
    }
    
    async getTemperatures (tempReq: DateDptRequest): Promise<TemperatureResponse>{
        return await axios.post(this.URL +'/getTemperatures', tempReq)
        .then(res=>{
            return res.data
        })
    }
 
    async getCurrentTemperature (deptDateReq: DeptDateHourRequest): Promise<DeptTemperaturesResponse>{
        return await axios.post(this.URL +'/getCurrentTemperature', deptDateReq)
        .then(res=>{
            return res.data
        })
    }
    
    async getDayWiseTemeperaturesForDept (dayWiseReq: DayWiseTempRequest): Promise<DayWiseDeptTempResponse>{
        return await axios.post(this.URL +'/getDayWiseTemeperaturesForDept', dayWiseReq)
        .then(res=>{
            return res.data
        })
    }

    async getCountOfTemperature (): Promise<CommonResponse>{
        return await axios.post(this.URL +'/getCountOfTemperature')
        .then(res=>{
            return res.data
        })
    }

    async getAllTempDetails (): Promise<CommonResponse>{
        return await axios.post(this.URL +'/getAllTempDetails')
        .then(res=>{
            return res.data
        })
    }
}