import { GlobalStatus, PlantsEnum,JobCompletedEnum, SubPlantCodesEnum, WorkStationTypeEnum, JobStatusEnum, ShiftsEnum} from '@gtpl/shared-models/common-models';
export class OperatonsRegisterDto{
    productionLogId : number;
    lotNumber : string;
    subLotNumber : string;
    saleOrderId : number;
    saleOrderNumber : string;
    saleOrderItemId : number;
    operation : string ;
    jobNumber : string;
    subPlantId : number;
    workstationType : string;
    jobStatus : JobCompletedEnum;
    workstation : any;
    inputGrade : number;
    plannedGrade : number;
    actualGrade : number;
    inputQuantity : number;
    plannedOutputQuantity : number;
    actualOutputQuantity : number;
    ipBoxes : number ;
    opBoxes : number ;
    assignedUser : string;
    assignedTime : Date;
    plantId : number;
    shift : ShiftsEnum;
    createdUser : string;
    updatedUser : string;
    constructor(
        productionLogId : number,
        lotNumber : string,
        subLotNumber : string,
        saleOrderId : number,
        saleOrderNumber : string,
        saleOrderItemId : number,
        operation : string ,
        jobNumber : string,
        subPlantId : number,
        workstationType : string,
        jobStatus : JobCompletedEnum,
        workstation : any,
        inputGrade : number,
        plannedGrade : number,
        actualGrade : number,
        inputQuantity : number,
        plannedOutputQuantity : number,
        actualOutputQuantity : number,
        ipBoxes : number ,
        opBoxes : number ,
        assignedUser : string,
        assignedTime : Date,
        plantId : number,
        shift : ShiftsEnum,
        createdUser : string,
        updatedUser : string,
    ){
        this.productionLogId = productionLogId;
        this.lotNumber = lotNumber;
        this.subLotNumber = subLotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.saleOrderItemId = saleOrderItemId;
        this.operation = operation;
        this.jobNumber = jobNumber;
        this.subPlantId = subPlantId;
        this.workstationType = workstationType;
        this.jobStatus = jobStatus; 
        this.workstation = workstation;
        this.inputGrade = inputGrade; 
        this.plannedGrade = plannedGrade;
        this.actualGrade = actualGrade;
        this.inputQuantity = inputQuantity;
        this.plannedOutputQuantity = plannedOutputQuantity;
        this.actualOutputQuantity = actualOutputQuantity;
        this.ipBoxes = ipBoxes;
        this.opBoxes = opBoxes;
        this.assignedUser = assignedUser;
        this.assignedTime = assignedTime;
        this.plantId = plantId;
        this.shift = shift;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
    }
}

