import { SupplierCreationDTO} from '@gtpl/shared-models/masters';
import { CommonResponse } from '@gtpl/shared-models/production-management';
import axios from 'axios';
import connection from './connection';

export class SupplierMappingService {
    URL = connection.DEPLOY_URL + '/supplier-mapping';


    async create(supplierInfo:SupplierCreationDTO):Promise<CommonResponse> {
        return await axios.post(this.URL + '/create', supplierInfo)
            .then(res => {
                return res.data
            })
    }

    async login(supplierInfo:SupplierCreationDTO):Promise<CommonResponse> {
        return await axios.post(this.URL + '/login', supplierInfo)
            .then(res => {
                return res.data
            })
    }

    async getRegisteredSuppliers():Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRegisteredSuppliers')
            .then(res => {
                return res.data
            })
    }

    async updatePassword(dto:any):Promise<CommonResponse> {
        return await axios.post(this.URL + '/updatePassword',dto)
            .then(res => {
                return res.data
            })
    }



   
    

}