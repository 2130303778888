import { PlantsEnum } from "@gtpl/shared-models/common-models";

export class AntibiotsMicrobiologyInfo{
  infoId: number;
  grnId: number;
  productCode: string;
  lotNumber: string;
  deHeadingCenter: PlantsEnum;
  sampleDetails: string;
  variety: string;
  count: number;
  noOfBoxes: number;
  productId: number;
  plantId: number;
  CAPL:number;
  CAP: number;
  AOZL: number;
  AOZ: number;
  AMOZL: number;
  AMOZ: number;
  SEM: number;
  AHD: number;
  remarks: string;
  createdUser: string;
  updatedUser: string;
  versionFlag: number;
}