import React, { useEffect, useState } from 'react';
import { Button, Card,Drawer,Form,Row,Col,Alert,Select, Anchor,Spin, Input, DatePicker, Table, InputNumber, Tag} from 'antd';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'
import {StockInRequest, StockInService} from '@gtpl/shared-services/warehouse-management'
import {ZoneService} from '@gtpl/shared-services/masters';
import './reporting-form.css';
import form from 'antd/lib/form';
import moment from 'moment';
import { GlobalStatus, PalletType, ShiftsDropDown, ShiftsEnum } from '../../../../../../shared-models/common-models/src/lib/enum';
import { SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderDto, SaleOrderItemsDTO } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnProps } from 'antd/lib/table';
import { StockInDto } from '@gtpl/shared-models/warehouse-management';
import { ZoneDropDownDto } from '@gtpl/shared-models/masters';


const { Option } = Select;

/* eslint-disable-next-line */
export interface ReportingFormProps {}

export function ReportingForm(
  props: ReportingFormProps
) {
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  let createdUser= localStorage.getItem('createdUser');
  const [showItemsTable, setShowItemsTable] = useState<boolean>(false)
  const [refreshTable, setRefreshTable] = useState<boolean>(false)
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([])
  const [saleOrderItemsData, setSaleOrderItemsData] = useState<SaleOrderDetailViewInfoDTO>(undefined)
  const [saleOrderItemsId, setSaleOrderItemsId] = useState<number>(undefined)
  const [zoneDropDown, setZoneDropDown] = useState<ZoneDropDownDto[]>(undefined)
  let shifts = ShiftsDropDown;
  const saleOrderService = new SaleOrderService();
  const stockInService = new StockInService();
  const zoneService = new ZoneService();

  
  
  const getZones = () => {
    zoneService.getAllZoneDropDown().then((res) => {
      if (res.status) {
        console.log(res.data);
        setZoneDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setZoneDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setZoneDropDown([]);
    });
  }
  const getSaleOrderData = () => {
    saleOrderService.getSaleOrdersDropDownList().then((res) => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
 
  const getSaleOrderItems=(reqData)=>{
    saleOrderService.getSaleOrderDetailsById({saleOrderId:reqData}).then(res => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderItemsData(res.data);
        setRefreshTable(true);
        setShowItemsTable(true);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setShowItemsTable(false);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setShowItemsTable(false);

        }
        setSaleOrderItemsData(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData(undefined);
      setShowItemsTable(false);

    })
  }

  const handleReportCases=(record:StockInDto)=>{
    console.log(record);
    stockInService.reportStockIn(record).then(res => {
      console.log(res);
      if (res.status) {
        getSaleOrderItems(res.data.saleOrderId);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const handleSaleOrder = (value) => {
    console.log(`selected ${value}`);
    getSaleOrderItems(value);

  }
  const reportStock = (values:StockInDto, index:number) => {
    form.validateFields().then(res => {
      values.plantId = Number(localStorage.getItem("unit_id"));
      values.saleOrderItemId = saleOrderItemsId;
      values.loosePouches = form.getFieldValue("loosePouches"+index);
      values.lotCode = form.getFieldValue("lotCode");
      values.packingCompleted = form.getFieldValue("packingCompleted");
      values.palletId = form.getFieldValue("palletId");
      values.palletType = form.getFieldValue("palletType");
      values.rackPostion = form.getFieldValue("rackPostion");
      values.reportingCases = form.getFieldValue("reportingCases"+index);
      values.reportingDate = form.getFieldValue("reportingDate");
      values.saleOrderId = form.getFieldValue("saleOrderId");
      values.shifts = form.getFieldValue("shifts") === undefined? ShiftsEnum.General:form.getFieldValue("shifts");
      console.log(values);
      stockInService.reportStockIn(values).then(res => {
        if(res.status){
          setSaleOrderItemsId(undefined)
          AlertMessages.getSuccessMessage(res.internalMessage);
          getSaleOrderItems(res.data.saleOrderId);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }
  let saleOrderItemId = 0;
  const reportCases = (e:any,values: any) => {
    console.log(values);
    console.log(values.cases);
    console.log(values.reportedCases);
    console.log(e);
    if(e > (values.cases-values.reportedCases)){
      const reportingCase = "reportingCases"+values.saleOrderItemId;
      console.log(reportingCase);
      form.setFieldsValue({"reportingCases":0});
      form.setFieldsValue({reportingCase:0});
        AlertMessages.getErrorMessage('Reporting Cases must be less than Required Cases');
    }
    form.validateFields();
    values.reportingCases=e;
    console.log(e);
    console.log(values.reportingCases);
    form.setFieldsValue({saleOrderItemId:values.saleOrderItemId})
    form.setFieldsValue({reportingCases:e})
    form.setFieldsValue({palletId:1})
    saveData(form.getFieldsValue())

  }
  const setSaleOrderItem = (e,values: number) => {
    setSaleOrderItemsId(values);
  }
  const saveData = (values: any) => {
    console.log(values);
    handleReportCases(values);
  };

const tableColumns = () => {
  const saleOrderItemColumn: ColumnProps<any>[] =[
    
  ]
  const columns: ColumnProps<any>[] = [
    // {
    //   title: '',
    //   key: 'saleOrderItemId',
    //   dataIndex: 'saleOrderItemId',
    //   width:2,
    //   render: (text, rowData,index) =>{return (
    //     <>
    //     <Form.Item
    //         name={"saleOrderItemId"+index}
    //         // rules={[{ required: true, message:"'Reporting Cases' is required " }]}
    //         style={{ margin: 0 }}
    //         initialValue={rowData.saleOrderItemId}
    //       >
    //         <InputNumber readOnly
    //         value={Number(rowData.saleOrderItemId)}
    //          />
    //       </Form.Item></>
    //     )
    //   } 
    // },
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align:'left',
      //fixed: 'left',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Brand',
      key: 'brand',
      //fixed: 'left',
      dataIndex: 'brand',
      align:'left',
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
      align:'left',
    },
    
    {
      title: 'Varient',
      key: 'Varient',
      dataIndex: 'varient',
      align:'left',
    },
    {
      title: 'Grade',
      key: 'grade',
      align:'left',
      render: (text, rowData) => (<>{rowData.minGrade} - {rowData.maxGrade}</>)
    },
    {
      title: 'Uom',
      key: 'uom',
      align:'left',

      render: (text, rowData) => {return rowData.uom}
    },
    {
      title: 'Pack Style',
      key: 'packStyle',
      align:'left',

      render: (text, rowData) => {
        return <span>{`${rowData.pouches} pouches *  ${Math.round(rowData.pouchWeight)} ${rowData.uom}`}</span>
      }
    },
    {
      title: 'Glazing',
      key: "glazing",
      align:'left',

      render: (text, rowData) => (<>{rowData.minGlaze + "-" + rowData.maxGlaze}</>)
    },
    {
      title: 'Cases Required',
      key: 'cases',
      dataIndex: 'cases',
      align:'left',
    },
    {
      title: 'Reported Till Now',
      key: 'reportedCases',
      align:'left',
      render: (text, rowData) => (<>{rowData.reportedCases==undefined?0:rowData.reportedCases}</>)
    },
    {
      title: 'Reporting Cases',
      key:'reportingCases',
      align:'left',
      render: (text, rowData,index) =>{return (
        <>
        <Form.Item
            name={"reportingCases"+index}
            rules={[
              {
                required: true, message: 'Missing Reporting Cases',
              },
            ]}
            initialValue={0}
            style={{ margin: 0 }}
          >
             {rowData.reportedCases === rowData.cases ? "-":
            <InputNumber 
             key={rowData.reportingCases} value={0}
             onChange={(e)=>setSaleOrderItem(e,rowData.saleOrderItemId)} 
             min={0}
             />
      }
          </Form.Item></>
        )
      } 
    },
    {
      title: 'Loose Pouches',
      key:'loosePouches',
      align:'left',
      render: (text, rowData,index) =>{return (
        <>
        <Form.Item
            name={"loosePouches"+index}
            rules={[
              {
                required: true, message: 'Missing loose Pouches',
              },
            ]}
            initialValue={0}
            style={{ margin: 0 }}
          >
             {rowData.reportedCases === rowData.cases ? "-":
            <InputNumber 
             key={rowData.loosePouches} value={0}
             min={0}
             />
            }
          </Form.Item></>
        )
      } 
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData, index) => (
        <span>
          {rowData.reportedCases === rowData.cases ? <Tag style={{backgroundColor:'#c5f8f6',color: "black", fontWeight: 'bold'}}>Item Reported Successfully</Tag>:
            <Button  style={{ backgroundColor: '#8fec79', color: "black", fontWeight: 'bold' }} onClick={(e) => reportStock(form.getFieldsValue(),index)}>Report</Button>
          }
        </span>
      )
    }
  ]
  return [...columns];

}
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getSaleOrderData();
    getZones();
    setTimeout(() => setLoading(false), 1000)
  }, [])
  return (
    <>
     {loading === false ? (
       <>
    <br></br>
    <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Reporting Cases</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} >
        <Form form={form} onFinish={saveData} layout="vertical">
          <Form.Item style={{ display: "none" }} name="saleOrderItemId" >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="plantId" >
            <Input defaultValue={Number(localStorage.getItem('unit_id'))}/>
          </Form.Item>
          <Row gutter={24}>
            <Col span={4}>
             <Form.Item name="reportingDate" label="Reporting Date" rules={[{ required: true }]} initialValue={moment()}>
                  <DatePicker showTime={{format: "HH:mm"}} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                  showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="saleOrderId"
                label="Sale Order"
                rules={[
                  {
                    required: true, message: 'Missing Sale Order',
                  },
                ]}
              >
                <Select
                  placeholder="Select Sale Order"
                  onChange={handleSaleOrder}
                  allowClear
                >
                  {saleOrderData.map(saleOrderDropDown => {
                    return <Option value={saleOrderDropDown.saleOrderId}>{saleOrderDropDown.saleOrderNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="rackPostion" label="Rack Position"
              rules={[
                {
                  required: true, message: 'Missing Rack Position',
                },
              ]}>
                <Input value={"1"}/>
              </Form.Item>
            </Col>
            
            <Col span={5}>
              <Form.Item name="palletType" label="Pallet Type"
              rules={[
                {
                  required: true, message: 'Missing Pallet Type',
                },
              ]}>
              <Select
                  placeholder="Select Pallet Type"
                  allowClear
                >
                    <Option value="null">Select Pallet Type</Option>
                    <Option value={PalletType.existing}>{PalletType.existing}</Option>
                    <Option value={PalletType.new}>{PalletType.new}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="palletId" label="Pallet"
              rules={[
                {
                  required: true, message: 'Missing Pallet',
                },
              ]}>
                <Input value={"1"}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="lotCode" label="Lot Code"
              rules={[
                {
                  required: true, message: 'Missing Lot Code',
                },
              ]}>
                <Input value={"LOT-001"}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="coldStorageId" label="Cold Storage"
              rules={[
                {
                  required: true, message: 'Missing Cold Storage',
                },
              ]}>
              <Select
                  placeholder="Select Cold Storage"
                  allowClear
                >
                  {zoneDropDown.map(dropdown => {
                    return <Option value={dropdown.zoneId}>{dropdown.zoneName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="packingCompleted" label="Packing Status"
              rules={[
                {
                  required: true, message: 'Missing Packing Status',
                },
              ]}>
              <Select
                  placeholder="Select packing Status"
                  allowClear
                >
                    <Option value="null">Select Packing Status</Option>
                    <Option value={GlobalStatus.NO}>{GlobalStatus.NO}</Option>
                    <Option value={GlobalStatus.YES}>{GlobalStatus.YES}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="shifts"
                label="Shifts"
                rules={[
                  {
                    required: true, message: 'Missing Shifts',
                  },
                ]}
              >
                <Select defaultValue={shifts[0].value}
                  placeholder="Select Shifts"
                  allowClear
                >
                  {shifts.map(shift => {
                    return <Option value={shift.value}>{shift.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            
          </Row>
      {
        showItemsTable || refreshTable  ?
          <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Sale Order Items</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} 
          >
        <Table
          columns={tableColumns()}
           dataSource= {saleOrderItemsData.itemData}
           rowKey={record => record.saleOrderItemId}
           pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x:true }}
          size="small"
          bordered
         />
        </Card>
        :""
      }
          
        </Form>
        </Card>
    </>
      ): (
        <div className="spins">
        <Spin size="large"/>
        </div>
      )}
    </>
  );
}

export default ReportingForm;
