import { JobCompletedEnum, OperationTypeEnum, PlantsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";

export class SubPlantHistoryDto{
    productionLogId: number;
    operationType: OperationTypeEnum;
    lotNumber: string;
    subLotNumber: string;
    jobNumber: string
    saleOrderId: number;
    saleOrderItemId: number;
    plantId: number;
    subPlantId: SubPlantCodesEnum;
    workStationType: WorkStationTypeEnum;
    jobStatus: JobCompletedEnum;
    assignFreezing: JobCompletedEnum;
    workStation: number;
    inputGrade: number;
    plannedGrade: number;
    actualGrade: number;
    inputQuantity: number;
    plannedOutputQuantity: number;
    actualOutputQuantity: number;
    inputBoxes: number;
    outputBoxes: number;
    assignedUser: string
    assignedTime: string
    shift: string;
    isReceived: JobCompletedEnum;
    operation:OperationTypeEnum;
    soNumber:string;
    plantName:string;
    subPlantName:string;
    wipQuantity:number;
    poNumber?:string
    constructor(productionLogId: number,
        operationType: OperationTypeEnum,
        lotNumber: string,
        subLotNumber: string,
        jobNumber: string,
        saleOrderId: number,
        saleOrderItemId: number,
        plantId: number,
        subPlantId: SubPlantCodesEnum,
        workStationType: WorkStationTypeEnum,
        jobStatus: JobCompletedEnum,
        assignFreezing: JobCompletedEnum,
        workStation: number,
        inputGrade: number,
        plannedGrade: number,
        actualGrade: number,
        inputQuantity: number,
        plannedOutputQuantity: number,
        actualOutputQuantity: number,
        inputBoxes: number,
        outputBoxes: number,
        assignedUser: string,
        assignedTime: string,
        shift: string,
        isReceived: JobCompletedEnum,operation:OperationTypeEnum,
        soNumber:string,plantName:string,subPlantName:string,wipQuantity:number,poNumber?:string
        ){
            this.productionLogId= productionLogId;
            this.operationType= operationType;
            this.lotNumber= lotNumber;
            this.subLotNumber= subLotNumber;
            this.jobNumber= jobNumber
            this.saleOrderId= saleOrderId;
            this.saleOrderItemId= saleOrderItemId;
            this.plantId= plantId;
            this.subPlantId= subPlantId;
            this.workStationType= workStationType;
            this.jobStatus= jobStatus;
            this.assignFreezing= assignFreezing;
            this.workStation= workStation;
            this.inputGrade= inputGrade;
            this.plannedGrade=plannedGrade;
            this.actualGrade=actualGrade;
            this.inputQuantity= inputQuantity;
            this.plannedOutputQuantity= plannedOutputQuantity;
            this.actualOutputQuantity= actualOutputQuantity;
            this.inputBoxes= inputBoxes;
            this.outputBoxes= outputBoxes;
            this.assignedUser= assignedUser;
            this.assignedTime= assignedTime;
            this.shift= shift;
            this.isReceived= isReceived;
            this.operation= operation
            this.soNumber= soNumber
            this.plantName= plantName
            this.subPlantName= subPlantName
            this.wipQuantity= wipQuantity
            this.poNumber=poNumber
        }
}