export class DestinationRequest{
    destinationId : number;
    /**
     * 
     * @param destinationId 
     */
    constructor(destinationId : number)
    {
        this.destinationId = destinationId;
        
    }
}
