export class StoreConsumptionSosDto {
    saleOrderId: number;
    saleOrderNo: string;
    poNumber?: string;

    constructor(saleOrderId: number, saleOrderNo: string,poNumber?:string){
        this.saleOrderId = saleOrderId; 
        this.saleOrderNo = saleOrderNo; 
        this.poNumber = poNumber; 
    }
}