export class RecruitmentDto {
    recruitmentId: number;
    recruitmentMode: string;
    createdUser: string;
    isActive: boolean;

    /**
     * 
     * @param recruitmentId 
     * @param recruitmentMode 
     * @param createdUser 
     * @param isActive 
     */

    constructor(recruitmentId: number, recruitmentMode: string, createdUser: string, isActive: boolean) {
        this.recruitmentId = recruitmentId;
        this.recruitmentMode = recruitmentMode;
        this.createdUser = createdUser;
        this.isActive = isActive;
    }
}