import { CommonResponse } from "@gtpl/shared-models/masters";
import connection from "./connection";
import { AssetRequestDto } from "@gtpl/shared-models/asset-management";
import axios from "axios";
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";

export class AssetRequestService{
    URL = connection.DEPLOY_URL + '/asset-request';

    async createAssetRequest(req: AssetRequestDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetRequest',req)
        .then(res => {
            return res.data;
        })

    }

    async getRequestedAssetsData(req?: AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRequestedAssetsData',req)
        .then(res => {
            return res.data;
        })

    }

    async getRequestCompletedAssets(req?: AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRequestCompletedAssets',req)
        .then(res => {
            return res.data;
        })

    }

    async getAssetCodeDropDownforAssetRequest(req?: AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetCodeDropDownforAssetRequest',req)
        .then(res => {
            return res.data;
        })

    }

    async getAssetCategoryDropDown(req?: AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetCategoryDropDown',req)
        .then(res => {
            return res.data;
        })

    }
}