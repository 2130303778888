import { POTypeEnum } from "@gtpl/shared-models/common-models";
import { PoItemsDetails } from "./po-items-details";

export class UpdatePoDetails {
    vendorId: number;
    saleOrderId: number;
    purchaseOrderDate: Date
    transportation: number;
    expectedDeliveryDate: Date;
    deliveryAddress: number;
    paymentTerms: number;
    remarks: string;
    note: string;
    exchangeRate: string;
    createdUser: string;
    updatedUser: string;
    poItems: PoItemsDetails[];
    poType: POTypeEnum;
    purchaseOrderId?: number;
    billingAddress?: number;
    shippingTerms?: string;
    deliveryTerms?: string;
    plantAddress?: number;
    saleOrderNumber?:string;
    /**
     * 
     * @param vendorId 
     * @param saleOrderId 
     * @param purchaseOrderDate 
     * @param transportation 
     * @param expectedDeliveryDate 
     * @param deliveryAddress 
     * @param paymentTerms 
     * @param remarks 
     * @param note 
     * @param exchangeRate 
     * @param createdUser 
     * @param updatedUser 
     * @param poItems 
     * @param purchaseOrderId 
     */
    constructor(vendorId: number, saleOrderId: number, purchaseOrderDate: Date,
        transportation: number, expectedDeliveryDate: Date, deliveryAddress: number, paymentTerms: number, remarks: string, note: string, exchangeRate: string, createdUser: string, updatedUser: string, poItems: PoItemsDetails[], poType: POTypeEnum, purchaseOrderId?: number, billingAddress?: number, shippingTerms?: string, deliveryTerms?: string, plantAddress?: number,saleOrderNumber?:string) {
        this.vendorId = vendorId;
        this.saleOrderId = saleOrderId;
        this.purchaseOrderDate = purchaseOrderDate
        this.transportation = transportation;
        this.expectedDeliveryDate = expectedDeliveryDate;
        this.deliveryAddress = deliveryAddress;
        this.paymentTerms = paymentTerms;
        this.remarks = remarks;
        this.note = note;
        this.exchangeRate = exchangeRate;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.poItems = poItems;
        this.poType = poType;
        this.purchaseOrderId = purchaseOrderId;
        this.billingAddress = billingAddress;
        this.shippingTerms = shippingTerms;
        this.deliveryTerms = deliveryTerms;
        this.plantAddress = plantAddress;
        this.saleOrderNumber = saleOrderNumber;
    }
}