import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Tabs, Modal, InputNumber, Form, Select, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { SearchOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Table } from "ant-table-extensions";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { StockService } from '@gtpl/shared-services/procurement';
import { AvailStockDto, StockDto, StockInputRequest } from '@gtpl/shared-models/procurement-management';
import './stock-view.css';
import { SaleOrderItemInfo, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { SkuService, MasterBrandService } from '@gtpl/shared-services/masters';
import { SkuDto, MasterBrandsDropDownDto, MasterBrandDto } from '@gtpl/shared-models/masters';
import { BrandsRequest } from '@gtpl/shared-models/masters';
import { PlantRoleRequest } from '@gtpl/shared-models/common-models';
const { Option } = Select;
const { Text } = Typography;


/* eslint-disable-next-line */
export interface StockViewProps { }

export function StockView(props: StockViewProps) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [stockQty, setStockQty] = useState(null);
  const [assignedSoPoNumber, setAssignedSoPoNumber] = useState<string>('');
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [formInput] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const { formatMessage: fm } = useIntl();
  const { TabPane } = Tabs;
  const [stockData, setStockData] = useState<StockDto[]>([]);
  const [filterStockData, setFilterStockData] = useState<StockDto[]>([]);
  const [assignedRowData, setRowData] = useState<StockDto>();
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderItemInfo[]>([]);
  const [brandsData, setBrandsData] = useState<string[]>([]);
  const [variantsData, setVariantsData] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [sizeData, setSizeData] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const service = new StockService;
  const soService = new SaleOrderService;
  const skuService = new SkuService();
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [brandArrayData, setBrandArrayData] = useState<MasterBrandsDropDownDto[]>([]);
  const [brandData, setBrandData] = useState<MasterBrandDto>();
  const history = useHistory();
  const [editedQty, setEditedQty] = useState<number>()
  const [packMaterialRequiredFields, setPackMaterialRequiredFields] = useState<boolean>(false);
  const [transferredSaleOrders, setTransferredSaleOrders] = useState<any>();
  const brandsService = new MasterBrandService()

  useEffect(() => {

    getFiltersDropdownData(filterStockData);
  }, [filterStockData])

  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkucode(res.data);
      }
      else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setSkucode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });
  }


  const getTransferredSosData = () => {
    service.getTransferredSosDataForStockGrid().then((res) => {
      if (res.status) {
        setTransferredSaleOrders(res.data)
      } else {
        setTransferredSaleOrders([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
      setTransferredSaleOrders([])
    })
  }


  const getFiltersDropdownData = (stockData) => {
    let brandData: any[] = [];
    let variantData: any[] = [];
    let itemSubCategoriesData: any[] = [];
    let sizesData: any[] = [];
    stockData.forEach(element => {
      if (element.variantName && (!brandData.includes(element.brandName))) {
        brandData.push(element.brandName)
      }
      if (element.variantName && (!variantData.includes(element.variantName))) {
        variantData.push(element.variantName)
      }
      if (element.itemSubCategoryName && (!itemSubCategoriesData.includes(element.itemSubCategoryName))) {
        itemSubCategoriesData.push(element.itemSubCategoryName)
      }
      if (element.sizeName && (!sizesData.includes(element.sizeName))) {
        sizesData.push(element.sizeName)
      }

    });
    setBrandsData(brandData)
    setVariantsData(variantData)
    setSubCategories(itemSubCategoriesData)
    setSizeData(sizesData)
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  // To get SO numbers
  const getSoNumbers = (rowData) => {
    // if(rowData.brandId >= 0){
    soService.getSoNumbersForDummyPackAssignment(new BrandsRequest(rowData.brandId, true)).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);

      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
    // }else{

    //   soService.getSoNumbers().then((res) => {
    //     if (res.status) {
    //       // setSaleOrderData(res.data);
    //     } else {
    //       setSaleOrderData([]);
    //     }
    //   }).catch((err) => {
    //     AlertMessages.getErrorMessage(err.message);
    //     setSaleOrderData([]);
    //   });
    // }
  }

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }


  useEffect(() => {
    getAllStock();
    getBrandData();
    getTransferredSosData();

  }, [])




  const getBrandData = () => {
    brandsService.getAllMasterBrandsDropDown().then(res => {
      console.log(res);
      if (res.status) {
        setBrandArrayData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllStock = () => {
    const role = (JSON.parse(localStorage.getItem('role')))
    const unit = (JSON.parse(localStorage.getItem('unit_id')))
    service.getAllStockData(new PlantRoleRequest(role, unit)).then(res => {
      if (res.data) {
        setStockData(res.data);
        setFilterStockData(res.data)
      } else {
        if (res.intlCode) {
          setStockData([]);
          setFilterStockData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setStockData([]);
      setFilterStockData([]);
    })
  }

  const setData = (value) => {
    console.log(value, 'value')
    setEditedQty(value)
    console.log(editedQty)
  }

  const editStock = (rowData) => {
    rowData.physicalQuantity = editedQty
    if (editedQty) {

      service.stockEdit(rowData).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Stock Edited Sucessfully')
          getAllStock();
        } else {
          formInput.resetFields()
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    } else {
      AlertMessages.getErrorMessage('Please Change Input and try again')
    }

  }

  const generalItemsColumns:ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '60px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      width: '100px',
      // ...getColumnSearchProps('unitName')
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      // width:200,
      ...getColumnSearchProps('physicalQuantity'),
      render: (value, rowData) =>
        <>
          <span style={{ display: 'inline-block', width: '50px' }}>
            {value}
          </span>
          {
            localStorage.getItem('username') == '"ramakrishna"' ?
              <>
                <Divider type='vertical' />
                <span style={{ display: 'inline-block', height: '1px' }}>
                  <InputNumber defaultValue={null} size='small' placeholder='Update' onChange={e => setData(e)} />
                </span>
                <Popconfirm onConfirm={e => { editStock(rowData) }} title="Are you sure to edit"  >
                  <span style={{ marginLeft: '4px', marginTop: '16px' }} >
                    <CheckSquareOutlined size={80} />
                  </span>
                </Popconfirm>
              </> : ''
          }
        </>
    },
    
  ]

  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '60px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      width: '100px',
      // ...getColumnSearchProps('unitName')
    },
    {
      title:'Location',
      dataIndex:'rackPosition',
      fixed: 'left',
      width: '100px',
      ...getColumnSearchProps('rackPosition'),
      render:(text,record)=>{
        return record.rackPosition ? record.rackPosition : '-';
      }
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNumber',
    //   ...getColumnSearchProps('saleOrderNumber'),
    //   render: (text, record) => {
    //     return record.soStatus == "CANCELLED" ? <span style={{ color: 'red' }}>{record.saleOrderNumber + ' (Cancelled)'}</span> : <span>{record.saleOrderNumber}</span>
    //   }
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    // {
    //   title: 'SO Number ID',
    //   dataIndex: 'saleOrderId',
    //   ...getColumnSearchProps('saleOrderId')
    // },
    // {
    //   title: 'Size',
    //   dataIndex: 'sizeName',
    //   ...getColumnSearchProps('sizeName')
    // },
    {
      title: 'Item',
      dataIndex: 'itemName',
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('brandName')
    },
    {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      // width:200,
      ...getColumnSearchProps('physicalQuantity'),
      render: (value, rowData) =>
        <>
          <span style={{ display: 'inline-block', width: '50px' }}>
            {value}
          </span>
          {
            localStorage.getItem('username') == '"ramakrishna"' ?
              <>
                <Divider type='vertical' />
                <span style={{ display: 'inline-block', height: '1px' }}>
                  <InputNumber defaultValue={null} size='small' placeholder='Update' onChange={e => setData(e)} />
                </span>
                <Popconfirm onConfirm={e => { editStock(rowData) }} title="Are you sure to edit"  >
                  <span style={{ marginLeft: '4px', marginTop: '16px' }} >
                    <CheckSquareOutlined size={80} />
                  </span>
                </Popconfirm>
              </> : ''
          }
        </>
    },
    {
      title:'Prepared Quantity',
      dataIndex:'preparedQuantity',
      render:(value,record)=>{
        return Math.round(record.preparedQuantity) ? Math.round(record.preparedQuantity) : '-'
      }
    },


    // {
    //   title: 'Reserved Quantity',
    //   dataIndex: 'reservedQuantity',
    //   ...getColumnSearchProps('reservedQuantity')
    // },
    // {
    //   title: 'Item Category ID',
    //   dataIndex: 'itemCategoryId',
    //   ...getColumnSearchProps('itemCategoryId')
    // },
    // {
    //   title: 'Item ID',
    //   dataIndex: 'itemId',
    //   ...getColumnSearchProps('itemId')
    // },
    // {
    //   title: 'Item Category',
    //   dataIndex: 'itemCategory',
    //   ...getColumnSearchProps('itemCategory')
    // },
    // {
    //   title: 'Size',
    //   dataIndex: 'sizeName',
    //   ...getColumnSearchProps('sizeName')
    // },
    // {
    //   title: 'Opening Balance',
    //   dataIndex: 'openingBalance',
    //   ...getColumnSearchProps('openingBalance')
    // },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unitPrice',
    //   ...getColumnSearchProps('unitPrice')
    // },
    // {
    //   title: 'Minimum Stock',
    //   dataIndex: 'minimumStock',
    //   ...getColumnSearchProps('minimumStock')
    // },

  ]

  const assignForm = (rowData) => {
    setIsModalVisible(true)
    setStockQty(rowData.physicalQuantity)
    setRowData(rowData);
    getSoNumbers(rowData);
  }
  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      // width: '60px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      width: '100px',
      // ...getColumnSearchProps('unitName')
    },
    {
      title:'Location',
      dataIndex:'rackPosition',
      fixed: 'left',
      width: '100px',
      ...getColumnSearchProps('rackPosition'),
      render:(text,record)=>{
        return record.rackPosition ? record.rackPosition : '-';
      }
    },
    // {
    //   title: 'SO Number ID',
    //   dataIndex: 'saleOrderId',
    //   ...getColumnSearchProps('saleOrderId')
    // },
    // {
    //   title: 'Size ID',
    //   dataIndex: 'sizeId',
    //   ...getColumnSearchProps('sizeId')
    // },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNumber',
    //   ...getColumnSearchProps('saleOrderNumber')
    // },

    {
      title: 'Item Sub category',
      dataIndex: 'itemSubCategoryName',
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName')
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },

    // {
    //   title: 'Packing Category',
    //   dataIndex: 'itemName',
    //   render:(text,data,index)=>{
    //     console.log(data)
    //     let packCategory = 'N/A';
    //     if(text.includes('/')){
    //       packCategory = text.split('/')[0]
    //     }
    //     console.log(text.split('/'))
    //     return packCategory;
    //   }
    //   // ...getColumnSearchProps('itemName')
    // },

    {
      title: 'Size',
      dataIndex: 'sizeName',
      ...getColumnSearchProps('sizeName')
    },

    {
      title: 'Brand',
      dataIndex: 'brandName',
      sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
      sortDirections: ['descend', 'ascend'],
      render: (text, data, index) => {
        let brand = 'N/A';
        if (data.brandId > 0) {
          brand = text
        }
        // console.log(data)
        // if(text){
        // }
        // console.log(text.split('/'))
        return brand;
      }
      // render:(text,data,index)=>{
      //   console.log(data)
      //   let brand = 'N/A';
      //   if(text.includes('/')){
      //     brand = text.split('/')[1]
      //   }
      //   console.log(text.split('/'))
      //   return brand;
      // }
      // ...getColumnSearchProps('itemName')
    },
    {
      title: 'Grade',
      dataIndex: 'minGrade',
      render: (text, data, index) => {
        if (data.maxGrade > 0 && text > 0) {
          return `${text} - ${data.maxGrade} `;
        } else {
          return 'N/A';
        }
        // console.log(data)
        // let grade = 'N/A';
        // if(text.includes('/')){
        //   grade = text.split('/')[2]
        // }
        // console.log(text.split('/'))
      }
      // ...getColumnSearchProps('itemName')
    },
    // {
    //   title: 'Variant',
    //   dataIndex: 'variantName',
    //   render: (text, data, index) => {
    //     let variant;
    //     if (text) {
    //       variant = text
    //     } else {
    //       variant = 'N/A'
    //     }
    //     //   console.log(data)
    //     //   let variant = 'N/A';
    //     //   if(text.includes('/')){
    //     //     variant = text.split('/')[3]
    //     //   }
    //     //   console.log(text.split('/'))
    //     return variant;
    //   }
    //   // ...getColumnSearchProps('itemName')
    // },
    // {
    //   title: 'Pack Style',
    //   dataIndex: 'itemName',
    //   render: (text, data, index) => {
    //     if (data.pouches > 0 && data.pouchWeight > 0) {
    //       return `${data.pouches} * ${data.pouchWeight} `;
    //     } else {
    //       return 'N/A';
    //     }
    //     // render:(text,data,index)=>{
    //     //   console.log(data)
    //     //   let packStyle = 'N/A';
    //     //   if(text.includes('/')){
    //     //     packStyle = text.split('/')[4]
    //     //   }
    //     //   console.log(text.split('/'))
    //     //   return packStyle;
    //   }
    //   // ...getColumnSearchProps('itemName')
    // },

    {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      // ...getColumnSearchProps('physicalQuantity')
      // width: '100',
      render: (value, rowData) =>
        <>
          <span style={{ display: 'inline-block', width: '50px' }}>
            {value}
          </span>
          {
            localStorage.getItem('username') == '"ramakrishna"' ?
              <>
                <Divider type='vertical' />
                <span style={{ display: 'inline-block', height: '1px' }}>

                  <Form form={formInput}>
                    <Form.Item >
                      <InputNumber size='small' placeholder='Update' onChange={e => setData(e)} />
                    </Form.Item>
                  </Form>
                </span>
                <Popconfirm onConfirm={e => { editStock(rowData) }} title="Are you sure to edit"  >
                  <span style={{ marginLeft: '4px', marginTop: '16px' }} >
                    <CheckSquareOutlined size={80} />
                  </span>
                </Popconfirm>
              </> : ''
          }


        </>
    },
    {
      title:'Prepared Quantity',
      dataIndex:'preparedQuantity',
      render:(value,record)=>{
        return Math.round(record.preparedQuantity) ? Math.round(record.preparedQuantity) : '-'
      }
    }

    // {
    //   title: 'Item Category ID',
    //   dataIndex: 'itemCategoryId',
    //   ...getColumnSearchProps('itemCategoryId')
    // },
    // {
    //   title: 'Item ID',
    //   dataIndex: 'itemId',
    //   ...getColumnSearchProps('itemId')
    // },
    // {
    //   title: 'Item Category',
    //   dataIndex: 'itemCategory',
    //   ...getColumnSearchProps('itemCategory')
    // },

    // {
    //   title: 'Opening Balance',
    //   dataIndex: 'openingBalance',
    //   ...getColumnSearchProps('openingBalance')
    // },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unitPrice',
    //   ...getColumnSearchProps('unitPrice')
    // },
    // {
    //   title: 'Minimum Stock',
    //   dataIndex: 'minimumStock',
    //   ...getColumnSearchProps('minimumStock')
    // },
    // {
    //   title: 'Location',
    //   dataIndex: 'location',
    //   ...getColumnSearchProps('location')
    // },
  ]
  const assignColumns = [...columnsSkelton2,
  {
    title: 'Assign',
    // dataIndex: 'reservedQuantity',
    render: (grnCompleted, rowData) => {

      return (
        rowData.brandId != 75 ? <></> :
          <>
            <Button type='primary' onClick={() => assignForm(rowData)}>
              Assign
            </Button>
          </>
      )
    }
  },
  ]
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setAssignedSoPoNumber(null)
  };

  useEffect(() => {
    getBrandInfoById();
  })


  const getBrandInfoById = () => {
    const brandId = form.getFieldValue('brandId');
    if (brandId) {
      const req = new BrandsRequest(brandId);
      brandsService.getBrandInfoById(req).then((res) => {
        if (res.status) {
          setBrandData(res.data);


        } else {
          setBrandData(undefined);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setBrandData(undefined);
      });
    }
  }

  const updateStock = (assignedRowData, val) => {
    if (form.getFieldValue('quantity') > val) {
      AlertMessages.getErrorMessage('Assigned quantity should less than physical Qty');
    } else {
      const assignStockReq = new StockInputRequest(form.getFieldValue('soId'), assignedRowData.itemCategoryId, assignedRowData.sizeId, assignedRowData.itemId, form.getFieldValue('quantity'), form.getFieldValue('quantity'), form.getFieldValue('quantity'), undefined, undefined, assignedRowData.itemSubCategoryId);
      service.AssignDummyPackingInventoryStock(assignStockReq).then((res) => {
        if (res) {
          AlertMessages.getSuccessMessage('successfully assigned QTY for Saleorder');
          setIsModalVisible(false)
          history.push("/stock-grid");

          // getSaleOrderItems(record.saleOrderId);
        } else {
          AlertMessages.getErrorMessage('Failed To Assign Quantity');
        }
      });
    }
    // const updateStock = () => {
    //   form.validateFields().then(poRes => {
    //     alert()
    //     // poDetailsForm.validateFields().then(poRes => {

    //   })
    // }
  }

  const searchData = () => {
    const selectedbrand = filterForm.getFieldValue('brandId');
    const selectedVariant = filterForm.getFieldValue('variant');
    const selectedSubCategory = filterForm.getFieldValue('itemSubcategory');
    const selectedSize = filterForm.getFieldValue('size');
    let selectedData = filterStockData;
    if (selectedbrand) {
      selectedData = filterStockData.filter(item => item.brandName == selectedbrand)
      // setStockData(selectedData);

    }
    if (selectedVariant) {
      selectedData = selectedData.filter(item => item.variantName == selectedVariant)
    }
    if (selectedSubCategory) {
      selectedData = selectedData.filter(item => item.itemSubCategoryName == selectedSubCategory)
    }
    if (selectedSize) {
      selectedData = selectedData.filter(item => item.sizeName == selectedSize)
    }
    setStockData(selectedData);
  }

  const onSearch = () => {

  }

  const getSoInfo = (val, row) => {
    setAssignedSoPoNumber(row.poNumber)

    // soService.getSaleOrderFewDetailsForSoId(new saleOrder(val,JSON.parse(localStorage.getItem('unit_id')))).then((res) => {
    //     if(res){
    //       console.log(res);
    //       setAssignedSoPoNumber(res.poNumber)
    //     }else{
    //       setAssignedSoPoNumber('')
    //     }
    //   });
  }
  const onReset = () => {
    filterForm.resetFields();
    getAllStock()

  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Stock</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      >
        <br></br>
        <div className="card-container">
          <Tabs type={'card'} tabPosition={'top'}>
            <TabPane
              // tab="Open Orders"
              key="1"
              tab={<span style={{ color: "#52c41a" }}>{'SO Inventory: ' + (stockData.filter((res) => res.saleOrderId != 0 && res.itemCategoryId == 6).length)}</span>}
            >
              {/* <Form>
               <Row gutter={24}>
                 <Col span={6}>

                 </Col>
               </Row>
             </Form> */}
              <Table
                //rowKey={record => record.stockId}
                columns={columnsSkelton1}
                dataSource={stockData.filter(res => (res.saleOrderId != 0 && res.itemCategoryId == 6))}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />
              {/* } */}
            </TabPane>
            <TabPane
              // tab="Open Orders"
              key="2"
              tab={<span style={{ color: "#1890ff" }}>{'Bulk Inventory: ' + (stockData.filter(res => (res.saleOrderId == 0 && res.itemCategoryId == 6)).length)}</span>}
            >
              {/* <Form layout='vertical' form={filterForm}>
                <Row gutter={24}>
                  <Col span={5}>
                    <Form.Item label="Item Sub category"
                      name={'itemSubcategory'}
                    >
                      <Select
                        placeholder="Select Sub Category"
                        allowClear
                        showSearch
                      // disabled={updateDisableField}

                      >
                        {subCategories.map(itemsubcategory => {
                          return <Option label={'itemSubCategory'} key={itemsubcategory} value={itemsubcategory}>{itemsubcategory}</Option>
                        })}
                      </Select>
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                  >
                    <Form.Item label="Brand"
                      name={'brandId'}
                      rules={[{ required: packMaterialRequiredFields, message: 'Missing Brand name' }]}
                    >
                      <Select
                        onSelect={getBrandInfoById}
                        placeholder="Select Brand"
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option key={0} value={null}>Select Brand</Option>
                        {brandArrayData.map(branddropData => {
                          return <Option label={'brandId'} key={branddropData.masterBrandId} value={branddropData.masterBrandName}>{branddropData.masterBrandName}</Option>
                        })}
                        |
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item label="Variant Code"
                      name={'variant'}
                      rules={[{ required: false, message: 'Missing Brand name' }]}
                    >
                      <Select
                        placeholder="Select Variant Code"
                        allowClear
                        showSearch
                      // disabled={updateDisableField}

                      >
                        {variantsData.map(variantDropData => {
                          return <Option label={'varientCode'} key={variantDropData} value={variantDropData}>{variantDropData}</Option>
                        })}
                      
                      </Select>
                    </Form.Item>

                  </Col>
                  <Col span={5}>
                    <Form.Item label="Size"
                      name={'size'}
                      rules={[{ required: false, message: 'Missing Brand name' }]}
                    >
                      <Select
                        placeholder="Select Size"
                        allowClear
                        showSearch
                      // disabled={updateDisableField}

                      >
                        {sizeData.map(size => {
                          return <Option label={'sizeId'} key={size} value={size}>{size}</Option>
                        })}
                      </Select>
                    </Form.Item>

                  </Col>

                </Row>
                <Row gutter={24}>

                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} style={{ paddingTop: '33px' }}>
                    <Form.Item>
                      <Button type='primary' onClick={() => searchData()}>Get Details</Button>

                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} style={{ paddingTop: '33px' }}>
                    <Form.Item>
                      <Button htmlType="button" onClick={onReset}>
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>

                </Row>
              </Form> */}
              <Tabs type={'card'} tabPosition={'top'}>

                <TabPane
                  // tab="Open Orders"
                  key="3"
                  tab={<span style={{ color: "#1890ff" }}>{'Product Inventory: ' + (stockData.filter(res => (res.saleOrderId == 0 && res.brandId != 75 && res.itemCategoryId == 6)).length)}</span>}
                >
                  <Link to="/allsaleorders-view">  <h4>Click here for assign stock to salorder</h4></Link>
                  <Table
                    rowKey={record => record.stockId}
                    columns={columnsSkelton2}
                    dataSource={stockData.filter(res => (res.saleOrderId == 0 && res.brandId != 75 && res.itemCategoryId == 6))}
                    pagination={{
                      onChange(current) {
                        setPage(current);
                      }
                    }}
                    onChange={onChange}
                    // scroll={{ y:500 }}
                    size="small"
                    bordered
                     />

                </TabPane>
                <TabPane
                  // tab="Open Orders"
                  key="4"
                  tab={<span style={{ color: "#1890ff" }}>{'Dummy Inventory: ' + (stockData.filter(res => (res.saleOrderId == 0 && res.brandId == 75 && res.itemCategoryId == 6)).length)}</span>}
                >
                  <Table
                    rowKey={record => record.stockId}
                    columns={assignColumns}
                    dataSource={stockData.filter(res => (res.saleOrderId == 0 && res.brandId == 75 && res.itemCategoryId == 6))}
                    pagination={{
                      onChange(current) {
                        setPage(current);
                      }
                    }}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size="small"
                    bordered />

                </TabPane>
              </Tabs>

              {/* } */}

            </TabPane>
            <TabPane
              // tab="Open Orders"
              key="5"
              tab={<span style={{ color: "#52c41a" }}>{'Transferred SOs'}</span>}
            >
              <Table
                rowKey={record => record.stockId}
                columns={columnsSkelton1}
                dataSource={transferredSaleOrders}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />

            </TabPane>
            <TabPane
              // tab="Open Orders"
              key="6"
              tab={<span style={{ color: "#1890ff" }}>{'General Items Inventory: ' + (stockData.filter(res => (res.saleOrderId == 0 && res.itemCategoryId == 33)).length)}</span>}
            >
              <Table
                rowKey={record => record.stockId}
                columns={generalItemsColumns}
                dataSource={stockData.filter(res => (res.saleOrderId == 0 && res.itemCategoryId == 33))}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
                scroll={{ x: true }}
                size="small"
                bordered />

            </TabPane>
          </Tabs>
        </div>


      </Card>
      <Modal
        // key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <Card title={<span style={{ color: 'white' }}>Assign To Sale Order</span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <Form layout="vertical" form={form}>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label='SO Number'
                  name={'soId'}
                  rules={[{ required: true, message: 'Missing SO Number ' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select SO Number"
                    optionFilterProp="children"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    onSearch={onSearch}
                    onChange={getSoInfo}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                  >
                    {saleOrderData.map((saleOrder) => {
                      return <><Option poNumber={saleOrder.poNumber} key={saleOrder.soId} value={saleOrder.soId}>{saleOrder.saleOrderNo}-{saleOrder.variantCode}-{saleOrder.product}-{saleOrder.brand}-({saleOrder.minGrade}-{saleOrder.maxGrade})-({saleOrder.pouches}*{saleOrder.pouchWeight})</Option></>
                    })}
                  </Select>
                </Form.Item>
                <Typography.Text type="secondary" mark >
                  Customer  PO Number:<b>{assignedSoPoNumber}</b>
                </Typography.Text>
              </Col>
              {/* <Col span={5}>
            <Text mark>PO Number:{assignedSoPoNumber}</Text>
            </Col> */}
              <Col span={5}>
                <Form.Item label={`Quantity`}
                  name={'quantity'}
                  rules={[{ required: true, message: 'Missing Quantity ' }]}
                >
                  <InputNumber placeholder="Quantity" style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <br />
              <Col span={6}>
                <Form.Item >
                  <Button style={{ marginTop: '30px' }} type='primary' block onClick={() => updateStock(assignedRowData, stockQty)}>Assign</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </>
  );
}


export default StockView;
