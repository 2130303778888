import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ComplaintsDto } from './complaints.dto';


export class ComplaiintsResponseModel extends GlobalResponseObject{
    data?: ComplaintsDto;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: ComplaintsDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}