
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { RmPriceListDto } from "./rm-price-list.dto";

export class RmPriceListResponse extends GlobalResponseObject{

      data?: RmPriceListDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: RmPriceListDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }

}