export class AttendanceLogModel {
    logDate: string;
    employeeCode: string;
    deviceCode: string;
    downloadDate: string;
    verifyMode: string;
    direction: string;
    employeeName: string;

    /**
     * 
     * @param logDate 
     * @param employeeCode 
     * @param deviceCode 
     * @param downloadDate 
     * @param verifyMode 
     * @param direction 
     * @param employeeName 
     */
    constructor(logDate: string, employeeCode: string, deviceCode: string, downloadDate: string, verifyMode: string, direction: string, employeeName: string) {
        this.logDate = logDate;
        this.employeeCode = employeeCode;
        this.deviceCode = deviceCode;
        this.downloadDate = downloadDate;
        this.verifyMode = verifyMode;
        this.direction = direction;
        this.employeeName = employeeName;
    }
}