export class SubCatReqForCategoryId {
    itemCategoryId: number;

    /**
     * 
     * @param itemCategoryId 
     */
    constructor(itemCategoryId: number){
        this.itemCategoryId = itemCategoryId;
    }
}
