import { GlobalStatus } from '@gtpl/shared-models/common-models';
export class SoAllInspectionsDto {
    soInspectionId: number;
    loadInspectionStatus: string;
    sgsInspectionStatus: string;
    tuvInspectionStatus: string;
    saleOrderId: number;
    saleOrderNumber: string;
    shipmentDate: Date;
    initiationDate: Date;
    plannedDate: Date;
    actualDate: Date;
    expectedResultDate: Date;
    isSgsTuv: boolean;
    poNumber?:string;
    
    constructor(soInspectionId: number,
        loadInspectionStatus: string,
        sgsInspectionStatus: string,
        tuvInspectionStatus: string,
        saleOrderId: number,saleOrderNumber: string,shipmentDate: Date, initiationDate: Date, plannedDate: Date, actualDate: Date, expectedResultDate: Date, isSgsTuv: boolean,poNumber?:string){
      this.soInspectionId = soInspectionId;
      this.loadInspectionStatus = loadInspectionStatus;
      this.sgsInspectionStatus = sgsInspectionStatus;
      this.tuvInspectionStatus = tuvInspectionStatus;
      this.saleOrderId = saleOrderId;
      this.saleOrderNumber = saleOrderNumber;
      this.shipmentDate = shipmentDate;
      this.initiationDate = initiationDate;
      this.plannedDate = plannedDate;
      this.actualDate = actualDate;
      this.expectedResultDate = expectedResultDate;
      this.isSgsTuv = isSgsTuv;
      this.poNumber=poNumber;
    }
  }