import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class OperationInventoryRequest {
    operation: OperationTypeEnum;
    plantId: number;

    /**
     * 
     * @param operation 
     * @param plantId 
     */
    constructor(operation: OperationTypeEnum, plantId: number) {
        this.operation = operation;
        this.plantId   = plantId;
    }
}