import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { CreateUserDto, EmployeeDto, CreateUserDtoDefault } from '@gtpl/shared-models/gtpl';
import './user-form.css';
import { Form, Input, Button, Select, DatePicker, Typography, Card, Table } from 'antd';
import { EmployeeService } from '@gtpl/shared-services/attendance'
import ClearableLabeledInput from 'antd/lib/input/ClearableLabeledInput';
import { clear } from 'console';
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';
import { UnitcodeService } from "@gtpl/shared-services/masters";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 14,
  },
};


/* eslint-disable-next-line */
export interface UserFormProps {
  userData: CreateUserDto;
  saveUser: (userData: CreateUserDto) => void;
  updateUser: (userData: CreateUserDto) => void;
  // removeproject: (userData: ProjectMappingDto) => void;
  closeForm: () => void;
  viewOnly: boolean;
  isNewForm: boolean;
  employeeData: EmployeeDto[];
  employeeProjectsData: any;
}

export const UserForm = (props: UserFormProps) => {
  const [form] = Form.useForm();
  const employeeService = new EmployeeService();
  const getCopiedData = (): CreateUserDto => {
    return JSON.parse(JSON.stringify(props.userData));
  };
  const { Text } = Typography;
  const [employeesData, setemployeesData] = useState([]);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [userData, setUserData] = useState<CreateUserDto>(getCopiedData);

  const unitService = new UnitcodeService();
  const saveData = (values) => {
    console.log(values);
    if (values.Id === undefined || values.Id === 0) {
      props.saveUser(values);
    } else {
      values.isActive = true;
      props.updateUser(values);
    }
  };

  const removeproject = (values) => {
    console.log(values);
    // props.removeproject(values);
  };

  const getAllemployees = async () => {

    await employeeService.getAllEmployees().then(res => {
      console.log(res);
      if (res) {
        setemployeesData(res.data);
      } else {
        setemployeesData([]);
      }
    })
  }
  console.log(userData);
  // const getAllemployees = async () =>{
  //   await employeeService.getAllEmployees().then(res => {
  //     console.log(res);
  //     if(res){
  //       setemployeesData(res.data);
  //     }else{
  //       setemployeesData([]);
  //     }
  //   })
  // }

  const onReset = () => {
    form.resetFields();
  };
  React.useEffect(() => {

    getAllemployees();
    getPlants();
  }, []);

  const employee = () => {
    if (employeesData) {
      return employeesData.map(item => {
        return (
          <Option value={item.Id}>
            {item.empName}
          </Option>
        );
      })
    }
  }
  const getPlants = () => {
    unitService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    });
  }

  const validatePassword = (rule, value, callback) => {
    if (value && value !== "Secret") {
      callback("Error!");
    } else {
      callback();
    }
  };
  // React.useEffect(() => {
  //   form.setFieldsValue({
  //      createdUser:userData.createdUser, 
  //      username:userData.username,
  //     //  password:userData.password,
  //      email:userData.email,
  //      role:(userData.role)?userData.role:'L2',
  //      employeeId:userData.employeeId,
  //      Id:userData.Id
  //    });
  //   //  getAllemployees();
  //  }, []);

  //  const employee = () => {
  //   if(employeesData){
  //     return employeesData.map(item => {
  //       return (
  //         <Option value={item.Id}>
  //           {item.empName}
  //         </Option>
  //       );
  //     })
  //   }
  // }
  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={saveData}>
        <Form.Item name="Id" style={{ display: "none" }} initialValue={userData.id}>
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={userData.createdUser}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="username"
          label="User Name"
          rules={[
            {
              required: true,
              pattern: /^[a-zA-Z ]*$/,
              message: 'Name should be in alphabet only'
            },
            {
              max:30,
              message:'User Name should not exceed 30 characters'
            }
          ]}
          initialValue={userData.username}
        >
          <Input disabled={userData.id != 0} />
        </Form.Item>
        <Form.Item
          // initialValue={userData.password}
          name="password"
          label="Password"
          rules={[
            { 
              required: props.isNewForm,
            }
          ]}
          help={props.isNewForm ? '' : 'Note: Leave it blank, if you wish not to change password'}
        >
          <Input.Password />
        </Form.Item>
        {/* { props.isNewForm ? '' : <Text type="secondary" underline>Note: Leave it blank, if you wish not to change password</Text> } */}
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: 'Invalid Email'
            },
          ]}
          initialValue={userData.email}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={userData.employeeRole}
          name="employeeRole"
          label="Role"
          rules={[
            {
              required: true,
            },
          ]}
        ><Select
          showSearch
          placeholder="Select Employee Role"
        >
            <Option key={0} value={null}>Select Employee Role</Option>
            {
              Object.keys(EmployeeRolesEnum).map(function (key, index) {
                return <Option key={EmployeeRolesEnum[key]} value={EmployeeRolesEnum[key]}>{EmployeeRolesEnum[key]}</Option>
              })
            }
          </Select>

        </Form.Item>
        <Form.Item initialValue={userData.employeeId} name="employeeId" label="Employee" rules={[{ required: true, },]}>
          <Select className='employeeId' showSearch
            // style={{ width: 200 }}
            placeholder="Select Employee"
            optionFilterProp="children"

            // onChange={onChange}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          // disabled={userData.employeeId!=null}
          >
            <Option key={0} value={null}>Select Employee</Option>
            {props.employeeData.map(EmployeeDto => {
              return <Option key={EmployeeDto.employeeId} value={EmployeeDto.employeeId}
              >{EmployeeDto.employeeName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={(userData.unitId)?userData.unitId:''}
          label="Plant"
          name={"unitId"}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Select Plant!`,
            },
          ]}
        >
          <Select
            placeholder="Select To Plant"
            allowClear
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option key={'plant'} value={''}>Select Sub Plant</Option>
            {plantData.map(dropData => {
              return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option>
            })}
          </Select>
        </Form.Item>
        <br />
        <Form.Item {...tailLayout} className='formControls'>
          <Button type="primary" htmlType="submit"

          >
            Submit
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>

        </Form.Item>
      </Form>
      {/* <Table
          rowKey={record => record.Id}
          columns={props.employeeProjectsData.length>0?sampleTypeColumns:null}
          dataSource={props.employeeProjectsData}
          pagination={{ onChange(current) {setPage(current);} }}
          size="small"
          bordered
           >
        </Table> */}

    </>
  );


}

