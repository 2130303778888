export class AssetSubCatDropDownDto {
    assetsSubCatagoryId: number;
    assetsSubCatagoryName: string;

    /**
     * 
     * @param assetsSubCatagoryId 
     * @param assetsSubCatagoryName 
     */
    constructor(assetsSubCatagoryId: number, assetsSubCatagoryName: string) {
        this.assetsSubCatagoryId = assetsSubCatagoryId;
        this.assetsSubCatagoryName = assetsSubCatagoryName;

    }
}