import { peelDeveinChild } from "./lotCodePeelD-Child";
import { SubAttribute } from "./lotCodeSubAttribute";

export class SubLotChild{
    name: string;
    attribute:SubAttribute;
    children:peelDeveinChild;
    constructor(name:string, attribute:SubAttribute, children:peelDeveinChild) {
        this.name = name;
        this.attribute = attribute;
        this.children = children;
    }
}