import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LotCodeOpsData } from '.';

export class AllLotCodeOpsResponseModel extends GlobalResponseObject{
    data?: LotCodeOpsData[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data LotCodeOpsData
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: LotCodeOpsData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
