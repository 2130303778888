import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import { BrowserRouter, Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { createBrowserHistory } from 'history';
import { flattenMessages } from '@gtpl/shared-utils/class-validators';
// import { enPmsLabels } from '@gtpl/shared-resources';

export const enPmsLabels = {

}

const history = createBrowserHistory()
export const messages = {
  en: enPmsLabels
};
export const language = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <React.StrictMode>
  <IntlProvider locale={language} messages={flattenMessages(messages[language])}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
