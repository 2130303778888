export class AvailStockDto {
    stockId:number;
    saleOrderId:number;
    physicalQuantity: number;
    constructor(physicalQuantity: number,
        stockId:number,
    saleOrderId:number
        ){
        this.stockId = stockId;
        this.saleOrderId = saleOrderId;
        this.physicalQuantity = physicalQuantity;
        
    }
}
