import axios from "axios";

import { MetalDetectionDto } from '@gtpl/shared-models/production-management';
import connection from './connection';
import { CommonResponse } from "@gtpl/shared-models/masters";
import { DateFilter } from '@gtpl/shared-models/common-models';
export class MetalDetectionService {
    URL = connection.DEPLOY_URL + '/metal-detection';

    async createMetalDetectionLog(formInfo: MetalDetectionDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createMetalDetectionLog', formInfo)
            .then(res => {
                return res.data
            })
    }

    async getMetalDetectionLog(date:DateFilter): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMetalDetectionLog', date)
            .then(res => {
                return res.data
            })
    }


}