import React, { useState } from 'react';
import { Card, Col, Row, Table, Tooltip, message } from 'antd';

import excel from '../../../assets-dashboard/src/lib/excel.png'
 import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface IVendorsAbstract {
    vendorAbstract: any[];
}

const VendorsAbstract = (props: IVendorsAbstract) => {
    const { vendorAbstract } = props;
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleExport: React.MouseEventHandler<HTMLElement> = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        if (vendorAbstract?.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .split("-")
            .join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { title: 'Vendor Name', key: 'vendorName', width: 20 },
            { title: 'No Of Assets', key: 'totalNoOfAssets', width: 20 },
            { title: 'Assets Value', key: 'assetsValue', width: 20 },
            { title: 'AMC Value/Due', key: 'amcValues', width: 25 },
            { title: 'Skipped/Total Services', key: 'serviceCounts', width: 25 },
            { title: 'No Of Damaged Assets', key: 'noOfDamagedAssets', width: 25 },
            { title: 'This Month Service/Completed', key: 'thisMonthServices', width: 25 }
        ];

        // Add header row with styling
        const headerRow = worksheet.addRow(columns.map(column => column.title));
        headerRow.font = { bold: true };
        headerRow.eachCell((cell, colNumber) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        vendorAbstract.forEach(item => {
            const rowData = [
                item.vendorName,
                item.totalNoOfAssets,
                parseFloat(item.assetsValue).toLocaleString('en-IN'),
                `${parseFloat(item.totalAmcValue || 0).toLocaleString('en-IN')}/₹${parseFloat(item.amcDue || 0).toLocaleString('en-IN')}`,
                `${parseFloat(item.skippedServicesCount).toLocaleString('en-IN')}/${parseFloat(item.totalServicesCount).toLocaleString('en-IN')}`,
                parseFloat(item.noOfDamagedAssets).toLocaleString('en-IN'),
                `${parseFloat(item.thisMonthServicesCount).toLocaleString('en-IN')}/${parseFloat(item.thisMonthCompletedServices).toLocaleString('en-IN')}`
            ];
            worksheet.addRow(rowData);
        });

        // Set column widths
        columns.forEach((column, index) => {
            worksheet.getColumn(index + 1).width = column.width;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Vendor Abstract Info-${currentDate}.xlsx`);
    };

    return (

        <>
            <Card title="Vendor Abstract Info" style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px',
                height: "400px",
                textAlign: 'center'
            }} headStyle={{ backgroundColor: '#114232', color: 'white' }} 
            extra={<Tooltip
                title="Export As Excel"
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    height: '35px',
                    width: '35px'
                }}>
                    {/* <FileExcelOutlined style={{ fontSize: '25px', color: '#1D6F42' }} onClick={handleExport} /> */}
                    <img src={excel} style={{width:40,height:40}} alt="" 
                      onClick={handleExport} 
                    />
                </div>
            </Tooltip>}
            >

                <Card style={{ backgroundColor: '#AFD198', height: '60px' }}>
                    <Row gutter={16}>
                        <Col span={2}>
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>Vendor</h1>
                            <br />
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', }}>Name</h1>
                        </Col>
                        <Col span={3} >
                            <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>NoOfAssets</h1>
                        </Col>
                        <Col  span={4}>
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>AssetsValues</h1>
                        </Col>
                        <Col span={3}>
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>AMCValue/Due</h1>
                        </Col>
                        <Col >
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>Total/SkippedServices</h1>
                        </Col>

                        <Col span={4}  >
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>No.ofDamagedAssets</h1>
                            <br />
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', }}>(Before expiry)</h1>
                        </Col>

                        <Col span={2} >
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', paddingTop: "15px" }}>ThisMonthService</h1>
                            <br />
                            <h1 style={{ fontSize: '13px', fontWeight: 'bold', }}>Completed</h1>
                        </Col>
                    </Row>
                </Card>
                <div style={{ maxHeight: '300px', overflowY: 'scroll',}}>
                    {vendorAbstract.map((vendorAbstract, index) => (
                        <>
                            <Card style={{
                                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                                height: '80px',
                                backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white',
                            }}
                                onMouseEnter={() => setHoveredCard(index)}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <Row key={index} gutter={16} style={{ borderStyle: 'solid', height: '70px', color: 'black', borderWidth: '0px', marginBottom: '10px', borderRadius: '5px', paddingTop: '9px' }}>
                                    <Col span={2}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >
                                            {vendorAbstract.vendorName}
                                        </p>
                                    </Col>
                                    <Col span={3}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}>
                                            {vendorAbstract.totalNoOfAssets}
                                        </p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >
                                            {parseFloat(vendorAbstract.assetsValue).toLocaleString('en-IN')}
                                        </p>
                                    </Col>
                                    <Col span={3}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        > {parseFloat(vendorAbstract.totalAmcValue || 0).toLocaleString('en-IN')}/₹{parseFloat(vendorAbstract.amcDue || 0).toLocaleString('en-IN')}
                                        </p>
                                    </Col>
                                    <Col span={3} >
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}

                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}>{parseFloat(vendorAbstract.skippedServicesCount).toLocaleString('en-IN')}/{parseFloat(vendorAbstract.totalServicesCount).toLocaleString('en-IN')}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}>{parseFloat(vendorAbstract.noOfDamagedAssets).toLocaleString('en-IN')}</p>
                                    </Col>
                                    <Col span={4} >
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}>{`${parseFloat(vendorAbstract.thisMonthServicesCount).toLocaleString('en-IN')}/${parseFloat(vendorAbstract.thisMonthCompletedServices).toLocaleString('en-IN')}`}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </>
                    ))}
                </div>
            </Card>
        </>

    );
};

export default VendorsAbstract;
