export class RouteDto{
    routeId:number;
    routeNumber:string;
    vehicleNumber:string;
    distance:string;
    plant:string;
    pickUp:string[];
    isActive:boolean;
    createdAt:Date;
    createdUser:string;
    updatedAt:Date;
    updatedUser:string;
    versionFlag:number;

    /**
     * 
     * @param routeId 
     * @param routeNumber 
     * @param vehicleNumber 
     * @param distance 
     * @param plant 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */

    constructor( routeId:number,routeNumber:string,vehicleNumber:string,distance:string,plant:string,isActive:boolean,createdAt:Date,
        createdUser:string,updatedAt:Date,updatedUser:string,versionFlag:number,pickUp:string[]){
                this.routeId = routeId;
                this.routeNumber = routeNumber;
                this.vehicleNumber = vehicleNumber;
                this.distance = distance;
                this.plant = plant;
                this.isActive = isActive;
                this.createdAt = createdAt;
                this.createdUser = createdUser;
                this.updatedAt = updatedAt;
                this.updatedUser = updatedUser;
                this.versionFlag = versionFlag;
                this.pickUp = pickUp;
                }
}