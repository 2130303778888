export class QaQcDropDown{
    qaQcTestId:number;
    testName:string;

    /**
     * 
     * @param qaQcTestId 
     * @param testName 
     */
    constructor(qaQcTestId:number, testName:string){
        this.qaQcTestId = qaQcTestId;
        this.testName = testName;
    }
}