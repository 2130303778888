export class ItemsDropDownDto{
    itemId : number;
    itemName : string;
    
    /**
     * 
     * @param itemId number
     * @param itemName string
     */
    constructor(itemId : number,itemName : string){
        this.itemId = itemId;
        this.itemName = itemName;
    }
}