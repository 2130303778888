import connection from "./connection";
import axios from 'axios';
import { AssetActionControlRequest, AssetCodeDto, AssetControlRequest } from '@gtpl/shared-models/asset-management'
import { CommonResponse } from "@gtpl/shared-models/masters";

export class AssetManagementService {
    URL = connection.DEPLOY_URL + '/asset-action-control';

    async saveAssetAction(reqObj: AssetActionControlRequest): Promise<CommonResponse> {

        return await axios.post(this.URL + '/saveAssetAction', reqObj)
            .then(res => {
                return res.data
            })
    }

    async getLatestActionOfAsset(reqObj: AssetCodeDto): Promise<AssetActionControlRequest> {

        return await axios.post(this.URL + '/getLatestActionOfAsset', reqObj)
            .then(res => {
                return res.data
            })
    }
    async stopAssets(reqObj: AssetControlRequest): Promise<CommonResponse> {

        return await axios.post(this.URL + '/stopAssets', reqObj)
            .then(res => {
                return res.data
            })
    }
    async assetActiveSoaking(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetActiveSoaking')
        .then(res => {
            return res.data
        })
    }

    async getSoakingMachineDashboardData(): Promise<CommonResponse> {

        return await axios.post(this.URL + '/getSoakingMachineDashboardData')
            .then(res => {
                return res.data
            })
    }
}