import { ApiProperty } from "@nestjs/swagger";

export class FarmsModel {
    farmId: number;
    farmCode: string;
    farmName: string;
    address: string;
    NumberOfAcres: number;
    numberOfPonds: number;
    traceCode: string;
    certificationId: number;
    certificationCode: string;
    expiryDate: any;
    mobileNumber: string;
    isActive: boolean;
    createdUser: string | null;

    

    constructor(farmId: number,farmCode: string,farmName: string,address: string,NumberOfAcres: number,numberOfPonds: number,traceCode: string,certificationId: number,certificationCode: string,expiryDate: Date,mobileNumber: string,isActive: boolean,createdUser: string | null) {
        this.farmId = farmId,
        this.farmCode = farmCode;
        this.farmName = farmName;
        this.address = address;
        this.NumberOfAcres = NumberOfAcres;
        this.numberOfPonds = numberOfPonds;
        this.traceCode = traceCode;
        this.certificationId = certificationId;
        this.certificationCode = certificationCode;
        this.expiryDate = expiryDate;
        this.mobileNumber = mobileNumber;
        this.isActive = isActive;
        this.createdUser = createdUser;
    }
}


