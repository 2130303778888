export class ConsumptionReportRequest{

    saleOrderId?:number;
    itemId?:number;
    consumedFromDate?:any;
    consumedToDate?:any;
    unitId?:number;
    constructor(saleOrderId?: number, itemId?:number,consumedFromDate?:any,consumedToDate?:any,unitId?:number) {
        this.saleOrderId = saleOrderId;
        this.itemId=itemId;
        this.consumedFromDate=consumedFromDate;
        this.consumedToDate=consumedToDate;
        this.unitId=unitId;
    }
}