import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip } from 'antd';
import { BarcodeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
// import './pages-asset-management-asset-management-components-mrn-grid.css';
import { ColumnProps } from 'antd/lib/table';
import { Scanner } from '../../../asset-classification-form/src/lib/Scanner';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLocationMappingService } from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import { AssetLocationMappingDto } from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import Highlighter from 'react-highlight-words';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { AssetsDto } from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { Redirect } from "react-router-dom";
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
// import {AssetsService} from 'apps/services/asset-management/src/app/open-assets/open-assets.service';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
// import {DepartmentService} from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
const { TabPane } = Tabs;
const { Option } = Select;
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import { log } from 'console';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';



/* eslint-disable-next-line */
export interface PurchasedNotAssignedProps { }

export function PurchasedNotAssigned(
  props: PurchasedNotAssignedProps
) {

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<any[]>([]);
  const [checkInData, setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [selectedData, setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService();
  const assignmentService = new AssetAssignmentService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [itemdata, setItemData] = useState<any>(null);
  const [code, setCode] = useState(0)
  const [activeTab, setActiveTab] = useState('1');
  const [showpopup, setShowpopup] = useState<boolean>(false);
  const locationService = new AssetLocationService();
  const PurchasedDetailsUnused = new AssetService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  // const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const [transData, setTransData] = useState<any>([]);
  const [insurance, setInsurance] = useState<any>([]);
  const [age, setAge] = useState<any>([]);
  const [length, setLength] = useState('');


  const service = new AssetService()

  let diff = 0;
  let date;
  let todate;

  useEffect(() => {

    getAllPlants();
    getItemCategory();
    getByAssetCode();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getAssetCode();
    getLocations();
  }, [])


  const getItemCategory = () => {
    assetsService.getpurchasedAssetCatogry().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDetailedView = (assetId) => {
    const req = new AssetByCode(assetId)
    assetsService.getByAssetCode(req).then(res => {
      if (res.status) {
        setPreviewData(res.data[0]);
        setItemData(res.data[1][0])
        setModal(true)
        // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)

      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)

    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getInstockAssetCodesDropDown(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getByAssetCode = () => {
    const request = new AssetByAssetCode();
    const req = new AssetByStatus();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
      request.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
        request.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
      request.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('asset_type') !== undefined) {
      req.assetType = form.getFieldValue('asset_type')
      request.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
      request.assetCategory = form.getFieldValue('assetCategory')
    }
    if (form.getFieldValue('aging') !== undefined) {
      req.aging = form.getFieldValue('aging')
    }
    assetsService.getPurchasedDetailsUnused(req).then(res => {
      if (res.status) {
        console.log(res.data)
        setAssetsData(res.data)
        setLength(res.data.length)
        if (res.data.length > 0) {
          AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('No records found')
        }
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
        setAssetsData([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const handleBack = () => {
    setModal(false)
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: 60,
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'plant',
      width: 100,
      align: 'left',
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plant'),
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      width: 100,
      align: 'left',
      sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('locationName'),
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      width: 100,
      align: 'left',
      sorter: (a, b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections: ['descend', 'ascend'],
      // filters: [
      //   {
      //     text: 'MOVABLE',
      //     value: 'movable',
      //   },

      //   {
      //     text: 'FIXED',
      //     value: 'FIXED',
      //   }, {
      //     text: 'SOFTASSETS',
      //     value: 'softassets',
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.asset_type === value;
      // },
      ...getColumnSearchProps('asset_type'),
    },
    {
      title: 'Asset Category',
      dataIndex: 'asetCategoryName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.asetCategoryName?.localeCompare(b.asetCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asetCategoryName')
    },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assetName?.localeCompare(b.assetName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetName')
    },
    {
      title: "Asset Code",
      dataIndex: "assetCode",
      width: '130',
      align: 'left',
      sorter: (a, b) => a.assetCode?.localeCompare(b.assetCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetCode'),
      // render:(text,record)=>{
      //   return(<>
      //   <Button type='link'onClick={() => getDetailedView(record.assetsCode)}>
      // //   {record.assetsCode}</Button>
      //   </>)
      // }
    },
   
    // {
    //   title:'Asset Status',
    //   dataIndex:'STATUS',
    //   width:130,
    //   align:'left',

    //   // ...getColumnSearchProps('assetCategory')
    // },
    {
      title: "Purchase Date",
      dataIndex: "purchaseddate",
      align: 'right',
      width: '5',
      sorter: (a, b) => moment(a.purchaseddate).unix() - moment(b.purchaseddate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.purchaseddate ? moment(record.purchaseddate).format('YYYY-MM-DD') : ' -'}
          </>
        )
      }
    },

    {
      title: 'Asset Condition',
      dataIndex: 'assetCondition',
      width: 100,
      align: 'left',
      render: (text, record) => {
        return (
          <>
            {record.assetCondition ? record.assetCondition : '-'}
          </>
        )
      },
      filters: [
        {
          text: 'Good',
          value: 'Good',
        },

        {
          text: 'PartiallyDamaged',
          value: 'PartiallyDamaged',
        },
        {
          text: 'Damaged',
          value: 'Damaged',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.assetCondition === value;
      },
      sorter: (a, b) => a.assetCondition?.localeCompare(b.assetCondition),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Asset Age',
      dataIndex: 'aging',
      width: 80,
      align: 'right',
      sorter: (a, b) => a.aging - b.aging,
      sortDirections: ['descend', 'ascend'],

      render: (text, record) => {
        return (
          <>{record.aging ? record.aging : '-'}</>
        )
      },
    },

  ]

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })
    getByAssetCode()

  }
  const searchhandler = () => {
    getByAssetCode();

  }
  const resetHandler = () => {
    form.resetFields();
    getByAssetCode();
    // getCheckIns();
  }

  const finishHandler = (value) => {
    getByAssetCode();

  }

  const exportedData = [];
  const execlData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Unit', dataIndex: 'plant', render: (text, record) => { return record.plant ? record.plant : '-' } },
    { title: 'Location', dataIndex: 'locationName', render: (text, record) => { return record.locationName ? record.locationName : '-' } },
    { title: 'Asset Type', dataIndex: 'asset_type', render: (text, record) => { return record.asset_type ? record.asset_type : '-' } },
    { title: 'Asset Category', dataIndex: 'asetCategoryName', render: (text, record) => { return record.asetCategoryName ? record.asetCategoryName : '-' } },
    { title: 'Asset Name', dataIndex: 'assetName', render: (text, record) => { return record.assetName ? record.assetName : '-' } },
    { title: 'Asset Code', dataIndex: 'assetCode', render: (text, record) => { return record.assetCode ? record.assetCode : '-' } },
    // {title:'Asset Status',dataIndex:'STATUS',render:(text,record) => {return record.STATUS ? record.STATUS : '-'}},
    { title: 'Purchase Date', dataIndex: 'purchaseddate', render: (text, record) => { return record.purchaseddate ? moment(record.purchaseddate).format('YYYY-MM-DD') : '-' } },
    // {title:'Insurance', dataIndex:'insurance',render:(text,record)=>{return record.insurance ? record.insurance : '-'}},
    { title: 'Asset Condition', dataIndex: 'assetCondition', render: (text, record) => { return record.assetCondition ? record.assetCondition : '-' } },
    { title: 'Asset Age (In Days)', dataIndex: 'aging', render: (text, record) => { return record.aging ? record.aging : '-' } },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('PurchasedNotused-report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('purchasednotassigned.xlsx');
  }
  function handleAgingFilter(values) {

  }


  return (

    <Card title={<span style={{ color: 'white' }}> Purchased But Unassigned - Audit Report </span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={assetsData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
    >
      <Form layout="vertical" form={form} onFinish={finishHandler}>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}>
            <Form.Item name="assetCode" label="Asset Code">
              <Select
                suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }} />}
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder='Asset Code'
                // onChange={onCodeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assetCodeData.map((assets) => {
                  return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.concateditemName}`}</Option>
                })

                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                // defaultValue={'all'}
                placeholder='All Categories'
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={'all'}>All Categories</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.item_sub_category}>{items.item_sub_category}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Unit' name='plantId'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                placeholder='All Units'
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All Units</Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // defaultValue={'All'}
                placeholder='All Locations'
                showSearch
                allowClear>
                <Option key={'all'} value={'All'}>All Locations</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label='Asset Type' name='asset_type'>
              <Select
                placeholder='asset_type'
                showSearch
                defaultValue={'MOVABLE'}
                allowClear
              >
                <Option key='All' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Aging' name='aging'>
              <Select
                placeholder='aging'
                showSearch
                defaultValue={'All'}
                allowClear
                onChange={handleAgingFilter}
              >
                <Option key='All' value=''>All</Option>
                <Option key='below 50' value='50'>below 50 days</Option>
                <Option key='51-300' value='51-300'>51-300 days</Option>
                <Option key='301-900' value='301-900'>301-900 days </Option>
                <Option key='901-1500' value='901-1500'>901-1500 days</Option>
                <Option key='1501-2125' value='1501-2125'>1501-2125 days</Option>
                <Option key='2125-3000' value='2125-3000'>2125-3000 days</Option>
                <Option key='3000-3600' value='3000-3600'>3000-3600 days</Option>
                <Option key='3600-4200' value='3600-4200'>3600-4200 days</Option>
                <Option key='above 4200' value='above 4200'>above 4200 days</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={() => searchhandler()} icon={<SearchOutlined />}
            >
              Search
            </Button>

            <Button style={{ margin: '0 14px' }} icon={<UndoOutlined />} onClick={resetHandler}> Reset </Button>
            <Button>Total no of records :{length}</Button>
          </Col>

        </Row>
      </Form>

      {/* <Tabs  type={'card'} tabPosition={'top'} onChange={(key) => setActiveTab(key)} activeKey={activeTab}> */}
      {assetsData.length > 0 ? (<>
        <Tabs>
          <TabPane key='1' tab={<span ><b>
            {/* {'In Stock'} */}
          </b></span>}>

            <Table columns={columns}
              dataSource={assetsData} pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }

              }} scroll={{ x: 1400, y: 500 }} />
          </TabPane>

        </Tabs>


        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}

          style={{ top: 30, alignContent: 'right' }}
          visible={modal}
          footer={[
            <Button key="back" onClick={() => handleBack()}>
              Back
            </Button>,
          ]}
          title={<React.Fragment>
          </React.Fragment>}
        >

        </Modal>
        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            qrModal == 'assetCode' ? <Scanner handleScan={handleAssetIdScan} /> : null
            //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal></>) : (
        <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
      )}

    </Card>


  );
}

export default PurchasedNotAssigned;
