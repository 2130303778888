export class IssueDropDownDto{
    storeConsumptionId:number;
    storeConsumptionCode:string;

    /**
     * 
     * @param storeConsumptionId 
     * @param storeConsumptionCode 
     */
    constructor(storeConsumptionId:number, storeConsumptionCode:string){
        this,storeConsumptionId = storeConsumptionId;
        this,storeConsumptionCode = storeConsumptionCode;
    }
}