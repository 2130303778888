import { AssetDto } from './assets.dto';
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class AssetsResponseModel extends GlobalResponseObject{
    data?: AssetDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: AssetDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
