import { PackingStatus, PalletType, StockStatus, TransactionType } from "@gtpl/shared-models/common-models";
import { Shifts } from "./shifts.enum";

export class StockInDto {
    // reportingLogId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    // reportedPerson: string;
    reportingCases: number;
    palletType: PalletType;
    palletId:number;
    rackPostion:number;
    loosePouches: number;
    lotCode: string;
    // transaction: string;
    transactionType: TransactionType;
    traceCode: number;
    // receivingPlant: number;
    coldStorageId: number;
    plantId: number;
    shifts: Shifts;
    packingCompleted: PackingStatus;
    stockType: StockStatus;;

    // loactionId:number;
    reportingDate: Date;
    manualReported: boolean;
    createdUser: string;
    updatedUser: string;
    isDamaged?: PackingStatus;
    remarks?: string;
    
   
    constructor(
        // reportingLogId: number,
        saleOrderId: number,
        saleOrderItemId: number,
        // shifts: Shifts,
        // reportedPerson: string,
        reportingCases: number,
        // casesRequired:number,
        palletType: PalletType,
        palletId:number,
        rackPostion:number,
        loosePouches: number,
        lotCode: string,
        // transaction: string,
        transactionType: TransactionType,
        traceCode: number,
        // receivingPlant: number,
        coldStorageId: number,
        plantId:number,
        shifts: Shifts,
        packingCompleted: PackingStatus,
        stockType: StockStatus,
        // loactionId:number,
        reportingDate: Date,
        manualReported: boolean,

        createdUser: string,
        updatedUser: string,
        isDamaged?: PackingStatus,
        remarks?: string
         ){
        // this.reportingLogId = reportingLogId;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.updatedUser = updatedUser;
        this.reportingCases = reportingCases;
        // this.casesRequired = casesRequired;
        this.palletType = palletType;
        this.palletId = palletId;
        this.rackPostion = rackPostion;
        this.loosePouches= loosePouches;
        this.lotCode= lotCode;
        this.transactionType= transactionType;
        this.packingCompleted= packingCompleted;
        this.traceCode= traceCode;
        // this.receivingPlant= receivingPlant;
        this.coldStorageId= coldStorageId;
        this.plantId= plantId;
        // this.loactionId = loactionId;
        // this.reportedPerson = reportedPerson;
        this.reportingDate = reportingDate;
        this.shifts = shifts;
        this.manualReported = manualReported;
        this.createdUser = createdUser;
        this.isDamaged = isDamaged;
        this.remarks = remarks;
        this.stockType = stockType;
       
    }
}
