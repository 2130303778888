import React, { useEffect, useRef, useState } from 'react';
import { TransferMaterialService } from '@gtpl/shared-services/procurement';

import { TransferMaterialDTO, StoreConsumptionDetails, StoreConsumptionItemsDto, TransferMaterialRequest, TransferMaterialInfo, TransferIdRequest } from '@gtpl/shared-models/procurement-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Input, Button, Divider, Popconfirm, Switch, Tag, Card, Col, Drawer, Row, Tabs, Tooltip, Modal, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import { CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, StockOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import Table, { ColumnProps } from 'antd/lib/table';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { TransferPrint } from "@gtpl/pages/procurement/packing-materials-components/transfer-print";
import './stock-transfer-grid.css';
import { EmployeeRolesEnum, TransferMethodEnum, TransferStatusEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface StockTransferGridProps { }

export function StockTransferGrid(
  props: StockTransferGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [defaultTab, setDefaultTab] = useState<string>('0');
  const [defaultTab1, setDefaultTab1] = useState<string>('0');
  const [defaultTab2, setDefaultTab2] = useState<string>('0');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [transferDCData, setTransferDCData] = useState<TransferMaterialInfo[]>([]);
  const [prepMaterialTransferMRNData, setPrepMaterialTransferMRNData] = useState<TransferMaterialInfo[]>([]);
  const [prepMaterialTransferDCData, setPrepMaterialTransferDCData] = useState<TransferMaterialInfo[]>([]);
  const [plantTransferDCData, setPlantTransferDCData] = useState<TransferMaterialInfo[]>([]);
  const [indentTransferDCData, setIndentTransferDCData] = useState<TransferMaterialInfo[]>([]);

  const [plantTransferMRNData, setPlantTransferMRNData] = useState<TransferMaterialInfo[]>([]);
  const [indentTransferMRNData, setIndentTransferMRNData] = useState<TransferMaterialInfo[]>([]);
  const [transferMRNData, setTransferMRNData] = useState<TransferMaterialInfo[]>([]);

  const [prepMaterialPlantTransferDCData, setPrepMaterialPlantTransferDCData] = useState<TransferMaterialInfo[]>([]);
  const [prepMaterialPlantTransferMRNData, setPrepMaterialPlantTransferMRNData] = useState<TransferMaterialInfo[]>([]);



  const [packingStockTransferId, setPackingStockTransferId] = useState<number>(0);
  const [updateState, setUpdateState] = useState(false);
  const [object, setObject] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [printType, setPrintType] = useState('');
  const [selectedLogId, setSelectedRowId] = useState<number>(0);

  const { TabPane } = Tabs;

  const transferMaterialService = new TransferMaterialService();

  useEffect(() => {
    getTransferMaterialDCData();
    getTransferMaterialMRNData();
  }, []);


  /**
   * 
   */

  const goToDetailView = (packingStockTransferId: number) => {
    setPackingStockTransferId(packingStockTransferId);
    console.log(packingStockTransferId);
  }
  const getTransferMaterialDCData = () => {
    const req = new TransferMaterialRequest(Number(localStorage.getItem('unit_id')));
    transferMaterialService.getTransferMaterialDCData(req).then(res => {
      if (res.status) {
        setTransferDCData(res.data.filter((res) => res.isPreparedMaterial === 'no'));
        setPrepMaterialTransferDCData(res.data.filter((res) => res.isPreparedMaterial === 'yes'));
        setIndentTransferDCData(res.data.filter((res) => res.transferMethod === "Warehouse To Plant" && res.isPreparedMaterial === 'no'));
        setPlantTransferDCData(res.data.filter((res) => res.transferMethod === "Plant To Plant" && res.isPreparedMaterial === 'no'));
        setPrepMaterialPlantTransferDCData(res.data.filter((res) => res.transferMethod === "Plant To Plant" && res.isPreparedMaterial === 'yes'));

      } else {
        setTransferDCData([]);
        setIndentTransferDCData([]);
        setPlantTransferDCData([]);
        setPrepMaterialPlantTransferDCData([]);
      }
    }).catch(err => {
      setTransferDCData([]);
      setIndentTransferDCData([]);
      setPlantTransferDCData([]);
      setPrepMaterialPlantTransferDCData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getTransferMaterialMRNData = () => {
    const req = new TransferMaterialRequest(Number(localStorage.getItem('unit_id')));
    transferMaterialService.getTransferMaterialMRNData(req).then(res => {
      if (res.status) {
        console.log(res.data.filter((res) => res.indentId === null));
        console.log(res.data.filter((res) => res.indentId != null));
        setTransferMRNData(res.data.filter((res) => res.isPreparedMaterial === 'no'))
        setPrepMaterialTransferMRNData(res.data.filter((res) => res.isPreparedMaterial === 'yes'))
        setPlantTransferMRNData(res.data.filter((res) => res.transferMethod != "Warehouse To Plant" && res.isPreparedMaterial === 'no'));
        setPrepMaterialPlantTransferMRNData(res.data.filter((res) => res.transferMethod != "Warehouse To Plant" && res.isPreparedMaterial === 'yes'));
        setIndentTransferMRNData(res.data.filter((res) => res.transferMethod ==="Warehouse To Plant" && res.isPreparedMaterial === 'no'));
      } else {
        setTransferMRNData([])
        setPlantTransferMRNData([]);
        setIndentTransferMRNData([]);
        setPrepMaterialPlantTransferMRNData([]);
      }
    }).catch(err => {
      setTransferMRNData([])
      setPlantTransferMRNData([]);
      setIndentTransferMRNData([]);
      setPrepMaterialPlantTransferMRNData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const closeDrawer = () => {
    setDrawerVisible(false);
  }


  const prepMRNcolumnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Stock Transfer Date',
      dataIndex: 'transferredDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferredDate).unix() - moment(b.transferredDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferredDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferMethod',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferMethod.localeCompare(b.transferMethod),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transferMethod')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'From Unit',
      dataIndex: 'fromPlantName',
      className: (([
        EmployeeRolesEnum.ADMIN,
        EmployeeRolesEnum.HO_PACKAGING_MANAGER,
    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? 'showColumn' : 'hideColumn',
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.fromPlantName == value;
      },
    },

    {
      title: 'To Unit',
      dataIndex: 'toPlantName',
     
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.toPlantName == value;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<StockOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(packingStockTransferId) ? <Redirect to={ `/stock-transfer-detail-view/${packingStockTransferId}` } /> : null}
          <Tooltip title="Click to Map Location & View" ><EyeOutlined type="view"
            onClick={() => {
              setPackingStockTransferId(rowData.packingStockTransferId);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /></Tooltip>
          <Divider type="vertical" />
          {((rowData.status === TransferStatusEnum.IN_TRANSIT) ?
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
              ReceiveStock(rowData);
            }}>
              <Tooltip placement="top" title='Receive'><StockOutlined disabled={disable} className={'receive'} type="Receive" name="Receive"
                style={{ color: 'red', fontSize: '14px' }}
              /> </Tooltip>
            </Popconfirm>
            : <Tooltip placement="top" title='Received'><StockOutlined style={{ color: 'lightgreen', fontSize: '14px' }} type="Received" name="Received" /></Tooltip>)}
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.packingStockTransferId, 'MRN');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
        </span>
      )
    }
  ];

  const prepDCcolumnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Stock Transfer Date',
      dataIndex: 'transferredDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferredDate).unix() - moment(b.transferredDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferredDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferMethod',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferMethod.localeCompare(b.transferMethod),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transferMethod')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'From Unit',
      dataIndex: 'fromPlantName',
      className: (([
        EmployeeRolesEnum.ADMIN,
        EmployeeRolesEnum.HO_PACKAGING_MANAGER,
    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? 'showColumn' : 'hideColumn',
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.fromPlantName == value;
      },
    },

    {
      title: 'To Unit',
      dataIndex: 'toPlantName',
     
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.toPlantName == value;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<StockOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(packingStockTransferId) ? <Redirect to={ `/stock-transfer-detail-view/${packingStockTransferId}` } /> : null}
          <Tooltip title="Click to Map Location & View" ><EyeOutlined type="view"
            onClick={() => {
              setPackingStockTransferId(rowData.packingStockTransferId);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /></Tooltip>
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.packingStockTransferId, 'MRN');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
        </span>
      )
    }
  ];

  //TO open the form for updation

  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Stock Transfer Date',
      dataIndex: 'transferredDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferredDate).unix() - moment(b.transferredDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferredDate).format('YYYY-MM-DD') },

      // ...getColumnSearchProps('transferredDate')
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferMethod',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferMethod.localeCompare(b.transferMethod),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transferMethod')
    },
    {
      title: 'Indent',
      dataIndex: 'indent',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.indent.localeCompare(b.indent),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indent')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'From Unit',
      dataIndex: 'fromPlantName',
      // width:180,
      // align:'left',
      className: (([
        EmployeeRolesEnum.ADMIN,
        EmployeeRolesEnum.HO_PACKAGING_MANAGER,
    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? 'showColumn' : 'hideColumn',
      // sorter: (a, b) => a.fromPlantName.localeCompare(b.fromPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('fromPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.fromPlantName == value;
      },
    },

    {
      title: 'To Unit',
      dataIndex: 'toPlantName',
      // width:140,
      // align:'left',
      // sorter: (a, b) => a.toPlantName.localeCompare(b.toPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('toPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.toPlantName == value;
      },
    },
    // {
    //   title: 'Sale Person',
    //   dataIndex: ["salePersonInfo","employeeName"],
    //   align:'left',
    //   sorter: (a, b) => a.salePersonInfo.employeeName.localeCompare(b.salePersonInfo.employeeName),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('salePerson')
    // },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<StockOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },


    // },
    // {
    //   title:`Action`,
    //   dataIndex: 'action',
    //   align:'left',
    //   render: (text, rowData) => (
    //     <span>   
    //       {(storeConsumptionId) ? <Redirect to={{pathname:"/store-consumption-detail-view", state:storeConsumptionId}}/>:null}      
    //         <EyeOutlined  className={'viewSamplTypeIcon'}  type="view" 
    //         onClick={() => gotToStoreIssuesDetailView(rowData)}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />

    // //     </span>
    //   )
    // }

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(packingStockTransferId) ? <Redirect

            to={`/stock-transfer-detail-view/${rowData.packingStockTransferId}`}
          /> : null}
          <EyeOutlined type="view"
            onClick={() => {
              setPackingStockTransferId(rowData);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.packingStockTransferId, 'DC');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
          {/* <Divider type="vertical" /> */}
        </span>
      )
    }
  ];

  const indentSkeleton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Stock Transfer Date',
      dataIndex: 'transferredDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferredDate).unix() - moment(b.transferredDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferredDate).format('YYYY-MM-DD') },

      // ...getColumnSearchProps('transferredDate')
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferMethod',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferMethod.localeCompare(b.transferMethod),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transferMethod')
    },
    {
      title: 'Indent',
      dataIndex: 'indent',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.indent.localeCompare(b.indent),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indent')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'MRN Number',
      dataIndex: 'mrnNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.mrnNumber?.localeCompare(b.mrnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mrnNumber')
    },

    {
      title: 'From Unit',
      dataIndex: 'fromPlantName',
      // width:180,
      // align:'left',
      // sorter: (a, b) => a.fromPlantName.localeCompare(b.fromPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('fromPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.fromPlantName == value;
      },
    },

    {
      title: 'To Unit',
      dataIndex: 'toPlantName',
      className: (([
        EmployeeRolesEnum.ADMIN,
        EmployeeRolesEnum.HO_PACKAGING_MANAGER,
    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? 'showColumn' : 'hideColumn',
      // width:140,
      // align:'left',
      // sorter: (a, b) => a.toPlantName.localeCompare(b.toPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('toPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.toPlantName == value;
      },
    },
    // {
    //   title: 'Sale Person',
    //   dataIndex: ["salePersonInfo","employeeName"],
    //   align:'left',
    //   sorter: (a, b) => a.salePersonInfo.employeeName.localeCompare(b.salePersonInfo.employeeName),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('salePerson')
    // },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<StockOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<CloseCircleOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(packingStockTransferId) ? <Redirect to={ `/stock-transfer-detail-view/${packingStockTransferId}` } /> : null}
          <Tooltip title="Click to Map Location & View" ><EyeOutlined type="view"
            onClick={() => {
              setPackingStockTransferId(rowData.packingStockTransferId);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /></Tooltip>
          <Divider type="vertical" />
          {((rowData.status === TransferStatusEnum.IN_TRANSIT) ?
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
              ReceiveStock(rowData);
            }}>
              <Tooltip placement="top" title='Receive'><StockOutlined disabled={disable} className={'receive'} type="Receive" name="Receive"
                style={{ color: 'red', fontSize: '14px' }}
              /> </Tooltip>
            </Popconfirm>
            : <Tooltip placement="top" title='Received'><StockOutlined style={{ color: 'lightgreen', fontSize: '14px' }} type="Received" name="Received" /></Tooltip>)}
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.packingStockTransferId, 'MRN');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
        </span>
      )
    }
  ];
  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Stock Transfer Date',
      dataIndex: 'transferredDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.transferredDate).unix() - moment(b.transferredDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.transferredDate).format('YYYY-MM-DD') },

      // ...getColumnSearchProps('transferredDate')
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transferMethod',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.transferMethod.localeCompare(b.transferMethod),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transferMethod')
    },
    {
      title: 'Indent',
      dataIndex: 'indent',
      width: 150,
      align: 'left',
      sorter: (a, b) => a.indent.localeCompare(b.indent),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indent')
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dcNumber')
    },
    {
      title: 'MRN Number',
      dataIndex: 'mrnNumber',
      // width:180,
      // align:'left',
      sorter: (a, b) => a.mrnNumber?.localeCompare(b.mrnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('mrnNumber')
    },

    {
      title: 'From Unit',
      dataIndex: 'fromPlantName',
      // width:180,
      // align:'left',
      // sorter: (a, b) => a.fromPlantName.localeCompare(b.fromPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('fromPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.fromPlantName == value;
      },
    },

    {
      title: 'To Unit',
      dataIndex: 'toPlantName',
      className: (([
        EmployeeRolesEnum.ADMIN,
        EmployeeRolesEnum.HO_PACKAGING_MANAGER,
    ].indexOf(JSON.parse(localStorage.getItem('role')))) > -1) ? 'showColumn' : 'hideColumn',
      // width:140,
      // align:'left',
      // sorter: (a, b) => a.toPlantName.localeCompare(b.toPlantName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('toPlantName')
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.toPlantName == value;
      },
    },
    // {
    //   title: 'Sale Person',
    //   dataIndex: ["salePersonInfo","employeeName"],
    //   align:'left',
    //   sorter: (a, b) => a.salePersonInfo.employeeName.localeCompare(b.salePersonInfo.employeeName),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('salePerson')
    // },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {status == TransferStatusEnum.IN_TRANSIT ? <Tag icon={<StockOutlined />} color="#f50">{TransferStatusEnum.IN_TRANSIT}</Tag> : status == TransferStatusEnum.RECEIVED ? <Tag icon={<CloseCircleOutlined />} color="#87d068">{TransferStatusEnum.RECEIVED}</Tag> : ""}
        </>
      ),
      filters: [
        {
          text: TransferStatusEnum.IN_TRANSIT,
          value: TransferStatusEnum.IN_TRANSIT,
        },
        {
          text: TransferStatusEnum.RECEIVED,
          value: TransferStatusEnum.RECEIVED,
        },

      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {(packingStockTransferId) ? <Redirect to={ `/stock-transfer-detail-view/${packingStockTransferId}` } /> : null}
          <Tooltip title="Click to Map Location & View" ><EyeOutlined type="view"
            onClick={() => {
              setPackingStockTransferId(rowData.packingStockTransferId);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /></Tooltip>
          <Divider type="vertical" />
          {((rowData.status === TransferStatusEnum.IN_TRANSIT && Number(rowData.issuedQty) === Number(rowData.transferedQty)) ?
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" disabled={disable} onConfirm={() => {
              ReceiveStock(rowData);
            }}>
              <Tooltip placement="top" title='Receive'><StockOutlined disabled={disable} className={'receive'} type="Receive" name="Receive"
                style={{ color: 'red', fontSize: '14px' }}
              /> </Tooltip>
            </Popconfirm>
            : (rowData.status === TransferStatusEnum.RECEIVED && Number(rowData.issuedQty) === Number(rowData.transferedQty)) ? <Tooltip placement="top" title='Received'><StockOutlined style={{ color: 'lightgreen', fontSize: '14px' }} type="Received" name="Received" /></Tooltip>:"")}
          <Divider type="vertical" />
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              openPrint(rowData.packingStockTransferId, 'MRN');
            }}
            style={{ color: '#1890ff', fontSize: '14px' }} />
        </span>
      )
    }
  ];
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const ReceiveStock = (rowData) => {
    setDisable(true);
    const req = new TransferIdRequest();
    req.packingStockTransferId = rowData.packingStockTransferId;
    req.createdUser = localStorage.getItem('createdUser');
    req.versionFlag = rowData.versionFlag;
    transferMaterialService.ReceiveStock(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTransferMRNData([]);
        getTransferMaterialMRNData();
      } else {
        AlertMessages.getErrorMessage(res.message);
      }
      setDisable(false);
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const openPrint = (logId, printType) => {
    setSelectedRowId(logId)
    setIsModalVisible(true);
    setPrintType(printType);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };


  return (
    <Card
      title={<span style={{ color: 'white' }}>Stock Transfer</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={<Link to='/packing-stock-transfer' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}
    >
      <div>
        {transferDCData?.length || transferMRNData?.length ?
        <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
          <TabPane
              key="1"
              tab={<span style={{ color: "#f5222d" }} >Prepared Stock Transfer: {prepMaterialTransferDCData.length}</span>}
            >
          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            <TabPane
              key="1"
              tab={<span style={{ color: "#f5222d" }} >DC: {prepMaterialPlantTransferDCData.length}</span>}
            >
                  {prepMaterialPlantTransferDCData?.length ?
                    <Table
                      columns={prepDCcolumnsSkelton1}
                      dataSource={prepMaterialPlantTransferDCData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      size="middle"
                      bordered />:<Empty />
                    }
            </TabPane>
            <TabPane
              key="2"
              tab={<span style={{ color: "#f5222d" }} >MRN: {prepMaterialTransferMRNData.length}</span>}
            >
                  {prepMaterialPlantTransferMRNData?.length?
                    <Table
                      columns={prepMRNcolumnsSkelton1}
                      dataSource={prepMaterialPlantTransferMRNData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      // scroll={{ y:500 }}
                      size="middle"
                      bordered />:<Empty />
                  }
            </TabPane>
          </Tabs> : <Empty />
          </TabPane>
          <TabPane
              key="2"
              tab={<span style={{ color: "#f5222d" }} >Stock Transfer: {transferDCData.length}</span>}
            >
          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            <TabPane
              key="1"
              tab={<span style={{ color: "#f5222d" }} >DC: {transferDCData.length}</span>}
            >
              <Tabs key={1} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab1}>
                <TabPane
                  key="1"
                  tab={<span style={{ color: "#f5222d" }} >Indent Transfer: {indentTransferDCData.length}</span>}
                >
                  {indentTransferDCData?.length ?
                    <Table
                      columns={columnsSkelton1}
                      dataSource={indentTransferDCData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      size="middle"
                      bordered />:<Empty />}
                </TabPane>
                <TabPane
                  key="2"
                  tab={<span style={{ color: "#f5222d" }} >Plant Transfer: {plantTransferDCData.length}</span>}
                >
                  {plantTransferDCData?.length ?
                    <Table
                      columns={columnsSkelton1}
                      dataSource={plantTransferDCData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      size="middle"
                      bordered />:<Empty />}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane
              key="2"
              tab={<span style={{ color: "#f5222d" }} >MRN: {transferMRNData.length}</span>}
            >
              <Tabs key={defaultTab2} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab2}>
                <TabPane
                  key="1"
                  tab={<span style={{ color: "#f5222d" }} >Indent Tranfer: {indentTransferMRNData.length}</span>}
                >
                  {indentTransferMRNData?.length?
                    <Table
                      columns={indentSkeleton}
                      dataSource={indentTransferMRNData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      // scroll={{ y:500 }}
                      size="middle"
                      bordered />:<Empty />
                  }
                </TabPane>
                <TabPane
                  // tab="Open Orders"
                  key="2"
                  tab={<span style={{ color: "#f5222d" }} >Plant Transfer: {plantTransferMRNData.length}</span>}
                >
                  {plantTransferMRNData?.length?
                    <Table
                      columns={columnsSkelton2}
                      dataSource={plantTransferMRNData}
                      pagination={{
                        onChange(current) {
                          setPage(current);
                        }
                      }}
                      onChange={onChange}
                      // scroll={{ y:500 }}
                      size="middle"
                      bordered />:<Empty />
                  }
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs> : <Empty />
          </TabPane>
        </Tabs> : <Empty />}
        <Modal
          className='print-docket-modal'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          {<TransferPrint transferId={selectedLogId} printOrder={printOrder} printType={printType} />}
        </Modal>
      </div>



    </Card>
  );
}

export default StockTransferGrid;
