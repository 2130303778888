import { PoVendorDataModel } from "../purchase-order";
import { PoNumbersModel } from "../purchase-order/po-number.model";
import { grnItemsDataModel } from "./grn-items-details";
import { GrnItemsInfo } from "./grn-items-info.model";

export class AllGrnDto {
    grnId?: number;
    grnDate: any;
    InvoiceDate: any;
    grnNumber?: string;
    invoiceNumber: string;
    poId: PoNumbersModel;
    unitId: number;
    totalQuantity?: number;
    totalDiscount: number;
    totalTax: number;
    totalCost: number;
    remarks: string;
    approvar: string;
    status?: string;
    createdUser : string;
    updatedUser ?: string;
    vendor:PoVendorDataModel;
    grnItemsInfo: grnItemsDataModel[];
    poStatus?: string;
    poType?: string;
    soNumber?: string;
    soId?: number;
    pmStatus?: string;
    poNumber?: string;

    
  
      /**
      * @param grnId
    * @param grnDate
    * @param InvoiceDate
    * @param grnNumber?
    * @param invoiceNumber
    * @param poId
    * @param totalQuantity
    * @param totalDiscount
    * @param totalTax
    * @param totalCost
    * @param remarks
    * @param approvar
    * @param status
    * @param createdAt 
    * @param createdUser 
    * @param updatedAt 
    * @param updatedUser 
    * @param versionFlag 
    * @param soId
    * @param pmStatus
       */
      constructor(grnId: number,grnDate: any,InvoiceDate: any,grnNumber: string,unitId: number,invoiceNumber: string,poId: PoNumbersModel,totalQuantity: number,totalDiscount: number,totalTax: number,totalCost: number,remarks: string,approvar: string,status: string,createdUser : string,updatedUser : string,vendor:PoVendorDataModel,grnItemsInfo: grnItemsDataModel[],poStatus?: string,poType?: string,soNumber?: string,soId?:number,pmStatus?:string, poNumber?: string){
          this.grnId = grnId;
    this.grnDate = grnDate;
    this.InvoiceDate = InvoiceDate;
    this.grnNumber = grnNumber;
    this.unitId =unitId;
    this.invoiceNumber = invoiceNumber;
    this.poId = poId;
    this.totalQuantity = totalQuantity;
    this.totalDiscount = totalDiscount;
    this.totalTax = totalTax;
    this.totalCost = totalCost;
    this.remarks = remarks;
    this.approvar = approvar;
    this.status = status;
    this.createdUser  = createdUser;
    this.updatedUser  = updatedUser;
    this.vendor  = vendor;
    this.grnItemsInfo  = grnItemsInfo;
    this.poStatus  = poStatus;
    this.poType  = poType;
    this.soNumber  = soNumber;
    this.soId  = soId;
    this.pmStatus  = pmStatus;
    this.poNumber  = poNumber;
         
      }
  }