import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, FormInstance, Popconfirm, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService } from '@gtpl/shared-services/masters'
import { PlantsDropDown, UnitcodeDto, UnitTypeEnum } from '@gtpl/shared-models/masters'
import {  SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoTranferDto } from '@gtpl/shared-models/sale-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { ColumnsType } from 'antd/lib/table';

export interface StockDetailsProps {
    rackPositionId:number,
    closeModal:(closeModal:boolean)=>void


}
export function StockDetails(props: StockDetailsProps) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [maxPalletCapacity, setMaxPalletCapacity] = useState<number>(0);
    const [filledCapacity, setFilledCapacity] = useState<number>(0);
    const [stockData, setStockData] = useState<any[]>([]);

    const fGStockService = new FGStockService();
    const [page, setPage] = React.useState(1);

    useEffect(()=> {
        console.log(props.rackPositionId)
        if(props.rackPositionId){
            console.log(props.rackPositionId);
            getStockDetails(props.rackPositionId)
        }
    },[props.rackPositionId])

    const getStockDetails = (res) => {
        fGStockService.getStockDetails({rackPositionId:res}).then((res) => {
            if (res.status) {
                let maxPalletCapacity = Math.max(...res.data.map(member => member.maxPalletCapacity));
                let filledCapacity = res.data?.reduce(function(prev, current) {
                    return prev + +current.cartons
                  }, 0);
                console.log(filledCapacity);
                setMaxPalletCapacity(maxPalletCapacity);
                setFilledCapacity(filledCapacity);
                setStockData(res.data);
            } else {
                setStockData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setStockData([]);
        });
    }
    const sampleTypeColumns: ColumnsType<any> = [
        {
            title: '#',
            dataIndex: 'sno',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        {
            title: 'Stock Type',
            dataIndex: 'stockType',
        },
        {
            title: 'PO No',
            dataIndex: 'poNumber',
        },
        {
            title: 'Product',
            dataIndex: 'variantCode',
        },
        {
            title: 'Cartons',
            dataIndex: 'cartons',
        }
  
    ]
    const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Location Info {' - '+stockData[0]?.rackCode}</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Row gutter={40} >
                <Col>
                    <Card title={'Pallet Capacity: ' + maxPalletCapacity} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
                </Col>
                <Col>
                    <Card title={'Filled Capacity: ' + filledCapacity} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
                </Col>
                <Col>
                    <Card title={'Available: ' + (Number(maxPalletCapacity) - Number(filledCapacity))} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'white'}}></Card>
                </Col>
            </Row>
            <Table
                rowKey={record => record.stockId}
                columns={sampleTypeColumns}
                dataSource={stockData.length>0?stockData:null}
                pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                scroll={{ x: 500 }}
                size="small"
                bordered
              >
              </Table>
            </Card>
        </>
    );
}
export default StockDetails;
