export class RfidAssocProductModel{
    brand: string;
    specie: string;
    variety: string;
    productForm: string;
    freezingType: string;
    packingStyle: string;
    grade: string;

    /**
     * 
     * @param brand 
     * @param specie 
     * @param variety 
     * @param productForm 
     * @param freezingType 
     * @param packingStyle 
     * @param grade 
     * @param assocPalletId 
     */
    constructor(brand: string,specie: string,variety: string,productForm: string,freezingType: string,packingStyle: string,grade: string){
        this.brand = brand;
        this.specie = specie;
        this.variety = variety;
        this.productForm = productForm;
        this.freezingType = freezingType;
        this.packingStyle = packingStyle;
        this.grade = grade;
    }
}