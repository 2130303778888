import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GradingInventoryModel } from "./grading-inventory.model";

export class GradingInventoryResponse extends GlobalResponseObject{
    data: GradingInventoryModel[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data: GradingInventoryModel[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}