import { Button, Card, Col, Row, Tooltip, message } from "antd";
import React, { useState } from "react";
import excel from '../../../assets-dashboard/src/lib/excel.png'
// import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
interface IRaciMatrix {
    raci: any[];
}

const RaciMatrix = (props: IRaciMatrix) => {
    const { raci } = props;
    const [hoveredCard, setHoveredCard] = useState(null);

    // const handleExport: React.MouseEventHandler<HTMLElement> = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    //     e.preventDefault();
    
    //     if (raci?.length === 0) {
    //         message.warning('No data to export.');
    //         return;
    //     }
    
    //     const currentDate = new Date()
    //         .toISOString()
    //         .slice(0, 10)
    //         .split("-")
    //         .join("/");
    
    //     const workbook = new ExcelJS.Workbook();
    //     const worksheet = workbook.addWorksheet('Sheet1');
    
    //     const columns = [
    //         { title: 'Person Name', key: 'ownerName', width: 20 },
    //         { title: 'Responsible', key: 'responsible', width: 20 },
    //         { title: 'Accountable', key: 'accountable', width: 20 },
    //         { title: 'Consulted', key: 'consulted', width: 25 },
    //         { title: 'Informed', key: 'informed', width: 25 },
    //         { title: 'Total', key: 'total', width: 25 }
    //     ];
    
    //     // Add header row with styling
    //     const headerRow = worksheet.addRow(columns.map(column => column.title));
    //     headerRow.font = { bold: true };
    //     headerRow.eachCell((cell, colNumber) => {
    //         cell.fill = {
    //             type: 'pattern',
    //             pattern: 'solid',
    //             fgColor: { argb: 'FFFF00' }  // Yellow color
    //         };
    //         cell.border = {
    //             top: { style: 'thin' },
    //             left: { style: 'thin' },
    //             bottom: { style: 'thin' },
    //             right: { style: 'thin' }
    //         };
    //     });
    
    //     // Add data rows
    //     raci.forEach(item => {
    //         const responsibleCount = item.responsible?.assetCount || 0;
    //         const responsibleValue = item.responsible?.assetValue || 0;
    //         const accountableCount = item.accountable?.assetCount || 0;
    //         const accountableValue = item.accountable?.assetValue || 0;
    //         const consultedCount = item.consulted?.assetCount || 0;
    //         const consultedValue = item.consulted?.assetValue || 0;
    //         const informedCount = item.informed?.assetCount || 0;
    //         const informedValue = item.informed?.assetValue || 0;
    
    //         const totalCount = responsibleCount + accountableCount + consultedCount + informedCount;
    //         const totalValue = responsibleValue + accountableValue + consultedValue + informedValue;
    
    //         const rowData = [
    //             item.ownerName,
    //             `${responsibleCount.toLocaleString('en-IN')}/₹${responsibleValue.toLocaleString('en-IN')}`,
    //             `${accountableCount.toLocaleString('en-IN')}/₹${accountableValue.toLocaleString('en-IN')}`,
    //             `${consultedCount.toLocaleString('en-IN')}/₹${consultedValue.toLocaleString('en-IN')}`,
    //             `${informedCount.toLocaleString('en-IN')}/₹${informedValue.toLocaleString('en-IN')}`,
    //             `${totalCount.toLocaleString('en-IN')}/₹${totalValue.toLocaleString('en-IN')}`
    //         ];
    //         worksheet.addRow(rowData);
    //     });
    
    //     // Set column widths
    //     columns.forEach((column, index) => {
    //         worksheet.getColumn(index + 1).width = column.width;
    //     });
    
    //     const buffer = await workbook.xlsx.writeBuffer();
    //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     saveAs(blob, `RACI_MATRIX_${currentDate}.xlsx`);
    // };
    

    return (
        <>
            <Card title="RACI MATRIX (ORDER BY TOTAL ASSET VALUE DESC)" style={{
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', borderRadius: '8px',
                height: "450px",
                textAlign: 'center'
            }} headStyle={{ backgroundColor: '#114232', color: 'white' }} extra={<Tooltip
                title="Export As Excel"
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    height: '35px',
                    width: '35px'
                }}>
                    {/* <FileExcelOutlined style={{ fontSize: '25px', color: '#1D6F42' }} onClick={handleExport}/> */}
                    <img src={excel} style={{width:40,height:40}} alt=""  
                    // onClick={handleExport}
                     />
                </div>
            </Tooltip>}>

                <Card style={{ backgroundColor: '#AFD198', height: '50px' }}>
                    <Row>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>PersonName</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Responsible</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Accountable</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Consulted</h1>
                        </Col>
                        <Col span={4}>
                            <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Informed</h1>
                        </Col>

                        <Col span={4}>
                            <div>
                                <h1 style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: "15px" }}>Total</h1>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {raci.map((raci, index) => (
                        <>
                            <Card style={{
                                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                                height: '80px',
                                backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white',

                            }}
                                onMouseEnter={() => setHoveredCard(index)}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <Row key={index} gutter={16} style={{ borderStyle: 'solid', height: '70px', color: 'black', borderWidth: '0px', marginBottom: '10px', borderRadius: '5px', paddingTop: '9px' }}>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >
                                            {raci.ownerName}
                                        </p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >{parseFloat(raci?.responsible?.assetCount || 0).toLocaleString('en-IN')}/{parseFloat(raci?.responsible?.assetValue || 0).toLocaleString('en-IN')}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >{parseFloat(raci?.accountable?.assetCount || 0).toLocaleString('en-IN')}/₹{parseFloat(raci?.accountable?.assetValue || 0).toLocaleString('en-IN')}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >
                                            {parseFloat(raci?.consulted?.assetCount || 0).toLocaleString('en-IN')}/₹{parseFloat(raci?.consulted?.assetValue || 0).toLocaleString('en-IN')}
                                        </p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }} onMouseOver={(e) => {
                                            e.currentTarget.style.transform = 'scale(1.10)';
                                        }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}>
                                            {parseFloat(raci?.informed?.assetCount || 0).toLocaleString('en-IN')}/₹{parseFloat(raci?.informed?.assetValue || 0).toLocaleString('en-IN')}
                                        </p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold', }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.10)';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                            }}
                                        >
                                            {parseFloat((raci?.responsible?.assetCount || 0) + (raci?.accountable?.assetCount || 0) + (raci?.consulted?.assetCount || 0) + (raci?.informed?.assetCount || 0)).toLocaleString('en-IN')}/{parseFloat((raci?.responsible?.assetValue || 0) + (raci?.accountable?.assetValue || 0) + (raci?.consulted?.assetValue || 0) + (raci?.informed?.assetValue || 0)).toLocaleString('en-IN')}
                                        </p>
                                    </Col>

                                </Row>
                            </Card>
                        </>

                    ))}
                </div>
            </Card>
        </>
    )

}
export default RaciMatrix
