export const appSettings = {
   'masters_url': 'http://167.71.234.87:6021/erpx',
   'sales_url': 'http://206.189.138.212:5002/erpx',
   'procure_url': 'http://206.189.138.212:5003/erpx',
   'warehouse_url': 'http://206.189.138.212:5004/erpx',
   'logistics_url': 'http://206.189.138.212:5005/erpx',
   'ums_url': 'http://167.71.234.87:6022/erpx',
   'rm_procure_url': 'http://206.189.138.212:5007/erpx',
   'transport_url': 'http://206.189.138.212:5008/erpx',
   'production_url': 'http://206.189.138.212:6024/erpx',
   'assets_url': 'http://167.71.234.87:6020/erpx',
   'hrms_url': 'http://206.189.138.212:5011/erpx',
   'planning_url': 'http://206.189.138.212:5012/erpx',
   'anlytics_url': 'http://206.189.138.212:5013/erpx',
   'bulljobs_url': 'http://206.189.138.212:5014/erpx',
   'bulljobs_manager_url': 'http://206.189.138.212:5015/erpx',
   'vms_url':'http://206.189.138.212:5016/erpx',
   'cms_url': 'http://206.189.138.212:5017/erpx',
   'logs_url':'http://206.189.138.212:5018/erpx', 
   'dbhost': '167.71.234.87',
   'dbuser': 'varunasset',
   'dbpassword': 'asdASD123',
   // 'analytics_db_port': 3306,
   'analytics_db': 'erpx_dev_analytics',
   'masters_db': 'varun_asset_masters',
   'sales_db': 'erpx_dev_sales',
   'procure_db': 'erpx_dev_procurement',
   'warehouse_db': 'erpx_dev_warehouse',
   'logistics_db': 'erpx_dev_logistics',
   'ums_db': 'varun_asset_ums',
   'rm_procure_db': 'erpx_dev_rm_procurement',
   'transport_db': 'erpx_dev_transport',
   'production_db': 'erpx_dev_production',
   'assets_db': 'varun_asset_management',
   'hrms_db': 'erpx_dev_hrms',
   'planning_db': 'erpx_dev_planning',
   'vms_db': 'visitor_management',
   'cms_db': 'erpx_dev_cms',
   'logs_db':'erpx_dev_logs',
   'whatsapp_db':'whatsapp_bi',
   'masters_port': '6021',
   'sales_port': '5002',
   'procurement_port': '5003',
   'warehouse_port': '5004',
   'logistics_port': '5005',
   'ums_port': '6022',
   'rm_procurement_port': '5007',
   'transport_port': '5008',
   'production_port': '6024',
   'assets_port': '6020',
   'hrms_port': '5011',
   'planning_port': '5012',
   'analytics_port': '5013',
   'bulljobs_port': '5014',
   'bulljobs_manager_port': '5014',
   'vms_port':'5016',
   'cms_port': '5017',
   'logs_port':'5018',
   // 'file_upload_path': 'http://206.189.138.212/erpx/dist/apps/services/sale-service/', // live 
   'file_upload_path': 'http://206.189.138.212/erpx_dev/dist/apps/services/', // dev instance
   // 'logistics_doc_upload_path': 'http://206.189.138.212/erpx/dist/apps/services/logistics/', // live 
   'logistics_doc_upload_path': 'http://206.189.138.212/erpx_dev/dist/apps/services/logistics/', // dev instance
   // 'brand_file_path': 'http://206.189.138.212/erpx/dist/apps/services/masters/', // live
   'brand_file_path': 'http://206.189.138.212/erpx_dev/dist/apps/services/masters/', // dev instance
   // 'whatsapp_redirection_dev_url': 'http://dev.schemaxtech.in/#',
   // 'whatsapp_redirection_live_url': 'http://uat.schemaxtech.in/#',
   'whatsapp_redirection_url': 'http://dev.schemaxtech.in/#',
   'whatsapp_notification_url': 'http://206.189.138.212:3000',
   'whatsapp_broadcast_url': 'http://206.189.138.212:3232/api',
}

export default appSettings;