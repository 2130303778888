import { CardHeaderBody } from "./card-header-body";

export class ChartSeries{
    seriesChartDetails: CardHeaderBody[];
    stack:string[];
    constructor(seriesChartDetails: CardHeaderBody[],stack:string[]){
        this.seriesChartDetails = seriesChartDetails,
        this.stack = stack
    }
    
}