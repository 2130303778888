import { ApiProperty } from '@nestjs/swagger';
import { PoItemsDataModel } from './po-items-info.model';
import { PoVendorDataModel } from './po-vendor-model';
export class PoDataModel {
  purchaseOrderId: number;
  saleOrderId: number;
  vendor: PoVendorDataModel;
  totalQuantity: number;
  totalBasicPrice: number;
  totalDiscount: number;
  totalTax: number;
  totalCost: number;
  poCreationItemsInfo:PoItemsDataModel[];
  plantId:number;

  /**
   * @param purchaseOrderId
  * @param vendor
  * @param totalQuantity
  * @param totalBasicPrice
  * @param totalDiscount
  * @param totalTax
  * @param totalCost
  * @param poCreationItemsInfo
  * @param saleOrderId
   */
  constructor(purchaseOrderId: number,saleOrderId:number,vendor: PoVendorDataModel,totalQuantity: number,totalBasicPrice: number,totalDiscount: number,totalTax: number,totalCost: number,poCreationItemsInfo:PoItemsDataModel[],plantId:number){
    this.purchaseOrderId = purchaseOrderId;
    this.saleOrderId = saleOrderId;
    this.vendor = vendor;
    this.totalQuantity = totalQuantity;
    this.totalBasicPrice = totalBasicPrice;
    this.totalDiscount = totalDiscount;
    this.totalTax = totalTax;
    this.totalCost = totalCost;
    this.poCreationItemsInfo =poCreationItemsInfo;
    this.plantId =plantId;
  }
}

