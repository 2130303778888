export class AssetGridDto {
    assetId?: number;
    assetsName: number;
    assetsCode: string;
    assetSerialNo: string;
    itemId: number;
    grnId: number;
    invoice: string;
    noOfUnits: number;
    insurance: string;
    insuranceDate: any;
    manufacturer: string;
    model: string;
    year: any;
    assetSupplier: string;
    expiredDate: any;
    assetCost: number;
    dateOfPurchased: any;
    assetCategory: number;
    assetcondition: string;
    amc: string;
    amcname: string;
    amcno: string;
    ownerOfAsset: string;
    status: string;
    unitsOfMeasurements: any;
    currentLocation: string;
    lastCheckIn: Date;
    lastCheckOut: Date;
    warrenty: string;
    warrentyExpiredDate: any;
    employeeId: number;
    department: string;
    maintenanceDueDate: Date;
    transactionDate: any;
    itemName?: string;
    createdUser: string;
    updatedUser?: string;
    isActive: boolean;
    versionFlag?: number;
    decommissionApprovedBy?: string;
    serviceTenure?: string;
    insuranceProvidedBy?: string;
    plant?: any;
    amcReason?: string;
    itemSubCategoryName?: string;
    assetType?: string;
    assignedName?: string;
    departmentName?: string;
    serviceStatus?: string;
    serviceType?: string;
    amcProvider?: string;
    frequencyOfService?: string;
    month?: any;
    day?: string;
    aging?: string;
    plantName?: string;
    assetRentTenureType?: string;
    assetRentPeriod?: string;
    rentalCostType?: string;
    rentalCost?: number;
    assetMode?: string;
    assetAge?: any
    insuranceRefNum?: string;
    licenseNumber?: string;
    softAssetManufacturer?: string;
    hostingInfo?: string;
    hostType?: string;
    hostAddress?: string;
    location?: string;
    domainUrl?: string;
    userName?: string;
    password?: string;

    constructor(
        assetId: number,
        assetsName: number,
        assetsCode: string,
        assetSerialNo: string,
        itemId: number,
        grnId: number,
        noOfUnits: number,
        insurance: string,
        insuranceDate: any,
        manufacturer: string,
        model: string,
        year: any,
        assetSupplier: string,
        invoice: string,
        expiredDate: any,
        assetCost: number,
        dateOfPurchased: any,
        assetCategory: number,
        status: string,
        assetcondition: string,
        amc: string,
        amcname: string,
        amcno: string,
        ownerOfAsset: string,
        unitsOfMeasurements: any,
        currentLocation: string,
        lastCheckIn: Date,
        lastCheckOut: Date,
        warrenty: string,
        warrentyExpiredDate: any,
        employeeId: number,
        department: string,
        maintenanceDueDate: Date,
        transactionDate: any,
        createdUser: string,
        isActive: boolean,
        itemName?: string,
        updatedUser?: string,
        versionFlag?: number,
        decommissionApprovedBy?: string,
        serviceTenure?: string,
        insuranceProvidedBy?: string,
        plant?: any,
        amcReason?: string,
        itemSubCategoryName?: string,
        assetType?: string,
        assignedName?: string,
        departmentName?: string,
        serviceStatus?: string,
        serviceType?: string,
        amcProvider?: string,
        frequencyOfService?: string,
        month?: any,
        day?: string,
        aging?: string,
        plantName?: string,
        assetRentTenureType?: string,
        assetRentPeriod?: string,
        rentalCostType?: string,
        rentalCost?: number,
        assetMode?: string,
        assetAge?:any,
        insuranceRefNum?: string,
        licenseNumber?: string,
        softAssetManufacturer?: string,
        hostingInfo?: string,
        hostType?: string,
        hostAddress?: string,
        location?: string,
        domainUrl?: string,
        userName?: string,
        password?: string,

    ) {
        this.assetId = assetId;
        this.assetsName = assetsName;
        this.assetsCode = assetsCode;
        this.assetSerialNo = assetSerialNo;
        this.itemId = itemId;
        this.grnId = grnId;
        this.invoice = invoice;
        this.noOfUnits = noOfUnits;
        this.insurance = insurance;
        this.insuranceDate = insuranceDate;
        this.manufacturer = manufacturer;
        this.model = model;
        this.year = year;
        this.assetSupplier = assetSupplier;
        this.expiredDate = expiredDate;
        this.assetCost = assetCost;
        this.dateOfPurchased = dateOfPurchased;
        this.assetCategory = assetCategory;
        this.status = status;
        this.amc = amc;
        this.amcname = amcname;
        this.amcno = amcno;
        this.assetcondition = assetcondition;
        this.ownerOfAsset = ownerOfAsset;
        this.unitsOfMeasurements = unitsOfMeasurements;
        this.currentLocation = currentLocation;
        this.lastCheckIn = lastCheckIn;
        this.lastCheckOut = lastCheckOut;
        this.warrenty = warrenty;
        this.warrentyExpiredDate = warrentyExpiredDate;
        this.employeeId = employeeId;
        this.department = department;
        this.maintenanceDueDate = maintenanceDueDate;
        this.transactionDate = transactionDate;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.itemName = itemName;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.decommissionApprovedBy = decommissionApprovedBy;
        this.serviceTenure = serviceTenure;
        this.insuranceProvidedBy = insuranceProvidedBy;
        this.plant = plant;
        this.amcReason = amcReason;
        this.itemSubCategoryName = itemSubCategoryName;
        this.assetType = assetType;
        this.assignedName = assignedName;
        this.departmentName = departmentName;
        this.serviceStatus = serviceStatus;
        this.serviceType = serviceType;
        this.amcProvider = amcProvider;
        this.frequencyOfService = frequencyOfService;
        this.month = month;
        this.day = day;
        this.aging = aging;
        this.plantName = plantName;
        this.assetRentTenureType = assetRentTenureType;
        this.assetRentPeriod = assetRentPeriod;
        this.rentalCostType = rentalCostType;
        this.rentalCost = rentalCost;
        this.assetMode = assetMode;
        this.assetAge = assetAge;
        this.insuranceRefNum = insuranceRefNum;
        this.licenseNumber = licenseNumber;
        this.softAssetManufacturer = softAssetManufacturer;
        this.hostingInfo = hostingInfo;
        this.hostType = hostType;
        this.hostAddress = hostAddress;
        this.location = location;
        this.domainUrl = domainUrl;
        this.userName = userName;
        this.password = password;
    }

}
