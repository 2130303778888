import axios from 'axios';
import {
    AllPalletCapacityResponseModel,
    PalletCapacityReq,
  PalletCapacityDto,
  PalletCapacityResponseModel,
} from '@gtpl/shared-models/masters';
import connection from './connection';

export class PalletCapacityService {
  URL = connection.DEPLOY_URL + '/pallet-capacity';

  async createPalletCapacity(PalletCapacity: PalletCapacityDto): Promise<PalletCapacityResponseModel> {
    return await axios
      .post(this.URL + '/createPalletCapacity', PalletCapacity)
      .then((res) => {
        return res.data;
      });
  }
  async updatePalletCapacity(PalletCapacity: PalletCapacityDto): Promise<PalletCapacityResponseModel> {
    return await axios
      .post(this.URL + '/updatePalletCapacity', PalletCapacity)
      .then((res) => {
        return res.data;
      });
  }
  async getAllPalletCapacitys(): Promise<AllPalletCapacityResponseModel> {
    return await axios.post(this.URL + '/getAllPalletCapacitys')
        .then(res => {
            return res.data
        })
  }
  async activateOrDeactivatePalletCapacity(
    PalletCapacityDto: PalletCapacityDto
  ): Promise<PalletCapacityResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivatePalletCapacity', PalletCapacityDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActivePalletCapacitys(): Promise<AllPalletCapacityResponseModel> {
    return await axios.post(this.URL + '/getAllActivePalletCapacity').then((res) => {
      return res.data;
    });
  }
 
}
