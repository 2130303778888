import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { TransferActionDto } from './transfer-action.dto';


export class AllTransferActionResponseModel extends GlobalResponseObject{
    data?: TransferActionDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: TransferActionDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}