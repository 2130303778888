import { ApiProperty } from "@nestjs/swagger";

export class CodeListValidationReq{
    codeListMainId : number;
    isActive : boolean;
    issuedBy : string;
    verifiedBy : string;
    totalCartons : number;

    
}