import React, { useEffect, useState } from 'react';

import './bom-parameter.css';
import {BomParameterForm} from '@gtpl/pages/sale-management/sale-components/bom-parameter-form';
import { BomPackingItemsDto, CreateBomDTO, SaleOrderDetailDto, SaleOrderDropDownDto} from '@gtpl/shared-models/sale-management';
import { Button, Card, Form } from 'antd';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemCategoryService, SizesService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ItemCategoriesDto, SizesDropDownDto } from '@gtpl/shared-models/masters';
import { Link, Redirect, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { BomParameterGrid } from '@gtpl/pages/sale-management/sale-components/bom-parameter-grid';
import { render } from 'react-dom';

/* eslint-disable-next-line */
export interface BomParameterProps {
}
export class SaleOrderInfo {
  saleOrderId: number; 
  unitId: number;
}

export function BomParameter(
  props: BomParameterProps
) {
  
  let location = useLocation();
  const [storeReturnForm] = Form.useForm();
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
  const [itemCategoriesData, setItemCategoriesData] = useState<ItemCategoriesDto[]>([]);
  const [sizesData, setSetSizesData] = useState<SizesDropDownDto[]>([]);
  const [redirectView, setRedirectView] = useState<boolean>(false);
  const soDetails: SaleOrderInfo | any = location.state;
  const saleOrderId = soDetails?.saleOrderId;
  const unitId = soDetails?.unitId;

  const saleOrderService = new SaleOrderService();
  const itemCategoryService = new ItemCategoryService();
  const sizesService = new SizesService();

  const history = useHistory();
  const {id} = useParams<any>()
  // get sale order drop down details

  useEffect(() => {
    getSaleOrderData();
    // getItemCategories();
    // getSizes();
  }, []);

  const getSaleOrderData = () => {
    saleOrderService.getSaleOrdersDropDownList().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const saveBomDetails  =async (bomData : CreateBomDTO) => {
   await saleOrderService.saveBomPackingMaterialDetails(bomData).then(res => {
      if (!res.status) {
        throw new Error(res.internalMessage);
      }else{
        history.push('/allsaleorders-view')
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <Card title={<span style={{color:'white'}}>BOM Tracker</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
        extra={<Link to='/allsaleorders-view' ><Button className='panel_button' >View </Button></Link>}>
          <BomParameterForm saleOrderId={saleOrderId} unitId={unitId} saleOrderData= {saleOrderData} itemCategoriesData={itemCategoriesData} sizesData={sizesData} saveBomDetails={saveBomDetails}/>
    </Card>
      
  );
}

export default BomParameter;
