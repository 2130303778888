import { RmRackTypeEnum } from "@gtpl/shared-models/common-models";


export class RmRackDropDownDto {
    rmRackId:number;
    rmRackCode:string;
    zoneId?:number;
    rmRackType?:RmRackTypeEnum;
    cnt?:number;
    plantId?:number;

    // rackName:string;

    /**
     * 
     * @param rmRackId 
     * @param rmRackName 
     */
    constructor(rmRackId:number, rmRackCode:string, zoneId?:number,rmRackType?:RmRackTypeEnum,cnt?:number,plantId?:number){
        this.rmRackId = rmRackId;
        this.rmRackCode=  rmRackCode;
        this.zoneId=  zoneId;
        this.rmRackType=  rmRackType;
        this.cnt=  cnt;
        this.plantId=  plantId;
    }
}