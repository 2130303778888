import { OccupancyEnum } from "../enums";

export class PalletDto{
    palletId: number;
    palletNo: string;
    palletCode: string;
    assocRfId: string;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag?: number;
    occupancy?:OccupancyEnum;
    plantId?: number;

    /**
     * 
     * @param palletId
     * @param palletCode
     * @param plantId
     * @param palletNo
     * @param createdUser
     * @param isActive
     * @param versionFlag
     * @param occupancy
     * @param assocRfId
     
     */

    constructor(palletNo:string,palletCode:string, createdUser:string, isActive?:boolean, updatedUser?: string, palletId?:number,plantId?:number, versionFlag?: number, occupancy?:OccupancyEnum,assocRfId?:string){
        this.palletId = palletId;
        this.palletNo = palletNo;
        this.palletCode=palletCode;
        this.plantId = plantId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.occupancy = occupancy;
        this.assocRfId = this.assocRfId;
   
        
    }
}