import React, { lazy, useEffect, useState } from 'react';
import { Form, Select, Card, Row, Col, InputNumber, Button, Divider, Popconfirm, Modal, Input } from 'antd';
import { GlobalVariableValues, OperationTypeEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { GradingService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { LotCodeDropDown, LotNoInfo, LotInformationRequest, LotNumberRequest, GradeReportingRequest, WorkStationCategoryReq, WorkStationsDropDown, GradeLotNoRequest, GradeLotNoModel, GradingModel, WorkStationIdReq} from '@gtpl/shared-models/production-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './grade-reporting.css';
import Table, { ColumnProps } from 'antd/lib/table';
// import {GlobalVariableValues} from '@gtpl/'
import {EditOutlined,DeleteOutlined,PlusSquareOutlined,MinusSquareOutlined,SafetyCertificateOutlined} from '@ant-design/icons';
import { GradeMonitoringForm} from '@gtpl/pages/production-management/production-management-components/grade-monitoring-form'

const { Option } = Select;
/* eslint-disable-next-line */
export interface GradeReportingProps {}

export function GradeReporting(
  props: GradeReportingProps
) {
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const [gradeReportingForm] = Form.useForm();
  // const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  // const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(WorkStationTypeEnum.INTERNAL);
  const [workstation, setWorkstation] = useState<number>(null);
  const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(null);
  const [workStationCapacity, setWorkstationCapacity] = useState<number>(0);  
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const [lotGradeCounts, setLotGradeCounts] = useState<GradeLotNoModel>(null); 
  const [gradingData, setGradingData] = useState<GradingModel[]>([]);
  const [gradeLabel, setGradeLabel] = useState<string>('Add Grade');
  const [reportingType, setReportingType] = useState<string>('boxes');
  const [pendingToReport, setPendingToReport] = useState<number>(0); 
  const [disable, setDisable] = useState<boolean>(false)
  const [clearWipFlag, setClearWipFlag] = useState<boolean>(false); 
  const [tableKey, setTableKey] = useState<number>(0);
  const prodInvService = new ProductionInventoryService();
  const gradingService = new GradingService();
  const workstationService = new WorkstationService();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedInformation, setSelectedInformation] = useState<GradingModel>();
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Headless Count',
      dataIndex: 'grade',
      sorter: (a, b) => a.grade - b.grade,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Received Boxes',
      dataIndex: 'receivedBoxes',
    },
    {
      title: 'Received Quantity(Kgs)',
      dataIndex: 'receivedQuantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'No.Of Boxes',
      dataIndex: 'boxes',
      render: (text, rowData) =>(
        <span>
          <MinusSquareOutlined type="delete" 
            onClick= { () => {
                removeOne(rowData);
            }}
            style={{color: 'red', fontSize: '16px'}}
          />
          <Divider type="vertical" />
          {rowData.boxes}
          <Divider type="vertical" />          
          <PlusSquareOutlined className={'editSampleTypeIcon'} type="edit"
            onClick= { () => {
                addOne(rowData);
            }}
            style={{color: '#1890ff', fontSize: '16px'}}
            />
        </span>
      )
    },
    {
      title: 'Quantity(Kgs)',
      dataIndex: 'quantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title:`Action`,
      dataIndex: 'action',
      render: (text, rowData) =>(
        <span>
          <EditOutlined className={'editSampleTypeIcon'} type="edit"
            onClick= { () => {
                editCountAndBox(rowData);
            }}
            style={{color: '#1890ff', fontSize: '16px'}}
            />
          <Divider type="vertical" />
           <Popconfirm onConfirm={e =>{deleteCountAndBox(rowData);}}
           title={ 'Are you sure to delete count ?' }
          >
            <DeleteOutlined type="delete" style={{color: 'red', fontSize: '16px'}}/>
          </Popconfirm>

          <Divider type="vertical" />

           <SafetyCertificateOutlined
          style={{ color: 'red' }}
          onClick={() => displayModel(rowData, 'rmEvaluation')} />


        </span>
      )
    }
  ]

  useEffect(() => {
    getLotNumbers();  
    // gradeReportingForm.setFieldsValue({workStationType: WorkStationTypeEnum.INTERNAL,type:'boxes'}); 
    gradeReportingForm.setFieldsValue({type:'boxes'}); 
    // if(workStationType ===  WorkStationTypeEnum.INTERNAL){
    //   getWorkStations();
    // }
  }, []);



  // const checkWorkStationType = () => {
  //   const type = gradeReportingForm.getFieldValue('workStationType');
  //   setWorkStationType(type);
  //   if(type === WorkStationTypeEnum.INTERNAL){
  //     getWorkStations(); 
  //   }
  // }

  // const getWorkStations = () => {
  //   const catReq = new WorkStationCategoryReq();
  //   catReq.workstationCategory = WorkStationCategoryEnum.find((res) => res.name === "GRADING").value;
  //   catReq.unitId = Number(localStorage.getItem('unit_id'));
  //   workstationService.getWorkStationsForCategory(catReq).then((res) => {
  //     if (res.status) {
  //       setWorkStations(res.data);
  //     } else {
  //       setWorkStations([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setWorkStations([]);
  //   });
  // }

  // const checkWorkStation = () => {
  //   const workstation = gradeReportingForm.getFieldValue('workstation');
  //   if(workstation)getCapacityForPlantAndWorkstation(workstation);
  // }

  function onBlur() {
    console.log('blur');
  }
  
  function onFocus() {
    console.log('focus');
  }
  
  function onSearch(val) {
    console.log('search:', val);
  }
  const getLotNumbers = () => {
    gradingService.getLotNosForGrading({unitId: plantId}).then((res) => {
      
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }
  
  const getLotNumberInfo = (lotNumber) => {
    // alert('get Lot Numbers')
    if(lotNumber){
      gradingService.getLotLevelInfoForGrading(new GradeLotNoRequest(lotNumber,plantId)).then((res) => {
        if (res.status) {
          console.log(res.data)
          gradeReportingForm.setFieldsValue({ mainPlantId: res.data.mainPlantId });
          setLotGradeCounts(res.data);
          setGradingData(res.data.counts);
          setWorkStationType(res.data.workstationType);
          setWorkstation(res.data.workstation);
          getCapacityForPlantAndWorkstation(res.data.workstation);
          setPendingToReport(Number(res.data.wipWeight)-Number(res.data.currentWeight));
          if(Number(res.data.currentWeight)> Number(res.data.wipWeight)*0.9){
            setClearWipFlag(true);
          }
        } else {
          setLotGradeCounts(undefined);
          setGradingData([]);
          setPendingToReport(0);
          setClearWipFlag(false);
        }
      }).catch((err) => {
        setLotGradeCounts(undefined);
        setGradingData([]);
        setPendingToReport(0);
        setClearWipFlag(false);
        AlertMessages.getErrorMessage(err.message);
      });
    } else {
      setLotGradeCounts(undefined);
      setGradingData([]);
      setPendingToReport(0);
      setClearWipFlag(false);
    }
  }

  const getCapacityForPlantAndWorkstation = (workstationId) => {
    workstationService.getCapacityForPlantAndWorkstation(new WorkStationIdReq(workstationId,plantId)).then((res) => {
      if (res.status) {
        setWorkstationCapacity(Number(res.data));
      } 
    }).catch((err) => {
      setWorkstationCapacity(0);
      AlertMessages.getErrorMessage(err.message);
    });
  }
  const handleReportingType = () => {
    const reportingType = gradeReportingForm.getFieldValue('type');
    if(reportingType === 'boxes'){
      setReportingType('boxes');
    } else if(reportingType === 'quantity') {
      setReportingType('quantity');
    } else {
      setReportingType('boxes');
    }    
  }

  const addGradeToGrid = () => {
    gradeReportingForm.validateFields().then(values=>{
      let validationFlag = true
      const validateDuplicateCount = gradingData.filter(item => (item.grade === gradeReportingForm.getFieldValue('grade') && item.type === gradeReportingForm.getFieldValue('type')))
      const sameGradeData = gradingData.filter(item => (item.grade === gradeReportingForm.getFieldValue('grade') ))
      console.log(validateDuplicateCount)
      if(validateDuplicateCount.length > 0 && gradeLabel == 'Add Grade'){
        AlertMessages.getErrorMessage('Item alresy exist.');
        gradeReportingForm.setFieldsValue({grade:null,boxes:null})
      }else{
        const reportingType = gradeReportingForm.getFieldValue('type');   
        const headLessCnt = gradeReportingForm.getFieldValue('grade');
        const boxesCnt = gradeReportingForm.getFieldValue('boxes');    
        const quantity = gradeReportingForm.getFieldValue('quantity');
        let qty = 0;
        let boxes = 0;
        let opBoxWeight
        if(headLessCnt <= 50){
          opBoxWeight = GlobalVariableValues.GradingOutputBelow50
        }else{
          opBoxWeight = GlobalVariableValues.GradingOutputAbove50
        }
        if(reportingType === 'boxes'){
          let prevboxes = 0
          let prevqty = 0
          if(sameGradeData.length > 0 && gradeLabel == 'Add Grade'){
            console.log(sameGradeData)
            prevboxes = Number(sameGradeData[0].boxes)
            prevqty = Number(sameGradeData[0].quantity)
          }
          if(boxesCnt){
            boxes = prevboxes+boxesCnt;
            qty = prevqty+(boxesCnt*Number(opBoxWeight))
            // qty =  boxesCnt * workStationCapacity;
          }
        } else if(reportingType === 'quantity'){
          let prevboxes = 0
          let prevqty = 0
          if(sameGradeData.length > 0 && gradeLabel == 'Add Grade'){
            console.log(sameGradeData)
            prevboxes = Number(sameGradeData[0].boxes)
            prevqty = Number(sameGradeData[0].quantity)
          }
          if(quantity && quantity <= opBoxWeight*1.5){
            boxes = prevboxes+1;
            qty =  prevqty+quantity;
          }else{
            validationFlag = false
            AlertMessages.getErrorMessage('quantity should not exceed 150 percentage than box weight');
            gradeReportingForm.setFieldsValue({grade:null,quantity:null})
          }
        }
        if(validationFlag){
          let totalQty = 0
          // if(gradeLabel == 'Add Grade'){
          //   totalQty = qty;
          // }
          // console.log(totalQty)
          console.log(gradingData)
          
         
            // console.log(totalQty)
    
          // if(totalQty <= (Number(lotGradeCounts.wipWeight)+(Number(lotGradeCounts.wipWeight)*0.05)) && totalQty >= (Number(lotGradeCounts.wipWeight)-(Number(lotGradeCounts.wipWeight)*0.05))) {
            if(gradingData.find(row => row.grade === headLessCnt)){
              gradingData.map((count) => {
                if(count.grade === headLessCnt){
                  count.boxes = boxes;
                  count.quantity = qty;
                  count.type = reportingType;
                  return count;
                }
              });
              
              setGradingData(gradingData);
              setTableKey(tableKey+1);
            } else {
              gradingData.push(new GradingModel(headLessCnt,boxes,qty,0,0,reportingType));
            }
            if(gradingData.length > 0){
              gradingData.map((count) => {
                console.log(count.receivedQuantity)
                totalQty+=Number(count.quantity)
              }
                );
            }

            gradeReportingForm.setFieldsValue({grade: '',boxes:'',type:'boxes',quantity:''});
            setReportingType('boxes');
            setGradingData(gradingData);
            setGradeLabel('Add Grade');
            setPendingToReport((Number(lotGradeCounts?.wipWeight))-(Number(lotGradeCounts?.currentWeight)+Number(totalQty)));
            setTableKey(tableKey+1);
          // } else {
          //   AlertMessages.getErrorMessage("Reporting Qty should be in + or - 5% of Prev Op Qty");
          // }    
        }
        
      }
      
    });
    
  }

  const displayModel = (data: GradingModel, type) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    // getGrnDetails();
    setIsModalVisible(false);
  };


  const editCountAndBox = (rowData:GradingModel) => {
    gradeReportingForm.setFieldsValue({grade: rowData.grade,boxes:rowData.boxes,quantity:rowData.quantity,type:'boxes'});
    setGradeLabel('Update Grade');
  }

  const deleteCountAndBox = (rowData:GradingModel) => {
    const updatedGradingData = gradingData.filter((grade) => {
      return grade.grade !== rowData.grade;
    })
    setGradingData(updatedGradingData);
    setTableKey(tableKey+1);    
  }

  const addOne = (rowData:GradingModel) => {
    console.log(rowData)
    rowData.boxes+=1;
    const headLessCnt = rowData.grade
    let opBoxWeight
      if(headLessCnt <= 50){
        opBoxWeight = GlobalVariableValues.GradingOutputBelow50
      }else{
        opBoxWeight = GlobalVariableValues.GradingOutputAbove50
      }
    // rowData.quantity = rowData.boxes * workStationCapacity;
    rowData.quantity = rowData.quantity+(1 * opBoxWeight);
    const prevpendingQty = pendingToReport
    setPendingToReport(prevpendingQty-(1 * opBoxWeight))
    setTableKey(tableKey+1);    
  }

  const removeOne = (rowData:GradingModel) => {
    rowData.boxes-=1;
    if(rowData.boxes<0){
      rowData.boxes = 0;
    }   
    const headLessCnt = rowData.grade
    let opBoxWeight
      if(headLessCnt <= 50){
        opBoxWeight = GlobalVariableValues.GradingOutputBelow50
      }else{
        opBoxWeight = GlobalVariableValues.GradingOutputAbove50
      } 
    // rowData.quantity = rowData.boxes * workStationCapacity;
    rowData.quantity = rowData.quantity-(1 * opBoxWeight);
    const prevpendingQty = pendingToReport
    setPendingToReport(prevpendingQty+(1 * opBoxWeight))
    setTableKey(tableKey+1);       
  }

  const saveCountInGradeInventory = () => {
    setDisable(true)
    const requestData = new GradeReportingRequest(gradeReportingForm.getFieldValue('shift'),plantId,gradeReportingForm.getFieldValue('lotNumber'),workStationType,workstation,gradingData,lotGradeCounts?.soNumber,createdUser,gradeReportingForm.getFieldValue('mainPlantId'));
    gradingService.saveCountInGradeInventory(requestData).then((res) => {
      setDisable(false)
      if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          cancelData();
          // gradeReportingForm.setFieldsValue({workStationType: WorkStationTypeEnum.INTERNAL}) 
          // if(workStationType ===  WorkStationTypeEnum.INTERNAL){
          //   getWorkStations();
          // }
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    });   
  }

  const cancelData = () => {
    gradeReportingForm.resetFields();  
    // gradeReportingForm.setFieldsValue({workStationType: WorkStationTypeEnum.INTERNAL,type:'boxes'}); 
    gradeReportingForm.setFieldsValue({type:'boxes'}); 
    setLotGradeCounts(undefined);
    setGradingData([]); 
    setWorkstation(null);
    setWorkStationType(null);
    setPendingToReport(0);
    setTableKey(0); 
    setClearWipFlag(false);   
  }

  const clearWip = () => {
    gradingService.clearWip(new GradeLotNoRequest(gradeReportingForm.getFieldValue('lotNumber'),plantId)).then((res) => {
      if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getLotNumbers();
          cancelData();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });   
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Grading Reporting</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout={'vertical'} form={gradeReportingForm} name="control-hooks">
        <Row gutter={24}>
        <Form.Item name="mainPlantId" >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Missing Shift' }]} >
              <Select
                placeholder="Select Shift"
                allowClear
                
              >                
                <Option key={0} value={null}>Select Shift</Option>
                {Object.keys(ShiftsEnum).map((type)=>{
                  return <Option value={type}>{ShiftsEnum[type]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="workStationType" label="Workstation Type" rules={[{ required: true, message: 'Missing Workstation Type' }]}>
              <Select
                placeholder="Select Workstation Type"
                onChange={checkWorkStationType}
                defaultValue = {workStationType}
              >
                <Option key={0} value={null}>Select Workstation Type</Option>
                {Object.keys(WorkStationTypeEnum).map((type)=>{
                  return <Option value={WorkStationTypeEnum[type]}>{WorkStationTypeEnum[type]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.INTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="workstation" label="Workstation" rules={[{ required: (workStationType == WorkStationTypeEnum.INTERNAL) ? true : false, message: 'Missing Workstation' }]} >
              <Select
                placeholder="Select Workstation"
                allowClear
                onChange={checkWorkStation}
              >
                {workStations.map(dropData => {
                  return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.EXTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="subPlantId" label="Plant" rules={[{ required: (workStationType == WorkStationTypeEnum.EXTERNAL) ? true : false, message: 'Missing Plant' }]} >
              <Select
                placeholder="Select Plant"
                allowClear
              >
                <Option key={0} value={null}>Select Plant</Option>
                {Object.keys(SubPlantCodesEnum).map((type)=>{
                  return <Option value={SubPlantCodesEnum[type]}>{SubPlantCodesEnum[type]}</Option>
                })}
              </Select>
            </Form.Item>

          </Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }}>
            <Form.Item name="lotNumber" label="Lot Code" rules={[{ required: true, message: 'Missing Lot No' }]}>
              <Select
                showSearch
                placeholder="Select Lot No"
                optionFilterProp="children"
                onChange={getLotNumberInfo}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.lotNumber} value={dropData.lotNumber}>{dropData.concatLotNum}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} offset={8} style={{ display: (clearWipFlag)?"block":"none" }}>
            <Button type="primary" onClick={clearWip}>
              Clear WIP
            </Button>
          </Col>
        </Row>
        <br />
        {(lotGradeCounts)?
        <Row
          gutter={40}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col>
            <Card
              title={'Head on Count:'+lotGradeCounts.headOnCount}
              style={{
                textAlign: 'left',
                width: 'fit-content',
                height: 41,
                backgroundColor: '#52c41a',
              }}
            ></Card>
          </Col>
          <Col>
            <Card
              title={'Current Weight :'+lotGradeCounts.currentWeight+'kgs'}
              style={{
                textAlign: 'left',
                width: 'fit-content',
                height: 41,
                backgroundColor: '#f8f838',
              }}
            ></Card>
          </Col>
          <Col>
            <Card
              title={'Prev Op Weight :'+lotGradeCounts.wipWeight+'kgs'}
              style={{
                textAlign: 'left',
                width: 'fit-content',
                height: 41,
                backgroundColor: '#f8a238',
              }}
            ></Card>
          </Col>
          <Col>
            <Card
              title={'Pending to Report :'+pendingToReport.toFixed(3)+'kgs'}
              style={{
                textAlign: 'left',
                width: 'fit-content',
                height: 41,
                backgroundColor: (pendingToReport>=0)?'#2b9212bf':'#ff1818',
              }}
            ></Card>
          </Col>
        </Row>:''}
        <br/>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="type" label="Reporting Type" rules={[{ required: true, message: 'Missing Reporting Type' }]}>
              <Select
                placeholder="Select Reporting Type"
                defaultValue = 'boxes'
                onChange={handleReportingType}
              >
                <Option key='boxes' value='boxes'>Boxes</Option>
                <Option key='quantity' value='quantity'>Quantity</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="grade" label="Headless Count" rules={[{ required: true, message: 'Missing Count' }]}>
              <InputNumber style={{ width: '100%' }} min={0} readOnly={(gradeLabel==='Update Grade')?true:false}/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: (reportingType === 'boxes') ? 'block' : 'none' }}>
            <Form.Item name="boxes" label="No. Of. Boxes" rules={[{ required: (reportingType=== 'boxes') ? true : false, message: 'Missing No. Of. Boxes' }]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: (reportingType === 'quantity') ? 'block' : 'none' }}>
            <Form.Item name="quantity" label="Quantity" rules={[{ required: (reportingType=== 'quantity') ? true : false, message: 'Missing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 3 }} sm={{ span: 3 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3}}>
            <Form.Item style={{ paddingTop: '30px' }}>
              <Button type='primary' block onClick={addGradeToGrid}>
                {gradeLabel}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        key ={tableKey}
        columns={columnsSkelton}
        dataSource={gradingData}
        pagination = {false}
        bordered
      />
      <Row gutter={24}>
        <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
          <Form.Item style={{ paddingTop: '30px' }}>
            <Button type='primary'disabled={disable} block onClick={saveCountInGradeInventory}>
            Submit
            </Button>
          </Form.Item>
        </Col>
        <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
          <Form.Item style={{ paddingTop: '30px' }}>
            <Button type='primary' block onClick={cancelData} danger>
            Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Modal
        className='grade-monitoring'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
       <GradeMonitoringForm 
       isUpdate = {false}
       gradingInfo={selectedInformation}
       gradingLotNo={lotGradeCounts}
       handleCancel={handleCancel}
        />

        
      </Modal>
    </Card>
  );
}

export default GradeReporting;
