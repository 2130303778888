export class CustomersDropDownData{
    customerId:number;
    customerName:string;
    customerAddress:string[];
    customerPaymentTerms:string;
    customershipmentTerms:string;
    customerdeliveryTerms:string;
    
    
    /**
     * 
     * @param customerId 
     * @param customerName 
     * @param customerAddress
     * @param customerPaymentTerms
     * @param customershipmentTerms;
     * @param customerdeliveryTerms;
     */
    constructor(customerId:number,customerName:string,customerAddress:string[],customerPaymentTerms:string,customershipmentTerms:string,customerdeliveryTerms:string){
      this.customerId = customerId;
      this.customerName = customerName;
      this.customerAddress= customerAddress;
      this.customerPaymentTerms= customerPaymentTerms;
      this.customershipmentTerms= customershipmentTerms;
      this.customerdeliveryTerms= customerdeliveryTerms;
    }
}