import React, { useState, useEffect, useRef } from 'react';
import { Table, Card, Select, Form, Button, Row, Col, Input, Divider, Tooltip, Modal, Pagination } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import './grading-inventory-grid.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GradingInventoryModel, ProductionInventoryDetails } from '@gtpl/shared-models/production-management';
import { GradingService } from "@gtpl/shared-services/production";
import { SearchOutlined, DeliveredProcedureOutlined, DoubleRightOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import { StockTransfer } from '@gtpl/pages/production-management/production-management-components/stock-transfer';
import Link from 'antd/lib/typography/Link';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { LotDetailView } from '@gtpl/pages/production-management/production-management-components/lot-detail-view';
/* eslint-disable-next-line */
export interface GradingInventoryGridProps { }


export function GradingInventoryGrid(props: GradingInventoryGridProps) {
  const gradingService = new GradingService();
  const plantId = JSON.parse(localStorage.getItem('unit_id'));
  const [gradingInv, setGradingInv] = useState<GradingInventoryModel[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedInformation, setSelectedInformation] = useState<ProductionInventoryDetails>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saleId , setSaleId]= useState(0);
  const [lotINo, setLotINo] = useState('lotNumber');
  useEffect(() => {
    getGradingInventory();
  }, []);
  
  const getGradingInventory = () => {
    const unitIdReq :UnitRequest ={
      unitId:plantId
    }
    gradingService.getGradingInventory({ unitId: plantId }).then((res) => {
      if (res.status) {
        setGradingInv(res.data);
      }else {
        if(res.intlCode){
          setGradingInv([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  /**
   * 
   * @param dataIndex 
   */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });
/**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  
  const handleCancel = () => {
    getGradingInventory();
    setIsModalVisible(false);
  };
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const goToLotDetailView = (lotNumber: string) => {
    setLotINo(lotNumber);
    console.log("lotNo", lotNumber);
    setIsModalVisible(true)
  }


  const issuedQty = gradingInv.reduce(
    function (previousValue, currentValue, index, array) {
      return previousValue + Number(currentValue.issuedQty)
    }, 0
  );

  const physicalQty = gradingInv.reduce(
    function (previousValue, currentValue, index, array) {
      return previousValue + Number(currentValue.quantity)
    }, 0
  );
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      sorter: (a, b) => { a = a.soNumber || ''; b = b.soNumber || ''; return a.localeCompare(b)},
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
      // render: (value, record) => 
      // <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Link>
    
  
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => { a = a.poNumber || ''; b = b.poNumber || ''; return a.localeCompare(b)},
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
      
    },
    
    {

      title: 'Lot Code',
      dataIndex: 'concatLotNum',
      width:'230px',
      sorter: (a, b) => a.concatLotNum.length - b.concatLotNum.length || a.subconcatLotNum.localeCompare(b.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('concatLotNum'),
      render: (value, record) =>
        <Link onClick={e => goToLotDetailView(record.lotNo)} >{record.concatLotNum} </Link>
    },
    {
      title: 'Sub LOT Code',
      dataIndex: 'subLotNo',
      sorter: (a, b) => a.subLotNo.length - b.subLotNo.length || a.subLotNo.localeCompare(b.subLotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('subLotNo'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity(Kgs)',
      dataIndex: 'quantity',
      render: (val) => (<span style={{ float: 'right' }}>{val}</span>)
    },
    {
      title: 'Next Operation',
      dataIndex: 'nextOperation',
      // sorter: (a, b) => a.nextOperation.length - b.nextOperation.length || a.nextOperation.localeCompare(b.nextOperation),
      sorter: (a, b) => {
        if(a && a.nextOperation && a.nextOperation.length && b && b.nextOperation && b.nextOperation.length) {
            return a.nextOperation.length - b.nextOperation.length;
        } else if(a && a.nextOperation && a.nextOperation.length) {
            // That means b has null nextOperation, so a will come first.
            return -1;
        } else if(b && b.nextOperation && b.nextOperation.length) {
            // That means a has null nextOperation so b will come first.
            return 1;
        }
    
        // Both nextOperation has null value so there will be no order change.
        return 0;
    },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('nextOperation'),
    },
    {
      title: 'Issued Quantity(Kgs)',
      dataIndex: 'issuedQty',
      render: (val, rowData) => (<span style={{ float: 'right' }}>{val}</span>)
    },
  ];

  const displayModel = (data: GradingInventoryModel) => {
    const inventoryData = new ProductionInventoryDetails(data.lotNo, data.subLotNo, data.soNumber, 'product', 0, data.issuedQty, data.quantity, 0, 0, 0, 0, 0, 0, OperationTypeEnum.GRADING, 'variantCode', 0, 0, 1, 0)
    setSelectedInformation(inventoryData);
    setIsModalVisible(true);
  };

  /**
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
 const onChange =(Pagination,filters, sorter, extra) => {
   console.log('params',Pagination, filters, sorter,extra)
 }
  return (
    <Card
      title={<span style={{ color: 'white' }}>Graded Inventory</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Row gutter={40}>
        <Col>
          <Card
            title={'No. of Lots Available:' + gradingInv.length}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity To be Processed:' + issuedQty}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Graded Inventory:' + physicalQty}
            style={{
              textAlign: 'left',
              width: 250,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Table
        columns={columnsSkelton}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              {/* <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell> */}
              {/* <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell> */}
              <Table.Summary.Cell index={3} colSpan={4} >
                <div style={{ textAlign: "center" }}> {"Total"} </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                {(physicalQty).toFixed(3) + 'Kgs'}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        dataSource={gradingInv}
        bordered
      />
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen="production" />
        <LotDetailView lotNumber={lotINo} />
        {/* <StockTransfer operation={OperationTypeEnum.GRADING} inventoryInfo={selectedInformation} /> */}
      </Modal>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}></Row>
    </Card>
  );
}

export default GradingInventoryGrid;
