import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { AttendanceModel } from "./attendance.model";

export class AttendanceAdjustResponse extends GlobalResponseObject {
    data?: AttendanceModel;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AttendanceModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}