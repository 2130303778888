import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, TimePicker, Drawer, AutoComplete, Collapse, Descriptions, Switch, Radio, Space, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { LabellingDto, LotCodeDropDown, VarientIdRequest, LabelIdRequest } from '@gtpl/shared-models/production-management';
import { CountryDto, MasterBrandsDropDownDto, ProductDto } from '@gtpl/shared-models/masters';
import { ProductService, MasterBrandService, CountryService } from '@gtpl/shared-services/masters'
import { SaleOrderService, saleOrder } from '@gtpl/shared-services/sale-management';
import { LabellingService, LotCodeOpsService } from '@gtpl/shared-services/production'


import './labelling-form.css';
import { ItemsDetails, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { SoItemDetailsBySoIdDTO } from 'libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto';
import moment from 'moment';
import { GlobalStatus, LabellingInput, UnitRequest } from '@gtpl/shared-models/common-models';
import { GrnData } from '@gtpl/shared-models/raw-material-procurement';
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';

/* eslint-disable-next-line */
export interface LabellingFormProps {
  labellingData: LabellingDto;
  isUpdate: boolean;
  closeForm: () => void;
}

export function LabellingForm(
  props: LabellingFormProps
) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Option } = Select;
  const countriesService = new CountryService
  const [countriesData, setCountriesData] = useState<CountryDto[]>([]);
  const [grnData, setGrnData] = useState();
  const labellingService = new LabellingService()
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [soItemName, setSoItemName] = useState<ItemsDetails[]>([]);
  const [soItemDetails, setSoItemDetails] = useState<SoItemDetailsBySoIdDTO>(undefined);
  const [labellingSamplesInfo] = Form.useForm()
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const lotCodeService = new LotCodeOpsService();
  const soItemService = new SaleOrderService;
  const [certification, setCertification] = useState<boolean>(false);
  const plainOptions = ['BAP', 'ASC', 'MSC'];
  const [dataSource, setDataSource] = useState([]);
  const [disable, setDisable] = useState<boolean>(false)
  const [labellingData, setLabellingData] = useState<any[]>([]);

  const rmService = new RMGrnService();
  let history = useHistory();

  useEffect(() => {

    labellingSamplesInfo.setFieldsValue({ certificationLogo: 'BAP', shrimpsLabel: true, allergenLabel: true });
    if (props.isUpdate) {
      form.setFieldsValue({
        labellingId: props.labellingData.labellingId,
        labellingDate: moment(props.labellingData.labellingDate),
        saleOrderId: props.labellingData.saleOrderId,
        saleOrderItemId: props.labellingData.saleOrderItemId,
        traceabilityCode: props.labellingData.traceabilityCode,
        country: props.labellingData.country,
        lotNumber: props.labellingData.lotNumber,
        totalMCs: props.labellingData.totalMCs,
        updatedUser: localStorage.getItem('createdUser'),
      });
      getAllLabellingSamplesForLabelId();
      getAllLabelling();
    } else {
      getSaleOrders();
      getAllActiveCountries();
      getAllLabellingSamplesForLabelId()
    }
  }, [])

  console.log("datas", props.labellingData)

  const getAllLabellingSamplesForLabelId = () => {
    const labelRequest = new LabelIdRequest();
    labelRequest.labellingId = props.labellingData?.labellingId
    labellingService.getAllLabellingSamplesForLabelId(labelRequest).then(res => {
      if (res.status) {
        setDataSource(res.data.labellingSamplesInfo);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllActiveCountries = () => {
    countriesService.getAllActiveCountries().then(res => {
      if (res.status) {
        setCountriesData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSaleOrders = () => {
    setSoItemDetails(undefined);
    lotCodeService.getSaleOrders().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getAllSoItemsDropDown = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    console.log(saleOrderId)
    form.setFieldsValue({
      saleOrderItemId: ''
    })
    const soREq: saleOrder = {
      saleOrderId: saleOrderId
    }
    lotCodeService.getSaleOrderItemsForSo(soREq).then((res) => {
      if (res.status) {
        setSoItemName(res.data);
      } else {
        AlertMessages.getErrorMessage("Something went wrong");
        setSoItemName([]);
      }
    })
      .catch((err) => {
        setSoItemName([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };


  const getSaleOrderItemDetails = () => {
    setSoItemDetails(undefined);
    const saleOrderId = form.getFieldValue('saleOrderId');
    const saleOrderItemId = form.getFieldValue('saleOrderItemId');
    soItemService.getSaleOrderItemDetailsBySoItemId({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId }).then((res) => {
      if (res.status) {
        setSoItemDetails(res.data);
        form.setFieldsValue({
          brand: res.data.brand,
          packing: res.data.packingStyle,
          product: res.data.productName
        })
      }
      else {
        if (res.intlCode) {
          setSoItemDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoItemDetails(null);
        AlertMessages.getErrorMessage(err.message);
      });
  }

  const getAllLotNumbersForSoItem = () => {
    const saleOrderId = form.getFieldValue('saleOrderId');
    const saleOrderItemId = form.getFieldValue('saleOrderItemId');
    lotCodeService.getAllLotNumbersForSoItem({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId, plantId: Number(localStorage.getItem('unitId')) }).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getAllLabelling = () => {

    labellingService.getAllLabellings().then(res => {
      if (res.status) {
        setLabellingData(res.data);
      } else {
        if (res.intlCode) {
          setLabellingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setLabellingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }






  const saveLabelling = () => {
    setDisable(true)
    form.validateFields().then(mainForm => {
      labellingSamplesInfo.validateFields().then(labelSamples => {
        mainForm.labellingSamplesInfo = (props.isUpdate) ? [...dataSource, labelSamples] : [labelSamples];
        labellingService.createLabellingInfo(mainForm).then(res => {
          setDisable(false)
          if (res.status) {
            AlertMessages.getSuccessMessage('Labelling along with samples');
            if (!props.isUpdate){
            history.push('/labelling-grid')
            }
            if (props.isUpdate) {
              getAllLabellingSamplesForLabelId();
            }
           
          } else {
            AlertMessages.getErrorMessage("Labelling Already Exists");
            if (res.intlCode == 11104) {
              AlertMessages.getErrorMessage("Labelling Already Exists");
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        })
      })
    })
    getAllLabellingSamplesForLabelId();
  }


  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const onReset = () => {
    form.resetFields();
  };

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const certificationChange = (value) => {
    if (value) {

    }
  }

  const columns = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      key: 'sno',
      render: (value, record, index) => ++index
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (value) => (moment(value).format("hh:mm:ss"))
    },
    {
      title: 'Details of Shrimps(units/bags)',
      dataIndex: 'shrimpsLabel',
      key: 'shrimpsLabel',
      render: (value) => {
        return value ? 'Yes' : 'No'
      }
    },
    {
      title: 'Details of Allergen(units/bags)',
      dataIndex: 'allergenLabel',
      key: 'allergenLabel',
      render: (value) => {
        return value ? 'Yes' : 'No'
      }
    },
    {
      title: 'Certification Logo',
      dataIndex: 'certificationLogo',
      key: 'certificationLogo',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: 'Corrective Action',
      dataIndex: 'correctiveAction',
      key: 'correctiveAction',
    },
    {
      title: 'Checked By',
      dataIndex: 'checkedBy',
      key: 'checkedBy',
    },

  ];

  const getTraceabilityCodeForLot = (lotNumber) => {
    if (lotNumber) {
      rmService.getTraceabilityCodeForLot({ lotNumber: lotNumber }).then(res => {
        if (res.status) {
          setGrnData(res.data);
          form.setFieldsValue({traceabilityCode:res.data.supplierName+"/"+res.data.supplierCode})
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }


  return (
    <Card title={<span style={{ color: 'white' }}>Labelling Form</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      // extra={<Link to='/labelling-grid'>{<span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>}</Link>}
    >
      <Descriptions style={{ display: (props.isUpdate) ? 'unset' : 'none' }}>
        <Descriptions.Item label="Labelling Date">
          {props.labellingData?.labellingDate ? moment(props.labellingData?.labellingDate).format("DD-MM-YYYY") : ''}
        </Descriptions.Item>
        <Descriptions.Item label="SO number">
          {props.labellingData?.soNumber ? props.labellingData?.soNumber : ''}
        </Descriptions.Item>
        <Descriptions.Item label="SO Item">
          {props.labellingData?.item ? props.labellingData?.item : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Lot">
          {props.labellingData?.lotNumber ? props.labellingData?.lotNumber : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Traceability code">
          {props.labellingData?.traceabilityCode ? props.labellingData?.traceabilityCode : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          {props.labellingData?.country ? props.labellingData?.country : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Total MC's">
          {props.labellingData?.totalMCs ? props.labellingData?.totalMCs : ''}
        </Descriptions.Item>
      </Descriptions>
      <Form form={form} name="control-hooks"
        layout="vertical"
      >
        <div style={{ display: props.isUpdate ? 'none' : 'block' }}>
          <Row gutter={24} >
            <Form.Item name="labellingId" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="createdUser" initialValue={createdUser}>
              <Input hidden />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="labellingDate"
                label="Date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: 'Date is Required'
                  },
                ]}
              >
                <DatePicker showToday={true} style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="saleOrderId" label="SO number" rules={[
                {
                  required: true,
                  message: 'Sale Order is Required'
                },
              ]}>
                <Select
                  showSearch
                  placeholder="Select Sale Order"
                  optionFilterProp="children"
                  onChange={getAllSoItemsDropDown}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  onClear={onReset}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Sale Order</Option>
                  {saleOrderData.map((saleOrder) => {
                    return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.saleOrderNumber}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>

              <Form.Item
                name="saleOrderItemId"
                label="SO Item"
                rules={[
                  {
                    required: true,
                    message: 'Sale Order Item is Required'
                  },
                ]}
              >
                <Select
                  placeholder="Select Sale Order Item."
                  onChange={getSaleOrderItemDetails}
                  onSelect={getAllLotNumbersForSoItem}
                  // onClear={resetAll}
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  {soItemName.map((dropData) => {
                    return (
                      <Option name="saleOrderItemId" key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}
                      >
                        {dropData.item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="lotNumber" label="Lot No" rules={[{ required: true, message: 'Missing Lot No' }]}>
                <Select
                  placeholder="Select Lot No"
                  allowClear
                  onSelect={getTraceabilityCodeForLot}
                >
                  <Option key={0} value={null}>Select Lot</Option>
                  {lotNumbers.map((lot) => {
                    return <Option key={lot.lotCodeOpsId} value={lot.lotNumber}>{lot.lotNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="traceabilityCode"
                label="Traceability Code"
                rules={[
                  {
                    required: true,
                    message: 'Traceability Code Required'
                  },
                ]}

              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true, message: 'Country is required'

                  },

                ]}>
                <Select
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  // onChange={handleOperation}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Country</Option>
                  {countriesData.map((product) => {
                    return <Option key={product.countryId} value={product.countryName}>{product.countryName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="totalMCs"
                label="Total MC's"
                rules={[
                  {
                    required: true,
                    message: 'Packing Required'
                  },
                  {
                    pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z\\0-9\[\]()@#$_\-+/\s-]*$/,
                    message: `Invalid input`
                  },

                ]}
              >
                <Input />
              </Form.Item>
            </Col>

          </Row>
        </div>
        <Row gutter={24} style={{ display: (soItemDetails) ? 'unset' : 'none' }}>
          <Descriptions>
            <Descriptions.Item label="Product">
              {soItemDetails?.productName}
            </Descriptions.Item>
            <Descriptions.Item label="Packing">
              {soItemDetails?.packingStyle}
            </Descriptions.Item>
            <Descriptions.Item label="Brand">
              {soItemDetails?.brand}
            </Descriptions.Item>
            <Descriptions.Item label="Grade">
              {soItemDetails?.grade}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Form
          layout="vertical"
          form={labellingSamplesInfo}

        >
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Sample Info" key="1" style={{ backgroundColor: '#69c0ff', border: 0, width: '100%' }} showArrow={true}>

              <Row gutter={24}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                  <Form.Item
                    name={"time"}
                    label="Time"
                    rules={[
                      {
                        required: true,
                        message: `Missing Start Time`,
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <TimePicker use12Hours format="h:mm a" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"remarks"}
                    label="Remarks"
                    initialValue={LabellingInput[0].value}
                    rules={[
                      {
                        required: false,

                      },
                    ]}
                  >
                    <AutoComplete
                      style={{ width: "100%" }}
                      options={LabellingInput}
                      placeholder="Please Enter"
                      filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"correctiveAction"}
                    label="Corrective Action"
                    initialValue={LabellingInput[1].value}
                    rules={[
                      {
                        required: true,
                        message: `Corrective Action required`,
                      },
                    ]}
                  >
                    <AutoComplete
                      style={{ width: "100%" }}
                      options={LabellingInput}
                      placeholder="Please Enter"
                      filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"checkedBy"}
                    label="checked By"
                    rules={[
                      {
                        required: true,
                        message: `checked By required`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"certificationLogo"}
                    label="Certification Logo"
                    rules={[
                      {
                        required: true,
                        message: `Certification Logo`,
                      },
                    ]}
                  >
                    <Radio.Group options={plainOptions} onChange={certificationChange} value='BAP'>
                      <Radio value={4}>
                        More...
                        {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"shrimpsLabel"}
                    label="Details Of Shrimps(unit bags/label)"
                    rules={[
                      {
                        required: true,
                        message: `Details Of Shrimps`,
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item
                    name={"allergenLabel"}
                    label="Details Of Allergen(unit bags/label)"
                    rules={[
                      {
                        required: true,
                        message: `Details Of Allergen`,
                      },
                    ]}
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ paddingTop: '28px' }}>
                  <Button type="primary"disabled={disable} htmlType="button" onClick={saveLabelling}>
                    Submit
                  </Button>
                  {(props.isUpdate != true) ?
                    <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                      Reset
                    </Button> : ''
                  }
                </Col>
              </Row>

            </Panel>
          </Collapse>

        </Form>
      </Form>
      <Row gutter={24}
        style={{ display: dataSource.length ? 'unset' : 'none' }}
      >
        <Table dataSource={dataSource} columns={columns} scroll={{ x: true }} />
      </Row>
    </Card >
  );
}

export default LabellingForm;
