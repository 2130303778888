import React, { useEffect, useRef, useState } from 'react';
import Table, { ColumnProps, ColumnsType, ColumnType } from 'antd/lib/table';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Space, Col, Row, Tag, DatePicker, Modal, Tabs } from 'antd';
import { CaretRightOutlined, SearchOutlined, FilterOutlined, EditOutlined, RotateRightOutlined, RedoOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CrrencySymbols, SaleOrderTrackDto, SOItemsDto, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';

import Highlighter from 'react-highlight-words';

// import './sale-order-tracking-report-grid.css';
import Link from 'antd/lib/typography/Link';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Column from 'antd/lib/table/Column';

import { render } from 'react-dom';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import { EndCustomerDto, ProductDto } from '@gtpl/shared-models/masters';
import { ReProcess } from '@gtpl/pages/dashboard/dashboard-components/factory-head-dashboard-grid';
import { RePacking } from '@gtpl/pages/dashboard/dashboard-components/factory-head-dashboard-grid';
import { EmployeeRolesEnum, SoProcessTypesEnum, SoStatusEnum, SoTypesEnum } from '@gtpl/shared-models/common-models';
import SaleOrderItemsView from 'libs/pages/sale-management/sale-components/sale-order-tracking-report-grid/src/lib/sale-order-items-view';
/* eslint-disable-next-line */
export interface EndCustomerSOTrackGridProps {
  saleOrderTrackingReportData: SaleOrderTrackDto[];
  endCustomers:EndCustomerDto[];
}

export function EndCustomerSOTrackGrid(
  props: EndCustomerSOTrackGridProps
) {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [saleId, setSaleId] = useState(0);
  const [object, setObject] = useState(null);
  // const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [saleIdForProcess, setSaleIdForProcess] = useState(0);
  const [selectedBeginKeys, setSelectedBeginKeys] = useState(String);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [selectedEndKeys, setSelectedEndKeys] = useState(String);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReProcess, setIsReProcess] = useState<boolean>(false);
  const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [dataEndCustomers, setDataEndCustomers] = useState<any[]>([]);
  // let dataEndCustomers: EndCustomerDto[] = []

  useEffect(()=>{
    if(props.endCustomers){
      let dataArray : any[] = []
      props.saleOrderTrackingReportData.forEach((element,index) => {
        if(!(dataArray.includes(element.endCustomername))){
          // dataArray.push({endCustomerId:element.endCustomerId});
          dataArray.push(element.endCustomername);
        }
      });
      setDataEndCustomers(dataArray)
      console.log(dataArray)
    }

  },[props.endCustomers])


  useEffect(() => {
    if(dataEndCustomers){
      console.log(dataEndCustomers)
    }
  }, [dataEndCustomers]);

  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      console.log(searchInput.current);
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  function getdata(val: any) {
    console.log("getdata is running");
    return (
      val === 'yes' ?
        <span key={val} color="#f4646c">
          *
        </span> : null
    );
  }
const tableColumns = (key) => {

  const sampleTypeColumns: ColumnsType<any> = [
    // {
    //   title: "",
    //   children: [
        {
          title: '#',
          dataIndex: 'saleId',
          width: 50,
          fixed: 'left',
          align: 'center',
          render: (text, object, index) => (page - 1) * 10 + (index + 1)

        },

        // {
        //   title: 'SO Number',
        //   dataIndex: 'saleOrderNumber',
        //   fixed: 'left',
        //   width: 140,
        //   align: 'left',

        //   ...getColumnSearchProps('saleOrderNumber'),
        //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</a>,
        //   sorter: (a, b) => a.saleOrderId - b.saleOrderId,
        //   sortDirections: ['descend', 'ascend'],
        //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</Link>,
        // },
        {
          title: 'Customer PO Number',
          dataIndex: 'poNumber',
          width: 130,
          align: 'left',
          ...getColumnSearchProps('poNumber'),
          sorter: (a, b) => a.poNumber != null ? a.poNumber.localeCompare(b.poNumber) : "Have Null Values",
          sortDirections: ['descend', 'ascend'],
          render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.poNumber}</Link>,
        },
        {
          title: 'Po Date',
          dataIndex: 'poDate',
          // fixed: 'left',
          width: 125,
          align: 'left',

          // ...getColumnSearchProps('poDate'),
          render: (text, record) => { return record.poDate != undefined ? moment(record.poDate).format('YYYY-MM-DD') : "" },
          sorter: (a, b) => moment(a.poDate).unix() - moment(b.poDate).unix(),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Customer',
          dataIndex: 'customer',
          width: 130,
          align: 'left',
          sorter: (a, b) => a.customer.localeCompare(b.customer),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('customer'),
        },
    //   ],
    // }
  ]
  const agingColumns: ColumnsType<any> = [
    // {
    //   title: "",
    //   width: 50,
    //   children: [
        {
          title: 'Aging',
          key: 'actualEtd',
          width: 80,
          align: 'left',
          sorter: (a, b) => (Math.floor((new Date(a.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
          sortDirections: ['descend', 'ascend'],
          // ...getColumnSearchProps('aging'),
          render(text, record) {
            console.log(record)
            const obj: any = {
              children: (<div style={{ textAlign: 'right' }}>{record.actualEtd?(Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)):'0'}</div>),
              props: {
                style: {
                  background: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
                  color: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
                }
              },
            };
            return obj;
          }
        },
    //   ]
    // },
  ]
  // const agingColumn: ColumnsType<any> = [
    const customerColumns: ColumnsType<any> = [
      // {
      //   title: "",
      //   children: [
        
        {
          title: 'End Customer',
          dataIndex: 'endCustomername',
          width: 130,
          align: 'left',
        },
        {
          title: 'Buyer PO Number',
          dataIndex: 'buyerPONo',
          width: 125,
          align: 'left',
          sorter: (a, b) => a.buyerPONo.localeCompare(b.buyerPONo),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('buyerPONo'),
        },
        {
          title: 'Load Type',
          dataIndex: 'loadType',
          //fixed: 'right',
          width: 115,
          align: 'left',
          // sorter: (a, b) => a.loadType.localeCompare(b.loadType),
          // sortDirections: ['descend', 'ascend'],
          render: (text, record) => {
            let loadType: number = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length
            return loadType === 1 ? "Single" : loadType > 1 ? "Mixed" : "-"
          },
          filters: [
            {
              text: 'Single',
              value: true,
            },
            {
              text: 'Mixed',
              value: false,
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            let loadType: boolean = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length === 1 ?true:false
            console.log(value, loadType);
            // === is not work
            return loadType === value;
          },

        },

    //   ],

    // },
  ]
  const scheduledDatesColumns: ColumnsType<any> = [

    // {
    //   title: "SCHEDULED DATES",
    //   width: 50,
    //   children: [
        // {
        //   title: 'Ordered Date',
        //   dataIndex: 'orderedDate',
        //   //fixed: 'right',
        //   width: 150,
        //   align:'center',
        //   sorter: (a, b) => moment(a.orderedDate).unix() - moment(b.orderedDate).unix(),

        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <RangePicker
        //       // showTime={{ format: 'HH:mm' }}
        //       format="YYYY-MM-DD"
        //       onChange={e => setSelectedKeys(e.indexOf[0])}
        //       // onOk={onOk}
        //     />
        //   <br></br>
        //     <Button
        //       type="primary"
        //       onClick={() => {handleSearch(selectedKeys, confirm, 'orderedDate')}}
        //       icon={<SearchOutlined />}
        //       size="small"
        //       style={{ width: 90, marginRight: 8 }}
        //     >
        //       Search
        //     </Button>
        //     <Button
        //       onClick={() => {handleReset(clearFilters)}}
        //       size="small"
        //       style={{ width: 90 }}
        //     >
        //       Reset
        //     </Button>
        //   </div>
        // ),
        // filterIcon: filtered => (
        //   <SearchOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        // ),
        // onFilter: (value:string, record) =>
        //   record['orderedDate']
        //     .toString()
        //     .toLowerCase()
        //     .includes(value.toLowerCase()),

        //     onFilterDropdownVisibleChange: visible => {
        //       console.log(selectedBeginKeys)
        //       // console.log(searchInput)
        //       if (visible) {    setTimeout(() => selectedBeginKeys.indexOf[0]);   }
        //     },
        //     render: text => 
        //       text ?(
        //       searchedColumn === 'OrderedDate' ? (
        //         <Highlighter
        //           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //           searchWords={[selectedBeginKeys]}
        //           autoEscape
        //           textToHighlight={text.toString()}
        //         />
        //       ) :text
        //       )
        //       : null,
        // onFilterDropdownVisibleChange: visible => {
        //   if (visible) {
        //     //setTimeout(() => this.searchInput.select());
        //   }
        // },
        // ...getColumnSearchProps('OrderedDate'),

        // sorter: (a, b) => moment(a.OrderedDate).unix() - moment(b.OrderedDate).unix(),
        // sortDirections: ['descend', 'ascend'],
        // },
        
        {
          title: 'ETD',
          dataIndex: 'expectedEndDate',
          width: 130,
          align: 'left',
          render: (text, record) => { return record.expectedEndDate != undefined ? moment(record.expectedEndDate).format('YYYY-MM-DD') : "-" },
          sorter: (a, b) => moment(a.expectedEndDate).unix() - moment(b.expectedEndDate).unix(),
        },
        {
          title: 'Revised ETD',
          dataIndex: 'actualEtd',
          sorter: (a, b) => moment(a.actualEtd).unix() - moment(b.actualEtd).unix(),
          width: 130,
          align: 'left',
          render: (text, record) => { return record.actualEtd != undefined ? moment(record.actualEtd).format('YYYY-MM-DD') : "-" },
        },
          // ...getColumnSearchProps('expectedEndDate'),
          // key: 'expectedEndDate',
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // defaultSortOrder: 'descend',
          // sortDirections: ['descend', 'ascend'],

    //   ],
    //   render(text, record) {
    //     return {
    //       props: {
    //         style: {
    //           background: "cyan",
    //           color: "black"
    //         }
    //       },
    //     };
    //   }
    // },
  ]
  const destinationColumns: ColumnsType<any> = [

    // {
    //   title: "",
    //   align: 'center',
    //   children: [

        // {
        //   title: 'Ordered Quantity',
        //   dataIndex: 'orderQty',
        //   //fixed: 'right',
        //   width: 150,
        //   align:'center',

        //   ...getColumnSearchProps('orderQty'),
        //   sorter: (a, b) => a.orderQty - b.orderQty,
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: 'Final Country',
          dataIndex: 'countryName',
          //fixed: 'right',
          width: 130,
          align: 'left',

          ...getColumnSearchProps('countryName'),
          sorter: (a, b) => a.countryName.localeCompare(b.countryName),
          sortDirections: ['descend', 'ascend'],
        },
        // {
        //   title: 'Price',
        //   dataIndex: 'cost',
        //   //fixed: 'right',
        //   width: 150,
        //   align:'center',
        //   sorter: (a, b) => a.cost - b.cost,
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('cost'),

        // },
        {
          title: 'Final Destination',
          dataIndex: 'destination',
          //fixed: 'right',
          width: 130,
          align: 'left',
          ...getColumnSearchProps('destination'),
          sorter: (a, b) => a.destination != null ? a.destination.localeCompare(b.destination) : "Have Null Values",

          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Unit',
          dataIndex: 'unit',
          //fixed: 'right',
          width: 130,
          align: 'left',
          ...getColumnSearchProps('unit'),
          sorter: (a, b) => a.unit != null ? a.unit.localeCompare(b.unit) : "Have Null Values",
          sortDirections: ['descend', 'ascend'],
          // render: (text, record) => { return record.unit },

          // filters: [
          //   {
          //     text: 'Unit1',
          //     value: 1,
          //   },
          //   {
          //     text: 'Unit2',
          //     value: 2,
          //   },
          //   {
          //     text: 'Kakinada',
          //     value: 3,
          //   },
          // ],
          // filterMultiple: false,
          // onFilter: (value, record) => {
          //   // === is not work
          //   return record.unit === value;
          // },

        },
        // {
        //   title: 'Category',
        //   dataIndex: 'product',
        //   width: 130,
        //   align: 'left',
        //   sorter: (a, b) => a.product.localeCompare(b.product),
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('product'),

        // },
        {
          title: 'Is Invoiced?',
          dataIndex: 'isInvoiced',
          //fixed: 'right',
          width: 130,
          align: 'left',
          sorter: (a, b) => a.isInvoiced.localeCompare(b.isInvoiced),
          sortDirections: ['descend', 'ascend'],
          filters: [
            {
              text: 'YES',
              value: 'YES',
            },
            {
              text: 'NO',
              value: 'NO',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            // === is not work
            return record.isInvoiced === value;
          },

        },
        {
          title: 'Total Weight',
          dataIndex: 'totnetWeight',
          width: 130,
          align: 'right',
          // ...getColumnSearchProps('totnetWeight'),
          render: (text, record) => { return record.totnetWeight != null ? (Math.round(record.totnetWeight) + " " + ((record.uom === 1 || record.uom === 4)? "KG" : (record.uom === 2 || record.uom === 3) ? "LB" : "-")) : '-' },
          // sorter: (a, b) => a.totnetWeight - b.totnetWeight,
          // sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Total Amount',
          dataIndex: 'cost',
          width: 120,
          align: 'right',
          // ...getColumnSearchProps('cost'),
          render: (text, record) => { return record.cost != null ? (CrrencySymbols.find(item => item.id == record.currencyId).symbol + Math.round(record.cost)) : "-" },
          // sorter: (a, b) => a.cost - b.cost,
          // sortDirections: ['descend', 'ascend'],
        },

        {
          title: 'SO Status',
          dataIndex: 'status',
          //fixed: 'right',
          width: 140,
          align: 'left',
          render: (status, rowData) => (
            <>
              {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}

            </>
          ),
          sorter: (a, b) => a.status.localeCompare(b.status),
          sortDirections: ['descend', 'ascend'],
          filters: [
            {
              text: 'OPEN',
              value: 'OPEN',
            },

            {
              text: 'INPROGRESS',
              value: 'INPROGRESS',
            },
            {
              text: 'CLOSED',
              value: 'CLOSED',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            // === is not work
            return record.status === value;
          },
        },
    //   ]
    // },
  ]
  const operationsColumns: ColumnsType<any> = [
    // {
    //   title: "OPERATIONS",
    //   align: 'center',
    //   className: 'operations',

    //   children: [
        {
          title: 'Packing',
          dataIndex: 'packing',
          //fixed: 'right',
          width: 120,
          align: 'left',
          render(text, record) {
            return {
              props: {
                // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
              },
              children: <a href={'#/allsaleorders-view'}>{record.packing}</a>
              // children: record.packing,
            };
          }
        },
        {
          title: 'Production Inventory',
          dataIndex: 'rmStock',
          //fixed: 'right',
          width: 110,
          align: 'left',

          render(text, record) {
            return {
              props: {
                style: { background : ((record.rmStock/record.orderQty)*100) < 50 ? "#f4646c" : (((record.rmStock/record.orderQty)*100) >= 50 && ((record.rmStock/record.orderQty)*100) < 75) ? "#f8f838" : '#38f438', 
                color : ((record.rmStock/record.orderQty)*100) < 50 ? "white" : (((record.rmStock/record.orderQty)*100) >= 50 && ((record.rmStock/record.orderQty)*100) <= 75) ? "black" : 'black' }
              },
              children: record.rmStock,
            };
          }
          // render: (text, rowData) => (<>{rowData.rmStock+'/'+rowData.orderQty}</>),
        },
        {
          title: 'Production',
          dataIndex: 'prodStatus',
          //fixed: 'right',
          width: 120,
          align: 'left',
          filters: [
            {
              text: 'OPEN',
              value: 'OPEN',
            },
            {
              text: 'INPROGRESS',
              value: 'IN_PROGRESS',
            },
            {
              text: 'CLOSED',
              value: 'CLOSED',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            // === is not work
            return record.prodStatus === value;
          },
          render(text, record) {
            return {
              props: {
                // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
              },
              children: <a href={'#/production-inventory-grid'}>{record.prodStatus}</a>
            };
          }
         
        },
        
        {
          title: 'Quality Assurance',
          dataIndex: 'qualityAssurance',
          //fixed: 'right',
          width: 130,
          align: 'left',
          render(text, record) {
            return {
              props: {
                // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
              },
              children: <a href={'#/qa-qc-sample-test-grid'}>{record.qualityAssurance}</a>
              // children: <a href={'sale-order/' + text}>{text}</a>
            };
          }
        },
        {
          title: 'FG Stock (Cases)',
          dataIndex: 'fgStock',
          // //fixed: 'right',
          width: 90,
          align: 'left',
          render(text, record) {
            return {
              props: {
                style: { background : ((record.fgStock/record.orderQty)*100) < 50 ? "#f4646c" : (((record.fgStock/record.orderQty)*100) >= 50 && ((record.fgStock/record.orderQty)*100) < 75) ? "#f8f838" : '#38f438', 
                color : ((record.fgStock/record.orderQty)*100) < 50 ? "white" : (((record.fgStock/record.orderQty)*100) >= 50 && ((record.fgStock/record.orderQty)*100) <= 75) ? "black" : 'black'}
              },
              children: record.fgStock,
            };
          }
        },
        {
          title: 'Documentation',
          dataIndex: 'doc',
          // //fixed: 'right',
          width: 135,
          align: 'left',
          render(text, record) {
            console.log(record);
            return {
              props: {
                // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
              },
              children: <a href={'#/document-approval'}>{record.doc}</a>
              // children: <a href={'sale-order/' + text}>{text}</a>
            };
          }
        },
        {
          title: 'Logistics',
          dataIndex: 'logistics',
          // //fixed: 'right',
          width: 130,
          align: 'left',
          render(text, record) {
            return {
              props: {
                // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
              },
              children: <a href={'#/container-register-grid'}>{record.logistics}</a>
            };
          }
        },
        
    //   ]
    // },
  ]
  const actionColumns: ColumnsType<any> = [
    {
      title: "Action",
      align: 'left',
      className: 'operations',
      fixed: 'right',
      children: [
        {
          title: '',
          dataIndex: 'action',
          fixed: 'right',
          width: 75,
          align: 'left',
          render: (text, rowData) => (
            <span>
              {/* {(object) ?<Redirect
                    to={{
                      pathname: "/saleorder-form",
                      state: object
                    }}
                  />:null} */}
              {(rowData.status != SoStatusEnum.CLOSED) && ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.ADMIN || (JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.HO_SALES_MANAGER || (JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.SR_SALES_MANAGER) ?
                <EditOutlined className={'editSamplTypeIcon'} type="edit"
                  onClick={() => {

                    // if (rowData.isActive) {
                    setUpdatevalue(rowData.saleOrderId);
                    // } else {
                    //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                    // }
                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                /> : null
              }
            </span>
          )
        },

      ]
    },
    // {
    //   title: `Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>
    //       {(object) ?<Redirect
    //         to={{
    //           pathname: "/saleorder-form",
    //           state: object
    //         }}
    //       />:null}
    //       {rowData.status !="CLOSED"?
    //       <EditOutlined className={'editSamplTypeIcon'} type="edit"
    //         onClick={() => {

    //           // if (rowData.isActive) {
    //             setUpdatevalue(rowData);
    //           // } else {
    //           //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
    //           // }
    //         }}
    //         style={{ color: '#1890ff', fontSize: '14px' }}
    //       />:null
    //       }
    //     </span>
    //   )
    // }
  ]

  if(key >= 0){
    return [...sampleTypeColumns, ...agingColumns,...customerColumns,...scheduledDatesColumns,...destinationColumns,...operationsColumns];
  }
  // else if(key === "2"){
  //   return [...sampleTypeColumns,...customerColumns,...destinationColumns,...operationsColumns,...actionColumns];
  // }
  // else if(key === "3"){
  //   return [...sampleTypeColumns,...customerColumns,...destinationColumns,...actionColumns];
  // }
}

  const displayModel = (type, data) => {
    if (type == 'ReProcess') {
      setIsReProcess(true)
    } else {
      setIsReProcess(false)
    }
    setIsModalVisible(true);
    setSelectedSoItem(data);
  };

  const renderItems = (record: SaleOrderTrackDto, index, indent, expanded) =>{
    console.log(record);
    return <SaleOrderItemsView saleOrderId = {record.saleOrderId} currencyId = {record.currencyId} poNumber={record.poNumber}/>
  }



  //     </>
  //   );
  // }
  const setUpdatevalue = (saleOrderId: number) => {
    console.log(saleOrderId)
    setObject(saleOrderId)

  }
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log(saleOrderId);
  }
  const goToProcessDetails = (saleOrderId: number) => {
    setSaleIdForProcess(saleOrderId);
    console.log(saleOrderId);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */

  const setIndex = (expanded,record) => {
    const expandedRows = []
    if(expanded){
      expandedRows.push(record.saleOrderId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <>
      {(saleId) ? <Redirect to={{ pathname: "/saleorder-detail-view", state: saleId }} /> : null}
      {(saleIdForProcess) ? <Redirect to={{ pathname: "/purchase-order-form", state: { id: saleIdForProcess, type: "Saleorder" } }} /> : null}
      {(object) ? <Redirect to={{ pathname: "/saleorder-form", state: object }} /> : null}
      <br></br>


      <Card title={<span style={{ color: 'white' }}>{'Sale Order Details-Total:'+props.saleOrderTrackingReportData.length}</span>}
        extra={<>
        <span><Tag style={{ background: "#f4646c", color: 'white', height:30, width:81, fontSize:14}}>Below 50%</Tag></span>
        <span><Tag style={{ background: "#38f438", color: 'black', height:30, width:81, fontSize:14}}>Above 75%</Tag></span>
        <span><Tag style={{ background: "#f8f838", color: 'black', height:30, width:81, fontSize:14}}>Above 50%</Tag></span></>}
        style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

      >
        <br></br>
        <div className="card-container">
          <Tabs type={'card'} tabPosition={'top'}>
          {dataEndCustomers.map((element,index) => {
            return <TabPane 
            key={index}
            tab={<span style={{ color: "#0ec92d" }}>{element+': '+props.saleOrderTrackingReportData.filter(item=>item.endCustomername === element).length}</span>}
            >
             <Table 
            rowKey={record => record.saleOrderId}
            columns={props.saleOrderTrackingReportData.length == 0 ? null : tableColumns(index)}
            dataSource={props.saleOrderTrackingReportData.filter(item=>item.endCustomername === element)}
            expandedRowRender={renderItems}
            expandedRowKeys={expandedIndex}
            onExpand={setIndex}
            // expandIconColumnIndex={6}
            pagination={{
              onChange(current) {
                setExpandedIndex([]);
                setPage(current);
              }
            }}
            onChange={onChange}
            scroll={{ x: 500 }}
            size="large"
            bordered
          >
            </Table> 
            
          </TabPane>
          })}
          
          
          </Tabs>
          </div>
      </Card>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(isReProcess) ? <ReProcess SoItemData={selectedSoItem} /> : <RePacking SoItemData={selectedSoItem} />}
      </Modal>
    </>
  );
}

export default EndCustomerSOTrackGrid;
