import { GlobalResponseObject } from "../global-response-object";
import { SoProductionStatusDetails } from "./so-production-status";

export class SoProductionStatusDetailsResponse extends GlobalResponseObject{
    data?:SoProductionStatusDetails[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoProductionStatusDetails[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}