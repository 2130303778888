import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Select, Card, Row, Col, InputNumber} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from 'react-router-dom';
import {RackEndCustomerMapDto, ZoneDto, RackDto, EndCustomerDto, RackDetails, RacksForZoneId, ZoneDropDownDto, RackDropDownDto} from '@gtpl/shared-models/masters';
import {RackEndCustomerMapService, ZoneService, RackService, EndCustomersService} from '@gtpl/shared-services/masters';

import './pages-warehouse-masters-warehouse-master-component-rack-endcustomer-mapping-form.css';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

/* eslint-disable-next-line */

const {Option} =Select;
const layout= {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span:8,
  },
};

const tailLayout ={
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
export interface RackEndcustomerMappingFormProps {
  Data : RackEndCustomerMapDto;
  updateRackZone: (data: RackEndCustomerMapDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function RackEndcustomerMappingForm(
  props: RackEndcustomerMappingFormProps
) {
  const [form] =Form.useForm();
  const [zoneData, setZoneData] = useState<ZoneDropDownDto[]>([]);
  const [rackData, setRackData] = useState<RackDropDownDto[]>([]);
  const [display, setDisplay] = useState<string>('none');
  const [custData, setCustData] = useState<EndCustomerDto[]>([]);

  const service = new RackEndCustomerMapService();
  const zoneService = new ZoneService();
  const rackService = new RackService();
  const custService = new EndCustomersService();

  useEffect(() => {
    getAllZonesData();
    getAllEndCustomersData();
    if(props.isUpdate){
      getRacksForZone(props.Data.zoneId)
    }
  },[])
  let history = useHistory();

  const getAllZonesData = () =>{
    zoneService.getAllZoneDropDown().then((res) => {
      if(res.status) {
        setZoneData(res.data);
      }else {
        if(res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err =>{
      AlertMessages.getErrorMessage(err.message);
    })
 }
 const getRacksForZone = (zoneId) => {
  // const zone = new ZoneDropDownDto(zoneId, zoneName.children);
  const zoneRequest = new RacksForZoneId(zoneId);
  rackService.getRacksForZone(zoneRequest).then((res) => {
    if (res.status) {
      setRackData(res.data);
    } else {
      setRackData([]);
      form.setFieldsValue({rackName:''})
      AlertMessages.getErrorMessage(res.internalMessage);
    }

  });
}


 
 const getAllEndCustomersData = () =>{
   custService.getAllActiveEndCustomers().then((res) => {
     if(res.status){
       setCustData(res.data);
     }else {
       if(res.intlCode){
         AlertMessages.getErrorMessage(res.internalMessage);
       }else {
         AlertMessages.getErrorMessage(res.internalMessage);
       }
     }
   }).catch(err => {
     AlertMessages.getErrorMessage(err.internalMessage);
   })
 }

 const CreateRackZone = (Data: RackEndCustomerMapDto) =>{
   service.CreateRackZone(Data).then(res =>{
     if(res.status)  {
     AlertMessages.getSuccessMessage('Created Successfully');
     history.push('/rack-endCustomer-mapping');
     onReset();
   } else {
     if(res.intlCode) {
       AlertMessages.getErrorMessage(res.internalMessage);
     }else {
       AlertMessages.getErrorMessage(res.internalMessage);
     }
   }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.internalMessage);
  })
 }
 const onReset = () => {
   console.log('');
   form.resetFields();
 }

 const onFocus=() =>{
  console.log('focus');
}

const onSearch=(val)=> {
  console.log('search:', val);
}
const onBlur=() =>{
  console.log('blur');
} 

let createdUser="";
  if(!props.isUpdate){
    createdUser= (localStorage.getItem("createdUser"))?localStorage.getItem("createdUser"):'ADMIN';
  }

 const saveData = (values: RackEndCustomerMapDto) =>{
   if(props.isUpdate){
     console.log(values)
     props.updateRackZone(values);
   }else{
     console.log(values);
     CreateRackZone(values);
   }
 };

 const style = {
   margin: '1%',
   display : display
 }
  return (
    <Card title={<span style={{color:'white'}}>Rack End Customer Mapping </span>} style={{textAlign:'center'}} extra={props.isUpdate ? '' : <Link to='/rack-endCustomer-mapping' ><Button className='panel_button' >View </Button></Link>}headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
      <Form form={form} layout={'vertical'} onFinish={saveData} initialValues={props.Data} >
        <Form.Item 
        name ="rackZoneMappingId" style={{display:"none"}} >
        <Input hidden/>
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
        </Form.Item>
        <Row gutter={24}>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:5}} xl={{span:5}} >
          <Form.Item
            name="zoneId"
            label="Zone Name"
            rules={[
              {
                required: true,
                message: 'Zone is required'
              },
            ]}
          >
            <Select
                placeholder="Select Zone"
                // onChange={getSaleOrderItems}
                allowClear
                onChange={(zoneId, value) => getRacksForZone(zoneId)}
              >
                <Option value={''}>Please Select</Option>
                {
                  zoneData.map((zne) => {
                    return <Option value={zne.zoneId}>{zne.zoneName}</Option>
                  })
                }
              </Select>

            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="rackId"
              label="Rack"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select placeholder="Select Rack" allowClear >
                <Option value={''}>Please Select</Option>
                {
                  rackData.map(rack => {
                    return <Option key = {rack.rackId} value={rack.rackId}>{rack.rackCode}</Option>
                  })}

              </Select>

            </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="endCustomerId"
            label="End Customer Name"
            rules={[
              {
                required: true,
                message: 'End customer name is required'
              },
            ]}

          >
            <Select
                  showSearch
                  style={{ width: 260 }}
                  placeholder="Select End Customer"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={''}>Select End Customer</Option>
                  {custData.map((customer)=>{
                    return <Option key={customer.endCustomerId} value={customer.endCustomerId}>{customer.endCustomerName}</Option>
                  })}
                </Select>
            </Form.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
           <Button type = 'primary' htmlType = 'submit' >
             Submit
           </Button>
           {(props.isUpdate===false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
            </Button>
           }
        </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default RackEndcustomerMappingForm;
