import { PlantIdRequest } from "@gtpl/shared-models/masters";
import { ProductConversionDetailsResponseModel, ProductConversionDTO, ProductConversionResponseModel } from "@gtpl/shared-models/production-management";
import axios from "axios";
import { connection } from "..";

export class ProductConversionService{
    URL = connection.DEPLOY_URL + '/product-conversion';

    async createProductConversion(dto: ProductConversionDTO): Promise<ProductConversionResponseModel>{
        console.log("hhhhhhhh"); 
        return await axios.post(this.URL+ '/createProductConversion', dto)
            .then(res => {
                return res.data
            })
    }
    async getProductConversionDetails(dto: PlantIdRequest): Promise<ProductConversionDetailsResponseModel>{
        return await axios.post(this.URL+ '/getProductConversionDetails', dto)
            .then(res => {
                return res.data
            })
    }

}