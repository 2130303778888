export class GetItemForProductRequest{
    itemId:number;
    productId:number;

    /**
     * 
     * @param itemId 
     * @param productId 
     */
    constructor(itemId:number, productId:number){
        this.itemId = itemId;
        this.productId = productId;
    }
}