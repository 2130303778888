import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { RmIndentQtyLimitDto } from "./rm-indent-qty-limit.dto";

export class RmIndentQtyLimitResponse extends GlobalResponseObject {
    data?: RmIndentQtyLimitDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: RmIndentQtyLimitDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }
}