export class StockResponseDto {
    saleOrderId: number;
    stockId: number;
    physicalQuantity: number;
    
    constructor(saleOrderId:number, stockId: number, physicalQuantity: number) {
        this.saleOrderId = saleOrderId;
        this.stockId = stockId;
        this.physicalQuantity = physicalQuantity;
    }
}
