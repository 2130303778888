export class GradePatternRequest{
    count: number;
    maxCount: number;
    plantId?: number;
   constructor( count: number,
    maxCount: number,
    plantId?: number){
        this.count=count;
        this.maxCount=maxCount;
        this.plantId=plantId;
    }
}