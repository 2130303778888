import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { UnitcodeDto } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UnitCodeForm } from '@gtpl/pages/master/master-components/unit-code-form';

import './unit-code-grid.css';
//import Form from 'antd/lib/form/Form';
import { Link, Redirect } from 'react-router-dom';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface UnitCodeGridProps { }

export function UnitCodeGrid(props: UnitCodeGridProps) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [unitcodeData, setUnitcodeData] = useState<UnitcodeDto[]>([]);
  const [selectedUnitcodeData, setSelectedUnitcodeData] = useState<any>(undefined);

  const unitcodeDataService = new UnitcodeService();

  useEffect(() => {
    getAllUnitcode();
  }, []);

  /**
   * 
   */
  const getAllUnitcode = () => {
    unitcodeDataService.getAllUnitcode(new UserRequestDto(JSON.parse(localStorage.getItem('username')))).then(res => {
      if (res.status) {
        setUnitcodeData(res.data);
      } else {
        if (res.intlCode) {
          setUnitcodeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setUnitcodeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param unitcodeData 
   */
  const deleteUnitcode = (unitcodeData: UnitcodeDto) => {
    unitcodeData.isActive = unitcodeData.isActive ? false : true;
    unitcodeDataService.ActivateorDeactivateUnitcode(unitcodeData).then(res => {
      console.log(res);
      if (res.status) {
        getAllUnitcode();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param variantData 
   */
  const updateUnitcode = (unitcodeData: UnitcodeDto) => {
    unitcodeData.updatedUser=localStorage.getItem('username')
    // unitcodeData.updatedUser= JSON.parse(localStorage.getItem('username'))
    unitcodeDataService.updateUnitcode(unitcodeData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllUnitcode();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData: UnitcodeDto) => {
    setDrawerVisible(true);
    setSelectedUnitcodeData(viewData);
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Unit',
      dataIndex: 'unitcodeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.unitcodeName.localeCompare(b.unitcodeName.length),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unitcodeName')
    },
    {
      title: 'Unit Type',
      dataIndex: 'plantType',
      // responsive: ['lg'],
      sorter: (a, b) => a.plantType.localeCompare(b.plantType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'PLANT',
          value: 'PLANT',
        },
        {
          text: 'SUB PLANT',
          value: 'SUB PLANT',
        },
        {
          text: 'HO',
          value: 'HO',
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.plantType == value;
      }
    },

    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}

        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isActive === value;
      },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Unitcode');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteUnitcode(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate Unitcode ?'
                : 'Are you sure to Activate Unitcode ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');

  }

  return (
    <Card title={<span style={{ color: 'white' }}>Units</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/unitcode-form' ><Button className='panel_button' >Create </Button></Link>}

    >
      <br></br>
      <Row gutter={40} style={{ marginLeft: '1%' }}>

        <Card title={'Total Units: ' + unitcodeData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>


        <Card title={'Active: ' + unitcodeData.filter(el => el.isActive).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a', marginLeft: '1%' }}></Card>


        <Card title={'In-Active :' + unitcodeData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d', marginLeft: '1%' }}></Card>

      </Row>
      <br></br> <br />
      <Table
        rowKey={record => record.deptId}
        columns={columnsSkelton}
        dataSource={unitcodeData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <UnitCodeForm key={Date.now()}
            updateUnitcode={updateUnitcode}
            isUpdate={true}
            unitcodeData={selectedUnitcodeData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default UnitCodeGrid;
