export class GrnItemsForReturn{
    grnItemId: number;
    stockId:number;
    itemCategoryId: number;
    itemCategoryName: string;
    itemSubCategoryId: number;
    itemSubCategoryName: string;
    itemId: number;
    itemName: string;
    sizeId: number;
    sizeName: string;
    taxId:number;
    taxPercentage:number;
    taxAmount:number;
    discountPercentage:number;
    discountAmount:number;
    grnReceivedQty: number;
    grnReturnQty: number;
    grnRemainingQty: number;
    availQty: number;
    unitPrice: number;
    grnTotalReturnPrice: number;
    remarks:string;
}