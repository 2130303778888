export class EmployeeAttDateRequest {

    employeeCode: string;
    startDate: Date;
    endDate: Date;
    departmentId?: number;
    shift?: string;
    unitId?: string;

    /**
     * 
     * @param employeeCode 
     * @param startDate 
     * @param endDate 
     * @param departmentId 
     */
    constructor(employeeCode: string, startDate: Date, endDate: Date, departmentId?: number, shift?: string, unitId?: string) {

        this.employeeCode = employeeCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.departmentId = departmentId;
        this.shift = shift;
        this.unitId = unitId;

    }
}