import { AssetAssignmentService, AssetService } from '@gtpl/shared-services/asset-management';
import { Card, Row, Col, Progress, Tooltip, Button, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import fixedd from './fixed.png';
import movable from './movable.png';
import purchased from './dollar.png';
import rentedicon from './password-manager.png';
import totalassets from './control.png';
import mattressicon from './mattress.png';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import excel from '../../../assets-dashboard/src/lib/excel.png'


interface IProps {
    tableData: any[]
    transformedData: any[]
}
const AssetCountAndValueInfoByAssetType = (props: IProps) => {
    const { tableData, transformedData } = props

    const assetsService = new AssetAssignmentService();
    const [hoveredCard, setHoveredCard] = useState(null);
    const assetService = new AssetService();



    // Filter assets with assetType "Fixed"
    const fixedAssets = tableData.filter(asset => asset.assetType === 'FIXED');

    // Calculate the total fixed asset value and assets count
    const totalFixedAssetValue = fixedAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + info.totalAssetsValue, 0), 0);

    const totalFixedAssetsCount = fixedAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + parseInt(info.assetsCount), 0), 0);


    // Filter assets with assetType "Movable"
    const movableAssets = tableData.filter(asset => asset.assetType === 'MOVABLE');

    // Calculate the total fixed asset value and assets count
    const movableAssetsValue = movableAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + info.totalAssetsValue, 0), 0);

    const movableAssetsCount = movableAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + parseInt(info.assetsCount), 0), 0);

    // Filter assets with assetType "Soft"
    const softAssets = tableData.filter(asset => asset.assetType === 'SOFT');

    // Calculate the total fixed asset value and assets count
    const softAssetsValue = softAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + info.totalAssetsValue, 0), 0);

    const softAssetsCount = softAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + parseInt(info.assetsCount), 0), 0);
    // Filter assets with assetType "Purchase"
    //const purchaseAssets = tableData.filter(asset => asset.assetType === 'Purchase');

    // Calculate the total fixed asset value and assets count
    const assetTypeInfoArrays = tableData.map(rec => rec.assetTypeInfo);
    const flattenedAssetTypeInfo = [].concat(...assetTypeInfoArrays);

    const purchaseAssetsValue = flattenedAssetTypeInfo
        .filter(rec => rec.assetStatus.toLowerCase() === "INSTOCK")
        .reduce((acc, cur) => acc + Number(cur.totalAssetsValue), 0);

    const purchaseAssetsCount = flattenedAssetTypeInfo
        .filter(rec => rec.assetStatus.toLowerCase() === "INSTOCK")
        .reduce((acc, cur) => acc + Number(cur.assetsCount), 0);


    // Filter assets with assetType "Rented"
    const rentedAssets = tableData.filter(asset => asset.assetType === 'Rented');

    // Calculate the total fixed asset value and assets count
    const rentedAssetsValue = rentedAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + info.totalAssetsValue, 0), 0);

    const rentedAssetsCount = rentedAssets.reduce((total, asset) => total + asset.assetTypeInfo.reduce((subtotal, info) => subtotal + parseInt(info.assetsCount), 0), 0);


    //totalAssetsValue and totalAssentCount
    const totalAssetsValue = totalFixedAssetValue + movableAssetsValue + softAssetsValue;
    const totalAssetsCount = totalFixedAssetsCount + movableAssetsCount + softAssetsCount;



    //percentage Total Fixed Assets
    const percentageFixedCount = (totalFixedAssetsCount / totalAssetsCount) * 100

    //percentage Total movableAssets
    const percentageMovableCount = (movableAssetsCount / totalAssetsCount) * 100


    //percentage Total SoftAssets
    const percentageSoftCount = (softAssetsCount / totalAssetsCount) * 100

    //percentage Total PurchaseAssets
    const percentagePurchaseCount = (purchaseAssetsCount / totalAssetsCount) * 100

    //percentage Total RentedAssets
    const percentageRentCount = (rentedAssetsCount / totalAssetsCount) * 100

    //percentage Total Assets
    const percentageTotalCount = (percentageFixedCount + percentageMovableCount + percentageSoftCount + percentagePurchaseCount + percentageRentCount)


    return (

        <div>

            {/* <Card style={{ backgroundColor: '#0d092b', borderRadius: '6px' }}>
                <Row gutter={[16, 16]}>
                    {[
                        { src: fixedd, alt: 'fixed', title: 'Fixed Assets', count: totalFixedAssetsCount, value: totalFixedAssetValue, percent: percentageFixedCount, color: '#0b96b5' },
                        { src: movable, alt: 'movable', title: 'Movable Assets', count: movableAssetsCount, value: movableAssetsValue, percent: percentageMovableCount, color: '#0b96b5' },
                        // { src: mattressicon, alt: 'soft', title: 'Soft Assets', count: softAssetsCount, value: softAssetsValue, percent: percentageSoftCount, color: '#0b96b5' },
                        { src: purchased, alt: 'purchased', title: 'UnAssigned Assets', count: purchaseAssetsCount, value: purchaseAssetsValue, percent: percentagePurchaseCount, color: '#8B0000' },
                        { src: rentedicon, alt: 'rented', title: 'UnServed Assets', count: rentedAssetsCount, value: rentedAssetsValue, percent: percentageRentCount, color: '#8B0000' },
                        { src: totalassets, alt: 'total', title: 'Total Assets', count: totalAssetsCount, value: totalAssetsValue, percent: percentageTotalCount, color: '#0b96b5' }
                    ].map(({ src, alt, title, count, value, percent, color }) => (
                        <Col key={title} xs={24} sm={12} md={8} lg={6} xl={4} offset={1}>
                            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: color, marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={src} alt={alt} style={{ width: '40px', height: '40px' }} />
                                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>{title}</a>
                            </div>
                            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{count} Pcs / ₹ {value.toLocaleString('en-IN')}</span>
                                <Progress percent={Number(percent.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Card> */}

<Card style={{ backgroundColor: '#0d092b', borderRadius: '6px' }}>
    <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: '#0b96b5', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={fixedd} alt="FIXED" style={{ width: '40px', height: '40px' }} />
                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>Fixed Assets</a>
            </div>
            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{totalFixedAssetsCount} Pcs / ₹ {totalFixedAssetValue.toLocaleString('en-IN')}</span>
                <Progress percent={Number(percentageFixedCount.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
            </div>
        </Col>
        
        <Col xs={24} sm={12} md={8} lg={4} xl={4} offset={1}>
            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: '#0b96b5', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={movable} alt="MOVABLE" style={{ width: '40px', height: '40px' }} />
                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>Movable Assets</a>
            </div>
            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{movableAssetsCount} Pcs / ₹ {movableAssetsValue.toLocaleString('en-IN')}</span>
                <Progress percent={Number(percentageMovableCount.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
            </div>
        </Col>
        
        <Col xs={24} sm={12} md={8} lg={4} xl={4} offset={1}>
            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={purchased} alt="purchased" style={{ width: '40px', height: '40px' }} />
                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>UnAssigned Assets</a>
            </div>
            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{purchaseAssetsCount} Pcs / ₹ {purchaseAssetsValue.toLocaleString('en-IN')}</span>
                <Progress percent={Number(percentagePurchaseCount.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
            </div>
        </Col>
        
        <Col xs={24} sm={12} md={8} lg={4} xl={4} offset={1}>
            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: '#8B0000', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={rentedicon} alt="rented" style={{ width: '40px', height: '40px' }} />
                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>UnServed Assets</a>
            </div>
            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{rentedAssetsCount} Pcs / ₹ {rentedAssetsValue.toLocaleString('en-IN')}</span>
                <Progress percent={Number(percentageRentCount.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
            </div>
        </Col>
        
        <Col xs={24} sm={12} md={8} lg={4} xl={4} offset={1}>
            <div style={{ border: 'solid', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: '#0b96b5', marginBottom: '0px', textAlign: 'center', marginTop: '20px', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={totalassets} alt="total" style={{ width: '40px', height: '40px' }} />
                <a style={{ color: 'white', fontSize: '14px', marginLeft: '10px', textAlign: 'center' }}>Total Assets</a>
            </div>
            <div style={{ border: 'solid', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', borderWidth: '0px', height: '100px', backgroundColor: 'white', marginTop: '0px', textAlign: 'center', paddingTop: '10px' }}>
                <span className="hind-font caption-12 c-dashboardInfo__count" style={{ fontSize: '14px', marginBottom: '2px', fontWeight: 600 }}>{totalAssetsCount} Pcs / ₹ {totalAssetsValue.toLocaleString('en-IN')}</span>
                <Progress percent={Number(percentageTotalCount.toFixed(2))} style={{ fontSize: '14px', marginBottom: '2px', width: '80%' }} />
            </div>
        </Col>
    </Row>
</Card>

            <br />
            <br />
            {/* Assets Information */}
            <Card style={{ backgroundColor: '#AFD198', height: '60px' }} >
                <Row>
                    <Col span={2}>
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Asset </h1>
                        <br />
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Type</h1>
                    </Col>
                    <Col span={3} >
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>INSTOCK</h1>
                    </Col>
                    <Col span={4} >
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>INUSE</h1>
                    </Col>
                    <Col span={2} offset={1}>
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Under</h1>
                        <br />
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Maintenance</h1>
                    </Col>
                    {/* <Col span={2} offset={1}>
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Damaged</h1>
                    </Col> */}
                    <Col span={2} offset={1}>
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Retire</h1>
                    </Col>
                    <Col span={3} >
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>Serviced</h1>
                    </Col>
                    <Col span={3} >
                        <h1 style={{ fontSize: '19px', fontWeight: 600 }}>UnServiced</h1>
                    </Col>
                    <Col span={2}>

                        <h1 style={{ fontSize: '19px', fontWeight: 600, }}>Sold</h1>
                        {/* <FileExcelOutlined style={{ fontSize: '25px', paddingLeft: '50px' }} onClick={handleExport} /> */}

                    </Col>
                </Row>
            </Card>
            <div style={{ maxHeight: '400px', overflowY: 'scroll', }}>
                {transformedData.map((data, index) => (
                    <>
                        <Card style={{
                            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                            height: '80px',
                            backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white'

                        }}
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <Row gutter={[16, 16]} // Adjust gutter for spacing
                                style={{
                                    display: 'flex',

                                    borderStyle: 'solid',
                                    height: 'auto', // make height auto to accommodate wrapping
                                    color: 'black',
                                    borderWidth: '0px',
                                    marginBottom: '10px',
                                    borderRadius: '5px',
                                    paddingTop: '9px',
                                }}>
                                <Col span={2} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.assetType}</p>
                                </Col>
                                <Col span={3} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.Instock}</p>
                                </Col>
                                <Col span={4} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.InUse}</p>
                                </Col>
                                <Col span={2} offset={1} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.UnderMaintenance}</p>
                                </Col>
                                {/* <Col span={2} offset={1} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.Damaged}</p>
                                </Col> */}
                                <Col span={2} offset={1} >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.Retire}</p>
                                </Col>
                                <Col span={3}  >
                                    <p style={{ wordWrap: 'break-word', }}>
                                        {data.Service}</p>
                                </Col>
                                <Col span={3} >
                                    <p style={{ wordWrap: 'break-word', color: "red" }}>
                                        {data.INTRANSIT}
                                    </p>
                                </Col>
                                <Col span={2} >
                                    <p style={{ wordWrap: 'break-word', }}

                                    >
                                        {data.Sold}
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                        <br />
                    </>
                ))}
            </div>

        </div>
    )
}

export default AssetCountAndValueInfoByAssetType