import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { PlantOperationCapacityDto } from "./plant-operation-capacity-dto";


export class PlantOperationCapacityResponse extends  GlobalResponseObject{

data?: PlantOperationCapacityDto
/**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
constructor(status:boolean, intlCode:number, internalMessage:string, data?: PlantOperationCapacityDto){
    super(status,intlCode,internalMessage);
    this.status = status;
    this.internalMessage = internalMessage;
    this.intlCode = intlCode;
    this.data = data;
}
}