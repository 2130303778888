import axios from 'axios';
import connection from './connection';
import {AllCodeListMainResponseModel, CodeListMainDto,CodeListMainId, CodeListMainIdRequest, CodeListResponseModel, CodeListValidationReq,CodeListSOItemRequest,SubJobCodeListRequest} from '@gtpl/shared-models/planning'
import { CommonResponse } from '@gtpl/shared-models/masters';
import { SubJobReviseRequest, SubJobRevisionCodesRequest } from '@gtpl/shared-models/sale-management';
import { UnitRequest } from '@gtpl/shared-models/common-models';



export class CodeListMainService {
  URL = connection.DEPLOY_URL + '/code-list-main';

  async createCodeListInfo(codeList: CodeListMainDto): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/createCodeListInfo', codeList)
      .then((res) => {
        return res.data;
      });
  }

  async getAllCodeLists(req : UnitRequest): Promise<AllCodeListMainResponseModel> {
    return await axios
      .post(this.URL + '/getAllCodeLists',req)
      .then((res) => {
        return res.data;
      });
  }
  async updateCodeList(codeListMainDto: CodeListMainDto): Promise<CodeListResponseModel> {
    return await axios
      .post(this.URL + '/updateCodeList',codeListMainDto)
      .then((res) => {
        return res.data;
      });
  }

  async activateOrDeactivateProduct(codeListReq: CodeListMainDto): Promise<CodeListResponseModel> {
    return await axios
      .post(this.URL + '/activateOrDeactivateProduct',codeListReq)
      .then((res) => {
        return res.data;
      });
  }

  async getCodeListsForId(codeListReq:CodeListMainIdRequest ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/getCodeListsForId',codeListReq)
      .then((res) => {
        return res.data;
      });
  }

  async geTracebilityCodeForSoId(codeListReq:CodeListMainIdRequest ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/geTracebilityCodeForSoId',codeListReq)
      .then((res) => {
        return res.data;
      });
  }
  
  async validateCodeListCartons(codeListReq:CodeListValidationReq ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/validateCodeListCartons',codeListReq)
      .then((res) => {
        return res.data;
      });
  }

  async getCodeListPrintData(codeListReq:CodeListMainIdRequest ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/getCodeListPrintData',codeListReq)
      .then((res) => {
        return res.data;
      });
  }

  async getCodeListDataForUpdate(codeListReq:CodeListMainId ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/getCodeListDataForUpdate',codeListReq)
      .then((res) => {
        return res.data;
      });
  }
  async geTracebilityCodeForSoItem(): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/geTracebilityCodeForSoItem')
      .then((res) => {
        return res.data;
      });
  }
  async updateCodeListForSubJob(req:SubJobCodeListRequest[]): Promise<CommonResponse> {
    console.log('oui')
    console.log(req)
    return await axios
      .post(this.URL + '/updateCodeListForSubJob',req)
      .then((res) => {
        return res.data;
      });
  }
  async updateCodeListFromSubJobRevision(req:SubJobReviseRequest): Promise<CommonResponse> {
    console.log('request')
    console.log(req)
    return await axios
      .post(this.URL + '/updateCodeListFromSubJobRevision',req)
      .then((res) => {
        return res.data;
      });
  }
  async getUnitsForCodeList(): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/getUnitsForCodeList')
      .then((res) => {
        return res.data;
      });
  }

  async getCodeListPrimaryPrintData(codeListReq:CodeListMainIdRequest ): Promise<CommonResponse> {
    return await axios
      .post(this.URL + '/getCodeListPrimaryPrintData',codeListReq)
      .then((res) => {
        return res.data;
      });
  }

  

  
  
  
}
