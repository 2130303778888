import { CellDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllCellsResponseModel extends GlobalResponseObject {
    data?: CellDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /CellDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: CellDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 