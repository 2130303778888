import { ApiProperty } from "@nestjs/swagger";

export class LeaveUpdateRequest {

    employeeCode: string;
    fromDate: Date;
    toDate: Date;
    fromDay: string;
    toDay: string;

    /**
     * 
     * @param employeeCode 
     * @param fromDate 
     * @param toDate 
     * @param fromDay 
     * @param toDay 
     */
    constructor(employeeCode: string, fromDate: Date, toDate: Date, fromDay: string, toDay: string) {

        this.employeeCode = employeeCode;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fromDay = fromDay;
        this.toDay = toDay;
    }
}