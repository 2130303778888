import axios from 'axios';
import connection from './connection';
import {TrimsDto, TrimsResponse, AllTrimsResponse, TrimsRequest} from'@gtpl/shared-models/masters';
import { CommonResponse } from '@gtpl/shared-models/production-management';



export class TrimsService{
    URL = connection.DEPLOY_URL + '/trims';

    async create (dto:TrimsDto): Promise<TrimsResponse>{
        return await axios.post(this.URL +'/createTrims', dto)
        .then(res=>{
            return res.data
        })
    }

    async update(dto: TrimsDto): Promise<TrimsResponse> {
        return await axios.post(this.URL + '/updateTrims', dto)
            .then(res => {
                return res.data
            })
    }

    async activatedeActivate(Dto: TrimsDto): Promise<TrimsResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateTrims', Dto)
            .then(res => {
                return res.data
            })
    }

    async getAll(): Promise<AllTrimsResponse> {
        return await axios.post(this.URL + '/getAllTrims')
            .then(res => {
                return res.data
            })
    }

    async getAllTrimsDropDown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllTrimsDropDown')
            .then(res => {
                return res.data
            })
    }

    async getTrimsInfoById(TrimsReq: TrimsRequest): Promise<TrimsResponse> {
        return await axios.post(this.URL + '/getTrimsInfoById', TrimsReq)
            .then(res => {
                return res.data
            })
    }

    

}