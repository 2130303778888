import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ShiftWiseCountModel } from "./shift-wise-count.model";
import { ShiftWiseDataModel } from "./shift-wise-data.model";


export class EmployeeShiftCountResponse extends GlobalResponseObject {
    data?: ShiftWiseCountModel[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ShiftWiseCountModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}