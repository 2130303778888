
import { Form, Input, Button, Space, Card, Select, Col, Row, Layout, Table, Empty, InputNumber, Tooltip, Divider, Descriptions,Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {Link, useLocation} from 'react-router-dom';
import {SoakingDto} from '@gtpl/shared-models/production-management';
import { FactoriesInput, PlantInvoiceDetailsModel, PlantInvoiceDetailsRequest, SaleOrderDetailViewInfoDTO, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { SoakingInventoryService } from '@gtpl/shared-services/production';
import moment from 'moment';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';

import './soaking-material-detail-view.css';

/* eslint-disable-next-line */
export interface SoakingMaterialDetailViewProps {}

export function SoakingMaterialDetailView(
  props: SoakingMaterialDetailViewProps
) {
  const [page, setPage] = React.useState(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [soakingDetailViewData, setSoakingDetailViewData] = useState<SoakingDto>();
  // const [indexVal, setIndexVal] = useState(0);
  // const [selectedExporter,setSelectedExporter] = useState<any>();
  // const [selectedImporter,setSelectedImporter] = useState<any>();
  const soakingInventoryService = new SoakingInventoryService;
  let location = useLocation();
  const prodLogId = location.state;
  const { Text } = Typography;
  const unitId = localStorage.getItem('unit_id');
  useEffect(() => {
    getSoakingDataById();
  }, [])

  const getSoakingDataById = () => {
    soakingInventoryService.getSoakingDataById({productionLogId:prodLogId,plantId:unitId}).then(res => {
      if (res.status) {
        setSoakingDetailViewData(res.data);
        // AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setSoakingDetailViewData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setSoakingDetailViewData(null)

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  return (
    <>
    {
        <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
          <Card title='Soaking Inventory Detail View' style={{ textAlign: 'center'}} headStyle={{ backgroundColor: '#69c0ff',fontSize:'15px',color:'white'  }} extra={<Link to='/soaking-assignment-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} size='small'> 
          { soakingDetailViewData?<div>
            <Descriptions column={3}>
              <Descriptions.Item label="Job Number">
                {soakingDetailViewData?.jobNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Lot Number">
              {soakingDetailViewData?.lotNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Sale Order Number">
                {soakingDetailViewData?.soNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Workstation">
                {soakingDetailViewData?.workStationType}
              </Descriptions.Item>
              <Descriptions.Item label="Variant Code">
                {soakingDetailViewData?.variantCode}
              </Descriptions.Item>
              <Descriptions.Item label="Soaking Style">
                {soakingDetailViewData?.soakingStyle}
              </Descriptions.Item>
              <Descriptions.Item label="Soaking Time">
                {soakingDetailViewData?.soakingTime}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                {soakingDetailViewData?.physicalQuantity}
              </Descriptions.Item>
              <Descriptions.Item label="Job Completed">
                {soakingDetailViewData?.jobStatus}
              </Descriptions.Item>
             
              
            </Descriptions>

            </div>:<div></div>}
          </Card><br></br>
        </Layout>
    }
  </>
  );
}

export default SoakingMaterialDetailView;