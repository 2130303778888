import { GlobalStatus, HarvestTypes, PlantsEnum, SupplierTypeEnum } from "@gtpl/shared-models/common-models";
import { CommissionType, HarvestingTime } from "../enums";
import { IndentItemsModel } from "./indent-items-model";

export class IndentDetailsModel{
    plantId: number;
    indentDate: any;
    supplierType: SupplierTypeEnum;
    commissionType: CommissionType;
    commission: number;
    harvestingDate: any;
    harvestingTime: HarvestingTime;
    assignLead1: number;
    assignLead2: number; 
    indentItems:IndentItemsModel[]
    indentBy: number; 
    indentId?: number;
    indentCode?: string;
    vehicleAssigned?: boolean;
    grnCompleted?: boolean; 
    createdUser?: string;
    createdAt?: Date;
    updatedUser?: string;
    updatedAt?: Date;
    isActive?:boolean;
    versionFlag?:number;
    isVehicleRequired?:boolean;
    harvestType?:HarvestTypes;

   
    /**
     * 
     * @param plantId
    * @param indentDate
    * @param commissionType
    * @param commission
    * @param harvestingDate
    * @param harvestingTime
    * @param assignLead1
    * @param assignLead2
    * @param indentId
    * @param indentCode
    * @param vehicleAssigned
    * @param grnCompleted
    * @param createdUser
    * @param createdAt
    * @param updatedUser
    * @param updatedAt
    * @param isActive
    * @param versionFlag
     */
    
    constructor(plantId: number,indentDate: any,supplierType: SupplierTypeEnum,commissionType: CommissionType,commission: number,harvestingDate: any,harvestingTime: HarvestingTime,assignLead1: number,assignLead2: number, indentItems:IndentItemsModel[],indentBy:number,createdUser?: string,updatedUser?: string,indentId?: number,indentCode?: string,vehicleAssigned?: boolean,grnCompleted?: boolean, 
        createdAt?: Date,updatedAt?: Date,isActive?:boolean,versionFlag?:number,isVehicleRequired?:boolean,
        harvestType?:HarvestTypes){
            this.plantId = plantId
            this.indentDate = indentDate
            this.supplierType = supplierType
            this.commissionType = commissionType
            this.commission = commission
            this.harvestingDate = harvestingDate
            this.harvestingTime = harvestingTime
            this.assignLead1 = assignLead1
            this.assignLead2 = assignLead2
            this.indentItems = indentItems
            this.indentId = indentId
            this.indentCode = indentCode
            this.vehicleAssigned = vehicleAssigned
            this.grnCompleted = grnCompleted
            this.createdUser = createdUser
            this.createdAt = createdAt
            this.updatedUser = updatedUser
            this.updatedAt = updatedAt
            this.isActive = isActive
            this.versionFlag = versionFlag
            this.indentBy = indentBy
            this.isVehicleRequired = isVehicleRequired
            this.harvestType = harvestType
    }
}