import { OperationTypeEnum, JobCompletedEnum, TransferStatusEnum } from "@gtpl/shared-models/common-models";
export class ReceiveSubplantStockDto {
    receiveSubplantStockId: number;
    operation: OperationTypeEnum;
    jobNo: string;
    soNumber: string;
    dcNumber: string;
    jobNumber: string;
    grade: number;
    boxes: number;
    opBoxes: number;
    jobStatus: JobCompletedEnum;
    isActive: boolean;
    createdUser: string;
    fromPlant: string;
    plantId: number;
    toPlant: string;
    subPlantId: number;
    sendBoxes: number;
    receivedBoxes: number;
    sendQuantity: number;
    receivedQuantity: number;
    saleOrderId: number;
    sendCount:number;
    transferLogId:number;
    status:TransferStatusEnum;
    poNumber?:string;
    lotNumber?:string;
    date?:string;
    mrnDate?:string;
    mrnNumber?:string;
    remarks?:string;
    lotandplantlot?:string;

    constructor(
        receiveSubplantStockId: number,
        operation: OperationTypeEnum,
        jobNo: string,
        soNumber: string,
        grade: number,
        boxes: number,
        opBoxes: number,
        jobStatus: JobCompletedEnum,
        isActive: boolean,
        createdUser: string,
        fromPlant: string,
        plantId: number,
        toPlant: string,
        subPlantId: number,
        dcNumber: string,
        jobNumber: string,
        sendBoxes: number,
        receivedBoxes: number,
        sendQuantity: number,
        receivedQuantity: number,
        saleOrderId: number,
        sendCount:number,
        transferLogId:number,
        status:TransferStatusEnum,
        poNumber?:string,
        lotNumber?:string,
        date?:string,
        mrnDate?:string,
        mrnNumber?:string,
        remarks?:string,
        lotandplantlot?:string,
    ) {
        this.receiveSubplantStockId = receiveSubplantStockId;
        this.operation = operation;
        this.jobNo = jobNo;
        this.soNumber = soNumber;
        this.grade = grade;
        this.boxes = boxes;
        this.opBoxes = opBoxes;
        this.jobStatus = jobStatus;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.fromPlant = fromPlant;
        this.plantId = plantId;
        this.toPlant = toPlant;
        this.subPlantId = subPlantId;
        this.dcNumber = dcNumber;
        this.jobNumber = jobNumber;
        this.sendBoxes = sendBoxes;
        this.receivedBoxes = receivedBoxes;
        this.sendQuantity = sendQuantity;
        this.receivedQuantity = receivedQuantity;
        this.saleOrderId = saleOrderId;
        this.sendCount = sendCount;
        this.transferLogId = transferLogId;
        this.status = status;
        this.poNumber = poNumber;
        this.lotNumber = lotNumber;
        this.date = date;
        this.mrnDate = mrnDate;
        this.mrnNumber = mrnNumber;
        this.remarks = remarks;
        this.lotandplantlot = lotandplantlot;
    }
}