export class HarvestModel{
    plantId: number;
    harvestDate: Date;
    farmerData: string;
    harvestTime: string;
    product: string;
    quantity: number;
    temperature1: string;
    temperature2: string;
    temperature3: string;
    temperature4: string;
    temperature5: string;
    cleanBoxes: boolean;
    hygineHandle: boolean;
    healthyShirmps: boolean;
    chillKilled: boolean;
    properIcing: boolean;
    vehicleNo: string;
    driverName: string;
    vehicleLeavingTime: string;
    indentId: number;
    createdUser: string;
    remarks: string;
    sealNo?:string;
    indentItemId: number;
    /**
     * 
     * @param plantId 
     * @param harvestDate 
     * @param farmerData 
     * @param harvestTime 
     * @param product 
     * @param quantity 
     * @param temperature1 
     * @param temperature2 
     * @param temperature3 
     * @param temperature4 
     * @param temperature5 
     * @param cleanBoxes 
     * @param hygineHandle 
     * @param healthyShirmps 
     * @param chillKilled 
     * @param properIcing 
     * @param vehicleNo 
     * @param driverName 
     * @param vehicleLeavingTime 
     * @param indentId 
     * @param createdUser 
     * @param remarks
     * @param sealNo
     */
    constructor(
        plantId: number,
        harvestDate: Date,
        farmerData: string,
        harvestTime: string,
        product: string,
        quantity: number,
        temperature1: string,
        temperature2: string,
        temperature3: string,
        temperature4: string,
        temperature5: string,
        cleanBoxes: boolean,
        hygineHandle: boolean,
        healthyShirmps: boolean,
        chillKilled: boolean,
        properIcing: boolean,
        vehicleNo: string,
        driverName: string,
        vehicleLeavingTime: string,
        indentId: number,
        createdUser: string,
        remarks: string,
        sealNo:string,
        indentItemId: number
        )
    {
        this.plantId = plantId;
        this.harvestDate = harvestDate;
        this.farmerData = farmerData;
        this.harvestTime = harvestTime;
        this.product = product;
        this.quantity = quantity;
        this.temperature1 = temperature1;
        this.temperature2 = temperature2;
        this.temperature3 = temperature3;
        this.temperature4 = temperature4;
        this.temperature5 = temperature5;
        this.cleanBoxes = cleanBoxes;
        this.hygineHandle = hygineHandle;
        this.healthyShirmps = healthyShirmps;
        this.chillKilled = chillKilled;
        this.properIcing = properIcing;
        this.vehicleNo = vehicleNo;
        this.driverName = driverName;
        this.vehicleLeavingTime = vehicleLeavingTime;
        this.indentId = indentId;
        this.createdUser = createdUser;
        this.remarks = remarks;
        this.sealNo = sealNo;
        this.indentItemId = indentItemId;
    }
}