import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ProductConversionDTO } from "./product-conversion.dto";

export class ProductConversionResponseModel extends GlobalResponseObject{
    data?: ProductConversionDTO;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ProductConversionDTO){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}