import { FgStatusEnum, OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class CookingInventoryDto {
    productionInventoryId: number;
    lotNumber: string;
    saleOrderId: number;
    soNumber: string;
    variantCode: string;
    inputQuantity: number;
    plantId?: number;
    createdUser?: string;
    saleOrderItemId?: number;
    operationType?: OperationTypeEnum;
    nextOperation?: OperationTypeEnum;
    status?: FgStatusEnum;
    physicalQuantity?: number;
    poNumber?: string

    constructor(productionInventoryId: number, lotNumber: string, saleOrderId: number, soNumber: string, variantCode: string, inputQuantity: number, plantId?: number, createdUser?: string, saleOrderItemId?: number, operationType?: OperationTypeEnum, nextOperation?: OperationTypeEnum, status?: FgStatusEnum, physicalQuantity?: number,poNumber?:string) {
        this.productionInventoryId = productionInventoryId;
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.soNumber = soNumber;
        this.variantCode = variantCode;
        this.inputQuantity = inputQuantity;
        this.plantId = plantId;
        this.createdUser = createdUser;
        this.saleOrderItemId = saleOrderItemId;
        this.operationType = operationType;
        this.nextOperation = nextOperation;
        this.status = status;
        this.physicalQuantity = physicalQuantity;
        this.poNumber = poNumber;
    }
}


