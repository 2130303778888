export class QcdefectWorkstaionResponse{
   
    workstationId:number;
    workstation:string
    constructor(workstationId:number,workstation:string)
    {

this.workstationId=workstationId;
this.workstation=workstation;
    }
}