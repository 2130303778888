import { AssetDownTimeDetails } from "./asset-down-time-details.model";
import { AssetMaintenanceInfoModel } from "./asset-maintanance-info.model";
import { DownTimeDetailsModel } from "./down-time-details.model";

export class AssetCategoryWiseMaintenanceDetail {
    assetCategory: string;
    assetCount: number;
    assetValue: number;
    assetMaintenanceDetails: AssetMaintenanceInfoModel[];
    assetDownTimeDetails: DownTimeDetailsModel[];
    top5AssetsDownTimeDetails: AssetDownTimeDetails[];
  
    constructor(
      assetCategory: string,
      assetCount: number,
      assetValue: number,
      assetMaintenanceDetails: AssetMaintenanceInfoModel[],
      assetDownTimeDetails: DownTimeDetailsModel[],
      top5AssetsDownTimeDetails: AssetDownTimeDetails[]
    ) {
      this.assetCategory = assetCategory;
      this.assetCount = assetCount;
      this.assetValue = assetValue;
      this.assetMaintenanceDetails = assetMaintenanceDetails;
      this.assetDownTimeDetails = assetDownTimeDetails;
      this.top5AssetsDownTimeDetails = top5AssetsDownTimeDetails;
    }
  }