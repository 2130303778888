import { SoProcessTypesEnum } from "@gtpl/shared-models/common-models"

export class saleOrder {
  saleOrderId: number;
  plantId?: number;
  poNumber?:string;
  poQuantity?:number;
  itemId?:number;

  /**
  * @param saleOrderId
  * */


  constructor(saleOrderId: number, plantId?: number,poNumber?:string,poQuantity?:number,itemId?:number) {
    this.saleOrderId = saleOrderId;
    this.plantId = plantId;
    this.poNumber=poNumber;
    this.poQuantity=poQuantity;
    this.itemId=itemId;
  }
}

export class SaleOrderNumberRequest {
  saleOrderNumber: string;
  plantId?: number;

  /**
   * 
   * @param saleOrderNumber 
   * @param plantId 
   */
  constructor(saleOrderNumber: string, plantId?: number) {
    this.saleOrderNumber = saleOrderNumber;
    this.plantId = plantId;
  }
}
export class ItemRequest {
  saleOrderId: number;
  categoryId?: number;
  isPreparedMaterial?:boolean

  /**
   * 
   * @param saleOrderId 
   * @param categoryId 
   * @param isPreparedMaterial
   */
  constructor(saleOrderId: number, categoryId?: number,isPreparedMaterial?:boolean) {
    this.saleOrderId = saleOrderId;
    this.categoryId = categoryId;
    this.isPreparedMaterial = isPreparedMaterial
  }
}

export class SaleOrderTrackFilters {
  selectedEstimatedFromDate: string;
  selectedEstimatedToDate: string;
  saleOrderStatus: string;
  selectedEndCustomer: number;
  unit: any;
  prodUnit?: any;
  destinations?: number[];
  countryId?: number;
  selectedCreatedFromDate?: string;
  selectedCreatedToDate?: string;
  masterBrandId?: number;
  varientCode?: number;
  readyForShipment?: boolean;
  mainCategory?:string[];
  lifeCycleStatus?: string;
  carType?: string
}
export class SaleOrderItemsCasesRequest {
  saleOrderId: number;
  saleOrderItemId: number;
  reportingCases: number;
  reportedDate: Date;
}
export class SaleOrderItemsRequest {
  saleOrderId: number;
  saleOrderItemId: number;
  plantId?: number;
  stockType?: string;
}

export class StoreConsumptionItemsRequest{
  saleOrderId: number;
  saleOrderItemId?: number;
  unitId?: number;
  isPreparedMaterial?:boolean
}

export class SoItemReq{
  saleOrderItemId : number
}

export class SoProcessTypeREQ{
    soProcessType : SoProcessTypesEnum;
    unitId:number;
    barcode?:string;

}

export class StoreConsumptionItemQtyReq{
 saleOrderItemId: number;
 itemId: number;
}

export class SoLifeCycleStatusCategoryReq{
  mainCategory:any
}

