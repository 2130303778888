export class RmPriceListDto {
    rmPriceListId: number;
    date: any;
    minCount: number;
    maxCount: number;
    price: number;
    isActive: boolean;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    
    /**
     * 
     * @param mPriceListId 
     * @param date 
     * @param minCount 
     * @param maxCount 
     * @param price 
     * @param isActive 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(mPriceListId: number, date: any, minCount: number, maxCount: number, price: number, isActive: boolean, createdUser: string, updatedAt: Date,
        updatedUser: string, versionFlag: number) {
            this.rmPriceListId= mPriceListId;
            this.date= date;
            this.minCount= minCount;
            this.maxCount= maxCount;
            this.price= price;
            this.isActive= isActive;
            this.createdUser= createdUser;
            this.updatedAt= updatedAt;
            this.updatedUser= updatedUser;
            this.versionFlag= versionFlag;
    }
}