import { StockTypesEnum } from "@gtpl/shared-models/common-models";

export class InputIdRequest {
    inputId: number;
    dispatchType?: StockTypesEnum;

   
    /**
     * 
     * @param inputId 
     */
    constructor(inputId: number,  dispatchType?: StockTypesEnum){
        this.inputId = inputId;
        this.dispatchType = dispatchType;
    }
}
