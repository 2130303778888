import React, { useEffect, useState } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, Modal, DatePicker, InputNumber, Radio, Table, Tooltip, Divider, Popconfirm, FormInstance } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import TextArea from 'antd/lib/input/TextArea';
import './qc-defect-details-form.css';
import { OperationIdRequest, operationRequest, OperationsDropDown, QaQcData, QaQcDropDown, Qcdefectdto, QcDefectItemDetailsDto, WorkstationDTO } from '@gtpl/shared-models/production-management';
import { QrcodeOutlined, UserOutlined, ScanOutlined, EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';
import { OperationService, QaQcOperationsService, QcDefectService } from '@gtpl/shared-services/production';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { from } from 'rxjs';
import QcDefectItemDetails from '../../../qc-defect-details-grid/src/lib/qcdefect-item-details-grid';
import {QcdefectItemForm} from './qc-defect-items-form';
/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

/* eslint-disable-next-line */
export interface QcDefectDetailsFormProps {
  qcdefectData: Qcdefectdto;
  updateItem: (qcdefectData: Qcdefectdto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}
export function QcDefectDetailsForm(props: QcDefectDetailsFormProps) {
  console.log(props.qcdefectData)
  const [modal, setModal] = useState('')
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [qcdefectItemDetails] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [workstationData, setWorkstationData] = useState<any[]>()
  const [testScandat, settestScandat] = useState<any[]>([]);
  const [testsData, setTestsData] = useState<QaQcDropDown[]>([]);
  const [workstationdropdown, setworkstationdropdown] = useState<WorkstationDTO[]>([]);
  const [Pass, setPass] = useState({});
  const [PassWithDefect, setPassWithDefect] = useState({});
  const [Fail, setFail] = useState({});
  const [page, setPage] = React.useState(1);
  const [tableData, setTableData] = useState<QcDefectItemDetailsDto[]>([]);
  const [qcdefectItemsData, setQcdefectItemsData] = useState<any[]>([]);
  const [indexVal, setIndexVal] = useState(0);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [fieldsData, setFieldsData] = useState<any>(undefined);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [itemform] = Form.useForm();

  const testservice = new QaQcOperationsService;
  const qcDefectiveService = new QcDefectService;
  const operationService = new OperationService();
  const onChange = (event) => {
    const { value, checked } = event.target;
    value === "Pass" && checked
      ? setPass({ background: "green", color: "black" })
      : setPass({});
    value === "Pass With Defect" && checked
      ? setPassWithDefect({ background: "orange", color: "black" })
      : setPassWithDefect({});
    value === "Fail" && checked
      ? setFail({ background: "red" })
      : setFail({});
    form.resetFields(['inspectedQuantity', 'passedQuantity']);
    qcdefectItemDetails.resetFields(['rejectedQuantity'])
  };

  const setvalue = () => {
    let result = form.getFieldValue('testResult')
    let inspectedquantity: number = form.getFieldValue('inspectedQuantity') ? Number(form.getFieldValue('inspectedQuantity')) : 0;
    let passedquantity: number = form.getFieldValue('passedQuantity') ? Number(form.getFieldValue('passedQuantity')) : 0;
    const netvalue = Number(((inspectedquantity) - (passedquantity)));
    console.log(netvalue)
    if (result == 'Pass') {
      form.setFieldsValue({ passedQuantity: inspectedquantity })
      // qcdefectItemDetails.setFieldsValue({rejectedQuantity:0})
    }
    if (result == 'Fail') {
      qcdefectItemDetails.setFieldsValue({ rejectedQuantity: inspectedquantity })
      form.setFieldsValue({ passedQuantity: 0 })

    }

    console.log(result)

  }
  const rejectedvalue = () => {
    let result = form.getFieldValue('testResult')
    let inspectedquantity: number = form.getFieldValue('inspectedQuantity') ? Number(form.getFieldValue('inspectedQuantity')) : 0;
    let passedquantity: number = form.getFieldValue('passedQuantity') ? Number(form.getFieldValue('passedQuantity')) : 0;
    const netvalue = Number(((inspectedquantity) - (passedquantity)));
    if (result == 'Pass With Defect') {
      qcdefectItemDetails.setFieldsValue({ rejectedQuantity: netvalue })
    }
  }
  const getitemDetails = (req) => {
    qcDefectiveService.getQcdefectItemByQcId({ qcId: req }).then(res => {
      if (res.status) {
        setQcdefectItemsData(res.data);
      }
      else {
        if (res.intlCode) {
          setQcdefectItemsData([])
          AlertMessages.getInfoMessage(res.internalMessage)
        }
        else {
          setQcdefectItemsData([])
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err)
      setQcdefectItemsData([]);
    })
  }

  useEffect(() => {
    console.log(props);
    // getAllActiveTesetresults(),
    getAllOperations(),
      getAllworkstations();
    console.log(props.qcdefectData, 'qcdefectData')
    if (props.qcdefectData?.operationId != undefined && props.isUpdate) {
      getAllQcQcActiveTestsForOperation(props.qcdefectData.operationId)
      getitemDetails(props.qcdefectData.qcId)
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
    }
    if (props.isUpdate) {
      deleteData;
    }
  }, [])

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  const handletestresult = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({ testResultId: value, })
    setShowQrScan(false)
  }
  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      lotNumber: value
    })
  }
  const handleWorkstation = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      workstation: value
    })
    getWorkstaioncode(value);

  }
  let history = useHistory()

  const save = () => {
    form.validateFields().then(res => {
      const defectDetails = form.getFieldsValue();
      const defectItemdetails = tableData
      let qcdefectdto = new Qcdefectdto(
        defectDetails.qcId,
        defectDetails.operationId,
        defectDetails.lotNumber,
        defectDetails.workstationId,
        defectDetails.startTime,
        defectDetails.remarks,
        defectDetails.isActive,
        defectDetails.createdAt,
        defectDetails.createdUser,
        defectDetails.unitId,
        defectDetails.inspectedQuantity,
        defectDetails.inspectedCount,
        defectDetails.passedQuantity,
        defectDetails.updatedUser,
        defectDetails.testResult,
        defectDetails.versionFlag,
        defectItemdetails,
      );
      setDisable(true)
      console.log("qcdefectdto")
      console.log(qcdefectdto)

      qcDefectiveService.createQcDefect(qcdefectdto).then(res => {
        setDisable(false)
        if (res.status) {
          AlertMessages.getSuccessMessage('Qcdefect details Created Successfully');
          // history.push("/qcdefect-view")
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }

  // const getQcDefectItemDetails = () => {
  //   const defectitemDetails: QcDefectItemDetailsDto[] = []
  //   itemDetailsGridData.forEach(itemGridData => {
  //     console.log(itemDetailsGridData)
  //     defectitemDetails.push(new QcDefectItemDetailsDto(undefined, undefined, itemGridData['testResultId'], itemGridData['rejectedQuantity'], itemGridData['testName']))

  //   })
  // }
  const onReset = () => {
    form.resetFields(['testResultName', 'lotNumber', 'workstationId', 'shift', 'remarks', 'inspectedCount', 'inspectedQuantity', 'passedQuantity', 'testResult']);
    setPassWithDefect([]);
    setFail([]);
    setPass([]);

  };
  const resetItemForm = () => {
    setBtnChange(false);
    qcdefectItemDetails.resetFields();
  };
  const saveData = (values: Qcdefectdto) => {
    setDisable(false)
    if (props.isUpdate) {
      props.updateItem(values);
    } else {
      setDisable(false)
      save();

    }

  };
  const deleteData = (index) => {
    console.log(index)
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }
  const setEditForm = (rowData, index) => {
    setIndexVal(index);
    setBtnChange(true);
    console.log(tableData)
    qcdefectItemDetails.setFieldsValue({
      qcdefectitemId: !isUpdate ? rowData.qcdefectitemId : Number(rowData.qcdefectitemId),
      testResultId: !isUpdate ? rowData.testResultId : rowData.testResultId,
      rejectedQuantity: !isUpdate ? rowData.rejectedQuantity : rowData.rejectedQuantity,
      isUpdate: true
    });
    setFieldsData(rowData);


  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },


    {
      title: 'Defect Name',
      dataIndex: 'testName',
      width: '140px'
    },

    {
      title: 'Rejected quantity',
      dataIndex: 'rejectedQuantity',
      width: '100px',
      align: 'right'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '100px',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData) {
                  console.log(rowData);
                  rowData.isUpdate = true
                  setEditForm(rowData, index);
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title='dlete'>
            <Popconfirm title='Sure to delete?' onConfirm={e => { deleteData(index); }}>
              <MinusCircleOutlined
                style={{ color: '#1890ff', fontSize: '14px' }} />
            </Popconfirm>

          </Tooltip>
        </span>)
    }


  ];


  const onFocus = () => {
    console.log('focus');
  }
  const onBlur = () => {
    console.log('blur');
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const getAllOperations = () => {
    operationService.getAllOperationsDropDown().then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        setOperations([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setOperations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllworkstations = () => {
    qcDefectiveService.getAllActiveWorkstation().then(res => {
      if (res.status) {
        setworkstationdropdown(res.data);
      } else {
        setworkstationdropdown([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setworkstationdropdown([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getWorkstaioncode = (value) => {
    if (value != undefined) {
      qcDefectiveService.getWorkstation({ workstationCode: value }).then(res => {
        if (res.data) {
          if (res.data.length > 0) {
            form.setFieldsValue({
              workstation: res.data[0].workstation,
              workstationId: res.data[0].workstationId
            })

            setWorkstationData(res.data)

          } else {
            form.resetFields(['workstation'])
            AlertMessages.getErrorMessage('No workstation Found for scanned  code')
          }
        } else {
          form.resetFields(['workstation'])
          AlertMessages.getErrorMessage('No workstation Found for scanned code')

        }
      }).catch(err => {
        // AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage("Scann Valid QR Code");

    }

  }

  const gettestResultforoperation = (value) => {
    if (value != undefined) {
      qcDefectiveService.getTestnameforOperationForScan({ testCode: value }).then(res => {
        if (res.data) {
          if (res.data.length > 0) {
            form.setFieldsValue({
              testResultId: res.data[0].testName,
            })

            settestScandat(res.data)

          } else {
            form.resetFields(['testResultId'])
            AlertMessages.getErrorMessage('No testresult Found for scanned  code')
          }
        } else {
          form.resetFields(['testResultId'])
          AlertMessages.getErrorMessage('No testresult Found for scanned code')

        }
      }).catch(err => {
        // AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage("Scann Valid QR Code");

    }

  }


  const getWorkStationDetails = (value) => {
    setShowQrScan(true);
    setModal('workstation')
    getWorkstaioncode(value);
  }
  const gettestresultname = (value) => {
    setShowQrScan(true);
    setModal('testResultId')
    gettestResultforoperation(value);
  }
  const getAllQcQcActiveTestsForOperation = (operationId) => {
    if (operationId) {
      const opRequest = new OperationIdRequest(operationId)
      testservice.getAllQcQcDropDownForOperation(opRequest).then(res => {
        if (res.status) {
          setTestsData(res.data);
        } else {
          setTestsData([]);
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setTestsData([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }
  const appendData = (val, row) => {
    console.log(val + "***" + row.value);
    // console.log(val+"***"+row.qcdefectItemDetails.getFieldValue('restResultId'));
    console.log(val + "***" + row.label);
    const { label } = row;
    console.log(label);
    if (row = 'rejectedQuantity') {
      setFieldsData({ ...fieldsData, [row]: val });
    }
    else {
      setFieldsData({ ...fieldsData, [label]: row });
    }
    console.log(fieldsData)
  }
  // const addDefectDetails = () => {
  //   console.log(btnChange)
  //   qcdefectItemDetails.validateFields().then(res => {
  //   // setFieldsData(res);
  //     console.log(res);
  //     let tableName = tableData;
  //     let duplicateData;
  //     //  const details = []
  //     // details.push({ testResultId: qcdefectItemDetails.getFieldValue('testResultId'), rejectedQuantity: qcdefectItemDetails.getFieldValue('rejectedQuantity'), testName: testsData.find((i) => i.qaQcTestId == qcdefectItemDetails.getFieldValue('testResultId')).testName })
  //     // setTableData(prevValue => [...prevValue, ...details])
  //     // qcdefectItemDetails.resetFields(['testResultId', 'rejectedQuantity'])
  //     if (!btnChange) {
  //       duplicateData = tableName.find(item => (item.rejectedQuantity == fieldsData.rejectedQuantity && item.testResultId == fieldsData.testResultId));
  //     }
  //     else {
  //       duplicateData = tableName.find(item => ((item.rejectedQuantity == fieldsData.rejectedQuantity && item.testResultId == fieldsData.testResultId) || item.testResultId == fieldsData.testResultId));
  //     }
  //     console.log("duplicateData");
  //     console.log(duplicateData);
  //     if (duplicateData) {
  //       AlertMessages.getErrorMessage('Defect already exist. ');
  //       onReset();
  //     } else {
  //       console.log(btnChange)
  //       if (!btnChange) {
  //         qcdefectItemDetails.validateFields().then(res => {
  //           setShowTable(true);
  //           console.log(fieldsData)
  //           console.log(res.testResultId);
  //           fieldsData.testResultId = res.testResultId;
  //           fieldsData.rejectedQuantity = res.rejectedQuantity;
  //           let dynTableData = [...tableData, fieldsData]
  //           console.log(fieldsData);
  //           console.log(dynTableData)
  //           setTableData(dynTableData);
  //           setShowTable(true);
  //           // props.addDataToItemDetails(dynTableData);
  //         })
  //       } else {

  //         let dynTableData = [...tableData]

  //         // dynTableData[indexVal].itemCategory=fieldsData.itemCategory
  //         dynTableData[indexVal].testResultId = fieldsData.testResultId
  //         dynTableData[indexVal].rejectedQuantity = fieldsData.rejectedQuantity
  //         // let dynTableData = [...tableData]
  //         // dynTableData[indexVal] = fieldsData
  //         // console.log(dynTableData);
  //         setShowTable(true);
  //         setTableData(dynTableData);
  //         // props.addDataToItemDetails(dynTableData);



  //       }
  //       resetItemForm()
  //       // onReset();
  //       console.log(tableData)
  //     }
  //   })
  // };

  const addDefectDetails = () => {
    console.log(qcdefectItemDetails);
    const isUpdate = qcdefectItemDetails.getFieldValue('isUpdate')
    console.log(isUpdate);
    if (btnChange) {
      let dynTableData = [...tableData]
      console.log(dynTableData)
      console.log(fieldsData)

      // dynTableData[indexVal].itemCategory=fieldsData.itemCategory
      dynTableData[indexVal].testResultId = fieldsData.testResultId
      dynTableData[indexVal].testName = fieldsData.testName
      dynTableData[indexVal].rejectedQuantity = fieldsData.rejectedQuantity

      // let dynTableData = [...tableData]
      // dynTableData[indexVal] = fieldsData
      // console.log(dynTableData);
      setShowTable(true);
      setTableData(dynTableData);
      qcdefectItemDetails.resetFields(['testResultId', 'rejectedQuantity'])
      setBtnChange(false);
      // setShowTable(true); 
      // fieldsData.rejectedQuantity = qcdefectItemDetails.getFieldValue('rejectedQuantity');
      // let dynTableData = [...tableData, fieldsData]
      // // const details = tableData
      // // console.log(tableData);
      // // details[indexVal].rejectedQuantity = qcdefectItemDetails.getFieldValue('rejectedQuantity');
      // // console.log(details);
      // setTableData(dynTableData)

    } else {
      const details = []
      details.push({ testResultId: qcdefectItemDetails.getFieldValue('testResultId'), rejectedQuantity: qcdefectItemDetails.getFieldValue('rejectedQuantity'), testName: testsData.find((i) => i.qaQcTestId == qcdefectItemDetails.getFieldValue('testResultId')).testName })
      setTableData(prevValue => [...prevValue, ...details])
      qcdefectItemDetails.resetFields(['testResultId', 'rejectedQuantity'])
      setShowTable(true);

    }
  }

  const getItemsDetails = (val) => {
    console.log(val)
    
  }
  return (

    <Form form={form} layout={'vertical'} onFinish={saveData} initialValues={props.qcdefectData}>
      <Card title={<span style={{ color: 'white' }}>Qc Defect</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/qcdefect-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >

        <Row gutter={24}>
          <Form.Item
            name="qcId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>

          <Form.Item
            name="workstationId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="unitId" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >
            <Form.Item name="lotNumber" label="Lot Number"
              rules={[
                {
                  required: true,
                  message: 'lotNumber  is required'
                }
              ]}>
              <Input placeholder="Scan lot number"
                suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('lotNumber'); }} style={{ fontSize: '28px', marginTop: '3px', marginRight: '-6px' }} />} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item
              name="workstationId"
              label="Workstation"
              rules={[
                {
                  required: true, message: 'Workstation Name is required'

                },

              ]}>
              <Select
                showSearch
                placeholder="Select or scan workstation "
                // onChange={getAllQcQcActiveTestsForOperation}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<QrcodeOutlined onClick={(e) => { getWorkStationDetails(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px' }} />}
              >
                <Option key={0} value={null}>Select workstation</Option>
                {workstationdropdown.map((value) => {
                  return <Option key={value.workStationId} value={value.workStationId}>{value.workstation}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item
              name="operationId"
              label="Operation"
              rules={[
                {
                  required: true, message: 'Operation Name is required'

                },

              ]}>
              <Select
                showSearch
                placeholder="Select Operation Category"
                onChange={getAllQcQcActiveTestsForOperation}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Operations</Option>
                {operations.map((operation) => {
                  return <Option key={operation.operationId} value={operation.operationId}>{operation.operationName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

        </Row>


        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            modal == 'lotNumber' ? <QrScanner handleScan={handleQrScan} /> : modal == 'workstation' ? <QrScanner handleScan={handleWorkstation} /> : modal === 'testResultId' ? <QrScanner handleScan={handletestresult} /> : null
          }

        </Modal>

      </Card>
      <Card title={<span style={{ color: 'white' }}>Lot details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Row gutter={24}>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >

            <Form.Item name="inspectedCount" label="Inspected Count"
              rules={[
                {
                  required: true,
                  message: "Enter tested Count"
                },
                {
                  pattern: /^[0-9\b]+$/,
                  message: `Should contain only numbers`
                },
              ]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item name="testResult" label="Test Result" rules={[{ required: true, message: 'Missing Test Result' }]} >
              <Radio.Group buttonStyle="solid" onChange={onChange}>
                <Radio.Button value="Pass" className='ant-radio-checked' style={Pass}>Pass</Radio.Button>
                <Radio.Button value="Pass With Defect" style={PassWithDefect}>Pass with Defect</Radio.Button>
                <Radio.Button value="Fail" style={Fail}>Fail</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >

            <Form.Item name="inspectedQuantity" label="Inspected Quantity"
              rules={[{ required: true, message: 'Missing Quantity Tested' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={setvalue} />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >
            <Form.Item name="passedQuantity" label="Passed Quantity"
            // rules={[{ required: true, message: 'Missing Rejection Quantity' }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} onChange={rejectedvalue} />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }} >
            <Form.Item name="remarks" label="Remarks" >
              <TextArea rows={4}></TextArea>
            </Form.Item>

          </Col>


        </Row>
        {/* <Row>
        <QcdefectItemForm itemdetailsform={itemform} addDataToItemDetails={getItemsDetails} />

        </Row> */}
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 14 }} style={{ paddingRight: '-8px' }}
            hidden={form.getFieldValue('testResult') == 'Fail' || form.getFieldValue('testResult') == 'Pass With Defect' ? false : true}
          >
            <Form form={qcdefectItemDetails} layout={'vertical'}
              initialValues={qcdefectItemsData}>
              <Form.Item
                name="qcdefectitemId" style={{ display: "none" }} >
                <Input hidden />
              </Form.Item>
              <Card title={<span style={{ color: 'white' }}>Add Defects</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0, width: '747px' }}>

                <Row gutter={24}>
                  <Form.Item
                    name="isUpdate" style={{ display: "none" }} >
                    <Input hidden />
                  </Form.Item>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 10 }}>

                    <Form.Item name="testResultId" label="Defect"
                      rules={[{
                        required: form.getFieldValue('testResult') == 'Fail' || form.getFieldValue('testResult') == 'Pass With Defect' ? true : false,

                        message: 'Missing Test'
                      }]}>
                      <Select
                        placeholder="Select Defect"
                        allowClear
                        onChange={appendData}
                        suffixIcon={<ScanOutlined onClick={(e) => { gettestresultname(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px' }} />}
                      >
                        <Option key={0} value={null}>Select Defect</Option>
                        {testsData.map((test) => {
                          return <Option key={test.qaQcTestId} value={test.qaQcTestId}>{test.testName}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }} >

                    <Form.Item name="rejectedQuantity" label="Rejected Quantity"
                      rules={[{
                        required: form.getFieldValue('testResult') == 'Fail' || form.getFieldValue('testResult') == 'Pass With Defect' ? true : false,
                        message: 'Missing Rejection Quantity'
                      }]}
                    >
                      <InputNumber style={{ width: '100%' }} min={0} onChange={e => appendData(e, 'rejectedQuantity')} />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }} style={{ marginTop: '27px' }} >

                    <Button style={{ backgroundColor: "#67b32d", color: "white" }} htmlType="button" onClick={addDefectDetails}>
                      {btnChange ? 'UPDATE' : 'ADD'}
                    </Button>
                   

                  </Col>
                </Row>
                <Row>
                  {showTable ?
                    <Table
                      rowKey={record => record.qcId}
                      columns={columnsSkelton}
                      dataSource={props.isUpdate ? qcdefectItemsData : tableData}
                      pagination={false}
                      scroll={{ y: 300 }}
                      onChange={onChange}
                      size='small'
                      bordered />
                    : ""}
                </Row>

              </Card>

            </Form>
          </Col>

        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate === false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Card>
    </Form>

  );
}

export default QcDefectDetailsForm;
