import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PalletDto } from '.';


export class CreatePalletsResponseModel extends GlobalResponseObject{
    data?: PalletDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: PalletDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 