import { GrnItemsForReturn } from "./grn-item-details-for-return.dto";

export class GrnDetailsForReturn {
    grnId: number;
    vendorId: number;
    vendorName: string;
    grnDate: Date;
    grnReturnDate: Date;
    invoiceDate: Date;
    invoiceNumber: string;
    grnNumber: string;
    totalCost: number;
    remarks: string;
    grnItems: GrnItemsForReturn[];
    createdUser: string;
    plantId: number;
}