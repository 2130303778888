
export class ContainerReportPlantsData {
    plantId:number;
    qty:number;
    
    /**
     * 
     * @param plantId 
     * @param qty 
     */
    constructor(plantId:number,qty:number) {
        this.plantId = plantId;
        this.qty = qty;    
    }
    
}