import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleorderTypeDto } from './sale-order-type-dto';

export class AllSaleorderTypeResponseModel extends GlobalResponseObject {
    data?: SaleorderTypeDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleorderTypeDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}

