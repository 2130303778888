import axios from 'axios';
import connection from './connection';
import {AllPlantCapacityResponse, PlantCapacityDto, PlantRequest,} from '@gtpl/shared-models/planning'
import {CodeListDto, CodeListResponseModel, CodeListStatus} from '@gtpl/shared-models/planning'


export class PlantCapacityService {
  URL = connection.DEPLOY_URL + '/plant-capacity';

  async createPlantCapacity(plantCapacityReq: PlantCapacityDto[]): Promise<AllPlantCapacityResponse> {
    return await axios
      .post(this.URL + '/createPlantCapacity', plantCapacityReq)
      .then((res) => {
        return res.data;
      });
  }
  async getCapacityByPlant(plantReq: PlantRequest): Promise<AllPlantCapacityResponse> {
    return await axios
      .post(this.URL + '/getCapacityByPlant', plantReq)
      .then((res) => {
        return res.data;
      });
  }
  async updatePlantCapacity(plantCapacityReq: PlantCapacityDto[]): Promise<AllPlantCapacityResponse> {
    return await axios
      .post(this.URL + '/updatePlantCapacity', plantCapacityReq)
      .then((res) => {
        return res.data;
      });
  }
  

  


  
}
