import React, { useCallback, useEffect, useRef, useState } from 'react';

import './variant-operations-form.css';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Form, Select, InputNumber } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { MenuOutlined } from '@ant-design/icons';
// import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SkuDto } from '@gtpl/shared-models/masters';
import update from 'immutability-helper';
import { OperationService,VariantOperationsService } from '@gtpl/shared-services/production';
import { SkuService } from '@gtpl/shared-services/masters';
import { VarientIdRequest, VarientOperationsDTO } from '@gtpl/shared-models/production-management';
import { SequenceDataModel } from 'libs/shared-models/production-management/src/lib/varient-operations/sequence-data.model';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
const { Option } = Select;


/* eslint-disable-next-line */
// export interface VariantOperationsFormProps {}
const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex }:any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item:any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};
export function VariantOperationsForm(
  // props: VariantOperationsFormProps
) {


  const [form] = Form.useForm();
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [page, setPage] = React.useState(1);

  const [data, setData] = useState<any[]>([]);
  const [variantCode, setVariantCode] = useState<string>();


  const skuService = new SkuService();
  const operationService = new OperationService()
  const variantOperationService = new VariantOperationsService()

  useEffect(()=>{
    getSkuCodes();
  },[])

  useEffect(()=>{
    setTableData(data);
  },[data])

  const saveOperationSequence = () => {
    setBtnDisable(true);
    console.log(JSON.parse(localStorage.getItem('unit_id')));
    const operationsData = form.getFieldsValue();
    let seqModel:SequenceDataModel[] = [];
    data.forEach(eachRecord => {
      const seqDto = new SequenceDataModel(eachRecord.sequence,eachRecord.operationId,eachRecord.operationName,eachRecord.sequence,(eachRecord.sequence-1),(eachRecord.sequence-1),undefined,true,undefined,JSON.parse(localStorage.getItem('username')),Number(JSON.parse(localStorage.getItem('unit_id'))),eachRecord.isValueAddition)
      seqModel.push(seqDto);
    })
    const operationDto = new VarientOperationsDTO(operationsData.varientCode,variantCode,seqModel)
    variantOperationService.createVariantOperation(operationDto).then((res) => {
      if (res.status) {
        
        AlertMessages.getSuccessMessage('Variant Operation Sequence created successfully');
        setBtnDisable(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
    
  }


  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkucode(res.data);
      }
      else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setSkucode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });
  }

  const getTableData = (val,row) => {
    console.log(row);
    console.log(val);
    setShowTable(true);
    setVariantCode(row.children);
    getOpeartions(val);

  }

  const getOpeartions = (val) => {
    operationService.getAllOperations(new VarientIdRequest(val)).then((res) => {
      if (res) {
        console.log(res.data)
        setData(res.data);
      }
      else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setData([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });
  }

  const deleteData = (index) => {
    data.splice(index, 1);
    setData([...data]);
    if (data.length == 0) {
      setShowTable(false)
    }
  }

  const storeGroupDependancy = (e, index,data) => {
  }
  
  const columns = [
    
    {
      title: 'Operation',
      dataIndex: 'operationName',
      key: 'operation',
      className: 'drag-visible',
    },
    {
      title: 'Sequence',
      key: 'sequence',
      dataIndex:'sequence',
      render: (text, object, index) =>{
        object.sequence = index+1
        return index+1
      } 
    },
    {
      title: 'Operation Group',
      key: 'operationGroup',
      render: (text, data, index) => {
        return <span><InputNumber min={0} defaultValue={index+1} onChange={e => storeGroupDependancy(e, index,data)} /></span>
        // return <span>{data.brandId.children}</span>
      }
      
    },
    {
      title: 'Reporting Required',
      key: 'isReporting',
      dataIndex:'isReporting',
      render: (text, data, index) => {
        let defaultVal = data.isReporting;
        if(data.operationName==OperationTypeEnum.PACKING){
           defaultVal = 'NO';
        }else{
          
           defaultVal = 'YES';
        }
        return <span><Select
        placeholder="Select"
        allowClear
        defaultValue={defaultVal}
    >
        <Option value="YES">YES</Option>
        <Option value="NO">NO</Option>

    </Select></span>
        // return <span>{data.brandId.children}</span>
      },
      className: 'drag-visible',
      
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
           <Popconfirm  onConfirm={() => {
              if (rowData) {
                deleteData(index)
                // openFormWithData(rowData);
              }
            }} title='Are you sure to delete?' >
          <Tooltip placement="top" title='Delete'>
            <MinusCircleOutlined style={{ color: '#1890ff', fontSize: '14px' }} />
          </Tooltip>
          </Popconfirm>

        </span>)
    }
    // {
    //   title: 'Group Dependency',
    //   key: 'groupDependency',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={text} onChange={e => storeGroupDependancy(e, index,data)} /></span>
    //     // return <span>{data.brandId.children}</span>
    //   }
      
    // },
    // {
    //   title: 'Operation Dependancy',
    //   key: 'operationDependency',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={text} onChange={e => storeGroupDependancy(e, index,data)} /></span>
    //     // return <span>{data.brandId.children}</span>
    //   }
      
    // },
    // {
    //   title: 'Gain Or Loss',
    //   key: 'gainOrLoss',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={0} /></span>
    //     // return <span>{data.brandId.children}</span>
    //   }
      
    // },
   
  ];

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex:any, hoverIndex:any) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [data],
  );

  
  
  
  
  return (
    <Form  autoComplete="off" layout="vertical" form={form}>
      

    <>
    <DndProvider backend={HTML5Backend}>
    <Card>
    <Row>
      <Col span={8}>
                <Form.Item label="Variant Code"
                  name={'varientCode'}
                  rules={[{ required: true, message: 'Missing Varient Code name' }]}
                >
                  <Select
                    allowClear
                    placeholder="Select Variant Code"
                    onChange={getTableData}
                    showSearch
                    style={{width:'100%'}}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    
                  >
                    {skucodes.map(skudropData => {
                      return <Option label={'varientCode'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
      </Row>
      {
        showTable?
        <>
        {tableData[0]?.isUpdate ? <p style={{fontSize:20,color:'orange'}}>Existed operation sequence for selected variant</p> : <></>}
        <Table pagination={false}
      columns={columns}
      dataSource={data}
      components={components}
      onRow={(record:any,index:any) => ({
        index,
        moveRow,
      })}
      />
      <br />
      <Row justify="end">
        <Col>
          <Form.Item >

            <br />
            {tableData[0]?.isUpdate ? <></> :
            <Button type='primary' onClick={saveOperationSequence} disabled={btnDisable} block >
              Submit
                    </Button>
}
          </Form.Item>
        </Col>
      </Row>
      </>
        :<></>
      }
      
    
      </Card>
  </DndProvider>
  </>
  </Form>
  );
}

export default VariantOperationsForm;
