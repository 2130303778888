import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { AssetLicenseDto } from 'libs/shared-models/asset-management/src/lib/asset-license/asset-license.dto';
// import {GrnRejectionReasonService} from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';
// import {GrnRejectionReasonsForm} from '@gtpl/pages/master/master-components/grn-rejection-reasons-form';
import { Link } from 'react-router-dom';
import { AssetLicenseForm } from '@gtpl/pages/asset-management/asset-management-components/asset-license-form';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLicenseService } from '@gtpl/shared-services/asset-management';
import moment from 'moment';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { IconMap } from 'antd/lib/result';

/* eslint-disable-next-line */
export interface AssetLicenseGridProps { }

export function AssetLicenseGrid(
  props: AssetLicenseGridProps
) {
  const [searchText, setSearchText] = useState('');
  const { Option } = Select;
  const [form] = Form.useForm()
  const plantsService = new UnitcodeService();
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const service = new AssetLicenseService();
  const [selectedReasonData, setSelectedReasonData] = useState<any>(undefined);
  const [AssetLicenseData, setAssetLicenseData] = useState<AssetLicenseDto[]>([]);
  const [selectedunit, setUnit] = useState<number>(0)
  const [plantName, setPlantName] = useState<any>([]);
  const role = JSON.parse(localStorage.getItem('role'))


  const openFormWithData=(viewData: AssetLicenseDto)=>{
    viewData.expiryDate?viewData.expiryDate=moment(viewData.expiryDate):null;
    console.log(viewData)
    viewData.renewalStartDate?viewData.renewalStartDate=moment(viewData.renewalStartDate):null;
    viewData.renewalStartDate= viewData.renewalStartDate ? moment(viewData.renewalStartDate):moment(viewData.expiryDate)
    viewData.renewalExpiryDate = viewData.renewalExpiryDate ? moment(viewData.renewalExpiryDate) :null
    setDrawerVisible(true);
    setSelectedReasonData(viewData);
  }

  useEffect(() => {
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plant: Number(localStorage.getItem('unit_id')) })
    }
    getAllPlants();
    getAll();
  }, []);
  const getAll = () => {
    const req = new AssetByStatus();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      req.plantId = form.getFieldValue('plant')
    }
    // req.plantId=Number(localStorage.getItem('unit_id'))
    service.getAllAssetsLicense(req).then(res => {
      if (res.status) {
        setAssetLicenseData(res.data);
      } else {
        if (res.intlCode) {
          setAssetLicenseData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAssetLicenseData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const deleteReason = (Data: AssetLicenseDto) => {
    Data.isActive = Data.isActive ? false : true;
    service.activateorDeactivateLicense(Data).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    // {
    //   title: 'License Code',
    //   dataIndex: 'licenseId',
    //   responsive: ['sm'],
    //   sorter: (a, b) => a.licenseId.localeCompare(b.licenseId),
    //    sortDirections: ['descend', 'ascend'],
    //     ...getColumnSearchProps('licenseId')
    // },
    {
      title: 'Unit',
      dataIndex: 'plantName',
      sorter: (a, b) => String(a.plantName)?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),

    },
    {
      title: 'License Name',
      dataIndex: 'certificateName',
      //  responsive: ['lg'],
      sorter: (a, b) => a.certificateName.localeCompare(b.certificateName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('certificateName')
    },
    {
      title: 'License Number',
      dataIndex: 'licenseNumber',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.licenseNumber).localeCompare(String(b.licenseNumber)),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('licenseNumber')
    },
    {
      title: 'Reference Number',
      dataIndex: 'referenceNumber',
      //  responsive: ['lg'],
      sorter: (a, b) => a.referenceNumber - b.referenceNumber,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('referenceNumber')
    },
    {
      title: 'License Issuer',
      dataIndex: 'company',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.company).localeCompare(String(b.company)),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('company')
    },
    {
      title: 'Responsibility',
      dataIndex: 'responsibility',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.responsibility).localeCompare(String(b.responsibility)),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('responsibility')
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      render: (text, record) => {
        return (
          <>
            {/* {record.expiryDate ? moment(record.expiryDate).format('YYYY-MM-DD') : '-'} */}
            {record.renewalExpiryDate ? moment(record.renewalExpiryDate).format('YYYY-MM-DD') : moment(record.expiryDate).format('YYYY-MM-DD')}
          </>
        )
      },
    },

    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      )
      // filters: [
      //   {
      //     text: 'Active',
      //     value: true,
      //   },
      //   {
      //     text: 'InActive',
      //     value: false,
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.isActive === value;
      // },

    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage('You Cannot Edit Deactivated License');
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteReason(rowData); }
          }
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate  ?'
                : 'Are you sure to Activate  ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];
  
  const updateReason = (Data: AssetLicenseDto) => {
    console.log(Data)
    service.updateLicense(Data).then(res => {
      console.log(res);

      if (res.status) {
        console.log(res)
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const handleUnit = (value) => {
    setUnit(value);
    getAll()
  }



  return (
    <Card title={<span style={{ color: 'white' }}>Asset License</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to="/asset-license-form"  ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <br></br>
      <Form form={form}>
        <Row gutter={40}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="plant"
              label="Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            // initialValue={Number(localStorage.getItem("unit_id"))}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
              >
                {plantName.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Card title={'Total Asset License: ' + AssetLicenseData.length} style={{ textAlign: 'left', width: 220, height: 38, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          {/* <Col>
           <Card title={'Active: ' + AssetLicenseData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + AssetLicenseData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col> */}
        </Row>
      </Form>
      <br></br>
      <Table
        rowKey={record => record.productId}
        columns={columnsSkelton}
        dataSource={AssetLicenseData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        scroll={{ x: true }}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <AssetLicenseForm key={Date.now()}
            updateReason={updateReason}
            isUpdate={true}
            assetLicenseData={selectedReasonData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}

export default AssetLicenseGrid;
