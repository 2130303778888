
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Typography, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeOpsData, LotNoInfo } from '@gtpl/shared-models/production-management';
import { LotCodeOpsService, ProdInvService } from '@gtpl/shared-services/production';
import Link from 'antd/lib/typography/Link';
import { Redirect } from 'react-router-dom';
import './production-inventory-grid.css';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { SoInfoViewPages } from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages';
import { LotDetailView } from '@gtpl/pages/production-management/production-management-components/lot-detail-view'

/* eslint-disable-next-line */
export interface ProductionInventoryGridProps { }

export function ProductionInventoryGrid(
  props: ProductionInventoryGridProps
) {
  const plantId = JSON.parse(localStorage.getItem('unit_id'))
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { Text } = Typography;
  const [saleId, setSaleId] = useState(0);
  const [lotINo, setLotINo] = useState('lotNumber');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [prodInvData, setProdInvData] = useState<LotCodeOpsData[]>([]);

  const productionInventoryService = new LotCodeOpsService();



  useEffect(() => {
    getAllLotOps();

  }, []);

  const getAllLotOps = () => {
    const unitIdReq: UnitRequest = {
      unitId: plantId
    }

    console.log(plantId)
    productionInventoryService.getAllLotOps(unitIdReq).then(res => {
      if (res.status) {
        setProdInvData(res.data);
        console.log(prodInvData)
      } else {
        if (res.intlCode) {
          setProdInvData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setProdInvData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  // const activateorDeactivateprodInv = (prodInvData:  LotCodeOpsData) => {
  //   prodInvData.weighmentReq=prodInvData.weighmentReq?false:true;
  //   productionInventoryService.activateorDeactivateprodInv(prodInvData).then(res => { console.log(res);
  //     if (res.status) {
  //        getAllLotOps();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }



  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleCancel = () => {
    getAllLotOps();
    setIsModalVisible(false);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid", saleOrderId);
    setIsModalVisible(true)
  }

  const goToLotDetailView = (lotNumber: string) => {
    setLotINo(lotNumber);
    console.log("lotNo", lotNumber);
    setIsModalVisible(true)
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'LOT Code',
      dataIndex: 'concatLotNum',
      width: '180px',
      // responsive: ['lg'],
      sorter: (a, b) => a.concatLotNum.localeCompare(b.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('concatLotNum'),
      render: (value, record) =>
        <Link onClick={e => goToLotDetailView(record.concatLotNum)} >{record.concatLotNum} </Link>
    },

    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      sorter: (a, b) => a.grnDate.localeCompare(b.grnDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnDate'),
      responsive: ['md'],
      render: (value, record: LotCodeOpsData, index) => {
        return moment(record.grnDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'GRN Code',
      dataIndex: 'grnNumber',
      sorter: (a, b) => a.grnNumber.localeCompare(b.grnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnNumber')
    },
    // {
    //   title: 'Farmer Name',
    //   dataIndex: 'supplierName',
    //   sorter: (a,b) => a.supplierName?.localeCompare(b.supplierName),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('supplierName')
    // },
    // {
    //   title: 'Farmer Code',
    //   dataIndex: 'supplierCode',
    //   sorter: (a,b) => a.supplierCode.localeCompare(b.supplierCode),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('supplierCode')
    // },

    // title: 'SO Number',
    // dataIndex: ' soNumber',
    // sorter: (a,b) => a.soNumber.localeCompare(b.soNumber),
    // responsive: ['md'],
    // sortDirections: ['descend', 'ascend'],
    // ...getColumnSearchProps('soNumber'),

    // render: (value, record) => 
    //   <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.soNumber}</Link>

    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      width: '180px',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) =>
        <Link onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>
    },
    {
      title: 'SO Item',
      dataIndex: 'itemName',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Count',
      dataIndex: 'count',
      // responsive: ['lg'],
      align: 'center',

    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
      // responsive: ['lg'],

    },

  ]



  /**
    * 
    * @param pagination 
    * @param filters 
    * @param sorter 
    * @param extra 
    */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  /**
  * 
  * @param selectedKeys 
  * @param confirm 
  * @param dataIndex 
  */


  return (
    <>
      {/* {(saleId) ? <Redirect  to={{ pathname: "/so-info-view", state: saleId }}/> : null} */}
      <Card title={<span style={{ color: 'white' }}>Recived Lots Info</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <br></br>
        <Row gutter={40}>
          <Col>
            <Card title={'Total: ' + prodInvData.length} style={{ textAlign: 'left', width: 215, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          {/* <Col>
           <Card title={'Active: ' + certificationData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + certificationData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col> */}
        </Row>
        <br></br>

        <Table
          rowKey={record => record.prodInvId}
          columns={columnsSkelton}
          dataSource={prodInvData}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalQuantity = 0;

            pageData.forEach(({ quantity }) => {
              totalQuantity += Number(quantity);

            });

            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={1} colSpan={7} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                </Table.Summary.Row>

              </>


            );
          }
          }
        />

        <Modal
          className='so-info-view'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >
          <SoInfoViewPages saleOrderId={saleId} screen='production' />
          <LotDetailView lotNumber={lotINo} />
        </Modal>

      </Card>
    </>
  );
}

export default ProductionInventoryGrid;



