export class AssetsReportDto{
    assetCode:string;
    assetName:string;
    ownerOfAsset:string;
    createdAt:string;
    date:string;
    checkIn:string;
    checkOut:string;
    employeeName:string;
    employeePhNo:string;
    maintainanceType:string;
    costOfRepair:string;
    /**
     * 
     * @param assetCode 
     * @param assetName 
     * @param ownerOfAsset
     * @param createdAt 
     * @param date 
     * @param checkIn 
     * @param checkOut 
     * @param employeeName 
     * @param employeePhNo 
     * @param maintainanceType 
     * @param costOfRepair 
     */

    constructor(assetCode:string,
        assetName:string,
        ownerOfAsset:string,
        createdAt:string,
        date:string,
        checkIn:string,
        checkOut:string,
        employeeName:string,
        employeePhNo:string,
        maintainanceType:string,
        costOfRepair:string){
            this.assetCode = assetCode;
            this.assetName = assetName;
            this.ownerOfAsset = ownerOfAsset;
            this.createdAt = createdAt;
            this.date = date;
            this.checkIn = checkIn;
            this.checkOut = checkOut;
            this.employeeName = employeeName;
            this.employeePhNo = employeePhNo;
            this.maintainanceType = maintainanceType;
            this.costOfRepair = costOfRepair;

    }
}