import { FgStatusEnum, GlobalStatus, PlantsEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class LotCodeData {
  lotCodeOpsId: number;
  grnDate: Date;
  indentId: number;
  grnNumber?: string;
  lotNumber?: string;
  saleOrderId?: number;
  soNumber?: string;
  saleOrderItemId?: number;
  itemName?: string;
  supplierType?: string;
  farmer?: number;
  farmerCode?: number;
  quantity?: number;
  count?: number;
  price?: number;
  boxes?: number;
  plantId?: number;
  subPlantId?: number;
  createdAt?: Date;
  createdUser?: string;
  updatedAt?: Date;
  updatedUser?: string;
  isActive?: boolean;
  versionFlag?: number;

}