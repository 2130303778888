import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ProcurementTypesDto } from './procurement-types-dto';


export class AllProcurementTypesResponseModel extends GlobalResponseObject{
    data?: ProcurementTypesDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ProcurementTypesDto[]){
        super(status, errorCode, internalMessage);
        this.data =  data;
    }
}

