export class AllocationRevereseRequest{
    stockId: number;
    category: string;
    rmStockQty: number;
    minGrade:number;
    maxGrade:number;
    hlPercentage:number;
    finalPercentage:number;
    totalPouches:number;
    shrimpWeight:number;
    uomId:number;
    totalCases:number;
    constructor(stockId:number,category: string, rmStockQty:number, minGrade:number,maxGrade:number, hlPercentage:number,finalPercentage:number,totalPouches:number,shrimpWeight:number,uomId:number,totalCases:number){
        this.stockId = stockId;
        this.category = category;
        this.rmStockQty = rmStockQty;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.hlPercentage = hlPercentage;
        this.finalPercentage = finalPercentage;
        this.totalPouches = totalPouches;
        this.shrimpWeight = shrimpWeight;
        this.uomId = uomId;
        this.totalCases = totalCases;
    }
}
