import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { UploadDetailesResponseDto } from "./upload.detailes.response.dto";

export class UploadDetailesGetResponse extends GlobalResponseObject {
    data?: UploadDetailesResponseDto[]

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: UploadDetailesResponseDto[]){
            super(status,intlCode,internalMessage);
            this.status = status;
            this.intlCode = intlCode;
            this.internalMessage = internalMessage;
            this.data = data;
        }



    


}