import { StockService } from '@gtpl/shared-services/procurement';
import { Button, Card, Input, Tag} from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import {SearchOutlined ,CloseCircleOutlined,CheckCircleOutlined} from '@ant-design/icons';
import moment from 'moment';
import { color } from 'highcharts';
import { CycleCheckerEnum } from '@gtpl/shared-models/common-models';


export interface CycleCheckerTableProps { }

export function CycleCheckerTable(props: CycleCheckerTableProps) {
    const [page, setPage] = React.useState(1);
    const [cycleCheckerData, setCycleCheckerData] = useState<any[]>([]);
    const service = new StockService();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        getCycleCheckerTableData();
    }, [])

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
          text ? (
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : text
          )
            : null
    
      });
    
      /**
       * 
       * @param selectedKeys 
       * @param confirm 
       * @param dataIndex 
       */
      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };

    const getCycleCheckerTableData = () => {
        service.getCycleCheckerTableData().then((res) => {
            if (res.status) {
                setCycleCheckerData(res.data)
                //AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)

        },
        {
            title: 'Plant',
            dataIndex: 'unit',
            ...getColumnSearchProps('unit')
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render : (value , record) =>{
            return <>{record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-'}</>
          }
    
          // ...getColumnSearchProps('createdAt')
        },
        {
           title: 'Location',
           dataIndex :'rackCode',
           ...getColumnSearchProps('rackCode')
        },
        {
            title: 'Brand Name',
            dataIndex: 'brand',
            ...getColumnSearchProps('brand')
        },
        {
            title: 'Item Sub Category',
            dataIndex: 'itemSubCategory',
            ...getColumnSearchProps('itemSubCategory')
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            ...getColumnSearchProps('itemName')
        },
        {
            title: 'Quantity',
            dataIndex: 'physicalQuantity',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (rowData, status) => (
              <>{rowData == CycleCheckerEnum.NOT_APPROVED ? <Tag icon={<CloseCircleOutlined />} color="#f50">{CycleCheckerEnum.NOT_APPROVED}</Tag> : rowData == CycleCheckerEnum.APPROVED ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{CycleCheckerEnum.APPROVED}</Tag> : ""}</>
            ),
            filters :[
              {text :CycleCheckerEnum.APPROVED , value:CycleCheckerEnum.APPROVED },
              {text :CycleCheckerEnum.NOT_APPROVED , value:CycleCheckerEnum.NOT_APPROVED },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              return record.status == value;
            },
        },
    ]
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    return (
        <>
            <Card size="small" style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
            <Table
                    rowKey={record => record.saleOrderId}
                    columns={columns}
                    dataSource={cycleCheckerData}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size='small'
                    bordered
                />
            </Card>
        </>
    )
}
export default CycleCheckerTable;