export class TypesOfValueAddition2Dto{
    valueAdditionTwoId:number;
    code:string;
    name:string;
    remarks:string;
    createdUser:string;
    updatedUser:string;

    isActive:boolean;
    
    /**
     * 
     * @param valueAdditionTwoId This is a number
     * @param code This is a string
     * @param name This is a string
     * @param remarks This is a string
     * @param createdUser This is a string
     * @param updatedUser This is a string
     * 
     * @param isActive This is a boolean
     */
    constructor(valueAdditionTwoId:number,code:string,name:string,remarks:string,createdUser:string,updatedUser:string,isActive:boolean){
    this.valueAdditionTwoId = valueAdditionTwoId;
    this.code = code;
    this.name = name;
    this.remarks = remarks;
    this.createdUser = 'Admin';
    this.updatedUser = 'Admin';

    this.isActive = isActive
    }
} 