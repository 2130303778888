export class TrimsDropDownDto{
    trimsId:number;
    trimName:string;

  /**
   * 
   * @param trimsId 
   * @param trimName 
   */
    constructor(trimsId:number, trimName:string){
         this.trimsId = trimsId;
         this.trimName = trimName;
    }
}