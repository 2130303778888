import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DeheadingQaQcDto } from './deheading-qa-qc.dto';

export class DeheadingSampleJobResponse extends GlobalResponseObject{
    data?: DeheadingQaQcDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: DeheadingQaQcDto){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}