import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { FarmersDropDownDto } from '.';

export class SupplierFarmResponse extends GlobalResponseObject{
    data?: FarmersDropDownDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: FarmersDropDownDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
