import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { EmployeeGetLeavesDto } from "./employee-leaves-dto";


export class EmployeeLeavesResponse extends GlobalResponseObject {
    data?: EmployeeGetLeavesDto[];

 /**
  * 
  * @param status 
  * @param intlCode 
  * @param internalMessage 
  * @param data 
  * 
  * 
  */

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: EmployeeGetLeavesDto[]) {
        super(status, intlCode, internalMessage);

        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;


    }







}