export class ExpensesCategoryDto {
    expensesCategoryId?: number;
    expensesCategory: string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(expensesCategoryId: number, expensesCategory: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.expensesCategoryId = expensesCategoryId;
        this.expensesCategory = expensesCategory;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}