import { ConsumptionReportRequest } from '@gtpl/shared-models/procurement-management';
import { StoreConsumptionService } from '@gtpl/shared-services/procurement';
import Table, { ColumnProps } from 'antd/lib/table';
import { QrcodeOutlined, PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Button, Modal } from 'antd';

export interface PlaceListPrintProps { }

export function PlaceListPrint(props: PlaceListPrintProps) {
    const [printData, setPrintData] = useState<any[]>([])
    const [page, setPage] = React.useState(1);
    const [imageUrl, setImageUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [showQRModal, setShowQRModal] = useState('')
    const [rowData, setRowData] = useState<any>()
    const storeConsumptionService = new StoreConsumptionService();

    useEffect(() => {
        getAvailableConsumedStockForSO()
    }, [])
    const getAvailableConsumedStockForSO = (onReset?: boolean) => {
        const req = new ConsumptionReportRequest();
        storeConsumptionService.getAvailableConsumedStockForSO(req).then(res => {
            if (res.status) {
                setPrintData(res.data)
            } else {
                if (res.intlCode) {
                    setPrintData([]);
                }
            }
        })

    }

    const generateQR = async (record) => {
        try {
            const response = await QRCode.toDataURL(record.saleOrder);
            console.log(record, 'res')
            setImageUrl(response);
            setIsModalVisible(true);
            setShowQRModal('QRModal');
            setRowData(record)


        } catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        getAvailableConsumedStockForSO();
        setIsModalVisible(false);
    };

    const printOrder = () => {
        const divContents = document.getElementById('printme').innerHTML;
        const element = window.open('', '', 'height=700, width=1024');
        element.document.write(divContents);
        element.document.close();
        element.print();
        element.close(); // to close window when click on cancel/Save
        setIsModalVisible(true); // model should be open
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'SO Num',
            dataIndex: 'saleOrder'
        },
        {
            title: 'Brand',
            dataIndex: 'brand'
        },
        {
            title: 'Rack Position',
            dataIndex: 'rackPosition'
        },
        {
            title: 'Pallet Code',
            dataIndex: 'palletCode'
        },
        {
            title: 'SO Num',
            dataIndex: 'soNum'
        },
        {
            title: 'Cartons',
            dataIndex: 'cartons'
        },
        {
            title: 'Action',
            dataIndex: 'generateQr',
            render: (value, record) => {
                return <> 
                <QrcodeOutlined onClick={(e) => { generateQR(record) }} style={{ color: '#1890ff', fontSize: '30px' }} />
                </>
            }
        }

    ]
    const onChange = (pagination, filters, sorter, extra) => {
    }
    return (
        <>
            <Table
                rowKey={record => record.storeConsumptionId}
                columns={columns}
                dataSource={printData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered

            >
            </Table>
            <Modal
                className='so-info-view'
                key={'modal' + Date.now()}
                width={showQRModal ? '30%' : '80%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={isModalVisible}
                title={<React.Fragment>
                </React.Fragment>}
                onCancel={handleCancel}
                footer={[

                ]}
            >
                {
                    showQRModal == 'QRModal' ?
                        <>
                            {imageUrl ? (
                                <html>
                                    <head>
                                    </head>
                                    <body id='printme'>
                                        <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                                        <div className="ticket">
                                            <h3 className="centered">SANDHYA AQUA </h3>
                                            <a href={imageUrl} download>
                                                <img src={imageUrl} alt="img" style={{ width: '100%' }} />
                                            </a>
                                            <p className="centered">{rowData ? rowData?.saleOrder : ''}
                                            </p>
                                            <p style={{ textAlign: 'center' }}>Description</p>
                                            <p style={{ textAlign: 'center' }}>{rowData ? rowData.saleOrder : ''}</p>
                                        </div>
                                    </body>
                                </html>
                            )
                                : null}

                        </> :
                        ''
                }
            </Modal>

        </>
    )

}
export default PlaceListPrint;