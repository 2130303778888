export class InvoiceCustomerDetailsModel {
    clientCode : string;            
    clientName : string; 
    addLineOne: string;
    addLineTwo: string;
    addLineThree: string;
    addLineFour: string;
    addressType: string;
    country: string;

  /**
   * 
   * @param clientCode 
   * @param clientName 
   * @param addLineOne 
   * @param addLineTwo 
   * @param addLineThree 
   * @param addLineFour 
   * @param addressType 
   * @param country
   */
    constructor (clientCode : string, clientName : string, addLineOne: string, addLineTwo: string, addLineThree: string, addLineFour: string, addressType: string, country: string) { 
      this.clientCode=clientCode;
      this.clientName=clientName;
      this.addLineOne=addLineOne;
      this.addLineTwo=addLineTwo;
      this.addLineThree=addLineThree;
      this.addLineFour=addLineFour;
      this.addressType=addressType;
      this.country=country;
    }
}