import { subPlantHistoryReport } from "@gtpl/shared-models/production-management"

export class GRNLocationPropsRequest {
    grnUnitId: number 
    vendorId: number
    vendorName: string
    itemId: number
    itemName: string
    brandId: number
    invoiceNumber:string
    receivedQty:number
    saleOrderId:number
    itemCategoryId:number
    itemSubCategoryId:number
    unitPrice:number
    grnId:number
    grnItemId:number
    sizeId:number
    poId:number
    locationId?:number
    physicalQuantity?:number
    locationStatus?:string
    createdUser?:string
    grnNo?:string
    balance?:number
    constructor(grnUnitId: number,vendorId: number,vendorName: string,itemId: number,itemName: string,brandId: number,invoiceNumber:string,receivedQty:number,saleOrderId:number,itemCategoryId:number,itemSubCategoryId:number,unitPrice:number,grnId:number,grnItemId:number,sizeId:number,poId:number,locationId?:number,physicalQuantity?:number,locationStatus?:string,createdUser?:string,grnNo?:string,balance?:number) {
        this.grnUnitId = grnUnitId
        this.vendorId = vendorId
        this.vendorName = vendorName
        this.itemId = itemId
        this.itemName = itemName
        this.brandId = brandId
        this.invoiceNumber = invoiceNumber
        this.receivedQty = receivedQty
        this.saleOrderId = saleOrderId
        this.itemCategoryId = itemCategoryId
        this.itemSubCategoryId = itemSubCategoryId
        this.unitPrice = unitPrice
        this.locationId = locationId
        this.physicalQuantity = physicalQuantity
        this.locationStatus = locationStatus
        this.grnId = grnId
        this.grnItemId = grnItemId
        this.poId = poId
        this.sizeId = sizeId
        this.createdUser = createdUser
        this.grnNo=grnNo
        this.balance=balance
    }
}
