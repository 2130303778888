import { UserRequestDto } from '@gtpl/shared-models/common-models';
import { AllInsurancesResponseModel, GetInsuranceResponseModel, InsuranceDto, InsuranceRequest, InsuranceTypeRequest } from '@gtpl/shared-models/masters';
import { Body } from '@nestjs/common';
import axios from 'axios';
import connection from './connection';

export class InsuranceService {
URL = connection.DEPLOY_URL + '/insurance';

async createInsurance(req: InsuranceDto): Promise<GetInsuranceResponseModel> {
    return await axios.post(this.URL + '/createInsurance',req)
        .then(res => {
            return res.data
        })     
}
async getAllInsurances(req?:UserRequestDto): Promise<AllInsurancesResponseModel> {
    return await axios.post(this.URL + '/getAllInsurances',req)
        .then(res => {
            return res.data
        })     
}
async getInsuranceByType(req: InsuranceTypeRequest): Promise<AllInsurancesResponseModel> {
    return await axios.post(this.URL + '/getInsuranceByType',req)
    .then(res => {
        return res.data
    })     
}
async  updateInsurance(insuranceDto: InsuranceDto): Promise<GetInsuranceResponseModel> {
    return await axios.post(this.URL + '/updateInsurance', insuranceDto)
           .then(res => {
               return res.data
           })
}
async activateOrDeactivateInsurance(insuranceReq: InsuranceRequest): Promise<GetInsuranceResponseModel> {
    console.log(insuranceReq);
    return await axios.post(this.URL + '/activateOrDeactivateInsurance', insuranceReq)
           .then(res => {
               return res.data
           })
}

}