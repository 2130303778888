import React,{useState,useEffect} from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { WorkstationDTO } from '@gtpl/shared-models/production-management';
import { WorkstationService} from '@gtpl/shared-services/production';
import { WorkstationCategoryService} from '@gtpl/shared-services/production';
import { WorkstationCategoryDTO } from '@gtpl/shared-models/production-management';

import './workstations-form.css';

/* eslint-disable-next-line */
export interface WorkstationFormProps {
  workstationData:WorkstationDTO;
  updateWorkstation:(workstation:WorkstationDTO)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function WorkstationsForm(
  props: WorkstationFormProps
) {

  const workstationcategoryService = new WorkstationCategoryService;
  const [selectedWorkstationCategory, setSelectedWorkstationCategory] =useState<WorkstationCategoryDTO>();
  const [workstationCategoryData, setWorkstationCategoryData] = useState<WorkstationCategoryDTO[]>([]);
  const { Option } = Select;
  const [disable, setDisable] = useState<boolean>(false)


    useEffect(()=> {getAllActiveWorkstationCategories()},[])



  const [form] = Form.useForm();
  let history = useHistory();

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }

  const workstationService = new WorkstationService()

  const createWorkstation=(workstationData:WorkstationDTO)=>{
    setDisable(true)
    workstationData.unitId=Number(localStorage.getItem('unit_id'));
    workstationService.createWorkstation(workstationData).then(res => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Workstation Category Created Successfully');
        history.push("/workstations-grid");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    form.resetFields();
    
  }
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }

  const getAllActiveWorkstationCategories=() =>{
    workstationcategoryService.getAllActiveWorkstationCategories().then(res => {
      if(res.status){
        // AlertMessages.getSuccessMessage('Countries Retrived successfully');
        setWorkstationCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
 const [display, setDisplay] = useState<string>('none');
  const handleWorkstationCategory=(value)=>{
    setSelectedWorkstationCategory(value);
    if(value == ''){
      setDisplay('block');
    } else {
      setDisplay('none');
    }
     
  }
  


  const saveData = (values: WorkstationDTO) => {
    setDisable(false)
    if(props.isUpdate){
      console.log(values)
      props.updateWorkstation(values);
    }else{
      setDisable(false)
      console.log(values)
      createWorkstation(values);
    }
  
  };
  return (
    <Card title={<span style={{color:'white'}}> Workstation </span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate==true?"":<Link to='/workstations-grid' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.workstationData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="workstationId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row >
    <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>

            <Form.Item
                name="workstationCategoryId"
                label="Workstation Category "
                rules={[
                  {
                    required: true,
                    message:'Workstation Category Name is required'   
                  },
                 ]}>
                <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Workstation Category"
                optionFilterProp="children"
                onChange={handleWorkstationCategory}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Workstation Category</Option>
                {workstationCategoryData.map((workstationCategoryData)=>{
                  return <Option key={workstationCategoryData.workstationCategoryId} value={workstationCategoryData.workstationCategoryId}>{workstationCategoryData.workstationCategory}</Option>
                })}
              </Select>
              </Form.Item>
        </Col><br/>
    
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="workstation"
                  label="Work Station"
                  rules={[
                    {
                      required: true,message:'Work Station is required'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="workstationCode"
                  label="Work Station Code"
                  rules={[
                    {
                      required: true,message:'Work Station Code is required'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="workstationUnitCapacity"
                  label="Work Station Unit Capacity"
                  rules={[
                    {
                      required: true,message:'Pallet No is required'
                      
                    },
                    {
                      pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                      message: `Don't Allow Spaces`
                    }
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>


          </Row>
          <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
          
          <Button type="primary"disabled={disable} htmlType="submit" >
            Submit
          </Button>
          {(props.isUpdate === false) &&
       <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
          Reset
        </Button>
        }
    
          
      </Col>
          </Row>
          
      </Form>
    </Card>

  );
}

export default WorkstationsForm;
