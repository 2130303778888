import { LeaveApprovalStatusEnum } from "@gtpl/shared-models/common-models";

export class OTStatusRequest {
    otId: number;
    employeeCode: string;
    status: LeaveApprovalStatusEnum;
    updatedUser: string;

    constructor(otId: number, employeeCode: string, status: LeaveApprovalStatusEnum, updatedUser: string) {
        this.otId = otId;
        this.employeeCode = employeeCode;
        this.status = status;
        this.updatedUser = updatedUser;
    }
}