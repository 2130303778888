export class BomWithCountAndQuantityModel {
    soNumber:string;
    variantCode:string;
    count:number;
    quantity:number;
    hlCount?:number;
    poNumber?:string

    constructor(soNumber:string,variantCode:string,count:number,quantity:number,hlCount?:number,poNumber?:string){
        this.soNumber = soNumber;
        this.variantCode = variantCode;
        this.count = count;
        this.quantity = quantity;
        this.hlCount = hlCount;
        this.poNumber=poNumber;
    }
}