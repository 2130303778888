import React, { useEffect, useRef, useState } from 'react';
import { Tree, Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import './lot-tracking-report.css';
import { LotCodeOpsService, ProdlogService, ProductionInventoryService } from '@gtpl/shared-services/production'
import { LotCodeDropDown, LotInformationRequest, LotNoReq, LotTrackingResponseDto, TreeStructureData } from '@gtpl/shared-models/production-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
/* eslint-disable-next-line */
export interface LotTrackingReportProps { }

export function LotTrackingReport(
  props: LotTrackingReportProps
) {
  const [form] = Form.useForm();
  const [display, setDisplay] = useState<string>('none');
  const [table, setTable] = useState<boolean>(false);
  const { Option } = Select;
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const [lotNumber, setLotNumber] = useState("");
  const [page, setPage] = React.useState(1);
  const [lotTrackingData, setLotTrackingData] = useState<LotTrackingResponseDto[]>(undefined);

  const lotCodeService = new LotCodeOpsService();
  
  const handleChange = (value) => {
    setDisplay("none");
    setLotNumber(value);
  };

  useEffect(() => {
    getAllLotNumbers();
  }, [])

  const getAllLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const req = new UnitRequest(plant);

    lotCodeService.getAllLotNumbers(req).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Work Station',
      dataIndex: 'workStation',
      sorter: (a, b) => a.workStation.localeCompare(b.workStation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Assigned Time',
      dataIndex: 'assignedTime',
      render: (text, record) => { return record.assignedTime != undefined ? moment(record.assignedTime).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.assignedTime).unix() - moment(b.assignedTime).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Physical Quantity',
      dataIndex: 'physicalQuantity',
      align:'right',
      sorter: (a, b) => a.physicalQuantity-b.physicalQuantity,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Issued Quantity',
      dataIndex: 'issuedQuantity',
      align:'right',
      sorter: (a, b) => a.issuedQuantity-b.issuedQuantity,
      sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Job Status',
      dataIndex: 'jobStatus',
      sorter: (a, b) => a.jobStatus.localeCompare(b.jobStatus),
      sortDirections: ['descend', 'ascend'],
    },
    
    
  ];


  const Demo = (props) => {
    const [expandedKeys, setExpandedKeys] = useState([props.reqData]);

    const [selectedKeys, setSelectedKeys] = useState(props.reqData);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [treeData, setTreeData] = useState<any>(undefined);

    const invService = new ProductionInventoryService();
    const logService = new ProdlogService();

    useEffect(() => {
      getLotTrackingReportDetails(props.reqData);
    }, [props.reqData])

    const getLotTrackingReportDetails = (lot) => {
      const req = new LotInformationRequest(lot, Number(localStorage.getItem('unit_id')));
      invService.getLotTrackingReportDetails(req).then((res) => {
        if (res.status) {
          setTreeData(res.data);
          setDisplay("block");
        } else {
          setTreeData(undefined);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setTreeData(undefined);
      });
    }
    const getTableData = (lot:string, operation:OperationTypeEnum, plantId:number) => {
      console.log("1");
      const req = new LotInformationRequest(lot, Number(localStorage.getItem('unit_id')),operation);
      logService.getLotTrackingReportData(req).then((res) => {
        console.log(res.data);
        if (res.status) {
          setLotTrackingData(res.data);
          setDisplay("block");
        } else {
          setLotTrackingData(undefined);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setLotTrackingData(undefined);
      });
    }


    const onExpand = (expandedKeysValue) => {
      console.log('onExpand', expandedKeysValue); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.

      setExpandedKeys(expandedKeysValue);
      setAutoExpandParent(false);
    };
    const onSelect = (selectedKeysValue, info) => {
      console.log('onSelect', info);
      console.log(selectedKeysValue);
      console.log(info.node.key);
      setSelectedKeys(info.node.key);
      setTable(true);
      console.log(expandedKeys);
      console.log(treeData);
      getTableData(form.getFieldValue("lotNumber"), info.node.key, Number(localStorage.getItem('unit_id')));
    };

    return (
      <Tree
        style={{ display: display }}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
      />
    );
  }
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  
  return (
    <Card title={<span style={{ color: 'white' }}>Lot Tracking Report</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <br></br>
      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="lotNumber"
              label="Lot Number"
            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Lot Number"
                optionFilterProp="children"
                onChange={handleChange}
              >
                <Option key={0} value={null}>Select Lot</Option>
                {lotNumbers.map((lot) => {
                  return <Option key={lot.lotNumber} value={lot.lotNumber}>{lot.conacatlotnplantlot}</Option>
                })}


              </Select>
            </Form.Item>
            {console.log(lotNumber)}
          </Col>
          <Col style={{ marginTop: '30px' }}>
            <Button style={{ marginRight: '4px' }} onClick={() => setDisplay('block')}>Get Report</Button>

          </Col>
        </Row>


        <br /><br />

      </Form>

      <Row>
        <Col span={4}><Demo reqData={lotNumber} /></Col>
        <Col span={20}>{table?<Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          scroll={{x:true}}
          dataSource={lotTrackingData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />:null}</Col>
      </Row>

    </Card>
  );

}
export default LotTrackingReport;

