

export class AssetsCatagoryDto {
    assetsCatagoryId: number;
    assetsCatagoryName: string;
    assetsClassificationId: number;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag: number;

    /**
     *
     * @param assetsCatagoryId
     * @param assetsClassificationId
     * @param assetsCatagoryName
     * @param createdUser
     * @param isActive
     */
    constructor(

        assetsCatagoryId: number,
        assetsCatagoryName: string,
        assetsClassificationId: number,
        createdUser: string,
        updatedUser: string,
        isActive: boolean,
        versionFlag: number,
        


    ) {
        this.assetsCatagoryId = assetsCatagoryId;
        this.assetsClassificationId = assetsClassificationId;
        this.assetsCatagoryName = assetsCatagoryName;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;

    }
}
