import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";

export class ContainerDetailsModel {
    containerNumber: string;
    lineSeal: string;
    eSeal: string;
    blNo: string;
    scac: string;
    bookingNo: string;
    status: ContainerStatusEnum;
    refNo: string;
    trackingNo:string;
    vesselName:string;
    portOfTranshipment:string;
    portOfDischarge:string;
    importingVesselName:string;
    dateOfTranshipment:string;
    shippedOnboardDate:string;
    dischargeDate:string;
    destinationDate:string;

    constructor (containerNumber: string, lineSeal: string, eSeal: string, blNo:string,scac:string,bookingNo:string,status: ContainerStatusEnum,refNo:string,
    trackingNo:string,
    vesselName:string,
    portOfTranshipment:string,
    portOfDischarge:string,
    importingVesselName:string,
    dateOfTranshipment:string,
    shippedOnboardDate:string,
    dischargeDate:string,
    destinationDate:string,) {
       this.containerNumber=containerNumber;
       this.lineSeal=lineSeal;
       this.eSeal=eSeal;
       this.blNo=blNo;
       this.scac=scac;
       this.bookingNo=bookingNo;
       this.status=status;
       this.refNo=refNo;
       this.trackingNo= trackingNo;
        this.vesselName= vesselName;
        this.portOfTranshipment= portOfTranshipment;
        this.portOfDischarge= portOfDischarge;
        this.importingVesselName= importingVesselName;
        this.dateOfTranshipment= dateOfTranshipment;
        this.shippedOnboardDate= shippedOnboardDate;
        this.dischargeDate= dischargeDate;
        this.destinationDate= destinationDate;
    }
}