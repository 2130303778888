import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {PODetailViewInfoDTO} from './po-detail-view-dto'

export class PoAllDetailViewResponseModel extends GlobalResponseObject {
    data?: PODetailViewInfoDTO[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PODetailViewInfoDTO[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}