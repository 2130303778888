import { ApiProperty } from '@nestjs/swagger';


export class AssignStockInputRequest{
    saleOrderId:number;
    itemcategoryId:number;
    sizeId:number;
    itemId:number;
    unitId?:number;
    unitPrice?:number;
    requiredQuantity:number;
    existingQuantity:number;

    constructor(saleOrderId: number, itemcategoryId: number, sizeId: number, itemId: number, requiredQuantity: number, existingQuantity: number, unitPrice?: number, unitId?: number) {
        this.saleOrderId = saleOrderId;
        this.itemcategoryId = itemcategoryId;
        this.sizeId = sizeId;
        this.itemId = itemId;
        this.unitId = unitId;
        this.unitPrice = unitPrice;
        this.requiredQuantity = requiredQuantity;
        this.existingQuantity = existingQuantity;
    }
    
  }