export class DesignationActionDto{
    designationActionId: number;
    employeeId: number;
    designationDate:any;
    employeeCode?:string;
    employeeName?: string;
    fromDesignation:string;
    toDesignation:string;
    department:string;
    toDepartment:string;
    approvedBy:string;
    createdAt: Date | any;
    createdUser: string;
    isActive: boolean;
    updatedUser: string;
    versionFlag: number;
   //  fromDepartmentId:number;
   //  toDepartmentId:number;

    constructor(
    designationActionId: number,employeeId: number,designationDate:any,employeeCode:string,employeeName: string,fromDesignation:string,toDesignation:string,approvedBy:string,createdAt: Date | any,createdUser: string,isActive: boolean,updatedUser: string,versionFlag: number,department:string,toDepartment:string)
       {
          this.designationActionId = designationActionId;
          this.employeeId = employeeId;
          this.designationDate=designationDate;
          this.employeeCode=employeeCode;
          this.employeeName = employeeName;
          this.department = department;
          this.toDepartment = toDepartment;
          this.fromDesignation = fromDesignation;
          this.toDesignation = toDesignation;
          this.approvedBy=approvedBy;
          this.createdAt=createdAt;
          this.createdUser=createdUser;
          this.isActive = isActive;
          this.updatedUser = updatedUser;
          this.versionFlag = versionFlag;
         //  this.fromDepartmentId=fromDepartmentId;
         //  this.fromDepartmentId=toDepartmentId;
        }
  }