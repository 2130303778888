import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { WorkStationsResponseDto } from "./workstations-response.dto";

export class workstationsStatusResponseModel extends GlobalResponseObject{
    data?: WorkStationsResponseDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: WorkStationsResponseDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}