export enum IndiaStatesEnum{
    Andhra_Pradesh = 'Andhra Pradesh',
    Arunachal_Pradesh ='Arunachal Pradesh',
    Assam = 'Assam',
    Bihar = 'Bihar',
    Chhattisgarh = 'Chhattisgarh',
    Goa = 'Goa',
    Gujarat = 'Gujarat',
    Haryana = 'Haryana',
    Himachal_Pradesh = 'Himachal Pradesh',
    Jammu_and_Kashmir = 'Jammu and Kashmir',
    Jharkhand = 'Jharkhand',
    Karnataka = 'Karnataka',
    Kerala = 'Kerala',
    Madhya_Pradesh = 'Madhya Pradesh',
    Maharashtra = 'Maharashtra' ,
    Manipur = 'Manipur',
    Meghalaya = 'Meghalaya',
    Mizoram = 'Mizoram',
    Nagaland = 'Nagaland',
    Odisha = 'Odisha',
    Punjab = 'Punjab',
    Rajasthan = 'Rajasthan',
    Sikkim =  'Sikkim',
    Tamil_Nadu = 'Tamil Nadu',
    Telangana = 'Telangana',
    Tripura = 'Tripura',
    Uttar_Pradesh = 'Uttar Pradesh',
    Uttarakhand = 'Uttarakhand',
    West_Bengal = 'West Bengal',










    }