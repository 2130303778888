import { JobCompletedEnum, OperationTypeEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";

export class InventoryBeHeadingDashboardDto {
    productionLogId?: number;
    workstation: string;
    lotNumber:string;
    jobNumber?:string;
    quantity:number;
    wastageQuantity:number
    jobStatus?:JobCompletedEnum;
    assignedTime:string;
    createdUser: string;
    updatedUser: string;
    subLotNumber?:string;
    workstationType?: WorkStationTypeEnum;
    operation?:OperationTypeEnum;
    plantId?: number;
    subPlantId?: SubPlantCodesEnum;
    dcNumber?:string;
    mrnNumber?:string;
    saleOrderId?:number;
    saleOrder?:string;
    saleOrderItemId?:number;
    boxes?:number;
    isReceived?:JobCompletedEnum;
    opBoxes?:number;
    physicalQuantity?:number;
    constructor(
        productionLogId: number,
        workstation: string,
        lotNumber:string,
        jobNumber:string,
        quantity:number,
        wastageQuantity:number,
        jobStatus:JobCompletedEnum,
        assignedTime:string,
        createdUser: string,
        updatedUser: string,
        ){

            this.productionLogId  = productionLogId;
            this.workstation  = workstation;
            this.lotNumber  = lotNumber;
            this.jobNumber  = jobNumber;
            this.workstation  = workstation;
            this.quantity  = quantity;
            this.wastageQuantity = wastageQuantity;
            this.jobStatus  = jobStatus;
            this.assignedTime  = assignedTime;
            this.createdUser  = createdUser;
            this.updatedUser  = updatedUser;
    }
}
