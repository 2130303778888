import { LogBookItems } from "./log-book-items.dto";

export class ContainerLogBookDTO{
    containerLogBookId: number;
    saleOrderId: number;
    brandId: number;
    containerNo: string;
    loadingStartTime: string;
    loadingEndTime: string;
    loadingDate: Date;
    unitId: number;
    filePath: string;
    fileName: string;
    isActive:boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    LogBookInfo: LogBookItems[];
    vehicleNo:string;
    
    constructor(containerLogBookId: number,saleOrderId: number,brandId: number,containerNo: string,loadingStartTime: string,loadingEndTime: string,loadingDate: Date,unitId: number,filePath: string,fileName: string,isActive:boolean,createdAt: Date,createdUser: string,updatedAt: Date,updatedUser: string,versionFlag: number,LogBookInfo: LogBookItems[],vehicleNo:string){
        this.containerLogBookId = containerLogBookId;
        this.saleOrderId = saleOrderId;
        this.brandId = brandId;
        this.containerNo = containerNo;
        this.loadingStartTime = loadingStartTime;
        this.loadingEndTime = loadingEndTime;
        this.loadingDate = loadingDate;
        this.unitId = unitId;
        this.fileName = fileName;
        this.filePath = filePath;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.LogBookInfo = LogBookInfo;
        this.vehicleNo = vehicleNo;

    }
}