import { RackTypeEnum } from "@gtpl/shared-models/common-models";

export class RackDropDownDto {
    rackId:number;
    rackCode:string;
    zoneId?:number;
    rackType?:RackTypeEnum;
    cnt?:number;
    plantId?:number;

    // rackName:string;

    /**
     * 
     * @param rackId 
     * @param rackName 
     */
    constructor(rackId:number, rackCode:string, zoneId?:number,rackType?:RackTypeEnum,cnt?:number,plantId?:number){
        this.rackId = rackId;
        this.rackCode=  rackCode;
        this.zoneId=  zoneId;
        this.rackType=  rackType;
        this.cnt=  cnt;
        this.plantId=  plantId;

        // this.rackName=  rackName;
    }
}