export class IndentMaterialDropDownDto{
    indentMaterialPreparationId: number;
    indentCode: string;
    constructor(
    indentMaterialPreparationId: number,
    indentCode: string,
    ){
        this.indentMaterialPreparationId=indentMaterialPreparationId;
        this.indentCode=indentCode;
    }
    
    }