import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DocumentsUploadData } from './documents-upload.dto';

export class GetAllDocApprovalResponse extends GlobalResponseObject{
    data?: DocumentsUploadData[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data DocumentsUploadData[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: DocumentsUploadData[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}