import { CarrierType } from "@gtpl/shared-models/common-models";

export class ReWeighingDto{
    rmReWeighingId: number;
    carrierType:CarrierType;
    expectedCntQty:string;
    count:number;
    noOfCratesOrNets:number;
    quantity:number;
    plantId: number;
    indentId: number;
    indentItemId: number;
    grnId:number;
    grnItemId:number;
    createdUser: string;
    updatedUser : string;
    isActive : boolean;
}