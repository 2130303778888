import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { IndentDropdownModel } from './indent-dropdown.model';

export class IndentDropdownResponse extends GlobalResponseObject{
    data?: IndentDropdownModel[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: IndentDropdownModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 