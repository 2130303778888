import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import './packing-stock-transfer.css';
import { PackingStockTransferItems } from './packing-stock-transfer-items';
import { IndentMaterialDropDownDto, PackingStockTransferData } from '@gtpl/shared-models/procurement-management'
import { ItemCategoryDropDownDto, PlantsDropDown } from '@gtpl/shared-models/masters';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { GlobalStatus, IsPreparedMaterial, UnitRequest } from '@gtpl/shared-models/common-models';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { StockService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
const { Option } = Select;
/* eslint-disable-next-line */
export interface PackingStockTransferMainProps {
  indentData: IndentMaterialDropDownDto[];
  plantInfo: PlantsDropDown[];
  itemCategories: ItemCategoryDropDownDto[];
  form: FormInstance<any>;
  // saleOrders: SaleOrderDropDownDto[];
  isSoTransfer: (values:boolean,val1:number) => void;
  getSaleOrderItems: () => void;
  getIndentItems: (value:number) => void;
  indentItemsData : any[];
  setTransferType: (value:boolean) => void;
  indentId : number;
  indentAgainst: string;
  itemCategoriesDropDown: () => void;
  so:number;
  isPreparedMaterial: (values:boolean) => void;
  job_type: (values:string) => void;
} 


export function PackingStockTransferMain(
  props: PackingStockTransferMainProps
) {
  useEffect(() => {
    props.form.setFieldsValue({ transferType: GlobalStatus.NO });
    props.form.setFieldsValue({ isPreparedMaterial: IsPreparedMaterial.NO});
  }, []);
  useEffect(() => {
    if(props.indentId > 0){
      console.log(props);
      props.form.setFieldsValue({ transferMethod: 'INDENT' });
      console.log(props?.indentData);
      props.form.setFieldsValue({indentId: props.indentId});
      props.getIndentItems(props.indentId);
      onChangeTransferType('INDENT');
      setIsTransferMethodDisabled(true)
      setIsIndentDisabled(true)
      if(props.indentAgainst === "SALE ORDER"){
        props.form.setFieldsValue({ transferType: GlobalStatus.YES })
      }
      else if(props.indentAgainst === "DUMMY"){
        props.form.setFieldsValue({ transferType: GlobalStatus.NO })
      }
      console.log(props.form.getFieldValue('saleOrderId'));
      changeTransferType();
      console.log(props.form.getFieldValue('saleOrderId'));
      props.form.setFieldsValue({"saleOrderId" : props.so})
      console.log(props.form.getFieldValue('saleOrderId'));
      props.form.setFieldsValue({fromSaleOrderId : 0})
      setDisableSO(true);
    }
  }, [props.indentId]);
  // useEffect(() => {
  //   console.log(props.indentData)
  // },[props?.indentData])
  // useEffect(() => {
  //   console.log(props.indentItemsData)
  //   if(props.indentItemsData[0]?.saleOrderId === 0){
  //     setDisableSO(true);
  //   }
  //   else{
  //     setDisableSO(false);
  //   }
  //   props.form.setFieldsValue({ saleOrderId: props.indentItemsData[0]?.saleOrderId })

  // },[props?.indentItemsData])
  const [isSo, setIsSo] = useState<boolean>(false);
  const [toPlantHidden, setToPlantHidden] = useState<boolean>(true);
  const [indentHidden, setIndentHidden] = useState<boolean>(false);
  const [isAgainstSODisabled, setIsAgainstSODisabled] = useState<boolean>(true);
  const [isTransferMethodDisabled, setIsTransferMethodDisabled] = useState<boolean>(false);
  const [isIndentDisabled, setIsIndentDisabled] = useState<boolean>(false);
  const [disableSO, setDisableSO] = useState<boolean>(false);
  const [isDateDisabled, setIsDateDisabled] = useState<boolean>(true);
  const [transferTypeVisible, setTransferTypeVisible] = useState<boolean>(false);
  const [saleOrders, setSaleOrders] = useState<SaleOrderDropDownDto[]>([]);
  const [allSaleOrders, setAllSaleOrders] = useState<SaleOrderDropDownDto[]>([]);
  const [fromsaleOrders, setFromSaleOrders] = useState<SaleOrderDropDownDto>(undefined);

  const skService = new StockService();
  const soService = new SaleOrderService();

  
  const dropDrownInfo = (key, value) => <Option key={key} value={key}>{value}</Option>

  const changeTransferType = () => {
    const transferType = props.form.getFieldValue('transferType');

    props.form.setFieldsValue({ saleOrderId: null });
    if(transferType=== GlobalStatus.NO){
      setIsSo(false)
      props.isSoTransfer(false,0);
    }else{
      setIsSo(true);
      getSaleOrder();
      props.isSoTransfer(true,props.form.getFieldValue('fromSaleOrderId'));
    }
    props.getSaleOrderItems();
  }

  const getSaleOrderItems = (val,rowObj) => {
    console.log(saleOrders);
    props.form.setFieldsValue({'saleOrderItemId': saleOrders.find((rec) => rec.saleOrderId === props.form.getFieldValue("saleOrderId")).saleOrderItemId})
    console.log(allSaleOrders.find((res) => res.poNumber === (rowObj.children).split('- ')[1] && res.jobType === "Main Job"));
    console.log(rowObj);
    console.log(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType);
    props.job_type(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType);
    console.log("**********************");
    console.log((rowObj.children).split('-')[1]);
    console.log(allSaleOrders);
    if(props.form.getFieldValue("isPreparedMaterial") === 'yes'){
      console.log(allSaleOrders.filter((res) => res.poNumber === (rowObj.children).split('-')[1]));
      if(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType === "Sub Job"){
        console.log("Sub Job Sub Job");
        props.form.setFieldsValue({'fromSaleOrderId' : (allSaleOrders.find((res) => res.poNumber === ((rowObj.children).split('- ')[1]).split('/')[0] && res.jobType === "Main Job")?.saleOrderId) != undefined ? (allSaleOrders.find((res) => res.poNumber === ((rowObj.children).split('- ')[1]).split('/')[0] && res.jobType === "Main Job")?.saleOrderId) : undefined});
      }
      else if(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType === "Main Job"){
        console.log("Main Job Main Job");
        props.form.setFieldsValue({'fromSaleOrderId' : (allSaleOrders.find((res) => res.poNumber === ((rowObj.children).split('- ')[1]).split('/')[0] && res.jobType === "Sub Job")?.saleOrderId) != undefined ? (allSaleOrders.find((res) => res.poNumber === ((rowObj.children).split('- ')[1]).split('/')[0] && res.jobType === "Sub Job")?.saleOrderId) : undefined});
      }
    }
    else{
      if(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType === "Sub Job"){
        console.log("Sub Job Sub Job");
        props.form.setFieldsValue({'fromSaleOrderId' : (allSaleOrders.find((res) => res.poNumber === (rowObj.children).split('- ')[1] && res.jobType === "Main Job")?.saleOrderId) != undefined ? (allSaleOrders.find((res) => res.poNumber === (rowObj.children).split('- ')[1] && res.jobType === "Main Job")?.saleOrderId) : undefined});
      }
      else if(saleOrders.find((res) => res.saleOrderId === rowObj.value).jobType === "Main Job"){
        console.log("Main Job Main Job");
        props.form.setFieldsValue({'fromSaleOrderId' : val});
      }
    }
    props.getSaleOrderItems();
  }

  const getSaleOrder = () => {
    console.log("hii")
    const transferType = props.form.getFieldValue('transferType');
    const isPreparedMaterial = props.form.getFieldValue('isPreparedMaterial');
    const plant = props.form.getFieldValue('toPlant');
    if(transferType === GlobalStatus.YES&&plant){
      let state = '';
      if(isPreparedMaterial === 'yes'){
        state = "StockTransferForPrepMaterial";
      }
      else if(isPreparedMaterial === 'no'){
        state = "StockTransfer";
      }
      const req = new UnitRequest(plant,"",state);
      soService.getSaleOrdersForTransfers(req).then((res) => {
        if (res.status) {
          console.log(res.data)
          props.form.setFieldsValue({'saleOrderId':''});
          setAllSaleOrders(res.data);
          console.log(isPreparedMaterial)
          if(isPreparedMaterial === "yes"){
            console.log("yes"+isPreparedMaterial)
            setSaleOrders(res.data.filter((res) => res.jobType === "Sub Job" && res.processingUnit === plant));
          }
          else if(isPreparedMaterial === "no"){
            console.log("no"+isPreparedMaterial)
            setSaleOrders(res.data.filter((rec) => rec.processingUnit === plant));
            if(props.indentId > 0){
              props.form.setFieldsValue({saleOrderId:props.so});
            }
          }
        } else {
          setSaleOrders([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSaleOrders([]);
      });
    }
  }

  const getIndentItems = (val) => {
    if(val > 0){
      props.form.setFieldsValue({'transferType':GlobalStatus.YES});
      props.form.setFieldsValue({'toPlant':Number(localStorage.getItem('unit_id'))});
      getSaleOrder();
      props.isSoTransfer(true,0);
      props.getSaleOrderItems();
      setIsSo(true);
      setDisableSO(true)
    }
    props.getIndentItems(val);

  }
  const onChangeMaterialTransferType = (val) => {
    console.log(val);
    if(val === "yes"){
      setTransferTypeVisible(true);
      props.isPreparedMaterial(true);
      props.form.setFieldsValue({transferMethod : "INTERNAL"})
      setIsTransferMethodDisabled(true);
      onChangeTransferType("INTERNAL");
      props.form.setFieldsValue({transferType : GlobalStatus.YES});
      // setIsAgainstSODisabled(true);
      changeTransferType();
    }
    else{
      setIsTransferMethodDisabled(false);
      props.form.setFieldsValue({transferType : GlobalStatus.NO});
      // setIsAgainstSODisabled(false);
      changeTransferType();
      setTransferTypeVisible(false);
      props.isPreparedMaterial(false);
    }

    getSaleOrder();

  }
  const onChangeTransferType = (val) => {
    console.log(val)
    if(val == 'INTERNAL'){
      setToPlantHidden(false)
      setIsAgainstSODisabled(false)
      setIndentHidden(false)
      props.setTransferType(true);
      props.itemCategoriesDropDown();
        // setIsSo(true)
      // props.form.setFieldsValue({transferType:'NO'})
    }else if(val == 'EXTERNAL'){
      props.form.setFieldsValue({toPlant:null})
      props.form.setFieldsValue({saleOrderId:null})
      setToPlantHidden(true)
      setIsAgainstSODisabled(true)
      setIsSo(false)
      setIndentHidden(false)
      props.setTransferType(true);
      props.form.setFieldsValue({transferType:'NO'})
    }else if(val == 'INDENT'){
      props.form.setFieldsValue({toPlant:Number(localStorage.getItem('unit_id'))});
      props.form.setFieldsValue({saleOrderId:null})
      setToPlantHidden(false)
      setIsAgainstSODisabled(true)
      setIndentHidden(true)
      props.setTransferType(false);
      setIsDateDisabled(true)
    }

  }

  return (
    <>
      <Card>
        <Form form={props.form} layout="vertical">
          <Row gutter={24}>
            <Form.Item name="saleOrderItemId" initialValue={0} hidden>
              <Input />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name={"isPreparedMaterial"}
                label="Is Material Prepared"
                rules={[
                  {
                    required: true,
                    message: `Please Select Material Prepared!`,
                  },
                ]}
              >
                <Select
                  placeholder="Select Is Material Prepared"
                  allowClear
                  showSearch
                  onChange={onChangeMaterialTransferType}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    <Option label="isPreparedMaterial" value="yes">YES</Option>
                    <Option label="isPreparedMaterial" value="no">NO</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name={"transferredDate"}
                label="Date"
                rules={[
                  {
                    required: true,
                    message: `Missing Stock Transferred Date`,
                  },
                ]}
                initialValue={moment()}
              >
                <DatePicker disabled={isDateDisabled} showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name={"transferMethod"}
                label="Transfer Type"
                rules={[
                  {
                    required: true,
                    message: `Please Select Plant!`,
                  },
                ]}
              >
                <Select
                  placeholder="Select Transfer Type"
                  allowClear
                  showSearch
                  disabled={isTransferMethodDisabled}
                  onChange={onChangeTransferType}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {!transferTypeVisible? 
                    <><Option label="styleWeighment" value="INTERNAL">Plant To Plant</Option><Option label="styleWeighment" value="EXTERNAL">Plant To Third Party</Option><Option label="styleWeighment" value="INDENT">Warehouse To Plant</Option></> : <><Option label="styleWeighment" value="INTERNAL">Plant To Plant</Option></> 
                  }
                </Select>
              </Form.Item>
            </Col>
            {indentHidden ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name={"indentId"}
                label="Indent"
                rules={[
                  {
                    required: true,
                    message: `Please Select Indent!`,
                  },
                ]}
              >
                <Select
                  placeholder="Select Indent"
                  allowClear
                  showSearch
                  disabled={isIndentDisabled}
                  style={{ width: '100%' }}
                  onSelect={getIndentItems}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option key={'indentId'} value={''}>Select Indent</Option>
                  {props.indentData.map(dropData => {
                    return <Option value={dropData.indentMaterialPreparationId}>{dropData.indentCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>:<></>
            }
            {!toPlantHidden ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name={"toPlant"}
                label="To Plant"
                hidden={toPlantHidden}
                rules={[
                  {
                    required: true,
                    message: `Please Select Plant!`,
                  },
                ]}
              >
                <Select
                  placeholder="Select To Plant"
                  allowClear
                  showSearch
                  disabled={isAgainstSODisabled}
                  style={{ width: '100%' }}
                  onSelect={getSaleOrder}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option key={'plant'} value={''}>Select To Plant</Option>
                  {
                    props.form.getFieldValue('transferMethod') === 'INDENT' ? 
                    props.plantInfo.map(dropData => {
                      return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? dropDrownInfo(dropData.plantId, dropData.plantName) : dropDrownInfo(props.plantInfo.find((res) => res.plantId === Number(localStorage.getItem('unit_id'))).plantId, props.plantInfo.find((res) => res.plantId === Number(localStorage.getItem('unit_id'))).plantName);
                    })
                    :
                    props.plantInfo.map(dropData => {
                      return (dropData.plantId != Number(localStorage.getItem('unit_id'))) ? dropDrownInfo(dropData.plantId, dropData.plantName) : ''
                    })
                  }
                </Select>
              </Form.Item>
            </Col>:<></>
            }
            
            <Col span={3}>
              <Form.Item
                name="transferType"
                label="Against So"
                rules={[
                  {
                    required: true,
                    message: 'Against So is required'
                  },
                ]}

              >
                <Select
                  showSearch
                  disabled={isAgainstSODisabled}
                  placeholder="Select Against So"
                  defaultValue={GlobalStatus.NO}
                  onSelect={changeTransferType}
                >
                  <Option key={0} value={null}>Select Against So</Option>
                  <Option key={1} selected value={GlobalStatus.YES}>{GlobalStatus.YES}</Option>
                  <Option key={2} value={GlobalStatus.NO}>{GlobalStatus.NO}</Option>
                </Select>
              </Form.Item>
            </Col>
            
          {/* </Row> */}
          {/* <Row style={{ display: (isSo) ? 'block' : 'none' }}> */}
            <Col style={{ display: (isSo) ? 'block' : 'none' }} span={12}>
              <Form.Item
                name={"saleOrderId"}
                label="SO Number"
                rules={[
                  {
                    required: isSo,
                    message: `Please Select Sale Order!`,
                  },
                ]}
              >
                <Select
                  placeholder="Select Sale Order"
                  allowClear
                  showSearch
                  disabled={disableSO}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSelect={getSaleOrderItems}
                >
                  <Option key={'plant'} value={''}>Select Sale Order</Option>
                  {saleOrders.filter((res) => res.status != 'CANCELLED' && res.status != 'CLOSED')?.map(dropData => {
                    return dropDrownInfo(dropData.saleOrderId, dropData.saleOrderNumber);
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="note" label="Note">
                <TextArea showCount />
              </Form.Item>
            </Col>
            <Col style={{ display: (isSo) ? 'block' : 'none' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} hidden>
              <Form.Item
                name={"fromSaleOrderId"}
                label="From SO Number"
                rules={[
                  {
                    required: isSo,
                    message: `Please Select From Sale Order!`,
                  },
                ]}
              >
               <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

    </>
  );
}

export default PackingStockTransferMain;