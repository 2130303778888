import { CycleCheckerEnum } from "@gtpl/shared-models/common-models";

export class CycleCheckerDto {
    cycleCheckerId: number;
    unitId: number;
    physicalQuantity: number;
    itemCategoryId: number;
    itemSubCategoryId: number;
    masterBrandId: number;
    itemId: number;
    saleOrderId:number;
    status:CycleCheckerEnum;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    locationId:string;
    reason:string;
    previousQuantity:number;
        
    constructor(cycleCheckerId: number,unitId: number,physicalQuantity: number,itemCategoryId: number,itemSubCategoryId: number,masterBrandId: number,itemId: number,saleOrderId:number,status:CycleCheckerEnum,createdAt: Date,createdUser: string,updatedAt: Date,updatedUser: string,versionFlag: number,locationId:string,reason:string,previousQuantity:number){
        this.cycleCheckerId = cycleCheckerId;
        this.unitId = unitId;
        this.physicalQuantity = physicalQuantity;
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.masterBrandId = masterBrandId;
        this.itemId = itemId;
        this.saleOrderId = saleOrderId;
        this.status = status;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.locationId = locationId;
        this.reason = reason;
        this.previousQuantity=previousQuantity;
    }
}