import { LocalNotificationsPluginWeb } from '@capacitor/core';
import { EmployeeRolesEnum } from '@gtpl/shared-models/common-models';
import {RolesList} from '@gtpl/shared-models/gtpl';
export class CreateUserDto {
    Id?: number;
    username:string;
    password:string;
    mobileNumber: string;
    email:string;
    unitId:number;
    employeeRole:EmployeeRolesEnum;
    employeeId:number;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    id?:number;
  }
  
  export const CreateUserDtoDefault : CreateUserDto = {
    Id: 0,
    username:"",
    password:"",
    employeeRole:EmployeeRolesEnum.ADMIN,
    email:"",
    unitId:0,
    mobileNumber: "",
    employeeId:1,    
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1,
    id: 0
  };
  
  