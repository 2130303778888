
export class EmployeeCodesRequest {
    employeeCodes: string[];

    /**
     * 
     * @param employeeCodes
     */
    constructor(employeeCodes: string[]) {
        this.employeeCodes = employeeCodes
    }
}