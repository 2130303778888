import { GetVendorItemsPriceListResponse, PriceListForVendorResponse, VendorIdRequest, VendorItemRequest, VendorPriceListInfo, VPLActivateOrDeActiveRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';


export class VendorsPriceListService{
    URL = connection.DEPLOY_URL + '/vendors-price-list';

    /**
     * 
     * @param priceList 
     * @returns success Info if created price list 
     */
    async CreateVendorPriceList(priceList:VendorPriceListInfo){
        return await axios.post(this.URL + '/CreateVendorPriceList',priceList)
            .then(res => {
                return res.data
            })
    }

    async GetVendorsPriceListForVendor(req:VendorIdRequest):Promise<PriceListForVendorResponse>{
        return await axios.post(this.URL + '/GetVendorsPriceListForVendor',req)
        .then(res => {
            return res.data
        })
    }

    async GetItemPriceTaxForVendorIdItemId(req:VendorItemRequest):Promise<GetVendorItemsPriceListResponse>{
        return await axios.post(this.URL + '/GetItemPriceTaxForVendorIdItemId',req)
            .then(res => {
                return res.data 
            }) 
    }

    async ActivateOrDeActivateVPL(req:VPLActivateOrDeActiveRequest):Promise<GetVendorItemsPriceListResponse>{
        return await axios.post(this.URL + '/ActivateOrDeActivateVPL',req)
            .then(res => {
                return res.data 
            }) 
    }
    
}