
export class AttnAdjustLogReq {

    employeeCode: string;
    date: string;
    inTime: Date;
    outTime: Date;
    presentStatus: string;
    reason: string;
    remarks: string;
    user: string;

    constructor(employeeCode: string, date: string, inTime: Date, outTime: Date, presentStatus: string, reason: string, remarks: string, user: string) {
        this.employeeCode = employeeCode;
        this.date = date;
        this.inTime = inTime;
        this.outTime = outTime;
        this.presentStatus = presentStatus;
        this.reason = reason;
        this.remarks = remarks;
        this.user = user;
    }
}