import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import './pallet-grid.css';
import {Link, Redirect} from 'react-router-dom';
import {PalletForm} from '@gtpl/pages/warehouse-masters/warehouse-master-component/pallet-form'
import {PalletService} from '@gtpl/shared-services/masters';
import {PalletDto} from '@gtpl/shared-models/masters';
import ProTable, { ProColumns } from '@ant-design/pro-table';
/* eslint-disable-next-line */
export interface PalletGridProps {}

export function PalletGrid(
  props: PalletGridProps
) {
   
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [palletsData, setPalletsData] = useState<PalletDto[]>([]);
    const [selectedPalletData, setSelectedPalletData] = useState<any>(undefined);
    const username = localStorage.getItem('username')
    const palletService = new PalletService();
    
    useEffect(() => {
      getAllPallets();
    }, []);

    const getAllPallets = () => {
      palletService.getAllPallets({plantId:Number(localStorage.getItem("unit_id"))}).then(res => {
        console.log(res.data);
        if(res.status) {
          setPalletsData(res.data);
        }else {
          if(res.intlCode) {
            setPalletsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setPalletsData([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }

    const deletePallet = (palletDto: PalletDto) => {
      palletDto.isActive = palletDto.isActive ? false : true;
      palletService.ActivateorDeactivatePallet(palletDto).then(res => { console.log(res);
       if(res.status) {
         getAllPallets();
         AlertMessages.getSuccessMessage('Success');
       } else {
         if(res.intlCode){
           AlertMessages.getErrorMessage(res.internalMessage);
         }else {
           AlertMessages.getErrorMessage(res.internalMessage);
         }
       }
      }).catch(err =>{
        AlertMessages.getErrorMessage(err.message);
      })
    }
  
    const updatePallet = (palletDto: PalletDto) => {
      console.log(palletDto);
      palletService.updatePallet(palletDto).then(res => {console.log(res);
        if(res.status){
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllPallets();
          setDrawerVisible(false);
        }else {
          if(res.intlCode){
            AlertMessages.getErrorMessage(res.internalMessage);
          }else{
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

    const getColumnSearchProps = (dataIndex:string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={ searchInput }
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filterd =>(
       <SearchOutlined type="search" style={{color: filterd ? '#1890ff' : undefined}}/>
      ),
      onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex]
         .toString()
         .toLowerCase()
         .includes(value.toLowerCase())
         : false,
      onFilterDropDownVisibleChange: visible =>{
        if(visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: text =>
        text ?(
          searchedColumn === dataIndex ? (
            <Highlighter 
             highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
             searchWords={[searchText]}
             autoEscape
             textToHighlight= {text.toString()}
           />
          ) : text
        )
        : null
     
    });
    /**
     * 
     * @param selectedKeys
     * @param confirm
     * @param dataIndex
     */
    function handleSearch(selectedKeys, confirm, dataIndex){
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    
    function handleReset(clearFilters){
      clearFilters();
      setSearchText('');
    };
      
      //drawer related
      const closeDrawer = () =>{
        setDrawerVisible(false);
      }
      const openFormWithData= (viewData: PalletDto) =>{
        setDrawerVisible(true);
        setSelectedPalletData(viewData);
        console.log(selectedPalletData)
      }

    const columnsSkeleton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        render: (text, object, index) => (page-1) * 10 + (index+1)
      },
     
      // {
      //   title: 'Plant',
      //   dataIndex: 'plantName',
      //   // responsive ['lg'],
      //   sorter: (a, b) => a.plantName.localeCompare(b.plantName),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('plantName')
      // },
      {
        title: 'Pallet Code',
        dataIndex: 'palletNo',
        // responsive ['lg'],
        sorter: (a, b) => a.palletNo.localeCompare(b.palletNo),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('palletNo')
      },
      // {
      //   title: 'palletCode',
      //   dataIndex: 'palletCode',
      //   // responsive ['lg'],
      // sorter: (a, b) => a.palletCode.localeCompare(b.palletCode),
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('palletCode')
      // },

      {
        title: 'Associate Rf Id',
        dataIndex: 'assocRfId',
        // responsive ['lg'],
      sorter: (a, b) => a.assocRfId.localeCompare(b.assocRfId),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assocRfId')
      },
      {
        title: 'Occupancy',
        dataIndex:'occupancy',
        sorter: (a, b) => a.occupancy.localeCompare(b.occupancy),
        sortDirections: ['descend', 'ascend'],
        filters: [
          {
            text: 'Occupied',
            value:'Occupied',
          },
          {
            text: 'Occupied-NR',
            value:'Occupied-NR',
          },
          {
            text: 'Assigned',
            value:'Assigned',
          },
          {
            text: 'Not Occupied ',
            value:'Not Occupied ',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.occupancy === value;
        },
        
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color='#87d068'>Active</Tag>: <Tag icon={<CloseCircleOutlined />} color='#f50'>In Active</Tag>}
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'InActive',
            value: false,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) =>
        {
          // === is not work
          return record.isActive === value;
        },
      },
      // {
      //   title:`Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) =>(
      //     <span>
      //       { username == '"ramakrishna"' ?
      //         <>
      //       <EditOutlined className={'editSampleTypeIcon'} type="edit"
      //         onClick= { () => {
      //           if(rowData.isActive){
      //             openFormWithData(rowData);
      //           }else{
      //             AlertMessages.getErrorMessage('You Cannot Edit Deactivated Pallet.');
      //           }
      //         }}
      //         style={{color: '#1890ff', fontSize: '14px'}}
      //         />
      //       <Divider type="vertical" />
             
      //        <Popconfirm onConfirm={e =>{deletePallet(rowData);}}
      //        title={
      //          rowData.isActive 
      //           ? 'Are you sure to Deactive Pallet ?'
      //           : 'Are you sure to Activate Pallet ?'
      //        }
      //       >
      //         <Switch size="default"
      //           className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
      //           checkedChildren={<RightSquareOutlined type='check' />}
      //           unCheckedChildren={<RightSquareOutlined  type="close" />}
      //           checked = {rowData.isActive}
      //         />
      //       </Popconfirm>
      //       </>:''
      //       }
      //     </span>
      //   )
      // }
    ];
     const onChange=(pagination, filters, sorter, extra) => {
       console.log("params", pagination, filters, sorter, extra);
     }

  return (
    <Card title={<span style={{color:'white'}}>Pallets</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={(username == '"ramakrishna"') ? <Link to='/pallet-form' ><Button className='panel_button' >Create </Button></Link>:''}
    
    >
      <br></br>
      <Row gutter={40} >
      <Col>
          <Card title={'Total Pallets: ' + palletsData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + palletsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + palletsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          <Col>
          <Button type="primary" style={{ marginRight: '4px',paddingTop:'8px' }}>
            Get Report
          </Button>
              </Col>
          </Row>
          <br></br>
          <Table 
           rowKey = {record => record.deptId}
           columns={columnsSkeleton}
           dataSource={palletsData}
           pagination={{
             onChange(current){
               setPage(current);
             }
          }}
          onChange={onChange}
          bordered />
         <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <PalletForm key={Date.now()}
                updatePallet={updatePallet}
                isUpdate={true}
                palletData={selectedPalletData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>

      </Card>
  );
}

export default PalletGrid;
