import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LotCodesDTO } from './lot-codes.dto';
import { GrnData } from './raw-material-grn.dto';

export class LotCodesResponseModel extends GlobalResponseObject{
    data?: LotCodesDTO[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //GrnData
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: LotCodesDTO[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
