import { BomPackingItemsDto } from "./bom-details.dto";

export class CreateBomDTO{
    bomItems:BomPackingItemsDto[];
    deletedBomItems:number[];

    /**
     * 
     * @param bomItems 
     * @param deletedBomItems 
     */
    constructor(bomItems:BomPackingItemsDto[], deletedBomItems:number[]){
        this.bomItems = bomItems;
        this.deletedBomItems = deletedBomItems;
    }
}