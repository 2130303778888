import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";
import { ContainerReportPlantsData } from "./container-report-plants-model";

export class ContainerReportModel {
    status: ContainerStatusEnum | string;
    plantsdata: ContainerReportPlantsData[]
    total:number;
    /**
     * 
     * @param status 
     * @param plantsdata 
     * @param total
     */
    constructor(status: ContainerStatusEnum | string, plantsdata: ContainerReportPlantsData[],total:number) {
        this.status = status;
        this.total=total;
        this.plantsdata = plantsdata;
    }
}