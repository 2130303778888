export class TopFiveDestinationDto{
    destination:string;
    count:number;
    unitName:string;
    constructor(destination:string,count:number,unitName:string){
this.destination=destination;
this.count=count;
this.unitName=unitName;
    }
}

    export class TopFiveDestinationDtoList{
        data:TopFiveDestinationDto[]
        constructor(data:TopFiveDestinationDto[]){
        this.data=data;
    }
}