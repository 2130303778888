export class UomRequest{
    uomId?:number;
    
    /**
     * 
     * @param uomId 
     */
   
    constructor(uomId:number){
    this.uomId = uomId;
  
    }
} 
