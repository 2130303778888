export class RmEvaluationSamplesDto{
    rmEvaluationSampleId?: number;
    rmEvaluationId?: number;
    time: Date;
    species: number;
    temperature: number;
    generalAppearance: string;
    generalOdour: string;
    freshness: string;
    dehydration: string;
    discolouration: string;
    deterioration: string;
    blackSpotOrTail: string;
    looseShells: string;
    brokenOrDamagedPcs: string;
    fungalGrowth: string;
    softSpecies: string;
    droopingHead: string;
    foreignMatter: string;
    sulphiteKit: string;
    pests: string;
    muddyOdour: string;
    cookedTexture: string;
    cookedFlavor: string;
    parasite: string;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    sampleNo:number;
    constructor(
    rmEvaluationSampleId: number,
    rmEvaluationId: number,
    time: Date,
    species: number,
    temperature: number,
    generalAppearance: string,
    generalOdour: string,
    freshness: string,
    dehydration: string,
    discolouration: string,
    deterioration: string,
    blackSpotOrTail: string,
    looseShells: string,
    brokenOrDamagedPcs: string,
    fungalGrowth: string,
    softSpecies: string,
    droopingHead: string,
    foreignMatter: string,
    sulphiteKit: string,
    pests: string,
    muddyOdour: string,
    cookedTexture: string,
    cookedFlavor: string,
    parasite: string,
    createdAt: Date,
    createdUser: string | null,
    updatedAt: Date,
    updatedUser: string | null,
    versionFlag: number,
    sampleNo:number
    ){
        this.rmEvaluationSampleId = rmEvaluationSampleId;
        this.rmEvaluationId = rmEvaluationId;
        this.time = time;
        this.species = species;
        this.temperature = temperature;
        this.generalAppearance = generalAppearance;
        this.generalOdour = generalOdour;
        this.freshness = freshness;
        this.dehydration = dehydration;
        this.discolouration = discolouration;
        this.deterioration = deterioration;
        this.blackSpotOrTail = blackSpotOrTail;
        this.looseShells = looseShells;
        this.brokenOrDamagedPcs = brokenOrDamagedPcs;
        this.fungalGrowth = fungalGrowth;
        this.softSpecies = softSpecies;
        this.droopingHead = droopingHead;
        this.foreignMatter = foreignMatter;
        this.sulphiteKit = sulphiteKit;
        this.pests = pests;
        this.muddyOdour = muddyOdour;
        this.cookedTexture = cookedTexture;
        this.cookedFlavor = cookedFlavor;
        this.parasite = parasite;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.sampleNo = sampleNo;
    }
}