export class WorkstationDTO{
    workStationId?: number;
    workstationCategoryId : number;
    workstationCategoryName : string;
    workstation : string;
    workstationUnitCapacity : number;
    isActive: boolean;
    createdUser : string;
    unitId? : number;
    createdAt : Date;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    workstationCode:string;
    
    
    /**
     * 
     * @param workstationCategoryId number
     * @param workstationCategoryName string
     * @param isActive boolean
     * @param createdUser string
     * @param workstation
     * @param workstationUnitCapacity 
     * @param unitId
     * @param createdAt 
    * @param createdUser 
    * @param updatedAt 
    * @param updatedUser 
    * @param versionFlag 
    * @param workstationCode
    */
    
    constructor(workstationCategoryId : number,workstationCode:string,workstationCategoryName : string,workstation : string, workstationUnitCapacity : number,isActive: boolean,createdUser : string,createdAt: Date,  updatedAt: Date, updatedUser: string, versionFlag: number
        ,unitId? : number){
        this.workstationCategoryId = workstationCategoryId;
        this.workstationCategoryName = workstationCategoryName;
        this.workstation = workstation;
        this.workstationUnitCapacity = workstationUnitCapacity
        this.unitId = unitId;
        this.isActive= isActive;
        this.createdUser= createdUser;
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.updatedUser = updatedUser
        this.versionFlag = versionFlag
        this.isActive = isActive
        this.workstationCode=workstationCode
    }
}