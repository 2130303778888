

export class EmployeeDesignationRequest{
    employeeId:number;
    designation:string;
    constructor(employeeId:number,designation:string){
        this.employeeId = employeeId;
        this.designation = designation;
    }
    
   
}