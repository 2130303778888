import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class StockTransferInformation{
    lotNumber:string;
    subLotNumber:string;
    plant:string;
    subPlant:string;
    plantId:number;
    subPlantId:number;
    transferredQTY:number;
    noOfBoxes:number;
    presentPlantId:number;
}

export let StockTransferDefaultInformation ={
    lotNumber:'',
    subLotNumber:'',
    plant:'',
    subPlant:'',
    transferredQTY:0,
    noOfBoxes:0,
    plantId:0,
    subPlantId:0,
    presentPlantId:0
}