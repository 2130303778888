import React, { useEffect, useState } from 'react';
import { SaleOrderDetailViewGrid, SaleOrderDetailViewGridProps } from '@gtpl/pages/sale-management/sale-components/sale-order-detail-view-grid';
import './sale-order-detail-view-pages.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDetailDto, SaleOrderDetailViewInfoDTO } from '@gtpl/shared-models/sale-management';
import { useLocation, useParams } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom';

/* eslint-disable-next-line */
export interface SaleOrderDetailViewPagesProps { }

export function SaleOrderDetailViewPages(
  props: SaleOrderDetailViewPagesProps
) {
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<SaleOrderDetailViewInfoDTO>(undefined)
  const [totNetWeight, setTotNetWeight] = useState<number>(0)
  const [totNetWeightInKg, setTotNetWeightInKg] = useState<number>(0)
  const [redirectView, setRedirectView] = useState<boolean>(false)
  const { id, poNumber } = useParams<any>()

  console.log(poNumber)
  let location = useLocation();
  console.log(location.state);
  const service = new SaleOrderService;
  let totWeight = 0;
  let totWeightInKg = 0;
  const getSaleOrderDetailsById = (reqDate, poNo) => {
    service.getSaleOrderDetailsById({ saleOrderId: reqDate, plantId: 0, poNumber: poNo }).then(res => {
      if (res.status) {
        console.log(res.data);
        (res.data.itemData).forEach(data => {
          console.log(data);
          if (data.uom == "KG" || data.uom == "Gms") {
            totWeight += Number(data.netWeight) * 2.20462;
          }
          else {
            totWeight += Number(data.netWeight);
          }

          if (data.uom == "LB" || data.uom == "OZ") {
            totWeightInKg += Number(data.netWeight) * 0.453592;
          }
          else {
            totWeightInKg += Number(data.netWeight);
          }

          // else if(data.uom == "gm"){
          //   totWeight += Number(data.netWeight) * 0.00220462;
          // }
        })
        console.log(totWeight);
        setTotNetWeight(totWeight);
        setTotNetWeightInKg(totWeightInKg);
        setSelectedSaleOrder(res.data);
        console.log(selectedSaleOrder);
      } else {
        if (res.intlCode) {
          setSelectedSaleOrder(undefined);
          // AlertMessages.getErrorMessage(res.internalMessage);
          goToTracker();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSelectedSaleOrder(undefined);
    })
  }
  // const getTotal = ({selectedSaleOrder}) => {
  //   console.log({selectedSaleOrder});

  // }
  const goToTracker = () => {
    setRedirectView(true);
  }
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
    getSaleOrderDetailsById(id, poNumber);
    // getTotal({selectedSaleOrder})
  }, [id, poNumber])
  return (
    <>
      {(redirectView) ? <Redirect key={Date.now()} to={{ pathname: "/saleorder-tracker" }} /> : null}

      <SaleOrderDetailViewGrid SaleOrderDetails={selectedSaleOrder} netWeightConversion={totNetWeight} netWeightInKg={totNetWeightInKg} />
    </>
  );
}

export default SaleOrderDetailViewPages;
