import { RecruitmentDto } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllRecruitmentResponseModel extends GlobalResponseObject {
    data?: RecruitmentDto[];

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: RecruitmentDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}

