import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";
import { SaleOrderDropDownDto } from "@gtpl/shared-models/sale-management";

export class DispatchedToPlant{
    containerNumber:string;
    saleOrderId:number;
    containerStatus:ContainerStatusEnum;
    createdUser:string;
    updatedUser?:string;
     containerId?:number;
     saleOrder?:SaleOrderDropDownDto;

    /**
     * 
     * @param containerNumber 
     * @param saleOrderId 
     * @param containerStatus 
     * @param containerId 
     */
    constructor(containerNumber:string, saleOrderId:number, containerStatus:ContainerStatusEnum, createdUser:string, containerId?:number, updatedUser?:string){
        this.containerNumber = containerNumber;
        this.saleOrderId = saleOrderId;
        this.containerStatus = containerStatus;
        this.containerId = containerId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
    }
}