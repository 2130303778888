export class UidResponseModel{
    temperature: number;
    loadStatus : string;
    date : string;
    uid: string;
    constructor(temperature: number,loadStatus : string,date : string,uid: string){
        this.temperature =temperature;
        this.loadStatus = loadStatus;
        this.date = date;
        this.uid = uid;
    }
}