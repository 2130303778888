import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import  {PODetailViewInfoDTO}  from "./po-detail-view-dto";
import { PoDetailsDto } from "./po-details.dto";
import {PoDto} from './po.dto'


export class AllPoResponseModel extends GlobalResponseObject {
    data?: PoDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: PoDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 