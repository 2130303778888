import { EmployeeDto  } from "@gtpl/shared-models/gtpl";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class EmployeeResponseModel extends GlobalResponseObject{
    data?: EmployeeDto;

    /**
     * 
     * @param status
     * @param intlCode
     * @param internalMessage
     * @param data
     */


    constructor(status: boolean, intlCode: number, internalMessage: string, data?: EmployeeDto){
      super(status, intlCode, internalMessage);
      this.status = status;
      this.intlCode = intlCode;
      this.internalMessage = internalMessage;
      this.data = data;
}

}
