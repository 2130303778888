import { InspectionStatusEnum } from "@gtpl/shared-models/common-models";

export class SgsTuvDto {
    shipmentDate: any;
    initiationDate: any;
    plannedDate: any;
    actualDate: any;
    expectedResultDate: any;
    createdUser: string;
    saleOrderId: number;
    inspectionStatus?:InspectionStatusEnum;
    constructor(
        shipmentDate: any,
        initiationDate: any,
        plannedDate: any,
        actualDate: any,
        expectedResultDate: any, createdUser: string, saleOrderId: number,inspectionStatus?:InspectionStatusEnum) {
        this.shipmentDate = shipmentDate;
        this.initiationDate = initiationDate;
        this.plannedDate = plannedDate;
        this.actualDate = actualDate;
        this.expectedResultDate = expectedResultDate;
        this.createdUser = createdUser;
        this.saleOrderId = saleOrderId;
        this.inspectionStatus = inspectionStatus;
    }
}