


export enum JobTypeEnum {
    CUTJOB = 'CUT_JOB',
    CUT_EMB_JOB = 'CUT_EMB_JOB',
    PACKJOB = 'PACK_JOB',
    SEWINGJOB = 'SEWING_JOB',
    SUBPO = 'SUB_PO',
    MPO = 'MASTER_PO',
    RATIO = 'RATIO',
}
