import { PoStockReq } from '@gtpl/shared-models/procurement-management';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';

export interface PackingStockReportProps { }

export function PackingStockReport() {
    const [page, setPage] = React.useState(1);
    const [reportData, setReportData] = useState<any[]>([]);
    const [itemSubDrop, setItemSubCat] = useState<any[]>([]);
    const [poDrop, setPoDrop] = useState<any[]>([]);
    const service = new PurchaseOrderService();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [disable, setDisable] = useState<boolean>(false);
    const [filtersData, setFiltersData] = useState<any[]>([]);

    useEffect(() => {
        //getPackingStockData();
        getItemSubCatForPackingStock();
        getPoNumForPackingStock();
    }, [])

    const getPackingStockData = () => {
        setDisable(true);
        const req = new PoStockReq()
        let unitId = form.getFieldValue('unitId');
        let saleOrderId = form.getFieldValue('saleOrderId');
        let itemSubcategoryId = form.getFieldValue('itemSubcategoryId');
        let selectedData = filtersData;
        req.unitId = unitId;
        req.itemSubcategoryId = itemSubcategoryId
        req.saleOrderId = saleOrderId;
        service.getPackingStockData(req).then((res) => {
            setDisable(false);
            if (res.data) {
                setDisable(false);
                setReportData(res.data)
                setFiltersData(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
        setReportData(selectedData)
    }
    const getItemSubCatForPackingStock = () => {
        service.getItemSubCatForPackingStock({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setItemSubCat(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }
    const getPoNumForPackingStock = () => {
        service.getPoNumForPackingStock({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
            if (res.data) {
                setPoDrop(res.data)
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    const onReset = () => {
        form.resetFields();
        setReportData([]);
    }

    const Columns: ColumnProps<any>[] = [
        {
            title: '#Sno',
            key: 'sno',
            width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Item',
            dataIndex: 'itemName',
            width: '300px'
        },
        {
            title: 'Head Office',
            dataIndex: 'headOfficeValue',
           // width: '100px',
            render: (value, record, index) => {
                return Math.round(value);
              }
        },
        {
            title: 'KKD',
            dataIndex: 'kkdValue',
            //width: '100px',
            render: (value, record, index) => {
                return Math.round(value);
              }
        },
        {
            title: 'Unit 1',
            dataIndex: 'unitOneValue',
           // width: '100px',
            render: (value, record, index) => {
                return Math.round(value);
              }
        },
        {
            title: 'Unit 2',
            dataIndex: 'unitTwoValue',
           // width: '100px',
            render: (value, record, index) => {
                return Math.round(value);
              }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            //width: '100px',
            render: (value, record, index) => {
                return Math.round(value);
              }
        },
    ]
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Packing Stock Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}></Card>
            <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                <Row gutter={[24, 24]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="itemSubcategoryId"
                            label=" Item Sub Category"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Item Sub Category"
                                allowClear
                                style={{ width: '100%' }}
                            >
                                {itemSubDrop.map(dropData => {
                                    return <Option value={dropData.itemSubcategoryId}>{dropData.itemSubCat}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="saleOrderId"
                            label="Customer PO"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Item Sub Category"
                                allowClear
                                style={{ width: '100%' }}
                            >
                                {poDrop.map(dropData => {
                                    return <Option value={dropData.saleOrderId}>{dropData.saleOrder}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Button
                            type="primary"
                            block onClick={() => getPackingStockData()} disabled={disable} style={{ marginRight: 2, width: 100 }}>
                            Get Report
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={onReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table
                rowKey={record => record.saleOrderId}
                columns={Columns}
                dataSource={reportData}
                scroll={{ x: true }}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                size='small'
                bordered />
        </>
    )
}
export default PackingStockReport;