import React from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import './rm-indent-qty-limit.css';
import { RmIndentQtyLimitService } from '@gtpl/shared-services/raw-material-procurement';
import { RmIndentQtyLimitDto } from '@gtpl/shared-models/raw-material-procurement';
import moment from 'moment';

/* eslint-disable-next-line */
const { TextArea } = Input;
export interface RmIndentQtyLimitProps {
  rmIndentQtyLimitData: RmIndentQtyLimitDto;
  updateRmIndentQtyLimit: (rmIndentQtyLimit: RmIndentQtyLimitDto) => void;
  isUpdate: boolean;
  closeForm: () => void;

}

export function RmIndentQtyLimit(
  props: RmIndentQtyLimitProps
) {

  const service = new RmIndentQtyLimitService;
  const [form] = Form.useForm();
  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const create = (Data: RmIndentQtyLimitDto) => {
    console.log(Data)

    service.create(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Created Successfully');

        history.push("/rm-indent-Qty-limit-grid")
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveData = (values: RmIndentQtyLimitDto) => {
  console.log("saved")
    if (props.isUpdate) {
      props.updateRmIndentQtyLimit(values);
    } else {
      create(values);
console.log("created")
    }
  };
  
  const qtyValidate = (event) => {
    // alert()
    try {
      console.log(event)
      let givenValue = Number(event);
      if (isNaN(givenValue)) {
        throw new Error('Qty Should be of number');
      }
      if (givenValue > 150 || givenValue < 0) {
        throw new Error('Qty Should be less than 150');
      }
    } catch (err) {
      throw new Error('error')
    }
  }

  const onReset = () => {
    console.log("reset")
    form.resetFields();
  };


  return (
    <Card title={<span style={{ color: 'white' }}>Rm Indent Qty Limit</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/rm-indent-qty-limit-grid' ><span style={{ color: 'white' }} >  {(props.isUpdate === false) &&
        <Button className='panel_button' >View </Button> }  </span></Link>} >
     <Form form={form} initialValues={props.rmIndentQtyLimitData} name="control-hooks" onFinish={saveData}
        layout="vertical" >
        <Form.Item name="rmIndentQtyLimitId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser} >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true }]}
              initialValue={moment()} >

              <DatePicker showToday onChange={(val) => { moment(val).format('YYYY-MM-DD') }} style={{ width: '100%' }} />

            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="plannedQty"
              label="Planned Qty(Tons)"

              rules={[
                {
                  required: true,
                  message: 'Planned Qty is mandatory, enter a correct value'

                },
                {
                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                  message: `Invalid Planned Qty`
                },
              ]}>
              <InputNumber onBlur={qtyValidate} style = {{width:'100%'}}/>
              
            </Form.Item>
          </Col>



          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="indentQty"
              label="Indent Qty(Tons)"
              rules={[
                {
                  required: true,
                  message: ' enter a correct value',
                 
                },
                {
                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                  message: `Invalid Indent Qty`
                },
              ]}>
              <InputNumber onBlur={qtyValidate} style = {{width:'100%'}}/>
             
            </Form.Item>
          </Col>



        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate !== true) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default RmIndentQtyLimit;
