import { UnitRequest } from '@gtpl/shared-models/common-models';
import { ProductionInventoryService } from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Input, Modal, Table , Typography} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState, useRef } from 'react';
import { SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Link from 'antd/lib/typography/Link';
import { SoInfoViewPages } from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages';
import { LotDetailView } from '@gtpl/pages/production-management/production-management-components/lot-detail-view';


export interface GrnInventoryGridProps { }
export function GrnInventoryGrid(props: GrnInventoryGridProps) {

    const [grnInventory, setGrnInventory] = useState<any[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [saleId, setSaleId] = useState(0);
    const [lotINo, setLotINo] = useState('lotNumber');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { Text } = Typography;
    const service = new ProductionInventoryService()
    const searchInput = useRef(null);

    useEffect(() => {
        getReadyForDeheading()

    }, []);


    const getReadyForDeheading = () => {
        const req = new UnitRequest(Number(localStorage.getItem('unit_id'))
        );
        service.getReadyForDeheading(req).then((res) => {
            if (res.status) {
                setGrnInventory(res.data);

            }
            else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
            });
    };

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                )
            ) : null,
    });

    const goToSaleDetailView = (saleOrderId: number) => {
        setSaleId(saleOrderId);
        console.log("soid", saleOrderId);
        setIsModalVisible(true)
    }

    const goToLotDetailView = (lotNumber: string) => {
        setLotINo(lotNumber);
        console.log("lotNo", lotNumber);
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'Lot Code',
            dataIndex: 'lotandplantlot',
            sorter: (a, b) => a.lotandplantlot.localeCompare(b.lotandplantlot),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('lotandplantlot'),
            render: (value, record) =>
                <Link onClick={e => goToLotDetailView(record.lotNumber)} >{record.lotandplantlot} </Link>
        },
        {
            title: 'Customer PO',
            dataIndex: 'poNumber',
            sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('poNumber'),
            // render: (value, record) => 
            // <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
          },
        {
            title: 'VariantCode',
            dataIndex: 'variantCode',

        },

        {
            title: 'Operation',
            dataIndex: 'operation',

        },

        {
            title: 'Boxes',
            dataIndex: 'boxes',
            align: 'right',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'right',
        },

    ];
    return (
        <Card
            title={<span style={{ color: 'white' }}>Ready For Deheading Inventory</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        >
            <Table
                columns={columnsSkelton}
                dataSource={grnInventory}
                scroll={{ x: 500 }}
                // size='small'
                bordered
            />
            <Modal
                className='print-docket-modal'
                key={'modal' + Date.now()}
                width={'80%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={isModalVisible}
                title={<React.Fragment>
                </React.Fragment>}
                onCancel={handleCancel}
                footer={[

                ]}
            >
                <SoInfoViewPages saleOrderId={saleId} screen="production" />

                <LotDetailView lotNumber={lotINo} />
            </Modal>

        </Card>
    );

}
export default GrnInventoryGrid;