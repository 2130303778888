export class FgStockReportDto{
    saleOrderId:number;
    poNumber:string;
    brand:string;
    packing:string;
    required:number;
    finalFg:string;
    cf:string;
    dummy:number;
    balance:number;
    plantName:string;
    plantId:number;
    pouchFinal?:number;
    brandId?:number;
    category?:string;
    categoryId?:number;

    constructor( saleOrderId:number,
        poNumber:string,
        brand:string,
        packing:string,
        required:number,
        finalFg:string,
        cf:string,
        dummy:number,
        balance:number,
        plantName:string,
        plantId:number,pouchFinal?:number,brandId?:number,category?:string,categoryId?:number){
        this.saleOrderId = saleOrderId;
        this.poNumber = poNumber;
        this.brand = brand;
        this.packing = packing;
        this.required = required;
        this.cf = cf;
        this.finalFg = finalFg;
        this.balance = balance;
        this.dummy = dummy;
        this.plantName = plantName;
        this.plantId = plantId;
        this.pouchFinal = pouchFinal;
        this.brandId = brandId;
        this.category = category;
        this.categoryId = categoryId;
    }
}