import React, { useEffect, useState } from 'react';
import { Table, Card, Input, Button, Row, Col, Form, Select, Spin, Tag } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {ProductionInventoryService} from '@gtpl/shared-services/production'
import './soaking-tub-report.css';
import { ColumnProps } from 'antd/lib/table';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

/* eslint-disable-next-line */
export interface SoakingTubReportProps {}

export function SoakingTubReport(
  props: SoakingTubReportProps
) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [soakingData, setSoakingData] = useState<any[]>([]);
  const services=new ProductionInventoryService();

  useEffect(() => {
    getSoakingReport();
  }, []);

  const getSoakingReport= () => {
    services.getSoakingReport().then(res => {
      if (res.status) {
        setSoakingData(res.data);
      } else {
        if (res.intlCode) {
            setSoakingData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSoakingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'sno',
      width: '60px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    // {
    //   title: 'Unit',
    //   dataIndex: 'unitName',
    // },
    {
      title: 'Asset Code',
      dataIndex: 'assetCode',
    },
    {
      title : 'Start Time',
      dataIndex: 'createdAt',
      render: (value, rowData) => (moment(value).format('YYYY-MM-DD HH:mm:ss'))
    },
    // {
    //   title: 'Cron Job Time',
    //   dataIndex: 'cronJobTime',
    // },
    // {
    //   title: 'Asset Control',
    //   dataIndex: 'assetControl',
    // },
    {
      title: 'Tub Code',
      dataIndex: 'tubCode',
    },
    {
      title: 'Asset duration',
      dataIndex: 'durationOne',
    },
    {
      title: 'Asset Status',
      dataIndex: 'assetStatus',
      align:'center',
      render: (assetStatus, rowData) => (
        <>
          {assetStatus?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title: 'Tub duration',
      dataIndex: 'durationTwo',
    },
    
    {
      title: 'Tub Status',
      dataIndex: 'tubStatus',
      align:'center',
      render: (tubStatus, rowData) => (
        <>
          {tubStatus?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: (status, rowData) => (
        <>
          {/* {(moment(rowData.cronJobTime).format('YYYY-MM-DD HH:mm:ss') - moment(createdAt).format('YYYY-MM-DD HH:mm:ss')) > rowData.duration ? "-" : "*"} */}
        </>
      )
    }
   
  ];
  return (
    <Card title={<span style={{color:'white'}}>Soaking Report</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
     <br></br>
    <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={soakingData}
          scroll={{ x: 1500, y: 400 }}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
          bordered />
    </Card>
  );
}

export default SoakingTubReport;
