import { AssetCountValueModel } from "./asset-count-value.model";
import { ServiceListDetails } from "./service-list-details.model";

export class serviceOwnerDetails {
    ownerName: string;
    servicesList: ServiceListDetails[];
    responsible: AssetCountValueModel;
    accountable: AssetCountValueModel;
    consulted: AssetCountValueModel;
    informed: AssetCountValueModel;

    constructor(
        ownerName: string,
        servicesList: ServiceListDetails[],
        responsible: AssetCountValueModel,
        accountable: AssetCountValueModel,
        consulted: AssetCountValueModel,
        informed: AssetCountValueModel
    ) {
        this.ownerName = ownerName;
        this.servicesList = servicesList;
        this.responsible = responsible;
        this.accountable = accountable;
        this.consulted = consulted;
        this.informed = informed;
    }
}