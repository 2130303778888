import { ZoneDto } from "../zone_master";

export class RackDetails{
    rackId?:number;
    plantName?:string;
    rackName:string;
    rackCode:string;
    reservedForDummy:number;
    zoneId:number;
    zoneName:string;
    allocatedZoneId:number;
    allocatedPlantId:number;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    allocatedzoneName?:string;
    allocatedPlantName?:string;
    

    
    /**
     * 
     * @param rackId 
     * @param rackName 
     * @param zoneId 
     * @param rackCode 
     * @param zoneName 
     * @param createdUser 
     * @param updatedUser 
     * @param isActive 
     * @param versionFlag
     * 
     */
    constructor(rackId:number,rackName:string, rackCode:string,reservedForDummy:number,zoneId:number, zoneName:string,createdUser:string, updatedUser:string, isActive:boolean, versionFlag:number,allocatedZoneId:number,
        allocatedPlantId:number,
        plantName?:string,allocatedzoneName?:string,
        allocatedPlantName?:string,){
    this.rackId = rackId;
    this.rackName = rackName;
    this.rackCode = rackCode;
    this.reservedForDummy = reservedForDummy;
    this.zoneName = zoneName;
    this.zoneId = zoneId;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;
    this.isActive = isActive;
    this.versionFlag = versionFlag;
    this.plantName=plantName;
    this.allocatedZoneId=allocatedZoneId;
    this.allocatedPlantId=allocatedPlantId;
    this.allocatedzoneName=allocatedzoneName;
    this.allocatedPlantName=allocatedPlantName;

    }
} 
