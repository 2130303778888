import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tooltip, message } from 'antd';
import excel from '../../../assets-dashboard/src/lib/excel.png';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AssetService } from '@gtpl/shared-services/asset-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

const TopFiveUpComingServicesComponent = () => {
    const [serviceDetails, setServiceDetails] = useState([]);
    const [hoveredCard, setHoveredCard] = useState(null);

    const service = new AssetService();

    useEffect(() => {
        assetsTopService();
    }, []);

    const assetsTopService = () => {
        service.assetsTopServices().then((res) => {
            if (res.status) {
                setServiceDetails(res.data);
            } else {
                setServiceDetails([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setServiceDetails([]);
        });
    };

    const handleExport = async (e) => {
        e.preventDefault();

        if (serviceDetails.length === 0) {
            message.warning('No data to export.');
            return;
        }

        const currentDate = new Date().toISOString().slice(0, 10).split("-").join("/");

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const columns = [
            { title: 'Unit', key: 'unit', width: 20 },
            { title: 'Asset Code', key: 'assets_code', width: 20 },
            { title: 'Asset Name', key: 'asset_name', width: 20 },
            { title: 'Service Date', key: 'asset_service_date', width: 20 },
            { title: 'Aging', key: 'aging', width: 20 }
        ];

        // Add header row with styling
        const headerRow = worksheet.addRow(columns.map(column => column.title));
        headerRow.font = { bold: true };
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }  // Yellow color
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        serviceDetails[0].forEach(item => {
            const rowData = columns.map(column => {
                if (column.key === 'asset_service_date') {
                    return item[column.key] ? moment(item[column.key]).format('YYYY-MM-DD') : '--';
                }
                return item[column.key];
            });
            worksheet.addRow(rowData);
        });

        // Set column widths
        columns.forEach((column, index) => {
            worksheet.getColumn(index + 1).width = column.width;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Top Five UpComing Services-${currentDate}.xlsx`);
    };

    return (
        <div>
        <Card
          title="TOP 5 Upcoming Services"
          style={{
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            height: "450px",
            textAlign: 'center'
          }}
          headStyle={{ backgroundColor: '#114232', color: 'white' }}
          extra={
            <Tooltip title="Export As Excel">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '5px',
                  height: '35px',
                  width: '35px'
                }}
              >
                <img
                  src={excel}
                  style={{ width: 40, height: 40 }}
                  alt="Export to Excel"
                  onClick={handleExport}
                />
              </div>
            </Tooltip>
          }
        >
          <Card style={{ backgroundColor: '#AFD198', height: '60px' }}>
            <Row gutter={16}>
              <Col span={3}>
                <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Unit</h1>
              </Col>
              <Col span={5}>
                <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Asset Code</h1>
              </Col>
              <Col span={8}>
                <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Asset Name</h1>
              </Col>
              <Col span={4}>
                <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Service Date</h1>
              </Col>
              <Col span={4}>
                <h1 style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: "15px" }}>Aging</h1>
              </Col>
            </Row>
          </Card>
          <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
            {serviceDetails[0]?.map((rec, index) => (
              <Card
                key={index}
                style={{
                  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                  height: '80px',
                  backgroundColor: hoveredCard === index ? '#FFF5E0' : 'white',
                }}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <Row gutter={16}>
                  <Col span={3}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.unit}</p>
                  </Col>
                  <Col span={5}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.assets_code}</p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px", wordWrap: 'break-word' }}>{rec.asset_name}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.asset_service_date ? moment(rec.asset_service_date).format('YYYY-MM-DD') : '--'}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', paddingTop: "15px" }}>{rec.aging}</p>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </Card>
      </div>
    );
}

export default TopFiveUpComingServicesComponent;
