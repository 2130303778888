import axios from 'axios';
import {HatcheriesDto} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


export class HatcheryService{
    URL = connection.DEPLOY_URL + '/hatcheries';

    async createHatchery(hatchery: HatcheriesDto){
        return await axios.post(this.URL + '/createHatcheries', hatchery)
         .then(res => {
             return res.data
         })
    }
    async updateHatchery(hatchery: HatcheriesDto){
        return await axios.post(this.URL + '/updateHatcheries', hatchery)
         .then(res => {
             return res.data
         })
    }
    async getAllHatcheries(req?:UserRequestDto){
        return await axios.post(this.URL + '/getAllHatcheries',req)
         .then(res => {
             return res.data
         })
    }
    async activateordeactivatehatchery(hatcheryDto: HatcheriesDto){
        return await axios.post(this.URL+ '/activateOrDeactivateHatcheries', hatcheryDto)
         .then(res =>{
             return res.data
         })
    }
    async getHatcheryById(){
        return await axios.post(this.URL + '/getHatcheryById') 
         .then(res =>{
             return res.data
         })
    }
    async getAllActiveHatcheries(){
        return await axios.post(this.URL + '/getAllActiveHatcheries')
          .then(res => {
              return res.data
          })
    }
}