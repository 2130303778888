export const ApprovalInput = [
    { value: 'Satisfactory' },
    { value: 'Un Satisfactory' }
];

export const SignOfContaminationInput = [
    { value: 'No Physical Contamination' },
    { value: 'Yes' },
    { value: 'Yes Physical Contamination' },
];

export const DamageConditionInput = [
    { value: 'Infact No Damage Found' },
    { value: 'Damage Found' }
];

export const ConditionInput = [
    { key: 'Good', value: 'G' },
    { key: 'Bad', value: 'B' },
    { key: 'Satisfy', value: 'S' },
    { key: 'Nil/absent', value: 'N' },
    { key: 'Soft&Firm', value: 'SF' },
    { key: 'Un Satisfy', value: 'US' },
    { key: 'Not Applicable', value: 'NA' },
    { key: 'No Color Change', value: 'NCC' },
    { key: 'Piece', value: 'Pc' },
];

export const InfestationInput = [
    { value: 'Not Found' },
    { value: 'Found' }
];

export const LabellingInput = [
    {
        value: 'SF', key: 'S'
    },
    {
        value: 'N', key: 'N'
    }
]

export const GradingMonitoringInput = [
    {
        value:'SF',key:'s'
    },
    {
        value:'G',key:'g'
    },
    {
        value:'NIL',key:'n'
    }
]