import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { HlPercentageDto } from './hl-percentage.dto';

export class HlPercentageResponseModel extends GlobalResponseObject{
    data?: HlPercentageDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: HlPercentageDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
        }
}
