import { CommonResponse } from "@gtpl/shared-models/masters"
import axios from "axios"
import { WhatsAppBroadCastRequest, WhatsAppLogDto } from '@gtpl/shared-models/whatsapp-broad-cast';
import {appSettings} from '../../../../../apps/services/config';
import { BroadCastMessageRequest } from "@gtpl/shared-models/whatsapp-broad-cast";
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";

export class WhatsappBroadCastService{

   whatsAppBroadCatURL = appSettings.whatsapp_broadcast_url+'/message-broadcast';
   whatsappbiMessageURL = appSettings.whatsapp_broadcast_url+'/bi-message';
  //  whatsAppBroadCatURL = 'http://localhost:3000/api/message-broadcast';
    async CallBroadCastApi(req:WhatsAppBroadCastRequest): Promise<CommonResponse> {
        return await axios.post(this.whatsAppBroadCatURL + '/saveBroadCastedMessageToMultipleCustomers',req)
          .then(res => {
            return res.data
          })
      }
    async sendWhatsappMessage(req: BroadCastMessageRequest): Promise<GlobalResponseObject> {
      console.log(this.whatsAppBroadCatURL)
        return await axios.post(this.whatsAppBroadCatURL + '/sendMessage', req)
            .then(res => {
              console.log(res)
                return res.data
            })
    }
    async createWhatappLog(req: WhatsAppLogDto): Promise<GlobalResponseObject> {

      console.log(this.whatsAppBroadCatURL)
        return await axios.post(this.whatsappbiMessageURL + '/createWhatappLog', req)
            .then(res => {
              console.log(res)
                return res.data
            })
    }
}