import { StoreConsumptionStatus } from "./store-consumption.enum";
import { SaleOrderDetailsResponse, SaleOrderDetailViewInfoResponseModel } from '@gtpl/shared-models/sale-management';
import { GetStoreConsumptionItemsDto } from "./get-store-consumption-items.dto";

export class GetStoreConsumptionDetails {
    consumedDate: Date;
    indentDate: Date;
    saleOrderId: Number;
    saleOrder: SaleOrderDetailsResponse;
    storeConsumptionCode: string;
    indentNo: string; 
    status: StoreConsumptionStatus; 
    createdUser: string;
    storeConsumptionItems: GetStoreConsumptionItemsDto[];
    storeConsumptionId?: Number;
    updatedUser?: string | null;

    /**
     * 
     * @param consumedDate 
     * @param indentDate 
     * @param saleOrder 
     * @param saleOrderId 
     * @param storeConsumptionCode 
     * @param indentNo 
     * @param status 
     * @param createdUser 
     * @param storeConsumptionItems 
     * @param storeConsumptionId 
     * @param updatedUser 
     */
    constructor(consumedDate: Date, indentDate: Date, saleOrderId: Number, saleOrder: SaleOrderDetailsResponse, storeConsumptionCode: string, indentNo: string, status: StoreConsumptionStatus, createdUser: string, storeConsumptionItems: GetStoreConsumptionItemsDto[], storeConsumptionId?: Number, updatedUser?: string | null){
        this.consumedDate = consumedDate;
        this.indentDate = indentDate;
        this.saleOrderId = saleOrderId;
        this.saleOrder = saleOrder;
        this.storeConsumptionCode = storeConsumptionCode;
        this.indentNo = indentNo; 
        this.status = status; 
        this.createdUser = createdUser;
        this.storeConsumptionItems = storeConsumptionItems;
        this.storeConsumptionId = storeConsumptionId;
        this.updatedUser = updatedUser;
    }
}
