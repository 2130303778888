
import axios from 'axios';
import { AllEmployeesResponseModel, EmployeeResponseModel, EmployeesDeptWiseResponseModel } from '@gtpl/shared-models/gtpl';
import { DeptRequest, EmployeeCodeRequest, EmployeeDepartmentRequest, EmployeeDeptRequest, EmployeeDesignationRequest, EmployeeRequest, EmployeeRoleRequest, EmployeeUnitRequest } from '@gtpl/shared-models/masters'
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/production-management';

export class EmployeeService {

  URL = connection.DEPLOY_URL + '/employee-details';


  async getActiveEmployeesById(employeeRequest: EmployeeRequest): Promise<EmployeeResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesById', employeeRequest)
      .then(res => {
        return res.data
      })
  }

  async getAllActiveEmployees(): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveEmployees').then((res) => {
      return res.data;
    });
  }

  async getAllEmployees(): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getAllEmployees').then((res) => {
      return res.data;
    });
  }

  async getActiveEmployeesByEmpCode(empCodeReq: EmployeeCodeRequest): Promise<EmployeeResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesByEmpCode', empCodeReq).then((res) => {
      return res.data
    });
  }

  async getEmployeeByEmpCode(empCodeReq: EmployeeCodeRequest): Promise<EmployeeResponseModel> {
    return await axios.post(this.URL + '/getEmployeeByEmpCode', empCodeReq).then((res) => {
      return res.data
    });
  }

  async getActiveEmployeesByRole(employeeRoleReq: EmployeeRoleRequest): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesByRole', employeeRoleReq).then((res) => {
      return res.data;
    });
  }

  async getAllActiveEmployeesForLeaves(): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveEmployeesForLeaves').then((res) => {
      return res.data;
    });
  }

  async updateDesignationForDesignationAction(request: EmployeeDesignationRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/updateDesignationForDesignationAction', request).then((res) => {
      return res.data;
    });
  }
  async updateDepartmentForDesignationAction(request: EmployeeDepartmentRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/updateDepartmentForDesignationAction', request).then((res) => {
      return res.data;
    });
  }

  async getActiveEmployeesByDeptId(deptId: number): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getActiveEmployeesByDeptId', deptId).then((res) => {
      return res.data;
    });
  }

  async getActiveEmployeeByEmpCode(empCode: string): Promise<EmployeeResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeeByEmpCode', empCode).then((res) => {
      return res.data;
    });
  }

  async getActiveEmpDeptWiseCount(deptReq: DeptRequest): Promise<EmployeesDeptWiseResponseModel> {
    return await axios.post(this.URL + '/getActiveEmpDeptWiseCount', deptReq).then((res) => {
      return res.data;
    });
  }
  async getEmployeesByUnitId(Req: EmployeeUnitRequest): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getEmployeesByUnitId', Req).then((res) => {
      return res.data;
    });
  }
  async getActiveEmployeesByDept(Req: EmployeeDeptRequest): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesByDept', Req).then((res) => {
      return res.data;
    });
  }

  async getAllInactiveEmployees(): Promise<AllEmployeesResponseModel> {
    return await axios.post(this.URL + '/getAllInactiveEmployees').then((res) => {
      return res.data;
    });
  }

} 