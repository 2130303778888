import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DownTimeTrackingDto } from './downtime-tracking.dto';

export class DownTimeTrackingResponsemodel extends GlobalResponseObject{

    data?:DownTimeTrackingDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: DownTimeTrackingDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}