export class PackingCategoryDto{
    packingCategoryId : number;
    packingCategoryName : string;
    isActive: boolean;
    createdUser : string;
    
    /**
     * 
     * @param packingCategoryId number
     * @param packingCategoryName string
     * @param isActive boolean
     * @param createdUser string
     */
    constructor(packingCategoryId : number,packingCategoryName : string,isActive: boolean,createdUser : string){
        this.packingCategoryId = packingCategoryId;
        this.packingCategoryName = packingCategoryName;
        this.isActive= isActive;
        this.createdUser= createdUser;
    }
}
export const PackingCategoryDtoDefault : PackingCategoryDto = {
    packingCategoryId: 0,
    packingCategoryName: '',
    isActive: true,
    createdUser : '',


}; 
