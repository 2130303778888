import { CardHeaderBody } from "./card-header-body";

export class CardBodyDetails {
    cardBodyTitle?: string;
    cardBodyParam1?: string;
    cardBodyParam2?: string;
    cardBodyParam3?: string;
    cardBodyParam4?: string;
    cardBodyParam5?: string;

    constructor(cardBodyTitle: string, cardBodyParam1?: string, cardBodyParam2?: string,cardBodyParam3?: string,cardBodyParam4?: string,cardBodyParam5?: string){

        this.cardBodyTitle = cardBodyTitle,
        this.cardBodyParam1 = cardBodyParam1,
        this.cardBodyParam2 = cardBodyParam2,
        this.cardBodyParam3= cardBodyParam3,
        this.cardBodyParam4 = cardBodyParam4,
        this.cardBodyParam5 = cardBodyParam5
    }
}