export class RackPostionRequest {
    rackId: number;
    updatedUser: string;
    versionFlag: number;
    isActive: boolean;

    constructor(rackId: number,updatedUser: string,versionFlag: number,isActive: boolean) {
        this.rackId = rackId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isActive = isActive;
    }
}