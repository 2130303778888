import { ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto } from "@gtpl/shared-models/masters";
import { SOInfoReq } from "./so-info.req";

export class BomUpdateRequest {
    itemId: number;
    saleOrderId: number;
    acceptedQuantity: any;
    existingQty?: any;


    /**
     * @param itemId
    * @param saleOrderId
    * @param acceptedQuantity
     */
    constructor(itemId: number, saleOrderId: number, acceptedQuantity: any,existingQty?: any) {
        this.itemId = itemId;
        this.saleOrderId = saleOrderId;
        this.acceptedQuantity = acceptedQuantity;
        this.existingQty = existingQty;
    }
}