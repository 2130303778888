import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { RouteDto } from "./route.dto";

export class RouteResponse extends GlobalResponseObject{
    data?: RouteDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: RouteDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
}

}