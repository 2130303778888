import axios from "axios";
import { SoakingAssignmentDto, SoakingResponseModel, AllSoakingAssignmentResponseModel, JobCompletedStatusForAssignmentDto, assignSoakingForFreezingResponse, BeheadingInventoryResponse, LogIdRequest, LotDetailsResponseModel, ReportSoakingRequest, workstationsStatusResponseModel, SoakingStatusWiseResponseModel} from '@gtpl/shared-models/production-management';
import { JobCompletedEnum, PlantsEnum, ShiftsEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import connection from './connection';

export class SoakingAssignmentService{
    URL = connection.DEPLOY_URL + '/soaking';

    async createSoakingAssignment(formInfo):Promise<SoakingResponseModel>{
        console.log(formInfo);
        return await axios.post(this.URL + '/createSoakingAssignment', formInfo)
            .then(res => {
                return res.data
            })   
    }
    async reportSoakingAssignment(formInfo:ReportSoakingRequest):Promise<SoakingResponseModel>{
        console.log(formInfo);
        return await axios.post(this.URL + '/reportSoakingAssignment', formInfo)
            .then(res => {
                return res.data
            })   
    }

    async getAllSoakingAssignments(plantReq:UnitRequest):Promise<SoakingResponseModel>{
        return await axios.post(this.URL + '/getAllSoakingAssignments', plantReq)
            .then(res =>{
                return res.data;
            })
    }
    async  jobCompletedStatus(soakingInfo: JobCompletedStatusForAssignmentDto): Promise<assignSoakingForFreezingResponse> {
        console.log(soakingInfo);
        return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
                .then(res => {
                    return res.data
                })
    }
    async  getLogDetailsById(inputReq: LogIdRequest): Promise<LotDetailsResponseModel> {
        // console.log(inputReq.productionLogid);
        return await axios.post(this.URL + '/getLotDetailsById', inputReq)
                .then(res => {
                    return res.data
                })
    }
    async  getInProgressWorkstations(inputReq: UnitRequest): Promise<workstationsStatusResponseModel> {
        // console.log(inputReq.productionLogid);
        return await axios.post(this.URL + '/getInProgressWorkstations', inputReq)
                .then(res => {
                    return res.data
                })
    }
    async  getsoakingStatusWiseDetails(inputReq: UnitRequest): Promise<SoakingStatusWiseResponseModel> {
        return await axios.post(this.URL + '/getsoakingStatusWiseDetails', inputReq)
                .then(res => {
                    return res.data
                })
    }

}