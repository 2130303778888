export class HatcheriesRequest{
    [x: string]: any;
    hatcheriesId : number;
    versionFlag: any;
    
    constructor(hatcheriesId : number)
    {
        this.hatcheriesId = hatcheriesId;
        
    }
}
