import React,{ useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Redirect } from 'react-router-dom';
import './qc-defect-details-grid.css';
import {QcDefectDetailsForm} from '@gtpl/pages/production-management/production-management-components/qc-defect-details-form';
import { QcDefectItemDetailsDto } from '@gtpl/shared-models/production-management';
import { QcDefectService } from '@gtpl/shared-services/production';
import { error } from 'console';

export interface QcDefectItemDetailsGridProps{
  qcId:number;
}

export function QcDefectItemDetails(props:QcDefectItemDetailsGridProps){
    const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [qcdefectItemsData,setQcdefectItemsData] = useState<QcDefectItemDetailsDto[]>([])
  const defectitemservice=new QcDefectService

  useEffect(() => {
    getitemsdata();
}, [])

  const getitemsdata = () =>{
    defectitemservice.getQcdefectItemByQcId({qcId: props.qcId}).then(res =>{
      if(res.status){
        setQcdefectItemsData(res.data);
      }else{
        setQcdefectItemsData([]);
      }
    }).catch(err =>{
      setQcdefectItemsData([]);
    })

  }
  
  const sampleTypeColumns:ColumnsType<any>= [
    {
      title:'#',
      dataIndex:'qcId',
      width: 50,
      fixed: 'left',
      align:'left',
    },
    {
      title: 'Defect',
      dataIndex:"testName",
      align:'left',
    
    },
  {
      title: 'Rejected Quantity',
      dataIndex:'rejectedQuantity',
      align:'left',
  }
   
];
return (
  <>
      <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 ,textAlign:'center'}} title={<span>Defect Details</span>}></Card>
      <Table
          key={Date.now()}
          className="components-table-nested"
          columns={sampleTypeColumns}
          dataSource={qcdefectItemsData}
      />

  </>
);

}
export default QcDefectItemDetails;

