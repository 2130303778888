import { GlobalStatus } from "@gtpl/shared-models/common-models";
import { PackingSTItems } from "./packing-stock-transfer-items.model";

export class PackingStockTransferData{
    fromPlant:number;
    toPlant:number;
    transferredDate:Date;
    note:string;
    stockTransferItems?:PackingSTItems[];
    packingStockTransferId?:number;
    transferType:GlobalStatus;
}