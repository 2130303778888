export class ValueAdditionFiveDto{
    valueAdditionFiveId?:number;
    code:string;
    name:string;
    remarks:string;
    createdUser:string;
    isActive:boolean;
    updatedUser?:string;
    
    /**
     * 
     * @param valueAdditionFiveId number
     * @param code string
     * @param name string
     * @param remarks string
     * @param createdUser string
     * @param isActive boolean
     * @param updatedUser string
     */
    constructor(valueAdditionFiveId:number,code:string,name:string,remarks:string,createdUser:string,isActive:boolean,updatedUser?:string){
    this.valueAdditionFiveId = valueAdditionFiveId;
    this.code = code;
    this.name = name;
    this.remarks = remarks;
    this.createdUser = createdUser;
    this.isActive = isActive;
    this.updatedUser = updatedUser;
    }
}