import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { LocationDto } from "libs/shared-models/masters/src/lib/location/location-dto";
import connection from "./connection";
import {LocationById} from '../../../../shared-models/asset-management/src/lib/asset-location/location-by-id.dto'
import {AssetsLocationDto} from '../../../../shared-models/asset-management/src/lib/asset-location/asset-location.dto'
import { AssetCheckListDto } from '@gtpl/shared-models/asset-management';
import {LocationByPlant} from '../../../../shared-models/asset-management/src/lib/asset-location/location-by-plant.dto'



export class AssetCheckListService{
    URL = connection.DEPLOY_URL + '/checkList';

    
    async createAssetChecklist(checkList: AssetCheckListDto): Promise<CommonResponse> {
        return await axios
          .post(this.URL + '/createAssetChecklist', checkList)
          .then((res) => {
            return res.data;
          });
      }

    async getAllChecklist() : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllChecklist')
        .then(res => {
            return res.data;
        })
    }
    async updateChecklist(req: AssetCheckListDto) : Promise<CommonResponse> {
      return await axios.post(this.URL + '/updateChecklist',req)
      .then(res => {
          return res.data;
      })
  }
  async activateorDeactivateLicense(req: AssetCheckListDto) : Promise<CommonResponse> {
    return await axios.post(this.URL + '/activateorDeactivateLicense',req)
    .then(res => {
        return res.data;
    })
}


}