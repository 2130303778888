import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import './store-return-grid.css';
import { StoreReturnService, StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { GetStoreConsumptionItemsDto, StoreConsumptionDetails, StoreReturnDto } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import { SaleOrderStatusEnum, SaleStatusEnum, SoProcessTypesEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { StoreReturnInformation } from 'libs/shared-models/procurement-management/src/lib/store-returns/store-return-information.dto';
import { Link, Redirect } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import StoreConsumptionsItemsDetails from './store-consumption-items-detail-view';


/* eslint-disable-next-line */
export interface StoreReturnGridProps {
  storeReturnData: StoreReturnInformation[]
}



export function StoreReturnGrid(
  props: StoreReturnGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { TabPane } = Tabs;


  var today = new Date();
  const date1 = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();

  const [storeReturnData, setStoreReturnData] = useState<StoreReturnInformation[]>(props.storeReturnData);
  const [object, setObject] = useState(null);
  const [storeReturnId, setStoreReturnId] = useState<number>(0);
  const [formVisible, setFormVisible] = useState<boolean>(false);

  const [storeConsumptionData, setStoreConsumptionData] = useState<StoreConsumptionDetails[]>([]);
  const storeConsumptionService = new StoreConsumptionService();
  const [storeConsumptionItemsData, setStoreConsumptionItemsData] = useState<GetStoreConsumptionItemsDto[]>([]);

  useEffect(() => {
    getAllStoreConsumptionDetails();
    // getAllStoreConsumptionItemDetails();
  }, []);
  // const getAllStoreConsumptionItemDetails = () => {
  //   storeConsumptionService.getAllStoreConsumptionItemDetails().then(res => {
  //     console.log(res.data);
  //     if (res.status) {
  //       console.log(res.data);
  //       setStoreConsumptionItemsData(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         setStoreConsumptionItemsData([]);
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     setStoreConsumptionItemsData([]);
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  const goToReturnForm = () => {
    setFormVisible(true);
  }
  const getAllStoreConsumptionDetails = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getAllStoreConsumptionDetails(req).then(res => {
      console.log(res.data);
      if (res.status) {
        setStoreConsumptionData(res.data);
      } else {
        if (res.intlCode) {
          setStoreConsumptionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setStoreConsumptionData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  // const closeDrawer=()=>{
  //   setDrawerVisible(false);
  // }

  const gotToStoreReturnDetailView = (storeReturnId: number) => {
    setStoreReturnId(storeReturnId);
    console.log(storeReturnId);
  }
  let itemId = '';
  let itemCategory = 'itemCategoryDetails.itemCategoryId';

  // Table Columns 
  const getTableColumns = () => {
    const columns: ColumnProps<any>[] = [
      {
        title:'S No',
        key: 'sno',
        width: '70px',
        align: 'left',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
  
      },
      {
        title: 'Prod Unit',
        dataIndex: 'unitId',
        align: 'center',
        width:180,
      // ...getColumnSearchProps('unitId'),
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.unitId == value;
      },
      },
      {
        title: 'Store Return Date',
        dataIndex: 'storeReturnDate',
        align: 'center',
        key: 'storeReturnDate',
        sorter: (a, b) => moment(a.storeReturnDate).unix() - moment(b.storeReturnDate).unix(),
        render: (value, record: StoreReturnInformation, index) => {
          return moment(value).format('YYYY/MM/DD');
        }

      },
      {
        title: 'Customer PO',
        dataIndex: "saleOrder",
        align: 'center',
        key: 'saleOrder',
        sorter: (a, b) => a.saleOrder?.localeCompare(b.saleOrder),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('saleOrder')
      },
      {
        title: 'Store Return No',
        dataIndex: 'storeReturnNo',
        align: 'center',
        key: 'storeReturnNo',
        sorter: (a, b) => a.storeReturnNo?.localeCompare(b.storeReturnNo),
        ...getColumnSearchProps('storeReturnNo')
      },
      // {
      //   title: 'PO Number',
      //   dataIndex: 'poNumber',
      //   align: 'center',
      //   key: 'poNumber',
      //   sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      //   // ...getColumnSearchProps('itemCategoryDetails.itemCategory'),
      //   render: (value, record: StoreReturnInformation, index) => {
      //     return (record.saleOrder) ? record.poNumber : '';
      //   }
      // },
      // {
      //   title: 'Packing Category',
      //   dataIndex: 'category',
      //   align: 'center',
      //   key: 'category',
      //   sorter: (a, b) => a.category?.localeCompare(b.category),
      // },
      {
        title: 'Item',
        dataIndex: 'itemName',
        align: 'center',
        key: 'itemName',
        sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      },
      {
        title: 'Returned Quantity',
        dataIndex: 'returnQuantity',
        align: 'center',
        key: 'returnQuantity',
        sorter: (a, b) => a.returnQuantity - b.returnQuantity,
        ...getColumnSearchProps('returnQuantity'),
      },
      // {
      //   title: 'Remarks',
      //   dataIndex: 'remarks',
      //   align:'center',
      //   key: 'remarks',
      //   ...getColumnSearchProps('remarks')
      // },
      // {
      //   title:`Action`,
      //   dataIndex: 'action',
      //   align:'center',
      //   render: (text, rowData) => (
      //     <span>         
      //         <EyeOutlined  className={'viewSamplTypeIcon'}  type="view" 
      //         onClick={e => gotToStoreReturnDetailView(rowData.storeReturnId)}
      //           style={{ color: '#1890ff', fontSize: '14px' }}
      //         />

      //     </span>
      //   )
      // }
    ];
    return columns;
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitId',
      align: 'center',
      width:180,
// ...getColumnSearchProps('unitId')
filters: [
  {
    text: 'Unit 1',
    value: 'Unit 1',
  },
  {
    text: 'Unit 2',
    value: 'Unit 2',
  },
  {
    text: 'Kakinada',
    value: 'Kakinada',
  },
],
filterMultiple: false,
onFilter: (value, record) => {
  return record.unitId == value;
},
    },
    {
      title: 'Store Consumption Code',
      dataIndex: 'storeConsumptionCode',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.storeConsumptionCode.localeCompare(b.storeConsumptionCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('storeConsumptionCode')
    },


    {
      title: 'Customer PO',
      dataIndex: 'saleOrder',
      align: 'left',
      width: 200,

      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder')
    },
    {
      title: 'Customer',
      dataIndex: "endCustomerName",
      align: 'left',
      width: 200,

      sorter: (a, b) => a.endCustomerName.localeCompare(b.endCustomerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerName')
    },

    {
      title: 'Consumed Date',
      dataIndex: 'consumedDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.consumedDate).unix() - moment(b.consumedDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.consumedDate).format('YYYY-MM-DD') },

      // ...getColumnSearchProps('consumedDate')
    },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      align: 'left',
      width: 150,
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },


      // ...getColumnSearchProps('indentDate')
    },
    {
      title: 'Indent Number',
      dataIndex: 'indentNo',
      width: 140,
      align: 'left',
      sorter: (a, b) => a.indentNo.localeCompare(b.indentNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNo')
    },
    // {
    //   title: 'Sale Person',
    //   dataIndex: ["salePersonInfo","employeeName"],
    //   align:'left',
    //   sorter: (a, b) => a.salePersonInfo.employeeName.localeCompare(b.salePersonInfo.employeeName),
    //   sortDirections: ['descend', 'ascend'],
    //   // ...getColumnSearchProps('salePerson')
    // },

    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   align:'left',
    //   render: (status, rowData) => (
    //     <>
    //       {status?<Tag icon={<CheckCircleOutlined />} color="#87d068">{status}</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">{status}</Tag>}

    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'OPEN',
    //       value: 'OPEN',
    //     },
    //     {
    //       text: 'RETURNED',
    //       value: 'RETURNED',
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     console.log(value);
    //     return record.status === value;
    //   },

    // },
    // {
    //   title:`Action`,
    //   dataIndex: 'action',
    //   align:'left',
    //   render: (text, rowData) => (
    //     <span>   
    //       {(storeConsumptionId) ? <Redirect to={{pathname:"/store-consumption-detail-view", state:storeConsumptionId}}/>:null}      
    //         <EyeOutlined  className={'viewSamplTypeIcon'}  type="view" 
    //         onClick={() => gotToStoreIssuesDetailView(rowData)}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />

    // //     </span>
    //   )
    // }
    // {
    //     title:`Action`,
    //     dataIndex: 'action',
    //     align:'left',
    //     render: (text, rowData) => (
    //       <span>   
    //         {(formVisible) ? <Redirect to={{pathname:"/store-return-form", state:rowData.storeConsumptionId}}/>:null}      
    //           <EyeOutlined  className={'viewSamplTypeIcon'}  type="view" 
    //           onClick={() => goToReturnForm()}
    //             style={{ color: '#1890ff', fontSize: '14px' }}
    //           />
  
    //       </span>
    //     )
    //   }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const renderItems = (record: GetStoreConsumptionItemsDto, index, indent, expanded) => {
    return <StoreConsumptionsItemsDetails storeConsumptionId={record.storeConsumptionId} />
  }

  return (
    <>
      {(storeReturnId) ? <Redirect to={{ pathname: "/store-return-detail-view", state: storeReturnId }} /> : null}

      {/* <Row gutter={24} >
        <Col>
          <Card title={'TOTAL RETURNED QUANTITY: ' + props.storeReturnData.reduce((sum, data) => sum + data.returnQuantity, 0)} style={{ textAlign: 'left', height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col >
          <Card title={'OPEN : ' + (storeConsumptionData.filter(el => el.status == "OPEN" && el.soStatus != "CANCELLED")).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f6e552' }}></Card>
        </Col>
        <Col >
          <Card title={'IN PROGRESS : ' + props.storeReturnData.length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f2a637' }}></Card>
        </Col>
        <Col >
          <Card title={"CANCELLED SO's : " + (storeConsumptionData.filter(el => el.soStatus == "CANCELLED")).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f2a637' }}></Card>
        </Col>
      </Row> */}
      <br></br><br/>
      <div className="card-container">
        <Tabs type={'card'} tabPosition={'top'}>
          {/* <TabPane 
              key = "1"
              tab = {<span style={{color: '#f5222d'}}>{'Last 24hrs: '+(props.storeReturnData.filter(el=>el.storeReturnDate.toLocaleDateString() == date1).length)}</span>}
              >
              <Table key={Date.now()} columns={getTableColumns()} dataSource={props.storeReturnData.filter(el=>el.storeReturnDate.toLocaleDateString() == date1)} onChange={onChange}
               pagination={{
               onChange(current) {
               setPage(current);
               }
               }}
               bordered/>
              </TabPane> */}
          <TabPane
            key="1"
            tab={<span style={{ color: '#f5222d' }}>{'Open: ' + (storeConsumptionData.filter(el => el.status == "OPEN" && el.soStatus != "CANCELLED")).length} </span>}
          >
            {/* <Table key={'OPEN'} columns={getTableColumns()} dataSource={props.storeReturnData.filter(rec => rec.saleOrder.status == SaleOrderStatusEnum.OPEN)} onChange={onChange}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              bordered /> */}
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={columnsSkelton}
              dataSource={storeConsumptionData.filter(el => el.status == 'OPEN' && el.soStatus != "CANCELLED")}
              expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered

            >
            </Table>
          </TabPane>
          <TabPane
            key="2"
            tab={<span style={{ color: '#f5222d' }}>{'In Progress: ' + (props.storeReturnData.length)}</span>}
          >
            <Table 
            key={Date.now()}
             columns={getTableColumns()}
             dataSource={props.storeReturnData} 
             onChange={onChange}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              bordered />
          </TabPane>
          <TabPane
            key="3"
            tab={<span style={{ color: '#f5222d' }}>{"Cancelled SO's :"  + (storeConsumptionData.filter(el => el.soStatus == "CANCELLED")).length}</span>}
          >
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={columnsSkelton}
              dataSource={storeConsumptionData.filter(el => el.soStatus == "CANCELLED")}
              expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered

            ></Table>
          </TabPane>
          <TabPane
            key="4"
            tab={<span style={{ color: '#f5222d' }}>{"Shipped SO's :"  + (storeConsumptionData.filter(el => el.containerStatus == "SHIPPED")).length}</span>}
          >
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={columnsSkelton}
              dataSource={storeConsumptionData.filter(el => el.containerStatus == "SHIPPED")}
              expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered

            ></Table>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default StoreReturnGrid;


