export class ProductRequest{
    productId?:number;
    
    /**
     * 
     * @param productId 
     */
   
    constructor(productId:number){
    this.productId = productId;
  
    }
} 
