import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PONumbersDropDownDto } from './po-number-dropdown.dto';

export class PoNumberDropDownResponse extends GlobalResponseObject {
    data?: PONumbersDropDownDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PONumbersDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

