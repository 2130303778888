import { GlobalResponseObject } from '@gtpl/shared-models/common-models'
import { AssetsSubCatagoryDto } from '.';




export class AssetsSubCatagoryResponseModel extends GlobalResponseObject {
    data?: AssetsSubCatagoryDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AssetsSubCatagoryDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
