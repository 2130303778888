import { GlobalResponseObject, PackingStatus, TransactionType } from '@gtpl/shared-models/common-models';
import { StoreReturnInformation } from '@gtpl/shared-models/procurement-management';

export class StockInPrinResponse{
    clientName:string;
    brandName:string;
    customerPo:number;
    transactionType:TransactionType;
    stocktype:string;
    varientcode:string;
    reportedCases:number;
    poucShortage:PackingStatus;
    cartonShortage:PackingStatus;
    packmethodName:string;
    rackPostion:string;
    palletCode:string;
    constructor( clientName:string,brandName:string,customerPo:number, transactionType:TransactionType,stocktype:string,varientcode:string,
        reportedCases:number,poucShortage:PackingStatus,cartonShortage:PackingStatus,packmethodName:string, rackPostion:string)

{
    this.clientName=clientName;
    this.brandName=brandName;
    this.customerPo=customerPo;
    this.transactionType=transactionType;
    this.stocktype=stocktype;
    this.varientcode=varientcode;
    this.reportedCases=reportedCases;
    this.poucShortage=poucShortage;
    this.cartonShortage=cartonShortage;
    this.packmethodName=packmethodName;
    this.rackPostion=rackPostion;
}

}