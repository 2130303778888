export class PoVendorDataModel {
    vendorId:number;
    vendorCode: string;
    vendorName: string;
    contactPerson: string;
    contactNumber: string;
    street: string;
    apartment: string;
    city: string;
    postalCode: string;

    /**
    * @param vendorId
     * @param vendorCode
    * @param vendorName
    * @param contactPerson
    * @param contactNumber
    * @param street
    * @param apartment
    * @param city
    * @param postalCode
     */
    constructor(vendorId:number,vendorCode: string,vendorName: string,contactPerson: string,contactNumber: string,street: string,apartment: string,city: string,postalCode: string){
        this.vendorId = vendorId;
        this.vendorCode = vendorCode;
    this.vendorName = vendorName;
    this.contactPerson = contactPerson;
    this.contactNumber = contactNumber;
    this.street = street;
    this.apartment = apartment;
    this.city = city;
    this.postalCode = postalCode;
    }

}

