import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Upload, message, UploadProps } from 'antd';

import './store-consumption-form.css';
import StoreConsumptionDetailsForm from './store-consumption-details-form';
import StoreConsumptionItemsForm from './store-consumption-items-form';
import { AvailStockDto, StoreConsumptionDetails, StoreConsumptionItemsDto } from '@gtpl/shared-models/procurement-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeService, ItemCategoryService, ItemsService } from '@gtpl/shared-services/masters';
import { EmployeeDto, ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, ProductDto } from '@gtpl/shared-models/masters';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
import { StockService, StoreConsumptionService } from '../../../../../../shared-services/procurement/src';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { UnitRequest } from '@gtpl/shared-models/common-models';


/* eslint-disable-next-line */
export interface StoreConsumptionFormProps {
  isUpdate: boolean;
  closeForm: () => void;
}

export function StoreConsumptionForm(
  props: StoreConsumptionFormProps
) {
  const [storeConsumptionDetailsForm] = Form.useForm();
  const [storeConsumptionItemsForm] = Form.useForm();
  const [itemDetailsGridData, setItemDetailsGridData] = useState<StoreConsumptionItemsDto[]>([]);
  const [itemsCategorydata, setItemsCategorydata] = useState<ItemCategoryDropDownDto[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([])
  const [salesPersonData, setSalesPersonData] = useState<EmployeeDto[]>([])
  const [storeConsumptionData, setStoreConsumptionData] = useState<any>(undefined)
  const [redirectView, setRedirectView] = useState<boolean>(false)
  const [updateKey, setUpdateKey] = useState<number>(0)
  const [itemDetails, setItemDetails] = useState<boolean>(false)
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [saleOrderId, setSaleOrderId] = useState<number>(0);
  const [saleOrderItemId, setSaleOrderItemId] = useState<number>(0);



  const saleOrdersService = new SaleOrderService();
  const itemCategoryService = new ItemCategoryService();
  const saleOrderService = new SaleOrderService();
  const storeConsumptionService = new StoreConsumptionService();
  const employeeService = new EmployeeService();
  const stockService = new StockService();



  useEffect(() => {
    getSaleOrderData();
    getSalesPersonData();
  }, []);
  const getSaleOrderData = () => {
    const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    stockService.getAvailableStockSosForConsumption(unitIdReq).then((res) => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const getSalesPersonData = () => {
    employeeService.getAllActiveEmployees().then((res) => {
      if (res.status) {
        setSalesPersonData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSalesPersonData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSalesPersonData([]);
    });
  }
  const setItemsPanelStatus = (reqData) => {
    console.log(reqData,'item panel props')
    if (reqData.saleOrderId > 0) {
      setUpdateKey(prevVal => prevVal+1)
      // setItemDetails(!itemDetails);
      // setItemDetails(true);
    }
    else {
      setItemDetails(!itemDetails);
      setItemDetails(false);
    }
    setSaleOrderId(reqData.saleOrderId);
    setSaleOrderItemId(reqData.saleOrderItemId)
  }
  const getItemsCategoryData = (reqData) => {
    console.log(reqData);
    saleOrderService.getAllBomDetailsBySoId({ saleOrderId: reqData }).then((res) => {
      console.log(res.data);
      if (res.status) {
        setItemDetails(true);
        setItemsCategorydata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemsCategorydata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsCategorydata([]);
    });
  }


  const getStoreConsumptionItemDetailsData = () => {
    const storeConsumptionItemsDto: StoreConsumptionItemsDto[] = []
    itemDetailsGridData.forEach(itemGridData => {
      console.log(itemGridData);
      storeConsumptionItemsDto.push(new StoreConsumptionItemsDto(undefined, undefined, undefined, itemGridData['itemId']['value'], itemGridData['availableStock'], itemGridData['quantity'], itemGridData['status'], undefined, undefined, undefined, undefined, undefined, undefined,itemGridData.stockType))
    })
    console.log(storeConsumptionItemsDto)
    return storeConsumptionItemsDto;
  }
  const addDataToItemDetails = (itemDetalFormdata: StoreConsumptionItemsDto[]) => {
    console.log(itemDetalFormdata)
    setItemDetailsGridData(itemDetalFormdata)
  }
  const onReset = () => {
    storeConsumptionDetailsForm.resetFields();
    storeConsumptionItemsForm.resetFields();
  };
  const saveStoreConsumption = () => {
    console.log(storeConsumptionDetailsForm.getFieldsValue());
    console.log(storeConsumptionItemsForm.getFieldsValue());
    // if(storeConsumptionItemsForm.getFieldValue("itemCategory")===undefined){
    //   AlertMessages.getErrorMessage("Items Data not found")
    // }
    storeConsumptionDetailsForm.validateFields().then(storeConsumptionres => {
      // storeConsumptionItemsForm.validateFields().then(storeItems => {
      console.log('itemDetailsGridData.length' + itemDetailsGridData.length);
      if (itemDetailsGridData.length > 0) {
        setBtnDisable(true);
        const storeDetails = storeConsumptionDetailsForm.getFieldsValue();
        const storeItemDetails = storeConsumptionItemsForm.getFieldsValue();
        const storeConsumptionDto = new StoreConsumptionDetails(
          storeDetails.consumedDate,
          // moment(storeDetails.indentDate).format('YYYY/MM/DD HH:MM:SS');
          storeDetails.indentDate,
          storeDetails.saleOrderId,
          storeDetails.storeConsumptionCode,
          storeDetails.indentNo,
          // storeDetails.salePerson,
          1,
          storeDetails.status,
          localStorage.getItem('createdUser'),
          Number(localStorage.getItem('unit_id')),
          getStoreConsumptionItemDetailsData(),""
        );
        storeConsumptionDto.soType = storeDetails.soType
        console.log(storeConsumptionDto);
        storeConsumptionService.createStoreConsumption(storeConsumptionDto).then((res) => {
          if (res.status) {

            AlertMessages.getSuccessMessage('Store Consumption created successfully');
            goToSaleDetailView();
            // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
            // history.push("/saleorder-tracker");
            onReset();
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
      }
      // })
    })
  }
  const goToSaleDetailView = () => {
    setRedirectView(true);
  }

  return (
    <>
      {(redirectView) ? <Redirect key={Date.now()} to={{ pathname: "/store-consumption-grid" }} /> : null}

      <Card title={props.isUpdate ? "Update Store Consumption Details" : "Create Store Consumption"} extra={<Link to='/store-consumption-grid' ><Button className='panel_button' >View </Button></Link>}>
        <StoreConsumptionDetailsForm form={storeConsumptionDetailsForm} saleOrderData={saleOrderData} getItemsPanel={setItemsPanelStatus} getSalesPersonData={salesPersonData} />
        {updateKey > 0 ?
          <StoreConsumptionItemsForm key={updateKey} form={storeConsumptionItemsForm} saleOrderId={saleOrderId} saleOrderItemId={saleOrderItemId} addDataToItemDetails={addDataToItemDetails} />
          : ""}
        <br />
        
        <Row justify="end">
          <Col>
            <Form.Item >
              <br />
              <Button type='primary' onClick={saveStoreConsumption} block disabled={btnDisable}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default StoreConsumptionForm;
