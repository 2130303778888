
export class VendorItemRequest {
    vendorId: number;
    itemId: number;
    /**
     * 
     * @param vendorId 
     * @param itemId 
     */
    constructor(vendorId: number, itemId: number){
        this.vendorId = vendorId;
        this.itemId = itemId;
    }
}