export class SizesDto {
    sizeId?:number;
    sizeName: string;
    itemCategoryId: number;    
    itemCategory?:string;
    itemSubCategoryId?:number;
    itemSubCategory?: string;
    itemId: number;
    itemName?:string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;


}

export const sizesDtoDefault : SizesDto = {
    sizeName: "",
    itemCategoryId: 0,
    itemId: 0,
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1

};