import { LotCodeAttribute } from "./lotCodeAttribute";
import { LotCodeDeHeading } from "./lotCodeDeheadingChild";

export class LotCodeDto{
    name: string;
    attribute:LotCodeAttribute;
    children:LotCodeDeHeading;
    constructor(name: string, attribute:LotCodeAttribute, children:LotCodeDeHeading) {
        this.name = name;
        this.attribute = attribute;
        this.children = children;
    }
}