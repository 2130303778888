export class VendorPriceListCreateDto{
    vendorId: number;
    itemId: number;
    taxId: number;
    unitPrice: number;
    discountPercentage: number;
    discountPrice: number;
    actualPrice: number;
    skuCode : string;
    createdUser : string;
    isActive: boolean;
    updatedUser : string;
    vendorPriceListId?: number;

    /**
     * 
     * @param vendorId 
     * @param itemId 
     * @param taxId 
     * @param unitPrice 
     * @param createdUser 
     * @param updatedUser 
     * @param vendorPriceListId 
     */
    constructor(vendorId: number, itemId: number, taxId: number, unitPrice: number, discountPercentage: number, discountPrice: number, actualPrice: number, skuCode : string, createdUser : string,isActive: boolean, updatedUser : string, vendorPriceListId?: number){
        this.vendorId = vendorId;
        this.itemId = itemId;
        this.taxId = taxId;
        this.unitPrice = unitPrice;
        this.discountPercentage = discountPercentage;
        this.discountPrice = discountPrice;
        this.actualPrice = actualPrice;
        this.skuCode = skuCode;
        this.createdUser  = createdUser;
        this.isActive  = isActive;
        this.updatedUser  = updatedUser;
        this.vendorPriceListId = vendorPriceListId;
    }
  }