
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Form, Table, Popconfirm, Card, Tooltip, Switch, Input, InputNumber, Button, Tag, Row, Tabs, Col, Descriptions, Drawer, Typography, Modal } from 'antd';
import { CaptureWorkStatusDto, WorkStatusDto } from '@gtpl/shared-models/production-management';
import { CaptureWorkStatusService, ValueAdditionService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, RedoOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FactoriesInput } from '@gtpl/shared-models/sale-management';

import { Link, Redirect } from 'react-router-dom';
import './report-sub-plant-operations.css';
import { OperationTypeEnum, JobCompletedEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import ReportingSubPlantForm from './reporting-sub-plant-op-form';
import { PrinterOutlined } from '@ant-design/icons'
import { SubPlantDcPrint } from '@gtpl/pages/production-management/production-management-components/sub-plant-dc-print'

/* eslint-disable-next-line */
export interface ReportSubPlantOperationsProps { }

export function ReportSubPlantOperations(props: ReportSubPlantOperationsProps) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const text = <span>Rejections</span>
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [captureWorkStatusData, setCaptureWorkStatusData] = useState<CaptureWorkStatusDto[]>([]);
  const [workStatusData, setWorkStatusData] = useState<WorkStatusDto[]>([]);
  const [workStatusDto, setSelectedWorkStatusDto] = useState<WorkStatusDto>(undefined);
  const { TabPane } = Tabs;
  const captureWorkStatusService = new CaptureWorkStatusService();
  const valueAdditionService = new ValueAdditionService();
  const [opBoxData, setOpBoxData] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [printType, setPrintType] = useState('');
  const [selectedLogId, setSelectedLogId] = useState<number>(0);
  const [operationType, setOperationType] = useState<OperationTypeEnum>();
  const [formOrPrint, setFormOrPrint] = useState('');
  

  useEffect(() => {
    valAdditionWorkStatusInfo()
    // getAllCaptureWorkStatus();
  }, []);

  /**
   * 
   */
  const getAllCaptureWorkStatus = () => {
    captureWorkStatusService.getAllCaptureWorkStatus().then(res => {
      if (res.status) {
        setCaptureWorkStatusData(res.data);
      } else {
        if (res.intlCode) {
          setCaptureWorkStatusData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCaptureWorkStatusData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const valAdditionWorkStatusInfo = () => {
    const unitId = Number(localStorage.getItem('unit_id'))
    valueAdditionService.valAdditionWorkStatusInfo(new UnitRequest(unitId)).then(res => {
      if (res.status) {
        setWorkStatusData(res.data);
      } else {
        if (res.intlCode) {
          setWorkStatusData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setWorkStatusData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const jobCompletedStatus = (workstatusDto: WorkStatusDto) => {
    workstatusDto.plantId = Number(localStorage.getItem('unit_id'));
    valueAdditionService.ReportSubPlantOperation(workstatusDto).then(res => {
      if (res.status) {
        valAdditionWorkStatusInfo();
        AlertMessages.getSuccessMessage('Success');
        setIsModalVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setWorkStatusData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const opBoxes = (value) => {
    setOpBoxData(value)
  }


  /**
   * 
   * @param soakingData 
   */
  // const activateorDeactivateSoakingInventory = (soakingData:SoakingDto) => {
  //   soakingData.jobStatus=soakingData.jobStatus?false:true;
  //   soakingInventoryService.activateorDeactivateSoakingInventory(soakingData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllSoakingInventories();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }


  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  /**
   * 
   * @param soakingData 
   */
  // const soakingAssignmentJobCompleted = (captureWorkStatusData:CaptureWorkStatusDto) => {
  //   captureWorkStatusService.jobCompletedStatus(captureWorkStatusData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllCaptureWorkStatus();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const setData = (rowdata) => {
    setObject(rowdata.productionInventoryId)
    console.log(object);
  }

  const onFinish = (data) => {
  }

  // const jobCompleted = (data:CaptureWorkStatusDto) => {
  //   captureWorkStatusService.jobCompletedStatus(data).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllCaptureWorkStatus();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }


  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plantId',
      // responsive: ['lg'],
      sorter: (a, b) => a.plantId - (b.plantId),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantId'),
      render: (value) => {
        let name = FactoriesInput.map((unit) => {
          if (unit.id == value) {
            return unit.value
          }
        });
        return name;
      }
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNo')
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    }, {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber')
    }, {
      title: 'Grade',
      dataIndex: 'Grade',
    }, {
      title: 'I/P Boxes',
      dataIndex: 'ipBoxes',
      align: 'right',
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      align: 'right',
    },
    {
      title: 'I/P Qty',
      dataIndex: 'ipQuantity',
      align: 'right',
    },
    {
      title: 'O/P Qty',
      dataIndex: 'opQuantity',
      align: 'right',
    },
    // {
    //   title: 'O/P Boxes',
    //   dataIndex: 'opBoxes',

    //   render: (value, rowData) => {
    //     console.log(rowData);
    //     return (
    //       rowData.opBoxes
    // Math.ceil((rowData.ipBoxes) * (0.7))
    // <Form.Item name={"opBoxes" + rowData.productionLogId}
    //   rules={[
    //     {
    //       required: true, message: 'O/P Boxes Name is required'
    //     },
    //     {
    //       pattern: /^[0-9]*$/,
    //       message: `Should contain only numbers.`
    //     },
    //     {
    //       validator: (_, value) => {
    //         if (value <= rowData?.ipBoxes) {
    //           rowData.opBoxes = value;
    //           Promise.resolve()
    //         } else {
    //           Promise.reject(new Error('Should not be greater than O/P Boxes'))
    //         }
    //       }
    //     }]} >
    //   <InputNumber
    //     min={0}
    //     required={true}
    //     style={{ width: '100%' }}
    //     defaultValue={Math.ceil((rowData.ipBoxes) * (0.7))}
    //     onChange={opBoxes}
    //   />
    // </Form.Item>
    //     )
    //   }
    // },
    // {
    //   title: 'I/P Qty',
    //   dataIndex: 'ipQuantity',
    //   align: 'right',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.ipQuantity - b.ipQuantity,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('ipQuantity')
    // },
    // {
    //   title: 'O/P Qty',
    //   dataIndex: 'opQuantity',
    //   align: 'right',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.opQuantity - b.opQuantity,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('opQuantity')
    // },
    // {
    //   title: 'Wastage Qty',
    //   dataIndex: 'wastageQuantity',
    //   align: 'right',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.opQuantity - b.opQuantity,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('opQuantity')
    // },
    // {
    //   title: 'Rejected Quantity',
    //   dataIndex: 'rejectedQty',

    //   render: (rowData) => {
    //     return (
    //       <Form.Item name={"rejectedQty"+ rowData.productionLogId}
    //         rules={[
    //           {
    //             required: true, message: 'O/P Boxes Name is required'

    //           },
    //           {
    //             pattern: /^[0-9]*$/,
    //             message: `Should contain only numbers.`
    //           },
    //         ]} >
    //         <InputNumber
    //           min={0}
    //           required={true}
    //           style={{ width: '100%' }}
    //         />
    //       </Form.Item>
    //     )
    //   }
    // },
    // {
    //   title: 'Action',
    //   dataIndex: 'jobStatus',
    //   render: (jobStatus, rowData) => (
    //     <>
    //       {jobStatus == "YES"?<Tag icon={<CheckCircleOutlined />} color="#87d068">Received</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">Not Received</Tag>}
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'Active',
    //       value: true,
    //     },
    //     {
    //       text: 'InActive',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => 
    //   {
    //     // === is not work
    //     return record.isActive === value;
    //   },

    // },
    {
      title: `Job Status`,
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData: WorkStatusDto) => (
        <span>
          {rowData.jobStatus === JobCompletedEnum.YES ?

            <Switch
              className={rowData.jobStatus === JobCompletedEnum.YES ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren="Completed"
              unCheckedChildren="No"
              checked={rowData.jobStatus === JobCompletedEnum.YES ? true : false}
              disabled={rowData.jobStatus === JobCompletedEnum.YES ? true : false}
            />
            :
            <><Popconfirm onConfirm={e => { jobCompletedStatus(rowData); }}
              title={'Are you sure to make it as completed ?'}
            >
              <Switch
                className={'toggle-deactivated'}
                checkedChildren="Completed"
                unCheckedChildren="No"
                checked={false}
                disabled={false} />
            </Popconfirm>
              <Divider type="vertical" />
              <Tooltip placement="top" title={text}>
              <RedoOutlined onClick={() => displayModel('Rejections', rowData)} type='edit' style={{ color: 'red' }}>Rejections</RedoOutlined><Divider type="vertical" /></Tooltip>
              <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  openPrint(rowData.productionLogId, 'DC', rowData.operationType)
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              /></>}

        </span>
      )
    }
    // {
    //   title: 'Job Status',
    //   dataIndex: 'jobStatus',
    //   render: (jobStatus, rowData) => (
    //     <>
    //       {jobStatus == JobCompletedEnum.YES ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{JobCompletedEnum.YES}</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">{JobCompletedEnum.NO}</Tag>}
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: JobCompletedEnum.YES,
    //       value: JobCompletedEnum.YES,
    //     },
    //     {
    //       text: JobCompletedEnum.NO,
    //       value: JobCompletedEnum.NO,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => {
    //     // === is not work
    //     return record.jobStatus === value;
    //   },
    // },
  ];
  const displayModel = (type, data) => {
    setIsModalVisible(true);
    setSelectedWorkStatusDto(data);
    setFormOrPrint('form');
  };

  const openPrint = (logId, printType, operationType) => {
    setSelectedLogId(logId)
    setIsModalVisible(true);
    setPrintType(printType);
    setOperationType(operationType);
    setFormOrPrint('print');
  }

  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNo.localeCompare(b.jobNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNo')
    }, {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber.locateCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber')
    }, {
      title: 'Grade',
      dataIndex: 'grade',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.grade - b.grade,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grade')
    }, {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.boxes - b.boxes,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('boxes')
    },
    {
      title: 'O/P Boxes',
      key: 'sno',
      render: (value, rowData) => {
        return (
          <Form.Item name={"opBoxes" + rowData.productionLogId}
            rules={[
              {
                required: true, message: 'O/P Boxes Name is required'

              },
              {
                pattern: /^[0-9]*$/,
                message: `Should contain only numbers.`
              },
              {
                validator: (_, value) => {
                  if (value <= rowData?.ipBoxes) {
                    rowData.opBoxes = value;
                    Promise.resolve()
                  } else {
                    Promise.reject(new Error('Should not be greater than O/P Boxes'))
                  }
                }
              }]} >
            <InputNumber
              min={0}
              required={true}
              style={{ width: '100%' }}
              defaultValue={Math.ceil((rowData.ipBoxes) * (0.7))}
              onChange={opBoxes}
            />
          </Form.Item>
        )
      }
    },
    {
      title: `Action`,
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData) => (
        <span>
          {rowData.jobStatus === "YES" ?

            <Switch
              className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren="Received"
              unCheckedChildren="Not Received"
              checked={rowData.jobStatus === "YES" ? true : false}
              disabled={rowData.jobStatus === "YES" ? true : false}
            />
            :
            <Popconfirm onConfirm={e => { jobCompletedStatus(rowData); }}
              title={
                rowData.jobStatus === "YES"
                  ? 'Are you sure to make it as not received ?'
                  : 'Are you sure to make it as received ?'
              }
            >
              <Switch
                className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren="Received"
                unCheckedChildren="Not Received"
                checked={rowData.jobStatus === "YES" ? true : false}
                disabled={rowData.jobStatus === "YES" ? true : false}
              />

            </Popconfirm>}
        </span>
      )
    }
  ];


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
* get form data 
* @param fromDoc 
* @param toDoc 
*/
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  /**
* print modal
*/
  const printOrder = () => {
    console.log("****************************");
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };


  return (
    <Card title={<span style={{ color: 'white' }}>Operations History</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <br></br>
      {/* <Row gutter={40} >
      <Col>
          <Card title={'Total Departments: ' + departmentsData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + departmentsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + departmentsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row> */}
      {/* <br></br> */}

      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane
          key="1"
          tab={<span style={{ color: "blue" }}>Deheading: {workStatusData.filter(res => res.operationType === OperationTypeEnum.BE_HEADING && res.jobStatus === JobCompletedEnum.NO).length}</span>}
        >
          <Table
            rowKey={record => record.captureWorkStatusId}
            columns={columnsSkelton1}
            dataSource={workStatusData.filter(res => res.operationType === OperationTypeEnum.BE_HEADING && res.jobStatus === JobCompletedEnum.NO)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "green" }}>Value Addition: {workStatusData.filter(res => res.operationType === OperationTypeEnum.VALUE_ADDITION && res.jobStatus === JobCompletedEnum.NO).length}</span>}
        >
          <Table
            rowKey={record => record.captureWorkStatusId}
            columns={columnsSkelton1}
            dataSource={workStatusData.filter(res => res.operationType === OperationTypeEnum.VALUE_ADDITION && res.jobStatus === JobCompletedEnum.NO)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />
        </TabPane>


      </Tabs>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(formOrPrint == 'form') ? <ReportingSubPlantForm subPlantData={workStatusDto} jobCompletedStatus={jobCompletedStatus} /> : <SubPlantDcPrint logId={selectedLogId} printOrder={printOrder} operationType={operationType} />}
      </Modal>
    </Card>
  );
}
export default ReportSubPlantOperations;