import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select,DatePicker, Typography } from 'antd';
import {DepartmentDto, HrmsEmployeeDetailsDto, ItemCategoriesDto, ItemsDropDownDto, ItemsDto, ProductDto} from '@gtpl/shared-models/masters';
// import {DepartmentService, ItemCategoryService, ItemsService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderDropDownDto} from '@gtpl/shared-models/sale-management';
// import {saleOrder, SaleOrderService} from '@gtpl/shared-services/sale-management';
import { StockService } from '@gtpl/shared-services/procurement';
import {CSVLink} from 'react-csv';
// import { Link, Redirect } from 'react-router-dom';
// import './stock-report.css';
import { StockDto } from '@gtpl/shared-models/procurement-management';
import {GrnService} from '@gtpl/shared-services/procurement';
import { GrnData } from '@gtpl/shared-models/raw-material-procurement';
import {grnAbstract} from '@gtpl/shared-models/raw-material-procurement';
import {RMGrnService} from '@gtpl/shared-services/raw-material-procurement'
import { ProductService } from '@gtpl/shared-services/masters';
import { HrmsEmpDetService, RolesService } from '@gtpl/shared-services/masters';
const { Option } = Select;

/* eslint-disable-next-line */
export interface GrnAbstractReportGridProps {}

export function GrnAbstractReportGrid(
  props: GrnAbstractReportGridProps
) {
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [stockData, setStockData] = useState<StockDto[]>([]);
  const [SODataForDropDown, setSODataForDropDown] = useState<StockDto[]>([]);
  const [itemCategoryForDropDown, setItemCategoryForDropDown] = useState<StockDto[]>([]);
  const [itemNameForDropDown, setItemNameForDropDown] = useState<StockDto[]>([]);
  const [display,setDisplay] = useState<string>('none');

  const [grnAbstractData, setAbstractGrnData] = useState<GrnData[]>([])
  const [selectedProduct, setSelectedProduct] = useState<string>(null)
  const [productsData, setProductsData] = useState<ProductDto[]>([])
  const [selectedGrade,setSelectedGrade] = useState<string>(null)
  const [selectedFood, setSelectedFood] = useState<string>(null)
  const productService = new ProductService();
  const { Text } = Typography;
  const [employeesData, setEmployeesData]= useState<HrmsEmployeeDetailsDto[]>([]);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const [filtersData, setFiltersData] =useState<HrmsEmployeeDetailsDto[]>([]);
  // const [itemCategoryData, setItemCategoryData] = useState<ItemCategoriesDto[]>([]);
  // const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  // const [items, setItems] = useState<ItemsDto[]>([]);
  // const itemCategoryService = new ItemCategoryService();
  // const service = new SaleOrderService;
  // const itemService = new ItemsService;

  const service = new RMGrnService;
  const hrmsEmpDetService = new HrmsEmpDetService();

  useEffect(() => {
    getGRNAbstract();
    getAllProducts();
    getAllActiveEmployeesForLeaves();
  }, []);

  const getVarcodeDefaultValue=(defaultCode:string)=>{
    // if(code==''){
      form.setFieldsValue({
        variantCode: defaultCode,
      });
    // }
  }
  
  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }

    const getGRNAbstract = (req?) => {
      setAbstractGrnData([]);
      service.getGRNAbstract(req).then(res => {
        console.log(res.data);
        if (res.status) {
          console.log(res.data);
          setAbstractGrnData(res.data);
        } else {
          setAbstractGrnData([]);
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setAbstractGrnData([]);
        AlertMessages.getErrorMessage(err.message);
  
      })
    }
  // const handleSaleOrder=(value, item)=>{
  //   const newStockData = stockData.filter(stock =>
  //     stock.saleOrderId == value
  //   )
  //   setStockData(newStockData);
  //   setSODataForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.saleOrderId))));
  //   setItemCategoryForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemCategoryId))));
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  // }
  // const handleItemCategory=(value, item)=>{
  //   const newStockData = stockData.filter(stock =>
  //     stock.itemCategoryId == value
  //   )
  //   setStockData(newStockData);
  //   setItemCategoryForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemCategoryId))));
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
     
  // }
  // const handleItemName=(value,item)=>{
  //   const newStockData = stockData.filter(stock =>
  //     stock.itemId == value
  //   )
  //   setStockData(newStockData);
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  // }

  const getAllProducts = () => {
    productService.getAllActiveProducts().then(res => {
      if (res.status) {
        setProductsData(res.data);
      } else {
        if (res.intlCode) {
          setProductsData([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProductsData([]);
    })
  }
  
  // const handleProduct = (value) => {
  //   const selectedProduct = productsData.find(product => product.productId.toString() == value.toString());
  //   getVarcodeDefaultValue(`${value?selectedProduct.productCode:''}`);
  //   setSelectedProduct(value?selectedProduct.productCode:'')
    
  //  }
  const getAllActiveEmployeesForLeaves = () => {
    hrmsEmpDetService.getAllActiveEmployeesForLeaves().then(res => {
      if (res.status) {
        setEmployeeData(res.data);
        setFiltersData(res.data);
      } else {
        if (res.intlCode) {
          setEmployeeData(res.data);
          setFiltersData(res.data);
          console.log(res.data)
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setEmployeeData([]);
      setFiltersData([]);
      // AlertMessages.getErrorMessage(err.message);
    })
  }
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleEmployeeName=(value) =>{
    console.log(value)
    setEmployeesData(value)
  }


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    // const closeDrawer=()=>{
    //   setDrawerVisible(false);
    // }
  
    //TO open the form for updation
    // const openFormWithData=(viewData: DepartmentDto)=>{
    //   setDrawerVisible(true);
    //   setSelectedDepartmentData(viewData);
    //   console.log(selectedDepartmentData)
    //   console.log('selectedDepartmentData')
    // }


  const columnsSkelton:  ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Plant',

      dataIndex: 'unit_name',
      // responsive: ['lg'],
      // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unit_name')
    },
    {
      title: '0-25',
      dataIndex: 'count25',
      // responsive: ['lg'],
      // sorter: (a, b) => a.itemCategory.localeCompare(b.itemCategory),
      sortDirections: ['descend', 'ascend'],
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
      // ...getColumnSearchProps('itemCategory')

    {
      title: '30',
      dataIndex: 'count30',
      // responsive: ['lg'],
      // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('itemName')
    },
    {
      title: '40',
      dataIndex: 'count40',
      // responsive: ['lg'],
      // sorter: (a, b) => a.sizeName.localeCompare(b.sizeName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('sizeName')
    },
    {
      title: '50',
      dataIndex: 'count50',
      // responsive: ['lg'],
      // sorter: (a, b) => a.physicalQuantity.localeCompare(b.physicalQuantity),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('physicalQuantity')
    },
    {
      title: '60',
      dataIndex: 'count60',
      // responsive: ['lg'],
      // sorter: (a, b) => a.openingBalance.localeCompare(b.openingBalance),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('openingBalance')
    },
    {
      title: '70',
      dataIndex: 'count70',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '80',
      dataIndex: 'count80',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '90',
      dataIndex: 'count90',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '100',
      dataIndex: 'count100',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '110',
      dataIndex: 'count110',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '120',
      dataIndex: 'count120',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '130',
      dataIndex: 'count130',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: '>130',
      dataIndex: 'count140',
      // responsive: ['lg'],
      // sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('minimumStock')
    },
    {
      title: 'Total',
     
      sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('minimumStock'),
      render: (text, record) => {
        let total = 0;
        for (const [key, value] of Object.entries(record)) {
          if (key.includes('count')) {
            total += Number(value);
          }
        }
        return total;
      }
    },
   
   
   
   
   
   
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=( filters, sorter, extra)=> {
    console.log('params', filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   


  }
  return (
    <Card title={<span style={{color:'white'}}>GRN Report</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}
    
    >
     <br></br>
    
      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>
        
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="grnFromDate" label="GRN From Date" initialValue={0}>
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                  showToday={true} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="grnToDate" label="GRN To Date" initialValue={0}>
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                  showToday={true} />
              </Form.Item>
              </Col>
              <Col>
      <Form.Item
        name="productId"
        label="Product:"
        rules={[
          {
            required: false,
            message: "Product is required"
          },
        ]}
      >
        <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select Product"
                optionFilterProp="children"
                // onChange={handleProduct}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>Select Product</Option>
                {productsData.map((product)=>{
                  return <Option value={product.productId}>{product.productName}</Option>
                })}
              </Select>
      </Form.Item></Col >
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name="employeeName"
              label="Grader"
              rules={[
                {
                  required: false, message: 'Select Employee',
                },
              ]}
            >
              <Select
                placeholder="Select grader"
                onChange={handleEmployeeName}
                allowClear
                style={{ width: '100%' }}
                showSearch
              >
                {employeeData.map(dropData => {
                  return <Option value={dropData.employeeName}>{dropData.employeeName}</Option>;
                })}
              </Select>

            </Form.Item>
          </Col>

          
              <Col style={{marginTop:'30px'}}>
                <Button type ='primary' style={{marginRight:'4px'}} onClick={()=>setDisplay('block')}>Get Report</Button>
                <Button type="primary" ><CSVLink filename={"GrnAbstractReport.csv"} data={grnAbstractData} >Get Excel </CSVLink></Button>
               </Col>
        </Row>
        
        
        <br/><br/>
      </Form>
          <Table
          rowKey={record => record.unit_name}
          columns={columnsSkelton}
          
          dataSource={grnAbstractData}
          style={{display:display}}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered 
          summary={(pageData) => {
            let count25 = 1172;
           let count30 = 1295
           let count40 = 1908
           let count50=   4500
           let count90 = 30
           let count60=0
          let count70 =1100
            pageData.forEach(({ count25,count30 ,count40,count50,count60 ,count70}) => {
              count25 += Number(count25);
              count30 += (Number(count30) )
              count40 += (Number(count40))
              count50 += (Number(count50))
              count60 += (Number(count60))
              count70 += (Number(count70))
              count90 +=(Number(count90))
            });
    
            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                <Table.Summary.Cell index={14} colSpan={1}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14} colSpan={2} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count25}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count30}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count40}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count90}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count70}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}><Text  style={{textAlign:'end'}}>{count60}</Text></Table.Summary.Cell>


                </Table.Summary.Row>
              </>
            );
          }
          } />
     </Card>
  );
}

export default GrnAbstractReportGrid;