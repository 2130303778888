import {   GradeSuggestionResponse,AllGradeSuggestionResponse,GradingSuggestionDto,GradeSuggestionRequest, GradeSequenceModel } from "@gtpl/shared-models/masters";
import connection from "./connection";
import axios from 'axios';
import { CommonResponse } from "@gtpl/shared-models/production-management";

export class GradeSuggestionService{ 
  URL = connection.DEPLOY_URL + '/grade-suggestion';

  async createGradeSuggestion(dto: GradingSuggestionDto): Promise<GradeSuggestionResponse> {
    return await axios.post(this.URL + '/createGradeSuggestion',dto)
        .then(res => {
            return res.data
        }) 
}
async updateGradeSuggestion(req: GradeSequenceModel[]): Promise<CommonResponse> {
    return await axios.post(this.URL + '/updateGradeSuggestion',req)
        .then(res => {
            return res.data
        }) 
}
async getAllGradeSuggestions(): Promise<AllGradeSuggestionResponse> {
    return await axios.post(this.URL + '/getAllGradeSuggestions')
        .then(res => {
            return res.data
        }) 
}
async getAllActiveGradeSuggestions(): Promise<AllGradeSuggestionResponse> {
    return await axios.post(this.URL + '/getAllActiveGradeSuggestions')
        .then(res => {
            return res.data
        }) 
}
async  activateDeactivateGradeSuggestion(dto: GradingSuggestionDto): Promise<GradeSuggestionResponse> {
    return await axios.post(this.URL + '/activateOrDeactivateGradeSuggestion', dto)
            .then(res => {
                return res.data
            })
}

async getGradeSuggestionDataById(GradeSuggestionRequest:GradeSuggestionRequest): Promise<GradeSuggestionResponse> {
    return await axios.post(this.URL + '/getGradeSuggestionDataById', GradeSuggestionRequest).then(res => {
      // console.log(res);
        return res.data
    });
  }

 

}