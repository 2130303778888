import { GlobalResponseObject } from "../global-response-object";
import { SoTopFiveDetails } from "./so-top-five-details";


export class SoTopFiveResponse extends GlobalResponseObject {
    data?: SoTopFiveDetails[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoTopFiveDetails[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}