import axios from 'axios';
import {PalletDto, AllPalletsResponseModel, PalletsResponseModel, CreatePalletsResponseModel, PalletResponse, PalletRequest, CommonResponse, PlantPalletRequest} from '@gtpl/shared-models/masters';
import connection from './connection';
import { PlantIdRequest } from '@gtpl/shared-models/warehouse-management';

export class PalletService{

   URL = connection.DEPLOY_URL + '/pallets';
    
   async createPallet(palletDto: PalletDto): Promise<CreatePalletsResponseModel>{
       return await axios.post(this.URL+ '/createPallet', palletDto)
           .then(res =>{
               return res.data
           })
   }
   async updatePallet(palletDto: PalletDto): Promise<CreatePalletsResponseModel>{
    return await axios.post(this.URL+ '/updatePallet', palletDto)
        .then(res =>{
            return res.data
        })
   }
   async getAllPallets(req:PlantIdRequest): Promise<AllPalletsResponseModel>{
    console.log(URL);
    return await axios.post(this.URL+ '/getAllPallets',req)
        .then(res =>{
            return res.data
        })
   }
   async ActivateorDeactivatePallet(palletDto: PalletDto): Promise<PalletResponse>{
    return await axios.post(this.URL+ '/activateorDeactivatePallet', palletDto)
        .then(res =>{
            return res.data
        })
   }
   async getPalletById(palletRequest: PalletRequest): Promise<PalletsResponseModel>{
    return await axios.post(this.URL+ '/getPalletById', palletRequest)
        .then(res =>{
            return res.data
        })
   }
   async getPalletByPalletNo(palletRequest: PlantPalletRequest): Promise<CommonResponse> {
    return await axios.post(this.URL+ '/getPalletByPalletNo', palletRequest)
        .then(res =>{
            return res.data
        })
   }

}