import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import './sub-plant-mrn-print.css';
import { LogIdRequest, LogInfoData, ReceiveSubplantStockDto } from '@gtpl/shared-models/production-management'
import { ProdlogService } from '@gtpl/shared-services/production'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FactoriesInput } from '@gtpl/shared-models/sale-management'
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
/* eslint-disable-next-line */
export interface SubPlantMrnPrintProps {
  logId: number;
  printOrder: () => void
  operationType: OperationTypeEnum;
  data?:ReceiveSubplantStockDto;
}

export function SubPlantMrnPrint(
  props: SubPlantMrnPrintProps
) {

  useEffect(() => {
    getData(props.logId);
  }, [])

  const [logInfo, setLogInfo] = useState<LogInfoData>(undefined);
  const logService = new ProdlogService();


  const getData = (logId) => {
    const reqObj = new LogIdRequest(logId);
    logService
      .getLogDetailsForId(reqObj)
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          setLogInfo(res.data);
          // console.log(PoDataModel?.poItems[0])
        } else {
          if (res.intlCode) {
            setLogInfo(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setLogInfo(undefined);
      });
  };



  if (logInfo) {
    return (
      <html>
        <head></head>
        <body id='printme'>
          <h1 style={{ textAlign: 'center', fontSize: '28px', marginTop: '12px' }}>SANDHYA AQUA</h1>
          <h1 style={{ textAlign: 'center', fontSize: '20px', marginTop: '4px' }}>
            {props.operationType} - MRN
          </h1>
          <br />
          <table className={'ta-b no-bt-bb'} style={{ width: '100%' }}>
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'} colSpan={2}>
                <h4>From Plant: </h4>
                {/* <b>{FactoriesInput.map((addfr) => {
                  return (logInfo.plantId == addfr.id) ? addfr.value : ''
                })}</b> */}
                <b>{props.data?.fromPlant}</b>
              </td>
              <td className={'no-bt-bb'} style={{ padding: '15px' }}>
                {' '}
              </td>
              <td className={'ta-b'} colSpan={2}>
                <h4>To Plant: </h4>
                <b>{props.data?.toPlant}</b>
                {/* <b>{logInfo.toPlant}</b> */}
              </td>
            </tr>
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>JOB NUMBER</b>
              </td>
              <td className={'ta-b'}>
                {props.data?.jobNumber}
              </td>
              <td className={'no-bt-bb'} style={{ padding: '15px' }}>
                {' '}
              </td>
              <td className={'ta-b'}>
                <b>LOT NUMBER</b>
              </td>
              <td className={'ta-b'}>{props.data?.lotNumber}</td>
            </tr>
            <br />
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC NUMBER</b>
              </td>
              <td className={'ta-b'}>{props.data?.dcNumber}</td>
              <td className={'no-bt-bb'} style={{ padding: '15px' }}>
                {' '}
              </td>
              <td className={'ta-b'}>
                <b>MRN NUMBER</b>
              </td>
              <td className={'ta-b'}>{props.data?.mrnNumber}</td>
            </tr>
            <br />
            <tr className={'ta-b no-bt-bb'}>
              <td className={'ta-b'}>
                <b>DC DATE</b>
              </td>
              <td className={'ta-b'}>{moment(props.data?.date).format("YYYY/MM/DD")}</td>
              <td className={'no-bt-bb'} style={{ padding: '30px' }}>
                {' '}
              </td>
              <td className={'ta-b'}>
                <b>RECEIVED DATE</b>
              </td>
              <td className={'ta-b'}>{moment(props.data?.mrnDate).format("YYYY/MM/DD")}</td>
            </tr>
          </table>
          <br />
          <br />
          <table className={'ta-b'} style={{ width: '100%' }}>
            <tr className={'ta-b'}>
              <th className={'ta-b'}>S.No</th>
              <th className={'ta-b'}>ITEM NAME</th>
              <th className={'ta-b'}>I/P BOXES</th>
              <th className={'ta-b'}>O/P BOXES</th>
              <th className={'ta-b'}>QUANTITY</th>
            </tr>
            <tr>
              <td className={'ta-b'} style={{ textAlign: 'center' }}>1</td>
              <td className={'ta-b'}>{logInfo.itemName}</td>
              <td className={'ta-b'} style={{ textAlign: 'right' }}>{logInfo.ipBoxes}</td>
              <td className={'ta-b'} style={{ textAlign: 'right' }}>{logInfo.opBoxes}</td>
              <td className={'ta-b'} style={{ textAlign: 'right' }}>{logInfo.ipQuantity}</td>
            </tr>
          </table>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>{logInfo.reportedBy}</p>
              <h4>ISSUED BY</h4>
            </div>
            <div style={{ padding: '10px' }}>
              <br />
              <h4>RECEIVED BY</h4>
            </div>
          </div>
          <Button onClick={props.printOrder} className='noprint' style={{ display: 'block', margin: 'auto', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}>Print</span></Button>
        </body>
      </html>
    );
  }
  else {
    return (<Empty />);
  }
}

export default SubPlantMrnPrint;
