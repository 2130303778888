import { GlobalStatus, OperationTypeEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";

export class ValueAdditionDto {
    productionInventoryId?: number;
    lotNumber: string;
    subLotNumber: string;
    saleOrderId: number;
    saleOrderItemId?: number;
    operation: OperationTypeEnum;
    nextOperation: OperationTypeEnum;
    physicalQuantity: number;
    issuedQuantity: number;
    intransitQuantity?: number;
    wipQuantity?: number;
    wastageQuantity?: number;
    rejectedQuantity?: number;
    inputGrade: number;
    outputGrade: number;
    shift: ShiftsEnum;
    workStationType: WorkStationTypeEnum;
    workstation: number;
    weighmentReq: GlobalStatus;
    subPlantId?: SubPlantCodesEnum;
    ipBoxes?: number;
    opBoxes?: number;
    createdUser: string;
    plantId: number;
    updatedUser: string;
    plantType: string;
    productionInvId:number;

    /**
    @param productionInventoryId
    @param lotNumber
    @param subLotNumber
    @param saleOrderId
    @param saleOrderItemId
    @param operation
    @param nextOperation
    @param physicalQuantity
    @param issuedQuantity
    @param intransitQuantity
    @param wipQuantity
    @param wastageQuantity
    @param rejectedQuantity
    @param inputGrade
    @param outputGrade
    @param shift
    @param workStationType
    @param workstation
    @param weighmentReq
    @param subPlantId
    @param PlantId
    @param createdUser
    @param updatedUser
     */

    constructor(lotNumber: string, subLotNumber: string, saleOrderId: number, operation: OperationTypeEnum, nextOperation: OperationTypeEnum, physicalQuantity: number, issuedQuantity: number, inputGrade: number, outputGrade: number, shift: ShiftsEnum, workStationType: WorkStationTypeEnum, workstation: number, weighmentReq: GlobalStatus, createdUser: string,plantId:number, updatedUser: string, productionInventoryId?: number, saleOrderItemId?: number, wipQuantity?: number, wastageQuantity?: number, rejectedQuantity?: number, intransitQuantity?: number, subPlantId?: SubPlantCodesEnum,ipBoxes?:number,opBoxes?:number,plantType?:string,productionInvId?:number) {

        this.productionInventoryId = productionInventoryId;
        this.lotNumber = lotNumber;
        this.subLotNumber = subLotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.operation = operation;
        this.nextOperation = nextOperation;
        this.physicalQuantity = physicalQuantity;
        this.issuedQuantity = issuedQuantity;
        this.intransitQuantity = intransitQuantity;
        this.wipQuantity = wipQuantity;
        this.wastageQuantity = wastageQuantity;
        this.rejectedQuantity = rejectedQuantity;
        this.inputGrade = inputGrade;
        this.outputGrade = outputGrade;
        this.shift = shift;
        this.workStationType = workStationType;
        this.workstation = workstation;
        this.weighmentReq = weighmentReq;
        this.subPlantId = subPlantId;
        this.plantId = plantId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.ipBoxes = ipBoxes;
        this.opBoxes = opBoxes;
        this.plantType = plantType;
        this.productionInvId = productionInvId;
    }

}