export class MaterialPreparationReq{
    unitId?:number;
    saleOrderId?:number;
    itemId?:number;
    status?:string[];
    constructor(unitId?:number,saleOrderId?:number,itemId?:number,status?:string[]){
        this.unitId = unitId;
        this.saleOrderId - saleOrderId;
        this.itemId = itemId;
        this.status = status;
    }
}