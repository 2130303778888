import axios from 'axios';
import {IdProofsTypeDto,IdProofsTypeResponseModel,AllIdProofTypesResponseModel} from '@gtpl/shared-models/masters';
import connection from './connection';

export class IdproofsTypeService {
URL = connection.DEPLOY_URL + '/id-proofs';

  async createIdProof(idProof: IdProofsTypeDto): Promise<IdProofsTypeResponseModel> {
    console.log(idProof);
      return await axios.post(this.URL + '/createIdProof',idProof)
          .then(res => {
              return res.data
          })
  }
  async  updateIdProof(idProof: IdProofsTypeDto): Promise<IdProofsTypeResponseModel> {
    return await axios.post(this.URL + '/updateIdProof', idProof)
           .then(res => {
               return res.data
           })
}
  async getAllIdProofs(): Promise<AllIdProofTypesResponseModel> {
    return await axios.post(this.URL + '/getAllIdProofs')
        .then(res => {
            return res.data
        })     
}
async  activateorDeactivateIdProof(idProof: IdProofsTypeDto): Promise<AllIdProofTypesResponseModel> {
      console.log(idProof);
      return await axios.post(this.URL + '/activateorDeactivateIdProof', idProof)
              .then(res => {
                  return res.data
              })
 }

} 