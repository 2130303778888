
import {FgStockTransferLocationDto} from "./fg-location.dto";
export class FgStockTransferDto {
    transferType:string;
    fromPlant:string;
    fromLocation:string;
    locationDto: FgStockTransferLocationDto[];
    toLocation:string;
    toPlant:string;
    fromColdStorage:string;
    toColdStorage:string;
    fromSoId:number;
    toSoId:number;

    constructor(transferType:string,fromPlant:string,fromLocation:string, toLocation:string,
        toPlant:string,    fromColdStorage:string,toColdStorage:string,fromSoId:number,toSoId:number){

            this.transferType = transferType;
            this.fromPlant = fromPlant;
            this.toLocation = toLocation;
            this.toPlant = toPlant;
            this.fromLocation = fromLocation;
            this.fromColdStorage = fromColdStorage;
            this.toColdStorage = toColdStorage;
            this.fromSoId = fromSoId;
            this.toSoId=toSoId;
        }
}