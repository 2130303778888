import { EmployeeDataModel } from "./employee-data.model";


export class DeptDataModel {
    departmentId: number;
    departmentName: string;
    totalCount: number;
    presentCount: number;

    /**
     * 
     * @param departmentId
     * @param departmentName
     * @param totalCount 
     * @param presentCount 
     */
    constructor(departmentId: number, departmentName: string, totalCount: number, presentCount: number) {
        this.departmentId = departmentId;
        this.departmentName = departmentName;
        this.totalCount = totalCount;
        this.presentCount = presentCount;

    }
}