import React, { useEffect, useState } from 'react';
import { Form, Button, Select, Card, Row, Col, InputNumber, Input } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { BrandReqForLocation, Brands, ItemForBrandReq, ItemsDropDownDto, ItemSubCategoryDropDownDto, MasterBrandsDropDownDto, PlantsDropDown, SubCatReqForCategoryId } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService, MasterBrandService, UnitcodeService } from '@gtpl/shared-services/masters';
import { StockService } from '@gtpl/shared-services/procurement';
import { CycleCheckerDto, ItemIdReq, StockDto } from '@gtpl/shared-models/procurement-management';
import CycleCheckerTable from './cycle-checker-table';
const { Option } = Select;

export interface CycleCheckerProps {
    isUpdate: boolean;
    closeForm: () => void;
}

export function CycleChecker(
    props: CycleCheckerProps
) {
    const [form] = Form.useForm();
    const brandsService = new MasterBrandService()
    const [brandData, setBrandData] = useState<MasterBrandsDropDownDto[]>([]);
    const subCategoryService = new ItemSubCategoryService();
    const [itemSubCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
    const [itemData, setItemData] = useState<ItemsDropDownDto[]>([]);
    const itemService = new ItemsService();
    const stockService = new StockService;
    const [disable, setDisable] = useState<boolean>(false)
    const [selectedunit, setUnit] = useState<number>(0)
    const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
    const unitsService = new UnitcodeService();
    const [stockQty,setStockQty] = useState<any[]>([]);
    const [reasons, setReasons] = useState<any[]>([]);
    const [locations , setLocations] = useState<any[]>([])

    useEffect(() => {
        getAllMasterBrandsDropDown();
        getAllItemSubCategories();
        getAllPlants();
        getReasonsForCycleChecker();
       // getLocationsForCycleChecker();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
          }
          if(Number(localStorage.getItem('unit_id')) ===5){
            setDisable(true);
            AlertMessages.getInfoMessage('Superadmin is restricted to do stock entry')
          }
    }, [])

    const getAllMasterBrandsDropDown = () => {
        brandsService.getAllMasterBrandsDropDown().then((res) => {
            if (res.status) {
                setBrandData(res.data);
            } else {
                setBrandData(undefined);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setBrandData(undefined);
        });
    }

    const getAllItemSubCategories = () => {
        form.setFieldsValue({ itemSubCategoryId: undefined, itemId: undefined })
        const itemCategoryId = 6;
        const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
        subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
            if (res.status) {
                setSubCategoryData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getItemData = () => {
        form.setFieldsValue({ itemId: undefined, physicalQunatity: undefined })
        const brandId = form.getFieldValue('masterBrandId')
        const itemSubCategoryId = form.getFieldValue('itemSubCategoryId')
        const req = new ItemForBrandReq()
        req.brandId = brandId;
        req.itemSubCategoryId = itemSubCategoryId
        itemService.getItemForBrandAndSubCategory(req).then(res => {
            if (res.status) {
                setItemData(res.data)
            } else {
                setItemData([])
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const getLocationsForCycleChecker = () =>{
        const brandId = form.getFieldValue('masterBrandId')
        const unitId = Number(localStorage.getItem('unit_id'))
        const req = new BrandReqForLocation()
        req.brandId = brandId;
        req.unitId = unitId;
        stockService.getLocationsForCycleChecker(req).then((res)=>{
            if(res.data){
                setLocations(res.data)
            }else{
                AlertMessages.getInfoMessage(res.internalMessage)
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setLocations([]);
        })
    }
    const getAllPlants = () => {
        unitsService.getAllMainPlants().then((res) => {
            if (res.status) {
                setPlantData(res.data);
            } else {
                setPlantData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setPlantData([]);
        })
    }
    
    const getReasonsForCycleChecker = () =>{
        stockService.getReasonsForCycleChecker().then((res)=>{
            if(res.data){
                setReasons(res.data)
            }else{
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setReasons([]);
        })
    }
   
    const getStockQtyForCycleChecker =()=>{
        const itemId = form.getFieldValue('itemId')
        const unitId = Number(localStorage.getItem('unit_id'))
        const locationId = form.getFieldValue('locationId')
        const req = new ItemIdReq()
        req.itemId = itemId;
        req.unitId = unitId;
        req.locationId = locationId;
        stockService.getStockQtyForCycleChecker(req).then((res)=>{
            if (res.data){
                setStockQty(res.data)
                form.setFieldsValue({physicalQuantity:res.data[0].physicalQty})
                form.setFieldsValue({previousQuantity:res.data[0].physicalQty})
                // if(res.data == 0){
                //     setDisable(true);
                //     AlertMessages.getInfoMessage('Cycle Checker is restricted to do stock entry')
                // }
            }else{
                AlertMessages.getInfoMessage(res.internalMessage)
            }
        });
    }
    const onReset = () => {
        console.log('');
        form.resetFields();

    }
    const onFocus = () => {
        //console.log('focus');
    }

    const onSearch = (val) => {
        //console.log('search:', val);
    }
    const onBlur = () => {
        //console.log('blur');
    }

    const handleUnit = (value) => {
        setUnit(value)
    }

    const saveStock = (values: CycleCheckerDto) => {
        setDisable(true)
        const unitId = Number(localStorage.getItem('unit_id'))
       values.unitId = unitId;
        stockService.saveCycleCheckerEntry(values).then((res) => {
            setDisable(false)
            if (res.status) {
                AlertMessages.getSuccessMessage('Stock saved sucessfully')
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch((err) => {
            setDisable(false)
            AlertMessages.getErrorMessage(err.message)
        });
        form.setFieldsValue({
            itemId: undefined,
            physicalQuantity: undefined
        })
    }
    return (
        <>
        
        <Card title={<span style={{ color: 'white' }}>Cycle Checker</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
            <Form form={form} layout='vertical' name='control-hooks' onFinish={saveStock}>
                <Row gutter={24}>
                    <Col style={{display:'none'}} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="unitId"
                            label=" Unit"
                            rules={[
                                {
                                    required: false, message: 'Select Unit',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Unit"
                                allowClear
                                style={{ width: '100%' }}
                                onChange={handleUnit}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                
                            >
                                {plantData.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
                        <Form.Item
                            label="Brand"
                            name={'masterBrandId'}
                            rules={[{ required: false, message: 'Missing Brand name' }]}
                        >
                            <Select
                                placeholder="Select Brand"
                                allowClear
                                showSearch
                                onChange={getAllItemSubCategories}
                                onFocus={onFocus}
                                onSearch={onSearch}
                                onSelect={getLocationsForCycleChecker}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option value={undefined}>please select Brand</Option>;
                                {brandData?.map(item => {
                                    return <Option value={item.masterBrandId}>{item.masterBrandName}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name='locationId' label='Location'>
                        <Select
                                showSearch
                                // style={{ width: 240 }}
                                placeholder="Select"
                                optionFilterProp="children"
                                onBlur={onBlur}
                                onSearch={onSearch}
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={0}>Select Rack Position</Option>
                                {locations.map((locationDrop) => {
                                    return <Option key={locationDrop.locationId} value={locationDrop.locationId}>{locationDrop.rackPosition}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="itemSubCategoryId"
                            label="Item Sub Category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Item Sub Category is required'
                                },
                                {
                                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                                    message: `Enter valid Item Sub Category `,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                // style={{ width: 240 }}
                                placeholder="Select Category"
                                optionFilterProp="children"
                                onSelect={getItemData}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={null}>Select Sub Category</Option>
                                {itemSubCategoryData.map((subCategory) => {
                                    return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col><br />
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                        <Form.Item
                            name="itemId"
                            label="Item Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Item Name is required',
                                },

                            ]}>
                            <Select
                                showSearch
                                // style={{ width: 240 }}
                                placeholder="Select Item"
                                optionFilterProp="children"
                                onSelect={getStockQtyForCycleChecker}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={0}>Select Item</Option>
                                {itemData.map((item) => {
                                    return <Option key={item.itemId} value={item.itemId}>{item.itemName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} >
                        <Form.Item
                            name="physicalQuantity"
                            label="Quantity"
                            rules={[
                                {
                                    required: true,
                                    message: 'Minimum Quantity is required',
                                    
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={3}>
                        <Form.Item name='location' label='Location'>
                            <Input/>
                        </Form.Item>
                    </Col> */}
                    <Col span={3}>
                        <Form.Item name='reason' label='Reason'>
                        <Select
                                showSearch
                                // style={{ width: 240 }}
                                placeholder="Select Item"
                                optionFilterProp="children"
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={0}>Select Reason</Option>
                                {reasons.map((reasonDrop) => {
                                    return <Option key={reasonDrop.reason} value={reasonDrop.reason}>{reasonDrop.reason}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{display:'none'}}>
                        <Form.Item name='previousQuantity' label='Previous qty'>
                            <Input />
                        </Form.Item>
                    </Col>
                {/* </Row>
                <Row justify='end'> */}
                    <Col span={4} style={{ textAlign: 'right', paddingTop: '30px' }}>
                        <Button type="primary" disabled={disable} htmlType="submit" >
                            Submit
                        </Button>
                        <Button htmlType="button" style={{ margin: '0 14px' , marginLeft:'1px' }} onClick={onReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Card>
            <CycleCheckerTable/>
        </Card>
        </>
    );
}

export default CycleChecker;