import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class LotNumberRequest{
    plant:number;
    operation:OperationTypeEnum;
    /**
     * @param plant
     * @param operation
     */
    constructor(plant:number,operation:OperationTypeEnum){
        this.plant = plant;
        this.operation = operation;

    }
}