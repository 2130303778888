import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { AssetMaintainanceDto } from "libs/shared-models/asset-management/src/lib/asset-maintainance";
import connection from "./connection";
import {AssetById} from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-id.dto';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto";
import { AssetByDate } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-date.dto";
export class AssetMaintenanceService {
    URL = connection.DEPLOY_URL + '/maintainance';

    async createAssetMaintainance(req: AssetMaintainanceDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetMaintainance',req)
        .then(res => {
            return res.data;
        })
    }

    async updateAssetMaintainance(req: AssetMaintainanceDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateAssetMaintainance',req)
        .then(res => {
            return res.data;
        })
    }

    async getAllMaintainance(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetMaintainance')
        .then(res => {
            return res.data;
        })
    }

    async getAllMaintainanceTrans(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllMaintainanceTrans')
        .then(res => {
            return res.data;
        })
    }

    async getByAssetId(req?: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getByAssetId',req)
        .then(res => {
            return res.data;
        })
    }

    async getTransById(req?: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransById',req)
        .then(res => {
            return res.data;
        })
    }

    async getByDate(req: AssetByDate): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getByDate',req)
        .then(res => {
            return res.data;
        })
    }
    async getAssetCodesDropDownformaintance(req?:AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetCodesDropDownformaintance',req)
        .then(res => {
            return res.data;
        })
    }

    async getAssetCodesDropDownformaintanancetrans(req?:AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetCodesDropDownformaintanancetrans',req)
        .then(res => {
            return res.data;
        })
    }
}