import { PoItemsCreateDetails } from "./po-item-create-details.dto";

export class itemDataDto{

    itemCategory : string;
    itemSize: string;
    itemQuantity: string;
    itemCost:number;
    discountPercentage: number;
    discountAmount: number;
    taxPercentage: number;
    taxAmount:number;
    transportation:string;
    subAmount: number;
    
        constructor(itemCategory : string,itemSize: string,itemQuantity: string,itemCost:number,discountPercentage: number,discountAmount: number,taxPercentage: number,taxAmount:number,transportation:string,subAmount: number,){
       this.itemCategory = itemCategory;
       this.itemSize=itemSize;
       this.itemQuantity=itemQuantity;
       this.itemCost= itemCost; 
       this.discountPercentage = discountPercentage;
       this.discountAmount=discountAmount;
       this.taxPercentage=taxPercentage;
       this.taxAmount=taxAmount;
       this.transportation=transportation;
       this.subAmount=subAmount;           
   
          }
}


export class PODetailViewInfoDTO{
    purchaseOrderId:number;
    saleOrderId: number;
    purchaseOrderDate : Date;
    vendorName: number;
    poNumber:string;
    expectedDeliveryDate:Date;
    
    
    // constructor(purchaseOrderDate : Date,purchaseOrderId:number,vendorName: number,poNumber:string,expectedDeliveryDate:Date){
    //     this.purchaseOrderId=purchaseOrderId    
    //     this.purchaseOrderDate= purchaseOrderDate;
    //     this.vendorName=vendorName;
    //     this.poNumber=poNumber;
    //     this.expectedDeliveryDate=expectedDeliveryDate;
    //      }


}
export class POViewInfoDTO{
    purchaseOrderId: number;
    purchaseOrderDate: any;
    vendorName: number;
    poNumber: string;
    saleOrderId: number;
    totalQuantity?: number;
    totalDiscount: number;
    totalTax: number;
    totalCost: number;
    totalBasicPrice: number;
    paymentTerms: number;
    usageArea: string;
    expectedDeliveryDate: any;
    note: string;
    approvar: string;
    otherTerms: string;
    referenceNumber: number;
    deliveryAddress: string;
  transportation: number;
  remarks: string;
  //   currencyName: string;
    status?: string;
    createdUser : string;
    updatedUser ?: string;
    // poCreationItemsInfo: PoItemsCreateDetails[];
  
     
  //     constructor(purchaseOrderId: number,
  //         poNumber: string,
  //         purchaseOrderDate: any,
  //         vendorName: number,
  //         saleOrderId: number,
  //         totalQuantity: number,
  //         totalDiscount: number,
  //         totalTax: number,
  //         totalCost: number,
  //         totalBasicPrice: number,
  //         paymentTerms: number,
  //         usageArea: string,
  //         expectedDeliveryDate: any,
  //         note: string,
  //         approvar: string,
  //         otherTerms: string,
  //         referenceNumber: number,
  //         deliveryAddress: string,
  //       transportation: number,
  //       remarks: string,
  //         // currencyName: string,
  //         status: string,
  //         createdUser : string,
  //         updatedUser : string,
  //         poCreationItemsInfo: PoItemsCreateDetails[],
  //         )
  //         {
  //         this.purchaseOrderId = purchaseOrderId;
  //   this.purchaseOrderDate = purchaseOrderDate;
  //   this.vendorName = vendorName;
  //   this.poNumber = poNumber;
  //   this.saleOrderId = saleOrderId;
  //   this.totalQuantity = totalQuantity;
  //   this.totalDiscount = totalDiscount;
  //   this.totalTax = totalTax;
  //   this.totalCost = totalCost;
  //   this.totalBasicPrice= totalBasicPrice;
  //   this.paymentTerms=paymentTerms;
  //   this.usageArea=usageArea;
  //   this.deliveryAddress=deliveryAddress;
  //   this.expectedDeliveryDate=expectedDeliveryDate;
  //   this.otherTerms=otherTerms;
  // this.transportation=transportation;
  // this.remarks=remarks;
  // //   this.currencyName=currencyName;
  //   this.note = note;
  //   this.approvar = approvar;
  //   this.status = status;
  //   this.referenceNumber = referenceNumber;
  //   this.createdUser  = createdUser;
  //   this.updatedUser  = updatedUser;
  //   // this.poCreationItemsInfo = poCreationItemsInfo;
         
  //     }
}
export const defaultDto :itemDataDto ={
    itemCategory : "string",
    itemSize: "string",
    itemQuantity: "string",
    itemCost:0,
    discountPercentage: 0,
    discountAmount: 0,
    taxPercentage: 0,
    taxAmount:0,
    transportation:"string",
    subAmount: 0,
}