import axios from 'axios';
import connection from './connection';
import {OperationDto,OperationResponseModel,AllOperationsResponseModel, VarientIdRequest, OperationsDropDownResponse, OperationandLotReq, operationReq, LotAndSaleOrderIdReq} from '@gtpl/shared-models/production-management';
import { CommonResponse, PlantIdRequest } from '@gtpl/shared-models/masters';
export class OperationService{
    URL = connection.DEPLOY_URL + '/operations';

    async createOperation(operationDto: OperationDto):Promise<OperationResponseModel>{
        return await axios.post(this.URL+'/createOperation',operationDto)
            .then(res => {
                return res.data
            })
    }

    async updateOperation(operationDto: OperationDto):Promise<OperationResponseModel>{
        return await axios.post(this.URL+'/updateOperation',operationDto)
           .then(res => {
               return res.data
           })
    }
    async getAllOperations(inputReq?:VarientIdRequest):Promise<AllOperationsResponseModel> {
      
        return await axios.post(this.URL + '/getAllOperations',inputReq)
            .then(res => {
                return res.data
            })     
    }

    async getAllOperationsDropDown():Promise<OperationsDropDownResponse> {
        return await axios.post(this.URL + '/getAllOperationsDropDown')
            .then(res => {
                return res.data
            })     
    }
    
    async activateOrDeactivateOperation(operationDto: OperationDto):Promise<OperationResponseModel>{
        return await axios.post(this.URL+'/activateOrDeactivateOperation',operationDto)
           .then(res => {
               return res.data
           })
    }
    

    async GetAllLotInformationAginstOperationandlot(req?:OperationandLotReq):Promise<CommonResponse>{
        return await axios.post(this.URL+'/GetAllLotInformationAginstOperationandlot',req)
           .then(res => {
               return res.data
           })
    }

    
    async getLotNumbersDropDownForLotClub(req?:operationReq):Promise<CommonResponse>{
        return await axios.post(this.URL+'/getLotNumbersDropDownForLotClub',req)
           .then(res => {
               return res.data
           })
    }
    async getLotNumbersDropDownForRejections(req?:PlantIdRequest):Promise<CommonResponse>{
        return await axios.post(this.URL+'/getLotNumbersDropDownForRejections',req)
           .then(res => {
               return res.data
           })
    }
    async getAllOperationsAgainistlotAndsoId(req:LotAndSaleOrderIdReq):Promise<CommonResponse>{
        return await axios.post(this.URL+'/getAllOperationsAgainistlotAndsoId',req)
           .then(res => {
               return res.data
           })
    }
}