
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { TypesDto } from './types.dto';


export class AllTypesResponse extends GlobalResponseObject {
    data?: TypesDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: TypesDto[]) {
        super(status, intlCode, internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

