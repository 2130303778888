import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AssetsClasificationDropDownDto } from '.';



export class AssetsClassificationDropDownResponse extends GlobalResponseObject {
    data?: AssetsClasificationDropDownDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: AssetsClasificationDropDownDto[]) {
        super(status, intlCode, internalMessage)
        this.data = data;
    }
}

