
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { RackDetails } from "./rack-details";


export class AllRacksResponseModel extends GlobalResponseObject {
    data?: RackDetails[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /RackDetails[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RackDetails[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 