export const ProductCategoryDropDown = [
    {name:'Over Count',value:'overcount'},{name:'Over Count Full Cut',value:'overcountfullcut'},{name:'Over Count Pull veined',value:'overcountpullveined'},{name:'For Weight',value:'forweight'},{name : 'Under Count',value:'undercount'},{name : 'G1',value:'g1'},{name : 'G2',value:'g2'},
    {name : 'Cut',value:'cut'},{name : 'RJ(Rejections)',value:'rj'},{name : 'Damaged',value:'damaged'}
]

export enum ProductCategoryEnum {
    overcount = 'Over Count',
    overcountfullcut = 'Over Count Full Cut',
    overcountpullveined = 'Over Count Pull veined',
    undercount = 'Under Count',
    g1 = 'G1',
    g2 = 'G2',
    cut = 'Cut',
    rj = 'RJ(Rejections)',
    forweight = 'For Weight',
    damaged = 'Damaged',
}
