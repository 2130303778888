import { TransactionType } from "@gtpl/shared-models/common-models";
import { ForkLiftJobStatus } from "./fork-lift-job-status.enum";
export class JobIdRequest {
    jobId: number;
    transactionType: string;

    constructor(jobId:number,transactionType: string){
        this.jobId = jobId;
        this.transactionType = transactionType;
    }
}
export class ForkLiftJobIdRequest {
    jobId: number;
    constructor(jobId:number){
        this.jobId = jobId;
    }
}