export class VariantsDropDownDto{
    variantId : number;
    variantName : string;
    
    /**
     * 
     * @param variantId number
     * @param variantName string
     */
    constructor(variantId : number,variantName : string){
        this.variantId = variantId;
        this.variantName = variantName;
    }
}