import { QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";
import { QcDefectItemDetailsDto } from "./qc-defect-item-details.dto";

export class Qcdefectdto {
    qcId: number;
    lotNumber: string;

    workstationId: number;
    startTime: any;

    inspectedCount: number;
    inspectedQuantity: number;
    passedQuantity:number;

    testResult: string;
    remarks: string;
    isActive: boolean;

    createdAt: Date;
    createdUser: string;

    updatedAt: Date;
    updatedUser: string;

    unitId: number;

    versionFlag: number;
    operationId: number;
    qcDefectItemDetails: QcDefectItemDetailsDto[];

    constructor(qcId: number, operationId: number, lotNumber: string, workstationId: number, startTime: any, remarks: string,
        isActive: boolean, createdAt: Date, createdUser: string, unitId: number,
        inspectedQuantity: number, inspectedCount: number, passedQuantity:number,updatedUser: string,
        testResult: string,
        versionFlag: number,
        qcDefectItemDetails: QcDefectItemDetailsDto[]) {
        this.qcId = qcId;
        this.lotNumber = lotNumber;
        this.workstationId = workstationId;
        this.startTime = startTime;
        // this.testResultId=testResultId;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.operationId = operationId;
        this.inspectedCount = inspectedCount;
        this.inspectedQuantity = inspectedQuantity;
        this.passedQuantity=passedQuantity;
        this.testResult = testResult;
        this.unitId = unitId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.qcDefectItemDetails = qcDefectItemDetails


    }
}
