export class LotNoInfo{
    lotNumber:string;
    saleOrderId:number;
    saleOrderNo:string;
    saleOrderItemId:number;
    saleOrderItem:string;
    count:number;
    availableQuantity:number;
    boxes:number;
    productionInventoryId?:number;
    inputGrade?:number;
    outputGrade?:number;
    boxWeight?:number;
    soakingTime?:string;
    soakingStyle?:string;
    outputBoxes?:number;
    lot?:string;
    mainPlantId?:number;
    poNumber?:string;

    /**
     * 
     * @param lotNumber 
     * @param saleOrderId 
     * @param saleOrderNo 
     * @param saleOrderItemId 
     * @param saleOrderItem 
     * @param count 
     * @param availableQuantity 
     * @param boxes 
     */
    constructor(lotNumber:string, saleOrderId:number, saleOrderNo:string, saleOrderItemId:number, saleOrderItem:string, count:number, availableQuantity:number, boxes:number, productionInventoryId:number, inputGrade?:number, outputGrade?:number, boxWeight?:number,soakingTime?:string,soakingStyle?:string, outputBoxes?:number,lot?:string,mainPlantId?:number,poNumber?:string){
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderNo = saleOrderNo;
        this.saleOrderItemId = saleOrderItemId;
        this.saleOrderItem = saleOrderItem;//product
        this.count = count;
        this.availableQuantity = availableQuantity;
        this.boxes = boxes;
        this.productionInventoryId = productionInventoryId;
        this.inputGrade = inputGrade;
        this.outputGrade = outputGrade;
        this.boxWeight = boxWeight;
        this.soakingTime = soakingTime;
        this.soakingStyle = soakingStyle;
        this.outputBoxes = outputBoxes;
        this.lot = lot;
        this.mainPlantId = mainPlantId;
        this.poNumber = poNumber;
    }

}