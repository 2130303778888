import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AllGrnResponseModel, grnAbstract, GrnData, GrnRequest, GrnResponseModel, LotCodesResponseModel, ReceivedBoxesRequest, RmAuditRequest, RmGrnResponseModel, RMVehicleInfoResponse } from '@gtpl/shared-models/raw-material-procurement';
import { CommonResponse, PlantIdRequest, VehicleResponseModel } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDetailsResponseModel, SaleOrderDropDownResponseModel } from '@gtpl/shared-models/sale-management';
import { GrnAbstractResponse } from "@gtpl/shared-models/procurement-management";

export class RMGrnService {
    URL = connection.DEPLOY_URL + '/rm-grn';
    //     userid = connection.userid;
    //   role=connection.role;
    //   unitId=connection.unit_id;

    async createGrn(values): Promise<RmGrnResponseModel> {
        return await axios.post(this.URL + '/createGrn', values)
            .then(res => {
                return res.data
            })
    }
    async getAllGrnData(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllGrn',req
            // {headers: {"userid" : this.userid,"role":this.role,"unit_id":this.unitId}}
        )
            .then(res => {
                return res.data
            })
    }
    async getGrnDataForId(value): Promise<GrnResponseModel> {
        return await axios.post(this.URL + '/getGrnById', value)
            .then(res => {
                return res.data
            })
    }
    async updateGrn(values): Promise<RmGrnResponseModel> {
        return await axios.post(this.URL + '/updateGrn', values)
            .then(res => {
                return res.data
            })
    }

    async updateReceivedBoxes(receivedBoxes: ReceivedBoxesRequest): Promise<GrnResponseModel> {
        return await axios.post(this.URL + '/updateReceivedBoxes', receivedBoxes)
            .then(res => {
                return res.data
            })
    }

    async assignToRM(values): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assignToRM', values)
            .then(res => {
                return res.data
            })
    }

    async getAllLotCodes(plantIdRequest: PlantIdRequest): Promise<LotCodesResponseModel> {
        return await axios.post(this.URL + '/getAllLotCodes', plantIdRequest)
            .then(res => {
                return res.data
            })
    }
    async getAllSaleOrders(plantIdRequest: PlantIdRequest): Promise<SaleOrderDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllSaleOrders', plantIdRequest)
            .then(res => {
                return res.data
            })
    }

    async getRmVehicleGrnById(grnId): Promise<RMVehicleInfoResponse> {
        return await axios.post(this.URL + '/getRmVehicleGrnById', grnId)
            .then(res => {
                return res.data
            })
    }
    async getGrnInfoByItemId(grnId): Promise<RMVehicleInfoResponse> {
        return await axios.post(this.URL + '/getGrnInfoByItemId', grnId)
            .then(res => {
                return res.data
            })
    }
    async getRmGrnDataById(grnReq: GrnRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRmGrnDataById', grnReq)
            .then(res => {
                return res.data
            })
    }
    async updatePlantOrSubPlant(values): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updatePlantOrSubPlant', values)
            .then(res => {
                return res.data
            })
    }

    async getTraceabilityCodeForLot(lotNumber): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTraceabilityCodeForLot', lotNumber)
            .then(res => {
                return res.data
            })
    }

    async updateGrnAuditQty(req:RmAuditRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateGrnAuditQty', req)
            .then(res => {
                return res.data
            })
    }
    async getGRNAbstract(req :grnAbstract) : Promise<GrnAbstractResponse>{
        return await axios.post(this.URL + '/getGRNAbstract', req)
        .then(res=>{
        return res.data
        })
    }
    
}