import axios from 'axios';
import {AllTypeOfValueAddition4ResponseModel, TypeOfValueAddition4Dto, TypeOfValueAddition4Model, ValAddFourDropDownData, ValAddFourDropDownDataResponseModel, ValueAdditionIdRequest, valueAdditionIdResponse} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class TypeofValueAddition4Service {
URL = connection.DEPLOY_URL + '/value-addition4-types';

        async createTypeOfValueAddition4(product: TypeOfValueAddition4Dto): Promise<TypeOfValueAddition4Model> {
            return await axios.post(this.URL + '/createValAddFourType',product)
                .then(res => {
                    return res.data
                })
        }
        async  updateTypeOfValueAddition4(product: TypeOfValueAddition4Dto): Promise<TypeOfValueAddition4Model> {
        return await axios.post(this.URL + '/updateValAddFourType', product)
                        .then(res => {
                            return res.data
                        })
                }
            async getAllTypeOfValueAddition4(req?:UserRequestDto): Promise<AllTypeOfValueAddition4ResponseModel> {
                
                // return new AllTypeOfValueAddition4ResponseModel(true,1111,'Type of Value Addition 4 retrieved successfully',[new TypeOfValueAddition4Dto(1,'Some Code','Some Name','Remark 1','Me',true)])
                return await axios.post(this.URL + '/getAllValAddTypes',req)
                    .then(res => {
                        return res.data
                    })     
            }
                async  activatedActivateTypeOfValueAddition4(typeOfValueAddition4Dto: TypeOfValueAddition4Dto): Promise<TypeOfValueAddition4Model> {
                    //   console.log(State.stateCode);
                    return await axios.post(this.URL + '/activateOrDeactivateValAddFourType', typeOfValueAddition4Dto)
                            .then(res => {
                                return res.data
                            })
                }
            async  getAllActiveTypeOfValueAddition4(): Promise<AllTypeOfValueAddition4ResponseModel> {
                return await axios.post(this.URL + '/getAllActiveValAddTypes')
                    .then(res => {
                        return res.data
                    })
            }
            async getActiveTypeOfValueAddition4Count(): Promise<AllTypeOfValueAddition4ResponseModel> {
            return await axios.post(this.URL + '/getActiveCount').then(res => {
                return res.data
            });
            }

    async getValAdditionFourTypes(){
        //   alert('hai')
        return new ValAddFourDropDownDataResponseModel(true,11,'customersData retrived successfully',[new ValAddFourDropDownData(1,'QC'),new ValAddFourDropDownData(2,'Block')])
    }

     /**
     * Function to get valAddDetails
     * @param valueAdditionId  
     */
    async  getValAddDetails(valAddOneReq: ValueAdditionIdRequest): Promise<valueAdditionIdResponse> {
        return await axios.post(this.URL + '/getValAddDetails', valAddOneReq).then(res => {return res.data});
    }

}