export class SubPlantValAddDto{
    subplantId?: number;
    subplant: string;
    operationName: string;
    lotNo: string;
    quantity: number;
    boxes: number;
    soNumber: string;
    grade: number;
    status: string;
    

    constructor(
        subplantId: number,
        subplant: string,
        operationName: string,
        lotNo: string,
        quantity: number,
        boxes: number,
        soNumber: string,
        grade: number,
        status: string,
        
    ){
       this.subplantId = subplantId;
       this.subplant = subplant;
       this.operationName = operationName;
       this.lotNo = lotNo;
       this.quantity = quantity;
       this.boxes = boxes;
       this.soNumber = soNumber;
       this.grade = grade;
       this.status = status;
       
    }
}

