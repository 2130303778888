import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ProductionLogDto } from './production-log.dto';

export class ProductionLogResponse extends GlobalResponseObject{
    data?: ProductionLogDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductionLogDto
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ProductionLogDto) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
