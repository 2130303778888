
export class AssetsRequest {
    assetId: number;
    updatedUser: string;
    versionFlag: number;
    isActive: boolean;

    
 constructor(
    assetId: number,
    updatedUser: string,
    versionFlag: number,
    isActive: boolean,

){
    this.assetId =assetId;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
    this.isActive = isActive;
}
 }