import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { InProgressBeHeadingDto } from "./inprogress-beheading.dto";

export class InProgressBeheadingResponse extends GlobalResponseObject{
    data?: InProgressBeHeadingDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: InProgressBeHeadingDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}