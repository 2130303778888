
import { RmRackPositionTypeEnum } from "../rm-rack-position";

export class RmRackModel{
    rmRackPositionId: number;
    rmRackPositionCode: string;
    plantId: number;
    assocRfId: string;
    rmRackPosition: string;
    rmRackLevel: number;
    rmRackBoundaries: string;
    occupancy: string;
    createdDate: Date;
    modifiedDate: Date;
    isActive:boolean;
    versionFlag:number;
    rmRackMasterId: number;
    rmRackMasterName: string;
    rmRackMasterCode: string;
    rmRackPositionType?: RmRackPositionTypeEnum;
    zoneId?:number;



    /**
     * 
     * @param rmRackPositionId 
     * @param rmRackPositionCode 
     * @param plantId 
     * @param rfIds 
     * @param rmRackPosition 
     * @param rmRackLevel 
     * @param rmRackBoundaries 
     * @param occupancy 
     * @param createdDate 
     * @param modifiedDate 
     * @param versionFlag
     * @param rmRackMasterId 
     * @param rmRackMasterName 
     * @param rmRackMasterCode
     */
     constructor(rmRackId:number,rmRackCode:string,plantId: number,assocRfId: string,rmRackPosition: string,rmRackLevel: number,rmRackBoundaries: string,occupancy: string,createdDate: Date, modifiedDate: Date, isActive:boolean,versionFlag:number,rmRackMasterId: number, rmRackMasterName: string, rmRackMasterCode: string,rmRackPositionType?: RmRackPositionTypeEnum,zoneId?:number){
        this.rmRackPositionId = rmRackId;
        this.rmRackPositionCode = rmRackCode;
        this.plantId = plantId;        
        this.assocRfId = assocRfId;
        this.rmRackPosition = rmRackPosition;
        this.rmRackLevel = rmRackLevel;
        this.rmRackBoundaries = rmRackBoundaries;
        this.occupancy = occupancy;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.rmRackMasterId = rmRackMasterId;
        this.rmRackMasterName = rmRackMasterName;
        this.rmRackMasterCode = rmRackMasterCode;
        this.rmRackPositionType = rmRackPositionType;
        this.zoneId = zoneId;
    }
}