import { WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { GradingModel } from "./gradings.model";

export class GradeLotNoModel {
    headOnCount: number;
    currentWeight: string;
    counts: GradingModel[];
    soNumber: string;
    workstationType: WorkStationTypeEnum;
    workstation: number;
    wipWeight?: number;
    mainPlantId?: number;
    

    constructor(headOnCount: number,currentWeight: string,counts: GradingModel[],soNumber: string,workstationType:WorkStationTypeEnum,workstation: number,wipWeight?: number,mainPlantId?: number){
        this.headOnCount = headOnCount;
        this.currentWeight = currentWeight;
        this.counts = counts;
        this.soNumber = soNumber;
        this.workstationType = workstationType;
        this.workstation = workstation;
        this.wipWeight = wipWeight;
        this.mainPlantId = mainPlantId;
        
    }
}