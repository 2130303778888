import { DefectService } from '@gtpl/shared-services/masters'
import { Button, Card, Col, Form, Input, Row, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
const { Option } = Select

export interface AddDefectsProps {
    setTableData: (value) => void,
    indentItemId: number,
    initialValues: any[]
}

export default function AddDefects(props: AddDefectsProps) {
    const [defects, setDeffects] = useState<any[]>([])
    const defectsService = new DefectService()
    const [form] = Form.useForm()
    const [tableData, setTableData] = useState<any>(props.initialValues)

    useEffect(() => {
        getDefectsDropdown()
    }, [])


    const getDefectsDropdown = () => {
        defectsService.getAllActiveDefects().then((res) => {
            if (res.status) {
                setDeffects(res.data)
            } else {
                setDeffects([])
            }
        })
    }

    function addDefect() {
        form.validateFields().then((res) => {
            tableData.push(res)
            console.log(res)
            const defect = defects.find((el) => el.defectId == res.defect)
            res.defectId = defect.defectId
            res.defect = defect.defectName
            setTableData(prev => [...tableData])
            props.setTableData(tableData)
        })
    }

    function deleteDefect(index) {
        console.log(index)
        tableData.splice(index, 1)
        setTableData(prev => [...tableData])
        props.setTableData(tableData)
    }

    const columns = [
        { title: 'Defect', dataIndex: 'defect' },
        { title: 'Percentage', dataIndex: 'defectPercentage' },
        {
            title: 'Action',
            dataIndex: 'delete',
            render: (value, rowData, index) => {
                return <Button onClick={() => deleteDefect(index)} type='ghost' size='small' icon={<DeleteOutlined />} />
            }
        }
    ]
    return (
        <Card title='Add Defects' >
            <Form form={form} layout={'vertical'} name='control-hooks'  >
                <Row gutter={24}>
                    <Form.Item name='indentItemId' initialValue={props.indentItemId} style={{ display: 'none' }}>
                        <Input hidden />
                    </Form.Item>
                    <Col span={4}>
                        <Form.Item name='defect' label='Defect' rules={[
                            {
                                required: true,
                                message: 'Input Required',
                            },
                        ]}>
                            <Select placeholder='Select defect'>
                                {defects.map((el) => {
                                    return <Option defect={el.defectName} value={el.defectId}>{el.defectName}</Option>
                                })}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name='defectPercentage' label='Percentage' rules={[
                            {
                                required: true,
                                message: 'Input Required',
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={1} style={{ marginTop: '30px' }}>
                        <Button type='primary' onClick={addDefect} >Add</Button>
                    </Col>
                </Row>
            </Form>
            {tableData.length ? <Table columns={columns} dataSource={tableData} pagination={false} /> : <></>}
        </Card>
    )
}
