import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, DatePicker } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './indent-from-for-prepared-material.css';
import IndentItemFrom from './indent-items-form';
import { Indentdetailform } from './indent-details-form';
import { indentformDto, IndentFormItemsDto, RequestforIndentItem } from '@gtpl/shared-models/procurement-management';
import { IndentformService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';


/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

/* eslint-disable-next-line */
/* eslint-disable-next-line */
export interface IndentFromForPreparedMaterialProps {
  updateItem: (Data: indentformDto) => void;
  isUpdate: boolean
  selectedData: indentformDto;
  closeForm: () => void;
}

export function IndentFromForPreparedMaterial(props: IndentFromForPreparedMaterialProps) {
  const [addIndentForm] = Form.useForm()
  const [indentdetailsform] = Form.useForm()
  const [indentitemdetailsform] = Form.useForm()
  const [indentData, setIndentData] = useState<any[]>()
  const [indentupdate, setIndentupdate] = useState<any[]>([])
  const { selectedData, isUpdate } = props
  const [indentitemupdate, setIndentitemupdate] = useState<any[]>([])
  const [indentUpdateData, setindentUpdateData] = useState<any>()
  const [tableData, setTableData] = useState<IndentFormItemsDto[]>([])
  const [form] = Form.useForm();
  const [saleOrderId,setSaleOrderId] = useState<any>()

  const service = new IndentformService();
  let history = useHistory()

  useEffect(() => {
    console.log(props.isUpdate)
    if (props.isUpdate) {
      getDataForUpdate()
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
    }
  }, [])

  const save = (indentdto: indentformDto) => {
    {
      console.log(indentdto)
      service.createIndentFormDetails(indentdto).then((res) => {
        if (res.status) {
          console.log(res)
          AlertMessages.getSuccessMessage(res.internalMessage)
          addIndentForm.resetFields()
          indentdetailsform.resetFields()
          props.closeForm()
          history.push('indent-grid-for-prepared-material')
        } else {
          AlertMessages.getInfoMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage('Error while creating');
      })
    }
  }

  const saveData = () => {
    indentdetailsform.validateFields().then((indentDetail) => {
      const indentdetailArry: IndentFormItemsDto[] = [];
      indentData?.forEach((element) => {
        console.log(element.itemsubcategoryId.value)
        indentdetailArry.push(new IndentFormItemsDto(element?.indentItemId ? element.indentItemId : undefined, indentDetail.indentMaterialPreparationId ? indentDetail.indentMaterialPreparationId : undefined, element.itemsubcategoryId?.value, element?.itemId?.value ? element?.itemId?.value:undefined, element.quantity, Number(localStorage.getItem('unit_id')), undefined, indentDetail.createdUser, undefined, undefined, 1))
      })
      const indentdto = new indentformDto(indentDetail?.indentMaterialPreparationId ? indentDetail.indentMaterialPreparationId : undefined, indentDetail.indentDate, indentDetail.employeeId,indentDetail.indentAgainst, indentDetail.saleOrderItemId? indentDetail.saleOrderItemId:undefined,indentDetail.saleOrderId? indentDetail.saleOrderId:0, Number(localStorage.getItem('unit_id')), undefined, indentDetail.createdUser, undefined, indentDetail.indentCode ? indentDetail.indentCode : undefined, indentdetailArry, true);
      if (props.isUpdate) {
        console.log(indentdto);
        props.updateItem(indentdto);
      } else {
        console.log(indentdto);
        if(indentdto.indentItemDetails.length>0){
          save(indentdto);
        }
        else{
          AlertMessages.getErrorMessage('Add items data');
        }
      }
    })

  };

  const getDataForUpdate = () => {
    const req = new RequestforIndentItem(selectedData.indentMaterialPreparationId)
    service.getAllIndentdataforindentmaterialId(req).then((res) => {
      console.log(res)
      if (res.status) {
        console.log(res)
        const { indentItemDetails, ...rest } = res.data
        setindentUpdateData(rest)
        console.log(indentItemDetails)
        setIndentitemupdate(indentItemDetails)
        console.log(indentitemupdate, '***************************8')
        indentdetailsform.setFieldsValue({
          // indentDate: rest.indentDate,
          employeeId: rest.employeeId,
          saleOrderItemId: rest.saleOrderItemId,
          indentMaterialPreparationId: rest.indentMaterialPreparationId,
          indentCode:rest.indentCode,
          saleOrderId:rest.saleOrderId,
          indentAgainst:rest.indentAgainst
        })
        setIndentData(res.data.indentItemDetails)
        setTableData(indentItemDetails);

      } else {
        setIndentData([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setIndentData([])
    })
  }

  console.log(indentdetailsform.getFieldsValue())

  const getSetIndentData = (indentFormItemsDto: IndentFormItemsDto[]) => {
    console.log(indentFormItemsDto);
    setIndentData(indentFormItemsDto)
  }

console.log(indentUpdateData)

  return (

    <Card title={<span style={{ color: 'white' }}>Indent for Prepared Material</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={props.isUpdate == true ? "" : <Link to='/indent-grid-for-prepared-material' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>
      }
    >

      <Indentdetailform form={indentdetailsform} addindentForm={addIndentForm} initialValues={indentupdate} isUpdate={isUpdate}
       setSaleOrderId={setSaleOrderId} 
      />
      <IndentItemFrom indentAgainst={indentdetailsform.getFieldValue('indentAgainst')} status={indentUpdateData?.status} form={addIndentForm} indentItemDetailForm={indentdetailsform} setIndentData={getSetIndentData} initialValues={indentitemupdate} isUpdate={isUpdate} 
      saleOrderId={indentdetailsform.getFieldValue('saleOrderId')}
      />
      <Row justify='end'>
        <Col span={2}>
          <Button onClick={saveData} type='primary'>
            Submit
          </Button>
        </Col>
      </Row>
    </Card>


  );
}

export default IndentFromForPreparedMaterial;
