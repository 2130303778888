

export class TeamDto {

   
    id:number;
    shiftCode:string;
    isActive:boolean;
    createdAt:Date;
    createdUser:string;
    updatedAt:Date;
    updatedUser:string;
    versionFlag:number;

    /**
     * 
     * @param id 
     * @param shiftCode 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(id:number,shiftCode:string,isActive:boolean,createdAt:Date,createdUser:string,updatedAt:Date,updatedUser:string,versionFlag:number){

        this.id =id;
        this.shiftCode = shiftCode;
        this.isActive = isActive;
        this.createdAt=  createdAt;
        this.createdUser=createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
    


}