export class RmToleranceDto {
  rmToleranceId?: number;
  date: any;
  looseShell:number;
  softShell:number;
  fungus:number;
  isActive: boolean;
  createdUser :string ;
  updatedAt : Date
   updatedUser : string;
   versionFlag : number;
}