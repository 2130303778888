export class ReprocessingInputRequest {
    saleOrderId: number;
    stockId: number[];
    saleOrderItemId: number;
    rmStockQty:number
    category:string
    minGrade?:number
    maxGrade?:number
    hlPercentage?:number
    finalPercentage?:number
    uomId?:number
    shrimpWeight?:number
    totalPouches?:number
    totalCases?:number
    plantId?:number
    constructor(saleOrderId:number, stockId: number[],saleOrderItemId:number,rmStockQty:number,category:string,minGrade?:number,maxGrade?:number,hlPercentage?:number,finalPercentage?:number,uomId?:number,shrimpWeight?:number,totalPouches?:number,totalCases?:number,plantId?:number) {
        this.saleOrderId = saleOrderId;
        this.stockId = stockId;
        this.saleOrderItemId = saleOrderItemId;
        this.rmStockQty = rmStockQty;
        this.category = category;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.hlPercentage = hlPercentage;
        this.finalPercentage = finalPercentage;
        this.uomId = uomId;
        this.shrimpWeight = shrimpWeight;
        this.totalPouches = totalPouches;
        this.totalCases = totalCases;
        this.plantId = plantId;
    }
}
