
import { Card, Button, Form, Descriptions, Row, Col, Input, Modal } from 'antd';
import './stock-in-form.css'
import React, { useEffect, useState } from 'react';
import form from 'antd/lib/form';
import { ScanOutlined, SearchOutlined, PrinterOutlined, BarcodeOutlined } from '@ant-design/icons';
import { FGStockService, ForkLiftJobService, StockInPrinResponse, StockInPritnRequest, StockInService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';


export interface StockOutVerificationProps{ reportingLogId : number}
export function StockOutVerification(props:StockOutVerificationProps){

  const [form] = Form.useForm();
  const time = moment().format("DD-MM-YYYY HH:mm")
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const stockService = new FGStockService();
  const forkLiftJob = new ForkLiftJobService();


  const saveData = (value) => {
    }

  const getStockOutDetails = (value) => {
    forkLiftJob.getJobDetailsByLocation({plantId: 0,zoneId: 0,rackId: 0,rackPositionId: value,isReserved:true,updatedUser: "",saleOrderId: 0,varientId: 0}).then((res) => {
      if(res.status){
        AlertMessages.getSuccessMessage(res.internalMessage);
        onReset();
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  };
  const handleQrScan = (value) => {
    setShowQrScan(false)
  }
    return(
        <Card title={<span style={{ color: 'white' }}> Stock Out</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
            <Form  layout="vertical" form={form} name="control-hooks" onFinish={saveData} >
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            name="location"
                            label="Location Barcode"
                            rules={[
                            {
                                required:true,
                                message: 'Location Barcode must be required'
                            },
                            
                            ]}
                        >
                            <Input onChange={e => getStockOutDetails(e.target.value)} suffix={<ScanOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'3px',marginRight:'-6px'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                className='qr-container'
                key={'modal' + Date.now()}
                width={'95%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={showQrSacn}
                onCancel={() => { setShowQrScan(false) }}
                title={<React.Fragment>
                </React.Fragment>}
                >
                {
                modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
                }
            </Modal>
        </Card>
    )
}
export default StockOutVerification