export enum StatusEnum { // Used For Bom Items Status and GRN
    OPEN = 'OPEN',
    //*  IN_PROGRESS not in use.
    IN_PROGRESS = 'IN_PROGRESS',
    PO = 'PO',
    PARTIAL_ASN = 'PARTIAL ASN',
    ASN = 'ASN',
    GRN = 'GRN',
    CLOSED = 'CLOSED',
    ASSIGNED = 'ASSIGNED',
    CANCELLED = 'CANCELLED'
}