export class ColumnDropDownDto {
    columnsId:number;
    columnsNo:number;
    columnsCode:string;

    /**
     * 
     * @param columnsId 
     * @param columnsNo 
     * @param columnsCode
     */
    constructor(columnsId:number, columnsNo:number,columnsCode:string){
        this.columnsId = columnsId;
        this.columnsNo=  columnsNo;
        this.columnsCode= columnsCode;
    }
}