import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, InputNumber, Modal, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import './grading-assignment.css';
import { AssignSoModel, GradeLotInvModel } from '@gtpl/shared-models/production-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { BomWithCountAndQuantityModel, CountRequest, ItemsDetails, SaleOrderDropDownDto, SoTypeReq } from '@gtpl/shared-models/sale-management';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SoProcessTypesEnum } from '@gtpl/shared-models/common-models';
import { GlobalVariableValues } from '@gtpl/shared-models/common-models';
const { Option } = Select;
/* eslint-disable-next-line */
export interface GradingAssignmentProps {
  assignCountToSO: (val:AssignSoModel) => void;
  gradeLotInv: GradeLotInvModel;
  closeForm: () => void;
}
// console.log(props.gradeLotInv)
export function GradingAssignment(props: GradingAssignmentProps) {
  const [assignCountToSOForm] = Form.useForm();
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const [saleOrderNumbers, setSaleOrderNumbers] = useState<SaleOrderDropDownDto[]>([]);
  const [soItemNumbers, setSoItemNumbers] = useState<ItemsDetails[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [soData, setSoData] = useState<BomWithCountAndQuantityModel[]>([]);
  const [soNumbers, setSoNumbers] = useState<any[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(''); 
  const service = new SaleOrderService();
  useEffect(() => {
    assignCountToSOForm.setFieldsValue({lotNo:props.gradeLotInv.lotNo,count:props.gradeLotInv.count,quantity:props.gradeLotInv.quantity-props.gradeLotInv.assignedQty,boxes:(props.gradeLotInv.boxes-props.gradeLotInv.assignedBoxes),plannedSoId:props.gradeLotInv.saleOrderId});
    getAllSaleOrders();
  }, []);

  // const getAllSaleOrders = () => {
  //   service.getSaleOrdersForCount(new CountRequest(props.gradeLotInv.count,plantId)).then((res) => {
  //     if (res.status) {
  //       setSaleOrderNumbers(res.data.soDropdownData);
  //       setSoData(res.data.soCountQtyData);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   })
  //   .catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setSaleOrderNumbers([]);
  //   });
  // };
  const getAllSaleOrders = () => {
    const req = new SoTypeReq()
    req.soType = assignCountToSOForm.getFieldValue('soType')
    req.unitId = Number(localStorage.getItem('unit_id'))
    service.getAllSoForAssignSo(req).then((res) => {
      if (res.status) {
        setSoNumbers(res.data);
      } else {
        setSoNumbers([]);
      }
    })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSoNumbers([]);
    });
  };

  const getSaleOrderItemIds = () => {
    const saleOrderId = assignCountToSOForm.getFieldValue('soNumber');
    if(saleOrderId){
      service.getSaleOrderItemsForGradingAssignSo(new saleOrder(saleOrderId,plantId)).then((res) => {
        if (res.status) {
          setSoItemNumbers(res.data.itemsDetails);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSaleOrderNumbers([]);
      });
    }
  };

  const saleOrderOnchange = (e,obj) => {
    console.log(e)
    // const soiItem = soNumbers.filter((el) => { return el.saleOrderId == e })
    // console.log(soiItem[0].saleOrderItemId)
    assignCountToSOForm.setFieldsValue({soNumber:obj.soId});
    // getSaleOrderItemIds();
  }

  const onReset = () => {
    assignCountToSOForm.resetFields();
  };

  const assignCountToSO = (values: AssignSoModel) => {
    if(values.quantity > (props.gradeLotInv.quantity-props.gradeLotInv.assignedQty)){
      AlertMessages.getWarningMessage('Assigned quantity should be equal or less than quantity-assigned quantity');
    } else{
      props.assignCountToSO(values);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
 

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      sorter: (a, b) => a.soNumber.length - b.soNumber.length || a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
    },
    {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      sorter: (a, b) => a.variantCode.length - b.variantCode.length || a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),
    },
    {
      title: 'Headon Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'hlCount',
      sorter: (a, b) => a.hlCount.toString().length - b.hlCount.toString().length || a.hlCount.toString().localeCompare(b.hlCount.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity (In Tons)',
      dataIndex: 'quantity',
    },
  ];

  const calculateQuantity = (e) => {
    console.log(e)
    if(e > (props.gradeLotInv.boxes-props.gradeLotInv.assignedBoxes)){
      assignCountToSOForm.setFieldsValue({boxes:null,quantity:null})
      AlertMessages.getErrorMessage('boxes exceeded')
    }else{
      let boxWeight
      if((props.gradeLotInv.boxes-props.gradeLotInv.assignedBoxes-e) > 0){
        if(props.gradeLotInv.count > 50){
          boxWeight = GlobalVariableValues.GradingOutputAbove50
        }else{
          boxWeight = GlobalVariableValues.GradingOutputBelow50
        }
        const assignedQty = e*boxWeight
        assignCountToSOForm.setFieldsValue({quantity:assignedQty})
      }else{
        assignCountToSOForm.setFieldsValue({quantity:props.gradeLotInv.quantity-props.gradeLotInv.assignedQty})
      }
    }
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Assign Count to SO </span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra = {
          <Button onClick={showModal} htmlType="submit">
            SO wise Count & Quantity Requirement
          </Button>
      }
    >
      
      <Form layout={'vertical'} form={assignCountToSOForm} onFinish={assignCountToSO}>
        <Row gutter={24}>
          <Col span={7}>
            <Form.Item name="lotNo" label="Lot Code" rules={[{ required: true, message: 'Lot Number is required' }]}>
              <InputNumber style={{ width: '100%' }} defaultValue={props.gradeLotInv.lotNo} readOnly/>
            </Form.Item>
          </Col>
          {/* <Col style={{width: '50%'}}>
            <Form.Item name="soNumber" label="SO number" rules={[{ required: true, message: 'SO Number is required' }]}>
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO No"
                allowClear
                onChange = {saleOrderOnchange}
              >
                {saleOrderNumbers.map(dropData => {
                  return <Option key={dropData.saleOrderNumber} value={dropData.saleOrderId}>{`${dropData.saleOrderNumber}-${dropData.poNumber}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={3}>
          <Form.Item name="soType" label="SO Type" rules={[{ required: true, message: 'SO Type is required' }]} initialValue={SoProcessTypesEnum.MTO} >
              <Select
              
              showSearch     
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO Type"
                allowClear
                onChange = {e =>{getAllSaleOrders()}}
                defaultValue={SoProcessTypesEnum.MTO}
              >
                <Option key={'Saleorder'} value={SoProcessTypesEnum.MTO}>{'Saleorder'}</Option>
                <Option key={'Dummy'} value={SoProcessTypesEnum.MTS}>{'Dummy'}</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="soItemNumber" label="SO number" rules={[{ required: true, message: 'SO Number is required' }]}>
              <Select
              showSearch
              
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO No"
                allowClear
                onChange = {saleOrderOnchange}
              >
                {soNumbers.map(dropData => {
                  return <Option soId = {dropData.saleOrderId} key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}>{dropData.item}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          </Row>
          <Row>

          <Col style={{width: '50%'}} hidden>
            <Form.Item name="soNumber" label="SO Item number" rules={[{ required: true, message: 'SO Item Number is required' }]}>
            <InputNumber  style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item name="plannedSoId" label="planned SO Id" rules={[{ required: true, message: 'SO Item Number is required' }]}>
            <InputNumber style={{ width: '100%' }}/>
            </Form.Item>
              {/* <Select
                placeholder="Select So Item No"
                allowClear
              >
                {soItemNumbers.map(dropData => {
                  return <Option key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}>{dropData.item}</Option>
                })}
              </Select> */}
            {/* <Form.Item name="boxes" label="boxes" rules={[{ required: true, message: 'boxes is required' }]}>
            <InputNumber style={{ width: '100%' }}/>
            </Form.Item> */}
          </Col>
          <Col style={{width: '30%'}}>
            <Form.Item name="count" label="Headless Count" rules={[{ required: true, message: 'Headless count is required' }]}>
              <InputNumber style={{ width: '100%' }} defaultValue={props.gradeLotInv.count} readOnly/>
            </Form.Item>
          </Col>
          <Col style={{width: '30%'}}>
            <Form.Item name="boxes" label="Boxes" rules={[{ required: true, message: 'Boxes is required' }]}>
              <InputNumber style={{ width: '100%' }} defaultValue={props.gradeLotInv.boxes} onChange={e => calculateQuantity(e)}/>
            </Form.Item>
          </Col>
          <Col style={{width: '30%'}}>
            <Form.Item name="quantity" label="Assign Quantity" rules={[{ required: true, message: 'Quantity is required' }]}>
              <InputNumber style={{ width: '100%' }} defaultValue={props.gradeLotInv.quantity}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <Form.Item style={{ paddingTop: '30px' }}>
              <Button type='primary' block htmlType="submit">
              Submit
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ paddingTop: '30px' }}>
              <Button type='primary' block onClick={onReset} danger>
              Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal width={800} title="SO wise Count & Quantity Requirement" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Table columns={columnsSkelton} dataSource={soData} bordered pagination={{ defaultPageSize:6}}/>
      </Modal>
    </Card>
  );
}

export default GradingAssignment;
