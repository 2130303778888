export class SaleOrderItemsDetails {
    saleOrderItemId: number;
    saleOrderId: number;
    itemId: number;//products -->masters/products.service --->itemname,code
    itemName: string;
    itemCode: string;
    minGrade: number;
    maxGrade: number;
    foodTypeId: number;//foodtypes -->foodtypes.service -->foodtypename
    foodTypeName: string;
    valueAdditionOneId: number;//  -->   masters-->name,code
    valueAdditionOneIdName: string;
    valueAdditionOneIdCode: string;
    valueAdditionTwoId: number;
    valueAdditionTwoIdName: string;
    valueAdditionTwoIdCode: string;
    valueAdditionThreeId: number;
    valueAdditionThreeIdName: string;
    valueAdditionThreeIdCode: string;
    valueAdditionFourId: number;
    valueAdditionFourIdName: string;
    valueAdditionFourIdCode: string;
    valueAdditionFiveId: number;
    valueAdditionFiveIdName: string;
    valueAdditionFiveIdCode: string;
    varientId: number;//  -->   masters/skcodes-->variantcode
    varientCode: string;
    skewerSizeItemId: number;//  -->   masters/sizesmaster-->name
    skewerSizeItemName: string;
    innerBagSizeId: number;//  -->   masters/sizesmaster-->name
    innerBagSizeName: string;
    cases: number;
    netAmount: number;
    unitPrice: number;
    pouches: number;
    innerBags:number;
    shrimpWeight: number;
    pouchWeight: number;
    caseWeight: number;
    categoryId: number;
    netWeight: number;
    uomId: number;
    minGlaze: number;
    MaxGlaze: number;
    soakingTime: string;
    soakingStyle: string;
    styleWeighment: string;
    hlPercentage: number;
    finalPercentage: number;
    brandId: number;// -->masters/brand
    brandName: string;
    reportedCases: number;// -->
    invoicedQty: number;
    dispatchedQty: number;
    revisedUnitPrice: number;
    paymentTerms: string;
    customeraddress: string;
    deliveryaddress: string;
    containerNo: string;
    linearSelaNo: string;
    billOfLadingno: string;
    destinationDetails: string;
    foodTypeHsnCode: number;
    productypeHsnCode: number;
    currencyDetails: string;
    blNo: string;
    scas: string;
    bookingNo: string;
    currencyId: number;
    grossWeight: number;
    hsnCode: string;
    taxName: string;
    taxPer: number;
    taxCategory: string;
    invoicedCases?: number;
    foodTypeCode?:string;
    trackingNo?:string;
    grossWeightPerKg?:number;
    displayBrandName?:string;
    invoiceAmount?:number;


    constructor(saleOrderItemId: number, saleOrderId: number, itemId: number, itemName: string, itemCode: string, minGrade: number, maxGrade: number, foodTypeId: number, foodTypeName: string, valueAdditionOneId: number, valueAdditionOneIdName: string, valueAdditionOneIdCode: string, valueAdditionTwoId: number, valueAdditionTwoIdName: string, valueAdditionTwoIdCode: string, valueAdditionThreeId: number, valueAdditionThreeIdName: string, valueAdditionThreeIdCode: string, valueAdditionFourId: number,valueAdditionFourIdName: string, valueAdditionFourIdCode: string, valueAdditionFiveId: number, valueAdditionFiveIdName: string, valueAdditionFiveIdCode: string, varientId: number, varientCode: string, skewerSizeItemId: number, skewerSizeItemName: string, innerBagSizeId: number, innerBagSizeName: string, cases: number, netAmount: number, unitPrice: number, pouches: number, innerBags:number, shrimpWeight: number, pouchWeight: number, caseWeight: number, categoryId: number, netWeight: number, uomId: number, minGlaze: number, MaxGlaze: number, soakingTime: string, soakingStyle: string, styleWeighment: string, hlPercentage: number, finalPercentage: number, brandId: number, brandName: string, reportedCases: number, invoicedQty: number, dispatchedQty: number, revisedUnitPrice: number, paymentTerms: string, customeraddress: string, deliveryaddress: string, containerNo: string, linearSelaNo: string, billOfLadingno: string, destinationDetails: string,  foodTypeHsnCode: number, productypeHsnCode: number, currencyDetails: string, blNo: string,scas: string,bookingNo:string,currencyId:number,grossWeight:number,hsnCode:string,taxName: string,taxPer:number,taxCategory:string,invoicedCases?:number,foodTypeCode?:string,trackingNo?:string,grossWeightPerKg?:number,displayBrandName?:string,invoiceAmount?:number)  {

        this.saleOrderItemId= saleOrderItemId;
        this.saleOrderId= saleOrderId;
        this.itemId= itemId;
        this.itemName= itemName;
        this.itemCode= itemCode;
        this.minGrade= minGrade;
        this.maxGrade= maxGrade;
        this.foodTypeId= foodTypeId;
        this.foodTypeName= foodTypeName;
        this.valueAdditionOneId= valueAdditionOneId;
        this.valueAdditionOneIdName= valueAdditionOneIdName;
        this.valueAdditionOneIdCode= valueAdditionOneIdCode;
        this.valueAdditionTwoId= valueAdditionTwoId;
        this.valueAdditionTwoIdName= valueAdditionTwoIdName;
        this.valueAdditionTwoIdCode= valueAdditionTwoIdCode;
        this.valueAdditionThreeId= valueAdditionThreeId;
        this.valueAdditionThreeIdName= valueAdditionThreeIdName;
        this.valueAdditionThreeIdCode= valueAdditionThreeIdCode;
        this.valueAdditionFourId= valueAdditionFourId;
        this.valueAdditionFourIdName= valueAdditionFourIdName;
        this.valueAdditionFourIdCode= valueAdditionFourIdCode;
        this.valueAdditionFiveId= valueAdditionFiveId;
        this.valueAdditionFiveIdName= valueAdditionFiveIdName;
        this.valueAdditionFiveIdCode= valueAdditionFiveIdCode;
        this.varientId= varientId;
        this.varientCode= varientCode;
        this.skewerSizeItemId= skewerSizeItemId;
        this.skewerSizeItemName= skewerSizeItemName;
        this.innerBagSizeId= innerBagSizeId;
        this.innerBagSizeName= innerBagSizeName;
        this.cases= cases;
        this.netAmount= netAmount;
        this.unitPrice= unitPrice;
        this.pouches= pouches;
        this.innerBags= innerBags;
        this.shrimpWeight= shrimpWeight;
        this.pouchWeight= pouchWeight;
        this.caseWeight= caseWeight;
        this.categoryId= categoryId;
        this.netWeight= netWeight;
        this.uomId= uomId;
        this.minGlaze= minGlaze;
        this.MaxGlaze= MaxGlaze;
        this.soakingTime= soakingTime;
        this.soakingStyle= soakingStyle;
        this.styleWeighment= styleWeighment;
        this.hlPercentage= hlPercentage;
        this.finalPercentage= finalPercentage;
        this.brandId= brandId;
        this.brandName= brandName;
        this.reportedCases= reportedCases;
        this.invoicedQty= invoicedQty;
        this.dispatchedQty= dispatchedQty;
        this.revisedUnitPrice= revisedUnitPrice;
        this.paymentTerms=paymentTerms;
        this.customeraddress=customeraddress;
        this.deliveryaddress=deliveryaddress;
        this.containerNo=containerNo;
        this.linearSelaNo=linearSelaNo;
        this.billOfLadingno=billOfLadingno;
        this.destinationDetails=destinationDetails;
        this.foodTypeHsnCode=foodTypeHsnCode;
        this.productypeHsnCode=productypeHsnCode;
        this.currencyDetails=currencyDetails;
        this.blNo=blNo;
        this.scas=scas;
        this.bookingNo=bookingNo;
        this.currencyId=currencyId;
        this.grossWeight=grossWeight;
        this.hsnCode=hsnCode;
        this.taxName=taxName;
        this.taxPer=taxPer;
        this.taxCategory=taxCategory;
        this.invoicedCases=invoicedCases;
        this.foodTypeCode=foodTypeCode;
        this.trackingNo = trackingNo;
        this.grossWeightPerKg = grossWeightPerKg;
        this.displayBrandName = displayBrandName;
        this.invoiceAmount = invoiceAmount;
        
    }
}