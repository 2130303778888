import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, ConfigProvider, Divider, Drawer, Form, Input, Modal, Row, Select, Tooltip, Typography } from 'antd';
import React from "react";
import enUSIntl from 'antd/lib/locale/en_US';
import { Link } from "react-router-dom";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined, BarcodeOutlined } from '@ant-design/icons';
import { AssetMaintenanceService } from 'libs/shared-services/asset-management/src/lib/asset-maintenance';
import { AssetMaintainanceDto } from "libs/shared-models/asset-management/src/lib/asset-maintainance";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetMaintainanceForm } from '../../../asset-sub-category/src/lib/pages-asset-management-asset-management-components-asset-sub-category';
import { AssetById } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-id.dto';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-code.dto';
import './pages-asset-management-asset-management-components-asset-sub-category-grid.css'
import moment from "moment";
import { AssetDto } from "libs/shared-models/asset-management/src/lib/open-assets/assets.dto";
import { AssetService } from "libs/shared-services/asset-management/src/lib/assets.service";
import Scanner from '../../../asset-classification-form/src/lib/Scanner';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import Highlighter from 'react-highlight-words';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';


const { Option } = Select;

export interface AssetMaintainanceGridProps { }

export function AssetMaintainanceGrid(props: AssetMaintainanceGridProps) {
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [assetData, setAssetData] = useState<AssetMaintainanceDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<AssetDto[]>([]);
  const service = new AssetMaintenanceService();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedMaintainanceData, setSelectedMaintainanceData] = useState<any>(undefined);
  const assetsService = new AssetService();
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('');
  const { Text } = Typography;
  const [searchText, setSearchText] = useState('');
  const locationService = new AssetLocationService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [AssetDropData, setAssetDropData] = useState<any[]>([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const role = JSON.parse(localStorage.getItem('role'))
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    // getAllAssets();
    // getAssetCode();
    getAllPlants();
    getItemCategory();
    getAllAssetscodedropdowm();
    finishHandler();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }  
    getLocations()
  },[])

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  // const getAssetCode = () => {
  //   assetsService.getallAssets().then((res) => {
  //     if(res.status) {
  //       setAssetCodeData(res.data);
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }

  // const getAllAssets = () => {
  //   service.getByAssetId().then(res => {
  //     if(res.status) {
  //       setAssetData(res.data);
  //       // AlertMessages.getSuccessMessage('Data retrieved successfully')
  //     }
  //   })
  // }
  const getAllAssetscodedropdowm = () => {
    const req = new AssetByCode();
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getAssetCodesDropDownformaintance(req).then(res => {
      if (res.status) {
        setAssetDropData(res.data)
      }
    })
  }

  const updaetMaintainance = (rowData: AssetMaintainanceDto) => {
    service.updateAssetMaintainance(rowData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated successfully')
        setDrawerVisible(false)
        finishHandler();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const closeDrawer = () => {
    setDrawerVisible(false);
    finishHandler();
  }

  const openFormWithData = (viewData: AssetMaintainanceDto) => {
    console.log(viewData, '-----------------viewdata')
    setDrawerVisible(true);
    viewData.expiredDate ? viewData.expiredDate = moment(viewData.expiredDate, 'YYYY-MM-DD') : null;
    // viewData.owner ? viewData.owner = viewData.employeeName : null;
    setSelectedMaintainanceData(viewData);
  }

  const finishHandler = () => {
    // const assetId = form.getFieldValue('assetCode')
    const req = new AssetByCode();
    if (form.getFieldValue('assetCode') !== undefined) {
      req.assetsCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('location') !== undefined) {
      req.location = form.getFieldValue('location')
    }
    if (form.getFieldValue('assetType') !== undefined) {
      req.assetType = form.getFieldValue('assetType')
    }
    if (form.getFieldValue('assetCategory') !== undefined) {
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    service.getByAssetId(req).then(res => {
      if (res.status) {
        setAssetData(res.data)
        AlertMessages.getSuccessMessage('Data retrieved successfully')
      } else {
        setAssetData([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })

  }

  const resetHandler = () => {

    console.log('reset')
    form.resetFields();
    finishHandler();
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })
    // getByCode(value)

  }

  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: '130',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: "Unit",
      dataIndex: "plant",
      align: "left",
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('plant'),
      render: (text, record) => {
        return (
          <>
            {record.plant ? record.plant : '-'}
          </>
        )
      },
     
    },

    {
      title: "Location",
      dataIndex: "currentLocation",
      align: "left",
      sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('currentLocation'),
      render: (text, record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: 'Asset Mode',
      dataIndex: 'assetMode',
      width: '130',
      sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetMode'),
      // sorter:(a,b) => a.itemName.localeComapre(b.itemName),
      // sortDirections:['descend','ascend']
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      width: '130',
      sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetType'),
      // sorter:(a,b) => a.itemName.localeComapre(b.itemName),
      // sortDirections:['descend','ascend']
    },
    {
      title: "Asset Category",
      dataIndex: "itemSubCategoryName",
      align: "left",
      // width:120,
      // fixed: "left",
      sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategoryName')
    },
    {
      title: 'Asset Name',
      dataIndex: 'itemName',
      width: '130',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Asset Code',
      dataIndex: 'assetsCode',
      width: '130',
      sorter: (a, b) => a.assetsCode.localeCompare(b.assetsCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetsCode')
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      align: "left",
      sorter: (a, b) => a.invoiceNo?.localeCompare(b.invoiceNo),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('invoiceNo'),
      render: (text, record) => {
        return (
          <>
            {record.invoiceNo ? record.invoiceNo : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align: "left",
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('assetSerialNo'),
      render: (text, record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: 'Maintenance Type',
      dataIndex: 'maintainanceType',
      width: '130',
      sorter: (a, b) => a.maintainanceType?.localeCompare(b.maintainanceType),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('maintainanceType'),
    },
    {
      title: 'Owner Name',
      dataIndex: 'employeeName',
      width: '130',
      sorter: (a, b) => a.employeeName?.localeCompare(b.employeeName),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('employeeName'),
    },
    // {
    //   title: 'Assigned To',
    //   dataIndex: 'assigned',
    //   width: '130'
    // },
    // {
    //   title: 'Maintainance Frequency In Days',
    //   dataIndex: 'maintainanceFrequencyInDays',
    //   width: '130'
    // },
    {
      title: 'Cost Of Repair',
      dataIndex: 'costOfRepair',
      width: '130',
      sorter: (a, b) => a.costOfRepair?.localeCompare(b.costOfRepair),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('costOfRepair'),
    },
    {
      title: ' Due Date',
      dataIndex: 'expiredDate',
      width: '130',
      sorter: (a, b) => a.expiredDate?.localeCompare(b.expiredDate),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('expiredDate'),
      render: (value, record) => {
        return (
          <span>
            {
              record.expiredDate ? moment(record.expiredDate).format('YYYY-MM-DD') : 'N/A'
            }
          </span>
        )
      }
    },
    {
      title: ' Tentative Age',
      key: 'expiredDate',
      align: "right",
      sorter: (a, b) => (Math.floor((new Date(a.expiredDate).getTime() - new Date(b.expiredDate).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.expiredDate).getTime() - new Date(b.expiredDate).getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.expiredDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
          props: {
            style: {
              background: Math.floor((new Date(record.expiredDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(record.expiredDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
            }
          },
        };
        return obj;
      }
     
    },
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   width: '130',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.remarks ? record.remarks : '-'}
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {/* <Tooltip placement="top" title='Edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type='vertical'/> */}
          <Button type='primary' shape='round' onClick={() => openFormWithData(rowData)}>
            Return
          </Button>
        </span>
      )

    }
  ]

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Asset Maintenance</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-maintainance-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>}   >
        <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              xl={{ span: 5 }}
            >
              <Form.Item name="assetCode" label="Asset Code">
                <Select
                  suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('assetId') }} />}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {AssetDropData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.concateditemName} `}</Option>
                  })

                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
              //  defaultValue={'All'}
              placeholder='All Categories'
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All Categories</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType' >
          <Select
                // placeholder='assetType'
                showSearch
                defaultValue={'MOVABLE'}
                allowClear
              >
                <Option key='all' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
          </Form.Item>
          </Col>
            <Col span={4}>
          <Form.Item label='Unit' name='plantId' >
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
            showSearch
            allowClear>
              {/* <Option key={'all'} value={''}>All</Option> */}
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Locations'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Locations</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col>
            <Col  style={{ marginTop: "2.5%" }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Col>
            <Col style={{ marginTop: "2.5%" }}>
              <Button
                icon={<UndoOutlined />}
                onClick={resetHandler}>
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <ConfigProvider locale={enUSIntl}>
          <Table
            columns={columns}
            scroll={{ x: 1700 }}
            dataSource={assetData}
            rowClassName={(record) => moment(record.expiredDate).format('YYYY-MM-DD') < moment(Date.now()).format('YYYY-MM-DD') ? '' : ''}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
          // bordered
          // summary={(pageData) => {
          //   let totalCost = 0;

          //   pageData.forEach(({costOfRepair}) => {
          //     if(Number(costOfRepair)) {
          //       totalCost += Number(costOfRepair)
          //     }
          //   })

          //   return(
          //     <>
          //     <Table.Summary.Row className="tableFooter">
          //       <Table.Summary.Cell index={1} colSpan={6}><Text>Total</Text></Table.Summary.Cell>
          //       <Table.Summary.Cell index={2} colSpan={6}><Text style={{textAlign:'end'}}>{totalCost.toFixed()}</Text></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //     </>
          //   )
          // }}
          />
        </ConfigProvider>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '80%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <AssetMaintainanceForm key={Date.now()}
              updateMaintainance={updaetMaintainance}
              isUpdate={true}
              maintainanceData={selectedMaintainanceData}
              closeForm={closeDrawer}
            />
          </Card>
        </Drawer>
        <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
            modal == 'assetId' ? <Scanner handleScan={handleAssetIdScan} /> : null
            //<Scanner handleScan={handleAssetIdScan}/>
          }

        </Modal>
      </Card>
    </>
  )
}

export default AssetMaintainanceGrid;