export class TransferActionDto{

    transferActionId : number;
    transferDate:any;
    employeeCode:string;
    employeeName:string;
    designation:string;
    employeeRole:string;
    approvedBy:string;
    fromPlant:string;
    toPlant:string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;

    constructor(transferActionId:number,employeeCode:string,transferDate:any,employeeName:string,employeeRole:string,approvedBy:string,fromPlant:string,toPlant:string,createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.transferActionId=transferActionId;
        this.transferDate=transferDate;
        this.employeeCode=employeeCode;
        this.employeeName=employeeName;
        this.designation=this.designation;
        this.employeeRole=employeeRole;
        this.approvedBy=approvedBy;
        this.fromPlant=fromPlant;
        this.toPlant=toPlant;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}