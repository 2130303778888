import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LotTrackingResponseDto } from '.';

export class LotNumberReportResponseModel extends GlobalResponseObject{
    data?: LotTrackingResponseDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //LotTrackingResponseDto
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: LotTrackingResponseDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
