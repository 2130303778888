export class AboutToExpireDetail {
    expireWithInDays: number; // < 30, < 60, < 90
    assetCount: number;
    assetValue: number;
  
    constructor(
      expireWithInDays: number,
      assetCount: number,
      assetValue: number
    ) {
      this.expireWithInDays = expireWithInDays;
      this.assetCount = assetCount;
      this.assetValue = assetValue;
    }
  }
  