export class AssetLocationMappingDto {

    assetLocationId: number;
    assetId : number;
    assetName : string;
    locationId : number;
    // location : string;
    isActive : boolean;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
    assetCode?:string;

    constructor(
        assetLocationId: number,
        assetId : number,
        assetName : string,
        locationId : number,
        // location : string,
        isActive : boolean,
        versionFlag: number,
        assetCode?:string,
    ) {
        this.assetLocationId = assetLocationId;
        this.assetId = assetId;
        this.assetName = assetName;
        this.locationId = locationId;
        // this.location = location;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.assetCode = assetCode;
    }
}