import { CreateUserDto,UserResponseModel,AllUserResponseModel,userUpdateRequest, UserStatusWiseResponseModel } from '@gtpl/shared-models/gtpl';

import axios from 'axios';
import connection from './connection';
import { UserRequest,UserCreateResponse,GetUserresponse } from "./user-model";
export class UserService {
    URL = connection.DEPLOY_URL + '/auth';
    async  register(createUser:CreateUserDto): Promise<any> {
        return await axios.post(this.URL + '/register',createUser)
          .then(res => {
              return res.data
          })
      }

      async  login(createUser:CreateUserDto): Promise<any> {
        return await axios.post(this.URL + '/login',createUser)
          .then(res => {
              return res.data
          })
      }

      async getAllUser(): Promise<any> {
        return await axios.post(this.URL + '/getAllUsers')
            .then(res => {
                console.log('res');
                console.log(res);
                return res;
            })
    }
    async getAllActiveUser() {
        return await axios.post(this.URL + '/getAllActiveUser').then(res => {
            return res.data
        });
    }
    async  getActiveUser(): Promise<AllUserResponseModel> {
        return await axios.post(this.URL + '/getActiveUser')
            .then(res => {
                return res.data
            })
    }
    async  getActiveUsersCount(): Promise<UserStatusWiseResponseModel> {
        return await axios.post(this.URL + '/getActiveUsersCount')
            .then(res => {
                return res.data
            })
    }
    async  getUser(User: UserRequest): Promise<UserResponseModel> {
        return await axios.post(this.URL + '/getUser', User)
                .then(res => {
                    return res.data
                })
    }
      async  ActivatedeActivateEmployee(userDto: CreateUserDto): Promise<UserResponseModel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/updateUser', userDto)
                  .then(res => {
                      return res.data
                  })
      }

      async ActivateOrDeactivateUser(userDto: CreateUserDto): Promise<UserResponseModel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/activateOrDeactivateUser', userDto)
                  .then(res => {
                      return res.data
                  })
      }

      async  updateUser(User: CreateUserDto): Promise<UserResponseModel> {
           return await axios.post(this.URL + '/updateUser', User)
                  .then(res => {
                      console.log(res+'res');
                      return res.data
                  })
      }
      
    }


    export default UserService