import { SoStatusEnum } from "@gtpl/shared-models/common-models";
import { ApiProperty } from "@nestjs/swagger";

export class InsuranceDto{
    insurancefileId?: number;
    originalname:string;
    filePath: string;
    fileName: string;
    destination: string;
    saleOrderName?:string;
    saleOrderId:number;
}