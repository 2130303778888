export class CertificatesDto {
    certificateId?: number;
    certificateName: string;
    remarks: string;
    isActive: boolean;
    createdUser?: string;
    updatedUser?: string;
    
    constructor(certificateId:number,certificateName:string,remarks: string,isActive:boolean,createdUser?: string,updatedUser?: string){
        this.certificateId = certificateId;
        this.certificateName = certificateName;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
    }
}

export const CertificatesDtoDefault : CertificatesDto = {
    certificateId: 0,
    certificateName: "",
    remarks:"",
    isActive:true,
};

