import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, Table, Popover, Tag } from 'antd';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import './store-consumption-form.css';
import TextArea from 'antd/lib/input/TextArea';
import { ItemsDetails, SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { StoreConsumptionDetails } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import { EmployeeDto, ItemCategoriesDto, ItemCategoryDropDownDto } from '@gtpl/shared-models/masters';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { StockDTO, StockSoRequest } from '@gtpl/shared-models/warehouse-management';
import { CodeListMainDto, CodeListMainIdRequest } from '@gtpl/shared-models/planning';
import { CodeListMainService } from '@gtpl/shared-services/planning';
const { Option } = Select;


/* eslint-disable-next-line */
export class ItemsPanelProps{
  saleOrderId: number
  saleOrderItemId: number
}
export interface StoreConsumptionDetailsFormProps {
  form: FormInstance<any>
  saleOrderData: SaleOrderDropDownDto[];
  // getItemCategory: (saleOrderId: number) => void;
  getItemsPanel: (props:ItemsPanelProps) => void;
  getSalesPersonData: EmployeeDto[];
  // storeConsumptionData:StoreConsumptionDetails;
}
export function StoreConsumptionDetailsForm(
  props: StoreConsumptionDetailsFormProps
) {
  const form = props.form;
  const [fgStock, setFgStock] = useState<StockDTO[]>();
  const fgService = new FGStockService();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const codeListMainService = new CodeListMainService();
  const saleOrderService = new SaleOrderService();

  const [saleOrderItemsData, setSaleOrderItemsData] = useState<ItemsDetails[]>([])

  const getItemCategories = (value) => {
    console.log(value);
    getSaleOrderItems(value)
    getFgStockInfo(value);
    getCodeListsForId();
  }
  const getItemsForm = () => {
    const saleOrderId = form.getFieldValue('saleOrderId')
    const saleOrderItemId = form.getFieldValue('saleOrderItemId')
    const req = new ItemsPanelProps()
    req.saleOrderId = saleOrderId
    req.saleOrderItemId = saleOrderItemId
    props.getItemsPanel(req)
  }
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{moment(record.date).format('DD-MM-YYYY')}</div>
      }
    },
    {
      title: 'Pouches',
      dataIndex: 'totalPouches',
      key: 'totalPouches',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{record.totalPouches}</div>
      }
    },
    {
      title: 'Loose Pouches',
      dataIndex: 'loosePouches',
      key: 'loosePouches',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{record.loosePouches}</div>
      }
    },
    {
      title: 'Cartons',
      dataIndex: 'totalCartons',
      key: 'totalCartons',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{record.totalCartons}</div>
      }
    },
    {
      title: 'Type',
      dataIndex: 'stockType',
      key: 'stockType',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const content = (
    <div>
      <Table
        dataSource={fgStock}
        columns={columns}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>{'Total'}</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {fgStock?.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.totalPouches);
                }, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {fgStock?.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.loosePouches);
                }, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>{fgStock?.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.totalCartons);
              }, 0)}</Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );

  const getCodeListsForId = () => {
    const req = new CodeListMainIdRequest();
    const saleOrderId = form.getFieldValue('saleOrderId')
    req.saleOrderId = saleOrderId
    codeListMainService.geTracebilityCodeForSoId(req).then(res => {
      if (res.status) {
        console.log(res.data)
        setDataSource(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
          setDataSource([])
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setDataSource([])
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getFgStockInfo = (value) => {
    const req = new StockSoRequest(value);
    fgService.getStockBySoId(req).then((res) => {
      if (res.status) {
        setFgStock(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setFgStock([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFgStock([]);
    });
  }

  const getSaleOrderItems = (reqData) => {
    // console.log("item details service");
    setSaleOrderItemsData([]);
    saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        // console.log(res.data.length);
        setSaleOrderItemsData(res.data);
       
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemsData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData([]);
    })
  }


  return (
    <Card title={<span style={{ color: 'white' }}>Store Consumption Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout="vertical" form={form} name="control-hooks" >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
            <Form.Item name="consumedDate" label="Consumed Date" rules={[{ required: true }]} initialValue={moment()}>
              <DatePicker onChange={() => { }} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item name='soType' label = 'SO Type' >

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: true, message: "SO Number is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO Number"
                onChange={getItemCategories}
                // style={{ width: '100%' }}

                // onClear={clearCustomerDependentData}
                allowClear
              >
                {props.saleOrderData.map(dropData => {
                  return <Option value={dropData.saleOrderId}>{`${dropData.saleOrderNumber} - ${dropData.poNumber}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
            <Form.Item name="saleOrderItemId" label="SO Item" rules={[{ required: true, message: "SO Item is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO Items"
                // style={{ width: '100%' }}
                onSelect={getItemsForm}
                // onClear={clearCustomerDependentData}
                allowClear
              >
                {saleOrderItemsData.map(dropData => {
                        return <Option value={dropData.saleOrderItemId}>{dropData.varient}</Option>
                      })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span:6 }} lg={{ span:6 }} xl={{ span:6 }}>
                  <Form.Item
                    name={"tracebilityCodeId"}
                    label="Tracebility Code"
                    rules={[
                      {
                        required: false,

                      },
                    ]}
                  >
                   <Select
                  showSearch
                  placeholder="Select Tracebility code"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Tracebility Code</Option>
                  {dataSource?.map((item) => {
                    return <Option key={item.tracebilityCodeId} value={item.tracebilityCodeId}>{item.tracebilityCode}</Option>
                  })}
                </Select>
                  </Form.Item>
                </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="indentDate" label="Indent Date" rules={[{ required: true }]} initialValue={moment()}>
              <DatePicker onChange={() => { }} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="indentNo" label="Indent Number" rules={[{ required: true ,pattern: /^[\d]{0,9}$/, message:'Enter Correct value'}]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
            <Form.Item name="salePerson" label="Sale Person" rules={[{ required: false }]}>
              <Select
                placeholder="Select SalePerson"
                // onChange={getItemCategories}
                // onClear={clearCustomerDependentData}
                allowClear
              >
                {props.getSalesPersonData.map(dropData => {
                  return <Option value={dropData.employeeId}>{dropData.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {
            fgStock?.length ? <Popover content={content} title="FG Stock">
              <Tag color={'geekblue'}>FG Stock</Tag>
            </Popover> : ''
          }
        </Row>
      </Form>
      <br />
      <br />
    </Card>
  );
}

export default StoreConsumptionDetailsForm;