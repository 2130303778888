import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { IndentQtyModel } from './indent-quantity.model';
import { IndentModel } from './indent.model';

export class IndentQtyResponse extends GlobalResponseObject{
    data?: IndentQtyModel;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: IndentQtyModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 