export class RouteRequest {
    routeId:number;
    isActive:boolean;
    updatedUser:string;
    versionFlag:number;


    /**
     * 
     * @param routeId 
     * @param isActive 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(routeId:number,isActive:boolean,updatedUser:string,versionFlag:number){
        this.routeId = routeId;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;

    }
}