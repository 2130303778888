

export class AssetsCatagoryByIdReq {
    assetsCatagoryId?: number;
    unitId?: number;
    locationId?:number;
    /**
     *
     * @param assetsCatagoryId
     * @param unitId
     * @param locationId
     * 
     */
    constructor(
        assetsCatagoryId?: number,
        unitId?: number,
        locationId?:number

    ) {
        this.assetsCatagoryId = assetsCatagoryId;
        this.unitId = unitId;
        this.locationId = locationId;
    }
}
