
export class EmpCodeShiftRequest {
    employeeCodes: string[];
    shiftCode: string;
    fromDate: Date;
    toDate: Date;

    /**
     * 
     * @param employeeCode 
     * @param shiftCode 
     */
    constructor(employeeCodes: string[], shiftCode: string, fromDate: Date, toDate: Date) {
        this.employeeCodes = employeeCodes,
            this.shiftCode = shiftCode,
            this.fromDate = fromDate,
            this.toDate = toDate;

    }
}