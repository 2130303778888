import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class LogDetailsResponseModelDto{
    productionLogId: number;
    lotNumber: string;
    subLotNumber: string;
    saleOrderId: number;
    saleOrderNo: string;
    operation: OperationTypeEnum;
    nextOperation: OperationTypeEnum;
    issuedQuantity: number;
    inputGrade: number;
    soakingTime: string;
    soakingStyle: string;
    assignedTime: string;
    workStationId: number;
    workStation: string;
    saleOrderItemId?: number;
    
    constructor(
        productionLogId: number,
        lotNumber: string,
        subLotNumber: string,
        saleOrderId: number,
        saleOrderNo: string,
        operation: OperationTypeEnum,
        nextOperation: OperationTypeEnum,
        issuedQuantity: number,
        inputGrade: number,
        soakingTime: string,
        soakingStyle: string,
        assignedTime: string,
        workStationId: number,
        workStation: string,
        saleOrderItemId?: number,){
            this.productionLogId= productionLogId,
            this.lotNumber= lotNumber,
            this.subLotNumber= subLotNumber,
            this.saleOrderId= saleOrderId,
            this.saleOrderNo= saleOrderNo,
            this.operation= operation,
            this.nextOperation= nextOperation,
            this.issuedQuantity= issuedQuantity,
            this.inputGrade= inputGrade,
            this.soakingTime= soakingTime,
            this.soakingStyle= soakingStyle,
            this.assignedTime= assignedTime,
            this.workStationId= workStationId,
            this.workStation= workStation,
            this.saleOrderItemId= saleOrderItemId
    }
}