import { PackingMethodDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllPackingMehodsResponseModel extends GlobalResponseObject {
    data?: PackingMethodDto[];

    /**
     * 
     * @param status /boolean
     * @param errorCode /number
     * @param internalMessage /string
     * @param data /PackingMethodDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: PackingMethodDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 