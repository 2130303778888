import React, { useEffect, useState } from 'react';
import './rm-reserve-location.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantZone, RackDashboard, WarehouseDashboard, WarehouseResponseData } from '@gtpl/shared-models/warehouse-management';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import { Card, Form, Row, Col, Select, Button, InputNumber, Collapse, Tag, Tooltip, Descriptions } from 'antd';
import { ItemSubCategoriesDto, MasterBrandsDropDownDto, RackDropDownDto, RmRackDetails, RmRackDropDownDto, RmZonesDropDownDto, SkuDropDownDataResponseModel, ZoneDto, RmOccupancyEnum, SkuDto } from '@gtpl/shared-models/masters';
import {EndCustomersService, MasterBrandService, ProductService, RackService, SizesService, SkuService, RmZonesService,HrmsEmpDetService, RackPositionService, ItemSubCategoryService, RmRackService, RmRackPositionService} from '@gtpl/shared-services/masters';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'
import { PONumbersDropDownDto } from 'libs/shared-models/sale-management/src/lib/sale-order/po-number-dropdown.dto';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { RackTypeEnum } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
const { Option } = Select;
const { Panel } = Collapse;

export interface RmReserveLocationProps {}

export function RmReserveLocation(
  props: RmReserveLocationProps
) {
  const [form] = Form.useForm();
  const [racks,setRacks] = useState<WarehouseDashboard[]>([]);   
  const [visible,setVisible] = useState<boolean>(false);   
  const rmRackPositionService = new RmRackPositionService();
  const [zoneDropDown, setZoneDropDown] = useState<RmZonesDropDownDto[]>([]);
  const [brandsDropDown, setBrandsDropDown] = useState<MasterBrandsDropDownDto[]>([]);
  const [itemSubCategoryDropDown, setItemSubCategoryDropDown] = useState<ItemSubCategoriesDto[]>([]);
  const [rackDropDown, setRackDropDown] = useState<RmRackDropDownDto[]>([]);
  const [varientsDropDown, setVarientsDropDown] = useState<SkuDto[]>([]);
  const [poNumbers, setPoNumbers] = useState<SaleOrderDropDownDto[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [zoneId, setZoneId] = useState<number>(undefined);
  const [reservedForDummy, setReservedForDummy] = useState<boolean>(true);
  const [showMandatory, setShowMandatory] = useState<boolean>(true);
  const [dummyVisible, setDummyVisible] = useState<boolean>(false);
  const [soVisible, SetSoVisible] = useState<boolean>(false);
  const rmZonesService = new RmZonesService();
  const masterBrandService = new MasterBrandService();
  const itemSubCategoryService = new ItemSubCategoryService();
  const rmRackService = new RmRackService();
  const skuService = new SkuService();
  const soService = new SaleOrderService();

  useEffect(() => {
    getZones();
    getItemSubCategoryDropDown();
    getRacks();
    getBrands();
}, []);


const saveData = (values: any, data: any) => {
  setBtnDisable(true)
  console.log(data.poNo)
  if(data.itemCategoryName != undefined){
    AlertMessages.getWarningMessage("You cannot reserve Reserved rack. ");
    setBtnDisable(false)
  }
  else{
      console.log(form.getFieldsValue())
      console.log(values);
      console.log(data);
      let flag = true;
      if(data.isReserved){
        setShowMandatory(false)
      }
      else{
        setShowMandatory(true)
      }
    form.validateFields().then(rec => {
      console.log(rec)
        data.isReserved = data.isReserved?false:true;
        rmRackPositionService.getReserveRackPosition({plantId:Number(localStorage.getItem("unit_id")),zoneId:rec.coldStorageId,rackId:rec.rack,rackPositionId:data.rackPositionId,isReserved: data.isReserved,updatedUser:localStorage.getItem('username'),itemSubCategoryId:rec.itemcategory,brandId:rec.brand}).then((res) => {
          if(res.status){
            AlertMessages.getSuccessMessage(res.internalMessage);
            setBtnDisable(false)
            setShowMandatory(true)
            getZoneLevelRacks(rec.coldStorageId,rec.rack);
          } else {
            if (res.intlCode) {
              setBtnDisable(false)
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              setBtnDisable(false)
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
          // form.setFieldsValue({saleOrderId:undefined});  
          // form.setFieldsValue({variant:undefined});  
        }).catch((err) => {
        setBtnDisable(false)
        AlertMessages.getErrorMessage(err.message);
      });
  })
  }
};

const getItemSubCategoryDropDown = () => {
  itemSubCategoryService.getAll().then((res) => {
    if(res.status){
      setItemSubCategoryDropDown(res.data);
    }else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setItemSubCategoryDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setItemSubCategoryDropDown([]);
  });
}
const getBrands = () => {
  masterBrandService.getAllMasterBrandsDropDown().then((res) => {
    if (res.status) {
      console.log(res.data);
      setBrandsDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setBrandsDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setBrandsDropDown([]);
  });
}
const getZones = () => {
  rmZonesService.getPlantWiseZones({unitcodeId:Number(localStorage.getItem('unit_id'))}).then((res) => {
    if (res.status) {
      console.log(res.data);
      setZoneDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setZoneDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setZoneDropDown([]);
  });
}
const getRacks = () => {
  rmRackService.getRmRacksForZoneId({zoneId:form.getFieldValue('coldStorageId'),rackId:0}).then((res) => {
    if (res.status) {
      console.log(res.data);
      setRackDropDown(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setRackDropDown([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setRackDropDown([]);
  });
}

const handleColdStorage = (value) => {
  console.log(value);
  setZoneId(value);
  getRacks()
}
const handleRack = (value) => {
  console.log(value);
  getZoneLevelRacks(zoneId,value)
}
const getZoneLevelRacks = (zoneId:number, rackId:number) => {
  // stockInService.getRackDetails({plantId:Number(localStorage.getItem("unit_id")),zoneId:zoneId,rackId:rackId}).then((res) => {
    rmRackPositionService.getWarehouseRackDetails({plantId:Number(localStorage.getItem("unit_id")),zoneId:zoneId,rackId:rackId}).then((res) => {
    if (res.status) {
      setRacks(res.data);
      setVisible(true);
    } else {
      if (res) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  })
  .catch((err) => {
    AlertMessages.getErrorMessage(err.message);
  });
}
const displayTooltip = (props:any,rackName:string) => {
  console.log(props);
  return (
      <>
          <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      Item Sub Category:&nbsp;&nbsp;{props.itemSubCategoryName}<br/>
                      Brand Name:&nbsp;&nbsp;{props.masterBrandName}
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
      </>
  )
}
const displayRackNameTooltip = (props:any,rackName:string, type:string) => {
  return (
      <>
      {type==="PoNo"?
          <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      PO No:&nbsp;&nbsp;{props.poNumber}<br/>
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
          :type==="Varient"?
          <Descriptions layout="horizontal" size="default" contentStyle={{backgroundColor:"white", padding:'3px', fontSize:'12px'}}>
              <Descriptions.Item>
                  <strong>
                      Rack Name:&nbsp;&nbsp;{rackName}<br/>
                      Varient:&nbsp;&nbsp;{props.variantCode}<br/>
                  </strong>                    
              </Descriptions.Item>
          </Descriptions>
          :""}
      </>
  )
}
const renderData = (racks) => {
  const mainTr = [];
  racks.forEach(rackObj => {
    console.log(rackObj);
    let rackTds = [];
    // rackTds.push(<td className={'ta-b'} rowSpan={rackObj.rackPositions.length * 2}> Rack - {rackObj.rackName}</td>); // A 
    rackObj.rackPositions.forEach(rackPosition => { // 1
      console.log("rackPosition");
      console.log(rackPosition.rackDetails);
      (rackPosition.rackDetails[1].locations).length > 0 ?
      rackTds.push(<td className={'ta-b'} rowSpan={rackPosition.rackDetails.length} style={{width:'20px',left:'0px'}}><b>{rackObj.rackName + rackPosition.rackLevel}</b></td>) :null;
      rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
        // rackTds.push(<td className={'ta-b'}><b>{rackTypeObj.rackType}</b></td>)
        rackTypeObj.locations.forEach(childRackPostion => {
          console.log(childRackPostion);
          let bgColor;
          let name;
          let tooltipType;
          if(childRackPostion.isActive){
            if(childRackPostion.occupancy === RmOccupancyEnum.PARTIALLY_OCCUPIED){
                bgColor="#f7f871";
              name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
              console.log("testt"+childRackPostion.poNo);
              console.log("testt"+childRackPostion.poNumber);
              tooltipType = "poNo";
            }
            else if(childRackPostion.occupancy === RmOccupancyEnum.OCCUPIED){
              bgColor="#52c41a8c";
              name = childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):"";
              tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
            }
            else if(childRackPostion.occupancy === RmOccupancyEnum.RESERVED){
              bgColor="#c1dffa";
              name = childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):"";
              tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
            }
            else{
              bgColor="white";
              name = "";
              tooltipType = null;
            }
          }
          else{
            bgColor="grey";
            name = "";
            tooltipType = "inActive";
          }
          {console.log(rackTypeObj.rackType)}
          {console.log(childRackPostion.rackLevel)}

                
          {
                !childRackPostion.isActive ?
                rackTds.push(<Tooltip title={<Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        FORKLIFT WAY
                    </strong>                    
                </Descriptions.Item>
            </Descriptions>}>
                <td className={'ta-b'} style={{backgroundColor:bgColor}}></td></Tooltip>)
                :childRackPostion.occupancy===RmOccupancyEnum.OCCUPIED?rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}}>{childRackPostion.poNumber}</td></Tooltip>)
                :childRackPostion.occupancy===RmOccupancyEnum.PARTIALLY_OCCUPIED?rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}}>{childRackPostion.poNumber}</td></Tooltip>)
                :childRackPostion.occupancy===RmOccupancyEnum.RESERVED?rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}>
                <td className={'ta-b'} style={{backgroundColor:bgColor,color:'black' , fontSize:'12px'}} onClick={((e) => saveData(e, childRackPostion))}>{childRackPostion.poNumber}</td></Tooltip>)
                :childRackPostion.occupancy===RmOccupancyEnum.NOT_OCCUPIED?rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor,color:'black'}} onClick={((e) => saveData(e, childRackPostion))}><Button title={childRackPostion.rackCode} block disabled={btnDisable} style={{color:'white'}} >''</Button>
                  </td>):""
            // (tooltipType===null?
            // rackTds.push(<td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td>)
            // :rackTds.push(<Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode)}><td className={'ta-b'} style={{backgroundColor:bgColor}}>{name}</td></Tooltip>))
          }
        });
        mainTr.push(<tr>{rackTds}</tr>);
         rackTds = [];
      });
    });
  });
  return mainTr;
}


const eachRackDisplay = (racks:WarehouseDashboard[]) => {
  return (
    <>
    {
      <div id="table-wrapper">
        <div id="table-scroll" style={{overflow:'scroll'}}>
          <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
              <tr style={{backgroundColor:'#f3b69f'}}>
                <th style={{width:'68px'}}>LEVELS</th>
                {
                  
                  // racks[0]?.rackType === RackTypeEnum.Corner ? 
                  Array.from(Array(racks[0]?.colCount), (e, i) => {
                    return <th className={'ta-b'} style={{width:'55px'}}>{Number(racks[0]?.colCount)-i}</th>
                  })
                  // :racks[0]?.rackType === RackTypeEnum.Center ? 
                  // Array.from(Array(racks[0]?.colCount), (e, i) => {
                  //   if(Number(racks[0]?.colCount)-2-i === 13){
                  //     return <><th className={'ta-b'} style={{ width: '55px', color:'white' }}>{27}</th><th className={'ta-b'} style={{ width: '55px', color:'white' }}>{26}</th><th className={'ta-b'} style={{ width: '55px' }}>{Number(racks[0]?.colCount)-2-i}</th></>
                  //   }
                  //   else if(i<25){
                  //     console.log("test" + racks[0]?.colCount + "test"+ i)
                  //     return <th className={'ta-b'} style={{width:'55px'}}>{Number(racks[0]?.colCount)-2-i}</th>
                  //   }
                  // })
                  // :""
                }
              </tr>
                {renderData(racks)}
          </table>
        </div>
      </div>
    }

  </>
)
}

  
function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

  return (
      <Card
      title={<span style={{ color: 'white' }}>Reserve Pallets</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form layout={'vertical'} form={form} name="control-hooks">
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="coldStorageId" label="Warehouse"
              rules={[
                {
                  required: true, message: 'Missing Warehouse',
                },
              ]}>
                {/* <Input /> */}
              <Select
                  placeholder="Select Warehouse"
                  onChange={handleColdStorage}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {zoneDropDown?.map(zonedata => {
                    return <Option key={zonedata.zoneId} value={zonedata.zoneId}>{zonedata.zoneName}</Option>
                  })}
                </Select>
              </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="rack"
                label="Rack"
                rules={[
                  {
                    required: true, message: 'Missing Rack',
                  },
                ]}
              >
                <Select
                  placeholder="Select Rack"
                  onChange={handleRack}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {rackDropDown?.map(rack => {
                    return <Option key={rack.rmRackId} value={rack.rmRackId}>{rack.rmRackCode}</Option>;
                  })}
                </Select>
              </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
                name="itemcategory"
                label="Item Category"
                rules={[
                  {
                    required: showMandatory, message: 'Missing Item Category',
                  },
                ]}
              >
                <Select
                  placeholder="Select Item Category"
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {itemSubCategoryDropDown?.map(res => {
                    return <Option key={res.itemSubCategoryId} value={res.itemSubCategoryId}>{res.itemSubCategory}</Option>
                  })}
                </Select>
            </Form.Item>
          </Col>
          
            <Col span={6}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[
                  {
                    required: showMandatory, message: 'Missing Brand',
                  },
                ]}
              >
                <Select
                  placeholder="Select Brand"
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {brandsDropDown.map(res => {
                    return <Option value={res.masterBrandId}>{res.masterBrandName}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
        </Row>
      </Form>
      {visible && racks[0].rackPositions.length>0?
      <Card title={<span style={{ color: 'white' }}>Rack Details</span>}
      style={{ textAlign: 'center'}}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}> 
        <Tag style={{backgroundColor:'#52c41a8c', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}> Reserved & Occupied </Tag>  &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'#c1dffa', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Reserved</Tag> &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'#f7f871', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Partially Occupied</Tag> &nbsp;&nbsp;&nbsp;
        <Tag style={{backgroundColor:'white', color:'black', textAlign:'center',fontSize:'15px',fontWeight:'bold', width:'22%'}}>Not Reserved</Tag> &nbsp;&nbsp;&nbsp;
        <br/>
        <br/>
        <Collapse defaultActiveKey={[0]} accordion>
          {eachRackDisplay(racks)}
        </Collapse>
      </Card>
      :<Card title={<span style={{ color: 'white' }}>Rack Details</span>}
      style={{ textAlign: 'center'}}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}> No Racks found. </Card>}
      </Card>
  );
}

export default RmReserveLocation;
