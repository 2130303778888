import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Card, Cascader, Col, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Space, Switch, Tag, TreeSelect } from 'antd';
import './rm-vehicle-inspection.css';
import moment from 'moment';
import { GrnData, GrnVehicleInfo } from '@gtpl/shared-models/raw-material-procurement';
import { RMGrnService, RmVehicleInspectionService } from '@gtpl/shared-services/raw-material-procurement';
import { ApprovalInput, ConditionInput, DamageConditionInput, SignOfContaminationInput, InfestationInput } from '@gtpl/shared-models/common-models';
import TextArea from 'antd/lib/input/TextArea';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
/* eslint-disable-next-line */
export interface RmVehicleInspectionProps {
  grnInfo: any;
  handleCancel: () => void;
}

export function RmVehicleInspection(
  props: RmVehicleInspectionProps
) {
  const [vehicleInspection] = Form.useForm<GrnVehicleInfo>();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [rmInfo, setRmInfo] = useState<GrnVehicleInfo>();
  const rmGnService = new RMGrnService();
  const viService = new RmVehicleInspectionService();
  const plainOptions = ['Satisfactory', 'Un Satisfactory'];
  const [certification, setCertification] = useState<boolean>(false);
  useEffect(() => {
    getGrnInfoByItemId();
  }, [])

  const getGrnInfoByItemId = () => {
    if (props.grnInfo.grnItemId) {
      rmGnService.getGrnInfoByItemId({ grnItemId: props.grnInfo.grnItemId }).then(res => {
        console.log(res)
        if (res.status) {
          setRmInfo(res.data);
          vehicleInspection.setFieldsValue({
            vehicleNumber: res.data?.vehicleNumber,
            varietyOfMaterial: res.data?.varietyOfMaterial,
            supplierAddress: res.data?.supplierAddress,
            vehicleId: res.data.vehicleId?res.data.vehicleId:0,
            productId: res.data?.productId,
            sealNumber: res.data?.sealNumber,
            rmGrnItemId: props.grnInfo.grnItemId
          })
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }

  const saveRmInspection = () => {

    vehicleInspection.validateFields().then(rmVehicleIns => {
      rmVehicleIns.plantId = Number(localStorage.getItem('unit_id'));
      rmVehicleIns.createdUser = localStorage.getItem('createdUser');
      rmVehicleIns.updatedUser = localStorage.getItem('createdUser');
      rmVehicleIns.sealNumber=props.grnInfo.sealNumber?props.grnInfo.sealNumber:''
      rmVehicleIns.vehicleId=props.grnInfo.vehicleId?props.grnInfo.vehicleId:0
      viService.createVehicleInspectionInfo(rmVehicleIns).then(res => {
        if (res.status) {
          props.handleCancel();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    });
  }

  const certificationChange = (value) => {
    if (value) {

    }
  }

  return (
    <Card size="small" title={<span style={{ color: 'white' }} >Raw Material Vehicle Inspection</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Row gutter={24}>
        <Descriptions>
          <Descriptions.Item label="Vehicle Number">
            {rmInfo?.vehicleNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Product">
            {rmInfo?.varietyOfMaterial}
          </Descriptions.Item>
          <Descriptions.Item label="Pond">
            {rmInfo?.supplierCode}
          </Descriptions.Item>
          <Descriptions.Item label="Batch Number">
            {props.grnInfo.lotNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Supplier/Address">
            {rmInfo?.supplierAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Seal No">
            {rmInfo?.sealNumber?rmInfo.sealNumber:'null'}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Form
        layout="vertical"
        form={vehicleInspection}
      >
        <Row gutter={24}>
          <Form.Item name={'supplierId'} initialValue={props.grnInfo.farmer}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'rmGrnId'} initialValue={props.grnInfo.grnId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'rmGrnItemId'} initialValue={props.grnInfo.grnItemId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'indentId'} initialValue={props.grnInfo.indentId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'vehicleId'} initialValue ={props.grnInfo.vehicleId?props.grnInfo.vehicleId:0}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'productId'}>
            <Input hidden />
          </Form.Item>

          <Form.Item name={'sealNumber'} initialValue={props.grnInfo?.sealNumber?props.grnInfo.sealNumber:'0'}>
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"vehicleInTime"}
              label="Date"
              rules={[
                {
                  required: true,
                  message: `Missing Date`,
                },
              ]}
              initialValue={moment()}
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Icing Condition Of Material" name={'icingConditionOfMaterial'} initialValue={ConditionInput[0].value} rules={[
              {
                required: true,
                message: `Missing Icing Condition Of Material`,
              },
            ]}>
              <AutoComplete
                style={{ width: "100%" }}
                options={ConditionInput}
                placeholder="Please Enter"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item label="Crates Condition (Damage If any)" name={'cratesCondition'} initialValue={DamageConditionInput[0].value} rules={[
              {
                required: true,
                message: `Missing Crates Condition (Damage If any)`,
              },
            ]}>
              <AutoComplete
                style={{ width: "100%" }}
                options={DamageConditionInput}
                placeholder="Please Enter"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Cleanliness Of Crates" name={'cleanlinessOfCrates'} initialValue={ApprovalInput[0].value} rules={[
              {
                required: true,
                message: `Missing Cleanliness Of Crates`,
              },
            ]}>
              <Radio.Group options={plainOptions} onChange={certificationChange} value='Satisfactory'  >
                <Space direction="vertical">
                  <Radio value={4}>
                    More...
                    {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label="Vehicle Cleanliness" name={'vehicleCleanliness'} initialValue={ApprovalInput[0].value} rules={[
              {
                required: true,
                message: `Missing Vehicle Cleanliness`,
              },
            ]}>
              <Radio.Group options={plainOptions} onChange={certificationChange} value='Satisfactory'  >
                <Space direction="vertical">
                  <Radio value={4}>
                    More...
                    {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Any Sign Of Infestation" name={'anySignOfInfestation'} initialValue={InfestationInput[0].value} rules={[
              {
                required: true,
                message: `Missing Any Sign Of Infestation`,
              },
            ]}>
              <AutoComplete
                style={{ width: "100%" }}
                options={InfestationInput}
                placeholder="Please Enter"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
            <Form.Item label="Any Sign Of Contamination(with dust/filth)" name={'contaminationDustOrFilth'} initialValue={SignOfContaminationInput[0].value} rules={[
              {
                required: true,
                message: `Missing Any Sign Of Contamination(with dust/filth)`,
              },
            ]}>
              <AutoComplete
                style={{ width: "100%" }}
                options={SignOfContaminationInput}
                placeholder="Please Enter"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
            <Form.Item label="Any Damage to the insulation inside the container" name={'insulationDamage'} initialValue={ApprovalInput[0].value} rules={[
              {
                required: true,
                message: `Missing Any Damage to the insulation inside the container`,
              },
            ]}>
              <Radio.Group options={plainOptions} onChange={certificationChange} value='Satisfactory'  >
                <Space direction="vertical">
                  <Radio value={4}>
                    More...
                    {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Remarks" name={'remarks'} initialValue={ApprovalInput[0].value} rules={[
              {
                required: true,
                message: `Missing Remarks`,
              },
            ]}>
              <Radio.Group options={plainOptions} onChange={certificationChange} value='Satisfactory'  >
                <Space direction="vertical">
                  <Radio value={4}>
                    More...
                    {certification ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
            <Col>
            <h4 style={{ color: 'blue' }}>*Note:&ensp; G -Good, S - Satisfy, N - Nil/absent, SF - Soft & Firm, US - UnSatisfy, NA - Not Applicable, NCC - No Colour Change, Pc - Piece</h4>
            </Col>
            <Col >
            <Button type='primary' onClick={saveRmInspection} block disabled={btnDisable}>
              Submit
            </Button>
            </Col>
          </Row>
      </Form>
    </Card>
  );
}

export default RmVehicleInspection;
