import React, { useEffect, useState } from 'react';
import { Card, Button, Descriptions, Layout, Table, Divider, Popconfirm, Switch, Tag, Rate, Input, Row, Col } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import './lot-detail-view.css';
import { ProductionInventoryService } from '@gtpl/shared-services/production';
import form from 'antd/lib/form';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ProductionLogRequest } from 'libs/shared-models/production-management/src/lib/production-inv/production-log.req';


export interface LotDetailViewProps {
  lotNumber: string,
  // screen: string,
}

export function LotDetailView(
  props: LotDetailViewProps,
) {
  const production = new ProductionInventoryService();
  const [lotInfoData, setLotInfoData] = useState<any[]>([]);
  useEffect(() => {
    getLotInfo();
  }, [])

  const getLotInfo = (onReset?: boolean) => { // const plant = Number(localStorage.getItem('unit_id'))

    const req = new ProductionLogRequest();
    req.lotNumber = props.lotNumber
    production.getLotInfo(req).then((res) => {
      if (res.status) {
        setLotInfoData(res.data);
      } else {
        setLotInfoData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotInfoData([]);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('lotNumber'),
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
    },


    {
      title: 'Input Grade',
      dataIndex: 'inputGrade',
    },
    {
      title: 'Transfer Quantity',
      dataIndex: 'intransitQuantity',
    },
    {
      title: 'Physical Quantity',
      dataIndex: 'physicalQuantity',
    },
    {
      title: 'Output Grade',
      dataIndex: 'outputGrade',
    },

    {
      title: 'Wastage Quantity',
      dataIndex: 'wastageQuantity',
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectedQuantity',
    },
    {
      title: 'Wip Quantity',
      dataIndex: 'wipQuantity',
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
    },

    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
    },

  ];



  return (
    <>
      <Card
        title={<span style={{ color: 'white' }}>LOT DETAIL VIEW</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      >
        <Row>
          <Table
            // rowKey={record => record.downtimeTrackingId}
            columns={columnsSkelton}
            dataSource={lotInfoData}
            size='small'
            scroll={{ y: 400 }}
            bordered />
        </Row>

      </Card></>
  );
}

export default LotDetailView;