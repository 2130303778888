import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './app.css';
import { Form, Input, Button, Checkbox, Card, Avatar, Layout, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import BasicLayout from './layout';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import logo from './logo2.jpg';
import schemax22 from './schemax22.jpg';
import assetcycle from './assetcycle.png';
import { UserService } from '@gtpl/shared-services/attendance'
import { appSettings } from '../../../../../apps/services/config';
import CustomSpinner from "libs/shared-components/custom-spinner/src/lib/custom-spinner";
import { Redirect, useHistory } from "react-router-dom";
import NewLoginComponent from "./new-login-component/new-login-component";

const App = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user_data, setUser] = useState(null);
  const [load, setLoad] = useState(false);
  let history = useHistory();

  axios.interceptors.request.use(request => {
    setLoad(true);
    return request;
  });

  axios.interceptors.response.use(response => {
    setLoad(false);
    return response;
  }, error => {
    setLoad(false);
    throw error;
  });


  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  // console.log(loggedInUser)
  useEffect(() => {

    if (loggedInUser) {
      // setTimeout(() => setLoading(false), 1000)
      setUser(loggedInUser);
    }
  }, []);

  // login the user
  const handleSubmit = async (values) => {
    // e.preventDefault();
    const response = await axios.post(appSettings.ums_url + '/auth/login', values);
    if (response.data.status) {
      // store the user in localStorage
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("username", JSON.stringify(response.data.user_data.username));
      localStorage.setItem("userid", JSON.stringify(response.data.user_data.id));
      localStorage.setItem("createdUser", response.data.user_data.username);
      localStorage.setItem("expiresIn", JSON.stringify(response.data.expiresIn));
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
      localStorage.setItem("role", JSON.stringify(response.data.user_data.employeeRole));
      localStorage.setItem("unit_id", JSON.stringify(response.data.user_data.unitId));

      // set the state of the user
      setUser(response.data);
      window.location.reload();
      AlertMessages.getSuccessMessage('Login Successful');
      history.push('./asset-dashboard')
    } else {
      AlertMessages.getErrorMessage('Invalid Credentials');
    }
  };
  // if there's a user show the message below
  if (loggedInUser) {
    return (
      <>
        <CustomSpinner loading={load} />
        <BasicLayout key={1} user={user_data} username={username} />

      </>
    );
  }

  return (
    <>
      <NewLoginComponent />
    </>

  );
};

export default App;
