import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderItemsDropDownDto } from './sale-order-items-dropdown.dto';

export class SaleOrderItemsForSoDropDownResponseModel extends GlobalResponseObject {
    data?: SaleOrderItemsDropDownDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderItemsDropDownDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

