import axios from 'axios';
import { DeptRequest, DepartmentResponseModel, DepartmentDto, AllDepartmentsResponseModel, DepartmentStatusWiseResponseModel } from '@gtpl/shared-models/masters';
import connection from './connection';

export class DepartmentService {
  URL = connection.DEPLOY_URL + '/departments';

  async createDepartment(department: DepartmentDto): Promise<DepartmentResponseModel> {
    console.log(department);
    return await axios.post(this.URL + '/createDepartment', department)
      .then(res => {
        return res.data
      })
  }

  async updateDepartment(department: DepartmentDto): Promise<DepartmentResponseModel> {
    return await axios.post(this.URL + '/updateDepartment', department)
      .then(res => {
        return res.data
      })
  }

  async getAllDepartments(): Promise<AllDepartmentsResponseModel> {
    return await axios.post(this.URL + '/getAllDepartments')
      .then(res => {
        return res.data
      })
  }

  async ActivateorDeactivateDepartment(departmentDto: DepartmentDto): Promise<AllDepartmentsResponseModel> {
    console.log(departmentDto);
    return await axios.post(this.URL + '/activateorDeactivateDepartment', departmentDto)
      .then(res => {
        return res.data
      })
  }

  async getAllActiveDepartment(): Promise<AllDepartmentsResponseModel> {
    return await axios.post(this.URL + '/getAllActiveDepartments')
      .then(res => {
        return res.data
      })
  }

  async getActiveDepartmentsCount(): Promise<DepartmentStatusWiseResponseModel> {
    return await axios.post(this.URL + '/getActiveDepartmentCount').then(res => {
      return res.data
    });
  }

  async getDeptDataById(deptReq: DeptRequest): Promise<DepartmentResponseModel> {
    return await axios.post(this.URL + '/getDepartmentDataById', deptReq)
      .then(res => {
        console.log(res.data);
        return res.data
      });
  }

  async getDepartmentNameByDeptIds(deptReq: number[]): Promise<AllDepartmentsResponseModel> {
    return await axios.post(this.URL + '/getDepartmentNameByDeptIds', deptReq)
      .then(res => {
        return res.data
      })
  }
}