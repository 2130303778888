import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer} from 'antd';
import {RackEndCustomerMapDto} from '@gtpl/shared-models/masters';
import {RackEndCustomerMapService} from '@gtpl/shared-services/masters';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {RackEndcustomerMappingForm} from '@gtpl/pages/warehouse-masters/warehouse-master-component/rack-endcustomer-mapping-form';

import './pages-warehouse-masters-warehouse-master-component-rack-endcustomer-mapping-grid.css';
import {Link} from 'react-router-dom';

/* eslint-disable-next-line */
export interface RackEndcustomerMappingGridProps {}

export function RackEndcustomerMappingGrid(
  props: RackEndcustomerMappingGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [Data, setData] = useState<RackEndCustomerMapDto[]>([]);
  const [selectedData, setSelectedData] = useState<any>(undefined);
  const rackEndCustomerService= new RackEndCustomerMapService();

  useEffect(()=>{
    getAllRackZone();
  }, []);

  /**
   * 
   */
  const getAllRackZone= () =>{
    rackEndCustomerService.getAllRackZone().then(res =>{
      if(res.status){
        setData(res.data);
      }else{
        if(res.intlCode){
          setData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param Data
   */
  const deleteData=(Data:RackEndCustomerMapDto) => {
    Data.isActive = Data.isActive?false : true;
    rackEndCustomerService.activateorDeactivateData(Data).then(res => {
      console.log(res);
      if(res.status)
      {
        getAllRackZone();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if(res.intlCode){
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err =>{
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
   * 
   * @param variantData
   */
  const updateRackZone = (Data: RackEndCustomerMapDto) =>{
    rackEndCustomerService.updateRackZone(Data).then(res => {console.log(res);
    if(res.status){
      AlertMessages.getSuccessMessage("Updated Successfully");
      getAllRackZone();
      setDrawerVisible(false);
    }else{
      if(res.intlCode){
        AlertMessages.getErrorMessage(res.internalMessage);
      }else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err =>{
    AlertMessages.getErrorMessage(err.message);
  })
  }
  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) =>({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding : 8}}>
        <Input 
         ref ={searchInput}
         placeholder={`Search ${dataIndex}`}
         value={selectedKeys[0]}
         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
         style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button 
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon = {<SearchOutlined />}
          size = 'small'
          style = {{width: 90, marginRight: 8}}
        >
          Search 
        </Button>
        <Button onClick = {()=> handleReset(clearFilters)} size = "small" style = {{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
    record[dataIndex] 
    ? record[dataIndex] 
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropDownVisibleChange: visible =>{
      if(visible) { setTimeout(() => searchInput.current.select());}
    },
    render: text =>
      text ?(
        searchedColumn === dataIndex ? (
          <Highlighter 
            highlightStyle= {{backgroundColor: '#ffc069', padding: 0}}
            searchWords = {[searchText]}
            autoEscape
            textToHighlight = {text.toString()}
          />
        ): text
      )
      : null
  });

  /**
   * 
   * @param selectedKeys
   * @param confirm
   * @param dataIndex
   */
  function handleSearch(selectedKeys, confirm, dataIndex){
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () =>{
    setDrawerVisible(false);
  }
  
  //To open the form for updation
  const openFormWithData = (viewData: RackEndCustomerMapDto) => {
    setDrawerVisible(true);
    setSelectedData(viewData);
  }
   
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 + (index + 1)
    },
    {
      title: 'Zone',
      dataIndex: 'zoneName',
      sorter: (a, b) => a.zoneName.localeCompare(b.zoneName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('zoneCode'),
    },
    {
      title: 'Rack',
      dataIndex: 'rackName',
      sorter: (a,b) => a.rackName.localeCompare(b.rackName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rackCode'),
    },
    {
      title: 'End Customer',
      dataIndex: 'endCustomerName',
      sorter: (a,b) => a.endCustomerName.localeCompare(b.endCustomerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerId')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
           <EditOutlined className = {'editSampleTypeIcon'} type="edit"
             onClick={() => {
              if(rowData.isActive){
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You cannot edit deactivated End Customer');
              }
             }}
             style = {{color: '#1890ff', fontSize: '14px'}}
           />
        <Divider type="vertical" />
          <Popconfirm onConfirm={e => {deleteData(rowData);}}
           title = {
             rowData.isActive
              ? 'Are you sure to Deactivate Data ?'
              : 'Are you sure to Activate Data ?'
           }
          >
          <Switch size="default"
           className = {rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
           checkedChildren = {<RightSquareOutlined type="check" />}
           unCheckedChildren = {<RightSquareOutlined type="close" />}
           checked = {rowData.isActive}
          />
          </Popconfirm>
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title= {<span style={{color: 'white'}}>Rack End Customer Mapping</span>}
        style= {{textAlign: 'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra = {<Link to='/rack-endCustomers-mapping-form' ><span style={{color: 'white'}} ><Button className = 'panel_button' > Create </Button></span></Link>} >
        <br></br>
        <Row gutter={40}>
        <Col>
          <Card title={'Total Zones: ' + Data.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
      
          <Col>
             <Card title={'Active: '+ Data.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
             <Card title={'In-Active: '+ Data.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
        </Row>
        <br></br>
        <Table
        rowKey = {record => record.productId}
        columns = {columnsSkelton}
        dataSource = {Data}
        pagination = {{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange = {onChange}
        bordered />
        <Drawer bodyStyle = {{paddingBottom: 80}} title='Update' width={window.innerWidth> 768 ? '50%' : '85%'}
           onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{textAlign: 'center', fontWeight: 500, fontSize: 16}} size='small'>
            <RackEndcustomerMappingForm key={Date.now()}
              updateRackZone={updateRackZone}
              isUpdate={true}
              Data= {selectedData}
              closeForm= {closeDrawer} />
          </Card>
        </Drawer>
    </Card>
  );
}

export default RackEndcustomerMappingGrid;
