export class RmZonesDto {
    rmZoneId?: number;
    rmZoneName: string;
    rmZoneCode: string;
    isActive: boolean;
    createdUser : string;
    plantId?:number;
    plantName?:string;
    
    constructor(rmZoneId: number,rmZoneName: string,rmZoneCode: string,isActive: boolean,createdUser : string,plantId?:number,plantName?:string){ 
            this.rmZoneId = rmZoneId;
            this.rmZoneName = rmZoneName
            this.rmZoneCode=rmZoneCode;
            this.isActive=isActive;
            this.createdUser= createdUser;
            this.plantId=plantId;
            this.plantName=plantName;
        }
    }

export const RmZonesDtoDefault : RmZonesDto = {
     rmZoneId: 0,
    rmZoneName: "",
    rmZoneCode: "",
    isActive: true,
    createdUser : '',
    plantId : 0
};

