export class ReserveRackResponse {
    masterBrandName:string;
    itemSubCategoryName:string;
    zoneCode: string;
    rackPositionId: number;
    rackCode: string;
    occupancy:string;
    poNumber:string;
    brandName: string;
    grade: string;  
    productName: number;
    variantCode: string;
    palletCode: string;  
    packingCompleted:string;
    stockState:string;
    stockType:string;
    zoneId:number;
    rackId:number;
    rackPositionType:string;
    poNo:string;
    isReserved:boolean;
    varientCode:string;
    totalCartons:number;
    palletCapacity:number;
    rackLevel:number;
    rackColumn:number;
    minGrade:number;
    maxGrade:number;
    isActive:boolean;
    pouches?:number;
    pouchWeight?:number;
    constructor(masterBrandName:string,itemSubCategoryName:string,zoneId,number,
      rackId:number,
      zoneCode: string,
      rackPositionId: number,
      rackCode: string,
      occupancy:string,
      poNumber:string,
      brandName: string,
      grade: string,  
      productName: number,
      variantCode: string,
      palletCode: string,  
      packingCompleted:string,
      stockState:string,
      stockType:string,rackPositionType:string,poNo:string,isReserved:boolean,varientCode:string,totalCartons:number,palletCapacity:number,rackLevel:number,rackColumn:number,minGrade:number,maxGrade:number,isActive:boolean,pouches?:number,pouchWeight?:number){
        this.masterBrandName=masterBrandName,
        this.itemSubCategoryName=itemSubCategoryName,
        this.zoneId=zoneId,
        this.rackId=rackId,
        this.zoneCode=zoneCode;
        this.rackPositionId=rackPositionId;
        this.rackCode=rackCode;
        this.occupancy=occupancy;
        this.poNumber=poNumber;
        this.brandName=brandName;
        this.grade=grade;
        this.productName=productName;
        this.variantCode=variantCode;
        this.palletCode=palletCode;
        this.packingCompleted=packingCompleted;
        this.stockState=stockState;
        this.stockType=stockType;
        this.rackPositionType=rackPositionType;
        this.poNo=poNo;
        this.isReserved=isReserved;
        this.varientCode=varientCode;
        this.totalCartons=totalCartons;
        this.palletCapacity=palletCapacity;
        this.rackLevel=rackLevel;
        this.rackColumn=rackColumn;
        this.minGrade=minGrade;
        this.maxGrade=maxGrade;
        this.isActive=isActive;
        this.pouches=pouches;
        this.pouchWeight=pouchWeight;
    }
  }