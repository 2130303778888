export const SaleOrderDetails = 
    {
      'saleOrder':'SAL/ERPX/0001',
      'invDate':'2021-03-17',
      'customer':'cust1',
      'poNo':'PO/ERPX/001',
      'endCustomer':'ECust1',
      'brand':'Brand1',
      'address':'Address1',
      'destinationAddress':'Hyderabad',
      'deliveryTerms':'Handle product with care',
      'paymentTerms':'Should pay before deliver the product',
      'totWeight':'60',
      'totAmount':'7000',
      'styleWeightment' : 'FWNC',
      'certfication':'ACS',
      'soakingTime':'120 mins',
      'soakingStyle':'CP + Salt',
      'brandDesign':'Yes',
      'noOfPouches':'20',
      'noOfCartone':'50',
      'additionalNote':'test',
      'sgsInspection':'test',
      'tuvInspection':'test',
    itemData:
    [
        {
        'itemName':'Item1',
        'minGrade':'10',
        'maxGrade':'20',
        'type':'type1',
        'packingStyle':'test',
        'cases':'10',
        'caseWeight':'20',
        'netWeight':'100',
        'netAmount':'3000',
        },
        {
        'itemName':'Item2',
        'minGrade':'30',
        'maxGrade':'50',
        'type':'type2',
        'packingStyle':'test',
        'cases':'10',
        'caseWeight':'40',
        'netWeight':'300',
        'netAmount':'4000',
        },
    ]
};

