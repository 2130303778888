import { FgDamageLevelEnum } from "./fg-damage-level-enum";
import { DamageTypeEnum } from "./fg-damage-type-enum";

export class FgDamagesDto{
    fgDamageId:number;
    LocationId:number;
    saleOrderId:number;
    damageType:DamageTypeEnum;
    damageLevel:FgDamageLevelEnum
    noOfCartons:number
    damageBy:number
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;   
    unitId:number;
    versionFlag: number;
    damageReason:number;
    saleOrderItemId:number;
    stockId:number;
    constructor(
        fgDamageId:number,
        LocationId:number,
        saleOrderId:number,
        damageType:DamageTypeEnum,
        damageLevel:FgDamageLevelEnum,
        noOfCartons:number,
        damageBy:number,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string, 
        unitId:number,
        versionFlag: number,
        damageReason:number,
        saleOrderItemId:number,
        stockId:number
    ){
      this.fgDamageId=fgDamageId;
      this.LocationId=LocationId;
      this.saleOrderId=saleOrderId;
      this.damageType=damageType;
      this.damageLevel=damageLevel;
      this.noOfCartons=noOfCartons;
      this.damageBy=damageBy;
      this.isActive=isActive;
      this.createdAt=createdAt;
      this.createdUser=createdUser;
      this.updatedAt=updatedAt;
      this.unitId=unitId;
      this.updatedUser=updatedUser;
      this.versionFlag=versionFlag;
      this.damageReason=damageReason;
      this.saleOrderItemId=saleOrderItemId;
      this.stockId=stockId;

    }

}