import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {SearchOutlined,DownloadOutlined} from '@ant-design/icons'

import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import { DownTimeDashboardDateRequest, DownTimeTrackingDto } from "@gtpl/shared-models/masters";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Form, Tooltip,DatePicker, Empty } from "antd";
const {RangePicker} = DatePicker
import form from "antd/lib/form";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
require("highcharts/modules/exporting")(Highcharts);






export interface DownTimePieChartProps {

}

export function DownTimePieChart(props: DownTimePieChartProps) {
  const downtimeservice = new DownTimeTrackingService()
  const [downtimedata, setDowntimedata] = useState<any[]>([]);
  const [selectedDate,setSelectedDate] = useState<any[]>([])

  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [form ]= Form.useForm();
  const chart = useRef(null);


  useEffect(() =>{
    if(selectedEstimatedFromDate != undefined){
    getAllDownTimeTrackings();

    }
  },[selectedEstimatedFromDate])
  useEffect(() => {
    // getAllDownTimeTrackings();
    form.setFieldsValue({
     datePicker:[(moment(moment().format("YYYY-MM-DD")).subtract(1,'months')),moment(moment().format("YYYY-MM-DD"))] 
    
    })
    EstimatedETDDate(form.getFieldValue('datePicker'))
    // setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1,'months')))
    // setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
  
  }, [])



console.log(chart,'chart ref')

  const getAllDownTimeTrackings = () => {
    // const reqObj = new DownTimeDashboardDateRequest();
    //   if (selectedDate.length > 0) {
    //     const selectedFromDate = moment(selectedDate[0]).format('YYYY-MM-DD');
    //     const selectedToDate = moment(selectedDate[1]).format('YYYY-MM-DD');
    //     reqObj.fromDate = (selectedFromDate)
    //     reqObj.toDate = (selectedToDate)

    // }
    downtimeservice.gettrackingGroupbyReason({fromDate:selectedEstimatedFromDate,toDate:selectedEstimatedToDate}).then(res => {
      if (res.status) {
        setDowntimedata(res.data);
      } else {
        if (res.intlCode) {
          setDowntimedata([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setDowntimedata([]);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDowntimedata([]);
    })
  }

  const chartData = downtimedata.map(value => {
    return {
      name: value.reason,
      y: Number(value.percent),
    }
  });

  const config = {

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: 'Reason wise DownTime Analysis'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: 'Reasons',
      colorByPoint: true,
      data: chartData
    }]
  }
  const EstimatedETDDate=(value) =>{
    console.log(value,'value') 
    if (value){
    const consumedFromDate = new Date(value[0].format('YYYY-MM-DD'));
    const consumedToDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedEstimatedFromDate(consumedFromDate)
    setSelectedEstimatedToDate(consumedToDate)}
  }
  return (
    <div>
      <Card  style={{textAlign:'center'}} title={<span className="title" style={{display:'flex'}} > 
      <Form form={form}>
        <Form.Item name='datePicker'>
        <RangePicker onChange={(value) => EstimatedETDDate(value)}/>
        </Form.Item>
        </Form>
        &nbsp;&nbsp;
          <Tooltip title="search">
              <Button type="primary" color="black" shape="circle" icon={<SearchOutlined  />} onClick={ () => {getAllDownTimeTrackings()} }/>
            </Tooltip>
            {/* <Tooltip title="download">
              <Button type="primary" color="black" shape="circle" icon={<DownloadOutlined />} onClick={ () => chart?.exportChart() }/>
            </Tooltip> */}
     
         
         </span>}>
              <div></div>
            
        {
          downtimedata.length ?
          <HighchartsReact highcharts={Highcharts} options={config}  /> : <Empty />
          }
        </Card>
    </div>

  )
}
export default DownTimePieChart;