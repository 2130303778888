
import React, { useEffect, useRef, useState } from 'react';
import {Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Popconfirm, Row, Select, Table, Tabs, Tooltip} from 'antd';
import {BarcodeOutlined,SearchOutlined,UndoOutlined} from '@ant-design/icons';

import { ColumnProps } from 'antd/lib/table';
import { Scanner} from '../../../asset-classification-form/src/lib/Scanner';
import {EmployeeService} from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {AssetLocationMappingService } from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import {AssetLocationMappingDto} from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import {AssetAssignmentService} from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import moment from 'moment';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import Highlighter from 'react-highlight-words';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import{AssetsDto}  from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { Redirect } from "react-router-dom";
import { AssetById } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-id.dto';
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import {ItemSubCategoryService, UnitcodeService} from '@gtpl/shared-services/masters';
import {DepartmentService} from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import { Link } from "react-router-dom";
import { ItemBySubcategory } from "libs/shared-models/masters/src/lib/items/item-by-subcategory";
import { ItemsDto } from '@gtpl/shared-models/masters';
import { ItemsService } from 'libs/shared-services/masters/src/lib/items-service';
import {AssetRequestService} from 'libs/shared-services/asset-management/src/lib/asset-request.service';

const {TabPane} = Tabs;
const {Option} = Select;

/* eslint-disable-next-line */
export interface AssetRequisitionGridProps {
  updateAsset: (assetData: AssetDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function AssetRequisitionGrid(
  props: AssetRequisitionGridProps
) {
  
  const [page,setPage] = useState<number>(1);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<AssetDto[]>([]);
  const [checkInData,setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData,setAssetCodeData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [selectedData,setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService(); 
  const assignmentService = new AssetAssignmentService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState<any>([]);
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [itemdata, setItemData] = useState<any>(null);
  const [item, setItem] = useState<any[]>([]);
  const [code,setCode]=useState(0)
  const [activeTab,setActiveTab] = useState('1');
  const [showpopup, setShowpopup] = useState<boolean>(false);
  const locationService = new AssetLocationService();
  const [locationData,setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName,setPlantName]= useState<any>([]);
  const [itemCategory,setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart,setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const itemService = new ItemsService();
  const service = new AssetRequestService();
  const [requestedAssetData,setRequestedAssetData] = useState<any>([]);
  const [requestCompletedAssetData,setRequestCompletedAssetData] = useState<any>([]);

  let diff = 0;
  let date;
  let todate;

  useEffect(()=>{
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    employeeDetails();
    getItemCategory();
    getRequestedAssets();
    getAllPlants();
    getAssetCode();
    getLocations();
    getDepartments();
  },[])


  const getRequestedAssets = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('assetCode') !== undefined){
      req.assetCode = form.getFieldValue('assetCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }};
    if(form.getFieldValue('location') !== undefined){
      req.location = form.getFieldValue('location')
    }
    if(form.getFieldValue('assetCategory') !== undefined){
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if(form.getFieldValue('department') !== undefined){
      req.department = form.getFieldValue('department')
    }
    if(form.getFieldValue('employeeName') !== undefined){
      req.employeeId = form.getFieldValue('employeeName')
    }    
    service.getRequestedAssetsData(req).then(res => {
      if(res.status) {
        setRequestedAssetData(res.data)
        if(res.data.length >0){
          AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage('Requested Assets Data not found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
    service.getRequestCompletedAssets(req).then(res => {
      if(res.status) {
        setRequestCompletedAssetData(res.data)
        if(res.data.length >0){
          AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage('Request Completed Assets Data not found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }};
    service.getAssetCodeDropDownforAssetRequest(req).then(res => {
      if(res.status){
      setAssetCodeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if(res.status){
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if (res.status) {
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const handleBack = () => {
    setModal(false)
  }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const closeDrawer=()=>{
    setDrawerVisible(false);
    form1.resetFields();
  }

  const Dclose=()=>{
    setShowpopup(false);
    form.resetFields();
  }
  const onCheckIn = (data) => {
    console.log(data,'--------------rowdata')
    const request = new AssetAssignmentDto(null,null,null,data.employeeId,null,null,true,null,null,data.assetName,null,null,null,null,null,null,null,null,null,null,null,data.requestId)
    assignmentService.createCheckIn(request).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage('Created Successfully');
       getRequestedAssets()
       // getCheckIns();
        closeDrawer();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const onCheckout = (rowData) => {
    setData(rowData)
    setShowpopup(true)

  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  const getItems = (id) => {
    console.log(id,'----------category')
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'));
    req.assetCategory = id;
    assetsService.getInstockAssets(req).then((res) => {
      if (res.status) {
        setItem(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };
  
  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then((res) => {
      if (res.status) {
        setItemCategory(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const resetHandler = () => {
    form.resetFields();
    getRequestedAssets()
  }
  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res)=>{
      if(res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const finishHandler = (value) => {
    console.log(value,'------------value')
    getRequestedAssets()
    
  }

  const checkInHandler = (rowData) => {
    console.log(rowData,'---------------')
    getItems(rowData.asset_category)
    setSelectedData(rowData)
    setDrawerVisible(true)
  }
 

  const columns : ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title:'Request Code',
      dataIndex:'request_code',
      width:130,
      align:'left',
      sorter: (a, b) => a.request_code?.localeCompare(b.request_code),
      sortDirections: ['descend', 'ascend'],
       ...getColumnSearchProps('request_code'),
    }, 
    // {
    //   title:'Location',
    //   dataIndex:'location_name',
    //   width:130,
    //   align:'left',
    //   sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('currentLocation'),
    // },
    // {
    //   title:'Asset Mode',
    //   dataIndex:'asset_mode',
    //   width:130,
    //   align:'left',
    //   ...getColumnSearchProps('asset_mode'),
    //   sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
    //    sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title:'Asset Type',
    //   dataIndex:'asset_type',
    //   width:130,
    //   align:'left',
    //   ...getColumnSearchProps('assetType'),
    //   sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
    //    sortDirections: ['descend', 'ascend'],
    // },
    {
      title:'Asset Category',
      dataIndex:'item_sub_category',
      width:130,
      align:'left',
      sorter:(a,b) => a.item_sub_category.localeCompare(b.item_sub_category),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('item_sub_category')
    },
    // {
    //   title:'Asset Name',
    //   dataIndex:'item_name',
    //   width:130,
    //   align:'left',
    //   sorter:(a,b) => a.item_name.localeCompare(b.item_name),
    //   sortDirections:['descend','ascend'],
    //   ...getColumnSearchProps('itemName')
    // },
    // {
    //   title: "Asset Code",
    //   dataIndex: "assets_code",
    //   width:'130',
    //   align:'left',
    //   sorter:(a,b) =>a.assets_code.localeCompare(b.assets_code),
    //   sortDirections:['descend','ascend'],
    //   ...getColumnSearchProps("assets_code")
    // },
    // {
    //   title: "Invoice Number",
    //   dataIndex: "asset_invoice_no",
    //   align:"left",
    //   sorter:(a,b) => a.asset_invoice_no.localeCompare(b.asset_invoice_no),
    //   sortDirections:['descend','ascend'],
    //   ...getColumnSearchProps('asset_invoice_no'),
    //   render:(text,record) => {
    //     return (
    //       <>
    //         {record.asset_invoice_no ? record.asset_invoice_no : '-'}
    //       </>
    //     )
    //   },
    
    // },
    // {
    //   title: "Serial Number",
    //   dataIndex: "asset_serialno",
    //   align:"left",
    //   sorter:(a,b) => a.asset_serialno.localeCompare(b.asset_serialno),
    //   sortDirections:['descend','ascend'],
    //   ...getColumnSearchProps('asset_serialno'),
    //   render:(text,record) => {
    //     return (
    //       <>
    //         {record.asset_serialno ? record.asset_serialno : '-'}
    //       </>
    //     )
    //   },
    // },
  
    
    // {
    //   title:'Asset Condition',
    //   dataIndex:'asset_condition',
    //   width:130,
    //   align:'left',
    //   sorter:(a,b) => a.asset_condition.localeCompare(b.asset_condition),
    //   sortDirections:['descend','ascend'],
    //   ...getColumnSearchProps('asset_condition'),
    // },
    {
      title:'Requested Date',
      dataIndex:'request_time',
      width:130,
      align:'left',
      sorter:(a,b) => a.request_time.localeCompare(b.request_time),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('request_time'),
      render:(text,record) => {
        return (
          <>
            {record.request_time ? moment(record.request_time).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
     
    }, 
    {
      title:'Request Raised By',
      dataIndex:'employee_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.employee_name?.localeCompare(b.employee_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('employee_name'),
    },
    {
      title:'Location Name',
      dataIndex:'location_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.location_name?.localeCompare(b.location_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('location_name'),
      render: (text, record) => {
        return (
          <>
            {record.location_name ?record.location_name : '-'}
          </>
        )
      },
    },
    {
      title:'Department',
      dataIndex:'dept_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.dept_name?.localeCompare(b.dept_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('dept_name'),
    },
    {
      title:'Employee Name',
      dataIndex:'employee_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.employee_name?.localeCompare(b.employee_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('employee_name'),
    },
    {
      title:'Reason',
      dataIndex:'reason',
      width:130,
      align:'left',
      sorter:(a,b) => a.reason.localeCompare(b.reason),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('reason'),
    },
    {
      title:'Action',
      dataIndex: 'action',
      width: 130,
      align:'left',
      render: (text, rowData) => (
        <span>
          
          <Tooltip placement="top" title="Edit">
            <Button
                type="primary"
                shape="round"
                size="small"
              onClick={() => checkInHandler(rowData)}
              >
                CheckIn
              </Button>
          </Tooltip>
        </span>
      ),
    },
  ]

  const columns1 : ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    
    {
      title:'Unit',
      dataIndex:'plant',
      width:130,
      align:'left',
      sorter:(a,b) => a.plant.localeCompare(b.plant),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('plant'),
    }, 
    {
      title:'Location',
      dataIndex:'location_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.location_name.localeCompare(b.location_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('location_name'),
    },
    {
      title:'Asset Mode',
      dataIndex:'asset_mode',
      width:130,
      align:'left',
      ...getColumnSearchProps('asset_mode'),
      sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
       sortDirections: ['descend', 'ascend'],
    },
    {
      title:'Asset Type',
      dataIndex:'asset_type',
      width:130,
      align:'left',
      sorter:(a,b) => a.location_name.localeCompare(b.location_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('location_name'),
    },
    {
      title:'Asset Category',
      dataIndex:'item_sub_category',
      width:130,
      align:'left',
      sorter:(a,b) => a.item_sub_category.localeCompare(b.item_sub_category),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('location_name'),
    },
    {
      title:'Asset Name',
      dataIndex:'item_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.itemName.localeCompare(b.itemName),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('itemName')
    },
    {
      title: "Asset Code",
      dataIndex: "assets_code",
      width:'130',
      align:'left',
      sorter:(a,b) =>a.assets_code.localeCompare(b.assets_code),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('assets_code')
    },
    {
      title: "Invoice Number",
      dataIndex: "asset_invoice_no",
      align:"left",
      sorter:(a,b) => a.asset_invoice_no.localeCompare(b.asset_invoice_no),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('asset_invoice_no'),
      render:(text,record) => {
        return (
          <>
            {record.asset_invoice_no ? record.asset_invoice_no : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Serial Number",
      dataIndex: "asset_serialno",
      align:"left",
      sorter:(a,b) => a.asset_serialno.localeCompare(b.asset_serialno),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('asset_serialno'),
      render:(text,record) => {
        return (
          <>
            {record.asset_serialno ? record.asset_serialno : '-'}
          </>
        )
      },
    },
  
    
    {
      title:'Asset Condition',
      dataIndex:'asset_condition',
      width:130,
      align:'left',
      sorter:(a,b) => a.asset_condition.localeCompare(b.asset_condition),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('asset_condition'),
    },
     {
      title:'Requested Date',
      dataIndex:'request_time',
      width:130,
      align:'left',
      sorter:(a,b) => a.request_time.localeCompare(b.request_time),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('request_time'),
      render:(text,record) => {
        return (
          <>
            {record.request_time ? moment(record.request_time).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title:'Requested By',
      dataIndex:'owner_of_asset',
      width:130,
      align:'left',
      sorter:(a,b) => a.owner_of_asset?.localeCompare(b.owner_of_asset),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('owner_of_asset'),
    },
      
    {
      title:'Location Name',
      dataIndex:'location_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.location_name?.localeCompare(b.location_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('location_name'),
    },
    {
      title:'Department',
      dataIndex:'dept_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.dept_name?.localeCompare(b.dept_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('dept_name'),
    },
    {
      title:'Employee Name',
      dataIndex:'employee_name',
      width:130,
      align:'left',
      sorter:(a,b) => a.employee_name?.localeCompare(b.employee_name),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('employee_name'),
    },
 {
      title:'Reason',
      dataIndex:'reason',
      width:130,
      align:'left',
    },
 
  ]
  return (
    <Card title={<span style={{ color: 'white' }}> Asset Requisition </span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} 
    extra={
      props.isUpdate == true ? (
        ''
      ) : (
        <Link to="/asset-requistion-form">
          <span style={{ color: 'white' }}>
            <Button>Create </Button>{' '}
          </span>
        </Link>
      )
    }>
       <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[24,24]}>
            <Col  xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5}}
            lg={{ span: 5}}
            xl={{ span: 5}}>
              <Form.Item name="assetCode" label="Asset Code">
              <Select 
            suffixIcon={<BarcodeOutlined onClick={(e) => { setShowQrScan(true); setQrModal('assetCode') }}/>}
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetCodeData.map((assets) => {
              return <Option key={assets.assets_code} value={assets.assets_code}>{`${assets.assets_name}--${assets.assets_code}`}</Option>
            })

            }
          </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
              //  defaultValue={'All'}
              placeholder="All Categories"
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All Categories</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
            <Col span={3}>
          <Form.Item label='Unit' name='plantId'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
            showSearch
            allowClear>
              {/* <Option key={'all'} value={''}>All</Option> */}
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Locations'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Locations</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Department' name='department'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Departments'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Departments</Option>
            {depart.map((e) => {
              return<Option key={e.deptId} value={e.deptId}>{e.deptName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={5}>
          <Form.Item label='Employee Name - Code' name='employeeName'>
          <Select
             optionFilterProp="children"
            //  filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            // defaultValue={'All'}
            placeholder='All Employees'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Employees</Option>
              {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
            
          
            <Col style={{marginLeft:'80%'}}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
              </Col>
              <Col style={{marginLeft:'1%'}}>
            <Button 
              icon={<UndoOutlined/>}
              onClick={ resetHandler}>
              Reset
            </Button>
            </Col>
          </Row>
        </Form>
      {/* <Tabs  type={'card'} tabPosition={'top'} onChange={(key) => setActiveTab(key)} activeKey={activeTab}> */}
      <Tabs>
        <TabPane key='1' tab= {'Open: ' + requestedAssetData.length}> 
      <Table columns={columns} dataSource={requestedAssetData} pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{x:1400}}/>
      </TabPane>
      <TabPane key='2' tab= {'Complete: ' + requestCompletedAssetData.length}>
      <Table columns={columns1} dataSource={requestCompletedAssetData} pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{x:1400}}/>
      </TabPane>
      
      </Tabs>
      <Drawer
        bodyStyle={{ paddingBottom: 80 }} title='Check-In ' width={window.innerWidth > 768 ? '65%' : '60%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}
        
      > 
            <Descriptions>
              <DescriptionsItem  label='Employee Name'>{selectedData.employee_name}</DescriptionsItem>
              <DescriptionsItem  label='Department'>{selectedData.dept_name}</DescriptionsItem>
            </Descriptions>
            
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          
        <Form form={form1} 
        onFinish={onCheckIn} >
          <Row gutter={[24,24]}>  
          <Row>
          <Col>
            <Form.Item name='requestId' label='Request Id' style={{display:'none'}} initialValue={selectedData.request_id}>
              <Input disabled defaultValue={selectedData.request_id}/>
            </Form.Item>
            </Col>
            <Col>
            <Form.Item name='employeeId' label='Employee Id' style={{display:'none'}} initialValue={selectedData.employee_id}>
              <Input disabled defaultValue={selectedData.employee_id}/>
            </Form.Item>
            </Col>
          </Row>
          <Col span={9} >
            <Form.Item
              name="assetName"
              label="Asset Name"
              rules={[
                {
                  required: true,
                  message: 'Asset Name is required',
                },
              ]}
            >
              <Select
                placeholder="asset name"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {item.map((items) => {
                  return (
                    <Option key={items.asset_id} value={items.assets_code}>
                      {items.assets_code}--{items.item_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button type="primary"  htmlType="submit" >
              Submit
            </Button>
            {[] && 
            <Button htmlType="button" style={{margin: '0 14px'}} onClick={closeDrawer}>
              Cancel
            </Button>
            } 
          </Col>
        </Row>
        </Form>
        </Card>
      </Drawer>

    </Card>
  );
}
export default AssetRequisitionGrid;
