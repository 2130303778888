import { ApiProperty } from "@nestjs/swagger";
import { IsAlphanumeric, MaxLength, Matches, IsOptional, IsNotEmpty } from "class-validator";

export class WorkCalenderDto{
    id: number;
    employeeCode: string;
    date: Date;
    shift: string;
    shiftCode: string;
    workStationTeam: string;
    inTime:Date;
    outTime:Date;
    attendanceStatus: string;
    leaveCode: string;
    holiday: boolean;
    department: string;
    contractor: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;

    /**
     * 
     * @param id 
     * @param employeeCode 
     * @param date 
     * @param shift 
     * @param shiftCode 
     * @param workStationTeam 
     * @param inTime 
     * @param outTime 
     * @param attendanceStatus 
     * @param leaveCode 
     * @param holiday 
     * @param department 
     * @param contractor 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */

    constructor(id: number,employeeCode: string,date: Date,shift: string,shiftCode: string,workStationTeam: string,
inTime:Date, outTime:Date,attendanceStatus: string,leaveCode: string,holiday: boolean,department: string,contractor: string,
isActive: boolean,createdAt: Date,createdUser: string,updatedAt: Date,updatedUser: string,versionFlag: number){

    this.id = id;
    this.employeeCode = employeeCode;
    this.date = date;
    this.shift = shift;
    this.shiftCode = shiftCode;
    this.workStationTeam = workStationTeam;
    this.inTime = inTime;
    this.outTime = outTime;
    this.attendanceStatus = attendanceStatus;
    this.leaveCode = leaveCode;
    this.holiday = holiday;
    this.department = department;
    this.contractor = contractor;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.createdUser = createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;

    }
}