export class AssetVendorDetailsModel {
    vendorName: string;
    vendorCode: string;
    totalNoOfAssets: number;
    assetsValue: number;
    totalAmcValue: number;
    amcDue: number;
    totalServicesCount: number;
    skippedServicesCount: number;
    noOfDamagedAssets: number;
    thisMonthServicesCount: number;
    thisMonthCompletedServices: number;
  
    constructor(
      vendorName: string,
      vendorCode: string,
      totalNoOfAssets: number,
      assetsValue: number,
      totalAmcValue: number,
      amcDue: number,
      totalServicesCount: number,
      skippedServicesCount: number,
      noOfDamagedAssets: number,
      thisMonthServicesCount: number,
      thisMonthCompletedServices: number
    ) {
      this.vendorName = vendorName;
      this.vendorCode = vendorCode;
      this.totalNoOfAssets = totalNoOfAssets;
      this.assetsValue = assetsValue;
      this.totalAmcValue = totalAmcValue;
      this.amcDue = amcDue;
      this.totalServicesCount = totalServicesCount;
      this.skippedServicesCount = skippedServicesCount;
      this.noOfDamagedAssets = noOfDamagedAssets;
      this.thisMonthServicesCount = thisMonthServicesCount;
      this.thisMonthCompletedServices = thisMonthCompletedServices;
    }
  }
  