import axios from 'axios';
import { HlPercentageDto ,  HlPercentageRequest, HlPercentageStatusWiseResponseModel , HlPercentageResponseModel, AllHlPercentageResponseModel, HlPercentageRangeRequest} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class HLpercentagesService {
    URL = connection.DEPLOY_URL + '/hl-percentages';

    async createHlPercentage(Hlpercentages: HlPercentageDto): Promise<HlPercentageResponseModel> {
      return await axios
        .post(this.URL + '/createHlPercentage', Hlpercentages)
        .then((res) => {
          return res.data;
        });
    }
    async updateGrade(Hlpercentages: HlPercentageDto): Promise<HlPercentageResponseModel> {
      return await axios
        .post(this.URL + '/updateHlPercentage', Hlpercentages)
        .then((res) => {
          return res.data;
        });
    }
    async getAllHlPercentage(req?:UserRequestDto): Promise<AllHlPercentageResponseModel> {
      return await axios.post(this.URL + '/getAllHlPercentage',req)
          .then(res => {
              return res.data
          })
    }
    async activateOrDeactivateHlPercentage(
      hlPercentageDto : HlPercentageDto
    ):  Promise<HlPercentageResponseModel> {
      //   console.log(State.stateCode);
      return await axios
        .post(this.URL + '/activateOrDeactivateHlPercentage', hlPercentageDto)
        .then((res) => {
          return res.data;
        });
    }
    async getAllActiveHlpercentages():  Promise<AllHlPercentageResponseModel> {
      return await axios.post(this.URL + '/getAllActiveHlpercentages').then((res) => {
        return res.data;
      });
    }
    
    async getHlpercentagesById(): Promise<HlPercentageResponseModel> {
        return await axios.post(this.URL + '/getHlpercentagesById', HlPercentageRequest).then(res => {
          console.log(res);
            return res.data
        });
      }

      async getHlPercentageByRange(hlPerReq:HlPercentageRangeRequest): Promise<HlPercentageResponseModel> {
        return await axios.post(this.URL + '/getHlPercentageByRange', hlPerReq).then(res => {
            return res.data
        }).catch(err => {
          throw new Error(err.message);
      })
      }

      

}
