import { POTypeEnum, StatusEnum } from "@gtpl/shared-models/common-models";
import { PoItemsCreateDetails } from "./po-item-create-details.dto";
import { PoItemsDetails } from "./po-items-details";

export class PoReportDto {
    purchaseOrderId:number;
    poType:POTypeEnum;
    soDate:Date;
    purchaseOrderDate:Date;
    expectedDeliveryDate:Date;
    poNumber:string;
    soNumber:string;
    vendorName:string;
    totalAmount:number;
    status?:StatusEnum;
    createdDate?:Date;

    constructor(purchaseOrderId:number, poType:POTypeEnum, soDate:Date, purchaseOrderDate:Date, expectedDeliveryDate:Date, poNumber:string, soNumber:string, vendorName:string, totalAmount:number, status:StatusEnum,createdDate?:Date,){
        this.purchaseOrderId = purchaseOrderId;
        this.poType = poType;
        this.soDate = soDate;
        this.purchaseOrderDate = purchaseOrderDate;
        this.expectedDeliveryDate = expectedDeliveryDate;
        this.poNumber = poNumber;
        this.soNumber = soNumber;
        this.vendorName = vendorName;
        this.totalAmount = totalAmount;
        this.status = status;
        this.createdDate = createdDate;
    }
}