import { VendorsDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class VendorsResponseModel extends GlobalResponseObject{
    data?: VendorsDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: VendorsDto) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
