export class HlPercentageDropDownData{
    hlPercentageId:number;
    minGrade:number;
    maxGrade:number;
    hlPercentage:string;
    
    
    /**
     * 
     * @param hlPercentageId 
     * @param minGrade
     * @param maxGrade
     * @param hlPercentage 
     * 
     */
    constructor(hlPercentageId:number,minGrade:number,maxGrade:number,hlPercentage:string){
      this.hlPercentageId = hlPercentageId;
      this.minGrade = minGrade;
      this.maxGrade = maxGrade;
      this.hlPercentage = hlPercentage;
    }
}