export class ConsumedData {
    unitId: number;
    saleOrder: string;
    itemName: string;
    consumedQty: number;
    returnQty: number;
    unitName: string;
    saleOrderId:number;
    itemId:number;
    consumptionCode: string;
    poNumber?:string;
}