import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoNumbersModel } from './so-number.model';


export class SoNumbersResponseModel extends GlobalResponseObject {
    data?: SoNumbersModel[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoNumbersModel[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

