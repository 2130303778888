import axios from 'axios';
import connection from './connection';
import {IndentRequest, VehicleAssignmentDTO, VehicleAssignmentResponseModel} from '@gtpl/shared-models/transportation'
export class VehicleAssignmentService {
    URL = connection.DEPLOY_URL + '/vehicle-assignment';
    
    async createVehicleAssignment(vehicleAssignmentDTO: VehicleAssignmentDTO): Promise<VehicleAssignmentResponseModel> {
        console.log(vehicleAssignmentDTO);
          return await axios.post(this.URL + '/createVehicleAssignment',vehicleAssignmentDTO)
              .then(res => {
                  return res.data
              })
    }
    async getVehicleAssignDetailsByIndentId(inputData: IndentRequest): Promise<VehicleAssignmentResponseModel> {
          return await axios.post(this.URL + '/getVehicleAssignDetailsByIndentId',inputData)
              .then(res => {
                  return res.data
              })
    }
}