import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { QaQcData } from './qa-qc-data.dto';
import { QaQcDropDown } from './qa-qc-drop-down.dto';

export class QaQcTestsDropDownResponse extends GlobalResponseObject{
    data?: QaQcDropDown[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: QaQcDropDown[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}
