export class EndCustomersDropDownData{
    endcustomerId:number;
    endCustomerCode:string;
    endcustomerName:string;
    endcustomerAddress:string;
    endcustomerbrand:string;
    endcustomerdeliveryadd:string;
    
    /**
     * 
     * @param endcustomerId 
     * @param endcustomerName 
     * @param endCustomerCode 
     * @param endcustomerAddress
     * @param endcustomerbrand
     * @param endcustomerdeliveryadd
     */
    constructor(endcustomerId:number,endcustomerName:string,endCustomerCode:string,endcustomerAddress:string,endcustomerbrand:string,endcustomerdeliveryadd:string){
      this.endcustomerId = endcustomerId;
      this.endCustomerCode = endCustomerCode;
      this.endcustomerName = endcustomerName;
      this.endcustomerAddress= endcustomerAddress;
      this.endcustomerbrand= endcustomerbrand;
      this.endcustomerdeliveryadd= endcustomerdeliveryadd;
    }
}