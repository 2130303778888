import { RouteDto, RouteResponse } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class RouteService {
    URL = connection.DEPLOY_URL + '/route';

    async createRoute(route: RouteDto): Promise<RouteResponse> {
        console.log(route);
        return await axios.post(this.URL + '/createRoute', route)
          .then(res => {
            return res.data
          })
      }

    async updateRoute(route: RouteDto): Promise<RouteResponse> {
        return await axios.post(this.URL + '/updateRoute', route)
          .then(res => {
            return res.data
          })
      }

      async getAllRoute(): Promise<RouteResponse> {
        return await axios.post(this.URL + '/getAllRoute')
          .then(res => {
            return res.data
          })
      }

      async ActivateorDeactivateRoute(routeDto: RouteDto): Promise<RouteResponse> {
        console.log(routeDto);
        return await axios.post(this.URL + '/activateorDeactivateRoute', routeDto)
          .then(res => {
            return res.data
          })
      }

      async getAllActiveRoute(): Promise<RouteResponse> {
        return await axios.post(this.URL + '/getAllActiveRoute')
          .then(res => {
            return res.data
          })
      }
}