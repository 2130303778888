export class SoTranferDto{
    saleOrderId:number;
    fromUnit: number;
    toUnit: number;
    fromProcessingUnit: number;
    toProcessingUnit: number;
    stockConversionStatus?:boolean;
    constructor(saleOrderId:number,fromUnit: number,toUnit: number,fromProcessingUnit: number,toProcessingUnit: number,stockConversionStatus?:boolean){
        this.saleOrderId = saleOrderId;
        this.fromUnit = fromUnit;
        this.toUnit = toUnit;
        this.fromProcessingUnit = fromProcessingUnit;
        this.toProcessingUnit = toProcessingUnit;
        this.toProcessingUnit = toProcessingUnit;
        this.stockConversionStatus = stockConversionStatus;
    }
}