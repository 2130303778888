import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DestinationDropDownData } from './destination-drop-down-model';

export class DestinationDropDownDataResponseModel extends GlobalResponseObject{
    data?: DestinationDropDownData[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //DestinationDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: DestinationDropDownData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}