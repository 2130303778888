import {TypesOfValueAddition2Dto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllTypesOfValueAddition2ResponseModel extends GlobalResponseObject {
    data?:TypesOfValueAddition2Dto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: TypesOfValueAddition2Dto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 