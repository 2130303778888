import axios from 'axios';
import { HsnDto, HsnResponseModel, AllHsnResponseModel, HsnCodeRequest, HsnCodeRequestByVal,} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class HsnService {
  URL = connection.DEPLOY_URL + '/hsn-codes';

  async createHsn(Hsn: HsnDto): Promise<HsnResponseModel> {
    console.log(Hsn);
    return await axios.post(this.URL + '/createHsnCode', Hsn)
      .then(res => {
        return res.data
      })
  }
  async updateHsn(Hsn: HsnDto): Promise<HsnResponseModel> {
    return await axios.post(this.URL + '/updateHsnCode', Hsn)
      .then(res => {
        return res.data
      })
  }
  async getAllHsn(req?:UserRequestDto): Promise<AllHsnResponseModel> {
    return await axios.post(this.URL + '/getAllHsnCodes',req)
      .then(res => {
        return res.data
      })
  }
  async ActivateOrDeactivateHsn(HsnDto: HsnDto): Promise<HsnResponseModel> {
    //   console.log(State.stateCode);
    return await axios.post(this.URL + '/activateOrDeactivateHsnCode', HsnDto)
      .then(res => {
        return res.data
      })
  }
  

  async getActiveHsnCodes(): Promise<AllHsnResponseModel> {
    // return new HsnDropDownDataResponseModel(true, 11, 'itemsData retrived successfully', [new HsnDropDownData(1, 'PDTON'), new HsnDropDownData(2, 'PD')])
    return await axios.post(this.URL + '/getAllActiveHsnCodes')
      .then(res => {
        return res.data
      })
  }

 
  async getHsnDataById(HsnCodeReq:HsnCodeRequest): Promise<HsnResponseModel> {
    return await axios.post(this.URL + '/getHsnDataById', HsnCodeReq).then(res => {
      // console.log(res);
        return res.data
    });
  }

  async getHsnByFoodType(req: HsnCodeRequestByVal): Promise<HsnResponseModel> {
    return await axios.post(this.URL + '/getHsnByFoodType', req).then(res => {
      // console.log(res);
        return res.data
    });
  }

 
  
}


