import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal } from 'antd';
import { LabellingDto } from '@gtpl/shared-models/production-management';
import { LabellingService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LabellingForm } from '@gtpl/pages/qa-qc/qa-qc-components/labelling-form';
import { Link, Redirect } from 'react-router-dom';
import { LabellingDetails } from '@gtpl/page/qa-qc/qa-qc-components/labelling-details'

import './labelling-grid.css';
import moment from 'moment';

/* eslint-disable-next-line */
export interface LabellingGridProps { }

export function LabellingGrid(
  props: LabellingGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [labellingData, setLabellingData] = useState<LabellingDto[]>([]);
  const [selectedInformation, setSelectedInformation] = useState<LabellingDto>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
 
  const labellingService = new LabellingService();

  useEffect(() => {
    getAllLabelling();
  }, []);

  /**
   * 
   */
  const getAllLabelling = () => {

    labellingService.getAllLabellings().then(res => {
      if (res.status) {
        setLabellingData(res.data);
      } else {
        if (res.intlCode) {
          setLabellingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setLabellingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param labellingData 
   */
  const deleteLabelling = (labellingData: LabellingDto) => {
    labellingData.isActive = labellingData.isActive ? false : true;
    labellingService.activateOrDeactivateLabelling(labellingData).then(res => {
      console.log(res);
      if (res.status) {
        getAllLabelling();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
 
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
 
  const displayModel = (data: LabellingDto,) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    getAllLabelling();
    setIsModalVisible(false);
  };


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Labelling Date ',
      dataIndex: 'labellingDate',
      responsive: ['md'],
      // responsive: ['lg'],
      sorter: (a, b) => a.labellingDate.localeCompare(b.labellingDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('labellingDate'),
      render : (value) => moment(value).format('DD-MM-YYYY')
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber?.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber')
    },
    {
      title: 'SO Item Name',
      dataIndex: 'item',
      // responsive: ['lg'],
      sorter: (a, b) => a.item?.localeCompare(b.item),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item')
    },
   
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.remarks.length - b.remarks.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    // {
    //   title: `Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>
    //       {/* <EditOutlined className={'editSamplTypeIcon'} type="edit"
    //         onClick={() => {
    //           if (rowData.isActive) {
    //             openFormWithData(rowData);
    //           } else {
    //             AlertMessages.getErrorMessage('You Cannot Edit Deactivated Labelling');
    //           }
    //         }}
    //         style={{ color: '#1890ff', fontSize: '14px' }}
    //       /> */}

    //       <Divider type="vertical" />
    //       <Popconfirm onConfirm={e => { deleteLabelling(rowData); }}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate Labelling  ?'
    //             : 'Are you sure to Activate Labelling ?'
    //         }
    //       >
    //         <Switch size="default"
    //           className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
    //           checkedChildren={<RightSquareOutlined type="check" />}
    //           unCheckedChildren={<RightSquareOutlined type="close" />}
    //           checked={rowData.isActive}
    //         />

    //       </Popconfirm>
    //     </span>
    //   )
    // },
    {
      title: 'Labelling',
      dataIndex: 'labelling',
      render: (text, rowData: LabellingDto, index) => (
        <span> <Button type="primary" shape="round"  size="small"
          // style={{ color: 'red' }}
          onClick={() => displayModel(rowData)}> Add Samples </Button>
        </span>
      )
    },
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');



  }
  return (
    <Card title={<span style={{ color: 'white' }}>Labelling </span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/labelling-form' ><Button className='panel_button' >Create </Button></Link>}
    >
      <br></br>
      <Row gutter={24} >
        <Col>
          <Card title={'Total Labellings: ' + labellingData.length} style={{ textAlign: 'left', height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Active: ' + labellingData.filter(el => el.isActive).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'In-Active: ' + labellingData.filter(el => el.isActive == false).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col>
      </Row>
      <br></br>
      <Table
        rowKey={record => record.labellingId}
        columns={columnsSkelton}
        dataSource={labellingData}

        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered
        scroll={{ x: true }} />

      <Modal
        className='labelling'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <LabellingForm key={Date.now()}
          labellingData={selectedInformation}
          isUpdate={true}
          closeForm={handleCancel} />
      </Modal>
    </Card>
  );
}

export default LabellingGrid;
