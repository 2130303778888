import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Input, Descriptions, Collapse, Tag, BackTop, Card, Modal } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantZone, RackDashboard, RackLevelResponseDto, RackTypeResponseDto, WarehouseDashboard, WarehouseResponseData } from '@gtpl/shared-models/warehouse-management';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import {ProjectOutlined,AuditOutlined,AppstoreOutlined,PartitionOutlined,CalendarOutlined,DownOutlined,BellFilled,InsertRowLeftOutlined } from '@ant-design/icons'
import { RackPositionService, RackService } from '@gtpl/shared-services/masters';
import { OccupancyEnum, RackDropDownDto, RackStatusRequest } from '@gtpl/shared-models/masters';
import { RackTypeEnum } from '@gtpl/shared-models/common-models';
import StockDetails from './stock-details';
const { Search } = Input;
const { Panel } = Collapse;

export interface Unit1ZoneDisplayProps {
    plantId: number;
    zoneId: number;
    zoneName:string;
}

export function Unit1ZoneDisplay(props: Unit1ZoneDisplayProps) {    
    const [racks,setRacks] = useState<RackDashboard[]>([]);    
    const [searchString,setSearchString] = useState('');
    const [racksData,setRacksData] = useState<RackDropDownDto[]>([]);
    const [rackDashboard,setRackDashboard] = useState<WarehouseDashboard[]>([]);
    const [rackStatus,setRackStatus] = useState<any[]>(undefined);
    const stockInService = new StockInService();
    const rackService = new RackService();
    const rackPositionService = new RackPositionService();
    const [showVisible,setShowVisible] = useState<boolean>(false);
    const [rackInfo,setRackInfo] = useState<number>(0);
    const [R, setR] = React.useState(false)
    const [S, setS] = React.useState(false)
    const [X, setX] = React.useState(false)
    const [Y, setY] = React.useState(false)
    const [A, setA] = React.useState(false)
    const [B, setB] = React.useState(false)
    const [C, setC] = React.useState(false)
    const [RW, setRW] = React.useState(false)
    const [SW, setSW] = React.useState(false)
    const [XW, setXW] = React.useState(false)
    const [YW, setYW] = React.useState(false)
    const [AW, setAW] = React.useState(false)
    const [BW, setBW] = React.useState(false)
    const [CW, setCW] = React.useState(false)
    useEffect(() => {
      getRacksForZoneId()    
      getColdStorageStatus();    
    }, []);

    const getColdStorageStatus = () => {
      rackPositionService.getRackStatus({plantId:props.plantId,zoneId:props.zoneId}).then((res) => {
        if(res.status){
          setRackStatus(res.data)
        }
        else {
          if (res) {
            setRackStatus(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            setRackStatus(undefined);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        setRackStatus(undefined);
        AlertMessages.getErrorMessage(err.message);
    });
    }
    const getRacksForZoneId = () => {
      rackService.getRacksByZone({zoneId:props.zoneId}).then((res) => {
        if(res.status){
          setRacksData(res.data);
        }
        else {
          if (res) {
            setRacksData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            setRacksData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        setRacksData([]);
        AlertMessages.getErrorMessage(err.message);
    });
    }
    
    const displayRackNameTooltip = (props:any,rackName:string, type:string) => {
      return (
          <>
          {type==="PoNo"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                  <Descriptions.Item>
                      <strong>
                          Rack Name:&nbsp;&nbsp;{rackName}<br/>
                          PO No:&nbsp;&nbsp;{props.poNumber}<br/>
                      </strong>                    
                  </Descriptions.Item>
              </Descriptions>
              :type==="Varient"?
              <Descriptions layout="horizontal" size="default" contentStyle={{backgroundColor:"white", padding:'3px', fontSize:'12px'}}>
                  <Descriptions.Item>
                      <strong>
                          Rack Name:&nbsp;&nbsp;{rackName}<br/>
                          Varient:&nbsp;&nbsp;{props.variantCode}<br/>
                      </strong>                    
                  </Descriptions.Item>
              </Descriptions>:""}
          </>
      )
    }
    const displayTooltip = (props:any,rackName:string,type:string) => {
        return (
            <>
            {type === "poNo" ? 
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        PO Number:&nbsp;&nbsp;{props.poNumber}<br/>
                        {/* Brand Name:&nbsp;&nbsp;{props.brandName}<br/>
                        Grade:&nbsp;&nbsp;{props.grade}<br/>
                        Product Name:&nbsp;&nbsp;{props.productName}<br/>
                        Variant Code:&nbsp;&nbsp;{props.variantCode}<br/>
                        Pallet Code:&nbsp;&nbsp;{props.palletCode}<br/>
                        Packing Completed:&nbsp;&nbsp;{props.packingCompleted}<br/> */}
                        {/* Stock State:&nbsp;&nbsp;{props.stockState}<br/> */}
                        Stock Type:&nbsp;&nbsp;{props.stockType} <br/>


                        {/* Pallet Capacity:&nbsp;&nbsp;{props.palletCapacity} <br/> */}
                        Filled Cartons:&nbsp;&nbsp;{props.totalCartons}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="varient"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        Variant Code:&nbsp;&nbsp;{props.variantCode}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="poNumber"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong>
                        Rack Name:&nbsp;&nbsp;{rackName}<br/>
                        PO Number:&nbsp;&nbsp;{props.poNumber}
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :type==="inActive"?
              <Descriptions layout="vertical" size="small" contentStyle={{backgroundColor:"white", padding:'3px'}}>
                <Descriptions.Item>
                    <strong style={{textAlign:'center'}}>
                        FORKLIFT WAY
                    </strong>                    
                </Descriptions.Item>
              </Descriptions>
            :""
            }
            </>
        )
    }

    const closeModal = (closeModal) => {
      if(closeModal){
        setShowVisible(false);
        window.location.reload();
      }
    }
    const handleTransferCancel = () => {
      setShowVisible(false);
    }
    
    const showStockDetails = (data:any) => {
      console.log(data);
      setRackInfo(data);
      setShowVisible(true)
    }
    const renderData = (racks) => {
        // console.log(racks);
      const mainTr = [];
      racks.forEach(rackObj => {
        console.log(rackObj);
        let rackTds = [];
        // rackTds.push(<td className={'ta-b'} rowSpan={rackObj.rackPositions.length * 2}> Rack - {rackObj.rackName}</td>); // A 
        rackObj.rackPositions.forEach(rackPosition => { // 1
            console.log(rackPosition.rackDetails.length);
        //     rackPosition.rackDetails[0].locations.length>0?
        //   rackTds.push(<td className={'ta-b'} rowSpan={rackPosition.rackDetails.length} style={{width:'20px',left:'0px'}}><b>{rackObj.rackName + rackPosition.rackLevel}</b></td>):""
          rackPosition.rackDetails.forEach(rackTypeObj => { // Rare Front
            // rackTds.push(<td className={'ta-b'}><b>{rackTypeObj.rackType}</b></td>)
            rackTypeObj.locations.forEach(childRackPostion => {
              console.log(childRackPostion);
              let bgColor;
              let name;
              let tooltipType;
              if(childRackPostion.isActive){
                if(childRackPostion.occupancy === OccupancyEnum.ASSIGNED){
                  if(childRackPostion.palletCapacity > childRackPostion.totalCartons){
                    bgColor="#f7f871";
                  }
                  else if(childRackPostion.palletCapacity <= childRackPostion.totalCartons){
                    bgColor="#52c41a69";
                  }
                  // name = childRackPostion.poNo?.replace('AZ','')!=null?childRackPostion.poNo?.replace('AZ',''):childRackPostion.poNumber?.replace('AZ','')!=null?childRackPostion.poNumber?.replace('AZ',''):"";
                  name = childRackPostion.rackCode;
                  console.log("testt"+childRackPostion.poNo);
                  console.log("testt"+childRackPostion.poNumber);
                  tooltipType = "poNo";
                }
                else if(childRackPostion.occupancy === OccupancyEnum.OCCUPIED){
                  bgColor="#c1dffa";
                  // name = childRackPostion.poNumber!=null?childRackPostion.poNumber?.replace('AZ',''):childRackPostion.rackCode;
                  name = childRackPostion.rackCode;
                  tooltipType = childRackPostion.poNumber!=null?"poNumber":"varient";
                }
                else{
                  bgColor="white";
                  name = childRackPostion.rackCode;
                  tooltipType = null;
                }
              }
              else{
                  bgColor="grey";
                  name = "";
                  tooltipType = "inActive";
              }
              {console.log(rackTypeObj.rackType)}
              {console.log(childRackPostion.rackLevel)}
              {console.log(childRackPostion)}
              {
                  (tooltipType===null?
                  rackTds.push(<td className={'ta-b'} style={{textAlign:'center',backgroundColor:bgColor}}>{name}</td>)
                  :tooltipType === "inActive"?
                  rackTds.push(
                  // <Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}>
                    <td style={{textAlign:'center',backgroundColor:bgColor}}>{name}</td>
                    // {/* </Tooltip> */}
                    )
                  :rackTds.push(
                  // <Tooltip title={displayTooltip(childRackPostion,childRackPostion.rackCode,tooltipType)}>
                  <Tooltip title={'click to check rack info'}>
                    <td className={'ta-b'} id={childRackPostion.rackPositionId} onClick={() => showStockDetails(childRackPostion.rackPositionId)} style={{textAlign:'center',backgroundColor:bgColor}}>{name}</td>
                  </Tooltip>
                  ))
              }
            });
            mainTr.push(<tr>{rackTds}</tr>);
             rackTds = [];
          });
        });
      });
      return mainTr;
    }
    const eachRackDisplay = (racks:WarehouseDashboard[], zoneId:number) => {
        return (
          <>
    <div id="table-wrapper">
      <div id="table-scroll" style={{overflow:'scroll'}}>
        <table className={'ta-b'} style={{ width: '100%', tableLayout: 'fixed' }} id="table-to-xls">
            <tr style={{backgroundColor:'#52c41a695c'}}>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
              {renderData(racks)}
        </table>
        </div>
        </div>
        </>
      )
    }

    const getZoneLevelRacks = (rackId:number) => {
      setRackDashboard([]);
      stockInService.getWarehouseRackDetails({plantId:props.plantId,zoneId:props.zoneId,rackId:rackId}).then((res) => {
        if(res.status){
          setRackDashboard(res.data);
        }
        else {
              if (res) {
                setRackDashboard([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                setRackDashboard([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
      }).catch((err) => {
          setRackDashboard([]);
          AlertMessages.getErrorMessage(err.message);
      });
    }
    const handleRack = (rackCode:string,rackId:number, rackType:RackTypeEnum) => {
      if(rackCode === "R"){
        R==true? setR(false):setR(true);
        setS(false);
        setX(false);
        setY(false);
        setA(false);
        setB(false);
        setC(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "S"){
        S==true? setS(false):setS(true);
        setR(false);
        setX(false);
        setY(false);
        setA(false);
        setB(false);
        setC(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "X"){
        X==true? setX(false):setX(true);
        setY(false);
        setR(false);
        setS(false);
        setA(false);
        setB(false);
        setC(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "Y"){
        Y==true? setY(false):setY(true);
        setX(false);
        setR(false);
        setS(false);
        setA(false);
        setB(false);
        setC(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      
      else if(rackCode === "A"){
        A==true? setA(false):setA(true);
        setB(false);
        setC(false);
        setR(false);
        setS(false);
        setX(false);
        setY(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "B"){
        B==true? setB(false):setB(true);
        setA(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "C"){
        C==true? setC(false):setC(true);
        setA(false);
        setB(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "RW"){
        RW==true? setRW(false):setRW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setYW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "SW"){
        SW==true? setSW(false):setSW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setYW(false);
        setRW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "XW"){
        XW==true? setXW(false):setXW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setYW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "YW"){
        YW==true? setYW(false):setYW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setRW(false);
        setSW(false);
        setAW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "AW"){
        AW==true? setAW(false):setAW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setRW(false);
        setSW(false);
        setYW(false);
        setBW(false);
        setCW(false);
      }
      else if(rackCode === "BW"){
        BW==true? setBW(false):setBW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setRW(false);
        setSW(false);
        setYW(false);
        setAW(false);
        setCW(false);
      }
      else if(rackCode === "CW"){
        CW==true? setCW(false):setCW(true);
        setA(false);
        setB(false);
        setC(false);
        setX(false);
        setY(false);
        setR(false);
        setS(false);
        setXW(false);
        setRW(false);
        setSW(false);
        setYW(false);
        setAW(false);
        setBW(false);
      }
      getZoneLevelRacks(rackId)
    }
    return (
        <>
            <Tag style={{backgroundColor:'white', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}> Available</Tag>  &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#f7f871', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Partially Occupied</Tag> &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#52c41a69', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Fully Occupied</Tag> &nbsp;&nbsp;&nbsp;
            <Tag style={{backgroundColor:'#c1dffa', color:'black', textAlign:'center',fontSize:'14px', fontWeight:'bold' , width:'15%'}}>Reserved</Tag>
            <br/><br/>
            {
              racksData.length>0?racksData.map((res) => {
                // console.log(res);
                return <><Card style={{ overflow: 'auto', overflowY:'hidden',backgroundColor:'#bfbfbf', color:'white', height:50,margin:1  }} onClick={() => handleRack(res.rackCode, res.rackId, res.rackType)} title={<span><DownOutlined /><InsertRowLeftOutlined /><span style={{color:'white'}}>Rack - {res.rackCode} </span></span>} 
                extra={<span style={{color:'black'}}>{(rackStatus!=undefined?
                rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.ASSIGNED)?.cnt!=undefined?"RESERVED :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.ASSIGNED)?.cnt + ", ":"RESERVED : 0, " :"RESERVED : 0, ") + 
                (rackStatus!=undefined?rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.OCCUPIED)?.cnt!=undefined?"OCCUPIED :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.OCCUPIED)?.cnt + ", ":"OCCUPIED : 0, " :"OCCUPIED : 0, ") + 
                (rackStatus!=undefined?rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.NOT_OCCUPIED)?.cnt!=undefined?"AVAILABLE :" +rackStatus?.find((rec) => rec.rackId === res.rackId && rec.occupancy === OccupancyEnum.NOT_OCCUPIED)?.cnt + ", ":"AVAILABLE : 0, " :"AVAILABLE : 0, ") +
                (rackStatus!=undefined? "Rack Capacity : " + res.cnt:"Rack Capacity : 0")
                }</span>}></Card>
                { props.zoneId === 5?
                  res.rackCode === "R"? R? eachRackDisplay(rackDashboard,props.zoneId):null 
                  : res.rackCode === "S" ?S? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "RW" ?RW? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "SW" ?SW? eachRackDisplay(rackDashboard,props.zoneId): null
                  :null
                  
                  :props.zoneId === 6?
                  res.rackCode === "X"? X? eachRackDisplay(rackDashboard,props.zoneId):null 
                  : res.rackCode === "Y" ?Y? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "XW" ?XW? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "YW" ?YW? eachRackDisplay(rackDashboard,props.zoneId): null
                  :null
                  
                  :props.zoneId === 7?
                  res.rackCode === "A"? A? eachRackDisplay(rackDashboard,props.zoneId):null 
                  : res.rackCode === "B" ?B? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "C" ?C? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "AW" ?AW? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "BW" ?BW? eachRackDisplay(rackDashboard,props.zoneId): null
                  : res.rackCode === "CW" ?CW? eachRackDisplay(rackDashboard,props.zoneId): null
                  :null

                  :null
                }
              </>
              }):<Card> No Racks Found. </Card>
            }
            <Modal
                  className='print-docket-modal'
                  key={'modal' + Date.now()}
                  width={'50%'}
                  style={{ top: 30, alignContent: 'right' }}
                  visible={showVisible}
                  title={<React.Fragment>
                  </React.Fragment>}
                  onCancel={handleTransferCancel}
                  footer={[

                  ]}
                >
                  {<StockDetails closeModal={closeModal} rackPositionId={rackInfo} />}
              </Modal>
        </>
    )
}

export default Unit1ZoneDisplay;