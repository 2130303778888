export class ContaierRegisterRequest{
    etdFromDate:string;
    etdToDate:string;
    destination:number;
    plantId:number;
    
    constructor(
        etdFromDate:string,
        etdToDate:string,
        destination:number,
        plantId:number,
    ){
        this.etdFromDate=etdFromDate,
        this.etdToDate=etdToDate,
        this.destination=destination,
        this.plantId=plantId
    }
}