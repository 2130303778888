import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GrnDto } from './grn.dto';


export class grnResponse extends GlobalResponseObject{
    data?: GrnDto;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: GrnDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
