import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { DayWiseTemperatureModel } from "./daywise-temp.response.model";


export  class DayWiseDeptTempResponse extends GlobalResponseObject {
    data?: DayWiseTemperatureModel[];

     /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data
     */
      

    constructor(status: boolean, errorCode: number, internalMessage: string, data?: DayWiseTemperatureModel[]){
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}