import React, { useEffect, useRef, useState } from 'react';
import { Card, Table, Form, Input, Button, Col, Row, Tag, Tooltip, Drawer, Select, Popconfirm, Tabs, Modal, Divider } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AssetAssignmentService, AssetService } from '@gtpl/shared-services/asset-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import AssetServiceForm from './asset-service-form';
import { AssetServiceDto } from '@gtpl/shared-models/asset-management';
import form from 'antd/lib/form';
import { AssetServiceReq, ServiceStatusEnum } from '@gtpl/shared-models/common-models';
import { CheckOutlined } from '@ant-design/icons';
import AddRemarks from './add-remarks';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto";
import ServiceDetailView from './service-detail-view';
const { Option } = Select;

/* eslint-disable-next-line */
export interface AssetServiceProps { }

export function AssetsServices(
  props: AssetServiceProps
) {
  const { id } = useParams<any>()
  const [page, setPage] = React.useState(1);
  const [readyForAsset, setReadyForAsset] = useState<any[]>([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const service = new AssetAssignmentService()
  const searchInput = useRef(null);
  const [pageSize, setPageSize] = useState<number>(null);
  const [plantsData, setPlantsData] = useState<any>([]);
  const [codeData, setCodeData] = useState<any>([]);
  const [locationData, setLocationData] = useState<any>([]);
  const [selectedServiceData, setSelectedServiceData] = useState<any>(undefined);
  const [disable, setDisable] = useState<boolean>(false);
  const [missedAssetServices, setMissedAssetServices] = useState<any[]>([])
  const [handleRemarks, showHandleRemarks] = useState<{ modalVisible?: boolean, remarks?: string, assetServiceId?: number }>({ modalVisible: false, remarks: '', assetServiceId: 0 });
  const [modalName, setModalName] = useState<string>('')
  const assetsService = new AssetService();
  const [form] = Form.useForm();
  const history = useHistory()

  console.log(id)

  useEffect(() => {
    assetService()
    getPlantsForAssetService();
    getLocationsForAssetService();
    getAssetsCodeForAssetService();
    getMissedAssetServices()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
  }, [])


  function filterData(){
    getMissedAssetServices()
    assetService()
  }


  const assetService = () => {
    setDisable(true);
    const req = new AssetServiceReq();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('locationId') != undefined) {
      req.location = form.getFieldValue('locationId')
    };
    if (id) {
      form.setFieldsValue({ assetId: id })
    }

    if (form.getFieldValue('assetId') != undefined) {
      req.assetId = form.getFieldValue('assetId')
    }
    service.getReadyForAssetService(req).then((res) => {
      if (res.status) {
        setDisable(true);
        setReadyForAsset(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDisable(false)
    })
  }

  const getPlantsForAssetService = () => {
    service.getPlantsForAssetService().then((res) => {
      setDisable(true)
      if (res.status) {
        setPlantsData(res.data);
      } else {
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
      setDisable(false)
    });
  }

  const getAssetsCodeForAssetService = () => {
    service.getAssetsCodeForAssetService().then((res) => {
      setDisable(true)
      if (res.status) {
        setCodeData(res.data);
      } else {
        setCodeData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
      setDisable(false)
    });
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }
  const getLocationsForAssetService = () => {
    const req = new AssetServiceReq()
    if (form.getFieldValue('plant') != undefined) {
      req.plant = form.getFieldValue('plant')
    };
    service.getLocationsForAssetService(req).then((res) => {
      setDisable(true)
      if (res.status) {
        setLocationData(res.data);
      } else {
        setLocationData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLocationData([]);
      setDisable(false)
    });
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const openFormWithData = (viewData: AssetServiceDto) => {
    setDrawerVisible(true);
    viewData.dateOfPurchased ? viewData.dateOfPurchased = moment(viewData.dateOfPurchased, 'YYYY-MM-DD') : null;
    viewData.assetServiceDate ? viewData.assetServiceDate = moment(viewData.assetServiceDate, 'YYYY-MM-DD') : null;
    viewData.actAssetServiceDate ? viewData.actAssetServiceDate = moment(viewData.actAssetServiceDate, 'YYYY-MM-DD') : null;
    setSelectedServiceData(viewData);

  }

  const updateAssetService = (updateDto: AssetServiceDto) => {
    // insuranceDto.updatedUser= JSON.parse(localStorage.getItem('username'))
    updateDto.updatedUser = localStorage.getItem("createdUser");
    console.log(updateDto.updatedUser)
    assetsService.updateAssetService(updateDto).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        assetService();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage('Data not found');
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getMissedAssetServices = () => {
    setDisable(true);
    const req = new AssetServiceReq();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }
    };
    if (form.getFieldValue('locationId') != undefined) {
      req.location = form.getFieldValue('locationId')
    };
    if (id) {
      form.setFieldsValue({ assetId: id })
    }

    if (form.getFieldValue('assetId') != undefined) {
      req.assetId = form.getFieldValue('assetId')
    }
    service.getMissedAssetServices(req).then((res) => {
      if (res.status) {
        setDisable(true);
        setMissedAssetServices(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDisable(false)
    })
  }
  const updateAssetsStatus = (values: any) => {
    console.log(values);
    const status = values.status == ServiceStatusEnum.OPEN ? ServiceStatusEnum.INPROGRESS : ServiceStatusEnum.COMPLETE
    const req = new AssetByCode(values.assetId, values.assetServiceId, status)
    assetsService.updateAssetStatus(req).then((res) => {
      if (res.status) {
        assetService();
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const renderStatusUpdateButton = (status: ServiceStatusEnum, rowData) => {
    let buttonText = status == ServiceStatusEnum.OPEN ? 'Start service' : status == ServiceStatusEnum.INPROGRESS ? 'Completed' : 'Print'
    return <Popconfirm onConfirm={e => { updateAssetsStatus(rowData); }}
      title={
        rowData.status == ServiceStatusEnum.OPEN
          ? `Are you sure to Start service  ?`
          : 'Are you sure to Complete service ?'
      }
    >
      <Button type='primary' size='small'>{buttonText}</Button>
    </Popconfirm>
  }

  const renderStatusTag = (status: ServiceStatusEnum) => {
    let bgColor = status == ServiceStatusEnum.OPEN ? 'blue' : status == ServiceStatusEnum.INPROGRESS ? 'orange' : 'red'
    return <Tag color={bgColor}>{status}</Tag>
  }




  function renderColumns(key){
    let width
    if(key == 'INPROGRESS'){
      width = '320px'
    }else {
      width = '80px'
    }
    const columns: ColumnProps<any>[] = [
      {
        title: 'S.No',
        key: 'sno',
        width: '50px',
        fixed: 'left',
        render: (text, object, index) => (page - 1) * pageSize + (index + 1)
      },
      {
        title: 'Service name',
        dataIndex: 'serviceName',
        fixed: 'left',
        ...getColumnSearchProps('serviceName'),
      },
  
      {
        title: 'Asset Code',
        dataIndex: 'assetCode',
        fixed: 'left',
        ...getColumnSearchProps('assetCode'),
      },
  
      {
        title: 'Asset Name',
        dataIndex: 'assetName',
        ...getColumnSearchProps('assetName'),
      },
  
      {
        title: 'Asset Category',
        dataIndex: 'assetCategory',
        ...getColumnSearchProps('assetCategory'),
      },
  
      {
        title: 'Asset Type',
        dataIndex: 'assetType',
        // render: (status, rowData) => (
        //   <>
        //     {status == "movable" ? <Tag color='gold'>Movable</Tag> : status == "immovable" ? <Tag color='purple' >Immovable</Tag> : ""}
  
        //   </>
        // ),
        // filters: [
        //   {
        //     text: 'movable',
        //     value: 'movable',
        //   },
  
        //   {
        //     text: 'immovable',
        //     value: 'immovable',
        //   },
        // ],
        // filterMultiple: false,
        // onFilter: (value, record) => {
        //   // === is not work
        //   return record.assetType === value;
        // },
  
      },
  
      {
        title: 'Unit',
        dataIndex: 'plant',
      },
      {
        title: 'Location',
        dataIndex: 'locationName',
      },
      // {
      //   title: 'Service Tenure',
      //   dataIndex: 'serviceTenure',
      // },
      // {
      //   title: 'Asset Created Date',
      //   dataIndex: 'assetCreateDate',
      //   render: (value, record) => {
      //     return <span>
      //       {record.assetCreateDate ? moment(record.assetCreateDate).format('YYYY-MM-DD') : '-'}
      //     </span>
      //   }
      // },
      {
        title: 'Service Date',
        dataIndex: 'serviceDate',
        render: (value, record) => {
          return <span>
            {record.serviceDate ? moment(record.serviceDate).format('YYYY-MM-DD') : '-'}
          </span>
        },
        sorter: (a, b) => a.serviceDate.localeCompare(b.serviceDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Aging',
        key: 'serviceDate',
        width: 80,
        align: 'left',
        sorter: (a, b) => (Math.floor((new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.serviceDate).getTime() - new Date(b.serviceDate).getTime()) / (1000 * 60 * 60 * 24)) + 1),
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('aging'),
        render(text, record) {
          const reduceOneDay = moment(record.serviceDate,"YYYY-MM-DD").add(1,'day')
          const dateWithOuttime = moment(reduceOneDay).format("YYYY-MM-DD")
          console.log(dateWithOuttime)
          const obj: any = {
            children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(dateWithOuttime).getTime()  - new Date().getTime()) / (1000 * 60 * 60 * 24) + 0)}</div>),
            props: {
              style: {
                background: Math.floor((new Date(dateWithOuttime).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
                color: Math.floor((new Date(dateWithOuttime).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
              }
            },
          };
          return obj;
        }
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   render: (status, rowData) => (
      //     <span>
      //       {renderStatusTag(status)}
      //     </span>
      //   ),
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
        width: width,
        render: (text, rowData) => {
          return <>
            {rowData.status == ServiceStatusEnum.INPROGRESS ? <>{renderRemarks(rowData)}<Divider type='vertical' /> </> : <></>}
            {rowData.status != ServiceStatusEnum.COMPLETE ? renderStatusUpdateButton(rowData.status, rowData) : <Button onClick={() => { handleAddRemarks(rowData); setModalName('detail-view') }} size='small' type='primary'>View</Button>}</>
        }
      }
  
    ]
    return columns
  }

  const renderRemarks = (rowData) => {
    return <Button size='small' type='primary' onClick={() => { handleAddRemarks(rowData);setModalName('add-remarks') }}>Update Service Note</Button>
  }


  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const onReset = () => {
    form.resetFields();
    assetService();
    getMissedAssetServices()
  }

  function handleAddRemarks(rowData) {
    showHandleRemarks({ modalVisible: true, remarks: rowData.remarks, assetServiceId: rowData.assetServiceId })
  }
  return (
    <div>
      <Card title={<span style={{ color: 'white' }}> Asset Service</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-service-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
        <Form form={form} layout={'vertical'}
        >
          <Row gutter={[24, 10]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item
                name="plantId"
                label=" plant"
                rules={[
                  {
                    required: false, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Unit"
                  allowClear
                  style={{ width: '100%' }}
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  {/* <Option key={0} value={null}>Select Plant</Option> */}
                  {plantsData.map(dropData => {
                    return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plant}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item
                name="locationId"
                label=" Location"
                rules={[
                  {
                    required: false, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select location"
                  allowClear
                  // disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  style={{ width: '100%' }}
                >
                  {/* <Option key={0} value={null}>Select Location</Option> */}
                  {locationData.map(dropData => {
                    return <Option key={dropData.locationId} value={dropData.locationId}>{dropData.locationName}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }}>
              <Form.Item
                name="assetId"
                label="Asset Code"
                rules={[
                  {
                    required: false, message: 'Asset Code',
                  },
                ]}
              >
                <Select
                  placeholder="Select Asset"
                  allowClear
                  onFocus={onFocus}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* <Option key={0} value={null}>Select Asset Code</Option> */}
                  {codeData.map(dropData => {
                    return <Option key={dropData.assetCode} value={dropData.assetCode}>{dropData.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ marginTop: '30px' }}>
              <Button style={{ marginRight: '4px' }} onClick={filterData}>Get Details</Button>
            </Col>
            <Col style={{ marginTop: '30px' }}>
              <Button type="primary" htmlType="submit" onClick={onReset}>
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <Tabs>
          <Tabs.TabPane key="1"
            tab={<span style={{ color: "#1849FF" }}>{'Open' + ":" + (readyForAsset.filter(el => el.status == ServiceStatusEnum.OPEN)?.length)}</span>}
          >
            <Table columns={renderColumns('OPEN')}
              dataSource={readyForAsset.filter(el => el.status == ServiceStatusEnum.OPEN)}
              scroll={{ x: 1500, y: 400 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered />
          </Tabs.TabPane>
          <Tabs.TabPane key="2"
            tab={<span style={{ color: "#1849FF" }}>{'Inprogress' + ":" + (readyForAsset.filter(el => el.status == ServiceStatusEnum.INPROGRESS)?.length)}</span>}
          >
            <Table columns={renderColumns('INPROGRESS')}
              dataSource={readyForAsset.filter(el => el.status == ServiceStatusEnum.INPROGRESS)}
              scroll={{ x: 1500, y: 400 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered />
          </Tabs.TabPane>
          <Tabs.TabPane key="3"
            tab={<span style={{ color: "#1849FF" }}>{'Completed' + ":" + (readyForAsset.filter(el => el.status == ServiceStatusEnum.COMPLETE)?.length)}</span>}
          >
            <Table columns={renderColumns('COMPLETED')}
              dataSource={readyForAsset.filter(el => el.status == ServiceStatusEnum.COMPLETE)}
              scroll={{ x: 1500, y: 400 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered />
          </Tabs.TabPane>

          <Tabs.TabPane key='4'
            tab={<span style={{ color: "#f5222d" }}>{'Missed' + ":" + (missedAssetServices?.length)}</span>}
          >
            <Table columns={renderColumns('MISSED')}
              dataSource={missedAssetServices}
              scroll={{ x: 1500, y: 400 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              bordered />
          </Tabs.TabPane>
        </Tabs>

        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '70%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <AssetServiceForm key={Date.now()}
              updateService={updateAssetService}
              isUpdate={true}
              serviceData={selectedServiceData}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
        <Modal key={handleRemarks.assetServiceId} visible={handleRemarks.modalVisible} footer={false} width='70%' closable={true} onCancel={() => showHandleRemarks({ modalVisible: false })}>
          {modalName == 'detail-view' ? <ServiceDetailView assetServiceId={handleRemarks.assetServiceId} /> :
            <AddRemarks values={{ ...handleRemarks }} closeModal={() => {showHandleRemarks({ modalVisible: false });assetService();
          }} />
            }
        </Modal>
        </Card>

    </div >
  )
}
export default AssetsServices;
