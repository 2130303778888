import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PaymentModeDto } from './payment-mode-dto';
export class PaymentModeResponseModel extends GlobalResponseObject {
    data?: PaymentModeDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PaymentModeDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}

