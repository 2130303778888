export class LotCodesDTO {
    lotCode: string;
    poNumber: string;

    constructor(lotCode: string,poNumber:string){
        this.lotCode = lotCode;
        this.poNumber = poNumber;
    }
}

