import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Table, { ColumnProps } from 'antd/lib/table';

import { Link } from 'react-router-dom';
import './fg-stock-transfer.css';
import { ItemsDropDownDto, PlantsDropDown,RackPositionIdRequest,RacksForZoneId,ZoneDropDownDto,ZoneDto,Brands, ZoneIdRequest, RackDropDownDto} from '@gtpl/shared-models/masters';
import { ItemsDetails, SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderItemsDTO, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import FormItem from 'antd/lib/form/FormItem';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ZoneService,RackPositionService,EndCustomersService, RackService} from '@gtpl/shared-services/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'
import {FGStockService, ForkLiftJobService} from '@gtpl/shared-services/warehouse-management'
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import StockTransferItemDetails from './stock-transfer-item-details';


/* eslint-disable-next-line */
export interface FgStockTransferProps {

}

export function FgStockTransfer(
  props: FgStockTransferProps
) {
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
  const [saleOrderItemDetails, setSaleOrderItemDetails] = useState<SaleOrderItemsDTO>(undefined)
  const [rackPostionsDropDown, setRackPostionsDropDown] = useState<any[]>(undefined)
  const [racksDropDown, setRacksDropDown] = useState<RackDropDownDto[]>([])
  const [transferType, setTransferType] = useState<string>(undefined)
  const [stockData, setStockData] = useState<any[]>(undefined)
  const [stockTransferDcData, setStockTransferDcData] = useState<any[]>(undefined)
  const [stockTransferMrnData, setStockTransferMrnData] = useState<any[]>(undefined)
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [hideColumns, setHideColumns] = useState<boolean>(false)
  const [coldStorageVissible,setColdStorageVissible] = useState<boolean>(false); 
  const [coldStorageDisable,setColdStorageDisable] = useState<boolean>(false); 
  const [rackRequired,setRackRequired] = useState<boolean>(true); 
  const [soItemRequired,setSoItemRequired] = useState<boolean>(false); 
  const [toPlantVisible,setToPlantVisible] = useState<boolean>(false); 
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [saleOrderVissible,setSaleOrderVissible] = useState<boolean>(false);
  const [transferVissible,setTransferVissible] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
 const zoneService = new ZoneService();
 const [hideRackColumns, setHideRackColumns] = useState<boolean>(false)
 const [hideRacksColumns, setHideRacksColumns] = useState<boolean>(false)
 const [saleOrderItemsData, setSaleOrderItemsData] = useState<ItemsDetails[]>([])
 const rackPositionService = new RackPositionService();
 const rackService = new RackService();
 const fGStockService = new FGStockService();
 const forkLiftJobService = new ForkLiftJobService();
 const [zoneData, setZoneData] = useState<ZoneDto[]>([]);
 const saleOrderService = new SaleOrderService();
 const unitsService = new UnitcodeService()
 const [page, setPage] = React.useState(1);
 const [saleOrderId, setSaleOrderId] = useState<number>(undefined)
 const [itemsKey, setItemsKey] = useState<number>(10);
 const [selectedCategory, setSelectedCategory] = useState<string>('');
 const [selectedCurrency, setSelectedCurrency] = useState<number>(1);
 const endcustomerservice = new EndCustomersService();
 const [customerBrandsData, setCustomerBrandsData] = useState<Brands[]>([]);
 const [selectedRowKeysData,setSelectedRowKeysData]=useState<string[]>([]);   
 const [selectedRowsData,setSelectedRowsData]=useState<any[]>([]);   
 const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
const soDataSevice = new SaleOrderService()
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    console.log(selectedRowKeys);
    console.log(selectedRowKeys.length);
    setSelectedRowKeysData(selectedRowKeys);
    setSelectedRowsData(selectedRows);
    if(selectedRows.length>0){
      if(transferType === "internal"){
        setColdStorageVissible(true)
        setColdStorageDisable(true)
        form.setFieldsValue({zoneId:selectedRows[0].coldStorageId});
        form.setFieldsValue({rackId:selectedRows[0].rackId});
        form.setFieldsValue({fromSaleOrderId:selectedRows[0].saleOrderId});
        handleColdStorage()
        handleRack()
      }
      else if(transferType === "warehouse"){
        console.log(selectedRows[0].coldStorageId);
        setColdStorageVissible(true)
        setColdStorageDisable(false)
        getAllZonesData(Number(localStorage.getItem("unit_id")),selectedRows[0].coldStorageId);

        // form.setFieldsValue({zoneId:selectedRows[0].coldStorageId});
        // form.setFieldsValue({rackId:selectedRows[0].rackId});
        // form.setFieldsValue({fromSaleOrderId:selectedRows[0].saleOrderId});
      }
      // setTransferVissible(true)
      setBtnDisable(false);

    }
    else{
      // setTransferVissible(false)
      setBtnDisable(true);

    }
  },
};
 useEffect(() => {
   form.setFieldsValue({createdUser:localStorage.getItem("username").replace(/"/g, "")})
  getAllPlants();
  getAllZonesData(Number(localStorage.getItem("unit_id")),undefined);
  // getRacks();
  getSaleOrderData(localStorage.getItem("unit_id"));
  getSaleOrders();
  getStockData(Number(localStorage.getItem("unit_id")));
  getAllStockTranserDcData(Number(localStorage.getItem("unit_id")));
  getAllStockTranserMrnData(Number(localStorage.getItem("unit_id")))
}, []);



   const handleTransferType = (value) =>{
     console.log(stockData);
     if(stockData.length>0){
        setTransferType(value);
        if(value === "plant"){
        // setStockData([]);
        setSelectionType('checkbox')
        form.setFieldsValue({zoneId:undefined})
        form.setFieldsValue({rackPosition:undefined})
        form.setFieldsValue({saleOrderId:undefined})
        setToPlantVisible(true)
        setHideRackColumns(false)
        setHideRacksColumns(false)
        setSaleOrderVissible(false)
        setColdStorageVissible(false)
        setColdStorageDisable(false)
        setHideColumns(false)
        setRackRequired(false)
        setSoItemRequired(false)
        // setStockData(stockData.filter(stockData => stockData.stockType === "dummy"))
        }
        if (value === "internal"){
        setSelectionType('radio')
        form.setFieldsValue({saleOrderItemId:undefined})
        form.setFieldsValue({toPlant:undefined})
        form.setFieldsValue({saleOrderId:undefined})
        form.setFieldsValue({rackPosition:undefined})
        form.setFieldsValue({zoneId:undefined})
        setColdStorageVissible(false)
        setColdStorageDisable(false)
        setSaleOrderVissible(false)
        setToPlantVisible(false)
        setHideColumns(false)
        setSoItemRequired(false)
        setHideRackColumns(false)       
        setHideRacksColumns(false)       
        setRackRequired(true)
        }
  
        if (value === "saleOrder"){
        setSelectionType('checkbox')
        form.setFieldsValue({toPlant:undefined})
        form.setFieldsValue({zoneId:undefined})
        form.setFieldsValue({rackPosition:undefined})
        setSaleOrderVissible(true)
        setColdStorageVissible(false)
        setColdStorageDisable(false)
        setHideRackColumns(false)   
        setHideRacksColumns(false)   
        setSoItemRequired(true)   
        getSaleOrderData(localStorage.getItem("unit_id"));
        setToPlantVisible(false)
        setRackRequired(false)
        }
        
        if (value === "warehouse"){
        setSelectionType('radio')
        form.setFieldsValue({zoneId:undefined})
        form.setFieldsValue({toPlant:undefined})
        form.setFieldsValue({saleOrderId:undefined})
        form.setFieldsValue({rackPosition:undefined})
        setColdStorageVissible(false)
        setColdStorageDisable(false)
        setToPlantVisible(false)
        setSaleOrderVissible(false)
        setHideRackColumns(false)
        setHideRacksColumns(false)
        setHideColumns(false)
        setSoItemRequired(false)
        setRackRequired(true)
        }
     }
     else{
       AlertMessages.getErrorMessage("No Stock Available, please do Stock In. ");
     }
     
    //  getRacks();
   }


   
   const handleSaleOrderItem = (value) => {
    console.log(`selected ${value}`);
    getSaleOrderItemDetails(value);
    // getRacks();

  }

  const getAllStockTranserDcData = (val) => {
    forkLiftJobService.getAllStockTranserDcData({plantId:val}).then((res) => {
      if(res.status){
        setStockTransferDcData(res.data);
      }
      else {
        if (res.intlCode) {
          setStockTransferDcData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setStockTransferDcData([]);
      }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setStockTransferDcData([]);
      });

  }
  const getAllStockTranserMrnData = (val) => {
    forkLiftJobService.getAllStockTranserMrnData({plantId:val}).then((res) => {
      if(res.status){
        setStockTransferMrnData(res.data);
      }
      else {
        if (res.intlCode) {
          setStockTransferMrnData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setStockTransferMrnData([]);
      }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setStockTransferMrnData([]);
      });

  }
  const getStockData = (val) => {
    setStockData([]);
    fGStockService.getStockForPlant({plantId:val}).then((res) => {
      if(res.status){
        setStockData(res.data);
      }
      else {
        if (res.intlCode) {
          setStockData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setStockData([]);
      }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setStockData([]);
      });
  }
  // const getRacks = () => {
  //   let saleOrderId = form.getFieldValue("saleOrderId");
  //   let saleOrderItemId = form.getFieldValue("saleOrderItemId");
  //   let reportingCases = form.getFieldValue("reportingCases");
  //   let stockStatus = form.getFieldValue("stockStatus");
  //   let plantId = Number(localStorage.getItem("unit_id"));
  //   console.log(form.getFieldsValue());
  //   console.log(plantId+"***" +saleOrderId+"***" + saleOrderItemId+"***"+reportingCases+"***"+stockStatus);
  //   if(plantId != undefined && saleOrderId != undefined && saleOrderItemId != undefined && reportingCases != undefined && stockStatus != undefined){
  //     rackPositionService.getSuggestLocation({plantId:plantId,saleOrderId:saleOrderId,saleOrderItemId:saleOrderItemId,mastersCartons:reportingCases,stockTypeStatus:stockStatus}).then(res => {
  //       if (res.status) {
  //         setRackPostionsDropDown(res.data);
  //       }
  //      else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //       setRackPostionsDropDown([]);
  //     }
  //     }).catch((err) => {
  //       AlertMessages.getErrorMessage(err.message);
  //       setRackPostionsDropDown([]);
  //     });
  //   }
  // }


  const getSaleOrderItemDetails = (reqData) => {
    console.log(saleOrderId)
    console.log(reqData)
    // setSaleOrderItemDetails(undefined);
    saleOrderService.getSaleOrderItemDetails({ saleOrderId: form.getFieldValue("saleOrderId"), saleOrderItemId: reqData }).then(res => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderItemDetails(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemDetails(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemDetails(undefined);
    })
    // getRacks();
  }

   const getAllZonesData = (val,zoneId) =>{
    console.log(zoneId);
    zoneService.getPlantZonesExceptFromZone({plantId:val,isPlantHO:true,zoneId:zoneId}).then((res) => {
      if(res.status) {
        setZoneData(res.data);
      }else {
        if(res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err =>{
      AlertMessages.getErrorMessage(err.message);
    })
 }

 const getAllPlants = () => {
  unitsService.getAllMainPlants().then((res) => {
    if (res.status) {
      setPlantData(res.data.filter((res) => res.plantId != Number(localStorage.getItem('unit_id'))));
    } else {
      if (res.intlCode) {
        setPlantData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        setPlantData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setPlantData([]);
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setPlantData([]);
  })
}
 
  const getSaleOrderData = (value) => {
    saleOrderService.getAllActiveSaleOrders({ plantId: value }).then((res) => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  
  const getSaleOrders = () =>{
   soDataSevice.getAllActiveSaleOrders({ plantId: Number(localStorage.getItem("unit_id")) }).then ((res) =>{
     if (res.data) {
       setSaleOrderData(res.data);
     } else {
       setSaleOrderData([]);
     }
   }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setSaleOrderData([]);
  });
   
  }

  const getSaleOrderItems = (reqData) => {
    console.log("item details service");
    setSaleOrderItemDetails(undefined);
    setSaleOrderItemsData([]);
    saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        console.log(res.data.length);
        if(res.data.length === 1){
          console.log(reqData)
          form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId });
          setSaleOrderId(reqData);
          getSaleOrderItemDetails(res.data[0].saleOrderItemId);

        }
        setSaleOrderItemsData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemsData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData([]);
    })
    setHideColumns(true);
  }

  const handleSaleOrder = (value) => {
    console.log(value);
    getSaleOrderItems(value); // items dropdown
    setSaleOrderId(value);
    // getRacks();
  }
  const onReset = () => {
    form.resetFields();
    setStockData([]);
  };
  const handleBulkTransfer = (values) => {
    setBtnDisable(true);
    console.log(selectedRowKeysData)
    console.log(form.getFieldsValue())
    console.log(values);
    fGStockService.getTransferStock({transferType:values.transferType,stockIds:selectedRowKeysData,saleOrderId:values.saleOrderId,saleOrderItemId:values.saleOrderItemId,toPlant:values.toPlant,zoneId:values.zoneId,rackPostion:values.rackPostion,createdUser:values.createdUser,selectedRows:selectedRowsData}).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage(res.internalMessage);
        onReset()
        getStockData(Number(localStorage.getItem("unit_id")))

      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemsData([]);
      }
    })
  }
 
  const handleToPlant = () => {
    const transferType = form.getFieldValue('transferType')
    if(transferType == "plant"){
      setColdStorageVissible(false);
    }
    else{
      setColdStorageVissible(true);
    }

    console.log(form.getFieldValue('toPlant'));
    getAllZonesData(Number(form.getFieldValue('toPlant')),undefined);

  }
  const handleColdStorage = () => {
    const zoneId = form.getFieldValue('zoneId')
    rackService.getRacksByZone({zoneId:zoneId}).then(res =>{
      if (res.status) {
        setRacksDropDown(res.data);
        setHideRacksColumns(true)
      } else {
        if (res.intlCode) {
          setRacksDropDown([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setRacksDropDown([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })  
   
  }

 
  const handleRack = () => {
      form.setFieldsValue({ rackPostion:undefined })
      setRackPostionsDropDown([])
      console.log(rackPostionsDropDown,'state1')
      const zoneId = form.getFieldValue('zoneId')
      const rackId = form.getFieldValue('rackId')
      const saleOrder = form.getFieldValue('fromSaleOrderId')
      console.log('zoneID',zoneId);
      console.log('saleorderId',saleOrder);
      // const zoneRequest = new ZoneIdRequest(zoneId,rackId);
      // if(saleOrder=== undefined){ //commented if, else condition reserved rack suggestion for stock transfer bcz reserve & suggestion logic not in use
        rackPositionService.getAvailableRackPositionsForZoneId({zoneId:zoneId,rackId:rackId,plantId:Number(localStorage.getItem('unit_id'))}).then(res =>{
          if (res.status) {
             setRackPostionsDropDown([]);
             setRackPostionsDropDown(res.data);
             console.log(rackPostionsDropDown,'state2')
           setHideRackColumns(true);
          } else {
            if (res.intlCode) {
             setRackPostionsDropDown([]);
             AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              setRackPostionsDropDown([]);
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        })  
      // }
      // else{
      //   rackPositionService.getReservedRackPositionsForSO({saleOrderId:saleOrder,rackId:rackId}).then(res =>{
      //     if (res.status) {
      //        setRackPostionsDropDown([]);
      //        setRackPostionsDropDown(res.data);
      //        console.log(rackPostionsDropDown,'state2')
      //      setHideRackColumns(true);
      //     } else {
      //       if (res.intlCode) {
      //        setRackPostionsDropDown([]);
      //        AlertMessages.getErrorMessage(res.internalMessage);
      //       } else {
      //         setRackPostionsDropDown([]);
      //         AlertMessages.getErrorMessage(res.internalMessage);
      //       }
      //     }
      //   })   
      // }
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  const renderItems = (record: any, index, indent, expanded) => {
    console.log(record);
    return <StockTransferItemDetails record={record} />
  }
  const stockTransforColumns: ColumnProps<any>[] = [
    {
      title: 'From Location',
      dataIndex: 'fromPlant',
      sortDirections: ['descend', 'ascend'],
      
      filters: [
        { text: 'Kakinada', value: 'Kakinada' },
        { text: 'Unit 1', value: 'Unit 1' },
        { text: 'Unit 2', value: 'Unit 2' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.fromPlant == value;
      }
      // ...getColumnSearchProps('fromPlant'),
      // sorter: (a, b) => a.fromPlant.localeCompare(b.fromPlant),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'To Location',
      dataIndex: 'toPlant',
      sortDirections: ['descend', 'ascend'],
      
      filters: [
        { text: 'Kakinada', value: 'Kakinada' },
        { text: 'Unit 1', value: 'Unit 1' },
        { text: 'Unit 2', value: 'Unit 2' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.toPlant == value;
      }
      // ...getColumnSearchProps('toPlant'),
      // sorter: (a, b) => a.toPlant.localeCompare(b.toPlant),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Job Code',
      dataIndex: 'jobCode',
      ...getColumnSearchProps('jobCode'),
      // sorter: (a, b) => a.jobCode.localeCompare(b.jobCode),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Job Date',
      dataIndex: 'jobDate',
      // ...getColumnSearchProps('poNumber'),
      // sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      // sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Job Purpose',
      dataIndex: 'jobPurpose',
      // ...getColumnSearchProps('jobPurpose'),
      // sorter: (a, b) => a.jobPurpose.localeCompare(b.jobPurpose),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Job Cartons',
      dataIndex: 'totalJobCartons',
      align:'right'
      // ...getColumnSearchProps('totalJobCartons'),
      // sorter: (a, b) => a.totalJobCartons.localeCompare(b.totalJobCartons),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Received Cartons',
      dataIndex: 'totReceivedQuantity',
      align:'right'
      // ...getColumnSearchProps('totReceivedQuantity'),
      // sorter: (a, b) => a.totReceivedQuantity.localeCompare(b.totReceivedQuantity),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Excess Quantity',
      dataIndex: 'excessQuantity',
      align:'right'
    },
    {
      title: 'Damaged Quantity',
      dataIndex: 'damagedQuantity',
      align:'right'
    },
    {
      title: 'Transfer Status',
      dataIndex: 'transferStatus',
      sortDirections: ['descend', 'ascend'],
      
      filters: [
        { text: 'IN TRANSIT', value: 'IN TRANSIT' },
        { text: 'RECEIVED', value: 'RECEIVED' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.transferStatus == value;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        { text: 'OPEN', value: 'Open' },
        { text: 'PENDING', value: 'Pending' },
        { text: 'COMPLETED', value: 'Completed' },
        { text: 'CANCELLED', value: 'Cancelled' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.status == value;
      }
      // ...getColumnSearchProps('statua'),
      // sorter: (a, b) => a.statua.localeCompare(b.statua),
      // sortDirections: ['descend', 'ascend'],
    },
  ]
  const columnsSkelton: ColumnProps<any>[] = [

    {
      title: 'Prod Unit',
      dataIndex: 'unit',
      fixed: 'left',
      filters :[
          {text:"Kakinada", value:"Kakinada"},
          {text:"Unit 1", value:"Unit 1"},
          {text:"Unit 2", value:"Unit 2"},
      ],
      filterMultiple :false,
      onFilter : (value,record)=>{
           return record.unit == value;
      }
  },
    {
      title: 'Cold Storage',
      dataIndex: 'coldStorage',
      ...getColumnSearchProps('coldStorage'),
      sorter: (a, b) => a.coldStorage.localeCompare(b.coldStorage),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Rack Code',
      dataIndex: 'rackCode',
      ...getColumnSearchProps('rackCode'),
      sorter: (a, b) => a.rackCode.localeCompare(b.rackCode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'From Location',
      dataIndex: 'rackName',
      ...getColumnSearchProps('rackName'),
      sorter: (a, b) => a.rackName.localeCompare(b.rackName),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNo',
    //   ...getColumnSearchProps('saleOrderNo'),
    //   sorter: (a, b) => a.saleOrderNo.localeCompare(b.saleOrderNo),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      ...getColumnSearchProps('poNumber'),
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'SO Item',
    //   dataIndex: 'saleOrderItem',
    //   ...getColumnSearchProps('saleOrderItem'),
    //   sorter: (a, b) => a.saleOrderItem?.localeCompare(b.saleOrderItem),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Product SKU',
      dataIndex: 'productName',
      ...getColumnSearchProps('productName'),
      sorter: (a, b) => a.productName?.localeCompare(b.productName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cartons',
      dataIndex: 'cartons',
      ...getColumnSearchProps('cartons'),
      sorter: (a, b) => a.cartons-b.cartons,
      sortDirections: ['descend', 'ascend'],
    },
  ];

   /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
    const onChange=(pagination, filters, sorter, extra)=> {
      console.log('params', pagination, filters, sorter, extra);
    }

  return (
    <><Card title={<span style={{ color: 'white' }}>FG Stock</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Tabs type={'card'} tabPosition={'top'}>
      <TabPane key="1" tab={<span style={{ color: "#0ec92d" }}>{'Transfer Stock'}</span>}>
        <Form layout={'vertical'} form={form} name="control-hooks" onFinish={handleBulkTransfer}>
        <Form.Item style={{ display: "none" }} name="createdUser">
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="fromSaleOrderId">
          <Input hidden />
        </Form.Item>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item
              name="transferType"
              label="Transfer Type"

              rules={[
                {
                  required: true,
                  message: "Enter transfer type"
                }
              ]}>
              <Select
                onChange={handleTransferType}
                showSearch
                placeholder="select transfer type"
              >

                <Option value="internal">Internal</Option>
                <Option value="saleOrder">SaleOrder</Option>
                <Option value="warehouse">Warehouse</Option>
                <Option value="plant">Plant</Option>
              </Select>
            </Form.Item>
          
          </Col>
          
          {toPlantVisible ?
            <Col span={4}>
              <Form.Item
                name="toPlant"
                label="To Plant"
                rules={[
                  {
                    required: true, message: 'Missing Plant'
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Select Plant"
                  onChange={handleToPlant}
                  optionFilterProp="children"

                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={undefined}>please select To Plant</Option>;
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col> : ""}
          {coldStorageVissible ?
            <Col span={4}>
              <Form.Item
                name="zoneId"
                label="To Cold Storage"
                rules={[
                  {
                    required: true, message: 'Missing Cold Storage'
                  },
                ]}>
                <Select
                  placeholder="Select Cold Storage"
                  onChange={handleColdStorage}
                  allowClear
                  // optionFilterProp="children"
                  onFocus={onFocus}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={coldStorageDisable}
                >
                  <Option value={undefined}>please select Cold Storage</Option>;
                  {zoneData.map((zne) => {
                    return <Option key={zne.zoneId} value={zne.zoneId}>{zne.zoneName}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            :""
          }
          {hideRacksColumns ?
            <Col span={3}>
              <Form.Item
                name="rackId"
                label="Rack"
                rules={[
                  {
                    required: true, message: 'Missing Rack'
                  },
                ]}>
                <Select
                  placeholder="Select Rack"
                  onChange={handleRack}
                  allowClear
                  // optionFilterProp="children"
                  onFocus={onFocus}
                  onSearch={onSearch}
                  onSelect={handleRack}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={undefined}>Select Rack</Option>;
                  {racksDropDown.map((zne) => {
                    return <Option key={zne.rackId} value={zne.rackId}>{zne.rackCode}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            :""
          }
          {
            hideRackColumns?
              <Col span={3}>
                <Form.Item
                  name="rackPostion"
                  label="To Rack Position"
                  rules={[
                    {
                      required: rackRequired, message: 'Missing Rack Position',
                    },
                  ]}
                >
                <Select
                  placeholder="Select Rack Position"
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={undefined}>please select Rack Position</Option>;
                  {rackPostionsDropDown.map((rack) => {
                  return <Option key={rack.rackMasterId} value={rack.rackMasterId}>{rack.rackMasterName + " - " + rack.rackCode}</Option>;
                })}
                </Select>
                </Form.Item>
              </Col>:""
          }
        {saleOrderVissible ?
          <Col span={4}>
            <Form.Item name="saleOrderId" label="To Customer PO"
              rules={[
                {
                  required: true, message: 'Missing Sale Order',
                },
              ]}>
              {/* <Input /> */}
              <Select
                placeholder="Select PO Number"

                allowClear
                // optionFilterProp="children"
                onChange={handleSaleOrder}
                onFocus={onFocus}
                onSearch={onSearch}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value={undefined}>please select Sale Order</Option>;
                {saleOrderData.map(saleOrderDropDown => {
                  return <Option value={saleOrderDropDown.saleOrderId}>{saleOrderDropDown.poNumber}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>: ""
        }
        {hideColumns ?
          <Col span={5}>
            <Form.Item
              name="saleOrderItemId"
              label="SO Item"
              rules={[
                {
                  required:soItemRequired, message: "Missing So Item.",
                }
              ]}
            >
              <Select
                placeholder="Select Sale Order Item"
                onChange={handleSaleOrderItem}
                allowClear
                showSearch
                onFocus={onFocus}
                onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value={undefined}>please select Sale Order Item</Option>;
                {saleOrderItemsData?.map(saleOrderDropDown => {
                  return <Option value={saleOrderDropDown.saleOrderItemId}>{saleOrderDropDown.item}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          : ""
        }
        <Col style={{ marginTop: 30 }} span={5}>
          <Button type="primary" onClick={onReset}>
              Reset
            </Button>
        </Col>
        </Row>

        {/* {transferVissible? */}
        <Col span={3}>
        <Button type="primary" block disabled={btnDisable} htmlType="submit">
          Transfer
        </Button>

        </Col>
        {/* :""} */}
        </Form><br /><br /><br />
        {/* {transferVissible?<Button type='primary' onClick={handleBulkTransfer}>Transfer</Button>:""} */}
        <Table
        rowSelection={{
          type:selectionType,
          ...rowSelection
          }}
        columns={columnsSkelton}
        dataSource={stockData}
        rowKey={record => record.stockId}
        pagination={{
        onChange(current) {
        setPage(current);
        }
        }}
        onChange={onChange}
        scroll={{ x: 500 }}
        size="large"
        bordered />
      </TabPane>
      <TabPane key="2" tab={<span style={{ color: "#0ec92d" }}>{'DC: '+stockTransferDcData?.length}</span>}>
        <Table
          columns={stockTransforColumns}
          dataSource={stockTransferDcData}
          rowKey={record => record.jobId}
          expandedRowRender={renderItems}
          expandIconColumnIndex={0}
          pagination={{
          onChange(current) {
          setPage(current);
          }
          }}
          onChange={onChange}
          scroll={{ x: 500 }}
          size="large"
          bordered />
      </TabPane>
      <TabPane key="3" tab={<span style={{ color: "#0ec92d" }}>{'MRN: '+stockTransferMrnData?.length}</span>}>
        <Table
          columns={stockTransforColumns}
          dataSource={stockTransferMrnData}
          rowKey={record => record.jobId}
          expandedRowRender={renderItems}
          expandIconColumnIndex={0}
          pagination={{
          onChange(current) {
          setPage(current);
          }
          }}
          onChange={onChange}
          scroll={{ x: 500 }}
          size="large"
          bordered />
      </TabPane>
      
      </Tabs>
      </Card></>
  );
}

export default FgStockTransfer;
