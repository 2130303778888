export class RmRackPositionModel{
    rmRackCode: string;
    plantId: number;
    assocRfId: string;
    rmRackPosition: string;
    rmRackLevel: number;
    rmRackBoundaries: string;
    occupancy: string;    
    rmRackId?:number;
    isActive?:boolean;
    rmRackMasterId?: number;
    rmRackMasterName?: string;
    rmRackMasterCode?: string;
    rmRackPositionId?: number;
    maxPalletCapacity?: number;
    zoneId?:number;
    rmRackColumn?:number





    /**
     * @param rmRackId
     * @param rmRackCode 
     * @param plantId 
     * @param rfIds 
     * @param rmRackPosition 
     * @param rmRackLevel 
     * @param rmRackBoundaries 
     * @param occupancy 
     * @param rmRackId
     * @param isActive
     * @param rmRackMasterId 
     * @param rmRackMasterName 
     * @param rmRackMasterCode
     * @param rmRackColumn
     */
     constructor(rmRackCode:string,plantId: number,assocRfId: string,rmRackPosition: string,rmRackLevel: number,rmRackBoundaries: string,occupancy: string,rmRackId?:number,isActive?:boolean,rmRackMasterId?: number, rmRackMasterName?: string, rmRackMasterCode?: string,rmRackPositionId?: number, maxPalletCapacity?:number,zoneId?:number,rmRackColumn?:number){
        this.rmRackMasterId = rmRackMasterId;
        this.rmRackCode = rmRackCode;
        this.plantId = plantId;        
        this.assocRfId = assocRfId;
        this.rmRackPosition = rmRackPosition;
        this.rmRackLevel = rmRackLevel;
        this.rmRackBoundaries = rmRackBoundaries;
        this.occupancy = occupancy;
        this.rmRackId = rmRackId;
        this.isActive = isActive;
        this.rmRackMasterId = rmRackMasterId;
        this.rmRackMasterName = rmRackMasterName;
        this.rmRackMasterCode = rmRackMasterCode;
        this.rmRackPositionId = rmRackPositionId;
        this.maxPalletCapacity = maxPalletCapacity;
        this.zoneId = zoneId;
        this.rmRackColumn = rmRackColumn
    }
}