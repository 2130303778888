import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Descriptions } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import './store-return-grid.css';
import { StoreReturnService } from '@gtpl/shared-services/procurement';
import { StoreReturnDto, StrRtnReq } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import { StoreReturnInformation } from 'libs/shared-models/procurement-management/src/lib/store-returns/store-return-information.dto';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

/* eslint-disable-next-line */
export interface StoreReturnDetailViewProps {
  storeReturnData:StoreReturnInformation[]
}

export function StoreReturnDetailView(
  props: StoreReturnDetailViewProps
) {
    let location = useLocation();
    const storeReturnId = location.state;
    const [storeReturnData, setStoreReturnData] = useState<StoreReturnInformation>(undefined);
    const storeRtnService = new StoreReturnService();
  

    useEffect(() => {
        getStoreReturnInfo(storeReturnId);
      }, [storeReturnId]);

      const getStoreReturnInfo = (storeReturnId) => {
          const req = new StrRtnReq();
          req.storeReturnId = storeReturnId;
        storeRtnService.getStoreReturnById(req).then((res) => {
            if(res.status){
              setStoreReturnData(res.data);
            }
          })
      }
  return (
    <>
    {(storeReturnData)?
        <Card title={<span style={{color:'white'}}>Store Return</span>}
        style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/store-return' ><Button className='panel_button' >View </Button></Link>}>
                <Descriptions column={4}>
                    <Descriptions.Item label="Store Return Date">
                    {moment(storeReturnData.storeReturnDate).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Store Return No">
                    {storeReturnData.storeReturnNo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sale Order">
                    {storeReturnData.saleOrder.saleOrderNumber}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={4}>
                    <Descriptions.Item label="Returned Qty">
                    {storeReturnData.returnQuantity}
                    </Descriptions.Item>
                    <Descriptions.Item label="Remarks">
                    {storeReturnData.remarks}
                    </Descriptions.Item>
                </Descriptions>

        </Card>
    :''}
    </>
  );
}

export default StoreReturnDetailView;

