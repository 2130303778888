import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, FormInstance, Popconfirm, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { RackService, UnitcodeService, ZoneService } from '@gtpl/shared-services/masters'
import { PlantsDropDown, UnitcodeDto, UnitTypeEnum, ZoneDto } from '@gtpl/shared-models/masters'
import {  SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoTranferDto } from '@gtpl/shared-models/sale-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { ColumnsType } from 'antd/lib/table';

export interface TransferRackProps {
    record:any,
    closeModal:(closeModal:boolean)=>void


}
export function TransferRack(props: TransferRackProps) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [disable, setDisable] = useState<boolean>(false)
    const [maxPalletCapacity, setMaxPalletCapacity] = useState<number>(0);
    const [filledCapacity, setFilledCapacity] = useState<number>(0);
    const [stockData, setStockData] = useState<any[]>([]);
    const [zonesData, setZonesData] = useState<ZoneDto[]>([]);
    const { Option } = Select;
    const fGStockService = new FGStockService();
    const [page, setPage] = React.useState(1);
    const rackService = new RackService();
    const zoneService = new ZoneService();


    useEffect(()=> {
        if(props.record){
            getZonesByPlant()
        }
    },[props.record])

    const getZonesByPlant = () => {
        zoneService.getPlantZones({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            if (res.status) {
                setZonesData(res.data);
            //   AlertMessages.getSuccessMessage('Success');
            } else {
              if (res.intlCode) {
                setZonesData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                setZonesData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
          })
    }
    const transferRack = (data : any) => {
        setDisable(true)
        console.log(data);
        data.allocatedPlantId = Number(localStorage.getItem('unit_id'));
        data.allocatedZoneId = Number(localStorage.getItem('unit_id'));
        data.rackId = props.record.rackId;
        rackService.transferRack(data).then((res) => {
          if (res.status) {
            props.closeModal(true);
            AlertMessages.getSuccessMessage('Success');
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }
   
    const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Transfer Rack </span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <Form layout={"vertical"} form={form} onFinish={transferRack}>
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item name="zoneId" label=" Cold Storage" rules={[{ required: true, message: 'Missing zone Id' }]} >
                                <Select
                                    showSearch
                                    placeholder="Select Zone Name"
                                    allowClear
                                >
                                    {zonesData?.map(dropData => {
                                        return <Option key={dropData.zoneId} value={dropData?.zoneId}>{dropData?.zoneName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary"disabled={disable} htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
}
export default TransferRack;
