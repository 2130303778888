export class SaleOrderItemsDropDownDto {
    saleOrderId: number;
    saleOrder: string;
    poNumber: string;
    itemsDetails: ItemsDetails[];

    /**
     * 
     * @param saleOrderItemId 
     * @param item 
     */
    constructor(saleOrderId:number,saleOrder: string,poNumber:string,
        itemsDetails: ItemsDetails[],){ 
        this.saleOrderId = saleOrderId
        this.saleOrder = saleOrder
        this.poNumber = poNumber
        this.itemsDetails = itemsDetails
        }
}


export class ItemsDetails{
    saleOrderItemId: number;
    item: string;
    varient?: string;
    variantId?: number;
    endCustomerId?: number;
    packStyleId?: number;


    constructor(saleOrderItemId: number,
        item: string,varient?:string,variantId?:number,endCustomerId?:number,packStyleId?:number){ 
        this.saleOrderItemId = saleOrderItemId
        this.item = item
        this.varient = varient
        this.variantId = variantId
        this.endCustomerId = endCustomerId
        this.packStyleId = packStyleId
        }
    }