export class AttendanceAdjustRequest {
    employeeCode: string;
    date: string;

    /**
     * 
     * @param employeeCode 
     * @param date 
     */

    constructor(employeeCode: string, date: string) {
        this.employeeCode = employeeCode;
        this.date = date

    }
}