import axios from 'axios';
import connection from './connection';
import { AllSizesResponse, CategoryItemReq, SizeRequest, SizesDropDownDto, SizesDropDownResponseModel, SizesDto, SizesResponse } from '@gtpl/shared-models/masters'

export class SizesService {
    URL = connection.DEPLOY_URL + '/sizes';


    async create(dto: SizesDto): Promise<SizesResponse> {

        console.log(dto);

        return await axios.post(this.URL + '/createSize', dto)
            .then(res => {
                return res.data
            })
    }


    async update(dto: SizesDto): Promise<SizesResponse> {
        return await axios.post(this.URL + '/updateSize', dto)
            .then(res => {
                return res.data
            })
    }


    async activatedeActivate(Dto: SizesDto): Promise<SizesResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateSize', Dto)
            .then(res => {
                return res.data
            })
    }



    async getAll(): Promise<AllSizesResponse> {

        return await axios.post(this.URL + '/getAllSizes')
            .then(res => {
                return res.data
            })
    }

    async getAllSizesDropDown(): Promise<SizesDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllSizesDropDown')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveSizesDropDown(): Promise<SizesDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllActiveSizesDropDown')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveSizesDropDownForCategory(req:CategoryItemReq): Promise<SizesDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllActiveSizesDropDownForCategory', req)
            .then(res => {
                return res.data
            })
    }
    
    async getSizeDataById(sizeReq: SizeRequest): Promise<SizesDropDownDto> {

        return await axios.post(this.URL + '/getSizeById', sizeReq)
            .then(res => {
                return res.data
            })
    }





}