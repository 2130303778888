export class ZoneDto {
    zoneId?: number;
    zoneName: string;
    zoneCode: string;
    isActive: boolean;
    createdUser : string;
    plantId?:number;
    plantName?:string;
    /**
     * 
     * @param zoneId number
     * @param zoneName string
     * @param zoneCode string
     * @param isActive boolean
     * @param createdUser string
     * @param plantId number
     */
    constructor(zoneId: number,zoneName: string,zoneCode: string,isActive: boolean,createdUser : string,plantId?:number,plantName?:string){ 
            this.zoneId = zoneId;
            this.zoneName = zoneName
            this.zoneCode=zoneCode;
            this.isActive=isActive;
            this.createdUser= createdUser;
            this.plantId=plantId;
            this.plantName=plantName;
        }
    }

export const ZoneDtoDefault : ZoneDto = {
     zoneId: 0,
    zoneName: "",
    zoneCode: "",
    isActive: true,
    createdUser : '',
    plantId : 0
};

