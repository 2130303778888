import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LabellingDto } from '.';

export class LabellingResponseModel extends GlobalResponseObject{
    data?: LabellingDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //LabellingDto
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: LabellingDto) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
