import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StoreConsumptionDetails } from './store-consumption.dto';

export class getStoreConsumptionsResponse extends GlobalResponseObject {
    data?: StoreConsumptionDetails;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: StoreConsumptionDetails){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}