import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { TransferMaterialRequest, PackingStockTransferData, AllTransferInfo, TransferIdRequest, SaleOrderIdRequest, GetAvailableStockResponse } from '@gtpl/shared-models/procurement-management';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class TransferMaterialService {
    URL = connection.DEPLOY_URL + '/transfer-material';

    async createTransferMaterial(createDto: PackingStockTransferData) {
        return await axios.post(this.URL + '/createTransferMaterial', createDto)
            .then(res => {
                return res.data
            })
    }

    async getTransferMaterialDCData(req: TransferMaterialRequest) {
        return await axios.post(this.URL + '/getTransferMaterialDCData', req)
            .then(res => {
                return res.data
            })
    }

    async getTransferMaterialMRNData(req: TransferMaterialRequest) {
        return await axios.post(this.URL + '/getTransferMaterialMRNData', req)
            .then(res => {
                return res.data
            })
    }

    async ReceiveStock(req: TransferIdRequest) {
        return await axios.post(this.URL + '/ReceiveStock', req)
            .then(res => {
                return res.data
            })
    }

    async TransferMaterialDetailInfo(req: TransferIdRequest) {
        return await axios.post(this.URL + '/TransferMaterialDetailInfo', req)
            .then(res => {
                return res.data
            })
    }


    async getSaleOrderRequiredItemDetails(saleOrderReq: SaleOrderIdRequest): Promise<GetAvailableStockResponse> {
        return await axios.post(this.URL + '/getSaleOrderRequiredItemDetails', saleOrderReq)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getTransferedStockDetails(req: TransferIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransferedStockDetails', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

}