
import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Input, Button, Form, Col, Row, Select, DatePicker, Empty, Typography, Statistic, Spin, Alert } from 'antd';
import { SearchOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { StockOutTransactionRequest } from '@gtpl/shared-models/warehouse-management';
import { StockTypesEnum, TransactionType, TransactionTypeDropDown, UnitRequest } from '@gtpl/shared-models/common-models';
import { isNull } from 'util';
const { Option } = Select;
export interface StockOutTransactionProps {

}

export function StockOutTransaction(props: StockOutTransactionProps) {
  const { Text } = Typography;
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [page, setPage] = React.useState(1);
  const [stockOutTransactionData, setStockOutTransaction] = useState<any>();
  const service = new FGStockService();
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const unitsService = new UnitcodeService();
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>()
  const localstorageUnitId = Number(localStorage.getItem('unit_id'));
  const [disable, setDisable] = useState<boolean>(false);
  // const [selectedunit, setUnit] = useState<number>(0)
  const [brandfiltersData, setFiltersData] = useState<any[]>([]);
  const [productfiltersData, setproductFiltersData] = useState<any[]>([]);
  const [unit, setUnit] = useState<any[]>([])
  const fgservice = new FGStockService();
  const [pageSize, setPageSize] = useState<number>(null)
  const [productData, setProductData] = useState<any[]>([]);
  const stockInService = new StockInService();
  let totalCart = 0
  let totalStockOutQty = 0;
  let transactionTypeDropDown = TransactionTypeDropDown;



  useEffect(() => {
    if( Number(localStorage.getItem('unit_id')) != 5){
      form.setFieldsValue({unitId:Number(localStorage.getItem('unit_id'))})
    }
    form.setFieldsValue({
      fromDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1,'days')),moment(moment().format("YYYY-MM-DD"))] 
    })
    setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1,'days')))
    setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
    getStockInSaleOrders();
    getStockOutTransaction();
    getUnitDropdownForStockInReport();
    getAllPlants();
    setEmpty(true); setDisable(false)
    getProductDropForStockReport();
  }, []);




  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  /**
  * 
  * @param pagination 
  * @param filters 
  * @param sorter 
  * @param extra 
  */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  
  const EstimatedETDDate = (value) => {
    console.log(value, 'value')
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
    }
  }
  const handleUnit=(value) =>{
    // setUnit(value)
  }

  const getProductDropForStockReport = () => {
    stockInService.getProductDropForStockReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setProductData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setProductData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProductData([]);

    });
  }

  const getStockOutTransaction = (onReset?: boolean) => {
    if (form.getFieldValue('unitId') || form.getFieldValue('saleOrderId') || form.getFieldValue('fromDate') || form.getFieldValue('transactionType') || form.getFieldValue('product') ) {
      setLoading(true); setEmpty(false); setDisable(true)
      const req = new StockOutTransactionRequest()
      const unitId = form.getFieldValue('unitId')
      const fromDate=form.getFieldValue('')
      let saleOrderId = form.getFieldValue('saleOrderId')
      let transactionType = form.getFieldValue('transactionType')

      if (localstorageUnitId == 5) {
        if (unitId) {
          req.unitId = unitId
        }
      } else {
        req.unitId = localstorageUnitId
      }
      if(form.getFieldValue('product')){
        req.product = form.getFieldValue('product')
      }
      if(form.getFieldValue('fromDate')){
        const selectedFromDate =  moment(form.getFieldValue('fromDate')[0]).format('YYYY-MM-DD');
        const selectedToDate = moment(form.getFieldValue('fromDate')[1]).format('YYYY-MM-DD');
        req.fromDate = onReset ? undefined : selectedFromDate;
        req.toDate = onReset ? undefined : selectedToDate;
   
      }
          req.saleOrderId = saleOrderId;
          req.transactionType = transactionType;
      service.getStockOutTransaction(req).then(res => {
        setDisable(false)
        if (res.status) {
          setStockOutTransaction(res.data)
          setLoading(false)
          for (const StockReport of res.data) {
            const packingStyle =  Math.round(StockReport.packingMethod).toFixed(1)
            StockReport.packingMethod = packingStyle
          }
           // data for brands filter
           let filtersArray:{text:string,value:string}[] = []
           let uniq = res.data.filter((thing, index, self) =>
           index === self.findIndex((t) => (
             t.brandName === thing.brandName
           ))
         );
       // data for products filter
       uniq.map(item => {
           if(!(filtersArray.includes({text:item.brandName,value:item.brandName})))
           filtersArray.push({text:item.brandName,value:item.brandName})
           })

           let productFiltersArray:{text:string,value:string}[] = []
           let produtsUniq = res.data.filter((thing, index, self) =>
           index === self.findIndex((t) => (
               t.product === thing.product
           ))
           );
           produtsUniq.map(item => {
               if(!(productFiltersArray.includes({text:item.product,value:item.product})))
               productFiltersArray.push({text:item.product,value:item.product})
               })
               setproductFiltersData( productFiltersArray.sort((a, b) => {
                let splittedKey1 = a.text.split('/');
                let splittedKey2 = b.text.split('/');
                let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                let key1 = splittedKey1[1] + `${numberKey1}`;
                let key2 = splittedKey2[1] + `${numberKey2}`;
                return (key1 > key2) ? 1 : -1
              })
              .sort((a, b) => {
                let splittedKey1 = a.text.split('/');
                let splittedKey2 = b.text.split('/');
                let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                return (numberKey1 - numberKey2) ? 1 : -1
              })
              )
           setFiltersData(filtersArray)
          //  console.log(productFiltersArray + "productFiltersArray");
          //  console.log(filtersArray+"filtersArray");

        } else {
          if (res.intlCode) {
            setStockOutTransaction([]);
            setLoading(false)
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setLoading(false)
          }
        }
      }).catch(err => {
        setStockOutTransaction([]);
        setLoading(false); setDisable(false)
      })
    }
    else {
      AlertMessages.getInfoMessage('Please select any input')
    }
  }
  const getUnitDropdownForStockInReport = () => {
    fgservice.getUnitDropdownForStockInReport().then((res) => {
      if (res.status) {
       setUnit(res.data);
      } else {
       setUnit(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const data = [
    { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },

    { title: 'Unit', dataIndex: 'plant' },
    { title: 'Purpose', dataIndex: 'jobPurpose' },
    { title: "Rack Position", dataIndex: "rackPositionCode" },
    { title: 'Customer PO', dataIndex: 'saleOrderNo' },
    { title: 'Created Date', dataIndex: 'transactionDate', render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: 'Brand', dataIndex: 'brandName' },
    { title: 'Product SKU', dataIndex: 'product' },
    { title: 'Product  Category', dataIndex: 'productCategory' },
    { title: 'Pack Style', dataIndex: 'packingMethod' },
    { title: 'Cartons', dataIndex: 'cartons' },
    { title: 'Stock Out Qty', dataIndex: 'stockOut' },
    { title: 'Pouch Shortage', dataIndex: 'pouchShortage' },
    { title: "Carton Shortage", dataIndex: "cartonShortage" },
  ]

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('StockOutTransaction')
      .addColumns(data)
      .addDataSource(stockOutTransactionData,{str2num:true})
      .saveAs('stock-out-transaction.xlsx');
  }


  const exportToPdf = () => {
    var columns = [
      { title: "Sno", dataKey: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },

      { title: 'Unit ', dataKey: 'plant' }, 
      { title: 'Purpose', dataIndex: 'jobPurpose' },
      { title: "Rack Position", dataKey: "rackPositionCode" },
      { title: 'Customer PO', dataKey: 'saleOrderNo' },
      { title: 'Created Date', dataKey: 'transactionDate', render: (value) => moment(value).format("DD-MM-YYYY") },
      { title: 'Brand', dataKey: 'brandName' },
      { title: 'Product SKU', dataKey: 'product' },
      { title: 'Product  Category', dataKey: 'productCategory' },
      { title: 'Pack Style', dataKey: 'packingMethod' },
      { title: 'Cartons', dataKey: 'cartons' },
      { title: 'Stock Out Qty', dataKey: 'stockOut' },
      { title: 'Pouch Shortage', dataKey: 'pouchShortage' },
      { title: "Carton Shortage", dataKey: "cartonShortage" },

    ];
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, stockOutTransactionData, {
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      addPageContent: function (data) {
        doc.text("STOCK OUT TRANSACTION", 50, 15);
      }
    });
    doc.save('stock-out-transaction.pdf')
  }



  const getStockInSaleOrders = () => {
    setSoItemData([])
    const unitId = Number(localStorage.getItem('unit_id'))
    const req = new UnitRequest(unitId)

    if (unitId != 5) {

      service.getAllStockOutSaleOrders(req).then((res) => {
        if (res.status) {
          setSoItemData(res.data);
        } else {
          if (res.intlCode) {
            setSoItemData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
        .catch((err) => {
          setSoItemData([]);
          AlertMessages.getErrorMessage(err.message);
        });
    } else {
      const selectedUnitId = form.getFieldValue('unitId');
      if (selectedUnitId) {
        const req = new UnitRequest(selectedUnitId)
        service.getAllStockOutSaleOrders(req).then((res) => {
          if (res.status) {
            setSoItemData(res.data);
          } else {
            if (res.intlCode) {
              setSoItemData([]);
              // AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        })
          .catch((err) => {
            setSoItemData([]);
            AlertMessages.getErrorMessage(err.message);
          });
      }
    }



  }

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        if (localstorageUnitId == 5) {
       setPlantData(res.data);
      } else {
        setPlantData(res.data.filter(i => i.plantId == localstorageUnitId))
      }

    } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }



  const onReset = () => {
    form.resetFields();
    if( Number(localStorage.getItem('unit_id')) != 5){
      form.setFieldsValue({unitId:Number(localStorage.getItem('unit_id'))})
    }
    // setSoItemData([])
    setSelectedEstimatedFromDate(undefined);
    setSelectedEstimatedToDate(undefined);
    setStockOutTransaction([])
    setEmpty(true)
  }

  const columnsSkelton: ColumnProps<any>[] = [

    {
      title: 'S No',
      key: 'sno',
      width: 70,
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'plant',
      width: 90,
      ...getColumnSearchProps('plant'),
    },
    {
      title: 'Purpose',
      dataIndex: 'jobPurpose',
      width: 120,
      responsive: ["lg"],
      filters: [
        { text: 'Dispatch', value: 'dispatch' },
        { text: 'Repacking', value: 'repacking' },
        { text: 'Reprocessing', value: 'reprocessing' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.jobPurpose == value;
      },
    },
    {
      title: 'Rack Position',
      dataIndex: 'rackPositionCode',
      width: 120,
      ...getColumnSearchProps('rackPositionCode'),
      sorter: (a, b) => a.rackPositionCode.toString().length - b.rackPositionCode.toString().length || a.rackPositionCode.toString().localeCompare(b.rackPositionCode.toString()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrderNo',
      width: 120,
      sorter: (a, b) => a.saleOrderNo?.localeCompare(b.saleOrderNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrderNo')
    },
    {
      title: 'Allocated PO',
      dataIndex: 'allocatedPO',
      width: 120,
      sorter: (a, b) => a.allocatedPO?.localeCompare(b.allocatedPO),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('allocatedPO')
    },
    {
      title: 'Created Date',
      dataIndex: 'transactionDate',
      width: 120,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.transactionDate).unix() - moment(b.transactionDate).unix(),
      render: (value, record) => {
        return <span>
          {record.transactionDate ? moment(record.transactionDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      width: 120,
      // sorter: (a, b) => a.brandName?.localeCompare(b.brandName),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('brandName')
      filters: brandfiltersData,
            
      filterMultiple: true,
      onFilter: (value, record) => {
          return record.brandName == value;
      },
    },
    {
      title: 'Product SKU',
      dataIndex: 'product',
      width: 220,
      // ...getColumnSearchProps('product')
      filters: productfiltersData,
            
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.product == value;
            },
            render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    
    },

    {
      title: 'Product Category',
      dataIndex: 'productCategory',
      width: 120,
      // sorter: (a, b) => a.productCategory.length - b.productCategory.length || a.productCategory.localeCompare(b.productCategory),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('productCategory'),
      responsive: ["lg"],
      filters: [
        { text: 'overcount', value: 'overcount' },
        { text: 'Under Count', value: 'Under Count' },
        { text: 'g1', value: 'g1' },
        { text: 'g2', value: 'g2' },
        { text: 'Cut', value: 'Cut' },
        { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.productCategory == value;
      },
    },
    {
      title: 'Pack Style',
      dataIndex: 'packingMethod',
      width: 120,
      // sorter: (a, b) => a.packingMethod?.localeCompare(b.packingMethod),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packingMethod')
    },
    {
      title: 'Cartons',
      dataIndex: 'cartons',
      width: 100,
      align: 'right',
      ...getColumnSearchProps('cartons')

    },
    {
      title: 'Stock Out Cartons',
      dataIndex: 'stockOut',
      width: 100,
      align: 'right',
      // sorter: (a, b) => a.stockOut?.localeCompare(b.stockOut),
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('stockOut')
    },
    {
      title: "Quantity (IN LB's)",
      dataIndex: 'totalQty',
      width: 100,
      align: 'right',
      render: (value, record) => {
        return <span>
          {record.totalQty ? Math.ceil(record.totalQty).toLocaleString(undefined, { maximumFractionDigits: 0 }) : '-'}
        </span>
      }
      // sorter: (a, b) => a.totalQty?.localeCompare(b.totalQty),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('totalQty')
    },
    {
      title: 'Pouches Shortage',
      dataIndex: 'pouchShortage',
      width: 100,
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.pouchShortage === value;
      },

    },
    {
      title: 'Cartons Shortage',
      dataIndex: 'cartonShortage',
      width: 100,
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.cartonShortage === value;
      },
    },
  ];

  return (
    <Card title={<span style={{ color: 'white' }}>Stock Out Transaction</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout='vertical' form={form}  >
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item
              name="unitId"
              label=" Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                onSelect={getStockInSaleOrders}
                allowClear
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id'))!=5 ? true : false}
              >
                {unit.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="saleOrderId"
              label="Customer PO "
            >
              <Select
                showSearch
                placeholder="Select Sale Order"
                optionFilterProp="children"
                allowClear
              // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {soItemData.map((data) => {
                  return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:6}} xl={{span:6}}>
            <Form.Item
              name="transactionType"
              label="Transaction Type"
              rules={[
                {
                  required: false, message: 'Missing Transaction Type',
                },
              ]}

            >
              <Select
                showSearch
                placeholder="Select Sale Order"
                optionFilterProp="children"
                allowClear
                mode="multiple"
              >
                  <Option value={null}>select All</Option>
                  <Option value="dispatch">Dispatch</Option>
                  <Option value="repacking">Re packing</Option>
                  <Option value="reprocessing">Re processing</Option>

                {/* {transactionTypeDropDown.map(transactionType => {
                  return <Option value={transactionType.value}>{transactionType.name}</Option>
                })} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="fromDate"
              label="Created Date"
              initialValue={undefined}
              rules={[
                {
                  required: false,
                  message: " ETD Date"
                },
              ]}>
              <RangePicker onChange={EstimatedETDDate}  />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="product"
              label="Product SKU"
              rules={[
                {
                  required: false,
                  message: 'product is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {productData?.map(productsku => {
                  return <Option key={productsku.varientId} value={productsku.varientId}>{productsku.variantCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Button
              type="primary"
              // size="small"
              block
              onClick={() => getStockOutTransaction()}
              style={{ marginRight: 2, width: 100 }}
              disabled={disable}
            >
              Get Report
            </Button>
            <Button type="primary" htmlType="submit" onClick={onReset}>
              Reset
            </Button>
          </Col>
          {/* <Button icon={<DownloadOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }} >
            Get Excel
          </Button>
          <Button icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
            Get PDF
          </Button> */}

        </Row>
      </Form> <br /><br />
      {
          stockOutTransactionData?.length > 0 ?
            <>
              {
                stockOutTransactionData?.forEach(item => {
                  totalCart = item.cartons + totalCart
                  totalStockOutQty = Number(item.stockOut) + Number(totalStockOutQty)
                  console.log(item.stockOut, 'stock out')
                })
              }
              {console.log(totalStockOutQty, 'totalStockOutQty')}
              <Card title='Summary'>
                <Row gutter={24}>
                  <Col span={4}>
                    <Statistic title="Total Transactions:" value={stockOutTransactionData.length}></Statistic>
                  </Col>
                  <Col span={4}>
                    <Statistic title="Total Cartons:" value={totalCart}></Statistic>
                  </Col>
                  <Col span={6}>
                    <Statistic title="Total StockOut Cartons:" value={totalStockOutQty}></Statistic>
                  </Col>
                  <Col>
                    <Button icon={<DownloadOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }}>
                      Get Excel
                    </Button>
                    <Button  icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
                      Get PDF
                    </Button>
                  </Col>
                </Row>

              </Card>

              <Table
                rowKey={record => record.productId}
                columns={columnsSkelton}
                dataSource={stockOutTransactionData}
                pagination={{
                  onChange(current, pageSize) {
                    setPage(current);
                    setPageSize(pageSize)
                  }
                }}
                onChange={onChange}
                scroll={{ x: 1200, y: 500 }}
                bordered
                summary={(pageData) => {
                  let pageCartons = 0;
                  let stockOutCar = 0;
                  let totalQtyInLb = 0;

                  pageData.forEach(({ cartons, stockOut, totalQty }) => {
                    pageCartons += Number(cartons);
                    stockOutCar += Number(stockOut);
                    totalQtyInLb += Number(totalQty);
                  });

                  return (
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={5} colSpan={9}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={2}><Text>Total Cartons</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11}>< Text style={{ textAlign: 'end' }}>{pageCartons.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}>< Text style={{ textAlign: 'end' }}>{stockOutCar.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}>< Text style={{ textAlign: 'end' }}>{totalQtyInLb.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text></Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </> :
            <Empty description={empty ? 'Select inputs and click Get Report to retreive data' : 'No data found'}></Empty> 
      }
    </Card>

  );
}

export default StockOutTransaction;