import { RackCellMapDto } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class RackCellMapResponseModel extends GlobalResponseObject {
    data?: RackCellMapDto;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /RackDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: RackCellMapDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 