import React, { useEffect, useState, useRef } from 'react';
import { Table, Card, Row, Col, Input, Button, Empty, Divider, Tooltip, Modal } from 'antd';
import { SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';
import {ProductService} from '@gtpl/shared-services/masters';

import { ColumnProps } from 'antd/lib/table';
import './beheading-inventory-grid.css';
import { useHistory } from 'react-router-dom';
import { OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { StockTransfer } from '@gtpl/pages/production-management/production-management-components/stock-transfer';
import Link from 'antd/lib/typography/Link';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import {
  InProgressBeHeadingDto,
  OperationRequest,
  BeheadingInventoryDto,
  LotNumberRequest,
  ProductionInventoryDetails,
} from '@gtpl/shared-models/production-management';
import {
  BeheadingService,
  ProdInvService,
  ProductionInventoryService,
  WorkstationService,
} from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Highlighter from 'react-highlight-words';
import { ProductDto, ProductsDropDownData } from '@gtpl/shared-models/masters';
import { LotDetailView } from '@gtpl/pages/production-management/production-management-components/lot-detail-view'

/* eslint-disable-next-line */
export interface BeheadingInventoryGridProps { }



export function BeheadingInventoryGrid(props: BeheadingInventoryGridProps) {
  const beheadService = new BeheadingService();
  const [behead, setBeheadData] = useState<ProductionInventoryDetails[]>([]);
  const [selectedInformation, setSelectedInformation] = useState<ProductionInventoryDetails>();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [productsData, setProductsData] = useState<ProductDto[]>([]);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [noOfBoxes, setNoOfBoxes] = useState<number>(0);
  const [qtyToBeProcessed, setQtyToBeProcessed] = useState<number>(0);
  const [inventoryQty, setInventoryQty] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saleId, setSaleId] = useState(0);
  const [lotINo, setLotINo] = useState('lotNumber');
  const beheadingService = new BeheadingService();
  const inventoryService = new ProductionInventoryService();
  const activeProducts =  new ProductService();

  useEffect(() => {
    getBeheadingInventory();
    getNoOfAvailableBoxes();
    getQtyToBeProcessed();
    getInventoryQty();
    getAllActiveProducts();
    
  }, []);

  const getNoOfAvailableBoxes = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getNoOfAvailableBoxes(lotNoReq).then((res) => {
      if (res.status) {
        setNoOfBoxes(res.data);
      } else {
        setNoOfBoxes(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setNoOfBoxes(0);
    });
  }
  const getAllActiveProducts = () => {
    activeProducts.getAllActiveProducts().then((res) => {
      if (res.status) {
        setProductsData(res.data);
        let filtersArray:{text:string,value:string}[] = []
        res.data.map(item => {
          if(!(filtersArray.includes({text:item.productName,value:item.productName})))
          filtersArray.push({text:item.productName,value:item.productName})
        })
        setFiltersData(filtersArray)

        console.log(res.data)
        // console.log(getProductFilters())
      } else {
        setProductsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    setProductsData([]);
    });
  }
  

  const getQtyToBeProcessed = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getQtyToBeProcessed(lotNoReq).then((res) => {
      if (res.status) {
        setQtyToBeProcessed(res.data);
      } else {
        setQtyToBeProcessed(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setQtyToBeProcessed(0);
    });
  }

  const getInventoryQty = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getInventoryQty(lotNoReq).then((res) => {
      if (res.status) {
        setInventoryQty(res.data);
      } else {
        setInventoryQty(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setInventoryQty(0);
    });
  }
  let history = useHistory();

  const getBeheadingInventory = () => {
     const beheadInvReq = new UnitRequest(Number(localStorage.getItem('unit_id'))
    );
    beheadService.getBeheadingInventoryInfo(beheadInvReq).then((res) => {
        if (res.status) {
         setBeheadData(res.data);          

        } 
        else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  var sum = behead.reduce(function (previousValue, currentValue) {
    return previousValue + Number(currentValue.physicalQuantity);
  }, 0);
 const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  const getProductFilters = () => {
    productsData.map(dropData => 
      {
      return [
      {
        text:dropData.productName,
        value:dropData.productId,
       },
      ]
    }
    )
  }
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }
  const goToLotDetailView = (lotNumber: string) => {
    setLotINo(lotNumber);
    console.log("lotNo", lotNumber);
    setIsModalVisible(true)
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Lot Code',
      dataIndex: 'lotandplantlot',
      sorter: (a, b) => a.lotandplantlot.localeCompare(b.lotandplantlot),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotandplantlot'),
      render: (value, record) =>
        <Link onClick={e => goToLotDetailView(record.lotNumber)} >{record.lotandplantlot} </Link>
    },
    
    // {
    //   title: 'So Number',
    //   dataIndex: 'saleOrder',
    //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrder'),
     
  
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
    },
    {
      title: 'Product',
      dataIndex: 'product',
      // sorter: (a, b) => a.product.localeCompare(b.product),
      // sortDirections: ['descend', 'ascend'],
      render:(text,data) => {
        console.log(data)
        return text
      },
      
      // ...getColumnSearchProps('product'),
      

      filters: filtersData
       ,
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        console.log(value)
        // === is not work
        return record.product === value;
      },
       
    },
    {
      title: 'I/P Count',
      dataIndex: 'ipCount',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'I/P Qty',
      dataIndex: 'physicalQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },

    {
      title: 'WIP QTY',
      dataIndex: 'wipQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Intransit QTY',
      dataIndex: 'intransitQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Wastage QTY',
      dataIndex: 'wastageQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Issued QTY',
      dataIndex: 'issuedQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Transfered QTY',
      dataIndex: 'transferedQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Conversion QTY',
      dataIndex: 'conversionQty',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Rejected QTY',
      dataIndex: 'rejectedQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'QTY',
      dataIndex: 'qty',
      responsive:['md'],
      render: (value, rowData:ProductionInventoryDetails) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}> {value}</div>
      }
    },
  ];

  const displayModel = (data:ProductionInventoryDetails) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Card
      title={<span style={{ color: 'white' }}>Deheading Inventory</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'No. of Lots Available: ' + Number(noOfBoxes[0]?.noOfRecords).toLocaleString(undefined, {maximumFractionDigits:2})}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Quantity To be Processed :' + Number(Math.round(qtyToBeProcessed[0]?.qty)).toLocaleString(undefined, {maximumFractionDigits:2})}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Inventory: ' + Number(Math.round(inventoryQty[0]?.qty)).toLocaleString(undefined, {maximumFractionDigits:2})}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
      {behead.length ? <Table
        columns={columnsSkelton}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              {/* <Table.Summary.Cell index={4}></Table.Summary.Cell> */}
              <Table.Summary.Cell index={2}>{'Total'}</Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>

              <Table.Summary.Cell index={4}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.ipQty);
              }, 0)}</Table.Summary.Cell>

              <Table.Summary.Cell index={5}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.boxes);
              }, 0)}</Table.Summary.Cell>

              <Table.Summary.Cell index={6}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.opBoxes);
              }, 0)}</Table.Summary.Cell>

              <Table.Summary.Cell index={7}>
                {behead.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.wipQuantity);
                }, 0)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={8}>
                {behead.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.intransitQuantity);
                }, 0)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={9}>
                {behead.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.wastageQuantity);
                }, 0)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={10}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.issuedQuantity);
              }, 0)}</Table.Summary.Cell>

              <Table.Summary.Cell index={11}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.transferedQuantity);
              }, 0)}</Table.Summary.Cell>

             <Table.Summary.Cell index={12}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.conversionQty);
              }, 0)}</Table.Summary.Cell>


              <Table.Summary.Cell index={13}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.rejectedQuantity);
              }, 0)}</Table.Summary.Cell>

              <Table.Summary.Cell index={14}>{behead.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.qty);
              }, 0)}</Table.Summary.Cell>

            </Table.Summary.Row>
          </Table.Summary>
        )}
        dataSource={behead}
        rowKey={(record) => record.plantId}
        bordered
        scroll={{ x: 1500 }}
      /> : <Empty />}
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
         <SoInfoViewPages saleOrderId={saleId} screen="production"
          />
         <LotDetailView lotNumber={lotINo} />
        {/* <StockTransfer operation={OperationTypeEnum.BE_HEADING} inventoryInfo={selectedInformation}/> */}
      </Modal>

      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}></Row>
    </Card>
  );
}

export default BeheadingInventoryGrid;
