export class GetAllAvailableLeavesDto {
    employeeId: number;
    typeOfLeave: number;
    
    /**
     * 
     * @param employeeId 
     * @param typeOfLeave 
     */
    constructor(employeeId: number,typeOfLeave: number){
        this.employeeId = employeeId;
        this.typeOfLeave = typeOfLeave;
    }
}