import { CategoryEnum, DownTimePlannedCategoryenum, DownTimeTrackingStatusEnum } from "@gtpl/shared-models/common-models";


export class DownTimeTrackingDto{

    downtimeTrackingId?:number;
    departmentId: number;
    category:CategoryEnum;
    startTime: any;
    reasonId:number;
    status:DownTimeTrackingStatusEnum;
    endtime:any;
    remarks:string;
    saleorderId: number;
    machineName:string;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    resources:number;
    employeeId:number;
    assetId:number;
    unitId:number;
    machineCode:string;
    resolution:string;
    reason?:string;
    downtimetype:DownTimePlannedCategoryenum;
    plannedtime:any;
    sla?:number;
    diffhours?:Date;
    departmentname?:string;
    hours?:Date;
    employeeName?:string;
    assetCategoryId?:number
//   downTimeTrackingViewData: any;

    constructor(downtimeTrackingId:number,departmentId:number,
        category:CategoryEnum,
        startTime:any,
        reasonId:number,
        resources:number,
        status:DownTimeTrackingStatusEnum,
        endTime:any,
        remarks:string,saleorderId:number,
        employeeId:number,
        assetId:number,
        machineName:string,
        isActive:boolean,
        createdAt:Date,
        unitId:number,
       machineCode:string,
       resolution:string,
        downtimetype:DownTimePlannedCategoryenum,
        plannedtime:any,
        departmentname:string,
        hours:any,
        assetCategoryId:number,
        createdUser:string,reason?:string,diffhours?:any)
        {
            this.downtimeTrackingId=downtimeTrackingId;
            this.departmentId=departmentId;
           this.category=category;
           this.startTime=startTime;
            this.reasonId=reasonId;
            this.status=status;
            this.endtime=endTime;
            this.remarks=remarks;
            this.machineName=machineName;
            this.isActive=isActive;
            this.createdAt=createdAt;
            this.createdUser=createdUser;
            this.resources=resources;
            this.saleorderId=saleorderId;
            this.reason=reason;
            this.unitId=unitId;
            this.assetId=assetId;
            this.machineCode=machineCode;
            this.resolution=resolution;
            this.plannedtime=plannedtime;
            this.downtimetype=downtimetype;
            this.diffhours=diffhours;
            this.departmentname=departmentname;
            this.hours=hours;
            this.employeeId=employeeId;
            this.assetCategoryId=assetCategoryId;
           
    }

}


