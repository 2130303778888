import { Button, Card, Col, Row } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useState, useEffect, useRef  } from 'react';
import { Link } from 'react-router-dom';

import './fg-stock-transfer-grid.css';

/* eslint-disable-next-line */
export interface FgStockTransferGridProps {}

export function FgStockTransferGrid(props: FgStockTransferGridProps) {

  const [page, setPage] = React.useState(1);
  const columns = useState('');


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'From Location',
      dataIndex: 'fromLocation',
      sorter: (a, b) => a.fromLocation.localeCompare(b.fromLocation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('countryName')
    },
    {
      title: 'Cartons',
      dataIndex: 'cortons',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('countryName')
    },
    {
      title: 'Select All',
      dataIndex: 'selectAll',
      // responsive: ['lg'],
      // sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('countryName')
    },
   
  ];


  return (
    <Card title={<span style={{color:'white'}}>FG Stock</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/fg-stock-transfer' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>} >
     


     <Card >
      {/* <GetCumulatives cumulativeColumns={cumulativeSkelton} data={variantData}/> */}
      
        <Table
        
          columns={columnsSkelton}
          // dataSource={countryData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll = {{x:true}}
          // onChange={onChange}
          bordered />
        </Card>

      </Card > 
        );
}

export default FgStockTransferGrid; 




// import './resource-operation-efficiency-form.css';
// import { Form, Input, Button, Select, Card, Row, Col, InputNumber, Table, Tooltip, Divider } from 'antd';
// import { Link, useHistory } from "react-router-dom";
// import {SkuService} from '@gtpl/shared-services/masters';
// import { MinusCircleOutlined, PlusOutlined,BorderInnerOutlined } from '@ant-design/icons';
// import { SkuDto } from '@gtpl/shared-models/masters';
// import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
// import { ResourceOperationEfficiencyDto, VariantEfficiencyRequest} from '@gtpl/shared-models/planning'
// import { ResourceOpEfficiencyService } from '@gtpl/shared-services/planning';
// import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
// import { OperationService,VariantOperationsService } from '@gtpl/shared-services/production';
// import { VarientIdRequest } from '@gtpl/shared-models/production-management';
// import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';

// const {Option} = Select


// /* eslint-disable-next-line */
// export interface ResourceOperationEfficiencyFormProps {
//   resourceOpEfficiency: ResourceOperationEfficiencyDto;
//   isUpdate: boolean;
//   closeForm: () => void;
//   updateResourceOpEfficiency: (opEfficiency: ResourceOperationEfficiencyDto) => void;
// }

// export function ResourceOperationEfficiencyForm(
//   props: ResourceOperationEfficiencyFormProps
// ) {
//   const skuService = new SkuService();
//   const [form] = Form.useForm()
//   const [skucodes,setSkuCode] = useState<SkuDto[]>([])
//   const [btnChange, setBtnChange] = useState<boolean>(false);
//   const [btnDisable, setBtnDisable] = useState<boolean>(false);
//   const operationService = new OperationService()
//   const [tableData,setTableData] = useState<ResourceOperationEfficiencyDto[]>([
//     // {
//     //   operation:undefined ,
//     //   variantCode:null,
//     //   minCount:null,
//     //   maxCount:null,
//     //   efficiency:null,
//     //   createdUser:'',
//     // },
//     // {
//     //   operation:OperationTypeEnum.BE_HEADING ,
//     //   variantCode:null,
//     //   minCount:21,
//     //   maxCount:40,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.BE_HEADING ,
//     //   variantCode:null,
//     //   minCount:41,
//     //   maxCount:60,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.BE_HEADING ,
//     //   variantCode:null,
//     //   minCount:61,
//     //   maxCount:80,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.BE_HEADING ,
//     //   variantCode:null,
//     //   minCount:81,
//     //   maxCount:100,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.BE_HEADING ,
//     //   variantCode:null,
//     //   minCount:101,
//     //   maxCount:300,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:0,
//     //   maxCount:20,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:21,
//     //   maxCount:40,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:41,
//     //   maxCount:60,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:61,
//     //   maxCount:80,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:81,
//     //   maxCount:100,
//     //   efficiency:null,
//     //   createdUser:''
//     // },
//     // {
//     //   operation:OperationTypeEnum.VALUE_ADDITION,
//     //   variantCode:null,
//     //   minCount:101,
//     //   maxCount:300,
//     //   efficiency:null,
//     //   createdUser:''
//     // }
//   ])
//   const [showTable, setShowTable] = useState<boolean>(false);

//   let history = useHistory();

//   let createdUser = "";
//   if (!props.isUpdate) {
//     createdUser = localStorage.getItem("createdUser");
//   }

//   // let unitId = "";
//   // if (!props.isUpdate) {
//   //   unitId = localStorage.getItem("unit_id");
//   // }

//   useEffect(()=>{
//     getSkuCodes();

//   },[])

//   useEffect(()=>{
//     console.log(tableData);
//     if(tableData.length > 0){
//       setShowTable(true)
//     }
//   },[tableData])



//   const getSkuCodes = () => {
//     skuService.getActiveSkuCodes().then((res) => {
//       if (res.status) {
//         setSkuCode(res.data);
//       }
//       else {
//         setSkuCode([]);
//       }
//     })
//       .catch((err) => {
//         AlertMessages.getErrorMessage(err.message);
//         setSkuCode([]);
//       });
//   }

//   const getOpeartions = (val) => {
//     operationService.getAllOperations(new VarientIdRequest(val)).then((res) => {
//       if (res) {
//         console.log(res.data)
//         const dynTableData = []
//         console.log(dynTableData);
//         res.data.forEach((element,index) => {
//           console.log(element)
//           console.log(index)
//           dynTableData.push({operation:element.operationName})
//         });
//         setTableData(dynTableData);
//       }
//       else {
//         // if (res.intlCode) {
//         //   AlertMessages.getErrorMessage(res.internalMessage);
//         // } else {
//         //   AlertMessages.getErrorMessage(res.internalMessage);
//         // }
//         setTableData([]);
//       }
//     })
//       .catch((err) => {
//         AlertMessages.getErrorMessage(err.message);
//         setTableData([]);
//       });
//   }

//   const onReset = () => {
//     form.resetFields();
//   }

//   const saveData = (values) => {
//     console.log(props.isUpdate)
//     setBtnDisable(true)
//     if (props.isUpdate) {
//       console.log(values);
//       updateResourceOpEfficiency(tableData);
//       // props.updatePoduct(values);
//     } else {
//       let effFlag:boolean[] = []
//       tableData.forEach(element => {
//         console.log(element)
//         if(element.efficiency > 0 && element.minCount>=0 && element.maxCount>0){
//           effFlag.push(true)
//           console.log(effFlag)
//         }else{
//           effFlag.push(false)
//           console.log(effFlag)

//         }

//       });
//       if(effFlag.includes(false)){
//         AlertMessages.getErrorMessage('Please fill all ranges efficiency for all operations');
//       }else{
//         console.log(tableData);
//         createResourceOpEfficiency(tableData);
//       }

//     }

//   };

//   const resourceOpEffService = new ResourceOpEfficiencyService()

//   const createResourceOpEfficiency = (tableData: ResourceOperationEfficiencyDto[]) => {

//     resourceOpEffService.createResourceOperationEfficiency(tableData).then(res => {
//       if (res.status) {
//         AlertMessages.getSuccessMessage('Resource Operation Efficiency Created Successfully');
//         setBtnDisable(false)

//         // history.push("/resource-op-efficiency-view");
//         // onReset();
//       } else {
//         if (res.intlCode) {
//           AlertMessages.getErrorMessage(res.internalMessage);
//           setBtnDisable(false)
//         } else {
//           AlertMessages.getErrorMessage(res.internalMessage);
//           setBtnDisable(false)
//         }
//       }
//     }).catch(err => {
//       AlertMessages.getErrorMessage(err.message);
//     })
//   }

//   const updateResourceOpEfficiency = (tableData: ResourceOperationEfficiencyDto[]) => {

//     resourceOpEffService.updateResourceOperationEfficiency(tableData).then(res => {
//       if (res.status) {
//         AlertMessages.getSuccessMessage('Resource Operation Efficiency Updated Successfully');
//         setBtnDisable(false)
//         // history.push("/resource-op-efficiency-view");
//         // onReset();
//       } else {
//         if (res.intlCode) {
//           AlertMessages.getErrorMessage(res.internalMessage);
//           setBtnDisable(false)
//         } else {
//           AlertMessages.getErrorMessage(res.internalMessage);
//           setBtnDisable(false)
//         }
//       }
//     }).catch(err => {
//       AlertMessages.getErrorMessage(err.message);
//     })
//   }

//   const getEfficiencyData = (val,row) => {
//     console.log(val)
//     console.log(row)
//     setTableData([]);
//     resourceOpEffService.getEfficiencyByVariant(new VariantEfficiencyRequest(val)).then(res => {
//       if (res.status) {
//         setBtnChange(true);
//         setTableData(res.data)
//         // setShowTable(true)
//         props.isUpdate=true;
//       } else {
//         setBtnChange(false);
//         getOpeartions(row.variantId)
//         // setShowTable(true)
//       }
//     }).catch(err => {
//       AlertMessages.getErrorMessage(err.message);
//     })

//   }

//   const onSearch = () => {

//   }

//   const storeEfficiency = (e, index,data) => {
//     console.log(e)
//     console.log(index)
//     console.log(data)
//     const dynTableData = [...tableData];
//     dynTableData[index].efficiency = e
//     dynTableData[index].variantCode = form.getFieldValue('variantCode')
//     dynTableData[index].createdUser = JSON.parse(localStorage.getItem('username'));
//     setTableData(dynTableData);
//   }

//   const storeCount = (val,label,index) => {
//     console.log(val)
//     console.log(label)
//     const dynTableData = [...tableData];
//     if(label == 'minCount'){
//       dynTableData[index].minCount = val
//     }
//     if(label == 'maxCount'){
//       console.log(val)
//       console.log(dynTableData[index].minCount)
//       if(Number(val) <= Number(dynTableData[index].minCount)){
//         AlertMessages.getWarningMessage('max should greater than min')
//       }else{
//         dynTableData[index].maxCount = val
//       }
//     }
//     setTableData(dynTableData);

//   }

//   const deleteData = (index) => {
//     tableData.splice(index, 1);
//     setTableData([...tableData]);
//     if (tableData.length == 0) {
//       setShowTable(false)
//     }
//   }

//   const addRowData = (rowData,index) => {
//     console.log(rowData)
//     console.log(index)
//     const newRowData = {
//       operation:rowData.operation,
//       minCount:null,
//       maxCount:null,
//       efficiency:null,
//       variantCode:rowData.variantCode,
//       createdUser:rowData.createdUser,
//     }
//     index=index+1
//     const newTable = [...tableData,newRowData]
//     tableData.forEach(element => {
//       console.log(element)
//     });
//     setTableData(newTable);
//   }

//   const defaultColumns = [
    
//     {
//       title: 'Operation',
//       dataIndex: 'operation',
//       key: 'operation',
//       width: '25%',
//       colspan:5
//     },
//     {
//       title: 'Count Range',
//       dataIndex: 'minCount',
//       // key: 'minCount',
//       width: '25%',
//       render: (text, data, index) => {
//         return <span>
//           <Input.Group compact>
//               <InputNumber defaultValue={text} onBlur={e => storeCount(e.target.value, ('minCount'),index)}  style={{ width: "35%" }} placeholder="Min" min={0}  />

//               <Input style={{ width: "30%" }}
//                 placeholder="~"
//                 disabled
//               />
//               <InputNumber defaultValue={data.maxCount} onBlur={e => storeCount(e.target.value, ('maxCount'),index)} style={{ width: "35%" }} placeholder="Max" min={0}  />
//           </Input.Group>
//         </span>
//       }
//     },
//     {
//       title: 'Efficiency',
//       dataIndex: 'efficiency',
//       width: '25%',
//       render: (text, data, index) => {
//         return <span><InputNumber style={{ width: '70%' }}  min={0} defaultValue={text} onChange={e => storeEfficiency(e, index,data)} /></span>
//         // return <span>{data.brandId.children}</span>
//     }
//     },
//     {
//       title: 'Action',
//       dataIndex: 'action',
//       width: '25%',
//       render: (text, rowData: any, index) => (
//         <span>
//           <Tooltip placement="top" title='Add Row'>
//             <Button style={{backgroundColor:"#aed581",width:"55px"}} htmlType="button"  onClick={() => {
//                 // if (rowData) {
//                 addRowData(rowData, index);
//                 // }
//               }}>
//             <BorderInnerOutlined  type="edit" 
//               // onClick={() => {
//               //   // if (rowData) {
//               //   addRowData(rowData, index);
//               //   // }
//               // }}
//               style={{ color: "black", fontSize: '14px' }}
//             />
//             </Button>
//           </Tooltip>

//           <Divider type="vertical" />
//           <Tooltip placement="top" title='Delete Row'>
//             <Button style={{backgroundColor:"#e53935",color:"white",width:"55px"}} onClick={() => {
//               if (rowData) {
//                 deleteData(index)
//                 // openFormWithData(rowData);
//               }
//             }}>
              
//             <DeleteOutlined 
//               style={{ color:"white", fontSize: '14px' }} />
              
//             </Button>
//           </Tooltip>

//         </span>)
//     }


//   ]

//   const columns = [
    
//     {
//       title: 'Operation',
//       dataIndex: 'operation',
//       key: 'operation',
//       colspan:5
//     },
//     {
//       title: 'Count Range',
//       dataIndex: 'minCount',
//       key: 'minCount',
//       render: (text, data, index) => {
//         return <span>{`${text} - ${data.maxCount} `}</span>
//       }
//     },
//     {
//       title: 'Efficiency',
//       dataIndex: 'efficiency',
//       render: (text, data, index) => {
//         console.log(data);
//         return <span><InputNumber min={0} style={{ width: '70%' }} defaultValue={text} onChange={e => storeEfficiency(e, index,data)} /></span>
//         // return <span>{data.brandId.children}</span>
//     }
//     },

//   ]
  
//   return (
//     <Card title={<span style={{ color: 'white' }}>Resource Operation Efficicency</span>}
//       style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={props.isUpdate == true ? "" : <Link to='/resource-op-efficiency-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >


//       <Form form={form}  name="control-hooks" onFinish={saveData}
//         layout="vertical"
//       >
//         <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
//           <Input hidden />
//         </Form.Item>
//         {/* <Form.Item style={{ display: "none" }} name="unitId" initialValue={unitId}>
//           <Input hidden />
//         </Form.Item> */}

//         <Row gutter={24}>
//         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}} >
//             <Form.Item
//               name="variantCode"
//               label="Variant Code"
//               rules={[
//                 {
//                   required: true,
//                   message: 'Variant Code Required',

//                 },
                
//               ]}>
//               <Select
//                 showSearch
//                 // style={{ width: 200 }}
//                 placeholder="Select Variant Code"
//                 optionFilterProp="children"
//                 onSearch={onSearch}
//                 filterOption={(input, option) =>
//                   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                 }
//                 onChange={getEfficiencyData}
//               >
//                {skucodes.map(skudropData => {
//                       return <Option variantId = {skudropData.skuCodeId} label={'varientId'} key={skudropData.variantCode} value={skudropData.variantCode}>{skudropData.variantCode}</Option>
//                     })}
//               </Select>
//             </Form.Item>
//           </Col>
         
//         </Row>
        
//         {
//           showTable ?
//         <Card>
//         <Table  dataSource={tableData} columns={defaultColumns}  pagination={false} />
//         </Card>
//         :<></>
//         }
//         <Row>
//           <Col span={24} style={{ textAlign: 'right' }}>

//             <Button type="primary"  htmlType='submit'>
//               {btnChange?'Update':'Create'}
//             </Button>
//           </Col>
//         </Row>
//       </Form>
//     </Card>
//   );
// }

// export default ResourceOperationEfficiencyForm;
