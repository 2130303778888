
export interface EmployeeRequest {
  Employee_code: string;
  is_active:boolean;
}
export interface EmployeeDTO {
  Employee_code: string;
  Employee_name: string;
  activated_date?: Date;
  deactivated_date?: Date;
  isActive: boolean;
  first_name: string;
  middle_name?: string;
  last_name: string;
  gender: string;
  uid_country: string;
  date_of_birth: Date;
  door_no: string;
  address_line_1: string;
  address_line_2?: string;
  land_mark?: string;
  Employee?: string;
  country?: string;
  zipcode?: string;
  location_type?: string;
  lat?: string;
  long?: string;
  land_line_no?: string;
  mobile_no: string;
  email?: string;
  location_uid?: string;
  user_uid?: string;
  contact_uid?: string;
  address_uid?: string;
}

export interface EmployeeCreateResponse {
  status: boolean;
  data?: EmployeeDTO;
  errorInfo?: string;
}

export interface GetEmployeesResponse {
  status: boolean;
  data?: EmployeeDTO[];
  errorInfo?: string;
}

export interface GetEmployeeResponse {
  status: boolean;
  data?: boolean;
  errorInfo?: string;
}

export const EmployeeDefaultData = {
  Employee_code: "",
  Employee_name: "",
  activated_date: new Date(),
  first_name: '',
  middle_name: '',
  last_name: '',
  gender: "",
  uid_country: '',
  isActive: true,
  date_of_birth: new Date(),
  door_no: '',
  address_line_1: '',
  address_line_2: '',
  land_mark: '',
  Employee: '',
  country: '',
  zipcode: '',
  location_type: '',
  lat: '',
  long: '',
  land_line_no: '',
  mobile_no: '',
  email: '',
  location_uid: '',
  user_uid: '',
  contact_uid: '',
  address_uid: '',
}



