import { WhatsAppMsgTypeEnum, WhatsappPriorityEnum, WhatsappSendToEnum } from '@gtpl/shared-models/common-models';
import { EmpLeaveDTO, GetEmpLeaves } from '@gtpl/shared-models/hrms';
import { CustomerDto } from '@gtpl/shared-models/masters';
import { AllGrnReportDto } from '@gtpl/shared-models/procurement-management';
import { EmployeeLeavesService } from '@gtpl/shared-services/hrms';
import { CustomersService, DepartmentService, HrmsEmpDetService } from '@gtpl/shared-services/masters';
import { EmployeeService } from '@gtpl/shared-services/attendance'
import { GrnService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Upload, UploadProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { WhatsAppOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { DepartmentRequest } from '@gtpl/shared-models/whatsapp-broad-cast';
import axios from 'axios';
import { WhatsappBroadCastService } from '@gtpl/shared-services/whatsapp-broad-cast';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { WhatsAppBroadCastRequest } from '@gtpl/shared-models/warehouse-management';


export interface WhatsAppBroadCastProps { isUpdate: boolean; }

export function WhatsAppBroadCast(props: WhatsAppBroadCastProps) {
    const Option = Select;
    const [form] = Form.useForm();
    const [SendToDrop, setSendToDrop] = useState<any>();
    const [msgTypeDrop, setMsgTypeDrop] = useState<any>();
    const [priorityDrop, setPriorityDrop] = useState<any>();
    const [vendorDrop, setVendorDrop] = useState<AllGrnReportDto[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [customersData, setcustomersData] = useState<CustomerDto[]>([]);
    const [deptData, setDeptData] = useState<any[]>([]);
    const [apiCall, setApiCall] = useState<any>()
    const customersService = new CustomersService();
    const [contactNum, setContactNum] = useState<any[]>([]);
    const employeeService = new HrmsEmpDetService();
    const service = new GrnService;
    const deptService = new DepartmentService;
    const whatsappservice = new FGStockService;
    const [loading, setLoading] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState('');
    const [fieldsData, setFieldsData] = useState<any>(undefined);
    const broadCatService = new WhatsappBroadCastService;

    const enumArry = Object.keys(WhatsAppMsgTypeEnum).reduce((arr, key) => {
        if (!arr.includes(key)) {
            arr.push(WhatsAppMsgTypeEnum[key]);
        }
        return arr;
    }, []);

    useEffect(() => {
        getVendorDropForGrnReport();
        getAllEmployees();
        getCustomersData();
        getAllActiveDepartment();
    }, [])

    const whatsappBroadCastApiCall = () => {
        const req = new WhatsAppBroadCastRequest();
        req.contacts = contactNum;
        req.priority = form.getFieldValue('priority');
        req.messageType = form.getFieldValue('msgType');
        req.companyId = 1;
        req.message = form.getFieldValue('msgBody');
        req.image = form.getFieldValue('file');
        broadCatService.CallBroadCastApi(req).then((res) => {
            if (res.status) {
                AlertMessages.getSuccessMessage('msg send sucessfully')
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }

    const getVendorDropForGrnReport = () => {
        service.getVendorDropForGrnReport({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
            if (res.status) {
                setVendorDrop(res.data);
            } else {
                if (res.intlCode) {
                    setVendorDrop([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setVendorDrop([]);
        })
    }
    const getCustomersData = () => {
        customersService.getAllActiveCustomers().then((res) => {
            if (res.status) {
                setcustomersData(res.data);
            } else {
                setcustomersData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setcustomersData([]);
        });
    }

    const getAllActiveDepartment = () => {
        deptService.getAllActiveDepartment().then(res => {
            if (res.data) {
                setDeptData(res.data)
            } else {
                setDeptData([])
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setcustomersData([]);
        });
    }

    const getAllEmployees = () => {
        employeeService.getAllActiveEmployees().then(res => {
            if (res.status) {
                setData(res.data);
            } else {
                if (res.intlCode) {
                    setData([]);
                    AlertMessages.getErrorMessage('No Employees Data Found');
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setData([]);
        });
    }



    const onReset = () => {
        form.resetFields();
    }
    const handleEmployeeName = (value) => {
        setData(value)
    }
    const handleSendToDrop = (value) => {
        setSendToDrop(value);
    }
    const handleMsgType = (value) => {
        setMsgTypeDrop(value);
    }
    const handlePriority = (value) => {
        setPriorityDrop(value);
    }
    const onFocus = () => {
        console.log('focus');
    }
    const onSearch = (val) => {
        console.log('search:', val);
    }
    const onBlur = () => {
        console.log('blur');
    }
    const appendInputData = (val, label) => {
        console.log(val);
        console.log(label);
        setFieldsData({ ...fieldsData, [label]: val });
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Image</div>
        </div>
    );

    const handleSelectAll = () => {
        const contacts = []
        form.getFieldValue('associates').forEach(element => {
            contacts.push(data.find(i => { return i.employeeId == element }).mobileNumber)
            setContactNum(contacts)
        });
        console.log(contacts)
        return contacts;
    }

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>WhatsApp Broad Cast</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#18ad16', border: 0 }} >
                <Form layout={"vertical"} autoComplete="off" form={form} >
                    <Row gutter={[24, 24]}>
                        <Col span={3}>
                            <Form.Item
                                name="sendTo"
                                label=" Send To"
                                rules={[
                                    {
                                        required: true, message: 'Select Field',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select "
                                    allowClear
                                    onChange={handleSendToDrop}>
                                    {Object.values(WhatsappSendToEnum).map(dropDownValue => {
                                        return <Option value={dropDownValue}>{dropDownValue}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} style={{ display: SendToDrop == WhatsappSendToEnum.Associates ? 'unset' : 'none' }}>
                            <Form.Item name="dept" label="Department"
                                rules={[
                                    {
                                        required: props.isUpdate && form.getFieldValue('category') == WhatsappSendToEnum.Associates ? true : false
                                    }
                                ]}>
                                <Select showSearch
                                    placeholder="Select department"
                                    optionFilterProp="children"
                                    mode='multiple'
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // onChange={customerOnchange}
                                    onSearch={onSearch}
                                    allowClear >
                                    <Option key="all" value="all">Select All departments</Option>
                                    {deptData.map(dropData => {
                                        return <Option value={dropData.deptId}>{dropData.deptName}</Option>
                                    })}
                                </Select>
                            </Form.Item >
                        </Col>
                        <Col span={5} style={{ display: SendToDrop == WhatsappSendToEnum.Associates ? 'unset' : 'none' }}>
                            <Form.Item name="associates" label="Associates"
                                rules={[
                                    {
                                        required: props.isUpdate && form.getFieldValue('category') == WhatsappSendToEnum.Associates ? true : false
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Select Employee Name"
                                    optionFilterProp="children"
                                    mode='multiple'
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    onChange={handleSelectAll}
                                >
                                    <Option key="all" value="all">Select All Employees</Option>
                                    {data.map((employe) => {
                                        return <Option key={employe.employeeId} value={employe.employeeId}>{employe.employeeName + ' - ' + employe.employeeCode}</Option>
                                    })}
                                </Select>
                            </Form.Item >
                        </Col>
                        <Col span={6} style={{ display: SendToDrop == WhatsappSendToEnum.Vendors ? 'unset' : 'none' }}>
                            <Form.Item name="vendors" label="Vendors"
                                rules={[
                                    {
                                        required: props.isUpdate && form.getFieldValue('category') == WhatsappSendToEnum.Vendors ? true : false
                                    }
                                ]}>
                                <Select showSearch
                                    placeholder="Select Vendor Name"
                                    optionFilterProp="children"
                                    mode='multiple'
                                    allowClear
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option key="all" value="all">Select All Vendors</Option>
                                    {vendorDrop.map((vendor) => {
                                        return <Option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</Option>
                                    })}
                                </Select>
                            </Form.Item >
                        </Col>
                        <Col span={5} style={{ display: SendToDrop == WhatsappSendToEnum.Customers ? 'unset' : 'none' }}>
                            <Form.Item name="customers" label="Customers"
                                rules={[
                                    {
                                        required: props.isUpdate && form.getFieldValue('category') == WhatsappSendToEnum.Customers ? true : false
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Select customer"
                                    mode='multiple'
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // onChange={customerOnchange}
                                    onSearch={onSearch}
                                    allowClear
                                >
                                    <Option key="all" value="all">Select All Customer</Option>
                                    {customersData.map(dropData => {
                                        return <Option value={dropData.custId}>{dropData.clientName}</Option>
                                    })}
                                </Select>
                            </Form.Item >
                        </Col>
                        <Col span={3} >
                            <Form.Item name="msgType" label="Message Type" >
                                <Select placeholder="Select "
                                    allowClear
                                    onChange={handleMsgType} >
                                    {enumArry.map(dropValue => {
                                        return <Option value={WhatsAppMsgTypeEnum[dropValue]}>{dropValue}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3} >
                            <Form.Item name="priority" label="Priority" >
                                <Select placeholder="Select Priority"
                                    allowClear
                                    onChange={handlePriority} >
                                    {Object.values(WhatsappPriorityEnum).map(dropValue => {
                                        return <Option value={dropValue}>{dropValue}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="msgSub" label="Message Subject">
                                <TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="msgBody" label="Message Body">
                                <TextArea />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ marginTop: 30 }} >
                            <Button icon={<WhatsAppOutlined />} type="primary" htmlType="submit" style={{ color: 'white', background: 'green', marginRight: 2, width: 100 }} onClick={() => whatsappBroadCastApiCall()} >
                                Send
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={onReset} style={{ color: 'black', background: 'white' }}> Reset </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}
export default WhatsAppBroadCast;

