import { JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class JobCompletedStatusForAssignmentDto{
    productionInventoryId:number;
    jobStatus?: JobCompletedEnum;
    assignFreezing?: JobCompletedEnum;


    constructor(productionInventoryId:number,jobStatus?:JobCompletedEnum, assignFreezing?: JobCompletedEnum){
        this.productionInventoryId = productionInventoryId;
        this.jobStatus = jobStatus;
        this.assignFreezing = assignFreezing;
    }
}