export class SoakingSampleTestDto{
    soakingSampleTestId: number;
    logId:number;
    jobNumber :string;
    batchNoScode: string;
    bsTemp: number;
    bsCount: number;
    bsWeight: number;
    asTemp: number;
    asCount: number;
    asWeight: number;
    chemicalConsumptiona: number;
    chemicalConsumptionb: number;
    timeIn: Date;
    timeOut: Date;
    pickupPercentage: number;
    remarks: string;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;

    constructor(
        soakingSampleTestId: number,
        logId:number,
    batchNoScode: string,
    jobNumber:string,
    bsTemp: number,
    bsCount: number,
    bsWeight: number,
    asTemp: number,
    asCount: number,
    asWeight: number,
    chemicalConsumptiona: number,
    chemicalConsumptionb: number,
    timeIn: Date,
    timeOut: Date,
    pickupPercentage: number,
    remarks: string,
    createdAt: Date,
    createdUser: string | null,
    updatedAt: Date,
    updatedUser: string | null,
    versionFlag: number,
    ){
        this.soakingSampleTestId= soakingSampleTestId;
        this.logId=logId;
   this. batchNoScode=batchNoScode;
   this.jobNumber=jobNumber;
    this.bsTemp= bsTemp;
    this.bsCount=bsCount;
    this.bsWeight= bsWeight;
    this.asTemp= asTemp;
    this.asCount= asCount;
    this.asWeight= asWeight;
    this.chemicalConsumptiona= chemicalConsumptiona;
    this.chemicalConsumptionb= chemicalConsumptionb;
    this.timeIn= timeIn;
    this.timeOut= timeOut;
    this.pickupPercentage= pickupPercentage;
    this.remarks= remarks;
    this.createdAt= createdAt;
    this.createdUser= createdUser;
    this.updatedAt= updatedAt;
    this.updatedUser= updatedUser;
    this.versionFlag= versionFlag;
    }
}