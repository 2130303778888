import axios from 'axios';
import {SoakingDto, SoakingResponseModel, AllSoakingResponseModel, JobCompletedStatusDto, assignSoakingForFreezingResponse, AllFreezingResponseModel, FreezingDto} from '@gtpl/shared-models/production-management';
import connection from './connection';
import { UnitRequest } from '@gtpl/shared-models/common-models';

export class FreezingInventoryService {
    URL = connection.DEPLOY_URL + '/freezing';
  
   
    async  assignSoakingForFreezing(soakingInfo: FreezingDto): Promise<assignSoakingForFreezingResponse> {
      console.log(FreezingDto);
      return await axios.post(this.URL + '/assignSoakingForFreezing', soakingInfo)
              .then(res => {
                  return res.data
              })
  }
  async  jobCompletedStatus(soakingInfo: SoakingDto): Promise<assignSoakingForFreezingResponse> {
      console.log(SoakingDto);
      return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
              .then(res => {
                  return res.data
              })
  }
  
  async  getAssignForFreezingData(plantReq: UnitRequest): Promise<AllSoakingResponseModel> {
    console.log(plantReq);
    return await axios.post(this.URL + '/getAssignForFreezingData', plantReq)
            .then(res => {
                return res.data
            })
}

async  getAssignForFreezingInvData(plantReq: UnitRequest): Promise<AllFreezingResponseModel> {
    console.log(plantReq);
    return await axios.post(this.URL + '/getAssignForFreezingInvData', plantReq)
            .then(res => {
                return res.data
            })
}
  
    }
  
  