import { HarvestTypes, PlantsEnum, SupplierTypeEnum } from "@gtpl/shared-models/common-models";
import { CommissionType, HarvestingTime } from "../enums";

export class IndentModel {
    plantId: number;
    indentDate: any;
    supplierType: SupplierTypeEnum;
    farmer: number;
    farmerCode: number;
    product: number;
    expectedCount: number;
    expectedQty: number;
    expectedPrice: number;
    commissionType: CommissionType;
    commission: number;
    harvestingDate: any;
    harvestingTime: HarvestingTime;
    assignLead1: number;
    assignLead2: number;
    indentBy: number;
    expectedCount2?: number;
    expectedPrice2?: number;
    expectedCount3?: number;
    expectedPrice3?: number;
    indentId?: number;
    indentCode?: string;
    vehicleAssigned?: boolean;
    grnCompleted?: boolean;
    createdUser?: string;
    createdAt?: Date;
    updatedUser?: string;
    updatedAt?: Date;
    supplierCode?: string;
    supplierName?: string;
    farmName?: string;
    farmCode?: string;
    productCode?: string;
    lead1Name?: string;
    lead2Name?: string;
    isActive?: boolean;
    versionFlag?: number;
    address?: string;
    mobileNumber?: string;
    brokerId?: number;
    brokerName?: string;
    harvestReportId?: number;
    isVehicleRequired?: boolean;
    harvestType?: HarvestTypes;
    indentByName?: string;
    supplier?: string

    /**
     * 
     * @param plantId 
     * @param indentDate 
     * @param supplierType 
     * @param farmer 
     * @param farmerCode 
     * @param product 
     * @param expectedCount 
     * @param expectedQty 
     * @param expectedPrice 
     * @param commissionType 
     * @param commission 
     * @param harvestingDate 
     * @param harvestingTime 
     * @param assignLead1 
     * @param assignLead2 
     * @param indentBy 
     * @param indentId 
     * @param indentCode 
     * @param vehicleAssigned 
     * @param grnCompleted 
     * @param createdUser 
     * @param createdAt 
     * @param updatedUser 
     * @param updatedAt 
     * @param supplierCode 
     * @param supplierName 
     * @param farmName 
     * @param farmCode 
     * @param productCode 
     * @param lead1Name 
     * @param lead2Name 
     * @param expectedCount2 
     * @param expectedPrice2 
     * @param expectedCount3 
     * @param expectedPrice3 
     * @param isActive 
     * @param versionFlag 
     * @param address 
     * @param mobileNumber 
     * @param brokerId 
     * @param brokerName 
     * @param harvestReportId 
     * @param isVehicleRequired 
     * @param harvestType 
     * @param indentByName 
     * @param supplier 
     */
    constructor(plantId: number, indentDate: Date, supplierType: SupplierTypeEnum, farmer: number, farmerCode: number,
        product: number, expectedCount: number, expectedQty: number, expectedPrice: number, commissionType: CommissionType,
        commission: number, harvestingDate: Date, harvestingTime: HarvestingTime, assignLead1: number, assignLead2: number, indentBy: number,
        indentId: number, indentCode: string, vehicleAssigned: boolean, grnCompleted: boolean, createdUser: string, createdAt: Date, updatedUser: string, updatedAt: Date, supplierCode: string, supplierName: string, farmName: string, farmCode: string, productCode: string, lead1Name: string, lead2Name: string, expectedCount2?: number, expectedPrice2?: number, expectedCount3?: number, expectedPrice3?: number, isActive?: boolean, versionFlag?: number, address?: string, mobileNumber?: string, brokerId?: number, brokerName?: string, harvestReportId?: number, isVehicleRequired?: boolean, harvestType?: HarvestTypes, indentByName?: string, supplier?: string) {
        this.plantId = plantId;
        this.indentDate = indentDate;
        this.supplierType = supplierType;
        this.farmer = farmer;
        this.farmerCode = farmerCode;
        this.product = product;
        this.expectedCount = expectedCount;
        this.expectedQty = expectedQty;
        this.expectedPrice = expectedPrice;
        this.expectedCount2 = expectedCount2;
        this.expectedPrice2 = expectedPrice2;
        this.expectedCount3 = expectedCount3;
        this.expectedPrice3 = expectedPrice3;
        this.commissionType = commissionType;
        this.commission = commission;
        this.harvestingDate = harvestingDate;
        this.harvestingTime = harvestingTime;
        this.indentBy = indentBy;
        this.assignLead1 = assignLead1;
        this.assignLead2 = assignLead2;
        this.indentId = indentId;
        this.indentCode = indentCode;
        this.vehicleAssigned = vehicleAssigned;
        this.grnCompleted = grnCompleted;
        this.createdUser = createdUser;
        this.createdAt = createdAt;
        this.updatedUser = updatedUser;
        this.updatedAt = updatedAt;
        this.supplierCode = supplierCode;
        this.supplierName = supplierName;
        this.farmName = farmName;
        this.farmCode = farmCode;
        this.productCode = productCode;
        this.lead1Name = lead1Name;
        this.lead2Name = lead2Name;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.address = address;
        this.mobileNumber = mobileNumber;
        this.brokerId = brokerId;
        this.brokerName = brokerName;
        this.harvestReportId = harvestReportId;
        this.isVehicleRequired = isVehicleRequired;
        this.harvestType = harvestType;
        this.indentByName = indentByName;
        this.supplier = supplier;
    }
}
