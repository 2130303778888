import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ItemsDto } from "./items.dto";

export class ItemResponse extends GlobalResponseObject{

      data?: ItemsDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: ItemsDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }

}