export class SaleOrderTypeDto{
    saleorderTypeId:number;
    saleOrderTypeName:string;
    createdUser:string;
    isActive:boolean;
    
    /**
     * SaleOrderType
     * @param saleorderTypeId is a number
     * @param saleOrderTypeName is a string 
     * @param createdUser is a string
     * @param isActive is a boolean
     */
    constructor(saleorderTypeId:number,saleOrderTypeName:string,createdUser:string,isActive:boolean){
    this.saleorderTypeId = saleorderTypeId;
    this.saleOrderTypeName = saleOrderTypeName;
    this.createdUser = createdUser;
    this.isActive = isActive
    }
}


export const SaleOrderTypeDtoDefault : SaleOrderTypeDto= {
    saleorderTypeId:0,
    saleOrderTypeName:'',
    isActive: true,
    createdUser : '0',
    
};
