export class GrnNumberDto {
    grnId: number;
    grnNumber: string;

    /**
     * @param grnId: number
     * @param grnNumber: string;
     */
    constructor(grnId: number,grnNumber: string) {
        this.grnId = grnId;
        this.grnNumber = grnNumber;
    }
}