import axios from 'axios';
import connection from './connection';
import {
  LocationsDto,
  LocationsResponseModel,
  AllLocationsResponseModel,
  LocationRequest,
  RackZoneLocationRequest,
  
  // RackDropDownResponseModel,
  // RacksForZoneId
} from '@gtpl/shared-models/masters';

export class LocationsService {
  URL = connection.DEPLOY_URL + '/locations';

  async createLocations(
    locationsdto: LocationsDto
  ): Promise<LocationsResponseModel> {
    console.log(locationsdto);

    return await axios
      .post(this.URL + '/createLocations', locationsdto)
      .then((res) => {
        return res.data;
      });
  }

  async updateLocations(
    locationsdto: LocationsDto
  ): Promise<LocationsResponseModel> {
    return await axios
      .post(this.URL + '/updateLocations', locationsdto)
      .then((res) => {
        return res.data;
      });
  }

  async activatedeActivateLocations(
    locationsDto: LocationsDto
  ): Promise<LocationsResponseModel> {
    return await axios
      .post(this.URL + '/activateorDeactivateLocation', locationsDto)
      .then((res) => {
        return res.data;
      });
  }
  async getLocationById(
    locationRequest: LocationRequest
  ): Promise<LocationsResponseModel> {
    return await axios
      .post(this.URL + '/getRecordByLocationsId', locationRequest)
      .then((res) => {
        return res.data;
      });
  }
  async getAllLocations(): Promise<AllLocationsResponseModel> {
    return await axios.post(this.URL + '/getAllLocations').then((res) => {
      return res.data;
    });
  }
  async getAllLocationsByZoneAndRack(rackZoneLocationRequest: RackZoneLocationRequest): Promise<AllLocationsResponseModel> {
    return await axios.post(this.URL + '/getAllLocationsByZoneAndRack',rackZoneLocationRequest).then((res) => {
      return res.data;
    });
  }
  
}
