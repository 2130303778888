export class QcdefectReportRequest{
    lotNumber?:string;
    workstationId?:number
    operationId?:number
    testResultId?:number;

}
export class QcDefectItemDetailsReq{
    qcId:number
    constructor(qcId:number){
        this.qcId=qcId;
    }
}