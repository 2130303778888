import { ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto, TaxDropDownDto } from "@gtpl/shared-models/masters";

export class grnItemsDataModel{
    grnItemId?: number;

    grnId?: number;

    itemCategory: ItemCategoryDropDownDto;

    itemName: ItemsDropDownDto;

    size: SizesDropDownDto;

    itemQuantity: number;

    acceptedQuantity: number;

    rejectedQuantity: number;
    receivedQuantity: number;

    itemCost: number;

    discountPer: number;

    discount: number;

    taxPer: TaxDropDownDto;

    tax: number;

    subAmount: number;


    createdUser: string;


    updatedUser: string;

    createdAt?: Date;

    updatedAt?: Date;
    status: boolean;
    soNumber?: string;
    poNumber?: string;



    /**
    * @param grnId
    * @param itemCategoryId
    * @param itemId
    * @param sizeId
    * @param poQuantity
    * @param acceptedQuantity
    * @param rejectedQuantity
    * @param unitPrice
    * @param discountPercentage
    * @param discountAmount
    * @param taxPercentage
    * @param taxAmount
    * @param totalAmount
    * @param createdUser
    * @param updatedUser
    * @param status
   
     */
    constructor(grnId: number,itemCategory: ItemCategoryDropDownDto,itemName: ItemsDropDownDto,size: SizesDropDownDto,itemQuantity: number,acceptedQuantity: number,rejectedQuantity: number,receivedQuantity:number,itemCost: number,discountPer: number,discount: number,taxPer: TaxDropDownDto,tax: number,subAmount: number,createdUser: string,updatedUser: string,status:boolean,soNumber?: string,poNumber?: string) {
        this.grnId = grnId;
    this.itemCategory = itemCategory;
    this.itemName = itemName;
    this.size = size;
    this.itemQuantity = itemQuantity;
    this.acceptedQuantity = acceptedQuantity;
    this.rejectedQuantity = rejectedQuantity;
    this.receivedQuantity = receivedQuantity;
    this.itemCost = itemCost;
    this.discountPer = discountPer;
    this.discount = discount;
    this.taxPer = taxPer;
    this.tax = tax;
    this.subAmount = subAmount;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;
    this.status = status;
    this.soNumber = soNumber;
    this.poNumber = poNumber;
    }
}