import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Select, Row, Col, DatePicker, FormInstance } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './indent-from-for-prepared-material.css';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { indentformDto, IndentFormItemsDto } from '@gtpl/shared-models/procurement-management';
import { EmployeeDto, EmployeeUnitRequest } from '@gtpl/shared-models/masters';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { IndentformService } from '@gtpl/shared-services/procurement';
import { EmployeeService } from '@gtpl/shared-services/masters';
import { CategoryEnum, IndentAgainistEnum, IndentAgainistEnumdropdown, indentstatusenum, UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
const { Option } = Select;
export interface Indentdetailformprops {
  form: FormInstance<any>,
  addindentForm: FormInstance<any>,
  isUpdate?: boolean
  initialValues: any
  setSaleOrderId: (value) => void
}
export function Indentdetailform(props: Indentdetailformprops) {
  const { form } = props
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [itemDetailsGridData, setItemDetailsGridData] = useState<IndentFormItemsDto[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<any>([]);
  const [saleorderIndex, setSaleorderIndex] = useState<any>()
  const { isUpdate, initialValues } = props
  const [indentAgainst,setIndentAgainst]=useState(' ');
  const SoService = new SaleOrderService();
  const empservice = new EmployeeService;
  const indentservice = new IndentformService();

  let createdUser = "";

  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }
  useEffect(() => {
    getEmpByUnit();
    getSaleOrderData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }, [])

  // useEffect(()=>{
  //  if( form.getFieldValue('indentAgainst') == IndentAgainistEnum.DUMMY){
  //   form.setFieldsValue({saleOrderId:0})
  //  }
  // })

  const getEmpByUnit = () => {
    const req = new EmployeeUnitRequest(Number(localStorage.getItem('unit_id')))
    empservice.getEmployeesByUnitId(req).then((res) => {
      if (res.status) {
        setEmployeeData(res.data)
      } else {
        setEmployeeData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getSaleOrderData = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    SoService.getsoitemforIndent(req).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
      form.resetFields(['saleOrderId'])
    });
  }
  const onFocus = () => {
    //console.log('focus');
  }
  const onSearch = (val) => {
    //console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }

  const handleitem = (e) => {
    console.log(e)
    const soId = saleOrderData.filter((el) => { return el.saleOrderItemId == e })
    console.log(saleOrderData)
    props.setSaleOrderId(soId[0].saleOrderId)
    form.setFieldsValue({ saleOrderId: soId[0].saleOrderId })
  }
const handleCategory=(value)=>{
  setIndentAgainst(value)
  console.log(value)
  if(value == 'DUMMY'){
    props.setSaleOrderId(0)
    form.setFieldsValue({ saleOrderId:0 })
  }

}
  return (
    <Card title={false}>
      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="indentMaterialPreparationId"  >
            <Input hidden />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ display: props.isUpdate ? 'unset' : 'none' }}>
            <FormItem name='indentCode' label='Indent Code'>
              <Input disabled={props.isUpdate} />
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <FormItem name='indentDate' label='Indent Time' initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: 'indent time is required'
                }
              ]}
            >
              <DatePicker showTime disabled />

            </FormItem>
          </Col>
         
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="employeeId"
              label="Raised By"
              rules={[
                {
                  required: true,
                  message: 'Employee Name is Required'
                },

              ]}
            >
              <Select
                showSearch
                placeholder="Select Employee"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Employee</Option>
                {employeeData?.map((employeeName) => {
                  return <Option key={employeeName.employeeId} value={employeeName.employeeId}>{employeeName.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
          <Form.Item
              name="indentAgainst"
              label="Indent Against"
              initialValue={IndentAgainistEnum.SALE_ORDER}
              rules={[
                {
                  required:  true,
                  message: 'indentAgainst is required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Select the category`
                }
              ]}
            >
              <Select
                showSearch
                placeholder="Select category"
                optionFilterProp="children"
                onSelect={(value) => { handleCategory(value) }}
                // onChange={(value) => handleCategory(value)}
                onSearch={onSearch}
                defaultValue={IndentAgainistEnum.SALE_ORDER}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={props.isUpdate}
              >
                {IndentAgainistEnumdropdown.map(item => {
                    return <Option value={item.value}>{item.name}</Option>
                  })}
              </Select>
            </Form.Item>
            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 8 }} 
          // style={{display: indentAgainst == IndentAgainistEnum.DUMMY ?'none':'unset'}}
          hidden={form.getFieldValue('indentAgainst') == IndentAgainistEnum.DUMMY ?true:false}>
            <Form.Item
              name="saleOrderItemId"
              label="Product(So & So Item)"
              rules={[
                {
                  required:indentAgainst == IndentAgainistEnum.DUMMY || props.isUpdate ?false:true,
                  message: 'saleOrderItem  is Required'
                },

              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select soitem"
                optionFilterProp="children"
                onChange={e => handleitem(e)}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
            
                // onSelect={handlesaleorderId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Product</Option>
                {saleOrderData?.map((dropData) => {
                  return <Option key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}>{dropData.soItem}</Option>

                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}
           style={{ display: 'none' }}
            >
            <FormItem name='saleOrderId' label='Sale Order id'>
              <Input />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}


