

export class InterviewReportDto {
   jobDescription:string;
   totalProfileAssigned: number;
   Rejected: number;
   toBeInterviewed: number;
   Selected: number;

   /**
    * 
    * @param totalProfileAssigned 
    * @param toBeInterviewed 
    * @param Rejected 
    * @param Selected 
    */
   constructor(jobDescription:string,totalProfileAssigned: number, Rejected: number,toBeInterviewed: number,  Selected: number) {
      this.jobDescription=jobDescription;
      this.totalProfileAssigned = totalProfileAssigned;
      this.Rejected = Rejected;
      this.toBeInterviewed = toBeInterviewed;
      this.Selected = Selected;
   }


}