import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ProductInspectionDto } from ".";

export class AllProductInspectionsResponse extends GlobalResponseObject{
    data?: ProductInspectionDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: ProductInspectionDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}