import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { HarvestModel } from "./harvest-report.model";

export class HarvestReportResponse extends GlobalResponseObject{
    data: HarvestModel;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data: HarvestModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}