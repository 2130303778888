import { Form, Input, Button, Space, Card, Select, Col, Row, Table, Empty, InputNumber, Tooltip, Divider, Modal, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { FoodTypesDropDownData, FoodTypesDto, GetValueAdditionDataBySkuCodesRequest, GradesDropDownData, ItemsDto, PackingMethodDto, ProductDto, ProductsDropDownData, SizesDropDownDto, SizesDto, SkuDropDownData, SkuDto, TypeOfValueAddition4Dto, UomsDropDownDto, UomsDto, ValAddFourDropDownData, ValAddOneDropDownData, ValAddThreeDropDownData, ValAddTwoDropDownData } from '@gtpl/shared-models/masters';
import { GradeServices, ProductService, FoodTypeService, SkuService, PackingMethodService, UomsService, TypeofValueAddition4Service, SizesService, ItemsService } from '@gtpl/shared-services/masters';
import { SizesForm } from '@gtpl/pages/master/master-components/sizes-form';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";


import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CategoriesInput, CrrencySymbols, ItemsDetails, PackTypeCategory, SaleOrderItemsInfo, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { Route } from 'react-router-dom';
import { JobTypesEnum, PackingCategoryEnum, SoStatusEnum, UomEnum } from '@gtpl/shared-models/common-models';
import { BrandsInfo } from 'libs/shared-models/masters/src/lib/endCuctomer/brands-model';
import { SaleOrderItemsDTO } from '@gtpl/shared-models/sale-management';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';

const { Option } = Select;

export interface ItemDetailsFormProps {
  key:number
  mastesrBrandData: (masterBrand: number) => void;
  // getVarientId: (varientId: number) => void;
  brandsData: BrandsInfo[]
  category: string
  // initialData: SaleOrderItemsDTO[]
  soStatus: string
  packCategory: string
  currency: number
  updateCurrency: number
  stockStatus:string
  saleOrderId:number
  closeModal: () => void
  varientsData:ItemsDetails[];

}


const ItemDetailsForm = (props: ItemDetailsFormProps) => {
  let unitOfWeights = UnitsOfWeightInput;
  let categories = CategoriesInput;

  const [form] = Form.useForm();
  const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [productsdata, setPruseEffectoductdata] = useState<ProductsDropDownData[]>([]);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [addBtnChange, setAddBtnChange] = useState<boolean>(false);
  const [priceLabel, setPriceLabel] = useState<string>('');
  const [fieldUpdateKey, setFieldUpdateKey] = useState<number>(1);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [packStyle, setPackStyle] = useState<number>(0);
  const [productsBtnDisable, setProductsBtnDisable] = useState<boolean>(false);
  const [subPoDisable, setsubPoDisable] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [foodTypeData, setFoodType] = useState<FoodTypesDto[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [updateTableData, setUpdateTableData] = useState<any>(undefined);
  const [fieldsData, setFieldsData] = useState<any>({
    product: '',
    foodType: '',
    soakingTime: 120,
    innerBags: 0,
    innerBagSizeId: undefined,
    shrimpWeight: 0,
    lbnetWeight: 0,
    kgnetWeight: 0
  });

  
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [updatedefaultFormData, setUpdateDefaultFormData] = useState(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [callItemState, setCallItemState] = useState<boolean>(false);
  const [masterBrandId, setMasterBrandId] = useState<number>(0);
  const [category, setCategory] = useState<string>('');

  const [isInnerBagsHidden, setIsInnerBagsHidden] = useState<boolean>(true);
  const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [isInnerBagsizeHidden, setIsInnerBagsizeHidden] = useState<boolean>(true);
  const [isShrimpWeightHidden, setIsShrimpWeightHidden] = useState<boolean>(true);
  const [packingStyles, setPackingStyke] = useState<ProductsDropDownData[]>([]);
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [packingMethods, setPackingMethods] = useState<PackingMethodDto[]>([]);

  const [weighingStyle, setWeighingStyle] = useState<UomsDto[]>([]);
  const [freezeStyles, setFreezeStyles] = useState<TypeOfValueAddition4Dto[]>([]);
  const [updateDisableField, setUpdateDisableField] = useState<boolean>(false);
  const productService = new ProductService();
  const foodTypeService = new FoodTypeService();
  const skuService = new SkuService();
  const pmService = new PackingMethodService();
  const uomsService = new UomsService();
  const freezeStleService = new TypeofValueAddition4Service();
  const sizeService = new SizesService();
  const itemService = new ItemsService();
  const saleOrderService = new SaleOrderService();

  let history = useHistory();
  




  useEffect(() => {
    form.setFieldsValue({saleOrderId:props.saleOrderId});
    console.log(props);
    if (defaultFormData) {
      const selectedUom = unitOfWeights.find(item => item.value == defaultFormData.uomId);
      if (!isUpdate && defaultFormData.category.children == PackTypeCategory.SKEWERS) {
        setIsInnerBagsHidden(false)
        setIsInnerBagsizeHidden(false)
      }else if((!isUpdate && defaultFormData.category.children == PackTypeCategory.RING) || (!isUpdate && defaultFormData.category.children == PackTypeCategory.PLATTER)){
        setIsInnerBagsHidden(true)
        setIsInnerBagsizeHidden(false)
      }else{
        setIsInnerBagsHidden(true)
        setIsInnerBagsizeHidden(true)
      }
      form.setFieldsValue({
        category: !isUpdate ? defaultFormData.category.children : defaultFormData.category,
        Brand: !isUpdate ? defaultFormData.brandId.children : defaultFormData.brandId,
        packStyleId: !isUpdate ? defaultFormData.uomId.packMethodName : defaultFormData.packStyleId,
        // Grade: defaultFormData.Grade,
        // maxGrade: defaultFormData.maxGrade,
        itemId: !isUpdate ? defaultFormData.itemId : defaultFormData.itemId,
        // foodTypeId: defaultFormData.foodTypeId.children,
        // freezeStyle: defaultFormData.freezeStyle.children,
        varientId: !isUpdate ? defaultFormData.varientId.children : defaultFormData.varientId,
        minGlaze: defaultFormData.minGlaze, MaxGlaze: !isUpdate ? defaultFormData.MaxGlaze : defaultFormData.MaxGlaze ,
        minGrade: defaultFormData.minGrade, maxGrade: defaultFormData.maxGrade,
        pouches: defaultFormData.pouches, pouchWeight: defaultFormData.pouchWeight ,'uomId': defaultFormData.uomId,
        WeighingType: !isUpdate ? defaultFormData.uomId.children : selectedUom.name,
        styleWeighment: !isUpdate ? defaultFormData.styleWeighment.children : defaultFormData.styleWeighment,
        innerBags: defaultFormData.innerBags,
        shrimpWeight: defaultFormData.shrimpWeight,
        soakingStyle: !isUpdate ? defaultFormData.soakingStyle : defaultFormData.soakingStyle,
        soakingTime: defaultFormData.soakingTime,
        unitPrice: defaultFormData.unitPrice,
        cases: defaultFormData.cases,
        jobCases: defaultFormData.cases,
        caseWeight: !isUpdate ? defaultFormData.caseWeight : defaultFormData.caseWeight,
        netWeight: defaultFormData.netWeight,
        netAmount: defaultFormData.netAmount,
        innerBagSizeId: !isUpdate ? defaultFormData.innerBagSizeId?.children : defaultFormData.innerBagSizeId

      });
      setFieldsData(defaultFormData);
      // const test=form.getFieldsValue();
      // form.setFieldsValue({...test, glazzing['minGlaze']: defaultFormData.minGlaze,
      // glazzing['maxGlaze']: defaultFormData.maxGlaze})
    }
    getItemsData();
    getFoodTypesData();
    getSkuCodes();
    getPackingMethods();
    getUomsdata();
    getFreezeStyles();
    getSizes();

  }, [defaultFormData]);

  const showModal = () => {
    setIsModalVisible(true);
    // getSizes();

  };

  const handleOk = () => {
    setIsModalVisible(false);
    getSizes();

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getSizes();

  };


  const getSizes = () => {
    sizeService.getAllSizesDropDown().then((res) => {

      if (res.status) {
        console.log(res.data);
        setSizes(res.data);
      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }


  const getUomsdata = () => {
    uomsService.getAllUoms().then((res) => {
      if (res.status) {
        setWeighingStyle(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setWeighingStyle([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWeighingStyle([]);
    });
  }

  const getFreezeStyles = () => {
    freezeStleService.getAllActiveTypeOfValueAddition4().then((res) => {
      if (res.status) {
        setFreezeStyles(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setFreezeStyles([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFreezeStyles([]);
    });
  }


  const getItemsData = () => {
    productService.getAllActiveProducts().then((res) => {
      if (res.status) {
        setItemsdata(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setItemsdata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsdata([]);
    });
  }

  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkucode(res.data);
      }
      else {
        setSkucode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });
  }

  const getPackingMethods = () => {
    pmService.getAllPackingMethod().then((res) => {
      if (res.status) {
        setPackingMethods(res.data);
      }
      else {
        setPackingMethods([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setPackingMethods([]);
      });
  }

  const getFoodTypesData = () => {
    foodTypeService.getAllActiveFoodTypes().then((res) => {
      if (res.status) {
        setFoodType(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setFoodType([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFoodType([]);
    });
  }

  const onReset = () => {
    setBtnChange(false);
    form.resetFields();
    setDefaultFormData(undefined)
    form.setFieldsValue({saleOrderId:props.saleOrderId});

  };

  
  const calculatWeightPrice = () => {
    console.log("Calculations........")
    let selectedCategory
    if (isUpdate) {
      selectedCategory = fieldsData.category
    } else {
      // console.log(fieldsData)
      if (fieldsData.category) {
        selectedCategory = fieldsData.category.value
      }
      // }else{
      //   AlertMessages.getErrorMessage('please select Category')
      // }
    }
    if (selectedCategory) {
      // console.log('selcat')
      // console.log(selectedCategory)

      const unitprice = fieldsData.unitPrice
      const cases = fieldsData.cases;
      const jobCases = fieldsData.cases;
      const uom = fieldsData.uomId
      const shrimpWeight = fieldsData.shrimpWeight
      const pouches = fieldsData.pouches
      let pouchWeight;
      pouchWeight = fieldsData.pouchWeight
      let selUom

      if (selectedCategory == PackTypeCategory.POUCH || selectedCategory == PackTypeCategory.SKEWERS) {
        if (uom) {
          if (isUpdate) {
            selUom = uom
          } else {
            selUom = uom.value
          }
          if (selUom == 1 || selUom == 4) {
            setPriceLabel('Per KG')
          }
          if (selUom == 2 || selUom == 3) {
            setPriceLabel('Per LB')
          }
        }
      } else if (selectedCategory == PackTypeCategory.RING || selectedCategory == PackTypeCategory.PLATTER) {
        if (uom) {
          setPriceLabel('Per Unit')
        }
      }
      if (cases && unitprice >= 0 && pouches && pouchWeight && uom && shrimpWeight) {
        console.log("unitprice >0")

        let selUomId
        if (isUpdate) {
          selUomId = fieldsData.uomId
        } else {
          selUomId = fieldsData.uomId.value
        }
        if (selUomId == 3) {
          pouchWeight = (fieldsData.pouchWeight) * 0.0625
        }
        if (selUomId == 4) {
          pouchWeight = (fieldsData.pouchWeight) * 0.001
        }

        const caseWeight = pouches * pouchWeight;
        const netWeight = caseWeight * cases;
        let netAmount
        if (selectedCategory == PackTypeCategory.POUCH || selectedCategory == PackTypeCategory.SKEWERS) {
          netAmount = netWeight * unitprice;
        } else if (selectedCategory == PackTypeCategory.RING || selectedCategory == PackTypeCategory.PLATTER) {
          netAmount = pouches * cases * unitprice;
        }
        //netweight conversion for total
        let lbnetWeight;
        let kgnetWeight;

        if (selUomId == 1 || selUomId == 4) {
          lbnetWeight = (netWeight) * 2.20462
        } else {
          lbnetWeight = netWeight;
        }

        if (selUomId == 2 || selUomId == 3) {
          kgnetWeight = (netWeight) * 0.453592
        } else {
          kgnetWeight = netWeight;
        }

        form.setFieldsValue({ caseWeight: String(caseWeight.toFixed(3)), netWeight: String(netWeight.toFixed(3)), netAmount: String(netAmount.toFixed(2)), lbnetWeight: String(lbnetWeight) });
        setFieldsData({ ...fieldsData, caseWeight: caseWeight, netWeight: netWeight, lbnetWeight: lbnetWeight, kgnetWeight: kgnetWeight, netAmount: netAmount })
        // setFieldsData({ ...finalData, [label]: val });

      } else {
        console.log("unitprice 0")
        form.setFieldsValue({ caseWeight: String(0), netWeight: String(0), netAmount: String(0), lbnetWeight: String(0) });
        // let finalData = { ...fieldsData, caseWeight: 0, netWeight: 0, netAmount: 0  }
        setFieldsData({ ...fieldsData, caseWeight: 0, netWeight: 0, netAmount: 0, lbnetWeight: 0 })
        // setFieldsData({ ...finalData, [label]: val });
      }

    }
  }

  const appendData = (val, rowObj) => {
    console.log(isUpdate);
    console.log(rowObj);
    console.log(val);
    setProductsBtnDisable(false)
    setFieldsData({ ...fieldsData, saleOrderId: props.saleOrderId})

    // console.log(rowObj);
    let row = rowObj
    if (isUpdate) {
      row = rowObj.value
    }

    // console.log(rowObj);
    let { label } = rowObj;
    if (label == 'brandId') {
      console.log(val);
      console.log(rowObj);
      setFieldsData({ ...fieldsData, masterBrandId: rowObj?.masterBrandId})
      form.setFieldsValue({masterBrandId:rowObj?.masterBrandId});
      // setMasterBrandId(rowObj.masterBrandId);
      // props.mastesrBrandData(rowObj.masterBrandId)
    }
    if (label == 'styleWeighment') {
      console.log(label)
      console.log(val)
      setFieldsData({ ...fieldsData, [label]: val })
      form.setFieldsValue({styleWeighment:val});
    }
    if (isUpdate) {
      label = rowObj.label;
    }

    if (label == 'varientId' || label == 'uomId') {
      console.log(val);
      console.log(packStyle);
      console.log(form.getFieldValue("varientId"));
      console.log(form.getFieldValue("packStyleId"));
      if(props.varientsData.find((rec) => rec.variantId === form.getFieldValue("varientId") && rec.packStyleId === form.getFieldValue("packStyleId")) != undefined)
      {
        AlertMessages.getErrorMessage("Product already exist.");
        onReset();
      }
      else{
        console.log("varient change")
        let object: any = ''
        setFieldsData({ ...fieldsData, [label]: row.value })
        skuService.getValueAdditionDataBySkuCode(new GetValueAdditionDataBySkuCodesRequest(form.getFieldValue("varientId"))).then((res) => {
          if (res.status) {
            console.log(res.data);
            const valuAdditions = res.data;
            // console.log(res.data)
            form.setFieldsValue({ unitPrice:valuAdditions.unitPrice,requiredCount:valuAdditions.requiredCount,foodTypeId:valuAdditions.foodTypeId,itemId: valuAdditions.productId, soakingStyle: valuAdditions.soakingStyle, soakingTime: valuAdditions.soakingTime, minGlaze: valuAdditions.minglaze, MaxGlaze: valuAdditions.maxglaze , minGrade: Number(valuAdditions.minGrade), maxGrade: Number(valuAdditions.maxGrade),valueAdditionOneId: valuAdditions.valueAdditionOneId,
            valueAdditionTwoId: valuAdditions.valueAdditionTwoId,
            valueAdditionThreeId: valuAdditions.valueAdditionThreeId,
            valueAdditionFourId: valuAdditions.valueAdditionFourId,
            valueAdditionFiveId: valuAdditions.valueAdditionFiveId })
            object = {
              ...fieldsData, [label]: row.value, valueAdditionOneId: valuAdditions.valueAdditionOneId,
              valueAdditionTwoId: valuAdditions.valueAdditionTwoId,
              valueAdditionThreeId: valuAdditions.valueAdditionThreeId,
              valueAdditionFourId: valuAdditions.valueAdditionFourId,
              valueAdditionFiveId: valuAdditions.valueAdditionFiveId,
              lossOrGainPercentage: valuAdditions.lossOrGainPercentage,
              requiredCount: valuAdditions.requiredCount,
              foodTypeId: valuAdditions.foodTypeId,
              itemId: valuAdditions.productId,
              soakingStyle: valuAdditions.soakingStyle,
              soakingTime: valuAdditions.soakingTime,
              // grade: { 'minGrade': valuAdditions.minGrade, 'maxGrade': valuAdditions.maxGrade },
              minGrade: valuAdditions.minGrade,
              maxGrade: valuAdditions.maxGrade,
              minGlaze: valuAdditions.minglaze,
              MaxGlaze: valuAdditions.maxglaze
            }
            setFieldsData({ ...object })
            // props.getVarientId(val);
            // console.log(val)
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
      }
    } 
    else {
      // console.log(fieldsData)
      if (label == 'category') {
        form.setFieldsValue({'categoryId' : CategoriesInput.find((res) => res.value=val).id})
        console.log(form.getFieldValue('pouchWeight'));
        setFieldsData({ ...fieldsData, [label]: row });
        // alert();
        // console.log(val);
        props.category = val;
        setCategory(val)
        const category = props.category
        let packstyleValues
        let pouchWeightValue
        if (form.getFieldValue('pouchWeight')) {
          packstyleValues = form.getFieldValue('pouchWeight');
          pouchWeightValue = packstyleValues
        }
        if (val == PackTypeCategory.SKEWERS) {
          // console.log('yesssssss')
          setIsInnerBagsHidden(false)
          setIsInnerBagsizeHidden(false)
          form.setFieldsValue({ shrimpWeight: pouchWeightValue===undefined?0:pouchWeightValue });
          const object = {
            ...fieldsData, [label]: row.value, shrimpWeight: pouchWeightValue===undefined?0:pouchWeightValue
          }
          setFieldsData({ ...object });
        }
        else if (val == PackTypeCategory.RING) {
          console.log('ring')
          setIsInnerBagsHidden(true)
          setIsInnerBagsizeHidden(false)
          form.setFieldsValue({ innerBags: 0 })
          form.setFieldsValue({ innerBagSizeId: null })
          form.setFieldsValue({ shrimpWeight: 0 });
          const object = {
            ...fieldsData, [label]: row.value, shrimpWeight: 0, innerBags: 0, innerBagSizeId: undefined
          }
          setFieldsData({ ...object });
        }
        else {
          console.log('pouch,platter')
          setIsInnerBagsHidden(true)
          if(val == PackTypeCategory.PLATTER){
            setIsInnerBagsizeHidden(false)
          }else{
            setIsInnerBagsizeHidden(true)
          }
          console.log(pouchWeightValue);
          form.setFieldsValue({ shrimpWeight: (val == PackTypeCategory.POUCH?(pouchWeightValue?Number(pouchWeightValue):0):0), innerBags: 0, innerBagSizeId: undefined });
          console.log(form.getFieldsValue());
          const object = {
            ...fieldsData, [label]: row.value, shrimpWeight: val == PackTypeCategory.POUCH ? pouchWeightValue:0, innerBags: 0, innerBagSizeId: undefined
          }
          console.log(object)
          setFieldsData({ ...object });
          // setFieldUpdateKey(prevState => prevState+1)
        }
        props.category = val;

        // }
      } else {

        if (label != 'soakingTime' && form.getFieldValue('soakingTime') == 120) {
          if (label == 'uomId') {
            const itemFeilds = form.getFieldsValue();
            // console.log(itemFeilds);
            if(fieldsData.category != PackTypeCategory.RING){
              form.setFieldsValue({shrimpWeight:itemFeilds.pouchWeight})
            }else{
              form.setFieldsValue({shrimpWeight:0})
            }
            const object = {
              ...fieldsData, pouches: itemFeilds.pouches, pouchWeight: itemFeilds.pouchWeight, shrimpWeight: itemFeilds.pouchWeight, soakingTime: 120, uomId: row.value
            }
            setFieldsData({ ...object });
          } 
          else if(label == 'brandId'){
            let newobject
            newobject = {
              ...fieldsData, [label]: row.value, masterBrandId: rowObj?.masterBrandId,

            }
            form.setFieldsValue({masterBrandId:rowObj?.masterBrandId});
            setFieldsData({ ...newobject })
          }
          else {
            let newobject
            newobject = {
              ...fieldsData, [label]: row.value, soakingTime: 120,

            }
            setFieldsData({ ...newobject })
          }

        } else {


          setFieldsData({ ...fieldsData, [label]: row.value });
        }
      }



    }


  }



  const appendInputData = (val, label) => {
    setProductsBtnDisable(false)
    if (props.category != PackTypeCategory.RING) {
        setFieldsData({ ...fieldsData, [label]: val });
        if (label == 'pouchWeight') {
          form.setFieldsValue({ shrimpWeight: val });
          const object = {
            ...fieldsData, [label]: val, shrimpWeight: val
          }
          setFieldsData({ ...object });

        }
      // }
    } else {
      setFieldsData({ ...fieldsData, [label]: val });

    }

  }
 

  const packMethodeChange = (val) => {
    const selectedPackMethode = packingMethods.find((value) => value.packingMethodId === val);
    form.setFieldsValue({ 'pouches': selectedPackMethode.noOfPouches, 'pouchWeight': selectedPackMethode.pouchesPerRow, packStyleId: selectedPackMethode.packingMethodId, WeighingType: selectedPackMethode.uomId, 'uomId': selectedPackMethode.uomId });
    const unitName = unitOfWeights.find((val) => val.value === selectedPackMethode.uomId).name;
    const rowData = {
      children: unitName,
      className: "stylesInfo",
      key: selectedPackMethode.uomId,
      label: "uomId",
      value: selectedPackMethode.uomId,
      packMethodId:selectedPackMethode.packingMethodId,
      packMethodName:selectedPackMethode.packingMethodName,
    }
    appendData(selectedPackMethode.uomId, rowData);
    setPackStyle(val);
  }
  const saveData = () => {
    console.log("values");
    const values = form.getFieldsValue()
    console.log(values);
    
    form.validateFields().then(res => {
      console.log(fieldsData)
      console.log(res);
      res.saleOrderId = props.saleOrderId;
      res.brandId = values.Brand;
      
      saleOrderService.addSoItem(res).then((res) => {
        if(res.status){
          AlertMessages.getSuccessMessage(res.internalMessage);
          onReset();
          props.closeModal();
        }
        else{
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  };
  return (
    <Form layout="vertical" form={form} name="control-hooks" >
      <>
        <Card title={<span style={{ color: 'white' }}>Product Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <div >

            <Row gutter={24}>
              <Form.Item
                name={'saleOrderId'}
                rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing saleOrderId/can not allow decimals' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'saleOrderId')} />
              </Form.Item>
              <Form.Item
                name={'pouches'}
                rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing Pouches/can not allow decimals' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'pouches')} />
              </Form.Item>

              <Form.Item
                name={'pouchWeight'}
                rules={[{ required: true, message: 'Missing Pouch Weight' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'pouchWeight')} />
              </Form.Item>
              <Form.Item
                name={'uomId'}
                rules={[{ required: true, message: 'Missing UomId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'uomId')} />
              </Form.Item>
              <Form.Item
                name={'masterBrandId'}
                rules={[{ required: true, message: 'Missing masterBrandId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'masterBrandId')} />
              </Form.Item>
              <Form.Item
                name={'valueAdditionOneId'}
                rules={[{ required: true, message: 'Missing valueAdditionOneId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'valueAdditionOneId')} />
              </Form.Item>
              <Form.Item
                name={'valueAdditionTwoId'}
                rules={[{ required: true, message: 'Missing valueAdditionTwoId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'valueAdditionTwoId')} />
              </Form.Item>
              <Form.Item
                name={'valueAdditionThreeId'}
                rules={[{ required: true, message: 'Missing valueAdditionThreeId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'valueAdditionThreeId')} />
              </Form.Item>
              <Form.Item
                name={'valueAdditionFourId'}
                rules={[{ required: true, message: 'Missing valueAdditionFourId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'valueAdditionFourId')} />
              </Form.Item>
              <Form.Item
                name={'valueAdditionFiveId'}
                rules={[{ required: true, message: 'Missing valueAdditionFiveId' }]}
              >
                <Input hidden onChange={e => appendInputData(e, 'valueAdditionFiveId')} />
              </Form.Item>
              <Form.Item
                name={'categoryId'}
                rules={[{ required: true, message: 'Missing categoryId' }]} hidden
              >
                <Input hidden />
              </Form.Item>
              <Col span={4}>
                <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Missing category' }]}>
                  <Select

                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Category"
                    allowClear
                    onChange={appendData}
                  // disabled={updateDisableField}
                  >
                    {categories.map(catdropData => {
                      return <Option label={'category'} value={catdropData.value}>{catdropData.name}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={5}>

                <Form.Item label="Brand"
                  name={'Brand'}
                  rules={[{ required: true, message: 'Missing Brand name' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Brand"
                    onChange={appendData}
                    allowClear
                    disabled={updateDisableField}

                  >
                    {props.brandsData.map(branddropData => {
                      return <Option label={'brandId'} masterBrandId={branddropData.masterBrandId} key={branddropData.brandId} value={branddropData.brandId}>{branddropData.brandName}</Option>
                    })}
                  </Select>
                </Form.Item>



              </Col>

              {/* <Col span={4}> */}



              <Form.Item label="Grade" required hidden>
                <Input.Group compact>
                  <Form.Item
                    name={'minGrade'}
                    noStyle
                    rules={[{ required: true, message: 'Missing Min Grade' }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, 'minGrade')} style={{ width: "50%" }} placeholder="Min" min={0} disabled={updateDisableField} />
                  </Form.Item>


                  <Form.Item required
                    name={'maxGrade'}
                    noStyle
                    rules={[{ required: true, message: 'Missing Max Grade/Max grade should greater than Min grade' }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, 'maxGrade')} style={{ width: "50%" }} placeholder="Max" min={0} disabled={updateDisableField} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>



              {/* </Col> */}
              {/* <Col span={5}> */}

              <Form.Item label="Required Count" hidden
                name={'requiredCount'}
                rules={[{ required: true, message: 'Missing requiredCount' }]}
              >
                <InputNumber/>
                </Form.Item>
              <Form.Item label="Product" hidden
                name={'itemId'}
                rules={[{ required: true, message: 'Missing Product name' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  key={Date.now()}
                  placeholder="Select Product"
                  onChange={appendData}
                  allowClear
                  disabled={updateDisableField}
                >
                  {itemsdata.map(dropData => {
                    return <Option key={dropData.productId} label='itemId' value={dropData.productId}>{dropData.productName}</Option>
                  })}
                </Select>
              </Form.Item>


              {/* </Col> */}
              <Col span={6} hidden>
                <Form.Item label="Food Type"
                  name={'foodTypeId'}
                  rules={[{ required: false, message: 'Missing Food Type name' }]}
                >
                  <Select
                    placeholder="Select Food Type"
                    onChange={appendData}
                    allowClear
                  >
                    {foodTypeData.map(foodTypedropData => {
                      return <Option label='foodTypeId' key={foodTypedropData.foodTypeId} value={foodTypedropData.foodTypeId}>{foodTypedropData.foodType}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={8} >
                <Form.Item label="Product"
                  name={'varientId'}
                  rules={[{ required: true, message: 'Missing Varient Code name' }]}
                >
                  <Select
                    className={'stylesInfo'}//for font style
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Variant Code"
                    onChange={appendData}
                    allowClear
                    disabled={updateDisableField}
                    dropdownMatchSelectWidth={false}
                  >
                    {skucodes.map(skudropData => {
                      return <Option className={'stylesInfo'} label={'varientId'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Pack Style" required name={'packStyleId'}>
                  <Select
                    className={'stylesInfo'}//for font style
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Packing Method"
                    onSelect={packMethodeChange}
                    allowClear
                    disabled={updateDisableField}
                  >
                    {packingMethods.map(skudropData => {
                      return <Option className={'stylesInfo'} label={'packStyleId'} key={skudropData.packingMethodId} value={skudropData.packingMethodId}>{skudropData.packingMethodName}</Option>
                    })}
                  </Select>
                </Form.Item>


              </Col>
              <Col span={3} style={{ display: 'none' }}>

                <Form.Item label="UOM"
                  name={'WeighingType'}
                  rules={[{ required: true, message: 'Missing UOM' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select UOM"
                    onChange={appendData}
                    allowClear
                    disabled={updateDisableField}
                  >
                    {unitOfWeights.map(weighdropData => {
                      return <Option label={'uomId'} key={weighdropData.value} value={weighdropData.value}>{weighdropData.name}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              

            </Row>

            <Row gutter={24}>


              <Form.Item label="Glazing" required hidden>
                <Input.Group compact>
                  <Form.Item
                    name={'minGlaze'}
                    noStyle
                    rules={[{ required: true, message: 'Missed glazing/Enter a correct value', pattern: /^[0-9]*$/ }]}

                  >
                    <InputNumber onChange={e => appendInputData(e, 'minGlaze')} style={{ width: "35%" }} placeholder="Min" min={1} disabled={updateDisableField} />
                  </Form.Item>

                  <Form.Item name={['glazzing', 'middle']} noStyle>
                    <Input style={{ width: "30%" }}
                      placeholder="~"
                      disabled

                    />
                  </Form.Item>
                  <Form.Item
                    name={'MaxGlaze'}
                    noStyle
                    rules={[{ required: true, message: 'Missed max glaze/Max glaze greater than Min glaze/Enter a correct value', pattern: /^[0-9]*$/ }]}
                  >
                    <InputNumber onChange={e => appendInputData(e, ('MaxGlaze'))} style={{ width: "35%" }} placeholder="Max" min={1} disabled={updateDisableField} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>


              {/* </Col> */}
              {/* <Col span={3}> */}
              <Form.Item name="soakingTime" hidden label="Soaking Time" rules={[{ required: true }]} initialValue={120}>
                <InputNumber onChange={e => appendInputData(e, 'soakingTime')} style={{ width: '100%' }} min={1} disabled={updateDisableField} />
              </Form.Item>
              {/* </Col> */}

              {/* <Col span={4}> */}
              <Form.Item name="soakingStyle" hidden label="Soaking Style" rules={[{ required: true, message: 'Missing Soaking Style' }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Soaking Style"
                  onChange={appendData}
                  // onChange={soakingStyleOnchange}
                  allowClear
                  disabled={updateDisableField}
                >
                  <Option label={'soakingStyle'} value="STPP+SALT">STPP+SALT</Option>
                  <Option label={'soakingStyle'} value="Only SALT">Only SALT</Option>
                  <Option label={'soakingStyle'} value="NP + SALT">NP + SALT</Option>
                  <Option label={'soakingStyle'} value="CP + SALT">CP + SALT</Option>
                </Select>
              </Form.Item>
              {/* </Col> */}

              <Col span={4}>
                <Form.Item name="styleWeighment" label="Style Weighment" rules={[{ required: true, message: 'Missing Style Weighment' }]}>
                  <Select
                    placeholder="Weighment"
                    onChange={appendData}
                    // onChange={poNWNCOnchange}
                    allowClear
                    disabled={updateDisableField}
                  >
                    <Option label="styleWeighment" value="NWNC">NWNC</Option>
                    <Option label="styleWeighment" value="FWNC">FWNC</Option>
                    <Option label="styleWeighment" value="FWFC">FWFC</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>

                <Form.Item label={"Unit Price"}
                  name={'unitPrice'}
                  rules={[{ required: true, message: 'Missing unitPrice ' }]}
                >
                  <InputNumber placeholder="Unit Price" onChange={e => appendInputData(e, 'unitPrice')} style={{ width: '100%' }} min={0} />
                </Form.Item>

              </Col>
              <Col span={4} hidden>

                <Form.Item label="Net Drained Weight"
                  name={'shrimpWeight'}
                  rules={[{ required: true, message: 'Missing Weight' }]}
                >
                  <Input placeholder="Net Drained Weight" onChange={e => appendInputData(e.target.value, 'shrimpWeight')} disabled={updateDisableField} min={0} />

                </Form.Item>

              </Col>


              <Col span={4} hidden>

                <Form.Item label="No. Of Cases"
                  name={'cases'}
                  rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing cases/can not allow decimals' }]}
                  initialValue={0}
                >
                  <InputNumber placeholder="Cases" defaultValue={0} style={{ width: '100%' }} min={0} />
                </Form.Item>

              </Col>
              <Col span={4} hidden>

                <Form.Item label="No. Of Cases"
                  name={'jobCases'}
                  rules={[{ required: true, pattern: /^[0-9]*$/, message: 'Missing jobCases/can not allow decimals' }]}
                  initialValue={0}
                >
                  <InputNumber placeholder="jobCases" defaultValue={0} style={{ width: '100%' }} min={0} />
                </Form.Item>

              </Col>
              <Col span={4}>

                <Form.Item label="Net Case Weight"
                  name={'caseWeight'}
                  rules={[{ message: 'Missing Net case Weight' }]}
                  initialValue={"0"}
                >
                  <Input placeholder="Net Case Weight" defaultValue={"0"} />
                </Form.Item>


              </Col>
              <Col span={4}>

                <Form.Item label="Net Weight"
                  name={'netWeight'}
                  rules={[{ message: 'Missing netWeight' }]}
                    initialValue={"0"}
                >
                  <Input placeholder="Net weight" defaultValue={"0"} />
                </Form.Item>

              </Col>

              <Col span={4}>
                <Form.Item label="Net Amount"
                  name={'netAmount'}
                  rules={[{ message: 'Missing netAmount' }]}
                  initialValue={"0"}
                >
                  <Input placeholder="Net Amount" defaultValue={"0"}/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>


              <Col span={4}>

                <Form.Item label="Skewers"
                  name={'innerBags'}
                  hidden={isInnerBagsHidden}
                  rules={[{ required: !isInnerBagsHidden, message: 'Missing Skewers' }]}
                >
                  <Input placeholder="Skewers" onChange={e => appendInputData(e.target.value, 'innerBags')} />

                </Form.Item>

              </Col>


              <Col span={4}>

                <Form.Item label="Variety"
                  name={'innerBagSizeId'}
                  hidden={isInnerBagsizeHidden}
                  rules={[{ required: !isInnerBagsizeHidden, message: 'Missing Skewer size' }]}
                >
                  <Select
                    placeholder="Select Skewer Variety"
                    onChange={appendData}
                    // onClear={clearCustomerDependentData}
                    allowClear
                  >
                    {sizes.map(dropData => {
                      return <Option label="innerBagSizeId" key={dropData.sizeId} value={dropData.sizeId}>{dropData.sizeName}</Option>
                    })}
                  </Select>

                </Form.Item>

              </Col>

              <Col span={4}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Button type="primary" hidden={isInnerBagsizeHidden} onClick={showModal} style={{ backgroundColor: '#40a9ff' }}>+New Size</Button>
                
                <Modal title="Add Size" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                  <SizesForm sizesData={undefined}
                    isUpdate={false}
                    closeForm={() => { }}
                    updateSize={(undefined => { })}
                  />
                </Modal>
              </Col>
            </Row>
            <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" onClick={saveData}>
                Submit
              </Button>
                <Button
                  htmlType="button"
                  style={{ margin: '0 14px' }}
                  onClick={onReset}
                >
                  Reset
                </Button>
            </Col>
          </Row>
          </div>
          <br />
        </Card>
        <br />
      </>
    </Form>
  );
};

export default ItemDetailsForm;
//so form bckup