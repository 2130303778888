import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { WorkStationsDropDown } from "./work-stations-drop-down.dto";

export class WorkStationsForCategoryResponse extends GlobalResponseObject{
    data?: WorkStationsDropDown[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: WorkStationsDropDown[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}
