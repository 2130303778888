import axios from 'axios';
import connection from './connection';
import {AllGradingMonitoringResponseModel, GradeInventoryIdReq, GradingMonitoringDto,  GradingMonitoringResponseModel} from '@gtpl/shared-models/production-management';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class GradingMonitoringService {

    URL = connection.DEPLOY_URL + '/grading-monitoring';

    async createGradingMonitoring(dto: GradingMonitoringDto ): Promise<GradingMonitoringResponseModel> {
        console.log(dto);
       
        return await axios.post(this.URL + '/createGradingMonitoring',dto)
            .then(res => {
                return res.data
            })
    }

    async getSamplesInfoByGrdInvId(req:GradeInventoryIdReq):Promise<AllGradingMonitoringResponseModel>{
        return await axios.post(this.URL + '/getSamplesInfoByGrdInvId', req)
            .then(res => {
                return res.data
            })   
    }

    async getAllGradeMonitoring():Promise<AllGradingMonitoringResponseModel>{
        return await axios.post(this.URL + '/getAllGradeMonitoring')
            .then(res => {
                return res.data
            })   
    }
}