import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LocationDto } from './location-dto';



export class LocationResponse extends GlobalResponseObject{
    data?: LocationDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: LocationDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 