export class VehicleDto {
    vehicleId?: number;
    vehicleName: string;
    registrationNumber: string;
    isActive: boolean;
    createdUser : string;
    updatedUser?:string;
    /**
     * 
     * @param vehicleId number
     * @param vehicleName string
     * @param registrationNumber string
     * @param isActive boolean
     * @param createdUser string
     * @param updatedUser string
     */
    constructor(vehicleId: number,vehicleName: string,registrationNumber: string,isActive: boolean,createdUser : string,updatedUser?:string){ 
            this.vehicleId = vehicleId;
            this.vehicleName = vehicleName
            this.registrationNumber=registrationNumber;
            this.isActive=isActive;
            this.createdUser= createdUser;
            this.updatedUser=updatedUser;
        }
    }

export const VehicleDtoDefault : VehicleDto = {
     vehicleId: 0,
    vehicleName: "",
    registrationNumber: "",
    isActive: true,
    createdUser : '',
    updatedUser:''
};

