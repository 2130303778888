export class RackPostionReq {
    rackPositionId: number;
    newRackPositionId?: number;
    saleOrderId?:number;
    updatedUser?: string;
    versionFlag?: number;
    isActive?: boolean;

    constructor(rackPositionId: number,newRackPositionId?:number,saleOrderId?:number,updatedUser?: string,versionFlag?: number,isActive?: boolean) {
        this.rackPositionId = rackPositionId;
        this.newRackPositionId = newRackPositionId;
        this.saleOrderId = saleOrderId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isActive = isActive;
    }
}