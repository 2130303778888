export class SkuDropDownData{
  skuCodeId:number;
  variantCode:string;
    
    /**
     * 
     * @param skuCodeId 
     * @param skuCode 
     */
    constructor(skuCodeId:number,variantCode:string){
      this.skuCodeId = skuCodeId;
      this.variantCode = variantCode;
    }
}