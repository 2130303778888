import axios from 'axios';
import {
  AllProductsResponseModel,
  ProductDto,
  ProductRequest,
  ProductResponseModel,
  ProductsDropDownData,
  ProductsDropDownDataResponseModel,
} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class ProductService {
  URL = connection.DEPLOY_URL + '/products';

  async createProduct(product: ProductDto): Promise<ProductResponseModel> {
    return await axios
      .post(this.URL + '/createProduct', product)
      .then((res) => {
        return res.data;
      });
  }
  async updateProduct(product: ProductDto): Promise<ProductResponseModel> {
    return await axios
      .post(this.URL + '/updateProduct', product)
      .then((res) => {
        return res.data;
      });
  }
  async getAllProducts(req?:UserRequestDto): Promise<AllProductsResponseModel> {
    return await axios.post(this.URL + '/getAllProducts',req)
        .then(res => {
            return res.data
        })
  }
  async activatedeActivateProduct(
    productDto: ProductDto
  ): Promise<ProductResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivateProduct', productDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveProducts(): Promise<AllProductsResponseModel> {
    return await axios.post(this.URL + '/getAllActiveProducts').then((res) => {
      return res.data;
    });
  }
  
  async getActiveProductsCount(): Promise<AllProductsResponseModel> {
    return await axios.post(this.URL + '/getActiveProductCount').then((res) => {
      return res.data;
    });
  }
  async getActiveProductById(productRequest:ProductRequest): Promise<ProductResponseModel> {
    return await axios.post(this.URL + '/getActiveProductById', productRequest).then((res) => {
      return res.data;
    });
  }
  async getProductByCategoryId(itemCategoryId:number): Promise<AllProductsResponseModel> {
    return await axios.post(this.URL + '/getProductByCategoryId', itemCategoryId).then((res) => {
      return res.data;
    });
  }
  async getProducts(): Promise<ProductsDropDownDataResponseModel> {
    return new ProductsDropDownDataResponseModel(true,11,'itemsData retrived successfully',[new ProductsDropDownData(1,'vanami'),new ProductsDropDownData(2,'Black Tiger')])
    // return await axios.post(this.URL + '/getActiveProductCount').then((res) => {
    //   return res.data;
    // });
  }
}
