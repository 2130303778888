import { RmOccupancyEnum } from "../rm-rack-position";


export class RmRackSuggestionResponseDto{
    plantId:number;
    totalCases:number;
    inStockCases:number;
    zoneId:number;
    zoneName:string;
    rmRackPositionCode:number;
    rmRackPositionId:number;
    rmRackCode:string;
    occupancy:RmOccupancyEnum;
    constructor(plantId:number,
        totalCases:number,
        inStockCases:number,
        zoneId:number,
        zoneName:string,
        rmRackPositionCode:number,
        rmRackPositionId:number,
        rmRackCode:string,
        occupancy:RmOccupancyEnum,){
            this.plantId=plantId
        this.totalCases=totalCases
        this.inStockCases=inStockCases
        this.zoneId=zoneId
        this.zoneName=zoneName
        this.rmRackPositionCode=rmRackPositionCode
        this.rmRackPositionId=rmRackPositionId
        this.rmRackCode=rmRackCode
        this.occupancy=occupancy
        }

}