import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DimensionServiceModel } from './dimension-service.model';

export class DimensionServiceAckResponse extends GlobalResponseObject{
    data?: DimensionServiceModel[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: DimensionServiceModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 