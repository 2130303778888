export class ServiceListDetails {
    totalNoOfServices: number;
    totalServicesValue: number;
    totalNoOfSkippedServices: number;
    noOfServicesDueIn30Days: number;
    noOfServicesDueIn60Days: number;
    noOfServicesDueIn90Days: number;
    serviceApplicableButNotInUseCount:number;
    serviceApplicableButNotInUseValue:number;
    constructor(
      totalNoOfServices: number,
      totalServicesValue: number,
      totalNoOfSkippedServices: number,
      noOfServicesDueIn30Days: number,
      noOfServicesDueIn60Days: number,
      noOfServicesDueIn90Days: number,
      serviceApplicableButNotInUseCount:number,
    serviceApplicableButNotInUseValue:number
    ) {
      this.totalNoOfServices = totalNoOfServices;
      this.totalServicesValue = totalServicesValue;
      this.totalNoOfSkippedServices = totalNoOfSkippedServices;
      this.noOfServicesDueIn30Days = noOfServicesDueIn30Days;
      this.noOfServicesDueIn60Days = noOfServicesDueIn60Days;
      this.noOfServicesDueIn90Days = noOfServicesDueIn90Days;
      this.serviceApplicableButNotInUseCount = serviceApplicableButNotInUseCount;
      this.serviceApplicableButNotInUseValue = serviceApplicableButNotInUseValue;
    }
  }
  