import React, { useEffect, useState,useRef } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal, Form, Select, DatePicker, Alert } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined,FilePdfOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Excel } from 'antd-table-saveas-excel';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import {AssetService} from '../../../../../../shared-services/asset-management/src/lib/assets.service';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';

import './pages-asset-management-asset-management-components-mrn-form.css';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';
import {UnitcodeService,ItemSubCategoryService} from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import {DepartmentService} from 'libs/shared-services/masters/src/lib/department-service';
import {EmployeeService} from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
const {RangePicker} = DatePicker;
import Highlighter from 'react-highlight-words';
import {AssetTypeEnum} from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const {Option} = Select;
let diff = 0;
let date;
let todate;

/* eslint-disable-next-line */
export interface AssetsReportProps {}

export function AssetsReport(
  props: AssetsReportProps
) {
  const [assetsData,setAssetsData] = useState<AssetDto[]>([]);
  const [form] = Form.useForm();
  const service = new AssetService();
  const locationService = new AssetLocationService();
  const [locationData,setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName,setPlantName]= useState<any>([]);
  const [itemCategory,setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  const departmentService = new DepartmentService();
  const [depart,setDepart] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);
  const employeeService = new EmployeeService();
  const role = JSON.parse(localStorage.getItem('role'))
  const [length,setLength] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);





  useEffect(()=>{
    // getAllAssets();
    getAllPlants();
    getItemCategory();
    onStatusSearch()
    getDepartments();
    employeeDetails();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations()
  },[])

  
  const employeeDetails = () => {
    employeeService.getAllActiveEmployees().then((res)=>{
      if(res.status) {
        setEmployeeData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if(res.status){
        setDepart(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
  var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear,currentMonth,1)
  const dateformat = 'YYYY-MM-DD';

  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
    const getAllAssets = () => {
      service.getByStatus().then(res => {
        if(res.status) {
          setAssetsData(res.data)
          AlertMessages.getSuccessMessage('Assets retrieved successfully')
        } else { 
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }

    const getAllPlants = () => {
      plantsService.getAllPlants().then(res => {
        if(res.status){
          setPlantName(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
  
    const getLocations = () => {
      const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
      locationService.getAllAssets(req).then(res => {
        if(res.status){
          setLocationData(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex: string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: visible => {
        if (visible) { setTimeout(() => searchInput.current.select()); }
      },
      render: text =>
        text ? (
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : text
        ) : null
    });


    const columns: ColumnProps<any>[] = [
      {
        title: "S.No",
        key: "sno",
        width: 60,
        fixed: "left",
        responsive: ["sm"],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },    {
        title: "Unit",
        dataIndex: "plantName",
        align: "left",
        fixed: "left",
        // width:120,
        sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('plantName'),
        render: (text, record) => {
          return (
            <>
              {record.plantName ? record.plantName : '-'}
            </>
          )
        },
      },
  
      {
        title: "Location",
        dataIndex: "currentLocation",
        align: "left",
        fixed: "left",
        // width:120,
        sorter: (a, b) => a.currentLocation?.localeCompare(b.currentLocation),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('currentLocation'),
        render: (text, record) => {
          return (
            <>
              {record.currentLocation ? record.currentLocation : '-'}
            </>
          )
        },
      },
      {
        title: "Asset Mode",
        dataIndex: "assetMode",
        align: "left",
        fixed: "left",
         width:110,
        sorter: (a, b) => a.assetMode?.localeCompare(b.assetMode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetMode'),
        render: (text, record) => {
          return (
            <>
              {record.assetMode ? record.assetMode : '-'}
            </>
          )
        },
      },
      {
        title: "Asset Type",
        dataIndex: "assetType",
        align: "left",
        fixed: "left",
        // width:120,
        sorter: (a, b) => a.assetType?.localeCompare(b.assetType),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetType'),
        render: (text, record) => {
          return (
            <>
              {record.assetType ? record.assetType : '-'}
            </>
          )
        },
      },
      {
        title: "Asset Category",
        dataIndex: "itemSubCategoryName",
        align: "left",
        // width:120,
        // fixed: "left",
        sorter: (a, b) => a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('itemSubCategoryName')
      },
      {
        title: "Asset Name",
        dataIndex: "itemName",
        align: "left",
        // fixed: "left",
        width: 120,
        sorter: (a, b) => a.itemName.localeCompare(b.itemName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('itemName')
      },
      {
        title: "Asset Code",
        dataIndex: "assetsCode",
        align: "left",
        width: 120,
        sorter: (a, b) => a.assetsCode?.localeCompare(b.assetsCode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetsCode'),
       
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice",
        align: "left",
        // width:120,
        sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('invoice'),
        render: (text, record) => {
          return (
            <>
              {record.invoice ? record.invoice : '-'}
            </>
          )
        },
      },
      {
        title: "Serial Number",
        dataIndex: "assetSerialNo",
        align: "left",
        // width:120,
        sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetSerialNo'),
        render: (text, record) => {
          return (
            <>
              {record.assetSerialNo ? record.assetSerialNo : '-'}
            </>
          )
        },
      },
  
      {
        title: "Purchase Date",
        dataIndex: "dateOfPurchased",
        align: "right",
        sorter: (a, b) => a.dateOfPurchased?.localeCompare(b.dateOfPurchased),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('dateOfPurchased'),
        render: (text, record) => {
          return (
            <>
              {record.dateOfPurchased ? moment(record.dateOfPurchased).format('YYYY-MM-DD') : 'N/A'}
            </>
          )
        },
  
      },
      {
        title: "AMC",
        dataIndex: "amcname",
        align: "left",
        // width:120,
        sorter: (a, b) => a.amcname?.localeCompare(b.amcname),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('amcname'),
        render: (text, record) => {
          return (
            <>
              {record.amcname ? record.amcname : 'No'}
            </>
          )
        },
  
      },
      {
        title: "Warranty Date",
        dataIndex: "warrentyExpiredDate",
        align: "right",
        //  width:80,
        // sorter: {
        //   compare:(a, b) =>{
        //     a= a.created_date.toLowerCase()
        //     b = b.created_date.toLowerCase()
        //     return a > b ? -1 : b > a ? 1:0
        //   },
        // },
        sorter: (a, b) => a.warrentyExpiredDate?.localeCompare(b.warrentyExpiredDate),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('warrentyExpiredDate'),
        render: (text, record) => {
          return (
            <>
              {record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD') : 'N/A'}
            </>
          )
        },
  
      },
      {
        title: "Insurance Date",
        dataIndex: "insuranceDate",
        align: "right",
        //  width:70,
        sorter: (a, b) => a.insuranceDate?.localeCompare(b.insuranceDate),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('insuranceDate'),
        render: (text, record) => {
          return (
            <>
              {record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : '-'}
            </>
          )
        },
  
      },
      {
        title: "Expiry Date",
        dataIndex: "expiredDate",
        align: "right",
        // width:120,
        sorter: (a, b) => a.expiredDate?.localeCompare(b.expiredDate),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('expiredDate'),
        render: (text, record) => {
          return (
            <>
              {record.expiredDate ? moment(record.expiredDate).format('YYYY-MM-DD') : 'N/A'}
            </>
          )
        },
  
      },
     
      {
        title: "Status",
        dataIndex: "status",
        align: "left",
        sorter: (a, b) => a.status?.localeCompare(b.status),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('status')
  
      },
      {
        title: "Assigned To",
        dataIndex: "assignedName",
        align: "left",
        sorter: (a, b) => a.assignedName?.localeCompare(b.assignedName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assignedName'),
        render: (text, record) => {
          return (
            <>
              {record.status == `${AssetStatusEnum.INUSE}` ? record.assignedName : '-'}
            </>
          )
        },
  
      },
      {
        title: "Department",
        dataIndex: "departmentName",
        align: "left",
        sorter: (a, b) => a.departmentName?.localeCompare(b.departmentName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('departmentName'),
        render: (text, record) => {
          return (
            <>
              {record.status == `${AssetStatusEnum.INUSE}` ? record.departmentName : '-'}
            </>
          )
        },
      },
      // {
      //   title: "Aging",
      //   dataIndex: "age",
      //   render:(text,record,index) => {        
      //       todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //       date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
      //       diff = (todate - date) / 864e5;
      //       if(diff < 0) {
      //         diff = -diff;
      //       }
      //     return(
      //       <>
      //       {record.transactionDate ?  diff : '-'}
      //       </>
      //     )
  
      //   }
      // },
      {
        title: "Usage Age",
        dataIndex: "age",
        align:'right',
        // sorter:(a,b)=>a.age-b.age,
        // sortDirections:['descend','ascend'],
        // ...getColumnSearchProps('age'),
        sorter: (a, b) => moment(a.transactionDate).year() - moment(b.transactionDate).year(),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('transactionDate'),
        render:(text,record,index) => {        
            todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
            date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
            diff = (todate - date) / 864e5;
            if(diff < 0) {
              diff = -diff;
            }
          return(
            <>
            {record.transactionDate ?  diff : '-'}
            </>
          )
          
        }
      },
      {
         //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
        //   date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD'));
        //   diff = (todate - date) / 864e5;
        //   return (
        //     <>
        //       {record.dateOfPurchased ? diff : null}
        //     </>
        //   )
        // },  render: (text, record) => {
        title: ' Asset Age',
        dataIndex: 'assetAge',
        align: "right",
        sorter: (a, b) => moment(a.assetAge).year() - moment(b.assetAge).year(),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetAge'),
        render: (text, record) => {
       
          return (
            <>
              {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
            </>
          )
        },
        // filters: [
        //   // {
        //   //   text: 'Below 1 Year',
        //   //   value:'Below 1 Year'
        //   // },
        //   // {
        //   //   text: 'Below 2 Year',
        //   //   value:'Below 2 Year'
        //   // },
        //   {
        //     text: 'Below 5 Years',
        //     value:'Below 5 Years'
        //   },
        //   {
        //     text: 'Above 5 Years',
        //     value:'Above 5 Years'
        //   },
        // ],
        // filterMultiple: false,
        // onFilter: (value,record) => {
        //   const assetAge = record.assetAge;
        //   if(value === 'Below 5 Years'){
        //     return assetAge <= 1825;
        //   } else if(value === 'Above 5 Years') {
        //     return assetAge > 1825;
        //   } 
        //   return false;
        // }
      },  
      {
        title: "Cost of Asset",
        dataIndex: "assetCost",
        align: "right",
        // width:120,
        sorter: (a, b) => a.assetCost?.localeCompare(b.assetCost),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('assetCost'),
        render: (text, record) => {
          return (
            <>
              {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
            </>
          )
        },
  
      },
    ];
  
  
  const column: ColumnProps<any>[] = [
    {
      title: "S.No",
      key: "sno",
      width: 60,
      fixed:"left",
      responsive: ["sm"],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: "Unit",
      dataIndex: "plantName",
      align:"left",
      // width:120,
      sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
      render:(text,record) => {
        return (
          <>
            {record.plantName ? record.plantName : '-'}
          </>
        )
      },
    },
    
    {
      title:"Location",
      dataIndex: "currentLocation",
       align:'left',
       sorter:(a,b)=>a.currentLocation?.localeCompare(b.currentLocation),
       sortDirections:['descend','ascend'],
       ...getColumnSearchProps('currentLocation'),
      render:(text,record) => {
        return (
          <>
            {record.currentLocation ? record.currentLocation : '-'}
          </>
        )
      }
    },
    {
      title: "Asset Code",
      dataIndex: "assetsCode",
      align:'left',
      fixed:"left",
      sorter:(a,b)=>a.assetsCode.localeCompare(b.assetsCode),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('assetsCode'),

      // render:(text,record)=>{
      //   return<>
      //   <Button type='link'onClick={e => AssetsDetailedView(record.assetsCode)}>{record.assetsCode}</Button>
      //   </>
      // }
    },
    {
      title: "Asset Category",
      dataIndex: "itemSubCategoryName",
      align:'left',
      fixed:"left",
      sorter:(a,b)=> a.itemSubCategoryName.localeCompare(b.itemSubCategoryName),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('itemSubCategoryName'),
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      align:'left',
      fixed:"left",
      sorter:(a,b)=>a.itemName.localeCompare(b.itemName),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('itemName'),
    }, 
   
    {
      title: "Asset Type",
      dataIndex: "assetType",
      align:"left",
      // width:120,
      sorter:(a,b)=>a.assetType.localeCompare(b.assetType),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('assetType'),
      render:(text,record) => {
        return (
          <>
            {record.assetType ? record.assetType : '-'}
          </>
        )
      },
    },
    {
      title: "Serial Number",
      dataIndex: "assetSerialNo",
      align:"left",
      // width:120,
      sorter: (a, b) => a.assetSerialNo?.localeCompare(b.assetSerialNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assetSerialNo'),
      render:(text,record) => {
        return (
          <>
            {record.assetSerialNo ? record.assetSerialNo : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice",
      align:"left",
      // width:120,
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoice'),
      render:(text,record) => {
        return (
          <>
            {record.invoice ? record.invoice : '-'}
          </>
        )
      },
      // ...getColumnSearchProps('currentLocation')
    },
   
    {
      title: "Purchase Date",
      dataIndex: "dateOfPurchased",
      sorter: (a, b) => a.dateOfPurchased?.localeCompare(b.dateOfPurchased),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dateOfPurchased'),
      align:'right',
      render:(text,record) => {
        return(
          <>
          {record.dateOfPurchased? moment(record.dateOfPurchased).format('YYYY-MM-DD'): ' -'}
          </>
        )
      }
    },  {
      title: "Cost of Asset",
      dataIndex: "assetCost",
      align:'right',
      sorter:(a,b)=>a.assetCost.localeCompare(b.assetCost),
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('assetCost'),
      render:(text,record) => {
        return (
          <>
            {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : '-'}
          </>
        )
      }
    },
    {
      title: "AMC",
      dataIndex: "amcname",
      align:'left',
      sorter: (a, b) => a.amcname?.localeCompare(b.amcname),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('amcname'),
      render:(text,record) => {
        return(
          <>
          {record.amcname? record.amcname : 'No'}
          </>
        )
      }
    },
    {
      title: "Warranty Date",
      dataIndex: "warrentyExpiredDate",
      align:'right',
      sorter: (a, b) => a.warrentyExpiredDate?.localeCompare(b.warrentyExpiredDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('warrentyExpiredDate'),
      render:(text,record) => {
        return(
          <>
          {record.warrentyExpiredDate? moment(record.warrentyExpiredDate).format('YYYY-MM-DD'): '-'}
          </>
        )
      }
    },
    {
      title: "Insurance Date",
      dataIndex: "insuranceDate",
      align:'right',
      sorter: (a, b) => a.insuranceDate?.localeCompare(b.insuranceDate),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('insuranceDate'),
      render:(text,record) => {
        return(
          <>
          {record.insuranceDate? moment(record.insuranceDate).format('YYYY-MM-DD'): '-'}
          </>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align:'left',
      width:150,
      sorter:(a,b)=>a.status?.localeCompare(b.status),
       sortDirections:['descend','ascend'],
       ...getColumnSearchProps('status'),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedName",
      align:"right",
      // width:120,
      sorter: (a, b) => a.assignedName?.localeCompare(b.assignedName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedName'),
      render:(text,record) => {
        return (
          <>
            { record.status == `${AssetStatusEnum.INUSE}` ? record.assignedName : '-'}
          </>
        )
      }
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      align:"right",
      // width:120,
      sorter: (a, b) => a.departmentName?.localeCompare(b.departmentName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('departmentName'),
      render:(text,record) => {
        return (
          <>
            { record.status == `${AssetStatusEnum.INUSE}` ? record.departmentName : '-'}
          </>
        )
      }
    },
    {
      title: "Usage Age",
      dataIndex: "age",
      align:'right',
      // sorter:(a,b)=>a.age-b.age,
      // sortDirections:['descend','ascend'],
      // ...getColumnSearchProps('age'),
      sorter: (a, b) => moment(a.transactionDate).year() - moment(b.transactionDate).year(),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transactionDate'),
      render:(text,record,index) => {        
          todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
          date = moment(moment(record.transactionDate).format('YYYY-MM-DD')); 
          diff = (todate - date) / 864e5;
          if(diff < 0) {
            diff = -diff;
          }
        return(
          <>
          {record.transactionDate ?  diff : '-'}
          </>
        )
        
      }
    },
    {
       // render:(text,record) => {
      //   todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      //   date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD')); 
      //   diff = Math.floor((todate - date) / 864e5);
      //   return(
      //     <>
      //     {record.dateOfPurchased ? diff : null}
      //     </>
      //   )
      title: ' Asset Age',
      dataIndex: 'assetAge',
      align:'right',
      sorter:(a,b)=>a.assetAge-b.assetAge,
      sortDirections:['descend','ascend'],
      ...getColumnSearchProps('assetAge'),
      render: (text, record) => {
        return (
          <>
            {record.assetAge ? Number(Number(record.assetAge).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
          </>
        )
      },
      // filters: [
      //   // {
      //   //   text: 'Below 1 Year',
      //   //   value:'Below 1 Year'
      //   // },
      //   // {
      //   //   text: 'Below 2 Year',
      //   //   value:'Below 2 Year'
      //   // },
      //   {
      //     text: 'Below 5 Years',
      //     value:'Below 5 Years'
      //   },
      //   {
      //     text: 'Above 5 Years',
      //     value:'Above 5 Years'
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value,record) => {
      //   const assetAge = record.assetAge;
      //   if(value === 'Below 5 Years'){
      //     return assetAge <= 1825;
      //   } else if(value === 'Above 5 Years') {
      //     return assetAge > 1825;
      //   } 
      //   return false;
      // }
    },
    
  ];

  

  const exportedData = [];
  const excelData = assetsData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    { title: 'Unit', dataIndex: 'plantName',render: (text, record) => { return record.plantName ? record.plantName: '-'} },
    { title: 'Location', dataIndex: 'currentLocation',render: (text, record) => { return record.currentLocation ? record.currentLocation: '-'} },
        { title: 'Asset Mode', dataIndex: 'assetMode',render: (text, record) => { return record.assetMode ? record.assetMode: '-'} },
        { title: 'Asset Type', dataIndex: 'assetType',render: (text, record) => { return record.assetType ? record.assetType: '-'} },
        { title: 'Asset Category', dataIndex: 'itemSubCategoryName',render: (text, record) => { return record.itemSubCategoryName ? record.itemSubCategoryName: '-'} },
        { title: 'Asset Name', dataIndex: 'itemName',render: (text, record) => { return record.itemName ? record.itemName: '-'} },
    { title: 'Asset Code', dataIndex: 'assetsCode',render: (text, record) => { return record.assetsCode ? record.assetsCode: '-'} },
    {title: 'Invoice Number', dataIndex: 'invoice',render: (text, record) => { return record.invoice ? record.invoice: '-'} },
    { title: 'Serial Number', dataIndex: 'assetSerialNo',render: (text, record) => { return record.assetSerialNo ? record.assetSerialNo: '-'}  },
    { title: 'Purchase Date', dataIndex: 'dateOfPurchased', render: (text, record) => { return record.dateOfPurchased ? moment(record.daetOfPurchased).format('YYYY-MM-DD'): '-'} },
    { title: 'AMC', dataIndex: 'amc',render:(text,record) => {return record.amc == 'yes' ? 'Yes': 'No'} },
    { title: 'Warranty Date', dataIndex: 'warrentyExpiredDate', render: (text, record) => { return record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD'): '-'} },
    { title: 'Insurance Date ', dataIndex: 'insuranceDate', render: (text, record) => { return record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD'): '-'}  },
    { title: 'expiry Date ', dataIndex: 'expiredDate', render: (text, record) => { return record.expiredDate ? moment(record.expiredDate).format('YYYY-MM-DD'): '-'}  },
    {title: 'Status', dataIndex: 'status',render: (text, record) => { return record.status ? record.status: '-'}},
    { title: 'Assigned To', dataIndex: 'assignedName',render:(text,record) => {return record.status == 'INUSE' ? record.assignedName:'-'} },
    { title: 'Department', dataIndex: 'department',render:(text,record) => {return record.status == 'INUSE' ? record.departmentName:'-'} },
    { title: 'Usage Age', dataIndex: 'age',render: (text, record) => { return record.age ? record.age: '-'}  },
    { title: 'Cost of Asset', dataIndex: 'assetCost',render: (text, record) => { return record.assetCost ? record.assetCost: '-'}  },

    // {title: 'Model', dataIndex: 'model',render: (text, record) => { return record.model ? record.model: '-'} },
    // { title: 'Purchase Date', dataIndex: 'dateOfPurchased', render: (text, record) => { return record.dateOfPurchased ? moment(record.daetOfPurchased).format('YYYY-MM-DD'): '-'} },
    // { title: 'Cost of Asset', dataIndex: 'assetCost',render: (text, record) => { return record.assetCost ? record.assetCost: '-'}  },
    // { title: 'AMC', dataIndex: 'amc',render:(text,record) => {return record.amc == 'yes' ? 'Yes': 'No'} },
    // { title: 'Warranty ', dataIndex: 'warrentyExpiredDate', render: (text, record) => { return record.warrentyExpiredDate ? moment(record.warrentyExpiredDate).format('YYYY-MM-DD'): '-'} },
    // { title: 'Insurance ', dataIndex: 'insuranceDate', render: (text, record) => { return record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD'): '-'}  },
    // {title: 'Status', dataIndex: 'status',render: (text, record) => { return record.status ? record.status: '-'}},
    // { title: 'Assigned To', dataIndex: 'assignedName',render:(text,record) => {return record.status == 'INUSE' ? record.assignedName:'-'} },
    // { title: 'Department', dataIndex: 'department',render:(text,record) => {return record.status == 'INUSE' ? record.departmentName:'-'} },
    {title: 'Asset Age (In Days)' , dataIndex: 'assetAge', render:(text,record) => {
      todate = moment(moment(Date.now()).format('YYYY-MM-DD'));
      date = moment(moment(record.dateOfPurchased).format('YYYY-MM-DD')); 
      diff = (todate - date) / 864e5;
      return(
        <>
        {record.dateOfPurchased ? diff : null}
        </>
      )
    }},
    
  ];


  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Assets-report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('AssetsReport.xlsx');
  }

  const onStatusSearch = () => {
    const req = new AssetByStatus();
if(form.getFieldValue('assetCode') !== undefined){
  req.assetCode = form.getFieldValue('assetCode')
}
if(form.getFieldValue('status') !== undefined){
  req.status = form.getFieldValue('status')
}
if(form.getFieldValue('amcAvailable') !== undefined){
  req.amcAvailable = form.getFieldValue('amcAvailable')
}
if(form.getFieldValue('warrentyAvailable') !== undefined){
  req.warrentyAvailable = form.getFieldValue('warrentyAvailable')
}
if(form.getFieldValue('insuranceAvailable') !== undefined){
  req.insuranceAvailable = form.getFieldValue('insuranceAvailable')
}
if(form.getFieldValue('date') !== undefined){
  req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM_DD')
}
if(form.getFieldValue('date') !== undefined){
  req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM_DD')
}
if (Number(localStorage.getItem('unit_id')) != 5) {
  req.plantId = Number(localStorage.getItem('unit_id'));
} else {
  if (form.getFieldValue('plantId') != undefined) {
    req.plantId = form.getFieldValue('plantId')
  }};
if(form.getFieldValue('location') !== undefined){
  req.location = form.getFieldValue('location')
}
if(form.getFieldValue('assetType') !== undefined){
  req.assetType = form.getFieldValue('assetType')
}
if(form.getFieldValue('assetCategory') !== undefined){
  console.log('------------hhhhhhh------')
  req.assetCategory = form.getFieldValue('assetCategory')
}
if(form.getFieldValue('department') !== undefined){
  req.department = form.getFieldValue('department')
}
if(form.getFieldValue('employeeId') !== undefined){
  req.employeeId = form.getFieldValue('employeeId')
}
if(form.getFieldValue('warrantyDate') !== undefined){
  req.warrantyStartDate = moment(form.getFieldValue('warrantyDate')[0]).format('YYYY-MM_DD')
}
if(form.getFieldValue('warrantyDate') !== undefined){
  req.warrantyEndDate = moment(form.getFieldValue('warrantyDate')[1]).format('YYYY-MM_DD')
}
if(form.getFieldValue('insuranceDate') !== undefined){
  req.insuranceStartDate = moment(form.getFieldValue('insuranceDate')[0]).format('YYYY-MM_DD')
}
if(form.getFieldValue('insuranceDate') !== undefined){
  req.insuranceEndDate = moment(form.getFieldValue('insuranceDate')[1]).format('YYYY-MM_DD')
}
if(form.getFieldValue('assetCost') != undefined){
  req.assetCost = form.getFieldValue('assetCost')
}
if (form.getFieldValue('assetAge') != undefined) {
  req.aging = form.getFieldValue('assetAge')
}

    // const req = new AssetByStatus(assetCode,null,status,amcAvailable,warrentyAvailable,insuranceAvailable,null,null,plant,location,assetType,assetCategory,department)
    service.getByStatus(req).then(res => {
      if(res.status) {
        
        setAssetsData(res.data)
        setLength(res.data.length)
        if(res.data.length > 0){
          AlertMessages.getSuccessMessage('Data retrieved successfully')
          } else {
            AlertMessages.getErrorMessage('No records found')
          }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const onReset = () => {
    form.resetFields();
    onStatusSearch()

  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <>
    <Card
        title={<span style={{ color: "white" }}> Asset Report</span>} style={{ textAlign: "center" }} headStyle={{ backgroundColor: "#69c0ff", border: 0 }}
        // extra={<> <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button> </>  }
        extra={assetsData.length > 0 ? (
          <>
            <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
          </>
        ) : (<></>)}
      >
       <Form form={form}
          layout='vertical'>
          <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 3 }} >
              <Form.Item label='Asset Mode' name='assetMode'>
                <Select
                  showSearch
                  defaultValue={'OwnedAsset'}
                  allowClear
                //onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='OwnedAsset' value='OwnedAsset'>OwnedAsset </Option>
                  <Option key='RentedAsset' value='RentedAsset'>RentedAsset</Option>
                </Select>
              </Form.Item>
        </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 5 }} >

              <Form.Item name="assetCode"
                label="Asset Code">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetsData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
                  })

                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Asset Category' name='assetCategory' >
                <Select
                  // defaultValue={'All'}
                  placeholder='All Categories'
                  showSearch
                  //onChange={getItems}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear>
                  <Option key={'all'} value={''}>All Categories</Option>
                  {itemCategory.map((items) => {
                    return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Unit' name='plantId' 
              // initialValue={Number(localStorage.getItem('unit_id'))}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                  placeholder='All Units'
                  showSearch
                  allowClear >
                  <Option key={'all'} value={''}>All Units</Option>
                  {plantName.map((plant) => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Location' name='location'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  // defaultValue={'All'}
                  placeholder='All Locations'
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All Locations</Option>
                  {locationData.map((location) => {
                    return (
                      <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Department' name='department'>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  // defaultValue={'All'}
                  placeholder='All Departments'
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All Departments</Option>
                  {depart.map((e) => {
                    return <Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Employee Name - Code' name='employeeId' >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  // defaultValue={'All'}
                  placeholder='All Employees'
                  showSearch
                  allowClear>
                  <Option key={'all'} value={''}>All Employees</Option>
                  {employeeData.map((employee) => {
                    return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          
            <Col span={3}>
              <Form.Item label='Asset Type' name='assetType'>
                <Select
                  placeholder='assetType'
                  showSearch
                  defaultValue={'MOVABLE'}
                  //onChange={onStatusSearch}
                  allowClear
                >
                  <Option key='all' value=''>All</Option>
                  {/* <Option key='movable' value='movable'>Movable</Option>
                  <Option key='immovable' value='immovable'>Immovable </Option> */}
                   {Object.values(AssetTypeEnum).map((i) => {
                              return (
                                <Option key={i} value={i}>
                                  {i}
                                </Option>
                              );
                            })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>

              <Form.Item label='Status' name='status'>

                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                //onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='INSTOCK' value='INSTOCK'>INSTOCK </Option>
                  <Option key='INUSE' value='INUSE'>INUSE</Option>
                  <Option key='SERVICE' value='SERVICE'>SERVICE</Option>
              <Option key='INTRANSIT' value='INTRANSIT'>INTRANSIT</Option>
                  <Option key='DAMAGED' value='DAMAGED'>DAMAGED</Option>
                  <Option key='UNDERMAINTENANCE' value='UNDERMAINTENANCE'>UNDER MAINTENANCE</Option>
                  <Option key='RETIRED' value='RETIRED'>RETIRED</Option>
                  <Option key='SOLD' value='SOLD'>SOLD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>

              <Form.Item label='AMC Available' name='amcAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                // onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='yes' value='yes'>Yes</Option>
                  <Option key='no' value='no'>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} >

              <Form.Item label='Warranty Available' name='warrentyAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                // onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='yes' value='yes'>Yes</Option>
                  <Option key='no' value='no'>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} >

              <Form.Item label='Insurance Available' name='insuranceAvailable'>
                <Select
                  showSearch
                  defaultValue={'All'}
                  allowClear
                //onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='yes' value='yes'>Yes</Option>
                  <Option key='no' value='no'>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Asset Cost' name='assetCost'>
                <Select
                  showSearch
                  defaultValue={'All'}
                // onChange={onStatusSearch}
                >
                  <Option key='all' value=''>All</Option>
                  <Option key='0-25,000' value='0-25,000'>0-25,000</Option>
                  <Option key='25,000-50,000' value='25,000-50,000'>25,000-50,000</Option>
                  <Option key='50,000-1 lakh' value='50,000-1 lakh'>50,000-1 lakh</Option>
                  <Option key='above 1 lakh' value='above 1 lakh'>above 1 lakh</Option>

                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={5}>
              <Form.Item label='Purchase Date' name='date'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                // onChange={EstimatedETDDate}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Warranty' name='warrantyDate'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label='Insurance' name='insuranceDate'>
                <RangePicker
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Asset Age' name='assetAge'>
                <Select defaultValue={'All'}>
                <Option key='All' value=''>All</Option>
              <Option key='0-50' value='0-50'>Below 50 days</Option>
              <Option key='0-90' value='0-90'>Below 3 months</Option>
              <Option key='0-180' value='0-180'>Below 6 months</Option>
              <Option key='0-365' value='0-365'>Below 1 year</Option>
              <Option key='0-1095' value='0-1095'>Below 3 years</Option>
              <Option key='0-1825' value='0-1825'>Below 5 years</Option>
              <Option key='above 1825' value='above 1825'>Above 5 years</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ marginTop: "2.3%" }} >
              <Button type='primary' onClick={onStatusSearch}>Submit</Button>
            </Col>
            <Col style={{ marginTop: "2.3%" }}>
              <Button onClick={onReset}>Reset</Button>
            </Col>
            {/* <Col>
              <Link to='/items-view' ><span><Button className='panel_button'>Asset Update</Button></span></Link>
            </Col> */}
          </Row>
        </Form>
        {/* <br/>
        <Row gutter={24}>
         <Col>
          // <Card title={'IN-STOCK: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.INSTOCK).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'green'}}/>
          </Col>
          <Col>
          <Card title={'CHECK-IN: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.CHECKIN).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'orange'}}/>
          </Col>
          <Col >
          <Card title={'UNDERMAINTENANCE: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.UNDERMAINTENANCE).length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'yellow'}}/>
          </Col>
          <Col>
          <Card title={'DAMAGED: ' + assetsData.filter(e1 => e1.status == AssetStatusEnum.DAMAGED).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'red'}}/>

          </Col>
        </Row>
        <br></br> */}
        <Row gutter={[16,16]}>
        <Col span={2} style={{paddingBottom:'1px'}}>
            <Button ><b>INSTOCK:{assetsData.filter(e => e.status == AssetStatusEnum.INSTOCK).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'30px'}}>
            <Button ><b>INUSE:{assetsData.filter(e => e.status == AssetStatusEnum.INUSE).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'1%'}}>
            <Button ><b>MAINTENANCE:{assetsData.filter(e => e.status == AssetStatusEnum.UNDERMAINTENANCE).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'70px'}}>
            <Button ><b>SERVICE:{assetsData.filter(e => e.status == AssetStatusEnum.SERVICE).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'1%'}}>
            <Button ><b>INTRANSIT:{assetsData.filter(e => e.status == AssetStatusEnum.INTRANSIT).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'30px'}}>
            <Button ><b>DAMAGED:{assetsData.filter(e => e.status == AssetStatusEnum.DAMAGED).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'30px'}}>
            <Button ><b>RETIRED:{assetsData.filter(e => e.status == AssetStatusEnum.RETIRED).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'1px',marginLeft:'1%'}}>
            <Button ><b>SOLD:{assetsData.filter(e => e.status == AssetStatusEnum.SOLD).length}</b>
            </Button>
        </Col>
        <Col span={2} style={{paddingBottom:'2px'}}>
            <Button ><b>TOTAL:{length}</b>
            </Button>
        </Col>
        </Row>
        {assetsData.length > 0 ? (<>  <Table 
          columns={columns} 
          dataSource={assetsData} 
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x: 2500, y: 500 }}
          onChange={onChange}
        /></>):(
          <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
        )}
       
          </Card>
          </>
  );
}

export default AssetsReport;
