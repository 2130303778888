import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { CertificationDto } from "./certification-dto";

export class AllCertificationsResponse extends GlobalResponseObject{
    data?: CertificationDto[];

    /**
     * 
     * @param status
     * @param intlCode
     * @param internalMessage
     * @param data
     */

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CertificationDto[]){
        super(status, intlCode, internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}