import axios from "axios";
import { ReceiveSubplantStockDto,AllReceiveSubplantStockResponseModel,ReceiveSubplantStockReponseModel } from '@gtpl/shared-models/production-management';
import { ShiftsEnum,OperationTypeEnum,JobCompletedEnum } from "@gtpl/shared-models/common-models";
import connection from './connection';

export class ReceiveSubplantStockService{
    URL = connection.DEPLOY_URL + '/receive-subplant-stock';

    async getAllReceiveSubplantStock():Promise<AllReceiveSubplantStockResponseModel>{
        return await axios.post(this.URL + './getAllReceiveSubplantStock')
            .then(res =>{
                return res.data;
            })
        // const data = new ReceiveSubplantStockDto(1,OperationTypeEnum.BE_HEADING,"jobNo","so number",23,11,11,JobCompletedEnum.NO,true,"me");
        // const data1 = new ReceiveSubplantStockDto(1,OperationTypeEnum.BE_HEADING,"jobNo1","so number1",231,111,111,JobCompletedEnum.YES,true,"me");
        // const data2 = new ReceiveSubplantStockDto(1,OperationTypeEnum.VALUE_ADDITION,"jobNo2","so number2",223,121,151,JobCompletedEnum.NO,false,"me");
        // const data3 = new ReceiveSubplantStockDto(1,OperationTypeEnum.VALUE_ADDITION,"jobNo3","so number3",2231,1211,1,JobCompletedEnum.YES,false,"me");
        // return new AllReceiveSubplantStockResponseModel(true,1,"",[data,data1,data3,data2]);
    }
    async  jobCompletedStatus(soakingInfo: ReceiveSubplantStockDto): Promise<ReceiveSubplantStockReponseModel> {
        console.log(soakingInfo);
        return await axios.post(this.URL + '/jobCompletedStatus', soakingInfo)
                .then(res => {
                    return res.data
                })
    }

}