import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GrnDetails } from './rm-grn-details';

export class RmGrnResponseModel extends GlobalResponseObject{
    data?: GrnDetails;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //GrnDetails
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: GrnDetails) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
