import axios from 'axios';
import connection from './connection';
import {RmPriceListDto, RmPriceListResponse, AllRmPriceListResponse} from'@gtpl/shared-models/masters';


export class RmPriceListService{
    URL = connection.DEPLOY_URL + '/rm-price-list';

    async create (dto:RmPriceListDto): Promise<RmPriceListResponse>{
        return await axios.post(this.URL +'/createRmPriceList', dto)
        .then(res=>{
            return res.data
        })
    }

    async update(dto: RmPriceListDto): Promise<RmPriceListResponse> {
        return await axios.post(this.URL + '/updateRmPriceList', dto)
            .then(res => {
                return res.data
            })
    }

    async activatedeActivate(Dto: RmPriceListDto): Promise<RmPriceListResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateRmPriceList', Dto)
            .then(res => {
                return res.data
            })
    }

    async getAll(): Promise<AllRmPriceListResponse> {
        return await axios.post(this.URL + '/getAllRmPriceList')
            .then(res => {
                return res.data
            })
    }

}