
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GrnData } from '@gtpl/shared-models/raw-material-procurement';

export class GrnAbstractResponse extends GlobalResponseObject {
    data?: GrnData[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: GrnData[]) {
        super(status, errorCode, internalMessage);
        this.data= data;
    }
}