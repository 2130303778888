import { ZoneDto } from '../zone_master';

export class RackDto {
  rackId?: number;
  zoneId: number;
  zoneName:string;
  rackName: string;
  rackCode: string;
  reservedForDummy: number;
  createdUser: string;
  updatedUser: string;
  isActive: boolean;
  versionFlag: number;
  racksinfo?: ZoneDto;
  plantId?: number;
  plantName?:string;

  /**
   *
   * @param rackId
   * @param zoneId
   * @param rackName
   * @param createdUser
   * @param isActive
   */
  constructor(
    rackId: number,
    rackName: string,
    rackCode: string,
    reservedForDummy: number,
    zoneId: number,
    remarks: string,
    createdUser: string,
    isActive: boolean,
    versionFlag: number,
    racksinfo?: ZoneDto,
    plantId?: number,
    plantName?:string

  ) {
    this.rackId = rackId;
    this.rackName = rackName;
    this.rackCode = rackCode;
    this.reservedForDummy = reservedForDummy;
    this.zoneId = zoneId;
    this.createdUser = createdUser;
    this.isActive = isActive;
    this.racksinfo = racksinfo;
    this.versionFlag = versionFlag;
    this.plantId= plantId;
    this.plantName= plantName;
  }
}
