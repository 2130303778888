import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Input, Button, Row, Col, Form, Select, Spin, DatePicker } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { StockService } from '@gtpl/shared-services/procurement';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf';
import { PackingBomRequest } from '@gtpl/shared-models/procurement-management';

export interface PackingBomReportProps { }

export function PackingBomReport(
  props: PackingBomReportProps
) {
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [disable, setDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const service = new StockService();
  const [reportData, setReportData] = useState<any[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [selectedunit, setUnit] = useState<number>(0)
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService();
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const services = new SaleOrderService;
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [empty, setEmpty] = useState<boolean>()
  const [soDropBom, setSoDropBom] = useState<any[]>([]);
  const [showAbstract, setshowAbstract] = useState(false);
  const [prosUnit,setProsUnit]=useState<any[]>([]);
  useEffect(() => {
    getProsUintDropForSoReport();
    getSoDropForBomReport();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
    // setEmpty(false);

  }, [])


  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const getSoDropForBomReport = () => {
    service.getSoDropForBomReport({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
      if (res.status) {
        setSoDropBom(res.data);
      } else {
        if (res.intlCode) {
          setSoDropBom([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoDropBom([]);
    })
  }

  const getProsUintDropForSoReport = () => {
    service.getProsUintDropForSoReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setProsUnit(res.data);
      } else {
        setProsUnit([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProsUnit([]);
    })
  }
  const getPackingBomReport = (onReset?: boolean) => {
    setDisable(true);
    setLoading(true);
    const req = new PackingBomRequest()
    let saleOrderId = form.getFieldValue('saleOrderId');
    let selectedData = filtersData;
    req.fromDate = onReset ? undefined : selectedEstimatedFromDate;
    req.toDate = onReset ? undefined : selectedEstimatedToDate;
    req.saleOrderId = saleOrderId;
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    service.getPackingBomReport(req).then(res => {
      setDisable(false)
      if (res.status) {
        setReportData(res.data)
        setFiltersData(res.data);
        setLoading(false);
        setshowAbstract(true);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setReportData([]);
          setshowAbstract(true);
          //AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setLoading(false);
        }
      }
    }).catch((err) => {
      setReportData([]);
      AlertMessages.getErrorMessage(err.message);
    })
    setReportData(selectedData);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('packingBomReport')
      .addColumns(data)
      .addDataSource(reportData, { str2num: true })
      .saveAs('packing-bom.xlsx');
  }
  const data = [
    { title: "Customer PO", dataIndex: "poNum" },
    { title: "Unit", dataIndex: "unit" },
    { title: "ETD", dataIndex: "etd", render: (value, record) => { return <span>{record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}</span> } },
    { title: "Target Days", dataIndex: "targetDays" },
    { title: "So Type", dataIndex: "soType" },
    { title: "PM Status", dataIndex: "pmStatus" },
    { title: "Closed Items", dataIndex: "closedItems" },
    { title: "Pending Items", dataIndex: "pendingItems" },
    { title: "Max Date", dataIndex: "maxDate" },
  ];
  const exportToPdf = () => {
    var columns = [
      { title: "Customer PO", dataKey: "poNum" },
      { title: "Unit", dataKey: "unit" },
      { title: "ETD", dataKey: "etd", render: (value, record) => { return <span>{record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}</span> } },
      { title: "Target Days", dataKey: "targetDays" },
      { title: "So Type", dataKey: "soType" },
      { title: "PM Status", dataKey: "pmStatus" },
      { title: "Closed Items", dataKey: "closedItems" },
      { title: "Pending Items", dataKey: "pendingItems" },
      { title: "Max Date", dataKey: "maxDate", render: (value, record) => { return <span> {record.maxDate ? moment(record.maxDate).format('YYYY-MM-DD') : '-'}</span> } },
    ];
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, reportData, {
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      addPageContent: function (data) {
        doc.text("PACKING BOM", 50, 15);
      }
    });
    doc.save('packing-bom-report.pdf')
  }
  const columns: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      width: '70px',
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNum',
      width: '110px',
      // sorter: (a, b) => a.poNum.length - b.poNum.length || a.poNum.localeCompare(b.poNum),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('poNum'),
      render: (value, record) => {
        return record.poNum ? record.poNum : '-';
      }
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: '120px',
      // ...getColumnSearchProps('unit'),
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: '150px',
      // sorter: (a, b) => a.etd?.localeCompare(b.etd),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.etd ? moment(record.etd).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },

    {
      title: 'Target Days',
      dataIndex: 'targetDays',
      width: '150px',
      // sorter: (a, b) => a.targetDays?.localeCompare(b.targetDays),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'So Type',
      dataIndex: 'soType',
      width: '150px',
      filters: [
        {
          text: 'Dispatch',
          value: 'Dispatch',
        },

        {
          text: 'Stock',
          value: 'Stock',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.soType === value;
      },
    },
    {
      title: 'PM Status',
      dataIndex: 'pmStatus',
      width: '150px',
      filters: [
        { text: 'OPEN', value: 'OPEN' },
        { text: 'IN_PROGRESS', value: 'IN_PROGRESS' },
        { text: 'CLOSE', value: 'CLOSE' },
        { text: 'PARTIAL_PO', value: 'PARTIAL_PO' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.pmStatus === value;
      },

    },
    // {
    //   title: 'Count',
    //   dataIndex: 'count',
    //   width: '150px',
    //   sorter: (a, b) => a.count?.localeCompare(b.count),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Closed Items',
      dataIndex: 'closedItems',
      width: '150px',
      // sorter: (a, b) => a.closedItems?.localeCompare(b.closedItems),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Pending Items',
      dataIndex: 'pendingItems',
      width: '150px',
      // sorter: (a, b) => a.pendingItems?.localeCompare(b.pendingItems),
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Max Date',
      dataIndex: 'maxDate',
      width: '150px',
      // sorter: (a, b) => a.maxDate?.localeCompare(b.maxDate),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.maxDate ? moment(record.maxDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
  ];
  const handleUnit = (value) => {
    setUnit(value)
  }
  const EstimatedETDDate = (value) => {
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
    }
  }
  const onReset = () => {
    form.resetFields();
    setSelectedEstimatedFromDate(undefined);
    setSelectedEstimatedToDate(undefined);
    setReportData([]);
    setUnit(0); setSoItemData([]);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }
  return (
    <Card
      title={<span style={{ color: 'white' }}>Packing Bom Report</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Form layout={"vertical"} form={form} >
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="fromDate"
              label="ETD"
              initialValue={undefined}
              rules={[
                {
                  required: false,
                  message: " ETD Date"
                },
              ]}>
              <RangePicker onChange={EstimatedETDDate} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item
              name="procUnit"
              label="Mfg Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
              >
                {prosUnit.map(dropData => {
                  return <Option value={dropData.procUnit}>{dropData.unitName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
            <Form.Item name="saleOrderId"
              label="Customer PO"
              rules=
              {[{ required: false, message: ' Select ' },]}>
              <Select showSearch placeholder="Select Customer Po"
                optionFilterProp="children"
                allowClear
              //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

              >
                <Option key={0} value={null}>Select Sale Order</Option>
                {soDropBom.map((data) => {
                  return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNum}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getPackingBomReport()}>
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>

        </Row>
      </Form>
      {showAbstract ? <>< Row gutter={28} >
        <Col>
          <Button icon={<DownloadOutlined />} style={{ marginTop: '30px', }} onClick={() => { exportExcel(); }}>
            Get Excel
          </Button>
          <Button icon={<FilePdfOutlined />} style={{ marginLeft: 5, marginTop: '30px', }} onClick={() => { exportToPdf(); }}>
            Get PDF
          </Button>
        </Col>
      </Row >
      <Table
        columns={columns}
        dataSource={reportData}
        scroll={{ x: 1500, y: 500 }}
        bordered
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
      />
      </> : <></>}
    </Card>
  )
}
export default PackingBomReport;