import { ApiProperty } from "@nestjs/swagger";
import { IsAlphanumeric, IsNotEmpty, IsNumber, IsOptional } from "class-validator";

export class CodeListMainIdRequest {
   
    saleOrderId: number;
    updatedUser?: string;
    versionFlag?: number;
    isActive?: boolean;
    poNumber?:string;
}