export class SaleOrderItemInfo{
    saleOrderNo:string;
    variantId:number;
    edaDate:string;
    edlDate:string;
    soakingStyle:string;
    soakingTime:string;
    variantCode:string;
    endCustomerId?:number;
    endCustomer?:string;
    product?:string;
    productId?:number;
    soId?:number;
    brand?:string;
    minGrade?:number;
    maxGrade?:number;
    pouches?:number;
    pouchWeight?:number;
    poNumber?:string;


    /**
     * 
     * @param saleOrderNo 
     * @param variantId 
     * @param edaDate 
     * @param edlDate 
     * @param soakingStyle 
     * @param soakingTime 
     * @param endCustomerId 
     * @param endCustomer 
     */
    constructor(saleOrderNo:string, variantId:number, edaDate:string, edlDate:string, soakingStyle:string, soakingTime:string, variantCode:string, endCustomerId?:number, endCustomer?:string, product?:string,soId?:number,brand?:string,minGrade?:number,maxGrade?:number,pouches?:number,pouchWeight?:number,poNumber?:string){
        this.saleOrderNo = saleOrderNo;
        this.variantId = variantId;
        this.edaDate = edaDate;
        this.edlDate = edlDate;
        this.soakingStyle = soakingStyle;
        this.soakingTime = soakingTime;
        this.variantCode = variantCode;
        this.endCustomerId = endCustomerId;
        this.endCustomer = endCustomer;
        this.product = product;
        this.soId = soId;
        this.brand = brand;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.pouches = pouches;
        this.pouchWeight = pouchWeight;
        this.poNumber = poNumber;
    }
}