import connection from './connection';
import { AssigningStockResponse, CommonResponse, FGStockData, GetAllRepackingStockResponseModel, GetAllStockResponseModel, GetStockResponseModel, InputIdRequest, RepackingDtoRequest, ReProcessingDtoReq, StockDTO, StockInResponseModel, StockOutResponse, StockQtyResponseModel, StockSoRequest, StockStatusRequest, SuggestPalletRequest, SoPlantRequest, StockResponseDto, ReprocessingInputRequest, PlantIdRequest, StockTransferModel, BulkStockOutRequest, FgStockReportResponse, StockOutTransactionRequest, SoIdRequest, ShipmentStatusRequest, DPReportRequest, AssignRepackingRequest, WhatsAppBroadCastRequest, AssignSuperDummyRepackingRequest, StockInReverseReq, AllocationRevereseRequest, LocationUpdateRequest } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import { PalletsResponseModel, RackPostionReq, RackZoneLevelRequest } from '@gtpl/shared-models/masters';
import { SaleOrderItemsBySoIdReaponseModel } from '@gtpl/shared-models/sale-management';
import { SaleOrderItemsRequest } from 'libs/shared-services/sale-management/src/lib/saleorder-model';
import { StockInTransactionsRequest, UnitRequest } from '@gtpl/shared-models/common-models';
import { LotNumberRequest } from '@gtpl/shared-models/production-management';
import { FgSaleReportRequest } from './fg-sale-report.request';
import { SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';
import { Request } from '@nestjs/common';
import appSettings from 'apps/services/config';

export class FGStockService {
    URL = connection.DEPLOY_URL + '/stock';

    async getStockBySoItemId(suggestPalletRequest: SuggestPalletRequest): Promise<GetStockResponseModel> {
        return await axios.post(this.URL + '/getStockBySoItemId', suggestPalletRequest)
            .then(res => {
                return res.data
            })
    }
    async getStockBySoId(stockSoRequest: StockSoRequest): Promise<GetAllStockResponseModel> {
        return await axios.post(this.URL + '/getStockBySoId', stockSoRequest)
            .then(res => {
                return res.data
            })
    }
    async createStock(stockDTO: StockDTO): Promise<GetStockResponseModel> {
        return await axios.post(this.URL + '/createStock', stockDTO)
            .then(res => {
                return res.data
            })
    }
    async createReportedStock(stockDTO: StockDTO): Promise<GetStockResponseModel> {
        return await axios.post(this.URL + '/createReportedStock', stockDTO)
            .then(res => {
                return res.data
            })
    }
    async updateStock(stockDTO: StockDTO): Promise<GetStockResponseModel> {
        return await axios.post(this.URL + '/updateStock', stockDTO)
            .then(res => {
                return res.data
            })
    }

    async getAllStockData(): Promise<GetAllStockResponseModel> {
        return await axios.post(this.URL + '/getAllStockData')
            .then(res => {
                return res.data
            })
    }

    async getReProcessingStock(req: ReProcessingDtoReq): Promise<AssigningStockResponse> {
        return await axios.post(this.URL + '/getReProcessingStock', req)
            .then(res => {
                return res.data
            })
    }

    async getRePackingStock(req: RepackingDtoRequest): Promise<AssigningStockResponse> {
        return await axios.post(this.URL + '/getRePackingStock', req)
            .then(res => {
                return res.data
            })
    }

    async getAllAssignableReProcessingStock(req: ReProcessingDtoReq): Promise<GetAllRepackingStockResponseModel> {
        return await axios.post(this.URL + '/getAllAssignableReProcessingStock', req)
            .then(res => {
                return res.data
            })
    }

    async getAllAssignableRePackingStock(req: RepackingDtoRequest): Promise<GetAllRepackingStockResponseModel> {
        return await axios.post(this.URL + '/getAllAssignableRePackingStock', req)
            .then(res => {
                return res.data
            })
    }

    async AssignReprocessQty(req: ReprocessingInputRequest) {
        return await axios.post(this.URL + '/AssignReprocessQty', req)
            .then(res => {
                return res.data
            }).catch((error) => {
                console.log(error);
            })
    }

    async AssignQty(req: FGStockData) {
        return await axios.post(this.URL + '/AssignQty', req)
            .then(res => {
                return res.data
            }).catch((error) => {
                console.log(error);
            })
    }

    async AssignRepackingQty(req: ReprocessingInputRequest) {
        return await axios.post(this.URL + '/AssignRepackingQty', req)
            .then(res => {
                return res.data
            }).catch((error) => {
                console.log(error);
            })
    }
    async getLotWiseReportedQuantity() {
        return await axios.post(this.URL + '/getLotWiseReportedQuantity')
            .then(res => {
                return res.data
            }).catch((error) => {
                console.log(error);
            })
    }

    async productDetailsForPalletId(req: InputIdRequest): Promise<SaleOrderItemsBySoIdReaponseModel> {
        return await axios.post(this.URL + '/productDetailsForPalletId', req)
            .then(res => {
                return res.data
            })
    }

    async getPalletDetailsForRackPosition(req: InputIdRequest): Promise<PalletsResponseModel> {
        return await axios.post(this.URL + '/getPalletDetailsForRackPosition', req)
            .then(res => {
                return res.data
            })
    }
    async getStockQtyForSo(saleOrderIdRequest: StockSoRequest): Promise<StockQtyResponseModel> {
        return await axios.post(this.URL + '/getStockQtyForSo', saleOrderIdRequest)
            .then(res => {
                return res.data
            })
    }
    async getStockQtyForSoAndSoItem(saleOrderIdRequest: SaleOrderItemsRequest): Promise<StockQtyResponseModel> {
        return await axios.post(this.URL + '/getStockQtyForSoAndSoItem', saleOrderIdRequest)
            .then(res => {
                return res.data
            })
    }

    async getStockInData(plantIdReq: SoPlantRequest): Promise<StockOutResponse> {
        return await axios.post(this.URL + '/getStockInData', plantIdReq)
            .then(res => {
                return res.data
            })
    }

    async updateStockOutStatus(stockStatusReq: BulkStockOutRequest[]): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateStockOutStatus', stockStatusReq)
            .then(res => {
                return res.data
            })
    }

    async getStockInSaleOrders(plantIdReq?: InputIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockInSaleOrders', plantIdReq)
            .then(res => {
                return res.data
            })
    }
    async getStockInSaleOrdersDropdown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockInSaleOrdersDropdown')
            .then(res => {
                return res.data
            })
    }
    async getStockForPlant(req: PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockForPlant', req)
            .then(res => {
                return res.data
            })
    }


    async getStockSummaryReportData(req: RackZoneLevelRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockSummaryReportData', req)
            .then(res => {
                return res.data
            })
    }
    async getTransferStock(req: StockTransferModel): Promise<CommonResponse> {
        console.log(req);
        return await axios.post(this.URL + '/getTransferStock', req)
            .then(res => {
                return res.data
            })
    }

    async getAllFgStockReport(req: FgSaleReportRequest): Promise<FgStockReportResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllFgStockReport', req)
            .then(res => {
                return res.data
            })
    }
    async getPoNoForFgStockReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getPoNoForFgStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getBrandDropForFgStockReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getBrandDropForFgStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getCategoryDropForFgStockReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getCategoryDropForFgStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getStockOutTransaction(req?: StockOutTransactionRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getStockOutTransaction', req)
            .then(res => {
                return res.data
            })
    }

    async getStockInTransactions(req?: StockInTransactionsRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getStockInTransactions', req)
            .then(res => {
                return res.data
            })
    }

    async getAllStockOutSaleOrders(req?: UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllStockOutSaleOrders', req)
            .then(res => {
                return res.data
            })
    }
    async getStockInReverseTransactions(req?: PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getStockInReverseTransactions', req)
            .then(res => {
                return res.data
            })
    }
    async moveLeftOverQtytoDummy(req?: SoIdRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/moveLeftOverQtytoDummy', req)
            .then(res => {
                return res.data
            })
    }
    async getUnitDropdownForStockInReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnitDropdownForStockInReport')
            .then(res => {
                return res.data
            })
    }

    async getShipmentStatusReport(req?: ShipmentStatusRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getShipmentStatusReport', req)
            .then(res => {
                return res.data
            })
    }
    async getSoDropForShipmentStatusReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForShipmentStatusReport', req)
            .then(res => {
                return res.data
            })
    }

    async getStockDetails(req?: RackPostionReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockDetails', req)
            .then(res => {
                return res.data
            })
    }

    async getDummyPackingReport(req?: DPReportRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDummyPackingReport', req)
            .then(res => {
                return res.data
            })
    }

    async getBrandDropForDummyPackingReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getBrandDropForDummyPackingReport', req)
            .then(res => {
                return res.data
            })
    }

    async getVariantDropForDummyPackingReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getVariantDropForDummyPackingReport', req)
            .then(res => {
                return res.data
            })
    }

    async getbrandDropForShipmentStatusReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getbrandDropForShipmentStatusReport', req)
            .then(res => {
                return res.data
            })
    }

    async getVariantDropdown(req?: RackZoneLevelRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getVariantDropdown', req)
            .then(res => {
                return res.data
            })
    }

    async getValueAdditionsFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getValueAdditionsFiltersData')
            .then(res => {
                return res.data
            })
    }

    async getValueAdditionsTwoFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getValueAdditionsTwoFiltersData')
            .then(res => {
                return res.data
            })
    }

    async getValueAdditionsThreeFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getValueAdditionsThreeFiltersData')
            .then(res => {
                return res.data
            })
    }

    async getValueAdditionsfourFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getValueAdditionsfourFiltersData')
            .then(res => {
                return res.data
            })
    }

    async getValueAdditionsfiveFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getValueAdditionsfiveFiltersData')
            .then(res => {
                return res.data
            })
    }

    async getFoodTypeFiltersData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getFoodTypeFiltersData')
            .then(res => {
                return res.data
            })
    }
    async getDummyStock(req:PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDummyStock', req)
            .then(res => {
                return res.data
            })
    }
    async getSaleOrderToAssignRepacking(req:AssignRepackingRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSaleOrderToAssignRepacking', req)
            .then(res => {
                return res.data
            })
    }
    async generatePickList(req:SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/generatePickList', req)
        .then(res => {
            return res.data
        })
    }
    async getSaleOrderToAssignReprocessing(req:AssignRepackingRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSaleOrderToAssignReprocessing', req)
            .then(res => {
                return res.data
            })
    }
    async getCheckPickListGeneratedORNot(req:SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getCheckPickListGeneratedORNot', req)
            .then(res => {
                return res.data
            })
    }
    async getPOsInStock(req:SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPOsInStock', req)
            .then(res => {
                return res.data
            })
        }
    async getSuperDummyStock(req:UnitRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSuperDummyStock', req)
        .then(res => {
            return res.data
        })
    }
    async getAssignSuperDummyToRepack(req:AssignSuperDummyRepackingRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getAssignSuperDummyToRepack', req)
        .then(res => {
            return res.data
        })
    }
    async getStockInReverseReportdata(req?: PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getStockInReverseReportdata', req)
            .then(res => {
                return res.data
            })
    }
    async getSaleOrderDropForStockInReverse(req?: StockInReverseReq): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getSaleOrderDropForStockInReverse', req)
            .then(res => {
                return res.data
            })
    }
    async getVariantDropForStockInReverse(req?: StockInReverseReq): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getVariantDropForStockInReverse', req)
            .then(res => {
                return res.data
            })
    }
    async getPlantDropForStockInReverse(req?: StockInReverseReq): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getPlantDropForStockInReverse', req)
            .then(res => {
                return res.data
            })
    }
    async getlocationsHistoryReportData(): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getlocationsHistoryReportData')
            .then(res => {
                return res.data
            })
    }
    async getAllocatedStock(req?:PlantIdRequest):Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllocatedStock',req)
        .then(res => {
            return res.data
        })
    }
    async getReverseAllocatedStock(req:AllocationRevereseRequest):Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getReverseAllocatedStock',req)
        .then(res => {
            return res.data
        })
    }
    async getUpdateStockLocation(req:LocationUpdateRequest):Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getUpdateStockLocation',req)
        .then(res => {
            return res.data
        })
    }
}
