import { GlobalResponseObject } from "../global-response-object";
import { SoLongRunDetails } from "./so-long-run-details";


export class SoLongRunResponse extends GlobalResponseObject {
    data?: SoLongRunDetails[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoLongRunDetails[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}