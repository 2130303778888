export class UpdateBomItemDto{
    packingMaterialId:number;
    itemCategoryId?:number;
    itemId:number;
    quantity:number;
    toBeProcured:number;

    constructor(packingMaterialId:number, itemId:number, quantity:number, toBeProcured:number,  itemCategoryId?:number){
        this.packingMaterialId = packingMaterialId;
        this.itemCategoryId = itemCategoryId;
        this.itemId = itemId;
        this.quantity = quantity;
        this.toBeProcured = toBeProcured;
    }
}