import { EnvVarEnum } from '../enum/environment-variables.enum';

// const envType = EnvVarEnum[process.env.NX_ENVIRONMENT.toLocaleUpperCase()];

export class UiConfig {
    // MDM= process.env[`NX_MDM_` + envType + `_UI_URL`];
    // PMS= process.env[`NX_PMS_` + envType + `_UI_URL`];
    // SMS= process.env[`NX_SMS_` + envType + `_UI_URL`];
    // PPS= process.env[`NX_PPS_` + envType + `_UI_URL`];
    // TMS= process.env[`NX_TMS_` + envType + `_UI_URL`];
    // PTS= process.env[`NX_PTS_` + envType + `_UI_URL`];
    // WMS= process.env[`NX_WMS_` + envType + `_UI_URL`];
    // INSPECTION= process.env[`NX_INSPECTION_` + envType + `_UI_URL`];
    // DASHBOARDS= process.env[`NX_DASHBOARDS_` + envType + `_UI_URL`];
    // ONEDOTFIVE= process.env[`NX_ONEDOTFIVE_` + envType + `_UI_URL`];
    // ODBC= process.env[`NX_ODBC_` + envType + `_UI_URL`];
}
