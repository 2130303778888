import { TransferStatusEnum } from "@gtpl/shared-models/common-models";

export class AssetTransfersDto {
    assetTransferId: number;
    assetLocationId : number;
    employeeId: number;
    assetsId: number;
    unitId: number;
    transferredDate: Date;
    receivedDate: Date;
    transferType: string;
    dcNumber: string;
    mrnNumber: string;
    fromPlant: number;
    toPlant: number;
    transferedQuantity: number;
    receivedQuantity: number;
    note: string;
    status : TransferStatusEnum;
    isActive: boolean;
    createdAt : Date;
    createdUser: string;
    updatedAt : Date;
    updatedUser: string;
    versionFlag: number;

    
    constructor(assetTransferId: number,assetLocationId : number,employeeId: number,assetsId: number,unitId: number,transferredDate: Date,receivedDate: Date,transferType: string,dcNumber: string,mrnNumber: string,fromPlant: number,toPlant: number,transferedQuantity: number,receivedQuantity: number,note: string,status : TransferStatusEnum,isActive: boolean,createdAt : Date,createdUser: string,updatedAt : Date,updatedUser: string,versionFlag: number){
        this.assetTransferId=  assetTransferId;
        this.assetLocationId = assetLocationId;
        this.employeeId=  employeeId;
        this.assetsId=  assetsId;
        this.unitId=  unitId;
        this.transferredDate=  transferredDate;
        this.receivedDate=  receivedDate;
        this.transferType=  transferType;
        this.dcNumber=  dcNumber;
        this.mrnNumber=  mrnNumber;
        this.fromPlant=  fromPlant;
        this.toPlant=  toPlant;
        this.transferedQuantity=  transferedQuantity;
        this.receivedQuantity=  receivedQuantity;
        this.note=  note;
        this.status = status;
        this.isActive=  isActive;
        this.createdAt = createdAt;
        this.createdUser=  createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser=  updatedUser;
        this.versionFlag=  versionFlag;
    }
}