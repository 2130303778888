export class DocumentsUploadRequest{
    documentsListId:number;
    saleOrderId:number;
    documentCategory:string;
    files:any[]
}

export class fileDto{
    file: any[]
}
