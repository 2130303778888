import * as React from "react";
import './traffic-lights.css';

const colors = {
  red: {
    backgroundColor: "#cc3232"
  },
  yellow: {
    backgroundColor: "#e7b416"
  },
  green: {
    backgroundColor: "#2dc937"
  },
  grey: {
    backgroundColor: "grey"
  }
};

class TrafficLight extends React.PureComponent {
  state = {
    red: colors.red,
    yellow: colors.grey,
    green: colors.grey,
    next: 'yellow'
  }

  handeLightChange = () => {
    switch (this.state.next) {
      case "red":
        this.setState({
          red: colors.grey,
          yellow: colors.grey,
          green: colors.green,
          next: 'green'
        });
        break;
      case "yellow":
        this.setState({
          red: colors.grey,
          yellow: colors.yellow,
          green: colors.grey,
          next: 'red'
        });
        break;
      case "green":
        this.setState({
          red: colors.red,
          yellow: colors.grey,
          green: colors.grey,
          next: 'yellow'
        });
        break;
    }
  }

  componentDidMount() {
    setInterval(() => { 
      this.handeLightChange();
    }, 2000)
  }

  render() {
    const { green, yellow, red } = this.state;
    const handeLightChange = () => {
      switch (this.state.next) {
        case "red":
          this.setState({
            red: colors.grey,
            yellow: colors.grey,
            green: colors.green,
            next: 'green'
          });
          break;
        case "yellow":
          this.setState({
            red: colors.grey,
            yellow: colors.yellow,
            green: colors.grey,
            next: 'red'
          });
          break;
        case "green":
          this.setState({
            red: colors.red,
            yellow: colors.grey,
            green: colors.grey,
            next: 'yellow'
          });
          break;
      }
    }
    return (
      <div className="container">
        <div className="circle red" style={this.state.red}></div>
        <div className="circle yellow" style={this.state.yellow}></div>
        <div className="circle green" style={this.state.green}></div>
      </div>
    );
  }
}

export default TrafficLight;
