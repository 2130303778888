import { IndentAgainistEnum } from "@gtpl/shared-models/common-models";
import { IndentFormItemsDto } from "./indent-items-dto";

export class indentformDto{
    indentMaterialPreparationId:number
    indentDate: any;
    employeeId: number;
    saleOrderItemId: number;
    indentAgainst:IndentAgainistEnum
    saleOrderId: number;
    unitId: number;
    createdAt: any;
    createdUser: string | null;
    // updatedAt: Date;
    // updatedUser: string | null;
    versionFlag: number;
    indentCode:string;
    indentItemDetails:IndentFormItemsDto[];
    isActive: boolean;
    constructor(
    indentMaterialPreparationId:number,
    indentDate: any,
    employeeId: number,
    indentAgainst:IndentAgainistEnum,
    saleOrderItemId: number,
    saleOrderId: number,
    unitId: number,
    createdAt: Date,
    createdUser: string,
    // updatedAt: Date,
    // updatedUser: string,
    versionFlag: number,
    indentCode:string,
    indentItemDetails:IndentFormItemsDto[],
    isActive: boolean,

    ){
      this.indentMaterialPreparationId=indentMaterialPreparationId;
      this.indentDate=indentDate;
      this.employeeId=employeeId;
      this.indentAgainst=indentAgainst;
      this.saleOrderItemId=saleOrderItemId;
      this.saleOrderId=saleOrderId;
      this.unitId=unitId;
      this.createdAt=createdAt;
      this.createdUser=createdUser;
      // this.updatedAt=updatedAt;
      // this.updatedUser=updatedUser;
      this.versionFlag=versionFlag;
      this.indentCode=indentCode;
      this.indentItemDetails=indentItemDetails;
      this.isActive=isActive;

    }
    

}