import axios from 'axios';
import { AllUnitcodeResponseModel, PlantIdRequest, PlantsDropDownResponse, UnitcodeDto, UnitcodeResponseModel } from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class UnitcodeService {
  URL = connection.DEPLOY_URL + '/unitcode';

  async createUnitcode(unitcode: UnitcodeDto): Promise<UnitcodeResponseModel> {
    console.log(unitcode);
    return await axios.post(this.URL + '/createUnitcode', unitcode)
      .then(res => {
        return res.data
      })
  }
  async updateUnitcode(department: UnitcodeDto): Promise<UnitcodeResponseModel> {
    return await axios.post(this.URL + '/updateUnitcode', department)
      .then(res => {
        return res.data
      })
  }
  async getAllUnitcode(req?:UserRequestDto): Promise<AllUnitcodeResponseModel> {
    return await axios.post(this.URL + '/getAllUnitcodes',req)
      .then(res => {
        return res.data
      })
  }
  async ActivateorDeactivateUnitcode(unitcodeDto: UnitcodeDto): Promise<AllUnitcodeResponseModel> {
    console.log(unitcodeDto);
    return await axios.post(this.URL + '/activateorDeactivateUnitcode', unitcodeDto)
      .then(res => {
        return res.data
      })
  }
  async getAllActiveUnitcode(): Promise<AllUnitcodeResponseModel> {
    return await axios.post(this.URL + '/getAllActiveUnitcodes')
      .then(res => {
        return res.data
      })
  }

  async getAllMainPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllMainPlants')
      .then(res => {
        return res.data
      })
  }
  async getAllMainAndSubContractPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllMainAndSubContractPlants')
      .then(res => {
        return res.data
      })
  }
  
  async getAllSubContractorPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllSubContractorPlants')
      .then(res => {
        return res.data
      })
  }

  
  async getAllMainAndHoPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllMainAndHoPlants')
      .then(res => {
        return res.data
      })
  }
  

  async getAllSubPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllSubPlants')
      .then(res => {
        return res.data
      })
  }
  async getPlantDetailsById(unitcodeReq: PlantIdRequest): Promise<UnitcodeResponseModel> {
    return await axios.post(this.URL + '/getPlantDetailsById',unitcodeReq)
      .then(res => {
        return res.data
      })
  }
  async getAllPlants(): Promise<PlantsDropDownResponse> {
    return await axios.post(this.URL + '/getAllPlants')
      .then(res => {
        return res.data
      })
  }
 
  // async getActiveUnitcodeCount(): Promise<DepartmentStatusWiseResponseModel> {
  //   return await axios.post(this.URL + '/getActiveDepartmentCount').then(res => {
  //       return res.data
  //   });
  // }

}