import { LotCodeIwAttributes } from "./lotCodeAttribute-i-w";
import { SubLotChild } from "./lotCodeSubChild";

export class LotGradeChildren{
    name: string;
    attribute:LotCodeIwAttributes;
    children:SubLotChild[]
    constructor(name: string, attribute:LotCodeIwAttributes, children: SubLotChild[]){
        this.name = name;
        this.attribute = attribute;
        this.children = children;
    }
}