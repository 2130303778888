
import connection from './connection';
import axios from 'axios';
import { CommonResponse, HrmsEmployeeDetailsDto } from '@gtpl/shared-models/masters';
import { EmpLeavesResponse, EmployeeLeavesDto, EmployeeLeavesResponse, GetAllAvailableLeavesResponseModel, GetEmpAvlLeaves, GetEmpLeaves } from '@gtpl/shared-models/hrms';

export class EmployeeLeavesService {

    URL = connection.DEPLOY_URL + '/employee-leaves';
    async createEmployeeLeaves(dto: any): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createEmployeeLeaves', dto)
            .then(res => {
                return res.data;
            })
    }

    async getAllEmployeeLeaves(dto: GetEmpLeaves): Promise<EmpLeavesResponse> {
        return await axios.post(this.URL + '/getAllEmployeeLeaves', dto)
            .then(res => {
                return res.data;
            })
    }

    async getAllAvailableLeaves(req: GetEmpAvlLeaves): Promise<GetAllAvailableLeavesResponseModel> {
        return await axios.post(this.URL + '/getAllAvailableLeaves', req)
            .then(res => {
                return res.data;
            })
    }

    async getLeavesData(): Promise<EmployeeLeavesResponse> {
        return await axios.post(this.URL + '/getLeavesData')
            .then(res => { return res.data });

    }
    
    async HolidayCalander(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/HolidayCalander')
            .then(res => { return res.data });

    }



}