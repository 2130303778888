import { JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class JobCompletedStatusForReceiveSubplantStock{
    receiveSubplantStockId:number;
    jobStatus?: JobCompletedEnum;
    
    constructor(receiveSubplantStockId:number,jobStatus?:JobCompletedEnum, assignFreezing?: JobCompletedEnum){
        this.receiveSubplantStockId = receiveSubplantStockId;
        this.jobStatus = jobStatus;
    }
}