import axios from 'axios';
import connection from './connection';
import {SkillsDto, AllSkillsResponseModel, SkillsResponseModel} from '@gtpl/shared-models/hrms';

export class SkillsService{

    URL = connection.DEPLOY_URL + '/skills';

    async createSkill(skillsdto: SkillsDto ): Promise<SkillsResponseModel> {
        console.log(skillsdto);
       
        return await axios.post(this.URL + '/createSkill',skillsdto)
            .then(res => {
                return res.data
            })
    }

    async getAllSkills(): Promise<AllSkillsResponseModel> {
                
        return await axios.post(this.URL + '/getAllSkills')
            .then(res => {
                return res.data 
            })     
    }

    async  updateSkill(skillsdto: SkillsDto): Promise<SkillsResponseModel> {
        return await axios.post(this.URL + '/updateSkill', skillsdto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateSkill(skillsDto: SkillsDto): Promise<SkillsResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateSkill', skillsDto)
                        .then(res => {
                                return res.data
                        })
                }
                
}