import React, { useEffect, useState } from 'react';
import {GrnReturnDto,GrnReturnIdRequest,GrnDetailsForReturn,GrnReturnResponseModel} from '@gtpl/shared-models/procurement-management';
import { StoreConsumptionItemsDto} from '@gtpl/shared-models/procurement-management';
import {  GrnIdRequest,GrnReturnAutoFillModel,GrnDetailViewModel,GrnItemsForReturn} from "@gtpl/shared-models/procurement-management";

import {GrnItemReturnService} from '@gtpl/shared-services/procurement';

import { Button, Card, Form, Row, Col, Select, Table, Space,Input, Layout, Descriptions } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

export interface GrnReturnDetailViewProps {
  grnReturnitems: GrnItemsForReturn
}
export function GrnReturnDetailView(props:GrnReturnDetailViewProps){
   
    const grnService = new GrnItemReturnService();
    const [grnReturnData, setGrnReturnData] = useState<GrnDetailsForReturn>();
    const [page, setPage] = React.useState(1);
    const [indexVal, setIndexVal] = useState(0);

    let location = useLocation();
    const grnReturnId: any = location.state;
    const {id} = useParams<any>()

    useEffect(() => {
      getGrnReturnDataById(id);
   }, [id]);
  
  
    const getGrnReturnDataById = (grnReturnId) => {
      const reqObj = new GrnReturnIdRequest();
      reqObj.grnReturnId = grnReturnId;
      grnService.getGrnReturnDataById(reqObj).then(res => {
        if (res.status) {
          setGrnReturnData(res.data);
          setIndexVal(indexVal+1);
        }
      })
    }
  
   //For Columns

  const getColumns = () => {
      const columns = [
        {
          title: 'Item Category',
          dataIndex: 'itemCategoryName.itemCategoryId',
          key: 'itemCategoryName.itemCategoryId',
          render: (value, record: GrnItemsForReturn, index) => {
            return (record.itemCategoryName) ? record.itemCategoryName : '';
    
          },
        },
        {
          title: 'Item Sub Category',
          dataIndex: 'itemSubcategory.itemSubCategoryId',
          key: 'itemSubcategory.itemCategoryId',
          render: (value, record: GrnItemsForReturn, index) => {
            return (record.itemSubCategoryName) ? record.itemSubCategoryName : '';
          },
        },
    
        {
          title: 'Item',
          dataIndex: 'itemName.itemId',
          key: 'itemName.itemId',
          render: (value, record: GrnItemsForReturn, index) => {
            return (record.itemName) ? record.itemName : '';
          },
        },
    
        {
          title: 'Size',
          dataIndex: 'sizeName.sizeId',
          key: 'sizeName.sizeId',
          render: (value, record: GrnItemsForReturn, index) => {
            return (record.sizeName) ? record.sizeName : '';
          },
        },
        {
          title: 'Tax %',
          dataIndex: 'taxPercentage',
          key: 'taxPercentage',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.taxPercentage) ? Number(record.taxPercentage) : 0}</div>;
          },
        },
        {
          title: 'Tax Amount',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.taxAmount) ? Number(record.taxAmount) : 0}</div>;
          },
        },
        {
          title: 'Discount %',
          dataIndex: 'discountPercentage',
          key: 'discountPercentage',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.discountPercentage) ? Number(record.discountPercentage) : 0}</div>;
          },
        },
        {
          title: 'Discount Amount',
          dataIndex: 'discountAmount',
          key: 'discountAmount',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.discountAmount) ? Number(record.discountAmount) : 0}</div>;
          },
        },
        {
          title: 'GRN Received Quantity',
          dataIndex: 'grnReceivedQty',
          key: 'grnReceivedQty',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.grnReceivedQty) ? Number(record.grnReceivedQty) : 0}</div>;
          },
        },
        {
          title: 'GRN Return Quantity',
          dataIndex: 'grnReturnQty',
          key: 'grnReturnQty',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.grnReturnQty) ? Number(record.grnReturnQty) : 0}</div>;
          },
        },
        {
          title: 'GRN Remaining Quantity',
          dataIndex: 'grnRemainingQty',
          key: 'grnRemainingQty',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.grnRemainingQty) ? Number(record.grnRemainingQty) : 0}</div>;
          },
        },
        {
          title: 'Available Quantity',
          dataIndex: 'availQty',
          key: 'availQty',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.availQty) ? Number(record.availQty) : 0}</div>;
          },
        },
        {
          title: 'Unit Price',
          dataIndex: 'unitPrice',
          key: 'unitPrice',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.unitPrice) ? Number(record.unitPrice) : 0}</div>;
          },
        },
        {
          title: 'GRN Total Return Price',
          dataIndex: 'grnTotalReturnPrice',
          key: 'grnTotalReturnPrice',
          render: (value, record: GrnItemsForReturn, index) => {
            return <div style={{ textAlign: 'end' }}>{(record.grnTotalReturnPrice) ? Number(record.grnTotalReturnPrice) : 0}</div>;
          },
        },
      ];
      return columns;
    }
    
    return (
      <>
  
      <Layout style={{padding:10, backgroundColor:'white' , border:10}}>
      <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>GRN Return Detail View</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} extra={<Button href="/#/grn-return-grid" style={{color:'black'}}>GRN Return Grid</Button> } >
          <br></br>
           <>
              
              {grnReturnData? <>
        <Descriptions column={3}>
        <Descriptions.Item label="Vendor Name">
            {grnReturnData.vendorName}
            </Descriptions.Item>
            <Descriptions.Item label="GRN Date">
            {moment(grnReturnData.grnDate).format('YYYY/MM/DD')}
            </Descriptions.Item>
            <Descriptions.Item label="GRN Number">
            {grnReturnData.grnNumber}
            </Descriptions.Item>
            <Descriptions.Item label="GRN Return Date">
            {moment(grnReturnData.grnReturnDate).format('YYYY/MM/DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Date">
            {moment(grnReturnData.invoiceDate).format('YYYY/MM/DD')}
            </Descriptions.Item>
          {/* </Descriptions>
          <Descriptions column={3}> */}
              
             
              <Descriptions.Item label="Total Cost">
              {grnReturnData?.grnItems.reduce((sum, data) => sum + data.grnTotalReturnPrice, 0)}
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
              {grnReturnData.remarks}
              </Descriptions.Item>
              
          </Descriptions>
          </>:""}
           <br></br></>
           <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>GRN Items</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>
                <Table
                // rowKey={record => record.Id}
                columns={grnReturnData?getColumns():null}
                dataSource={(grnReturnData)?grnReturnData.grnItems:[]}
                pagination={{ onChange(current) { setPage(current); } }}
                scroll={{ x: 500 }}
                size="small"
                bordered
              ></Table>
  
          </Card>
         
      </Card>
      
     </Layout>
  
      </>
    );
}


export default GrnReturnDetailView;