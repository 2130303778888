export class SoakingRequestDTO{
    soakingId:number;
    plantId:number;
    assertId:number;
    duration:number;
    StartTime:string;
    EndTime:string;
    date:Date;
    isActive:boolean;
    createdAt:Date;
    createdUser:string;
    updatedAt:Date;
    updatedUser:string;
    versionFlag:number;
    constructor(
        soakingId:number,plantId:number,assertId:number,duration:number,StartTime:string,EndTime:string,date:Date,isActive:boolean,createdAt:Date,createdUser:string,updatedAt:Date,updatedUser:string,versionFlag:number){
            this.soakingId = soakingId;
            this.plantId = plantId;
            this.assertId = assertId;
            this.duration = duration;
            this.StartTime = StartTime;
            this.EndTime = EndTime;
            this.date = date;
            this.isActive = isActive;
            this.createdAt = createdAt;
            this.createdUser = createdUser;
            this.updatedAt = updatedAt;
            this.updatedUser = updatedUser;
            this.versionFlag = versionFlag;    
    }
}