import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { WarehouseDashboard } from '.';
import { RackDashboard } from './rack.dsbd';

export class WarehouseDashboardResponseModel extends GlobalResponseObject {
    data?: WarehouseDashboard[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: WarehouseDashboard[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}