export class ProductConversionDTO {

    productConversionId:number;
    toSaleOrder:number;
    fromSaleOrder:number;
    quantity:number;
    lotCode:string;
    conversionReason:string;
    fromOperation:string;
    toOperation:string;
    remarks:string;
    plantId:number;
    isActive: boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number

    constructor(
        productConversionId:number,
        toSaleOrder:number,
        fromSaleOrder:number,
        quantity:number,
        lotCode:string,
        conversionReason:string,
        fromOperation:string,
        toOperation:string,
        remarks:string,
        plantId:number,
        isActive: boolean,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number
    ){
        this.productConversionId = productConversionId;
        this.toSaleOrder = toSaleOrder;
        this.fromSaleOrder = fromSaleOrder;
        this.quantity = quantity;
        this.lotCode = lotCode;
        this.conversionReason = conversionReason;
        this.fromOperation = fromOperation;
        this.toOperation = toOperation;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.plantId = plantId;
    }

}