export class PaymentModeDto {
    paymentModeId?: number;
    paymentMode: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
}

export const PaymentModeDtoDefault : PaymentModeDto = {
    paymentModeId: 0,
    paymentMode: "",
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1
};

