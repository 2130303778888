import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AssetSubCatDropDownDto } from './assets-sub-catagory-drop-down-dto';





export class AssetsSubCatDropDownResponseModel extends GlobalResponseObject {
    data?: AssetSubCatDropDownDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: AssetSubCatDropDownDto[]) {
        super(status, intlCode, internalMessage)
        this.data = data;
    }
}

