export class GrnRtRequest{
    fromDate?:Date;
    toDate?:Date;
    grnNumber?:number;
    saleOrderId?:number;
    poNumber?:number;
    vendorName?:number;
    invoiceNumber?:string;
    unitId?:number;
    itemSubCategoryId?:number;
    poType?:string;
    selectedCreatedFromDate?:Date;
    selectedCreatedToDate?:Date;
    createdDate?:Date;
    itemNameId?:number;
    excessPercent?:number;
}