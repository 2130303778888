import { GlobalResponseObject } from "../global-response-object";
import { SoProductCompleteNotShipped } from "./so-product-complete-not-shipped";


export class SoProductCompleteNotShippedResponse extends GlobalResponseObject {
    data?: SoProductCompleteNotShipped[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoProductCompleteNotShipped[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}