import { AssetStatusInfoModel } from "./asset-status-info.model";


export class AssetStatusTypeInfoModel {
  assetType: string;
  assetTypeInfo: AssetStatusInfoModel[];
  newAssetsCount: number;
  newAssetsValue: number;
  lastMonthAssetCount: number;
  lastMonthAssetValue: number;

  constructor(
    assetType: string,
    assetTypeInfo: AssetStatusInfoModel[],
    newAssetsCount: number,
    newAssetsValue: number,
    lastMonthAssetCount: number,
    lastMonthAssetValue: number
  ) {
    this.assetType = assetType;
    this.assetTypeInfo = assetTypeInfo;
    this.newAssetsCount = newAssetsCount;
    this.newAssetsValue = newAssetsValue;
    this.lastMonthAssetCount = lastMonthAssetCount;
    this.lastMonthAssetValue = lastMonthAssetValue;
  }
}
