import { ItemCategoryDropDownDto, ItemsDropDownDto } from "@gtpl/shared-models/masters";
import { SaleOrderDropDownDto, SaleOrderFewDetailsDto } from "@gtpl/shared-models/sale-management";

export class StoreReturnInformation {
    storeReturnId : number;
    storeReturnDate : Date;
    saleOrder:SaleOrderFewDetailsDto;
    itemCategoryDetails:ItemCategoryDropDownDto;
    itemDetails:ItemsDropDownDto;
    storeReturnNo: string;
    returnQuantity: number;
    remarks: string;
    unitId: number;
    poNumber?:string;
    saleOrderNo?:string;
    status?:string;
    itemSubCategoryId?:number;
    itemCtegory?:string;
    itemId?:number;
    itemName?:string;
    
    /**
     * 
     * @param storeReturnId 
     * @param storeReturnDate 
     * @param saleOrder 
     * @param itemCategoryDetails 
     * @param itemDetails 
     * @param storeReturnNo 
     * @param returnQuantity 
     * @param remarks 
     * @param unitId 
     * @param itemSubCategoryId 
     * @param itemCtegory 
     */
    constructor(storeReturnId:number,storeReturnDate : Date, saleOrder:SaleOrderFewDetailsDto, itemCategoryDetails:ItemCategoryDropDownDto, itemDetails:ItemsDropDownDto, storeReturnNo: string, returnQuantity: number, remarks: string,unitId:number ,itemSubCategoryId?: number ,itemCtegory?:string, itemId?:number,itemName?:string){
        this.storeReturnId  = storeReturnId;
        this.storeReturnDate  = storeReturnDate;
        this.saleOrder = saleOrder;
        this.itemCategoryDetails = itemCategoryDetails;
        this.itemDetails = itemDetails;
        this.storeReturnNo = storeReturnNo;
        this.returnQuantity = returnQuantity;
        this.remarks = remarks;
        this.unitId = unitId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemCtegory = itemCtegory;
        this.itemId = itemId;
        this.itemName = itemName;
    }
}