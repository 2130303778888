export class ReProcessRequiredQty {
    productId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    count?: number;

    /**
     * 
     * @param productId 
     * @param saleOrderId 
     * @param saleOrderItemId 
     * @param count 
     */
    constructor(productId: number, saleOrderId: number, saleOrderItemId: number, count?: number){
        this.productId = productId;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.count = count;
    }
}