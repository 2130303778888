export class SizesDropDownDto{
    sizeId : number;
    sizeName : string;
    itemSubCategoryId? : number;
    
    /**
     * 
     * @param sizeId number
     * @param sizeName string
     */
    constructor(sizeId : number,sizeName : string,itemSubCategoryId?:number){
        this.sizeId = sizeId;
        this.sizeName = sizeName;
        this.itemSubCategoryId = itemSubCategoryId;
    }
}