import React, { useState, useEffect } from 'react';
import { Card, Layout, Button, Table, Descriptions, Tag, Modal, Typography, Tooltip } from 'antd';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { HarvestIdRequest, HarvestModel, IndentModel, IndentRequest } from '@gtpl/shared-models/raw-material-procurement';
import { Link, useLocation } from 'react-router-dom';
import { HarvestTypes, PlantsEnum, SupplierTypeEnum } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { SnippetsOutlined, FormOutlined, PrinterOutlined } from '@ant-design/icons';

import './indent-detail-view.css';
import { FactoriesInput } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantIdRequest, PlantsDropDown } from '@gtpl/shared-models/masters';
import { HarvestReport } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/harvest-report';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import RMAudit from './indent-grn-audit';
import RMReweighing from './rm-re-weighment';

/* eslint-disable-next-line */
export interface IndentDetailViewProps {
  IndentDetails: IndentModel[]
}

export function IndentDetailView(
  props: IndentDetailViewProps
) {

  let location = useLocation();
  const indentId = location.state;
  const [indentData, setIndentData] = useState<any[]>();
  const service = new IndentService();
  const [compName, setCompName] = useState<string>(null);
  const [visible, setVisible] = useState(false);
  const [indentRecord, setIndentRecord] = useState(null);
  const [plantName, setPlantName] = useState<string>('');
  const [harvestData, setHarvestData] = useState<HarvestModel>(null);
  const unitCodeService = new UnitcodeService();
  const [isReportModalShow, setIsReportModalShow] = useState<boolean>(false);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [modelType, setModelType] = useState('');
  const unitcodeService = new UnitcodeService();


  useEffect(() => {
    getAllMainPlants();
    if (indentId) {
      console.log(indentId)
    }
    getIndentDetailsForIndentId(indentId);
  }, [indentId]);

  const getIndentDetailsForIndentId = (indentId) => {
    const req = new IndentRequest();
    req.indentId = indentId;
    service.getIndentDataById(req).then((res) => {
      if (res.status) {
        setIndentData(res.data);
        console.log(res);
      }
    })
  }
  const getHarvestData = (rowData: IndentModel) => {
    unitCodeService.getPlantDetailsById(new PlantIdRequest(rowData.plantId)).then(res => {
      if (res.status) {
        setPlantName(res.data.unitcodeName);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });

    service.getHarvestReport(new HarvestIdRequest(rowData.harvestReportId, rowData.plantId)).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Harvest Report retrived Successfully');
        setHarvestData(res.data);
        setIsReportModalShow(true);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const columns: any[] = [
    {
      title: 'Farmer',
      dataIndex: 'farmerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Middle Man',
      dataIndex: 'BrokerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Address',
      dataIndex: 'farmName',
      render: (text, data, index) => {

        return <span>{`${data.street},${data.apartment},${data.city},${data.state},${data.district},${data.postalCode}`}</span>
      }
    },
    {
      title: 'Contact Number',
      dataIndex: 'mobileNumber',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farm',
      dataIndex: 'farmName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text, data, index) => {
        console.log(data)
        return <span>{text}</span>
      }
    },
    {
      title: 'Count',
      dataIndex: 'expectedCount',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'expectedQty',
      render: (text, data, index) => {
        return <span>{text}</span>
      }
    },
    {
      title: 'Price',
      dataIndex: 'expectedPrice',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title:'Defect',
      dataIndex:'defectName',
      render :(text,record)=>{
        return <span>{text}</span>
      }
    },
    {
      title: 'Harvest Report',
      dataIndex: 'harvestReportId',
      key: 'harvestReportId',
      hideInSearch: true,
      hideInForm: true,
      width: 180,
      render: (harvestReportId, rowData) => (
        <>
          {(harvestReportId) ?
            <PrinterOutlined style={{ fontSize: '16px', color: '#096dd9' }} onClick={() => { getHarvestData(rowData) }} />
            :
            <Button type="primary" size={"small"} onClick={() => { openHarvestForm(rowData, 'Harvest Report Form') }}>Create</Button>
          }
        </>
      ),
    },
    {
      title: 'Actual Count',
      dataIndex: 'grnCount',
      key: 'grnCount',
      className: (indentData) ? (indentData[0]?.harvestType == HarvestTypes.Re_Weighment) ? 'hideColumn' : 'showColumn' : '',
      render: (value, record, index) => {
        return record.grnCount;
      },
    },
    {
      title: 'Actual Quantity',
      dataIndex: 'grnQuantity',
      key: 'grnQuantity',
      className: (indentData) ? (indentData[0]?.harvestType == HarvestTypes.Re_Weighment) ? 'hideColumn' : 'showColumn' : '',
      render: (value, record, index) => {
        return record.grnQuantity;
      },
    },
    // {
    //   title: `Action`,
    //   dataIndex: 'action',
    //   fixed: 'right',
    //   render: (text, rowData) => {
    //     let buttonName;
    //     let icon;
    //     if (rowData.harvestType === HarvestTypes.Live_Harvest) {
    //       buttonName = 'Audit';
    //       icon = <FormOutlined />;
    //     } else {
    //       buttonName = 'Re Weighing';
    //       icon = <SnippetsOutlined />;
    //     }
    //     return <Button type="primary" onClick={() => openHarvestForm(rowData, buttonName)} danger icon={icon}>{buttonName}</Button>
    //   }
    // }

  ];
  const openHarvestForm = (rowData, type) => {
    setVisible(true);
    setModelType(type);
    console.log(rowData);
    setIndentRecord(rowData);
  }
  const closeModal = (harvestData: HarvestModel) => {
    getIndentDetailsForIndentId(indentId);
    unitCodeService.getPlantDetailsById(new PlantIdRequest(harvestData.plantId)).then(res => {
      if (res.status) {
        setPlantName(res.data.unitcodeName);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });

    setVisible(false);
    setHarvestData(harvestData);
    setIsReportModalShow(true);
  }
  const handleModalCancel = () => {
    setIsReportModalShow(false);
  };

  const closeReweighing = () => {
    setVisible(false);
    if (modelType === 'Audit') {
      getIndentDetailsForIndentId(indentId);
    }
  }

  const getAllMainPlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    // setIsReportModalShow(true); // model should be open
  };

  return (
    <Card title={<span style={{ color: 'white' }}>Indent Detailed View</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/indent-view' ><span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>  </Link>}>
      {indentData ? <><Descriptions column={3}>
        <Descriptions.Item label="Indent Code">
          {indentData[0]?.indentCode}
        </Descriptions.Item>
        <Descriptions.Item label="Indent Date">
          {moment(indentData[0]?.indentDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="Supplier Type">
          {indentData[0]?.supplierType}
        </Descriptions.Item>
        {(indentData[0].supplierType === SupplierTypeEnum.BROKER) ?
          <Descriptions.Item label="Supplier Name">
            {indentData[0]?.brokerName}
          </Descriptions.Item> : ''}
        {/* <Descriptions.Item label="Farmer">
          {indentData[0]?.supplierName}
        </Descriptions.Item>
        <Descriptions.Item label="Farmer Code">
          {indentData?.supplierCode}
        </Descriptions.Item>
        <Descriptions.Item label="Farm Name">
          {indentData?.farmName}
        </Descriptions.Item>
        <Descriptions.Item label="Product">
          {indentData?.productCode}
        </Descriptions.Item>
        <Descriptions.Item label="Expected Count">
          {indentData?.expectedCount}
        </Descriptions.Item>
        <Descriptions.Item label="Expected Quantity">
          {indentData?.expectedQty}
        </Descriptions.Item>
        <Descriptions.Item label="Expected Price">
          {indentData?.expectedPrice}
        </Descriptions.Item> */}
        <Descriptions.Item label="Commission Type">
          {indentData[0]?.commissionType}
        </Descriptions.Item>
        <Descriptions.Item label="Commission">
          {indentData[0]?.commission}
        </Descriptions.Item>
        <Descriptions.Item label="Harvesting Date">
          {moment(indentData[0]?.harvestingDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="Harvesting Time">
          {indentData[0]?.harvestingTime}
        </Descriptions.Item>
        <Descriptions.Item label="Lead1 Name">
          {indentData[0]?.lead1Name}
        </Descriptions.Item>
        <Descriptions.Item label="Lead2 Name">
          {indentData[0]?.lead2Name}
        </Descriptions.Item>
        <Descriptions.Item label="Vehicle Assigned">
          {indentData[0]?.vehicleAssigned ? 'YES' : 'NO'}
        </Descriptions.Item>
        <Descriptions.Item label="GRN Status">
          {indentData[0]?.grnCompleted ? 'COMPLETED' : 'PENDING'}
        </Descriptions.Item>
        <Descriptions.Item label="Assigned Plant">
          {factoriesData.map((addfr) => {
            return (indentData[0].plantId == addfr.plantId) ? addfr.plantName : ''
          })}
        </Descriptions.Item>
        <Descriptions.Item label="Harvest Type">
          {indentData[0]?.harvestType}
        </Descriptions.Item>
        <Descriptions.Item label="Is Vehicle Required">
          {(indentData[0]?.isVehicleRequired == 0) ? 'No' : 'Yes'}
        </Descriptions.Item>
      </Descriptions>
        <br />
        <Table dataSource={indentData} columns={columns} pagination={false} scroll={{ x: true }} />
      </> : ''}



      <Modal
        title={modelType}
        visible={visible}
        footer={null}
        width={'80%'}
        onCancel={() => { setVisible(false) }}
      >
        {modelType === 'Audit' ? <RMAudit key={Date.now()} itemData={indentRecord} closeModal={closeReweighing} /> : ''}
        {modelType === 'Re Weighing' ? <RMReweighing key={Date.now()} indentData={indentData[0]} closeModal={closeReweighing} indentRecord={indentRecord} /> : ''}
        {modelType === 'Harvest Report Form' ? <HarvestReport indentRecord={indentRecord} key={Date.now()} closeModal={closeModal} />
          : ''}
      </Modal>
      {harvestData ?
        <Modal
          className='harvest-report-from'
          key={'modal' + Date.now()}
          width={'60%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isReportModalShow}
          onCancel={handleModalCancel}
          footer={[]}
        >
          <br /><br />
          <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
          <div id="printme">
            <div style={{ fontSize: 'xx-large', textAlign: 'center' }}>SANDHYA AQUA EXPORTS PVT.LTD, {plantName}</div>
            <div id="reportsubtitle" style={{ fontSize: 'x-large', textAlign: 'center', backgroundColor: 'black', color: 'white', width: '40%', marginLeft: '28%' }}>SHIRMP HARVEST REPORT</div><br />
            <table style={{ width: '100%', marginLeft: '5%', fontSize: 'large' }} id="table-to-xls">
              <tr><td className="firstcoltd">1</td><td className="secondcoltd">Date</td><td>:{moment(harvestData.harvestDate).format('DD-MM-YYYY')}</td></tr>
              <tr><td className="firstcoltd">2</td><td className="secondcoltd">Farmer Id Number</td><td>:{harvestData.farmerData}</td></tr>
              <tr><td className="firstcoltd">3</td><td className="secondcoltd">Harvest Start Time</td><td>:{harvestData.harvestTime}</td></tr>
              <tr><td className="firstcoltd">4</td><td className="secondcoltd">Variety</td><td>:{harvestData.product}</td></tr>
              <tr><td className="firstcoltd">5</td><td className="secondcoltd">Quantity (in Kgs.)</td><td>:{harvestData.quantity}</td></tr>
              <tr><td className="firstcoltd">6</td><td className="secondcoltd">Temperature of the material(random) in*c</td><td>:{harvestData.temperature1 + ',' + harvestData.temperature2 + ',' + harvestData.temperature3 + ',' + harvestData.temperature4 + ',' + harvestData.temperature5}</td></tr>
              <tr><td className="firstcoltd">7</td><td className="secondcoltd">Whether boxes are clean?</td><td>:{(harvestData.cleanBoxes) ? 'Yes' : 'No'}</td></tr>
              <tr><td className="firstcoltd">8</td><td className="secondcoltd">Whether the handling is hygine?</td><td>:{(harvestData.hygineHandle) ? 'Yes' : 'No'}</td></tr>
              <tr><td className="firstcoltd">9</td><td className="secondcoltd">Whether shrimps are healthy?</td><td>:{(harvestData.healthyShirmps) ? 'Yes' : 'No'}</td></tr>
              <tr><td className="firstcoltd">10</td><td className="secondcoltd">Whether shrimps are chill killed?</td><td>:{(harvestData.chillKilled) ? 'Yes' : 'No'}</td></tr>
              <tr><td className="firstcoltd">11</td><td className="secondcoltd">Whether Icing is done properly?</td><td>:{(harvestData.properIcing) ? 'Yes' : 'No'}</td></tr>
              <tr><td className="firstcoltd">12</td><td className="secondcoltd">Vehicle Number</td><td>:{harvestData.vehicleNo}</td></tr>
              <tr><td className="firstcoltd">13</td><td className="secondcoltd">Name of the Driver </td><td>:{harvestData.driverName}</td></tr>
              <tr><td className="firstcoltd">14</td><td className="secondcoltd">Vehicle Leaving Time</td><td>:{harvestData.vehicleLeavingTime}</td></tr>
            </table>
            <br />
            <div style={{ marginLeft: '5%', border: '1px solid', height: '120px', width: '90%' }}><br />
              <span style={{ fontSize: 'large' }}><strong>Remarks/Corrective Actions</strong></span><br />
              <span>{harvestData.remarks}</span>
            </div>
            <br /><br /><br /><br /><br />
            <table style={{ width: '100%', marginLeft: '5%', fontSize: 'large' }}>
              <tr><td>Checked By</td><td>Farmer Sign</td><td>Verified By</td></tr>
            </table>
          </div>
        </Modal> : ''}
    </Card>

  );
}

export default IndentDetailView;
