import React,{useState, useEffect, useRef} from 'react';
import { Table, Card,Button, Row, Modal,Col,Typography, Empty, Form, Select, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './val-addition-grading-dashboard.css';
//import Link from 'antd/lib/typography/Link';
import {Link} from 'react-router-dom';
import {ValueAdditionService, VariantOperationsService} from '@gtpl/shared-services/production';
import { OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import {GradeToValAddGridDto, ValAddOpModel} from '@gtpl/shared-models/production-management';
import {  Route, useHistory } from "react-router-dom";
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
const { Option } = Select;
import Highlighter from 'react-highlight-words';


/* eslint-disable-next-line */
export interface ValAdditionGradingDashboardProps {}

export function ValAdditionGradingDashboard(
  props: ValAdditionGradingDashboardProps
) {

  const [gradingData, setGradingData] = useState<GradeToValAddGridDto[]>([]);
  const [valAddOperations, setValAddOperations] = useState<ValAddOpModel[]>([]);
  const valAdditionService = new ValueAdditionService();
  const varientOpService = new VariantOperationsService()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saleId, setSaleId] = useState(0);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { Text } = Typography;

  useEffect(() => {
    getAllLotOps();
 }, []);

 const  getAllLotOps= () => {
   
   }
 
   const handleCancel = () => {
    getAllLotOps();
    setIsModalVisible(false);
  };
  useEffect(() => {
    getValAdditionOperations();
    getGradedInventoryData();
  }, []);

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log(dataIndex);
  };
  
  const getValAdditionOperations = () => {
    const unitId = Number(localStorage.getItem('unit_id'));
    varientOpService.getValAddOperations(new UnitRequest(unitId)).then((res) => {
      if (res.status) {
        setValAddOperations(res.data);
      } else {
        setValAddOperations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setValAddOperations([]);
    });
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const columnsSkelton: ColumnProps<GradeToValAddGridDto>[] = [
    {
      title: 'Lot Number',
      dataIndex: 'concatLotNum',
      ...getColumnSearchProps('concatLotNum'),

    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      align:'center',
      render: (value, record) => 
      <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      align:'center',
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      align:'center',
    },
    {
      title: 'HeadOn Count',
      dataIndex: 'inputGrade',
      align:'center',
    },
    {
      title: 'HeadLess Count',
      dataIndex: 'outputGrade',
      align:'center',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align:'center',
      render: (value) => (<span style={{float:'right'}}>{Number(value).toFixed(0)}</span>)
    },
  ];

  const getGradedInventoryData = () => {
    valAdditionService.getGradedInventoryForValAddition({plantId:Number(localStorage.getItem('unit_id')),operation:OperationTypeEnum.VALUE_ADDITION}).then(res => {
      if (res.status) {
        console.log(res.data);
        setGradingData(res.data.gridData);
      } else {
        if (res.intlCode) {
          setGradingData([]);
          // console.log(res);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setGradingData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setGradingData([]);
    })
  }
  
  var gradedInventory = gradingData?.reduce(function (previousValue, currentValue) {
    return previousValue + Number(currentValue.physicalQuantity);
  }, 0);

  var issuedQnty = gradingData.reduce(function (previousValue, currentValue) {
    return previousValue + Number(currentValue.quantity);
  }, 0);

  return (
    <Card
      title={<span style={{ color: 'white' }}>Value Addition Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      {/* <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

          <Form.Item name="operation" label="Operation" rules={[{ required: true, message: 'Missing peration' }]} >
            <Select
              placeholder="Select Operation"
              allowClear
              // onChange={getLotNumbers}
            >
              {valAddOperations.map(dropData => {
                return <Option key={dropData.operationName} value={dropData.operationName}>{dropData.operationName}</Option>
              })}
            </Select>
          </Form.Item>


        </Col>

        <Col span={4}>
                <Form.Item style={{ paddingTop: 30 }}>

                  <Button type='primary' block

                    // onClick={onReset}
                  >
                    get Data
                  </Button>
                </Form.Item>
              </Col>



      </Row> */}
      <Row gutter={40}>
        <Col>
          <Card
            title={'No. of Lots Available : '+gradingData.length}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity To be Processed : '+issuedQnty}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Graded Inventory : '+gradedInventory}
            style={{
              textAlign: 'left',
              width: 250,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Link to='/assign-to-val-addition'><Button danger type="primary" htmlType="submit">
          Assign To Value Addition
        </Button></Link>
      </Row>
      <br />
      <br />
      {gradingData.length ? <Table
     
        columns={columnsSkelton}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row className='tableFooter'>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
              <h1 style={{textAlign:'left',fontSize:'15px'}}><Text>{"Total Quantity"}</Text></h1>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
              <h1 style={{textAlign:'right',fontSize:'15px'}}><Text>{issuedQnty.toFixed(0)}</Text></h1>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        dataSource={gradingData}
        bordered
      
      /> : <Empty />}
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}></Row>
      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen='production'
          />
      </Modal>

    
    </Card>
  );
}

export default ValAdditionGradingDashboard;
