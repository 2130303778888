import React, { useEffect, useState } from 'react';

import './metal-detection-form.css';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd'
import { MetalDetectionService } from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { MetalDetectionDefault, MetalDetectionDto } from '@gtpl/shared-models/production-management';
import { Link, useHistory } from 'react-router-dom';
import { MasterBrandsDropDownDto, SkuDto } from '@gtpl/shared-models/masters';
import { VariantService, MasterBrandService, SkuService } from '@gtpl/shared-services/masters';
/* eslint-disable-next-line */
export interface MetalDetectionFormProps { }
const { Option } = Select;
export function MetalDetectionForm(
  props: MetalDetectionFormProps
) {
  useEffect(() => {
    form.setFieldsValue(MetalDetectionDefault);
    getSkuCodes();
    getBrandData();

  }, [])
  const [form] = Form.useForm<MetalDetectionDto>();
  let history = useHistory();

  const [brandArrayData, setBrandArrayData] = useState<MasterBrandsDropDownDto[]>([]);
  const [skucodes, setSkucode] = useState<SkuDto[]>([]);
  const [preOrPost, setPreOrPost] = useState<boolean>(true);
  const [disable, setDisable] = useState<boolean>(false)
  const service = new MetalDetectionService();
  const skuService = new SkuService();
  const brandsService = new MasterBrandService();
  const getBrandData = () => {
    brandsService.getAllMasterBrandsDropDown().then(res => {
      console.log(res);
      if (res.status) {
        setBrandArrayData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getSkuCodes = () => {
    skuService.getActiveSkuCodes().then((res) => {
      if (res.status) {
        setSkucode(res.data);
      }
      else {
        setSkucode([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSkucode([]);
      });

  }

  const onFinish = () => {
    setDisable(true)
    form.validateFields().then((formData) => {
      formData.createdUser = localStorage.getItem('createdUser');
      formData.updatedUser = localStorage.getItem('createdUser');
      formData.plant = Number(localStorage.getItem('unit_id'));
      service.createMetalDetectionLog(formData).then(res => {
        setDisable(false)
        if (res.status) {
          history.push("metal-detection-grid");
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }

  const handlePreOrPost = () => {
    const operation = form.getFieldValue('operation');
    if (['Pre Operation', 'Post Operation'].includes(operation)) {
      setPreOrPost(false);
    } else {
      setPreOrPost(true);
    }
  }

  return (
    <Card title={<span style={{ color: 'white', textAlign: 'center' }}>Metal Detection Log</span>}
      headStyle={{ backgroundColor: '#69c0ff', border: 0, textAlign: 'center' }} extra={<Link to='/metal-detection-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View</Button> </span></Link>} >
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Form.Item name="metalDetectionId" >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="date"
              label='Date'
              rules={[{ required: true, message: 'Date is Required!' }]}
            >
              <DatePicker showTime />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label='Grade' className='ItemsFormGrade' >
              <Input.Group compact>
                <Form.Item
                  name={'minGrade'}
                  rules={[{
                    required: preOrPost, message: 'Missing Min Grade'
                  }, {
                    validator: (_, value) => {
                      const maxGrade = form.getFieldValue('maxGrade');
                      if (maxGrade) {
                        return value < maxGrade ? Promise.resolve() : Promise.reject(new Error('Min Grade should be < Max Grade'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}
                  noStyle
                >
                  <InputNumber style={{ width: "50%" }} placeholder="Min" min={0} />
                </Form.Item>


                <Form.Item
                  name={"maxGrade"}
                  rules={[{
                    required: preOrPost, message: 'Missing Max Grade'
                  }, {
                    validator: (_, value) => {
                      const minGrade = form.getFieldValue('minGrade');
                      if (minGrade) {
                        return value > minGrade ? Promise.resolve() : Promise.reject(new Error('Max Grade should be > Min Grade'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}
                  noStyle

                >
                  <InputNumber style={{ width: "50%" }} placeholder="Max" min={0} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
            <Form.Item label='Variety/Brand/S.Code' className='ItemsFormGrade' >
              <Input.Group compact style={{ width: '100%' }}>
                <Form.Item
                  name={'variety'}
                  rules={[{ required: false, message: 'Missing Variety' }]}
                >
                  <Select
                    placeholder="Select Variant Code"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {skucodes.map(skudropData => {
                      return <Option label={'varientId'} key={skudropData.skuCodeId} value={skudropData.skuCodeId}>{skudropData.variantCode}</Option>
                    })}
                  </Select>

                </Form.Item>

                <Form.Item
                  name={'brand'}
                  rules={[{ required: false, message: 'Missing Brand' }]}
                >
                  <Select
                    placeholder="Select Brand"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={null}>Select Size</Option>
                    {brandArrayData.map(branddropData => {
                      return <Option label={'brandId'} key={branddropData.masterBrandId} value={branddropData.masterBrandId}>{branddropData.masterBrandName}</Option>
                    })}
                    |
                  </Select>
                </Form.Item>

                <Form.Item
                  name={'sCode'}
                  rules={[{ required: false, message: 'Missing S. Code' }]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  name={'operation'}
                  rules={[{ required: false, message: 'Missing Operation' }]}
                >
                  <Select defaultValue='' style={{ width: '100%' }} onSelect={handlePreOrPost}>
                    <Option value={''}>Please Select</Option>
                    <Option value="Pre Operation">Pre Operation</Option>
                    <Option value="Post Operation">Post Operation</Option>
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item label={'Standard Test Pads'} className='ItemsFormGrade' >
              <Input.Group compact>
                <Form.Item
                  name={'mmFe'}
                  label={'2 MM Fe'}
                  rules={[{ required: true, message: 'Missing 2 MM Fe' }]}
                >
                  <Switch defaultChecked />
                </Form.Item>
                <Divider type="vertical" />
                <Form.Item
                  name={'mmNonFe'}
                  label={'2.5 MM Non Fe'}
                  rules={[{ required: true, message: 'Missing 2.5 MM Non Fe' }]}
                >
                  <Switch defaultChecked />
                </Form.Item>
                <Divider type="vertical" />
                <Form.Item
                  name={'mmSS'}
                  label={'2.5 MM SS'}
                  rules={[{ required: true, message: 'Missing 2.5 MM SS' }]}
                >
                  <Switch defaultChecked />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item label='No Of Pouches Passed' className='ItemsFormGrade' >
              <Input.Group compact>
                <Form.Item
                  name={'minPouches'}
                  rules={[{ required: preOrPost, message: 'Missing Minimum Pouches' }, {
                    validator: (_, value) => {
                      const maxPouches = form.getFieldValue('maxPouches');
                      if (maxPouches) {
                        return value < maxPouches ? Promise.resolve() : Promise.reject(new Error('Min Pouches should be < Max Pouches'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}
                  noStyle
                >
                  <InputNumber min={0} placeholder='Min' style={{ width: "50%" }} />
                </Form.Item>

                <Form.Item
                  name={'maxPouches'}
                  rules={[{ required: preOrPost, message: 'Missing Max Pouches' }, {
                    validator: (_, value) => {
                      const minPouches = form.getFieldValue('minPouches');
                      if (minPouches) {
                        return value > minPouches ? Promise.resolve() : Promise.reject(new Error('Max Pouches should be > Min Pouches'));
                      } else {
                        return Promise.resolve();
                      }

                    }
                  }]}
                  noStyle
                >
                  <InputNumber min={0} placeholder='max' style={{ width: "50%" }} />
                </Form.Item>

              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"metalDetected"}
              label="Metal Detected"
              rules={[
                {
                  required: true,
                  message: `Missing Metal Detected`,
                },
              ]}
              style={{ width: '100%' }}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name={"correctiveAction"}
              label="Corrective Action"
              rules={[
                {
                  required: false,
                  message: `Missing Corrective Action`,
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item style={{ paddingTop: '30px' }}>
              <Button type="primary"disabled={disable} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form >
    </Card>
  );
}

export default MetalDetectionForm;
