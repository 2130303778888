import axios from "axios";
import connection from './connection';
import {NotificationDto, AllNotificationsResponse, NotificationResponse } from '@gtpl/shared-models/hrms';

export class NotificationsService{

    URL = connection.DEPLOY_URL + '/notifications';

    async createNotification(data: NotificationDto): Promise<NotificationResponse>{        
        return await axios.post(this.URL + '/createNotification', data)
            .then(res => {
                return res.data
            })
    }

    async updateNotification(data: NotificationDto): Promise<NotificationResponse>{
        return await axios.post(this.URL + '/updateNotification', data)
            .then(res => {
                return res.data
            })
    }

    async getAllNotifications(): Promise<AllNotificationsResponse>{
        return await axios.post(this.URL + '/getAllNotifications')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateNotification(data: NotificationDto): Promise<NotificationResponse>{
        return await axios.post(this.URL + '/activateOrDeactivateNotification', data)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveNotifications(): Promise<AllNotificationsResponse>{
        return await axios.post(this.URL + '/getAllActiveNotifications')
           .then(res => {
               return res.data
           })
    }
}