import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SkuDetails } from '.';

export class SkuDetailsResponseModel extends GlobalResponseObject{
    data?: SkuDetails;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ProductDto
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SkuDetails) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}