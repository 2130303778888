
export class GradesDto {
    gradeId: number;
    gradeName: string;
    gradeMinCount:number;
    gradeMaxCount: number;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    constructor(gradeId: number,gradeName: string,gradeMinCount:number,gradeMaxCount: number,isActive: boolean,createdAt : Date | any,createdUser : string,updatedAt : Date | any,updatedUser : string,versionFlag : number){
        this.gradeId = gradeId;
        this.gradeName = gradeName;
        this.gradeMinCount = gradeMinCount;
        this.gradeMaxCount = gradeMaxCount;
        this.isActive = isActive;
        this.createdAt  = createdAt;
        this.createdUser  = createdUser;
        this.updatedAt  = updatedAt;
        this.updatedUser  = updatedUser;
        this.versionFlag  = versionFlag;     
    }
}


