import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { InterviewDto } from './interview.dto';

export class InterviewResponseModel extends GlobalResponseObject{
    data?: InterviewDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: InterviewDto){
            super(status,intlCode,internalMessage);
            this.status = status;
            this.intlCode = intlCode;
            this.internalMessage = internalMessage;
            this.data = data;
        }
    }