import { SaleOrderRequest } from '@gtpl/shared-models/logistics';
import {  CommonResponse, InternalTransferDto, LocationRequestDto, RackAckResponse, RackPositionId, RackPositionIdRequest, RackPositionModel, RackPositionResponseModel, RackPostionReq, RackPostionRequest, RackResponseModel, RacksForRackId, RacksForZoneId,RackStatusRequest,RackSuggestionRequest,RackZoneLevelRequest,ReservedRacksSoRequest,ReserveRackPositionsRequest,UpdateRackpositionRequest,ZoneIdRequest } from '@gtpl/shared-models/masters';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import { PlantIdRequest, SaleOrderIdRequest } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import connection from './connection';

export class RackPositionService {
URL = connection.DEPLOY_URL + '/rack-position';

  async createRack(rackDto: RackPositionModel): Promise<RackAckResponse> {
      return await axios.post(this.URL + '/createRackPosition',rackDto)
          .then(res => {
              return res.data
          })
  }
  async  updateRack(rackDto: RackPositionModel): Promise<RackAckResponse> {
    return await axios.post(this.URL + '/updateRackPosition', rackDto)
           .then(res => {
               return res.data
           })
}
  async getAllRacks(): Promise<RackAckResponse> {
    return await axios.post(this.URL + '/getAllRacksPositions')
        .then(res => {
            return res.data
        })     
}
async  ActivateorDeactivateRack(rackDto: RackPostionReq): Promise<RackAckResponse> {
      return await axios.post(this.URL + '/activateorDeactivateRack', rackDto)
              .then(res => {
                  return res.data
              })
 }
 async getRackPositionsForRackMasterId(rackMasterId :RacksForRackId): Promise<RackResponseModel> {
  return await axios.post(this.URL + '/getRackPositionsForRackMasterId',rackMasterId)
      .then(res => {
          return res.data 
      })     
}
 async getRackByRackId(racksForRackId :RacksForRackId): Promise<RackPositionResponseModel> {
     console.log(racksForRackId.rackId);
  return await axios.post(this.URL + '/getRacksForRackId',racksForRackId)
      .then(res => {
          console.log(res.data);
          return res.data 
      })     
}

async getRackByZoneId(racksForZoneId :RacksForZoneId): Promise<RackPositionResponseModel> {
    console.log(racksForZoneId.zoneId);
 return await axios.post(this.URL + '/getRacksForZoneId',racksForZoneId)
     .then(res => {
         console.log(res.data);
         return res.data 
     })     
}

 async updateRackPostionOccupancy(racksForRackId :RacksForRackId): Promise<CommonResponse> {
  return await axios.post(this.URL + '/updateRackPostionOccupancy',racksForRackId)
      .then(res => {
          return res.data 
      })     
}
async updateRackPostionOccupancyNotOccupied(racksForRackId :RacksForRackId): Promise<CommonResponse> {
    return await axios.post(this.URL + '/updateRackPostionOccupancyNotOccupied',racksForRackId)
        .then(res => {
            return res.data 
        })     
  }

async freeRackPostionOccupancy(racksForRackId :RacksForRackId): Promise<CommonResponse> {
    return await axios.post(this.URL + '/freeRackPostionOccupancy',racksForRackId)
        .then(res => {
            return res.data 
        })     
  }
  async updateRackStatus(plantRackPosReq: UpdateRackpositionRequest):Promise<boolean> {
    return await axios.post(this.URL + '/updateRackStatus',plantRackPosReq)
        .then(res => {
            console.log(res);
            return res.data 
        })     
  }
async getReserveRackPosition(rackPositionIdRequest: RackPositionIdRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getReserveRackPosition',rackPositionIdRequest)
        .then(res => {
            return res.data 
        })     
  }
  async getSuggestLocation(locationsRequest: LocationRequestDto): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getSuggestLocation',locationsRequest)
        .then(res => {
            return res.data 
        })     
  }
  async reserveRackPosition(rackSuggestionRequest: LocationRequestDto):Promise<any>{
    return await axios.post(this.URL + '/reserveRackPosition',rackSuggestionRequest)
        .then(res => {
            return res.data 
        })     
  }
  async getUnit1RackPositions(plantIdRequest: PlantIdRequest):Promise<any>{
    return await axios.post(this.URL + '/getUnit1RackPositions',plantIdRequest)
        .then(res => {
            return res.data 
        })     
  }
  async reserveRackPositions(rackSuggestionRequest: LocationRequestDto):Promise<any>{
    return await axios.post(this.URL + '/reserveRackPositions',rackSuggestionRequest)
        .then(res => {
            return res.data 
        })     
  }
  async getAvailableRackPositionsForZoneId(req: ZoneIdRequest): Promise<RackPositionResponseModel> {
    return await axios.post(this.URL + '/getAvailableRackPositionsForZoneId',req)
        .then(res => {
            return res.data 
        })     
  }
  async getAvailableRackPositionsForPlantId(req: LocationRequestDto): Promise<RackPositionResponseModel> {
    return await axios.post(this.URL + '/getAvailableRackPositionsForPlantId',req)
        .then(res => {
            return res.data 
        })     
  }

  async getLocationByZRL(req: RackZoneLevelRequest) {
    return await axios.post(this.URL + '/getLocationByZRL',req)
        .then(res => {
            return res.data 
        })     
  }
  async getUnreserveRackPositions(req:SaleOrderIdRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getUnreserveRackPositions',req)
        .then(res => {
            return res.data 
        })     
  }
  async getRackStatus(req:RackStatusRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getRackStatus',req)
        .then(res => {
            return res.data 
        })     
  }

  async updateOccupancyAfterTransfer(req:RackPostionReq): Promise<CommonResponse> {
    return await axios.post(this.URL + '/updateOccupancyAfterTransfer',req)
        .then(res => {
            return res.data 
        })     
  }
  
  async getReservedRackPositionsForSO(reservedRacksSoRequest: ReservedRacksSoRequest): Promise<RackPositionResponseModel> {
    return await axios.post(this.URL + '/getReservedRackPositionsForSO',reservedRacksSoRequest)
        .then(res => {
            return res.data 
        })     
  }
  async reserveRacksForIssuedQty(reservedRacksSoRequest: ReserveRackPositionsRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/reserveRacksForIssuedQty',reservedRacksSoRequest)
        .then(res => {
            return res.data 
        })     
  }
  async getRackSuggestion(req: RackSuggestionRequest):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getRackSuggestion',req)
        .then(res => {
            return res.data 
        })     
  }
  async getRackDetailsById(req: RackPositionId):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getRackDetailsById',req)
        .then(res => {
            return res.data 
        })     
  }
  async getNotOccupiedLocationsForPlant(req: PlantIdRequest):Promise<CommonResponse> {
    return await axios.post(this.URL + '/getNotOccupiedLocationsForPlant',req)
    .then(res => {
        return res.data 
    })     
}
    async getAvailableRackPositionsForInternalTransfer(req: InternalTransferDto):Promise<RackPositionResponseModel> {
        return await axios.post(this.URL + '/getAvailableRackPositionsForInternalTransfer',req)
        .then(res => {
            return res.data 
        })     
    }

} 