import { SaleOrderDto } from './sale-order.dto';
import {SODto} from './sale-order.dto';
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { AllSaleOrdersDto } from './all-sale-orders.dto';


export class AllSaleOrdersDetailsResponseModel extends GlobalResponseObject {
    data?: AllSaleOrdersDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /SODto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AllSaleOrdersDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 