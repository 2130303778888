import axios from 'axios';
import {RelationDto,RelationResponseModel,AllRelationsResponseModel} from '@gtpl/shared-models/masters';
import connection from './connection';

export class RelationService {
URL = connection.DEPLOY_URL + '/relations';

  async createRelation(relation: RelationDto): Promise<RelationResponseModel> {
    console.log(relation);
      return await axios.post(this.URL + '/createRelation',relation)
          .then(res => {
              return res.data
          })
  }
  async  updateRelation(relation: RelationDto): Promise<RelationResponseModel> {
    return await axios.post(this.URL + '/updateRelation', relation)
           .then(res => {
               return res.data
           })
}
  async getAllRelations(): Promise<AllRelationsResponseModel> {
    return await axios.post(this.URL + '/getAllRelations')
        .then(res => {
            return res.data
        })     
}
async  activateorDeactivateRelation(relation: RelationDto): Promise<AllRelationsResponseModel> {
      console.log(relation);
      return await axios.post(this.URL + '/activateorDeactivateRelation', relation)
              .then(res => {
                  return res.data
              })
 }

} 