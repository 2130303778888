export enum OperationTypeEnum {
    GRN = 'GRN',
    BE_HEADING = 'DEHEADING',
    GRADING = 'GRADING',
    VALUE_ADDITION = 'VALUE ADDITION',
    SOAKING = 'SOAKING',
    FREEZING = 'FREEZING',
    PACKING = 'PACKING',
    NO_OPERATION = 'NO OPERATION',
    TOFF = 'TOFF',
    PD = 'PD',
    RPDTON = 'RPDTON',
    COOKING='COOKING',
    PEELING='PEELING',
    PEELUNDEVEIN = 'PEELUNDEVEIN',
    REJECTION = 'REJECTION',

}

export const OpertionTypeDropDown = [
    {name : 'GRN',value:'GRN'},{name : 'DEHEADING',value:'DEHEADING'},{name:'GRADING',value:'GRADING'},{name : 'VALUE ADDITION',value:'VALUE_ADDITION'},{name:'SOAKING',value:'SOAKING'},{name:'FREEZING',value:'FREEZING'},{name:'PACKING',value:'PACKING'},{name:'NO_OPERATION',value:'NO_OPERATION'},{name:'TOFF',value:'TOFF'},{name:'PD',value:'PD'},{name:'RPDTON',value:'RPDTON'},
    {name:'COOKING',value:'COOKING'},{name:'PEELING',value:'PEELING'},{name:'PEELUNDEVEIN',value:'PEELUNDEVEIN'},{name:'REJECTION',value:'REJECTION'}
]