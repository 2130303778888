import { EmployeeDataModel } from "./employee-data.model";


export class DeptWiseDataModel {
    departmentId: number;
    departmentName: string;
    empDetailes: EmployeeDataModel[];
    totalCount: number;
    presentCount: number;

    /**
     * 
     * @param departmentId
     * @param departmentName
     * @param empDetailes 
     * @param totalCount 
     * @param presentCount 
     */
    constructor(departmentId: number, departmentName: string, empDetailes: EmployeeDataModel[], totalCount: number, presentCount: number) {
        this.departmentId = departmentId;
        this.departmentName = departmentName;
        this.empDetailes = empDetailes;
        this.totalCount = totalCount;
        this.presentCount = presentCount;

    }
}