// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QpGUIxHqdsznR6pKcC_Sh{\r\n    margin:3px;\r\n    background-color:rgb(248, 5, 5);\r\n    color:white;\r\n    font-weight: bold;\r\n}\r\n\r\n.efz_Ur-LuuN3ZrWBi-_55{\r\n    margin:3px;\r\n    background-color:#7fff00;\r\n    font-weight: bold;\r\n}\r\n\r\n._2x_8KlMQYKTiXMKAD01seI{\r\n    margin:3px;\r\n    background-color:#ff007f;\r\n    color:white;\r\n    font-weight: bold;\r\n}\r\n\r\n._3NS63Q6fN7SHFX16chJdDM {\r\n    background-color: '#69c0ff';\r\n    margin:5px;\r\n    text-align: center;\r\n}\r\n", ""]);
// Exports
exports.locals = {
	"cardSpace1": "QpGUIxHqdsznR6pKcC_Sh",
	"cardSpace2": "efz_Ur-LuuN3ZrWBi-_55",
	"cardSpace3": "_2x_8KlMQYKTiXMKAD01seI",
	"mainCardSpace": "_3NS63Q6fN7SHFX16chJdDM"
};
module.exports = exports;
