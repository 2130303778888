import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class VarientIdRequest {
    variantId: number;

    constructor(
        variantId: number) {
        this.variantId = variantId;
    }
}

export class VariantNextOpModel {
    presentOperation: OperationTypeEnum;
    variantId: number;
    plantId: number;

    constructor(presentOperation: OperationTypeEnum,variantId: number,plantId: number){
        this.presentOperation = presentOperation;
        this.variantId = variantId;
        this.plantId = plantId;
    }
}

export class OperationandLotReq{
    operation?:string;
    lotNumber?:string;
    plant?:number;
    subLotNumber?:string;
}
export class operationReq{
    operation?:string;
    plant?:number;
}

export class LotAndSaleOrderIdReq{
    lotNumber:string;
    saleOrderItemId:number;
    saleOrderId:number;
    plantId:number
    subLotNumber:string;


}
