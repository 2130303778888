import { VirtualTimeScheduler } from "rxjs";

export class BomItemRemovedQty{
    packingMaterialId:number;
    itemId:number;
    saleOrderId:number;
    removedQuantity:number;
    unitId?:number;
    createdUser?:string;
    locationWiseStock?:any[]

    constructor(packingMaterialId:number, itemId:number, saleOrderId:number, removedQuantity:number,unitId?:number,createdUser?:string,locationWiseStock?:any[]){
        this.packingMaterialId = packingMaterialId;
        this.itemId = itemId;
        this.removedQuantity = removedQuantity;
        this.saleOrderId = saleOrderId;
        this.unitId = unitId;
        this.createdUser = createdUser;
        this.locationWiseStock = locationWiseStock;
    }
}