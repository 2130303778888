export class DowntimeTrackingMacineNameResponse{
   assetId:number;
    assetName:string;
    assetSla:number;
    assetPriority:string;
    employeeName:string;
    constructor(   assetId:number,assetName:string,assetSla:number,assetPriority:string,employeeName:string)
    {
        this.assetId=assetId;
this.assetName=assetName;
this.assetSla=assetSla;
this.assetPriority=assetPriority;
this.employeeName=employeeName;
    }
}