export class EmployeeAdjustment{
    employeeCode:string;
    employeeName:string;
    date:string;
    inTime:Date;
    outTime:Date;
    presentStatus:string;
    
    /**
     * 
     * @param employeeCode 
     * @param employeeName 
     * @param date 
     * @param inTime 
     * @param outTime 
     * @param presentStatus 
     */

    constructor(employeeCode:string,employeeName:string,date:string,inTime:Date,outTime:Date,presentStatus:string){

        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.date = date;
        this.inTime = inTime;
        this.outTime = outTime;
        this.presentStatus = presentStatus;


    }
}