import {BlStatusEnum} from "@gtpl/shared-models/common-models";
export class LoadingReportModel {
    consignee: string;
    loadPort: string;
    finalDestination: string;
    shippingBillNo: string;
    shippingDate: Date;
    containerNo: string;
    sealNo: string;
    vesselName: string;
    billOfLadingNo: string;
    SOBDate: Date;
    blStatus: BlStatusEnum;
    poNo: string;
    dispatchStatus: string;
    remarks: string;
    freightInvoiceReceived: string;
    obStatus: string;
    coReceived: boolean;
    docDispatchStatus: boolean;
    refNo: string;
    loadingSurveyStatus: string;
    invoiceAmount: number;
    shippingTerms: string;
    fobInUsd: number;
    fobInInr: number;
    taxAmntInInr: number;
    saleOrderNo: string;
    hoCertificateNo:string;
    hoCertificateStatus:boolean;
    signedDsStatus:boolean;
    isfWorksheetUploaded:boolean;
    shippingBillReceivedStatus:boolean;
    paymentTerms:string;
    insuranceStatus:string;
    manufacturingPlant?:string;
    processingPlant?:string;
    endCustomer?:string;
    plant?:string;
    saleOrderStatus?:string;
    containerStatus?:string;
    status?:string;
    soStatus?:string;

    constructor(
    consignee: string,
    loadPort: string,
    finalDestination: string,
    shippingBillNo: string,
    shippingDate: Date,
    containerNo: string,
    sealNo: string,
    vesselName: string,
    billOfLadingNo: string,
    SOBDate: Date,
    blStatus: BlStatusEnum,
    poNo: string,
    dispatchStatus: string,
    remarks: string,
    freightInvoiceReceived: string,
    obStatus: string,
    coReceived: boolean,
    docDispatchStatus: boolean,
    refNo: string,
    loadingSurveyStatus: string,
    invoiceAmount: number,
    shippingTerms: string,
    fobInUsd: number,
    fobInInr: number,
    taxAmntInInr: number,
    saleOrderNo: string,
    hoCertificateNo:string,
    hoCertificateStatus:boolean,
    signedDsStatus:boolean,
    isfWorksheetUploaded:boolean,
    shippingBillReceivedStatus:boolean,
    paymentTerms:string,
    insuranceStatus:string,
    manufacturingPlant?:string,
    processingPlant?:string,
    endCustomer?:string,
    plant?:string,
    saleOrderStatus?:string,
    containerStatus?:string,
    status?:string,
    soStatus?:string
        ){
          this.consignee = consignee;
          this.loadPort = loadPort;
          this.finalDestination = finalDestination;
          this.shippingBillNo = shippingBillNo;
          this.shippingDate = shippingDate;
          this.containerNo = containerNo;
          this.sealNo = sealNo;
          this.vesselName = vesselName;
          this.billOfLadingNo = billOfLadingNo;
          this.SOBDate = SOBDate;
          this.blStatus = blStatus;
          this.poNo = poNo;
          this.dispatchStatus = dispatchStatus;
          this.remarks = remarks;
          this.freightInvoiceReceived = freightInvoiceReceived;
          this.obStatus = obStatus;
          this.coReceived = coReceived;
          this.docDispatchStatus = docDispatchStatus;
          this.refNo = refNo;
          this.loadingSurveyStatus = loadingSurveyStatus;
          this.invoiceAmount = invoiceAmount;
          this.shippingTerms = shippingTerms;
          this.fobInUsd= fobInUsd;
          this.fobInInr= fobInInr;
          this.taxAmntInInr= taxAmntInInr;
          this.saleOrderNo= saleOrderNo;
          this.hoCertificateNo= hoCertificateNo;
          this.hoCertificateStatus= hoCertificateStatus;
          this.signedDsStatus= signedDsStatus;
          this.isfWorksheetUploaded= isfWorksheetUploaded;
          this.shippingBillReceivedStatus= shippingBillReceivedStatus;
          this.paymentTerms= paymentTerms;
          this.insuranceStatus= insuranceStatus;
          this.manufacturingPlant=manufacturingPlant;
          this.processingPlant=processingPlant;
          this.endCustomer=endCustomer;
          this.plant=plant;
          this.saleOrderStatus=saleOrderStatus;
          this.containerStatus=containerStatus;
          this.status=status;
          this.soStatus=soStatus;
        }
}
