import axios from 'axios';
import {
  CurrencyResponseModel,
  CurrencyDto,
  AllCurrencyResponseModel,
  CurrencyRequest,
} from '@gtpl/shared-models/masters';
import connection from './connection';
import { Body } from '@nestjs/common';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class CurrencyService {
  URL = connection.DEPLOY_URL + '/currencies';

  async createCurrency(currency: CurrencyDto): Promise<CurrencyResponseModel> {
    return await axios
      .post(this.URL + '/createCurrency', currency)
      .then((res) => {
        return res.data;
      });
  }
  async updateCurrency(currency: CurrencyDto): Promise<CurrencyResponseModel> {
    return await axios
      .post(this.URL + '/updateCurrency', currency)
      .then((res) => {
        return res.data;
      });
  }
  async getAllCurrencies(req?:UserRequestDto): Promise<AllCurrencyResponseModel> {
    return await axios.post(this.URL + '/getAllCurrencies',req).then((res) => {
      console.log('Get Currencies')
      console.log(res)
      return res.data;
    });
    // });
    // return new AllCurrencyResponseModel(true,123,"successful",[new CurrencyDto(1,"Rupees","KK",true)]);
  }

  async ActivatedeActivateCurrency(
    currencyDto: CurrencyDto
  ): Promise<CurrencyResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivateCurrency', currencyDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveCurrencys(): Promise<AllCurrencyResponseModel> {
    return await axios.post(this.URL + '/getAllActiveCurrencies').then((res) => {
      return res.data;
    });
  }
  async getCurrencyById(currencyRequest:CurrencyRequest): Promise<CurrencyResponseModel> {
    return await axios.post(this.URL + '/getCurrencyById',currencyRequest).then((res) => {
      return res.data;
    });
  }
  async getActiveCurrencysCount(): Promise<AllCurrencyResponseModel> {
    return await axios
      .post(this.URL + '/getActiveCurrencyCount')
      .then((res) => {
        return res.data;
      });
  }
}
