import { LocationStatus } from "../location/loaction-status.enum";

export class LocationsDto {
  locationId: number;
  zoneId: number;
  rackId: number;
  levelId: number;
  columnId: number;
  locationName: string;
  locationStatus: LocationStatus;
  isActive: boolean;
  createdAt: Date;
  createdUser: string | null;
  updatedAt: Date;
  updatedUser: string | null;
  versionFlag: number;

  /**
   * @params locationId
   * @params zoneId
   * @params rackId
   * @params levelId
   * @params columnId
   * @params locationName
   * @params locationStatus
   * @params isActive
   * @params createdAt
   * @params createdUser
   * @params updatedAt
   * @params updatedUser
   * @params versionFlag
   *
   *
   *
   */
  constructor(
    locationId: number,
    zoneId: number,
    rackId: number,
    levelId: number,
    columnId: number,
    locationName: string,
    locationStatus: LocationStatus,
    isActive: boolean,
    createdAt: Date,
    createdUser: string | null,
    updatedAt: Date,
    updatedUser: string | null,
    versionFlag: number
  ) {
    this.locationId = locationId;
    this.zoneId = zoneId;
    this.rackId = rackId;
    this.levelId = levelId;
    this.columnId = columnId;
    this.locationName = locationName;
    this.locationStatus = locationStatus;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.createdUser = createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
  }
}
