import { GrnDetailsForReturn, GrnItemsForReturn, GrnNumberDto, GrnReturnItemsDto } from '@gtpl/shared-models/procurement-management';
import { GrnService, GrnItemReturnService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Col, Divider, Empty, Form, Row, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import GrnReturnDetails from './grn-return-details'
import GrnReturnItemsFrom from './grn-return-items-from';
import { Link, Route, useHistory } from "react-router-dom";
import { UnitRequest } from '@gtpl/shared-models/common-models';
export const GrnReturnForm = () => {
  const [grnNumberDropDowndata, setGrnNumberDropDowndata] = useState<GrnNumberDto[]>([]);
  const [grnReturnDetailsForm] = Form.useForm<GrnDetailsForReturn>();
  const [grnReturnItemsForm] = Form.useForm();
  const [gridData, setGridData] = useState<GrnItemsForReturn[]>([]);
  const [keyUpdate, setKeyUpdate] = useState<number>(0);
  const grnService = new GrnService();
  const [disabled, setDisabled] = useState(false);
  const [noData, setNoData] = useState(false);
  let history = useHistory();

  const grnItemReturnService = new GrnItemReturnService()
  useEffect(() => {
    getAllActiveGrnNumbers();
  }, []);

  const getAllActiveGrnNumbers = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')));
    grnService.getAllActiveGrnNumbers(req).then((res) => {
      if (res.status) {
        setGrnNumberDropDowndata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const updateReturnItems = (Items) => {
    if (Items.length) {
      setGridData(Items);
      setKeyUpdate(keyUpdate + 1);
    } else {
      setGridData([]);
      setKeyUpdate(keyUpdate + 1);
      setNoData(true);
    }
  }

  const saveData = () => {
    if (gridData.length) {
      grnReturnDetailsForm.validateFields().then((formFields) => {
        setDisabled(true);
        formFields.plantId = Number(localStorage.getItem('unit_id'));
        formFields.createdUser = localStorage.getItem('createdUser');
        formFields.grnItems = gridData;
        grnItemReturnService.saveGrnReturns(formFields).then(grnRes => {
          if (grnRes.status) {
            history.push('./grn-return-grid');
            AlertMessages.getSuccessMessage(grnRes.internalMessage);
          } else {
            AlertMessages.getErrorMessage("Something Went Wrong, Please Verify and Try again");
          }
          setDisabled(false);
        }).catch((err) => {
          setDisabled(false);
          AlertMessages.getErrorMessage(err.message);
        });
      })
    }
  }

  return (
    <Card size="small" title={<span style={{ color: 'white' }}>GRN Return</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<><Link to='/grn-return-grid'><span style={{ color: 'white' }}><Button className='panel_button'>View </Button> </span></Link></>}>

      <GrnReturnDetails form={grnReturnDetailsForm} grnReturnItemsForm={grnReturnItemsForm} grnNumberDropDowndata={grnNumberDropDowndata} updateReturnItems={updateReturnItems} />

      {gridData.length ? <GrnReturnItemsFrom key={keyUpdate} form={grnReturnItemsForm} grnReturnDetailsForm={grnReturnDetailsForm} gridData={gridData} addItems={updateReturnItems} /> : (noData) ? <Empty
        description={
          <span>
            Stock Not Found
          </span>
        }
      /> : ''
      }

      <Row justify='start'>
        <Col style={{ display: (gridData.length) ? 'block' : 'none' }}>
          <Form.Item >
            <br />
            <Button style={{ backgroundColor: "#67b32d", color: "white" }} onClick={saveData}
              disabled={disabled} block >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}

export default GrnReturnForm
