export class EmployeeGetLeavesDto {

    typesOfLeaves: number;
    leavesLeftOver: number;


    /**
     * 
     * @param typesOfLeaves 
     * @param leavesLeftOver
     *  
     */


    constructor(typesOfLeaves: number, leavesLeftOver: number) {

        this.typesOfLeaves = typesOfLeaves;
            this.leavesLeftOver = leavesLeftOver;



    }


}



// // export class DtoOfRecruitmentDashboard {
// //     jobDescription: string;
// //     totalProfileAssigned: number;
// //     resourceRequired: number
// //     totalSelect: number;

// //     /**
// //      * 
// //      * @param jobDescription 
// //      * @param totalProfileAssigned 
// //      * @param resourceRequired 
// //      * @param totalSelect 
// //      * 
// //      */

// //     constructor(jobDescription: string,totalProfileAssigned: number,resourceRequired: number,totalSelect: number) {
        
// //         this.jobDescription = jobDescription;
// //         this.totalProfileAssigned = totalProfileAssigned;
// //         this.resourceRequired = resourceRequired;
// //         this.totalSelect = totalSelect;
// //     }


// }