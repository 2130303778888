import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShrimp, faWater,faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd'

import './shared-components-custom-spinner.css';

/* eslint-disable-next-line */
export interface CustomSpinnerProps {
  loading: boolean;
}

export function CustomSpinner(
  props: CustomSpinnerProps
) {
  const { loading } = props;
  return (
    loading && <Spin indicator={<div id='loader'   >
      <FontAwesomeIcon icon={faBoxesStacked} className='fa-spin' fontSize={'40px'} style={{marginTop:'30vh'}} />
    </div>} >
    </Spin>
  );
}

export default CustomSpinner;
