import { ContainerLoadingStatus, ContainerStatusEnum } from "@gtpl/shared-models/common-models";
import { SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
export class ContainerStatusInfo {
    containerNo: string;
    containerStatus: ContainerStatusEnum;
    saleOrder: SaleOrderDropDownDto;
    loadingStatus: ContainerLoadingStatus;
    createdUser?: string;
    updatedUser?: string;
    containerId?: number;
    saleOrderId?: number;
    saleOrderNumber?: string;
    referenceNumber?: string;
    scac?: string;
    poNo?: string;
    buyerPoNo?: string;
    customer?: string;
    etd?: Date;
    endCustomer?: string;
    destination?: string;
    grossWeight?: boolean;
    plant?: string;
    plantId?: number;
    filePath?: any[];
    isInvoiced?: string;
    isGrossWeightUpdated?: boolean;
    fgQty?:number;
    poQty?:number;
    stockId?:number;



    /**
     * 
     * @param containerNo 
     * @param containerStatus 
     * @param saleOrder 
     * @param loadingStatus 
     * @param containerId 
     */
    constructor(containerNo: string, containerStatus: ContainerStatusEnum, saleOrder: SaleOrderDropDownDto, loadingStatus: ContainerLoadingStatus, createdUser?: string, containerId?: number, updatedUser?: string, saleOrderNumber?: string, referenceNumber?: string, scac?: string, poNo?: string,
        buyerPoNo?: string,
        customer?: string,
        etd?: Date, endCustomer?: string,
        destination?: string, grossWeight?: boolean, plant?: string, plantId?: number, filePath?: any[], isInvoiced?: string, isGrossWeightUpdated?:boolean,fgQty?:number,poQty?:number,stockId?:number) {
        this.containerNo = containerNo;
        this.containerStatus = containerStatus;
        this.saleOrder = saleOrder;
        this.loadingStatus = loadingStatus;
        this.createdUser = createdUser;
        this.containerId = containerId;
        this.updatedUser = updatedUser;
        this.saleOrderNumber = saleOrderNumber;
        this.referenceNumber = referenceNumber;
        this.scac = scac;
        this.poNo = poNo;
        this.buyerPoNo = buyerPoNo;
        this.customer = customer;
        this.etd = etd;
        this.endCustomer = endCustomer;
        this.destination = destination;
        this.grossWeight = grossWeight;
        this.plant = plant;
        this.plantId = plantId;
        this.filePath = filePath;
        this.isInvoiced = isInvoiced;
        this.isGrossWeightUpdated = isGrossWeightUpdated;
        this.fgQty = fgQty;
        this.poQty = poQty;
        this.stockId = stockId;
    }

}

