import axios from 'axios';
import connection from './connection';
import { RmIndentQtyLimitResponse,RmIndentQtyLimitDto,AllRmIndentQtyLimitResponse} from '@gtpl/shared-models/raw-material-procurement';


export class RmIndentQtyLimitService {

    URL = connection.DEPLOY_URL + '/rm-indent-qty-limit';


    async create(dto: RmIndentQtyLimitDto): Promise<RmIndentQtyLimitResponse> {
        console.log('dto',dto)
        return await axios.post(this.URL + '/createRmIndentQtyLimit', dto)
            .then(res => {
                return res.data
            })
    }


    async update(dto: RmIndentQtyLimitDto): Promise<RmIndentQtyLimitResponse> {
        return await axios.post(this.URL + '/updateRmIndentQtyLimit', dto)
            .then(res => {
                return res.data
            })
    }


    async activatedeActivate(Dto: RmIndentQtyLimitDto): Promise<RmIndentQtyLimitResponse> {
        console.log("activate")
        return await axios.post(this.URL + '/activateOrDeactivateRmIndentQtyLimit', Dto)
            .then(res => {
                return res.data
            })
    }



    async getAll(): Promise<AllRmIndentQtyLimitResponse> {
        return await axios.post(this.URL + '/getAllRmIndentQtyLimit')
            .then(res => {
                return res.data
            })
    }

}