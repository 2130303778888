export class OverTimeCategoryDto{
    overTimeCategoryId?: number;
    overTimeCategoryName: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;

}

export const OverTimeCategoryDtoDefault: OverTimeCategoryDto = {
    overTimeCategoryName: '',
    isActive: true,
    createdAt: new Date(),
    createdUser: '0',
    updatedAt: new Date(),
    updatedUser: '0',
    versionFlag: 1
}