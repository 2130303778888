import { TemperatureLogEnum } from "@gtpl/shared-models/common-models";
export class TemperaturesModel {
    hour: number;
    temperature: number;
    uom: TemperatureLogEnum;
    constructor(hour: number, temperature: number, uom: TemperatureLogEnum) {
        this.hour = hour;
        this.temperature = temperature;
        this.uom = uom;
    }
}