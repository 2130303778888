export class MasterInfoModel {
    supplierName: string;
    supplierCode: string;
    farmerName: string;
    farmerCode: string;
    productCode: string;
    leaderName1: string;
    leaderName2: string;
    address?:string;
    mobileNumber?:string;
    indentByName?:string;

    /**
     * 
     * @param supplierName 
     * @param supplierCode 
     * @param farmerName 
     * @param farmerCode 
     * @param productCode 
     * @param leaderName1 
     * @param leaderName2 
     */
    constructor(supplierName: string,supplierCode: string,farmerName: string,farmerCode: string,productCode: string,leaderName1: string,leaderName2: string,address?:string,mobileNumber?:string,indentByName?:string) {
        this.supplierName = supplierName,
        this.supplierCode = supplierCode;
        this.farmerName = farmerName,
        this.farmerCode = farmerCode;
        this.productCode = productCode;
        this.leaderName1 = leaderName1;
        this.leaderName2 = leaderName2;
        this.address = address;
        this.mobileNumber = mobileNumber;
        this.indentByName = indentByName;
    }
}


