export class UpdateVehStatusRequest{
    indentId: number;
    vehicleAssigned: boolean;
   
    /**
     * 
     * @param indentId 
     * @param vehicleAssigned 
     */
    constructor( indentId: number,
        vehicleAssigned: boolean){
        this.indentId = indentId;
        this.vehicleAssigned = vehicleAssigned;
    }
}