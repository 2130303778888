export class QualificationsDto{
    qualificationId?: number;
    qualificationName: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;
}

export const QualificationsDtoDefault: QualificationsDto = {
    qualificationName: '',
    isActive: true,
    createdAt: new Date(),
    createdUser: '0',
    updatedAt: new Date(),
    updatedUser: '0',
    versionFlag: 1
};