import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto } from "@gtpl/shared-models/masters";
import { StoreConsumptionStatus } from "./store-consumption.enum";

export class GetStoreConsumptionItemsDto {
    storeConsumptionItemsId: number;
    storeConsumptionId: number;
    itemCategoryDetails:ItemCategoryDropDownDto;
    itemDetails:ItemsDropDownDto;
    quantity:number;
    status: StoreConsumptionStatus;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    
   /**
    * 
    * @param storeConsumptionItemsId 
    * @param storeConsumptionId 
    * @param itemCategoryDetails 
    * @param itemDetails 
    * @param quantity 
    * @param status 
    * @param createdAt 
    * @param createdUser 
    * @param updatedAt 
    * @param updatedUser 
    * @param versionFlag 
    */
    constructor(storeConsumptionItemsId: number, storeConsumptionId: number, itemCategoryDetails:ItemCategoryDropDownDto,
        itemDetails:ItemsDropDownDto, quantity:number, status: StoreConsumptionStatus, isActive: boolean, createdAt: Date, createdUser: string, updatedAt: Date, updatedUser: string, versionFlag: number){ 
            this.storeConsumptionId = storeConsumptionId
            this.storeConsumptionItemsId = storeConsumptionItemsId
            this.itemCategoryDetails = itemCategoryDetails
            this.itemDetails = itemDetails
            this.quantity = quantity
            this.status = status
            this.createdAt = createdAt
            this.createdUser = createdUser
            this.updatedAt = updatedAt
            this.updatedUser = updatedUser
            this.versionFlag = versionFlag
            this.isActive = isActive
        }
    }
