import React from 'react';

import './rm-dashboard.css';
import {
  Divider,
  Table,
  Typography,
  Popconfirm,
  Card,
  Tooltip,
  Switch,
  Input,
  Button,
  Tag,
  Row,
  Col,
  Drawer,
  Tabs,
  Spin,
  Empty,
  Form,
  Select,
  InputNumber,
  Modal,
  Badge,
} from 'antd';

/* eslint-disable-next-line */
export interface RmDashboardProps {}

export function RmDashboard(
  props: RmDashboardProps
) {
  return (
    <>
    <Card
      title={<span style={{ color: 'white' }}>RM Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >

    </Card>
    </>
  );
}

export default RmDashboard;
