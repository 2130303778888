export class PalletLocationUpdateRequest {
  stockId:number;
  coldStorage:number;
  location:number;//rackposition

  warehouseIncharge:string;

  constructor(stockId: number,coldStorage:number, location: number,warehouseIncharge:string) {
      this.stockId = stockId;
      this.location = location;
      this.coldStorage = coldStorage;
      this.warehouseIncharge= warehouseIncharge;
  }

}