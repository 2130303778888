import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Card, Cascader, Col, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch, Table, Tag, TimePicker, TreeSelect } from 'antd';
import './deheading-sample-test-form.css';
import { ApprovalInput, ConditionInput } from '@gtpl/shared-models/common-models';
import { DeheadingSampleService } from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DeheadingQaQcDto, DeHeadingSampleDto, InProgressBeHeadingDto, LogIdRequest } from '@gtpl/shared-models/production-management';
/* eslint-disable-next-line */
export interface DeheadingSampleTestFormProps {
  deHeadingInfo: InProgressBeHeadingDto;
  handleCancel: () => void;
}

export function DeheadingSampleTestForm(
  props: DeheadingSampleTestFormProps
) {
  useEffect(() => {
    getSamplesInfoByLogId();
  }, [])
  const [deHeadingSample] = Form.useForm();
  const [deHeadingInfo] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [deheadingSamples, setDeheadingSamples] = useState<DeheadingQaQcDto>(undefined);
  const sampleService = new DeheadingSampleService();

  const columns = [
    {
      title: 'Sample',
      dataIndex: 'sampleNo',
      key: 'sampleNo',
    },
    // {
    //   title: 'Variety',
    //   dataIndex: 'variety',
    //   key: 'variety',
    // },
    // {
    //   title: 'Batch No',
    //   dataIndex: 'batchNo',
    //   key: 'batchNo',
    // },
    {
      title: 'Temperature',
      dataIndex: 'temperature',
      key: 'temperature',
    },
    {
      title: 'Discoloration',
      dataIndex: 'discoloration',
      key: 'discoloration',
    },
    {
      title: 'Deterioration',
      dataIndex: 'deterioration',
      key: 'deterioration',
    },
    {
      title: 'Soft Tail',
      dataIndex: 'softTail',
      key: 'softTail',
    },
    {
      title: 'Black Tail/Spot',
      dataIndex: 'blackTail',
      key: 'blackTail',
    },
    {
      title: 'Broken/Un Usable',
      dataIndex: 'brokenOrUnUsable',
      key: 'brokenOrUnUsable',
    },
    {
      title: 'Improper Deheading',
      dataIndex: 'improperDeheading',
      key: 'improperDeheading',
    },
    {
      title: 'Brown Meat',
      dataIndex: 'brownMeat',
      key: 'brownMeat',
    },
    {
      title: 'Hanging Meat Or Throat',
      dataIndex: 'hangingMeatOrThroat',
      key: 'hangingMeatOrThroat',
    },
  ];

  const getSamplesInfoByLogId = () => {
    const req = new LogIdRequest(props.deHeadingInfo.productionLogId);
    sampleService.getSamplesInfoByLogId(req).then(res => {
      if (res.status) {
        setDeheadingSamples(res.data);
        deHeadingSample.setFieldsValue({
          sampleNo: res.data.deheadingSampleInfo.length + 1
        })
      } else {
        setDeheadingSamples(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const saveDeheadingSample = () => {
    deHeadingInfo.validateFields().then(deHead => {
      deHeadingSample.validateFields().then(deHeadSample => {
        deHead.deheadingQAQCId = deheadingSamples?.deheadingQAQCId;
        deHead.hOnQty = props.deHeadingInfo.quantity;
        deHead.hLessQty = props.deHeadingInfo.physicalQuantity;
        deHead.logId = props.deHeadingInfo.productionLogId;
        deHead.varietyOfMaterial = props.deHeadingInfo.variety;
        deHead.jobNo = props.deHeadingInfo.jobNumber;
        deHead.batchNo = props.deHeadingInfo.lotNumber;
        deHead.plantId = Number(localStorage.getItem('unit_id'));
        deHead.createdUser = localStorage.getItem('createdUser');
        deHead.updatedUser = localStorage.getItem('createdUser');
        deHead.deheadingSampleInfo = (deheadingSamples?.deheadingSampleInfo.length) ? [...deheadingSamples?.deheadingSampleInfo, deHeadSample] : [deHeadSample];
        sampleService.CreateSampleInfo(deHead).then(res => {
          if (res.status) {
            getSamplesInfoByLogId();
            AlertMessages.getSuccessMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      })
    });
    
  }



  return (
    <><Card size="small" title={<span style={{ color: 'white' }}>Deheading WIP</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

      <Form
        layout="vertical"
        form={deHeadingInfo}
      >
        {/* <Row gutter={24}> */}
        
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Batch No/Lot No">
              {props.deHeadingInfo.lotNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Job Number">
              {props.deHeadingInfo.jobNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Head On Qty">
              {props.deHeadingInfo.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Head Less Qty">
              {Number(props.deHeadingInfo.physicalQuantity)}
            </Descriptions.Item>
            <Descriptions.Item label="Variety">
              {props.deHeadingInfo.varietyOfMaterial}
            </Descriptions.Item>
          </Descriptions>
          
        {/* </Row> */}
      </Form>
    </Card>

      <Card size="small" title={<span style={{ color: 'white' }}>Deheading Sample</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Form
          layout="vertical"
          form={deHeadingSample}
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"sampleNo"}
                label="Sample No."
                rules={[
                  {
                    required: true,
                    message: `Missing Sample No.`,
                  },
                ]}
                initialValue={dataSource.length + 1}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"temperature"}
                label="Temperature(<4)"
                rules={[
                  {
                    required: true,
                    message: `Missing Temperature`,
                  },
                ]}
              >
                <InputNumber min={0} max={4} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"discoloration"}
                label="Discoloration"
                rules={[
                  {
                    required: true,
                    message: `Missing Discoloration`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"deterioration"}
                label="Deterioration"
                rules={[
                  {
                    required: true,
                    message: `Missing Deterioration`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"softTail"}
                label="Soft Tail"
                rules={[
                  {
                    required: true,
                    message: `Missing Soft Tail`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"blackTail"}
                label="Black Tail/Spot"
                rules={[
                  {
                    required: true,
                    message: `Missing Black Tail/Spot`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"brokenOrUnUsable"}
                label="Broken/Un Usable"
                rules={[
                  {
                    required: true,
                    message: `Missing Broken/Un Usable`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"improperDeheading"}
                label="Improper Deheading"
                rules={[
                  {
                    required: true,
                    message: `Missing Improper Deheading`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"brownMeat"}
                label="Brown Meat"
                rules={[
                  {
                    required: true,
                    message: `Missing Brown Meat`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name={"hangingMeatOrThroat"}
                label="Hanging Meat or Throat"
                rules={[
                  {
                    required: true,
                    message: `Missing Brown Meat`,
                  },
                ]}
                initialValue={ConditionInput[3].value}
              >
                <AutoComplete
                  style={{ width: "100%" }}
                  options={ConditionInput}
                  placeholder="Please Enter"
                  filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <h3 style={{ color: 'blue' }} >*Note: &ensp; Sample tested for every 1000 kgs each       N - Nil     P-pieces</h3>
            </Col>
            <Col >
              <Button type='primary' onClick={saveDeheadingSample} disabled={btnDisable}  >
                Submit
              </Button>
              <Button onClick={props.handleCancel} disabled={btnDisable}>
                Back
              </Button>
            </Col>
          </Row>
          <Row gutter={24}>
            <Table dataSource={deheadingSamples?.deheadingSampleInfo ?? []} columns={columns} scroll={{ x: true }} />
          </Row>
        </Form>
      </Card >
    </>
  );
}

export default DeheadingSampleTestForm;
