import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { QaQcData } from './qa-qc-data.dto';

export class QaQcResponse extends GlobalResponseObject{
    data?: QaQcData;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: QaQcData){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}
