export class CategorySubCategoryReq {
    itemCategoryId: number;
    itemSubCategoryId: number;
    unitId: number;
    saleOrderId?: number;

    constructor(itemCategoryId: number, itemSubCategoryId: number, unitId: number, saleOrderId?: number) {
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.unitId = unitId;
        this.saleOrderId = saleOrderId;
    }

}