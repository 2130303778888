import { PlantsEnum, SupplierTypeEnum } from "@gtpl/shared-models/common-models";
import { CommissionType, HarvestingTime } from "../enums";

export class HarvestingModelDto{
    plantId: number;
    indentDate: any;
    supplierType: SupplierTypeEnum;
    farmer: number;
    farmerCode: number;
    product: number;
    expectedCount: number;
    expectedQty: number;
    expectedPrice: number;
    commissionType: CommissionType;
    commission: number;
    harvestingDate: any;
    harvestingTime: HarvestingTime;
    assignLead1: number;
    assignLead2: number; 
    indentBy: number;
    driverId: number; 
    vehicleId: number;  
    driver: string;  
    vehicle: string;  
    indentId?: number;
    indentCode?: string;
    vehicleAssigned?: boolean;
    grnCompleted?: boolean; 
    createdUser?: string;
    createdAt?: Date;
    updatedUser?: string;
    updatedAt?: Date;
    supplierCode?: string;
    supplierName?: string;
    farmName?: string;
    farmCode?: string;
    productCode?: string;
    lead1Name?: string;
    lead2Name?: string;
    isActive?:boolean;
    versionFlag?:number;
    address?:string;
    mobileNumber?:string;

   
    /**
     * 
     * @param plantId 
     * @param indentDate 
     * @param supplierType 
     * @param farmer 
     * @param farmerCode 
     * @param product 
     * @param expectedCount 
     * @param expectedQty 
     * @param expectedPrice 
     * @param commissionType 
     * @param commission 
     * @param harvestingDate 
     * @param harvestingTime 
     * @param assignLead1 
     * @param assignLead2 
     * @param indentId
     * @param indentCode
     * @param vehicleAssigned 
     * @param grnCompleted 
     * @param createdUser 
     * @param createdAt 
     * @param updatedUser 
     * @param updatedAt 
     * @param supplierCode 
     * @param supplierName 
     * @param farmName 
     * @param farmCode
     * @param productCode
     * @param lead1Name
     * @param lead2Name 
     */
    constructor(plantId: number,indentDate: Date,supplierType: SupplierTypeEnum,farmer: number,farmerCode: number,
        product: number,expectedCount: number,expectedQty: number,expectedPrice: number,commissionType: CommissionType,
        commission: number,harvestingDate: Date,harvestingTime: HarvestingTime,assignLead1: number,assignLead2: number,indentBy: number,
        driverId: number,vehicleId: number, driver: string, vehicle: string,indentId: number,indentCode: string,vehicleAssigned: boolean,grnCompleted: boolean,createdUser: string,createdAt: Date,updatedUser : string,updatedAt: Date,supplierCode: string,supplierName: string,farmName: string,farmCode: string,productCode:string,lead1Name:string,lead2Name:string,isActive?:boolean,versionFlag?:number,address?:string,mobileNumber?:string){
       this.plantId = plantId;
       this.indentDate = indentDate;
       this.supplierType = supplierType;
       this.farmer = farmer;
       this.farmerCode = farmerCode;
       this.product = product;
       this.expectedCount = expectedCount;
       this.expectedQty = expectedQty;
       this.expectedPrice = expectedPrice;
       this.commissionType = commissionType;
       this.commission = commission;
       this.harvestingDate = harvestingDate;
       this.harvestingTime = harvestingTime;
       this.assignLead1 = assignLead1;
       this.assignLead2 = assignLead2;
       this.indentBy = indentBy;
       this.driverId = driverId;
       this.vehicleId = vehicleId;
       this.driver = driver;
       this.vehicle = vehicle;
       this.indentId = indentId;
       this.indentCode = indentCode;
       this.vehicleAssigned = vehicleAssigned;
       this.grnCompleted = grnCompleted;
       this.createdUser = createdUser;
       this.createdAt = createdAt;
       this.updatedUser = updatedUser;
       this.updatedAt = updatedAt;
       this.supplierCode = supplierCode;
       this.supplierName = supplierName;
       this.farmName = farmName;
       this.farmCode = farmCode;
       this.productCode = productCode;
       this.lead1Name = lead1Name;
       this.lead2Name = lead2Name;
       this.isActive = isActive;
       this.versionFlag = versionFlag;
       this.address = address;
       this.mobileNumber = mobileNumber;
    }
}