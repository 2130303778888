import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Table, Card, Input, Button, Form, Col, Row, Select, Typography, Spin, Statistic,Space, DatePicker } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import form from 'antd/lib/form';
import { FgStockTransferService } from 'libs/shared-services/warehouse-management/src/lib/fg-stock-transfer.service';
import { fgStockTransferReq } from 'libs/shared-services/warehouse-management/src/lib/fg-stock-transfer-request';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { UnitcodeService } from '@gtpl/shared-services/masters';

export interface fgStockTransferProps{

}

// useEffect(() => {
//   getUnitDropdownForStockInReport();
// }, []);


let i=1;
const excelColumns:any[]=[
  {title:'S No',dataIndex: 'sNo'},
  {title:'Transfer Type',dataIndex: 'transferType'},
  {title:'PO Number',dataIndex: 'oldPoNumber'},
  {title:'Product SKU',dataIndex: 'variantCode'},
  {title:'No Of Cartons',dataIndex: 'NoOfCartons'}

];

export function FgStockTransferReport(
    props : fgStockTransferProps
){

  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const { Option } = Select;
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [reportData, setReportData] = useState<any[]>([]);
  const [disable,setDisable]=useState<boolean>(false);
  const [transferData, setTransferData] = useState<any[]>([]);
 const fgStockTransferService = new FgStockTransferService();
 const [unitData,setunitData] = useState<any[]>([]);
const [employeeData, setEmployeeData] = useState<any[]>([]);
const fgservice = new FGStockService();
const unitsService = new UnitcodeService();
const [selectedunit, setUnit] = useState<number>(0)




console.log(form.getFieldValue('transferType'))

  // const getColumnSearchProps = (dataIndex: string) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 188, marginBottom: 8, display: 'block' }}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         icon={<SearchOutlined />}
  //         size="small"
  //         style={{ width: 90, marginRight: 8 }}
  //       >
  //         Search
  //       </Button>
  //       <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: filtered => (
  //     <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase())
  //       : false,
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) { setTimeout(() => searchInput.current.select()); }
  //   },
  //   render: text =>
  //     text ? (
  //       searchedColumn === dataIndex ? (
  //         <Highlighter
  //           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //           searchWords={[searchText]}
  //           autoEscape
  //           textToHighlight={text.toString()}
  //         />
  //       ) : text
  //     ) : null
  // });

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
 
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Transfer Type',
      dataIndex: 'purpose',
      sorter: (a, b) => a.purpose.localeCompare(b.purpose),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('purpose'),
    },
    {
      title: 'PO Number',
      dataIndex: 'po_number',
 
      sorter: (a, b) => a.po_number?.localeCompare(b.po_number),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('po_number')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variant_code',
  
      sorter: (a, b) => a.variant_code?.localeCompare(b.variant_code),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variant_code')

      
    },
    {
      title: 'No Of Cartons',
      dataIndex: 'cartons',
    
      sorter: (a, b) => a.cartons?.localeCompare(b.cartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cartons')

    },
    {
      title: 'Old Location',
      dataIndex: 'oldRackCode',
      sorter: (a, b) => a.oldRackCode?.localeCompare(b.oldRackCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('oldRackCode')

    },
    {
      title: 'New Location',
      dataIndex: 'newRackCode',
    
      sorter: (a, b) => a.newRackCode?.localeCompare(b.newRackCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('newRackCode')

    },

  ];
  const columnsSkeltonWarehouse: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
 
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Transfer Type',
      dataIndex: 'purpose',
      sorter: (a, b) => a.purpose.localeCompare(b.purpose),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('purpose'),
    },
    {
      title: 'PO Number',
      dataIndex: 'oldPoNumber',
 
      sorter: (a, b) => a.oldPoNumber?.localeCompare(b.oldPoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('oldPoNumber')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
  
      sorter: (a, b) => a.variantCode?.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),

    },
    {
      title: 'No Of Cartons',
      dataIndex: 'cartons',
    
      sorter: (a, b) => a.cartons?.localeCompare(b.cartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cartons'),

    },
    // {
    //   title: 'No Of Cartons',
    //   dataIndex: 'NoOfCartons',
    
    //   sorter: (a, b) => a.NoOfCartons?.localeCompare(b.NoOfCartons),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'No Of Cartons',
    //   dataIndex: 'NoOfCartons',
    
    //   sorter: (a, b) => a.NoOfCartons?.localeCompare(b.NoOfCartons),
    //   sortDirections: ['descend', 'ascend'],
    // },
  ];

  const columnsSkeltonPlant: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
 
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Transfer Type',
      dataIndex: 'purpose',
      sorter: (a, b) => a.purpose.localeCompare(b.purpose),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('purpose'),
    },
    {
      title: 'PO Number',
      dataIndex: 'oldPoNumber',
 
      sorter: (a, b) => a.oldPoNumber?.localeCompare(b.oldPoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('oldPoNumber')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
  
      sorter: (a, b) => a.variantCode?.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')

    },
    {
      title: 'No Of Cartons',
      dataIndex: 'cartons',
    
      sorter: (a, b) => a.cartons?.localeCompare(b.cartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cartons')

    },
    {
      title: 'Old Plant',
      dataIndex: 'oldUnitName',
    
      sorter: (a, b) => a.oldUnitName?.localeCompare(b.oldUnitName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('oldUnitName')

    },
    {
      title: 'New Plant',
      dataIndex: 'newUnitName',
    
      sorter: (a, b) => a.newUnitName?.localeCompare(b.newUnitName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('newUnitName')

    },

  ]
  const columnsSkeltonsaleorder: ColumnProps<any>[] = [
    {
      title: 'S No',
      dataIndex: 'sNo',
 
      align: 'center',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Transfer Type',
      dataIndex: 'purpose',
      sorter: (a, b) => a.purpose.localeCompare(b.purpose),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('purpose'),
    },
    
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
  
      sorter: (a, b) => a.variantCode?.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),

    },
    {
      title: 'No Of Cartons',
      dataIndex: 'cartons',
    
      sorter: (a, b) => a.cartons?.localeCompare(b.cartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cartons'),

    },
    {
      title: 'Old Customer SO',
      dataIndex: 'oldPoNumber',
      sorter: (a, b) => a.oldPoNumber?.localeCompare(b.oldPoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('oldPoNumber')
    },
    {
      title: 'New Customer SO',
      dataIndex: 'updateSoItem',
      sorter: (a, b) => a.updateSoItem?.localeCompare(b.updateSoItem),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('updateSoItem')

    },
    
  ];
  const onReset = () => {
   
    form.resetFields();
    setReportData([]);
  }

  const exportExcel=()=>{
    const excel=new Excel();
    excel
      .addSheet('id')
      .addColumns(excelColumns)
      .addDataSource(transferData, { str2num: true })
      .saveAs('fg-stock-transfer-Report.xlsx');
  }

  const handleUnit = (value)=>{
     setUnit(value);
  }

  const getUnitDropdownForStockInReport = () => {
    fgservice.getUnitDropdownForStockInReport().then((res) => {
        if (res.status) {
          setunitData(res.data);
        } else {
          setunitData(res.data);
        }
    }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
    })
}

// const getAllPlants = () => {
//   unitsService.getAllMainPlants().then((res) => {
//       if (res.status) {
//           if (unitId == 5) {
//               setunitData(res.data)
//           } else {
//               setunitData(res.data.filter(i => i.plantId == unitId))
//           }
//       } else {
//           setunitData([]);
//       }
//   }).catch(err => {
//       AlertMessages.getErrorMessage(err.message);
//       setunitData([]);
//   })
// }

useEffect(() =>{
  getUnitDropdownForStockInReport();
  if (Number(localStorage.getItem('unit_id')) != 5) {
    form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
}
gettransferReportData();
  // getAllPlants();
},[])
  const gettransferReportData = () => {
    const transferType = form.getFieldValue('purpose')
    const Date = form.getFieldValue('date')
    const unitId = form.getFieldValue('unit_id');
    const req = new fgStockTransferReq();
    req.Date = Date;
    req.transferType = transferType;
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    fgStockTransferService.gettransferTypeData(req).then((res) => {
      console.log(res)
      if(res.status){
        setReportData(res.data);
      }
      else {
        if (res.intlCode) {
          setReportData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setReportData([]);
      }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setReportData([]);
      });

  }

    return (
        <Card title={<span style={{ color: 'white' }}>FG Stock Transfer Report</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}>
          <br></br>
          <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
            <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="purpose"
              label="Transfer Type"
              rules={[
                {
                  required: true,
                  message: "Enter transfer type"
                }
              ]}>
              <Select
                // onChange={handleTransferType}
                showSearch
                placeholder="select transfer type"
              >
              
                <Option value="internal">Internal</Option>
                <Option value="saleOrder">SaleOrder</Option>
                <Option value="warehouse">Warehouse</Option>
                <Option value="plant">Plant</Option>
              </Select>
            </Form.Item>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="date" label="Date" >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                // defaultValue={moment(moment().format("YYYY-MM-DD"))}
                showToday={true}
                // onChange={() => { getAdjustmentData(); }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="unitId"
                            label=" Unit"
                            rules={[
                                {
                                    required: false, message: 'Select Unit',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Unit"
                                allowClear
                                style={{ width: '100%' }}
                                onChange={handleUnit}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

                            >
                                {unitData.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} 
            onClick={() => gettransferReportData()}
            >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          </Row>
          <Row>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Card title={'Total Transactions: '+reportData.length} style={{textAlign:'left',width: 250, height:41, backgroundColor:'#bfbfbf'}}></Card>
          </Col>
              
            </Row>
          </Form>{
            form.getFieldValue('purpose') === 'internal' ? <>
             <Table
        columns={columnsSkelton}
        dataSource={reportData}
        // scroll={{ x: 1500, y: 500 }}
        bordered
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }} 
        onChange={onChange}
      />
            </> :  form.getFieldValue('purpose') === 'warehouse' ?
              <Table
              columns={columnsSkeltonWarehouse}
              dataSource={reportData}
              // scroll={{ x: 1500, y: 500 }}
              bordered
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              />
               :  form.getFieldValue('purpose') === 'plant' ?
                <Table
                columns={columnsSkeltonPlant}
                dataSource={reportData}
                // scroll={{ x: 1500, y: 500 }}
                bordered
                pagination={{
                  onChange(current) {
                    setPage(current);
                  }
                }}
                onChange={onChange}
            />: form.getFieldValue('purpose') === 'saleOrder' ?
            <Table
            columns={columnsSkeltonsaleorder}
            dataSource={reportData}
            // scroll={{ x: 1500, y: 500 }}
            bordered
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
          />: <Table
          columns={columnsSkelton}
          dataSource={reportData}
          // scroll={{ x: 1500, y: 500 }}
          bordered
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
        />
        
          }
         
        </Card>
      )
};
export default FgStockTransferReport;

// function addColumns(excelColumns: any[]) {
//   throw new Error('Function not implemented.');
// }
