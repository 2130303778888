export class RmIndentQtyLimitDto{
    rmIndentQtyLimitId : number;
    date: any;
    plannedQty: number;
    indentQty: number;
    isActive: boolean;
    createdAt : Date;
    createdUser :string ;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;

}