export class AssetRequestDto {
    requestId: number;
    requestCode: number;
    raisedBy: number;
    requestTime: Date;
    assetCategory: number;
    assetName: number;
    quantity: number;
    status: string;
    fromPlant: number; 
    toPlant: number;
    reason: string;
    isActive: boolean;
    createdAt : Date;
    createdUser: string;
    updatedAt : Date;
    updatedUser: string;
    versionFlag: number;
    /**
     * 
     * @param requestId 
     * @param requestCode
     * @param raisedBy 
     * @param requestTime 
     * @param assetCategory 
     * @param assetName 
     * @param quantity 
     * @param status 
     * @param fromPlant 
     * @param toPlant 
     * @param reason 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(requestId: number,requestCode: number,raisedBy: number,requestTime: Date,assetCategory: number,assetName: number,quantity: number,status: string,fromPlant: number, toPlant: number,reason: string,isActive: boolean,createdAt : Date,createdUser: string,updatedAt : Date,
    updatedUser: string,versionFlag: number){
               this.requestId = requestId;
               this.requestCode = requestCode;
               this.raisedBy = raisedBy;
               this.requestTime = requestTime;
               this.assetCategory = assetCategory;
               this.assetName = assetName;
               this.quantity = quantity;
               this.status = status;
               this.fromPlant = fromPlant;
               this.toPlant = toPlant;
               this.reason = reason;
               this.isActive = isActive;
               this.createdAt = createdAt;
               this.createdUser = createdUser;
               this.updatedAt = updatedAt;
               this.updatedUser = updatedUser;
               this.versionFlag = versionFlag;
    }
}