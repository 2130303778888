import { StatusEnum } from "@gtpl/shared-models/common-models";
import { ItemCategoryDropDownDto, ItemsDropDownDto } from "@gtpl/shared-models/masters";

export class StoreReturnDto{
    storeReturnId? : number;
    storeReturnDate : Date;
    saleOrderId: number;
    itemCategoryDetails?:ItemCategoryDropDownDto;
    itemDetails?:ItemsDropDownDto;
    itemCategoryId : number;
    itemId : number;
    storeReturnNo: string;
    returnQuantity: number;
    remarks: string;
    approvar: string;
    status: string;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;

   
    constructor(storeReturnDate : Date,saleOrderId: number,itemCategoryId : number,itemId:number,
    storeReturnNo: string,
    returnQuantity: number,
    remarks: string,
    approvar: string,
    status: string,
    createdAt: Date,
    createdUser: string,
    updatedAt: Date,
    updatedUser: string,
    versionFlag: number,storeReturnId? : number){
        this.storeReturnId = storeReturnId;
        this.storeReturnDate = storeReturnDate;
        this.saleOrderId= saleOrderId;
        this.itemCategoryId= itemCategoryId; 
        this.itemId= itemId; 
        this.storeReturnNo= storeReturnNo;
        this.returnQuantity= returnQuantity;
        this.remarks= remarks;
        this.approvar= approvar;
        this.status= status;
        this.createdAt= createdAt;
        this.createdUser= createdUser;
        this.updatedAt= updatedAt;
        this.updatedUser= updatedUser;
        this.versionFlag= versionFlag;
    }
}