export class EmployeeDataModel {
    employeeId: number;
    employeeCode: string;
    employeeName: string;
    designation: string;
    contractorName?: string;
    department?: string;
    shiftGroup?: string;

    /**
     * 
     * @param employeeId 
     * @param employeeCode 
     * @param employeeName 
     * @param designation 
     * @param contractorName 
     * @param department 
     * @param shiftGroup 
     */
    constructor(employeeId: number, employeeCode: string, employeeName: string, designation: string, contractorName?: string, department?: string, shiftGroup?: string) {
        this.employeeId = employeeId;
        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.designation = designation;
        this.contractorName = contractorName;
        this.department = department;
        this.shiftGroup = shiftGroup;
    }
}