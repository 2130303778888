import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Tabs, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import {Redirect, useHistory, useLocation } from 'react-router-dom';
import { Table } from "ant-table-extensions";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BeheadingService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { PrinterOutlined } from '@ant-design/icons'
import Link from 'antd/lib/typography/Link';
import './beheading-sub-plant-history.css';
import { OperationTypeEnum, SubPlantOperationStatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { InProgressBeHeadingDto } from '@gtpl/shared-models/production-management';
import { SubPlantMrnPrint } from '@gtpl/pages/production-management/production-management-components/sub-plant-mrn-print'
import { SubPlantDcPrint } from '@gtpl/pages/production-management/production-management-components/sub-plant-dc-print'
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
/* eslint-disable-next-line */
export interface BeheadingSubPlantHistoryProps {
 
 }

export function BeheadingSubPlantHistory(
  props: BeheadingSubPlantHistoryProps
) {
   const plantId = JSON.parse(localStorage.getItem('unit_id'))
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [defaultTab, setDefaultTab] = useState<string>('0');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isNewForm, setisNewForm] = useState<boolean>(true);
  const [object, setObject] = useState(null);
  const [viewOnly, setViewOnly] = useState(true);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const [saleId, setSaleId] = useState(0);
  const { TabPane } = Tabs;
  const [updateState, setUpdateState] = useState(false);
  const [coId1, setCoIdState1] = useState<number>(null);
  const [coId2, setCoIdState2] = useState<number>(null);
  const [beheadingInprogressData, setBeheadingInprogressData] = useState<InProgressBeHeadingDto[]>([]);
  const [printType, setPrintType] = useState('');
  const [selectedLogId, setSelectedLogId] = useState<number>(0);
  const [operationType, setOperationType] = useState<OperationTypeEnum>();
  const beheadingService = new BeheadingService();
  const [prodInvData, setProdInvData] = useState<InProgressBeHeadingDto[]>([])
  useEffect(() => {
    getAllBeheadingExternalPlantJobs();
  }, [])
//  const getAllBeheadingExternalPlantJobs = () =>{
//    const unitIdReq : UnitRequest ={
//      unitId :plantId
//    }
//    console.log(plantId)
//    beheadingService.getAllBeheadingExternalPlantJobs(unitIdReq).then(res =>{
//      if(res.status){
//       setProdInvData(res.data);
//       console.log(prodInvData)
//     } else {
//       if (res.intlCode) {
//           setProdInvData([]);
//           AlertMessages.getErrorMessage(res.internalMessage);
//       } else {
//        AlertMessages.getErrorMessage(res.internalMessage);
//       }
//     }
//   }).catch(err => {
//     setProdInvData([]);
//     AlertMessages.getErrorMessage(err.message);
     
//    })
//  }

  const getAllBeheadingExternalPlantJobs = () => {
    unitId :plantId
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    beheadingService.getAllBeheadingExternalPlantJobs(req).then((res) => {
      if (res.status) {
        setBeheadingInprogressData(res.data);
      } else {
        setBeheadingInprogressData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setBeheadingInprogressData([]);
    });
  }

  const history = useHistory();
  const text = <span>Set Previous Status</span>

  

  /**
* get form data 
* @param fromDoc 
* @param toDoc 
*/
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  /**
 * print modal
 */
  const printOrder = () => {
    console.log("****************************");
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };




  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log(dataIndex);
  };
  const handleCancel = () => {
    getAllBeheadingExternalPlantJobs();
    setIsModalVisible(false);
  };
  
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  // const BeheadingReceivedStatus = (soakingData:BeHeadingDto) => {
  //   soakingAssignmentService.jobCompletedStatus(beheadingData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllSoakingInventories();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'SNo',
      key: 'sno',
      width: '60px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'subPlantId',
      sorter: (a, b) => a.subPlantId.localeCompare(b.subPlantId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      responsive:['md'],
      sorter: (a, b) => a.lotNumber.length - b.lotNumber.length || a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('lotNumber'),
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.length - b.jobNumber.length || a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('jobNumber'),
    },
    // {
    //   title: 'Sale Order',
    //   dataIndex: 'saleOrder',
    //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
    //   sortDirections: ['descend', 'ascend'],
    //  ...getColumnSearchProps('saleOrderId'),
    //  render: (value, record) => 
    //  <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{value}</Link>

   
     
    // },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('saleOrderId'),
      render: (value, record) => 
      <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
   
    },
    {
      title: 'DC Number',
      dataIndex: 'dcNumber',
      sorter: (a, b) => a.dcNumber.localeCompare(b.dcNumber),
      sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('dcNumber'),
    // },

    // {
    //   title: 'Variant Code',
    //   dataIndex: 'variantCode',
     },
    {
      title: 'Issuing Quantity',
      dataIndex: 'quantity',
      render: (value, record, index) => {
        console.log(record)
        return <div style={{ textAlign: 'right' }}>{(record.quantity) ? record.quantity : '-'}</div>;
      }
      //...getColumnSearchProps('quantity),
    },

    {
      title: 'Number of Boxes',
      dataIndex: 'boxes',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'right' }}>{(record.boxes) ? record.boxes : '-'}</div>;
      }
      // ...getColumnSearchProps('boxes'),
    },
    {
      title: 'Status',
      dataIndex: 'isReceived',
      render: (isReceived, rowData) => (
        <>
          {isReceived == SubPlantOperationStatusEnum.OPEN ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{SubPlantOperationStatusEnum.OPEN}</Tag> : isReceived == SubPlantOperationStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{SubPlantOperationStatusEnum.IN_TRANSIT}</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">{SubPlantOperationStatusEnum.RECEIVED}</Tag>}
        </>
      ),
      filters: [
        {
          text: SubPlantOperationStatusEnum.OPEN,
          value: SubPlantOperationStatusEnum.OPEN,
        },
        {
          text: SubPlantOperationStatusEnum.IN_TRANSIT,
          value: SubPlantOperationStatusEnum.IN_TRANSIT,
        },
        {
          text: SubPlantOperationStatusEnum.RECEIVED,
          value: SubPlantOperationStatusEnum.RECEIVED,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isReceived === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isReceived == SubPlantOperationStatusEnum.RECEIVED) {

              } else {
                openPrint(rowData.productionLogId, 'DC', OperationTypeEnum.BE_HEADING)
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
        </span>
      )
    }
  ]

  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'SNo',
      key: 'sno',
      width: '60px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'subPlantId',
      
      sorter: (a, b) => a.subPlantId.localeCompare(b.subPlantId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      responsive:['md'],
      sorter: (a, b) => a.lotNumber.length - b.lotNumber.length || a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('lotNumber'),
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('jobNumber'),
    },
    // {
    //   title: 'Sale Order',
    //   dataIndex: 'saleOrder',
    //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrderId'),
      

    // },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('saleOrderId'),
      render: (value, record) => 
      <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
    },

    {
      title: 'MRN Number',
      dataIndex: 'mrnNumber',
      sorter: (a, b) => a.mrnNumber.localeCompare(b.mrnNumber),
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('mrnNumberId'),
    },
    // {
    //   title: 'Variant Code',
    //   dataIndex: 'variantCode',
    // },
    {
      title: 'Issued Quantity',
      dataIndex: 'quantity',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'right' }}>{(record.quantity) ? record.quantity : '-'}</div>;
      }
    },
    {
      title: 'Received Quantity',
      dataIndex: 'physicalQuantity',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'right' }}>{(record.physicalQuantity) ? record.physicalQuantity : '-'}</div>;
      }
    },

    {
      title: 'Number of Boxes',
      dataIndex: 'boxes',
      render: (value, record, index) => {
        return <div style={{ textAlign: 'right' }}>{(record.boxes) ? record.boxes : '-'}</div>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'isReceived',
      render: (isReceived, rowData) => (
        <>
          {isReceived == SubPlantOperationStatusEnum.OPEN ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{SubPlantOperationStatusEnum.OPEN}</Tag> : isReceived == SubPlantOperationStatusEnum.IN_TRANSIT ? <Tag icon={<CloseCircleOutlined />} color="#f50">{SubPlantOperationStatusEnum.IN_TRANSIT}</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">{SubPlantOperationStatusEnum.RECEIVED}</Tag>}
        </>
      ),
      filters: [
        {
          text: SubPlantOperationStatusEnum.OPEN,
          value: SubPlantOperationStatusEnum.OPEN,
        },
        {
          text: SubPlantOperationStatusEnum.IN_TRANSIT,
          value: SubPlantOperationStatusEnum.IN_TRANSIT,
        },
        {
          text: SubPlantOperationStatusEnum.RECEIVED,
          value: SubPlantOperationStatusEnum.RECEIVED,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isReceived === value;
      },
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <PrinterOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isReceived != SubPlantOperationStatusEnum.RECEIVED) {

              } else {
                openPrint(rowData.productionLogId, 'MRN', OperationTypeEnum.BE_HEADING)
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
        </span>
      )
    }

  ]

  const openPrint = (logId, printType, operationType) => {
    setSelectedLogId(logId)
    setIsModalVisible(true);
    setPrintType(printType);
    setOperationType(operationType);
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Deheading Sub Plant History</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <div>
        <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
          <TabPane
            // tab="Open Orders"
            key="1"
            tab={<span style={{ color: "#f5222d" }} >DC: {beheadingInprogressData.filter(rec => rec.isReceived != SubPlantOperationStatusEnum.RECEIVED).length}</span>}
          >

            <Table
              columns={columnsSkelton1}
              dataSource={beheadingInprogressData.filter(rec => rec.isReceived != SubPlantOperationStatusEnum.RECEIVED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              // scroll={{ y:500 }}
              size="middle"
              bordered />
            {/* } */}
          </TabPane>


          <TabPane
            // tab="Open Orders"
            key="2"
            tab={<span style={{ color: "#f5222d" }} >MRN: {beheadingInprogressData.filter(rec => rec.isReceived == SubPlantOperationStatusEnum.RECEIVED).length}</span>}
          >
            <Table
              columns={columnsSkelton2}
              dataSource={beheadingInprogressData.filter(rec => rec.isReceived == SubPlantOperationStatusEnum.RECEIVED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              // scroll={{ y:500 }}
              size="middle"
              bordered />
            {/* } */}
          </TabPane>

        </Tabs>
      </div>

      {isModalVisible ?
        <Modal
          className='print-docket-modal'
          key={'modal' + Date.now()}
          width={'80%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >

          {(printType === 'MRN') ? <SubPlantMrnPrint logId={selectedLogId} printOrder={printOrder} operationType={operationType} /> : <SubPlantDcPrint logId={selectedLogId} printOrder={printOrder} operationType={operationType} />}
        </Modal> : ""}
        {/* <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen="production"
          />
      </Modal> */}
    </Card>
  );
}

export default BeheadingSubPlantHistory;




