import axios from 'axios';
import {WorkstationDTO, WorkstationResponseModel, AllWorkstationResponseModel, WorkStationsForCategoryResponse,WorkStationCategoryReq, WorkStationIdReq, WorkStationsListResponseModel, CommonResponse} from '@gtpl/shared-models/production-management';
import connection from './connection';
import { UnitRequest } from '@gtpl/shared-models/common-models';

export class WorkstationService {
URL = connection.DEPLOY_URL + '/workstations';

  async createWorkstation(workstation): Promise<WorkstationResponseModel> {
    console.log(workstation);
      return await axios.post(this.URL + '/createWorkstation',workstation)
          .then(res => {
              return res.data
          })
  }
  async  updateWorkstation(workstation): Promise<WorkstationResponseModel> {
    return await axios.post(this.URL + '/updateWorkstation', workstation)
           .then(res => {
               return res.data
           })
}
  async getAllWorkstation(req:UnitRequest): Promise<AllWorkstationResponseModel> {
    return await axios.post(this.URL + '/getAllWorkstation',req)
        .then(res => {
            return res.data
        })     
}
    async  ActivateorDeactivateWorkstation(workstation: WorkstationDTO): Promise<AllWorkstationResponseModel> {
      console.log(workstation);
      return await axios.post(this.URL + '/ActivateorDeactivateWorkstation', workstation)
              .then(res => {
                  return res.data
              })
 }

 async  getWorkStationsForCategory(category:WorkStationCategoryReq): Promise<WorkStationsForCategoryResponse> {
  return await axios.post(this.URL + '/getWorkStationsForCategory',category)
      .then(res => {
          return res.data
      })     
}
 async  getAllWorkStationsForCategory(category:WorkStationCategoryReq): Promise<WorkStationsListResponseModel> {
  return await axios.post(this.URL + '/getAllWorkStationsForCategory',category)
      .then(res => {
          return res.data
      })     
}

async getWorkstationCapacity(workstationReq:WorkStationIdReq): Promise<WorkstationResponseModel> {
  return await axios.post(this.URL + '/getWorkStationsById',workstationReq)
      .then(res => {
          return res.data
      })     
}
 

async getCapacityForPlantAndWorkstation(requestData: WorkStationIdReq):Promise<CommonResponse>{
    return await axios.post(this.URL + '/getCapacityForPlantAndWorkstation', requestData).then(res => {
        return res.data
    })   
}


} 