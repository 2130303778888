import React, { useEffect, useState } from 'react';
import {BomParameterForm} from '@gtpl/pages/sale-management/sale-components/bom-parameter-form';
import { BomInfoForDetailView, BomPackingItemsDto, BomSODto, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO, SaleOrderDropDownDto, SaleOrderInfo, SaleOrderItemsDTO, SaleOrderItemsDto } from '@gtpl/shared-models/sale-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import {DeleteOutlined } from '@ant-design/icons';

import { Button, Card, Form, Row, Col, Select, Table, Space,Input, Layout, Descriptions } from 'antd';
import { useLocation } from 'react-router-dom';

const { Option } = Select;

export interface BomParameterDetailViewProps {
    saleOrderId:any;
}
export function BomParameterDetailView(props:BomParameterDetailViewProps){
      const saleOrderService = new SaleOrderService();
      const [bomPackingMaterialData, setBomPackingMaterialData] = useState<BomInfoForDetailView[]>([]);
      const [updateStateData, setUpdateStateData] = useState<number>(1);
      const [loading, setLoading] = useState(true)

      let location = useLocation();

      useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
        getAllBomDetailViewDataForSoId(location.state);
      }, [])

      const getAllBomDetailViewDataForSoId = (saleOrderId) => {
        const saleOrderReq = new saleOrder(saleOrderId);
        saleOrderService.getAllBomDetailViewDataForSoId(saleOrderReq).then((res) => {
            if(res.status){
              setBomPackingMaterialData(res.data);
            }
        });
      }
     //For Columns

    const getColumns = () => {
        const columns = [
          {
            title: 'Item Category',
            dataIndex: 'itemCategoryDetails.itemCategoryId',
            key: 'itemCategoryDetails.itemCategoryId',
            render: (value, record: BomInfoForDetailView, index) => {
              return (record.itemCategoryDetails) ? record.itemCategoryDetails.itemCategory : '';
            },
          },
          {
            title: 'Item',
            dataIndex: 'item.itemId',
            key: 'item.itemId',
            render: (value, record: BomInfoForDetailView, index) => {
              return (record.itemDetails) ? record.itemDetails.itemName : '';
            },
          },
          {
            title: 'Size',
            dataIndex: 'size.sizeId',
            key: 'size.sizeId',
            render: (value, record: BomInfoForDetailView, index) => {
              return (record.size) ? record.size.sizeName : '';
            },
          },
          {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
          },
        ];
        return columns;
      }
      
      return (
        <>
    
        <Layout style={{padding:10, backgroundColor:'white' , border:10}}>
        <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Bom Calculation Detail View</span>}
                  style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff'}} extra={<Button href="/#/saleorder-bom-grid" style={{color:'black'}}>Bom Calculation</Button> } >
            <br></br>
             <>
                {bomPackingMaterialData.length?<Descriptions column={2}>
                    <Descriptions.Item label="Sale Order">
                    {bomPackingMaterialData[0].saleOrderInfo.saleOrderNumber}
                    </Descriptions.Item>
                </Descriptions>:''}
             <br></br></>
             <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Item Details</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0' }}>
                  
                <Table columns={getColumns()} dataSource={bomPackingMaterialData} bordered/>
    
            </Card>
           
        </Card>
        
       </Layout>
    
        </>
      );
    }


export default BomParameterDetailView;