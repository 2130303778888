import { FgStatusEnum } from "@gtpl/shared-models/common-models";

export class SuggestionsDto {
    suggestionId?: number;
    suggestion: string;
    suggestionType: string;
    departmentId?: number;
    status : FgStatusEnum;
    remarks: string;
    createdUser: string;
    isActive: boolean;
    employeeId: number;
    employeeName?: string;
    updatedUser?: string;
    versionFlag?: number;
    departmentName?: string;
    department?: string;
    constructor(suggestionId: number, suggestion: string,suggestionsType:string,status: FgStatusEnum,remarks:string,createdUser: string, isActive: boolean,employeeId: number, updatedUser?: string, versionFlag?: number,departmentName?:string,department?:string,employeeName?: string){
        this.suggestionId = suggestionId;
        this.suggestion = suggestion;
        this.suggestionType = suggestionsType;
        this.departmentName = departmentName;
        this.department=department;
        this.status = status;
        this.remarks= remarks;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.departmentName = departmentName;
        this.employeeId = employeeId;
        this.employeeName = employeeName;

    }
}