import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { AllIndentReportDto } from "./rm-procurement-report";




export class IndentReportResponse extends GlobalResponseObject {
    data?: AllIndentReportDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /ProductDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: AllIndentReportDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
