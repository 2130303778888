import axios from 'axios';
import {EndCustomerDto,AllEndCustomersResponseModel, EndCustomersDropDownDataResponseModel, CustomerRequest, EndCustomerResponseModel, EndCustomerRequest, BrandsRequest, BrandsResponseModel, GetAllBrandsResponseModel} from '@gtpl/shared-models/masters';
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/production-management';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class EndCustomersService {
  activatedeActivate(endCustomersData: EndCustomerDto) {
    throw new Error('Method not implemented.');
  }
  URL = connection.DEPLOY_URL + '/endCustomers';

  async createEndCustomers(endCustomers:EndCustomerDto): Promise<EndCustomerResponseModel> {
    console.log(endCustomers);
    return await axios.post(this.URL + '/createEndCustomers', endCustomers)
      .then(res => {
        return res.data
      })
  }
  async  updateEndCustomers(endCustomers: EndCustomerDto): Promise<EndCustomerResponseModel> {
    return await axios.post(this.URL + '/updateEndCustomer', endCustomers)
      .then(res => {
        return res.data
      })
  }
  async getAllEndCustomersData(): Promise<AllEndCustomersResponseModel> {
    return await axios.post(this.URL + '/getAllEndCustomersData')
      .then(res => {
        return res.data
      })
  }
  async getAllEndCustomers(req?:UserRequestDto): Promise<AllEndCustomersResponseModel> {
    return await axios.post(this.URL + '/getAllEndCustomers',req)
      .then(res => {
        return res.data
      })
  }
  async ActivateorDeactivateEndCustomers(endCustomersDto: EndCustomerRequest): Promise<AllEndCustomersResponseModel> {
    console.log(endCustomersDto);
    return await axios.post(this.URL + '/activateorDeactivateendCustomers', endCustomersDto)
      .then(res => {
        return res.data
      })
  }
  async getAllActiveEndCustomers(): Promise<AllEndCustomersResponseModel> {
    return await axios.post(this.URL + '/getActiveEndCustomers').then(res => {
      return res.data
  });
}
  async getActiveEndCustomersCount(): Promise<EndCustomersDropDownDataResponseModel> {
    return await axios.post(this.URL + '/getActiveEndCustomersCount').then(res => {
      return res.data
  });
}
async getEndCustomerDataById(endCustomerRequest:EndCustomerRequest): Promise<EndCustomerResponseModel> {
  return await axios.post(this.URL + '/getCustomerDataById', endCustomerRequest).then(res => {
    // console.log(res);
      return res.data
  });
}


  async getActiveEndCustomersDropDownData() {
    return await axios.post(this.URL + '/getAllEndCustomersDropData').then(res => {
      return res.data
    });
  }
  async getBrandsById(brandRequest:BrandsRequest): Promise<BrandsResponseModel> {
    // console.log(brandRequest);
    return await axios.post(this.URL + '/getBrandsById',brandRequest).then(res => {
      console.log(res);
      return res.data
    });
  }
  async getAllBrands(): Promise<GetAllBrandsResponseModel> {
    return await axios.post(this.URL + '/getAllBrands').then(res => {
      console.log(res);
      return res.data
    });
  }

  async logoUpload(file: any) {
    console.log(this.URL)
    return await axios.post(this.URL + '/logoUpload', file)
        .then(res => {
            return res.data
        })
}
async getAllFilesData(): Promise<EndCustomerResponseModel> {
    return await axios.post(this.URL + '/getAllFiles')
        .then(res => {
            return res.data
        })
  }
async getAllActiveBrands(): Promise<GetAllBrandsResponseModel> {
    return await axios.post(this.URL + '/getAllActiveBrands')
        .then(res => {
            return res.data
        })
  }

  async getBrandsForEndCustomerId(endCustomerRequest:EndCustomerRequest): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getBrandsForEndCustomerId', endCustomerRequest).then(res => {
      // console.log(res);
        return res.data
    });
  }
  



}
