import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, Table } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CertificationsService } from '@gtpl/shared-services/masters';
import { Link, useHistory } from "react-router-dom";
import { AssetLicenseDto } from 'libs/shared-models/asset-management/src/lib/asset-license/asset-license.dto';
import { AssetLicenseService } from '@gtpl/shared-services/asset-management';
import moment from 'moment';
import { CertificatesDto } from '@gtpl/shared-models/masters';
import { ColumnProps } from 'antd/lib/table';
import { FileUploadForm } from './file-upload-form';



const { Option } = Select;
// const { TextArea } = Input;

export interface AssetLicenseFormProps {
  assetLicenseData: AssetLicenseDto;
  updateReason: (licensereason: AssetLicenseDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
};




export function AssetLicenseForm(props: AssetLicenseFormProps) {
  const [disable, setDisable] = useState<boolean>(false)
  const [form] = Form.useForm();
  const [AssetHistoryData, setAssetHistoryData] = useState<any>();
  const [certificationdata, setcertificationData] = useState<CertificatesDto[]>([]);
  const [licenseNameData, setSelecteLicenseData] = useState<AssetLicenseDto[]>([]);
  const service = new AssetLicenseService;
  const newService = new AssetLicenseService();
  const certficateService = new CertificationsService();
  let history = useHistory();
  const [page, setPage] = React.useState(1);




  useEffect(() => {
    getAllAssetsLicense();
    getAll();
    getPoDetailsData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
    if (props.isUpdate) {
      console.log(props.assetLicenseData)
    }
  }, []);



  const getAllAssetsLicense = () => {
    newService.getAllAssetsLicense().then(res => {
      if (res.status) {
        setSelecteLicenseData(res.data);
      } else {
        if (res.intlCode) {
          setSelecteLicenseData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSelecteLicenseData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const getPoDetailsData = () => {
    certficateService.getAllActiveCertifications().then((res) => {
      if (res.status) {
        setcertificationData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setcertificationData([]);
      }
    })
  }

  const getAll = () => {
    service.getAllHistoryLicense().then(res => {
      if (res.status) {
        setAssetHistoryData(res.data);
      } else {
        if (res.intlCode) {
          setAssetHistoryData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAssetHistoryData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }




  const getBase64 = (file: any) => {
    console.log(file, "Ppppppppppppp")
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader(); //FileReader is an API that allows reading the contents of a file.
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = error => reject(error);

    })
  }

  const saveData = () => {
    form.validateFields().then(async (values: AssetLicenseDto) => {

      const filesData = [];
      if (values.filesData.length) {
        for (const rec of values.filesData) {
          filesData.push({
            ...rec.file[0],
            base64: await getBase64(rec.file[0].originFileObj),
            description: rec.fileDescription
          });
        }
      }
      const req = { ...values, filesData }
      service.createAssetLicense(req).then(res => {
        if (res.status) {
          history.push("/container-log-book-grid");
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => console.log(err.message));

    }).catch(err => console.log(err.message));


  }


  const onReset = () => {
    // form.resetFields();
  };


  const getvalue = (value) => {
    console.log(value)
  }

  const historySkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'License Name',
      dataIndex: 'certificateName',
      //  responsive: ['lg'],
      sorter: (a, b) => a.certificateName.localeCompare(b.certificateName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'License Number',
      dataIndex: 'licenseNumber',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.licenseNumber).localeCompare(String(b.licenseNumber)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Reference Number',
      dataIndex: 'referenceNumber',
      //  responsive: ['lg'],
      sorter: (a, b) => a.referenceNumber - b.referenceNumber,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'License Issuer',
      dataIndex: 'company',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.company).localeCompare(String(b.company)),
    },
    {
      title: 'Responsibility',
      dataIndex: 'responsibility',
      // responsive: ['lg'],
      sorter: (a, b) => String(a.responsibility).localeCompare(String(b.responsibility)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      render: (text, record) => {
        return (
          <>
            {record.renewalExpiryDate ? moment(record.renewalExpiryDate).format('YYYY-MM-DD') : moment(record.expiryDate).format('YYYY-MM-DD')}
          </>
        )
      },
    },
    {
      title: 'Renewal Person Name',
      dataIndex: 'renewal_person_name',
      //  responsive: ['lg'],
      sorter: (a, b) => a.renewal_person_name.localeCompare(b.renewal_person_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Renewal Start Date',
      dataIndex: 'renewal_start_date',
      render: (text, record) => {
        return (
          <>
            {record.renewal_start_date ? moment(record.renewal_start_date).format('YYYY-MM-DD') : '-'}
          </>
        )
      },
    },
    {
      title: 'Renewal Expiry Date',
      dataIndex: 'renewal_expiry_date',
      render: (text, record) => {
        return (
          <>
            {record.renewal_expiry_date ? moment(record.renewal_expiry_date).format('YYYY-MM-DD') : '-'}
          </>
        )
      },
    },


  ]
  return (
    <Card title={<span style={{ color: 'white' }}>Asset License</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/asset-license-view' ><span style={{ color: 'white' }} >
        <Button className='panel_button' >View </Button>
      </span></Link>} >
      <Form form={form}
        initialValues={props.assetLicenseData}
        name="control-hooks"
        layout="vertical"
      >

        <Form.Item name="plantId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="licenseId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser"
          initialValue={props.assetLicenseData}
        >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="licenseName"
              label="License Name"
              rules={[
                {
                  required: true,
                  message: 'License Name required'

                },
                // {
                //   pattern:/^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
                //   message: `Invalid License Name`
                // }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Name"
                // defaultValue={'101'}
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => getvalue(value)}
              >
                <Option key={'all'} value={100}>100</Option>
                {/* {certificationdata.map(dropData => {
                  return <Option key={dropData.certificateId}value={dropData.certificateId}>{dropData.certificateName}</Option>
                })} */}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="licenseNumber"
              label="License Number"
              rules={[
                {
                  required: true,
                  message: 'License Number required'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Invalid License Number`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="referenceNumber"
              label="Reference Number"
              rules={[
                {
                  required: true,
                  message: 'Reference Number required',

                },
                {
                  pattern: /^[0-9]*$/,
                  message: `Invalid Reference Number`
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }} >
            <Form.Item
              name="company"
              label="License Issuer"
            // rules={[
            //   {
            //     required: true, 
            //     message: 'company required'

            //   },
            //   {
            //     pattern:  /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z. ]*$/,
            //     message: `Invalid Rejection Reason`
            //   }
            // ]}
            >
              <Input />

            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="responsibility"
              label="Responsibility"

              rules={[
                {
                  // required: true,
                  message: "Responsibility is required"
                },
              ]}
            >
              <Select
                showSearch
                //style={{ width: 210 }}
                placeholder="Select Name"
                // optionFilterProp="children"
                allowClear
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              >
                {licenseNameData.map((licenseNameData) => {
                  return <Option key={licenseNameData.licenseId} value={licenseNameData.licenseName}>{licenseNameData.licenseName} </Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="expiryDate"
              label="Expiry Date"
              initialValue={moment
                ()}
              rules={[
                {
                  required: props.isUpdate ? true : false,
                  message: 'Enter valid date'
                }
              ]}
            >
              <DatePicker style={{ width: '100%' }}
                showToday={true} />
            </Form.Item>
          </Col>

          {props.isUpdate ?

            <Card title={<span style={{ color: 'white' }}>Asset Renewal</span>}
              style={{ textAlign: 'center', width: '934px' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
              <Row>
              
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                    name="renewalPersonName"
                    label="Renewal Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Name required"
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      //style={{ width: 210 }}
                      placeholder="Select Name"
                      // optionFilterProp="children"
                      allowClear
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    >
                      {licenseNameData.map((licenseNameData) => {
                        return <Option key={licenseNameData.licenseId} value={licenseNameData.licenseName + ' ' + licenseNameData.licenseNumber}>{licenseNameData.licenseName} {licenseNameData.licenseNumber}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                    name="renewalStartDate"
                    label="Renewal Start Date"
                    rules={[
                      {
                        // required: props.isUpdate ? true : false,
                        required: false,
                        message: 'Enter valid date'
                      }
                    ]}
                    initialValue={props.assetLicenseData.expiryDate}
                  >
                    <DatePicker style={{ width: '100%' }}
                      showToday={true} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ margin: '1%' }}>
                  <Form.Item
                    name="renewalExpiryDate"
                    label="Renewal Expiry Date"
                    rules={[
                      {
                        required: false,
                        message: 'Enter valid date'
                      }
                    ]}
                  >
                    <DatePicker style={{ width: '100%' }}
                      showToday={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Card title={<span style={{ color: 'white' }}>License History</span>}
                  style={{ textAlign: 'center', width: '934px' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                  <Table
                    rowKey={record => record.productId}
                    columns={historySkelton}
                    dataSource={AssetHistoryData}
                    pagination={{
                      onChange(current) {
                        // setPage(current);
                      }
                    }}
                    scroll={{ x: true }}
                    bordered />

                </Card>
              </Row>
            </Card>
            : <></>}

        </Row>
        <Card>
          <FileUploadForm
            maxCount={3}
            layoutType={'inline'}
            formRef={form}
            initialValues={[]}
          />
        </Card>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>

            <Button type="primary" disabled={disable} onClick={saveData} >
              Submit
            </Button>

            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>

          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AssetLicenseForm;
