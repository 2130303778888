export class PlaceListDTO {
    placeListItemsId: number;
    storeConsumptionId: number;
    itemId: number;
    issuedQty: number;
    rackPosition: string;
    rackPositionId: number;
    palletCode: string;
    // reportingCases: number;
    palletCapacity: number;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    constructor(placeListItemsId: number,storeConsumptionId: number,itemId: number,issuedQty: number,rackPosition: string,rackPositionId: number,palletCode: string,palletCapacity: number,createdAt: Date,createdUser: string ,updatedAt: Date,updatedUser: string ,versionFlag: number){
        this.placeListItemsId = placeListItemsId;
        this.storeConsumptionId = storeConsumptionId;
        this.itemId = itemId;
        this.issuedQty = issuedQty;
        this.rackPosition = rackPosition;
        this.rackPositionId = rackPositionId;
        this.palletCode = palletCode;
        this.palletCapacity = palletCapacity;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}