import { DocumentType } from "@gtpl/shared-models/common-models";

export class SaleOrderRequest {
    saleOrderId: number;
    documentCategory?: string;
    documentType?: DocumentType;
    constructor(
        saleOrderId: number,
        documentCategory?: string,
        documentType?: DocumentType,
        ) {
        this.saleOrderId = saleOrderId;
        this.documentCategory = documentCategory;
        this.documentType = documentType;
    }
    
}