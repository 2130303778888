
import React, { useEffect, useRef, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Tree, Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, DatePicker, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import Link from 'antd/lib/typography/Link';
import form from 'antd/lib/form';
import { OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { BeheadingService, LotCodeOpsService, ProductionInventoryService } from '@gtpl/shared-services/production';
import { LotCodeDropDown, ProductionInventoryDetails } from '@gtpl/shared-models/production-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ProductionLogRequest } from 'libs/shared-models/production-management/src/lib/production-inv/production-log.req';
import moment from 'moment';


const { Option } = Select;

export interface ProductionInventoryOperationsProps{}

export function ProductionInvenOPerationsGrid(props:ProductionInventoryOperationsProps){
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const { TabPane } = Tabs;
  const [searchedColumn, setSearchedColumn] = useState('');
  const[searchText, setSearchText] = useState('');
  const { RangePicker } = DatePicker;
  const [page, setPage] = React.useState(1);
  const production = new ProductionInventoryService();
  const beheadService = new BeheadingService();
  const [behead, setBeheadData] = useState<ProductionInventoryDetails[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [saleId, setSaleId] = useState(0);
const [selectedunit, setUnit] = useState<number>(0)
  const [disable,setDisable]=useState<boolean>(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const lotCodeService = new LotCodeOpsService();
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const [lotNumber, setLotNumber] = useState("");


  const handleChange = (value) => {
    // setDisplay("none");
    setLotNumber(value);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    // getBeheadingInventory();
    getProductionLogReport();
    getAllLotNumbers();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
  }
    
  }, []);

  const getAllLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new UnitRequest(plant);

    lotCodeService.getAllLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getProductionLogReport = (onReset?:boolean)=>{
    // const plant = Number(localStorage.getItem('unit_id'))
    const lotNumber = form.getFieldValue('lotNumber')
    let fromDate = onReset ? undefined : selectedEstimatedFromDate;
    let toDate = onReset ? undefined : selectedEstimatedToDate;
    const req = new ProductionLogRequest();
    req.lotNumber=lotNumber
    req.fromDate=fromDate
    req.toDate=toDate
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    production.getProductionInventoryReport(req).then((res)=>{
      if(res.status){
        setReportData(res.data);
      }else{
        setReportData([]);
      }
    }).catch((err)=>{
      AlertMessages.getErrorMessage(err.message);
      setReportData([]);
    })
  }

  const EstimatedETDDate = (value) => {
    if (value) {
      console.log(value)
      const fromDate = new Date(value[0].format('yyyy-MM-DD'));
      const toDate = new Date(value[1].format('yyyy-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
      console.log(fromDate)
    }
  }
//   const getBeheadingInventory = () => {
//     const beheadInvReq = new UnitRequest(Number(localStorage.getItem('unit_id'))
//    );
//    beheadService.getBeheadingInventoryInfo(beheadInvReq).then((res) => {
//        if (res.status) {
//         setBeheadData(res.data);          

//        } 
//        else {
//          if (res.intlCode) {
//            AlertMessages.getErrorMessage(res.internalMessage);
//          } else {
//            AlertMessages.getErrorMessage(res.internalMessage);
//          }
//        }
//      })
//      .catch((err) => {
//        AlertMessages.getErrorMessage(err.message);
//      });
//  };

    const goToSaleDetailView = (saleOrderId: number) => {
        setSaleId(saleOrderId);
        console.log("soid",saleOrderId);
        // setIsModalVisible(true)
      }
    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'Lot Code',
            dataIndex: 'lotNumber',
            sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('lotNumber'),
          },
          {
            title: 'Po Number',
          dataIndex: 'poNumber',
          },
        //   {
        //     title: 'Operation',
        //   dataIndex: 'operation',
        //   },
          
         
          {
            title: 'Input Grade',
          dataIndex: 'inputGrade',
          },
          {
            title: 'Transfer Quantity',
          dataIndex: 'intransitQuantity',
          },
              {
                title: 'Physical Quantity',
                dataIndex: 'physicalQuantity',
                // sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
                // sortDirections: ['descend', 'ascend'],
                //   ...getColumnSearchProps('poNumber'),
                // render: (value, record) => 
                // <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Link>
                },
                
                // {
                //   title: 'Planned Output Quantity',
                // dataIndex: 'plannedOutputQuantity',
                // },
                {
                  title: 'Output Grade',
                dataIndex: 'outputGrade',
                },
                
                {
                  title: 'Wastage Quantity',
                dataIndex: 'wastageQuantity',
                },
                {
                  title: 'Rejected Quantity',
                dataIndex: 'rejectedQuantity',
                },
                {
                  title: 'Wip Quantity',
                dataIndex: 'wipQuantity',
                },
                {
                  title: 'I/P Boxes',
                dataIndex: 'ipBoxes',
                },

                  {
                    title: 'O/P Boxes',
                    dataIndex: 'opBoxes',
                    // render: (value) => {
                    //   return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
                    // }
                  },
                 
    ];

    const onReset = () => {
      form.resetFields();
      setSelectedEstimatedFromDate(undefined);
      setSelectedEstimatedToDate(undefined);
        // setReportData([]);
      }
      const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
      }

    return(
        <Card
        title={<span style={{ color: 'white' }}>Operation Wise Inventory</span>}
        style={{ textAlign: 'center' }}
        headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        >
        <br />
        <Tabs type={'card'} tabPosition={'top'}>
        <TabPane key='1'
          tab={<span style={{ color: '#52c41a' }}>Deheading: {reportData.filter(res => res.operation === OperationTypeEnum.BE_HEADING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={reportData.filter(res => res.operation === OperationTypeEnum.BE_HEADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='2'
          tab={<span style={{ color: '#d4b106' }}>Grading: {reportData.filter(res => res.operation === OperationTypeEnum.GRADING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={reportData.filter(res => res.operation === OperationTypeEnum.GRADING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>

        <TabPane key='3'
          tab={<span style={{ color: '#f5222d' }}>Value Addition: {reportData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={reportData.filter(res => res.operation === OperationTypeEnum.VALUE_ADDITION)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='4'
          tab={<span style={{ color: '#08979c' }}>Soaking: {reportData.filter(res => res.operation === OperationTypeEnum.SOAKING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={reportData.filter(res => res.operation === OperationTypeEnum.SOAKING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>
        <TabPane key='5'
          tab={<span style={{ color: 'blue' }}>Freezing: {reportData.filter(res => res.operation === OperationTypeEnum.FREEZING).length} </span>}
        >
          <Table
            columns={columnsSkelton}
            dataSource={reportData.filter(res => res.operation === OperationTypeEnum.FREEZING)}
            scroll={{ x: true }}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
          />

        </TabPane>

        </Tabs>
        {/* <Row>
        <Table
            // rowKey={record => record.downtimeTrackingId}
            columns={columnsSkelton}
            dataSource={reportData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            size='small'
            
            scroll={{ y:400 }}
            bordered />
            </Row> */}
    
        </Card>
    )
}
export default ProductionInvenOPerationsGrid;

