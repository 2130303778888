import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderFewDetailsDto } from './sale-order-few-details.dto';

export class SaleOrderFewDetailsResponseModel extends GlobalResponseObject {
    data?: SaleOrderFewDetailsDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderFewDetailsDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}