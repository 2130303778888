export class CertificationsDropDownData{
    certificateId:number;
    certificateName:string;
    
    /**
     * 
     * @param certificateId 
     * @param certificateName
     * 
     */
    constructor(certificateId:number,certificateName:string){
      this.certificateId = certificateId;
      this.certificateName = certificateName;
      
    }
}