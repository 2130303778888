export class FoodTypesDto {
    foodTypeId:number;
    foodType: string;
    foodTypeCode: string;
    remarks: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    hsnCode : number;
    constructor( foodTypeId:number,
        foodType: string,
        foodTypeCode: string,
        remarks: string,
        isActive: boolean,
        createdAt : Date | any,
        createdUser : string,
        updatedAt : Date | any,
        updatedUser : string,
        versionFlag : number,
        hsnCode : number){
        this.foodTypeId = foodTypeId,
        this.foodType = foodType,
        this.foodTypeCode = foodTypeCode,
        this.remarks = remarks,
        this.isActive = isActive,
        this.createdAt  = createdAt,
        this.createdUser  = createdUser,
        this.updatedAt  = updatedAt,
        this.updatedUser  = updatedUser,
        this.versionFlag  = versionFlag,
        this.hsnCode =  hsnCode
        }

}

export const foodTypeDtoDefault : FoodTypesDto = {
    foodTypeId:0,
    foodType: "",
    foodTypeCode: "",
    remarks: "",
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1,
    hsnCode : 0
};