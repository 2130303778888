import {OperationTypeEnum,JobCompletedEnum} from "@gtpl/shared-models/common-models";
export class CaptureWorkStatusDto{
    captureWorkStatusId: number;
    operation: OperationTypeEnum;
    jobNo:string;
    soNumber: string;
    grade : number;
    boxes : number;
    jobStatus:JobCompletedEnum;
    isActive : boolean;
    createdUser : string;

    constructor(captureWorkStatusId: number,
        operation: OperationTypeEnum,
        jobNo:string,
        soNumber: string,
        grade : number,
        boxes : number,
        jobStatus:JobCompletedEnum,
        isActive : boolean,
        createdUser : string){
            this.captureWorkStatusId = captureWorkStatusId;
            this.operation = operation;
            this.jobNo = jobNo;
            this.soNumber = soNumber;
            this.grade = grade;
            this.boxes = boxes;
            this.jobStatus = jobStatus;
            this.isActive = isActive;
            this.createdUser = createdUser;
    }
}