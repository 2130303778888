import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Modal, Form } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './excess-inventory-report.css';
import { StockService } from '@gtpl/shared-services/procurement';
import form from 'antd/lib/form';
import { UnitRequest } from '@gtpl/shared-models/common-models';


/* eslint-disable-next-line */
export interface ExcessInventoryReportProps { }

export function ExcessInventoryReport(props: ExcessInventoryReportProps) {
    const [searchText, setSearchText] = useState('');
    const [form] = Form.useForm();
    const [data, setData] = useState<any>();
    const [open, setOpen] = useState(false);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [showpopup, setShowpopup] = useState<boolean>(false);
    const [excessiveReportData, setexcessiveReportData] = useState<any[]>([]);
    const service = new StockService();

    /**
   * used for column filter
   * @param dataIndex column data index
   */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    /**
     * 
     * @param selectedKeys 
     * @param confirm 
     * @param dataIndex 
     */
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    useEffect(() => {
        getExcessInventoryReport();
    }, [])

    const handleCancel = () => {
        setShowpopup(false)
    };

    const handleOk = () => {
        setShowpopup(false)
    }

    const handlesubmit = () => {
        setShowpopup(false);
    }


    const getExcessInventoryReport = () => {
        const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
        service.getExcessInventoryReport(req).then(res => {
            if (res.status) {
                setexcessiveReportData(res.data);
            } else {
                if (res.intlCode) {
                    setexcessiveReportData([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setexcessiveReportData([]);
        })
    }

    const columnDatailView: ColumnProps<any>[] = [
        {
            title: 'SO Number',
            dataIndex: 'soNumber',
            width: '100px'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '100px'
        },
    ]

    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: '#Sno',
            key: 'sno',
            width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Unit',
            dataIndex: 'unitName',
            width: '100px'
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            width: '400px',
            ...getColumnSearchProps('itemName')
            // responsive: ['lg'],
        },
        //   {
        //       title: 'PO wise allocated Qty',
        //       dataIndex: 'stockAllocated',  
        //      width:'800px',
        //   },
        {
            title: 'Inventory Stock',
            dataIndex: 'physicalQty',
            // width: '90px',
            align:'right',
            sorter: (a, b) => a.physicalQty - b.physicalQty,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Bom Required',
            dataIndex: 'requiredQty',
            // width: '90px',
            align:'right',
            sorter: (a, b) => a.requiredQty - b.requiredQty,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Excess Qty',
            dataIndex: 'excessQty',
            width: '90px',
            align:'right',
            sorter: (a, b) => a.excessQty - b.excessQty,
            sortDirections: ['descend', 'ascend'],
        },
        // {
        //     title: 'Action',
        //     render: (rowData) => (
        //         <span>
                    
        //             <Button icon={<EyeOutlined />} type="primary" shape="round" size="small" onClick={() => {
        //                 setData(rowData)
        //                 //   alert();
        //                 setShowpopup(true)
        //             }} />
        //         </span>
        //     )
        // },

    ];
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Excess inventory Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}></Card>

            <Table
                rowKey={record => record.downtimeTrackingId}
                columns={columnsSkelton}
                dataSource={excessiveReportData}
                scroll={{ x: 1000 }}
                pagination={{
                    onChange(current, pageSize) {
                        setPage(current);
                    }
                }}
                // onChange={onChange}
                size='small'
                bordered />
            <Modal
                onOk={handleOk}
                onCancel={handleCancel}
                key={Date.now()}
                visible={showpopup}
                width={'40%'}
                style={{ top: 30, alignContent: 'right' }}
            >
                <Card title={<span style={{ color: 'white' }}>Detail View</span>}
                    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}></Card>
                <Table
                    columns={columnDatailView}
                >
                </Table>
            </Modal>
        </>

    );
}

export default ExcessInventoryReport;
