import { AssetStatusEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-status.enum';

export class AssetByStatus {
    assetCode?: string;
    assetName?: string;
    status?: AssetStatusEnum;
    amcAvailable?: string;
    warrentyAvailable?: string;
    insuranceAvailable?: string;
    fromDate?: any;
    toDate?: any;
    plant?: string;
    location?: string;
    assetType?: string;
    assetCategory?: string;
    department?: string;
    plantId?: number;
    employeeId?: number;
    warrantyStartDate?: any;
    warrantyEndDate?: any;
    insuranceStartDate?: any;
    insuranceEndDate?: any;
    assetCost?: any;
    aging?: string;
    assetMode?: string;
    result?: string;
    days_between?: string;

    constructor(
        assetCode?: string,
        assetName?: string,
        status?: AssetStatusEnum,
        amcAvailable?: string,
        warrentyAvailable?: string,
        insuranceAvailable?: string,
        fromDate?: any,
        toDate?: any,
        plant?: string,
        location?: string,
        assetType?: string,
        assetCategory?: string,
        department?: string,
        plantId?: number,
        employeeId?: number,
        warrantyStartDate?: any,
        warrantyEndDate?: any,
        insuranceStartDate?: any,
        insuranceEndDate?: any,
        assetCost?: any,
        aging?: string,
        assetMode?: string,
        result?: string,
        days_between?: string,

    ) {
        this.assetCode = assetCode;
        this.assetName = assetName;
        this.status = status;
        this.amcAvailable = amcAvailable;
        this.warrentyAvailable = warrentyAvailable;
        this.insuranceAvailable = insuranceAvailable;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.plant = plant;
        this.location = location;
        this.assetType = assetType;
        this.assetCategory = assetCategory;
        this.department = department;
        this.plantId = plantId;
        this.employeeId = employeeId;
        this.warrantyStartDate = warrantyStartDate;
        this.warrantyEndDate = warrantyEndDate;
        this.insuranceStartDate = insuranceStartDate;
        this.insuranceEndDate = insuranceEndDate;
        this.assetCost = assetCost;
        this.aging = aging;
        this.assetMode = assetMode;
        this.result = result;
        this.days_between = days_between;
    }
}
export class AssetServiceRportReq {
    plant: number;
    location: number;
    assetCategory: number;
    assetCode: string;
}