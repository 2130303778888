export class ReasonsDto {
    reasonId?: number;
    reasonCode: string;
    reasonName: string;
    isActive: boolean;
   createdUser :string ;
   updatedAt : Date
   updatedUser : string;
  versionFlag : number;

}