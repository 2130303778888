export class RackEndCustomerMapDto{
    rackEndCustomerMapId?: number;
    rackId: number;
    zoneId: number;
    endCustomerId: number;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag: number;

    /**
     * 
     * @param rackEndCustomerMapId
     * @param rackId
     * @param zoneId
     * @param endCustomerId
     * @param createdUser
     * @param isActive
     */

    constructor(rackEndCustomerMapId: number, rackId: number, zoneId: number, endCustomerId: number, createdUser: string, updatedUser: string, isActive: boolean, versionFalg: number){
        this.rackEndCustomerMapId = rackEndCustomerMapId;
        this.rackId = rackId;
        this.zoneId = zoneId;
        this.endCustomerId = endCustomerId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFalg;
    }
}