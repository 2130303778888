import axios from 'axios';
import { EducationDto, AllEducationResponseModel, EducationResponseModel, EducationIdRequest } from '@gtpl/shared-models/hrms';
import connection from './connection';

export class EducationService{

    URL = connection.DEPLOY_URL + '/education';

    async createEducation(dto: EducationDto): Promise<EducationResponseModel>{
        return await axios.post(this.URL+ '/createEducation', dto)
            .then(res => {
                return res.data
            })
    }

    async updateEducation(dto: EducationDto): Promise<EducationResponseModel>{
        return await axios.post(this.URL+ '/updateEducation', dto)
            .then(res => {
                return res.data
            })
    }

    async getAllEducations(): Promise<AllEducationResponseModel>{
        // const data = [
        //     {
        //         educationId: 1,
        //         education: 'B.Tech.',
        //         isActive: true,
        //         createdAt: new Date(),
        //         createdUser: '',
        //         updatedAt: new Date(),
        //         updatedUser: '',
        //         versionFlag: 1,
        //     },
        //     {
        //         educationId: 2,
        //         education: 'B.E.',
        //         isActive: false,
        //         createdAt: new Date(),
        //         createdUser: '',
        //         updatedAt: new Date(),
        //         updatedUser: '',
        //         versionFlag: 1,
        //     }
        // ];
        // return new AllEducationResponseModel(true, 11103, 'Educations Retrieved Successfully', data);
        return await axios.post(this.URL+ '/getAllEducations')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveEducations(): Promise<AllEducationResponseModel>{
        return await axios.post(this.URL+ '/getAllActiveEducations')
            .then(res => {
                return res.data
            })
    }

    async getEducationDetailsById(req: EducationIdRequest): Promise<EducationResponseModel>{
        return await axios.post(this.URL+ '/getEducationDetailsById')
            .then(res => {
                return res.data
            })
    }

    async activeOrDeactivate(dto: EducationDto): Promise<EducationResponseModel>{
        return await axios.post(this.URL+ '/activateOrDeactivate', dto)
            .then(res => {
                return res.data
            })
    }
}