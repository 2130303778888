import { TaxesDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllTaxesResponseModel extends GlobalResponseObject {
    data?:  TaxesDto[];

    constructor(status: boolean, errorCode: number, internalMessage: string, data?: TaxesDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

