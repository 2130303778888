import { JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class JobCompletedStatusForCaptureWorkDto{
    captureWorkStatusId:number;
    jobStatus?: JobCompletedEnum;
    
    constructor(captureWorkStatusId:number,jobStatus?:JobCompletedEnum, assignFreezing?: JobCompletedEnum){
        this.captureWorkStatusId = captureWorkStatusId;
        this.jobStatus = jobStatus;
    }
}