import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { OperationTypeEnum, PlantsEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { SoakingAssignmentService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LogDetailsResponseModelDto, LotCodeDropDown, LotDetailsResponseModel, LotInformationRequest, LotNoInfo, LotNumberRequest, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import {SaleOrderService} from '@gtpl/shared-services/sale-management'

import './soaking-reporting.css';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
const { Option } = Select;

/* eslint-disable-next-line */
export interface SoakingReportingProps {}

export function SoakingReporting(
  props: SoakingReportingProps
) {

  const [assignSoakingReportingForm] = Form.useForm();
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(WorkStationTypeEnum.INTERNAL);
  const [lotNoInfo, setLotNoInfo] = useState<LogDetailsResponseModelDto>(undefined);
  const plantId =Number(localStorage.getItem('unit_id'));
  const soakingAssignmentService = new SoakingAssignmentService();
  const saleOrderService = new SaleOrderService();
  const history = useHistory();
  let location = useLocation();
  console.log(location.state);
  let logId = location.state;
  useEffect(() => {
    getLogDetails(logId);
  }, [])
  
  const getLogDetails = (logId) => {
    soakingAssignmentService.getLogDetailsById({productionLogid:logId}).then((res) => {
      if (res.status) {
        setLotNoInfo(res.data)
        console.log(res.data);
        assignSoakingReportingForm.setFieldsValue({
          productionInventoryId:res.data.productionLogId,
          workstationId:res.data.workStationId,
          workstation:res.data.workStation,
          subLotNumber:res.data.lotNumber,
          saleOrderId:res.data.saleOrderId,
          saleOrder:res.data.saleOrderNo,
          assignedTime:moment(res.data.assignedTime).format('YYYY-MM-DD HH:mm:ss'),
          inputGrade:res.data.inputGrade,
          issuingQuantity:res.data.issuedQuantity,
          soakingStyle:res.data.soakingStyle,
          soakingTime:res.data.soakingTime,
          saleOrderItemId:res.data.saleOrderItemId,
          reportingTime:moment().format('YYYY-MM-DD HH:mm:ss'),
        })
      } else {
        setLotNoInfo(undefined);
      }
    }).catch((err) => {
      setLotNoInfo(undefined);
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const onFinish = () => {
    const formInfo = assignSoakingReportingForm.getFieldsValue();
    formInfo.updatedUser = localStorage.getItem('createdUser');
    const startDate = moment(formInfo.assignedTime);
    const timeEnd = moment(formInfo.reportingTime);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    const soakingTimeToms = formInfo.soakingTime;
    console.log('startDate' +startDate);
    console.log('timeEnd' +timeEnd);
console.log('soakingTimeToms' +soakingTimeToms);
console.log('diffDuration' +diffDuration + diff);
console.log("Total Duration in millis:", diffDuration.asMilliseconds() , '********' , diffDuration.asMinutes());
console.log("Days:", diffDuration.days());
console.log("Hours:", diffDuration.hours());
console.log("Minutes:", diffDuration.minutes());
console.log("Seconds:", diffDuration.seconds());
if(soakingTimeToms < diffDuration.asMinutes()){
  AlertMessages.getErrorMessage("Info, Soaking time exceeded. ");
}
else if(soakingTimeToms >  diffDuration.asMinutes()){
  AlertMessages.getErrorMessage("Info, Reporting before soaking time. ");
}
    console.log(moment(formInfo.assignedTime).format('YYYY-MM-DD HH:mm:ss') + formInfo.assignedTime + 'assignedTime');
    console.log(formInfo.reportingTime + 'reportingTime');
    console.log("my data is:",formInfo);
    soakingAssignmentService.reportSoakingAssignment({productionLogId:formInfo.productionInventoryId,outputGrade:formInfo.outputGrade,workstation:formInfo.workstationId,lotNumber:formInfo.subLotNumber,outputQuantity:formInfo.outputqty,saleOrderItemId:formInfo.saleOrderItemId,plantId:plantId,inputQuantity:formInfo.issuingQuantity}).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        history.push('/soaking-assignment-grid')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  return (
    <Card title={<span style={{ color: 'white' }}>Soaking Reporting</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout={'vertical'} form={assignSoakingReportingForm} name="control-hooks">

        <Row gutter={24}>
          <Form.Item name="productionInventoryId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="reportingTime" >
          <Input defaultValue={moment().format('YYYY-MM-DD HH:mm:ss')} hidden />
          </Form.Item>
            <Form.Item name="workstationId"  >
            <Input hidden/>
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.INTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]} >
            <Input disabled/>
          </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, }} lg={{ span: 5, }} xl={{ span: 5, }}>
          <Form.Item  name="assignedTime" label="Input Time" rules={[{ required: true,message: 'Missing Input Time' }]}>
          <Input disabled/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }}>

            <Form.Item name="subLotNumber" label="Sub Lot Number" rules={[{ required: true, message: 'Missing Sub Lot No' }]}>
              <Input disabled/>
            </Form.Item>

          </Col>
          <Form.Item name="saleOrderId">
              <Input hidden/>
            </Form.Item>
          <Form.Item name="saleOrderItemId">
              <Input hidden/>
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrder" label="Sale Order No" rules={[{ required: true, message: 'Missing Sale Order No' }]}>
              <Input disabled/>
            </Form.Item>

            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
         <Form.Item name="inputGrade" label="Input Grade" rules={[{ required: true, message: 'Missing Input Grade' }]}>
         <Input disabled/>
          </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
         <Form.Item name="outputGrade" label="Output Grade" rules={[{ required: true, message: 'Missing Output Grade' }]}>
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
         <Form.Item name="outputqty" label="Output Qty" rules={[{ required: true, message: 'Missing Output Qty' }]}>
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>

          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="issuingQuantity" label="Issuing Quantity" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <Input disabled/>
            </Form.Item>

          </Col>
          <Col span={4}>
                <Form.Item name="soakingStyle" label="Soaking Style" rules={[{ required: true, message: 'Missing Soaking Style' }]}>
                  <Input disabled/>
                </Form.Item>
              </Col>
           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="soakingTime" label="Soaking Time (Min)" rules={[{ required: true, message: 'Missing Soaking Time' }]}>
              <Input disabled/>
            </Form.Item>

          </Col>
         </Row>
         <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" onClick={onFinish} htmlType="submit" >
              Submit
            </Button>
            
        </Col>

        </Row>
      </Form>
    </Card>
  );
}

export default SoakingReporting;
