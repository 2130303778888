import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip, Typography } from 'antd';
import { BarcodeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
// import './pages-asset-management-asset-management-components-mrn-grid.css';
import { ColumnProps } from 'antd/lib/table';
import { Scanner } from '../../../asset-classification-form/src/lib/Scanner';
import { EmployeeService } from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AssetLocationMappingService } from '../../../../../../shared-services/asset-management/src/lib/asset-location-mapping';
import { AssetLocationMappingDto } from '../../../../../../shared-models/asset-management/src/lib/asset-location-mapping/asset-location-mapping.dto';
import { AssetAssignmentService } from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetAssignmentDto } from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetByCode } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import Highlighter from 'react-highlight-words';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { AssetsDto } from '../../../../../../../apps/services/asset-management/src/app/open-assets/dto/open-assets-dto';
import { Redirect } from "react-router-dom";
import { AssetByAssetCode } from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
// import {AssetsService} from 'apps/services/asset-management/src/app/open-assets/open-assets.service';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
// import {DepartmentService} from 'libs/shared-services/masters/src/lib/department-service';
import { AssetByStatus } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
const { TabPane } = Tabs;
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import { AssetTypeEnum } from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const { Option } = Select;

const {Text} = Typography

/* eslint-disable-next-line */
export interface DepreciationReportProps { }

export function DepreciationReport(
  props: DepreciationReportProps
) {

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [assetsData, setAssetsData] = useState<any[]>([]);
  const [checkInData, setCheckInData] = useState<AssetAssignmentDto[]>([]);
  const [assetCodeData, setAssetCodeData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [qrModal, setQrModal] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [selectedData, setSelectedData] = useState<any>([]);
  const employeeService = new EmployeeService();
  const assetsService = new AssetService();
  const assignmentService = new AssetAssignmentService();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [previewdata, setPreviewData] = useState<AssetsDto>(null);
  const [itemdata, setItemData] = useState<any>(null);
  const [code, setCode] = useState(0)
  const [activeTab, setActiveTab] = useState('1');
  const [showpopup, setShowpopup] = useState<boolean>(false);
  const locationService = new AssetLocationService();
  const PurchasedDetailsUnused = new AssetService();
  const [locationData, setLocationData] = useState<any[]>([]);
  const plantsService = new UnitcodeService();
  const [plantName, setPlantName] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState([]);
  const itemSubCategoryService = new ItemSubCategoryService();
  // const departmentService = new DepartmentService();
  const [depart, setDepart] = useState<any[]>([]);
  const role = JSON.parse(localStorage.getItem('role'))
  const [transData, setTransData] = useState<any>([]);
  const [insurance, setInsurance] = useState<any>([]);
  const [age, setAge] = useState<any>([]);
  const [length, setLength] = useState('');



  const service = new AssetService()

  let currentamount;
  let depCharge;
  let depAmountWholeLife;
  let carryingAmount;


  useEffect(() => {

    getAllPlants();
    getItemCategory();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getAssetCode();
    getLocations();
    depreciationReport();

  }, [])


  const depreciationReport = () => {
    const req = new AssetByStatus();
    if(form.getFieldValue('plantId') != undefined){
      req.plantId = form.getFieldValue('plantId')
    } else {
      req.plantId = Number(localStorage.getItem('unit_id'))
    }
    if(form.getFieldValue('assetCode') != undefined){
      req.assetCode = form.getFieldValue('assetCode')
    } 
    if(form.getFieldValue('location') != undefined){
      req.location = form.getFieldValue('location')
    }
    if(form.getFieldValue('assetCategory') != undefined){
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if(form.getFieldValue('assetType') != undefined){
      req.assetType = form.getFieldValue('assetType')
    }
    if(form.getFieldValue('aging') != undefined){
      req.aging = form.getFieldValue('aging')
    }
    service.depreciaitionReport(req).then(res => {
      if(res.status){
        setAssetsData(res.data)
        if(res.data.length > 0){
        AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage('Data Not Found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if (res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAllPlants = () => {
    plantsService.getAllPlants().then(res => {
      if (res.status) {
        setPlantName(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getLocations = () => {
    const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if (res.status) {
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getAssetCode = () => {
    const req = new AssetByStatus();
    req.plantId = Number(localStorage.getItem('unit_id'))
    assetsService.getallAssets(req).then((res) => {
      if (res.status) {
        setAssetCodeData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

 

  const handleBack = () => {
    setModal(false)
  }


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });



  const columns: ColumnProps<any>[] = [
    {
      title: 'S.No',
      key: 'sno',
      width: 60,
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unit_name',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.unit_name?.localeCompare(b.unit_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unit_name'),
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location_name'),
    },
    {
      title: 'Asset Mode',
      dataIndex: 'asset_mode',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.asset_mode?.localeCompare(b.asset_mode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_mode')
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.asset_type?.localeCompare(b.asset_type),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('asset_type'),
      filters: [
        {
          text: 'MOVABLE',
          value: 'movable',
        },

        {
          text: 'FIXED',
          value: 'FIXED',
        }, {
          text: 'SOFTASSETS',
          value: 'softassets',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.asset_type === value;
      },
      // ...getColumnSearchProps('asset_type'),
    },
    {
      title: 'Asset Category',
      dataIndex: 'item_sub_category',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.item_sub_category?.localeCompare(b.item_sub_category),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item_sub_category')
    },
        {
      title: 'Asset Name',
      dataIndex: 'item_name',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.item_name?.localeCompare(b.item_name),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item_name')
    },
    {
      title: "Asset Code",
      dataIndex: "assets_code",
      width: 130,
      align: 'left',
      sorter: (a, b) => a.assets_code?.localeCompare(b.assets_code),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assets_code'),
      // render:(text,record)=>{
      //   return(<>
      //   <Button type='link'onClick={() => getDetailedView(record.assetsCode)}>
      // //   {record.assetsCode}</Button>
      //   </>)
      // }
    },
    {
      title:'Model',
      dataIndex:'model',
      width:130,
      align:'left',     
      sorter: (a, b) => a.model?.localeCompare(b.model),
      sortDirections: ['descend', 'ascend'],

      ...getColumnSearchProps('model')
    },
    {
        title: "Serial Number",
        dataIndex: "asset_serialno",
        align: "left",
        width:130,
        sorter: (a, b) => a.asset_serialno?.localeCompare(b.asset_serialno),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('asset_serialno'),
        render: (text, record) => {
          return (
            <>
              {record.asset_serialno ? record.asset_serialno : '-'}
            </>
          )
        },
      },
      {
        title:'Asset Condition',
        dataIndex:'asset_condition',
        width:130,
        align:'left',
        sorter: (a, b) => a.asset_condition?.localeCompare(b.asset_condition),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('asset_condition')
      },
      {
        title:'Status',
        dataIndex:'status',
        width:130,
        align:'left',
        sorter: (a, b) => a.status?.localeCompare(b.status),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('status')
      },
    {
      title: "Purchased Date",
      dataIndex: "date_of_purchased",
      align: 'right',
      width: 130,
      sorter: (a, b) => a.date_of_purchased?.localeCompare(b.date_of_purchased),
        sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <>
            {record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : ' -'}
          </>
        )
      }
    },

    {
        title: "Purchased Amount",
        dataIndex: "asset_cost",
        align: "right",
        width:130,
        sorter: (a, b) => a.asset_cost - b.asset_cost,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('asset_cost'),
        render: (text, record) => {
          return (
            <>
              {record.asset_cost ? Number(Number(record.asset_cost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
            </>
          )
        },
    },
    {
      title: "Current Amount",
      dataIndex: "currentamount",
      align:'right',
      width:130,
      sorter: (a, b) => a.currentamount - b.currentamount,
        sortDirections: ['descend', 'ascend'],
      render:(text,record) => {
         currentamount = record.asset_cost;
        for(let i=0;i<record.yeardiff;i++){
              let amount = (currentamount/100)*5;
              currentamount = currentamount - amount;
            }
        return(
          <>
            {
                record.asset_cost ? Number(Number(currentamount).toFixed(0)).toLocaleString('en-IN') : 'N/A'
            }
          </>
        )
      }
  },
    
    {
        title: "Difference",
        dataIndex: "difference",
        align:'right',
        width:130,
          sorter: (a, b) => a.difference - b.difference,
          sortDirections: ['descend', 'ascend'],
        render:(text,record) => {
          let diff = record.asset_cost - currentamount;
          return(
            <>
            {
              record.asset_cost ? Number(Number(diff).toFixed(0)).toLocaleString('en-IN') : 'N/A'
            }
            </>
          )
        }
    },
    {
      title: 'Asset Age',
      dataIndex: 'assetAge',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.assetAge - b.assetAge,
      sortDirections: ['descend', 'ascend'],

      render: (text, record) => {
        return (
          <>{record.assetAge ? record.assetAge : '-'}</>
        )
      },
    },
    {
      title: 'Year diff',
      dataIndex: 'yeardiff',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.yeardiff - b.yeardiff,
      sortDirections: ['descend', 'ascend'],

      render: (text, record) => {
        return (
          <>{record.yeardiff ? record.yeardiff : '-'}</>
        )
      },
    },
    {
      title: 'Carrying Amount',
      dataIndex: 'carryingAmount',
      width: 130,
      align: 'right',
      render: (text, record) => {
        carryingAmount = record.asset_cost;
        if(record.yeardiff >= 5){
          for (let i=0; i<5;i++){
            let amount = (carryingAmount/100)*5;
            carryingAmount = carryingAmount - amount;
          }
        }
        return (
          <>{record.yeardiff >= 5 ? Number(Number(carryingAmount).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    {
      title: 'Dep charged upto last 5 years',
      dataIndex: 'depUpto5Years',
      width: 130,
      align: 'right',
      render: (text, record) => {
       let depCharge = record.asset_cost - carryingAmount;
        return (
          <>{record.asset_cost ? Number(Number(depCharge).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expired_date",
      align: "right",
      sorter: (a, b) => a.expired_date?.localeCompare(b.expired_date),
      sortDirections: ['descend', 'ascend'],
      width:120,
      render: (text, record) => {
        return (
          <>
            {record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : 'N/A'}
          </>
        )
      }
    },
    {
      title: 'Life as Per Co.',
      dataIndex: 'lifediff',
      width: 130,
      align: 'right',
      render: (text, record) => {
        return (
          <>{record.lifediff ? record.lifediff : '-'}</>
        )
      },
    },
    {
      title: 'Remaining Life',
      dataIndex: 'remainingLife',
      width: 130,
      align: 'right',
      render: (text, record) => {
        return (
          <>{record.remainingLife ? record.remainingLife : '-'}</>
        )
      },
    },
    {
      title:'Dep Amount Over Whole Life',
      dataIndex: 'depAmountWholeLife',
      width: 130,
      align: 'right',
      render: (text, record) => {
        depAmountWholeLife = record.asset_cost;
          for (let i=0; i< record.lifediff;i++){
            let amount = (depAmountWholeLife/100)*5;
            depAmountWholeLife = depAmountWholeLife - amount;
          }
        return (
          <>{record.lifediff  ? Number(Number(depAmountWholeLife).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    {
      title:'Rate of Dep',
      dataIndex: 'rateOfDept',
      width: 130,
      align: 'right',
      render: (text, record) => {
        return '5%'
      },
    },
    {
      title:'Slavaged Value',
      dataIndex: 'slavagedValue',
      width: 130,
      align: 'right',
      render: (text, record) => {
        return(
          <>
          {
            record.expired_date ? Number(Number(record.asset_cost-depAmountWholeLife).toFixed(0)).toLocaleString('en-IN') : record.asset_cost
          }
          </>
        )
      },
    },
    {
      title:'Date of Sale',
      dataIndex: 'assetSaleDate',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.assetSaleDate - b.assetSaleDate,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return(
          <>
          {
            record.assetSaleDate ? moment(record.assetSaleDate).format('YYYY-MM-DD') : '-'
          }
          </>
        )
      },
    },
    {
      title:'Amount of Sale',
      dataIndex: 'asset_sale_cost',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.asset_sale_cost - b.asset_sale_cost,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return(
          <>
          {
            record.asset_sale_cost ? record.asset_sale_cost : '-'
          }
          </>
        )
      },
    },
    {
      title:'Profit/Loss on Asset Sold',
      dataIndex: 'profitLoss',
      width: 130,
      align: 'right',
      sorter: (a, b) => a.profitLoss - b.profitLoss,
      sortDirections: ['descend', 'ascend'],
      render:(text,record) => {
        currentamount = record.asset_cost;
       for(let i=0;i<record.yeardiff;i++){
             let amount = (currentamount/100)*5;
             currentamount = currentamount - amount;
           }
       const profitLoss = record.asset_sale_cost - currentamount;
       const obj: any = {
        children: (<div style={{ textAlign: 'right' }}>{Number(Number(profitLoss).toFixed(0)).toLocaleString('en-IN')}</div>),
        props: {
          style: {
            background: profitLoss >= 0 ? "#38f438" : '#f4646c',
            color : profitLoss >= 0 ? 'Black' : 'white'
          }
        }
       }
       return record.asset_sale_cost ? obj : '-';
     }
    },

  ]

  const handleAssetIdScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      assetCode: value
    })

  }
  const searchhandler = () => {
    depreciationReport()
  }
  const resetHandler = () => {
    form.resetFields();
    depreciationReport()
  }

  

  const exportedData = [];
  const execlData = assetsData
  let x = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object,index) => { return x++; } },
    { title: 'Unit', dataIndex: 'unit_name', render: (text, record) => { return record.unit_name ? record.unit_name : '-' } },
    { title: 'Location', dataIndex: 'location_name', render: (text, record) => { return record.location_name ? record.location_name : '-' } },
    { title: 'Asset Mode', dataIndex: 'asset_mode', render: (text, record) => { return record.asset_mode ? record.asset_mode : '-' } },
    { title: 'Asset Type', dataIndex: 'asset_type', render: (text, record) => { return record.asset_type ? record.asset_type : '-' } },
    { title: 'Asset Category', dataIndex: 'item_sub_category', render: (text, record) => { return record.item_sub_category ? record.item_sub_category : '-' } },
    { title: 'Asset Name', dataIndex: 'item_name', render: (text, record) => { return record.item_name ? record.item_name : '-' } },
    { title: 'Asset Code', dataIndex: 'assets_code', render: (text, record) => { return record.assets_code ? record.assets_code : '-' } },
    { title: 'Serial Number', dataIndex: 'asset_serialno', render: (text, record) => { return record.asset_serialno ? record.asset_serialno : '-' } },
    { title: 'Model', dataIndex: 'model', render: (text, record) => { return record.model ? record.model : '-' } },
    { title: 'Asset Condition', dataIndex: 'asset_condition', render: (text, record) => { return record.asset_condition ? record.asset_condition : '-' } },
    {title:'Asset Status',dataIndex:'status',render:(text,record) => {return record.status ? record.status : '-'}},
    { title: 'Purchase Date', dataIndex: 'date_of_purchased', render: (text, record) => { return record.date_of_purchased ? moment(record.date_of_purchased).format('YYYY-MM-DD') : '-' } },
    {title:'Purchased Amount', dataIndex:'asset_cost',render:(text,record)=>{return record.asset_cost ? record.asset_cost : '-'}},
    {title:'Current Amount', dataIndex:'currentAmount',
      render:(text,record)=>{
        currentamount = record.asset_cost;
        for(let i=0;i<record.yeardiff;i++){
              let amount = (currentamount/100)*10;
              currentamount = currentamount - amount;
            }
            return(
              <>
                {
                    record.asset_cost ? Number(Number(currentamount).toFixed(0)).toLocaleString('en-IN') : 'N/A'
                }
              </>
            )
        }},
    { title: 'Difference', dataIndex: 'difference', 
      render: (text, record) => { 
        let diff = record.asset_cost - currentamount;
        return(
          <>
          {
            record.asset_cost ? Number(Number(diff).toFixed(0)).toLocaleString('en-IN') : 'N/A'
          }
          </>
        )
      }},
    { title: 'Asset Age (In Days)', dataIndex: 'assetAge', render: (text, record) => { return record.assetAge ? record.assetAge : '-' } },
    { title: 'Year Diff', dataIndex: 'yeardiff', render: (text, record) => { return record.yeardiff ? record.yeardiff : '-' } },
    { title: 'Carrying Amount', dataIndex: 'carryingAmount', 
      render: (text, record) => {
        carryingAmount = record.asset_cost;
        if(record.yeardiff >= 5){
          for (let i=0; i<5;i++){
            let amount = (carryingAmount/100)*5;
            carryingAmount = carryingAmount - amount;
          }
        }
        return (
          <>{record.yeardiff >= 5 ? Number(Number(carryingAmount).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    { title: 'Dep charged upto last 5 years', dataIndex: 'depUpto5Years', 
      render: (text, record) => {
        let depCharge = record.asset_cost - carryingAmount;
        return (
          <>{record.asset_cost ? Number(Number(depCharge).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    { title: 'Expiry Date', dataIndex: 'expired_date', render: (text, record) => { record.expired_date ? moment(record.expired_date).format('YYYY-MM-DD') : '-' } },
    { title: 'Life as Per Co.', dataIndex: 'lifediff', render: (text, record) => { return record.lifediff ? record.lifediff : '-' } },
    { title: 'Remaining Life', dataIndex: 'remainingLife', render: (text, record) => { return record.remainingLife ? record.remainingLife : '-' } },
    { title: 'Dep Amount Over Whole Life.', dataIndex: 'depAmountWholeLife', 
      render: (text, record) => {
        depAmountWholeLife = record.asset_cost;
          for (let i=0; i< record.lifediff;i++){
            let amount = (depAmountWholeLife/100)*5;
            depAmountWholeLife = depAmountWholeLife - amount;
          }
        return (
          <>{record.lifediff  ? Number(Number(depAmountWholeLife).toFixed(0)).toLocaleString('en-IN') : '-'}</>
        )
      },
    },
    { title: 'Rate of Dep', dataIndex: 'rateOfDept', render: (text, record) => { return '5%'} },
    { title: 'Slavaged Value', dataIndex: 'slavagedValue', 
      render: (text, record) => {
        return(
          <>
          {
            record.expired_date ? Number(Number(record.asset_cost-depAmountWholeLife).toFixed(0)).toLocaleString('en-IN') : record.asset_cost
          }
          </>
        )
      },
    },


  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Depreciation-report')
      .addColumns(data)
      .addDataSource(assetsData, { str2num: true })
      .saveAs('DepreciationReport.xlsx');
  }


  return (

    <Card title={<span style={{ color: 'white' }}> Depreciation Report </span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      // extra={<Button onClick={() => { exportExcel() }}>Get Excel</Button>}
      extra={assetsData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}>
            <Form.Item name="assetCode"
                label="Asset Code">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder='Asset Code'
                  // onChange={onCodeChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assetCodeData.map((assets) => {
                    return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.assetsName} -- ${assets.assetsCode}`}</Option>
                  })

                  }
                </Select>
              </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label='Asset Category' name='assetCategory' >
              <Select
                // defaultValue={'all'}
                placeholder='All Categories'
                showSearch
                //onChange={getItems}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear>
                <Option key={'all'} value={'all'}>All Categories</Option>
                {itemCategory.map((items) => {
                  return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Unit' name='plantId'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                placeholder='All Units'
                showSearch
                allowClear>
                <Option key={'all'} value={'all'}>All Units</Option>
                {plantName.map((plant) => {
                  return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Location' name='location'>
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // defaultValue={'All'}
                placeholder='All Locations'
                showSearch
                allowClear>
                <Option key={'all'} value={'All'}>All Locations</Option>
                {locationData.map((location) => {
                  return (
                    <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label='Asset Type' name='assetType'>
              <Select
                placeholder='asset_type'
                showSearch
                defaultValue={'MOVABLE'}
                allowClear
              >
                <Option key='All' value=''>All</Option>
                {Object.values(AssetTypeEnum).map((i) => {
                  return (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Aging' name='aging'>
              <Select
                placeholder='aging'
                showSearch
                defaultValue={'All'}
                allowClear
              >
                <Option key='All' value=''>All</Option>
                <Option key='below 50' value='50'>below 50 days</Option>
                <Option key='51-300' value='51-300'>51-300 days</Option>
                <Option key='301-900' value='301-900'>301-900 days </Option>
                <Option key='901-1500' value='901-1500'>901-1500 days</Option>
                <Option key='1501-2125' value='1501-2125'>1501-2125 days</Option>
                <Option key='2125-3000' value='2125-3000'>2125-3000 days</Option>
                <Option key='3000-3600' value='3000-3600'>3000-3600 days</Option>
                <Option key='3600-4200' value='3600-4200'>3600-4200 days</Option>
                <Option key='above 4200' value='above 4200'>above 4200 days</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={() => searchhandler()} icon={<SearchOutlined />}
            >
              Search
            </Button>

            <Button style={{ margin: '0 14px' }} icon={<UndoOutlined />} onClick={resetHandler}> Reset </Button>
            <Button>Total no of records :{assetsData.length}</Button>
          </Col>

        </Row>
      </Form>



            <Table columns={columns}
              dataSource={assetsData} pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }

              }} 
              scroll={{ x: 1400, y: 500 }} 
              bordered
              summary={(pageData) => {
                let totalCost = 0;
                let currentCost = 0;
                let totalDifference = 0;
                let totalCarryingAmount = 0;
                let totaldepUpto5Years = 0;
                let totaldepAmountWholeLife = 0;
                let totalslavagedValue = 0;

                pageData.forEach(({ asset_cost }) => {
                  if (Number(asset_cost)) {
                    totalCost += Number(asset_cost)
                  }
                })


                pageData.forEach(({ asset_cost,yeardiff }) => {
                  if (Number(asset_cost)) {
                    currentamount = asset_cost;
                  for(let i=0;i<yeardiff;i++){
                        let amount = (currentamount/100)*5;
                        currentamount = currentamount - amount;
                      }
                    currentCost += Number(currentamount)
                  }
                  let difference = asset_cost - currentamount
                    totalDifference += Number(difference)

                    
                    
                  
                })

                pageData.forEach(({ asset_cost,yeardiff }) => {
                  if (Number(asset_cost)) {
                    carryingAmount = asset_cost;
                    if(yeardiff >= 5){
                      for (let i=0; i<5;i++){
                        let amount = (carryingAmount/100)*5;
                        carryingAmount = carryingAmount - amount;
                      }
                      totalCarryingAmount += carryingAmount
                    }
                    let depCharge = asset_cost - carryingAmount;
                    totaldepUpto5Years += depCharge
                  }
                })

                pageData.forEach(({ asset_cost,lifediff}) => {
                  if (Number(asset_cost)) {
                    if(lifediff != null){
                      depAmountWholeLife = Number(asset_cost);
                      for (let i=0; i< lifediff;i++){
                        let amount = (depAmountWholeLife/100)*5;
                        depAmountWholeLife = depAmountWholeLife - Number(amount);
                      }
                    } else{
                      depAmountWholeLife = 0;
                    }                 }
                  totaldepAmountWholeLife += depAmountWholeLife
                  let slavagedvalue = asset_cost-depAmountWholeLife
                  totalslavagedValue += slavagedvalue
                })


                return (
                  <>
                    <Table.Summary.Row className="tableFooter">
                      <Table.Summary.Cell index={15} colSpan={12}><Text>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={17} colSpan={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={18} colSpan={1}>₹ {Number(Number(totalCost).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={19} colSpan={1}>₹ {Number(Number(currentCost).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={20} colSpan={1}>₹ {Number(Number(totalDifference).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={23} colSpan={3}>₹ {Number(Number(totalCarryingAmount).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={24} colSpan={1}>₹ {Number(Number(totaldepUpto5Years).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={24} colSpan={4}>₹ {Number(Number(totaldepAmountWholeLife).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={24} colSpan={2}>₹ {Number(Number(totalslavagedValue).toFixed(0)).toLocaleString('en-IN')}</Table.Summary.Cell>

                    </Table.Summary.Row>
                  </>
                )
              }}
              
            />
       
    </Card>


  );
}

export default DepreciationReport;
