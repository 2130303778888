
import axios from 'axios';
import connection from './connection';
import {AllItemDamageReasonsResponse,DamageReasonsDto,DamageReasonResponse}  from '@gtpl/shared-models/masters'

export class ItemDamageReasonsService{
  create(Data: DamageReasonsDto) {
    throw new Error('Method not implemented.');
  }
    URL = connection.DEPLOY_URL + '/item-damage-reasons';


    async createItemDamageReason(dto: DamageReasonsDto): Promise<DamageReasonResponse> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createItemDamageReason',dto)
            .then(res => {
                return res.data
            })
}


async  updateItemdamageReason(dto: DamageReasonsDto): Promise<DamageReasonResponse> {
    return await axios.post(this.URL + '/updateItemdamageReason', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activateOrDeactivateItemdamageReason(Dto: DamageReasonsDto): Promise<DamageReasonResponse> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivateItemdamageReason', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAllItemDamageReasons(): Promise<AllItemDamageReasonsResponse> {
                
                return await axios.post(this.URL + '/getAllItemDamageReasons')
                    .then(res => {
                        return res.data 
                    })     
            }

}