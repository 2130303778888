
export class StocktemRequest {
    saleOrderId?: number;
    itemCategoryId?: number;
    itemId: number;
    unitId?: number;
    role?: string;
    /**
     * 
     * @param itemId 
     * 
     * @param itemCategoryId 
     */
    constructor(saleOrderId: number, itemCategoryId: number, itemId: number, unitId?: number, role?: string) {
        this.saleOrderId = saleOrderId;
        this.itemCategoryId = itemCategoryId;
        this.itemId = itemId;
        this.unitId = unitId;
        this.role = role;
    }
}

