import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { PdQaqcDto } from "./pd-qaqc-dto";

export class PdQaqcResponse extends GlobalResponseObject{
    data?: PdQaqcDto;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: PdQaqcDto){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}