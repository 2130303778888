import React, { useEffect, useRef, useState } from 'react';
import { Divider,Modal, Table, Popconfirm, Card, Form, FormInstance, Tooltip, Switch, Input, Button, Tag, Row, Col, Select, Descriptions, Drawer, Typography, Tabs, DatePicker } from 'antd';
import { FreezingDto, FreezingGetDto, SoakingDto } from '@gtpl/shared-models/production-management';
import { FreezingInventoryService, SoakingInventoryService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined ,ArrowRightOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
const { Option } = Select;
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import { FgStatusEnum, JobCompletedEnum, OperationTypeEnum, ShiftsEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import './assign-soaking-to-freeze-grid.css';

/* eslint-disable-next-line */
export interface AssignSoakingToFreezeGridProps { }

export function AssignSoakingToFreezeGrid(
  props: AssignSoakingToFreezeGridProps
) {
  const plantId = JSON.parse(localStorage.getItem('unit_id'))
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [saleId, setSaleId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [soakingData, setSoakingData] = useState<SoakingDto[]>([]);
  const [freezingData, setFreezingData] = useState<FreezingGetDto[]>([]);
  let total = 0;
  const [object, setObject] = useState(null);
  const [workShitValue, setWorkShiftvalue] = useState(null);
  const [dateValue, setDateValue] = useState(moment());
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [lotNumber,setLotNumber]=useState('');
  const [renderToAssignForFreezingForm, setRenderToAssignForFreezingForm] = useState<boolean>(false);


  const soakingInventoryService = new SoakingInventoryService();
  const freezingService = new FreezingInventoryService();
  useEffect(() => {
    getAllFreezingInventories();
    getAllFreezingLogs();
  }, []);


  /**
   * 
   */
  // console.log(soakingData.length)
  const getAllFreezingLogs = () => {
    freezingService.getAssignForFreezingData({unitId:plantId}).then(res => {
      if (res.status) {
        setSoakingData(res.data);
      } else {
        if (res.intlCode) {
          setSoakingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } 
      }
    }).catch(err => {
      setSoakingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
 
  const getAllFreezingInventories = () => {
    const unitIdReq: UnitRequest = {
      unitId: plantId
    }
    // console.log(data)
    console.log(plantId)
    freezingService.getAssignForFreezingInvData(unitIdReq).then(res => {
      console.log(res)
      if (res.status) {
        setFreezingData(res.data);
      } else {
        if (res.intlCode) {
          setFreezingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setFreezingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  {
    total = Number(freezingData.length) + Number(soakingData.length)
  }
  /**
   * 
   * @param soakingData 
   */
  const soakingInventoryJobCompleted = (soakingData: SoakingDto) => {
    soakingData.shift = workShitValue;
    soakingData.assignedTime = new Date(dateValue.format());
    console.log('---- ' + workShitValue + ' ---- ' + dateValue + ' ----')
    freezingService.jobCompletedStatus(soakingData).then(res => {
      console.log(res);
      if (res.status) {
        getAllFreezingInventories();
        getAllFreezingLogs();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const assignSoakingInventoryForFreezing = (soakingData:SoakingDto) => {
  //   freezingService.assignSoakingForFreezing(soakingData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllFreezingInventories();
  //       getAllFreezingLogs();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }


  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const handleCancel = () => {
    getAllFreezingLogs();
    setIsModalVisible(false);
  };


  const setData = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.productionLogId)
    console.log(object);
  }

  const data = [
    {
      productionLogId: '1',
      jobNumber: '1',
      lotNumber: 'LOT01',
      soNumber: 'SO01',
      variantCode: 'RDTON',
      soakingStyle: 'STTP+SALT',
      soakingTime: '60 min',
      quantity: '500',
      jobStatus: JobCompletedEnum.NO,
      assignToFreezing: JobCompletedEnum.NO
    },
    {
      productionLogId: '2',
      jobNumber: '1',
      lotNumber: 'LOT01',
      soNumber: 'SO01',
      variantCode: 'RDTON',
      soakingStyle: 'STTP+SALT',
      soakingTime: '60 min',
      quantity: '500',
      jobStatus: JobCompletedEnum.NO,
      assignToFreezing: JobCompletedEnum.YES
    },
    {
      productionLogId: '3',
      jobNumber: '1',
      lotNumber: 'LOT01',
      soNumber: 'SO01',
      variantCode: 'RDTON',
      soakingStyle: 'STTP+SALT',
      soakingTime: '60 min',
      quantity: '500',
      jobStatus: JobCompletedEnum.YES,
      assignToFreezing: JobCompletedEnum.NO
    },
    {
      productionLogId: '4',
      jobNumber: '1',
      lotNumber: 'LOT01',
      soNumber: 'SO01',
      variantCode: 'RDTON',
      soakingStyle: 'STTP+SALT',
      soakingTime: '60 min',
      quantity: '500',
      jobStatus: JobCompletedEnum.YES,
      assignToFreezing: JobCompletedEnum.YES
    }
  ]
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }
  
  const renderToLot=(rowData) =>{
   
    
    setObject({rowData});
  }


  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    // {
    //   title: 'Job Number',
    //   dataIndex: 'jobNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('jobNumber')
    // },
    {
      title: 'Lot Code',
      dataIndex: 'plantandlot',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
    // }, 
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    }, {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      responsive:['md'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    }, {
      title: 'Soaking Style',
      dataIndex: 'soakingStyle',
      // responsive: ['lg'],
      sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingStyle')
    }, {
      title: 'Soaking Time (Min)',
      dataIndex: 'soakingTime',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.soakingTime - b.soakingTime,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingTime')
    }, {
      title: 'Quantity',
      dataIndex: 'inputQuantity',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.inputQuantity - b.inputQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('inputQuantity')
    },
    {
      title: 'Assign for Freezing',
      dataIndex: 'nextOperation',
      width:'200px',
      render: (text, rowData) => (
        <span>
          {(object) ?<Redirect
            to={{
              pathname: "/assign-for-freezing-form",
              state: object
            }}
          />:null}
          {rowData.inputQuantity < rowData.physicalQuantity  ? <a><Tooltip placement="top" title='Assign For Freezing'><ArrowRightOutlined style={{ color: 'Green' }} onClick={() => renderToLot(rowData)} /></Tooltip> </a> : ""}
          {/* {rowData.inputQuantity < rowData.physicalQuantity ?
            <Link to='/assign-for-freezing-form' ><Button className='panel_button'>Assign For Freezing</Button></Link>
            : ''} */}
          {/* <Divider type="vertical" /> */}
          {/* uncomment the below comments to set the detail view */}
          {/* {(object) ?<Redirect
            to={{
              pathname: "/assign-for-freezing-form",
              state: object
            }}
          />:null}
          <EyeOutlined  type="view"
            onClick={() => {
              
                setData(rowData);
              }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /> */}
        </span>
      )

    },
  ];

  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotandplantLot',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    }, 
    // {
    //   title: 'So. Number',
    //   dataIndex: 'soNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
   
    // },
     {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    }, {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      responsive:['md'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    }, {
      title: 'Soaking Style',
      dataIndex: 'soakingStyle',
      // responsive: ['lg'],
      sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingStyle')
    }, {
      title: 'Soaking Time (Min)',
      dataIndex: 'soakingTime',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.soakingTime - b.soakingTime,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingTime')
    }, {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.physicalQuantity - b.physicalQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('physicalQuantity')
    },
    {
      title: `Job Completed`,
      dataIndex: 'jobStatus',
      render: (text, rowData) => (
        <span>
          {rowData.jobStatus === "YES" ?

            <Switch size="small"
              className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={rowData.jobStatus === "YES" ? true : false}
              disabled={rowData.jobStatus === "YES" ? true : false}
            />
            : workShitValue != null && dateValue != null &&
            <Popconfirm onConfirm={e => { soakingInventoryJobCompleted(rowData); }}
              title={
                rowData.jobStatus === "YES"
                  ? 'Are you sure to make it as incompleted ?'
                  : 'Are you sure to make it as completed ?'
              }
            >
              <Switch size="small"
                className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={rowData.jobStatus === "YES" ? true : false}
                disabled={rowData.jobStatus === "YES" ? true : false}
              />

            </Popconfirm>}
          {/* <Divider type="vertical" /> */}
          {/* uncomment the below comments to set the detail view */}
          {/* {(object) ?<Redirect
            to={{
              pathname: "/soaking-material-detail-view",
              state: object
            }}
          />:null}
          <EyeOutlined  type="view"
            onClick={() => {
              
                setData(rowData);
              }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /> */}
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');
  }

  const dateChange = () => {
    console.log(form.getFieldValue('dateAndTime')
    )
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Freezing Inventory</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    //  extra={<Link to='/form' ><Button className='panel_button' >Create </Button></Link>}

    >
      <br></br>
      <Row gutter={40} >
        <Col>

          <Card title={'Total: ' + total} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        {/* <Col>
         <Card title={'Active: ' + departmentsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
        </Col>
        <Col>
         <Card title={'In-Active: ' + departmentsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
        </Col> */}
      </Row>
      <br></br>
      {/* <Descriptions column={3}>
            <Descriptions.Item label="No. of Lots Available"> */}
      {/* {data} */}
      {/* </Descriptions.Item>
            <Descriptions.Item label="Quantity to be Processed"> */}
      {/* {data} */}
      {/* </Descriptions.Item>
            <Descriptions.Item label="Soaking Inventory"> */}
      {/* {data} */}
      {/* </Descriptions.Item>
            
          </Descriptions> */}
      {/* <br/><br/> */}
      {/* <br/><br/>  */}
      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane
          key="1"
          tab={<span style={{ color: "blue" }}>Assign For Freezing: {freezingData.filter(res => res.nextOperation === OperationTypeEnum.FREEZING && res.status != FgStatusEnum.CLOSE).length}</span>}
        >
          <Card title={<span>Assign Inventory for Freezing</span>}
            style={{ textAlign: 'center', padding: 0 }} headStyle={{ border: 0 }} bodyStyle={{ padding: 0 }}
          >
            <Table
              rowKey={record => record.deptId}
              columns={columnsSkelton1}
              dataSource={freezingData.filter(res => res.nextOperation === OperationTypeEnum.FREEZING && res.status != FgStatusEnum.CLOSE)}
              scroll={{ y: 500 }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              size='small'
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;

                pageData.forEach(({ inputQuantity }) => {
                  totalQuantity += Number(inputQuantity);

                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={2} colSpan={6} ><Text >Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                    </Table.Summary.Row>

                  </>
                );
              }
              } />
          </Card>
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "green" }}>Freezing Assigned: {soakingData.filter(res => res.assignFreezing === "YES" && res.jobStatus === "NO").length}</span>}
        >
          <Card title={<span>Freezing Assigned</span>}
            style={{ textAlign: 'center', padding: 0 }} headStyle={{ border: 0 }} bodyStyle={{ padding: 0 }}
          >
            <Form autoComplete="off" layout="vertical">
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item label="Work Shift:"
                    name={'shift'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={'Note: Select to Change the Job Status'}
                  >
                    <Select
                      showSearch
                      placeholder="Select Work Shift"
                      allowClear={true}
                      onChange={setWorkShiftvalue}
                    >
                      <Option value={null}>Select Shift</Option>
                      {Object.keys(ShiftsEnum).map((type) => {
                        return <Option value={ShiftsEnum[type]}>{ShiftsEnum[type]}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label="Date & Time:"
                    name={'assignedTime'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={'Note: Select to Change the Assign time'}
                    initialValue={moment()} >
                    <DatePicker showTime onChange={setDateValue} format="YYYY-MM-DD HH:mm:ss"
                      showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={record => record.deptId}
              columns={columnsSkelton2}
              dataSource={soakingData.filter(res => res.assignFreezing === "YES" && res.jobStatus === "NO")}
              scroll={{ y: 500 }}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              size='small'
              onChange={onChange}
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;

                pageData.forEach(({ physicalQuantity }) => {
                  totalQuantity += Number(physicalQuantity);

                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={2} colSpan={7} ><Text >Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{totalQuantity}</Text></Table.Summary.Cell>
                    </Table.Summary.Row>

                  </>
                );
              }
              } />
          </Card>
        </TabPane>
      </Tabs>
      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen='production'
          />
      </Modal>
    </Card>
  );
}

export default AssignSoakingToFreezeGrid;
