import { DayStatusModel } from "./day-status.model";

export class MonthAttnDataModel {
    employeeCode: string;
    employeeName: string;
    department: string;
    contractorName: string;
    shift: string;
    workStation: string;
    dayStatus: DayStatusModel[];
    presentCount: number;
    absentCount: number;
    workFromHomeCount: number;
    leaveCount: number;
    unitName: string;
    otHours?: number;
    otShifts?: number;

    /**
     * 
     * @param employeeCode 
     * @param employeeName 
     * @param department 
     * @param contractorName 
     * @param shift 
     * @param workStation 
     * @param dayStatus 
     * @param presentCount 
     * @param absentCount 
     * @param workFromHomeCount 
     * @param leaveCount 
     * @param unitName 
     * @param otHours 
     * @param otShifts 
     */

    constructor(employeeCode: string, employeeName: string, department: string, contractorName: string, shift: string, workStation: string, dayStatus: DayStatusModel[], presentCount: number, absentCount: number, workFromHomeCount: number, leaveCount: number, unitName: string, otHours: number, otShifts: number) {

        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.department = department;
        this.contractorName = contractorName;
        this.shift = shift;
        this.workStation = workStation;
        this.dayStatus = dayStatus;
        this.presentCount = presentCount;
        this.absentCount = absentCount;
        this.workFromHomeCount = workFromHomeCount;
        this.leaveCount = leaveCount;
        this.unitName = unitName;
        this.otHours = otHours;
        this.otShifts = otShifts;

    }
}