
export class GrnQtyRequest {
    purchaseOrderId: number;
    itemCategoryId: number;
    itemId: number;
    saleOrderId?: number;
    constructor(purchaseOrderId: number,itemCategoryId: number,itemId: number,saleOrderId?: number){
        this.purchaseOrderId = purchaseOrderId
        this.itemCategoryId = itemCategoryId
        this.itemId = itemId
        this.saleOrderId = saleOrderId
    }
}