import { RowDataResponse } from "./row-data.response.dto";

export class LoadingReportResponse{
    containerLogBookId: number;
    saleOrderId: number;
    containerNo: string;
    loadingStartTime: string;
    loadingEndTime: string;
    loadingDate:Date;
    filePath: string;
    fileName: string;
    logBookItemsInfo: RowDataResponse[];
    
    constructor(containerLogBookId: number,saleOrderId: number,containerNo: string,loadingStartTime: string,loadingEndTime: string,loadingDate:Date,filePath: string,fileName: string,logBookItemsInfo: RowDataResponse[]){
        this.containerLogBookId = containerLogBookId;
        this.saleOrderId = saleOrderId;
        this.containerNo = containerNo;
        this.loadingStartTime = loadingStartTime;
        this.loadingEndTime = loadingEndTime;
        this.loadingDate = loadingDate;
        this.fileName = fileName;
        this.filePath = filePath;
        this.logBookItemsInfo = logBookItemsInfo;

    }
}