import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ShippingTermsDto } from './shipping-terms-dto';

export class ShippingTermsResponseModel extends GlobalResponseObject{
    data?: ShippingTermsDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //ShippingTermsDto
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: ShippingTermsDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
