export class TaxIdRequest {
    taxId: number;

    /**
     * 
     * @param taxId 
     */
    constructor(taxId: number){
        this.taxId = taxId;
    }
}