import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { MetalDetectionDto } from './metal-detection.dto';

export class MetalDetectionResponse extends GlobalResponseObject {
    data?: MetalDetectionDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //MetalDetectionDto
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: MetalDetectionDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
