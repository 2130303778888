import React, {useEffect, useRef, useState} from 'react';
import {Divider,Tabs, Table, Popconfirm, Card, InputNumber, Tooltip, Form, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Select, Modal} from 'antd';
import {InventoryRequest, ValAddInventoryDto, ValAddOpModel, WorkStatusDto} from '@gtpl/shared-models/production-management';
import {ValueAdditionService, VariantOperationsService} from '@gtpl/shared-services/production';
import { JobCompletedEnum, JobStatusEnum, UnitRequest } from "@gtpl/shared-models/common-models";

import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 
import './val-addition-inventory-grid.css';
import SafetyCertificateOutlined from '@ant-design/icons/lib/icons/SafetyCertificateOutlined';
import {BlanchingCookingForm} from '@gtpl/pages/production-management/production-management-components/blanching-cooking-form'
import PrinterOutlined from '@ant-design/icons/lib/icons/PrinterOutlined';
import { LotDetailView } from '@gtpl/pages/production-management/production-management-components/lot-detail-view';
import Link from 'antd/lib/typography/Link';
const { Option } = Select;



/* eslint-disable-next-line */
export interface ValAdditionInventoryGridProps {}

export function ValAdditionInventoryGrid(
  props: ValAdditionInventoryGridProps
) {
  const { TabPane } = Tabs;
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [form] = Form.useForm();
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false); 
  const [opQtyKey, setopQtyKey] = useState(1); 
  const [opBoxData, setOpBoxData] = useState<number>(0);
  const [opBoxQuantity, setOpBoxQuantity] = useState<number>();
  const [saleId, setSaleId] = useState(0);
  const [valAdditionData, setValAdditionData] = useState<ValAddInventoryDto>();
  const [selectedInformation, setSelectedInformation] = useState<ValAddInventoryDto>();
  const [workStatusData, setWorkStatusData] = useState<WorkStatusDto[]>([]);
  const [valAddOperations, setValAddOperations] = useState<ValAddOpModel[]>([]);
  const valAdditonService = new ValueAdditionService();
  const varientOpService = new VariantOperationsService()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [lotINo, setLotINo] = useState('lotNumber');
  

  useEffect(() => {
    getAllLotOps();
 }, []);

 const  getAllLotOps= () => {
   
   }

  useEffect(() => {
    getValAdditionOperations();
    // getAllValAdditionInventories();
  },[]);

  const getValAdditionOperations = () => {
    const unitId = Number(localStorage.getItem('unit_id'));
    varientOpService.getValAddOperations(new UnitRequest(unitId)).then((res) => {
      if (res.status) {
        setValAddOperations(res.data);
      } else {
        setValAddOperations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setValAddOperations([]);
    });
  }


  const getAllValAdditionInventories = () => {
    const unitId = Number(localStorage.getItem('unit_id'));
    const operation = form.getFieldValue('operation');
    // console.log(operation)
    valAdditonService.valAdditionsWipData(new InventoryRequest(unitId,form.getFieldValue('operation'))).then(res => {
      if(res.status){
        console.log(res)
        setValAdditionData(res.data);
      }else{
        if(res.intlCode){
          setValAdditionData(null);
          console.log(valAdditionData);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(error => {
      setValAdditionData(null);
      AlertMessages.getErrorMessage(error.message);
    })
  }
  
  const opBoxes = (value,index,rowData)=>{
    rowData.opQuantity = Number(value)*Number(rowData.workstationCapacity);
    console.log(rowData);
    // console.log(valAdditionData);
    setopQtyKey(preState => preState+1);

    setOpBoxData(value)
  }

  const opBoxesQuantity = (value,index,rowData)=>{
    console.log(value);
    // console.log(valAdditionData);

    setOpBoxQuantity(value)
  }
  const jobCompletedStatus= (workstatusDto:WorkStatusDto) => {
    console.log(opBoxData);
    console.log(opBoxQuantity);

    if(Number(opBoxData) > 0 && Number(opBoxQuantity) > 0){
      workstatusDto.opBoxes = opBoxData;
      workstatusDto.opQuantity = opBoxQuantity;
      workstatusDto.plantId = Number(localStorage.getItem('unit_id'));
      console.log(workstatusDto);
      valAdditonService.jobCompletedStatus(workstatusDto).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Job Status Updated Successfully');
          setOpBoxQuantity(0);
          setOpBoxData(0);
          getAllValAdditionInventories();
        } else {
          if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
          } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setWorkStatusData([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }
    else{
      AlertMessages.getErrorMessage("Invalid output boxes / ouput quantity.");
    }
  }

  

  

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleCancel = () => {
    getAllLotOps();
    setIsModalVisible(false);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
  const closeDrawer=()=>{
      setDrawerVisible(false);
  }

  const displayModel = (data: ValAddInventoryDto, type) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
    
  };

  

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }
  
  const goToLotDetailView = (lotNumber: string) => {
    setLotINo(lotNumber);
    console.log("lotNo", lotNumber);
    setIsModalVisible(true)
  }
  const columnsSkelton1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      responsive: ['sm'],
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.jobNumber.localeCompare(b.gridData?.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Work Station',
      dataIndex: 'workStation',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.workStation.localeCompare(b.gridData?.workStation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workStation')
    },
    {
      title: 'Lot Code',
      dataIndex: 'concatLotNum',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.concatLotNum.localeCompare(b.gridData?.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('concatLotNum'),
      render: (value, record) =>
      <Link onClick={e => goToLotDetailView(record.lotNo)} >{record.lotNo} </Link>
    },
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      responsive: ['sm'],
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.soNumber.localeCompare(b.gridData?.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
      // render: (value, record) => 
      //       <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.poNumber.localeCompare(b.gridData?.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      ...getColumnSearchProps('grade'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      // responsive: ['lg'],
     
    },
    // {
    //   title: 'Value Addition',
    //   dataIndex: 'valueAddition',
    //   //responsive: ['lg'],
    //   sorter: (a, b) => a.gridData?.valueAddition.localeCompre(b.gridData?.valueAddition),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('valueAddition')
    // },
    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      align:'right',
      responsive: ['sm'],
      // responsive: ['lg'],
      sorter: (a, b) => a.boxes - b.boxes,
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('boxes')
      //responsive: ['lg'],
    },
    {
      title: 'O/P Boxes',
      dataIndex:'opboxes',
      responsive: ['sm'],
      
      render: (text,rowData,index) => {
        return (
            <Form.Item name="opboxes"
            rules={[
              {
                required: true,message:'O/P Boxes Name is required'
                
              },
              {
                pattern: /^[0-9]*$/,
                message: `Should contain only numbers.`
              },
              {
                validator: (_, value) =>{
                  if(value <=rowData?.boxes){
                    console.log(value);
                    rowData.opboxes=value;
                    Promise.resolve()
                  }else{
                    Promise.reject(new Error('Should not be greater than I/P Boxes'))
                  }
                }
              } ]} >
              <InputNumber
                      min={0}
                      required={true}
                      style={{width:'250'}}
                      onChange={e => opBoxes(e,index,rowData)}
                       />
            </Form.Item>
        )
      }
      //responsive: ['lg'],
    },
    {
      title: 'I/P Quantity',
      dataIndex: 'quantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{Number(Number(value).toFixed())}</div>
      }
      // responsive: ['lg'],
    },
    {
      title: 'O/P Quantity',
      dataIndex: 'opQuantity',
      render: (text, data, index) => {
        console.log(data)
        return <span><InputNumber  defaultValue={text} onChange={e => opBoxesQuantity(e,index,data)} /></span>
        // return <span>{data.brandId.children}</span>
      }
      // render:(value) =>{
      //   return <div style={{textAlign:'right'}}>{Number(value).toFixed()}</div>
      // }
      // responsive: ['lg'],
    },
    {
      title:`is Completed?`,
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData) => (
        <span>         
           {rowData.jobStatus === "YES"?
          
            <Switch 
                className={ rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={rowData.jobStatus === "YES"?true:false}
                disabled={rowData.jobStatus === "YES"?true:false}
              />
              : 
               <Popconfirm onConfirm={e =>{jobCompletedStatus(rowData);}}
            title={
              rowData.jobStatus === "YES"
                ? 'Are you sure to make it as not Completed ?'
                :  'Are you sure to make it as Completed ?'
            }
          >
            <Switch  
                className={ rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated' }
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={rowData.jobStatus === "YES"?true:false}
                disabled={rowData.jobStatus === "YES"?true:false}
              />
            
          </Popconfirm>}
        </span>
      )
    },
    
  //   {
  //     title:'Action',
  //     dataIndex:'action',
  //     render: (text, rowData: ValAddInventoryDto, index) => (
  //       <span> <SafetyCertificateOutlined
  //       style={{ color: 'red' }}
  //       onClick={() => displayModel(rowData, 'blanchingCooking')} />
         
  //       </span>
  //   )

      
      
      
    
  // }
   
  ];

  const columnsSkelton2: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.jobNumber.localeCompare(b.gridData?.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Work Station',
      dataIndex: 'workStation',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.workStation.localeCompare(b.gridData?.workStation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workStation')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNo',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.lotNo.localeCompare(b.gridData?.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNo'),
      render: (value, record) =>
        <Link onClick={e => goToLotDetailView(record.lotNo)} >{record.lotNo} </Link>
    },
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.soNumber.localeCompare(b.gridData?.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
      render: (value, record) => 
            <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.soNumber}</Text>
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.gridData?.poNumber.localeCompare(b.gridData?.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      ...getColumnSearchProps('grade'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      // responsive: ['lg'],
     
    },
    {
      title: 'Value Addition',
      // dataIndex: 'valueAddition',
      //responsive: ['lg'],
      sorter: (a, b) => a.gridData?.valueAddition.localeCompre(b.gridData?.valueAddition),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('valueAddition'),
      render(text,record){
        return record.valueAddition?record.valueAddition:'PDTON'
      }
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      align:'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.boxes - b.boxes,
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('boxes')
      //responsive: ['lg'],
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opboxes',
      align:'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.opboxes - b.opboxes,
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('opboxes')
      //responsive: ['lg'],
    },
    {
      title: 'I/P Quantity',
      dataIndex: 'quantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{Number(Number(value).toFixed())}</div>
      }
      // responsive: ['lg'],
    },
    {
      title: 'O/P Quantity',
      dataIndex: 'opQuantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{Number(Number(value).toFixed())}</div>
      }
      // responsive: ['lg'],
    },
    
  ];
  
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getOperationWiseData = () => {
    getAllValAdditionInventories();
  }
  return (
    <Card title={<span style={{color:'white'}}>Value Addition Inventory</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
    <br></br>
    <Form form={form} layout="vertical">
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

          <Form.Item name="operation" label="Operation" rules={[{ required: true, message: 'Missing peration' }]} >
            <Select
              placeholder="Select Operation"
              allowClear
              // onChange={getLotNumbers}
            >
              {valAddOperations.map(dropData => {
                return <Option key={dropData.operationName} value={dropData.operationName}>{dropData.operationName}</Option>
              })}
            </Select>
          </Form.Item>


        </Col>

        <Col span={4}>
                <Form.Item style={{ paddingTop: 30 }}>

                  <Button type='primary' block

                    onClick={getOperationWiseData}
                  >
                    Get Data
                  </Button>
                </Form.Item>
              </Col>



      </Row>
    </Form>
    {/* <Descriptions column={3}>
              <Descriptions.Item label="No. of Lots Available">
                {valAdditionData?.numOfLots}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity to be Processed">
                {valAdditionData?.qtytobeprocessed}
              </Descriptions.Item>
              <Descriptions.Item label="Value Addition Inventory">
                {valAdditionData?.valAddInventory}
              </Descriptions.Item>
              
            </Descriptions> */}
    {valAdditionData ? 
    <Card>
    <Row gutter={40}>
        <Col>
          <Card
            title={'No. of Lots Available : ' + valAdditionData?.numOfLots}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity to be Processed : ' + Number(Number(valAdditionData?.qtytobeprocessed).toFixed())}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Value Addition Inventory : ' + Number(Number(valAdditionData?.valAddInventory).toFixed())}
            style={{
              textAlign: 'left',
              width: 330,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br/><br/><br/> 
      <Tabs type={'card'} tabPosition={'top'}>
          <TabPane 
          key="1"
          tab={<span style={{ color: "blue" , fontSize: '18px'}}>WIP: {valAdditionData?.gridData.filter(res=>res.jobStatus===JobCompletedEnum.NO).length}</span>}
          >
          <Table
          rowKey={record => record.captureWorkStatusId}
          columns={columnsSkelton1}
          dataSource={valAdditionData?.gridData.filter(res=>res.jobStatus===JobCompletedEnum.NO)}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalOPQuantity = 0;
            let totalQuantity = 0;
            let totalBoxes = 0;
          
            pageData.forEach(({ quantity,opQuantity }) => {
              totalQuantity += Number(Number(quantity).toFixed());
              totalOPQuantity += Number(Number(opQuantity).toFixed());
              
            });

           
            return (
              
              <>{valAdditionData?.gridData.filter(res=>res.jobStatus===JobCompletedEnum.NO)? 
              <Table.Summary.Row className='tableFooter'>
              <Table.Summary.Cell index={2} colSpan={9} ><Text >Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalQuantity.toFixed()}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalOPQuantity.toFixed()}</Text></Table.Summary.Cell>
            </Table.Summary.Row>: ''}
                
                
              </>
            );
           
          }
         }
          />
           </TabPane>
          <TabPane 
         key="2"
         tab={<span style={{ color: "green", fontSize: '18px' }}>Closed: {valAdditionData?.gridData.filter(res=> res.jobStatus === JobCompletedEnum.YES).length}</span>}
         >
          <Table
          rowKey={record => record.jobCompletedStatus}
          columns={columnsSkelton2}
          dataSource={valAdditionData?.gridData.filter(res=> res.jobStatus === JobCompletedEnum.YES)}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalOPQuantity = 0;
            let totalQuantity = 0;
            let totalBoxes = 0;
          
            pageData.forEach(({ quantity,opQuantity }) => {
              totalQuantity += Number(Number(quantity).toFixed());
              totalOPQuantity += Number(Number(opQuantity).toFixed());
              
            });


            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={2} colSpan={10} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalQuantity}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalOPQuantity}</Text></Table.Summary.Cell>
                </Table.Summary.Row>
                
              </>
            );
          }
         }
          />
         </TabPane>
       

        </Tabs>
        </Card>
        : <></>
    }

<Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
      <SoInfoViewPages saleOrderId={saleId} screen="production" />
      <LotDetailView lotNumber={lotINo} />
      </Modal>
    </Card>

  );
}

export default ValAdditionInventoryGrid;
