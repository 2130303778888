import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { MonthAttnDataModel } from "./month-attn-data.model";


export class MonthAttendanceResponse extends GlobalResponseObject {
    data?: MonthAttnDataModel[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: MonthAttnDataModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}