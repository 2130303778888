import React, { useState, useEffect, useRef } from 'react';
import {  Divider, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import Link from 'antd/lib/typography/Link';

import {BomPackingItemsDto, BomSODto, SODto} from '@gtpl/shared-models/sale-management';
import {SaleOrderItemsInfo,SaleOrderItemsDto,SOItemsDto} from '@gtpl/shared-models/sale-management';

import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import {SoDesignForm} from '@gtpl/pages/master/master-components/so-design-form';

import { Table } from "ant-table-extensions";

import { FormattedMessage } from 'react-intl';

import { Redirect } from 'react-router-dom';
import './sale-order-report-grid.css';
import { FoodTypesDto, ProductDto } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import moment from 'moment';
import SOReportItemsGrid from './so-report-items';

/* eslint-disable-next-line */
export interface SaleOrderReportGridProps {}

export function SaleOrderReportGrid(props: SaleOrderReportGridProps) {
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isNewForm, setisNewForm] = useState<boolean>(true);
  const [object, setObject] = useState(null);
  const [saleId, setSaleId] = useState(0);
  const [viewOnly, setViewOnly] = useState(true);
  const [saleOrderData, setSaleOrderData] = useState<any[]>([]);
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [bomSoData, setBomSoData] = useState<BomPackingItemsDto[]>([]);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<any>(undefined);
  const [selectedSoItem, setSelectedSoItem] = useState<any>(undefined);
  const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [foodTypeData, setFoodType] = useState<FoodTypesDto[]>([]);

  const [data1, setData1] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);


  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const { formatMessage: fm } = useIntl();
  const service = new SaleOrderService;
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {getAllSaleOrders();getAllSaleOrderItems()}, [])
  const getAllSaleOrders= () => {
    service.soReport().then(res => {
      if (res.status) {setSaleOrderData(res.data);
        console.log(res.data)
        console.log(exportedData)
      } else {
        if (res.intlCode) {
          setSaleOrderData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    })
  }
  const getAllSaleOrderItems= () => {
    service.getAllSaleOrderItems().then(res => {

      if (res.status) {setSoItemData(res.data);
        console.log(res.data)
      } else {
        if (res.intlCode) {
          setSoItemData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoItemData([]);
    })
  }
  // const getAllBomDetails= () => {
  //   service.getAllBomData().then(res => {

  //     if (res.status) {
  //       console.log('==========================');
  //       console.log(res.data);
  //       setBomSoData(res.data);
  //     } else {
  //       if (res.intlCode) {
  //         setBomSoData([]);
  //           AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //        AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setBomSoData([]);
  //   })
  // }


  //drawer related
  const closeDrawer=()=>{
    setDrawerVisible(false);
  }
  // const updateSaleOrder = (saleorderData: SODto) => {
  //   service.updateSaleOrder(saleorderData).then(res => { console.log(res);
  //     if (res.status) {
  //       AlertMessages.getSuccessMessage('Updated Successfully');
  //       getAllSaleOrders();
  //       setDrawerVisible(false);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  
  // const deleteSaleOrder = (SaleOrderData:SODto) => {
  //   SaleOrderData.isActive=SaleOrderData.isActive?false:true;
  //   service.activatedeActivateSaleOrder(SaleOrderData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllSaleOrders();
  //       AlertMessages.getSuccessMessage('Success'); 
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  const openFormWithData=(SaleOrderData: SODto)=>{
    setisNewForm(false);
    setDrawerVisible(true);
    setSelectedSaleOrder(SaleOrderData);
  }

  const setUpdatevalue = (rowdata) => {
    console.log(rowdata.saleOrderId)
    setObject(rowdata.saleOrderId)

  }
  
   
const expandedRowRender =  (record:AllSaleOrdersDto, index, indent, expanded)  => {
  return <SOReportItemsGrid saleOrderId={record.saleOrderId} currencyId={record.currencyId} />

const Columns: ColumnProps<any>[] = [
  {
    title: 'Brand',
    dataIndex: 'brand',
    width:150,
    sorter: (a, b) => a.brand.localeCompare(b.brand),
    ...getColumnSearchProps('brand'),
    // render: (text, data, index) => {
    //   console.log(text, data, index)
    //   return <span>{data.brand.children}</span>
    // }
  },
  // {
  //   title: 'Product',
  //   dataIndex: 'itemId',
  //   // render: (text, data, index) => {
  //   //   console.log(text, data, index)
  //   //   return <span>{data.itemId.children}</span>
  //   // }
  // },
  // {
  //   title: 'Food Type',
  //   dataIndex: 'foodTypeId',
  //   // render: (text, data, index) => {
  //   //   console.log(text, data, index)
  //   //   return <span>{data.foodTypeId.children}</span>
  //   // }
  // },
  {
    title: 'Variant',
    dataIndex: 'varientId',
    sorter: (a, b) => a.varientId.localeCompare(b.varientId),
    ...getColumnSearchProps('varientId'),
    render: (text, data, index) => {
      console.log(text, data, index)
      return <span>{`${data.itemId}${text}`}</span>
    }
  },
  {
    title: 'Grade',
    dataIndex: 'minGrade',
    key: 'minGrade',
    sorter: (a, b) => a.minGrade-b.minGrade,
    ...getColumnSearchProps('minGrade'),
    render: (text, data, index) => {
      console.log(text, data, index)
      return <span>{`${text} - ${data.maxGrade} `}</span>
    }
  },
  {
    title: 'Pack Type',
    dataIndex: 'pouchWeight',
    key: 'pouchWeight',
    sorter: (a, b) => a.pouchWeight-b.pouchWeight,
    ...getColumnSearchProps('pouchWeight'),
    render: (text, data, index) => {
      console.log(text, data, index)
      return <span>{`${data.pouches} * ${text} ${data.uomId}`}</span>
    }
  },
  {
    title: 'Net Case Weight',
    dataIndex: 'caseWeight',
    key: 'caseWeight',
    sorter: (a, b) => a.caseWeight-b.caseWeight,
    ...getColumnSearchProps('caseWeight'),
    render: (text, data, index) => {
      console.log(itemsdata[index]);
      return <span>{`${text} ${data.uomId}`}</span>
    }
  },
  {
    title: 'Cases',
    dataIndex: 'cases',
    key: 'cases',
    sorter: (a, b) => a.cases-b.cases,
    ...getColumnSearchProps('cases'),
  },
  {
    title: 'Net Weight',
    dataIndex: 'netWeight',
    key: 'netWeight',
    sorter: (a, b) => a.netWeight-b.netWeight,
    ...getColumnSearchProps('netWeight'),
    render: (text, data, index) => {
      console.log(text, data, index)
      return <span>{`${text} ${data.uomId}`}</span>
    }
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    sorter: (a, b) => a.unitPrice - b.unitPrice,
    ...getColumnSearchProps('unitPrice'),
  },
  {
    title: 'Net Amount',
    dataIndex: 'netAmount',
    key: 'netAmount',
    sorter: (a, b) => a.netAmount- b.netAmount,
    ...getColumnSearchProps('netAmount'),
  },
  
];
const BomColumns: ColumnProps<any>[] = [
  {
    title: 'Item Category',
    dataIndex: ['itemCategoryDetails',"itemCategory"],
    width:150,
    sorter: (a, b) => a.itemCategoryDetails.itemCategory.localeCompare(b.itemCategoryDetails.itemCategory),
    ...getColumnSearchProps('itemCategoryId'),
  },
  {
    title: 'Item',
    dataIndex: ['itemDetails',"itemName"],
    width:150,

    sorter: (a, b) => a.itemDetails.itemName.localeCompare(b.itemDetails.itemName),
    ...getColumnSearchProps('itemId'),

  },
  {
    title: 'Size',
    dataIndex: ["size", "sizeName"],
    width:150,

    sorter: (a, b) => a.size.sizeName.localeCompare(b.size.sizeName),
    ...getColumnSearchProps('sizeId'),

  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width:150,

    sorter: (a, b) => a.quantity - b.quantity,
    ...getColumnSearchProps('quantity'),
    
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width:150,

    render: (status, rowData) => (
      <>
        {status=="OPEN"?<Tag  style={{backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>OPEN</Tag> : status=="PO"?<Tag  style={{backgroundColor:'#f2a637',color:"black", fontWeight: 'bold' }}>PURCHASE ORDER</Tag>:
        status=="GRN"?<Tag  style={{backgroundColor:'#e4f467',color:"black", fontWeight: 'bold' }}>GRN</Tag>
        :status=="CLOSED"?<Tag  style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}>CLOSED</Tag>:""}
        
      </>
    ),
    sorter: (a, b) => a.status.localeCompare(b.status),
    sortDirections: ['descend', 'ascend'],
    filters: [
      {
        text: 'OPEN',
        value: 'OPEN',
      },
      
      {
        text: 'PURCHASE ORDER',
        value: 'PO',
      },
      {
        text: 'GRN',
        value: 'GRN',
      },
      {
        text: 'CLOSED',
        value: 'CLOSED',
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => 
    {
      // === is not work
      return record.status === value;
    },
  },
  
  
];
// console.log(soItemData.filter(rec=>rec.saleOrderId == record.saleOrderId));
return (
  <>
  <Card  style={{ overflow: 'auto',overflowY:'hidden', backgroundColor:'#d9d9d9', height:40 ,margin:1 }} title={<span>SALE ORDER ITEMS </span>}></Card>
  {soItemData.filter(rec=>rec.saleOrderId == record.saleOrderId) &&
    <Table
      // key={Date.now()}
      className="components-table-demo-nested"
      columns={Columns}
      dataSource={soItemData.filter(rec=>rec.saleOrderId == record.saleOrderId)}
      // exportable
    />
  }
  <Card  style={{ overflow: 'auto',overflowY:'hidden', backgroundColor:'#d9d9d9', height:40 ,margin:1 }} title={<span>BOM DETAILS</span>}></Card>
  <Card>
    <Row >
      <Col style={{marginLeft:'5%'}}>
        <Card title={'TOTAL: ' + (bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'OPEN :' + (bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)).filter(el => el.status == "OPEN").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#f6e552'}}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'PURCHASE ORDER :' + (bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)).filter(el => el.status == "PO").length} style={{textAlign: 'left', width: 220, height: 41,backgroundColor:'#f2a637' }}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'GRN :' + (bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)).filter(el => el.status == "GRN").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#e4f467' }}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'CLOSED :' + (bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)).filter(el => el.status == "CLOSED").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#52c41a' }}></Card>
      </Col>
    </Row>
  </Card> 
  {bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId) ?
  
    <Table
    key={Date.now()}
      className="components-table-nested"
      columns={BomColumns}
      dataSource={bomSoData.filter(rec=>rec.saleOrderId == record.saleOrderId)}
      // exportable
    />
    : ""
  }
  </>
);
}

const columnsSkelton: ColumnProps<any>[] = [
  {
    title: 'S No',
    key: 'sno',
    width: '70px',
    render: (text, object, index) => (page-1) * 10 +(index+1)
  },
  // {
  //   title: 'SO Number',
  //   dataIndex: 'saleOrder',
  //   fixed: 'left',
  //   width: 150,
  //   align:'center',

  //   ...getColumnSearchProps('saleOrder'),
  //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</Link>,
  //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrder}</a>,
  //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
  //   sortDirections: ['descend', 'ascend'],
  //   ...getColumnSearchProps('saleOrder')
  // },
  {
    title: 'PO Number',
    dataIndex: 'poNo',   
    sorter: (a, b) => a.poNo.localeCompare(b.poNo),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('poNo')
  },

  {
    title: 'Customer Name',
    dataIndex: 'customer', 
    sorter: (a, b) => a.customer.localeCompare(b.customer),
    sortDirections: ['descend', 'ascend'],  
    ...getColumnSearchProps('customer')
  },
  {
    title: 'End Customer Name',
    dataIndex: 'endCustomer',   
    sorter: (a, b) => a.endCustomer.localeCompare(b.endCustomer),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('endCustomer')
  },
  {
    title: 'ETD',
    dataIndex: 'etd',   
    sortDirections: ['descend', 'ascend'],
    // sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),

    ...getColumnSearchProps('etd')
  },
  {
    title: 'ETA',
    dataIndex: 'eta',  
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => moment(a.eta).unix() - moment(b.eta).unix(),

    ...getColumnSearchProps('eta')
  },
  {
    title: 'Final Destination',
    dataIndex: 'destinationAddress',   
    sorter: (a, b) => a.destinationAddress.localeCompare(b.destinationAddress),
    sortDirections: ['descend', 'ascend'],
    ...getColumnSearchProps('destinationAddress')
  },
 
  
  
  {
    title: 'Status',
    dataIndex: 'status',
    // responsive: ['lg'],
    render: (status, rowData) => (
      <>
        {status=="OPEN"?<Tag  style={{backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>OPEN</Tag> : status=="INPROGRESS"?<Tag  style={{backgroundColor:'#f2a637',color:"black", fontWeight: 'bold' }}>IN PROGRESS</Tag>:status=="CLOSED"?<Tag  style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}>CLOSED</Tag>:""}
        
      </>
    ),
    sorter: (a, b) => a.status.localeCompare(b.status),
    sortDirections: ['descend', 'ascend'],
    filters: [
      {
        text: 'OPEN',
        value: 'OPEN',
      },
      
      {
        text: 'INPROGRESS',
        value: 'INPROGRESS',
      },
      {
        text: 'CLOSED',
        value: 'CLOSED',
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => 
    {
      // === is not work
      return record.status === value;
    },
    // ...getColumnSearchProps('status')
  },

  
  
];

const exportedData = [];

   saleOrderData.forEach(element => {
    soItemData.forEach(element1 => element.saleOrderId==element1.saleOrderId?exportedData.push(Object.assign(element,element1)):{} )

    })
    console.log(exportedData)

    const headers = [
      { label: "SO process TYpe", key: "saleOrder" },
      { label: "Invoice Date", key: "invDate" },
      { label: "Customer", key: "customer" },
      { label: "Po Number", key: "poNo" },
      { label: "End Customer", key: "endCustomer" },
      { label: "Destination Address", key: "destinationAddress" },
      { label: "ETA", key: "eta" },
      { label: "ETD", key: "etd" },
      { label: "Item", key: "itemId" },
      { label: "Min Grade", key: "minGrade" },
      { label: "Max Grade", key: "maxGrade" },
      { label: "Food Type", key: "foodTypeId" },
      { label: "Varient", key: "varientId" },
      { label: "Cases", key: "cases" },
      { label: "Net Amount", key: "netAmount" },
      { label: "Unit Price", key: "unitPrice" },
      { label: "Pouches", key: "pouches" },
      { label: "Pouch Weight", key: "pouchWeight" },
      { label: "Net Case Weight", key: "caseWeight" },
      { label: "Net Weight", key: "netWeight" },
      { label: "Min Glaze", key: "minGlaze" },
      { label: "Max Grade", key: "MaxGlaze" },
      { label: "Soaking Time", key: "soakingTime" },
      { label: "Soaking Style", key: "soakingStyle" },
      { label: "Style Weighment", key: "styleWeighment" },
      { label: "Brand", key: "brand" },
      { label: "UOM", key: "uomId" },
      { label: "Dispatched Qty", key: "dispatchedQty" },
      { label: "Invoice Amount", key: "invAmount" },
      { label: "Re Pack Qty", key: "rePackQty" },
      { label: "Re Process Qty", key: "reProcessQty" },
      { label: "Shrimp Weight", key: "shrimpWeight" },
      { label: "Required Quantity", key: "requiredQuantity" },
      { label: "Re Pack Qty", key: "rePackQty" },
      { label: "Re Process Qty", key: "reProcessQty" },
    ];
  
    const csvReport = {
      data: exportedData,
      headers: headers,
      filename: 'SaleOrderReport.csv'
    };


const goToSaleDetailView = (saleOrderId:number) => {
  setSaleId(saleOrderId);
  console.log(saleOrderId);
}
return (
  <>
  {(saleId) ? <Redirect to={{pathname:"/saleorder-detail-view", state:saleId}}/>:null}

<Card title={<span style={{color:'white'}}>Sale Order Report</span>}
style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}  >


  
    <Row gutter={24}>
      <Col span={5}>
        <Card title={"TOTAL SO's: " + saleOrderData.length} style={{textAlign: 'left', height: 41,backgroundColor:'#bfbfbf'}}></Card>
      </Col>
      <Col span={4}>
        <Card title={'OPEN :' + saleOrderData.filter(el => el.status == "OPEN").length} style={{textAlign: 'left', height: 41,backgroundColor:'#f6e552'}}></Card>
      </Col>
      <Col span={5}>
        <Card title={'IN-PROGRESS :' + saleOrderData.filter(el => el.status == "INPROGRESS").length} style={{textAlign: 'left', height: 41,backgroundColor:'#f2a637' }}></Card>
      </Col>
      <Col span={4}>
        <Card title={'CLOSED :' + saleOrderData.filter(el => el.status == "CLOSED").length} style={{textAlign: 'left', height: 41,backgroundColor:'#52c41a' }}></Card>
      </Col>
      <Col span={5}>
        <Card title={'CANCELLED :' + saleOrderData.filter(el => el.status == "CANCELLED").length} style={{textAlign: 'left', height: 41,backgroundColor:'#52c41a' }}></Card>
      </Col>
      
      <Col span={1}>  
        <Button ><CSVLink {...csvReport} ></CSVLink></Button>
       </Col>
    </Row>
      {/* <Col>
       <Card title={'Active: ' + saleOrderData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
      </Col>
      <Col>
       <Card title={'In-Active: ' + saleOrderData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
      </Col> */}
      <br></br>
      
      <Table
      rowKey={record => record.saleOrderId}
      columns={columnsSkelton}
      expandedRowRender = { expandedRowRender}
      // expandedBomData = { expandedBomData}
      // expandable={{
      //   expandedRowRender: record => <p style={{ margin: 0 }}>{record.saleOrderId.saleOrderItemsInfo}</p>,
      //   rowExpandable: record => record.saleOrderId,
      // }}
      dataSource={saleOrderData}
      pagination={{
        onChange(current) {
          setPage(current);
        }
      }}
      onChange={onChange}
      scroll={{ y:1500 }}
      size="small"
      bordered/>
    <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <SoDesignForm key={Date.now()}
            // updateSaleOrder={updateSaleOrder}
            isUpdate={true}
            saleOrderData={selectedSaleOrder}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
 </Card>
 </>
);
}


export default SaleOrderReportGrid;
