import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class ProductionInventoryDetails{
  
  
    lotNumber:string;
    subLotNumber:string;
    saleOrderId:number;
    saleOrder:string;
    saleOrderItemId:number;
    product:string;
    boxes:number;
    quantity:number;
    issuedQuantity:number;
    physicalQuantity:number;
    intransitQuantity:number;
    wipQuantity:number;
    wastageQuantity:number;
    rejectedQuantity:number;
    inputGrade:number;
    outputGrade:number;
    operation:OperationTypeEnum;
    varientCode?:string;
    varientId?:number;
    assignedRejection?:number;
    productionInventoryId?:number;
    opBoxes?:number;
    nextOperation?:OperationTypeEnum;
    count?:number;
    poNumber?:string;
    productSKU?:string;
    plantId?:number;
    ipCount?:number;
    ipQty?:number;
    qty?:number;
    transferedQuantity?:number;
    conversionQty?:number;

    /**
     * @param quantity
     * @param lotNumber 
     * @param subLotNumber 
     * @param saleOrder 
     * @param product 
     * @param boxes 
     * @param issuedQuantity 
     * @param physicalQuantity 
     * @param intransitQuantity 
     * @param wipQuantity 
     * @param wastageQuantity 
     * @param rejectedQuantity 
     * @param inputGrade 
     * @param outputGrade 
     * @param operation 
     */
    constructor(lotNumber:string, subLotNumber:string, saleOrder:string, product:string, boxes:number, issuedQuantity:number, physicalQuantity:number, intransitQuantity:number, wipQuantity:number, wastageQuantity:number, rejectedQuantity:number, inputGrade:number, outputGrade:number, operation:OperationTypeEnum,varientCode?:string,varientId?:number,assignedRejection?:number,productionInventoryId?:number, opBoxes?:number, nextOperation?:OperationTypeEnum, count?:number,poNumber?:string,productSKU?:string,plantId?:number,ipCount?:number,ipQty?:number,qty?:number,transferedQuantity?:number,conversionQty?:number){
        this.lotNumber = lotNumber;
        this.subLotNumber = subLotNumber;
        this.saleOrder = saleOrder;
        this.product = product;
        this.boxes = boxes;
        this.quantity=this.quantity;
        this.issuedQuantity = issuedQuantity;
        this.physicalQuantity = physicalQuantity;
        this.intransitQuantity = intransitQuantity;
        this.wipQuantity = wipQuantity;
        this.wastageQuantity = wastageQuantity;
        this.rejectedQuantity = rejectedQuantity;
        this.inputGrade = inputGrade;
        this.outputGrade = outputGrade;
        this.operation = operation;
        this.varientCode = varientCode;
        this.varientId = varientId;
        this.assignedRejection = assignedRejection;
        this.productionInventoryId = productionInventoryId;
        this.opBoxes = opBoxes;
        this.nextOperation = nextOperation;
        this.count = count;
        this.poNumber = poNumber;
        this.productSKU = productSKU;
        this.plantId = plantId;
        this.ipCount = ipCount;
        this.ipQty = ipQty;
        this.qty = qty;
        this.transferedQuantity = transferedQuantity;
        this.conversionQty = conversionQty;
    }

}