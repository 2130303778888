import { OccupancyEnum } from "../enums";

export class RackSuggestionResponseDto{
    plantId:number;
    totalCases:number;
    inStockCases:number;
    zoneId:number;
    zoneName:string;
    rackPositionCode:number;
    rackPositionId:number;
    rackCode:string;
    occupancy:OccupancyEnum;
    constructor(plantId:number,
        totalCases:number,
        inStockCases:number,
        zoneId:number,
        zoneName:string,
        rackPositionCode:number,
        rackPositionId:number,
        rackCode:string,
        occupancy:OccupancyEnum,){
            this.plantId=plantId
        this.totalCases=totalCases
        this.inStockCases=inStockCases
        this.zoneId=zoneId
        this.zoneName=zoneName
        this.rackPositionCode=rackPositionCode
        this.rackPositionId=rackPositionId
        this.rackCode=rackCode
        this.occupancy=occupancy
        }

}