import { OperationTypeEnum, QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";


export class RejectionDto {
    rejectionId: number;
    plantId: number;
    shift: ShiftsEnum;
    lotNumber: string;
    saleOrderId: number;
    saleOrderItemId: number;
    saleOrderNumber:string;
    count: number;
    operation: OperationTypeEnum;
    workstation: number;
    testId: number;
    samples: number;
    quantity: number;
    rejectionQuantity: number;
    rejectionReason: RejectionReasonsEnum;
    testResult: QaQcTestResultEnum;
    testName:string;
    product :string;
    remarks: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    workstationName:string;

    constructor(
        rejectionId: number,
        plantId: number,
        shift: ShiftsEnum,
        lotNumber: string,
        saleOrderId: number,
        saleOrderItemId: number,
        saleOrderNumber:string,
        count: number,
        operation: OperationTypeEnum,
        workstation: number,
        testId: number,
        samples: number,
        quantity: number,
        rejectionQuantity: number,
        rejectionReason: RejectionReasonsEnum,
        testResult: QaQcTestResultEnum,
        testName:string,
        product :string,
        remarks: string,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        workstationName:string,
    ) {
        this.rejectionId = rejectionId;
        this.plantId = plantId;
        this.shift = shift;
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.saleOrderNumber = saleOrderNumber;
        this.count = count;
        this.operation = operation;
        this.workstation = workstation;
        this.testId = testId;
        this.samples = samples;
        this.quantity = quantity;
        this.rejectionQuantity = rejectionQuantity;
        this.rejectionReason = rejectionReason;
        this.testResult = testResult;
        this.testName = testName;
        this.product = product;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt
        this.workstationName = workstationName
    }
    
}