export class EmployeeExperienceDetailsDto {

employeeExperienceId: number;
organisation: string;
designation: string;
fromDate: Date;
toDate: Date;
experience: number;
isActive: boolean;
createdAt: Date;
createdUser: string | null;
updatedAt: Date;
updatedUser: string | null;
versionFlag: number;
 
 }
 