import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { CaptureWorkStatusDto } from './capture-work-status.dto';

export class AllCaptureWorkStatusResponseModel extends GlobalResponseObject{
    data?: CaptureWorkStatusDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CaptureWorkStatusDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
