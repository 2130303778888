import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";

export class BookContainerDto{
    referenceNumber:string;
    expectedDeliveryDate:Date;
    saleOrderId:number;
    containerStatus:ContainerStatusEnum;
    createdUser:string;
    updatedUser?:string;
    containerId?:number;
    scac?:string;
    unitId?:number;
    /**
     * 
     * @param referenceNumber 
     * @param expectedDeliveryDate 
     * @param saleOrderId 
     * @param containerStatus 
     * @param createdUser 
     * @param containerId 
     * @param updatedUser 
     */
    constructor(referenceNumber:string, expectedDeliveryDate:Date, saleOrderId:number, containerStatus:ContainerStatusEnum, createdUser:string, containerId?:number, updatedUser?:string, scac?:string, unitId?:number){
        this.referenceNumber = referenceNumber;
        this.expectedDeliveryDate = expectedDeliveryDate;
        this.saleOrderId = saleOrderId;
        this.containerStatus = containerStatus;
        this.createdUser = createdUser;
        this.containerId = containerId;
        this.updatedUser = updatedUser;
        this.scac = scac;
        this.unitId = unitId;
    }
}