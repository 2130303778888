import { LeavesDTO } from "./leaves.dto";

export class EmpLeaveDTO {
    employeeId: number; 
    employeeCode:string;
    employeeName: string;
    employeeLastName:string;
    designation:string;
    year: string;
    leaves: LeavesDTO[]
    gender?:string;
    departmentName?:string;


}