export class TemperatureLogDTO {

  tempLogId: number;
  deptName: string;
  loadStatus:string;
  uid:string;
  deptCode:string;
  unitId:number;
  module: string;
  subModule: string;
  date: any;
  day: number;
  month: number;
  hour: number;
  minutes: number;
  temperature: number;
  uom: string;
  createdAt: Date;
  createdUser: string; 
  updatedAt: Date;
  updatedUser: string; 
  versionFlag: number;
    constructor( tempLogId: number, loadStatus:string, uid:string,deptName: string,deptCode:string,unitId:number, module: string, subModule: string, date: any, day: number, month: number, hour: number, minutes: number, temperature:number, uom: string, createdAt: Date, createdUser: string,  updatedAt: Date, updatedUser: string,  versionFlag: number){
      this.tempLogId = tempLogId;
      this.loadStatus = loadStatus;
      this.uid = uid;
      this.deptName = deptName;
      this.deptCode = deptCode;
      this.unitId =unitId;
      this.module = module;
      this.subModule = subModule;
      this.date = date;
      this.month = month;
      this.day = day;
      this.hour = hour;
      this.minutes = minutes;
      this.temperature = temperature;
      this.uom = uom;
      this.createdAt = createdAt;
      this.createdUser = createdUser;
      this.updatedAt = updatedAt;
      this.updatedUser = updatedUser;
      this.versionFlag = versionFlag;
}

  }
