export class CertificationsDto{
    certificationId:number;
    certificateName:string;
    remarks:string;
    createdUser:string;
    isActive:boolean;
    
    /**
     * 
     * @param certificationId 
     * @param certificateName 
     * @param remarks 
     * @param createdUser
     * @param isActive
     */
    constructor(certificationId:number,certificateName:string,remarks:string,createdUser:string,isActive:boolean){
    this.certificationId = certificationId;
    this.certificateName = certificateName;
    this.remarks = remarks;
    this.createdUser = createdUser;
    this.isActive = isActive
    }
} 