import { SaleOrderInvDto } from "./sale-order-inv.dto";
import { SaleOrderItemsDto } from "./sale-order-items.dto";
import { SaleOrderPoDto } from "./sale-order-po.dto";

export class SaleOrderDetailDto {
    saleOrderId: number;
    saleOrderNumber: string;
    InspectionId: number;
    status: string;
    currencyId: number;
    factoryId: number;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    saleOrderItemsInfo: SaleOrderItemsDto[];
    soInvDetailsInfo: SaleOrderInvDto;
    soPoDetailsInfo: SaleOrderPoDto;
   
    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     * @param InspectionId 
     * @param status 
     * @param currencyId 
     * @param factoryId 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     * @param saleOrderItemsInfo 
     * @param soInvDetailsInfo 
     * @param soPoDetailsInfo 
     */
    constructor(saleOrderId: number,
        saleOrderNumber: string,
        InspectionId: number,
        status: string,
        currencyId: number,
        factoryId: number,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number,
        saleOrderItemsInfo: SaleOrderItemsDto[],
        soInvDetailsInfo: SaleOrderInvDto,
        soPoDetailsInfo: SaleOrderPoDto,){ 
            this.saleOrderId = saleOrderId
            this.saleOrderNumber = saleOrderNumber
            this.InspectionId = InspectionId
            this.status = status
            this.currencyId = currencyId
            this.factoryId = factoryId
            this.isActive = isActive
            this.createdAt = createdAt
            this.createdUser = createdUser
            this.updatedAt = updatedAt
            this.updatedUser = updatedUser
            this.versionFlag = versionFlag
            this.soPoDetailsInfo = soPoDetailsInfo
            this.soInvDetailsInfo = soInvDetailsInfo
            this.saleOrderItemsInfo = saleOrderItemsInfo
        }
    }
