import axios from 'axios';
import connection from './connection';
import {EmploymentStatusDto, EmploymentStatusResponse, AllEmploymentStatusResponse} from '@gtpl/shared-models/hrms';

export class EmploymentStatService{
   
    URL = connection.DEPLOY_URL + "/employment-status";

    async create(dto: EmploymentStatusDto): Promise<EmploymentStatusResponse>{
        return await axios.post(this.URL+ '/createEmployStatus', dto)
           .then(res => {
               return res.data
           })
    }

    async update(dto: EmploymentStatusDto): Promise<EmploymentStatusResponse>{
        return await axios.post(this.URL+ '/updateEmployStatus', dto)
           .then(res => {
               return res.data
           })
    }

    async getAllEmploymentStats(): Promise<AllEmploymentStatusResponse>{
        return await axios.post(this.URL + '/getAllEmployStats')
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivate(dto: EmploymentStatusDto): Promise<EmploymentStatusResponse>{
        return await axios.post(this.URL+ '/activateOrDeactivate', dto)
           .then(res => {
               return res.data
        })
    }

    async getAllActiveEmployStats(): Promise<AllEmploymentStatusResponse>{
        return await axios.post(this.URL+ '/getAllActiveEmployStats')
        .then(res => {
            return res.data
        })
    } 
}