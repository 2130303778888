import { BomItemsDto } from "./bom-details.model";

export class RmBomDetails {
    saleOrderId: number;
    etdDate:any;
    saleOrderNumber: string;
    saleOrderItemId: number;
    productId: number;
    minGrade: number;
    maxGrade: number;
    uomId: number;
    hlPercentage: number;
    finalPercentage: number;
    minCount: number;
    maxCount: number;
    count: number;
    caseWeight: number;
    oupPutMinWeight: number;
    oupPutMaxWeight: number;
    cases: number;
    ratio: number;
    quantity: number;
    endCustomerName: string;
    isActive?: boolean;
    span?: boolean;
    unitId?:number;
    unitName?:string;
    productName?:string;
    poNumber?:string
    bomLogId?:number;
/** 
* @param saleOrderId
* @param etdDat
* @param saleOrderNumber
* @param saleOrderItemId
* @param productId
* @param minGrade
* @param maxGrade
* @param uomId
* @param hlPercentage
* @param finalPercentage
* @param minCount
* @param maxCount
* @param count
* @param caseWeight
* @param oupPutMinWeight
* @param oupPutMaxWeight
* @param cases
* @param ratio
* @param quantity
* @param endCustomerName
*/

    constructor(saleOrderId: number,etdDate:any,saleOrderNumber: string,saleOrderItemId: number,productId: number,minGrade: number,maxGrade: number,uomId: number,hlPercentage: number,finalPercentage: number,minCount: number,maxCount: number,count: number,caseWeight: number,oupPutMinWeight: number,oupPutMaxWeight: number,cases: number,ratio: number,quantity: number,endCustomerName:string, span?: boolean, poNumber?:string){
        this.saleOrderId = saleOrderId
        this.etdDate = etdDate;
        this.saleOrderNumber = saleOrderNumber;
        this.saleOrderItemId = saleOrderItemId;
        this.productId = productId;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.uomId = uomId;
        this.hlPercentage = hlPercentage;
        this.finalPercentage = finalPercentage;
        this.minCount = minCount;
        this.maxCount = maxCount;
        this.count = count;
        this.caseWeight = caseWeight;
        this.oupPutMinWeight = oupPutMinWeight;
        this.oupPutMaxWeight = oupPutMaxWeight;
        this.cases = cases;
        this.ratio = ratio;
        this.quantity = quantity;
        this.endCustomerName = endCustomerName;
        this.span = span;
        this.poNumber = poNumber;
    }
}
