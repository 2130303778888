
import axios from 'axios';
import { UomsResponseModel, AllUomsResponseModel, UomsDto,UomRequest, UomsDropDownResponseModel, UomsDropDownDto } from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class UomsService {
    getAllActiveUom() {
      throw new Error('Method not implemented.');
    }
    URL = connection.DEPLOY_URL + '/Uoms';

    async createUoms(uoms: UomsDto): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/createUom', uoms)
            .then(res => {
                return res.data
            })
    }
    async updateUoms(uoms: UomsDto): Promise<any> {
        return await axios.post(this.URL + '/updateUom', uoms)
            .then(res => {
                return res.data
            })
    }
    async getAllUoms(req?:UserRequestDto): Promise<AllUomsResponseModel> {
        return await axios.post(this.URL + '/getAllUoms',req)
            .then(res => {
                return res.data
            })
    }
    async ActivatedeActivateUoms(uomsDto: UomsDto): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateUom', uomsDto)
            .then(res => {
                return res.data
            })
    }
    async getAllActiveUoms(): Promise<AllUomsResponseModel> {
        return await axios.post(this.URL + '/getAllActiveUoms')
            .then(res => {
                return res.data
            })
    }
    async deleteUoms(uoms: UomsDto): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/deleteUoms', uoms)
            .then(res => {
                return res.data
            })
    }
    async getselectedUoms(uoms: UomsDto): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/selectedUoms', uoms)
            .then(res => {
                return res.data
            })
    }
    async getActiveUomById(uoms: UomRequest): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/getActiveUomById', uoms)
            .then(res => {
                return res.data
            })
    }
    async getActiveUomsCount(): Promise<UomsResponseModel> {
        return await axios.post(this.URL + '/getActiveUomsCount').then(res => {
            return res.data
        });
    }

    async getAllUomsDropDownData(): Promise<UomsDropDownResponseModel> {
        return new UomsDropDownResponseModel(true,11,'customersData retrived successfully',[new UomsDropDownDto(1,'KG'),new UomsDropDownDto(2,'LB')])
        // return await axios.post(this.URL + '/getAllUomsDropDownData').then(res => {
        //     return res.data
        // });
    }

}