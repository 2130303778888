
import React from 'react';
import { Form, Input, Button, Select,Card, Row, Col } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";

import './level-form.css';
import {LevelService} from '@gtpl/shared-services/masters'
import { LevelDto } from '@gtpl/shared-models/masters';
/* eslint-disable-next-line */
export interface LevelFormProps {
  levelData:LevelDto;
  updateLevel:(level:LevelDto)=>void;
  isUpdate:boolean;
  closeForm: () => void;
}

export function LevelForm(
  props: LevelFormProps
) {
  const [form] = Form.useForm();
  let history = useHistory();

  let createdUser="";
  if(!props.isUpdate){
    createdUser= localStorage.getItem("createdUser");
  }
  const levelService=new LevelService();


  const createLevel=(levelData:LevelDto)=>{
    levelService.createLevel(levelData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Level Created Successfully');
        history.push("/level");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset=()=>{
    // console.log('hhhhhhhhhhhhhhhhhhhhhhh');
    form.resetFields();
    
  }

  const saveData = (values: LevelDto) => {
    if(props.isUpdate){
      console.log(values)
      props.updateLevel(values);
    }else{

      console.log(values)
      createLevel(values);
    }
  
  };
  
  return (
    <Card title={<span style={{color:'white'}}>{(!props.isUpdate)?"Add Level":"Update Level"}</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={(!props.isUpdate)?<Link to='/level' ><Button className='panel_button' >View </Button></Link> : ''}
    >
      <Form layout="vertical" form={form} initialValues={props.levelData} name="control-hooks" onFinish={saveData}>
      <Form.Item name="levelId" style={{display:"none"}} >
        <Input hidden/>
      </Form.Item>
    <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
      <Input hidden/>
    </Form.Item>
    <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name="levelCode"
                label="Level Code"
                rules={[
                  {
                    required: true,
                    message: `Enter Level Code`
                  },
                  {
                    pattern: /^[^-\s][A-Za-z0-9_@./$#&!*^%+-_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name="levelName"
                label="Level Name"
                rules={[
                  {
                    required: true,
                    message: `Enter Level Name`
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Don't Allow Spaces`
                  }
                ]}>
                <Input/>
              </Form.Item>
        </Col>
      </Row>
     
        <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
         <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
            Reset
          </Button>
          }
        </Col>
      </Form>
    </Card>
  );
}

export default LevelForm;
