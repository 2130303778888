import { ForkLiftJobStatus } from "./fork-lift-job-status.enum";

export class 
ForkLiftJobStatusRequest {
    jobId: number;
    status: ForkLiftJobStatus;

    constructor(jobId:number,status:ForkLiftJobStatus){
        this.jobId = jobId;
        this.status = status;
    }
}