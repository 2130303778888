import { EmployeeRolesEnum, EmpPrefixEnum, IndiaStatesEnum } from "@gtpl/shared-models/common-models";
import { EmployeeEducationDetailsDto, EmployeeExperienceDetailsDto, EmployeeFamilyDetailsDto, EmployeeIdProofDetailsDto, RolesList } from "@gtpl/shared-models/gtpl";

export class HrmsEmployeeDetailsDto {

    employeeType: string;
    employeeId: number;
    employeeCode: string;
    employeeName: string;
    mobileNumber: string;
    employeeRole: EmployeeRolesEnum;
    unitId: number;
    prefix: EmpPrefixEnum;
    dateOfBirth: any;
    gender: string;
    maritualStatus: string;
    altMobileNumber: string;
    emailID: string;
    bloodGroup: string;
    nationality: string;
    departmentId: string;
    priorityOne: string;
    priorityType: string;
    currentAddress: string;
    permanentAddress: string;
    qualification: string;
    yearsOfExperience: Number;
    companyEmail: string;
    employeeMode: string;
    employeeStatus: string;
    contractorName: string;
    shiftGroup: string;
    dateOfJoin: any;
    dateOfleave: any;
    workstationNumber: string;
    healthCardNumber: string;
    uanNumber: string;
    salary: string;
    bankAccountNumber: string;
    bankName: string;
    ifscCode: string;
    designation: string;
    dateOfEntry: any;
    emergencyContactNo: string;
    emergencyContactName: string;
    isProbPeriodCompleted: string;
    transportation: string;
    accommodation: string;
    isAuditCompleted: string;
    skillSet: string;
    pickUpPoint: string;
    routeNumber: string;
    panNumber: string;
    esiNumber: string;
    reportingManager: string;
    employeeCategory: string;
    wageType: string;
    aadhaarNumber: string;
    state: string;
    pinCode: string;
    permanentState: string;
    permanentPinCode: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    familyDetails?: EmployeeFamilyDetailsDto[];
    educationDetails?: EmployeeEducationDetailsDto[];
    experienceDetails?: EmployeeExperienceDetailsDto[];
    idProofDetails?: EmployeeIdProofDetailsDto[];
    employeeLastName?: string;
    Fname?: string;
    aadhaarCard?: number;
    mobilePhone?: number;
    address?: string;
    image?: string;

    /**
     * 
     * @param employeeType 
     * @param employeeId 
     * @param employeeCode 
     * @param employeeName 
     * @param mobileNumber 
     * @param employeeRole 
     * @param unitId 
     * @param prefix 
     * @param dateOfBirth 
     * @param gender 
     * @param maritualStatus 
     * @param altMobileNumber 
     * @param emailID 
     * @param bloodGroup 
     * @param nationality 
     * @param departmentId 
     * @param priorityOne 
     * @param priorityType 
     * @param currentAddress 
     * @param permanentAddress 
     * @param qualification 
     * @param yearsOfExperience 
     * @param companyEmail 
     * @param employeeMode 
     * @param employeeStatus 
     * @param contractorName
     * @param shiftGroup
     * @param dateOfJoin 
     * @param dateOfleave 
     * @param workstationNumber 
     * @param healthCardNumber 
     * @param uanNumber 
     * @param salary 
     * @param bankAccountNumber 
     * @param ifscCode 
     * @param designation 
     * @param dateOfEntry 
     * @param emergencyContactNo 
     * @param emergencyContactName 
     * @param isProbPeriodCompleted 
     * @param isAuditCompleted 
     * @param transportation 
     * @param accommodation 
     * @param skillSet 
     * @param panNumber 
     * @param esiNumber 
     * @param reportingManager 
     * @param bankName 
     * @param employeeCategory 
     * @param wageType 
     * @param aadhaarNumber 
     * @param state 
     * @param pinCode 
     * @param permanentState 
     * @param permanentPinCode 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     * @param familyDetails 
     * @param educationDetails 
     * @param experienceDetails 
     * @param idProofDetails 
     * @param employeeLastName 
     * @param image 
     */
    constructor(
        employeeType: string,
        employeeId: number,
        employeeCode: string,
        employeeName: string,
        mobileNumber: string,
        employeeRole: EmployeeRolesEnum,
        unitId: number,
        prefix: EmpPrefixEnum,
        dateOfBirth: Date,
        gender: string,
        maritualStatus: string,
        altMobileNumber: string,
        emailID: string,
        bloodGroup: string,
        nationality: string,
        departmentId: string,
        priorityOne: string,
        priorityType: string,
        currentAddress: string,
        permanentAddress: string,
        qualification: string,
        yearsOfExperience: Number,
        companyEmail: string,
        employeeMode: string,
        employeeStatus: string,
        contractorName: string,
        shiftGroup: string,
        dateOfJoin: Date,
        dateOfleave: Date,
        workstationNumber: string,
        healthCardNumber: string,
        uanNumber: string,
        salary: string,
        bankAccountNumber: string,
        ifscCode: string,
        designation: string,
        dateOfEntry: Date,
        emergencyContactNo: string,
        emergencyContactName: string,
        isProbPeriodCompleted: string,
        isAuditCompleted: string,
        transportation: string,
        accommodation: string,
        skillSet: string,
        panNumber: string,
        esiNumber: string,
        reportingManager: string,
        bankName: string,
        employeeCategory: string,
        wageType: string,
        aadhaarNumber: string,
        state: string,
        pinCode: string,
        permanentState: string,
        permanentPinCode: string,
        isActive: boolean,
        createdAt: Date,
        createdUser: string,
        updatedAt: Date,
        updatedUser: string,
        versionFlag: number,
        familyDetails?: EmployeeFamilyDetailsDto[],
        educationDetails?: EmployeeEducationDetailsDto[],
        experienceDetails?: EmployeeExperienceDetailsDto[],
        idProofDetails?: EmployeeIdProofDetailsDto[],
        employeeLastName?: string,
        image?: string,

    ) {
        this.employeeType = employeeType;
        this.employeeId = employeeId;
        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.mobileNumber = mobileNumber;
        this.emailID = emailID;
        this.employeeRole = employeeRole;
        this.unitId = unitId;
        this.prefix = prefix;
        this.dateOfBirth = dateOfBirth;
        this.gender = gender;
        this.maritualStatus = maritualStatus;
        this.altMobileNumber = altMobileNumber;
        this.bloodGroup = bloodGroup;
        this.nationality = nationality;
        this.departmentId = departmentId;
        this.priorityOne = priorityOne;
        this.priorityType = priorityType;
        this.currentAddress = currentAddress;
        this.permanentAddress = permanentAddress;
        this.qualification = qualification;
        this.yearsOfExperience = yearsOfExperience;
        this.companyEmail = companyEmail;
        this.employeeMode = employeeMode;
        this.employeeStatus = employeeStatus;
        this.contractorName = contractorName;
        this.shiftGroup = shiftGroup;
        this.dateOfJoin = dateOfJoin;
        this.dateOfleave = dateOfleave;
        this.workstationNumber = workstationNumber;
        this.uanNumber = uanNumber;
        this.healthCardNumber = healthCardNumber;
        this.salary = salary;
        this.bankAccountNumber = bankAccountNumber;
        this.ifscCode = ifscCode;
        this.designation = designation;
        this.dateOfEntry = dateOfEntry
        this.emergencyContactName = emergencyContactName;
        this.emergencyContactNo = emergencyContactNo;
        this.isProbPeriodCompleted = isProbPeriodCompleted;
        this.transportation = transportation;
        this.accommodation = accommodation;
        this.isAuditCompleted = isAuditCompleted;
        this.skillSet = skillSet;
        this.panNumber = panNumber;
        this.esiNumber = esiNumber;
        this.reportingManager = reportingManager;
        this.bankName = bankName;
        this.aadhaarNumber = aadhaarNumber;
        this.state = state;
        this.pinCode = pinCode;
        this.permanentState = permanentState;
        this.permanentPinCode = permanentPinCode;
        this.familyDetails = familyDetails;
        this.idProofDetails = idProofDetails;
        this.educationDetails = educationDetails;
        this.experienceDetails = experienceDetails;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.employeeCategory = employeeCategory;
        this.wageType = wageType;
        this.employeeLastName = employeeLastName;
        this.image = image;

    }

}