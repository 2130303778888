import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderItemInfo } from './sale-order-item.info';

export class SoItemDataResponse extends GlobalResponseObject {
    data?:  SaleOrderItemInfo[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?:  SaleOrderItemInfo[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}

