import axios from 'axios';

import connection from './connection';
import { AllContainerStatusInfo, BookContainerDto, ConfirmOrAssignSoData, ContainerStatusInfo, DispatchedToPlant, FillSealInfoDto, DraftReceived, ContainerDetailsResponse, ContainerSoIdRequest, EtdUpdateRequest, BillNoUpdateRequest,ContaierRegisterRequest, AllLoadingReportResponse, ContainerDetailsModel, ShipmentDetailsDto, ShippmentDetailsResponse, ContainerRequest, ContainerReportModel, ContainerBarChartResponseModel, Containertrnd } from '@gtpl/shared-models/logistics';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class ContainerRegisterService {
  URL = connection.DEPLOY_URL + '/container';

  async createContainer() {
    // return await axios
    //   .post(this.URL + '/confirmOrAssignSo', containerInfo)
    //   .then((res) => {
    //     return res.data;
    //   });
  }

  // To confirmOrAssignSo
  async confirmOrAssignSo(containerInfo:ConfirmOrAssignSoData) {
    return await axios
      .post(this.URL + '/confirmOrAssignSo', containerInfo)
      .then((res) => {
        return res.data;
      });
  }
  
  // To bookContainer
  async bookContainer(containerInfo:BookContainerDto){
    return await axios
      .post(this.URL + '/bookContainer', containerInfo)
      .then((res) => {
        return res.data;
      });
  }


  // To containerDispatchedToPlant
  async containerDispatchedToPlant(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/containerDispatchedToPlant', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To fill seal info
  async fillSealInfo(containerInfo:FillSealInfoDto){
    return await axios
      .post(this.URL + '/fillSealInfo', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To Update the status to ReadyToBook
  async updateToReadyToBook(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateToReadyToBook', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To Update the status to Booked
  async updateToBooked(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateToBooked', containerInfo)
      .then((res) => {
        return res.data;
      });
  }
  async updateStatusToLoading(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateStatusToLoading', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To Update the status to DispatchedToPlant
  async updateToDispatchedToPlant(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateToDispatchedToPlant', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To Update the status to Loading
  async updateToLoading(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateToLoading', containerInfo)
      .then((res) => {
        return res.data;
      });
  }

  // To Update the status to LoadedAndSealed
  async updateToLoadedAndSealed(containerInfo:DispatchedToPlant){
    return await axios
      .post(this.URL + '/updateToLoadedAndSealed', containerInfo)
      .then((res) => {
        return res.data;
      });
  }
  

  // get All Container Status Info
  async getAllContainerStatusInfo(req:UnitRequest){
    // console.log(containerRegisterRequest);
     return await axios
      .post(this.URL + '/getAllContainerStatusInfo', req)
      .then((res) => {
        // console.log(res);
        return res.data;
      });
  }


  // To initiate dispatch
  async initiateDispatch(containerInfo:ContainerStatusInfo){
    return await axios
     .post(this.URL + '/initiateDispatch', containerInfo)
     .then((res) => {
      return res.data;
    });
  }


  // To Ship the container
  async shipContainer(containerInfo:ContainerStatusInfo){
    return await axios
     .post(this.URL + '/shipContainer', containerInfo)
     .then((res) => {
      return res.data;
    });
  }
  async updateShipmentDetails(values:ShipmentDetailsDto) : Promise<ContainerDetailsResponse>{
    return await axios
     .post(this.URL + '/updateShipmentDetails', values)
     .then((res) => {
      return res.data;
    });
  }
  async getShipmentDetails(req: ContainerRequest): Promise<ShippmentDetailsResponse> {
    return await axios
     .post(this.URL + '/getShipmentDetails', req)
     .then((res) => {
      return res.data;
    });
  }



// ---------------- BL status services ------------------

  async getAllContainerBlStatusInfo(){
    return await axios
     .post(this.URL + '/getAllContainerBlStatusInfo')
     .then((res) => {
       return res.data;
     });
 }

 async notReceived(containerInfo:DraftReceived){
  return await axios
    .post(this.URL + '/notReceived', containerInfo)
    .then((res) => {
      return res.data;
    });
}

 async draftReceived(containerInfo:DraftReceived){
   console.log(containerInfo);
  return await axios
    .post(this.URL + '/draftReceived', containerInfo)
    .then((res) => {
      return res.data;
    });
}

async oblReceived(containerInfo:DraftReceived){
  return await axios
    .post(this.URL + '/oblReceived', containerInfo)
    .then((res) => {
      return res.data;
    });
}

async receivedAndShared(containerInfo:DraftReceived){
  return await axios
    .post(this.URL + '/receivedAndShared', containerInfo)
    .then((res) => {
      return res.data;
    });
}
async updateEtd(containerInfo:EtdUpdateRequest){
  console.log(containerInfo);
  return await axios
    .post(this.URL + '/updateEtd', containerInfo)
    .then((res) => {
      return res.data;
    });
}
async updateBlNo(containerInfo:BillNoUpdateRequest){
  return await axios
    .post(this.URL + '/updateBlNo', containerInfo)
    .then((res) => {
      return res.data;
    });
}

/**
 * To get containerNumber,lineSeal,Eseal
 * @Param saleOrderId
 */
async getContainerDeatils(soId:ContainerSoIdRequest): Promise<ContainerDetailsResponse>{
  // console.log(this.URL + '/getContainerDeatils');
  return await axios
    .post(this.URL + '/getContainerDeatils', soId)
    .then((res) => {
      return res.data;
    });
}

async getLoadingReportDetails(): Promise<AllLoadingReportResponse>{
  // console.log(this.URL + '/getContainerDeatils');
  return await axios
    .post(this.URL + '/getLoadingReportDetails')
    .then((res) => {
      return res.data;
    });
}

async getContainerReportData(): Promise<ContainerReportModel>{

  return await axios 
  .post(this.URL + '/getContainerReportData')
  .then((resposeData)=>{
    return resposeData.data;
  })
  
}
async getMonthWiseContainersData(req:Containertrnd):Promise<ContainerBarChartResponseModel>{
  return await axios
  .post(this.URL + '/getMonthWiseContainersData',req)
  .then((resposeData)=>{
    return resposeData.data;
  })
}

 async getTopFiveDestinations():Promise<CommonResponse>{
  return await axios
  .post(this.URL + '/getTopFiveDestinations')
  .then((resposeData)=>{
    return resposeData.data;
  })
 }
 async getLogisticsLifeCycleData(): Promise<CommonResponse>{
  return await axios 
  .post(this.URL + '/getLogisticsLifeCycleData')
  .then((resposeData)=>{
    return resposeData.data;
  })
 
}
async gettotalsaleorderShipped(req:Containertrnd):Promise<CommonResponse>{
  return await axios
  .post(this.URL + '/gettotalsaleorderShipped',req)
  .then((resposeData) =>{
    return resposeData.data;
  })
}

async totalsaleordersshiipedinyear(req:Containertrnd):Promise<CommonResponse>{
  return await axios
  .post(this.URL + '/totalsaleordersshiipedinyear',req)
  .then((resposeData) =>{
    return resposeData.data;
  })
}
async totalsaleordersShippedinDayWise(req:Containertrnd):Promise<CommonResponse>{
  return await axios
  .post(this.URL + '/totalsaleordersShippedinDayWise',req)
  .then((resposeData) =>{
    return resposeData.data;
  })
}
}
