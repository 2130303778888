export class OverTimeRequest {

    otDetails: { employeeCode: string, otType: string, otHours: any }[]
    date: Date;
    createdUser: string;

    constructor(otDetails: { employeeCode: string, otType: string, otHours: any }[], date: Date, createdUser: string) {
        this.otDetails = otDetails
        this.date = date
        this.createdUser = createdUser

    }

}