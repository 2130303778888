
export class AddressModel {
    addressId:string;
    doorNo:string;
    addressLine1:string;
    addressLine2:string;
    landmark:string;
    state:string;
    country:string;
    zipcode:string;
    createdAt: Date | any;
    updatedAt: Date | any;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
}



export const AddressModelDefault: AddressModel = {
    addressId: '',
    doorNo: '',
    addressLine1: '',
    addressLine2: '',
    landmark: '',
    state: '',
    country: '',
    zipcode: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdUser: '',
    updatedUser: '',
    versionFlag: 0
}