export class AssetsClassificationByIdDto {
    assetsClassificationId?: number;

    /**
     *
     * @param assetsCatagoryId
     */
    constructor(
        assetsClassificationId?: number,


    ) {
        this.assetsClassificationId = assetsClassificationId;

    }
}

export class assetLocation{
    plant:number;
}