import axios from "axios";
import connection from './connection';
import { OverTimeCategoryDto, OverTimeCategoryResponse, AllOverTimeCategoriesResponse, OverTimeCatIdRequest } from "@gtpl/shared-models/hrms";

export class OverTimeCategoryService{

    URL = connection.DEPLOY_URL + '/overtime-category';

    async create(dto: OverTimeCategoryDto): Promise<OverTimeCategoryResponse>{
        return await axios.post(this.URL+ '/createOverTimeCat', dto)
           .then(res => {
               return res.data
           })
    }

    async update(dto: OverTimeCategoryDto): Promise<OverTimeCategoryResponse>{
        return await axios.post(this.URL+ '/updateOverTimeCat', dto)
           .then(res => {
               return res.data
           })
    }

    async getAllOverTimeCategories(): Promise<AllOverTimeCategoriesResponse>{
        return await axios.post(this.URL + '/getAllOverTimeCategories')
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivate(dto: OverTimeCategoryDto): Promise<OverTimeCategoryResponse>{
        return await axios.post(this.URL+ '/activateOrDeactivate', dto)
           .then(res => {
               return res.data
           })
    }

    async getAllActiveOverTimeCats(): Promise<AllOverTimeCategoriesResponse>{
        return await axios.post(this.URL+ '/getAllActiveOverTimeCats')
        .then(res => {
            return res.data
        })
    }

}