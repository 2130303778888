import { GlobalStatus, ProductionTypeEnum } from "@gtpl/shared-models/common-models";
import { ApiProperty } from "@nestjs/swagger";

export class SoProductionDTO {
  soProductionId: number;
  type: ProductionTypeEnum;
  lotNumber: string;
  saleOrderId: number;
  saleOrderItemId: number; 
  inputQuantity: number;
  outputQuantity: number;
  createdAt?: Date; 
  createdUser: string | null;
  updatedAt?: Date;
  updatedUser: string | null;
  versionFlag: number
    // @ApiProperty()
    // soProductionId: number;
    // @ApiProperty({ enum: Object.values(ProductionTypeEnum) })
    // type: ProductionTypeEnum; 
    // @ApiProperty()
    // lotNumber: string;
    // @ApiProperty()
    // saleOrderId: number;
    // @ApiProperty()
    // saleOrderItemId: number; 
    // @ApiProperty()  
    // inputQuantity: number;
    // @ApiProperty()
    // outputQuantity: number;
    // @ApiProperty()
    // createdAt?: Date;
    // @ApiProperty()
    // createdUser: string | null;
    // @ApiProperty()
    // updatedAt?: Date;
    // @ApiProperty()
    // updatedUser: string | null;
    // @ApiProperty()
    // versionFlag?: number;

    // constructor(soProductionId: number,type: ProductionTypeEnum,lotNumber: string,saleOrderId: number,saleOrderItemId: number, inputQuantity: number,
    //     outputQuantity: number,
    // createdAt: Date,  createdUser: string | null,
    //     updatedAt: Date,
    //     updatedUser: string | null,
    //     versionFlag: number){
    //   this.soProductionId = soProductionId;
    //   this.type = type;
    //   this.lotNumber = lotNumber;
    //   this.saleOrderId = saleOrderId;
    //   this.saleOrderItemId = saleOrderItemId;
    //   this.inputQuantity = inputQuantity;
    //   this.outputQuantity= outputQuantity;
    //   this.createdAt = createdAt;
    //   this.createdUser = createdUser;
    //   this.updatedAt = updatedAt;
    //   this.updatedUser = updatedUser;
    //   this.versionFlag = versionFlag;
    // }
  }