export class QcDefectItemDetailsDto{
    qcdefectitemId:number;
    qcId: number;
    testResultId:number;
    rejectedQuantity:number; 
    createdAt:any;
    createdUser: string;
    updatedAt: any;
    isActive: boolean;
    updatedUser : string;
    versionFlag : number;
    testName?:string
constructor(qcdefectitemId:number,qcId: number, testResultId:number,rejectedQuantity:number,createdAt:Date,
    createdUser: string,
    updatedAt: Date,
    isActive: boolean,
    updatedUser : string,
    versionFlag : number,testName?:string){
    this.qcdefectitemId=qcdefectitemId;
    this.qcId=qcId;
    this.testResultId=testResultId;
    this.testName=testName;
    this.rejectedQuantity=rejectedQuantity;
    this.createdAt=createdAt;
    this.createdUser=createdUser;
    this.updatedAt=updatedAt;
    this.isActive=isActive;
    this.updatedUser=updatedUser;
    this.versionFlag=versionFlag;

}
}