import { TransactionType } from "@gtpl/shared-models/common-models";
import { ForkLiftItemsDTO } from "../fork-lift-items";
import { ForkLiftJobStatus } from "./fork-lift-job-status.enum";

export class ForkLiftJobDTO {
    jobId: number;
    jobCode: string;
    transactionTime: Date;
    status: ForkLiftJobStatus;
    reportedId: number;
    transactionType: TransactionType;
    createdUser: string;
    plantId: number;
    lotCode?: string;
    updatedUser?: string;
    totalJobCartons?: number;
    forkLiftItemsInfo: ForkLiftItemsDTO[];
    totalIssuedQuantity?: number;
    totalReceivedQuantity?: number;
    damagedQuantity?: number;
    excessQuantity?: number;
    fromPlantId?: number;
    toPlantId?: number;
    jobPurpose?: string;

    
   
    constructor(jobId: number,
    jobCode: string,
    transactionTime: Date,
    status: ForkLiftJobStatus,
    reportedId: number,
    transactionType: TransactionType,
    forkLiftItemsInfo: ForkLiftItemsDTO[], createdUser: string, plantId: number,lotCode: string,
    updatedUser?: string,totalJobCartons?: number,totalIssuedQuantity?: number,totalReceivedQuantity?: number,damagedQuantity?: number,excessQuantity?: number,fromPlantId?: number,
    toPlantId?: number,jobPurpose?: string){
        this.jobId= jobId;
        this.jobCode= jobCode;
        this.transactionTime= transactionTime;
        this.status= status;
        this.reportedId= reportedId;
        this.transactionType= transactionType;
        this.forkLiftItemsInfo= forkLiftItemsInfo;
        this.createdUser = createdUser;
        this.plantId = plantId;
        this.lotCode =  lotCode;
        this.updatedUser =  updatedUser;
        this.totalJobCartons =  totalJobCartons;
        this.totalIssuedQuantity =  totalIssuedQuantity;
        this.totalReceivedQuantity =  totalReceivedQuantity;
        this.damagedQuantity =  damagedQuantity;
        this.excessQuantity =  excessQuantity;
        this.fromPlantId =  fromPlantId;
        this.toPlantId =  toPlantId;
        this.jobPurpose =  jobPurpose;
    }
}
