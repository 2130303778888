import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { EmployeeDto } from './employee-dto';


export class AllEmployeesResponseModel extends GlobalResponseObject {
    data?: EmployeeDto[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: EmployeeDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

