import { SizesDto } from "../sizes";
import { SkuDto, SkuResponseModel } from "../sku";

export class ItemsDropDownDto{
    itemId : number;
    itemName : string;
    sizeName?:string;
    sizeId?:number;
    sizeInfo?:SizesDto[]
    product?:string;
    foodType?:string;
    valAddFour?:string;
    valAddFive?:string;
    itemSubCategoryId?:number;
    brand?:number;
    isAsset?:number;
    
    /**
     * 
     * @param itemId number
     * @param itemName string
     */
    constructor(itemId : number,itemName : string, sizeName?:string, sizeId?:number,sizeInfo?:SizesDto[],product?:string,
        foodType?:string,
        valAddFour?:string,
        valAddFive?:string,itemSubCategoryId?:number,brand?:number,isAsset?:number){
        this.itemId = itemId;
        this.itemName = itemName;
        this.sizeName = sizeName;
        this.sizeId = sizeId;
        this.sizeInfo = sizeInfo;
        this.product = product;
        this.foodType = foodType;
        this.valAddFour = valAddFour;
        this.valAddFive = valAddFive
        this.itemSubCategoryId = itemSubCategoryId
        this.isAsset = isAsset
        this.brand = brand
    }
}