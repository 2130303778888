import { IdProofsTypeDto } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class IdProofsTypeResponseModel extends GlobalResponseObject{
    data?: IdProofsTypeDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //IdProofsTypeDto
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: IdProofsTypeDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
