export class OrderInfo {
    brandName: string;
    poNumber: string;
    etd: Date;
    itemName: string;
    quantity: number;
    requiredQty: number;
    assignQty: number;
    poQty: number;
    receivedQty: number;
    minQnty: number;
    unitId: number;
    itemId: number;
    bomId: number;
    saleOrderId: number;
}