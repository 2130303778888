import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import {SaleOrderTrackingReportGrid} from '@gtpl/pages/sale-management/sale-components/sale-order-tracking-report-grid'
// import './sale-order-tracking-report-pages.css';
import {SaleOrderService} from '@gtpl/shared-services/sale-management';
import {DestinationService, EndCustomersService, UnitcodeService} from '@gtpl/shared-services/masters';
import { AlertMessages} from '../../../../../../shared-utils/alert-messages/src';

import form from 'antd/lib/form';
import { SaleOrderFiltersDto, SaleOrderStatusDropdownDto, SaleOrderTrackDto } from '@gtpl/shared-models/sale-management';
/* eslint-disable-next-line */
const { Option } = Select;
import moment from 'moment';
import { DestinationDto, EndCustomerDto, EndCustomersDropDownData, PlantsDropDown } from '@gtpl/shared-models/masters';
import { EndCustomerSOTrackGrid } from '..';

export interface EndCustomerSoReportProps {}

export function EndCustomerSoReport(
  props: EndCustomerSoReportProps
) {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(true)
  const [saleOrderData, setSaleOrderData] = useState<any[]>([])
  const [selectedMonth, setSelectedMonth]= useState('');
  const [saleOrderStatus, setSaleOrderStatus]= useState(null);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate]= useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate]= useState(undefined);
  const [selectedEndCustomer, setSelectedEndCustomer]= useState<number>(0);
  const [destinationData, setDestinationData]= useState<DestinationDto[]>([]);
  const [plantsData, setPlantsData]= useState<PlantsDropDown[]>([]);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<SaleOrderTrackDto[]>([])
  const [initialData, setInitialData] = useState<SaleOrderTrackDto[]>([])
  const [trackFilters, setTrackFilters] = useState<SaleOrderFiltersDto>(null)
  const [statusList, setStatusList] = useState<SaleOrderStatusDropdownDto[]>(null)
  const [endCustomers, setEndCustomers] = useState<EndCustomerDto[]>(null)
  const [unit, setUnit] = useState<number>(0)
  const [updateState, setUpdateState] = useState<number>(0)
  const [destinations, setDestinations] = useState<number[]>([])
  const service = new SaleOrderService;
  const destinationservice = new DestinationService();
  const endCustomersService = new EndCustomersService;
  const unitcodeService = new UnitcodeService;

  const onFocus=() =>{
    console.log('focus');
  }
  
  const onSearch=(val)=> {
    console.log('search:', val);
  }
  const onBlur=() =>{
    console.log('blur');
  }
  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    console.log(form.getFieldsValue());
    setSelectedEstimatedFromDate(null)
    setSelectedEstimatedToDate(null)
    setSaleOrderStatus(null)
    setSelectedEndCustomer(0)
    setDestinations([])
    setSelectedSaleOrder([]);


    // setSelectedSaleOrder(initialData)
    // setUpdateState(prevState => prevState+1)
  
    
  };
  const handleChange=(value) =>{
    console.log(value.format('YYYY-MM-DD'));
    setSelectedMonth(value.format('YYYY-MM-DD'))
  }
  const handleSaleOrder = (value) => {
    console.log(`selected ${value}`);
    setSelectedSaleOrder(value);
  }
  


  // console.log(saleData);
  const viewSaleOrderReport= (saleOrderFiltersDto: SaleOrderFiltersDto, viewOnly: boolean = true) => {

    setTrackFilters({selectedEstimatedFromDate,selectedEstimatedToDate,saleOrderStatus,selectedEndCustomer,unit,destinations});
    console.log({trackFilters});
    getAllSaleOrdersTrackData(selectedEstimatedFromDate,selectedEstimatedToDate,saleOrderStatus,selectedEndCustomer,unit,destinations);
  // setShowSaleOrders(true);
}

useEffect(() => {
  setTimeout(() => setLoading(false), 1000); 
  console.log(trackFilters);
  getFinalDestinationData();
  
  getAllSaleOrdersTrackData(selectedEstimatedFromDate,selectedEstimatedToDate,saleOrderStatus,selectedEndCustomer,unit,destinations);
  getStatusList();
  getActiveEndCustomersDropDownData();
  // setSaleOrderData(saleData); console.log(saleData);
  getAllActivePlants();
}, [])

const getAllActivePlants = () => {
  unitcodeService.getAllMainPlants().then((res) => {
    if (res.status) {
      setPlantsData(res.data);
    } else {
      // if (res.intlCode) {
      //   AlertMessages.getErrorMessage(res.internalMessage);
      // } else {
      //   AlertMessages.getErrorMessage(res.internalMessage);
      // }
      setPlantsData([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setPlantsData([]);
  });
}
const getFinalDestinationData = () => {
  destinationservice.getAllActiveDestination().then((res) => {
    if (res.status) {
      setDestinationData(res.data);
    } else {
      // if (res.intlCode) {
      //   AlertMessages.getErrorMessage(res.internalMessage);
      // } else {
      //   AlertMessages.getErrorMessage(res.internalMessage);
      // }
      setDestinationData([]);
    }
  }).catch((err) => {
    AlertMessages.getErrorMessage(err.message);
    setDestinationData([]);
  });
}

const getAllSaleOrdersTrackData= async (selectedEstimatedFromDate,selectedEstimatedToDate,saleOrderStatus,selectedEndCustomer,unit,destinations) => {
  service.getAllSaleOrdersTrackDataNew({selectedEstimatedFromDate,selectedEstimatedToDate,saleOrderStatus,selectedEndCustomer,unit,destinations}).then(res => {
    if (res.status) 
    {
      console.log(res.data);
      setSelectedSaleOrder(res.data);
      setInitialData(res.data)
    } else {
      if (res.intlCode) {
        setSelectedSaleOrder([]);
          AlertMessages.getErrorMessage(res.internalMessage);
      } else {
       AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
    setSelectedSaleOrder([]);
  })
}

const getActiveEndCustomersDropDownData = () => {
  endCustomersService.getAllActiveEndCustomers().then((res) => {
    if (res.status) {
      setEndCustomers(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setEndCustomers([]);
    }
  })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEndCustomers([]);
    });
}
const getStatusList = () => {
  service.getStatusList().then((res) => {
    if (res.status) {
      setStatusList(res.data);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
      setStatusList([]);
    }
  })
    .catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setStatusList([]);
    });
}

const EstimatedETDDate=(value) =>{
  const fromDate = new Date(value[0].format('YYYY-MM-DD'));
  const toDate = new Date(value[1].format('YYYY-MM-DD'));
  setSelectedEstimatedFromDate(fromDate)
  setSelectedEstimatedToDate(toDate)
}

const handleEndCustomer=(value) =>{
  console.log(value);
  setSelectedEndCustomer(value)
}
const handleUnit=(value) =>{
  setUnit(value)
}

const handleDestination=(value) =>{
  console.log(value)
  setDestinations(value)
}

const handleStatus = (val) => {
  setSaleOrderStatus(val);
}
  return (
    <>
   {loading === false ? (
  <>
   <Card title={<span style={{color:'white'}}>End Customer Wise Sales Report</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}>
     <br></br>
    {/* <Card title="Sale Order Tracking Report" style={{ padding: '0px' }}> */}
          <Form form={form} layout={'vertical'} style={{ padding: '0px' }}  onFinish={viewSaleOrderReport}>
            <Row gutter={[24, 24]}>
            <Col span={5}>
              <Form.Item name="fromDate"
                label="ETD Date"
                rules={[
                  {
                    required: false,
                    message: "Missing EDD Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate}/>
              </Form.Item>
            </Col>
              {/* <Col>
              
                <Form.Item name="estimatedFromDate"
                  label="ETD From Date"
                  rules={[
                    {
                      required: false,
                      message: "ETD From Date"
                    },
                  ]}>
                  <DatePicker placeholder={"select from date"}  onChange={EstimatedFromDate} format={"YYYY-MM-DD"} picker="date" />
                </Form.Item>
              </Col>
            
              <Col>
                <Form.Item name="estimatedToDate"
                  label="ETD To Date"
                  rules={[
                    {
                      required: false,
                      message: "ETD To Date"
                    },
                  ]}>
                  <DatePicker placeholder={"select to date"} onChange={EstimatedToDate} picker="date" />
                </Form.Item>
              </Col> */}
            
              <Col span={3}>
                <Form.Item name="saleOrderStatus" label="SO Status" rules={[{ required: false }]}>
                    <Select
                        placeholder="SO Status"
                        onChange={handleStatus}
                        allowClear
                    >
                {/* <Option key={0} value={null}>Select All Status</Option> */}
                      {statusList.map(skustatus => {
                      return <Option key={skustatus.saleOrderStatus} value={skustatus.saleOrderStatus}>{skustatus.saleOrderStatus}</Option>
                    })} 

                    </Select>
                </Form.Item>
              </Col>
              {/* <Col span={4}>
                <Form.Item name="endCustomers" label="End Customer" rules={[{ required: false }]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder=" Select End Customer"
                        onChange={handleEndCustomer}
                        allowClear
                    >
                      <Option key={0} value={null}>Select All End Customer</Option>
                      {endCustomers?endCustomers.map(endCust => {
                      return <Option key={endCust.endCustomerId}  value={endCust.endCustomerId}>{endCust.endCustomerName}</Option>
                    }):""} 

                    </Select>
                </Form.Item>
              </Col> */}
              <Col span={3}>
                <Form.Item name="unit" label="Unit" rules={[{ required: false }]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select Unit"
                        onChange={handleUnit}
                        allowClear
                    >
                      <Option key={0} value={null}>Select Unit</Option>
                      {plantsData?plantsData.map(plant => {
                      return <Option key={plant.plantId}  value={plant.plantId}>{plant.plantName}</Option>
                    }):""}

                    </Select>
                </Form.Item>
              </Col>
              
          
           <Col span={5}>
                <Form.Item name="destination" label="Destination" rules={[{ required: false }]}>
                    <Select
                        showSearch
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select Destination"
                        onChange={handleDestination}
                        allowClear
                        mode="multiple"
                    >
                      {destinationData.map(dropData => {
                  return <Option value={dropData.destinationId}>{dropData.destinationName}</Option>
                })}

                    </Select>
                </Form.Item>
              </Col>
            {/* </Row>
           <Row gutter={24}> */}
              <Col style={{ paddingLeft:'10px', marginTop:'30px' }}>
                <Form.Item >
                  <Button type="primary" 
                  onClick={()=>getAllSaleOrdersTrackData}
                  htmlType="submit">
                    Get Details
                  </Button>
                </Form.Item>
              </Col>
              <Col style={{ paddingLeft:'10px', marginTop:'30px' }}>
                <Form.Item>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <EndCustomerSOTrackGrid saleOrderTrackingReportData={selectedSaleOrder} endCustomers={endCustomers} />

        </Card>
  </>
  ): (
    <div className="spins">
    <Spin size="large"/>
    </div>
  )}
    </>
  );
}

export default EndCustomerSoReport;
