import React, { useEffect, useRef, useState } from 'react';
import { Table, Card, Select, Form, Button, Row, Col, Input,Drawer, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import './rejections-grid.css';
import { SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Route, useHistory } from "react-router-dom";
import {ProductionInventoryService} from '@gtpl/shared-services/production'
import { ProductionInventoryDetails } from '@gtpl/shared-models/production-management';
import {AssignRejections} from '@gtpl/pages/production-management/production-management-components/assign-rejections'
import { UnitRequest } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface RejectionsGridProps {}

export function RejectionsGrid(
  props: RejectionsGridProps
) {
  const plantId = JSON.parse(localStorage.getItem('unit_id'))
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(''); 
  const proInv = new ProductionInventoryService()
  const [rejectionsData, setRejectionsData] = useState<ProductionInventoryDetails[]>([]);
  const [assignRejectionToSO,setAssignRejectionToSO] = useState<ProductionInventoryDetails>(undefined);
  const [drawerVisible, setDrawerVisible] = useState(false);   
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  useEffect(()=>{
    getAllRejections();
  },[])
  const getAllRejections = () => {
    const unitIdReq: UnitRequest = {
      unitId: plantId
    }
    proInv.getAllRejections(unitIdReq).then((res) => {
      console.log(res.data);
      if (res.status) {
        setRejectionsData(res.data);
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setRejectionsData([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setRejectionsData([]);
      });
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Lot Code',
      dataIndex: 'concatLotNum',
      sorter: (a, b) => a.concatLotNum.length - b.concatLotNum.length || a.concatLotNum.localeCompare(b.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('concatLotNum'),
    },
    {
      title: 'Planned SO No.',
      dataIndex: 'saleOrder',
      sorter: (a, b) => a.saleOrder.length - b.saleOrder.length || a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder'),
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.length - b.poNumber.length || a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'Varient Code',
      dataIndex: 'varientCode',
      sorter: (a, b) => a.varientCode.length - b.varientCode.length || a.varientCode.localeCompare(b.varientCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('varientCode'),
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      sorter: (a, b) => a.operation.length - b.operation.length || a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('operation'),
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectedQuantity',
      sorter: (a, b) => a.rejectedQuantity - b.rejectedQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('rejectedQuantity'),
    },
    {
      title: 'Rejection Assigned',
      dataIndex: 'assignedRejection',
      sorter: (a, b) => a.assignedRejection - b.assignedRejection,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedRejection'),
    },
    {
      title: 'Action',
      key:'action',
      width:100,
      render:(value,record)=>(record.rejectedQuantity > record.assignedRejection? <Tooltip title="Assign Rejected Qty To SO">
          <DeliveredProcedureOutlined onClick={event=>openFormWithData(record)} style={{fontSize:'24px',color:'#1890ff'}}/>
        </Tooltip>:null)
    },
  ];

  const closeDrawer = () =>{
    setDrawerVisible(false);
    setAssignRejectionToSO(null);
  }

  const openFormWithData= (viewData: ProductionInventoryDetails) =>{
    console.log(viewData);
    setDrawerVisible(true);
    setAssignRejectionToSO(viewData);
  }
  let viewPortwidth = window.innerWidth;

  var processingQty = rejectionsData.reduce(function (previousValue, currentValue) {
    return previousValue + Number(currentValue.rejectedQuantity - currentValue.assignedRejection);
  }, 0);
  var assignedQty = rejectionsData.reduce(function (previousValue, currentValue) {
      return previousValue + Number(currentValue.assignedRejection);
  }, 0);
  return (
    <Card
      title={<span style={{ color: 'white' }}>Rejection Inventory</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'No. of Lots : '+ rejectionsData.length}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Quantity To be Assign :'+ processingQty}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Rejection Assigned : '+ assignedQty}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br/>
      <br/>
      {/* <Row style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
        {/* <Link to='/'><Button danger type="primary" htmlType="submit">
          Assign Rejections
        </Button></Link> */}
      {/* </Row> */}
      <br/>
      <br/>

      <Table columns={columnsSkelton} dataSource={rejectionsData} bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Assign Rejections Form' width={viewPortwidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              {assignRejectionToSO?<AssignRejections 
                rejectionsData={assignRejectionToSO}
                closeForm={closeDrawer} 
                />:''}
            </Card>
          </Drawer>
    </Card>
  );
}

export default RejectionsGrid;
