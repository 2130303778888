import { TransferStatusEnum } from "@gtpl/shared-models/common-models";

export class TransferIdRequest {
    assetTransferId?: number;
    updatedUser?: string;
    receivedDate?: Date;
    createdUser?: string;
    updatedUserId?:number;
    constructor(assetTransferId?: number, updatedUser?: string, receivedDate?: Date, createdUser?: string,  updatedUserId?:number
        ) {
        this.assetTransferId = assetTransferId;
        this.receivedDate = receivedDate;
        this.updatedUser = updatedUser;
        this.createdUser = createdUser;
        this.updatedUserId = updatedUserId;
    }
}