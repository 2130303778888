export class SkillsDto {
    skillsId?: number;
    skills: string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(skillsId: number, skills: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.skillsId = skillsId;
        this.skills = skills;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}