import axios from 'axios';
import connection from './connection';
import { IndentDropdownResponse, IndentAckResponse, IndentModel, AllIndentsResponseModel, IndentRequest, UpdateVehStatusRequest, GrnStatusUpdate, GrnCompletedStatusResponse, IndentQuantityRequest, IndentQtyResponse, GetHarvestingResponseModel, PlantRequest, IndentRtRequest, IndentReportResponse, HarvestReportResponse, HarvestModel, HarvestIdRequest, IndentDetailsModel, IndentResponseModel, AllIndentReportResponse, IndentAbstractRequest } from '@gtpl/shared-models/raw-material-procurement';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class IndentService {
    URL = connection.DEPLOY_URL + '/indent';
    // userid = connection.userid;
    // role=connection.role;
    // unitId=connection.unit_id;

    async createIndent(indentModel: IndentDetailsModel): Promise<IndentResponseModel> {
        return await axios.post(this.URL + '/createIndent', indentModel)
            .then(res => {
                return res.data
            })
    }

    async updateIndent(indentModel: IndentDetailsModel): Promise<IndentResponseModel> {
        return await axios.post(this.URL + '/updateIndent', indentModel)
            .then(res => {
                return res.data
            })
    }


    async getAllIndents(): Promise<AllIndentsResponseModel> {
        return await axios.post(this.URL + '/getAllIndents',
            // {headers: { "userid" : this.userid,"role":this.role,"unit_id":this.unitId}}
        )
            .then(res => {
                return res.data
            })
    }

    async getCompletedIndents(): Promise<AllIndentsResponseModel> {
        return await axios.post(this.URL + '/getClosedIndents',
            // {headers: { "userid" : this.userid,"role":this.role,"unit_id":this.unitId}}
        )
            .then(res => {
                return res.data
            })
    }
    async getIndentsForDropdown(): Promise<IndentDropdownResponse> {
        return await axios.post(this.URL + '/getIndentsForDropdown')
            .then(res => {
                return res.data
            })
    }
    async getIndentDetailsById(indentIdReq: IndentRequest): Promise<IndentAckResponse> {
        return await axios.post(this.URL + '/getIndentDetailsById', indentIdReq)
            .then(res => {
                return res.data
            })
    }
    async updateVehicleStatus(updateVehStatusRequest: UpdateVehStatusRequest): Promise<any> {
        return await axios.post(this.URL + '/updateVehicleStatus', updateVehStatusRequest)
            .then(res => {
                return res.data
            })
    }

    async updateGrnCompletedStatus(grnInfo: GrnStatusUpdate): Promise<GrnCompletedStatusResponse> {
        return await axios.post(this.URL + '/updateGrnCompletedStatus', grnInfo)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateIndent(indentModel: IndentModel): Promise<IndentAckResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateIndent', indentModel)
            .then(res => {
                return res.data
            })
    }

    async getProductCountWiseIndentQty(inputReq: IndentQuantityRequest): Promise<IndentQtyResponse> {
        return await axios.post(this.URL + '/getProductCountWiseIndentQty', inputReq)
            .then(res => {
                return res.data
            })
    }
    async getIndentQty(): Promise<IndentQtyResponse> {
        return await axios.post(this.URL + '/getIndentQty')
            .then(res => {
                return res.data
            })
    }

    async getPendingGrnQty(): Promise<IndentQtyResponse> {
        return await axios.post(this.URL + '/getPendingGrnQty')
            .then(res => {
                return res.data
            })
    }


    async getHarvestinData(inputReq: PlantRequest): Promise<GetHarvestingResponseModel> {
        return await axios.post(this.URL + '/getHarvestinData', inputReq)
            .then(res => {
                return res.data
            })
    }
    async cancelAssignedVehicle(inputReq: IndentModel): Promise<CommonResponse> {
        return await axios.post(this.URL + '/cancelAssignedVehicle', inputReq)
            .then(res => {
                return res.data
            })
    }

    async getIndentReportDetails(inputReq: IndentRtRequest): Promise<AllIndentReportResponse> {
        return await axios.post(this.URL + '/getIndentReportDetails', inputReq)
            .then(res => {
                return res.data
            })
    }


    async createHarvestReport(inputReq: HarvestModel): Promise<HarvestReportResponse> {
        return await axios.post(this.URL + '/createHarvestReport', inputReq)
            .then(res => {
                return res.data
            })
    }

    async getHarvestReport(inputReq: HarvestIdRequest): Promise<HarvestReportResponse> {
        return await axios.post(this.URL + '/getHarvestReport', inputReq)
            .then(res => {
                return res.data
            })
    }
    async getIndentDataById(inputReq: IndentRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getIndentDataById', inputReq)
            .then(res => {
                return res.data
            })
    }

    async openOrCloseGrn(inputReq: IndentRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/openOrCloseGrn', inputReq)
            .then(res => {
                return res.data
            })
    }

    async getIndentAbstract(inputReq: IndentAbstractRequest): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getIndentAbstract',inputReq)
            .then(res => {
                return res.data
            })
     }

    

}


