import { DocumentType } from "@gtpl/shared-models/common-models";

export class DocumentsListRequest {
    saleOrderId: number;
    documentCategory: string;
    file: string[];
    documentType: DocumentType;
    constructor(
        saleOrderId: number,
        documentCategory: string,
        file: string[],
        documentType: DocumentType,
        ) {
        this.saleOrderId = saleOrderId;
        this.documentCategory = documentCategory;
        this.file = file;   
        this.documentType = documentType;   
    }
    
}