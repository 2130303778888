import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { Qcdefectdto } from './qc-defect.dto';


export class QcdefectResponsemodel extends GlobalResponseObject{

    data?:Qcdefectdto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: Qcdefectdto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }


}