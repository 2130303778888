import React, { useState, useEffect } from 'react';
import { BackTop, Card, Tabs  } from 'antd';
import './warehouse-dashboard.css';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import {ZoneService} from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PlantsIdRequest } from '@gtpl/shared-models/masters';
import ZoneDisplay from './zone-display';
import Unit1ZoneDisplay from './unit1-zone-display';
import MainDashboard from './main-dashboard';
const { TabPane } = Tabs;
/* eslint-disable-next-line */
export interface WarehouseDashboardProps {}

export function WarehouseDashboard(props: WarehouseDashboardProps) {
  const plantId = Number(localStorage.getItem('unit_id'));
  const [zones,setZones] = useState([]);
  const [renderKey,setRenderKey] = useState(0);
  const [activeKey,setActiveKey] = useState('');
  const zoneService = new ZoneService();

  useEffect(() => {
    getAllPlantZones();
  }, []);

  // get all zones
  const getAllPlantZones=()=>{
    zoneService.getPlantZones(new PlantsIdRequest(plantId)).then((res)=>{
      if(res.status){ 
        setZones(res.data);
        setActiveKey(res.data[0].zoneId.toString());
      }else{
        if(res){
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  const tabScroll = (key) => {
    return "bottom"
  }
  const onTabClick = (key) => {
    setRenderKey(renderKey+1);
    setActiveKey(key);
  }


  return (
  <>
    <Card  title={<span style={{ color: 'white' }}>Warehouse Dashboard</span>} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
    <BackTop />
    <Tabs onChange={onTabClick} defaultActiveKey={activeKey} type="card" centered key={renderKey} onTabScroll={tabScroll}>
      {
        zones.map(zone => {
          return (
            <>
              <TabPane tab={zone.zoneName} key={zone.zoneId} style={{overflow: "auto"}}>
                {
                  plantId === 5?<MainDashboard plantId={plantId} zoneId={zone.zoneId} />:plantId === 2?
                  <Unit1ZoneDisplay plantId={plantId} zoneId={zone.zoneId} zoneName={zone.zoneName} />
                  :<ZoneDisplay plantId={plantId} zoneId={zone.zoneId} />

                }
              </TabPane>
            </>
          )
        })
      }
    </Tabs>
    </Card>
  </>
  );
}

export default WarehouseDashboard;
