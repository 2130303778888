import axios from 'axios';
import connection from './connection';
import {ItemsDto,ItemResponse,AllItemResponse, ItemsDropDownResponseModel, ItemRequest, ItemsDropDownDto, ItemDetailsResponse, ItemsReqForCategoryId, BomItemRequest, RmBomItemRequest, GetItemForProductRequest, ItemsReqForSubCategoryId, ItemForBrandReq, ItemCodeRequest, AssetsDropDownResponseModel} from '@gtpl/shared-models/masters'
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { CommonResponse } from '@gtpl/shared-models/production-management';
import { ItemBySubcategory } from 'libs/shared-models/masters/src/lib/items/item-by-subcategory';

export class ItemsService{
    URL = connection.DEPLOY_URL + '/items';


    async createItem(itemsdto: ItemsDto ): Promise<ItemResponse> {
        console.log(itemsdto);
       
        return await axios.post(this.URL + '/createItem',itemsdto)
            .then(res => {
                return res.data
            })
        }

     async  updateItem(itemsdto: ItemsDto): Promise<ItemResponse> {
         return await axios.post(this.URL + '/updateItem', itemsdto)
            .then(res => {
             return res.data
       })
     }
        
     async  activatedeActivateItem(itemsDto: ItemsDto): Promise<ItemResponse> {
         //   console.log(State.stateCode);
         return await axios.post(this.URL + '/activateOrDeactivateItem', itemsDto)
                 .then(res => {
                     return res.data
                 })
     }

     async getAllItems(): Promise<AllItemResponse> {
                
         return await axios.post(this.URL + '/getAllItems')
             .then(res => {
                 return res.data 
             })     
     }

     async getItemByCategoryId(itemCatId :ItemsReqForCategoryId): Promise<ItemsDropDownResponseModel> {
         return await axios.post(this.URL + '/getItemByCategoryId',itemCatId)
             .then(res => {
                 return res.data 
             })     
     }

     async getItemForCategoryAndSubCat(itemCatId :ItemsReqForCategoryId): Promise<ItemsDropDownResponseModel> {
                
        return await axios.post(this.URL + '/getItemForCategoryAndSubCat',itemCatId)
            .then(res => {
                return res.data 
            })     
    }

    async getItemBySubCategoryId(itemSubCatId :ItemsReqForSubCategoryId): Promise<ItemsDropDownResponseModel> {
        return await axios.post(this.URL + '/getItemBySubCategoryId',itemSubCatId)
            .then(res => {
                return res.data 
            })     
    }

     async getItemById(itemRequest:ItemRequest): Promise<ItemsDropDownDto> {
         return await axios.post(this.URL + '/getItemById',itemRequest).then((res) => {
           return res.data;
         });
     }

     async getItemForItemId(itemRequest:ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemForItemId',itemRequest).then((res) => {
          return res.data;
        });
    }

     async getItemForBom(bomItemRequest:BomItemRequest): Promise<ItemResponse | any> {
        return await axios.post(this.URL + '/getItemForBom',bomItemRequest).then((res) => {
          return res.data;
        });
    }

    async getRmItemForBom(bomItemRequest:RmBomItemRequest): Promise<ItemResponse | any> {
        return await axios.post(this.URL + '/getRmItemForBom',bomItemRequest).then((res) => {
          return res.data;
        });
    }

    async getItemForProduct(bomItemRequest:GetItemForProductRequest): Promise<ItemResponse | any> {
        return await axios.post(this.URL + '/getItemForProduct',bomItemRequest).then((res) => {
          return res.data;
        });
    }

    

    async getTotalItemDataById(itemReq:ItemRequest): Promise<ItemResponse> {
        return await axios.post(this.URL + '/getTotalItemDataById',itemReq).then((res) => {
          return res.data;
        });
    }
    async getTotalItemDataByCode(itemReq:ItemCodeRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTotalItemDataByCode',itemReq).then((res) => {
          return res.data;
        });
    }

     async getItemsDropDownData(): Promise<ItemsDropDownResponseModel> {
                
        return await axios.post(this.URL + '/getItemsDropDownData',undefined)
            .then(res => {
                return res.data 
            })     
    }

    async getPMItemsDropDownData(req?:UnitRequest): Promise<ItemsDropDownResponseModel> {
                
        return await axios.post(this.URL + '/getPMItemsDropDownData',undefined)
            .then(res => {
                return res.data 
            })     
    }

    async getPouchItemForBom(bomItemRequest:BomItemRequest): Promise<ItemResponse | any> {
        return await axios.post(this.URL + '/getPouchItemForBom',bomItemRequest).then((res) => {
          return res.data;
        });
    }

    async getItemForBrandAndSubCategory(req:ItemForBrandReq): Promise<ItemsDropDownResponseModel | any> {
        return await axios.post(this.URL + '/getItemForBrandAndSubCategory',req).then((res) => {
          return res.data;
        });
    }

    async getItemsForSubCategory(req:ItemForBrandReq): Promise<ItemsDropDownResponseModel | any> {
        return await axios.post(this.URL + '/getItemsForSubCategory',req).then((res) => {
          return res.data;
        });
    }
    

    async getitemsforsubcategories(req:ItemForBrandReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getitemsforsubcategories',req).then((res) => {
          return res.data;
        });
    } 
    
    async getAllGeneralItems(req: ItemBySubcategory): Promise<AllItemResponse> {
                
        return await axios.post(this.URL + '/getAllGeneralItems',req)
            .then(res => {
                return res.data 
            })     
    }
    async getAssetDropdown(): Promise<AssetsDropDownResponseModel> {
                
        return await axios.post(this.URL + '/getAssetDropdown')
            .then(res => {
                return res.data 
            })     
    }
}
        
    