export class DownTimeReasonDto{
    downtimereasonId?:number;
    downtimeReason?:string;
    downtimestatus?:string;
    sla:number;
    createdUser:string;
    isActive: boolean;

    constructor(downtimeReasonId:number,downtimeReason:string,sla:number,downtimestatus:string,createdUser:string,isActive:boolean){
        this.downtimereasonId = downtimeReasonId;
        this.downtimeReason = downtimeReason;
        this.downtimestatus=downtimestatus;
        this.createdUser =createdUser;
        this.isActive = isActive;
        this.sla=sla;
    }
}
export const DowntimeReasonDefault : DownTimeReasonDto = {
    downtimereasonId: 0,
    downtimeReason: "",
    downtimestatus:"",
    createdUser:"",
    sla:120,
    isActive:true
    
};

