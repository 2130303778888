import { InsuranceType, JobCompletedEnum } from "@gtpl/shared-models/common-models";

export class ShipmentDetailsDto {
    containerId: number;
    containerNo: string;
    dateOfTranshipment: string;
    destinationDate: string;
    dischargeDate: string;
    importingVesselName: string;
    portOfDischarge: string;
    portOfTranshipment: string;
    shippedOnboardDate: string;
    trackingNo: string;
    vesselName: string;
    hoCertificateNo:string;
    hoIssuedDate:string;
    vcNo:string;
    documentDispatchStatus:JobCompletedEnum;
    couriourNo:string;
    documentDispatchDate:string;
    insuranceType:InsuranceType;
    shippingBillNo:string;
    insuranceId:number;
    insuranceDate:string;
    referenceNo:string;
    insuranceAmount:number;
    shippingBillDate?:string;
    bookingNo?:string;
    scac?:string;
    constructor (
        containerId: number,
        containerNo: string,
        dateOfTranshipment: string,
        destinationDate: string,
        dischargeDate: string,
        importingVesselName: string,
        portOfDischarge: string,
        portOfTranshipment: string,
        shippedOnboardDate: string,
        trackingNo: string,
        vesselName: string,hoCertificateNo:string,
        hoIssuedDate:string,
        vcNo:string,
        documentDispatchStatus:JobCompletedEnum,
        couriourNo:string,
        documentDispatchDate:string,
        insuranceType:InsuranceType,
        shippingBillNo:string,insuranceId:number,
        insuranceDate:string,
        referenceNo:string,
        insuranceAmount:number,
        shippingBillDate?:string,
        bookingNo?:string,
        scac?:string

        ) {
            this.containerId= containerId;
            this.containerNo= containerNo;
            this.dateOfTranshipment= dateOfTranshipment;
            this.destinationDate= destinationDate;
            this.dischargeDate= dischargeDate;
            this.importingVesselName= importingVesselName;
            this.portOfDischarge= portOfDischarge;
            this.portOfTranshipment= portOfTranshipment;
            this.shippedOnboardDate= shippedOnboardDate;
            this.trackingNo= trackingNo;
            this.vesselName= vesselName;
            this.hoCertificateNo= hoCertificateNo;
            this.hoIssuedDate= hoIssuedDate;
            this.vcNo= vcNo;
            this.documentDispatchStatus= documentDispatchStatus;
            this.couriourNo= couriourNo;
            this.documentDispatchDate= documentDispatchDate;
            this.insuranceType= insuranceType;
            this.shippingBillNo= shippingBillNo;    
            this.insuranceId= insuranceId; 
            this.insuranceDate=insuranceDate,
            this.referenceNo=referenceNo,
            this.insuranceAmount=insuranceAmount
            this.shippingBillDate=shippingBillDate
            this.bookingNo = bookingNo
            this.scac = scac
        }
}