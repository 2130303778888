import { Button, Card, Col, Row, Tooltip, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons'
import './down-time-dashboard.css';
import DownTimeDetailsTable from './down-time-details-table';
import DownTimePieChart from './down-time-piechart';
import DowntimeSummaryDetailsTable from './down-time-summary-table';
import form from 'antd/lib/form';
import { DownTimeDashboardDateRequest, DownTimePieChartDto, DownTimeTrackingDto } from '@gtpl/shared-models/masters';
import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Content } from 'antd/lib/layout/layout';
import { CardBodyDetails, CardDetail, CardHeader, CardHeaderBody } from '@gtpl/shared-models/common-models';
import DowntimeCardComponet from './down-time-card-component';
import { useHistory } from 'react-router-dom';
const { RangePicker } = DatePicker



/* eslint-disable-next-line */
export interface DownTimeDashboardProps { }

export function DownTimeDashboard(
  props: DownTimeDashboardProps) {


  const downtimetrackingService = new DownTimeTrackingService();
  const [downtimeTypeData, setDownTimeTypeData] = useState<any[]>([]);
  const [skippedUnderSLA, setskippedUnderSLA] = useState<any[]>([]);
  let history = useHistory()
  // const timeoutRef = setTimeout(() => {
  //   history.push('/wh-dashboard-components')
  // }, 120000);


  // down time type wise
  const cardBodyForDownTimeType: CardBodyDetails[] = [];
  let downtimeTypePlanedCount = 0;
  let downtimeTypeunPlanedCount = 0;

  for (const productInfo of downtimeTypeData) {

    cardBodyForDownTimeType.push(new CardBodyDetails(productInfo.Status, productInfo.plannedCount, productInfo.unplannedCount, productInfo.statuscount));
    downtimeTypePlanedCount += Number(productInfo.plannedCount);
    downtimeTypeunPlanedCount += Number(productInfo.unplannedCount);

  }

  const downtimeTypeBodyPlanedCount = new CardHeaderBody('Planned cnt.', downtimeTypePlanedCount.toString());
  const downtimetypeBodyUnPlanedCount = new CardHeaderBody('UnPlanned cnt.', downtimeTypeunPlanedCount.toString());
  const downTimeBodyCardHeader = new CardHeader('Planned vs UnPlanned', [downtimeTypeBodyPlanedCount, downtimetypeBodyUnPlanedCount]);

  const cardForDownTimeType = new CardDetail(downTimeBodyCardHeader, cardBodyForDownTimeType, ['Status', 'Planned', 'Unplanned', 'StatusCount']);
  // Downtime SLA
  const cardBodyForSkippedAndUnderSLA: CardBodyDetails[] = [];
  let skippedSlacount = 0;
  let underSlacount = 0;
  for (const productInfo of skippedUnderSLA) {

    cardBodyForSkippedAndUnderSLA.push(new CardBodyDetails(productInfo.status, productInfo.skippedsla, Number(productInfo.undersla).toString()));
    skippedSlacount += Number(productInfo.skippedsla);
    underSlacount += Number(productInfo.undersla);
  }

  const downTimeSkippedSlaCount = new CardHeaderBody('Skipped cnt.', skippedSlacount.toString());
  const downtimeUnderSlaCount = new CardHeaderBody('UnderSLA cnt.', underSlacount.toString());
  const BodyCardHeader = new CardHeader('SLA  Based Report', [downTimeSkippedSlaCount, downtimeUnderSlaCount]);

  const cardForSkippedAndUndelSla = new CardDetail(BodyCardHeader, cardBodyForSkippedAndUnderSLA, ['Status', 'SkippedSLA', 'UnderSLA']);




  useEffect(() => {
    getTotalDownTimeType();
    getTotalSkippedAndUnderSla();
  }, []);

  const getTotalDownTimeType = () => {

    downtimetrackingService.getTotalDownTimeType().then(res => {
      if (res.status) {
        setDownTimeTypeData(res.data);
      } else {
        if (res.intlCode) {
          setDownTimeTypeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setDownTimeTypeData([]);
    })
  }

  const getTotalSkippedAndUnderSla = () => {

    downtimetrackingService.getTotalSkippedSlaAndUndeSla().then(res => {
      if (res.status) {
        setskippedUnderSLA(res.data);
      } else {
        if (res.intlCode) {
          setskippedUnderSLA([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setskippedUnderSLA([]);
    })
  }




  return (

    <div className='dashboard' >
      <Row gutter={24} style={{ height: '33%' }}>


      </Row>
      <Row gutter={24} justify='space-around'>

        <Col className="cardComp" xs={24} sm={24} md={12} xl={7}>
          <DowntimeCardComponet cardData={cardForDownTimeType} />
        </Col>
        <Col className="cardComp" xs={24} sm={24} md={12} xl={6}>
          <DowntimeCardComponet cardData={cardForSkippedAndUndelSla} />
        </Col>
        <Col xs={24} sm={24} md={24} xl={11} style={{ marginTop: '20px' }} >

          <Card title={<span style={{ color: 'black' }} >Downtime Summary Details</span>}
            style={{ textAlign: 'center' }}  >


            <DowntimeSummaryDetailsTable />

          </Card>
        </Col>
      </Row>
      <Row></Row>
      <Row gutter={24}>

        <Col xs={24} sm={24} md={24} xl={12} className='downtime-details-table'>
          <Card title={<span style={{ color: 'black' }}>Details of Downtime</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#white', border: 0 }}  >
            <DownTimeDetailsTable />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} xl={12}  >
          <DownTimePieChart />
        </Col>
      </Row>
    </div >

  );
}

export default DownTimeDashboard;
