import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Tooltip, Form, Col, Row, Select, Modal, Empty, Popconfirm, message, Tabs, DatePicker, Typography, Pagination, Descriptions, Statistic, Spin, Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, FileExcelOutlined, FilePdfOutlined, WhatsAppOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import './stock-grid.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MessageRequest, ShiftsDropDown, StockInTransactionsRequest, StockTypeDropDown, StockTypesEnum, StockTypeStatus, UnitRequest } from '@gtpl/shared-models/common-models';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { MasterBrandService, SkuService } from '@gtpl/shared-services/masters';
import { MasterBrandDto, MasterBrandsDropDownDto } from '@gtpl/shared-models/masters';
// import {MasterBrandsService} from '@gtpl/shared-srvices/MasterBrandsService'
import { UnitcodeService } from '@gtpl/shared-services/masters';
import {WhatsAppNotificationService} from '@gtpl/shared-services/notification-management'


const { Option } = Select;
/* eslint-disable-next-line */
export interface StockInTransctionsReportProps {

}


export function StockInTransactionsReport(props: StockInTransctionsReportProps) {
    const { Text } = Typography;
    const [form] = Form.useForm();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const stockService = new FGStockService();
    const [page, setPage] = React.useState(1);
    const { RangePicker } = DatePicker;
    const [stockinTransactionsData, setStockinTransactionsData] = useState<any>([])
    const [unitData, setunitData] = useState<any[]>([]);
    const unitsService = new UnitcodeService();
    const [loading, setLoading] = useState<boolean>(false)
    const [empty, setEmpty] = useState<boolean>(false)
    let totalReportedCases = 0;
    const unitId = Number(localStorage.getItem('unit_id'));
    const [product, setProduct] = useState<any>()
    const productService = new SkuService()
    const [pageSize, setPageSize] = useState<number>(null);
    const [disable, setDisable] = useState<boolean>(false)
    const [brandfiltersData, setFiltersData] = useState<any[]>([]);
    // const [selectedunit, setUnit] = useState<number>(0)
    const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
    const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
    const [productfiltersData, setproductFiltersData] = useState<any[]>([]);
    const [brandsDropDown, setBrandsDropDown] = useState<MasterBrandsDropDownDto[]>([]);
    const [visible, setVisible] = useState<boolean>(false)
    const [unit, setUnit] = useState<any[]>([])
    const fgservice = new FGStockService();
    const brandsService = new MasterBrandService();
    const [modalForm] = Form.useForm();
    const whatsAppService = new WhatsAppNotificationService
    const [productData, setProductData] = useState<any[]>([]);
    const stockInService = new StockInService();




    useEffect(() => {
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
        getAllPlants()
        form.setFieldsValue({
            createdAt: [(moment(moment().format("YYYY-MM-DD")).subtract(1, 'days')), moment(moment().format("YYYY-MM-DD"))]
        })
        setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1, 'days')))
        setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
        getUnitDropdownForStockInReport()
        getStockInTransactions()
        setEmpty(true)
        getProductDropForStockReport();
    }, [])



    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                )
            ) : null,
    });
    const getUnitDropdownForStockInReport = () => {
        fgservice.getUnitDropdownForStockInReport().then((res) => {
            if (res.status) {
                setUnit(res.data);
            } else {
                setUnit(res.data);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const getProductDropForStockReport = () => {
        stockInService.getProductDropForStockReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
          if (res.status) {
            // console.log(res.data);
            setProductData(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setProductData([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setProductData([]);
    
        });
      }
    const getAllPlants = () => {
        unitsService.getAllMainPlants().then((res) => {
            if (res.status) {
                if (unitId == 5) {
                    setunitData(res.data)
                } else {
                    setunitData(res.data.filter(i => i.plantId == unitId))
                }
            } else {
                setunitData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setunitData([]);
        })
    }

    function unique(data, key) {
        return [
            ...new Map(
                data.map(x => [key(x), x])
            ).values()
        ]
    }

    const handleUnit = (value) => {
        // setUnit(value)
    }


    const getStockInTransactions = () => {
        if (form.getFieldValue('reportingDate') || form.getFieldValue('unitId') || form.getFieldValue('createdAt') || form.getFieldValue('product')) {
            setLoading(true); setEmpty(false); setDisable(true)
            const req = new StockInTransactionsRequest()
            const unitId = Number(localStorage.getItem('unit_id'))
            const dropdownUnitId = form.getFieldValue('unitId')
            if (unitId == 5) {
                if (unitId) {
                    console.log(dropdownUnitId, 'dropdownUnitId')
                    req.unitId = dropdownUnitId
                }
            } else {
                req.unitId = unitId
            }
            if(form.getFieldValue('product')){
                req.product = form.getFieldValue('product')
              }
            if (form.getFieldValue('reportingDate') != undefined) {
                const selectedFromDate = moment(form.getFieldValue('reportingDate')[0]).format('YYYY-MM-DD');
                const selectedToDate = moment(form.getFieldValue('reportingDate')[1]).format('YYYY-MM-DD');
                req.reportedFromData = (selectedFromDate)
                req.reportedToData = (selectedToDate)

            } 
            if (form.getFieldValue('createdAt') != undefined) {
                const selectedFromDate = moment(form.getFieldValue('createdAt')[0]).format('YYYY-MM-DD');
                const selectedToDate = moment(form.getFieldValue('createdAt')[1]).format('YYYY-MM-DD');
                req.transactionFromDate = (selectedFromDate)
                req.transactionToDate = (selectedToDate)
            }
            stockService.getStockInTransactions(req).then((res) => {
                setDisable(false)
                if (res.status) {
                    setStockinTransactionsData(res.data)
                    setLoading(false)
                    // for (const StockReport of res.data) {
                    //     const packingStyle =  Math.round(StockReport.packStyle).toFixed(1)
                    //     StockReport.packStyle = packingStyle
                    //   }
                    // data for brands filter
                    let filtersArray: { text: string, value: string }[] = []
                    let uniq = res.data.filter((thing, index, self) =>
                        index === self.findIndex((t) => (
                            t.brandName === thing.brandName
                        ))
                    );
                    uniq.map(item => {
                        if (!(filtersArray.includes({ text: item.brandName, value: item.brandName })))
                            filtersArray.push({ text: item.brandName, value: item.brandName })
                    })
                    // data for products filter
                    let productFiltersArray: { text: string, value: string }[] = []
                    let produtsUniq = res.data.filter((thing, index, self) =>
                        index === self.findIndex((t) => (
                            t.variantCode === thing.variantCode
                        ))
                    );
                    produtsUniq.map(item => {
                        if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
                            productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
                    })
                    setproductFiltersData( productFiltersArray.sort((a, b) => {
                        let splittedKey1 = a.text.split('/');
                        let splittedKey2 = b.text.split('/');
                        let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                        let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                        let key1 = splittedKey1[1] + `${numberKey1}`;
                        let key2 = splittedKey2[1] + `${numberKey2}`;
                        return (key1 > key2) ? 1 : -1
                      })
                      .sort((a, b) => {
                        let splittedKey1 = a.text.split('/');
                        let splittedKey2 = b.text.split('/');
                        let numberKey1 = Number(splittedKey1[0].split('-')[0]);
                        let numberKey2 = Number(splittedKey2[0].split('-')[0]);
                        return (numberKey1 - numberKey2) ? 1 : -1
                      })
                      )
                    setFiltersData(filtersArray)
                    // AlertMessages.getSuccessMessage("Data retrived sucessfully")

                } else {
                    setStockinTransactionsData([])
                    setLoading(false)
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch((error) => {
                setDisable(false)
                setLoading(false)
                AlertMessages.getErrorMessage('Some thing went wrong')
            })
        } else {

            AlertMessages.getInfoMessage('   Please select any input ')
        }
    }

    const shareData = () => {
        setVisible(true)
    }
    const onCancel = () => {
        setVisible(false)
    }

    const sendMessage = () => {
        const phNo = modalForm.getFieldValue('number')
        const req = new MessageRequest('91' + phNo,'hello','hi','dileep')
        whatsAppService.sendPersonalMessage(req).then(res => {
            if(res.status){
                AlertMessages.getSuccessMessage('message sent')
                setVisible(false)
            }
        })
    }


    const EstimatedETDDate = (value) => {
        console.log(value, 'value')
        if (value) {
            const fromDate = new Date(value[0].format('YYYY-MM-DD'));
            const toDate = new Date(value[1].format('YYYY-MM-DD'));
            setSelectedEstimatedFromDate(fromDate)
            setSelectedEstimatedToDate(toDate)
        }
    }


    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');

    }


    /**
    * 
    * @param pagination 
    * @param filters 
    * @param sorter 
    * @param extra 
    */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const onReset = () => {
        form.resetFields();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
        }
        // setStockinTransactionsData([])
        setEmpty(true)
    };

    const columnsSkelton: ColumnProps<any>[] = [

        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            render: (text, object, index) => (page - 1) * pageSize + (index + 1)
        },
        {
            title: 'Unit',
            dataIndex: 'unitName',
            width: 80,
        },
        {
            title: 'Stock Type',
            dataIndex: 'stockType',
            width: 120,
            sorter: (a, b) => a.stockType.length - b.stockType.length || a.stockType.localeCompare(b.stockType),
            sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('stockType'),
            responsive: ["lg"],
            filters: [
                { text: 'Anticipated', value: 'anticipated' },
                { text: 'Dummy', value: 'dummy' },
                { text: 'SaleOrder', value: 'SaleOrder' },
                { text: 'SuperDummy', value: 'superDummy' },
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.stockType == value;
            },
        },


        {
            title: 'Rack Position',
            dataIndex: 'rackCode',
            width: 100,
            sorter: (a, b) => a.rackCode.toString().length - b.rackCode.toString().length || a.rackCode.toString().localeCompare(b.rackCode.toString()),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('rackCode'),
        },

        {
            title: 'Customer PO',
            dataIndex: 'poNumber',
            width: 120,
            // sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
            sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('poNumber'),
        },
        {
            title: 'Brand',
            dataIndex: "brandName",
            width: 140,
            // sorter: (a, b) => a.brandName.localeCompare(b.brandName),
            sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('brandName')
            filters: brandfiltersData,

            filterMultiple: true,
            onFilter: (value, record) => {
                return record.brandName == value;
            },


        },
        {
            title: 'Product SKU',
            dataIndex: 'variantCode',
            width: 200,
            // sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
            //   sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('variantCode'),
            // filters: productfiltersData,

            // filterMultiple: true,
            // onFilter: (value, record) => {
            //     return record.variantCode == value;
            // },
            // render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
            title: 'Product Category',
            dataIndex: 'productCategory',
            width: 120,
            // sorter: (a, b) =>  a.productCategory?.localeCompare(b.productCategory),
            // sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('productCategory'),
            responsive: ["lg"],
            filters: [
                { text: 'Over Count', value: 'overcount' },
                { text: 'Under Count', value: 'undercount' },
                { text: 'G1', value: 'g1' },
                { text: 'G2', value: 'g2' },
                { text: 'Cut', value: 'cut' },
                { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.productCategory == value;
            },
            render: (value) => value ? value : '-'
        },
        {
            title: 'Pack Style',
            dataIndex: 'packStyle',
            width: 120,
            ...getColumnSearchProps('packStyle'),
        },
        {
            title: 'Reporting Cases',
            dataIndex: "reportingCases",
            width: 120,
            sorter: (a, b) => a.reportingCases - b.reportingCases,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('reportingCases'),
            render: (value) => <span style={{ float: 'right' }}>{value}</span>
        },
        {
            title: 'Pouches Shortage',
            dataIndex: 'pouchShortage',
            width: 100,
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.pouchShortage === value;
            },

        },
        {
            title: 'Cartons Shortage',
            dataIndex: 'cartonShortage',
            width: 100,
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.cartonShortage === value;
            },

        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            width: 100,
            filters: [
                { text: 'Shift A', value: 'shiftA' },
                { text: 'Shift B', value: 'shiftB' },
                { text: 'Shift C', value: 'shiftC' },
                { text: 'General', value: 'general' },
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.shift === value;

            },
            sorter: (a, b) => a.shift - b.shift,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('shift'),
        },

        {
            title: 'Warehouse Incharge',
            dataIndex: 'whIncharge',
            width: 150,
            ...getColumnSearchProps('whIncharge'),
            render: (value) => value ? value : '-'
        },


        {
            title: 'Reported Date',
            dataIndex: 'reportingDate',
            width: 100,
            key: 'reportingDate',
            render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY")}</span>),
            sorter: (a, b) => a.reportingDate.localeCompare(b.reportingDate),
            sortDirections: ['descend', 'ascend'],

        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            width: 100,
            key: 'createdAt',
            render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY")}</span>),
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ['descend', 'ascend'],
        },
        // {
        //   title: 'Cold Storage',
        //   dataIndex: 'coldStorage',
        //   sorter: (a, b) => a.coldStorage.length - b.coldStorage.length || a.coldStorage.localeCompare(b.coldStorage),
        //   sortDirections: ['descend', 'ascend'],
        //   ...getColumnSearchProps('coldStorage'),
        // },
        {
            title: "Transaction Type",
            dataIndex: "transactionType",
            width: 100,
            render: (value) => (<span style={{ float: 'right' }}>{value === 'rm'?'Fresh Production':value}</span>),
            filters: [
                { text: 'palleting', value: 'palleting' },
                { text: 'repalleting', value: 'repalleting' },
                { text: 'repacking', value: 'repacking' },
                { text: 'reprocessing', value: 'reprocessing' },
                { text: 'dispatch', value: 'dispatch' },
                { text: 'unregistered', value: 'unregistered' },
                { text: 'stockTransfer', value: 'stockTransfer' },
                { text: 'Fresh Production', value: 'rm' }
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                console.log(value); 
                return record.transactionType == value;
            },
        },
        {
            title:"Remarks",
            dataIndex:"remarks",
            width: 100,
            render: (status, rowData) => (
                <>
                  {rowData.remarks? rowData.remarks : '-'}
    
                </>
              ),
        }
    ];


    const data = [
        { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
        { title: 'Unit', dataIndex: 'unitName' },
        { title: 'Stock Type', dataIndex: 'stockType' },
        { title: "Rack Position", dataIndex: "rackCode" },
        { title: "Customer PO", dataIndex: "poNumber" },
        { title: 'Brand', dataIndex: 'brandName' },
        { title: "Product SKU", dataIndex: "variantCode" },
        { title: 'Product Category', dataIndex: 'productCategory' },
        { title: "Reporting Cases", dataIndex: "reportingCases", string2Num: true },
        { title: "Pack Style", dataIndex: "packStyle" },
        { title: "Pouch Shortage", dataIndex: "pouchShortage" },
        { title: "Carton Shortage", dataIndex: "cartonShortage" },
        { title: "Shift", dataIndex: "shift" },
        { title: "Loose Pouches", dataIndex: "loosePouches" },
        { title: 'WH Incharge', dataIndex: 'whIncharge' },
        { title: "Reported Date", dataIndex: "reportingDate", render: (value) => moment(value).format("DD-MM-YYYY") },
        { title: "Created Date", dataIndex: "createdAt", render: (value) => moment(value).format("DD-MM-YYYY") },
        { title: "Transaction Type", dataIndex: "transactionType", render: (value) => value === 'rm'?'Fresh Production':value},
    ];

    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet('Stock-In Transactions')
            .addColumns(data)
            .addDataSource(stockinTransactionsData, { str2num: true })
            .saveAs('stock-in-transactions.xlsx');
    }
    const exportToPdf = () => {

        var columns = [
            { title: "Sno", dataKey: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
            { title: 'Stock Type', dataKey: 'stockType' },
            { title: "Rack Position", dataKey: "rackCode" },
            { title: "Customer PO", dataKey: "poNumber" },
            { title: 'Brand', dataKey: 'brandName' },
            { title: "Product SKU", dataKey: "variantCode" },
            { title: 'Product Category', dataKey: 'productCategory' },
            { title: "Reporting Cases", dataKey: "reportingCases" },
            { title: "Pack Style", dataKey: "packStyle" },
            { title: "Pouch Shorthage", dataKey: "pouchShortage" },
            { title: "Carton Shortage", dataKey: "cartonShortage" },
            { title: "Shift", dataKey: "shift" },
            { title: "Loose Pouches", dataKey: "loosePouches" },
            { title: 'WH Incharge', dataKey: 'whIncharge' },
            { title: "Reported Date", dataKey: "reportingDate", render: (value) => moment(value).format("DD-MM-YYYY") },
            { title: "Created", dataKey: "createdAt", render: (value) => moment(value).format("DD-MM-YYYY") },
            { title: "Transaction Type", dataKey: "transactionType" },

        ];
        const doc = new jsPDF()
        // @ts-ignore

        doc.autoTable(columns, stockinTransactionsData, {
            columnStyles: {
                id: { fillColor: 255 }
            },

            margin: { top: 20 },
            addPageContent: function (data) {
                doc.text("STOCK IN TRANSACTIONS", 50, 15);
            }
        });
        doc.save('stock-in-transactions.pdf')
    }

    return (
        <Card
            title={<span style={{ color: 'white' }}>Stock In Transactions</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >

            <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                <Form.Item
                    style={{ display: "none" }}
                    name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                </Form.Item>
                <Row gutter={[24, 24]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="reportingDate" label="Reported Date" rules={[{
                            required: false,
                            message: "Reported Date",

                        },
                        ]}>
                            <RangePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="createdAt"
                            label="Created Date"
                            initialValue={undefined}
                            rules={[{
                                required: false,
                                message: "Transaction Date"
                            },
                            ]}>
                            <RangePicker onChange={EstimatedETDDate} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="unitId"
                            label=" Unit"
                            rules={[
                                {
                                    required: false, message: 'Select Unit',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Unit"
                                allowClear
                                style={{ width: '100%' }}
                                onChange={handleUnit}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

                            >
                                {unit.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
            <Form.Item
              name="product"
              label="Product SKU"
              rules={[
                {
                  required: false,
                  message: 'product is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {productData?.map(productsku => {
                  return <Option key={productsku.varientId} value={productsku.varientId}>{productsku.variantCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
                    <Col style={{ paddingTop: '30px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Button
                            type="primary"
                            onClick={() => getStockInTransactions()}
                            style={{ width: 100 }}
                            disabled={disable}
                        >
                            Get Report
                        </Button>
                    </Col>
                    <Col style={{ paddingTop: '30px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Button type="primary" htmlType="submit" onClick={onReset} style={{ marginLeft: 2 }} >
                            Reset
                        </Button>
                    </Col>


                </Row>
            </Form>
            <br /><br />

            {   

                stockinTransactionsData.length > 0 ?
                    <>
                        {

                            stockinTransactionsData.forEach(item => {
                                totalReportedCases += Number(item.reportingCases);
                            })
                        }

                        <Card title='Report Summary' >
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Statistic title="Total Transactions" value={stockinTransactionsData.length} />
                                </Col>
                                <Col span={4}>
                                    <Statistic title="Total Reported Cases" value={totalReportedCases} />
                                </Col>
                                <Col span={4}>
                                    <Button icon={<FileExcelOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }}>
                                        Get Excel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
                                        Get PDF
                                    </Button>
                                </Col>
                                {/* <Col>
                                    <Button icon={<WhatsAppOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { shareData() }}>
                                        Share
                                    </Button>
                                </Col> */}

                            </Row>
                        </Card>
                        <Table rowKey={record => record.stockId}
                            columns={columnsSkelton} dataSource={stockinTransactionsData}
                            pagination={{

                                onChange(current, pageSize) {
                                    setPage(current);
                                    setPageSize(pageSize)
                                }
                            }}
                            onChange={onChange}
                            scroll={{ x: 1800, y: 500 }}
                            size="small"
                            bordered
                            summary={(pageData) => {
                                let totalreportingCases = 0
                                pageData.forEach(({ reportingCases }) => {
                                    totalreportingCases += Number(reportingCases);


                                });

                                return (
                                    <>
                                        <Table.Summary.Row className='tableFooter'>
                                            <Table.Summary.Cell index={3} colSpan={3}><Text style={{ textAlign: 'end' }}></Text></Table.Summary.Cell>
                                            <Table.Summary.Cell index={10} colSpan={6} ><Text >Total</Text></Table.Summary.Cell>
                                            <Table.Summary.Cell index={11}><Text style={{ textAlign: 'end' }}>{totalreportingCases}</Text></Table.Summary.Cell>
                                            {/* <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell> */}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }
                            }
                        />
                    </> : <Empty description={empty ? ' Select inputs and click Get Report to retreive data' : 'No data found'}></Empty>

            }

            <Modal title='Share on WhatsApp' visible={visible} onCancel={onCancel}>
                <Form form={modalForm} layout={'vertical'} >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name='number'>
                                <Input placeholder='Phone Number' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Button type='primary' onClick={sendMessage} > Send</Button>
                        </Col>
                    </Row>
                </Form>

            </Modal>




        </Card>

    );
}

export default StockInTransactionsReport;
