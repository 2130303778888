export class CountRequest {
    count: number;
    plantId: number;
    productId?: number;
    /**
     * @param count
     * @param plantId
    */
    constructor(count: number,plantId: number,productId?:number){
       this.count=count;
       this.plantId = plantId;
       this.productId = productId;
    }
}