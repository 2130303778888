import axios from "axios";
import {ValAddSoakingDto, ValAdditionSoakingResponseModel, OperationRequest, InProgressBeheadingResponse, BeheadingInventoryResponse, BeheadingDashboardResponse, ProductionLogIdRequest, InventoryBeHeadingDashboardDto, InventoryBeheadingDashboardResponse, ProductionInventoryResponse, LotInformationRequest, LotNumberInfoResponse, MainLotClubDto} from '@gtpl/shared-models/production-management';
import connection from './connection';
import { JobCompletedEnum, UnitRequest } from "@gtpl/shared-models/common-models";
import { CommonResponse, SaleOrderDropDownResponseModel } from '@gtpl/shared-models/sale-management';
import { ItemsDropDownResponseModel } from "@gtpl/shared-models/masters";
export class BeheadingService{
    URL = connection.DEPLOY_URL + '/be-heading';
    URLTWO = connection.DEPLOY_URL+'/lot-clubbing';
    async assignBeHeadingOperation(formInfo){
        return await axios.post(this.URL + '/assignBeHeadingOperation', formInfo)
            .then(res => {
                return res.data
            })   
    }

    async getWorkstationWiseInprogressData(req:OperationRequest): Promise<InProgressBeheadingResponse> {
        return await axios.post(this.URL + '/getWorkstationWiseInprogressData', req)
            .then(res => {
                return res.data
            })
    }
    async getAllBeheadingPlantJobs(req:UnitRequest): Promise<InventoryBeheadingDashboardResponse> {
        return await axios.post(this.URL + '/getAllBeheadingPlantJobs', req)
            .then(res => {
                return res.data
            })
    }
    async getBeheadingInventoryData(req:OperationRequest): Promise<BeheadingInventoryResponse> {
        return await axios.post(this.URL + '/getBeheadingInventoryData', req)
            .then(res => {
                return res.data
            })
    }

    async getBeheadingInventoryInfo(req:UnitRequest): Promise<ProductionInventoryResponse> {
        return await axios.post(this.URL + '/getBeheadingInventoryInfo', req)
            .then(res => {
                return res.data
            })
    }

    async getAllBeheadingInfo(req:UnitRequest): Promise<BeheadingDashboardResponse> {
        return await axios.post(this.URL + '/getAllBeheadingInfo', req)
            .then(res => {
                return res.data
            })
    }

    async getAllBeheadingExternalPlantJobs(req:UnitRequest): Promise<InProgressBeheadingResponse> {
        return await axios.post(this.URL + '/getAllBeheadingExternalPlantJobs', req)
            .then(res => {
                return res.data
            })
    }
    
    async getAllBeheadingInProgressJobs(req:UnitRequest): Promise<InProgressBeheadingResponse> {
        return await axios.post(this.URL + '/getAllBeheadingInProgressJobs', req)
            .then(res => {
                return res.data
            })
    }

    async updateBeheadingJobCompleted(req:ProductionLogIdRequest): Promise<InProgressBeheadingResponse> {
        return await axios.post(this.URL + '/updateBeheadingJobCompleted', req)
            .then(res => {
                return res.data
            })
    }

    async getSaleOrdersForLot(lotNumberReq:LotInformationRequest): Promise<SaleOrderDropDownResponseModel> {
        return await axios.post(this.URL + '/getSaleOrdersForLot', lotNumberReq)
            .then(res => {
                return res.data
            })
    }

    async getSaleOrderItemsForLotAndSo(lotNumberReq:LotInformationRequest): Promise<ItemsDropDownResponseModel> {
        return await axios.post(this.URL + '/getSaleOrderItemsForLotAndSo', lotNumberReq)
            .then(res => {
                return res.data
            })
    }

    async getLotNumberInfo(lotNumberreq:LotInformationRequest): Promise<LotNumberInfoResponse> {
        return await axios.post(this.URL + '/getLotNumberInfo', lotNumberreq)
            .then(res => {
                return res.data
            })
    }
    async createLotClubbing(lotDto:MainLotClubDto):Promise<CommonResponse>{
        console.log('hiiiiiiiiiiiiii')
        return await axios.post(this.URLTWO+'/createLotClubbing',lotDto)
        .then(res =>{
            return res.data
        })
    }
}