import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { WarehouseResponseData } from './warehouse-response.data';

export class WarehouseResponseModel extends GlobalResponseObject {
    data: any[][];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data: any[][]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}