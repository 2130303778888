import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import { AllProdInvResponseModel, InventoryWipResponse, LotAndOperationReq, LotCodeDropDownResponse, LotInformationRequest, LotNoReq, LotNumberInfoResponse, LotNumberReportResponseModel, LotNumberRequest, LotTrackingResponse, OperationInventoryRequest, ProdInvResponseModel, ProductionInventoryResponse, SoandSoitemRequest, WipQuantityResponseModel } from '@gtpl/shared-models/production-management';
import { CommonResponse, PlantIdRequest } from '@gtpl/shared-models/masters';
import { ProductionLogRequest } from 'libs/shared-models/production-management/src/lib/production-inv/production-log.req';
import { unitRequiest } from '@gtpl/shared-models/warehouse-management';

export class ProductionInventoryService {
    URL = connection.DEPLOY_URL + '/production-inventory';

    async getLotNumbers(lotNumberreq:LotNumberRequest): Promise<LotCodeDropDownResponse> {
        return await axios.post(this.URL + '/getLotNumbers', lotNumberreq)
            .then(res => {
                return res.data
            })
    }

    async getLotNumberInfo(lotNumberreq:LotInformationRequest): Promise<LotNumberInfoResponse> {
        return await axios.post(this.URL + '/getLotNumberInfo', lotNumberreq)
            .then(res => {
                return res.data
            })
    }

    async createProdInv(values): Promise<ProdInvResponseModel> {
        return await axios.post(this.URL + '/createProdInv', values)
            .then(res => {
                return res.data
            })
    }

    async createGrnInventory(values): Promise<ProdInvResponseModel> {
        return await axios.post(this.URL + '/createGrnInventory', values)
            .then(res => {
                return res.data
            })
    }

    async getAllProdInvs(): Promise<AllProdInvResponseModel> {
        return await axios.post(this.URL + '/getAllProdInvs')
            .then(res => {
                return res.data
            })
    }

    async getNoOfAvailableBoxes(lotNumberreq:LotNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getNoOfAvailableBoxes', lotNumberreq)
            .then(res => {
                return res.data
            })
    }

    async getQtyToBeProcessed(lotNumberreq:LotNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getQtyToBeProcessed', lotNumberreq)
            .then(res => {
                return res.data
            })
    }

    async getInventoryQty(lotNumberreq:LotNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getInventoryQty', lotNumberreq)
            .then(res => {
                return res.data
            })
    }

    async getAllSubPlantValAdditions(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllExternalPlantLots', req)
            .then(res => {
                return res.data
            })
    }
    async getAllRejections(req:UnitRequest): Promise<ProductionInventoryResponse> {
        return await axios.post(this.URL + '/getAllRejections',req)
        .then(res => {
            return res.data
        })
    }
    async assignRejectionToSo(req): Promise<ProductionInventoryResponse> {
        return await axios.post(this.URL + '/assignRejectionToSo', req)
        .then(res => {
            return res.data
        })
    }
     
    async getLotTrackingReportDetails(req:LotInformationRequest): Promise<LotTrackingResponse> {
        return await axios.post(this.URL + '/getLotTrackingReportDetails',req)
            .then(res => {
                return res.data
            })
    }
    async getInventoryWipQuantity(): Promise<InventoryWipResponse> {
        return await axios.post(this.URL + '/getInventoryWipQuantity')
            .then(res => {
                return res.data
            })
    }

    async getProductionInventoryInfo(req:OperationInventoryRequest): Promise<ProductionInventoryResponse> {
        return await axios.post(this.URL + '/getProductionInventoryInfo', req)
            .then(res => {
                return res.data
            })
    }
    async getProcessedLotNumber(req: PlantIdRequest): Promise<LotCodeDropDownResponse> {
        return await axios.post(this.URL + '/getProcessedLotNumber', req)
            .then(res => {
                return res.data
            })
    }
    async getOperationWiseLotsInfo(): Promise<LotCodeDropDownResponse> {
        return await axios.post(this.URL + '/getOperationWiseLotsInfo')
            .then(res => {
                return res.data
            })
    }
    async getAllSoakingAssets(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllSoakingAssets')
            .then(res => {
                return res.data
            })
    }
    async getSoakingReport():Promise<CommonResponse>{
        return await axios.post(this.URL + '/getSoakingReport')
        .then(res => {
            return res.data
        })
    }
    async getWipQuantityAgainstsoandsoitem(req:SoandSoitemRequest):Promise<WipQuantityResponseModel>{
        return await axios.post(this.URL + '/getWipQuantityAgainstsoandsoitem',req)
        .then(res => {
            return res.data
        })
    }
    async getphysicalquantityforlotnumber(req:LotAndOperationReq):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getphysicalquantityforlotnumber',req)
        .then(res => {
            return res.data
        })
    }

    async getProductionLogReport(req:ProductionLogRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getProductionLogReport',req)
        .then(res => {
            return res.data
        })
    }
    
    async getProductionInventoryReport(req:ProductionLogRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getProductionInventoryReport',req)
        .then(res => {
            return res.data
        })
    }


    async getLotInfo(req?:ProductionLogRequest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getLotInfo',req)
        .then(res => {
            return res.data
        })
    }
    
    async getReadyForDeheading(req?: unitRequiest):Promise<CommonResponse>{
        return await axios.post(this.URL + '/getReadyForDeheading',req)
        .then(res => {
            return res.data
        })
    }
    async getInventoryLots(req?: LotNumberRequest):Promise<LotCodeDropDownResponse>{
        return await axios.post(this.URL + '/getInventoryLots',req)
        .then(res => {
            return res.data
        })
    }

    async getSaleOrdersByLot(req: LotInformationRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSaleOrdersByLot',req)
        .then(res => {
            return res.data
        })
    }
    async getStockInventory(req: OperationInventoryRequest): Promise<ProductionInventoryResponse> {
        return await axios.post(this.URL + '/getStockInventory',req)
        .then(res => {
            return res.data
        })
    }
}