export class pdQaQCsamplesDto{
    peelingSamplesId:number  
    peelingId:number;  
    sampleNo:number;
    temperature:number;
    count:number;
    typeOfCut:string;
    bigToSmallRatio:number;
    legsVeinsShell:string;
    improperCutting:string;
    blackTailBlackSpot:string;
    brokenUnstable:string;
    deteriorationDecomposition:string;
    otherObjectionableDefects:string;
    checkedBy:string;
    verifiedBy:string;
    remarks:string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
}