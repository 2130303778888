export class IndentQuantityRequest {
    product:number;
    count:number
    fromDate: Date;
    toDate: Date;
    constructor(product:number,count:number,fromDate: Date, toDate: Date) {
        this.product = product;
        this.count = count;
        this.fromDate = fromDate;
        this.toDate = toDate;
    }
}