import { PlantRoleRequest, stockDropdownData, UnitRequest } from '@gtpl/shared-models/common-models';
import { CommonResponse, GRNLocationPropsRequest, ItemCategoriesDropDownResponseModel, ItemRequest, ItemsDropDownResponseModel, ItemSubCategoriesDropDownResponse, SizesDropDownResponseModel, BrandReqForLocation } from '@gtpl/shared-models/masters';
import { AvailableStockQtyResponseModel, StocktemRequest, AllStockResponseModel, StockInputRequest, StockQtyResponseModel, SaleOrderIdRequest, ItemCategoryIdReq, CategorySubCategoryReq, QtyRequest, GetAvailableStockResponse, SizeIdRequest, StockDto, SoPackingStatusDto, ProductConversionModel, PlantItemSubCategoryIdReq, PackingBomRequest, CycleCheckerDto, CycleCheckerDateReq, MaterialPreperationRequest, ItemBarcodeRequest, ItemCodeRequestDto, ItemIdReq, StockIdReq, BarcodeRequest, MaterialPreparationReq, GrnInvoiceNumberRequest, locationIdRequest, LocationUpdateRequest, PoStockReq, ReClassificationReq, ProductConversionReq, InventoryLogsDto } from '@gtpl/shared-models/procurement-management';
import { ItemsDetailsResponseModel, BomItemRemovedQty, SaleOrderDropDownResponseModel, SaleOrderFewDetailsResponseModel, saleOrderItemIdRequest, SoPackingStatusRequest } from '@gtpl/shared-models/sale-management';
import axios from 'axios';
import connection from './connection';
// import { PoDropDownDto, PoDropDownResponseModel } from "@gtpl/shared-models/procurement-management";


export class StockService {
    URL = connection.DEPLOY_URL + '/stock';

    async getAvailableStockQty(stockItemRequest: StocktemRequest): Promise<AvailableStockQtyResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockQty', stockItemRequest)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getInventoryStockQty(stockItemRequest: StocktemRequest): Promise<AvailableStockQtyResponseModel> {
        console.log(stockItemRequest);
        return await axios.post(this.URL + '/getInventoryStockQty', stockItemRequest)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAllStockData(plantroleReq?: PlantRoleRequest): Promise<AllStockResponseModel> {
        return await axios.post(this.URL + '/getAllStockData', plantroleReq)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async AssignInventoryStock(stockReq: StockInputRequest): Promise<boolean> {
        return await axios.post(this.URL + '/AssignInventoryStock', stockReq)
            .then(res => {
                return res
            }).catch(error => {
                return error;
            })
    }

    async AssignDummyPackingInventoryStock(stockReq: StockInputRequest): Promise<boolean> {
        return await axios.post(this.URL + '/AssignDummyPackingInventoryStock', stockReq)
            .then(res => {
                return res
            }).catch(error => {
                return error;
            })
    }



    async updateRemovedAssignedQtyForSo(stockReq: BomItemRemovedQty): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateRemovedAssignedQtyForSo', stockReq)
            .then(res => {
                return res
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockSosForConsumption(req?: UnitRequest): Promise<SaleOrderDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockSosForConsumption', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getAvailableStockItemSos(req?: ItemRequest): Promise<SaleOrderDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockItemSos', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getSOAndSoitemCombData(req?: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSOAndSoitemCombData', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getAvailableStockSosForReturns(): Promise<SaleOrderFewDetailsResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockSosForReturns')
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getAvailableStockForSO(saleOrderReq: SaleOrderIdRequest): Promise<StockQtyResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockForSO', saleOrderReq)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockItemCategories(req: UnitRequest): Promise<ItemCategoriesDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockItemCategories', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockItemSubCategories(req: ItemCategoryIdReq): Promise<ItemSubCategoriesDropDownResponse> {
        return await axios.post(this.URL + '/getAvailableStockItemSubCategories', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockItems(req: CategorySubCategoryReq): Promise<ItemsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockItems', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockTransferItems(req: CategorySubCategoryReq): Promise<ItemsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockTransferItems', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAvailableStockSizes(req: SizeIdRequest): Promise<SizesDropDownResponseModel> {
        return await axios.post(this.URL + '/getAvailableStockSizes', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getAvailableTransferStockQty(req: QtyRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAvailableTransferStockQty', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getAvailableStockSoItems(saleOrderReq: SaleOrderIdRequest): Promise<GetAvailableStockResponse> {
        return await axios.post(this.URL + '/getAvailableStockSoItems', saleOrderReq)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async stockEntry(dto: StockDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/stockEntry', dto)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async stockEdit(dto: StockDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/stockEdit', dto)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async stockConversion(dto: ProductConversionModel): Promise<CommonResponse> {
        return await axios.post(this.URL + '/stockConversion', dto)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getStockDetailsForItemId(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockDetailsForItemId', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getSoPackingStatus(req: SoPackingStatusRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoPackingStatus', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async moveLeftOverQtyIntoBulk(req: SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/moveLeftOverQtyIntoBulk', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getavailbleItemsInStock(req: SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getavailbleItemsInStock', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async cancelledSoStockUpdate(req: SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/cancelledSoStockUpdate', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getPhysicalQuantityForItemId(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPhysicalQuantityForItemId', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getNoLocationPhysicalQuantity(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getNoLocationPhysicalQuantity', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getPreparedMaterialForItem(req: ItemBarcodeRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPreparedMaterialForItem', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }


    async getFromPosForProductConversion(req: ProductConversionReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getFromPosForProductConversion', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getAllStockDataForReport(req: stockDropdownData): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllStockDataForReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getPoNumberForProductConversion(req: PlantItemSubCategoryIdReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPoNumberForProductConversion', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getPackingItemsForSoId(req: SaleOrderIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPackingItemsForSoId', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async getTransferredSosDataForStockGrid(req?: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransferredSosDataForStockGrid', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getUnitDropdownForStockInReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnitDropdownForStockInReport')
            .then(res => {
                return res.data
            })
    }
    async getPackingBomReport(req?: PackingBomRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPackingBomReport', req)
            .then(res => {
                return res.data
            })
    }
    async getProsUintDropForSoReport(req?: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getProsUintDropForSoReport', req)
            .then(res => {
                return res.data
            })
    }
    async getSoDropForBomReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForBomReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getSoDropForStockReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForStockReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getItemSubDropForStockReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemSubDropForStockReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getItemDropForStockReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemDropForStockReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getBrandDropForStockReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getBrandDropForStockReport', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }

    async saveCycleCheckerEntry(req: CycleCheckerDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/saveCycleCheckerEntry', req)
            .then(res => {
                return res.data
            })
    }
    async getCycleCheckerData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getCycleCheckerData')
            .then(res => {
                return res.data
            })
    }
    async getCycleCheckerTableData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getCycleCheckerTableData')
            .then(res => {
                return res.data
            })
    }
    async cycleCheckerApproval(req: CycleCheckerDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/cycleCheckerApproval', req)
            .then(res => {
                return res.data
            })
    }
    async updateStockToBulk(req: SaleOrderIdRequest): Promise<CommonResponse> {
        console.log('--cshared-service')
        return await axios.post(this.URL + '/updateStockToBulk', req)
            .then(res => {
                return res.data
            })
    }
    async getPackingMaterialInfo(req: ItemCodeRequestDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPackingMaterialInfo', req)
            .then(res => {
                return res.data
            })
    }

    async createMaterilaPreperation(req: MaterialPreperationRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createMaterilaPreperation', req)
            .then(res => {
                return res.data
            })
    }
    async getMaterialPreparationData(req: MaterialPreparationReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMaterialPreparationData', req)
            .then(res => {
                return res.data
            })
    }

    async getPreparedAvailableStockQty(req: StockIdReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPreparedAvailableStockQty', req)
            .then(res => {
                return res.data
            })
    }
    async getExcessInventoryReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getExcessInventoryReport',req)
            .then(res => {
                return res.data
            })
    }
    async getPreparedStockByBarcode(req: BarcodeRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPreparedStockByBarcode', req)
            .then(res => {
                return res.data
            })
    }
    async getStockQtyForCycleChecker(req: ItemIdReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockQtyForCycleChecker', req)
            .then(res => {
                return res.data
            })
    }
    async getDummyItems(req: SaleOrderIdRequest): Promise<ItemsDetailsResponseModel> {
        return await axios.post(this.URL + '/getDummyItems', req)
            .then(res => {
                return res.data
            })
    }
    async getReasonsForCycleChecker(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getReasonsForCycleChecker')
            .then(res => {
                return res.data
            })
    }
    async getSoDropDown(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropDown', req)
            .then(res => {
                return res.data
            })
    }
    async getLocationsForCycleChecker(req: BrandReqForLocation): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationsForCycleChecker', req)
            .then(res => {
                return res.data
            })
    }
    async getItemDropDown(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemDropDown', req)
            .then(res => {
                return res.data
            })
    }
    async getStatusDropDown(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStatusDropDown', req)
            .then(res => {
                return res.data
            })
    }
    async getPreparedProductsData(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPreparedProductsData', req)
            .then(res => {
                return res.data
            })
    }
    async getReasonsForProdConversion(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getReasonsForProdConversion')
            .then(res => {
                return res.data
            })
    }
    async createLocationMappedStock(req: GRNLocationPropsRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createLocationMappedStock', req)
            .then(res => {
                return res.data
            })
    }

    async getLocationWiseStockByGrn(req: GrnInvoiceNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationWiseStockByGrn', req)
            .then(res => {
                return res.data
            })
    }

    async getLocationsForStockReport(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationsForStockReport', req)
            .then(res => {
                return res.data
            })
    }
    async getStockDetailsForItem(req: any): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockDetailsForItem', req)
            .then(res => {
                return res.data
            })
    }

    async getAvailableStockLocations(req: QtyRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAvailableStockLocations', req)
            .then(res => {
                return res.data
            })
    }
    async getPreviousTransactionsByGrn(req: locationIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPreviousTransactionsByGrn', req)
            .then(res => {
                return res.data
            })
    }

    async getLocationWiseStockByItem(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationWiseStockByItem', req)
            .then(res => {
                return res.data
            })
    }
    async getLocationWiseStockBySaleOrder(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationWiseStockBySaleOrder', req)
            .then(res => {
                return res.data
            })
    }
    async getLocationsForItem(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationsForItem', req)
            .then(res => {
                return res.data
            })
    }
    async getSaveStockForLocationPending(req: LocationUpdateRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSaveStockForLocationPending', req)
        .then(res => {
            return res.data
        })
    }
    async getClassificationReportData(req:ReClassificationReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getClassificationReportData',req)
        .then(res => {
            return res.data
        })
    }

    async getToItemsForProductConversion(req: ProductConversionReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getToItemsForProductConversion', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async getSOAndSoitemData(req: ItemRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSOAndSoitemData', req)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
    async createinventiryLogs(dto: InventoryLogsDto): Promise<any> {
        return await axios.post(this.URL + '/createinventiryLogs', dto)
            .then(res => {
                return res.data
            }).catch(error => {
                return error;
            })
    }
}

