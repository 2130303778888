import {RolesList} from '@gtpl/shared-models/gtpl';

export class userUpdateRequest {
    Id?: number;
    username:string;
    password:string;
    email:string;
    role:RolesList;
    employeeId:number;
    isActive: boolean;
    createdUser:number;
    updatedUser: number | null;
    versionFlag: number;
  
}
export class IdRequest {
    Id:number;
    updatedUser: number | null;
    versionFlag: number;
  }
  export class userStatusUpdateRequest{
    Id:number;
    isActive:boolean;
  }


