import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ItemsDropDownDto } from './items-drop-down.dto';


export class ItemsDropDownResponseModel extends GlobalResponseObject {
    data?: ItemsDropDownDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ItemsDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

