import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";

export class InvoiceItemsInfo {
  saleOrderItemId: number;
  invoicedQuantity: number;
  revisedUnitPrice: number;
  invoicedCases?: number;
  dispatchedQty?: number;
  taxId?: number;
  saleOrder?: any;
  invoiceAmount?: number;
  grossWeight?:number;


  /**
   * @param saleOrderItemId
   * @param invoicedQuantity
   * @param revisedUnitPrice
   * @param invoiceAmount
   * @param dispatchedQty
   * @param grossWeight
   */


  constructor(saleOrderItemId: number, invoicedQuantity: number, revisedUnitPrice: number, invoiceAmount: number,dispatchedQty?: number, taxId?: number, invoicedCases?: number,grossWeight?:number) {
    this.saleOrderItemId = saleOrderItemId;
    this.invoicedQuantity = invoicedQuantity;
    this.revisedUnitPrice = revisedUnitPrice;
    this.invoiceAmount = invoiceAmount;
    this.invoicedCases = invoicedCases;
    this.dispatchedQty = dispatchedQty;
    this.taxId = taxId;
    this.grossWeight = grossWeight;
  }
}