import React, { useEffect, useState } from 'react';
import { TransferItemsDetails, TransferIdRequest, TransferMaterialInfo } from '@gtpl/shared-models/procurement-management';
import { Button, Card, Form, Row, Col, Select, Table, Space, Input, Layout, Descriptions, Typography, Tag, Modal } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { TransferMaterialService } from '@gtpl/shared-services/procurement';
import { ColumnsType } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import UpdateLocationForReceivedStock from './update-location-for-received-stock';
import './stock-transfer-detail-view.css';
import { GlobalStatus } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface StockTransferDetailViewProps {
  transferitems: TransferMaterialInfo
}

export function StockTransferDetailView(
  props: StockTransferDetailViewProps
) {



  const transferMaterialService = new TransferMaterialService();
  const [transferMaterialData, setTransferMaterialData] = useState<TransferMaterialInfo>();
  const [data, setData] = useState<any>(undefined);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [indexVal, setIndexVal] = useState(0);

  const {id} = useParams<any>()
  console.log(id)
  let location = useLocation();
  const packingStockTransferId: any = location.state;

  useEffect(() => {
    
      TransferMaterialDetailInfo(id);
    
  }, [id]);


  const TransferMaterialDetailInfo = (packingStockTransferId) => {
    const reqObj = new TransferIdRequest();
    reqObj.packingStockTransferId = packingStockTransferId;
    transferMaterialService.TransferMaterialDetailInfo(reqObj).then(res => {
      if (res.status) {
        console.log(res.data);
        setTransferMaterialData(res.data);
        setIndexVal(indexVal + 1);
      }
    })
  }

  const columns = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Item Category',
      dataIndex: 'itemCategory.itemCategoryId',
      key: 'itemCategory.itemCategoryId',
      render: (value, record: TransferItemsDetails, index) => {
        return (record.itemCategory) ? record.itemCategory : '';

      },
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubcategory.itemSubCategoryId',
      key: 'itemSubcategory.itemCategoryId',
      render: (value, record: TransferItemsDetails, index) => {
        return (record.itemSubCategory) ? record.itemSubCategory : '';
      },
    },

    {
      title: 'Item',
      dataIndex: 'item.itemId',
      key: 'item.itemId',
      render: (value, record: TransferItemsDetails, index) => {
        return (record.item) ? record.item : '';
      },
    },

    {
      title: 'Size',
      dataIndex: 'size.sizeId',
      key: 'size.sizeId',
      render: (value, record: TransferItemsDetails, index) => {
        return (record.size) ? record.size : '';
      },
    },

    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (value, record: TransferItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.unitPrice) ? Number(record.unitPrice) : 0}</div>;
      },
    },

    {
      title: 'Issuing Quantity',
      dataIndex: 'transferredQty',
      key: 'transferredQty',
      render: (value, record: TransferItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.transferredQty) ? Number(record.transferredQty) : 0}</div>;
      },
    },
    {
      title: 'Location Updated Quantity',
      dataIndex: 'allocatedQty',
      key: 'allocatedQty',
      render: (value, record: TransferItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.allocatedQty) ? Number(record.allocatedQty) : 0}</div>;
      },
    },
    {
      title:'Allocate',
      render: (rowData) =>(
              (!rowData.logId && transferMaterialData.isPreparedMaterial === "no" && transferMaterialData.transferMethod === "INTERNAL")?
                <span>
              <Button type="primary" shape="round" size="small" 
                 onClick={() => {
                  setVisibleModal(true);
                  setData(rowData)
                }}
                  >
                  Allocate
                </Button>
            </span>:""
      )
    },
  ];

  const handleCancel = () => {
    setVisibleModal(false);
    TransferMaterialDetailInfo(id);
  };

  return (
    <>
      {
        <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
          <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Stock Transfer Detail View</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }} extra={<Button href="/#/stock-transfer-grid" style={{ color: 'black' }}>Stock Transfer </Button>} >
            <br></br>

            {transferMaterialData ?
              <div>
                <Descriptions column={3}>
                  <Descriptions.Item label="Against SO">
                    {transferMaterialData.transferType == GlobalStatus.YES ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>YES</Tag> :
                      // transferMaterialData.transferType == GlobalStatus.NO ?
                      <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>No</Tag>}
                  </Descriptions.Item>
                  <Descriptions.Item label="From Plant" >
                    {transferMaterialData.fromPlantName}
                  </Descriptions.Item>
                  <Descriptions.Item label="To Plant" >
                    {transferMaterialData.toPlantName}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="DC Number">
                    {transferMaterialData.dcNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="MRN Number">
                    {transferMaterialData.mrnNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer PO">
                    {transferMaterialData.poNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Indent">
                    {transferMaterialData.indentCode}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Transferred Date">
                    {moment(transferMaterialData.transferredDate).format('YYYY-MM-DD LTS')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Recived Date">
                    {transferMaterialData.receivedDate ? moment(transferMaterialData.receivedDate).format('YYYY-MM-DD LTS') : "Not Received"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Note">
                    {transferMaterialData.note}
                  </Descriptions.Item>
                </Descriptions>

              </div>
              : <span> </span>}

            <Card>
              <Table
                columns={columns}
                dataSource={(transferMaterialData) ? transferMaterialData.items : []}
                pagination={false}
                scroll={{ y: 500 }}
                size="small"
                bordered
              />
              <Modal
                className='print-docket-modal'
                key={'modal' + Date.now()}
                width={'80%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={visibleModal}
                title={<React.Fragment>
                </React.Fragment>}
                onCancel={handleCancel}
                footer={[

                ]}
              >
                {<UpdateLocationForReceivedStock transferId={data} closeForm={handleCancel} />}
              </Modal>
            </Card>




          </Card>

        </Layout>
      }

    </>
  );
}

export default StockTransferDetailView;
