export enum POLifeCycleStatusEnum { // Used For Bom Items Status and GRN
    OPEN = 'OPEN',
    //*  IN_PROGRESS not in use.
    WORK_IN_PROGRESS1 = 'Work In Progress1',
    WORK_IN_PROGRESS2 = 'Work In Progress2',
    RISK = 'Risk',
    SHIPPED = 'Shipped',
    EARLY_SHIPPED = 'Early Shipped',
    DELAYED = 'Delayed',
    DELAY_SHIPPED = 'Delay Shipped',
}