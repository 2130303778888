import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Modal,Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Layout, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Link} from 'react-router-dom';
import { Route, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeRolesEnum, OperationTypeEnum, Series, UnitRequest } from '@gtpl/shared-models/common-models';
import { SoakingAssignmentService, WorkstationService } from '@gtpl/shared-services/production';

import './soaking-dashboard.css';
import { WorkstationDTO, WorkStationsResponseDto } from '@gtpl/shared-models/production-management';
import moment from 'moment';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 

/* eslint-disable-next-line */
export interface SoakingDashboardProps {}

export function SoakingDashboard(
  props: SoakingDashboardProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [xseries, setXseries] = useState<any[]>([]);
  const [soakingInprogressData, setSoakingInprogressData] = useState<WorkStationsResponseDto[]>([]);
  const [workStationsData, setWorkStationsData] = useState<string[]>([]);
  const [inprogressQty, setInprogressQty] = useState<number[]>([]);
  const [completedQty, setCompletedQty] = useState<number[]>([]);
  const soakingAssignmentService = new SoakingAssignmentService();
  const workstationService = new WorkstationService();
  const [saleId, setSaleId] = useState(0);
  const [defaultTabPane, setDefaultTabPane] = useState<string>("1")
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const {Text} = Typography
  useEffect(() => {
    getAllLotOps();
 }, []);

 const  getAllLotOps= () => {
 
  }

  
   /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
    const { TabPane } = Tabs;

    
     /**
* used for column filter
* @param dataIndex column data index
*/
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  useEffect(() => {
      getInprogressWorkstations(localStorage.getItem('unit_id'));
      getWorkStations(localStorage.getItem('unit_id'));
      getsoakingStatusWiseDetails(localStorage.getItem('unit_id'));
  }, [])

  const getInprogressWorkstations = (unitId) => {
    soakingAssignmentService.getInProgressWorkstations({unitId:unitId}).then(res => {
      if (res.status) {
        console.log(res.data);
        setSoakingInprogressData(res.data)
        // AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setSoakingInprogressData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setSoakingInprogressData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
  const getWorkStations = (unitId) => {
    workstationService.getAllWorkStationsForCategory({workstationCategory:5,unitId:unitId}).then(res => {
      if (res.status) {
        console.log(res.data[0].workStationsData);
        const workSTationsList: string[] = res.data[0].workStationsData;
        console.log(workSTationsList);
        setWorkStationsData(workSTationsList);
        // AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setWorkStationsData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setWorkStationsData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
  const getsoakingStatusWiseDetails = (unitId) => {
    soakingAssignmentService.getsoakingStatusWiseDetails({unitId:unitId}).then(res => {
      if (res.status) {
        console.log(res.data.completedQty);
        console.log(res.data.inprogressQty);
        const inprogressList: number[] = res.data.inprogressQty;
        console.log(inprogressList);
        setInprogressQty(inprogressList);
        const completedList: number[] = res.data.completedQty;
        console.log(completedList);
        setCompletedQty(completedList);
        // AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.intlCode) {
          setWorkStationsData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setWorkStationsData([])

          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };
  const handleCancel = () => {
    getAllLotOps();
    setIsModalVisible(false);
  };
     
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'WorkStation',
      dataIndex: 'workstation',
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNo',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.saleOrderNo?.localeCompare(b.saleOrderNo),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrderNo'),
    //   // render: (value, record) => 
    //   // <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.saleOrderNo}</Text>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
       <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} >{record.poNumber}</Text>
      
      
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Soaking Time',
      dataIndex: 'soakingTime',
      // sorter: (a, b) => a.soakingTime.localeCompare(b.soakingTime),
      // sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('soakingTime')
    },
    {
      title: 'Soaking Style',
      dataIndex: 'soakingStyle',
      sorter: (a, b) => a.soakingStyle - b.soakingStyle,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingStyle')
    },
    {
      title: 'Quantity',
      dataIndex: 'inputQuantity',
      sorter: (a, b) => a.inputQuantity - b.inputQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('inputQuantity')
    },
    {
      title: 'Job Status',
      dataIndex: 'jobStatus',
      sorter: (a, b) => a.jobStatus?.length - b.jobStatus?.length,
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'NO',
          value:'NO',
        },
        {
          text: 'YES',
          value:'YES',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.jobStatus === value;
      },
      // ...getColumnSearchProps('jobStatus'),
      render: (jobStatus, rowData) => (
        <>
          {jobStatus==="YES"?<Tag icon={<CheckCircleOutlined />} color="#87d068">YES</Tag>:jobStatus==="NO"?<Tag icon={<CloseCircleOutlined />} color="#f50">NO</Tag>:""}
          
        </>
      ),
    },
    {
      title: 'Assigned Date',
      dataIndex: 'assignedTime',
      sorter: (a, b) => a.assignedTime.localeCompare(b.assignedTime),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedTime'),
      render : (value) =>{
        return <div style={{textAlign:'right'}}>{moment(value).format("YYYY-MM-DD")}</div>
      }
    },
    {
      title: 'Assigned Time',
      dataIndex: 'assignedTime',
      sorter: (a, b) => a.assignedTime - b.assignedTime,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedTime'),
      render : (value) =>{
        return <div style={{textAlign:'right'}}><Tag style={{textAlign:'right',backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>{moment(value).format('hh:mm A')}</Tag></div>
      }
    //   render: (text,record) => {
    //     return record.assigneTime!=undefined?moment(record.assigneTime).format('YYYY-MM-DD hh:mm:ss'):""},
    },
    {
      title: 'Time Left',
      dataIndex: 'assigneTime',
      sorter: (a, b) => a.assigneTime - b.assigneTime,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assigneTime'),
      render: (text,record) => {
        let assignTime = moment(record.assigneTime);
        let currentDateTime = moment();
        let diff = currentDateTime.diff(assignTime);
        let timeLeft = record.soakingTime - (diff/60000);
        console.log(timeLeft);
        console.log(moment(assignTime).format('YYYY-MM-DD hh:mm:ss'));
        return <div style={{textAlign:'right'}}>{timeLeft > 0 && record.jobStatus==="YES"?"-":timeLeft > 0 && record.jobStatus==="NO"?<Tag style={{textAlign:'right',backgroundColor:'#87d068',color:"black", fontWeight: 'bold'}}>{timeLeft.toFixed(2) + ' mins'}</Tag>:timeLeft > 0 && record.jobStatus==="YES"?"-":timeLeft > 0 && record.jobStatus==="YES"?<Tag style={{textAlign:'right',backgroundColor:'#f86767',color:"black", fontWeight: 'bold'}}>{timeLeft.toFixed(2) + ' mins'}</Tag>:null}</div>
      },
    }
  ];

  const constructChartData = (workStationsData:string[],inprogressQty:number[],completedQty:number[]) => {
    const workstation = workStationsData?.map(eachAtt=> {return eachAtt});
    const inProgressQty = inprogressQty?.map(eachAtt=> {return eachAtt});
    const completedqty = completedQty?.map(eachAtt=> {return eachAtt});
    const options = {
      chart: {
        type: 'column'
      },
      colors: ['#1890ff','#888',],
      title: {
        text: 'Workstation Wise Status'
      },
      xAxis: {
        title: {
          text: 'WorkStations'
        },
        categories:workstation
      },
      yAxis: {
        min: 0,
        title: {
          text: 'In-progress vs completed'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: 'In-progress',
        data: inProgressQty
      }, {
        name: 'Completed',
        data: completedqty
      }]
    };
    return options;
  };
  return (
  <>
    <Card title={<span style={{ color: 'white'}}>Soaking Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Row gutter={24}>
        <Col>
          <Card
            title={'No. of Lots Available: '+soakingInprogressData.length}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity To be Processed :'+soakingInprogressData.filter(rec=>rec.jobStatus === "NO").length}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Soaking Inventory: '+soakingInprogressData.filter(rec=>rec.jobStatus === "YES").length}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br/>
      <br/>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to='/soaking-assignment-form'><Button danger type="primary" htmlType="submit">
          Assign Material
        </Button></Link>
      </Row>
      <br/>
      <br/>
    <Card title={<span style={{ color: 'white' }}>Tub Wise In-progress Quantity</span>}
      style={{ textAlign: 'center'  }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0}}>
      <br></br>
      <div className="card-container">
            <Table
              key={defaultTabPane}
              rowKey={record => record.containerId}
              columns={columnsSkelton}
              dataSource={soakingInprogressData}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              size="large"
              scroll={{ x:true }}
              bordered/>
      </div>
    </Card>
    <HighchartsReact key={Date.now()} highcharts={Highcharts} options={constructChartData(workStationsData,inprogressQty,completedQty)} />
   
    <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen='production'
          />
      </Modal>
   </Card>
  </>
);
}

export default SoakingDashboard;
