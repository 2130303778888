import {CountQuantityModel} from './count-quantity.model';
export class YieldReportModel{
    supplierType: string;
    farmerCode: string;
    farmerName: string;
    indentDate: Date;
    bookedBy: string;
    unitCode: string;
    lotNumber: string;
    indentWeight: number;
    indentCount:number;
    grnDate: Date;
    grnWeight: number;
    grnCount: number;
    antibioticResult:string;
    soft:string;
    gradingDate: Date;
    avgCount: number;
    totalWeight: number;
    yeild: number;    
    counts: CountQuantityModel;
    concatLotNum:string;

    constructor(
        supplierType: string,
        farmerCode: string,
        farmerName: string,
        indentDate: Date,
        bookedBy: string,
        unitCode: string,
        lotNumber: string,
        indentWeight: number,
        indentCount:number,
        grnDate: Date,
        grnWeight: number,
        grnCount: number,
        antibioticResult:string,
        soft:string,
        gradingDate: Date,
        avgCount: number,
        totalWeight: number,
        yeild: number,
        counts: CountQuantityModel,concatLotNum:string) {
            this.supplierType = supplierType;
            this.farmerCode = farmerCode;
            this.farmerName = farmerName;
            this.indentDate = indentDate;
            this.bookedBy = bookedBy;
            this.unitCode = unitCode;
            this.lotNumber = lotNumber;
            this.indentWeight = indentWeight;
            this.indentCount = indentCount;
            this.grnDate = grnDate;
            this.grnWeight = grnWeight;
            this.grnCount = grnCount;
            this.antibioticResult = antibioticResult;
            this.soft = soft;
            this.gradingDate = gradingDate;
            this.avgCount = avgCount;
            this.totalWeight = totalWeight;
            this.yeild = yeild;
            this.counts = counts;
            this.concatLotNum = concatLotNum;
    }    
  }