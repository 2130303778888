import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { ContainerLogBookDTO } from './container-log-book-dto';

export class ContainerLogBookResponseModel extends GlobalResponseObject{
    data?: ContainerLogBookDTO;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data ContainerLogBookDTO
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ContainerLogBookDTO) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}