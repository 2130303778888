import { FgInspectionSampleDto } from "./fg-inspection-samples.dto";

export class FgInspectionDto{
    fgInspectionId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    lotNumber:string;
    section:string;
    date:Date;
    isActive: boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number
    samplesInfo: FgInspectionSampleDto[];
    item?:string;
    soNumber?:string

}