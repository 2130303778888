import axios from 'axios';
import connection from './connection';
import { PlantOperationCapacityResponse, PlantOperationCapacityDto ,ALLPlantOprationCapacityResponse, PlantIdRequest } from '@gtpl/shared-models/masters';


export class PlantOperationCapacityService{

URL = connection.DEPLOY_URL+'/plant-operation-capacity';


    async create(dto:PlantOperationCapacityDto): Promise<PlantOperationCapacityResponse> {
        console.log(this.URL + '/createPlantOperationCapacity')
        return await axios.post(this.URL + '/createPlantOperationCapacity', dto)
            .then(res => {
                return res.data
            })

    }
    async update(dto:PlantOperationCapacityDto ): Promise<PlantOperationCapacityResponse> {
        return await axios.post(this.URL + '/updatePlantOperationCapacity', dto)
            .then(res => {
                return res.data
            })
    }
    async activatedeActivate(Dto:PlantOperationCapacityDto): Promise<PlantOperationCapacityResponse> {
        return await axios.post(this.URL + '/activateOrDeactivatePlantOperationCapacity', Dto)
            .then(res => {
                return res.data
            })
    }
    async getAll(req:PlantIdRequest): Promise<ALLPlantOprationCapacityResponse> {
        return await axios.post(this.URL + '/getAllPlantOperationCapacity',req)
            .then(res => {
                return res.data
            })
    }


        }