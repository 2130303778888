import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { TaxDropDownDto } from "./tax-drop-down.dto";

export class TaxDropDownResponse extends GlobalResponseObject {
    data?: TaxDropDownDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: TaxDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}
