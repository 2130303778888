
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import { DepartmentDto, ItemCategoriesDto, ItemsDropDownDto, ItemsDto } from '@gtpl/shared-models/masters';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { OperationsRegisterService } from '@gtpl/shared-services/production';
import { CSVLink } from 'react-csv';
// import { Link, Redirect } from 'react-router-dom';
import { OperatonsRegisterDto } from '@gtpl/shared-models/production-management';
const { Option } = Select;

import './operations-register.css';

/* eslint-disable-next-line */
export interface OperationsRegisterProps { }

export function OperationsRegister(
  props: OperationsRegisterProps
) {
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [disable,setDisable]=useState<boolean>(false);
  const [operationData, setOperationData] = useState<OperatonsRegisterDto[]>([]);
  const [lotNumbersForDropDown, setLotNumbersForDropDown] = useState<OperatonsRegisterDto[]>([]);
  const [soNumberForDropDown, setSoNumberForDropDown] = useState<OperatonsRegisterDto[]>([]);
  // const [itemNameForDropDown, setItemNameForDropDown] = useState<StockDto[]>([]);
  const [display, setDisplay] = useState<string>('none');


  const operationsRegisterService = new OperationsRegisterService;

  useEffect(() => {
    getAllStockData();
  }, []);


  function unique(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  const getAllStockData = () => {
    operationsRegisterService.getAllOperationReports().then(res => {
      if (res.status) {
        console.log("-------------Operations data----------------");
        console.log(res.data);
        setOperationData(res.data);
        console.log("-------------important data----------------");
        console.log(res.data);
        console.log(JSON.parse(JSON.stringify(unique(res.data, it => it.saleOrderId))))
        setLotNumbersForDropDown(JSON.parse(JSON.stringify(unique(res.data, it => it.lotNumber))));
        setSoNumberForDropDown(JSON.parse(JSON.stringify(unique(res.data, it => it.saleOrderId))));
        // setItemNameForDropDown(JSON.parse(JSON.stringify(unique(res.data, it => it.itemId))));
        setDisplay('block');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setOperationData([]);
        setDisplay('none');
      }
    }).catch(err => {
      setOperationData([]);
      setDisplay('none');
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const handleLotNumber = (value, item) => {
    const newOperationData = operationData.filter(stock =>
      stock.lotNumber == value
    )
    setOperationData(newOperationData);
    // setLotNumbersForDropDown(JSON.parse(JSON.stringify(unique(newOperationData, it => it.lotNumber))));
    setSoNumberForDropDown(JSON.parse(JSON.stringify(unique(newOperationData, it => it.saleOrderId))));
    //setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  }
  const handleSoNumber = (value, item) => {
    const newStockData = operationData.filter(stock =>
      stock.saleOrderId == value
    )
    setOperationData(newStockData);
    setSoNumberForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.saleOrderId))));
    setLotNumbersForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.lotNumber))));
  }

  // const handleItemName=(value,item)=>{
  //   const newStockData = stockData.filter(stock =>
  //     stock.itemId == value
  //   )
  //   setOperationData(newStockData);
  //   setItemNameForDropDown(JSON.parse(JSON.stringify(unique(newStockData, it => it.itemId))));
  // }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };




  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Sub Lot Number',
      dataIndex: 'subLotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.subLotNumber.localeCompare(b.subLotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('subLotNumber')
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrderNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrderNumber')
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      // responsive: ['lg'],
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('operation')
    },
    {
      title: 'Sub Plant',
      dataIndex: 'subPlantId',
      // responsive: ['lg'],
      sorter: (a, b) => {
        a = a.subPlantId || '';
        b = b.subPlantId || '';
        return a.localeCompare(b);
    },
      // sorter: (a, b) =>  a.subPlantId.localeCompare(b.subPlantId),
       sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('subPlantId')
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Workstation Type',
      dataIndex: 'workstationType',
      // responsive: ['lg'],
      sorter: (a, b) => a.workstationType.localeCompare(b.workstationType),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstationType')
    },
    {
      title: 'Workstation',
      dataIndex: 'workstation',
      // responsive: ['lg'],
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    {
      title: 'Input Grade',
      dataIndex: 'inputGrade',
      // responsive: ['lg'],
      sorter: (a, b) => a.inputGrade - (b.inputGrade),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('inputGrade')
    },
    {
      title: 'Planned Grade',
      dataIndex: 'plannedGrade',
      // responsive: ['lg'],
      sorter: (a, b) => a.plannedGrade - (b.plannedGrade),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedGrade')
    },
    {
      title: 'Actual Grade',
      dataIndex: 'actualGrade',
      // responsive: ['lg'],
      sorter: (a, b) => a.actualGrade - (b.actualGrade),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('actualGrade')
    },
    {
      title: 'Input Quantity',
      dataIndex: 'inputQuantity',
      // responsive: ['lg'],
      sorter: (a, b) => a.inputQuantity - (b.inputQuantity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('inputQuantity')
    },
    {
      title: 'Planned Output Quantity',
      dataIndex: 'plannedOutputQuantity',
      // responsive: ['lg'],
      sorter: (a, b) => a.plannedOutputQuantity - (b.plannedOutputQuantity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedOutputQuantity')
    },
    {
      title: 'Actual Output Quantity',
      dataIndex: 'actualOutputQuantity',
      // responsive: ['lg'],
      sorter: (a, b) => a.actualOutputQuantity - (b.actualOutputQuantity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('actualOutputQuantity')
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'ipBoxes',
      // responsive: ['lg'],
      sorter: (a, b) => a.ipBoxes - (b.ipBoxes),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ipBoxes')
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      // responsive: ['lg'],
      sorter: (a, b) => a.opBoxes - (b.opBoxes),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('opBoxes')
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      // responsive: ['lg'],
      sorter: (a, b) => a.shift.localecompare(b.shift),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('shift')
    },


  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');



  }
  const onReset = () => {
    form.resetFields();
    setOperationData([]);
    }
  return (
    <Card title={<span style={{ color: 'white' }}>Operations Register</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <br></br>

      <Form form={form} layout={'vertical'} >
        <Row gutter={24}>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="lotNumber"
              label="Lot Number"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Lot Number is required'
            //   },
            // ]}

            >
              <Select
                showSearch
                // style={{ width: 260 }}
                placeholder="Select Lot Number"
                optionFilterProp="children"
                onSelect={handleLotNumber}
                //   onFocus={onFocus}
                //   onBlur={onBlur}
                //   onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Option key={0} value={null}>Select Sale Order</Option> */}
                {/* {soItemData.map((data)=>{
                      return <Option key={data.saleOrderId} value={data.saleOrderId}>{data.saleOrderNumber}</Option>
                    })} */}
                {lotNumbersForDropDown.map((data) => {
                  return <Option key={data.lotNumber} value={data.lotNumber}>{data.lotNumber}</Option>
                })}

              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="saleOrderId"
              label="SO Number"

            >
              <Select
                showSearch
                placeholder="Select Category"
                optionFilterProp="children"
                onSelect={handleSoNumber}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {soNumberForDropDown.map((itemCategory) => {
                  return <Option key={itemCategory.saleOrderId} value={itemCategory.saleOrderId}>{itemCategory.saleOrderNumber}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5}} lg={{span:6}} xl={{span:6}}>
                <Form.Item  label="Item Name"
                  name={'itemName'}
                >
                  <Select showSearch placeholder="Select Item"  onSelect={handleItemName}>
                    {itemNameForDropDown === undefined ? '' : itemNameForDropDown.map(dropData => {
                      return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                    })}
                  </Select>
                  </Form.Item>
              </Col> */}
          <Col style={{ marginTop: '30px' }}>
            {/* <Button style={{ marginRight: '4px' }} onClick={() => setDisplay('block')}>Get Report</Button> */}
            <Button  style={{ marginRight: '4px' }} disabled={disable} 
            onClick={() => getAllStockData()}
            >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px', marginRight:'4px' }}  htmlType="submit" onClick={onReset}> Reset </Button>
            <Button><CSVLink filename={"OperationRegister.csv"} data={operationData} >Get Excel </CSVLink></Button>
          </Col>
         
        </Row>
        <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Card title={'No. of Lots : '+operationData.length} style={{textAlign:'left',width: 250, height:41,  backgroundColor: '#52c41a',}}></Card>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        rowKey={record => record.productionLogId}
        columns={columnsSkelton}
        dataSource={operationData}
        style={{ display: display }}
        scroll={{ x: true }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
    </Card>
  );
}

export default OperationsRegister;