export class SOInfoReq{
    saleOrderId:number;
    saleOrderNumber:string;

    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     */
    constructor(saleOrderId:number,saleOrderNumber:string){
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
    }
}