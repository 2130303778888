export class VPLActivateOrDeActiveRequest{
    vendorPriceListId: number;
    isActive: boolean;
    versionFlag?:number;
    updatedUser?:string;
    
    constructor(vendorPriceListId: number, isActive: boolean, versionFlag?:number, updatedUser?:string){
        this.vendorPriceListId = vendorPriceListId;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.updatedUser = updatedUser;
    }
}