import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GradesDropDownData } from './grades-drop-down-model';

export class GradesDropDownDataResponseModel extends GlobalResponseObject{
    data?: GradesDropDownData[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //GradesDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: GradesDropDownData[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
