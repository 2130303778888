import axios from 'axios';
import connection from './connection';
import {SoakingSampleTestDto, AllSoakingSampleTestReponseModel, SoakingSampleTestReponseModel, LogIdRequest} from '@gtpl/shared-models/production-management';
import { CommonResponse } from '@gtpl/shared-models/masters';

export class SoakingSampleTestService{

    URL = connection.DEPLOY_URL + '/soaking-sample-test';

    async createSoakingSampleTest(dto: SoakingSampleTestDto ): Promise<SoakingSampleTestReponseModel> {
        console.log(dto);
       
        return await axios.post(this.URL + '/createSoakingSampleTest',dto)
            .then(res => {
                return res.data
            })
    }

    async getSamplesInfoBylogId(req: LogIdRequest): Promise<AllSoakingSampleTestReponseModel>{
        return await axios.post(this.URL + '/getSamplesInfoBylogId',req)
        .then(res => {
            return res.data
        })
    }

}