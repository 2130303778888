import React, { useEffect, useState } from 'react';
import styles from './packing-dashboard.module.css';
import { Row, Col, Divider, Card, Badge, Table, Form, Select, Checkbox, Empty, Spin, Alert, Button } from 'antd';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EndCustomerDto, ItemsDropDownDto, ItemSubCategoryDropDownDto, PlantsDropDown, SubCatReqForCategoryId, VendorDropDownDto } from '@gtpl/shared-models/masters';
import { EndCustomersService, ItemsService, ItemSubCategoryService, UnitcodeService, VendorsService } from '@gtpl/shared-services/masters';
import { OrderInfo, PackingBomFilter, UnitsRequiredBomInfo } from '@gtpl/shared-models/procurement-management';
import { ShoppingCartOutlined, SearchOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import {CustomSpinner} from '../../../../../../shared-components/custom-spinner/src/lib/custom-spinner'

const { Option } = Select;
/* eslint-disable-next-line */
export interface PackingBOMProps { }

export function PackingBOM(
    props: PackingBOMProps
) {
    const [loading, setLoading] = useState<boolean>(false);
    const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
    const [requiredBom, setRequiredBom] = useState<UnitsRequiredBomInfo[]>([]);
    const [filteredBom, setFilteredBom] = useState<UnitsRequiredBomInfo[]>([]);
    const [items, setItems] = useState<ItemsDropDownDto[]>([]);
    const [itemSubCategory, setItemSubCategory] = useState<ItemSubCategoryDropDownDto[]>([]);
    const [checkKey, setCheckKey] = useState(0);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [soIdsRecords, setSoIdsRecords] = useState([]);
    const [generatePO, setGeneratePO] = useState<boolean>(false);
    const [checkStrictly, setCheckStrictly] = React.useState(false);
    const [unitId, setUnitId] = useState<number>(0);
    const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([]);
    const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
    const [endcustomersData, setendcustomersData] = useState<EndCustomerDto[]>([]);
    const itemService = new ItemsService;
    const itemSubCategoryService = new ItemSubCategoryService();

    const vendorService = new VendorsService();
    const saleService = new SaleOrderService();
    const unitService = new UnitcodeService();
    const endcustomerservice = new EndCustomersService();

    const [form] = Form.useForm();
    const [poform] = Form.useForm();
    useEffect(() => {
        
        PackingBomData();
       if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ unitName: Number(localStorage.getItem('unit_id')) })
          } 
        return () => { };
    }, []);

    useEffect(() => {
        
        
        getPlantsData();
        getAllVendors();
        getItems();
        getEndCustomersData();
        getItemSubCategories();
        getSOsAgainstPlant();
      
    }, [loading]);

    const getPlantsData = () => {
        unitService.getAllMainPlants().then((res) => {
            if (res.status) {
                setFactoriesData(res.data);
                setFactoriesData(res.data);
            } else {
                setFactoriesData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setFactoriesData([]);
        });
    }

    const getSOsAgainstPlant = () => {
        const plantId = form.getFieldValue('unitName')
        setSaleOrderData([]);
        form.setFieldsValue({ saleOrderId: undefined })
        const req = new UnitRequest(plantId);
        saleService.getSOsAgainstPlant(req).then((res) => {
            if (res.status) {
                setSaleOrderData(res.data);
            } else {
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });

    }

    const getEndCustomersData = () => {
        endcustomerservice.getAllActiveEndCustomers().then((res) => {
            if (res.status) {
                setendcustomersData(res.data);
            } else {
                setendcustomersData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setendcustomersData([]);
        });
    }

    const getItems = () => {
        setItems([]);
        itemService.getItemsDropDownData().then((res) => {
            if (res.status) {
                setItems(res.data);
            } else {
                setItems([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        });
    }

    const getItemSubCategories = () => {
        setItemSubCategory([])
        const itemCategoryId = 6
        const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
        itemSubCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then((res) => {
            if (res.status) {
                setItemSubCategory(res.data);
            } else {
                setItemSubCategory([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        })
    }
    const PackingBomData = () => {
        setLoading(true);
        setRequiredBom([]);
       // const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
        const unitId = Number(localStorage.getItem('unit_id'))===5 ? form.getFieldValue('unitName') : Number(localStorage.getItem('unit_id'));
        const saleOrderIds = form.getFieldValue('saleOrderId');
        const itemId = form.getFieldValue('itemName');
        const endCustomerId = form.getFieldValue('endCustomerId');
        const itemSubCategoryId = form.getFieldValue('itemSubCategoryId')
        const filter = new PackingBomFilter();
        filter.unitId = unitId;
        filter.saleOrderIds = saleOrderIds;
        filter.itemName = itemId;
        filter.endCustomerId = endCustomerId;
        filter.itemSubCategoryId = itemSubCategoryId
        saleService.PackingBomData(filter).then((res) => {
            if (res.status) {
                setRequiredBom(res.data);
                setFilteredBom(res.data)
            } else {
                setRequiredBom([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setRequiredBom([]);
            AlertMessages.getErrorMessage(err.message);
        });
    }

    const getRows = (val) => {
        const rows = val.map((val1, i) => {
            if (i) {
                return <tr style={{ border: '1px solid black', lineHeight: 'normal' }}><th style={{ border: '1px solid black' }}>{val1.endCustomerName}</th><th style={{ border: '1px solid black' }}>{val1.brandName}</th><th style={{ border: '1px solid black' }}>{val1.poNumber}</th><th style={{ border: '1px solid black' }}>{val1.etd}</th><th style={{ border: '1px solid black' }}>{val1.itemName}</th><th style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val1.quantity)}</th><th style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val1.requiredQty)}</th><th style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val1.assignQty)}</th><th style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val1.poQty)}</th><th style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val1.receivedQty)}</th></tr>;
            }
        })
        return rows;
    };


    const captureRecords = (e, record: OrderInfo) => {
        const unitId = record.unitId;
        const bomId = record.bomId;
        const saleOrderId = record.saleOrderId;
        if (e.target.checked) {
            selectedRecords.push(bomId);
            soIdsRecords.push(saleOrderId);
            setSoIdsRecords([...new Set(soIdsRecords)]);
            setSelectedRecords([...new Set(selectedRecords)]);
        } else {
            if (selectedRecords.includes(bomId)) {
                const filteredRecords = selectedRecords.filter((val) => val != bomId);
                setSelectedRecords([...new Set(filteredRecords)]);
            }
            if (soIdsRecords.includes(saleOrderId)) {
                const filteredRecords = selectedRecords.filter((val) => val != saleOrderId);
                setSoIdsRecords([...new Set(filteredRecords)]);
            }
        }
        setUnitId(unitId);
        setCheckKey((key) => key + 1);
    }

    const generatePurchaseOrder = () => {
        poform.validateFields().then(poRes => {
            setGeneratePO(true);
        });
    }

    // To get Vendors
    const getAllVendors = () => {
        vendorService.getVendorsDropDownData().then(res => {
            if (res.status) {
                // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
                setVendorData(res.data);
            } else {
                if (res.intlCode) {
                    setVendorData([]);
                } else {
                    setVendorData([]);
                }
            }
        }).catch(err => {
            setVendorData([]);
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const onReset = () => {
        form.resetFields();
        PackingBomData();
    }

    return (
        <Card title={<span style={{ color: 'white' }}>Packing BOM</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            {(generatePO) ? <Redirect to={{ pathname: "/purchase-order-form", state: { bomIds: selectedRecords, type: 'GeneratePO', vendorId: poform.getFieldValue('vendorId'), unitId: unitId, soIds: soIdsRecords, fromBomTrackingReport: true } }} /> : null}
            <Form layout="vertical" form={form} name="control-hooks" >
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                        <Form.Item
                            name="unitName"
                            label="Unit"
                            rules={[
                                {
                                    required: false, message: 'Missed Unit'
                                },
                            ]}>
                            <Select
                                allowClear
                                showSearch
                                onChange={getSOsAgainstPlant}
                                placeholder="Select Unit"
                                dropdownMatchSelectWidth={false}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {factoriesData.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                        <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: false, message: 'SO Number is Required' },]}>
                            <Select
                                allowClear
                                showArrow
                                placeholder="Select SO Number"
                                optionFilterProp="children"
                                mode="multiple"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                dropdownMatchSelectWidth={false}
                                showSearch
                            >
                                {saleOrderData.filter(item=>(item.status!='CANCELLED' && item.status!='CLOSED') ).map((saleOrder) => {
                                    return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.saleOrderNumber}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="endCustomerId" label="End Customer Name" rules={[{ required: false, message: 'Missing end customer name' }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select end customer"
                                allowClear
                            >

                                {endcustomersData.map(dropData => {
                                    return <Option value={dropData.endCustomerId}>{dropData.endCustomerName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="itemSubCategoryId"
                            label="Item Sub Category"
                            rules={[
                                {
                                    required: false,
                                    message: 'Item Sub Category is required'
                                },
                                {
                                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                                    message: `Enter valid Item Sub Category `,
                                },
                            ]}

                        >
                            <Select
                                showSearch
                                // style={{ width: 240 }}
                                allowClear
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={null}>Select Sub Category</Option>
                                {itemSubCategory.map((subCategory) => {
                                    return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                        <Form.Item label="Item Name"
                            name={'itemName'}
                            rules={[{ required: false, message: 'Missing Item Name' }]}

                        >
                            <Select showSearch placeholder="Select Item" allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} dropdownMatchSelectWidth={false}>
                                {items === undefined ? '' : items.map(dropData => {
                                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.itemName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ paddingTop: '30px' }}>
                        <Button type="primary" shape="round" icon={<SearchOutlined />} onClick={PackingBomData}>
                            Search
                        </Button>
                        <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
            {/* <table style={{ border: '1px solid black', width: '100%' }}>
                <tr style={{ border: '1px solid black', lineHeight: 'normal' }}><th style={{ border: '1px solid black' }}>Unit</th><th style={{ border: '1px solid black' }}>Customer</th><th style={{ border: '1px solid black' }}>Brand</th><th style={{ border: '1px solid black' }}>PO Number</th><th style={{ border: '1px solid black' }}>ETD</th><th style={{ border: '1px solid black' }}>Item</th><th style={{ border: '1px solid black' }}>Qty</th><th style={{ border: '1px solid black' }}>Required Qty</th><th style={{ border: '1px solid black' }}>Assigned Qty</th><th style={{ border: '1px solid black' }}>PO Qty</th><th style={{ border: '1px solid black' }}>GRN Qty</th></tr>
                {
                    requiredBom.map((val) => {
                        return <><tr style={{ border: '1px solid black', lineHeight: 'normal' }}>
                            <th rowSpan={val[1]?.length} style={{ border: '1px solid black' }}>{val[0]}</th>
                            <th style={{ border: '1px solid black' }}>{val[1][0].endCustomerName}</th>
                            <th style={{ border: '1px solid black' }}>{val[1][0].brandName}</th>
                            <th style={{ border: '1px solid black' }}>{val[1][0].poNumber}</th>
                            <th style={{ border: '1px solid black' }}>{val[1][0].etd}</th>
                            <th style={{ border: '1px solid black' }}>{val[1][0].itemName}</th>
                            <th style={{ border: '1px solid black', textAlign:'right'}}>{Number(val[1][0].quantity)}</th>
                            <th style={{ border: '1px solid black', textAlign:'right'}}>{Number(val[1][0].requiredQty)}</th>
                            <th style={{ border: '1px solid black', textAlign:'right'}}>{Number(val[1][0].assignQty)}</th>
                            <th style={{ border: '1px solid black', textAlign:'right'}}>{Number(val[1][0].poQty)}</th>
                            <th style={{ border: '1px solid black', textAlign:'right'}}>{Number(val[1][0].receivedQty)}</th>
                        </tr>{getRows(val[1])}</>;
                    })
                }
            </table>
            <Table
                rowKey={record => record[0]}
                columns={columns}
                expandable={{
                    expandedRowRender: record => <table>{getRows(record[1])}</table>,
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
                dataSource={requiredBom}
            /> */}
            {filteredBom?.length ? <table className="table table-striped" style={{ border: '1px solid black', width: '100%' }}>

                {
                    // looping the response object
                    filteredBom.map((val, i) => {
                        return val.children.map((val1, i1) =>  {
                            let totalRawMaterialQtyAtSameLoc = val1.availableQty;
                            return val1.children.map((val2, i2) => {

                                const generateButton = ((i === 0 && i1 === 0 && i2 === 0)) ? <tr>
                                    <td colSpan={11} style={{ textAlign: 'right' }}>
                                        <Form layout="vertical" form={poform} name="control-hooks" >
                                            <Row gutter={24}>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                                    <Form.Item name="vendorId" label={false} rules={[{ required: true, message: 'Vendor is Required' },]}>
                                                        <Select showSearch placeholder="Select Vendor" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option key={0} value={null}>Select Vendor</Option>
                                                            {vendorData.map((vendor) => {
                                                                return <Option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>

                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                                    <Button type="primary" shape="round" icon={<ShoppingCartOutlined />} onClick={generatePurchaseOrder} size={'large'}>
                                                        Generate Po
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </td>
                                </tr> : null;
                                const header = (i >= 0 && i1 === 0 && i2 === 0) ? <tr style={{ border: '1px solid black', lineHeight: 'normal' }}>
                                    <th style={{ border: '1px solid black' }}>Unit</th>
                                    <th style={{ border: '1px solid black' }}>Customer</th>
                                    <th style={{ border: '1px solid black' }}>Cust PO</th>
                                    <th style={{ border: '1px solid black' }}>ETD</th>
                                    <th style={{ border: '1px solid black' }}>Item</th>
                                    <th style={{ border: '1px solid black'}}>Required Qty</th>
                                    <th style={{ border: '1px solid black' }}>Assigned Qty</th>
                                    <th style={{ border: '1px solid black' }}>PO Qty</th>
                                    <th style={{ border: '1px solid black' }}>GRN Qty</th>
                                    <th style={{ border: '1px solid black' }}>To be Procure</th>
                                    <th style={{ border: '1px solid black' }}>Generate PO</th>
                                </tr> : null;

                                const unitName = (i >= 0 && i1 === 0 && i2 === 0) ? <th rowSpan={val.rowSpan + (5 * val.children.length)} style={{ border: '1px solid black' }}>{val.unitName}</th> : null;
                                const customerName = (i >= 0 && i1 >= 0 && i2 === 0) ? <th rowSpan={val1.rowSpan + 5} style={{ border: '1px solid black' }}>{val1.customerName}</th> : null;

                                let checkboxDisplay = true;
                                let currentReplaceableStock = val2.requiredQty;
                                // after reducing the current BOM qty from the available qty within the same location, then if still we have some avl qty, then we no need to display the checkbox
                                if(totalRawMaterialQtyAtSameLoc > 0) {
                                    if(totalRawMaterialQtyAtSameLoc - val2.requiredQty >= 0) {
                                        checkboxDisplay = false;
                                        currentReplaceableStock = 0;
                                        totalRawMaterialQtyAtSameLoc -= val2.requiredQty;
                                    } else {
                                        currentReplaceableStock = Number(val2.requiredQty) - Number(totalRawMaterialQtyAtSameLoc);
                                        totalRawMaterialQtyAtSameLoc = 0;
                                    }
                                }
                                // Number(val2.quantity) - (Number(val2.poQty) - Number(val2.receivedQty))
                                const checkBox = (Number(val2.requiredQty) > 0) ? <td style={{ textAlign: 'center' }}><Checkbox onChange={(e) => captureRecords(e, val2)}></Checkbox></td> : <td>-</td>;

                                const total = (val1.children.length - i2 === 1) ? <>
                                    <tr style={{ border: '1px solid black', lineHeight: 'normal', textAlign: 'right' }}>
                                        <td colSpan={3} style={{ border: '1px solid black' }}>Total Required</td>
                                        <td style={{ border: '1px solid black',paddingRight:'3px' }}>{val1.totalQty}</td>
                                        <td colSpan={5} style={{ border: '1px solid black' }}></td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', lineHeight: 'normal', textAlign: 'right' }}>
                                        <td colSpan={3} style={{ border: '1px solid black' }}>Available Quantity @ {val.unitName}</td>
                                        <td style={{ border: '1px solid black',paddingRight:'3px' }}>{val1.availableQty}</td>
                                        <td colSpan={5} style={{ border: '1px solid black' }}></td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', lineHeight: 'normal', textAlign: 'right' }}>
                                        <td colSpan={3} style={{ border: '1px solid black' }}>Item available at other plants and there are no orders for this item in the plant</td>
                                        <td style={{ border: '1px solid black',paddingRight:'3px' }}>{val1.othersAvailableQty}</td>
                                        <td colSpan={5} style={{ border: '1px solid black' }}></td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', lineHeight: 'normal', textAlign: 'right' }}>
                                        <td colSpan={3} style={{ border: '1px solid black' }}>MOQ</td>
                                        <td style={{ border: '1px solid black',paddingRight:'3px' }}>{val2.minQnty}</td>
                                        <td colSpan={5} style={{ border: '1px solid black' }}></td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', lineHeight: 'normal', textAlign: 'right' }}>
                                        <td colSpan={3} style={{ border: '1px solid black' }}>To be Procured</td>
                                        <td style={{ border: '1px solid black' }}>
                                            <span style={{minWidth:'50%'}}>{Number(val1.totalRequiredQty)}</span>
                                            <span style={{minWidth:'50%'}}>(<b style={{color: 'green'}}>
                                                { Number(val1.totalRequiredQty) - Number(val1.availableQty) > 0 ? Number(val1.totalRequiredQty) - Number(val1.availableQty) : 0}
                                                </b>)
                                            </span>
                                        </td>
                                        <td style={{ border: '1px solid black' }}>{val1.totalAssignedQty}</td>
                                        <td style={{ border: '1px solid black' }}>{val1.totalPoQty}</td>
                                        <td colSpan={3} style={{ border: '1px solid black' }}></td>
                                    </tr>
                                </> : null;

                                return <>{(selectedRecords.length > 0) ? generateButton : null}{header}<tr style={{ border: '1px solid black', lineHeight: 'normal' }}>     {unitName}
                                    {customerName}
                                    <td style={{ border: '1px solid black' }}>{val2.poNumber}</td>
                                    <td style={{ border: '1px solid black' }}>{val2.etd}</td>
                                    <td style={{ border: '1px solid black' }}>{val2.itemName}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'right',paddingRight:'3px' }}>{Number(val2.quantity)}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val2.assignQty)}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val2.poQty)}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'right' }}>{Number(val2.receivedQty)}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'right' }}>
                                        <span style={{minWidth:'50%'}}>{Number(val2.requiredQty)}</span>
                                        <span style={{minWidth:'50%'}}>(<b style={{color: 'green'}}>{Number(currentReplaceableStock)}</b>)</span>
                                    </td>
                                    { checkboxDisplay ? checkBox : <td>-</td> }
                                </tr>{total}</>
                            });
                        });
                    })
                }
            </table>
                : <Empty />}
        </Card >
    );
}

export default PackingBOM;
