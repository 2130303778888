
export class GrnIdRequest {
    grnId: number;
    /**
     * 
     * @param grnId 
     */
    constructor(grnId: number){
        this.grnId=grnId;
    }
}