import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Tooltip, Form, Col, Row, Select, Modal, Empty, Popconfirm, message, Tabs, DatePicker, Typography, Pagination, Descriptions, Statistic, Spin, Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, FileExcelOutlined, FilePdfOutlined, WhatsAppOutlined,DoubleLeftOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import './stock-grid.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MessageRequest, ShiftsDropDown, StockInTransactionsRequest, StockTypeDropDown, StockTypesEnum, StockTypeStatus, UnitRequest } from '@gtpl/shared-models/common-models';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { MasterBrandService, RackPositionService, SkuService } from '@gtpl/shared-services/masters';
import { MasterBrandDto, MasterBrandsDropDownDto } from '@gtpl/shared-models/masters';
// import {MasterBrandsService} from '@gtpl/shared-srvices/MasterBrandsService'
import { UnitcodeService } from '@gtpl/shared-services/masters';
import {WhatsAppNotificationService} from '@gtpl/shared-services/notification-management'


const { Option } = Select;
/* eslint-disable-next-line */
export interface StockLocationUpdateProps {

}


export function StockLocationUpdate(props: StockLocationUpdateProps) {
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [page, setPage] = React.useState(1);
    const [stockData, setStockData] = useState<any[]>([])
    const [selectedRow, setSelectedRow] = useState<any>(undefined)
    const [rackPositionsData, setRackPositionsData] = useState<any>([])
    const [disable, setDisable] = useState<boolean>(false)
    const [brandfiltersData, setFiltersData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false)
    const [modalForm] = Form.useForm();
    const whatsAppService = new WhatsAppNotificationService
    const stockInService = new StockInService();
    const stockService = new FGStockService();
    const rackPositionService = new RackPositionService();





    useEffect(() => {
        getStockInTransactions()
    }, [])



    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                type="search"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                )
            ) : null,
    });

    const getAvailableLocationsList = () => {
      rackPositionService.getNotOccupiedLocationsForPlant({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
        if (res.status) {
          setRackPositionsData(res.data)
        } else {
            setRackPositionsData([])
            AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch((error) => {
        setRackPositionsData([])
        AlertMessages.getErrorMessage('Something went wrong')
      })
    }
    const getStockInTransactions = () => {
        stockInService.getAllStock({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
            setDisable(false)
            if (res.status) {
                setStockData(res.data)
            } else {
                setStockData([])
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch((error) => {
            setStockData([])
            AlertMessages.getErrorMessage('Something went wrong')
        })
    }

    const onCancel = () => {
        setVisible(false)
    }

    const updateLocation = () => {
      console.log(selectedRow);
      if(selectedRow.stockId > 0){
        console.log(selectedRow);
        console.log(modalForm.getFieldsValue());
        modalForm.validateFields().then((res) => {
          console.log(res);
          stockService.getUpdateStockLocation({stockId:selectedRow.stockId,rackPosition:res.rackPostion}).then((res) => {
            if (res.status) {
              onCancel()
              getStockInTransactions();
              AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
          });
        })
      }
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');

    }


    /**
    * 
    * @param pagination 
    * @param filters 
    * @param sorter 
    * @param extra 
    */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const columnsSkeleton: ColumnProps<any>[] = [
        {
          title: 'Sno',
          dataIndex: 'saleOrderId',
          width: 50,
          fixed: 'left',
          align: 'center',
          render: (text, object, index) => (page - 1) * 10 + (index + 1)
    
        },
        {
          title: 'Prod Unit',
          dataIndex: 'unitName',
          fixed: 'left',
          filters: [
            { text: "Kakinada", value: "Kakinada" },
            { text: "Unit 1", value: "Unit 1" },
            { text: "Unit 2", value: "Unit 2" },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            return record.unitName == value;
          }
        },
        {
          title: 'Stock Type',
          dataIndex: 'stockType',
          sorter: (a, b) => a.stockType.length - b.stockType.length || a.stockType.localeCompare(b.stockType),
          sortDirections: ['descend', 'ascend'],
          // ...getColumnSearchProps('stockType'),
          responsive: ["lg"],
          filters: [
            { text: 'Anticipated', value: 'anticipated' },
            { text: 'Dummy', value: 'dummy' },
            { text: 'SaleOrder', value: 'SaleOrder' },
            { text: 'SuperDummy', value: 'superDummy' },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            return record.stockType === value;
          },
        },
    
        {
          title: 'Cold Storage',
          dataIndex: 'coldStorage',
          sorter: (a, b) => a.coldStorage?.localeCompare(b.coldStorage),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('coldStorage'),
        },
        {
          title: 'Rack Position',
          dataIndex: 'rackPosition',
          sorter: (a, b) => a.rackPosition.toString().length - b.rackPosition.toString().length || a.rackPosition.toString().localeCompare(b.rackPosition.toString()),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('rackPosition'),
        },
        {
          title: 'Customer PO',
          dataIndex: 'poNumber',
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('poNumber'),
        },
        {
          title: 'Brand Name',
          dataIndex: 'brandName',
          filters: brandfiltersData,
    
          filterMultiple: true,
          onFilter: (value, record) => {
            return record.brandName == value;
          },
          render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
          title: 'Product SKU',
          dataIndex: 'variantCode',
          // filters: productfiltersData,
          // filterMultiple: true,
          // onFilter: (value, record) => {
          //   return record.variantCode == value;
          // },
          render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
          title: 'Pack Style',
          dataIndex: 'packStyle',
          ...getColumnSearchProps('packStyle'),
          render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
          title: 'Product Category',
          dataIndex: 'productCategory',
          sorter: (a, b) => a.productCategory.length - b.productCategory.length || a.productCategory.localeCompare(b.productCategory),
          sortDirections: ['descend', 'ascend'],
          responsive: ["lg"],
          filters: [
            { text: 'Over Count', value: 'overcount' },
            { text: 'Under Count', value: 'undercount' },
            { text: 'G1', value: 'g1' },
            { text: 'G2', value: 'g2' },
            { text: 'Cut', value: 'cut' },
            { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },
          ],
          filterMultiple: true,
          onFilter: (value, record) => {
            return record.productCategory == value;
          },
        },
    
        {
          title: 'Cartons',
          dataIndex: 'totalCartons',
          render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        // {
        //   title: 'Loose Pouches',
        //   dataIndex: 'loosePouches',
        //   render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        // },
        // {
        //   title: 'Date',
        //   dataIndex: 'stockDate',
        //   render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY:h:mm A")}</span>)
        // },
        {
          title:`Action`,
          dataIndex: 'action',
          render: (text, rowData) => (
            <span>         
                <Tooltip title='Update Location'><DoubleLeftOutlined  className={'editSamplTypeIcon'}  type="edit" 
                  onClick={() => {
                    console.log(rowData)
                      setSelectedRow(rowData);
                      getAvailableLocationsList()
                      setVisible(true);
                  }}
                  style={{ color: '#1890ff', fontSize: '16px' }}
                /></Tooltip>
            </span>
          )
        }
    
      ];

      function onBlur() {
        // console.log('blur');
      }
    
      function onFocus() {
        // console.log('focus');
      }
    
      function onSearch(val) {
        // console.log('search:', val);
      }
    return (
        <Card
            title={<span style={{ color: 'white' }}>Location Update</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
            <Table rowKey={record => record.stockId}
            columns={columnsSkeleton} dataSource={(stockData.filter(res => res.stockLocationId != 0))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll={{ x: true }}
            size="small"
            bordered
            />
            <Modal title='Locations List' visible={visible} onCancel={onCancel}>
                <Form form={modalForm} layout={'vertical'} >
                    <Row gutter={12}>
                      <Col span={10}>
                        <Form.Item
                          name="rackPostion"
                          label="New Location"
                          rules={[
                            {
                              required: true, message: 'Missing Rack Position',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Rack Position"
                            // onChange={handleSaleOrder}
                            allowClear
                            showSearch
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value={undefined}>Select Rack Position</Option>
                            {rackPositionsData.map(res => {
                              return <Option value={res.rackPositionId}>{res.rackCode}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                        <Col span={2} style={{marginTop:'30px'}}>
                            <Button type='primary' onClick={updateLocation} > Submit</Button>
                        </Col>
                    </Row>
                </Form>

            </Modal>




        </Card>

    );
}

export default StockLocationUpdate;
