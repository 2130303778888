import axios from 'axios';
import connection from './connection';
import { IndentFormDTO, IndentNumberRequest, indentrequest } from '@gtpl/shared-models/procurement-management';
import { CommonResponse } from '@gtpl/shared-models/sale-management';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
export class PackingIndentService {
    URL = connection.DEPLOY_URL + '/packing-indent';
    async getIndentDataByIndentNumber(req: IndentNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getIndentDataByIndentNumber', req)
            .then(res => {
                return res.data
            })
    }
    async getindentbarcodedetails(): Promise<CommonResponse>{
        return await axios.post(this.URL + '/getindentbarcodedetails')
            .then(res => {
                return res.data
            })
     }

     async CreateIndentFormEntry(dto:IndentFormDTO) : Promise<CommonResponse>{
        return await axios.post(this.URL + '/CreateIndentFormEntry', dto)
        .then(res => {
            return res.data
        }).catch(error=>{
            return error;
        })
    }
    async updateReceivedQuantity(req: indentrequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateReceivedQuantity', req)
            .then(res => {
                return res.data
            })
    }
    async getReceivedQuantityagainstPonumber(req: IndentNumberRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getReceivedQuantityagainstPonumber', req)
            .then(res => {
                return res.data
            })
    }
    async updateIssuedQuantity(req: indentrequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateIssuedQuantity', req)
            .then(res => {
                return res.data
            })
    }
    async getIndentDropDownData(req: PlantIdRequest): Promise<CommonResponse> {
        console.log('request',req)
        return await axios.post(this.URL + '/getIndentDropDownData', req)
            .then(res => {
                return res.data
            })
    }
}