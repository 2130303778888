import axios from 'axios';
import { MasterBrandDto, MasterBrandResponseModel, AllMasterBrandsResponseModel, MasterBrandDtoDefault, MasterDropDownResponseModel, BrandsRequest, BrandIDReq } from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';
import { CommonResponse } from '@gtpl/shared-models/production-management';

export class MasterBrandService {
    getAllFiles() {
        throw new Error('Method not implemented.');
    }
    URL = connection.DEPLOY_URL + '/master-brands';

    async createMasterBrand(masterBrand: MasterBrandDto): Promise<MasterBrandResponseModel> {
        console.log(masterBrand);
        return await axios.post(this.URL + '/createMasterBrand', masterBrand)
            .then(res => {
                return res.data
            })
    }

    async getAllMasterBrands(req?:UserRequestDto): Promise<AllMasterBrandsResponseModel> {
        return await axios.post(this.URL + '/getAllMasterBrands',req)
            .then(res => {
                return res.data
            })
    }
    async updateMasterBrand(masterBrand: MasterBrandDto): Promise<MasterBrandResponseModel> {
        return await axios.post(this.URL + '/updateMasterBrand', masterBrand)
            .then(res => {
                return res.data
            })
    }

    async getAllMasterBrandsDropDown(): Promise<MasterDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllMasterBrandsDropDown')
            .then(res => {
                return res.data
            })
    }
    async getMasterBrandById(brandReq: BrandsRequest): Promise<any> {
        return await axios.post(this.URL + '/getMasterBrandById', brandReq)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateMasterBrand(brandReq: MasterBrandDto): Promise<MasterBrandResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateMasterBrand', brandReq)
            .then(res => {
                return res.data
            })
    }


    async getBrandInfoById(brandReq: BrandsRequest): Promise<MasterBrandResponseModel> {
        return await axios.post(this.URL + '/getBrandInfoById', brandReq)
            .then(res => {
                return res.data
            })
    }
    
    async getAllFilesData(): Promise<MasterBrandResponseModel> {
        return await axios.post(this.URL + '/getAllFiles')
            .then(res => {
                return res.data
            })
    }
    async Brandupload(file: any) {
        return await axios.post(this.URL + '/brandUpload', file)
            .then(res => {
                return res.data
            })
    }
    async getDisplayBrandsById(req:BrandIDReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDisplayBrandsById',req)
            .then(res => {
                return res.data
            })
    }
}