import React, { useEffect, useRef, useState } from 'react';
import { OperationInventoryRequest, OperationsDropDown, ProductionInventoryDetails } from '@gtpl/shared-models/production-management';
import './production-inventory-transfer.css';

import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button, Table, Empty, Divider, Tooltip, Modal, Spin, Alert } from 'antd';
import { SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
import { ProductionInventoryService } from '@gtpl/shared-services/production';
import Link from 'antd/lib/typography/Link';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { SoInfoViewPages } from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'
import {ProductService} from '@gtpl/shared-services/masters';
import { ProductDto, ProductsDropDownData } from '@gtpl/shared-models/masters';
import { StockTransfer } from '@gtpl/pages/production-management/production-management-components/stock-transfer';
const { Option } = Select;

/* eslint-disable-next-line */
export interface ProductionInventoryTransferProps { }

export function ProductionInventoryTransfer(
  props: ProductionInventoryTransferProps
) {
  const [inventoryTransfer] = Form.useForm();
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [inventory, setInventoryData] = useState<ProductionInventoryDetails[]>([]);
  const searchInput = useRef(null);
  const activeProducts =  new ProductService();
  const [productsData, setProductsData] = useState<ProductDto[]>([]);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedInformation, setSelectedInformation] = useState<ProductionInventoryDetails>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saleId, setSaleId] = useState(0);
  const [typeModel, setTypeModel] = useState('');
  const inventoryService = new ProductionInventoryService();
 
  useEffect(() => {
    getAllLotOps();
    getAllActiveProducts();
  }, []);

  const getAllLotOps = () => {
    // const unitIdReq:UnitRequest={
    //   unitId: plantId
  }
  const getAllActiveProducts = () => {
    activeProducts.getAllActiveProducts().then((res) => {
      if (res.status) {
        setProductsData(res.data);
        let filtersArray:{text:string,value:string}[] = []
        res.data.map(item => {
          if(!(filtersArray.includes({text:item.productName,value:item.productName})))
          filtersArray.push({text:item.productName,value:item.productName})
        })
        setFiltersData(filtersArray)

        console.log(res.data)
        // console.log(getProductFilters())
      } else {
        setProductsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    setProductsData([]);
    });
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  const handleCancel = () => {
    getAllLotOps();
    setIsModalVisible(false);
    getInventoryInfo()
  };

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid", saleOrderId);
    setIsModalVisible(true)
    setTypeModel('detailView');
  }


  const getInventoryInfo = () => {
    const operation = inventoryTransfer.getFieldValue('operation');
    if (operation) {
      setInventoryData([]);
      setLoading(true);
      const req = new OperationInventoryRequest(operation, Number(localStorage.getItem('unit_id')))
      inventoryService.getStockInventory(req).then((res) => {
        if (res.status) {
          setInventoryData(res.data);
          setLoading(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setLoading(false);
          setInventoryData([]);
        }
      })
        .catch((err) => {
          setLoading(false);
          setInventoryData([]);
          AlertMessages.getErrorMessage(err.message);
        });
    }
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Lot Code',
      dataIndex: 'lotandplantlot',
      sorter: (a, b) => a.lotandplantlot.localeCompare(b.lotandplantlot),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotandplantlot'),
      render: (value, record) => {
        return record.lotNumber + (record.plantLotNumber != null ? "-" + record.plantLotNumber : '');
      }
    },

    //   title: 'So Number',
    //   dataIndex: 'saleOrder',
    //   sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('saleOrder'),
    //   render: (value, record) => 
    //  <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.saleOrder}</Link>
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      width:'20px',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) =>
        <Link onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Link>
    },
    {
      title: 'Product SKU',
      dataIndex: 'productSKU',
      sorter: (a, b) => a.productSKU?.localeCompare(b.productSKU),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('productSKU'),
    },
    {
      title: 'Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count?.localeCompare(b.count),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Product',
      dataIndex: 'product',
      // sorter: (a, b) => a.product.localeCompare(b.product),
      // sortDirections: ['descend', 'ascend'],
      render:(text,data) => {
        console.log(data)
        return text
      },
      
      // ...getColumnSearchProps('product'),
      

      filters: filtersData
       ,
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        console.log(value)
        // === is not work
        return record.product === value;
      },
       
    },

    {
      title: 'I/P Boxes',
      dataIndex: 'boxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },

    {
      title: 'WIP QTY',
      dataIndex: 'wipQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Transferred QTY',
      dataIndex: 'transferedQty',
      width:'20px',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Wastage QTY',
      dataIndex: 'wastageQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Issued QTY',
      dataIndex: 'issuedQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'Rejected QTY',
      dataIndex: 'rejectedQuantity',
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'QTY',
      dataIndex: 'physicalQuantity',
      render: (value, rowData: ProductionInventoryDetails) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}> {value} <Divider type="vertical" />{((rowData.physicalQuantity) > 0) ? <Tooltip placement="topLeft" title={'Transfer'}><DoubleRightOutlined onClick={() => displayModel(rowData)} type='edit' style={{ color: 'red' }} /></Tooltip> : ''}</div>
      }
    },
  ];

  const displayModel = (data: ProductionInventoryDetails) => {
    setTypeModel('transfer');
    setSaleId(data.saleOrderId);
    setSelectedInformation(data);
    setIsModalVisible(true);
  };



  return (
    <>
    <Card title={<span style={{ color: 'white' }}>Stock Transfer</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <Form form={inventoryTransfer}>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"operation"}
              style={{ margin: 0 }}
              rules={[
                {
                  required: false,
                  message: `Please Select Operation`,
                },
              ]}
            >
              <Select
                placeholder="Select Operation"
                allowClear
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onSelect={getInventoryInfo}
              >
                <Option key={'operation'} value={''}>Select Operation</Option>
                <Option key={1} selected value={OperationTypeEnum.GRN}>{OperationTypeEnum.GRN}</Option>
                <Option key={2} value={OperationTypeEnum.BE_HEADING}>{OperationTypeEnum.BE_HEADING}</Option>
                <Option key={3} value={OperationTypeEnum.GRADING}>{OperationTypeEnum.GRADING}</Option>
                <Option key={4} value={OperationTypeEnum.VALUE_ADDITION}>{OperationTypeEnum.VALUE_ADDITION}</Option>
                <Option key={5} value={OperationTypeEnum.SOAKING}>{OperationTypeEnum.SOAKING}</Option>
                <Option key={6} value={OperationTypeEnum.FREEZING}>{OperationTypeEnum.FREEZING}</Option>
                <Option key={7} value={OperationTypeEnum.PACKING}>{OperationTypeEnum.PACKING}</Option>
              </Select>
            </Form.Item>

          </Col>
        </Row>
      </Form> <br />
      {inventory.length ? 
      // <Card title={<span style={{ color: 'white' }}>Inventory</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
        <Table
          columns={columnsSkelton}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{'Total'}</Table.Summary.Cell>
                {/* <Table.Summary.Cell index={3}>{'Total'}</Table.Summary.Cell> */}

                <Table.Summary.Cell index={4}>{inventory.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.boxes);
                }, 0)}</Table.Summary.Cell>

                <Table.Summary.Cell index={5}>{inventory.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.opBoxes);
                }, 0)}</Table.Summary.Cell>

                <Table.Summary.Cell index={6}>
                  {inventory.reduce(function (previousValue, currentValue) {
                    return previousValue + Number(currentValue.wipQuantity);
                  }, 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={7}>
                  {inventory.reduce(function (previousValue, currentValue) {
                    return previousValue + Number(currentValue.intransitQuantity);
                  }, 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={8}>
                  {inventory.reduce(function (previousValue, currentValue) {
                    return previousValue + Number(currentValue.wastageQuantity);
                  }, 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={9}>{inventory.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.issuedQuantity);
                }, 0)}</Table.Summary.Cell>

                <Table.Summary.Cell index={10}>{inventory.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.rejectedQuantity);
                }, 0)}</Table.Summary.Cell>

                <Table.Summary.Cell index={11}>{inventory.reduce(function (previousValue, currentValue) {
                  return previousValue + Number(currentValue.physicalQuantity);
                }, 0)}</Table.Summary.Cell>

              </Table.Summary.Row>
            </Table.Summary>
          )}
          dataSource={inventory}
          rowKey={(record) => record.productionInventoryId}
          bordered
        />
      // </Card> 
      : <>{(loading) ? <Spin tip="Loading...">
        <Alert
          message="Please Wait"
          description={"getting " + inventoryTransfer.getFieldValue('operation') + " Inventory"}
          type="info" />
      </Spin> : <Empty />}</>}
      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {typeModel == 'transfer' ? <StockTransfer operation={selectedInformation.operation} inventoryInfo={selectedInformation} handleCancel={handleCancel} /> : ''}
        {typeModel == 'detailView' ? <SoInfoViewPages saleOrderId={saleId} screen='production' /> : ''}
      </Modal>
      </Card>
    </>
  );
}

export default ProductionInventoryTransfer;
