import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";

export class FillSealInfoDto{
    saleOrderId:number;
    containerStatus:ContainerStatusEnum;
    containerId?:number;
    lineSeal: string;
    eSeal: string;
    driverName: string;
    vehicleNumber: string;
    phoneNumber: string;
    scac: string;
    constructor(saleOrderId:number, containerStatus:ContainerStatusEnum, containerId?:number){
        this.saleOrderId = saleOrderId;
        this.containerStatus = containerStatus;
        this.containerId = containerId;
    }
}