import {SubPlantCodesEnum, PlantsEnum, OperationTypeEnum, JobStatusEnum} from '@gtpl/shared-models/common-models';

export class SubPlantOperationsDto{
    plant?: PlantsEnum;
    subPlant?: SubPlantCodesEnum;
    lotNumber: string;
    quantity: number;
    wipQuantity?: number;
    soNumber?: string;
    ipBoxes: number;
    opBoxes?: number;
    operation: OperationTypeEnum;

    constructor(
        plant: PlantsEnum,
        subPlant: SubPlantCodesEnum,
        lotNumber: string,
        quantity: number,
        wipQuantity: number,
        soNumber: string,
        ipBoxes: number,
        opBoxes: number,
        operation: OperationTypeEnum,
    ){
        this.plant = plant;
        this.subPlant = subPlant;
        this.lotNumber = lotNumber;
        this.quantity = quantity;
        this.wipQuantity = wipQuantity;
        this.soNumber = soNumber;
        this.ipBoxes = ipBoxes;
        this.opBoxes = opBoxes;
        this.operation = operation;
    }
}