
export class SaleOrderDetailsResponse {
    saleOrderId: number;
    saleOrderNumber: string;
    soProcessType?: string;
    customerPO?: string;
    soDate?: Date;
    poNumber?:string;

    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     */
    constructor(saleOrderId: number, saleOrderNumber: string,soProcessType?:string, soDate?: Date, customerPO?: string){ 
            this.saleOrderId = saleOrderId
            this.saleOrderNumber = saleOrderNumber
            this.soProcessType = soProcessType
            this.soDate = soDate;
            this.customerPO = customerPO;
        }
    }
