import React, { useEffect, useState } from 'react';
import {Form, Input, Button, Select, Card, Row, Col} from 'antd';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link, useHistory} from "react-router-dom";
import {PalletService, UnitcodeService} from '@gtpl/shared-services/masters';
import {OccupancyEnum, PalletDto, PlantsDropDown} from '@gtpl/shared-models/masters';
import './pallet-form.css';
import { ColumnProps } from 'antd/lib/table';
//import {PalletService} from '@gtpl/shared-services/masters';
//import {PalletDto} from '@gptl/shared-models/masters';
const { Option } = Select;
/* eslint-disable-next-line */
export interface PalletFormProps {
  palletData: PalletDto;
  updatePallet:(pallet:PalletDto)=>void;
  isUpdate: boolean;
  closeForm:()=>void;
}

export function PalletForm(
  props:PalletFormProps
) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService()

  let user = localStorage.getItem("createdUser");
  const palletService = new PalletService();

  useEffect(() => {
    form.setFieldsValue({occupancy:OccupancyEnum.NOT_OCCUPIED});
    form.setFieldsValue({plantId:Number(localStorage.getItem("unit_id"))});
    getAllPlants();
  }, []);
  const createPallet = (palletData : PalletDto)=> {
    
    palletData.plantId=palletData.plantId;
    palletData.palletCode=palletData.palletNo;
    palletService.createPallet(palletData).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage('Pallet Created Successfully');
        history.push("/pallet");
        onReset();
      }else{
        if(res.intlCode)
        {
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = ()=>{
    form.resetFields();
  }
  
  const saveData = (values: PalletDto) => {
    if(props.isUpdate){
      props.updatePallet(values);
    }else{
      createPallet(values);
    }
  };

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        if (res.intlCode) {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setPlantData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const columnsSkeleton: ColumnProps<any>[] = [
    {
      title: 'Pallet Code',
      dataIndex: 'palletNo',
    },
    {
      title:'Associate Ref number',
      dataIndex:'string'
    }
  ];
   const onChange=(pagination, filters, sorter, extra) => {
     console.log("params", pagination, filters, sorter, extra);
   }
  return (
    <Card title={<span style={{color:'white'}}>Pallet</span>} style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0}} extra ={(!props.isUpdate)?<Link to='/pallet'><Button className='panel_button' > View </Button></Link>:''}
    >
      <Form layout="vertical" form={form} initialValues={props.palletData} name="control-hooks" onFinish={saveData}>
            <Form.Item name="palletNo" label="Pallet Code" rules={[
            {
              required: false,message:'Pallet Code  is required'
              
            },
          ]} style={{marginLeft:'2%'}} hidden>
            <Input />
            </Form.Item>
        {(!props.isUpdate)?
          <Form.Item style={{display:"none"}} name="createdUser"  initialValue={user}>
          <Input hidden/>
        </Form.Item>:
      <Form.Item style={{display:"none"}} name="updatedUser"  initialValue={user}>
      <Input hidden/>
      </Form.Item>}
        <Row>
        <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="assocRfId"
                  label="Associate Rf Id"
                  
                  rules={[
                    {
                      required: true,message:'Associate Rf Id  is required'
                      
                    },
                  ]}>
                  <Input /> 
            </Form.Item>
          </Col>
          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
              <Form.Item
                name="plantId"
                label="To Plant"
                rules={[
                  {
                    required: true, message: 'Missing Plant'
                  },
                ]} hidden>
                <Select
                  showSearch
                  placeholder="Select Plant"
                  optionFilterProp="children"

                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={undefined}>please select Plant</Option>;
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          
         

          <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item 
                  name="occupancy"
                  label="Occupancy"
                  
                  rules={[
                    {
                      required: false,message:'Occupancy  is required'
                      
                    },
                  ]} hidden>
                    <Select
                      showSearch
                      placeholder="Select Occupancy"
                      allowClear = {true}             
                    >
                     {Object.keys(OccupancyEnum).map((occupancy)=>{
                  return <Option value={OccupancyEnum[occupancy]}>{OccupancyEnum[occupancy]}</Option>
                })}
                    </Select>
                
            </Form.Item>
          </Col>
          
         
          {/* <Col xs={{span:24}} sm={{span:5, offset: 1}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{marginLeft:'1%'}} hidden>
                <Form.Item 
                    name="occupancy"
                    label="Occupancy" 
                     rules={[{ required: true,message:'Enter Occupancy' }]}>
                    <Select
                      showSearch
                      placeholder="Select Occupancy"
                      allowClear = {true}             
                    >
                     {Object.keys(OccupancyEnum).map((occupancy)=>{
                  return <Option value={OccupancyEnum[occupancy]}>{OccupancyEnum[occupancy]}</Option>
                })}
                    </Select>
                  </Form.Item>
          </Col> */}

        </Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {(props.isUpdate===false) &&
              <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
              </Button>
            }
          </Col>
      </Form>
    </Card>

  );
}

export default PalletForm;
