import { ContainerStatusEnum,BlStatusEnum } from "@gtpl/shared-models/common-models";

export class DraftReceived{
    containerNumber:string;
    saleOrderId:number;
    blStatus:BlStatusEnum;
    containerId?:number;
    etd?:Date;
    /**
     * 
     * @param containerNumber 
     * @param saleOrderId 
     * @param blStatus 
     * @param containerId 
     */
    constructor(containerNumber:string, saleOrderId:number, blStatus:BlStatusEnum, containerId?:number, etd?:Date){
        this.containerNumber = containerNumber;
        this.saleOrderId = saleOrderId;
        this.blStatus = blStatus;
        this.containerId = containerId;
        this.etd = etd;
    }
}