import { AllRecruitmentResponseModel, RecruitmentDto, RecruitmentRequest, RecruitmentResponseModel } from "@gtpl/shared-models/masters";
import axios from "axios";
import { connection } from "..";






export class RecruitmentService {
    URL = connection.DEPLOY_URL + '/recruitment';

    async createRecruitment(recruitmentDTO: RecruitmentDto): Promise<RecruitmentResponseModel> {
        return await axios
            .post(this.URL + '/createRecruitment', recruitmentDTO)
            .then((res) => {
                return res.data;
            });
    }
    async updateRecruitment(recruitmentDTO: RecruitmentDto): Promise<RecruitmentResponseModel> {
        return await axios
            .post(this.URL + '/updateRecruitment', recruitmentDTO)
            .then((res) => {
                return res.data;
            });
    }

    async getAllRecruitment(): Promise<AllRecruitmentResponseModel> {
        return await axios.post(this.URL + '/getAllRecruitment').then((res) => {
            console.log('Get Recruitment')
            console.log(res)
            return res.data;
        });

    }

    async activateOrDeactivateRecruitment( recruitmentDto: RecruitmentDto ): Promise<RecruitmentResponseModel> {
        return await axios
          .post(this.URL + '/activateOrDeactivateRecruitment', recruitmentDto)
          .then((res) => {
            return res.data;
          });
      }

      async getAllActiveRecruitment(): Promise<AllRecruitmentResponseModel> {
        return await axios.post(this.URL + '/getAllActiveRecruitment').then((res) => {
          return res.data;
        });
      }
      async getCurrencyById(recruitmentReq: RecruitmentRequest): Promise<RecruitmentResponseModel> {
        return await axios.post(this.URL + '/getCurrencyById',recruitmentReq).then((res) => {
          return res.data;
        });
      }


}