
export class grnAbstract{
    fromDate:string;
    toDate:string;
    unit_name:string;
    count25?:number;
    count30?:number;
    count40?:number;
    count50?:number;
    count60?:number;
    count70?:number;
    count80?:number;
    count90?:number;
    count100?:number;
    count110?:number;
    count120?:number;
    count130?:number;
}