export class DestinationDropDownData{
    destinationId:number;
    destinationName:string;
    finaldestinationName:string;

    /**
     * 
     * @param destinationId 
     * @param destinationName 
     * @param finaldestinationName
     */
    constructor(destinationId:number,destinationName:string,finaldestinationName:string){
      this.destinationId = destinationId;
      this.destinationName = destinationName;
      this.finaldestinationName= finaldestinationName;
    }
}