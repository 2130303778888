import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { AllPoDetailsInfo } from "./all-po-details-info.model";

export class GetAllPoResponse extends GlobalResponseObject {
    data?: AllPoDetailsInfo[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: AllPoDetailsInfo[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}