import React, { useEffect, useState } from 'react';
import { ColumnDropDownDto, LocationsDto, RackDetails, RackDropDownDto, RacksForZoneId, ZoneDropDownDto } from '@gtpl/shared-models/masters'
import './locations-form.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnService, LevelService, LocationsService, RackService, ZoneService } from '@gtpl/shared-services/masters'
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Card, Row, Col } from 'antd';
import { LevelDropDownDto } from 'libs/shared-models/masters/src/lib/level/level-drop-down-dto';

const { Option } = Select;
/* eslint-disable-next-line */
export interface LocationsFormProps {
  locationsData: LocationsDto;
  updateLocations: (locations: LocationsDto) => void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function LocationsForm(
  props: LocationsFormProps
) {

  const [form] = Form.useForm();
  let history = useHistory();

  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const [zones, setZones] = useState<ZoneDropDownDto[]>([]);
  const [rack, setRacks] = useState<RackDetails[]>([]);
  const [column, setColumns] = useState<ColumnDropDownDto[]>([]);
  const [level, setlevels] = useState<LevelDropDownDto[]>([]);
  const locationsService = new LocationsService();
  const zoneService = new ZoneService();
  const racksService = new RackService();
  const levelservice = new LevelService();
  const columnservice = new ColumnService();


  useEffect(() => {
    getAllColumns();
    getAllLevels();
    getAllZones();
    getAllRacks();
    // if(props.isUpdate){
    //   getRacksForZoneId(props.locationsData.zoneId)
    // }
  }, [])


  const getAllRacks = () => {
    racksService.getAllRacks().then(res => {
      if (res.status) {
        setRacks(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        setRacks([]);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        setRacks([]);

        }
      }
    })
  }
  const getAllZones = () => {
    zoneService.getAllZoneDropDown().then(res => {
      if (res.status) {
        setZones(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        setZones([]);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        setZones([]);

        }
      }
    })
  }
  // const getRacksForZoneId = (zoneId) => {
  //   //const zone = new ZoneDropDownDto(zoneId, zoneName.children);
  //   const zoneRequest = new RacksForZoneId(zoneId);
  //   racksService.getRacksForZoneId(zoneRequest).then((res) => {
  //     if (res.status) {
  //       setRacks(res.data);
  //     } else {
  //       setRacks([]);
  //       form.setFieldsValue({rackName:''})
  //       AlertMessages.getErrorMessage(res.internalMessage);
  //     }

  //   });
  // }


  const getAllLevels = () => {
    levelservice.getAllLevelDropDown().then(res => {
      if (res.status) {
        setlevels(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getAllColumns = () => {
    columnservice.getAllColumnDropDown().then(res => {
      if (res.status) {
        setColumns(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }




  const createLocations = (locationsDto: LocationsDto) => {
    locationsService.createLocations(locationsDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Locations Created Successfully');
        history.push("/locations");
        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    // console.log('hhhhhhhhhhhhhhhhhhhhhhh');
    form.resetFields();

  }


  const saveData = (values: LocationsDto) => {
    if (props.isUpdate) {
      console.log(values)
      props.updateLocations(values);
    } else {

      console.log(values)
      createLocations(values);
    }

  };


  return (
    <Card title={<span style={{ color: 'white' }}>Update Location</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/locations' ><Button className='panel_button' >View </Button></Link>}
    >
      <Form layout="vertical" form={form} initialValues={props.locationsData} name="control-hooks" onFinish={saveData} >
        <Form.Item name="locationsId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser}>
          <Input hidden />
        </Form.Item>
        <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="zoneId"
              label="Zone"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                placeholder="Select Zone"
                // onChange={getSaleOrderItems}
                allowClear
                // onChange={(zoneId, value) => getRacksForZoneId(zoneId)}



              >
                <Option value={''}>Please Select</Option>
                {
                  zones.map((zne) => {
                    return <Option value={zne.zoneId}>{zne.zoneName}</Option>
                  })
                }
              </Select>

            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="rackId"
              label="Rack"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select placeholder="Select Rack" allowClear >
                <Option value={''}>Please Select</Option>
                {
                  rack.map(rack => {
                    return <Option value={rack.rackId}>{rack.rackCode}</Option>
                  })}

              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="levelId"
              label="Level"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                placeholder="Select Level"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {
                  level.map(level => {
                    return <Option value={level.levelId}>{level.levelName}</Option>
                  })}

              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
            <Form.Item
              name="columnId"
              label="Column"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                placeholder="Select Column"
                // onChange={getSaleOrderItems}
                allowClear

              >
                <Option value={''}>Please Select</Option>{
                  column.map(column => {
                    return <Option value={column.columnsId}>{column.columnsNo}</Option>
                  })}




              </Select>

            </Form.Item>
          </Col>



          {/* <Col xs={{span:24}} sm={{span:24}} md={{span:5,offset:1}} lg={{span:5,offset:1}} xl={{span:5,offset:1}} style={{margin:'1%'}}>
            <Form.Item
                name="versionFlag"
                label="Version Flag"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input/>
              </Form.Item>
        </Col> */}
        </Row>

        <Col span={24} style={{ textAlign: 'right' }}>

          <Button type="primary" htmlType="submit" >
            Submit
            </Button>
          {(props.isUpdate === false) &&
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
          </Button>
          }


        </Col>
      </Form>
    </Card>
  );
}

export default LocationsForm;
