import { CodeListMainItemsDto } from "./code-list-main-items.dto"

export class CodeListMainDto {
    codeListMainId: number
    saleOrderId: number 
    totalCartons: number
    issuedBy: string
    verifiedBy: string
    createdUser: string | null
    isActive:boolean;
    codeListItems : CodeListMainItemsDto[];
    isUpdate?: boolean;
    remarks?:string;
   
    constructor(codeListMainId:number,saleOrderId:number,totalCartons:number,issuedBy:string,verifiedBy:string,createdUser:string,isActive:boolean,codeListItems:CodeListMainItemsDto[],isUpdate?:boolean,remarks?:string){
        this.codeListMainId = codeListMainId;
        this.saleOrderId = saleOrderId;
        this.totalCartons = totalCartons;
        this.issuedBy = issuedBy;
        this.verifiedBy = verifiedBy;
        this.createdUser =createdUser;
        this.isActive = isActive;
        this.codeListItems = codeListItems;
        this.isUpdate = isUpdate;
        this.remarks = remarks;
    }
}



