import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import './level-grid.css';
import { Link, Redirect } from 'react-router-dom';
import {LevelForm} from '@gtpl/pages/warehouse-masters/warehouse-master-component/level-form'
// import Link from 'antd/lib/typography/Link';
import {LevelService} from '@gtpl/shared-services/masters'
import { LevelDto } from '@gtpl/shared-models/masters';
/* eslint-disable-next-line */
export interface LevelGridProps {}

export function LevelGrid(
  props: LevelGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [levelsData, setLevelsData] = useState<LevelDto[]>([]);
  const [selectedLevelData, setSelectedLevelData] = useState<any>(undefined);
  const username = localStorage.getItem('username')
  const levelService=new LevelService();

  useEffect(() => {
    getAllLevels();
  }, []);

  /**
   * 
   */
  const getAllLevels= () => {
    levelService.getAllLevels().then(res => {
      console.log(res.data);
      if (res.status) {
        setLevelsData(res.data);
      } else {
        if (res.intlCode) {
            setLevelsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setLevelsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const deleteLevel = (levelDto:LevelDto) => {
    levelDto.isActive=levelDto.isActive?false:true;
    levelService.ActivateorDeactivateLevel(levelDto).then(res => { console.log(res);
      if (res.status) {
        getAllLevels();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const updateLevel = (levelDto: LevelDto) => {
    levelService.updateLevel(levelDto).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllLevels();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
    const openFormWithData=(viewData: LevelDto)=>{
      setDrawerVisible(true);
      setSelectedLevelData(viewData);
      console.log(selectedLevelData)
    }

  
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    
    {
      title: 'Level Code',
      dataIndex: 'levelCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.levelCode.length - b.levelCode.length || a.levelCode.localeCompare(b.levelCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('levelCode')
    },
    {
      title: 'Level Name',
      dataIndex: 'levelName',
      // responsive: ['lg'],
      sorter: (a, b) => a.levelName.length - b.levelName.length || a.levelName.localeCompare(b.levelName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('levelName')
    },
    ,
    // {
    //   title: 'Remarks',
    //   dataIndex: 'remarks',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.remarks.length - b.remarks.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('productName')
    // },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive, rowData) => (
        <>
          {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.isActive === value;
      },
      
    },
    // {
    //   title:`Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>     
    //       { username == '"ramakrishna"' ?
    //       <>
    //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
    //           onClick={() => {
    //             if (rowData.isActive) {
    //               openFormWithData(rowData);
    //             } else {
    //               AlertMessages.getErrorMessage('You Cannot Edit Deactivated Level.');
    //             }
    //           }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />
          
    //       <Divider type="vertical" />
          
    //         <Popconfirm onConfirm={e =>{deleteLevel(rowData);}}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate Level ?'
    //             :  'Are you sure to Activate Level ?'
    //         }
    //       >
    //         <Switch  size="default"
    //             className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
    //             checkedChildren={<RightSquareOutlined type="check" />}
    //             unCheckedChildren={<RightSquareOutlined type="close" />}
    //             checked={rowData.isActive}
    //           />
            
    //       </Popconfirm>
    //       </> : ''
    //       }    
    //     </span>
    //   )
    // }
  ];
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title={<span style={{color:'white'}}>Levels</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={(username == '"ramakrishna"') ? <Link to='/level-form' ><Button className='panel_button' >Create </Button></Link>:''}
    
    >
     <br></br>
     <Row gutter={40} >
      <Col>
          <Card title={'Total Levels: ' + levelsData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + levelsData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + levelsData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={levelsData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <LevelForm key={Date.now()}
                updateLevel={updateLevel}
                isUpdate={true}
                levelData={selectedLevelData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default LevelGrid;
