import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { BlStatusInfo } from './bl-status-info';

export class AllBlStatusInfo extends GlobalResponseObject{
    data?: BlStatusInfo[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data BlStatusInfo
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: BlStatusInfo[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}