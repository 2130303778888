
export class AttendanceLogRequest {
    logDate: string;
    employeeCode: string;
    punchDate: string;
    punchTime: string;
    deviceCode: string;

    /**
     * 
     * @param logDate 
     * @param employeeCode 
     * @param punchDate 
     * @param punchTime 
     * @param deviceCode 
     */
    constructor(logDate: string, employeeCode: string, punchDate: string, punchTime: string, deviceCode: string) {
        this.logDate = logDate;
        this.employeeCode = employeeCode;
        this.punchDate = punchDate;
        this.punchTime = punchTime;
        this.deviceCode = deviceCode;
    }
}