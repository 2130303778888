export class RmColumnDto{
    columnsId?: number;
    columnsCode: string;
    columnsNo: number;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag?: number;


    /**
     * 
     * @param columnsId
     * @param columnsCode
     * @param columnsNo
     * @param createdUser
     * @param isActive
     * @param versionFlag
     */

    constructor(columnsCode:string, columnsNo:number, createdUser:string, isActive?:boolean, updatedUser?: string, columnsId?:number, versionFlag?: number){
        this.columnsId = columnsId;
        this.columnsCode =columnsCode;
        this.columnsNo = columnsNo;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
    }
}