import { FgStatusEnum } from "@gtpl/shared-models/common-models";

export class ComplaintsDto {
    complaintId?: number;
    complaint: string;
    departmentId?: number;
    departmentName?: string
    complaintRaisedDate : any;
    status : FgStatusEnum
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(complaintId: number, complaint: string,departmentName: string,  complaintRaisedDate : Date, status : FgStatusEnum,createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.complaintId = complaintId;
        this.complaint = complaint;
        this.departmentName = departmentName;
        this.complaintRaisedDate = complaintRaisedDate;
        this.status = status;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}