import { CodeListInfoDto } from "./code-list-info";

export class SubJobCodeListRequest {
    codeListInfo: CodeListInfoDto[]
    operation: number;
    saleOrderItemId?: number;
    subSaleOrderItemId?: number;
    subSoId?: number;
    mainJobUnitId?: number;
    subJobUnitId?: number;
    SubSoJobCases?: number;
    createdUser?: string;
    constructor(codeListInfo: CodeListInfoDto[],operation: number,saleOrderItemId?: number,subSaleOrderItemId?: number,subSoId?: number,mainJobUnitId?: number,subJobUnitId?: number,SubSoJobCases?: number,createdUser?: string){
        this.saleOrderItemId = saleOrderItemId; 
        this.codeListInfo = codeListInfo; 
        this.operation = operation; 
        this.mainJobUnitId = mainJobUnitId; 
        this.subJobUnitId = subJobUnitId; 
        this.subSaleOrderItemId = subSaleOrderItemId; 
        this.subSoId = subSoId; 
        this.SubSoJobCases = SubSoJobCases; 
        this.createdUser = createdUser; 

    }
  
}