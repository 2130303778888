export class NotifyPartyDto {
    notifyPartyId?: number;
    notifyPartyName: string;
    isActive: boolean;
   createdUser :string ;
   createdAt:Date;
   updatedAt : Date;
   updatedUser : string;
  versionFlag : number;

}