import { StatusEnum } from "@gtpl/shared-models/common-models";
import { PoItemsCreateDetails } from "./po-item-create-details.dto";
import { PoItemsDataModel } from "./po-items-info.model";

export class PoDto {
  vendorId:string;
    saleOrderId:string;
    poNumber?:string;
    purchaseOrderDate:Date;
    transportation:number;
    expectedDeliveryDate:Date;
    deliveryAddress:string;
    paymentTerms: number;
    remarks:string;
    note:string;
    createdUser:string;
    updatedUser:string;
    poItems:PoItemsCreateDetails[];
    purchaseOrderId?:number;
    status?:StatusEnum;
    /**
     * 
     * @param vendorId 
     * @param saleOrderId 
     * @param purchaseOrderDate 
     * @param transportation 
     * @param expectedDeliveryDate 
     * @param deliveryAddress 
     * @param paymentTerms 
     * @param remarks 
     * @param note 
     * @param createdUser 
     * @param updatedUser 
     * @param poItems 
     * @param purchaseOrderId 
     */
    constructor(vendorId:string, saleOrderId:string, purchaseOrderDate:Date,
        transportation:number, expectedDeliveryDate:Date, deliveryAddress:string, paymentTerms: number, remarks:string, note:string, createdUser:string, updatedUser:string, poItems:PoItemsCreateDetails[], purchaseOrderId?:number,poNumber?:string,status?:StatusEnum){
            this.vendorId = vendorId;
            this.saleOrderId = saleOrderId;
            this.purchaseOrderDate = purchaseOrderDate;
            this.transportation = transportation;
            this.expectedDeliveryDate = expectedDeliveryDate;
            this.deliveryAddress = deliveryAddress;
            this.paymentTerms =  paymentTerms;
            this.remarks = remarks;
            this.note = note;
            this.createdUser = createdUser;
            this.updatedUser = updatedUser;
            this.poItems = poItems;
            this.purchaseOrderId = purchaseOrderId;
            this.poNumber = poNumber;
            this.status = status;
    }
}

// export const PoDtoDefault : PoDto = {
//   purchaseOrderId: 0,
//   purchaseOrderDate: new Date(),
//   vendorName: 0,
//   poNumber: '',
//   saleOrderId: '',
//   totalQuantity: 0,
//   totalDiscount: 0,
//   totalTax: 0,
//   totalCost: 0,
//   totalBasicPrice: 0,
//   paymentTerms: 0,
//   usageArea: '',
//   expectedDeliveryDate: new Date(),
//   note: '',
//   approvar: '',
//   otherTerms: '',
//   referenceNumber: 0,
//   deliveryAddress: '',
//   transportation: 0,
//   remarks: '',
// //   currencyName: '',
//   status: 'OPEN',
//   createdUser : '',
//   updatedUser : '',
//   // poCreationItemsInfo: PoItemsCreateDetails[],

// };