export class BomIdRequest{
    packingMaterialId:number;
    isActive?:boolean;
    poQty?:number;
    fromPo:boolean;
    itemId?:number;
    constructor(packingMaterialId:number, isActive?:boolean, poQty?:number, fromPo?:boolean,itemId?:number){
        this.packingMaterialId = packingMaterialId;
        this.isActive = isActive;
        this.poQty = poQty;
        this.itemId = itemId;
        this.fromPo = fromPo;
    }
}