
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class CommonResponse extends GlobalResponseObject{
    data?: any;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: any){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
