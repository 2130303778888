import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { HatcheriesDto } from './hatcheries.dto';

export class HatcheriesResponseModel extends GlobalResponseObject{
    data?: HatcheriesDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: HatcheriesDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
        }
}
