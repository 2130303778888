import axios from 'axios';
import connection from './connection';
import {QualificationsDto, AllQualificationsResponse, QualificationsResponse} from '@gtpl/shared-models/masters';

export class QualificationsService{

    URL = connection.DEPLOY_URL + '/qualifications';
  static getAllActiveQualification: any;

    async createQualification(qualificationsdto: QualificationsDto ): Promise<QualificationsResponse> {
        console.log(qualificationsdto);
       
        return await axios.post(this.URL + '/createQualification',qualificationsdto)
            .then(res => {
                return res.data
            })
    }

    async getAllQualifications(): Promise<AllQualificationsResponse> {
                
        return await axios.post(this.URL + '/getAllQualifications')
            .then(res => {
                return res.data 
            })     
    }

    async  updateQualification(Qualificationsdto: QualificationsDto): Promise<QualificationsResponse> {
        return await axios.post(this.URL + '/updateQualification', Qualificationsdto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateQualification(QualificationsDto: QualificationsDto): Promise<QualificationsResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateQualification', QualificationsDto)
                        .then(res => {
                                return res.data
                        })
                }
                async  getAllActiveQualification(): Promise<AllQualificationsResponse> {
                    return await axios.post(this.URL + '/getAllActiveQualifications')
                        .then(res => {
                            return res.data
                        })
                }

                
}