export class WorkStationsDropDown{
    workstationId:number;
    workstation:string;

    /**
     * 
     * @param workstationId 
     * @param workstation 
     */
    constructor(workstationId:number, workstation:string){
        this.workstationId = workstationId;
        this.workstation = workstation;
    }

}