
export class 
CodeListMainItemsDto {
    soItemId:number;
    code: string;
    tracebilityCodeId: number;
    cartons: number;
    productionDate: Date;
    bestBeforeDate: Date;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    unitId: number;
    codeListMainItemsId?: number;
    codeListMainId?:number;
    constructor(
        soItemId:number,
        code: string,
        tracebilityCodeId: number,
        cartons: number,
        productionDate: Date,
        bestBeforeDate: Date,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number,
        unitId:number,
        codeListMainItemsId?: number,
        codeListMainId?:number
    ){
        this.soItemId = soItemId;
        this.code =code;
        this.tracebilityCodeId=tracebilityCodeId;
        this.cartons=cartons;
        this.productionDate=productionDate;
        this.bestBeforeDate=bestBeforeDate;
        this.createdAt=createdAt;
        this.createdUser=createdUser;
        this.updatedAt=updatedAt;
        this.updatedUser=updatedUser;
        this.versionFlag=versionFlag;
        this.codeListMainItemsId=codeListMainItemsId;
        this.unitId = unitId;
        this.codeListMainId = codeListMainId
    }
   

  
}