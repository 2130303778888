export class AttendanceModel {
    employeeId?: number;
    logDate?: Date;
    rfCode?: string;
    date: string;
    employeeCode: string;
    employeeName: string;
    inTime: Date;
    outTime: Date;
    contractorName?: string;
    deviceCode?: string;
    downloadDate?: string;
    presentStatus?: string;
    shift?: string;
    verifyMode?: string;
    departmentId?: number;
    departmentCode?: string;
    designation?: string;
    shiftGroup?: string;
    unitId?: any;
    reportingManager?: string;
    shiftInTime?: Date;
    shiftOutTime?: Date;
    workingHours?: any;
    reason?: string;

    /**
     * 
     * @param employeeId 
     * @param employeeCode 
     * @param employeeName 
     * @param date 
     * @param inTime 
     * @param outTime 
     * @param logDate 
     * @param rfCode 
     * @param deviceCode 
     * @param downloadDate 
     * @param presentStatus 
     * @param shift 
     * @param verifyMode 
     * @param departmentId 
     * @param departmentCode 
     * @param designation 
     * @param shiftGroup 
     * @param unitId 
     * @param reportingManager 
     * @param shiftInTime 
     * @param shiftOutTime 
     * @param contractorName 
     * @param workingHours 
     * @param reason 
     */
    constructor(employeeId: number, employeeCode: string, employeeName: string, date: string, inTime: Date, outTime: Date, logDate?: Date, rfCode?: string, deviceCode?: string, downloadDate?: string, presentStatus?: string, shift?: string, verifyMode?: string, departmentId?: number, departmentCode?: string, designation?: string, shiftGroup?: string, unitId?: any, reportingManager?: string, shiftInTime?: Date, shiftOutTime?: Date, contractorName?: string, workingHours?: any, reason?: string
    ) {
        this.employeeId = employeeId;
        this.logDate = logDate;
        this.rfCode = rfCode;
        this.date = date;
        this.employeeCode = employeeCode;
        this.contractorName = contractorName;
        this.deviceCode = deviceCode;
        this.downloadDate = downloadDate;
        this.presentStatus = presentStatus;
        this.inTime = inTime;
        this.outTime = outTime;
        this.shift = shift;
        this.verifyMode = verifyMode;
        this.departmentId = departmentId;
        this.departmentCode = departmentCode;
        this.designation = designation;
        this.shiftGroup = shiftGroup;
        this.employeeName = employeeName;
        this.unitId = unitId;
        this.reportingManager = reportingManager;
        this.shiftInTime = shiftInTime;
        this.shiftOutTime = shiftOutTime;
        this.workingHours = workingHours;
        this.reason = reason;
    }
}