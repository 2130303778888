import { QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";
import { pdQaQCsamplesDto } from "./pd-qaqc-samples.dto";
export class PdQaqcDto{
  peelingId:number;
  peelingDate:Date;
  saleOrderId:number;
  saleOrderItemId:number;
  lotNumber:string
  shift:ShiftsEnum;
  isActive: boolean;
  createdAt: Date;
  createdUser: string;
  updatedAt: Date;
   updatedUser: string;
  versionFlag: number;
  peelingSamplesInfo: pdQaQCsamplesDto[];
  soNumber?: string;
  item?:string;
  constructor(  peelingId:number,
    peelingDate:Date,
    saleOrderId:number,
    saleOrderItemId:number,
    lotNumber:string,
    shift:ShiftsEnum,
    isActive: boolean,
    createdAt: Date,
    createdUser: string,
    updatedAt: Date,
     updatedUser: string,
    versionFlag: number,
    peelingSamplesInfo: pdQaQCsamplesDto[],soNumber?: string,item?:string,){
      this.peelingId=peelingId
    this.peelingDate = peelingDate;
    this.saleOrderId = saleOrderId;
    this.saleOrderItemId = saleOrderItemId;
    this.lotNumber = lotNumber
    this.shift = shift;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.createdUser = createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
    this.peelingSamplesInfo = peelingSamplesInfo
    this.soNumber = soNumber
    this.item = item
  }

}