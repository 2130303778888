export class RmIndentReportDto {
    indentCode: string;
    productId: number;
    productCode: string;
    indentDate: Date;
    harvestingDate: Date;
    grnDate: Date;
    grnNumber: string;
    grnStatus: boolean;
    quantity: number;
    price: number;
    count: number;
    expectedCount: number;
    expectedQty: number;
    supplierType: string;

    /**
     * 
     * @param indentId 
     * @param indentCode 
     * @param productCode 
     * @param indentDate 
     * @param harvestingDate 
     * @param grnDate 
     * @param grnNumber 
     * @param grnStatus 
     * @param quantity 
     * @param price 
     * @param count 
     */
    constructor(indentCode: string, productCode: string, indentDate: Date, harvestingDate: Date, grnDate: Date, grnNumber: string, grnStatus: boolean, quantity: number, price: number, count: number,supplierType:string){
        this.indentCode = indentCode;
        this.productCode = productCode;
        this.indentDate = indentDate;
        this.harvestingDate = harvestingDate;
        this.grnDate = grnDate;
        this.grnNumber = grnNumber;
        this.grnStatus = grnStatus;
        this.quantity = quantity;
        this.price = price;
        this.count = count;
        this.supplierType = supplierType;
    }

}