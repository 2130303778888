import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StockInResDto } from './stock-in.dto';

export class StockInResponseData extends GlobalResponseObject {
    data?: StockInResDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: StockInResDto){
        super(status,errorCode,internalMessage);
        // this.status = status;
        // this.statusCode = statusCode;
        // this.internalMessage = internalMessage;
        // this.errorCode = errorCode;
        this.data = data;
    }
}