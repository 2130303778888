export class HolidaysCalendarDto {
    holidayId?: number;
    holidayName: string;
    holidayDate:any;
    //holidayYear:any;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(holidayId: number,
         holidayName: string,
        holidayDate:any,
        holidayYear:Date,
        createdUser: string,
        isActive: boolean,
        updatedUser?: string,
        versionFlag?: number){
        this.holidayId = holidayId;
        this.holidayName = holidayName;
        this.holidayDate=holidayDate;
        //this.holidayYear=holidayYear
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}