import React, { useEffect, useState } from 'react';

import { Divider, Table, Select, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Form, DatePicker, Modal, Space, Badge, InputNumber } from 'antd';
import { SaleOrderDropDownDto, SaleOrderItemsDTO, ItemsDetails, SODto } from '@gtpl/shared-models/sale-management';
import { QrcodeOutlined } from '@ant-design/icons';
import { ItemCodeRequest, ItemRequest, ItemsDropDownDto, ItemsDropDownResponseModel, ItemsDto } from '@gtpl/shared-models/masters'
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
const { Option } = Select;
import { packingIndentEnum, StockTypeDropDown, stockTypeEnum, TransactionType, TransactionTypeDropDown, UnitRequest } from '@gtpl/shared-models/common-models';
import { ItemsService } from '@gtpl/shared-services/masters';
import { Link, Redirect,useHistory, useLocation } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { PackingIndentService, StockService, StoreConsumptionService } from '../../../../../../shared-services/procurement/src';
import { BarcodeRequest, IndentFormDTO, IndentNumberRequest, indentrequest, ItemBarcodeRequest, MaterialPreperationRequest, SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import { CodeListMainIdRequest } from '@gtpl/shared-models/planning';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
import { CodeListMainService } from '@gtpl/shared-services/planning';
import moment from 'moment';
import { ConstraintMetadata } from 'class-validator/types/metadata/ConstraintMetadata';

export class indentDetsils{
  id: number; // po id or so id
  type: string; // type po or so 
  indentNumber: string;
  // soIds: number[];
  saleOrderItemId:number;
}


export function StoreIssuesForm(
) {
  let location = useLocation();
  const indentStateData: indentDetsils | any = location.state;
    let history = useHistory();
    const [form] = Form.useForm();
    let stockTypes = StockTypeDropDown;
    let transactionTypeDropDown = TransactionTypeDropDown;
    const storeConsumptionService = new StoreConsumptionService();
    const codeListMainService = new CodeListMainService();
    const stockService = new StockService();
    const itemService = new ItemsService();
    const saleOrderService = new SaleOrderService();
    const packingIndentService = new PackingIndentService();
    const [itemDisable, setItemDisable] = useState<boolean>(false);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [isItemHidden, setIsItemHidden] = useState<boolean>(false);
    const [generalItemsRequiredFlag, setGeneralItemsRequiredFlag] = useState<boolean>(true);
    const [isPreparedMaterial, setIsPreparedMaterial] = useState<string>('')
    const [issueType, setIssueType] = useState<string>('')
    const [productVal, setProductVal] = useState<string>('')
    const [selectedValue, setSelectedValue] = useState<boolean>(false)
    const [existingConsumedQty, setExistingConsumedQty] = useState<number>(0)
    const [existingStock, setExistingStock] = useState<number>(0)
    const [existingPreparedQty, setExistingPreparedQty] = useState<number>(0)
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [modal, setModal] = useState('')
    const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([])
    const [productsData, setProductsData] = useState<any[]>([])
    const [indentsData, setIndentsData] = useState<any[]>([])
    const [itemsData, setItemsData] = useState<ItemsDropDownDto[]>([])
    const [itemDetails, setItemDetails] = useState<ItemsDto>(undefined)
    const [showQrSacn, setShowQrScan] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [dummyItemsdata, setDummyItemsdata] = useState<ItemsDropDownDto[]>([]);
    const [saleOrderItemsData, setSaleOrderItemsData] = useState<ItemsDetails[]>([])
    const [storeDetailsId, setSroreDetailsId] = useState();
   const [disableUpdate, setDisableUpdate] = useState<boolean>(false);
   const[indentDisable,setIndentDisable]=useState<boolean>(false);
  const [indentDetails, setIndentDetails] = useState();
  const service = new PackingIndentService;
   
    useEffect(()=>{
      console.log(form.getFieldValue('saleOrderId'))
      if(form.getFieldValue('saleOrderId') > 0){
        console.log(form.getFieldValue('saleOrderId'))
        getSaleOrderItems(form.getFieldValue('saleOrderId'))
      }
    },[form.getFieldValue('saleOrderId')])



    useEffect(() => {
      // onIsPreparedMaterialSelect('YES')
      getSaleOrderData()
    getCodeListsForId();
    getItemsData();
    getIndents()
    getPreparedProducts();
    if(indentStateData){
      console.log(indentStateData,'uiiiii')
      if(indentStateData?.storeConsumptionId){
        indentStateData.consumedDate=indentStateData.consumedDate?moment(indentStateData.consumedDate, 'YYYY-MM-DD'):moment();
        getSaleOrderItems(indentStateData.saleOrderId)
        form.setFieldsValue({saleOrderId:indentStateData.saleOrderId})
        form.setFieldsValue({indentNo:indentStateData.indentNo})
        form.setFieldsValue({saleOrderItemId:indentStateData.saleOrderItemId})
        form.setFieldsValue({stockType:indentStateData.stockType})
        form.setFieldsValue({itemId:indentStateData.itemId})
        form.setFieldsValue({indentQuantity:Number(indentStateData.issuedQuantity)})
        form.setFieldsValue({issuedQuantity:Number(indentStateData.issuedQuantity)})
        form.setFieldsValue({issueType:"INDENT"})
        if(indentStateData.stockType == stockTypeEnum.Dummy){
          setItemDisable(false)
        }
        // form.setFieldsValue({indentQuantity:Number(indentStateData.issuedQuantity)})
        // form.setFieldsValue({indentQuantity:Number(indentStateData.issuedQuantity)})


      }else{
        setIndentDetails(indentStateData.id);
        form.setFieldsValue({issueType:"INDENT"})
        setIndentDisable(true);
        form.setFieldsValue({indentNo:indentStateData.indentNumber})
        getIndentData(indentStateData.indentNumber);
        form.setFieldsValue(indentStateData.saleOrderItemId)      

      }
      // form.setFieldsValue(indentsData)

      // setDisableUpdate(true);
    }
    }, [])

    const getIndents = () => {
      console.log('yes')
      service.getIndentDropDownData(new PlantIdRequest(JSON.parse(localStorage.getItem('unit_id')))).then((res) => {
        if (res.status) {
            console.log(res.data);
            setIndentsData(res.data);
        } else {
            // if (res.intlCode) {
            //     AlertMessages.getErrorMessage(res.internalMessage);
            // } else {
            //     AlertMessages.getErrorMessage(res.internalMessage);
            // }
            setIndentsData([]);
        }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setIndentsData([]);
    });
    }

    const getPreparedProducts = () => {
        stockService.getPreparedProductsData(new UnitRequest(JSON.parse(localStorage.getItem('unit_id')))).then((res) => {
            if (res.status) {
                console.log(res.data);
                setProductsData(res.data);
            } else {
                // if (res.intlCode) {
                //     AlertMessages.getErrorMessage(res.internalMessage);
                // } else {
                //     AlertMessages.getErrorMessage(res.internalMessage);
                // }
                setProductsData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setProductsData([]);
        });
    }
    
    const handleQrScan = (value) => {
        setShowQrScan(false)
        form.setFieldsValue({
          barcode: value
        })
        // getPackingMaterialInfo(value);
    
      }

      const getItemsData = () => {
        itemService.getItemsDropDownData().then((res) => {
            if (res.status) {
                console.log(res.data);
                setDummyItemsdata(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setDummyItemsdata([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setDummyItemsdata([]);
        });
      }
    const getSaleOrderData = () => {
        const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
        stockService.getAvailableStockSosForConsumption(unitIdReq).then((res) => {
            if (res.status) {
                console.log(res.data);
                setSaleOrderData(res.data);
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderData([]);
        });
    }

    const getCodeListsForId = () => {
        const req = new CodeListMainIdRequest();
        const saleOrderId = form.getFieldValue('saleOrderId')
        req.saleOrderId = saleOrderId
        codeListMainService.geTracebilityCodeForSoId(req).then(res => {
          if (res.status) {
            console.log(res.data)
            setDataSource(res.data);
          } else {
            if (res.intlCode) {
            //   AlertMessages.getErrorMessage(res.internalMessage);
              setDataSource([])
            } else {
            //   AlertMessages.getErrorMessage(res.internalMessage);
              setDataSource([])
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }

    const getBarcodeData = (e) => {
        console.log(e)
        // console.log(e.target.value)
        const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unitId')), JSON.parse(localStorage.getItem('role')));
        stockService.getPreparedStockByBarcode(new BarcodeRequest(e, JSON.parse(localStorage.getItem('unit_id')),form.getFieldValue('itemId')),).then(res => {
            if (res.status) {
                form.setFieldsValue({ itemName: res.data[0].itemName })
                form.setFieldsValue({ itemId: res.data[0].itemId })
                form.setFieldsValue({ saleOrderId: res.data[0].saleOrderId })
                form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId })
                form.setFieldsValue({ poNumber: res.data[0].poNumber })
                // form.setFieldsValue({ preparedQuantity: res.data[0].preparedQty })
                // form.setFieldsValue({ stockType: stockTypeEnum.SaleOrder })
                getSaleOrderItems(res.data[0].saleOrderId)
                setExistingConsumedQty(Number(res.data[0].consumedQty))
                setExistingPreparedQty(Number(res.data[0].preparedQty))
                setProductVal(res.data[0].productName)
                form.setFieldsValue({productName:res.data[0].productName})
                // setIsHidden(true)
                // form.setFieldsValue({itemId:res.data.itemId})
                // getSaleOrderData(res.data.itemId)
                // checkStock(res.data.itemId)
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })

    }

    const handleSaleOrder = (value, rowObj) => {
        form.setFieldsValue({ poNumber: rowObj.poNumber })
        setDummyItemsdata([])
        setSaleOrderItemsData([])
        console.log(rowObj)
        console.log(isPreparedMaterial)
        console.log(form.getFieldValue('stockType'))

        if((isPreparedMaterial == 'NO') && (form.getFieldValue('stockType') == stockTypeEnum.SaleOrder) && (issueType != 'DIRECT')){
            getNotPreparedItems(value);
            console.log('soooo')

        }
        if((isPreparedMaterial == 'NO') && (form.getFieldValue('stockType') == stockTypeEnum.Dummy || form.getFieldValue('stockType') == 'superDummy' || form.getFieldValue('stockType') == stockTypeEnum.Anticipated)){
            console.log('innnn')
            getDummyItemsData();
        }
        if(issueType == 'DIRECT'){
          getDummyItemsData();
        }
        // if(form.getFieldValue('stockType') == ''))
        getSaleOrderItems(value); // items dropdown
    }

    const getSaleOrderItems = (reqData) => {
        console.log("item details service");
        setSaleOrderItemsData([]);
        saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
            if (res.status) {
                // console.log(res.data.length);
                setSaleOrderItemsData(res.data);
                if (res.data.length === 1) {
                    form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId });
                    form.setFieldsValue({ soItemName: res.data[0].varient });
                }
                else {
                    if(form.getFieldValue('issueType') == 'DIRECT'){
                      form.setFieldsValue({ saleOrderItemId: null });
                    }
                }
                // getStockQuantity(reqData)
            } else {
                if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
                setSaleOrderItemsData([]);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSaleOrderItemsData([]);
        })
    }

    const saveData = (values) => {
        // setBtnDisable(true)
        form.validateFields().then(res => {
            console.log(values)
            console.log(existingPreparedQty)
            console.log(existingConsumedQty)
            values.isPreparedMaterial = isPreparedMaterial
            if(values.stockType == 'generalItems'){
                values.createdUser = localStorage.getItem('createdUser')
                values.unitId = JSON.parse(localStorage.getItem('unit_id'))
                values.barcode = ''
                values.saleOrderId = 0
                console.log(values)
                stockService.getPhysicalQuantityForItemId(values).then((res) => {
                  if (res.status) {
                      if(Number(values.issuedQuantity) > Number(res.data[0].physicalQuantity)){
                          AlertMessages.getErrorMessage('should not exceed Available Stock Quantity');
                          setBtnDisable(false);
                      }else{
                        storeConsumptionService.createStoreIssues(values).then((res) => {
                          if (res.status) {
                            console.log(res.data);
                            AlertMessages.getSuccessMessage(res.internalMessage);
                            history.push('/store-consumption-grid')
                            // setBtnDisable(false);
                            // form.resetFields()
                            // updateissueddQuantity()
                          } else {
                            if (res.intlCode) {
                              AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
                    
                            } else {
                              AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
                    
                            }
                            setSaleOrderData([]);
                          }
                        }).catch((err) => {
                          AlertMessages.getErrorMessage(err.message);
                          setSaleOrderData([]);
                        });
                      }
                  } else {
                    if (res.intlCode) {
                      console.log('yesss')
                      setBtnDisable(false);
                      AlertMessages.getErrorMessage(res.internalMessage);
                    } else {
                      AlertMessages.getErrorMessage(res.internalMessage);
                      setBtnDisable(false);
            
                    }
                    setSaleOrderData([]);
                  }
                })
                
            }else{

              if(isPreparedMaterial == 'YES' && (Number(existingPreparedQty-existingConsumedQty) == 0)){
                console.log('first')
                setBtnDisable(false);
                  AlertMessages.getErrorMessage('No Available Prepared Quantity');
              }
              else if(isPreparedMaterial == 'YES' && (Number(values.issuedQuantity) > Number(existingPreparedQty-existingConsumedQty))){
                console.log('second')
  
                AlertMessages.getErrorMessage('should not exceed Available Prepared Quantity');
                setBtnDisable(false);
              }
              else{
                console.log('third')
  
                  if(values.isPreparedMaterial == 'NO'){
                console.log('four')
                
  
                      values.barcode = ''
                      stockService.getPhysicalQuantityForItemId(values).then((res) => {
                          if (res.status) {
                              if(Number(values.issuedQuantity) > Number(res.data[0].physicalQuantity)){
                                  AlertMessages.getErrorMessage('should not exceed Available Stock Quantity');
                                  setBtnDisable(false);
                              }
                          } else {
                            if (res.intlCode) {
                              console.log('yesss')
                              setBtnDisable(false);
                              AlertMessages.getErrorMessage(res.internalMessage);
                            } else {
                              AlertMessages.getErrorMessage(res.internalMessage);
                              setBtnDisable(false);
                    
                            }
                            setSaleOrderData([]);
                          }
                        })
                      
                  }else{
  
                    values.createdUser = localStorage.getItem('createdUser')
                values.unitId = JSON.parse(localStorage.getItem('unit_id'))
                console.log(values)
                storeConsumptionService.createStoreIssues(values).then((res) => {
                  if (res.status) {
                    console.log(res.data);
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    history.push('/store-consumption-grid')
                    // setBtnDisable(false);
                    // form.resetFields()
                    updateissueddQuantity()
                  } else {
                    if (res.intlCode) {
                      AlertMessages.getErrorMessage(res.internalMessage);
                      setBtnDisable(false);
            
                    } else {
                      AlertMessages.getErrorMessage(res.internalMessage);
                      setBtnDisable(false);
            
                    }
                    setSaleOrderData([]);
                  }
                }).catch((err) => {
                  AlertMessages.getErrorMessage(err.message);
                  setSaleOrderData([]);
                });
                  }
              }
            }
        })

    }
    const onIsPreparedMaterialSelect = (value) => {
      if(value == 'NO'){
        setSaleOrderData([])
        // setIsHidden(false)
        }else{
            getSaleOrderData()
            // setIsHidden(true)
        }
        form.resetFields()
        form.setFieldsValue({ isPreparedMaterial: value })
        setIsPreparedMaterial(value)
        setSelectedValue(true)
    }

    const validateSleorders = (value) => {
      form.setFieldsValue({TransactionType:null})
      form.setFieldsValue({saleOrderId:null})
      form.setFieldsValue({saleOrderItemId:null})
      form.setFieldsValue({itemId:null})
      form.setFieldsValue({itemBarcode:''})
      if(value != 'generalItems'){
        setItemDisable(false)
        if(form.getFieldValue('stockType') && form.getFieldValue('transactionType')){
            const transactionType = form.getFieldValue('transactionType')
            if(value == stockTypeEnum.Dummy || value == stockTypeEnum.Anticipated){
              alert();
                getDummySaleOrdersData(localStorage.getItem("unit_id"));
                // getDummyItemsData();
            }else{
                getSaleOrderData()
            }
        }
      }else{
        setGeneralItemsRequiredFlag(false)
        // form.setFieldsValue({saleOrderId:0,locationId:0})
        // getGeneralItems()
      }
    }

    // const getGeneralItems = () => {
    //   const unitId = Number(localStorage.getItem('unit_id'))

    //     stockService.getGeneralItems(new SaleOrderIdRequest(null,unitId)).then((res) => {
    //         if (res.status) {
    //           // console.log(res.data);
    //           setDummyItemsdata(res.data);
    //         } else {
    //           if (res.intlCode) {
    //             AlertMessages.getErrorMessage(res.internalMessage);
    //           } else {
    //             AlertMessages.getErrorMessage(res.internalMessage);
    //           }
    //           setDummyItemsdata([]);
    //         }
    //       }).catch((err) => {
    //         AlertMessages.getErrorMessage(err.message);
    //         setDummyItemsdata([]);
    //       });
    // }

    const getNotPreparedItems = (saleId) => {
        const unitId = Number(localStorage.getItem('unit_id'))

        stockService.getDummyItems(new SaleOrderIdRequest(saleId,unitId)).then((res) => {
            if (res.status) {
              // console.log(res.data);
              setDummyItemsdata(res.data);
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
              setDummyItemsdata([]);
            }
          }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setDummyItemsdata([]);
          });

    }
    const getDummySaleOrdersData = (value) => {
        saleOrderService.getDummySaleOrders({ plantId: value }).then((res) => {
          if (res.status) {
            // console.log(res.data);
            setSaleOrderData(res.data);
                // getDummyItemsData();
                // getDummyItemsData()
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setSaleOrderData([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSaleOrderData([]);
        });
      }

      const getIndentData = (val) => {
        packingIndentService.getIndentDataByIndentNumber(new IndentNumberRequest(val)).then((res) => {
          if(res.status){

            if(form.getFieldValue('issueType') == 'INDENT' && (res.data.stockType == 'dummy' || res.data.stockType == 'Super Dummy')){
              console.log('ifffyesss')

              setIsHidden(false)
                setItemDisable(false)
                setIsPreparedMaterial('NO')
              getDummySaleOrdersData(localStorage.getItem("unit_id"));
              getSaleOrderItems(res.data.saleOrderId)
              form.setFieldsValue({indentQuantity:Number(res.data.quantity)})
              form.setFieldsValue({barcode:res.data.barcode})
              form.setFieldsValue({stockType:res.data.stockType})
              form.setFieldsValue({saleOrderId:res.data.saleOrderId})
              form.setFieldsValue({ saleOrderItemId: res.data.saleOrderItemId })
              form.setFieldsValue({ itemId: res.data.itemId })
            }else{
              console.log('elseddddd')
              
              if(res.data.stockType == 'dummy' || res.data.stockType == 'Super Dummy'){
                setIsHidden(false)
                setItemDisable(false)
                setIsPreparedMaterial('NO')
              }else{
                setIsHidden(false)
                setItemDisable(true)
                setIsPreparedMaterial('YES')
              }
              console.log(res.data)
              form.setFieldsValue({indentQuantity:Number(res.data.quantity)})
              form.setFieldsValue({barcode:res.data.barcode})
              form.setFieldsValue({stockType:res.data.stockType})
              form.setFieldsValue({saleOrderId:res.data.saleOrderId})
              getSaleOrderItems(res.data.saleOrderId)
              form.setFieldsValue({ saleOrderItemId: res.data.saleOrderItemId })
              form.setFieldsValue({ itemId: res.data.itemId })
              // if(res.data.stockType == 'SaleOrder'){
                getBarcodeData(res.data.barcode)
              // }
            }

          }else{
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
      }

      const getDummyItemsData = () => {
        setDummyItemsdata([])
        const unitId = Number(localStorage.getItem('unit_id'))
        const req = new UnitRequest(unitId)
    
        saleOrderService.getDummyItemsForStoreConsumption(req).then((res) => {
          console.log(res.data);
          if (res.status) {
            setDummyItemsdata(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setDummyItemsdata([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setDummyItemsdata([]);
        });
      }

      const onitemchange = (value,rowObj) => {
        console.log(rowObj)
        form.setFieldsValue({itemName:rowObj.itemName})
      }
      const getItem = (e) => {
        if(e.target.value != ''){
        form.setFieldsValue({itemId:null})
        //   setItemDisable(true)
          console.log(e)
          console.log(e.target.value)
          const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unitId')), JSON.parse(localStorage.getItem('role')));
          itemService.getTotalItemDataByCode(new ItemCodeRequest(e.target.value,JSON.parse(localStorage.getItem('unit_id')))).then(res => {
            if (res.status) {
                const itemExist = dummyItemsdata.filter(item => item.itemId == res.data.itemId)
                console.log(itemExist)
                if(itemExist.length > 0){
                    form.setFieldsValue({itemId:res.data.itemId})
                    form.setFieldsValue({itemName:res.data.itemName})
                    setItemDisable(true)

                }else{
                    AlertMessages.getErrorMessage('No barcode exist');
                    setItemDisable(false)
                    // setDummyItemsdata([])
                }
              // form.setFieldsValue({itemId:res.data.itemId})
            //   getSaleOrderData(res.data.itemId)
              // checkStock(res.data.itemId)
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
                setItemDisable(false)
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setItemDisable(false)
              }
              setItemDetails(undefined);
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setItemDetails(undefined);
          })
        }else{
          setItemDisable(false)
        //   form.resetFields()
        //   setSaleOrderData([])
        }
    
      }

      const setbarcode = (value,rowObj) => {
        console.log(rowObj)
        getBarcodeData(rowObj.barcode)
        // setBarcode(rowObj.)
      }
      const getIssueType = (val) => {
        form.resetFields()
        form.setFieldsValue({issueType:val})
        setIssueType(val)
        if(val == 'INDENT'){
          // setIsHidden(true)
        }else{
          form.setFieldsValue({saleOrderId:null})
          form.setFieldsValue({saleOrderItemId:null})
          form.setFieldsValue({itemId:null})
          setIsPreparedMaterial('NO')
          form.setFieldsValue({isPreparedMaterial:'NO'})
          // setIsHidden(false)
        }
      }


      const updateissueddQuantity = () => {
        const req = new indentrequest(form.getFieldValue('indentNo'), packingIndentEnum.ISSUESD);
        req.issuedQuantity = form.getFieldValue('issuedQuantity');
        console.log(req,'req');
          service.updateIssuedQuantity(req).then(res => { 
            console.log(res);
            if (res.status) {
              AlertMessages.getSuccessMessage('Updated Successfully');
            } else {
              if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage);
              }
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
          })
        }
        
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Store Issues</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/material-preperation-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
                <Form layout="vertical" form={form}  name="control-hooks" onFinish={saveData}>
                    <Form.Item name="itemName" style={{ display: "none" }} >
                        <Input hidden />
                    </Form.Item>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden>
                        <Form.Item name="consumedDate" label="Consumed Date" rules={[{ required: true }]} initialValue={moment()}>
                        <DatePicker onChange={() => { }} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Form.Item name="poNumber" style={{ display: "none" }} >
                        <Input hidden />
                    </Form.Item>
                    <Form.Item name="soItemName" style={{ display: "none" }} >
                        <Input hidden />
                    </Form.Item>
                    <Form.Item name="createdUser" style={{ display: "none" }} >
                        <Input hidden />
                    </Form.Item>
                    <Form.Item name="stockId" style={{ display: "none" }} >
                        <Input hidden />
                    </Form.Item>
                    <Row gutter={24}>
                        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}> */}
                            <Form.Item hidden name="isPreparedMaterial" label="Is Prepared Material" rules={[{ required: false, message: "input is required" }]}>
                                <Select
                                    defaultValue={'YES'}
                                    placeholder="Select Value"
                                    allowClear
                                    onChange={onIsPreparedMaterialSelect}
                                >
                                    <Option value="YES">YES</Option>
                                    <Option value="NO">NO</Option>

                                </Select>
                            </Form.Item>
                        {/* </Col> */}

                        {/* {(selectedValue && isPreparedMaterial == 'YES') ? */}
                            <>
                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
                name="barcode"
                label="Scan/Enter Barcode"
                rules={[
                  {
                    required:true,
                    message: 'barcode must be required'
                  },
                
                ]}
              >

                <Input 
                    onChange={(e) => getBarcodeData(e)}
                 suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'3px',marginRight:'-6px'}}/>}/>
            </Form.Item>
            </Col> */}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                                    <Form.Item
                                        name="issueType"
                                        label="Issue Type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Barcode Required',
                                            },
                                        ]}>
                                        <Select
                                          placeholder="Issue type"
                                          allowClear
                                          onChange={getIssueType}
                                          disabled={indentStateData}
                                        >
                                          <Option  value="INDENT">INDENT</Option>
                                          <Option  value="DIRECT">DIRECT</Option>
                                        
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {issueType == 'INDENT' || indentStateData? 
                                <><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                                    <Form.Item
                                        name="indentNo"
                                        label="Indent Number"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Barcode Required',
                                            },
                                        ]}>
                                          <Select
                                            placeholder="Select Product"
                                            allowClear
                                            showSearch
                                            onChange={getIndentData}
                                            disabled = {indentStateData}
                                            filterOption={(input, option) =>
                                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                          >
                                            {indentsData.map(indData => {
                                              return <Option  value={indData.indentNumber}>{indData.indentNumber}</Option>
                                            })}
                                          </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} > */}
            <Form.Item
              name="productName"
              label="product"
              hidden
              rules={[
                {
                  required: false,
                  message: 'Product Required',
                },
              ]}>
              <Select
                      placeholder="Select Product"
                      allowClear
                      showSearch
                      onChange={setbarcode}
                      disabled = {itemDisable}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={null}>select SO Item</Option>
                      {productsData.map(productDropDown => {
                        return <Option barcode = {productDropDown.barcode} value={productDropDown.productName}>{productDropDown.productName}</Option>
                      })}
                    </Select>
            </Form.Item>
        {/* </Col> */}
        </>:<></>
                                }
                            </>
                            {/* :
                            <></>
                            } */}
                        {/* {selectedValue ? */}
                            <>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span:4 }} lg={{ span:4 }} xl={{ span:4 }}>
                  <Form.Item
                    name={"tracebilityCodeId"}
                    label="Tracebility Code"
                    rules={[
                      {
                        required: false,

                      },
                    ]}
                  >
                   <Select
                  showSearch
                  placeholder="Select Tracebility code"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Tracebility Code</Option>
                  {dataSource?.map((item) => {
                    return <Option key={item.tracebilityCodeId} value={item.tracebilityCodeId}>{item.tracebilityCode}</Option>
                  })}
                </Select>
                  </Form.Item>
                </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="indentDate" label="Indent Date" rules={[{ required: true }]} initialValue={moment()}>
              <DatePicker onChange={() => { }} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} > */}
            <Form.Item hidden name="barcode" label="Product Barcode" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          {/* </Col> */}
          {issueType == 'INDENT' || indentStateData ?
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="indentQuantity" label="Indent Quantity" rules={[{ required: false }]}>
              <Input disabled/>
            </Form.Item>
          </Col>:<></>}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="stockType"
                                    label="Stock Type"
                                    rules={[
                                        {
                                            required: true, message: 'Missing Stock Type',
                                        },
                                    ]}

                                >
                                    <Select
                                        placeholder="Select Stock Type"
                                          onChange={validateSleorders}
                                        allowClear
                                        disabled={(issueType == 'INDENT') || indentStateData ? true : false}
                                    //   onClear={clearDependentFields}
                                    >
                                        {/* <Option value="0">Please select StockType</Option> */}
                                        <Option value={undefined}>Please select StockType</Option>
                                        <Option value={'generalItems'}>Genaral Items</Option>
                                        {stockTypes.map(stockType => {
                                            return <Option value={stockType.value}>{stockType.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="transactionType"
                                        label="Transaction Type"
                                        initialValue={TransactionType.rm}
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'Missing Transaction Type',
                                        //     },
                                        // ]}

                                    >
                                        <Select
                                            // placeholder="Select Transaction Type"
                                            allowClear
                                            onChange={validateSleorders}
                                        //   onClear={clearDependentFields}
                                        >
                                            {transactionTypeDropDown.map(transactionType => {
                                                return <Option value={transactionType.value}>{transactionType.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                                    <Form.Item hidden={isHidden} name="saleOrderId" label="SO Number" rules={[{ required: generalItemsRequiredFlag, message: "SO Number is required" }]}>
                                        <Select
                                            showSearch
                                            onChange={handleSaleOrder}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Select SO Number"
                                            allowClear
                                            disabled={(isPreparedMaterial == 'YES' && issueType == 'INDENT') || (isPreparedMaterial == 'NO' && issueType == 'INDENT') || indentStateData || !generalItemsRequiredFlag ? true : false}
                                        >
                                            {saleOrderData.map(dropData => {
                                                return <Option poNumber={dropData.poNumber} value={dropData.saleOrderId}>{`${dropData.saleOrderNumber} - ${dropData.poNumber}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>



                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 11 }} xl={{ span: 11 }}>
                                    <Form.Item
                                        name="saleOrderItemId"
                                        label="SO Item"
                                        hidden={isHidden}
                                    >
                                        <Select
                                            placeholder="Select Sale Order Item"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={(issueType == 'INDENT') || indentStateData || !generalItemsRequiredFlag ? true : false}
                                        //  onChange={saveSOItem}
                                        >
                                            <Option value={null}>select SO Item</Option>
                                            {saleOrderItemsData.map(saleOrderDropDown => {
                                                return <Option soItemName={saleOrderDropDown.varient} value={saleOrderDropDown.saleOrderItemId}>{saleOrderDropDown.varient}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {(isPreparedMaterial == 'NO' || issueType == 'DIRECT' || issueType == 'INDENT') ? 
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="itemBarcode"
                                        label="Scan/Enter Item Barcode"
                                        // rules={[
                                        //   {
                                        //     required:true,
                                        //     message: 'barcode must be required'
                                        //   },
                                        
                                        // ]}
                                    >

                                        <Input 
                                            onChange={(e) => getItem(e)}
                                        suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'3px',marginRight:'-6px'}}/>}/>
                                    </Form.Item>
                                </Col>:<></>
                                }
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="itemId"
                                        label="Item Name"
                                        hidden={isHidden}
                                    >
                                        <Select
                                            placeholder="Select Item"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={ itemDisable}
                                            onChange={onitemchange}
                                        >
                                            {dummyItemsdata.map(itemDropDown => {
                                                return <Option itemName = {itemDropDown.itemName} value={itemDropDown.itemId}>{itemDropDown.itemName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} >
                                    <Form.Item
                                        name="itemId"
                                        label="Item Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Item Code Required',
                                            },
                                        ]}>
                                            <Select
                                            placeholder="Select Item"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={isPreparedMaterial == 'YES' ? true : false}
                                        //  onChange={saveSOItem}
                                        >
                                            {dummyItemsdata.map(itemDropDown => {
                                                return <Option  value={itemDropDown.itemId}>{itemDropDown.itemName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                                    {/* <label><span style={{ color: 'red' }}>Available Qty :{Number(availQty)} Existing PreparedQty: {existingPreparedQty}</span><span style={{ color: 'red' }}>*</span>Prepared Quantity: </label> */}
                                    <Form.Item
                                        name="issuedQuantity"
                                        label="Issued Quantity"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Quantity Required',
                                            },
                                        ]}>
                                        <InputNumber style={{ width: "100%" }} min={1} />
                                    </Form.Item>
                                </Col>
                            </>
                             {/* : <></>
                             } */}

                        <Col span={3}>
                            <Form.Item >
                                <br />
                                <Button type='primary' htmlType='submit' block
                                disabled={btnDisable}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
                <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
        }

      </Modal>
            </Card>
        </>
    );
}
export default StoreIssuesForm;
