import React,{ useState, useEffect, useRef } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select, Tabs } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import './indent-grid-for-prepared-material.css';
import { IndentformService } from '@gtpl/shared-services/procurement';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, CloseOutlined,DoubleLeftOutlined, DoubleRightOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { indentformDto, IndentFormItemsDto, RequestforIndentItem } from '@gtpl/shared-models/procurement-management';
import IndentItemsGrid from './indent-items-grid';
import { Link, Redirect, useHistory } from "react-router-dom";
import { IndentFromForPreparedMaterial } from '@gtpl/pages/procurement/packing-materials-components/indent-from-for-prepared-material';
import { indentstatusenum, UnitRequest } from '@gtpl/shared-models/common-models';
import moment from 'moment';

/* eslint-disable-next-line */
export interface IndentGridForPreparedMaterialProps {}

export function IndentGridForPreparedMaterial(
  props: IndentGridForPreparedMaterialProps
) {
  const [indentdetailsdata,setIndentdetailsdata] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [selectedIndentData, setselectedIndentData] = useState<indentformDto>();
  const [object, setObject] = useState<number>(0);
  const [indentAgainst, setIndentAgainst] = useState<string>(undefined);
  const [soId, setSoId] = useState<number>(0);
  const { TabPane } = Tabs;


  const service= new IndentformService();
  useEffect(() =>{
    getitemsdata();
  },[])
  const getitemsdata = () =>{
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    service.getindentformdetails(req).then(res =>{
      if(res.status){
        setIndentdetailsdata(res.data);
      }else{
        setIndentdetailsdata([]);
      }
    }).catch(err =>{
      setIndentdetailsdata([]);
    })

  }
  
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
   * used for column filter
   * @param dataIndex column data index
   */
   

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const openFormWithData=(viewData:indentformDto) => {
    setDrawerVisible(true);
    setselectedIndentData(viewData);
    console.log(setselectedIndentData)
    console.log('selectedIndentData')
  }


const updateitem = (data:indentformDto) =>{
  service.updateindentform(data).then(res => { console.log(res);
    if (res.status) {
      AlertMessages.getSuccessMessage('Updated Successfully');
      getitemsdata();
      setDrawerVisible(false);
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    AlertMessages.getErrorMessage(err.message);
  })
}
const setObjectValues = (rowData: any) => {
  console.log(rowData)
  setObject(rowData.indentMaterialPreparationId);
  setIndentAgainst(rowData.indentAgainst)
  setSoId(rowData.sale_order_id);
}
const CancelIndentformaterialPreparation = (indentMaterialPreparationId:number) => {
  const req = new RequestforIndentItem(indentMaterialPreparationId, indentstatusenum.CANCEL,)
  console.log(req,'req');
    service.CancelIndentformaterialPreparation(req).then(res => { console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Indent cancelled Sucesfully');
        getitemsdata();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('indentDate'),
      render: (value, record) => {
        return moment(record.indentDate).format('YYYY-MM-DD');
    }
    },
    {
      title: 'Indent Against',
      dataIndex: 'indentAgainst',
       sorter: (a, b) => a.indentAgainst - b.indentAgainst,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentAgainst'),
    },
    {
      title: 'Indent Code',
      dataIndex: 'indentCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.indentCode - b.indentCode,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentCode')
    },
    {
      title: 'Raised By',
      dataIndex: 'employeeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName - b.employeeName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    {
      title: 'Product',
      dataIndex: 'saleOrderItem',
      // responsive: ['lg'],
      sorter: (a, b) => a.saleOrderItem - b.saleOrderItem,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrderItem')
    },
    {
      title: 'Indent status',
      dataIndex: 'status',
      // responsive: ['lg'],
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          <Tooltip placement="top" title='edit'>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
          onClick={() => {
            if (rowData.isActive) {
              console.log(rowData)
              openFormWithData(rowData);
            } else {
              AlertMessages.getErrorMessage('You Cannot Edit Deactivated Trims');
            }
          }}
          style={{ color: '#1890ff', fontSize: '14px' }}
          /></Tooltip>
        {
           rowData.status == indentstatusenum.OPEN || rowData.status == indentstatusenum.PARTIALLY_TRANSFERRED  ?
          <><Divider type="vertical" />
           <Tooltip placement="top" title='Transfer Stock' > <DoubleRightOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  if (rowData.isActive) {
                    setObjectValues(rowData);
                  }
                } }
                style={{ color: '#1890ff', fontSize: '14px' }} /></Tooltip>
                
                </>:" "
        }
        {rowData.status == indentstatusenum.OPEN?
        <><Divider type="vertical" />
        <Tooltip title='Cancel'>
           <Popconfirm title='Sure to cancel?' 
           onConfirm={e => { CancelIndentformaterialPreparation(rowData.indentMaterialPreparationId); }}
              >
             <CloseOutlined className={'editSamplTypeIcon'} type="cancel"

                style={{ color: '#1890ff', fontSize: '14px' }}
             /> </Popconfirm></Tooltip> </>
           :""
         
        }
        </span>
      )
    }
  ];
  const columnsSkeltonforinPrgressandCloseandcancel: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
       sorter: (a, b) => a.indentDate.length - b.indentDate.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentDate'),
      render: (value, record) => {
        return moment(record.indentDate).format('YYYY-MM-DD');
    }
    },
    {
      title: 'Indent Against',
      dataIndex: 'indentAgainst',
       sorter: (a, b) => a.indentAgainst - b.indentAgainst,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentAgainst'),
    },
    {
      title: 'Indent Code',
      dataIndex: 'indentCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.indentCode - b.indentCode,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentCode')
    },
    {
      title: 'Raised By',
      dataIndex: 'employeeName',
      // responsive: ['lg'],
      sorter: (a, b) => a.employeeName - b.employeeName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('employeeName')
    },
    {
      title: 'Product',
      dataIndex: 'saleOrderItem',
      // responsive: ['lg'],
      sorter: (a, b) => a.saleOrderItem - b.saleOrderItem,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrderItem')
    },
    {
      title: 'Indent status',
      dataIndex: 'status',
      // responsive: ['lg'],
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    },
  ];

  const renderItems = (record: IndentFormItemsDto, index, indent, expanded) => {

    return <IndentItemsGrid indentMaterialPreparationId={record.indentMaterialPreparationId} />
  }
  return (
    <>
    {(object) ? <Redirect to={{ pathname: "/packing-stock-transfer", state: {indentId: object, so: soId, indentAgainst:indentAgainst} }} /> : null}
    <Card title={<span style={{ color: 'white' }}>Indent for  Prepared Material</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={<Link to='/indent-form-for-prepared-material' ><Button className='panel_button' >Create </Button></Link>}
    >
      <br></br>
      <Tabs type={'card'} tabPosition={'top'}>
      <TabPane
          key="1"
          tab={<span style={{ color: "green" }}>{"Open: " + (indentdetailsdata.filter(el => (el.status == indentstatusenum.OPEN)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
           rowKey={record => record.indentMaterialPreparationId}
            columns={columnsSkelton}
            dataSource={indentdetailsdata.filter(res =>
              (res.status == indentstatusenum.OPEN))}
              expandedRowRender={renderItems}
              expandIconColumnIndex={7}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "green" }}>{"Partially transferred: " + (indentdetailsdata.filter(el => (el.status == indentstatusenum.PARTIALLY_TRANSFERRED)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
           rowKey={record => record.indentMaterialPreparationId}
            columns={columnsSkelton}
            dataSource={indentdetailsdata.filter(res =>
              (res.status == indentstatusenum.PARTIALLY_TRANSFERRED))}
              expandedRowRender={renderItems}
              expandIconColumnIndex={7}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
        </TabPane>
        <TabPane
          key="3"
          tab={<span style={{ color: "green" }}>{"In Progress: " + (indentdetailsdata.filter(el => (el.status == indentstatusenum.IN_PROGRESS)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
           rowKey={record => record.indentMaterialPreparationId}
            columns={columnsSkeltonforinPrgressandCloseandcancel}
            dataSource={indentdetailsdata.filter(res =>
              (res.status == indentstatusenum.IN_PROGRESS))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            expandedRowRender={renderItems}
            expandIconColumnIndex={7}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
        </TabPane>
        <TabPane
          key="4"
          tab={<span style={{ color: "green" }}>{"Close: " + (indentdetailsdata.filter(el => (el.status == indentstatusenum.CLOSE)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
           rowKey={record => record.indentMaterialPreparationId}
            columns={columnsSkeltonforinPrgressandCloseandcancel}
            dataSource={indentdetailsdata.filter(res =>
              (res.status == indentstatusenum.CLOSE))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
        expandedRowRender={renderItems}
        expandIconColumnIndex={7}
       
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
        </TabPane>
        <TabPane
          key="5"
          tab={<span style={{ color: "green" }}>{"Cancel: " + (indentdetailsdata.filter(el => (el.status == indentstatusenum.CANCEL)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
           rowKey={record => record.indentMaterialPreparationId}
            columns={columnsSkeltonforinPrgressandCloseandcancel}
            dataSource={indentdetailsdata.filter(res =>
              (res.status == indentstatusenum.CANCEL))}
              expandedRowRender={renderItems}
              expandIconColumnIndex={7}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
        </TabPane>
        </Tabs>
      {/* <Table
        rowKey={record => record.indentMaterialPreparationId}
        columns={columnsSkelton}
        dataSource={indentdetailsdata}
        scroll={{ x: true }}
        expandedRowRender={renderItems}
        expandIconColumnIndex={7}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered /> */}
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '95%' : '85%'}
        onClose={closeDrawer} visible={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <IndentFromForPreparedMaterial key={Date.now() }
                isUpdate={true}
                selectedData = {selectedIndentData}
                updateItem={updateitem}
                closeForm={closeDrawer}
                />
        </Card>
      </Drawer>
    </Card>
    </>
  );
}

export default IndentGridForPreparedMaterial;
