export class PlantIdRequest{
    plantId: number;
    role?: string;
    varientId?: number;
}

export class StockInReverseReq{
    plantId: number;
    varientId?: number;
    saleOrderId?:number;
    fromDate?:Date;
    toDate?:Date;
}