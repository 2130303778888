import axios from 'axios';
import {AllRackZoneMapResponseModel, RackEndCustomerMapDto} from '@gtpl/shared-models/masters';
import connection from './connection';
import { RackZoneEndCustomerRequest } from 'libs/shared-models/masters/src/lib/rack-endcustomers-mapping/rack-zone-end-customer.request';

export class RackEndCustomerMapService{
    
    URL = connection.DEPLOY_URL + '/rack-zone-mapping';

    async CreateRackZone(Data: RackEndCustomerMapDto){
        console.log(Data);
        return await axios.post(this.URL + '/CreateRackZone', Data)
        .then(res => {
            return res.data
        })
    }
    async updateRackZone(Data: RackEndCustomerMapDto){
        return await axios.post(this.URL + '/updateRackZone', Data)
         .then(res => {
             return res.data
         })
    }
    async activateorDeactivateData(Data: RackEndCustomerMapDto){
        return await axios.post(this.URL + '/activateorDeactivateRackZone', Data)
        .then(res => {
            return res.data
        })
    }
    async getAllRackZone(){
        return await axios.post(this.URL + '/getAllRackZone')
        .then(res => {
            return res.data
        })
    }
    async getAllRacksByEndCustomer(rackZoneEndCustomerRequest:RackZoneEndCustomerRequest): Promise<AllRackZoneMapResponseModel>{
        return await axios.post(this.URL + '/getAllRacksByEndCustomer',rackZoneEndCustomerRequest)
        .then(res => {
            return res.data
        })
    }
}
