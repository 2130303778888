import React, { useRef, useState } from 'react';
import {
  Table,
  Card,
  Tabs,
  DatePicker,
  Form,
  Col,
  Input,
  Select,
  Row,
  Button,
  Statistic,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import './repacking-reprocessing-report.css';
/* eslint-disable-next-line */
export interface RepackingReprocessingReportProps {}

export function RepackingReprocessingReport(
  props: RepackingReprocessingReportProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }
  const datasource = [
    {
      key: '1',
      Process: 'Repacking',
      jobcode: 'JOB 1',
      customerPoNo: '001',
      productSKU: '1-10/BTRHOSOI/S2S2/11-15/120Min',
      AllocateQuantity: '50',
      AssignQuantity: '50',
      ReceivedQuantity: '50',
      ExcessQuantityReported: '0.000',
      DamagedQuantityReported: '0.000',
      Cartons: '30',
      Status: 'Open',
    },
    {
      key: '2',
      Process: 'Reprocessing',
      jobcode: 'JOB 2',
      customerPoNo: '001',
      productSKU: '1-10/BTRHOSOI/S2S2/11-15/120Min',
      AllocateQuantity: '50',
      AssignQuantity: '50',
      ReceivedQuantity: '50',
      ExcessQuantityReported: '0.000',
      DamagedQuantityReported: '0.000',
      Cartons: '30',
      Status: 'closed',
    },
    {
      key: '3',
      Process: 'Repacking',
      jobcode: 'JOB 3',
      customerPoNo: '001',
      productSKU: '1-10/BTRHOSOI/S2S2/11-15/120Min',
      AllocateQuantity: '50',
      AssignQuantity: '50',
      ReceivedQuantity: '50',
      ExcessQuantityReported: '0.000',
      DamagedQuantityReported: '0.000',
      Cartons: '30',
      Status: 'Open',
    },
  ];

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      width: '70px',
      dataIndex: 'key',
    },
    {
      title: 'Process',
      width: '70px',
      dataIndex: 'Process',
      sorter: (a, b) => a.Repacking - b.Reprocessing,
      ...getColumnSearchProps('Process'),
    },

    {
      title: 'JobCode',
      width: '70px',
      dataIndex: 'jobcode',
      sorter: (a, b) => a.code - b.code,
      ...getColumnSearchProps('jobcode'),
    },
    {
      title: 'Customer Po No',
      width: '70px',
      dataIndex: 'customerPoNo',
      sorter: (a, b) => a.Asscending - b.Decending,
      ...getColumnSearchProps('customerPoNo'),
    },
    {
      title: 'Product SKU',
      width: '70px',
      dataIndex: 'productSKU',
      sorter: (a, b) => a.Asscending - b.Decending,
      ...getColumnSearchProps('productSKU'),
    },
    {
      title: 'Allocate Quantity',
      width: '70px',
      dataIndex: 'AllocateQuantity',
      sorter: (a, b) => a.Asscending - b.Decending,
    },
    {
      title: 'Assign Quantity',
      width: '70px',
      dataIndex: 'AssignQuantity',
      sorter: (a, b) => a.Asscending - b.Decending,
    },

    {
      title: 'Received Quantity',
      width: '70px',
      dataIndex: 'ReceivedQuantity',
      sorter: (a, b) => a.Asscending - b.Decending,
    },
    {
      title: 'Excess Quantity Reported',
      width: '70px',
      dataIndex: 'ExcessQuantityReported',
      sorter: (a, b) => a.Asscending - b.Decending,
    },
    {
      title: 'Damaged Quantity Reported',
      width: '70px',
      dataIndex: 'DamagedQuantityReported',
      sorter: (a, b) => a.Asscending - b.Decending,
    },

    {
      title: 'Cartons',
      width: '70px',
      dataIndex: 'Cartons',
      sorter: (a, b) => a.Asscending - b.Decending,
      ...getColumnSearchProps('Cartons'),
    },
    {
      title: 'Status',
      width: '70px',
      dataIndex: 'Status',
      sorter: (a, b) => a.Asscending - b.Decending,
      ...getColumnSearchProps('Status'),
    },
  ];
  return (
    <div>
      <h1>
        <Card
          title={
            <span style={{ color: 'white' }}>Repacking & Reprocessing</span>
          }
          style={{ textAlign: 'center' }}
          headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
        >
          <br></br>
          <Form>
            <Row>
              <Col>
                <Form.Item
                  name="Allocation Date"
                  label="Allocation Date"
                  rules={[
                    {
                      required: false,
                      message: ' Allocation',
                    },
                  ]}
                >
                  <RangePicker />
                </Form.Item>
              </Col>

              <Col style={{ marginLeft: '1%' }}>
                <Form.Item
                  name="Product"
                  label="Product"
                  rules={[
                    {
                      required: false,
                      message: ' Select product status',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Product Status"
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Card title="Report Summary">
              <Row gutter={24}>
                <Col>
                  {/* <Card title={'Allocate Quantity'} style={{textAlign:'left' ,width: 200, height:41,}}></Card> */}

                  <h1>Alocate Quantity</h1>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Repacking :50</h4>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Reprocessing:50</h4>
                </Col>
                <Col style={{marginLeft:'5%'}}>
                  <h1>Assigned Quantity</h1>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Repacking : 50</h4>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>  Reprocessing:50</h4>
                  </Col>
                <Col style={{marginLeft:'5%'}} >
                  <h1>Received Quantity</h1>
                  <br></br>
                  <h4 style={{ marginTop: '5%'}}>Repacking :50</h4>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Reprocessing:50</h4>
                </Col>
                <Col style={{marginLeft:'5%'}}>
                  <h1>Damaged Quantity </h1>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Repacking :50</h4>
                  <br></br>
                  <h4 style={{ marginTop: '5%' }}>Reprocessing:50</h4>
                </Col>
                
              </Row>
            </Card>
          </Form>
          <Table columns={columnsSkelton} dataSource={datasource} />
        </Card>
      </h1>
    </div>
  );
}

export default RepackingReprocessingReport;
