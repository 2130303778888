import { TrimsItemMappingDto } from "./trims-items-mapping-dto"

export class TrimsConfigurationDTO{
    trimId:number
    brandId:number
    packStyleId:number
    variantId:number
    isActive:boolean
    createdAt: Date
    createdUser: string | null
    updatedAt: Date
    updatedUser: string | null
    versionFlag: number
    trimItemsInfo: TrimsItemMappingDto[]
    endCustomerId: number;
    constructor(
        trimId:number,
        brandId:number,
        packStyleId:number,
        variantId:number,
        isActive:boolean,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number,
        trimItemsInfo: TrimsItemMappingDto[],
        endCustomerId : number
    ){
        this.trimId = trimId;
        this.brandId = brandId;
        this.packStyleId = packStyleId;
        this.variantId = variantId;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser
        this.versionFlag = versionFlag;
        this.trimItemsInfo = trimItemsInfo;
        this.endCustomerId = endCustomerId
    }


}
