import axios from 'axios';
import connection from './connection';
import { AllExpensesCategoryResponse, ExpensesCategoryDto, ExpensesCategoryResponse} from '@gtpl/shared-models/hrms';

export class ExpensesCategoryService{

    URL = connection.DEPLOY_URL + '/expenses-category';

    async createExpensesCategory(expensesCategorydto: ExpensesCategoryDto ): Promise<ExpensesCategoryResponse> {
        console.log(expensesCategorydto);
       
        return await axios.post(this.URL + '/createExpensesCategory',expensesCategorydto)
            .then(res => {
                return res.data
            })
    }

    async getAllExpensesCategories(): Promise<AllExpensesCategoryResponse> {
                
        return await axios.post(this.URL + '/getAllExpensesCategories')
            .then(res => {
                return res.data 
            })     
    }

    async  updateExpensesCategory(expensesCategorydto: ExpensesCategoryDto): Promise<ExpensesCategoryResponse> {
        return await axios.post(this.URL + '/updateExpensesCategory', expensesCategorydto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateExpensesCategory(expensesCategorydto: ExpensesCategoryDto): Promise<ExpensesCategoryResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateExpensesCategory', expensesCategorydto)
                        .then(res => {
                                return res.data
                        })
                }

    async getAllActiveExpensesCategories(): Promise<AllExpensesCategoryResponse> {
    
        return await axios.post(this.URL + '/getAllActiveExpensesCategories')
            .then(res => {
                return res.data 
            })     
    }
                
}