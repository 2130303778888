import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col,Descriptions, Drawer,Typography, Modal} from 'antd';
import { BeheadingInventoryDto, SoakingAssignmentDto, SoakingDto } from '@gtpl/shared-models/production-management';
import { SoakingAssignmentService } from '@gtpl/shared-services/production';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined ,SafetyCertificateOutlined,PrinterOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {SoakingSampleTestForm} from '@gtpl/pages/production-management/production-management-components/soaking-sample-test-form'
import Link from 'antd/lib/typography/Link';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'
import {  Redirect } from 'react-router-dom';

import './soaking-material-inventory.css';

/* eslint-disable-next-line */
export interface SoakingMaterialInventoryProps {}


export function SoakingMaterialInventory(props: SoakingMaterialInventoryProps) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [soakingAssignmentData, setSoakingAssignmentData] = useState<SoakingDto[]>([]);
  const [productionLogId, setProductionLogId] = useState<number>(0);
  const [selectedInformation, setSelectedInformation] = useState<SoakingDto>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saleId, setSaleId] = useState(0);
  const [modelType, setModelType] = useState<string>('');
  
  const soakingAssignmentService = new SoakingAssignmentService();
  const unit = JSON.parse(localStorage.getItem('unit_id'));

  useEffect(() => {
    getAllSoakingInventories();
  }, []);

  /**
   * 
   */
  const getAllSoakingInventories= () => {
    soakingAssignmentService.getAllSoakingAssignments({unitId:unit}).then(res => {
      console.log(res.status,'*******')
      if (res.status) {
        setSoakingAssignmentData(res.data);
        
      } else {
        if (res.intlCode) {
            setSoakingAssignmentData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSoakingAssignmentData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param soakingData 
   */
  // const activateorDeactivateSoakingInventory = (soakingData:SoakingDto) => {
  //   soakingData.jobStatus=soakingData.jobStatus?false:true;
  //   soakingInventoryService.activateorDeactivateSoakingInventory(soakingData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllSoakingInventories();
  //       AlertMessages.getSuccessMessage('Success');
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
   
    
   /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
  /**
   * 
   * @param soakingData 
   */
  const soakingAssignmentJobCompleted = (soakingData:SoakingAssignmentDto) => {
    soakingAssignmentService.jobCompletedStatus(soakingData).then(res => { console.log(res);
      if (res.status) {
        getAllSoakingInventories();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const goToDetailView = (productionLogId:number) => {
    setProductionLogId(productionLogId);
    console.log(productionLogId);
  }
  var sum = (soakingAssignmentData.filter(rec=> rec.jobStatus==="YES")).reduce(function (previousValue, currentValue) {
      return previousValue + Number(currentValue.physicalQuantity);
  }, 0);
  var issuedQnty = (soakingAssignmentData.filter(rec=> rec.jobStatus==="NO")).reduce(function (previousValue, currentValue) {
      return previousValue + Number(currentValue.physicalQuantity);
  }, 0);

  const reportSoaking = (logId: number) => {
    console.log(logId)
    setObject(logId)

  }

  const displayModel = (data: SoakingDto, type) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
    setModelType('SampleForm')
    
  };

 
  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
    setModelType('SoInfoView')
  }

  const handleCancel = () => {
    getAllSoakingInventories();
    setIsModalVisible(false);
  };

  
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'SNo',
      key: 'sno',
      width: '80px',
      responsive:['md'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Sub Lot Code',
      dataIndex: 'plantAndSubLotNumber',
      // responsive: ['lg'],
      width:'50px',
      sorter: (a, b) => a.plantAndSubLotNumber.localeCompare(b.plantAndSubLotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantAndSubLotNumber')
    },
    // {
    //   title: 'Sale Order Number',
    //   dataIndex: 'soNumber',
    //   sorter: (a, b) => a.soNumber.localeCompare(b.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.soNumber}</Text>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Text>
    },
    {
      title: 'Input Grade', 
      dataIndex: 'inputGrade',
      align:'right',
      sorter: (a, b) => a.inputGrade - b.inputGrade,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('inputGrade')
    },
    {
      title: 'output Grade',
      dataIndex: 'outputGrade',
      align:'right',
      sorter: (a, b) => a.outputGrade - b.outputGrade,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('outputGrade')
    },
    {
      title: 'Varient Code',
      dataIndex: 'variantCode',
      align:'right',
      responsive:['md'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    },
    {
      title: 'Workstation',
      dataIndex: 'workstation',
      align:'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    {
      title: 'Soaking Style',
      dataIndex: 'soakingStyle',
      // responsive: ['lg'],  `
      sorter: (a, b) => a.soakingStyle.localeCompare(b.soakingStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingStyle')
    },
    {
      title: 'Soaking Time (Min)',
      dataIndex: 'soakingTime',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.soakingTime -b.soakingTime,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingTime')
    },
    {
      title: 'Issuing Quantity',
      dataIndex: 'physicalQuantity',
      align:'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.physicalQuantity - b.physicalQuantity,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('physicalQuantity')
    },
    {
      title: 'Job Completed',
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData) => (
        <>
          {jobStatus === "YES"?<Tag icon={<CheckCircleOutlined />} color="#87d068">Yes</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">No</Tag>}
          
        </>
      ),
      filters: [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.jobStatus === value;
      },
      
    },
    {
      title:'Action',
      dataIndex:'action',
      render: (text, rowData) => (
        <span>
          {rowData.jobStatus === "NO"?
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
                reportSoaking(rowData.productionLogId);
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />:""}
          <EyeOutlined  className={'viewSamplTypeIcon'}  type="view" 
            onClick={() => goToDetailView(rowData.productionLogId)}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
             <Divider type="vertical" />
             <SafetyCertificateOutlined
        style={{ color: 'red' }}
        onClick={() => displayModel(rowData, 'soaking-sample')} />
        
        
        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  const OpenFormTocreateRecord = () => {
   console.log('redirect here');
   


  }
  return (
    <>
    {(object) ?<Redirect to={{pathname: "/soaking-reporting", state: object}} />:null}
    {(productionLogId) ?<Redirect to={{pathname: "/soaking-material-detail-view", state: productionLogId}} />:null}
      <br></br>

    <Card title={<span style={{color:'white'}}>Soaking Inventory</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    // extra={<Link to='/soaking-assignment-form' ><Button className='panel_button' >Assign Soaking </Button></Link>}
    >
     <br></br>
     <Row gutter={40}>
        <Col>
          <Card
            title={'No. of Lots Available : ' + soakingAssignmentData.length}
            style={{
              textAlign: 'left',
              width: 230,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity To be Processed : ' + issuedQnty}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Soaking Inventory : ' + sum}
            style={{
              textAlign: 'left',
              width: 280,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
          <br></br>
          <Card title={<span style={{ color: 'black' }}>Soaking Inventory</span>}
      style={{ textAlign: 'center' }} headStyle={{border: 0 }} bodyStyle={{padding:0}}>
         
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={soakingAssignmentData}
          scroll={{ x: true }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalQuantity = 0;
          
            pageData.forEach(({ physicalQuantity }) => {
              totalQuantity += Number(physicalQuantity);
              
            });

            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={2} colSpan={10} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}><Text  style={{textAlign:'end'}}>{totalQuantity}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }
         } />
       </Card>

       <Modal
        className='soaking-sample'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(modelType == 'SoInfoView')? <SoInfoViewPages saleOrderId={saleId}  screen='production'/> : '' }
     {(modelType == 'SampleForm') ? <SoakingSampleTestForm  isUpdate = {false} soakingSampleData={selectedInformation} /> : ''}
        
     

        
      </Modal>
     
     </Card>
     </>
  );
}

export default SoakingMaterialInventory;