import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { GradeLotNoModel } from "./grade-lot-no.model";

export class GradeLotNoResponse extends GlobalResponseObject{
    data: GradeLotNoModel;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data: GradeLotNoModel){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}