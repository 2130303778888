import axios from 'axios';
import connection from './connection';

export class FgStockReportService{
    URL = connection.DEPLOY_URL + '/fg-stock-report';

   async getFgStockReportData(){
       return await axios
       .post(this.URL + '/fgStockReportData')
       .then((responseData)=>{
           return responseData.data;
       })
   }
}
