import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { LocationDto } from "libs/shared-models/masters/src/lib/location/location-dto";
import connection from "./connection";
import {LocationById} from '../../../../shared-models/asset-management/src/lib/asset-location/location-by-id.dto'
import {AssetsLocationDto} from '../../../../shared-models/asset-management/src/lib/asset-location/asset-location.dto'
import {LocationByPlant} from '../../../../shared-models/asset-management/src/lib/asset-location/location-by-plant.dto'



export class AssetLocationService{
    URL = connection.DEPLOY_URL + '/location';

    
    async create(req: AssetsLocationDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/create',req)
        .then(res => {
            return res.data;
        })

    }

    async getAllAssets(req?:LocationByPlant) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsLocations',req)
        .then(res => {
            return res.data;
        })
    }

    async activateOrDeactivate(req: CommonResponse) : Promise<CommonResponse> {
            return await axios.post(this.URL + '/activateOrDeactivate',req)
            .then(res => {
                return res.data;
            })
    }

    async update(req: AssetsLocationDto) : Promise<CommonResponse> {
            return await axios.post(this.URL + '/updateLocation',req)
            .then(res => {
                return res.data;
            })
    }

    async getById(req: LocationById) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getById',req)
        .then(res => {
            return res.data;
        })
    }

    async getLocationByPlant(req: LocationByPlant) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationByPlant',req)
        .then(res => {
            return res.data;
        })
    }

    async getAssetsCountByLocations(req: LocationById) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetsCountByLocations',req)
        .then(res => {
            return res.data;
        })
    }





}