import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { StockInResponseDto } from './stock-in-response.dto';

export class StockInResponseModel extends GlobalResponseObject {
    data?: StockInResponseDto;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: StockInResponseDto){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}