import { ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto } from "@gtpl/shared-models/masters";
import { SOInfoReq } from "./so-info.req";

export class DummyPackBomItemsRequest {
    itemId: number;
    saleOrderId: number;
    itemCategoryId: number;
    itemSubCategoryId: number;
    quantity: number;
    acceptedQuantity: any;
    excessQty: number;



    /**
     * @param itemId
    * @param saleOrderId
    * @param acceptedQuantity
     */
    constructor(itemId: number, saleOrderId: number,itemCategoryId: number,itemSubCategoryId: number,quantity: number,acceptedQuantity: any,excessQty: number) {
        this.itemId = itemId;
        this.saleOrderId = saleOrderId;
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.quantity = quantity;
        this.acceptedQuantity = acceptedQuantity;
        this.excessQty = excessQty;
    }
}