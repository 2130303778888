
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { NotifyPartyDto } from "./notify-party.dto";

export class NotifyPartyResponse extends GlobalResponseObject{

      data?: NotifyPartyDto;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, intlCode:number, internalMessage:string, data?: NotifyPartyDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.internalMessage = internalMessage;
        this.intlCode = intlCode;
        this.data = data;
    }

}