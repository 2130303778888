export class ValueAdditionFourDto {
    valueAdditionFourId?:number;
    code: string;
    name: string;
    remarks: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;


}

export const valueAdditionFourDtoDefault : ValueAdditionFourDto = {
    code: "",
    name: "",
    remarks: "",
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1

};