export class EmployeeIdProofDetailsDto {
    
    employeeIdProofId: number;
    idType: string;
    idNumber: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
 
 }