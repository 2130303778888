import connection from './connection';
import { getAllStockInDetailsResponseModel, InputIdRequest, PalletLocationUpdateRequest, PlantIdRequest, PlantZone, RackReserveRequest, SaleOrderIdRequest, StockInDto, StockInReportResponseModel, StockInRequestDto, StockInResponseModel, StockInVerfication, StockOutResponse, StockResponseData, WarehouseDashboardResponseModel, WarehouseResponseModel } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import { StockInRequest } from './stock-in.request';
import { CommonResponse, SaleOrderIdsRequest, SaleOrderItemsBySoIdReaponseModel } from '@gtpl/shared-models/sale-management';
import { StockReportFilters, UnitRequest } from '@gtpl/shared-models/common-models';
import { StockinPrintResponseModel } from './stock-in-print-resonsemodel';
import { StockInPritnRequest } from './stock-in-print-request';

export class StockInService {
    URL = connection.DEPLOY_URL + '/stock-in';

    async reportStockIn(stockInRequest: StockInDto): Promise<StockInResponseModel> {
        return await axios.post(this.URL + '/reportStockIn', stockInRequest)
            .then(res => {
                return res.data
            })
    }
    async reportStock(stockInRequest: StockInRequestDto): Promise<StockInReportResponseModel> {
        console.log(stockInRequest);
        return await axios.post(this.URL + '/reportStock', stockInRequest)
            .then(res => {
                return res.data
            })
    }
    async reportStockData(stockInRequest: StockInRequest): Promise<StockInResponseModel> {
        // console.log(stockInRequest)
        return await axios.post(this.URL + '/reportStockData', stockInRequest)
            .then(res => {
                return res.data
            })
    }

    async productDetailsForPalletId(req: InputIdRequest):Promise<SaleOrderItemsBySoIdReaponseModel> {
        return await axios.post(this.URL + '/productDetailsForPalletId', req)
            .then(res => {
                return res.data
            })
    }
    async getReportedStockForSoId(req: SaleOrderIdRequest):Promise<getAllStockInDetailsResponseModel> {
        // console.log(req);
        return await axios.post(connection.DEPLOY_URL + '/stock/getReportedStockForSoId', req)
            .then(res => {
                return res.data
            })
    }
    async getStockIndata(req: SaleOrderIdRequest):Promise<getAllStockInDetailsResponseModel> {
        // console.log(req);
        return await axios.post(connection.DEPLOY_URL + '/stock/getStockInData', req)
            .then(res => {
                return res.data
            })
    }
    async getAllStock(req:PlantIdRequest):Promise<any> {
        console.log(req);
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllStock', req)
            .then(res => {
                return res.data
            })
    }
    async updagtePalletLocation(req: PalletLocationUpdateRequest):Promise<getAllStockInDetailsResponseModel> {
        console.log(req);
        return await axios.post(connection.DEPLOY_URL + '/stock/updatePalletLocation', req)
            .then(res => {
                return res.data
            })
    }
    async getAllStockData():Promise<getAllStockInDetailsResponseModel> {
        // console.log(req);
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllStockData')
            .then(res => {
                return res.data
            })
    }
    async getWarehouseView(plantZone: PlantZone):Promise<StockResponseData> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getWarehouseView',plantZone)
            .then(res => {
                return res.data
            })
    }
    async getRackDetails(req: RackReserveRequest): Promise<StockResponseData> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getRackDetails', req)
            .then(res => {
                return res.data
            })
    }
    async getAllStockForStockReport(req?:StockReportFilters):Promise<any> {
        
        return await axios.post(connection.DEPLOY_URL + '/stock/getAllStockForStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getColdDropForStockRep(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getColdDropForStockRep',req)
            .then(res => {
                return res.data
            })
    }
    async getProductDropForStockReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getProductDropForStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getProductDropForFgStockReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getProductDropForFgStockReport',req)
            .then(res => {
                return res.data
            })
    }
    async getWarehouseRackDetails(req: RackReserveRequest): Promise<WarehouseDashboardResponseModel> {
        return await axios.post(connection.DEPLOY_URL + '/stock/getWarehouseRackDetails',req)
            .then(res => {
                return res.data
            })
    }
    async reverseStockIn(req: StockInRequest): Promise<WarehouseDashboardResponseModel> {
        return await axios.post(this.URL + '/reverseStockIn',req)
            .then(res => {
                return res.data
            })
    }
    async getShiftsDropdownForStockInReport(req?:UnitRequest): Promise<CommonResponse> {
        console.log(req,'req')
        return await axios.post(connection.DEPLOY_URL + '/stock/getShiftsDropdownForStockInReport',req)
            .then(res => {
                return res.data
            })
    }
    async getUnitDropdownForStockInReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnitDropdownForStockInReport')
            .then(res => {
                return res.data
            })
    }

    async getUnitDropdownForStockReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnitDropdownForStockReport')
            .then(res => {
                return res.data
            })
    }
    async getStockInPrint(req:StockInPritnRequest):Promise<StockinPrintResponseModel>{
        return await axios.post(this.URL+'/getStockInPrint',req)
        .then(res =>{
            return res.data;
        })
    }
    async getDeadStockData(req:StockReportFilters):Promise<CommonResponse>{
        return await axios.post(connection.DEPLOY_URL + '/stock/getDeadStockData',req)
        .then(res =>{
            return res.data;
        })
    }
    async reportStockInData(stockInDto: StockInRequestDto): Promise<StockInReportResponseModel> {
        return await axios.post(this.URL + '/reportStockInData',stockInDto)
        .then(res =>{
            return res.data;
        })
    }
    async stockPlacementVerification(req: StockInVerfication): Promise<CommonResponse> {
        return await axios.post(this.URL + '/stockPlacementVerification',req)
        .then(res =>{
            return res.data;
        })
    }
    async getPalletsVerificationDropDown(req: PlantIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPalletsVerificationDropDown',req)
        .then(res =>{
            return res.data;
        })
    }
}
