import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { SoakingDto } from ".";

export class SoakingDetailViewResponseModel extends GlobalResponseObject{
    data?: SoakingDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SoakingDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}