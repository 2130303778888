import React, { useEffect, useRef, useState } from 'react';
import Table, { ColumnProps, ColumnsType, ColumnType } from 'antd/lib/table';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Space, Col, Row, Tag, DatePicker, Modal, Tabs } from 'antd';
import { CaretRightOutlined, SearchOutlined, FilterOutlined, EditOutlined, SplitCellsOutlined, CheckCircleTwoTone, CloseOutlined, RotateRightOutlined, RedoOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CrrencySymbols, SaleOrderTrackDto, SOItemsDto, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';

import Highlighter from 'react-highlight-words';

import './sale-order-tracking-report-grid.css';
import Link from 'antd/lib/typography/Link';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Column from 'antd/lib/table/Column';

import { render } from 'react-dom';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import { ProductDto } from '@gtpl/shared-models/masters';
import { ReProcess } from '@gtpl/pages/dashboard/dashboard-components/factory-head-dashboard-grid';
import { RePacking } from '@gtpl/pages/dashboard/dashboard-components/factory-head-dashboard-grid';
import { EmployeeRolesEnum, JobTypesEnum, SoLifeCycleStatusEnum, SoProcessTypesEnum, SoStatusEnum, SoTypesEnum } from '@gtpl/shared-models/common-models';
import SaleOrderItemsView from './sale-order-items-view';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderIdRequest } from '@gtpl/shared-models/warehouse-management';
import { useHistory, useLocation } from 'react-router-dom'
import SOTransferForm from './so-transfer-form';



/* eslint-disable-next-line */
export interface SaleOrderTrackingReportGridProps {
  saleOrderTrackingReportData: SaleOrderTrackDto[];
  updateState: (updateState: number) => void
}

export function SaleOrderTrackingReportGrid(
  props: SaleOrderTrackingReportGridProps
) {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [saleId, setSaleId] = useState(0);
  const[poNumber,setPonumber] = useState('');
  const [object, setObject] = useState(null);
  const [cloneObject, setCloneObject] = useState(null);
  const [jobType, setJobType] = useState(null);
  // const [itemsdata, setItemsdata] = useState<ProductDto[]>([]);
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [soData, setSoData] = useState<any>();
  const [saleIdForProcess, setSaleIdForProcess] = useState(0);
  const [selectedBeginKeys, setSelectedBeginKeys] = useState(String);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [selectedEndKeys, setSelectedEndKeys] = useState(String);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [soTransferModalVisible, setSoTransferModalVisible] = useState<boolean>(false);
  const [isReProcess, setIsReProcess] = useState<boolean>(false);
  const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [pageSize, setPageSize] = useState<number>(null);
  const saleService = new SaleOrderService()
  let history = useHistory();

  useEffect(() => {

  }, [page, pageSize]);


  // useEffect(() => {
  //   console.log(props.saleOrderTrackingReportData);
  //   console.log(props.saleOrderTrackingReportData.length);
  //   getAllSaleOrderItems();
  //   // setSelectedBeginKeys(["2021-03-04"]);
  //   // setSelectedEndKeys(["2021-03-05"]);

  // }, []);

  const closeModal = (closeModal) => {
    console.log(closeModal);
    if (closeModal) {
      setIsModalVisible(false);
      window.location.reload();
    }
    // setSelectedCurrency(currencyName);
    // setItemsKey(prevVal=>prevVal+1);

  }


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      console.log(searchInput.current);
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  function getdata(val: any) {
    console.log("getdata is running");
    return (
      val === 'yes' ?
        <span key={val} color="#f4646c">
          *
        </span> : null
    );
  }
  const tableColumns = (key) => {
    const agingColumns: ColumnsType<any> = [
      {
        title: "",
        width: 50,
        children: [
          {
            title: 'Aging',
            key: 'actualEtd',
            width: 80,
            align: 'left',
            sorter: (a, b) => (Math.floor((new Date(a.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
            sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('aging'),
            render(text, record) {
              const obj: any = {
                children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
                props: {
                  style: {
                    background: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
                    color: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
                  }
                },
              };
              return obj;
            }
          },
        ]
      },
    ]
    const sampleTypeColumns: ColumnsType<any> = [
      {
        title: "",
        children: [
          {
            title: 'SNo',
            dataIndex: 'sno',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, object, index) => (page - 1) * pageSize + (index + 1)

          },

          // {
          //   title: 'SO Number',
          //   dataIndex: 'saleOrderNumber',
          //   fixed: 'left',
          //   width: 140,
          //   align: 'left',

          //   ...getColumnSearchProps('saleOrderNumber'),
          //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</a>,
          //   sorter: (a, b) => a.saleOrderId - b.saleOrderId,
          //   sortDirections: ['descend', 'ascend'],
          //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</Link>,
          // },
          {
            title: 'Customer PO',
            dataIndex: 'poNumber',
            width: 130,
            align: 'left',
            ...getColumnSearchProps('poNumber'),
            sorter: (a, b) => a.poNumber != null ? a.poNumber.localeCompare(b.poNumber) : "Have Null Values",
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {
              const PoNumberCount = ((props.saleOrderTrackingReportData.filter(rec => rec.soType === SoTypesEnum.DISPATCH && rec.soProcessType === SoProcessTypesEnum.MTO && rec.poNumber == record.poNumber && rec.status !== SoStatusEnum.CANCELLED))).length
              console.log(PoNumberCount)
              return <>
                <Link onClick={e => goToSaleDetailView(record.saleOrderId,record.poNumber)}>{record.poNumber}</Link>
                {record.jobType == JobTypesEnum.MAIN_JOB ? <Button type='primary' htmlType='button' onClick={e => (setClonevalue(record.saleOrderId))}>Clone</Button> : <></>}
              </>
            },
          },
          {
            title: 'PO Date',
            dataIndex: 'poDate',
            // fixed: 'left',
            width: 125,
            align: 'left',

            // ...getColumnSearchProps('poDate'),1
            render: (text, record) => { return record.poDate != undefined ? moment(record.poDate).format('YYYY-MM-DD') : "" },
            sorter: (a, b) => moment(a.poDate).unix() - moment(b.poDate).unix(),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Customer',
            dataIndex: 'customer',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.customer.localeCompare(b.customer),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('customer'),
          },
          {
            title: 'FG%',
            dataIndex: 'productionPercentage',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.productionPercentage-(b.productionPercentage),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('productionPercentage'),
          },
        ],
      }
    ]
   
    // const agingColumn: ColumnsType<any> = [
    const customerColumns: ColumnsType<any> = [
      {
        title: "",
        children: [

          {
            title: 'End Customers',
            dataIndex: 'endCustomername',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.endCustomername.localeCompare(b.endCustomername),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('endCustomername'),
          },
          {
            title: 'Buyer PO',
            dataIndex: 'buyerPONo',
            width: 125,
            align: 'left',
            sorter: (a, b) => a.buyerPONo.localeCompare(b.buyerPONo),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('buyerPONo'),
          },
          {
            title: 'Load Type',
            dataIndex: 'loadType',
            //fixed: 'right',
            width: 115,
            align: 'left',
            // sorter: (a, b) => a.loadType.localeCompare(b.loadType),
            // sortDirections: ['descend', 'ascend'],
            render: (text, record) => {
              // let loadType: number = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length
              return record.loadType === "1" ? "Single" : record.loadType === "0" ? "Mixed" : "-"
            },
            filters: [
              {
                text: 'Single',
                value: "1",
              },
              {
                text: 'Mixed',
                value: "0",
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.loadType === value;
            },
            // onFilter: (value, record) => {
            //   // let loadType: boolean = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length === 1 ?true:false
            //   // console.log(value, loadType);
            //   // === is not work
            //   return loadType === value;
            // },

          },

        ],

      },
    ]
    const scheduledDatesColumns: ColumnsType<any> = [

      {
        title: "SCHEDULED DATES",
        width: 50,
        children: [
          // {
          //   title: 'Ordered Date',
          //   dataIndex: 'orderedDate',
          //   //fixed: 'right',
          //   width: 150,
          //   align:'center',
          //   sorter: (a, b) => moment(a.orderedDate).unix() - moment(b.orderedDate).unix(),

          // filterDropdown: ({
          //   setSelectedKeys,
          //   selectedKeys,
          //   confirm,
          //   clearFilters
          // }) => (
          //   <div style={{ padding: 8 }}>
          //     <RangePicker
          //       // showTime={{ format: 'HH:mm' }}
          //       format="YYYY-MM-DD"
          //       onChange={e => setSelectedKeys(e.indexOf[0])}
          //       // onOk={onOk}
          //     />
          //   <br></br>
          //     <Button
          //       type="primary"
          //       onClick={() => {handleSearch(selectedKeys, confirm, 'orderedDate')}}
          //       icon={<SearchOutlined />}
          //       size="small"
          //       style={{ width: 90, marginRight: 8 }}
          //     >
          //       Search
          //     </Button>
          //     <Button
          //       onClick={() => {handleReset(clearFilters)}}
          //       size="small"
          //       style={{ width: 90 }}
          //     >
          //       Reset
          //     </Button>
          //   </div>
          // ),
          // filterIcon: filtered => (
          //   <SearchOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
          // ),
          // onFilter: (value:string, record) =>
          //   record['orderedDate']
          //     .toString()
          //     .toLowerCase()
          //     .includes(value.toLowerCase()),

          //     onFilterDropdownVisibleChange: visible => {
          //       console.log(selectedBeginKeys)
          //       // console.log(searchInput)
          //       if (visible) {    setTimeout(() => selectedBeginKeys.indexOf[0]);   }
          //     },
          //     render: text => 
          //       text ?(
          //       searchedColumn === 'OrderedDate' ? (
          //         <Highlighter
          //           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //           searchWords={[selectedBeginKeys]}
          //           autoEscape
          //           textToHighlight={text.toString()}
          //         />
          //       ) :text
          //       )
          //       : null,
          // onFilterDropdownVisibleChange: visible => {
          //   if (visible) {
          //     //setTimeout(() => this.searchInput.select());
          //   }
          // },
          // ...getColumnSearchProps('OrderedDate'),

          // sorter: (a, b) => moment(a.OrderedDate).unix() - moment(b.OrderedDate).unix(),
          // sortDirections: ['descend', 'ascend'],
          // },

          {
            title: 'ETD',
            dataIndex: 'expectedEndDate',
            sorter: (a, b) => moment(a.expectedEndDate).unix() - moment(b.expectedEndDate).unix(),
            width: 130,
            align: 'left',
            render: (value, rowData: SaleOrderTrackDto, index) => {
              return moment(value).format('YYYY-MM-DD');
            },
          },
          {
            title: 'Aging',
            key: 'actualEtd',
            width: 80,
            align: 'left',
            sorter: (a, b) => (Math.floor((new Date(a.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
            sortDirections: ['descend', 'ascend'],
            // ...getColumnSearchProps('aging'),
            render(text, record) {
              const obj: any = {
                children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)}</div>),
                props: {
                  style: {
                    background: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c',
                    color: Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white'
                  }
                },
              };
              return obj;
            }
          },

          {
            title: 'Revised ETD',
            dataIndex: 'actualEtd',
            sorter: (a, b) => moment(a.actualEtd).unix() - moment(b.actualEtd).unix(),
            width: 130,
            align: 'left',
            render: (value, rowData: SaleOrderTrackDto, index) => {
              return moment(value).format('YYYY-MM-DD');
            },
          },
          // ...getColumnSearchProps('expectedEndDate'),
          // key: 'expectedEndDate',
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
          // defaultSortOrder: 'descend',
          // sortDirections: ['descend', 'ascend'],

        ],
        render(text, record) {
          return {
            props: {
              style: {
                background: "cyan",
                color: "black"
              }
            },
          };
        }
      },
    ]
    const destinationColumns: ColumnsType<any> = [

      {
        title: "",
        align: 'center',
        children: [

          // {
          //   title: 'Ordered Quantity',
          //   dataIndex: 'orderQty',
          //   //fixed: 'right',
          //   width: 150,
          //   align:'center',

          //   ...getColumnSearchProps('orderQty'),
          //   sorter: (a, b) => a.orderQty - b.orderQty,
          //   sortDirections: ['descend', 'ascend'],
          // },
          {
            title: 'Final Country',
            dataIndex: 'countryName',
            //fixed: 'right',
            width: 130,
            align: 'left',

            ...getColumnSearchProps('countryName'),
            sorter: (a, b) => a.countryName.localeCompare(b.countryName),
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: 'Price',
          //   dataIndex: 'cost',
          //   //fixed: 'right',
          //   width: 150,
          //   align:'center',
          //   sorter: (a, b) => a.cost - b.cost,
          //   sortDirections: ['descend', 'ascend'],
          //   ...getColumnSearchProps('cost'),

          // },
          {
            title: 'Final Destination',
            dataIndex: 'destination',
            //fixed: 'right',
            width: 140,
            align: 'left',
            ...getColumnSearchProps('destination'),
            sorter: (a, b) => a.destination != null ? a.destination.localeCompare(b.destination) : "Have Null Values",

            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'BAP Unit',
            dataIndex: 'unit',
            //fixed: 'right',
            width: 130,
            align: 'left',
            ...getColumnSearchProps('unit'),
            sorter: (a, b) => a.unit != null ? a.unit.localeCompare(b.unit) : "Have Null Values",
            sortDirections: ['descend', 'ascend'],
            // render: (text, record) => { return record.unit },

            // filters: [
            //   {
            //     text: 'Unit1',
            //     value: 1,
            //   },
            //   {
            //     text: 'Unit2',
            //     value: 2,
            //   },
            //   {
            //     text: 'Kakinada',
            //     value: 3,
            //   },
            // ],
            // filterMultiple: false,
            // onFilter: (value, record) => {
            //   // === is not work
            //   return record.unit === value;
            // },

          },
          {
            title: 'Prod Unit',
            dataIndex: 'processingUnitName',
            //fixed: 'right',
            width: 130,
            align: 'left',
            ...getColumnSearchProps('processingUnitName'),
            sorter: (a, b) => a.processingUnitName != null ? a.processingUnitName.localeCompare(b.processingUnitName) : "Have Null Values",
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: 'Category',
          //   dataIndex: 'product',
          //   width: 130,
          //   align: 'left',
          //   sorter: (a, b) => a.product.localeCompare(b.product),
          //   sortDirections: ['descend', 'ascend'],
          //   ...getColumnSearchProps('product'),

          // },
          {
            title: 'Is Invoiced?',
            dataIndex: 'isInvoiced',
            //fixed: 'right',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.isInvoiced.localeCompare(b.isInvoiced),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'YES',
                value: 'YES',
              },
              {
                text: 'NO',
                value: 'NO',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.isInvoiced === value;
            },

          },
          {
            title: 'Total Weight',
            dataIndex: 'totnetWeight',
            width: 130,
            align: 'right',
            ...getColumnSearchProps('totnetWeight'),
            render: (text, record) => { return record.totnetWeight != null ? (Math.round(record.totnetWeight).toLocaleString('en-IN',{minimumFractionDigits: 0, maximumFractionDigits: 0}) + " " + ((record.uom === 1 || record.uom === 4) ? "KG" : (record.uom === 2 || record.uom === 3) ? "LB" : "-")) : '-' },
            sorter: (a, b) => a.totnetWeight - b.totnetWeight,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Total Amount',
            dataIndex: 'cost',
            width: 120,
            align: 'right',
            ...getColumnSearchProps('cost'),
            render: (text, record) => { return record.cost != null ? (CrrencySymbols.find(item => item.id == record.currencyId).symbol + Math.round(record.cost).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3})) : "-" },
            sorter: (a, b) => a.cost - b.cost,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Current Status',
            dataIndex: 'lifeCyclestatus',
            width: 250,
            align: 'left',
            ...getColumnSearchProps('lifeCyclestatus'),
            render: (text, record) => {
              let color = 'black';
              let bckGrndColor = '#f6e552'
              if (text == SoLifeCycleStatusEnum.Risk1 || SoLifeCycleStatusEnum.RISK2) {
                bckGrndColor = '#e51515'
                color = 'white'
              }
              if (text == SoLifeCycleStatusEnum.CLOSED) {
                bckGrndColor = '#52c41a'
                color = 'blck'
              }
              return <Tag style={{ backgroundColor: bckGrndColor, color: color, fontWeight: 'bold' }}>{text}</Tag>
            },
          },

          {
            title: 'SO Status',
            dataIndex: 'status',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}

              </>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'FG Status',
            dataIndex: 'fgStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.fgStatus.localeCompare(b.fgStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Rm Status',
            dataIndex: 'rmStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.rmStatus.localeCompare(b.rmStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Production Status',
            dataIndex: 'productionStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.productionStatus.localeCompare(b.productionStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Is Dispatched?',
            dataIndex: 'dispatchStatus',
            //fixed: 'right',
            width: 150,
            align: 'left',
            render: (dispatchStatus, rowData) => (
              <>
                {dispatchStatus == true ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>YES</Tag> : dispatchStatus == false ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>NO</Tag> : ""}

              </>
            ),
            sorter: (a, b) => a.dispatchStatus - b.dispatchStatus,
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'YES',
                value: 1,
              },

              {
                text: 'NO',
                value: 0,
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.dispatchStatus === value;
            },
          },
        ]
      },
    ]
    const deletedAmountColumns: ColumnsType<any> = [

      {
        title: "",
        align: 'center',
        children: [

          // {
          //   title: 'Ordered Quantity',
          //   dataIndex: 'orderQty',
          //   //fixed: 'right',
          //   width: 150,
          //   align:'center',

          //   ...getColumnSearchProps('orderQty'),
          //   sorter: (a, b) => a.orderQty - b.orderQty,
          //   sortDirections: ['descend', 'ascend'],
          // },
          {
            title: 'Final Country',
            dataIndex: 'countryName',
            //fixed: 'right',
            width: 130,
            align: 'left',

            ...getColumnSearchProps('countryName'),
            sorter: (a, b) => a.countryName.localeCompare(b.countryName),
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: 'Price',
          //   dataIndex: 'cost',
          //   //fixed: 'right',
          //   width: 150,
          //   align:'center',
          //   sorter: (a, b) => a.cost - b.cost,
          //   sortDirections: ['descend', 'ascend'],
          //   ...getColumnSearchProps('cost'),

          // },
          {
            title: 'Final Destination',
            dataIndex: 'destination',
            //fixed: 'right',
            width: 140,
            align: 'left',
            ...getColumnSearchProps('destination'),
            sorter: (a, b) => a.destination != null ? a.destination.localeCompare(b.destination) : "Have Null Values",

            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'BAP Unit',
            dataIndex: 'unit',
            //fixed: 'right',
            width: 130,
            align: 'left',
            ...getColumnSearchProps('unit'),
            sorter: (a, b) => a.unit != null ? a.unit.localeCompare(b.unit) : "Have Null Values",
            sortDirections: ['descend', 'ascend'],
            // render: (text, record) => { return record.unit },

            // filters: [
            //   {
            //     text: 'Unit1',
            //     value: 1,
            //   },
            //   {
            //     text: 'Unit2',
            //     value: 2,
            //   },
            //   {
            //     text: 'Kakinada',
            //     value: 3,
            //   },
            // ],
            // filterMultiple: false,
            // onFilter: (value, record) => {
            //   // === is not work
            //   return record.unit === value;
            // },

          },
          {
            title: 'Prod Unit',
            dataIndex: 'processingUnitName',
            //fixed: 'right',
            width: 130,
            align: 'left',
            ...getColumnSearchProps('processingUnitName'),
            sorter: (a, b) => a.processingUnitName != null ? a.processingUnitName.localeCompare(b.processingUnitName) : "Have Null Values",
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: 'Category',
          //   dataIndex: 'product',
          //   width: 130,
          //   align: 'left',
          //   sorter: (a, b) => a.product.localeCompare(b.product),
          //   sortDirections: ['descend', 'ascend'],
          //   ...getColumnSearchProps('product'),

          // },
          {
            title: 'Is Invoiced?',
            dataIndex: 'isInvoiced',
            //fixed: 'right',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.isInvoiced.localeCompare(b.isInvoiced),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'YES',
                value: 'YES',
              },
              {
                text: 'NO',
                value: 'NO',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.isInvoiced === value;
            },

          },
          {
            title: 'Total Weight',
            dataIndex: 'totnetWeight',
            width: 130,
            align: 'right',
            ...getColumnSearchProps('totnetWeight'),
            render: (text, record) => { return record.totnetWeight != null ? (Math.round(record.totnetWeight).toLocaleString('en-IN',{minimumFractionDigits: 0, maximumFractionDigits: 0}) + " " + ((record.uom === 1 || record.uom === 4) ? "KG" : (record.uom === 2 || record.uom === 3) ? "LB" : "-")) : '-' },
            sorter: (a, b) => a.totnetWeight - b.totnetWeight,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Current Status',
            dataIndex: 'lifeCyclestatus',
            width: 250,
            align: 'left',
            ...getColumnSearchProps('lifeCyclestatus'),
            render: (text, record) => {
              let color = 'black';
              let bckGrndColor = '#f6e552'
              if (text == SoLifeCycleStatusEnum.Risk1 || SoLifeCycleStatusEnum.RISK2) {
                bckGrndColor = '#e51515'
                color = 'white'
              }
              if (text == SoLifeCycleStatusEnum.CLOSED) {
                bckGrndColor = '#52c41a'
                color = 'blck'
              }
              return <Tag style={{ backgroundColor: bckGrndColor, color: color, fontWeight: 'bold' }}>{text}</Tag>
            },
          },

          {
            title: 'SO Status',
            dataIndex: 'status',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}

              </>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'FG Status',
            dataIndex: 'fgStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.fgStatus.localeCompare(b.fgStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Rm Status',
            dataIndex: 'rmStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.rmStatus.localeCompare(b.rmStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Production Status',
            dataIndex: 'productionStatus',
            //fixed: 'right',
            width: 140,
            align: 'left',
            render: (status, rowData) => (
              <>
                {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : "-"}

              </>
            ),
            sorter: (a, b) => a.productionStatus.localeCompare(b.productionStatus),
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },

              {
                text: 'INPROGRESS',
                value: 'INPROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.status === value;
            },
          },
          {
            title: 'Is Dispatched?',
            dataIndex: 'dispatchStatus',
            //fixed: 'right',
            width: 150,
            align: 'left',
            render: (dispatchStatus, rowData) => (
              <>
                {dispatchStatus == true ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>YES</Tag> : dispatchStatus == false ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>NO</Tag> : ""}

              </>
            ),
            sorter: (a, b) => a.dispatchStatus - b.dispatchStatus,
            sortDirections: ['descend', 'ascend'],
            filters: [
              {
                text: 'YES',
                value: 1,
              },

              {
                text: 'NO',
                value: 0,
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.dispatchStatus === value;
            },
          },
        ]
      },
    ]
    const operationsColumns: ColumnsType<any> = [
      {
        title: "OPERATIONS",
        align: 'center',
        className: 'operations',

        children: [
          {
            title: 'Packing',
            dataIndex: 'packing',
            //fixed: 'right',
            width: 120,
            align: 'left',
            render(text, record) {
              return {
                props: {
                  // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
                },
                children: <a href={'#/allsaleorders-view'}>{record.packing}</a>
                // children: record.packing,
              };
            }
          },
          {
            title: 'Production Inventory',
            dataIndex: 'rmStock',
            //fixed: 'right',
            width: 110,
            align: 'left',

            render(text, record) {
              return {
                props: {
                  style: {
                    background: ((record.rmStock / record.orderQty) * 100) < 50 ? "#f4646c" : (((record.rmStock / record.orderQty) * 100) >= 50 && ((record.rmStock / record.orderQty) * 100) < 75) ? "#f8f838" : '#38f438',
                    color: ((record.rmStock / record.orderQty) * 100) < 50 ? "white" : (((record.rmStock / record.orderQty) * 100) >= 50 && ((record.rmStock / record.orderQty) * 100) <= 75) ? "black" : 'black'
                  }
                },
                children: record.rmStock,
              };
            }
            // render: (text, rowData) => (<>{rowData.rmStock+'/'+rowData.orderQty}</>),
          },
          {
            title: 'Production',
            dataIndex: 'prodStatus',
            //fixed: 'right',
            width: 130,
            align: 'left',
            filters: [
              {
                text: 'OPEN',
                value: 'OPEN',
              },
              {
                text: 'INPROGRESS',
                value: 'IN_PROGRESS',
              },
              {
                text: 'CLOSED',
                value: 'CLOSED',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.prodStatus === value;
            },
            render(text, record) {
              return {
                props: {
                  // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
                },
                children: <a href={'#/production-inventory-grid'}>{record.prodStatus}</a>
              };
            }

          },

          {
            title: 'Quality Assurance',
            dataIndex: 'qualityAssurance',
            //fixed: 'right',
            width: 130,
            align: 'left',
            render(text, record) {
              return {
                props: {
                  // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
                },
                children: <a href={'#/qa-qc-sample-test-grid'}>{record.qualityAssurance}</a>
                // children: <a href={'sale-order/' + text}>{text}</a>
              };
            }
          },
          {
            title: 'FG Stock (Cases)',
            dataIndex: 'fgStock',
            // //fixed: 'right',
            width: 90,
            align: 'left',
            render(value) { return value ? value : 0 }

          },
          
          {
            title: 'Documentation',
            dataIndex: 'doc',
            // //fixed: 'right',
            width: 135,
            align: 'left',
            render(text, record) {
              console.log(record);
              return {
                props: {
                  // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
                },
                children: <a href={'#/document-approval'}>{record.doc}</a>
                // children: <a href={'sale-order/' + text}>{text}</a>
              };
            }
          },
          {
            title: 'Logistics',
            dataIndex: 'logistics',
            // //fixed: 'right',
            width: 130,
            align: 'left',
            render(text, record) {
              return {
                props: {
                  // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
                },
                children: <a href={'#/container-register-grid'}>{record.logistics}</a>
              };
            }
          },
         
          {
            title: 'Job Type',
            dataIndex: 'jobType',
            //fixed: 'right',
            width: 120,
            align: 'left',
            filters: [
              {
                text: JobTypesEnum.MAIN_JOB,
                value: JobTypesEnum.MAIN_JOB,
              },
              {
                text: JobTypesEnum.SUB_JOB,
                value: JobTypesEnum.SUB_JOB,
              },
              {
                text: JobTypesEnum.NO_DOC,
                value: JobTypesEnum.NO_DOC,
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
              // === is not work
              return record.jobType === value;
            },
            render(text, record) {
              return <>
                {text == JobTypesEnum.MAIN_JOB ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.MAIN_JOB}</Tag> : text == JobTypesEnum.SUB_JOB ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.SUB_JOB}</Tag> : text == JobTypesEnum.NO_DOC ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.NO_DOC}</Tag> : ""}

              </>
            }

          },

        ]
      },
    ]
    const actionColumns: ColumnsType<any> = [
      {
        title: "Action",
        align: 'left',
        className: 'operations',
        fixed: 'right',
        children: [
          {
            title: '',
            dataIndex: 'action',
            fixed: 'right',
            width: 90,
            align: 'left',
            render: (text, rowData) => {
              let PoNumberCount = ((props.saleOrderTrackingReportData.filter(rec => (rec.poNumber == rowData.poNumber && rec.status != SoStatusEnum.CANCELLED)))).length
              return <span>
                {/* {(object) ?<Redirect
                    to={{
                      pathname: "/saleorder-form",
                      state: object
                    }}
                  />:null} */}
                {
                  (rowData.status != SoStatusEnum.CLOSED && rowData.status != SoStatusEnum.CANCELLED && PoNumberCount == 1) && ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.PLANT_SALES_MANAGER ||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER ||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.HO_PACKAGING_MANAGER||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.PACKAGING_SUPERVISOR|| (JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.SR_SALES_MANAGER) ?
                    <><Tooltip title='Edit'><EditOutlined className={'editSamplTypeIcon'} type="edit"
                      onClick={() => {

                        // if (rowData.isActive) {
                        setUpdatevalue(rowData.saleOrderId, JobTypesEnum.MAIN_JOB);
                        // } else {
                        //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                        // }
                      }}
                      style={{ color: '#1890ff', fontSize: '14px' }}
                    /></Tooltip>
                      <Divider type="vertical" />
                      {(rowData.soProcessType == SoProcessTypesEnum.MTO && rowData.status != SoStatusEnum.CANCELLED) ? PoNumberCount == 1 ? <Tooltip title='Sub Job'><SplitCellsOutlined className={'editSamplTypeIcon'} type="subPo"
                        onClick={() => {

                          // if (rowData.isActive) {
                          setUpdatevalue(rowData.saleOrderId, JobTypesEnum.SUB_JOB);
                          // } else {
                          //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                          // }
                        }}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      /></Tooltip> : <Tooltip title='Sub Job Done'><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> : <></>}
                      <Tooltip title='Transfer'><RotateRightOutlined className={'editSamplTypeIcon'} type="transfer"
                        onClick={() => {

                          // if (rowData.isActive) {
                          transferSo(rowData);
                          // } else {
                          //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                          // }
                        }}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      /></Tooltip>

                    </>
                    : null

                }
                <Divider type="vertical" />
                {(rowData.jobType == JobTypesEnum.MAIN_JOB && rowData.status != SoStatusEnum.CLOSED && rowData.status != SoStatusEnum.CANCELLED) && ((JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.PLANT_SALES_MANAGER||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.HO_PACKAGING_MANAGER||(JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.PACKAGING_SUPERVISOR|| (JSON.parse(localStorage.getItem('role'))) === EmployeeRolesEnum.SR_SALES_MANAGER) ?
                  <Tooltip title='Cancel'>
                    <Popconfirm title='Sure to cancel?' onConfirm={e => { soCancel(rowData.saleOrderId, rowData.poNumber, rowData.processingUnitId); }}>


                      <CloseOutlined className={'editSamplTypeIcon'} type="cancel"

                        style={{ color: '#1890ff', fontSize: '14px' }}
                      /> </Popconfirm></Tooltip> : null
                }
                {(rowData.jobType == JobTypesEnum.SUB_JOB && rowData.status != SoStatusEnum.CLOSED && rowData.status != SoStatusEnum.CANCELLED) ?
                  <Tooltip title='Reverse Sub Job'>
                    <Popconfirm title='Sure to Reverse?' onConfirm={e => { subJobReverse(rowData.saleOrderId, rowData.poNumber, rowData.processingUnitId); }}>
                      <DoubleLeftOutlined className={'editSamplTypeIcon'} type="cancel"
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      /> </Popconfirm></Tooltip> : null
                }
              </span>
            }
          },

        ]
      },
      // {
      //   title: `Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) => (
      //     <span>
      //       {(object) ?<Redirect
      //         to={{
      //           pathname: "/saleorder-form",
      //           state: object
      //         }}
      //       />:null}
      //       {rowData.status !="CLOSED"?
      //       <EditOutlined className={'editSamplTypeIcon'} type="edit"
      //         onClick={() => {

      //           // if (rowData.isActive) {
      //             setUpdatevalue(rowData);
      //           // } else {
      //           //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
      //           // }
      //         }}
      //         style={{ color: '#1890ff', fontSize: '14px' }}
      //       />:null
      //       }
      //     </span>
      //   )
      // }
    ]

    if (key === "1") {
      if((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.HO_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKAGING_SUPERVISOR)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKING_CAPTAINS)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER)) {
        return [...sampleTypeColumns, ...customerColumns, ...scheduledDatesColumns,      
          ...deletedAmountColumns, ...operationsColumns, ...actionColumns];
      }else{
        return [...sampleTypeColumns, ...customerColumns, ...scheduledDatesColumns,      
          ...destinationColumns,...operationsColumns, ...actionColumns];
      }
    }
    else if (key === "2") {
      if((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.HO_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKAGING_SUPERVISOR)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKING_CAPTAINS)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER)) {
        return [...sampleTypeColumns, ...customerColumns,      
          ...deletedAmountColumns, ...operationsColumns, ...actionColumns];
      }
      return [...sampleTypeColumns, ...customerColumns, ...destinationColumns, ...operationsColumns, ...actionColumns];
    }
    else if (key === "3") {
      if((JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.HO_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.ASSISTANT_PACKAGING_MANAGER)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKAGING_SUPERVISOR)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKING_CAPTAINS)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.PACKING_CAPTAINS)||(JSON.parse(localStorage.getItem('role')) === EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER)) {
        return [...sampleTypeColumns, ...customerColumns,      
          ...deletedAmountColumns, ...actionColumns];
      }
      return [...sampleTypeColumns, ...customerColumns, ...destinationColumns, ...actionColumns];
    }
  }

  const displayModel = (type, data) => {
    if (type == 'ReProcess') {
      setIsReProcess(true)
    } else {
      setIsReProcess(false)
    }
    setIsModalVisible(true);
    setSelectedSoItem(data);
  };

  const renderItems = (record: SaleOrderTrackDto, index, indent, expanded) => {
    console.log(record);
    return <SaleOrderItemsView saleOrderId={record.saleOrderId} currencyId={record.currencyId} poNumber={record.poNumber} />
  }



  //     </>
  //   );
  // }
  const setUpdatevalue = (saleOrderId: number, jobType: string) => {
    console.log(saleOrderId)
    setObject(saleOrderId)
    setJobType(jobType)
  }

  const setClonevalue = (saleOrderId: number) => {
    console.log(saleOrderId)
    setCloneObject(saleOrderId)
    setJobType(jobType)
  }

  const transferSo = (rowData: any) => {
    setSoData(rowData)
    setSoTransferModalVisible(true)

  }

  const subJobReverse = (saleOrderId: number, poNumber: string, plantId: number) => {
    saleService.subJobReverse(new SaleOrderIdRequest(saleOrderId, plantId, null, poNumber)).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        props.updateState(1)
      } else {
        AlertMessages.getErrorMessage('Something Went Wrong');
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  const soCancel = (saleOrderId: number, poNumber: string, plantId: number) => {
    console.log(saleOrderId)
    saleService.cancelSO(new SaleOrderIdRequest(saleOrderId, plantId, null, poNumber)).then((res) => {
      if (res.status) {

        AlertMessages.getSuccessMessage('SO Cancelled successfully');
        props.updateState(1)
        // <Route exact path="/saleorder-tracker" component={SaleOrderTrackingReportPages} />
        // history.push("/saleorder-tracker");
        // onReset();
      } else {
        AlertMessages.getErrorMessage('Something Went Wrong');
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });

  }
  const goToSaleDetailView = (saleOrderId: number,poNumber:string) => {
    setSaleId(saleOrderId);
    setPonumber(poNumber);
    console.log(saleOrderId);
  }
  const goToProcessDetails = (saleOrderId: number) => {
    setSaleIdForProcess(saleOrderId);
    console.log(saleOrderId);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTransferCancel = () => {
    setSoTransferModalVisible(false);
  }

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record.saleOrderId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    console.log(page, pageSize, '--pagination')
  }
  return (
    <>
      {(saleId) ? <Redirect to={`/saleorder-detail-view/${saleId}/${poNumber}`} /> : null}
      {(saleIdForProcess) ? <Redirect to={{ pathname: "/purchase-order-form", state: { id: saleIdForProcess, type: "Saleorder" } }} /> : null}
      {(object) ? <Redirect to={{ pathname: "/saleorder-form", state: object, key: jobType }} /> : null}
      {(cloneObject) ? <Redirect to={{ pathname: "/saleorder-form", state: cloneObject, key: 'Clone' }} /> : null}
      <br></br>


      <Card title={<span style={{ color: 'white' }}>Sale Order Details</span>}
        extra={<><span><Tag style={{ background: "#f4646c", color: 'white', height: 30, width: 81, fontSize: 14 }}>Below 50%</Tag></span>
          <span><Tag style={{ background: "#38f438", color: 'black', height: 30, width: 81, fontSize: 14 }}>Above 75%</Tag></span>
          <span><Tag style={{ background: "#f8f838", color: 'black', height: 30, width: 81, fontSize: 14 }}>Above 50%</Tag></span></>}
        style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

      >
        <br></br>
        <div className="card-container">
          <Tabs type={'card'} tabPosition={'top'} onChange={() => { setPageSize(10); setPage(1) }} >
            <TabPane
              key="1"

              tab={<span style={{ color: "#0ec92d" }}>{'Dispatch: ' + (props.saleOrderTrackingReportData.filter(el => el.soType == SoTypesEnum.DISPATCH && el.soProcessType == SoProcessTypesEnum.MTO && el.status != SoStatusEnum.CANCELLED).length)}</span>}
            >
              <Table
                // key = {updateState}
                rowKey={record => record.saleOrderId}
                columns={props.saleOrderTrackingReportData.length == 0 ? null : tableColumns("1")}
                dataSource={props.saleOrderTrackingReportData.filter(rec => rec.soType === SoTypesEnum.DISPATCH && rec.soProcessType === SoProcessTypesEnum.MTO && rec.status !== SoStatusEnum.CANCELLED)}
                expandedRowRender={renderItems}
                expandedRowKeys={expandedIndex}
                onExpand={setIndex}
                expandIconColumnIndex={6}
                pagination={{
                  onChange(current, pageSize) {
                    setExpandedIndex([]);
                    setPage(current);
                    setPageSize(pageSize)
                  }
                }}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered
              >
              </Table>
            </TabPane>
            <TabPane
              key="2"
              tab={<span style={{ color: "#c7a21b" }}>{'Inventory: ' + (props.saleOrderTrackingReportData.filter(el => el.soType == SoTypesEnum.STOCK && el.soProcessType == SoProcessTypesEnum.MTO && el.status != SoStatusEnum.CANCELLED).length)}</span>}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={props.saleOrderTrackingReportData.length == 0 ? null : tableColumns("2")}
                dataSource={props.saleOrderTrackingReportData.filter(rec => rec.soType === SoTypesEnum.STOCK && rec.soProcessType === SoProcessTypesEnum.MTO && rec.status !== SoStatusEnum.CANCELLED)}
                expandedRowRender={renderItems}
                expandedRowKeys={expandedIndex}
                onExpand={setIndex}
                expandIconColumnIndex={4}
                pagination={{
                  onChange(current, pageSize) {
                    setExpandedIndex([]);
                    setPage(current);
                    setPageSize(pageSize);
                  }
                }}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered
              >
              </Table>
            </TabPane>
            <TabPane
              key="3"
              tab={<span style={{ color: "#3e75f0" }}>{'Dummy: ' + (props.saleOrderTrackingReportData.filter(el => el.soProcessType == SoProcessTypesEnum.MTS && el.status != SoStatusEnum.CANCELLED).length)}</span>}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={props.saleOrderTrackingReportData.length == 0 ? null : tableColumns("3")}
                dataSource={props.saleOrderTrackingReportData.filter(rec => rec.soProcessType == SoProcessTypesEnum.MTS && rec.status != SoStatusEnum.CANCELLED)}
                expandedRowRender={renderItems}
                expandedRowKeys={expandedIndex}
                onExpand={setIndex}
                expandIconColumnIndex={3}
                pagination={{
                  onChange(current) {
                    setExpandedIndex([]);
                    setPage(current);
                    setPageSize(pageSize);
                  }
                }}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered
              >
              </Table>
            </TabPane>
            <TabPane
              key="4"
              tab={<span style={{ color: "#0ec92d" }}>{'Cancelled: ' + (props.saleOrderTrackingReportData.filter(el => el.status == SoStatusEnum.CANCELLED).length)}</span>}
            >
              <Table
                rowKey={record => record.saleOrderId}
                columns={props.saleOrderTrackingReportData.length == 0 ? null : tableColumns("1")}
                dataSource={props.saleOrderTrackingReportData.filter(rec => rec.status == SoStatusEnum.CANCELLED)}
                expandedRowRender={renderItems}
                expandedRowKeys={expandedIndex}
                onExpand={setIndex}
                expandIconColumnIndex={6}

                pagination={{
                  onChange(current) {
                    setExpandedIndex([]);
                    setPage(current);
                    setPageSize(pageSize);
                  }
                }}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered
              >
              </Table>
            </TabPane>
          </Tabs>
        </div>
      </Card>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(isReProcess) ? <ReProcess SoItemData={selectedSoItem} /> : <RePacking SoItemData={selectedSoItem} />}
      </Modal>
      <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={soTransferModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleTransferCancel}
        footer={[

        ]}
      >
        {<SOTransferForm closeModal={closeModal} soData={soData} />}
      </Modal>
    </>
  );
}

export default SaleOrderTrackingReportGrid;
