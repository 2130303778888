export class FarmersDropDownDto {
    supplierId: number;
    supplierCode: string;
    supplierName: string;
    address?:string;
    mobileNumber?:string;



    /**
     * 
     * @param supplierId number
     * @param supplierCode string
     * @param supplierName string
     */
    constructor(supplierId: number, supplierCode: string, supplierName: string, address?: string, mobileNumber?:string) {
        this.supplierId = supplierId;
        this.supplierCode = supplierCode;
        this.supplierName = supplierName;
        this.address = address;
        this.mobileNumber = mobileNumber;
    }
}