import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select, DatePicker } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { AllIndentReportDto, IndentRtRequest, IndentDropdownModel, RmIndentReportDto, IndentAbstractRequest } from '@gtpl/shared-models/raw-material-procurement';
import { CSVLink } from 'react-csv';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import './indent-report.css';

const { Option } = Select;

export interface IndentReportProps { }

export const IndentReport =(
  props: IndentReportProps
) =>{
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [indentsData, setIndentsData] = useState<RmIndentReportDto[]>([]);
  const [indentReportData, setIndentReportData] = useState<AllIndentReportDto[]>([]);
  const [display, setDisplay] = useState<string>('none');
  const service = new IndentService();


  function unique(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };




  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Indent By',
      dataIndex: 'indentBy',

      sorter: (a, b) => a.indentCode.localeCompare(b.indentBy),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentBy')
    },
    {
      title: '0-25',
      dataIndex: 'count25',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '30',
      dataIndex: 'count30',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '40',
      dataIndex: 'count40',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '50',
      dataIndex: 'count50',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '60',
      dataIndex: 'count60',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '70',
      dataIndex: 'count70',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '80',
      dataIndex: 'count80',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '90',
      dataIndex: 'count90',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '100',
      dataIndex: 'count100',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '110',
      dataIndex: 'count110',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '120',
      dataIndex: 'count120',
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '130',
      dataIndex: 'count130',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '>130',
      dataIndex: 'count140',
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Total',
    //   sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('minimumStock'),
    //   render: (text, record) => {
    //     let total = 0;
    //     for (const [key, value] of Object.entries(record)) {
    //       if (key.includes('count')) {
    //         total += Number(value);
    //       }
    //     }
    //     return total;
    //   }
    // },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const getReport = (values) => {
    setDisplay('block');
    const req = new IndentAbstractRequest(values.fromDate, values.toDate);
    service.getIndentAbstract(req).then(res => {
      if (res.status) {
        setIndentsData(res.data);
      } else {
        setIndentsData([])
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);

    })
  }
  const OpenFormTocreateRecord = () => {
    console.log('redirect here');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Indent</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
      <br></br>
      <Form form={form} layout={'vertical'} onFinish={getReport}>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="fromDate"
              label="Date From"
            >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="toDate"
              label="Date Upto"
            >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                showToday={true} />
            </Form.Item>
          </Col>

          <Col style={{ marginTop: '30px' }}>
            <Button style={{ marginRight: '4px' }} type="primary" htmlType="submit">Get Report</Button>
            <Button ><CSVLink filename={"IReport.csv"} data={indentReportData} >Get Excel </CSVLink></Button>
          </Col>

        </Row>

        <br /><br />
      </Form>
      <Table
        //rowKey={record => record.indent_by}
        columns={columnsSkelton}
        dataSource={indentsData}
        style={{ display: display }}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered />
    </Card>
  );
      }

export default IndentReport;



