import { Button, Space, Card, Select, Col, Row, Layout, Table, Empty, InputNumber, Tooltip, Divider, Descriptions,Typography } from 'antd';

import React, { useEffect, useState } from 'react';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {Link, useLocation} from 'react-router-dom';
import { RejectionDto, RejectionRequest} from '@gtpl/shared-models/production-management';

import { RejectionsService } from '@gtpl/shared-services/production';




import './qa-qc-sample-detail-view.css';

/* eslint-disable-next-line */
export interface QaQcSampleDetailViewProps {}

export function QaQcSampleDetailView(
  props: QaQcSampleDetailViewProps
) {
  
 
  const [qaQcDetailViewData, setQaQcDetailViewData] = useState<any>();
 
  const rejectionService = new RejectionsService;
  let location = useLocation();
  const rejectionId :any= location.state;
  
  useEffect(() => {
    if(rejectionId){
      getDetailViewById(rejectionId.rejectionId);
      console.log(rejectionId);
    }
  }, [rejectionId]);

  const getDetailViewById = (rejectionId) => {
    const req = new RejectionRequest();
    req.rejectionId =rejectionId;
   
    rejectionService.getDetailViewById(req).then((res) => {
      if (res.status) {
        console.log(res)
        setQaQcDetailViewData(res.data);
        
      } else {
        setQaQcDetailViewData([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setQaQcDetailViewData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <>
    {
      <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
        <Card title='QAQC Sample Detail View' style={{ textAlign: 'center'}} headStyle={{ backgroundColor: '#69c0ff',fontSize:'15px',color:'white'  }} extra={<Link to='/qa-qc-sample-test-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} size='small'> 
        { qaQcDetailViewData?<div>
          <Descriptions column={3}>
            <Descriptions.Item label="LOT Code">
              {qaQcDetailViewData[0].lotNumber?qaQcDetailViewData[0].lotNumber:'-'}
            </Descriptions.Item>
            <Descriptions.Item label="Product">
            {qaQcDetailViewData[0].product?qaQcDetailViewData[0].product:''}
            </Descriptions.Item>
            <Descriptions.Item label="Po Number">
              {qaQcDetailViewData[0].PoNumber?qaQcDetailViewData[0]?.PoNumber:''}
            </Descriptions.Item>
           
            <Descriptions.Item label="Shift">
              {qaQcDetailViewData[0].shift?qaQcDetailViewData[0].shift:''}
            </Descriptions.Item>
            <Descriptions.Item label="Operation">
              {qaQcDetailViewData[0].operation?qaQcDetailViewData[0].operation:''}
            </Descriptions.Item>
            <Descriptions.Item label="Workstation">
              {qaQcDetailViewData[0].workstationName?qaQcDetailViewData[0].workstationName:''}
            </Descriptions.Item>
            <Descriptions.Item label="Test">
              {qaQcDetailViewData[0].testName?qaQcDetailViewData[0].testName:''}
            </Descriptions.Item>
            <Descriptions.Item label="Samples">
              {qaQcDetailViewData[0].samples?qaQcDetailViewData[0].samples:''}
            </Descriptions.Item>
            <Descriptions.Item label="Quantity Tested">
              {qaQcDetailViewData[0].quantity?qaQcDetailViewData[0].quantity:''}
            </Descriptions.Item>
            <Descriptions.Item label="Rejected Quantity">
              {qaQcDetailViewData[0].rejectionQuantity?qaQcDetailViewData[0].rejectionQuantity:''}
            </Descriptions.Item>
            <Descriptions.Item label="Rejection Reason">
              {qaQcDetailViewData[0].rejectionReason?qaQcDetailViewData[0].rejectionReason:''}
            </Descriptions.Item>
            <Descriptions.Item label="Test Result">
              {qaQcDetailViewData[0].testResult?qaQcDetailViewData[0].testResult:''}
            </Descriptions.Item>
           
            
          </Descriptions>

          </div>:<div></div>}
        </Card><br></br>
      </Layout>
  }
</>
  );
}

export default QaQcSampleDetailView;
