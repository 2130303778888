export class ExpensesSubCategoryDto {
    expensesSubCategoryId?: number;
    expensesSubCategory: string;
    expensesCategoryId?: number;
    expensesCategory: string
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(expensesSubCategoryId: number, expensesSubCategory: string, expensesCategoryId : number,expensesCategory: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.expensesSubCategoryId = expensesSubCategoryId;
        this.expensesSubCategory = expensesSubCategory;
        this.expensesCategoryId = expensesCategoryId;
        this.expensesCategory = expensesCategory;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}