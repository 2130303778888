import axios from 'axios';
import connection from './connection';
import { ItemsDto, ItemResponse, AllItemResponse, ItemsDropDownResponseModel, ItemRequest, ItemsDropDownDto, ItemDetailsResponse, ItemsReqForCategoryId, BomItemRequest, RmBomItemRequest, GetItemForProductRequest, ItemsReqForSubCategoryId, ItemForBrandReq, ItemCodeRequest, TrimsRequest, TrimsMappingRequset, TrimsItemMappingRequest, TrimsConfigurationDTO } from '@gtpl/shared-models/masters'
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { CommonResponse } from '@gtpl/shared-models/production-management';
export class TrimsMappingService {
    URL = connection.DEPLOY_URL + '/trims-mapping';
    async getTrimsData(trimsReq: TrimsMappingRequset): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTrimsData', trimsReq)
            .then(res => {
                return res.data
            })
    }
    async getalltrimsmappingdetails(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getalltrimsmappingdetails')
            .then(res => {
                return res.data
            })
    }
    async getalltrimsitemmappingdetailsByTrimsId(req: TrimsItemMappingRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getalltrimsitemmappingdetailsByTrimsId', req)
            .then(res => {
                return res.data
            })
    }

    async saveTrimsConfiguration(req: TrimsConfigurationDTO): Promise<CommonResponse> {
        return await axios.post(this.URL + '/saveTrimsConfiguration', req)
            .then(res => {
                return res.data
            })
    }
    
    async getAllTrimsDataForTrimId(req: TrimsItemMappingRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllTrimsDataForTrimId', req)
            .then(res => {
                return res.data
            })
    }
}