export class ValAddOpModel {
    sequence: number;
    operationId: number;
    operationName: string;
    VarientOperationId: number;
    unitId:number

    /**
     * 
     * @param sequence 
     * @param operationId 
     * @param operationName 
     * @param VarientOperationId 
     * @param unitId 
     */

    constructor(
        sequence: number,operationId:number,operationName:string,unitId:number,VarientOperationId?: number) {
        this.sequence = sequence;
        this.operationId = operationId;
        this.operationName = operationName;
        this.unitId = unitId;
        this.VarientOperationId  = VarientOperationId;
    }
}

