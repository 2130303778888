
import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ContainerDetailsModel } from "./container-details.model";

export class ContainerDetailsResponse extends GlobalResponseObject{
    data?: ContainerDetailsModel;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data string
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ContainerDetailsModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}