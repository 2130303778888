import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class VariantStatusWiseResponseModel extends GlobalResponseObject {
    active?: number;
    inActive?: number;
    total?: number;
    constructor(status: boolean, intlCode: number, internalMessage: string, active?: number,
        inActive?: number,
        total?: number,) {
        super(status, intlCode, internalMessage);
        this.active = active;
        this.inActive = inActive;
        this.total = total;
    }
}
