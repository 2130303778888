export class EmployeeTeamDto{
    id:number;
    employeeCode:string;
    employeeName:string;
    shiftCode:string;
    workStationTeam:string;
    isActive:boolean;
    createdAt:Date;
    createdUser:string;
    updatedUser:string;
    updatedAt:Date;
    versionFlag:number;


    /**
     * 
     * @param id 
     * @param employeeCode 
     * @param employeeName 
     * @param shiftCode 
     * @param workStationTeam
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedUser 
     * @param updatedAt 
     * @param versionFlag 
     */

    constructor(id:number,employeeCode:string,employeeName:string,shiftCode:string,workStationTeam:string, isActive:boolean,createdAt:Date,
createdUser:string,updatedUser:string,updatedAt:Date,versionFlag:number){

             this.id = id;
             this.employeeCode=employeeCode;
             this.employeeName = employeeName;
             this.shiftCode = shiftCode;
             this.workStationTeam = workStationTeam;
             this.isActive = isActive;
             this.createdAt = createdAt;
             this.createdUser = createdUser;
             this.updatedAt = updatedAt;
             this.updatedUser = updatedUser;
             this.versionFlag = versionFlag;

    }
}