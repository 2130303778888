export class DPReportRequest {

    unitId?: number;
    zoneId?: number;
    masterBrandId?: number;
    skuCodeId?: number;
    shift?: any;
    stockType?: string;
    fromDate?: string;
    toDate?: string;
    foodTypeId?:number;

}