import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { EndCustomersDropDownData } from './end-customer-drop-down-model';

export class EndCustomersDropDownDataResponseModel extends GlobalResponseObject{
    data?: EndCustomersDropDownData;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //EndCustomersDropDownData[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: EndCustomersDropDownData) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}