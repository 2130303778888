
import axios from 'axios';
import { DestinationResponseModel, AllDestinationsResponseModel, DestinationDto,DestinationRequest,DestinationDropDownDataResponseModel, DestinationDropDownData, CountryRequest} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class DestinationService {
  // activatedeActivate(DestinationData: DestinationDto[]) {
  //   throw new Error('Method not implemented.');
  // }
  // create(destinationData: DestinationDto) {
  //   throw new Error('Method not implemented.');
  // }
URL = connection.DEPLOY_URL + '/destination';

  async createDestination(destination): Promise<DestinationResponseModel> {
    // console.log(destination);
      return await axios.post(this.URL + '/createDestination',destination)
          .then(res => {
              return res.data
          })
  }
  async  updateDestination(destination: DestinationDto): Promise<DestinationResponseModel> {

    // console.log(destination)
    return await axios.post(this.URL + '/updateDestination', destination)
           .then(res => {
               return res.data
           })
}
  async getAllDestination(req?:UserRequestDto): Promise<AllDestinationsResponseModel> {
    return await axios.post(this.URL + '/getAllDestinations',req)
        .then(res => {
            return res.data
        })     
}
async  ActivateorDeactivateDestination(destinationDto: DestinationDto): Promise<AllDestinationsResponseModel> {
      console.log(destinationDto);
      return await axios.post(this.URL + '/activateorDeactivateDestination', destinationDto)
              .then(res => {
                  return res.data
              })
 }
async  getDestinationById(destinationRequest: DestinationRequest): Promise<DestinationResponseModel> {
      return await axios.post(this.URL + '/getDestinationById', destinationRequest)
              .then(res => {
                  return res.data
              })
 }
  async  getAllActiveDestination(): Promise<AllDestinationsResponseModel> {
    return await axios.post(this.URL + '/getAllActiveDestinations')
        .then(res => {
            return res.data
        })
}
  async getActivedestinations(){
      return new DestinationDropDownDataResponseModel(true,11,'customersData retrived successfully',[new DestinationDropDownData(1,'country1','fin dest1'),new DestinationDropDownData(2,'country2','fin dest2')])
  }

  async  getDestinationsByCountry(countryReq:CountryRequest): Promise<AllDestinationsResponseModel> {
    return await axios.post(this.URL + '/getDestinationsByCountry',countryReq)
        .then(res => {
            return res.data
        })
}

  



} 