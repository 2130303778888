import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Select, Input, DatePicker, Table, InputNumber, Descriptions } from 'antd';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { ContainerRegisterService } from '@gtpl/shared-services/logistics';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FactoriesInput, SaleOrderDropDownDto, SaleOrderItemsDTO, SOItemsDto } from '@gtpl/shared-models/sale-management';
import moment from 'moment';
import { ContainerStatusInfo } from '@gtpl/shared-models/logistics';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, RedoOutlined, RotateRightOutlined } from '@ant-design/icons';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { WorkStatusDto } from '@gtpl/shared-models/production-management';
import { ValueAdditionService } from '@gtpl/shared-services/production';
/* eslint-disable-next-line */
export interface ReportingSubPlantFormProps {
    subPlantData: WorkStatusDto,
    jobCompletedStatus : (workstatusDto: WorkStatusDto) => void
}
const { Option } = Select;
export function ReportingSubPlantForm(
    props: ReportingSubPlantFormProps
) {

    let location = useLocation();
    const containerStatusInfo: ContainerStatusInfo | any = location.state;

    const history = useHistory();

    const [ReportingSubPlantForm] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([]);
    const [saleOrderItemsData, setSaleOrderItemsData] = useState<SaleOrderItemsDTO[]>([]);
    const [keyUpdate, setKeyUpdate] = useState<number>(1);
    const [updateStateKey, setUpdateStateKey] = useState<number>(1);
    const [rejectedQty, setRejectedQty] = useState<number>(0);
    const [opQty, setOpQty] = useState<number>(0);
    const valueAdditionService = new ValueAdditionService();

    useEffect(() => {
        ReportingSubPlantForm.setFieldsValue({ boxes: props.subPlantData.opBoxes })
    }, [props.subPlantData]);


    const calculateData = () => {
        const rejectedQty = ReportingSubPlantForm.getFieldValue('rejectedQuantity');
        setRejectedQty(rejectedQty);
        setOpQty(props.subPlantData.opQuantity - rejectedQty)
        props.subPlantData.newOpQuantity = props.subPlantData.opQuantity - rejectedQty;
        props.subPlantData.rejectedQuantity = rejectedQty;
        props.subPlantData.rejectionReason = ReportingSubPlantForm.getFieldValue('rejectionReason');
    }

    const onFinish = () => {
        ReportingSubPlantForm.validateFields().then(rejForm => {
            props.jobCompletedStatus(props.subPlantData);
        })
    }

    return (
        <Card title={<span style={{ color: 'white' }}>Rejections</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
            <Form layout="vertical" form={ReportingSubPlantForm} name="control-hooks">
                <Row gutter={24}>

                    <Col>
                        <Descriptions column={4}>
                            <Descriptions.Item label="Plant">
                                {FactoriesInput.map((unit) => {
                                    if (unit.id == props.subPlantData.plantId) {
                                      return unit.value
                                    }
                                  })}
                            </Descriptions.Item>
                            <Descriptions.Item label="SaleOrder">
                                {props.subPlantData.soNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Job Number">
                                {props.subPlantData.jobNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Lot Number">
                                {props.subPlantData.soNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="I/P Boxes">
                                {props.subPlantData.ipBoxes}
                            </Descriptions.Item>
                            <Descriptions.Item label="O/P Boxes">
                                {props.subPlantData.opBoxes}
                            </Descriptions.Item>
                            <Descriptions.Item label="I/P QTY">
                                {props.subPlantData.ipQuantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="O/P QTY">
                                {props.subPlantData.opQuantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="Wastage QTY">
                                {Number(props.subPlantData.wastageQuantity)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Rejected QTY">
                                {rejectedQty}
                            </Descriptions.Item>
                            <Descriptions.Item label="Actual O/P QTY">
                                {(props.subPlantData.newOpQuantity) ? props.subPlantData.newOpQuantity : 0}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="boxes" label="O/P Boxes" rules={[{ required: true, message: 'Missing O/P Boxes' }]}>
                            <InputNumber style={{ width: '100%' }} min={0} disabled />
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="rejectedQuantity" label="Rejected Quantity" rules={[{ required: true, message: 'Missing Rejected Quantity.' }, { pattern: new RegExp('^[0-9]*$'), message: 'only numbers are allowed!.' }, {
                            validator: (_, value) =>
                                value <= props.subPlantData.opQuantity ? (value == 0) ? Promise.reject(new Error('Rejected Qty should be > 0')) : Promise.resolve() : Promise.reject(new Error('Quantity should be <= output qty ' + props.subPlantData.opQuantity)),
                        }]}>

                            <InputNumber style={{ width: '100%' }} min={0} onChange={calculateData} />
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (rejectedQty > 0) ? 'block' : 'none' }}>

                        <Form.Item name="rejectionReason" label="Rejection Reason" rules={[{ required: true, message: 'Missing Rejection Reason' }]}>
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={5} style={{ textAlign: 'right', paddingTop: '23px' }}>
                        <Form.Item >
                            <Button type='primary' block onClick={onFinish}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
}

export default ReportingSubPlantForm;
