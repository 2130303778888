import connection from "./connection";
import axios from 'axios';
import { CommonResponse } from "@gtpl/shared-models/masters";
import { AssetLicenseDto } from 'libs/shared-models/asset-management/src/lib/asset-license/asset-license.dto';
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";


export class AssetLicenseService {
    URL = connection.DEPLOY_URL + '/asset_license';

    async createAssetLicense(req: AssetLicenseDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetLicense',req)
        .then(res => {
            return res.data;
        })
    }
    async getAllAssetsLicense(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsLicense',req)
        .then(res => {
            return res.data;
        })
    }
    async getAllHistoryLicense(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllHistoryLicense',req)
        .then(res => {
            return res.data;
        })
    }
    
    async updateLicense(req: AssetLicenseDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateLicense',req)
        .then(res => {
            return res.data;
        })
    }
    
    async activateorDeactivateLicense(req: AssetLicenseDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/activateorDeactivateLicense',req)
        .then(res => {
            return res.data;
        })
    }

    
async fileUpload(file: any): Promise<CommonResponse> {
    console.log(file)
    return await axios.post(this.URL + '/fileUpload', file)
        .then(res => {
            return res.data
        })
}
}