import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Modal} from 'antd';
import {ValAddSoakingDto} from '@gtpl/shared-models/production-management';
import {ValueAdditionService} from '@gtpl/shared-services/production';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';

import './val-addition-soaking-grid.css';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 


/* eslint-disable-next-line */
export interface ValAdditionSoakingGridProps {}

export function ValAdditionSoakingGrid(
  props: ValAdditionSoakingGridProps
) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [saleId, setSaleId] = useState(0);
  const [valAdditionData, setValAdditionData] = useState<ValAddSoakingDto>();
  const valAdditonService = new ValueAdditionService();

  useEffect(() => {
    getAllValAdditionInventories();
  },[]);

  const getAllValAdditionInventories = () => {
    const unitId = Number(localStorage.getItem('unit_id'));
    valAdditonService.valAdditionsReportedData(new UnitRequest(unitId)).then(res => {
      if(res.status){
        setValAdditionData(res.data);
      }else{
        if(res.intlCode){
          setValAdditionData(null);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(error => {
      setValAdditionData(null);
      AlertMessages.getErrorMessage(error.message);
    })
  }
  
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
  const closeDrawer=()=>{
      setIsModalVisible(false);
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    getAllValAdditionInventories();
    setIsModalVisible(false);
  };



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive:['md'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNo',
      responsive: ['lg'],
      sorter: (a, b) => a.lotNo?.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNo')
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.gridData?.soNumber.localeCompare(b.gridData?.soNumber),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record) => 
    //   <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.soNumber}</Text>
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      responsive: ['lg'],
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record) => 
      <Text  onClick={e => goToSaleDetailView(record.saleOrderId)} > {record.poNumber}</Text>
     
    },
    {
      title: 'Varient Code',
      dataIndex: 'variantCode',
      responsive:['md'],
      sorter: (a, b) => a.variantCode?.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    },
    {
      title: 'Soaking Style',
      dataIndex: 'soakingStyle',
      responsive: ['lg'],
      sorter: (a, b) => a.soakingStyle?.localeCompare(b.soakingStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soakingStyle')
    },
    {
      title: 'Soaking Time (Min)',
      dataIndex: 'soakingTime',
      // responsive: ['lg'],
      ...getColumnSearchProps('soakingTime'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      },
      // responsive: ['lg'],
      sorter: (a, b) => a.quantity?.localeCompare(b.quantity),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Issued Quantity',
      dataIndex: 'issuedQuantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      // responsive: ['lg'],
    },
  ];
 
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <>
    <Card title={<span style={{color:'white'}}>Ready for Soaking Inventory</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
    <br></br>
    <Row gutter={40}>
        <Col>
          <Card
            title={'No. of Lots Available : ' + (valAdditionData?.gridData.length )}
            style={{
              textAlign: 'left',
              width: 300,
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity To be Processed : ' + Number(Math.round((valAdditionData?.qtytobeprocessed) ? (valAdditionData?.qtytobeprocessed) : 0))}
            style={{
              textAlign: 'left',
              width: 320,
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Value Addition Inventory : ' + Math.round(valAdditionData?.valAddInventory ? valAdditionData?.valAddInventory : 0)}
            style={{
              textAlign: 'left',
              width: 320,
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
          <br></br>
    {/* <Descriptions column={3}>
    <Descriptions.Item label="No. of Lots Available">
                {valAdditionData?.numOfLots}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity to be Processed">
                {valAdditionData?.qtytobeprocessed}
              </Descriptions.Item>
              <Descriptions.Item label="Value Addition Inventory">
                {valAdditionData?.valAddInventory}
              </Descriptions.Item>
              
            </Descriptions>
            <br/><br/><br/> */}
            <Card title={<span style={{textAlign: 'center'}}> Ready For Soaking Inventory</span>}
            style={{height:'60px'}} headStyle={{border: 0 }} extra={<Link to='/soaking-assignment-form' ><span ><Button className='panel_button' style = {{backgroundColor:'seagreen', color: 'white'}}>Assign for Soaking </Button></span></Link>} ></Card>
          <br/><br/> 
          <Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={valAdditionData?.gridData}
          scroll={{ y: 500 }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalQuantity = 0;
          
            pageData.forEach(({ quantity }) => {
              totalQuantity += Number(quantity);
              
            });

            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={2} colSpan={6} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}><Text  style={{textAlign:'end'}}>{totalQuantity}</Text></Table.Summary.Cell>
                </Table.Summary.Row>
                
              </>
            );
          }
         } />
    </Card>

        <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
        >
        <SoInfoViewPages saleOrderId={saleId} screen='production'
          />
        </Modal>


</>
  );
}

export default ValAdditionSoakingGrid;
