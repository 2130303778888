import { GlobalStatus, JobTypesEnum } from "@gtpl/shared-models/common-models";

export class SoInvoiceDetailsInfo {
    invDetailsId?:number
    saleOrderId: number;
    poNumber: string;
    buyerPoNumber: string;
    invoiceDate: any;
    edaDate: any;
    edlDate: any;
    cusAddress: string;
    delAddress: string;
    deliveryTerms: number;
    endCustomerId: number;
    customerId: number;
    country: number;
    destination: number;
    payTermId: number;
    shipmentTerms: number;
    salePersonId:number;
    portOfEntry:number;
    currencyId:number;
    unit:number;
    category?: string;
    currency?:number;
    actualEdldate?: any;
    jobType?: JobTypesEnum;
    processingUnit?: number;
    isSubContract?: GlobalStatus;



    /**
     * @param invDetailsId
    * @param saleOrderId
    * @param poNumber
    * @param invoiceDate
    * @param edaDate
    * @param edlDate
    * @param cusAddress
    * @param delAddress
    * @param deliveryTerms
    * @param endCustomerId
    * @param CustomerId
    * @param country
    * @param destination
    * @param payTermId
    * @param shipmentTerms
    * @param salePersonId
    * @param portOfEntry
    * @param currencyId
    * @param unit
    * @param category
    * @param jobType
    * @param processingUnit
     */

    
    constructor(invDetailsId:number,saleOrderId: number, poNumber: string, invoiceDate: Date, edaDate: Date, edlDate: Date, cusAddress: string, delAddress: string, deliveryTerms: number, endCustomerId: number, customerId: number, country: number, destination: number, payTermId: number, shipmentTerms: number, salePersonId:number,portOfEntry:number,currencyId:number,unit:number,category?:string,actualEdldate?:Date,jobType?: JobTypesEnum,processingUnit?: number,isSubContract?: GlobalStatus) {
        this.invDetailsId = invDetailsId;
        this.saleOrderId = saleOrderId;
        this.poNumber = poNumber;
        this.invoiceDate = invoiceDate;
        this.edaDate = edaDate;
        this.edlDate = edlDate;
        this.cusAddress = cusAddress;
        this.delAddress = delAddress;
        this.deliveryTerms = deliveryTerms;
        this.endCustomerId = endCustomerId;
        this.customerId = customerId;
        this.country = country;
        this.destination = destination;
        this.payTermId = payTermId;
        this.shipmentTerms = shipmentTerms;
        this.salePersonId = salePersonId;
        this.portOfEntry = portOfEntry;
        this.currencyId = currencyId;
        this.unit = unit;
        this.category = category;
        this.actualEdldate = actualEdldate;
        this.jobType = jobType;
        this.processingUnit = processingUnit;
        this.isSubContract = isSubContract;
    }
}


