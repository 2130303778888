import { CardBodyDetails } from "./card-body";
import { CardHeader } from "./card-header";

export class CardDetail {
    carHeader?: CardHeader;
    cardBody?: CardBodyDetails[];
    tableHeaders?: string[];
    cardHeaderRequired?:boolean

    constructor(carHeader?: CardHeader, cardBody?: CardBodyDetails[], tableHeaders?: string[],cardHeaderRequired?:boolean) {
        this.carHeader = carHeader;
        this.cardBody = cardBody;
        this.tableHeaders = tableHeaders;
        this.cardHeaderRequired = cardHeaderRequired
    }
}

