import { UserRequestDto } from "@gtpl/shared-models/common-models";
import { ShiftDto, ShiftReq, ShiftRequest, ShiftResponseModel } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class ShiftService {

    URL = connection.DEPLOY_URL + '/shifts';

    async createShift(data: ShiftDto): Promise<ShiftResponseModel> {
        return await axios.post(this.URL + '/createShift', data)
            .then(res => {
                return res.data
            })
    }

    async updateShift(data: ShiftDto): Promise<ShiftResponseModel> {
        return await axios.post(this.URL + '/updateShift', data)
            .then(res => {
                return res.data
            })
    }

    async getAllShiftActions(req?:UserRequestDto): Promise<ShiftResponseModel> {

        return await axios.post(this.URL + '/getAllShiftActions',req)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveShiftActions(): Promise<ShiftResponseModel> {
        return await axios.post(this.URL + '/getAllActiveShiftActions')
            .then(res => {
                return res.data
            })
    }

    async getShiftByShift(req: ShiftReq): Promise<ShiftResponseModel> {
        return await axios.post(this.URL + '/getShiftByShift', req)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateShift(data: ShiftRequest): Promise<ShiftResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateShift', data)
            .then(res => {
                return res.data
            })
    }
}