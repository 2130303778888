import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, Row, Select, Table, Tooltip } from 'antd';
import { GrnService, PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';




const { Option } = Select;

import './grn-form.css';
import { GrnDetailViewModel, PoDataModel, PoDropDownDto, PoItemsDataModel, PoRequest } from '@gtpl/shared-models/procurement-management';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { Link } from 'react-router-dom';
import { RackService, ZoneService } from '@gtpl/shared-services/masters';
import { RackDropDownDto, VendorDropDownDto, ZoneDto } from '@gtpl/shared-models/masters';
import {  VendorsService } from '@gtpl/shared-services/masters';
import { BomIdsRequest } from '@gtpl/shared-models/sale-management';


/* eslint-disable-next-line */
export interface GrnDetailsFormProps {
    form: FormInstance<any>
    poDetails: (poData: any) => void;

    isUpdate: any;
    grnDetails: GrnDetailViewModel;
    totalCost:number;


}


export function GrnDetailsForm(
    props: GrnDetailsFormProps
) {
    const form = props.form;

    const [poNumbers, setPoNumbers] = useState<PoDropDownDto[]>([]);
    const [poDetails, setPoDetails] = useState<PoItemsDataModel[]>([]);
    const [zoneDropDown, setZoneDropDown] = useState<ZoneDto[]>([]);
    const [rackPostionsDropDown, setRackPostionsDropDown] = useState<RackDropDownDto[]>([]);

    const grnService = new GrnService();
    const [showTable, setShowTable] = useState<boolean>(false);
    const [isPoDisabled, setIsPoDisabled] = useState<boolean>(false);
    const [keyUpdate, setkeyUpdate] = useState<number>(1);
    const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([]);
    const poService = new PurchaseOrderService();
    const zoneService = new ZoneService();
     const rackService = new RackService();
    const vendorService = new VendorsService;



    // const [form] = Form.useForm();

    useEffect(()=> {
        if(form.getFieldValue('vendorName')){
            getPoNumbers(form.getFieldValue('vendorName'))
        }
        getAllVendors();
    },[])

    useEffect(() => {
        getZones()
        if(props.totalCost){
            console.log(props.totalCost)
            form.setFieldsValue({totalCost:props.totalCost})
        }

        if (props.isUpdate) {
            setIsPoDisabled(true);
            if (props.grnDetails) {
                console.log(props.isUpdate)
                console.log(props.grnDetails)
                const initialdata = props.grnDetails
                if(initialdata){
                    initialdata.InvoiceDate = moment(initialdata.InvoiceDate, 'YYYY-MM-DD')
                    initialdata.grnDate = moment(initialdata.grnDate, 'YYYY-MM-DD')
                    
    
                    // form.resetFields();
                    form.setFieldsValue({
                        poId:initialdata.poId,
                        invoiceDate:initialdata.InvoiceDate,
                        grnDate:initialdata.grnDate,
                        invoiceNumber:initialdata.invoiceNumber,
                        vendorName:initialdata.vendor.vendorName,
                        contactPerson:initialdata.vendor.contactPerson,
                        mobileNumber:initialdata.vendor.contactNumber,
                        totalCost:initialdata.totalCost,
                        remarks:initialdata.remarks,
                        address:initialdata.vendor.apartment + ',' + initialdata.vendor.street + ',' + initialdata.vendor.city + ',' + initialdata.vendor.postalCode,
                    })
                    setkeyUpdate(keyUpdate+1);
                }

                console.log(form.getFieldsValue());
            }
            // console.log(props.grnId)
            // getGrnData(props.grnId);

        } else {

        }
    }, [form])

    // To get Vendors
  const getAllVendors = () => {
    vendorService.getVendorsDropDownData().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setVendorData(res.data);
      } else {
        if (res.intlCode) {
          setVendorData([]);
        } else {
          setVendorData([]);
        }
      }
    }).catch(err => {
      setVendorData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
    const getPoData = (val) => {
        if(val){
            poService.getPoDataById(new PoRequest(val)).then((res) => {
                console.log(res.data);
    
                if (res.status) {
                    form.setFieldsValue({
                        vendorId: res.data.vendor.vendorId,
                        // vendorName: res.data.vendor.vendorName,
                        contactPerson: res.data.vendor.contactPerson,
                        mobileNumber: res.data.vendor.contactNumber,
                        address: res.data.vendor.apartment + ',' + res.data.vendor.street + ',' + res.data.vendor.city + ',' + res.data.vendor.postalCode,
                        totalCost: res.data.totalCost,
                        saleOrderId:res.data.saleOrderId
                    })
    
                    // console.log(res.data);
                    // setPoDetails(res.data.poCreationItemsInfo);
                    props.poDetails(res.data)
                    setShowTable(true);
                } else {
    
                    setPoDetails([]);
                }
            }).catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                setPoDetails([]);
            });
        }

    }



    const getPoNumbers = (val) => {
        const req = new BomIdsRequest();
    req.packingMaterialId = undefined;
    req.vendorId = val;
    console.log(req)
        poService.getPoNumbersByVendor(req).then((res) => {
            console.log('test')

            if (res.status) {
                // console.log(res.data);
                setPoNumbers(res.data);
            } else {

                setPoNumbers([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setPoNumbers([]);
        });
    }

    const dateValidator = () => { 
      if(form.getFieldValue('invoiceDate')){
        const gndate = moment(form.getFieldValue('grnDate')).format('YYYY-MM-DD')
        const invoiceDate = moment(form.getFieldValue('invoiceDate')).format('YYYY-MM-DD')
        if(moment(gndate).isSameOrAfter(invoiceDate)){
            console.log((moment(gndate).isSameOrAfter(invoiceDate)),'date-validator')
            return
        }else{
            AlertMessages.getErrorMessage('GRN date  must be after Invoice date');form.setFieldsValue({grnDate:''})}
        }
    }

    const resetAll = () => {
        form.setFieldsValue({grnDate:null,contactPerson:null,mobileNumber:null,address:null,totalCost:null})
        props.poDetails(null)
        setShowTable(false);
    };

    const getZones = () => {
        zoneService.getPlantZones({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
          if (res.status) {
            console.log(res.data);
            setZoneDropDown(res.data);
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
            setZoneDropDown([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setZoneDropDown([]);
        });
      }

      const handleColdStorage = (value) => {
        getRacks(value);
    
      }

      const getRacks = (value) => {
        rackService.getRacksForZoneId({zoneId:value}).then(res => {
          if(res.status){
            setRackPostionsDropDown(res.data);
          }
          else{
            setRackPostionsDropDown([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setRackPostionsDropDown([]);
        });
      }



    return (
        <>
         <Card size="small" title={<span style={{ color: 'white' }} >GRN</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/grn-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
            {/* <Card title={'GRN'} size="small"> */}
                <Form layout={"vertical"} autoComplete="off" form={form} >
                    <Row gutter={24}>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
                       
                            <Form.Item name="vendorName" label="Vendor" rules={[{ required: true,message:'Missed Vendor' }]} >
                            <Select onChange={getPoNumbers} showSearch allowClear placeholder="Select Vendor" optionFilterProp="children"  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            dropdownMatchSelectWidth={false}
                            >
                            {vendorData.map((vendor) => {
                                return <Option value={vendor.vendorId}>{vendor.vendorName}</Option>
                            })}

                            </Select>
                                {/* <Input disabled /> */}
                            </Form.Item>
                            

                        </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="poId" label="PO Number" rules={[{ required: true,message:'Missed PO Number' }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select PO No."
                                    onChange={getPoData}
                                    onClear={resetAll}
                                    allowClear
                                    disabled={isPoDisabled}
                                >
                                    {poNumbers.map(dropData => {
                                        return <Option name="poId" key={dropData.purchaseOrderId} value={dropData.purchaseOrderId}>{dropData.poNumber}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="grnDate" label="GRN Date"  rules={[{ required: true,message:'Missed GRN Date' }]} >
                                <DatePicker 
                                allowClear
                                onChange={e => dateValidator()}
                                style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="invoiceDate" label="Invoice Date" rules={[{ required: true,message:'Missed Invoice Date' }]}>
                                <DatePicker 
                                allowClear
                                onChange={e => dateValidator()} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row gutter={24}>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="invoiceNumber" label="Invoice Number"  rules={[{ required: true,message:'Missed Invoice Number' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="contactPerson" label="Contact Person" rules={[{ required: true,message:'Missed Contact person' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="mobileNumber" label="Mobile Number" rules={[{ required: true,message:'Missed Mobile Number' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="address" label="Address" rules={[{ required: true,message:'Missed Address' }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="totalCost" label="Total Cost" rules={[{ required: false }]}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="coldStorage" label="Cold Storage" rules={[{ required: false }]}>
                            <Select
                            placeholder="Select Cold Storage"
                            onChange={handleColdStorage}
                            allowClear
                            optionFilterProp="children"
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            >
                            {zoneDropDown?.map(zonedata => {
                                return <Option key={zonedata.zoneId} value={zonedata.zoneId}>{zonedata.zoneName}</Option>
                            })}
                            </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="locationId" label="RackPosition" rules={[{ required: false }]}>
                            <Select
                            placeholder="Select Rack Position"
                            allowClear
                            optionFilterProp="children"
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            >
                            {rackPostionsDropDown.map(saleOrderDropDown => {
                                return <Option value={saleOrderDropDown.rackId}>{saleOrderDropDown.rackCode}</Option>
                            })}
                            </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item name="remarks" label="Remarks" >
                                <TextArea rows={1} />
                            </Form.Item>
                            <Form.Item name="saleOrderId" >
                                <Input hidden/>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}> */}
                        <Form.Item name="vendorId" >
                                <Input hidden />
                            </Form.Item>
                        {/* </Col> */}
                        {/* <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                        </Col> */}
                    </Row>
                </Form>
            </Card>

        </>
    );
}

export default GrnDetailsForm;
