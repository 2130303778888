import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { IndentDetailsModel } from './indent-details.model';
import { IndentModel } from './indent.model';

export class IndentResponseModel extends GlobalResponseObject{
    data?: IndentDetailsModel;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: IndentDetailsModel) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 