import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SubPlantValAddDto } from './sub-plant-val-additions.dto';

export class SubPlantValAddResponseModel extends GlobalResponseObject{
    data?: SubPlantValAddDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SubPlantValAddDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}