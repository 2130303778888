import { POTypeEnum, StatusEnum } from "@gtpl/shared-models/common-models";
import { PoItemsCreateDetails } from "./po-item-create-details.dto";
import { PoItemsDetails } from "./po-items-details";

export class PoDetailsDto {
    vendorId:number;
    saleOrderId:number;
    poNumber?:string;
    purchaseOrderDate:Date;
    transportation:number;
    expectedDeliveryDate:Date;
    deliveryAddress:number;
    paymentTerms: number;
    shippingTerms:string;
    deliveryTerms:string;
    remarks:string;
    note:string;
    exchangeRate:number;
    createdUser:string;
    updatedUser:string;
    billingAddress:number;
    poItems:PoItemsCreateDetails[];
    poType:POTypeEnum;
    purchaseOrderId?:number;
    status?:StatusEnum;
    unitId?:number;
    totalAmount?:number;
    plantAddress:number;
    /**
     * 
     * @param vendorId 
     * @param saleOrderId 
     * @param purchaseOrderDate 
     * @param transportation 
     * @param expectedDeliveryDate 
     * @param deliveryAddress 
     * @param paymentTerms 
     * @param remarks 
     * @param note 
     * @param exchangeRate 
     * @param createdUser 
     * @param updatedUser 
     * @param poItems 
     * @param purchaseOrderId 
     */
    constructor(vendorId:number, saleOrderId:number, purchaseOrderDate:Date, transportation:number, expectedDeliveryDate:Date, deliveryAddress:number, paymentTerms: number, shippingTerms:string, deliveryTerms:string ,remarks:string, note:string, exchangeRate:number, createdUser:string, updatedUser:string, poItems:PoItemsCreateDetails[], poType:POTypeEnum,purchaseOrderId?:number,poNumber?:string,status?:StatusEnum, unitId?:number, totalAmount?:number, billingAddress?:number, plantAddress?:number){
            this.vendorId = vendorId;
            this.saleOrderId = saleOrderId;
            this.purchaseOrderDate = purchaseOrderDate;
            this.transportation = transportation;
            this.expectedDeliveryDate = expectedDeliveryDate;
            this.deliveryAddress = deliveryAddress;
            this.paymentTerms =  paymentTerms;
            this.shippingTerms =  shippingTerms;
            this.deliveryTerms =  deliveryTerms;
            this.remarks = remarks;
            this.note = note;
            this.exchangeRate = exchangeRate;
            this.createdUser = createdUser;
            this.updatedUser = updatedUser;
            this.poItems = poItems;
            this.purchaseOrderId = purchaseOrderId;
            this.poType = poType;
            this.poNumber = poNumber;
            this.status = status;
            this.unitId = unitId;
            this.totalAmount = totalAmount;
            this.billingAddress = billingAddress;
            this.plantAddress = plantAddress;
    }
}