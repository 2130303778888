import React, { useEffect, useReducer, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, Upload, UploadProps, InputNumber, Table, Radio } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Brands, BrandsRequest, CategoryItemReq, ItemCategoryRequest, ItemForBrandReq, ItemRequest, ItemsDropDownDto, ItemSubCategoryDropDownDto, LocationMappingRmRackPostionReq, MasterBrandDto, MasterBrandsDropDownDto, SizesDropDownDto, SubCatReqForCategoryId } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService, MasterBrandService, RmRackPositionService, SizesService } from '@gtpl/shared-services/masters';
import { PackingCategoryEnum, ConversionType, UnitRequest } from '@gtpl/shared-models/common-models';
import { StockService } from '@gtpl/shared-services/procurement';
import { StockDTO } from '@gtpl/shared-models/warehouse-management';
import { PlantItemSubCategoryIdReq, ProductConversionModel, ProductConversionReq, SaleOrderIdRequest, StockDto } from '@gtpl/shared-models/procurement-management';
const { Option } = Select;
export interface PackingProductConversionProps {
  isUpdate: boolean;
  closeForm: () => void;
}
export function PackingProductConversion(
  props: PackingProductConversionProps
) {


  const [form] = Form.useForm();
  const [soDropDown, setSoDropDown] = useState<any>()
  const [toSoDropDown, setToSoDropDown] = useState<any>()
  const [disable, setDisable] = useState<boolean>(false)
  const [soItemdropDown, setSoItemDropdown] = useState<any>()
  const [toSoItemdropDown, setToSoItemDropdown] = useState<any[]>([])
  const stockService = new StockService;
  const [conversionType, setConversionType] = useState<ConversionType>()
  const createdUser = localStorage.getItem('createdUser')
  const fromItemId = form.getFieldValue('fromSaleOrderItemId')
  const [reasons, setReasons] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const itemsService = new ItemsService()
  const locationService = new RmRackPositionService;



  useEffect(() => {
    //  getItemData()
    // getSoDropDown()
    getReasonsForProdConversion();
  }, [])

  const getReasonsForProdConversion = () => {
    stockService.getReasonsForProdConversion().then((res) => {
      if (res.data) {
        setReasons(res.data);
      } else {
        setReasons([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    });
  }

  const getAvailableQtyforFromItem = (value) => {
    form.resetFields(['toSaleOrderId', 'toSaleOrderItemId', 'toStockId', 'fromQuantity', 'toQuantity', 'conversionQuantity', 'reason'])
    const unitId = Number(localStorage.getItem('unit_id'))
    const req = new ItemRequest(form.getFieldValue('fromSaleOrderItemId'), unitId, form.getFieldValue('fromSaleOrderId'), form.getFieldValue('locationId'));
    stockService.getPhysicalQuantityForItemId(req).then(res => {
      if (res.status) {
        form.setFieldsValue({ fromQuantity: res.data[0].physicalQuantity, fromStockId: res.data[0].stockId })
        getAllItemsFormDummyConversion()
      }
    })
  }

  const getAvailableQtyforToItem = (value) => {

    const unitId = Number(localStorage.getItem('unit_id'))
    const req = new ItemRequest(value, unitId, form.getFieldValue('toSaleOrderId'));
    stockService.getPhysicalQuantityForItemId(req).then(res => {
      if (res.status) {
        console.log(res.data, '--qty')
        form.setFieldsValue({ toQuantity: res.data[0].physicalQuantity, toStockId: res.data[0].stockId })
      }
    })
  }

  const getSoDropDown = (value) => {
    setSoDropDown([])
    const req = new ProductConversionReq()
    req.conversionType = value
    req.unitId = Number(localStorage.getItem('unit_id'))
    stockService.getFromPosForProductConversion(req).then((res) => {
      if (res.status) {
        setSoDropDown(res.data)
      } else {
        setSoDropDown([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getToSoDropDown = () => {
    if (conversionType == ConversionType.saleorder_to_saleorder) {
      setToSoDropDown([])
      const req = new ProductConversionReq
      req.conversionType = form.getFieldValue('conversionType')
      req.unitId = Number(localStorage.getItem('unit_id'))
      req.itemId = form.getFieldValue('fromSaleOrderItemId')
      req.saleOrderId = form.getFieldValue('fromSaleOrderId')
      stockService.getPoNumberForProductConversion(req).then((res) => {
        if (res.status) {
          setToSoDropDown(res.data)
        } else {
          setToSoDropDown([])
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
  }

  const getFromSoItemDroDown = (value) => {
    form.resetFields(['fromSaleOrderItemId', 'fromStockId', 'toSaleOrderId', 'toSaleOrderItemId', 'toStockId', 'fromQuantity', 'toQuantity', 'conversionQuantity', 'reason', 'locationId'])
    form.resetFields(['fromSaleOrderItemId', 'fromQuantity'])
    const req = new SaleOrderIdRequest(value, Number(localStorage.getItem('unit_id')));
    stockService.getavailbleItemsInStock(req).then((res) => {
      if (res.status) {
        setSoItemDropdown(res.data)
      } else {
        setSoItemDropdown([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getToSoItemDroDown = (value) => {
    form.resetFields(['toSaleOrderItemId', 'toQuantity'])
    const req = new ProductConversionReq
    req.saleOrderId = form.getFieldValue('toSaleOrderId')
    req.itemId = form.getFieldValue('fromSaleOrderItemId')
    req.unitId = Number(localStorage.getItem('unit_id'))
    req.conversionType = form.getFieldValue('conversionType')
    stockService.getToItemsForProductConversion(req).then((res) => {
      if (res.status) {
        setToSoItemDropdown(res.data)
        form.setFieldsValue({ toSaleOrderItemId: (res.data)[0]?.itemId })
      } else {
        setToSoItemDropdown([])
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })

  }

  const getAllItemsFormDummyConversion = () => {
    if (conversionType == ConversionType.saleorder_to_bulk) {
      form.resetFields(['toSaleOrderItemId'])
      const req = new ItemForBrandReq()
      req.itemSubCategoryId = soItemdropDown.find(i => i.itemId == form.getFieldValue('fromSaleOrderItemId')).itemSubCategoryId
      itemsService.getItemsForSubCategory(req).then((res) => {
        if (res.status) {
          setToSoItemDropdown(res.data)
          form.setFieldsValue({ toSaleOrderItemId: form.getFieldValue('fromSaleOrderItemId') })
        } else {
          setToSoItemDropdown([])
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }
      )
    }
  }

  const getLocationsForItem = () => {
    form.resetFields(['locationId'])
    const req = new ItemRequest(form.getFieldValue('fromSaleOrderItemId'), Number(localStorage.getItem('unit_id')), form.getFieldValue('fromSaleOrderId'))
    stockService.getLocationsForItem(req).then((res) => {
      if (res.status) {
        setLocations(res.data)
      } else {
        setLocations([])
      }
    })
  }


  const handleConversionType = (value) => {
    setConversionType(value)
    form.resetFields(['fromSaleOrderId', 'fromSaleOrderItemId', 'fromStockId', 'toSaleOrderId', 'toSaleOrderItemId', 'toStockId', 'fromQuantity', 'toQuantity', 'conversionQuantity', 'reason'])
    getSoDropDown(value)
  }

  const onReset = () => {
    console.log('');
    form.resetFields();

  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const onFocus = () => {
    //console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }


  const convertProduct = (values: ProductConversionModel) => {
    setDisable(true)
    if (form.getFieldValue('conversionQuantity') <= form.getFieldValue('fromQuantity')) {
      values.unitId = Number(localStorage.getItem('unit_id'))
      values.itemSubCategoryId = soItemdropDown.find(i => i.itemId == form.getFieldValue('fromSaleOrderItemId')).itemSubCategoryId

      stockService.stockConversion(values).then((res) => {
        setDisable(false)
        console.log
        if (res.status) {
          form.resetFields()
          AlertMessages.getSuccessMessage('Stock converted sucessfully')

        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch((err) => {
        setDisable(false)
        AlertMessages.getErrorMessage(err.message)
      });
    } else {
      AlertMessages.getInfoMessage('Conversion Qty should be less than Converting Items Qty')
    }
  }

  const conversionTypeHandler = () => {
    return soDropDown?.map((item) => {
      return <Option soStatus={item.status} value={item.saleOrderId}>{item.poNumber}</Option>
    })

  }
  return (

    <Card title={<span style={{ color: 'white' }}>Re Classification</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <Form form={form} layout='vertical' name='control-hooks' onFinish={convertProduct}>
        <Row gutter={24} >
          <Form.Item style={{ display: "none" }} name="createdUser" initialValue={createdUser} >
            <Input hidden />
          </Form.Item>
          <Form.Item style={{ display: "none" }} name="soStatus"  >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              label="Conversion Type"
              name={'conversionType'}
              rules={[{ required: true, message: 'Missing Conversion type' }]} >
              <Select
                placeholder="Select Conversion type"
                allowClear
                showSearch
                onChange={(value) => { handleConversionType(value) }}
                onFocus={onFocus}
                onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Object.values(ConversionType)?.map(item => {
                  return <Option value={item} >{item}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="fromSaleOrderId"
              label=" From PO Number"
              rules={[
                {
                  required: true,
                  message: 'PO Number is required',
                },

              ]} >
              <Select
                showSearch
                placeholder="Select PO Number"
                optionFilterProp="children"
                onSelect={(value, option) => { getFromSoItemDroDown(value), form.setFieldsValue({ soStatus: option.soStatus }) }}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {conversionTypeHandler()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item
              name="fromSaleOrderItemId"
              label="Item"
              rules={[
                {
                  required: true,
                  message: 'Item is required',
                },

              ]}>
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Item"
                optionFilterProp="children"
                onSelect={(value) => { getLocationsForItem(); getToSoDropDown(); }}
                onBlur={onBlur}
              // onSearch={onSearch}
              >
                {soItemdropDown?.map((item) => {
                  return <Option key={item.itemId} value={item.itemId}>{item.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="locationId"
              label="Locaation"
              rules={[
                {
                  required: true,
                  message: 'Location is required',
                },

              ]}>
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Location"
                optionFilterProp="children"
                onSelect={(value) => { getAvailableQtyforFromItem(value); }}
                onBlur={onBlur}
              // onSearch={onSearch}

              >

                {locations?.map((item) => {
                  return <Option key={item.locationId} value={item.locationId}>{item.location}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="fromQuantity"
              label="Available Qty"
              rules={[
                {
                  required: true,
                  message: 'Item is required',
                },
              ]}

            >
              <Input disabled />
            </Form.Item>
          </Col>

          {
            form.getFieldValue('fromSaleOrderId') >= 0 ?
              <>
                {conversionType == ConversionType.saleorder_to_saleorder ?
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
                    <Form.Item
                      name="toSaleOrderId"
                      label=" To PO Number"
                      rules={[
                        {
                          required: true,
                          message: 'PO Number is required',
                        },

                      ]}>
                      <Select
                        showSearch
                        // style={{ width: 240 }}
                        placeholder="Select PO Number"
                        optionFilterProp="children"
                        onSelect={(value) => { getToSoItemDroDown(value) }}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          toSoDropDown?.map((res) => {
                            return <Option value={res.saleOrderId}>{res.poNumber}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col> : <></>
                }
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                  <Form.Item
                    name="toSaleOrderItemId"
                    label="To Item"
                    rules={[
                      {
                        required: true,
                        message: 'Item is required',
                      },

                    ]}>
                    <Select
                      showSearch
                      // style={{ width: 240 }}
                      placeholder="Select Item"
                      optionFilterProp="children"
                      onBlur={onBlur}
                      disabled={true}
                      onSelect={(value) => { getAvailableQtyforToItem(value); }}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {toSoItemdropDown?.map((item) => {
                        return <Option value={item.itemId}>{item.itemName}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>

              </> : ''
          }
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} >
            <Form.Item
              name="conversionQuantity"
              label="Conversion Qty"
              rules={[
                {
                  required: true,
                  message: 'Conversion Quantity is required',
                  pattern: /^[\d]{0,9}$/
                }, {
                  validator: (_, value) => {
                    const fromQty = form.getFieldValue('fromQuantity')
                    let alertmessage = 'Conversion Qty should be less than Converting Items Qty'
                    // if (Number(value) > Number(fromQty)) {
                    //   alertmessage = 'Conversion Qty should be less than Converting Items Qty'
                    // }
                    if (fromQty) return value <= fromQty ? Promise.resolve() : Promise.reject(new Error(alertmessage));
                  }
                }

              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="reason"
              label="Reason"
              rules={[
                {
                  required: false,
                  message: 'Reason is required',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select reason"
                optionFilterProp="children"
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {reasons?.map((reasonDrop) => {
                  return <Option value={reasonDrop.reason}>{reasonDrop.reason}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  required: false,
                  message: 'Remarks is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end'>

          <Col span={4} style={{ textAlign: 'right', paddingTop: '30px' }}>
            <Button type="primary" disabled={disable} htmlType="submit"  >
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>

          </Col>

        </Row>
      </Form>
      <br /><br />




    </Card>


  );
}

export default PackingProductConversion;