import { AllCertificatesResponseModel, CertificatesDto, CertificatesResponseModel, CertificationsRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import {
  AllCertificationsResponseModel,
  CertificationsDto,
  CertificationResponseModel,
  CertificationsDropDownData,
  CertificationsDropDownDataResponseModel,
} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class CertificationsService {

  URL = connection.DEPLOY_URL + '/certificates';

  // async createCertification(certification: CertificationsDto): Promise<CertificationResponseModel> {
  //   return await axios
  //     .post(this.URL + '/createCertification', certification)
  //     .then((res) => {
  //       return res.data;
  //     });
  // }
  // async updateCertification(certification: CertificationsDto): Promise<CertificationResponseModel> {
  //   return await axios
  //     .post(this.URL + '/updateCertification', certification)
  //     .then((res) => {
  //       return res.data;
  //     });
  // }
  // async getAllCertifications(): Promise<AllCertificationsResponseModel> {
  //   return new AllCertificationsResponseModel(
  //     true,
  //     1111,
  //     'Certifications retrieved successfully',
  //     [
  //       new CertificationsDto(
  //         1,
  //         'ISI',
  //         'Remark 1',
  //         'Bhuvan',
  //         true
  //       ),
  //     ]
  //   );
  //   // return await axios.post(this.URL + '/getAllCertifications')
  //   //     .then(res => {
  //   //         return res.data
  //   //     })
  // }
  // async activatedeActivateCertification(
  //   certificationDto: CertificationsDto
  // ): Promise<CertificationResponseModel> {
  //   //   console.log(State.stateCode);
  //   return await axios
  //     .post(this.URL + '/updateCertificationStatus', certificationDto)
  //     .then((res) => {
  //       return res.data;
  //     });
  // }
  // async getAllActiveCertifications(): Promise<AllCertificationsResponseModel> {
  //   return await axios.post(this.URL + '/getAllCertifications').then((res) => {
  //     return res.data;
  //   });
  // }
  // async getActiveCertificationsCount(): Promise<AllCertificationsResponseModel> {
  //   return await axios.post(this.URL + '/getActiveCertificationsCount').then((res) => {
  //     return res.data;
  //   });
  // }
  async getActiveCertifications(){
    return new CertificationsDropDownDataResponseModel(true,11,'customersData retrived successfully',[new CertificationsDropDownData(1,'BAP'),new CertificationsDropDownData(2,'ASC')])
}
// import {
//   AllCertificationsResponseModel,
//   CertificatesDto,
//   CertificatesResponseModel,
// } from '@gtpl/shared-models/masters';
// import connection from './connection';

// export class CertificationsService {
  // URL = connection.DEPLOY_URL + '/certificates';

  async createCertification(certification: CertificatesDto): Promise<CertificatesResponseModel> {
    return await axios
      .post(this.URL + '/createCertificate', certification)
      .then((res) => {
        return res.data;
      });
  }
  async updateCertification(certification: CertificatesDto): Promise<CertificatesResponseModel> {
    console.log('update Certificate');
    return await axios.post(this.URL + '/updateCertificates', certification)
      .then((res) => {
        return res.data;
      });
  }
  async getAllCertifications(req?:UserRequestDto): Promise<AllCertificatesResponseModel> {
    // return new AllCertificatesResponseModel(
    //   true,
    //   1111,
    //   'Certifications retrieved successfully',
    //   [
    //     new CertificatesDto(
    //       1,
    //       'ISI',
    //       'Remark 1',
    //       'Bhuvan',
    //       true
    //     ),
    //   ]
    // );
    return await axios.post(this.URL + '/getAllCertificates',req)
        .then(res => {
            return res.data
        })
  }
  async activatedeActivateCertification(
    certificationDto: CertificatesDto
  ): Promise<CertificatesResponseModel> {
    //   console.log(State.stateCode);
    return await axios
      .post(this.URL + '/activateOrDeactivateCertificates', certificationDto)
      .then((res) => {
        return res.data;
      });
  }
  async getAllActiveCertifications(): Promise<AllCertificatesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveCertificates').then((res) => {
      return res.data;
    });
  }
  async getActiveCertificationsCount(): Promise<AllCertificatesResponseModel> {
    return await axios.post(this.URL + '/getActiveCertificationsCount').then((res) => {
      return res.data;
    });
  }
  async getCertificationById(certificationsRequest:CertificationsRequest): Promise<CertificatesResponseModel> {
    console.log(certificationsRequest);
    return await axios.post(this.URL + '/getCertificationById', certificationsRequest).then(res => {
      // console.log(res);
        return res.data
    });
  }
}
