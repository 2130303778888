import { UnitRequest } from '@gtpl/shared-models/common-models';
import { AllGrnReturnResponse, GrnDetailsForReturn, GrnItemReturnResponse, GrnItemReturnResponseModel, GrnReturnIdRequest, GrnReturnItemSaveDto } from '@gtpl/shared-models/procurement-management';

import axios from 'axios';
import connection from './connection';


export class GrnItemReturnService {
    URL = connection.DEPLOY_URL + '/grn-returns';
    async saveGrnReturns(grnRetunDto: GrnDetailsForReturn): Promise<GrnItemReturnResponseModel> {
        return await axios.post(this.URL + '/saveGrnReturns', grnRetunDto)
            .then(res => {
                return res.data
            })
    }
    async getAllGrnReturns( req: UnitRequest): Promise<AllGrnReturnResponse> {
        return await axios.post(this.URL + '/getAllGrnReturns', req)
            .then(res => {
                return res.data
            })
    }

    async getGrnReturnDataById(req: GrnReturnIdRequest): Promise<GrnItemReturnResponse> {
        return await axios.post(this.URL + '/getGrnReturnDataById', req)
            .then(res => {
                return res.data
            })
    }


}

