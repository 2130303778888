import axios from 'axios';
import connection from './connection';
// import {EmployeeRequest, EmployeeRoleRequest, HrmsAllResponseModel, EmployeeRequest,} from '@gtpl/shared-models/masters'
import { HrmsAllResponseModel, HrmsEmployeeDetailsDto, EmployeeRequest, HrmsResponseModel, AllEmployeesResponseModel, EmployeeDeptRequest } from '@gtpl/shared-models/masters';
import { CommonResponse } from '@gtpl/shared-models/production-management';
export class HrmsEmpDetService {
  URL = connection.DEPLOY_URL + '/employee-details';


  async create(hrmsdto: HrmsEmployeeDetailsDto): Promise<HrmsResponseModel> {
    return await axios.post(this.URL + '/createEmployee', hrmsdto)
      .then(res => {
        return res.data
      })
  }

  async update(hrmsdto: HrmsEmployeeDetailsDto): Promise<HrmsResponseModel> {
    return await axios.post(this.URL + '/updateEmployee', hrmsdto)
      .then(res => {
        return res.data
      })
  }

  async activatedeActivate(hrmsDto: HrmsEmployeeDetailsDto): Promise<HrmsResponseModel> {
    //   console.log(State.stateCode);
    return await axios.post(this.URL + '/updateEmployee', hrmsDto)
      .then(res => {
        return res.data
      })
  }
  async getActiveEmployeesById(employeeRequest: EmployeeRequest): Promise<HrmsResponseModel> {
    //   console.log(State.stateCode);
    return await axios.post(this.URL + '/getActiveEmployeesById', employeeRequest)
      .then(res => {
        return res.data
      })
  }

  async getAll(): Promise<CommonResponse> {

    return await axios.post(this.URL + '/getAllEmployees')
      .then(res => {
        return res.data
      })
  }

  async getAllActiveEmployees(): Promise<HrmsAllResponseModel> {
    return await axios.post(this.URL + '/getAllActiveEmployees').then((res) => {
      return res.data;
    });
  }

  async getAllActiveEmployeesForLeaves(): Promise<HrmsAllResponseModel> {
    return await axios.post(this.URL + '/getAllActiveEmployeesForLeaves').then((res) => {
      return res.data;
    });
  }

  async getActiveEmployeesByRole(empReq: EmployeeRequest): Promise<HrmsAllResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesByRole', empReq).then((res) => {
      return res.data;
    });
  }

  async getActiveEmployeesByDept(employeeDeptRequest: EmployeeDeptRequest): Promise<HrmsAllResponseModel> {
    return await axios.post(this.URL + '/getActiveEmployeesByDept', employeeDeptRequest).then((res) => {
      return res.data;
    });
  }

  async getEmployeeUnitData(): Promise<CommonResponse> {
    return await axios.post(this.URL + '/getEmployeeUnitData').then((res) => {
      return res.data
    });
  }

}