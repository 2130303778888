import { PaymentTermsDropDownDto, PaymentTermsDropDownResponseModel, PaymentTermsDto, PaymentTermsRequest, PaymentTermsResponseModel } from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";
import {AllPaymentTermsResponseModel} from '@gtpl/shared-models/masters'
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export class PaymentTermsService {

    URL = connection.DEPLOY_URL + '/payment-terms';
    async create(dto: PaymentTermsDto): Promise<PaymentTermsResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createPaymentTerms',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: PaymentTermsDto): Promise<PaymentTermsResponseModel> {
    return await axios.post(this.URL + '/updatePaymentTerms', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: PaymentTermsDto): Promise<PaymentTermsResponseModel> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivatePaymentTerms', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(req?:UserRequestDto): Promise<AllPaymentTermsResponseModel> {
                
                // const dto = [];
                // for(let i=0;i<5;i++){
                //     const isActive = (i%2 == 1)? true:false;
                //                 dto.push(new PaymentTermsDto(i+1,'person',111,true,) )
                // }
                // return new AllPaymentTermsResponseModel(true,1111,'Profile retrieved successfully',dto)
                return await axios.post(this.URL + '/getAllPaymentTerms',req)
                    .then(res => {
                        return res.data 
                    })     
            }

    async getAllpaymentTermsDropDown(): Promise<PaymentTermsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllpaymentTermsDropDown').then(res => {
            return res.data
        });
        // return new PaymentTermsDropDownResponseModel(true,11,'customersData retrived successfully',[new PaymentTermsDropDownDto(1,'payterm1'),new PaymentTermsDropDownDto(2,'payterm2')])
    }

    async getAllVendorPaymentTermsDropDown(): Promise<PaymentTermsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllVendorPaymentTermsDropDown').then(res => {
            return res.data
        });
    }
    async getPaymentById(paymentTermsRequest:PaymentTermsRequest): Promise<PaymentTermsResponseModel> {
        return await axios.post(this.URL + '/getPaymentById',paymentTermsRequest).then(res => {
            return res.data
        });
    }
}