import connection from "./connection";
import {AssetsSaleDto} from '../../../../shared-models/asset-management/src/lib/open-assets/asset-sale.dto';
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";
import { AssetByAssetCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto";

export class AssetsSaleService {
    URL = connection.DEPLOY_URL + '/assetsSale';

    async createAssetSale(req: AssetsSaleDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetSale',req)
        .then(res => {
            return res.data;
        })
    }

    async getAssetSaleDetails(req?: AssetByAssetCode) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetSaleDetails',req)
        .then(res => {
            return res.data;
        })
    }

    async getassetCodeDropDownforAssetSale(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getassetCodeDropDownforAssetSale',req)
        .then(res => {
            return res.data;
        })
    }

    async getassetCategoryDropDownforAssetSale(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getassetCategoryDropDownforAssetSale',req)
        .then(res => {
            return res.data;
        })
    }

    async assetCodeDropdownforAssetSaleGrid(req?:AssetByStatus) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetCodeDropdownforAssetSaleGrid',req)
        .then(res => {
            return res.data;
        })
    }

}