import { PoGrnData } from "./poGrnData.dto";

export class PoGrnDataResDto {
    names:string[];
    PoGrnData: PoGrnData;
    

    constructor(names: string[], stockIn: PoGrnData){
            this.names= names;
            this.PoGrnData= stockIn;            
    }
}