import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

         
export class FgStockReportModel {
    saleOrderId:number;
    poNumber:string;
    brand:string;
    packing:string;
    required:number;
    finalFg:string;
    cf:string;
    dummy:number;
    balance:number;
    plantName:string;
    plantId:number;
  variantcode: string;
  allocatedSaleOrderItemId: number;

    constructor( saleOrderId:number,poNumber:string, brand:string,  packing:string,  required:number,  finalFg:string, cf:string,dummy:number, balance:number,plantName:string,plantId:number,allocatedSaleOrderItemId: number){
        this.saleOrderId = saleOrderId;
        this.poNumber = poNumber;
        this.brand = brand;
        this.packing = packing;
        this.required = required;
        this.finalFg = finalFg;
        this.cf = cf;
        this.dummy = dummy;
        this.balance = balance;
        this.plantName = plantName;
        this.plantId = plantId;
        this.allocatedSaleOrderItemId = allocatedSaleOrderItemId;
    }
   
}