export class PickUpDto {
    pickUpId?: number;
    pickUp: string;
    createdUser: string;
    isActive: boolean;
    updatedUser?: string;
    versionFlag?: number;
    constructor(pickUpId: number, pickUp: string, createdUser: string, isActive: boolean, updatedUser?: string, versionFlag?: number){
        this.pickUpId = pickUpId;
        this.pickUp = pickUp;
        this.createdUser = createdUser;
        this.isActive = isActive;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }
}