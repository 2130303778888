import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { QcdefectWorkstaionResponse } from './work-station-response';


export class QcdefectworkstationNameResponseModell extends GlobalResponseObject{
    data?:QcdefectWorkstaionResponse[];
      /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

   constructor(status: boolean, intlCode: number, internalMessage: string, data?: QcdefectWorkstaionResponse[]){
    super(status,intlCode,internalMessage);
    this.status = status;
    this.intlCode = intlCode;
    this.internalMessage = internalMessage;
    this.data = data;
}
}