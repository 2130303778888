import { OperationTypeEnum } from "@gtpl/shared-models/common-models";


export class OperationRequest {
    operationType:OperationTypeEnum;
    plantId?:number;

    /**
     * 
     * @param operationType 
     * @param plantId 
     */
    constructor(operationType:OperationTypeEnum, plantId?:number){
        this.operationType = operationType;
        this.plantId = plantId;
    }
}