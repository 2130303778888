export class TrimsDto {
    trimsId: number;
    trimName: string;
    isActive: boolean;
    createdAt:Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    
    /**
     * 
     * @param trimsId 
     * @param trimName 
     * @param isActive 
     * @param createdAt
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
    constructor(trimsId: number, trimName: string, isActive: boolean,createdAt:Date, createdUser: string, updatedAt: Date,
        updatedUser: string, versionFlag: number) {
            this.trimsId= trimsId;
            this.trimName= trimName;
            this.isActive= isActive;
            this.createdAt=createdAt;
            this.createdUser= createdUser;
            this.updatedAt= updatedAt;
            this.updatedUser= updatedUser;
            this.versionFlag= versionFlag;
    }
}