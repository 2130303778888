import { CommonResponse } from '@gtpl/shared-models/production-management';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import { AllSaleOrdersItemsDetailsResponseModel, SaleOrderDetailViewInfoResponseModel, SaleOrderDropDownResponseModel ,SaleOrderItemsDropdownResponse} from '@gtpl/shared-models/sale-management';

import { FgStockTransferDto, FgStockTransferResponseModel } from '@gtpl/shared-models/warehouse-management';
import axios from 'axios';
import { SaleOrderItemsRequest ,saleOrder} from 'libs/shared-services/sale-management/src/lib/saleorder-model';
import connection from './connection';
import { fgStockTransferReq } from 'libs/shared-services/warehouse-management/src/lib/fg-stock-transfer-request';


export class FgStockTransferService {
    URL = connection.DEPLOY_URL + '/fg-stock-transfer';

    async createFgStockTransfer(FgStockTransferDto: FgStockTransferDto): Promise<FgStockTransferResponseModel> {
        return await axios.post(this.URL + '/createFgStockTransfer',FgStockTransferDto)
            .then(res => {
                return res.data
            })
    }
    async  updateFgStockTransfer(FgStockTransferDto: FgStockTransferDto): Promise<FgStockTransferResponseModel> {
      return await axios.post(this.URL + '/updateFgStockTransfer', FgStockTransferDto)
             .then(res => {
                 return res.data
             })
  }

  async gettransferTypeData(req?:fgStockTransferReq):Promise <CommonResponse>{
        return await axios.post(this.URL+'/gettransferTypeData',req)
        .then(res =>{
            return res.data
        }
        )    
  }

  async getMTOSaleOrders(req: PlantRequest): Promise<SaleOrderDropDownResponseModel> {
    return await axios.post(this.URL + '/getMTOSaleOrders', req)
        .then(res => {
            return res.data
        })
}
async getSaleOrderItemDetails(saleOrderItemsRequest: SaleOrderItemsRequest): Promise<AllSaleOrdersItemsDetailsResponseModel> {
    return await axios.post(this.URL + '/getSaleOrderItemDetails', saleOrderItemsRequest)
        .then(res => {
            return res.data
        })
}

async getSaleOrderItemsForSoId(saleOrderIdRequest: saleOrder): Promise<SaleOrderItemsDropdownResponse> {
    return await axios.post(this.URL + '/getSaleOrderItemsForSoId', saleOrderIdRequest)
        .then(res => {
            return res.data
        })
}

async getSaleOrderDetailsById(saleOrderId: saleOrder): Promise<SaleOrderDetailViewInfoResponseModel> {
    console.log(saleOrderId);
    return await axios.post(this.URL + '/getSaleOrderDetailsById', saleOrderId)
        .then(res => {
            return res.data
        })
}

}

