export class AssignRepackingRequest{
    plantId: number;
    role?: string;
    varientId?: number;
    uom?: string;
    availQty?: number;
    stockIds?: number[];

}
export class AssignSuperDummyRepackingRequest{
    allocatedSaleOrderId: number;
    allocatedSaleOrderItemId: number;
    stockIds: number[];
}