import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Card, Cascader, Col, Collapse, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch, Table, Tag, TimePicker, TreeSelect } from 'antd';
// import './stock-grid.css';
import moment from 'moment';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import TextArea from 'antd/lib/input/TextArea';
import { RackDropDownDto, ZoneDropDownDto,HrmsEmployeeDetailsDto, ZoneDto } from '@gtpl/shared-models/masters';
import {EndCustomersService, MasterBrandService, ProductService, RackService, SizesService, SkuService, ZoneService,HrmsEmpDetService} from '@gtpl/shared-services/masters';
import { EmployeeService } from '@gtpl/shared-services/attendance';
import { StockInService } from '@gtpl/shared-services/warehouse-management';
import { getAllStockInDetailsResponseModel, InputIdRequest, SaleOrderIdRequest, StockInDto, StockInRequestDto, StockInResponseModel, StockOutResponse,PalletLocationUpdateRequest, StockOutModel } from '@gtpl/shared-models/warehouse-management';
/* eslint-disable-next-line */
export interface PalletLocationProps {
  // grnInfo: GrnData;
  handleCancel: () => void;
  stockOutModel:StockOutModel;
  // form:FormInstance<any>;
}

export function PalletLocation(
  props: PalletLocationProps
) {
  const [rmEvaluation] = Form.useForm();
  const [palletLocationUpdateInfo] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { Option } = Select;
  const [zoneDropDown, setZoneDropDown] = useState<ZoneDto[]>([]);
  const [rackPostionsDropDown, setRackPostionsDropDown] = useState<RackDropDownDto[]>([]);
  const [employeeData, setEmployeeData] = useState<HrmsEmployeeDetailsDto[]>([]);
  const employeeService = new HrmsEmpDetService();
  const zoneService = new ZoneService();
  const rackService = new RackService();
  const stockInService = new StockInService();
  
  useEffect(() => {
    getWarehousePersonsData();
    getZones();
  }, [])
  console.log('stockId');
  console.log(props.stockOutModel.stockId);
  const getWarehousePersonsData = () => {
    employeeService.getAll().then((res) => {
      if (res.status) {
        setEmployeeData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setEmployeeData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setEmployeeData([]);
    });
  }
  const getZones = () => {
    zoneService.getPlantZones({plantId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        console.log(res.data);
        setZoneDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setZoneDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setZoneDropDown([]);
    });
  }
  const getRacks = (value) => {
    rackService.getRacksForZoneId({zoneId:value}).then(res => {
      if(res.status){
        setRackPostionsDropDown(res.data);
      }
      else{
        setRackPostionsDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setRackPostionsDropDown([]);
    });
  }
  const handleColdStorage = (value) => {
    getRacks(value);

  }
  const updatePalletLocation = () => {
    // let palletLocationUpdateRequest = new PalletLocationUpdateRequest();
    palletLocationUpdateInfo.validateFields().then(palletLocationUpdateI => {
        console.log(palletLocationUpdateI);
        stockInService.updagtePalletLocation(new PalletLocationUpdateRequest(props.stockOutModel.stockId,palletLocationUpdateI.coldStorageId,palletLocationUpdateI.rackPostion,palletLocationUpdateI.whPersonId)).then(res => {
          console.log(res);
          if (res) {            
            AlertMessages.getSuccessMessage('Location updated successfully');
            props.handleCancel();
          } else {
            AlertMessages.getErrorMessage('Location not updated');
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })

      
    });
  }
  function onBlur() {
    console.log('blur');
  }
  
  function onFocus() {
    console.log('focus');
  }
  
  function onSearch(val) {
    console.log('search:', val);
  }
  return (

    <>
    
      <Form
        layout="vertical"
        form={palletLocationUpdateInfo}
      >
      <Card>
            <Row gutter={24}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="coldStorageId" label="Cold Storage"
              rules={[
                {
                  required: true, message: 'Missing Cold Storage',
                },
              ]}>
                {/* <Input /> */}
              <Select
                  placeholder="Select Cold Storage"
                  onChange={handleColdStorage}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {zoneDropDown?.map(zonedata => {
                    return <Option key={zonedata.zoneId} value={zonedata.zoneId}>{zonedata.zoneName}</Option>
                  })}
                </Select>
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="rackPostion"
                label="Rack Position"
                rules={[
                  {
                    required: true, message: 'Missing Rack Position',
                  },
                ]}
              >
                <Select
                  placeholder="Select Rack Position"
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {rackPostionsDropDown.map(saleOrderDropDown => {
                    return <Option value={saleOrderDropDown.rackId}>{saleOrderDropDown.rackCode}</Option>
                  })}
                </Select>
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
            <Form.Item name="whPersonId"  label="W/H Person Name" rules={[{ required: true,message: 'Missing W/H person' }]}>
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select W/H person"
                allowClear
              >
                {employeeData.map(dropData => {
                  return <Option value={dropData.employeeName}>{`${dropData.employeeName}`}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
             </Row>
         
        <Row justify="end">
          <Col>
            <Button type='primary' onClick={updatePalletLocation} block disabled={btnDisable}>
              Submit
            </Button>
          </Col>
        </Row>
        <Row gutter={24} style={{display:dataSource?.length?'block':'none'}}>
          {/* <Table dataSource={dataSource} columns={columns} scroll={{ x: true }} /> */}
        </Row>
        </Card>
      </Form>
      </>
  );
}

export default PalletLocation;
