

export class ServiceUploadDto {
    uploadId?: number;
    name?: string | null;
    serveId?: string | null;
    fileName?: string | null;
    filePath?: string | null;
    isActive?: boolean | null;
    createdAt?: Date;
    createdUser?: string | null;
    updatedAt?: Date;
    updatedUser?: string | null;
    versionFlag?: number;

    constructor(uploadId?: number,
        name?: string | null,
        serveId?: string | null,
        fileName?: string | null,
        filePath?: string | null,
        isActive?: boolean | null,
        createdAt?: Date,
        createdUser?: string | null,
        updatedAt?: Date,
        updatedUser?: string | null,
        versionFlag?: number){
            this.name = name,
            this.uploadId = uploadId;
            this.serveId = serveId;
            this.fileName = fileName;
            this.filePath = filePath;
            this.isActive = isActive;
            this.createdAt = createdAt;
            this.createdUser = createdUser;
            this.updatedAt = updatedAt;
            this.updatedUser = updatedUser;
            this.versionFlag = versionFlag;

    }

}