import { packingIndentEnum } from "@gtpl/shared-models/common-models";
import { ApiProperty } from "@nestjs/swagger";

export class IndentNumberRequest {
    indentNumber:string
    constructor(indentNumber:string){
        this.indentNumber = indentNumber
    }

}
 export class indentrequest {
        indentNumber:string;
        status:packingIndentEnum;
        receivedQuantity?:number;
        issuedQuantity?:number;

        constructor(indentNumber:string,status:packingIndentEnum,receivedQuantity?:number,issuedQuantity?:number){
            this.indentNumber=indentNumber;
            this.status=status;
            this.receivedQuantity=receivedQuantity;
            this.issuedQuantity=issuedQuantity;
        }
    }
