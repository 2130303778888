export enum OperationsList {
  Created = "Created",
  Updated="Updated",
  InActiveated="In-Activated",
  Activated="Activated",
  Viewed="Viewed",
  Printed="Printed",
  LoggedIn="LoggedIn",
  LoggedOut="LoggedOut"
}
