import { POTypeEnum, StatusEnum } from "@gtpl/shared-models/common-models";
import { VendorDropDownDto, PaymentTermsDropDownDto, UnitcodeDto, VendorsDto } from "@gtpl/shared-models/masters";
import { SaleOrderDropDownDto } from "@gtpl/shared-models/sale-management";
import { PoItemsDetails } from "./po-items-details";

export class AllPoDetailsInfo {
    saleOrder: SaleOrderDropDownDto;
    vendor: VendorDropDownDto;
    purchaseOrderNo: string;
    purchaseOrderDate: Date;
    transportation: number;
    expectedDeliveryDate: Date;
    deliveryAddress: number;
    paymentTerms: number;
    remarks: string;
    note: string;
    createdUser: string;
    updatedUser: string;
    poItems: PoItemsDetails[];
    purchaseOrderId: number;
    status: StatusEnum;
    poType?: POTypeEnum;
    currency?: string;
    soNumber?: string;
    poNumber?: string;
    vendorName?: string;
    paymentTermsName?: string
    deliveryTerms?: string
    shippingTerms?: string
    unitinfo?: UnitcodeDto;
    deliveryAddressInfo?: UnitcodeDto;
    billingAddressInfo?: UnitcodeDto;
    vendorInfo?: VendorsDto;
    isTransferred?:boolean
    /**
     * 
     * @param saleOrder 
     * @param vendor 
     * @param purchaseOrderNo 
     * @param purchaseOrderDate 
     * @param transportation 
     * @param expectedDeliveryDate 
     * @param deliveryAddress 
     * @param paymentTerms 
     * @param remarks 
     * @param note 
     * @param createdUser 
     * @param updatedUser 
     * @param poItems 
     * @param purchaseOrderId 
     * @param status 
     * @param deliveryTerms
     * @param shippingTerms
     */
    constructor(saleOrder: SaleOrderDropDownDto, vendor: VendorDropDownDto, purchaseOrderNo: string, purchaseOrderDate: Date, transportation: number, expectedDeliveryDate: Date, deliveryAddress: number, paymentTerms: number, remarks: string, note: string, createdUser: string, updatedUser: string, poItems: PoItemsDetails[], purchaseOrderId: number, status: StatusEnum, poType?: POTypeEnum, currency?: string, soNumber?: string, poNumber?: string, paymentTermsName?: string, deliveryterms?: string, shippingterms?: string, unitinfo?: UnitcodeDto, deliveryAddressInfo?: UnitcodeDto, billingAddressInfo?: UnitcodeDto, vendorInfo?: VendorsDto,isTransferred?:boolean) {
        this.saleOrder = saleOrder;
        this.vendor = vendor;
        this.purchaseOrderNo = purchaseOrderNo;
        this.purchaseOrderDate = purchaseOrderDate;
        this.transportation = transportation;
        this.expectedDeliveryDate = expectedDeliveryDate;
        this.deliveryAddress = deliveryAddress;
        this.paymentTerms = paymentTerms;
        this.remarks = remarks;
        this.note = note;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.poItems = poItems;
        this.purchaseOrderId = purchaseOrderId;
        this.status = status;
        this.poType = poType;
        this.currency = currency;
        this.soNumber = soNumber;
        this.paymentTermsName = paymentTermsName;
        this.deliveryTerms = deliveryterms;
        this.shippingTerms = shippingterms;
        this.poNumber = poNumber;
        this.unitinfo = unitinfo;
        this.deliveryAddressInfo = deliveryAddressInfo;
        this.billingAddressInfo = billingAddressInfo;
        this.vendorInfo = vendorInfo;
        this.isTransferred = isTransferred
    }
}