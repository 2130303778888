import { GradesDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';


export class AllGradesResponseModel extends GlobalResponseObject {
    data?: GradesDto[];
   
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: GradesDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}

