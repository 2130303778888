import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Form, Pagination, Modal } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';
import { GrnData, IndentRequest } from '@gtpl/shared-models/raw-material-procurement';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, CloseSquareOutlined, SnippetsFilled, PrinterOutlined, ReconciliationOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GrnForm } from '@gtpl/pages/procurement/packing-materials-components/grn-form';
import moment from 'moment';
import { RawMaterialGrnForm } from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/raw-material-grn-form';


import { Link, Redirect, Route } from 'react-router-dom';
import { GrnDetailsDto, AllGrnDto } from '@gtpl/shared-models/procurement-management';
import { PmStatusEnum, StatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import './raw-material-grn-grid.css';

import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { UnitTypeEnum } from '@gtpl/shared-models/masters';

/* eslint-disable-next-line */
export interface RawMaterialGrnGridProps { }

export function RawMaterialGrnGrid(props: RawMaterialGrnGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [grnId, setGrnIdState] = useState<number>(null);
  const [rmGrnInspectionId, setRmInspectionIdState] = useState<number>(null);
  const [grnData, setGrnData] = useState<any[]>([]);
  const [grnDetails, setGrnDetails] = useState<GrnData>(undefined);
  const [selectedGrn, setSelectedGrn] = useState<any>(undefined);
  const [object, setObject] = useState(null);
  const [selectedProductData, setSelectedProductData] = useState<any>(undefined);
  const [productsBtnDisable, setProductsBtnDisable] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { TabPane } = Tabs;
  const gnrService = new RMGrnService();
  const indentService = new IndentService();


  useEffect(() => {
    getGrnDetails();
    console.log(localStorage)
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }, []);

  useEffect(() => {
    if (grnData) {
      console.log(grnData)
    }
  }, [grnData]);

  /**
   * 
   */
  const getGrnDetails = () => {
    setGrnData([]);
    console.log(Number(localStorage.getItem('unit_id')))
    gnrService.getAllGrnData(new UnitRequest(Number(localStorage.getItem('unit_id')))).then(res => {
      if (res.status) {
        setGrnData(res.data);
      } else {
        if (res.intlCode) {
          setGrnData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setGrnData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const assigntoRm = (grnData: GrnData, index: number) => {
    form.validateFields().then(res => {
      console.log(grnData);
      grnData.receivedBoxes = form.getFieldValue("receivedBoxes" + index);
      setProductsBtnDisable(true);
      gnrService.assignToRM(grnData).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('RM Inserted Successfully');
          setProductsBtnDisable(false);
          getGrnDetails();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        setGrnData([]);
        AlertMessages.getErrorMessage(err.message);
      })

    })
  }

  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.grnId)

  }

  const setGrnId = (rowdata) => {
    setGrnIdState(rowdata.grnId)

  }







  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const closeOrOpenGrn = (indentData: IndentRequest) => {
    console.log(indentData);
    indentData.grnCompleted = indentData.grnCompleted ? false : true;
    console.log(indentData);
    indentService.openOrCloseGrn(indentData).then(res => {
      console.log(res);
      if (res.status) {
        getGrnDetails();
        AlertMessages.getSuccessMessage('Updated');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  //TO open the form for updation
  // const openFormWithData = (viewData: ProductDto) => {
  //   setDrawerVisible(true);
  //   setSelectedProductData(viewData);
  // }
  const updateReceivedBoxes = (e, grnId: number, boxes: number) => {
    if (e > Number(boxes)) {
      AlertMessages.getWarningMessage("Received Boxes exceeded actual Grn Quantity. ");
    }
    // gnrService.updateReceivedBoxes({receivedBoxes:e,grnId:grnId}).then((res) => {
    //   if(res.status){
    //     setGrnDetails(res.data);
    //     AlertMessages.getSuccessMessage(res.internalMessage);
    //   }
    //   else{
    //     if (res.intlCode) {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     } else {
    //       AlertMessages.getErrorMessage(res.internalMessage);
    //     }
    //   }
    // }).catch(err => {
    //   setGrnDetails(undefined);
    //   AlertMessages.getErrorMessage(err.message);
    // })
    console.log(e);
    console.log(grnId);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unit'),
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      // responsive: ['lg'],
      sorter: (a, b) => moment(a.grnDate).unix() - moment(b.grnDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('grnDate'),
      render: (value, record: GrnDetailsDto, index) => {
        console.log(record)
        return moment(record.grnDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'GRN Number',
      dataIndex: 'grnNumber',
      sorter: (a, b) => a.grnNumber.localeCompare(b.grnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnNumber'),
      render: (value, record: GrnDetailsDto, index) => {
        return record.grnNumber ? record.grnNumber.toString().slice(-7) : record.grnNumber
      }

    },
    {
      title: 'Indent Number',
      dataIndex: 'indentNumber',
      sorter: (a, b) => a.indentNumber.localeCompare(b.indentNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNumber'),
      // render: (value, record: AllGrnDto, index) => {
      //   return (record.poId) ? record.poId.poNumber : '';
      // }
    },
    {
      title: 'Indent Person',
      dataIndex: 'indentPerson',
      sorter: (a, b) => a.indentPerson.localeCompare(b.indentPerson),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentPerson'),
      // render: (value, record: AllGrnDto, index) => {
      //   return (record.poId) ? record.poId.poNumber : '';
      // }
    },
    {
      title: 'Supplier Type',
      dataIndex: 'supplierType',
      key: 'supplierType',
      // width: 180,
      align: 'left',
      sorter: (a, b) => a.supplierType.localeCompare(b.supplierType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('supplierType')
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier',
      key: 'supplier',
      // width: 180,
      // responsive: ['sm'],
      align: 'left',
      sorter: (a, b) => a.supplier.localeCompare(b.supplier),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('supplier')
      // render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.status.length - b.status.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('status')
    // },
    //   {
    //     title: 'Status',
    //     dataIndex: 'isActive',
    //     render: (isActive, rowData) => (
    //       <>
    //         {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
    //       </>
    //     ),
    //     filters: [
    //       {
    //         text: 'Active',
    //         value: true,
    //       },
    //       {
    //         text: 'InActive',
    //         value: false,
    //       },
    //     ],
    //     filterMultiple: false,
    //     onFilter: (value, record) => {
    //       // === is not work
    //       return record.isActive === value;
    //     },
    // },

    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          {rowData.assignToRm === false ?
            <span>
              <EditOutlined className={'editSamplTypeIcon'} type="edit"
                onClick={() => {
                  openFormWithData(rowData);
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              />

            </span>
            : ''}
          <Divider type="vertical" />
          {(rmGrnInspectionId) ? <Redirect
            to={{
              pathname: "/rm-grn-detailview",
              state: rmGrnInspectionId
            }}
          /> : null}

          <Divider type="vertical" />
          {(grnId) ? <Redirect
            to={{
              pathname: "/rm-grn-detailview",
              state: grnId
            }}
          /> : null}
          {(JSON.parse(localStorage.getItem('plantType')) === UnitTypeEnum.PLANT) || (JSON.parse(localStorage.getItem('plantType')) === UnitTypeEnum.SUB_PLANT) ? 
          <EyeOutlined type="view"
            onClick={() => {

              // if (rowData.isActive) {
              setGrnId(rowData);
              // } else {
              //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
              // }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />:<></>}
        </span>
      )
    },

    // {
    //   title: 'RM Received',
    //   dataIndex: 'assignToRm',
    //   render: (text, rowData: any, index) => (

    //     <span>
    //       {rowData.assignToRm === 0 ? rowData.plantId && rowData.saleOrderId ?
    //         <Button onClick={() => { assigntoRm(rowData, index) }} disabled={productsBtnDisable}>
    //           Receive RM
    //         </Button> : 'Fill RM data to Receive' : 'RM Received'
    //       }


    //     </span>
    //   )
    // },
    // {
    //   title: 'Close Action',
    //   dataIndex: 'closeGrn',
    //   render: (text, rowData: any, index) => (
    //     <span> <Popconfirm onConfirm={e =>{closeOrOpenGrn(rowData);}}
    //     title={
    //       rowData.isActive
    //         ? 'Are you sure to Close GRN ?'
    //         :  'Are you sure to Open GRN ?'
    //     }
    //   >
    //     <Switch
    //     checkedChildren={<CheckOutlined />}
    //     unCheckedChildren={<CloseOutlined />}
    //     checked={!(rowData.grnCompleted)}
    //     />
    //   </Popconfirm>

    //   </span>
    //   )
    // },

  ];

  const updateGrn = (grnData: GrnData) => {
    console.log(grnData)
    gnrService.updateGrn(grnData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        setDrawerVisible(false);
        getGrnDetails();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const openFormWithData = (viewData: GrnData) => {
    viewData.grnDate = moment(viewData.grnDate);
    setDrawerVisible(true);
    setSelectedGrn(viewData);
  };
  // const closeDrawer=()=>{
  //   setDrawerVisible(false);
  // }

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }


  return (

    <Card size="small" title={<span style={{ color: 'white' }} >GRN Details</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    // extra={<Link to='/rm-grn-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} 
    >
      <br></br>
      <Row gutter={40}>
        <Col>
          <Card title={'Total GRN: ' + grnData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        {/* <Col>
           <Card title={'Active: ' + grnData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active: ' + grnData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col> */}
      </Row>
      {/* <Row gutter={40}>
        <br></br>

      </Row> */}
      <br></br>
      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane
          key="1"
          tab={<span style={{ color: "#52c41a" }}>{'Open: ' + (grnData.filter(i => i.assignToRm == '0').length)}</span>}
        >
          <Table
            rowKey={record => record.grnId}
            columns={columnsSkelton}
            dataSource={grnData.filter(i => i.assignToRm == 0)}
            pagination={{
              onChange(current) {
                setPage(current);
              },
              pageSizeOptions: ['20', '30', '40', '50'],
              showSizeChanger: true
            }}
            onChange={onChange}
            bordered

            scroll={{ x: 500 }}
          />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '65%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <RawMaterialGrnForm key={Date.now()}
                updateItem={updateGrn}
                isUpdate={true}
                grnData={selectedGrn}
                closeForm={closeDrawer}
                indentId={undefined} />
            </Card>
          </Drawer>
        </TabPane>
        <TabPane
          key="2"
          tab={<span style={{ color: "#52c41a" }}>{'Received: ' + (grnData.filter(i => i.assignToRm == '1').length)}</span>}
        >
          <Table
            rowKey={record => record.grnId}
            columns={columnsSkelton}
            dataSource={grnData.filter(ij => ij.assignToRm == 1)}
            pagination={{
              onChange(current) {
                setPage(current);
              },
              pageSizeOptions: ['20', '30', '40', '50'],
              showSizeChanger: true
            }}
            onChange={onChange}
            bordered

            scroll={{ x: 500 }}
          />
          <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '65%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <RawMaterialGrnForm key={Date.now()}
                updateItem={updateGrn}
                isUpdate={true}
                grnData={selectedGrn}
                closeForm={closeDrawer}
                indentId={undefined} />
            </Card>
          </Drawer>
        </TabPane>
      </Tabs>
      {/* } */}

      {/* <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible2}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel1}
        footer={[

        ]}
      >
        <RMGrnPrint />
      </Modal> */}


    </Card>
  );
}

export default RawMaterialGrnGrid;
