import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SequenceDataModel } from './sequence-data.model';
import { ValAddOpModel } from './val-add-op.model';

export class ValAddOperationsModel extends GlobalResponseObject{
    data?: ValAddOpModel[] ;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ValAddOpModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
 