export class ResourceOperationEfficiencyDto {
    resourceOperationEfficiencyId?: number;
    operation:string;
    variantCode:string;
    hrsPerShift?:number;
    efficiency:number;
    minCount:number;
    maxCount:number;
    createdUser:string;
    isActive?: boolean;
    constructor(resourceOperationEfficiencyId:number,operation:string,variantCode:string,efficiency:number,minCount:number,maxCount:number,createdUser:string,hrsPerShift:number,isActive?:boolean){
        this.resourceOperationEfficiencyId = resourceOperationEfficiencyId;
        this.operation = operation;
        this.variantCode = variantCode;
        this.hrsPerShift=hrsPerShift;
        this.efficiency=efficiency;
        this.minCount=minCount;
        this.maxCount=maxCount;
        this.createdUser =createdUser;
        this.isActive = isActive;
    }
}

export const ResourceOperationEfficiencyDtoDefault : ResourceOperationEfficiencyDto = {
    resourceOperationEfficiencyId: 0,
    operation: '',
    variantCode: "",
    hrsPerShift:0,
    efficiency:0,
    minCount:0,
    maxCount:0,
    createdUser:"",
    isActive:true,
};

