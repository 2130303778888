import axios from 'axios';
import connection from './connection';
import {AllResourceOperationEfficiencyResponseModel, ResourceOperationEfficiencyDto, VariantEfficiencyRequest} from '@gtpl/shared-models/planning'
import {CodeListDto, CodeListResponseModel, CodeListStatus} from '@gtpl/shared-models/planning'


export class ResourceOpEfficiencyService {
  URL = connection.DEPLOY_URL + '/resource-operation-efficiency';

  async createResourceOperationEfficiency(resourceOpeEfficiencyReq: ResourceOperationEfficiencyDto[]): Promise<AllResourceOperationEfficiencyResponseModel> {
    return await axios
      .post(this.URL + '/createResourceOperationEfficiency', resourceOpeEfficiencyReq)
      .then((res) => {
        return res.data;
      });
  }

  async updateResourceOperationEfficiency(resourceOpeEfficiencyReq: ResourceOperationEfficiencyDto[]): Promise<AllResourceOperationEfficiencyResponseModel> {
    return await axios
      .post(this.URL + '/updateResourceOperationEfficiency', resourceOpeEfficiencyReq)
      .then((res) => {
        return res.data;
      });
  }

  async getEfficiencyByVariant(variantEffReq: VariantEfficiencyRequest): Promise<AllResourceOperationEfficiencyResponseModel> {
    return await axios
      .post(this.URL + '/getEfficiencyByVariant', variantEffReq)
      .then((res) => {
        return res.data;
      });
  }

  
}
