import React, { useEffect, useReducer, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Space, Upload, UploadProps, InputNumber } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Brands, BrandsRequest, CategoryItemReq, ItemCategoryRequest, ItemForBrandReq, ItemsDropDownDto, ItemSubCategoryDropDownDto, MasterBrandDto, MasterBrandsDropDownDto, SizesDropDownDto, SubCatReqForCategoryId, LocationMappingRmRackPostionReq } from '@gtpl/shared-models/masters';
import { ItemsService, ItemSubCategoryService, MasterBrandService, RmRackPositionService, SizesService } from '@gtpl/shared-services/masters';
import { PackingCategoryEnum } from '@gtpl/shared-models/common-models';
import { StockService } from '@gtpl/shared-services/procurement';
import { StockDTO } from '@gtpl/shared-models/warehouse-management';
import { StockDto } from '@gtpl/shared-models/procurement-management';
const { Option } = Select;
export interface PackingStockEntryProps {
  isUpdate: boolean;
  closeForm: () => void;
}
export function PackingStockEntry(
  props: PackingStockEntryProps
) {


  const [form] = Form.useForm();
  const brandsService = new MasterBrandService()
  const [brandsInfo, setBrandsInfo] = useState<Brands[]>([]);
  const [packMaterialRequiredFields, setPackMaterialRequiredFields] = useState<boolean>(false);
  const [brandData, setBrandData] = useState<MasterBrandsDropDownDto[]>([]);
  const subCategoryService = new ItemSubCategoryService();
  const [itemSubCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [itemData, setItemData] = useState<ItemsDropDownDto[]>([]);
  const itemService = new ItemsService();
  const stockService = new StockService;
  const [disable, setDisable] = useState<boolean>(false);
  const [locations, setLocations] = useState<any[]>([]);
  const locationService = new RmRackPositionService;
  const units = [{value:2,label:'UNIT 1'},{value:3,label:'UNIT 2'}]

  useEffect(() => {

    getAllMasterBrandsDropDown();
    getAllItemSubCategories();
    // getAllLocations()
  }, [])


  const getAllMasterBrandsDropDown = () => {
    brandsService.getAllMasterBrandsDropDown().then((res) => {
      if (res.status) {
        setBrandData(res.data);
      } else {
        setBrandData(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setBrandData(undefined);
    });
  }



  const getAllItemSubCategories = () => {
    form.setFieldsValue({ itemSubCategoryId: undefined, itemId: undefined })
    const itemCategoryId = 6
    const itemsRequest = new SubCatReqForCategoryId(itemCategoryId);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then(res => {
      if (res.status) {
        setSubCategoryData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getItemData = () => {
    form.setFieldsValue({ itemId: undefined, physicalQunatity: undefined })
    const brandId = form.getFieldValue('brandId')
    const itemSubCategoryId = form.getFieldValue('itemSubCategoryId')
    const req = new ItemForBrandReq()
    req.brandId = brandId;
    req.itemSubCategoryId = itemSubCategoryId
    itemService.getItemForBrandAndSubCategory(req).then(res => {
      if (res.status) {
        setItemData(res.data)
      } else {
        setItemData([])
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message)
    })

  }



  const onReset = () => {
    console.log('');
    form.resetFields();

  }
  const onFocus = () => {
    //console.log('focus');
  }

  const onSearch = (val) => {
    //console.log('search:', val);
  }
  const onBlur = () => {
    //console.log('blur');
  }


  const saveStock = (values: StockDto) => {
    setDisable(true)
    const unitId = Number(localStorage.getItem('unit_id'))
    const itemId = form.getFieldValue('itemId')
    const sizeId = itemData.find(item => item.itemId = itemId)
    values.unitId = form.getFieldValue('unitId')
    values.sizeId = sizeId.sizeId
    values.locationId = form.getFieldValue('locationId')
    stockService.stockEntry(values).then((res) => {
      setDisable(false)
      if (res.status) {
        AlertMessages.getSuccessMessage('Stock saved sucessfully')

      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      setDisable(false)
      AlertMessages.getErrorMessage(err.message)
    });
    form.setFieldsValue({
      itemId: undefined,
      physicalQuantity: undefined
    })

  }

  const getAllLocations = () => {
    locationService.getLocationsForStockEntryScreen().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setLocations(res.data);
      } else {
        if (res.intlCode) {
          setLocations([]);
        } else {
          setLocations([]);
        }
      }
    }).catch(err => {
      setLocations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getLocationData = (val,rowData) => {
    if(form.getFieldValue('brandId') && form.getFieldValue('itemSubCategoryId') && form.getFieldValue('unitId')){

      locationService.getRmRackPostionsForLocationMapping(new LocationMappingRmRackPostionReq(form.getFieldValue('unitId'), form.getFieldValue('brandId'))).then(res => {
        if (res.status) {
          // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
          setLocations(res.data);
        } else {
          if (res.intlCode) {
            setLocations([]);
          } else {
            setLocations([]);
          }
        }
      }).catch(err => {
        setLocations([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }else{
      AlertMessages.getSuccessMessage('please select misssed fields to get locations')
    }
  }

  

  return (

    <Card size="small" title={<span style={{ color: 'white' }}>Stock Entry</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
      <Form form={form} layout='vertical' name='control-hooks' onFinish={saveStock}>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item
              label="Brand"
              name={'brandId'}
              // rules={[{ required: true, message: 'Missing Brand name' }]}
            >
              <Select
                placeholder="Select Brand"
                allowClear
                showSearch
                onChange={getAllItemSubCategories}
                onFocus={onFocus}
                onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value={undefined}>please select Brand</Option>;
                {brandData?.map(item => {
                  return <Option value={item.masterBrandId}>{item.masterBrandName}</Option>;
                })}
              </Select>


            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="itemSubCategoryId"
              label="Item Sub Category"
              rules={[
                {
                  required: true,
                  message: 'Item Sub Category is required'
                },
                {
                  pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                  message: `Enter valid Item Sub Category `,
                },
              ]}

            >
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onSelect={getItemData}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Sub Category</Option>
                {itemSubCategoryData.map((subCategory) => {
                  return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                })}
              </Select>
            </Form.Item>
          </Col><br />
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item
              name="itemId"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: 'Item Name is required',
                },

              ]}>
              <Select
                showSearch
                // style={{ width: 240 }}
                placeholder="Select Item"
                optionFilterProp="children"
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={0}>Select Item</Option>
                {itemData.map((item) => {
                  return <Option key={item.itemId} value={item.itemId}>{item.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="unitId" label="Unit" rules={[{ required: true, message: 'Missed Unit' }]}>
              <Select
                onChange = {getLocationData}
                showSearch allowClear placeholder="Select Unit" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={false}
              >
                {units.map((i) => {
                  return <Option value={i.value}>{i.label}</Option>
                })}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="locationId" label="Location" rules={[{ required: true, message: 'Missed Location' }]}>
              <Select
                showSearch allowClear placeholder="Select Location" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={false}
              >
                {locations.map((locationData) => {
                  return <Option  value={locationData.rackPositionId}>{locationData.location}</Option>
                })}

              </Select>
            </Form.Item>
          </Col>
          
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} >
            <Form.Item
              name="physicalQuantity"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: 'Minimum Quantity is required',
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end'>

          <Col span={4} style={{ textAlign: 'right', paddingTop: '30px' }}>
            <Button type="primary" disabled={disable} htmlType="submit" >
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>

          </Col>

        </Row>
      </Form>
    </Card>


  );
}

export default PackingStockEntry;