import React, { useEffect, useRef, useState } from 'react';
import { Card, Input, Button, Select, Col, Form, Row } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './material-preperation-grid.css';
import Table, { ColumnProps } from 'antd/lib/table';
import { GrnService, StockService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import moment from 'moment';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { AllGrnReportDto, MaterialPreparationReq } from '@gtpl/shared-models/procurement-management';


/* eslint-disable-next-line */
export interface MaterialPreperationGridProps { }

export function MaterialPreperationGrid(
  props: MaterialPreperationGridProps
) {
  const Option = Select;
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const service = new StockService();
  const [data, setData] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>()
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [selectedunit, setUnit] = useState<number>(0)
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService();
  const soService = new GrnService;
  const [soDrop, setSoDrop] = useState<AllGrnReportDto[]>([]);
  const [itemDrop, setItemDrop] = useState<AllGrnReportDto[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any[]>([]);
const [status , setStatus] = useState<any[]>([]);
  

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    getMaterialPreparationData();
    getAllPlants();
    getSoDropDown();
    getItemDropDown();
    getStatusDropDown();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
  }, [])

  const getMaterialPreparationData = () => {
    const req = new MaterialPreparationReq()
    let saleOrderId = form.getFieldValue('saleOrderId');
    let itemId = form.getFieldValue('item')
    let status = form.getFieldValue('status')
    let selectedData = filtersData;
    req.saleOrderId = saleOrderId;
    req.itemId = itemId;
    req.status = status;
    if (status == 'Consumed') {
      setIsFiltered(true)
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    service.getMaterialPreparationData(req).then((res) => {
      if (res.status) {
        setData(res.data)
        setFilteredData(res.data?.filter(i => i.status != 'Consumed'))
        setFiltersData(res.data)
        // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      setData([]);
      AlertMessages.getErrorMessage(err.message);
    })
    setData(selectedData)
  }
  const getSoDropDown = () => {
    service.getSoDropDown({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
      if (res.status) {
        setSoDrop(res.data);
      } else {
        if (res.intlCode) {
          setSoDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoDrop([]);
    })
  }
  const getItemDropDown = () => {
    service.getItemDropDown({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
      if (res.status) {
        setItemDrop(res.data);
      } else {
        if (res.intlCode) {
          setItemDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDrop([]);
    })
  }
  const getStatusDropDown = () => {
    service.getStatusDropDown({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
      if (res.status) {
        setStatus(res.data);
      } else {
        if (res.intlCode) {
          setStatus([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setStatus([]);
    })
  }
  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }
  const onReset = () => {
    form.resetFields();
    getMaterialPreparationData();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
  }
  const handleUnit = (value) => {
    setUnit(value)
  }
  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      width: '50px',
      key: 'sno',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * 50 + (index + 1)
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.unit?.localeCompare(b.unit),
      ...getColumnSearchProps('unit')

    },
    {
      title: 'Customer PO No',
      dataIndex: 'saleOrderNo',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.saleOrderNo?.localeCompare(b.saleOrderNo),
      ...getColumnSearchProps('saleOrderNo')

    },

    {
      title: 'SO Job',
      dataIndex: 'soItem',
      width: '300px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.soItem?.localeCompare(b.soItem),
      ...getColumnSearchProps('soItem'),
    },
    {
      title: 'Item',
      dataIndex: 'item',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.item?.localeCompare(b.item),
      ...getColumnSearchProps('item')

    },

    {
      title: 'Item Bar Code',
      dataIndex: 'itemBarCode',
      width: '150px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.itemBarCode?.localeCompare(b.itemBarCode),
    },
    {
      title: 'Prepared Qty',
      dataIndex: 'preQty',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.preQty - b.preQty,
      render: (value, record) => {
        return record.preQty ? record.preQty : '-'
      }
    },
    {
      title: 'Consumed Qty',
      dataIndex: 'consQty',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.consQty - b.consQty,
      render: (value, record) => {
        return record.consQty ? record.consQty : '-'
      }
    },
    {
      title: 'Prepared Date',
      dataIndex: 'createdDate',
      width: 150,
      // responsive: ['lg'],
      sorter: (a, b) => a.createdDate?.localeCompare(b.createdDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.createdDate ? moment(record.createdDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    {
      title: "Aging",
      dataIndex: 'aging',
      width: 100,
      sorter: (a, b) => (Math.floor((new Date().getTime() - new Date(moment(a.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date().getTime() - new Date(moment(b.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date().getTime() - new Date(moment(record.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
          // props: {
          //   style: {
          //     background: Math.floor((new Date().getTime() - new Date(moment(record.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 < 50 ? "Green" : (Math.floor((new Date().getTime() - new Date(moment(record.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 50 && Math.floor((new Date().getTime() - new Date(moment(record.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1) < 100 ? "yellow " : "red",
          //     color: Math.floor((new Date().getTime() - new Date(moment(record.createdDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 > 100 ? "white" : "black"
          //   },
          // }
        };
        return obj;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.status?.localeCompare(b.status),
      filters: [
        { text: 'Consumed', value: 'Consumed' },
        { text: 'Partially Consumed', value: 'Partially Consumed' },
        { text: 'Prepared', value: 'Prepared' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        if (value == 'Consumed') {
          setIsFiltered(true)
        }
        return record.status === value
      },

    },
    {
      title: 'Bar Code',
      dataIndex: 'barCode',
      width: 250,
      align: 'left',
      render: (value, record) => {
        return record.barCode ? record.barCode : '-'
      }
    }
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Material Preparation</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/material-preperation-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
        <Form layout={"vertical"} autoComplete="off" form={form} >
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Form.Item
                name="unitId"
                label=" Unit"
                rules={[
                  {
                    required: false, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Unit"
                  allowClear
                  style={{ width: '100%' }}
                  onChange={handleUnit}
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
              <Form.Item name="saleOrderId"
                label="Customer PO"
                rules=
                {[{ required: false, message: ' Select ' },]}>
                <Select showSearch placeholder="Select Customer Po"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Sale Order</Option>
                  {soDrop.map((data) => {
                    return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.soNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 9 }}>
              <Form.Item label="Item Name"
                name='item'
              >
                <Select showSearch
                  allowClear
                  placeholder="Select Item"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {itemDrop === undefined ? '' : itemDrop.map(dropData => {
                    return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.item}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 3 }}>
              <Form.Item label="Status"
                name='status'
              >
                <Select showSearch
                  allowClear
                  placeholder="Select Item"
                  mode='multiple'
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {status === undefined ? '' : status.map(statusDrop => {
                    return <Option key={statusDrop.status} value={statusDrop.status}>{statusDrop.status}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ padding: '20px',marginTop:'30px' }}>
              <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getMaterialPreparationData()}>
                Get Report
              </Button>
              <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey={record => record.saleOrderId}
          columns={columns}
          dataSource={isFiltered ? data : filteredData}
          pagination={false}
          onChange={onChange}
          scroll={{ x: true }}
          size='small'
          bordered
        />
      </Card>
    </>
  );
}

export default MaterialPreperationGrid;
