import { CandidateType, SourceType } from "@gtpl/shared-models/common-models";
export class ProfilesDto{
    profileId: number;
    candidateName: string;
    jobDescription:string;
    profileDate: any;
    mobileNumber: string;
    qualification: string;
    skills: string;
    // stack: string;
    altMobileNumber: string;
    expectedCTC: number;
    candidateType: CandidateType;
    currentCTC: number;
    noticePeriod: number;
    experience: number;
    sourceType: SourceType;
    referredBy: string;
    remarks: string;
    isActive: boolean;
    createdAt: Date;
    createdUser?: string;
    updatedAt: Date;
    updatedUser?: string;
    versionFlag: number;

    constructor(
    profileId: number,
    candidateName: string,
    profileDate: any,
    mobileNumber: string,
    qualification: string,
    skills: string,
    // stack: string,
    altMobileNumber: string,
    expectedCTC: number,
    candidateType: CandidateType,
    currentCTC: number,
    noticePeriod: number,
    experience: number,
    sourceType: SourceType,
    referredBy: string,
    remarks: string,
    isActive: boolean,
    createdAt: Date,
    updatedAt: Date,
    versionFlag: number,
    createdUser?: string,    
    updatedUser?: string,    
    ){
        this.profileId = profileId;
        this.candidateName = candidateName;
        this.profileDate = profileDate;
        this.mobileNumber = mobileNumber;
        this.qualification = qualification;
        this.skills = skills;
        // this.stack = stack;
        this.altMobileNumber = altMobileNumber;
        this.expectedCTC = expectedCTC;
        this.candidateType = candidateType;
        this.currentCTC = currentCTC;
        this.noticePeriod = noticePeriod;
        this.experience = experience;
        this.sourceType = sourceType;
        this.referredBy = referredBy;
        this.remarks = remarks;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;        
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;      

    }
}