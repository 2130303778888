import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { HarvestingModelDto } from './harvesting.model.dto';

export class GetHarvestingResponseModel extends GlobalResponseObject{
    data?: HarvestingModelDto[];
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: HarvestingModelDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}