import { JobCompletedEnum, JobStatusEnum } from "@gtpl/shared-models/common-models";

export class ValAddInventoryDto{
    numOfLots: number;
    qtytobeprocessed: number;
    valAddInventory: number;
    gridData:ValAddGridDto[];
   
    constructor(
        numOfLots: number,
        qtytobeprocessed: number,
        valAddInventory: number,
        gridData:ValAddGridDto[]
    ){
        this.numOfLots = numOfLots;
        this.qtytobeprocessed = qtytobeprocessed;
        this.valAddInventory = valAddInventory;
        this.gridData = gridData;
    }
}
export class ValAddGridDto{
    jobNumber: string;
    workStation: string;
    lotNo: string;
    soNumber: string;
    grade: number;
    valueAddition: string;
    boxes: number;
    opboxes?: number;
    quantity: number;
    jobStatus: JobCompletedEnum;
    productionLogId: number;
    opQuantity?:number;
    workstationCapacity?: number;
    poNumber?:string;
    saleOrderId?:number;


    constructor(
        jobNumber: string,
        workStation: string,
        lotNo: string,
        soNumber: string,
        grade: number,
        valueAddition: string,
        boxes: number,
        quantity: number, 
        jobStatus: JobCompletedEnum,
        productionLogId:number,
        opboxes?: number,
        opQuantity?: number,
        workstationCapacity?: number,
        poNumber?:string,
        saleOrderId?:number

        
       ){
         this.jobNumber = jobNumber;
         this.lotNo = lotNo;
         this.workStation = workStation;
         this.soNumber = soNumber;
         this.grade = grade;
         this.valueAddition = valueAddition;
         this.boxes = boxes;
         this.quantity = quantity;
         this.jobStatus = jobStatus;
         this.opboxes = opboxes;
         this.productionLogId = productionLogId;
         this.opQuantity = opQuantity;
         this.workstationCapacity = workstationCapacity;
         this.poNumber = poNumber;
         this.saleOrderId = saleOrderId
       }
}