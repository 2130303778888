export class GradingSuggestionDto {
    gradeSuggestionId:number;
    minGrade: number;
    maxGrade: number;
    productId:number;
    plantId:number;
    date:any;
    saleOrderId:number;
    sequence:number;
    priority:number;
    plannedQuantity:number;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    constructor( gradeSuggestionId:number,
        minGrade: number,
        maxGrade: number,
        productId:number,
        plantId:number,
        date:Date,
        saleOrderId:number,
        sequence:number,
        priority:number,
        plannedQuanity:number,
        isActive: boolean,
        createdAt : Date | any,
        createdUser : string,
        updatedAt : Date | any,
        updatedUser : string,
        versionFlag : number,
       ){
        this.gradeSuggestionId = gradeSuggestionId,
        this.minGrade = minGrade,
        this.maxGrade = maxGrade,
        this.productId = productId,
        this.plantId = plantId,
        this.date = date,
        this.saleOrderId = saleOrderId,
        this.sequence = sequence,
        this.priority = priority,
        this.plannedQuantity = plannedQuanity,
        this.isActive = isActive,
        this.createdAt  = createdAt,
        this.createdUser  = createdUser,
        this.updatedAt  = updatedAt,
        this.updatedUser  = updatedUser,
        this.versionFlag  = versionFlag
        
        }

}

