import { RmEvaluationDto, RmEvaluationSamplesDto, RmEvaluationResponse, GrnRequest, ReWeighingItemsDto } from "@gtpl/shared-models/raw-material-procurement";
import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";

export class RMReWeighingService {
    URL = connection.DEPLOY_URL + '/rm-re-weighing';

    async createRmReWeighing(data: ReWeighingItemsDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createRmReWeighing', data)
            .then(res => {
                return res.data
            })
    }

    async getRmReWeighingData(req) {
        return await axios.post(this.URL + '/getRmReWeighingData', req)
            .then(res => {
                return res.data
            })
    }

    async deleteReWeighItem(req) {
        return await axios.post(this.URL + '/deleteReWeighItem', req)
            .then(res => {
                return res.data
            })
    }
}