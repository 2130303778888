import axios from 'axios';
import { OperatonsRegisterDto, OperationsRegisterResponseModel} from '@gtpl/shared-models/production-management';
import connection from './connection';
import { JobStatusEnum, ShiftsEnum } from '@gtpl/shared-models/common-models';

export class OperationsRegisterService {
URL = connection.DEPLOY_URL + '/production-inventory';
  async getAllOperationReports(): Promise<OperationsRegisterResponseModel> {
    return await axios.post(this.URL + '/getAllOperationReports')
        .then(res => {
            return res.data
        })     
    }
    
  
} 