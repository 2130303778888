import { JobCompletedEnum, OperationTypeEnum, SubPlantCodesEnum, SubPlantOperationStatusEnum } from "@gtpl/shared-models/common-models";


export class WorkStatusDto {
  productionLogId?: number;
  lotNo: string;
  saleOrderId: number;
  soNumber: string;
  saleOrderItemId: number;
  jobNumber: string;
  Grade: number;
  ipBoxes: number;
  opBoxes: number;
  ipQuantity: number;
  opQuantity: number;
  operationType: OperationTypeEnum;
  jobStatus: JobCompletedEnum;
  isReceived?: SubPlantOperationStatusEnum;
  plantId?: number
  dcNumber?: string
  mrnNumber?: string
  subPlantId?: number
  boxWeight?: number;
  wastageQuantity?: number;
  rejectedQuantity?:number;
  newOpQuantity?:number;
  rejectionReason?: string;
  constructor(
    lotNo: string,
    saleOrderId: number,
    soNumber: string,
    saleOrderItemId: number,
    jobNumber: string,
    Grade: number,
    ipBoxes: number,
    opBoxes: number,
    ipQuantity: number,
    opQuantity: number,
    operationType: OperationTypeEnum,
    jobStatus: JobCompletedEnum,
    isReceived?: SubPlantOperationStatusEnum,
    productionLogId?: number,
    plantId?: number,
    dcNumber?: string,
    mrnNumber?: string,
    subPlantId?: number,
    boxWeight?: number,
    wastageQuantity?: number,
    rejectedQuantity?:number,
    newOpQuantity?:number,
    rejectionReason?: string
  ) {
    this.lotNo = lotNo;
    this.saleOrderId = saleOrderId;
    this.soNumber = soNumber;
    this.saleOrderItemId = saleOrderItemId;
    this.jobNumber = jobNumber;
    this.Grade = Grade;
    this.ipBoxes = ipBoxes;
    this.opBoxes = opBoxes;
    this.ipQuantity = ipQuantity;
    this.opQuantity = opQuantity;
    this.operationType = operationType;
    this.jobStatus = jobStatus;
    this.isReceived = isReceived;
    this.productionLogId = productionLogId;
    this.plantId = plantId;
    this.dcNumber = dcNumber;
    this.mrnNumber = mrnNumber;
    this.subPlantId = subPlantId;
    this.boxWeight = boxWeight;
    this.wastageQuantity = wastageQuantity;
    this.rejectedQuantity = rejectedQuantity;
    this.newOpQuantity = newOpQuantity;
    this.rejectionReason = rejectionReason;
  }
}