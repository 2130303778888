// import { ContactModel, ContactModelDefault  } from "@brandix-apps/shared-models/master-data-management";

export class UserModel {
    userId:string;
    firstName:string;
    middleName:string;
    lastName:string;
    gender:string;
    refId:string;
    dateOfBirth: string;
    createdAt: Date | any;
    updatedAt: Date | any;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
    employeeNumber?: string;
}

export const UserModelDefault: UserModel = {
    userId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    refId: '',
    dateOfBirth: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdUser: '',
    updatedUser: '',
    versionFlag: 0,
    employeeNumber: ''
}
