import { ItemCategoryDropDownDto, ItemsDropDownDto, ItemSubCategoryDropDownDto, SizesDropDownDto, TaxDropDownDto } from "@gtpl/shared-models/masters";

export class PoItemsDetails {
    itemCategory:ItemCategoryDropDownDto;
    itemSubCategory:ItemSubCategoryDropDownDto;
    item:ItemsDropDownDto;
    size:SizesDropDownDto;
    quantity:number;
    unitPrice:number;
    tax:TaxDropDownDto;
    taxAmount:number;
    discountPercentage:number;
    discountAmount:number;
    transportation:number;
    Amount:number;
    bomItemId:number;
    purchaseOrderId?:number;
    purchaseOrderItemId?:number;
    saleOrderId?:number;
    saleOrderNumber?:string;
    poNumber?:string;
    fromPo?:boolean;
    isActive?:boolean;
    availbleQty?:number;
    receivedQty?:number;

   /**
    * 
    * @param itemCategory 
    * @param item 
    * @param size 
    * @param quantity 
    * @param unitPrice 
    * @param tax 
    * @param taxAmount 
    * @param discountPercentage 
    * @param discountAmount 
    * @param transportation 
    * @param Amount 
    * @param bomItemId 
    * @param purchaseOrderId 
    * @param purchaseOrderItemId 
    * @param saleOrderId 
    * @param saleOrderNumber 
    */
    constructor(itemCategory:ItemCategoryDropDownDto, itemSubCategory:ItemSubCategoryDropDownDto, item:ItemsDropDownDto, size:SizesDropDownDto, quantity:number, unitPrice:number, tax:TaxDropDownDto, taxAmount:number, discountPercentage:number, discountAmount:number, transportation:number, Amount:number, bomItemId:number, purchaseOrderId?:number, purchaseOrderItemId?:number, saleOrderId?:number, saleOrderNumber?:string, poNumber?:string, fromPo?:boolean, isActive?:boolean,availbleQty?:number,receivedQty?:number){
     this.itemCategory = itemCategory;
     this.itemSubCategory = itemSubCategory;
     this.item = item;
     this.size = size;
     this.quantity = quantity;
     this.unitPrice = unitPrice;
     this.tax = tax;
     this.taxAmount = taxAmount;
     this.discountPercentage = discountPercentage;
     this.discountAmount = discountAmount;
     this.transportation = transportation;
     this.Amount = Amount;
     this.bomItemId = bomItemId;
     this.purchaseOrderId = purchaseOrderId;   
     this.purchaseOrderItemId = purchaseOrderItemId;   
     this.saleOrderId = saleOrderId;   
     this.saleOrderNumber = saleOrderNumber;   
     this.poNumber = poNumber;   
     this.fromPo = fromPo;   
     this.isActive = isActive;   
     this.availbleQty = availbleQty;   
     this.receivedQty = receivedQty;   
    }
}