export class SoandSoitemRequest{
    saleOrderId:number;
    saleOrderItemId:number;
    constructor(
        saleOrderId:number,
        saleOrderItemId:number
    ){
        this.saleOrderId=saleOrderId;
        this.saleOrderItemId=saleOrderItemId;
    }
}
export class LotAndOperationReq{
    lotNumber:string;
    operation:string;
    constructor(lotNumber:string,operation:string){
        this.lotNumber=lotNumber;
        this.operation=operation;
    }
}