import { AllRacksResponseModel, AllRmRacksResponseModel, CommonResponse, LocationRequestDto, RackDropDownDto, RackDropDownResponseModel, RackDto, RackResponseModel, RacksForRackId, RacksForZoneId, ReserveRequest, RmRackDropDownResponseModel, RmRackDto, RmRackResponseModel, RmZonesIdRequest, } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';

export class RmRackService {
    URL = connection.DEPLOY_URL + '/rm-racks';

    async createRmRack(rackDto: RmRackDto): Promise<RmRackResponseModel> {
        return await axios.post(this.URL + '/createRmRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async updateRmRack(rackDto: RmRackDto): Promise<RmRackResponseModel> {
        return await axios.post(this.URL + '/updateRmRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async getAllRmRacks(): Promise<AllRmRacksResponseModel> {
        return await axios.post(this.URL + '/getAllRmRacks')
            .then(res => {
                return res.data
            })
    }
    async activateOrDeactivateRmRack(rackDto: RmRackDto): Promise<RmRackResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateRmRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async getRmRacksForZoneId(zoneId: RmZonesIdRequest): Promise<RmRackDropDownResponseModel> {

        return await axios.post(this.URL + '/getRmRacksForZoneId', zoneId)
            .then(res => {
                return res.data
            })
    }

    // async getRacksForZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

    //     return await axios.post(this.URL + '/getRacksForZoneId', zoneId)
    //         .then(res => {
    //             return res.data
    //         })
    // }
    // async getRacksByZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

    //     return await axios.post(this.URL + '/getRacksForZone', zoneId)
    //         .then(res => {
    //             return res.data
    //         })
    // }
    // async getdummyRacksByZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

    //     return await axios.post(this.URL + '/getdummyRacksByZone', zoneId)
    //         .then(res => {
    //             return res.data
    //         })
    // }
    // async getRacksForRackId(racksForRackId: RacksForRackId): Promise<RackDropDownDto> {
    //     return await axios.post(this.URL + '/getRacksForRackId', racksForRackId)
    //         .then(res => {
    //             console.log(res.data);
    //             return res.data
    //         })
    // }

    // async getRackPositionsCount(req:ReserveRequest): Promise<any> {
    //     return await axios.post('http://206.189.138.212/erp_php/post_gres.php?plant_id='+req.plantId+'&positions='+req.positions)
    //         .then(res => {
    //             console.log(res);
    //             return res.data 
    //         })     
    //   }
    // async getRereserveRackPositions(req:ReserveRequest): Promise<any> {
    //     return await axios.post('http://206.189.138.212/erp_php/post_gres_local.php?plant_id='+req.plantId+'&positions='+req.positions+'&saleOrderId='+req.saleOrderId)
    //         .then(res => {
    //             console.log(res);
    //             return res.data 
    //         })     
    //   }

    // async transferRack(rackDetails:any): Promise<CommonResponse> {
    //     return await axios.post(this.URL + '/transferRack', rackDetails)
    //         .then(res => {
    //             console.log(res);
    //             return res.data 
    //         })
    // }
    
}