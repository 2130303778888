import { CodeListStatus } from "./enum/code-list-status.enum";

export class CodeListDto {
    codeListId?: number;
    unitId:number;
    traceabilityCode:string;
    CodeListStatus:CodeListStatus;
    createdUser:string;
    isActive: boolean;
    constructor(codeListId:number,unitId:number,traceabilityCode:string,CodeListStatus:CodeListStatus,createdUser:string,isActive:boolean){
        this.codeListId = codeListId;
        
        this.codeListId = codeListId;
        this.unitId=unitId;
        this.traceabilityCode=traceabilityCode;
        this.CodeListStatus=CodeListStatus;
        this.createdUser =createdUser;
        this.isActive = isActive;
    }
}

export const CodeListDtoDefault : CodeListDto = {
    codeListId: 0,
    unitId: 0,
    traceabilityCode:'',
    CodeListStatus:CodeListStatus.Available,
    createdUser:"",
    isActive:true,
};

