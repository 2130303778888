import { PackingStatus, ProductCategoryEnum } from '@gtpl/shared-models/common-models';
import { LocationDto, PalletDto } from '@gtpl/shared-models/masters';
import {SaleOrderDropDownDto, SaleOrderItemsDTO} from '@gtpl/shared-models/sale-management'
import { Shifts } from '../reporting-stock';
export class StockDTO {
    stockId?: number;
    saleOrderId: number;
    saleOrderItemId: number;
    totalCartons: number;
    totalPouches: number;
    loosePouches: number;
    location: number;
    pallet: number;
    date:Date
    stockType: string;
    status: string;
    lotNo: string;
    transactionType:string;
    shift:Shifts;
    packingType?: PackingStatus;
    brandId?: number;
    minGrade?: number;
    maxGrade?: number;
    varientId?: number;
    pouches?: number;
    pouchWeight?: number;
    categoryId?: number;
    productId?: number;
    palletInfo?: PalletDto;
    locationInfo?: LocationDto;
    saleOrderInfo?: SaleOrderDropDownDto;
    saleOrderItemsInfo?: SaleOrderItemsDTO;
    updatedUser?: string;
    count?: number;
    unitId?: number;
    palletCapacity?: number;
    cartonsStatus?: PackingStatus;
    pouchesStatus?: PackingStatus;
    productCategory?: ProductCategoryEnum;
    traceCode?: number;
    uomId?: number;
    stockInQty?: number;
    jobId?: number;
    isDamaged?: PackingStatus;
    remarks?: string;
    zoneId?: number;
    packStyleId?: number;
    palletcode?:string;

    constructor(saleOrderId: number,
        saleOrderItemId: number,
        totalCartons: number,
        totalPouches: number,
        loosePouches: number,
        location: number,
        pallet: number,
        date:Date,
        stockType: string,
        status: string,
        lotNo: string,
        transactionType:string,
        shift:Shifts,
        packingType?: PackingStatus,
        stockId?: number,
        brandId?: number,
        minGrade?: number,
        maxGrade?: number,
        varientId?: number,
        pouches?: number,
        pouchWeight?: number,
        categoryId?: number,
        productId?: number, 
        palletInfo?: PalletDto,
        locationInfo?: LocationDto,
        saleOrderInfo?: SaleOrderDropDownDto,
        saleOrderItemsInfo?: SaleOrderItemsDTO,
        updatedUser?: string,
        count?: number,
        unitId?: number,
        palletCapacity?: number,
        cartonsStatus?: PackingStatus,
        pouchesStatus?: PackingStatus,
        productCategory?: ProductCategoryEnum,
        traceCode?: number,
        uomId?: number,
        stockInQty?: number,
        jobId?: number,
        isDamaged?: PackingStatus,
        remarks?: string,
        zoneId?: number,
        packStyleId?: number,
        palletcode?:string

        ){
        this.stockId = stockId;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.updatedUser = updatedUser;
        this.pallet = pallet;
        this.date = date;
        this.location = location;
        this.stockType = stockType;
        this.totalCartons = totalCartons;
        this.totalPouches = totalPouches;
        this.loosePouches = loosePouches;
        this.lotNo =lotNo;
        this.transactionType=transactionType;
        this.shift=shift;
        this.packingType =packingType;
        this.status = status;
        this.brandId= brandId;
        this.minGrade= minGrade;
        this.maxGrade= maxGrade;
        this.varientId= varientId;
        this.pouches= pouches;
        this.pouchWeight= pouchWeight;
        this.categoryId= categoryId;
        this.productId= productId;
        this.palletInfo = palletInfo;
        this.locationInfo= locationInfo;
        this.saleOrderInfo = saleOrderInfo;
        this.saleOrderItemsInfo = saleOrderItemsInfo;
        this.count = count;
        this.unitId = unitId;
        this.palletCapacity = palletCapacity;
        this.cartonsStatus = cartonsStatus;
        this.pouchesStatus = pouchesStatus;
        this.productCategory = productCategory;
        this.traceCode = traceCode;
        this.uomId = uomId;
        this.stockInQty = stockInQty;
        this.jobId = jobId;
        this.isDamaged = isDamaged;
        this.remarks = remarks;
        this.zoneId = zoneId;
        this.packStyleId = packStyleId;
        this.palletcode = palletcode;
    }
}
