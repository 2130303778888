export class InterviewDto{ 
   
    interviewId: number;
    interviewDate:any;
    interviewType: string;
    interviewer:string;
    interviewerMobieNo:string;
    plant:string;
    jobDescription:string;
    candidateName:string;
    status:string;
    refereedBy:string;
    willing:string;
    remarks:string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;
    notificationId:number;
    constructor(
        interviewId: number,
    interviewDate:any,
    interviewType: string,
    interviewer:string,
    interviewerMobieNo:string,
    plant:string,
    jobDescription:string,
    candidateName:string,
    status:string,
    willing:string,
    referredBy:string,
    remarks:string,
    isActive: boolean,
    createdAt: Date | any,
    createdUser: string,
    updatedAt: Date | any,
    updatedUser: string,
    versionFlag: number,
    notificationId:number,)
    {
    this.interviewId=interviewId;
    this.interviewDate =interviewDate;
    this.interviewType = interviewType;
    this.interviewer=interviewer;
    this.interviewerMobieNo=interviewerMobieNo;
    this.plant=plant;
    this.jobDescription=jobDescription;
    this.candidateName=candidateName;
    this.status=status;
    this.willing=willing;
    this.refereedBy=referredBy;
    this.remarks=remarks;
    this.isActive= isActive;
    this.createdAt= createdAt;
    this.createdUser= createdUser;
    this.updatedAt= updatedAt;
    this.updatedUser= updatedUser;
    this.versionFlag= versionFlag;
    this. notificationId=notificationId;
    }
}
