import { CategoryEnum } from "../enums";

export class HistoryServiceModel{
    categoryId: string;
    category: CategoryEnum;
    plantId: number;
    rfIds: string;
    expiryDate: Date;
    createdDate: Date;
    modifiedDate: Date;


   
    constructor(categoryId:string,category:CategoryEnum,plantId: number,rfIds: string,expiryDate: Date,createdDate: Date, modifiedDate: Date){
        this.categoryId = categoryId;
        this.category = category;
        this.plantId = plantId;        
        this.rfIds = rfIds;
        this.expiryDate = expiryDate;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
    }
}