
import { TemperaturesModel } from "./temperatures.model";
export class DayWiseTemperatureModel {
        date: string;
        deptName: string;
        highestTemperature: TemperaturesModel;
        lowestTemperature: TemperaturesModel;
    
    constructor(date: string, deptName: string, highestTemperature: TemperaturesModel, lowestTemperature: TemperaturesModel) {
        this.date = date;
        this.deptName = deptName;
        this.highestTemperature = highestTemperature;
        this.lowestTemperature = lowestTemperature;
    }
}