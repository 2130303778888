export class ShippingTermsDropDownDto {
    shiptermId: number;
    shipmenttermName: string;
    /**
     * 
     * @param shiptermId 
     * @param shipmenttermName 
     */
    constructor(shiptermId: number, shipmenttermName: string) {
        this.shiptermId = shiptermId;
        this.shipmenttermName = shipmenttermName;
    }

}