import { LoginUserModel } from ".";
import { GlobalResponseObject } from "../global-response-object";


export class UserAuthResponse extends GlobalResponseObject {
    data?: LoginUserModel;

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: LoginUserModel) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}