
export class PoRequest {
  purchaseOrderId: number;
  /**
   * @paarm purchaseOrderId
   */
  constructor(purchaseOrderId:number){
      this.purchaseOrderId = purchaseOrderId;
  }
}

