import { EmployeeRolesEnum } from "@gtpl/shared-models/common-models";

export class PoFilterDto {
    fromDate: Date;
    toDate: Date;
    unitId: number;
    role:EmployeeRolesEnum;

    /**
     * 
     * @param fromDate 
     * @param toDate 
     * @param unitId 
     */
    constructor(fromDate: Date, toDate: Date, unitId: number, role:EmployeeRolesEnum) {
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.unitId = unitId;
        this.role = role;
    }
}