import axios from 'axios';
import {CountriesResponseModel,AllCountriesResponseModel,CountryDto, CountriesDropDownDataResponseModel, CountriesDropDownData, CountryRequest, CertificatesDto} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class CountryService {
URL = connection.DEPLOY_URL + '/countries';

  async createCountry(country: CountryDto): Promise<CountriesResponseModel> {
      return await axios.post(this.URL + '/createCountry',country)
          .then(res => {
              return res.data
          })
  }
  async  updateCountry(country: CountryDto): Promise<CountriesResponseModel> {
    return await axios.post(this.URL + '/updateCountry', country)
           .then(res => {
               return res.data
           })
}
  async getAllCountries(req?:UserRequestDto): Promise<AllCountriesResponseModel> {
    return await axios.post(this.URL + '/getAllCountries',req)
        .then(res => {
            return res.data
        })     
    // return new AllCountriesResponseModel(true,123,"successful",[new CountryDto(1,"Japan",true)]);
}
async  ActivatedeActivateCountry(countryDto: CountryDto): Promise<CountriesResponseModel> {
    //   console.log(State.stateCode);
      return await axios.post(this.URL + '/activateOrDeactivateCountry', countryDto)
              .then(res => {
                  return res.data
              })
  }
async  getCountryById(countryRequest: CountryRequest): Promise<CountriesResponseModel> {
    //   console.log(State.stateCode);
      return await axios.post(this.URL + '/getCountryById', countryRequest)
              .then(res => {
                  return res.data
              })
  }
  async  getAllActiveCountries(): Promise<AllCountriesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveCountries')
        .then(res => {
            return res.data
        })
}
// async getActiveCountriesCount(): Promise<CountryStatusWiseResponseModel> {
//   return await axios.post(this.URL + '/getActiveCountryCount').then(res => {
//       return res.data
//   });
// }
async getActiveCountries(){
    //   alert('hai')
      return new CountriesDropDownDataResponseModel(true,11,'countriesData retrived successfully',[new CountriesDropDownData(1,'country1'),new CountriesDropDownData(2,'country2')])
  }

}