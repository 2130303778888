import { MasterBrandDto } from './master-brand-dto';
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class MasterBrandResponseModel extends GlobalResponseObject{
    data?: MasterBrandDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //MasterBrandDto
     */
     constructor(status: boolean, errorCode: number, internalMessage: string, data?: MasterBrandDto) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
