import axios from 'axios';
import connection from './connection';
import {DesignationDto, AllDesignationResponseModel, DesignationResponseModel} from '@gtpl/shared-models/hrms';

export class DesignationService{

    URL = connection.DEPLOY_URL + '/designation';

    async createDesignation(designationdto: DesignationDto ): Promise<DesignationResponseModel> {
        console.log(designationdto);
       
        return await axios.post(this.URL + '/createDesignation',designationdto)
            .then(res => {
                return res.data
            })
    }

    async getAllDesignation(): Promise<AllDesignationResponseModel> {
                
        return await axios.post(this.URL + '/getAllDesignation')
            .then(res => {
                return res.data 
            })     
    }

    async  updateDesignation(designationdto: DesignationDto): Promise<DesignationResponseModel> {
        return await axios.post(this.URL + '/updateDesignation', designationdto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateDesignation(designationDto: DesignationDto): Promise<DesignationResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateDesignation', designationDto)
                        .then(res => {
                                return res.data
                        })
                }
}