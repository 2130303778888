import React, { useEffect, useRef, useState } from 'react';
import { InProgressBeHeadingDto, LotNumberRequest, OperationRequest, ProductionLogIdRequest } from '@gtpl/shared-models/production-management';
import './beheading-inprogress-grid.css';
import { BeheadingService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { JobCompletedEnum, OperationTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Modal, Form, InputNumber } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PrinterOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import {DeheadingSampleTestPrint} from '@gtpl/pages/production-management/production-management-components/deheading-sample-test-print'
import { DeheadingSampleTestForm } from '@gtpl/pages/production-management/production-management-components/deheading-sample-test-form'
/* eslint-disable-next-line */
export interface BeheadingInprogressGridProps { }

export function BeheadingInprogressGrid(
  props: BeheadingInprogressGridProps
) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [beheadingInprogressData, setBeheadingInprogressData] = useState<InProgressBeHeadingDto[]>([]);
  const [object, setObject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [noOfBoxes, setNoOfBoxes] = useState<number>(0);
  const [qtyToBeProcessed, setQtyToBeProcessed] = useState<number>(0);
  const [inventoryQty, setInventoryQty] = useState<number>(0);
  const [selectedInformation, setSelectedInformation] = useState<any>();
  const beheadingService = new BeheadingService();
  const inventoryService = new ProductionInventoryService();
  const [modelType, setModelType] = useState<string>('');
  const [opQtyKey, setopQtyKey] = useState(1); 
  const [opBoxData, setOpBoxData] = useState<number>(0);
  const [opBoxQuantity, setOpBoxQuantity] = useState<number>();
  const { Text } = Typography;

  useEffect(() => {
    getAllBeheadingInProgressJobs();
    getNoOfAvailableBoxes();
    getQtyToBeProcessed();
    getInventoryQty();
  }, []);

  const getNoOfAvailableBoxes = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getNoOfAvailableBoxes(lotNoReq).then((res) => {
      if (res.status) {
        setNoOfBoxes(res.data);
      } else {
        setNoOfBoxes(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setNoOfBoxes(0);
    });
  }

  const getQtyToBeProcessed = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getQtyToBeProcessed(lotNoReq).then((res) => {
      if (res.status) {
        setQtyToBeProcessed(res.data);
      } else {
        setQtyToBeProcessed(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setQtyToBeProcessed(0);
    });
  }

  const getInventoryQty = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    inventoryService.getInventoryQty(lotNoReq).then((res) => {
      if (res.status) {
        setInventoryQty(res.data);
      } else {
        setInventoryQty(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setInventoryQty(0);
    });
  }

  const getAllBeheadingInProgressJobs = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))
    beheadingService.getAllBeheadingInProgressJobs(req).then(res => {
      if (res.status) {
        setBeheadingInprogressData(res.data);
      } else {
        if (res.intlCode) {
          setBeheadingInprogressData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setBeheadingInprogressData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  /**
* 
* @param selectedKeys 
* @param confirm 
* @param dataIndex 
*/
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const setData = (rowdata) => {
    console.log(rowdata);
    setObject(rowdata.productionLogId)
    console.log(object);
  }

  /**
* used for column filter
* @param dataIndex column data index
*/
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const opBoxesQuantity = (value,index,rowData)=>{
    console.log(value);
    // console.log(valAdditionData);

    setOpBoxQuantity(value)
  }
  // const opBoxes = (value,index,rowData)=>{
  //   rowData.outputQuantity = Number(value)*Number(rowData.workstationCapacity);
  //   console.log(rowData);
  //   // console.log(valAdditionData);
  //   setopQtyKey(preState => preState+1);

  //   setOpBoxData(value)
  // }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Table',
      dataIndex: 'workstation',
      sorter: (a, b) => a.workstation.localeCompare(b.workstation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('workstation')
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobNumber')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotandplantlot',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'Assigned Time',
      dataIndex: 'assignedTime',
      sorter: (a, b) => a.assignedTime.localeCompare(b.assignedTime),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('assignedTime'),
      render: (value) => {
        return <div style={{ textAlign: 'right' }}><Tag style={{ textAlign: 'right', backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{moment(value).format('hh:mm A')}</Tag></div>
      }
    },
    {
      title: 'I/P Boxes',
      dataIndex: 'ipBoxes',
      align:'right',
      responsive: ['sm'],
      // responsive: ['lg'],
      sorter: (a, b) => a.ipBoxes - b.ipBoxes,
      sortDirections: ['descend', 'ascend'],
      //...getColumnSearchProps('boxes')
      //responsive: ['lg'],
    },
    {
      title: 'O/P Boxes',
      dataIndex: 'opBoxes',
      responsive: ['sm'],

      render: (text, rowData, index) => {
        return (
          <Form.Item name="opBoxes"
            rules={[
              {
                required: true, message: 'O/P Boxes Name is required'

              },
              {
                pattern: /^[0-9]*$/,
                message: `Should contain only numbers.`
              },
              {
                validator: (_, value) => {
                  if (value <= rowData?.boxes) {
                    console.log(value);
                    rowData.opBoxes = value;
                    Promise.resolve()
                  } else {
                    Promise.reject(new Error('Should not be greater than I/P Boxes'))
                  }
                }
              }]} >
            <InputNumber
              defaultValue={rowData.opBoxes}
              min={0}
              required={true}
              style={{ width: '250' }}
              onChange={e => {
                rowData.opBoxes = e; setopQtyKey(preState => preState + 1);
              }}
            />
          </Form.Item>
        )
      }
      //responsive: ['lg'],
    },
    {
      title: 'I/P Quantity',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('quantity'),
      render: (value) => {
        return <div style={{ textAlign: 'right', color: "black", fontWeight: 'bold' }}>{value}</div>
      }
    },
    {
      title: 'O/P Quantity',
      dataIndex: 'outputQuantity',
      render: (text, rowData, index) => {
        return <span><InputNumber min={0}
          defaultValue={rowData.physicalQuantity} 
          disabled={true}
          required={true}
          style={{ width: '250' }}
          // onChange={e => {
          //   rowData.outputQuantity = e; setopQtyKey(preState => preState + 1);
          // }} 
          />
          </span>
      }
    },
    {
      title: `Job Status`,
      dataIndex: 'jobStatus',
      render: (jobStatus, rowData) => (
        <span>
          {rowData.jobStatus === "YES" ?

            <Switch
              className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren="Completed"
              unCheckedChildren="Not Completed"
              checked={rowData.jobStatus === "YES" ? true : false}
              disabled={rowData.jobStatus === "YES" ? true : false}
            />
            :
            <Popconfirm onConfirm={e => { updateBeheadingJobCompleted(rowData); }}
              title={
                rowData.jobStatus === "YES"
                  ? 'Are you sure to make it as not completed ?'
                  : 'Are you sure to make it as completed ?'
              }
            >
              <Switch
                className={rowData.jobStatus === "YES" ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren="Completed"
                unCheckedChildren="Not Completed"
                checked={rowData.jobStatus === "YES" ? true : false}
                disabled={rowData.jobStatus === "YES" ? true : false}
              />

            </Popconfirm>}
            <Divider type="vertical" />
            
            <SafetyCertificateOutlined
          style={{ color: 'red' }}
          onClick={() => displayModel(rowData , 'deheading-sample')} />
          <> <Divider type="vertical" /> <PrinterOutlined style={{ color: 'blue' }} onClick={() => displayModel(rowData, 'deheadPrint')} /></>
        </span>
      )
    }
  ];

  const displayModel = (data:InProgressBeHeadingDto , type) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
    setModelType(type);
  };
  const updateBeheadingJobCompleted = (data) => {
    if (data.productionLogId || Number(data.opBoxData) > 0 ) {
      setBeheadingInprogressData([]);
      const req = new ProductionLogIdRequest(data.productionLogId,data.opBoxes)
      beheadingService.updateBeheadingJobCompleted(req).then(res => {
        console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getAllBeheadingInProgressJobs();
          getNoOfAvailableBoxes();
          getQtyToBeProcessed();
          getInventoryQty();
          setOpBoxQuantity(0);
          setOpBoxData(0);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage("Failed To Complete Job, Please Contact Admin or Try again after some time");
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getErrorMessage('Please Check Info');
    }

  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <Card title={<span style={{ color: 'white' }}>Deheading Work In-Progress</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'No. of Lots Available: ' + Number(noOfBoxes[0]?.noOfRecords)}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Quantity To be Processed :' + Number(Math.round(qtyToBeProcessed[0]?.qty))}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Card
            title={'Inventory: ' + Number(Math.round(inventoryQty[0]?.qty))}
            style={{
              textAlign: 'center',
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <br />
      <Table
        rowKey={record => record.logId}
        columns={columnsSkelton}
        dataSource={beheadingInprogressData}
        scroll={{ x: true }}
        bordered
        summary={(pageData) => {
          let totalOutputQuantity = 0;
          let totalQuantity = 0;
          let totalBoxes = 0;
        
          pageData.forEach(({ quantity,outputQuantity }) => {
            totalQuantity += Number(Number(quantity).toFixed());
            totalOutputQuantity += Number(Number(outputQuantity).toFixed());
            
          });

         
          return (
            
            <>{beheadingInprogressData?.filter(res=>res.jobStatus===JobCompletedEnum.NO)? 
            <Table.Summary.Row className='tableFooter'>
            <Table.Summary.Cell index={2} colSpan={6} ><Text >Total</Text></Table.Summary.Cell>
            <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalQuantity.toFixed()}</Text></Table.Summary.Cell>
            {/* <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalOutputQuantity.toFixed()}</Text></Table.Summary.Cell> */}
          </Table.Summary.Row>: ''}
              
              
            </>
          );
         
        }
      }
      />
      <Modal
        className='deheading-sample'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {(modelType == 'deheading-sample') ? <DeheadingSampleTestForm deHeadingInfo={selectedInformation} handleCancel={handleCancel} /> : ''}
        
        {(modelType == 'deheadPrint') ? <DeheadingSampleTestPrint productionLogId={selectedInformation.productionLogId} /> : ''}
      </Modal>
    </Card>
  );
}

export default BeheadingInprogressGrid;
