import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { FgDamagesDto } from './fg-damages-dto';

export class FgDamagesResponseModel extends GlobalResponseObject{

    data?:FgDamagesDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: FgDamagesDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}