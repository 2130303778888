import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { BomTrackerPackingItemsDto } from './bom-tracker-packing-items.dto';


export class BomTrackerForSoIdResponseModel extends GlobalResponseObject {
    data?: BomTrackerPackingItemsDto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /BomTrackerPackingItemsDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: BomTrackerPackingItemsDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

