import axios from 'axios';
import connection from './connection';
import {SaleOrderProcessDto} from './../../../../shared-models/masters/src/lib/saler_order_process/sale_order_process.dto'
import {SaleOrderProcessResponseModel} from './../../../../shared-models/masters/src/lib/saler_order_process/response_model'
import {AllSaleOrderProcessResponseModel} from './../../../../shared-models/masters/src/lib/saler_order_process/all_response_model'
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
import { AllSaleOrdersItemsDetailsResponseModel, SaleOrderDropDownResponseModel ,SaleOrderItemsDropdownResponse,SaleOrderDetailViewInfoResponseModel} from '@gtpl/shared-models/sale-management';
import {  saleOrder, SaleOrderItemsRequest} from '@gtpl/shared-services/sale-management';


export class SaleOrderService{
    URL = connection.DEPLOY_URL + '/sale-order-process-type';


    async create(dto: SaleOrderProcessDto): Promise<SaleOrderProcessResponseModel> {
        console.log(dto);
       
        return await axios.post(this.URL + '/createSaleOrderProcessType',dto)
            .then(res => {
                return res.data
            })
}


async  activatedeActivate(saleDto: SaleOrderProcessDto): Promise<SaleOrderProcessResponseModel> {
    //   console.log(State.stateCode);
    // if(saleDto.isActive){
    //     saleDto.isActive = false;
    // }else{saleDto.isActive=true;}
    return await axios.post(this.URL + '/activateOrDeactivateSaleOrderProcessType', saleDto)
            .then(res => {
                return res.data
            })
}

async getAll(): Promise<AllSaleOrderProcessResponseModel> {
                
    // return new AllSaleOrderProcessResponseModel(true,1111,'Products retrieved successfully',[new SaleOrderProcessDto(1,'Product Name','Product Code','Remark 1',true)])
    return await axios.post(this.URL + '/getallSaleOrderProcessType')
        .then(res => {
            return res.data
        })     
}

async  update(data: SaleOrderProcessDto): Promise<SaleOrderProcessResponseModel> {
    return await axios.post(this.URL + '/updateSaleOrderProcessType', data)
                    .then(res => {
                        return res.data
                    })
            }

            async getMTOSaleOrders(req: PlantRequest): Promise<SaleOrderDropDownResponseModel> {
                return await axios.post(this.URL + '/getMTOSaleOrders', req)
                    .then(res => {
                        return res.data
                    })
            }

            async getSaleOrderItemDetails(saleOrderItemsRequest: SaleOrderItemsRequest): Promise<AllSaleOrdersItemsDetailsResponseModel> {
                return await axios.post(this.URL + '/getSaleOrderItemDetails', saleOrderItemsRequest)
                    .then(res => {
                        return res.data
                    })
            }


            async getSaleOrderItemsForSoId(saleOrderIdRequest: saleOrder): Promise<SaleOrderItemsDropdownResponse> {
                return await axios.post(this.URL + '/getSaleOrderItemsForSoId', saleOrderIdRequest)
                    .then(res => {
                        return res.data
                    })
            }

            async getSaleOrderDetailsById(saleOrderId: saleOrder): Promise<SaleOrderDetailViewInfoResponseModel> {
                console.log(saleOrderId);
                return await axios.post(this.URL + '/getSaleOrderDetailsById', saleOrderId)
                    .then(res => {
                        return res.data
                    })
            }

}