import React ,{useState,useEffect,useRef} from 'react';
import { Table, Card, Row, Col, Button, Modal, Input } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './grading-dashboard.css';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnProps } from 'antd/lib/table';
import { BomWithCountAndQuantityModel, CountRequest} from '@gtpl/shared-models/sale-management';
import { GradingService, ProductionInventoryService } from "@gtpl/shared-services/production";
import { SaleOrderService } from "@gtpl/shared-services/sale-management";
import { SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { LotNumberRequest } from '@gtpl/shared-models/production-management';
import { OperationTypeEnum } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface GradingDashboardProps {}

export function GradingDashboard(props: GradingDashboardProps) {
  const plantId = Number(localStorage.getItem('unit_id'));
  const [soData, setSoData] = useState<BomWithCountAndQuantityModel[]>([]);
  const [lotCount, setLotCount] = useState<number>(0);
  const [qtyToBeProcessed, setQtyToBeProcessed] = useState<number>(0);
  // const [qtyToBeProcessed, setQtyToBeProcessed] = useState<any>();

  const [qtyInProgress, setQtyInProgress] = useState<number>(0);
  const [gradingInventory, setGradingInventory] = useState<number>(0);
  const [counts, setCounts] = useState<string[]>([]);
  const [series,setSeries] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);  
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(''); 
  const gradingService = new GradingService();
  const saleService = new SaleOrderService();
  const inventoryService = new ProductionInventoryService();

  useEffect(() => {
    getGradingDashboardData();  
    getSaleOrdersCountAndQuantity();
    getQtyToBeProcessed();
  }, []);

  const getGradingDashboardData = () => {
    gradingService.getGradingDashboardData({unitId:plantId}).then((res) => {
      if (res.status) {
        setSeries([
          {
            name: 'Qnty Pending',
            data: res.data.pendingQty,
          },
          {
            name: 'Qnty Filled',
            data: res.data.filledQty,
          },
        ]);
        setCounts(res.data.counts);
        setLotCount(res.data.lotCount);
        // setQtyToBeProcessed(res.data[0]?.qtyToBeProcessed);
        setQtyInProgress(res.data.qtyInProgress);
        setGradingInventory(res.data.gradingInventory);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  const getQtyToBeProcessed = () => {
    const plant = Number(localStorage.getItem('unit_id'))   
    inventoryService.getQtyToBeProcessed({plant:plant,operation:OperationTypeEnum.GRADING}).then((res) => {
      if (res.status) {
        console.log(res)
        setQtyToBeProcessed(res.data);
      } else {
        setQtyToBeProcessed(0);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setQtyToBeProcessed(0);
    });
  }

  const getSaleOrdersCountAndQuantity = () => {
    saleService.getSaleOrdersCountAndQuantity(new CountRequest(0,plantId)).then((res) => {
      if (res.status) {
        console.log(res)
        setSoData(res.data);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const chartData = () => {
    const options: Highcharts.Options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'COUNT WISE REQUIRED & FILLED QUANTITIES',
        style: {
          color: '#00008B',
          fontWeight: 'bold',
        },
      },
      colors: ['#ff8c00', '#1d99db'],
      xAxis: {
        categories: counts,
        gridLineWidth: 1,
      },
      yAxis: {
        title: null,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      legend: {
        align: 'center',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderWidth: 0,
        shadow: false,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: series
    };
    return options;
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
 
  
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.soNumber.length - b.soNumber.length || a.soNumber.localeCompare(b.soNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber'),
    },
    {
      title: 'Variant Code',
      dataIndex: 'variantCode',
      sorter: (a, b) => a.variantCode.length - b.variantCode.length || a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode'),
    },
    {
      title: 'Headon Count',
      dataIndex: 'count',
      align:'right',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'hlCount',
      align:'right',
      sorter: (a, b) => a.hlCount.toString().length - b.hlCount.toString().length || a.hlCount.toString().localeCompare(b.hlCount.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity (In Tons)',
      dataIndex: 'quantity',
      align:'right',
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Card title={<span style={{ color: 'white' }}>Grading Dashboard</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Row
        gutter={40}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col>
          <Card
            title={'No. of Lots Available:'+lotCount}
            style={{
              textAlign: 'left',
              width: 'fit-content',
              height: 41,
              backgroundColor: '#52c41a',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity to be processed:'+Number(Math.round(qtyToBeProcessed[0]?.qty)).toLocaleString('en-IN')}
            style={{
              textAlign: 'left',
              width: 'fit-content',
              height: 41,
              backgroundColor: '#f8f838',
            }}
          ></Card>
        </Col>
        <Col>
          <Card
            title={'Quantity in progress:'+Number(qtyInProgress).toLocaleString('en-IN')}
            style={{
              textAlign: 'left',
              width: 'fit-content',
              height: 41,
              backgroundColor: '#69c0ff',
            }}
          ></Card>
        </Col>
      </Row>
      <br />
      <Row
        gutter={40}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col>
          <Card
            title={'Grading Inventory:'+Number(gradingInventory).toLocaleString('en-IN')}
            style={{
              textAlign: 'left',
              width: 'fit-content',
              height: 41,
              backgroundColor: '#bfbfbf',
            }}
          ></Card>
        </Col>
        <Col>
          <Button danger type="primary" onClick={showModal} htmlType="submit">
            SO wise Count & Quantity Requirement
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Row
        gutter={40}
        style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}
      >
        <Card>
          <HighchartsReact highcharts={Highcharts} options={chartData()} />
        </Card>
      </Row>
        <Modal width={800} title="SO wise Count & Quantity Requirement" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Table columns={columnsSkelton} dataSource={soData} bordered pagination={{ defaultPageSize:6}}/>
        </Modal>
    </Card>
  );
}

export default GradingDashboard;
