import React, { useRef, useState, useEffect } from 'react';
import { Table, Card, Input, Button, Tooltip, Form, Col, Row, Select, Modal, Empty, Popconfirm, message, Tabs, DatePicker, Typography, Pagination, Descriptions, Statistic, Spin, Alert, Tag, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FgStockStatusEnum, InputIdRequest, SoPlantRequest, StockOutModel, Shifts, PlantIdRequest } from '@gtpl/shared-models/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import './stock-grid.css';
import { PlantsDropDown, RacksForZoneId, ZoneDto } from '@gtpl/shared-models/masters';
import { RackService, ZoneService, EndCustomersService, UnitcodeService } from '@gtpl/shared-services/masters';
import { RackDropDownDto, ZoneDropDownDto, RackEndCustomerMapDto, EndCustomerDto } from '@gtpl/shared-models/masters';
import PalletLocation from './pallet-location';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { DeadStockEnum, ShiftsDropDown, StockReportFilters, StockTypeDropDown, StockTypeStatus, UnitRequest, WarehouseLifeCycleEnum } from '@gtpl/shared-models/common-models';
import { CSVLink } from 'react-csv';
import { SkuDto, TypeOfValueAddition4Dto, TypesOfValueAddition2Dto, Type1Dto, ValueAdditionThreeDto, ValueAdditionFiveDto, ProductDto, FoodTypesDto, FoodTypesDropDownData } from '@gtpl/shared-models/masters';
import { SkuService, Type3Service, TypeofValueAddition4Service, TypesOfValueAddition2Service, Type1Service, ProductService, FoodTypeService, TypeofValueAddition5Service } from '@gtpl/shared-services/masters';
import Item from 'antd/lib/list/Item';
import { StockService, StoreReturnService } from '@gtpl/shared-services/procurement';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Redirect, useLocation } from 'react-router-dom';

const { Option } = Select;
/* eslint-empty-next-line */
export interface StockReportWarehouseProps {

}


export function StockReportWarehouse(props: StockReportWarehouseProps) {
  const { Text } = Typography;
  const [form] = Form.useForm();
  let stockType = StockTypeDropDown;
  const [stockInData, setStockInData] = useState<StockOutModel[]>([]);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const stockInService = new StockInService();
  const [page, setPage] = React.useState(1);
  const zoneService = new ZoneService();
  const [zoneData, setZoneData] = useState<ZoneDropDownDto[]>([]);
  const [empty, setEmpty] = useState<boolean>(true)
  const [brandfiltersData, setbrandFiltersData] = useState<any[]>([]);
  const [unit, setUnit] = useState<any[]>([])
  const [productfiltersData, setproductFiltersData] = useState<any[]>([]);
  const [unitfiltersData, setunitFiltersData] = useState<any[]>([]);
  const [zoneDropDown, setZoneDropDown] = useState<StockOutModel[]>([]);
  const [productData, setProductData] = useState<any[]>([]);

  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const [shiftData, setshiftData] = useState<any[]>([]);
  const service = new StockService();
  const fgservice = new FGStockService();
  const stockInservice = new StockInService();
  const unitsService = new UnitcodeService()
  const [disable, setDisable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(null);
  const storageUnitId = (localStorage.getItem('unit_id'))
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>();
  const [deadStock, setDeadStock] = useState<any[]>([]);
  const [deadStockDrop, setDeadStockDrop] = useState<any>();

  let location = useLocation();

  const { status, cardType }: any = location.state ? location.state : { status: undefined, cardType: undefined }

  console.log(location.state, '11111111')
  const stockTypeRedirect = useParams<any>()
  let totalQuan = 0
  let totalCart = 0;



  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }


  /**
  * 
  * @param pagination 
  * @param filters 
  * @param sorter 
  * @param extra 
  */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }


  const yesterday = new Date(Date.now() - 86400000)

  86400000
  useEffect(() => {
    getProductDropForStockReport();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })
    }
    // if (stockTypeRedirect.stockType) {
    //   form.setFieldsValue({ stockType: Array(stockTypeRedirect.stockType) })
    //   form.setFieldsValue(moment(yesterday).format('DD-MM-YYYY'))
    //   getStockInData()
    // }
    if (cardType) {
      form.setFieldsValue({ deadStock: DeadStockEnum.ABOVE_180_DAYS })
    }
  }, [])
  console.log(cardType, '54545454554545')
  const getDeadStockData = () => {
    const req = new StockReportFilters()
    const storageUnitId = (localStorage.getItem('unit_id'))
    console.log(storageUnitId, 'storageUnitId')
    const unitId = form.getFieldValue('plantData')
    console.log(unitId, 'unitId')
    if (Number(storageUnitId) == 5) {
      if (unitId) {
        req.unitId = unitId
      }
    } else {
      req.plantId = (Number(storageUnitId))
    }

    stockInService.getDeadStockData(req).then((res) => {
      if (res.status) {
        setDeadStock(res.data)
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }
  const getStockInData = () => {
    if (form.getFieldValue('plantData') || form.getFieldValue('shift') || form.getFieldValue('stockType') || form.getFieldValue('coldStorage') || form.getFieldValue('lifeCycleStatus') || form.getFieldValue('deadStock') || form.getFieldValue('product'))  {
      setEmpty(false); setDisable(true); setLoading(true)
      console.log(disable)
      const req = new StockReportFilters()
      const storageUnitId = (localStorage.getItem('unit_id'))
      console.log(storageUnitId, 'storageUnitId')
      const unitId = form.getFieldValue('plantData')
      console.log(unitId, 'unitId')
      if (Number(storageUnitId) == 5) {
        if (unitId) {
          req.unitId = unitId
        }
      } else {
        req.plantId = (Number(storageUnitId))
      }

      if (form.getFieldValue('stockDateRange') != undefined) {
        const selectedFromDate = moment(form.getFieldValue('stockDateRange')[0]).format('YYYY-MM-DD');
        const selectedToDate = moment(form.getFieldValue('stockDateRange')[1]).format('YYYY-MM-DD');
        req.stockFromDate = (selectedFromDate)
        req.stockToDate = (selectedToDate)
      }
      if(form.getFieldValue('product')){
        req.product = form.getFieldValue('product')
      }
      if (form.getFieldValue('coldStorage')) {
        req.coldStorage = form.getFieldValue('coldStorage')
      }
      if (form.getFieldValue('shift')) {
        req.shift = form.getFieldValue('shift')
      }
      if (form.getFieldValue('stockType')) {
        req.soType = form.getFieldValue('stockType')
      }
      if (form.getFieldValue('lifeCycleStatus')) {
        req.lifeCycleStatus = form.getFieldValue('lifeCycleStatus')
      }
      if (form.getFieldValue('deadStock')) {
        req.deadStock = form.getFieldValue('deadStock')
      }


      console.log(req, 'req')
      stockInService.getAllStockForStockReport(req).then(res => {
        setLoading(false); setDisable(false);

        if (res.status) {
          for (const StockReport of res.data) {
            const packingStyle = StockReport.pouches + '*' + Math.round(StockReport.pouchWeight).toFixed(1) +" "+ StockReport.uomName
            StockReport.packStyle = packingStyle
          }
          setStockInData(res.data);
          let unitfiltersArray: { text: string, value: string }[] = []
          let unituniq = res.data.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.unitName === thing.unitName
            ))

          );
          unituniq.map(item => {
            if (!(unitfiltersArray.includes({ text: item.unitName, value: item.unitName })))
              unitfiltersArray.push({ text: item.unitName, value: item.unitName })
          })
          let filtersArray: { text: string, value: string }[] = []
          let uniq = res.data.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.brandName === thing.brandName
            ))
          );
          // data for products filter
          uniq.map(item => {
            if (!(filtersArray.includes({ text: item.brandName, value: item.brandName })))
              filtersArray.push({ text: item.brandName, value: item.brandName })
          })

          let productFiltersArray: { text: string, value: string }[] = []
          let produtsUniq = res.data.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.variantCode === thing.variantCode
            ))
          );
          produtsUniq.map(item => {
            if (!(productFiltersArray.includes({ text: item.variantCode, value: item.variantCode })))
              productFiltersArray.push({ text: item.variantCode, value: item.variantCode })
          })
          setunitFiltersData(unitfiltersArray)
          setproductFiltersData(productFiltersArray.sort((a, b) => {
            let splittedKey1 = a.text.split('/');
            let splittedKey2 = b.text.split('/');
            let numberKey1 = Number(splittedKey1[0].split('-')[0]);
            let numberKey2 = Number(splittedKey2[0].split('-')[0]);
            let key1 = splittedKey1[1] + `${numberKey1}`;
            let key2 = splittedKey2[1] + `${numberKey2}`;
            return (key1 > key2) ? 1 : -1
          })
            .sort((a, b) => {
              let splittedKey1 = a.text.split('/');
              let splittedKey2 = b.text.split('/');
              let numberKey1 = Number(splittedKey1[0].split('-')[0]);
              let numberKey2 = Number(splittedKey2[0].split('-')[0]);
              return (numberKey1 - numberKey2) ? 1 : -1
            })
          )
          setbrandFiltersData(filtersArray)
          if (res.data.length < 1) {
            setEmpty(true)
            AlertMessages.getSuccessMessage(res.internalMessage);
          }
        } else {
          setStockInData([])
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        setLoading(false); setDisable(false);
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      AlertMessages.getInfoMessage('   Please select any input  ')
    }
  }
  const handleUnit = (value) => {
  }
  console.log(productfiltersData, 'product filter')
  useEffect(() => {
    getZones();
    setEmpty(false)
    getUnitDropdownForStockInReport()
    getDeadStockData();
    getStockInData();

  }, [])

  const getUnitDropdownForStockInReport = () => {
    fgservice.getUnitDropdownForStockInReport().then((res) => {
      if (res.status) {
        setUnit(res.data);
      } else {
        setUnit(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getProductDropForStockReport = () => {
    stockInService.getProductDropForStockReport({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setProductData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setProductData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setProductData([]);

    });
  }

  const getZones = () => {
    stockInService.getColdDropForStockRep({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setZoneDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setZoneDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setZoneDropDown([]);

    });
  }

  const onReset = () => {
    form.resetFields();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })
    }
    setEmpty(true);
    // setStockInData([])
  };

  const handleLifeCycle = (value) => {
    setLifeCycleStatus(value);
  }

  const handleDeadStock = (value) => {
    setDeadStockDrop(value);
  }

  const data = [
    { title: "Sno", dataIndex: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
    { title: 'Plant', dataIndex: 'unitName' },
    { title: 'Stock Type', dataIndex: 'stockType' },
    { title: "Location", dataIndex: "rackPosition" },
    { title: "Customer PO", dataIndex: "poNumber" },
    { title: 'Brand', dataIndex: 'brandName' },
    { title: "Product SKU", dataIndex: "variantCode" },
    { title: "Pack Style", dataIndex: "packStyle" },
    { title: "Product Category", dataIndex: "productCategory" },
    { title: "Pouch Shorthage", dataIndex: "pouchesShortage" },
    { title: "Carton Shortage", dataIndex: "cartonsShortage" },
    { title: "Shift", dataIndex: "shift" },
    { title: "Date", dataIndex: "stockDate", render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY")}</span>) },
    { title: "Cartons", dataIndex: "totalCartons", render: (value) => (<span style={{ float: 'right' }}>{(value)}</span>) },
    { title: "Quantity", dataIndex: "quantity", render: (value) => (<span style={{ float: 'right' }}>{(value)}</span>) },
    { title: "Remarks", dataIndex: "remarks", render: (status, rowData) => (
      <>
        {rowData.remarks? rowData.remarks : '-'}

      </>
    ), },
  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Stock-report')
      .addColumns(data)
      .addDataSource(stockInData, { str2num: true })
      .saveAs('stock-report.xlsx');
  }
  const exportToPdf = () => {

    var columns = [
      { title: "Sno", dataKey: "sno", render: (text, object, index) => (page - 1) * 10 + (index + 1) },
      { title: 'Plant', dataKey: 'unitName' },
      { title: 'Stock Type', dataKey: 'stockType' },
      { title: "Location", dataKey: "rackPosition" },
      { title: "Customer PO", dataKey: "poNumber" },
      { title: 'Brand', dataKey: 'brandName' },
      { title: "Product SKU", dataKey: "packStyle" },
      { title: "Pack Style", dataKey: "packingMethodName" },
      { title: "Pouch Shorthage", dataKey: "pouchesShortage" },
      { title: "Carton Shortage", dataKey: "cartonsShortage" },
      { title: "Shift", dataKey: "shift" },
      { title: "Date", dataKey: "stockDate", render: (value) => moment(value).format("DD-MM-YYYY") },
      { title: "Cartons", dataKey: "totalCartons" },
      { title: "Quantity", dataKey: "quantity" },

    ];
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, stockinTransactionsData, {
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      addPageContent: function (data) {
        doc.text("STOCK", 50, 15);
      }
    });
    doc.save('stock-report.pdf')
  }


  const columns = [
    {
      title: 'Unit',
      dataIndex: 'unitName',
      width: 80,
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>),
      filters: unitfiltersData,
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.unitName == value;
      },
    },
    {
      title: 'Product',
      dataIndex: 'variant',
      key: 'variant',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{record.variant}</div>
      }
    },
    {
      title: 'Cartons',
      dataIndex: 'totalCartons',
      key: 'totalCartons',
      render: (value, record) => {
        return <div style={{ textAlign: 'right' }}>{record.totalCartons}</div>
      }
    },
  ]

  const columnsSkelton: ColumnProps<any>[] = [

    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)
    },

    {
      title: 'Unit',
      dataIndex: 'unitName',
      width: 80,
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>),
      filters: unitfiltersData,
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.unitName == value;
      },
    },

    {
      title: 'SO Type',
      dataIndex: 'stockType',
      width: 100,
      render: (value, record, StockOutModel) => {
        return record.stockType
      },
      filters: [
        { text: 'Anticipated', value: 'Anticipated' },
        { text: 'dummy', value: 'dummy' },
        { text: 'SaleOrder', value: 'SaleOrder' },
        { text: 'SuperDummy', value: 'superDummy' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.stockType === value;
      },

    },
    {
      title: 'Cold Storage',
      dataIndex: 'coldStorage',
      width: 80,
      ...getColumnSearchProps('coldStorage'),
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>)
    },

    {
      title: 'Location',
      dataIndex: 'rackPosition',
      width: 100,
      ...getColumnSearchProps('rackPosition'),
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>)
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      width: 150,
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
    },

    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      width: 150,
      filters: brandfiltersData,
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.brandName == value;
      },
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>)
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      width: 200,
      // ...getColumnSearchProps('variantCode'),
      // filters: productfiltersData,

      // filterMultiple: true,
      // onFilter: (value, record) => {
      //   return record.variantCode == value;
      // },
      render: (value) => (<span style={{ float: 'left' }}>{value}</span>)
    },
    {
      title: 'Product Category',
      dataIndex: 'productCategory',
      width: 100,
      responsive: ["lg"],
      filters: [
        { text: 'Over Count', value: 'overcount' },
        { text: 'Under Count', value: 'undercount' },
        { text: 'G1', value: 'g1' },
        { text: 'G2', value: 'g2' },
        { text: 'Cut', value: 'cut' },
        { text: 'RJ(Rejections)', value: 'RJ(Rejections)' },

      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.productCategory == value;
      },
      render: (value) => value ? value : '-'
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      width: 100,
      align:'center',
      ...getColumnSearchProps('packStyle'),
    },
    {
      title: 'Pouches Shortage',
      dataIndex: 'pouchesShortage',
      width: 100,
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.pouchesShortage === value;
      },

    },
    {
      title: 'Cartons Shortage',
      dataIndex: 'cartonsShortage',
      width: 100,
      filters: [
        { text: 'YES', value: 'YES' },
        { text: 'NO', value: 'NO' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.cartonsShortage === value;
      },


    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      width: 80,
      filters: [
        { text: 'Shift A', value: 'shiftA' },
        { text: 'Shift B', value: 'shiftB' },
        { text: 'Shift C', value: 'shiftC' },
        { text: 'General', value: 'general' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.shift == value;
      }
    },
    {
      title: 'Date',
      dataIndex: 'stockDate',
      width: 100,
      key: 'stockDate',
      render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY")}</span>),
      sorter: (a, b) => moment(a.stockDate).unix() - moment(b.stockDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Stock Aging",
      dataIndex: 'aging',
      width: 100,
      sorter: (a, b) => (Math.floor((new Date().getTime() - new Date(moment(a.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date().getTime() - new Date(moment(b.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date().getTime() - new Date(moment(record.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
          props: {
            style: {
              background: Math.floor((new Date().getTime() - new Date(moment(record.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 < 50 ? "Green" : (Math.floor((new Date().getTime() - new Date(moment(record.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 50 && Math.floor((new Date().getTime() - new Date(moment(record.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1) < 100 ? "yellow " : "red",
              color: Math.floor((new Date().getTime() - new Date(moment(record.stockDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 > 100 ? "white" : "black"
            },
          }
        };
        return obj;
      }
    },
    {
      title: 'Cartons',
      dataIndex: 'totalCartons',
      sorter: (a, b) => a.totalCartons - b.totalCartons,
      sortDirections: ['descend', 'ascend'],
      fixed: 'right',
      width: 80,
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    {
      title: "Quantity(KG's)",
      dataIndex: 'quantity',
      fixed: 'right',
      width: 100,
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ['descend', 'ascend'],
      render: (value, record: StockOutModel) => (<span style={{ float: 'right' }}>{Math.round(value)}</span>)
    },
    {
      title:"Remarks",
      dataIndex: 'remarks',
      fixed: 'right',
      width: 100,
      sorter: (a, b) => a.remarks - b.remarks,
      sortDirections: ['descend', 'ascend'],
      render: (status, rowData) => (
        <>
          {rowData.remarks? rowData.remarks : '-'}

        </>
      ),
    }

  ];

  return (
    <Card
      title={<span style={{ color: 'white' }}>Stock Report</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}

    >
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
        <Form.Item
          style={{ display: "none" }}
          name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
          <Input hidden />
        </Form.Item>
        <Row gutter={[24, 24]}>
          <Col span={3}>
            <Form.Item
              name="plantData"
              label="Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                // onChange={handlePlant}
                // allowClear = {setInitialData}
                style={{ width: '100%' }}
                onChange={handleUnit}
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                mode='multiple'
              >
                {unit.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="coldStorage"
              label="Cold Storage"
              rules={[
                {
                  required: false,
                  message: 'Cold Storage is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
                mode="multiple"
              >
                <Option value={''}>Please Select</Option>
                {zoneDropDown?.map(zoneData => {
                  return <Option key={zoneData.zoneId} value={zoneData.zoneId}>{zoneData.coldStorage}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="product"
              label="Product SKU"
              rules={[
                {
                  required: false,
                  message: 'product is required'
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Cold Storage"
                // onChange={getSaleOrderItems}
                allowClear
              >
                <Option value={''}>Please Select</Option>
                {productData?.map(productsku => {
                  return <Option key={productsku.varientId} value={productsku.varientId}>{productsku.variantCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="stockType"
              label="SO Type"
              rules={[
                {
                  required: false,
                  message: 'Stock Type is required'
                },
              ]}
            >
              <Select
                placeholder="Select Stock Type"
                // onChange={getSaleOrderItems}
                allowClear
                mode="multiple"

              >
                <Option value="0">Please select</Option>
                {stockType.map(stockType => {
                  return <Option value={stockType.value}>{stockType.name}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="shift"
              label="Shift"
              rules={[
                {
                  required: false,
                  message: 'Stock Type is required'
                },
              ]}
            >
              <Select
                placeholder="Select Shift"
                // onChange={getSaleOrderItems}
                allowClear
                mode="multiple"

              >
                <Option value="0">Please select</Option>
                {Object.values(Shifts).map(res => {
                  return <Option value={res}>{res}</Option>
                })
                }
              </Select>

            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="lifeCycleStatus"
              label="Life Cycle Status"
              rules={[
                {
                  required: false, message: 'Missing Life Cycle Status',
                },
              ]}
            >
              <Select
                placeholder="Select Life Cycle Status"
                allowClear
                onChange={handleLifeCycle}

              >
                {Object.values(WarehouseLifeCycleEnum).map(WhLifeCycleStatus => {
                  return <Option value={WhLifeCycleStatus}>{WhLifeCycleStatus}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Dead stock aging' name='deadStock'>
              <Select placeholder="Select Aging" allowClear onChange={handleDeadStock} >
                {Object.values(DeadStockEnum).map(dropDeadStock => {
                  return <Option value={dropDeadStock}>{dropDeadStock}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>

          <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}  >
            <Button
              type="primary"
              // icon={<SearchOutlined />}
              onClick={() => {getStockInData(); getDeadStockData()}}
              disabled={loading}
            >
              Search
            </Button>
          </Col>
          <Col span={2} style={{ paddingTop: '30px' }}>
            <Button type="primary" htmlType="submit" onClick={onReset} style={{ marginLeft: 1 }} >
              Reset
            </Button>
            <br /><br />
          </Col>
        </Row>
      </Form>
      <br /><br />
      {


        stockInData.length > 0 ?

          <>
            {

              stockInData.forEach(item => {
                totalQuan += Number(item.quantity);
                totalCart += Number(item.totalCartons)
              })
            }

            <Card title='Report Summary' extra={<>
              <span><Tag style={{ background: "#38f438", color: 'black', height: 30, width: 120, fontSize: 14 }}>Below 50 Days</Tag></span>
              <span><Tag style={{ background: "#f8f838", color: 'black', height: 30, width: 120, fontSize: 14 }}>Above 50 Days</Tag></span>
              <span><Tag style={{ background: "#f4646c", color: 'white', height: 30, width: 120, fontSize: 14 }}>Above 100 Days</Tag></span></>}>
              <Row gutter={24}>
                <Col span={4}>
                  <Statistic title="Total Transactions" value={stockInData.length} />
                </Col>
                <Col span={4}>
                  <Statistic title="Total Cartons" value={Math.round(totalCart)} />
                </Col>
                <Col span={4}>
                  <Statistic title="Total Quantity(in KG's)" value={Math.round(totalQuan)} />
                </Col>
                <Col span={4}>
                  <Statistic title="Total Quantity(in LB's)" value={Math.round(totalQuan * 2.20462)} />
                </Col>
                {/* <Col >
                  {
                    deadStock?.length ? <Popover content={content} title="Dead Stock">
                      <Tag color={'geekblue'}>Dead Stock</Tag>
                    </Popover> : ''
                  }
                </Col> */}
                <Col span={4}>
                  <Button icon={<FileExcelOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }}>
                    Get Excel
                  </Button>
                </Col>
                <Col span={4}>
                  <Button icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
                    Get PDF
                  </Button>
                </Col>

              </Row>
            </Card>
            <Table rowKey={record => record.stockId}
              columns={columnsSkelton} dataSource={stockInData}
              scroll={{ x: 1200, y: 500 }}
              pagination={{
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize)
                }
              }}
              onChange={onChange}
              size="small"
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalCartoons = 0
                pageData.forEach(({ totalCartons, quantity }) => {
                  totalCartoons += Number(totalCartons);
                  totalQuantity += Number(quantity);
                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter' >
                      <Table.Summary.Cell index={3} colSpan={8}><Text style={{ textAlign: 'end' }}>Total</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} colSpan={5} ><Text ></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={11}><Text style={{ textAlign: 'end' }}>{totalCartoons}</Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }
              } />

          </> : <Empty description={empty ? 'No data found' : 'Select inputs and click search to get data'}></Empty>
      }
      {
        stockInData.length > 0 ?
          <>
            {
              deadStock.length > 0 ?
                <>
                  <Row justify='center'>
                    <Card title='Dead Stock'>
                      <Table dataSource={deadStock}
                        columns={columns}
                        style={{ width: '500px' }} ></Table>
                    </Card>
                  </Row>
                </> : ''}
          </> : ''
      }

    </Card>

  );
}

export default StockReportWarehouse;
