import { RolesList } from '@gtpl/shared-models/gtpl';

export interface UserRequest {
  username:string;
  password: string;
  email: string;
  Role: RolesList;
  employeeId: number;
  is_active:boolean;
}
export interface CreateUserDto {
  username:string;
  password: string;
  email: string;
  Role: RolesList;
  employeeId: number;
}
export interface UserCreateResponse {
  status: boolean;
  data?: CreateUserDto;
  errorInfo?: string;
}
export interface GetUserresponse {
  status: boolean;
  data?: CreateUserDto[];
  errorInfo?: string;
}

export const UserDefaultData = {
  username:"",
  password: "",
  Role:RolesList.LTWO,
  email: "",
  employeeId: 1,  
}