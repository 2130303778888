export class RackZoneMappingDTo {
    rackZoneMappingId?: number;
    rackId: number;
    zoneId: number;
    endCustomerId: number;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag?: number;


    /**
     * 
     * @param rackZoneMappingId
     * @param createdUser
     * @param isActive
     * @param versionFlag
     */

    constructor(rackId: number,zoneId: number,endCustomerId: number, createdUser: string, updatedUser: string, isActive: boolean, rackZoneMappingId?: number, versionFlag?: number) {
        this.rackZoneMappingId = rackZoneMappingId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.rackId = rackId;
        this.zoneId = zoneId;
        this.endCustomerId = endCustomerId;
    }
}