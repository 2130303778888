import { UploadDetailesGetResponse } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "../../../hrms/src/lib/connection";

export class UploadDetailesServiceIntigration{

    URL = connection.DEPLOY_URL + '/uploadDetailes';

    // async createContractor(data: ContractorDto): Promise<ContractResponseModel>{        
    //     return await axios.post(this.URL + '/uploadDetailes', data)
    //         .then(res => {
    //             return res.data
    //         })
    // }

    async getUploadDetailes(): Promise<UploadDetailesGetResponse>{
       
        return await axios.post(this.URL + '/getUploadDetailes')
           .then(res => {
               return res.data
           })
    }



}