
import { GlobalResponseObject } from '../global-response-object';
import { serviceOwnerDetails } from './service-owner-details.model';

export class ServiceOwnerDetailsResponse extends GlobalResponseObject{
    data: serviceOwnerDetails[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data: serviceOwnerDetails[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}