import React, { useEffect, useState } from 'react';

import './pd-qaqc.css';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button, Collapse, Descriptions } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import { LotCodeDropDown, OperationIdRequest, OperationRequest, OperationsDropDown, PdQaqcDto, QaQcDropDown, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import { QaQcTestResultEnum, RejectionReasonsEnum, ShiftsEnum, UnitRequest,TypeOfCutEnum } from '@gtpl/shared-models/common-models';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeOpsService, OperationService, QaQcOperationsService,PdQaqcService, WorkstationService } from '@gtpl/shared-services/production';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;
import { ItemsDetails, SaleOrderDropDownDto, SoNumbersModel } from '@gtpl/shared-models/sale-management';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoItemDetailsBySoIdDTO } from 'libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto';

/* eslint-disable-next-line */
export interface PdQaqcFormProps {
  peelingData : PdQaqcDto;
  isUpdate:boolean;
  closeForm: () => void;
 }

export function PdQaqcForm(props: PdQaqcFormProps) {
  let history = useHistory();
  const [PdQaqcForm] = Form.useForm();
  const [peelingSamplesForm] = Form.useForm()
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [soItemName, setSoItemName] = useState<ItemsDetails[]>([]);
  const [soItemDetails, setSoItemDetails] = useState<SoItemDetailsBySoIdDTO>(undefined);
  const [lotNumbers, setLotNumbers] = useState<LotCodeDropDown[]>([]);
  const lotCodeService = new LotCodeOpsService();
  const soItemService = new SaleOrderService;
  const [data,setData] = useState<any>([])
  const [disable,setDisable] = useState<boolean>(false);
  const pdQaqcService = new PdQaqcService();
  const selectBefore = (
    <Select defaultValue="Pcs" className="select-before">
      {/* <Option value="">Nil</Option> */}
      <Option value="Pcs">Pcs</Option>
    </Select>
  );
  useEffect(() => {
    if(props.peelingData){
      console.log(props.peelingData)
    }
  },[props.peelingData])
  useEffect(() => {
    PdQaqcForm.setFieldsValue({ noOfSamples:1,quantityTested:30 })
    getSaleOrders();
    getAllPeelings();
    if (props.isUpdate) {
      form.setFieldsValue({
        peelingId: props.peelingData.peelingId,
        peelingDate: moment(props.peelingData.peelingDate),
        saleOrderId: props.peelingData.saleOrderId,
        saleOrderItemId: props.peelingData.saleOrderItemId,
        lotNumber: props.peelingData.lotNumber,
        shift: props.peelingData.shift,
        updatedUser: localStorage.getItem('createdUser'),
      });
    }
  }, [])
  const getSaleOrders = () => {
    setSoItemDetails(undefined);
    lotCodeService.getSaleOrders().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
       console.log("q", form.getFieldValue('saleOrderId'))
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getAllSoItemsDropDown = () => {
    const saleOrderId = PdQaqcForm.getFieldValue('saleOrderId')
    console.log("soId",saleOrderId)
    form.setFieldsValue({
      saleOrderItemId: ''
    })
    const soREq: saleOrder = {
      saleOrderId: saleOrderId
    }
    lotCodeService.getSaleOrderItemsForSo(soREq).then((res) => {
      if (res.status) {
        setSoItemName(res.data);
      } else {
        AlertMessages.getErrorMessage("Something went wrong");
        setSoItemName([]);
      }
    })
      .catch((err) => {
        setSoItemName([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };


  const getSaleOrderItemDetails = () => {
    setSoItemDetails(undefined);
    const saleOrderId = PdQaqcForm.getFieldValue('saleOrderId');
    const saleOrderItemId = PdQaqcForm.getFieldValue('saleOrderItemId');
    soItemService.getSaleOrderItemDetailsBySoItemId({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId }).then((res) => {
      if (res.status) {
        setSoItemDetails(res.data);
        form.setFieldsValue({
          brand: res.data.brand,
          packing: res.data.packingStyle,
          product: res.data.productName
        })
      }
      else {
        if (res.intlCode) {
          setSoItemDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoItemDetails(null);
        AlertMessages.getErrorMessage(err.message);
      });
  }

  const getAllLotNumbersForSoItem = () => {
    const saleOrderId = PdQaqcForm.getFieldValue('saleOrderId');
    const saleOrderItemId = PdQaqcForm.getFieldValue('saleOrderItemId');
    lotCodeService.getAllLotNumbersForSoItem({ saleOrderId: saleOrderId, saleOrderItemId: saleOrderItemId, plantId: Number(localStorage.getItem('unitId')) }).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getAllPeelings = () => {

    pdQaqcService.getAll().then(res => {
      if (res.status) {
        console.log(res.data)
        setData(res.data);
      } else {
        if (res.intlCode) {
          setData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


 
  let createdUser = "";
  if (!props.isUpdate) {
    createdUser = localStorage.getItem("createdUser");
  }

  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const savePeeling = () => {
    setDisable(true)
    PdQaqcForm.validateFields().then(mainForm => {
      peelingSamplesForm.validateFields().then(samples => {
        mainForm.peelingSamplesInfo = (props.isUpdate) ? [...dataSource, samples] : [samples];
        pdQaqcService.createPeelingInfo(mainForm).then(res => {
          setDisable(false)
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            history.push("/pd-qaqc-print");
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message);
        })
      })
    })
  }
//   const onSubmit = ()=>{
//     PdQaqcForm.validateFields().then(formDetails) => {
//       formDetails.plantId= Number (localStorage.getItem('unit_id'));
//     pdQaqcService.createPeelingInfo(formDetails).then((res)=>{
//       if (res.status) {
//       AlertMessages.getSuccessMessage(res.internalMessage);
//       history.push('/pd-qaqc-print')
//     } else {

//     }
//   }).catch((err) => {
//     AlertMessages.getErrorMessage(err.message);
//   });
// }
    
    const onReset = () => {
    PdQaqcForm.resetFields();
    peelingSamplesForm.resetFields();
  };

  const columns = [

    {
      title:''
    }
  ]


  return (
    <Card title={<span style={{ color: 'white' }}>PD </span>}
          style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  extra={<Link to='/pd-qaqc-print' ><Button className='panel_button' > View </Button></Link>}>
       <Descriptions style={{ display: (props.isUpdate) ? 'unset' : 'none' }}>
        <Descriptions.Item label="PD Date">
          {(props.peelingData?.peelingDate) ? (moment(props.peelingData?.peelingDate).format("DD-MM-YYYY")) : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Sale Order">
        {(props.peelingData?.soNumber) ? (props.peelingData?.soNumber) : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Sale Order Item">
          {(props.peelingData?.item) ? (props.peelingData?.item) : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Lot">
       { (props.peelingData?.lotNumber) ? (props.peelingData?.lotNumber) : ''}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Total MC's">
          {props.peelingData?.shift ? props.peelingData?.shift : ''}
        </Descriptions.Item> */}
      </Descriptions>
      <Form layout={'vertical'} form={PdQaqcForm} name="control-hooks">
         <div style={{ display: props.isUpdate ? 'none' : 'block' }}>
          <Row gutter={24}>
            <Form.Item name="peelingId" >
              <Input hidden />
            </Form.Item>
            <Form.Item name="createdUser" initialValue={createdUser}>
              <Input hidden />
            </Form.Item>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="peelingDate"
                label="Date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: 'Date is Required'
                  },
                ]}
              >
                <DatePicker showToday={true} style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="saleOrderId" label="SO number" rules={[
                {
                  required: true,
                  message: 'SO number is Required'
                },
              ]}>
                <Select
                  showSearch
                  placeholder="Select Sale Order"
                  optionFilterProp="children"
                  onChange={getAllSoItemsDropDown}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  onClear={onReset}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Sale Order</Option>
                  {saleOrderData.map((saleOrder) => {
                    return <Option name='saleOrderId' key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.saleOrderNumber}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>

              <Form.Item
                name="saleOrderItemId"
                label="SO item"
                rules={[
                  {
                    required: true,
                    message: 'So item is Required'
                  },
                ]}
              >
                <Select
                  placeholder="Select Sale Order Item."
                  onChange={getSaleOrderItemDetails}
                  onSelect={getAllLotNumbersForSoItem}
                  // onClear={resetAll}
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  {soItemName.map((dropData) => {
                    return (
                      <Option name="saleOrderItemId" key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}
                      >
                        {dropData.item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="lotNumber" label="Lot No" rules={[{ required: true, message: 'Missing Lot No' }]}>
                <Select
                  placeholder="Select Lot No"
                  allowClear
                >
                  <Option key={0} value={null}>Select Lot</Option>
                  {lotNumbers.map((lot) => {
                    return <Option key={lot.lotCodeOpsId} value={lot.lotNumber}>{lot.lotNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Missing Shift' }]} >
                <Select
                  placeholder="Select Shift"
                  allowClear
                >
                  <Option key={0} value={null}>Select Shift</Option>
                  <Option key={1} selected value={ShiftsEnum.General}>{ShiftsEnum.General}</Option>
                  <Option key={2} value={ShiftsEnum.Shift_A}>{ShiftsEnum.Shift_A}</Option>
                  <Option key={3} value={ShiftsEnum.Shift_B}>{ShiftsEnum.Shift_B}</Option>
                  <Option key={4} value={ShiftsEnum.Shift_C}>{ShiftsEnum.Shift_C}</Option>
                </Select>
              </Form.Item>
           </Col>
        </Row>
        </div>
        <Row gutter={24} style={{ display: (soItemDetails) ? 'unset' : 'none' }}>
          <Descriptions column={3}>
            <Descriptions.Item label="Brand">
              {soItemDetails?.brand? soItemDetails.brand:''}
            </Descriptions.Item> 
            <Descriptions.Item label="Grade">
              {soItemDetails?.grade? soItemDetails.grade:''}
            </Descriptions.Item>
           </Descriptions>
         </Row>

        <br /><br />

         <Form
          layout="vertical"
          form={peelingSamplesForm}

        >
           <Collapse defaultActiveKey={['1']}>
            <Panel header="Sample Info" key="1" style={{ backgroundColor: '#69c0ff', border: 0, width: '100%' }} showArrow={true}>

       
          <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="sampleNo" label="Sample Number" rules={[{ required: true, message: 'Missing No. Of. Samples' }]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item name="temperature" label="Temperature" rules={[{ required: true, message: 'Temperature is required' }]}>
                  <InputNumber style={{ width: '100%' }} min={-5} max={7} />
                </Form.Item>
            </Col>
            
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

              <Form.Item name="count" label="Tested Count" rules={[{ required: true, message: 'Missing Count' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="typeOfCut" label="Type of Cut" rules={[{ required: true, message: 'Type of Cut is required' }]}>
              <Select
                  placeholder="Select Type of Cut"
                  allowClear
                >
                  <Option key={0} value={null}>Select Type of Cut</Option>
                  <Option key={1} selected value={TypeOfCutEnum.OneToFour}>{TypeOfCutEnum.OneToFour}</Option>
                  <Option key={2} value={TypeOfCutEnum.OneToFive}>{TypeOfCutEnum.OneToFive}</Option>
                  <Option key={3} value={TypeOfCutEnum.TwoToFour}>{TypeOfCutEnum.TwoToFour}</Option>
                  <Option key={4} value={TypeOfCutEnum.TwoToFive}>{TypeOfCutEnum.TwoToFive}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >

              <Form.Item name="bigToSmallRatio" label="Big-Small Ratio" rules={[{ required: true, message: 'Big-Small ratio required' }]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="legsVeinsShell" label="Legs/Veins/Shell" 
              rules={[{ required: true, message: 'Missing Legs/Veins/Shell' }]}>
               <Input  defaultValue="0" min={0} addonAfter={selectBefore} />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="improperCutting" label="Improper Cutting" rules={[{ required: true, message: 'Improper cutting is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="blackTailBlackSpot" label="Black Tail/Black Spot" rules={[{ required: true, message: 'Black Tail/Black Spot is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="brokenUnstable" label="Broken/Unusable" rules={[{ required: true, message: 'Broken/Unusable is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="deteriorationDecomposition" label="Deterioration" rules={[{ required: true, message: 'Deterioration/Decomposition is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item initialValue="0" name="otherObjectionableDefects" label="Oth. Obj. Defects" rules={[{ required: true, message: 'O ther Objectionable Defects is required' }]}>
               <Input  defaultValue="0" addonAfter={selectBefore} min={0} />
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
              <Form.Item name="testResult" label="Test Result" rules={[{ required: true, message: 'Missing Test Result' }]} >
                <Select
                  placeholder="Select Test Result"
                  allowClear
                >
                  <Option key={0} value={null}>Select Test Result</Option>
                  <Option key={1} selected value={QaQcTestResultEnum.TEST_RESULT}>{QaQcTestResultEnum.TEST_RESULT}</Option>

                </Select>
              </Form.Item>

            </Col> */}

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item name="remarks" label="Remarks" >
                <TextArea rows={4}></TextArea>
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                  <Form.Item name={"checkedBy"} label="checked By" rules={[
                      {
                        required: true,
                        message: `checked By required`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} style={{ paddingTop: '92px' }}>
                {/* <Button type='primary' onClick={savePeeling}> */}
                <Button type="primary"disabled={disable} onClick={savePeeling}>
                  Submit
                </Button>
                <Button htmlType="button" style={{ margin: '0 14px', color:'white', backgroundColor:'red' }} onClick={onReset}>
                  Cancel
                </Button>
            </Col>
          </Row>
          
          </Panel>
          </Collapse>
          </Form>
          </Form>
        </Card>
      
  );
}


export default PdQaqcForm;
