import { GlobalResponseObject, UnitRequest } from '@gtpl/shared-models/common-models';
import axios from 'axios';
import connection from './connection';
import{ReturnReq} from '@gtpl/shared-models/procurement-management';
import { AllStoreReturnsResponseModel, ConsumedQtyRequest, StoreReturnResponseModel, StrRtnCreateDto, StrRtnReq } from '@gtpl/shared-models/procurement-management';
import { CommonResponse } from '@gtpl/shared-models/masters';
export class StoreReturnService {
    static getAllStoreReturnsReport(req: UnitRequest) {
      throw new Error('Method not implemented.');
    }
    URL = connection.DEPLOY_URL + '/store-returns';


    /**
     * 
     * @returns  all store return details
     */
    async getAllStoreReturns(req): Promise<AllStoreReturnsResponseModel> {
        return await axios.post(this.URL + '/getAllStoreReturns', req)
            .then(res => {
                return res.data
            })
    }

    async getAllStoreReturnsReports(req:ReturnReq): Promise<AllStoreReturnsResponseModel> {
        return await axios.post(this.URL + '/getAllStoreReturnsReports', req)
            .then(res => {
                return res.data
            })
    }

    async getSoDropForStoreReturnReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSoDropForStoreReturnReport',req)
            .then(res => {
                return res.data
            })
    }
    async getItemSubDropForStoreReturnReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemSubDropForStoreReturnReport',req)
            .then(res => {
                return res.data
            })
    }
    async getItemDropForStoreReturnReport(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getItemDropForStoreReturnReport',req)
            .then(res => {
                return res.data
            })
    }


    async createStoreReturn(storeReturnData: StrRtnCreateDto): Promise<StoreReturnResponseModel> {
        return await axios.post(this.URL + '/createStoreReturn', storeReturnData)
            .then(res => {
                return res.data
            })
    }

    async getStoreReturnById(req: StrRtnReq): Promise<StoreReturnResponseModel> {
        return await axios.post(this.URL + '/getStoreReturnById', req)
            .then(res => {
                return res.data
            })
    }

    async getReturnedQty(req: ConsumedQtyRequest): Promise<number> {
        return await axios.post(this.URL + '/getReturnedQty', req)
            .then(res => {
                return res.data
            })
    }
}
