import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { LoanCategoriesDto } from "./loan-categories.dto";

export class AllLoanCategoriesResponse extends GlobalResponseObject{
    data?: LoanCategoriesDto[];

    /**
     * 
     * @param status
     * @param intlCode
     * @param internalMessage
     * @param data
     */

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: LoanCategoriesDto[]){
        super(status, intlCode, internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}