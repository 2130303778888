import React, { useState, useEffect } from 'react';
import { Descriptions, Badge, Layout, Row, Col, Card, Button, Spin } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { GrnRequest, RmEvaluationDto } from '@gtpl/shared-models/raw-material-procurement';
import { RMEvaluationService } from '@gtpl/shared-services/raw-material-procurement';
import { DeheadingSampleService } from '@gtpl/shared-services/production'
import moment from 'moment';

import './deheading-sample-test-print.css';
import { DeheadingQaQcDto, LogIdRequest } from '@gtpl/shared-models/production-management';

/* eslint-disable-next-line */
export interface DeheadingSampleTestPrintProps {
  productionLogId: number;
}

export function DeheadingSampleTestPrint(
  props: DeheadingSampleTestPrintProps
) {
  const [deheadingSamples, setDeheadingSamples] = useState<DeheadingQaQcDto>(undefined);
  const sampleService = new DeheadingSampleService();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const sampleColumnObj = { sampleNo: 'Sample No.', batchNo: 'B.No/S.Code', species: 'Type / Species', temperature: 'Temperature', discoloration: 'Discolouration', deterioration: 'Deterioration', softTail: 'Soft Tail', blackTail: 'Black Spot / Tail', brokenOrUnUsable: 'Broken/Un Usable', improperDeheading: 'Improper Deheading', brownMeat: 'Brown Meat', hangingMeatOrThroat: 'Hanging Meat/Throat' };


  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }

  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };

  const getSamplesInfoByLogId = (productionLogId) => {
    const req = new LogIdRequest(productionLogId);
    sampleService.getSamplesInfoByLogId(req).then(res => {
      if (res.status) {
        setDeheadingSamples(res.data);
        console.log
      } else {
        setDeheadingSamples(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    if (props.productionLogId) {
      getSamplesInfoByLogId(props.productionLogId);
    }
  }, [props.productionLogId])


  return (
    <div>
      <>
        <br /><br />
        {!deheadingSamples ?
          ''
          : <html>
            <head>
            </head>
            <body id='printme'>
              <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
              <br /><br />
              <table style={{ width: '100%' }}>
                <tr style={{ borderBottom: '0px' }}>
                  <td colSpan={4} style={{ fontSize: '17px', lineHeight: '12px', paddingTop: '20px', textAlign: 'center' }}>
                    <h2 >SANDHYA AQUA EXPORTS PVT. LIMITED, PAMARRU</h2>
                    <h3 ><br /><span style={{ backgroundColor: '#57595A', color: '#D4E1E7', margin: '4px' }}>Online Deheading REPORT</span></h3>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <h3 style={{ display: 'inline' }}>Date: </h3>&ensp;{moment(new Date()).format('YYYY-MM-DD')}
                  </td>
                </tr>
                <tr><td colSpan={4} style={{ fontSize: '17px', lineHeight: '12px', paddingTop: '20px', textAlign: 'center' }}>
                </td></tr>
                <tr>
                  <td>
                    <h3>HON QTY</h3>
                  </td>
                  <td>
                    :&ensp;{deheadingSamples?.hOnQty}
                  </td>
                  <td>
                    <h3 style={{ display: 'inline' }}>Date: </h3>&ensp;{moment(new Date()).format('YYYY-MM-DD')}
                  </td>

                </tr>
                <tr>
                  <td>
                    <h3>HL QTY</h3>
                  </td>
                  <td>
                    {/* :&ensp;{formatAMPM(deheadingSamples?.arrivalTime)} */}
                    :&ensp;{deheadingSamples?.hLessQty}
                  </td>

                </tr>
                {Object.entries(sampleColumnObj).map(([key, value]) => {
                  return (<tr style={{ border: '1px solid black' }}>
                    <td colSpan={(deheadingSamples?.deheadingSampleInfo.length % 2 === 0) ? 1 : 2} style={{ border: '1px solid black' }}><b>{(value).toUpperCase()}</b></td>
                    {deheadingSamples?.deheadingSampleInfo?.map((sample, index, array) => <td style={{ border: '1px solid black', textAlign: 'center' }}>{(key == 'time') ? moment(sample[key]).format('h:m A') : sample[key]}</td>)}
                  </tr>)
                })}

                <tr>
                  <td colSpan={2} style={{ border: '1px solid black', borderRight: '0px' }}><h3><u>Remarks and <br />Corrective Action</u></h3></td>
                  <td colSpan={2} style={{ border: '1px solid black', borderLeft: '0px' }}>{ }</td>
                </tr>
                <tr >
                  <td colSpan={4} style={{ textAlign: 'center' }}><h3>*Note:</h3>&ensp; Sample tested for every 1000 kgs each       N - Nil     P-pieces</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <br /><br /><br /><br />
                    <h3>Checked by</h3>
                  </td>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <br /><br /><br /><br />
                    <h3>Verified by</h3>
                  </td>
                </tr>
              </table>
              <script type='text/javascript'>

              </script>
            </body>
          </html>
        }
      </>
    </div>
  );
}

export default DeheadingSampleTestPrint;
