import connection from './connection';
import axios from 'axios';
import { CommonResponse, DeviceRequest, ForkLiftJobDTO, ForkLiftJobIdRequest, ForkLiftJobRequestDTO, ForkLiftJobStatusRequest, ForkLiftJobUpdateRequest, GetForkLiftJobResponseModel, JobIdRequest, tempLogReq, updateJobQty } from '@gtpl/shared-models/warehouse-management';
import { PlantIdRequest, RackPositionIdRequest } from '@gtpl/shared-models/masters';
import { PlantRequest } from '@gtpl/shared-models/raw-material-procurement';
export class ForkLiftJobService {
    URL = connection.DEPLOY_URL + '/fork-lift-jobs';
    temPDataURL = 'http://3.109.225.143:8888';

    async createForkLiftJob(forkLiftJobDTO: ForkLiftJobDTO): Promise<GetForkLiftJobResponseModel> {
        return await axios.post(this.URL + '/createForkLiftJob', forkLiftJobDTO)
            .then(res => {
                return res.data
            })
    }
    async updateForkLiftJobStatus(forkLiftJobStatusRequest: ForkLiftJobStatusRequest): Promise<boolean> {
        return await axios.post(this.URL + '/updateForkLiftJobStatus', forkLiftJobStatusRequest)
            .then(res => {
                return res.data
            })
    }
    async getForkliftJobCodes(forkLiftJobRequestDTO: ForkLiftJobRequestDTO): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getForkliftJobCodes', forkLiftJobRequestDTO)
            .then(res => {
                return res.data
            })
    }

    async getForkliftJobCodesForGrid(forkLiftJobRequestDTO: ForkLiftJobRequestDTO): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getForkliftJobCodesForGrid', forkLiftJobRequestDTO)
            .then(res => {
                return res.data
            })
    }
    async getSosAgainstJob(req: JobIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSosAgainstJob', req)
            .then(res => {
                return res.data
            })
    }
    async updateForkLiftJobQty(req: updateJobQty): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateForkLiftJobQty', req)
            .then(res => {
                return res.data
            })
    }
    async getAllStockTranserDcData(req: PlantRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllStockTranserDcData', req)
            .then(res => {
                return res.data
            })
    }
    async getAllStockTranserMrnData(req: PlantRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllStockTranserMrnData', req)
            .then(res => {
                return res.data
            })
    }
    async getStockTranserItemsData(req: JobIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getStockTranserItemsData', req)
            .then(res => {
                return res.data
            })
    }
    async getAllForkLiftItemsByJobId(req: ForkLiftJobIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllForkLiftItemsByJobId', req)
            .then(res => {
                return res.data
            })
    }
    async getTempLogData (req:tempLogReq): Promise<any>{
        return await axios.post(this.temPDataURL +'/api_v1',{
            "uid":["TM32D244","TM866790"]
      } , {
            headers: {
                'Content-Type': 'application/json',
            },
            auth: {
                username: 'username',
                password: '5*_UYApBW2Xds{F5PJ'
            },
        }) 
        .then(res=>{
            return res.data
        })
    }
    async getOperateDevice (req:DeviceRequest): Promise<any>{
        try{
            // deviceDataURL = 'http://192.168.0.19/api/'+req.userName+'/'+req.deviceName+'/'+req.bulbId+'/state';

            return await axios.put('http://192.168.2.91/api/'+req.userName+'/'+req.deviceName+'/'+req.bulbId+'/state',req) 
            .then(res=>{
                return res.data
            })
        }catch(err){
            console.log("Err"+err);

            return err;
        }
        
    }

    async updatePlaceListJob(forkLiftJobUpdateRequest: ForkLiftJobUpdateRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updatePlaceListJob', forkLiftJobUpdateRequest)
            .then(res => {
                return res.data
            })
    }
    async getJobDetailsByLocation(req: RackPositionIdRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getJobDetailsByLocation', req)
            .then(res => {
                return res.data
            })
    }

    
}
