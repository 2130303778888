import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { ShiftWiseDataModel } from "./shift-wise-data.model";


export class EmployeeShiftAttendanceResponse extends GlobalResponseObject {
    data?: ShiftWiseDataModel[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: ShiftWiseDataModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}