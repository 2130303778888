import { RackTypeResponseDto } from ".";

export class RackLevelResponseDto {
    rackLevel: number;
    rackDetails: RackTypeResponseDto[]
    constructor(rackLevel: number,
        rackDetails: RackTypeResponseDto[]){
            this.rackLevel=rackLevel
            this.rackDetails=rackDetails
    }
}