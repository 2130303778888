import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {  CodeListMainDto } from "@gtpl/shared-models/planning";

export class CodeListResponseMainModel extends GlobalResponseObject{
    data?: CodeListMainDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CodeListMainDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
