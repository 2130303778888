import { AllInterviewResponseModel, InterviewDto,  InterviewReportResponse,  InterviewResponseModel, JobDescriptionReq, ResponseObjectForRecruitment } from '@gtpl/shared-models/hrms';
import { CommonResponse } from '@gtpl/shared-models/masters';

import axios from 'axios';

import connection from './connection';

export class InterviewService {

    URL = connection.DEPLOY_URL + '/interview';

    async createinterview(InterviewDto: InterviewDto): Promise<InterviewResponseModel> {
        console.log(InterviewDto);

        return await axios.post(this.URL + '/createinterview', InterviewDto)
            .then(res => {
                return res.data
            })
    }

    async getAllInterview(): Promise<AllInterviewResponseModel> {

        return await axios.post(this.URL + '/getAllInterview')
            .then(res => {
                return res.data
            })
    }

    async updateInterview(InterviewDto: InterviewDto): Promise<InterviewResponseModel> {
        return await axios.post(this.URL + '/updateinterview', InterviewDto)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateInterview(InterviewDto: InterviewDto): Promise<InterviewResponseModel> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateinterview', InterviewDto)
            .then(res => {
                return res.data
            })
    }
    async getInterviewNameByInterviewIds(interviewReq: number[]): Promise<AllInterviewResponseModel> {
        return await axios.post(this.URL + '/getInterviewNameByInterviewIds', interviewReq)
            .then(res => {
                return res.data
            })
    }
    async getInterviewReportingData(): Promise<InterviewReportResponse> {
        return await axios.post(this.URL + '/getInterviewReportingData')
            .then(res => {
                return res.data
            })
    }

  


    async getRecruitmentData(): Promise<ResponseObjectForRecruitment> {
        return await axios.post(this.URL + '/getRecruitmentData')
            .then(res => {
                return res.data
            })
    }


    async getIDJob(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getIDJob')
            .then(res => {
                return res.data
            })
    }

    async getRecruitmentDataForUnit(unitReq:{plant_id:number}): Promise<ResponseObjectForRecruitment> {
        return await axios.post(this.URL + '/getRecruitmentDataForUnit',unitReq)
            .then(res => {
                return res.data
            })
    }


}