export class ItemCategoryIdReq {
    itemCategoryId: number;
    unitId: number;
    saleOrderId?: number;
    
    /**
     * 
     * @param itemCategoryId 
     * @param unitId 
     * @param saleOrderId 
     */
    constructor(itemCategoryId: number, unitId: number, saleOrderId?: number) {
        this.itemCategoryId = itemCategoryId;
        this.unitId = unitId;
        this.saleOrderId = saleOrderId;
    }
}