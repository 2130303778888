import { ConsumptionReportRequest, GetConsumedItemsRequest } from '@gtpl/shared-models/procurement-management';
import { StoreConsumptionService } from '@gtpl/shared-services/procurement';
import Table, { ColumnProps } from 'antd/lib/table';
import { QrcodeOutlined, PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Button, Modal } from 'antd';
import { Image } from 'antd';
export interface PlaceListProps {
    storeConsumptionId: number
    setModalVisible: (req: boolean) => void;
}

export function PlaceList(props: PlaceListProps) {
    const [printData, setPrintData] = useState<any[]>([])
    const [page, setPage] = React.useState(1);
    const [imageUrl, setImageUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [showQRModal, setShowQRModal] = useState('')
    const [rowData, setRowData] = useState<any>()
    const storeConsumptionService = new StoreConsumptionService();

    useEffect(() => {
        placeListPrintData()
    }, [])
    
    const placeListPrintData = () => {
        const req = new GetConsumedItemsRequest(0,props.storeConsumptionId)
        storeConsumptionService.placeListPrintData(req).then(res => {
            if (res.status) {
                res.data.forEach( async (i) => {i.imageURL = await  QRCode.toDataURL(i.rackPosition)})
                //res.data.forEach( async (i) => {i.imageUrl = await  QRCode.toDataURL(i.palletCode)})
                setPrintData(res.data)
                
            } else {
                if (res.intlCode) {
                    setPrintData([]);
                }
            }
        })

    }
    console.log(printData)

    const generateQR = async (record) => {
        try {
            const response = await QRCode.toDataURL(record.rackPosition);
            console.log(record, 'res')
            setImageUrl(response);
            setIsModalVisible(true);
            setShowQRModal('QRModal');
            setRowData(record)
        } catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        placeListPrintData();
        setIsModalVisible(false);
    };

    const printOrder = () => {
        const divContents = document.getElementById('printme').innerHTML;
        const element = window.open('', '', 'height=700, width=1024');
        element.document.write(divContents);
        element.document.close();
        element.print();
        element.close(); // to close window when click on cancel/Save
        setIsModalVisible(true); // model should be open
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'Rack Position',
            dataIndex: 'rackPosition'
        },
        {
            title: 'Item',
            dataIndex: 'item'
        },
        {
            title: 'No of Cartons',
            dataIndex: 'cartons'
        },
        {
            title: 'Rack Position Code',
            dataIndex: 'imageURL',
            render: imageURL  =>    <img src={imageURL} />
        },
        {
            title: 'Pallet Code',
            dataIndex: 'imageURL',
            render: imageUrl  =>    <img src={imageUrl} />
        }
    ]

    const onChange = (pagination, filters, sorter, extra) => {
    }
    return (
        <><body id='printme'>
            <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
            <Table
                rowKey={record => record.storeConsumptionId}
                columns={columns}
                dataSource={printData}
                pagination={false}
                onChange={onChange}
                scroll={{ x: 500 }}
                size="large"
                bordered
            >
            </Table></body>
            <Modal
                className='so-info-view'
                key={'modal' + Date.now()}
                width={showQRModal ? '30%' : '80%'}
                style={{ top: 30, alignContent: 'right' }}
                visible={isModalVisible}
                title={<React.Fragment>
                </React.Fragment>}
                onCancel={handleCancel}
                footer={[
                ]}
            >
                {
                    showQRModal == 'QRModal' ?
                        <>
                            {imageUrl ? (
                                <html>
                                    <body id='printme'>
                                        <Button onClick={printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                                        <div className="ticket">
                                            <h3 className="centered">SANDHYA AQUA </h3>
                                            <a href={imageUrl} download>
                                                <img src={imageUrl} alt="img" style={{ width: '100%' }} />
                                            </a>
                                            <p className="centered">{rowData ? rowData?.rackPosition : ''}
                                            </p>
                                            <p style={{ textAlign: 'center' }}>Description</p>
                                            <p style={{ textAlign: 'center' }}>{rowData ? rowData.rackPosition : ''}</p>
                                        </div>
                                    </body>
                                </html>
                            )
                                : null}
                        </> : ''
                }
            </Modal>
        </>
    )
}
export default PlaceList;