import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { Button, Card, Col, DatePicker, Form, Row, Select, Typography } from 'antd';
import { PlantIdRequest, StockInReverseReq } from 'libs/shared-models/warehouse-management/src/lib/fork-lift-job';

export interface StockInReverseReportProps { }

export function StockInReverseReport() {
    const { Option } = Select;
    const { Text } = Typography;
    const [page, setPage] = React.useState(1);
    const [stockinTransactionsData, setStockinTransactionsData] = useState<any>([])
    const [filtersData, setFiltersData] = useState<any[]>([]);
    const stockService = new FGStockService();
    const [form] = Form.useForm();
    const [poDrop, setPoDrop] = useState<any[]>([]);
    const [variantDrop, setVariantDrop] = useState<any[]>([]);
    const [plantDrop, setPlantDrop] = useState<any[]>([]);
    const [selectedunit, setUnit] = useState<number>(0)
    const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
    const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        getSaleOrderDropForStockInReverse();
        getVariantDropForStockInReverse();
        getPlantDropForStockInReverse();
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
        }
    }, [])

    const EstimatedETDDate = (value) => {
        if (value) {
            const fromDate = new Date(value[0].format('YYYY-MM-DD'));
            const toDate = new Date(value[1].format('YYYY-MM-DD'));
            setSelectedEstimatedFromDate(fromDate)
            setSelectedEstimatedToDate(toDate)
        }
    }
    const getStockInReverseReportdata = (onReset?: boolean) => {
        const req = new StockInReverseReq();
        let plantId = form.getFieldValue('plantId');
        let saleOrderId = form.getFieldValue('saleOrderId');
        let varientId = form.getFieldValue('varientId');
        let selectedData = filtersData;
        req.fromDate = onReset ? undefined : selectedEstimatedFromDate;
        req.toDate = onReset ? undefined : selectedEstimatedToDate;
        req.plantId = plantId;
        req.varientId = varientId
        req.saleOrderId = saleOrderId;
        if (Number(localStorage.getItem('unit_id')) != 5) {
            req.plantId = Number(localStorage.getItem('unit_id'));
        } else {
            req.plantId = selectedunit
        }
        stockService.getStockInReverseReportdata(req).then((res) => {
            if (res.status) {
                setStockinTransactionsData(res.data)
                setFiltersData(res.data)
                AlertMessages.getSuccessMessage("Data retrived sucessfully")
            } else {
                setStockinTransactionsData([])
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        });
        setStockinTransactionsData(selectedData)
    }
    const getSaleOrderDropForStockInReverse = () => {
        stockService.getSaleOrderDropForStockInReverse({ plantId: Number(localStorage.getItem("unit_id")) }).then((res) => {
            if (res.status) {
                setPoDrop(res.data)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    const getVariantDropForStockInReverse = () => {
        stockService.getVariantDropForStockInReverse({ plantId: Number(localStorage.getItem("unit_id")) }).then((res) => {
            if (res.status) {
                setVariantDrop(res.data)
            } else {
                setStockinTransactionsData([])
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    const getPlantDropForStockInReverse = () => {
        stockService.getPlantDropForStockInReverse({ plantId: Number(localStorage.getItem("unit_id")) }).then((res) => {
            if (res.status) {
                setPlantDrop(res.data)
            } else {
                setStockinTransactionsData([])
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        })
    }
    const onReset = () => {
        form.resetFields();
        setStockinTransactionsData([]);
        setSelectedEstimatedFromDate(undefined);
        setSelectedEstimatedToDate(undefined);
        if (Number(localStorage.getItem('unit_id')) != 5) {
            form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
        }

    }
    const handleUnit = (value) => {
        setUnit(value)
    }

    const columnsSkelton: ColumnProps<any>[] = [

        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            fixed: 'left',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            fixed: 'left',
            // filters: [
            //     { text: "Kakinada", value: "Kakinada" },
            //     { text: "Unit 1", value: "Unit 1" },
            //     { text: "Unit 2", value: "Unit 2" },
            // ],
            // filterMultiple: false,
            // onFilter: (value, record) => {
            //     return record.unit == value;
            // }
        },
        {
            title: 'Customer PO',
            dataIndex: 'poNumber',
            fixed: 'left',
            // sorter: (a, b) => a.saleOrderNumber.length - b.saleOrderNumber.length || a.saleOrderNumber.localeCompare(b.saleOrderNumber),
            sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: ' Reporting Date',
            dataIndex: 'reportingDate',
            fixed: 'left',
            width: '40px',
            key: 'stockDate',
            render: (value) => (<span style={{ float: 'right' }}>{moment(value).format("DD-MM-YYYY")}</span>)
        },
        {
            title: 'Reporting Cases',
            dataIndex: "reportingCases",
            fixed: 'left',
            sorter: (a, b) => a.reportingCases - (b.reportingCases),
            sortDirections: ['descend', 'ascend'],

        },


        {
            title: 'Rack Position',
            dataIndex: 'rackCode',
            sorter: (a, b) => a.rackCode?.localeCompare(b.rackCode),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            filters: [
                { text: 'Shift A', value: 'shiftA' },
                { text: 'Shift B', value: 'shiftB' },
                { text: 'Shift C', value: 'shiftC' },
                { text: 'General', value: 'general' },
            ],
            filterMultiple: true,
            onFilter: (value, record) => {
                return record.shift === value;

            }
        },

        {
            title: 'Loose Pouches',
            dataIndex: 'loosePouches'
        },
        {
            title: 'Pack Style',
            dataIndex: 'packStyle'
        },
        {
            title: "Transaction Type",
            dataIndex: "transactionType"
        },
        {
            title: "Packing status",
            dataIndex: 'packingCompleted',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.packingCompleted === value;
            },

        },
        {
            title: 'Pouches Shortage',
            dataIndex: 'pouchShortage',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.pouchShortage === value;
            },

        },
        {
            title: 'Cartons Shortage',
            dataIndex: 'cartonShortage',
            filters: [
                { text: 'YES', value: 'YES' },
                { text: 'NO', value: 'NO' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                return record.cartonShortage === value;
            },

        },
        {
            title: 'Variant',
            dataIndex: 'variantCode',
            render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
        },
        {
            title: 'Brand',
            dataIndex: "brandName"

        },



    ];
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    return (
        <>
            <Card
                title={<span style={{ color: 'white' }}>Stock In Reverse</span>}
                style={{ textAlign: 'center' }}
                headStyle={{ backgroundColor: '#69c0ff', border: 0 }} >
                <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                    <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="plantId"
                                label="Unit"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Unit"
                                    allowClear
                                    style={{ width: '100%' }}
                                    onChange={handleUnit}
                                    disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                                >
                                    {plantDrop.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.unitName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="fromDate"
                                label="Reported Date"
                                initialValue={undefined}
                                rules={[
                                    {
                                        required: false,
                                        message: " ETD Date"
                                    },
                                ]}>
                                <RangePicker onChange={EstimatedETDDate} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="saleOrderId"
                                label=" Sale Order"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Select Sale Order"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {poDrop.map(dropData => {
                                        return <Option value={dropData.saleOrderId}>{dropData.poNumber}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="varientId"
                                label="Variant"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Variant"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {variantDrop.map(dropData => {
                                        return <Option value={dropData.varientId}>{dropData.variantCode}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Button
                                type="primary"
                                block onClick={() => getStockInReverseReportdata()} style={{ marginRight: 2, width: 100 }}>
                                Get Report
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={onReset}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Table rowKey={record => record.stockId}
                    columns={columnsSkelton}
                    dataSource={stockinTransactionsData}
                    pagination={{
                        onChange(current) {
                            setPage(current);
                        }
                    }}
                    onChange={onChange}
                    scroll={{ x: true }}
                    size="small"
                    bordered
                    summary={(pageData) => {
                        let totalreportingCases = 0
                        pageData.forEach(({ reportingCases }) => {
                            totalreportingCases += Number(reportingCases);
                        });
                        return (
                            <>
                                <Table.Summary.Row className='tableFooter'>
                                    <Table.Summary.Cell index={11} colSpan={4} ><Text >Total</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{totalreportingCases}</Text></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }
                    }
                />


            </Card>
        </>
    )
}

export default StockInReverseReport;