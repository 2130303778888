export class GradeLotInvModel {
    lotNo: string;
    count: number;
    quantity: number;
    assignedQty: number;
    plannedSoNumber: string;
    poNumber?:string;
    saleOrderId?:number;
    concatLotNum?:string;
    boxes?:number;
    assignedBoxes?:number;
    assignedSO?:string;

    constructor(lotNo: string,count: number,quantity: number,assignedQty: number,plannedSoNumber: string,poNumber?:string,saleOrderId?:number,concatLotNum?:string,boxes?:number,assignedBoxes?:number,assignedSO?:string){
        this.lotNo = lotNo;
        this.count = count;
        this.quantity = quantity;
        this.assignedQty = assignedQty;
        this.plannedSoNumber = plannedSoNumber;
        this.poNumber = poNumber;
        this.saleOrderId = saleOrderId;
        this.concatLotNum = concatLotNum;
        this.boxes = boxes;
        this.assignedBoxes = assignedBoxes;
        this.assignedSO = assignedSO;
    }
}