import { ItemsDropDownDto } from "../items/items-drop-down.dto";
import { TaxDropDownDto } from "../taxes/tax-drop-down.dto";

export class VendorPriceListDto{
    vendorId:number;
    item:ItemsDropDownDto;
    tax:TaxDropDownDto;
    unitPrice:number;
    discountPercentage: number;
    discountPrice: number;
    actualPrice: number;
    skuCode : string;
    createdUser: string;
    isActive: boolean;
    vendorPriceListId?:number;
    updatedUser?: string;
    versionFlag?: number;
    itemName?: string;
    
    /**
     * 
     * @param vendor 
     * @param item 
     * @param tax 
     * @param unitPrice 
     * @param vendorPriceListId 
     */
    constructor(vendorId:number, item:ItemsDropDownDto, tax:TaxDropDownDto, unitPrice:number, discountPercentage: number, discountPrice: number, actualPrice: number, skuCode : string, createdUser: string, isActive: boolean, vendorPriceListId?:number, updatedUser?: string, versionFlag?: number,itemName?: string){
        this.vendorId =vendorId;
        this.item =item;
        this.tax =tax;
        this.unitPrice =unitPrice;
        this.discountPercentage =discountPercentage;
        this.discountPrice =discountPrice;
        this.actualPrice =actualPrice;
        this.skuCode =skuCode;
        this.createdUser =createdUser;
        this.isActive =isActive;
        this.vendorPriceListId =vendorPriceListId;
        this.updatedUser =updatedUser;
        this.versionFlag =versionFlag;
        this.itemName =itemName;
    }
}