import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";

export class ConfirmOrAssignSoData{
    containerNumber:string;
    saleOrderId:number;
    containerStatus:ContainerStatusEnum;
    createdUser:string;
    updatedUser?:string;
    containerId?:number;
    oldSoNumber?:number;

    /**
     * 
     * @param containerNumber 
     * @param saleOrderId 
     * @param containerStatus 
     * @param createdUser 
     * @param containerId 
     * @param updatedUser 
     */
    constructor(containerNumber:string, saleOrderId:number, containerStatus:ContainerStatusEnum, createdUser:string, containerId?:number, updatedUser?:string, oldSoNumber?:number){
        this.containerNumber = containerNumber;
        this.saleOrderId = saleOrderId;
        this.containerStatus = containerStatus;
        this.containerId = containerId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.oldSoNumber = oldSoNumber;
    }
}