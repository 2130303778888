import axios from 'axios';
import {VariantResponseModel, AllVariantResponseModel,VariantStatusWiseResponseModel,TaxesDto,TaxesResponseModel,AllTaxesResponseModel, TaxIdRequest, TaxDropDownDto, TaxDropDownResponse} from '@gtpl/shared-models/masters';
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/production-management';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class TaxesService {
URL = connection.DEPLOY_URL + '/taxes';

  async createTax(tax: TaxesDto): Promise<TaxesResponseModel> {
    console.log(tax);
      return await axios.post(this.URL + '/createTax',tax)
          .then(res => {
              return res.data
          })
  }
  async  updateTax(tax:  TaxesDto): Promise<TaxesResponseModel> {
    return await axios.post(this.URL + '/updateTax', tax)
           .then(res => {
               return res.data
           })
}
  async getAllTaxes(req?:UserRequestDto): Promise<AllTaxesResponseModel> {
    return await axios.post(this.URL + '/getAllTaxes',req)
        .then(res => {
            return res.data
        })     
}
async  ActivateOrDeactivateTax(taxDto: TaxesDto): Promise<VariantResponseModel> {
    //   console.log(State.stateCode);
      return await axios.post(this.URL + '/activateOrDeactivateTax', taxDto)
              .then(res => {
                  return res.data
              })
  }
//   async  getAllActiveVariants(): Promise<AllVariantResponseModel> {
//     return await axios.post(this.URL + '/getAllActiveVariants')
//         .then(res => {
//             return res.data
//         })
  async getAllActiveTaxes(): Promise<AllTaxesResponseModel> {
    return await axios.post(this.URL + '/getAllActiveTaxes')
        .then(res => {
            return res.data
        })     
  }

  async getActiveTaxDropDown(): Promise<TaxDropDownResponse> {
    return await axios.post(this.URL + '/getActiveTaxDropDown')
        .then(res => {
            return res.data
        })     
  }
  
  async getTaxForId(req:TaxIdRequest): Promise<TaxDropDownDto> {
    return await axios.post(this.URL + '/getTaxForId', req)
        .then(res => {
            return res.data
        })     
  }

  async getTaxForNoTax(): Promise<TaxDropDownDto> {
    return await axios.post(this.URL + '/getTaxForNoTax')
        .then(res => {
            return res.data
        })     
  }
  async getDefaultTaxForPO(): Promise<TaxDropDownDto> {
    return await axios.post(this.URL + '/getDefaultTaxForPO')
        .then(res => {
            return res.data
        })     
  }

  async testWhatsapp(): Promise<any> {
      return await axios.post('https://graph.facebook.com/v15.0/102194172756615/messages', {
        "messaging_product": "whatsapp",
        "to": "919030069351",
        "type": "template",
        "template": {
            "name": "hello_world",
            "language": {
                "code": "en_US"
            },
            "components": [
              {
                "type": "body",
                "parameters": [
                  {
                    "type": "text",
                    "text": "your-text-string"
                  },
                  {
                    "type": "currency",
                    "currency": {
                      "fallback_value": "$100.99",
                      "code": "USD",
                      "amount_1000": 100990
                    }
                  },
                  {
                    "type": "date_time",
                    "date_time": {
                      "fallback_value": "February 25, 1977",
                      "day_of_week": 5,
                      "day_of_month": 25,
                      "year": 1977,
                      "month": 2,
                      "hour": 15,
                      "minute": 33
                    }
                  },
                  {
                    "type": "date_time",
                    "date_time": {
                      "fallback_value": "February 25, 1977",
                      "timestamp": 1485470276
                    }
                  }
                ]
              }
            ]
        }
    }, {
        headers: {
            'Authorization': `Bearer EAAMPZAswUgD4BAOUwlRXgVhMgpFW4eXT3z2ZBcVlIPnTtixguAqc74xFS1ydBrTuCD55njvOS7B9ZB4ZAZB3pvFQ4ySYEMZChD1OkKv5RUQ6wZBwOZCfiOB1F3KZCbqL8CHcDReQSOcjGHAZAzX7oy7ylsmaNpTgacI1aUQ8LydnFXR4PMHMZBDH8ZAz`,
        },
    }).then(res => {
        return res.data;
    }).catch(err => {
      return err
    }

    )
  }
}


