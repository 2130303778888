import {IsInt, IsEmail, IsString, IsDateString, IsNumber, IsDate,IsAlphanumeric, MaxLength, Matches, IsOptional, IsNotEmpty, ValidateNested } from 'class-validator';
import { ApiProperty } from '@nestjs/swagger';
import { FarmsModel } from './farms.model';
import { FarmerModel } from './farmers.model';
import { SupplierTypeEnum } from '@gtpl/shared-models/common-models';
export class SupplierDTO {
  supplierId?: number;
  supplierCode: string;
  supplierName: string;
  gstNumber: string;
  brokerId: number;
  contactPerson: string;
  street: string;
  state: string;
  district: string;
  commission: number;
  apartment: string;
  city: string;
  postalCode: string;
  bankAccNo: string;
  bankIfsc: string;
  bankName: string;
  bankBranch: string;
  contactNumber: string;
  emailId: string;
  isActive: boolean;
  createdAt : Date;
  createdUser : string;
  supplierCategory: SupplierTypeEnum;
  updatedAt : Date;
  updatedUser? : string;
  farmInfo?: FarmsModel[];
  Farmers?: number[];
  versionFlag?:number;
  paymentPeriod?:number;
  constructor(
    supplierCode: string,supplierName: string,gstNumber: string,brokerId:number,contactPerson: string,street: string,apartment: string,city: string,postalCode: string,bankAccNo: string,bankIfsc: string,bankName: string,bankBranch: string,contactNumber: string,emailId: string,isActive: boolean,createdAt : Date,createdUser : string,supplierCategory:SupplierTypeEnum,updatedAt : Date,farmInfo?:FarmsModel[],updatedUser? : string,supplierId?: number,Farmers?:number[],versionFlag?:number, paymentPeriod?:number
  ){
    this.supplierCode = supplierCode;
    this.supplierName = supplierName;
    this.gstNumber = gstNumber;
    this.brokerId = brokerId;
    this.contactPerson = contactPerson;
    this.street = street;
    this.apartment = apartment;
    this.city = city;
    this.postalCode = postalCode;
    this.bankAccNo = bankAccNo;
    this.bankIfsc = bankIfsc;
    this.bankName = bankName;
    this.bankBranch = bankBranch;
    this.contactNumber = contactNumber;
    this.emailId = emailId;
    this.isActive = isActive;
    this.createdAt  =createdAt;
    this.createdUser  =createdUser;
    this.supplierCategory  =supplierCategory;
    this.updatedAt  =updatedAt;
    this.farmInfo  =farmInfo;
    this.updatedUser =  updatedUser;
    this.supplierId = supplierId;
    this.Farmers = Farmers;
    this.versionFlag = versionFlag;
    this.paymentPeriod = paymentPeriod;
  }
}

