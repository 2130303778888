import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class LotTrackingResponseDto{
    lotNumber:string;
    // saleOrderId:number;
    // saleOrderNo:string;
    // saleOrderItemId:number;
    // saleOrderItem:string;
    // count:number;
    physicalQuantity:number;
    issuedQuantity:number;
    workStation:string;
    operation:OperationTypeEnum;
    jobNumber:string;
    jobStatus:string;
    assignedTime:Date;
    // boxes:number;
    // productionInventoryId:number;
    // inputGrade:number;
    // outputGrade:number;
    // boxWeight:number;
    // soakingTime:string;
    // soakingStyle:string;
    // outputBoxes:number;

    constructor(lotNumber:string,
        //  saleOrderId:number,
        //  saleOrderNo:string,
        //  saleOrderItemId:number,
        //  saleOrderItem:string,
        //  count:number,
         physicalQuantity:number,
         issuedQuantity:number,
         workStation:string,
        operation:OperationTypeEnum,
        jobNumber:string,
        jobStatus:string,
        assignedTime:Date,
        // boxes:number,
        //  productionInventoryId:number,
        //  inputGrade:number,
        //  outputGrade:number,
        //  boxWeight:number,
        // soakingTime:string,
        // soakingStyle:string,
        //  outputBoxes:number
        ){
        this.lotNumber = lotNumber;
        // this.saleOrderId = saleOrderId;
        // this.saleOrderNo = saleOrderNo;
        // this.saleOrderItemId = saleOrderItemId;
        // this.saleOrderItem = saleOrderItem;//product
        // this.count = count;
        this.physicalQuantity = physicalQuantity;
        this.issuedQuantity = issuedQuantity;
        this.workStation = workStation;
        this.operation = operation;
        this.jobNumber = jobNumber;
        this.jobStatus = jobStatus;
        this.assignedTime = assignedTime;
        // this.boxes = boxes;
        // this.productionInventoryId = productionInventoryId;
        // this.inputGrade = inputGrade;
        // this.outputGrade = outputGrade;
        // this.boxWeight = boxWeight;
        // this.soakingTime = soakingTime;
        // this.soakingStyle = soakingStyle;
        // this.outputBoxes = outputBoxes;
    }

}