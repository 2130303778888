import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';
import {
    AllPoDetailsInfo,
    // FactoriesInput,
    PoDataModel,
    PoRequest,

    // UnitsOfWeightInput,
} from '@gtpl/shared-models/procurement-management';
import { PrinterOutlined } from '@ant-design/icons'
import { CrrencySymbols } from '@gtpl/shared-models/sale-management';

import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ExporterDataInput } from '@gtpl/shared-models/logistics';
import { GlobalStatus, UomEnum } from '@gtpl/shared-models/common-models';
import { useLocation, useParams } from 'react-router-dom';
let converter = require('number-to-words');
import { ToWords } from 'to-words';

var convert = require('decimal-number-to-words');
import { numberToWords } from 'amount-to-words'
import moment from 'moment';
import './po-print.css';
/* eslint-disable-next-line */
export interface PoPrintProps {
    poId: number;
    printOrder: () => void
}
export function PoPrint(props: PoPrintProps) {
    const poService = new PurchaseOrderService();
    const [PoDataModel, PoItemsCreateDetail] = useState<AllPoDetailsInfo>();
    const [loading, setLoading] = useState(true);
    let sizeRequired;
    // let location = useLocation();
    // let  { poId } = useParams<{poId}>();
    console.log(location);
    useEffect(() => {
        getData(props.poId);
    }, [])
    // let totalCases = 0;
    // let totalLbWeight = 0;
    let totalAmount = 0;
    const getData = (poId) => {
        const reqObj = new PoRequest(poId);
        poService
            .GetPoDataDetailsForPoId(reqObj)
            .then((res) => {
                if (res.status) {
                    console.log(res.data);
                    PoItemsCreateDetail(res.data);
                    console.log(PoDataModel?.poItems[0])
                } else {
                    if (res.intlCode) {
                        PoItemsCreateDetail(undefined);
                        AlertMessages.getErrorMessage(res.internalMessage);
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    }
                }
            })
            .catch((err) => {
                AlertMessages.getErrorMessage(err.message);
                PoItemsCreateDetail(undefined);
            });
    }; //deliveryAddress
    let addressDetails = ExporterDataInput;
    const poData = addressDetails.find(
        (item) => item.value == PoDataModel?.purchaseOrderId
    );

    // let unitInput = FactoriesInput;
    // const unitsData = FactoriesInput.find(item => item.id == PoDataModel?.unitId)
    // const tableData = () => {
    //     const tableDataArray = [];
    //     for (const data of PoDataModel?.poCreationItemsInfo) {
    //         const tableDataArray = data;
    //     }
    // };
    // return 'test';
    const printOrder = () => {
        const printableElements = document.getElementById('printme').innerHTML;
        const orderHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
        const oldPage = document.body.innerHTML;
        document.body.innerHTML = orderHTML;
        window.print();
    }

    const convertCurrencyToSymbol = (currency) => {
        console.log(currency)
        let symbol = '';
        CrrencySymbols.map((curr) => {
            if (curr.name == currency) {
                symbol = curr.symbol
            }
        })
        return symbol;
    }

    const totalValue = (total, value) => {
        return Number(total) + Number(value);
    }

    const titleCase = (str) => str.toLowerCase().split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    PoDataModel?.poItems.forEach(item => {
        const flagSet = new Set()
        sizeRequired = (item.itemSubCategory.itemSubCategory == "Pouch" || "Carton") ? false : true
        if (sizeRequired == true) {
            flagSet.add(true)
        }
    })

    const totalTaxAmount = PoDataModel ? (Math.round(Number(PoDataModel?.poItems.reduce((currentValue, currentObject) => (currentObject.isActive) ? totalValue(currentValue, currentObject.taxAmount) : 0, 0)))) : null

console.log(PoDataModel?.poNumber,'poNumber')
console.log('totalTaxAmount',totalTaxAmount)

    return (
        <Card
            title={<span style={{ color: 'white' }}>Purchase Order</span>}
            style={{ textAlign: 'center' }}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
            extra={<Button onClick={props.printOrder} className='noprint' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>}
        >
            <html>
                <body id='printme'>
                    <h1 style={{ textAlign: 'center', padding: "20px"}}>{(PoDataModel?.billingAddressInfo?.unitcodeId == 12)?'NEELI SEA FOODS PVT. LIMITED':'SANDHYA AQUA EXPORTS PVT. LIMITED'}</h1>
                    <h2 style={{ textAlign: 'center' }}>REGD. OFFICE:
                        MIG 25-A, Lawsons Bay Colony,<br /> Visakhapatnam - 530017,
                        {PoDataModel?.unitinfo?.stateName?.toUpperCase()}. {PoDataModel?.unitinfo?.countryName?.toUpperCase()}  </h2>
                    <table className={'ta-b'} style={{ width: '100%' }}>
                        <tr>
                            <td colSpan={2} className={'ta-b'} style = {{textAlign :'left'}}>
                                Purchase Order Date:
                                <h4>{moment(PoDataModel?.purchaseOrderDate).format('DD-MM-YYYY')}</h4>
                            </td>
                            <td className={'ta-b'} colSpan={3} style = {{textAlign :'left'}}>
                                Purchase Order No.:<br />
                                <h4>{`${PoDataModel?.purchaseOrderNo}-${PoDataModel?.deliveryAddressInfo?.plantCode}`} </h4>

                            </td>
                            <td className={'ta-b'} colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 2 : 2} style={{ textAlign: 'right' }}>
                                GST Number:<br />
                                <h4>{(PoDataModel?.billingAddressInfo?.unitcodeId == 12)?'37AAECN9396F1ZX':'37AAJCS2286C1ZA'}</h4>
                            </td>
                        </tr>
                        <tr>
                            <td className={'ta-b'} colSpan={3} style = {{textAlign :'left'}}>
                                Vendor:<br />
                                <h4>{PoDataModel?.vendor.vendorName}<br />
                                    {(PoDataModel?.vendorInfo?.street) ? PoDataModel?.vendorInfo?.street + ", " : ''}
                                    {(PoDataModel?.vendorInfo?.apartment) ? PoDataModel?.vendorInfo?.apartment + ", " : ''}
                                    {(PoDataModel?.vendorInfo?.city) ? PoDataModel?.vendorInfo?.city + ", " : ''}
                                    {PoDataModel?.vendorInfo?.postalCode + ", "}
                                    <br />
                                    Attn: {(PoDataModel?.vendorInfo?.contactPerson) ? PoDataModel?.vendorInfo?.contactPerson : ''}
                                </h4>
                            </td>
                            <td style={{ textAlign: 'right' }} colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 4} className={'ta-b'} >
                                Expected Delivery Date:
                                <h4>{moment(PoDataModel?.expectedDeliveryDate).format('DD-MM-YYYY')}</h4>
                            </td>

                        </tr>
                        <tr>
                            <td  colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 3}  style = {{textAlign :'left'}}>
                                <h4>Delivery Address:</h4>
                                {(PoDataModel?.billingAddressInfo?.unitcodeId == 12) ? <><p style={{ width: '60%' }}><b>{'Neeli Sea Foods Pvt. Ltd'}</b></p></>:
                                <><p style={{ width: '60%' }}><b>SANDHYA AQUA EXPORTS PVT. LIMITED<br />{PoDataModel?.deliveryAddressInfo?.fdaRegNumber?.toUpperCase()},  {PoDataModel?.deliveryAddressInfo?.villageName?.toUpperCase()}, {PoDataModel?.deliveryAddressInfo?.mandalName?.toUpperCase()}, {PoDataModel?.deliveryAddressInfo?.distirctName?.toUpperCase()}, {PoDataModel?.deliveryAddressInfo?.postalCode?.toUpperCase()}, {PoDataModel?.deliveryAddressInfo?.stateName?.toUpperCase()}. {PoDataModel?.deliveryAddressInfo?.countryName?.toUpperCase()}</b></p></>}
                                {/* <br /> */}
                            </td>

                            <td className={'ta-b'} colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 4} style = {{textAlign :'left'}}>
                                <h4>Billing Address:</h4>
                                    {(PoDataModel?.billingAddressInfo?.unitcodeId == 12)?<p><b>{'Neeli Sea Foods Pvt.Ltd'}</b></p>:null}
                                <p style={{ width: '40%' }}><b>
                                    {PoDataModel?.billingAddressInfo?.fdaRegNumber?.toUpperCase()},  {PoDataModel?.billingAddressInfo?.villageName?.toUpperCase()}, {PoDataModel?.billingAddressInfo?.mandalName?.toUpperCase()}, {PoDataModel?.billingAddressInfo?.distirctName?.toUpperCase()}, {PoDataModel?.billingAddressInfo?.postalCode?.toUpperCase()}, {PoDataModel?.billingAddressInfo?.stateName?.toUpperCase()}. {PoDataModel?.billingAddressInfo?.countryName?.toUpperCase()}</b></p>
                            </td>
                        </tr>

                        <tr style = {{textAlign :'left'}}>
                            <th className={'ta-b'} > Sno</th>
                            {PoDataModel?.poType === "WITH SO" ? <>
                            <th colSpan={1} className={'ta-b'} >
                                PO Number
                            </th>
                            </> : '' }
                            
                            <th colSpan={1}
                                // colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 2 : 3}
                                className={'ta-b'}>
                                Item
                            </th>
                            {/* <th colSpan={1}
                                // colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 2 : 3}
                                className={'ta-b'}>
                                Variant-Description
                            </th> */}

                            <th colSpan={1} className={'ta-b'} >
                                Size
                            </th>
                            <th colSpan={1}
                                //  colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 1} 
                                className={'ta-b'}>
                                Quantity

                            </th>
                            {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th colSpan={1} className={'ta-b'}>Unit Price</th> : ''}
                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th className={'ta-b'}>Tax(%)</th> : ''} */}
                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th colSpan={1} className={'ta-b'}>TaxAmount</th> : ""} */}
                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th colSpan={2} style={{ textAlign: 'right' }} className={'ta-b'}>Amount</th> : ""} */}
                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th className={'ta-b'}>Unit Price</th> : ''} */}
                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th className={'ta-b'}>Tax(%)</th> : ''}
                            {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th colSpan={1} className={'ta-b'}>TaxAmount</th> : ""} */}
                            {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <th style={{ textAlign: 'right' }} className={'ta-b'}>Amount</th> : ""}
                        </tr>
                        {
                            PoDataModel?.poItems.map((item,index) => {
                                if (item.isActive) {
                                    totalAmount = totalAmount + Number(item.Amount);
                                    let itemNameParts = item.item.itemName?.split('/');
                                    console.log(itemNameParts, 'item name')

                                    return (

                                        <tr className='css-serial'>

                                            {/* <td className={'ta-b'}>{item.itemCategory.itemCategory}</td> */}
                                            <td className={'ta-b'} id='count1' >{index+1} </td>
                                            {PoDataModel?.poType === "WITH SO" ? <>
                                            <td style={{ textAlign: 'left' }} colSpan={1} className={'ta-b'}>{item.poNumber}</td>
                                            </>:''}
                                            
                                            
                                            <td colSpan={1} 
                                                //  colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 2 : 3} 
                                                className={`ta-b stylesInfo`} style={{ fontSize: '1.17em' , textAlign:'left' }} >{(itemNameParts?.length > 2) ? (itemNameParts.map((itemPart, index) => {
                                                    let name = (index === 1 ? <b>{itemPart}</b> : itemPart);
                                                    if ((itemNameParts?.length - index) != 1 && index != 1) {
                                                        name += '/';
                                                    }
                                                    if (index === 2) {
                                                        name = "/" + name;
                                                    }
                                                    // adding p before bap no
                                                    // if (index === 4) {
                                                    //     name = "P" + name;
                                                    // }
                                                    return name;
                                                })) : item.item.itemName}
                                                 
                                                - {item.item.product}&nbsp;{item.item.foodType}&nbsp;{item.item.valAddFour}
                                                
                                                
                                                </td>
                                                {/* <td colSpan={1} style={{ textAlign: 'left' }} className={'ta-b'}>
                                                {item.item.product}&nbsp;{item.item.foodType}&nbsp;{item.item.valAddFive}
                                                </td> */}
                                            <td style={{ textAlign: 'right' }} colSpan={1} className={'ta-b'}>{item.item.sizeName}</td>

                                            <td colSpan={1}
                                                // colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 1} 
                                                style={{ textAlign: 'right' }} className={'ta-b'}>{item.quantity}</td >

                                            {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <td style={{ textAlign: 'right' }} className={'ta-b'}>{convertCurrencyToSymbol(PoDataModel?.vendor.currency)} {Number(item.unitPrice)}</td > : ""}


                                            {/* <td style={{textAlign:'right'}} className={'ta-b'}>{item.transportation}</td >
                                            <td style={{textAlign:'right'}} className={'ta-b'}>{item.discountPercentage}</td >
                                            <td style={{textAlign:'right'}} className={'ta-b'}>{item.discountAmount}</td > */}
                                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <td style={{ textAlign: 'right' }} className={'ta-b'}>{(item.tax.taxPercentage) ? item.tax.taxPercentage : 0}</td> : ""} */}

                                            {/* {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <td style={{ textAlign: 'right' }} className={'ta-b'}>{Number(Number(item.taxAmount).toFixed(3))}</td> : ""} */}

                                            {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <td colSpan={2} style={{ textAlign: 'right' }} className={'ta-b'}>{convertCurrencyToSymbol(PoDataModel?.vendor.currency)} {
                                                new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Number(item.Amount).toFixed(3)))
                                            }</td> : ""}
                                        </tr>
                                    )
                                }
                            }
                            )
                        }
                        {(PoDataModel?.vendor.priceNeeded == GlobalStatus.YES) ? <tr>
                            <td colSpan={3} className={'ta-b'} style={{ textAlign: 'left' }}>
                                Amount Payable (in words)
                                <br />
                                <h3>{PoDataModel?.vendor?.currency == 'INR' ? '('+(PoDataModel?.vendor?.currency?.toUpperCase())+')':''} {PoDataModel?.vendor?.currency == 'INR' ? titleCase(numberToWords(Number(Math.round(Number(Number(totalAmount)))))) : (new ToWords({
                                localeCode: 'en-US',
                                }).convert(Number(Number(totalAmount).toFixed(3)),{ currency: true, ignoreZeroCurrency: true }))} {PoDataModel?.vendor?.currency == 'INR' ? 'Only' : ''}</h3>
                            </td>
                            <td className={'ta-b'} colSpan={2} style={{ textAlign: 'left' }}>
                                <br />
                                <h4>Basic Amount</h4>
                                {
                                    totalTaxAmount > 0 ? <>
                                    <h4>Taxable Amount</h4>
                                    </> : ''
                                }
                                
                                <h4>Total ({PoDataModel?.vendor?.currency?.toUpperCase()})</h4>
                            </td>
                            <td style={{ textAlign: 'right' }} className={'ta-b'} colSpan={2}>
                                {/* {convertCurrencyToSymbol(PoDataModel?.vendor.currency)} {
                                    Math.round(Number(PoDataModel?.poItems.reduce((currentValue, currentObject) => totalValue(currentValue, currentObject.taxAmount), 0)))
                                } */}

                                {
                                    CrrencySymbols.find(item => item.name == 'USD') ? <>
                                     {
                                         convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                     }
                                     {
                                         new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Number(totalAmount)   - Number(totalTaxAmount)))  
                                     }
                                    </> : <>
                                    {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                   }
                                   {
                                    new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Math.round(Number((Number(totalAmount)   - Number(totalTaxAmount) ) ))))  
                                    }
                                    </>
                                }
                                {/* {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Math.round(Number((Number(totalAmount)   - Number(totalTaxAmount) ) ))))  
                                } */}
                                <br />
                                  {
                                    totalTaxAmount > 0 ? <>
                                    {
                                      CrrencySymbols.find(item => item.name == 'USD') ? <> 
                                {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(PoDataModel?.poItems.reduce((currentValue, currentObject) => (currentObject.isActive) ? totalValue(currentValue, currentObject.taxAmount) : 0, 0))))
                                } </> : <>
                                {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Math.round(Number(PoDataModel?.poItems.reduce((currentValue, currentObject) => (currentObject.isActive) ? totalValue(currentValue, currentObject.taxAmount) : 0, 0)))))
                                }
                                </>
                                }
                                    </> : ''
                                }
                                

                                {/* {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Math.round(Number(PoDataModel?.poItems.reduce((currentValue, currentObject) => (currentObject.isActive) ? totalValue(currentValue, currentObject.taxAmount) : 0, 0)))))
                                } */}
                                <br />

                                {
                                       CrrencySymbols.find(item => item.name == 'USD') ? <>
                                {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Number(totalAmount)))
                                }
                                       </> : <>
                                {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Math.round(Number(Number(totalAmount)))))
                                }
                                       </>
                                }


                                {/* {
                                    convertCurrencyToSymbol((PoDataModel?.vendor.currency))
                                }
                                {
                                    new Intl.NumberFormat(CrrencySymbols.find(item => item.name == PoDataModel?.vendor.currency).locale).format(Number(Math.round(Number(Number(totalAmount)))))
                                } */}

                                {/* {convertCurrencyToSymbol(PoDataModel?.vendor.currency)} {Number(Math.round(Number(Number(totalAmount))))} */}
                            </td>
                        </tr> : ''}

                        <tr>

                            <td colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 5} className={'ta-b'} style={{ textAlign: 'left' }}>
                                Remarks:<br />
                                <p style={{ width: 300 }}>{PoDataModel?.remarks} </p>
                            </td>
                            <td colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 3 : 2} className={'ta-b'} style={{ textAlign: 'left' }}>
                                Note:
                                <h4>{PoDataModel?.note} </h4>

                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }} colSpan={(PoDataModel?.vendor.priceNeeded == GlobalStatus.NO) ? 6 : 7} className={'ta-b'}>
                                Signature
                                <h4>{(PoDataModel?.billingAddressInfo?.unitcodeId == 12)?'NEELI SEA FOODS PVT. LIMITED':'SANDHYA AQUA EXPORTS PVT. LIMITED'}</h4>
                                <br />
                                <h4>AUTHORISED SIGNATORY</h4>
                            </td>
                        </tr>
                    </table>
                </body>
            </html>
        </Card>
    );
}

export default PoPrint;