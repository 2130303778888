export const StockTypeDropDown = [
    {name : 'Anticipated',value:'anticipated'},{name : 'Dummy',value:'dummy'},{name:'Sale Order',value:'SaleOrder'},{name : 'Super Dummy',value:'superDummy'}
]
export enum stockTypeEnum {
    Anticipated = 'anticipated',
    Dummy = 'dummy',
    SaleOrder = 'SaleOrder',
    SuperDummy = 'Super Dummy',
}
export const TransactionTypeDropDown =[
    {name : 'Fresh Production',value:'rm'},{name : 'Repacking',value:'repacking'},{name:'Reprocessing',value:'reprocessing'},{name:'Stock Transfer',value:'stockTransfer'}
]

export enum TypeOfStock{
    GOOD = 'GOOD',
    DAMAGED = 'DAMAGED'
}