import React, { useState } from 'react';
import { AutoComplete, Button, Card, Cascader, Col, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch, Tag, TreeSelect } from 'antd';
import './close-po-form.css';
import { AllPoDetailsInfo } from '@gtpl/shared-models/procurement-management';
import TextArea from 'antd/lib/input/TextArea';
import { PurchaseOrderService, } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';

/* eslint-disable-next-line */
export interface ClosePoFormProps {
  poInfo: AllPoDetailsInfo;
  handleCancel: () => void;
}

export function ClosePoForm(
  props: ClosePoFormProps
) {
  const [closePoManually] = Form.useForm<any>();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);

  const service = new PurchaseOrderService;
  const closePO = () => {
    closePoManually.validateFields().then(manualPO => {
      setBtnDisable(true);
      service.closePOManually(manualPO).then(res => {
        if (res.status) {
          setBtnDisable(false);
          props.handleCancel();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })

    })
  }
  return (
    <Card size="small" title={<span style={{ color: 'white' }} >Close Purchase Order</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form
        layout="vertical"
        form={closePoManually}
      >
        <Row gutter={24}>
          <Form.Item name={'purchaseOrderId'} initialValue={props.poInfo.purchaseOrderId}>
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Closing Reason" name={'reasonClosing'} rules={[
              {
                required: true,
                message: `Missing Closing Reason`,
              },
            ]}>
              <TextArea showCount autoSize maxLength={50}/>
            </Form.Item>
          </Col>
          <Col style={{paddingTop:'28px'}}>
            <Button type='primary' onClick={closePO} block disabled={btnDisable}>
              Close PO
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ClosePoForm;
