import { BomIndentInfo } from "./bom-indent-info";
import { CountryInfo } from "./country-info";
import { DrilldownInfo } from "./drill-down-info";
import { PoInfo } from "./po-info";
import { countRangeWiseInfo } from "./product-count-wise-bom-info";
import { RatingInfo } from "./rating-info";
import { seriesInfo } from "./series-info";

export class RmBomDto {
    bomId: number;
    productName: string;
    countryName: string;
    certification: string;
    rating: number;
    count: number;
    requiredQuantity: number;
    indentQuantity: number;
    uomName?: string;
    edaDate?: Date;
    bomIndentInfo?:BomIndentInfo[];
    poWiseInfo?:PoInfo[];
    ratingWiseInfo?:RatingInfo[];
    countryWiseInfo?:CountryInfo[];
    countRangeWiseInfo?:seriesInfo[];
    drilldownInfo?:DrilldownInfo[];
    dataExistingKeys?:any[];
    // countRangeBomInfo?:[];
    /**
     * 
     * @param bomId
     * @param countryName
     * @param certification
     * @param rating
     * @param count
     * @param requiredQuantity
     * @param indentQuantity
     * @param uomName
     * @param edaDate
     */
    constructor(bomId:number,productName: string, countryName: string, certification: string, rating: number, count: number, requiredQuantity: number, indentQuantity: number,uomName?:string,edaDate?:Date,bomIndentInfo?:BomIndentInfo[],poWiseInfo?:PoInfo[],ratingWiseInfo?:RatingInfo[],countryWiseInfo?:CountryInfo[],countRangeWiseInfo?:seriesInfo[],drilldownInfo?:DrilldownInfo[],dataExistingKeys?:any[]) {
        this.bomId = bomId;
        this.productName = productName;
        this.countryName = countryName;
        this.certification = certification;
        this.rating = rating;
        this.count = count;
        this.requiredQuantity = requiredQuantity;
        this.indentQuantity = indentQuantity;
        this.uomName = uomName;
        this.edaDate = edaDate;
        this.poWiseInfo = poWiseInfo;
        this.bomIndentInfo = bomIndentInfo;
        this.countryWiseInfo = countryWiseInfo;
        this.ratingWiseInfo = ratingWiseInfo;
        this.countRangeWiseInfo = countRangeWiseInfo;
        this.drilldownInfo = drilldownInfo;
        this.dataExistingKeys = dataExistingKeys;
    }
}