export class AssetByAssetCode{
    assetsCode?: string;
    assetCategory?: string;
    plant?: string;
    location?: string;
    department?: string;
    assetType?: string;     
    employeeId?: number;
    plantId?:number;
    aging?:string;
    fromDate?: any;
    toDate?: any;

    constructor(
        assetsCode?: string,
        assetCategory?: string,
        plant?: string,
        location?: string,
        department?: string,
        assetType?: string,    
        employeeId?: number,
        plantId?:number,
        aging?:string,
        fromDate?: any,
        toDate?: any,

    ) {
        this.assetsCode = assetsCode;
        this.assetCategory = assetCategory;
        this.plant = plant;
        this.location = location;
        this.department = department;
        this.assetType = assetType;
        this.employeeId = employeeId;
        this.plantId = plantId;
        this.aging = aging;
        this.fromDate = fromDate;
        this.toDate = toDate;
    }
}