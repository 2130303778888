import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Modal, Radio, Row, Select, Table, Tooltip } from 'antd';
import { GRNLocationPropsRequest, LocationMappingRmRackPostionReq, VendorDropDownDto } from '@gtpl/shared-models/masters';
import { Link, useHistory,useLocation} from 'react-router-dom';
import { PlantIdRequest } from '@gtpl/shared-models/warehouse-management';
import { RmRackPositionService, VendorsService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {GrnService, StockService, TransferMaterialService} from '@gtpl/shared-services/procurement';
import type { RadioChangeEvent } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner';
import { GrnInvoiceNumberRequest, locationIdRequest, LocationUpdateRequest } from '@gtpl/shared-models/procurement-management';
const { Option } = Select;

/* eslint-disable-next-line */
export interface UpdateLocationForReceivedStockProps {
  transferId : any;
  closeForm: () => void;
}

export function UpdateLocationForReceivedStock(
  props: UpdateLocationForReceivedStockProps
) {
  const locationService = new RmRackPositionService;
  const stockService = new StockService();
  const transferServce = new TransferMaterialService();

  const [page, setPage] = React.useState(1);
  const [locations, setLocations] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [prevTransactionsInfo, sePrevTransactionsInfo] = useState<any[]>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [locationStatusValue, setValue] = useState<string>('Occupied');
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);

  const [form] = Form.useForm();
  useEffect(() => {
    if(props.transferId){
      getAllLocations(props.transferId.unitId, props.transferId.brandId);
      form.setFieldsValue({itemId:props.transferId.itemId});
      form.setFieldsValue({itemName:props.transferId.item});
      form.setFieldsValue({itemSubCategoryId:props.transferId.itemSubCategoryId});
      form.setFieldsValue({itemCategoryId:props.transferId.itemCategoryId});
      form.setFieldsValue({saleOrderId:props.transferId.saleOrderId});
      form.setFieldsValue({unitId:props.transferId.unitId});
      form.setFieldsValue({unitPrice:props.transferId.unitPrice});
      form.setFieldsValue({packingStockTransferItemId:props.transferId.packingStockTransferItemId});
      form.setFieldsValue({locationStatus:locationStatusValue});
      form.setFieldsValue({brandId:props.transferId.brandId});
      form.setFieldsValue({createdUser:localStorage.getItem("username")});
      getExistingInvoiceWiseStock(props.transferId.packingStockTransferItemId);
    }
    console.log(props.transferId)
  }, [])

  const getExistingInvoiceWiseStock = (transferId) => {
    console.log(transferId)
    transferServce.getTransferedStockDetails({packingStockTransferId:transferId}).then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setTableData(res.data);
      } else {
        if (res.intlCode) {
          setTableData([]);
        } else {
          setTableData([]);
        }
      }
    }).catch(err => {
      setTableData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      locationId: value
    })
  }

  const getAllLocations = (grnUnitId, brandId) => {
    locationService.getRmRackPostionsForLocationMapping(new LocationMappingRmRackPostionReq(grnUnitId, brandId)).then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setLocations(res.data);
      } else {
        if (res.intlCode) {
          setLocations([]);
        } else {
          setLocations([]);
        }
      }
    }).catch(err => {
      setLocations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
    form.setFieldsValue({locationStatus:e.target.value});

  };
  const saveLocation = (btnval) => {
    form.validateFields().then(res => {
    setBtnDisable(true)
    const values = form.getFieldsValue();
    console.log(values);
    stockService.getSaveStockForLocationPending(values).then((res) => {
      // setDisable(false)
      if (res.status) {
        if(btnval == 'close'){
          props.closeForm();
          AlertMessages.getSuccessMessage('Location Mapped sucessfully')
        }
        if(btnval == 'continue'){
          AlertMessages.getSuccessMessage('Location Mapped sucessfully')
          form.setFieldsValue({locationId:null})
          form.setFieldsValue({locationStatus:null})
          setBtnDisable(false)
          getExistingInvoiceWiseStock(values.packingStockTransferItemId);
          getAllLocations(values.unitId, values.brandId)
          props.transferId.transferredQty = Number(props.transferId.transferredQty)-Number(form.getFieldValue('quantity'))
        }
      } else {
        setBtnDisable(false)
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      // setDisable(false)
      AlertMessages.getErrorMessage(err.message)
    });
  })

  }

  const validateQty = (selQty) => {
    console.log(selQty)
    if(selQty > props.transferId.transferredQty){
      AlertMessages.getErrorMessage('Qunatity should not greater than Received')
      form.setFieldsValue({quantity:null})
    }
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Item',
      dataIndex: 'itemName',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text, object, index) => {
        return <>{Number(text)}</>
      }

    },
  ] 
  const getPreviousTransactions = (val) => {
    console.log(val)
    stockService.getPreviousTransactionsByGrn(new locationIdRequest(val,JSON.parse(localStorage.getItem('unit_id')))).then(res => {
      if (res.status) {
        console.log(res.data)
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        sePrevTransactionsInfo(res.data);
      } else {
        if (res.intlCode) {
          sePrevTransactionsInfo([]);
        } else {
          sePrevTransactionsInfo([]);
        }
      }
    }).catch(err => {
      sePrevTransactionsInfo([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getLocationDetails = (value) => {
    setShowQrScan(true);
    const sellocationId = (locations.find(item => item.location == value)).rackPositionId

    form.setFieldsValue({locationId:sellocationId})
    // setModal('workstation')
    // getlocationId(value);
  }

  const columns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Location',
      dataIndex: 'location',
      // responsive: ['lg'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render:(text,data) => {
        return <>{Number(text)}</>
      }
      // responsive: ['lg'],
    },

  ]
  return (
    <div>
      <Card size="small" title={<span style={{ color: 'white' }} >Location Mapping</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/pending-grn-info-grid' ><span style={{ color: 'white' }} ></span></Link>} >
        <Form layout={"vertical"} autoComplete="off" form={form} >
        
          <Form.Item name="itemId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="itemSubCategoryId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="itemCategoryId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="saleOrderId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="unitId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="intransitQty" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="unitPrice" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="packingStockTransferItemId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="brandId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name="createdUser" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
              <Form.Item name="itemName" label="Item" rules={[{ required: true, message: 'Missed Item' }]}>
                <Input disabled={props.transferId}/>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="locationId" label="Location" rules={[{ required: true, message: 'Missed Location' }]}>
                <Select 
                onChange={getPreviousTransactions}
                showSearch allowClear placeholder="Select Location" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<QrcodeOutlined onClick={(e) => { getLocationDetails(e.target) }} style={{ fontSize: '28px', marginTop: '-11px', marginLeft: '-7px' }} />}
                >
                  {locations.map((locationData) => {
                    return <Option style = {{color:locationData.color}} value={locationData.rackPositionId}>{locationData.location}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: 'Missed Quantity' }]}>
                <InputNumber min={1} style={{ width: "100%" }} onChange={e => validateQty(e)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item name="locationStatus" rules={[{ required: true, message: 'Missed Location Status' }]}>
                Location Status : <Radio.Group name = 'locationStatus' onChange={onChange} value = {locationStatusValue}>
                <Radio value={'Occupied'}>Fully Occupied</Radio>
                <Radio value={'partially Occupied'}>Partially Occupied</Radio>
              </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item >
                <Button type='primary' onClick={e=>(saveLocation('continue'))} block disabled={btnDisable}>
                      Save & Continue...
                    </Button>
                </Form.Item>
            </Col>
            <Col span={4}>
          <Form.Item >
            <Button type='primary' danger onClick={e=>(saveLocation('close'))} block disabled={btnDisable}>
              Save & Close
            </Button>
          </Form.Item>
        </Col>
          </Row>
        </Form>
        {tableData.length > 0 ?<Table
          columns={columnsSkelton}
          dataSource={tableData}
          // pagination={{
          //   onChange(current) {
          //     setPage(current);
          //   }
          // }}
          // onChange={onChange}
          bordered
        />:<></>}
         <Modal
          className='qr-container'
          key={'modal' + Date.now()}
          width={'95%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={showQrSacn}
          onCancel={() => { setShowQrScan(false) }}
          title={<React.Fragment>
          </React.Fragment>}
        >
          {
           <QrScanner handleScan={handleQrScan} /> 
          }

        </Modal>
      </Card>
    </div>
  );
  
}

export default UpdateLocationForReceivedStock;
