import { CardHeaderBody } from "./card-header-body";
import { LineSeries } from "./line-series";


export class Highlinereq{
    headerTitle:string;
    categories:string[];
    format:string;
    subTitles:string;
    seriesDetails:LineSeries[];
    

/**
 * 
 * @param headerTitle 
 * @param format 
 * @param categories 
 * @param subTitles 
 * @param seriesDetails 
 */
    constructor(headerTitle:string,format:string,categories:string[],subTitles:string,seriesDetails:LineSeries[])
    {
        this.headerTitle = headerTitle,
        this.categories = categories,
        this.subTitles = subTitles,
        this.format = format,
        this.seriesDetails = seriesDetails
        
    }

}