import { CategoryEnum } from "../enums";

export class DimensionServiceModel{
    categoryId: string;
    category: CategoryEnum;
    plantId: number;
    length: number;
    breadth: number;
    height: number;


   
    constructor(categoryId:string,category:CategoryEnum,plantId: number,length: number,breadth: number,height: number){
        this.categoryId = categoryId;
        this.category = category;
        this.plantId = plantId;        
        this.length = length;
        this.breadth = breadth;
        this.height = height;
    }
}