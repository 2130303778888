import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { EmployeesStatusWiseCountDto } from "./employees-status-wise-count-dto";


export class EmployeesStatusWiseResponseModel extends GlobalResponseObject {
    data?: number;
    data1?: number;
    total?: number;
}

