import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button, Spin, Alert, notification } from 'antd';
import './be-heading.css';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { OperationTypeEnum, PlantsEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationCategoryEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { BeheadingService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeDropDown, LotInformationRequest, LotNoInfo, LotNumberRequest, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import moment from 'moment';
import { ItemsDetails, ItemsDropDownDto, SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
const { Option } = Select;
/* eslint-disable-next-line */
export interface BeHeadingProps { }

export function BeHeading(
  props: BeHeadingProps
) {

  let history = useHistory();
  const [beHeadingForm] = Form.useForm();
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(WorkStationTypeEnum.INTERNAL);
  const [lotNoInfo, setLotNoInfo] = useState<LotNoInfo>(undefined);
  const [boxWeight, setBoxWeight] = useState<number>(0);
  const [availableBoxes, setAvailableBoxes] = useState<number>(0);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>('info');
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const [soItemData, setSoItemData] = useState<ItemsDropDownDto[]>([]);
  const [disableSo, setDisableSo] = useState<boolean>(false);
  const [disableSoItem, setDisableSoItem] = useState<boolean>(false);

  const productionInvService = new ProdInvService();
  const prodInvService = new ProductionInventoryService();
  const workstationService = new WorkstationService();
  const beHeadingService = new BeheadingService();

  useEffect(() => {
    beHeadingForm.setFieldsValue({ workStationType: WorkStationTypeEnum.INTERNAL })
    beHeadingForm.setFieldsValue({ shift: ShiftsEnum.General })
    getWorkStations();
    getLotNumbers();
  }, [])


  const getSaleOrdersForLot = (val,rowdata) => {
    console.log(rowdata)
    console.log(val)
    beHeadingForm.setFieldsValue({ mainPlantId: rowdata.mainPlantId });
    const lotNumber = beHeadingForm.getFieldValue('lotNumber');
    if (lotNumber) {
      setSoData([]);
      beHeadingForm.setFieldsValue({saleOrderId:'',
                                    saleOrderItemId:'',
                                    inputGrade:0,
                                    boxes:0,
                                    availableQuantity:0,
                                    physicalQuantity:0
                                  });
    
      const plant = Number(localStorage.getItem('unit_id'))
      const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.BE_HEADING)
      beHeadingService.getSaleOrdersForLot(lotNoReq).then((res) => {
        if (res.status) {
          setSoData(res.data);
          if(res.data.length===1){
            res.data.map((Sos)=>{
              beHeadingForm.setFieldsValue({saleOrderId:Sos.saleOrderId});
            })
            setDisableSo(true);
            getSaleOrderItemsForLotAndSo();
          }else{
            setDisableSo(false);
          }
        } else {
          if (res.intlCode) {

            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setSoData([]);
        }
      })
        .catch((err) => {
          setSoData([]);
          AlertMessages.getErrorMessage(err.message);
        });
    }

  };


  const getSaleOrderItemsForLotAndSo = () => {
    const saleOrderId = beHeadingForm.getFieldValue('saleOrderId');
    const lotNumber = beHeadingForm.getFieldValue('lotNumber');
    if(saleOrderId&&lotNumber){
      setSoItemData([]);
      beHeadingForm.setFieldsValue({saleOrderItemId:''});
      const plant = Number(localStorage.getItem('unit_id'))

      const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.BE_HEADING, saleOrderId);
      beHeadingService.getSaleOrderItemsForLotAndSo(lotNoReq).then((res) => {
        if (res.status) {
          setSoItemData(res.data);
          if(res.data.length===1){
            res.data.map((Sos)=>{
              beHeadingForm.setFieldsValue({saleOrderItemId:Sos.itemId});
              setDisableSoItem(true);
            })
            getLotNumberInfo();
          }else{
            setDisableSoItem(false);
          }
        } else {
          if (res.intlCode) {
  
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setSoItemData([]);
        }
      })
        .catch((err) => {
          setSoItemData([]);
          AlertMessages.getErrorMessage(err.message);
        });
    }
  };

  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory = WorkStationCategoryEnum.find((res) => res.name === "DEHEADING").value;
    catReq.unitId = Number(localStorage.getItem('unit_id'));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        setWorkStations(res.data);
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }

  const getLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.BE_HEADING);

    prodInvService.getLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const getLotNumberInfo = () => {
    const lotNumber = beHeadingForm.getFieldValue('lotNumber');
    const saleOrderId = beHeadingForm.getFieldValue('saleOrderId');
    const saleOrderItemId = beHeadingForm.getFieldValue('saleOrderItemId');
    if (lotNumber&&saleOrderId&&saleOrderItemId) {
      const plant = Number(localStorage.getItem('unit_id'))
      const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.BE_HEADING, saleOrderId, saleOrderItemId);

      beHeadingService.getLotNumberInfo(lotNoReq).then((res) => {
        if (res.status) {
          setLotNoInfo(res.data)
          let boxes = res.data.boxes?res.data.boxes:1;
          let boxWeight = res.data.boxWeight;
          setAvailableBoxes(boxes);
          setBoxWeight(res.data.boxWeight);
          beHeadingForm.setFieldsValue({
            prodInvId: res.data.productionInventoryId,
            saleOrderId: res.data.saleOrderId,
            saleOrderItemId: res.data.saleOrderItemId,
            saleOrder: res.data.saleOrderNo,
            inputGrade: res.data.count,
            boxes: res.data.boxes?res.data.boxes:1,
            availableQuantity: res.data.availableQuantity,
            physicalQuantity: res.data.availableQuantity
          })
        } else {
          setLotNoInfo(undefined);
        }
      }).catch((err) => {
        setLotNoInfo(undefined);
        AlertMessages.getErrorMessage(err.message);
      });
    }
  }


  const onFinish = () => {
    beHeadingForm.setFieldsValue({ workStationType: WorkStationTypeEnum.INTERNAL });
    const type = beHeadingForm.getFieldValue('workStationType');
    if (type == WorkStationTypeEnum.INTERNAL) {
      beHeadingForm.setFieldsValue({ subPlantId: 0 });
    } else {
      beHeadingForm.setFieldsValue({ workstation: 0 });
    }

    beHeadingForm.validateFields().then(formData => {
      if (formData.boxes && formData.physicalQuantity) {
        setDisableSubmit(true)
        const formInfo = beHeadingForm.getFieldsValue();
        formInfo.createdUser = localStorage.getItem('createdUser')
        formInfo.plantId = Number(localStorage.getItem('unit_id'))
        beHeadingService.assignBeHeadingOperation(formInfo).then((res) => {
          if (res.status) {
            setAlertType('success');
            if (type == WorkStationTypeEnum.INTERNAL) {
              AlertMessages.getSuccessMessage('Material assigned for deheading');
              history.push('/be-heading-dashboard')
            } else {
              history.push('/be-heading-sub-plant-history')
            }
          } else {
            // setAlertType('Error');
            // openNotificationWithIcon('error');
            AlertMessages.getErrorMessage(res.message);
            setDisableSubmit(false)
          }
        }).catch((err) => {
          setAlertType('Error');
          setDisableSubmit(false)
          AlertMessages.getErrorMessage(err.message);
        });
      } else {
        AlertMessages.getErrorMessage('Please Enter Valid Details');
      }

    })

  }

  const openNotificationWithIcon = (error) => {
    notification[error]({
      message: 'Failed To Update',
      description:
        'There is an Database issue, please clear it. before assigning job',
    });
  };

  const checkWorkStationType = () => {
    const type = beHeadingForm.getFieldValue('workStationType');
    if (type == WorkStationTypeEnum.EXTERNAL) {
      setWorkStationType(WorkStationTypeEnum.EXTERNAL)
    } else {
      setWorkStationType(WorkStationTypeEnum.INTERNAL)
    }
  }


  const calculateIssuingQty = () => {
    const noOfBoxes = beHeadingForm.getFieldValue('boxes');
    const availableQuantity = beHeadingForm.getFieldValue('availableQuantity');
    // const boxWeight = availableQuantity/noOfBoxes;
    const physicalQuantity = boxWeight * noOfBoxes;
    console.log(physicalQuantity);
    console.log(boxWeight);
    console.log(noOfBoxes);
    console.log(availableQuantity);
    if (Number(Number(physicalQuantity).toFixed(3)) <= Number(Number(availableQuantity).toFixed(3))) {
      beHeadingForm.setFieldsValue({ physicalQuantity: Math.round(physicalQuantity) });
    } else {
      beHeadingForm.setFieldsValue({ physicalQuantity: 0, boxes: 0 });
    }
  }


  return (
    <Card title={<span style={{ color: 'white' }}>Assign Deheading</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/be-heading-dashboard' ><Button className='panel_button' >Dashboard </Button></Link>}>
      <Form layout={'vertical'} form={beHeadingForm} name="control-hooks" style={{ display: (disableSubmit) ? 'none' : 'block' }}>

        <Row gutter={24}>
          <Form.Item name="productionInventoryId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="mainPlantId" >
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 7 }}>

            <Form.Item name="lotNumber" label="Lot Code" rules={[{ required: true, message: 'Missing Lot No' }]}>
              <Select
                placeholder="Select Lot Code"
                allowClear
                onChange={(val,rowdata) => getSaleOrdersForLot(val,rowdata)}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.lotNumber} mainPlantId = {dropData.mainPlantId} value={dropData.lotNumber}>{dropData.lotandplantlot
                  }</Option>
                })}
              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="saleOrderId" label="SO Number"
            //  rules={[{ required: true, message: 'Missing Sale Order No' }]}
             >
              {/* <Input disabled /> */}

              <Select
                placeholder="Select SO Number"
                allowClear
                onChange={getSaleOrderItemsForLotAndSo}
                disabled={disableSo}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {soData.map((dropData) => {
                  return (
                    <Option
                      key={dropData.saleOrderId}
                      value={dropData.saleOrderId}
                    >
                      {`${dropData.saleOrderNumber} -${dropData.poNumber}`}
                    </Option>
                  );
                })}
              </Select>

            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrderItemId" label="SO Item">
              <Select
                placeholder="Select SO Item"
                allowClear
                onChange={getLotNumberInfo}
                disabled={disableSoItem}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {soItemData.map((dropData) => {
                  return (
                    <Option
                      key={dropData.itemId}
                      value={dropData.itemId}
                    >
                      {dropData.itemName}
                    </Option>
                  );
                })}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="inputGrade" label="Count" rules={[{ required: true, message: 'Missing Count' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="availableQuantity" label={"Quantity Available"} rules={[{ required: true, message: 'Missing Quantity Available' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>



        </Row>

        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <label>No. Of. Boxes: <span style={{ color: 'red' }}>Avl. Boxes :{availableBoxes}</span></label>
            <Form.Item name="boxes" label={false} rules={[{ required: true, message: 'Missing No. Of. Boxes' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={calculateIssuingQty} />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="physicalQuantity" label="Issuing Quantity (KGS)" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{display:'none'}}>

            <Form.Item name="workStationType" label="Workstation Type" rules={[{ required: true, message: 'Missing Workstation Type' }]}>
              <Select
                placeholder="Select Workstation Type"
                allowClear
                onChange={checkWorkStationType}
              >
                <Option key={0} value={null}>Select Workstation Type</Option>
                <Option key={1} selected value={WorkStationTypeEnum.INTERNAL}>{WorkStationTypeEnum.INTERNAL}</Option>
                <Option key={2} value={WorkStationTypeEnum.EXTERNAL}>{WorkStationTypeEnum.EXTERNAL}</Option>
              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.INTERNAL) ? 'block' : 'none' }}>

            <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]} >
              <Select
                placeholder="Select Workstation"
                allowClear
              >
                {workStations.map(dropData => {
                  return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.EXTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="subPlantId" label="Plant" rules={[{ required: true, message: 'Missing Plant' }]} >
              <Select
                placeholder="Select Plant"
                allowClear
              >
                <Option key={0} value={null}>Select Plant</Option>
                <Option key={1} selected value={SubPlantCodesEnum.KDM}>{SubPlantCodesEnum.KDM}</Option>
                <Option key={2} value={SubPlantCodesEnum.BVRM}>{SubPlantCodesEnum.BVRM}</Option>
                <Option key={3} value={SubPlantCodesEnum.MTM}>{SubPlantCodesEnum.MTM}</Option>
              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} >
            <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Missing Shift' }]} >
              <Select
                placeholder="Select Shift"
                allowClear
              >
                <Option key={0} value={null}>Select Shift</Option>
                <Option key={1} selected value={ShiftsEnum.General}>{ShiftsEnum.General}</Option>
                <Option key={2} value={ShiftsEnum.Shift_A}>{ShiftsEnum.Shift_A}</Option>
                <Option key={3} value={ShiftsEnum.Shift_B}>{ShiftsEnum.Shift_B}</Option>
                <Option key={4} value={ShiftsEnum.Shift_C}>{ShiftsEnum.Shift_C}</Option>
              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, }} lg={{ span: 5, }} xl={{ span: 5, }}>
            <Form.Item name="assignedTime" label="Assigned Time" rules={[{ required: true, message: 'Missing Assigned Time' }]} initialValue={moment()}>
              <DatePicker showTime style={{ width: '100%' }} />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          
          <Col span={5} style={{ textAlign: 'right', paddingTop: '26px' }}>
            <Form.Item >
              <Button type='primary' disabled={disableSubmit} block onClick={onFinish}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
      <Spin size="large" tip="Loading..." style={{ display: (disableSubmit) ? 'block' : 'none' }}>
        <Alert
          message="Please Wait"
          description="Job assigning to workstation."
          type={alertType}
          style={{ display: (disableSubmit) ? 'block' : 'none' }}
        />
      </Spin>
    </Card>
  );
}

export default BeHeading;
