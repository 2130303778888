import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import connection from "./connection";
import {AssetByCode} from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-code.dto';
import {AssetByDate} from 'libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-date.dto';
import {AssetByAssetCode} from 'libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto';
import { AssetsId } from "@gtpl/shared-models/asset-management";
import { AssetServiceReq, UnitRequest } from "@gtpl/shared-models/common-models";
import { AssetByStatus } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";

export class AssetAssignmentService{
    URL = connection.DEPLOY_URL + '/assetAssignment';

    async createCheckIn(req: AssetAssignmentDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createCheckIn',req)
        .then(res => {
            return res.data;
        })
    }

    async updateCheckOut(req: AssetAssignmentDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateCheckOut',req)
        .then(res => {
            return res.data;
        })
    }

    async getAll(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAll')
        .then(res => {
            return res.data;
        })
    }

    async getAllTrans(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllTrans')
        .then(res => {
            return res.data;
        })
    }

    async getAssetByCode(req?: AssetByAssetCode): Promise<CommonResponse> {
        console.log(req,'-------req')
        return await axios.post(this.URL + '/getAssetByCode',req)
        .then(res => {
            return res.data;
        })
    }

    async getAllCheckIn(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllCheckIn')
        .then(res => {
            return res.data;
        })
    }

    async getByAssetId(req: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getByAssetId',req)
        .then(res => {
            return res.data;
        })
    }

    async getTransById(req: AssetByAssetCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransById',req)
        .then(res => {
            return res.data;
        })
    }

    async getByDate(req: AssetByDate): Promise<CommonResponse> {
        console.log(req,"---------")
        return await axios.post(this. URL + '/getByDate',req) 
        .then(res => {
            return res.data;
        })
    }
    async getReadyForAssetService(req?:AssetServiceReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getReadyForAssetService',req)
            .then(res => {
                return res.data;
            })
    }
    async getServiceOverdue(req?:AssetServiceReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getServiceOverdue',req)
            .then(res => {
                return res.data;
            })
    }
    async getAssetsCodeForAssetService(req?:AssetServiceReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetsCodeForAssetService',req)
            .then(res => {
                return res.data;
            })
    }
    async getDataForServiceCalender(req:UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDataForServiceCalender',req)
        .then(res => {
            return res.data;
        })
    }

    async getPlantsForAssetService(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPlantsForAssetService')
            .then(res => {
                return res.data;
            })
    }
    async getWarrantyAndInsuranceDate(req: UnitRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getWarrantyAndInsuranceDate')
        .then(res => {
            return res.data;
        })
    }

    async getLocationsForAssetService(req?:AssetServiceReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationsForAssetService',req)
            .then(res => {
                return res.data;
            })
    }

    async getAssignmentTransAssetCodesDropDown(req?: AssetByAssetCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssignmentTransAssetCodesDropDown',req)
        .then(res => {
            return res.data;
        })
    }

    async getInactiveReport(req?: AssetByAssetCode): Promise<CommonResponse> {
        console.log(req,'-------req')
        return await axios.post(this.URL + '/getInactiveReport',req)
        .then(res => {
            return res.data;
        })
    }

    async getInactiveReportDropDown(req?: AssetByAssetCode): Promise<CommonResponse> {
        console.log(req,'-------req')
        return await axios.post(this.URL + '/getInactiveReportDropDown',req)
        .then(res => {
            return res.data;
        })
    }

    async getMissedAssetServices(req?:AssetServiceReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMissedAssetServices',req)
            .then(res => {
                return res.data;
            })
    }

    async getAssetsAccountDashBoardsData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetsAccountDashBoardsData')
            .then(res => {
                return res.data;
            })
    }

    async getVendorAbstractData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getVendorAbstractData')
            .then(res => {
                return res.data;
            })
    }
    async getRaciMatrixData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRaciMatrixData')
            .then(res => {
                return res.data;
            })
    }


}