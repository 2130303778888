export class BrandsRequest{
    brandId : number;
    isActive ?: boolean;
    
    constructor(brandId : number,isActive? : boolean)
    {
        this.brandId = brandId;
        this.isActive = isActive;
        
    }
}
