import { AssetsCode } from "@gtpl/shared-models/common-models";

export class AssetsDto {
    assetsClassificationId?: number;
    assetsClassificationName: string;
    assetsClassificationCode: string;
    createdUser: string;
    updatedUser: string;
    isActive: boolean;
    versionFlag?: number;
   assetCodeRequired?: AssetsCode


    /**
     * 
     * @param assetsClassificationId
     * @param assetsClassificationName
     * @param assetsClassificationCode
     * @param assetCodeRequired
     * @param createdUser
     * @param isActive
     * @param versionFlag
     */

    constructor(assetsClassificationName: string, assetsClassificationCode: string, createdUser: string, isActive?: boolean, updatedUser?: string, assetsClassificationId?: number, versionFlag?: number, assetCodeRequired?: AssetsCode) {
        this.assetsClassificationId = assetsClassificationId;
        this.assetsClassificationName = assetsClassificationName;
        this.assetsClassificationCode = assetsClassificationCode;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.assetCodeRequired = assetCodeRequired
    }
}