import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { VehicleDto } from './vehicle-dto';

export class VehicleResponseModel extends GlobalResponseObject{
    data?: VehicleDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: VehicleDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
