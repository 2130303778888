import {DownTimeReasonDto } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class AllDownTimeReasonResponseModel extends GlobalResponseObject {

    data?: DownTimeReasonDto[];
     /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

      constructor(status: boolean, intlCode: number, internalMessage: string, data?: DownTimeReasonDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}