export class FoodTypesDropDownData{
  foodTypeId:number;
  foodType:string;
    
    /**
     * 
     * @param foodTypeId 
     * @param foodType 
     */
    constructor(foodTypeId:number,foodType:string){
      this.foodTypeId = foodTypeId;
      this.foodType = foodType;
    }
}