import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Table, InputNumber, DatePicker, Typography, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Form, Select, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { RmBomDetails } from '@gtpl/shared-models/sale-management';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import { SoInfoViewPages } from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'
const { RangePicker } = DatePicker;


import './bom-tracker-grid.css';
import Link from 'antd/lib/typography/Link';
import { Redirect } from 'react-router-dom';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters'

export interface IstartEndIndex {
  start: number;
  end: number;
}
/* eslint-disable-next-line */
export interface BomTrackerGridProps { }

export function BomTrackerGrid(
  props: BomTrackerGridProps
) {
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = React.useState(1);
  const [searchedColumn, setSearchedColumn] = useState('');

  const [modalVisible, setModalVisible] = useState(false);

  const [saleOrderId, setSaleOrderIdState] = useState<number>(null);
  const saleService = new SaleOrderService();
  const [bomTrackerData, setBomTrackerData] = useState<RmBomDetails[]>([]);
  const [gridData, setGridData] = useState<RmBomDetails[]>([]);
  const [saleId, setSaleId] = useState(0);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService()
  const [filtersData, setFiltersData] = useState<RmBomDetails[]>([]);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Text } = Typography;

  /**
 * used for column filter
 * @param dataIndex column data index
 */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  useEffect(() => {
    getAllsaleordersBomdata();
    getAllPlants();

  }, []);

  const setSaleOrderId = (rowdata) => {
    setSaleOrderIdState(rowdata.saleOrderId)

  }


  const getAllsaleordersBomdata = () => {
    saleService.getAllsaleordersBomdata().then(res => {
      if (res.status) {
        setBomTrackerData(res.data);
        setFiltersData(res.data);
        setGridData(res.data)
        console.log(res.data)
      } else {
        if (res.intlCode) {
          setBomTrackerData([]);
          setFiltersData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setBomTrackerData([]);
      setFiltersData([]);
    })
  }

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log(saleOrderId);
    setModalVisible(true);
  }

  const handleCancel = () => {
    getAllsaleordersBomdata();
    getAllPlants();
    setModalVisible(false);
  };


  const getAllPlants = () => {

    unitsService.getAllPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);

      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const searchData = () => {

    const maxxGrade = form.getFieldValue(['grade', 'maxGrade']);
    const minnGrade = form.getFieldValue(['grade', 'minGrade']);
    if (maxxGrade <= minnGrade) {
      AlertMessages.getErrorMessage('maximum count should be greater than minimum grade');
    }

    const selectedEtdFromDate = form.getFieldValue('etdDateRange') ? (moment(form.getFieldValue('etdDateRange')[0]).format("YYYY-MM-DD")) : null;
    const selectedEtdToDate = form.getFieldValue('etdDateRange') ? (moment(form.getFieldValue('etdDateRange')[1]).format("YYYY-MM-DD")) : null;
    console.log(form.getFieldValue('plantData'));
    const plantData = form.getFieldValue('plantData');
    console.log(selectedEtdToDate, selectedEtdFromDate, plantData);
    let selectedData = filtersData;
    if (selectedEtdFromDate && selectedEtdToDate) {
      selectedData = filtersData.filter(item => moment(item.etdDate).format("YYYY-MM-DD") > selectedEtdFromDate && moment(item.etdDate).format("YYYY-MM-DD") < selectedEtdToDate)
    }
    if (maxxGrade && minnGrade) {
      selectedData = filtersData.filter(item => item.count >= minnGrade && item.count <= maxxGrade)
      console.log(maxxGrade)
      console.log(minnGrade)

    } if (plantData) {
      selectedData = selectedData.filter(item => item.unitName === plantData)
    }
    setFiltersData(selectedData);
    setBomTrackerData(selectedData);

    setGridData(selectedData);

  }

  const onReset = () => {
    form.resetFields();
    setSearchText('');
    // setBomTrackerData(filtersData);
    // setBomTrackerData(gridData);
    getAllsaleordersBomdata();
  };

  // const getColumns = () => {
    /*Map to construct data based on key */
    // const rowSpanTypeMap = new Map<string, IstartEndIndex>();
    // const rowSpanTypeMap1 = new Map<string, IstartEndIndex>();
    // // const moduleData: RmBomDetails[] = JSON.parse(JSON.stringify(bomTrackerData));
    //         if(bomTrackerData){
    //           let indexVal=0;
    //           /* Map to get module Types to merge the row span to Zero for repeated keys */
    //           bomTrackerData.map((moduleObj: RmBomDetails, index) => {
    //             if(index%10==0){
    //               indexVal=0
    //             }
    //             const moduleKey = moduleObj.saleOrderNumber;
    //             rowSpanTypeMap.set(moduleKey, rowSpanTypeMap.has(moduleKey) ? { start: rowSpanTypeMap.get(moduleKey).start, end: rowSpanTypeMap.get(moduleKey).end + 1 } : { start: indexVal, end: 1 
    //             });
    //             indexVal=indexVal+1
    //           });
    //         }

    //         if(bomTrackerData){
    //           let indexVal=0;
    //           let pageVal=1;
    //           /* Map to get module Types to merge the row span to Zero for repeated keys */
    //           bomTrackerData.map((moduleObj: RmBomDetails, index) => {
    //             if(index%10==0){
    //               indexVal=0
    //               pageVal+=1
    //             }
    //             const moduleKey = moduleObj.endCustomerName;
    //             rowSpanTypeMap.set(moduleKey, rowSpanTypeMap.has(moduleKey) ? { start: rowSpanTypeMap.get(moduleKey).start, end: rowSpanTypeMap.get(moduleKey).end + 1 } : { start: indexVal, end: 1 
    //             });
    //             indexVal=indexVal+1
    //           });
    //         }

    const columns: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        align: 'left',
        responsive: ['md'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },

      // {
      //   title: 'SO Number',
      //   dataIndex: 'saleOrderNumber',
      //   sorter: (a, b) => a.saleOrderNumber.localeCompare(b.saleOrderNumber),
      //   sortDirections: ['descend', 'ascend'],
      //   responsive: ['md'],
      //   ...getColumnSearchProps('saleOrderNumber'),
      // render:(value, record, index)=>{
      // if(record.span){
      // return record.saleOrderNumber &&
      // <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} target="_blank" >{record.saleOrderNumber}</Link>
      // }else{
      //   return ''
      // }
      // }
      // },
      {
        title: 'Customer PO',
        dataIndex: 'poNumber',
        sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('poNumber'),
        render: (value, record, index) => {
          // if(record.span){
          return record.saleOrderNumber &&
            <Link onClick={e => goToSaleDetailView(record.saleOrderId)} target="_blank" >{record.poNumber}</Link>
          // }else{
          //   return ''
          // }
        }

      },
      {
        title: 'Prod Unit',
        dataIndex: 'unitName',
        sorter: (a, b) => a.unitName.localeCompare(b.unitName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('unitName'),

      },
      {
        title: 'End Customer',
        dataIndex: 'endCustomerName',
        align: 'left',
        responsive: ['md'],
        sorter: (a, b) => a.endCustomerName.localeCompare(b.endCustomerName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('endCustomerName'),
        //   render:(value, record, index)=>{
        //     const obj: any = {
        //       children: (<div >{value}</div>),
        //       props: {},
        //     };
        //     const mdlPrKey = record.endCustomerName;
        //     if (index === rowSpanTypeMap.get(mdlPrKey).start) {
        //       obj.props.rowSpan = rowSpanTypeMap.get(mdlPrKey).end;
        //     } else {
        //       if(page>1&&index==0){
        //         obj.props.rowSpan = 1;
        //       }else{
        //         obj.props.rowSpan = 0;
        //       }
        //     }
        //     return obj;
        // }

      },
      {
        title: 'Product',
        dataIndex: 'productName', 
        //...getColumnSearchProps('productName'),
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        sortDirections: ['descend', 'ascend'],

        filters: [
          {
            text: 'Vannamei',
            value:'Vannamei',
          },
          {
            text: 'Black Tiger',
            value:'Black Tiger',
          },
          {
            text:'Sea Tiger',
            value:'Sea Tiger',
          }
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.productName === value;
        },
        
      },

      {
        title: 'Grade',
        dataIndex: 'minGrade',
        sorter: (a, b) => a.minGrade - b.minGrade,
        sortDirections: ['descend', 'ascend'],
        align: 'left',
        responsive: ['md'],
        width: '100px',
        render: (value, record, index) => {
          return `${record.minGrade} - ${record.maxGrade}`
        },
      },
      {
        title: <div style={{ textAlign: 'center' }}>Cases</div>,
        dataIndex: 'cases',
        align: 'right',
        width: '100px',
        responsive: ['md'],


      },
      {
        title: 'Net Case Weight(In kgs)',
        dataIndex: 'caseWeight',
        align: 'right',
        width: '100px',
        render: (text, record) => {
          return <span>{`${Number(record.caseWeight)}`}</span>
        }



      },
      {
        title: 'Count',
        dataIndex: 'count',
        align: 'right',
        sorter: (a, b) => a.count - b.count,
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('count'),

      },


      {
        title: 'Required Quantity(In Tons)',
        dataIndex: 'quantity',
        align: 'right',
        width: '100px',
        render: (text, record) => { return ` ${Number((record.quantity / 1000).toFixed(2))}` },




      },
      {
        title: 'ETD',
        dataIndex: 'etdDate',
        ...getColumnSearchProps('etdDate'),
        // sorter: (a, b) => moment(a.etdDate).unix() - moment(b.etdDate).unix(),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => { return record.etdDate != undefined ? moment(record.etdDate).format('YYYY-MM-DD') : "--" }
      },

      {
        title: 'Aging',
        key: 'aging',
        width: 80,
        align: 'left',

        sorter: (a, b) => (Math.floor((new Date(moment(a.etdDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.etdDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
        sortDirections: ['descend', 'ascend'],
        // ...getColumnSearchProps('aging'),
        render(text, record) {
          const obj: any = {
            children: (record.status != "CLOSED" ? <div style={{ textAlign: 'left' }}>{Math.floor((new Date(moment(record.etdDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div> : <div>{"--"}</div>),
            props: {
              style: {
                background: record.status != "CLOSED" ? Math.floor((new Date(moment(record.etdDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c' : "-",
                color: Math.floor((new Date(moment(record.etdDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "Black" : 'white'
              }
            },
          };
          return obj;
        }
      },

    ];

  //   return columns;
  // }

  const setInitialData = () => {
    setBomTrackerData(gridData);
  }



  return (
    <>

      <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>Bom Tracker</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }}>
        <br /> <br />
        <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
          <Row gutter={[32, 32]} >
            <Col span={6}>
              <Form.Item name="etdDateRange" label="ETD" rules={[{
                required: false,
                message: "ETD From Date"
              },
              ]}>
                <RangePicker />
              </Form.Item>
            </Col>




            <Col span={5} >
              <Form.Item label="Count" required>
                <Input.Group compact>
                  <Form.Item
                    name={["grade", 'minGrade']}
                    noStyle
                    rules={[{ required: false, }]}
                  >
                    <InputNumber style={{ width: "50%" }} placeholder="Min" min={0} />

                  </Form.Item>


                  <Form.Item
                    name={['grade', 'maxGrade']}
                    noStyle
                    rules={[{ required: false, message: 'Max grade should greater than Min grade' }]}
                  >
                    <InputNumber style={{ width: "50%" }} placeholder="Max" min={0} />
                  </Form.Item>
                </Input.Group>

              </Form.Item>





            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
              <Form.Item
                name="plantData"
                label="Filter by Unit:"
                rules={[
                  {
                    required: false, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Unit"
                  // onChange={handlePlant}
                  // allowClear = {setInitialData}
                  style={{ width: '100%' }}
                >
                  {plantData.filter(plant => (bomTrackerData.find(item => item.unitId == plant.plantId))).map(dropData => {
                    return <Option value={dropData.plantName}>{dropData.plantName}</Option>
                  })}
                </Select>
                {/* <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item style={{ display: "none" }} name="plantId" initialValue={Number(localStorage.getItem('unit_id'))}>
                    <Input hidden />
                  </Form.Item> */}





              </Form.Item>
            </Col>
            <Col style={{ marginTop: 30 }} span={5}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                // size="small"
                block onClick={() => searchData()}
                style={{ marginRight: 2, width: 100 }}
              >
                Search
              </Button>
              <Button type="primary" htmlType="submit" onClick={onReset}>
                Reset
              </Button>
            </Col>


          </Row>
        </Form>
        <Table
          rowKey={record => record.bomLogId}
          columns={columns}
          dataSource={bomTrackerData}
          pagination={{

            onChange(current) {
              setPage(current);
            }
          }}
          scroll={{ x: true }}
          size="small"
          bordered
          summary={(pageData) => {
            let totalQuantity = 0;

            pageData.forEach(({ quantity }) => {
              totalQuantity += Number(quantity);

            });

            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={1} colSpan={9} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}><Text style={{ textAlign: 'end' }}>{(totalQuantity / 1000).toFixed(2)}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} ></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} ></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }
          }

        />
      </Card>

      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={modalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen='rm-bom-tracker' />
      </Modal>

    </>
  );
}

export default BomTrackerGrid;
