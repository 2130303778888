import { GlobalStatus } from "@gtpl/shared-models/common-models";

export class SalePoDetailsInfo {
    SoPoId:number;
    saleOrderId:number;
    // soakingTime:string;
    // // hLFinalProduct:string;
    // glazzing:number;
    additionalNote:string;
    // styleWeighmentId:string;
    // sockingStyleId:number;
    certificationId:number;
    isBrandApproved:GlobalStatus;
    brandDesignType:string;


    /**
    * @param SoPoId
    * @param saleOrderId
    * @param additionalNote
    * @param certificationId
    * @param isBrandApproved
    * @param brandDesignType
     */
    constructor(SoPoId:number,saleOrderId:number,additionalNote:string,certificationId:number,createdUser: string,brandDesignType:string,isBrandApproved:GlobalStatus) {
        this.SoPoId = SoPoId;
        this.saleOrderId = saleOrderId;
        this.additionalNote = additionalNote;
        this.certificationId = certificationId;
        this.brandDesignType = brandDesignType;
        this.isBrandApproved = isBrandApproved;
    
    }
}


