import React, { useState, useEffect, useRef } from 'react';
import { Card, Input, Button, Row, Col, Drawer, Form, DatePicker, Select, Empty, Spin, } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SearchOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import { AllGrnReportDto, GrnDetailsDto, GrnNumberDto, GrnRtRequest, PoDropDownDto } from '@gtpl/shared-models/procurement-management';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { GrnService, PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { Table } from "ant-table-extensions";
import './grn-report-grid.css';
import moment from 'moment';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { ItemSubCategoryService, UnitcodeService, VendorsService,ItemsService } from '@gtpl/shared-services/masters'
import { ItemSubCategoryDropDownDto, PlantsDropDown, SubCatReqForCategoryId, VendorDropDownDto,ItemsDropDownDto, VendorRequest } from '@gtpl/shared-models/masters';
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable';

/* eslint-disable-next-line */
export interface GrnReportGridProps { }

export function GrnReportGrid(props: GrnReportGridProps) {
  
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [disable,setDisable]=useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [grnReport, setGrnReport] = useState<AllGrnReportDto[]>([]);
  const [grnData, setGrnData] = useState<GrnNumberDto[]>([]);
  const [totalGRNCount, settotalGRNCount] = useState<number>(0)
  const [poNumbers, setPoNumber] = useState<PoDropDownDto[]>([]);
  const vendorsService = new VendorsService;
  const [vendorName, setVenderName] = useState<VendorDropDownDto[]>([]);
  const [saleOrderData, setSaleOrderData] = useState<SaleOrderDropDownDto[]>([])
  const saleOrderService = new SaleOrderService();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [soItemData, setSoItemData] = useState<SaleOrderDropDownDto[]>([]);
  const [grnNumberDropDowndata, setGrnNumberDropDowndata] = useState<GrnNumberDto[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const subCategoryService = new ItemSubCategoryService();
  const [subCategoryData, setSubCategoryData] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [selectedunit, setUnit] = useState<number>(0)
  const [loading, setLoading] = useState(false);
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [soDrop, setSoDrop] = useState<AllGrnReportDto[]>([]);
  const [poDrop, setPoDrop] = useState<AllGrnReportDto[]>([]);
  const [itemSubDrop, setItemSubDrop] = useState<AllGrnReportDto[]>([]);
  const [itemDrop, setItemDrop] = useState<AllGrnReportDto[]>([]);
  const [vendorDrop, setVendorDrop] = useState<AllGrnReportDto[]>([]);



  const itemService = new ItemsService();
  const service = new GrnService;
  const services = new SaleOrderService;
  const unitsService = new UnitcodeService();
  const poService = new PurchaseOrderService();
  const grnService = new GrnService();


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys);
    console.log(confirm);
    console.log(dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
      
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex)
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        {  console.log(selectedKeys)}
        <Button
          type="primary"
          onClick={() => {
            handleSearch(selectedKeys, confirm, dataIndex)
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
      ) : null,
  });
  const { Option } = Select;
  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 8,
    },
  };
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const onReset = () => {
    form.resetFields();
    EstimatedETDDate(undefined);
    setSelectedEstimatedFromDate(undefined);
    setSelectedEstimatedToDate(undefined);
    setGrnReport([]);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }

  }
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */

  useEffect(() => {
    getAllActiveGrnNumbers();
    getSoDropForGrnReport();
    getPoDropForGrnReport();
    getVendorDropForGrnReport();
    getAllPlants();
    getItemSubDropForGrnReport();
    getItemDropForGrnReport();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ delUnitId: Number(localStorage.getItem('unit_id')) })
    }
    form.setFieldsValue({
      fromDate: [(moment(moment().format("YYYY-MM-DD")).subtract(1,'months')),moment(moment().format("YYYY-MM-DD"))] 
    })
    setSelectedEstimatedFromDate((moment(moment().format("YYYY-MM-DD")).subtract(1,'months')))
      setSelectedEstimatedToDate(moment(moment().format("YYYY-MM-DD")))
  }, [])

  const getAllActiveGrnNumbers = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')));
    grnService.getAllActiveGrnNumbers(req).then((res) => {
      if (res.status) {
        setGrnNumberDropDowndata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const EstimatedETDDate = (value) => {
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));
      setSelectedEstimatedFromDate(fromDate)
      setSelectedEstimatedToDate(toDate)
    }
  }
  const EstimatedCreatedDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedCreatedFromDate(fromDate)
    setSelectedCreatedToDate(toDate)
  }

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }

  const getItems = () => {
    setItems([]);
    itemService.getItemsDropDownData().then((res) => {
      if (res.status) {
        setItems(res.data);
      } else {
        setItems([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  const getAllGrnReport = (onReset?: boolean) => {
    setDisable(true);
    setLoading(true);
    const req = new GrnRtRequest()
  
    let saleOrderId = form.getFieldValue('saleOrderId');
    let grnNumber = form.getFieldValue('grnNumber');
    let poNumber = form.getFieldValue('poNumber');
    let vendorName = form.getFieldValue('vendorName');
    let invoiceNumber = form.getFieldValue('invoiceNumber');
    let unitId = form.getFieldValue('unitId');
    let itemSubCategoryId = form.getFieldValue('itemSubCategoryId')
    let poType = form.getFieldValue('poType')
    let itemNameId = form.getFieldValue('item')
    let excessPercent = form.getFieldValue('excessPercent')
    let selectedData = filtersData;
    req.fromDate = onReset ? undefined : selectedEstimatedFromDate;
    req.toDate = onReset ? undefined : selectedEstimatedToDate;
    req.saleOrderId = saleOrderId;
    req.grnNumber = grnNumber;
    req.poNumber = poNumber;
    req.vendorName = vendorName;
    req.invoiceNumber = invoiceNumber;
    req.itemSubCategoryId = itemSubCategoryId;
    req.poType = poType;
    req.createdDate = undefined ;
    req.selectedCreatedFromDate = selectedCreatedFromDate;
    req.selectedCreatedToDate = selectedCreatedToDate;
    req.itemNameId = itemNameId;
    req.excessPercent = excessPercent
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    service.getAllGrnReport(req).then(res => {
      setDisable(false)
      if (res.status) {
        setGrnReport(res.data);
        setFiltersData(res.data);
        setLoading(false);
        AlertMessages.getSuccessMessage(res.internalMessage);
        var tempResult = {}

          for(let { grnNumber } of res.data)
            tempResult[grnNumber] = { 
              grnNumber, 
                count: tempResult[grnNumber] ? tempResult[grnNumber].count + 1 : 1
            }      

          let result = Object.values(tempResult)
          settotalGRNCount(result.length)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setGrnReport([]);
         // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setLoading(false);

        }
      }
    }).catch(err => {
      setGrnReport([]);
      AlertMessages.getErrorMessage(err.message);
    })
    setGrnReport(selectedData);
  }

  const handleUnit = (value) => {
    setUnit(value)
  }

  const getAllVendors = () => {
    vendorsService.getVendorsDropDownData().then(res => {
      if (res.status) {
        setVenderName(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);

    })
  }


  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }


  const getSoDropForGrnReport = () => {
    service.getSoDropForGrnReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setSoDrop(res.data);
      } else {
        if (res.intlCode) {
          setSoDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSoDrop([]);
    })
  }
  const getPoDropForGrnReport = () => {
    service.getPoDropForGrnReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setPoDrop(res.data);
      } else {
        if (res.intlCode) {
          setPoDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPoDrop([]);
    })
  }
  const getItemSubDropForGrnReport = () => {
    service.getItemSubDropForGrnReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setItemSubDrop(res.data);
      } else {
        if (res.intlCode) {
          setItemSubDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemSubDrop([]);
    })
  }
  const getItemDropForGrnReport = () => {
    service.getItemDropForGrnReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setItemDrop(res.data);
      } else {
        if (res.intlCode) {
          setItemDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setItemDrop([]);
    })
  }
  const getVendorDropForGrnReport = () => {
    service.getVendorDropForGrnReport({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setVendorDrop(res.data);
      } else {
        if (res.intlCode) {
          setVendorDrop([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setVendorDrop([]);
    })
  }

  const data = [
    { title: "Plant", dataIndex: "plant" },
    { title: "PO Type", dataIndex: "poType" },
    { title: "PO Number", dataIndex: "poNumber" },
    { title: "PO Date", dataIndex: "poDate", render: (value, record) => (record.poDate ? moment(record.poDate).format('YYYY-MM-DD') : '-') },
    { title: "Customer PO", dataIndex: "saleOrder" },
    { title: "Item Sub Category", dataIndex: "itemSubCategory" },
    { title: "Item", dataIndex: "item" },
    { title: "GRN Number", dataIndex: "grnNumber" },
    { title: "GRN Date", dataIndex: "grnDate", render: (value, record) => (record.grnDate ? moment(record.grnDate).format('YYYY-MM-DD') : '-') },
    { title: "GRN Qty", dataIndex: "grnQty", render: (value) => {return Math.round(value);} },
    { title: "Invoice Number", dataIndex: "invoiceNumber" },
    { title: "Invoice Date", dataIndex: "invDate", render: (value, record) => (record.invDate ? moment(record.invDate).format('YYYY-MM-DD') : '-') },
    { title: "Vender Name", dataIndex: "vendorName"  },
    { title: "Received Qty", dataIndex: "rcvdQty", render: (value) => {return Math.round(value);} },
    { title: "Accepted Qty", dataIndex: "acptQty", render: (value) => {return Math.round(value);} },
    { title: "Rejected Qty", dataIndex: "rjctQty", render: (value) => {return Math.round(value);} },
    { title: "Aging", dataIndex: "expectedDeliveryDate" }

  ];

  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('grnReport')
      .addColumns(data)
      .addDataSource(grnReport,{str2num:true})
      .saveAs('grn-report.xlsx');
  }

  const exportToPdf = () => {
    var columns = [
      { title: "Plant", dataKey: "plant" },
      { title: "PO Type", dataKey: "poType" },
      { title: "PO Number", dataKey: "poNumber" },
      { title: "PO Date", dataKey: "poDate", render: (value, record) => (record.poDate ? moment(record.poDate).format('YYYY-MM-DD') : '-') },
      { title: "Customer PO", dataKey: "saleOrder" },
      { title: "Item Sub Category", dataKey: "itemSubCategory" },
      { title: "Item", dataKey: "item" },
      { title: "GRN Number", dataKey: "grnNumber" },
      { title: "GRN Date", dataKey: "grnDate", render: (value, record) => (record.grnDate ? moment(record.grnDate).format('YYYY-MM-DD') : '-') },
      { title: "GRN Qty", dataKey: "grnQty", render: (value,record) => {return Math.round(value);} },
      { title: "Invoice Number", dataKey: "invoiceNumber" },
      { title: "Invoice Date", dataKey: "invDate", render: (value, record) => (record.invDate ? moment(record.invDate).format('YYYY-MM-DD') : '-') },
      { title: "Vender Name", dataKey: "vendorName" },
      { title: "Received Qty", dataKey: "rcvdQty", render: (value,record) =>  {return Math.round(value);} },
      { title: "Accepted Qty", dataKey: "acptQty", render: (value,record) => {return Math.round(value);} },
      { title: "Rejected Qty", dataKey: "rjctQty", render: (value,record) => {return Math.round(value);} },
      { title: "Aging", dataKey: "expectedDeliveryDate" }
    ];
    const doc = new jsPDF()
    // @ts-ignore

    doc.autoTable(columns, grnReport, {
      columnStyles: {
        id: { fillColor: 255 }
      },

      margin: { top: 20 },
      addPageContent: function (data) {
        doc.text("GRN REPORT", 50, 15);
      }
    });
    doc.save('grn-report.pdf')
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'plant',
      width: '100px',
      fixed:'left',
      sorter: (a, b) => a.plant?.localeCompare(b.plant),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      width: '120px',
      sorter: (a, b) => a.poType?.localeCompare(b.poType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        { text: 'WITH SO', value: 'WITH SO' },
        { text: 'WITH OUT SO', value: 'WITH OUT SO' }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.poType === value;
      }
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      width: '140px',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'PO Date',
      dataIndex: 'poDate',
      width: 160,
      align: 'center',
      sorter: (a, b) => a.poDate?.localeCompare(b.poDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('poDate'),
      render: (value, record: AllGrnReportDto, index) => {
        return moment(record.poDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      width: 160,
      align: 'center',
      sorter: (a, b) => a.createdDate?.localeCompare(b.createdDate),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('poDate'),
      render: (value, record: AllGrnReportDto, index) => {
        return moment(record.createdDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Customer PO',
      dataIndex: 'saleOrder',
      width: '150px',
      sorter: (a, b) => a.saleOrder?.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder'),
      render: (value, record) => {
        return (record.saleOrder ? record.saleOrder : '-')
      },
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategory',
      width: '120px',
      sorter: (a, b) => a.itemSubCategory?.localeCompare(b.itemSubCategory),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('itemSubCategory')
    },
    {
      title: 'Item',
      dataIndex: 'item',
      width: '250px',
      sorter: (a, b) => a.item?.localeCompare(b.item),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item')
    },
    {
      title: 'GRN Number',
      dataIndex: 'grnNumber',
      width: '150px',
      sorter: (a, b) => a.grnNumber?.localeCompare(b.grnNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnNumber')
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      //fixed: 'right',
      width: 170,
      //fixed: 'left',
      align: 'left',
      sorter: (a, b) => a.grnDate?.localeCompare(b.grnDate),
      //...getColumnSearchProps('grnDate'),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: GrnDetailsDto, index) => {
        return moment(record.grnDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'PO Qty',
      dataIndex: 'grnQty',
      width: '100px',
      align: 'right',
      sorter: (a, b) => a.grnQty?.localeCompare(b.grnQty),
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return Math.round(value);
      }
      //...getColumnSearchProps('toalValue')
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      width: '150px',
      // responsive: ['lg'],
      sorter: (a, b) => a.invoiceNumber?.localeCompare(b.invoiceNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoiceNumber')
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invDate',
      width: '170px',
      // responsive: ['lg'],
      sorter: (a, b) => a.invDate?.localeCompare(b.invDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return <span>
          {record.invDate ? moment(record.invDate).format('YYYY-MM-DD') : '-'}
        </span>
      }
    },
    // {
    //   title: 'SO Date',
    //   dataIndex: 'soDate',
    //   fixed: 'left',
    //   width: 160,
    //   align: 'center',
    //   sorter: (a, b) => a.soDate?.localeCompare(b.soDate),
    //   sortDirections: ['descend', 'ascend'],
    //  // ...getColumnSearchProps('soDate'),
    //   render: (value, record: AllGrnReportDto, index) => {
    //     return moment(record.soDate).format('YYYY-MM-DD');
    //   }
    // },

    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      width: '210px',
      sorter: (a, b) => a.vendorName?.localeCompare(b.vendorName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('vendorName')
    },
    {
      title: 'Received Qty',
      dataIndex: 'rcvdQty',
      width: '100px',
      align: 'right',
      sorter: (a, b) => a.rcvdQty?.localeCompare(b.rcvdQty),
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return Math.round(value);
      }
      //...getColumnSearchProps('toalValue')
    },
    {
      title: 'Accepted Qty',
      dataIndex: 'acptQty',
      width: '100px',
      align: 'right',
      sorter: (a, b) => a.acptQty?.localeCompare(b.acptQty),
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return Math.round(value);
      }
      //...getColumnSearchProps('toalValue')
    },
    
    {
      title: 'Rejected Qty',
      dataIndex: 'rjctQty',
      width: '100px',
      align: 'right',
      sorter: (a, b) => a.rjctQty?.localeCompare(b.rjctQty),
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => {
        return Math.round(value);
      }
      //...getColumnSearchProps('toalValue')
    },
    {
      title: 'Aging',
      key: 'expectedDeliveryDate',
      width: '100px',
      // responsive: ['lg'],
      sorter: (a, b) => (Math.floor((new Date(moment(a.expectedDeliveryDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.expectedDeliveryDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
          props: {
            style: {
              background: Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY/MM/DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
            }
          },
        };
        return obj;
      }
    },

  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <>

      <Card title={<span style={{ color: 'white' }}>GRN Report</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
        <Form layout={"vertical"} autoComplete="off" form={form} >
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Form.Item
                name="delUnitId"
                label=" Plant"
                rules={[
                  {
                    required: false, message: 'Select Unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Unit"
                  allowClear
                  style={{ width: '100%' }}
                  onChange={handleUnit}
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  {plantData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Form.Item
                name="poType"
                label="PO Type"
                rules={[
                  {
                    required: false,
                    message: 'PO Type is required'
                  },
                ]}

              >
                <Select
                  showSearch
                  placeholder="Select PO Type"
                  allowClear
                >
                  <Option key={0} value={null}>Select PO Type</Option>
                  <Option key={1} selected value={'WITH SO'}>WITH SO</Option>
                  <Option key={2} value={'WITH OUT SO'}>WITH OUT SO</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="fromDate"
                label="GRN Date"
                initialValue={undefined}
                rules={[
                  {
                    required: false,
                    message: " ETD Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }}>
            <Form.Item name="createdDate"
                label="Created Date"
                rules={[
                  {
                    required: false,
                    message: "Missing Created Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedCreatedDate} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
              <Form.Item name="saleOrderId"
                label="Customer PO"
                rules=
                {[{ required: false, message: ' Select ' },]}>
                <Select showSearch placeholder="Select Customer Po"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  <Option key={0} value={null}>Select Sale Order</Option>
                  {soDrop.map((data) => {
                    return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.soNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Form.Item
                name="grnNumber"
                label="GRN Number"
              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select GRN Number"
                  optionFilterProp="children"
                  optionLabelProp="label"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {grnNumberDropDowndata.map(grnNumberDropDowndata => {
                    return <Option name="grnId" key={grnNumberDropDowndata.grnId} value={grnNumberDropDowndata.grnId} label={grnNumberDropDowndata.grnNumber}>{grnNumberDropDowndata.grnNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Form.Item
                name="poNumber"
                label="Po Number"
              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select Po Number"
                  optionFilterProp="children"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {poDrop.map(dropData => {
                    return <Option key={dropData.purchaseOrderId} value={dropData.purchaseOrderId}>{dropData.poNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 9 }}>
            <Form.Item label="Item Name"
              name='item'
            >
              <Select showSearch
                allowClear
                placeholder="Select Item"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {itemDrop === undefined ? '' : itemDrop.map(dropData => {
                  return <Option key={dropData.itemId} value={dropData.itemId}>{dropData.item}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }}>
              <Form.Item
                name="itemSubCategoryId"
                label="Item Sub Category"
                rules={[
                  {
                    required: false,
                    message: 'Item Sub Category is required'
                  },
                  {
                    pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                    message: `Enter valid Item Sub Category `,
                  },
                ]}
              >
                <Select
                  showSearch
                  // style={{ width: 260 }}
                  placeholder="Select Sub Category"
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  allowClear
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Sub Category</Option>
                  {itemSubDrop.map((subCategory) => {
                    return <Option key={subCategory.itemSubCategoryId} value={subCategory.itemSubCategoryId}>{subCategory.itemSubCategory}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
              <Form.Item
                name="vendorName"
                label="Vendor Name"
              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select Vendor Name"
                  optionFilterProp="children"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {vendorDrop.map((vendor) => {
                    return <Option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 6 }}>
              <Form.Item
                name="excessPercent"
                label="Excess GRN %"
              >
                <Select
                  showSearch
                  // style={{ width: 220 }}
                  placeholder="Select GRN %"
                  optionFilterProp="children"
                  allowClear
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                 
                     <Option  value={100}>{'100%'}</Option>
                     <Option  value={105}>{'105%'}</Option>
                
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item
                name="invoiceNumber"
                label="Invoice Number"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col> */}
            
            <Col style={{ padding: '20px', marginTop: '10px',marginLeft:750 }}>
              <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getAllGrnReport()}>
                Get Report
              </Button>
              <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
            </Col>
            <Col>
              <Button icon={<DownloadOutlined />} style={{ marginTop: '10px', }} onClick={() => { exportExcel(); }}>
                Get Excel
              </Button>
              <Button icon={<FilePdfOutlined />} style={{ marginLeft: 5, marginTop: '10px', }} onClick={() => { exportToPdf(); }}>
                Get PDF
              </Button>
            </Col>
          </Row>
        </Form>
        <br></br>
        {(grnReport.length) ? <>
          <Row gutter={40}>
          <Col>
            <Card title={"Total GRN's: " + totalGRNCount} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
            <Card title={"Total GRN Items: " + grnReport.length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
        </Row>
          <Table
            rowKey={record => record.saleOrderId}
            columns={columnsSkelton}
            dataSource={grnReport}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            scroll={{ x: 1500, y: 500 }}
            bordered
          />
        </> : <></>}
      </Card>
    </>

  );
}


