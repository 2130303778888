import axiox from 'axios';
import connection from './connection';
import { ApplyForLeavesDto, AllApplyForLeavesResponse, ApplyForLeavesResponseModel, ApplyForLeavesReq, GetAllAvailableLeavesDto, GetAllAvailableLeavesResponseModel } from "@gtpl/shared-models/hrms";
import axios from 'axios';

export class ApplyForLeavesService {

    URL = connection.DEPLOY_URL + '/apply-for-leaves';

    async createLeave(dto: ApplyForLeavesDto): Promise<ApplyForLeavesResponseModel> {
        return await axios.post(this.URL + '/createLeave', dto)
            .then(res => {
                return res.data;
            })
    }

    async updateLeave(dto: ApplyForLeavesDto): Promise<ApplyForLeavesResponseModel> {
        return await axios.post(this.URL + '/updateLeave', dto)
            .then(res => {
                return res.data;
            })
    }

    async getAllAppliedLeaves(): Promise<AllApplyForLeavesResponse> {
        return await axios.post(this.URL + '/getAllAppliedLeaves')
            .then(res => {
                return res.data;
            })
    }



    async activateOrDeactivateApplyForLeave(dto: ApplyForLeavesReq): Promise<ApplyForLeavesResponseModel> {
        return await axios.post(this.URL + '/activateOrDeactivateApplyForLeave', dto)
            .then(res => {
                return res.data;
            })
    }

    async getApplyForLeavesById(req: ApplyForLeavesReq): Promise<ApplyForLeavesResponseModel> {
        return await axios.post(this.URL + '/getApplyForLeavesById', req)
            .then(res => {
                return res.data;
            })
    }
    
    async updateLeaveStatus(req: ApplyForLeavesReq): Promise<ApplyForLeavesResponseModel> {
        return await axios.post(this.URL + '/updateLeaveStatus', req)
            .then(res => {
                return res.data;
            })
    }

    async getAllAvailableLeaves(req: GetAllAvailableLeavesDto): Promise<GetAllAvailableLeavesResponseModel> {
        return await axios.post(this.URL + '/getAllAvailableLeaves', req)
            .then(res => {
                return res.data;
            })
    }


}