

export class ShiftReq {

    shift: string;

    constructor(shift: string) {
        this.shift = shift;
    }

}