import { GlobalResponseObject } from "../global-response-object";
import { AssetVendorDetailsModel } from "./asset-vendor-details.model";

export class AssetVendorDetailsResponse extends GlobalResponseObject{
    data: AssetVendorDetailsModel[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data: AssetVendorDetailsModel[]){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}