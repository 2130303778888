import React, { useState, useEffect, useRef } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import './qc-defect-details-grid.css';
import { QcDefectItemDetailsDto, QcdefectReportRequest, QCDefectRequest } from '@gtpl/shared-models/production-management';
import { OperationService, QaQcOperationsService, QcDefectService } from '@gtpl/shared-services/production';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { OperationIdRequest, OperationsDropDown, QaQcDropDown, Qcdefectdto, WorkstationDTO } from '@gtpl/shared-models/production-management';
import { QcDefectDetailsForm } from '@gtpl/pages/production-management/production-management-components/qc-defect-details-form';
import moment from 'moment';
import QcDefectItemDetails from './qcdefect-item-details-grid';
/* eslint-disable-next-line */
export interface QcDefectDetailsGridProps { }

export function QcDefectDetailsGrid(
  props: QcDefectDetailsGridProps
) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any[]>([]);

  const [selectedqcDefectdetails, setselectedqcDefectdetails] = useState<any>(undefined);
  const [QcedefectdetailsData, setQcedefectdetailsData] = useState<Qcdefectdto[]>([]);
  const [operations, setOperations] = useState<OperationsDropDown[]>([]);
  const [testsData, setTestsData] = useState<QaQcDropDown[]>([]);
  const [workstationdropdown, setworkstationdropdown] = useState<WorkstationDTO[]>([]);
  const [lotNumber, setlotNumber] = useState<Qcdefectdto[]>([]);
  const [returnData, setReturnData] = useState(undefined);
  const [updateState, setUpdateState] = useState(false);
  const [object, setObject] = useState(null);
  const [qcdefectItemsData,setQcdefectItemsData] = useState<QcDefectItemDetailsDto[]>([])



  const QcDefectiveService = new QcDefectService;
  const testservice = new QaQcOperationsService;
  const operationService = new OperationService();



  /**
* used for column filter
* @param dataIndex column data index
*/
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
* 
* @param selectedKeys 
* @param confirm 
* @param dataIndex 
*/
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const openFormWithData = async (QcdefectViewData: Qcdefectdto) => {
    // console.log(QcdefectViewData);
    // QcDefectiveService.getQcdefectItemByQcId({qcId: QcdefectViewData.qcId}).then(res =>{
    //   if(res.status){
    //     QcdefectViewData.startTime = moment(QcdefectViewData.startTime)
    //     QcdefectViewData.qcDefectItemDetails = res.data
        setDrawerVisible(true);
        setselectedqcDefectdetails(QcdefectViewData);      
      // }else{
    //     setQcdefectItemsData([]);
    //   }
    // }).catch(err =>{
    //   setQcdefectItemsData([]);
    // })
   
  }
  const deleteQcdefectdetails = (qcdefectdetailsData: Qcdefectdto) => {
    qcdefectdetailsData.isActive = qcdefectdetailsData.isActive ? false : true;
    QcDefectiveService.activateOrDeactivateQcDefectDetails(qcdefectdetailsData).then(res => {
      console.log(res);
      if (res.status) {
        getAllQcedefectiveDetails();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Lot number',
      dataIndex: 'lotNumber',
    },
    {
      title: 'Work Station',
      dataIndex: 'workstation',
      width: '140px'

    },

    {
      title: 'Operation',
      dataIndex: 'operationName',
      width: '140px'

    },
    {
      title: 'Inspected Count',
      dataIndex: 'inspectedCount',
      width: '160px',
      align: 'right',

    },
    {
      title: 'Testresult',
      dataIndex: 'testResult',
      width: '150px',
      align: 'right',

    },

    {
      title: `Action`,
      dataIndex: 'action',
      align: 'right',
      render: (text, rowData) => (
        <span>
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Qcdefect details');
              }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />

          <Divider type="vertical" />
          <Popconfirm onConfirm={e => { deleteQcdefectdetails(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate QcdefectDetails ?'
                : 'Are you sure to Activate QcdefectDetails ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>
        </span>
      )
    }
  ];
  const storeReturn = (rowData) => {
    getStoreItems(rowData.qcId);
  }
  const getStoreItems = (qcId) => {
    QcDefectiveService.getQcdefectItemByQcId({ qcId: qcId }).then(res => {
      if (res.status) {
        const itemId = res.data[0].itemId;
        setReturnData({ itemId })
      } else {
      }
    }).catch(err => {
    })
  }
  /**
     * 
     * @param pagination 
     * @param filters 
     * @param sorter 
     * @param extra 
     */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    getAllQcedefectiveDetails();
    getAllOperations(),
      getAllworkstations(),
      getAllLotNumbers();
    getAllQcQcActiveTestsForOperation();
  }, [])

  const getAllQcedefectiveDetails = () => {
    const req = new QcdefectReportRequest()
    let lotNumber = form.getFieldValue('lotNumber');
    let workstationId = form.getFieldValue('workstationId');
    let operationId = form.getFieldValue('operationId');
    let testResultId = form.getFieldValue('testResultId')
    req.lotNumber = lotNumber;
    req.workstationId = workstationId;
    req.operationId = operationId;
    req.testResultId = testResultId;
    QcDefectiveService.getAllQcDefectDetails(req).then(res => {
      if (res.status) {
        setQcedefectdetailsData(res.data);
      } else {
        if (res.intlCode) {
          setQcedefectdetailsData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setQcedefectdetailsData([]);
    })
  }
  const getAllLotNumbers = () => {
    QcDefectiveService.getAllLotnumbers().then(res => {
      if (res.status) {
        setlotNumber(res.data);
      } else {
        setlotNumber([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setlotNumber([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllworkstations = () => {
    QcDefectiveService.getAllActiveWorkstation().then(res => {
      if (res.status) {
        setworkstationdropdown(res.data);
      } else {
        setworkstationdropdown([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setworkstationdropdown([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllOperations = () => {
    operationService.getAllOperationsDropDown().then(res => {
      if (res.status) {
        setOperations(res.data);
      } else {
        setOperations([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setOperations([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getAllQcQcActiveTestsForOperation = () => {
    QcDefectiveService.getTestNameForReport().then(res => {
      if (res.status) {
        setTestsData(res.data);
      } else {
        setTestsData([]);
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setTestsData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const onReset = () => {
    form.resetFields();
    getAllQcedefectiveDetails();
    // setQcedefectdetailsData([]);
  }

  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.qcId)

  }
  /**
      * 
      * @param variantData 
      */
  const updateQcDefectDetails = (qcdefectDetailsData: Qcdefectdto) => {
    QcDefectiveService.updateQcDefectDetails(qcdefectDetailsData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllQcedefectiveDetails();
        setDrawerVisible(false);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  
  const renderItems = (record: QcDefectItemDetailsDto, index, indent, expanded) => {
   
    return <QcDefectItemDetails qcId={record.qcId}  />
  }

  return (

    <Card title={<span style={{ color: 'white' }}>Qc Defect</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/qcdefect-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <Card>
        <Form form={form} layout={'vertical'}>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item
                name="lotNumber"
                label="Lotnumber"
              >
                <Select
                  showSearch
                  placeholder="Select  Lotnumber "
                  // onChange={getAllQcQcActiveTestsForOperation}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select workstation</Option>
                  {lotNumber.map((value) => {
                    return <Option key={value.lotNumber} value={value.lotNumber}>{value.lotNumber}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item
                name="workstationId"
                label="Workstation"
              >
                <Select
                  showSearch
                  placeholder="Select or scan workstation "
                  // onChange={getAllQcQcActiveTestsForOperation}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select workstation</Option>
                  {workstationdropdown.map((value) => {
                    return <Option key={value.workStationId} value={value.workStationId}>{value.workstation}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item
                name="operationId"
                label="Operation"
              >
                <Select
                  showSearch
                  placeholder="Select Operation Category"
                  // onChange={getAllQcQcActiveTestsForOperation}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={null}>Select Operations</Option>
                  {operations.map((operation) => {
                    return <Option key={operation.operationId} value={operation.operationId}>{operation.operationName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

<Form.Item name="testResultId" label="Defect">
  <Select
    placeholder="Select Defect"
    allowClear
  >
    <Option key={0} value={null}>Select Defect</Option>
    {testsData.map((test) => {
      return <Option key={test.qaQcTestId} value={test.qaQcTestId}>{test.testName}</Option>
    })}
  </Select>
</Form.Item>
</Col> */}
            <Col style={{ padding: '20px', marginTop: '10px' }}>
              <Button type="primary" style={{ marginRight: '4px' }} disabled={disable} onClick={() => getAllQcedefectiveDetails()}>
                Get Report
              </Button>
              <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
            </Col>
          </Row>
        </Form>
        <Card >

          <Table
            rowKey={record => record.qcId}
            columns={columnsSkelton}
            dataSource={QcedefectdetailsData}
            expandedRowRender={renderItems}
            expandIconColumnIndex={8}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            scroll={{ x: true }}
            onChange={onChange}
            size='small'
            bordered />
        </Card>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '85%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>
          <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
            <QcDefectDetailsForm key={Date.now()}
              updateItem={updateQcDefectDetails}
              isUpdate={true}
              // saveItem={saveVariant}
              qcdefectData={selectedqcDefectdetails}
              closeForm={closeDrawer} />
          </Card>
        </Drawer>
      </Card>
    </Card>
  );
}

export default QcDefectDetailsGrid;
