export class GrnItemReceivedQtyRequest {
    grnId: number;
    itemId: number;
    itemCategoryId: number;
    /**
     * 
     * @param grnId 
     * @param itemId 
     * @param itemCategoryId
     */
    constructor(grnId: number, itemId: number, itemCategoryId: number) {
        this.grnId = grnId;
        this.itemId = itemId;
        this.itemCategoryId = itemCategoryId;
    }
}