import { RejectionsDto, RejectionsResponseModel,LotDetailsResModel, RejectionResponseModel,RejectionRequest} from '@gtpl/shared-models/production-management';
import axios from 'axios';
import connection from './connection';
import { CommonResponse } from '@gtpl/shared-models/masters';

export class RejectionsService {
    URL = connection.DEPLOY_URL + '/rejections';

    async createRejectionAssignment(inputReq): Promise<RejectionsResponseModel> {
        return await axios.post(this.URL + '/createRejectionAssignment', inputReq)
            .then(res => {
                return res.data
            })
    }

    async  getAllLotDetailsInfo(req): Promise<LotDetailsResModel> {
        return await axios.post(this.URL + '/getAllLotDetailsInfo', req)
            .then(res => {
                return res.data
            })
    }

    async   getDetailViewById(rejectionRequest: RejectionRequest): Promise<RejectionResponseModel> {
        return await axios.post(this.URL + '/getDetailViewById',rejectionRequest)
            .then(res => {
                return res.data
            })
    }

    async  getAllRejectedLots(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllRejectedLots')
            .then(res => {
                return res.data
            })
    }

   
    }