export class IndentFormItemsDto{
indentItemId: number;
indentMaterialPreparationId: number;
itemsubcategoryId: number;
itemId: number;
quantity: number;
unitId: number;
createdAt: Date;
createdUser: string;
updatedAt: Date;
updatedUser: string;
versionFlag: number;
isActive: boolean;
constructor(
    indentItemId: number,
indentMaterialPreparationId: number,
itemsubcategoryId: number,
itemId: number,
quantity: number,
unitId: number,
createdAt: Date,
createdUser: string,
updatedAt: Date,
updatedUser: string,
versionFlag: number,
){this.indentItemId=indentItemId;
    this.indentMaterialPreparationId=indentMaterialPreparationId;
    this.itemsubcategoryId=itemsubcategoryId;
    this.itemId=itemId;
    this.quantity=quantity;
    this.unitId=unitId;
    this.createdAt=createdAt;
    this.createdUser=createdUser;
    this.updatedAt=updatedAt;
    this.updatedUser=updatedUser;
    this.versionFlag=versionFlag;
}

}