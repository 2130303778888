import { Card, Button, Form, Descriptions, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import form from 'antd/lib/form';
import { QrcodeOutlined, SearchOutlined, PrinterOutlined, BarcodeOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';
import { PalletDto } from '@gtpl/shared-models/masters';
import { PalletService } from '@gtpl/shared-services/masters';
import Highlighter from 'react-highlight-words';


export interface PalletbarcodeProps{}
export function PalletbarCode(props:PalletbarcodeProps){
    const searchInput = useRef(null);
    const [from] = Form.useForm();
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');   
    const [drawerVisible, setDrawerVisible] = useState(false);
    const[barcodeData, setbarcodeData]=useState<PalletDto[]>([]);
    const palletService = new PalletService();
    useEffect(() =>{getAllPallets();
    },[])

    const getAllPallets = () => {
        palletService.getAllPallets({plantId:Number(localStorage.getItem("unit_id"))}).then(res => {
            console.log(res.data);
          if(res.status) {
            setbarcodeData(res.data);
          }else {
            if(res.intlCode) {
                setbarcodeData([]);
              AlertMessages.getErrorMessage(res.internalMessage);
            }else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
            setbarcodeData([]);
          AlertMessages.getErrorMessage(err.message);
        })
      }
      
    const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filterd =>(
         <SearchOutlined type="search" style={{color: filterd ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
           .toLowerCase()
           .includes(value.toLowerCase())
           : false,
        onFilterDropDownVisibleChange: visible =>{
          if(visible) {
            setTimeout(() => searchInput.current.select());
          }
        },
        render: text =>
          text ?(
            searchedColumn === dataIndex ? (
              <Highlighter 
               highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
               searchWords={[searchText]}
               autoEscape
               textToHighlight= {text.toString()}
             />
            ) : text
          )
          : null
       
      });
      /**
       * 
       * @param selectedKeys
       * @param confirm
       * @param dataIndex
       */
      function handleSearch(selectedKeys, confirm, dataIndex){
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      
      function handleReset(clearFilters){
        clearFilters();
        setSearchText('');
      };
        
        //drawer related
        const closeDrawer = () =>{
          setDrawerVisible(false);
        }
      
return(
    <>
    <div>

    </div>
    </>
)
}
export default PalletbarCode