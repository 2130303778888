export class NotifyPartyDropDownDto{
    notifyPartyId:number;
    notifyPartyName:string;

  /**
   * 
   * @param notifyPartyId 
   * @param notifyPartyName 
   */
    constructor(notifyPartyId:number, notifyPartyName:string){
         this.notifyPartyId = notifyPartyId;
         this.notifyPartyName = notifyPartyName;
    }
}