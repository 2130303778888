export class ValAddTwoDropDownData{
  valueAdditionTwoId:number;
  name:string;
    
    /**
     * 
     * @param valueAdditionTwoId 
     * @param name 
     */
    constructor(valueAdditionTwoId:number,name:string){
      this.valueAdditionTwoId = valueAdditionTwoId;
      this.name = name;
    }
}