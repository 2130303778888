export class SequenceDataModel {
    sequence: number;
    operationId: number;
    operationName: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    VarientOperationId?: number;
    updatedUser?: string;
    versionFlag?: number;
    operationGroup?: number;
    groupDependency?: number;
    operationDependency?: number;
    lossOrGainPercentage?: number;
    unitId:number
    operationCategory?:string

    /**
     * 
     * @param sequence 
     * @param operationId 
     * @param operationName 
     * @param operationGroup 
     * @param groupDependency 
     * @param operationDependency 
     * @param lossOrGainPercentage 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedUser 
     * @param versionFlag 
     * @param VarientOperationId 
     * @param unitId 
     */

    constructor(
        sequence: number,operationId:number,operationName:string,operationGroup: number, groupDependency: number, operationDependency: number, lossOrGainPercentage: number, isActive: boolean, createdAt: Date, createdUser: string,unitId:number,operationCategory?:string, updatedUser?: string, versionFlag?: number, VarientOperationId?: number) {
        this.sequence = sequence;
        this.operationId = operationId;
        this.operationName = operationName;
        this.operationGroup = operationGroup;
        this.groupDependency = groupDependency;
        this.operationDependency = operationDependency;
        this.lossOrGainPercentage = lossOrGainPercentage;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.unitId = unitId;
        this.VarientOperationId  = VarientOperationId;
        this.updatedUser  = updatedUser;
        this.versionFlag  = versionFlag;
        this.operationCategory  = operationCategory;
    }
}

