import { CardHeaderBody } from "./card-header-body";

export class DohountRequest {
    headerTitle: string;
    name: string;
    portionData: CardHeaderBody[];

    constructor(headerTitle: string, name: string, portionData: CardHeaderBody[]) {
        this.headerTitle = headerTitle;
        this.name = name;
        this.portionData = portionData;
    }
}