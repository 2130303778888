import React, { useState, useEffect } from 'react';
import { SubJobItemDto, SubJobRequest, SubJobRevisionCodesRequest, } from '@gtpl/shared-models/sale-management';
import { Button, Card, Col, DatePicker, Form, Row, Select, Spin, InputNumber, Dropdown, Tooltip, Modal } from 'antd';
import form from 'antd/lib/form';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import Table, { ColumnProps } from 'antd/lib/table';
import { CrrencySymbols, SaleOrderDetailDto, SaleOrderDetailViewInfoDTO, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { UomEnum } from '@gtpl/shared-models/common-models';
import { format } from 'url';
import {  RotateRightOutlined } from '@ant-design/icons'
import { SoDesignForm,CodeListAllocationForm, SubJobRevisionCodelist } from '@gtpl/pages/master/master-components/so-design-form';
import { SubJobCodeListRequest } from '@gtpl/shared-models/planning';


export interface SubJobRevisionProps {
    isUpdate: boolean;
    subJobRevisionPropsData: SubJobItemDto[];
    updateState: (updateState: number) => void
}
export function SubJobRevision(
    props: SubJobRevisionProps) {


    const [form] = Form.useForm();
    const { Option } = Select;
    const [soDropForSubJob, setSoDropForSubJob] = useState<any[]>([]);
    const service = new SaleOrderService;
    const [page, setPage] = React.useState(1);
    const [selectedSaleOrder, setSelectedSaleOrder] = useState<SaleOrderDetailViewInfoDTO>(undefined);
    const [selectedSaleOrderId, setSelectedSaleOrderId] = useState<number>(undefined);
    const [selectedOperationType, setSelectedOperationType] = useState<any>(undefined);
    const [totNetWeight, setTotNetWeight] = useState<number>(0);
    const [soItemId, setSoItemId] = useState<number[]>([]);
    const [totNetWeightInKg, setTotNetWeightInKg] = useState<number>(0)
    const [redirectView, setRedirectView] = useState<boolean>(false)
    const [updateKey, setUpdateKey] = useState<number>(0)
    const [tableData, setTableData] = useState<any[]>([]);
    const [subJobCodeListData, setSubJobCodeListData] = useState<SubJobRevisionCodesRequest[]>([]);
    const [codeListDetails, setCodeListDetails] = useState<SubJobCodeListRequest[]>([]);
    const [codeListModalVisisble, setCodeListModalVisisble] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(true)
    let revisedCodeList = []


    const goToTracker = () => {
        setRedirectView(true);
    }


    useEffect(() => {

        getSoDropForSubJob();
    }, [])

    useEffect(()=>{
        if(codeListDetails.length > 0){
            console.log(codeListDetails)
        }
    },[codeListDetails])

    let totWeight = 0;
    let totWeightInKg = 0;
    const getSaleOrderDetailsById = (value) => {
        setSelectedSaleOrderId(value)
        service.getSaleOrderDetailsById({ saleOrderId: value }).then(res => {
            if (res.status) {
                console.log(res.data);
                (res.data.itemData).forEach(data => {
                    console.log(data);
                    if (data.uom == "KG" || data.uom == "gm") {
                        totWeight += Number(data.netWeight) * 2.20462;
                    }
                    else {
                        totWeight += Number(data.netWeight);
                    }

                    if (data.uom == "LB" || data.uom == "OZ") {
                        totWeightInKg += Number(data.netWeight) * 0.453592;
                    }
                    else {
                        totWeightInKg += Number(data.netWeight);
                    }

                    // else if(data.uom == "gm"){
                    //   totWeight += Number(data.netWeight) * 0.00220462;
                    // }
                })
                console.log(totWeight);
                setTotNetWeight(totWeight);
                setTotNetWeightInKg(totWeightInKg);
                setSelectedSaleOrder(res.data);
                console.log(selectedSaleOrder);
            } else {
                if (res.intlCode) {
                    setSelectedSaleOrder(undefined);
                    // AlertMessages.getErrorMessage(res.internalMessage);
                    goToTracker();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSelectedSaleOrder(undefined);
        })
    }
    const getSoDropForSubJob = () => {
        service.getSoDropForSubJob({ unitId: Number(localStorage.getItem('unit_id')) }).then(res => {
            if (res.status) {
                setSoDropForSubJob(res.data);
            } else {
                if (res.intlCode) {
                    setSoDropForSubJob([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setSoDropForSubJob([]);
        })
    }

    const editCases = (e, index, row) => {
        // alert()
        console.log(e)
        console.log(row)
        console.log(e.jobCases)
        console.log(index)
        row.cases = e;
        // row.operation = 
        const dynTableData = [...selectedSaleOrder.itemData];
        selectedSaleOrder.itemData[index].jobCases = e;
        // setSelectedSaleOrder(dynTableData)                  
        setUpdateKey(prevState => prevState+1)
        
        // setTableData(dynTableData);
        // props.subJobRevisionPropsData([tableData])
       
    }

    
    const allocateCodeList = (rowData: any) => {
        let soItemIds = []
        soItemIds.push(rowData.saleOrderItemId,rowData.mainSoItemId)
        console.log(rowData)
        setSoItemId(soItemIds)
        // setSoData(rowData)
        setCodeListModalVisisble(true)
    
      }

    console.log(selectedOperationType, 'operationType')

    /**
  * 
  * @param pagination 
  * @param filters 
  * @param sorter 
  * @param extra 
  */
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    let weight = 0;

    const sampleTypeColumns: ColumnProps<any>[] = [

        {
            title: 'SNo',
            key: 'sno',
            width: 70,
            align: 'center',
            fixed: 'left',
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },

        {
            title: 'Brand',
            dataIndex: 'brand',
            width: 90,
            // fixed:'left',
            align: 'center',
            // ...getColumnSearchProps('brand'),

            // // responsive: ['lg'],
            // sorter: (a, b) => a.brand.localeCompare(b.brand),
            // sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Product SKU',
            dataIndex: 'varient',
            width: 120,

            align: 'center',

        },
        {
            title: 'Pack Type',
            dataIndex: 'packingStyle',
            width: 150,

            align: 'center',
            render: (text, record) => { return ` ${Number(record.pouches)}` + " * " + ` ${Number(record.pouchWeight)}` + ' ' + record.uom },

        },
        {
            title: 'Net Case Weight',
            dataIndex: 'caseWeight',
            width: 100,
            align: 'center',

            render: (text, record) => {
                const selUom = UnitsOfWeightInput.find(item => item.value == record.uomId);

                let outputUom
                if (selUom.name == UomEnum.LB || selUom.name == UomEnum.OZ) {
                    outputUom = UomEnum.LB;
                }
                if (selUom.name == UomEnum.KG || selUom.name == UomEnum.Gms) {
                    outputUom = UomEnum.KG;
                }
                return ` ${Number(parseFloat(record.caseWeight).toFixed(2))}` + ' ' + outputUom
            },


        },
        {
            title: 'Operation Type',
            dataIndex: 'OpType',
            width: 70,
            render: (text, data, index) => {
                return <span> <Select
                    placeholder="Operation Type"
                    allowClear
                    onSelect={operationType}
                >
                    <Option value="Add">Add</Option>
                    <Option value="Substraction">Subtraction</Option>
                </Select> </span>
            }


        },
        {
            title: 'Cases',
            dataIndex: 'jobCases',
            width: 80,
            align: 'center',
            render: (text, data, index) => {
                console.log(data)
                return <span><InputNumber  defaultValue={text} disabled={disable}
                onChange={e => editCases(e, index, data)}/></span>
                
            }

        },

        {
            title: 'Category',
            dataIndex: 'category',
            align: 'center',
            width: 100,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (text, data, index) => {
                console.log(data)
                return <span>
                    <Button type="primary" onClick={() => {
                    allocateCodeList(data);
                    }} 
                    style={{ backgroundColor: '#40a9ff' }}>Allocat Code List</Button>
                    {/* <Tooltip title='Allocate'><RotateRightOutlined className={'editSamplTypeIcon'} type="allocate"
                        onClick={() => {

                          // if (rowData.isActive) {
                          allocateCodeList(data);
                          // } else {
                          //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                          // }
                        }}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      /></Tooltip> */}
                </span>
                
            }

        },

    ]


    const handleSaleOrderId = (value) => {
        console.log(value);
        setSelectedSaleOrderId(value)
    }
    const operationType = (value) => {
        console.log(value);
        setSelectedOperationType(value)
        setDisable(false)
    }


    const saveSubJob = () => {
        setDisable(true);
        const soItemData = []
        for(const data of selectedSaleOrder.itemData){
            soItemData.push(new SubJobItemDto(data.saleOrderItemId,data.mainSoItemId,selectedOperationType,data.cases))

        }
        console.log(soItemData)
        console.log(codeListDetails)

        const request = new SubJobRequest(form.getFieldValue('saleOrderId'),soItemData,subJobCodeListData,localStorage.getItem('createdUser'))
        console.log(request)
        service.editSubJob(request).then((res) => {
          setDisable(false)
          if (res.status) {
            AlertMessages.getSuccessMessage('Sub Job saved successfully')
            window.location.reload()
          } else {
            AlertMessages.getErrorMessage(res.internalMessage)
          }
        }).catch((err) => {
          setDisable(false)
          AlertMessages.getErrorMessage(err.message)
        });
        form.setFieldsValue({
          itemId: undefined,
          cases: undefined
        })
    
      }

      const handleCancel = () => {
        setCodeListModalVisisble(false)
      }

      const codeListData = (codeListData) => {
        console.log(codeListData)
        // revisedCodeList.push(codeListData)
        setCodeListDetails([...codeListDetails,codeListData])
      }

      const updatedTraceCodes = (updateTraceCodesData) => {
        console.log(updateTraceCodesData)
        setSubJobCodeListData(updateTraceCodesData)
        setCodeListModalVisisble(false)
      }


    return (
        <>
        <Card title={<span style={{ color: 'white' }}>Sub Job  Revision</span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}><br></br>
            <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }}>
                        <Form.Item name="saleOrderId"
                            label="Customer PO"
                            rules=
                            {[{ required: false, message: ' Select ' },]}>
                            <Select showSearch placeholder="Select Customer Po"
                                optionFilterProp="children"
                                allowClear
                                onSelect={(value) => { getSaleOrderDetailsById(value) }}
                                // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                            >
                                <Option key={0} value={null}>Select Sale Order</Option>
                                {soDropForSubJob.map((data) => {
                                    return <Option key={data.saleOrderId} value={data.saleOrderId}> {data.poNumber}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
            {selectedSaleOrder ? <Table
                //   rowKey={record => record.certificationId}
                columns={sampleTypeColumns}
                dataSource={selectedSaleOrder.itemData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                scroll={{ x: true }}
                onChange={onChange}
                bordered /> : <></>}
            <Row justify='end'>
                <Col span={4} style={{ textAlign: 'right', paddingTop: '30px' }}>
                    <Button type="primary" disabled={disable} onClick={saveSubJob} >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Card>
        <Modal
        className='print-docket-modal'
        key={'modal' + Date.now()}
        width={'60%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={codeListModalVisisble}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        {<SubJobRevisionCodelist 
        // soId = {selectedSaleOrderId} 
        // codeListData={codeListData} 
        id = {soItemId} 
        updatedTraceCodes = {updatedTraceCodes}
        // operation={'SUB JOB REVISION'}
        />
        }
      </Modal>
      </>
    );
}
export default SubJobRevision;
