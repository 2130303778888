export class ProductDto{
    productId?:number;
    productName:string;
    productCode:string;
    remarks:string;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    hsnCode:number;
    
    /**
     * 
     * @param productId 
     * @param productName 
     * @param productCode 
     * @param remarks 
     * @param createdUser
     * @param isActive
     */
    constructor(productId:number,productName:string,productCode:string,remarks:string,createdUser:string,isActive:boolean, hsnCode:number){
    this.productId = productId;
    this.productName = productName;
    this.productCode = productCode;
    this.remarks = remarks;
    this.createdUser = createdUser;
    this.isActive = isActive;
    this.hsnCode =  hsnCode
    }
} 
