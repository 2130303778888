import React, { useEffect, useState } from 'react';
import Assets from './asserts-table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { render } from 'react-dom';
import ReactFullscreen from 'react-easyfullscreen';

import './soaking-main-dashboard.css';
import {ProductionInventoryService} from '@gtpl/shared-services/production'
import { Button, Card } from 'antd';
/* eslint-disable-next-line */
export interface SoakingMainDashboardProps {
  updateKey : number
}

export function SoakingMainDashboard(
  props: SoakingMainDashboardProps
) {
  const [updateState,setUpdateState] = useState<any>(0)
  const [isFullScreen,setIsFullScreen] = useState<boolean>(false)
  // setUpdateState(props?.updateKey)
  console.log(props?.updateKey,'state update smd')
  return (
    <>
     <ReactFullscreen>
    {({ ref, onRequest, onExit }) => (
      <>
      <div style={{display:'flex',float:'right'}}>
      <Button size='small' onClick={() => {setIsFullScreen(!isFullScreen); isFullScreen ? onExit() : onRequest()}}> {isFullScreen ?'Esc' : 'Full Screen'}</Button>
      </div>
    <Card title={false}  >
      <Assets updateKey={props?.updateKey} />
    </Card>
    </>
     )}
     </ReactFullscreen>
    </>
  );
}

export default SoakingMainDashboard;
