import axios from 'axios';
import connection from './connection';
import { RmToleranceResponse, RmToleranceDto, AllRmToleranceResponse} from '@gtpl/shared-models/raw-material-procurement';
import { UserRequestDto } from '@gtpl/shared-models/common-models';


export class RmToleranceService {

    URL = connection.DEPLOY_URL + '/rm-tolerance';


    async create(dto: RmToleranceDto): Promise<RmToleranceResponse> {
        return await axios.post(this.URL + '/createRmTolerance', dto)
            .then(res => {
                return res.data
            })
    }


    async update(dto: RmToleranceDto): Promise<RmToleranceResponse> {
        return await axios.post(this.URL + '/updateRmTolerance', dto)
            .then(res => {
                return res.data
            })
    }


    async activatedeActivate(Dto: RmToleranceDto): Promise<RmToleranceResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateRmTolerance', Dto)
            .then(res => {
                return res.data
            })
    }



    async getAll(req?:UserRequestDto): Promise<AllRmToleranceResponse> {
        return await axios.post(this.URL + '/getAllRmTolerance',req)
            .then(res => {
                return res.data
            })
    }

}