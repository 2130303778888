
export class PoItemsCreateDetails {
    itemCategory: number;
    itemSubCategoryId: number;
    item: number;
    size: number;
    quantity: number;
    unitPrice: number;
    tax: number;
    taxAmount: number;
    discountPercentage: number;
    discountAmount: number;
    transportation: number;
    Amount: number;
    bomItemId: number;
    purchaseOrderId?: number;
    purchaseOrderItemId?: number;
    saleOrderId?: number;
    fromPo?: boolean;
    isActive?:boolean;

    /**
     * 
     * @param itemCategory 
     * @param item 
     * @param quantity 
     * @param unitPrice 
     * @param tax 
     * @param taxAmount 
     * @param discountPercentage 
     * @param discountAmount 
     * @param transportation 
     * @param Amount 
     * @param purchaseOrderItemId 
     */
    constructor(itemCategory: number, itemSubCategoryId: number, item: number, size: number, quantity: number, unitPrice: number, tax: number, taxAmount: number, discountPercentage: number, discountAmount: number, transportation: number, Amount: number, bomItemId: number, purchaseOrderId?: number, purchaseOrderItemId?: number, saleOrderId?: number, fromPo?: boolean, isActive?:boolean) {
        this.itemCategory = itemCategory;
        this.itemSubCategoryId = itemSubCategoryId;
        this.item = item;
        this.size = size;
        this.quantity = quantity;
        this.unitPrice = unitPrice;
        this.tax = tax;
        this.taxAmount = taxAmount;
        this.discountPercentage = discountPercentage;
        this.discountAmount = discountAmount;
        this.transportation = transportation;
        this.Amount = Amount;
        this.bomItemId = bomItemId;
        this.purchaseOrderId = purchaseOrderId;
        this.purchaseOrderItemId = purchaseOrderItemId;
        this.saleOrderId = saleOrderId;
        this.fromPo = fromPo;
        this.isActive = isActive;
    }
}