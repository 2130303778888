import { POTypeEnum, StatusEnum } from "@gtpl/shared-models/common-models";
import { VendorDropDownDto } from "@gtpl/shared-models/masters";
import { SaleOrderDropDownDto } from "@gtpl/shared-models/sale-management";


export class AllAssetsPoDetailsInfo{
    saleOrder:SaleOrderDropDownDto;
    vendor:VendorDropDownDto;
    purchaseOrderNo:string;
    purchaseOrderDate:Date;
    transportation:number;
    expectedDeliveryDate:Date;
    deliveryAddress:string;
    paymentTerms: number;
    remarks:string;
    note:string;
    createdUser:string;
    updatedUser:string;
    purchaseOrderId:number;
    status:StatusEnum;
    poType?:POTypeEnum;
    currency?:string;
    soNumber?:string;
    paymentTermsName?:string
    /**
     * 
     * @param saleOrder 
     * @param vendor 
     * @param purchaseOrderNo 
     * @param purchaseOrderDate 
     * @param transportation 
     * @param expectedDeliveryDate 
     * @param deliveryAddress 
     * @param paymentTerms 
     * @param remarks 
     * @param note 
     * @param createdUser 
     * @param updatedUser 
     * @param poItems 
     * @param purchaseOrderId 
     * @param status 
     */
    constructor( vendor:VendorDropDownDto, purchaseOrderNo:string, purchaseOrderDate:Date, transportation:number, expectedDeliveryDate:Date, deliveryAddress:string, paymentTerms: number, remarks:string, note:string, createdUser:string, updatedUser:string, purchaseOrderId:number, status:StatusEnum, poType?:POTypeEnum, currency?:string, soNumber?:string, paymentTermsName?:string){
        // this.saleOrder = saleOrder;
        this.vendor = vendor;
        this.purchaseOrderNo = purchaseOrderNo;
        this.purchaseOrderDate = purchaseOrderDate;
        this.transportation = transportation;
        this.expectedDeliveryDate = expectedDeliveryDate;
        this.deliveryAddress = deliveryAddress;
        this.paymentTerms =  paymentTerms;
        this.remarks = remarks;
        this.note = note;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.purchaseOrderId = purchaseOrderId;
        this.status = status;
        this.poType = poType;
        this.currency = currency;
        this.soNumber = soNumber;
        this.paymentTermsName = paymentTermsName;
    }
}