import React, { useEffect, useRef, useState } from 'react';
import { Form,  Select } from 'antd';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer } from 'antd';
import './zone-grid.css';
import {ZoneService} from '@gtpl/shared-services/masters'
import { UnitcodeDto, ZoneDto } from '@gtpl/shared-models/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link, Redirect } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import {ZoneForm} from '@gtpl/pages/warehouse-masters/warehouse-master-component/zone-form';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { PlantIdRequest } from '@gtpl/shared-models/masters';
/* eslint-disable-next-line */
export interface ZoneGridProps {}

export function ZoneGrid(
  props: ZoneGridProps
) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [plantId,setPlantId] = useState<any>()
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [zonesData, setZonesData] = useState<ZoneDto[]>([]);
  const [selectedZoneData, setSelectedZoneData] = useState<any>(undefined);
  const [plantData, setPlantData] = useState<any[]>();
  const zoneService=new ZoneService();
  const unitsService = new UnitcodeService()
  const username = localStorage.getItem('username')
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });
  useEffect(() => {
    getAllZones();
    //  getAllPlants()
  }, []);
  
  // const getAllPlants = () => {
  //   unitsService.getAllPlants().then(res => {
  //     if(res.status){
  //       res.data.forEach((rowData)=> {
  //     unitsService.getPlantDetailsById(new PlantIdRequest(rowData.plantId)).then((res) => {
  //   rowData.plantName= res.data.unitcodeName;
  //     })
  //     console.log(rowData.plantName);
  //       });
        // setPlantData(res.data);

  //     } else {
  //       if (res.intlCode) {
  //         // setPlantData([]);
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
      
  //     }
  //   }).catch(err => {
  //     // setPlantData([]);
  //     AlertMessages.getErrorMessage(err.message);
     
  //   })
  // }


// const getAllPlants=()=>{
//   zoneService.getAllPlants().then(res=>{
//     if (res.status){
//          setPlantData(res.data);

//       }else {
//         if(res.intlCode){
//           setPlantData([]);
//           AlertMessages.getErrorMessage(res.internalMessage);
//         }else {
//           AlertMessages.getErrorMessage(res.internalMessage);
//         }
//       }
//   }).catch(err => {
//     setPlantData([]);
//     AlertMessages.getErrorMessage(err.message);
//   })
// }




  const getAllZones= () => {
    zoneService.getAllZones().then(res => {
      if (res.status) {
        res.data.forEach((rowData)=> {
          unitsService.getPlantDetailsById(new PlantIdRequest(rowData.plantId)).then((res) => {
        rowData.plantName= res.data.unitcodeName;
          })
          console.log(rowData.plantName);
            });
            // setPlantData(res.data);
        setZonesData(res.data);
        setPlantId(res.data);
      } else {
        if (res.intlCode) {
            setZonesData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setZonesData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
 
  const deleteZone = (zonesData:ZoneDto) => {
    // zonesData.isActive=zonesData.isActive?false:true;
    zoneService.ActivateorDeactivateZone({...zonesData, isActive: zonesData.isActive ? false : true }).then(res => { console.log(res);
      if (res.status) {
        getAllZones();
        AlertMessages.getSuccessMessage('Success');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
   
    /**
     * 
     * @param variantData 
     */
    const updateZone = (zonesData: ZoneDto) => {
      zoneService.updateZone(zonesData).then(res => { console.log(res);
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAllZones();
          setDrawerVisible(false);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    function handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    };
  
      //drawer related
      const closeDrawer=()=>{
        setDrawerVisible(false);
      }
    
      //TO open the form for updation
      const openFormWithData=(viewData: ZoneDto)=>{
        setDrawerVisible(true);
        setSelectedZoneData(viewData);
        console.log(selectedZoneData)
        console.log('selectedZoneData')
      }
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        render: (text, object, index) => (page-1) * 10 +(index+1)
      },
      {
        title: 'Plant',
        dataIndex: 'plantName',
        // responsive: ['lg'],
        sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('plantName')
      },
      
      {
        title: 'Zone Code',
        dataIndex: 'zoneCode',
        // responsive: ['lg'],
        sorter: (a, b) => a.zoneCode.localeCompare(b.zoneCode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('zoneCode')
      },
      {
        title: 'Zone Name',
        dataIndex: 'zoneName',
        // responsive: ['lg'],
        sorter: (a, b) => a.zoneName.localeCompare(b.zoneName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('zoneName')
      },
      ,
      // {
      //   title: 'Remarks',
      //   dataIndex: 'remarks',
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.remarks.length - b.remarks.length,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('productName')
      // },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive, rowData) => (
          <>
            {isActive?<Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag>:<Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
            
          </>
        ),
        filters: [
          {
            text: 'Active',
            value: 1,
          },
          {
            text: 'InActive',
            value: 0,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => 
        {
          // === is not work
          return record.isActive === value;
        },
        
      },
      
      // {
      //   title:`Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) => (
      //     <span>         
      //       {
      //         username == '"ramakrishna"' ?
      //         <>
      //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
      //           onClick={() => {
      //             if (rowData.isActive) {
      //               openFormWithData(rowData);
      //             } else {
      //               AlertMessages.getErrorMessage('You Cannot Edit Deactivated Zone');
      //             }
      //           }}
      //           style={{ color: '#1890ff', fontSize: '14px' }}
      //         />
            
      //       <Divider type="vertical" />
              
      //         <Popconfirm onConfirm={e =>{deleteZone(rowData);}}
      //         title={
      //           rowData.isActive
      //             ? 'Are you sure to Deactivate Zone ?'
      //             :  'Are you sure to Activate Zone ?'
      //         }
      //       >
      //         <Switch  size="default"
      //             className={ rowData.isActive ? 'toggle-activated' : 'toggle-deactivated' }
      //             checkedChildren={<RightSquareOutlined type="check" />}
      //             unCheckedChildren={<RightSquareOutlined type="close" />}
      //             checked={rowData.isActive}
      //           />
              
      //       </Popconfirm>
      //       </> : ''
      //       }
      //     </span>
      //   )
      // }
    ];
    /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <Card title={<span style={{color:'white'}}>Zones</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={(username == '"ramakrishna"') ?<Link to='/zone-form' ><Button className='panel_button' >Create </Button></Link>:""}
    
    >
     <br></br>
     <Row gutter={40} >
      <Col>
          <Card title={'Total Zones: ' + zonesData.length} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'#bfbfbf'}}></Card>
          </Col>
          <Col>
           <Card title={'Active: ' + zonesData.filter(el => el.isActive).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#52c41a'}}></Card>
          </Col>
          <Col>
           <Card title={'In-Active :' + zonesData.filter(el => el.isActive == false).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#f5222d'}}></Card>
          </Col>
          </Row>
          <br></br>
          <Table
          rowKey={record => record.zoneId}
          columns={columnsSkelton}
          dataSource={zonesData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered />
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
            onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              <ZoneForm key={Date.now()}
                updateZone={updateZone}
                isUpdate={true}
                zoneData={selectedZoneData}
                closeForm={closeDrawer} />
            </Card>
          </Drawer>
     </Card>
  );
}

export default ZoneGrid;
