export enum StockTypeStatus {
    Anticipated = 'anticipated',
    SaleOrder = 'SaleOrder',
    dummy = 'dummy',
    superDummy = 'superDummy',
}

export enum StockStatus {
    Anticipated = 'anticipated',
    SaleOrder = 'SaleOrder',
    dummy = 'dummy',
    superDummy = 'superDummy',
}