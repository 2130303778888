import { JobCompletedEnum, OperationTypeEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";

export class CookingDto {
    productionLogId: number;
    jobNumber: string;
    lotNumber: string;
    workStationType: string;
    saleOrderId: number;
    soNumber: string;
    variantCode: string;
    physicalQuantity: number;
    jobStatus: JobCompletedEnum;
    assignCooking: boolean;
    shift?: ShiftsEnum;
    assignedTime?: Date;
    plantId?: number;
    createdUser?: string;
    workstationId?: number;
    workstation?: string;
    saleOrderItemId?: number;
    inputGrade?: number;
    outputGrade?: number;
    operation?: OperationTypeEnum;
    boxes?: number;
    subLotNumber?: string;
    subPlantId?: number;
    wipQuantity?:number;
    ipBoxes?:number;
    poNumber?:string;

    constructor(productionLogId: number, jobNumber: string, lotNumber: string, workStationType: string, saleOrderId: number, soNumber: string, variantCode: string, physicalQuantity: number, jobStatus: JobCompletedEnum, assignCooking: boolean, shift?: ShiftsEnum, assignedTime?: Date, plantId?: number, createdUser?: string, workstationId?: number, workstation?: string, saleOrderItemId?: number, inputGrade?: number, outputGrade?: number, operation?: OperationTypeEnum, boxes?: number, subLotNumber?: string, subPlantId?: number,poNumber?:string) {
        this.productionLogId = productionLogId;
        this.jobNumber = jobNumber;
        this.lotNumber = lotNumber;
        this.workStationType = workStationType;
        this.saleOrderId = saleOrderId;
        this.soNumber = soNumber;
        this.variantCode = variantCode;
        this.physicalQuantity = physicalQuantity;
        this.assignCooking = assignCooking;
        this.jobStatus = jobStatus;
        this.shift = shift;
        this.assignedTime = assignedTime;
        this.plantId = plantId;
        this.createdUser = createdUser;
        this.workstationId = workstationId;
        this.workstation = workstation;
        this.saleOrderItemId = saleOrderItemId;
        this.inputGrade = inputGrade;
        this.outputGrade = outputGrade;
        this.operation = operation;
        this.boxes = boxes;
        this.subLotNumber = subLotNumber;
        this.subPlantId = subPlantId;
        this.poNumber = poNumber;
    }
}