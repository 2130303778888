import { CommonResponse } from "@gtpl/shared-models/production-management";
import { ForkLiftJobService } from "@gtpl/shared-services/warehouse-management";
import { Card, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { useEffect, useState } from "react";

export interface StockTransferItemDetailsProps {
    record: any;
}

function StockTransferItemDetails(
    props: StockTransferItemDetailsProps
) {

    const [jobItemsData, setJobItemsData] = useState<CommonResponse[]>([]);
    const forkLiftJobService = new ForkLiftJobService();
    useEffect(() => {
        getStoreItems();
    }, [])

    const getStoreItems = () => {
        forkLiftJobService.getStockTranserItemsData({jobId:props.record.jobId,transactionType:props.record.transactionType}).then(res => {
            if (res.status) {
                setJobItemsData(res.data);
            } else {
                setJobItemsData([]);
            }
        }).catch(err => {
            setJobItemsData([]);
        })
    }


    const jobItems: ColumnProps<any>[] = [
        {
            title: 'Po Number',
            dataIndex: "poNumber",
        },
        {
            title: 'Product SKU',
            dataIndex: 'variantCode',
        },
        {
            title: 'Job Cartons',
            dataIndex: 'jobCartons',
            align:'right'
        },
        {
            title: 'Issued Quantity',
            dataIndex: 'issuedQuantity',
            align:'right'
        },
        {
            title: 'Received Quantity',
            dataIndex: 'receivedQuantity',
            align:'right'
        },
    ];
    return (
        <>
            <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>Job Items</span>}></Card>
            <Table
                key={Date.now()}
                className="components-table-nested"
                columns={jobItems}
                dataSource={jobItemsData}
            />

        </>
    );
}
export default StockTransferItemDetails;