import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card,Form,DatePicker, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs } from 'antd';
import { GrnService } from '@gtpl/shared-services/procurement';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GrnForm } from '@gtpl/pages/procurement/packing-materials-components/grn-form';
import moment from 'moment';

import { Link, Redirect, Route } from 'react-router-dom';
import { GrnDetailsDto, AllGrnDto } from '@gtpl/shared-models/procurement-management';
import { EmployeeRolesEnum, PmStatusEnum, StatusEnum, UnitRequest } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface GrnGridProps { }

export function GrnGrid(props: GrnGridProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filtersData, setFiltersData] = useState<AllGrnDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [grnId, setGrnIdState] = useState<number>(null);
  const [grnData, setGrnData] = useState<AllGrnDto[]>([]);
  const [oldGrnData, setOldGrnData] = useState<AllGrnDto[]>([]);
  const [object, setObject] = useState(null);
  const [selectedProductData, setSelectedProductData] = useState<any>(undefined);
  const [transferredSosData,setTransferredSosData] = useState<any>()
  const [gridData, setGridData] = useState<AllGrnDto[]>([]);
  const { TabPane } = Tabs;
  // const text = <span>{vendor}</span>;
  const plantId = JSON.parse(localStorage.getItem('unit_id'));
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const gnrService = new GrnService();
  const onReset = () => {
    form.resetFields();
     setSearchText('');
     getGrnDetails();
    // setGrnData(gridData);
  }

  useEffect(() => {
    getGrnDetails();
    getTransferredSosGrnData()
  }, []);

  /**
   * 
   */
  const getGrnDetails = () => {
    let unitReq 
    if ((JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.HO_PACKAGING_MANAGER && (JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.ADMIN) {
      unitReq =   new UnitRequest( Number(localStorage.getItem('unit_id'))) 
    }else{
      unitReq =  new UnitRequest( Number(localStorage.getItem('unit_id'))) 
    }
    gnrService.getAllGrnData(unitReq).then(res => {
      if (res.status) {
        setFiltersData(res.data);
        setGrnData(res.data);
      } else {
        if (res.intlCode) {
         
          setGrnData(res.data);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setGrnData([]);
      }
    }).catch(err => {
      setGrnData([]);
      setFiltersData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

    console.log(grnData)

  const getTransferredSosGrnData = () => {
    let unitReq 
    if ((JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.HO_PACKAGING_MANAGER && (JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.ADMIN) {
      unitReq =   new UnitRequest( Number(localStorage.getItem('unit_id'))) 
    }else{
      unitReq =  new UnitRequest( Number(localStorage.getItem('unit_id'))) 
    }
    gnrService.getTransferredSosDataForGrnGrid(unitReq).then(res => {
      if (res.status) {
        setTransferredSosData(res.data);
      } else {
        if (res.intlCode) {
         setTransferredSosData(res.data);
          //AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //AlertMessages.getErrorMessage(res.internalMessage);
        }
        setTransferredSosData([]);
      }
    }).catch(err => {
      setTransferredSosData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  /**
   * 
   * @param productData 
   */
  // const deleteProduct = (productData:ProductDto) => {
  //   productData.isActive=productData.isActive?false:true;
  //   productDataService.activatedeActivateProduct(productData).then(res => { console.log(res);
  //     if (res.status) {
  //       getAllProducts();
  //       AlertMessages.getSuccessMessage('Success'); 
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  /**
   * 
   * @param variantData 
   */
  // const updateProduct = (productData: ProductDto) => {
  //   productDataService.updateProduct(productData).then(res => { console.log(res);
  //     if (res.status) {
  //       AlertMessages.getSuccessMessage('Updated Successfully');
  //       getAllProducts();
  //       setDrawerVisible(false);
  //     } else {
  //       if (res.intlCode) {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       } else {
  //         AlertMessages.getErrorMessage(res.internalMessage);
  //       }
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }
  /**
  * used for column filter
  * @param dataIndex column data index
  */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.grnId)

  }

  const setGrnId = (rowdata) => {
    setGrnIdState(rowdata.grnId)

  }
 

  const searchData = () => {
    const  selectedEtdFromDate1 = form.getFieldValue('toDate')?(moment(form.getFieldValue('toDate')[0]).format("YYYY-MM-DD")):null;
    const selectedEtdToDate1 = form.getFieldValue('toDate')?(moment(form.getFieldValue('toDate')[1]).format("YYYY-MM-DD")): null;
    const  selectedEtdFromDate = form.getFieldValue('fromDate')?(moment(form.getFieldValue('fromDate')[0]).format("YYYY-MM-DD")):null;
    const selectedEtdToDate = form.getFieldValue('fromDate')?(moment(form.getFieldValue('fromDate')[1]).format("YYYY-MM-DD")):null;

    let selectedData = filtersData;

    if ( selectedEtdFromDate && selectedEtdToDate )
      {
      selectedData = filtersData.filter(item => moment(item.grnDate).format("YYYY-MM-DD") >= selectedEtdFromDate && moment(item.grnDate).format("YYYY-MM-DD") <= selectedEtdToDate)
      } 
     if ( selectedEtdFromDate1 && selectedEtdToDate1 ) 
      {

      selectedData = filtersData.filter(item => moment(item.InvoiceDate).format("YYYY-MM-DD") >= selectedEtdFromDate1 && moment(item.InvoiceDate).format("YYYY-MM-DD") <= selectedEtdToDate1)
console.log(selectedEtdFromDate,selectedEtdToDate)
      }
    setGrnData( selectedData );
    setFiltersData( selectedData );
   
    // setGridData(filtersData)
  
    // setGridData(selectedData);
      }

//   form.validateFields().then(poRes => {
//   if (poRes.fromDate && poRes.toDate) {
//       const fromDate = new Date(poRes.fromDate[0]);
//       const toDate = new Date(poRes.fromDate[1]);
//       const startDate = new Date(poRes.toDate[0]);
//       const endDate = new Date(poRes.toDate[1]);
//       const filteredData = oldGrnData.filter((data) => {
//       let date = new Date(data.grnDate);
//       return date >= fromDate && date <= toDate;
//       }).filter((data) => {
//       let date = new Date(data.InvoiceDate);
//       return date >= startDate && date <= endDate;
//       });
//       setGrnData(filteredData);
//     } else if (poRes.fromDate) {
//       const fromDate = new Date(poRes.fromDate[0]);
//       const toDate = new Date(poRes.fromDate[1]);
//       const filteredData = oldGrnData.filter((data) => {
//       let date = new Date(data.grnDate);
//       return date >= fromDate && date <= toDate;
//       });
//       setGrnData(filteredData);
//     } else if (poRes.toDate) {
//       const startDate = new Date(poRes.toDate[0]);
//       const endDate = new Date(poRes.toDate[1]);
//       const filteredData = oldGrnData.filter((data) => {
//       let date = new Date(data.InvoiceDate);
//       return date >= startDate && date <= endDate;
//       });
//       setGrnData(filteredData);
//     }
//       else {
//       setGrnData(oldGrnData);
//     }
//   });
// }
     
  //    const onReset = () => {
  //    form.resetFields();
  //    setSearchText('');
  //    setGrnData(filtersData);
  //    };


        //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  // const openFormWithData = (viewData: ProductDto) => {
  //   setDrawerVisible(true);
  //   setSelectedProductData(viewData);
  // }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'soNumber',
    //   sorter: (a, b) => a.soId-b.soId,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('soNumber'),
    //   render: (value, record: AllGrnDto, index) => {
    //     return (record.soNumber) ? record.soNumber: '';
    //   }
    // },
    {
      title: 'Prod Unit',
      dataIndex: 'unitName',
      
      filters: [
        {
          text:  'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.unitName === value;
      },
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      sorter: (a, b) => a.poType.localeCompare(b.poType),
      // ...getColumnSearchProps('poType'),
      render: (value, record: AllGrnDto, index) => {
        return (record.poType) ? record.poType : '';
      },
      // sorter: (a, b) => a.loadType.localeCompare(b.loadType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text:  'WITH SO',
          value: 'WITH SO',
        },
        {
          text: 'WITH OUT SO',
          value: 'WITH OUT SO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.poType === value;
      },
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumbers',
      sorter: (a, b) => a.poNumbers?.localeCompare(b.poNumbers),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumbers'),
      width:'110px'
    },
    {
      title: 'PO Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),

    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor',
      sorter: (a, b) => a.vendor.length - b.vendor.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('vendor'),  
      width:200,
      render: (value, record: AllGrnDto, index) => {
        <Tooltip title={record.vendor} style={{backgroundColor:"black", float:'right',textAlign:'right'}}>
          </Tooltip>
            return (record.vendor) ? record.vendor: '';
      }
    },
    {
      title: 'GRN Number',
      dataIndex: 'grnNumber',
      sorter: (a, b) => a.grnId - b.grnId,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grnNumber'),
    },
    {
      title: 'GRN Date',
      dataIndex: 'grnDate',
      // responsive: ['lg'],
      sorter: (a, b) => moment(a.grnDate).unix() - moment(b.grnDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('grnDate'),
      render: (value, record: GrnDetailsDto, index) => {
        return moment(record.grnDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.invoiceNumber.length - b.invoiceNumber.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoiceNumber')
    },
    {
      title: 'Invoice Date',
      dataIndex: 'InvoiceDate',
      // responsive: ['lg'],
      sorter: (a, b) => moment(a.InvoiceDate).unix() - moment(b.InvoiceDate).unix(),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('InvoiceDate'),
      render: (value, record: GrnDetailsDto, index) => {
        return moment(record.InvoiceDate).format('YYYY-MM-DD');
      }
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   // responsive: ['lg'],
    //   sorter: (a, b) => a.status.length - b.status.length,
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('status')
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'isActive',
    //   render: (isActive, rowData) => (
    //     <>
    //       {isActive ? <Tag icon={<CheckCircleOutlined />} color="#87d068">Active</Tag> : <Tag icon={<CloseCircleOutlined />} color="#f50">In Active</Tag>}
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: 'Active',
    //       value: true,
    //     },
    //     {
    //       text: 'InActive',
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => {
    //     // === is not work
    //     return record.isActive === value;
    //   },

    // },
    {
      title: `Action`,
      dataIndex: 'action',
      render: (text, rowData) => (
        <span>
          
          {/* {(object) ?<Redirect
            to={{
              pathname: "/grn-form",
              state: object
            }}
          />:null}<EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              
              // if (rowData.isActive) {
                setUpdatevalue(rowData);
              // } else {
              //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
              // }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          /> */}

          <Divider type="vertical" />

          {(grnId) ? <Redirect to={ `/grn-detail-view/${grnId}`}
          /> : null}
          <EyeOutlined type="view"
            onClick={() => {

              // if (rowData.isActive) {
              setGrnId(rowData);
              // } else {
              //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
              // }
            }}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />





        </span>
      )
    }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (

    <Card size="small" title={<span style={{ color: 'white' }} >GRN Details</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/grn-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <br></br>
      <br></br>
      {/* <Row gutter={40}>
        <Col>
          <Card title={'Total GRN: ' + grnData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        </Row> */}
  
 
      <br></br>

      <Form  form={form} layout={'vertical'} style={{ padding: '0px' }}>
      <Row gutter={24}>
            <Col>
              <Form.Item name="fromDate"
                label="GRN Date"
                rules={[
                  {
                    required: false,
                    message: "Missing EDD Date"
                  },
                ]}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="toDate"
                label="Invoice Date"
                rules={[
                  {
                    required: false,
                    message: "Missing PO Date"
                  },
                ]}>
                <RangePicker />
              </Form.Item>
            </Col>
                    
                      <Col style={{ marginTop: 30 }} span={5}>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        // size="small"
                        block onClick={() => searchData()}
                        style={{ marginRight: 2, width: 100 }}
                      >
                        Search
                      </Button>
                      <Button type="primary" htmlType="submit" onClick={onReset}>
                        Reset
                      </Button>
                    </Col>            
</Row>
</Form>

      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane
          // tab="Open Orders"
          key="1"
          tab={<span style={{ color: "green" }}>{"Yet To GRN's: " + (grnData.filter(el => (el.poStatus == StatusEnum.OPEN || el.poStatus == StatusEnum.IN_PROGRESS)).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
            rowKey={record => record.productId}
            columns={columnsSkelton}
            dataSource={grnData.filter(res =>
              // (res.unitId == JSON.parse(localStorage.getItem('unit_id')) && 
              (res.poStatus == StatusEnum.OPEN || res.poStatus == StatusEnum.IN_PROGRESS))}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="2"
          tab={<span style={{ color: "red" }}>{"Completed GRN: " + (grnData.filter(el => el.poStatus == StatusEnum.CLOSED).length)}</span>}
          style={{ color: "#363636" }}
        >
          <Table
            rowKey={record => record.productId}
            columns={columnsSkelton}
            dataSource={grnData.filter(res => res.poStatus == StatusEnum.CLOSED)}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>
        <TabPane
          // tab="Open Orders"
          key="3"
          tab={<span style={{ color: "orange" }}>{"Transferred SO's" }</span>}
          style={{ color: "#363636" }}
        >
          <Table
            rowKey={record => record.grnId}
            columns={columnsSkelton}
            dataSource={transferredSosData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            onChange={onChange}
            bordered
            scroll={{ x: 500 }}
          />
          {/* } */}
        </TabPane>
      </Tabs>


      <br></br>
      <Row gutter={40}>
        {/* <Col>
          <Card title={'Total GRN: ' + grnData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col> */}
        {/* <Col>
          <Card title={'Open: ' + grnData.filter(el => el.status == 'Open').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#52c41a' }}></Card>
        </Col>
        <Col>
          <Card title={'Inprogress: ' + grnData.filter(el => el.status == 'Inprogress').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#e8e21e' }}></Card>
        </Col>

        <Col>
          <Card title={'Closed :' + grnData.filter(el => el.status == 'Closed').length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#f5222d' }}></Card>
        </Col> */}
      </Row>
      <br />
      <br />
      <br />
      {/* <Table
        rowKey={record => record.productId}
        columns={columnsSkelton}
        dataSource={grnData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered /> */}

    </Card>
  );
}


export default GrnGrid;
