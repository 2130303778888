import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { LotCodeDropDown } from "../lot-code-ops";

export class LotCodesResponse extends GlobalResponseObject{
    data: LotCodeDropDown[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data: LotCodeDropDown[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }

}