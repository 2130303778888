import { GlobalStatus, TransferStatusEnum } from "@gtpl/shared-models/common-models";
import { TransferItemsDetails } from "./transfer-items-details";
export class TransferMaterialInfo {
    packingStockTransferId: number;
    fromPlant: number;
    fromPlantName: string;
    toPlant: number;
    toPlantName: string;
    transferredDate: Date;
    receivedDate: Date;
    transferType: GlobalStatus;
    note: string;
    dcNumber: string;
    createdUser: string;
    updatedUser: string;
    receivedUser: string;
    mrnNumber: string;
    versionFlag: number;
    status: TransferStatusEnum;
    items?: TransferItemsDetails[];
    indentId?:number;
    poNumber?:string;
    indentCode?:string;
    transferedQty?:number;
    issuedQty?:number;
    isPreparedMaterial?:string;
    transferMethod?:string;
    plantId?:number
}