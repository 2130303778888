import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DownTimeReasonDto } from './downtime-reason.dto';

export class DownTimeReasonResponsemodel extends GlobalResponseObject{

    data?:DownTimeReasonDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: DownTimeReasonDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }


}