import { GrnReturnItemsDto } from "./grn-return-items-dto";


export class GrnReturnItemSaveDto {
    grnId: number;
    grnNumber: string;
    grnReturnDate: string;  
    createdUser: string;
    grnReturnItemsDto:GrnReturnItemsDto[]

    /**
     * 
     * @param grnId 
     * @param grnNumber 
     * @param grnReturnDate 
     * @param createdUser 
     * @param grnReturnItemsDto 
     */
    constructor(grnId: number,grnNumber: string,grnReturnDate: string,createdUser: string,grnReturnItemsDto:GrnReturnItemsDto[]){
        this.grnId = grnId;
        this.grnNumber = grnNumber;
        this.grnReturnDate = grnReturnDate;  
        this.createdUser = createdUser;
        this.grnReturnItemsDto = grnReturnItemsDto; 
    }
  }