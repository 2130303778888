import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LogInfoData } from './log-info.dto';


export class SubPlantTransferResponse extends GlobalResponseObject {
    data?: LogInfoData;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //LogInfoData 
     * 
     * */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: LogInfoData) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
