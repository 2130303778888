export class GradesDropDownData{
    gradeId:number;
    gradeName:string;
    
    /**
     * 
     * @param gradeId 
     * @param gradeName 
     */
    constructor(gradeId:number,gradeName:string){
      this.gradeId = gradeId;
      this.gradeName = gradeName;
    }
}