export class SaleOrderIdRequest {
    saleOrderId:number;
    plantId?:number;
    itemId?:number;
    poNumber?:string;
    stockOutType?:string;
    stockType?:string;

    

    constructor(saleOrderId: number, plantId?: number,itemId?:number,poNumber?:string,stockOutType?:string,stockType?:string) {
        this.saleOrderId = saleOrderId;
        this.plantId = plantId;
        this.itemId = itemId;
        this.poNumber = poNumber;
        this.stockOutType = stockOutType;
        this.stockType = stockType;
    }

}