export class DocumentApprovalUpdateRequest {
    saleOrderId:number;
    documentName:string;
    plantId?:number;
    

    constructor(saleOrderId: number,  documentName:string, plantId: number) {
        this.saleOrderId = saleOrderId;
        this.documentName = documentName;
        this.plantId = plantId;
    }

}