export class AllRackZoneMappingDto{
    rackId:number;
    zoneId:number;
    rackName:string;
    zoneName:string;
    endCustomerName:string;
    endCustomerId:number;
    isActive:boolean
    versionFlag:number;
    rackZoneMappingId?:number;
    constructor(rackId:number,zoneId:number,rackName:string, zoneName:string, endCustomerId:number,endCustomerName:string, isActive:boolean,
        versionFlag:number, rackZoneMappingId?:number){
            this.rackId = rackId;
            this.zoneId = zoneId;
            this.rackName = rackName;
            this.zoneName = zoneName;
            this.endCustomerName = endCustomerName;
            this.endCustomerId = endCustomerId;
            this.isActive = isActive
            this.versionFlag = versionFlag;
            this.rackZoneMappingId = rackZoneMappingId;
    }
}