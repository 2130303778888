import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { UnitsRequiredBomInfo } from "./unit-info";

export class RequiredPackingResponse extends GlobalResponseObject{
    data?: UnitsRequiredBomInfo[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: UnitsRequiredBomInfo[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}