import { PackingStatus, TransactionType } from "@gtpl/shared-models/common-models";
import { ForkLiftJobStatus } from "./fork-lift-job-status.enum";
export class updateJobQty {
    jobId: number;
    receivedCartons: number;
    receivedqty: number;
    saleOrderId: number;
    saleOrderItemId: number;
    isDamaged:PackingStatus;
    transactionType?:TransactionType;


    constructor(jobId: number,
        receivedCartons: number,
        receivedqty: number,saleOrderId: number,
        saleOrderItemId: number,isDamaged:PackingStatus,transactionType?:TransactionType){
        this.jobId = jobId;
        this.receivedCartons = receivedCartons;
        this.receivedqty = receivedqty;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.isDamaged = isDamaged;
        this.transactionType = transactionType;
    }
}