import axios from 'axios';
import {DownTimeReasonStatusResponseModel,AllDownTimeReasonResponseModel,DownTimeReasonResponsemodel,DownTimeReasonDto, DownTimeReasonRequest} from '@gtpl/shared-models/masters';
import connection from './connection';

export class DowntimeReasonService{
    URL=connection.DEPLOY_URL + '/downtimereason';

    async createDownTimeReason(downtimereason: DownTimeReasonDto): Promise<DownTimeReasonResponsemodel> {
        return await axios.post(this.URL + '/createDownTimeReason',downtimereason)
            .then(res => {
                return res.data
            })
    }

    async  updateDownTimeReason(downtimereason: DownTimeReasonDto): Promise<DownTimeReasonResponsemodel> {
        return await axios.post(this.URL + '/updateDownTimeReason', downtimereason)
               .then(res => {
                   return res.data
               })
    }

    async getAllDownTimeReason(): Promise<AllDownTimeReasonResponseModel> {
        return await axios.post(this.URL + '/getAllDownTimeReason')
            .then(res => {
                return res.data
            })     
        // return new getAllDownTimeReason(true,123,"successful",[new CountryDto(1,"Japan",true)]);
    }

    async  ActivatedeOrActivateDownTimeReason(downtimereasonDto: DownTimeReasonDto): Promise<DownTimeReasonResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/ActivateordeActivateDownTimeReason', downtimereasonDto)
                  .then(res => {
                      return res.data
                  })
      }

      async  getDownTimeReasonById(downtimerequestRequest: DownTimeReasonRequest): Promise<DownTimeReasonResponsemodel> {
        //   console.log(State.stateCode);
          return await axios.post(this.URL + '/getDownTimeReasonById', downtimerequestRequest)
                  .then(res => {
                      return res.data
                  })
      }

      async  getAllActiveDownTimeReason(): Promise<AllDownTimeReasonResponseModel> {
        return await axios.post(this.URL + '/getAllActiveDownTimeReason')
            .then(res => {
                return res.data
            })
    }

    // async getActiveDownTimeReasonCount(): Promise<DownTimeReasonStatusResponseModel> {
//   return await axios.post(this.URL + '/getActiveDownTimeReasonCount').then(res => {
//       return res.data
//   });
// }

}
