import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Modal,Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Layout, Tabs, Form, InputNumber, TimePicker, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Highcharts, { format } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Link} from 'react-router-dom';
import { Route, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EmployeeRolesEnum, OperationTypeEnum, Series, UnitRequest } from '@gtpl/shared-models/common-models';
import { SoakingAssignmentService, SoakingTubService, WorkstationService } from '@gtpl/shared-services/production';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'

import './soaking-dashboard.css';
import { SoakingRequestDTO, WorkstationDTO, WorkStationsResponseDto } from '@gtpl/shared-models/production-management';
import moment from 'moment';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages' 

/* eslint-disable-next-line */
export interface SoakingTubBulbIntegrationProps {}

export function SoakingTubBulbIntegration(
  props: SoakingTubBulbIntegrationProps
) {

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [object, setObject] = useState(null);
  const [xseries, setXseries] = useState<any[]>([]);
  const [soakingInprogressData, setSoakingInprogressData] = useState<WorkStationsResponseDto[]>([]);
  const [workStationsData, setWorkStationsData] = useState<string[]>([]);
  const [inprogressQty, setInprogressQty] = useState<number[]>([]);
  const [completedQty, setCompletedQty] = useState<number[]>([]);
  const soakingTubService = new SoakingTubService();
  const workstationService = new WorkstationService();
  const [saleId, setSaleId] = useState(0);
  const [defaultTabPane, setDefaultTabPane] = useState<string>("1")
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const {Text} = Typography

  const getMachineInfo = (val) => {
    soakingTubService.getMachineDetails({assertCode:val}).then((res) => {
      if(res.status){
        form.setFieldsValue({'assertId': res.data.assertId});
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          console.log(res.internalMessage)
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    }); 
  }

  const handleQrScan = (lotcode) => {
    setShowQrScan(false)
    console.log(lotcode)
    getMachineInfo(lotcode);
  }
  const onReset = () => {
    form.resetFields();
  };

  const saveData = (req: SoakingRequestDTO) => {
    req.plantId = Number(localStorage.getItem('unit_id'))
    console.log(req)
    soakingTubService.createSoaking(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            console.log(res.internalMessage)
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const calculateEndTime = (val) => {
    console.log(val)
    let endTime = form.getFieldValue('startTime');
    console.log(moment(endTime, "hh:mm:ss A"));
    let time = moment(endTime, "hh:mm:ss A")
        .add(val, 'minutes');
        console.log(time);
    form.setFieldsValue({
      endTime: time
    })

  }


  useEffect(() => {

  }, [])

  return (
  <>
    <Card title={<span style={{ color: 'white'}}>Soaking Tub </span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>

        <Form form={form} layout={'vertical'}  onFinish={saveData}>
            <Row gutter={24}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{paddingTop: '25px'}}>
                <Button htmlType='button' onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{ color: '#1890ff', width: '120px' }} >
                  Scan
                </Button>
              </Col>
              <Col span={4}>
                <Form.Item name="assertId" label = "assert"
                  rules={[
                    {
                      required: true, message: 'Missing assert',
                    },
                  ]} 
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="startTime" label = "Start Time"
                  rules={[
                    {
                      required: true, message: 'Missing Start Time',
                    },
                  ]} initialValue={moment()}
                >
                  <DatePicker format={"YYYY-MM-DD HH:mm:ss"} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="endTime" label = "End Time"
                  rules={[
                    {
                      required: true, message: 'Missing End Time',
                    },
                  ]}
                >
                  <DatePicker format={"YYYY-MM-DD HH:mm:ss"} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={2} >
                  <Form.Item
                    name="duration"
                    label="Dration"
                    initialValue={120}
                    >
                    <Input onChange={e => calculateEndTime(e.target.value)}/>
                  </Form.Item>
              </Col>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                  <Button
                    htmlType="button"
                    style={{ margin: '0 14px' }}
                    onClick={onReset}
                  >
                    Reset
                  </Button>
              </Col>
            </Row>
        </Form>
        <Modal 
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}>
        {
          modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
        }

      </Modal>
   </Card>
  </>
);
}

export default SoakingTubBulbIntegration;
