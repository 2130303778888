import axios from 'axios';

import connection from './connection';
import { AllContainerStatusInfo, BookContainerDto, ConfirmOrAssignSoData, ContainerStatusInfo, DispatchedToPlant, FillSealInfoDto, DraftReceived, ContainerDetailsResponse, ContainerSoIdRequest, EtdUpdateRequest, BillNoUpdateRequest, DocumentsListRequest, DocumentsListResponseModel, SaleOrderRequest, DocumentFileUploadResponse, DocumentsUploadRequest } from '@gtpl/shared-models/logistics';
export class DocumentsListService {
  URL = connection.DEPLOY_URL + '/documents-list';

  
  async createDocumentsList(documentsListRequest:DocumentsListRequest): Promise<DocumentsListResponseModel> {
    return await axios
      .post(this.URL + '/createDocumentsList', documentsListRequest)
      .then((res) => {
        return res.data;
      });
  }
  async getDocumentsListBySoId(saleOrderRequest:SaleOrderRequest): Promise<DocumentsListResponseModel> {
    return await axios
      .post(this.URL + '/getDocumentsList', saleOrderRequest)
      .then((res) => {
        return res.data;
      });
  }
  async getDocumentsDetailsBySoId(saleOrderRequest:SaleOrderRequest): Promise<DocumentsListResponseModel> {
    return await axios
      .post(this.URL + '/getDocumentsDetailsBySoId', saleOrderRequest)
      .then((res) => {
        return res.data;
      });
  }
  async getDocumentsDetailsForSoId(saleOrderRequest:SaleOrderRequest): Promise<DocumentsListResponseModel> {
    return await axios
      .post(this.URL + '/getDocumentsDetailsForSoId', saleOrderRequest)
      .then((res) => {
        return res.data;
      });
  }
  async documentFileUpload(documentsUploadRequest: any): Promise<DocumentFileUploadResponse> {
    return await axios.post(this.URL + '/documentFileUpload', documentsUploadRequest)
        .then(res => {
            return res.data
    })
}
}