export class SaleOrderDropDownDto {
    saleOrderId: number;
    saleOrderNumber: string;
    poNumber?: string;
    status?: string;
    processingUnitId? : number
    jobType? : string;
    saleOrderItemId? : number;



    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     */
    constructor(saleOrderId: number,saleOrderNumber: string,poNumber?:string,status?: string,processingUnitId?:number,jobType? : string,saleOrderItemId? : number){ 
        this.saleOrderId = saleOrderId
        this.saleOrderNumber = saleOrderNumber
        this.poNumber = poNumber
        this.status = status
        this.processingUnitId = processingUnitId
        this.jobType = jobType
        this.saleOrderItemId = saleOrderItemId
        }
    }
