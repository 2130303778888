import { SoStatusEnum } from "@gtpl/shared-models/common-models";

export class SoIdRequest {
    saleOrderId:number;
    plantId?:number;
    status?:SoStatusEnum;

    constructor(saleOrderId: number, plantId?: number,status?:SoStatusEnum) {
        this.saleOrderId = saleOrderId;
        this.plantId = plantId;
        this.status = status;
    }


}