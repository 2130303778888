import { SaleOrderItemsInfo } from './sale-order-items-info.model';
import { SoInvoiceDetailsInfo } from './so-invoice-details.model';
import { SalePoDetailsInfo } from './so-po-details.model';
import { SaleStatusEnum, SoProcessTypesEnum, SoTypesEnum } from '@gtpl/shared-models/common-models';
export class SaleOrderDto {
    filePath:string;
    saleOrderId: number;
    saleOrderNumber: string;
    snspectionId: any;
    lotCode:string;
    status: string;
    soProcessType:SoProcessTypesEnum
    soType:SoTypesEnum
    saleOrderItemsInfo: SaleOrderItemsInfo[];
    soInvDetailsInfo: SoInvoiceDetailsInfo;
    soPoDetailsInfo: SalePoDetailsInfo;
    isActive: boolean;
    createdAt : Date;
    createdUser : string;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    operation? : string;
    subJobCodeListData? : any[];
    // constructor(saleOrderId: number,
    //     saleOrderNumber: string,
    //     inspectionId: number,
    //     status: string,
    //     saleOrderItemsInfo: SaleOrderItemsInfo[],
    //     soInvDetailsInfo: SoInvoiceDetailsInfo,
    //     soPoDetailsInfo: SalePoDetailsInfo,
    //     isActive: boolean,
    //     createdAt : Date,
    //     createdUser : string,
    //     updatedAt : Date,
    //     updatedUser : string,
    //     versionFlag : number,){
    //     this.saleOrderId = saleOrderId;
    //     this.saleOrderNumber = saleOrderNumber;
    //     this.inspectionId = inspectionId;
    //     this.saleOrderItemsInfo = saleOrderItemsInfo;
    //     this.status = status;
    //     this.soInvDetailsInfo = soInvDetailsInfo;
    //     this.soPoDetailsInfo = soPoDetailsInfo;
    //     this.createdAt = createdAt;
    //     this.createdUser = createdUser;
    //     this.updatedAt = updatedAt;
    //     this.updatedUser = updatedUser;
    //     this.isActive = isActive;
    //     this.versionFlag = versionFlag;
    //   }
}

export class SODto {
    saleOrderId: number;
    createdAt : Date;
    createdUser : string;
    customerAccountType: string;
    salePerson: string;
    saleOrderNumber: string;
    poNumber: string;
    customerName:string;
    endCustomerName:string;
    customerAddress: string;
    edaDate: Date;
    edlDate: Date;
    destination: string;
    portOfEntry: number;
    country: string;
    shippingTerms: string;
    deliveryTerms:string;
    paymentTerms:string;
    unit: number;
    subOrder: string;
    brand: string;
    minGrade: string;
    maxGrade: string;
    category: string;
    foodType: string;
    foodStyle: string;
    variety: string;
    Freeze: string;
    itemCase: number;
    itemWeight: string;
    unitOfWeight: string;
    TotalCases: string;
    unitOfCurrency: string;
    pricePerUnit: number;
    netUnits: string;
    netAmount: number;
    totalUnits: string;
    totalWeight: number;
    totalAmount: number;
    packagingDesign:string;
    approved: string;
    weighmentStyle: string;
    bapCertification: string;
    ascCertification: string;
    soakingTime: string;
    soakingTimemin: string;
    specSheetUpload: string;
    glazzing: string;
    inspection: string;
    tuvInspection: string;
    // saleOrderId: number;
    status: string;
    lotCode:string;
    // isActive: boolean;
    // updatedAt : Date;
    // updatedUser : string;
    // versionFlag : number;
    /**
     * 
     * @param saleOrderId 
     * @param createdAt 
     * @param createdUser 
     * @param customerAccountType 
     * @param salePerson 
     * @param saleOrderNumber 
     * @param poNumber 
     * @param customerName 
     * @param endCustomerName 
     * @param customerAddress 
     * @param edaDate 
     * @param edlDate 
     * @param destination 
     * @param portOfEntry 
     * @param country 
     * @param shippingTerms 
     * @param deliveryTerms 
     * @param paymentTerms 
     * @param unit 
     * @param subOrder 
     * @param brand 
     * @param minGrade 
     * @param maxGrade 
     * @param category 
     * @param foodType 
     * @param foodStyle 
     * @param variety 
     * @param Freeze 
     * @param itemCase 
     * @param itemWeight 
     * @param unitOfWeight 
     * @param TotalCases 
     * @param unitOfCurrency 
     * @param pricePerUnit 
     * @param netUnits 
     * @param netAmount 
     * @param totalUnits 
     * @param totalWeight 
     * @param packagingDesign 
     * @param approved 
     * @param weighmentStyle 
     * @param bapCertification 
     * @param ascCertification 
     * @param soakingTime 
     * @param soakingTimemin 
     * @param specSheetUpload 
     * @param glazzing 
     * @param inspection 
     * @param tuvInspection 
     * @param status 
     */
    constructor(
        saleOrderId: number,
        createdAt : Date,
        createdUser : string,
        customerAccountType: string,
        salePerson: string,
        saleOrderNumber: string,
        poNumber: string,
        customerName:string,
        endCustomerName:string,
        customerAddress: string,
        edaDate: Date,
        edlDate: Date,
        destination: string,
        portOfEntry: number,
        country: string,
        shippingTerms: string,
        deliveryTerms:string,
        paymentTerms:string,
        unit: number,
        subOrder: string,
        brand: string,
        minGrade: string,
        maxGrade: string,
        category: string,
        foodType: string,
        foodStyle: string,
        variety: string,
        Freeze: string,
        itemCase: number,
        itemWeight: string,
        unitOfWeight: string,
        TotalCases: string,
        unitOfCurrency: string,
        pricePerUnit: number,
        netUnits: string,
        netAmount: number,
        totalUnits: string,
        totalWeight: number,
        totalAmount: number,
        packagingDesign:string,
        approved: string,
        weighmentStyle: string,
        bapCertification: string,
        ascCertification: string,
        soakingTime: string,
        soakingTimemin: string,
        specSheetUpload: string,
        glazzing: string,
        inspection: string,
        tuvInspection: string,
        status: string,
        // isActive: boolean,
        // updatedAt : Date,
        // updatedUser : string,
        // versionFlag : number,
        ){
           this.saleOrderId= saleOrderId;
           this.createdAt = createdAt;
           this.createdUser = createdUser;
           this.customerAccountType= customerAccountType;
           this.salePerson= salePerson;
           this.saleOrderNumber= saleOrderNumber;
           this.poNumber= poNumber;
           this.customerName=customerName;
           this.endCustomerName=endCustomerName;
           this.customerAddress= customerAddress;
           this.edaDate= edaDate;
           this.edlDate= edlDate;
           this.destination= destination;
           this.portOfEntry= portOfEntry;
           this.country= country;
           this.shippingTerms= shippingTerms;
           this.deliveryTerms=deliveryTerms;
           this.paymentTerms=paymentTerms;
           this.unit= unit;
           this.subOrder= subOrder;
           this.brand= brand;
           this.minGrade= minGrade;
           this.maxGrade= maxGrade;
           this.category= category;
           this.foodType= foodType;
           this.foodStyle= foodStyle;
           this.variety= variety;
           this.Freeze= Freeze;
           this.itemCase= itemCase;
           this.itemWeight= itemWeight;
           this.unitOfWeight= unitOfWeight;
           this.TotalCases= TotalCases;
           this.unitOfCurrency= unitOfCurrency;
           this.pricePerUnit= pricePerUnit;
            this.netUnits= netUnits;
            this.netAmount= netAmount;
            this.totalUnits= totalUnits;
            this.totalWeight= totalWeight;
            this.totalAmount = totalAmount;
            this.packagingDesign=packagingDesign;
            this.approved= approved;
            this.weighmentStyle= weighmentStyle;
            this.bapCertification= bapCertification;
            this.ascCertification= ascCertification;
            this.soakingTime= soakingTime;
            this.soakingTimemin= soakingTimemin;
            this.specSheetUpload= specSheetUpload;
            this.glazzing= glazzing;
            this.inspection= inspection;
            this.tuvInspection= tuvInspection;
            // saleOrderId= number;
            this.status= status;
            // isActive= boolean;
            // updatedAt = Date;
            // updatedUser = string;
            // versionFlag = number;
      }

}