import { ApiProperty } from '@nestjs/swagger';
import { SoItemsDataModel } from './so-items.model';

export class SoDataModel {
  saleOrderId: number;
  totalQuantity: number;
  totalBasicPrice: number;
  totalDiscount: number;
  totalTax: number;
  totalCost: number;
  soCreationItemsInfo:SoItemsDataModel[];

  /**
   * @param saleOrderId
  * @param vendor
  * @param totalQuantity
  * @param totalBasicPrice
  * @param totalDiscount
  * @param totalTax
  * @param totalCost
  * @param SoItemsDataModel
   */
  constructor(saleOrderId: number,totalQuantity: number,totalBasicPrice: number,totalDiscount: number,totalTax: number,totalCost: number,soCreationItemsInfo:SoItemsDataModel[]){
    this.saleOrderId = saleOrderId;
    this.totalQuantity = totalQuantity;
    this.totalBasicPrice = totalBasicPrice;
    this.totalDiscount = totalDiscount;
    this.totalTax = totalTax;
    this.totalCost = totalCost;
    this.soCreationItemsInfo =soCreationItemsInfo;
  }
}

