import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { GrnDetailViewModel } from './grn-detail-view.model';


export class GrnDetailViewResponseModel extends GlobalResponseObject {
    data?: GrnDetailViewModel;
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: GrnDetailViewModel){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

