import React, { useEffect, useRef, useState } from 'react';
import { StoreConsumptionService } from '@gtpl/shared-services/procurement';
import './store-consumption-grid.css';
import { GetStoreConsumptionItemsDto, StoreConsumptionDetails, StoreConsumptionItemsDto, StoreConsumptionStatus } from '@gtpl/shared-models/procurement-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Input, Button, Divider, Popconfirm, Switch, Tag, Card, Col, Drawer, Row, Tabs, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, SnippetsOutlined, ArrowRightOutlined, PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import Table, { ColumnProps } from 'antd/lib/table';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import StoreConsumptions from './store-consumption-detail-view';
import { EmployeeRolesEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import PlaceList from './place-list';
const { TabPane } = Tabs;
import QRCode from 'qrcode';


/* eslint-disable-next-line */
export interface StoreConsumptionGridProps { }

export function StoreConsumptionGrid(
  props: StoreConsumptionGridProps
) {

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [redirectToReturn, setRedirectToReturn] = useState(false);
  const [returnData, setReturnData] = useState(undefined);
  const [storeConsumptionData, setStoreConsumptionData] = useState<StoreConsumptionDetails[]>([]);
  const [cancelledSoConsumptions, setCancelledSoConsumptions] = useState<StoreConsumptionDetails[]>([]);
  const [revisedSoConsumptions, setRevisedSoConsumptions] = useState<StoreConsumptionDetails[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modelType, setModelType] = useState<string>('');
  const [storeIssueData, setStoreIssueData] = useState<any>(undefined);
  const [storeConsumptionId, setStoreConsumptionId] = useState<number>(0);
  const [placeListData, setPlaceListData] = useState<any[]>([]);
  const [saleOrderId, setSaleOrderId] = useState(undefined)
  const storeConsumptionService = new StoreConsumptionService();

  const [printData, setPrintData] = useState<any[]>([])
  const [imageUrl, setImageUrl] = useState('')
  const [showQRModal, setShowQRModal] = useState('')
  const [rowData, setRowData] = useState<any>()
  const [visible , setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);




  useEffect(() => {
    getAllStoreConsumptionDetails();
    getAllCancelledSosStoreConsumptions();
    getAllRevisedSosStoreConsumptions();
  }, []);

  const generateQR = async (record) => {
    try {
      const response = await QRCode.toDataURL(record.storeConsumptionCode);
      console.log(record, 'res')
      setImageUrl(response);
      setIsModalVisible(true);
      setShowQRModal('printQr');
      setRowData(record)
    } catch (error) {
      console.log(error);
    }
  }

  const printOrder = () => {
    const divContents = document.getElementById('printQrCode').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  }
  


  const getAllStoreConsumptionDetails = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getAllStoreConsumptionDetails(req).then(res => {
      console.log(res.data);
      if (res.status) {
        setStoreConsumptionData(res.data);
      } else {
        if (res.intlCode) {
          setStoreConsumptionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setStoreConsumptionData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllCancelledSosStoreConsumptions = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getAllCancelledSosStoreConsumptions(req).then(res => {
      console.log(res.data);
      if (res.status) {
        setCancelledSoConsumptions(res.data);
      } else {
        if (res.intlCode) {
          setCancelledSoConsumptions([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCancelledSoConsumptions([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllRevisedSosStoreConsumptions = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    storeConsumptionService.getAllRevisedSosStoreConsumptions(req).then(res => {
      if (res.status) {
        setRevisedSoConsumptions(res.data);
      } else {
        if (res.intlCode) {
          setRevisedSoConsumptions([]);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRevisedSoConsumptions([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  //TO open the form for updation
  const openFormWithData = (viewData:any) => {
    console.log(viewData)
    viewData.operation = 'Edit'
    setStoreIssueData(viewData);
  }
  const gotToStoreIssuesDetailView = (rowData: { storeConsumptionId: number; }) => {
    setStoreConsumptionId(rowData.storeConsumptionId);
    console.log(storeConsumptionId);
  }
  const getPlaceListData = (req: StoreConsumptionDetails) => {
    storeConsumptionService.getPlaceListData(req).then(res => {
      if (res.status) {
        setPlaceListData(res.data);
        setTimeout(() => {
          this.setState({ loading: false, visible: false });
      }, 3000);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }
  const placeListPrint = (value) => {
    console.log(value)

    setIsModalVisible(true);
    setModelType('place-list-print')
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      align: 'left',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unitId',
      width: 180,
      filters: [
        {
          text: 'Unit 1',
          value: 'Unit 1',
        },
        {
          text: 'Unit 2',
          value: 'Unit 2',
        },
        {
          text: 'Kakinada',
          value: 'Kakinada',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.unitId == value;
      },
    },
    {
      title: 'Store Consumption Code',
      dataIndex: 'storeConsumptionCode',
      width: 180,
      align: 'left',
      sorter: (a, b) => a.storeConsumptionCode.localeCompare(b.storeConsumptionCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('storeConsumptionCode')
    },
    {
      title: 'SO Item',
      dataIndex: 'soleOrderItem',
      align: 'left',
      width: 350,
      sorter: (a, b) => a.soleOrderItem.localeCompare(b.soleOrderItem),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soleOrderItem')
    },
    {
      title: 'Item',
      dataIndex: "itemName",
      width: 150,
      align: 'left',
      ...getColumnSearchProps('itemName'),
      render: (value, record) => {
        return record.itemName ? record.itemName : '-'
      }
    },
    {
      title: 'Customer',
      dataIndex: 'endCustomerName',
      align: 'left',
      width: 200,
      sorter: (a, b) => a.endCustomerName.localeCompare(b.endCustomerName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomerName')
    },

    {
      title: 'Consumed Date',
      dataIndex: 'consumedDate',
      width: 150,
      align: 'left',
      sorter: (a, b) => moment(a.consumedDate).unix() - moment(b.consumedDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.consumedDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      align: 'left',
      width: 150,
      sorter: (a, b) => moment(a.indentDate).unix() - moment(b.indentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => { return moment(record.indentDate).format('YYYY-MM-DD') },
    },
    {
      title: 'Indent Number',
      dataIndex: 'indentNo',
      width: 140,
      align: 'left',
      sorter: (a, b) => a.indentNo.localeCompare(b.indentNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('indentNo')
    },
    {
      title: 'Consumed Quantity',
      dataIndex: 'quantity',
      width: 150,
      align: 'left',
      render: (value) => {
        return Math.round(value);
      }
    },
    // {
    //   title: 'Place List Generation',
    //   dataIndex: 'generate',
    //   render: (value, record) => {
    //     console.log(record)
    //     return rowData?.placeListItemId != null ?<Tag style={{backgroundColor:'greenyellow'}}>Place List Generated</Tag>:<Button color='green' type="primary" onClick={() => getPlaceListData(rowData)} icon={<ArrowRightOutlined />}>Generate</Button>
    //   }
    // },
    // {
    //   title: 'Print',
    //   dataIndex: 'print',
    //   render: (text, rowData, index) => <Button color='green' type="primary" onClick={() => { setIsModalVisible(true); setStoreConsumptionId(rowData.storeConsumptionId); }} icon={<PrinterOutlined />}>Print</Button>
    // },
    // {
    //   title: 'QR Code',
    //   dataIndex: 'generateQr',
    //   render: (value, record) => {
    //     return <>
    //       <QrcodeOutlined onClick={(e) => { generateQR(record) }} style={{ color: '#1890ff', fontSize: '30px' }} />
    //     </>
    //   }
    // },
    // {
    //   title: 'Bar Code',
    //   dataIndex: 'barCode',
    //   width: 250,
    //   align: 'left',
    //   render: (value, record) => {
    //     return record.barCode ? record.barCode : '-'
    //   }
    // }
    // {
    //   title:`Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>         
    //         <EditOutlined  className={'editSamplTypeIcon'}  type="edit" 
    //           onClick={() => {
    //             // if (rowData.isActive) {
    //               openFormWithData(rowData);
    //             // } 
    //           }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         />
          
    //     </span>
    //   )
    // }

  ];

  const cancelledAction: ColumnProps<any>[] = [
    {
      title: 'Action',
      key: 'action',
      render: (text, rowData, index) => <Button type="primary" onClick={() => storeReturn(rowData)} danger icon={<SnippetsOutlined />}>Return to Bulk</Button>
    },
  ];

  const storeReturn = (rowData) => {
    getStoreItems(rowData.saleOrderId, rowData.storeConsumptionId);
  }

  const getStoreItems = (saleOrderId, storeConsumptionId) => {
    storeConsumptionService.getStoreConsumptionItemsById({ saleOrderId: 0, storeConsumptionId: storeConsumptionId }).then(res => {
      if (res.status) {
        const itemId = res.data[0].itemId;
        setReturnData({ itemId, saleOrderId })
        setRedirectToReturn(true);
      } else {
      }
    }).catch(err => {
    })
  }
  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("TESTING");
  }

  // const renderItems = (record: GetStoreConsumptionItemsDto, index, indent, expanded) => {
  //   return <StoreConsumptions storeConsumptionId={record.storeConsumptionId} />
  // }

  return (
    <>
    {(storeIssueData) ? <Redirect
            to={{
              pathname: "/store-issues-form",
              state: storeIssueData
            }}
          /> : null}
      {(redirectToReturn) ? <Redirect to={{ pathname: "/store-return-form", state: { returnData } }} /> : null}
      <Card title={<span style={{ color: 'white' }}>Store Issues</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={((JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.HO_PACKAGING_MANAGER) ? <Link to='/store-issues-form' ><Button className='panel_button' >Create </Button></Link> : <></>}

      >
        <br></br>
        <Row gutter={24} >
          <Col>
            <Card title={'TOTAL STORE ISSUES: ' + storeConsumptionData.length} style={{ textAlign: 'left', width: 250, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          {/* <Col>
            <Card title={StoreConsumptionStatus.Open + ' :' + (storeConsumptionData.filter(el => el.status == StoreConsumptionStatus.Open)).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f6e552' }}></Card>
          </Col> */}
          <Col>
            <Card title={StoreConsumptionStatus.Partially_Returned + ' :' + (storeConsumptionData.filter(el => el.status == StoreConsumptionStatus.Partially_Returned)).length} style={{ textAlign: 'left', height: 41, backgroundColor: '#f2a637' }}></Card>
          </Col>
          <Col>
            <Card title={StoreConsumptionStatus.Returned + ' :' + (storeConsumptionData.filter(el => el.status == StoreConsumptionStatus.Returned)).length} style={{ textAlign: 'left', height: 41, backgroundColor: 'rgb(135, 208, 104)' }}></Card>
          </Col>
        </Row>
        <br></br>

        <Tabs type={'card'} tabPosition={'top'}>
          <TabPane
            key="1"
            tab={<span style={{ color: "#f5222d" }}>{"Consumptions :" + storeConsumptionData.length}</span>}
          >
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={columnsSkelton}
              dataSource={storeConsumptionData}
              // expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered

            >
            </Table>
          </TabPane>
          <TabPane
            key="2"
            tab={<span style={{ color: "#f5222d" }}>{"Cancelled SO's :" + cancelledSoConsumptions.length}</span>}
          >
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={[...columnsSkelton, ...cancelledAction]}
              dataSource={cancelledSoConsumptions}
              // expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered

            >
            </Table>
          </TabPane>
          <TabPane
            key="3"
            tab={<span style={{ color: "#f5222d" }}>{"Revised SO's :" + revisedSoConsumptions.length}</span>}
          >
            <Table
              rowKey={record => record.storeConsumptionId}
              columns={[...columnsSkelton, ...cancelledAction]}
              dataSource={revisedSoConsumptions}
              // expandedRowRender={renderItems}
              expandIconColumnIndex={8}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              scroll={{ x: 500 }}
              size="large"
              bordered
            >
            </Table>
          </TabPane>
        </Tabs>
        <>
          <Modal
            className='print-docket-modal'
            key={'modal' + Date.now()}
            width={showQRModal ? '30%' : '80%'}
            style={{ top: 30, alignContent: 'right' }}
            visible={isModalVisible}
            title={<React.Fragment>
            </React.Fragment>}
            onCancel={handleCancel}
            footer={[
            ]}
          >
            {
              showQRModal == 'printQr' ?
                <>
                  {imageUrl ? (
                    <html>
                      <head>
                      </head>
                      <body id='printQrCode'>
                        <Button onClick={printOrder} className='noprintqr' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button>
                        <div className="qr-ticket">
                          <h3 className="centered">SANDHYA AQUA </h3>
                          <a href={imageUrl} download>
                            <img src={imageUrl} alt="img" style={{ width: '100%' }} />
                          </a>
                          {/* <p className="centered">{rowData ? rowData?.saleOrder : ''}
                          </p> */}
                          <p style={{ textAlign: 'center' }}><b>Details</b></p>
                          <p style={{ textAlign: 'left' }}><b>Sale Order :</b> {rowData ? rowData.saleOrderNo : '-'}</p>
                          <p style={{ textAlign: 'left' }}><b>Product :</b> {rowData ? rowData.soItem : '-'}</p>
                          <p style={{ textAlign: 'left' }}><b>Item :</b> {rowData ? rowData.itemName : '-'}</p>

                        </div>
                      </body>
                    </html>
                  )
                    : null}

                </> :          
                  <PlaceList storeConsumptionId={storeConsumptionId} setModalVisible={handleCancel} />

                
            }
          </Modal></>
          
      </Card>
    </>
  );
}

export default StoreConsumptionGrid;
