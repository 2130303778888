
export class ContractRequest{

    contractorId: number;
    updatedUser: string;
    versionFlag: number;
    isActive: boolean;

/**
 * 
 * @param contractorId 
 * @param updatedUser 
 * @param versionFlag 
 * @param isActive 
 */
    constructor(contractorId: number,updatedUser: string,versionFlag: number,isActive: boolean){
        this.contractorId = contractorId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isActive = isActive;
    }
}