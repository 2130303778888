import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { WorkStatusDto } from './work-status.dto';

export class DeheadingValAdditionWorkStatusResponse extends GlobalResponseObject{
    data?: WorkStatusDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: WorkStatusDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
