export class AssetServiceChecklistDTO {
    checklist: string
    checklistStatus: string;
    createdUser: string;
    assetServiceCheckListId: number;
    constructor(
        checklist: string,
        checklistStatus: string,
        createdUser: string,
        assetServiceCheckListId?: number,
    ) {
        this.assetServiceCheckListId = assetServiceCheckListId;
        this.checklist = checklist;
        this.checklistStatus = checklistStatus;
        this.createdUser = createdUser;
    }
}