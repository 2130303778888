import { CommonResponse } from "@gtpl/shared-models/masters";
import { DeheadingSampleJobResponse, LogIdRequest } from "@gtpl/shared-models/production-management";
import axios from "axios";
import connection from './connection';
export class DeheadingSampleService{
    URL = connection.DEPLOY_URL + '/deheading-sample';

    async CreateSampleInfo(formInfo):Promise<CommonResponse>{
        return await axios.post(this.URL + '/CreateSampleInfo', formInfo)
            .then(res => {
                return res.data
            })   
    }

    async getSamplesInfoByLogId(req:LogIdRequest):Promise<DeheadingSampleJobResponse>{
        return await axios.post(this.URL + '/getSamplesInfoByLogId', req)
            .then(res => {
                return res.data
            })   
    }
}