import React, {useEffect, useRef, useState} from 'react';
import {Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Descriptions, Drawer, Typography, Empty} from 'antd';
import {ExternalPlantInfo, SubPlantValAddDto} from '@gtpl/shared-models/production-management';
import {ProductionInventoryService, SubPlantValAddService} from '@gtpl/shared-services/production';
import {CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {ColumnProps} from 'antd/lib/table';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {Link} from 'react-router-dom';
import './sub-plant-val-additions-report.css';
import { OperationTypeEnum, SubPlantOperationStatusEnum } from '@gtpl/shared-models/common-models';
import { UnitRequest } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface SubPlantValAdditionsReportProps {}

export function SubPlantValAdditionsReport(
  props: SubPlantValAdditionsReportProps
) {
  const searchInput = useRef(null);
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false); 
  
  const [valAdditionData, setValAdditionData] = useState<ExternalPlantInfo[]>([]);
  const valAdditonService = new SubPlantValAddService();
  const inventoryService = new ProductionInventoryService();


  useEffect(() => {
    getAllValAdditions();
  },[]);


  const getAllValAdditions = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')))

    inventoryService.getAllSubPlantValAdditions(req).then(res => {
      if(res.status){
        setValAdditionData(res.data);
      }else{
        if(res.intlCode){
          setValAdditionData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }else{
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(error => {
      setValAdditionData([]);
      AlertMessages.getErrorMessage(error.message);
    })
  }

  const deleteValAddition = (valAdditionData: SubPlantValAddDto) => {

  }
  
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
   function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    //drawer related
  const closeDrawer=()=>{
      setDrawerVisible(false);
  }
  
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page-1) * 10 +(index+1)
    },
    {
      title: 'Plant',
      dataIndex: 'subPlant',
      // responsive: ['lg'],
      sorter: (a, b) => a.subPlant.localeCompare(b.subPlant),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('subPlant')
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      // responsive: ['lg'],
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('operation')
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    
    {
      title: 'SO Number',
      dataIndex: 'saleOrder',
      // responsive: ['lg'],
      sorter: (a, b) => a.saleOrder.localeCompare(b.saleOrder),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('saleOrder')
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      ...getColumnSearchProps('grade'),
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      // responsive: ['lg'],
     
    },
    {
      title: 'Boxes',
      dataIndex: 'boxes',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      //responsive: ['lg'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render:(value) =>{
        return <div style={{textAlign:'right'}}>{value}</div>
      }
      // responsive: ['lg'],
    },
    {
      title: 'Status',
      dataIndex: 'subPlantOpStatus',
      render: (subPlantOpStatus, rowData) => (
        <>
          {subPlantOpStatus==SubPlantOperationStatusEnum.OPEN?<Tag  style={{backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>{SubPlantOperationStatusEnum.OPEN}</Tag> : subPlantOpStatus==SubPlantOperationStatusEnum.IN_TRANSIT?<Tag  style={{backgroundColor:'#f2a637',color:"black", fontWeight: 'bold' }}>{SubPlantOperationStatusEnum.IN_TRANSIT}</Tag>:subPlantOpStatus==SubPlantOperationStatusEnum.RECEIVED?<Tag  style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}>{SubPlantOperationStatusEnum.RECEIVED}</Tag>:""}
          
        </>
      ),
      sorter: (a, b) => a.subPlantOpstatus - (b.subPlantOpstatus),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'OPEN',
          value: 'OPEN',
        },
        {
          text: 'INTRANSIT',
          value: 'INTRANSIT',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => 
      {
        // === is not work
        return record.subPlantOpstatus === value;
      },
      
    },
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
   const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card title={<span style={{color:'white'}}>Sub Plant Operations History</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
    >
      <Row gutter={24} >
        <Col>
        <Card title={'DEHEADING QUANTITY: ' + (valAdditionData.filter(el => el.operation == OperationTypeEnum.BE_HEADING)).reduce((sum, data) => Number(sum)+Number(data.quantity),0) } style={{textAlign: 'left', width: 280, height: 41,backgroundColor:'#bfbfbf'}}></Card>
        </Col>
        <Col style={{marginLeft:'2%'}}>
            <Card title={'VAL ADDITION QUANTITY : ' + (valAdditionData.filter(el => el.operation == OperationTypeEnum.VALUE_ADDITION)).reduce((sum, data) => Number(sum)+Number(data.quantity),0)} style={{textAlign: 'left', width: 300, height: 41,backgroundColor:'#f2a637'}}></Card>
        </Col>
      </Row>
       <br/><br/> 
       {valAdditionData.length?<Table
          rowKey={record => record.deptId}
          columns={columnsSkelton}
          dataSource={valAdditionData}
          scroll={{ y: 500 }}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          bordered
          summary={(pageData) => {
            let totalQuantity = 0;
          
            pageData.forEach(({ quantity }) => {
              totalQuantity += Number(quantity);
              
            });

            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={2} colSpan={7} ><Text >Total</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}><Text  style={{textAlign:'end'}}>{totalQuantity}</Text></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} ><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
                </Table.Summary.Row>
                
              </>
            );
          }
         } />:<Empty/>}
          
    </Card>
  );
}

export default SubPlantValAdditionsReport;
