import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { EmployeeDataModel } from "./employee-data.model";


export class EmployeeDataModelResponse extends GlobalResponseObject {
    data?: EmployeeDataModel[];
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: EmployeeDataModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}