import { AllRacksResponseModel, CommonResponse, LocationRequestDto, RackDropDownDto, RackDropDownResponseModel, RackDto, RackResponseModel, RacksForRackId, RacksForZoneId, ReserveRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';

export class RackService {
    URL = connection.DEPLOY_URL + '/racks';

    async createRack(rackDto: RackDto): Promise<RackResponseModel> {
        return await axios.post(this.URL + '/createRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async updateRack(rackDto: RackDto): Promise<RackResponseModel> {
        return await axios.post(this.URL + '/updateRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async getAllRacks(): Promise<AllRacksResponseModel> {
        return await axios.post(this.URL + '/getAllRacks')
            .then(res => {
                return res.data
            })
    }
    async ActivateorDeactivateRack(rackDto: RackDto): Promise<RackResponseModel> {
        return await axios.post(this.URL + '/activateorDeactivateRack', rackDto)
            .then(res => {
                return res.data
            })
    }
    async getRacksForZoneId(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

        return await axios.post(this.URL + '/getRacksForZoneId', zoneId)
            .then(res => {
                return res.data
            })
    }

    async getRacksForZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

        return await axios.post(this.URL + '/getRacksForZoneId', zoneId)
            .then(res => {
                return res.data
            })
    }
    async getRacksByZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

        return await axios.post(this.URL + '/getRacksForZone', zoneId)
            .then(res => {
                return res.data
            })
    }
    async getdummyRacksByZone(zoneId: RacksForZoneId): Promise<RackDropDownResponseModel> {

        return await axios.post(this.URL + '/getdummyRacksByZone', zoneId)
            .then(res => {
                return res.data
            })
    }
    async getRacksForRackId(racksForRackId: RacksForRackId): Promise<RackDropDownDto> {
        return await axios.post(this.URL + '/getRacksForRackId', racksForRackId)
            .then(res => {
                console.log(res.data);
                return res.data
            })
    }

    async getRackPositionsCount(req:ReserveRequest): Promise<any> {
        return await axios.post('http://206.189.138.212/erp_php/post_gres.php?plant_id='+req.plantId+'&positions='+req.positions)
            .then(res => {
                console.log(res);
                return res.data 
            })     
      }
    async getRereserveRackPositions(req:ReserveRequest): Promise<any> {
        return await axios.post('http://206.189.138.212/erp_php/post_gres_local.php?plant_id='+req.plantId+'&positions='+req.positions+'&saleOrderId='+req.saleOrderId)
            .then(res => {
                console.log(res);
                return res.data 
            })     
      }

    async transferRack(rackDetails:any): Promise<CommonResponse> {
        return await axios.post(this.URL + '/transferRack', rackDetails)
            .then(res => {
                console.log(res);
                return res.data 
            })
    }
    
}