
export class AssetRaciReport {
    assetId? : number;
    employeeId?: number;
    ownerOfAsset?: string;
    assetSupplier?: string;
    reportingManager?: string;
    plant?: number;
    location?:string;
    assetCategory?: string;
    assetType?:string;
    status?:string;
    constructor(
        assetId ?:number,
        employeeId?: number,
        ownerOfAsset?: string,
        assetSupplier?: string,
        reportingManager?: string,
        plant?: number,
        location?:string,
        assetCategory?: string,
        assetType?:string,
        status?:string,

    ) {
        this.assetId = assetId;
        this.employeeId = employeeId;
        this.ownerOfAsset = ownerOfAsset;
        this.assetSupplier = assetSupplier;
        this.reportingManager = reportingManager;
        this.plant = plant;
        this.location = location;
        this.assetCategory = assetCategory;
        this. assetType =assetType;
        this.status = status;
    }
}