import axios from 'axios';
import connection from './connection';
import {EndCustomerDto} from '@gtpl/shared-models/masters'
import {EndCustomerResponseModel} from '@gtpl/shared-models/masters'
import {AllEndCustomersResponseModel} from '@gtpl/shared-models/masters'
export class EndCustomerService{
    URL = connection.DEPLOY_URL + '/end-customer';


    async create(dto: EndCustomerDto): Promise<EndCustomerResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createEndCustomer',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: EndCustomerDto): Promise<EndCustomerResponseModel> {
    return await axios.post(this.URL + '/updateEndCustomer', dto)
                    .then(res => {
                        return res.data
                    })
            }


 async  activatedeActivate(Dto: EndCustomerDto): Promise<EndCustomerResponseModel> {
                //   console.log(State.stateCode);
     return await axios.post(this.URL + '/activateOrDeactivateEndCustomer', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(): Promise<AllEndCustomersResponseModel> {
                return await axios.post(this.URL + '/getAllEndCustomer')
                        .then(res => {
                            return res.data
                        })
               
              
            }
           

}