import axios from 'axios';
import {EmployeeDto,AllEmployeesResponseModel,EmployeeResponseModel,employeeUpdateRequest, EmployeesStatusWiseResponseModel} from '@gtpl/shared-models/gtpl';
import connection from './connection';
import { EmployeeCreateResponse,EmployeeRequest,GetEmployeeResponse} from './employee-model';
export class EmployeeService {
  URL = connection.DEPLOY_URL + '/employee-details';
  userid = 4;
  role='L4';
  async getAllEmployees(): Promise<AllEmployeesResponseModel> {
      return await axios.post(this.URL + '/getAllEmployees',null,
      {headers: {"userid" : this.userid,"role":this.role}}
        )
          .then(res => {
              return res.data
          })
  }

  async  createEmployee(Employee: EmployeeDto): Promise<EmployeeResponseModel> {
      return await axios.post(this.URL + '/createEmployee', Employee,
      {headers: {"userid" : this.userid,"role":this.role}}
      )
              .then(res => {
                  return res.data
              })
  }
  async  ActivatedeActivateEmployee(employeeDto: EmployeeDto): Promise<EmployeeResponseModel> {
    //   console.log(State.stateCode);
      return await axios.post(this.URL + '/updateEmployeeStatus', employeeDto,
      {headers: {"userid" : this.userid,"role":this.role}}
        )
              .then(res => {
                  return res.data
              })
  }
  async  updateEmployee(Employee: EmployeeDto): Promise<EmployeeResponseModel> {
       return await axios.post(this.URL + '/updateEmployee', Employee,
        {headers: {"userid" : this.userid,"role":this.role}}
        )
              .then(res => {
                  return res.data
              })
  }
  //   async getTerminatedEmployees(): Promise<AllEmployeesResponseModel> {
//     return await axios.post(this.URL + '/getTerminatedEmployees')
//         .then(res => {
//             return res.data
//         })
//     }
  
  async  getActiveEmployees(): Promise<AllEmployeesResponseModel> {
      
      return await axios.post(this.URL + '/getActiveEmployees')
          .then(res => {
              return res.data
          })
  }
 
//   async  getActiveEmpCount(): Promise<EmployeesStatusWiseResponseModel> {
//       return await axios.post(this.URL + '/getActiveEmpCount')
//           .then(res => {
//               return res.data
//           })
//   }

//   async  getTerminationCount(): Promise<EmployeesStatusWiseResponseModel> {
//     return await axios.post(this.URL + '/getTerminationCount')
//         .then(res => {
//             return res.data
//         })
// }
// async  getTerminationApprovalCount(): Promise<EmployeesStatusWiseResponseModel> {
//     return await axios.post(this.URL + '/getTerminationApprovalCount')
//         .then(res => {
//             return res.data
//         })
// } 
//   async  getEmployee(Employee: EmployeeRequest): Promise<EmployeeResponseModel> {
//       return await axios.post(this.URL + '/getEmployeeByEmployeeCode', Employee)
//               .then(res => {
//                   return res.data
//               })
//   }

//   async getAllActiveEmployeeNames(): Promise<AllEmployeesResponseModel> {
//       return await axios.post(this.URL + '/getAllActiveEmployeeNames').then(res => {
//           return res.data
//       });
//   }
//   async terminateEmployee(employeeDto: EmployeeDto): Promise<EmployeeResponseModel> {
//     //   console.log(State.stateCode);
//       return await axios.post(this.URL + '/terminateEmployee', employeeDto, 
//       {headers: {"userid" : this.userid,"role":this.role}}
//       )
//               .then(res => {
//                   return res.data
//               })
//   }
//   async approveEmployeeTermination(employeeDto: EmployeeDto): Promise<EmployeeResponseModel> {
//     //   console.log(State.stateCode);
//       return await axios.post(this.URL + '/approveEmployeeTermination', employeeDto,
//       {headers: {"userid" : this.userid,"role":this.role}}
//       )
//               .then(res => {
//                   return res.data
//               })
//   }
  
}

export default EmployeeService