import { OperationTypeEnum } from "@gtpl/shared-models/common-models";

export class OperationSequenceDto {
    operationId: number;
    previousOperation: string;
    nextOperation: OperationTypeEnum;

    /**
     * 
     * @param operationId 
     * @param previousOperation 
     * @param nextOperation 
    
     */

    constructor(
        operationId: number,previousOperation: string,nextOperation: OperationTypeEnum) {
        this.operationId = operationId;
        this.previousOperation = previousOperation;
        this.nextOperation = nextOperation;
    }
}

