import { GlobalStatus, JobCompletedEnum, OperationTypeEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";


export class FreezingDto{
    productionInventoryId:number;
    lotNumber: string;
    subLotNumber: string;
    saleOrderId: number;
    soNumber: string;
    saleOrderItemId?: number;
    operation: OperationTypeEnum;
    nextOperation: OperationTypeEnum;
    physicalQuantity: number;
    issuedQuantity: number;
    intransitQuantity?: number;
    wipQuantity?: number;
    wastageQuantity?: number;
    rejectedQuantity?: number;
    inputGrade: number;
    outputGrade: number;
    boxes: number;
    ipBoxes: number;
    opBoxes: number;
    shift: ShiftsEnum;
    workStationType: WorkStationTypeEnum;
    workstation: number;
    weighmentReq: GlobalStatus;
    plantId?: number;
    assignedTime?: Date;
    subPlantId?: number;
    createdUser: string;
    updatedUser: string;

    constructor(
        productionInventoryId:number,
        lotNumber: string,
        subLotNumber: string,
        saleOrderId: number,
        soNumber: string,
        operation: OperationTypeEnum,
        nextOperation: OperationTypeEnum,
        physicalQuantity: number,
        issuedQuantity: number,
        inputGrade: number,
        outputGrade: number,
        boxes: number,
        ipBoxes: number,
        opBoxes: number,
        shift: ShiftsEnum,
        workStationType: WorkStationTypeEnum,
        workstation: number,
        weighmentReq: GlobalStatus,
        createdUser: string,
        updatedUser: string,
        saleOrderItemId?: number,
        intransitQuantity?: number,
        wipQuantity?: number,
        wastageQuantity?: number,
        rejectedQuantity?: number,
        plantId?: number,
        assignedTime?: Date,
        subPlantId?: number){
            this.productionInventoryId =productionInventoryId,
            this.lotNumber= lotNumber,
            this.subLotNumber= subLotNumber,
            this.saleOrderId= saleOrderId,
            this.soNumber = soNumber;
            this.operation= operation,
            this.nextOperation= nextOperation,
            this.physicalQuantity= physicalQuantity,
            this.issuedQuantity= issuedQuantity,
            this.inputGrade= inputGrade,
            this.outputGrade= outputGrade,
            this.boxes= boxes,
            this.ipBoxes= ipBoxes,
            this.opBoxes= opBoxes,
            this.shift= shift,
            this.workStationType= workStationType,
            this.workstation= workstation,
            this.weighmentReq= weighmentReq,
            this.createdUser= createdUser,
            this.updatedUser= updatedUser,
            this.saleOrderItemId= saleOrderItemId,
            this.intransitQuantity= intransitQuantity,
            this.wipQuantity= wipQuantity,
            this.wastageQuantity= wastageQuantity,
            this.rejectedQuantity= rejectedQuantity,
            this.plantId= plantId,
            this.assignedTime= assignedTime,
            this.subPlantId= subPlantId
    }
}