import axios from 'axios';
import {SubPlantValAddDto, SubPlantValAddResponseModel} from '@gtpl/shared-models/production-management';
import connection from './connection';

export class SubPlantValAddService{
    URL = connection.DEPLOY_URL + '/sub-plant-val-additions';

    async getAllSubPlantValAdditions():Promise<SubPlantValAddResponseModel>{
          const data = [
           {
            subplantId: 1,
            subplant: 'Kadiyam',
            operationName: 'Beheading',
            lotNo: 'Lot001',
            quantity: 5000,
            boxes: 10,
            soNumber: 'SO001',
            grade: 33,
            status: 'OPEN'
           },
           {
            subplantId: 2,
            subplant: 'Machilipatnam',
            operationName: 'Val Addition',
            lotNo: 'Lot002',
            quantity: 3000,
            boxes: 30,
            soNumber: 'SO001',
            grade: 33,
            status: 'INTRANSIT'
           },
           ];
        
          return new SubPlantValAddResponseModel(true, 100234, 'Data Retrieved', data)
          // return await axios.post(this.URL + '/getAllSubPlantValAdditions')
          //     .then(res => {
          //         return res.data
          //     })
    }
}