import { MasterBrandsDropDownDto } from "@gtpl/shared-models/masters";

export class StockDto {
    stockId: number;
    unitId: number;
    physicalQuantity: number;
    itemCategoryId: number;
    itemCategory: string;
    itemId: number;
    itemName: string;
    saleOrderId: number;
    saleOrderNumber: string;
    sizeId: number;
    sizeName: string;
    openingBalance: number;
    unitPrice: number;
    minimumStock: number;
    locationId: number;
    location: string;
    createdUser: string | null;
    updatedUser?: string | null;
    itemSubCategoryId?: number;
    itemSubCategoryName?: string;
    brandId?: number;
    brandName?: string;
    minGrade?: number;
    maxGrade?: number;
    pouches?: number;
    pouchWeight?: number;
    variantId?: number;
    variantName?: string;
    brands?: MasterBrandsDropDownDto[];
    poNumber?: string;
    soStatus: string;
    unitName?: string;



    /**
    * @param stockId
   * @param unitId
   * @param unitName
   * @param physicalQuantity
   * @param itemCategoryId
   * @param itemCategory
   * @param itemId
   * @param itemName
   * @param saleOrderId
   * @param saleOrderNumber
   * @param sizeId
   * @param sizeName
   * @param openingBalance
   * @param unitPrice
   * @param minimumStock
   * @param locationId
   * @param location
   * @param createdUser
   * @param updatedUser
     */
    constructor(stockId: number, unitId: number, physicalQuantity: number, itemCategoryId: number, itemCategory: string, itemId: number, itemName: string, saleOrderId: number, saleOrderNumber: string, sizeId: number, sizeName: string, openingBalance: number, unitPrice: number, minimumStock: number, locationId: number, location: string, createdUser: string | null, updatedUser?: string | null,itemSubCategoryId?: number,itemSubCategoryName?: string,brandId?: number,brandName?: string,minGrade?: number,maxGrade?: number,pouches?: number,pouchWeight?: number,variantId?: number,variantName?: string,brands?:MasterBrandsDropDownDto[],poNumber?:string,soStatus?:string,unitName?: string) {
        this.stockId = stockId;
        this.unitId = unitId;
        this.physicalQuantity = physicalQuantity;
        this.itemCategoryId = itemCategoryId;
        this.itemCategory = itemCategory;
        this.itemId = itemId;
        this.itemName = itemName;
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.sizeId = sizeId;
        this.sizeName = sizeName;
        this.openingBalance = openingBalance;
        this.unitPrice = unitPrice;
        this.minimumStock = minimumStock;
        this.locationId = locationId;
        this.location = location;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.brandId = brandId;
        this.brandName = brandName;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemSubCategoryName = itemSubCategoryName;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.pouches = pouches;
        this.pouchWeight = pouchWeight;
        this.pouchWeight = pouchWeight;
        this.variantId = variantId;
        this.variantName = variantName;
        this.brands = brands;
        this.poNumber = poNumber;
        this.soStatus = soStatus
        this.unitName = unitName
    }
}
