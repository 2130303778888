import axios from 'axios';
import connection from './connection';
import { ReasonsResponse, ReasonsDto, AllReasonsResponse, ReasonsDropDownResponse } from '@gtpl/shared-models/masters'

export class ReasonsService {
    URL = connection.DEPLOY_URL + '/reasons';


    async create(dto: ReasonsDto): Promise<ReasonsResponse> {
        return await axios.post(this.URL + '/createReasons', dto)
            .then(res => {
                return res.data
            })
    }


    async update(dto: ReasonsDto): Promise<ReasonsResponse> {
        return await axios.post(this.URL + '/updateReasons', dto)
            .then(res => {
                return res.data
            })
    }


    async activatedeActivate(Dto: ReasonsDto): Promise<ReasonsResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateReasons', Dto)
            .then(res => {
                return res.data
            })
    }



    async getAll(): Promise<AllReasonsResponse> {
        return await axios.post(this.URL + '/getAllReasons')
            .then(res => {
                return res.data
            })
    }

    async getReasonsDropDownInfo(): Promise<ReasonsDropDownResponse> {
        return await axios.post(this.URL + '/getReasonsDropDownInfo')
            .then(res => {
                return res.data
            })
    }



}