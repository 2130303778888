import { AssetsCode } from "@gtpl/shared-models/common-models";

export class AssetCheckListDto {
    checkListId?: number;
    checkListName:string;
    assetCategory:string;
    isActive: boolean;
    createdUser : string;
    updatedUser: string;
    versionFlag?: number;
   


    /**
     * 
     * @param checkListId
     * @param checkListName
     * @param assetCategory
     * @param isActive
     * @param createdUser
     * @param updatedUser
     * @param versionFlag
     */

    constructor(checkListId: number, checkListName: string, assetCategory: string, isActive:boolean,createdUser : string,updatedUser:string, versionFlag?: number) {
        this.checkListId = checkListId;
        this.checkListName = checkListName;
        this.assetCategory = assetCategory;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
    }
}