import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import AssetActivityControllerForm from './shared-components-asset-activity-controller';
import { AllAssetResponseModel, AssetActionControlRequest, AssetCodeDto, AssetControlRequest } from '@gtpl/shared-models/asset-management';
import { AssetManagementService } from '@gtpl/shared-services/asset-management';
import { request } from 'http';
import moment from 'moment';
export interface AssetActivityGridProps {
    updateState? : (key : number) => void;
}
export function AssetActivityControllerGrid(props: AssetActivityGridProps) {
    const [page, setPage] = React.useState(1);
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [assetData, setAssetData] = useState<AllAssetResponseModel[]>([]);
    const service = new AssetManagementService();


    useEffect(() => {
        assetActiveSoaking();
    }, [])

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        },
        render: text =>
            text ? (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
            )
                : null

    });
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    };
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }


    const assetActiveSoaking = () => {

        service.assetActiveSoaking().then((res) => {
            if (res) {
                props.updateState(1)
                setAssetData(res.data)
            } else {
                setAssetData([])
            }
        });
    }
    const stopAssets = (reqObj,qrCodeType) => {
        const req = new AssetControlRequest()
        req.assetsActionControlId = reqObj.assetActionControlId
        req.qrCodeType=qrCodeType
        service.stopAssets(req).then((res) => {
            if (res) {
                AlertMessages.getSuccessMessage('Asset stopped successfully')
                assetActiveSoaking();
            }else{
                AlertMessages.getErrorMessage('stop action failed')
            }
        });
    }

    const columnsSkelton: ColumnProps<any>[] = [
        {
            title: 'S No',
            key: 'sno',
            width: '70px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
            title: 'Asset Code',
            dataIndex: 'assetCode',
            sorter: (a, b) => a.assetCode.localeCompare(b.assetCode),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetCode')
        },
        {
            title: 'Machine Stop Time(24 hrs Format)',
            dataIndex: 'cronJobTime',
            sorter: (a, b) => a.cronJobTime.localeCompare(b.cronJobTime),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('cronJobTime'),
            render: (text, data, index) => {
                const dataArray = text.split(' ')
                console.log(text)
                return <span>{`${dataArray[2]}-${dataArray[3]}-2022 ${dataArray[1]}:${dataArray[0]}`}</span>
              },
        },
        {
            title: 'Start Time',
            dataIndex: 'stratTime',
            width: '100px',
            sorter: (a, b) => moment(a.stratTime).unix() - moment(b.stratTime).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
              return <span>
                {record.stratTime ? moment(record.stratTime).format('YYYY-MM-DD') : '-'}
              </span>
            }
          },

        // {
        //     title: 'Asset Control',
        //     dataIndex: 'assetControl',
        //     sorter: (a, b) => a.cronJobTime.localeCompare(b.assetControl),
        //     sortDirections: ['descend', 'ascend'],
        //     ...getColumnSearchProps('assetControl')
        // },
        {
            title: 'Tub Code',
            dataIndex: 'tubCode',
            sorter: (a, b) => a.tubCode.localeCompare(b.tubCode),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('tubCode')
        },
        {
            title: 'Duration One',
            dataIndex: 'durationOne',
            sorter: (a, b) => a.durationOne.localeCompare(b.durationOne),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('durationOne')
        },
        {
            title: 'Duration Two',
            dataIndex: 'durationTwo',
            sorter: (a, b) => a.durationTwo.localeCompare(b.durationTwo),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('durationTwo')
        },
        {
            title: 'Asset status',
            dataIndex: 'assetStatus',
            sorter: (a, b) => a.assetStatus.localeCompare(b.assetStatus),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetStatus'),
            render: (text, rowData) => (

                rowData.assetStatus?
                    <span>
                    <Popconfirm onConfirm={e => { stopAssets(rowData,'assetCode'); }}
                        title={
                             'Are you sure to Stop ?'
                        }
                    >
                        <Button className='panel_button'style={{ backgroundColor: 'Red',color:'white' }} >Stop </Button>
                        <span style={{ color: 'Green' }}>   Running</span>
                    </Popconfirm>
                </span> : <span style={{ color: 'Red' }}>Stopped</span>
                
               
            )
        },
        {
            title: 'Tub status',
            dataIndex: 'tubStatus',
            sorter: (a, b) => a.tubStatus.localeCompare(b.tubStatus),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('tubStatus'),
            render: (text, rowData) => (
                rowData.tubStatus?
                <span>
                    <Popconfirm onConfirm={e => { stopAssets(rowData,'tubCode'); }}
                        title={
                             'Are you sure to Stop ?'
                        }
                    >
                        <Button className='panel_button' style={{ backgroundColor: 'Red',color:'white' }}>Stop </Button>
                        <span style={{ color: 'Green' }}>   Running</span>
                    </Popconfirm>
                </span>:<span style={{ color: 'Red' }}>Stopped</span>
            )
        },
       

    ];
    return (
        <Card title={<span style={{ color: 'white' }}> Asset Active Controller View </span>}
            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/Asset-view' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
            <br></br>

            <Table
                rowKey={record => record.endCustomerId}
                columns={columnsSkelton}
                dataSource={assetData}
                scroll={{ x: true }}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                onChange={onChange}
                bordered />
        </Card>
    )
}
export default AssetActivityControllerGrid