export class UploadDetailesGetResponseDto {
    // employeeLeavesId: number;
    employeeCode: string;
    employeeName: string;
    gender: string;
    designation: string;
    department: string;
    priorityOne: string;
    priorityTwo: string;
    employeeStatus: string;
    yearOfExperience: Number;
    emailId: string;
    mobileNumber: string;


    /**
     * 
     * @param employeeCode 
     * @param employeeName 
     * @param gender 
     * @param designation 
     * @param department 
     * @param priorityOne 
     * @param priorityTwo 
     * @param employeeStatus 
     * @param yearOfExperience 
     * @param emailId 
     * @param mobileNumber 
     */


    constructor( employeeCode: string, employeeName: string, gender: string, designation: string, department: string, priorityOne: string, priorityTwo: string, employeeStatus: string, yearOfExperience: Number, emailId: string, mobileNumber: string) {

        // this.employeeLeavesId = employeeLeavesId
        this.employeeCode = employeeCode;
        this.employeeName = employeeName;
        this.gender = gender;
        this.designation = designation;
        this.department = department;
        this.priorityOne = priorityOne;
        this.priorityTwo = priorityTwo;
        this.employeeStatus = employeeStatus;
        this.yearOfExperience = yearOfExperience;
        this.emailId = emailId;
        this.mobileNumber = mobileNumber;



    }


}
