import { ContainerStatusEnum, InvoiceCategoriesEnum } from "@gtpl/shared-models/common-models";
import { InvoiceItemsInfo } from "./invoice-items-info.dto";

export class InvoiceDto{
    invoiceId: number;
    saleOrderId: number;
    invoiceNumber: string;
    invoiceDate: string;
    exporterId: number;
    unitId: number;
    customerId: number;
    endCustomerId: number;
    flightNumber: string;
    portOfLoading:string;
    portOfDischarge:number;
    status: string;
    freightCharges: number;
    exchangeRate: number;
    totalAmount:number;
    total_INR_amount:number;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    invoiceItemsInfo: InvoiceItemsInfo[];
    isTaxApplicable?:string;
    saleCommission?:number;
    insurance?:number;
    finNumber?:string;
    paymentTerms?:number;
    finalDestination?:number;
    notifyPartyOne?:number;
    notifyPartyTwo?:number;
    notifyPartyThree?:number;
    orderDate?:string;
    notifyPartyFour?:number;
    placeOfReceipt?:string;
    etaDate?:string;
    invoiceCategory?:InvoiceCategoriesEnum;
    epcgLicenseNumber?:string;
    epcgLicenseDate?:string;
    epcgFobValue?:number;
    exportObligation?:number;
    remarks?:string
    

    

    constructor(invoiceId: number,saleOrderId: number,invoiceNumber: string,invoiceDate: string,exporterId: number,unitId: number,customerId: number,endCustomerId: number, portOfLoading:string,portOfDischarge:number,flightNumber: string,status: string,freightCharges: number,exchangeRate: number, totalAmount:number,total_INR_amount:number,invoiceItemsInfo:InvoiceItemsInfo[],createdAt: Date,createdUser: string | null,updatedAt: Date,updatedUser: string | null,versionFlag: number,isTaxApplicable?:string,saleCommission?:number,insurance?:number,finNumber?:string,paymentTerms?:number,
        finalDestination?:number,notifyPartyOne?:number,
        notifyPartyTwo?:number,
        notifyPartyThree?:number,orderDate?:string,notifyPartyFour?:number,placeOfReceipt?:string,
        etaDate?:string,
        invoiceCategory?:InvoiceCategoriesEnum,epcgLicenseNumber?:string,epcgLicenseDate?:string,epcgFobValue?:number,exportObligation?:number,remarks?:string){
        this.invoiceId = invoiceId;
    this.saleOrderId = saleOrderId;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.exporterId = exporterId;
    this.unitId = unitId;
    this.customerId = customerId;
    this.endCustomerId = endCustomerId;
    this.flightNumber = flightNumber;
    this.status = status;
    this.freightCharges = freightCharges;
    this.exchangeRate = exchangeRate;
    this.portOfLoading = portOfLoading;
    this.portOfDischarge = portOfDischarge;
    this.totalAmount = totalAmount;
    this.total_INR_amount = total_INR_amount;
    this.isTaxApplicable = isTaxApplicable;
    this.saleCommission = saleCommission;
    this.invoiceItemsInfo = invoiceItemsInfo;
    this.createdAt = createdAt;
    this.createdUser = createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
    this.insurance = insurance;
    this.finNumber = finNumber;
    this.paymentTerms = paymentTerms;
    this.finalDestination = finalDestination;
    this.notifyPartyOne = notifyPartyOne;
    this.notifyPartyTwo = notifyPartyTwo;
    this.notifyPartyThree = notifyPartyThree;
    this.orderDate = orderDate;
    this.notifyPartyFour = notifyPartyFour
    this.placeOfReceipt = placeOfReceipt
    this.etaDate = etaDate
    this.invoiceCategory = invoiceCategory
    this.epcgLicenseNumber = epcgLicenseNumber
    this.epcgLicenseDate = epcgLicenseDate
    this.epcgFobValue = epcgFobValue
    this.exportObligation = exportObligation
    this.remarks = remarks
    }
}