import React, { useEffect, useRef, useState } from 'react';

import './metal-detection.css';
import { Card, Button, Form, Row, Col, DatePicker, Input, Alert } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { MetalDetectionService } from '@gtpl/shared-services/production';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DateFilter } from '@gtpl/shared-models/common-models'
import { MetalDetectionDto } from '@gtpl/shared-models/production-management';
import Table, { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { Column, ColumnGroup } = Table;
/* eslint-disable-next-line */
export interface MetalDetectionProps { }
const { RangePicker } = DatePicker;
export function MetalDetection(
  props: MetalDetectionProps
) {


  useEffect(() => {
    onFinish()
  }, [])
  const dateFormat = 'DD/MM/YYYY';

  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [dataSource, setDataSource] = useState<MetalDetectionDto[]>([]);

  const service = new MetalDetectionService();
  const onFinish = () => {
    const date = form.getFieldValue('date');
    if (date) {
      const fromDate = new Date(date[0]);
      const toDate = new Date(date[1]);
      if (fromDate && toDate) {
        const filter = new DateFilter(fromDate, toDate)
        service.getMetalDetectionLog(filter).then(res => {
          if (res.status) {
            setDataSource(res.data);
            AlertMessages.getSuccessMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }

    }
  }


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });


  /**
 * 
 * @param selectedKeys 
 * @param confirm 
 * @param dataIndex 
 */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const columnsSkelton: any[] = [
    {
      title: 'Note: For every 30 minutes standard test pieces are passed & tested',
      children: [
        {
          title: 'S No',
          key: 'sno',
          width: '70px',
          render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
        {
          title: 'Date',
          dataIndex: 'date',
          width: '150px',
          // responsive: ['lg'],
          sorter: (a, b) => moment(a.purchaseOrderDate).unix() - moment(b.purchaseOrderDate).unix(),
          sortDirections: ['descend', 'ascend'],
          // ...getColumnSearchProps('purchaseOrderDate'),
          render: (value, record: MetalDetectionDto, index) => {
            return moment(record.date).format('DD-MM-YYYY HH:MM A');
          }
        },
        {
          title: 'Grade',
          dataIndex: 'fe',
          render: (value, record: MetalDetectionDto, index) => {
            return `${(record.minGrade) ? record.minGrade + ' - ' : ''}${(record.maxGrade) ? record.maxGrade : ''}`;
          }
        },
        {
          title: 'Variety/Brand/S.Code',
          dataIndex: 'vbs',
          render: (value, record: MetalDetectionDto, index) => {
            return `${(record.varietyName) ? record.varietyName + "/" : ''}${(record.brandName) ? record.brandName + "/" : ''}${(record.sCode) ? record.sCode + "/" : ''}${(record.operation) ? record.operation : ''}`;
          }
        },
        {
          title: 'Standard Test Pads',
          children: [
            {
              title: '2 MM Fe',
              dataIndex: 'mmFe',
              key: 'mmFe',
              width: 100,
              render: (value, record: MetalDetectionDto, index) => {
                return record.mmFe ? <CheckOutlined style={{ color: 'red', fontSize: '15px' }} /> : <CloseOutlined style={{ color: 'green', fontSize: '15px' }} />;
              }
            },
            {
              title: '2.5 MM Non Fe',
              dataIndex: 'mmNonFe',
              key: 'mmNonFe',
              width: 100,
              render: (value, record: MetalDetectionDto, index) => {
                return record.mmNonFe ? <CheckOutlined style={{ color: 'red', fontSize: '15px' }} /> : <CloseOutlined style={{ color: 'green', fontSize: '15px' }} />;
              }
            },
            {
              title: '2.5 MM SS',
              dataIndex: 'mmSS',
              key: 'mmSS',
              width: 100,
              render: (value, record: MetalDetectionDto, index) => {
                return record.mmSS ? <CheckOutlined style={{ color: 'red', fontSize: '15px' }} /> : <CloseOutlined style={{ color: 'green', fontSize: '15px' }} />;
              }
            },
          ],
        },
        {
          title: 'No. Of. Pouches Passed',
          dataIndex: 'fe',
          render: (value, record: MetalDetectionDto, index) => {
            return `${(record.minPouches) ? record.minPouches + '/' : ''}${(record.maxPouches) ? record.maxPouches : ''}`;
          }
        },
        {
          title: 'Metal Detected',
          dataIndex: 'metalDetected',
          render: (value, record: MetalDetectionDto, index) => {
            return record.metalDetected ? <CheckOutlined style={{ color: 'red', fontSize: '15px' }} /> : <CloseOutlined style={{ color: 'green', fontSize: '15px' }} />;
          }
        },
      ],
    }
  ];

  return (
    <Card title={<span style={{ color: 'white' }}>Metal Detection Log</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/metal-detection-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link>} >
      <Form form={form} name="horizontal_login" layout="vertical">
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="date"
              label='Date'
              rules={[{ required: true, message: 'Date is Required!' }]}
              initialValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
            >
              <RangePicker defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]} onChange={onFinish} format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {(dataSource?.length) ?
        <><div style={{textAlign:'right'}}><CheckOutlined style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }} />-
          Metal pieces detected in the product <br />
          <CloseOutlined style={{ color: 'green', fontSize: '15px', fontWeight: 'bold' }} />- Metal pieces not detected in the product</div>
          <Table
            columns={columnsSkelton}
            dataSource={dataSource}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            bordered
            scroll={{ x: true }} /></>
        : ''}


    </Card>
  );
}

export default MetalDetection;
