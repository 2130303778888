export class DestinationDetails{
    destinationId:number;
    destinationName:string;
    countryId:number;
    countryName:string;
    createdUser:string;
    isActive:boolean;
    createdAt : Date;
    updatedAt : Date;
    updatedUser : string;
    versionFlag : number;
    
    /**
     * 
     * @param destinationId 
     * @param destinationName 
     * @param countryId 
     * @param countryName
     * @param createdUser
     * @param isActive
     * @param createdAt
     * @param updatedUser
     * @param updatedAt
     * @param versionFlag
     */
    constructor(destinationId:number,destinationName:string,countryId:number,countryName:string,createdUser:string,isActive:boolean,createdAt : Date,updatedAt : Date,updatedUser : string,versionFlag : number){
    this.destinationId = destinationId;
    this.destinationName = destinationName;
    this.countryId = countryId;
    this.countryName=countryName
    this.createdUser = createdUser;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.isActive = isActive;
    this.versionFlag = versionFlag;
    }
}