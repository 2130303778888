import axios from "axios";
import connection from './connection';
import { LoanCategoriesDto, AllLoanCategoriesResponse, LoanCategoriesResponse, LoanCatIdRequest } from "@gtpl/shared-models/hrms";

export class LoanCategoriesService{
    
    URL = connection.DEPLOY_URL + '/loan-categories';

    async create(dto: LoanCategoriesDto): Promise <LoanCategoriesResponse>{
        return await axios.post(this.URL + '/createLoanCategory', dto)
            .then(res => {
                return res.data
            })
    }

    async update(dto: LoanCategoriesDto): Promise <LoanCategoriesResponse>{
        return await axios.post(this.URL + '/updateLoanCategory', dto)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactive(dto: LoanCategoriesDto): Promise <LoanCategoriesResponse>{
        return await axios.post(this.URL + '/activateOrDeactivate', dto)
            .then(res => {
                return res.data
            })
    }

    async getAllLoanCategories(): Promise <AllLoanCategoriesResponse>{
        return await axios.post(this.URL + '/getAllLoanCategories')
            .then(res => {
                return res.data
            })
    }

    async getAllActiveLoanCategories(): Promise <AllLoanCategoriesResponse>{
        return await axios.post(this.URL + '/getAllActiveLoanCategories')
            .then(res => {
                return res.data
            })
    }
}