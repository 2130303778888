import { GetStoreConsumptionItemsDto } from "@gtpl/shared-models/procurement-management";
import { BomPackingItemsDto, CrrencySymbols, SOItemsDto, UnitsOfWeightInput } from "@gtpl/shared-models/sale-management";
import { StoreConsumptionService } from "@gtpl/shared-services/procurement";
import { CaretRightOutlined, SearchOutlined, FilterOutlined, EditOutlined, RotateRightOutlined, RedoOutlined } from '@ant-design/icons'
import { saleOrder,SaleOrderService} from "@gtpl/shared-services/sale-management";
import { Button, Card, Col, Input, Row, Table, Tag } from "antd";
import { ColumnProps, ColumnsType } from "antd/lib/table";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import React, { useEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';


export interface SaleOrderItemProps {
    saleOrderId: number;
    currencyId: number;
}

function SOReportItemsGrid(
    props: SaleOrderItemProps
) {

    const service = new SaleOrderService;
  const searchInput = useRef(null);

    const [soItemData, setSoItemData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
    const [isReProcess, setIsReProcess] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedSoItem, setSelectedSoItem] = useState<SOItemsDto>(undefined);
  const [bomSoData, setBomSoData] = useState<BomPackingItemsDto[]>([]);
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        getAllSaleOrderItems();
        getAllBomDetails();
    }, [])

    const displayModel = (type, data) => {
        if (type == 'ReProcess') {
          setIsReProcess(true)
        } else {
          setIsReProcess(false)
        }
        setIsModalVisible(true);
        setSelectedSoItem(data);
      };

    const getAllSaleOrderItems = () => {
        console.log(props.saleOrderId)
        service.getAllSaleOrderItemDetails(new saleOrder(props.saleOrderId)).then(res => {
    
          if (res.status) {
            setSoItemData(res.data);
          } else {
            if (res.intlCode) {
              setSoItemData([]);
            } else {
              setSoItemData([]);
            }
          }
        }).catch(err => {
          setSoItemData([]);
        })
      }

      const getAllBomDetails= () => {
        service.getAllBomData(new saleOrder(props.saleOrderId)).then(res => {
    
          if (res.status) {
            setBomSoData(res.data);
          } else {
            if (res.intlCode) {
              setBomSoData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setBomSoData([]);
        })
      }

       /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  

      const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {    setTimeout(() => searchInput.current.select());   }
        },
        render: text =>
          text ?(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) :text
          )
          : null
         
      });

        const Columns: ColumnsType<any> = [
            {
                title: 'Brand',
                dataIndex: 'brand',
                width:150,
                sorter: (a, b) => a.brand.localeCompare(b.brand),
                ...getColumnSearchProps('brand'),
                // render: (text, data, index) => {
                //   console.log(text, data, index)
                //   return <span>{data.brand.children}</span>
                // }
              },
              // {
              //   title: 'Product',
              //   dataIndex: 'itemId',
              //   // render: (text, data, index) => {
              //   //   console.log(text, data, index)
              //   //   return <span>{data.itemId.children}</span>
              //   // }
              // },
              // {
              //   title: 'Food Type',
              //   dataIndex: 'foodTypeId',
              //   // render: (text, data, index) => {
              //   //   console.log(text, data, index)
              //   //   return <span>{data.foodTypeId.children}</span>
              //   // }
              // },
              {
                title: 'Variant',
                dataIndex: 'varientId',
                sorter: (a, b) => a.varientId.localeCompare(b.varientId),
                ...getColumnSearchProps('varientId'),
                render: (text, data, index) => {
                  console.log(text, data, index)
                  return <span>{`${data.itemId}${text}`}</span>
                }
              },
              {
                title: 'Grade',
                dataIndex: 'minGrade',
                key: 'minGrade',
                sorter: (a, b) => a.minGrade-b.minGrade,
                ...getColumnSearchProps('minGrade'),
                render: (text, data, index) => {
                  console.log(text, data, index)
                  return <span>{`${text} - ${data.maxGrade} `}</span>
                }
              },
              {
                title: 'Pack Type',
                dataIndex: 'pouchWeight',
                key: 'pouchWeight',
                sorter: (a, b) => a.pouchWeight-b.pouchWeight,
                ...getColumnSearchProps('pouchWeight'),
                render: (text, data, index) => {
                    const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
                    console.log(text, data, index)
                    return <span>{`${data.pouches} *  ${Number(data.pouchWeight)} ${uomData.name}`}</span>
                  }
              },
              {
                title: 'Net Case Weight',
                dataIndex: 'caseWeight',
                key: 'caseWeight',
                sorter: (a, b) => a.caseWeight-b.caseWeight,
                ...getColumnSearchProps('caseWeight'),
                render: (text, data, index) => {
                    const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
                  return <span>{`${Number(text)} ${uomData.name}`}</span>
                }
              },
              {
                title: 'Cases',
                dataIndex: 'cases',
                key: 'cases',
                sorter: (a, b) => a.cases-b.cases,
                ...getColumnSearchProps('cases'),
              },
              {
                title: 'Net Weight',
                dataIndex: 'netWeight',
                key: 'netWeight',
                sorter: (a, b) => a.netWeight-b.netWeight,
                ...getColumnSearchProps('netWeight'),
                render: (text, data, index) => {
                    const uomData = UnitsOfWeightInput.find(uom => uom.value == data.uomId);
                  console.log(text, data, index)
                  return <span>{`${Number(text)} ${uomData.name}`}</span>
                }
              },
              {
                title: 'Unit Price',
                dataIndex: 'unitPrice',
                key: 'unitPrice',
              },
              {
                title: 'Net Amount',
                dataIndex: 'netAmount',
                key: 'netAmount',
                sorter: (a, b) => a.netAmount- b.netAmount,
                ...getColumnSearchProps('netAmount'),
              },
              
            ];
            const BomColumns: ColumnProps<any>[] = [
              {
                title: 'Item Category',
                dataIndex: ['itemCategoryDetails',"itemCategory"],
                width:150,
                sorter: (a, b) => a.itemCategoryDetails.itemCategory.localeCompare(b.itemCategoryDetails.itemCategory),
                ...getColumnSearchProps('itemCategoryId'),
              },
              {
                title: 'Item',
                dataIndex: ['itemDetails',"itemName"],
                width:150,
            
                sorter: (a, b) => a.itemDetails.itemName.localeCompare(b.itemDetails.itemName),
                ...getColumnSearchProps('itemId'),
            
              },
              {
                title: 'Size',
                dataIndex: ["size", "sizeName"],
                width:150,
            
                sorter: (a, b) => a.size.sizeName.localeCompare(b.size.sizeName),
                ...getColumnSearchProps('sizeId'),
            
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                width:150,
            
                sorter: (a, b) => a.quantity - b.quantity,
                ...getColumnSearchProps('quantity'),
                
              },
              {
                title: 'Status',
                dataIndex: 'status',
                width:150,
            
                render: (status, rowData) => (
                  <>
                    {status=="OPEN"?<Tag  style={{backgroundColor:'#f6e552',color:"black", fontWeight: 'bold'}}>OPEN</Tag> : status=="PO"?<Tag  style={{backgroundColor:'#f2a637',color:"black", fontWeight: 'bold' }}>PURCHASE ORDER</Tag>:
                    status=="GRN"?<Tag  style={{backgroundColor:'#e4f467',color:"black", fontWeight: 'bold' }}>GRN</Tag>
                    :status=="CLOSED"?<Tag  style={{backgroundColor:'#52c41a',color:"black", fontWeight: 'bold' }}>CLOSED</Tag>:""}
                    
                  </>
                ),
                sorter: (a, b) => a.status.localeCompare(b.status),
                sortDirections: ['descend', 'ascend'],
                filters: [
                  {
                    text: 'OPEN',
                    value: 'OPEN',
                  },
                  
                  {
                    text: 'PURCHASE ORDER',
                    value: 'PO',
                  },
                  {
                    text: 'GRN',
                    value: 'GRN',
                  },
                  {
                    text: 'CLOSED',
                    value: 'CLOSED',
                  },
                ],
                filterMultiple: false,
                onFilter: (value, record) => 
                {
                  // === is not work
                  return record.status === value;
                },
              },

    ];
    return (
      <>
        <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>SALE ORDER ITEMS </span>}></Card>

        {soItemData.length >0 ?
          <Table
            // key={Date.now()}
            columns={Columns}
            dataSource={soItemData}
            pagination={{
              onChange(current) {
                setPage(current);
              }
            }}
            // onChange={onChange}
            scroll={{ x: true }}
            size="small"
            bordered
          ></Table>:<></>
        }
          <Card  style={{ overflow: 'auto',overflowY:'hidden', backgroundColor:'#d9d9d9', height:40 ,margin:1 }} title={<span>BOM DETAILS</span>}></Card>
  <Card>
    <Row >
      <Col style={{marginLeft:'5%'}}>
        <Card title={'TOTAL: ' + (bomSoData).length} style={{textAlign: 'left', width: 200, height: 41,backgroundColor:'#bfbfbf'}}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'OPEN :' + (bomSoData).filter(el => el.status == "OPEN").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#f6e552'}}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'PURCHASE ORDER :' + (bomSoData).filter(el => el.status == "PO").length} style={{textAlign: 'left', width: 220, height: 41,backgroundColor:'#f2a637' }}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'GRN :' + (bomSoData).filter(el => el.status == "GRN").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#e4f467' }}></Card>
      </Col>
      <Col style={{marginLeft:'2%'}}>
        <Card title={'CLOSED :' + (bomSoData).filter(el => el.status == "CLOSED").length} style={{textAlign: 'left', width: 130, height: 41,backgroundColor:'#52c41a' }}></Card>
      </Col>
    </Row>
  </Card> 
  {bomSoData ?
  
    <Table
    key={Date.now()}
      className="components-table-nested"
      columns={BomColumns}
      dataSource={bomSoData}
      // exportable
    />
    : ""
  }


      </>
    );


    // const storeConsumptionItems: ColumnProps<any>[] = [
    //     {
    //         title: 'Item',
    //         dataIndex: "itemName",
    //         width: 150,
    //         align: 'left',
    //     },
    //     {
    //         title: 'Consumed Quantity',
    //         dataIndex: 'quantity',
    //         width: 150,
    //         align: 'left',
    //     },
    // ];
    // return (
    //     <>
    //         <Card style={{ overflow: 'auto', overflowY: 'hidden', backgroundColor: '#d9d9d9', height: 40, margin: 1 }} title={<span>Store Consumption Items</span>}></Card>
    //         <Table
    //             key={Date.now()}
    //             className="components-table-nested"
    //             columns={storeConsumptionItems}
    //             dataSource={storeConsumptionItemsData}
    //         />

    //     </>
    // );
}
export default SOReportItemsGrid;