import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Space, Form, DatePicker, Select, Spin, Tabs, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import Link from 'antd/lib/typography/Link';

import { BomPackingItemsDto, BomSODto, CrrencySymbols, PortOfEntryInput, SaleOrderFiltersDto, SaleOrderStatusDropdownDto, SaleOrderTrackDto, SODto, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import { SaleOrderItemsInfo, SaleOrderItemsDto, SOItemsDto } from '@gtpl/shared-models/sale-management';

import { SaleOrderService, SaleOrderTrackFilters } from '@gtpl/shared-services/sale-management';
import { SoDesignForm } from '@gtpl/pages/master/master-components/so-design-form';

import { Table } from "ant-table-extensions";

import { FormattedMessage } from 'react-intl';
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Redirect, useLocation } from 'react-router-dom';
import './sale-order-report-grid.css';
import { DestinationDto, EndCustomerDto, FoodTypesDto, PlantsDropDown, ProductDto, CountryDto, Brands, MasterBrandsDropDownDto } from '@gtpl/shared-models/masters';
import { AllSaleOrdersDto } from 'libs/shared-models/sale-management/src/lib/sale-order/all-sale-orders.dto';
import moment from 'moment';
import SOReportItemsGrid from './so-report-items';
import { DestinationService, EndCustomersService, UnitcodeService, CountryService, MasterBrandService, SkuService } from '@gtpl/shared-services/masters';
import { JobTypesEnum, SoLifeCycleCategoryEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import Text from 'antd/lib/typography/Text';
import CustomSpinner from 'libs/shared-components/custom-spinner/src/lib/custom-spinner';
import { SoLifeCycleStatusEnum, } from "@gtpl/shared-models/common-models";
const { Option } = Select;



/* eslint-disable-next-line */
export interface SaleOrderAbstractProps { }

export function SaleOrderAbstractReport(props: SaleOrderAbstractProps) {
  // let soLifeCycleStatus = SoLifeCycleStatusEnum;
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const [display, setDisplay] = useState<string>('none');
  const [disable, setDisable] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true)
  const [showAbstract, setshowAbstract] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState('');

  const [saleId, setSaleId] = useState(0);

  const [saleOrderData, setSaleOrderData] = useState<any[]>([]);
  const [selectedEndCustomer, setSelectedEndCustomer] = useState<number>(0);
  const [masterBrandId, setMasterBrandId] = useState<number>(0);
  const [varientCode, setVarientCode] = useState<number>(0);
  const [unit, setUnit] = useState<number>(0)
  const [prodUnit, setProcdUnit] = useState<number>(0)
  const [destinations, setDestinations] = useState<number[]>([])
  const [totalPoCount, settotalSOCount] = useState<number>(0)
  const [country, setCountry] = useState<number[]>([])
  const [filters, setFilters] = useState<SaleOrderFiltersDto>(null)
  const [soItemData, setSoItemData] = useState<SOItemsDto[]>([]);
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);

  const [saleOrderStatus, setSaleOrderStatus] = useState(null);
  const [plantsData, setPlantsData] = useState<PlantsDropDown[]>([]);
  const unitcodeService = new UnitcodeService;
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [readyForShipment, setReadyForShipment] = useState<string>('All');
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(null);
  const [lifeCycleStatusforCategory,setLifeCycleStatusforCategory] = useState<any[]>([])
  const [lifeCycleCategory,setLifeCycleCategory] = useState<any>();
  let location = useLocation();

  const { status, cardType }: any = location.state ? location.state : { status: undefined, cardType: undefined }


  console.log(location.state);
  useEffect(() => {
    if (status) {
      form.setFieldsValue({ lifeCycleStatus: status })
      setLifeCycleStatus(status)
    }
    if (cardType) {
      form.setFieldsValue({ cardType: cardType })
    }
  }, [])
  const { formatMessage: fm } = useIntl();
  const service = new SaleOrderService;
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    // setSaleOrderData(saleData); console.log(saleData);
    getAllActivePlants();
    GetSoLifeCycleStatusForCategory();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ prodUnit: Number(localStorage.getItem('unit_id')) })
    }

  }, []);



  const getAllActivePlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantsData(res.data);
      } else {
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
    });
  }






  const getAllSaleOrders = (selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, countryId, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode, readyForShipmentVal, lifeCycleCategory,lifeCycleStatus, cardType) => {
    setDisable(true);
    const req = new SaleOrderTrackFilters()
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.prodUnit = Number(localStorage.getItem('unit_id'));
    } else {
      req.prodUnit = prodUnit;
    }
    req.selectedEstimatedFromDate = selectedEstimatedFromDate;
    req.selectedEstimatedToDate = selectedEstimatedToDate;
    req.saleOrderStatus = saleOrderStatus;
    req.unit = unit;
    req.countryId = countryId;
    req.selectedCreatedFromDate = selectedCreatedFromDate;
    req.selectedCreatedToDate = selectedCreatedToDate;
    req.masterBrandId = masterBrandId;
    req.varientCode = varientCode;
    req.mainCategory= lifeCycleCategory;
    req.lifeCycleStatus = lifeCycleStatus;
    if (readyForShipmentVal !== 'All') {
      req.readyForShipment = readyForShipmentVal === "Yes" ? true : false;
    }
    req.carType = cardType
    setLoading(true);
    service.getSaleordersAbstractReport(req).then(res => {
      setDisable(false);
      if (res.status) {
        setSaleOrderData(res.data);
        setLoading(false);
        setshowAbstract(true)
        AlertMessages.getSuccessMessage(res.internalMessage);

      } else {
        if (res.intlCode) {
          setLoading(false);
          setDisable(false);
          setSaleOrderData([]);
          setshowAbstract(true)
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setLoading(false);
          setshowAbstract(true);
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
      setDisable(false);
    })
  }



  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'sno',
      width: 10,
      fixed: 'left',
      align: 'center',
      render: (text, object, index) => (page - 1) * pageSize + (index + 1)

    },

    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNumber',
    //   fixed: 'left',
    //   width: 140,
    //   align: 'left',

    //   ...getColumnSearchProps('saleOrderNumber'),
    //   // render: (text, record) => <a onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</a>,
    //   sorter: (a, b) => a.saleOrderId - b.saleOrderId,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <Link onClick={e => goToSaleDetailView(record.saleOrderId)}>{record.saleOrderNumber}</Link>,
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      width: 20,
      align: 'left',
      ...getColumnSearchProps('poNumber'),
      sorter: (a, b) => a.poNumber != null ? a.poNumber.localeCompare(b.poNumber) : "Have Null Values",
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: 'Buyer PO',
      dataIndex: 'buyerPONo',
      width: 30,
      align: 'left',
      sorter: (a, b) => a.buyerPONo.localeCompare(b.buyerPONo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('buyerPONo'),
    },
    {
      title: 'PO Date',
      dataIndex: 'poDate',
      // fixed: 'left',
      width: 30,
      align: 'left',

      // ...getColumnSearchProps('poDate'),1
      render: (text, record) => { return record.poDate != undefined ? moment(record.poDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.poDate).unix() - moment(b.poDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 30,
      align: 'left',
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('customer'),
    },

    {
      title: 'End Customer',
      dataIndex: 'endCustomername',
      width: 35,
      align: 'left',
      sorter: (a, b) => a.endCustomername.localeCompare(b.endCustomername),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('endCustomername'),
    },

    {
      title: 'Load Type',
      dataIndex: 'loadType',
      //fixed: 'right',
      width: 15,
      align: 'left',
      // sorter: (a, b) => a.loadType.localeCompare(b.loadType),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        // let loadType: number = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length
        return record.loadType === "1" ? "Single" : record.loadType === "0" ? "Mixed" : "-"
      },
      filters: [
        {
          text: 'Single',
          value: "1",
        },
        {
          text: 'Mixed',
          value: "0",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.loadType === value;
      },
      // onFilter: (value, record) => {
      //   // let loadType: boolean = (soItemData.filter(rec => rec.saleOrderId == record.saleOrderId)).length === 1 ?true:false
      //   // console.log(value, loadType);
      //   // === is not work
      //   return loadType === value;
      // },

    },
    {
      title: 'ETD',
      dataIndex: 'expectedEndDate',
      sorter: (a, b) => moment(a.expectedEndDate).unix() - moment(b.expectedEndDate).unix(),
      width: 20,
      align: 'left',
      render: (value, rowData: SaleOrderTrackDto, index) => {
        return value ? moment(value).format('YYYY-MM-DD') : '-'
      },
    },
    {
      title: 'Aging',
      key: 'actualEtd',
      width: 15,
      align: 'left',
      sorter: (a, b) => (Math.floor((new Date(a.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{record.actualEtd ? (Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1)) : '-'}</div>),
          props: {
            style: {
              background: record.actualEtd ? Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "#38f438" : '#f4646c' : 'white',
              color: record.actualEtd ? Math.floor((new Date(record.actualEtd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) + 1) >= 0 ? "Black" : 'white' : 'Black'
            }
          },
        };
        return obj;
      }
    },
    {
      title: 'Revised ETD',
      dataIndex: 'actualEtd',
      sorter: (a, b) => moment(a.actualEtd).unix() - moment(b.actualEtd).unix(),
      width: 20,
      align: 'left',
      render: (value, rowData: SaleOrderTrackDto, index) => {
        return value ? moment(value).format('YYYY-MM-DD') : '-'
      },
    },
    // ...getColumnSearchProps('expectedEndDate'),
    // key: 'expectedEndDate',
    // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
    // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
    // sorter: (a, b) => new Date(a.expectedEndDate) - new Date(b.expectedEndDate),
    // defaultSortOrder: 'descend',
    // sortDirections: ['descend', 'ascend'],


    {
      title: 'Final Country',
      dataIndex: 'countryName',
      //fixed: 'right',
      width: 20,
      align: 'left',

      ...getColumnSearchProps('countryName'),
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Final Destination',
      dataIndex: 'destination',
      //fixed: 'right',
      width: 25,
      align: 'left',
      ...getColumnSearchProps('destination'),
      sorter: (a, b) => a.destination != null ? a.destination.localeCompare(b.destination) : "Have Null Values",

      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'BAP Unit',
      dataIndex: 'unit',
      //fixed: 'right',
      width: 20,
      align: 'left',
      ...getColumnSearchProps('unit'),
      sorter: (a, b) => a.unit != null ? a.unit.localeCompare(b.unit) : "Have Null Values",
      sortDirections: ['descend', 'ascend'],
      // render: (text, record) => { return record.unit },

      // filters: [
      //   {
      //     text: 'Unit1',
      //     value: 1,
      //   },
      //   {
      //     text: 'Unit2',
      //     value: 2,
      //   },
      //   {
      //     text: 'Kakinada',
      //     value: 3,
      //   },
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   // === is not work
      //   return record.unit === value;
      // },

    },
    {
      title: 'Prod Unit',
      dataIndex: 'processingUnitName',
      //fixed: 'right',
      width: 20,
      align: 'left',
      ...getColumnSearchProps('processingUnitName'),
      sorter: (a, b) => a.processingUnitName != null ? a.processingUnitName.localeCompare(b.processingUnitName) : "Have Null Values",
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'product',
    //   width: 130,
    //   align: 'left',
    //   sorter: (a, b) => a.product.localeCompare(b.product),
    //   sortDirections: ['descend', 'ascend'],
    //   ...getColumnSearchProps('product'),

    // },
    {
      title: 'Is Invoiced?',
      dataIndex: 'isInvoiced',
      //fixed: 'right',
      width: 25,
      align: 'left',
      sorter: (a, b) => a.isInvoiced.localeCompare(b.isInvoiced),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'YES',
          value: 'YES',
        },
        {
          text: 'NO',
          value: 'NO',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.isInvoiced === value;
      },

    },
    {
      title: 'Total Weight',
      dataIndex: 'totnetWeight',
      width: 25,
      align: 'right',
      ...getColumnSearchProps('totnetWeight'),
      render: (text, record) => { return record.totnetWeight != null ? (Math.round(record.totnetWeight) + " " + ((record.uom === 1 || record.uom === 4) ? "KG" : (record.uom === 2 || record.uom === 3) ? "LB" : "-")) : '-' },
      sorter: (a, b) => a.totnetWeight - b.totnetWeight,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Amount',
      dataIndex: 'cost',
      width: 20,
      align: 'right',
      ...getColumnSearchProps('cost'),
      render: (text, record) => { return record.cost != null ? (CrrencySymbols.find(item => item.id == record.currencyId).symbol + Math.round(record.cost)) : "-" },
      sorter: (a, b) => a.cost - b.cost,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Current Status',
      dataIndex: 'lifeCyclestatus',
      width: 40,
      // align: 'right',
      ...getColumnSearchProps('lifeCyclestatus'),
      render: (text, record) => {
        let color = 'black';
        let bckGrndColor = '#f6e552'
        if (text == SoLifeCycleStatusEnum.Risk1 || SoLifeCycleStatusEnum.RISK2) {
          bckGrndColor = '#e51515'
          color = 'white'
        }
        if (text == SoLifeCycleStatusEnum.CLOSED) {
          bckGrndColor = '#52c41a'
          color = 'blck'
        }
        return <Tag style={{ backgroundColor: bckGrndColor, color: color, fontWeight: 'bold' }}>{text}</Tag>
      },
    },

    {
      title: 'SO Status',
      dataIndex: 'status',
      //fixed: 'right',
      width: 30,
      align: 'left',
      render: (status, rowData) => (
        <>
          {status == "OPEN" ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : status == "INPROGRESS" ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : status == "CLOSED" ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}

        </>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'OPEN',
          value: 'OPEN',
        },

        {
          text: 'INPROGRESS',
          value: 'INPROGRESS',
        },
        {
          text: 'CLOSED',
          value: 'CLOSED',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.status === value;
      },
    },
    {
      title: 'Is Dispatched?',
      dataIndex: 'dispatchStatus',
      //fixed: 'right',
      width: 30,
      align: 'left',
      render: (dispatchStatus, rowData) => (
        <>
          {dispatchStatus == true ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>YES</Tag> : dispatchStatus == false ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>NO</Tag> : ""}

        </>
      ),
      sorter: (a, b) => a.dispatchStatus.localeCompare(b.dispatchStatus),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'YES',
          value: 1,
        },

        {
          text: 'NO',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.dispatchStatus === value;
      },
    },
    {
      title: 'Packing',
      dataIndex: 'packing',
      //fixed: 'right',
      width: 30,
      align: 'left',
      render(text, record) {
        return {
          props: {
            // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
          },
          children: <a href={'#/allsaleorders-view'}>{record.packing}</a>
          // children: record.packing,
        };
      }
    },
    {
      title: 'Production Inventory',
      dataIndex: 'rmStock',
      //fixed: 'right',
      width: 30,
      align: 'left',

      render(text, record) {
        return {
          props: {
            style: {
              background: ((record.rmStock / record.orderQty) * 100) < 50 ? "#f4646c" : (((record.rmStock / record.orderQty) * 100) >= 50 && ((record.rmStock / record.orderQty) * 100) < 75) ? "#f8f838" : '#38f438',
              color: ((record.rmStock / record.orderQty) * 100) < 50 ? "white" : (((record.rmStock / record.orderQty) * 100) >= 50 && ((record.rmStock / record.orderQty) * 100) <= 75) ? "black" : 'black'
            }
          },
          children: record.rmStock,
        };
      }
      // render: (text, rowData) => (<>{rowData.rmStock+'/'+rowData.orderQty}</>),
    },
    {
      title: 'Production',
      dataIndex: 'prodStatus',
      //fixed: 'right',
      width: 25,
      align: 'left',
      filters: [
        {
          text: 'OPEN',
          value: 'OPEN',
        },
        {
          text: 'INPROGRESS',
          value: 'IN_PROGRESS',
        },
        {
          text: 'CLOSED',
          value: 'CLOSED',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.prodStatus === value;
      },
      render(text, record) {
        return {
          props: {
            // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
          },
          children: <a href={'#/production-inventory-grid'}>{record.prodStatus}</a>
        };
      }

    },

    {
      title: 'Quality Assurance',
      dataIndex: 'qualityAssurance',
      //fixed: 'right',
      width: 25,
      align: 'left',
      render(text, record) {
        return {
          props: {
            // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
          },
          children: <a href={'#/qa-qc-sample-test-grid'}>{record.qualityAssurance}</a>
          // children: <a href={'sale-order/' + text}>{text}</a>
        };
      }
    },
    {
      title: 'FG Stock (Cases)',
      dataIndex: 'fgStock',
      // //fixed: 'right',
      width: 30,
      align: 'left',
      render(value) { return value ? value : 0 }

    },
    {
      title: 'Documentation',
      dataIndex: 'doc',
      // //fixed: 'right',
      width: 30,
      align: 'left',
      render(text, record) {
        console.log(record);
        return {
          props: {
            // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
          },
          children: <a href={'#/document-approval'}>{record.doc}</a>
          // children: <a href={'sale-order/' + text}>{text}</a>
        };
      }
    },
    {
      title: 'Logistics',
      dataIndex: 'logistics',
      // //fixed: 'right',
      width: 30,
      align: 'left',
      render(text, record) {
        return {
          props: {
            // style: { color : (text) == 'yes' ? "#096dd9" : "#f4646c", textSizeAdjust:50, fontSize:20 }
          },
          children: <a href={'#/container-register-grid'}>{record.logistics}</a>
        };
      }
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      //fixed: 'right',
      width: 20,
      align: 'left',
      filters: [
        {
          text: JobTypesEnum.MAIN_JOB,
          value: JobTypesEnum.MAIN_JOB,
        },
        {
          text: JobTypesEnum.SUB_JOB,
          value: JobTypesEnum.SUB_JOB,
        },
        {
          text: JobTypesEnum.NO_DOC,
          value: JobTypesEnum.NO_DOC,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        // === is not work
        return record.jobType === value;
      },
      render(text, record) {
        return <>
          {text == JobTypesEnum.MAIN_JOB ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.MAIN_JOB}</Tag> : text == JobTypesEnum.SUB_JOB ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.SUB_JOB}</Tag> : text == JobTypesEnum.NO_DOC ? <Tag style={{ backgroundColor: '#52c41a', color: "black", fontWeight: 'bold' }}>{JobTypesEnum.NO_DOC}</Tag> : ""}

        </>
      }

    },

  ];


  const EstimatedETDDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedEstimatedFromDate(fromDate)
    setSelectedEstimatedToDate(toDate)
  }
  const EstimatedCreatedDate = (value) => {
    if (value) {
      const fromDate = new Date(value[0].format('YYYY-MM-DD'));
      const toDate = new Date(value[1].format('YYYY-MM-DD'));

      setSelectedCreatedFromDate(fromDate)
      setSelectedCreatedToDate(toDate)
    } else {
      setSelectedCreatedFromDate(undefined)
      setSelectedCreatedToDate(undefined)
    }

  }

  const handleUnit = (value) => {
    setUnit(value)
  }
  const handleProdUnit = (value) => {
    setProcdUnit(value)
  }


  const handleStatus = (val) => {
    setSaleOrderStatus(val);
  }

  const handleLifeCycle = (value) => {
    setLifeCycleStatus(value);
  }
  const onReset = () => {
    // form.resetFields();
    form.resetFields();
    setSearchText('');
    getAllSaleOrders(undefined, undefined, null, 0, 0, 0, 0, 0, 0, [], 0, 0, "All", 0, 0,undefined)
    setSelectedEstimatedFromDate(undefined)
    setSelectedEstimatedToDate(undefined)
    setSelectedCreatedFromDate(undefined)
    setSelectedCreatedToDate(undefined)
    setDestinations([]);
    setVarientCode(0);
    setReadyForShipment('');
    setCountry([]); setProcdUnit(0); setUnit(0); setSelectedEndCustomer(0); setMasterBrandId(0);
    setSaleOrderStatus(null);
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ prodUnit: Number(localStorage.getItem('unit_id')) })
    }
    // window.location.reload();
  };

  const viewFilteredReport = (values) => {
    console.log(values);
    setFilters({ selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, country, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode });
    console.log({ filters });
    getAllSaleOrders(selectedEstimatedFromDate, selectedEstimatedToDate, saleOrderStatus, selectedEndCustomer, unit, prodUnit, country, destinations, selectedCreatedFromDate, selectedCreatedToDate, masterBrandId, varientCode, readyForShipment,lifeCycleCategory,lifeCycleStatus, cardType);
  }
  const data = [
    { title: "Customer PO", dataIndex: "poNumber" },
    { title: "Buyer Po Number", dataIndex: "buyerPoNo" },
    { title: "Po Date", dataIndex: "poDate", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Customer", dataIndex: "customer" },
    { title: "End Customer", dataIndex: "endCustomername" },
    { title: "Load Type", dataIndex: "loadType" },
    { title: "ETD", dataIndex: "expectedEndDate", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Revised ETD", dataIndex: "actualEtd", render: (value) => moment(value).format("DD-MM-YYYY") },
    { title: "Final Country", dataIndex: "countryName" },
    { title: "Final Destination", dataIndex: "destination" },
    { title: "BAP Unit", dataIndex: "unit" },
    { title: "Prod Unit", dataIndex: "processingUnitName" },
    { title: "Is Invoiced?", dataIndex: "isInvoiced" },
    { title: "Total Weight", dataIndex: "totnetWeight", render: (value) => Number(value) },
    { title: "Total Amount", dataIndex: "cost", render: (text, record) => {
      return <span>{Math.round(record.cost)}</span>
    } },
    { title: "Current Status", dataIndex: "lifeCyclestatus" },
    { title: "SO Status", dataIndex: "status" },
    { title: "Is Dispatched?", dataIndex: "dispatchStatus" },
    {title: "Packing", dataIndex: "packing"},
    { title: "Production Inventory", dataIndex: "rmStock" },
    { title: "Quality Assurance", dataIndex: "qualityAssurance" },
    { title: "FG Stock (Cases)", dataIndex: "fgStock" },
    { title: "Documentation", dataIndex: "doc" },
    { title: "Logistics", dataIndex: "logistics" },
    { title: "Job Type", dataIndex: "jobType" },

  ];
  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Saleorders')
      .addColumns(data)
      .addDataSource(saleOrderData, { str2num: true })
      .saveAs('saleOrderAbstract.xlsx');
  }
  const handleCategory = (value) =>{
    console.log(value)
    setLifeCycleCategory(value)
    GetSoLifeCycleStatusForCategory()
    form.resetFields(['lifeCycleStatus'])
  } 
  const GetSoLifeCycleStatusForCategory = () => {
    service.GetSoLifeCycleStatusForCategory({mainCategory:form.getFieldValue('lifeCycleCategory')}).then(res => {
      if (res.status) {
        setLifeCycleStatusforCategory(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  return (
    <>
      {(saleId) ? <Redirect to={{ pathname: "/saleorder-detail-view", state: saleId }} /> : null}

      <Card title={<span style={{ color: 'white' }}>SO Abstract</span>} extra={<Button onClick={() => { exportExcel(); }}>Get Excel</Button>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >

        <Form form={form} layout={'vertical'} style={{ padding: '0px' }} onFinish={viewFilteredReport} >
          <Row gutter={[24, 10]}>
            <Col span={5}>
              <Form.Item name="fromDate"
                label="PO Date"
                rules={[
                  {
                    required: false,
                    message: "Missing PO Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedETDDate} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="createdDate"
                label="Created Date"

                rules={[
                  {
                    required: false,
                    message: "Missing Created Date"
                  },
                ]}>
                <RangePicker onChange={EstimatedCreatedDate} />

              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="saleOrderStatus" label="SO Status" rules={[{ required: false }]}>
                <Select
                  placeholder="SO Status"
                  onChange={handleStatus}
                  allowClear
                  showSearch
                  mode="multiple" >
                  <Option key={'OPEN'} value={'OPEN'}>{'OPEN'}</Option>
                  <Option key={'INPROGRESS'} value={'INPROGRESS'}>{'INPROGRESS'}</Option>



                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="Unit" label=" Manufacturing Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  onChange={handleUnit}
                  allowClear
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="prodUnit" label=" Processing Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  onChange={handleProdUnit}
                  allowClear
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
              <Form.Item
                name="lifeCycleCategory"
                label="Life Cycle Category"
                rules={[
                  {
                    required: false, message: 'Missing Life Cycle category',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Life Cycle category"
                  allowClear
                  onChange={handleCategory}
                  mode="multiple"
                >
                  {Object.values(SoLifeCycleCategoryEnum).map(SoLifeCycleStatus => {
                    return <Option value={SoLifeCycleStatus}>{SoLifeCycleStatus}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
              <Form.Item
                name="lifeCycleStatus"
                label="Life Cycle Status"
                rules={[
                  {
                    required: false, message: 'Missing Life Cycle Status',
                  },
                ]}
              >
                <Select
                  placeholder="Select Life Cycle Status"
                  allowClear
                  onChange={handleLifeCycle}

                >
                  {Object.values(SoLifeCycleStatusEnum).map(SoLifeCycleStatus => {
                    return <Option value={SoLifeCycleStatus}>{SoLifeCycleStatus}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> */}
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
              <Form.Item
                name="lifeCycleStatus"
                label="Life Cycle Status"
                rules={[
                  {
                    required: false, message: 'Missing Life Cycle Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Life Cycle Status"
                  allowClear
                  onChange={handleLifeCycle}

                >
                 <Option key={0} value={null}>Select Life Cycle Status</Option>
                {lifeCycleStatusforCategory?.map((value) => {
                  return <Option key={value.subCategory} value={value.subCategory}>{value.subCategory}</Option>
                })}
                </Select>
              </Form.Item>
            </Col>
            <Col hidden>
              <Form.Item name={'cardType'} >
                <Input hidden />
              </Form.Item>
            </Col>



            {/* </Row>
           <Row style={{marginRight:'20px'}} gutter={24}> */}
            < Col style={{ paddingLeft: '50px', marginTop: '30px' }
            }>
              <Form.Item >
                <Button type="primary" htmlType="submit" disabled={disable} onClick={() => setDisplay('block')}> Get Details</Button>
              </Form.Item>
            </Col >
            <Col style={{ paddingLeft: '10px', marginTop: '30px' }}>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>

              </Form.Item>
            </Col>
          </Row >
        </Form >

        <br></br>

        <Table
          rowKey={record => record.saleOrderId}
          columns={columnsSkelton}
          dataSource={saleOrderData}

          // expandIconColumnIndex={6}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              setPageSize(pageSize)
            }
          }}
          onChange={onChange}
          scroll={{ x: 3800, y: 400 }}
          size="large"
          bordered
        >
        </Table>





      </Card >
    </>
  );
}


export default SaleOrderAbstractReport;
