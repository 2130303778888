export class StockReceiveData {
    transferLogId: number;
    receivedQuantity: number;
    receivedBoxes: number;
    updatedUser: string;
    constructor(transferLogId: number, receivedQuantity: number, receivedBoxes: number, updatedUser: string){
        this.transferLogId =  transferLogId;
        this.receivedQuantity =  receivedQuantity;
        this.receivedBoxes =  receivedBoxes;
        this.updatedUser =  updatedUser;
    }
}