import { ApiProperty } from '@nestjs/swagger';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, SizesDropDownDto } from '@gtpl/shared-models/masters';

export class SoItemsDataModel {
    saleOrderItemId: number;
    saleOrderId: number;
    itemCategory: ItemCategoryDropDownDto;
    itemName: ItemsDropDownDto;
    size: SizesDropDownDto;
    itemQuantity: number;
    itemCost: number;
    discount: number;
    tax: number;
    subAmount: number;
    // taxPercentage: number;
    // discountPercentage: number;

    /**
     * @param saleOrderItemId
    * @param saleOrderId
    * @param itemCategory
    * @param itemName
    * @param size
    * @param itemQuantity
    * @param itemCost
    * @param discount
    * @param tax
    * @param  subAmount
    * @param taxPercentage
    * @param discountPercentage
     */

    constructor(saleOrderItemId: number, saleOrderId: number, itemCategory: ItemCategoryDropDownDto, itemName: ItemsDropDownDto, size: SizesDropDownDto, itemQuantity: number, itemCost: number, discount: number, tax: number, subAmount: number) {
        this.saleOrderItemId = saleOrderItemId;
        this.saleOrderId = saleOrderId;
        this.itemCategory = itemCategory;
        this.itemName = itemName;
        this.size = size;
        this.itemQuantity = itemQuantity;
        this.itemCost = itemCost;
        this.discount = discount;
        this.tax = tax;
        this.subAmount = subAmount;
        // this.taxPercentage = taxPercentage;
        // this.discountPercentage = discountPercentage;
    }


}

