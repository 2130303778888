import { EmployeeRolesEnum } from "./enum/employee-enum";

export class UnitRequest{
    unitId:number;
    role?:EmployeeRolesEnum|string;
    state?:string
    constructor(unitId:number, role?:EmployeeRolesEnum|string,state?:string){
        this.unitId = unitId;
        this.role = role;
        this.state = state;
    }
}