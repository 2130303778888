import axios, { AxiosRequestConfig } from 'axios';
import connection from './connection';
import {VendorsDto,VendorsResponseModel,AllVendorsResponseModel, VendorRequest, VendorsDropDownResponseModel, VendorItemRequest} from '@gtpl/shared-models/masters'
export class VendorsService{
    URL = connection.DEPLOY_URL + '/Vendors';


    async create(dto: VendorsDto): Promise<VendorsResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createVendor',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: VendorsDto): Promise<VendorsResponseModel> {
    return await axios.post(this.URL + '/updateVendor', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: VendorsDto): Promise<VendorsResponseModel> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivateVendor', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(): Promise<AllVendorsResponseModel> {
                
                return await axios.post(this.URL + '/getAllVendors')
                    .then(res => {
                        return res.data 
                    })     
            }

            async getVendorDataById(vendorRequest:VendorRequest): Promise<VendorsResponseModel> {
                return await axios.post(this.URL + '/getVendorDataById',vendorRequest)
                    .then(res => {
                        return res.data 
                    })     
            }
            
          /**
           * get vendors for drop down
          */
           async getVendorsDropDownData(): Promise<VendorsDropDownResponseModel> {
                return await axios.post(this.URL + '/getVendorsDropDownData',undefined)
                    .then(res => {
                        return res.data 
                    })     
            }
            


            async getCurrenciesByVendorId(vendorRequest:VendorRequest): Promise<AllVendorsResponseModel> {
                return await axios.post(this.URL + '/getCurrenciesByVendorId',vendorRequest)
                .then(res => {
                    return res.data 
                })   
            }

}