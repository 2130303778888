import { CommonResponse } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';
import { ContainerLogBookIdRequestDTO } from '@gtpl/shared-models/logistics'
export class LogBookItemsService{
    URL = connection.DEPLOY_URL + '/log-book-items';

    async getLogBookItemDetails(req:ContainerLogBookIdRequestDTO): Promise<CommonResponse> {
      return await axios.post(this.URL + '/getLogBookItemDetails', req)
          .then(res => {
              return res.data
          })
    }
}
