import { BrandsTrimsDto } from "./brands-trims.dto";

export class MasterBrandDto {
    masterBrandId?: number;
    masterBrandName: string;
    isPouchVariantConsider: string;
    isPouchBapNoConsider: string;
    isPouchGradeConsider: string;

    isPouchFoodTypeConsider: string;
    isPouchProductConsider: string;
    isPouchValAddOneConsider: string;
    isPouchValAddTwoConsider: string;
    isPouchValAddThreeConsider: string;
    isPouchValAddFourConsider: string;
    isPouchValAddFiveConsider: string;

    isCartonVariantConsider: string;
    isCartonBapNoConsider: string;
    isCartonGradeConsider: string;

    isCartonFoodTypeConsider: string;
    isCartonProductConsider: string;
    isCartonValAddOneConsider: string;
    isCartonValAddTwoConsider: string;
    isCartonValAddThreeConsider: string;
    isCartonValAddFourConsider: string;
    isCartonValAddFiveConsider: string;

    filePath: string;
    fileName: string;
    isActive: boolean;
    createdAt: Date | any;
    createdUser: string;
    updatedAt: Date | any;
    updatedUser: string;
    versionFlag: number;
    displayBrandName:string;
    trimsInfo?: BrandsTrimsDto[];

    /**
     * 
     * @param masterBrandId number
     * @param masterBrandName string
     * @param filePath string
     * @param fileName string
     * @param isActive boolean
     * @param createdAt Date
     * @param createdUser string
     * @param updatedAt Date
     * @param updatedUser string
     * @param versionFlag number
     */
    constructor(masterBrandId: number, masterBrandName: string, filePath: string, fileName: string, isActive: boolean, createdAt: Date | any, createdUser: string, updatedAt: Date | any,
        updatedUser: string, versionFlag: number, isPouchVariantConsider: string,
        isPouchBapNoConsider: string,
        isPouchGradeConsider: string,
    
        isPouchFoodTypeConsider: string,
        isPouchProductConsider: string,
        isPouchValAddOneConsider: string,
        isPouchValAddTwoConsider: string,
        isPouchValAddThreeConsider: string,
        isPouchValAddFourConsider: string,
        isPouchValAddFiveConsider: string,
    
        isCartonVariantConsider: string,
        isCartonBapNoConsider: string,
        isCartonGradeConsider: string,
    
        isCartonFoodTypeConsider: string,
        isCartonProductConsider: string,
        isCartonValAddOneConsider: string,
        isCartonValAddTwoConsider: string,
        isCartonValAddThreeConsider: string,
        isCartonValAddFourConsider: string,
        isCartonValAddFiveConsider: string,
        displayBrandName:string,
        trimsInfo?:BrandsTrimsDto[]) {
        this.masterBrandId = masterBrandId;
        this.masterBrandName = masterBrandName;
        this.filePath = filePath;
        this.fileName = fileName;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isPouchBapNoConsider = isPouchBapNoConsider;
        this.isPouchGradeConsider = isPouchGradeConsider;
        this.isPouchVariantConsider = isPouchVariantConsider;
        this.isPouchFoodTypeConsider = isPouchFoodTypeConsider;
        this.isPouchProductConsider = isPouchProductConsider;
        this.isPouchValAddOneConsider = isPouchValAddOneConsider;
        this.isPouchValAddTwoConsider = isPouchValAddTwoConsider;
        this.isPouchValAddThreeConsider = isPouchValAddOneConsider;
        this.isPouchValAddFourConsider = isPouchValAddFourConsider;
        this.isPouchValAddFiveConsider = isPouchValAddFiveConsider;
        this.isCartonBapNoConsider = isCartonBapNoConsider;
        this.isCartonGradeConsider = isCartonGradeConsider;
        this.isCartonVariantConsider = isCartonVariantConsider;
        this.isCartonFoodTypeConsider = isCartonFoodTypeConsider;
        this.isCartonProductConsider = isCartonProductConsider;
        this.isCartonValAddOneConsider = isCartonValAddOneConsider;
        this.isCartonValAddTwoConsider = isCartonValAddTwoConsider;
        this.isCartonValAddThreeConsider = isCartonValAddOneConsider;
        this.isCartonValAddFourConsider = isCartonValAddFourConsider;
        this.isCartonValAddFiveConsider = isCartonValAddFiveConsider;
        this.displayBrandName = displayBrandName;
        this.trimsInfo = trimsInfo
    }
}
export const MasterBrandDtoDefault: MasterBrandDto = {
    masterBrandId: 0,
    masterBrandName: '',
    fileName: '',
    filePath: '',
    isActive: true,
    createdAt: new Date(),
    createdUser: '0',
    updatedAt: new Date(),
    updatedUser: '0',
    versionFlag: 1,
    isPouchVariantConsider: '',
    isPouchBapNoConsider: '',
    isPouchGradeConsider: '',
    isPouchFoodTypeConsider: '',
    isPouchProductConsider: '',
    isPouchValAddOneConsider: '',
    isPouchValAddTwoConsider: '',
    isPouchValAddThreeConsider: '',
    isPouchValAddFourConsider: '',
    isPouchValAddFiveConsider: '',
    isCartonVariantConsider: '',
    isCartonBapNoConsider: '',
    isCartonGradeConsider: '',
    isCartonFoodTypeConsider: '',
    isCartonProductConsider: '',
    isCartonValAddOneConsider: '',
    isCartonValAddTwoConsider: '',
    isCartonValAddThreeConsider: '',
    isCartonValAddFourConsider: '',
    isCartonValAddFiveConsider: '',
    displayBrandName:'',
    trimsInfo:[]
}; 