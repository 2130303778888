export class TrimsItemMappingDto{
    trimItemId:number; 
   subcategoryId:number;
    itemId:number;
    trimId:number;
    AgainstTo:string;
    numberOfTrims:number;
    isActive:boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    constructor(
        trimItemId:number,
        subcategoryId:number,
         itemId:number,
         trimId:number,
         AgainstTo:string,
         numberOfTrims:number,
         isActive:boolean,
         createdAt: Date,
         createdUser: string | null,
         updatedAt: Date,
         updatedUser: string | null,
         versionFlag: number,

    ){
        
this.trimItemId=trimItemId;
this.subcategoryId=subcategoryId;
this.itemId=itemId;
this.trimId=trimId;
this.AgainstTo=AgainstTo;
this.numberOfTrims=numberOfTrims;
this.isActive=isActive;
this.createdAt=createdAt;
this.createdUser=createdUser;
this.updatedAt=updatedAt;
this.updatedUser=updatedUser;
this.versionFlag=versionFlag;
    }
  

}