import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { LotNoInfo } from './lot-number-info.dto';

export class LotNumberInfoResponse extends GlobalResponseObject{
    data?: LotNoInfo;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data //LotNoInfo
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: LotNoInfo) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }    
}
