import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { IdProofsTypeDto } from './id-proofs-type-dto';


export class AllIdProofTypesResponseModel extends GlobalResponseObject {
    data?: IdProofsTypeDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: IdProofsTypeDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

