
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { BomWithCountAndQuantityModel } from './bom-with-count-quantity.model';


export class BomWithCountAndQuantityResponse extends GlobalResponseObject {
    data?: BomWithCountAndQuantityModel[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /BomPackingItemsDto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: BomWithCountAndQuantityModel[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}
