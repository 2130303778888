import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderDetailViewInfoDTO } from '.';


export class GetSaleOrderInformationResponseModel extends GlobalResponseObject {
    data?: SaleOrderDetailViewInfoDTO;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /SODto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SaleOrderDetailViewInfoDTO) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 