export class SaleOrderInfo {
    saleOrderId: number;
    saleOrderNumber: string;
    category:string;
    poNo:string;
    noOfCases:number;
    noOfPouches:number;
    noOfInnerBags:number;
    additionalNote:string;
    constructor(saleOrderId: number, saleOrderNumber: string, category:string, poNo:string, noOfCases:number, noOfPouches:number,
        noOfInnerBags:number, additionalNote:string){
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.category =category;
        this.poNo =poNo;
        this.noOfCases =noOfCases;
        this.noOfPouches =noOfPouches;
        this.noOfInnerBags =noOfInnerBags;
        this.additionalNote =additionalNote;
    }
}