import { GlobalResponseObject } from "@gtpl/shared-models/common-models";

export class StockAssigningResponse extends GlobalResponseObject{
    data?: string;

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: string){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}