import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider, Modal} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {PdQaqcForm} from '@gtpl/pages/production-management/production-management-components/peeling-preparation-form';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';

import moment from 'moment';
import { PdQaqcDto } from '@gtpl/shared-models/production-management';
import { ColumnProps } from 'antd/lib/table';
import { PdQaqcService } from '@gtpl/shared-services/production';
export interface PdQaqcPrintProps {}

export function PdQaqcPrint(props: PdQaqcPrintProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');
  const [peelingData, setPeelingData] = useState<PdQaqcDto[]>([]);
  const [slectedInspectionData, setSelectedInspectionData] = useState<any>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const service = new PdQaqcService()

  useEffect(() => {
    getAll();
  },[]);
     

 
  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  const getAll = () => {

    service.getAll().then(res => {
      if (res.status) {
        setPeelingData(res.data);
      } else {
        if (res.intlCode) {
          setPeelingData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPeelingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    
    const columnsSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['md'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
  
      {
        title: 'PD Date ',
        dataIndex: 'peelingDate',
        // responsive: ['lg'],
        responsive: ['md'],
        sorter: (a, b) => a.peelingDate?.localeCompare(b.peelingDate),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('labellingDate'),
        render : (value) => moment(value).format('DD-MM-YYYY')
      },
      {
        title: 'Lot Number',
        dataIndex: 'lotNumber',
        // responsive: ['lg'],
        sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('lotNumber')
      },
      {
        title: 'SO Number',
        dataIndex: 'soNumber',
        // responsive: ['lg'],
        sorter: (a, b) => a.soNumber - b.soNumber,
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('soNumber')
      },
      {
        title: 'SO Item Name',
        dataIndex: 'item',
        // responsive: ['lg'],
        sorter: (a, b) => a.item?.localeCompare(b.item),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('item')
      },
     
      // {
      //   title: 'Remarks',
      //   dataIndex: 'remarks',
      //   // responsive: ['lg'],
      //   sorter: (a, b) => a.remarks.length - b.remarks.length,
      //   sortDirections: ['descend', 'ascend'],
      //   ...getColumnSearchProps('productName')
      // },
      // {
      //   title: `Action`,
      //   dataIndex: 'action',
      //   render: (text, rowData) => (
      //     <span>
      //       {/* <EditOutlined className={'editSamplTypeIcon'} type="edit"
      //         onClick={() => {
      //           if (rowData.isActive) {
      //             openFormWithData(rowData);
      //           } else {
      //             AlertMessages.getErrorMessage('You Cannot Edit Deactivated Labelling');
      //           }
      //         }}
      //         style={{ color: '#1890ff', fontSize: '14px' }}
      //       /> */}
  
      //       <Divider type="vertical" />
      //       <Popconfirm onConfirm={e => { deleteLabelling(rowData); }}
      //         title={
      //           rowData.isActive
      //             ? 'Are you sure to Deactivate Labelling  ?'
      //             : 'Are you sure to Activate Labelling ?'
      //         }
      //       >
      //         <Switch size="default"
      //           className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
      //           checkedChildren={<RightSquareOutlined type="check" />}
      //           unCheckedChildren={<RightSquareOutlined type="close" />}
      //           checked={rowData.isActive}
      //         />
  
      //       </Popconfirm>
      //     </span>
      //   )
      // },
      {
        title: 'Action',
        dataIndex: 'peeling',
        render: (text, rowData: PdQaqcDto, index) => (
          <span> <Button type="primary" shape="round"  size="small"
            // style={{ color: 'red' }}
            onClick={() => displayModel(rowData)}> Add Samples </Button>
          </span>
        )
      },
    ];

    const displayModel = (data: PdQaqcDto) => {
      setSelectedInspectionData(data);
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      getAll();
      setIsModalVisible(false);
    };
  

  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>PD</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/pd-qaqc' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
        </Row>
          <br></br>
          <Table
        rowKey={record => record.peelingId}
        columns={columnsSkelton}
        dataSource={peelingData}

        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered
        scroll={{ x: true }} />

          <Modal
            className='peeling'
            key={'modal' + Date.now()}
            width={'80%'}
            style={{ top: 30, alignContent: 'right' }}
            visible={isModalVisible}
            title={<React.Fragment>
            </React.Fragment>}
            onCancel={handleCancel}
            footer={[

            ]}
          >
              <PdQaqcForm key={Date.now()} 
                peelingData={slectedInspectionData}
                isUpdate={true}                
                closeForm={handleCancel} />
            </Modal>
          
     </Card>
    </>
  );
}

export default PdQaqcPrint;


