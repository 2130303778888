export class AssetAssignmentTransactionDto {

    assetAssignmentTransactionId: number;
    assetAssignmentId: number;
    assetLocationId : number;
    assetsId : number;
    employeeId : number;
    // employeePhNum : string;
    // checkIn : Date;
    // checkOut: Date;
    isActive: boolean;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
    checkIn?:Date;
    assetsCode?: string;
    itemName?: string;
    location?: string;
    employeeName?: string;
    checkOut?: Date;
    employeePhNum?: string;
    department?: string;
    assetCost?: string;
    assetCategory?: string;
    status?: string;
    plant?: string;
    assetType?: string;
    invoiceNo?:string;
    assetSerialNo?:string;
    assetMode?:string;

    constructor(
        assetAssignmentTransactionId: number,
        assetAssignmentId: number,
        assetLocationId : number,
        assetsId: number,
        employeeId : number,
        // employeePhNum: string,
        // checkIn : Date,
        // checkOut: Date,
        isActive : boolean,
        versionFlag: number,
        checkIn?:Date,
        assetsCode?: string,
        itemName?: string,
        location?: string,
        employeeName?: string,
        checkOut?: Date,
        employeePhNum?: string,
        department?: string,
        assetCost?: string,
        assetCategory?: string,
        status?: string,
        plant?: string,
        assetType?: string,
        invoiceNo?:string,
        assetSerialNo?:string,
        assetMode?:string,
    ) {
        this.assetAssignmentTransactionId = assetAssignmentTransactionId;
        this.assetAssignmentId = assetAssignmentId;
        this.assetLocationId = assetLocationId;
        this.assetsId = assetsId;
        this.employeeId = employeeId;
        //this.employeePhNum = employeePhNum;
        // this.checkIn = checkIn;
        // this.checkOut = checkOut;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
        this.checkIn = checkIn;
        this.assetsCode = assetsCode;
        this.itemName = itemName;
        this.location = location;
        this.employeeName = employeeName;
        this.checkOut = checkOut;
        this.employeePhNum = employeePhNum;
        this.department = department;
        this.assetCost = assetCost;
        this.assetCategory = assetCategory;
        this.status = status;
        this.plant = plant;
        this.assetType = assetType;
        this.invoiceNo = invoiceNo;
        this.assetSerialNo = assetSerialNo;
        this.assetMode = assetMode;
    }
}