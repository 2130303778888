export class SizeIdRequest {
    itemCategoryId: number;
    itemSubCategoryId: number;
    itemId: number;
    unitId: number;
    saleOrderId?: number;
    constructor(itemCategoryId: number, itemSubCategoryId: number, itemId: number, unitId: number, saleOrderId?: number) {
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemId = itemId;
        this.unitId = unitId;
        this.saleOrderId = saleOrderId;
    }
}