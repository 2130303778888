import { GlobalStatus } from "@gtpl/shared-models/common-models";
import { ItemCategoryDropDownDto, ItemsDropDownDto, ItemSubCategoryDropDownDto, SizesDropDownDto } from "@gtpl/shared-models/masters";

export class BomPackingItemsDto {
    itemCategoryDetails:ItemCategoryDropDownDto;
    itemSubCategoryDetails:ItemSubCategoryDropDownDto;
    itemDetails:ItemsDropDownDto;
    size:SizesDropDownDto;
    quantity:number;
    grnQuantity?:number;
    poQuantity?:number;
    existingQuantity?:number;
    status:string;
    packingMaterialId?:number;
    createdUser? : string;
    saleOrderId?:number;
    updatedUser? : string;
    avilableQuantity?: number;
    requiredQuantity?: number;
    receivedQuantity?: number;
    packingCategory?:string;
    isActive?:boolean;
    assign?:string; // button for every record to assign
    changedQty?:boolean; // change the required qty to disable assign button
    AssignedQuantity?:number;
    ToBeAssignedQuantity?:number;
    poQty?:number;
    saleOrderNumber?:string;
    isRequired?:GlobalStatus;
    isActiveRevised?:GlobalStatus;
    isQtyRevised?:GlobalStatus;

    /**
     * 
     * @param saleOrderId 
     * @param itemCategoryDetails 
     * @param itemDetails 
     * @param size 
     * @param quantity 
     * @param createdUser 
     * @param updatedUser 
     * @param packingMaterialId 
     */
    constructor(itemCategoryDetails:ItemCategoryDropDownDto, itemDetails:ItemsDropDownDto, itemSubCategoryDetails:ItemSubCategoryDropDownDto, size:SizesDropDownDto, quantity:number, status:string, packingMaterialId?:number, createdUser? : string, saleOrderId?:number, updatedUser? : string, packingCategory?:string, isActive?:boolean, avilableQuantity?:number,requiredQuantity?:number,receivedQuantity?:number, AssignedQuantity?:number, poQty?:number, saleOrderNumber?:string, isRequired?:GlobalStatus, isActiveRevised?:GlobalStatus, isQtyRevised?:GlobalStatus){
        this.saleOrderId = saleOrderId;
        this.itemCategoryDetails = itemCategoryDetails;
        this.itemSubCategoryDetails = itemSubCategoryDetails;
        this.itemDetails = itemDetails;
        this.size = size;
        this.quantity = quantity;
        this.status = status;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.packingMaterialId = packingMaterialId;
        this.packingCategory = packingCategory;
        this.isActive = isActive;
        this.avilableQuantity = avilableQuantity;
        this.requiredQuantity = requiredQuantity;
        this.receivedQuantity = receivedQuantity;
        this.AssignedQuantity = AssignedQuantity;
        this.poQty = poQty;
        this.saleOrderNumber = saleOrderNumber;
        this.isRequired = isRequired;
        this.isActiveRevised = isActiveRevised;
        this.isQtyRevised = isQtyRevised;
    }
}