import { ContractorDto, ContractRequest, ContractResponseModel } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class ContractorService{

    URL = connection.DEPLOY_URL + '/contractor';

    async createContractor(data: ContractorDto): Promise<ContractResponseModel>{        
        return await axios.post(this.URL + '/createContractor', data)
            .then(res => {
                return res.data
            })
    }

    async updateContractor(data: ContractorDto): Promise<ContractResponseModel>{
        return await axios.post(this.URL + '/updateContractor', data)
            .then(res => {
                return res.data
            })
    }

    async getAllContractorActions(): Promise<ContractResponseModel>{
       
        return await axios.post(this.URL + '/getAllContractorActions')
           .then(res => {
               return res.data
           })
    }

    async getAllActiveContractActions(): Promise<ContractResponseModel>{
        return await axios.post(this.URL + '/getAllActiveContractActions')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateContractor(data: ContractRequest): Promise<ContractResponseModel>{
        return await axios.post(this.URL + '/activateOrDeactivateContractor', data)
            .then(res => {
                return res.data
            })
    }
}