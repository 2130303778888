export class PalletModel{
    palletId: number;
    palletNo: string;
    plantId: number;
    plantName: string;
    assocRfId: string;
    occupancy: string;
    createdDate: Date;
    modifiedDate: Date;
    isActive: boolean;
    versionFlag: number;



    /**
     * 
     * @param palletId 
     * @param palletNo 
     * @param plantId 
     * @param assocRfId 
     * @param occupancy
     * @param createdDate 
     * @param modifiedDate 
     */
    constructor(palletId:number,palletNo:string,plantId: number,plantName:string,assocRfId: string,occupancy: string,createdDate: Date, modifiedDate: Date,isActive: boolean,versionFlag: number){
        this.palletId = palletId;
        this.palletNo = palletNo;
        this.plantId = plantId;        
        this.plantName = plantName;        
        this.assocRfId = assocRfId;
        this.occupancy = occupancy;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
        this.isActive = isActive;
        this.versionFlag = versionFlag;
    }
}