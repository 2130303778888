import axios from 'axios';
import { AllvalueAdditionFiveResponse, valueAdditionFiveResponse,ValueAdditionFiveDto, ValueAdditionIdRequest, valueAdditionIdResponse } from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class TypeofValueAddition5Service {
URL = connection.DEPLOY_URL + '/value-addition5-types';

        async createTypeOfValueAddition5(product: ValueAdditionFiveDto): Promise<valueAdditionFiveResponse> {
            return await axios.post(this.URL + '/createValAddFiveType',product)
                .then(res => {
                    return res.data
                })
        }
        async  updateTypeOfValueAddition5(product: ValueAdditionFiveDto): Promise<valueAdditionFiveResponse> {
        return await axios.post(this.URL + '/updateValAddFiveType', product)
                        .then(res => {
                            return res.data
                        })
                }
            async getAllTypeOfValueAddition5(req?:UserRequestDto): Promise<AllvalueAdditionFiveResponse> {
                
                // return new AllvalueAdditionFiveResponse(true,1111,'Type of Value Addition 5 retrieved successfully',[new ValueAdditionFiveDto(1,'Some Code','Some Name','Remark 1','Me',true)])
                return await axios.post(this.URL + '/getAllValAddTypes',req)
                    .then(res => {
                        return res.data
                    })     
            }
                async  activatedActivateTypeOfValueAddition5(ValueAdditionFiveDto: ValueAdditionFiveDto): Promise<valueAdditionFiveResponse> {
                    //   console.log(State.stateCode);
                    return await axios.post(this.URL + '/activateOrDeactivateValAddFiveType', ValueAdditionFiveDto)
                            .then(res => {
                                return res.data
                            })
                }
            async  getAllActiveTypeOfValueAddition5(): Promise<AllvalueAdditionFiveResponse> {
                return await axios.post(this.URL + '/getAllActiveValAddTypes')
                    .then(res => {
                        return res.data
                    })
            }
            async getActiveTypeOfValueAddition5Count(): Promise<AllvalueAdditionFiveResponse> {
            return await axios.post(this.URL + '/getActiveCount').then(res => {
                return res.data
            });
            }

    // async getValAdditionFiveTypes(){
    //     //   alert('hai')
    //     return new ValAddFiveDropDownDataResponseModel(true,11,'customersData retrived successfully',[new ValAddFiveDropDownData(1,'QC'),new ValAddFiveDropDownData(2,'Block')])
    // }

     /**
     * Function to get valAddDetails
     * @param valueAdditionId  
     */
    async  getValAddDetails(valAddOneReq: ValueAdditionIdRequest): Promise<valueAdditionIdResponse> {
        return await axios.post(this.URL + '/getValAddDetails', valAddOneReq).then(res => {return res.data});
    }

}