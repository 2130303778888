import { UnitTypeEnum } from "./unit-type.enum";

export class UnitcodeDto{
    unitcodeId : number;
    unitcodeName : string;
    plantType: UnitTypeEnum;
    isActive: boolean;
    createdUser: string;
    // updatedUser:string;
    villageName?: string;
    mandalName?: string;
    distirctName?: string;
    stateName?: string;
    countryName?: string;
    fdaRegNumber?: string;
    feiNumber?: string;
    shortKey?: string;
    postalCode? :string;
    plantCode?: string;
    bapNo?: string;
    approvalNo?:string;
    ascNo?:string;
    walmartId?:string;
    fishInapprovalNo?:string;
    krogerNo?:string;
    updatedUser?:string;

    

     /**
     * 
     * @param unitcodeId number
     * @param unitcodeName string
     * @param isActive boolean
     * @param createdUser string
     * @param updatedUser string
     * 
     */
      constructor(unitcodeId : number,unitcodeName : string,plantType:UnitTypeEnum , isActive: boolean,createdUser : string,
        // updatedUser:string,
         villageName?: string,
        mandalName?: string,
        distirctName?: string,
        stateName?: string,
        countryName?: string,
        fdaRegNumber?: string,
        feiNumber?: string,
        shortKey?: string,
        postalCode?:string,
        plantCode?:string,bapNo?:string, approvalNo?:string,ascNo?:string,walmartId?:string,fishInapprovalNo?:string,krogerNo?:string,updatedUser?:string)
        {
        this.unitcodeId = unitcodeId;
        this.unitcodeName = unitcodeName;
        this.isActive= isActive;
        this.createdUser= createdUser;
        // this.updatedUser= updatedUser;

        this.plantType= plantType;
        this.villageName = villageName;
        this.mandalName = mandalName;
        this.distirctName= distirctName;
        this.stateName= stateName;
        this.countryName= countryName;
        this.fdaRegNumber= fdaRegNumber;
        this.feiNumber= feiNumber;
        this.shortKey= shortKey;
        this.postalCode=postalCode;
        this.plantCode=plantCode;
        this.bapNo = bapNo;
        this.approvalNo=approvalNo;
        this.ascNo=ascNo;
        this.walmartId=walmartId;
        this.fishInapprovalNo=fishInapprovalNo;
        this.krogerNo=krogerNo;
        this.updatedUser=updatedUser;
    }

}
export const UnitcodeDtoDefault :UnitcodeDto = {
    unitcodeId: 0,
    unitcodeName: '',
    plantType: UnitTypeEnum.PLANT,
    isActive: true,
    createdUser : '',
    updatedUser : '',

    
   


}; 