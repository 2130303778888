import { DeliveryTermsDropDownDto, DeliveryTermsDropDownResponseModel, DeliveryTermsRequest, DeliveryTermsResponseModel ,AllDeliveryTermsResponseModel} from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";
import {DeliveryTermsDto} from '@gtpl/shared-models/masters'
import { UserRequestDto } from "@gtpl/shared-models/common-models";

export class DeliveryTermsService {

    URL = connection.DEPLOY_URL + '/delivery-terms';

    async create(dto: DeliveryTermsDto): Promise<DeliveryTermsResponseModel> {
       
        console.log(dto);
       
        return await axios.post(this.URL + '/createDeliveryTerms',dto)
            .then(res => {
                return res.data
            })
}


async  update(dto: DeliveryTermsDto): Promise<DeliveryTermsResponseModel> {
    return await axios.post(this.URL + '/updateDeliveryTerms', dto)
                    .then(res => {
                        return res.data
                    })
            }


            async  activatedeActivate(Dto: DeliveryTermsDto): Promise<DeliveryTermsResponseModel> {
                //   console.log(State.stateCode);
                return await axios.post(this.URL + '/activateOrDeactivateDeliveryTerms', Dto)
                        .then(res => {
                            return res.data
                        })
            }



            async getAll(req:UserRequestDto): Promise<AllDeliveryTermsResponseModel> {
                
               
                return await axios.post(this.URL + '/getAllDeliveryTerms',req)
                    .then(res => {
                        return res.data 
                    })     
            }


    async getAllDeliveryTermsDropDown(): Promise<DeliveryTermsDropDownResponseModel> {
        return await axios.post(this.URL + '/getAllDeliveryTermsDropDown').then(res => {
            return res.data
        });
        // return new DeliveryTermsDropDownResponseModel(true,11,'customersData retrived successfully',[new DeliveryTermsDropDownDto(1,'Dterm1'),new DeliveryTermsDropDownDto(2,'Dterm2')])
    }
    async getDeliveryTermsById(deliveryTermsRequest:DeliveryTermsRequest): Promise<DeliveryTermsResponseModel> {
        console.log(this.URL);
        return await axios.post(this.URL + '/getDeliveryTermsById',deliveryTermsRequest).then(res => {
            return res.data
        });
    }
}