import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { DtoOfRecruitmentDashboard } from './recruitment-report-dto';



export class ResponseObjectForRecruitment extends GlobalResponseObject {
    data?:DtoOfRecruitmentDashboard [];

 /**
  * 
  * @param status 
  * @param intlCode 
  * @param internalMessage 
  * @param data 
  * 
  */

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: DtoOfRecruitmentDashboard[]) {
        super(status, intlCode, internalMessage);

        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}