import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popconfirm, Table, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Form, DatePicker, Select, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import Link from 'antd/lib/typography/Link';

import {AllIndentReportDto, IndentRtRequest, IndentDropdownModel} from '@gtpl/shared-models/raw-material-procurement';
import {IndentService} from '@gtpl/shared-services/raw-material-procurement';
import moment from 'moment';

import './rm-procurement-report-grid.css';
import { SupplierTypeEnum } from '@gtpl/shared-models/common-models';

/* eslint-disable-next-line */
export interface RmProcurementReportGridProps {}

export function RmProcurementReportGrid(
  props: RmProcurementReportGridProps
) {

  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const [indentData, setIndentData] = useState<IndentDropdownModel[]>([]);
  const [indentReportData, setIndentReportData] = useState<any>([]);
  const service = new IndentService();

  const [display, setDisplay] = useState<string>('none');

  useEffect(() => {
    getAllindentReport();
    getAllIndentCodes();
  }, [])

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getAllindentReport = (req?) => {
    console.log(req);
    service.getIndentReportDetails(req).then(res => {
      console.log(res.data);
      if (res.status) {
        console.log(res.data);
        setIndentReportData(res.data);

      } else {
        if (res.intlCode) {
          setIndentReportData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setIndentReportData([]);
      AlertMessages.getErrorMessage(err.message);

    })
  }


  const getReportData = () => {
    console.log(form.getFieldValue('indentCode'));
    const indentFromDate = form.getFieldValue('indentFromDate')===undefined?null:form.getFieldValue('indentFromDate')===null?null:moment(form.getFieldValue('indentFromDate')).format('YYYY-MM-DD');
    console.log(indentFromDate);

    const indentToDate = form.getFieldValue('indentToDate')===undefined?null:form.getFieldValue('indentToDate')===null?null:moment(form.getFieldValue('indentToDate')).format('YYYY-MM-DD');
    const indentCode = form.getFieldValue('indentCode');
    const harvestDate = form.getFieldValue('harvestDate')===undefined?null:form.getFieldValue('harvestDate')===null?null:moment(form.getFieldValue('harvestDate')).format('YYYY-MM-DD');
    const supplierType = form.getFieldValue('supplierType');

    const req = new IndentRtRequest();
    req.fromDate = indentFromDate;
    req.toDate = indentToDate;
    req.indentCode = indentCode;
    req.harvestingDate = harvestDate;
    req.supplierType = supplierType;
    getAllindentReport(req);
  }

  const getAllIndentCodes = () => {    
    service.getIndentsForDropdown().then(res => {
      console.log(res.data);
      if (res.status) {
        console.log(res.data);
        setIndentData(res.data);

      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);

    })
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const { Option } = Select;
  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 8,
    },
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  function unique(data, key){
    return [
        ...new Map(
          data.map(x=> [key(x),x])
        ).values()
    ]
  }

  const handleIndentCode=(value, item) => {
    const newIndentReportData = indentReportData.filter(indent =>
       indent.indentCode == value
      )

    setIndentReportData(newIndentReportData);
    // setIndentData(JSON.parse(JSON.stringify(unique(newIndentReportData, it => it.indentCode)))); 
  }
  const handleSupplierType=(value, item) => {
    const newIndentReportData = indentReportData.filter(indent =>
       indent.supplierType === value
      )

    setIndentReportData(newIndentReportData);
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
        title: 'S No',
        key: 'sno',
        width: 100,
        // fixed: 'left',
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
        title: 'Indent Date',
        dataIndex: 'indentDate',
        //fixed: 'right',
        width: 200,
        // fixed: 'left',
        align: 'left',
        sorter: (a, b) => a.indentDate - b.indentDate,
        ...getColumnSearchProps('indentDate'),
        sortDirections: ['descend', 'ascend'],
  
  
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.isInvoiced === value;
        },
        render: (value, record: AllIndentReportDto, index) => {
          return moment(record.indentDate).format('YYYY-MM-DD');
        }
      },
      {
        title: 'Indent Code',
        key: 'indentCode',
        dataIndex: 'indentCode',
        width: 170,
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('indentCode'),
        sorter: (a, b) => a.indentCode.localeCompare(b.indentCode),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Supplier Type',
        dataIndex: 'supplierType',
        width: 150,
        align: 'left',
        
        filters: [
          {
            text: 'Agent',
            value: 'Agent',
          },
          {
            text: 'Broker',
            value: 'Broker',
          },
          {
            text: 'Farmer',
            value: 'Farmer',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.supplierType === value;
        },
        sorter: (a, b) => a.supplierType.localeCompare(b.supplierType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Harvest Date',
        dataIndex:'harvestingDate',
        key: 'harvestingDate',      
        width: 200,
        align: 'left',
        // fixed: 'left',
        sorter: (a, b) => moment(a.harvestingDate).unix() - moment(b.harvestingDate).unix(),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {return moment(record.harvestingDate).format('YYYY-MM-DD')}
  
      },
      {
        title: 'Product Code',
        dataIndex:'productCode',
        key: 'productCode',
        width: 170,
        align: 'left',
        // fixed: 'left',
        // render: (rowData) => {return rowData.product},
        sorter: (a, b) => a.productCode.localeCompare(b.productCode),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('productCode')
      },
      {
        title: 'Indent Quantity',
        dataIndex:'expectedQty',
        key: 'expectedQty',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.expectedQty - b.expectedQty,
        sortDirections: ['descend', 'ascend'],
  
      },
      {
        title: 'Indent Price',
        dataIndex:'expectedPrice',
        key: 'expectedPrice',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.expectedPrice - b.expectedPrice,
        sortDirections: ['descend', 'ascend'],
  
      },
      {
        title: 'Indent Count',
        dataIndex:'expectedCount',
        key: 'expectedCount',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.expectedCount - b.expectedCount,
        sortDirections: ['descend', 'ascend'],
  
      },
      {
        title: 'GRN Date',
        dataIndex: 'grnDate',
        align: 'left',
        width: 170,
        // fixed: 'left',
        sorter: (a, b) => a.grnDate - b.grnDate,
        ...getColumnSearchProps('grnDate'),
        sortDirections: ['descend', 'ascend'],
  
  
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.isInvoiced === value;
        },
        render: (value, record: AllIndentReportDto, index) => {
          return record.grnDate!=null?moment(record.grnDate).format('YYYY-MM-DD'):"-";
        }
      },
      {
        title: 'GRN Quantity',
        dataIndex:'quantity',
        key: 'quantity',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.quantity - b.quantity,
        sortDirections: ['descend', 'ascend'],
  
      },
      {
        title: 'GRN Price',
        dataIndex:'price',
        key: 'price',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['descend', 'ascend'],
  
      },
      {
        title: 'GRN Count',
        dataIndex:'count',
        key: 'count',
        width: 170,
        align: 'right',
        // fixed: 'left',
        sorter: (a, b) => a.count - b.count,
        sortDirections: ['descend', 'ascend'],
  
      },
      
  ];

 

  return (
    <>
    <Card title={<span style={{ color: 'white' }}>Indent Report</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
        <Form layout={"vertical"} autoComplete="off" form={form} >
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="indentFromDate" label="Indent From Date">
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                  showToday={true} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="indentToDate" label="Indent To Date">
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                  showToday={true} />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item
                name="indentCode"
                label="IndentCode"
              >
                <Select
                  placeholder="Select Indent Code"
                  optionFilterProp="children"
                  onChange={handleIndentCode}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                    <Option key={0} value={null}>Select Indent Code</Option>

                  {indentData.map((indentData) => {
                    return <Option key={indentData.indentId} value={indentData.indentCode}>{indentData.indentCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item
                name="supplierType"
                label="supplierType"
              >
                <Select
                  placeholder="Select Supplier Type"
                  optionFilterProp="children"
                  onChange={handleSupplierType}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                    <Option key={0} value={null}>Select Supplier Type</Option>
                    <Option key={1} selected value={SupplierTypeEnum.AGENT}>{SupplierTypeEnum.AGENT}</Option>
                    <Option key={2} value={SupplierTypeEnum.BROKER}>{SupplierTypeEnum.BROKER}</Option>
                    <Option key={3} value={SupplierTypeEnum.FARMER}>{SupplierTypeEnum.FARMER}</Option>
                  </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item name="harvestDate" label="Harvest Date">
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD"
                  showToday={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: '30px'}}>

                <Button type="primary" htmlType="submit" onClick={getReportData}>
                  Get Report
                </Button>
          
            </Col>
            <Col style={{ padding: '30px' }}>

                <Button type="primary" htmlType="submit" >
                <CSVLink filename={"PoReport.csv"} data={indentReportData} >Get Excel </CSVLink>
                </Button>
            </Col>
          </Row>
            

         
        </Form>

        <br/>

        <Table
          rowKey={record => record.indentId}
          columns={indentReportData.length>0?columnsSkelton:null}
          dataSource={indentReportData}
          pagination={{
            onChange(current) {
              setPage(current);
            }
          }}
          onChange={onChange}
          // scroll={{ x: true }}
          // size="large"
          bordered 
          summary={(pageData) => {
            let totalPrice = 0;
            let totalQuantity = 0;
            let totalCount = 0;
            let totalgrnPrice = 0;
            let totalgrnCount = 0;
            let totalgrnQty = 0;
          

            pageData.forEach(({ quantity }) => {
              if(Number(quantity)){
                totalgrnQty+=Number(quantity)
               }
               
             });
             pageData.forEach(({ price }) => {
              
               if(Number(price)){
                 totalgrnPrice += Number(price);
                 }
               
             });
             pageData.forEach(({ count }) => {
               if(Number(count)){
               totalgrnCount += Number(count)
             }
               
             });
             pageData.forEach(({ expectedQty }) => {
 
               if(Number(expectedQty)){
               totalQuantity += Number(expectedQty);
               }
               
             });
             pageData.forEach(({ expectedPrice }) => {
 
               if(Number(expectedPrice)){
               totalPrice += Number(expectedPrice);
               }
               
             });
 
       
             pageData.forEach(({expectedCount }) => {
 
               if(Number(expectedCount)){
               totalCount += Number(expectedCount);
               }
             });


            return (
              <>
              <Table.Summary.Row className='tableFooter'>
              <Table.Summary.Cell index={1} colSpan={6} ><Text >Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={1}><Text  style={{textAlign:'end'}}>{totalQuantity.toFixed()}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={1}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
              <Table.Summary.Cell index={8} colSpan={1}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
              <Table.Summary.Cell index={9} colSpan={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={10} colSpan={1}><Text  style={{textAlign:'end'}}>{totalgrnQty}</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={11} colSpan={1}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>
              <Table.Summary.Cell index={12} colSpan={1}><Text  style={{textAlign:'end'}}></Text></Table.Summary.Cell>                  
              <Table.Summary.Cell index={13}></Table.Summary.Cell>
              </Table.Summary.Row>
              </>
            );
          }
         }/>
        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
          onClose={closeDrawer} visible={drawerVisible} closable={true}>

        </Drawer>
    </Card>        
  </>
  );
}

export default RmProcurementReportGrid;
