import React, { useEffect, useRef, useState } from 'react';
import {Button, Card, Col, DatePicker, Form, Input, Row, Select, Table} from 'antd'
import './material-requisition-report.css';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import form from 'antd/lib/form';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';


/* eslint-disable-next-line */
export interface MaterialRequisitionReportProps {}

export function MaterialRequisitionReport(
  props: MaterialRequisitionReportProps
) {
  const { RangePicker } = DatePicker;
  const [page, setPage] = React.useState(1);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const unitcodeService = new UnitcodeService();
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const { Option } = Select;


  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  useEffect(()=>{
    getAllMainPlants();
  },[])
  const getAllMainPlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });
  
  const EstimatedCreatedDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedCreatedFromDate(fromDate)
    setSelectedCreatedToDate(toDate)
  }
  const dataSource = [
    {
      key: '1',
      indentAgainst:'dummy',
      indentCode:'IND/22-23/00138',
      itemsubcategory:'Carton',
      item:'Carton/Cape Gourmat/10*1LB',
      quantity:'5000',
      indenQty:'450',
      receivedQty:'300',
      balanceQty:'150',
     status:'open'
    },
    {
      key: '2',
      indentAgainst:'saleOder',
      indentCode:'IND/22-23/0015',
      itemsubcategory:'Pouch',
      item:'Pouch/Sandhya Aqua/1KG',
      quantity:'6000',
      indenQty:'200',
      receivedQty:'150',
      balanceQty:'50',
     status:'open'
    },
    {
      key: '3',
      indentAgainst:'dummy',
      indentCode:'IND/22-23/0016',
      itemsubcategory:'Pouch',
      item:'	Pouch/Cape Gourmat/1LB',
      quantity:'5000',
      indenQty:'300',
      receivedQty:'300',
      balanceQty:'0',
     status:'In Progress'
    },
  ];
 

  const columnsSkelton: ColumnProps<any>[] =[
    {
      title: 'S No',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)

    },
    {
      title: 'Indent Date',
      dataIndex: 'indentDate',
      render: (value, record) => {
        return moment(record.indentDate).format('YYYY-MM-DD');
    }

    },
    {
      title: 'Indent Against',
      dataIndex: 'indentAgainst',
      ...getColumnSearchProps('indentAgainst'),
      sorter: (a, b) => a.indentAgainst - b.indentAgainst,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Indent Code',
      dataIndex: 'indentCode',
      ...getColumnSearchProps('indentCode'),
      sorter: (a, b) => a.indentCode - b.indentCode,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sub category',
      dataIndex:"itemsubcategory",
      ...getColumnSearchProps('itemsubcategory'),
      sorter: (a, b) => a.itemsubcategory - b.itemsubcategory,
      sortDirections: ['descend', 'ascend'],
    },
  {
    title: 'Item',
    dataIndex:"item",
    ...getColumnSearchProps('item'),
    sorter: (a, b) => a.item - b.item,
    sortDirections: ['descend', 'ascend'],
  },
    {
      title: 'Indent Qty',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Received Qty',
      dataIndex: 'receivedQty',
      sorter: (a, b) => a.receivedQty - b.receivedQty,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Balance Qty',
      dataIndex: 'balanceQty',
      sorter: (a, b) => a.balanceQty - b.balanceQty,
      sortDirections: ['descend', 'ascend'],
    },
    
   
    
    {
      title: 'Indent status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.balanceQty - b.balanceQty,
      sortDirections: ['descend', 'ascend'],
    },
 
]

  return (
   
    <div>
      <Card title={<span style={{ color: 'white' }}>Material Requisition Report</span>}
              style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
                 <Card >
      <Row>
      <Col>
            <Form.Item name="createdAt"
              label="Created Date"
              rules={[
                {
                  required: false,
                  message: "Missing Created Date"
                },
              ]}>
              <RangePicker onChange={EstimatedCreatedDate} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 30 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{marginLeft:'2%'}}>
                  <Form.Item
                    name="plant"
                    label="Plant"
                    rules={[
                      {
                        required: false,
                        message: 'Select Plant'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Plant"
                    >
                      {factoriesData.map(dropData => {
                            return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                          })}
                    </Select>
                  </Form.Item>
                </Col>
          <Col xs={{ span: 30 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{marginLeft:'2%'}}>
                  <Form.Item
                    name="indentStatus"
                    label="Status"
                    rules={[
                      {
                        required: false,
                        message: 'Select Order Status'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Order Status"
                    >
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{marginLeft:'2%'}}>
                  <Form.Item
                    name="indentAgainst"
                    label="Indent Against"
                    rules={[
                      {
                        required: false,
                        message: 'Select Indent Against'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Indent Against"
                    >
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{marginLeft:'2%'}}>
                  <Form.Item
                    name="indentCode"
                    label="Indent Code"
                    rules={[
                      {
                        required: false,
                        message: 'Select Indent Code'
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Indent Code"
                    >
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: '10px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Button
                            type="primary"
                            onClick={() => console.log('done1')}
                            style={{ width: 100 }}
                        >Get Report</Button>
                        </Col>
                        <Col style={{ paddingLeft: '10px' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                            <Button
                            type="primary"
                            onClick={() => console.log('done2')}
                            style={{ width: 100 }}
                        >Get Excel</Button>
               </Col>
                </Row>
                </Card>
       <Table
        columns={columnsSkelton}
        dataSource={dataSource}
      

      />
    
      
      </Card>

    </div>
  );
}

export default MaterialRequisitionReport;
