import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { CustomerDto } from './customers-dto';

export class AllCustomerResponseModel extends GlobalResponseObject {
    data?: CustomerDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: CustomerDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

