import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, FormInstance, Popconfirm } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService } from '@gtpl/shared-services/masters'
import { PlantsDropDown, UnitcodeDto, UnitTypeEnum } from '@gtpl/shared-models/masters'
import {  SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoTranferDto } from '@gtpl/shared-models/sale-management';

const { Option } = Select;
export interface soTransferFormProps {
    soData:any,
    closeModal:(closeModal:boolean)=>void


}
export function SOTransferForm(props: soTransferFormProps) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
    const [mainAndSubCOntractPlantsData, setMainAndSubCOntractPlantsData] = useState<PlantsDropDown[]>([]);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [stockConversionStatus, setStockConversionStatus] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    const unitService = new UnitcodeService();
  const saleService = new SaleOrderService();



    useEffect(() => {
        getPlantsData();
    }, [])
    useEffect(()=> {
        if(props.soData){
            if(props.soData.isSubContract == 'YES'){
                getSubContractsPlantsData();
            }
            form.setFieldsValue({fromUnit:props.soData.unit})
            form.setFieldsValue({fromProcessingUnit:props.soData.processingUnitName})
            console.log(props.soData)
        }
    },[props.soData])

    const getSubContractsPlantsData = () => {
        unitService.getAllMainAndSubContractPlants().then((res) => {
            if (res.status) {
                setMainAndSubCOntractPlantsData(res.data);
            } else {
                setMainAndSubCOntractPlantsData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setMainAndSubCOntractPlantsData([]);
        });
    }

    const getPlantsData = () => {
        unitService.getAllMainPlants().then((res) => {
            if (res.status) {
                setFactoriesData(res.data);
            } else {
                setFactoriesData([]);
            }
        }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
            setFactoriesData([]);
        });
    }
  
    const onTransfer = (data,status) => {
        setBtnDisable(true);
    form.validateFields().then(res => {
        console.log(form.getFieldsValue().toProcessingUnit)
        console.log(data)
        if(props.soData.unitId ==res.ToUnit && props.soData.processingUnitId ==res.toProcessingUnit){
            AlertMessages.getErrorMessage('From and To plants of manufacturing and processing units are same.Please check!');
        }else{
            const soTransferDto = new SoTranferDto(props.soData.saleOrderId,props.soData.unitId,res.ToUnit,props.soData.processingUnitId,res.toProcessingUnit,status)
            console.log(soTransferDto);
            saleService.transferSo(soTransferDto).then((res) => {
                if (res.status) {
                    AlertMessages.getSuccessMessage('Sale Order Transferred successfully');
                    props.closeModal(true)
                    // history.push("/saleorder-tracker");
                } else {
                  if (res.intlCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                  setBtnDisable(false);
    
                  } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                  setBtnDisable(false);
    
                  }
                }
              }).catch((err) => {
                AlertMessages.getErrorMessage(err.message);
              });
        }
        
    })

    }

    const showPopconfirm = () => {
        setVisible(true);
      };
      const handleCancel = () => {
        console.log('Clicked cancel button');
        setStockConversionStatus(false);
        setVisible(false);
        onTransfer(undefined,false)
      };
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Transfer SO</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
                <Form layout={"vertical"} form={form} autoComplete="off">
                    <Row gutter={24}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item name="fromUnit" label=" From Manufacturing Unit" rules={[{ required: true, message: 'Missing Unit' }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Unit"
                                    allowClear
                                    disabled={true}
                                >
                                    {factoriesData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5}} lg={{ span: 5}} xl={{ span: 5}}>
                            <Form.Item name="ToUnit" label=" To Manufacturing Unit" rules={[{ required: true, message: 'Missing Unit' }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Unit"
                                    allowClear
                                >
                                    {(mainAndSubCOntractPlantsData.length) > 0 ? mainAndSubCOntractPlantsData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    }) : factoriesData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="fromProcessingUnit" label="From Processing Unit" rules={[{ required: true, message: 'Missing Unit' }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Processing Unit"
                                    allowClear
                                    disabled={true}
                                >
                                    {factoriesData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    }) }
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="toProcessingUnit" label="To Processing Unit" rules={[{ required: true, message: 'Missing Unit' }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select Processing Unit"
                                    allowClear
                                >
                                    {factoriesData.map(dropData => {
                                        return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                    }) }
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row justify='end'>
                    <Col span={3}>
                    <Form.Item >
                    <Popconfirm
                        title={'Are you sure to Move Stock to dummy ?'}
                        visible={visible}
                        onConfirm={e => onTransfer(undefined,true)}
                        onCancel={handleCancel}
                        >
                    <Button type="primary" onClick={showPopconfirm} style={{ backgroundColor: "#67b32d", color: "white" }}  block >
                    {'TRANSFER'}
                  </Button></Popconfirm>
                </Form.Item>
              </Col>
                    </Row>
                </Form>

            </Card>
        </>
    );
}
export default SOTransferForm;
