export class JobNotificationDto{
    jobNotificationsId? : number;
    department: string;
    jobNotification:string;
    isActive:boolean;
    createdAt: Date | any;
    createdUser : string;
    updatedAt: Date |any;
    updatedUser: string;
    versionFlag: number;

constructor(
    jobNotificationsId : number,
    department: string,
    jobNotification:string,
    isActive:boolean,
    createdAt: Date | any,
    createdUser : string,
    updatedAt: Date |any,
    updatedUser: string,
    versionFlag: number

){
    this.jobNotificationsId = jobNotificationsId;
    this.department = department;
    this.jobNotification = jobNotification;
    this.createdAt = createdAt;
    this.createdUser= createdUser;
    this.updatedAt = updatedAt;
    this.updatedUser = updatedUser;
    this.versionFlag = versionFlag;
    this.isActive= isActive;
}
}