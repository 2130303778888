import { ProductInfoResponseDto } from "./product-info.response.dto";

export class RowDataResponse{
    row: string;
    total: number;
    productInfo: ProductInfoResponseDto[];
    
    constructor(row: string,total: number,productInfo:ProductInfoResponseDto[]){
        this.row = row;
        this.total = total;
        this.productInfo = productInfo;
    }
}

