import { SizeCategoryEnum } from "@gtpl/shared-models/common-models";



export class PalletCapacityDto {
    palletCapacityId:number;
    palletCapacity:number;
    packingMethodId:number;
    packingMethod:string;
    unitName:string;
    unitId:number;
    sizeCategory:SizeCategoryEnum;
    isActive:boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    constructor(
        palletCapacityId:number,
        palletCapacity:number,
        packingMethodId:number,
        packingMethod:string,
        unitName:string,
        sizeCategory:SizeCategoryEnum,
        isActive:boolean,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number,
    ){
        this.palletCapacityId=palletCapacityId;
        this.palletCapacity=palletCapacity;
        this.packingMethodId=packingMethodId;
        this.packingMethod=packingMethod;
        this.unitName=unitName
        this.sizeCategory=sizeCategory;
        this.isActive=isActive;
        this.createdAt= createdAt;
        this.createdUser= createdUser;
        this.updatedAt= updatedAt;
        this.updatedUser= updatedUser;
        this.versionFlag= versionFlag;
    }
   
  

  


  


 

 

  
 
 
}
