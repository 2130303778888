import { BomPackingItemsDto } from "./bom-details.dto";

export class BomSODto {
    saleOrderId:number;
    saleOrderNumber:string;
    BomDetailsInfo:BomPackingItemsDto[];


    /**
     * 
     * @param saleOrderId 
     * @param saleOrderNumber 
     * @param BomDetailsInfo 
     */
    constructor(saleOrderId:number, saleOrderNumber:string, BomDetailsInfo:BomPackingItemsDto[]){
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.BomDetailsInfo = BomDetailsInfo;
    }
}