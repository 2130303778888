import { IsNotEmpty, IsAlphanumeric, MaxLength, Matches, IsOptional, IsNumber } from 'class-validator';
import { ApiProperty } from '@nestjs/swagger';

export class SkuDataRequest {
    skuCodeId: number;
    foodTypeId: number;
    soakingStyle: string;

    constructor(skuCodeId: number,foodTypeId: number,soakingStyle: string){
        this.skuCodeId = skuCodeId;
        this.foodTypeId = foodTypeId;
        this.soakingStyle = soakingStyle;
    }

}