export class ItemsDto {
    itemId?:number;
    itemCode: string;
    itemCategoryId?:number;
    itemSubCategoryId?:number;
    itemName: string;
    productCode:string;
    packingCategory:string;
    brandId:number;
    varientId:number;
    minGrade:number;
    maxGrade:number;
    pouches:number;
    pouchWeight:number;
    sizeId:number;
    minimumQuantity:number;
    remarks: string;
    isActive: boolean;
    createdAt : Date | any;
    createdUser : string;
    updatedAt : Date | any;
    updatedUser : string;
    versionFlag : number;
    uomId:number;
    itemCategoryName?: string;
    itemSubCategoryName?: string;
    brandName?: string;
    varientName?: string;
    uom?: string;
    productId?: number;
    count?: number;
    sizeName?:string;
    bapNo?:string;
    foodTypeId?:number;
    valueAdditionOneId?:number;
    valueAdditionTwoId?:number;
    valueAdditionThreeId?:number;
    valueAdditionFourId?:number;
    valueAdditionFiveId?:number;
    type?:number;
    approvalNo?:string;
    netWeight?:number;
    isAsset?:string;
    assetType?: string;
    assetSla?:number;
    assetPriority?:string;

    constructor(itemId:number,itemCode: string,itemCategoryId:number,itemName: string,productCode:string,packingCategory:string,brandId:number,varientId:number,minGrade:number,maxGrade:number,pouches:number,pouchWeight:number,sizeId:number,minimumQuantity:number,remarks: string,isActive: boolean,createdAt : Date | any,createdUser : string,updatedAt : Date | any,updatedUser : string,versionFlag : number,uomId:number,itemSubCategoryId?:number,itemCategoryName?:string,productId?:number,count?:number, sizeName?:string,bapNo?:string,foodTypeId?:number,valueAdditionOneId?:number,valueAdditionTwoId?:number,valueAdditionThreeId?:number,valueAdditionFourId?:number,valueAdditionFiveId?:number,type?:number,approvalno?:string,netWeight?:number,isAsset?:string,assetType?:string,assetSla?:number,assetPriority?:string){
        this.itemId=itemId;
        this.itemCode=itemCode;
        this.itemCategoryId=itemCategoryId;
        this.itemName=itemName;
        this.productCode=productCode;
        this.packingCategory=packingCategory;
        this.brandId=brandId;
        this.varientId=varientId;
        this.minGrade=minGrade;
        this.maxGrade=maxGrade;
        this.pouches=pouches;
        this.pouchWeight=pouchWeight;
        this.sizeId=sizeId;
        this.minimumQuantity=minimumQuantity;
        this.remarks=remarks;
        this.isActive=isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.uomId = uomId;
        this.productId = productId;
        this.count = count;
        this.itemCategoryName = itemCategoryName;
        this.itemSubCategoryId = itemSubCategoryId;
        this.sizeName = sizeName;
        this.bapNo = bapNo;
        this.foodTypeId = foodTypeId;
        this.valueAdditionOneId = valueAdditionOneId;
        this.valueAdditionTwoId = valueAdditionTwoId;
        this.valueAdditionThreeId = valueAdditionThreeId;
        this.valueAdditionFourId = valueAdditionFourId;
        this.valueAdditionFiveId = valueAdditionFiveId;
        this.type = type;
        this.approvalNo = approvalno;
        this.netWeight = netWeight;
        this.isAsset = isAsset;
        this.assetType = assetType;
        this.assetSla = assetSla;
        this.assetPriority= assetPriority;

    }


}

export const ItemDtoDefault : ItemsDto = {
    itemCode: "",
    itemName: "",
    itemCategoryId:0,
    itemSubCategoryId:0,
    productCode: "",
    packingCategory:"",
    brandId:0,
    varientId:0,
    minGrade:0,
    maxGrade:0,
    pouches:0,
    pouchWeight:0,
    sizeId:0,
    minimumQuantity:0,
    remarks: "",
    isActive: true,
    createdAt : new Date() ,
    createdUser : '0',
    updatedAt : new Date() ,
    updatedUser : '0',
    versionFlag : 1,
    uomId:0

};