
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { TrimsDto } from "./trims.dto"


export class AllTrimsResponse extends GlobalResponseObject{
    data?: TrimsDto[];

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */
     constructor(status:boolean, intlCode:number, internalMessage:string, data?: TrimsDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}