export class RepackingDtoRequest{
    product: number;
    varient: number;
    brand: number;
    category: number;
    minGrade: number;
    maxGrade: number;
    soakingStyle:string;
    soakingTime:string;
    requiredQty?:number;
    requiredCases?:number;
    masterBrandId?:number;
    unitId?:number;
    uomId?:string;

}