export class AssetDetails {
    assetCode: string;
    expiryDate: string;
    assetMaintenanceValue: number;
  
    constructor(
      assetCode: string,
      expiryDate: string,
      assetMaintenanceValue: number
    ) {
      this.assetCode = assetCode;
      this.expiryDate = expiryDate;
      this.assetMaintenanceValue = assetMaintenanceValue;
    }
  }
  