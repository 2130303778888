import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoakingAssignmentDto } from './assign-soaking-materail.dto';

export class ProductionInventoryResponseModel extends GlobalResponseObject{
    data?: SoakingAssignmentDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoakingAssignmentDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
