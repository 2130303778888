import { RolesList } from "@gtpl/shared-models/gtpl";

export class EmployeeLeavesDto{
employeeId: number;
employeeName: string;
employeeLastName:string;
employeeCode: number;
emailId?: string;
employeeRole: RolesList;
mobileNo: number;
isActive:boolean;

}
