import connection from './connection';
import axios from 'axios';
import { FgDamagesDto, FgDamagesRequest, unitRequiest } from '@gtpl/shared-models/warehouse-management';
import { CommonResponse, PlantIdRequest } from '@gtpl/shared-models/masters';


export class FgDamgesService{
    URL = connection.DEPLOY_URL + '/fgdamages';
    temPDataURL = 'http://3.109.225.143:8888';

    async createFgDamgesReason(DTO: FgDamagesDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createFgDamgesReason', DTO)
            .then(res => {
                return res.data
            })
    }
    async updateFgdamages(Dto: FgDamagesDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateFgdamages', Dto)
            .then(res => {
                return res.data
            })
    }
    async getLocationforDamages(req:unitRequiest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationforDamages',req)
            .then(res => {
                return res.data
            })
    }
    async getsaleorderagainstlocation(req:unitRequiest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getsaleorderagainstlocation',req)
            .then(res => {
                return res.data
            })
    }
    
    async getAllfgDamagesData(req:unitRequiest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllfgDamagesData',req)
            .then(res => {
                return res.data
            })
    }
     
    async getallLocationSaleorderandProduct(req:unitRequiest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getallLocationSaleorderandProduct',req)
            .then(res => {
                return res.data
            })
    }
    async activateOrDeactivateDamges(req:FgDamagesDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/activateOrDeactivateDamges',req)
            .then(res => {
                return res.data
            })
    }
}