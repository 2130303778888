import { GlobalResponseObject } from "../global-response-object";
import { SoCountDetail } from "./so-count-detail";

export class SoCountDetailResponse extends GlobalResponseObject {
    data?: SoCountDetail[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SoCountDetail[]){
        super(status,intlCode,internalMessage)
        this.data = data;
    }
}
