import { CommonResponse, PlantIdRequest } from "@gtpl/shared-models/masters";
import axios from "axios";
import connection from "./connection";
import { AssetTransfersDto, TransferIdRequest } from "@gtpl/shared-models/asset-management";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";

export class AssetTransfersService{
    URL = connection.DEPLOY_URL + '/asset-transfers';

    async createAssetTransfers(req: AssetTransfersDto) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetTransfers',req)
        .then(res => {
            return res.data;
        })

    }

    async getDCTransfers(req: LocationByPlant) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDCTransfers',req)
        .then(res => {
            return res.data;
        })

    }async getMRNTransfers(req: PlantIdRequest) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMRNTransfers',req)
        .then(res => {
            return res.data;
        })

    }

    async ReceiveAsset(req: TransferIdRequest) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/ReceiveAsset',req)
        .then(res => {
            return res.data;
        })

    }

    async getTransferDetails(req: TransferIdRequest) : Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTransferDetails',req)
        .then(res => {
            return res.data;
        })

    }

}