import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, Table, Divider, Tooltip, Popconfirm } from 'antd';
import { SaleOrderService, StoreConsumptionItemsRequest } from '@gtpl/shared-services/sale-management';
import './store-consumption-form.css';
import TextArea from 'antd/lib/input/TextArea';
import { SODto } from '@gtpl/shared-models/sale-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { EditOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';
import { ItemCategoriesDto, ItemCategoryDropDownDto, ItemsDropDownDto, ProductDto } from '@gtpl/shared-models/masters';
import { AvailableStockQtyResponseModel, AvailStockDto, getStoreConsumptionsResponse, StockIdReq, StoreConsumptionItemsDetails, StoreConsumptionItemsDto } from '@gtpl/shared-models/procurement-management';
import { ItemsService } from '@gtpl/shared-services/masters';
import { StockService, StoreConsumptionService } from '@gtpl/shared-services/procurement';
import { type } from 'os';
import { ColumnProps } from 'antd/lib/table';
import { IsNumber } from 'class-validator';
import { UnitRequest } from '@gtpl/shared-models/common-models';

const { Option } = Select;

/* eslint-disable-next-line */
export interface StoreConsumptionItemsFormProps {
  form: FormInstance<any>
  addDataToItemDetails: (itemDetalFormdata: StoreConsumptionItemsDto[]) => void;
  saleOrderId: number;
  saleOrderItemId: number;
  // itemsdata: (itemCatId: number) => void;
  // getAvailStock: (storeConsumptionItemsDto: StoreConsumptionItemsDto) => void;
  // availabletock:number;
  // itemsDropDown:ItemsDropDownDto[];
  // itemsCategorydata:ItemCategoryDropDownDto[];
}

export function StoreConsumptionItemsForm(
  props: StoreConsumptionItemsFormProps
) {
  const form = props.form;
  const [defaultFormData, setDefaultFormData] = useState<any>(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [fieldsData, setFieldsData] = useState<any>(undefined);
  const [storeItemsData, setStoreItemsData] = useState(undefined);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [qtyDisable, setQtyDisable] = useState<boolean>(true);
  const [defaultStockQty, setDefaultStockQty] = useState<number>(0);
  const [itemsdata, setItemsdata] = useState<ItemsDropDownDto[]>([]);
  const [dummyItemsdata, setDummyItemsdata] = useState<ItemsDropDownDto[]>([]);
  const [itemType, setItemType] = useState<any>('')

  const [availStockQty, setAvailStockQty] = useState<number>(0);
  const [quantity, setQuantity] = useState<string>(null);
  const [updateTableData, setUpdateTableData] = useState<any[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [consumedQty, setConsumedQty] = useState<number>(0);
  const [isPreparedMaterial, setIsPreparedMaterial] = useState<boolean>(true)
  const [preparedMaterialItems, setPreparedMaterialItems] = useState<any[]>([])

  const itemService = new ItemsService();
  const stockService = new StockService();
  const saleOrderService = new SaleOrderService();
  const storeConsumptionService = new StoreConsumptionService();
  useEffect(() => {
    if (props.saleOrderId > 0) {
      form.setFieldsValue({ itemId: "select Item Name" });
      form.setFieldsValue({ availableQty: "" });

    }
    // if(props.soType == 'Dummy'){
    //   form.setFieldsValue({stockType:'Dummy'})
    //   setItemType('Dummy')
    // }


    getItemsData(props.saleOrderId);
    getItemsForStoreConsumption(props.saleOrderId, props.saleOrderItemId);

    getDummyItemsData()
    // if (defaultFormData) {
    //   console.log(defaultFormData);
    //   form.setFieldsValue({
    //     itemCategory: !isUpdate?defaultFormData.itemCategory.children:defaultFormData.itemCategory,
    //     itemId: !isUpdate?defaultFormData.itemId.children:defaultFormData.itemId,
    //     qugetBomItemsDetailsantity: !isUpdate?defaultFormData.quantity:defaultFormData.quantity,
    //     availableQty: !isUpdate?defaultFormData.availableStock:defaultFormData.availableStock,
    //   });
    //   setFieldsData(defaultFormData);
    // }
  }, [defaultFormData]);
  const onReset = () => {
    setBtnChange(false);
    form.resetFields();
  };


  const getItemsData = (saleorder,) => {
    console.log(saleorder);

    saleOrderService.getBomItemsDetails({ saleOrderId: saleorder }).then((res) => {
      // console.log(res.data);
      if (res.status) {
        setItemsdata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setItemsdata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemsdata([]);
    });
  }

  const getItemsForStoreConsumption = (saleorder, saleOrderItemId?: number) => {
    console.log(saleorder);
    const req = new StoreConsumptionItemsRequest()
    req.saleOrderId = saleorder
    req.saleOrderItemId = saleOrderItemId
    req.isPreparedMaterial = isPreparedMaterial
    req.unitId = Number(localStorage.getItem('unit_id'))
    saleOrderService.getItemsForStoreConsumption(req).then((res) => {
      // console.log(res.data);
      if (res.status) {
        setPreparedMaterialItems(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setPreparedMaterialItems([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPreparedMaterialItems([]);
    });
  }



  const getDummyItemsData = () => {
    const unitId = Number(localStorage.getItem('unit_id'))
    const req = new UnitRequest(unitId)

    saleOrderService.getDummyItemsForStoreConsumption(req).then((res) => {
      // console.log(res.data);
      if (res.status) {
        setDummyItemsdata(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setDummyItemsdata([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDummyItemsdata([]);
    });
  }

  const getAvailableStock = (reqData, saleOrderId) => {
    console.log(reqData, saleOrderId);
    stockService.getAvailableStockQty({ saleOrderId: saleOrderId, itemId: reqData, unitId: Number(localStorage.getItem('unit_id')), role: JSON.parse(localStorage.getItem('role')) }).then((res) => {
      // console.log(res);
      if (res.status && res.data.physicalQuantity > 0) {
        setAvailStockQty(res.data.physicalQuantity);
        // const { label } = row;
        // setFieldsData({ ...fieldsData, 'availableStock': res.data.physicalQuantity ,  [label]: row })
        form.setFieldsValue({ availableQty: res.data.physicalQuantity });
        setQtyDisable(false);
      } else {
        console.log(res.internalMessage)
        AlertMessages.getErrorMessage(res.internalMessage);
        form.setFieldsValue({ availableQty: "" });
        form.setFieldsValue({ quantity: "" });
        form.setFieldsValue({ itemCategory: "Select Item Category" });
        form.setFieldsValue({ itemId: "select Item Name" });
        setQuantity("");
        setQtyDisable(true);
        // }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setAvailStockQty(undefined);
    });
  }
  const getAvailablePreparedStock = (stockId) => {
    const unitId = Number(localStorage.getItem('unit_id'))
    const stockIdReq = preparedMaterialItems.find( i => i.itemId == stockId.itemId).stockId
    const req = {stockId : stockIdReq,unitId:unitId}
    stockService.getPreparedAvailableStockQty(req).then((res) => {
      // console.log(res);
      if (res.status && res.data.physicalQuantity > 0) {
        setAvailStockQty(res.data.physicalQuantity);
        // const { label } = row;
        // setFieldsData({ ...fieldsData, 'availableStock': res.data.physicalQuantity ,  [label]: row })
        form.setFieldsValue({ availableQty: res.data.physicalQuantity });
        setQtyDisable(false);
      } else {
        console.log(res.internalMessage)
        AlertMessages.getErrorMessage(res.internalMessage);
        form.setFieldsValue({ availableQty: "" });
        form.setFieldsValue({ quantity: "" });
        form.setFieldsValue({ itemCategory: "Select Item Category" });
        form.setFieldsValue({ itemId: "select Item Name" });
        setQuantity("");
        setQtyDisable(true);
        // }
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setAvailStockQty(undefined);
    });
  }
  const appendData = (val, row) => {
   console.log(row,'row data')
    const { label } = row;
    setFieldsData({ ...fieldsData, [label]: row })

    if(isPreparedMaterial){
      console.log(isPreparedMaterial,'isPreparedMaterial')
      const stockId = preparedMaterialItems.find(item => item.itemId = row.value )
      getAvailablePreparedStock(stockId)
    }else{

      if (itemType == 'SaleOrder') {
        if (row.label === "itemId") {
          getAvailableStock(val, props.saleOrderId);
          getConsumedStock(val, props.saleOrderId);
        }
      }
      else {
        if (row.label === "itemId") {
          getAvailableStock(val, 0);
          getConsumedStock(val, 0);
        }
      }
    }
    
  }


  const getConsumedStock = (itemid, saleOrderId) => {
    setConsumedQty(0);
    storeConsumptionService.getConsumedStock({ saleOrderId: saleOrderId, itemId: itemid, unitId: Number(localStorage.getItem('unit_id')), role: JSON.parse(localStorage.getItem('role')) }).then((res) => {
      if (res.status) {
        setConsumedQty(Number(res.data.consumedQty));
      } else {
        setConsumedQty(0);
      }
    }).catch((err) => {
      setConsumedQty(0);
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const setEditForm = (rowData, index) => {
    getConsumedStock(rowData.itemId, props.saleOrderId);
    setDefaultFormData(rowData);
    setIndexVal(index);
    setBtnChange(true);

    form.setFieldsValue({
      // itemCategory: !isUpdate?rowData.itemCategory.children:rowData.itemCategory,
      itemId: !isUpdate ? rowData.itemId.children : rowData.itemId,
      quantity: !isUpdate ? rowData.quantity : rowData.quantity,
      availableQty: !isUpdate ? rowData.availableStock : rowData.availableStock,
    });
    setFieldsData(rowData);

    // setIsUpdate(true);

  }
  const deleteData = (index) => {
    console.log(index)
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }
  const columns: ColumnProps<any>[] = [
    // {
    //   title: 'Item Category',
    //   dataIndex: 'itemCategory',
    //   render: (text, data, index) => {
    //     // console.log(text, data, index)
    //     return <span>{data.itemCategory.children}</span>
    //   }
    // },
    {
      title: 'Item Name',
      dataIndex: 'itemId',
      key: 'itemId',
      align: 'left',
      render: (text, data, index) => {
        // console.log(text, data, index)
        return <span>{data.itemId.children}</span>
      }
    },
    {
      title: 'Available Quantity',
      dataIndex: 'availableStock',
      key: 'availableStock',
      align: 'left',
    },
    {
      title: 'Consumed Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'left',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData) {
                  console.log(rowData);
                  setEditForm(rowData, index);
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title='dlete'>
            <Popconfirm title='Sure to delete?' onConfirm={e => { deleteData(index); }}>
              <MinusCircleOutlined
                style={{ color: '#1890ff', fontSize: '14px' }} />
            </Popconfirm>

          </Tooltip>
        </span>)
    }

  ]
  const appendInputData = (val, label) => {


    if (label == "quantity") {
      setFieldsData({ ...fieldsData, [label]: val });
      const quantity: number = val;
      const stockQty: number = form.getFieldValue("availableQty");
      console.log(stockQty);
      console.log(quantity);
      if (Number(quantity) > Number(stockQty) || Number(quantity) <= 0) {
        form.setFieldsValue({ quantity: "" });
        AlertMessages.getErrorMessage('Consumed Quantity must be less than Available Quantity');
        // form.setFieldsValue({quantity:0});
        return false;
      }
    }
    else if (label == "availableQty") {

      setFieldsData({ ...fieldsData, [label]: val.availStockQty });
    } else if (label == 'stockType') {
      setFieldsData({ ...fieldsData, [label]: val });

    }
  }


  const saveSaleOrderItems = (values: any) => {
    console.log(values,'values')
    setFieldsData(values);
    console.log(values,'on create')
    form.validateFields().then(storeConsumptionres => {
      setConsumedQty(0);
      let tableName = tableData;
      let duplicateData;
      if (!btnChange) {
        duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key));
      }
      else {
        duplicateData = tableName.find(item => (item.itemId.key == fieldsData.itemId.key && item.quantity == fieldsData.quantity));
      }

      if (duplicateData) {
        AlertMessages.getErrorMessage('Item already exist in Store Consumption Items. ');
        onReset();
      } else {
        // console.log('btnChangetest');

        if (!btnChange) {
          form.validateFields().then(res => {
            setShowTable(true);
            fieldsData.availableStock = values.availableQty;
            let dynTableData = [...tableData, fieldsData]
            setTableData(dynTableData);
            props.addDataToItemDetails(dynTableData);
          })
        } else {

          let dynTableData = [...tableData]

          // dynTableData[indexVal].itemCategory=fieldsData.itemCategory
          dynTableData[indexVal].itemId = fieldsData.itemId
          dynTableData[indexVal].quantity = fieldsData.quantity
          dynTableData[indexVal].availableStock = fieldsData.availableStock

          // let dynTableData = [...tableData]
          // dynTableData[indexVal] = fieldsData
          // console.log(dynTableData);
          setShowTable(true);
          setTableData(dynTableData);
          props.addDataToItemDetails(dynTableData);



        }
        onReset();
      }
    })
  };

  const onIsPreparedMaterialSelect = (value) => {
    if (value === 'Yes') {
      setIsPreparedMaterial(true)
    } else {
      setIsPreparedMaterial(false)
    }
    appendInputData(value, 'isPreparedMaterial'); form.resetFields(['itemId', 'availableQty'])
  }


 
  return (
    <Card title={<span style={{ color: 'white' }}>Store Item Details </span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form layout="vertical" form={form} name="control-hooks" onFinish={saveSaleOrderItems}>
        <Row gutter={24} >

          <Col span={4}>
            <Form.Item name="isPreparedMaterial" label="Is Prepared Material" rules={[{ required: true, message: "stock type is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Stock type"
                onChange={onIsPreparedMaterialSelect}
                allowClear
              >
                <Option key={0} value={null}>Select Prepared Material</Option>
                <Option key={1} label='Yes' value={'Yes'}>{'Yes'}</Option>
                <Option key={2} label='No' value={'No'}>{'No'}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ display: isPreparedMaterial ? 'unset' : 'none' }}>
            <Form.Item name="itemId" label="Item Name" rules={[{ required: isPreparedMaterial ? true : false, message: "'itemName' is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Item Name"
                onChange={appendData}
                allowClear
              >
                <Option key={0} value={null}>Select Item Name</Option>
                {preparedMaterialItems.map(dropData => {
                  return <Option key={dropData.itemId} label='itemId' value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4} style={{ display: isPreparedMaterial ? 'none' : 'unset' }}>
            <Form.Item name="stockType" label="Stock Type" rules={[{ required: isPreparedMaterial ?  false : true, message: "stock type is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Stock type"
                onChange={(e) => { appendInputData(e, 'stockType'); setItemType(e); form.resetFields(['itemId', 'availableQty']) }}
                allowClear
              >
                <Option key={0} value={null}>Select Stock type</Option>
                <Option key={1} label='Dummy' value={'Dummy'}>{'Dummy'}</Option>
                <Option key={2} label='SaleOrder' value={'SaleOrder'}>{'SaleOrder'}</Option>
              </Select>
            </Form.Item>
          </Col>

          {itemType == 'SaleOrder' && isPreparedMaterial == false &&<Col span={8} key={'SaleOrder'}>
            <Form.Item name="itemId" label="Item Name" rules={[{ required: true, message: "'itemName' is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Item Name"
                onChange={appendData}
                allowClear
              >
                <Option key={0} value={null}>Select Item Name</Option>
                {itemsdata.map(dropData => {
                  return <Option key={dropData.itemId} label='itemId' value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>}
          {itemType == 'Dummy' &&  isPreparedMaterial == false&&<Col span={8} key='Dummy'>
            <Form.Item name="itemId" label="Item Name" rules={[{ required: true, message: "'itemName' is required" }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Item Name"
                onChange={appendData}


                // onChange={getAvailableStock} 
                allowClear
              >
                <Option key={0} value={null}>Select Item Name</Option>
                {dummyItemsdata.map(dropData => {
                  return <Option key={dropData.itemId} label='itemId' value={dropData.itemId}>{dropData.itemName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>}
          <Col span={4} >
            <Form.Item name="availableQty" label="Available Quantity" rules={[{ required: true }]}  >
              <Input disabled onClick={e => appendInputData({ availStockQty }, 'availableQty')} value={availStockQty} />
            </Form.Item>
          </Col>
          <Col span={4} >
            <label><span style={{ color: 'red' }}>Total IssUed Qty :{consumedQty}</span><span style={{ color: 'red' }}>*</span>Consumed Quantity: </label>
            <br />
            <Form.Item name="quantity" label={false} rules={[{ required: true, message: "'Consumed Quantity' is required " }]}  >
              <Input type='number' disabled={qtyDisable} onChange={e => appendInputData(e.target.value, 'quantity')} value={quantity} />
            </Form.Item>
          </Col>
          <br/>
      

          <Col span={2}>
            <Form.Item >

  
              <Button style={{ backgroundColor: "#67b32d", color: "white", marginTop:"40px" }} type="primary" htmlType="submit" >
                {btnChange ? 'UPDATE' : 'ADD'}
              </Button>
              {/* <Button style={{ backgroundColor: "#67b32d", color: "white" }} onClick={onAddProduct} block >
                    Add
                    </Button> */}
            </Form.Item>
          </Col>
          <br/>
          <Col span={2}>
            <Form.Item >
            

              <Button style={{ backgroundColor: "#f19c9b", color: "white", marginTop:"40px" }}

                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>

          {/* <Col span={6}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col> */}
        </Row>
        <br />
        {showTable ?
          <Table key={Date.now()} scroll={{ x: 500 }} dataSource={tableData}
            columns={columns} pagination={false} /> :
          <></>
        }
        {/* {
            showTable ?
              <Table dataSource={tableData} columns={columns} pagination={false} /> :
              <></>
          } */}
      </Form>

    </Card>
  );
}

export default StoreConsumptionItemsForm;
