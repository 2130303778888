import { OperationTypeEnum, SubPlantCodesEnum } from "@gtpl/shared-models/common-models";

export class StockTransferData {
    lotNo: string;
    subLotNo: string;
    plantId: number;
    plantName: string;
    subPlantId: number;
    subPlantName: string;
    quantity: number;
    noOfBoxes: number;
    count: number;
    prodInvId: number;
    operation: OperationTypeEnum;
    nextOperation: OperationTypeEnum;
    createdUser: string;
    estimatedDate:Date;
    inventoryplantId:number;

    constructor(lotNo: string, subLotNo: string, plantId: number, plantName: string, subPlantId: number, subPlantName: SubPlantCodesEnum, quantity: number, noOfBoxes: number, count: number, prodInvId: number, operation: OperationTypeEnum, nextOperation: OperationTypeEnum, createdUser: string, estimatedDate:Date,inventoryplantId:number) {
        this.lotNo = lotNo;
        this.subLotNo = subLotNo;
        this.plantId = plantId;
        this.plantName = plantName;
        this.subPlantId = subPlantId;
        this.subPlantName = subPlantName;
        this.quantity = quantity;
        this.noOfBoxes = noOfBoxes;
        this.count = count;
        this.prodInvId = prodInvId;
        this.operation = operation;
        this.nextOperation = nextOperation;
        this.createdUser = createdUser;
        this.estimatedDate = estimatedDate;
        this.inventoryplantId = inventoryplantId;
    }
}