export class ProductBarcodeRequest {
    saleOrderId: number;
    saleOrderItemId: number;
    itemId: number;
    /**
     * @param count
     * @param plantId
    */
    constructor(saleOrderId: number,saleOrderItemId: number,itemId: number){
       this.saleOrderId=saleOrderId;
       this.saleOrderItemId = saleOrderItemId;
       this.itemId = itemId;
    }
}