import { ContainerStatusEnum,BlStatusEnum } from "@gtpl/shared-models/common-models";
import { SaleOrderDropDownDto, SODto } from '@gtpl/shared-models/sale-management';
export class BlStatusInfo {
    containerNo?:string;
    blStatus:BlStatusEnum;
    saleOrder:SaleOrderDropDownDto;
    saleOrderNo?: string;
    containerId?:number;
    etd?:Date;
    oblEtd?:Date;
    blNo?:string;
    customPoNo?:string;
    destination?:string;
    trackingNo?:string;
    destinationName?:string;
    buyerPoNumber?:string;

    /**
     * 
     * @param containerNo 
     * @param blStatus 
     * @param saleOrder 
     * @param containerId 
     */
    constructor(containerNo:string, blStatus:BlStatusEnum, saleOrder:SaleOrderDropDownDto,saleOrderNo:string, containerId?:number,etd?:Date,
        oblEtd?:Date,blNo?:string,customPoNo?:string,destination?:string,trackingNo?:string,destinationName?:string,buyerPoNumber?:string) {
        this.containerNo = containerNo;
        this.blStatus = blStatus;
        this.saleOrder = saleOrder;   
        this.saleOrderNo = saleOrderNo  
        this.containerId = containerId;     
        this.etd = etd;     
        this.oblEtd = oblEtd;     
        this.blNo = blNo;     
        this.customPoNo = customPoNo;     
        this.destination = destination;     
        this.trackingNo = trackingNo;
        this.destinationName = destinationName;     
        this.buyerPoNumber = buyerPoNumber
    }
    
}