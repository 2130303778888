import { GlobalStatus } from "@gtpl/shared-models/common-models";

export class BomTrackerPackingItemsDto {
    item: string;
    size: string;
    quantity: number;
    grnQuantity: number;
    poQuantity: number;
    existingQuantity: number;
    status: string;
    packingMaterialId: number;
    createdUser: string;
    saleOrderId: number;
    updatedUser: string;
    avilableQuantity: number;
    requiredQuantity: number;
    receivedQuantity: number;
    packingCategory: string;
    isActive: boolean;
    AssignedQuantity: number;
    ToBeAssignedQuantity: number;
    poQty: number;
    saleOrderNumber: string;
    isRequired:GlobalStatus;
    isActiveRevised:GlobalStatus;
    isQtyRevised:GlobalStatus;
}

