import { FarmersDropDownResponseModel, SupplierDTO, SupplierResponseModel,AllSupplierResponseModel, SupplierTypeRequest, FarmerModel, SupplierFarmResponse, MasterInfoFromIndentRequest, MasterInfoFromIndentResponse } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class SuppliersService {
    URL = connection.DEPLOY_URL + '/suppliers';


    async createSupplier(supplierInfo:SupplierDTO):Promise<SupplierResponseModel> {
        return await axios.post(this.URL + '/createSupplier', supplierInfo)
            .then(res => {
                return res.data
            })
    }

    async getAllFarmers():Promise<FarmersDropDownResponseModel> {
        return await axios.post(this.URL + '/getfarmers')
            .then(res => {
                return res.data
            })
    }
    async getAllSuppliers(req?:UserRequestDto):Promise<AllSupplierResponseModel> {
        return await axios.post(this.URL + '/getAllSuppliers',req)
            .then(res => {
                return res.data
            })
    }
    async  updateSupplier(supplierInfo: SupplierDTO): Promise<SupplierResponseModel> {
        return await axios.post(this.URL + '/updateSupplier', supplierInfo)
               .then(res => {
                   return res.data
               })
    }
    async  activateorDeactivateSupplier(supplierInfo: SupplierDTO): Promise<AllSupplierResponseModel> {
        console.log(supplierInfo);
        return await axios.post(this.URL + '/activateorDeactivateSupplier', supplierInfo)
            .then(res => {
                return res.data
            })
   }

   async getFarmersBySupplierType(supplierReq: SupplierTypeRequest): Promise<FarmersDropDownResponseModel> {
        return await axios.post(this.URL + '/getFarmersBySupplierType', supplierReq)
           .then(res => {
               return res.data
           })
    }

    async getFarmCodesBySupplierId(farmerReq: FarmerModel): Promise<FarmersDropDownResponseModel> {
        return await axios.post(this.URL + '/getFarmCodesBySupplierId', farmerReq)
            .then(res => {
                return res.data
            })
    }

    async getSupplierBySupplierId(supplierId:FarmerModel): Promise<SupplierFarmResponse> {
        return await axios.post(this.URL + '/getSupplierBySupplierId', supplierId).then((res) => {
          return res.data;
        });
    }

    async getFarmerDetailsByFarmerId(farmerId:FarmerModel): Promise<SupplierFarmResponse> {
        return await axios.post(this.URL + '/getFarmerDetailsByFarmerId', farmerId).then((res) => {
          return res.data;
        });
    }

    async getMastersRelatedDataFromIndent(request:MasterInfoFromIndentRequest): Promise<MasterInfoFromIndentResponse> {
        return await axios.post(this.URL + '/getMastersRelatedDataFromIndent', request).then((res) => {
          return res.data;
        });
    }

    async getFarmersCount(): Promise<number> {
        return await axios.post(this.URL + '/getFarmersCount').then((res) => {
          return res.data;
        });
    }

    async getSuppliersByBrokerId(brokerReq: {brokerId:number}): Promise<FarmersDropDownResponseModel> {
        return await axios.post(this.URL + '/getSuppliersByBrokerId', brokerReq)
            .then(res => {
                return res.data
            })
    }
    

}