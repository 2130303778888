import { WarehouseResponseData } from ".";

export class RackTypeResponseDto {
    rackType: string;
    locations: WarehouseResponseData[]
    constructor(rackType: string,
        locations: WarehouseResponseData[]){
            this.rackType=rackType
            this.locations=locations
    }
}