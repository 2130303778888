import React, { useEffect, useRef, useState } from 'react';
import{Form,Input,Card,Button,Select,Row,Col, InputNumber} from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import './fg-damages-form.css';
import {  DamageTypeEnum, FgDamageLevelEnum, FgDamagesDto, unitRequiest } from '@gtpl/shared-models/warehouse-management';
import { EmployeeDto, EmployeeUnitRequest, PlantIdRequest } from '@gtpl/shared-models/masters';
import {  EmployeeService } from '@gtpl/shared-services/masters';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import TextArea from 'antd/lib/input/TextArea';
import { FgDamgesService } from "@gtpl/shared-services/warehouse-management";



/* eslint-disable-next-line */
const { Option } = Select;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 8,
  }, 
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};
/**
 * For props 
 */

/* eslint-disable-next-line */

/* eslint-disable-next-line */
export interface FgDamagesFormProps {
  fgDamgesData:FgDamagesDto;
  updateItem:(fgdamagesData:FgDamagesDto)=>void;
  isUpdate: boolean;
  closeForm: () => void;
}

export function FgDamagesForm(
  props: FgDamagesFormProps
) {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState<boolean>(false)
  const [employeeData, setEmployeeData] = useState<EmployeeDto[]>([]);
  const [locationdata,setLocationdata] = useState<any[]>([]);
  const [saleorderdata,setSaleorderdata] = useState<any[]>([]);
  const [damagetypevisible,setDamagetypevisible] = useState<boolean>(false)
  const[cartonsvisible,setCartonsvisible] = useState<boolean>(false)
  const empservice = new EmployeeService;
  const fgdamageservice = new FgDamgesService



useEffect (() =>{
  if (Number(localStorage.getItem('unit_id')) != 5) {
    form.setFieldsValue({ bapUnit: Number(localStorage.getItem('unit_id')) })
  }
  getEmpByUnit();
  // LocationforDamages();
  getallLocationSaleorderandProduct()
  console.log(Number(localStorage.getItem('unit_id')))
 
},[])

let createdUser="";
if(!props.isUpdate){
  createdUser= localStorage.getItem("createdUser");
}
let history =  useHistory()

const getEmpByUnit = () => {
  const req = new EmployeeUnitRequest(Number(localStorage.getItem('unit_id')))
  empservice.getEmployeesByUnitId(req).then((res) => {
    if (res.status) {
      setEmployeeData(res.data)
    } else {
      setEmployeeData([]);
      AlertMessages.getErrorMessage(res.internalMessage)
    }
  })
}
// const LocationforDamages = () => {
//   const req = new unitRequiest(Number(localStorage.getItem('unit_id')))
//   fgdamageservice.getLocationforDamages(req).then((res) => {
//     if (res.status) {
//       setLocationdata(res.data)
//     } else {
//       setLocationdata([]);
//       AlertMessages.getErrorMessage(res.internalMessage)
//     }
//   })
// }
const getallLocationSaleorderandProduct = () => {
  const req = new unitRequiest(Number(localStorage.getItem('unit_id')))
  fgdamageservice.getallLocationSaleorderandProduct(req).then((res) => {
    if (res.status) {
      setSaleorderdata(res.data)
    } else {
      setSaleorderdata([]);
      AlertMessages.getErrorMessage(res.internalMessage)
    }
  })
}

// const locationChange = (value) =>{
//   getsaleorderagainstlocation(value)
// }

// const getsaleorderagainstlocation = (locatinId) => {
//   const req = new unitRequiest(Number(localStorage.getItem('unit_id')),locatinId)
//   fgdamageservice.getsaleorderagainstlocation(req).then((res) => {
//     if (res.status) {
//       setSaleorderdata(res.data)
//     } else {
//       setSaleorderdata([]);
//       AlertMessages.getErrorMessage(res.internalMessage)
//     }
//   })
// }
const onReset = () => {
  form.resetFields();
};
 const damagelevelchange=()=>{
   if(form.getFieldValue('damageLevel') == FgDamageLevelEnum.PALLET){
    setDamagetypevisible(true)
    if(form.getFieldValue('damageType') == DamageTypeEnum.PARTIALLY_DAMAGED){
      setCartonsvisible(true)
    }else{
      setCartonsvisible(false)
    }
   }else{
    setDamagetypevisible(false)

   }
 }
 const savedamages = (data: FgDamagesDto) => {
  setDisable(true)
  data.fgDamageId=0;
  data.isActive=true;
  fgdamageservice.createFgDamgesReason(data).then(res => {
    setDisable(false)
    if (res.status) {
      AlertMessages.getSuccessMessage('fgdamages captured Successfully');
   history.push("/fg-damages-view")
      onReset();
    } else {
      if (res.intlCode) {
        AlertMessages.getErrorMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }
  }).catch(err => {
    setDisable(false)
    AlertMessages.getErrorMessage(err.message);
  })
}
const saveData = (values: FgDamagesDto) => {
  console.log(values);
  setDisable(false)
  if(props.isUpdate){
    props.updateItem(values);
  }else{
    setDisable(false)
    savedamages(values);

  }

};


const handleitem = (e) => {
  console.log(e)
  const soId = saleorderdata.filter((el) => { return el.saleOrderId == e })
  console.log(saleorderdata)
  form.setFieldsValue({ LocationId: soId[0].locationId,saleOrderItemId:soId[0].saleOrderItemId,stockId:soId[0].stockId})
  if(form.getFieldValue('damageType') == DamageTypeEnum.FULLY_DAMAGED){
    form.setFieldsValue({noOfCartons:soId[0].totalCartons})
  }
  }

const handleDamageType = (value) => {
  const soId = saleorderdata.filter((el) => { return el.saleOrderId == form.getFieldValue('saleOrderId') })
  if(value == DamageTypeEnum.FULLY_DAMAGED){
    form.setFieldsValue({
      noOfCartons : soId[0].totalCartons
    })
  }
}

  return (
   
   <>
     <Card title={<span style={{color:'white'}}>Fg Warehouse RealTime Damages</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} 
      extra={props.isUpdate==true?"":<Link to='/fg-damages-view' ><span style={{color:'white'}} >
        <Button className='panel_button' >View </Button> </span></Link>} 
        >
        <Form form={form}
        initialValues={props.fgDamgesData} 
        name="control-hooks" 
         onFinish={saveData}
              layout="vertical"
              >
          <Form.Item name="fgDamageId" style={{display:"none"}} >
                  <Input hidden/>
                </Form.Item>
                <Form.Item style={{display:"none"}} name="createdUser"  initialValue={createdUser}>
                <Input hidden/>
              </Form.Item>
              <Form.Item
            style={{ display: "none" }}
            name="unitId" initialValue={Number(localStorage.getItem('unit_id'))}>
            <Input hidden />
          </Form.Item>
              <Row>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}}>
          <Form.Item
                    name="LocationId"
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message:"Select  Location"
                      },
                    ]}>
                     <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Location"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange = {locationChange}
              >
                {locationdata?.map((dropdata) => {
                  return <Option key={dropdata.locationId} value={dropdata.locationId}>{dropdata.rackCode}</Option>
                })}
              </Select>
                  </Form.Item>
            </Col> */}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 10 }} style={{margin:'1%'}}>
          <Form.Item
                    name="saleOrderId"
                    label="Product"
                    rules={[
                      {
                        required: true,
                        message:"Select  Item"
                      },
                    ]}>
                     <Select
                showSearch
                //style={{ width: 200 }}
                onChange={e =>handleitem(e)}
                placeholder="Select SaleOrder"
                optionFilterProp="children"
                allowClear
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
              >
                {saleorderdata?.map((dropdata) => {
                  return <Option key={dropdata.saleOrderId} value={dropdata.saleOrderId}>{dropdata.product}
                    {/* {dropdata.rackCode}-{dropdata.poNumber}-{dropdata.brandName}-{dropdata.variantCode}-{dropdata.productCategory}-{dropdata.packingMethodName} */}
                    </Option>
                })}
              </Select>
                  </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}} hidden>
          <Form.Item
                    name="LocationId"
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message:"Select  Location"
                      },
                    ]}>
                 <Input/>
                  </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}} hidden>
          <Form.Item
                    name="saleOrderItemId"
                    label="Sale orderItem Id"
                    >
                 <Input/>
                  </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{margin:'1%'}} hidden>
          <Form.Item
                    name="stockId"
                    label="Stock Id"
                    >
                 <Input/>
                  </Form.Item>
            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}  style={{margin:'1%'}}>
          <Form.Item
                    name="damageLevel"
                    label="Damage Category"
                    rules={[
                      {
                        required: true,
                        message:"Select  Damage Level"
                      },
                    ]}>
                       <Select
                showSearch
                placeholder="Select status"
                optionFilterProp="children"
                // defaultValue={FgDamageLevelEnum.CARTON}
                onChange={damagelevelchange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.values(FgDamageLevelEnum).map((key,value) => {
                  return <Option key={key} value={key}>{key}</Option>
                })}
              </Select>
            </Form.Item>
            </Col>
            {damagetypevisible?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}  style={{margin:'1%'}} >
          <Form.Item
                    name="damageType"
                    label="Damage Type"
                    rules={[
                      {
                        required: damagetypevisible,
                        message:"Select  Damage Level"
                      },
                    ]}>
                       <Select
                showSearch
                placeholder="Select status"
                optionFilterProp="children"
                onChange={(value) => {handleDamageType(value)}}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.values(DamageTypeEnum).map((key,value) => {
                  return <Option key={key} value={key}>{key}</Option>
                })}
              </Select>
            </Form.Item>
            </Col>:''}
           
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }}  style={{margin:'1%'}} >
            <Form.Item
                    name="noOfCartons"
                    label="No.Of Cartons"
                    rules={[
                      {
                        required: true,
                        message:"Enter noOfCartons"
                      },
                    ]}>
                      <InputNumber/>
                      </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}  style={{margin:'1%'}}>
            <Form.Item
              name="damageBy"
              label="Damaged By"
              rules={[
                {
                  required: true,
                  message: 'Employee Name is Required'
                },
                
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                placeholder="Select Employee"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value={null}>Select Employee</Option>
                {employeeData?.map((employeeName) => {
                  return <Option key={employeeName.employeeId} value={employeeName.employeeId}>{employeeName.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}  style={{margin:'1%'}}>
            <Form.Item
                    name="damageReason"
                    label="Remarks"
                    rules={[
                      {
                        required: true,
                        message:"Enter Reason Name"
                      },
                      {
                        pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                        message: `Should contain only alphabets.`
                      },
                    ]}>
                    <TextArea rows={1} />
                    </Form.Item>
            </Col>
              </Row>
              <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                
                  <Button type="primary" disabled={disable} htmlType="submit" >
                    Submit
                  </Button>
                  {(props.isUpdate===false) &&
                  <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
              Reset
            </Button>
        }
              </Col>
            </Row>
        </Form>
      </Card>
   </>
  );
}

export default FgDamagesForm;
