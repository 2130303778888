import axios from 'axios';
import connection from './connection';
import {BlanchingCookingDto, AllBlanchingCookingReponseModel, BlanchingCookingReponseModel, LogIdRequest} from '@gtpl/shared-models/production-management';
import { CommonResponse } from '@gtpl/shared-models/masters';

export class BlanchingCookingService{

    URL = connection.DEPLOY_URL + '/blanching-cooking';

    async createBlanchingCooking(dto: BlanchingCookingDto ): Promise<BlanchingCookingReponseModel> {
        console.log(dto);
       
        return await axios.post(this.URL + '/createBlanchingCooking',dto)
            .then(res => {
                return res.data
            })
    }

    async getSamplleInfoByLogId(req : LogIdRequest):Promise<AllBlanchingCookingReponseModel>{
        return await axios.post(this.URL + '/getSamplleInfoByLogId', req)
            .then(res => {
                return res.data
            })   
    }

}