
export class GetEmpLeaves {
    year: string;
    
    /**
     * 
     * @param year 
     */
    constructor(year: string) {
        this.year = year;
    }
}