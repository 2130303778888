
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { WorkstationDTO } from './workstation.dto';

export class AllWorkstationResponseModel extends GlobalResponseObject{
    data?: WorkstationDTO[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: WorkstationDTO[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}
