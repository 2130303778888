import { LocationsDto } from '@gtpl/shared-models/masters';
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class AllLocationsResponseModel extends GlobalResponseObject {
  data?: LocationsDto[];

  /**
   *
   * @param status
   * @param errorCode
   * @param internalMessage
   * @param data /LocationsDto[]
   */
  constructor(
    status: boolean,
    errorCode: number,
    internalMessage: string,
    data?: LocationsDto[]
  ) {
    super(status, errorCode, internalMessage);
    this.data = data;
  }
}
