import axios from "axios";
import connection from './connection';
import {ProfilesDto, AllProfilesResponse, ProfileResponse } from '@gtpl/shared-models/hrms';
import { CommonResponse } from "@gtpl/shared-models/masters";

export class ProfilesService{

    URL = connection.DEPLOY_URL + '/profiles';

    async createProfile(data: ProfilesDto): Promise<ProfileResponse>{        
        return await axios.post(this.URL + '/createProfile', data)
            .then(res => {
                return res.data
            })
    }

    async updateProfile(data: ProfilesDto): Promise<ProfileResponse>{
        return await axios.post(this.URL + '/updateProfile', data)
            .then(res => {
                return res.data
            })
    }

    async getAllProfiles(): Promise<AllProfilesResponse>{
        return await axios.post(this.URL + '/getAllProfiles')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateProfile(data: ProfilesDto): Promise<ProfileResponse>{
        return await axios.post(this.URL + '/activateOrDeactivateProfile', data)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveProfiles(): Promise<AllProfilesResponse>{
        return await axios.post(this.URL + '/getAllActiveProfiles')
           .then(res => {
               return res.data
           })
    }
    async getIDJob(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getIDJob')
            .then(res => {
                return res.data
            })
    }
}