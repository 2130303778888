import { ZoneDto } from "../zone_master";

export class RmRackDetails{
    rmRackId?:number;
    plantName?:string;
    rmRackName:string;
    rmRackCode:string;
    reservedForDummy:number;
    zoneId:number;
    zoneName:string;
    allocatedZoneId:number;
    allocatedPlantId:number;
    createdUser:string;
    updatedUser:string;
    isActive:boolean;
    versionFlag:number;
    allocatedzoneName?:string;
    allocatedPlantName?:string;
    

    
    /**
     * 
     * @param rmRackId 
     * @param rmRackName 
     * @param zoneId 
     * @param rmRackCode 
     * @param zoneName 
     * @param createdUser 
     * @param updatedUser 
     * @param isActive 
     * @param versionFlag
     * 
     */
    constructor(rmRackId:number,rmRackName:string, rmRackCode:string,reservedForDummy:number,zoneId:number, zoneName:string,createdUser:string, updatedUser:string, isActive:boolean, versionFlag:number,allocatedZoneId:number,
        allocatedPlantId:number,
        plantName?:string,allocatedzoneName?:string,
        allocatedPlantName?:string,){
    this.rmRackId = rmRackId;
    this.rmRackName = rmRackName;
    this.rmRackCode = rmRackCode;
    this.reservedForDummy = reservedForDummy;
    this.zoneName = zoneName;
    this.zoneId = zoneId;
    this.createdUser = createdUser;
    this.updatedUser = updatedUser;
    this.isActive = isActive;
    this.versionFlag = versionFlag;
    this.plantName=plantName;
    this.allocatedZoneId=allocatedZoneId;
    this.allocatedPlantId=allocatedPlantId;
    this.allocatedzoneName=allocatedzoneName;
    this.allocatedPlantName=allocatedPlantName;

    }
} 
