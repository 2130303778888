
export class PoStockReq{
    itemSubcategoryId:number;
    saleOrderId?:number;
    unitId?:number;
    vendorId?:number;
    itemId?:number;
    fromDate?:Date;
    toDate?:Date;
}
