import { DeviceCategoryEnum } from "../enums";

export class DeviceCategoryAndDeptNameRequest {
    deptName: string;
    deviceCategory: DeviceCategoryEnum;
    plantId: number;

    /**
     * 
     * @param deptName 
     * @param deviceCategory 
     * @param plantId 
     */
    constructor(deptName: string, deviceCategory: DeviceCategoryEnum, plantId: number) {
        this.deptName = deptName;
        this.deviceCategory = deviceCategory;
        this.plantId = plantId;
    }
}