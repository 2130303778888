export class InventoryLogsDto{
    oldLocationId:number;
    newLocationId:number;
    oldSaleOrderId:number
    newSaleOrderId:number
    stockId:number
    oldQuantity:number 
    updatedQuantity:number
    constructor(
        oldLocationId:number, 
        newLocationId:number,
        oldSaleOrderId:number, 
       newSaleOrderId:number,
        stockId:number,    
        oldQuantity:number ,    
        updatedQuantity:number,  
    ){
       this.oldLocationId=oldLocationId;
       this.newLocationId=newLocationId;
       this.oldSaleOrderId=oldSaleOrderId;
       this.newSaleOrderId=newSaleOrderId;
       this.stockId=stockId;
       this.oldQuantity=oldQuantity;
       this.updatedQuantity=updatedQuantity;
    }
}