import { SaleOrderDropDownDto } from '.';
import { BomWithCountAndQuantityModel } from '../bom';

export class SoDropCntQtyModel {
    soDropdownData: SaleOrderDropDownDto[];
    soCountQtyData: BomWithCountAndQuantityModel[];
    constructor(soDropdownData: SaleOrderDropDownDto[],soCountQtyData: BomWithCountAndQuantityModel[]){
        this.soDropdownData = soDropdownData;
        this.soCountQtyData = soCountQtyData;
    }
}