import { StockService } from '@gtpl/shared-services/procurement';
import { Button, Card, Col, DatePicker, Form, Input, Popconfirm, Tag, Tooltip } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react'
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { CycleCheckerDateReq, CycleCheckerDto } from '@gtpl/shared-models/procurement-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CycleCheckerEnum } from '@gtpl/shared-models/common-models';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

export interface CycleCheckerApprovalProps { }

export function CycleCheckerApproval(props: CycleCheckerApprovalProps) {
  const [page, setPage] = React.useState(1);
  const [cycleCheckerData, setCycleCheckerData] = useState<any[]>([]);
  const [approvalData, setApprovalData] = useState<any[]>([]);
  const service = new StockService();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useState(undefined);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useState(undefined);
  const [filtersData, setFiltersData] = useState<any[]>([]);


  useEffect(() => {
    getCycleCheckerData();
  }, [])

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const getCycleCheckerData = () => {
    // const req = new CycleCheckerDateReq()
    // let selectedData = filtersData;
    // req.createdAt = undefined ;
    // req.fromDate =  selectedCreatedFromDate;
    // req.toDate =  selectedCreatedToDate;
    service.getCycleCheckerData().then((res) => {
      if (res.status) {
        setCycleCheckerData(res.data)
        setFiltersData(res.data);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
    
  }

  const cycleCheckerApproval = (values: CycleCheckerDto) => {
    console.log(values)
    values.status = CycleCheckerEnum.APPROVED
    values.physicalQuantity = Number(values.physicalQuantity)
    service.cycleCheckerApproval(values).then((res) => {
      if (res.status) {
        getCycleCheckerData()
        AlertMessages.getSuccessMessage('Stock Updated sucessfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const EstimatedCreatedDate = (value) => {
    const fromDate = new Date(value[0].format('YYYY-MM-DD'));
    const toDate = new Date(value[1].format('YYYY-MM-DD'));
    setSelectedCreatedFromDate(fromDate)
    setSelectedCreatedToDate(toDate)
  }
  const onReset = () => {
    form.resetFields();
    setSelectedCreatedFromDate(undefined);
    setSelectedCreatedToDate(undefined);
    setCycleCheckerData([])
  }
  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      key: 'sno',
      width: '30px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Plant',
      dataIndex: 'unit',
      ...getColumnSearchProps('unit'),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      width: 200,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render : (value , record) =>{
        return <>{record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-'}</>
      }

      // ...getColumnSearchProps('createdAt')
    },
    {
      title : 'Location',
      dataIndex : 'rackCode',
      ...getColumnSearchProps('rackCode'),
    },
    {
      title: 'Brand Name',
      dataIndex: 'brand',
      ...getColumnSearchProps('brand'),
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemSubCategory',
      ...getColumnSearchProps('itemSubCategory'),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      ...getColumnSearchProps('itemName')
    },
    {
      title: 'Quantity',
      dataIndex: 'physicalQuantity',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData, index) =>
        <Popconfirm title="Are you sure to approve" onConfirm={() => cycleCheckerApproval(rowData)}> <CheckOutlined style={{ color: 'green', fontSize: '20px', }} /></Popconfirm>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (rowData, status) => (
        <>{rowData == CycleCheckerEnum.NOT_APPROVED ? <Tag icon={<CloseCircleOutlined />} color="#f50">{CycleCheckerEnum.NOT_APPROVED}</Tag> : rowData == CycleCheckerEnum.APPROVED ? <Tag icon={<CheckCircleOutlined />} color="#87d068">{CycleCheckerEnum.APPROVED}</Tag> : ""}</>
      )
    }
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Cycle Checker Approval</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}  >
        <Form layout={"vertical"} autoComplete="off" form={form} >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }}>
            <Form.Item name="createdAt"
              label="Created Date"
              rules={[
                {
                  required: false,
                  message: "Missing Created Date"
                },
              ]}>
              <RangePicker onChange={EstimatedCreatedDate} />
            </Form.Item>
          </Col>
          <Col style={{ padding: '20px', marginTop: '10px', marginLeft: 750 }}>
            <Button type="primary" style={{ marginRight: '4px' }}  onClick={() => getCycleCheckerData()}>
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
        </Form>

        <Table
          rowKey={record => record.saleOrderId}
          columns={columns}
          dataSource={cycleCheckerData}
          pagination={false}
          onChange={onChange}
          scroll={{ x: true }}
          size='small'
          bordered
        />
      </Card>
    </>
  )
}