
export class EmployeeDepartmentRequest{
    employeeId:number;
    departmentId:string;
    constructor(employeeId:number,departmentId:string){
        this.employeeId = employeeId;
        this.departmentId = departmentId;
    }
    
   
}