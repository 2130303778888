import { GlobalResponseObject } from "@gtpl/shared-models/common-models";
import { JobNotificationDto } from "./job-notification.dto";
export class jobNotificationResponse extends GlobalResponseObject{
    data? : JobNotificationDto;


/**
 * @param status
 * @param intlCode
 * @param internalMessage
 * @param data
 */
constructor(status:boolean,intlCode:number,internalMessage:string,data?:JobNotificationDto){
    super (status,intlCode,internalMessage);
    this.status = status;
    this.intlCode = intlCode;
    this.internalMessage = internalMessage;
    this.data = data;
}
}