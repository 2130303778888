export class EndCustomerReq{
    endCustomerId : number;
    fromDate : Date;
    toDate: Date;
    unitId : number;
    constructor(endCustomerId : number, fromDate : Date, toDate : Date, unitId : number) {
        this.endCustomerId = endCustomerId;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.unitId = unitId;
    }
}