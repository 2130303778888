export class FGStockData{
    stockId: number;
    saleOrderId: number;
    saleOrderNumber: string;
    saleOrderItemId: number;
    brandId: number;
    brand: string;
    minGrade: number;
    maxGrade: number;
    varientId: number;
    variantName: string;
    pouches: number;
    pouchWeight: number;
    categoryId: number;
    category: string;
    productId: number;
    product: string;
    totalCartons: number;
    totalPouches: number;
    loosePouches: number;
    locationId: number;
    location: string;
    palletId: number;
    pallet: number;
    date:Date;
    stockType: string;
    status: string;
    assigningQty?:number;
    assignedSoId?:number;
    assignedSoItemId?:number;
    soakingStyle?:string;
    soakingTime?:string;
    uom?:string;
    shrimpWeight?:number;
    netWeight?:number;
    usableCases?:number;
    lotNumber?:string;
    assignableRMWeight:number;
    qtyInKgs?:number
    assignablecases?:number;
    constructor(stockId: number, saleOrderId: number, saleOrderNumber: string, saleOrderItemId: number, brandId: number, brand: string, minGrade: number, maxGrade: number, varientId: number, variantName: string, pouches: number, pouchWeight: number, categoryId: number, category: string, productId: number, product: string, totalCartons: number,totalPouches: number,loosePouches: number,locationId: number, location: string,  date:Date, stockType: string, status: string,palletId: number, pallet: number, assigningQty?:number, assignedSoId?:number, assignedSoItemId?:number, soakingStyle?:string, soakingTime?:string, uom?:string, shrimpWeight?:number, netWeight?:number, usableCases?:number, lotNumber?:string,assignableRMWeight?:number,qtyInKgs?:number,assignablecases?:number){
        this.stockId = stockId;
        this.saleOrderId = saleOrderId;
        this.saleOrderNumber = saleOrderNumber;
        this.saleOrderItemId = saleOrderItemId;
        this.brandId = brandId;
        this.brand = brand;
        this.minGrade = minGrade;
        this.maxGrade = maxGrade;
        this.varientId = varientId;
        this.variantName = variantName;
        this.pouches = pouches;
        this.pouchWeight = pouchWeight;
        this.categoryId = categoryId;
        this.category = category;
        this.productId = productId;
        this.product = product;
        this.totalCartons = totalCartons;
        this.totalPouches = totalPouches;
        this.loosePouches = loosePouches;
        this.location = location;
        this.locationId = locationId;
        this.palletId = palletId;
        this.pallet = pallet;
        this.date =date;
        this.stockType = stockType;
        this.status = status;
        this.assigningQty = assigningQty;
        this.assignedSoId = assignedSoId;
        this.assignedSoItemId = assignedSoItemId;
        this.soakingStyle = soakingStyle;
        this.soakingTime = soakingTime;
        this.uom = uom;
        this.shrimpWeight = shrimpWeight;
        this.netWeight = netWeight;
        this.usableCases = usableCases;
        this.assignableRMWeight = assignableRMWeight;
        this.qtyInKgs = qtyInKgs;
        this.lotNumber = lotNumber;
        this.assignablecases = assignablecases;
    }

}