export class GetConsumedItemsRequest{
    saleOrderId: number;
    storeConsumptionId?:number;
   
    /**
     * 
     * @param saleOrderId 
     * @param storeConsumptionId 
     */
    constructor(saleOrderId:number, storeConsumptionId?:number){
        this.saleOrderId = saleOrderId;
        this.storeConsumptionId = storeConsumptionId;
    }
}