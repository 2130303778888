import { EmpLeaveDTO } from "./emp-leaves.dto";

export class EmployeeLeavesCreateDTO {
    createdUser: string;
    dateTime: Date;
    empLeaveData: EmpLeaveDTO[];
    /**
     * 
     * @param createdUser 
     * @param dateTime 
     * @param empLeaveData 
     */
    constructor(createdUser: string, dateTime: Date, empLeaveData: EmpLeaveDTO[]) {
        this.createdUser = createdUser;
        this.dateTime = dateTime;
        this.empLeaveData = empLeaveData;
    }
}