import { AllTypesResponse, CommonResponse, TypesDto, TypesResponse,TypesRequest, ItemSubCategoryRequest } from '@gtpl/shared-models/masters';
import axios from 'axios';
import connection from './connection';


export class TypesService {
    URL = connection.DEPLOY_URL + '/types'

    async createTypes(dto: TypesDto): Promise<TypesResponse> {
        return await axios.post(this.URL + '/createTypes', dto)
            .then(res => {
                return res.data
            })
    }
    async updateTypes(dto: TypesDto): Promise<TypesResponse> {
        return await axios.post(this.URL + '/updateTypes', dto)
            .then(res => {
                return res.data
            })
    }
    async activatedeActivateTypes(dto: TypesDto): Promise<TypesResponse> {
        return await axios.post(this.URL + '/activatedeActivateTypes', dto)
            .then(res => {
                return res.data
            })
    }
    async getAllTypes(): Promise<AllTypesResponse> {
        return await axios.post(this.URL + '/getAllTypes')
            .then(res => {
                return res.data
            })
    }
    async getAllTypesDropDown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllTypesDropDown')
            .then(res => {
                return res.data
            })
    }

    async getTypesInfoById(TypesReq: TypesRequest): Promise<TypesResponse> {
        return await axios.post(this.URL + '/getTypesInfoById', TypesReq)
            .then(res => {
                return res.data
            })
    }

    async getTypesForItemSubcategoryId( req : ItemSubCategoryRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTypesForItemSubcategoryId', req)
            .then(res => {
                return res.data
            })
    }

}