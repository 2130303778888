export class RackReserveRequest {
    plantId: number;
    zoneId:number;
    rackId:number;
   
    constructor(plantId: number,zoneId:number,rackId:number){
        this.plantId = plantId;
        this.zoneId = zoneId;
        this.rackId = rackId;
    }
}
