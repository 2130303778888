import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SoViewDto } from './dto/so-view.dto';

export class SoViewResponseModel extends GlobalResponseObject{
    data?: SoViewDto[];

    constructor(status: boolean, errorCode: number, internalMessage: string, data?: SoViewDto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

