export class ReserveRackPositionsRequest {
    plantId:number;
    saleOrderId: number;
    saleOrderItemId: number;
    issuedCases: number;
    stockTypeStatus:string; 
    varientId?:number; 
    packStyleId?:number; 

    constructor(plantId:number,
        saleOrderId: number,
        saleOrderItemId: number,
        issuedCases: number,
        stockTypeStatus:string, 
        varientId?:number, 
        packStyleId?:number,) {
        this.plantId= plantId;
        this.saleOrderId= saleOrderId;
        this.saleOrderItemId= saleOrderItemId;
        this.issuedCases= issuedCases;
        this.stockTypeStatus= stockTypeStatus; 
        this.varientId = varientId; 
        this.packStyleId = packStyleId;
    }
}