
export class CusAddressInfo {

    addressId: number;
    addLineOne: string;
    addLineTwo: string;
    addLineThree: string;

    addLineFour: string;

    addressType: string;

    createdUser: string;








    /**
    * @param addressId
    * @param addLineOne
    * @param addLineTwo
    * @param addLineThree

    * @param addLineFour

    * @param addressType

    * @param createdUser:
     */
    constructor(addressId: number, addLineOne: string, addLineTwo: string, addLineThree: string, addLineFour: string, addressType: string, createdUser: string) {
        this.addressId = addressId;
        this.addLineOne = addLineOne;
        this.addLineTwo = addLineTwo;
        this.addLineThree = addLineThree;
        this.addLineFour = addLineFour;
        this.addressType = 'Customer';
        this.createdUser = createdUser;
    }
}