import React, { useEffect, useRef, useState } from 'react';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, ConfigProvider, Modal} from 'antd';
import {SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {InspectionForm} from '@gtpl/pages/qa-qc/qa-qc-components/product-inspection-form';
import {  Link } from "react-router-dom";
import ProTable, { ProColumns } from '@ant-design/pro-table';
import enUSIntl from 'antd/lib/locale/en_US';
import { ProductInspecReq, ProductInspectionDto } from '@gtpl/shared-models/production-management';
import { ProductInspecService } from '@gtpl/shared-services/production';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
export interface InspectionPrintProps {}

export function InspectionPrint(props: InspectionPrintProps) {
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState(''); 
  const [searchedColumn, setSearchedColumn] = useState('');

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [slectedInspectionData, setSelectedInspectionData] = useState<any>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [productInspecData, setProductInspecData] = useState<ProductInspectionDto[]>([]);
  const service = new ProductInspecService();

  useEffect(() => {
    getAllInspectionData();
  },[]);

  const getAllInspectionData = () => {
    service.getAll().then(res => {
      if(res.status){
        setProductInspecData(res.data);
      }else {
        if (res.intlCode) {
          setProductInspecData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setProductInspecData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteInspection = (dto: ProductInspectionDto) => {
    dto.isActive = dto.isActive? false: true;
    service.activateOrDeactivate(dto).then(res => {
      if(res.status){
        getAllInspectionData();
        AlertMessages.getErrorMessage('Success');
      }else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateInspection = (dto: ProductInspectionDto) => {
    service.update(dto).then(res => {
      if(res.status){
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAllInspectionData();
        setDrawerVisible(false);
      }else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getColumnSearchProps = (dataIndex:string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex]
       .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {    setTimeout(() => searchInput.current.select());   }
    },
    render: text =>
      text ?(
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) :text
      )
      : null
     
  });

  
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

    
    const closeDrawer=()=>{
      setDrawerVisible(false);
    }
   
    const openFormWithData = (data: ProductInspectionDto) => {
      setDrawerVisible(true);
      setSelectedInspectionData(data);
    }
    
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['md'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },

    {
      title: 'Inspection Date ',
      dataIndex: 'date',
      responsive: ['md'],
      // responsive: ['lg'],
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('date'),
      render : (value) => moment(value).format('DD-MM-YYYY')
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotNumber')
    },
    {
      title: 'SO Number',
      dataIndex: 'soNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.soNumber?.localeCompare(b.soNumber) ,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('soNumber')
    },
    {
      title: 'SO Item Name',
      dataIndex: 'item',
      // responsive: ['lg'],
      sorter: (a, b) => a.item?.localeCompare(b.item),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('item')
    },
   
    // {
    //   title: `Action`,
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span>
    //       {/* <EditOutlined className={'editSamplTypeIcon'} type="edit"
    //         onClick={() => {
    //           if (rowData.isActive) {
    //             openFormWithData(rowData);
    //           } else {
    //             AlertMessages.getErrorMessage('You Cannot Edit Deactivated Labelling');
    //           }
    //         }}
    //         style={{ color: '#1890ff', fontSize: '14px' }}
    //       /> */}

    //       <Divider type="vertical" />
    //       <Popconfirm onConfirm={e => { deleteLabelling(rowData); }}
    //         title={
    //           rowData.isActive
    //             ? 'Are you sure to Deactivate Labelling  ?'
    //             : 'Are you sure to Activate Labelling ?'
    //         }
    //       >
    //         <Switch size="default"
    //           className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
    //           checkedChildren={<RightSquareOutlined type="check" />}
    //           unCheckedChildren={<RightSquareOutlined type="close" />}
    //           checked={rowData.isActive}
    //         />

    //       </Popconfirm>
    //     </span>
    //   )
    // },
    {
      title: 'Action',
      dataIndex: 'inspection',
      render: (text, rowData: ProductInspectionDto, index) => (
        <span> <Button type="primary" shape="round"  size="small"
          // style={{ color: 'red' }}
          onClick={() => displayModel(rowData)}> Add Samples </Button>
        </span>
      )
    },
  ];

  const displayModel = (data: ProductInspectionDto) => {
    setSelectedInspectionData(data);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    getAllInspectionData();
    setIsModalVisible(false);
  };


  
  const onChange=(pagination, filters, sorter, extra)=> {
    console.log('params', pagination, filters, sorter, extra);
  }

  const getBoolean = (text:string) => {
    switch(text){
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <>
    <Card title={<span style={{color:'white'}}>PD-Inspection</span>}
    style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/inspection-form' ><span style={{color:'white'}} ><Button className='panel_button' >Create </Button> </span></Link>}   >
     <br></br>
     <Row gutter={40}>
        </Row>
          <br></br>
          <Table
        rowKey={record => record.inspectionId}
        columns={columnsSkelton}
        dataSource={productInspecData}

        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        onChange={onChange}
        bordered
        scroll={{ x: true }} />
          <Modal
        className='inspection'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
              <InspectionForm key={Date.now()} 
                inspectionData={slectedInspectionData}              
                isUpdate={true}                
                closeForm={closeDrawer} />
            
          </Modal>
     </Card>
    </>
  );
}

export default InspectionPrint;
