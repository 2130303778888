import React, { useState, useEffect } from 'react';
import { Tooltip, Divider, Card, Layout, Button, Table, Descriptions, Tag, Modal, Typography } from 'antd';
import './po-detailview-grid.css';
import { PODetailViewInfoDTO, itemDataDto, POViewInfoDTO, PoDetailsDto, PoDto, UpdatePoDetails, AllPoDetailsInfo, PoItemsDetails } from '@gtpl/shared-models/procurement-management'
import { MinusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement'
import { PoRequest } from "@gtpl/shared-models/procurement-management";
import { Link, Route, useHistory } from "react-router-dom";
import moment from 'moment';
import { CrrencySymbols } from '@gtpl/shared-models/sale-management';
import { PrinterOutlined } from '@ant-design/icons'
import { PoPrint } from '@gtpl/pages/procurement-masters/procurement-master-pages/po-detail-view-pages';
import { StatusEnum } from '@gtpl/shared-models/common-models';
/* eslint-disable-next-line */
export interface PoDetailviewGridProps {
  PurchaseOrderDetails: AllPoDetailsInfo
}

export function PoDetailviewGrid(
  props: PoDetailviewGridProps
) {
  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [indexVal, setIndexVal] = useState(0);
  const [poData, setPoData] = useState<PoDto>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const Service = new PurchaseOrderService;

  const { Text } = Typography;
  const setEditForm = (rowData: any, index) => {
    setDefaultFormData(rowData);
    setIndexVal(index);
  }
  const columns = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    // {
    //   title: 'SO Number',
    //   dataIndex: 'saleOrderNumber',
    //   key: 'saleOrderNumber',
    // },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      key: 'poNumber',
    },
    //  {
    //   title: 'Item Category',
    //   dataIndex: 'itemCategory.itemCategoryId',
    //   key: 'itemCategory.itemCategoryId',
    //   render: (value, record: PoItemsDetails, index) => {
    //     return (record.itemCategory) ? record.itemCategory.itemCategory : '';
    //   },
    // },
    {
      title: 'Item',
      dataIndex: 'item.itemId',
      key: 'item.itemId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.item) ? record.item.itemName : '';
      },
    },
    // {
    //   title:'Variant',
    //   dataIndex:'item.product',
    //   key:'item.product',
    //   render: (value, record: PoItemsDetails, index) => {
    //     return (record.item) ?( record.item.product + '/' + record.item.foodType ) : ''
    //   }
    // },
    {
      title: 'Size',
      dataIndex: 'item.sizeName',
      key: 'item.sizeName',
      render: (value, record: PoItemsDetails, index) => {
        return (record.item) ? record.item.sizeName : '';
      },
    },

    {
      title: 'Item Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record: PoItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.quantity) ? Number(record.quantity) : 0}</div>;
      },
    },
    {
      title: 'Received Quantity',
      dataIndex: 'receivedQty',
      key: 'receivedQty',
      render: (value, record: PoItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.receivedQty) ? Number(record.receivedQty) : 0}</div>;
      },
    },
    {
      title: 'Item Cost',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (value, record: PoItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.unitPrice) ? Number(record.unitPrice) : 0}</div>;
      },
    },
    // {
    //   title: 'Discount (%)',
    //   dataIndex: 'discountPercentage',
    //   key: 'discountPercentage',
    //   render: (value, record: PoItemsDetails, index) => {
    //     return <div style={{ textAlign: 'end' }}>{(record.discountPercentage) ? Number(record.discountPercentage) : 0}</div>;
    //   },
    // },
    // {
    //   title: 'Discount Amount',
    //   dataIndex: 'discountAmount',
    //   key: 'discountAmount',
    //   // render: (value, record: PoItemsDetails, index)
    //   render: (text,rowData) => {
    //     // return <div style={{ textAlign: 'end' }}>{(record.discountAmount) ? Number(record.discountAmount) : 0}</div>;
    //     return Number(rowData.discountAmount).toLocaleString('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2})},
    //   },


    {
      title: 'Tax (%)',
      dataIndex: 'tax.taxId',
      key: 'tax.taxId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.tax) ? record.tax.taxName : '';
      },
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      render: (value, record: PoItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.taxAmount) ? Number(record.taxAmount) : 0}</div>;
      },
    },
    // {
    //   title: 'Transportation',
    //   dataIndex: 'transportation',
    //   key: 'transportation',
    //   render: (value, record: PoItemsDetails, index) => {
    //     return <div style={{ textAlign: 'end' }}>{(record.transportation) ? Number(record.transportation) : 0}</div>;
    //   },
    // },
    {
      title: 'Sub Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (value, record: PoItemsDetails, index) => {
        return <div style={{ textAlign: 'end' }}>{(record.Amount) ? Number(record.Amount) : 0}</div>;
      },
    },
  ]
  

  const getTotalAmount = () => {
    let totalAmount = 0;
    props.PurchaseOrderDetails.poItems.map((item) => {
      if (item.isActive) {
        totalAmount = Number(totalAmount) + Number(item.Amount)
      }
    })
    return Number(Number(totalAmount).toFixed(3))
  }

  const convertCurrencyToSymbol = (currency) => {
    console.log(currency)
    let symbol = '';
    CrrencySymbols.map((curr) => {
      if (curr.name == currency) {
        symbol = curr.symbol
      }
    })
    return symbol;
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
 * print modal
 */
  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    setIsModalVisible(true); // model should be open
  };

  /**
 * get form data 
 * @param fromDoc 
 * @param toDoc 
 */
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleElement = toDoc.createElement('style');
        Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
          newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
        });
        toDoc.head.appendChild(newStyleElement);
      }
    });
  }
  const openPrint = () => {
    setIsModalVisible(true);
  }

  return (
    <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}>
      <Card title={<span style={{ color: 'black', borderColor: 'black', borderStyle: 'initial' }}>PO Detail View</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff' }} extra={<><span style={{ color: 'white' }}><Button className='panel_button' onClick={openPrint}><PrinterOutlined />Print</Button> </span>&nbsp;&nbsp;<Link to='/purchase-order-grid'><span style={{ color: 'white' }}><Button className='panel_button'>PO View </Button> </span></Link></>} >
        {props.PurchaseOrderDetails ? <><Descriptions column={3}>
          <Descriptions.Item label="PO Number">
            {props.PurchaseOrderDetails.purchaseOrderNo}
          </Descriptions.Item>
          <Descriptions.Item label="PO Date">
            {moment(props.PurchaseOrderDetails.purchaseOrderDate).format('YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="Vendor Name">
            {props.PurchaseOrderDetails.vendor.vendorName}
          </Descriptions.Item>
          <Descriptions.Item label="Expected Delivery Date">
            {moment(props.PurchaseOrderDetails.expectedDeliveryDate).format('YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {props.PurchaseOrderDetails.status == StatusEnum.OPEN ? <Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>OPEN</Tag> : props.PurchaseOrderDetails.status == StatusEnum.IN_PROGRESS ? <Tag style={{ backgroundColor: '#f2a637', color: "black", fontWeight: 'bold' }}>IN PROGRESS</Tag> : props.PurchaseOrderDetails.status == "GRN" ? <Tag style={{ backgroundColor: '#e4f467', color: "black", fontWeight: 'bold' }}>GRN</Tag> : props.PurchaseOrderDetails.status == StatusEnum.CLOSED ? <Tag style={{ backgroundColor: '#f5222d', color: "black", fontWeight: 'bold' }}>CLOSED</Tag> : ""}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Transportation">
            {props.PurchaseOrderDetails.transportation}
          </Descriptions.Item> */}
          <Descriptions.Item label="Total Amount">
            {convertCurrencyToSymbol(props.PurchaseOrderDetails.vendor.currency) + " " + (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == props.PurchaseOrderDetails.vendor.currency).locale).format((Number(props.PurchaseOrderDetails.transportation) + getTotalAmount())))}
          </Descriptions.Item>
        </Descriptions>
          <Descriptions column={{ xs: 1, sm: 1, md: 1 }}>
            <Descriptions.Item label="Delivery Address" style={{ fontWeight: 'bold' }}>
              {"SANDHYA AQUA EXPORTS PVT. LIMITED"}, {props.PurchaseOrderDetails?.deliveryAddressInfo?.fdaRegNumber?.toUpperCase()},  {props.PurchaseOrderDetails?.deliveryAddressInfo?.villageName?.toUpperCase()}, {props.PurchaseOrderDetails?.deliveryAddressInfo?.mandalName?.toUpperCase()}, {props.PurchaseOrderDetails?.deliveryAddressInfo?.distirctName?.toUpperCase()}, {props.PurchaseOrderDetails?.deliveryAddressInfo?.postalCode?.toUpperCase()}, {props.PurchaseOrderDetails?.deliveryAddressInfo?.stateName?.toUpperCase()}. {props.PurchaseOrderDetails?.deliveryAddressInfo?.countryName?.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Billing Address" style={{ fontWeight: 'bold' }}>
              {props.PurchaseOrderDetails?.billingAddressInfo?.fdaRegNumber?.toUpperCase()},  {props.PurchaseOrderDetails?.billingAddressInfo?.villageName?.toUpperCase()}, {props.PurchaseOrderDetails?.billingAddressInfo?.mandalName?.toUpperCase()}, {props.PurchaseOrderDetails?.billingAddressInfo?.distirctName?.toUpperCase()}, {props.PurchaseOrderDetails?.billingAddressInfo?.postalCode?.toUpperCase()}, {props.PurchaseOrderDetails?.billingAddressInfo?.stateName?.toUpperCase()}. {props.PurchaseOrderDetails?.billingAddressInfo?.countryName?.toUpperCase()}
            </Descriptions.Item>
          </Descriptions>
          <Card>
            <Table
              columns={columns}
              dataSource={(props.PurchaseOrderDetails) ? props.PurchaseOrderDetails.poItems.filter((val) => val.isActive) : []}
              pagination={false}
              scroll={{ y: 500 }}
              size="small"
              bordered
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalDiscount = 0;
                let totalTaxAmount = 0;
                let totalTransport = 0;
                let totalPoCost = 0;
                let totalUnitPrice = 0;
                pageData.forEach(({ quantity, unitPrice, discountAmount, taxAmount, transportation, Amount, isActive }) => {
                  if (isActive) {
                    totalQuantity += Number(quantity);
                    totalUnitPrice += Number(unitPrice);
                    totalDiscount += Number(discountAmount);
                    totalTaxAmount += Number(taxAmount);
                    totalTransport += Number(transportation);
                    totalPoCost += Number(Amount);
                  }
                });

                return (
                  <>
                    <Table.Summary.Row className='tableFooter'>
                      <Table.Summary.Cell index={0} colSpan={5} ><Text >Total Amount</Text></Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={1}><Text style={{ textAlign: 'right' }}>{ (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == props.PurchaseOrderDetails.vendor.currency).locale).format((Number(totalQuantity))))}</Text></Table.Summary.Cell> */}

                      {/* <Table.Summary.Cell index={2} ><Text >{convertCurrencyToSymbol(props.PurchaseOrderDetails.vendor.currency) + " " +(new Intl.NumberFormat(CrrencySymbols.find(item => item.name == props.PurchaseOrderDetails.vendor.currency).locale).format(totalUnitPrice))}</Text></Table.Summary.Cell> */}
                      <Table.Summary.Cell index={4} ><Text ></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} ><Text ></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} ><Text ></Text></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} ><Text >{convertCurrencyToSymbol(props.PurchaseOrderDetails.vendor.currency) + " " + (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == props.PurchaseOrderDetails.vendor.currency).locale).format(Number(Number(totalTaxAmount).toFixed(3))))}</Text></Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={7} ><Text >{totalTransport}</Text></Table.Summary.Cell> */}
                      <Table.Summary.Cell index={5} ><Text >{convertCurrencyToSymbol(props.PurchaseOrderDetails.vendor.currency) + " " + (new Intl.NumberFormat(CrrencySymbols.find(item => item.name == props.PurchaseOrderDetails.vendor.currency).locale).format(Number(Number(totalPoCost).toFixed(3))))}</Text></Table.Summary.Cell>
                    </Table.Summary.Row>

                  </>
                );
              }}
            /></Card></> : ''}

      </Card><br></br>
      {isModalVisible ?
        <Modal
          className='print-docket-modal'
          key={'modal' + Date.now()}
          width={'100%'}
          style={{ top: 30, alignContent: 'right' }}
          visible={isModalVisible}
          title={<React.Fragment>
          </React.Fragment>}
          onCancel={handleCancel}
          footer={[

          ]}
        >

          <PoPrint poId={props.PurchaseOrderDetails.purchaseOrderId} printOrder={printOrder} />
        </Modal> : ""}

    </Layout>
  );
}

export default PoDetailviewGrid;
