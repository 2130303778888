export class AssetActionControlRequest {
    tubCode: string;
    assetCode: string;
    durationOne: number;
    durationTwo: number;
    createdUser: string;
    date: string;
    cronJobTime: string;
    jobId: string;
    assetControl:boolean;
}