import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Card, Select, Col, Row, Table, Empty, InputNumber, Tooltip, Divider, DatePicker, Modal, Popconfirm } from 'antd';
import './purchase-order-form.css';
import moment from 'moment';
import { Link, Route, useHistory } from "react-router-dom";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import ItemDetailsForm from './item-details-form';
import PoForm from './po-details-form';
import { EditOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PoItemsDataModel, PoDataModel, PoDto, PoRequest, PoItemsDetails, PoDetailsDto, PoItemsCreateDetails, UpdatePoDetails, PoNumbersModel, POItemIdRequest } from '@gtpl/shared-models/procurement-management';
import { PurchaseOrderService } from '@gtpl/shared-services/procurement';
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDto, SODto, SoRequest, BomPackingItemsDto, FactoriesInput, SoNumbersModel, CrrencySymbols, BomIdsRequest } from '@gtpl/shared-models/sale-management';
import { useLocation } from 'react-router-dom'
import { CurrencyService, EmployeeService, ItemCategoryService, ItemsService, ItemSubCategoryService, PaymentTermsService, SizesService, TaxesService, UnitcodeService, VendorsPriceListService, VendorsService } from '@gtpl/shared-services/masters';
import { CategoryItemReq, ItemCategoriesDto, ItemCategoryDropDownDto, ItemCategoryRequest, ItemsDropDownDto, ItemsReqForCategoryId, PaymentTermsDropDownDto, PlantsDropDown, ShippingTermsRequest, SizesDropDownDto, TaxDropDownDto, TaxesDto, VendorDropDownDto, VendorItemRequest, VendorRequest, VendorsDto } from '@gtpl/shared-models/masters';
import TextArea from 'antd/lib/input/TextArea';
import { ApiTooManyRequestsResponse } from '@nestjs/swagger';
import { POTypeEnum, UnitRequest } from '@gtpl/shared-models/common-models';
import { isNullOrUndefined } from 'util';
import { ItemSubCategoryDropDownDto } from '@gtpl/shared-models/masters';
import { IsJSON } from 'class-validator';
const { Option } = Select;
/* eslint-disable-next-line */
export interface PurchaseOrderFormProps {
  // purchaseOrderData: PurchaseOrderDto;
  // updateSaleOrder:(saleOrder:purchaseOrderDto)=>void;
  isUpdate: boolean;
  closeForm: () => void;
}
export class PoStateDetails {
  id: number; // po id or so id
  type: string; // type po or so 
  unitId: number;
  bomIds: number[];
  soIds: number[];
  vendorId: number;
  fromBomTrackingReport: boolean;
}
export function PurchaseOrderForm(props: PurchaseOrderFormProps) {

  let location = useLocation();
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const [PoItemform] = Form.useForm();
  const [selectedSaleOrder, setSelectedSaleOrder] = useState<number>(null);
  const [tableData, setTableData] = useState<PoItemsDetails[]>([]);
  let history = useHistory();
  const [disable, setDisable] = useState<boolean>(false)
  const [vendorForm] = Form.useForm();
  const [itemDetailsGridData, setItemDetailsGridData] = useState([]);
  const [saleOrderData, setSaleOrderData] = useState<SoNumbersModel[]>([]);
  const [showPo, setShowPo] = useState<Boolean>(false)
  const [poItemsFormKeyUpdate, setPoItemsFormKeyUpdate] = useState<number>(1);
  const [poItemsdata, setPoItemsdata] = useState<any[]>([]);
  const [poDetailsFormKey, setPoDetailsFormKey] = useState<number>(1);
  const [isSoDisabled, setIsSoDisabled] = useState<boolean>(false);
  const [soDetails, setSoDetails] = useState<BomPackingItemsDto[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<number>(null);
  const [vendorData, setVendorData] = useState<VendorDropDownDto[]>([]);
  const [keyUpdate, setkeyUpdate] = useState<number>(1);
  const [defaultFormData, setDefaultFormData] = useState(undefined);
  const [poItems, setPoItemsDetails] = useState<PoItemsDetails[]>([]);
  const [indexVal, setIndexVal] = useState();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<string>('Add');

  const [poData, setPoData] = useState<PoDataModel>()
  const [poDetails, setPoDetails] = useState<PoDto>();
  // const [selectedPo, setSelectedPo] = useState<any>(PoDtoDefault);
  const [totalAmount, setTotalAmount] = useState<number>();
  const [transport, setTransport] = useState<number>(0);
  const [soData, setSoData] = useState<BomPackingItemsDto[]>([]);
  const [poDetailsForm] = Form.useForm();
  const [poItemsForm] = Form.useForm();

  const soService = new SaleOrderService;
  const poService = new PurchaseOrderService();
  const vendorService = new VendorsService;
  const currencyService = new CurrencyService;
  const employeeService = new EmployeeService;
  const paymentTermsService = new PaymentTermsService;
  const unitService = new UnitcodeService();
  const vendorPriceService = new VendorsPriceListService();


  const [itemCategories, setItemCategories] = useState<ItemCategoriesDto[]>([]);
  const [items, setItems] = useState<ItemsDropDownDto[]>([]);
  const [tableIndex, settableIndex] = useState<number>(null);
  const [sizes, setSizes] = useState<SizesDropDownDto[]>([]);
  const [paymentTermsData, setPaymentTermsData] = useState<PaymentTermsDropDownDto[]>([])
  const [taxes, setTaxes] = useState<TaxDropDownDto[]>([]);
  const [itemCategoryDetails, setItemCategoryDetails] = useState<ItemCategoryDropDownDto>();
  const [itemSubCategoryDetails, setItemSubCategoryDetails] = useState<ItemSubCategoryDropDownDto>();
  const [taxData, setTaxData] = useState<TaxDropDownDto>();
  const [defaultTax, setDefaultTax] = useState<TaxDropDownDto>();
  const [saleOrderId, setSaleOrderId] = useState<number>(0);
  const [saleOrderNumber, setSaleOrderNumber] = useState<string>('');

  const itemCategoryService = new ItemCategoryService;
  const itemService = new ItemsService;
  const sizeService = new SizesService;
  const taxService = new TaxesService;
  const subCategoryService = new ItemSubCategoryService();

  const [subCategories, SetSubCategories] = useState<ItemSubCategoryDropDownDto[]>([]);
  const [itemDetails, setItemDetails] = useState<ItemsDropDownDto>();
  const [size, setsize] = useState<SizesDropDownDto>();
  const [itemEditForm, setItemEditForm] = useState<boolean>(false);
  const [afterSubmitDisable, setAfterSubmitDisable] = useState<boolean>(false);
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState<number>(null);
  const [deletedRecords, setDeletedRecords] = useState<PoItemsDetails[]>([]);
  const poStateData: PoStateDetails | any = location.state;
  const [formUpdateDetails, setFormUpdateDetails] = useState<UpdatePoDetails>();
  const [disableUpdate, setDisableUpdate] = useState<boolean>(false);
  const [withOutSoDisable, setWithOutSoDisable] = useState<boolean>(false);
  const [poDetailsId, setPoDetailsId] = useState();
  const [bomItemId, setBomItemId] = useState<number>(0);
  const [fromPO, setFromPO] = useState<boolean>(false);
  const [disablePoType, setDisablePoType] = useState<boolean>(true);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [plantsANdHoData, setPlantsANdHoData] = useState<PlantsDropDown[]>([]);

  const displayAvlQty = poStateData ? !poStateData.fromBomTrackingReport : true;

  // To get poDetails
  useEffect(() => {
    getPlantsData();
    getHOPlantsData();
    getAllVendors();
    getSoNumbersForPo();
    getTaxes();
    getTaxForNoTax();
    getItemCategories();
    getAllPaymentTerms();
    if (poStateData) {
      getSizes();
      if (poStateData.type == 'PO') {
        getSoNumbers();
        getPoData(poStateData.id)
        setDisableUpdate(true);
        setPoDetailsId(poStateData.id);
      } else if (poStateData.type == 'BOM' || poStateData.type == 'Saleorder') {
        setDisablePoType(false)
        getSoNumbersForPo();
        form.setFieldsValue({
          saleOrderId: poStateData.id,
          plantAddress: poStateData.unitId,
          poType: 'WITH SO'
        })
      } else if (poStateData.type == 'GeneratePO') {
        GetItemsForBomVendor();
        getSOsAgainstPlant(poStateData.unitId);
        form.setFieldsValue({
          poType: 'WITH SO',
          vendorId: poStateData.vendorId,
          plantAddress: poStateData.unitId,
          saleOrderId: poStateData.soIds
        });

      }
    } else {
      // getAllActiveSizesDropDown();
      // getSoNumbersForPo();
      form.setFieldsValue({
        poType: 'WITH SO'
      })
    }

  }, [form])

  const GetItemsForBomVendor = () => {
    const req = new BomIdsRequest();
    req.packingMaterialId = poStateData.bomIds;
    req.vendorId = poStateData.vendorId;
    req.unitId = poStateData.unitId;
    poService.GetItemsForBomVendor(req).then((res) => {
      if (res.status) {
        if (res.data.length) {
          setTableData(res.data);
          calculateAmount(res.data);
        } else {
          setTableData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      } else {
        setTableData([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      setTableData([]);
      AlertMessages.getErrorMessage(err.message);
    });
  }
  // To get the Sizes Data
  const getSizes = () => {
    sizeService.getAllSizesDropDown().then((res) => {
      if (res.status) {
        setSizes(res.data);
      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }

  const getPlantsData = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data);
        setFactoriesData(res.data);
      } else {
        setFactoriesData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setFactoriesData([]);
    });
  }

  const getHOPlantsData = () => {
    unitService.getAllMainAndHoPlants().then((res) => {
      if (res.status) {
        setPlantsANdHoData(res.data);
      } else {
        setPlantsANdHoData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsANdHoData([]);
    });
  }

  // To get all the Active Sizes Data
  const getAllActiveSizesDropDown = () => {

    sizeService.getAllActiveSizesDropDown().then((res) => {

      if (res.status) {
        setSizes(res.data);

      } else {

        setSizes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSizes([]);
    });
  }

  // // To get all the Active Sizes Data
  // const getAllActiveSizesDropDownForCategoryAndItem = () => {
  //   const itemCategoryId = JSON.parse(poItemsForm.getFieldValue('itemCategoryId')).itemCategoryId;
  //   const itemId = JSON.parse(poItemsForm.getFieldValue('itemName')).itemId;
  //   const req = new CategoryItemReq();
  //   req.categoryId = itemCategoryId;
  //   req.itemId = itemId;
  //   sizeService.getAllActiveSizesDropDownForCategoryAndItem(req).then((res) => {
  //     if (res.status) {
  //       setSizes(res.data);
  //       if (!res.data.length) {
  //         getAllActiveSizesDropDown();
  //       }
  //     } else {
  //       setSizes([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setSizes([]);
  //   });
  // }

  // To get the Item Categories
  const getItemCategories = () => {
    itemCategoryService.getActiveItemCategories().then((res) => {
      if (res.status) {
        setItemCategories(res.data);
      } else {
        setItemCategories([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategories([]);
    });
  }


  // To get the Item Sub Categories Against Item Category
  const getItemSubCategories = () => {
    SetSubCategories([]);
    setItems([]);
    poItemsForm.setFieldsValue({ itemSubCategoryId: '', itemName: '' });
    const itemsRequest = new ItemCategoryRequest(JSON.parse(poItemsForm.getFieldValue('itemCategoryId')).itemCategoryId);
    subCategoryService.getItemSubCategoriesForCategoryDropDown(itemsRequest).then((res) => {
      if (res.status) {
        SetSubCategories(res.data);
      } else {
        SetSubCategories([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  // To get the Items Against Item Category and Item Sub Category
  const getItems = () => {
    setItems([]);
    poItemsForm.setFieldsValue({ itemName: '' });
    const itemCategoryId = JSON.parse(poItemsForm.getFieldValue('itemCategoryId')).itemCategoryId;
    const itemSubCategoryId = JSON.parse(poItemsForm.getFieldValue('itemSubCategoryId')).itemSubCategoryId;
    const itemsRequest = new ItemsReqForCategoryId(itemCategoryId, itemSubCategoryId);
    itemService.getItemForCategoryAndSubCat(itemsRequest).then((res) => {
      if (res.status) {
        setItems(res.data);
      } else {
        setItems([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }

  // To get the Taxes Data
  const getTaxes = () => {
    taxService.getActiveTaxDropDown().then((res) => {
      if (res.status) {
        setTaxes(res.data);
      } else {
        setTaxes([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setTaxes([]);
    });
  }

  // To get the Taxes Data
  const getTaxForNoTax = () => {
    taxService.getDefaultTaxForPO().then((res) => {
      if (res) {
        setDefaultTax(res);
        poItemsForm.setFieldsValue({ taxPer: JSON.stringify(res) })
      } else {
        setDefaultTax(undefined);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setDefaultTax(undefined);
    });
  }




  // To getPoNumbers()

  const getPoData = (poId) => {
    poService.GetPurchaseOrderDetailsForPoId(new PoRequest(poId)).then((res) => {
      if (res.status) {
        console.log('res.dataaaaaaaaaaaa');
        console.log(res.data);
        
        setFormUpdateDetails(res.data);
        setTableData(res.data.poItems);
        setPoDetailsFormKey(prevStateVal => prevStateVal + 1)
        setPoItemsFormKeyUpdate(prevStateVal => prevStateVal + 2)
        form.setFieldsValue({
          saleOrderId: (res.data.poType == POTypeEnum.WITHSO) ? res.data.poItems[0].saleOrderNumber: null,
          // saleOrderNumber:(res.data.poType == POTypeEnum.WITHSO) ? res.data.saleOrderNumber :null,
          vendorId: res.data.vendorId,
          poType: res.data.poType,
          plantAddress: res.data.plantAddress
        })
        if (res.data.poType === POTypeEnum.WITHOUTSO) {
          setWithOutSoDisable(true);
        }
        getVendorDataById(res.data.vendorId);
        poDetailsForm.setFieldsValue({
          purchaseOrderId: res.data.purchaseOrderId,
          purchaseOrderDate: moment(res.data.purchaseOrderDate),
          transportation: res.data.transportation,
          paymentTerms: res.data.paymentTerms,
          exchangeRate: res.data.exchangeRate,
          remarks: res.data.remarks,
          note: res.data.note,
          expectedDeliveryDate: moment(res.data.expectedDeliveryDate),
          deliveryAddress: res.data.deliveryAddress,
          billingAddress: res.data.billingAddress,
          deliveryTerms: res.data.deliveryTerms,
          shippingTerms: res.data.shippingTerms,
        })
        setTransport(res.data.transportation)
        calculateAmount(res.data.poItems);
      } else {
        setPoDetails(null);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPoDetails(null);
    });

  }





  // const soDetails = (soData) => {
  //   setSoData(soData);
  //   setPoItemsFormKeyUpdate(preStateval=>preStateval+1);

  // }
  const poItemDetails = (itemDetalFormdata) => {
    setPoItemsdata(itemDetalFormdata)
  }

  const onReset = () => {
    form.resetFields();
    setBomItemId(0);
    setFromPO(false);
  };

  const clearItems = () => {
    poItemsForm.resetFields();
    poItemsForm.setFieldsValue({ taxPer: JSON.stringify(defaultTax) })
  };




  // To get SO numbers
  const getSoNumbers = () => {
    soService.getSoNumbersForPoFormUpdate().then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  // To get SO numbers
  const getSoNumbersForPo = () => {
    soService.getSoNumbersForPo({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setSaleOrderData(res.data);
      } else {
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  // To get Vendors
  const getAllVendors = () => {
    vendorService.getVendorsDropDownData().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setVendorData(res.data);
      } else {
        if (res.intlCode) {
          setVendorData([]);
        } else {
          setVendorData([]);
        }
      }
    }).catch(err => {
      setVendorData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // To get Forms with Data
  const viewPo = (poData: PoDto, viewOnly: boolean = true) => {
    setShowPo(true);
    getSoData(selectedSaleOrder);
  }

  // const updateTotalAmount = (amount) => {
  //   console.log(amount);
  //   setTotalAmount(amount);
  // }

  const updateTransport = (val) => {
    setTransport(val)
  }

  // To Save PO
  const savePo = () => {
    form.validateFields().then(poRes => {
      poDetailsForm.validateFields().then(poRes => {
        setPoDetailsFormKey(prevState => prevState + 1);
        const poData = poDetailsForm.getFieldsValue();
        const poDate = moment(poData.poDate).format('YYYY-MM-DD');
        const poItemsInfo: PoItemsCreateDetails[] = []
        console.log(tableData)

        let taxMandatory = true;
        [...tableData,...deletedRecords].forEach(itemData => {
          if (itemData.tax) {
            poItemsInfo.push(new PoItemsCreateDetails(itemData.itemCategory.itemCategoryId, itemData.itemSubCategory.itemSubCategoryId, itemData.item.itemId, 0, Number(itemData.quantity), itemData.unitPrice, (itemData.tax) ? itemData.tax.taxId : 0, itemData.taxAmount, itemData.discountPercentage, itemData.discountAmount, itemData.transportation, itemData.Amount, itemData.bomItemId, itemData.purchaseOrderId, itemData.purchaseOrderItemId, itemData.saleOrderId, itemData.fromPo, itemData.isActive))
          } else {
            taxMandatory = false
          }
        })
        console.log(poItemsInfo)
        if (taxMandatory) {
          const SoVendorData = form.getFieldsValue();
          const poDto = new PoDetailsDto(SoVendorData.vendorId, 0, poData.purchaseOrderDate, poData.transportation, poData.expectedDeliveryDate, poData.deliveryAddress, poData.paymentTerms, poData.shippingTerms, poData.deliveryTerms, poData.remarks, poData.note, (poData.exchangeRate) ? poData.exchangeRate : 0, localStorage.getItem("createdUser"), localStorage.getItem("createdUser"), poItemsInfo, SoVendorData.poType, (poDetailsId) ? Number(poDetailsId) : 0, SoVendorData.plantAddress);
          poDto.plantAddress = SoVendorData.plantAddress;
          poDto.billingAddress = poDetailsForm.getFieldValue('billingAddress');
          poDto.unitId = (localStorage.getItem('unit_id')) ? Number(localStorage.getItem('unit_id')) : 0;
          poDto.totalAmount = poDetailsForm.getFieldValue('totalCost');
          console.log(poDto)

          setAfterSubmitDisable(true);
          poService.CreatePo(poDto).then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage((!disableUpdate) ? 'Po created successfully' : 'Po updated successfully');
              clearItems();
              onReset();
              setAfterSubmitDisable(false);
              history.push("purchase-order-grid");
            } else {
              setAfterSubmitDisable(false);
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch((err) => {
            setAfterSubmitDisable(false);
            AlertMessages.getErrorMessage(err.message);
          });
        } else {
          AlertMessages.getErrorMessage('Please Fill Tax');
        }
        // setAfterSubmitDisable(false);
      })
    })
  }

  const handleSaleOrder = (value) => {
    setSelectedSaleOrder(value);
  }

  const handleItemsSaleOrder = (saleOrderId, value) => {
    setSelectedSaleOrder(value);
  }

  const handleVendor = (value) => {
    setSelectedVendor(value);
  }

  // To get BOM data against SO ID
  const getSoData = async (selectedSaleOrder) => {
    await soService.getAllBomDetailsForSoId(new SoRequest(selectedSaleOrder)).then((res) => {

      if (res.status) {
        setSoDetails(res.data);
        setShowTable(true);
      } else {
        setSoDetails([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSoDetails([]);
    });

  }

  const getVendorDataById = (values) => {
    vendorService.getVendorDataById(new VendorRequest(values)).then((res) => {

      if (res.status) {
        poDetailsForm.setFieldsValue({
          currencyName: res.data.currencyName,
        })
      } else {
        setSoDetails([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSoDetails([]);
    });

  }

  // Table Columns
  const columns: any[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      fixed: 'left',
      render: (text, object, index) => (index + 1)
    },
    {
      title: 'SO Number',
      dataIndex: 'saleOrderNumber',
      key: 'saleOrderNumber',
      fixed: (withOutSoDisable) ? false : 'left',
      className: (withOutSoDisable) ? 'hideColumn' : 'showColumn',
      render: (value, record: PoItemsDetails, index) => {
        return (record.saleOrderNumber) ? record.saleOrderNumber : '';
      },
    },
    {
      title: 'Item Category',
      dataIndex: 'itemCategory.itemCategoryId',
      key: 'itemCategory.itemCategoryId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.itemCategory) ? record.itemCategory.itemCategory : '';
      },
    },
    {
      title: 'Item Sub Category',
      dataIndex: 'itemCategory.itemSubCategoryId',
      key: 'itemCategory.itemSubCategoryId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.itemSubCategory) ? record.itemSubCategory.itemSubCategory : '';
      },
    },
    {
      title: 'Item',
      dataIndex: 'item.itemId',
      key: 'item.itemId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.item) ? record.item.itemName : '';
      },
    },
    // {
    //   title: 'Size',
    //   dataIndex: 'size.sizeId',
    //   key: 'size.sizeId',
    //   render: (value, record: PoItemsDetails, index) => {
    //     return (record.size) ? record.size.sizeName : '';
    //   },
    // },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, data, index) => {
        console.log(data)
        return <span>{Number(data.quantity)}<br/><span style={{color:'red'}}>{ displayAvlQty ? `Avail Qty:${Number(data.availbleQty)}` : '' }</span></span>
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text, data, index) => {
        return <span>{(data.unitPrice) ? Number(Number(data.unitPrice).toFixed(3)) : 0}</span>
      }
    },
    {
      title: 'Tax (%)',
      dataIndex: 'tax.taxId',
      key: 'tax.taxId',
      render: (value, record: PoItemsDetails, index) => {
        return (record.tax) ? record.tax.taxName : '';
      },
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax',
      key: 'tax',
      render: (text, data, index) => {
        return <span>{(data.taxAmount) ? Number(Number(data.taxAmount).toFixed(3)) : 0}</span>
      }
    },

    {
      title: 'Discount (%)',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, data, index) => {
        return <span>{data.discountPercentage}</span>
      }
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (text, data, index) => {
        return <span>{data.discountAmount}</span>
      }
    },
    {
      title: 'Transportation',
      dataIndex: 'transportation',
      key: 'transportation',
      render: (text, data, index) => {
        return <span>{data.transportation}</span>
      }
    },
    {
      title: 'Subjective Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text, data, index) => {
        return <span>{data.Amount}</span>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      // width: '20%',
      render: (text, rowData: PoItemsDetails, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                // if (rowData) {
                setEditForm(rowData, index);
                // }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          <Divider type="vertical" />
          {<Tooltip placement="top" title='delete'>
          <Popconfirm title='Sure to delete?' onConfirm={e =>{deleteData(index, rowData);}}>

            <MinusCircleOutlined 
              style={{ color: '#1890ff', fontSize: '14px' }} />
          </Popconfirm>
          </Tooltip>
          }
        </span>)
    }
  ];

  const showConfirm = (rowData: PoItemsDetails, index) => {
    confirm({
      title: 'Are you sure do you want to delete the item?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check once',
      onOk() {
        deletePOItem(rowData, index);
      },
      onCancel() {
        AlertMessages.getErrorMessage('Cancelled Deleting Item');
      },
    });
  }


  const deletePOItem = (rowData: PoItemsDetails, index) => {
    if (rowData.purchaseOrderItemId) {
      const req = new POItemIdRequest(rowData.purchaseOrderItemId);
      poService.deletePoItem(req).then((res) => {
        if (res.status) {
          tableData.splice(index, 1);
          console.log(tableData)
          setTableData(tableData);
          setkeyUpdate(keyUpdate + 1);
          calculateAmount(res.data);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
    }

  }
  // To get the Items Against Item Category
  const getItemsForCategory = (itemCategoryId) => {
    const itemsRequest = new ItemsReqForCategoryId(itemCategoryId);
    itemService.getItemByCategoryId(itemsRequest).then((res) => {
      if (res.status) {
        setItems(res.data);
      } else {
        setItems([]);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    });
  }


  // To set the values to edit
  const setEditForm = (rowData: PoItemsDetails, index) => {
    console.log('rowData: PoItemsDetails');
    console.log(rowData.tax);
    const taxPercent=JSON.stringify({ taxId:rowData.tax.taxId,taxName:rowData.tax.taxName })

    setEditItem('Update');
    poItemsForm.setFieldsValue({
      itemCategoryId: JSON.stringify(rowData.itemCategory)
    });
    getItemSubCategories();
    poItemsForm.setFieldsValue({
      itemSubCategoryId: JSON.stringify(rowData.itemSubCategory)
    });
    getItems();
    poItemsForm.setFieldsValue({
      itemName: JSON.stringify(rowData.item)
    });
    // if (withOutSoDisable) {
    //   getAllActiveSizesDropDownForCategoryAndItem();
    // } else {
    //   getAllActiveSizesDropDown();
    // }

    setItemEditForm(true)
    // setDefaultFormData(rowData);
    setIndexVal(index);
    setBtnDisable(false);


    poItemsForm.setFieldsValue({
      saleOrderId: JSON.stringify({ saleOrderId: rowData.saleOrderId, saleOrderNumber: rowData.saleOrderNumber }),
      itemCategoryId: JSON.stringify(rowData.itemCategory),
      itemSubCategoryId: JSON.stringify(rowData.itemSubCategory),
      itemName: JSON.stringify(rowData.item),
      sizeName: JSON.stringify(rowData.size),
      quantity: Number(rowData.quantity),
      unitPrice: rowData.unitPrice,
      taxPer: (rowData.tax) ? JSON.stringify({taxId:rowData.tax.taxId,taxName:rowData.tax.taxName,taxPercentage:rowData.tax.taxPercentage}) : JSON.stringify(defaultTax),
      taxAmount: (rowData.taxAmount) ? Number(Number(rowData.taxAmount).toFixed(3)) : 0,
      discountPercentage: (rowData.discountPercentage) ? Number(rowData.discountPercentage) : 0,
      discountAmount: (rowData.discountPercentage) ? Number(rowData.discountAmount) : 0,
      transportation: (rowData.transportation) ? Number(rowData.transportation) : 0,
      Amount: rowData.Amount ? Number(rowData.Amount) : 0,
      bomItemId: rowData.bomItemId,
      purchaseOrderId: rowData.purchaseOrderId,
      purchaseOrderItemId: rowData.purchaseOrderItemId,
      saleOrderNumber: rowData.saleOrderNumber,
      fromPo: rowData.fromPo,
      isActive: rowData.isActive,
      availableQty : rowData.availbleQty
    })
    setBomItemId(rowData.bomItemId);
    setFromPO(rowData.fromPo);
    // setItemCategoryDetails(rowData.itemCategory);
    // setItemSubCategoryDetails(rowData.itemSubCategory);
    // setItemDetails(rowData.item);
    // setsize(rowData.size);
    // setTaxData(rowData.tax);
  }

  // To delete the row data
  const deleteData = (index, rowData) => {
    if (disableUpdate) {
      rowData.isActive = false;
      deletedRecords.push(JSON.parse(JSON.stringify(rowData)));
      console.log('yes'+index)
      console.log(deletedRecords)
      tableData.splice(index, 1);
      console.log(tableData)
      setTableData([...tableData]);
      setDeletedRecords(deletedRecords)
    } else {
      console.log('no')
      tableData.splice(index, 1);
      console.log(tableData)

      setTableData([...tableData]);
    }

    if (tableData.length == 0) {
      setShowTable(false)
    }
    calculateTotalAmount();
  }

  const getItemDetails = () => {
    setDisable(true)
    console.log('yyyy')

    form.validateFields().then(poForm => {
      poService.GetItemsForSaleOrderVendor(poForm).then((res) => {
        setDisable(false)
        if (res.status) {
          if (res.data.length) {
            setTableData(res.data);
            calculateAmount(res.data);
          } else {
            setTableData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        } else {
          console.log(res)
          setTableData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch((err) => {
        setDisable(false)
        setTableData([]);
        AlertMessages.getErrorMessage(err.message);
      });
    });
  }



  const onAddProduct = async () => {
    setEditItem('Add');

    await poItemsForm.validateFields().then(res => {
      if (!withOutSoDisable && !disableUpdate && res.bomItemId) {
        res.fromPo = false;
      }
      console.log(tableData)
      console.log(res)
      const poItem = new PoItemsDetails(JSON.parse(res.itemCategoryId), JSON.parse(res.itemSubCategoryId), JSON.parse(res.itemName), null, Number(res.quantity), res.unitPrice, JSON.parse(res.taxPer), res.taxAmount, res.discountPercentage, res.discountAmount, res.transportation, res.Amount, res.bomItemId, res.purchaseOrderId, res.purchaseOrderItemId, (res.saleOrderId) ? JSON.parse(res.saleOrderId).saleOrderId : 0, (res.saleOrderId) ? JSON.parse(res.saleOrderId).saleOrderNumber : '', '', res.fromPo, res.isActive,res.availableQty!=undefined?res.availableQty:0)

      const exists = tableData.find(item => (item.item.itemId === JSON.parse(res.itemName).itemId) && item.saleOrderId === JSON.parse(res.saleOrderId).saleOrderId && item.isActive == true);
      console.log(exists);
      // const exists = tableData.find(item => (item.item.itemId === res.itemName&&item.size.sizeId===res.sizeName));

      if (exists && !(itemEditForm)) {
        console.log("exists");
        AlertMessages.getErrorMessage('Item already in list');
      } else {
        console.log("existssss");
        if (itemEditForm) {
        // console.log("edit");

        tableData.splice(indexVal, 1, poItem);
        } else {
        console.log("not edit");

          tableData.push(poItem);
        }
        console.log(tableData,'after push')
        setTableData(tableData);
        setkeyUpdate(keyUpdate + 1);
        // setItemCategoryDetails(null);
        // setItemSubCategoryDetails(null);
        // setItemDetails(null);
        // setsize(null);
        // setTaxData(null);
        calculateTotalAmount();
        setBomItemId(0);
        clearItems();
        setItemEditForm(false);
      }

    })



  }
  const handleChange = () => {
    const transport1: number = (poDetailsForm.getFieldValue('transportation')) ? Number(poDetailsForm.getFieldValue('transportation')) : 0;

    setTransport(transport1)

  }

  const validateAmount = () => {

    const acceptedQty: number = (poItemsForm.getFieldValue('quantity')) ? Number(poItemsForm.getFieldValue('quantity')) : 0;
    const unitPrice: number = (poItemsForm.getFieldValue('unitPrice')) ? Number(poItemsForm.getFieldValue('unitPrice')) : 0;
    const ItemsCost: number = acceptedQty * unitPrice;
    const itemDiscountAMount: number = (poItemsForm.getFieldValue('discountPercentage')) ? (ItemsCost * Number(poItemsForm.getFieldValue('discountPercentage')) / 100) : 0;
    let taxPercentage = 0;
    if (poItemsForm.getFieldValue('taxPer')) {
      taxes.map((tax) => {
        if (tax.taxId == JSON.parse(poItemsForm.getFieldValue('taxPer')).taxId) {
          taxPercentage = Number(tax.taxPercentage);
        }
      })
    }
    const itemTaxAMount = (taxPercentage) ? ((ItemsCost - itemDiscountAMount) * Number(taxPercentage / 100)) : 0;
    const itemTotCost = (ItemsCost - itemDiscountAMount) + itemTaxAMount;
    const transport: number = (poItemsForm.getFieldValue('transportation')) ? Number(poItemsForm.getFieldValue('transportation')) : 0;
    const totalCost = transport + itemTotCost;
    poItemsForm.setFieldsValue({
      quantity: acceptedQty,
      discountAmount: (itemDiscountAMount) ? Number(Number(itemDiscountAMount).toFixed(3)) : 0,
      taxAmount: (itemTaxAMount) ? Number(Number(itemTaxAMount).toFixed(3)) : 0,
      transportation: transport,
      Amount: totalCost ? Number(Number(totalCost).toFixed(3)) : 0,
    })
  }

  const handlePaymentTerm = (value) => {
    setSelectedPaymentTerms(value);
  }


  const getAllPaymentTerms = () => {
    paymentTermsService.getAllVendorPaymentTermsDropDown().then(res => {
      if (res.status) {
        // AlertMessages.getSuccessMessage('Vendors Retrived successfully');
        setPaymentTermsData(res.data);
      } else {
        if (res.intlCode) {
          setPaymentTermsData([]);
        } else {
          setPaymentTermsData([]);
        }
      }
    }).catch(err => {
      setPaymentTermsData([]);
    })
  }

  const handleCurrency = () => {

  }

  const calculateTotalAmount = () => {
    let TotalAmount = 0;
    const transport1: number = (poDetailsForm.getFieldValue('transportation')) ? Number(poDetailsForm.getFieldValue('transportation')) : 0;
    setTransport(transport1)
    // let totalTransportation =  transport?transport:0;
    tableData.map((items) => {
      if (items.isActive) {
        TotalAmount = Number(TotalAmount) + Number((items.Amount) ? items.Amount : 0);

      }
    })
    TotalAmount = Number(TotalAmount) + Number(transport1);
    poDetailsForm.setFieldsValue({ totalCost: TotalAmount ? Number(Number(TotalAmount).toFixed(3)) : 0 });
  }

  const calculateAmount = (itemDetails) => {
    if (itemDetails) {
      let TotalAmount: Number = 0;
      let totalTransportation: Number = (poDetailsForm.getFieldValue('transportation')) ? poDetailsForm.getFieldValue('transportation') : 0;
      itemDetails.map((items) => {
        if (items.isActive) {
          TotalAmount = Number(TotalAmount) + Number(items.Amount);
        }
      })
      TotalAmount = Number(TotalAmount) + Number(totalTransportation);
      poDetailsForm.setFieldsValue({ totalCost: Number(Number(TotalAmount).toFixed(3)) });
    }
  }
  const renderFooterData = (data: any[]) => {
    if (tableData.length > 0) {
      let TotalAmount1 = 0;
      let totalTransportation1: number = transport ? Number(transport) : 0;
      tableData.map((items) => {
        TotalAmount1 = Number(TotalAmount1) + Number(items.Amount);
      })
      TotalAmount1 = Number(TotalAmount1) + Number(totalTransportation1);
      // poDetailsForm.setFieldsValue({totalCost:Number(TotalAmount1?Number(TotalAmount1):0)});
      // poDetailsForm.setFieldsValue({transportation:Number(totalTransportation1?Number(totalTransportation1):0)});

      return (<><span style={{marginLeft:'700px'}}><b>Total Cost : {(poDetailsForm.getFieldValue('currencyName')) ? convertCurrencyToSymbol(poDetailsForm.getFieldValue('currencyName')) : ''} {TotalAmount1 ? Number(Number(TotalAmount1).toFixed(3)) : 0}  </b></span></>)
    }
  }

  const disabledDate = (current) => {
    const poDate = poDetailsForm.getFieldValue('purchaseOrderDate');
    return current < poDate;
  }

  const withSo = () => {
    const poType = form.getFieldValue('poType');
    if (poType == POTypeEnum.WITHOUTSO) {
      setWithOutSoDisable(true);
      form.setFieldsValue({
        saleOrderId: null
      })
    } else {
      setWithOutSoDisable(false);
    }
    clearItems();
    resetPoDetails();
    setTableData([]);
    setkeyUpdate(keyUpdate + 1);
    getTaxForNoTax();
  }

  const resetPoDetails = () => {
    poDetailsForm.resetFields();
  }

  const convertCurrencyToSymbol = (currency) => {
    let symbol = '';
    CrrencySymbols.map((curr) => {
      if (curr.name == currency) {
        symbol = curr.symbol
      }
    })
    return symbol;
  }

  const getSOsAgainstPlant = (plantId) => {
    if (plantId) {
      poDetailsForm.setFieldsValue({deliveryAddress:plantId})
      form.setFieldsValue({ saleOrderId: undefined })
      const req = new UnitRequest(plantId);
      soService.getSOsAgainstPlant(req).then((res) => {
        if (res.status) {
          setSaleOrderData(res.data);
        } else {
          setSaleOrderData([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSaleOrderData([]);
      });
    }
  }

  const getVendorPrice = (val,rowObj) => {
    console.log(rowObj)
    if((form.getFieldValue('poType') == 'WITH OUT SO' || form.getFieldValue('poType') == 'WITH SO') && form.getFieldValue('vendorId') > 0){
      vendorPriceService.GetItemPriceTaxForVendorIdItemId(new VendorItemRequest(form.getFieldValue('vendorId'),Number(rowObj.key))).then(res => {
        if(res.status){
          console.log(res.data)
          poItemsForm.setFieldsValue({unitPrice:Number(res.data.unitPrice)})
        }
      })
    }
  }

  return (
    <>
      <Card title={<span style={{ color: 'white' }}>PO</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/purchase-order-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>}>

        <Form layout="vertical" form={form} name="control-hooks" >
          <Row gutter={24}>
            <Col style={{ display: (disablePoType) ? 'block' : 'none' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <Form.Item
                name="poType"
                label="PO Type"
                rules={[
                  {
                    required: true,
                    message: 'PO Type is required'
                  },
                ]}

              >
                <Select
                  showSearch
                  placeholder="Select PO Type"
                  defaultValue={'WITH SO'}
                  onChange={withSo}
                  disabled={disableUpdate}
                >
                  <Option key={0} value={null}>Select PO Type</Option>
                  <Option key={1} selected value={'WITH SO'}>WITH SO</Option>
                  <Option key={2} value={'WITH OUT SO'}>WITH OUT SO</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
              <Form.Item
                name="plantAddress"
                label="Plant"
                rules={[
                  {
                    required: true, message: 'Missed Plant'
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Select Plant"
                  onChange={getSOsAgainstPlant}
                  dropdownMatchSelectWidth={false}
                  disabled={disableUpdate || !disablePoType}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* {FactoriesInput.map((addr) => {
                      return <Option key={addr.address} value={addr.value + " - " + addr.address}>{addr.value} - {addr.addnress}</Option>
                    })} */}

                  {factoriesData.map(dropData => {
                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: !withOutSoDisable, message: 'SO Number is Required' },]}>
                <Select
                  showArrow
                  placeholder="Select SO Number"
                  optionFilterProp="children"
                  onChange={handleSaleOrder}
                  onClear={onReset}
                  mode="multiple"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={withOutSoDisable || disableUpdate || !disablePoType}
                  dropdownMatchSelectWidth={false}
                  showSearch
                >
                  {saleOrderData.map((saleOrder) => {
                    return <Option key={saleOrder.saleOrderId} value={saleOrder.saleOrderId}>{saleOrder.saleOrderNumber}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

              <Form.Item name="vendorId" label="Vendor Name" rules={[{ required: true, message: 'Vendor is Required' },]}>
                <Select showSearch placeholder="Select Vendor" optionFilterProp="children" onChange={getVendorDataById} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={disableUpdate}
                  dropdownMatchSelectWidth={false}
                >
                  <Option key={0} value={null}>Select Vendor</Option>
                  {vendorData.map((vendor) => {
                    return <Option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</Option>
                  })}
                </Select>
              </Form.Item>

            </Col>
            <Col>

              <Form.Item style={{ paddingTop: 30 }}>
                <Button type="primary" disabled={withOutSoDisable || disableUpdate ||disable} onClick={() => getItemDetails()} >
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ paddingTop: 30 }}>
                <Button htmlType="button" disabled={withOutSoDisable || disableUpdate} onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {(tableData.length || deletedRecords.length || withOutSoDisable) ? <Card title={disableUpdate ? <span style={{ color: 'white' }}>Update PO Details</span> : <span style={{ color: 'white' }}>Create PO</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>


          <Form form={poDetailsForm} autoComplete="off" name="control-hooks" layout="vertical">
            <Row>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="purchaseOrderDate"
                  label="PO Date"
                  rules={[{ required: true, message: 'Missing PO Date' },
                  ]} initialValue={moment()}>
                  <DatePicker style={{ width: '100%' }} />

                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item label="Transportation" name="transportation"  >
                  <InputNumber style={{ width: '100%' }} defaultValue={0} onChange={calculateTotalAmount} min={0} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item name="paymentTerms" label="Payment Terms" rules={[{ required: false, message: 'Missing Payment Terms' },]}>
                  <Select
                    showSearch
                    placeholder="Select Payment Term"
                    optionFilterProp="children"
                    onChange={handlePaymentTerm}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={null}>Select Payment</Option>
                    {paymentTermsData.map((payment) => {
                      return <Option key={payment.paymentTermsId} value={payment.paymentTermsId}>{payment.paymentTermsName}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="currencyName"
                  label="Currency"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input onChange={handleCurrency} disabled />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="exchangeRate"
                  label="Exchange Rate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} min={0} onChange={handleChange} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="remarks"
                  label="Remarks"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="note"
                  label="Note"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="expectedDeliveryDate"
                  label="Expected Delivery Date"
                  rules={[
                    {
                      required: true, message: 'Missed Expected Delivery Date'
                    },
                  ]}>
                  <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="deliveryAddress"
                  label="Delivery Address"
                  rules={[
                    {
                      required: true, message: 'Missed Delivery Address'
                    },
                  ]}>
                  <Select
                    placeholder="Select Address"
                    dropdownMatchSelectWidth={false}
                    disabled
                  >
                    <Option key={0} value={null}>Select Address</Option>
                    {/* {FactoriesInput.map((addr) => {
                      return <Option key={addr.address} value={addr.value + " - " + addr.address}>{addr.value} - {addr.address}</Option>
                    })} */}

                    {factoriesData.map(dropData => {
                      return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="billingAddress"
                  label="Billing Address"
                  rules={[
                    {
                      required: true, message: 'Missed Billing Address'
                    },
                  ]}>
                  <Select
                    placeholder="Select Address"
                    dropdownMatchSelectWidth={false}
                  >
                    <Option key={0} value={null}>Select Address</Option>
                    <Option key={0} value={12}>Neeli Sea Foods</Option>
                    {plantsANdHoData.map(dropData => {
                      return <Option key={dropData.plantId} value={dropData.plantId}>{dropData.plantName}</Option>
                    })}
                    {/* <Option key={0} value={null}>Select Address</Option>
                    {FactoriesInput.map((addr) => {
                      return <Option key={addr.address} value={addr.value + " - " + addr.address}>{addr.value} - {addr.address}</Option>
                    })} */}
                  </Select>
                </Form.Item>
              </Col>


              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="shippingTerms"
                  label="Shipping Terms"
                  rules={[
                    {
                      required: false,
                      message: 'Shipping Terms is required'
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }} >
                <Form.Item
                  name="deliveryTerms"
                  label="Delivery Terms"
                  rules={[
                    {
                      required: false,
                      message: 'Delivery Terms is required'
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ margin: '1%' }}>
                <Form.Item label="Total Amount" name="totalCost"   >
                  <Input disabled={true} />
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Card> : ''}
        {(tableData.length || deletedRecords.length || withOutSoDisable) ?
          <Card title={<span style={{ color: 'white' }}>PO - Items Form</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Form layout="vertical" form={poItemsForm} name="control-hooks" initialValues={defaultFormData} >
              <Row gutter={24}>
                <Form.Item name="purchaseOrderId" >
                  <Input hidden />
                </Form.Item>
                <Form.Item name="purchaseOrderItemId" >
                  <Input hidden />
                </Form.Item>
                <Form.Item name="bomItemId" >
                  <Input hidden />
                </Form.Item>
                <Form.Item name="saleOrderNumber" >
                  <Input hidden />
                </Form.Item>
                <Form.Item name="fromPo" >
                  <Input hidden />
                </Form.Item>
                <Form.Item name="isActive" initialValue={true}>
                  <Input hidden />
                </Form.Item>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} style={{ display: withOutSoDisable ? 'none' : 'block' }}>

                  <Form.Item name="saleOrderId" label="SO Number" rules={[{ required: !withOutSoDisable, message: 'SO Number is Required' },]}>
                    <Select
                      showSearch
                      placeholder="Select SO Number"
                      optionFilterProp="children"
                      onChange={(saleorderId, value) => handleItemsSaleOrder(saleorderId, value)}
                      onClear={onReset}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                    >
                      <Option key={0} value={null}>Select SO Number</Option>
                      {saleOrderData.map((saleOrder) => {
                        return <Option key={saleOrder.saleOrderId} value={JSON.stringify({saleOrderId:saleOrder.saleOrderId,saleOrderNumber:saleOrder.saleOrderNumber})}>{saleOrder.saleOrderNumber}</Option>
                      })}
                    </Select>
                  </Form.Item>

                </Col>

                <Col span={5}>
                  <Form.Item label="Item Category"
                    name={'itemCategoryId'}
                    rules={[{ required: true, message: 'Missing Item Category' }]}

                  >
                    <Select disabled={bomItemId ? disableUpdate && fromPO ? false : true : false} showSearch placeholder="Select Item Category" onChange={() => getItemSubCategories()} allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {itemCategories.map(dropData => {
                        return <Option key={dropData.itemCategoryId} value={JSON.stringify(dropData)}>{dropData.itemCategory}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item label="Item Sub Category"
                    name={'itemSubCategoryId'}
                    rules={[{ required: true, message: 'Missing Item Sub Category' }]}

                  >
                    <Select disabled={bomItemId ? disableUpdate && fromPO ? false : true : false} showSearch placeholder="Select Item Sub Category" onChange={() => getItems()} allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {subCategories.map(dropData => {
                        return <Option key={dropData.itemSubCategoryId} value={JSON.stringify(dropData)}>{dropData.itemSubCategory}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={9}>
                  <Form.Item label="Item Name"
                    name={'itemName'}
                    rules={[{ required: true, message: 'Missing Item Name' }]}
                  >
                    <Select disabled={bomItemId ? disableUpdate && fromPO ? false : true : false} showSearch placeholder="Select Item" onChange={getVendorPrice}>
                      {items === undefined ? '' : items.map(dropData => {
                        return <Option key={dropData.itemId} value={JSON.stringify(dropData)}>{dropData.itemName}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={5}>
                  <Form.Item label="Size"
                    name={'sizeName'}
                    rules={[{ required: true, message: 'Missing Item Size' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Size"
                      allowClear
                    >
                      {sizes.map(dropData => {
                        return <Option name="sizeId" key={dropData.sizeId} value={JSON.stringify(dropData)}>{dropData.sizeName}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col span={4}>
                  <Form.Item label="Quantity"
                    name={'quantity'}
                    rules={[{ required: true, message: 'Missing Item Quantity' }]}

                  >
                    <Input onChange={validateAmount} />

                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label="Unit Price"
                    name={'unitPrice'}
                    rules={[{ required: true, message: 'Missing Unit Price' }]}
                  >
                    <InputNumber style={{ width: '100%' }} min={0} onChange={validateAmount} />

                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Discount (%)"
                    name={'discountPercentage'}
                    rules={[{ required: false, message: 'Missing Discount' }, { pattern: new RegExp('^[+]*[(+]{0,1}[0-9]{1,4}[)]{0,1}[\\s0-9]*$'), message: 'only numbers are allowed!.' }]}

                  >
                    <InputNumber style={{ width: '100%' }} min={0} max={100} onChange={validateAmount} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Discount Amount"
                    name={'discountAmount'}
                    rules={[{ required: false, message: 'Missing Discount Amount' }]}
                  >
                    <InputNumber disabled={true} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Tax Percentage(%)"
                    name={'taxPer'}
                    rules={[{ required: (poDetailsForm.getFieldValue('currencyName') == 'INR'), message: 'Missing Tax' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Tax"
                      // onChange={appendData}
                      allowClear
                      onChange={validateAmount}
                    >
                      {taxes.map(dropData => {
                        return <Option name="taxId" key={dropData.taxId} value={JSON.stringify(dropData)}>{dropData.taxName + " - " + dropData.taxPercentage}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Tax Amount"
                    name={'taxAmount'}
                    rules={[{ required: true, message: 'Missing Tax Amount' }]}
                  >
                    <InputNumber style={{ width: '100%' }} disabled={true} />

                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label="Transportation"
                    name={'transportation'}
                    rules={[{ required: false, message: 'Missing Transportation' }]}
                  >
                    <InputNumber style={{ width: '100%' }} onChange={validateAmount} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Subjective Amount"
                    name={'Amount'}
                    rules={[{ required: true, message: 'Missing Subjective Amount' }]}
                  >
                    <Input disabled={true} />

                  </Form.Item>
                </Col>
                <Form.Item name={'availableQty'} hidden>
                  <InputNumber defaultValue={0}/>
                </Form.Item>
              </Row>
              <Row justify="end">
                <Col>

                  <Form.Item >

                    <br />
                    <Button
                      // disabled={btnDisable}
                      style={{ backgroundColor: "#67b32d", color: "white" }}
                      onClick={onAddProduct}
                      block
                    >
                      {editItem}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item >
                    <br />

                    <Button style={{ backgroundColor: "#f19c9b", color: "white" }}

                      onClick={clearItems}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>

                {/* <Col span={6}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col> */}
              </Row>
            </Form></Card> : ''}



        {(tableData.length || deletedRecords.length) ? <Card title={<span style={{ color: 'white' }}>PO - Item Details</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <Table key={keyUpdate} dataSource={tableData.filter((val) => val.isActive)} columns={columns} pagination={false} footer={() => renderFooterData(tableData)} scroll={{ x: true }} />
          <br />
          <Row justify="end">
            <Col>
              <Form.Item >

                <br />
                <Button type='primary' disabled={afterSubmitDisable} block onClick={savePo}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card> : ''}

      </Card>
    </>
  );
}

export default PurchaseOrderForm;
