import axios from 'axios';
import {AllPaymentmodesResponseModel, PaymentmodesDto, PaymentmodesResponseModel, PaymentTermsDto} from '@gtpl/shared-models/masters';
import connection from './connection';
import { UserRequestDto } from '@gtpl/shared-models/common-models';

export class PaymentmodesService {
URL = connection.DEPLOY_URL + '/paymentmodes';

        async createPaymentmode(paymentmode: PaymentmodesDto): Promise<PaymentmodesResponseModel> {
            return await axios.post(this.URL + '/createPaymentmode',paymentmode)
                .then(res => {
                    return res.data
                })
        }
        async  updatePaymentmode(paymentmode: PaymentmodesDto): Promise<PaymentmodesResponseModel> {
        return await axios.post(this.URL + '/updatePaymentmode', paymentmode)
                        .then(res => {
                            return res.data
                        })
                }
            async getAllPaymentmodes(req:UserRequestDto): Promise<AllPaymentmodesResponseModel> {
                return await axios.post(this.URL + '/getAllPaymentModes',req)
                    .then(res => {
                        return res.data
                    })     
            }
            async  activatedeActivatePaymentmode(paymentmodeDto: PaymentmodesDto): Promise<PaymentmodesResponseModel> {
                return await axios.post(this.URL + '/updatePaymentMode', paymentmodeDto)
                        .then(res => {
                            return res.data
                        })
            }
            async  getAllActivePaymentmodes(): Promise<AllPaymentmodesResponseModel> {
                return await axios.post(this.URL + '/getAllActivePaymentModes')
                    .then(res => {
                        return res.data
                    })
            }
            async getActivePaymentmodesCount(): Promise<AllPaymentmodesResponseModel> {
            return await axios.post(this.URL + '/getActivePaymentmodesCount').then(res => {
                return res.data
            });
            }
            async getPaymentById(paymentTermsRequest:PaymentTermsDto): Promise<PaymentmodesResponseModel> {
                return await axios.post(this.URL + '/getPaymentById',paymentTermsRequest).then(res => {
                    return res.data
                });
            }

}