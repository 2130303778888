export class SoakingDetailsRequest{
    productionLogId: any;
    plantId: any;
    constructor(
        productionLogId:any,
        plantId: any,
        ){
            this.productionLogId= productionLogId,
            this.plantId= plantId
    }
}