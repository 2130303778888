
export class SoRequest {
    saleOrderId: number;
    itemCategoryId?:number;
    /**
     * @paarm saleOrderId
     */
    constructor(saleOrderId:number,itemCategoryId?:number){
        this.saleOrderId = saleOrderId;
        this.itemCategoryId = itemCategoryId;
    }
}