export class ItemsReqForCategoryId {
    itemCategoryId: number;
    itemSubCategoryId?: number;

    /**
     * 
     * @param itemCategoryId 
     * @param itemSubCategoryId 
     */
    constructor(itemCategoryId: number, itemSubCategoryId?: number){
        this.itemCategoryId = itemCategoryId;
        this.itemSubCategoryId = itemSubCategoryId;
    }
}

