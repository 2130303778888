import { AssetServiceChecklistDTO } from "./asset-service-checklist.dto";

export class AssetServiceDto {
    assetServeId: number;
    assetId: number;
    assetName: number;
    assetCode: string;
    assetCategory: string;
    serviceTenure: number;
    dateOfPurchased: any;
    assetServiceDate: any;
    actAssetServiceDate: any;
    isActive: boolean | null;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
    frequencyOfService: string;
    month: string;
    day: string;
    serviceProvider: string
    weekday: string
    status: string;
    assetServiceChecklist: AssetServiceChecklistDTO[]
    serviceName: string;
    assetCategoryId: number;
    remarks:string;
    phoneNumber:string;
    serviceOwner:string;
    constructor(assetServeId: number,
        assetId: number,
        assetName: number,
        assetCode: string,
        assetCategory: string,
        serviceTenure: number,
        dateOfPurchased: Date,
        assetServiceDate: Date,
        actAssetServiceDate: Date,
        isActive: boolean | null,
        createdAt: Date,
        createdUser: string | null,
        updatedAt: Date,
        updatedUser: string | null,
        versionFlag: number,
        frequencyOfService: string,
        month: string,
        day: string,
        serviceProvider: string,
        weekday: string,
        status: string,
        assetServiceChecklist: AssetServiceChecklistDTO[],
        serviceName: string,
        assetCategoryId: number,
        remarks:string,
        serviceOwner:string,
        phoneNumebr:string

    ) {
        this.assetServeId = assetServeId
        this.assetId = assetId
        this.assetName = assetName
        this.assetCode = assetCode
        this.assetCategory = assetCategory
        this.serviceTenure = serviceTenure
        this.dateOfPurchased = dateOfPurchased
        this.assetServiceDate = assetServiceDate
        this.actAssetServiceDate = actAssetServiceDate
        this.isActive = isActive
        this.createdAt = createdAt
        this.createdUser = createdUser
        this.updatedAt = updatedAt
        this.updatedUser = updatedUser
        this.versionFlag = versionFlag
        this.frequencyOfService = frequencyOfService;
        this.month = month;
        this.day = day;
        this.weekday = weekday;
        this.serviceProvider = serviceProvider
        this.status = status;
        this.assetServiceChecklist = assetServiceChecklist;
        this.serviceName = serviceName;
        this.assetCategoryId = assetCategoryId;
        this.remarks = remarks;
        this.serviceOwner = serviceOwner;
        this.phoneNumber = phoneNumebr;
    }
}