
export class ContainerRequest {
    containerId: number;

    /**
     * @param containerId
    */
    constructor(containerId?: number){
       this.containerId=containerId;
    }
}