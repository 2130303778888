export class MetalDetectionDto {
    metalDetectionId: number;
    date: Date;
    minGrade: number;
    maxGrade: number;
    variety: number;
    brand: number;
    sCode: string;
    operation: string;
    mmFe: boolean;
    mmNonFe: boolean;
    mmSS: boolean;
    minPouches: number;
    maxPouches: number;
    metalDetected: boolean;
    correctiveAction: string;
    plant: number;
    createdUser: string;
    updatedUser: string;
    brandName?: string;
    varietyName?: string;
}

export const MetalDetectionDefault: MetalDetectionDto = {
    metalDetectionId: 0,
    date: undefined,
    minGrade: 0,
    maxGrade: 0,
    variety: undefined,
    brand: undefined,
    sCode: undefined,
    operation: undefined,
    mmFe: true,
    mmNonFe: true,
    mmSS: true,
    minPouches: 0,
    maxPouches: 0,
    metalDetected: false,
    correctiveAction: undefined,
    plant: 0,
    createdUser: undefined,
    updatedUser: undefined,
    brandName: undefined,
    varietyName: undefined
}


