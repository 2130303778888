export class SubJobItemDto{
    soItemId:number;
    mainSoItemId:number;
    operationType:string;
    cases: number;
    constructor(soItemId:number,mainSoItemId:number, operationType:string, cases:number) {  
        this.soItemId = soItemId; 
        this.mainSoItemId = mainSoItemId;
        this.operationType = operationType; 
        this.cases = cases;
     }
}