import { JobCompletedEnum, ShiftsEnum } from "@gtpl/shared-models/common-models";
export class SoakingAssignmentDto{
    productionInventoryId: number;
    inputTime: string;
    subLotNumber: string; 
    saleOrderId: number; 
    issuingQuantity: number; 
    soakingStyle: string; 
    soakingTime: string; 
    inputGrade: number; 
    workstation: number; 
    jobStatus: JobCompletedEnum;
    assignFreezing?: JobCompletedEnum;
    isActive: boolean;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;

    constructor(
        productionInventoryId: number ,
        inputTime: string,
        issuingQuantity:number,
        soakingStyle:string,
        soakingTime: string,
        subLotNumber: string ,
        saleOrderId: number ,
        inputGrade: number ,
        workstation: number ,
        jobStatus: JobCompletedEnum,
        assignFreezing: JobCompletedEnum,
        isActive: boolean ,
        createdUser: string ,
        updatedUser: string ,
        versionFlag: number ,
    ){
        this.productionInventoryId = productionInventoryId;
        this.subLotNumber = subLotNumber;
        this.saleOrderId = saleOrderId;
        this.inputGrade = inputGrade;
        this.inputTime = inputTime;
        this.issuingQuantity = issuingQuantity;
        this.soakingStyle = soakingStyle;
        this.soakingTime = soakingTime;
        this.workstation = workstation;
        this.jobStatus = jobStatus;
        this.assignFreezing = assignFreezing;
        this.isActive = isActive;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;

    }
}