import React, { useEffect, useRef, useState } from 'react';
import {  SearchOutlined,FilePdfOutlined } from '@ant-design/icons';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import {  Divider, Table, Popconfirm, Card, Tooltip, Switch,Input,Button,Tag,Row, Col, Drawer, Form, Select, Alert } from 'antd';
import { AssetService } from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetDto } from '@gtpl/shared-models/asset-management';
import { PlantsDropDown } from '@gtpl/shared-models/masters';
import { ItemSubCategoryService, UnitcodeService } from '@gtpl/shared-services/masters';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import { AssetServiceRportReq } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto';
import { LocationByPlant } from 'libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';

const {Option} = Select;

export interface AsserServiceReportProps{
}
export function AsserServiceReport(){
    const [searchText, setSearchText] = useState(''); 
    const [searchedColumn, setSearchedColumn] = useState('');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const searchInput = useRef(null);
    const [page, setPage] = React.useState(1);
    const [assetData, setAssetData] = useState<any[]>([]);
   const [form] = Form.useForm();
const locationService = new AssetLocationService();
   const [length,setLength]= useState('');
  const [assetsData, setAssetsData] = useState<AssetDto[]>([])
  const [plantsData, setPlantsData] = useState<PlantsDropDown[]>([]);
  const [categoryData,setCategoryData] =  useState<any[]>([])
  const [locationData,setLocationData] = useState<any[]>([]);
  const unitcodeService = new UnitcodeService;
  const role = JSON.parse(localStorage.getItem('role'))
  const [pageSize, setPageSize] = useState<number>(null);

  const assetService = new AssetService();
    const service = new AssetService();
  const itemSubCategoryService = new ItemSubCategoryService();
    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };
      
        useEffect ( () =>{
      getAssetServiceReportdata();
      getAssetCode();
      getAllActivePlants();
      getAllCategories();
      getLocations();
      // getAllLocations();
      // if (Number(localStorage.getItem('unit_id')) != 5) {
      //   form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
      // }
        },[])

    const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {    setTimeout(() => searchInput.current.select());   }
        },
        render: text =>
          text ?(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) :text
          )
          : null
         
      });
      const getAssetCode = () => {
        assetService.getallAssets().then((res) => {
          if(res.status) {
            setAssetsData(res.data);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage)
          }
        })
      }
      
  const getAllActivePlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantsData(res.data);
      } else {
    
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
    });
  }
  const getLocations = () => {
    const req = new LocationByPlant();
  req.plant = form.getFieldValue('plantId')
    locationService.getAllAssets(req).then(res => {
      if(res.status){
        setLocationData(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  // const getAllCategories = () => {
  //   service.getcategorydropdown().then((res) => {
  //     if (res.status) {
  //       setCategoryData(res.data);
  //     } else {
    
  //       setCategoryData([]);
  //     }
  //   }).catch((err) => {
  //     AlertMessages.getErrorMessage(err.message);
  //     setCategoryData([]);
  //   });
  // }
  const getAllCategories = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setCategoryData(res.data)
      } else {
        setCategoryData([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setCategoryData([]);
    });
  }
 
   
    const columnsSkelton: ColumnProps<any>[] = [
        {
          title: 'S No',
          key: 'sno',
          // width: '70px',
          responsive: ['sm'],
          render: (text, object, index) => (page-1) * pageSize +(index+1)
        },
        {
          title: 'Unit ',
          dataIndex: 'plantName',
          align:'left',
          // responsive: ['lg'],
          sorter: (a, b) => a.plantName?.localeCompare(b.plantName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('plantName')
        },
        {
          title: 'Location',
          dataIndex: 'locationName',
          align:'left',
          // width:'90px',
          sorter: (a, b) => a.locationName?.localeCompare(b.locationName),
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('locationName')
        },
        {
            title: 'Asset Category',
            dataIndex: 'assetCategory',
          align:'left',
            // width:'90px',
            sorter: (a, b) => a.categoryName - b.categoryName,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('categoryName')
          },
          {
            title: 'Asset Code',
            dataIndex: 'assetCode',
            // width:'90px',
            align:'left',
            sorter: (a, b) => a.assetCode.localeCompare(b.assetCode),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetCode')
          },
          {
            title: 'Asset Name',
            dataIndex: 'assetName',
            // width:'150px',
            align:'left',
            sorter: (a, b) =>a.assetName.localeCompare(b.assetName),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetName')
          },
          {
            title: 'Asset Type',
            dataIndex: 'assetType',
            align:'left',
            sorter: (a, b) =>a.assetType?.localeCompare(b.assetType),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Asset Cost',
            dataIndex: 'assetCost',
            // width:'90px',
            align:'right',
            sorter: (a, b) => a.assetCost - b.assetCost,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetCost')
          },
          {
            title: 'Asset Owner',
            dataIndex: 'assetOwner',
            // width:'90px',
            align:'left',
            sorter: (a, b) =>  a.assetOwner.localeCompare(b.assetOwner),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('assetOwner')
          },
          // {
          //   title: 'Maintainer',
          //   dataIndex: 'maintainer',
            //width:'90px',
          //   align:'right',
          //   sorter: (a, b) => a.maintainer - b.maintainer,
          //   sortDirections: ['descend', 'ascend'],
          //   ...getColumnSearchProps('maintainer')
          // },
          {
            title: 'Purchased Date',
            dataIndex: 'purchasedDate',
            // width:'90px',
            align:'left',
            sorter: (a, b) => moment(a.purchasedDate).unix() - moment(b.purchasedDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('purchasedDate'),
            render:(rext,record) =>{
              return(
                <>
                {record.purchasedDate ? moment(record.purchasedDate).format('YYYY-MM-DD'):'-'}
                </>
              )
            }
          },
          
          {
            title: 'Expiry Date',
            dataIndex: 'expiredDate',
            // width:'90px',
            align:'left',
            sorter: (a, b) => moment(a.expiredDate).unix() - moment(b.expiredDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('expiredDate'),
            render:(text,record) => {
              return(
                <>
                {record.expiredDate? moment(record.expiredDate).format('YYYY-MM-DD'): '-'}
                </>
              )
            }
          },
          {
            title: 'Insurance Date',
            dataIndex: 'insuranceDate',
            // width:'90px',
            align:'left',

            sorter: (a, b) => moment(a.insuranceDate).unix() - moment(b.insuranceDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('insuranceDate'),
            render:(text,record) => {
              return(
                <>
                {record.insuranceDate? moment(record.insuranceDate).format('YYYY-MM-DD'): '-'}
                </>
              )
            }
          },
          {
            title: 'Warranty Date',
            dataIndex: 'warantyExpiredDate',
            // width:'90px',
            align:'left',
            sorter: (a, b) => moment(a.warantyExpiredDate).unix() - moment(b.warantyExpiredDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('warantyExpiredDate'),
            render:(text,record) => {
              return(
                <>
                {record.warantyExpiredDate? moment(record.warantyExpiredDate).format('YYYY-MM-DD'): '-'}
                </>
              )
            }
          },
          {
            title: 'Service Name',
            dataIndex: 'serviceName',
            // width:'90px',
            align:'left',
            // sorter: (a, b) => a.serviceName - b.serviceName,
            sorter: (a, b) =>  a.serviceName?.localeCompare(b.serviceName),
            
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('serviceName')
          },
          {
            title: 'Is Serviced',
            dataIndex: 'serviceStatus',
            // width:'90px',
            align:'left',
            sorter: (a, b) =>  a.serviceStatus?.localeCompare(b.serviceStatus),

            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('serviceStatus')
          },
          {
            title: 'Planned Service Date',
            // dataIndex: 'ServiceDate',
            width:'90px',
            align:'left',
            sorter: (a, b) => moment(a.actuallassetServiceDate).unix() - moment(b.actuallassetServiceDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('actuallassetServiceDate'),
            render:(text,record) => {
              return(
                <>
                {record.actuallassetServiceDate? moment(record.actuallassetServiceDate).format('YYYY-MM-DD'): '-'}
                </>
              )
            }
          },
          
          {
            title: 'Actual Service Date',
            dataIndex: 'ServiceDate',
            // width:'90px',
            align:'left',
            sorter: (a, b) => moment(a.actualserviceDate).unix() - moment(b.actualserviceDate).unix(),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('ServiceDate'),
            render:(text,record) => {
              return(
                <>
                {record.actualserviceDate? moment(record.actualserviceDate).format('YYYY-MM-DD'): moment(record.serviceDate).format('YYYY-MM-DD')}
                </>
              )
            }
          },
          
          
      ];
      const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
   
      const getAssetServiceReportdata= () => {
        const assetcode = form.getFieldValue('assetsCode')
        const location = form.getFieldValue('assetLocation')
        const assetcategory = form.getFieldValue('assetCategory');

        const plant = form.getFieldValue('plantId')
        const req= new AssetServiceRportReq();
        if (Number(localStorage.getItem('unit_id')) == 5) {
        req.plant= plant
        }else{
          req.plant= Number(localStorage.getItem('unit_id'))
        }
        req.location = location
        req.assetCategory = assetcategory;
        req.assetCode=assetcode;

        service.getAssetServiceReportdata(req).then(res => {
          if (res.status) {setAssetData(res.data);
            setLength(res.data.length)
          } else {
            if (res.intlCode) {
                setAssetData([]);
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
             AlertMessages.getErrorMessage(res.internalMessage);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setAssetData([]);
        })
      }
      const onReset = () => {
        form.resetFields();
        getAssetServiceReportdata();
        }
       
      
        const exportedData = [];
        const excelData = assetData
        let i = 1;
        const data=[
          { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
          {title : 'Unit', dataIndex:'plantName',render:(text,record) => {return record.plantName ? record.plantName : '-'}},
          {title:'Location',dataIndex:'locationName',render:(text,record) => {return record.locationName ? record.locationName : '-'}},
          {title:'Asset Category',dataIndex:'assetCategory',render:(text,record) => {return record.assetCategory ? record.assetCategory :'-'}},
          { title: 'Asset Code', dataIndex: 'assetCode',render:(text,record) => {return record.assetCode ? record.assetCode : '-'} },
          { title: 'Asset Name', dataIndex: 'assetName',render:(text,record) => {return record.assetName ? record.assetName : '-'} },
          {title:'Asset Type',dataIndex:'assetType',render:(text,record) => {return record.assetType ? record.assetType : '-'}},
          {title:'Asset Cost',dataIndex:'assetCost',render:(text,record) => {return record.assetCost ? record.assetCost : '-'}},
          {title:'Asset Owner',dataIndex:'assetOwner',render:(text,record) => {return record.assetOwner ? record.assetOwner : '-'}},
          {title:'Purchased Date',dataIndex:'purchasedDate',render:(text,record) => {return record.purchasedDate ? moment(record.purchasedDate).format('YYYY-MM-DD') : '-'}},
          {title:'Expiry Date',dataIndex:'warantyExpiredDate',render:(text,record) => {return record.warantyExpiredDate ? moment(record.warantyExpiredDate).format('YYYY-MM-DD') : '-'}},
          {title:'Insurance Date',dataIndex:'insuranceDate',render:(text,record) => {return record.insuranceDate ? moment(record.insuranceDate).format('YYYY-MM-DD') : '-'}},
          {title:'Warranty Date',dataIndex:'warantyExpiredDate',render:(text,record) => {return record.warantyExpiredDate ? moment(record.warantyExpiredDate).format('YYYY-MM-DD') : '-'}},
          {title:'Service Name',dataIndex:'serviceName',render:(text,record) => {return record.serviceName ? record.serviceName : '-'}},
          {title:'Is Serviced',dataIndex:'serviceStatus',render:(text,record) => {return record.serviceStatus ? record.serviceStatus : '-'}},
          {title:'Planned Service Date',dataIndex:'actuallassetServiceDate',render:(text,record) => {return record.actuallassetServiceDate ? moment(record.actuallassetServiceDate).format('YYYY-MM-DD') : '-'}},
          {title:'Actual Service Date',dataIndex:'actualserviceDate',render:(text,record) => {return record.actualserviceDate ? moment(record.actualserviceDate).format('YYYY-MM-DD') : '-'}},
          ];
          const exportExcel = () => {
            const excel = new Excel();
            excel
              .addSheet('Asset Service Report')
              .addColumns(data)
              .addDataSource(assetData, { str2num: true })
              .saveAs('Asset Service Report.xlsx');
          }
return (
<>
<Card title={<span style={{color:'white'}}>Asset Service Report</span>}
      style={{textAlign:'center'}} headStyle={{backgroundColor: '#69c0ff', border: 0 }}
      extra={assetData.length > 0 ? (
        <>
          <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
        </>
      ) : (<></>)}
       >
         <br/><br/>
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={[24,24]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.itemName} -- ${assets.assetsCode}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }} hidden={Number(localStorage.getItem('unit_id')) != 5}>
              <Form.Item name="plantId" label="Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder=" All Units"
                  // onChange={handleUnit}
                  allowClear
                  // disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                  // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
                >
                  <Option key={0} value={null}>All Units</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Locations'
            showSearch
            allowClear>
            <Option key={'all'} value={''}>All Locations</Option>
            {locationData.map((location) => {
              return <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetCategory" label='Asset Category'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='All Categories'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option key={'all'} value={''}>All Categories</Option>
            {categoryData.map((assets) => {
              return <Option key={assets.itemSubCategoryId} value={assets.itemSubCategoryId}>{`${assets.itemSubCategory}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col style={{ padding: '20px', marginTop: '30px' }}>
            <Button type="primary" style={{ marginRight: '4px' }} 
            onClick={() => getAssetServiceReportdata()}
            >
              Get Report
            </Button>
            <Button style={{ marginLeft: '5px' }} type="primary" htmlType="submit" onClick={onReset}> Reset </Button>
          </Col>
          <Col span={3} style={{marginLeft:'85%',paddingBottom:'1px'}}>
            <Button >Total no of Records:{length}
            </Button>
            </Col>
        </Row>
        
        </Form>
        {assetData.length > 0?(<><Table
            rowKey={record => record.downtimereasonId}
            columns={columnsSkelton}
            dataSource={assetData}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize)
              }
            }}
            scroll = {{x:1600,y:500}}
            onChange={onChange}
            size='small'
            bordered />
        </>):(
          <Alert
          message="No Data Found"
          type="info"
          showIcon
        />
        )}
         
        </Card>
</>
)

}

export default AsserServiceReport;