import { Card, Col, Row } from "antd";
import './down-time-dashboard.css'

import React, { } from "react";
//import 'libs/pages/production-management/production-management-components/down-time-dashboard/src/lib/das'
import { CardBodyDetails, CardDetail, CardHeaderBody } from "@gtpl/shared-models/common-models";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

export interface cardProps {
    cardData: CardDetail
}

export const DowntimeCardComponet = (props: cardProps) => {
    const { cardData } = props;

    const constructLocationDetail = (bodyDetail: CardBodyDetails[]) => {
        console.log(bodyDetail);
        return bodyDetail.map(bodyProps =>
            <tr style={{ borderBottom: '1px solid #f0f8ff', lineHeight: ' 1.8em' }}>
                <td >{bodyProps.cardBodyTitle}</td>
                <td >{bodyProps.cardBodyParam1}</td>
                <td >{bodyProps.cardBodyParam2}</td>
                <td >{bodyProps.cardBodyParam3}</td>
                <td >{bodyProps.cardBodyParam4}</td>
            </tr>
        )
    }

    const constructTableHeader = (headers: string[]) => {
        return headers.map(head => <th style={{ borderBottom: '1px solid #ccc', lineHeight: ' 1.8em', marginBottom: '10px' }}>{head}</th>)
    }
    return (
        <>
            <Card className="downtime-card" title={<span style={{marginBottom: '10px'}}className="title">{cardData.carHeader.cardHeaderTitle}</span>} bordered={false} style={{ color: 'black', height:'100%' }}>
                <Row style={{ width: '100%', height: '20px' }}>
                    <Col span={10}>
                        {cardData.carHeader.cardHederData[0].title}
                    </Col>
                    <Col span={10} offset={2}>
                        {cardData.carHeader.cardHederData[1].title}
                    </Col>
                </Row>
                <Row>
                    <Col span={10} style={{fontSize:'30px'}}>
                        {cardData.carHeader.cardHederData[0].value}
                    </Col>
                    <Col span={10} offset={2} style={{fontSize:'30px'}}>
                        {cardData.carHeader.cardHederData[1].value}
                    </Col>
                </Row>
                <table cellPadding="0" style={{ borderCollapse: 'collapse', width: '90%', margin: '1.5em', fontSize: '0.85em', border: '0',overflowX:'auto' }}>
                        {constructTableHeader(cardData.tableHeaders)}
                    <tbody>
                        {constructLocationDetail(cardData.cardBody)}
                    </tbody>
                </table>
            </Card>
        </>
    );
}
export default DowntimeCardComponet;