import React, { useEffect, useRef, useState } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { DownTimeTrackingDto } from '@gtpl/shared-models/masters';
import { DownTimeTrackingService } from '@gtpl/shared-services/masters';
import './down-time-dashboard.css';

export function DownTimeStatusWiseDataCard() {



    const downtimetrackingService = new DownTimeTrackingService();
    const [downTimeStatusWiseData, setDownTimeStatusWiseData] = useState<any[]>([]);


    useEffect(() => { gettrackingDepartmentData(); }, [])

    const gettrackingDepartmentData = () => {

        downtimetrackingService.getTotalDownTimeType().then(res => {
            if (res.status) {
                setDownTimeStatusWiseData(res.data);
            } else {
                if (res.intlCode) {
                    setDownTimeStatusWiseData([]);
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setDownTimeStatusWiseData([]);
        })
    }


    return (
        <Card title={<span style={{ marginLeft: '45%', color: 'black' }}> Total Down Time</span>} color='black'>
            <table>
        

                                <tr className='ta-b'>
                                  
                                    <td className='ta-b'><b>Status</b></td>
                                   {downTimeStatusWiseData.map(item => {
                                    return(
                                    <td className='ta-b'>
                                    <b>{item.downTimeType}</b>
                                </td>)
                                   }) }
                                    {/* <td>
                                        <b>Un planned</b>
                                    </td> */}
                                </tr>
                                {
                            downTimeStatusWiseData.map(item => {
                              return( <tr className='ta-b'>
                                    <td className='ta-b'>
                                       <b>{item.status}</b>
                                    </td>
                                    <td className='ta-b'>
                                       {item.totalCount}
                                    </td>
                                    
                                </tr>
                              )
                            })
                                
                                
}
                        

            </table>

        </Card>

    );

}
export default DownTimeStatusWiseDataCard;
