import { PlantsEnum } from "@gtpl/shared-models/common-models";

export class GrnData {
    grnId?: number;
    grnDate: any;
    indentId: number;
    indentNumber?: string;
    grnNumber?: string;
    lotNumber?: string;
    saleOrderId?: number;
    saleOrderItemId?: number;
    supplierType: string;
    farmer: number;
    farmerName?: string;
    farmerCode: number;
    farmCode?: string;
    quantity: number;
    count: number;
    price: number;
    softPercentage?: number;
    boxes?: number;
    plantId?: number;
    subPlantId?: number;
    createdUser: string;
    updatedUser?: string;
    assignToRm?: boolean;
    receivedBoxes?: number;
    subPlantName?: string;
    isActive: boolean;
    isVInspectionCompleted?: boolean;
    isRmInspectionCompleted?: boolean;
    isAntibioticsTestCompleted?: boolean;
    grnItemId?: number;
    count25?: number;
    count30?: number;
    count40?: number;
    count50?: number;
    count60?: number;
    count70?: number;
    count80?: number;
    count90?: number;
    count100?: number;
    count110?: number;
    count120?: number;
    count130?: number;
    looseShell?: string;
    fungusPercentage?: number;
    remarks?:string;


/**
 * 
 * @param grnDate 
 * @param indentId 
 * @param supplierType 
 * @param farmer 
 * @param farmerCode 
 * @param quantity 
 * @param count 
 * @param price 
 * @param createdUser 
 * @param isActive 
 * @param grnNumber 
 * @param grnId 
 * @param lotNumber 
 * @param saleOrderId 
 * @param saleOrderItemId 
 * @param farmerName 
 * @param indentNumber 
 * @param farmCode 
 * @param boxes 
 * @param plantId 
 * @param subPlantId 
 * @param updatedUser 
 * @param assignToRm 
 * @param softPercentage 
 * @param receivedBoxes 
 * @param subPlantName 
 * @param isVInspectionCompleted 
 * @param isRmInspectionCompleted 
 * @param isAntibioticsTestCompleted 
 * @param count25 
 * @param count30 
 * @param count40 
 * @param count50 
 * @param count60 
 * @param count70 
 * @param count80 
 * @param count90 
 * @param count100 
 * @param count110 
 * @param count120 
 * @param count130 
 * @param looseShell 
 * @param fungusPercentage 
 * @param remarks 
 */
    constructor(grnDate: any, indentId: number, supplierType: string, farmer: number, farmerCode: number, quantity: number, count: number, price: number, createdUser: string, isActive: boolean, grnNumber?: string, grnId?: number, lotNumber?: string, saleOrderId?: number, saleOrderItemId?: number, farmerName?: string, indentNumber?: string, farmCode?: string, boxes?: number, plantId?: number,
        subPlantId?: number, updatedUser?: string, assignToRm?: boolean, softPercentage?: number, receivedBoxes?: number, subPlantName?: string, isVInspectionCompleted?: boolean, isRmInspectionCompleted?: boolean, isAntibioticsTestCompleted?: boolean, count25?: number,
        count30?: number,
        count40?: number,
        count50?: number,
        count60?: number,
        count70?: number,
        count80?: number,
        count90?: number,
        count100?: number,
        count110?: number,
        count120?: number,
        count130?: number, 
        looseShell?: string, 
        fungusPercentage?: number,
        remarks?:string,) {
        this.grnId = grnId;
        this.grnDate = grnDate;
        this.indentId = indentId;
        this.grnNumber = grnNumber;
        this.lotNumber = lotNumber;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        this.supplierType = supplierType;
        this.farmer = farmer;
        this.farmerCode = farmerCode;
        this.farmCode = farmCode;
        this.quantity = quantity;
        this.count = count;
        this.price = price;
        this.boxes = boxes;
        this.plantId = plantId;
        this.subPlantId = subPlantId;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
        this.isActive = isActive;
        this.farmerName = farmerName;
        this.indentNumber = indentNumber;
        this.assignToRm = assignToRm;
        this.softPercentage = softPercentage;
        this.receivedBoxes = receivedBoxes;
        this.subPlantName = subPlantName;
        this.isVInspectionCompleted = isVInspectionCompleted;
        this.isRmInspectionCompleted = isRmInspectionCompleted;
        this.isAntibioticsTestCompleted = isAntibioticsTestCompleted;
        this.count25 = count25;
        this.count30 = count30;
        this.count40 = count40;
        this.count50 = count50;
        this.count60 = count60;
        this.count70 = count70;
        this.count80 = count80;
        this.count90 = count90;
        this.count100 = count100;
        this.count110 = count110;
        this.count120 = count120;
        this.count130 = count130;
        this.looseShell = looseShell;
        this.fungusPercentage = fungusPercentage;
        this.remarks = remarks;
    }
}

