import { RmEvaluationSamplesDto } from ".";
export class RmEvaluationDto{
    rmEvaluationId?: number;    
    grnId: number;
    vehicleId: number;
    vehicleNumber?: string;
    arrivalTime: Date;
    startTimeOfObv: Date;
    freqencyOfInsp: number;
    batchNumber: string;
    sourceCode: string;
    center: string;
    totalQuantity: number;
    noOfSamplesDrawn: number;    
    remarks: string;
    RmEvalSamplesInfo: RmEvaluationSamplesDto[];
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;

    constructor(
    rmEvaluationId: number,    
    grnId: number,
    vehicleId: number,
    vehicleNumber: string,
    arrivalTime: Date,
    startTimeOfObv: Date,
    freqencyOfInsp: number,
    batchNumber: string,
    sourceCode: string,
    center: string,
    totalQuantity: number,
    noOfSamplesDrawn: number,
    remarks: string,
    RmEvalSamplesInfo: RmEvaluationSamplesDto[],
    createdAt: Date,
    createdUser: string | null,
    updatedAt: Date,
    updatedUser: string | null,
    versionFlag: number,
    ){
      this.rmEvaluationId = rmEvaluationId;
      this.grnId = grnId;
      this.vehicleId = vehicleId;
      this.vehicleNumber = vehicleNumber;
      this.arrivalTime = arrivalTime;
      this.startTimeOfObv = startTimeOfObv;
      this.freqencyOfInsp = freqencyOfInsp;
      this.batchNumber = batchNumber;
      this.sourceCode = sourceCode;
      this.center = center;
      this.totalQuantity = totalQuantity;
      this.noOfSamplesDrawn = noOfSamplesDrawn;
      this.remarks = remarks;
      this.RmEvalSamplesInfo = RmEvalSamplesInfo;
      this.createdAt = createdAt;
      this.createdUser = createdUser;
      this.updatedAt = updatedAt;
      this.updatedUser = updatedUser;
      this.versionFlag = versionFlag;
    }
}