export class AssetDownTimeDetails {
   assetCode: string;
   downTimeReason: string;
   downTimeStartedAt: string;

   constructor(assetCode: string,downTimeReason: string,downTimeStartedAt: string){
      this.assetCode = assetCode;
      this.downTimeReason = downTimeReason;
      this.downTimeStartedAt = downTimeStartedAt;

   }
}