
import { StockService } from '@gtpl/shared-services/procurement';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Button, Card, Col, DatePicker, Form, Row, Select, Input} from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import {CheckCircleOutlined,CloseCircleOutlined,RightSquareOutlined,EyeOutlined,EditOutlined,SearchOutlined } from '@ant-design/icons';
import { ReClassificationReq } from '@gtpl/shared-models/procurement-management';
import { PoStockReq } from '@gtpl/shared-models/procurement-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import Highlighter from 'react-highlight-words';
import moment from 'moment';


export interface ReClassificationReportProps { }

export function ReClassificationReport() {
    const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
    const [reportData, setReportData] = useState<any[]>([]);
    const [form] = Form.useForm();
  const searchInput = useRef(null);
    const service = new StockService();
  const { RangePicker } = DatePicker;
  const [selectedEstimatedFromDate, setSelectedEstimatedFromDate] = useState(undefined);
  const [selectedEstimatedToDate, setSelectedEstimatedToDate] = useState(undefined);
    const { Option } = Select;
const fgservice = new FGStockService();
const [selectedunit, setUnit] = useState<number>(0)
const [unitData,setunitData] = useState<any[]>([]);
    const [disable, setDisable] = useState<boolean>(false);

    useEffect(() => {
        // getBeheadingInventory();
        getReClassificationLogReport();
        // getAllLotNumbers();
    //     if (Number(localStorage.getItem('unit_id')) != 5) {
    //       form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    //   }
        
      }, []);

      const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
           .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {    setTimeout(() => searchInput.current.select());   }
        },
        render: text =>
          text ?(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) :text
          )
          : null
         
      });
      function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
      };

    const EstimatedETDDate = (value) => {
        if (value) {
          console.log(value)
          const fromDate = new Date(value[0].format('yyyy-MM-DD'));
          const toDate = new Date(value[1].format('yyyy-MM-DD'));
          setSelectedEstimatedFromDate(fromDate)
          setSelectedEstimatedToDate(toDate)
          console.log(fromDate)
        }
      }

      const getReClassificationLogReport = (onReset?:boolean)=>{
        // const plant = Number(localStorage.getItem('unit_id'))
        let fromDate = onReset ? undefined : selectedEstimatedFromDate;
        let toDate = onReset ? undefined : selectedEstimatedToDate;
        const req = new ReClassificationReq()
        req.fromDate = fromDate;
        req.toDate = toDate;
        service.getClassificationReportData(req).then((res)=>{
          if(res.status){
            setReportData(res.data);
          }else{
            setReportData([]);
          }
        }).catch((err)=>{
          AlertMessages.getErrorMessage(err.message);
          setReportData([]);
        })
      }

      const onReset = () => {
        form.resetFields();
        setSelectedEstimatedFromDate(undefined);
        setSelectedEstimatedToDate(undefined);
        //   setReportData([]);
        }

    const Columns :ColumnProps<any>[]=[
        {
            title: 'Sno',
            key: 'sno',
            width: '30px',
            responsive: ['sm'],
            render: (text, object, index) => (page - 1) * 10 + (index + 1)
        },
       
        {
            title:'Conversion Type',
            dataIndex: 'conversionType',
            filters: [
                // {
                //   text: 'Bulk to Bulk',
                //   value:'Bulk to Bulk',
                // },
                // {
                //   text: 'Bulk to Saleorder',
                //   value:'Bulk to Saleorder',
                // },
                {
                  text: 'Salerorder to Saleorder',
                  value:'Salerorder to Saleorder',
                },
                {
                  text: 'Saleorder to Bulk',
                  value:'Saleorder to Bulk',
                },
              ],
              filterMultiple: false,
              onFilter: (value, record) => 
              {
                // === is not work
                return record.conversionType === value;
              },
        },
        {
            title:'From PO Number',
            dataIndex: 'fromPoNumber',
        ...getColumnSearchProps('fromPoNumber')

        },
        {
            title:'Item',
            dataIndex: 'item',
        ...getColumnSearchProps('item')
            
        },
        {
            title:'To PO Number',
            dataIndex: 'toPoNumber',
        ...getColumnSearchProps('toPoNumber'),
        render : (value,record)=>{
              return <span>
                {record.toPoNumber?record.toPoNumber:'Bulk'}
              </span>
        }
            
        },
        
        {
            title:'Conversion Quantity',
            dataIndex: 'conversionQuantity',
            align:'center',
        ...getColumnSearchProps('conversionQuantity')

        },
        {
            title:'Reason',
            dataIndex: 'reason',
        ...getColumnSearchProps('reason')

        },
        {
            title:'Converted Date',
            dataIndex: 'convertedDate',
            render: (value, record) => {
                return <span>
                  {record.convertedDate ? moment(record.convertedDate).format('YYYY-MM-DD') : '-'}
                </span>
              }
        },

    ]

    const handleUnit = (value)=>{
        setUnit(value);
     }
     const getUnitDropdownForStockInReport = () => {
        fgservice.getUnitDropdownForStockInReport().then((res) => {
            if (res.status) {
              setunitData(res.data);
            } else {
              setunitData(res.data);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Re Classification Report</span>}
                style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}></Card>
            <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
                <Row gutter={[24, 24]}>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="unitId"
                            label=" Unit"
                            rules={[
                                {
                                    required: false, message: 'Select Unit',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Unit"
                                allowClear
                                style={{ width: '100%' }}
                                onChange={handleUnit}
                                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

                            >
                                {unitData.map(dropData => {
                                    return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col> */}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="fromDate"
              label="Date Range"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "select date range"
                },
              ]}>
              <RangePicker onChange={EstimatedETDDate} />
            </Form.Item>
          </Col>
                     <Col style={{ marginTop: 30 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Button
                            type="primary"
                            block disabled={disable} style={{ marginRight: 2, width: 100 }}>
                            Get Report
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={onReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table
                rowKey={record => record.saleOrderId}
                columns={Columns}
                dataSource={reportData}
                scroll={{ x: true }}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
                size='small'
                bordered />
        </>
    )
}
export default ReClassificationReport;