import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeDropDown, LotInformationRequest, LotNoInfo, LotNumberRequest, WorkStationCategoryReq, WorkStationsDropDown } from '@gtpl/shared-models/production-management';
import { SoakingAssignmentService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { OperationTypeEnum, ShiftsEnum, WorkStationCategoryEnum } from '@gtpl/shared-models/common-models';
import { FreezingInventoryService, SoakingInventoryService } from '@gtpl/shared-services/production';
const { Option } = Select;

import './assign-for-freezing-form.css';
import moment from 'moment';

/* eslint-disable-next-line */
export interface AssignForFreezingFormProps {}

export function AssignForFreezingForm(props: AssignForFreezingFormProps) {
  let location = useLocation();
  const lotData : any =location.state 
  console.log(lotData)
  console.log(lotData?.rowData)
  console.log(lotData?.rowData?.sub_lot_number)

  const [assignFreezingForm] = Form.useForm();
  const [lotNumbers, setLotNumbers] = useState<any>([]);
  const service = new ProductionInventoryService();
  const freezingService = new FreezingInventoryService();
  const [lotNoInfo, setLotNoInfo] = useState<LotNoInfo>(undefined);
  const [boxWeight, setBoxWeight] = useState<number>(0);
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const workstationService = new WorkstationService();
  let history =  useHistory();

  useEffect(() => {
    getLotNumbers();
    getWorkStations();
    
    // assignFreezingForm.setFieldsValue({assignedTime:Date.now()})
  }, [])

  useEffect(() => {
    if(lotData){
      assignFreezingForm.setFieldsValue({subLotNumber:lotData?.rowData?.sub_lot_number})
      console.log(lotData.sub_lot_number)
      getLotNumberInfo(lotData?.rowData?.sub_lot_number);
    }
  }, [lotData])

  const getLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.FREEZING);
    
    service.getLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }
  // let workstation = 0;
  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory =  WorkStationCategoryEnum.find((res) => res.name === "FREEZING").value;
    catReq.unitId = Number(localStorage.getItem('unit_id'));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        console.log(res.data);
        setWorkStations(res.data.filter(res => res.workstation==="Freezing"));
        // workstation = (res.data[0].workstationId)
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }

  const getLotNumberInfo = (lotNumber) => {
    const plant = Number(localStorage.getItem('unit_id'))
 
    const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.FREEZING)
    service.getLotNumberInfo(lotNoReq).then((res) => {
      if (res.status) {
        
        setLotNoInfo(res.data)
        let boxWeight = (res.data.availableQuantity)/(res.data.boxes);
        setBoxWeight(boxWeight);
        assignFreezingForm.setFieldsValue({
          productionInventoryId:res.data.productionInventoryId,
          saleOrderId:res.data.saleOrderId,
          lotNumber:res.data.lot,
          saleOrderItemId:res.data.saleOrderItemId,
          saleOrder:res.data.poNumber,
          count:res.data.count,
          boxes:res.data.boxes,
          availableQuantity:res.data.availableQuantity,
          physicalQuantity: Number(Number(res.data.availableQuantity).toFixed(3))
        })
      } else {
        setLotNoInfo(undefined);
      }
    }).catch((err) => {
      setLotNoInfo(undefined);
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const onFinish = () => {
    const formInfo = assignFreezingForm.getFieldsValue();
    formInfo.createdUser = localStorage.getItem('createdUser');
    formInfo.plantId = Number(localStorage.getItem('unit_id'));
    console.log(workStations.filter(rec => rec.workstation === "Freezing"));
    formInfo.workstation = 19
    console.log("my data is:",formInfo);
    freezingService.assignSoakingForFreezing(formInfo).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        onReset();
        history.push("/assign-soaking-for-freezing")
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
    });
  }
  const onReset=()=>{
    assignFreezingForm.resetFields();
  }
  const calculateIssuingQty = () =>{
    const noOfBoxes = assignFreezingForm.getFieldValue('boxes');
    const availableQuantity = assignFreezingForm.getFieldValue('availableQuantity');
    // const boxWeight = availableQuantity/noOfBoxes;
    const physicalQuantity = boxWeight*noOfBoxes;
    if(physicalQuantity<=availableQuantity){
      assignFreezingForm.setFieldsValue({physicalQuantity:physicalQuantity.toFixed(3)});
    }else{
      assignFreezingForm.setFieldsValue({physicalQuantity:0, boxes:0});
    }
  }

  return (
    <>
    <Card title={<span style={{ color: 'white' }}>Assign For Freezing</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/assign-soaking-for-freezing' ><Button className='panel_button' >View</Button></Link>}>
      <Form layout={'vertical'} form={assignFreezingForm} name="control-hooks">

        <Row gutter={24}>
          <Form.Item name="productionInventoryId" hidden>
            <Input hidden />
          </Form.Item>
          
          <Form.Item name="saleOrderItemId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="lotNumber" >
            <Input hidden />
          </Form.Item>
          {/* <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]}   >
            <Input />
            </Form.Item> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item name="subLotNumber" label="Lot No" rules={[{ required: true, message: 'Missing Lot No' }]}>
              <Select
                placeholder="Select Lot No"
                allowClear
                onChange={getLotNumberInfo}
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.lotNumber} value={dropData.subLotNumber}>{dropData.sublotandplantlot}</Option>
                })}
              </Select>
            </Form.Item>
            </Col>
            <Form.Item name="saleOrderId">
              <Input hidden/>
            </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrder" label="Sale Order No" rules={[{ required: true, message: 'Missing Sale Order No' }]}>
              <Input disabled/>
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="count" label="Count" rules={[{ required: true, message: 'Missing Count' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="availableQuantity" label="Quantity Available" rules={[{ required: true, message: 'Missing Quantity Available' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

            <Form.Item name="boxes" label="No. Of. Boxes" rules={[{ required: true, message: 'Missing No. Of. Boxes' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={calculateIssuingQty} />
            </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="physicalQuantity" label="Issuing Quantity" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} disabled />
            </Form.Item>

            </Col>
            <Col span={6}>
          <Form.Item name="shift" label="Shift"
          >
            <Select
              showSearch
              placeholder="Select Shift"
              allowClear = {true}
            >
              <Option value={null}>Select Shift</Option>
              {Object.keys(ShiftsEnum).map((type)=>{
                  return <Option value={ShiftsEnum[type]}>{ShiftsEnum[type]}</Option>
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, }} lg={{ span: 5, }} xl={{ span: 5, }} hidden>
            <Form.Item name="assignedTime" label="Input Time" rules={[{ required: true }]} initialValue={moment()}>
              <DatePicker showTime={{format: "HH:mm"}} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                  showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')}/>
            </Form.Item>
          </Col>
         </Row>
         <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
          
            <Button type="primary" onClick={onFinish} htmlType="submit" >
              Submit
            </Button>
            <Button htmlType="button" style={{ margin: '0 14px' }} onClick={onReset}>
                Reset
            </Button>
        </Col>

        </Row>
      </Form>
    </Card>
    </>
  );
}

export default AssignForFreezingForm;
