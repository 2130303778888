import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { BomInfo } from './bom-info.dto';

export class BomDetailsCreationResponseModel extends GlobalResponseObject {
    data?: BomInfo[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /BomInfo
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: BomInfo[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 