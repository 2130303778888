export class BlanchingCookingDto{
    cookingId : number;
    logId : number;
    time : Date;
    blanchingCooking : string;
    temp1 : number;
    temp2 : number;
    temp3 : number;
    cookingTime : Date;
    productCoreTemp : number;
    productTempAfterChilling : number;
    steamPressure : string;
    chillWaterTemp : number;
    chillerTime: Date;
    remarks: string;
    correctiveAction : string;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;

    constructor(
    cookingId : number,
    logId : number,
    time : Date,
    blanchingCooking : string,
    temp1 : number,
    temp2 : number,
    temp3 : number,
    cookingTime : Date,
    productCoreTemp : number,
    productTempAfterChilling : number,
    steamPressure : string,
    chillWaterTemp : number,
    chillerTime: Date,
    remarks: string,
    correctiveAction : string,
    createdAt: Date,
    createdUser: string | null,
    updatedAt: Date,
    updatedUser: string | null,
    versionFlag: number,
    ){
        this.cookingId = cookingId;
        this.logId = logId;
        this.time = time;
        this.blanchingCooking = blanchingCooking;
        this.temp1 =temp1;
        this.temp2 = temp2;
        this.temp3 = temp3;
       
        this.cookingTime = cookingTime;
        this.productCoreTemp = productCoreTemp;
        this.productTempAfterChilling = productTempAfterChilling;
        this.steamPressure = steamPressure;
        this.chillWaterTemp = chillWaterTemp;
        this.chillerTime = chillerTime;
        this.remarks = remarks;
        this.correctiveAction = correctiveAction;
        this.createdAt =createdAt;
        this.createdUser= createdUser;
        this.updatedAt= updatedAt;
        this.updatedUser= updatedUser;
        this.versionFlag= versionFlag;
    }

}