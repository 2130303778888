export class AttendanceMonthRequest {
    month: number;
    departmentId?: number;
    unitId?: number;
    shift?: string;


    /**
     * 
     * @param month 
     * @param departmentId 
     * @param unitId 
     * @param shift 
     */
    constructor(month: number, departmentId?: number, unitId?: number, shift?: string) {
        this.month = month;
        this.departmentId = departmentId;
        this.unitId = unitId;
        this.shift = shift;
    }
}