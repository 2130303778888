import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, FormInstance, Popconfirm, Table, Tooltip, Divider } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from "react-router-dom";
import { UnitcodeService } from '@gtpl/shared-services/masters'
import { PlantsDropDown, UnitcodeDto, UnitTypeEnum } from '@gtpl/shared-models/masters'
import {  saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SoTranferDto } from '@gtpl/shared-models/sale-management';
import { FGStockService } from '@gtpl/shared-services/warehouse-management';
import { ColumnsType } from 'antd/lib/table';
import { ReprocessingInputRequest } from '@gtpl/shared-models/warehouse-management';
import moment from 'moment';
const { Option } = Select;

export interface AssignStockProps {
    tableData: any[];
    availableQty:number;
    closeForm: () => void;
}
export function AssignStock(props: AssignStockProps) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [maxPalletCapacity, setMaxPalletCapacity] = useState<number>(0);
    const [filledCapacity, setFilledCapacity] = useState<number>(0);
    const [stockData, setStockData] = useState<any[]>([]);
    const [saleOrderId, setSaleOrderId] = useState<number>(0);

    const fGStockService = new FGStockService();
    const [page, setPage] = React.useState(1);

    const AssignQty = (data)=>{
        console.log(data);
        fGStockService.AssignReprocessQty(new ReprocessingInputRequest(data.saleOrderId,data.stockIds,data.saleOrderItemId,
          data.availableQty,'rePack',data.minGrade,data.maxGrade,data.hlPer,data.finalPercentage,data.uomId,data.shrimpWeight,data.pouches,data.cases,Number(localStorage.getItem('unit_id')))).then((res)=>{
            if (res.status) {
                // setFgData([]);
              //   getRepackingStock();
                AlertMessages.getSuccessMessage(res.internalMessage);
                props.closeForm();
                //history.push('/reprocess-repacking')
            } else {
              if (res.intlCode) {
                // AlertMessages.getErrorMessage(res.internalMessage);
              } else {
                // AlertMessages.getErrorMessage(res.internalMessage);
              }
              // setFgData([]);
            }
          }).catch((error)=>{
            AlertMessages.getErrorMessage(error.message);
            // setFgData([]);
          })
          // saleService.updateReProcessQty(record).then((res)=>{
          //   getReProcessingRequiredQuantity();
          //   console.log(res);
          // })
          // const req = new SoProductionDTO();
          // req.soProductionId =0;
          // req.type =ProductionTypeEnum.RE_PACKING;
          // req.lotNumber =record.lotNumber;
          // req.saleOrderId =record.assignedSoId;
          // req.saleOrderItemId =record.assignedSoItemId;
          // req.inputQuantity =record.assigningQty;
          // req.outputQuantity =record.assigningQty;
          // req.createdUser =localStorage.getItem('createdUser');
          // req.updatedUser =localStorage.getItem('createdUser');
          // saleService.createSoProduction(req).then((res)=>{
          //   getReProcessingRequiredQuantity();
          //   console.log(res);
          // })
    }
  
    const sampleTypeColumns: ColumnsType<any> = [
        {
            title: '#',
            dataIndex: 'sno',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, object, index) => (page-1) * 10 +(index+1)
        },
        {
            title: 'PO Number',
            dataIndex: 'poNumber',
        },
        {
          title: 'ETD',
          dataIndex: 'etd',
          render: (value, rowData, index) => {
            return value ? moment(value).format("YYYY-MM-DD"): ""
          },
        },
        {
          title: 'Category',
          dataIndex: 'category',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
        },
        {
          title: 'Pack Style',
          dataIndex: 'packStyle',
      },
        {
            title: 'Required Cartons',
            dataIndex: 'jobCases',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },
        },
        {
            title: 'Required Qty',
            dataIndex: 'requiredQty',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },
        },
        {
            title: 'Production Qty',
            dataIndex: 'productionQty',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },  
        },
        {
            title: 'Allocated Qty',
            dataIndex: 'allocatedQty',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },
        },
        {
            title: 'Balance Required',
            dataIndex: 'balanceReq',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },
        },
        {
            title: 'Available Qty(Assignable)',
            dataIndex: 'availableQty',
            render: (value, rowData, index) => {
                return <span style={{float:'right'}}>{value!= null ? Number(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) : "0.00"}</span>;
              },
        },
        {
            title: 'Action',
            dataIndex:'action',
            fixed: 'right',
            render: (text, rowData) => (
            //   rowData.isSOExist != null?
                <span>
                  <Tooltip placement="top" title='Allocate'>          
                    <Button
                      onClick={() => {AssignQty(rowData)}}
                      style={{ color: '#1890ff', fontSize: '14px' }}
                    >Allocate</Button>
                  </Tooltip>
                  <Divider type="vertical" />
                </span>
            // :"-"
            )
        }
    ]
    const onChange=(pagination, filters, sorter, extra)=> {
        console.log('params', pagination, filters, sorter, extra);
      }
      function onBlur() {
        // console.log('blur');
      }
    
      function onFocus() {
        // console.log('focus');
      }
    
      function onSearch(val) {
        // console.log('search:', val);
      }
      const handleSaleOrder = (value) => {
        setSaleOrderId(Number(value));
        // setReprocessingSaleOrdersData(props.tableData?.find((res) => res.saleOrderId === value));
      }
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Allocate Repacking </span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
            <Row gutter={24} >
            <Col span={14}>
                <Form.Item
                  name="saleOrderId"
                  label="Customer PO"
                  rules={[
                    {
                      required: true, message: 'Missing Sale Order',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Customer Po"
                    onChange={handleSaleOrder}
                    allowClear
                    showSearch
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                    [...new Map(props?.tableData?.map(item =>
                      [item['saleOrderId'], item])).values()]?.map(res => {
                      return <Option key={res.saleOrderId} value={res.saleOrderId}>{res.poNumber +" / "+res.productSku + " / "+ res.brand}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={24} >
                <Col span={5}>
                    <Card title={'Available: ' + Number(props.availableQty).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 3}) + ' LB'} style={{textAlign: 'left', width: 250, height: 41,backgroundColor:'white'}}></Card>
                </Col>
                <Col span={10}>
                    <Card title={'Product SKU: ' + props.tableData[0]?.productSku != undefined ? props.tableData[0]?.productSku : ""}></Card>
                </Col>
            </Row>
            <Row gutter={24} >
                <Col span={24} >
                {
                    saleOrderId > 0 ?
            <Table
                rowKey={record => record.stockId}
                columns={sampleTypeColumns}
                dataSource={props.tableData.filter((rec) => rec.saleOrderId === saleOrderId)}
                pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                scroll={{ x: 500 }}
                size="small"
                bordered
              >
            </Table> :"" }
              </Col>
            </Row>
            </Card>
        </>
    );
}
export default AssignStock;
