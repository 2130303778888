export class NotifypartyRequest{
    notifyPartyId : number;
    isActive ?: boolean;
    
    constructor(notifyPartyId : number,isActive? : boolean)
    {
        this.notifyPartyId = notifyPartyId;
        this.isActive = isActive;
        
    }
}
