import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class ExpectedStockInOutResponse extends GlobalResponseObject {
    expectedStockInCnt?: any;
    expectedLocations?: any;
    expectedStockOutCnt?: any;
    expectedavailableLocations?: any;


    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     * @param expectedLocations 
     * @param expectedStockOutCnt 
     * @param expectedavailableLocations 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, expectedStockInCnt?: any, expectedLocations?: any, expectedStockOutCnt?: any, expectedavailableLocations?: any){
        super(status,errorCode,internalMessage);
        this.expectedStockInCnt = expectedStockInCnt;
        this.expectedLocations = expectedLocations;
        this.expectedStockOutCnt = expectedStockOutCnt;
        this.expectedavailableLocations = expectedavailableLocations;

    }
}