import React, { useState, useEffect, useRef } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Form, DatePicker, Modal, Space, Badge } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import './purchase-order-grid.css';
import { Link, Redirect } from 'react-router-dom';
import { PoItemsDataModel, PoDataModel, PoDto, PODetailViewInfoDTO, PoDetailsDto, AllPoDetailsInfo, PoFilterDto, POCloseRequest } from '@gtpl/shared-models/procurement-management';
import { PurchaseOrderForm } from '@gtpl/pages/procurement-masters/procurement-master-components/purchase-order-form';
import { PurchaseOrderService, } from '@gtpl/shared-services/procurement';
import moment from 'moment';
import { EmployeeRolesEnum, POTypeEnum, SoStatusEnum, StatusEnum } from '@gtpl/shared-models/common-models';
import { ClosePoForm } from '@gtpl/pages/procurement/packing-materials-components/close-po-form'
import { SaleOrderService } from '@gtpl/shared-services/sale-management';
import { StockTransferData } from '@gtpl/shared-models/production-management';


/* eslint-disable-next-line */
export interface PurchaseOrderGridProps {
  isUpdate: boolean;
}

export function PurchaseOrderGrid(props: PurchaseOrderGridProps) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm<PoFilterDto>();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [poData, setPoData] = useState<AllPoDetailsInfo[]>([]);
  const [cancelledSos, setCancelledSosData] = useState([]);
  const [cancelledPos, setCancelledPosData] = useState([]);
  const [revisedSos, setRevisedSos] = useState([]);
  const [oldPOData, setOldPOData] = useState<AllPoDetailsInfo[]>([]);
  const [updateState, setUpdateState] = useState(false);
  const [object, setObject] = useState(null);
  const [poId, setPoIdState] = useState(0);
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedInformation, setSelectedInformation] = useState<AllPoDetailsInfo>();
  const [transferredSaleOrders, setTransferredSaleOrders] = useState<any[]>([])
  // const [poData, setPOData] = useState<PODetailViewInfoDTO[]>([]);
  const [newPoData, setNewPoData] = useState<AllPoDetailsInfo[]>([])
  const onReset = () => {
    form.resetFields();
    setPoData(oldPOData);
  }

  const handleCancel = () => {
    getPoDetails();
    setIsModalVisible(false);
  };

  const displayModel = (data: AllPoDetailsInfo) => {
    setSelectedInformation(data);
    setIsModalVisible(true);
  };

  const service = new PurchaseOrderService;


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };



  useEffect(() => {
    getPoDetails();
    getCancelledSoPos();
    getCancelledPosData();
    getRevisedSos();
    getTransferredSos();

  }, []);



  const getPoDetails = () => {
    setPoData([]);
    const poRes = new PoFilterDto(undefined, undefined, Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getAllPoDetailsInfo(poRes).then(res => {
      if (res.status) {
        setPoData(res.data);
        setOldPOData(res.data);
      } else {
        if (res.intlCode) {
          setPoData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPoData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getCancelledSoPos = () => {
    setCancelledSosData([]);
    const poRes = new PoFilterDto(undefined, undefined, Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getCancelledSosForPo(poRes).then(res => {
      if (res.status) {
        setCancelledSosData(res.data);
      } else {
        if (res.intlCode) {
          setCancelledSosData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCancelledSosData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getCancelledPosData = () => {
    setCancelledPosData([]);
    const poRes = new PoFilterDto(undefined, undefined, Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getCancelledPosData(poRes).then(res => {
      if (res.status) {
        setCancelledPosData(res.data);
      } else {
        if (res.intlCode) {
          setCancelledPosData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setCancelledPosData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getRevisedSos = () => {
    setRevisedSos([]);
    const poRes = new PoFilterDto(undefined, undefined, Number(localStorage.getItem('unit_id')), JSON.parse(localStorage.getItem('role')));
    service.getRevisedSos(poRes).then(res => {
      if (res.status) {
        setRevisedSos(res.data);
      } else {
        if (res.intlCode) {
          setRevisedSos([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setRevisedSos([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getTransferredSos = () => {
    service.getTransferredSos().then((res) => {
      if (res.status) {
        setTransferredSaleOrders(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const setUpdatevalue = (rowdata) => {
    setUpdateState(true);
    setObject(rowdata.purchaseOrderId)
    
  }
  
  const setPoId = (rowdata: { purchaseOrderId: number; }) => {
    setPoIdState(rowdata.purchaseOrderId)

  }

  const cancelPO = (rowData) => {
    const req = new POCloseRequest();
    req.purchaseOrderId = rowData.purchaseOrderId;
    service.CancelPoForCancelledSo(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getRevisedSos();
        getCancelledPosData();
        getPoDetails();
        getCancelledSoPos();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columns1: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Prod Unit',
      dataIndex: 'unit_name',
      key: 'unit_name',
      ...getColumnSearchProps('unit_name'),
      // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      // sortDirections: ['descend', 'ascend'],
      // filters: Object.keys(poData).map(key => ({ text: poData[key], value: key })),
      // filters: [
      //   {
      //     text: 'Unit 1',
      //     value: 'Unit 1',
      //   },
      //   {
      //     text: 'Unit 2',
      //     value: 'Unit 2',
      //   },
      //   {
      //     text: 'Kakinada',
      //     value: 'Kakinada',
      //   },
      //   {
      //     text: 'Head Office',
      //     value: 'Head Office',
      //   }
      // ],
      // filterMultiple: false,
      // onFilter: (value, record) => {
      //   return record.unit_name == value;
      // },
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      sorter: (a, b) => a.poType.localeCompare(b.poType),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: POTypeEnum.WITHOUTSO,
          value: POTypeEnum.WITHOUTSO,
        },
        {
          text: POTypeEnum.WITHSO,
          value: POTypeEnum.WITHSO,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.poType == value;
      },
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      width: '250px',
      sorter: (a, b) => a.poNumber?.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
      render: (value, record: AllPoDetailsInfo, index) => {

        return (record.poNumber) ? [...new Set(((record.poNumber)).split(","))].toString() : '-';
      }
    },
    {
      title: 'PO Number',
      dataIndex: 'purchaseOrderNo',
      width: '150px',
      sorter: (a, b) => a.purchaseOrderId - b.purchaseOrderId,
      ...getColumnSearchProps('purchaseOrderNo'),
    },
    {
      title: 'PO Date',
      dataIndex: 'purchaseOrderDate',
      width: '150px',
      sorter: (a, b) => moment(a.purchaseOrderDate).unix() - moment(b.purchaseOrderDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: AllPoDetailsInfo, index) => {
        return moment(record.purchaseOrderDate).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      width: '150px',
      sorter: (a, b) => a.vendorName?.localeCompare(b.vendorName),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('vendorName')
    },
    {
      title: 'Expected Delivery Date',
      dataIndex: 'expectedDeliveryDate',
      width: '150px',
      sorter: (a, b) => a.expectedDeliveryDate?.localeCompare(b.expectedDeliveryDate),
      sortDirections: ['descend', 'ascend'],
      render: (value, record: AllPoDetailsInfo, index) => {
        return moment(record.expectedDeliveryDate).format('YYYY-MM-DD');
      }
    }
  ]
  const closedAging: ColumnProps<any>[] = [
    {
      title: 'Aging',
      key: 'closedAging',
      // responsive: ['lg'],
      sorter: (a, b) => (Math.floor((new Date(moment(a.grnDate).format('YYYY-MM-DD')).getTime() - new Date(moment(b.expectedDeliveryDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.grnDate).getTime() - new Date(moment(b.expectedDeliveryDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24))),
      // sorter: (a, b) => a.aging.localeCompare(b.aging),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
   
      render(text, record) {
        
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(moment(record.grnDate).format('YYYY-MM-DD')).getTime() - new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),   
          props: {
            style: {
              background: Math.floor((new Date(moment(record.grnDate).format('YYYY-MM-DD')).getTime() - new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(moment(record.grnDate).format('YYYY-MM-DD')).getTime() - new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
            }
          },
        };
        console.log(record);
        return obj;
      }
    },
  ];

  const aging: ColumnProps<any>[] = [
    {
      title: 'Aging',
      key: 'aging',
      // responsive: ['lg'],
      sorter: (a, b) => (Math.floor((new Date(moment(a.expectedDeliveryDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) - Math.floor((new Date(b.expectedDeliveryDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))),
      // sorter: (a, b) => a.aging.localeCompare(b.aging),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render(text, record) {
        const obj: any = {
          children: (<div style={{ textAlign: 'right' }}>{Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1}</div>),
          props: {
            style: {
              background: Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "#38f438" : '#f4646c',
              color: Math.floor((new Date(moment(record.expectedDeliveryDate).format('YYYY-MM-DD')).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1 >= 0 ? "black" : 'white'
            }
          },
        };
        return obj;
      }
    },
  ];

  const cancelledSoAction: ColumnProps<any>[] = [
    {
      title: `Action`,
      dataIndex: 'action',
      fixed: 'right',
      render: (text, rowData) => <Tooltip placement="topLeft" title={"Cancel PO"}><Popconfirm title="Are you sure do you want to cancel PO？" onConfirm={() => cancelPO(rowData)}><IssuesCloseOutlined style={{ color: 'red' }} /></Popconfirm></Tooltip>
    }
  ]

  const poAction: ColumnProps<any>[] = [
    {
      title: `Action`,
      dataIndex: 'action',
      fixed: 'right',
      render: (text, rowData) => (
        <span>
          {([StatusEnum.OPEN, StatusEnum.IN_PROGRESS].includes(rowData.status) && ((JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER)) ? <> <Link
            to={{
              pathname: "/purchase-order-form",
              state: { id: rowData.purchaseOrderId, type: 'PO' }
            }}
          >
            <Tooltip placement="topLeft" title={"Edit"}>
              <EditOutlined className={'editSamplTypeIcon'} type="edit" name="edit"
                onClick={() => {

                  setUpdatevalue(rowData);

                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              /></Tooltip></Link>  <Divider type="vertical" /> </> : ''}

          {/* {((rowData.status == StatusEnum.OPEN && (JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER) ?
            <Link
              to={{
                pathname: "/purchase-order-form",
                state: { id: rowData.purchaseOrderId, type: 'PO' }
              }}
            >
              <Tooltip placement="topLeft" title={"Edit"}>
                <EditOutlined className={'editSamplTypeIcon'} type="edit" name="edit"
                  onClick={() => {

                    setUpdatevalue(rowData);

                  }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                /></Tooltip></Link> : '')} */}


          <Link
            to={`/po-detail-view/${rowData.purchaseOrderId}`}
          >
            <Tooltip placement="topLeft" title={"Detail View"}>
              <EyeOutlined type="view" name="detail view"
                onClick={() => {

                  // if (rowData.isActive) {
                  setPoId(rowData);
                  // } else { 
                  //   AlertMessages.getErrorMessage('You Cannot Edit Deactivated Item -Variant');
                  // }
                }}
                style={{ color: '#1890ff', fontSize: '14px' }}
              /></Tooltip>
          </Link>

          {rowData.status != StatusEnum.CLOSED ? <> <Divider type="vertical" /> <Tooltip placement="topLeft" title={"Close PO"}><CloseCircleOutlined style={{ color: 'red' }} onClick={() => displayModel(rowData)} /></Tooltip> </> : ''
          }
          <Divider type="vertical" />
          <Tooltip placement="topLeft" title={"Cancel PO"}><Popconfirm title="Are you sure do you want to cancel PO？" onConfirm={() => cancelPO(rowData)}><IssuesCloseOutlined style={{ color: 'red' }} /></Popconfirm></Tooltip>
        </span>
      )
    }
  ]

  const poColumns = [...columns1, ...aging, ...poAction];

  const cancelledSoColumns = [...columns1, ...closedAging, ...cancelledSoAction];
  const transferredColumns = [...columns1, ...closedAging]

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  const closeDrawer = () => {
    setDrawerVisible(false);
  }

  const updatePo = (poData: PoDto) => {
    service.UpdatePo(poData).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const filterData = () => {
    form.validateFields().then(poRes => {
      if (poRes.fromDate && poRes.toDate) {
        const fromDate = new Date(poRes.fromDate[0]);
        const toDate = new Date(poRes.fromDate[1]);
        const startDate = new Date(poRes.toDate[0]);
        const endDate = new Date(poRes.toDate[1]);
        const filteredData = oldPOData.filter((data) => {
          let date = new Date(data.expectedDeliveryDate);
          return date >= fromDate && date <= toDate;
        }).filter((data) => {
          let date = new Date(data.purchaseOrderDate);
          return date >= startDate && date <= endDate;
        });
        setPoData(filteredData);
      } else if (poRes.fromDate) {
        const fromDate = new Date(poRes.fromDate[0]);
        const toDate = new Date(poRes.fromDate[1]);
        const filteredData = oldPOData.filter((data) => {
          let date = new Date(data.expectedDeliveryDate);
          return date >= fromDate && date <= toDate;
        });
        setPoData(filteredData);
      } else if (poRes.toDate) {
        const startDate = new Date(poRes.toDate[0]);
        const endDate = new Date(poRes.toDate[1]);
        const filteredData = oldPOData.filter((data) => {
          let date = new Date(data.purchaseOrderDate);
          return date >= startDate && date <= endDate;
        });
        setPoData(filteredData);
      } else {
        setPoData(oldPOData);
      }
    });
  }
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Purchase Orders</span>}
        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={(JSON.parse(localStorage.getItem('role'))) !== EmployeeRolesEnum.SENIOR_PACKAGING_MANAGER ? <Link to='/purchase-order-form' ><span style={{ color: 'white' }} ><Button className='panel_button' >Create </Button> </span></Link> : <></>} >

        <Form form={form} layout={'vertical'} style={{ padding: '0px' }} >
          <Row gutter={24}>
            <Col>
              <Form.Item name="fromDate"
                label="EDD Date"
                rules={[
                  {
                    required: false,
                    message: "Missing EDD Date"
                  },
                ]}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="toDate"
                label="PO Date"
                rules={[
                  {
                    required: false,
                    message: "Missing PO Date"
                  },
                ]}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <br />
              <Form.Item >
                <Button type="primary" htmlType="submit" onClick={filterData}>
                  Get Details
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <br />
              <Form.Item >
                {(props.isUpdate !== true) &&
                  <Button type="primary" htmlType="submit" onClick={onReset}>
                    Reset
                  </Button>
                }
              </Form.Item >
            </Col>
          </Row>
        </Form>
        <Row gutter={24}>
          <Col>
            <Card title={'Total POs: ' + poData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
          <Col>
            <Card title={'Total SOs: ' + (Number(cancelledSos.length) + Number(revisedSos.length) + Number(transferredSaleOrders.length))} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
          </Col>
        </Row>

        <Tabs type={'card'} tabPosition={'top'}>
          <TabPane
            key="1"
            tab={<span style={{ color: "#f5222d" }}>{StatusEnum.OPEN + ":" + (poData.filter(el => el.status == StatusEnum.OPEN).length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={poColumns}
              dataSource={poData.filter(rec => rec.status == StatusEnum.OPEN)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />
          </TabPane>
          <TabPane
            key="2"
            tab={<span style={{ color: "#52c41a" }}>{'IN PROGRESS: ' + (poData.filter(el => el.status == StatusEnum.IN_PROGRESS).length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={poColumns}
              dataSource={poData.filter(rec => rec.status == StatusEnum.IN_PROGRESS)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />

          </TabPane>
          <TabPane
            key="3"
            tab={<span style={{ color: "#722ed1" }}>{StatusEnum.CLOSED + ":" + (poData.filter(el => el.status == StatusEnum.CLOSED).length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={poColumns}
              dataSource={poData.filter(rec => rec.status == StatusEnum.CLOSED)}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />
          </TabPane>


          {cancelledSos?.length ? <TabPane
            key="4"
            tab={<span style={{ color: "#003366" }}>{SoStatusEnum.CANCELLED + " SO's: " + (cancelledSos?.length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={cancelledSoColumns}
              dataSource={cancelledSos}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />
          </TabPane> : ''}

          {cancelledPos?.length ? <TabPane
            key="5"
            tab={<span style={{ color: "#663300" }}>{SoStatusEnum.CANCELLED + " PO's: " + (cancelledPos?.length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={columns1}
              dataSource={cancelledPos}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />
          </TabPane> : ''}


          {revisedSos?.length ? <TabPane
            key="6"
            tab={<span style={{ color: "#cc00ff" }}>{"Revised SO's: " + (revisedSos?.length)}</span>}
          >
            <Table
              rowKey={record => record.purchaseOrderId}
              columns={cancelledSoColumns}
              dataSource={revisedSos}
              pagination={{
                onChange(current) {
                  setPage(current);
                }
              }}
              onChange={onChange}
              bordered
              scroll={{ x: true }} />
          </TabPane> : ''}
          {
            transferredSaleOrders.length ?
              <TabPane
                key="7"
                tab={<span style={{ color: "orange" }}>{"Transferred SO's "}</span>}
              >
                <Table
                  rowKey={record => record.purchaseOrderId}
                  columns={transferredColumns}
                  dataSource={transferredSaleOrders}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    }
                  }}
                  onChange={onChange}
                  bordered
                  scroll={{ x: true }} />
              </TabPane> : ''
          }



        </Tabs>

      </Card>
      <Modal
        className='po-closing'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <ClosePoForm poInfo={selectedInformation} handleCancel={handleCancel} />
      </Modal>
    </>
  );
}

export default PurchaseOrderGrid;
