import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Card, Row, Col, Upload, message, UploadProps, DatePicker, FormInstance, InputNumber, Button } from 'antd';
import { Link, Redirect,useHistory } from 'react-router-dom';
import { OperationTypeEnum, PlantsEnum, ShiftsEnum, SubPlantCodesEnum, WorkStationCategoryEnum, WorkStationTypeEnum } from "@gtpl/shared-models/common-models";
import { SoakingAssignmentService, ProdInvService, ProductionInventoryService, WorkstationService } from "@gtpl/shared-services/production";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { LotCodeDropDown, LotInformationRequest, LotNoInfo, LotNumberRequest, WorkStationCategoryReq, WorkstationDTO, WorkStationsDropDown } from '@gtpl/shared-models/production-management';

import './assign-soaking-material.css';
import moment from 'moment';

const { Option } = Select;
/* eslint-disable-next-line */
export interface AssignSoakingMaterialProps { }

export function AssignSoakingMaterial(
  props: AssignSoakingMaterialProps
) {
  const [assignSoakingMaterialForm] = Form.useForm();
  const [workStations, setWorkStations] = useState<WorkStationsDropDown[]>([]);
  const [lotNumbers, setLotNumbers] = useState<any[]>([]);
  const [workStationType, setWorkStationType] = useState<WorkStationTypeEnum>(WorkStationTypeEnum.INTERNAL);
  const [lotNoInfo, setLotNoInfo] = useState<LotNoInfo>(undefined);
  const [boxWeight, setBoxWeight] = useState<number>(0);
  const [workStationData, setWorkStationData] = useState<WorkstationDTO>(undefined);
  const [disable, setDisable] = useState<boolean>(false)
  const productionInvService = new ProdInvService();
  const prodInvService = new ProductionInventoryService();
  const workstationService = new WorkstationService();
  const soakingAssignmentService = new SoakingAssignmentService();
  let history =  useHistory()

  useEffect(() => {
    getWorkStations();
    getLotNumbers();
  }, [])

  const getWorkStations = () => {
    const catReq = new WorkStationCategoryReq();
    catReq.workstationCategory = WorkStationCategoryEnum.find((res) => res.name === "SOAKING").value;
    catReq.unitId = Number(localStorage.getItem('unit_id'));
    workstationService.getWorkStationsForCategory(catReq).then((res) => {
      if (res.status) {
        setWorkStations(res.data);
      } else {
        setWorkStations([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setWorkStations([]);
    });
  }

  const getLotNumbers = () => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotNumberRequest(plant, OperationTypeEnum.SOAKING);

    prodInvService.getLotNumbers(lotNoReq).then((res) => {
      if (res.status) {
        setLotNumbers(res.data);
      } else {
        setLotNumbers([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setLotNumbers([]);
    });

  }

  const checkIssuingQuantity = (value) => {
    const lotQty = assignSoakingMaterialForm.getFieldValue("physicalQuantity");
    if ((Number(lotQty) > Number(workStationData?.workstationUnitCapacity)) || (Number(lotQty) > Number(lotNoInfo?.availableQuantity))) {
      AlertMessages.getWarningMessage("WorkStation capacity exceeded. ");
      assignSoakingMaterialForm.resetFields(['physicalQuantity']);
    }
    console.log(value)
    console.log(lotNoInfo?.availableQuantity)
    if( Number(lotNoInfo?.availableQuantity == 0)  ){
      AlertMessages.getWarningMessage("Issueing Quantity must not be  exceeded to avil Quantity. ");
      assignSoakingMaterialForm.resetFields(['physicalQuantity','workstation','subLotNumber']);

    }
  }


  const checkWorkStationCapacity = () => {
    const lotQty = assignSoakingMaterialForm.getFieldValue("physicalQuantity");
    const plant = Number(localStorage.getItem('unit_id'))
    const workStationId = assignSoakingMaterialForm.getFieldValue("workstation")
    if (workStationId > 0) {
      workstationService.getWorkstationCapacity({ workstationId: workStationId, unitId: plant }).then((res) => {
        if (res.status) {
      
          if (Number(lotQty) > Number(res.data.workstationUnitCapacity)) {
            AlertMessages.getWarningMessage("WorkStation capacity exceeded. ");
            assignSoakingMaterialForm.resetFields(['physicalQuantity']);
          }
          setWorkStationData(res.data);
        } else {
          setWorkStationData(undefined);
        }
      }).catch((err) => {
        setWorkStationData(undefined);
        AlertMessages.getErrorMessage(err.message);
      });
    }
    else {
      AlertMessages.getWarningMessage("Please select workStation. ");
    }
  }
  const getLotNumberInfo = (lotNumber) => {
    const plant = Number(localStorage.getItem('unit_id'))
    const lotNoReq = new LotInformationRequest(lotNumber, plant, OperationTypeEnum.SOAKING)
    prodInvService.getLotNumberInfo(lotNoReq).then((res) => {
      if (res.status) {
        setLotNoInfo(res.data)
        let boxWeight = (res.data.availableQuantity) / (res.data.boxes);
        setBoxWeight(boxWeight);
        assignSoakingMaterialForm.setFieldsValue({
          productionInventoryId: res.data.productionInventoryId,
          saleOrderId: res.data.saleOrderId,
          saleOrder: res.data.saleOrderNo,
          count: res.data.count,
          boxes: res.data.boxes,
          availableQuantity: res.data.availableQuantity,
          physicalQuantity: workStationData?.workstationUnitCapacity ? workStationData?.workstationUnitCapacity : 0,
          inputGrade: res.data.inputGrade,
          saleOrderItemId: res.data.saleOrderItemId,
          soakingStyle: res.data.soakingStyle,
          soakingTime: res.data.soakingTime,
          lotNumber: res.data.lot
        })
        checkWorkStationCapacity();
      } else {
        setLotNoInfo(undefined);
      }
    }).catch((err) => {
      setLotNoInfo(undefined);
      AlertMessages.getErrorMessage(err.message);
    });
  }



  const onFinish = (isReset) => {
    assignSoakingMaterialForm.validateFields().then(() => {

      const formInfo = assignSoakingMaterialForm.getFieldsValue();
      formInfo.createdUser = localStorage.getItem('createdUser');
      formInfo.plantId = localStorage.getItem('unit_id');
      console.log("my data is:");

      if( (Number(formInfo.physicalQuantity) > Number(lotNoInfo?.availableQuantity))){
        message.error('physicalQuantity exceeds availquantity')
        assignSoakingMaterialForm.resetFields(['physicalQuantity']);  

      }

      else{ 
        if(Number(lotNoInfo?.availableQuantity) == assignSoakingMaterialForm.getFieldValue('physicalQuantity')){
          soakingAssignmentService.createSoakingAssignment(formInfo).then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getLotNumberInfo(assignSoakingMaterialForm.getFieldValue('subLotNumber'))
                onReset();            
                history.push('/soaking-assignment-grid')
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
          });
        }
       
        else{
          soakingAssignmentService.createSoakingAssignment(formInfo).then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getLotNumberInfo(assignSoakingMaterialForm.getFieldValue('subLotNumber'))
              if(isReset){
                onReset();            
                history.push('/soaking-assignment-grid')
              }
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch((err) => {
            AlertMessages.getErrorMessage(err.message);
          });
        }
   
    }
    })
  
  }
  const onReset = () => {
    assignSoakingMaterialForm.resetFields();

  }
  const calculateIssuingQty = () => {
    const noOfBoxes = assignSoakingMaterialForm.getFieldValue('boxes');
    const availableQuantity = assignSoakingMaterialForm.getFieldValue('availableQuantity');
    // const boxWeight = availableQuantity/noOfBoxes;
    const physicalQuantity = boxWeight * noOfBoxes;
    if (physicalQuantity <= availableQuantity) {
      assignSoakingMaterialForm.setFieldsValue({ physicalQuantity: physicalQuantity });
    } else {
      assignSoakingMaterialForm.setFieldsValue({ physicalQuantity: 0, boxes: 0 });
    }
  }
  console.log(disable)

  return (
    <Card title={<span style={{ color: 'white' }}>Soaking Assignment</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/soaking-assignment-grid' ><Button className='panel_button' >View </Button></Link>}>
      <Form layout={'vertical'} form={assignSoakingMaterialForm} name="control-hooks">

        <Row gutter={24}>
          <Form.Item name="productionInventoryId" >
            <Input hidden />
          </Form.Item>
          <Form.Item name="boxes" >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} style={{ display: (workStationType == WorkStationTypeEnum.INTERNAL) ? 'block' : 'none' }}>
            <Form.Item name="workstation" label="Workstation" rules={[{ required: true, message: 'Missing Workstation' }]} >
              <Select
                placeholder="Select Workstation"
                onChange={checkWorkStationCapacity}
                allowClear
              >
                {workStations.map(dropData => {
                  return <Option key={dropData.workstationId} value={dropData.workstationId}>{dropData.workstation}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, }} lg={{ span: 5, }} xl={{ span: 5, }}>
            <Form.Item name="assignedTime" label="Input Time" rules={[{ required: true }]} initialValue={moment()}>
              <DatePicker showTime={{ format: "HH:mm" }} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')} />
            </Form.Item>
            {/* <Form.Item  name="assignedTime" label="Input Time" rules={[{ required: true,message: 'Missing Input Time' }]}>
          <DatePicker showTime={{format: "HH:mm"}} style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss"
                  showToday={true} defaultValue={moment('YYYY-MM-DD HH:mm:ss')}/>
           
            </Form.Item> */}
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

            <Form.Item name="subLotNumber" label="Sub Lot Code" rules={[{ required: true, message: 'Missing Sub Lot Code' }]}>
              <Select
                placeholder="Select Sub Lot Code"
                allowClear
                onChange={getLotNumberInfo}
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {lotNumbers.map(dropData => {
                  return <Option key={dropData.subLotNumber} value={dropData.subLotNumber}>{dropData.sublotandplantlot}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>
          <Form.Item name="saleOrderId">
            <Input hidden />
          </Form.Item>
          <Form.Item name="saleOrderItemId">
            <Input hidden />
          </Form.Item>
          <Form.Item name="lotNumber">
            <Input hidden />
          </Form.Item>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="saleOrder" label="SO Number" rules={[{ required: true, message: 'Missing Sale Order No' }]}>
              <Input disabled />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="inputGrade" label="Input Grade" rules={[{ required: true, message: 'Missing Input Grade' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={calculateIssuingQty} disabled />
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <div className='qty-display-card' >
              <p>Workstation capacity : {workStationData?.workstationUnitCapacity ? workStationData?.workstationUnitCapacity : 0}</p>
              <p>Available Qty : {lotNoInfo?.availableQuantity ? lotNoInfo?.availableQuantity : 0}</p>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="physicalQuantity" label="Issuing Quantity" rules={[{ required: true, message: 'Missing Issuing Quantity' }]}>
              <InputNumber style={{ width: '100%' }} min={0} onChange={checkIssuingQuantity} />
            </Form.Item>

          </Col>
          <Col span={4}>
            <Form.Item name="soakingStyle" label="Soaking Style" rules={[{ required: true, message: 'Missing Soaking Style' }]}>
              <Input style={{ width: '100%' }} disabled />

              {/* <Select
                    placeholder="Soaking Style"
                    allowClear
                  >
                    <Option label={'soakingStyle'} value="STPP+SALT">STPP+SALT</Option>
                    <Option label={'soakingStyle'} value="Only SALT">Only SALT</Option>
                    <Option label={'soakingStyle'} value="NP + SALT">NP + SALT</Option>
                    <Option label={'soakingStyle'} value="CP + SALT"> CP + SALT</Option>
                  </Select> */}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="soakingTime" label="Soaking Time (Min)" rules={[{ required: true, message: 'Missing Soaking Time' }]}>
              <InputNumber style={{ width: '100%' }} disabled />
            </Form.Item>

          </Col>
        </Row>
        <Row gutter={24} justify='end'>
          {/* <Col>
            <Form.Item style={{ paddingTop: '25px' }}>
              <Button type='primary' block onClick={onFinish}>
                Submit
              </Button>
            </Form.Item>
          </Col> */}
          <Col span={3} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={disable == true ? true : false } onClick={() => {onFinish(false)}} htmlType="submit" >
              Save & next
            </Button>
          </Col>
          <Col span={3}>
            <Button type="primary" disabled={disable} onClick={() => {onFinish(true)}} htmlType="submit" >
              Save & close
            </Button>
          </Col>
          <Col span={2}>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          </Col>

      </Row>
    </Form>
    </Card >
  );
}

export default AssignSoakingMaterial;