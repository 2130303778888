export class SaleOrderIdsRequest {
    saleOrderId: number[];
    plantId?: number;

    /**
     * 
     * @param saleOrderId 
     * @param plantId 
     */
    constructor(saleOrderId: number[],plantId?: number){
       this.saleOrderId = saleOrderId;
       this.plantId = plantId;
     }
  }