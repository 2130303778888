import { PackingStatus, TransactionType } from "@gtpl/shared-models/common-models";
import { Shifts } from "./shifts.enum";

export class StockInResponseDto {
    // reportingLogId: number;
    saleOrderId: number;
    saleOrderItemId: number;
    // reportedPerson: string;
    reportingCases: number;
    palletId:number;
    rackPostion:number;
    loosePouches: number;
    lotCode: string;
    // transaction: string;
    // transactionType: TransactionType;
    // traceCode: string;
    // receivingPlant: number;
    // coldStorageId: number;
    plantId: number;
    packingCompleted: PackingStatus;
    shifts: Shifts;
    // loactionId:number;
    reportingDate: Date;
    // createdUser: string;
    rackSerial?:string;
    // updatedUser?: string;
    
   
    constructor(
        // reportingLogId: number,
        saleOrderId: number,
        saleOrderItemId: number,
        // reportedPerson: string,
        reportingCases: number,
        // casesRequired:number,
        palletId:number,
        rackPostion:number,
        loosePouches: number,
        lotCode: string,
        // transaction: string,
        // transactionType: TransactionType,
        // traceCode: string,
        // receivingPlant: number,
        // coldStorageId: number,
        plantId:number,
        packingCompleted: PackingStatus,
        shifts: Shifts,
        // loactionId:number,
        reportingDate: Date,
        // createdUser: string,
        rackSerial?:string,
        // updatedUser?: string,
         ){
        // this.reportingLogId = reportingLogId;
        this.saleOrderId = saleOrderId;
        this.saleOrderItemId = saleOrderItemId;
        // this.updatedUser = updatedUser;
        this.reportingCases = reportingCases;
        // this.casesRequired = casesRequired;
        // this.transactionType = transactionType;
        this.palletId = palletId;
        this.rackSerial = rackSerial;
        this.rackPostion = rackPostion;
        this.loosePouches= loosePouches;
        this.lotCode= lotCode;
        // this.transaction= transaction;
        this.packingCompleted= packingCompleted;
        this.shifts = shifts;
        // this.traceCode= traceCode;
        // this.receivingPlant= receivingPlant;
        // this.coldStorageId= coldStorageId;
        this.plantId= plantId;
        // this.loactionId = loactionId;
        // this.reportedPerson = reportedPerson;
        this.reportingDate = reportingDate;
        // this.createdUser = createdUser;
       
    }
}
