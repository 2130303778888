import React, { useState, useEffect, useRef } from 'react';
import { Divider, Table, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { ColumnProps } from 'antd/lib/table';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { CheckCircleOutlined,DoubleRightOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import './reprocessing-repacking-grid.css';
import { FGStockService, ForkLiftJobService } from '@gtpl/shared-services/warehouse-management';
import { ForkliftJobcodesDropDownDto } from '@gtpl/shared-models/sale-management';
import { ForkLiftJobRequestDTO } from '@gtpl/shared-models/warehouse-management';
import AssignStock from './assign-stock';
import AllocateReprocessingStock from './allocate-reprocessing-stock';
import { Redirect } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ReprocessingRepackingGridProps { }

export function ReprocessingRepackingGrid(
  props: ReprocessingRepackingGridProps
) {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [jobcodesData, setJobcodesData] = useState<any[]>([]);
  const [availableStockData, setAvailableStockData] = useState<any[]>([]);
  const [dummyStockData, setDummyStockData] = useState<any[]>([]);
  const [superDummyStockData, setSuperDummyStockData] = useState<any[]>([]);
  const [allocatedStockData, setAllocatedStockData] = useState<any[]>([]);
  const [showVisible, setShowVisible] = useState<boolean>(false);
  const [showReprocessingVisible, setShowReprocessingVisible] = useState<boolean>(false);
  const [availableQty, setAvailableQty] = useState<number>(0);
  const [saleOrdersData, setSaleOrdersData] = useState<any[]>([]);
  const [reprocessingSaleOrdersData, setReprocessingSaleOrdersData] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any>(undefined);

  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const columns = useState('');
  const service = new ForkLiftJobService;
  const fGStockService = new FGStockService;

  const { TabPane } = Tabs;
  const [pageSize, setPageSize] = useState<number>(null);

  /**
   * used for column filter
   * @param dataIndex column data index
   */
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @param dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Job Purpose',
      dataIndex: 'jobPurpose',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobPurpose.localeCompare(b.jobPurpose),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('jobPurpose')

    },
    {
      title: 'Stock Type',
      dataIndex: 'stockType',
      // responsive: ['lg'],
      sorter: (a, b) => a.stockType.localeCompare(b.stockType),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('jobPurpose')

    },
    {
      title: 'Job Code',
      dataIndex: 'jobCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.jobCode.localeCompare(b.jobCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('jobCode')
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      // responsive: ['lg'],
      sorter: (a, b) => a.packStyle.localeCompare(b.packStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packStyle')
    },
    {
      title: 'Total Cartons',
      dataIndex: 'totalJobCartons',
      align: 'right',
      // responsive: ['lg'],
      render: (status, rowData) => (
        <>
          {rowData.totalJobCartons + "(Qty : " + rowData.totIssuedQuantity + ")"}

        </>
      ),
    },
    {
      title: 'Received Quantity',
      dataIndex: 'totReceivedQuantity',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.totReceivedQuantity.localeCompare(b.totReceivedQuantity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('totReceivedQuantity')
    },
    {
      title: 'Excess Quantity',
      dataIndex: 'excessQuantity',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.excessQuantity.localeCompare(b.excessQuantity),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('excessQuantity')
    },
    {
      title: 'Damage Quantity',
      dataIndex: 'damagedCartons',
      align: 'right',
      // responsive: ['lg'],
      sorter: (a, b) => a.damagedCartons.localeCompare(b.damagedCartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('damagedCartons')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // responsive: ['lg'],
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    },
    {
      title:'Action',
      dataIndex:'action',
      render: (status, rowData) => (
        <>
        {rowData.status != "Completed"?
        <><DoubleRightOutlined type="view"
              onClick={() => {
                setRowData(rowData);
              } }
              style={{ color: '#1890ff', fontSize: '14px' }} /><Divider type="vertical" /></> : "-" }
       </>
      ),
    }
  ];
  const allocatedStockColumns: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Purpose',
      dataIndex: 'process',
      // responsive: ['lg'],
      sorter: (a, b) => a.process.localeCompare(b.process),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('process')
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: "Allocated SO",
      dataIndex: 'allocatedSO',
      // responsive: ['lg'],
      sorter: (a, b) => a.allocatedSO.localeCompare(b.allocatedSO),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('allocatedSO')
    },
    {
      title: 'Product SKU',
      dataIndex: 'productSKU',
      // responsive: ['lg'],
      sorter: (a, b) => a.productSKU.localeCompare(b.productSKU),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('productSKU')
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      // responsive: ['lg'],
    },
    {
      title: 'Location',
      dataIndex: 'location',
      // responsive: ['lg'],
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location')
    },
    {
      title: 'No of Cartons',
      dataIndex: 'totalCartons',
      // responsive: ['lg'],
      sorter: (a, b) => a.totalCartons - b.totalCartons,
      sortDirections: ['descend', 'ascend'],
      render: (value) => (<span style={{ float: 'right' }}>{Number(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</span>)
    },
    {
      title: "Allocated Qty (IN LB's)",
      dataIndex: 'qty',
      // responsive: ['lg'],
      sorter: (a, b) => a.qty - b.qty,
      sortDirections: ['descend', 'ascend'],
      render: (value) => (<span style={{ float: 'right' }}>{Number(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</span>)

    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span style={{ alignContent: 'center' }}>
    //       <Tooltip placement="top" title='Allocate'>
    //         <Button
    //           onClick={() => { ReverseAllocation(rowData) }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         >Reverse Allocation</Button>
    //       </Tooltip>
    //       <Divider type="vertical" />
    //     </span>
    //   )
    // }
  ]
  const tableColumns = (key) => {

    const columnSkelton: ColumnProps<any>[] = [
      {
        title: 'S No',
        key: 'sno',
        width: '70px',
        responsive: ['sm'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1)
      },
      {
        title: 'Product SKU',
        dataIndex: 'productSku',
        // responsive: ['lg'],
        sorter: (a, b) => a.productSku.localeCompare(b.productSku),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('productSku')
      },
      // {
      //   title: 'Pack Style',
      //   dataIndex: 'packStyle',
      //   // responsive: ['lg'],
      // },
      {
        title: 'No of Cartons',
        dataIndex: 'cartons',
        // responsive: ['lg'],
        sorter: (a, b) => a.cartons - b.cartons,
        sortDirections: ['descend', 'ascend'],
        render: (value) => (<span style={{ float: 'right' }}>{Number(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</span>)
      },
      {
        title: "Available Qty (In LB's)",
        dataIndex: 'availableQty',
        // responsive: ['lg'],
        sorter: (a, b) => a.availableQty - b.availableQty,
        sortDirections: ['descend', 'ascend'],
        render: (value) => (<span style={{ float: 'right' }}>{Number(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</span>)

      },
    ]
    const repackAndReprocess: ColumnProps<any>[] = [
      {
        title: 'Repacking',
        dataIndex: 'action',
        render: (text, rowData) => (
          rowData.isSOExist != null ?
            <span style={{ alignItems: 'center' }}>
              <Tooltip placement="top" title='Allocate Repacking'>
                <Button
                  onClick={() => { getSaleOrdersList(rowData) }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                >Allocate</Button>
              </Tooltip>
            </span>
            : "-"
        )
      },
      {
        title: 'Reprocessing',
        dataIndex: 'action',
        render: (text, rowData) => (
          rowData.reprocessSoId != null ?
            <span style={{ alignItems: 'center' }}>
              <Tooltip placement="top" title='Allocate Reprocessing'>
                <Button
                  onClick={() => { getReprocessingSaleOrdersList(rowData) }}
                  style={{ color: '#1890ff', fontSize: '14px' }}
                >Allocate</Button>
              </Tooltip>
            </span>
            : "-"
        )
      }
    ]
    if (key === 5) {
      return [...columnSkelton];
    }
    else {
      return [...columnSkelton, ...repackAndReprocess];
    }
  }

  const superDummySkelton: ColumnProps<any>[] = [
    {
      title: 'S No',
      key: 'sno',
      width: '70px',
      responsive: ['sm'],
      render: (text, object, index) => (page - 1) * 10 + (index + 1)
    },
    {
      title: 'Customer PO',
      dataIndex: 'poNumber',
      // responsive: ['lg'],
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber')
    },
    {
      title: 'Product SKU',
      dataIndex: 'variantCode',
      // responsive: ['lg'],
      sorter: (a, b) => a.variantCode.localeCompare(b.variantCode),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variantCode')
    },
    {
      title: 'Pack Style',
      dataIndex: 'packStyle',
      // responsive: ['lg'],
      sorter: (a, b) => a.packStyle.localeCompare(b.packStyle),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('packStyle')
    },
    {
      title: 'Location',
      dataIndex: 'location',
      // responsive: ['lg'],
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('location')
    },
    {
      title: 'No of Cartons',
      dataIndex: 'cartons',
      // responsive: ['lg'],
      sorter: (a, b) => a.cartons.localeCompare(b.cartons),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cartons'),
      render: (value) => (<span style={{ float: 'right' }}>{value}</span>)
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   render: (text, rowData) => (
    //     <span style={{ alignContent: 'center' }}>
    //       <Tooltip placement="top" title='Allocate'>
    //         <Button
    //           onClick={() => { AssignRepacking(rowData) }}
    //           style={{ color: '#1890ff', fontSize: '14px' }}
    //         >Allocate Stock</Button>
    //       </Tooltip>
    //       <Divider type="vertical" />
    //     </span>
    //   )
    // }
  ];

  /**
   * 
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  useEffect(() => { getForkliftJobCodesForGrid(); getDummyStock(); getSuperDummyStock(); getAllocatedStock() }, [])

  const getAllocatedStock = () => {
    fGStockService.getAllocatedStock({ plantId: Number(localStorage.getItem('unit_id')) }).then(res => {
      if (res.status) {
        setAllocatedStockData(res.data);
        // AlertMessages.getSuccessMessage(res.internalMessage)
      }
      else {
        if (res.intlCode) {
          setAllocatedStockData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setAllocatedStockData([]);
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setAllocatedStockData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getSuperDummyStock = () => {
    fGStockService.getSuperDummyStock({ unitId: Number(localStorage.getItem("unit_id")) }).then(res => {
      if (res.status) {
        setSuperDummyStockData(res.data);
        // AlertMessages.getSuccessMessage(res.internalMessage)
      }
      else {
        if (res.intlCode) {
          setSuperDummyStockData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setSuperDummyStockData([]);
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setSuperDummyStockData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getDummyStock = () => {
    fGStockService.getDummyStock({ plantId: Number(localStorage.getItem("unit_id")) }).then(res => {
      if (res.status) {
        setDummyStockData(res.data);
        // AlertMessages.getSuccessMessage(res.internalMessage)
      }
      else {
        if (res.intlCode) {
          setDummyStockData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          setDummyStockData([]);
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setDummyStockData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getForkliftJobCodesForGrid = () => {

    const plant = Number(localStorage.getItem('unit_id'));
    const req = new ForkLiftJobRequestDTO()
    req.unitId = plant
    service.getForkliftJobCodesForGrid(req).then(res => {
      if (res.status) {
        setJobcodesData(res.data);
        setAvailableStockData(res.data1)
        //  AlertMessages.getSuccessMessage('Data retreived sucessfully')
      }
      else {
        if (res.intlCode) {
          // setJobcodesData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setJobcodesData([]);
    })
  }
  const ReverseAllocation = (data) => {
    fGStockService.getReverseAllocatedStock({stockId:data.stockId,category:data.process,rmStockQty:data.qty,minGrade:data.minGrade,maxGrade:data.maxGrade,hlPercentage:data.hlPer,finalPercentage:data.finalPercentage,totalPouches:data.pouches,shrimpWeight:data.shrimpWeight,uomId:data.uomId,totalCases:data.cases}).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const AssignRepacking = (data) => {
    console.log(data);
    fGStockService.getAssignSuperDummyToRepack({ allocatedSaleOrderId: data.allocatedSaleOrderId, allocatedSaleOrderItemId: data.allocatedSaleOrderItemId, stockIds: data.stockIds }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getSuperDummyStock()
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getReprocessingSaleOrdersList = (data) => {
    setAvailableQty(data.availableQty);
    const plant = Number(localStorage.getItem('unit_id'));
    fGStockService.getSaleOrderToAssignReprocessing({ plantId: plant, role: undefined, varientId: data.variantId, uom: data.uom, availQty: data.availableQty, stockIds: data.stockIds }).then(res => {
      if (res.status) {
        setShowReprocessingVisible(true);
        setReprocessingSaleOrdersData(res.data);
        //AlertMessages.getSuccessMessage(res.internalMessage)
      }
      else {
        if (res.intlCode) {
          setShowReprocessingVisible(false);
          setReprocessingSaleOrdersData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      // AlertMessages.getErrorMessage(err.message);
      setShowReprocessingVisible(false);
      setReprocessingSaleOrdersData([]);
    })
  }
  const getSaleOrdersList = (data) => {
    setAvailableQty(data.availableQty);
    const plant = Number(localStorage.getItem('unit_id'));
    fGStockService.getSaleOrderToAssignRepacking({ plantId: plant, role: undefined, varientId: data.variantId, uom: data.uom, availQty: data.availableQty, stockIds: data.stockIds }).then(res => {
      if (res.status) {
        setShowVisible(true);
        setSaleOrdersData(res.data);
        // AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        if (res.intlCode) {
          setShowVisible(false);
          setSaleOrdersData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          //  AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setShowVisible(false);
      setSaleOrdersData([]);
    })
  }
  const handleRepackCancel = () => {
    getDummyStock()
    setShowVisible(false);
  }
  
  const handleReprocessCancel = () => {
    setShowReprocessingVisible(false)
  }
  const closeModal = (closeModal) => {
    console.log(closeModal)
    if (closeModal) {
      setShowVisible(false);
      setShowReprocessingVisible(false)
      window.location.reload();
    }
  }
  return (
    <>
      {(rowData) ? <Redirect to={{ pathname: "/stock-in", state: rowData }} /> : null}

    <Card title={<span style={{ color: 'white' }}>Repacking & Reprocessing</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <br></br>

    <Tabs type={'card'} tabPosition={'top'} onChange={() => { setPageSize(10); setPage(1); } }>
    <TabPane
      key="1"

      tab={<span style={{ color: "#0ec92d" }}>{'Allocate Dummy to SO'}</span>}
    >
      <Table
        rowKey={record => record.jobId}
        columns={tableColumns(Number(localStorage.getItem('unit_id')))}
        dataSource={dummyStockData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        bordered />
    </TabPane>
    <TabPane
      key="2"

      tab={<span style={{ color: "#0ec92d" }}>{'Assign Repacking & ReProcessing'}</span>}
    >
      <Table
        rowKey={record => record.jobId}
        columns={allocatedStockColumns}
        dataSource={allocatedStockData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        bordered />
    </TabPane>
    <TabPane
      key="3"

      tab={<span style={{ color: "#0ec92d" }}>{'Assign SuperDummy to Re-Pack'}</span>}
    >
      <Table
        rowKey={record => record.jobId}
        columns={superDummySkelton}
        dataSource={superDummyStockData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        bordered />
    </TabPane>
    <TabPane
      key="4"

      tab={<span style={{ color: "#0ec92d" }}>{'Repacking & Reprocessing Jobs: ' + (jobcodesData.length)}</span>}
    >
      <Row gutter={40}>
        <Col>
          <Card title={'Total Jobs: ' + jobcodesData.length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Pending Jobs: ' + jobcodesData.filter((rec) => rec.status === "Pending").length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
        <Col>
          <Card title={'Completed Jobs: ' + jobcodesData.filter((rec) => rec.status === "Completed").length} style={{ textAlign: 'left', width: 200, height: 41, backgroundColor: '#bfbfbf' }}></Card>
        </Col>
      </Row><br></br>
      <Table
        rowKey={record => record.jobId}
        columns={columnsSkelton}
        dataSource={jobcodesData}
        pagination={{
          onChange(current) {
            setPage(current);
          }
        }}
        scroll={{ x: true }}
        onChange={onChange}
        bordered />
    </TabPane>
  </Tabs>
  <Modal
    className='print-docket-modal'
    key={'modal' + Date.now()}
    width={'90%'}
    style={{ top: 30, alignContent: 'right' }}
    visible={showVisible}
    title={<React.Fragment>
    </React.Fragment>}
    onCancel={handleRepackCancel}
    footer={[]}
    closable

  >
    {<AssignStock tableData={saleOrdersData} availableQty={availableQty} closeForm={handleRepackCancel} />}
  </Modal>
  <Modal
    className='print-docket-modal1'
    key={'modal1' + Date.now()}
    width={'80%'}
    style={{ top: 30, alignContent: 'right' }}
    visible={showReprocessingVisible}
    title={<React.Fragment>
    </React.Fragment>}
    onCancel={handleReprocessCancel}
    footer={[]}
  >
    {<AllocateReprocessingStock tableData={reprocessingSaleOrdersData} availableQty={availableQty} closeForm={handleReprocessCancel} />}
  </Modal>
</Card></>
  );
}

export default ReprocessingRepackingGrid;
