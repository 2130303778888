import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SaleOrderStatusDropdownDto } from './sale-order-status-dropdown.dto';

export class SaleOrderStatusDropdownResponseModel extends GlobalResponseObject{
    data?: SaleOrderStatusDropdownDto[];
    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data //SaleOrderStatusDropdownDto[]
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: SaleOrderStatusDropdownDto[]) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
