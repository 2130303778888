import { GlobalResponseObject } from "@gtpl/shared-models/common-models"
import { TeamCalenderDto } from "./team-calender.dto"

export class TeamCalenderResponse extends GlobalResponseObject{
    data?:TeamCalenderDto[]

    /**
     * 
     * @param status 
     * @param intlCode 
     * @param internalMessage 
     * @param data 
     */

    constructor(status:boolean, intlCode:number, internalMessage:string, data?: TeamCalenderDto[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}