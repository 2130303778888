export class AssetByCode {
    assetsCode: string;
    assetServiceId?: number;
    status?: string;
    remarks?: string
    constructor(assetsCode: string, assetServiceId?: number, status?: string, remarks?: string) {
        this.assetsCode = assetsCode;
        this.assetServiceId = assetServiceId;
        this.status = status;
        this.remarks = remarks;
    }
}

