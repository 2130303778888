import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { Brands } from './brand.dto';

export class GetAllBrandsResponseModel extends GlobalResponseObject{
    data?: Brands[];

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: Brands[]){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
}
}
