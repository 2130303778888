import { FgStockStatusEnum } from './fg-stock-status.enum';
export class StockStatusRequest {
    stockId: number;
    status:FgStockStatusEnum;
    updatedUser: string;

    constructor(stockId:number,status:FgStockStatusEnum,updatedUser: string){
        this.stockId = stockId;
        this.status = status;
        this.updatedUser = updatedUser;
    }
}
