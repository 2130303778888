export class RmRackPostionReq {
    rmRackPositionId: number;
    newRmRackPositionId?: number;
    saleOrderId?:number;
    updatedUser?: string;
    versionFlag?: number;
    isActive?: boolean;

    constructor(rmRackPositionId: number,newRmRackPositionId?:number,saleOrderId?:number,updatedUser?: string,versionFlag?: number,isActive?: boolean) {
        this.rmRackPositionId = rmRackPositionId;
        this.newRmRackPositionId = newRmRackPositionId;
        this.saleOrderId = saleOrderId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isActive = isActive;
    }
}