import { ShiftCodeReq, TeamCalenderDto, TeamCalenderResponse } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class TeamCalenderService {

    URL = connection.DEPLOY_URL + '/teamcalender';

    async createTeamCalender(teamcalenderdto: TeamCalenderDto): Promise<TeamCalenderResponse> {
        console.log(teamcalenderdto);

        return await axios.post(this.URL + '/createTeamCalender', teamcalenderdto)
            .then(res => {
                return res.data
            })
    }

    async getAllTeamCalender(): Promise<TeamCalenderResponse> {

        return await axios.post(this.URL + '/getAllTeamCalender')
            .then(res => {
                return res.data
            })
    }

    async getTeamCalenderRecords(req: ShiftCodeReq): Promise<TeamCalenderResponse> {
        return await axios.post(this.URL + '/getTeamCalenderRecords', req)
            .then(res => {
                return res.data
            })
    }

    async updateTeamCalender(teamcalenderdto: TeamCalenderDto): Promise<TeamCalenderResponse> {
        return await axios.post(this.URL + '/updateTeamCalender', teamcalenderdto)
            .then(res => {
                return res.data
            })
    }

    async activateOrDeactivateTeamCalender(teamcalenderDto: TeamCalenderDto): Promise<TeamCalenderResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateTeamCalender', teamcalenderDto)
            .then(res => {
                return res.data
            })
    }
}