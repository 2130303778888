export class RelationDto{
    relationId : number;
    relation : string;
    isActive: boolean;
    createdUser : string;
    
    /**
     * 
     * @param relationId number
     * @param relation string
     * @param isActive boolean
     * @param createdUser string
     */
    constructor(relationId : number,relation : string,isActive: boolean,createdUser : string){
        this.relationId = relationId;
        this.relation = relation;
        this.isActive= isActive;
        this.createdUser= createdUser;
    }
}
