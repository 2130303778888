import { AboutToExpireDetail } from "./about-to-expire-detail.model";
import { AssetDetails } from "./asset-details.model";

export class AssetMaintenanceInfoModel {
  maintenanceType: string; // INSURANCE, AMC, WARRANTY, LICENSE etc..
  maintenancePer: string; // YEAR/MONTH etc..
  maintenanceTotalCost: number; // for one month/ year
  maintenanceDue: number;
  isExpiryApplicable: number;
  expiredAssetsCount: number;
  expiredAssetsValue: number;
  amcApplicableButNotInUseCount: number;
  amcApplicableButNotInUseValue: number;
  aboutToExpireDetails: AboutToExpireDetail[];
  top5AssetExpiryDetails: AssetDetails[];

  constructor(
    maintenanceType: string,
    maintenancePer: string,
    maintenanceTotalCost: number,
    maintenanceDue: number,
    isExpiryApplicable: number,
    expiredAssetsCount: number,
    expiredAssetsValue: number,
    amcApplicableButNotInUseCount: number,
    amcApplicableButNotInUseValue: number,
    aboutToExpireDetails: AboutToExpireDetail[],
    top5AssetExpiryDetails: AssetDetails[]
  ) {
    this.maintenanceType = maintenanceType;
    this.maintenancePer = maintenancePer;
    this.maintenanceTotalCost = maintenanceTotalCost;
    this.maintenanceDue = maintenanceDue;
    this.isExpiryApplicable = isExpiryApplicable;
    this.expiredAssetsCount = expiredAssetsCount;
    this.expiredAssetsValue = expiredAssetsValue;
    this.amcApplicableButNotInUseCount = amcApplicableButNotInUseCount;
    this.amcApplicableButNotInUseValue = amcApplicableButNotInUseValue;
    this.aboutToExpireDetails = aboutToExpireDetails;
    this.top5AssetExpiryDetails = top5AssetExpiryDetails;
  }
}
