export class SoPackingStatusDto {
    soNumber:string;
    packingSubCategory:string;
    requiredQty:number;
    poQty:number;
    item:string;
    receivedQty:number;
    assignedQty:number;
    issuedQty?:number;
    fgQty?:number;
    balanceQty?:number;
    returnedQty?:number;
    etd?:any;
    saleOrderId?:number;
    saleOrderNo?:string;
    itemSubCategoryId?: number;
    itemSubCategory?:string;
    itemNameId?:number;

    constructor(soNumber:string,packingSubCategory:string,requiredQty:number,poQty:number,item:string,receivedQty:number,assignedQty:number,issuedQty?:number,fgQty?:number,balanceQty?:number,returnedQty?:number,etd?:any,saleOrderId?:number,saleOrderNo?:string, itemSubCategoryId?: number, itemSubCategory?:string,itemNameId?:number){
        this.soNumber= soNumber;
        this.packingSubCategory = packingSubCategory;
        this.requiredQty = requiredQty;
        this.poQty = poQty;
        this.item = item;
        this.receivedQty = receivedQty;
        this.assignedQty = assignedQty;
        this.issuedQty = issuedQty;
        this.fgQty = fgQty;
        this.balanceQty = balanceQty;
        this.returnedQty = returnedQty;
        this.etd = etd;
        this.saleOrderId = saleOrderId;
        this.saleOrderNo = saleOrderNo;
        this.itemSubCategoryId = itemSubCategoryId;
        this.itemSubCategory = itemSubCategory;
        this.itemNameId = itemNameId;
    }
}