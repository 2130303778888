export class LogBookItems{
    logBookItemId: number;
    containerLogBookId: number;
    row: string;
    saleOrderItemId: number;
    brandId: number;
    cartons:number;
    isActive:boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;
    
    constructor(logBookItemId: number,containerLogBookId: number,row: string,saleOrderItemId: number,brandId: number,cartons:number,isActive:boolean,createdAt: Date,createdUser: string,updatedAt: Date,updatedUser: string,versionFlag: number){
            this.logBookItemId=logBookItemId; 
            this.containerLogBookId=containerLogBookId; 
            this.row=row; 
            this.saleOrderItemId=saleOrderItemId; 
            this.brandId=brandId; 
            this.cartons=cartons;
            this.isActive=isActive;
            this.createdAt=createdAt; 
            this.createdUser=createdUser; 
            this.updatedAt=updatedAt; 
            this.updatedUser=updatedUser; 
            this.versionFlag=versionFlag; 

    }
}