export class BomItemsDto {
    productId:number;
    minNetWeight:number;
    maxNetWeight:number;
    calculatedRatio:number;
    hl:number;
    valueAdditionPD:number;
    soaking:number;
    cooking:number;
    iqf:number;
    ratio:number;
    netWeight:number;
    countPerKG:number;
    rawMaterialRequiredInKgs:number;
    rawMaterialRequiredInLbs:number;
    availableQuantity:number;
    procuredQuantity:number;
}