import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Card, Cascader, Col, Collapse, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch, Table, Tag, TimePicker, TreeSelect } from 'antd';
import './rm-evaluation.css';
import moment from 'moment';
import { GrnData, GrnVehicleInfo } from '@gtpl/shared-models/raw-material-procurement';
import { ApprovalInput, ConditionInput } from '@gtpl/shared-models/common-models';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { RMGrnService, RmVehicleInspectionService, RMEvaluationService } from '@gtpl/shared-services/raw-material-procurement';
import TextArea from 'antd/lib/input/TextArea';
/* eslint-disable-next-line */
export interface RmEvaluationProps {
  grnInfo: GrnData;
  handleCancel: () => void;
  OnClose: () => void;
}
const { Panel } = Collapse;

export function RmEvaluation(
  props: RmEvaluationProps
) {
  const [rmEvaluation] = Form.useForm();
  const [rmEvalSamplesInfo] = Form.useForm();
  const [rmInfo, setRmInfo] = useState<GrnVehicleInfo>();
  const [dataSource, setDataSource] = useState([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const rmGnService = new RMGrnService();
  const viService = new RmVehicleInspectionService();
  const rmeService = new RMEvaluationService();

  useEffect(() => {
    getGrnInfoByItemId();
  }, [])
 console.log( dataSource.length + 1)
  const getGrnInfoByItemId = () => {
    rmGnService.getGrnInfoByItemId({ grnItemId: props.grnInfo.grnItemId }).then(res => {
      if (res.status) {
        setRmInfo(res.data);
        rmEvaluation.setFieldsValue({
          vehicleNumber: res.data?.vehicleNumber,
          varietyOfMaterial: res.data?.varietyOfMaterial,
          supplierAddress: res.data?.supplierAddress,
          vehicleId: res.data.vehicleId?res.data.vehicleId:0,
          productId: res.data?.productId
        })
        rmEvalSamplesInfo.setFieldsValue({
          varietyOfMaterial: res.data?.varietyOfMaterial,
        })
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

    if (props.grnInfo.isRmInspectionCompleted) {
      rmeService.getRmEvaluation({ grnItemId: props.grnInfo.grnItemId }).then(res => {
        if (res.status) {
          setDataSource(res.data.RmEvalSamplesInfo);
          rmEvaluation.setFieldsValue({
            noOfSamplesDrawn: res.data.RmEvalSamplesInfo.length + 1,
            rmEvaluationId: res.data.rmEvaluationId,
          })
          rmEvalSamplesInfo.setFieldsValue({
            sampleNo: res.data.RmEvalSamplesInfo.length + 1,
          })
        } else {
          setDataSource([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        setDataSource([]);
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }
  const columns = [
    {
      title: 'Sample',
      dataIndex: 'sampleNo',
      key: 'sampleNo',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (value) => {
        return moment(value).format("h:m A");
      }
    },
    {
      title: 'Temperature',
      dataIndex: 'temperature',
      key: 'temperature',
    },
    {
      title: 'General Appearance',
      dataIndex: 'generalAppearance',
      key: 'generalAppearance',
    },
    {
      title: 'General Odour',
      dataIndex: 'generalOdour',
      key: 'generalOdour',
    },
    {
      title: 'Freshness',
      dataIndex: 'freshness',
      key: 'freshness',
    },
    {
      title: 'Dehydration',
      dataIndex: 'dehydration',
      key: 'dehydration',
    },
    {
      title: 'Discolouration',
      dataIndex: 'discolouration',
      key: 'discolouration',
    },
    {
      title: 'Deterioration',
      dataIndex: 'deterioration',
      key: 'deterioration',
    },
    {
      title: 'BlackSpot/Tail',
      dataIndex: 'blackSpotOrTail',
      key: 'blackSpotOrTail',
    },
    {
      title: 'Loose Shells',
      dataIndex: 'looseShells',
      key: 'looseShells',
    },
    {
      title: 'Broken Or Damaged',
      dataIndex: 'brokenOrDamagedPcs',
      key: 'brokenOrDamagedPcs',
    },
    {
      title: 'Fungal Growth',
      dataIndex: 'fungalGrowth',
      key: 'fungalGrowth',
    },
    {
      title: 'Soft Species',
      dataIndex: 'softSpecies',
      key: 'softSpecies',
    },
    {
      title: 'Drooping Head',
      dataIndex: 'droopingHead',
      key: 'droopingHead',
    },
    {
      title: 'Foreign Matter',
      dataIndex: 'foreignMatter',
      key: 'foreignMatter',
    },
    {
      title: 'Sulphite Kit',
      dataIndex: 'sulphiteKit',
      key: 'sulphiteKit',
    },
    {
      title: 'Pests Present/Absent',
      dataIndex: 'pests',
      key: 'pests',
    },
    {
      title: 'Muddy Odour',
      dataIndex: 'muddyOdour',
      key: 'muddyOdour',
    },
    {
      title: 'Cooked Texture',
      dataIndex: 'cookedTexture',
      key: 'cookedTexture',
    },
    {
      title: 'Cooked Flavor',
      dataIndex: 'cookedFlavor',
      key: 'cookedFlavor',
    },
    {
      title: 'Parasite',
      dataIndex: 'parasite',
      key: 'parasite',
    }
  ];

  const saveRmEvaluation = () => {
    rmEvaluation.validateFields().then(rmVehicleEval => {
      rmEvalSamplesInfo.validateFields().then(rmVehicleEvalSamples => {
        rmVehicleEval.plantId = Number(localStorage.getItem('unit_id'));
        rmVehicleEval.createdUser = localStorage.getItem('createdUser');
        rmVehicleEval.updatedUser = localStorage.getItem('createdUser');
        rmVehicleEval.batchNumber = props.grnInfo.lotNumber?props.grnInfo.lotNumber:'';
        rmVehicleEval.RmEvalSamplesInfo = (dataSource.length) ? [...dataSource, rmVehicleEvalSamples] : [rmVehicleEvalSamples];
        rmVehicleEval.totalQuantity = props.grnInfo.quantity;
        rmeService.createRmEvaluation(rmVehicleEval).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getGrnInfoByItemId();
            // props.OnClose();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })

      })
    });
  }
  return (

    <><Card size="small" title={<span style={{ color: 'white' }}>Raw Material Evaluation</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
      <Form
        layout="vertical"
        form={rmEvaluation}
      >
        <Row gutter={24}>
          <Descriptions>
            <Descriptions.Item label="Vehicle Number">
              {rmInfo?.vehicleNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Total Qty">
              {props.grnInfo.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Source/Center">
              {rmInfo?.supplierAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Batch Number">
              {props.grnInfo.lotNumber?props.grnInfo.lotNumber:''}
            </Descriptions.Item>
            <Descriptions.Item label="Type / Species">
              {rmInfo?.varietyOfMaterial}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row gutter={24}>
          <Form.Item name={'rmEvaluationId'}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'centerOrSourceCode'} initialValue={props.grnInfo?.farmer}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'grnId'} initialValue={props.grnInfo?.grnId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'grnItemId'} initialValue={props.grnInfo?.grnItemId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'indentId'} initialValue={props.grnInfo?.indentId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'vehicleId'}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'productId'}>
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"arrivalTime"}
              label="Arrival Time"
              rules={[
                {
                  required: true,
                  message: `Missing Date`,
                },
              ]}
              initialValue={moment()}
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"startTimeOfObv"}
              label="Start Time Of Observation"
              rules={[
                {
                  required: true,
                  message: `Missing Start Time Of Observation`,
                },
              ]}
              initialValue={moment()}
            >
              <TimePicker use12Hours format="h:mm a" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
              name={"noOfSamplesDrawn"}
              label="No. Of Samples Drawn"
              rules={[
                {
                  required: true,
                  message: `Missing No. Of Samples Drawn`,
                },
              ]}
              initialValue={dataSource.length + 1}
            >
              <InputNumber min={0} style={{ width: '100%' }} disabled />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item label="Remarks" name={'remarks'} initialValue={ApprovalInput[0].value} rules={[
              {
                required: true,
                message: `Missing Remarks`,
              },
            ]}>
              <TextArea showCount autoSize />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
      <Form
        layout="vertical"
        form={rmEvalSamplesInfo}
      >
        <Collapse>
          <Panel header="Sample Info" key="1" style={{ backgroundColor: '#69c0ff', border: 0 }}>

            <Row gutter={24}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item
                  name={"sampleNo"}
                  label="Sample No"
                  rules={[
                    {
                      required: true,
                      message: `Missing Sample No`,
                    },
                  ]}
                  initialValue={dataSource.length + 1}
                >
                  <InputNumber min={0} style={{ width: '100%' }} disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item
                  name={"time"}
                  label="Start Time"
                  rules={[
                    {
                      required: true,
                      message: `Missing Start Time`,
                    },
                  ]}
                  initialValue={moment()}
                >
                  <TimePicker use12Hours format="h:mm a" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item
                  name={"temperature"}
                  label="Temperature (<4.0 C)"
                  rules={[
                    {
                      required: true,
                      message: `Missing Temperature`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={4} style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="General Appearance" name={'generalAppearance'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing General Appearance`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="General Odour" name={'generalOdour'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing General Odour`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Freshness" name={'freshness'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing Freshness`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item label="Dehydration (5%)" name={'dehydration'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Dehydration (5%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Discolouration (5%)" name={'discolouration'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Discolouration (5%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Deterioration (Nil)" name={'deterioration'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Deterioration (Nil)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Black Spot/Tail (5%)" name={'blackSpotOrTail'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Black Spot/Tail (5%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Loose Shells (6%)" name={'looseShells'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Loose Shells (6%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item label="Broken/Damaged Pcs. (5%)" name={'brokenOrDamagedPcs'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Broken/Damaged Pcs. (5%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Fungal Growth (10%)" name={'fungalGrowth'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Fungal Growth (10%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Soft Species (10%)" name={'softSpecies'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Soft Species (10%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Drooping Head (10%)" name={'droopingHead'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Drooping Head (10%)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Foreign Matter (Nil)" name={'foreignMatter'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Foreign Matter (Nil)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <Form.Item label="Sulphite Kit (No Colour Change)" name={'sulphiteKit'} initialValue={ConditionInput[7].value} rules={[
                  {
                    required: true,
                    message: `Missing Sulphite Kit (No Colour Change)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Pests Present/Absent (Nil)" name={'pests'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Pests Present/Absent (Nil)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Muddy Odour (Nil)" name={'muddyOdour'} initialValue={ConditionInput[3].value} rules={[
                  {
                    required: true,
                    message: `Missing Muddy Odour (Nil)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Cooked Texture" name={'cookedTexture'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing Cooked Texture`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Cooked Flavor" name={'cookedFlavor'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing Cooked Flavor`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                <Form.Item label="Parasite (Nil)" name={'parasite'} initialValue={ConditionInput[0].value} rules={[
                  {
                    required: true,
                    message: `Missing Parasite (Nil)`,
                  },
                ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ConditionInput}
                    placeholder="Please Enter"
                    filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                </Form.Item>
              </Col>
            </Row>

          </Panel>
        </Collapse>
        <h4 style={{ color: 'blue' }}>*Note:&ensp; G -Good, S - Satisfy, N - Nil/absent, SF - Soft & Firm, US - UnSatisfy, NA - Not Applicable, NCC - No Colour Change, Pc - Piece</h4>
        <Row justify="end">
          <Col>
            <Button type='primary' onClick={saveRmEvaluation} block disabled={btnDisable}>
              Submit
            </Button>
          </Col>
        </Row>
        <Row gutter={24} style={{ display: dataSource?.length ? 'block' : 'none' }}>
          <Table dataSource={dataSource} columns={columns} scroll={{ x: true }} />
        </Row>
      </Form></>
  );
}

export default RmEvaluation;
