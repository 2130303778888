import React, { useEffect, useState,useRef } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Select, Tabs , DatePicker, Typography, Alert,} from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {AssetAssignmentService} from '../../../../../../shared-services/asset-management/src/lib/asset-assignment';
import { AssetByCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-code.dto";
import { AssetAssignmentDto } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-assignment.dto";
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import moment from "moment";
import {AssetService} from 'libs/shared-services/asset-management/src/lib/assets.service';
import { AssetDto } from 'libs/shared-models/asset-management/src/lib/open-assets/assets.dto';
import { Excel } from 'antd-table-saveas-excel';
import { AssetByDate } from "libs/shared-models/asset-management/src/lib/asset-assignment/asset-by-date.dto";
import './pages-asset-management-asset-management-components-assets-repair-log.css';
import {DepartmentService} from 'libs/shared-services/masters/src/lib/department-service';
import {ItemSubCategoryService, UnitcodeService} from '@gtpl/shared-services/masters';
import { AssetLocationService } from 'libs/shared-services/asset-management/src/lib/asset-location';
import {EmployeeService} from '../../../../../../shared-services/masters/src/lib/employee-details.service';
import { EmployeeDto } from '@gtpl/shared-models/masters';
import { AssetByAssetCode } from "libs/shared-models/asset-management/src/lib/asset-assignment/get-by-assetCode.dto";
import { LocationByPlant } from "libs/shared-models/asset-management/src/lib/asset-location/location-by-plant.dto";
import {AssetTypeEnum} from 'libs/shared-models/common-models/src/lib/enum/asset-type.enum';

const {Option} = Select;
import Highlighter from 'react-highlight-words';

/* eslint-disable-next-line */
export interface AssetsReportProps {
  viewrow(rowData: any, arg1: boolean): unknown;
}

export const AssetsAssignmentReport = (props: AssetsReportProps) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = useState<number>(null);
  const onSearch = (value: string) => console.log(value);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [assetsData, setAssetsData] = useState<any[]>([])
  const [assetTransData,setAssetTransData] = useState<AssetAssignmentDto[]>([]);
  const service = new AssetAssignmentService();
  const assetService = new AssetService();
 const {RangePicker} = DatePicker;
 const locationService = new AssetLocationService();
 const [locationData,setLocationData] = useState<any[]>([]);
 const plantsService = new UnitcodeService();
 const [plantName,setPlantName]= useState<any>([]);
 const [itemCategory,setItemCategory] = useState([]);
 const itemSubCategoryService = new ItemSubCategoryService();
 const departmentService = new DepartmentService();
 const [depart,setDepart] = useState<any[]>([]);
 const employeeService = new EmployeeService();
 const [employeeData,setEmployeeData] = useState<EmployeeDto[]>([]);
 const role = JSON.parse(localStorage.getItem('role'))
 const { Text } = Typography;
 const [length,setLength]= useState('');
 const [searchText, setSearchText] = useState('');
 const [searchedColumn, setSearchedColumn] = useState('');
 const searchInput = useRef(null);


 var d = new Date();
  var currentMonth = d.getMonth();
  var currentYear = d.getFullYear();
  var startDate = new Date(currentYear,currentMonth,1)
  const dateformat = 'YYYY-MM-DD';

  useEffect(() => {
    //getAllData();
    getAssetCode();
    getAllPlants();
    getItemCategory();
    getDepartments();
    employeeDetails();
     getTransById();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantId: Number(localStorage.getItem('unit_id')) })
    }
    getLocations()
  },[])

  const getDepartments = () => {
    departmentService.getAllActiveDepartment().then(res => {
      if(res.status){
        setDepart(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
  
  const getItemCategory = () => {
    itemSubCategoryService.getGeneralItemSubCategories().then(res => {
      if(res.status) {
        setItemCategory(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  
    const getAllPlants = () => {
      plantsService.getAllPlants().then(res => {
        if(res.status){
          setPlantName(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }
  
    const getLocations = () => {
      const req = new LocationByPlant();
    req.plant = form.getFieldValue('plantId')
      locationService.getAllAssets(req).then(res => {
        if(res.status){
          setLocationData(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }

    const employeeDetails = () => {
      employeeService.getAllActiveEmployees().then((res)=>{
        if(res.status) {
          setEmployeeData(res.data)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      })
    }

  // const getAllData = () => {
  //   service.getAllTrans().then(res => {
  //     if(res.status) {
  //       setAssetTransData(res.data);
  //       // AlertMessages.getSuccessMessage(res.internalMessage)
  //     } else {
  //       AlertMessages.getErrorMessage(res.internalMessage)
  //     }
  //   })
  // }

  const getAssetCode = () => {
    const req = new AssetByAssetCode();
    req.plantId = Number(localStorage.getItem('unit_id'));
    service.getAssignmentTransAssetCodesDropDown(req).then((res) => {
      if(res.status) {
        setAssetsData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getTransById = () => {
    const req = new AssetByAssetCode();
     if(form.getFieldValue('assetsCode') !== undefined){
      req.assetsCode = form.getFieldValue('assetsCode')
    }
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.plantId = Number(localStorage.getItem('unit_id'));
    } else {
      if (form.getFieldValue('plantId') != undefined) {
        req.plantId = form.getFieldValue('plantId')
      }};
    if(form.getFieldValue('location') !== undefined){
      req.location = form.getFieldValue('location')
    }
    if(form.getFieldValue('assetType') !== undefined){
      req.assetType = form.getFieldValue('assetType')
    }
    if(form.getFieldValue('assetCategory') !== undefined){
      req.assetCategory = form.getFieldValue('assetCategory')
    }
    if(form.getFieldValue('department') !== undefined){
      req.department = form.getFieldValue('department')
    }
    if(form.getFieldValue('employeeName') !== undefined){
      req.employeeId = form.getFieldValue('employeeName')
    }
    if(form.getFieldValue('date') != undefined) {
      req.fromDate = moment(form.getFieldValue('date')[0]).format('YYYY-MM-DD');
      req.toDate = moment(form.getFieldValue('date')[1]).format('YYYY-MM-DD');
    }
    service.getTransById(req).then(res => {
      if(res.status) {
        setAssetTransData(res.data);
        setLength(res.data.length);

        if(res.data.length >0){
        AlertMessages.getSuccessMessage('Data retrieved successfully')
        } else {
          AlertMessages.getErrorMessage('Data not found')
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const getDateReport = (req) => {
    service.getByDate(req).then( res => {
      if(res.status) {
        setAssetTransData(res.data)
        // AlertMessages.getSuccessMessage('Data retrieved successfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }

  const searchHandler = () => {
    // if(form.getFieldValue('date') !== undefined) {
    //   const fromDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[0]).format('YYYY-MM-DD') : null;
    //   const toDate = form.getFieldValue('date') ? moment(form.getFieldValue('date')[1]).format('YYYY-MM-DD') : null;
    //   const selectedDate = { fromDate, toDate };
    //   getDateReport(selectedDate);
    // } else {
    //   getTransById();

    // }
    getTransById()
 
}

  const resetHandler = () => {
    form.resetFields();
    getTransById();
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      ) : null
  });

    const columns: ColumnProps<any>[] = [
      {
        title: "S.No",
        key: "sno",
        width: 60,
        responsive: ["sm"],
        render: (text, object, index) => (page - 1) * pageSize + (index + 1)
      },
      {
        title: "Unit",
        dataIndex: "plant",
        width: 130,
        align: "left",
        ...getColumnSearchProps('plant'),
        sorter:(a,b)=>a.plant.localeCompare(b.plant),
        sortDirections:['descend','ascend'],
      },
      {
        title: "Location",
        dataIndex: "location",
        width: 130,
        align: "left",
        sorter:(a,b)=> a.location?.localeCompare(b.location),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('location'),
      },
      {
        title: "Asset Category",
        dataIndex: "assetCategory",
        width: 150,
        align: "left",
        sorter:(a,b)=> a.assetCategory.localeCompare(b.assetCategory),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('assetCategory'),
      },
      {
        title: "Asset Code",
        dataIndex: "assetsCode",
        width: 130,
        align: "left",
        ...getColumnSearchProps('assetsCode')

      },
      
      {
        title: "Asset Mode",
        dataIndex: "assetMode",
        width: 130,
        align: "left",
        sorter:(a,b)=> a.assetMode?.localeCompare(b.assetMode),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('assetMode'),
      },
      {
        title: "Asset Type",
        dataIndex: "assetType",
        width: 130,
        align: "left",
        sorter:(a,b)=> a.assetType?.localeCompare(b.assetType),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('assetType'),
      },
  
      
      {
        title: "Asset Name",
        dataIndex: "itemName",
        width: 130,
        align: "left",
        sorter:(a,b)=> a.itemName?.localeCompare(b.itemName),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('itemName'),
      },
     
      
      {
        title: "Serial Number",
        dataIndex: "assetSerialNo",
        align:"left",
       width:120,
        render:(text,record) => {
          return (
            <>
              {record.assetSerialNo ? record.assetSerialNo : '-'}
            </>
          )
        },
        // ...getColumnSearchProps('currentLocation')
      },
     
      {
        title: "Invoice Number",
        dataIndex: "invoiceNo",
        align:"left",
        width:120,
        render:(text,record) => {
          return (
            <>
              {record.invoiceNo ? record.invoiceNo : '-'}
            </>
          )
        },
        // ...getColumnSearchProps('currentLocation')
      },
     
      
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        width: 130,
        align: "left",
        sorter:(a,b)=> a.employeeName.localeCompare(b.employeeName),
        sortDirections:['descend','ascend'],
        ...getColumnSearchProps('employeeName'),
      },
      {
        title: "Mobile Number",
        dataIndex: "employeePhNum",
        width: 130,
        align: "right",
        render:(data,record) => {
          return (
            <span>
              {record.employeePhNum ? record.employeePhNum: '-'}
            </span>
          )
        }
      },
      {
        title: "Department",
        dataIndex: "department",
        width: 130,
        align: "left"
      },
      {
        title: "Cost of Asset",
        dataIndex: "assetCost",
        width:130,
        align:"right",
        sorter:(a,b)=> a.assetCost.localeCompare(b.assetCost),
        sortDirections:['descend','ascend'],
        render: (text, record) => {
          return (
            <>
              {record.assetCost ? Number(Number(record.assetCost).toFixed(0)).toLocaleString('en-IN') : 'N/A'}
            </>
          )
        }
      },
      // {
      //   title: "Check In ",
      //   dataIndex: "checkIn",
       // width: 150,
      //   align: "right",
      //   render:(text,record) => {
      //     const obj: any = {
      //       children: (<div>{record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD'):'-'}</div>),
      //       props:{
      //         style:{background:record.checkOut == null ? "#FFFF00":""}
      //       }
      //     }
      //     return obj
      //   //   return (
      //   //     <span>
      //   // {/* rowClassName={(record) => record.checkOut == null ? 'highlighted-row': ''} */}

      //   //       {record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD') : '-'}
      //   //     </span>
      //   //   )

      
      //   }
      // },
      
      {
        title: "Status",
        dataIndex: "status",
        width: 130,
        align: "left",
       
        filters: [
          {
            text: 'CHECKIN',
            value: 'CHECKIN',
          },
  
          {
            text: 'CHECKOUT',
            value: 'CHECKOUT',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          // === is not work
          return record.status == value;
         
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 150,
        align: "right",
        render:(data,record) => {
          return (
            <span>
              {record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD') : moment(record.checkOut).format('YYYY-MM-DD')}
            </span>
          )
        }
      },
    ];
   
  
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const exportedData = [];
  const excelData = assetTransData
  let i = 1;
  const data = [
    { title: 'S No', dataIndex: 'sNo', render: (text, object, index) => { return i++; } },
    {title : 'Unit', dataIndex:'plant',render:(text,record) => {return record.plant ? record.plant : '-'}},
    {title:'Location',dataIndex:'location',render:(text,record) => {return record.location ? record.location : '-'}},
    // {title:'Asset Type',dataIndex:'assetType',render:(text,record) => {return record.assetType ? record.assetType : '-'}},
     {title:'Asset Category',dataIndex:'assetCategory',render:(text,record) => {return record.assetCategory ? record.assetCategory : 'employeePhNum-'}},
    { title: 'Asset Code', dataIndex: 'assetsCode',render:(text,record) => {return record.assetsCode ? record.assetsCode : '-'} },
    { title: "Asset Mode", dataIndex: "assetMode",render:(text,record) => {return record.assetMode ? record.assetMode : '-'}},
    {title:'Asset Type',dataIndex:'assetType',render:(text,record) => {return record.assetType ? record.assetType : '-'}},
    { title: 'Asset Name', dataIndex: 'itemName',render:(text,record) => {return record.itemName ? record.itemName : '-'} },
    // { title: "Asset Mode", dataIndex: "assetMode",render:(text,record) => {return record.assetMode ? record.assetMode : '-'}},
    {title:'Serial Number',dataIndex:'assetSerialNo',render:(text,record) => {return record.assetSerialNo ? record.assetSerialNo : '-'}},
    {title:'Invoice Number',dataIndex:'invoiceNo',render:(text,record) => {return record.invoiceNo ? record.invoiceNo : '-'}},
    {title:'Serial Number',dataIndex:'assetSerialNo',render:(text,record) => {return record.assetSerialNo ? record.assetSerialNo : '-'}},
    {title: 'Employee name', dataIndex: 'employeeName',render:(text,record) => {return record.employeeName ? record.employeeName : '-'}},
    {title: 'Mobile Number', dataIndex: 'employeePhNum',render:(text,record) => {return record.employeePhNum ? record.employeePhNum : '-'}},
    {title:'Department',dataIndex:'department',render:(text,record) => {return record.department ? record.department : '-'}},
    {title:'Cost ofAsset',dataIndex:'assetCost',render:(text,record) => {return record.assetCost ? record.assetCost : '-'}},
    { title: 'Status', dataIndex: 'status', render: (text, record) => { return record.status ? record.status : '-'} },
    { title: 'Date', dataIndex: 'date', render: (text, record) => { return record.checkIn ? moment(record.checkIn).format('YYYY-MM-DD') : moment(record.checkOut).format('YYYY-MM-DD') } },
  ];


  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('CheckIn-report')
      .addColumns(data)
      .addDataSource(assetTransData, { str2num: true })
      .saveAs('Assets Assignment Report.xlsx');
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Asset Assignment Report</span>}
    style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} 
    // extra = {<Button onClick={() => {exportExcel()}}>Get Excel</Button>}
    extra={assetTransData.length > 0 ? (
      <>
        <Button className='panel_button' onClick={() => exportExcel()}>Get Excel</Button>
      </>
    ) : (<></>)}
  >
      <br/><br/>
      <Form form={form} layout={'vertical'} style={{ padding: '0px' }}>
        <Row gutter={[24,24]}>
          <Col
           xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="date" label= 'Date'>
              <RangePicker 
                // defaultValue={[
                //   moment(startDate,dateformat),
                //   moment(new Date(),dateformat)
                // ]}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item name="assetsCode" label='Asset Code'>
            <Select 
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder='Asset Code'
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assets_code} value={assets.assets_code}>{`${assets.assets_name} -- ${assets.assets_code}`}</Option>
            })

            }
          </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Asset Category' name='assetCategory' >
          <Select
              //  defaultValue={'All'}
              placeholder='All Categories'
               showSearch
               //onChange={getItems}
               optionFilterProp="children"
               filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
               allowClear>
              <Option key={'all'} value={''}>All Categories</Option>
            {itemCategory.map((items) => {
              return <Option key={items.itemSubCategoryId} value={items.itemSubCategory}>{items.itemSubCategory}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
            <Col span={4}>
          <Form.Item label='Unit' name='plantId' >
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
            // defaultValue={role == 'SUPERADMIN' ? 'all' : Number(localStorage.getItem('unit_id'))}
           placeholder='All Units'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Units</Option>
            {plantName.map((plant) => {
              return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Location' name='location'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Locations'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Locations</Option>
            {locationData.map((location) => {
              return (
              <Option key={location.locationName} value={location.locationName}>{location.locationName}</Option>
              )
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label='Department' name='department'>
          <Select
             optionFilterProp="children"
             filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // defaultValue={'All'}
            placeholder='All Departments'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Departments</Option>
            {depart.map((e) => {
              return<Option key={e.deptName} value={e.deptName}>{e.deptName}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={5}>
          <Form.Item label='Employee Name - Code' name='employeeName'>
          <Select
             optionFilterProp="children"
            //  filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            // defaultValue={'All'}
            placeholder='All Employees'
            showSearch
            allowClear>
              <Option key={'all'} value={''}>All Employees</Option>
              {employeeData.map((employee) => {
              return <Option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName} - {employee.employeeId}</Option>
            })}
           </Select>
          </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item label='Asset Type' name='assetType'>
          <Select 
              // placeholder='assetType'
              showSearch
              defaultValue={'MOVABLE'}
              //onChange={onStatusSearch}
              allowClear
            >
              <Option key='all' value=''>All</Option>
              {Object.values(AssetTypeEnum).map((i) => {
                return (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          </Col>
          <Col  style={{marginTop:'2.5%'}}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              // size="small"
              block
              onClick={() => searchHandler()}
              // style={{ marginRight: 2, width: 100 }}
            >
              Search
            </Button>
          </Col>
          <Col  style={{marginTop:'2.5%'}}>
            <Button
              icon={<UndoOutlined />}
              onClick={resetHandler}
            >
              Reset
            </Button>
          </Col>
          <br></br>
        </Row>
        <Col span={3} style={{marginLeft:'85%',paddingBottom:'1px'}}>
            <Button >Total no of Records:{length}
            </Button>
            </Col>
      </Form>
    {/* <Form layout="horizontal" form ={form} onFinish={searchHandler}>
      <Row style={{ marginLeft: '1%' }}>
      <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%'}}>
          <Form.Item name="assetsCode" label="Asset Code">
          <Select 
            showSearch
            optionFilterProp="children"
            // onChange={onCodeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {assetsData.map((assets) => {
              return <Option key={assets.assetsCode} value={assets.assetsCode}>{`${assets.assetsCode} -- ${assets.assetsName}`}</Option>
            })

            }
          </Select>
          </Form.Item>
        </Col>
        <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%',marginLeft:'2%'}}>
        
            <Space direction="vertical" size={12}>
           <RangePicker />
           </Space>
          
          </Col>
          <Col   xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }} style={{marginTop:'1%'}}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            style={{ marginRight: 2, width: 100 }}
          >
            Search
          </Button>

          <Button
          onClick={() => getAllData()}
            icon={<UndoOutlined />}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
   */}
   {assetTransData.length >0 ?(<><Table
        columns={columns}
        scroll={{ x: 400 ,y:400}}
        dataSource={assetTransData}
        pagination={{
          onChange(current, pageSize) {
            setPage(current);
            setPageSize(pageSize)
          }
        }}
        bordered
        summary={(pageData) => {
          let totalCost = 0;
          

          pageData.forEach(({assetCost}) => {
            if(Number(assetCost)) {
              totalCost += Number(assetCost)
            }
          })

          

          return(
            <>
            <Table.Summary.Row className="tableFooter">
              <Table.Summary.Cell index={1} colSpan={14}><Text>Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={10}><Text style={{textAlign:'end'}}>{Number(totalCost).toLocaleString('en-IN')}</Text></Table.Summary.Cell>
              
            </Table.Summary.Row>
            </>
          )
        }}
       // // rowClassName={(record) => record.checkOut == null ? 'highlighted-row': ''}
       
        onChange={onChange}
  
      />

   </>):(
     <Alert
     message="No Data Found"
     type="info"
     showIcon
   />
   )}
        </Card>

  );
};
export default AssetsAssignmentReport;
