import { EmployeeRolesEnum } from "@gtpl/shared-models/common-models";


export class ReturnReq {
    unitId: number;
    role: EmployeeRolesEnum;
    storeConsumptionCode: string;
    saleOrder: string;
    itemName:string;
    returnReasion:string;
    approvar:string;
    itemCtegory:string;
    remarks:string;
    selectedCreatedFromDate?:any;
    selectedCreatedToDate?:any;
    createdDate?:Date;
    poNumber?:string;
    itemSubCategoryId?:string;
    itemId?:number;
}