import { GlobalResponseObject } from "@gtpl/shared-models/common-models";

export class AssigningStockResponse extends GlobalResponseObject{
    data?: {profitReproceeQty:number,lossReproceeQty:number}

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data 
     */
    constructor(status:boolean, errorCode:number, internalMessage:string, data?: {profitReproceeQty:number,lossReproceeQty:number}){
        super(status,errorCode,internalMessage);
        this.data = data;
    }
}