import React, { useEffect, useState } from 'react';

import './material-preperation-form.css';
import { Divider, Table,Select, Popconfirm, Card, Tooltip, Switch, Input, Button, Tag, Row, Col, Drawer, Tabs, Form, DatePicker, Modal, Space, Badge, InputNumber } from 'antd';
import { SaleOrderDropDownDto,SaleOrderItemsDTO,ItemsDetails, SODto } from '@gtpl/shared-models/sale-management';
import {ItemCodeRequest, ItemRequest, ItemsDropDownDto, ItemsDto} from '@gtpl/shared-models/masters'
import { SaleOrderService } from '@gtpl/shared-services/sale-management'
const { Option } = Select;
import { QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@gtpl/shared-components/qr-scanner'
import { GlobalStatus, UnitRequest } from '@gtpl/shared-models/common-models';
import { ItemsService } from '@gtpl/shared-services/masters';
import { Link, Redirect,useHistory } from 'react-router-dom';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { StockService, StoreConsumptionService } from '../../../../../../shared-services/procurement/src';
import { ItemBarcodeRequest, MaterialPreperationRequest, SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';



/* eslint-disable-next-line */
export interface MaterialPreperationFormProps {}

export function MaterialPreperationForm(
  props: MaterialPreperationFormProps
) {
  let history = useHistory();
  const [saleOrderData, setSaleOrderData] = useState<any[]>([])
  const [saleOrderItemsData, setSaleOrderItemsData] = useState<ItemsDetails[]>([])
  const [saleOrderItemDetails, setSaleOrderItemDetails] = useState<SaleOrderItemsDTO>(undefined)
  const [itemDetails, setItemDetails] = useState<ItemsDto>(undefined)
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [itemName, setItemName] = useState<ItemsDto>(undefined)
  const [showQrSacn, setShowQrScan] = useState<boolean>(false);
  const [itemDisable, setItemDisable] = useState<boolean>(false);
  const [modal, setModal] = useState('')
  const [availQty, setAvailQty] = useState<number>(0)
  const [selSubCategory, setselSubCategory] = useState<number>()
  const [itemsData, setItemsData] = useState<ItemsDropDownDto[]>([])
  const [existingPreparedQty, setExistingPreparedQty] = useState<number>(0)
  const [materialPreperationReq, setMaterialPreperationReq] = useState<MaterialPreperationRequest>(undefined)
  const stockService = new StockService();
  const saleOrderService = new SaleOrderService();
  const itemService = new ItemsService();
  const [form] = Form.useForm();
  useEffect(() => {
    // getItemsData()
    getSaleOrderData(null,undefined);
  }, []);
  const getItemsData = (soId) => {
    console.log(JSON.parse(localStorage.getItem('unit_id')))
    stockService.getavailbleItemsInStock(new SaleOrderIdRequest(soId,JSON.parse(localStorage.getItem('unit_id')),null,'',GlobalStatus.YES)).then((res) => {
        if (res.status) {
            console.log(res.data);
            setItemsData(res.data);
        } else {
            if (res.intlCode) {
                AlertMessages.getErrorMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
            setItemsData([]);
        }
    }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setItemsData([]);
    });
  }
  const getSaleOrderData = (itemId,rowObj) => {
    console.log(rowObj)
    // form.setFieldsValue({itemName:rowObj.children})
    const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unitId')), JSON.parse(localStorage.getItem('role')));
    const itemsReq = new ItemRequest(itemId,JSON.parse(localStorage.getItem('unit_id')));
    stockService.getSOAndSoitemCombData(itemsReq).then((res) => {
      if (res.status) {
        console.log(res.data);
        setSaleOrderData(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }

  const getPreparedQty = (soId) => {
    const barcodereq = new ItemBarcodeRequest(JSON.parse(localStorage.getItem('unit_id')),form.getFieldValue('itemBarcode'))
    stockService.getPreparedMaterialForItem(barcodereq).then((res) => {
      if (res.status) {
        console.log(res);
        setExistingPreparedQty(res.data)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
    
  }
  const getStockQuantity = (itemId,rowObj) => {
    setselSubCategory(rowObj.subCategoryId)
    console.log(localStorage.getItem('unit_id'))
    form.setFieldsValue({itemName:rowObj.children})
    const itemReq = new ItemRequest(itemId,JSON.parse(localStorage.getItem('unit_id')),form.getFieldValue('saleOrderId'));
    stockService.getNoLocationPhysicalQuantity(itemReq).then((res) => {
      if (res.status) {
        console.log(res.data);
        setAvailQty((res.data)[0].physicalQuantity)
        form.setFieldsValue({stockId:(res.data)[0].stockId})
        getPreparedQty(form.getFieldValue('saleOrderId'))
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const handleSaleOrder = (value,rowObj) => {
    console.log(rowObj)
    form.setFieldsValue({poNumber:rowObj.poNumber})
    getSaleOrderItems(value); // items dropdown
  }
  const getSaleOrderItems = (reqData) => {
    // console.log("item details service");
    setSaleOrderItemDetails(undefined);
    setSaleOrderItemsData([]);
    saleOrderService.getSaleOrderItemsForSoId({ saleOrderId: reqData }).then(res => {
      if (res.status) {
        // console.log(res.data.length);
        setSaleOrderItemsData(res.data);
        if(res.data.length === 1){
          form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId });
          form.setFieldsValue({ soItemName: res.data[0].varient });
        }
        else{
          form.setFieldsValue({ saleOrderItemId: null });
        }
        getStockQuantity(reqData,undefined)
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemsData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemsData([]);
    })
  }
  const getSaleOrderItemDetails = (reqData) => {
    // console.log(saleOrderId)
    // console.log(reqData)
    // setSaleOrderItemDetails(undefined);
    saleOrderService.getSaleOrderItemDetails({ saleOrderId: form.getFieldValue("saleOrderId"), saleOrderItemId: reqData, stockType: "SaleOrder" }).then(res => {
      if (res.status) {
        // console.log(res.data);
        setSaleOrderItemDetails(res.data);
      
        // getcheckPalletCapacity(res.data,form.getFieldValue("stockStatus"),res.data.varientId);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSaleOrderItemDetails(undefined);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderItemDetails(undefined);
    })
  }

  const handleQrScan = (value) => {
    setShowQrScan(false)
    form.setFieldsValue({
      itemBarcode: value
    })
    // getPackingMaterialInfo(value);

  }
  const checkStock = (itemId) => {
    const itemIdReq = new ItemRequest(itemId,JSON.parse(localStorage.getItem('unit_id')));
    stockService.getStockDetailsForItemId(itemIdReq).then((res) => {
      if (res.status) {
        console.log(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage('stock Not available for this item');
        } else {
          AlertMessages.getErrorMessage('stock Not available for this item');
        }
        setSaleOrderData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setSaleOrderData([]);
    });
  }
  const getItem = (e) => {
    if(e.target.value != ''){
      setItemDisable(true)
      console.log(e)
      console.log(e.target.value)
      const unitIdReq = new UnitRequest(JSON.parse(localStorage.getItem('unitId')), JSON.parse(localStorage.getItem('role')));
      itemService.getTotalItemDataByCode(new ItemCodeRequest(e.target.value,JSON.parse(localStorage.getItem('unit_id')))).then(res => {
        if (res.status) {
          form.setFieldsValue({itemId:res.data.itemId})
          // form.setFieldsValue({itemId:res.data.itemId})
          const itemObj = {children: res.data.itemName,
            key:null,
            value: res.data.itemId}
          // getSaleOrderData(res.data.itemId,itemObj)
          // checkStock(res.data.itemId)
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
          setItemDetails(undefined);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setItemDetails(undefined);
      })
    }else{
      setItemDisable(false)
      // form.resetFields()
      // setSaleOrderData([])
    }

  }
  const saveData = (values:MaterialPreperationRequest) => {
    setBtnDisable(true);
    form.validateFields().then(res => {
      console.log(values)
      if(values.preparedQuantity > (availQty-existingPreparedQty)){
        AlertMessages.getErrorMessage('should not exceed stock Quantity');
      setBtnDisable(false);
      }else{
        console.log(values)
        console.log(localStorage.getItem('createdUser'))
        values.createdUser = localStorage.getItem('createdUser')
        values.unitId = JSON.parse(localStorage.getItem('unit_id'))
        values.subCategoryId = selSubCategory
        stockService.createMaterilaPreperation(values).then((res) => {
          if (res.status) {
            console.log(res.data);
            AlertMessages.getSuccessMessage(res.internalMessage);
            history.push("/material-preperation-grid");
            // setBtnDisable(false);
            // form.resetFields()
            // setAvailQty(0)
            // setExistingPreparedQty(0)
          } else {
            if (res.intlCode) {
              AlertMessages.getErrorMessage(res.internalMessage);
              setBtnDisable(false);
    
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
              setBtnDisable(false);
    
            }
            setSaleOrderData([]);
          }
        }).catch((err) => {
          AlertMessages.getErrorMessage(err.message);
          setSaleOrderData([]);
        });
      }
    })

    // setBtnDisable(true)
  }

  const saveSOItem = (value,row) => {
    console.log(row)
    form.setFieldsValue({soItemName:row.soItemName})
    form.setFieldsValue({saleOrderId:row.saleOrderId})
    form.setFieldsValue({soItemBarcode:row.soItemBarcode})
    form.setFieldsValue({brandId:row.record.masterBrandId})
    form.setFieldsValue({variantId:row.record.variantId})
    form.setFieldsValue({endCustomerId:row.record.endCustomerId})
    form.setFieldsValue({packStyleId:row.record.packStyleId})
    getItemsData(row.saleOrderId);
  }
  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }
  return (
   <>
   <Card title={<span style={{ color: 'white' }}>Material Preperation</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<Link to='/material-preperation-grid' ><span style={{ color: 'white' }} ><Button className='panel_button' >View </Button> </span></Link>} >
   <Form layout="vertical" form={form} name="control-hooks" onFinish={saveData}>
   <Form.Item name="itemName" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="poNumber" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="soItemBarcode" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="saleOrderId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="soItemName" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="createdUser" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="stockId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="brandId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="variantId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="endCustomerId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="packStyleId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item name="subCategoryId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
    <Row gutter={24}>
          <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:12}} xl={{span:12}}>
                  <Form.Item
                    name="saleOrderItemId"
                    label="SO Item"
                    rules={[
                      {
                        required: true,
                        message: 'SO Item Required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Sale Order Item"
                      showSearch
                      onChange={saveSOItem}
                      // optionFilterProp="children"
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children.toLoitemwerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                      allowClear
                    >
                      {/* <Option value={null}>select SO Item</Option> */}
                      {saleOrderData.map(saleOrderDropDown => {
                        return <Option record={saleOrderDropDown} soItemBarcode = {saleOrderDropDown.barcode} saleOrderId = {saleOrderDropDown.saleOrderId} soItemName = {saleOrderDropDown.productDesc} value={saleOrderDropDown.saleOrderItemId}>{saleOrderDropDown.productDesc}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
      
        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} >
            <Form.Item hidden name="saleOrderId" label="SO Number" rules={[{ required: true, message: "SO Number is required" }]}>
              <Input/> */}
              {/* <Select
                showSearch
                onChange={handleSaleOrder}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO Number"
                allowClear
              >
                {saleOrderData.map(dropData => {
                  return <Option poNumber = {dropData.poNumber} value={dropData.saleOrderId}>{`${dropData.saleOrderNumber} - ${dropData.poNumber}`}</Option>
                })}
              </Select> */}
            {/* </Form.Item> */}
          {/* </Col> */}
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} > */}
          
          {/* </Col> */}
          
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item
                name="itemBarcode"
                label="Scan/Enter item Barcode"
                // rules={[
                //   {
                //     required:true,
                //     message: 'barcode must be required'
                //   },
                
                // ]}
              >

                <Input 
                    onChange={(e) => getItem(e)}
                 suffix={<QrcodeOutlined onClick={(e) => { setShowQrScan(true); setModal('QRScan') }} style={{fontSize:'28px', marginTop:'3px',marginRight:'-6px'}}/>}/>
            </Form.Item>
            </Col>
            <Card style={{width: '100px',marginTop: '10px'}}>
            <p>Stock:</p>
            <p style={{ color:'red'}}>{availQty}</p>
            <p>Prepared Qty</p>
            <p style={{ color:'red'}}>{existingPreparedQty}</p>
          </Card>
      {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
            <Form.Item
              name="itemBarcode"
              label="Scan/Enter Item Code"
              rules={[
                {
                  required: true,
                  message: 'Item Code Required',
                },
              ]}>
              <Input onChange={(e)=>getItem(e)}/>
            </Form.Item>
        </Col> */}

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} >
            <Form.Item
              name="itemId"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: 'Item Code Required',
                },
              ]}>
              <Select
                      placeholder="Select Item"
                      allowClear
                      showSearch
                      onChange={(e,val)=>getStockQuantity(e,val)}
                      disabled = {itemDisable}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={null}>select Item</Option>
                      {itemsData?.map(itemDropDown => {
                        return <Option subCategoryId = {itemDropDown.itemSubCategoryId} value={itemDropDown.itemId}>{itemDropDown.itemName}</Option>
                      })}
                    </Select>
            </Form.Item>
        </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} >
                {/* <label><span style={{ color: 'red' }}>Available Qty :{Number(availQty)} Existing PreparedQty: {existingPreparedQty}</span><span style={{ color: 'red' }}>*</span>Prepared Quantity: </label> */}
            <Form.Item
              name="preparedQuantity"
              label="Prepared Quantity"
              rules={[
                {
                  required: true,
                  message: 'Quantity Required',
                },
              ]}>
              <InputNumber style={{ width: "100%" }} min={1}/>
            </Form.Item>
        </Col>
        <Col span={4}>
            <Form.Item >
            <br />
            <Button type='primary' htmlType='submit' block 
            disabled={btnDisable}
            >
              Submit  
            </Button>
          </Form.Item>
        </Col>

    </Row>
    </Form>
    <Modal
        className='qr-container'
        key={'modal' + Date.now()}
        width={'95%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={showQrSacn}
        onCancel={() => { setShowQrScan(false) }}
        title={<React.Fragment>
        </React.Fragment>}
      >
        {
          modal == 'QRScan' ? <QrScanner handleScan={handleQrScan} /> : null
        }

      </Modal>
    </Card>
   </>
  );
}

export default MaterialPreperationForm;
