import { SequenceDataModel } from "./sequence-data.model";

export class VarientOperationsDTO {
    variantId: number;
    variantCode: string;
    sequenceData:SequenceDataModel[]

    /**
     * 
     * @param variantId 
     * @param variantCode 
     * @param sequenceData
     */

    constructor(
        variantId: number,variantCode:string,sequenceData:SequenceDataModel[]) {
        this.variantId = variantId;
        this.variantCode = variantCode;
        this.sequenceData = sequenceData;
    }
}

