import { WorkCalenderDto, WorkCalenderResponse } from "@gtpl/shared-models/hrms";
import axios from "axios";
import connection from "./connection";

export class WorkCalenderService{

    URL = connection.DEPLOY_URL + '/workcalender';

    async createWorkCalender(workcalenderdto: WorkCalenderDto ): Promise<WorkCalenderResponse> {
        console.log(workcalenderdto);
       
        return await axios.post(this.URL + '/createWorkCalender',workcalenderdto)
            .then(res => {
                return res.data
            })
    }

    async getAllWorkCalender(): Promise<WorkCalenderResponse> {
                
        return await axios.post(this.URL + '/getAllWorkCalender')
            .then(res => {
                return res.data 
            })     
    }

    async  updateWorkCalender(workcalenderdto: WorkCalenderDto): Promise<WorkCalenderResponse> {
        return await axios.post(this.URL + '/updateWorkCalender', workcalenderdto)
                        .then(res => {
                            return res.data
                        })
                }
    
    async  activateOrDeactivateWorkCalender(workcalenderDto: WorkCalenderDto): Promise<WorkCalenderResponse> {
        //   console.log(State.stateCode);
        return await axios.post(this.URL + '/activateOrDeactivateWorkCalender', workcalenderDto)
                        .then(res => {
                                return res.data
                        })
                }
}