export class AssignSoModel {
    plantId: number;
    lotNo: string;
    soNumber: number;
    soItemNumber: number;
    count: number;
    quantity: number;
    createdUser: string;
    plannedSoId?: number;
    constructor(plantId: number,lotNo: string,soNumber: number,soItemNumber: number,count: number,quantity: number,createdUser: string,plannedSoId?: number){
        this.plantId = plantId;
        this.lotNo = lotNo;
        this.soNumber = soNumber;
        this.soItemNumber = soItemNumber;
        this.count = count;
        this.quantity = quantity;
        this.createdUser = createdUser;
        this.plannedSoId = plannedSoId;
    }
}