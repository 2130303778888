import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, FormInstance, Input, Row, Select, Tag } from 'antd';
import './packing-stock-transfer.css';
import { PackingStockTransferMain } from './packing-stock-transfer-detail-from';
import { ItemCategoryDropDownDto, PlantsDropDown } from '@gtpl/shared-models/masters';
import { UnitcodeService } from "@gtpl/shared-services/masters";
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, Route, useHistory, useLocation } from "react-router-dom";

import PackingStockTransferItems from './packing-stock-transfer-items';
import { IndentformService, StockService, TransferMaterialService } from '@gtpl/shared-services/procurement'
import { IsPreparedMaterial, UnitRequest } from '@gtpl/shared-models/common-models';
import { SaleOrderDropDownDto } from '@gtpl/shared-models/sale-management';
import { IndentMaterialDropDownDto, SaleOrderIdRequest } from '@gtpl/shared-models/procurement-management';
import { validate } from 'class-validator';
/* eslint-disable-next-line */
export interface PackingStockTransferProps { }


export function PackingStockTransfer(
  props: PackingStockTransferProps
) {
  let history = useHistory();

  const [stockTransferForm] = Form.useForm<any>();
  const [stockTransferItemsForm] = Form.useForm<any>();
  const [stKey, setStKey] = useState<number>(0);
  const [stItemKey, setStItemKey] = useState<number>(1);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [plantInfo, setPlantInfo] = useState<PlantsDropDown[]>([]);
  const [indentsInfo, setIndentsInfo] = useState<IndentMaterialDropDownDto[]>([]);
  const [indentsItemsInfo, setIndentsItemsInfo] = useState<any[]>([]);
  const [fromPlant, setFromPlant] = useState<string>('');
  const [isSo, setIsSo] = useState<boolean>(false);
  const [fromIsSo, setFromIsSo] = useState<number>(0);
  const [isPreparedMaterialStatus, setIsPreparedMaterialStatus] = useState<boolean>(false);
  const [jobTypee, setJobTypee] = useState<string>(undefined);
  const [itemCategories, setItemCategories] = useState<ItemCategoryDropDownDto[]>([]);
  const [stItems, setStItems] = useState<any[]>([]);
  const [availableQty, setAvailableQty] = useState<number>(0);
  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  


  const unitService = new UnitcodeService();
  const indentformService = new IndentformService();
  const skService = new StockService();
  const tMService = new TransferMaterialService();

  let location = useLocation();
  console.log(location.state)
  let indentDetails:number =0;
  let so:number = 0
  let indentAgainst:string = undefined

  if(location.state != undefined){
    indentDetails = Number(location?.state['indentId']) >0? Number(location?.state['indentId']): 0;
    indentAgainst = location?.state['indentAgainst'] != undefined? location?.state['indentAgainst']: undefined;
    so = Number(location?.state['so']) >0? Number(location?.state['so']): 0;
  }

  console.log(indentDetails);
  console.log(so);
  console.log(indentAgainst);

  const isSoTransfer = (type,type1) => {
    setIsSo(type)
    setFromIsSo(type1)

  }
  const isPreparedMaterial = (type) => {
    setIsPreparedMaterialStatus(type)
  }
  const jobType = (type) => {
    console.log(type);
    setJobTypee(type)
  }

  const getIndentItems = (value) => {
    console.log(value)
    indentformService.getIndentItemDetailsById({indentMaterialPreparationId:value}).then((res) => {
      if(res.status){
        console.log(res.data)
        setStItems(res.data)
        setIndentsItemsInfo(res.data);
        } else {
        setStItems([])
          setIndentsItemsInfo([]);
        }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setStItems([])
      setIndentsItemsInfo([]);
    });
  }
  const getAllPlants = () => {
    unitService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantInfo(res.data);
        res.data.map((plant) => {
          if (plant.plantId == Number(localStorage.getItem('unit_id'))) {
            setFromPlant(plant.plantName);
          }
        })
      } else {
        setPlantInfo([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantInfo([]);
    });
  }
  const getIndents = () => {
    indentformService.getIndentsDropDown({unitId:Number(localStorage.getItem('unit_id'))}).then((res) => {
      if (res.status) {
        setIndentsInfo(res.data);
      } else {
        setIndentsInfo([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setIndentsInfo([]);
    });
  }

  useEffect(() => {
    getAllPlants();
    getIndents();
    getItemCategories();
  }, []);
  
  useEffect(()=> {
    if(stItems.length > 0){
      console.log(stItems)
    }
  },[stItems])
  const getItemCategories = () => {
    const req = new UnitRequest(Number(localStorage.getItem('unit_id')));
    skService.getAvailableStockItemCategories(req).then((res) => {
      if (res.status) {
        setItemCategories(res.data);

      } else {
        setItemCategories([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setItemCategories([]);
    });
  }


  const setTransferType = (val) => {
    console.log("setTransferType")
    setFormDisable(val);
  }
  const getSaleOrderItems = () => {
    console.log(stockTransferForm);
    stockTransferItemsForm.resetFields();
    setStItems([]);
    setAvailableQty(0);
    let getToSoId;
    let getFromSoId;
    let getSoItemId;
    console.log(stockTransferForm.getFieldValue('isPreparedMaterial'));
    console.log(stockTransferForm.getFieldValue('transferType'));
    console.log(stockTransferForm.getFieldValue('fromSaleOrderId'));
    console.log(stockTransferForm.getFieldValue('saleOrderId'));
    getFromSoId = stockTransferForm.getFieldValue('fromSaleOrderId');
    getToSoId = stockTransferForm.getFieldValue('saleOrderId');
    getSoItemId = stockTransferForm.getFieldValue('saleOrderItemId');

    if (getToSoId && jobTypee != undefined) {
      stockTransferItemsForm.resetFields();
      setStItems([]);
      setAvailableQty(0);
      console.log(jobTypee);
      const req = new SaleOrderIdRequest(getToSoId, Number(localStorage.getItem('unit_id')),0,"StockTransfer",stockTransferForm.getFieldValue('isPreparedMaterial'),getFromSoId,jobTypee,getSoItemId);
      tMService.getSaleOrderRequiredItemDetails(req).then((res) => {
        if (res.status) {
          setStItems(res.data);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setStItems([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setStItems([]);
      });
    } {
      stockTransferItemsForm.resetFields();
      setStItems([]);
      setAvailableQty(0);
    }

  }

  const saveStockTransfer = () => {
    stockTransferForm.validateFields().then((formInfo) => {
      console.log(formInfo)
      console.log(stItems);
      let stockItems = stItems.filter((res) => Number(res.availableQty) > 0);
      console.log(stItems);
      console.log("stockItems");
      console.log(stockItems);

      if (stockItems && stockItems.length) {
        setDisabled(true);
        formInfo.fromPlant = Number(localStorage.getItem('unit_id'));
        formInfo.createdUser = localStorage.getItem('createdUser');
        formInfo.stockTransferItems = stockItems;
        console.log(formInfo);
        formInfo?.stockTransferItems.forEach(res1 => {
          console.log(res1.locationStockInfo);
          let stockIds:any = '';
          let issQty:number = 0;
          res1?.locationStockInfo?.filter((t) => t.issuedQty > 0 && t.isChecked > 0)?.forEach(res2 => {
            console.log(res2);
            stockIds = res2.stockId;
            issQty = Number(issQty) + Number(res2.issuedQty);
            console.log(issQty)
          })
          console.log(issQty);
          console.log(stockIds);
          res1.stockIds = stockIds;
          res1.issuedQty = issQty;
          res1.locationStockInfo = res1?.locationStockInfo?.filter((t) => t.issuedQty > 0 && t.isChecked > 0);
        });
        console.log(formInfo);

        tMService.createTransferMaterial(formInfo).then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            history.push('./stock-transfer-grid');
          } else {
            AlertMessages.getErrorMessage(res.message);
          }
          setDisabled(false);
        }).catch((err) => {
          setDisabled(false);
          AlertMessages.getErrorMessage(err.message);
        });
      } else {
        AlertMessages.getErrorMessage("Please Select Items");
      }
    })
  }

  const addItems = (items) => {
    setStItems(items);
    setStItemKey(stItemKey + 1)
  }
  return (
    <Card size="small" title={<span style={{ color: 'white' }}>Stock Transfer</span>}
      style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<><Tag style={{ backgroundColor: '#f6e552', color: "black", fontWeight: 'bold' }}>{fromPlant}</Tag><Divider type="vertical" /><Link to='/stock-transfer-grid'><span style={{ color: 'white' }}><Button className='panel_button'>View </Button> </span></Link></>}>
      <PackingStockTransferMain key={stKey} indentData= {indentsInfo} plantInfo={plantInfo} itemCategories={itemCategories} form={stockTransferForm} getSaleOrderItems={getSaleOrderItems} getIndentItems = {getIndentItems} isSoTransfer={isSoTransfer} indentItemsData = {indentsItemsInfo} setTransferType = {setTransferType} indentId = {indentDetails} indentAgainst = {indentAgainst} itemCategoriesDropDown= {getItemCategories} so={so} isPreparedMaterial={isPreparedMaterial} job_type = {jobType}/>
      <PackingStockTransferItems key={stItemKey} itemCategories={itemCategories} form={stockTransferItemsForm} stItems={stItems} addItems={addItems} availQty={availableQty} isSo={isSo} fromSO={fromIsSo} isFormDisable = {formDisable} buttonDisable = {submitDisabled} isPreparedMaterialStatus={isPreparedMaterialStatus} />
      <Row justify="end">
        <Col>
          <Form.Item style={{ display: (disabled || (stItems.length) ? 'block' : 'none') }}>
            <Button type='primary' onClick={saveStockTransfer} block disabled={disabled || (stItems.length) ? false : true}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

export default PackingStockTransfer;
