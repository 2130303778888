import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PalletCapacityDto } from './pallet-capacity-dto';


export class AllPalletCapacityResponseModel extends GlobalResponseObject {
    data?: PalletCapacityDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PalletCapacityDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

