export class HsnDto{

    hsnCodeId: number;
    productId: number;
    foodTypeId: number;
    hsnCode: string;
    valueAdditionOneId: number;
    valueAdditionTwoId: number;
    valueAdditionThreeId: number;
    valueAdditionFourId: number;
    valueAdditionFiveId: number;
    variantCode: string;
    isActive: boolean;
    createdUser: string;
    updatedUser?: string;

    constructor(
        hsnCodeId:number,
        productId: number,
        // foodTypeId: number,
        
        variantCode: string,
        valueAdditionOneId: number,
        valueAdditionTwoId: number,
        valueAdditionThreeId: number,
        // valueAdditionFourId: number,
        hsnCode:string,createdUser: string,updatedUser:string,isActive: boolean)
    {
        this.hsnCodeId=hsnCodeId;
        this.productId=productId;
        //this.foodTypeId=foodTypeId;
        this.variantCode=variantCode;
        this.valueAdditionOneId=valueAdditionOneId;
        this.valueAdditionTwoId=valueAdditionTwoId;
        this.valueAdditionThreeId=valueAdditionThreeId;
        // this.valueAdditionFourId=valueAdditionFourId;
      
        this.hsnCode=hsnCode;
        this.createdUser=createdUser;
        this.updatedUser=updatedUser;

        this.isActive=isActive;
    }
}