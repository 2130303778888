export class EmployeeEducationDetailsDto {

   employeeEducationId: number;
   empQualification: string;
   specialization: string;
   dateOfPassOut: Date;
   percentage: number;
   isActive: boolean;
   createdAt: Date;
   createdUser: string | null;
   updatedAt: Date;
   updatedUser: string | null;
   versionFlag: number;
 
 }