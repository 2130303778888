


export enum PackingFeaturesEnum {
    VPO = 'vpo',
    CO = 'customer_order_no',
    DESTINATION = 'destination',
    SCHEDULE =  'schedule',
}

/*
export function validatePackingFeatureExist(feature: string): boolean {
   return Object.values(PackingFeaturesEnum).includes(feature);
}
*/