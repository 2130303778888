export class OperationDto{
    operationId?: number;
    operationCode: string;
    operationName: string;
    workstationCategoryId: number;
    workstationCategoryName: string;
    isValueAddition: boolean;
    isActive: boolean;
    createdUser: string;
    updatedUser: string;
    versionFlag: number;
    isUpdate:boolean;

    /**
     * 
     * @param operationId
     * @param operationCode
     * @param operationName
     * @param operationName
     * @param workstationCategoryId
     * @param isUpdate
     */

    constructor(
        operationId: number,
        operationCode: string,
        operationName: string,
        workstationCategoryId: number,
        workstationCategoryName: string,
        isValueAddition: boolean,
        isActive: boolean,
        createdUser: string,
        updatedUser: string,
        versionFlag: number,
        isUpdate: boolean,
    ){
      this.operationId = operationId;
      this.operationCode = operationCode;
      this.operationName = operationName;
      this.workstationCategoryId = workstationCategoryId;
      this.workstationCategoryName = workstationCategoryName;
      this.isValueAddition = isValueAddition;
      this.isActive = isActive;
      this.createdUser = createdUser;
      this.updatedUser = updatedUser;
      this.versionFlag = versionFlag;
      this.isUpdate = isUpdate;
    }
}