import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class GetVendorItemsPriceListResponse extends GlobalResponseObject{
        data?: any;

        /**
         * 
         * @param status 
         * @param intlCode 
         * @param internalMessage 
         * @param data 
         */
        constructor(status: boolean, intlCode: number, internalMessage: string, data?: any) {
            super(status, intlCode, internalMessage);
            this.data = data;
        }
}