export class RmRackPostionRequest {
    rmRackId: number;
    updatedUser: string;
    versionFlag: number;
    isActive: boolean;

    constructor(rmRackId: number,updatedUser: string,versionFlag: number,isActive: boolean) {
        this.rmRackId = rmRackId;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
        this.isActive = isActive;
    }
}