import { AssetStatusEnum } from "libs/shared-models/common-models/src/lib/enum/asset-status.enum";

export class AbstractDetails{
    assetCategory:string ;
    status: AssetStatusEnum
    constructor(assetCategory:string, status: AssetStatusEnum ){
        this.assetCategory = assetCategory;
        this.status = status;
        
    }
}

