import { CommonResponse } from "@gtpl/shared-models/masters";
import axios from "axios";
import { AssetDto, purchasedatefilterreq } from "libs/shared-models/asset-management/src/lib/open-assets/assets.dto";
import connection from "./connection";
import { AssetsRequest } from '../../../../shared-models/asset-management/src/lib/open-assets/assets.request';
import { AssetByCode } from '../../../../shared-models/asset-management/src/lib/open-assets/asset-by-code.dto';
import { AssetByStatus, AssetServiceRportReq } from "libs/shared-models/asset-management/src/lib/open-assets/asset-by-status.dto";
import { AssetRaciReport } from 'libs/shared-models/asset-management/src/lib/open-assets/raci-report.dto';
import { AssetChecklistRequestDto, AssetServiceDto, AssetsDto, MachineInwordReq, ServiceUploadDto, UpdateChecklist, UpdateServiceRemarksDto } from "@gtpl/shared-models/asset-management";
import { AbstractDetails } from "../../../../shared-models/asset-management/src/lib/open-assets/abstract-details.dto";
import { AssetById } from "libs/shared-models/asset-management/src/lib/asset-maintainance/asset-by-id.dto";
import { AssetsCatagoryByIdReq } from "libs/shared-models/asset-management/src/lib/assets-catagory/assets-catagory-id";
import { AssetById as GetById } from 'libs/shared-models/asset-management/src/lib/open-assets/asset-by-id.dto';
import { AssetStatusEnum } from "libs/shared-models/common-models/src/lib/enum/asset-status.enum";
import { AssetStatusWiseAbstractInfoResp, AssetVendorDetailsResponse, ServiceOwnerDetailsResponse, AssetCategoryWiseMaintenanceResponse } from "@gtpl/shared-models/common-models";
export class AssetService {
    URL = connection.DEPLOY_URL + '/assets';

    async createAssets(req: AssetDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssets', req)
            .then(res => {
                return res.data;
            })
    }
    // async updateAssets(req: AssetDto) : Promise<CommonResponse> {
    //     return await axios.post(this.URL + '/updateassets',req)
    //     .then(res => {
    //         return res.data;
    //     })
    // }
    async getallAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getallassets', req)
            .then(res => {
                return res.data;
            })
    }

    async getAllAssetsEligileForCheckIn(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsEligileForCheckIn', req)
            .then(res => {
                return res.data;
            })
    }


    async activateOrDeactivate(req: AssetsRequest): Promise<CommonResponse> {
        return await axios.post(this.URL + '/activeordeactive', req)
            .then(res => {
                return res.data;
            })
    }
    async getByAssetCode(req: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getByAssetCode', req)
            .then(res => {
                return res.data;
            })
    }

    async getByStatus(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getBYStatus', req)
            .then(res => {
                return res.data;
            })
    }


    async assetsCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsCount')
            .then(res => {
                return res.data;
            })
    }

    async assetsMonthWiseCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsMonthWiseCount')
            .then(res => {
                return res.data;
            })
    }

    async assetsStatusCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsStatusCount')
            .then(res => {
                return res.data;
            })
    }


    async assetMonthwiseReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetMonthwiseReport')
            .then(res => {
                return res.data;
            })
    }

    async getMonthwiseAssetsSale(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMonthwiseAssetsSale')
            .then(res => {
                return res.data;
            })
    }

    async assetsCheckInMonthWiseCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsCheckInMonthWiseCount')
            .then(res => {
                return res.data;
            })
    }



    async getAssetReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetReport')
            .then(res => {
                return res.data;
            })
    }

    async assetsTopWarrenty(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsTopWarrenty')
            .then(res => {
                return res.data;
            })
    }

    async assetsTopInsurance(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsTopInsurance')
            .then(res => {
                return res.data;
            })
    }

    async getRaciReport(req: AssetRaciReport): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRaciReport', req)
            .then(res => {
                return res.data;
            })
    }

    async getAllRaciReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllRaciReport')
            .then(res => {
                return res.data;
            })
    }

    async assetsDamagedCost(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsDamagedCost')
            .then(res => {
                return res.data;
            })
    }

    async departmentWiseCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/departmentWiseCount')
            .then(res => {
                return res.data;
            })
    }

    async getExpiredAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getExpiredAssets', req)
            .then(res => {
                return res.data;
            })
    }

    async assetDecommission(req: AssetDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetDecommission', req)
            .then(res => {
                return res.data;
            })
    }

    async assetCodeSearch(req: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetCodeSearch', req)
            .then(res => {
                return res.data;
            })
    }

    async getAbstractReport(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAbstractReport')
            .then(res => {
                return res.data;
            })
    }

    async getDetailsByAbstract(req: AbstractDetails): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDetailsByAbstract', req)
            .then(res => {
                return res.data;
            })
    }

    async getTotalAbstract(req: AbstractDetails): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTotalAbstract', req)
            .then(res => {
                return res.data;
            })
    }

    async historyReport(req: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/historyReport', req)
            .then(res => {
                return res.data;
            })
    }

    async createAssetService(req: AssetServiceDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/createAssetService', req)
            .then(res => {
                return res.data;
            })
    }
    async updateAssetService(req: AssetServiceDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateAssetService', req)
            .then(res => {
                return res.data;
            })
    }

    async getAllAssetsDataById(req?: AssetById): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAllAssetsDataById', req)
            .then(res => {
                return res.data;
            })
    }

    async getAsssetForCategory(req?: AssetsCatagoryByIdReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAsssetForCategory', req)
            .then(res => {
                return res.data;
            })
    }

    async getAssetServiceReportdata(req?: AssetServiceRportReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetServiceReportdata', req)
            .then(res => {
                return res.data;
            })
    }
    async getLocationdropdown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getLocationdropdown')
            .then(res => {
                return res.data;
            })
    }
    async getcategorydropdown(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getcategorydropdown')
            .then(res => {
                return res.data;
            })
    }
    async getDecommissioneddAssetsReport(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDecommissioneddAssetsReport', req)
            .then(res => {
                return res.data;
            })
    }
    async assetHistoryInformation(req: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetHistoryInformation', req)
            .then(res => {
                return res.data;
            })
    }
    async getAssetCodesDropDown(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetCodesDropDown', req)
            .then(res => {
                return res.data;
            })
    }

    async getInstockAssetCodesDropDown(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getInstockAssetCodesDropDown', req)
            .then(res => {
                return res.data;
            })
    }
    async getRentedAssetCodesDropDown(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRentedAssetCodesDropDown', req)
            .then(res => {
                return res.data;
            })
    }
    async assetsTopServices(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetsTopServices')
            .then(res => {
                return res.data;
            })
    }

    async updateAssetStatus(req: AssetByCode): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateAssetStatus', req)
            .then(res => {
                return res.data;
            })
    }

    async getDecommissioneddAssetsReportDropdown(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getDecommissioneddAssetsReportDropdown', req)
            .then(res => {
                return res.data;
            })
    }

    async getPurchasedDetailsUnused(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPurchasedDetailsUnused', req)
            .then(res => {
                return res.data;
            })
    }

    async getpurchasedbutnotAssigned(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getpurchasedbutnotAssigned')
            .then(res => {
                return res.data;
            })
    }

    async getAssignedforRent(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssignedForRent',req)
            .then(res => {
                return res.data;
            })
    }

    async underMaintainanceOnInternal(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/underMaintainanceOnInternal', req)
            .then(res => {
                return res.data;
            })
    }

    async getPlantWiseAssets(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getPlantWiseAssets')
            .then(res => {
                return res.data;
            })
    }

    async underMaintainanceOnVendor(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/underMaintainanceOnVendor', req)
            .then(res => {
                return res.data;
            })
    }
    async getAgeWiseAssets(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAgeWiseAssets')
            .then(res => {
                return res.data;
            })
    }
    async getSkippedAssetsCount(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getSkippedAssetsCount')
            .then(res => {
                return res.data;
            })
    }
    async updateServiceRemarks(req: UpdateServiceRemarksDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateServiceRemarks', req)
            .then(res => {
                return res.data;
            })
    }

    async getInsuranceAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getInsuranceAssets', req)
            .then(res => {
                return res.data;
            })
    }
    async getInsuranceDropDownAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getInsuranceDropDownAssets', req)
            .then(res => {
                return res.data;
            })
    }
    
    async getWarrantyAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getWarrantyAssets', req)
            .then(res => {
                return res.data;
            })
    }
    async getWarrantyDropDownAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getWarrantyDropDownAssets', req)
            .then(res => {
                return res.data;
            })
    }

    async getServiceDataForServiceId(req: UpdateServiceRemarksDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getServiceDataForServiceId', req)
            .then(res => {
                return res.data;
            })
    }
    async topResponsible():Promise<CommonResponse>{
        return await axios.post(this.URL+ '/topResponsible')
        .then(res =>{return res.data;})
    }
    async getInstockAssets(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getInstockAssets', req)
            .then(res => {
                return res.data;
            })
    }
    async getExpRents(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getExpRents')
            .then(res => {
                return res.data;
            })
    }

    async getTop5SaleAssets(req?:AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getTop5SaleAssets',req)
            .then(res => {
                return res.data;
            })
    }

    async assetHistory(req: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/assetHistory', req)
            .then(res => {
                return res.data;
            })
    }

    async depreciaitionReport(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/depreciaitionReport',req)
            .then(res => {
                return res.data;
            })
    }
    async getRentedAssetsDetails(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getRentedAssetsDetails')
        .then(res => {
            return res.data;
        })
    }
   
    async getAssignedforRentCatogry(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssignedforRentCatogry',req)
            .then(res => {
                return res.data;
            })
    }
    async getpurchasedAssetCatogry(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getpurchasedAssetCatogry',req)
            .then(res => {
                return res.data;
            })
    }
    async getAuditReportData(req?: AssetServiceRportReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAuditReportData',req)
            .then(res => {
                return res.data;
            })
    }
    async getAssetSaleReport(req?: AssetServiceRportReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetSaleReport',req)
            .then(res => {
                return res.data;
            })
    }
    async getVmsReportData(req?: AssetServiceRportReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getVmsReportData',req)
            .then(res => {
                return res.data;
            })
    }
    
    async getChecklistReport(req?: AssetByStatus): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getChecklistReport',req)
            .then(res => {
                return res.data;
            })
    }

    async getAssetChecklist(req: AssetChecklistRequestDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getAssetChecklist',req)
            .then(res => {
                return res.data;
            })
    }

    async updateChecklist(req: UpdateChecklist[]): Promise<CommonResponse> {
        return await axios.post(this.URL + '/updateChecklist',req)
            .then(res => {
                return res.data;
            })
    }

    async getCategoryWiseData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getCategoryWiseData')
        .then(res => {
            return res.data;
        })
    }

    async AssetStatusWiseAbstract(): Promise<AssetStatusWiseAbstractInfoResp> {
        return await axios.post(this.URL + '/AssetStatusWiseAbstract')
        .then(res => {
            return res.data;
        })
    }

    async uploadInfo(req:ServiceUploadDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/uploadInfo',req)
        .then(res => {
            return res.data;
        })
    }

    async AssetVendorDetails(): Promise<AssetVendorDetailsResponse> {
        return await axios.post(this.URL + '/AssetVendorDetails')
        .then(res => {
            return res.data;
        })
    }


    async fileUpload(file: any): Promise<CommonResponse> {
        console.log(file)
        return await axios.post(this.URL + '/fileUpload', file)
            .then(res => {
                return res.data
            })
    }

    async getChecklistByServeId(req:ServiceUploadDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getChecklistByServeId',req)
        .then(res => {
            return res.data;
        })
    }

    async serviceOwnerServices(): Promise<ServiceOwnerDetailsResponse> {
        return await axios.post(this.URL + '/serviceOwnerServices')
        .then(res => {
            return res.data;
        })
    }

    async AssetCategoryWiseMaintenance(): Promise<AssetCategoryWiseMaintenanceResponse> {
        return await axios.post(this.URL + '/AssetCategoryWiseMaintenance')
        .then(res => {
            return res.data;
        })
    }

    async getResponsibleAssets(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getResponsibleAssets')
        .then(res => {
            return res.data;
        })
    }

    async getUnassignedAssetsVendors(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getUnassignedAssetsVendors')
        .then(res => {
            return res.data;
        })
    }
    
    async getImagesByServeId(req:ServiceUploadDto): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getImagesByServeId',req)
        .then(res => {
            return res.data;
        })
    }

    async getMachineInwordDetails(req:MachineInwordReq): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMachineInwordDetails',req)
        .then(res => {
            return res.data;
        })
    }
    async getMachineBarcodeFilter(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getMachineBarcodeFilter')
        .then(res => {
            return res.data;
        })
    }

    async getKPIData(): Promise<CommonResponse> {
        return await axios.post(this.URL + '/getKPIData')
        .then(res => {
            return res.data;
        })
    }

}