import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, DatePicker, InputNumber, Table, Tooltip, Divider } from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory } from 'react-router-dom';
import './indent-form.css';
import moment from 'moment';
import { EmployeeRolesEnum, GlobalStatus, PlantsEnum, SupplierTypeEnum } from '@gtpl/shared-models/common-models';
import { CommissionType, HarvestingTime, IndentDetailsModel, IndentItemsModel, IndentModel, IndentRequest } from '@gtpl/shared-models/raw-material-procurement';
import { EmployeeDto, EmployeeRoleRequest, FarmerModel, FarmersDropDownDto, PlantsDropDown, ProductDto, SupplierTypeRequest } from '@gtpl/shared-models/masters';
import { ProductService, SuppliersService, EmployeeService, UnitcodeService } from '@gtpl/shared-services/masters';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { HrmsEmpDetService } from '@gtpl/shared-services/masters';

const { Option } = Select;
/* eslint-disable-next-line */
export interface IndentFormProps {
  indentData: any,
  isUpdate: boolean,
  // form:any,
  closeDrawer: () => void,
  // updateIndent: (indent: IndentDetailsModel) => void
}

export function IndentForm(
  props: IndentFormProps
) {
  // const { getFieldDecorator } = props.form;

  const userName = localStorage.getItem("createdUser");
  const plantId = localStorage.getItem("unit_id");
  const [form] = Form.useForm();
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [type, setType] = useState<string>('');
  const [brokers, setBrokers] = useState<FarmersDropDownDto[]>([]);
  const [farmers, setFarmers] = useState<FarmersDropDownDto[]>([]);
  const [farmerCodes, setFarmerCodes] = useState<FarmersDropDownDto[]>([]);
  const [employees, setEmployees] = useState<EmployeeDto[]>([]);
  const [indentEmployees, setIndentEmployees] = useState<EmployeeDto[]>([]);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [indentItemsData, setindentItemsData] = useState<IndentItemsModel[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [fieldsData, setFieldsData] = useState<any>({});
  const [showTable, setShowTable] = useState<boolean>(false);
  const [commissionTypeHiddenState, setCommissionTypeHiddenState] = useState<boolean>(true);
  const [submitBtnDisable, setSubmitBtnDisable] = useState<boolean>(true);
  const [btnChange, setBtnChange] = useState<boolean>(false);
  const [indexVal, setIndexVal] = useState(0);
  const productService = new ProductService();
  const supplierService = new SuppliersService();
  const employeeService = new EmployeeService();
  const indentService = new IndentService();
  const empService = new HrmsEmpDetService();
  const [assignlead1, setAssignLead1] = useState(0);
  const [assignlead2, setAssignLead2] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  let history = useHistory();
  const unitcodeService = new UnitcodeService();
  const username = localStorage.getItem('username')
  console.log(username)
  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }

  useEffect(() => {
    getAllProducts();
    getAllMainPlants();
    getAllEmployesForProcManager();
    getAllIndentEmployees();
    if (props.indentData) {
      setSubmitBtnDisable(false)
      setShowTable(true);
      supplierTypeChange(props.indentData.supplierType);
      brokerChange(props.indentData.broker);
      // farmerChange(props.indentData.farmer);

      farmerCodeChange(props.indentData.farmerCode);
      getIndentItemsData()
    } else {
      form.setFieldsValue({ commissionType: CommissionType.PERCENTAGE })
    }
  }, []);

  useEffect(() => {
    if (tableData) {
      console.log(tableData);
    }
  }, [tableData])

  // To format the date on loading the page
  if (props.indentData) {
    props.indentData.indentDate = moment(props.indentData.indentDate);
    props.indentData.harvestingDate = moment(props.indentData.harvestingDate);
    if (props.indentData.isVehicleRequired == false) {
      props.indentData.isVehicleRequired = 0
    } else {
      props.indentData.isVehicleRequired = 1
    }

  }

  const getIndentItemsData = () => {
    const indentReq = new IndentRequest();
    indentReq.indentId = props.indentData.indentId;
    indentService.getIndentDataById(indentReq).then((res) => {
      if (res.status) {
        console.log(res.data)
        setShowTable(true)
        //   let object = {...fieldsData,farmer:values.farmer,farmerName:farmerdata.supplierName,farmerCode:values.farmerCode,expectedCount:values.expectedCount,expectedQty:values.expectedQty,expectedPrice:values.expectedPrice,product:values.product,productName:productsData.productName,farmName:farmsData.supplierName}
        // console.log(object)
        // let dynTableData = [...tableData, object]
        // setTableData(dynTableData);
        setTableData(res.data)
      }
      else {
        if (res.intlCode) {
          setTableData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  const getAllMainPlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  const getAllProducts = () => {
    productService.getAllActiveProducts().then(res => {
      if (res.status) {
        setProducts(res.data);
      } else {
        if (res.intlCode) {
          setProducts([]);
          AlertMessages.getErrorMessage('No Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setProducts([]);
    });
  }

  const getAllEmployesForProcManager = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setEmployees(res.data);
      } else {
        if (res.intlCode) {
          setEmployees([]);
          AlertMessages.getErrorMessage('No Employees Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setEmployees([]);
    });
  }

  const supplierTypeChange = (supplier: SupplierTypeEnum) => {
    if (supplier) {
      console.log(supplier)
      if (supplier == SupplierTypeEnum.AGENT) {
        if (!props.indentData) {
          form.setFieldsValue({ isVehicleRequired: 0 })
          form.setFieldsValue({ harvestType: 'Re Weighment' })
        }
      } else {
        if (!props.indentData) {
          form.setFieldsValue({ isVehicleRequired: 1 })
          form.setFieldsValue({ harvestType: 'Live Harvest' })
        }
      }
      if (supplier == SupplierTypeEnum.BROKER) {
        setCommissionTypeHiddenState(false)
      } else {
        setCommissionTypeHiddenState(true)
        form.setFieldsValue({ commissionType: CommissionType.PERCENTAGE })
        form.setFieldsValue({ commission: 0 })
      }
      setType(supplier);
      supplierService.getFarmersBySupplierType(new SupplierTypeRequest(supplier)).then(res => {
        if (res.status) {
          if (supplier === SupplierTypeEnum.BROKER) {
            setBrokers(res.data);
          } else {
            setFarmers(res.data);
          }
        } else {
          if (res.intlCode) {
            if (supplier === SupplierTypeEnum.BROKER) {
              setBrokers([]);
            } else {
              setFarmers([]);
            }
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        if (supplier === SupplierTypeEnum.BROKER) {
          setBrokers([]);
        } else {
          setFarmers([]);
        }
      });
      // if(supplier === SupplierTypeEnum.BROKER){
      //   form.setFieldsValue({supplierType:supplier,broker:null,farmer:null,farmerCode:null});
      // } else {
      //   form.setFieldsValue({supplierType:supplier,farmer:null,farmerCode:null});
      // }
    } else {
      setType('');
      form.setFieldsValue({ broker: null, farmer: null, farmerCode: null });
    }
  }

  const brokerChange = (brokerId: number) => {
    if (brokerId) {
      supplierService.getSuppliersByBrokerId({ brokerId: brokerId }).then(res => {
        if (res.status) {
          setFarmers(res.data);
        } else {
          if (res.intlCode) {
            setFarmers([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setFarmers([]);
      });
      form.setFieldsValue({ broker: brokerId, farmer: null, farmerCode: null });
    } else {
      form.setFieldsValue({ farmer: null, farmerCode: null });
    }
  }

  const farmerChange = (farmerId: number) => {
    if (farmerId) {
      supplierService.getFarmCodesBySupplierId(new FarmerModel(farmerId)).then(res => {
        if (res.status) {
          console.log(res.data)
          setFarmerCodes(res.data);
        } else {
          if (res.intlCode) {
            setFarmerCodes([]);
            AlertMessages.getErrorMessage('No Data Found');
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
        setFarmerCodes([]);
      });
      form.setFieldsValue({ farmer: farmerId, farmerCode: null });
    } else {
      form.setFieldsValue({ farmerCode: null });
    }
  }
  const farmerCodeChange = (farmerId: number) => {
    form.setFieldsValue({ farmerCode: farmerId });
  }
  const saveIndent = () => {
    setSubmitBtnDisable(true)
    const formData = form.getFieldsValue()
    let createdUserName
    let updatedUserName
    let indentId
    let plantId
    if (props.isUpdate) {
      updatedUserName = userName
      createdUserName = undefined
      indentId = props.indentData.indentId
      plantId = form.getFieldValue('plantId')
    } else {
      createdUserName = userName
      updatedUserName = undefined
      indentId = undefined
      plantId = form.getFieldValue('plantId') || undefined
    }
    let indentItems: IndentItemsModel[] = []
    tableData.forEach(element => {
      console.log(tableData);
      let indentItemId = undefined
      let brokerId = undefined
      let farmerCode = element.farmerCode
      if (formData.supplierType == SupplierTypeEnum.BROKER) {
        brokerId = element.broker
      }
      if (props.isUpdate) {
        indentItemId = element.indentItemId
        farmerCode = element.farmId
      }
      const indentItem = new IndentItemsModel(indentItemId, plantId, element.farmer, farmerCode, element.product, element.expectedCount, element.expectedQty, element.expectedPrice, element.expectedCount2, element.expectedPrice2, element.expectedCount3, element.expectedPrice3, brokerId, undefined, userName, undefined, undefined, undefined)
      indentItems.push(indentItem);
    });
    const indentModel = new IndentDetailsModel(plantId, formData.indentDate, formData.supplierType, formData.commissionType, formData.commission, formData.harvestingDate, formData.harvestingTime, formData.assignLead1, formData.assignLead2, indentItems, formData.indentBy, createdUserName, updatedUserName, indentId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, formData.isVehicleRequired, formData.harvestType)
    console.log(indentModel)
    // indentModel.plantId = Number(plantId);
    if (props.isUpdate) {
      // Update Indent Details
      // indentModel.updatedUser = userName;
      indentModel.indentId = props.indentData.indentId;
      indentService.updateIndent(indentModel).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Indent Updated Successfully');
          onReset();
          props.closeDrawer();
        } else {
          if (res.intlCode) {
            // AlertMessages.getErrorMessage(res.internalMessage);
            setSubmitBtnDisable(false)
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setSubmitBtnDisable(false)
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      });
      // props.updateIndent(indentModel);
    } else {
      // Create Indent Details
      // indentModel.createdUser = userName;


      // indentModel.indentItems = indentItems;
      indentService.createIndent(indentModel).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Indent Created Successfully');
          history.push("/indent-view")
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
            setSubmitBtnDisable(false)
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setSubmitBtnDisable(false)
          }
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      });
    }
  };

  const onReset = () => {
    form.resetFields(['farmer', 'farmerCode', 'product', 'expectedCount', 'expectedQty', 'expectedPrice', 'expectedCount2', 'expectedPrice2', 'expectedCount3', 'expectedPrice3']);
    setType('');
    setIsFixed(false);
  }

  const commissionTypeChange = (value: CommissionType) => {
    if (value === CommissionType.FIXED) {
      setIsFixed(true);
    } else if (value === CommissionType.PERCENTAGE) {
      setIsFixed(false);
    } else {
      setIsFixed(false);
    }
  }

  const getAllIndentEmployees = () => {
    empService.getAllActiveEmployees().then(res => {
      if (res.status) {
        setIndentEmployees(res.data);
      } else {
        if (res.intlCode) {
          setIndentEmployees([]);
          AlertMessages.getErrorMessage('No Employees Data Found');
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setIndentEmployees([]);
    });
  }

  const setSelectedData = () => {
    form.validateFields().then(res => {
      console.log(res)
      let values = form.getFieldsValue();
      console.log(values)

      setShowTable(true);
      if (btnChange) {
        const farmerdata = farmers.find(item => item.supplierId == values.farmer);
        console.log(farmerdata)

        const productsData = products.find(item => item.productId == values.product);
        const farmsData = farmerCodes.find(item => item.supplierId == values.farmerCode);
        let brokerName = undefined;
        let brokersData;
        if (values.supplierType == SupplierTypeEnum.BROKER) {
          brokersData = brokers.find(item => item.supplierId == values.broker);
          brokerName = brokersData.supplierName
        }
        console.log(farmsData)
        let indentItemId = undefined;

        let dyntableData = [...tableData];
        if (props.isUpdate) {
          indentItemId = dyntableData[indexVal].indentItemId;
        }
        let updatedobject = { farmer: values.farmer, farmerName: farmerdata?.supplierName, farmerCode: values?.farmerCode, expectedCount: values.expectedCount, expectedQty: values.expectedQty, expectedPrice: values.expectedPrice, expectedCount2: values.expectedCount2, expectedPrice2: values.expectedPrice2, expectedCount3: values.expectedCount3, expectedPrice3: values.expectedPrice3, product: values.product, productName: productsData.productName, farmName: farmsData?.supplierName, indentItemId: indentItemId, broker: brokersData?.supplierId, brokerName: brokerName }
        console.log(updatedobject);
        dyntableData[indexVal] = updatedobject
        setTableData(dyntableData);
        setBtnChange(false);
      } else {
        setSubmitBtnDisable(false)
        let brokerId = undefined;
        let brokerData;
        if (values.supplierType == SupplierTypeEnum.BROKER) {
          brokerId = values.broker;
          brokerData = brokers.find(item => item.supplierId == brokerId);
        }
        const farmerdata = farmers.find(item => item.supplierId == values.farmer);
        console.log(farmerdata)

        const productsData = products.find(item => item.productId == values.product);
        const farmsData = farmerCodes.find(item => item.supplierId == values.farmerCode);

        console.log(values)
        console.log(farmerdata)
        let object = { ...fieldsData, farmer: values.farmer, farmerName: farmerdata?.supplierName, farmerCode: values?.farmerCode, expectedCount: values.expectedCount, expectedQty: values.expectedQty, expectedPrice: values.expectedPrice, expectedCount2: values.expectedCount2, expectedPrice2: values.expectedPrice2, expectedCount3: values.expectedCount3, expectedPrice3: values.expectedPrice3, product: values.product, productName: productsData.productName, farmName: farmsData?.supplierName, indentItemId: undefined, broker: brokerData?.supplierId }
        console.log(object)
        let dynTableData = [...tableData, object]
        setTableData(dynTableData);
        setBtnChange(false);
      }
      form.setFieldsValue({ expectedCount: null, expectedQty: null, expectedPrice: null, expectedCount2: null, expectedPrice2: null, expectedCount3: null, expectedPrice3: null })
    })

  }

  const deleteData = (index) => {
    tableData.splice(index, 1);
    setTableData([...tableData]);
    if (tableData.length == 0) {
      setShowTable(false)
    }
  }

  const setEditForm = (rowData, index) => {
    console.log(rowData)
    setBtnChange(true);
    let farmCode = rowData.farmerCode;
    if (props.isUpdate) {
      farmCode = rowData.farmId
      if (form.getFieldValue('supplierType') == SupplierTypeEnum.BROKER) {
        supplierTypeChange(props.indentData.supplierType);
        brokerChange(rowData.broker)
        farmerChange(rowData.farmer);
      } else {
        farmerChange(rowData.farmer);
      }
    }
    console.log('test')
    form.setFieldsValue({ farmer: rowData.farmer, farmerCode: farmCode, expectedCount: rowData.expectedCount, expectedQty: rowData.expectedQty, expectedPrice: rowData.expectedPrice, product: rowData.product, broker: rowData.broker })
    console.log('test')

    // if(props.isUpdate){
    //   form.setFieldsValue({farmerCode:rowData.farmName})
    // }
    setIndexVal(index);
  }

  const columns = [
    {
      title: 'Middle Man',
      dataIndex: 'brokerName',
      key: 'brokerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farmer',
      dataIndex: 'farmerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farm',
      dataIndex: 'farmName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'expectedQty',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Count',
      dataIndex: 'expectedCount',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Price',
      dataIndex: 'expectedPrice',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // width: '20%',
      render: (text, rowData: any, index) => (
        <span>
          <Tooltip placement="top" title='edit'>
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                if (rowData) {
                  setEditForm(rowData, index);
                }
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip>
          {
            !(props.isUpdate) ?
              <><Divider type="vertical" />
                <Tooltip placement="top" title='dlete'>
                  <MinusCircleOutlined onClick={() => {
                    if (rowData) {
                      deleteData(index)
                      // openFormWithData(rowData);
                    }
                  }}
                    style={{ color: '#1890ff', fontSize: '14px' }} />
                </Tooltip>
              </> : <></>
          }
        </span>)
    }

  ]

  const clearDependentData = () => {
    // alert();
    form.setFieldsValue({ isVehicleRequired: '' })
    form.setFieldsValue({ harvestType: '' })

  }



  return (
    <Card
      title={<span style={{ color: 'white' }}>Indent</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
      extra={props.isUpdate == true ? "" : <Link to='/indent-view' ><span style={{ color: 'white' }} ><Button className='panel_button' > View </Button> </span>  </Link>}>
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        initialValues={props.indentData}
        // onFinish={saveIndent}
        autoComplete={"off"}
      // key= {Date.now()}
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="indentDate" label="Indent Date" rules={[{ required: true, message: 'Indent Date is required' }]} initialValue={moment()}>
              <DatePicker onChange={() => { }} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="supplierType" label="Supplier Type" rules={[{ required: true, message: 'Supplier Type is required' }]}>
              <Select
                showSearch
                placeholder="Select Supplier Type"
                onChange={supplierTypeChange}
                allowClear={true}
                onClear={clearDependentData}
              >
                <Option value="Agent">Agent</Option>
                <Option value="Farmer">Farmer</Option>
                <Option value="Broker">Middle Man</Option>
                {/* {Object.keys(SupplierTypeEnum).map((type)=>{
                  return <Option value={SupplierTypeEnum[type]}>{SupplierTypeEnum[type]}</Option>
                })} */}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden={commissionTypeHiddenState}>
            <Form.Item name="commissionType" label="Commission Type" rules={[{ required: true, message: 'Commission Type is required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Commission Type"
                allowClear={true}
                onChange={commissionTypeChange}
                defaultValue={CommissionType.PERCENTAGE}

              >
                {Object.keys(CommissionType).map((type) => {
                  return <Option value={CommissionType[type]}>{CommissionType[type]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} hidden={commissionTypeHiddenState}>
            {isFixed ? <Form.Item name="commission" label="Commission(INR per KG)" rules={[{ required: true, message: 'Commission in INR per KG is required' }]}>
              <InputNumber value={commissionTypeHiddenState == true ? 0 : null} min={0} style={{ width: '100%' }} />
            </Form.Item> : <Form.Item name="commission" label="Commission(%)" rules={[{ required: true, message: 'Commission % is required' }]}>
              <InputNumber value={commissionTypeHiddenState == true ? 0 : null} min={0} style={{ width: '100%' }} />
            </Form.Item>
            }
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="harvestingDate" label="Harvesting Date" rules={[{ required: true, message: 'Harvesting Date is required' }]}
              initialValue={moment(Date.now()).add(1, 'd')}
            >
              <DatePicker onChange={() => { }} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="harvestingTime" label="Harvesting Time" initialValue={HarvestingTime.MORNING} rules={[{ required: true, message: 'Harvesting Time is required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Harvesting Time"
                allowClear={true}
              >
                {Object.keys(HarvestingTime).map((hTime) => {
                  return <Option value={HarvestingTime[hTime]}>{HarvestingTime[hTime]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="assignLead1" label="Greader 1" rules={[{ required: true, message: 'Greader 1 is required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Greader 1"
                onChange={(value) => {
                  setAssignLead1(Number(value))
                }}
                allowClear={true}
              >
                <Option value={null}>Select Greader 1</Option>
                {employees.map((emp) => {
                  return (emp.employeeId != assignlead2) ? <Option value={emp.employeeId}>{emp.employeeName}</Option> : null
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="assignLead2" label="Greader 2" rules={[{ required: false, message: 'Greader 2 is required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Greader 2"
                allowClear={true}
                onChange={(value) => {
                  setAssignLead2(Number(value))
                }}
              >
                <Option value={null}>Select Greader 2</Option>
                {employees.map((emp) => {
                  return (emp.employeeId != assignlead1) ? <Option value={emp.employeeId}>{emp.employeeName}</Option> : null
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="indentBy" label="Indent By" rules={[{ required: true, message: 'Indent By is required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Indent By"
                allowClear={true}
              >
                <Option value={null}>Select Indent By</Option>
                {indentEmployees.map((emp) => {
                  return <Option value={emp.employeeId}>{emp.employeeName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="isVehicleRequired" label="Is Vehicle Required" rules={[{ required: true, message: 'Is Vehicle Required is required' }]}>
              <Select
                placeholder="Select Is Vehicle Required"
                allowClear
              >
                <Option value={1}>YES</Option>
                <Option value={0}>NO</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="harvestType" label="Harvest Type" rules={[{ required: true, message: 'Harvest Type is required' }]}>
              <Select
                placeholder="Select Harvest Type"
                allowClear
              >
                <Option value="Re Weighment">Re Weighment</Option>
                <Option value="Live Harvest">Live Harvest</Option>

              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="plantId" label="Plant" rules={[{ required: false, message: 'Missing Unit' }]} >
              <Select
                placeholder="Select Plant"
                allowClear
              // disabled={userName != 'rmmanager'}
              >
                <Option value={null || 0}>Select Plant</Option>
                {factoriesData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}

              </Select>
            </Form.Item>

          </Col>

          {/* {props.isUpdate?
          <Col span={6}>
          <Form.Item name="subPlantId" label="Sub Plant"
          //  rules={[{ required: true,message:'Assignlead 2 is required' }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Select Sub Plant"
              allowClear = {true}
            >
              <Option value={null}>Select Sub Plant</Option>
              {employees.map((emp)=>{
                return <Option value={emp.employeeId}>{emp.employeeName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
          :''} */}
        </Row>
        <Card title={<span style={{ color: 'white' }}>Indent Items</span>} style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }}>
          <Row gutter={24}>
            {(type === SupplierTypeEnum.BROKER) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                <Form.Item name="broker" label="Middle Man" rules={[{ required: true, message: 'Middle Man is required' }]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Select Middle Man"
                    onChange={brokerChange}
                    allowClear={true}
                  >
                    <Option value={null}>Select Middle Man</Option>
                    {brokers.map((broker) => {
                      return <Option value={broker.supplierId}>{broker.supplierName + '(' + broker.supplierCode + ')'}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col> : ''}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
              <Form.Item name="farmer" label={(type === SupplierTypeEnum.AGENT) ? 'Agent' : 'Farmer'} rules={[{ required: true, message: (type === SupplierTypeEnum.AGENT) ? 'Agent required' : 'Farmer required' }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder={(type === SupplierTypeEnum.AGENT) ? 'Select Agent' : 'Select Farmer'}
                  onChange={farmerChange}
                  allowClear={true}
                >
                  <Option value={null}>{(type === SupplierTypeEnum.AGENT) ? 'Select Agent' : 'Select Farmer'}</Option>
                  {farmers.map((farmer) => {
                    return <Option value={farmer.supplierId}>{farmer.supplierName + '(' + farmer.supplierCode + ')'}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 2 }}>
              <Form.Item>
                <Link to='/suppliers-form'><Button style={{ marginTop: 30 }}> Add </Button></Link>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="farmerCode" label="Farmer Code" rules={[{ required: false, message: 'Farmer Code is required' }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Farmer Code"
                  onChange={farmerCodeChange}
                  allowClear={true}
                >
                  <Option value={null}>Select Farmer Code</Option>
                  {farmerCodes.map((farm) => {
                    return <Option value={farm.supplierId}>{farm.supplierName + '(' + farm.supplierCode + ')'}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="product" label="Product" initialValue={2} rules={[{ required: true, message: 'Product is required' }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select Product"
                  allowClear={true}
                >
                  <Option value={null}>Select Product</Option>
                  {products.map((product) => {
                    return <Option value={product.productId}>{product.productName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedQty" label="Expected Quantity (In KGs)" rules={[{ required: true, message: 'Expected Quantity is required' }, {
                validator: (_, value) =>
                  value >= 10 ? Promise.resolve() : Promise.reject(new Error('Enter Valid Quantity')),
              }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedCount" label="Expected Count 1" rules={[{ required: true, message: 'Expected Count is required' }, {
                validator: (_, value) =>
                  value >= 1 ? Promise.resolve() : Promise.reject(new Error('Enter Valid Count')),
              }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedPrice" label="Expected Price 1(Per Kg)" rules={[{ required: true, message: 'Expected Price is required' }]} >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedCount2" label="Expected Count 2" rules={[{ required: false, message: 'Expected Count is required' }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedPrice2" label="Expected Price 2(Per Kg)" rules={[{ required: false, message: 'Expected Price is required' }]} >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedCount3" label="Expected Count 3" rules={[{ required: false, message: 'Expected Count is required' }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="expectedPrice3" label="Expected Price 3(Per Kg)" rules={[{ required: false, message: 'Expected Price is required' }]} >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Form.Item style={{ marginTop: '29px' }}>

                {/* <br /> */}
                <Button type="primary" onClick={setSelectedData}>
                  {btnChange ? 'Update' : 'Add'}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
              <Form.Item style={{ marginTop: '29px' }}>
                <Button style={{ backgroundColor: "#f19c9b", color: "white" }}
                  onClick={onReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <br />
          {
            showTable ?
              <Table dataSource={tableData} columns={(form.getFieldValue('supplierType') != SupplierTypeEnum.BROKER) ? columns.filter(col => (col.dataIndex != 'brokerName')) : columns} pagination={false} /> : <></>
          }
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button disabled={submitBtnDisable} type="primary" onClick={saveIndent}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </Card>
  );
}

export default IndentForm;
