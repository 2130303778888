import React, { useEffect, useRef, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { GradeServices, LevelService, RackPositionService, RackService, UnitcodeService, ZoneService } from '@gtpl/shared-services/masters';
import { GradesDto, LevelDropDownResponseModel, PlantsDropDown, RackDropDownDto, RackPositionModel, RacksForRackId, RackZoneLevelRequest, ZoneDto } from '@gtpl/shared-models/masters';
import './fg-stock-summary-report.css';
import { Card, Form, Row, Col, Select, Button, Statistic, InputNumber, Collapse, Tag, Tooltip, Descriptions, Table, Spin, Alert, Typography, DatePicker, Empty, Input } from 'antd';
import { FGStockService, StockInService } from '@gtpl/shared-services/warehouse-management';
import { CheckCircleOutlined, CloseCircleOutlined, RightSquareOutlined, EyeOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { LevelDropDownDto } from 'libs/shared-models/masters/src/lib/level/level-drop-down-dto';
import { StockTypeDropDown, stockTypeEnum, StockTypeStatus } from '@gtpl/shared-models/common-models';
import { CSVLink } from 'react-csv';
import { Excel } from 'antd-table-saveas-excel';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { truncateSync } from 'fs';
import { StockOutModel } from '@gtpl/shared-models/warehouse-management';

const { Option } = Select;
/* eslint-disable-next-line */
export interface FgStockSummaryReportProps { }

export function FgStockSummaryReport(
  props: FgStockSummaryReportProps
) {
  let stockType = StockTypeDropDown;
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [zoneDropDown, setZoneDropDown] = useState<StockOutModel[]>([]);
  const [gradeDropDown, setGradeDropDown] = useState<GradesDto[]>([]);
  const [zoneId, setZoneId] = useState<number>(undefined);
  const [rackId, setRackId] = useState<any>(undefined);
  const [keyUpdate, setKeyUpdate] = useState<number>(0);
  const zoneService = new ZoneService();
  const rackService = new RackService();
  const levelService = new LevelService
  const gradeServices = new GradeServices
  const rackPositionService = new RackPositionService();
  const [rackDropDown, setRackDropDown] = useState<RackDropDownDto[]>([]);
  const [reportData, setReportData] = useState<any[]>([]);
  const stockService = new FGStockService()
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [empty, setEmpty] = useState<boolean>(true)
  const [variantFiltersData, setVariantFiltersData] = useState<any[]>([]);
  const { Text } = Typography;
  const [disable, setDisable] = useState<boolean>(false);
  const [coldStorageVissible, setColdStorageVissible] = useState<boolean>(false);
  const { RangePicker } = DatePicker;
  const stockInService = new StockInService();

  const [selectedunit, setUnit] = useState<number>()
  const [plantData, setPlantData] = useState<PlantsDropDown[]>([]);
  const unitsService = new UnitcodeService();
  const [totQtyInLbs, setTotQtyInLbs] = useState<any>()
  const [sumTotalCartons, setSumTotalCartons] = useState<any>()
  const [variantDropdown, setVariantDropdown] = useState<any>()

  /**
   * 
   * @param selectedKeys 
   * @param confirm 
   * @paramQtyInLbs dataIndex 
   */
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) { setTimeout(() => searchInput.current.select()); }
    },
    render: text =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : text
      )
        : null

  });

  const commonColumns: any[] = [
    {
      title: 'Product',
      dataIndex: 'variant',
      width: 200,
      fixed: 'left',
      sorter: (a, b) => a.variant?.length - b.variant.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('variant'),
      // filters: variantFiltersData,
      // filterMultiple: true,
      // onFilter: (value, record) => {
      //     return record.variant == value;
      // },
    },
    {
      title: 'Product Short Code',
      dataIndex: 'prodShortCode',
      width: 100,
      fixed: 'left',
      // sorter: (a, b) => a.prodShortCode?.length - b.prodShortCode.length,
      // sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('prodShortCode'),
      render: (value, record) => {
        return record.prodShortCode ? record.prodShortCode : record.variant;
      }
    },
    {
      title: "Cartons",
      dataIndex: 'totalCartons',
      width: 100,
      fixed: 'left',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalCartons.length - b.totalCartons.length || String(a.totalCartons)?.localeCompare(b.totalCartons),
      sortDirections: ['descend', 'ascend'],
      render: (value) => (<span style={{ float: 'right', width: 80 }}>{(value)}</span>)

    },
    {
      title: "Total Qty (in LB's)",
      dataIndex: 'total',
      width: 110,
      fixed: 'left',
      align: 'right',
      sorter: (a, b) => a.total.length - b.total.length || a.total?.localeCompare(b.total),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('total'),
      render: (value) => (<span style={{ float: 'right', width: 80 }}>{(value)}</span>)
    },

  ]
  const [columns, setColumns] = useState(commonColumns);

  useEffect(() => {
    form.setFieldsValue({ stockType: stockTypeEnum.Dummy });
    getAllGrades();
    getZones();
    getReportData();
    getAllPlants();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ unitId: Number(localStorage.getItem('unit_id')) })
    }
    getVariantDropdown();
  }, []);

  const getAllGrades = () => {
    gradeServices.getAllGrades().then((res) => {
      if (res.status) {
        setGradeDropDown(res.data);
      }
      else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setGradeDropDown([]);
      }
    })
  }

  const getZones = () => {
    stockInService.getColdDropForStockRep({ unitId: Number(localStorage.getItem('unit_id')) }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setZoneDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setZoneDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setZoneDropDown([]);

    });
  }

  const getVariantDropdown = (value?: any) => {
    const req = new RackZoneLevelRequest()
    req.unitId = value
    stockService.getVariantDropdown(req).then((res) => {
      if (res.status) {
        setVariantDropdown(res.data)
      } else {
        setVariantDropdown([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }


  const getRacks = (zoneId: number, reservedForDummy: boolean) => {
    rackService.getdummyRacksByZone({ zoneId: zoneId, reservedForDummy: reservedForDummy }).then((res) => {
      if (res.status) {
        // console.log(res.data);
        setRackDropDown(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
        setRackDropDown([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setRackDropDown([]);
    });
  }

  const getReportData = () => {
    setLoading(true)
    setDisable(true)
    setEmpty(false)
    const req = new RackZoneLevelRequest()
    let rackId = form.getFieldValue('rack')
    let zoneId = form.getFieldValue('coldStorageId')
    let level = form.getFieldValue('level')
    let stockType = form.getFieldValue('stockType')
    if (form.getFieldValue('reportingDate') != undefined) {
      const selectedFromDate = moment(form.getFieldValue('reportingDate')[0]).format('YYYY-MM-DD');
      const selectedToDate = moment(form.getFieldValue('reportingDate')[1]).format('YYYY-MM-DD');
      req.reportingFromDate = (selectedFromDate)
      req.reportingToDate = (selectedToDate)
    }
    req.rackId = rackId
    req.levelId = level
    req.zoneId = zoneId
    req.stockType = stockType
    req.variantCode = form.getFieldValue('variantCode')
    req.loginUnit = Number(localStorage.getItem('unit_id'));
    if (Number(localStorage.getItem('unit_id')) != 5) {
      req.unitId = Number(localStorage.getItem('unit_id'));
    } else {
      req.unitId = selectedunit
    }
    // req.unitId = Number(localStorage.getItem('unit_id'))
    req.role = localStorage.getItem('role').replace(/"/g, '');
    console.log(req)
    const col = [...commonColumns];
    const uniqueColumnSet = new Set<string>();
    stockService.getStockSummaryReportData(req).then((res) => {
      setDisable(false)
      setLoading(false)

      if (res.status) {
        let filtersArray: { text: string, value: string }[] = []
        let uniq = res.data.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.variant === thing.variant
          ))
        );
        uniq.map(item => {
          if (!(filtersArray.includes({ text: item.variant, value: item.variant })))
            filtersArray.push({ text: item.variant, value: item.variant })
        })
        console.log(col)
        console.log(col.find(eachCol => eachCol.title === 'Product'));
        col.find(eachCol => eachCol.title === 'Product').filters = filtersArray;
        // setVariantFiltersData(filtersArray)
        const summaryMap = new Map<string, number>();
        let totalVarint = 0;
        let totalCartonsSum = 0;
        let valrintTotal = 0;
        res.data.forEach(element => {
          let variantTotal = 0;
          (element.gradeQty).forEach(val => {
            element[val.grade] = Number(val.qty).toLocaleString(undefined, { maximumFractionDigits: 0 });
            variantTotal += Number(val.qty); // grade wise
            valrintTotal += Number(val.qty); // grade wise
            totalVarint += Number(val.qty);

            uniqueColumnSet.add(val.grade);
            if (!summaryMap.has(val.grade)) {
              summaryMap.set(val.grade, 0)
            }
            summaryMap.set(val.grade, summaryMap.get(val.grade) + Number(val.qty));

          });
          totalCartonsSum += Number(element.totalCartons);
          element['total'] = (variantTotal).toLocaleString(undefined, { maximumFractionDigits: 0 });
          element['totalCartons'] = Number(element.totalCartons).toLocaleString(undefined, { maximumFractionDigits: 0 });
          element['unit'] = element.unit;

        });
        // (res.data[0].gradeQty).forEach((res, index) => {
        //   if (res.qty == 0) {
        //     for (let varientsIndex = 1; varientsIndex < res.data.length; varientsIndex++) {
        //       if (res.data[index].gradeQty[index] != 0) {
        //         break
        //       }
        //     }
        //   }
        // })

        setTotQtyInLbs((valrintTotal).toLocaleString(undefined, { maximumFractionDigits: 0 }))
        setSumTotalCartons(totalCartonsSum)
        setColumns(col);
        setKeyUpdate(preVal => preVal + 1);
        // setDisableSubmit(true);
        const tabelData = [...res.data];
        const summaryObj = new Object();
        summaryObj['total'] = (totalVarint).toLocaleString(undefined, { maximumFractionDigits: 0 });
        summaryObj['unit'] = <b>-</b>;
        summaryObj['variant'] = <b>Total Qty (in LB's)</b>;
        summaryObj['totalCartons'] = <b>Total Carton : {totalCartonsSum.toLocaleString(undefined, { maximumFractionDigits: 0 })}</b>;

        for (const [mapKey, mapVal] of summaryMap.entries()) {
          const qty = (mapVal).toLocaleString(undefined, { maximumFractionDigits: 0 });
          summaryObj[mapKey] = qty;
          if(Number(qty)===0){
            uniqueColumnSet.delete(mapKey)
          }
        }

        uniqueColumnSet.forEach((grade) => {
          const dynCol = {
            title: grade,
            dataIndex: grade,
            align: 'right',
            render: (value) => (<span style={{ minWidth: 60, float: 'right' }}>{(value)}</span>)
          }
          col.push(dynCol);
        })
        tabelData.push(summaryObj);
        console.log(tabelData);
        setReportData(tabelData);
      } else {
        setReportData([])
        setDisableSubmit(false);
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
      .catch((err) => {
        console.log("error coming" + err)
        setDisableSubmit(false);
        setDisable(false);
        AlertMessages.getErrorMessage(err.message);
      });

  }
  // console.log("hellooo")


  const exportExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Stock Summary Report')
      .addColumns(columns)
      .addDataSource(reportData, { str2num: true })
      .saveAs('fgStockSummaryReport.xlsx');
  }



  const handleColdStorage = (value) => {
    // console.log(value);
    setZoneId(value);
    setColdStorageVissible(true);
    getRacks(value, false)
  }

  const handleRack = (value) => {
    console.log('handleRack', value);
    setRackId(value);
    // console.log('dd', rackId)

  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  function onBlur() {
    console.log('blur');
  }
  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  const onReset = () => {
    form.resetFields();
    if (Number(localStorage.getItem('unit_id')) != 5) {
      form.setFieldsValue({ plantData: Number(localStorage.getItem('unit_id')) })
    }
    getReportData();
    setColdStorageVissible(false);
  };

  const getAllPlants = () => {
    unitsService.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantData(res.data);
      } else {
        setPlantData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setPlantData([]);
    })
  }


  const handleUnit = (value) => {
    setUnit(value)
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Stock Summary Report</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >

      <Form layout={'vertical'} form={form} name="control-hooks">
        <Row gutter={24}>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <Form.Item name="reportingDate" label="Reporting From Date          -             To Date" rules={[{
              required: false,
              message: "Reporting From Date",

            },
            ]}>
              <RangePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item name="coldStorageId" label="Cold Storage"
            >
              {/* <Input /> */}
              <Select
                placeholder="Select Cold Storage"
                onChange={handleColdStorage}
                allowClear
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {zoneDropDown?.map(zoneData => {
                  return <Option key={zoneData.zoneId} value={zoneData.zoneId}>{zoneData.coldStorage}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          {coldStorageVissible ?
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
              <Form.Item
                name="rack"
                label="Rack"

              >
                <Select
                  placeholder="Select Rack"
                  onChange={handleRack}
                  allowClear
                  optionFilterProp="children"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {rackDropDown.map(saleOrderDropDown => {
                    return <Option key={saleOrderDropDown.rackId} value={saleOrderDropDown.rackId}>{saleOrderDropDown.rackCode}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col> : ""}

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <Form.Item
              name="stockType"
              label="Stock Type"
              rules={[
                {
                  required: false,
                  message: 'Stock Type is required'
                },
              ]}
            >
              <Select
                placeholder="Select Stock Type"
                // onChange={getSaleOrderItems}
                allowClear

              >
                <Option value={''}>Please Select</Option>
                {stockType.map(stockType => {
                  return <Option value={stockType.value}>{stockType.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item
              name="unitId"
              label=" Unit"
              rules={[
                {
                  required: false, message: 'Select Unit',
                },

              ]}
              initialValue="All Unit's"

            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Unit"
                allowClear
                style={{ width: '100%' }}
                // onSelect={(value)=>{ getVariantDropdown(value)}}
                onChange={handleUnit}
                mode='multiple'
                disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}

              >
                <Option value={null}>All Unit's</Option>
                {plantData.map(dropData => {
                  return <Option value={dropData.plantId}>{dropData.plantName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
            <Form.Item
              name="variantCode"
              label="Variant"
              rules={[
                {
                  required: false, message: 'Select Variant',
                },

              ]}

            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select Variant"
                allowClear
                style={{ width: '100%' }}
                mode='multiple'

              >
                {variantDropdown?.map(dropData => {
                  return <Option value={dropData.varientCode}>{dropData.varientCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} style={{ paddingTop: '28px' }}  >
            <Button
              type="primary"
              icon={<SearchOutlined />}
              disabled={disable}
              onClick={() => getReportData()}
            >
              Search
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} style={{ paddingTop: '28px' }}>
            <Button type="primary" htmlType="submit" onClick={onReset} >
              Reset
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} style={{ paddingTop: '28px' }}  >
            <Button onClick={() => { exportExcel(); }} >
              Get Excel
            </Button>
          </Col>

        </Row>
      </Form>
      {/* {disableSubmit ? */}

      {

        reportData.length > 0 ?
          <>
            <Card title='Report Summary' >
              <Row gutter={24} >
                <Col span={4}>
                  <Statistic title="Total Quantity in lbs" value={totQtyInLbs} />
                </Col>
                <Col span={4}>
                  <Statistic title="Total Cartons" value={sumTotalCartons} />
                </Col>
                {/* <Col span={4}>
              <Statistic title="Total Qunatity(in KG's)" value={Number(totQtyInLbs) * 0.45} />
            </Col> */}
                {/* <Col span={4}>
              <Button icon={<FileExcelOutlined />} style={{ marginTop: 30, }} onClick={() => { exportExcel(); }}>
                Get Excel
              </Button>
            </Col>
            <Col span={4}>
              <Button icon={<FilePdfOutlined />} style={{ marginLeft: 20, marginTop: 30, }} onClick={() => { exportToPdf(); }}>
                Get PDF
              </Button>
            </Col> */}

              </Row>
            </Card>
            <Table
              // key={keyUpdate}
              //  title={() => 'Report data' }
              columns={columns}
              dataSource={reportData}
              scroll={{ x: true }}
              bordered
              pagination={false}
              onChange={onChange}
            /> </> : <Empty description={<h1>No data found</h1>}></Empty>


      }


    </Card>
  );
}

export default FgStockSummaryReport;
