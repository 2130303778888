import React, { useEffect, useState } from 'react';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { SaleOrderNumberRequest, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { SaleOrderDetailDto, SaleOrderDetailViewInfoDTO } from '@gtpl/shared-models/sale-management';
import {useLocation} from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom';
import {SoInfoView} from '@gtpl/pages/sale-management/sale-pages/so-info-view';

import './so-info-view-pages.css';

/* eslint-disable-next-line */
export interface SoInfoViewPagesProps {
  saleOrderId:number
  screen:string
}

export function SoInfoViewPages(
  props: SoInfoViewPagesProps
) {

  const [selectedSaleOrder, setSelectedSaleOrder] = useState<SaleOrderDetailViewInfoDTO>(undefined)
  const [totNetWeight, setTotNetWeight] = useState<number>(0)
  const [totNetWeightInKg, setTotNetWeightInKg] = useState<number>(0)
  const [redirectView, setRedirectView] = useState<boolean>(false)

  let location = useLocation();
  
  const service = new SaleOrderService();
  let totWeight = 0;
  let totWeightInKg = 0;
  const getSaleOrderDetailsById= (reqDate) => {
    service.getSaleOrderDetailsById({saleOrderId:reqDate}).then(res => {
      if (res.status) 
      {
        console.log(res.data);
        (res.data.itemData).forEach(data => {
          
          console.log(data);
          if(data.uom == "KG" || data.uom == "gm"){
            totWeight += Number(data.netWeight) * 2.20462;
          }
          else{
            totWeight += Number(data.netWeight);
          }

          if(data.uom == "LB" || data.uom == "OZ"){
            totWeightInKg += Number(data.netWeight) * 0.453592;
          }
          else{
            totWeightInKg += Number(data.netWeight);
          }
          
          // else if(data.uom == "gm"){
          //   totWeight += Number(data.netWeight) * 0.00220462;
          // }
        })
        console.log(totWeight);
        setTotNetWeight(totWeight);
        setTotNetWeightInKg(totWeightInKg);
        setSelectedSaleOrder(res.data);
        console.log(selectedSaleOrder);
      } else {
        if (res.intlCode) {
          setSelectedSaleOrder(undefined);
            // AlertMessages.getErrorMessage(res.internalMessage);
            goToTracker();
        } else {
         AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setSelectedSaleOrder(undefined);
    })
  }
  // const getTotal = ({selectedSaleOrder}) => {
  //   console.log({selectedSaleOrder});

  // }
  const goToTracker = () => {
    setRedirectView(true);
  }
const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
    let saleId= location.state
    if(props.saleOrderId){
      saleId = props.saleOrderId
    }
    getSaleOrderDetailsById(saleId);
    // getTotal({selectedSaleOrder})
  }, [])
  return (
    <>



<SoInfoView SaleOrderDetails={selectedSaleOrder} netWeightConversion = {totNetWeight} netWeightInKg = {totNetWeightInKg} screen={props.screen}/>
    </>
  );
}

export default SoInfoViewPages;
