export class ItemRequest {
    itemId: number;
    unitId?: number;
    saleOrderId?: number;
    locationId?: number;
    saleOrderItemId?: number;

    constructor(itemId: number, unitId?: number, saleOrderId?: number, locationId?: number,saleOrderItemId?: number) {
        this.itemId = itemId;
        this.unitId = unitId;
        this.saleOrderId = saleOrderId;
        this.locationId = locationId;
        this.saleOrderItemId = saleOrderItemId;

    }
}