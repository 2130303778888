
export class ContainerSoIdRequest {
    saleOrderId?: number;


    /**
     * @param saleOrderId
    */
    constructor(saleOrderId?: number){
       this.saleOrderId=saleOrderId;
    }
}