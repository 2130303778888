
import { Card, Button, Form, Descriptions } from 'antd';
import './stock-in-form.css'
import React, { useEffect, useState } from 'react';
import form from 'antd/lib/form';
import { QrcodeOutlined, SearchOutlined, PrinterOutlined, BarcodeOutlined } from '@ant-design/icons';
import { StockInPrinResponse, StockInPritnRequest, StockInService } from '@gtpl/shared-services/warehouse-management';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import moment from 'moment';


export interface StockinPrintProps { reportingLogId: number }
export function StockinPrint(props: StockinPrintProps) {

  const [from] = Form.useForm();
  const [stockinPrint, setstockinPrint] = useState<StockInPrinResponse[]>();
  const [responseData, setResponseData] = useState<StockInPrinResponse[]>([]);
  const [transactionType, settransactionType] = useState<any>([]);
  const time = moment().format("DD-MM-YYYY HH:mm")


  const service = new StockInService;
  useEffect(() => {
    getStockInPrint();

  }, [])
  const getCssFromComponent = (fromDoc, toDoc) => {
    Array.from(fromDoc.styleSheets).forEach((styleSheet: any) => {
      // console.log(styleSheet.cssRules);
      // if (styleSheet.cssRules) { // true for inline styles
      //   const newStyleElement = toDoc.createElement('style');
      //   Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
      //     newStyleElement.appendChild(toDoc.createTextNode(cssRule.cssText));
      //   });
      //   toDoc.head.appendChild(newStyleElement);
      // }
    });
  }
  console.log(props.reportingLogId, 'props')
  const printOrder = () => {
    const divContents = document.getElementById('printme').innerHTML;
    const element = window.open('', '', 'height=700, width=1024');
    element.document.write(divContents);
    getCssFromComponent(document, element.document);
    element.document.close();
    element.print();
    element.close(); // to close window when click on cancel/Save
    // setIsModalVisible(true); // model should be open
  }

  const getStockInPrint = () => {
    const req = new StockInPritnRequest(props.reportingLogId)

    service.getStockInPrint(req).then(res => {
      if (res.status) {

        setResponseData(res.data);

      } else {
        setstockinPrint([]);
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
    return(
   <>
   <div id='printme' className='ticket'>
    {
      responseData.length> 0 ?
      <>
        <td className='ta-b' colSpan={11}> <Button onClick={printOrder} className='hidden' style={{ float: 'right', backgroundColor: '#69c0ff' }}><span style={{ color: 'white' }}><PrinterOutlined /> Print</span></Button></td>
   <br />
  
            <table className={'ta-b'} id ={'body'} style={{ width: '100%' }} > 
            <tr><td className={'ta-b'} colSpan={11} style={{textAlign: 'right'}}>{time}</td></tr>
        
        <tr> <td className={'ta-b'} colSpan={11} >PO NO:<b>{(responseData[0].stocktype== 'SaleOrder' ? responseData[0].customerPo: responseData[0].stocktype == 'Dummy' ? 'DUMMY':'-')}</b></td></tr>
    <tr><td className={'ta-b'} colSpan={11}>PRODUCT:<b>{(responseData[0].varientcode) ? responseData[0].varientcode:'-'}</b></td></tr>
      <br></br>
          <tr>
          <td className={'ta-b'} colSpan={3}>Customer</td>
          <td className={'ta-b'} colSpan={2}><b>{(responseData[0].clientName) ? responseData[0].clientName: '-'}</b></td>
          </tr>
          
          <tr>
          <td className={'ta-b'} colSpan={3}>Brand</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].brandName)? responseData[0].brandName:'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Pallet Code</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].palletCode)? responseData[0].palletCode:'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Pack Style</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].packmethodName)? responseData[0].packmethodName:'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Rack Position</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].rackPostion)? responseData[0].rackPostion:'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Transaction Type</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].transactionType)? responseData[0].transactionType == 'rm' ? 'Fresh Production': responseData[0].transactionType :'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Reported Cases</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].reportedCases)? responseData[0].reportedCases:responseData[0].reportedCases}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Pouch Shortage</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].poucShortage)? responseData[0].poucShortage:'-'}</b></td>
            
          </tr>
          <tr>
          <td className={'ta-b'} colSpan={3}>Carton Shortage</td>
          <td className={'ta-b'}  colSpan={2}><b>{(responseData[0].cartonShortage)? responseData[0].cartonShortage:'-'}</b></td>
            
          </tr>
</table>
</>:<></>
    }
   </div>
   </>
    )
}
export default StockinPrint