import { PackingStatus, StockStatus } from "@gtpl/shared-models/common-models";
import { FgStockStatusEnum } from "./fg-stock-status.enum";

export class SuggestPalletRequest {
    saleOrderItemId: number;
    location?: number;
    stockType?: StockStatus;
    pouchesShortage?: PackingStatus;
    cartonsShortage?: PackingStatus;
    productCategory?:string;
    packStyleId?:number;
   
    constructor(saleOrderItemId: number,location?: number, stockType?: StockStatus, pouchesShortage?: PackingStatus,
        cartonsShortage?: PackingStatus,productCategory?:string, packStyleId?:number){
        this.saleOrderItemId = saleOrderItemId;
        this.location = location;
        this.stockType = stockType;
        this.pouchesShortage =  pouchesShortage;
        this.cartonsShortage =  cartonsShortage;
        this.productCategory =  productCategory;
        this.packStyleId =  packStyleId;
    }
}
