import React, { useState, useEffect } from 'react';
import {
  Button, Card, Col, DatePicker, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Layout, Modal, Row, Select, Table, Tooltip,
} from 'antd';
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import './raw-material-grn-form.css';
import { GrnData, GrnDetails, GrnItemDefects, IndentModel, IndentRequest, PlantRequest, RmGrnItemDetails } from '@gtpl/shared-models/raw-material-procurement';
import { RMGrnService } from '@gtpl/shared-services/raw-material-procurement';
import { IndentService } from '@gtpl/shared-services/raw-material-procurement';
import { saleOrder, SaleOrderService } from '@gtpl/shared-services/sale-management';
import { CountRequest, ItemsDetails, ProductIdRequest, SaleOrderDropDownDto, SaleOrderItemsDropDownDto, SaleOrderProductIdRequest, SoTypeReq, UnitsOfWeightInput } from '@gtpl/shared-models/sale-management';
import moment from 'moment';
import { EmployeeRolesEnum, PlantsEnum, SoProcessTypesEnum, SubPlantCodesEnum, SupplierTypeEnum, UiConfig, UomEnum } from '@gtpl/shared-models/common-models';
import { SoItemDetailsBySoIdDTO } from 'libs/shared-models/sale-management/src/lib/sale-order/dto/so-item-details-by-so-id.dto';
import { ProductService, UnitcodeService } from '@gtpl/shared-services/masters';
import { PlantsDropDown, UnitcodeDto } from '@gtpl/shared-models/masters';
import AddDefects from './add-defects';
import Item from 'antd/lib/list/Item';
export interface RawMaterialGrnFormProps {
  grnData: any;
  updateItem: (grnData: any) => void;
  isUpdate: boolean;
  // saveItem:(varirantData:VariantDto) => void;
  closeForm: () => void;
  indentId: number;

}
const { Option } = Select;
export const RawMaterialGrnForm = (props: RawMaterialGrnFormProps) => {
  const plantId = Number(localStorage.getItem('unit_id'));
  const userName = localStorage.getItem("createdUser");
  const [form] = Form.useForm();
  const [grnForm] = Form.useForm();

  const [indentNumbers, setIndentNumbers] = useState<IndentModel[]>([]);
  const [grnData, setGrnData] = useState<GrnData>(null);
  // const [grnData, setGrnData] = useState<GrnDetails>(null);
  const [soData, setSoData] = useState<SaleOrderDropDownDto[]>([]);
  const [soItemDetails, setSoItemDetails] = useState<SoItemDetailsBySoIdDTO>(undefined);
  const [selectedSoData, setSelectedSoData] = useState<number>(null)
  const [showTable, setShowTable] = useState<boolean>(false)
  const [soItemData, setSoItemData] = useState<ItemsDetails[]>([]);
  const [factoriesData, setFactoriesData] = useState<PlantsDropDown[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [newTableData, setNewTableData] = useState<any[]>(tableData)
  const [subFactoriesData, setSubFactoriesData] = useState<PlantsDropDown[]>([]);
  const [soNumbers, setSoNumbers] = useState<any[]>([]);
  const service = new RMGrnService();
  const indentService = new IndentService();
  const saleOrderService = new SaleOrderService();
  const unitcodeService = new UnitcodeService();
  const username = localStorage.getItem('username')
  const [indentData, setIndentData] = useState<IndentModel>();
  const [addDeffects, setAddDeffects] = useState<any>(false)
  const [defectsData, setDefectsData] = useState<any[]>([]);
  const [index, setIndex] = useState<any>();
  const [products, setProducts] = useState<any[]>([]);
  const productsService = new ProductService();
  const { id } = useParams<any>()
  Number(id)
  let history = useHistory();

  let location = useLocation();

  let location1 = useLocation();



  let createdUser = '';
  if (!props.isUpdate) {
    createdUser = localStorage.getItem('createdUser');
  }



  useEffect(()=> {
    if(tableData.length > 0){
      console.log(tableData)
    }
  },[tableData])

  useEffect(() => {
    getAllMainPlants();
    getAllSubPlants();
    getAllIndents();
    getProductsdropData()
    if (id == undefined) {
      // getIndentDetailsForIndentId(props.grnData.indentId);
      // getIndentItemsData(props.indentId);
    }
    else {
      getIndentItemsData(Number(id));
      // getIndentDetailsForIndentId(props.indentId);
    }
    if (props.grnData) {
      getAllSaleOrders();
      if (props.grnData.saleOrderId) {
        getAllSoItemsDropDown(props.grnData.saleOrderId, props.grnData.product, props.grnData.count)
      }
      if (props.grnData.saleOrderItemId) {
        getSaleOrderItemDetails(props.grnData.saleOrderItemId)
      }
      //  if(props.isUpdate&&selectedSoData > 0){
      //    alert('yuyuyuy')
      //    console.log(props.grnData.saleOrderId);
      //   getAllSoItemsDropDown(form.getFieldValue('saleOrderId'),props.grnData.product)
      //   }
    }
    // if(location.state){
    //   getGrnDataForId(location.state);
    // }

  }, [])

  const getProductsdropData = () => {
    productsService.getAllActiveProducts().then((res) => {
      if (res.status) {
        setProducts(res.data)
      } else {
        setProducts([])
      }
    })
  }

  const getIndentItemsData = (indentId) => {
    const indentReq = new IndentRequest();
    indentReq.indentId = indentId;
    indentService.getIndentDataById(indentReq).then((res) => {
      if (res.status) {
        setShowTable(true)
        setTableData(res.data)
        form.setFieldsValue({plant:res.data[0].plantId})
      }
      else {
        if (res.intlCode) {
          setTableData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }

  const getAllMainPlants = () => {
    unitcodeService.getAllMainPlants().then((res) => {
      if (res.status) {
        setFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getAllSubPlants = () => {
    unitcodeService.getAllSubPlants().then((res) => {
      if (res.status) {
        setSubFactoriesData(res.data)
      }
      else {
        if (res.intlCode) {
          setSubFactoriesData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
  }
  const getIndentDetailsForIndentId = (indentId) => {
    const req = new IndentRequest();
    req.indentId = indentId;
    indentService.getIndentDetailsById(req).then((res) => {
      if (res.status) {
        setIndentData(res.data);
        form.setFieldsValue({
          indentId: res.data.indentId,
          supplierType: res.data.supplierType,
          farmer: res.data.farmer,
          farmerCode: res.data.farmerCode,
          plantId: res.data.plantId,
          // quantity: res.data.expectedQty,
          // count: res.data.expectedCount,
          // price: res.data.expectedPrice

        })
        getAllSaleOrders();
        if (props.isUpdate && props.grnData.saleOrderId) {
          getAllSoItemsDropDown(props.grnData.saleOrderId, res.data.product, res.data.expectedCount)
        }
      }
    })
  }


  const getAllIndents = () => {
    indentService.getAllIndents().then(res => {
      if (res.status) {
        setIndentNumbers(res.data);
      } else {
        if (res.intlCode) {
          setIndentNumbers([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setIndentNumbers([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const getGrnDataForId = (id) => {
    service.getGrnDataForId(id).then(res => {
      if (res.status) {
        setGrnData(res.data);
      } else {
        if (res.intlCode) {
          setGrnData(null);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setGrnData(null);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllSaleOrders = () => {
    //   const plantReq = new PlantRequest()
    //   plantReq.plantId =JSON.parse(localStorage.getItem('unit_id'))
    //   //after grading pattern this service should replaced.getting all saleorders for temperory
    //   saleOrderService.getAllActiveSaleOrders(plantReq).then((res) => {
    //     if (res.status) {
    //       setSoData(res.data);
    //     } else {
    //       if (res.intlCode) {
    //         AlertMessages.getErrorMessage(res.internalMessage);
    //       } else {
    //         AlertMessages.getErrorMessage(res.internalMessage);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     AlertMessages.getErrorMessage(err.message);
    //     setSoData([]);
    //   });
    const req = new SoTypeReq()
    req.soType = form.getFieldValue('soType')
    req.unitId =  props.grnData.plantId
    saleOrderService.getAllSoForAssignSo(req).then((res) => {
      if (res.status) {
        setSoNumbers(res.data);
      } else {
        setSoNumbers([]);
      }
    })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setSoNumbers([]);
      });
  };

  //   const getAllSoDropDown = (product)=>{
  //     const req = new ProductIdRequest(product, plantId)
  //     saleOrderService.getSaleOrdersForProduct(req).then((res)=>{
  //       if(res.status){
  //         setSoData(res.data);
  //         // AlertMessages.getSuccessMessage('Sale Orders retrived Successfully');
  //       }else {
  //         if (res.intlCode) {
  //           setSoData([]);
  //           // AlertMessages.getErrorMessage(res.internalMessage);
  //         } else {
  //           // AlertMessages.getErrorMessage(res.internalMessage);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       setSoData([]);
  //       AlertMessages.getErrorMessage(err.message);
  //     });
  // };

  const getSaleOrderItemDetails = (value) => {
    saleOrderService.getSaleOrderItemDetailsBySoItemId({ saleOrderId: selectedSoData, saleOrderItemId: value }).then((res) => {
      if (res.status) {
        setSoItemDetails(res.data);
      }
      else {
        if (res.intlCode) {
          setSoItemDetails(undefined);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    })
      .catch((err) => {
        setSoItemDetails(null);
        AlertMessages.getErrorMessage(err.message);
      });
  }
  const handleSoChange = (value,option) => {
    setSelectedSoData(value);
    // form.setFieldsValue({
    //   saleOrderItemId: option.soItem
    // })
    form.setFieldsValue({
      saleOrderId: option.soId
    })
    // getAllSoItemsDropDown(value, props.grnData.product, props.grnData.count);
  }

  const storeActualCount = (e, index, data) => {
    const dynTableData = [...tableData]
    dynTableData[index].actualCount = e
    setTableData(dynTableData);
  }

  const storeActualQty = (e, index, data) => {
     const dynTableData = [...tableData]
    dynTableData[index].actualQty = e
    setTableData(dynTableData);
   
  }

  const storeNumberOfBoxes = (e, index, data) => {
    const dynTableData = [...tableData]
    dynTableData[index].numberOfBoxes = e
    setTableData(dynTableData);
  }

  const storeSoftPercentage = (e, index, data) => {
    const dynTableData = [...tableData]
    dynTableData[index].softPercentage = e
    setTableData(dynTableData);
  }

  const storeLooseShell = (e, index, data) => {
    const dynTableData = [...tableData]
    dynTableData[index].looseShell = e
    setTableData(dynTableData);
  }

  const storeFungusPercentage = (e, index, data) => {
    tableData[index].fungusPercentage = e
    setTableData([...tableData]);
  }

  const storeSubplant = (e, index, data) => {
    tableData[index].subplant = e
    setTableData([...tableData]);
  }
  const storeRemarks = (e, index, data) => {
    const dynTableData = [...tableData]
    dynTableData[index].remarks = e.target.value
    setTableData(dynTableData);
  }

  const columns = [
    {
      title: 'Broker',
      dataIndex: 'brokerName',
      key: 'brokerName',
      render: (text, data, index) => {
        return <span>{text}</span>
      }
    },
    {
      title: 'Farmer',
      dataIndex: 'farmerName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },
    {
      title: 'Farm',
      dataIndex: 'farmName',
      render: (text, data, index) => {

        return <span>{text}</span>
      }
    },

    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text, data, index) => {

        return <span><Select placeholder={'Select Product'} defaultValue={text}>
          {products.map((i) => {
            return <Option value={i.productId}>{i.productName}</Option>
          })}
        </Select></span>
      }
    },
    // {
    //   title: 'Expected Count',
    //   dataIndex: 'expectedCount',
    //   render: (text, data, index) => {
    //     return <span>{text} 
    //     </span>
    //     // return <span>{data.brandId.children}</span>
    //   }
    // },
    {
      title: 'Expected Count/Qty',
      dataIndex: 'expectedQty',
      render: (text, data, index) => {
        return <span>{`${data.expectedCount}/${Number((text / 1000).toFixed(2))}T`}
        </span>
        // return <span>{data.brandId.children}</span>
      }
    },
    {
      title: 'Actual Count',
      dataIndex: 'count',
      render: (text, data, index) => {
        return <span>
         <label style={{color:'red',position:'absolute',top:'0px',left:'1px'}}>*</label>
          <InputNumber key={index} min={0} defaultValue={text}
          onChange={e => storeActualCount(e, index, data)}
        /></span>
        // return <span>{data.brandId.children}</span>
      }
    },
    {
      title: 'Actual Quantity(kg)',
      dataIndex: 'Qty',
      render: (text, data, index) => {
        return <span>
          <label style={{color:'red',position:'absolute',top:'0px',left:'1px'}}>*</label>
          <InputNumber  key={index} min={0} defaultValue={text}
          onChange={(value) => storeActualQty(value, index, data)}
        /></span>
        // return <span>{data.brandId.children}</span>
      }
    },
    {
      title: 'No Of Boxes',
      dataIndex: 'no_of_boxes',
      render: (text, data, index) => {
        return <span>
          <label style={{color:'red',position:'absolute',top:'0px',left:'1px'}}>*</label>
          <InputNumber key={index} min={0} disabled={props.isUpdate ? true : false} defaultValue={text}
          onChange={(value) => storeNumberOfBoxes(value, index, data)} />
        </span>
        // return <span>{data.brandId.children}</span>
      },

    },
    {
      title: 'Plant Lot Number',
      dataIndex: 'remarks',
      render: (text, data, index) => {
     
         return <span>
              <label style={{color:'red',position:'absolute',top:'0px',left:'1px'}}>*</label>
          <Input key={index}   disabled={props.isUpdate ? true : false} defaultValue={text}
          onChange={(value) => storeRemarks(value, index, data)}
        /></span>
        // return <span>{data.brandId.children}</span>
      },
    },
    // {
    //   title: 'Loose Shell',
    //   dataIndex: 'looseShell',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={text}
    //       onChange={e => storeLooseShell(e, index, data)}
    //     /></span>
    //     // return <span>{data.brandId.children}</span>
    //   },
    // },
    // {
    //   title: 'Soft %',
    //   dataIndex: 'softPercentage',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={text}
    //       onChange={e => storeSoftPercentage(e, index, data)}
    //     /></span>
    //     // return <span>{data.brandId.children}</span>
    //   },
    // },
    // {
    //   title: 'Fungus %',
    //   dataIndex: 'fungusPercentage',
    //   render: (text, data, index) => {
    //     return <span><InputNumber min={0} defaultValue={text}
    //       onChange={e => storeFungusPercentage(e, index, data)}
    //     /></span>
    //     // return <span>{data.brandId.children}</span>
    //   },
    // },
    // {
    //   title: 'Price',
    //   dataIndex: 'expectedPrice',
    //   render: (text, data, index) => {

    //     return <span>{text}</span>
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'add',
      width: '300px',
      render: (text, data, index) => {
        return <span>
          <Button size='small' type='primary' onClick={() => addRow(data, index)}>Add</Button>
          {tableData?.length > 1 && <>
            <Divider type='vertical' />
            <Button size='small' type='ghost' onClick={() => remove(index)}>  Remove</Button> </>
          }
          <Divider type='vertical' />
          <Button size='small' type='primary' onClick={() => { setIndex(index + 1); setAddDeffects(true) }}>Add Defects</Button>
        </span>
      }
    }
  ]

  function addRow(rowData, index) {
    // tableData.splice(index, 0, rowData)
    // setTableData(prev => [...tableData])

    const newData = [...tableData]

    //   tableData[index+1].codeListMainItemId = null
    let obj = {...tableData[index],codelistmainItemId:null}
      newData.push(obj)
    //   newData[newData.length-1].codelistmainItemId = null
      console.log(newData)
      setTableData(newData)
  }

  const remove = (index) => {
    tableData.splice(index, 1)
    setTableData(prev => [...tableData])
  }

  const getAllSoItemsDropDown = (selectedSoData, product, count) => {
    const soREq: SaleOrderProductIdRequest = {
      saleOrderId: selectedSoData,
      productId: product,
      plantId: plantId,
      count: count,
    }
    saleOrderService.getSaleOrderItemsForSoId(soREq).then((res) => {
      if (res.status) {
        setSoItemData(res.data);
        // AlertMessages.getSuccessMessage('Sale Orders retrived Successfully');
        if (res.data.length === 1) {
          form.setFieldsValue({ saleOrderItemId: res.data[0].saleOrderItemId });
        }
      } else {
        if (res.intlCode) {
          setSoItemData([]);
          // AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage);
        }
        setSoItemData([]);
      }
    })
      .catch((err) => {
        setSoItemData(null);
        // AlertMessages.getErrorMessage(err.message);
      });
  };


  const saveGrn = (tableData) => {
    const flagSet: boolean[] = []
    if(form.getFieldValue('subPlant') == undefined ){
      flagSet.push(true)
    }
    tableData.forEach(element => {
      if (element.actualCount > 0 && element.actualQty > 0 && element.numberOfBoxes > 0 ) {
        flagSet.push(true)
      } else {
        flagSet.push(false)
      }
    });

    if (!(flagSet.includes(false))) {
      console.log(props.grnData)
      let grnItems: RmGrnItemDetails[] = [];
      let farmerCode
      tableData.forEach((element,index) => {
        if (element.supplierType == SupplierTypeEnum.BROKER) {
          farmerCode = element.brokerCode;
        } else {
          farmerCode = element.farmerCode;
        }
        const defects = defectsData.filter((el) => { return el.indentItemId == (index+1) })
        const grnItemDefects: GrnItemDefects[] = []
        defects.forEach((i) => {
          const defect = new GrnItemDefects(i.defect, i.defectId, i.defectPercentage, element.indentId, true)
          grnItemDefects.push(defect)
        })
        const grnItemObj = new RmGrnItemDetails(element.farmer, farmerCode, element.indentId, element.actualQty, element.actualCount, undefined, userName, undefined, element.farmCode, element.farmId, element.farmerName, undefined, undefined, undefined, undefined, element.softPercentage, element.numberOfBoxes, undefined, undefined, undefined, element.indentItemId, element.product, element.looseShell, element.fungusPercentage, element.remarks , grnItemDefects)
        grnItems.push(grnItemObj)
      });
      const grnData = new GrnDetails(form.getFieldValue('grnDate'), tableData[0].indentId, tableData[0].supplierType, userName, grnItems, form.getFieldValue('plant'), undefined, form.getFieldValue('subPlant') ? form.getFieldValue('subPlant') : 0 , undefined, undefined, undefined, undefined, undefined, undefined,tableData[0].harvestType)
      service.createGrn(grnData).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Grn Created Successfully');
          history.push("/rm-grn-grid");
          onReset();
        } else {
          if (res.intlCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
    } else {
      AlertMessages.getErrorMessage('Please Enter all data');
    }



    // }
  };
  /**
   *
   * @param values //Dto values
   */
  const saveData = () => {
    form.validateFields().then(customerRes => {
      // console.log(values);
  
      // if(values.currencyName.startsWith(" "))
      //   AlertMessages.getErrorMessage("Invalid Input");
  
      if (props.isUpdate) {
  
        props.updateItem(form.getFieldsValue());
      } else {
        saveGrn(tableData);
      }
    })

  };

  /**
  * To reset form fields
  */
  const onReset = () => {
    form.resetFields();
  };



  return (

    <Card
      title={<span style={{ color: 'white' }}>GRN</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >

      {props.isUpdate ?
        <>
          {/* <Layout style={{ padding: 10, backgroundColor: 'white', border: 10 }}> */}
          <Descriptions column={1}>
            <Descriptions.Item label="LOT Number">
              {props.grnData.lotNumber ? props.grnData.lotNumber : ''}
            </Descriptions.Item>
            <Descriptions.Item label="GRN Number">
              {props.grnData.grnNumber ? props.grnData.grnNumber : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Indent Number">
              {props.grnData.indentCode ? props.grnData.indentCode : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Farmer">
              {props.grnData.farmer ? props.grnData.farmerName : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Product">
              {props.grnData.productName ? props.grnData.productName : ''}
            </Descriptions.Item>
          </Descriptions>
          {/* </Layout> */}
        </>
        : ''}

      <Form
        layout={'vertical'}
        form={form}
        initialValues={props.grnData}
        name="control-hooks"
      // onFinish={saveData}
      >
        {/* <Form.Item name="plantId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item> */}
        <Form.Item name="grnId" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item name="grnItemId" initialValue={props.grnData?.grnItemId} style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item name="lotNumber" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item name="sale" style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="grnNumber"
          style={{ display: 'none' }}
        // rules={[
        //   {
        //     required: true,
        //     message: 'GRN Number Is Required',
        //   },
        //   {
        //     pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
        //     message: `Don't Allow Spaces`,
        //   },
        // ]}
        >
          <Input hidden />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name="createdUser"
          initialValue={createdUser}
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          style={{ display: 'none' }}
          name="indentId"
          label="Indent Number"
          rules={[
            {
              required: false,
              message: 'Indent Number Is Required',
            },
          ]}
        // initialValue={indentData?.indentId}
        >
          <Select
            placeholder="Select Indent No."
            // onChange={getIndentData?}
            // onClear={resetAll}
            allowClear
          // disabled={isPoDisabled}
          >
            {indentNumbers?.map((dropData) => {
              return (
                <Option
                  // name="poId"
                  key={dropData.indentId}
                  value={dropData.indentId}
                >
                  {dropData.indentCode}
                </Option>
              );
            })}
          </Select>
          {/* <Input /> */}
        </Form.Item>




        <Form.Item
          style={{ display: 'none' }}
          name="supplierType"
          label="Supplier Type"
          rules={[
            {
              required: false,
            },
            {
              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
              message: `Don't Allow Spaces`,
            },
          ]}
        // initialValue={indentData?.supplierType}
        >
          <Input />
        </Form.Item>



        <Form.Item
          style={{ display: 'none' }}
          name="farmer"
          label="Farmer"
          rules={[
            {
              required: false,
            },
            {
              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
              message: `Don't Allow Spaces`,
            },
          ]}
        // initialValue={indentData?.farmer}
        >
          <Input />
        </Form.Item>



        <Form.Item
          style={{ display: 'none' }}
          name="farmerCode"
          label="Farmer Code"
          rules={[
            {
              required: false,
            },
            {
              pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
              message: `Don't Allow Spaces`,
            },
          ]}
        // initialValue={indentData?.farmCode}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="grnDate" label="GRN Date" rules={[{ required: true, message: 'Missing GRN Date' }]} initialValue={moment()}>
          <DatePicker onChange={() => { }} style={{ width: '100%' }} />
        </Form.Item>

        <Row gutter={60}>

          {
            // ((JSON.parse(localStorage.getItem('role'))===EmployeeRolesEnum.RM_GRN))  &&
            props.isUpdate == true ?
           <>
           <Col xs={{ span: 16 }} sm={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6 }}>
             <Form.Item name="soType" label="SO Type" rules={[{ required: true, message: 'SO Type is required' }]} initialValue={SoProcessTypesEnum.MTO} >
              <Select
              
              showSearch     
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select SO Type"
                allowClear
                onChange = {e =>{getAllSaleOrders()}}
                defaultValue={SoProcessTypesEnum.MTO}
              >
                <Option key={'Saleorder'} value={SoProcessTypesEnum.MTO}>{'Saleorder'}</Option>
                <Option key={'Dummy'} value={SoProcessTypesEnum.MTS}>{'Dummy'}</Option>

              </Select>
            </Form.Item>
            </Col>
            <Col xs={{ span: 16 }} sm={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item
                  name="saleOrderItemId"
                  label="SO Number"
                rules={[
                  {
                    required: true,
                    message: 'SO Number Is Required',
                  },
                ]}
                // style={{width:300}}
                >
                  <Select
                    placeholder="Select Sale Order No."
                    onChange={(value,option) => {handleSoChange(value,option)}}
                    // onClear={resetAll}
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    dropdownMatchSelectWidth={false}
                  // disabled={isPoDisabled}
                  >
                     {soNumbers.map(dropData => {
                  return <Option name="saleOrderItemId" soId = {dropData.saleOrderId} soItem={dropData.saleOrderItemId} key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}>{dropData.item}</Option>
                })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} xl={{ span: 15 }} hidden>

                <Form.Item
                  name="saleOrderId"
                  label="SO Item"
                  rules={[
                    {
                      required: true,
                      message: 'SO Item Is Required',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Sale Order Item."
                    onChange={getSaleOrderItemDetails}
                    // onClear={resetAll}
                    showSearch
                    optionFilterProp="children"
                    // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    dropdownMatchSelectWidth={false}
                    allowClear
                  // disabled={isPoDisabled}
                  >
                    {soItemData.map((dropData) => {
                      return (
                        <Option
                          name="saleOrderId"
                          key={dropData.saleOrderItemId} value={dropData.saleOrderItemId}> {dropData.item} </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col> </>: ''
          }
        

        </Row>

        {soItemDetails ?
          <Row>
            <Descriptions column={3}>
              <Descriptions.Item label="Brand">
                {soItemDetails.brand ? soItemDetails.brand : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Grade">
                {soItemDetails.grade ? soItemDetails.grade : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Pack Style">
                {soItemDetails.packingStyle ? `${soItemDetails.packingStyle} ${soItemDetails.uom}` : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Variety">
                {soItemDetails.variety ? soItemDetails.variety : ''}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          : null}
        {
          showTable ?
              <>  
              <Row gutter={24}>
               <Col span={4}>
                    <Form.Item name='plant' label='Plant' rules={[{ required: true, message: 'Input required' }]} >
                      <Select >
                        {factoriesData?.map((i) => {
                          return <Option value={i.plantId}>{i.plantName}</Option>
                        })}
                      </Select>
                    </Form.Item>
               </Col>
               <Col span={4}>
                    <Form.Item name='subPlant' label='Sub plant' rules={[{ required: false, message: 'Input required' }]}  >
                      <Select placeholder={'Select Subplant'} allowClear >
                       {/* <Option value={null || 0}>Select Subplant</Option> */}

                        {subFactoriesData?.map((i) => {
                          return <Option value={i.plantId}>{i.plantName}</Option>
                        })}
                      </Select>
                    </Form.Item>
               </Col>  
               </Row>
            <br /><br />
              <Table scroll={{ x: 1000 }} dataSource={tableData} columns={(tableData[0]?.supplierType != SupplierTypeEnum.BROKER) ? columns.filter(col => (col.dataIndex != 'brokerName')) : columns} pagination={false} /> 
             </>:<></>
           
        }

        <Row gutter={40}>


          {
            (props.isUpdate || grnData) ?
              <><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="count"
                  label="Count"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                // initialValue={indentData&&indentData?.expectedCount}
                >
                  <Input />
                </Form.Item>
              </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                  {' '}
                  <Form.Item
                    name="quantity"
                    label="Quantity (IN KGs)"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                        message: `Quantity should not be negative.`
                      }


                    ]}
                  // initialValue={indentData&&indentData?.expectedQty}
                  >
                    <Input />
                  </Form.Item>

                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                  {' '}
                  <Form.Item
                    name="boxes"
                    label="No Of Boxes"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9\s]*$/,
                        message: `Boxes should not be negative.`
                      }


                    ]}
                  // initialValue={indentData&&indentData?.expectedQty}
                  >
                    <Input />
                  </Form.Item>

                  

                </Col>

                <span>
                  {username == '"admin"' ?
                    <>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                        {' '}
                        <Form.Item
                          name="price"
                          label="Price"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        // initialValue={indentData&&indentData?.expectedPrice}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </> : ''}
                </span></> :
              <Form.Item
                name="price"
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
          }
          {
            (props.isUpdate) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="softPercentage"
                  label="Soft %"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col> : ''
          }
          {
            (props.isUpdate) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="receivedBoxes"
                  label="Received Boxes"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                // initialValue={indentData&&indentData?.expectedPrice}
                >
                  <Input />
                </Form.Item>
              </Col> : ''
          }
          {
            (props.isUpdate) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="looseShell"
                  label="Loose Shell"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                // initialValue={indentData&&indentData?.expectedPrice}
                >
                  <Input />
                </Form.Item>
              </Col> : ''
          }
          {
            (props.isUpdate) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="fungusPercentage"
                  label="Fungus %"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                // initialValue={indentData&&indentData?.expectedPrice}
                >
                  <Input />
                </Form.Item>
              </Col> : ''
          }
          {
            (props.isUpdate) ?
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                {' '}
                <Form.Item
                  name="remarks"
                  label="Plant Lot Number"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                // initialValue={indentData&&indentData?.expectedPrice}
                >
                  <Input />
                </Form.Item>
              </Col> : ''
          }

        </Row><br />
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="button" onClick={saveData}>
              Submit
            </Button>
            {/* {props.isUpdate === false && (
              <Button
                htmlType="button"
                style={{ margin: '0 14px' }}
                onClick={onReset}
              >
                Reset
              </Button>
            )} */}
          </Col>
        </Row>
      </Form>
      <Modal key={index} width={'60%'} visible={addDeffects} onOk={() => setAddDeffects(false)} closable onCancel={() => setAddDeffects(false)}>
        <AddDefects indentItemId={index} setTableData={(values) => setDefectsData(prev => [...prev, ...values])} initialValues={defectsData?.filter((e) => { return e.indentItemId == index })} />
      </Modal>
    </Card >
  );
};

export default RawMaterialGrnForm;
