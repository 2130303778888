import { SoProcessTypesEnum } from "@gtpl/shared-models/common-models";

export class RackSuggestionDto{
    plantId:number;
    totalCases:number;
    inStockCases:number;
    soProcessType:boolean;
    // palletCapacity:number;
    // palletStatus:string;
    existingRackDetails?:RackPostions;
    newRackDetails?:RackPostions;
    constructor(plantId:number,
        totalCases:number,
        inStockCases:number,
        soProcessType:boolean,
        // palletCapacity:number,
        // palletStatus:string,
        existingRackDetails?:RackPostions,newRackDetails?:RackPostions){
            this.plantId = plantId;
            this.totalCases = totalCases;
            this.inStockCases = inStockCases;
            this.soProcessType = soProcessType;
            // this.palletCapacity = palletCapacity;
            // this.palletStatus = palletStatus;
            this.existingRackDetails = existingRackDetails;
            this.newRackDetails = newRackDetails;
        }

}
export class RackPostions{
    zoneId?:number;
    rackPositionId:number;
    rack:string;
    palletId?:number;
    palletCode?:string;
    palletCapacity?:number;
    palletStatus?:string;
    constructor(zoneId:number,rackPositionId:number,
        rack:string,
        palletId?:number,
        palletCode?:string,palletCapacity?:number,palletStatus?:string){
        this.zoneId = zoneId;
        this.rackPositionId = rackPositionId;
        this.rack = rack;
        this.palletId = palletId;
        this.palletCode = palletCode;
        this.palletCapacity = palletCapacity;
        this.palletStatus = palletStatus;
    }

}