import { ApiProperty } from "@nestjs/swagger";

export class ReprocessingBOMUpdateRequset{
    saleOrderId: number;
    saleOrderItemId: number;
    assignedQty: number;
    constructor(saleOrderId:number,saleOrderItemId:number,assignedQty: number){
        this.saleOrderItemId = saleOrderItemId
        this.assignedQty = assignedQty
        this.saleOrderId = saleOrderId
    }
}