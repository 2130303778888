import { GradesDto  } from "@gtpl/shared-models/masters";
import { GlobalResponseObject } from '@gtpl/shared-models/common-models';

export class GradesResponseModel extends GlobalResponseObject{
    data?: GradesDto;
    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /GradesDto
     */
     constructor(status: boolean, intlCode: number, internalMessage: string, data?: GradesDto) {
        super(status, intlCode, internalMessage);
        this.data = data;
    }
}
