export enum WarehouseLifeCycleEnum{
    SO_STOCK_IN = 'So stock in',
    SO_STOCK_OUT = 'So stock out',
    STOCK_NOT_MOVED_BEYOND_ETD = 'Stock not moved beyond ETD(With SO)',
    STOCK_MOVED_BEFORE_ETD = 'Stock moved BEFORE ETD(With SO)',
    STOCK_MOVED_ONETIME = 'Stock moved onetime(With SO)',
    WITH_OUT_SO_STOCK_IN = 'With out SO Stock in',
    WITH_OUT_SO_STOCK_OUT = 'With out SO Stock out',
    STOCK_NOT_MOVED_BEYOND_30_DAYS = 'Dummy stock not moved beyond 30days',
    STOCK_NOT_MOVED_BEYOND_60_DAYS = 'Dummy stock not moved beyond 60days',
}