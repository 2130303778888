import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { PaymentTermsDropDownDto } from './payment-terms-drop-down-dto';

export class PaymentTermsDropDownResponseModel extends GlobalResponseObject {
    data?: PaymentTermsDropDownDto[];
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: PaymentTermsDropDownDto[]){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}

