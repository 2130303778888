import axios from "axios";
import connection from './connection';
import {RecruitmentActivitiesDto, AllRecruitmentActivitiesResponse, RecruitmentActivitiesResponse } from '@gtpl/shared-models/hrms';

export class RecruitmentActivitiesService{

    URL = connection.DEPLOY_URL + '/recruitment-activities';

    async createRecruitmentActivity(data: RecruitmentActivitiesDto): Promise<RecruitmentActivitiesResponse>{        
        return await axios.post(this.URL + '/createRecruitmentActivity', data)
            .then(res => {
                return res.data
            })
    }

    async updateRecruitmentActivity(data: RecruitmentActivitiesDto): Promise<RecruitmentActivitiesResponse>{
        return await axios.post(this.URL + '/updateRecruitmentActivity', data)
            .then(res => {
                return res.data
            })
    }

    async getAllRecruitmentActivities(): Promise<AllRecruitmentActivitiesResponse>{
        return await axios.post(this.URL + '/getAllRecruitmentActivities')
           .then(res => {
               return res.data
           })
    }

    async activateOrDeactivateRecruitmentActivity(data: RecruitmentActivitiesDto): Promise<RecruitmentActivitiesResponse>{
        return await axios.post(this.URL + '/activateOrDeactivateRecruitmentActivity', data)
            .then(res => {
                return res.data
            })
    }

    async getAllActiveRecruitmentActivitys(): Promise<AllRecruitmentActivitiesResponse>{
        return await axios.post(this.URL + '/getAllActiveRecruitmentActivitys')
           .then(res => {
               return res.data
           })
    }
}