import React, { useEffect, useState, useRef } from 'react';
import { Table, Card,Modal, Select, Form, Button, Row, Col, Input,Drawer, Tooltip, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import './assign-so-grading.css';
import Link from 'antd/lib/typography/Link';
import {GradingService} from '@gtpl/shared-services/production';
import { AssignSoModel, GradeLotInvModel } from '@gtpl/shared-models/production-management';
/* eslint-disable-next-line */
import { AlertMessages } from '@gtpl/shared-utils/alert-messages';
import {GradingAssignment} from '@gtpl/pages/production-management/production-management-components/grading-assignment';
import { SearchOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {SoInfoViewPages} from '@gtpl/pages/sale-management/sale-pages/so-info-view-pages'
import { UnitRequest } from '@gtpl/shared-models/common-models';
import { PlantsDropDown, UnitTypeEnum } from '@gtpl/shared-models/masters';
import { UnitcodeService } from '@gtpl/shared-services/masters';

export interface AssignSoGradingProps {}

export function AssignSoGrading(props: AssignSoGradingProps) {
  const plantId = Number(localStorage.getItem('unit_id'));
  const createdUser = localStorage.getItem('createdUser');
  const [lotGradingInventory,setLotGradingInventory] = useState<any[]>([]);
  const [assignSOData,setAssignSOData] = useState<GradeLotInvModel>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);   
  const searchInput = useRef(null);
  const [saleId, setSaleId] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(''); 
  const gradingInvService = new GradingService();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [plantsData, setPlantsData] = useState<PlantsDropDown[]>([]);
  const plantType = localStorage.getItem('plantType')
  const unitservice = new UnitcodeService();
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      text ? (
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
      ) : null,
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }
  
  const handleCancel = () => {
    getLotLevelGradingInventory();
    setIsModalVisible(false);
  };

  const goToSaleDetailView = (saleOrderId: number) => {
    setSaleId(saleOrderId);
    console.log("soid",saleOrderId);
    setIsModalVisible(true)
  }

  const getAllActivePlants = () => {
    unitservice.getAllMainPlants().then((res) => {
      if (res.status) {
        setPlantsData(res.data);
      } else {
        // if (res.intlCode) {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // } else {
        //   AlertMessages.getErrorMessage(res.internalMessage);
        // }
        setPlantsData([]);
      }
    }).catch((err) => {
      AlertMessages.getErrorMessage(err.message);
      setPlantsData([]);
    });
  }


  const columnsSkelton: ColumnProps<GradeLotInvModel>[] = 
  [
    {
      title: 'Plant',
      dataIndex: 'plantName',
      // sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotnplantlot',
      // sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotnplantlot'),
    },
    {
      title: 'Planned SO Number',
      dataIndex: 'plannedSoNumber',
      sorter: (a, b) => a.plannedSoNumber.length - b.plannedSoNumber.length || a.plannedSoNumber.localeCompare(b.plannedSoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedSoNumber'),
       render: (value, record) => 
       <Link  onClick={e => goToSaleDetailView(value)} >{value}</Link>
    
    },
      {
      title: 'Po Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.length - b.poNumber.length || a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity(Kgs)',
      dataIndex: 'quantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Assigned SO Qty(Kgs)',
      dataIndex: 'assignedQty',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    }, 
    {
      title: 'Action',
      key:'action',
      width:100,
      render:(value,record)=>(<Tooltip title="Assign Count To SO">
          <DeliveredProcedureOutlined onClick={event=>openFormWithData(record)} style={{fontSize:'24px',color:'#1890ff'}}/>
        </Tooltip>)
        
    }
    
  ];
  const columnsSkeltonForSubplant: ColumnProps<GradeLotInvModel>[] = 
  [
    {
      title: 'Plant',
      dataIndex: 'subplantName',
      // sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotnplantlot',
      // sorter: (a, b) => a.concatLotNum.length - b.concatLotNum.length || a.concatLotNum.localeCompare(b.concatLotNum),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotnplantlot'),
    },
    {
      title: 'Planned SO Number',
      dataIndex: 'plannedSoNumber',
      sorter: (a, b) => a.plannedSoNumber.length - b.plannedSoNumber.length || a.plannedSoNumber.localeCompare(b.plannedSoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedSoNumber'),
       render: (value, record) => 
       <Link  onClick={e => goToSaleDetailView(value)} >{value}</Link>
    
    },
    {
      title: 'Po Number',
      dataIndex: 'poNumber',
      sorter: (a, b) => a.poNumber.length - b.poNumber.length || a.poNumber.localeCompare(b.poNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('poNumber'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity(Kgs)',
      dataIndex: 'quantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Assigned SO Qty(Kgs)',
      dataIndex: 'assignedQty',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    }
    
  ];
  const columnsSkeltonCompleted: ColumnProps<GradeLotInvModel>[] = [
    {
      title: 'Plant',
      dataIndex: 'plantName',
      // sorter: (a, b) => a.lotNo.length - b.lotNo.length || a.lotNo.localeCompare(b.lotNo),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plantName'),
    },
    {
      title: 'Lot Code',
      dataIndex: 'lotnplantlot',
      // sorter: (a, b) => a.lotnplantlot.length - b.lotnplantlot.length || a.lotnplantlot.localeCompare(b.lotnplantlot),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lotnplantlot'),
    },
    {
      title: 'Planned SO No.',
      dataIndex: 'plannedSoNumber',
      sorter: (a, b) => a.plannedSoNumber.length - b.plannedSoNumber.length || a.plannedSoNumber.localeCompare(b.plannedSoNumber),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('plannedSoNumber'),
      render: (value, record) => 
      <Link  onClick={e => goToSaleDetailView(record.saleOrderId)} >{value}</Link>
    
    },
    {
      title: 'Assigned SO',
      dataIndex: 'assignedSO',
      sorter: (a, b) => a.assignedSO.length - b.assignedSO.length || a.assignedSO.localeCompare(b.assignedSO),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('assignedSO'),
    },
    {
      title: 'Headless Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count.toString().length - b.count.toString().length || a.count.toString().localeCompare(b.count.toString()),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('count'),
    },
    {
      title: 'Quantity(Kgs)',
      dataIndex: 'quantity',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    },
    {
      title: 'Assigned SO Qty(Kgs)',
      dataIndex: 'assignedQty',
      render: (value) => (<span style={{float:'right'}}>{value}</span>)
    }
  ];

  
  useEffect(() => {
    getLotLevelGradingInventory();
    getAllActivePlants();
  }, []);
console.log(plantType)
  const getLotLevelGradingInventory = () => {
    const unitIdReq:UnitRequest={
      unitId: plantId
    }
    gradingInvService.getLotLevelGradingInventory({unitId:Number(localStorage.getItem('unit_id'))}).then(res => {
      if (res.status) {
        setLotGradingInventory(res.data);
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }      
      closeDrawer();
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  } 

  const assignCountToSO = (formInfo:AssignSoModel) => {
    formInfo.plantId = plantId;
    formInfo.createdUser = createdUser;
    gradingInvService.assignCountToSO(formInfo).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getLotLevelGradingInventory();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const closeDrawer = () =>{
    setDrawerVisible(false);
    setAssignSOData(null);
  }

  const openFormWithData= (viewData: GradeLotInvModel) =>{
    if(Number(viewData.assignedQty)<Number(viewData.quantity)){
      setDrawerVisible(true);
      setAssignSOData(viewData);
      console.log(viewData)
    } else {
      AlertMessages.getWarningMessage('Quantity already assigned to SO');
    }
  }

  return (
    <Card
      title={<span style={{ color: 'white' }}>Assign SO</span>}
      style={{ textAlign: 'center' }}
      headStyle={{ backgroundColor: '#69c0ff', border: 0 }}
    >
   
   <Form form={form} layout={'vertical'} style={{ padding: '0px' }}  >
   {/* <Row gutter={[24, 24]}>
   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Form.Item name="delUnitId" label="Unit" rules={[{ required: false }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Unit"
                  allowClear
                  disabled={Number(localStorage.getItem('unit_id')) != 5 ? true : false}
                >
                  <Option key={0} value={null}>Select Unit</Option>
                  {plantsData?.map(plant => {
                    return <Option key={plant.plantId} value={plant.plantId}>{plant.plantName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
    </Row> */}
      </Form>
      <Tabs type={'card'} tabPosition={'top'}>
        <TabPane 
            key="1"
            tab={<span style={{ color: "#f5222d" }}>Pending: {lotGradingInventory.filter(res=>Number(res.quantity)!==Number(res.assignedQty)).length}</span>}
            >
        <Table columns={ JSON.parse(plantType) == 'PLANT'? columnsSkelton:columnsSkeltonForSubplant} dataSource={lotGradingInventory.filter(res=>Number(res.quantity)!==Number(res.assignedQty))} bordered />
          <Drawer bodyStyle={{ paddingBottom: 80 }} width={window.innerWidth > 768 ? '80%' : '85%'}
                onClose={closeDrawer} visible={drawerVisible} closable={true}>
            <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
              {assignSOData?<GradingAssignment
                assignCountToSO={assignCountToSO}
                gradeLotInv={assignSOData}
                closeForm={closeDrawer} />:''}
            </Card>
          </Drawer>
        </TabPane>
        <TabPane 
          key="2"
          tab={<span style={{ color: "#20b72c" }}>Completed: {lotGradingInventory.filter(res=>Number(res.quantity)===Number(res.assignedQty)).length}</span>}
          >
          <Table columns={columnsSkeltonCompleted} dataSource={lotGradingInventory.filter(res=>Number(res.quantity)===Number(res.assignedQty))} bordered />
        </TabPane>
      </Tabs>
      <Modal
        className='so-info-view'
        key={'modal' + Date.now()}
        width={'80%'}
        style={{ top: 30, alignContent: 'right' }}
        visible={isModalVisible}
        title={<React.Fragment>
        </React.Fragment>}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <SoInfoViewPages saleOrderId={saleId} screen="production" />
      </Modal>
    </Card>
  );
}

export default AssignSoGrading;
