export class HrmEmployeeFamilyDetailsDto {

    employeeFamilyId: number;
    familyMemName: string;
    relation: string;
    famDateOfBirth: Date;
    famAadhaarNo: string;
    contactNo: string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string | null;
    updatedAt: Date;
    updatedUser: string | null;
    versionFlag: number;
}
