export class GradingModel {
    grade: number;
    boxes: number;
    quantity: number;
    receivedBoxes: number;
    receivedQuantity: number;
    type?: string;
    gradingInventoryId?:number;

    constructor(grade: number,boxes: number,quantity: number,receivedBoxes: number,receivedQuantity: number,type?: string,gradingInventoryId?:number){
        this.grade = grade;
        this.boxes = boxes;
        this.quantity = quantity;
        this.receivedBoxes = receivedBoxes;
        this.receivedQuantity = receivedQuantity;
        this.type = type;
        this.gradingInventoryId=gradingInventoryId;
    }
}