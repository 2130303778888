import React, {useEffect, useState} from 'react';
import {AlertMessages} from '@gtpl/shared-utils/alert-messages';
import {useLocation} from 'react-router-dom';
import './indent-detail-view-pages.css';
import {IndentService} from '@gtpl/shared-services/raw-material-procurement';
import {IndentModel} from '@gtpl/shared-models/raw-material-procurement';
import {IndentGrid} from '@gtpl/pages/raw-material-procurement/raw-material-procurement-components/indent-grid';
import { loadavg } from 'os';
import IndentDetailView from './indent-detail-view';

export interface IndentDetailViewPagesProps{}

export function IndentDetailViewPages(props: IndentDetailViewPagesProps) {
    useEffect(() => {
        getAllIndents();
      }, []);
      const [selectedIndent, setSelectedIndent] = useState<IndentModel[]>([]);
      const service = new IndentService();
    
      // get store return sdata 
      const getAllIndents = () => {
        service.getAllIndents().then((res) => {
          if(res.status){
            setSelectedIndent(res.data);
            console.log(selectedIndent);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
          setSelectedIndent([]);
        });
      }
    
    
    // const [loading, setLoading] = useState(true);
    
    // let location = useLocation();
    // const indentId = location.state;
    // const getIndentDetailsById=(indentId) => {
    //     const req= new IndentRequest();
    //     req.indentId= indentId
    //     service.getIndentDetailsForIndentId(req).then(res =>{
    //         if(res.status){
    //             setSelectedIndent(res.data);
    //         }else{
    //             if(res.intlCode) {
    //                 setSelectedIndent(undefined);
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             }else{
    //                 AlertMessages.getErrorMessage(res.internalMessage);
    //             }
    //         }
    //     }).catch(err => {
    //         AlertMessages.getErrorMessage(err.message);
    //         setSelectedIndent(undefined);
    //     })
    // }

    // useEffect(() => {
    //     getIndentDetailsById(indentId);
    // },[indentId]);
    return(
        <>
         <IndentDetailView IndentDetails={selectedIndent} />
        </>
    )
}
