import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import {  ResourceOperationEfficiencyDto } from "@gtpl/shared-models/planning";

export class ResourceOperationEfficiencyResponseModel extends GlobalResponseObject{
    data?: ResourceOperationEfficiencyDto;

    constructor(status: boolean, intlCode: number, internalMessage: string, data?: ResourceOperationEfficiencyDto){
        super(status,intlCode,internalMessage);
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }
}
