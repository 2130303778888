export class ContractorDto {
    contractorId:number;
    contractorName:string;
    contractorProId:string;
    contractorType:string;
    noOfEmployees:number;
    fromDate:any;
    toDate:any;
    contractPeriod:string;
    isActive: boolean;
    createdAt: Date;
    createdUser: string;
    updatedAt: Date;
    updatedUser: string;
    versionFlag: number;

    /**
     * 
     * @param contractorId 
     * @param contractorName 
     * @param contractorProId 
     * @param contractorType 
     * @param noOfEmployees 
     * @param fromDate 
     * @param toDate 
     * @param contractPeriod 
     * @param isActive 
     * @param createdAt 
     * @param createdUser 
     * @param updatedAt 
     * @param updatedUser 
     * @param versionFlag 
     */
 
    constructor( contractorId:number,contractorName:string, contractorProId:string,contractorType:string, noOfEmployees:number,fromDate:Date,toDate:Date,contractPeriod:string,isActive: boolean,createdAt: Date,createdUser: string,updatedAt: Date,updatedUser: string,versionFlag: number,){

        this.contractorId = contractorId;
        this.contractorName = contractorName;
        this.contractorProId = contractorProId;
        this.contractorType = contractorType;
        this.noOfEmployees = noOfEmployees;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.contractPeriod = contractPeriod;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.createdUser = createdUser;
        this.updatedAt = updatedAt;
        this.updatedUser = updatedUser;
        this.versionFlag = versionFlag;
    }

}