import axios from 'axios';
import {VariantResponseModel, AllVariantResponseModel,VariantStatusWiseResponseModel,VariantDto} from '@gtpl/shared-models/masters';
import connection from './connection';

export class VariantService {
URL = connection.DEPLOY_URL + '/Item-Variant';

  async createVariant(variant: VariantDto): Promise<VariantResponseModel> {
      return await axios.post(this.URL + '/createVariant',variant)
          .then(res => {
              return res.data
          })
  }
  async  updateVariant(variant: VariantDto): Promise<VariantResponseModel> {
    return await axios.post(this.URL + '/updateVariant', variant)
           .then(res => {
               return res.data
           })
}
  async getAllVariants(): Promise<AllVariantResponseModel> {
    return await axios.post(this.URL + '/getAllVariants')
        .then(res => {
            return res.data
        })     
}
async  ActivatedeActivateVariant(variantDto: VariantDto): Promise<VariantResponseModel> {
    //   console.log(State.stateCode);
      return await axios.post(this.URL + '/updateVariantStatus', variantDto)
              .then(res => {
                  return res.data
              })
  }
  async  getAllActiveVariants(): Promise<AllVariantResponseModel> {
    return await axios.post(this.URL + '/getAllActiveVariants')
        .then(res => {
            return res.data
        })
}
async getActiveVariantsCount(): Promise<VariantStatusWiseResponseModel> {
  return await axios.post(this.URL + '/getActiveVariantCount').then(res => {
      return res.data
  });
}

}