import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { SubPlantOperationsDto } from './sub-plant-operations.dto';

export class SubPlantOperationsResponseModel extends GlobalResponseObject{
    data?: SubPlantOperationsDto;
    constructor(status: boolean, intlCode: number, internalMessage: string, data?: SubPlantOperationsDto){
        super(status,intlCode,internalMessage)
        this.status = status;
        this.intlCode = intlCode;
        this.internalMessage = internalMessage;
        this.data = data;
    }

}