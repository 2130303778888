import { GlobalResponseObject } from '@gtpl/shared-models/common-models';
import { BomSODto } from './all-bom-so.dto';


export class GetAllBomDetailsResponseModel extends GlobalResponseObject {
    data?: BomSODto[];

    /**
     * 
     * @param status 
     * @param errorCode 
     * @param internalMessage 
     * @param data /BomSODto[]
     */
    constructor(status: boolean, errorCode: number, internalMessage: string, data?: BomSODto[]) {
        super(status, errorCode, internalMessage);
        this.data = data;
    }
}

 