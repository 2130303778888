import { ContainerStatusEnum } from "@gtpl/shared-models/common-models";
import { SaleOrderDropDownDto } from "@gtpl/shared-models/sale-management";

export class GrnStatusUpdate{
    indentId:number;
    grnCompleted:Boolean;
    createdUser?:string;
    updatedUser?:string;

    /**
     * 
     * @param containerNumber 
     * @param saleOrderId 
     * @param containerStatus 
     * @param containerId 
     */
    constructor( indentId:number,
        grnCompleted:Boolean,
        createdUser?:string,
        updatedUser?:string,)
    {
        this.indentId = indentId;
        this.grnCompleted = grnCompleted;
        this.createdUser = createdUser;
        this.updatedUser = updatedUser;
    }
}